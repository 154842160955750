import React, { useState } from 'react'
import Scrollable from 'shared/components/Scrollable'
import Button from 'shared/components/Button'
import Location from './Location'
import EditLocation, { LocationType } from './EditLocation'

type Props = {
  locations: string[]
  onAdd: (location: string) => void
  onDelete: (index: number) => void
  onUpdate: (index: number, location: string) => void
}

const Locations = (props: Props) => {
  const { locations, onAdd, onDelete, onUpdate } = props
  const [editing, setEditing] = useState<number>(-1)

  const handleEdit = (index: number) => {
    setEditing(index)
  }

  const handleDelete = (index: number) => {
    onDelete(index)
  }

  const handleUpdate = (location: LocationType) => {
    if (editing < locations.length) {
      // edit existing location
      onUpdate(editing, JSON.stringify(location))
    } else {
      // new location
      onAdd(JSON.stringify(location))
    }

    setEditing(-1)
  }

  const handleAdd = () => {
    setEditing(locations.length)
  }

  const handleCancel = () => {
    setEditing(-1)
  }

  return (
    <>
      <Scrollable>
        {locations.map((l, index) => (
          <div key={`location-${index}`}>
            {editing === index ? (
              <EditLocation {...JSON.parse(l)} onUpdate={handleUpdate} />
            ) : (
              <Location
                location={l}
                onDelete={
                  editing !== -1 ? undefined : () => handleDelete(index)
                }
                onEdit={editing !== -1 ? undefined : () => handleEdit(index)}
              />
            )}
          </div>
        ))}
      </Scrollable>
      {editing === locations.length && (
        <EditLocation onUpdate={handleUpdate} onCancel={handleCancel} />
      )}
      {editing === -1 && (
        <div className='flex justify-end mt2'>
          <Button autoSize onClick={handleAdd}>
            Add a new Location
          </Button>
        </div>
      )}
    </>
  )
}

export default Locations
