import React from 'react'
import PageSection from 'shared/components/PageSection'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import SustainabilitySurvey from '../SustainabilitySurvey'
import RequestSustainabilitySurvey from '../RequestSustainabilitySurvey'
import surveySelectors from 'buyer/SupplierProfile/selectors/surveySelectors'
import useIsFeatureEnabled from 'shared/utils/featureFlags/useIsFeatureEnabled'
import SustainabilityQuickFacts from '../SustainabilityQuickFacts'

type Props = {
  supplierId: string
}

const SustainabilitySection = (props: Props) => {
  const survey = useSelector(surveySelectors.getEsgSurvey)
  const isESGSurveyRequestEnabled = useIsFeatureEnabled('ESGSurveyRequest')
  const isESGSurveyPhase2 = useIsFeatureEnabled('ESGSurveyPhase2')
  const attestedDate = survey?.get('attestedDate')

  return attestedDate || isESGSurveyRequestEnabled ? (
    <PageSection
      title={
        <FormattedMessage
          id='SustainabilitySection.SustainabilityTitle'
          defaultMessage='Sustainability Survey'
        />
      }
    >
      {!attestedDate && <RequestSustainabilitySurvey />}
      {!!attestedDate &&
        (isESGSurveyPhase2 ? (
          <SustainabilityQuickFacts />
        ) : (
          <SustainabilitySurvey
            attestedDate={attestedDate}
            supplierId={props.supplierId}
          />
        ))}
    </PageSection>
  ) : null
}

export default SustainabilitySection
