import React, { Component, ReactNode } from 'react'
import Paper from 'shared/components/Paper'
import classNames from 'classnames'

const styles = {
  wrapper: 'pt4-ns pt3',
  header: 'f7 fw6 ma0 dt w-100',
  title: 'f7 fw6 ma0 dtc v-mid',
  actions: 'dtc tr'
}

type Props = {
  title: string | ReactNode
  hideTitle?: boolean
  actions?: ReactNode
  children: ReactNode
  noPadding?: boolean
  className?: string
  style?: { marginTop: number }
}

class PageSideSection extends Component<Props> {
  render() {
    const {
      title,
      actions,
      children,
      noPadding,
      className,
      hideTitle
    } = this.props

    return (
      <section className={className || styles.wrapper}>
        <Paper noPadding={noPadding}>
          <div className={classNames(styles.header, !hideTitle && 'pt3-5')}>
            <h4
              className={classNames(
                styles.title,
                hideTitle && 'clip',
                noPadding && 'pl3-5'
              )}
            >
              {title}
            </h4>

            {actions && <div className={styles.actions}>{actions}</div>}
          </div>
          <main className={!hideTitle ? 'mt2' : ''}>{children}</main>
        </Paper>
      </section>
    )
  }
}

export default PageSideSection
