export const SEARCH_SUPPLIER = 'SEARCH_SUPPLIER'
export const CLEAR_SEARCH_KEYWORD = 'CLEAR_SEARCH_KEYWORD'

export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS'
export const ADD_FILTER_OPTION = 'ADD_FILTER_OPTION'
export const REMOVE_FILTER_OPTION = 'REMOVE_FILTER_OPTION'
export const TOGGLE_FILTER_OPTION = 'TOGGLE_FILTER_OPTION'
export const CHANGE_FILTER_OPTION = 'CHANGE_FILTER_OPTION'
export const MERGE_FILTER_OPTION = 'MERGE_FILTER_OPTION'

export const UPDATE_QUERY_STRING = 'buyer/Search/UPDATE_QUERY_STRING'

export const ADD_COLLECTION = 'ADD_COLLECTION'
export const REMOVE_COLLECTION = 'REMOVE_COLLECTION'
export const CLEAR_COLLECTION = 'CLEAR_COLLECTION'
export const LOAD_COLLECTION = 'LOAD_COLLECTION'
export const SET_COLLECTION = 'SET_COLLECTION'

export const SET_SIMILAR_SUPPLIERS = 'buyer/Search/SET_SIMILAR_SUPPLIERS'
export const CLEAR_SIMILAR_SUPPLIERS = 'buyer/Search/CLEAR_SIMILAR_SUPPLIERS'

export const UPDATE_SCROLL_POSITION = 'buyer/Search/UPDATE_SCROLL_POSITION'

export const TOGGLE_SEARCH_DETAILS = 'buyer/Search/TOGGLE_SEARCH_DETAILS'

export const OPEN_ADD_NOTE_DIALOG = 'buyer/Search/OPEN_ADD_NOTE_DIALOG'
export const OPEN_ADD_TAG_DIALOG = 'buyer/Search/OPEN_ADD_TAG_DIALOG'
export const CLOSE_ADD_TAG_DIALOG = 'buyer/Search/CLOSE_ADD_TAG_DIALOG'
export const ADD_TAG_TO_SUPPLIER = 'buyer/Search/ADD_TAG_TO_SUPPLIER'
export const REMOVE_TAG_FROM_SUPPLIER = 'buyer/Search/REMOVE_TAG_FROM_SUPPLIER'
export const REMOVE_LABEL_FROM_SUPPLIER =
  'buyer/Search/REMOVE_LABEL_FROM_SUPPLIER'

export const UPDATE_SUPPLIER_REVIEW = 'buyer/Search/UPDATE_SUPPLIER_REVIEW'
export const CLOSE_SUPPLIER_REVIEW_DIALOG =
  'buyer/Search/CLOSE_SUPPLIER_REVIEW_DIALOG'
export const SUBMIT_SUPPLIER_REVIEW = 'buyer/Search/SUBMIT_SUPPLIER_REVIEW'

export const TOGGLE_FILTER_PANEL = 'buyer/Search/TOGGLE_FILTER_PANEL'
export const CLEAR_FILTER_PANELS = 'buyer/Search/CLEAR_FILTER_PANELS'
export const SET_FILTER_PANELS = 'buyer/Search/SET_FILTER_PANELS'

export const INVITE_SUPPLIER = 'buyer/Search/INVITE_SUPPLIER'
export const OPEN_INVITE_SUPPLIER_DIALOG =
  'buyer/Search/OPEN_INVITE_SUPPLIER_DIALOG'
export const CLOSE_INVITE_SUPPLIER_DIALOG =
  'buyer/Search/CLOSE_INVITE_SUPPLIER_DIALOG'
export const FOUND_EXISTING_ORGS = 'buyer/Search/FOUND_EXISTING_ORGS'
export const CLEAR_FOUND_ORGS = 'buyer/Search/CLEAR_FOUND_ORGS'

export const OPEN_FILTERS_DIALOG = 'buyer/Search/OPEN_FILTERS_DIALOG'
export const CLOSE_FILTERS_DIALOG = 'buyer/Search/CLOSE_FILTERS_DIALOG'

export const SEARCH_LOCATION = 'buyer/Search/SEARCH_LOCATION'

export const GET_ORG_CHILDREN = 'buyer/Search/GET_ORG_CHILDREN'

export const CLEAR_ERROR_MESSAGE = 'buyer/Search/CLEAR_ERROR_MESSAGE'

export const SEND_SEARCH_FEEDBACK = 'buyer/Search/SEND_SEARCH_FEEDBACK'

export const LOAD_SOW_JOB = 'buyer/SEARCH/LOAD_SOW_JOB'
export const SET_TAB_FILTER = 'buyer/Search/SET_TAB_FILTER'
