import isUrl from 'is-url-superb'

export const notEmpty = value =>
  value && !value.trim() ? 'Value is empty' : undefined

const checkTwitter = twitterId => {
  var blacklist = [
    'search',
    'follow',
    'tweet',
    'twitter',
    'favorite',
    'login',
    'share',
    'user',
    'retweet'
  ]

  if (twitterId.indexOf('?') > -1) {
    twitterId = twitterId.split('?')[0]
  }

  var twitterRaw = twitterId.replace(/@/g, '')

  // if not a username (letters, numbers, underscores), or has two @ symbols, or is a blacklisted username, or username is empty, or username is a widget ID
  if (
    !twitterRaw.match(/^\w+$/) ||
    twitterId.indexOf('@@') > -1 ||
    blacklist.indexOf(twitterRaw) > -1 ||
    !twitterRaw ||
    (twitterRaw.length === 18 && !isNaN(twitterRaw))
  ) {
    return false
  } else {
    return true
  }
}

const checkFacebook = facebookUrl => {
  var blacklist = [
    'facebook.com/search/',
    'facebook.com/events/',
    'facebook.com/sharer.php'
  ]
  var whitelist = ['fb.me', 'facebook.de']
  var isWhitelisted = false

  // check if profile URL is linking to a website's root url, or is apart of blacklist
  if (
    /^https?:\/\/[^/]+\/?.$/.test(facebookUrl) ||
    new RegExp(blacklist.join('|')).test(facebookUrl)
  ) {
    return false
  }

  // check if profile URL apart of whitelist
  if (new RegExp(whitelist.join('|')).test(facebookUrl)) {
    isWhitelisted = true
  }

  // if not linking to facebook.com and not a whitelisted domain,
  // and it's not a facebook username (with no domain)
  if (
    facebookUrl.indexOf('facebook.com') === -1 &&
    !isWhitelisted &&
    !facebookUrl.match(/^\w+$/)
  ) {
    return false
  } else {
    return true
  }
}

const checkLinkedin = linkedInUrl => {
  var blacklist = [
    'linkedin.com/hp/',
    'linkedin.com/shareArticle',
    'linkedin.com/manageGroup',
    'linkedin.com/pulse',
    'linkedin.com/influencer',
    '/vsearch',
    '/login'
  ]

  // check if the profile URL is in blacklist, or is linking to a website's root url, or is not linking to a linkedin.com page
  if (
    /^https?:\/\/[^/]+\/?.$/.test(linkedInUrl) ||
    new RegExp(blacklist.join('|')).test(linkedInUrl) ||
    linkedInUrl.indexOf('linkedin.com') === -1
  ) {
    return false
  }

  return true

  // check if domain is part of the whitelist (so doesnt have to be linkedin.com)
  // var isWhitelisted = false
  // if (whitelist.length > 0 && new RegExp(whitelist.join('|')).test(linkedInUrl)) {
  //  isWhitelisted = true
  // }
}

export const validateLinkedIn = value => {
  return !value || checkLinkedin(value)
    ? undefined
    : 'URL does not seem to be valid. Please copy and paste the URL from your LinkedIn page.'
}

export const validateTwitter = value => {
  return !value
    ? undefined
    : value[0] !== '@'
    ? 'Please enter twitter handle starting with @'
    : checkTwitter(value)
    ? undefined
    : 'Twitter handle does not seem to be valid.'
}

export const validateFacebook = value => {
  return !value || checkFacebook(value)
    ? undefined
    : 'URL does not seem to be valid. Please copy and paste the URL from your Facebook page.'
}

export const validateUrl = value => {
  const testUrl =
    value && value.indexOf('http') !== 0 && value.indexOf('//') !== 0
      ? `//${value}`
      : value
  return !testUrl || isUrl(testUrl)
    ? undefined
    : 'URL does not seem to be valid.'
}

export const validateDomain = domain => {
  var re = new RegExp(
    /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/
  )
  return !!domain.match(re)
}
