import React, { ChangeEvent, useState } from 'react'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettings } from '../../actions'
import RootState from 'shared/models/RootState'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import SamlSettings from '../SamlSettings'
import Label from 'shared/components/Label'
import FilterRulesSetting from '../FilterRulesSetting'
import JsonSettings from '../JsonSettings'
import { FormattedMessage } from 'react-intl'
import PartnersSetting from '../PartnersSetting'
import withStyles from '@material-ui/styles/withStyles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const unParseFilters = filters => {
  return Object.keys(filters).reduce<Array<{ [x: string]: string }>>(
    (results, key) => {
      results.push({ [key]: filters[key] })
      return results
    },
    []
  )
}

const StyleCheckbox = withStyles({
  root: {
    padding: 3
  }
})(Checkbox)

const TechSettings = () => {
  const dispatch = useDispatch()
  const isReadonly = useSelector(
    (state: RootState) => !!state.get('session').get('partnerConcierge')
  )

  const acceptCertBasedOnReportStartDate = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'acceptCertBasedOnReportStartDate')
  )
  const fixedFilters = useSelector(settingsSelectors.getOrgFixedFilters)
  const fixedFiltersMessage = useSelector(
    settingsSelectors.getOrgFixedFiltersMessage
  )
  const defaultFilters = useSelector(settingsSelectors.getOrgDefaultFilters)
  const themeOptions = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'themeOptions')
  )
  const supplierProfileCustomPage = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'supplierProfileCustomPage')
  )
  const [editFixedFiltersMessage, setEditFixedFiltersMessage] = useState<
    string
  >(fixedFiltersMessage)
  const [editThemeOptions, setEditThemeOptions] = useState<{
    [key: string]: string
  }>(() => {
    const theme = themeOptions?.toJS()
    return !theme || Array.isArray(theme) ? {} : theme
  })

  const handleUpdateSettings = (settings: { [key: string]: any }) => {
    dispatch(updateSettings(settings))
  }

  const handleThemeOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    const newTheme = Object.assign({}, editThemeOptions, { [name]: value })
    setEditThemeOptions(newTheme)
    handleUpdateSettings({ themeOptions: newTheme })
  }

  return (
    <>
      <FormControlLabel
        label={
          <FormattedMessage
            id='Settings.AcceptCertBaseOnReportStartDate'
            defaultMessage='Accept Certificates Based On Report Start Date'
          />
        }
        control={
          <StyleCheckbox
            color='primary'
            name='acceptCertBasedOnReportStartDate'
            size='small'
            checked={acceptCertBasedOnReportStartDate}
            onChange={() =>
              handleUpdateSettings({
                acceptCertBasedOnReportStartDate: !acceptCertBasedOnReportStartDate
              })
            }
            disabled={isReadonly}
          />
        }
      />
      <SamlSettings />
      <div className='mt3'>
        <FilterRulesSetting
          fieldName='fixedFilters'
          label='Fixed Filters'
          description={`eg. [{ "attributes": ["existing", "preferred"] }]`}
          value={
            fixedFilters
              ? JSON.stringify(unParseFilters(fixedFilters.toJS()))
              : ''
          }
          onUpdate={handleUpdateSettings}
          isReadonly={isReadonly}
        />
      </div>
      <Label htmlFor='fixedFiltersMessage'>Fixed Filters Message</Label>
      <Input
        name='fixedFiltersMessage'
        value={editFixedFiltersMessage}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setEditFixedFiltersMessage(e.currentTarget.value)
        }
        onBlur={() =>
          handleUpdateSettings({
            fixedFiltersMessage: editFixedFiltersMessage
          })
        }
        readOnly={isReadonly}
      />
      <div className='mt3'>
        <FilterRulesSetting
          fieldName='defaultFilters'
          label='Default Filters'
          description={`eg. [{ "attributes": ["existing", "preferred"] }]`}
          value={
            fixedFilters
              ? JSON.stringify(unParseFilters(defaultFilters.toJS()))
              : ''
          }
          onUpdate={handleUpdateSettings}
          isReadonly={isReadonly}
        />
      </div>

      <PartnersSetting />

      <Label>Theme Options</Label>
      <div className='mt2 br1 ba b--black-10'>
        <Table stickyHeader size='small'>
          <TableHead className='bg-light-gray pv3'>
            <TableRow>
              <TableCell>Property</TableCell>
              <TableCell style={{ width: 100 }}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Text>Primary Color (Default #028383)</Text>
              </TableCell>
              <TableCell style={{ width: 100 }}>
                <Input
                  style={{ padding: 0 }}
                  name={'primaryColor'}
                  ariaLabel='Primary Color'
                  value={editThemeOptions['primaryColor'] || '#028383'}
                  onChange={handleThemeOptionChange}
                  type='color'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Text>Primary Light Color (Default #DCF6F4)</Text>
              </TableCell>
              <TableCell style={{ width: 100 }}>
                <Input
                  style={{ padding: 0 }}
                  name={'primaryLightColor'}
                  ariaLabel='Primary Light Color'
                  value={editThemeOptions['primaryLightColor'] || '#DCF6F4'}
                  onChange={handleThemeOptionChange}
                  type='color'
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <JsonSettings
        label={
          <FormattedMessage
            id='Settings.AdditionalQuestions'
            defaultMessage='Additional Questions for Supplier Profiles'
          />
        }
        description={`eg. {"title": "Section 1","pageSections": [{"title": "Custom",
    "questions": [{"questionId": "QuestionId","required": false}]}]}`}
        fieldName='supplierProfileCustomPage'
        value={
          supplierProfileCustomPage
            ? JSON.stringify(supplierProfileCustomPage.toJS())
            : ''
        }
        isReadOnly={isReadonly}
        onUpdate={handleUpdateSettings}
      />
    </>
  )
}

export default TechSettings
