import React, { Component } from 'react'
import classNames from 'classnames'

/**
 * This component displays an html combobox like:
 * <select/>
 * with extra error handling, usually through React Forms Passing values <br/>
 * When used with redux forms the value of the combobox is saved to the redux form state
 */

export type SelectProps = {
  value?: any
  id?: string
  name?: string
  input?: { name: string; value: string }
  className?: string
  fullWidth?: boolean
  disabled?: boolean
  meta?: { touched?: boolean; error?: string }
  onChange?: (e: any) => void
  required?: boolean
  wrapperClassName?: string
  customLabel?: boolean
}

class Select extends Component<SelectProps> {
  static defaultProps = {
    meta: {}
  }

  render() {
    const {
      input,
      className,
      wrapperClassName = 'ba pa2 b--black-10 bg-white',
      fullWidth,
      meta = {},
      id,
      name,
      customLabel,
      ...rest
    } = this.props

    return (
      <div className={classNames(['dib', fullWidth ? 'w-100' : 'default'])}>
        <div className={wrapperClassName}>
          <select
            aria-label={!customLabel ? 'Select' : ''}
            name={name}
            id={id}
            {...input}
            {...rest}
            className={className || 'f7 w-100 br1 bn'}
          />
        </div>
        {meta.touched && meta.error && (
          <div className='f7 fw3 red mt2'>{meta.error}</div>
        )}
      </div>
    )
  }
}

export default Select
