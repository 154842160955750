import { call } from 'redux-saga/effects'
import vets from 'shared/utils/api/vets'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* approveVetSupplier(action) {
  const vetBuyerSupplier = yield call(vets.approveVetSupplier, action.payload)
  return yield { vetBuyerSupplier }
}

export default createApiRequestSaga(approveVetSupplier)
