import { all, takeLatest } from 'redux-saga/effects'
import * as actionTypes from '../actionTypes'
import getCommunitiesSaga from './getCommunitiesSaga'
import getCommunitySaga from './getCommunitySaga'
import inviteToCommunitiesSaga from './inviteToCommunitiesSaga'
import batchInviteToCommunitiesSaga from './batchInviteToCommunitiesSaga'
import updateCommunityBuyerSaga from './updateCommunityBuyerSaga'
import updateCommunitySaga from './updateCommunitySaga'
import removeFromCommunitySaga from './removeFromCommunitySaga'
import inviteExistingSaga from './inviteExistingSaga'
import loadCommunityInsightSaga from './loadCommunityInsightSaga'
import batchInviteToTier2Saga from './batchInviteToTier2Saga'

export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.GET_COMMUNITIES, getCommunitiesSaga),
    takeLatest(actionTypes.GET_COMMUNITY, getCommunitySaga),
    takeLatest(actionTypes.INVITE_TO_COMMUNITIES, inviteToCommunitiesSaga),
    takeLatest(
      actionTypes.BATCH_INVITE_TO_COMMUNITIES,
      batchInviteToCommunitiesSaga
    ),
    takeLatest(actionTypes.UPDATE_COMMUNITY_BUYER, updateCommunityBuyerSaga),
    takeLatest(actionTypes.UPDATE_COMMUNITY, updateCommunitySaga),
    takeLatest(actionTypes.REMOVE_FROM_COMMUNITY, removeFromCommunitySaga),
    takeLatest(actionTypes.INVITE_EXISTING, inviteExistingSaga),
    takeLatest(actionTypes.LOAD_COMMUNITY_INSIGHT, loadCommunityInsightSaga),
    takeLatest(actionTypes.BATCH_INVITE_TO_TIER2, batchInviteToTier2Saga)
  ])
}
