import React from 'react'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../../routes/paths'
import { FormattedMessage } from 'react-intl'
import { useLocation, useHistory } from 'react-router-dom'
import useThemeColors from 'shared/utils/useThemeColors'
import parsePath from 'shared/utils/parsePath'
import { useParams } from 'react-router-dom'
import BannerBadge from 'supplier/Survey/components/EsgSurvey/BannerBadge'
import qs from 'qs'

type Props = {
  redirectFrom?: string
}

const EsgNav = ({ redirectFrom }: Props) => {
  const params = useParams()
  const { supplierId } = params
  const history = useHistory()
  const location = useLocation()
  const colors = useThemeColors()
  const { pathname } = location

  const handleIconClick = (
    pageId:
      | 'healthSafety'
      | 'environment'
      | 'diversityInclusion'
      | 'governance'
      | 'privacy'
  ) => {
    if (pageId === 'healthSafety') {
      history.push(
        `${parsePath(paths.supplierHealthSafety, { supplierId })}${
          redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''
        }`
      )
    } else if (pageId === 'environment') {
      history.push(
        `${parsePath(paths.supplierEnvironment, { supplierId })}${
          redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''
        }`
      )
    } else if (pageId === 'diversityInclusion') {
      history.push(
        `${parsePath(paths.supplierDiversityInclusion, { supplierId })}${
          redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''
        }`
      )
    } else if (pageId === 'governance') {
      history.push(
        `${parsePath(paths.supplierGovernance, { supplierId })}${
          redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''
        }`
      )
    } else if (pageId === 'privacy') {
      history.push(
        `${parsePath(paths.supplierPrivacy, { supplierId })}${
          redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''
        }`
      )
    }
  }

  return (
    <div className='pa3 mv2 mt3-ns mb5-ns'>
      <div className='mw8 center'>
        <div className='flex justify-between'>
          <div className='tc'>
            <div
              className='dib mb3 pointer dim'
              onClick={() => handleIconClick('healthSafety')}
            >
              <BannerBadge
                pageId='healthSafety'
                started={pathname.includes('esg-survey/health-safety')}
                completed={!pathname.includes('esg-survey/health-safety')}
                missing={false}
              />
            </div>
            <br />
            <NavLink
              to={`${parsePath(paths.supplierHealthSafety, { supplierId })}${
                redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''
              }`}
              label={
                <FormattedMessage
                  id='EsgNav.HealthSafety'
                  defaultMessage='Health & Safety'
                />
              }
              style={{ marginRight: 0 }}
              className='f5-ns'
              activeClassName={`${colors.primaryText} ${colors.primaryBorder} bw2`}
            />
          </div>
          <div className='tc'>
            <div
              className='dib mb3 pointer dim'
              onClick={() => handleIconClick('environment')}
            >
              <BannerBadge
                pageId='environment'
                started={pathname.includes('esg-survey/environment')}
                completed={!pathname.includes('esg-survey/environment')}
                missing={false}
              />
            </div>
            <br />
            <NavLink
              to={`${parsePath(paths.supplierEnvironment, { supplierId })}${
                redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''
              }`}
              label={
                <FormattedMessage
                  id='EsgNav.Environment'
                  defaultMessage='Environment'
                />
              }
              style={{ marginRight: 0 }}
              activeClassName={`${colors.primaryText} ${colors.primaryBorder} bw2`}
            />
          </div>
          <div className='tc'>
            <div
              className='dib mb3 pointer dim'
              onClick={() => handleIconClick('diversityInclusion')}
            >
              <BannerBadge
                pageId='diversityInclusion'
                started={pathname.includes('esg-survey/diversity-inclusion')}
                completed={!pathname.includes('esg-survey/diversity-inclusion')}
                missing={false}
              />
            </div>
            <br />
            <NavLink
              to={`${parsePath(paths.supplierDiversityInclusion, {
                supplierId
              })}${redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''}`}
              label={
                <FormattedMessage
                  id='EsgNav.Diversity'
                  defaultMessage='Diversity & Inclusion'
                />
              }
              style={{ marginRight: 0 }}
              activeClassName={`${colors.primaryText} ${colors.primaryBorder} bw2`}
            />
          </div>
          <div className='tc'>
            <div
              className='dib mb3 pointer dim'
              onClick={() => handleIconClick('governance')}
            >
              <BannerBadge
                pageId='governance'
                started={pathname.includes('esg-survey/governance')}
                completed={!pathname.includes('esg-survey/governance')}
                missing={false}
              />
            </div>
            <br />
            <NavLink
              to={`${parsePath(paths.supplierGovernance, { supplierId })}${
                redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''
              }`}
              label={
                <FormattedMessage
                  id='EsgNav.Governance'
                  defaultMessage='Governance'
                />
              }
              style={{ marginRight: 0 }}
              activeClassName={`${colors.primaryText} ${colors.primaryBorder} bw2`}
            />
          </div>
          <div className='tc'>
            <div
              className='dib mb3 pointer dim'
              onClick={() => handleIconClick('privacy')}
            >
              <BannerBadge
                pageId='privacy'
                started={pathname.includes('esg-survey/privacy')}
                completed={!pathname.includes('esg-survey/privacy')}
                missing={false}
              />
            </div>
            <br />
            <NavLink
              to={`${parsePath(paths.supplierPrivacy, { supplierId })}${
                redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''
              }`}
              label={
                <FormattedMessage
                  id='EsgNav.Privacy'
                  defaultMessage='Privacy'
                />
              }
              style={{ marginRight: 0 }}
              activeClassName={`${colors.primaryText} ${colors.primaryBorder} bw2`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EsgNav
