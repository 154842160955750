import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import buyer from 'shared/utils/api/buyer'

export function* makeSupplierABuyerSaga(action) {
  const { orgUnitId, buyerType } = action.payload

  return yield call(buyer.makeSupplierABuyer, {
    orgUnitId,
    buyerType
  })
}

export default createApiRequestSaga(makeSupplierABuyerSaga, {
  notifyOnSuccessMessage: 'Changes have been saved.'
})
