import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import Immutable from 'immutable'
import vets from 'shared/utils/api/vets'

export function* removeContactSendSaga(action) {
  const { vetBuyerSupplierId, userId } = Immutable.Iterable.isIterable(
    action.payload
  )
    ? action.payload.toJS()
    : action.payload

  const sendToContacts = yield call(vets.removeSupplierContact, {
    vetBuyerSupplierId,
    userId
  })

  return yield {
    vetBuyerSupplierId,
    sendToContacts
  }
}

export default createApiRequestSaga(removeContactSendSaga)
