import moment from 'moment'

const dateFormat = (
  dateValue?: string | Date | moment.Moment,
  format: string = 'MM/DD/YYYY',
  noRelativeDate?: boolean
) => {
  const date =
    typeof dateValue === 'string' ? dateValue.replace(/ /g, 'T') : dateValue

  const formattedDate = moment(date, [
    moment.HTML5_FMT.DATETIME_LOCAL_MS,
    moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
    moment.ISO_8601
  ]).format(format)
  const relativeDate = moment(date, [
    moment.HTML5_FMT.DATETIME_LOCAL_MS,
    moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
    moment.ISO_8601
  ]).calendar()
  return noRelativeDate ? formattedDate : relativeDate || formattedDate
}

export default dateFormat
