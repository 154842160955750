import React from 'react'
import Page from 'shared/components/Page'
import Button from 'shared/components/Button'
// import FileInput from 'shared/components/FileInput'
import Card from 'shared/components/Card'
import Warning from 'shared/components/Warning'
import { Field } from 'redux-form/immutable'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import formValidators from 'shared/utils/formValidators'
import Main from 'shared/components/Layout/Main'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { LOAD_NEW_JOB } from '../../actionTypes'
import { getFormSubmitErrors } from 'shared/reducers'
import RootState from 'shared/models/RootState'
import { formValueSelector } from 'redux-form/immutable'

type Props = {
  handleSubmit: () => void
  submitting: boolean
  disabledSubmit: boolean
  errorMessage: string
}

const JobLoader = (props: Props) => {
  const { errorMessage, handleSubmit, submitting, disabledSubmit } = props
  return (
    <Page title='Loader'>
      <Main>
        <Card>
          <form onSubmit={handleSubmit}>
            {/* <Label noPadding>Attach a file or enter JSON body</Label>
            <Field
              component={FileInput}
              accept='.json'
              className='mt3'
              name='file'
              limit={32}
            />
            <Warning
              message='Make sure the file meets the required format'
            /> */}

            <Label noPadding>Request Body (must be JSON)</Label>
            <Field
              component={TextArea}
              name='body'
              validate={[formValidators.isJSON]}
              height='36em'
            />

            {errorMessage && <Warning message={errorMessage} />}

            <Button
              label='Submit'
              type='submit'
              autoSize
              className='mt3'
              disabled={submitting || disabledSubmit}
            />
          </form>
        </Card>
      </Main>
    </Page>
  )
}

const formName = 'jobs/loader/newJob'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)
const selector = formValueSelector(formName)

export default connectReduxForm(
  (state: RootState) => ({
    errorMessage: formSubmitErrorsSelector(state),
    disabledSubmit: !selector(state, 'body')
  }),
  {
    onSubmit: createSubmitFormAction(formName, LOAD_NEW_JOB)
  },
  {
    form: formName
  },
  JobLoader
)
