import React, { Component, Fragment, ReactNode } from 'react'
import { List, RecordOf } from 'immutable'
import UserLookupItem from 'shared/components/UserLookupItem'
import { FormattedMessage } from 'react-intl'
import User from 'shared/models/User'
import Select from 'react-select'
import { components } from 'react-select'
import Avatar from 'shared/components/Avatar'
import EmailIcon from '@material-ui/icons/EmailRounded'

type Props = {
  users: List<RecordOf<User>>
  placeholder: string | ReactNode
  onClickUser: (userId: string | Array<string>) => void
  isMulti?: boolean
  noOptionsMessage?: string
  domains?: List<string>
}

type State = {
  search: string
}

class UserLookup extends Component<Props, State> {
  static defaultProps = {
    placeholder: 'Type a name...'
  }

  state: State = {
    search: ''
  }

  handleSearchChange = (inputValue: string) => {
    this.setState({
      search: inputValue
    })
  }

  handleOnChange = (data, metaAction) => {
    const { isMulti, onClickUser } = this.props
    if (isMulti) {
      onClickUser(data ? data.map(user => user.id || user.value) : [])
    } else {
      onClickUser(data.id || data.value)
    }

    this.setState({
      search: ''
    })
  }

  sortUsers = () => {
    const { search } = this.state
    const { users, domains } = this.props

    const emailUsers =
      search.includes('@') && domains
        ? domains.map(domain => {
            const username = search.split('@')[0]
            return {
              __isNew__: true,
              label: `${username}@${domain}`,
              value: `${username}@${domain}`
            }
          })
        : List([])

    return !users
      ? []
      : !search
      ? users.toJS()
      : users
          .sort((userA, userB) => {
            let fullNameA = `${userA.get('firstName') &&
              userA.get('firstName').trim()} ${userA.get('lastName') &&
              userA.get('lastName').trim()}`.toLowerCase()
            let fullNameB = `${userB.get('firstName') &&
              userB.get('firstName').trim()} ${userB.get('lastName') &&
              userB.get('lastName').trim()}`.toLowerCase()
            fullNameA = fullNameA.startsWith(search)
              ? `0${fullNameA}`
              : fullNameA
            fullNameB = fullNameB.startsWith(search)
              ? `0${fullNameB}`
              : fullNameB

            if (fullNameA > fullNameB) {
              return 1
            }
            if (fullNameA < fullNameB) {
              return -1
            }
            return 0
          })
          .concat(emailUsers)
          .toJS()
  }

  filterOption = props => {
    if (!this.state.search || props.data.__isNew__) {
      return true
    }

    const user = props.data
    const userFullName = `${user.firstName &&
      user.firstName.trim()} ${user.lastName &&
      user.lastName.trim()}`.toLowerCase()
    const email = (user.email || '').toLowerCase()

    return (
      userFullName.includes(this.state.search.toLowerCase()) ||
      email.includes(this.state.search.toLowerCase())
    )
  }

  formatOptionLabel = (user, data) => {
    if (user.__isNew__) {
      return (
        <span className='f7 fw4'>
          <FormattedMessage
            id='UserLookup.EmailTo'
            defaultMessage='Email to "{email}"'
            values={{ email: user.value }}
          />
        </span>
      )
    } else {
      return (
        user && (
          <UserLookupItem
            key={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            orgUnitName={user.orgUnitName}
            profilePictureUrl={user.profilePictureUrl}
            status={user.status}
          />
        )
      )
    }
  }

  render() {
    const { placeholder, isMulti, noOptionsMessage } = this.props
    const { search } = this.state
    return (
      <Fragment>
        <Select
          isMulti={isMulti}
          noOptionsMessage={inputValue => noOptionsMessage || null}
          components={{ MultiValueLabel }}
          InputValue={isMulti ? undefined : search}
          onInputChange={this.handleSearchChange}
          onChange={this.handleOnChange}
          placeholder={
            placeholder ||
            ((
              <FormattedMessage
                id='UserLookup.TypeInName'
                defaultMessage='Type in name'
              />
            ) as any)
          }
          options={this.sortUsers() as any}
          formatOptionLabel={this.formatOptionLabel}
          filterOption={this.filterOption}
          getOptionValue={opt => opt.id || opt.value}
          controlShouldRenderValue={!!isMulti}
          styles={{
            control: base => ({
              ...base,
              border: isMulti ? base['border'] : 'none'
            }),
            input: base => ({
              ...base,
              fontSize: '.875rem'
            }),
            placeholder: base => ({
              ...base,
              fontSize: '.875rem'
            }),
            option: (base, { isDisabled, isFocused }) => ({
              ...base,
              backgroundColor: isDisabled
                ? 'inherit'
                : isFocused
                ? '#E0E0E0'
                : 'inherit',
              ':hover': {
                ...base[':hover'],
                backgroundColor: !isDisabled && '#e0e0e0'
              }
            }),
            noOptionsMessage: base => ({ ...base, fontSize: '.875rem' }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            multiValue: base => ({
              ...base,
              background: '#F8F8Fa'
            }),
            multiValueRemove: base => ({
              ...base,
              ':hover': {
                backgroundColor: '#DCF6F4'
              }
            })
          }}
          menuPortalTarget={document.body}
        />
      </Fragment>
    )
  }
}

const MultiValueLabel = props => {
  return props.data.__isNew__ ? (
    <components.MultiValueLabel {...props}>
      <div className='dib h1-5 flex items-center'>
        <EmailIcon
          style={{
            fontSize: '1.5rem',
            color: '#DCDCDC',
            marginRight: '0.5rem'
          }}
        />
        <span className=''>{props.data.value}</span>
      </div>
    </components.MultiValueLabel>
  ) : (
    <components.MultiValueLabel {...props}>
      <div className='dib'>
        <Avatar
          url={props.data.profilePictureUrl}
          className='w1-5 mr2'
          name={`${props.data.firstName || ''} ${props.data.lastName || ''}`}
        />
        <span className='v-mid'>{`${props.data.firstName || ''} ${props.data
          .lastName || ''}`}</span>
      </div>
    </components.MultiValueLabel>
  )
}

export default UserLookup
