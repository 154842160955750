import buyerPaths from '../../routes/paths'

const basePath = buyerPaths.vets
const vetBasePath = `${basePath}/:vetId`

const paths = {
  basePath,
  newVet: `${basePath}/new-vet`,
  vet: vetBasePath,
  vetSearch: `${vetBasePath}/search`,
  vetDetails: `${vetBasePath}/details`,
  vetInvitation: `${vetBasePath}/invitation`,
  vetQA: `${vetBasePath}/qanda`,
  vetSuppliers: `${vetBasePath}/suppliers`,
  vetInbox: `${vetBasePath}/inbox/:vetBuyerSupplierId?`,
  vetTerms: `${vetBasePath}/terms`,
  vetActivities: `${vetBasePath}/activities`
}

export default paths
