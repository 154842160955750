import React, { Component } from 'react'
import Page from 'shared/components/Page'
import EnterEmailContainer from '../../containers/EnterEmailContainer'
import FindSupplierContainer from '../../containers/FindSupplierContainer'
import OrgSearchResults from '../../containers/OrgSearchResults'
import CreateOrganizationFormContainer from '../../containers/CreateOrganizationFormContainer'
import SearchBarContainer from '../../containers/SearchBarContainer'

type Props = {
  displaySupplierFinder: boolean
  selectedUserEmail: string
}

class CreateCardPage extends Component<Props> {
  render() {
    const { displaySupplierFinder, selectedUserEmail } = this.props

    return (
      <Page title='Create Card'>
        <div className='w-75-ns center'>
          <EnterEmailContainer />
          {displaySupplierFinder && (
            <div>
              <SearchBarContainer hideClientSearch />
              <FindSupplierContainer
                orgSearchResults={<OrgSearchResults />}
                createOrgForm={
                  <CreateOrganizationFormContainer email={selectedUserEmail} />
                }
              />
            </div>
          )}
        </div>
      </Page>
    )
  }
}

export default CreateCardPage
