import { call, put } from 'redux-saga/effects'
import {
  startSubmit,
  stopSubmit,
  SubmissionError,
  clearSubmitErrors,
  reset
} from 'redux-form/immutable'
import ApiError from '../api/ApiError'

const createSubmitSaga = saga => {
  return function*(action) {
    const formName = action.meta && action.meta.formName
    try {
      if (formName) {
        yield put(clearSubmitErrors(formName))
        yield put(startSubmit(formName))
      }
      yield call(saga, action)
      if (formName) {
        yield put(stopSubmit(formName))
        yield put(reset(formName))
      }
    } catch (err) {
      let errorMessage = 'Please try again later.'
      if (!(err instanceof ApiError)) {
        console.error(err)
      } else {
        errorMessage = err.message || errorMessage
      }
      if (formName) {
        yield put(stopSubmit(formName, new SubmissionError(errorMessage)))
      }
    }
  }
}

export default createSubmitSaga
