import RootState from 'shared/models/RootState'
import { createSelector } from 'reselect'
import { List, RecordOf } from 'immutable'
import {
  Contact,
  BulkSupplier,
  BulkSupplierRecord
} from '../bulkActionsReducer/bulkActionsReducer'

export const isLoading = (state: RootState) =>
  state.getIn(['buyer', 'bulkActions', 'loading'])

const sortContacts = (contact1: Contact, contact2: Contact) => {
  const sustainabilityContact1 = contact1.sustainabilityContact ? 1 : 0
  const sustainabilityContact2 = contact2.sustainabilityContact ? 1 : 0
  const sustainabilityContact = sustainabilityContact2 - sustainabilityContact1

  const myCompanyContact1 = contact1.myCompany ? 1 : 0
  const myCompanyContact2 = contact2.myCompany ? 1 : 0
  const myCompany = myCompanyContact2 - myCompanyContact1

  const activeUser1 = contact1.status === 'Normal' ? 1 : 0
  const activeUser2 = contact2.status === 'Normal' ? 1 : 0
  const activeUser = activeUser2 - activeUser1

  return sustainabilityContact || myCompany || activeUser
}

export const getSuppliers = createSelector<
  RootState,
  List<RecordOf<BulkSupplier>>,
  List<any>
>(
  (state: RootState) => state.getIn(['buyer', 'bulkActions', 'suppliers']),
  suppliers => {
    return suppliers.map(s => {
      const { contacts, supplier, ytdSpend } = s
      const newContacts = contacts.sort(sortContacts)

      return new BulkSupplierRecord({
        contacts: newContacts,
        supplier,
        ytdSpend
      })
    })
  }
)
