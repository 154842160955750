export const backgroundImageParallax = (
  container: HTMLDivElement,
  multiplier: number = 0.5
) => {
  window.addEventListener('scroll', () => {
    const from_top =
      document.documentElement.scrollTop || document.body.scrollTop
    const bg_css = `center ${multiplier * from_top}px`
    container.style.backgroundPosition = bg_css
  })
}

export const imageParallax = (
  container: HTMLDivElement,
  multiplier: number = 0.5
) => {
  window.addEventListener('scroll', () => {
    const image = container.querySelector('img')
    if (image) {
      const from_top =
        document.documentElement.scrollTop || document.body.scrollTop
      const css_top = `${multiplier * from_top}px`
      container.style.overflow = 'hidden'
      image.style.position = 'relative'
      image.style.top = css_top
    }
  })
}
