import { PasswordRules } from '../../admin/components/PasswordRulesSetting/PasswordRulesSetting'

export const lowercase = 'abcdefghijklmnopqrstuvwxyz'
export const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
export const numbers = '0123456789'
export const symbols = '^!@#$%&*)(+=._-'

const getRandomValue = (value: string) => {
  // return a random char in value string
  return value[Math.floor(Math.random() * value.length)]
}

const getPasswordPosition = (arr: Array<number>) => {
  // return a random value in an array
  const randomPosition = Math.floor(Math.random() * arr.length)
  // remove 1 element from arr
  const pos = arr.splice(randomPosition, 1)
  return pos[0]
}

const generatePassword = (rules?: PasswordRules) => {
  if (!rules) {
    return ''
  }
  // initialize password with random numbers
  const password = Array.from({ length: rules.max }, () =>
    getRandomValue(numbers)
  )
  // available positions to fulfill the rules [0, 1, 2,... rules.max - 1]
  const availablePositions = Array.from(Array(rules.max).keys())

  // apply rest of the rules to the password
  for (let i = 0; i < rules.alphaLowerCount; i++) {
    const position = getPasswordPosition(availablePositions)
    password[position] = getRandomValue(lowercase)
  }
  for (let i = 0; i < rules.alphaUpperCount; i++) {
    const position = getPasswordPosition(availablePositions)
    password[position] = getRandomValue(uppercase)
  }
  for (let i = 0; i < rules.specialCount; i++) {
    const position = getPasswordPosition(availablePositions)
    password[position] = getRandomValue(symbols)
  }

  return password?.join('')
}

export default generatePassword
