import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call } from 'redux-saga/effects'
import message from '../../utils/api/message'
import parseMessage from '../../utils/data/parseMessage'

export function* updateMessageSaga(action) {
  const {
    ancestorId,
    messageId,
    comment,
    isPrivate,
    messageKind
  } = action.payload.toJS()

  const newMessage = yield call(message.updateMessage, messageId, {
    body: comment,
    isPrivate
  })

  return yield {
    messageKind,
    ancestorId,
    messageId,
    newMessage: parseMessage(newMessage)
  }
}

export default createApiRequestSaga(updateMessageSaga)
