import React, { Component, Fragment } from 'react'
import OrgLogo from 'shared/components/OrgLogo'
import dateFormat from 'shared/utils/dateFormat'
import VotesContainer from '../../containers/VotesContainer'
import OutcomeContainer from '../../containers/OutcomeContainer'
import Text from 'shared/components/Text'
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  IntlShape
} from 'react-intl'
import camelCase from 'lodash.camelcase'
import { translatedFilterKeys } from 'shared/utils/data/defineMessages'
import PitchDeck from 'shared/components/PitchDeck'
import PricingTable from 'shared/components/PricingTable'
import UserStack from 'shared/components/UserStack'
import paths from 'buyer/routes/paths'
import parsePath from 'shared/utils/parsePath'
import Popover from '@material-ui/core/Popover'
import { List, RecordOf, Map } from 'immutable'
import fileToUrlImmutable from 'shared/utils/data/fileToUrlImmutable'
import Location from 'shared/models/Location'
import parseLocationToString from 'shared/utils/data/parseLocationToString'
import Select from '@material-ui/core/Select'
import MenuItem from 'shared/components/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import UserListItem from 'shared/components/UserListItem'
import Label from 'shared/components/Label'
import Scrollable from 'shared/components/Scrollable'
import { SendToContact as Recipient } from '../../containers/VetSupplierListItem/VetSupplierListItem'
import { ContactType } from '../../containers/ResponseMessageContainer/ResponseMessageContainer'
import UploadedFile from 'shared/models/UploadedFile'

const messages = defineMessages({
  vetInvite: {
    id: 'ResponseMessage.vetInvite',
    defaultMessage: 'Invitation sent'
  }
})

const CustomSelect = withStyles({
  root: {
    paddingLeft: 7,
    border: '1px solid lightgray',
    borderRadius: 2
  },
  select: {
    whiteSpace: 'normal'
  }
})(Select)

export const PricingItemRow = ({ name, description, qty, price, isHeader }) => (
  <div className='dt w-100 pv2 bb b--black-10'>
    <div className={`dtc`}>
      <Text className={`${isHeader ? 'fw6' : 'fw4'}`}>{name}</Text>
      {description && <Text secondary>{description}</Text>}
    </div>
    <div className={`dtc w-20`}>
      <Text className={`${isHeader ? 'fw6' : 'fw4'}`}>{qty}</Text>
    </div>
    <div className={`dtc w-20 tr`}>
      <Text className={`${isHeader ? 'fw6' : 'fw4'}`}>{price}</Text>
    </div>
  </div>
)

type User = {
  id: string
  firstName: string
  lastName: string
  title: string
  email: string
  mobilePhone: string
  officePhone: string
  profilePictureUrl: string
}

type Props = {
  vetBuyerSupplierId: string
  supplierName: string
  logoUrl: string
  locations: List<RecordOf<Location>>
  name: string
  user?: User
  date: Date
  description: string
  comment: string
  status: string
  submitted: boolean
  readonly: boolean
  responseDate: string
  emails: List<any>
  documents: List<RecordOf<UploadedFile>>
  proposalPricing: any
  pricing: Map<
    string,
    Map<
      string,
      RecordOf<{
        currency: string
        lineItems: List<
          RecordOf<{
            name: string
            description: string
            quantity: number
            UOM: string
            id: string
          }>
        >
      }>
    >
  >
  sendToContacts: Map<string, RecordOf<ContactType>>
  primaryContact?: RecordOf<ContactType>
  secondaryContact?: RecordOf<ContactType>
  onSupplierNameClick: () => void
  updateVetBuyerSupplier: ({
    vetBuyerSupplierId: string,
    primaryLocation: number
  }) => void
  primaryLocation?: number
  intl: IntlShape
}

export class ResponseMessage extends Component<Props> {
  state = {
    anchorEl: undefined,
    showPopover: false
  }

  handleClick = e => {
    e.preventDefault()
    this.setState({
      anchorEl: e.currentTarget,
      showPopover: true
    })
  }

  handleRequestClose = () => {
    this.setState({
      showPopover: false
    })
  }

  mapEmailType = type => {
    const { intl } = this.props
    return messages[type] ? intl.formatMessage(messages[type]) : type
  }

  renderResponse() {
    const {
      user,
      name,
      description,
      comment,
      documents,
      proposalPricing,
      pricing,
      responseDate,
      submitted
    } = this.props

    return submitted ? (
      <div>
        <div className='dt w-100 mt4'>
          {name && <Text className='fw6 mb3'>{name}</Text>}

          {responseDate && new Date(responseDate) > new Date() && (
            <div className='ba bw1 b--dark-red mb3 dark-red tc'>
              <p className='mv1 f4'>
                <FormattedMessage
                  id='ResponseMessage.Draft'
                  defaultMessage='Draft'
                />
              </p>
              <p className='mv1 f7'>
                <FormattedMessage
                  id='ResponseMessage.supplierCanChangeResponse'
                  defaultMessage='The supplier can change their submission up until the response deadline.'
                />
              </p>
            </div>
          )}

          {description && <Text className='mb3'>{description}</Text>}
          {comment && <Text className='mb3'>{comment}</Text>}
          {documents &&
            documents.size > 0 &&
            documents.map((document, index) => (
              <div key={document.get('fileName')} className='relative mv3'>
                <PitchDeck
                  url={fileToUrlImmutable(document)}
                  label={document.get('description')}
                  postfix={`#${index + 1}`}
                />
              </div>
            ))}
          {proposalPricing.get('lineItems') &&
            proposalPricing.get('lineItems').size > 0 && (
              <PricingTable
                pricing={pricing}
                proposalPricing={proposalPricing}
              />
            )}
          {user && (
            <div>
              <Label>
                <FormattedMessage
                  id='ResponseMessage.SubmittedBy'
                  defaultMessage='Submitted By'
                />
              </Label>
              <UserListItem
                mini
                profileUrl={parsePath(paths.userprofile, { userId: user.id })}
                className='mt2 flex justify-between items-center'
                {...user}
              />
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className='tc ma3'>
        <h6 className='f7 mid-gray fw6 dib pb0 ma0'>
          <FormattedMessage
            id='ResponseMessage.AwaitingResponse'
            defaultMessage='Awaiting response'
          />
        </h6>
      </div>
    )
  }

  render() {
    const {
      vetBuyerSupplierId,
      supplierName,
      locations,
      logoUrl,
      status,
      date,
      onSupplierNameClick,
      readonly,
      responseDate,
      emails,
      intl,
      updateVetBuyerSupplier,
      primaryLocation,
      sendToContacts,
      primaryContact,
      secondaryContact
    } = this.props

    const { showPopover, anchorEl } = this.state
    return (
      <div className='w-100 dt pb3'>
        <div className='w-100 pa4'>
          <div className='dtc v-top pr3'>
            <div
              className='pa1 dtc v-mid ba b--black-10 br2'
              style={{ height: '50px', width: '50px', minWidth: '50px' }}
            >
              <OrgLogo url={logoUrl} name={supplierName} />
            </div>
          </div>
          <div className='dtc v-top pr3 pl0 w-100'>
            <div className='flex-ns items-top'>
              <div className='dib w-100 w-50-ns'>
                <h5
                  className='f5 ma0 dib fw6 pointer mr1 underline-hover hover-teal v-mid'
                  onClick={onSupplierNameClick}
                >
                  {supplierName}
                </h5>
                <h5 className='f8 fw4 gray ma0 mt1'>
                  {intl.formatMessage(
                    translatedFilterKeys.collaborationStatus[camelCase(status)]
                  )}{' '}
                  {date && `(${dateFormat(date, 'MM/DD/YYYY, h:mmA')})`}
                </h5>
                {primaryContact && (
                  <Recipient
                    userId={primaryContact.get('user')}
                    profileUrl={parsePath(paths.userprofile, {
                      userId: primaryContact.get('user')
                    })}
                    primary
                  />
                )}
                {secondaryContact && (
                  <Recipient
                    userId={secondaryContact.get('user')}
                    profileUrl={parsePath(paths.userprofile, {
                      userId: secondaryContact.get('user')
                    })}
                    secondary
                  />
                )}
                {emails && (
                  <Fragment>
                    <div
                      className='v-mid pointer mt1'
                      onClick={this.handleClick}
                    >
                      <UserStack users={emails.keySeq() as any} mini />
                    </div>
                    <Popover
                      open={showPopover}
                      onClose={this.handleRequestClose}
                      anchorEl={anchorEl}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    >
                      <div className='dib pa3 lh-copy f7 fw4 mid-gray'>
                        <Scrollable>
                          {emails
                            .map((email, key) => {
                              const response = email && email.last()
                              return (
                                <Recipient
                                  key={key}
                                  userId={String(key)}
                                  profileUrl={parsePath(paths.userprofile, {
                                    userId: key
                                  })}
                                  primary={sendToContacts.getIn([
                                    key,
                                    'primary'
                                  ])}
                                  secondary={sendToContacts.getIn([
                                    key,
                                    'secondary'
                                  ])}
                                  secondaryText={
                                    response &&
                                    `${this.mapEmailType(
                                      response.get('type')
                                    )}: ${response.get('status')}`
                                  }
                                />
                              )
                            })
                            .toList()}
                        </Scrollable>
                      </div>
                    </Popover>
                  </Fragment>
                )}
              </div>
              <div className='dib w-100 w-50-ns'>
                {locations &&
                  locations.size > 0 &&
                  (readonly ? (
                    <div className='mid-gray'>
                      {locations.getIn([
                        primaryLocation || 0,
                        'description'
                      ]) && (
                        <div className='f7 fw8'>
                          {locations.getIn([0, 'description'])}
                        </div>
                      )}
                      <div className='f7 fw4'>
                        {parseLocationToString(
                          locations.getIn([primaryLocation || 0])
                        )}
                      </div>
                    </div>
                  ) : (
                    <CustomSelect
                      disableUnderline={true}
                      autoWidth
                      value={primaryLocation || 0}
                      renderValue={value => (
                        <div className='mid-gray'>
                          {locations.getIn([value, 'description']) && (
                            <div className='f7 fw8'>
                              {locations.getIn([value, 'description'])}
                            </div>
                          )}
                          <div className='f7 fw4'>
                            {parseLocationToString(locations.getIn([value]))}
                          </div>
                        </div>
                      )}
                      onChange={event =>
                        updateVetBuyerSupplier({
                          vetBuyerSupplierId,
                          primaryLocation: event.target.value
                        })
                      }
                    >
                      {locations.map((location, index) => (
                        <MenuItem key={`location-${index}`} value={index}>
                          <div>
                            {location.get('description') && (
                              <div className='f7 fw8'>
                                {location.get('description')}
                              </div>
                            )}
                            <div>{parseLocationToString(location)}</div>
                          </div>
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  ))}
              </div>
            </div>
          </div>
          {!(status === 'Suggested' || status === 'Approved') &&
            this.renderResponse()}
        </div>
        <div className='dt w-100 ph4'>
          <VotesContainer vetBuyerSupplierId={vetBuyerSupplierId} />
          {!readonly && responseDate && new Date(responseDate) < new Date() && (
            <OutcomeContainer vetBuyerSupplierId={vetBuyerSupplierId} />
          )}
        </div>
      </div>
    )
  }
}

export default injectIntl(ResponseMessage)
