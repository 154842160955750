import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import AppBar from '@material-ui/core/AppBar'
import { useHistory } from 'react-router'

type Props = {
  selectedTab: string
  children: React.ReactNode
}
export const TabNav = (props: Props) => {
  const { selectedTab } = props

  const history = useHistory()

  const handleChange = (e, path) => {
    history.push(path)
  }
  return (
    <AppBar position='static' color='default'>
      <div className='mw8 center'>
        <Tabs
          onChange={handleChange}
          value={selectedTab}
          variant='scrollable'
          indicatorColor='primary'
          textColor='primary'
        >
          {props.children}
        </Tabs>
      </div>
    </AppBar>
  )
}
export default TabNav
