import React, { memo, useCallback, useState, useMemo } from 'react'
import Page from 'shared/components/Page'
import Main from 'shared/components/Layout/Main'
import PageSection from 'shared/components/PageSection'
import { FormattedMessage } from 'react-intl'
import InputWithLabel from 'shared/components/InputWithLabel'
import DynamicInput, { InputTypeProps } from 'shared/components/DynamicInput'
import { useDebouncedCallback } from 'use-debounce'
import { updateRelationship } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS, Map, RecordOf } from 'immutable'
import profileSelectors from '../../selectors/profileSelectors'
import RootState from 'shared/models/RootState'
import mapValues from 'lodash.mapvalues'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import User from 'shared/models/User'
import dateFormat from 'shared/utils/dateFormat'
import Avatar from 'shared/components/Avatar'
import Tooltip from 'shared/components/Tooltip'
import parseNameFromUser from 'shared/utils/parseNameFromUser'
import Question from 'shared/models/Question'
import StatusHistory from 'shared/models/StatusHistory'

type Props = {
  currentUserId: string
  customPage: {
    title: string
    pageSections: Array<{
      title: string
      questions: Array<{
        questionId: string
        required: boolean
      }>
    }>
  }
  questions?: { [key: string]: Question | undefined }
  responses: {
    [key: string]:
      | {
          response: any
          modified: StatusHistory
          question: string
        }
      | undefined
  }
  users: Map<string, RecordOf<User>>
}

const CustomPage = memo((props: Props) => {
  const { customPage, currentUserId, responses = {}, questions = {} } = props

  const initialFormValues = useMemo(
    () =>
      customPage.pageSections?.reduce(
        (result, customFieldSection) =>
          customFieldSection.questions.reduce((sectionResults, question) => {
            const customField = questions[question.questionId]
            if (!customField) {
              return result
            }
            return {
              ...result,
              ...sectionResults,
              [customField.id]:
                responses[customField.id]?.response ||
                customField.defaultValue ||
                getInitialCustomFieldValue(customField.type)
            }
          }, {}),
        {}
      ),
    [customPage, questions, responses]
  )

  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState<{
    [key: string]: InputTypeProps['value']
  }>(initialFormValues)

  const debouncedSaveResponse = useDebouncedCallback(response => {
    dispatch(
      updateRelationship(
        fromJS({
          customQuestionsResponses: mapValues(response, value => ({
            response: value,
            modified: {
              user: currentUserId,
              date: new Date().toISOString()
            }
          }))
        })
      )
    )
  }, 1000)

  const handleInputChange = useCallback(
    newChange => {
      setFormValues(oldFormValues => ({
        ...oldFormValues,
        ...newChange
      }))
      debouncedSaveResponse.callback(newChange)
    },
    [debouncedSaveResponse]
  )

  return (
    <Page title='Custom Page'>
      <Main>
        {props.customPage.pageSections?.map(questionSection => (
          <PageSection
            key={questionSection.title}
            title={questionSection.title}
          >
            {questionSection.questions.map(question => {
              const customField = questions[question.questionId]
              if (!customField) {
                return null
              }
              const userLastModifiedBy = props.users.get(
                responses[customField.id]?.modified.user || ''
              )
              const displayName = parseNameFromUser(
                userLastModifiedBy?.get('email'),
                userLastModifiedBy?.get('firstName'),
                userLastModifiedBy?.get('lastName')
              )
              return (
                <InputWithLabel
                  key={customField.name}
                  editMode
                  label={customField.label}
                  required={question.required}
                  inputComponent={
                    <div className='flex items-start'>
                      <div className='flex-auto pr2'>
                        <DynamicInput
                          name={customField.id}
                          type={customField.type as any}
                          value={formValues[customField.id] as any}
                          onChange={handleInputChange}
                          options={customField?.responseOptions}
                          placeholder={customField.placeholder}
                          required={question.required}
                        />
                      </div>
                      <div className='w2'>
                        {userLastModifiedBy && (
                          <Tooltip
                            title={
                              <FormattedMessage
                                id='CustomPage.lastModified'
                                defaultMessage='Last Modified by {name} on {date}.'
                                values={{
                                  name: displayName,
                                  date: dateFormat(
                                    responses[customField.id]?.modified.date,
                                    'MM/DD/YYYY',
                                    true
                                  )
                                }}
                              />
                            }
                          >
                            <div>
                              <Avatar
                                url={userLastModifiedBy.get(
                                  'profilePictureUrl'
                                )}
                                name={displayName}
                              />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  }
                />
              )
            })}
          </PageSection>
        ))}
      </Main>
    </Page>
  )
})

const CustompageContainer = () => {
  const currentUserId = useSelector(sessionSelectors.getUserId)
  const questions = useSelector((state: RootState) => state.questions.byId)
  const users = useSelector(usersSelectors.getUsers)
  const supplierProfileCustomPage = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'supplierProfileCustomPage'])
  )
  const customPage = useMemo(() => supplierProfileCustomPage.toJS(), [
    supplierProfileCustomPage
  ])

  const relationship = useSelector(profileSelectors.getRelationship)
  const responses = useMemo(
    () => relationship?.get('customQuestionsResponses')?.toJS(),
    [relationship]
  )

  return (
    <CustomPage
      currentUserId={currentUserId}
      customPage={customPage}
      responses={responses}
      users={users}
      questions={questions}
    />
  )
}

const getInitialCustomFieldValue = (inputType: InputTypeProps['type']) => {
  switch (inputType) {
    case 'boolean':
      return false

    case 'select':
    case 'multipleSelect':
      return []

    default:
      return ''
  }
}

export default CustompageContainer
