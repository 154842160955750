import React, { Component } from 'react'
import { connect } from 'react-redux'
import Page from 'shared/components/Page'
import Grid from '@material-ui/core/Grid'
import TargetClient from './components/TargetClient'
import { loadData } from 'shared/actions'
import dataSelectors from 'shared/selectors/dataSelectors'
import Loading from 'shared/components/Loading'
import Divider from 'shared/components/Divider'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import { FormattedMessage } from 'react-intl'
import companySelectors from '../shared/selectors/companySelectors'
import RootState from 'shared/models/RootState'
import { PublicClient, PrivateClient } from 'shared/models/SupplierState'
import { List, RecordOf } from 'immutable'
import CommunityHeader from 'shared/components/CommunityHeader'
import TealbookSquareLogo from 'shared/assets/images/tealbook-logo.png'
import SubTitleText from 'shared/components/SubTitleText'

const targetClientsEntity = 'targetClients'

type ApiResponse = {
  communities: Array<{
    users: Array<{
      id: string
    }>
    contacts: Array<{
      user: string
    }>
  }>
}
export const resultParser = ({ publicTargets, privateTargets }) => ({
  publicTargets: publicTargets
    .map(({ communities, ...rest }: ApiResponse) => {
      let buyerContacts = {}
      if (communities) {
        Object.values(communities).forEach(community => {
          if (community.contacts) {
            community.contacts.forEach(contact => {
              const user = community.users && community.users[contact.user]
              if (user) {
                buyerContacts[user.id] = {
                  ...contact,
                  ...user
                }
              }
            })
          }
        })
      }

      return {
        ...rest,
        communities,
        contacts: Object.values(buyerContacts)
      }
    })
    .sort((target1, target2) =>
      target1.name.toLowerCase() < target2.name.toLowerCase() ? -1 : 1
    ),
  privateTargets: privateTargets.sort((target1, target2) =>
    target1.categorization < target2.categorization ? -1 : 1
  )
})

type Props = {
  supplierId: string
  isLoadingSupplier: boolean
  isLoadingTargets: boolean
  isPremium: boolean
  publicClients: List<RecordOf<PublicClient>>
  privateClients: List<RecordOf<PrivateClient>>
  loadData: typeof loadData
  language: string
}

export class Target extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { supplierId, loadData } = this.props

    if (prevProps.supplierId !== supplierId) {
      loadData({
        entity: 'supplierRelationships'
      })
    }
  }

  render() {
    const {
      isLoadingSupplier,
      isLoadingTargets,
      publicClients,
      privateClients,
      isPremium,
      language
    } = this.props

    if (isLoadingSupplier) {
      return <Loading />
    }

    return (
      <>
        <CommunityHeader
          name='TealBook'
          description='Every buyer and supplier in TealBook is automatically made a member of this community.'
          logoUrl={TealbookSquareLogo}
          supplierIsMember
        />

        <Page title='Targets'>
          {isLoadingTargets && <Loading />}

          {!isLoadingTargets && (
            <>
              <SubTitleText>
                <FormattedMessage
                  id='Target.Pitch'
                  defaultMessage='These are the companies using TealBook to learn about their existing and potential suppliers. They are accessing your company profile to find you and qualify your company for business opportunities. That is why we have been encouraging you to keep it up-to-date. From here you can learn more about each of them and the TealBook communities they are in.'
                />
              </SubTitleText>
              <Grid container spacing={2}>
                {publicClients &&
                  publicClients.map(client => (
                    <Grid item md={4} sm={6} xs={12} key={client.get('id')}>
                      <TargetClient
                        clientId={client.get('id')}
                        name={client.get('name')}
                        description={
                          !client.get('buyerDescription') ||
                          typeof client.get('buyerDescription') === 'string'
                            ? client.get('buyerDescription')
                            : client.getIn(['buyerDescription', language]) ||
                              client.getIn(['buyerDescription', 'en'])
                        }
                        address={client.getIn(['locations', '0'])}
                        communities={client.get('communities')}
                        websiteUrl={
                          client.getIn(['links', 'websiteUrl']) ||
                          (client.get('domains') &&
                            client.get('domains').size > 0 &&
                            client.get('domains').first())
                        }
                        contacts={client.get('contacts')}
                        logoUrl={
                          fileToUrl(client.get('logo')) ||
                          (client.get('domains') &&
                          client.get('domains').size > 0
                            ? `https://logo.clearbit.com/${client
                                .get('domains')
                                .first()}`
                            : '')
                        }
                      />
                    </Grid>
                  ))}
              </Grid>

              <div className='tc mb4 mt3'>
                <div className='mb3 f4'>
                  {isPremium && (
                    <FormattedMessage
                      id='Target.customize'
                      defaultMessage='As a premium supplier, you can customize your pitch for these clients.'
                    />
                  )}
                </div>
              </div>
              <Divider className='mv4' />

              <h3 className='f4 fw4 mb4'>
                <FormattedMessage
                  id='Target.privateClients'
                  defaultMessage='Private Clients'
                />
              </h3>

              <Grid container spacing={2}>
                {privateClients &&
                  privateClients.map((client, i) => (
                    <Grid item md={4} sm={6} xs={12} key={i}>
                      <TargetClient
                        name={client.get('categorization')}
                        communities={client.get('communities')}
                        clientIsPrivate
                      />
                    </Grid>
                  ))}
              </Grid>
            </>
          )}

          <ClearbitCredit />
        </Page>
      </>
    )
  }
}

export default connect(
  (state: RootState) => {
    const targets = dataSelectors.getByEntity(state, targetClientsEntity)
    const language = state.get('i18n').locale
    return {
      language,
      supplierId: companySelectors.getCompanyField(state, 'id'),
      isPremium: companySelectors.isPremium(state),
      isLoadingSupplier: companySelectors.isLoading(state),
      isLoadingTargets: dataSelectors.isLoading(state, targetClientsEntity),
      publicClients: targets.get('publicTargets'),
      privateClients: targets.get('privateTargets')
    }
  },
  {
    loadData
  }
)(Target)
