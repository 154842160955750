import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../Button'

/**
 * The cancel button for a form
 */
type Props = {
  onClick: () => void
}

class CancelButton extends Component<Props> {
  render() {
    const { onClick } = this.props
    return (
      <Button onClick={onClick} variant='text' autoSize size='small'>
        <FormattedMessage id='CancelButton' />
      </Button>
    )
  }
}

export default CancelButton
