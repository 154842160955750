import React, { useState, ChangeEvent } from 'react'
import { connect, useSelector } from 'react-redux'
import { List, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'
import insightsSelectors from '../../store/insightsSelectors'
import Button from 'shared/components/Button'
import {
  getOverviewSpend,
  OverviewSpendList,
  getQualifiedDetailSpend,
  getDisqualifiedDetailSpend,
  getPotentialDetailSpend,
  getOverviewSubCategories,
  getOverviewTotals,
  getCurrentGrouping
} from '../../store/diversityReportSelectors'
import {
  exportOverview,
  createDiversityReportForExport,
  exportQualificationRules,
  exportDiversitySummary,
  exportFilters,
  diversityReportSnapshot
} from '../../containers/DiversityReport/exportUtils'
import DiversityCategory from 'shared/models/DiversityCategory'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import {
  DiversityDetails,
  DiverseQualificationRules
} from 'buyer/Insights/store/diversityReportReducer'
import { FormattedMessage } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import { getDateFromQuarter } from 'shared/utils/sliderUtilsForSpendAndLoader'
import moment from 'moment'
import { saveSnapshot } from '../../store/actions'
import useIsFeatureEnabled from 'shared/utils/featureFlags/useIsFeatureEnabled'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from 'shared/components/Tooltip'

type Props = {
  totalSpend: number
  currentGrouping?: 'subCategory' | 'category' | 'country' | 'spendGroup'
  overviewSpend: OverviewSpendList
  overviewSubCategories: List<DiversityCategory>
  qualifiedData: List<RecordOf<DiversityDetails>>
  disqualifiedData: List<RecordOf<DiversityDetails>>
  potentialData: List<RecordOf<DiversityDetails>>
  selectedCategories: List<DiversityCategory>
  currentBuyerId: string
  currentBuyerName: string
  qualificationRules: DiverseQualificationRules
  startDate: number
  endDate: number
  diversityTotals: {
    disqualified: { amount: number; count: number }
    potential: { amount: number; count: number }
    qualified: { amount: number; count: number }
  }
  isTealbot: boolean
  saveSnapshot: typeof saveSnapshot
  countries: List<string>
  categories: List<string>
  businessUnits: List<string>
  isUsingSpendGroups: boolean
}

const ExportDiversitySnapshot = ({
  currentBuyerId,
  currentBuyerName,
  startDate,
  endDate,
  overviewSpend,
  totalSpend,
  qualifiedData,
  disqualifiedData,
  potentialData,
  selectedCategories,
  overviewSubCategories,
  qualificationRules,
  diversityTotals,
  currentGrouping,
  isTealbot,
  saveSnapshot,
  countries,
  categories,
  businessUnits,
  isUsingSpendGroups
}: Props) => {
  const reportSnapshot = useIsFeatureEnabled('reportSnapshot')

  const isSaving = useSelector(insightsSelectors.isSavingSnapshot)

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [reportName, setReportName] = useState<string>('')
  const startDateToString: string = moment(
    getDateFromQuarter(startDate)
  ).format('MMM/DD/YYYY')
  const endDateToString: string = moment(getDateFromQuarter(endDate)).format(
    'MMM/DD/YYYY'
  )

  const isInvalidGrouping = currentGrouping !== 'subCategory' // only grouping type
  const isInvalidReportingLength = endDate - startDate >= 100 // no more than 4 quarters
  const isInvalidYear =
    new Date().getFullYear() - Math.floor(startDate / 100) > 1 // not more than a year from current year
  const disableSaveReport =
    isInvalidGrouping || isInvalidReportingLength || isInvalidYear

  const groupingSubCategories = detailsTableData => {
    return detailsTableData
      .map(item => item.get('subCategories'))
      .flatten(2)
      .toSet()
      .toList()
  }

  const handleSaveReport = () => {
    const data = diversityReportSnapshot({
      reportName,
      startDate,
      endDate,
      overviewSpend,
      totalSpend,
      qualifiedData,
      disqualifiedData,
      potentialData,
      selectedCategories,
      overviewSubCategories,
      qualificationRules,
      diversityTotals,
      countries,
      categories,
      businessUnits,
      isUsingSpendGroups
    })
    saveSnapshot(data)
    setReportName('')
    setIsDialogOpen(false)
  }

  const handleCancelSaveReport = () => {
    setReportName('')
    setIsDialogOpen(false)
  }

  return (
    <div className='mt2'>
      {isTealbot && (
        <Button
          autoSize
          secondary
          className='mr2'
          onClick={() => {
            const date = `startDate: ${startDateToString} endDate: ${endDateToString}`
            const buyerLabel = `${currentBuyerName} ${currentBuyerId} ${date}`
            exportOverview(
              overviewSpend.spendItems,
              totalSpend,
              currentGrouping,
              `${buyerLabel}-Diversity Report Overview`
            )
            const types = ['qualified', 'disqualified', 'potential']
            types.forEach(
              (type: 'qualified' | 'potential' | 'disqualified') => {
                let data, categories
                if (type === 'qualified') {
                  data = qualifiedData
                } else if (type === 'disqualified') {
                  data = disqualifiedData
                } else {
                  data = potentialData
                }
                if (!currentGrouping || currentGrouping === 'subCategory') {
                  categories =
                    selectedCategories?.size > 0
                      ? selectedCategories
                      : overviewSubCategories
                } else {
                  categories = groupingSubCategories(data)
                }

                createDiversityReportForExport(
                  data,
                  categories,
                  type,
                  true,
                  `${buyerLabel}-Diversity Spend Details - ${type}`
                )
              }
            )
            exportQualificationRules({
              qualRules: qualificationRules,
              startDate: startDateToString,
              endDate: endDateToString,
              label: `${buyerLabel} - Qualification Rules`,
              totalSpend
            })
            exportDiversitySummary({
              diversityTotals,
              label: `${buyerLabel} - Diversity Report Summary`
            })
            exportFilters({
              countries,
              categories,
              businessUnits,
              isUsingSpendGroups,
              label: `${buyerLabel} - Filters`
            })
          }}
          label='Download Snapshot'
        ></Button>
      )}
      {reportSnapshot && (
        <div className='flex justify-end items-center'>
          <Button
            disabled={disableSaveReport || isSaving}
            autoSize
            onClick={() => {
              setIsDialogOpen(true)
            }}
          >
            {isSaving ? (
              <FormattedMessage
                id='ExportDiversitySnapshot.SavingReport'
                defaultMessage='Saving Report...'
              />
            ) : (
              <FormattedMessage
                id='ExportDiversitySnapshot.SaveReport'
                defaultMessage='Save Report'
              />
            )}
          </Button>
          {disableSaveReport && (
            <Tooltip
              title={
                <>
                  {isInvalidGrouping && (
                    <Text>
                      <FormattedMessage
                        id='ExportDiversitySnapshot.InvalidGrouping'
                        defaultMessage={`- Only available if Diversity Report grouping is in Type.`}
                      />
                    </Text>
                  )}
                  {isInvalidReportingLength && (
                    <Text>
                      <FormattedMessage
                        id='ExportDiversitySnapshot.InvalidReportingLength'
                        defaultMessage={`- Only available if reporting period is no longer than a year (4 Quarters).`}
                      />
                    </Text>
                  )}
                  {isInvalidYear && (
                    <Text>
                      <FormattedMessage
                        id='ExportDiversitySnapshot.InvalidYear'
                        defaultMessage={`- Only available if start date is not before last year. `}
                      />
                    </Text>
                  )}
                </>
              }
            >
              <InfoIcon className='ml2' />
            </Tooltip>
          )}
        </div>
      )}
      <Dialog open={isDialogOpen} onClose={handleCancelSaveReport}>
        <DialogTitle>
          <FormattedMessage
            id='ExportDiversitySnapshot.SaveReport'
            defaultMessage='Save Report'
          />
        </DialogTitle>
        <DialogContent>
          <Text>
            <FormattedMessage
              id='ExportDiversitySnapshot.DialogBody'
              defaultMessage='The following report will be saved with your current filters and can be accessed under Saved Reports'
            />
          </Text>
          <Label htmlFor='reportName'>
            <FormattedMessage
              id='ExportDiversitySnapshot.ReportName'
              defaultMessage='Report Name'
            />
          </Label>
          <Input
            name='reportName'
            value={reportName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setReportName(e.currentTarget.value)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button autoSize onClick={handleSaveReport} disabled={!reportName}>
            <FormattedMessage id='Save' defaultMessage='Save' />
          </Button>
          <Button autoSize secondary onClick={handleCancelSaveReport}>
            <FormattedMessage id='Cancel' defaultMessage='Cancel' />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default connect(
  (state: RootState) => {
    const [qualifiedData] = getQualifiedDetailSpend(state)
    const [disqualifiedData] = getDisqualifiedDetailSpend(state)
    const [potentialData] = getPotentialDetailSpend(state)
    const overviewSubCategories = getOverviewSubCategories(state)
    return {
      totalSpend: insightsSelectors.getTotalSpend(state),
      overviewSpend: getOverviewSpend(state),
      selectedCategories: state.get('buyer').get('diversityReport')
        .selectedCategories,
      overviewSubCategories,
      qualifiedData,
      disqualifiedData,
      potentialData,
      currentBuyerId: sessionSelectors.getOrgUnitId(state),
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      currentBuyerName: state.getIn(['buyer', 'settings', 'name']),
      qualificationRules: state.getIn([
        'buyer',
        'diversityReport',
        'diverseQualificationRules'
      ]),
      diversityTotals: getOverviewTotals(state),
      currentGrouping: getCurrentGrouping(state),
      countries: insightsSelectors.getSpendCountries(state),
      categories: insightsSelectors.getSpendCategories(state),
      businessUnits: insightsSelectors.getSpendSpendGroups(state),
      isUsingSpendGroups: insightsSelectors.isUsingSpendGroups(state)
    }
  },
  {
    saveSnapshot
  }
)(ExportDiversitySnapshot)
