import { call, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import relationshipsSelector from '../../../shared/selectors/relationshipsSelectors'
import relationships from 'shared/utils/api/relationships'

export function* removeRelationshipSaga(action) {
  if (action.payload) {
    const relationship = yield select(
      relationshipsSelector.getById,
      action.payload
    )
    const supplierId = relationship && relationship.get('supplier')
    yield call(relationships.deleteRelationship, action.payload)
    return { relationshipId: action.payload, supplierId }
  }
}

export default createApiRequestSaga(removeRelationshipSaga)
