import React, { useState } from 'react'
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel
} from '@material-ui/core'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import withStyles from '@material-ui/styles/withStyles'
import JsonSettings from '../JsonSettings'
import SamlJsonEditor from './SamlJsonEditor'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettings } from '../../actions'
import RootState from 'shared/models/RootState'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'

const StyleCheckbox = withStyles({
  root: {
    padding: 3
  }
})(Checkbox)

const SamlSettings = () => {
  const dispatch = useDispatch()
  const isReadonly = useSelector(
    (state: RootState) => !!state.get('session').get('partnerConcierge')
  )
  const isSaml = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'isSaml')
  )
  const saml = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'saml')
  )

  const [samlMetadata, setSamlMetadata] = useState<string | null>()
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false)

  const handleUpdateSettings = (settings: { [key: string]: any }) => {
    dispatch(updateSettings(settings))
  }

  return (
    <>
      <div className='flex justify-between items-center'>
        <div className='flex-auto'>
          <FormControlLabel
            label={
              <FormattedMessage
                id='Settings.UseSaml'
                defaultMessage='Use SAML'
              />
            }
            control={
              <StyleCheckbox
                color='primary'
                name='isSaml'
                size='small'
                checked={isSaml}
                onChange={() =>
                  handleUpdateSettings({
                    isSaml: !isSaml
                  })
                }
                disabled={isReadonly}
              />
            }
          />
        </div>
        {isSaml && (
          <>
            <Button
              label='Get Metadata'
              size='small'
              onClick={() => {
                api
                  .get(`${apiRoutes.authService}/saml/metadataString`)
                  .then(({ metadata }) => setSamlMetadata(metadata))
              }}
              className='mr2'
              autoSize
            />
            <Button
              autoSize
              secondary
              size='small'
              onClick={() => setIsAdvanced(!isAdvanced)}
            >
              {isAdvanced ? (
                <FormattedMessage id='Friendly' defaultMessage='Friendly' />
              ) : (
                <FormattedMessage id='Advanced' defaultMessage='Advanced' />
              )}
            </Button>
          </>
        )}
      </div>

      {isSaml &&
        (isAdvanced ? (
          <JsonSettings
            fieldName='saml'
            value={saml ? JSON.stringify(saml.toJS()) : ''}
            onUpdate={handleUpdateSettings}
          />
        ) : (
          <SamlJsonEditor onUpdate={handleUpdateSettings} />
        ))}
      <Dialog
        open={!!samlMetadata}
        onClose={() => setSamlMetadata(null)}
        fullWidth
        maxWidth='xl'
      >
        <DialogTitle>SAML Metadata</DialogTitle>
        <DialogContent>
          <pre style={{ wordBreak: 'break-word' }}>{samlMetadata}</pre>
        </DialogContent>
        <DialogActions>
          <Button
            label={<FormattedMessage id='CloseButton' defaultMessage='Close' />}
            onClick={() => setSamlMetadata(null)}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SamlSettings
