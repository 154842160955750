import { call, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import cards from 'shared/utils/api/cards'
import users from 'shared/utils/api/users'
import { notify } from 'shared/actions'

export function* connectUserToOrgSaga(action) {
  const { email, orgId, contacts } = action.payload

  // invite the new users
  let newUsers =
    contacts &&
    contacts.filter(c => !c.getIn(['user', 'id'])).map(c => c.get('user'))

  if (newUsers && newUsers.size > 0) {
    newUsers = yield call(users.inviteUsersOnBehalf, {
      orgUnitId: orgId,
      users: newUsers,
      email
    })
  }

  const newContacts =
    contacts &&
    contacts
      .map(c =>
        c.set(
          'user',
          c.getIn(['user', 'id']) ||
            newUsers.find(u => u.email === c.getIn(['user', 'email'])).id
        )
      )
      .toJS()

  const response = yield call(cards.createOnBehalf, {
    email,
    orgId,
    contacts: newContacts
  })

  yield put(
    notify({
      message: `Card for "${email}" was created`
    })
  )

  return response
}

export default createApiRequestSaga(connectUserToOrgSaga, {
  notifyOnError: true
})
