import React, { Component } from 'react'
import { connect } from 'react-redux'
import CommunitiesPage from '../../components/CommunitiesPage'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import {
  acceptCommunity,
  declineCommunity,
  leaveCommunity
} from '../../actions'
import communitiesPaths from '../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import dataSelectors from 'shared/selectors/dataSelectors'
import { RouteComponentProps } from 'react-router-dom'
import { List } from 'immutable'
import companySelectors from '../../../shared/selectors/companySelectors'
import RootState from 'shared/models/RootState'

type Props = {
  isPremium: boolean
  invitedIds: List<string>
  acceptedIds: List<string>
  recommendedIds: List<string>
  numberTealbookClients: number
  onAcceptCommunity: (payload: { commId: string }) => void
  onDeclineCommunity: (payload: { commId: string }) => void
  onLeaveCommunity: (payload: {
    communityId?: string
    comPlanMembershipId?: string
  }) => void
} & RouteComponentProps

export class CommunitiesPageContainer extends Component<Props> {
  handleViewForm = ({ commId }) => {
    const { history } = this.props
    history.push(
      parsePath(communitiesPaths.communityForm, {
        communityId: commId,
        pageNum: 1
      })
    )
  }

  render() {
    return <CommunitiesPage {...this.props} onViewForm={this.handleViewForm} />
  }
}

export default connect(
  (state: RootState) => {
    const targets = dataSelectors.getByEntity(state, 'targetClients')
    const publicClients = targets && targets.get('publicTargets')
    const privateClients = targets && targets.get('privateTargets')

    return {
      isPremium: companySelectors.isPremium(state),
      invitedIds: communitiesSelectors.getInvitedIds(state),
      acceptedIds: communitiesSelectors.getAcceptedIds(state),
      recommendedIds: communitiesSelectors.getRecommendedIds(state),
      numberTealbookClients:
        ((publicClients && publicClients.size) || 0) +
        ((privateClients && privateClients.size) || 0)
    }
  },
  {
    onAcceptCommunity: acceptCommunity,
    onDeclineCommunity: declineCommunity,
    onLeaveCommunity: leaveCommunity
  }
)(CommunitiesPageContainer)
