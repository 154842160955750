import React, { Component, Fragment } from 'react'
import VetInternalContainer from '../../containers/VetInternalContainer'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import PageSection from 'shared/components/PageSection'
import PageSideSection from 'shared/components/PageSideSection'
import Page from 'shared/components/Page'
import VetTeamCard from '../VetTeamCard'
import { FormattedMessage } from 'react-intl'
import VetSupplierListContainer from '../../containers/VetSupplierListContainer'
import VetInfoCardContainer from '../../containers/VetInfoCardContainer'
import { connect } from 'react-redux'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import { updateVet, deleteProjectBackground } from '../../actions'
import Loading from 'shared/components/Loading'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Select from 'shared/components/Select'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import AutoSaveInput from 'shared/components/AutoSaveInput'
import Paper from 'shared/components/Paper'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import startCase from 'lodash.startcase'
import { fromJS, RecordOf, List } from 'immutable'
import DatePicker from 'shared/components/DatePicker'
import moment from 'moment-timezone'
import dateFormat from 'shared/utils/dateFormat'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import VetContactUsDialogContainer from '../../containers/VetContactUsDialogContainer'
import FileAttachmentList from 'shared/components/FileAttachmentList'
import UploadFile from 'shared/models/UploadedFile'
import chatbot from 'shared/utils/chatbot'

type VetDetailsProps = {
  id: string
  includeBudget: string
  projectBackground: List<RecordOf<UploadFile>>
  category: string
  internalDescription: string
  department: string
  spendType: string
  spendStart: string
  spendEnd: string
  projectEstimate: string
  approvedBudget: string
}

type Props = {
  vet: RecordOf<VetDetailsProps>
  updateVet: ({ vetId: string }) => void
  deleteProjectBackground: (obj: { vetId: string; fileName: string }) => void
  isLoading: boolean
  readonly: boolean
  departmentOptions: List<string>
  categoryOptions: List<
    RecordOf<{
      categoryId: string
      name: string
      subCategories: List<RecordOf<{ categoryId: string; name: string }>>
    }>
  >
  categoryMap: RecordOf<{ selectedCategory: string }>
  allowWordDocs: boolean
}

type State = {
  contactUsDialog: boolean
  category: string
  subCategory: string
}
export class VetDetailsPage extends Component<Props, State> {
  state: State = {
    contactUsDialog: false,
    category: '',
    subCategory: ''
  }

  toggleShowBudget = () => {
    this.handleUpdateVet({
      includeBudget: !this.props.vet.get('includeBudget')
    })
  }

  handleUpdateVet = changes => {
    const { vet, updateVet } = this.props

    updateVet({
      vetId: vet.get('id'),
      ...changes
    })
  }

  handleSaveAttachment = (file: File) => {
    this.handleUpdateVet({
      projectBackground: file
    })
  }

  handleDeleteAttachment = (fileName: string) => {
    const { vet, deleteProjectBackground } = this.props
    deleteProjectBackground({
      vetId: vet.get('id'),
      fileName
    })
  }

  handleSaveCategory = (fieldName: 'category' | 'subCategory') => e => {
    if (fieldName === 'category' && e.target.value !== undefined) {
      this.setState({
        subCategory: '',
        category: e.target.value
      })
    } else {
      this.setState({
        [fieldName]: e.target.value
      } as any)
    }

    this.handleUpdateVet({
      category: e.target.value
    })
  }

  handleChangeDate = (fieldName, newDate) => {
    this.handleUpdateVet({
      [fieldName]: newDate && newDate.toDate()
    })
  }

  render() {
    const {
      isLoading,
      vet,
      readonly,
      departmentOptions,
      categoryOptions,
      categoryMap,
      allowWordDocs
    } = this.props

    const { category, subCategory } = this.state

    const selectedCategory =
      vet.get('category') && vet.get('category').split('-')[0]
    const subCategoryOptions =
      selectedCategory &&
      categoryOptions &&
      (
        categoryOptions.find(
          c => c.get('categoryId') === selectedCategory,
          null
        ) || fromJS({})
      ).get('subCategories')

    if (isLoading) {
      return <Loading />
    }

    if (!vet.id) {
      return (
        <Page title='RFI was not found'>
          <p className='fw5 f4 mt4 tc'>
            <FormattedMessage
              id='VetDetailsPage.vetWasNotFound'
              defaultMessage={
                "The RFI you are looking for does not exist, or you don't have access to it."
              }
            />
          </p>
        </Page>
      )
    }

    return (
      <Page
        title={
          <FormattedMessage
            id='VetDetailsPage.Details'
            defaultMessage='Details'
          />
        }
      >
        <ScrollToPosition />
        <Main>
          <PageSection
            title={
              <FormattedMessage
                id='VetDetailsPage.InternalOverview'
                defaultMessage='Internal Overview'
              />
            }
          >
            {!(
              vet.get('internalDescription') ||
              vet.get('department') ||
              vet.get('category') ||
              vet.get('projectBackground')
            ) &&
              readonly && (
                <Text className='tc mt3-5'>
                  <FormattedMessage
                    id='VetDetailsPage.NoData'
                    defaultMessage='No Data Available'
                  />
                </Text>
              )}
            {(!readonly || vet.get('internalDescription')) && (
              <Fragment>
                <Label>
                  <FormattedMessage
                    id='VetDetailsPage.Summary'
                    defaultMessage='Summary'
                  />
                </Label>
                {!readonly ? (
                  <FormattedMessage
                    id='VetDetailsPage.provideSummary'
                    defaultMessage='Provide a summary of your needs and goals'
                  >
                    {message => (
                      <AutoSaveInput
                        component={TextArea}
                        value={vet.get('internalDescription')}
                        name='internalDescription'
                        maxLength={500}
                        placeholder={message}
                        onSave={this.handleUpdateVet}
                      />
                    )}
                  </FormattedMessage>
                ) : (
                  <Text>{vet.get('internalDescription')}</Text>
                )}
              </Fragment>
            )}
            {(!readonly || vet.get('department')) &&
              departmentOptions &&
              departmentOptions.size > 0 && (
                <Fragment>
                  <Label>
                    <FormattedMessage
                      id='VetDetailsPage.department'
                      defaultMessage='Department'
                    />
                  </Label>
                  {!readonly ? (
                    <AutoSaveInput
                      name='department'
                      component={Select}
                      value={vet.get('department')}
                      onSave={this.handleUpdateVet}
                      fullWidth
                    >
                      <FormattedMessage
                        id='VetDetailsPage.departmentHint'
                        defaultMessage='Select a department'
                      >
                        {message => <option value=''>{message}</option>}
                      </FormattedMessage>
                      {departmentOptions.map(option => (
                        <option key={option} value={option}>
                          {startCase(option)}
                        </option>
                      ))}
                    </AutoSaveInput>
                  ) : (
                    <Text>{startCase(vet.get('department'))}</Text>
                  )}
                </Fragment>
              )}
            {(!readonly || vet.get('category')) &&
              categoryOptions &&
              categoryOptions.size > 0 && (
                <Fragment>
                  <Label>
                    <FormattedMessage
                      id='VetDetailsPage.category'
                      defaultMessage='Category'
                    />
                  </Label>
                  {!readonly ? (
                    <Fragment>
                      <Select
                        name='category'
                        value={category || selectedCategory}
                        onChange={this.handleSaveCategory('category')}
                        fullWidth
                      >
                        <FormattedMessage
                          id='VetDetailsPage.selectCategory'
                          defaultMessage='Select a category'
                        >
                          {message => <option value=''>{message}</option>}
                        </FormattedMessage>
                        {categoryOptions.map(category => (
                          <option
                            key={category.get('categoryId')}
                            value={category.get('categoryId')}
                          >
                            {category.get('name')}
                          </option>
                        ))}
                      </Select>
                      {subCategoryOptions && subCategoryOptions.size > 0 && (
                        <Fragment>
                          <Label>
                            <FormattedMessage
                              id='VetDetailsPage.subcategory'
                              defaultMessage='Subcategory'
                            />
                          </Label>
                          <Select
                            name='subCategory'
                            disabled={!selectedCategory}
                            value={subCategory || vet.get('category')}
                            onChange={this.handleSaveCategory('subCategory')}
                            fullWidth
                          >
                            <FormattedMessage
                              id='VetDetailsPage.selectSubCategory'
                              defaultMessage='Select a subcategory'
                            >
                              {message => <option value=''>{message}</option>}
                            </FormattedMessage>

                            {subCategoryOptions.map(category => (
                              <option
                                key={category.get('categoryId')}
                                value={category.get('categoryId')}
                              >
                                {category.get('name')}
                              </option>
                            ))}
                          </Select>
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Text>
                      {`${selectedCategory &&
                        categoryMap.getIn([selectedCategory, 'name'])}`}
                      {vet.get('category', '').includes('-') &&
                        ` / ${categoryMap.getIn([
                          vet.get('category'),
                          'name'
                        ])}`}
                    </Text>
                  )}
                </Fragment>
              )}

            <FileAttachmentList
              readonly={readonly}
              limit={10}
              allowWordDocs={allowWordDocs}
              attachment={vet.get('projectBackground')}
              label={
                <FormattedMessage
                  id='ProjectBackgroundContainer.ProjectBackground'
                  defaultMessage='Project Background'
                />
              }
              onSave={this.handleSaveAttachment}
              onRemove={this.handleDeleteAttachment}
            />
          </PageSection>
          {(!!vet.get('spendType') ||
            !!vet.get('spendStart') ||
            !!vet.get('spendEnd') ||
            !!vet.get('projectEstimate') ||
            !!vet.get('approvedBudget')) && (
            <>
              <div className='pl3-5 pb2 flex pt4 items-center'>
                <span className='flex-auto f5-l f6 fw6 ma0 pb2'>
                  <FormattedMessage
                    id='VetDetailsPage.budget'
                    defaultMessage='Budget'
                  />
                </span>
              </div>
              <Paper>
                {!(
                  vet.get('spendType') ||
                  vet.get('spendStart') ||
                  vet.get('spendEnd') ||
                  vet.get('projectEstimate') ||
                  vet.get('approvedBudget')
                ) &&
                  readonly && (
                    <Text className='tc mt3-5'>
                      <FormattedMessage id='VetDetailsPage.NoData' />
                    </Text>
                  )}
                {(!readonly || vet.get('spendType')) && (
                  <Fragment>
                    <Label>
                      <FormattedMessage
                        id='VetDetailsPage.spendType'
                        defaultMessage='Spend Type'
                      />
                    </Label>
                    {!readonly ? (
                      <AutoSaveInput
                        name='spendType'
                        component={Select}
                        value={vet.get('spendType')}
                        onSave={this.handleUpdateVet}
                        fullWidth
                      >
                        <FormattedMessage
                          id='VetDetailsPage.spendTypeHint'
                          defaultMessage='Select the type of spend'
                        >
                          {message => <option value=''>{message}</option>}
                        </FormattedMessage>

                        <FormattedMessage
                          id='VetDetailsPage.Direct'
                          defaultMessage='Direct'
                        >
                          {message => <option value='Direct'>{message}</option>}
                        </FormattedMessage>

                        <FormattedMessage
                          id='VetDetailsPage.Indirect'
                          defaultMessage='Indirect'
                        >
                          {message => (
                            <option value='Indirect'>{message}</option>
                          )}
                        </FormattedMessage>

                        <FormattedMessage
                          id='VetDetailsPage.Capital'
                          defaultMessage='Capital'
                        >
                          {message => (
                            <option value='Capital'>{message}</option>
                          )}
                        </FormattedMessage>
                      </AutoSaveInput>
                    ) : (
                      <Text>{vet.get('spendType')}</Text>
                    )}
                  </Fragment>
                )}
                {(!readonly ||
                  vet.get('spendStart') ||
                  vet.get('spendEnd')) && (
                  <div className='db'>
                    <div className='w-100 w-50-ns pr2-ns dib v-top'>
                      <Label>
                        <FormattedMessage
                          id='VetDetailsPage.SpendStart'
                          defaultMessage='Spend Start'
                        />
                      </Label>
                      {!readonly ? (
                        <DatePicker
                          name='spendStart'
                          maxDate={
                            vet.get('spendEnd')
                              ? moment(vet.get('spendEnd'))
                              : undefined
                          }
                          maxDateMessage={
                            <FormattedMessage
                              id='VetDetailsPage.SpendStartMaxMessage'
                              defaultMessage='Start date could not be after end date'
                            />
                          }
                          value={vet.get('spendStart')}
                          onChange={newDate =>
                            this.handleChangeDate(
                              'spendStart',
                              newDate &&
                                newDate
                                  .hour(0)
                                  .minute(0)
                                  .second(0)
                            )
                          }
                        />
                      ) : (
                        <Text>
                          {vet.get('spendStart') &&
                            dateFormat(vet.get('spendStart'))}
                        </Text>
                      )}
                    </div>
                    <div className='w-100 w-50-ns pl2-ns dib v-top'>
                      <Label>
                        <FormattedMessage
                          id='VetDetailsPage.SpendEnd'
                          defaultMessage='Spend End'
                        />
                      </Label>
                      {!readonly ? (
                        <DatePicker
                          name='spendEnd'
                          minDate={
                            vet.get('spendStart')
                              ? moment(vet.get('spendStart'))
                              : undefined
                          }
                          minDateMessage={
                            <FormattedMessage
                              id='VetDetailsPage.SpendEndMinMessage'
                              defaultMessage='End date could not be before start date'
                            />
                          }
                          value={vet.get('spendEnd')}
                          onChange={newDate =>
                            this.handleChangeDate(
                              'spendEnd',
                              newDate &&
                                newDate
                                  .hour(23)
                                  .minute(59)
                                  .second(59)
                            )
                          }
                        />
                      ) : (
                        <Text>
                          {vet.get('spendEnd') &&
                            dateFormat(vet.get('spendEnd'))}
                        </Text>
                      )}
                    </div>
                  </div>
                )}
                {(!readonly || vet.get('projectEstimate')) && (
                  <Fragment>
                    <Label>
                      <FormattedMessage
                        id='VetDetailsPage.projectEstimates'
                        defaultMessage='Project Estimate'
                      />
                    </Label>
                    {!readonly ? (
                      <AutoSaveInput
                        name='projectEstimate'
                        component={Select}
                        value={vet.get('projectEstimate')}
                        onSave={this.handleUpdateVet}
                        fullWidth
                      >
                        <FormattedMessage
                          id='VetDetailsPage.projectEstimateHint'
                          defaultMessage='Select the estimated budget range for the project'
                        >
                          {message => <option value=''>{message}</option>}
                        </FormattedMessage>

                        <FormattedMessage
                          id='VetDetailsPage.Unspecified'
                          defaultMessage='Unspecified'
                        >
                          {message => (
                            <option value='Unspecified'>{message}</option>
                          )}
                        </FormattedMessage>

                        <option value='$100,000-$250,000'>
                          $100,000-$250,000
                        </option>
                        <option value='$250,000-$500,000'>
                          $250,000-$500,000
                        </option>
                        <option value='$500,000-$1,000,000'>
                          $500,000-$1,000,000
                        </option>
                        <option value='$1,000,000-$2,000,000'>
                          $1,000,000-$2,000,000
                        </option>
                        <option value='$2,000,000+'>$2,000,000+</option>
                      </AutoSaveInput>
                    ) : (
                      <Text>{vet.get('projectEstimate')}</Text>
                    )}
                  </Fragment>
                )}
                {(!readonly || !!vet.get('approvedBudget')) && (
                  <Fragment>
                    <Label>
                      <FormattedMessage
                        id='VetDetailsPage.approvedBudget'
                        defaultMessage='Approved Budget'
                      />
                    </Label>
                    {!readonly ? (
                      <FormattedMessage
                        id='VetDetailsPage.approvedBudgetPlaceholder'
                        defaultMessage='Has a budget being approved? If so, how much?'
                      >
                        {message => (
                          <AutoSaveInput
                            component={Input}
                            value={vet.get('approvedBudget')}
                            name='approvedBudget'
                            placeholder={message}
                            onSave={this.handleUpdateVet}
                            normalize={value =>
                              parseFloat(value.replace(/^0+/, ''))
                            }
                            type='number'
                          />
                        )}
                      </FormattedMessage>
                    ) : (
                      <Text>{vet.get('approvedBudget')}</Text>
                    )}
                  </Fragment>
                )}
              </Paper>
            </>
          )}
          <Text className='mt3-5 tc'>
            <FormattedMessage
              id='VetDetailsPage.suppliersHint'
              defaultMessage='Click on the Suppliers tab to start finding suppliers for this opportunity.'
            />
          </Text>
          <VetInternalContainer />
        </Main>

        <Aside className='mt4-l'>
          <VetInfoCardContainer />
          <PageSideSection
            title={
              <div className='flex items-center'>
                <img
                  src={TealbotCircleImage}
                  alt='tealbot'
                  style={{ width: 20, height: 20 }}
                />
                <span className='ml2'>
                  <FormattedMessage
                    id='VetDetailsPage.NeedHelp'
                    defaultMessage='Need More Help?'
                  />
                </span>
              </div>
            }
          >
            <>
              <span className='teal f7 fw6 pointer dim' onClick={chatbot.open}>
                <FormattedMessage
                  id='VetDetailsPage.ContactUs'
                  defaultMessage='Contact us'
                />
              </span>
              &nbsp;
              <span className='lh-copy f7 fw4 mid-gray ma0'>
                <FormattedMessage
                  id='VetDetailsPage.ConciergeMessage'
                  defaultMessage='with a few details and the Concierge will be happy to assist you.'
                />
              </span>
            </>
          </PageSideSection>
          <VetSupplierListContainer />
          <VetTeamCard />
        </Aside>
        <VetContactUsDialogContainer
          open={this.state.contactUsDialog}
          onRequestClose={() => this.setState({ contactUsDialog: false })}
        />
      </Page>
    )
  }
}

export const mapStateToProps = state => {
  return {
    vet: currentVetSelectors.getVet(state),
    isLoading: currentVetSelectors.isLoading(state),
    readonly: currentVetSelectors.isReadonly(state),
    categoryOptions: settingsSelectors.getField(state, 'categories'),
    departmentOptions: settingsSelectors.getField(state, 'departments'),
    categoryMap: settingsSelectors.getCategoryMap(state),
    allowWordDocs: settingsSelectors.getAllowWordDocs(state)
  }
}

export default connect(mapStateToProps, {
  updateVet,
  deleteProjectBackground
})(VetDetailsPage)
