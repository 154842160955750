import React, { Component } from 'react'
import { reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import { connect } from 'react-redux'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { REQUEST_TO_REGISTER } from '../../actionTypes'
import { Field } from 'redux-form/immutable'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import { getFormSubmitErrors } from 'shared/reducers'
import Warning from 'shared/components/Warning'
import RadioButton from 'shared/components/RadioButton'
import RadioButtonGroup from 'shared/components/RadioButton/RadioButtonGroup'
import ActionInfoOutline from '@material-ui/icons/InfoOutlined'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'

type FormFields = {
  firstName: string
}

type Props = {
  errorMessage?: string
  hideNoneOfTheAbove: boolean
}

export class RegistrationPage3 extends Component<
  Props & InjectedFormProps<FormFields>
> {
  state = {
    company: '',
    firstName: '',
    lastName: ''
  }

  handleFieldChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  checkSubmitDisabled() {
    const { company, firstName, lastName } = this.state
    let disabled = true
    if (company && firstName && lastName) {
      disabled = false
    }
    return disabled
  }

  render() {
    const { handleSubmit, errorMessage, hideNoneOfTheAbove } = this.props

    return (
      <form className='tl' onSubmit={handleSubmit}>
        <Label htmlFor='firstName' className='db mb1 f7 fw6' required>
          <FormattedMessage
            id='RegistrationPage3.firstName'
            defaultMessage='First Name'
          />
        </Label>
        <Field
          name='firstName'
          component={Input}
          required
          onChange={this.handleFieldChange}
        />
        <Label htmlFor='lastName' required>
          <FormattedMessage
            id='RegistrationPage3.lastName'
            defaultMessage='Last Name'
          />
        </Label>
        <Field
          name='lastName'
          component={Input}
          required
          onChange={this.handleFieldChange}
        />
        <Label htmlFor='company' required>
          <FormattedMessage
            id='RegistrationPage3.companyName'
            defaultMessage='Company Name'
          />
        </Label>
        <Field
          name='company'
          component={Input}
          required
          onChange={this.handleFieldChange}
        />

        <div className='flex items-end'>
          <Label htmlFor='websiteUrl' required>
            <FormattedMessage
              id='RegistrationPage3.website'
              defaultMessage='Website'
            />
          </Label>
          <Tooltip
            title={
              <FormattedMessage
                id='RegistrationPage3.websiteUrlAlternative'
                defaultMessage={
                  "If you don't have a website, please provide a social media page or a business directory listing"
                }
              />
            }
          >
            <ActionInfoOutline className='ml2' />
          </Tooltip>
        </div>

        <Field name='websiteUrl' component={Input} required />

        {!hideNoneOfTheAbove && (
          <RadioButtonGroup
            title='Why did you choose "none of the above"?'
            className='mt3'
            required
            stack
          >
            <Field
              name='reasonOfNoneOfTheAbove'
              component={RadioButton}
              type='radio'
              label='I work for a different but related company'
              value='I work for a different but related company'
              required
            />
            <Field
              name='reasonOfNoneOfTheAbove'
              component={RadioButton}
              type='radio'
              label='Organization name is wrong or out-of-date'
              value='Organization name is wrong or out-of-date'
              required
            />
            <Field
              name='reasonOfNoneOfTheAbove'
              component={RadioButton}
              type='radio'
              label='I use this email address as my contact for an unrelated company'
              value='I use this email address as my contact for an unrelated company'
              required
            />
            <Field
              name='reasonOfNoneOfTheAbove'
              component={RadioButton}
              type='radio'
              label='Other'
              value='Other'
              required
            />
          </RadioButtonGroup>
        )}
        {errorMessage && <Warning className='mt3' message={errorMessage} />}
        <Button
          type='submit'
          label={
            <FormattedMessage
              id='RegistrationPage3.signUp'
              defaultMessage='Sign Up'
            />
          }
          className='mt3'
          disabled={this.checkSubmitDisabled()}
        />
      </form>
    )
  }
}

const formName = 'accounts/registration'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)

export default connect(
  (state, props: Props) => ({
    errorMessage: formSubmitErrorsSelector(state)
  }),
  {
    onSubmit: createSubmitFormAction(formName, REQUEST_TO_REGISTER)
  }
)(
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(RegistrationPage3)
)
