import React, { FunctionComponent, useState, useMemo } from 'react'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import dateFormat from 'shared/utils/dateFormat'
import { List, RecordOf } from 'immutable'
import { FormattedMessage } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { useSelector } from 'react-redux'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import RootState from 'shared/models/RootState'

type SpendDetail = {
  category: string
  spendDate: Date
  amount: number
  address: string
}

type Props = {
  data: List<RecordOf<SpendDetail>>
}

const SpendDetailsTable: FunctionComponent<Props> = props => {
  const [sort, setSort] = useState<{
    sortBy: keyof SpendDetail
    sortDirection: SortDirection
  }>({
    sortBy: 'spendDate',
    sortDirection: 'DESC'
  })

  const isTealbot = useSelector((state: RootState) =>
    sessionSelectors.userHasRole(state, 'tealbot')
  )

  const getRow = ({ index }) => {
    return sortedData.size > 0 ? sortedData.get(index) : undefined
  }

  const renderDate = ({ cellData }: TableCellProps) => {
    if (!cellData) {
      return null
    }
    const date = new Date(cellData)
    return dateFormat(
      new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    )
  }

  const renderAmount = ({ cellData }: TableCellProps) => {
    const amount = typeof cellData === 'number' ? cellData : 0
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  const renderAddress = ({ cellData, rowData }: TableCellProps) => {
    if (!cellData) {
      return null
    }
    const address = cellData.split(',')
    const trimAddress = address.filter(a => !!a.trim())

    const warning = (
      <Tooltip
        title={
          <>
            <FormattedMessage
              id='SpendDetailsTable.UnknownCountry1'
              defaultMessage='We were unable to determine a country from the input address for this item. It has been mapped to "Unknown Country".'
            />
            <br />
            <br />
            <FormattedMessage
              id='SpendDetailsTable.UnknownCountry2'
              defaultMessage="Here's the address we have:"
            />
            <br />
            {trimAddress.join(', ')}
          </>
        }
      >
        <InfoIcon className='mr2 v-mid' />
      </Tooltip>
    )

    return trimAddress.map((a, index) => {
      if (
        rowData.getIn(['location', 'components']) &&
        !!rowData.getIn(['location', 'components', 'country'])
      ) {
        return (
          <Tooltip title={trimAddress.join(', ')}>
            <span
              key={a}
              className={index === trimAddress.length - 1 ? 'db' : ''}
            >
              {`${a.trim()}${index < trimAddress.length - 2 ? ', ' : ''}`}
            </span>
          </Tooltip>
        )
      } else if (!rowData.getIn(['location', 'components'])) {
        return (
          <Tooltip title={trimAddress.join(', ')}>
            <span
              key={a}
              className={index === trimAddress.length - 1 ? 'db' : ''}
            >
              {`${a.trim()}${index < trimAddress.length - 2 ? ', ' : ''}`}
            </span>
          </Tooltip>
        )
      } else {
        return (
          <>
            {warning}
            <span
              key={a}
              className={index === trimAddress.length - 1 ? 'db' : ''}
            >
              {`${a.trim()}${index < trimAddress.length - 2 ? ', ' : ''}`}
            </span>
          </>
        )
      }
    })
  }

  const renderCategory = ({ cellData }: TableCellProps) => (
    <Tooltip title={cellData}>{<span>{cellData}</span>}</Tooltip>
  )

  const sortedData = useMemo(() => {
    const data = props.data

    return data.sort((vetA, vetB) => {
      const valueA =
        sort.sortBy === 'spendDate' || sort.sortBy === 'amount'
          ? vetA.get(sort.sortBy)
          : (vetA.get(sort.sortBy) || '').toLowerCase()

      const valueB =
        sort.sortBy === 'spendDate' || sort.sortBy === 'amount'
          ? vetB.get(sort.sortBy)
          : (vetB.get(sort.sortBy) || '').toLowerCase()

      return sort.sortDirection === 'ASC'
        ? valueA < valueB
          ? -1
          : 1
        : valueA > valueB
        ? -1
        : 1
    })
  }, [props.data, sort.sortBy, sort.sortDirection])

  const handleSortChange = ({ sortBy, sortDirection }) => {
    setSort({
      sortBy,
      sortDirection
    })
    // setSortedData(sortData(sortBy, sortDirection))
  }

  return (
    <div className='bg-white br1 ba b--black-10 overflow-hidden'>
      {sortedData && sortedData.size > 0 && (
        <Table
          minWidth={600}
          rowGetter={getRow}
          rowCount={sortedData.size}
          sort={handleSortChange}
          sortDirection={sort.sortDirection}
          sortBy={sort.sortBy}
        >
          {isTealbot && (
            <Column
              label={
                <FormattedMessage
                  id='SpendDetailsTable.InternalId'
                  defaultMessage='Internal Id'
                />
              }
              dataKey={'internalSupplierId'}
              width={100}
            />
          )}
          <Column
            label={
              <FormattedMessage
                id='SpendDetailsTable.Category'
                defaultMessage='Category'
              />
            }
            dataKey={'category'}
            width={200}
            cellRenderer={renderCategory}
          />
          <Column
            label={
              <FormattedMessage
                id='SpendDetailsTable.Address'
                defaultMessage='Address'
              />
            }
            dataKey={'address'}
            width={isTealbot ? 200 : 300}
            cellRenderer={renderAddress}
          />
          <Column
            label={
              <FormattedMessage
                id='SpendDetailsTable.Date'
                defaultMessage='Date'
              />
            }
            dataKey={'spendDate'}
            width={80}
            cellRenderer={renderDate}
          />
          <Column
            label={
              <FormattedMessage
                id='SpendDetailsTable.Amount'
                defaultMessage='Amount'
              />
            }
            dataKey={'amount'}
            width={120}
            cellRenderer={renderAmount}
            className='tr'
            headerClassName='tr'
          />
        </Table>
      )}
    </div>
  )
}

type SortDirection = 'ASC' | 'DESC'

export default SpendDetailsTable
