import React, { Component } from 'react'
import Input from '../Input'

const styles = {
  input: 'w4 dib v-top',
  to: 'mh3 mt2 dib v-top',
  errorMessage: 'f7 fw3 red mt2'
}

type Props = {
  name: string
  min: number
  max: number
  required: boolean
  value: {
    from: number
    to: number
  }
  meta: {
    error?: string
    touched: boolean
  }
  onChange: (changeRange: {
    target: { value: { from: number; to: number } & { [x: number]: any } }
  }) => void
}

class RangeInput extends Component<Props> {
  static defaultProps = {
    value: {
      from: 0,
      to: 0
    }
  }

  handleChange = e => {
    const { onChange } = this.props
    const { from, to } = this.props.value

    onChange({
      target: {
        value: Object.assign(
          {},
          {
            from,
            to
          },
          {
            [e.target.name]: e.target.value ? parseFloat(e.target.value) : ''
          }
        )
      }
    })
  }

  render() {
    const {
      value: { from, to },
      meta: { touched, error }
    } = this.props

    return (
      <div>
        <Input
          wrapperClassName={styles.input}
          type='number'
          name='from'
          onChange={this.handleChange}
          value={from}
        />

        <span className={styles.to}>to</span>

        <Input
          wrapperClassName={styles.input}
          type='number'
          name='to'
          onChange={this.handleChange}
          value={to}
        />

        {touched && error && <div className={styles.errorMessage}>{error}</div>}
      </div>
    )
  }
}

export default RangeInput
