import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import suppliers from 'shared/utils/api/suppliers'
import parseSupplier from 'shared/utils/data/parseSupplier'
import buyer from 'shared/utils/api/buyer'
import supplier from 'shared/utils/api/supplier'

export function* searchForOrgSaga(action) {
  const { q, searchBuyers, searchExactDomain } = action.payload.toJS()
  // search for the org by the query
  let result
  if (searchBuyers) {
    result = yield call(buyer.getByDomain, q)
  } else if (q && searchExactDomain) {
    result = yield call(supplier.getSupplierByDomain, q)
  } else {
    result = yield call(suppliers.searchSuppliers, { q })
  }

  // parse it for ease of use

  const parsedResults =
    (searchBuyers || searchExactDomain) && Array.isArray(result)
      ? {
          orgs: result.map(parseSupplier),
          totalOrgs: result.length
        }
      : {
          orgs: result.hits.map(hit => parseSupplier(hit.supplier)),
          totalOrgs: result.total?.value
        }

  return yield parsedResults
}

export default createApiRequestSaga(searchForOrgSaga)
