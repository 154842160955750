import React, { Component } from 'react'
import createRoutes from './routes/createRoutes'

class App extends Component {
  render() {
    return <div className='tc'>{createRoutes()}</div>
  }
}

export default App
