import React, { useMemo, useState, memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadData } from 'shared/actions'
import { FormattedMessage, useIntl } from 'react-intl'
import DiversityCategory from 'shared/models/DiversityCategory'
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer
} from 'recharts'
import numberFormat from 'shared/utils/numberFormat'
import { Column } from 'react-virtualized'
import Table from 'shared/components/Table'
import translateCertificationType from 'shared/utils/translateCertificationType'
import { useSelector } from 'react-redux'
import dataSelectors from 'shared/selectors/dataSelectors'
import RootState from 'shared/models/RootState'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import QuarterRangeSlider, {
  Quarter
} from 'shared/components/QuarterRangeSlider'
import Loading from 'shared/components/Loading'
import Grid from '@material-ui/core/Grid'
import Divider from 'shared/components/Divider'
import Paper from 'shared/components/Paper'
import Switch from 'shared/components/Switch'
import exportData from 'shared/utils/exportData'
import Text from 'shared/components/Text'
import { tier2DiverseSpendData } from '../store/loadTier2DiverseSpendData'
import DiversityReportSummary from 'shared/components/DiversityReportSummary'

export type OverviewSpendItem = {
  year: number
  quarter: 1 | 2 | 3 | 4
  totalSpend: number
  diverseSpend: {
    [key in DiversityCategory]?: { spend: number; numSuppliers: number }
  }
}

type SpendByCategoryItem = {
  name: string
  spendAmount: number
  spendPercentage: number
}

type ReportProps = {
  overviewSpendData: OverviewSpendItem[]
}
const CustomBarTooltip = props => {
  const { active } = props
  const intl = useIntl()
  if (active) {
    const { formattedData, label } = props
    return (
      <div className='bg-white br1 ba b--black-10 ph2'>
        {formattedData.map(spendAmount => (
          <div key={spendAmount.name}>
            <p>{spendAmount.name === label ? label : ''}</p>
            <p style={{ color: '#00B8A1' }}>
              {spendAmount.name === label
                ? `Diverse Spend: ${intl.formatNumber(
                    spendAmount.totalDiverseSpend,
                    {
                      currency: 'USD',
                      style: 'currency',
                      minimumFractionDigits: 0
                    }
                  )}`
                : ''}
            </p>
            <p style={{ color: '#F1CD5A' }}>
              {spendAmount.name === label
                ? `Total Spend: ${intl.formatNumber(spendAmount.totalSpend, {
                    currency: 'USD',
                    style: 'currency',
                    minimumFractionDigits: 0
                  })}`
                : ''}
            </p>
          </div>
        ))}
      </div>
    )
  }

  return null
}
const Report = memo(({ overviewSpendData }: ReportProps) => {
  const intl = useIntl()
  const quarterRangeLimits: [Quarter, Quarter] = [
    {
      year: overviewSpendData[0].year,
      quarter: overviewSpendData[0].quarter
    },
    {
      year: overviewSpendData[overviewSpendData.length - 1].year,
      quarter: overviewSpendData[overviewSpendData.length - 1].quarter
    }
  ]
  const [showDiverseOnly, setShowDiverseOnly] = useState<boolean>(false)
  const [quarterRangeValue, setQuarterRangeValue] = useState<
    [Quarter, Quarter]
  >(quarterRangeLimits)

  const formattedData = useMemo(
    () =>
      overviewSpendData &&
      overviewSpendData.map(item => ({
        ...item,
        name: `${item.year} Q${item.quarter}`,
        totalDiverseSpend: Object.values(item.diverseSpend).reduce<number>(
          (result, diverseSpendAmount) =>
            result + ((diverseSpendAmount && diverseSpendAmount.spend) || 0),
          0
        )
      })),
    [overviewSpendData]
  )

  // filter out spend that is not within the selected values
  const filterSpendByValue = useMemo(
    () =>
      formattedData.filter(spendItem => {
        const aboveBottomLimit =
          quarterRangeValue[0].year < spendItem.year ||
          (quarterRangeValue[0].year === spendItem.year &&
            quarterRangeValue[0].quarter <= spendItem.quarter)
        const belowUpperLimit =
          spendItem.year < quarterRangeValue[1].year ||
          (spendItem.year === quarterRangeValue[1].year &&
            spendItem.quarter <= quarterRangeValue[1].quarter)

        return aboveBottomLimit && belowUpperLimit
      }),
    [formattedData, quarterRangeValue]
  )

  const selectedTotalSpend = useMemo(
    () => filterSpendByValue.reduce((agg, item) => item.totalSpend + agg, 0),
    [filterSpendByValue]
  )

  const spendByCategory = useMemo(() => {
    const spendGroupedByCategory = filterSpendByValue
      // group the spend by their diversity category
      .reduce<{ [key: string]: SpendByCategoryItem }>(
        (result, overviewSpendItem) => ({
          ...result,
          ...Object.entries(overviewSpendItem.diverseSpend).reduce<{
            [key: string]: SpendByCategoryItem
          }>((groupedDiverseSpend, [diversityCategory, diverseSpend]) => {
            const acronym = translateCertificationType({
              intl,
              categoryType: 'diversity',
              subCategoryType: diversityCategory,
              useAcronym: true
            })
            const title = translateCertificationType({
              intl,
              categoryType: 'diversity',
              subCategoryType: diversityCategory
            })
            const categoryAmount = result[diversityCategory]
              ? result[diversityCategory].spendAmount
              : 0
            const aggregatedAmount =
              ((diverseSpend && diverseSpend.spend) || 0) + categoryAmount
            return {
              ...groupedDiverseSpend,
              [diversityCategory]: {
                name: `${acronym} (${title})`,
                spendAmount: aggregatedAmount,
                spendPercentage: aggregatedAmount
                  ? aggregatedAmount / selectedTotalSpend
                  : 0
              }
            }
          }, {})
        }),
        {}
      )

    return Object.values(spendGroupedByCategory).sort(
      (a, b) => b.spendAmount - a.spendAmount
    )
  }, [filterSpendByValue, selectedTotalSpend, intl])

  const selectedTotalDiverseSpend = useMemo(
    () => spendByCategory.reduce((agg, item) => item.spendAmount + agg, 0),
    [spendByCategory]
  )

  const supplierCountByCategory = useMemo(() => {
    return (
      filterSpendByValue
        // group the supplier Count by their diversity category
        .reduce<{
          [key: string]: { supplierCount: number; spendAmount: number }
        }>(
          (result, overviewSpendItem) => ({
            ...result,
            ...Object.entries(overviewSpendItem.diverseSpend).reduce<{
              [key: string]: { supplierCount: number; spendAmount: number }
            }>((groupedSupplierCount, [diversityCategory, diverseSpend]) => {
              const categorySupplierCount = result[diversityCategory]
                ? result[diversityCategory].supplierCount
                : 0
              const aggregatedSupplierCount =
                ((diverseSpend && diverseSpend.numSuppliers) || 0) +
                categorySupplierCount
              const categoryAmount = result[diversityCategory]
                ? result[diversityCategory].spendAmount
                : 0
              const aggregatedAmount =
                ((diverseSpend && diverseSpend.spend) || 0) + categoryAmount
              return {
                ...groupedSupplierCount,
                [diversityCategory]: {
                  supplierCount: aggregatedSupplierCount,
                  spendAmount: aggregatedAmount
                }
              }
            }, {})
          }),
          {}
        )
    )
  }, [filterSpendByValue])

  return (
    <>
      <div className='flex items-center justify-between mt3 mb2'>
        <Label className='db f7 fw6'>
          <FormattedMessage id='Report.Spend' defaultMessage='Spend' />
        </Label>
        <Switch
          label={
            <FormattedMessage
              id='Report.DiverseOnly'
              defaultMessage='Show Diverse Spend Only'
            />
          }
          checked={showDiverseOnly}
          onChange={() => setShowDiverseOnly(!showDiverseOnly)}
        />
      </div>
      <Paper>
        <ResponsiveContainer height={300} width='100%' className='f7'>
          <BarChart
            className='mt4'
            width={800}
            height={400}
            data={formattedData}
            barGap={0}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis tickFormatter={amount => `$${numberFormat(amount)}`} />
            <Tooltip
              content={<CustomBarTooltip formattedData={formattedData} />}
            />
            <Legend />
            <Bar
              dataKey='totalDiverseSpend'
              fill='#00B8A1'
              name='Diverse Spend'
            />
            {!showDiverseOnly && (
              <Bar dataKey='totalSpend' fill='#F1CD5A' name='Total Spend' />
            )}
          </BarChart>
        </ResponsiveContainer>
      </Paper>
      <div className='flex items-center justify-between mt3-5 mb2'>
        <Label className='db f7 fw6'>
          <FormattedMessage
            id='Report.DiversityReport'
            defaultMessage='Diversity Report'
          />
        </Label>
      </div>
      <Grid container spacing={3} justify='flex-end'>
        <Grid item md={6}>
          <div className='mt2 f4 gray'>
            <FormattedMessage
              id='Spend.DateRangeReport'
              values={{
                fromQuarter: `${quarterRangeValue[0].year} Q${quarterRangeValue[0].quarter}`,
                toQuarter: `${quarterRangeValue[1].year} Q${quarterRangeValue[1].quarter}`
              }}
            />
          </div>
          <QuarterRangeSlider
            value={quarterRangeValue}
            onChange={setQuarterRangeValue}
            minQuarter={quarterRangeLimits[0]}
            maxQuarter={quarterRangeLimits[1]}
          />
        </Grid>
        <Grid item md={6} className='tr'>
          {spendByCategory.length > 0 && (
            <Button
              autoSize
              label={
                <FormattedMessage id='Report.Export' defaultMessage='Export' />
              }
              onClick={() =>
                exportData.exportCSV(
                  spendByCategory.map(category => ({
                    type: category.name,
                    diverseAmount: category.spendAmount,
                    spendPercentage: (category.spendPercentage * 100).toFixed(2)
                  })),
                  `diversity-report-${quarterRangeValue[0].year}Q${quarterRangeValue[0].quarter}-${quarterRangeValue[1].year}Q${quarterRangeValue[1].quarter}`
                )
              }
            />
          )}
        </Grid>
      </Grid>
      <Divider className='mv3' />
      {spendByCategory.length > 0 ? (
        <>
          <DiversityReportSummary
            supplierReportPage
            qualifiedSpend={selectedTotalDiverseSpend}
            totalSpend={selectedTotalSpend}
            mbeCount={
              supplierCountByCategory.mbe
                ? supplierCountByCategory.mbe.supplierCount
                : 0
            }
            sbeCount={
              supplierCountByCategory.sbe
                ? supplierCountByCategory.sbe.supplierCount
                : 0
            }
            wbeCount={
              supplierCountByCategory.wbe
                ? supplierCountByCategory.wbe.supplierCount
                : 0
            }
            vbeCount={
              supplierCountByCategory.vbe
                ? supplierCountByCategory.vbe.supplierCount
                : 0
            }
            mbeSpend={
              supplierCountByCategory.mbe
                ? supplierCountByCategory.mbe.spendAmount
                : 0
            }
            sbeSpend={
              supplierCountByCategory.sbe
                ? supplierCountByCategory.sbe.spendAmount
                : 0
            }
            wbeSpend={
              supplierCountByCategory.wbe
                ? supplierCountByCategory.wbe.spendAmount
                : 0
            }
            vbeSpend={
              supplierCountByCategory.vbe
                ? supplierCountByCategory.vbe.spendAmount
                : 0
            }
          />
          <Divider className='mv3' />
          <Paper noPadding>
            <Table
              rowGetter={({ index }) => spendByCategory[index]}
              rowCount={spendByCategory.length}
            >
              <Column label='Type' dataKey='name' width={600} />
              <Column
                label='Qualified Diverse Spend'
                dataKey='spendAmount'
                className='tr'
                headerClassName='tr'
                cellRenderer={({ cellData }) =>
                  intl.formatNumber(cellData, {
                    currency: 'USD',
                    style: 'currency',
                    minimumFractionDigits: 0
                  })
                }
                width={200}
              />
              <Column
                label='%'
                dataKey='spendPercentage'
                width={100}
                headerClassName='tr'
                className='tr'
                cellRenderer={({ cellData }) =>
                  intl.formatNumber(cellData, {
                    style: 'percent',
                    maximumFractionDigits: 2
                  })
                }
              />
            </Table>
          </Paper>
        </>
      ) : (
        <div className='h5'>
          <Text>
            <FormattedMessage
              id='Report.NoDiverseSpend'
              defaultMessage='There is no diverse spend.'
            />
          </Text>
        </div>
      )}
    </>
  )
})

const ReportContainer = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadData(tier2DiverseSpendData))
  }, [dispatch])

  const isLoadingData = useSelector<RootState, boolean>(state =>
    dataSelectors.isLoading(state, 'tier2/diverse')
  )
  const overviewSpendData = useSelector<
    RootState,
    OverviewSpendItem[] | undefined
  >(state => {
    const data = dataSelectors.getByEntity(state, 'tier2/diverse')
    return data.size > 0 && data.toJS()
  })

  if (isLoadingData) {
    return <Loading />
  }

  return !!overviewSpendData ? (
    <Report overviewSpendData={overviewSpendData} />
  ) : (
    <Text className='mt3'>
      <FormattedMessage
        id='Report.NoDataMessage'
        defaultMessage='You need to publish at least one quarter of data to see your report..'
      />
    </Text>
  )
}

export default ReportContainer
