import React, { Component, ReactElement } from 'react'
import FileInput from '../FileInput'
import Label from '../Label'
import Avatar from '../Avatar'
import AvatarEditor from 'react-avatar-editor'
import Button from '../Button'
import Text from '../Text'
import { FormattedMessage } from 'react-intl'
import { dataURLToBlob } from 'blob-util'

const reader: FileReader = new FileReader()
const INITIAL_SCALE: number = 500

const styles = {
  image: 'mr3 v-mid w3 dib',
  fileInput: 'bn ph0',
  fileInputWrapper: 'f6 v-mid w-100 dib'
}

type Props = {
  limit: number
  accept: string
  imageUrl?: string
  avatar?: boolean
  logo?: boolean
  cropWidth?: number
  cropHeight?: number
  noResize?: boolean
  submitOnCrop?: boolean
  imageFormat?: 'image/png' | 'image/jpeg'
  imageQuality?: number
  solidBackground?: boolean
  actionButtonLabel?: string | ReactElement<FormattedMessage>
  onOpenCropper?: () => void
  onCrop?: () => void
  onCancel?: () => void
  value?: string
  onChange?: (file: File | string | Blob | null) => void
  input?: {
    onChange: (file: File | string | null | Blob) => void
    value?: string
  }
}

type State = {
  scale: number
  newImageUrl: string | null | ArrayBuffer
  cropperOpen: boolean
  cancelCount: number
}

class ImageFileInput extends Component<Props, State> {
  private editor

  static defaultProps = {
    limit: 1,
    accept: '.png,.jpg,.jpeg,.svg',
    cropWidth: 300,
    cropHeight: 300,
    actionButtonLabel: 'Crop',
    imageFormat: 'image/png',
    imageQuality: 1
  }

  state = {
    scale: INITIAL_SCALE,
    newImageUrl: '',
    cropperOpen: false,
    cancelCount: 0
  }

  handleFileChange = file => {
    const { onOpenCropper } = this.props

    if (file) {
      reader.onloadend = () => {
        this.setState({
          newImageUrl: reader.result,
          cropperOpen: true
        })
        if (onOpenCropper) {
          onOpenCropper()
        }
      }
      reader.readAsDataURL(file)
    }
  }

  handleSlide = e => {
    this.setState({
      scale: e.target.value
    })
  }

  handleCrop = () => {
    const {
      imageFormat,
      imageQuality,
      solidBackground,
      noResize,
      onCrop
    } = this.props

    const canvas: HTMLCanvasElement = noResize
      ? this.editor.getImage()
      : this.editor.getImageScaledToCanvas()

    const dataUrl = solidBackground
      ? this.canvasWithBackground(canvas).toDataURL(imageFormat, imageQuality)
      : canvas.toDataURL(imageFormat, imageQuality)

    this.triggerChange(dataUrl)
    this.setState(
      {
        cropperOpen: false,
        newImageUrl: dataUrl
      },
      () => {
        if (onCrop) {
          onCrop()
        }
      }
    )
  }

  canvasWithBackground(canvas): HTMLCanvasElement {
    const w = canvas.width
    const h = canvas.height
    const context = canvas.getContext('2d')

    //set to draw behind current content
    context.globalCompositeOperation = 'destination-over'

    //set background color
    context.fillStyle = 'rgba(255,255,255,1)'

    //draw background / rect on entire canvas
    context.fillRect(0, 0, w, h)

    return canvas
  }

  handleImageReady = () => {
    const dataUrl = this.editor
      .getImageScaledToCanvas()
      .toDataURL(this.props.imageFormat, this.props.imageQuality)
    this.triggerChange(dataUrl)
  }

  triggerChange = (dataURI: string | null) => {
    const { input, onChange } = this.props

    if (dataURI) {
      const blob = dataURLToBlob(dataURI)
      const newFile = new Blob([blob], {
        type: blob.type
      })
      newFile['filename'] = 'backgroundPicture.jpeg'

      if (input && input.onChange) {
        input.onChange(newFile)
      }

      if (onChange) {
        onChange(newFile)
      }
    } else {
      if (input && input.onChange) {
        input.onChange(dataURI)
      }

      if (onChange) {
        onChange(dataURI)
      }
    }
  }

  handleCancel = () => {
    const { onCancel } = this.props

    this.triggerChange(null)
    this.setState({
      scale: INITIAL_SCALE,
      cropperOpen: false,
      cancelCount: this.state.cancelCount + 1,
      newImageUrl: ''
    })

    if (onCancel) {
      onCancel()
    }
  }

  setEditorRef = (editor: any) => {
    this.editor = editor
  }

  render() {
    const { newImageUrl, cropperOpen, scale, cancelCount } = this.state
    const {
      imageUrl,
      avatar,
      logo,
      cropWidth,
      cropHeight,
      noResize,
      actionButtonLabel,
      imageFormat,
      imageQuality,
      onChange,
      onCrop,
      onOpenCropper,
      submitOnCrop,
      solidBackground,
      ...rest
    } = this.props

    return (
      <div>
        {!cropperOpen &&
          (avatar ? (
            <Avatar
              url={newImageUrl && !cropperOpen ? newImageUrl : imageUrl}
              className={styles.image}
            />
          ) : newImageUrl || imageUrl ? (
            <img src={newImageUrl || imageUrl} alt='Cover' className='w5' />
          ) : null)}

        {cropperOpen && (
          <div className={'mt2'}>
            <div className={`${logo ? 'w5' : 'w-100'} center tc`}>
              <AvatarEditor
                ref={this.setEditorRef}
                image={newImageUrl}
                border={3}
                scale={scale / INITIAL_SCALE}
                width={cropWidth}
                height={cropHeight}
                style={{
                  width: '100%',
                  height: 'auto'
                }}
                onImageReady={this.handleImageReady}
              />
            </div>
            <div className='w-100'>
              <Text>
                <FormattedMessage
                  id='ImageFileInput.DragThePreviewToPositonTheImage'
                  defaultMessage='Drag the preview to position the image'
                />
              </Text>
              <Label>
                <FormattedMessage
                  id='ImageFileInput.Scale'
                  defaultMessage='Scale'
                />
              </Label>
              <input
                type='range'
                defaultValue={INITIAL_SCALE.toString()}
                min={50}
                max={1000}
                onChange={this.handleSlide}
                className='w-100'
              />
            </div>
          </div>
        )}

        <FileInput
          small
          key={cancelCount}
          wrapperClassName={styles.fileInputWrapper}
          className={styles.fileInput}
          onChange={this.handleFileChange}
          {...rest}
        />

        {(cropperOpen || submitOnCrop) && (
          <div className='mv3'>
            <Button
              label={actionButtonLabel}
              onClick={this.handleCrop}
              autoSize
              className='mr3'
              disabled={!this.editor}
            />

            <Button
              label={<FormattedMessage id='CancelButton' />}
              onClick={this.handleCancel}
              autoSize
              secondary
            />
          </div>
        )}
      </div>
    )
  }
}

export default ImageFileInput
