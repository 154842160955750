import React, { FC } from 'react'
import { connect } from 'react-redux'
import statsSelectors from '../../../shared/selectors/statsSelectors'
import Text from 'shared/components/Text'
import Scrollable from 'shared/components/Scrollable'
import { FormattedMessage } from 'react-intl'
import { Map } from 'immutable'
import ViewCount from './ViewCount'

type Props = {
  viewDetails: Map<string, Map<string, number>>
}

export const ViewDetails: FC<Props> = ({ viewDetails }) => {
  return viewDetails && viewDetails.size > 0 ? (
    <div className='pa3'>
      <Scrollable>
        <ViewCount
          label={
            <FormattedMessage id='ViewDetails.Today' defaultMessage='Today' />
          }
          viewValue={viewDetails.get('today')}
        />
        <ViewCount
          label={
            <FormattedMessage
              id='ViewDetails.Yesterday'
              defaultMessage='Yesterday'
            />
          }
          viewValue={viewDetails.get('yesterday')}
        />
        <ViewCount
          label={
            <FormattedMessage
              id='ViewDetails.Last7Dats'
              defaultMessage='Last 7 Days'
            />
          }
          viewValue={viewDetails.get('last7Days')}
        />
        <ViewCount
          label={
            <FormattedMessage
              id='ViewDetails.Last30Days'
              defaultMessage='Last 30 Days'
            />
          }
          viewValue={viewDetails.get('last30Days')}
        />
        <ViewCount
          label={
            <FormattedMessage
              id='ViewDetails.Last90Days'
              defaultMessage='Last 90 Days'
            />
          }
          viewValue={viewDetails.get('last90Days')}
        />
      </Scrollable>
    </div>
  ) : (
    <Text className='pa3'>
      <FormattedMessage
        id='ViewDetails.NotAdditionalData'
        defaultMessage='No profile views in the last 90 days'
      />
    </Text>
  )
}

export default connect(state => ({
  viewDetails: statsSelectors.getBuyerViewDetails(state)
}))(ViewDetails)
