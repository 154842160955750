import React, { Fragment, ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import SpendOverviewData from './SpendOverviewData'
import { connect } from 'react-redux'
import {
  setOnlyDiverse,
  setOnlyPreferred,
  setUseSpendGroup
} from '../../store/actions'
import insightsSelectors from '../../store/insightsSelectors'
import Select from 'shared/components/Select'
import Label from 'shared/components/Label'

type Props = {
  diverse: boolean
  preferred: boolean
  useSpendGroup: boolean
  setOnlyDiverse: (diverse: boolean) => void
  setOnlyPreferred: (preferred: boolean) => void
  setUseSpendGroup: (useSpendGroup: boolean) => void
}

export const YearSpendOverview = (props: Props) => {
  return (
    <div className='mt3'>
      <Fragment>
        <div className='w-100 w-50-ns dib v-top pr2-ns'>
          <SpendOverviewData
            customTitle={
              <div>
                <Label
                  htmlFor={`sort${
                    props.useSpendGroup ? 'spendgroup' : 'category'
                  }`}
                  className='f7 mr1'
                >
                  <FormattedMessage
                    id='YearSpendOverview.SortedBy'
                    defaultMessage='Sorted by: '
                  />
                </Label>
                <Select
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    props.setUseSpendGroup(
                      e.currentTarget.value === 'spendgroup'
                    )
                  }
                  value={props.useSpendGroup ? 'spendgroup' : 'category'}
                  id={`sort${props.useSpendGroup ? 'spendgroup' : 'category'}`}
                  name={`sort${
                    props.useSpendGroup ? 'spendgroup' : 'category'
                  }`}
                >
                  <FormattedMessage
                    id='YearSpendOverview.SpendCategories'
                    defaultMessage='Spend Categories'
                  >
                    {message => <option value='category'>{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage
                    id='YearSpendOverview.BusinessUnits'
                    defaultMessage='Business Units'
                  >
                    {message => <option value='spendgroup'>{message}</option>}
                  </FormattedMessage>
                </Select>
              </div>
            }
            type={props.useSpendGroup ? 'spendgroup' : 'category'}
          />
        </div>
        <div className='w-100 w-50-ns dib v-top pl2-ns mt3 mt0-ns'>
          <SpendOverviewData
            type='country'
            countryTitle={
              <Label className='f7 pv2 bt bb b--transparent'>
                <FormattedMessage
                  id='SpendOverviewPie.SpendCountries'
                  defaultMessage='Spend Countries'
                />
              </Label>
            }
          />
        </div>
      </Fragment>
    </div>
  )
}

export default connect(
  state => ({
    preferred: !!insightsSelectors.getSpendField(state, 'onlyPreferred'),
    diverse: !!insightsSelectors.getSpendField(state, 'onlyDiverse'),
    useSpendGroup: !!insightsSelectors.getSpendField(state, 'useSpendGroup')
  }),
  { setOnlyDiverse, setOnlyPreferred, setUseSpendGroup }
)(YearSpendOverview)
