import React, { Component } from 'react'
import Text from 'shared/components/Text'
import DialogContactUsContainer from 'shared/containers/DialogContactUsContainer'
import { Field } from 'redux-form/immutable'
import Input from 'shared/components/Input'
import DatePicker from 'shared/components/DatePicker'
import { FormattedMessage } from 'react-intl'

type Props = {
  open: boolean
  initialValues: { sendTo: string }
  showConcierge: string
  onRequestClose: () => void
}
class VetContactUsDialog extends Component<Props> {
  render() {
    const { initialValues, showConcierge, onRequestClose } = this.props

    if (showConcierge === 'hide') {
      return null
    }

    return (
      <DialogContactUsContainer
        open={this.props.open}
        onRequestClose={onRequestClose}
        additionalInitialValues={showConcierge === 'yes' ? initialValues : {}}
      >
        {showConcierge === 'yes' && (
          <div>
            <div className='mb3'>
              <div className='pb2 fw6 f7'>
                <FormattedMessage
                  id='VetContactUsDialog.NameOfRFI'
                  defaultMessage='Name of RFI'
                />
              </div>
              <FormattedMessage
                id='VetContactUsDialog.UntitledRFI'
                defaultMessage='Untitled RFI'
              >
                {message => (
                  <Field
                    name='nameOfVet'
                    component={Input}
                    maxLength={150}
                    type='text'
                    placeholder={message as string}
                    required
                  />
                )}
              </FormattedMessage>
            </div>
            <div className='mb3'>
              <div className='pb2 fw6 f7'>
                <FormattedMessage
                  id='VetContactUsDialog.Summary'
                  defaultMessage='Summary'
                />
              </div>
              <FormattedMessage
                id='VetContactUsDialog.SummaryPlaceholder'
                defaultMessage='Summary'
              >
                {message => (
                  <Field
                    name='summary'
                    component={Input}
                    maxLength={500}
                    type='text'
                    placeholder={message as string}
                    required
                  />
                )}
              </FormattedMessage>
            </div>
            <div className='mb3'>
              <div className='pb2 fw6 f7'>
                <FormattedMessage
                  id='VetContactUsDialog.Deadline'
                  defaultMessage='Deadline'
                />
              </div>
              <Field
                name='deadline'
                component={DatePicker}
                disablePast
                minDateMessage={
                  <FormattedMessage
                    id='VetContactUsDialog.DeadlineMessage'
                    defaultMessage='Deadline should be in the future'
                  />
                }
              />
            </div>
            <div className='mb3'>
              <div className='pb2 fw6 f7'>
                <FormattedMessage
                  id='VetContactUsDialog.Availability'
                  defaultMessage='Availability'
                />
              </div>
              <FormattedMessage
                id='VetContactUsDialog.AvailabilityPlaceholder'
                defaultMessage='i.e. July 20 between 12 and 2 pm EST'
              >
                {message => (
                  <Field
                    name='availability'
                    component={Input}
                    type='text'
                    placeholder={message as string}
                    required
                  />
                )}
              </FormattedMessage>
              <div className='pt2'>
                <Text>
                  <FormattedMessage
                    id='VetContactUsDialog.AvailabilityTimes'
                    defaultMessage='Our available hours are between Monday to Friday, 9am-5pm EST'
                  />
                </Text>
              </div>
            </div>
          </div>
        )}
      </DialogContactUsContainer>
    )
  }
}

export default VetContactUsDialog
