import { combineReducers } from 'redux-immutable'
import companyReducers from './Company/reducers/rootReducer'
import company from './shared/reducers/companyReducer'
import stats from './shared/reducers/statsReducer'
import vets from './Vets/reducers/vetsReducer'
import currentVet from './Vets/reducers/currentVetReducer'
import communities from './Communities/reducers/communitiesReducer'
import createInviteUserReducer from 'shared/utils/inviteUser/createReducer'
import { ADD_VET_CONTACT } from './Vets/actionTypes'
import tier2Reducer from './Insight/store/tier2Reducer'
import survey from './Survey/reducers/surveyReducer'
import newSurveyCollaborateContact from './shared/reducers/newCollaborateContactReducer'

const rootReducer = combineReducers({
  ...companyReducers,
  stats,
  company,
  vets,
  currentVet,
  communities,
  tier2: tier2Reducer,
  inviteVetPrimaryContact: createInviteUserReducer(
    'invitePrimaryContact',
    ADD_VET_CONTACT
  ),
  survey,
  newSurveyCollaborateContact
})

export default rootReducer
