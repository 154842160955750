import React, { FC } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import insightsSelectors from '../../store/insightsSelectors'
import RootState from 'shared/models/RootState'
import { List, RecordOf } from 'immutable'
import InsightTitle from 'shared/components/InsightTitle'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import Table from 'shared/components/Table'
import { Column } from 'react-virtualized'
import exportData from 'shared/utils/exportData'
import parsePath from 'shared/utils/parsePath'
import paths from '../../../SupplierProfile/routes'
import Text from 'shared/components/Text'

type Props = {
  internalIdsUnMatchedList: List<string>
  topResults: List<
    RecordOf<{
      id: string
      name: string
      domains: List<string>
      matchedInternalIds: List<string>
      recentAmount: null | number
    }>
  >
  unMappedSupplierId: string
} & RouteComponentProps

export const TopConsolidatedProfiles: FC<Props> = ({
  topResults,
  internalIdsUnMatchedList,
  unMappedSupplierId,
  history: { push }
}) => {
  return (
    <>
      {topResults && topResults.size > 0 && (
        <>
          <div className='flex justify-between items-center mt4'>
            <InsightTitle>
              <FormattedMessage
                id='ConsolidatedProfiles.TopResults'
                defaultMessage='Suppliers with Multiple Supplier IDs'
              />
            </InsightTitle>
            <Button
              aria-label='Export Suppliers with Multiple Supplier IDs'
              onClick={() => {
                exportData.exportCSV(
                  topResults.toJS().map(({ recentAmount, ...rest }) => ({
                    ...rest,
                    spend: recentAmount === null ? 'Unavailable' : recentAmount
                  })),
                  'suppliers-with-multiple-supplier-ids'
                )
              }}
            >
              <FormattedMessage
                id='Top100ConsolidatedProfiles.Export'
                defaultMessage='Export'
              />
            </Button>
          </div>
          <div className='bg-white br1 ba b--black-10'>
            <Table
              rowGetter={({ index }) => topResults.get(index)}
              rowCount={topResults.size}
              onRowClick={({ rowData }) => {
                push(
                  `${parsePath(paths.myCompany, {
                    supplierId: rowData.get('id')
                  })}?scrollToAttribute=true`
                )
              }}
            >
              <Column
                label={
                  <FormattedMessage
                    id='Top100ConsolidatedProfiles.Name'
                    defaultMessage='Name'
                  />
                }
                dataKey='name'
                width={250}
                cellRenderer={({ rowData }) => (
                  <>
                    <div>{rowData.get('name')}</div>
                    <Text secondary>{rowData.get('domains').join(', ')}</Text>
                  </>
                )}
              />
              <Column
                label={
                  <FormattedMessage
                    id='Top100ConsolidatedProfiles.NumberSupplierIds'
                    defaultMessage='# Matches'
                  />
                }
                dataKey='matchedInternalIds'
                width={90}
                cellRenderer={({ cellData }) =>
                  `${cellData ? cellData.size.toLocaleString() : ''}`
                }
              />
              <Column
                label={
                  <FormattedMessage
                    id='Top100ConsolidatedProfiles.Spend'
                    defaultMessage='Spend'
                  />
                }
                dataKey='recentAmount'
                width={150}
                cellRenderer={({ cellData }) =>
                  cellData === null ? (
                    <FormattedMessage
                      id='Top100ConsolidatedProfiles.Unavailable'
                      defaultMessage='Unavailable'
                    />
                  ) : (
                    `$${cellData.toLocaleString()}`
                  )
                }
              />
              <Column
                label={
                  <FormattedMessage
                    id='Top100ConsolidatedProfiles.SupplierIds'
                    defaultMessage='Supplier IDs'
                  />
                }
                dataKey='matchedInternalIds'
                width={600}
                cellRenderer={({ cellData, rowIndex }) => (
                  <span>{cellData && cellData.join(', ')}</span>
                )}
              />
            </Table>
          </div>
        </>
      )}
      {internalIdsUnMatchedList && internalIdsUnMatchedList.size > 0 && (
        <>
          <div className='flex justify-between items-center mt4'>
            <InsightTitle>
              <FormattedMessage
                id='Top100ConsolidatedProfiles.UnMatched'
                defaultMessage='Unmatched Supplier IDs'
              />
            </InsightTitle>
            <Button
              aria-label='Export Unmatched Supplier IDs'
              onClick={() => {
                exportData.exportCSV(
                  internalIdsUnMatchedList
                    .toJS()
                    .sort()
                    .map(id => ({ unmatchedId: id })),
                  'unmatched-ids'
                )
              }}
            >
              <FormattedMessage id='Top100ConsolidatedProfiles.Export' />
            </Button>
          </div>
          <div className='bg-white br1 ba b--black-10'>
            <Table
              rowGetter={({ index }) => internalIdsUnMatchedList}
              rowCount={1}
              onRowClick={({ rowData }) => {
                push(
                  `${parsePath(paths.myCompany, {
                    supplierId: unMappedSupplierId
                  })}?scrollToAttribute=true`
                )
              }}
            >
              <Column
                label={
                  <FormattedMessage
                    id='Top100ConsolidatedProfiles.NumberUnmatched'
                    defaultMessage='# Unmatches'
                  />
                }
                width={100}
                dataKey='matchedInternalIds'
                cellRenderer={({ cellData }) =>
                  `${
                    internalIdsUnMatchedList
                      ? internalIdsUnMatchedList.size.toLocaleString()
                      : ''
                  }`
                }
              />
              <Column
                label={
                  <FormattedMessage id='Top100ConsolidatedProfiles.SupplierIds' />
                }
                width={900}
                dataKey='matchedInternalIds'
                cellRenderer={({ cellData, rowIndex }) => (
                  <span>
                    {internalIdsUnMatchedList &&
                      internalIdsUnMatchedList.join(', ')}
                  </span>
                )}
              />
            </Table>
          </div>
        </>
      )}
    </>
  )
}

export default connect((state: RootState) => {
  const consolidation = insightsSelectors.getConsolidation(state)

  return {
    topResults: consolidation && consolidation.get('topResults'),
    unMappedSupplierId:
      consolidation && consolidation.get('unMappedSupplierId'),
    internalIdsUnMatchedList:
      consolidation && consolidation.get('internalIdsUnMatchedList')
  }
})(withRouter(TopConsolidatedProfiles))
