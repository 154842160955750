import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select, put } from 'redux-saga/effects'
import users from 'shared/utils/api/users'
import supplier from 'shared/utils/api/supplier'
import newContactSelectors from '../../selectors/newContactSelectors'
import companySelectors from '../../../shared/selectors/companySelectors'
import reverseParsedUser from 'shared/utils/data/reverseParsedUser'
import FormError from 'shared/utils/FormError'
import { messageUser } from 'shared/actions'
import { fromJS } from 'immutable'

//2 emails: users.invite api calls email method and sends optional message, messageUser action sends email with optional message

export function* addContactSaga(action) {
  let { user, contactFor, message, addingMyself } = action.payload.toJS()
  const isCreatingNewUser = yield select(newContactSelectors.isCreatingNewUser)
  const orgUnitId = yield select(companySelectors.getCompanyField, 'id')

  // need to create non-existing users
  if (isCreatingNewUser) {
    if (!user.email) {
      throw new FormError('Please select a user')
    }
    // creating a new user, need to convert location (string) to location (object)
    if (user.location) {
      user.location = { address: user.location }
    }
    user = yield call(users.inviteUser, {
      user: reverseParsedUser(user),
      orgUnitId,
      message
    })
    // user.id = newUser.id
  }

  yield call(supplier.addContact, { userId: user.id, contactFor })

  if (!addingMyself) {
    yield put(
      messageUser(
        fromJS({
          userToMessageId: user.id,
          message,
          orgUnitId
        })
      )
    )
  }

  return yield {
    user,
    contactFor,
    isCreatingNewUser
  }
}

export default createApiRequestSaga(addContactSaga)
