import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import FilterHOC from '../FilterHOC'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import searchSelectors from '../../selectors/searchSelectors'
import RootState from '../../../../shared/models/RootState'
import FilterCheckboxListContainer from '../FilterCheckboxListContainer'
import { List, Map, RecordOf } from 'immutable'
import Divider from '../../../../shared/components/Divider'
import ColleagueSearchContainer from '../ColleagueSearchContainer'
import usersSelectors from '../../../../shared/selectors/usersSelectors'
import User from '../../../../shared/models/User'

type Props = {
  removeFilterOption: (
    param: { key: string; value: string },
    meta?: { fuzzyMatch: string }
  ) => void
  onChangeFilterOption: (
    param: { key: string; value: string } | { key: string; value: object }
  ) => void
  filters: Map<string, List<string | List<string>>>
  scope: string
  showSkeleton?: boolean
  allColleagues: List<RecordOf<User>>
  category?: string
}

type State = {
  active: string
  colleague: List<{ value: string; label: string }>
}

export class FilterScopeContainer extends Component<Props, State> {
  constructor(props) {
    super(props)
    const { scope, filters, allColleagues } = this.props
    let initialColleague = List()
    filters.get('connectedUserId')?.forEach(id => {
      let foundUser = allColleagues.get(
        allColleagues.findIndex(i => i.get('id') === id)
      )
      initialColleague = initialColleague.push({
        value: id,
        label: foundUser
          ? foundUser.get('firstName') + ' ' + foundUser.get('lastName')
          : ''
      })
    })

    this.state = {
      active: scope,
      colleague: initialColleague
    }
  }

  handleOnChange = v => {
    const {
      onChangeFilterOption,
      removeFilterOption,
      filters,
      scope
    } = this.props
    const { active, colleague } = this.state
    const value =
      active === 'All'
        ? v.key === 'connectedUserId'
          ? 'ColleagueConnections'
          : v.value
        : (active === 'ColleagueConnections' &&
            (v.key === 'connectedUserId' || scope === 'All')) ||
          (active === 'MyConnections' && v.key === 'ColleagueConnections')
        ? 'ColleagueConnections'
        : 'All'
    const colleagueObject = colleague.get(
      colleague.findIndex(c => c.value === v.key)
    )
    this.setState(
      {
        // reset colleague state
        active: value,
        colleague: List()
      },
      function() {
        if (colleagueObject && filters.has('connectedUserId')) {
          // if a colleague filter is being deselected
          onChangeFilterOption({
            key: 'connectedUserId',
            value: colleagueObject.value
          })
          removeFilterOption({
            key: 'connectedUserId',
            value: colleagueObject.value
          })
        } else {
          if (v.key === 'connectedUserId') {
            // if a colleague filter is being selected
            onChangeFilterOption({
              key: 'scope',
              value: 'ColleagueConnections'
            })
          }
        }

        if (v.key === 'scope') {
          if (v.value === 'ColleagueConnections') {
            // selecting ColleagueConnections filter, clear out colleagues
            colleague.forEach(c => {
              onChangeFilterOption({
                key: 'connectedUserId',
                value: c.value
              })
              removeFilterOption({
                key: 'connectedUserId',
                value: c.value
              })
            })
          }
          onChangeFilterOption({ key: v.key, value: value })
        }
      }
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      const { filters, allColleagues } = this.props

      this.setState({
        colleague: List()
      })

      if (
        filters &&
        filters.has('connectedUserId') &&
        filters.get('connectedUserId')!.size > 0
      ) {
        let colleague = List()
        filters.get('connectedUserId')?.forEach(id => {
          let foundUser = allColleagues.get(
            allColleagues.findIndex(i => i.get('id') === id)
          )
          this.setState({
            colleague: colleague.push({
              value: id,
              label: foundUser
                ? foundUser.get('firstName') + ' ' + foundUser.get('lastName')
                : ''
            })
          })
        })
      }
    }
  }

  render() {
    const { scope, filters, category } = this.props
    const { active, colleague } = this.state
    let scopes = List([
      { value: 'ColleagueConnections', label: "My Colleague's Suppliers" },
      { value: 'MyConnections', label: 'My Suppliers' }
    ])
    if (
      (active !== 'All' && scope !== 'All') ||
      (colleague.size > 0 && filters.has('connectedUserId'))
    ) {
      scopes = scopes.remove(scopes.findIndex(s => s.value !== active))
    }

    return (
      <Fragment>
        <FilterCheckboxListContainer
          filterKey='scope'
          list={scopes}
          onChange={this.handleOnChange}
          selected={List([
            colleague.size > 0 && filters.has('connectedUserId')
              ? active
              : scope
          ])}
          category={category}
        />
        {colleague.size > 0 && filters.has('connectedUserId') && (
          <Fragment>
            <Divider className='mv1' />
            <div className='pl2'>
              <FilterCheckboxListContainer
                filterKey='connectedUserId'
                list={colleague}
                onChange={this.handleOnChange}
                selected={colleague.map(item => item.value)}
                category={category}
              />
            </div>
          </Fragment>
        )}
        {(colleague.size === 0 || !filters.has('connectedUserId')) &&
          (active === 'All' ||
            active === 'ColleagueConnections' ||
            scope === 'All') && (
            <Fragment>
              <Divider className='mv2' />
              <ColleagueSearchContainer onChange={this.handleOnChange} />
            </Fragment>
          )}
      </Fragment>
    )
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    filters: filterOptionsSelectors.getFilterOptions(state),
    scope: filterOptionsSelectors.getFilterScope(state),
    allColleagues: usersSelectors.getColleagues(state) || List([]),
    showSkeleton: searchSelectors.isAggregating(state),
    selected: filterOptionsSelectors.getFilterScope(state)
  }
}

export default connect(mapStateToProps, FilterHOC)(FilterScopeContainer)
