import React, { ReactElement, useState } from 'react'
import CategoriesJsonEditor from '../CategoriesJsonEditor'
import JsonSettings from '../JsonSettings'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'

type Props = {
  label: string | ReactElement<FormattedMessage>
  description?: string | ReactElement<FormattedMessage>
  fieldName: string
  value: string
  onUpdate: (settings: { [key: string]: any }) => void
  isReadonly?: boolean
}

const CategoriesSetting = (props: Props) => {
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false)

  return (
    <>
      <div className='flex items-center justify-between'>
        <Label noPadding>{props.label}</Label>
        <Button
          autoSize
          secondary
          size='small'
          onClick={() => setIsAdvanced(!isAdvanced)}
        >
          {isAdvanced ? (
            <FormattedMessage id='Friendly' defaultMessage='Friendly' />
          ) : (
            <FormattedMessage id='Advanced' defaultMessage='Advanced' />
          )}
        </Button>
      </div>
      {isAdvanced ? (
        <JsonSettings
          fieldName={props.fieldName}
          description={props.description}
          value={props.value}
          onUpdate={props.onUpdate}
          isReadOnly={props.isReadonly}
        />
      ) : (
        <CategoriesJsonEditor
          fieldName={props.fieldName}
          value={props.value}
          onUpdate={props.onUpdate}
          isReadonly={props.isReadonly}
        />
      )}
    </>
  )
}

export default CategoriesSetting
