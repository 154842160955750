import React, { Component } from 'react'
import checkMark from 'shared/assets/icons/check.svg'
import classNames from 'classnames'

const styles = {
  wrapper: 'pv2',
  pageTitle: 'f6',
  icon: 'w1 mr2 dib',
  currentPage: 'fw6 dark-gray',
  notCurrentPage: 'gray'
}

type Props = {
  title: string
  currentPage: boolean
  completed: boolean
  missingRequired: boolean
}

class CommunityFormProgressItem extends Component<Props> {
  render() {
    const { title, currentPage, completed, missingRequired } = this.props

    const titleClassName = classNames(
      styles.pageTitle,
      currentPage ? styles.currentPage : styles.notCurrentPage
    )

    return (
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          {completed && <img src={checkMark} alt='check' className='v-mid' />}
        </div>
        <span className={titleClassName}>{title}</span>{' '}
        {missingRequired && <span className='red'>*</span>}
      </div>
    )
  }
}

export default CommunityFormProgressItem
