import { connect } from 'react-redux'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import Outcome from '../../components/Outcome'
import camelCase from 'lodash.camelcase'
import RootState from 'shared/models/RootState'

type ContainerProps = {
  vetBuyerSupplierId: string
}

export default connect((state: RootState, props: ContainerProps) => {
  const status = currentVetSuppliersSelectors.getVetSupplierStatus(
    state,
    props.vetBuyerSupplierId
  )
  const vetSupplier = currentVetSuppliersSelectors.getVetSupplierById(
    state,
    props.vetBuyerSupplierId
  )
  const buyerResponseComment =
    vetSupplier &&
    vetSupplier.getIn(['vetBS', 'collaboration', 'buyerResponseComment'])
  const buyerResponseNextSteps =
    vetSupplier &&
    vetSupplier.getIn(['vetBS', 'collaboration', 'buyerResponseNextSteps'])
  const userId =
    (status === 'Chosen' || status === 'Not Chosen') &&
    vetSupplier &&
    (vetSupplier.getIn(['vetBS', camelCase(status), 'user']) ||
      sessionSelectors.getUserId(state))

  return {
    status,
    buyerResponseComment,
    buyerResponseNextSteps,
    userId
  }
})(Outcome)
