/*
 *       {
 *         id: string
 *         createdDate: Date
 *         createdBy: string
 *         body: string
 *         isPrivate: boolean
 *       }
 */

const parseMessage = ({
  id,
  isPrivate,
  created: { user, date },
  body,
  ...rest
}) => {
  return {
    id,
    createdDate: new Date(date),
    createdBy: user,
    body,
    isPrivate,
    ...rest
  }
}

export default parseMessage
