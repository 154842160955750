import sessionSelectors from 'shared/selectors/sessionSelectors'
import Loader from '../../components/Loader'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { LOAD_CLIENT_DATA } from '../../actionTypes'
import { getSpendYears } from '../../../buyer/Insights/store/actions'
import insightsSelectors from 'buyer/Insights/store/insightsSelectors'
import { formValueSelector } from 'redux-form/immutable'

const formName = 'admin/loader'
const selector = formValueSelector(formName)
export default connectReduxForm(
  state => {
    const isTealbot = sessionSelectors.userHasRole(state, 'tealbot')
    return {
      isTealbot,
      initialValues: isTealbot
        ? { deleteSpendLineItems: false, enableNewLoader: false }
        : { enableNewLoader: false },
      minSpendDate: insightsSelectors.getMinSpendDate(state),
      maxSpendDate: insightsSelectors.getMaxSpendDate(state),
      enableNewLoader: selector(state, 'enableNewLoader'),
      yearEnd: state.getIn(['buyer', 'settings', 'yearEnd'])
    }
  },
  {
    onSubmit: createSubmitFormAction(formName, LOAD_CLIENT_DATA),
    getSpendYears
  },
  {
    form: formName
  },
  Loader
)
