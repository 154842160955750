import { call } from 'redux-saga/effects'
import suppliers from 'shared/utils/api/suppliers'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import parseSupplier from '../../../../shared/utils/data/parseSupplier'
import parseSuggest from '../../../../shared/utils/data/parseSuggest'
import mapKeys from 'lodash.mapkeys'

export function* suggestSupplierSaga(action) {
  const response = yield call(suppliers.suggestSuppliers, {
    q: action.payload,
    limit: 10
  })

  return yield {
    key: action.payload,
    results: response,
    orgUnitsById: mapKeys(
      response.map(supplier => parseSupplier(parseSuggest(supplier))),
      orgUnit => orgUnit.id
    )
  }
}

export default createApiRequestSaga(suggestSupplierSaga)
