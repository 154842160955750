import React, { Component, ReactNode } from 'react'
import Avatar from '../Avatar'
import Divider from '../Divider'
import dateFormat from '../../utils/dateFormat'
import Link from 'shared/components/Link'
import truncate from 'lodash.truncate'
import Button from 'shared/components/Button'
import { Field } from 'redux-form/immutable'
import TextArea from '../TextArea'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import SelectPublicPrivate from 'shared/components/SelectPublicPrivate'
import iconPrivate from 'shared/assets/icons/private.svg'
import iconPublic from 'shared/assets/icons/public.svg'
import Tooltip from 'shared/components/Tooltip'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'

const styles = {
  wrapper: 'dt w-100 pv3 ph4-ns ph3',
  avatar: 'br-100 w-100',
  avatarWrapper: 'dtc w2',
  contentWrapper: 'dtc pl3 v-top',
  name: 'f8 fw6 ma0 mid-gray pointer lh-copy dib',
  date: 'f8 fw4 ma0 gray lh-copy dib ml2',
  comment: 'f8 fw4 ma0 mid-gray lh-copy mt1',
  editButton: 'fr',
  icon: 'ml2'
}

type Props = {
  date: Date
  profilePictureUrl?: string
  userProfileUrl: string
  firstName: string
  lastName: string
  comment: string
  isPrivate: boolean
  editMode: boolean
  onCancel?: () => void
  onEdit?: () => void
  onSubmit?: () => void
  onRemove?: () => void
  enablePublicPrivate: boolean
  customContent?: string | ReactNode
  customWrapper?: string
}

type State = {
  showMore: boolean
  removeNoteDialog: {
    message?: string
    isOpen: boolean
  }
}

class UserComment extends Component<Props, State> {
  static defaultProps = {
    comment: ''
  }

  state = {
    showMore: false,
    removeNoteDialog: {
      message: '',
      isOpen: false
    }
  }

  handleCloseDialogRequest = () => {
    this.setState({
      removeNoteDialog: {
        isOpen: false
      }
    })
  }

  handleCompanyRemoveNote = () => {
    const { onRemove } = this.props

    if (onRemove) {
      onRemove()
    }
    this.setState({
      removeNoteDialog: {
        isOpen: false
      }
    })
  }

  confirmDeletion = () => {
    this.setState({
      removeNoteDialog: {
        isOpen: true,
        message: `Are you sure you want to delete this?`
      }
    })
  }

  toggleShowMore = () => {
    this.setState({
      showMore: !this.state.showMore
    })
  }

  render() {
    const {
      profilePictureUrl,
      userProfileUrl,
      firstName,
      lastName,
      comment,
      isPrivate,
      date,
      editMode,
      onEdit,
      onCancel,
      onSubmit,
      onRemove,
      enablePublicPrivate,
      customContent,
      customWrapper
    } = this.props

    const {
      showMore,
      removeNoteDialog: { isOpen, message = '' }
    } = this.state

    return (
      <div>
        <div className={customWrapper ? customWrapper : styles.wrapper}>
          <div className={styles.avatarWrapper}>
            {userProfileUrl && (
              <Link to={userProfileUrl}>
                <Avatar
                  url={profilePictureUrl}
                  className={styles.avatar}
                  name={`${firstName || ''} ${lastName || ''}`}
                />
              </Link>
            )}
            {!userProfileUrl && <Avatar className={styles.avatar} />}
          </div>
          <div className={styles.contentWrapper}>
            {userProfileUrl && (
              <Link to={userProfileUrl}>
                <h5 className={styles.name}>
                  {firstName} {lastName}
                </h5>
              </Link>
            )}
            <h5 className={styles.date}>{dateFormat(date)}</h5>
            {onEdit &&
              enablePublicPrivate &&
              (isPrivate ? (
                <Tooltip
                  title={
                    <FormattedMessage
                      id='UserComments.Personal'
                      defaultMessage='Personal'
                    />
                  }
                >
                  <img
                    src={iconPrivate}
                    className={styles.icon}
                    alt='Private'
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <FormattedMessage
                      id='UserComments.MyCompany'
                      defaultMessage='My Company'
                    />
                  }
                >
                  <img src={iconPublic} className={styles.icon} alt='Public' />
                </Tooltip>
              ))}

            {onEdit && !editMode && (
              <Button
                label={
                  <FormattedMessage
                    id='UserComments.Edit'
                    defaultMessage='Edit'
                  />
                }
                secondary
                onClick={onEdit}
                size='small'
                autoSize
                className={styles.editButton}
              />
            )}
            {!editMode && (
              <div>
                {customContent || (
                  <Text className={styles.comment}>
                    {comment && comment.length > 150
                      ? truncate(comment, {
                          length: showMore ? comment.length : 150,
                          separator: /,? +/
                        })
                      : comment}
                    {comment.length > 150 && (
                      <Button
                        variant='text'
                        autoSize
                        size='small'
                        label={
                          showMore ? (
                            <FormattedMessage
                              id='UserComments.ReadLess'
                              defaultMessage='Read less'
                            />
                          ) : (
                            <FormattedMessage
                              id='UserComments.ReadMore'
                              defaultMessage='Read more'
                            />
                          )
                        }
                        onClick={this.toggleShowMore}
                      />
                    )}
                  </Text>
                )}
              </div>
            )}

            {editMode && !customContent && (
              <form onSubmit={onSubmit}>
                <Field name='comment' component={TextArea} required />
                <div className='cf'>
                  {onRemove && (
                    <Button
                      label={
                        <FormattedMessage
                          id='UserComments.Remove'
                          defaultMessage='Remove'
                        />
                      }
                      autoSize
                      className='fr mt3'
                      caution
                      size='small'
                      onClick={this.confirmDeletion}
                    />
                  )}
                  <Button
                    label={
                      <FormattedMessage
                        id='UserComments.Update'
                        defaultMessage='Update'
                      />
                    }
                    type='submit'
                    autoSize
                    size='small'
                    className='mt3 mr2'
                  />
                  <Button
                    label={<FormattedMessage id='CancelButton' />}
                    secondary
                    size='small'
                    type='reset'
                    autoSize
                    onClick={onCancel}
                  />
                  {enablePublicPrivate && (
                    <Field
                      name='isPrivate'
                      component={SelectPublicPrivate}
                      className='v-mid ml2'
                    />
                  )}
                </div>
              </form>
            )}
          </div>
        </div>
        <Divider />

        {onRemove && (
          <Dialog open={isOpen} onClose={this.handleCloseDialogRequest}>
            <DialogTitle>
              <FormattedMessage
                id='UserComments.Confirmation'
                defaultMessage='Confirmation'
              />
            </DialogTitle>

            <DialogContent className='mt3'>{message}</DialogContent>

            <DialogActions>
              <Button
                type='button'
                autoSize
                onClick={this.handleCompanyRemoveNote}
                label={
                  <FormattedMessage id='UserComments.Ok' defaultMessage='OK' />
                }
              />
            </DialogActions>
          </Dialog>
        )}
      </div>
    )
  }
}

export default UserComment
