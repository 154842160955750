import AddCertificationForm from '../../../components/DiversitySection/AddCertificationForm'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { ADD_CERTIFICATION } from '../../../actionTypes'
import { formValueSelector } from 'redux-form/immutable'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import RootState from 'shared/models/RootState'

const formName = 'supplier/company/addCertification'
const valueSelector = formValueSelector(formName)

export default connectReduxForm(
  (state: RootState) => {
    return {
      category: valueSelector(state, 'category'),
      subCategory: valueSelector(state, 'subCategory'),
      certAgency: valueSelector(state, 'certAgency'),
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      subTypes: valueSelector(state, 'subTypes'),
      qualifiedNaics: valueSelector(state, 'qualifiedNaics'),
      certifyingLocationKey: valueSelector(state, 'certifyingLocationKey'),
      primaryNaics: valueSelector(state, 'primaryNaics')
    }
  },
  {
    onSubmit: createSubmitFormAction(formName, ADD_CERTIFICATION)
  },
  {
    form: formName
  },
  AddCertificationForm
)
