import * as Sentry from '@sentry/browser'
import { List } from 'immutable'

const setup = () => {
  if (process.env.REACT_APP_ENV === 'prd') {
    Sentry.init({
      // debug: process.env.REACT_APP_ENV !== 'prd',
      dsn: 'https://1928a87416f648c7a23ff1c7587f2a21@sentry.io/3665503',
      environment: 'Production',
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'Non-Error promise rejection captured with value:'
        //   'TypeError: Failed to fetch',
        //   'TypeError: NetworkError when attempting to fetch resource.',
        //   'TypeError: Network request failed'
      ],
      beforeBreadcrumb: (breadcrumb, hint) => {
        if (
          breadcrumb.category === 'ui.click' &&
          hint &&
          hint.event.target &&
          hint.event.target.className.indexOf('MuiButton-label') > -1 &&
          hint.event.target.innerText
        ) {
          breadcrumb.message = `button > ${hint.event.target.innerText}`
        }
        return breadcrumb
      }
    })
  }
}

type UserInfo = {
  id: string
  email: string
  roles?: string[]
}

const login = (userInfo: UserInfo) => {
  Sentry.configureScope(scope => {
    const userRoles = userInfo.roles ? userInfo.roles.join(', ') : 'No Roles'
    scope.setExtra('User Roles', userRoles)
    scope.setUser({
      id: userInfo.id,
      email: userInfo.email
    })
  })
}

type Props = {
  user: {
    id: string
    email: string
    roles: string[] | List<string>
  }
  actions: string[]
  error: Error
  currentUrl: string
  currentAction?: Object
}
const log = (props: Props) => {
  Sentry.captureException(props.error)
}

type Crumb = {
  message: string
  data?: { [key: string]: any }
  category: string
  level?:
    | Sentry.Severity.Debug
    | Sentry.Severity.Error
    | Sentry.Severity.Fatal
    | Sentry.Severity.Info
    | Sentry.Severity.Warning
}

const addBreadcrumb = (props: Crumb) => {
  const { level = Sentry.Severity.Info, ...restProps } = props
  Sentry.addBreadcrumb({ level, ...restProps })
}

const logger = {
  log,
  login,
  setup,
  addBreadcrumb
}

export default logger
