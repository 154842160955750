import React, { Component } from 'react'
import PageSideSection from 'shared/components/PageSideSection'
import Scrollable from 'shared/components/Scrollable'
import { FormattedMessage } from 'react-intl'
import AddPrimaryContactContainer from '../../containers/AddPrimaryContactContainer'
import VetContactListContainer from '../../containers/VetContactListContainer'

type Props = {
  readonly?: boolean
}

class PrimaryContacts extends Component<Props> {
  render() {
    const { readonly } = this.props

    return (
      <PageSideSection
        title={
          <FormattedMessage
            id='PrimaryContacts.RFIPrimaryContacts'
            defaultMessage='RFI Contacts'
          />
        }
        noPadding
      >
        <div className='ph3-5 pb3-5'>
          <Scrollable>
            <VetContactListContainer readonly={readonly} />
          </Scrollable>
        </div>
        {!readonly && (
          <AddPrimaryContactContainer
            userLookupLabel={
              <FormattedMessage
                id='PrimaryContacts.AddContact'
                defaultMessage='Add a contact'
              />
            }
          />
        )}
      </PageSideSection>
    )
  }
}

export default PrimaryContacts
