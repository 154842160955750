import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'

const apiRoute = `${apiRoutes.authService}/contacts`
const MaxContactsPerPost = 100
const syncContactsKey = 'syncContactsKey'

export const shouldSyncContacts = () => {
  const whenToRequestContactsSync = window.localStorage.getItem(syncContactsKey)
  return (
    !whenToRequestContactsSync ||
    new Date() > new Date(parseInt(whenToRequestContactsSync, 10))
  )
}

export const postponeContactsSync = () => {
  const today = new Date()
  window.localStorage.setItem(
    syncContactsKey,
    today.setDate(today.getDate() + 14)
  )
}

export const uploadContacts = contacts => {
  const today = new Date()
  window.localStorage.setItem(
    syncContactsKey,
    today.setMonth(today.getMonth() + 3)
  )

  if (contacts && contacts.length > 0 && contacts.length < MaxContactsPerPost) {
    return api.post(`${apiRoute}/upload`, { contacts: contacts })
  } else if (contacts && contacts.length >= MaxContactsPerPost) {
    const batch = contacts.slice(0, MaxContactsPerPost)
    const remainingContacts = contacts.slice(MaxContactsPerPost)
    return api
      .post(`${apiRoute}/upload`, { contacts: batch })
      .then(() => uploadContacts(remainingContacts))
  } else {
    return Promise.resolve(true)
  }
}
