import React, { Component } from 'react'
import classNames from 'classnames'

const styles = {
  divider: 'bg-light-gray ma0 pa0 bn'
}

type Props = {
  className?: string
}

class Divider extends Component<Props> {
  render() {
    const { className } = this.props

    return (
      <hr
        style={{
          height: 1
        }}
        className={classNames(styles.divider, className)}
      />
    )
  }
}

export default Divider
