import React, { Component, ReactElement } from 'react'
import GoldBadge from 'shared/assets/icons/badge-premium.svg'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

type Props = {
  text?: string | ReactElement<FormattedMessage>
  className?: string
  tabIndex?: number
}

class PremiumBadge extends Component<Props> {
  render() {
    const { text, className, ...rest } = this.props

    return (
      <div
        {...rest}
        role='img'
        className={classNames(className, 'dib')}
        aria-labelledby={text ? 'premiumDescription' : ''}
      >
        <img src={GoldBadge} alt='badge' className='v-mid w2 relative z-1' />
        {text && (
          <span
            className='v-mid dib f9 fw4 white bg-gold pl2 pr1 relative br2 z-0'
            style={{
              marginLeft: '-0.7em',
              marginBottom: '0.3em',
              padding: '.25em .5em'
            }}
            id='premiumDescription'
          >
            {text}
          </span>
        )}
      </div>
    )
  }
}

export default PremiumBadge
