import connectReduxForm from 'shared/utils/connectReduxForm'
import EnterEmail from '../../components/EnterEmail'
import { changeUserEmail, editUserEmail } from '../../store/actions'
import userEmailSelectors from '../../store/selectors/userEmailSelectors'

export default connectReduxForm(
  state => ({
    editMode: userEmailSelectors.getEditMode(state),
    email: userEmailSelectors.getEmail(state),
    initialValues: {
      email: userEmailSelectors.getEmail(state)
    }
  }),
  {
    onSubmit: changeUserEmail,
    onClickEdit: editUserEmail
  },
  {
    form: 'dataPortal/enterEmail'
  },
  EnterEmail
)
