import React, { ReactChild } from 'react'
import AppLogoContainer from 'shared/containers/AppLogoContainer'
import tealbookApp from './image-tealbook-app.png'
import yellowTriangle from './yellow-triangle.svg'
import lightTealTriangle from './light-teal-triangle.svg'
import { FormattedMessage } from 'react-intl'
import UploadCertification from './UploadCertification'
import SelfCertify from './SelfCertify'
import { useLocation } from 'react-router-dom'

const navStyles = {
  default: `black mr4 pv3 f4 f3-ns`,
  selected: `black mr4 pv3 fw6 bb bw3 b--dark-red f4 f3-ns`
}

type Props = {
  children: ReactChild
  client?: string
  iframe?: boolean
}

export const SelfCertificationLandingPage = (props: Props) => {
  const { children } = props
  const location = useLocation()
  const currentTab = location.hash || '#UploadCertification'

  return (
    <main>
      <div className='bg-teal pt2 pb5-ns'>
        <div className='mw8 center ph3-ns'>
          <div className='tl mv3 mv4-ns mh3 mh0-ns'>
            <AppLogoContainer className='w4 w5-ns' white />
          </div>
          <div className='flex-ns justify-between'>
            <div className='w-100 w-two-thirds-ns pa3 pa0-ns tl'>
              <h1 className='f2 f1-ns lh-title white fw6 mt0'>
                <FormattedMessage
                  id='SelfCertificationLandingPage.Title'
                  defaultMessage={`Create your free TealBook account and log into your Supplier Profile`}
                />
              </h1>
              <p className='lh-copy fw4 ma0 f5 f4-ns pr4-ns white'>
                <FormattedMessage
                  id='SelfCertificationLandingPage.Message'
                  defaultMessage={`TealBook is a Supplier Data solution with the power to revolutionize how buyers locate and engage with suppliers across the world. Using publicly and privately available information, Machine Learning, and Artificial Intelligence, TealBook has created the world's largest B2B Supplier Information platform. TealBook's $100B+ customer portfolio represents many of the largest organizations in the world, including a number of Fortune 500 companies, all of which are using TealBook to locate and connect with suppliers best suited to their requirements.`}
                  values={{
                    b: b => <span className='fw8'>{b}</span>
                  }}
                />
              </p>
            </div>
            <div>{children}</div>
          </div>
        </div>
      </div>
      <section
        className='tl bg-white'
        style={{
          backgroundImage: `url(${lightTealTriangle})`,
          backgroundPosition: `top right`,
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className='mw8 center pt2 pt4 pv5-ns ph3'>
          <div className='flex flex-wrap justify-between'>
            <div className='w-100 w-third-ns'>
              <img
                src={tealbookApp}
                alt='TealBook App'
                width='100%'
                aria-hidden='true'
                className=''
              />
            </div>
            <div className='w-two-thirds-ns'>
              <label className='f4 f3-ns fw6 ml4-ns'>
                <FormattedMessage
                  id='SelfCertificationLandingPage.ManageYourProfile'
                  defaultMessage={`Manage your supplier profile`}
                />
              </label>
              <p className='lh-copy fw4 mid-gray ma0 f5 f4-ns mt4 ml4-ns'>
                <FormattedMessage
                  id='SelfCertificationLandingPage.TealbookMessage'
                  defaultMessage={`TealBook allows you to upload your Diversity, Quality, Information Security, Social Responsibility and Sustainability, and Food related certifications onto its platform, to make your profile more competitive to TealBook's ever expanding network of Buyer organizations. In the case of Diversity certifications, you have the option to either <b>(1) pick from a list of Certification Agencies and upload the associated Diversity Certificate, or (2) Self Certify</b> - the latter being a quick and convenient way to categorize yourself as a Diverse Supplier to TealBook Buyers.`}
                  values={{
                    b: b => <span className='fw8'>{b}</span>
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className='tl bg-near-white'
        style={{
          backgroundImage: `url(${yellowTriangle})`,
          backgroundPositionY: '-420px',
          backgroundPositionX: 'right',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className='mw8 center ph3 pt2 pb4 pv4 pv5-ns'>
          <label className='f3 f2-ns fw6'>
            <FormattedMessage
              id='SelfCertificationLandingPage.Instructions'
              defaultMessage={`Instructions`}
            />
          </label>
          <nav className='mt4'>
            <a
              href='#UploadCertification'
              className={
                currentTab === '#UploadCertification'
                  ? navStyles.selected
                  : navStyles.default
              }
            >
              <FormattedMessage
                id='SelfCertificationLandingPage.UploadCertification'
                defaultMessage={`Upload Certification`}
              />
            </a>
            <a
              href='#SelfCertify'
              className={
                currentTab === '#SelfCertify'
                  ? navStyles.selected
                  : navStyles.default
              }
            >
              <FormattedMessage
                id='SelfCertificationLandingPage.SelfCertify'
                defaultMessage={`Self Certify`}
              />
            </a>
          </nav>
        </div>
        {currentTab === '#UploadCertification' ? (
          <UploadCertification />
        ) : (
          <SelfCertify />
        )}
      </section>
    </main>
  )
}

export default SelfCertificationLandingPage
