import React from 'react'
import { Route, Switch } from 'react-router'
import paths from './paths'
import CommunitiesPageContainer from '../containers/CommunitiesPageContainer'
import CommunityContainer from '../containers/CommunityContainer'

const createRoutes = () => (
  <Switch>
    <Route path={paths.community} component={CommunityContainer} />
    <Route path={paths.basePath} component={CommunitiesPageContainer} />
  </Switch>
)

export default createRoutes
