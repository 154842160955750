const stripParams = path => path.split(':')[0]

const createIsActiveFunc = path => (match, location) => {
  return typeof path === 'object'
    ? path.some(p => {
        return (
          location.pathname.startsWith(stripParams(p)) ||
          location.search.indexOf(stripParams(p)) !== -1
        )
      })
    : location.pathname.startsWith(stripParams(path)) ||
        location.search.indexOf(stripParams(path)) !== -1
}

export default createIsActiveFunc
