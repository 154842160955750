import React, { FunctionComponent } from 'react'
import Avatar from 'shared/components/Avatar'
import Link from 'shared/components/Link'
import Text from 'shared/components/Text'
import iconPrivate from 'shared/assets/icons/private.svg'
import iconPublic from 'shared/assets/icons/public.svg'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import checkMark from 'shared/assets/icons/check.svg'
import Button from 'shared/components/Button'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import BlockIcon from '@material-ui/icons/Block'
import PhoneIcon from '@material-ui/icons/Phone'
import Address from 'shared/components/Address'
import LocationIcon from '@material-ui/icons/LocationOn'
import EmailIcon from '@material-ui/icons/Email'
import ContactProps from 'shared/models/Contact'
import useThemeColors from '../../utils/useThemeColors'

const styles = {
  wrapper: 'dib w-100 w-50-ns pr2 pr3-ns pt3-5 v-top',
  avatar: 'w3 br-100',
  name: 'f5 fw6 mid-gray hover-teal ma0',
  title: 'f7 fw6 mid-gray mt1 ma0',
  contactFor: 'f7 fw4 gray mt2 mb1 mw-100 word-wrap',
  menu: 'fr',
  icon: 'ml2'
}

type Props = {
  userId: string
  baseUrl?: string
  contact: Partial<ContactProps>
  contactOwner?: string
  isCurrentUser?: boolean
  contactOwnerFirstName?: string
  contactOwnerLastName?: string
  currentUser?: string
  isEditable?: boolean
  isEditing?: boolean
  isTealbot?: boolean
  isBuyerAdmin?: boolean
  colleagueIsConnected?: boolean
  onUnfollow?: () => void
  onRemove?: () => void
  onDelete?: () => void
  onEdit?: () => void
  onProfileClick?: () => void
  onClickMessage?: (userId: string) => void
  onClickConnect?: (userId: string) => void
  onUpdate?: (updateConnectFor: {
    connectFor: string
    isPrivate: boolean
  }) => void
}

const Contact: FunctionComponent<Props> = props => {
  const colorClasses = useThemeColors()
  const handleClickConnect = () => {
    const { onClickConnect, userId } = props
    onClickConnect && onClickConnect(userId)
  }

  const handleClickMessage = () => {
    const { onClickMessage, userId } = props
    onClickMessage && onClickMessage(userId)
  }
  const {
    baseUrl,
    userId,
    isEditable,
    isTealbot,
    isBuyerAdmin,
    colleagueIsConnected,
    isEditing,
    isCurrentUser,
    contact: {
      profilePictureUrl,
      firstName,
      lastName,
      title,
      email,
      location,
      contactFor,
      phone,
      isPrivate,
      connected,
      blocked
    },
    contactOwner,
    contactOwnerFirstName,
    contactOwnerLastName,
    currentUser,
    onRemove,
    onDelete,
    onEdit,
    onProfileClick,
    onClickConnect,
    onUnfollow,
    onClickMessage,
    children
  } = props

  const showEditContactMenuItem = onEdit && isEditable && !isEditing
  const showDisconnectMenuItem = isEditable && connected && onUnfollow
  const showRemoveMenuItem = onRemove && !isEditing && isEditable
  const showRemoveContactRelationship =
    onDelete &&
    (isEditable || colleagueIsConnected) &&
    (isBuyerAdmin || isTealbot)

  return (
    <div className={styles.wrapper}>
      {onProfileClick && userId && baseUrl ? (
        <Link to={`${baseUrl}/users/${userId}`}>
          <Avatar
            className={styles.avatar}
            url={profilePictureUrl}
            name={`${firstName || ''} ${lastName || ''}`}
          />
        </Link>
      ) : (
        <Avatar
          className={styles.avatar}
          url={profilePictureUrl}
          name={`${firstName || ''} ${lastName || ''}`}
        />
      )}

      {(showEditContactMenuItem ||
        showDisconnectMenuItem ||
        showRemoveMenuItem ||
        showRemoveContactRelationship ||
        onProfileClick) && (
        <div className={styles.menu}>
          <DropDownMenu
            ariaLabel={`${firstName} ${lastName}'s Contact Menu Item`}
          >
            {showEditContactMenuItem && (
              <MenuItem onClick={onEdit}>
                <FormattedMessage
                  id='ContactCard.EditContact'
                  defaultMessage='Edit Contact'
                />
              </MenuItem>
            )}
            {showDisconnectMenuItem && (
              <MenuItem onClick={onUnfollow}>
                <FormattedMessage
                  id='ContactCard.Disconnect'
                  defaultMessage='Disconnect'
                />
              </MenuItem>
            )}
            {showRemoveMenuItem && (
              <MenuItem onClick={onRemove}>
                <FormattedMessage
                  id='ContactCard.Remove'
                  defaultMessage='Remove'
                />
              </MenuItem>
            )}
            {showRemoveContactRelationship && (
              <MenuItem onClick={onDelete}>
                <FormattedMessage
                  id='ContactCard.Delete'
                  defaultMessage='Delete Contact'
                />
              </MenuItem>
            )}

            {onProfileClick && (
              <MenuItem onClick={onProfileClick}>
                <FormattedMessage
                  id='ContactCard.ViewProfile'
                  defaultMessage='View Profile'
                />
              </MenuItem>
            )}
          </DropDownMenu>
        </div>
      )}

      <div className='flex items-center mt2'>
        {onProfileClick && userId && baseUrl ? (
          <Link to={`${baseUrl}/users/${userId}`}>
            <span className={styles.name}>
              {firstName} {lastName}
            </span>
          </Link>
        ) : (
          <span className='f5 fw6 mid-gray ma0'>
            {firstName} {lastName}
          </span>
        )}

        {typeof isPrivate !== 'undefined' &&
          (isPrivate ? (
            <Tooltip
              title={
                <FormattedMessage
                  id='ContactCard.Private'
                  defaultMessage='Private'
                />
              }
            >
              <img src={iconPrivate} className={styles.icon} alt='Private' />
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <FormattedMessage
                  id='ContactCard.Public'
                  defaultMessage='Public'
                />
              }
            >
              <img src={iconPublic} className={styles.icon} alt='Public' />
            </Tooltip>
          ))}

        {blocked && (
          <Tooltip
            title={
              <FormattedMessage
                id='ContactCard.Inactive'
                defaultMessage='Inactive'
              />
            }
          >
            <BlockIcon style={{ fontSize: 14 }} className='ml2' />
          </Tooltip>
        )}
      </div>
      <div className={styles.title}>{title}</div>

      {email && (
        <div className='flex items-center f6 fw4 gray truncate mt1'>
          <EmailIcon fontSize='small' />
          {!blocked ? (
            <a
              className={`ml2 f6 fw4 no-underline hover-teal ${colorClasses.primaryText}`}
              href={`mailto:${email}`}
            >
              {email}
            </a>
          ) : (
            <span className='ml2 f6 fw2 gray strike'>{email}</span>
          )}
        </div>
      )}

      {phone && (
        <div className='flex items-center f6 fw4 gray mt1'>
          <PhoneIcon fontSize='small' />
          <a
            className={`${colorClasses.primaryText} ml2 no-underline hover-teal`}
            href={`tel:${phone}`}
          >
            {phone}
          </a>
        </div>
      )}

      {location && (
        <div className='flex items-center f6 fw4 gray mt1 mb1'>
          <LocationIcon fontSize='small' />
          <Address noIcon location={location} className='ml2 dib' />
        </div>
      )}

      {contactFor && (
        <div className={styles.contactFor}>
          <span className='fw6 gray'>
            <FormattedMessage
              id='ContactCard.ContactFor'
              defaultMessage='Contact for:'
              values={{ contactOwnerFirstName, contactOwnerLastName }}
            />
          </span>
          &nbsp;
          {contactFor}
        </div>
      )}

      {contactOwner && currentUser !== contactOwner && (
        <Text className='gray'>
          <FormattedMessage
            id='ContactCard.AddedBy'
            defaultMessage='Added by {contactOwnerFirstName} {contactOwnerLastName}'
            values={{ contactOwnerFirstName, contactOwnerLastName }}
          />
        </Text>
      )}

      {children}

      {!blocked &&
        !isCurrentUser &&
        (!isEditable || !connected) &&
        onClickConnect && (
          <Button
            label={
              <FormattedMessage
                id='ContactCard.Connect'
                defaultMessage='Connect'
              />
            }
            onClick={handleClickConnect}
            secondary
            autoSize
            className='mr3'
          />
        )}

      {isEditable && connected && (
        <div>
          <span className={`f6 ${colorClasses.primaryText}`}>
            <FormattedMessage
              id='ContactCard.Connected'
              defaultMessage='Connected'
            />
          </span>{' '}
          <img src={checkMark} alt='check' className='v-mid' />
        </div>
      )}

      {!blocked && !isCurrentUser && onClickMessage && (
        <Button
          label={
            <FormattedMessage
              id='ContactCard.Message'
              defaultMessage='Message'
            />
          }
          onClick={handleClickMessage}
          secondary
          autoSize
          className='mt2'
        />
      )}
    </div>
  )
}

export default Contact
