import React, { Component } from 'react'
import AppBarContainer from 'shared/containers/AppBarContainer'
import UserNavContainer from 'shared/containers/UserNavContainer'
import paths from '../../routes/paths'

/**
 * AppBar for admin
 */

export class AdminAppBar extends Component {
  render() {
    return (
      <AppBarContainer userNav={<UserNavContainer />} homeUrl={paths.basePath}>
        <div className='flex-auto' />
      </AppBarContainer>
    )
  }
}

export default AdminAppBar
