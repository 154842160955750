import { withStyles } from '@material-ui/core/styles'
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade'
import React, { Component, ReactNode } from 'react'
const styles = {
  tooltip: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: 14,
    fontWeight: 500,
    color: 'black',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    marginTop: 6,
    opacity: '1 !important',
    maxWidth: 360
  }
}
const StyleTooltip = withStyles(styles as any)(Tooltip)
type Props = {
  title: ReactNode
} & Omit<TooltipProps, 'title'>

type State = {
  isToolTipOpen: boolean
  isToolTipHoverable: boolean
}
class CustomTooltip extends Component<Props, State> {
  state: State = {
    isToolTipOpen: false,
    isToolTipHoverable: true
  }

  handleKeyDown = nativeEvent => {
    if (!this.state.isToolTipOpen) {
      return
    }
    if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
      this.setState({ isToolTipOpen: false })
      this.setState({ isToolTipHoverable: false })
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  render() {
    const { title, ...rest } = this.props
    if (!title) {
      return null
    }
    return (
      <StyleTooltip
        {...rest}
        title={title}
        TransitionComponent={Fade}
        enterDelay={200}
        open={this.state.isToolTipOpen}
        onOpen={() => {
          this.setState({ isToolTipOpen: true })
        }}
        onClose={() => {
          this.setState({ isToolTipOpen: false })
        }}
        onMouseEnter={() => this.setState({ isToolTipHoverable: true })}
        disableHoverListener={!this.state.isToolTipHoverable}
        interactive
      />
    )
  }
}
export default CustomTooltip
