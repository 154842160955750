import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import parseFilters from '../../data/parseFilters'

const apiRoute = `${apiRoutes.authService}/buyer`

export const get = () => api.get(apiRoute)

export const getSettings = () => {
  return api
    .get(`${apiRoute}/settings`)
    .then(
      ({
        fixedFilters,
        defaultFilters,
        yearEnd,
        contractExpiryWindowDays,
        ...rest
      }) => {
        return {
          fixedFilters: parseFilters(fixedFilters),
          defaultFilters: parseFilters(defaultFilters),
          yearEnd: yearEnd?.replace('-', '/') || '12/31',
          contractExpiryWindowDays: contractExpiryWindowDays || 90,
          ...rest
        }
      }
    )
}

export const updateSettings = (settings: Object) => {
  return api.put(`${apiRoute}/settings`, settings)
}

export const getByDomain = (q: string) =>
  api.get(`${apiRoute}/domain`, {
    q
  })

type Props = {
  orgUnitId: string
  buyerType: string
}
export const makeSupplierABuyer = ({ orgUnitId, buyerType }: Props) => {
  return api.put(`${apiRoute}/${orgUnitId}/buyerType`, {
    buyerType
  })
}

export const createContactFromBusinessCard = (businessCardImage: File) => {
  return api.postFile(`${apiRoute}/cardPicture`, {
    cardPicture: businessCardImage
  })
}

export const savePartnerCredentials = (username, password, integrator) => {
  return api
    .post(`${apiRoute}/credentials`, {
      username,
      password,
      integrator
    })
    .catch(err => err)
}

export const loadWebhooks = () => {
  return api.get(`${apiRoute}/webhookSetting`)
}

export const saveWebhook = webhook => {
  return api.put(`${apiRoute}/webhookSetting`, webhook)
}
