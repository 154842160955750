import React from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes'
import parsePath from 'shared/utils/parsePath'

const JobsNav = () => {
  return (
    <NavBar>
      <NavLink
        label={'Active Jobs'}
        to={parsePath(paths.list, { show: 'active' })}
      />
      <NavLink
        label={'Available Jobs'}
        to={parsePath(paths.list, { show: 'available' })}
      />
      <NavLink
        label={'Completed Jobs'}
        to={parsePath(paths.list, { show: 'complete' })}
      />
    </NavBar>
  )
}

export default JobsNav
