import * as actionTypes from '../../actionTypes'
import { requestSuccess } from 'shared/actionTypes'
import { fromJS } from 'immutable'
import {
  REQUEST_ECOVADIS_SUPPLIER_RATING,
  RETRIEVE_ECOVADIS_ASSESSMENT_STATUS,
  RETRIEVE_ECOVADIS_SCORECARD
} from '../../../buyer/SupplierProfile/actionTypes'
import camelCase from 'lodash.camelcase'

const defaultState = fromJS({
  supplierRelationships: [],
  errorSupplierList: '',
  integrator: '',
  isLoadingSupplierList: false,
  openRequestRatingDialog: false,
  openBulkAssessmentErrorsDialog: false,
  errorCredentials: false,
  ecovadisAssessmentStatus: '',
  ecovadisScorecard: {},
  isSubmittingRequest: false,
  loadingEcovadisAssessmentStatus: false,
  actionRequired: false
})

const integrationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.CLOSE_REQUEST_RATING_DIALOG:
      return state.set('openRequestRatingDialog', false)
    case actionTypes.CLOSE_BULK_ASSESSMENT_ERRORS_DIALOG:
      return state.set('openBulkAssessmentErrorsDialog', false)

    case actionTypes.LINK_BUYER_WITH_INTEGRATION:
      return state.merge({
        integrator: action.payload.integrator,
        isLoadingSupplierList: true,
        errorCredentials: false
      })

    case requestSuccess(actionTypes.LINK_BUYER_WITH_INTEGRATION):
      return state.merge({
        isLoadingSupplierList: false,
        errorCredentials: !!action.payload.credsSaved?.devErrorMessage
        //Phase 2
        // supplierRelationships: fromJS(action.payload.response?.results),
        // openRequestRatingDialog: !!action.payload.response?.results.length
      })

    case REQUEST_ECOVADIS_SUPPLIER_RATING:
      return state.set('isSubmittingRequest', true)

    case requestSuccess(REQUEST_ECOVADIS_SUPPLIER_RATING):
      return state.merge({
        initialBatchAssessmentSubmitted:
          action.payload.initialBatchAssessmentSubmitted,
        isSubmittingRequest: false,
        ecovadisAssessmentStatus: action.payload.initialBatchAssessmentSubmitted
          ? 'registration'
          : null
        // Phase 2
        // errorSupplierList: fromJS(action.payload.batchAssessmentSupplierErrors),
        // openBulkAssessmentErrorsDialog: !!action.payload
        // .batchAssessmentSupplierErrors.length,
        //openRequestRatingDialog: false,
      })
    case RETRIEVE_ECOVADIS_ASSESSMENT_STATUS:
      return state.set('loadingEcovadisAssessmentStatus', true)
    case requestSuccess(RETRIEVE_ECOVADIS_ASSESSMENT_STATUS):
      return state.merge({
        loadingEcovadisAssessmentStatus: false,
        ecovadisAssessmentStatus: camelCase(action.payload.status),
        actionRequired: action.payload.actionRequired
      })

    case requestSuccess(RETRIEVE_ECOVADIS_SCORECARD):
      return state.set(
        'ecovadisScorecard',
        fromJS(action.payload.ecovadisScorecard)
      )

    default:
      return state
  }
}

export default integrationsReducer
