import React, { Component } from 'react'
import Snackbar from '@material-ui/core/Snackbar'

type Props = {
  message: string
}
class Notification extends Component<Props> {
  render() {
    const { message } = this.props

    return <Snackbar open={!!message} message={message} />
  }
}

export default Notification
