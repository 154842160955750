import React, { Component } from 'react'
import orgSvg from './org.svg'

/**
 * Similar to avatar, OrgLogo is just a html img tag with custom formatting and a special default image<br/>
 * The intended use of this class is for logos for different organizations
 */

type Props = {
  /** url for image */
  url?: string
  name?: string
  className?: string
  square?: boolean
  onError?: (e: string) => void
  onLoad?: (e: string) => void
  width?: string
}

type State = {
  error: boolean
}

class OrgLogo extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      error: false
    }
  }

  handleOnError = e => {
    // if src is broken, load default
    const { onError } = this.props
    this.setState({ error: true })
    if (onError) {
      onError(e)
    }
  }

  handleOnLoad = e => {
    const { onLoad } = this.props
    if (onLoad) {
      onLoad(e)
    }
  }

  render() {
    const { url, name, className, width } = this.props

    return (
      <img
        className={`dib ${className || ''}`}
        alt={this.state.error || !url ? '' : `Logo of ${name}`}
        src={this.state.error ? orgSvg : url || orgSvg}
        onError={this.handleOnError}
        onLoad={this.handleOnLoad}
        style={{ width: !!width ? width : '100%' }}
      />
    )
  }
}

export default OrgLogo
