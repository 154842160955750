import { call } from 'redux-saga/effects'
import message from '../../utils/api/message'
import createApiRequestSaga from '../../utils/sagas/createApiRequestSaga'

export function* loadMessagesSaga(action) {
  const { ancestorId, messageKind } = action.payload
  const messages = yield call(message.getMessagesByAncestorId, ancestorId)

  return yield {
    messageKind,
    ancestorId,
    messages
  }
}

export default createApiRequestSaga(loadMessagesSaga)
