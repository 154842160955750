import React, { Component } from 'react'
import { connect } from 'react-redux'
import insightsSelectors from '../../store/insightsSelectors'
import InsightTitle from 'shared/components/InsightTitle'
import Paper from 'shared/components/Paper'
import iconDiversity from 'shared/assets/icons/diversity.svg'
import iconDiversityProgram from 'shared/assets/icons/diversity-program.svg'
import iconPreferred from 'shared/assets/icons/prefer.svg'
import numberFormat from 'shared/utils/numberFormat'
import Loading from 'shared/components/Loading'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'
import RootState from 'shared/models/RootState'
import SpendFiltersSummary from '../SpendFiltersSummary'
import Link from 'shared/components/Link'
import { withStyles } from '@material-ui/core/styles'

const style = {
  rowEven: 'dt w-100 f7 fw6 bg-light-gray',
  rowOdd: 'dt w-100 f7 fw6 bg-white',
  column1: 'pa1 pl4-ns dtc-ns dib w-100 w-60-ns',
  column2: 'pa1 dtc-ns dib w-25 w-20-ns',
  column3: 'pa1 dtc-ns dib w-75 w-20-ns tr'
}

const useStyles = {
  row: {
    '&:hover': {
      backgroundColor: '#DBF6F5'
    }
  }
}

const componentStyles = theme => useStyles

type Props = {
  supplierLink: (supplierId: string) => string
  detail: any
  isLoadingDetail: boolean
  totalSpend: number
  classes: any
} & ContainerType

export class YearSpendDetail extends Component<Props> {
  state = {
    expanded: {}
  }

  toggleCategory = category => {
    const { expanded } = this.state
    const toggle = !expanded[category]
    this.setState({
      expanded: Object.assign(expanded, { [category]: toggle })
    })
  }

  renderCategoryRow = (category: string, suppliers: List<any>) => {
    const { expanded } = this.state
    const isExpanded = expanded[category]
    const { classes } = this.props

    return (
      <>
        <tr key={`cat-${category || 'null'}`} className={classes.row}>
          <td
            colSpan={3}
            className={'ph3'}
            style={{ paddingLeft: '0.25em', paddingRight: '0.5em' }}
          >
            <button
              className={
                'pointer f7 fw6 pv2 bn ma0 pa0 bg-transparent w-100 tl black ' +
                classes.row
              }
              onClick={() => this.toggleCategory(category || 'null')}
              aria-expanded={isExpanded ? 'true' : 'false'}
              aria-label={
                suppliers.size + ' suppliers under the category ' + category
              }
            >
              <div className='dib v-mid'>
                {isExpanded ? '[ - ]' : '[ + ]'}{' '}
                {category && category !== 'null' ? category : 'N/A'}
              </div>
            </button>
          </td>
        </tr>
        {isExpanded && suppliers && suppliers.map(this.renderSupplierRow)}
      </>
    )
  }

  renderSupplierRow = (supplier, index) => {
    const { supplierLink, totalSpend } = this.props

    return (
      <tr
        key={supplier.get('OrgUnitId')}
        className={index % 2 ? style.rowOdd : style.rowEven}
      >
        <td className={style.column1}>
          <Link
            to={supplierLink(supplier.get('OrgUnitId'))}
            className='underline-hover hover-teal'
          >
            {supplier.get('name')}
            {supplier.get('diverse') && (
              <img alt='Diverse' src={iconDiversity} className='w1 ml2 v-mid' />
            )}
            {supplier.get('diversityProgram') && (
              <img
                alt='Diverse Program'
                src={iconDiversityProgram}
                className='w1 ml2 v-mid'
              />
            )}
            {supplier.get('preferred') && (
              <img
                alt='Preferred'
                src={iconPreferred}
                className='w1 ml2 v-mid'
              />
            )}
          </Link>
        </td>
        <td className={style.column2}>
          {totalSpend &&
            ((supplier.get('totalAmount', 0) / totalSpend) * 100).toFixed(2)}
          %
        </td>
        <td className={style.column3} style={{ paddingRight: '0.75rem' }}>
          ${numberFormat(supplier.get('totalAmount', 0))}
        </td>
      </tr>
    )
  }

  render() {
    const { detail, isLoadingDetail, type } = this.props
    return isLoadingDetail ? (
      <Loading />
    ) : detail && detail.size > 0 ? (
      <>
        <InsightTitle>
          {type === 'category' ? (
            <FormattedMessage
              id='YearSpendDetail.Top50ByCategory'
              defaultMessage='Top 50 Spend by Category'
            />
          ) : (
            <FormattedMessage
              id='YearSpendDetail.Top50ByBusinessUnit'
              defaultMessage='Top 50 Spend by Business Unit'
            />
          )}
        </InsightTitle>
        <Paper>
          <div className='mt3-5'>
            <div className='flex justify-between items-center'>
              <div>
                <SpendFiltersSummary />
              </div>
            </div>
            <table className='bg-white br1 ba b--black-10 pb3 overflow-hidden w-100'>
              <thead>
                <tr className='dt w-100 bb b--black-10 f6 fw6 mid-gray ttc pa2 bg-near-white'>
                  <th
                    className='dtc-ns dib w-60-ns tl fw6'
                    style={{ paddingLeft: '1.25rem' }}
                  >
                    <FormattedMessage
                      id='YearSpendDetail.Supplier'
                      defaultMessage='Supplier'
                    />
                  </th>
                  <th className='pa1 dtc-ns w-20-ns dn tl fw6'>
                    <FormattedMessage
                      id='YearSpendDetail.PercentSpend'
                      defaultMessage='% of Spend'
                    />
                  </th>
                  <th className='pa1 dtc w-20-ns tr fw6'>
                    <FormattedMessage
                      id='YearSpendDetail.Spend'
                      defaultMessage='Spend'
                    />
                  </th>
                </tr>
              </thead>
              <tbody className='f7 fw6 ph3 w-100'>
                {detail.entrySeq().map(([key, value]) => {
                  return value ? this.renderCategoryRow(key, value) : null
                })}
              </tbody>
            </table>
          </div>
        </Paper>
      </>
    ) : null
  }
}

type ContainerType = {
  type: 'category' | 'spendgroup'
}

export default withStyles(componentStyles)(
  connect((state: RootState, props: ContainerType) => {
    return {
      isLoadingDetail: insightsSelectors.isLoadingDetail(state),
      detail: insightsSelectors.getSpendDetail(state),
      totalSpend: insightsSelectors.getSelectedSpend(state)
    }
  })(YearSpendDetail)
)
