import { connect } from 'react-redux'
import createAddForm from 'shared/utils/createAddForm'
import AddUserTeamForm from './AddUserTeamForm'
import { ADD_CONTACT } from '../../../actionTypes'
import {
  cancelAddingContact,
  selectNewUserForContact,
  selectUserForContact
} from '../../../actions'
import newContactSelectors from '../../../selectors/newContactSelectors'
import companySelectors from '../../../../shared/selectors/companySelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { getFormValues } from 'redux-form/immutable'
import RootState from 'shared/models/RootState'
import { RecordOf } from 'immutable'
import { createSelector } from 'reselect'

type FormValues = RecordOf<{
  user: RecordOf<{
    email: string
  }>
}>

const formName = 'supplier/company/addContact'
const formValueSelector = getFormValues(formName)

type ContainerProps = {
  exactMatch?: boolean
  isTealbot?: boolean
}

const getSuggestedUsers = createSelector(
  usersSelectors.getColleagues,
  companySelectors.getSupplierContacts,
  (colleagues, contacts: any) =>
    colleagues.filter(
      user => user && !contacts.some(c => c.get('user') === user.get('id'))
    )
)

const AddTeamFormContainer = connect(
  (state: RootState, props: ContainerProps) => {
    const formValues = formValueSelector(state) as FormValues
    const user = newContactSelectors.getSelectedUser(state)
    const creatingNewUser = newContactSelectors.isCreatingNewUser(state)
    const addingMyself =
      !creatingNewUser && user && user.id === sessionSelectors.getUserId(state)

    return {
      email: formValues && formValues.getIn(['user', 'email']),
      addingContact: newContactSelectors.isAddingContact(state),
      creatingNewUser,
      addingMyself,
      domains: companySelectors.getCompanyField(state, 'domains'),
      users: usersSelectors.getColleagues(state),
      suggestedUsers: getSuggestedUsers(state),
      initialValues: {
        user,
        message:
          'I would like to add you as a team member to our company page.',
        addingMyself
      }
    }
  },
  {
    onClickCancel: cancelAddingContact,
    onClickSuggestedUser: selectUserForContact,
    onClickNewUser: selectNewUserForContact
  }
)(
  createAddForm(
    {
      formName,
      submitActionType: ADD_CONTACT
    },
    AddUserTeamForm
  )
)

export default AddTeamFormContainer
