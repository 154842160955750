import React, { Component, ReactNode } from 'react'

const styles = {
  label: 'f7 ml2 mr3'
}

type Props = {
  name?: string
  label: string | ReactNode
  type?: string
  required?: boolean
  readonly?: boolean
  value?: any
  checked?: boolean
  input?: { name: string; checked: boolean; value: string }
  meta?: { error?: string; touched?: boolean }
  onChange?: (e: any) => void
  className?: string
}

class RadioButton extends Component<Props> {
  render() {
    const { input, meta, label, name, className, ...rest } = this.props
    const inputName = name || (input && input.name)

    return (
      <label className={`pointer ${className || ''}`}>
        <input
          {...rest}
          {...input}
          type='radio'
          name={inputName}
          className='v-mid'
        />
        <span className={styles.label}>{label}</span>
      </label>
    )
  }
}

export default RadioButton
