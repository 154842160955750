import React, { useState, useMemo } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Select from 'react-select'
import useSmallScreen from 'shared/utils/useSmallScreen'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import { useDispatch, useSelector } from 'react-redux'
import { notify } from 'shared/actions'
import { fromJS, List, RecordOf } from 'immutable'
import ESGSurveyMessageSummary from '../../../buyer/SupplierProfile/components/ESGSurveyMessageSummary'
import RootState from 'shared/models/RootState'
import {
  openForwardSurveyRequestDialog,
  closeForwardSurveyRequestDialog
} from 'shared/actions'
import AddIcon from '@material-ui/icons/AddCircle'
import AddSurveyCollaborateContactForm from '../../../supplier/Survey/containers/AddSurveyCollaborateContactForm'
import sorting from 'shared/utils/sortContactsByNameAndInput'
import User from 'shared/models/User'
import ContactItem from 'shared/components/ContactItem'

type Props = {
  contacts: any
  shareESGSurveyLink: boolean
  messageToForward: string
  onMessageUser: (arg: string) => void
  onClickNewUser: () => void
  onClickCancel: () => void
  onAddSurveyCollaborateContact: (user: { id: string }) => void
}

export const ForwardSurveyRequestDialog = (props: Props) => {
  const {
    contacts,
    shareESGSurveyLink,
    messageToForward,
    onMessageUser,
    onClickCancel,
    onClickNewUser,
    onAddSurveyCollaborateContact
  } = props
  const dispatch = useDispatch()
  const matchMobile = useSmallScreen()
  const [
    forwardSurveyRequestMessage,
    setForwardSurveyRequestMessage
  ] = useState<string>(messageToForward)
  const [selectInput, setSelectInput] = useState<string>('')
  const [selectedContactId, setSelectedContactId] = useState<string>('')
  const openForwardSurveyRequest = useSelector((state: RootState) =>
    state.getIn(['mess', 'openForwardSurveyRequestDialog'])
  )
  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false)
  const handleOnClickAddUserButton = () => {
    setOpenNewContactForm(true)
    onClickNewUser()
    handleCloseDialog()
  }
  const handleCancelNewUserForm = () => {
    onClickCancel()
    dispatch(openForwardSurveyRequestDialog())
  }
  const handleCloseDialog = () => {
    dispatch(closeForwardSurveyRequestDialog())
  }
  const handleSelectInputChange = (inputValue: string) => {
    setSelectInput(inputValue)
  }
  const sortedContactsBySustainability:
    | List<RecordOf<User>>
    | List<undefined> = useMemo(
    () => sorting.sortContactsBySustainabilityContact(contacts, selectInput),
    [contacts, selectInput]
  )

  const filterOption = props => {
    const user = props.data
    const userFullName = `${user.firstName &&
      user.firstName.trim()} ${user.lastName &&
      user.lastName.trim()}`.toLowerCase()

    return userFullName.includes(selectInput.toLowerCase())
  }

  const formatOptionLabel = (user, data) => {
    if (!user) {
      return null
    }
    return (
      <ContactItem
        key={user.id}
        firstName={user.firstName}
        lastName={user.lastName}
        profilePictureUrl={user.profilePictureUrl}
        title={user.title}
        isSustainabilityContact={user.isSustainabilityContact}
        isEsgSurvey={true}
      />
    )
  }

  const handleForwardMessage = e => {
    setForwardSurveyRequestMessage(e.target.value)
  }
  const handleContactSelected = (data, metaAction) => {
    setSelectedContactId(data.id)
    setSelectInput('')
  }
  const handleFowardSurveyRequest = e => {
    e.preventDefault()
    onMessageUser(
      fromJS({
        userToMessageId: selectedContactId,
        message: forwardSurveyRequestMessage,
        shareESGSurveyLink
      })
    )
    if (selectedContactId) {
      onAddSurveyCollaborateContact(fromJS({ user: { id: selectedContactId } }))
    }
    dispatch(notify({ message: 'Message succesfully sent' }))
    handleCloseDialog()
  }

  return (
    <>
      <Dialog
        onClose={handleCloseDialog}
        open={openForwardSurveyRequest}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
      >
        <form onSubmit={handleFowardSurveyRequest}>
          <DialogTitle>
            <FormattedMessage
              id='ForwardSurveyRequestDialog.RequestESGSurvey'
              defaultMessage='Sustainability Survey Request'
            />
          </DialogTitle>
          <DialogContent className='mt3'>
            <Text>
              <FormattedMessage
                id='ForwardSurveyRequestDialog.ContactSelectInstruction'
                defaultMessage='Indicate a contact you would like to forward this request to'
              />
            </Text>
            <Label>
              <FormattedMessage
                id='ForwardSurveyRequestDialog.ContactSelect'
                defaultMessage='Contacts'
              />
            </Label>
            <Select
              aria-label='Select Contact'
              InputValue={selectInput}
              onInputChange={handleSelectInputChange}
              onChange={handleContactSelected}
              placeholder={
                (
                  <FormattedMessage
                    id='UserLookup.Select Contact'
                    defaultMessage='Type in Contact Name'
                  />
                ) as any
              }
              options={sortedContactsBySustainability.toJS()}
              formatOptionLabel={formatOptionLabel}
              filterOption={filterOption}
              getOptionValue={opt => opt.id || opt.value}
              noOptionsMessage={inputValue => 'No Contacts Found'}
              controlShouldRenderValue={true}
              styles={{
                control: base => ({
                  ...base,
                  height: 30
                }),
                input: base => ({
                  ...base,
                  fontSize: '.875rem',
                  height: 30
                }),
                placeholder: base => ({
                  ...base,
                  fontSize: '.875rem'
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                noOptionsMessage: base => ({ ...base, fontSize: '.875rem' }),
                option: (base, { isDisabled, isFocused }) => ({
                  ...base,
                  backgroundColor: isDisabled
                    ? 'inherit'
                    : isFocused
                    ? '#E0E0E0'
                    : 'inherit',
                  ':hover': {
                    ...base[':hover'],
                    backgroundColor: !isDisabled && '#e0e0e0'
                  }
                })
              }}
              menuPortalTarget={document.body}
            />
            <div className='mb3 mt3'>
              <div className='dib w-35'>
                <Button
                  color='primary'
                  variant='text'
                  startIcon={<AddIcon />}
                  label={
                    <FormattedMessage
                      id='ESGSurveyDialog.AddContact'
                      defaultMessage='Add a New Contact'
                    />
                  }
                  onClick={() => handleOnClickAddUserButton()}
                />
              </div>
            </div>
            <Label htmlFor='message'>
              <FormattedMessage
                id='ForwardSurveyRequestDialog.Message'
                defaultMessage='Message'
              />
            </Label>
            <TextArea
              id='message'
              value={forwardSurveyRequestMessage}
              onChange={handleForwardMessage}
              height={100}
              maxLength={600}
            />
            <ESGSurveyMessageSummary
              name={`Sustainability Survey Link`}
              description={`Link to the Sustainability Survey Form`}
            />
          </DialogContent>
          <DialogActions className='fixed static-ns bottom-1'>
            <Button
              size={matchMobile ? 'large' : undefined}
              label={
                <FormattedMessage
                  id='ForwardSurveyRequestDialog.Send'
                  defaultMessage='Forward'
                />
              }
              disabled={!selectedContactId || !forwardSurveyRequestMessage}
              type='submit'
            />
            <Button
              size={matchMobile ? 'large' : undefined}
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleCloseDialog}
              secondary
              className='mr3'
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>
      {openNewContactForm && (
        <AddSurveyCollaborateContactForm
          defaultMessage={messageToForward}
          shareESGSurveyLink={shareESGSurveyLink}
          sendCollaborateRequest={true}
          onClickCancel={handleCancelNewUserForm}
        />
      )}
    </>
  )
}
export default ForwardSurveyRequestDialog
