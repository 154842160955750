import React, { useState } from 'react'
import Slide from '@material-ui/core/Slide'
import Fade from '@material-ui/core/Fade'
import useThemeColors from 'shared/utils/useThemeColors'
import { useIntl, defineMessages } from 'react-intl'
import Button from 'shared/components/Button'
import TextArea from 'shared/components/TextArea'
import Rating from 'shared/components/Rating'
import { sendSearchFeedback } from '../../actions'
import { useDispatch } from 'react-redux'
import CloseIcon from '@material-ui/icons/Clear'
import IconButton from 'shared/components/IconButton'

const messages = defineMessages({
  feedback: {
    id: 'Feedback.HowHelpful',
    defaultMessage: 'How helpful are these search results?'
  },
  askRatingDescription: {
    id: 'Feedback.Description',
    defaultMessage:
      'How could the results be improved? Specifics really help us get better.'
  },
  thankYou: {
    id: 'Feedback.ThankYou',
    defaultMessage: 'Thank you for your feedback!'
  },
  submit: {
    id: 'Feedback.Submit',
    defaultMessage: 'Submit'
  }
})

type Props = {
  open: boolean
}

const Feedback = (props: Props) => {
  const colors = useThemeColors()
  const intl = useIntl()
  const dispatch = useDispatch()
  const [needRatingDescription, setNeedRatingDescription] = useState<boolean>(
    false
  )
  const [showThankYou, setShowThankYou] = useState<boolean>(false)
  const [showFeedback, setShowFeedback] = useState<boolean>(true)
  const [ratingDescription, setRatingDescription] = useState<string>('')
  const [rating, setRating] = useState<number>(0)

  const handleRatingChange = (event, value) => {
    setRating(rating)
    if (event.type === 'click' || event.key === 'Enter') {
      if (rating < 3) {
        setNeedRatingDescription(true)
      } else {
        handleSubmitFeedback(rating)
      }
    }
  }
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleRatingChange(e, rating)
    }
  }
  const handleRatingHover = (event, value) => {
    setRating(value)
  }

  const handleReset = () => {
    setNeedRatingDescription(false)
    setRatingDescription('')
    setRating(0)
  }

  const handleSubmitFeedback = (quickRating?: number) => {
    const submitRating = quickRating || rating
    if (submitRating) {
      dispatch(
        sendSearchFeedback({
          rating: submitRating,
          ratingDescription
        })
      )
      setShowThankYou(true)
      setShowFeedback(false)
      handleReset()
    }
  }

  const handleThankYouEnter = () => {
    setTimeout(() => setShowThankYou(false), 2000)
  }

  const handleThankYouExit = () => {
    setTimeout(() => setShowFeedback(true), 1000)
  }

  return (
    <Slide direction='up' in={props.open}>
      <div
        className={`${colors.primaryLightContained} fixed shadow-1`}
        style={{ width: 265, bottom: 0 }}
      >
        <div className='pa2'>
          {!showFeedback && (
            <Fade
              in={showThankYou}
              timeout={{
                enter: 1000,
                exit: 1000
              }}
              onEnter={handleThankYouEnter}
              onExit={handleThankYouExit}
            >
              <div className='tc'>
                <div className='lh-copy mid-gray f8 fw6'>
                  {intl.formatMessage(messages.thankYou)}
                </div>
              </div>
            </Fade>
          )}
          {showFeedback &&
            (needRatingDescription ? (
              <div>
                <div className='flex items-center justify-between'>
                  <label
                    className='lh-copy mid-gray f8'
                    htmlFor='feedbackTextArea'
                  >
                    {intl.formatMessage(messages.askRatingDescription)}
                  </label>
                  <IconButton onClick={handleReset}>
                    <CloseIcon
                      className='pointer'
                      titleAccess='Close feedback form'
                    />
                  </IconButton>
                </div>
                <TextArea
                  value={ratingDescription}
                  onChange={e => setRatingDescription(e.currentTarget.value)}
                  className='f8 ba b--black-10 br2 pa1 w-100 border-box mv1'
                  height={50}
                  resize='none'
                  id='feedbackTextArea'
                />
                <Button
                  size='small'
                  autoSize
                  onClick={() => handleSubmitFeedback()}
                >
                  {intl.formatMessage(messages.submit)}
                </Button>
              </div>
            ) : (
              <div className='tc'>
                <div className='lh-copy mid-gray f8 fw6'>
                  {intl.formatMessage(messages.feedback)}
                </div>
                <Rating
                  name='feedbackRating'
                  value={rating}
                  max={5}
                  size={'small'}
                  onKeyDown={handleKeyDown}
                  onChange={handleRatingChange}
                  onChangeActive={handleRatingHover}
                />
              </div>
            ))}
        </div>
      </div>
    </Slide>
  )
}

export default Feedback
