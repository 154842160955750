import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps
} from '@material-ui/pickers'

type Props = KeyboardDateTimePickerProps

const DateTimePicker: FunctionComponent<Props> = props => {
  return (
    <KeyboardDateTimePicker
      placeholder='MM/DD/YYYY HH:mm'
      {...props}
      value={props.value || null}
      autoOk
      invalidDateMessage={
        <FormattedMessage
          id='DateTimePicker.InvalidDateMessage'
          defaultMessage='Invalid date format'
        />
      }
      inputVariant='outlined'
      views={['date', 'hours']}
      format='MM/DD/YYYY HH:mm'
      KeyboardButtonProps={{
        style: {
          padding: 0
        }
      }}
      InputAdornmentProps={{ position: 'start' }}
      onBlur={e => {
        e.preventDefault()
      }}
    />
  )
}

export default DateTimePicker
