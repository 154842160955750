import React, { useState, useMemo } from 'react'
import CompanyProfileHeader from 'supplier/Company/components/CompanyProfileHeader'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import StarsRating from 'shared/components/StarsRating'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import paths from '../../routes'
import parsePath from 'shared/utils/parsePath'
import SpendGauge from '../../../shared/components/SpendGauge'
import SpendTrend from '../../../shared/components/SpendTrend'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import InviteToCommunitiesDialogContainer from '../../../shared/containers/InviteToCommunitiesDialogContainer'
import iconAddCart from 'shared/assets/icons/add-collection.svg'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import { List, RecordOf, Map, fromJS } from 'immutable'
import Certification from 'shared/models/Certification'
import Location from 'shared/models/Location'
import { WebsiteWarningType } from 'shared/components/WebsiteWarning'
import useSmallScreen from 'shared/utils/useSmallScreen'
import ShareProfileDialog from '../ShareProfileDialog'
import Tooltip from 'shared/components/Tooltip'
import { AccessControl } from 'shared/models/AccessControl'
import BecomeTealbotDialog from 'shared/containers/BecomeTealbotDialog/BecomeTealbotDialog'
import RootState from 'shared/models/RootState'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { connect } from 'react-redux'
import { getReasonKey } from '../../../../data-portal/store/actions'
import { useHistory } from 'react-router'
import Select from 'react-select'
import CustomSelect from 'shared/components/Select'
import { useDispatch } from 'react-redux'
import { notify } from 'shared/actions'
import useIsFeatureEnabled from 'shared/utils/featureFlags/useIsFeatureEnabled'
import AddOutreachContactForm from '../../../Insights/containers/AddOutreachContactForm'
import AddIcon from '@material-ui/icons/AddCircle'
import ESGSurveyMessageSummary from '../ESGSurveyMessageSummary'
import { closeESGRequestDialog } from '../../actions'
import User from 'shared/models/User'
import ReportFormDialog from 'shared/containers/ReportFormDialog'
import sorting from 'shared/utils/sortContactsByNameAndInput'
import ContactItem from 'shared/components/ContactItem'
import Input from 'shared/components/Input'

const messages = defineMessages({
  update: {
    id: 'SupplierProfileHeader.PleaseUpdateYourSupplierProfile',
    defaultMessage: 'Please update your supplier profile.'
  },
  selfCertify: {
    id: 'SupplierProfileHeader.PleaseSelfCertify',
    defaultMessage: `Please log in to TealBook and complete the self-certification process to comply with {name}'s diversity program requirements. If you have any questions, please let us know by logging in and responding to this message. If you require assistance with the self-certification process, have a look at our detailed process guide here https://www.tealbook.com/resource/supplier-self-certification`
  },
  esgSurvey: {
    id: 'SupplierProfileHeader.PleaseCompleteESGSurvey',
    defaultMessage: `{name} has invited you to complete TealBook’s sustainability survey to capture information about your company’s Environment, Social, Governance (ESG) efforts. This survey is an opportunity for you to share your organization’s sustainability efforts with potential buyers, and the results will be publicly available to buyers on the TealBook platform. By taking just a few minutes to complete this survey, you could attract more business from sustainability-minded companies. Click the button below to get started.`
  }
})

type Props = {
  supplierId: string
  connected: boolean
  showAddToVet: boolean
  overallRating: number
  redirectFrom?: string
  contacts: List<RecordOf<User>>
  clientName: string
  recentPercentile: number
  recentTrend: number
  isTealbot: boolean
  relationshipId: string
  isInCollection: boolean
  hasClientCommunities: boolean
  reviewOptions: RecordOf<{ rating: string }>
  name: string
  logoUrl: string
  description: string
  websiteUrl: string
  address?: RecordOf<Location> | string
  recentAmount: number
  attachments: List<
    RecordOf<{
      createdBy: string
      createdDate: Date
      documentFileUrl?: string
      expirationDate: Date
      type: string
    }>
  >
  certifications: List<[string, List<RecordOf<Certification>>]>
  coverUrl?: string
  phone?: string
  linkedInUrl?: string
  facebookUrl?: string
  twitterId?: string
  supplierType?: string
  alternativeNames?: List<string>
  status?: string
  domains?: Array<string>
  buyerDomains: List<string>
  cpiLocation?: RecordOf<{
    countryCpi: number
    country: string
  }>
  websiteWarning?: WebsiteWarningType
  attributes?: {
    isDoNotUse?: boolean
    isExisting?: boolean
    isNonRecruit?: boolean
    isPreferred?: boolean
    isPrivate?: boolean
    isRestricted?: boolean
    isSanctionedCompany?: boolean
    isSanctionedCountry?: boolean
    sanctionedCountryList?: List<string>
  }
  onConnect: () => void
  onUnfollow: () => void
  onAddVetSupplier: () => void
  onAddCollection: () => void
  onRemoveCollection: () => void
  onClickMarkAcquired: () => void
  onClickMarkInactive: () => void
  removeRelationship: (arg: string) => void
  onShare: (arg: { message: string; userToMessage: Array<string> }) => void
  onRequestProfileUpdate: (arg: { supplierId: string; message: string }) => void
  onRequestSelfCertify: (arg: {
    supplierId: string
    relationshipId: string
    supplierName: string
    requestedContactId: string
  }) => void
  onRequestESGSurvey: (arg: {
    supplierId: string
    relationshipId: string
    supplierName: string
    requestedContactId: string
  }) => void
  onMessageUser: (arg: string) => void
  attachmentTypes: Map<string, Map<string, string>>
  relationshipTypes: Map<string, Map<string, string>>
  validations?: List<
    RecordOf<{
      confirmed: boolean
      subCategory: string
      timeStamp: string
    }>
  >
  accessControl: AccessControl
  hasAccessToDataPortal: boolean
  onGetReasonKey: (payload: {
    orgUnitId: string
    orgName: string
    userMobilePhone: string
  }) => void
  onClickNewUser: () => void
  onClickCancel: () => void
  userMobilePhone: string
  surveySubmitted: boolean
  openESGSurveyDialog: boolean
  onTransferSpend: (payload: {
    transferFromRelationshipId: string
    internalSupplierId: string
    transferToOrgUnitId: string
  }) => void
  internalSupplierIds: List<string>
}

export const SupplierProfileHeader = (props: Props) => {
  const {
    isTealbot,
    relationshipId,
    removeRelationship,
    isInCollection,
    hasClientCommunities,
    supplierId,
    connected,
    onConnect,
    onUnfollow,
    showAddToVet,
    onAddVetSupplier,
    onAddCollection,
    onRemoveCollection,
    overallRating,
    reviewOptions,
    name,
    logoUrl,
    description,
    websiteUrl,
    recentPercentile,
    recentTrend,
    recentAmount,
    attachments,
    certifications,
    redirectFrom,
    contacts,
    onClickMarkInactive,
    onClickMarkAcquired,
    onRequestProfileUpdate,
    onRequestSelfCertify,
    onRequestESGSurvey,
    onMessageUser,
    onClickNewUser,
    onClickCancel,
    buyerDomains,
    attachmentTypes,
    relationshipTypes,
    accessControl,
    hasAccessToDataPortal,
    onGetReasonKey,
    userMobilePhone,
    onShare,
    clientName,
    surveySubmitted,
    openESGSurveyDialog,
    onTransferSpend,
    internalSupplierIds,
    ...rest
  } = props
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()
  const matchMobile = useSmallScreen()

  const [sharingSupplier, setSharingSupplier] = useState<boolean>(false)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [openInviteDialog, setOpenInviteDialog] = useState<boolean>(false)
  const [openReportDialog, setOpenReportDialog] = useState<boolean>(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(
    false
  )
  const [openSelfCertifyDialog, setOpenSelfCertifyDialog] = useState<boolean>(
    false
  )
  const [requestUpdateMessage, setRequestUpdateMessage] = useState<string>(
    intl.formatMessage(messages.update)
  )
  const [requestSelfCertifyMessage, setRequestSelfCertifyMessage] = useState<
    string
  >(intl.formatMessage(messages.selfCertify, { name: clientName }))
  const [requestESGSurveyMessage, setrequestESGSurveyMessage] = useState<
    string
  >(intl.formatMessage(messages.esgSurvey, { name: clientName }))
  const [isESGSurveyRequest, setisESGSurveyRequest] = useState<boolean>(false)
  const [selectInput, setSelectInput] = useState<string>('')
  const [selectedContactId, setSelectedContactId] = useState<string>('')
  const [openTransferSpendDialog, setOpenTransferSpendDialog] = useState<
    boolean
  >(false)
  const [internalSupplierId, setInternalSupplierId] = useState<string>('')
  const [transferToOrgUnitId, setTransferToOrgUnitId] = useState<string>('')

  const isSelfCertificationRequestEnabled = useIsFeatureEnabled(
    'selfCertificationRequest'
  )
  const handleSelectInputChange = (inputValue: string) => {
    setSelectInput(inputValue)
  }
  const sortedContacts: List<RecordOf<User>> | List<undefined> = useMemo(
    () => sorting.sortContactsByNameAndInput(contacts, selectInput),
    [contacts, selectInput]
  )
  const sortedContactsBySustainability:
    | List<RecordOf<User>>
    | List<undefined> = useMemo(
    () => sorting.sortContactsBySustainabilityContact(contacts, selectInput),
    [contacts, selectInput]
  )

  const filterOption = props => {
    const user = props.data
    const userFullName = `${user.firstName &&
      user.firstName.trim()} ${user.lastName &&
      user.lastName.trim()}`.toLowerCase()

    return userFullName.includes(selectInput.toLowerCase())
  }

  const formatOptionLabel = (user, data) => {
    return (
      user && (
        <ContactItem
          key={user.id}
          firstName={user.firstName}
          lastName={user.lastName}
          profilePictureUrl={user.profilePictureUrl}
          title={user.title}
          isSustainabilityContact={user.isSustainabilityContact}
          isEsgSurvey={openESGSurveyDialog}
        />
      )
    )
  }

  const handleOpenRequestProfileUpdate = () => {
    setDialogOpen(true)
    setRequestUpdateMessage(intl.formatMessage(messages.update))
  }

  const handleRequestProfileUpdate = e => {
    e.preventDefault()
    onRequestProfileUpdate({ supplierId, message: requestUpdateMessage })
    setDialogOpen(false)
  }

  const handleRequestUpdateMessageChange = e => {
    setRequestUpdateMessage(e.target.value)
  }

  const handleRequestClose = () => {
    setDialogOpen(false)
  }

  const handleCloseESGSurveyDialog = () => {
    dispatch(closeESGRequestDialog())
  }

  const handleESGSurveyMessage = e => {
    setrequestESGSurveyMessage(e.target.value)
  }
  const handleCancelESGSurveyRequest = e => {
    handleCloseESGSurveyDialog()
  }

  const handleCancelESGNewUserForm = () => {
    onClickCancel()
    handleCloseESGSurveyDialog()
  }

  const handleCancelSelfCertifyNewUserForm = () => {
    onClickCancel()
    handleToggleSelfCertifyDialog()
  }
  const handleESGSurveyRequest = e => {
    e.preventDefault()
    onMessageUser(
      fromJS({
        userToMessageId: selectedContactId,
        message: requestESGSurveyMessage,
        shareESGSurveyLink: true
      })
    )
    onRequestESGSurvey({
      supplierId: supplierId,
      relationshipId: relationshipId,
      supplierName: name,
      requestedContactId: selectedContactId
    })
    dispatch(notify({ message: 'Message succesfully sent' }))

    handleCloseESGSurveyDialog()
  }
  const handleOnClickAddUserButtonInESGSurveyDialog = () => {
    setisESGSurveyRequest(true)
    onClickNewUser()
    handleCloseESGSurveyDialog()
  }
  const handleToggleSelfCertifyDialog = () => {
    setOpenSelfCertifyDialog(!openSelfCertifyDialog)
  }

  const handleSelfCertifyMessage = e => {
    setRequestSelfCertifyMessage(e.target.value)
  }

  const handleCancelSelfCertifyRequest = e => {
    handleToggleSelfCertifyDialog()
  }
  const handleContactSelected = (data, metaAction) => {
    setSelectedContactId(data.id)
    setSelectInput('')
  }

  const handleSelfCertifyRequest = e => {
    e.preventDefault()
    onMessageUser(
      fromJS({
        userToMessageId: selectedContactId,
        message: requestSelfCertifyMessage
      })
    )
    onRequestSelfCertify({
      supplierId: supplierId,
      relationshipId: relationshipId,
      supplierName: name,
      requestedContactId: selectedContactId
    })
    dispatch(notify({ message: 'Message succesfully sent' }))

    handleToggleSelfCertifyDialog()
  }
  const getMyCompanyPath = scrollTo => {
    const path = redirectFrom
      ? `${parsePath(paths.myCompany, {
          supplierId
        })}?${scrollTo}=true&redirectFrom=${redirectFrom}`
      : `${parsePath(paths.myCompany, { supplierId })}?${scrollTo}=true`

    return path
  }
  const handleOnClickAddUserButton = () => {
    setisESGSurveyRequest(false)
    onClickNewUser()
    handleToggleSelfCertifyDialog()
  }
  const toggleReportDialog = () => {
    setOpenReportDialog(!openReportDialog)
  }

  const toggleInviteDialog = () => {
    setOpenInviteDialog(!openInviteDialog)
  }

  const toggleConfirmationDialog = () => {
    setOpenConfirmationDialog(!openConfirmationDialog)
  }

  const toggleSharingSupplier = () => {
    setSharingSupplier(!sharingSupplier)
  }

  const handleShareSupplier = ({ userToMessage, message }) => {
    setSharingSupplier(false)

    onShare({
      message,
      userToMessage
    })
  }

  const handleToggleTransferSpendDialog = () => {
    setOpenTransferSpendDialog(!openTransferSpendDialog)
    setInternalSupplierId('')
    setTransferToOrgUnitId('')
  }
  const handleTransferSpend = e => {
    e.preventDefault()
    onTransferSpend({
      transferFromRelationshipId: relationshipId,
      internalSupplierId,
      transferToOrgUnitId
    })
    handleToggleTransferSpendDialog()
  }

  const inValidOrgUnitId = () => {
    if (transferToOrgUnitId) {
      let transferToOrgUnitIdArray = transferToOrgUnitId.split('-')
      if (
        transferToOrgUnitIdArray[0] !== 'OrgUnit' ||
        transferToOrgUnitIdArray.length < 3
      ) {
        return true
      } else return false
    }
    return false
  }
  return (
    <CompanyProfileHeader
      publicView
      name={name}
      logoUrl={logoUrl}
      description={description}
      websiteUrl={websiteUrl}
      attachments={attachments && attachments.toJS()}
      certifications={certifications}
      attachmentTypes={attachmentTypes}
      relationshipTypes={relationshipTypes}
      {...rest}
    >
      <Dialog
        onClose={handleRequestClose}
        open={dialogOpen}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
      >
        <form onSubmit={handleRequestProfileUpdate}>
          <DialogTitle>
            <FormattedMessage
              id='SupplierProfileHeader.RequestProfileUpdate'
              defaultMessage='Request Profile Update'
            />
          </DialogTitle>
          <DialogContent className='mt3'>
            <Label>
              <FormattedMessage
                id='SupplierProfileHeader.Message'
                defaultMessage='Message'
              />
            </Label>
            <TextArea
              value={requestUpdateMessage}
              onChange={handleRequestUpdateMessageChange}
              maxLength={250}
            />
          </DialogContent>
          <DialogActions className='fixed static-ns bottom-1'>
            <Button
              size={matchMobile ? 'large' : undefined}
              label={
                <FormattedMessage
                  id='SupplierProfileHeader.Send'
                  defaultMessage='Send'
                />
              }
              type='submit'
            />
            <Button
              size={matchMobile ? 'large' : undefined}
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleRequestClose}
              secondary
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        onClose={handleToggleSelfCertifyDialog}
        open={openSelfCertifyDialog}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
      >
        <form onSubmit={handleSelfCertifyRequest}>
          <DialogTitle>
            <FormattedMessage
              id='SupplierProfileHeader.RequestSelfCertify'
              defaultMessage='Self Certification Request'
            />
          </DialogTitle>
          <DialogContent className='mt3'>
            <Text>
              <FormattedMessage
                id='SupplierOutreach.ContactSelectInstruction'
                defaultMessage='Indicate a Contact you would like to complete this request'
              />
            </Text>
            <Label>
              <FormattedMessage
                id='SupplierProfileHeader.ContactSelect'
                defaultMessage='Contacts'
              />
            </Label>
            <Select
              aria-label='Select Contact'
              InputValue={selectInput}
              onInputChange={handleSelectInputChange}
              onChange={handleContactSelected}
              placeholder={
                (
                  <FormattedMessage
                    id='UserLookup.Select Contact'
                    defaultMessage='Type in Contact Name'
                  />
                ) as any
              }
              options={sortedContacts.toJS()}
              formatOptionLabel={formatOptionLabel}
              filterOption={filterOption}
              getOptionValue={opt => opt.id || opt.value}
              noOptionsMessage={inputValue => 'No Contacts Found'}
              controlShouldRenderValue={true}
              styles={{
                control: base => ({
                  ...base,
                  height: 30
                }),
                input: base => ({
                  ...base,
                  fontSize: '.875rem',
                  height: 30
                }),
                placeholder: base => ({
                  ...base,
                  fontSize: '.875rem'
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                noOptionsMessage: base => ({ ...base, fontSize: '.875rem' }),
                option: (base, { isDisabled, isFocused }) => ({
                  ...base,
                  backgroundColor: isDisabled
                    ? 'inherit'
                    : isFocused
                    ? '#E0E0E0'
                    : 'inherit',
                  ':hover': {
                    ...base[':hover'],
                    backgroundColor: !isDisabled && '#e0e0e0'
                  }
                })
              }}
              menuPortalTarget={document.body}
            />
            <div className='mb3 mt3'>
              <div className='dib w-35'>
                <Button
                  color='primary'
                  variant='text'
                  startIcon={<AddIcon />}
                  label={
                    <FormattedMessage
                      id='SupplierProfileHeader.AddContact'
                      defaultMessage='Add a New Contact'
                    />
                  }
                  onClick={() => handleOnClickAddUserButton()}
                />
              </div>
            </div>
            <Label>
              <FormattedMessage
                id='SupplierProfileHeader.AddMessage'
                defaultMessage='Add Message'
              />
            </Label>
            <TextArea
              value={requestSelfCertifyMessage}
              onChange={handleSelfCertifyMessage}
              maxLength={500}
            />
          </DialogContent>
          <DialogActions className='fixed static-ns bottom-1'>
            <Button
              size={matchMobile ? 'large' : undefined}
              label={
                <FormattedMessage
                  id='SupplierProfileHeader.Send'
                  defaultMessage='Send'
                />
              }
              disabled={!selectedContactId}
              type='submit'
            />
            <Button
              size={matchMobile ? 'large' : undefined}
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleCancelSelfCertifyRequest}
              secondary
              className='mr3'
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>
      <AddOutreachContactForm
        defaultMessage={
          isESGSurveyRequest
            ? intl.formatMessage(messages.esgSurvey, {
                name: clientName
              })
            : intl.formatMessage(messages.selfCertify, {
                name: clientName
              })
        }
        requestSelfCertify={isESGSurveyRequest ? false : true}
        requestESGSurvey={isESGSurveyRequest ? true : false}
        shareESGSurveyLink={isESGSurveyRequest ? true : false}
        supplierId={supplierId}
        supplierName={name}
        onClickCancel={
          isESGSurveyRequest
            ? handleCancelESGNewUserForm
            : handleCancelSelfCertifyNewUserForm
        }
        relationshipId={relationshipId}
      />
      <Dialog
        onClose={handleCloseESGSurveyDialog}
        open={openESGSurveyDialog}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
      >
        <form onSubmit={handleESGSurveyRequest}>
          <DialogTitle>
            <FormattedMessage
              id='SupplierProfileHeader.ESGSurvey'
              defaultMessage='Sustainability Survey Request'
            />
          </DialogTitle>
          <DialogContent className='mt3'>
            <Text>
              <FormattedMessage
                id='SupplierProfileHeader.ContactSelectInstruction'
                defaultMessage='Indicate a Contact you would like to complete this request'
              />
            </Text>
            <Label>
              <FormattedMessage
                id='SupplierProfileHeader.ContactSelect'
                defaultMessage='Contacts'
              />
            </Label>
            <Select
              aria-label='Select Contact'
              InputValue={selectInput}
              onInputChange={handleSelectInputChange}
              onChange={handleContactSelected}
              placeholder={
                (
                  <FormattedMessage
                    id='UserLookup.Select Contact'
                    defaultMessage='Type in Contact Name'
                  />
                ) as any
              }
              options={sortedContactsBySustainability.toJS()}
              formatOptionLabel={formatOptionLabel}
              filterOption={filterOption}
              getOptionValue={opt => opt.id || opt.value}
              noOptionsMessage={inputValue => 'No Contacts Found'}
              controlShouldRenderValue={true}
              styles={{
                control: base => ({
                  ...base,
                  height: 30
                }),
                input: base => ({
                  ...base,
                  fontSize: '.875rem',
                  height: 30
                }),
                placeholder: base => ({
                  ...base,
                  fontSize: '.875rem'
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                noOptionsMessage: base => ({ ...base, fontSize: '.875rem' }),
                option: (base, { isDisabled, isFocused }) => ({
                  ...base,
                  backgroundColor: isDisabled
                    ? 'inherit'
                    : isFocused
                    ? '#E0E0E0'
                    : 'inherit',
                  ':hover': {
                    ...base[':hover'],
                    backgroundColor: !isDisabled && '#e0e0e0'
                  }
                })
              }}
              menuPortalTarget={document.body}
            />
            <div className='mb3 mt3'>
              <div className='dib w-35'>
                <Button
                  color='primary'
                  variant='text'
                  startIcon={<AddIcon />}
                  label={
                    <FormattedMessage
                      id='SupplierProfileHeader.AddContact'
                      defaultMessage='Add a New Contact'
                    />
                  }
                  onClick={() => handleOnClickAddUserButtonInESGSurveyDialog()}
                />
              </div>
            </div>
            <Label>
              <FormattedMessage
                id='SupplierProfileHeader.AddMessage'
                defaultMessage='Add Message'
              />
            </Label>
            <TextArea
              value={requestESGSurveyMessage}
              onChange={handleESGSurveyMessage}
              maxLength={500}
            />
            <ESGSurveyMessageSummary
              name={`Sustainability Survey Link`}
              description={`Link to the Sustainability Survey Form`}
            />
          </DialogContent>
          <DialogActions className='fixed static-ns bottom-1'>
            <Button
              size={matchMobile ? 'large' : undefined}
              label={
                <FormattedMessage
                  id='SupplierProfileHeader.SendRequest'
                  defaultMessage='Send Request'
                />
              }
              disabled={!selectedContactId}
              type='submit'
            />
            <Button
              size={matchMobile ? 'large' : undefined}
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleCancelESGSurveyRequest}
              secondary
              className='mr3'
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>

      <ReportFormDialog
        onToggleReportDialog={toggleReportDialog}
        openReportDialog={openReportDialog}
        taskType='reportSupplier'
      />
      <Dialog
        onClose={handleToggleTransferSpendDialog}
        open={openTransferSpendDialog}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
      >
        <form onSubmit={handleTransferSpend}>
          <DialogTitle>
            <FormattedMessage
              id='SupplierProfileHeader.TransferSpendToDifferentSupplier'
              defaultMessage='Transfer spend to another Supplier'
            />
          </DialogTitle>
          <DialogContent>
            <Label>
              <FormattedMessage
                id='SupplierProfileHeader.Steps'
                defaultMessage='Steps for transferring spend to another supplier'
              />
            </Label>
            <ol className='lh-copy f7 fw4 mid-gray'>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendOne'
                  defaultMessage='Log in as Concierge for the client in question'
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendTwo'
                  defaultMessage='Navigate to supplier profile you want to transfer spend FROM'
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendThree'
                  defaultMessage="Input the OrgUnit id for the supplier you're transferring spend TO"
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendFour'
                  defaultMessage="Input the internal supplier id that's being transferred"
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendFive'
                  defaultMessage='Click "Transfer Spend"'
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendSix'
                  defaultMessage="Check My Company and EcoVadis tab, if there isn't any other activity besides the spend you're transferring, you can 'Delete Relationship'"
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendsSeven'
                  defaultMessage='Navigate to Admin and click "Compute Trends"'
                />
              </li>
            </ol>
            <Label>
              <FormattedMessage
                id='SupplierProfileHeader.transferToOrgUnitId'
                defaultMessage='Transfer spend to OrgUnit Id'
              />
            </Label>
            <Input
              name='transferToOrgUnitId'
              required
              value={transferToOrgUnitId}
              onChange={e => setTransferToOrgUnitId(e.currentTarget.value)}
              placeholder={'OrgUnit-5157071787917312-5629499534213120'}
              type='text'
              meta={{
                error: inValidOrgUnitId() ? 'Invalid entry' : '',
                touched: true
              }}
            />
            <Label>
              <FormattedMessage
                id='SupplierProfileHeader.internalSupplierId'
                defaultMessage='Internal supplier Id'
              />
            </Label>
            <Text>
              <FormattedMessage
                id='SupplierProfileHeader.SelectInternalSupplierIdNote'
                defaultMessage='Note: these are the internal supplier ids currently attached to this supplier by this buyer'
              />
            </Text>
            <CustomSelect
              onChange={e => setInternalSupplierId(e.currentTarget.value)}
              value={internalSupplierId}
              aria-label='Select internal supplier Id'
              required
            >
              <FormattedMessage
                id='SupplierProfileHeader.SelectInternalSupplierId'
                defaultMessage='Select internal supplier Id'
              >
                {label => <option value=''>{label}</option>}
              </FormattedMessage>
              {internalSupplierIds.map(id => (
                <option key={id} value={id}>
                  {id}
                </option>
              ))}
            </CustomSelect>
          </DialogContent>
          <DialogActions className='fixed static-ns bottom-1'>
            <Button
              size={matchMobile ? 'large' : undefined}
              label={
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpend'
                  defaultMessage='Transfer Spend'
                />
              }
              type='submit'
            />
            <Button
              size={matchMobile ? 'large' : undefined}
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleToggleTransferSpendDialog}
              secondary
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>
      {accessControl.access === 'full' && (
        <>
          <ShareProfileDialog
            matchMobile={matchMobile}
            onClose={toggleSharingSupplier}
            open={sharingSupplier}
            fullScreen={matchMobile}
            supplierName={name}
            logoUrl={logoUrl}
            description={description}
            handleShareSupplier={handleShareSupplier}
            buyerDomains={buyerDomains}
          />

          <InviteToCommunitiesDialogContainer
            supplierId={supplierId}
            open={openInviteDialog}
            onCloseInviteDialog={toggleInviteDialog}
          />

          <ConfirmationDialog
            open={openConfirmationDialog}
            onClose={toggleConfirmationDialog}
            onConfirm={() => removeRelationship(relationshipId)}
          >
            <Text>
              <FormattedMessage
                id='SupplierProfileHeader.ConfirmDeleteRelationship'
                defaultMessage='Are you sure you want to remove the relationship?'
              />
            </Text>
          </ConfirmationDialog>
        </>
      )}

      <div className='tr'>
        {accessControl.access === 'full' && (
          <>
            <SpendGauge
              recentPercentile={recentPercentile}
              recentAmount={recentAmount}
              definition
              linkTo={getMyCompanyPath('scrollToAttribute')}
            />
            <SpendTrend
              recentTrend={recentTrend}
              linkTo={getMyCompanyPath('scrollToAttribute')}
            />
            {reviewOptions && reviewOptions.get('rating') && !!overallRating && (
              <div className='dib mr4'>
                <StarsRating
                  value={overallRating}
                  mini
                  onClick={() =>
                    history.push(getMyCompanyPath('scrollToReviews'))
                  }
                />
              </div>
            )}

            {!connected ? (
              !isTealbot ? (
                <h5
                  className='f7 fw4 dim mid-gray bg-light-gray br2 ba pointer dib b--none pv2 ph2-5 ma0 mr2'
                  onClick={onConnect}
                >
                  <FormattedMessage
                    id='SupplierProfileHeader.Follow'
                    defaultMessage='Follow'
                  />
                </h5>
              ) : null
            ) : (
              <h5 className='f7 fw4 teal br2 ba dib b--none ma0 mr2'>
                <FormattedMessage
                  id='SupplierProfileHeader.Following'
                  defaultMessage='Following'
                />
              </h5>
            )}
          </>
        )}

        {!isInCollection ? (
          accessControl.access === 'punchout' ? (
            <Button size='small' autoSize onClick={onAddCollection}>
              <FormattedMessage
                id='SupplierProfileHeader.AddToList'
                defaultMessage='Add to List'
              />
            </Button>
          ) : (
            <Tooltip
              title={
                <FormattedMessage
                  id='SupplierProfileHeader.AddToCollection'
                  defaultMessage={
                    'These are your currently selected suppliers. TealBook keeps track of them between sessions. They also show up to the right side of the Supplier list.'
                  }
                />
              }
            >
              <img
                src={iconAddCart}
                alt='add to collection'
                className='w1-5 v-mid pointer dim ml3 mr2'
                onClick={onAddCollection}
              />
            </Tooltip>
          )
        ) : accessControl.access === 'punchout' ? (
          <Button
            size='small'
            variant='text'
            autoSize
            caution
            onClick={onRemoveCollection}
          >
            <FormattedMessage
              id='SupplierProfileHeader.RemoveFromList'
              defaultMessage='Remove from List'
            />
          </Button>
        ) : null}

        {showAddToVet && (
          <h5
            className='f8 fw6 ma0 dib v-mid gray pointer underline-hover hover-teal dim'
            onClick={onAddVetSupplier}
          >
            <span
              className='dib br--left br2 white pl1 pv1 pr2 v-mid f9 fw4'
              style={{ background: '#8B8B8B' }}
            >
              RFI
            </span>
            <span
              className='dib bg-white ba br-100 v-mid relative tc f4 nl2'
              style={{
                color: '#8B8B8B',
                height: '15px',
                width: '15px',
                lineHeight: '15px',
                borderColor: '#8B8B8B',
                borderWidth: '2px'
              }}
            >
              +
            </span>
          </h5>
        )}

        <DropDownMenu>
          {accessControl.access === 'full' && hasClientCommunities && (
            <MenuItem onClick={toggleInviteDialog}>
              <FormattedMessage
                id='SupplierProfileHeader.InviteToCommunity'
                defaultMessage='Invite to community'
              />
            </MenuItem>
          )}

          {accessControl.access === 'full' && (
            <MenuItem onClick={toggleSharingSupplier}>
              <FormattedMessage
                id='SupplierProfileHeader.Share'
                defaultMessage='Share'
              />
            </MenuItem>
          )}

          <MenuItem onClick={handleOpenRequestProfileUpdate}>
            <FormattedMessage
              id='SupplierProfileHeader.RequestProfileUpdateLowercase'
              defaultMessage='Request profile update'
            />
          </MenuItem>

          {isSelfCertificationRequestEnabled && (
            <MenuItem onClick={handleToggleSelfCertifyDialog}>
              <FormattedMessage
                id='SupplierProfileHeader.RequestSelfCertification'
                defaultMessage='Request Self Certification'
              />
            </MenuItem>
          )}

          <MenuItem onClick={toggleReportDialog}>
            <FormattedMessage
              id='SupplierProfileHeader.Report'
              defaultMessage='Report'
            />
          </MenuItem>

          {accessControl.access === 'full' && connected && (
            <MenuItem onClick={onUnfollow}>
              <FormattedMessage
                id='SupplierProfileHeader.Unfollow'
                defaultMessage='Unfollow'
              />
            </MenuItem>
          )}
          {isTealbot && (
            <MenuItem onClick={onClickMarkAcquired}>
              <FormattedMessage
                id='SupplierProfileHeader.markAsAcquired'
                defaultMessage='Mark as Acquired'
              />
            </MenuItem>
          )}

          {isTealbot && (
            <MenuItem onClick={onClickMarkInactive}>
              <FormattedMessage
                id='SupplierProfileHeader.markAsInactive'
                defaultMessage='Mark as Inactive'
              />
            </MenuItem>
          )}
          {isTealbot && relationshipId && (
            <MenuItem onClick={toggleConfirmationDialog}>
              <FormattedMessage
                id='SupplierProfileHeader.DeleteRelationship'
                defaultMessage='Delete Relationship'
              />
            </MenuItem>
          )}
          {isTealbot && relationshipId && !!internalSupplierIds.size && (
            <MenuItem onClick={handleToggleTransferSpendDialog}>
              <FormattedMessage
                id='SupplierProfileHeader.TransferSpend'
                defaultMessage='Transfer Spend'
              />
            </MenuItem>
          )}

          {hasAccessToDataPortal && (
            <MenuItem
              onClick={() => {
                //POST for oneTime pin code
                //saving orgUnitId and orgName to redux state so can pre-populate dialog once SMS successfully sent
                onGetReasonKey({
                  orgUnitId: supplierId,
                  orgName: name,
                  userMobilePhone: userMobilePhone
                })
              }}
            >
              <FormattedMessage
                id='SupplierProfileHeader.LoginAsConcierge'
                defaultMessage='Login as Concierge'
              />
            </MenuItem>
          )}
        </DropDownMenu>
      </div>
      <BecomeTealbotDialog />
    </CompanyProfileHeader>
  )
}

export default connect(
  (state: RootState) => {
    const user = sessionSelectors.getUser(state)
    return {
      userMobilePhone: user && user.get('mobilePhone')
    }
  },
  {
    onGetReasonKey: getReasonKey
  }
)(SupplierProfileHeader)
