import React, { Fragment, FunctionComponent, FormEvent } from 'react'
import Input from '../Input'
import TextArea from '../TextArea'
import { Field } from 'redux-form/immutable'
import Warning from 'shared/components/Warning'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import withWidth from '@material-ui/core/withWidth'
import { RecordOf } from 'immutable'

type Props = {
  error: string
  open: boolean
  successMessageState: boolean
  onRequestClose: () => void
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  width: string
  initialValues: RecordOf<{
    sendTo: string
    name: string
    email: string
    phoneNumber: string
  }>
  errorMessage: any
}
export const DialogContactUs: FunctionComponent<Props> = props => {
  const {
    open,
    onRequestClose,
    error,
    onSubmit,
    successMessageState,
    width
  } = props

  const mobileBreakpoint = ['xs', 'sm']

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      fullWidth
      disableBackdropClick={!successMessageState}
      fullScreen={mobileBreakpoint.includes(width)}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>
          <FormattedMessage
            id='DialogContactUs.ContactUs'
            defaultMessage='Contact Us'
          />
        </DialogTitle>
        <DialogContent className='mt3'>
          {successMessageState ? (
            <Text>
              <FormattedMessage
                id='DialogContactUs.ThankYouForSendingAMessage'
                defaultMessage='Thank you for sending a message. We will get back to you within 1 business day.'
              />
            </Text>
          ) : (
            <Fragment>
              <div className='mb3'>
                <div className='pb2 fw6 f7'>
                  <FormattedMessage
                    id='DialogContactUs.Name'
                    defaultMessage='Name'
                  />
                </div>
                <Field
                  name='name'
                  component={Input}
                  maxLength={50}
                  type='text'
                  placeholder='i.e. John Smith'
                  required
                />
              </div>

              <div className='mb3'>
                <div className='pb2 fw6 f7'>
                  <FormattedMessage
                    id='DialogContactUs.Email'
                    defaultMessage='What is the best email to reach you at?'
                  />
                </div>
                <Field
                  name='email'
                  component={Input}
                  type='text'
                  placeholder='example@company.com'
                  required
                />
              </div>

              <div className='mb3'>
                <div className='pb2 fw6 f7'>
                  <FormattedMessage
                    id='DialogContactUs.PhoneNumber'
                    defaultMessage='Phone Number'
                  />
                </div>
                <Field
                  name='phoneNumber'
                  component={Input}
                  type='text'
                  placeholder='i.e. 123-345-6789'
                  required
                />
              </div>

              {props.children}

              <div className='pb2 fw6 f7'>
                <FormattedMessage
                  id='DialogContactUs.Message'
                  defaultMessage='Message'
                />
              </div>
              <Field
                name='message'
                component={textAreaInput}
                maxLength={500}
                type='text'
                required
              />

              {error && <Warning message={error} />}
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          {!successMessageState && (
            <Fragment>
              <Button
                label={
                  <FormattedMessage
                    id='DialogContactUs.Send'
                    defaultMessage='Send'
                  />
                }
                type='submit'
                autoSize
              />
              <Button
                label={<FormattedMessage id='CancelButton' />}
                onClick={onRequestClose}
                secondary
                autoSize
              />
            </Fragment>
          )}

          {successMessageState && (
            <Button
              label={
                <FormattedMessage
                  id='DialogContactUs.Close'
                  defaultMessage='Close'
                />
              }
              type='button'
              className='w-auto'
              onClick={onRequestClose}
            />
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}

export const textAreaInput = props => {
  return (
    <FormattedMessage
      id='DialogContactUs.LetUsKnowIfYouHaveAnyQuestions'
      defaultMessage='Let us know if you have any questions.'
    >
      {message => (
        <TextArea placeholder={message} additionalClass='h4' {...props} />
      )}
    </FormattedMessage>
  )
}

export default withWidth()(DialogContactUs)
