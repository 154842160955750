import { call, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import users from 'shared/utils/api/users'
import { notify } from 'shared/actions'

export function* bulkAddUsersSaga(action) {
  const newUsers = yield call(users.bulkAddUsers, action.payload)

  let errMessages = ''
  newUsers.errList.forEach(error => {
    errMessages +=
      'Could not create user ' +
      error.userEmailOrName +
      ': ' +
      error.err.customClientErrorMessage +
      '\n'
  })
  if (errMessages.length > 0) {
    yield put(
      notify({
        message: errMessages
      })
    )
  }

  return newUsers
}

export default createApiRequestSaga(bulkAddUsersSaga)
