export const LOAD_TIER2_ALLOCATED_SPEND =
  'supplier/tier2/LOAD_TIER2_ALLOCATED_SPEND'
export const UPDATE_TIER2_ALLOCATED_SPEND =
  'supplier/tier2/UPDATE_TIER2_ALLOCATED_SPEND'
export const GET_TIER2_COMMUNITIES = 'supplier/tier2/GET_TIER2_COMMUNITIES'

export const LOAD_TIER2_DIVERSE_SUMMARY =
  'supplier/tier2/LOAD_TIER2_DIVERSE_SUMMARY'
export const POST_TIER2_DIVERSE_SUMMARY =
  'supplier/tier2/POST_TIER2_DIVERSE_SUMMARY'
export const REMOVE_TIER2_DIVERSE_SUMMARY =
  'supplier/tier2/REMOVE_TIER2_DIVERSE_SUMMARY'
