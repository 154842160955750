import React, { Component, Fragment, ReactNode } from 'react'
import { connect } from 'react-redux'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import ListItem from 'shared/components/List/ListItem'
import OrgLogo from 'shared/components/OrgLogo'
import Link from 'shared/components/Link'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import classNames from 'classnames'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import { FormattedMessage } from 'react-intl'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import CommentIcon from '@material-ui/icons/Comment'
import UserStack from 'shared/components/UserStack'
import withUser from 'shared/utils/withUser'
import Popover from '@material-ui/core/Popover'
import Avatar from 'shared/components/Avatar'
import ImageWarning from 'shared/assets/icons/warning.svg'
import UserLookup from 'shared/components/UserLookup'
import Divider from 'shared/components/Divider'
import { addContactSendTo } from '../../actions'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from 'shared/components/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from 'shared/components/Button'
import { RecordOf, List } from 'immutable'
import User from 'shared/models/User'
import Scrollable from 'shared/components/Scrollable'
import { CSSTransition } from 'react-transition-group'
import RootState from 'shared/models/RootState'

type SendToContactProps = {
  userId: string
  vbsId?: string
  firstName: string
  lastName: string
  profileUrl: string
  profilePictureUrl?: string
  secondaryText?: ReactNode
  primary?: boolean
  secondary?: boolean
  onClickRemove?: (params: {
    vetBuyerSupplierId: string
    userId: string
  }) => void
  setPrimary?: () => void
  setSecondary?: () => void
}

export const SendToContact = withUser((props: SendToContactProps) => {
  const {
    onClickRemove,
    setPrimary,
    setSecondary,
    vbsId,
    userId,
    firstName,
    lastName,
    profilePictureUrl,
    profileUrl,
    secondaryText,
    primary,
    secondary
  } = props

  return (
    <div className='flex mv1 items-center' onClick={e => e.stopPropagation()}>
      <Link to={profileUrl}>
        <Avatar
          url={profilePictureUrl}
          className='w1-5 ba b--white bw1 mr1 v-mid dib'
          highlight={primary ? 'b--gold' : secondary ? 'b--yellow' : undefined}
          name={`${firstName || ''} ${lastName || ''}`}
        />
      </Link>
      <div className='flex-auto'>
        <Link to={profileUrl}>
          <Text className='hover-teal'>
            {primary ? (
              <span className='br-pill ph2 mr1 bg-gold mid-gray'>
                <FormattedMessage
                  id='VetSupplierListItem.1st'
                  defaultMessage='1st'
                />
              </span>
            ) : secondary ? (
              <span className='br-pill ph2 mr1 bg-yellow mid-gray'>
                <FormattedMessage
                  id='VetSupplierListItem.2nd'
                  defaultMessage='2nd'
                />
              </span>
            ) : (
              ''
            )}
            {firstName} {lastName}
          </Text>
        </Link>
        <div className='f9 ma0 gray'>{secondaryText}</div>
      </div>
      {(onClickRemove || setPrimary || setSecondary) && (
        <DropDownMenu>
          {setPrimary && (
            <MenuItem onClick={setPrimary}>
              <FormattedMessage
                id='VetSupplierListItem.ContactSecondary'
                defaultMessage='Set Primary'
              />
            </MenuItem>
          )}
          {setSecondary && (
            <MenuItem onClick={setSecondary}>
              <FormattedMessage
                id='VetSupplierListItem.ContactRemove'
                defaultMessage='Set Secondary'
              />
            </MenuItem>
          )}
          {onClickRemove && (
            <MenuItem
              onClick={() => {
                if (vbsId) {
                  onClickRemove({
                    vetBuyerSupplierId: vbsId,
                    userId
                  })
                }
              }}
            >
              <FormattedMessage
                id='VetSupplierListItem.RemoveContact'
                defaultMessage='Remove contact from RFI'
              />
            </MenuItem>
          )}
        </DropDownMenu>
      )}
    </div>
  )
})

type Props = {
  vbsId: string
  vetIsReadonly?: boolean
  vetApproval?: boolean
  isOwner?: boolean
  isApprover?: boolean
  inviteSuppliers?: boolean
  currentUrl?: string
  vbs: RecordOf<{
    users: List<RecordOf<User>>
    supplier: {
      id: string
      name: string
      supplier: { logo: string }
      domains: Array<string>
    }
    vetBS: {
      collaboration: {
        status: string
        sendToContacts: List<RecordOf<{ user: string }>>
      }
    }
  }>
  onRemoveVetSupplier?: ({ vetBuyerSupplierId: string }) => void
  onInviteVetSupplier?: ({ vetBuyerSupplierIds: [string] }) => void
  onApproveVetSupplier?: ({ vetBuyerSupplierIds: [string] }) => void
  onGotoConversation?: (vbsId: string) => void
  onAddContact?: (addContact: { supplierId: string; value: string }) => void
  onRemoveContact?: (params: {
    vetBuyerSupplierId: string
    userId: string
  }) => void
  onUpdateContact?: (arg: {
    vetBuyerSupplierId: string
    user1: {
      user: string
      primary?: boolean
      secondary?: boolean
    }
    user2?: {
      user: string
      primary?: boolean
      secondary?: boolean
    }
  }) => void
  addContactSendTo: (sendToContact: {
    orgUnitId: string
    userId: string
  }) => void
  onUpdateVetSupplier?: (payload: {
    vetBuyerSupplierId: string
    doNotContact?: boolean
    incumbent?: boolean
  }) => void
  highlight?: boolean
  onClick?: (vbsId: string) => void
  lastAdditionName?: string
  followUpEmail?: boolean
}

type State = {
  anchorEl: undefined | HTMLElement
  showPopover: boolean
  openDialog: boolean
  openPermissionDialog: boolean
  permissionBy: Array<string>
  openAddedContactSuccess: boolean
}

export class VetSupplierListItem extends Component<Props, State> {
  state = {
    anchorEl: undefined,
    showPopover: false,
    openDialog: false,
    openPermissionDialog: false,
    permissionBy: ['RFI Approver'],
    openAddedContactSuccess: false
  }

  componentDidUpdate(prevProps, prevState) {
    const sendToContact = this.props.vbs.getIn([
      'vetBS',
      'collaboration',
      'sendToContacts'
    ])
    const prevPropsSend = prevProps.vbs.getIn([
      'vetBS',
      'collaboration',
      'sendToContacts'
    ])

    if (
      sendToContact &&
      prevPropsSend &&
      sendToContact.size > prevPropsSend.size
    ) {
      this.setState({
        openAddedContactSuccess: true
      })
    }
  }

  handleClick = e => {
    e.preventDefault()
    this.setState({
      anchorEl: e.currentTarget,
      showPopover: true
    })
  }

  handleRequestClose = () => {
    this.setState({
      showPopover: false
    })
  }

  handleContactAttributeChange = (
    user: string,
    attributes: { primary: boolean; secondary: boolean }
  ) => {
    const { vbs, onUpdateContact, vbsId } = this.props
    const sendToContacts = currentVetSuppliersSelectors.getSendToContactsFromVetSuppliers(
      vbs
    )
    const attribute = attributes.primary ? 'primary' : 'secondary'
    const previousContact = sendToContacts
      .toJS()
      .find(contact => !!contact[attribute])

    if (onUpdateContact) {
      onUpdateContact({
        vetBuyerSupplierId: vbsId,
        user1: Object.assign({}, { user }, attributes),
        user2:
          previousContact &&
          Object.assign({}, previousContact, { [attribute]: false })
      })
    }
  }

  render() {
    const {
      vbsId,
      vbs,
      vetIsReadonly,
      currentUrl,
      onRemoveVetSupplier,
      onInviteVetSupplier,
      onApproveVetSupplier,
      onGotoConversation,
      onAddContact,
      onRemoveContact,
      addContactSendTo,
      onUpdateVetSupplier,
      vetApproval,
      inviteSuppliers,
      highlight,
      onClick,
      lastAdditionName,
      followUpEmail
    } = this.props

    const {
      showPopover,
      anchorEl,
      openDialog,
      openPermissionDialog
    } = this.state
    const users = vbs.get('users')
    const supplierId = vbs.getIn(['supplier', 'id'])
    const upVotes = vbs.getIn(['vetBS', 'upVotes']).size
    const downVotes = vbs.getIn(['vetBS', 'downVotes']).size
    const comments = vbs.getIn(['vetBS', 'comments']).size
    const sendToContacts: List<any> = currentVetSuppliersSelectors.getSendToContactsFromVetSuppliers(
      vbs
    )

    const profileUrl = `${parsePath(paths.supplierProfile, {
      supplierId: vbs.getIn(['supplier', 'id'])
    })}?redirectFrom=${currentUrl}`
    const status = vbs.getIn(['vetBS', 'collaboration', 'status'])
    const incumbent = !!vbs.getIn(['vetBS', 'incumbent'])
    const doNotContact = !!vbs.getIn(['vetBS', 'doNotContact'])
    const name = vbs.getIn(['supplier', 'name'])
    const savedLogo = fileToUrl(vbs.getIn(['supplier', 'supplier', 'logo']))
    const domain = vbs.getIn(['supplier', 'domains'])
    const logoUrl =
      savedLogo ||
      (domain && domain.size > 0
        ? `https://logo.clearbit.com/${domain.first()}`
        : '')
    const statusClass = classNames({
      red: status === 'Declined',
      'dark-teal': status !== 'Declined'
    })

    return (
      <>
        <ListItem
          mini
          className={`mt3 pv2 ${highlight ? 'bg-washed-green' : ''} ${
            onClick && !highlight ? 'pointer dim' : ''
          }`}
          onClick={onClick && (() => onClick(vbsId))}
          leftAvatar={
            onClick ? (
              <div
                style={{ height: 32, width: 32, padding: 1 }}
                className={
                  incumbent
                    ? 'ba b--gold bw1 br2 flex items-center'
                    : 'flex items-center'
                }
              >
                <OrgLogo url={logoUrl} name={name} />
              </div>
            ) : (
              <Link to={profileUrl}>
                <div
                  style={{ height: 32, width: 32, padding: 1 }}
                  className={
                    incumbent
                      ? 'ba b--gold bw1 br2 flex items-center'
                      : 'flex items-center'
                  }
                >
                  <OrgLogo url={logoUrl} name={name} />
                </div>
              </Link>
            )
          }
          primaryText={onClick ? name : <Link to={profileUrl}>{name}</Link>}
          secondaryText={
            <div>
              <Text className={statusClass}>{status}</Text>
              {(onAddContact ||
                (sendToContacts && sendToContacts.size > 0)) && (
                <div
                  className='v-mid pointer mt1'
                  onClick={followUpEmail ? undefined : this.handleClick}
                >
                  {sendToContacts && sendToContacts.size > 0 && (
                    <UserStack
                      users={sendToContacts.map(c => c.get('user'))}
                      mini
                      limit={5}
                    />
                  )}
                  {onAddContact && sendToContacts && sendToContacts.size === 0 && (
                    <Text className='red pointer dim'>
                      <img
                        src={ImageWarning}
                        alt='Missing contact'
                        className='w1 dib v-mid'
                      />{' '}
                      <FormattedMessage
                        id='Vet.SupplierListItem.MissingContact'
                        defaultMessage='Add Contact'
                      />
                    </Text>
                  )}
                </div>
              )}
              <Popover
                open={showPopover}
                onClose={this.handleRequestClose}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                <div className='dib pa3 lh-copy f7 fw4 mid-gray w5'>
                  <div className='flex items-center mb2'>
                    <Label className='db mv1 f7 fw6 flex-auto'>
                      <FormattedMessage
                        id='Vet.SupplierListItem.InvitationRecipients'
                        defaultMessage='Email Recipients:'
                      />
                    </Label>
                    {/* {!doNotContact && onAddContact && ( */}
                    <Button
                      label='Edit'
                      secondary
                      onClick={() => this.setState({ openDialog: true })}
                      size='small'
                    />
                    {/* )} */}
                  </div>
                  <Scrollable>
                    {sendToContacts &&
                      sendToContacts.map(c => {
                        const userId = c.get('user')
                        const primary = c.get('primary')
                        const secondary = c.get('secondary')
                        return (
                          <SendToContact
                            key={userId}
                            vbsId={vbsId}
                            userId={userId}
                            profileUrl={parsePath(paths.userprofile, {
                              userId
                            })}
                            primary={primary}
                            secondary={secondary}
                          />
                        )
                      })}
                  </Scrollable>
                </div>
              </Popover>
              {upVotes + downVotes + comments > 0 && (
                <div
                  className='flex items-center mt1 pointer dim'
                  onClick={() =>
                    onGotoConversation && onGotoConversation(vbsId)
                  }
                >
                  {upVotes > 0 && (
                    <Fragment>
                      <span className='mr1 teal'>{upVotes}</span>
                      <ThumbUpIcon
                        color='primary'
                        style={{ width: 16, height: 16, marginRight: 8 }}
                      />
                    </Fragment>
                  )}
                  {downVotes > 0 && (
                    <Fragment>
                      <span className='mr1 red'>{downVotes}</span>
                      <ThumbDownIcon
                        color='error'
                        style={{ width: 16, height: 16, marginRight: 8 }}
                      />
                    </Fragment>
                  )}
                  {comments > 0 && (
                    <CommentIcon
                      color='secondary'
                      style={{ width: 16, height: 16, marginRight: 8 }}
                    />
                  )}
                </div>
              )}
            </div>
          }
          rightContent={
            !vetIsReadonly && !followUpEmail ? (
              <DropDownMenu>
                {(status === 'Suggested' || status === 'Approved') && (
                  <MenuItem
                    onClick={
                      onRemoveVetSupplier
                        ? () =>
                            onRemoveVetSupplier({ vetBuyerSupplierId: vbsId })
                        : () =>
                            this.setState({
                              openPermissionDialog: true,
                              permissionBy: ['RFI Owner, RFI Approver']
                            })
                    }
                  >
                    <FormattedMessage
                      id='Vet.SupplierListItem.Remove'
                      defaultMessage='Remove'
                    />
                  </MenuItem>
                )}

                {inviteSuppliers &&
                !doNotContact &&
                (status === 'Approved' ||
                  (status === 'Suggested' && !vetApproval)) ? (
                  sendToContacts.size > 0 ? (
                    <MenuItem
                      onClick={
                        onInviteVetSupplier
                          ? () =>
                              onInviteVetSupplier({
                                vetBuyerSupplierIds: [vbsId]
                              })
                          : () =>
                              this.setState({
                                openPermissionDialog: true,
                                permissionBy: ['RFI Owner, RFI Approver']
                              })
                      }
                    >
                      <FormattedMessage
                        id='Vet.SupplierListItem.Invite'
                        defaultMessage='Invite'
                      />
                    </MenuItem>
                  ) : (
                    <MenuItem disabled>
                      <FormattedMessage id='Vet.SupplierListItem.Invite' />
                    </MenuItem>
                  )
                ) : null}

                {status === 'Suggested' && vetApproval && (
                  <MenuItem
                    onClick={
                      onApproveVetSupplier
                        ? () =>
                            onApproveVetSupplier({
                              vetBuyerSupplierIds: [vbsId]
                            })
                        : () =>
                            this.setState({
                              openPermissionDialog: true,
                              permissionBy: ['RFI Approver']
                            })
                    }
                  >
                    <FormattedMessage
                      id='Vet.SupplierListItem.Approve'
                      defaultMessage='Approve'
                    />
                  </MenuItem>
                )}

                {(status === 'Suggested' || status === 'Approved') && (
                  <MenuItem
                    onClick={() =>
                      onUpdateVetSupplier &&
                      onUpdateVetSupplier({
                        vetBuyerSupplierId: vbsId,
                        doNotContact: !doNotContact
                      })
                    }
                  >
                    {doNotContact ? (
                      <FormattedMessage
                        id='Vet.SupplierListItem.RemoveDoNotContact'
                        defaultMessage='Remove Do Not Contact'
                      />
                    ) : (
                      <FormattedMessage
                        id='Vet.SupplierListItem.MarkAsDoNotContact'
                        defaultMessage='Mark as Do Not Contact'
                      />
                    )}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() =>
                    onUpdateVetSupplier &&
                    onUpdateVetSupplier({
                      vetBuyerSupplierId: vbsId,
                      incumbent: !incumbent
                    })
                  }
                >
                  {incumbent ? (
                    <FormattedMessage
                      id='Vet.SupplierListItem.RemoveIncumbent'
                      defaultMessage='Remove Incumbent'
                    />
                  ) : (
                    <FormattedMessage
                      id='Vet.SupplierListItem.MarkAsIncumbent'
                      defaultMessage='Mark as Incumbent'
                    />
                  )}
                </MenuItem>
              </DropDownMenu>
            ) : null
          }
        />

        <Dialog
          fullWidth
          open={openPermissionDialog}
          onClose={() => this.setState({ openPermissionDialog: false })}
        >
          <DialogTitle
            onClose={() => this.setState({ openPermissionDialog: false })}
          >
            <FormattedMessage
              id='Vet.SupplierListItem.Information'
              defaultMessage='Information'
            />
          </DialogTitle>
          <DialogContent>
            <Text className='mv3'>
              <FormattedMessage
                id='Vet.SupplierListItem.PermissionMessage'
                defaultMessage='Only {permissionBy} can perform this action. Contact your administrator or the TealBook concierge if you need assistance.'
                values={{ permissionBy: this.state.permissionBy.join(', ') }}
              />
            </Text>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth='xs'
          open={openDialog}
          onClose={() => this.setState({ openDialog: false })}
        >
          <DialogTitle
            customCloseLabel={
              <FormattedMessage
                id='VetSupplierListContainer.Done'
                defaultMessage='Done'
              />
            }
            onClose={() => this.setState({ openDialog: false })}
          >
            <FormattedMessage
              id='Vet.SupplierListItem.EmailRecipients'
              defaultMessage='Email Recipients'
            />
          </DialogTitle>

          <DialogContent>
            <CSSTransition
              in={this.state.openAddedContactSuccess}
              timeout={2000}
              classNames='fade'
              onEntered={() =>
                this.setState({ openAddedContactSuccess: false })
              }
              unmountOnExit
            >
              <Label>
                {lastAdditionName ? (
                  <FormattedMessage
                    id='VetSupplierListItem.successName'
                    defaultMessage='{lastAdditionName} has been added!'
                    values={{ lastAdditionName }}
                  />
                ) : (
                  <FormattedMessage
                    id='VetSupplierListItem.success'
                    defaultMessage='Your contact has been added!'
                  />
                )}
              </Label>
            </CSSTransition>
            <Scrollable>
              {sendToContacts &&
                sendToContacts.map(c => {
                  const userId = c.get('user')
                  const primary = c.get('primary')
                  const secondary = c.get('secondary')
                  return (
                    <SendToContact
                      key={userId}
                      vbsId={vbsId}
                      userId={userId}
                      profileUrl={parsePath(paths.userprofile, {
                        userId
                      })}
                      primary={primary}
                      secondary={secondary}
                      onClickRemove={onRemoveContact}
                      setPrimary={
                        !primary
                          ? () =>
                              this.handleContactAttributeChange(userId, {
                                primary: true,
                                secondary: false
                              })
                          : undefined
                      }
                      setSecondary={
                        !secondary
                          ? () =>
                              this.handleContactAttributeChange(userId, {
                                primary: false,
                                secondary: true
                              })
                          : undefined
                      }
                    />
                  )
                })}
            </Scrollable>
            {onAddContact && (
              <>
                <Divider className='mt3' />
                <div className='mb3'>
                  <UserLookup
                    label={
                      <FormattedMessage
                        id='Vet.SupplierListItem.AddAnotherContact'
                        defaultMessage='Add Another Contact'
                      />
                    }
                    users={users
                      .filter(
                        user =>
                          !sendToContacts ||
                          !sendToContacts.some(
                            sentContanct =>
                              sentContanct.get('user') === user.get('id')
                          )
                      )
                      .toList()}
                    onClickSuggestedUser={user =>
                      addContactSendTo({
                        orgUnitId: supplierId,
                        userId: user.get('id')
                      })
                    }
                    onClickNewUser={value => {
                      if (onAddContact) {
                        onAddContact({ supplierId, value })
                      }
                    }}
                  />
                </div>
              </>
            )}
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

type ContainerProps = {
  vbsId: string
}

export default connect(
  (state: RootState, props: ContainerProps) => ({
    vbs: currentVetSuppliersSelectors.getVetSupplierById(state, props.vbsId),
    lastAdditionName: currentVetSuppliersSelectors.getLatestContactAdded(
      state,
      props.vbsId
    )
  }),
  { addContactSendTo }
)(VetSupplierListItem)
