import { List, RecordOf } from 'immutable'
import User from 'shared/models/User'

export const sortContactsBySustainabilityContact = (
  contacts: List<RecordOf<User>>,
  selectInput: string | undefined
) => {
  if (sortContactsByNameAndInput(contacts, selectInput).size) {
    return sortContactsByNameAndInput(contacts, selectInput).sort(
      (userA, userB) => {
        if (userA.get('isSustainabilityContact')) {
          return -1
        }
        if (userB.get('isSustainabilityContact')) {
          return 1
        }
        return 0
      }
    )
  } else {
    return List([])
  }
}

export const sortContactsByNameAndInput = (
  contacts: List<RecordOf<User>>,
  selectInput: string | undefined
) => {
  if (contacts && contacts.size) {
    return (
      contacts &&
      contacts.sort((userA, userB) => {
        let fullNameA = `${userA.get('firstName') &&
          userA.get('firstName').trim()} ${userA.get('lastName') &&
          userA.get('lastName').trim()}`.toLowerCase()
        let fullNameB = `${userB.get('firstName') &&
          userB.get('firstName').trim()} ${userB.get('lastName') &&
          userB.get('lastName').trim()}`.toLowerCase()

        if (selectInput) {
          fullNameA = fullNameA.startsWith(selectInput)
            ? `0${fullNameA}`
            : fullNameA
          fullNameB = fullNameB.startsWith(selectInput)
            ? `0${fullNameB}`
            : fullNameB
        }
        if (fullNameA > fullNameB) {
          return 1
        }
        if (fullNameA < fullNameB) {
          return -1
        }
        return 0
      })
    )
  } else {
    return List([])
  }
}
