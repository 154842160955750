import { call, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { notify } from 'shared/actions'
import community from 'shared/utils/api/community'

export function* createCommunitySaga(action) {
  const { name, logo, isPrivate, ...rest } = action.payload.toJS()
  const result = yield call(community.createCommunity, {
    name,
    isPrivate: isPrivate.toString(),
    ...rest
  })

  if (logo) {
    yield call(community.addLogo, { communityId: result.id, logo })
  }

  yield put(
    notify({
      message: `The community "${name}" has been created`
    })
  )
}

export default createApiRequestSaga(createCommunitySaga, {
  notifyOnError: true
})
