import { call, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import cards from 'shared/utils/api/cards'
import profileSelectors from '../../selectors/profileSelectors'

export function* deletePrivateOfferingsSaga(action) {
  const { tagsFieldName, tagKey, tagName } = action.payload
  const supplierId = yield select(profileSelectors.getDataField, 'id')
  yield call(cards.deletePrivateOffering, supplierId, tagName)

  return yield {
    tagsFieldName: tagsFieldName,
    tagKey: tagKey
  }
}

export default createApiRequestSaga(deletePrivateOfferingsSaga)
