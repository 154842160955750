export const LOAD_COMPANY = 'supplier/LOAD_COMPANY'
export const LOAD_VETS = 'supplier/LOAD_VETS'
export const LOAD_VETS_SUCCESS = 'supplier/LOAD_VETS_SUCCESS'
export const LOAD_SUPPLIER_INSIGHT = 'supplier/LOAD_SUPPLIER_INSIGHT'

export const UPDATE_CUSTOMIZE_PITCH_DECK =
  'supplier/UPDATE_CUSTOMIZE_PITCH_DECK'
export const FOLLOW_CLIENT = 'supplier/FOLLOW_CLIENT'
export const SHOW_PAYMENT_DIALOG = 'supplier/SHOW_PAYMENT_DIALOG'
export const HIDE_PAYMENT_DIALOG = 'supplier/HIDE_PAYMENT_DIALOG'
export const PROCESS_PAYMENT = 'shared/PROCESS_PAYMENT'

export const CANCEL_SUBSCRIPTION = 'share/CANCEL_SUBSCRIPTION'
export const ADD_SURVEY_COLLABORATE_CONTACT =
  'supplier/ADD_SURVEY_COLLABORATE_CONTACT'
export const OPEN_ADD_COLLABORATE_CONTACT_FORM =
  'supplier/OPEN_ADD_COLLABORATE_CONTACT_FORM'
export const CANCEL_ADDING_SURVEY_COLLABORATE_CONTACT =
  'supplier/CANCEL_ADDING_SURVEY_COLLABORATE_CONTACT'
