import React, { Component } from 'react'
import Link from 'shared/components/Link'
import dateFormat from 'shared/utils/dateFormat'
import Text from 'shared/components/Text'
import Avatar from 'shared/components/Avatar'
import StarsRating from 'shared/components/StarsRating'
import Button from 'shared/components/Button'
import RatingDetails from '../RatingDetails'
import LikeButton from 'shared/components/LikeButton'
import Pill from 'shared/components/Pill'
import { FormattedMessage } from 'react-intl'
import { RecordOf, List, Map } from 'immutable'

type Props = {
  firstName: string
  lastName: string
  comment: string
  preferred: 'preferred' | 'noOpinion'
  preferredCategories: Array<string>
  ratings?: List<{
    label: string
    value: number
  }>
  averageRating: number
  lastUpdated: string
  userProfileUrl: string
  profilePictureUrl: string
  likes?: List<Map<string, Date>>
  currentUserLiked: boolean
  reviewOptions: RecordOf<{
    rating: boolean
    preferred: boolean
  }>
  onEdit?: () => void
  onLike?: () => void
  onDelete?: () => void
}

type State = {
  viewReviewDetails: boolean
}

class UserReview extends Component<Props, State> {
  state = {
    viewReviewDetails: false
  }

  handleClickToggleReviewsDetails = () => {
    this.setState({
      viewReviewDetails: !this.state.viewReviewDetails
    })
  }

  render() {
    const {
      firstName,
      lastName,
      comment,
      preferred,
      preferredCategories,
      lastUpdated,
      averageRating,
      userProfileUrl,
      profilePictureUrl,
      onEdit,
      onDelete,
      onLike,
      ratings,
      currentUserLiked,
      likes,
      reviewOptions
    } = this.props

    const { viewReviewDetails } = this.state

    return (
      <div className='mt3-5'>
        {onDelete && (
          <Button
            label={
              <FormattedMessage
                id='UserReview.Remove'
                defaultMessage='Remove'
              />
            }
            className='ml3 fr'
            caution
            size='small'
            autoSize
            onClick={onDelete}
          />
        )}
        {onEdit && (
          <Button
            label={
              <FormattedMessage id='UserReview.Edit' defaultMessage='Edit' />
            }
            className='fr'
            secondary
            autoSize
            size='small'
            onClick={onEdit}
          />
        )}
        <div className='dib mr3'>
          <Link to={userProfileUrl}>
            <Avatar
              url={profilePictureUrl}
              className='w3'
              name={`${firstName || ''} ${lastName || ''}`}
            />
          </Link>
        </div>

        <div className='dib v-top mw6'>
          <Link to={userProfileUrl}>
            <h3 className='ma0 f6 fw6 dark-gray'>
              {firstName} {lastName}
            </h3>
          </Link>

          <div className='f7 gray mt1'>{dateFormat(lastUpdated)}</div>

          <div className='mt1'>
            {reviewOptions && reviewOptions.get('rating') && (
              <div>
                <StarsRating value={averageRating} mini />
                <Button
                  label={
                    viewReviewDetails ? (
                      <FormattedMessage
                        id='UserReview.ViewLess'
                        defaultMessage='View less'
                      />
                    ) : (
                      <FormattedMessage
                        id='UserReview.ViewDetails'
                        defaultMessage='View details'
                      />
                    )
                  }
                  className='ml3'
                  variant='text'
                  autoSize
                  onClick={this.handleClickToggleReviewsDetails}
                />
              </div>
            )}
          </div>
        </div>

        <div className='ml3' style={{ paddingLeft: '64px' }}>
          {viewReviewDetails && (
            <div className='mb3'>
              <RatingDetails ratings={ratings} />
            </div>
          )}

          {reviewOptions &&
            reviewOptions.get('preferred') &&
            preferred &&
            preferred !== 'noOpinion' && (
              <div>
                <Text className='gray f5 dib mr3'>Liked for</Text>

                {preferred === 'preferred' &&
                  preferredCategories &&
                  preferredCategories.map(catLabel => (
                    <Pill label={catLabel} key={catLabel} />
                  ))}
              </div>
            )}

          {comment && <Text className='mt2'>{comment}</Text>}

          {((likes && likes.size > 0) || onLike) && (
            <LikeButton
              noCountLabel={
                <FormattedMessage
                  id='UserReview.Helpful'
                  defaultMessage='Helpful?'
                />
              }
              liked={currentUserLiked}
              count={likes && likes.size}
              onClick={onLike}
            />
          )}
        </div>
      </div>
    )
  }
}

export default UserReview
