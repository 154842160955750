import React, { Component, Fragment } from 'react'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import Text from 'shared/components/Text'
import AssignedByContainer from '../../containers/AssignedByContainer'
import CompleteByDate from '../CompleteByDate'
import Button from 'shared/components/Button'
import Link from 'shared/components/Link'
import OrgUnit from 'shared/containers/OrgUnit'
import AddTaskComment from '../../containers/AddTaskComment'
import CommentList from 'shared/containers/CommentList'
import VetLinkedToTaskContainer from '../../containers/VetLinkedToTaskContainer'
import buyerVetPaths from 'buyer/Vets/routes/paths'
import parsePath from 'shared/utils/parsePath'
import startcase from 'lodash.startcase'
import Divider from 'shared/components/Divider'
import Typography from '@material-ui/core/Typography'
import buyerPaths from 'buyer/routes/paths'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import { Map } from 'immutable'
import Table from 'shared/components/Table'
import { Column } from 'react-virtualized'
import paths from 'data-portal/routes/paths'
import classNames from 'classnames'
import BecomeTealbotDialog from 'shared/containers/BecomeTealbotDialog'
import Popover from '@material-ui/core/Popover'

const classes = {
  sectionTitles: 'f4 fw5 mb3 mt4'
}

type Props = {
  taskId: string
  taskDisplayId: string
  description: string
  createdBy: string
  responseByDate: Date
  status: string
  type: string
  linkedOrgId: string
  linkedVet: string
  createdDate?: Date
  hideLoginButton: boolean
  form: Map<string, any>
  basePath?: string
  onComplete: () => void
  onCancel: () => void
  onInProgress: () => void
  onWaitingForClient: () => void
  onClickGetReasonKey: (payload: {
    orgUnitId: string
    userMobilePhone: string
    orgName?: string
    redirectTo?: string
  }) => void
  mobilePhone?: string
  linkedOrgName?: string
}
class TaskDetails extends Component<Props> {
  state = {
    anchorEl: null
  }
  handleClickLoginAsTealbot = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {
      type,
      linkedOrgId,
      linkedVet,
      mobilePhone,
      onClickGetReasonKey,
      linkedOrgName
    } = this.props
    const redirectTo =
      type === 'NewVet' && linkedVet
        ? parsePath(buyerVetPaths.vetDetails, { vetId: linkedVet })
        : undefined

    if (!mobilePhone) {
      this.setState({ anchorEl: e.currentTarget })
    } else {
      //POST for oneTime pin code
      //saving orgUnitId and orgName to redux state so can pre-populate dialog once SMS successfully sent
      onClickGetReasonKey({
        orgUnitId: linkedOrgId,
        orgName: linkedOrgName,
        redirectTo: redirectTo,
        userMobilePhone: mobilePhone
      })
    }
  }

  renderSubTitle = (key, val) => {
    if (typeof val === 'boolean') {
      const valueString = val === false ? 'NO' : 'YES'
      return `${startcase(key)} ? ${valueString}`
    } else {
      return startcase(key)
    }
  }

  onClosePopover = () => {
    this.setState({
      anchorEl: null
    })
  }
  render() {
    const {
      taskId,
      taskDisplayId,
      type,
      description,
      createdBy,
      createdDate,
      responseByDate,
      status,
      linkedOrgId,
      linkedVet,
      hideLoginButton,
      form,
      basePath,
      onComplete,
      onCancel,
      onInProgress,
      onWaitingForClient
    } = this.props

    return (
      <>
        <Page title={`Details for ${type}`}>
          <PageSection
            title={
              <span>
                <span>{type}</span>{' '}
                <span className='mid-graygray fw5 pl1'>
                  {(taskDisplayId !== undefined ? taskDisplayId : '').replace(
                    'TASK-',
                    '#'
                  )}
                </span>
              </span>
            }
            actions={<div className='fw6 pr3-5'>{status}</div>}
          >
            <div className='mt3-5'>
              <div className='dib w-50 v-top'>
                <h5 className={classes.sectionTitles}>Created By</h5>
                <AssignedByContainer
                  userId={createdBy}
                  createdDate={createdDate}
                />
              </div>
              <div className='dib w-50 v-top'>
                <h5 className={classes.sectionTitles}>Complete By</h5>
                <CompleteByDate date={responseByDate} status={status} />
              </div>
            </div>

            <Divider className='mt3' />

            {description && (
              <Fragment>
                <h5 className={classes.sectionTitles}>Note</h5>
                <Text>{description}</Text>
                <Divider />
              </Fragment>
            )}

            {form &&
              form
                .entrySeq()
                .sort(function(a, b) {
                  if (a[0] < b[0]) {
                    return -1
                  }
                  if (a[0] > b[0]) {
                    return 1
                  }
                  return 0
                })
                .map(([key, val]) => (
                  <div
                    key={key}
                    className={classNames([
                      'mt2 ',
                      val === false ? 'dark-red' : undefined,
                      val === true ? 'dark-green' : undefined
                    ])}
                  >
                    <Typography
                      variant='subtitle1'
                      gutterBottom
                      className='underline'
                    >
                      {this.renderSubTitle(key, val)}
                    </Typography>
                    {key.toLowerCase() === 'file' ? (
                      <div className='tca'>
                        <img
                          src={fileToUrl(val)}
                          alt='business card'
                          className='h5'
                        />
                      </div>
                    ) : key.toLowerCase() === 'references' ? (
                      <Table
                        rowGetter={({ index }) => val.get(index)}
                        rowCount={val.size}
                      >
                        <Column
                          label='First Name'
                          dataKey='firstName'
                          width={150}
                        />
                        <Column
                          label='Last Name'
                          dataKey='lastName'
                          width={150}
                        />
                        <Column
                          label='Company Name'
                          dataKey='companyName'
                          width={150}
                        />
                        <Column label='Email' dataKey='email' width={250} />
                        <Column
                          label='Contacts'
                          dataKey='numContacts'
                          width={100}
                        />
                        <Column
                          label='Following'
                          dataKey='isFollowing'
                          width={100}
                        />
                        <Column
                          label='Profile URL'
                          dataKey='userProfileUrl'
                          width={150}
                          cellRenderer={({ cellData }) => (
                            <a
                              target='_blank'
                              href={cellData}
                              rel='noopener noreferrer'
                            >
                              Click here
                            </a>
                          )}
                        />
                      </Table>
                    ) : typeof val === 'object' ? (
                      <Typography paragraph>
                        <pre>{JSON.stringify(val, null, 2)}</pre>
                      </Typography>
                    ) : (
                      <Typography paragraph>{val}</Typography>
                    )}
                  </div>
                ))}

            {form && form.size > 0 && <Divider className='mt3' />}

            {linkedOrgId && (
              <Fragment>
                <h5 className={classes.sectionTitles}>Org Unit</h5>
                <OrgUnit
                  id={linkedOrgId}
                  showDetails
                  isClient
                  profileUrl={parsePath(buyerPaths.supplierProfile, {
                    supplierId: linkedOrgId
                  })}
                />
                <Divider className='mt3' />
              </Fragment>
            )}

            {linkedVet && (
              <Fragment>
                <h5 className={classes.sectionTitles}>RFI</h5>
                <VetLinkedToTaskContainer vetId={linkedVet} />
              </Fragment>
            )}

            {!hideLoginButton && linkedOrgId && (
              <Button
                label='Login as tealbot'
                onClick={this.handleClickLoginAsTealbot}
                className='w-auto mt2'
              />
            )}

            <CommentList kindName='Task' kindId={taskId} />

            {basePath && (
              <div className='pa3-5 bg-near-white ba b--light-gray'>
                <AddTaskComment taskId={taskId} />
              </div>
            )}

            <div className='mt4'>
              {status !== 'Completed' && (
                <Button
                  label='Complete Task'
                  onClick={onComplete}
                  autoSize
                  className='mr3'
                />
              )}
              {status !== 'Cancelled' && (
                <Button
                  label='Cancel Task'
                  onClick={onCancel}
                  autoSize
                  secondary
                  className='mr3'
                />
              )}
              {status !== 'InProgress' && (
                <Button
                  label='Task In Progress'
                  onClick={onInProgress}
                  autoSize
                  secondary
                  className='mr3'
                />
              )}
              {status !== 'WaitingForClient' && (
                <Button
                  label='Task Waiting For Client'
                  onClick={onWaitingForClient}
                  autoSize
                  secondary
                  className='mr3'
                />
              )}

              {!basePath && (
                <Link to={`${paths.tasks}/${taskId}`}>
                  <Button label='Go to Details' secondary autoSize />
                </Link>
              )}

              {basePath && (
                <Link to={`${basePath}/..`}>
                  <Button label='Back to list' secondary autoSize />
                </Link>
              )}
            </div>
          </PageSection>
        </Page>
        <BecomeTealbotDialog />
        <Popover
          open={Boolean(this.state.anchorEl)}
          onClose={this.onClosePopover}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <div className='dib pa2 lh-copy f7 fw4 mid-gray'>
            <Text className='hover-teal'>
              We need your mobile number to send you an access code. Please
              visit the My Account page to update your profile.
            </Text>
          </div>
        </Popover>
      </>
    )
  }
}

export default TaskDetails
