import { connect } from 'react-redux'
import createAddForm from 'shared/utils/createAddForm'
import { ADD_USER } from '../../actionTypes'
import { closeUserDialog } from '../../actions'
import companySelectors from 'supplier/shared/selectors/companySelectors'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import { getFormValues } from 'redux-form/immutable'
import AddUserForm from 'shared/components/AddUserForm'
import RootState from 'shared/models/RootState'
import { fromJS, RecordOf } from 'immutable'

type FormData = RecordOf<{
  user: RecordOf<{
    email: string
  }>
}>

const formName = 'admin/addUser'
const formValueSelector = getFormValues(formName)

export default connect(
  (state: RootState) => {
    const formValues = formValueSelector(state) as FormData
    return {
      caption: 'New User',
      email: formValues && formValues.get('user').get('email'),
      addingContact: state.getIn(['admin', 'addUser', 'userDialogOpen']),
      creatingNewUser: true,
      addingMyself: true,
      hideLookup: true,
      domains:
        companySelectors.getCompanyField(state, 'domains') ||
        settingsSelectors.getField(state, 'domains'),
      users: fromJS([]),
      uploadUsersFile: true
    }
  },
  {
    onClickCancel: closeUserDialog
  }
)(
  createAddForm(
    {
      formName,
      submitActionType: ADD_USER
    },
    AddUserForm
  )
)
