import { call } from 'redux-saga/effects'
import vets from 'shared/utils/api/vets'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* loadVetsSaga() {
  const openVets = yield call(vets.getOpenVets)
  return yield { vets: openVets.byId }
}

export default createApiRequestSaga(loadVetsSaga)
