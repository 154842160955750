import React, { Component } from 'react'
import classNames from 'classnames'

const styles = {
  aside: 'w-100 w-30-l mt4-ns mt0-l',
  left: 'pr4-l',
  right: 'pl4-l'
}

type Props = {
  className?: string
  left?: boolean
  style?: { [attr: string]: any }
}

class Aside extends Component<Props> {
  render() {
    const { className, left, children, ...rest } = this.props

    return (
      <aside
        {...rest}
        className={classNames(styles.aside, className, left ? 'fl' : 'fr')}
      >
        <div className={left ? styles.left : styles.right}>{children}</div>
      </aside>
    )
  }
}

export default Aside
