import React, { Component } from 'react'
import NavLink from 'shared/components/NavBar/NavLink'
import supplierPaths from 'supplier/routes/paths'
import buyerPaths from 'buyer/routes/paths'
import { connect } from 'react-redux'
import routingSelectors from 'shared/selectors/routingSelectors'
import vetBuyerSupplierSelectors from '../../../supplier/Vets/selectors/vetBuyerSupplierSelectors'
import { FormattedMessage } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'

type Props = {
  notification: boolean
  to: string
  pathname: string
}

export class VetNavLinkContainer extends Component<Props> {
  render() {
    const { notification, to, pathname } = this.props

    return (
      <Tooltip
        title={
          <FormattedMessage
            id='VetNavLinkContainer.VetTip'
            defaultMessage='Respond to requests for information from buyers'
          />
        }
      >
        <NavLink
          label={
            <FormattedMessage
              id='VetNavLinkContainer.RFI'
              defaultMessage='RFI'
            />
          }
          to={to}
          numberOfNotifications={notification ? 1 : undefined}
          isActive={() =>
            [supplierPaths.vets, buyerPaths.vets].some(paths =>
              pathname.includes(paths)
            )
          }
        />
      </Tooltip>
    )
  }
}

export default connect(state => {
  const isSupplierApp = !routingSelectors.isClientApp(state)
  const vets = vetBuyerSupplierSelectors.getVets(state).get('items')
  const notification =
    isSupplierApp &&
    vets.some(
      vet =>
        !['Accepted', 'Chosen', 'Declined', 'Cancelled', 'Closed'].includes(
          vet.get('status')
        )
    )
  return {
    notification,
    pathname: routingSelectors.getPathname(state)
  }
})(VetNavLinkContainer)
