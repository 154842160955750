import React, { Component } from 'react'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import PageSideSection from 'shared/components/PageSideSection'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import CommunityFormProgress from '../../containers/CommunityFormProgress'
import { FormattedMessage } from 'react-intl'

type Props = {
  name: string
  onContinue: () => void
}

class FinishCommunityFormPage extends Component<Props> {
  render() {
    const { name, onContinue } = this.props

    return (
      <Page
        title={
          <FormattedMessage
            id='FinishCommunityFormPage.FinishCommunityFormTitle'
            defaultMessage='Finish Community Form for {name}'
            values={{ name }}
          />
        }
      >
        <Main>
          <PageSection
            title={
              <FormattedMessage
                id='FinishCommunityFormPage.SurveryCompleteTitle'
                defaultMessage='Survey Complete'
              />
            }
          >
            <Text className='mt3-5'>
              <FormattedMessage
                id='FinishCommunityFormPage.ThankYouForCompletingtheCommunity'
                defaultMessage='Thank you for completing the {name} Community sign up questionnaire.
                You have now connected to the community.'
                values={{ name }}
              />
            </Text>
            <div className='mt3-5'>
              <Button
                label={
                  <FormattedMessage
                    id='FinishCommunityFormPage.ContinueButton'
                    defaultMessage='Continue'
                  />
                }
                onClick={onContinue}
                autoSize
                className='mr3'
              />
            </div>
          </PageSection>
        </Main>

        <Aside className='pt4'>
          <PageSideSection
            title={
              <FormattedMessage
                id='FinishCommunityFormPage.SummaryTitle'
                defaultMessage='Summary'
              />
            }
          >
            <div className='mt2'>
              <CommunityFormProgress />
            </div>
          </PageSideSection>
        </Aside>
      </Page>
    )
  }
}

export default FinishCommunityFormPage
