import React, { Component } from 'react'
import { connect } from 'react-redux'
import TaskDetails from '../../components/TaskDetails'
import { updateData, loadData } from 'shared/actions'
import dataSelectors from 'shared/selectors/dataSelectors'
import { getReasonKey } from 'data-portal/store/actions'
import paths from 'data-portal/routes/paths'
import Loading from 'shared/components/Loading'
import RootState from 'shared/models/RootState'
import sessionSelectors from 'shared/selectors/sessionSelectors'

type Props = {
  taskId: string
  taskDisplayId: string
  type: string
  description: string
  responseByDate: Date
  status: string
  createdBy: string
  linkedOrgId: string
  linkedVet: string
  form: any
  hideLoginButton: boolean
  basePath?: string
  createdDate?: Date
  loadData: typeof loadData
  updateData: typeof updateData
  onComplete?: () => void
  onCancel?: () => void
  onInProgress?: () => void
  onWaitingForClient?: () => void
  onClickGetReasonKey: typeof getReasonKey
  mobilePhone?: string
  orgUnitName?: string
}

export class TaskDetailsContainer extends Component<Props> {
  componentDidMount() {
    const { taskId, loadData } = this.props
    loadData({
      entity: 'tasks',
      query: `tasks?taskId=${taskId}`
    })
  }
  componentDidUpdate(prevProps) {
    const { taskId, loadData } = this.props
    if (prevProps.taskId !== taskId) {
      loadData({
        entity: 'tasks',
        query: `tasks?taskId=${taskId}`
      })
    }
  }

  updateTaskStatus(statusTo: string) {
    const { taskId, updateData } = this.props

    updateData({
      entity: 'tasks',
      record: {
        id: taskId,
        status: statusTo
      },
      redirectTo: paths.tasks
    })
  }

  handleCancel = () => {
    this.updateTaskStatus('Cancelled')
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  handleComplete = () => {
    this.updateTaskStatus('Completed')
    if (this.props.onComplete) {
      this.props.onComplete()
    }
  }

  handleInProgress = () => {
    this.updateTaskStatus('InProgress')
    if (this.props.onInProgress) {
      this.props.onInProgress()
    }
  }

  handleWaitingForClient = () => {
    this.updateTaskStatus('WaitingForClient')
    if (this.props.onWaitingForClient) {
      this.props.onWaitingForClient()
    }
  }

  render() {
    return this.props.createdDate ? (
      <TaskDetails
        {...this.props}
        onCancel={this.handleCancel}
        onComplete={this.handleComplete}
        onInProgress={this.handleInProgress}
        onWaitingForClient={this.handleWaitingForClient}
      />
    ) : (
      <Loading />
    )
  }
}

type ContainerProps = {
  taskId: string
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const taskId = props.taskId
    const task = dataSelectors.getById(state, 'tasks', taskId)
    const vet = dataSelectors.getById(state, 'vets', task.get('linkedVet'))
    const user = sessionSelectors.getUser(state)
    return {
      taskId,
      taskDisplayId: task.get('taskId'),
      type: task.get('type'),
      description: task.get('description'),
      responseByDate: task.get('responseDate'),
      status: task.get('status'),
      createdBy: task.getIn(['created', 'user']),
      createdDate: task.getIn(['created', 'date']),
      linkedOrgId: task.get('linkedOrgUnit'),
      linkedVet: task.get('linkedVet'),
      form: task.get('form'),
      hideLoginButton: vet.size > 0 && !vet.get('active'),
      mobilePhone: user && user.get('mobilePhone'),
      linkedOrgName: task.get('orgUnitName')
    }
  },
  {
    updateData,
    loadData,
    onClickGetReasonKey: getReasonKey
  }
)(TaskDetailsContainer)
