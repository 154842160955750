import React, { Component, ReactNode, FormEvent } from 'react'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import Warning from 'shared/components/Warning'
import classNames from 'classnames'
import EditButton from '../EditProfileButton'
import { FormattedMessage } from 'react-intl'

const styles = {
  wrapper: 'pt3-5',
  cancelButton: 'mr2'
}

type Props = {
  onEdit: () => void
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  onCancel: () => void
  placeholder: string | ReactNode
  editMode: boolean
  children: ReactNode
  readModeContent: ReactNode
  showReadModeContent: boolean
  isReadonly?: boolean
  isSubmitting?: boolean
  errorMessage?: string
  title: string | ReactNode
}

class EditForm extends Component<Props> {
  render() {
    const {
      title,
      onEdit,
      onSubmit,
      onCancel,
      editMode,
      placeholder,
      showReadModeContent,
      readModeContent,
      isReadonly,
      isSubmitting,
      errorMessage,
      children
    } = this.props

    return (
      <div className={styles.wrapper}>
        {errorMessage && <Warning message={errorMessage} />}
        {!editMode && (
          <div className='flex'>
            <div className='flex-auto pr4 i'>
              {!placeholder || showReadModeContent ? (
                <div className='fs-normal'>{readModeContent}</div>
              ) : (
                <Text secondary className='teal'>
                  {placeholder}
                </Text>
              )}
            </div>
            {!isReadonly && <EditButton onClick={onEdit} />}
          </div>
        )}

        <form hidden={!editMode} onSubmit={onSubmit}>
          {title && <h5 className='dib w-50 ma0 fw6 f6'>{title}</h5>}

          <div className={classNames('tr', title && 'dib w-50')}>
            <Button
              onClick={onCancel}
              className={styles.cancelButton}
              label={<FormattedMessage id='CancelButton' />}
              autoSize
              secondary
            />
            <Button
              label={
                <FormattedMessage id='EditForm.Save' defaultMessage='Save' />
              }
              type='submit'
              autoSize
              disabled={isSubmitting}
            />
          </div>
          {children}
        </form>
      </div>
    )
  }
}

export default EditForm
