export const SHOW_MORE_SUPPLIERS = 'buyer/SHOW_MORE_SUPPLIERS'

export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS'
export const ADD_FILTER_OPTION = 'ADD_FILTER_OPTION'
export const REMOVE_FILTER_OPTION = 'REMOVE_FILTER_OPTION'

export const SUGGEST_SUPPLIER = 'SUGGEST_SUPPLIER'
export const SUGGEST_SUPPLIER_SUCCESS = 'SUGGEST_SUPPLIER_SUCCESS'
export const SUGGEST_SUPPLIER_FAILURE = 'SUGGEST_SUPPLIER_FAILURE'

export const GET_SETTINGS = 'buyer/GET_SETTINGS'
export const CONNECT_TO_SUPPLIER = 'buyer/supplierProfile/CONNECT_TO_SUPPLIER'
export const UNFOLLOW_SUPPLIER = 'buyer/supplierProfile/UNFOLLOW_SUPPLIER'

export const SEARCH_SIMILAR_SUPPLIERS = 'buyer/SEARCH_SIMILAR_SUPPLIERS'

export const SEARCH_CONTACTS = 'buyer/contacts/SEARCH_CONTACTS'
export const SEARCH_LOCATIONS = 'buyer/shared/SEARCH_LOCATIONS'

export const GET_CARDS_BY_SUPPLIER_ID = 'GET_CARDS_BY_SUPPLIER_ID'
