import { Map, List, Record, RecordOf, fromJS } from 'immutable'
import * as actionTypes from '../actionTypes'
import { requestSuccess } from 'shared/actionTypes'
import moment, { Moment } from 'moment'
import DiversityCategory from 'shared/models/DiversityCategory'
import {
  ADD_CERTIFICATION_VALIDATION,
  REMOVE_CERTIFICATION_VALIDATION
} from '../../../SupplierProfile/actionTypes'
import { UPDATE_SETTINGS } from '../../../../admin/actionTypes'
import {
  savedBaseRules,
  savedIncludeRules,
  savedExcludeRules,
  userCustomSelection
} from '../diversityReportSelectors'
import { GET_SETTINGS } from 'buyer/shared/actionTypes'
import { agencyGroup } from 'shared/utils/data/certAgencyRank'
import CertLocations from 'shared/models/Location'

export const defaultBaseRules = fromJS({
  certAgencies: List([...agencyGroup.top, ...agencyGroup.state]),
  subCategories: List([]),
  countries: List([]),
  allSubCategories: true
})
export const defaultExcludeRules = fromJS({
  myTeam: 'rejected'
})
export const defaultIncludeRules = fromJS({
  myTeam: 'anyAgencies',
  tealbook: 'ignore',
  completeness: fromJS({
    attachmentAgencies: List([]),
    attachmentAgenciesSelected: false
  }),
  attestation: fromJS({
    selfCertified: false,
    notVerifiedByTealbook: false,
    certAgencies: List([]),
    certAgenciesSelected: false
  })
})

export type CertificationValidation = {
  category: string
  certificationNumber?: string
  confirmed: boolean
  date: string
  note: string
  subCategory: DiversityCategory | ''
  userId: string
  timeStamp: string
  validationExpires?: string
  byColleague?: boolean
}

export const CertificationValidationsRecord = Record<CertificationValidation>({
  category: '',
  certificationNumber: '',
  confirmed: false,
  date: '',
  note: '',
  subCategory: '',
  userId: '',
  timeStamp: '',
  validationExpires: '',
  byColleague: false
})

export type Info = {
  potential: boolean
  nameMatch: boolean
  alertCodes: List<string>
  infoCodes: List<string>
  validationCodes: List<string>
}

export type Company = {
  duns: string
  parentDuns: string
  name: string
  address?: string //remove after certLocations patch ran
  locations?: List<RecordOf<CertLocations> | RecordOf<{ address: string }>> //remove after certLocations patch ran
  location?: RecordOf<CertLocations> | RecordOf<{ address: string }>
  websiteUrl?: string
  contact?: RecordOf<{
    name: string
    email: string
    phone: string
  }>
}

export type CertNaics = {
  code: string
  primary: boolean
}

type Agency = {
  certAgency: string
  expiration?: string
  confirmed: boolean
  sourceURL: string
  subTypes: List<string>
  certNaics: List<CertNaics>
  company: RecordOf<Company> | null
  info: RecordOf<Info> | null
}

export const AgencyRecord = Record<Agency>({
  certAgency: '',
  expiration: '',
  confirmed: false,
  sourceURL: '',
  subTypes: List([]),
  certNaics: List([]),
  info: null,
  company: null
})

export type DiversityCertification = {
  agencies: List<RecordOf<Agency>>
  certificationValidations: List<RecordOf<CertificationValidation>>
  confirmed: boolean
  subCategory: DiversityCategory | ''
  timeStamp: string
  lastModifiedTimeStamp?: string
  attachment?: boolean
}

export type DiversityOverview = {
  includedAmount: number
  likelyAmount: number
  subCategory: DiversityCategory | ''
}

export type Location = {
  country: string
  countryAbbrev: string
  amount: number
}
const LocationRecord = Record<Location>({
  country: '',
  countryAbbrev: '',
  amount: 0
})

export type Category = {
  category: string
  amount: number
}

const CategoryRecord = Record<Category>({
  category: 'null',
  amount: 0
})

export type SpendGroup = {
  spendGroup: string
  amount: number
}

const SpendGroupRecord = Record<SpendGroup>({
  spendGroup: 'null',
  amount: 0
})

export type DiversityDetailsByInternalId = {
  domain: string
  name: string
  orgUnitId: string
  internalSupplierId: string
  supplierRelationshipId: string
  totalAmount: number
  locations: List<RecordOf<Location>>
  subCategories: List<DiversityCategory>
  certAgencies: List<string>
  categories: List<RecordOf<Category>>
  spendGroups: List<RecordOf<SpendGroup>>
  preferred: boolean
  certifications: List<RecordOf<DiversityCertification>>
}

export type DiversityDetails = {
  domain: string
  name: string
  orgUnitId: string
  // internalSupplierId: List<string>
  internalSupplierId: Map<string, any>
  supplierRelationshipId: string
  totalAmount: number
  locations: List<RecordOf<Location>>
  subCategories: List<DiversityCategory>
  certAgencies: List<string>
  categories: List<RecordOf<Category>>
  spendGroups: List<RecordOf<SpendGroup>>
  preferred: boolean
  certifications: List<RecordOf<DiversityCertification>>
}

export type BaseRulesType = {
  certAgencies: List<string>
  subCategories: List<DiversityCategory | ''>
  countries: List<string>
  allSubCategories: boolean
}

export type IncludeRulesType = {
  myTeam: 'ignore' | 'includeExpired' | 'anyAgencies'
  tealbook: 'ignore' | 'includeValid' | 'anyAgencies'
  completeness: RecordOf<{
    attachmentAgencies: List<string>
    attachmentAgenciesSelected: boolean
  }>
  attestation: RecordOf<{
    selfCertified: boolean
    notVerifiedByTealbook: boolean
    certAgencies: List<string>
    certAgenciesSelected: boolean
  }>
}

export type ExcludeRulesType = {
  myTeam: 'ignore' | 'rejected'
}

export type DiverseQualificationRules = {
  baseRules: RecordOf<BaseRulesType>
  includeRules: RecordOf<IncludeRulesType>
  excludeRules: RecordOf<ExcludeRulesType>
}
export const baseRules = Record<BaseRulesType>({
  certAgencies:
    savedBaseRules?.certAgencies?.length > 0
      ? List([...savedBaseRules.certAgencies])
      : defaultBaseRules.get('certAgencies'),
  subCategories:
    savedBaseRules?.subCategories?.length > 0
      ? List([...savedBaseRules.subCategories])
      : defaultBaseRules.get('subCategories'),
  countries:
    savedBaseRules?.countries?.length > 0
      ? List([...savedBaseRules.countries])
      : defaultBaseRules.get('countries'),
  allSubCategories: savedBaseRules
    ? savedBaseRules.allSubCategories
    : defaultBaseRules.get('allSubCategories')
})

export const includeRules = Record<IncludeRulesType>({
  myTeam: savedIncludeRules?.myTeam || defaultIncludeRules.get('myTeam'),
  tealbook: savedIncludeRules?.tealbook || defaultIncludeRules.get('tealbook'),
  completeness: fromJS({
    attachmentAgencies: savedIncludeRules?.completeness
      ? List([...savedIncludeRules.completeness.attachmentAgencies])
      : defaultIncludeRules.getIn(['completeness', 'attachmentAgencies']),
    attachmentAgenciesSelected:
      !!savedIncludeRules?.completeness.attachmentAgencies.size ||
      defaultIncludeRules.getIn(['completeness', 'attachmentAgenciesSelected'])
  }),
  attestation: fromJS({
    selfCertified: savedIncludeRules?.attestation
      ? savedIncludeRules.attestation.selfCertified
      : defaultIncludeRules.getIn(['attestation', 'selfCertified']),
    notVerifiedByTealbook: savedIncludeRules?.attestation
      ? savedIncludeRules.attestation.notVerifiedByTealbook
      : defaultIncludeRules.getIn(['attestation', 'notVerifiedByTealbook']),
    certAgencies: savedIncludeRules?.attestation
      ? List([...savedIncludeRules.attestation.certAgencies])
      : defaultIncludeRules.getIn(['attestation', 'certAgencies']),
    certAgenciesSelected:
      !!savedIncludeRules?.attestation.certAgencies.size ||
      defaultIncludeRules.get('certAgenciesSelected')
  })
})

export const excludeRules = Record<ExcludeRulesType>({
  myTeam: savedExcludeRules
    ? savedExcludeRules.myTeam
    : defaultExcludeRules.get('myTeam')
})

export const diverseQualificationRules = Record<DiverseQualificationRules>({
  baseRules: new baseRules(),
  includeRules: new includeRules(),
  excludeRules: new excludeRules()
})

export type DiversityReportState = {
  hasSubmitted: boolean
  loading: boolean
  overview: List<RecordOf<DiversityOverview>>
  details: List<RecordOf<DiversityDetailsByInternalId>>
  totalSpend: number
  minSpendDate: Moment | undefined
  maxSpendDate: Moment | undefined
  startDate: Moment | undefined
  endDate: Moment | undefined
  overviewCategories: List<DiversityCategory>
  categories: List<DiversityCategory>
  isMatchAnyCategories: boolean
  selectedCategories: List<DiversityCategory>
  selectedGroupingValues: List<string>
  currentGrouping: 'subCategory' | 'category' | 'country' | 'spendGroup'
  selectedAuthorities: List<string>
  selectedSpend: 'includedAmount' | 'likelyAmount' | ''
  diverseQualificationRules: RecordOf<DiverseQualificationRules>
  customRulesType?: string
}

export const DiversityReportRecord = Record<DiversityReportState>({
  hasSubmitted: false,
  loading: false,
  overview: List(),
  details: List(),
  totalSpend: 0,
  minSpendDate: undefined,
  maxSpendDate: undefined,
  startDate: undefined,
  endDate: undefined,
  overviewCategories: List([]),
  categories: List([]),
  isMatchAnyCategories: true,
  selectedCategories: List([]),
  selectedGroupingValues: List([]),
  currentGrouping: 'subCategory',
  selectedAuthorities: List([]),
  selectedSpend: '',
  diverseQualificationRules: new diverseQualificationRules(),
  customRulesType: userCustomSelection ? 'user' : undefined
})

export const DiversityDetailsRecord = Record<DiversityDetails>({
  domain: '',
  name: '',
  orgUnitId: '',
  internalSupplierId: Map({}),
  supplierRelationshipId: '',
  totalAmount: 0,
  certAgencies: List([]),
  subCategories: List([]),
  locations: List([]),
  categories: List([]),
  spendGroups: List([]),
  preferred: false,
  certifications: List([])
})

export const CertificationRecord = Record<DiversityCertification>({
  agencies: List([]),
  certificationValidations: List([]),
  confirmed: false,
  subCategory: '',
  timeStamp: '',
  lastModifiedTimeStamp: '',
  attachment: false
})

const diversityReportReducer = (state = DiversityReportRecord(), action) => {
  switch (action.type) {
    case requestSuccess(actionTypes.GET_SPEND_YEARS):
      return state.merge({
        minSpendDate: action.payload.minSpendDate,
        maxSpendDate: action.payload.maxSpendDate
      })

    case actionTypes.GENERATE_DIVERSITY_REPORT:
      return state.merge({
        loading: true,
        hasSubmitted: false,
        startDate: moment(action.payload.startDate),
        endDate: moment(action.payload.endDate),
        selectedCategories: List([]),
        selectedSpend: ''
      })

    case requestSuccess(actionTypes.GENERATE_DIVERSITY_REPORT):
      return state.merge({
        loading: false,
        hasSubmitted: true,
        totalSpend: action.payload.totalSpend,
        details: List(
          action.payload.details.map(detailItem =>
            DiversityDetailsRecord(detailItem)
              .set('certAgencies', List(detailItem.certAgencies))
              .set('subCategories', List(detailItem.subCategories))
              .set(
                'categories',
                List(
                  detailItem.categories.map(category =>
                    CategoryRecord(category)
                  )
                )
              )
              .set(
                'spendGroups',
                List(
                  detailItem.spendGroups.map(spendGroup =>
                    SpendGroupRecord(spendGroup)
                  )
                )
              )
              .set(
                'locations',
                List(
                  detailItem.locations.map(location => LocationRecord(location))
                )
              )
              .set(
                'certifications',
                List(
                  detailItem.certifications.map(cert =>
                    CertificationRecord(cert).merge({
                      agencies: List(cert.agencies.map(AgencyRecord)),
                      certificationValidations: List(
                        cert.certificationValidations.map(
                          CertificationValidationsRecord
                        )
                      )
                    })
                  )
                )
              )
          )
        )
      })

    case actionTypes.FILTER_OVERVIEW_CATEGORIES:
      return state.merge({
        overviewCategories: action.payload,
        selectedCategories: List([])
      })

    case actionTypes.SET_MATCH_ANY_CATEGORIES:
      return state.set('isMatchAnyCategories', action.payload)

    case actionTypes.SELECT_DIVERSITY_CATEGORY:
      return state.update('selectedCategories', selectedCategories =>
        selectedCategories.includes(action.payload)
          ? selectedCategories.filter(cat => cat !== action.payload)
          : selectedCategories.push(action.payload)
      )

    case actionTypes.SET_DIVERSITY_CATEGORY:
      return state.set('selectedCategories', List([action.payload]))

    case actionTypes.CLEAR_ALL_DIVERSITY_CATEGORIES:
      return state.update('selectedCategories', selectedCategories => List([]))

    case actionTypes.SELECT_ALL_DIVERSITY_CATEGORIES:
      return state.update(
        'selectedCategories',
        selectedCategories => action.payload
      )

    case actionTypes.SELECT_DIVERSITY_AUTHORITY:
      return state.set('selectedAuthorities', action.payload)

    case actionTypes.CHANGE_DIVERSITY_QUALIFICATION_RULES:
      return state
        .setIn(
          ['diverseQualificationRules', 'baseRules'],
          baseRules(fromJS(action.payload.baseRules))
        )
        .setIn(
          ['diverseQualificationRules', 'includeRules'],
          includeRules(fromJS(action.payload.includeRules))
        )
        .setIn(
          ['diverseQualificationRules', 'excludeRules'],
          excludeRules(fromJS(action.payload.excludeRules))
        )
    case actionTypes.SET_CUSTOM_RULES_TYPE:
      return state.setIn(['customRulesType'], action.payload)

    //default is assigned from diverseQualificationRules record factory above
    //if rules saved in localStorage, else system default rules
    //if corporate rules have been set and nothing saved in local storage assign corporate rules as default
    case requestSuccess(GET_SETTINGS):
    case requestSuccess(UPDATE_SETTINGS):
      const { corporateDiversityQualificationRules } = action.payload
      if (corporateDiversityQualificationRules && !userCustomSelection) {
        return state
          .setIn(
            ['diverseQualificationRules', 'baseRules'],
            baseRules(fromJS(corporateDiversityQualificationRules.baseRules))
          )
          .setIn(
            ['diverseQualificationRules', 'includeRules'],
            includeRules(
              fromJS(corporateDiversityQualificationRules.includeRules)
            )
          )
          .setIn(
            ['diverseQualificationRules', 'excludeRules'],
            excludeRules(
              fromJS(corporateDiversityQualificationRules.excludeRules)
            )
          )
          .setIn(['customRulesType'], 'corporate')
      } else {
        return state
      }

    case requestSuccess(ADD_CERTIFICATION_VALIDATION):
      const detailsRecordIndex = state.details.findIndex(
        item =>
          item.orgUnitId === action.payload.supplierRelationship.parents.OrgUnit
      )
      return detailsRecordIndex > -1
        ? state.update('details', details =>
            details.update(detailsRecordIndex, detailRecord =>
              detailRecord
                .set(
                  'supplierRelationshipId',
                  action.payload.supplierRelationship.id
                )
                .update('certifications', certifications => {
                  const validationKey = `${
                    action.payload.certificationValidation.category
                  }-${
                    action.payload.certificationValidation.subCategory
                  }-${action.payload.certificationValidation.timeStamp || ''}`
                  const newValidation =
                    action.payload.supplierRelationship.buyerCollaboration
                      .certificationValidation[validationKey][0]

                  const certificationIndex = certifications.findIndex(
                    cert =>
                      cert.subCategory === newValidation.subCategory &&
                      cert.timeStamp === newValidation.timeStamp
                  )
                  return certifications.updateIn(
                    [certificationIndex, 'certificationValidations'],
                    certificationValidations =>
                      certificationValidations.push(
                        CertificationValidationsRecord(newValidation)
                      )
                  )
                })
            )
          )
        : state

    case requestSuccess(REMOVE_CERTIFICATION_VALIDATION):
      const detailsRecordIndex2 = state.details.findIndex(
        item => item.orgUnitId === action.payload.supplierId
      )
      return detailsRecordIndex2 > -1
        ? state.update('details', details =>
            details.update(detailsRecordIndex2, detailRecord =>
              detailRecord.update('certifications', certifications => {
                const certificationIndex = certifications.findIndex(
                  cert =>
                    cert.subCategory === action.payload.subCategory &&
                    cert.timeStamp === action.payload.timeStamp
                )
                return certifications.updateIn(
                  [certificationIndex, 'certificationValidations'],
                  certificationValidations => certificationValidations.pop()
                )
              })
            )
          )
        : state

    case actionTypes.CHANGE_GROUPING:
      return state
        .set('currentGrouping', action.payload)
        .set('selectedGroupingValues', List([]))
        .set('selectedCategories', List([]))

    case actionTypes.SELECT_GROUPING_VALUE:
      return state.update('selectedGroupingValues', selectedGroupingValues =>
        selectedGroupingValues.includes(action.payload)
          ? selectedGroupingValues.filter(cat => cat !== action.payload)
          : selectedGroupingValues.push(action.payload)
      )

    case actionTypes.CLEAR_GROUPING_VALUE:
      return state.set('selectedGroupingValues', List([]))

    default:
      return state
  }
}

export default diversityReportReducer
