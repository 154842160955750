import React, { Component } from 'react'
import { List, RecordOf } from 'immutable'
import ListItem from 'shared/components/List/ListItem'
import fileToUrl from 'shared/utils/data/fileToUrl'
import OrgLogo from 'shared/components/OrgLogo'
import PageSideSection from 'shared/components/PageSideSection'
import parsePath from 'shared/utils/parsePath'
import paths from 'buyer/routes/paths'
import Link from 'shared/components/Link'
import { FormattedMessage } from 'react-intl'
import qs from 'qs'

type Props = {
  supplierCount: number
  suppliers: List<
    RecordOf<{
      id: string
      domains: List<string>
      supplier: RecordOf<{ logo: string }>
      name: string
    }>
  >
  userId: string
}

class SupplierConnections extends Component<Props> {
  render() {
    const { supplierCount, suppliers, userId } = this.props

    return suppliers && supplierCount > 0 ? (
      <div>
        <PageSideSection
          title={
            <FormattedMessage
              id='SupplierConnections.SupplierConnections'
              defaultMessage='{supplierCount} Supplier Connections'
              values={{ supplierCount }}
            />
          }
        >
          {suppliers.map((sup, index) => {
            const {
              id,
              domains,
              supplier: { logo }
            } = sup.toJS()

            const logoUrl = logo
              ? fileToUrl(logo)
              : domains && domains.length > 0
              ? `https://logo.clearbit.com/${domains[0]}`
              : ''

            const profileUrl = `${parsePath(paths.supplierProfile, {
              supplierId: id
            })}`

            return (
              <ListItem
                key={id}
                mini
                leftAvatar={
                  <div
                    className='pa1 dtc v-mid ba b--black-10 br2 bg-white'
                    style={{
                      height: '32px',
                      width: '32px',
                      minWidth: '32px',
                      maxHeight: '32px'
                    }}
                  >
                    <Link to={profileUrl}>
                      <OrgLogo url={logoUrl} />
                    </Link>
                  </div>
                }
                primaryText={<Link to={profileUrl}>{sup.get('name')}</Link>}
              />
            )
          })}

          {supplierCount > 10 && (
            <Link
              to={`${paths.search}?${qs.stringify({
                filter: {
                  connectedUserId: [userId]
                },
                scope: 'ColleagueConnections'
              })}`}
              className='f8 fw3 gray underline-hover pointer mt2 mb0 lh-copy dib v-mid bn'
            >
              <FormattedMessage
                id='SupplierConnections.SeeMore'
                defaultMessage='See more {rightIcon}'
                values={{ rightIcon: <span>&raquo;</span> }}
              />
            </Link>
          )}
        </PageSideSection>
      </div>
    ) : null
  }
}

export default SupplierConnections
