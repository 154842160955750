import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import Loading from 'shared/components/Loading'
import SupplierProfilePage from '../../components/SupplierProfilePage'
import {
  loadSupplierProfile,
  getVetBuyerSuppliers,
  loadSocialFeed
} from '../../actions'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import profileSelectors from '../../selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import tealboard from 'shared/utils/api/tealboard'
import PageNotFound from 'shared/components/PageNotFound'
import qs from 'qs'
import RootState from 'shared/models/RootState'
import { AccessControl } from 'shared/models/AccessControl'

type Props = {
  isLoading: boolean
  isTealbot: boolean
  pageNotFound: boolean
  isDataReady: boolean
  location: { search: string }
  supplierId: string
  accessControl: AccessControl
  loadSupplierProfile: (
    arg1: string,
    arg2: { vetId: string; searchId: string }
  ) => void
  getVetBuyerSuppliers: (arg: string) => void
  hasCommunityFormResponse: boolean
  customPageName?: string
} & ContainerProps

export const SupplierProfilePageContainer: FunctionComponent<Props> = props => {
  const {
    location: { search },
    supplierId,
    loadSupplierProfile,
    getVetBuyerSuppliers
  } = props
  const { vetId, searchId } = qs.parse(search, { ignoreQueryPrefix: true })
  useEffect(() => {
    loadSupplierProfile(supplierId, { vetId, searchId })
    getVetBuyerSuppliers(supplierId)
  }, [vetId, searchId, supplierId, loadSupplierProfile, getVetBuyerSuppliers])

  const { isLoading, pageNotFound, isDataReady, ...rest } = props
  return isLoading ? (
    <Loading />
  ) : pageNotFound ? (
    <PageNotFound />
  ) : isDataReady ? (
    <SupplierProfilePage {...rest} />
  ) : null
}

type ContainerProps = RouteComponentProps<{ supplierId: string }>

export default withRouter(
  connect(
    (state: RootState, props: ContainerProps) => {
      const communityFormResponse = profileSelectors.getFormResponses(
        state,
        props.match.params.supplierId
      )
      return {
        supplierId: props.match.params.supplierId,
        isLoading: profileSelectors.isLoading(state),
        isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
        isDataReady:
          props.match.params.supplierId ===
          profileSelectors.getDataField(state, 'id'),
        hasCoverUrl: !!profileSelectors.getDataField(state, 'coverUrl'),
        pageNotFound: !!profileSelectors.getErrorMessage(state),
        twitter: tealboard.formatTwitter(
          profileSelectors.getDataField(state, 'twitterId')
        ),
        accessControl: sessionSelectors.getAccessControl(state),
        hasCommunityFormResponse: !!communityFormResponse.get('availableForms')
          ?.size,
        customPageName: state.getIn([
          'buyer',
          'settings',
          'supplierProfileCustomPage',
          'title'
        ])
      }
    },
    {
      loadSupplierProfile,
      getVetBuyerSuppliers,
      loadSocialFeed
    }
  )(SupplierProfilePageContainer)
)
