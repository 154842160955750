import { call, put, select } from 'redux-saga/effects'
import job from 'shared/utils/api/job'
import jobsSelectors from '../selectors/jobsSelectors'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import Immutable from 'immutable'
import { navToNext } from '../actions'

export function* submitAnswersSaga(action) {
  // get jobId, rowNum, naics answers from the form values
  const { jobId, rowNum, ...answers } = Immutable.isImmutable(action.payload)
    ? action.payload?.toJS()
    : action.payload

  const questions = yield select(jobsSelectors.getQuestions, rowNum, jobId)

  // get classificationCodes from current supplier
  const classificationCodes = yield select(
    jobsSelectors.getOrgUnitClassificationCodes,
    rowNum
  )

  // define result dataset
  let result: {
    jobId: string
    rowNum: number
    answers: { [key: string]: any }
  } = { jobId, rowNum, answers: {} }

  let naicsAnswer
  questions?.forEach((question, index) => {
    const questionId = question.get('questionId') || `q${index}`
    if (answers[questionId || 'naics']) {
      if (question.get('answerType') === 'NAICS') {
        const naics = answers[questionId || 'naics']

        // format naics answer to match expected format
        naicsAnswer = classificationCodes?.toJS().reduce(
          (reduction, code) => {
            reduction[naics[code.code]].push(code)
            return reduction
          },
          {
            keep: [],
            unsure: [],
            remove: []
          }
        )

        result.answers[questionId] = naicsAnswer
      } else {
        result.answers[questionId] = answers[questionId]
      }
    }
  })

  // update result for given rowNum
  const results = yield call(job.createJobResult, result)

  // determine route to load after updated answers
  // get number of rows (suppliers)
  const jobRows = yield select(jobsSelectors.getRows)

  // get list of rows (suppliers) have already answered
  let answeredRows = yield select(jobsSelectors.getRowsAnswered)
  if (!answeredRows.includes(rowNum)) {
    answeredRows = answeredRows?.push(rowNum)
  }

  // check if job is done by checking number of row answer match number of rows in the job
  const done = !!(answeredRows?.size >= jobRows?.size)

  yield put(navToNext({ jobId, rowNum, answeredRows }))

  return yield Object.assign({}, results, { jobId, done })
}

export default createApiRequestSaga(submitAnswersSaga)
