import React, { useState } from 'react'
import AddCertificationFormContainer from '../../containers/DiversitySectionContainer/AddCertificationFormContainer'
import certificationCategories, {
  CategoryType
} from 'shared/models/CertificationCategories'
import CertificationCategoryTitle from 'shared/components/CertificationCategoryTitle'
import CertificationEditForm from './CertificationEditForm'
import createEditForm from 'shared/utils/createEditForm'
import { UPDATE_CERTIFICATION } from '../../actionTypes'
import { RecordOf, List } from 'immutable'
import moment from 'moment'
import DiversityRenewDialog from '../../containers/DiversitySectionContainer/DiversityRenewDialog'
import startCase from 'lodash.startcase'
import { useIntl } from 'react-intl'
import Certification from 'shared/models/Certification'

type Props = {
  isTealbot: boolean
  certifications: List<[CategoryType, List<RecordOf<Certification>>]>
  onRemoveCertificate: (params: {
    category: string
    subCategory: string
    timeStamp: string | boolean
  }) => void
}

const DiversitySection = (props: Props) => {
  const intl = useIntl()
  const [openRenewalDialog, setOpenRenewalDialog] = useState<boolean>(false)
  const [timeStamp, setTimeStamp] = useState<string>('')

  const toggleRenewalDialog = (newTimeStamp: string = '') => {
    setOpenRenewalDialog(open => !open)
    setTimeStamp(newTimeStamp)
  }

  const { certifications, onRemoveCertificate, isTealbot } = props

  return (
    <>
      {certifications &&
        certifications.map(([categoryKey, certs]) => {
          return (
            <div key={categoryKey} className='ph3-5 pt3-5'>
              <CertificationCategoryTitle
                title={
                  certificationCategories[categoryKey]
                    ? intl.formatMessage(
                        certificationCategories[categoryKey].title
                      )
                    : startCase(categoryKey)
                }
                icon={
                  certificationCategories[categoryKey] &&
                  certificationCategories[categoryKey].icon
                }
              />

              {certs &&
                certs.map((certification, index) => {
                  const certCategory = certification.get('category')
                  const formName = `supplier/company/certifications/${certCategory}/${certification.get(
                    'subCategory'
                  )}/${(certification.get('timeStamp') &&
                    certification.get('timeStamp').replace(/[ .:]/g, '')) ||
                    index}`
                  const EditForm = createEditForm(
                    {
                      formName,
                      injectFieldValues: [
                        'certifyingLocationKey',
                        'qualifiedNaics',
                        'primaryNaics'
                      ],
                      submitActionType: UPDATE_CERTIFICATION,
                      enableReinitialize: true,
                      destroyOnUnmount: false
                    },
                    CertificationEditForm
                  )

                  return (
                    !!EditForm && (
                      <EditForm
                        key={`${index}-${certification.get(
                          'category'
                        )}-${certification.get('subCategory')}`}
                        category={certification.get('category')}
                        subCategory={certification.get('subCategory')}
                        subTypes={certification.get('subTypes')}
                        certificateUrl={certification.get('certificationUrl')}
                        certExpiration={
                          certification.get('certExpiration') &&
                          new Date(certification.get('certExpiration'))
                        }
                        sourceURL={certification.get('sourceURL')}
                        certAgency={certification.get('certAgency')}
                        certificateNumber={certification.get(
                          'certificateNumber'
                        )}
                        lastModifiedTimeStamp={certification.get(
                          'lastModifiedTimeStamp'
                        )}
                        supplierName={certification.get('supplierName')}
                        supplierCertified={
                          certification.get('supplierCertified') || ''
                        }
                        certNaics={certification.get('certNaics')}
                        certifyingLocation={certification.get(
                          'certifyingLocation'
                        )}
                        company={certification.get('company')}
                        info={certification.get('info')}
                        confirmed={certification.get('confirmed')}
                        isTealbot={isTealbot}
                        initialValues={{
                          category: certification.get('category'),
                          subCategory: certification.get('subCategory'),
                          sourceURL: certification.get('sourceURL'),
                          certExpiration: certification.get('certExpiration')
                            ? new Date(certification.get('certExpiration'))
                            : undefined,
                          certAgency: certification.get('certAgency'),
                          certificateNumber: certification.get(
                            'certificateNumber'
                          ),
                          timeStamp: certification.get('timeStamp') || false,
                          supplierCertified: certification.get(
                            'supplierCertified'
                          ),
                          subTypes: certification.getIn(['subTypes', '0']),
                          certifyingLocationKey:
                            certification.get('certifyingLocation') &&
                            certification.getIn([
                              'certifyingLocation',
                              'location'
                            ]),
                          qualifiedNaics:
                            certification.get('certNaics') &&
                            certification
                              .get('certNaics')
                              ?.reduce((result, naics) => {
                                result[naics.get('code')] = true
                                return result
                              }, {}),
                          primaryNaics:
                            certification.get('certNaics') &&
                            certification
                              .get('certNaics')
                              ?.find(naics => naics.get('primary'))
                              ?.get('code'),
                          info: certification.get('info'),
                          company: certification.get('company')
                        }}
                        onRemove={() =>
                          onRemoveCertificate({
                            category: certification.get('category'),
                            subCategory: certification.get('subCategory'),
                            timeStamp: certification.get('timeStamp') || false
                          })
                        }
                        onRenew={
                          certification.get('category') === 'diversity' &&
                          moment(certification.get('lastModifiedTimeStamp'))
                            .add(1, 'year')
                            .isBefore(moment())
                            ? () =>
                                toggleRenewalDialog(
                                  certification.get('timeStamp')
                                )
                            : undefined
                        }
                      />
                    )
                  )
                })}
            </div>
          )
        })}

      {certifications && certifications.size > 0 && <div className='mt3-5' />}

      <AddCertificationFormContainer />

      {timeStamp && (
        <DiversityRenewDialog
          timeStamp={timeStamp}
          open={openRenewalDialog}
          onClose={toggleRenewalDialog}
        />
      )}
    </>
  )
}

export default DiversitySection
