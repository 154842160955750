import React from 'react'
import Paper from 'shared/components/Paper'
import AppConfigNav from './AppConfigNav'
import Divider from 'shared/components/Divider'
import { Switch, Route } from 'react-router'
import paths from '../../routes/paths'
import AppConfigSRM from './AppConfigSRM'
import AppConfigRFI from './AppConfigRFI'
import AppConfigSecurity from './AppConfigSecurity'
import AppConfigTools from './AppConfigTools'
import AppConfigIntegrationsContainer from 'admin/containers/AppConfigIntegrationsContainer'

type Props = {
  isBuyerAdmin: boolean
  isTealbot: boolean
}
const AppConfig = ({ isBuyerAdmin, isTealbot }: Props) => {
  return (
    <>
      <AppConfigNav isTealbot={isTealbot} />
      <Divider />
      <Paper className='mt4 pt3-5'>
        <Switch>
          {isBuyerAdmin && (
            <Route exact path={paths.appConfig} component={AppConfigSRM} />
          )}
          {isTealbot && <Route path={paths.appRFI} component={AppConfigRFI} />}
          {isTealbot && (
            <Route path={paths.appSecurity} component={AppConfigSecurity} />
          )}
          {isTealbot && (
            <Route path={paths.appTools} component={AppConfigTools} />
          )}
          {(isBuyerAdmin || isTealbot) && (
            <Route
              path={paths.integrations}
              component={AppConfigIntegrationsContainer}
            />
          )}
        </Switch>
      </Paper>
    </>
  )
}

export default AppConfig
