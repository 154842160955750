import React, { Component } from 'react'
import createAddCommentForm from 'shared/utils/createAddCommentForm'
import { ADD_COMMENT } from 'shared/actionTypes'

const TaskDetailsNewComment = createAddCommentForm({
  formName: 'dataPortal/taskDetails/newComment',
  submitFormActionType: ADD_COMMENT
})

type Props = {
  taskId: string
}

export class AddTaskComment extends Component<Props> {
  render() {
    return (
      <TaskDetailsNewComment
        initialValues={{
          ancestorId: this.props.taskId,
          messageKind: 'Task'
        }}
      />
    )
  }
}

export default AddTaskComment
