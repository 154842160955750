import { connect } from 'react-redux'
import cardsSelectors from 'buyer/shared/selectors/cardsSelectors'

type Props = {
  cardId: string
}

const withCard = component => {
  return connect((state, props: Props) => {
    const card = cardsSelectors.getById(state, props.cardId)

    return {
      cardId: card.get('id'),
      userId: card.get('owner'),
      contactFor: card.get('contactFor')
    }
  })(component)
}

export default withCard
