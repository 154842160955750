export default function reverseParsedUser(user) {
  const {
    disableAllEmails,
    options,
    mobilePhone,
    officePhone,
    linkedInUrl,
    department,
    firstName,
    lastName,
    location,
    title,
    email,
    status,
    foundTealbook,
    isPublic,
    ...rest
  } = user

  const notificationSettings =
    disableAllEmails || options
      ? {
          disableAllEmails,
          options
        }
      : undefined

  return {
    phones: {
      mobile: mobilePhone,
      office: officePhone
    },
    links: {
      linkedInUrl
    },
    department,
    firstName,
    lastName,
    location,
    title,
    email,
    status,
    foundTealbook,
    isPublic,
    notificationSettings,
    ...rest
  }
}
