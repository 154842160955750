import React, { Component, ReactNode } from 'react'
import iconComment from 'shared/assets/icons/comment.svg'
import iconTealComment from 'shared/assets/icons/comment-teal.svg'
import classNames from 'classnames'

const styles = {
  topMargin: 'mt2',
  wrapper: 'dib f7 fw4 ma0 mr4',
  clickable: 'pointer dim',
  color: 'gray',
  commentedColor: 'teal',
  label: 'ml1'
}

type Props = {
  count?: number
  noCountLabel: string | ReactNode
  commented: boolean
  noMargin: boolean
  onClick: () => void
}

class CommentButton extends Component<Props> {
  render() {
    const { count, noCountLabel, commented, onClick, noMargin } = this.props
    return (
      <div
        className={classNames(
          styles.wrapper,
          !noMargin && styles.topMargin,
          commented ? styles.commentedColor : styles.color,
          onClick && styles.clickable
        )}
        onClick={onClick}
      >
        <img src={commented ? iconTealComment : iconComment} alt='Comment' />{' '}
        <span className={styles.label}>{!count ? noCountLabel : count}</span>
      </div>
    )
  }
}

export default CommentButton
