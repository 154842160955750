import React, { memo, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from 'shared/components/MenuItem'
import { FormattedMessage } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import Button from 'shared/components/Button'
import Warning from 'shared/components/Warning'
import { useDispatch, useSelector } from 'react-redux'
import {
  bulkDeleteUsers,
  bulkUpdateStatus,
  bulkUpdateRoles,
  resendUserInvite
} from '../../actions'
import RadioButtonGroup from 'shared/components/RadioButton/RadioButtonGroup'
import RadioButton from 'shared/components/RadioButton'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import editUserSelectors from '../../selectors/editUserSelectors'
import { RecordOf } from 'immutable'
import sessionSelectors from 'shared/selectors/sessionSelectors'

type Props = {
  usersSelected: {
    userId: {
      id: string
      roles: string[]
      status: string
      firstName: string
      lastName: string
    }
  }
  currentOrgUnit: string
} & ContainerProps

const BulkActions = memo(
  ({ anchorEl, usersSelected, onHandleCloseMenu, currentOrgUnit }: Props) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [deleteReason, setDeleteReason] = useState<string | undefined>('')
    const [deleteReasonOther, setDeleteReasonOther] = useState<
      string | undefined
    >('')

    const dispatch = useDispatch()

    const disableDeleteUserButton =
      !deleteReason || (deleteReason === 'Other' && !deleteReasonOther)

    const handleChangeDeleteReasonOther = e => {
      setDeleteReasonOther(e.currentTarget.value)
    }

    const handleChangeDeleteReason = e => {
      setDeleteReason(e.currentTarget.value)
      setDeleteReasonOther('')
    }

    const handleClickDeleteUsers = e => {
      e.preventDefault()
      setDeleteReason('')
      setDeleteReasonOther('')
      let users: Array<{
        id: string
        roles: Array<string>
        status: string
        firstName: string
        lastName: string
      }> = Object.values(usersSelected)
      let listOfUserIds: string[] = []
      if (!disableDeleteUserButton) {
        users.forEach(user => {
          listOfUserIds.push(user.id)
        })
        dispatch(
          bulkDeleteUsers({
            listOfUserIds,
            query: {
              reason:
                deleteReason === 'Other'
                  ? `Other: ${deleteReasonOther}`
                  : deleteReason,
              hardDelete: true
            }
          })
        )
      }
      setOpenDeleteDialog(false)
    }

    const handleUpdateStatus = newStatus => {
      dispatch(
        bulkUpdateStatus({
          listOfUsers: usersSelected,
          status: newStatus === 'unblock' ? 'unblock' : 'block'
        })
      )
      onHandleCloseMenu()
    }

    const handleRemoveBuyerRoles = () => {
      let users: Array<{
        id: string
        roles: Array<string>
        status: string
        firstName: string
        lastName: string
      }> = Object.values(usersSelected)
      let updatedUsers = {}
      //users must have either supplier or buyer role
      let missingDefaultRole: string[] = []
      users.forEach(user => {
        const name = `${user.firstName} ${user.lastName}`
        if (!user.roles.includes('supplier')) {
          missingDefaultRole.push(name)
        } else {
          updatedUsers = {
            ...updatedUsers,
            [user.id]: {
              roles: user.roles.filter(role => !role.startsWith('buyer'))
            }
          }
        }
      })
      onHandleCloseMenu()
      dispatch(bulkUpdateRoles({ updatedUsers, missingDefaultRole }))
    }
    const handleSetBuyerRoles = () => {
      let users: Array<{
        id: string
        roles: Array<string>
        status: string
      }> = Object.values(usersSelected)
      let updatedUsers = {}
      users.forEach(user => {
        if (!user.roles.includes('buyer')) {
          updatedUsers = {
            ...updatedUsers,
            [user.id]: { roles: [...user.roles, 'buyer'] }
          }
        }
      })
      onHandleCloseMenu()
      dispatch(bulkUpdateRoles({ updatedUsers }))
    }
    const handleRemoveSupplierRoles = () => {
      let users: Array<{
        id: string
        roles: Array<string>
        status: string
        firstName: string
        lastName: string
      }> = Object.values(usersSelected)
      let updatedUsers = {}
      //users must have either supplier or buyer role
      let missingDefaultRole: string[] = []
      users.forEach(user => {
        const name = `${user.firstName} ${user.lastName}`
        if (!user.roles.includes('buyer')) {
          missingDefaultRole.push(name)
        } else {
          updatedUsers = {
            ...updatedUsers,
            [user.id]: {
              roles: user.roles.filter(role => !role.startsWith('supplier'))
            }
          }
        }
      })
      onHandleCloseMenu()
      dispatch(bulkUpdateRoles({ updatedUsers, missingDefaultRole }))
    }
    const handleSetSupplierRoles = () => {
      let users: Array<{
        id: string
        roles: Array<string>
        status: string
      }> = Object.values(usersSelected)
      let updatedUsers = {}
      users.forEach(user => {
        if (!user.roles.includes('supplier')) {
          updatedUsers = {
            ...updatedUsers,
            [user.id]: { roles: [...user.roles, 'supplier'] }
          }
        }
      })
      onHandleCloseMenu()
      dispatch(bulkUpdateRoles({ updatedUsers }))
    }

    const handleResendUserInvite = () => {
      let userIds: Array<string> = Object.keys(usersSelected)
      onHandleCloseMenu()
      dispatch(resendUserInvite({ userId: userIds, currentOrgUnit }))
    }

    return (
      <>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onHandleCloseMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MenuItem onClick={() => handleUpdateStatus('block')}>
            <FormattedMessage id='UserList.Block' defaultMessage='Block' />
          </MenuItem>

          <MenuItem onClick={() => handleUpdateStatus('unblock')}>
            <FormattedMessage id='UserList.Unblock' defaultMessage='Unblock' />
          </MenuItem>

          <MenuItem onClick={handleRemoveBuyerRoles}>
            <FormattedMessage
              id='UserList.RemoveBuyerRole'
              defaultMessage='Remove BuyerRole'
            />
          </MenuItem>

          <MenuItem onClick={handleSetBuyerRoles}>
            <FormattedMessage
              id='UserList.SetBuyerRole'
              defaultMessage='Set Buyer Role'
            />
          </MenuItem>

          <MenuItem onClick={handleRemoveSupplierRoles}>
            <FormattedMessage
              id='UserList.RemoveSupplierRole'
              defaultMessage='Remove Supplier Role'
            />
          </MenuItem>

          <MenuItem onClick={handleSetSupplierRoles}>
            <FormattedMessage
              id='UserList.SetSupplierRole'
              defaultMessage='Set Supplier Role'
            />
          </MenuItem>

          <MenuItem
            onClick={() => {
              setOpenDeleteDialog(true)
              onHandleCloseMenu()
            }}
          >
            <FormattedMessage id='UserList.Delete' defaultMessage='Delete' />
          </MenuItem>
          <MenuItem onClick={handleResendUserInvite}>
            <FormattedMessage
              id='UserList.resendInvite'
              defaultMessage='Resend Tealbook Invitation'
            />
          </MenuItem>
        </Menu>

        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle>
            <FormattedMessage
              id='UserList.DeletingUsers'
              defaultMessage='Deleting Selected Users'
            />
          </DialogTitle>
          <DialogContent className='mt3'>
            <form onSubmit={handleClickDeleteUsers}>
              <RadioButtonGroup
                title={
                  <FormattedMessage
                    id='EditUserProfile.WhyDeletingTheseAccounts'
                    defaultMessage='Why are you deleting these accounts?'
                  />
                }
                stack
              >
                <RadioButton
                  required={!deleteReason}
                  label={
                    <FormattedMessage
                      id='EditUserProfile.LeavingThisCompany'
                      defaultMessage='No longer with this company'
                    />
                  }
                  value='I am leaving this company'
                  name='deleteReason'
                  onChange={handleChangeDeleteReason}
                />
                <RadioButton
                  required={!deleteReason}
                  label={
                    <FormattedMessage
                      id='EditUserProfile.NoLongerUseThisEmail'
                      defaultMessage='No longer use this email address'
                    />
                  }
                  value='I no longer use this email address'
                  name='deleteReason'
                  onChange={handleChangeDeleteReason}
                />
                <RadioButton
                  required={!deleteReason}
                  label={
                    <FormattedMessage
                      id='EditUserProfile.RightToBeForgotten'
                      defaultMessage='Exercise the right to be forgotten'
                    />
                  }
                  value='Exercise the right to be forgotten'
                  name='deleteReason'
                  onChange={handleChangeDeleteReason}
                />
                <RadioButton
                  required={!deleteReason}
                  label={
                    <FormattedMessage
                      id='EditUserProfile.Other'
                      defaultMessage='Other'
                    />
                  }
                  value='Other'
                  name='deleteReason'
                  onChange={handleChangeDeleteReason}
                />
              </RadioButtonGroup>

              {deleteReason === 'Other' && (
                <>
                  <Label htmlFor='deleteReasonOther'>
                    <FormattedMessage
                      id='EditUserProfile.OtherExplain'
                      defaultMessage='If other, please help us improve by sharing your reason:'
                    />
                  </Label>
                  <Input
                    name='deleteReasonOther'
                    onChange={handleChangeDeleteReasonOther}
                    required
                  />
                </>
              )}

              {!disableDeleteUserButton && (
                <Warning
                  className='mt2'
                  message={
                    <FormattedMessage
                      id='UserList.delete'
                      defaultMessage='These Users will not be able to log in again'
                    />
                  }
                />
              )}
              <div className='mv3'>
                <Button
                  label={
                    <FormattedMessage
                      id='UserList.Delete'
                      defaultMessage='Delete'
                    />
                  }
                  autoSize
                  onClick={handleClickDeleteUsers}
                  disabled={disableDeleteUserButton}
                  style={{ backgroundColor: 'rgb(220, 0, 78)' }}
                />
                <Button
                  label={<FormattedMessage id='CancelButton' />}
                  autoSize
                  secondary
                  onClick={() => {
                    setOpenDeleteDialog(false)
                  }}
                  className='ml3'
                />
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </>
    )
  }
)

type ContainerProps = {
  anchorEl: HTMLImageElement | null
  onHandleCloseMenu: () => void
}
const BulkActionsContainer = ({
  anchorEl,
  onHandleCloseMenu
}: ContainerProps) => {
  const bulkUsersSelected: RecordOf<{
    userId: {
      id: string
      roles: string[]
      status: string
      firstName: string
      lastName: string
    }
  }> = useSelector(editUserSelectors.getBulkUsersSelected)
  const currentOrgUnit: string = useSelector(sessionSelectors.getOrgUnitId)
  return (
    <BulkActions
      usersSelected={bulkUsersSelected?.toJS()}
      anchorEl={anchorEl}
      onHandleCloseMenu={onHandleCloseMenu}
      currentOrgUnit={currentOrgUnit}
    />
  )
}
export default BulkActionsContainer
