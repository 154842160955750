import React, { FunctionComponent } from 'react'
import SearchContainer from '../../../../buyer/Search/containers/SearchContainer'

const SearchPage: FunctionComponent = props => {
  return (
    <div className='mw8 w-100 center border-box pt3-ns ph3 ph3-ns ph4-m ph0-ns cf'>
      <SearchContainer disableSuggest refKey='communitySearch' />
    </div>
  )
}

export default SearchPage
