export default function toggleArrayItem(array, item) {
  const copyArray = [...array]
  if (copyArray.includes(item)) {
    copyArray.splice(copyArray.indexOf(item), 1)
  } else {
    copyArray.push(item)
  }

  return copyArray
}
