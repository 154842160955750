import React, { Component } from 'react'
import Page from '../Page'
import TitleText from '../TitleText'
import Text from '../Text'
import Button from '../Button'
import { FormattedMessage } from 'react-intl'
import isBrowserSupported from '../../utils/isBrowserSupported'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import TealbookLogoWrapper from 'accounts/components/TealbookLogoWrapper'

const styles = {
  phoneAndEmail: 'teal f7 fw4 dim no-underline pointer'
}

export class ErrorPage extends Component<RouteComponentProps> {
  handleButtonClick = () => {
    const {
      history: { push },
      location: { pathname }
    } = this.props
    push(pathname.slice(0, (pathname.length - pathname.indexOf('/', 1)) * -1))
  }

  render() {
    return (
      <Page title='Account Needs Support' className='tc'>
        <TealbookLogoWrapper />
        <TitleText>
          <FormattedMessage
            id='ErrorPage.OhNoThereSeemsToBeAProblem'
            defaultMessage='Oh No, there seems to be a problem.'
          />
        </TitleText>

        <p className='f4 mw6 tl center mt4 pl3'>
          <FormattedMessage
            id='ErrorPage.PleaseMakeSureToDoTheFollowing'
            defaultMessage='Please make sure to do the following:'
          />
        </p>
        <ul className='mw6 tl center'>
          <li className='mb2'>
            <FormattedMessage
              id='ErrorPage.CheckYourFirewallSettings'
              defaultMessage='Check your firewall settings'
            />
          </li>
          {!isBrowserSupported() && (
            <li>
              <FormattedMessage
                id='ErrorPage.TryADifferentABrowser'
                defaultMessage='We do not officially support the browser that you are using. It is recommended that you switch to another browser such as Google Chrome, Firefox, Microsoft Edge, Safari or Microsoft IE11+.'
              />
            </li>
          )}
        </ul>

        <div className='mt4 mw6 center pa4 ba b--black-10 bg-white'>
          <Text>
            <FormattedMessage
              id='ErrorPage.IfTheProblemPersistContactSupport'
              defaultMessage='If the problem persist feel free to contact support at <tel>1-866-620-6640</tel> or at <email>support@tealbook.com</email> and we will be able to help.'
              values={{
                tel: (str: string) => (
                  <a href={`tel:+${str}`} className={styles.phoneAndEmail}>
                    {str}
                  </a>
                ),
                email: (str: string) => (
                  <a href={`mailto:${str}`} className={styles.phoneAndEmail}>
                    {str}
                  </a>
                )
              }}
            />
            <br />
            <FormattedMessage
              id='ErrorPage.OtherwiseTryAgainLater'
              defaultMessage='Otherwise try again later.'
            />
          </Text>

          <div className='mt3-5 w5 center'>
            <Button
              onClick={this.handleButtonClick}
              label={
                <FormattedMessage
                  id='ErrorPage.GoToHompage'
                  defaultMessage='Go to Homepage'
                />
              }
            />
          </div>
        </div>
      </Page>
    )
  }
}

export default withRouter(ErrorPage)
