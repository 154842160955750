import React, { useState, useEffect } from 'react'
import { isSubmitting, formValueSelector } from 'redux-form/immutable'
import { getFormSubmitErrors } from 'shared/reducers'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import paths from 'accounts/routes/paths'
import { LOGIN, IFRAME_LOGIN } from 'accounts/actionTypes'
import { changeLanguage } from 'i18n/store/actions'
import LoginForm from '../../components/LoginForm'
import TealbookLogoWrapper from '../../components/TealbookLogoWrapper'
import users from 'shared/utils/api/users'
import auth from 'shared/utils/api/auth'
import { RouteComponentProps } from 'react-router'
import RootState from 'shared/models/RootState'
import { connect } from 'react-redux'
import { InjectedFormProps } from 'redux-form'
import { reduxForm } from 'redux-form/immutable'
import qs from 'qs'

type Props = {
  loginIn: boolean
  loginOptions?: string
  client?: string
  login: (arg) => void
  iframeLogin: (formValues: {
    orgUnitId: string
    selectedOrgUnit: string
    language: string
    loginOptions: string[]
    client: string
    inviteToComId: string
  }) => void
  reset: () => void
  change: (fieldName: string, val: any) => void
  handleSubmit: (values: {}) => () => void
  language: string
  onChangeLanguage: (lang: string) => void
} & RouteComponentProps &
  InjectedFormProps

const LoginFormContainer = (props: Props) => {
  const lastLoggedInUser = auth.getLastLoggedInUser()

  const [gettingUser, setGettingUser] = useState<boolean>(false)
  const [userNotFound, setUserNotFound] = useState<boolean>(false)
  const [selectedOrgUnit, setSelectedOrgUnit] = useState<any>(
    lastLoggedInUser.selectedOrgUnit
  )
  const [orgUnits, setOrgUnits] = useState<any>()
  const [apiError, setApiError] = useState('')

  const { login, iframeLogin, history, loginOptions, language, client } = props

  const lastLoggedInUserName = lastLoggedInUser.username || ''
  useEffect(() => {
    if (!lastLoggedInUserName) {
      setSelectedOrgUnit(null)
      setOrgUnits(null)
    }
  }, [lastLoggedInUserName])

  const handleChangeUsername = () => {
    if (userNotFound) {
      setUserNotFound(false)
    }
  }

  const handleSubmit = values => {
    // if exited out current user login, missing org unit to log into
    if (!selectedOrgUnit) {
      // already tried with the current username take them to the sign up page
      if (userNotFound) {
        history.push(`${paths.register}?email=${values.get('username')}`)
      } else {
        setApiError('')
        setGettingUser(true)
        setUserNotFound(false)
        users
          .getAllByEmail(values.get('username'))
          .then(result => {
            // multiple org units to choose from
            if (result.length > 1) {
              setOrgUnits(result)
              setGettingUser(false)
              // only one org unit to login to
            } else if (result.length === 1) {
              if (result[0].redirectUrl) {
                window.location.href = result[0].redirectUrl
              } else {
                setSelectedOrgUnit(result[0])
                setGettingUser(false)
              }
              // the user doesn't exist
              // first check if domain has SSO login and redirect if true, else set userNotFound
            } else {
              auth
                .checkOrgUnitByEmail(values.get('username'))
                .then(({ names }) => {
                  if (names && names[0]?.samlRedirectUrl) {
                    window.location.href = names[0].samlRedirectUrl
                  } else {
                    setUserNotFound(true)
                    setGettingUser(false)
                  }
                })
            }
          })
          .catch(err => {
            setApiError('An error has occurred, please try again later.')
            setGettingUser(false)
          })
      }
    } else {
      if (loginOptions && loginOptions.split(',').includes('iframe')) {
        iframeLogin(
          values.merge({
            orgUnitId: selectedOrgUnit.orgUnitId,
            selectedOrgUnit: selectedOrgUnit,
            language,
            loginOptions: loginOptions.split(','),
            client
          })
        )
      } else {
        login(
          values.merge({
            orgUnitId: selectedOrgUnit.orgUnitId,
            selectedOrgUnit: selectedOrgUnit,
            language
          })
        )
      }
    }
  }

  const handleSelectOrgUnit = orgUnit => {
    if (!orgUnit) {
      setGettingUser(false)
      setUserNotFound(false)
      setSelectedOrgUnit(undefined)
      setOrgUnits(undefined)
      props.change('username', '')
      props.change('password', '')
    } else if (orgUnit.redirectUrl) {
      window.location.href = orgUnit.redirectUrl
    } else {
      setSelectedOrgUnit(orgUnit)
    }
  }

  const handleCancel = () => {
    setOrgUnits(undefined)
    setSelectedOrgUnit(undefined)
    props.reset()
    props.change('username', '')
  }

  const renderLoginForm = () => {
    return (
      <LoginForm
        onSubmit={props.handleSubmit(handleSubmit)}
        signupUrl={paths.register}
        resetPasswordUrl={paths.resetPassword}
        onSelectOrgUnit={handleSelectOrgUnit}
        onCancel={handleCancel}
        isSubmitting={props.loginIn || gettingUser}
        onChangeUserName={handleChangeUsername}
        userNotFound={userNotFound}
        selectedOrgUnit={selectedOrgUnit}
        orgUnits={orgUnits}
        apiError={apiError}
        {...props}
      />
    )
  }

  const options = !!loginOptions && loginOptions.split(',')

  return options && options.includes('no-logo') ? (
    <div className='mw6 center ph4  pt4 cf'>{renderLoginForm()}</div>
  ) : (
    <TealbookLogoWrapper
      small={options && options.includes('iframe')}
      clientOf={props.client}
    >
      {renderLoginForm()}
    </TealbookLogoWrapper>
  )
}

const formName = 'accounts/login'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)
const formIsSubmitting = isSubmitting(formName)
const formSelector = formValueSelector(formName)

export default connect(
  (state: RootState, props: RouteComponentProps) => {
    const { options, client } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    })

    return {
      loginOptions: options,
      client,
      errorMessage: formSubmitErrorsSelector(state),
      loginIn: formIsSubmitting(state),
      language: state.get('i18n').locale,
      email: formSelector(state, 'username') || '',
      initialValues: {
        username: auth.getLastLoggedInUser().username
      }
    }
  },
  {
    login: createSubmitFormAction(formName, LOGIN),
    iframeLogin: createSubmitFormAction(formName, IFRAME_LOGIN),
    onChangeLanguage: changeLanguage
  }
)(
  reduxForm({
    form: formName,
    destroyOnUnmount: false
  })(LoginFormContainer)
)
