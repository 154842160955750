import React, { Component, FormEvent } from 'react'
import TitleText from 'shared/components/TitleText'
import Input from 'shared/components/Input'
import { Field } from 'redux-form/immutable'
import Switch from 'shared/components/Switch'
import { withStyles } from '@material-ui/core/styles'

const CustomSwitch = withStyles({
  switchBase: {
    justifyContent: 'start'
  }
})(Switch)

type Props = {
  isClientConcierge: boolean
  isSupplierConcierge: boolean
  hideClientSearch: boolean
  onSearch: (event: FormEvent<HTMLFormElement>) => void
  onChangeSearchClients: () => void
  searchBuyers: boolean
  onChangeSearchExactDomain: () => void
}

class SearchBar extends Component<Props> {
  render() {
    const {
      onSearch,
      isClientConcierge,
      isSupplierConcierge,
      hideClientSearch,
      onChangeSearchClients,
      searchBuyers,
      onChangeSearchExactDomain
    } = this.props

    const title =
      isClientConcierge && isSupplierConcierge
        ? 'Find a Supplier/Client'
        : isClientConcierge
        ? 'Find a Client'
        : 'Find a Supplier'

    return (
      <form onSubmit={onSearch}>
        <TitleText className='tc mb4'>{title}</TitleText>
        {isClientConcierge && isSupplierConcierge && !hideClientSearch && (
          <div className='mv2'>
            <Field
              name='searchBuyers'
              component={CustomSwitch}
              color='primary'
              onChange={onChangeSearchClients}
              label='Search on Clients'
              labelRight
            />
          </div>
        )}
        {isClientConcierge && isSupplierConcierge && !searchBuyers && (
          <div className='mv2'>
            <Field
              name='searchExactDomain'
              component={CustomSwitch}
              color='primary'
              onChange={onChangeSearchExactDomain}
              label='Search by exact domain'
              labelRight
            />
          </div>
        )}
        <Field name='q' component={Input} />
      </form>
    )
  }
}

export default SearchBar
