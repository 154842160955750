import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Column } from 'react-virtualized'
import Table from 'shared/components/Table'
import InsightTitle from 'shared/components/InsightTitle'
import startCase from 'lodash.startcase'
import Button from 'shared/components/Button'
import exportData from 'shared/utils/exportData'
import Select from 'shared/components/Select'
import Text from 'shared/components/Text'
import Popover from '@material-ui/core/Popover'
import Label from 'shared/components/Label'
import { UserEngagement } from './InternalStats'
import { List } from 'immutable'

type Props = {
  stat: string
  userEngagement: List<UserEngagement>
}
const UserEngagementTable = ({ stat, userEngagement }: Props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [userEngageResults, setUserEngageResults] = useState<any>(undefined)
  const [statMeasured, setStatMeasured] = useState<string>('')

  const filterByStatMeasured = useMemo(() => {
    return userEngagement && !!userEngagement.size
      ? userEngagement
          .reduce<string[]>((results, currentItem) => {
            let statMeasured = currentItem.get('label')
            if (results.indexOf(statMeasured) < 0) {
              results.push(statMeasured)
            }
            return results
          }, [])
          .sort()
      : undefined
  }, [userEngagement])

  const visibleStatsMeasured = useMemo(() => {
    return userEngagement && !!userEngagement.size
      ? userEngagement
          .filter(stat => {
            if (!statMeasured) {
              return true
            }
            if (stat.get('label') && stat.get('label') === statMeasured) {
              return true
            }
            return false
          })
          .sort((item1, item2) => {
            return (
              item2.get('year') - item1.get('year') ||
              (item1.get('firstName') > item2.get('firstName') ? 1 : -1)
            )
          })
      : undefined
  }, [userEngagement, statMeasured])

  const onChangeStatMeasured = e => setStatMeasured(e.target.value)
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleResultsClick = (e, rowData) => {
    setUserEngageResults(rowData)
    setAnchorEl(e.currentTarget)
  }
  const renderUserDetails = rowData => {
    const { buyerRole, supplierRole, tealbotRole } = rowData.toJS()
    const roles = {
      buyer: buyerRole,
      supplier: supplierRole,
      tealbot: tealbotRole
    }
    return (
      <Text>
        {Object.entries(roles)
          .filter(v => v[1])
          .map(v => startCase(v[0]))
          .join(', ')}
      </Text>
    )
  }

  const renderResultsPopover = rowData => {
    return (
      <>
        <Button onClick={e => handleResultsClick(e, rowData)}>
          <FormattedMessage
            id='InternalStats.viewResults'
            defaultMessage='View Results'
          />
        </Button>
      </>
    )
  }

  return (
    <>
      {visibleStatsMeasured && (
        <div key={stat}>
          <div className='flex justify-between items-center mt4'>
            <InsightTitle>{startCase(stat)}</InsightTitle>
            <div className='flex items-end'>
              <Select
                fullWidth
                name='statMeasured'
                onChange={onChangeStatMeasured}
              >
                <option value=''>All Stats Measured</option>
                {filterByStatMeasured &&
                  filterByStatMeasured.map(stat => (
                    <option key={stat} value={stat}>
                      {startCase(stat)}
                    </option>
                  ))}
              </Select>
              <div className='ml2'>
                <Button
                  onClick={() => {
                    exportData.exportCSV(
                      visibleStatsMeasured
                        .toJS()
                        .map(
                          ({
                            months,
                            tealbotRole,
                            supplierRole,
                            buyerRole,
                            email,
                            firstName,
                            label,
                            lastName,
                            status,
                            total,
                            year
                          }) => ({
                            firstName,
                            lastName,
                            email,
                            status,
                            tealbotRole: tealbotRole.toString(),
                            supplierRole: supplierRole.toString(),
                            buyerRole: buyerRole.toString(),
                            label: startCase(label),
                            year,
                            total,
                            ...months.reduce((result, m) => {
                              result[Object.keys(m)[0]] = Object.values(m)[0]
                              return result
                            }, {})
                          })
                        ),
                      'Month to month User Engagement',
                      true
                    )
                  }}
                >
                  <FormattedMessage
                    id='InternalStats.Export'
                    defaultMessage='Export'
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className='bg-white br1 ba b--black-10'>
            <Table
              rowGetter={({ index }) =>
                !!visibleStatsMeasured.size && visibleStatsMeasured.get(index)
              }
              rowCount={visibleStatsMeasured.size}
              rowHeight={row => 70}
            >
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.firstName'
                    defaultMessage='First Name'
                  />
                }
                width={120}
                dataKey='firstName'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.lastName'
                    defaultMessage='Last Name'
                  />
                }
                width={150}
                dataKey='lastName'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.userStatus'
                    defaultMessage='Status'
                  />
                }
                width={100}
                dataKey='status'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.UserRoles'
                    defaultMessage='Roles'
                  />
                }
                width={150}
                dataKey='email'
                cellRenderer={({ rowData }) => renderUserDetails(rowData)}
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.StatsMeasured'
                    defaultMessage='Stats Measured'
                  />
                }
                width={150}
                dataKey='label'
                cellRenderer={({ cellData }) => startCase(cellData)}
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.YearMeasured'
                    defaultMessage='Year Measured'
                  />
                }
                width={100}
                dataKey='year'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.Results'
                    defaultMessage='Measured Results'
                  />
                }
                width={150}
                dataKey='total'
                cellRenderer={({ rowData }) => renderResultsPopover(rowData)}
              />
            </Table>
          </div>
        </div>
      )}
      {userEngageResults && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div className='pa3'>
            <Label noPadding>{startCase(userEngageResults.get('label'))}</Label>
            <Text>{`${userEngageResults.get(
              'total'
            )} for year ${userEngageResults.get('year')}`}</Text>
            <div className='bt b--gray mt2 pt2'>
              {userEngageResults.get('months').map(month => {
                const result = month && Object.entries(month.toJS())[0]
                return (
                  result && (
                    <div key={result[0]} className='flex justify-between'>
                      <Text>{`${result[0]}:`}</Text>
                      <Text>{`${result[1]}`}</Text>
                    </div>
                  )
                )
              })}
            </div>
          </div>
        </Popover>
      )}
    </>
  )
}

export default UserEngagementTable
