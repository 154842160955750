import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import parseMessage from '../../data/parseMessage'

const apiRoute = `${apiRoutes.authService}/message`

export const getMessagesByAncestorId = ancestorId => {
  return api.get(`${apiRoute}/list/${ancestorId}`).then(response => {
    return response
      .filter(message => message.active !== false)
      .map(message => parseMessage(message))
      .sort((messageA, messageB) => {
        return messageA.createdDate >= messageB.createdDate ? 1 : -1
      })
  })
}

export const postMessageToAncestor = (ancestorId, body) => {
  return api.post(`${apiRoute}/${ancestorId}`, { ...body }).then(parseMessage)
}

export const updateMessage = (messageId, body) => {
  return api
    .put(`${apiRoute}/${messageId}`, { ...body })
    .then(response => response)
}

export const deleteMessage = messageId => {
  return api.remove(`${apiRoute}/${messageId}`).then(response => response)
}
