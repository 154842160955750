const reverseParser = ({
  expirationDate,
  createdBy,
  createdDate,
  ...rest
}) => ({
  expiry: expirationDate,
  created: {
    user: createdBy,
    date: createdDate
  },
  ...rest
})

export default reverseParser
