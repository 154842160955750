import React, { useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RootState from 'shared/models/RootState'
import { AllocatedSpendState } from '../store/tier2Reducer'
import {
  loadTier2AllocatedSpend,
  updateTier2AllocatedSpend
} from '../store/actions'
import Loading from 'shared/components/Loading'
import ShareTier2 from '../components/ShareTier2'
import dataSelectors from 'shared/selectors/dataSelectors'
import { OverviewSpendItem } from './Report'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import { loadData } from 'shared/actions'
import { tier2DiverseSpendData } from '../store/loadTier2DiverseSpendData'

const ShareTier2Container = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadTier2AllocatedSpend())
  }, [dispatch])

  useEffect(() => {
    dispatch(loadData(tier2DiverseSpendData))
  }, [dispatch])

  const allocatedSpend = useSelector<RootState, AllocatedSpendState>(state =>
    state.getIn(['supplier', 'tier2', 'allocatedSpend'])
  )

  const isLoadingOverviewSpendData = useSelector<RootState, boolean>(state =>
    dataSelectors.isLoading(state, 'tier2/diverse')
  )
  const overviewSpendData = useSelector<RootState, OverviewSpendItem[]>(
    state => {
      const data = dataSelectors.getByEntity(state, 'tier2/diverse')
      return data.size > 0 ? data.toJS() : []
    }
  )

  const spendByQuarter = useMemo(
    () =>
      overviewSpendData.map(({ year, quarter, diverseSpend }) => ({
        year,
        quarter,
        totalDiverseSpend:
          Object.values(diverseSpend).reduce<number>(
            (agg, spendAmount) => (spendAmount ? spendAmount.spend + agg : agg),
            0
          ) || 0
      })),
    [overviewSpendData]
  )

  const handleShareAllocationSpend = useCallback(
    allocationSpend => dispatch(updateTier2AllocatedSpend(allocationSpend)),
    [dispatch]
  )

  if (allocatedSpend.isLoading || isLoadingOverviewSpendData) {
    return <Loading />
  }

  if (!spendByQuarter || !spendByQuarter.length) {
    return (
      <Text className='mt3'>
        <FormattedMessage
          id='ShareTier2Container.NoDataMessage'
          defaultMessage='You need to publish at least one quarter of spend to start sharing your spend.'
        />
      </Text>
    )
  }

  return (
    <ShareTier2
      allocatedSpend={allocatedSpend}
      spendByQuarter={spendByQuarter}
      onShareSpendAllocation={handleShareAllocationSpend}
    />
  )
}

export default ShareTier2Container
