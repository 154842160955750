import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'

export function* uploadFileToDataLoaderSaga(action) {
  const {
    updateType,
    attachedFile,
    fileName,
    body,
    overrideSupplier,
    uploadPath,
    useLocalServer,
    fromUtilities
  } = action.payload.toJS()

  const serverUrl = useLocalServer
    ? 'http://localhost:3001'
    : fromUtilities
    ? apiRoutes.authService
    : apiRoutes.loader

  const apiRoute = uploadPath
    ? `${serverUrl}/${uploadPath}`
    : `${serverUrl}/supplier/${updateType}`

  const requestBody = body ? JSON.parse(body) : {}

  if (attachedFile) {
    return yield call(api.postFile, apiRoute, {
      file: attachedFile,
      overrideSupplier,
      ...requestBody
    })
  } else if (fileName) {
    return yield call(
      api.post,
      `${apiRoute}File/${encodeURIComponent(fileName)}`,
      {
        overrideSupplier,
        ...requestBody
      }
    )
  }
}

export default createApiRequestSaga(uploadFileToDataLoaderSaga)
