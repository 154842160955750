import { all, takeLatest } from 'redux-saga/effects'
import {
  REGISTER,
  CREATE_PASSWORD,
  LOGIN,
  IFRAME_LOGIN,
  RESET_PASSWORD,
  REQUEST_TO_REGISTER,
  CHANGE_PASSWORD,
  SAVE_MOBILE_CONTACTS
} from '../actionTypes'
import registerSaga from './registerSaga'
import createPasswordSaga from './createPasswordSaga'
import loginSaga from './loginSaga'
import resetPasswordSaga from './resetPasswordSaga'
import requestToRegisterSaga from './requestToRegisterSaga'
import changePasswordSaga from './changePasswordSaga'
import contacts from 'shared/utils/api/contacts'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export default function* rootSaga() {
  yield all([
    takeLatest(REGISTER, registerSaga),
    takeLatest(CREATE_PASSWORD, createPasswordSaga),
    takeLatest([IFRAME_LOGIN, LOGIN], loginSaga),
    takeLatest(RESET_PASSWORD, resetPasswordSaga),
    takeLatest(REQUEST_TO_REGISTER, requestToRegisterSaga),
    takeLatest(CHANGE_PASSWORD, changePasswordSaga),
    takeLatest(
      SAVE_MOBILE_CONTACTS,
      createApiRequestSaga(contacts.uploadContacts, {
        usePayload: true
      })
    )
  ])
}
