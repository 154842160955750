import { call } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'
import {
  remapCertification,
  sortCertifications
} from 'shared/utils/data/parseSupplier'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* loadCompanySaga() {
  const companyInfo = yield call(supplier.get)
  const { supplier: supplierData, ...rest } = companyInfo || {}
  const { certification, ...restSupplier } = supplierData || {}
  const remappedCertification =
    certification &&
    certification.map(remapCertification).sort(sortCertifications)

  return yield {
    supplier: { certification: remappedCertification, ...restSupplier },
    ...rest
  }
}

export default createApiRequestSaga(loadCompanySaga)
