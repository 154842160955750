import { connect } from 'react-redux'
import Message from '../../components/Message'
import usersSelectors from 'shared/selectors/usersSelectors'
import RootState from 'shared/models/RootState'

type ContainerProps = {
  userId: string
}

export default connect((state: RootState, props: ContainerProps) => ({
  profilePictureUrl: usersSelectors
    .getById(state, props.userId)
    .get('profilePictureUrl'),
  firstName: usersSelectors.getById(state, props.userId).get('firstName'),
  lastName: usersSelectors.getById(state, props.userId).get('lastName')
}))(Message)
