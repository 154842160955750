import React, { Component } from 'react'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'

const CustomBadge = withStyles({
  badge: {
    top: -4,
    right: -6,
    minWidth: 8,
    height: 8,
    backgroundColor: '#e00b0b'
  }
})(Badge)

type Props = {
  src: string
  numberOfNotifications?: number
}

class NavIcon extends Component<Props> {
  render() {
    const { src, numberOfNotifications } = this.props

    return (
      <div className='v-mid' style={{ height: 26 }}>
        {numberOfNotifications && numberOfNotifications > 0 ? (
          <CustomBadge badgeContent={<span />}>
            <img src={src} alt='' />
          </CustomBadge>
        ) : (
          <img src={src} alt='filter' />
        )}
      </div>
    )
  }
}

export default NavIcon
