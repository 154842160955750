import { connect } from 'react-redux'
import IndexingPage from '../../components/IndexingPage'
import {
  dropIndex,
  reindexSuppliers,
  addUserMapping,
  reindexUsers,
  backupDatastore,
  flushCache,
  runPatch
} from '../../store/actions'
import apiRoutes from 'shared/routes/apiRoutes'

export default connect(state => ({ inProduction: apiRoutes.inProduction }), {
  onDropIndex: dropIndex,
  onReIndexSuppliers: reindexSuppliers,
  onReIndexUsers: reindexUsers,
  onAddUserMapping: addUserMapping,
  onBackupDatastore: backupDatastore,
  onFlushCache: flushCache,
  onRunPatch: runPatch
})(IndexingPage)
