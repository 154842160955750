import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import fileToUrl from '../../data/fileToUrl'

export const apiRoute = `${apiRoutes.loader}/job`

export const getJobs = () => {
  return api.get(`${apiRoute}/list`).then(response => {
    const list = response.map(job => job.id)
    const byId = response.reduce((result, job) => {
      result[job.id] = job
      return result
    }, {})
    return { list, byId }
  })
}

export const getJobById = (jobId: string) => {
  return api.get(`${apiRoute}/details/${jobId}`).then(response => {
    const { rows, ...rest } = Object.assign({}, response, { id: jobId })
    const newRows = rows.map(
      (
        {
          rowNum,
          logo,
          logoUrl,
          description,
          longDescription,
          links = {},
          ...rest
        },
        index
      ) => {
        const parsedLogoUrl = logoUrl || (logo && fileToUrl(logo))
        return {
          logoUrl: parsedLogoUrl,
          description,
          longDescription,
          rowNum: rowNum || index,
          ...links,
          ...rest
        }
      }
    )

    return {
      rows: newRows,
      ...rest
    }
  })
}

export const getJobResultsById = (jobId: string) => {
  return api.get(`${apiRoute}/results/${jobId}`).then(response => {
    return response?.map(
      ({ parents, expanded, siblings, ...restResponse }) => restResponse
    )
  })
}

export const createJobResult = ({ jobId, ignoreComplete = false, ...data }) => {
  return api
    .post(
      `${apiRoute}/result/${jobId}${ignoreComplete ? '/ignoreComplete' : ''}`,
      data
    )
    .then(({ expanded, parents, siblings, ...restResponse }) => {
      return restResponse
    })
}

export const updateJobResult = ({ jobResultId, ...data }) => {
  return api
    .put(`${apiRoute}/result/${jobResultId}`, data)
    .then(({ expanded, parents, siblings, ...restResponse }) => {
      return restResponse
    })
}

export const updateJobStatus = ({ jobId, status }) => {
  return api
    .put(`${apiRoute}/updateStatus/${jobId}/${status}`)
    .then(
      ({ rows, questions, expanded, parents, siblings, ...restResponse }) =>
        restResponse
    )
}

export const addJobRow = ({ jobId, row }) => {
  return api
    .put(`${apiRoute}/addJobRow/${jobId}`, row)
    .then(({ expanded, parents, siblings, ...restResponse }) => restResponse)
}

export const loadJob = ({ body }) => {
  return api
    .post(`${apiRoute}/newJob`, body)
    .then(
      ({ rows, questions, expanded, parents, siblings, ...restResponse }) =>
        restResponse
    )
}

export const reserveJob = (jobId: string) => {
  return api
    .put(`${apiRoute}/reserveJob/${jobId}`)
    .then(
      ({ rows, questions, expanded, parents, siblings, ...restResponse }) =>
        restResponse
    )
}

export const releaseJob = (jobId: string) => {
  return api
    .put(`${apiRoute}/releaseJob/${jobId}`)
    .then(
      ({ rows, questions, expanded, parents, siblings, ...restResponse }) =>
        restResponse
    )
}

export const removeJob = (jobId: string) => {
  return api.remove(`${apiRoute}/${jobId}`)
}

export const removeJobResult = (jobResultId: string) => {
  return api.remove(`${apiRoute}/result/${jobResultId}`)
}

export const updateMetadata = ({ jobId, ...metadata }) => {
  return api.put(`${apiRoute}/updateMetadata/${jobId}`, metadata)
}
