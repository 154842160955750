import React, { Component } from 'react'
import { connect } from 'react-redux'
import Page from 'shared/components/Page'
import Main from 'shared/components/Layout/Main'
import PageSection from 'shared/components/PageSection'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import PageAnswers from '../../components/PageAnswers'
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps } from 'react-router-dom'
import { List, RecordOf } from 'immutable'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import RootState from 'shared/models/RootState'

type Props = {
  communityForm: RecordOf<{
    pages: List<
      RecordOf<{
        id: string
        title: string
        questions: List<RecordOf<{ question: string }>>
      }>
    >
  }>
  isQuestionsLoaded: boolean
} & RouteComponentProps<{ membershipId: string }>

export class CommunityForm extends Component<Props> {
  render() {
    const { communityForm, history, isQuestionsLoaded } = this.props
    const { membershipId } = this.props.match.params

    return isQuestionsLoaded ? (
      <Page
        title={
          <FormattedMessage
            id='CommunityForm.SupplierProfile'
            defaultMessage='Supplier Profile'
          />
        }
      >
        <ScrollToPosition to={220} strict='up' />
        <Main>
          {communityForm &&
            communityForm.get('pages').map(page => {
              return (
                <PageSection key={page.get('id')} title={page.get('title')}>
                  <PageAnswers
                    pageQuestions={page.get('questions')}
                    membershipId={membershipId}
                  />
                </PageSection>
              )
            })}
          {membershipId !== 'form' && (
            <Button
              label={
                <FormattedMessage
                  id='CommunityForm.BackToSupplierProfile'
                  defaultMessage='Back to supplier profile'
                />
              }
              onClick={() => history.goBack()}
              className='mt4'
              autoSize
            />
          )}
        </Main>
      </Page>
    ) : (
      <Loading />
    )
  }
}
type ContainerProps = RouteComponentProps<{ communityId: string }>
export default connect((state: RootState, props: ContainerProps) => {
  const { communityId } = props.match.params
  const community = communitiesSelectors.getCommunityById(state, communityId)
  const communityForm = community && community.get('form')
  const firstQuestionId =
    communityForm &&
    communityForm.getIn(['pages', 0, 'questions', 0, 'question'])

  return {
    communityId,
    communityForm,
    isQuestionsLoaded: !!state.questions.byId[firstQuestionId]
  }
})(CommunityForm)
