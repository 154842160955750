import { call, select } from 'redux-saga/effects'
import routingSelectors from 'shared/selectors/routingSelectors'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'

export function* addInternalComment(action) {
  const pathname = yield select(routingSelectors.getPathname)
  const vetBuyerSupplierId = pathname.substr(
    pathname.indexOf('VetBuyerSupplier-')
  )
  const { newNote: note } = action.payload.toJS()
  const newNote = yield call(vets.addBuyerSupplierNotes, {
    vetBuyerSupplierId,
    note
  })

  return yield { vetBuyerSupplierId, newNote }
}

export default createApiRequestSaga(addInternalComment)
