import React, { FunctionComponent, useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import { List } from 'immutable'
import ListItem from 'shared/components/List/ListItem'
import Checkbox from 'shared/components/Checkbox'
import Scrollable from 'shared/components/Scrollable'
import Label from 'shared/components/Label'
import OrgLogo from 'shared/components/OrgLogo'
import { Field } from 'redux-form/immutable'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { InjectedFormProps } from 'redux-form'
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth'

type Props = {
  supplierId: string
  availableCommunities: List<string>
  open: boolean
  onCloseInviteDialog: () => void
  reset: () => void
  hasSelected: () => void
  inviteToCommunities: (params: Map<string, string>) => void
} & InjectedFormProps &
  WithWidthProps

export const InviteToCommunitiesDialog: FunctionComponent<Props> = props => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)

  const { open, submitSucceeded, onCloseInviteDialog } = props
  useEffect(() => {
    if (open && submitSucceeded) {
      setIsNotificationOpen(true)
      onCloseInviteDialog()
    }
  }, [onCloseInviteDialog, open, submitSucceeded])

  const renderCommunities = () => {
    const { availableCommunities } = props
    return availableCommunities.toJS().map((community, index) => {
      const secondaryText =
        community.membershipStatus &&
        (community.membershipStatus === 'Accepted' ? (
          <span className='teal'>
            <FormattedMessage
              id='InviteToCommunitiesDialog.InvitationAccepted'
              defaultMessage='Invitation accepted'
            />
          </span>
        ) : community.membershipStatus === 'Invited' ? (
          <span>
            <FormattedMessage
              id='InviteToCommunitiesDialog.InvitationAlreadySent'
              defaultMessage='Invitation already sent'
            />
          </span>
        ) : (
          <span>
            <FormattedMessage
              id='InviteToCommunitiesDialog.InvitationWasDeclined'
              defaultMessage='Invitation was declined'
            />
          </span>
        ))
      return (
        <div key={community.id} className='dt w-100 mb2'>
          <div className='pr2 dtc v-mid' style={{ width: '30px' }}>
            {!community.membershipStatus ? (
              <Field
                component={Checkbox}
                name={`communities[${community.id}]`}
                type='checkbox'
              />
            ) : (
              <Checkbox disabled checked />
            )}
          </div>
          <div
            className='dtc v-mid ph1'
            style={{ width: '64px', height: '64px' }}
          >
            <OrgLogo url={community.logoUrl} />
          </div>
          <div className='pl3 dtc v-mid'>
            <ListItem
              className='ma0'
              primaryText={community.name}
              secondaryText={secondaryText}
            />
          </div>
        </div>
      )
    })
  }

  const handleFormSubmit = (formValues: Map<string, string>) => {
    const { inviteToCommunities } = props

    inviteToCommunities(formValues)
  }

  const handleRequestClose = () => {
    const { reset, onCloseInviteDialog } = props
    onCloseInviteDialog()
    reset()
  }

  const handleNotificationClose = () => {
    setIsNotificationOpen(false)
  }

  const { hasSelected, width, handleSubmit } = props

  const matchMobile = width && ['xs', 'sm'].includes(width)

  return (
    <>
      <Dialog
        fullScreen={matchMobile}
        onClose={handleRequestClose}
        open={open}
        fullWidth
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <DialogTitle>
            <FormattedMessage
              id='InviteToCommunityDialog.InviteToCommunity'
              defaultMessage='Invite to community'
            />
          </DialogTitle>

          <DialogContent>
            <Label className='f7 pa0 mb3 dib fw6 mt4'>
              <FormattedMessage
                id='InviteToCommunitiesDialog.ChooseTheCommunity'
                defaultMessage='Choose the communities that you would like to invite this supplier to.'
              />
            </Label>

            {matchMobile ? (
              <Scrollable maxHeight={600}>{renderCommunities()}</Scrollable>
            ) : (
              <Scrollable>{renderCommunities()}</Scrollable>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              label={
                <FormattedMessage
                  id='InviteToCommunitiesDialog.Invite'
                  defaultMessage='Invite'
                />
              }
              autoSize
              type='submit'
              disabled={!hasSelected}
            />
            <Button
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleRequestClose}
              secondary
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>

      <Dialog onClose={handleNotificationClose} open={isNotificationOpen}>
        <DialogTitle>
          <FormattedMessage
            id='InviteToCommunitiesDialog.InviteToCommunity'
            defaultMessage='Invite to community'
          />
        </DialogTitle>
        <DialogContent className='mt4'>
          <FormattedMessage
            id='InviteToCommunitiesDialog.InvitationHasBeenSentAndTheSupplierWillBeNotified'
            defaultMessage='Your invitation has been sent and the supplier will be notified.'
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoSize
            label={
              <FormattedMessage
                id='InviteToCommunitiesDialog.Close'
                defaultMessage='Close'
              />
            }
            onClick={handleNotificationClose}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withWidth()(InviteToCommunitiesDialog)
