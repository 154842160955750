import { takeLatest, all } from 'redux-saga/effects'
import takeFirst from 'shared/utils/sagas/takeFirst'
import * as actionTypes from '../actionTypes'
import searchSupplierSaga from './searchSupplierSaga'
import updateQueryStringSaga from './updateQueryStringSaga'
import addTagToSupplierSaga from './addTagToSupplierSaga'
import removeTagFromSupplierSaga from './removeTagFromSupplierSaga'
import getMyCardBySupplierSaga from './getMyCardBySupplierSaga'
import submitSupplierReviewSaga from './submitSupplierReviewSaga'
import inviteSupplierSaga from './inviteSupplierSaga'
import getAllChildrenSaga from './getAllChildrenSaga'
import sendSearchFeedbackSaga from './sendSearchFeedbackSaga'
import loadSowJobSaga from './loadSowJobSaga'
import { GET_CARDS_BY_SUPPLIER_ID } from '../../shared/actionTypes'

export default function* rootSaga() {
  yield all([
    takeLatest(
      [actionTypes.SEARCH_SUPPLIER, actionTypes.SEARCH_LOCATION],
      searchSupplierSaga
    ),
    takeLatest(
      [
        actionTypes.TOGGLE_FILTER_OPTION,
        actionTypes.CHANGE_FILTER_OPTION,
        actionTypes.MERGE_FILTER_OPTION,
        actionTypes.REMOVE_FILTER_OPTION,
        actionTypes.ADD_FILTER_OPTION,
        actionTypes.UPDATE_QUERY_STRING,
        actionTypes.SET_TAB_FILTER
      ],
      updateQueryStringSaga
    ),
    takeFirst(actionTypes.ADD_TAG_TO_SUPPLIER, addTagToSupplierSaga),
    takeLatest(actionTypes.REMOVE_TAG_FROM_SUPPLIER, removeTagFromSupplierSaga),
    takeLatest(
      [actionTypes.UPDATE_SUPPLIER_REVIEW, GET_CARDS_BY_SUPPLIER_ID],
      getMyCardBySupplierSaga
    ),
    takeLatest(actionTypes.SUBMIT_SUPPLIER_REVIEW, submitSupplierReviewSaga),
    takeLatest(actionTypes.INVITE_SUPPLIER, inviteSupplierSaga),
    takeFirst(actionTypes.GET_ORG_CHILDREN, getAllChildrenSaga),
    takeFirst(actionTypes.SEND_SEARCH_FEEDBACK, sendSearchFeedbackSaga),
    takeFirst(actionTypes.LOAD_SOW_JOB, loadSowJobSaga)
  ])
}
