import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadData, updateData } from 'shared/actions'
import ForbiddenDomains from '../../components/ForbiddenDomains'
import RootState from 'shared/models/RootState'
import { List } from 'immutable'

const entity = 'forbiddenDomains'
const endpoint = 'suppliers/forbidden/domains'

type Props = {
  domains: List<string>
  loadData: typeof loadData
  updateData: typeof updateData
}
class ForbiddenDomainsContainer extends Component<Props> {
  componentDidMount() {
    this.props.loadData({
      entity,
      query: endpoint,
      resultParser: data => data.domains
    })
  }

  handleAddDomain = domain => {
    this.props.updateData({
      entity,
      endpoint,
      record: {
        domains: [domain]
      },
      resultParser: () => this.props.domains.push(domain)
    })
  }

  render() {
    return (
      <ForbiddenDomains onAddDomain={this.handleAddDomain} {...this.props} />
    )
  }
}

export default connect(
  (state: RootState) => ({
    domains: state.getIn(['data', 'forbiddenDomains'])
  }),
  {
    loadData,
    updateData
  }
)(ForbiddenDomainsContainer)
