import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import AutoSaveInput, {
  NormalizeFunction
} from 'shared/components/AutoSaveInput'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import companySelectors from '../../selectors/companySelectors'
import { saveCompanyInfo } from '../../../Company/actions'

type Props = {
  address: string
  saveCompanyInfo: () => void
}

const normalize: NormalizeFunction<{
  locations: Array<{
    address: string
  }>
}> = v => ({ locations: [{ address: v }] })

export class AddressForm extends Component<Props> {
  render() {
    const { saveCompanyInfo, address } = this.props

    return (
      <Fragment>
        <Label>Address</Label>
        <AutoSaveInput
          name='supplier'
          value={address}
          normalize={normalize}
          component={Input}
          onSave={saveCompanyInfo}
        />
      </Fragment>
    )
  }
}

export default connect(
  state => {
    const locations = companySelectors.getCompanySupplierField(
      state,
      'locations'
    )
    return {
      address: locations.getIn([0, 'address'])
    }
  },
  { saveCompanyInfo }
)(AddressForm)
