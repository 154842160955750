import { call, select } from 'redux-saga/effects'
import profileSelectors from '../../selectors/profileSelectors'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import updateCardSaga from '../updateCardSaga'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import camelCase from 'lodash.camelcase'
import Immutable from 'immutable'

export function* removePersonalRelationshipSaga(action) {
  const { fieldName, tagToRemove, supplierId } = Immutable.Iterable.isIterable(
    action.payload
  )
    ? action.payload.toJS()
    : action.payload

  if (tagToRemove) {
    // get current supplier and corresponding card
    const currentSupplierId = supplierId
      ? supplierId
      : yield select(profileSelectors.getDataField, 'id')
    const currentSupplierCard = yield select(
      cardsSelectors.getBySupplier,
      currentSupplierId
    )
    const cardTags = currentSupplierCard.get(fieldName)
    const newCards = cardTags.set(camelCase(tagToRemove), '')

    return yield call(updateCardSaga, {
      supplierId: currentSupplierId,
      cardId: currentSupplierCard.get('id'),
      cardChanges: {
        [fieldName]: newCards.toJS()
      }
    })
  }
}

export default createApiRequestSaga(removePersonalRelationshipSaga)
