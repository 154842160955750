import { List, Record, RecordOf, fromJS } from 'immutable'
import * as actionTypes from '../actionTypes'
import { requestSuccess, requestFailure } from 'shared/actionTypes'
import UploadedFile from 'shared/models/UploadedFile'

export type Contact = {
  userId: string
  firstName: string
  lastName: string
  status:
    | 'Registered'
    | 'Normal'
    | 'Expired'
    | 'Blocked'
    | 'Inactive'
    | 'No e-mail'
    | 'Blocked failed login'
  sustainabilityContact?: boolean
  email: string
  title?: string
  profilePicture?: UploadedFile
  myCompany?: boolean
}

export type BulkSupplier = {
  supplier: {
    orgUnitId: string
    name: string
  }
  contacts: Array<Contact>
  ytdSpend?: number
}

export const BulkSupplierRecord = Record<BulkSupplier>({
  supplier: {
    orgUnitId: '',
    name: ''
  },
  contacts: [],
  ytdSpend: undefined
})

export type BulkActions = {
  loading: boolean
  suppliers?: List<RecordOf<BulkSupplier>>
}

const defaultState: RecordOf<BulkActions> = fromJS({
  loading: false,
  suppliers: List([])
})

const bulkActionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_BULK_ESG_SUPPLIERS:
      return state.set('loading', true)

    case requestFailure(actionTypes.GET_BULK_ESG_SUPPLIERS):
      return state.set('loading', false)

    case requestSuccess(actionTypes.GET_BULK_ESG_SUPPLIERS):
      return state
        .set('loading', false)
        .set(
          'suppliers',
          List(action.payload.map(item => new BulkSupplierRecord(item)))
        )

    default:
      return state
  }
}

export default bulkActionsReducer
