import React, { useState } from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes/paths'
import { FormattedMessage } from 'react-intl'
import CloseIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import { CSSTransition } from 'react-transition-group'
import Hidden from '@material-ui/core/Hidden'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import chatbot from 'shared/utils/chatbot'
import storageManager from 'shared/utils/storageManager'
import TealbotHelpIcon from 'shared/components/TealbotHelpIcon'

const InsightTier2NavBar = () => {
  const [doNotShow, setDoNotShow] = useState<boolean>(
    !!storageManager.getItem('doNotShowTier2BuyerHelp')
  )

  const handleDoNotShow = () => {
    storageManager.setItem('doNotShowTier2BuyerHelp', true)
    setDoNotShow(true)
  }

  const handleShow = () => {
    storageManager.setItem('doNotShowTier2BuyerHelp', false)
    setDoNotShow(false)
  }

  return (
    <>
      <CSSTransition
        in={!doNotShow}
        timeout={300}
        classNames='tealbotHelp'
        unmountOnExit
      >
        <div className='bg-white br1 ba b--black-10 mt3-5 mb0 flex'>
          <div className='pa3-5 flex-auto'>
            <div className='lh-copy f7 fw4 mid-gray'>
              <Text>
                <FormattedMessage
                  id='InsightTier2NavBar.Tier2Help1'
                  defaultMessage='TealBook can securely automate your Tier 2 Diversity Reporting process, allowing your suppliers to upload, allocate and share their diversity spend with your organization.'
                />
              </Text>
              <Label>
                <FormattedMessage
                  id='InsightTier2NavBar.Tier2Help2'
                  defaultMessage='To build your Tier 2 Diversity Report:'
                />
              </Label>
              <ol>
                <li>
                  <FormattedMessage
                    id='InsightTier2NavBar.Tier2Help3'
                    defaultMessage='Enable Tier 2 reporting using the toggle on the Tier 2 insight page.'
                  />
                </li>
                <li>
                  <FormattedMessage
                    id='InsightTier2NavBar.Tier2Help4'
                    defaultMessage='Invite suppliers to participate. They will receive an email with a link they can follow.'
                  />
                </li>
                <li>
                  <FormattedMessage
                    id='InsightTier2NavBar.Tier2Help5'
                    defaultMessage='Reach out to your invited suppliers directly to encourage them, you can follow their progress to see when they accept, start loading and share spend for each quarter.'
                  />
                  <Text className='mv2'>
                    <span className={'red mr1'}>
                      <FormattedMessage
                        id='InsightTier2NavBar.Tier2Help6'
                        defaultMessage='PRIVACY NOTE:'
                      />
                    </span>
                    <FormattedMessage
                      id='InsightTier2NavBar.Tier2Help7'
                      defaultMessage='We only share a summarized version of the spend with you. It will contain no vendor details but those can be made available with the supplier’s permission in the case of an audit.'
                    />
                  </Text>
                </li>
              </ol>
              <Text className='mb2'>
                <FormattedMessage
                  id='InsightTier2NavBar.Tier2Help8'
                  defaultMessage='As your suppliers share spend for each quarter, you can review it on the Tier 2 report page and export the results to share with your team.'
                />
              </Text>
              <Text>
                <FormattedMessage
                  id='InsightTier2NavBar.Tier2Hel9'
                  defaultMessage='We are doing our best to make this process as simple for you as possible. If you need help, or have any questions, please {contactSupport}!'
                  values={{
                    contactSupport: (
                      <span className='teal pointer dim' onClick={chatbot.open}>
                        <FormattedMessage
                          id='InsightTier2NavBar.ContactUs'
                          defaultMessage={`Contact us`}
                        />
                      </span>
                    )
                  }}
                />
              </Text>
            </div>
          </div>
          <Hidden smDown>
            <div className='ml3-ns w4 self-center'>
              <img src={TealbotCircleImage} alt='tealbot-tip' />
            </div>
          </Hidden>
          <div className='pr2 pt2'>
            <IconButton
              onClick={handleDoNotShow}
              style={{
                width: 32,
                height: 32
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </CSSTransition>

      <div className='flex items-center'>
        <NavBar>
          <NavLink
            label={
              <FormattedMessage
                id='InsightTier2NavBar.SupplierList'
                defaultMessage='Supplier List'
              />
            }
            to={paths.supplierListTier2}
          />
          <NavLink
            label={
              <FormattedMessage
                id='InsightTier2NavBar.Spend Overview'
                defaultMessage='Spend Overview'
              />
            }
            to={paths.spendOverviewTier2}
          />
        </NavBar>

        {doNotShow && (
          <CSSTransition
            in={doNotShow}
            timeout={100}
            classNames='tealbotHelp'
            unmountOnExit
          >
            <TealbotHelpIcon
              show={doNotShow}
              imageClassName='dim pointer w2 ma0'
              onClick={handleShow}
            />
          </CSSTransition>
        )}
      </div>
    </>
  )
}

export default InsightTier2NavBar
