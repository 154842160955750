import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import QuestionContainer from 'shared/containers/QuestionContainer'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import { answerQuestion } from '../../actions'

class PageQuestions extends Component {
  render() {
    const {
      questions,
      answerQuestion,
      membershipId,
      responses,
      communityId
    } = this.props

    return (
      <div>
        {questions &&
          questions.size > 0 &&
          questions.map(q => (
            <QuestionContainer
              key={q.get('question')}
              questionId={q.get('question')}
              required={q.get('required')}
              response={
                responses && responses.getIn([q.get('question'), 'response'])
              }
              membershipId={membershipId}
              communityId={communityId}
              onSave={changes =>
                answerQuestion({
                  membershipId,
                  questionId: q.get('question'),
                  response: changes[q.get('question')]
                })
              }
            />
          ))}
      </div>
    )
  }
}

export default withRouter(
  connect(
    (state, props) => {
      const communityId = props.match.params.communityId
      const pageNum = props.match.params.pageNum || 1
      const membership = communitiesSelectors.getMembershipByCommunityId(
        state,
        communityId
      )

      return {
        responses: membership.getIn(['response', 'responses']),
        membershipId: membership.get('id'),
        questions: communitiesSelectors
          .getFormPage(state, communityId, pageNum)
          .get('questions'),
        communityId
      }
    },
    {
      answerQuestion
    }
  )(PageQuestions)
)
