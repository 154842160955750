import { call } from 'redux-saga/effects'
import { getVets } from 'shared/utils/api/supplier/supplier'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* loadVetsSaga() {
  const response = yield call(getVets)
  const filteredVets = response.filter(function(item) {
    return item.status !== 'Suggested' && item.status !== 'suggested'
  }) // for some reason some vets have status 'suggested' instead of 'Suggested'
  return yield filteredVets
}

export default createApiRequestSaga(loadVetsSaga)
