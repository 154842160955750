import { call, select } from 'redux-saga/effects'
import tier2 from 'shared/utils/api/tier2'
import insight from 'shared/utils/api/insight'

import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import RootState from 'shared/models/RootState'

function* loadTier2DataSaga(action) {
  const baseRules = yield select((state: RootState) =>
    state.getIn([
      'buyer',
      'diversityReport',
      'diverseQualificationRules',
      'baseRules'
    ])
  )
  const subCategories = baseRules && baseRules.get('subCategories').toJS()
  const certAgencies = baseRules && baseRules.get('certAgencies').toJS()
  const countries = baseRules && baseRules.get('countries').toJS()

  const key = `${action.payload.startYear}${action.payload.startQuarter}${action.payload.endYear}${action.payload.endQuarter}`

  const buyerTotalSpend = yield call(insight.getTotalSpend, action.payload)

  const data = yield call(tier2.loadTier2Data, {
    subCategories,
    certAgencies,
    countries,
    ...action.payload
  })
  return yield {
    key,
    ...buyerTotalSpend,
    data: data.overview,
    uniqueTotalTier2Spend: data.uniqueTotalTier2Spend
  }
}

export default createApiRequestSaga(loadTier2DataSaga)
