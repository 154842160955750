import React, { Component } from 'react'
import { List } from 'immutable'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import parsePath from 'shared/utils/parsePath'
import paths from '../../routes/paths'
import VetSupplierListContainer from '../../containers/VetSupplierListContainer'
import ResponseMessageContainer from '../../containers/ResponseMessageContainer'
import { FormattedMessage } from 'react-intl'
import ScrollToPosition from 'shared/components/ScrollToPosition'

type Props = {
  conversationCount: number
  vetId: string
  push: (arg: string) => void
  redirectFrom: string
  vetBuyerSuppliers: List<string>
  vetBuyerSupplierId: string
  loadComments: (params: { ancestorId: string; messageKind: string }) => void
}

type State = {
  vetBuyerSuppliers: List<string>
}

class VetInboxPage extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      vetBuyerSuppliers: List([])
    }
  }

  filterVetBuyerSuppliers = (data, filter) => {
    this.setState({
      vetBuyerSuppliers: data
        .filter(vbs => {
          const status = vbs.getIn(['vetBS', 'collaboration', 'status'])
          if (filter === 'All') {
            return true
          } else if (
            filter === 'Invited' &&
            (status === 'Invited' ||
              status === 'Opened' ||
              status === 'Interested' ||
              status === 'Meet Criteria')
          ) {
            return true
          } else if (
            filter === 'Responded' &&
            (status === 'Submitted' ||
              status === 'Accepted' ||
              status === 'Declined' ||
              status === 'Chosen' ||
              status === 'Not Chosen')
          ) {
            return true
          } else if (filter === 'Chosen' && status === 'Chosen') {
            return true
          } else if (
            filter === 'Suggested' &&
            (status === 'Suggested' || status === 'Approved')
          ) {
            return true
          } else {
            return false
          }
        })
        .map(vbs => vbs.getIn(['vetBS', 'id']))
    })
  }

  componentDidMount() {
    this.props.loadComments({
      ancestorId: this.props.vetId,
      messageKind: 'VetComment'
    })
  }

  handleVetBuyerSupplierClick = (vetBuyerSupplierId: string) => {
    const { push, vetId, redirectFrom } = this.props
    push(
      `${parsePath(paths.vetInbox, { vetId, vetBuyerSupplierId })}${
        redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
      }`
    )
  }

  render() {
    const { conversationCount, vetBuyerSupplierId } = this.props
    const { vetBuyerSuppliers } = this.state

    if (conversationCount === 0) {
      return (
        <div className='tc ma3'>
          <h6 className='f7 mid-gray fw6 dib pb0 ma0'>
            <FormattedMessage
              id='VetInboxPage.NoMessage'
              defaultMessage='No Messages'
            />
          </h6>
        </div>
      )
    }

    return (
      <Page
        title={
          <FormattedMessage id='VetInboxPage.Inbox' defaultMessage='Inbox' />
        }
      >
        <ScrollToPosition />
        <Main>
          <PageSection
            title={
              <FormattedMessage
                id='VetInboxPage.RFIResponse'
                defaultMessage='RFI Response'
              />
            }
            noPadding
          >
            <ResponseMessageContainer
              selectedVetBSId={
                vetBuyerSupplierId ||
                (vetBuyerSuppliers.size > 0 ? vetBuyerSuppliers.first() : '')
              }
            />
          </PageSection>
        </Main>
        <Aside>
          <div className='mt4-ns'>
            <VetSupplierListContainer
              selectedVetBSId={
                vetBuyerSupplierId ||
                (vetBuyerSuppliers.size > 0
                  ? vetBuyerSuppliers.first()
                  : undefined)
              }
              onClick={this.handleVetBuyerSupplierClick}
            />
          </div>
        </Aside>
      </Page>
    )
  }
}

export default VetInboxPage
