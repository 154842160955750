import React, { useMemo, useState } from 'react'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import { useSelector, useDispatch } from 'react-redux'
import jobsSelectors from '../../selectors/jobsSelectors'
import { selectOrgUnit } from '../../actions'
import RootState from 'shared/models/RootState'
import Divider from 'shared/components/Divider'
import SowMain from './SowMain'
import SowSide from './SowSide'
import SowHeader from './SowHeader'
import SowLookup from './SowLookup'
import { useParams } from 'react-router-dom'
import Text from 'shared/components/Text'
import Modal from '@material-ui/core/Modal'
import Loading from 'shared/components/Loading'

const JobSow = () => {
  const dispatch = useDispatch()
  const params = useParams<{
    jobId: string
  }>()
  const jobId = params.jobId

  const orgs = useSelector(jobsSelectors.getSowAcceptList)

  const currentOrgUnitId = useSelector(
    (state: RootState) =>
      state.getIn(['jobs', 'currentOrgUnitId']) ||
      (orgs.length > 0 && orgs[0].orgUnitId)
  )
  const loading = useSelector((state: RootState) =>
    state.getIn(['jobs', 'loadingSowJob'])
  )
  const job = useSelector((state: RootState) =>
    jobsSelectors.getJobById(state, jobId)
  )

  const [isEditing, setIsEditing] = useState<{ [section: string]: boolean }>({})

  const disabledSortable = useMemo(() => {
    return Object.values(isEditing).some(editing => !!editing)
  }, [isEditing])

  const handleOrgClick = (orgUnitId: string) => {
    dispatch(selectOrgUnit(orgUnitId))
  }

  if (job?.get('status') === 'complete') {
    return (
      <div className='bg-white ba br b--black-10 pa2 pa3-ns mt3-5'>
        <Text>This task has been completed.</Text>
      </div>
    )
  }

  return (
    <>
      <div className='pv3'>
        <SowHeader jobId={jobId} />
      </div>
      <div className='cf bg-white br1 ba b--black-10'>
        <Aside className='br-l b--black-10' left style={{ minHeight: 1000 }}>
          <SowLookup jobId={jobId} />
          <Divider className='nr4-l' />
          <SowSide
            jobId={jobId}
            onClick={handleOrgClick}
            disabled={disabledSortable}
          />
        </Aside>
        <Main>
          {orgs && (
            <SowMain
              orgUnitId={currentOrgUnitId}
              jobId={jobId}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          )}
        </Main>
      </div>
      <Modal open={loading}>
        <div className='pv6'>
          <Loading />
        </div>
      </Modal>
    </>
  )
}

export default JobSow
