import React, { Component } from 'react'
import Link from 'shared/components/Link'
import OrgLogo from 'shared/components/OrgLogo'

const styles = {
  wrapper: 'dt-ns w-100 ba b--light-gray br3 pa3 tl',
  logoWrapper: 'dtc-ns w4 w3-ns v-mid-ns pl3 pl0-ns pt2 pt0-ns pb3 pb0-ns',
  rightWrapper: 'dtc v-top pl3',
  name: 'fw6',
  description: 'pv2 f6'
}

type Props = {
  name: string
  description: string
  logoUrl: string
  profileUrl?: string
}

class SupplierMessageSummary extends Component<Props> {
  withLink(node) {
    const { profileUrl } = this.props

    return profileUrl ? <Link to={profileUrl}>{node}</Link> : node
  }

  render() {
    const { name, description, logoUrl } = this.props

    return (
      <div className={styles.wrapper}>
        <div className={styles.logoWrapper}>
          {this.withLink(<OrgLogo name={name} url={logoUrl} />)}
        </div>
        <div className={styles.rightWrapper}>
          {this.withLink(
            <div className='dib'>
              <div className={styles.name}>{name}</div>
              <div className={styles.description}>{description}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default SupplierMessageSummary
