import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select } from 'redux-saga/effects'
import users from 'shared/utils/api/users'
import userMetricsSelectors from 'buyer/shared/selectors/userMetricsSelectors'

export function* loadUserMetricsSaga(action) {
  const metrics = yield select(userMetricsSelectors.getUserMetrics)

  if (action.payload !== metrics.get('userId')) {
    return yield call(users.getMetricsById, action.payload)
  } else {
    return false
  }
}

export default createApiRequestSaga(loadUserMetricsSaga)
