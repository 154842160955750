import React, { useState, ChangeEvent, FormEvent, ReactElement } from 'react'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Scrollable from 'shared/components/Scrollable'
import { FormattedMessage } from 'react-intl'
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/BorderColor'
import camelCase from 'lodash.camelcase'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import Divider from 'shared/components/Divider'

type AttributeListType = {
  key: string
  label: string
  order?: number
}

const DragHandle = SortableHandle(() => (
  <div className='dib dim w2' style={{ cursor: 'ns-resize' }}>
    <DragHandleIcon />
  </div>
))

type SortableItemProps = {
  value: string
  isEdit?: boolean
  readonly?: boolean
  onUpdate: (value: string) => void
  onEdit: () => void
  onRemove: () => void
  onCancel: () => void
}
const SortableItem = SortableElement(
  ({
    value,
    onEdit,
    onRemove,
    onUpdate,
    onCancel,
    isEdit,
    readonly
  }: SortableItemProps) => {
    const [newValue, setNewValue] = useState<string>(value)
    return (
      <form
        onSubmit={(e: FormEvent) => {
          e.preventDefault()
          onUpdate(newValue)
        }}
      >
        {isEdit ? (
          <div className='bg-white br1 ba b--black-10 f7 pv1 ph2 flex items-center justify-between'>
            <Input
              wrapperClassName='flex-auto'
              value={newValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setNewValue(e.currentTarget.value)
              }
            />
            <Button
              autoSize
              size='small'
              type='submit'
              disabled={!newValue}
              className='ml2'
            >
              <FormattedMessage id='Update' defaultMessage='Update' />
            </Button>
            <Button
              autoSize
              secondary
              size='small'
              onClick={onCancel}
              className='ml2'
            >
              <FormattedMessage id='Cancel' defaultMessage='Cancel' />
            </Button>
          </div>
        ) : (
          <div className='bg-white br1 ba b--black-10 f7 pa2 flex items-center'>
            {!readonly ? (
              <DragHandle />
            ) : (
              <div className='dib w2'>
                <DragHandleIcon color='disabled' />
              </div>
            )}
            <span className='flex-auto'>{value}</span>
            {!readonly && (
              <>
                <EditIcon
                  fontSize='small'
                  onClick={onEdit}
                  className='pointer dim mr2'
                />
                <CloseIcon onClick={onRemove} className='pointer dim' />
              </>
            )}
          </div>
        )}
      </form>
    )
  }
)

type SortableListProps = {
  items: Array<AttributeListType>
  editingIndex: number | undefined
  onCancel: () => void
  onEdit: (index: number) => void
  onRemove: (index: number) => void
  onUpdate: (index: number, newValue: string) => void
  isReadonly?: boolean
}
const SortableList = SortableContainer(
  ({
    items,
    editingIndex,
    onEdit,
    onRemove,
    onUpdate,
    onCancel,
    isReadonly
  }: SortableListProps) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.key}`}
            index={index}
            value={value.label}
            readonly={
              isReadonly ||
              (editingIndex !== undefined && editingIndex !== index)
            }
            isEdit={editingIndex === index}
            onCancel={onCancel}
            onEdit={() => onEdit(index)}
            onRemove={() => onRemove(index)}
            onUpdate={value => {
              onUpdate(index, value)
            }}
          />
        ))}
      </div>
    )
  }
)

type Props = {
  fieldName: string
  value: string
  placeholder?: string | ReactElement<FormattedMessage>
  onUpdate: (value) => void
  isReadonly?: boolean
}

const AttributesJsonEditor = (props: Props) => {
  const [newAttribute, setNewAttribute] = useState<string>('')
  const [value, setValue] = useState<Array<AttributeListType> | undefined>(
    () => {
      try {
        const valueJson = JSON.parse(props.value)
        return (
          valueJson &&
          Object.keys(valueJson)
            .reduce((reduction: Array<any>, key) => {
              reduction.push({ key, ...valueJson[key] })
              return reduction
            }, [])
            .sort((a1, a2) => {
              return a1.order - a2.order
            })
        )
      } catch (e) {
        console.error(e)
      }
    }
  )
  const [removingIndex, setRemovingIndex] = useState<number | undefined>(
    undefined
  )
  const [editingIndex, setEditingIndex] = useState<number | undefined>(
    undefined
  )

  const parseValueForUpdate = (value: Array<AttributeListType>) => {
    return {
      [props.fieldName]: value?.reduce((result, attr, index) => {
        result[attr.key] = { label: attr.label, order: (index + 1) * 10 }
        return result
      }, {})
    }
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const key = camelCase(newAttribute)
    const newValue = value
      ? [...value, { key, label: newAttribute }]
      : [{ key, label: newAttribute }]

    setValue(newValue)
    props.onUpdate(parseValueForUpdate(newValue))
    setNewAttribute('')
  }

  const handleEditClick = (index: number) => {
    setEditingIndex(index)
  }

  const handleRemoveClick = (index: number) => {
    setRemovingIndex(index)
  }

  const removeAttribute = () => {
    if (removingIndex !== undefined && value) {
      value.splice(removingIndex, 1)
      const newValue = [...value]
      setValue(newValue)
      props.onUpdate(parseValueForUpdate(newValue))
    }
    setRemovingIndex(undefined)
  }

  const handleUpdateClick = (index: number, newLabel: string) => {
    if (editingIndex !== undefined && value) {
      value[editingIndex]['label'] = newLabel
      const newValue = [...value]
      setValue(newValue)
      props.onUpdate(parseValueForUpdate(newValue))
    }
    setEditingIndex(undefined)
  }

  const handleCancelClick = () => {
    setEditingIndex(undefined)
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return
    }

    const newValue = [...(value as Array<AttributeListType>)]
    const startIndex = oldIndex < 0 ? newValue.length + oldIndex : oldIndex

    if (startIndex >= 0 && startIndex < newValue.length) {
      const endIndex = newIndex < 0 ? newValue.length + newIndex : newIndex

      const [item] = newValue.splice(oldIndex, 1)
      newValue.splice(endIndex, 0, item)
    }

    setValue(newValue)
    props.onUpdate(parseValueForUpdate(newValue))
  }

  return (
    <>
      {!props.isReadonly && (
        <>
          <form onSubmit={handleSubmit}>
            <div className='flex items-center justify-between mt1'>
              <Input
                wrapperClassName='flex-auto'
                value={newAttribute}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewAttribute(e.currentTarget.value)
                }
                placeholder={props.placeholder}
              />
              <Button
                autoSize
                type='submit'
                className='ml2'
                disabled={!newAttribute || editingIndex !== undefined}
              >
                <FormattedMessage id='Add' defaultMessage='Add' />
              </Button>
            </div>
          </form>
          <Divider className='mv2' />
        </>
      )}
      <div className={'ba b--light-gray'} style={{ height: 250 }}>
        <Scrollable maxHeight={250}>
          {value && (
            <SortableList
              items={value}
              onSortEnd={handleSortEnd}
              useDragHandle
              editingIndex={editingIndex}
              onEdit={handleEditClick}
              onRemove={handleRemoveClick}
              onUpdate={handleUpdateClick}
              onCancel={handleCancelClick}
              isReadonly={props.isReadonly}
            />
          )}
        </Scrollable>
      </div>
      <ConfirmationDialog
        open={removingIndex !== undefined}
        onClose={() => setRemovingIndex(undefined)}
        onConfirm={removeAttribute}
      >
        <Text>
          <FormattedMessage
            id='AttributeListType'
            defaultMessage='Are you sure you want to remove this?'
          />
        </Text>
      </ConfirmationDialog>
    </>
  )
}

export default AttributesJsonEditor
