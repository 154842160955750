import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector } from 'react-redux'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import GlowingTealbot from './tealbot-glowing.gif'

type Props = {
  size?: number
  className?: string
}

const Loading = (props: Props) => {
  const user = useSelector(sessionSelectors.getUser)
  const { size = 50, className } = props

  return (
    <div role='status' aria-busy='true' className={className || 'tc mt4'}>
      {user?.get('happy') ? (
        <div
          style={{
            position: 'relative',
            width: 280,
            margin: 'auto'
          }}
        >
          <img
            alt='loading'
            src={GlowingTealbot}
            style={{
              width: 100,
              height: 100
            }}
          />
        </div>
      ) : (
        <CircularProgress
          size={size}
          color='primary'
          style={{
            position: 'relative',
            display: 'inline-block',
            textAlign: 'center',
            marginTop: 25,
            marginBottom: 25
          }}
        />
      )}
    </div>
  )
}

export default Loading
