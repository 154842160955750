import { call, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import supplier from 'shared/utils/api/supplier'
import { loadSupplierInsight } from '../../actions'

export function* processPaymentSaga(action) {
  const response = yield call(supplier.makePayment, action.payload)

  if (response === true) {
    yield put(loadSupplierInsight())
  }

  return yield response
}

export default createApiRequestSaga(processPaymentSaga)
