import React, { useState, useMemo, memo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Loading from 'shared/components/Loading'
import LookupSuppliers from 'shared/components/LookupSuppliers'
import { RecordOf, List } from 'immutable'
import Tier2InviteSupplierTable, {
  Tier2SuppliersProps
} from '../Tier2InviteSupplierTable'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import { inviteTier2Group } from '../../store/actions'
import { useDispatch } from 'react-redux'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import { exportTier2SupplierTable } from '../../store/actions'

const messages = defineMessages({
  highest: {
    id: 'Tier2InviteSupplier.GroupHighest',
    defaultMessage: 'Highest Spend Suppliers'
  },
  high: {
    id: 'Tier2InviteSupplier.GroupHigh',
    defaultMessage: 'High to Highest Spend Suppliers'
  },
  medium: {
    id: 'Tier2InviteSupplier.GroupMedium',
    defaultMessage: 'Medium to Highest Spend Suppliers'
  },
  invite: {
    id: 'Tier2InviteSupplier.Invite',
    defaultMessage: 'Invite'
  }
})

type Props = {
  tier2Suppliers?: Tier2SuppliersProps
  isLoading: boolean
  loadingSuggestions: boolean
  suggestedSuppliers?: List<
    RecordOf<{ id: string; logo: string; name: string; domains: List<string> }>
  >
  suggestQuery: string
  onClose: () => void
  onHandleClickSuggestedOrgUnit: (
    orgUnit: RecordOf<{
      id: string
      logo: string
      name: string
      domains: List<string>
    }>
  ) => void
  open: boolean
  onChangeCompanyName: (companyName: string) => void
  companyName: string
}
const Tier2InviteSupplier = memo(
  ({
    tier2Suppliers,
    isLoading,
    loadingSuggestions,
    suggestedSuppliers,
    suggestQuery,
    open,
    onChangeCompanyName,
    companyName,
    onClose,
    onHandleClickSuggestedOrgUnit
  }: Props) => {
    const dispatch = useDispatch()

    const [status, setStatus] = useState<string>('')
    const [inviteGroup, setInviteGroup] = useState<string>('highest')
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState<
      boolean
    >(false)
    const [suppliersInGroup, setSuppliersInGroup] = useState<
      number | undefined
    >(undefined)

    const visibleSuppliers = useMemo(
      () =>
        tier2Suppliers && tier2Suppliers.size > 0
          ? tier2Suppliers.filter(supplier => {
              if (!status) {
                return true
              }
              if (supplier.get('status') && supplier.get('status') === status) {
                return true
              }
              return false
            })
          : undefined,
      [tier2Suppliers, status]
    )

    const onChangeExport = e => {
      setStatus(e.target.value)
    }
    const filterByStatus = useMemo(
      () =>
        tier2Suppliers && tier2Suppliers.size > 0
          ? tier2Suppliers
              .reduce<Array<string>>((result, currentItem) => {
                let status = currentItem && currentItem.get('status')
                if (result.indexOf(status) < 0) {
                  result.push(status)
                }
                return result
              }, [])
              .sort()
          : undefined,
      [tier2Suppliers]
    )

    const exportSupplierTable = () => {
      dispatch(exportTier2SupplierTable({ status }))
    }

    const handleGroupInvite = group => {
      setInviteGroup(group)
      let recentPercentile: Array<Array<number>>
      if (group === 'highest') {
        recentPercentile = [[5]]
      } else if (group === 'high') {
        recentPercentile = [[4, 5]]
      } else {
        recentPercentile = [[3, 4, 5]]
      }

      let searchParams = {
        limit: 0,
        filter: {
          recentPercentile
        }
      }
      api
        .get(`${apiRoutes.authService}/suppliers/search`, searchParams)
        .then(response => {
          setSuppliersInGroup(response.total)
        })

      setOpenConfirmationDialog(true)
    }

    const handleConfirmDialogClose = () => {
      setOpenConfirmationDialog(false)
      setSuppliersInGroup(undefined)
    }

    const confirmGroupInvite = () => {
      dispatch(
        inviteTier2Group(
          inviteGroup === 'highest'
            ? [5]
            : inviteGroup === 'high'
            ? [4, 5]
            : [3, 4, 5]
        )
      )
    }

    const intl = useIntl()

    return (
      <section>
        <ScrollToPosition />
        <div className='flex justify-between items-end'>
          <Text className='mt3'>
            <FormattedMessage
              id='Tier2InviteSupplier.InviteSuppliersDetails'
              defaultMessage='Invite the suppliers you want to submit Tier 2 diversity spend.'
            />
          </Text>
          <DropDownMenu>
            <MenuItem onClick={() => handleGroupInvite('highest')}>
              {intl.formatMessage(messages.invite)}{' '}
              {intl.formatMessage(messages.highest)}
            </MenuItem>
            <MenuItem onClick={() => handleGroupInvite('high')}>
              {intl.formatMessage(messages.invite)}{' '}
              {intl.formatMessage(messages.high)}
            </MenuItem>
            <MenuItem onClick={() => handleGroupInvite('medium')}>
              {intl.formatMessage(messages.invite)}{' '}
              {intl.formatMessage(messages.medium)}
            </MenuItem>
          </DropDownMenu>
        </div>

        <div className='flex flex-wrap justify-between'>
          <div className='flex-auto'>
            <div className='dib w-100 w-30-ns mr3-ns'>
              <Label>
                <FormattedMessage
                  id='Tier2InviteSupplier.InviteIndividual'
                  defaultMessage='Invite Individual Supplier'
                />
              </Label>
              <FormattedMessage
                id='Tier2InviteSupplier.EnterName'
                defaultMessage='Enter Name'
              >
                {message => (
                  <LookupSuppliers
                    placeholder={message}
                    open={open}
                    loadingSuggestions={loadingSuggestions}
                    suggestedSuppliers={suggestedSuppliers}
                    suggestQuery={suggestQuery}
                    onClose={onClose}
                    onHandleClickSuggestedOrgUnit={
                      onHandleClickSuggestedOrgUnit
                    }
                    onChangeCompanyName={onChangeCompanyName}
                    companyName={companyName}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          {visibleSuppliers && (
            <div className='flex items-end'>
              <Select fullWidth name='export' onChange={onChangeExport}>
                <option value=''>All</option>
                {filterByStatus &&
                  filterByStatus.map(status => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
              </Select>
              <div className='ml2'>
                <Button
                  autoSize
                  label={
                    <FormattedMessage
                      id='Tier2InviteSupplier.Export'
                      defaultMessage='Export'
                    />
                  }
                  onClick={exportSupplierTable}
                />
              </div>
            </div>
          )}
          <ConfirmationDialog
            open={openConfirmationDialog}
            onClose={handleConfirmDialogClose}
            confirmLabel={intl.formatMessage(messages.invite)}
            onConfirm={() => {
              confirmGroupInvite()
              handleConfirmDialogClose()
            }}
            hideActions={suppliersInGroup === undefined}
          >
            {suppliersInGroup === undefined ? (
              <Loading />
            ) : (
              <Text>
                <FormattedMessage
                  id='Tier2InviteSupplier.ConfirmGroupInvite'
                  defaultMessage='Are you sure you want to invite {num} {group} to Tier 2 community?'
                  values={{
                    num: suppliersInGroup && suppliersInGroup.toLocaleString(),
                    group: intl.formatMessage(messages[inviteGroup])
                  }}
                />
              </Text>
            )}
          </ConfirmationDialog>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <Tier2InviteSupplierTable visibleSuppliers={visibleSuppliers} />
        )}
      </section>
    )
  }
)

export default Tier2InviteSupplier
