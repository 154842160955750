import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import { call } from 'redux-saga/effects'

export function* sendVerificationCodeSaga(action) {
  if (action.payload.userMobilePhone) {
    yield call(api.post, `${apiRoutes.authService}/user/sendOnetimeKey`, {
      notifyOnError: true,
      returnPayload: true,
      usePayload: true
    })
    return { ...action.payload }
  }
}

export default createApiRequestSaga(sendVerificationCodeSaga)
