import { call, put } from 'redux-saga/effects'
import insight from 'shared/utils/api/insight'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { notify } from 'shared/actions'

export function* saveSnapshotSaga(action) {
  const { lineItems, ...snapshot } = action.payload

  const response = yield call(insight.saveSnapshot, snapshot)
  yield call(insight.saveSnapshotLineItems, {
    snapshotId: response.id,
    lineItems
  })

  yield put(
    notify({
      message: `Report Saved: '${snapshot.name}'`
    })
  )
  return yield { snapshot: response, lineItems }
}

export default createApiRequestSaga(saveSnapshotSaga)
