import DiversitySection from '../../components/DiversitySection'
import companySelectors from '../../../shared/selectors/companySelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { connect } from 'react-redux'
import { removeCertificate } from '../../actions'

export default connect(
  state => ({
    certifications: companySelectors.getCertifications(state),
    isTealbot: sessionSelectors.userHasRole(state, 'tealbot')
  }),
  {
    onRemoveCertificate: removeCertificate
  }
)(DiversitySection)
