import React, { Component } from 'react'
import PageSection from 'shared/components/PageSection'
import Button from 'shared/components/Button'
import StarsRating from 'shared/components/StarsRating'
import WriteReviewForm from '../WriteReviewForm'
import UserReviews from '../../containers/UserReviews'
import ReviewsAverageRatingDetails from '../../containers/ReviewsAverageRatingDetails'
import Text from 'shared/components/Text'
import DialogTitle from 'shared/components/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Scrollable from 'shared/components/Scrollable'
import { FormattedMessage } from 'react-intl'
import { RecordOf } from 'immutable'
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth'

const styles = {
  sectionSubtitle: 'fw6 f6 mt4 mb2',
  noReviews: 'gray f8 ml2',
  numberOfReviews: 'f7 ml3 gray'
}

type Props = {
  isTealbot: boolean
  averageRating: number
  numberOfReviews: number
  writingReview: boolean
  hasReviewedBefore: boolean
  onClickWriteReview: () => void
  onClickCancelWritingReview: () => void
  onSubmitReview: () => void
  onDeleteReview: () => void
  reviewOptions?: RecordOf<{
    rating: boolean
    preferred: boolean
  }>
  numberOfPreferred: number
  numberOfNotPreferred: number
  isPreferred?: boolean
  formValueIsPreferred?: boolean
  onlyMine?: boolean
} & WithWidthProps

type State = {
  viewReviewsDetails: boolean
}

class ReviewsSection extends Component<Props, State> {
  state = {
    viewReviewsDetails: false
  }

  handleClickToggleReviewsDetails = () => {
    this.setState({
      viewReviewsDetails: !this.state.viewReviewsDetails
    })
  }

  render() {
    const {
      writingReview,
      onClickWriteReview,
      onClickCancelWritingReview,
      onSubmitReview,
      onDeleteReview,
      averageRating,
      numberOfReviews,
      hasReviewedBefore,
      reviewOptions,
      numberOfPreferred,
      numberOfNotPreferred,
      isPreferred,
      formValueIsPreferred,
      isTealbot,
      onlyMine,
      width
    } = this.props

    const { viewReviewsDetails } = this.state

    const matchMobile = width && ['xs', 'sm'].includes(width)

    return (
      <PageSection
        title={
          <FormattedMessage
            id='ReviewsSection.Review'
            defaultMessage='Review'
          />
        }
      >
        <div>
          <h3 className={styles.sectionSubtitle}>
            <div className='w-50 dib'>
              <FormattedMessage
                id='ReportForm.AllCompanyReviews'
                defaultMessage='All company reviews'
              />
              {numberOfReviews > 0 ? (
                <span className={styles.numberOfReviews}>
                  <FormattedMessage
                    id='ReportForm.Review'
                    defaultMessage='{numberOfReviews, plural, one {{numberOfReviews} review} other {{numberOfReviews} reviews}}'
                    values={{ numberOfReviews }}
                  />
                </span>
              ) : (
                <span className={styles.noReviews}>
                  <FormattedMessage
                    id='ReportForm.NoReview'
                    defaultMessage='No reviews'
                  />
                </span>
              )}
            </div>

            {!hasReviewedBefore && !isTealbot && (
              <div className='w-50 dib tr'>
                <Button
                  label={
                    <FormattedMessage
                      id='ReportForm.WriteAReview'
                      defaultMessage='Write a review'
                    />
                  }
                  autoSize
                  onClick={onClickWriteReview}
                />
              </div>
            )}
          </h3>
          {reviewOptions && reviewOptions.get('rating') && (
            <div>
              <StarsRating value={averageRating || 0} />
              {numberOfReviews > 0 && (
                <Button
                  label={
                    viewReviewsDetails ? (
                      <FormattedMessage
                        id='ReportForm.ViewLess'
                        defaultMessage='View less'
                      />
                    ) : (
                      <FormattedMessage
                        id='ReportForm.ViewDetails'
                        defaultMessage='View details'
                      />
                    )
                  }
                  className='ml3'
                  variant='text'
                  autoSize
                  onClick={this.handleClickToggleReviewsDetails}
                />
              )}

              {viewReviewsDetails && <ReviewsAverageRatingDetails />}
            </div>
          )}
          {reviewOptions &&
            reviewOptions.get('preferred') &&
            !(numberOfPreferred === 0 && numberOfNotPreferred === 0) && (
              <Text className='mt2'>
                <b>
                  {isPreferred ? (
                    <FormattedMessage
                      id='ReportForm.Preferred'
                      defaultMessage='Liked'
                    />
                  ) : (
                    <FormattedMessage
                      id='ReportForm.NotPreferred'
                      defaultMessage='Not liked'
                    />
                  )}
                </b>
                &nbsp;
                {numberOfPreferred === 0 || numberOfNotPreferred === 0 ? (
                  <FormattedMessage
                    id='ReportForm.ByAllPeersWhoExpressedAnOpinon'
                    defaultMessage='by all peers who expressed an opinion.'
                  />
                ) : (
                  <FormattedMessage
                    id='ReviewSection.PreferredSupplier'
                    defaultMessage='by {isPreferredValues} out of {totalPreferred} peers who expressed an opinion'
                    values={{
                      isPreferredValues: isPreferred
                        ? numberOfPreferred
                        : numberOfNotPreferred,
                      totalPreferred: numberOfPreferred + numberOfNotPreferred
                    }}
                  />
                )}
              </Text>
            )}
          <Scrollable>
            <UserReviews
              onEdit={onClickWriteReview}
              onDelete={onDeleteReview}
              isTealbot={isTealbot}
              onlyMine={onlyMine}
            />
          </Scrollable>
        </div>
        <Dialog
          onClose={onClickCancelWritingReview}
          open={writingReview || false}
          fullWidth
          disableBackdropClick
          fullScreen={matchMobile}
        >
          <DialogTitle>
            <FormattedMessage
              id='ReportForm.WriteAReview'
              defaultMessage='Write a review'
            />
          </DialogTitle>
          <WriteReviewForm
            isPreferred={formValueIsPreferred}
            enablePreferred={
              reviewOptions ? reviewOptions.get('preferred') : undefined
            }
            enableRatings={
              reviewOptions ? reviewOptions.get('rating') : undefined
            }
            onClickCancelWritingReview={onClickCancelWritingReview}
            onSubmit={onSubmitReview}
          />
        </Dialog>
      </PageSection>
    )
  }
}

export default withWidth()(ReviewsSection)
