import { fromJS } from 'immutable'
import { requestSuccess, LOGOUT } from 'shared/actionTypes'
import {
  LOAD_SUPPLIER_PROFILE,
  ADD_TAG,
  REMOVE_TAG,
  UPDATE_CARD,
  UPVOTE_RESPONSE,
  CLEAR_VOTE_RESPONSE,
  DELETE_REVIEW,
  ADD_CONTACT,
  ADD_OUTREACH_CONTACT,
  UPDATE_CONTACT,
  REMOVE_CONTACT,
  REMOVE_CONTACT_RELATIONSHIP,
  ADD_PERSONAL_RELATIONSHIP,
  REMOVE_PERSONAL_RELATIONSHIP
} from '../../../SupplierProfile/actionTypes'
import { UNFOLLOW_SUPPLIER, CONNECT_TO_SUPPLIER } from '../../actionTypes'
import {
  SEARCH_SUPPLIER,
  GET_ORG_CHILDREN,
  ADD_TAG_TO_SUPPLIER,
  REMOVE_TAG_FROM_SUPPLIER,
  UPDATE_SUPPLIER_REVIEW,
  SUBMIT_SUPPLIER_REVIEW
} from '../../../Search/actionTypes'
import { LOAD_VET } from '../../../Vets/actionTypes'
import { GET_CARDS_BY_SUPPLIER_ID } from '../../../shared/actionTypes'
/**
 *
 * cards: {
 *   byId,
 *   bySupplier {
 *     [supplierId]: {
 *       [userId]: cardId
 *     }
 *   }
 * }
 *
 */
const cardsReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case requestSuccess(LOAD_SUPPLIER_PROFILE):
      return state
        .mergeIn(['byId'], fromJS(action.payload.cardsById))
        .setIn(
          ['bySupplier', action.payload.supplier.id],
          fromJS(action.payload.cardsIdsByUser)
        )

    case requestSuccess(SEARCH_SUPPLIER):
    case requestSuccess(GET_ORG_CHILDREN):
    case requestSuccess(LOAD_VET):
      return state
        .mergeDeepIn(['byId'], fromJS(action.payload.cardsById))
        .mergeIn(
          ['bySupplier'],
          fromJS(action.payload.cardsIdsBySupplierByUser)
        )

    case requestSuccess(ADD_TAG):
      if (action.payload.newCard) {
        return addNewCard(state, action.payload.newCard)
      } else {
        return state.mergeIn(
          ['byId', action.payload.cardId, action.payload.tagsFieldName],
          action.payload.newTags
        )
      }

    case requestSuccess(REMOVE_TAG):
    case requestSuccess(REMOVE_TAG_FROM_SUPPLIER):
      return state.deleteIn([
        'byId',
        action.payload.cardId,
        action.payload.tagsFieldName,
        action.payload.tagKey
      ])
    case requestSuccess(CONNECT_TO_SUPPLIER):
    case requestSuccess(UNFOLLOW_SUPPLIER):
    case requestSuccess(UPDATE_CARD):
    case requestSuccess(ADD_PERSONAL_RELATIONSHIP):
    case requestSuccess(REMOVE_PERSONAL_RELATIONSHIP):
    case requestSuccess(SUBMIT_SUPPLIER_REVIEW):
    case requestSuccess(ADD_TAG_TO_SUPPLIER):
    case requestSuccess(DELETE_REVIEW):
      return action.payload.newCard
        ? addNewCard(state, action.payload.newCard)
        : state.mergeIn(
            ['byId', action.payload.cardId],
            fromJS(action.payload.cardChanges)
          )

    case requestSuccess(UPDATE_SUPPLIER_REVIEW):
    case requestSuccess(GET_CARDS_BY_SUPPLIER_ID):
      return state
        .mergeIn(['byId'], fromJS(action.payload.cardsById))
        .mergeIn(['bySupplier'], fromJS(action.payload.bySupplier))

    case requestSuccess(ADD_CONTACT):
    case requestSuccess(ADD_OUTREACH_CONTACT):
      return action.payload.newCard
        ? addNewCard(state, action.payload.newCard)
        : state.setIn(
            ['byId', action.payload.cardId, 'contacts', action.payload.userId],
            fromJS({
              user: action.payload.userId,
              contactFor: action.payload.contactFor,
              phone: action.payload.phone,
              location: action.payload.location,
              isPrivate: action.payload.isPrivate,
              connected: true
            })
          )

    case requestSuccess(UPDATE_CONTACT):
      const { cardId, userId, ownerId, newCard, ...rest } = action.payload
      return newCard
        ? addNewCard(state, newCard)
        : state.mergeIn(
            ['byId', cardId, 'contacts', userId],
            fromJS({
              user: userId,
              ...rest
            })
          )

    case requestSuccess(REMOVE_CONTACT):
      return state.deleteIn([
        'byId',
        action.payload.cardId,
        'contacts',
        action.payload.userId
      ])

    case requestSuccess(REMOVE_CONTACT_RELATIONSHIP):
      const supplierCards = state.getIn([
        'bySupplier',
        action.payload.supplierId
      ])
      const supplierCardsList = supplierCards && supplierCards.toList()
      let tempById = {}
      supplierCardsList.forEach(supplierCardId => {
        tempById[supplierCardId] = state
          .getIn(['byId', supplierCardId])
          .removeIn(['contacts', action.payload.contactId])
      })
      const byId = fromJS(tempById)
      return state.mergeIn(['byId'], byId)

    case requestSuccess(UPVOTE_RESPONSE):
      return state.setIn(
        [
          'byId',
          action.payload.cardId,
          'ratingResponses',
          'likes',
          action.payload.userId
        ],
        new Date().toISOString()
      )

    case requestSuccess(CLEAR_VOTE_RESPONSE):
      return state.deleteIn([
        'byId',
        action.payload.cardId,
        'ratingResponses',
        'likes',
        action.payload.userId
      ])

    case LOGOUT:
      return fromJS({})

    default:
      return state
  }
}

export default cardsReducer

function addNewCard(state, newCard) {
  return state
    .setIn(['byId', newCard.id], fromJS(newCard))
    .setIn(['bySupplier', newCard.supplier, newCard.owner], newCard.id)
}
