import { connect } from 'react-redux'
import Community from '../../components/Community'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import {
  updateCommunityBuyer,
  updateCommunity,
  inviteExisting
} from '../../actions'
import RootState from 'shared/models/RootState'
import { RouteComponentProps } from 'react-router-dom'

type ContainerProps = RouteComponentProps<{ communityId: string }>

export default connect(
  (state: RootState, props: ContainerProps) => {
    const orgUnitId = sessionSelectors.getOrgUnitId(state)
    const isTealbot = sessionSelectors.userHasRole(state, 'tealbot')
    const community = communitiesSelectors.getCommunityById(
      state,
      props.match.params.communityId
    )
    const buyers = community && community.get('buyers')
    const buyer = buyers && buyers.find(b => b.get('orgUnitId') === orgUnitId)
    const contacts = buyer && buyer.get('contacts')
    const message = buyer && buyer.get('message')
    const publicProfile = buyer && buyer.get('publicProfile')

    return {
      orgUnitId,
      isTealbot,
      communityId: community && community.get('id'),
      logoUrl: community && community.get('logoUrl'),
      name: community && community.get('name'),
      isPrivate: community && community.get('isPrivate'),
      description: community && community.get('description'),
      publicProfile,
      message,
      contacts: contacts && contacts.map(c => c.get('user'))
    }
  },
  { updateCommunityBuyer, updateCommunity, inviteExisting }
)(Community)
