import buyerPaths from '../../routes/paths'

const basePath = buyerPaths.communities

const paths = {
  basePath,
  communities: basePath,
  community: `${basePath}/:communityId`,
  communityBuyers: `${basePath}/:communityId/buyers`,
  communityMetrics: `${basePath}/:communityId/metrics`,
  communityForm: `${basePath}/:communityId/:membershipId`
}

export default paths
