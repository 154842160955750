import { call, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import supplierRelationships from 'shared/utils/api/supplierRelationships'
import supplierRelationshipsSelectors from '../../../shared/selectors/supplierRelationshipsSelectors'

export function* removeCertificationValidationSaga(action) {
  let { supplierId, supplierRelationshipId, ...validation } = action.payload

  if (!supplierRelationshipId) {
    const supplierRelationship = yield select(
      supplierRelationshipsSelectors.getSupplierRelationshipForCurrentSupplier
    )
    supplierRelationshipId =
      supplierRelationship && supplierRelationship.get('id')
  }

  yield call(supplierRelationships.deleteCertificationValidation, {
    supplierRelationshipId,
    ...validation
  })

  return yield {
    supplierId,
    supplierRelationshipId,
    ...validation
  }
}

export default createApiRequestSaga(removeCertificationValidationSaga)
