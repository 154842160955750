import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import posts from 'shared/utils/api/posts'

export function* addCommentSaga(action) {
  const formName = action.meta.formName
  const { message } = action.payload.toJS()
  const postId = formName.slice(formName.lastIndexOf('/') + 1)
  const response = yield call(posts.addComment, postId, message)

  return yield { comment: response, postId }
}

export default createApiRequestSaga(addCommentSaga)
