import React, {
  Component,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect
} from 'react'
import ErrorPage from '../ErrorPage'
import logger from '../../utils/logger'
import sessionSelectors from '../../selectors/sessionSelectors'
import { ReactReduxContext } from 'react-redux'

const LogErrors: FunctionComponent<{
  error: Error
  info: { componentStack: string }
}> = props => {
  const context = useContext(ReactReduxContext)

  useEffect(() => {
    const actions = context.store
      .getState()
      .get('actionsLog')
      .toJS()

    const user = sessionSelectors.getUser(context.store.getState())

    logger.log({
      currentUrl: window.location.href,
      actions,
      user: {
        id: user.get('id'),
        email: user.get('email'),
        roles: user.get('roles')
      },
      error: props.error
    })
  }, [props.error, props.info, context.store])

  return <ErrorPage />
}

type Props = {
  children: ReactNode
}

type State =
  | {
      hasError: true
      error: Error
      info: { componentStack: string }
    }
  | {
      hasError: false
    }

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error,
      info
    })
  }

  render() {
    const { children } = this.props

    return this.state.hasError ? (
      <LogErrors error={this.state.error} info={this.state.info} />
    ) : (
      children
    )
  }
}

export default ErrorBoundary
