import buyerBasePath from '../../routes/paths'

const basePath = buyerBasePath.insight

const paths = {
  basePath,
  general: `${basePath}/general`,
  spend: `${basePath}/spend`,
  consolidation: `${basePath}/consolidation`,
  spendDiverse: `${basePath}/spend/diverse`,
  tier2: `${basePath}/tier2`,
  supplierOutreach: `${basePath}/supplierOutreach`,
  spendOverviewTier2: `${basePath}/tier2/spend-overview`,
  supplierListTier2: `${basePath}/tier2/supplier-list`,
  internalStats: `${basePath}/internalStats`,
  reportSnapshot: `${basePath}/report-snapshot`,
  reportSnapshotView: `${basePath}/report-snapshot/:report1`,
  reportSnapshotCompare: `${basePath}/report-snapshot/compare/:report1/:report2`,
  sustainabilityReport: `${basePath}/sustainability-report`,
  sustainabilityReportHealthSafety: `${basePath}/sustainability-report/health-safety`,
  sustainabilityReportEnvironment: `${basePath}/sustainability-report/environment`,
  sustainabilityReportDiversity: `${basePath}/sustainability-report/diversity`,
  sustainabilityReportGovernance: `${basePath}/sustainability-report/governance`,
  sustainabilityReportPrivacy: `${basePath}/sustainability-report/privacy`
}

export default paths
