import { call, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import posts from 'shared/utils/api/posts'
import sessionSelectors from 'shared/selectors/sessionSelectors'

export function* markPostReadSaga(action) {
  const postId = action.payload
  const userId = yield select(sessionSelectors.getUserId)

  yield call(posts.markPostRead, postId)
  return yield {
    postId,
    user: userId
  }
}

export default createApiRequestSaga(markPostReadSaga)
