import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'

export function* addSuggestedTags(action) {
  const { offerings, differentiators } = action.payload

  if (offerings.length > 0) {
    yield call(supplier.addTags, 'offerings', offerings)
  }

  if (differentiators.length > 0) {
    yield call(supplier.addTags, 'differentiators', differentiators)
  }

  return yield {
    offerings,
    differentiators
  }
}

export default createApiRequestSaga(addSuggestedTags)
