import React, { useMemo } from 'react'
import ParentQuestion from '../ParentQuestion'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import companySelectors from '../../../shared/selectors/companySelectors'
import { useSelector } from 'react-redux'
import AttachmentQuestion from '../AttachmentQuestion'
import OptionsQuestion from '../OptionsQuestion'
import TextQuestion from '../TextQuestion'
import Text from 'shared/components/Text'
import ScrollToPosition from 'shared/components/ScrollToPosition'

const messages = defineMessages({
  placeholderLink: {
    id: 'DiversityInclusion.placeholderLink',
    defaultMessage: 'Please provide a link to the statement'
  },
  placeholderAdditionalInfo: {
    id: 'placeholderAdditionalInfo',
    defaultMessage: 'Please provide any additional details.'
  }
})

export const ownerGroups = [
  'Indigenous / Native American owned / controlled business',
  'Black / African-American / African-Canadian owned / controlled business',
  'Person(s) of Colour owned / controlled business',
  'Refugee owned / controlled business',
  'Immigrant owned / controlled business',
  'Women owned / controlled business',
  'LGBTQ2S owned / controlled business',
  'Persons with Disabilities owned / controlled business',
  'Military Veteran owned / controlled business'
]

const pageId = 'diversityInclusion'

const DiversityInclusion = () => {
  const intl = useIntl()
  const certifications = useSelector(companySelectors.getCertifications)
  const diversity = useMemo(() => {
    const certs = certifications?.find(c => c[0] === 'diversity')
    return certs?.[1]
  }, [certifications])

  return (
    <div>
      <ScrollToPosition />
      <ParentQuestion
        pageId={pageId}
        questionId='diversityCommitment'
        question={
          <FormattedMessage
            id='DiversityInclusion.diversityCommitment'
            defaultMessage='Q1. Does your company have a formal Diversity & Inclusion commitment?'
          />
        }
      >
        <ParentQuestion
          pageId={pageId}
          parentQuestionId='diversityCommitment'
          questionId='publishedExternally'
          question={
            <FormattedMessage
              id='DiversityInclusion.publishedExternally'
              defaultMessage='Is this statement published externally?'
            />
          }
        >
          <TextQuestion
            pageId={pageId}
            parentQuestionId='publishedExternally'
            questionId='publishedExternallyLink'
            placeholder={intl.formatMessage(messages.placeholderLink)}
          />
        </ParentQuestion>
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='formalPolicies'
        question={
          <FormattedMessage
            id='DiversityInclusion.diversityPolices'
            defaultMessage='Q2. Does your company have formal policies that uphold Diversity & Inclusion within your organization?'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='formalPolicies'
          type='doc'
          multiple
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='diversityTraining'
        question={
          <FormattedMessage
            id='DiversityInclusion.diversityTraining'
            defaultMessage='Q3. Does your company provide training to your employees about Diversity & Inclusion?'
          />
        }
      />
      <ParentQuestion
        pageId={pageId}
        questionId='integrateProcess'
        question={
          <FormattedMessage
            id='DiversityInclusion.integrateProcess'
            defaultMessage='Q4. Does your company integrate specific Diversity & Inclusion strategies into your supply chain and procurement processes?'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='integrateProcess'
          type='doc'
          multiple
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='diversityCertification'
        disableNoSubQuestion
        question={
          <FormattedMessage
            id='DiversityInclusion.diversityCertification'
            defaultMessage={`Q5. Does your company have any recognized Diversity & Inclusion certifications (i.e. Canadian Council for Aboriginal Business Progressive Aboriginal Relations, Canadian Aboriginal and Minority Supplier Council, Women Business Enterprises Canada, Canadian Gay and Lesbian Chamber of Commerce, National Minority Supplier Development Council, Women's Business Enterprise National, National Gay and Lesbian Chamber of Commerce, or other)? If you have answered Yes to any of the above, please attach any supporting documents.`}
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='diversityCertification'
          type='cert'
          suggestion={diversity}
          certCategory='diversity'
          multiple
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='51PercentOwned'
        disableNoSubQuestion
        question={
          <FormattedMessage
            id='DiversityInclusion.51Percent'
            defaultMessage='Q6. Are you a current or new supplier that is 51 percent or more owned, controlled, and managed by person(s) who belongs to one or more of the following groups:'
          />
        }
        additionalInfo={
          <ul>
            {ownerGroups.map(group => (
              <li key={group}>
                <Text secondary>{group}</Text>
              </li>
            ))}
          </ul>
        }
        hideAdditionalOnResponse
      >
        <OptionsQuestion
          pageId={pageId}
          parentQuestionId='51PercentOwned'
          questionId='groups'
          otherLabel='Another diversity demographic that should be considered owned / controlled business, please specify'
          options={ownerGroups}
        />
      </ParentQuestion>
      <TextQuestion
        pageId={pageId}
        questionId='note'
        fieldType='textarea'
        question={
          <FormattedMessage
            id='optionalAdditionalNote'
            defaultMessage='Additional information (optional)'
          />
        }
        placeholder={intl.formatMessage(messages.placeholderAdditionalInfo)}
      />
    </div>
  )
}

export default DiversityInclusion
