import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

const styles = {
  status: 'f5 fw3 dib ma0 mid-gray',
  highlight: 'fw6 teal'
}

type Props = {
  q: string
  resultsCount: number
  isSearching: boolean
}

class SearchCounter extends Component<Props> {
  render() {
    const { q, resultsCount, isSearching } = this.props
    return isSearching ? (
      <span className={styles.status}>
        <FormattedMessage
          id='SearchCounter.Searching'
          defaultMessage='Searching...'
        />
      </span>
    ) : resultsCount > 0 ? (
      <span className={styles.status}>
        {resultsCount.toLocaleString()}{' '}
        <FormattedMessage id='SearchCounter.Results' defaultMessage='Results' />{' '}
        {q && (
          <>
            <FormattedMessage id='SearchCounter.For' defaultMessage='for' />{' '}
            <b className={styles.highlight}>{q}</b>
          </>
        )}
      </span>
    ) : (
      <span className={styles.status}>
        <FormattedMessage
          id='SearchCounter.SorryWeCouldNotFindASupplierThatMatched'
          defaultMessage="Sorry, we couldn't find a supplier that matched."
        />
      </span>
    )
  }
}

export default SearchCounter
