import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select } from 'redux-saga/effects'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import profileSelectors from '../../selectors/profileSelectors'
import cards from 'shared/utils/api/cards'

export function* removeContactSaga(action) {
  const userId = action.payload
  const currentSupplierId = yield select(profileSelectors.getDataField, 'id')
  const currentCard = yield select(
    cardsSelectors.getBySupplier,
    currentSupplierId
  )
  const cardId = currentCard && currentCard.get('id')

  /* no card, throw an error */
  if (
    !currentCard ||
    currentCard.size === 0 ||
    currentCard.get('placeholderCard') ||
    currentCard.get('id').endsWith('-1')
  ) {
    throw new Error('Try to remove contact without a real card')
  }

  // remove the contact from the card
  const contacts = currentCard
    .deleteIn(['contacts', userId])
    .get('contacts')
    .toList()
    .toJS()

  yield call(cards.update, cardId, {
    contacts
  })

  return yield {
    userId,
    cardId
  }
}

export default createApiRequestSaga(removeContactSaga)
