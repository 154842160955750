import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import { FormattedMessage } from 'react-intl'
import useSmallScreen from 'shared/utils/useSmallScreen'
import { sendContactUsMessage } from 'shared/actions'
import { connect } from 'react-redux'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import profileSelectors from 'buyer/SupplierProfile/selectors/profileSelectors'
import RootState from 'shared/models/RootState'
import ReportForm from 'buyer/SupplierProfile/components/ReportForm'
import surveySelectors from 'buyer/SupplierProfile/selectors/surveySelectors'
import { Map } from 'immutable'

type Props = {
  userId: string
  orgId: string
  onToggleReportDialog: () => void
  openReportDialog: boolean
  sendContactUsMessage: any
  taskType: 'reportSupplier' | 'reportEsgSurvey'
  numberOfResponsesByPage: Map<string, Array<string>>
}

const ReportFormDialog = ({
  userId,
  orgId,
  onToggleReportDialog,
  openReportDialog,
  sendContactUsMessage,
  taskType,
  numberOfResponsesByPage
}: Props) => {
  const matchMobile = useSmallScreen()

  return (
    <Dialog
      onClose={onToggleReportDialog}
      open={openReportDialog}
      fullWidth
      disableBackdropClick
      fullScreen={matchMobile}
    >
      <DialogTitle>
        <FormattedMessage
          id='ReportFormDialog.HelpUsUnderstandWhatsHappening'
          defaultMessage="Help us understand what's happening"
        />
      </DialogTitle>
      <DialogContent className='mt3'>
        <ReportForm
          onCancel={onToggleReportDialog}
          taskType={taskType}
          matchMobile={matchMobile}
          numberOfResponsesByPage={numberOfResponsesByPage}
          onSendContactUsMessage={sendContactUsMessage}
          orgId={orgId}
          userId={userId}
        />
      </DialogContent>
    </Dialog>
  )
}

export default connect(
  (state: RootState) => {
    return {
      orgId: profileSelectors.getDataField(state, 'id'),
      userId: sessionSelectors.getUserId(state),
      numberOfResponsesByPage: surveySelectors.getNumberOfResponsesByPage(state)
    }
  },
  {
    sendContactUsMessage
  }
)(ReportFormDialog)
