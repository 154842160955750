import React, { Component } from 'react'
import { fromJS, RecordOf } from 'immutable'
import connectReduxForm from 'shared/utils/connectReduxForm'
import AddUserForm, { AddUserFormProps } from 'shared/components/AddUserForm'
import connectUserToOrgSelectors from '../../store/selectors/connectUserToOrgSelectors'
import { addContact, selectSuggestedUser } from '../../store/actions'
import { getFormValues } from 'redux-form/immutable'
import User from 'shared/models/User'
import { defaultMemoize } from 'reselect'

const formName = 'dataPortal/addContact'
const formValueSelector = getFormValues(formName)

type Props = {
  addContact: () => void
  selectSuggestedUser: (user?: RecordOf<User>) => void
  handleSubmit: (addContact: () => void) => (params: string) => void
} & AddUserFormProps

export class AddContactForm extends Component<Props> {
  state = {
    creatingNewUser: false
  }

  handleClickNewUser = newUserName => {
    this.props.selectSuggestedUser(
      fromJS({
        firstName: newUserName
      })
    )
  }

  handleCancelAddContact = () => {
    this.props.selectSuggestedUser(undefined)
  }

  render() {
    const {
      handleSubmit,
      addContact,
      addingContact,
      selectSuggestedUser,
      ...rest
    } = this.props

    const { creatingNewUser } = this.state

    return (
      <AddUserForm
        onSubmit={handleSubmit(addContact)}
        addingContact={addingContact}
        creatingNewUser={creatingNewUser}
        onClickNewUser={this.handleClickNewUser}
        onClickSuggestedUser={selectSuggestedUser}
        onClickCancel={this.handleCancelAddContact}
        {...rest}
      />
    )
  }
}

type FormValueProps = RecordOf<{
  user: { email: string }
}>

const getInitialValues = defaultMemoize(user => ({
  user
}))

export default connectReduxForm(
  state => {
    const selectedUser = connectUserToOrgSelectors.getSelectedUser(state)
    const formValues = formValueSelector(state) as FormValueProps

    return {
      email: formValues && formValues.getIn(['user', 'email']),
      users: connectUserToOrgSelectors.getUsersOfSelectedOrg(state),
      creatingNewUser: selectedUser && !selectedUser.get('id'),
      addingContact: !!selectedUser,
      initialValues: getInitialValues(selectedUser)
    }
  },
  {
    addContact,
    selectSuggestedUser
  },
  {
    form: formName,
    enableReinitialize: true
  },
  AddContactForm
)
