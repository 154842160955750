export const GET_JOBS_LIST = 'jobs/GET_JOBS_LIST'
export const OPEN_JOB = 'jobs/OPEN_JOB'
export const UPDATE_JOB_ANSWERS = 'jobs/UPDATE_JOB_ANSWERS'
export const LOAD_NEW_JOB = 'jobs/LOAD_NEW_JOB'
export const RESERVE_JOB = 'jobs/RESERVE_JOB'
export const RELEASE_JOB = 'jobs/RELEASE_JOB'
export const NAV_TO_NEXT = 'jobs/NAV_TO_NEXT'
export const DELETE_JOB = 'jobs/DELETE_JOB'
export const DELETE_JOB_RESULT = 'jobs/DELETE_JOB_RESULT'
export const BULK_DELETE_RESULTS = 'jobs/BULK_DELETE_RESULTS'
export const UPDATE_SOW_ORG = 'jobs/UPDATE_SOW_ORG'
export const REORDER_ORGS_POSITION = 'jobs/REORDER_ORGS_POSITION'
export const UPDATE_JOB_STATUS = 'jobs/UPDATE_JOB_STATUS'
export const REJECT_SOW_ORG = 'jobs/REJECT_SOW_ORG'
export const LOOKUP_ORG = 'jobs/LOOKUP_ORG'
export const ADD_ORG = 'jobs/ADD_ORG'
export const ADD_NEW_ORG = 'jobs/ADD_NEW_ORG'
export const ADD_JOB_ROW = 'jobs/ADD_JOB_ROW'
export const SELECT_ORG_UNIT = 'jobs/SELECT_ORG_UNIT'
