import React, { Component } from 'react'
import { InjectedFormProps } from 'redux-form'
import { formValueSelector, reduxForm } from 'redux-form/immutable'
import InviteSupplierForm from '../../components/InviteSupplierForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { INVITE_SUPPLIER } from '../../actionTypes'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { List, RecordOf } from 'immutable'
import OrgUnit from 'shared/models/OrgUnit'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import { clearErrorMessage } from '../../actions'

type Props = {
  handleSubmit: (formValues: any) => () => void
  inviteSupplier: (arg: boolean) => void
  hasSubmitted: boolean
  isSubmitting: boolean
  addingCustomer: boolean
  matchingOrgs: List<RecordOf<OrgUnit>>
  contactEmail: string
  errorMessage: string
  newSupplierCreated: () => void
  clearErrorMessage: () => void
} & ContainerProps

type State = {
  messageSent: boolean
}

export class InviteSupplierFormContainer extends Component<
  Props & InjectedFormProps,
  State
> {
  state: State = {
    messageSent: false
  }

  handleClickNoneOfTheAbove = formValues => {
    this.props.inviteSupplier(formValues.set('ignoreMatchingOrgs', true))
  }

  handleClickConciergeTask = formValues => {
    this.props.inviteSupplier(
      formValues.set('websiteError', this.props.errorMessage)
    )
    this.setState({
      messageSent: true
    })
  }

  handleClickEditFields = formValues => {
    this.props.clearErrorMessage()
  }

  render() {
    const { handleSubmit, inviteSupplier, onCancel, ...rest } = this.props
    const { messageSent } = this.state

    return (
      <InviteSupplierForm
        {...rest}
        onClickNoneOfTheAbove={handleSubmit(this.handleClickNoneOfTheAbove)}
        onClickConciergeTask={handleSubmit(this.handleClickConciergeTask)}
        onClickEditFields={this.handleClickEditFields}
        onSubmit={handleSubmit(inviteSupplier)}
        onCancel={onCancel}
        messageSent={messageSent}
      />
    )
  }
}

const formName = 'buyer/search/invite'
const selector = formValueSelector(formName)

type ContainerProps = {
  vetId?: string
  contactEmail?: string
  onClickMatchingOrg?: (orgUnitId?: string) => void
  onCancel: () => void
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const user = sessionSelectors.getUser(state)
    const { vetId, contactEmail } = props

    return {
      isSubmitting: state.getIn(['buyer', 'inviteSupplier', 'isSubmitting']),
      newSupplierCreated: state.getIn([
        'buyer',
        'inviteSupplier',
        'newSupplierCreated'
      ]),
      hasSubmitted: state.getIn([
        'buyer',
        'inviteSupplier',
        'supplierInvitedSuccessfully'
      ]),
      matchingOrgs: vetId
        ? undefined
        : state.getIn(['buyer', 'inviteSupplier', 'matchingOrgs', 'names']),
      contactEmail: selector(state, 'contactEmail'),
      errorMessage: state.getIn([
        'buyer',
        'inviteSupplier',
        'supplierInvitedErrorMessage'
      ]),
      initialValues: {
        action: vetId ? 'Invite a supplier from RFI' : 'Invite a supplier',
        orgId: user && user.get('orgUnitId'),
        userId: user && user.get('id'),
        autoConnect: true,
        contactEmail: contactEmail,
        vetId
      }
    }
  },
  {
    inviteSupplier: createSubmitFormAction(formName, INVITE_SUPPLIER),
    clearErrorMessage
  }
)(
  reduxForm({
    form: formName
  })(InviteSupplierFormContainer)
)
