import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import CommunityHeader from 'shared/components/CommunityHeader'
import Divider from 'shared/components/Divider'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import CommunityNavBar from '../../components/CommunityNavBar'
import CommunityDetailsContainer from '../CommunityDetailsContainer'
import BeginCommunityFormPageContainer from '../BeginCommunityFormPageContainer'
import FinishCommunityFormPageContainer from '../FinishCommunityFormPageContainer'
import CommunityFormPageContainer from '../CommunityFormPageContainer'
import paths from '../../routes/paths'
import { acceptCommunity, leaveCommunity } from '../../actions'
import ScrollToTopOnMount from 'shared/components/ScrollToTopOnMount'
import RootState from 'shared/models/RootState'
import { RouteComponentProps } from 'react-router-dom'

type Props = {
  name: string
  communityId: string
  communityMembershipId: string
  isPrivate?: boolean
  supplierSearchable?: boolean
  supplierIsMember?: boolean
  logoUrl?: string
  description: string
  longDescription?: string
  hasForm?: boolean
  acceptCommunity: ({ commId: string }) => void
  leaveCommunity: ({ communityId, comPlanMembershipId: string }) => void
}

export class CommunityContainer extends Component<Props> {
  handleJoinCommunity = () => {
    const { communityId, acceptCommunity } = this.props
    acceptCommunity({
      commId: communityId
    })
  }

  handleLeaveCommunity = () => {
    const { communityId, communityMembershipId, leaveCommunity } = this.props
    leaveCommunity({
      communityId,
      comPlanMembershipId: communityMembershipId
    })
  }

  render() {
    const {
      communityId,
      name,
      isPrivate,
      supplierSearchable,
      supplierIsMember,
      logoUrl,
      description,
      longDescription,
      hasForm
    } = this.props
    return (
      <Fragment>
        <ScrollToTopOnMount />
        <CommunityHeader
          name={name}
          isPrivate={isPrivate}
          supplierSearchable={supplierSearchable}
          logoUrl={logoUrl}
          description={description}
          longDescription={longDescription}
          supplierIsMember={supplierIsMember}
          onJoinCommunity={this.handleJoinCommunity}
          onLeaveCommunity={this.handleLeaveCommunity}
        />

        {hasForm && (
          <div
            style={{ position: 'sticky', top: 50 }}
            className='z-3 bg-near-white'
          >
            <div className='mw8 center ph3'>
              <CommunityNavBar communityId={communityId} />
            </div>
            <Divider />
          </div>
        )}
        <Switch>
          <Route
            path={paths.communityForm}
            component={CommunityFormPageContainer}
          />
          <Route
            path={paths.beginCommunityForm}
            component={BeginCommunityFormPageContainer}
          />
          <Route
            path={paths.finishCommunityForm}
            component={FinishCommunityFormPageContainer}
          />
          <Route path={paths.community} component={CommunityDetailsContainer} />
        </Switch>
      </Fragment>
    )
  }
}

type ContainerProps = RouteComponentProps<{ communityId: string }>

export default connect(
  (state: RootState, props: ContainerProps) => {
    const communityId = props.match.params.communityId
    const community = communitiesSelectors.getById(state, communityId)
    const membership = communitiesSelectors.getMembershipByCommunityId(
      state,
      communityId
    )

    return {
      communityId,
      communityMembershipId: membership.get('id'),
      name: community && community.get('name'),
      isPrivate: community && community.get('isPrivate'),
      supplierSearchable: community && community.get('supplierSearchable'),
      supplierIsMember: membership.get('status') === 'Accepted',
      logoUrl: community && community.get('logoUrl'),
      description: community && community.get('description'),
      longDescription: community && community.get('longDescription'),
      hasForm: community && community.has('form') && membership.size > 0
    }
  },
  {
    acceptCommunity,
    leaveCommunity
  }
)(CommunityContainer)
