import React, { useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import jobsSelectors from '../../selectors/jobsSelectors'
import RootState from 'shared/models/RootState'
import { SowJobOrgUnit } from 'shared/models/Job'
import SowField from './SowField'
import Button from 'shared/components/Button'
import Divider from 'shared/components/Divider'
import { updateSowOrg } from '../../actions'
import { diff } from 'deep-object-diff'
import equalsIgnoreOrders from 'shared/utils/equalsIgnoreOrder'
import SowMainSection from './SowMainSection'

type Props = {
  orgUnitId: string
  jobId: string
  isEditing: { [key: string]: boolean }
  setIsEditing: (value: { [key: string]: boolean }) => void
}

const SowMain = (props: Props) => {
  const dispatch = useDispatch()
  const { orgUnitId, jobId, isEditing, setIsEditing } = props
  const selectedOrg: SowJobOrgUnit = useSelector((state: RootState) =>
    jobsSelectors.getSowOrg(state, orgUnitId)
  )
  const numAcceptedOrgs = useSelector(
    (state: RootState) => jobsSelectors.getSowAcceptList(state)?.length || 0
  )
  const saving = useSelector((state: RootState) =>
    state.getIn(['jobs', 'submittingAnswer'])
  )

  const [orgChanges, setOrgChanges] = useState<SowJobOrgUnit>(selectedOrg)
  const editMode = useMemo(
    () => isEditing && Object.values(isEditing).some(v => v),
    [isEditing]
  )

  useEffect(() => {
    setOrgChanges(JSON.parse(JSON.stringify(selectedOrg)))
  }, [selectedOrg])

  const handleEdit = (section: string) => {
    setIsEditing(Object.assign({}, isEditing, { [section]: true }))
  }

  const handleChange = ({ fieldName, value }) => {
    setOrgChanges(Object.assign({}, orgChanges, { [fieldName]: value }))
  }

  const handleCancel = (section: string) => {
    setIsEditing(Object.assign({}, isEditing, { [section]: false }))
    setOrgChanges(selectedOrg)
  }

  const handleSave = (section?: string) => {
    const {
      offerings: selectedOfferings,
      naics: selectedNaics,
      serviceAreas: selectedServiceAreas,
      locations: selectedLocations,
      ...selectedRest
    } = selectedOrg
    const {
      offerings: changesOfferings,
      naics: changesNaics,
      serviceAreas: changesServiceAreas,
      locations: changesLocations,
      ...changesRest
    } = orgChanges
    const answers =
      selectedRest &&
      changesRest &&
      (diff(selectedRest, changesRest) as { [key: string]: string | string[] })

    if (answers && !equalsIgnoreOrders(selectedOfferings, changesOfferings)) {
      answers.offerings = changesOfferings
    }
    if (answers && !equalsIgnoreOrders(selectedNaics, changesNaics)) {
      answers.naics = changesNaics
    }
    if (
      answers &&
      !equalsIgnoreOrders(selectedServiceAreas, changesServiceAreas)
    ) {
      answers.serviceAreas = changesServiceAreas
    }
    if (answers && !equalsIgnoreOrders(selectedLocations, changesLocations)) {
      answers.locations = changesLocations.map(l => JSON.parse(l))
    }

    dispatch(
      updateSowOrg({
        jobId,
        orgUnitId: selectedOrg?.orgUnitId,
        rowNum: selectedOrg?.rowNum,
        answers
      })
    )
    if (section) {
      setIsEditing(Object.assign({}, isEditing, { [section]: false }))
    }
  }

  const handleSaveNew = () => {
    const { locations, ...rest } = orgChanges
    const answers = {
      locations: locations?.map(l => JSON.parse(l)),
      ...rest
    }
    dispatch(
      updateSowOrg({
        jobId,
        orgUnitId: `NewOrgUnit-${Math.random()
          .toString()
          .substr(2)}`,
        rowNum: numAcceptedOrgs,
        answers
      })
    )
  }

  return (
    <div className='pa3'>
      <SowMainSection
        header={selectedOrg?.name}
        size='large'
        isEditing={!!isEditing?.header}
        readonly={(editMode && !isEditing?.header) || orgUnitId === 'New'}
        onSave={() => handleSave('header')}
        onCancel={() => handleCancel('header')}
        onEdit={() => handleEdit('header')}
      >
        {(!!isEditing?.header || orgUnitId === 'New') && (
          <SowField
            questionType='text'
            fieldName={'name'}
            placeholder='Supplier Name'
            value={orgChanges.name || ''}
            editMode={!!isEditing?.header || orgUnitId === 'New'}
            onChange={handleChange}
          />
        )}
        <SowField
          questionType='text'
          label='Website'
          fieldName={'websiteUrl'}
          value={orgChanges.websiteUrl || ''}
          editMode={!!isEditing?.header || orgUnitId === 'New'}
          onChange={handleChange}
          sideLabel
          placeholder='Company website URL'
        />
        <SowField
          questionType='text'
          label='Phone'
          fieldName={'phoneNumber'}
          value={orgChanges.phoneNumber || ''}
          editMode={!!isEditing?.header || orgUnitId === 'New'}
          onChange={handleChange}
          sideLabel
          placeholder='Main phone number'
        />
        <SowField
          questionType='textArea'
          label='Summary'
          fieldName={'description'}
          value={orgChanges.description || ''}
          editMode={!!isEditing?.header || orgUnitId === 'New'}
          onChange={handleChange}
          sideLabel
          placeholder='A short summary in 200 characters'
          maxLength={200}
        />
      </SowMainSection>
      <Divider className='mt3 mb2' />
      <div className='cf'>
        <div className='dib w-two-thirds fl pr4'>
          <SowMainSection
            header={'About'}
            isEditing={!!isEditing?.about}
            readonly={(editMode && !isEditing?.about) || orgUnitId === 'New'}
            onSave={() => handleSave('about')}
            onCancel={() => handleCancel('about')}
            onEdit={() => handleEdit('about')}
          >
            <SowField
              questionType='textArea'
              fieldName={'longDescription'}
              value={orgChanges.longDescription || ''}
              editMode={!!isEditing?.about || orgUnitId === 'New'}
              onChange={handleChange}
              height={225}
              maxLength={2000}
            />
          </SowMainSection>
        </div>
        <div className='dib w-third'>
          <SowMainSection
            header={'Corporate'}
            isEditing={!!isEditing?.corporate}
            readonly={
              (editMode && !isEditing?.corporate) || orgUnitId === 'New'
            }
            onSave={() => handleSave('corporate')}
            onCancel={() => handleCancel('corporate')}
            onEdit={() => handleEdit('corporate')}
          >
            <SowField
              questionType='text'
              label={'Parent Company'}
              fieldName={'parentOrgName'}
              value={orgChanges.parentOrgName || ''}
              editMode={!!isEditing?.corporate || orgUnitId === 'New'}
              onChange={handleChange}
            />
            <SowField
              questionType='numberOfEmployees'
              label={'No. of Employees'}
              fieldName={'employeeNumber'}
              value={orgChanges.employeeNumber || ''}
              editMode={!!isEditing?.corporate || orgUnitId === 'New'}
              onChange={handleChange}
            />
            <SowField
              questionType='text'
              label={'Year Found'}
              fieldName={'yearFounded'}
              value={orgChanges.yearFounded || ''}
              editMode={!!isEditing?.corporate || orgUnitId === 'New'}
              onChange={handleChange}
            />
            <SowField
              questionType='annualRevenue'
              label={'Annual Revenue'}
              fieldName={'yearlyRevenue'}
              value={orgChanges.yearlyRevenue || ''}
              editMode={!!isEditing?.corporate || orgUnitId === 'New'}
              onChange={handleChange}
            />
          </SowMainSection>
        </div>
      </div>
      <Divider className='mt3 mb2' />
      <div className='cf'>
        <div className='dib w-two-thirds fl pr4'>
          <SowMainSection
            header={'Service Offerings'}
            isEditing={!!isEditing?.offerings}
            readonly={
              (editMode && !isEditing?.offerings) || orgUnitId === 'New'
            }
            onSave={() => handleSave('offerings')}
            onCancel={() => handleCancel('offerings')}
            onEdit={() => handleEdit('offerings')}
          >
            <SowField
              questionType='keyword'
              fieldName={'offerings'}
              value={orgChanges.offerings || []}
              editMode={!!isEditing?.offerings || orgUnitId === 'New'}
              onChange={handleChange}
              height={150}
              placeholder={'Enter an Offering'}
              buttonLabel={'Add'}
            />
          </SowMainSection>
        </div>
        <div className='dib w-third'>
          <SowMainSection
            header={'NAICS Codes'}
            isEditing={!!isEditing?.naics}
            readonly={(editMode && !isEditing?.naics) || orgUnitId === 'New'}
            onSave={() => handleSave('naics')}
            onCancel={() => handleCancel('naics')}
            onEdit={() => handleEdit('naics')}
          >
            <SowField
              questionType='naics'
              fieldName={'naics'}
              value={orgChanges.naics || []}
              editMode={!!isEditing?.naics || orgUnitId === 'New'}
              onChange={handleChange}
            />
          </SowMainSection>
        </div>
      </div>
      <Divider className='mt3 mb2' />
      <div className='cf'>
        <div className='dib w-two-thirds fl pr4'>
          <SowMainSection
            header={'Locations'}
            isEditing={!!isEditing?.locations}
            readonly={
              (editMode && !isEditing?.locations) || orgUnitId === 'New'
            }
            onSave={() => handleSave('locations')}
            onCancel={() => handleCancel('locations')}
            onEdit={() => handleEdit('locations')}
          >
            <SowField
              questionType='locations'
              fieldName={'locations'}
              value={orgChanges.locations || []}
              editMode={!!isEditing?.locations || orgUnitId === 'New'}
              onChange={handleChange}
            />
          </SowMainSection>
        </div>
        <div className='dib w-third'>
          <SowMainSection
            header={'Service Areas'}
            isEditing={!!isEditing?.service}
            readonly={(editMode && !isEditing?.service) || orgUnitId === 'New'}
            onSave={() => handleSave('service')}
            onCancel={() => handleCancel('service')}
            onEdit={() => handleEdit('service')}
          >
            <SowField
              questionType='keyword'
              fieldName={'serviceAreas'}
              value={orgChanges.serviceAreas || []}
              editMode={!!isEditing?.service || orgUnitId === 'New'}
              placeholder={'Enter a service location'}
              buttonLabel={'Add'}
              onChange={handleChange}
              editNote={'Default to Global if no areas are specified.'}
            />
          </SowMainSection>
        </div>
      </div>
      {orgUnitId === 'New' && (
        <>
          <Divider className='mv3' />
          <div className='flex justify-end'>
            <Button
              disabled={!orgChanges.name || !orgChanges.websiteUrl || saving}
              onClick={handleSaveNew}
            >
              Save the New Supplier
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default SowMain
