import enMessages from './locales/en.json'
import frMessages from './locales/fr.json'
import esMessages from './locales/es.json'
import zhCNMessages from './locales/zh-CN.json'
import zhTWMessages from './locales/zh-TW.json'
import moment from 'moment'
import 'moment/locale/fr'
import storageManager from 'shared/utils/storageManager'

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill')
  require('@formatjs/intl-pluralrules/locale-data/en')
  require('@formatjs/intl-pluralrules/locale-data/fr')
  require('@formatjs/intl-pluralrules/locale-data/es')
  require('@formatjs/intl-pluralrules/locale-data/zh')
}

if (!Intl.NumberFormat) {
  require('@formatjs/intl-numberformat/polyfill')
  require('@formatjs/intl-numberformat/locale-data/en')
  require('@formatjs/intl-numberformat/locale-data/fr')
  require('@formatjs/intl-numberformat/locale-data/es')
  require('@formatjs/intl-numberformat/locale-data/zh')
}

// if (!Intl.RelativeTimeFormat) {
//   require('@formatjs/intl-relativetimeformat/polyfill')
//   require('@formatjs/intl-relativetimeformat/locale-data/en')
//   require('@formatjs/intl-relativetimeformat/locale-data/fr')
//   require('@formatjs/intl-relativetimeformat/locale-data/es')
//   require('@formatjs/intl-relativetimeformat/locale-data/zh')
// }

// dates translations
moment.updateLocale('en', {
  calendar: {
    nextDay: '[Tomorrow]',
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    nextWeek: '[]',
    lastWeek: '[]',
    sameElse: '[]'
  }
})

moment.updateLocale('es', {
  calendar: {
    nextDay: '[Mañana]',
    sameDay: '[Hoy]',
    lastDay: '[Ayer]',
    nextWeek: '[]',
    lastWeek: '[]',
    sameElse: '[]'
  }
})

moment.updateLocale('fr', {
  calendar: {
    nextDay: '[Matin]',
    sameDay: "[Aujourd'hui]",
    lastDay: '[Hier]',
    nextWeek: '[]',
    lastWeek: '[]',
    sameElse: '[]'
  }
})

moment.updateLocale('zh', {
  calendar: {
    lastDay: '[昨天]',
    sameDay: '[今天]',
    nextDay: '[明天]',
    nextWeek: '[]',
    lastWeek: '[]',
    sameElse: '[]'
  }
})

export const supportedLanguages = [
  {
    key: 'en',
    title: 'English'
  },
  {
    key: 'fr',
    title: 'Français'
  },
  {
    key: 'es',
    title: 'Español'
  },
  {
    key: 'zh-CN',
    title: '中文 (简体)'
  },
  {
    key: 'zh-TW',
    title: '中文 (繁体)'
  }
]

const browserLanguage =
  // @ts-ignore
  window.navigator.userLanguage || window.navigator.language

const defaultLanguage = supportedLanguages
  .map(lang => lang.key)
  .includes(browserLanguage.slice(0, 2))
  ? browserLanguage.slice(0, 2)
  : 'en'

export const getLocaleFromBrowser = () =>
  storageManager.getItem('preferredLanguage') || defaultLanguage

// set inital locale to moment
moment.locale(getLocaleFromBrowser())

export const savePreferredLanguage = (locale: string) => {
  moment.locale(locale)
  storageManager.setItem('preferredLanguage', locale)
}

export const getMessagesByLocale = (loc = getLocaleFromBrowser()) => {
  if (loc === 'en') {
    return enMessages
  } else if (loc === 'fr') {
    return frMessages
  } else if (loc === 'es') {
    return esMessages
  } else if (loc === 'zh-CN') {
    return zhCNMessages
  } else if (loc === 'zh-TW') {
    return zhTWMessages
  }
}
