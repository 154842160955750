import React, { useEffect, useState } from 'react'
import Page from 'shared/components/Page'
import TitleText from 'shared/components/TitleText'
import SubTitleText from 'shared/components/SubTitleText'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import useQuery from 'shared/utils/useQuery'
import { useDispatch } from 'react-redux'
import Loading from 'shared/components/Loading'
import { login } from 'accounts/actions'
import { fromJS } from 'immutable'
import TealbookLogoWrapper from 'accounts/components/TealbookLogoWrapper'
import accountRoutes from 'accounts/routes/paths'

type Props = {
  loginCallback: (token: string) => Promise<void | {}>
}

const LoginWithTokenPage = ({ loginCallback }: Props) => {
  const history = useHistory()
  const { token } = useQuery<{ token: string }>()
  const dispatch = useDispatch()
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>('')

  useEffect(() => {
    if (token) {
      loginCallback(token)
        .then((result = {}) => {
          dispatch(
            login(
              fromJS({
                alreadyLoggedIn: true,
                ...result
              })
            )
          )
        })
        .catch(e => setLoginErrorMessage(e.message || 'Unexpected error.'))
    }
  }, [loginCallback, token, dispatch])

  if (loginErrorMessage || !token) {
    return (
      <Page title='Login with Token Error' className='tc'>
        <TealbookLogoWrapper />
        <TitleText>
          <FormattedMessage
            id='LoginWithTokenPage.OhNoThereSeemsToBeAProblem'
            defaultMessage='Oh No, there seems to be a problem.'
          />
        </TitleText>
        {loginErrorMessage && (
          <SubTitleText>Error: {loginErrorMessage}</SubTitleText>
        )}

        <div className='f4 mt4'>
          <Button
            autoSize
            onClick={() => history.push(accountRoutes.login)}
            label={
              <FormattedMessage
                id='LoginWithTokenPage.GoToLogin'
                defaultMessage='Please try login in again'
              />
            }
          />
        </div>
      </Page>
    )
  }

  return <Loading />
}

export default LoginWithTokenPage
