import React, { Component } from 'react'
import Avatar from 'shared/components/Avatar'
import dateFormat from 'shared/utils/dateFormat'
import Text from 'shared/components/Text'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'

const styles = {
  wrapper: 'mv3',
  leftColumn: 'dtc w2 v-top',
  rightColumn: 'dtc pl3 v-top',
  name: 'dib f7 fw6',
  date: 'dib f7 fw4 gray ml2'
}

type Props = {
  profilePictureUrl: string
  firstName: string
  lastName: string
  email: string
  createdDate: string
  body: string
  onDelete?: () => void
}

type State = {
  isDialogOpen: boolean
}
class Comment extends Component<Props, State> {
  state: State = {
    isDialogOpen: false
  }

  confirmDeletion = () => {
    this.setState({
      isDialogOpen: true
    })
  }

  handleCloseDialogRequest = () => {
    this.setState({
      isDialogOpen: false
    })
  }

  handleDeleteConfirmed = () => {
    if (this.props.onDelete) {
      this.props.onDelete()
    }
    this.setState({
      isDialogOpen: false
    })
  }

  render() {
    const {
      profilePictureUrl,
      firstName,
      lastName,
      email,
      createdDate,
      body,
      onDelete
    } = this.props
    const { isDialogOpen } = this.state

    return (
      <div className={styles.wrapper}>
        {onDelete && (
          <Button
            caution
            label={
              <FormattedMessage
                id='Comments.DeleteButton'
                defaultMessage='Delete'
              />
            }
            autoSize
            size='small'
            className='fr'
            onClick={this.confirmDeletion}
          />
        )}
        <div className={styles.leftColumn}>
          <Avatar
            url={profilePictureUrl}
            className='w2'
            name={`${firstName || ''} ${lastName || ''}`}
          />
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.name}>
            {!firstName && !lastName ? email : `${firstName} ${lastName}`}
          </div>
          <div className={styles.date}>{dateFormat(createdDate)}</div>
          <Text>{body}</Text>
        </div>
        {onDelete && (
          <Dialog open={isDialogOpen} onClose={this.handleCloseDialogRequest}>
            <DialogTitle>
              <FormattedMessage
                id='Comments.Confirmation'
                defaultMessage='Confirmation'
              />
            </DialogTitle>
            <DialogContent className='mt3'>
              <FormattedMessage
                id='Comments.AreYouSureText'
                defaultMessage='Are you sure you want to delete your comment?'
              />
            </DialogContent>
            <DialogActions>
              <Button
                label={
                  <FormattedMessage id='Comment.OKButton' defaultMessage='OK' />
                }
                onClick={this.handleDeleteConfirmed}
                autoSize
              />
              <Button
                label={<FormattedMessage id='CancelButton' />}
                onClick={this.handleCloseDialogRequest}
                secondary
                autoSize
              />
            </DialogActions>
          </Dialog>
        )}
      </div>
    )
  }
}

export default Comment
