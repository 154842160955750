import React, { Component } from 'react'
import { connect } from 'react-redux'
import TaskList from '../../components/TaskList'
import { createSelector } from 'reselect'
import { fromJS, List, RecordOf } from 'immutable'
import moment from 'moment'
import { updateData, loadData } from 'shared/actions'
import RootState from 'shared/models/RootState'
import { RouteComponentProps } from 'react-router'
import Task from 'shared/models/Task'

type Props = {
  tasks: List<RecordOf<Task>>
  isLoading: boolean
  loadData: typeof loadData
  updateData: typeof updateData
} & RouteComponentProps

class TaskListContainer extends Component<Props> {
  componentDidMount() {
    this.loadTasks()
  }

  loadTasks = (status?: string) => {
    const { loadData } = this.props
    if (status === 'completed' || status === 'cancelled') {
      loadData({
        entity: 'tasks',
        query: 'tasks?concierge=true&archived=true'
      })
    } else {
      loadData({
        entity: 'tasks',
        query: 'tasks?concierge=true'
      })
    }
  }

  updateTaskStatus(taskId, statusTo: string) {
    const { updateData } = this.props

    updateData({
      entity: 'tasks',
      record: {
        id: taskId,
        status: statusTo
      }
    })
  }

  handleCancel = (taskId: string) => {
    this.updateTaskStatus(taskId, 'Cancelled')
  }

  handleComplete = (taskId: string) => {
    this.updateTaskStatus(taskId, 'Completed')
  }

  handleChangeShowTasks = (status: string) => {
    this.loadTasks(status)
  }

  handleInProgress = (taskId: string) => {
    this.updateTaskStatus(taskId, 'InProgress')
  }

  handleWaitingForClient = (taskId: string) => {
    this.updateTaskStatus(taskId, 'WaitingForClient')
  }

  render() {
    return (
      <TaskList
        {...this.props}
        onCancel={this.handleCancel}
        onComplete={this.handleComplete}
        onChangeShowTasks={this.handleChangeShowTasks}
        onInProgress={this.handleInProgress}
        onWaitingForClient={this.handleWaitingForClient}
      />
    )
  }
}

const getAllTasks = createSelector(
  (state: RootState) => state.getIn(['data', 'tasks']) || fromJS({}),
  allTasks =>
    allTasks
      .toList()
      .sort(
        (task1: RecordOf<Task>, task2: RecordOf<Task>) =>
          new Date(task1.get('responseDate')).valueOf() -
          new Date(task2.get('responseDate')).valueOf()
      )
      .map((task: RecordOf<Task>) =>
        task.merge({
          responseDate: new Date(task.get('responseDate')),
          status: task.get('status'),
          dateCreated: moment(task.getIn(['created', 'date'])).format('LL'),
          createdBy: task.get('userName'),
          orgUnitName: task.get('orgUnitName')
        })
      )
)

export default connect(
  (state: RootState) => ({
    isLoading: state.getIn(['data', 'isLoading', 'tasks']),
    tasks: getAllTasks(state)
  }),
  {
    updateData,
    loadData
  }
)(TaskListContainer)
