import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

export const loadCompany = createAction(actionTypes.LOAD_COMPANY)
export const loadVets = createAction(actionTypes.LOAD_VETS)
export const loadVetsSuccess = createAction(actionTypes.LOAD_VETS_SUCCESS)
export const loadSupplierInsight = createAction(
  actionTypes.LOAD_SUPPLIER_INSIGHT
)

export const updateCustomizePitchDeck = createAction(
  actionTypes.UPDATE_CUSTOMIZE_PITCH_DECK
)
export const followClient = createAction(actionTypes.FOLLOW_CLIENT)
export const showPaymentDialog = createAction(actionTypes.SHOW_PAYMENT_DIALOG)
export const hidePaymentDialog = createAction(actionTypes.HIDE_PAYMENT_DIALOG)
export const processPayment = createAction(actionTypes.PROCESS_PAYMENT)

export const cancelSubscription = createAction(actionTypes.CANCEL_SUBSCRIPTION)
export const openAddCollaborateContactForm = createAction(
  actionTypes.OPEN_ADD_COLLABORATE_CONTACT_FORM
)
export const cancelAddingSurveyCollaborateContact = createAction(
  actionTypes.CANCEL_ADDING_SURVEY_COLLABORATE_CONTACT
)
export const addSurveyCollaborateContact = createAction(
  actionTypes.ADD_SURVEY_COLLABORATE_CONTACT
)
