import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call } from 'redux-saga/effects'
import supplierRelationshipsApi from 'shared/utils/api/supplierRelationships'

export function* removeCustomerSaga(action) {
  const { supplierRelationshipId } = action.payload

  return yield call(supplierRelationshipsApi.updateSupplierRelationship, {
    supplierRelationshipId,
    supplierCollaboration: {
      isClient: false
    }
  })
}

export default createApiRequestSaga(removeCustomerSaga)
