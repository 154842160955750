import React from 'react'
import { FormattedMessage } from 'react-intl'
import ShowQuestionResponse from './ShowQuestionResponse'
import ScrollToPosition from 'shared/components/ScrollToPosition'

const pageId = 'healthSafety'

const HealthSafety = () => {
  return (
    <div>
      <ScrollToPosition />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='iso45001'
        question={<FormattedMessage id='HealthSafety.iso45001' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='emergencyResponsePolicies'
        question={<FormattedMessage id='HealthSafety.hazardIdentification' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='healthSafetyPolicies'
        question={<FormattedMessage id='HealthSafety.workerParticipation' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='laggingIndicators'
        question={<FormattedMessage id='HealthSafety.laggingIndicators' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='leadingIndicators'
        question={<FormattedMessage id='HealthSafety.leadingIndicators' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='liabilityPrograms'
        question={<FormattedMessage id='HealthSafety.liabilityInsurance' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='occupationalChargesViolations'
        question={
          <FormattedMessage
            id='HealthSafety.chargeOccupationalSafety'
            values={{
              b: b => <span className='fw8 underline'>{b}</span>
            }}
          />
        }
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='note'
        question={<FormattedMessage id='optionalAdditionalNote' />}
        longAnswer
      />
    </div>
  )
}

export default HealthSafety
