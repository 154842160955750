const basePath = '/admin'

const paths = {
  basePath,
  rules: `${basePath}/rules`,
  users: `${basePath}/users`,
  salesConfig: `${basePath}/sales-config`,
  techConfig: `${basePath}/tech-config`,
  techWebhooks: `${basePath}/tech-config/webhooks`,
  appConfig: `${basePath}/app-config`,
  appSRM: `${basePath}/app-config/srm`,
  appRFI: `${basePath}/app-config/rfi`,
  appSecurity: `${basePath}/app-config/security`,
  appTools: `${basePath}/app-config/tools`,
  loader: `${basePath}/loader`,
  fileUpload: `${basePath}/upload`,
  changeHistory: `${basePath}/change-history`,
  integrations: `${basePath}/app-config/integrations`
}

export default paths
