import React, { Component, ComponentType } from 'react'
import { connect } from 'react-redux'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import {
  removeVetSupplier,
  inviteVetSupplier,
  approveVetSupplier,
  updateVetBuyerSupplier
} from '../../actions'
import createInviteUserActions from 'shared/utils/inviteUser/createActions'
import RootState from 'shared/models/RootState'

const { selectNewUser } = createInviteUserActions('inviteVetSupplierContact')

type StateProps = {
  vetApproval: boolean
  isApprover: boolean
  isOwner: boolean
  missingRequired: Array<string>
  invalidResponseDate?: boolean
  inviteSuppliers: () => void
  removeVetSupplier: () => void
  approveVetSupplier: () => void
  updateVetBuyerSupplier: () => void
  inviteVetSupplier: (param: string) => void
}

const withVetSupplierActions = function<P>(VbsComponent: ComponentType<P>) {
  return connect(
    (state: RootState) => {
      const currentUserId = sessionSelectors.getUserId(state)
      const vetApproval = settingsSelectors.vetApproval(state)
      const currentVet = currentVetSelectors.getVet(state)
      const team = currentVet && currentVet.getIn(['users'])
      const teamLead = team && team.find(user => user.lead)
      const teamLeadId = teamLead && teamLead.user
      const responseDate = currentVet && currentVet.getIn(['responseDate'])

      return {
        missingRequired: currentVetSelectors.getMissingRequiredFields(state),
        invalidResponseDate:
          responseDate && new Date(responseDate) < new Date(),
        vetApproval,
        isApprover: sessionSelectors.userHasRole(state, 'buyerVetApprover'),
        isOwner: teamLeadId === currentUserId,
        inviteSuppliers: currentVetSelectors.getVetField(
          state,
          'inviteSuppliers'
        )
      }
    },
    {
      inviteVetSupplier,
      removeVetSupplier,
      approveVetSupplier,
      updateVetBuyerSupplier,
      selectNewUser
    }
  )(
    // @ts-ignore
    class ModifiedHOC extends Component<P & StateProps> {
      handleInvite = param => {
        const { missingRequired, inviteVetSupplier } = this.props

        if (missingRequired.length === 0) {
          inviteVetSupplier(param)
        }
      }

      render() {
        const {
          vetApproval,
          isApprover,
          isOwner,
          inviteSuppliers,
          removeVetSupplier,
          approveVetSupplier,
          updateVetBuyerSupplier,
          ...restProps
        } = this.props

        return (
          // @ts-ignore
          <VbsComponent
            vetApproval={vetApproval}
            isApprover={isApprover}
            isOwner={isOwner}
            onApproveVetSupplier={
              vetApproval && isApprover ? approveVetSupplier : undefined
            }
            onInviteVetSupplier={
              inviteSuppliers && (isApprover || isOwner)
                ? this.handleInvite
                : undefined
            }
            onRemoveVetSupplier={
              isApprover || isOwner ? removeVetSupplier : undefined
            }
            onUpdateVetSupplier={updateVetBuyerSupplier}
            inviteSuppliers={inviteSuppliers}
            {...restProps}
          />
        )
      }
    }
  )
}

export default withVetSupplierActions
