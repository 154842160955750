import React, { Component } from 'react'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import PageSideSection from 'shared/components/PageSideSection'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import PersonalTags from '../../containers/PersonalTags'
import createTagsForm from 'shared/utils/createTagsForm'
import {
  ADD_PERSONAL_RELATIONSHIP,
  REMOVE_PERSONAL_RELATIONSHIP
} from '../../actionTypes'
import profileSelectors from '../../selectors/profileSelectors'
import CommentsAboutSupplier from '../../containers/CommentsAboutSupplier'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import { FormattedMessage } from 'react-intl'
import ContactsSection from '../ContactsSection'
import Contact from '../../../../shared/models/Contact'
import { Map, RecordOf, List } from 'immutable'
import ReviewsSectionContainer from '../../containers/ReviewsSectionContainer'
import AddAttachmentForm from '../../containers/AddAttachmentForm'
import RelationshipAttachments from '../../containers/RelationshipAttachments'
import SupplierVetsContainer from '../../containers/SupplierVetsContainer'
import ScrollToPosition from 'shared/components/ScrollToPosition'

const PersonalRelationshipsForm = createTagsForm({
  formName: 'buyer/personal/relationshps',
  fieldName: 'attributes',
  addTagActionType: ADD_PERSONAL_RELATIONSHIP,
  removeTagActionType: REMOVE_PERSONAL_RELATIONSHIP,
  tagsSelector: profileSelectors.getPersonalRelationship
})

type Props = {
  hasPersonalEndorsements: boolean
  contacts: List<RecordOf<Contact>>
  isEditingContact: boolean
  isTealbot: boolean
  isBuyerAdmin: boolean
  supplierId: string
  contactToEdit: RecordOf<Contact>
  supplierName: string
  onRemoveContact: (arg: string) => void
  onRemoveContactRelationship: (params: {
    contactId: string
    supplierId: string
  }) => void
  onEditContact: (arg: Contact) => void
  onUpdateContact: (arg: Map<{}, {}>) => void
  onCancelEdit: () => void
  hasAttachments: boolean
}

class PersonalPage extends Component<Props> {
  render() {
    const {
      hasPersonalEndorsements,
      contacts,
      isEditingContact,
      isTealbot,
      isBuyerAdmin,
      supplierId,
      onRemoveContact,
      onRemoveContactRelationship,
      onEditContact,
      onUpdateContact,
      onCancelEdit,
      contactToEdit,
      supplierName,
      hasAttachments
    } = this.props

    return (
      <Page title='Personal Notes'>
        <ScrollToPosition to={300} strict='up' />
        <Main>
          <ReviewsSectionContainer onlyMine />
          <CommentsAboutSupplier onlyMine />

          <PageSection
            title={
              <FormattedMessage
                id='PersonalPage.PersonalRelationship'
                defaultMessage='Personal Relationship'
              />
            }
            noPadding
          >
            <FormattedMessage
              id='PersonalPage.WorkedWithLikeFollowUp'
              defaultMessage='Worked With, Like, Follow Up'
            >
              {message => (
                <PersonalRelationshipsForm
                  addLabel={
                    <FormattedMessage
                      id='PersonalPage.AddWhatDescribesYourRealtionship'
                      defaultMessage='Add what describes your relationship'
                    />
                  }
                  inputPlaceholder={message}
                />
              )}
            </FormattedMessage>
          </PageSection>
          <PageSection
            title={
              <FormattedMessage
                id='PersonalPage.MyContacts'
                defaultMessage='My Contacts'
              />
            }
            noPadding
          >
            <ContactsSection
              isEditable={true}
              contacts={contacts && contacts.toList()}
              isEditingContact={isEditingContact}
              isTealbot={isTealbot}
              isBuyerAdmin={isBuyerAdmin}
              supplierId={supplierId}
              onRemoveContact={onRemoveContact}
              onRemoveContactRelationship={onRemoveContactRelationship}
              onEditContact={onEditContact}
              onUpdateContact={onUpdateContact}
              onCancelEdit={onCancelEdit}
              contactToEdit={contactToEdit}
              supplierName={supplierName}
            />
          </PageSection>
          <PageSection
            noPadding
            title={
              <FormattedMessage
                id='MyCompanyPage.Attachments'
                defaultMessage='Attachments'
              />
            }
          >
            {hasAttachments && (
              <div className='ph3-5 pb3'>
                <RelationshipAttachments onlyMine />
              </div>
            )}
            <AddAttachmentForm />
          </PageSection>
          <ClearbitCredit />
        </Main>

        <Aside>
          <div className='pt4-l'>
            {hasPersonalEndorsements && (
              <PageSideSection
                title={
                  <FormattedMessage
                    id='PersonalPage.PersonalEndorsements'
                    defaultMessage='Personal Endorsements'
                  />
                }
              >
                <div className='mt3'>
                  <PersonalTags />
                </div>
              </PageSideSection>
            )}
          </div>
          <SupplierVetsContainer onlyMine />
        </Aside>
      </Page>
    )
  }
}

export default PersonalPage
