import React, { FunctionComponent, ReactElement, useRef } from 'react'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Link from 'shared/components/Link'

type WrapLinkProps = {
  linkTo: string | undefined
  children: ReactElement
}

const WrapLink = React.forwardRef<HTMLAnchorElement, WrapLinkProps>(
  (props, ref) => {
    const { linkTo, children, ...rest } = props
    return linkTo ? (
      <Link to={linkTo} ref={ref} {...rest}>
        {children}
      </Link>
    ) : (
      props.children
    )
  }
)

type Props = {
  mini?: boolean
  className?: string
  reputation: number
  linkTo?: string
}

const Reputation: FunctionComponent<Props> = props => {
  const ref = useRef<HTMLAnchorElement>(null)
  const getTipText: (score: number) => ReactElement<FormattedMessage> = (
    score: number
  ) => {
    if (score <= 60) {
      return (
        <FormattedMessage
          id='Reputation.Moderate'
          defaultMessage='Moderate: Some references'
        />
      )
    } else if (score <= 80) {
      return (
        <FormattedMessage
          id='Reputation.High'
          defaultMessage='High: Many references'
        />
      )
    } else {
      return (
        <FormattedMessage
          id='Reputation.Highest'
          defaultMessage='Highest: Extensive references'
        />
      )
    }
  }

  return (
    <div
      className={`flex justify-center ${
        props.className ? props.className : 'mt3'
      }`}
    >
      <Tooltip title={getTipText(props.reputation)}>
        <WrapLink linkTo={props.linkTo} ref={ref}>
          <div
            className={classNames(
              'dib br-100 flex items-center justify-center f7 black',
              `${props.linkTo ? 'pointer' : ''}`,
              `${props.mini ? 'h1 w1' : 'h2 w2'}`
            )}
            style={{
              backgroundColor: props.reputation
                ? `rgba(255, 183, 0, ${props.reputation / 100}`
                : 'none'
            }}
            aria-label={`Reputation score: ${props.reputation} out of 100. Link to company references`}
          >
            {props.mini ? '' : props.reputation}
          </div>
        </WrapLink>
      </Tooltip>
    </div>
  )
}

export default Reputation
