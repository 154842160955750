import Immutable, { RecordOf } from 'immutable'
import Location from 'shared/models/Location'

function parseLocationToString(
  location: RecordOf<Location> | Location = { address: '' },
  options: { ignorePostcode?: boolean } = {}
) {
  const { address: defaultAddress, components } = Immutable.isImmutable(
    location
  )
    ? location.toJS()
    : location

  let address
  if (components) {
    const {
      house_number: houseNumber,
      road = '',
      city = '',
      state = '',
      postcode = '',
      country = '',
      unit = '',
      level = '',
      po_box = ''
    } = components
    const addressArr: Array<string> = []
    addressArr.push(
      po_box || houseNumber || road
        ? `${po_box || ''} ${houseNumber || ''} ${road || ''}`
        : ''
    )
    addressArr.push(level)
    addressArr.push(unit)
    addressArr.push(city)
    addressArr.push(state)
    addressArr.push(country)
    if (!options.ignorePostcode) {
      addressArr.push(postcode)
    }
    address = addressArr
      .filter(a => a && !!a.trim())
      .map(a => a.trim())
      .join(', ')
  }

  return address || defaultAddress
}

export default parseLocationToString
