import * as actionTypes from '../../actionTypes'
import { LOGOUT } from 'shared/actionTypes'
import { Record, List } from 'immutable'
import { requestSuccess } from 'shared/actionTypes'

type TabFilterType = {
  availableTabs: List<{ value: string; label: string }>
  currentTab: string
}

export const tabFilterRecord = Record<TabFilterType>({
  availableTabs: List([]),
  currentTab: ''
})

const tabFilterReducer = (state = tabFilterRecord(), action) => {
  let isInTabs
  switch (action.type) {
    case actionTypes.SEARCH_SUPPLIER:
      return !action.payload ? state.set('currentTab', '') : state

    case requestSuccess(actionTypes.SEARCH_SUPPLIER):
      const naics = action.payload?.aggregations?.filterSuggestions?.naics

      return !!naics &&
        (!state.get('currentTab') ||
          state.get('availableTabs').size === 0 ||
          !state
            .get('availableTabs')
            .take(3)
            .find(t => t.value === state.get('currentTab')))
        ? state.set('availableTabs', List(naics))
        : state

    case actionTypes.SET_TAB_FILTER:
      return state.set('currentTab', action.payload)

    case actionTypes.SET_FILTER_OPTIONS:
      const sector = action.payload.sector

      return Array.isArray(sector) && typeof sector[0] === 'string'
        ? state.set('currentTab', sector[0])
        : state

    case actionTypes.TOGGLE_FILTER_OPTION:
      const shallClearCurrentTab =
        action.payload.key === 'sector' &&
        action.payload.value === state.get('currentTab')
      isInTabs = state
        .get('availableTabs')
        .take(3)
        .some(t => t.value === action.payload.value)
      const shallSetCurrentTab = action.payload.key === 'sector' && isInTabs

      return shallClearCurrentTab
        ? state.set('currentTab', '')
        : shallSetCurrentTab
        ? state.set('currentTab', action.payload.value)
        : state

    case actionTypes.REMOVE_FILTER_OPTION:
      return action.payload.key === 'sector' &&
        action.payload.value === state.get('currentTab')
        ? state.set('currentTab', '')
        : state

    case LOGOUT:
      return tabFilterRecord()

    default:
      return state
  }
}

export default tabFilterReducer
