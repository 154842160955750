import React, { Component } from 'react'
import CertificationForm from '../CertificationForm'
import { InjectedFormProps } from 'redux-form'
import { CategoryType } from 'shared/models/CertificationCategories'

/**
 * Extends the AddForm component
 */

type Props = {
  category: CategoryType
  subCategory: string
  certAgency: string
  disableCategories: boolean
  editMode: boolean
} & InjectedFormProps

class AddCertificationForm extends Component<Props> {
  render() {
    const { reset, handleSubmit, ...rest } = this.props

    return (
      <div className='bg-near-white ph3-5 pb3-5'>
        <CertificationForm {...rest} onSubmit={handleSubmit} onCancel={reset} />
      </div>
    )
  }
}

export default AddCertificationForm
