import React, { Component } from 'react'
import { Field } from 'redux-form/immutable'
import createAddForm from 'shared/utils/createAddForm'
import { ADD_CRITERIA } from '../../actionTypes'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import CloseIcon from '@material-ui/icons/Clear'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import storageManager from 'shared/utils/storageManager'

const AddForm = createAddForm({
  formName: 'vet/criteria',
  submitActionType: ADD_CRITERIA
})

type Props = {
  vetId: string
  onChange?: (arg: string) => void
}

class AddCriteriaForm extends Component<Props> {
  state = {
    showHint: false,
    doNotShow: !!storageManager.getItem('doNotShowAddCriteriaHint')
  }

  handleChange = e => {
    const { onChange } = this.props
    const { doNotShow } = this.state

    if (!doNotShow) {
      this.setState({ showHint: e.target.value !== '' })
    }

    if (onChange) {
      onChange(e)
    }
  }

  handleKeyDown = e => {
    const { doNotShow } = this.state
    if (!doNotShow && e.keyCode === 13) {
      this.setState({ showHint: false })
    }
  }

  handleDoNotShow = () => {
    storageManager.setItem('doNotShowAddCriteriaHint', true)
    this.setState({ doNotShow: true })
  }

  render() {
    const { vetId } = this.props
    const { showHint, doNotShow } = this.state

    return (
      <AddForm
        noAddButton
        noActions
        initialValues={{
          vetId
        }}
      >
        <Label>
          <FormattedMessage
            id='AddCriteriaForm.WhatCapabilitiesAndQualifications'
            defaultMessage='What capabilities/qualifications are critical for suppliers to be considered?'
          />
        </Label>
        <FormattedMessage
          id='AddCriteriaForm'
          defaultMessage='Include criteria that are non-negotiable: experience, offerings, capacity.'
        >
          {message => (
            <Field
              component={Input}
              name='criticalCriteria'
              placeholder={message as string}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              required
            />
          )}
        </FormattedMessage>
        {!doNotShow && showHint && (
          <Text className='red mt2 flex items-center'>
            <img
              src={TealbotCircleImage}
              alt='tealbot-tip'
              className='mr3 w2 h2'
            />
            <FormattedMessage
              id='AddCriteriaForm.PressEnterToAddCriteria'
              defaultMessage='Press enter to add criteria.'
            />
            <span
              className='ml1 dim teal pointer bg-light-gray ph1 br2'
              onClick={this.handleDoNotShow}
            >
              <CloseIcon style={{ width: 10, height: 10 }} />
              <FormattedMessage
                id='AddCriteriaForm.DoNotShowAgain'
                defaultMessage='Do not show again'
              />
            </span>
          </Text>
        )}
        <Field name='vetId' component='input' type='hidden' />

        <Text className='mt3'>
          <FormattedMessage
            id='AddCriteriaForm.CriticalCriteriaNote'
            defaultMessage='NOTE: Suppliers will have to agree to all of these criteria to respond to the RFI'
          />
        </Text>
      </AddForm>
    )
  }
}

export default AddCriteriaForm
