import takeFirst from 'shared/utils/sagas/takeFirst'
import buyer from 'shared/utils/api/buyer'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { CREATE_CONTACT_FROM_BUSINESS_CARD } from './actionTypes'

export default function* rootSaga() {
  yield takeFirst(
    CREATE_CONTACT_FROM_BUSINESS_CARD,
    createApiRequestSaga(buyer.createContactFromBusinessCard, {
      notifyOnError: true,
      usePayload: true
    })
  )
}
