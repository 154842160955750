import { createAction } from 'redux-actions'
import {
  LOGIN,
  CONTACTS_UPLOAD,
  SAVE_MOBILE_CONTACTS,
  DISPLAY_WAITING_TO_CREATE_ORG
} from './actionTypes'

export const login = createAction(LOGIN)
export const contactsUpload = createAction(CONTACTS_UPLOAD)
export const saveMobileContacts = createAction(SAVE_MOBILE_CONTACTS)
export const displayWaitingToCreateOrg = createAction(
  DISPLAY_WAITING_TO_CREATE_ORG
)
