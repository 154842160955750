import { call } from 'redux-saga/effects'
import vets from 'shared/utils/api/vets'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* updateCriteriaSaga(action) {
  const { vetId, index, fromCriteria, toCriteria } = action.payload.toJS()

  yield call(vets.updateCriteria, {
    vetId,
    oldCriticalCriteria: fromCriteria,
    newCriticalCriteria: toCriteria
  })

  return yield {
    index,
    fromCriteria,
    toCriteria
  }
}

export default createApiRequestSaga(updateCriteriaSaga)
