import { connect } from 'react-redux'
import SupplierMessageSummary from 'buyer/SupplierProfile/components/SupplierMessageSummary'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import truncate from 'lodash.truncate'
import RootState from 'shared/models/RootState'

type Props = {
  supplierId: string
}

export default connect((state: RootState, props: Props) => {
  const language = state.get('i18n').locale
  const org = orgsSelectors.getById(state, props.supplierId)
  const description = org.get('description')
  const parseDescription =
    !description || typeof description === 'string'
      ? description
      : description.get(language) ||
        description.get('en') ||
        description
          .toList()
          .filter((d: string) => !!d)
          .first()

  return (
    org && {
      name: org.get('name'),
      description: truncate(parseDescription, { length: 150 }),
      websiteUrl: org.get('websiteUrl'),
      logoUrl:
        org.get('logoUrl') ||
        `https://logo.clearbit.com/${org.get('domains').first()}`
    }
  )
})(SupplierMessageSummary)
