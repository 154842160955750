import { call, select } from 'redux-saga/effects'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import searchSelectors from 'buyer/Search/selectors/searchSelectors'
import * as api from 'shared/utils/api/api'
import apiRoutes from 'shared/routes/apiRoutes'

export function* sendSearchFeedbackSaga(action) {
  const { rating, ratingDescription } = action.payload
  const queryString = yield select(routingSelectors.getSearch)
  const userId = yield select(sessionSelectors.getUserId)
  const searchId = yield select(searchSelectors.getSearchId)

  return yield call(
    params => api.post(`${apiRoutes.authService}/feedback/userSearch`, params),
    {
      rating,
      ratingDescription,
      userId,
      searchId,
      queryString: queryString?.substr(1) || 'q='
    }
  )
}

export default createApiRequestSaga(sendSearchFeedbackSaga)
