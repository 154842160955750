import React, { Component } from 'react'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import AddTagForm from '../../../SupplierProfile/components/AddTagForm'
import { ADD_TAG_TO_SUPPLIER } from '../../actionTypes'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import RootState from 'shared/models/RootState'
import searchSelectors from 'buyer/Search/selectors/searchSelectors'

type Props = {
  handleSubmit: (arg: string) => () => string
  addTag: string
  numberOfTags: number
}
export class OfferingsAddTagForm extends Component<Props & InjectedFormProps> {
  render() {
    const { handleSubmit, addTag, numberOfTags } = this.props
    return (
      <FormattedMessage
        id='OfferingsAddTagForm.Skill'
        defaultMessage='Skill (ex: Advertising or Digital Marketing)'
      >
        {message => (
          <AddTagForm
            inputId='offeringssNewTag'
            onSubmit={handleSubmit(addTag)}
            inputPlaceholder={message}
            numberOfTags={numberOfTags}
          />
        )}
      </FormattedMessage>
    )
  }
}

const formName = 'buyer/Search/offerings'

type ContainerProps = {
  supplierId: string
  cardId: string
}
export default connect(
  (state: RootState, props: ContainerProps) => {
    const { tags } = searchSelectors.getMyTags(
      state,
      props.supplierId,
      props.cardId
    )
    return {
      initialValues: {
        supplierId: props.supplierId,
        cardId: props.cardId,
        tagsFieldName: 'offerings'
      },
      numberOfTags: tags && tags.size
    }
  },
  {
    addTag: createSubmitFormAction(formName, ADD_TAG_TO_SUPPLIER)
  }
)(
  reduxForm({
    form: formName
  })(OfferingsAddTagForm)
)
