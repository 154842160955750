import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import RootState from 'shared/models/RootState'
import searchSelectors from '../../selectors/searchSelectors'
import withSearchResultItem from './withSearchResultItem'
import SupplierListItem from '../../../shared/components/SupplierListItem'
import SupplierListItemMini from '../../../shared/components/SupplierListItem/SupplierListItemMini'
import ImageExpandAll from 'shared/assets/icons/expand-all.svg'
import ImageCollapseAll from 'shared/assets/icons/collapse-all.svg'
import { ReactComponent as OrgChart } from 'shared/assets/icons/org-chart.svg'
import { FormattedMessage } from 'react-intl'
import { getAllChildren } from '../../actions'
import ImageArrowDown from 'shared/assets/icons/arrow-down.svg'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'

const SearchResultItem = withSearchResultItem(SupplierListItem)
const SearchResultItemMini = withSearchResultItem(SupplierListItemMini)

type Props = {
  searchId: string
  vetId?: string
  redirectFrom?: string
  index: number
  orgUnit: string
  orgName: string
  numOrgUnits: number
  orgUnits: List<Map<string, any>>
  getAllChildren: (string) => void
  attachmentTypes: Map<string, Map<string, string>>
  relationshipTypes: Map<string, Map<string, string>>
}

export const SearchResultGroup: FC<Props> = ({
  searchId,
  vetId,
  redirectFrom,
  orgUnit,
  orgName,
  orgUnits,
  numOrgUnits,
  getAllChildren,
  attachmentTypes,
  relationshipTypes
}) => {
  const [toggles, setToggles] = useState<object>({})

  const toggleItem = (supplierId: string) => {
    setToggles({
      ...toggles,
      [supplierId]: !toggles[supplierId]
    })
  }

  const expandAll = () => {
    setToggles(
      orgUnits
        .map(orgUnit => orgUnit.getIn(['supplier', 'id']))
        .toJS()
        .reduce((result, id) => {
          result[id] = true
          return result
        }, {})
    )
  }

  const collapseAll = () => {
    setToggles({})
  }

  return numOrgUnits > 1 ? (
    <div className='bg-light-gray pa1'>
      <div className='flex items-center justify-between pt1 pb2 ph2'>
        <OrgChart />
        <div className='flex-auto ml2 f5 fw6'>{orgName}</div>
        <div>
          {Object.values(toggles).includes(true) ? (
            <button
              onClick={collapseAll}
              className='bn bg-transparent'
              aria-expanded={true}
            >
              <img
                src={ImageExpandAll}
                className='v-mid pointer'
                alt='Collapse all related companies'
              />
            </button>
          ) : (
            <button
              onClick={expandAll}
              className='bn bg-transparent'
              aria-expanded={false}
            >
              <img
                src={ImageCollapseAll}
                className='v-mid pointer'
                alt='Expand all related companies'
              />
            </button>
          )}
        </div>
      </div>
      {orgUnits.map((orgUnit, orgIndex) =>
        orgIndex === 0 ? (
          <SearchResultItem
            key={orgUnit.getIn(['supplier', 'id'])}
            supplierId={orgUnit.getIn(['supplier', 'id'])}
            searchId={searchId}
            vetId={vetId}
            redirectFrom={redirectFrom}
            attachmentTypes={attachmentTypes}
            relationshipTypes={relationshipTypes}
          />
        ) : toggles[orgUnit.getIn(['supplier', 'id'])] ? (
          <SearchResultItem
            key={orgUnit.getIn(['supplier', 'id'])}
            supplierId={orgUnit.getIn(['supplier', 'id'])}
            searchId={searchId}
            vetId={vetId}
            redirectFrom={redirectFrom}
            attachmentTypes={attachmentTypes}
            relationshipTypes={relationshipTypes}
          />
        ) : (
          <SearchResultItemMini
            onExpand={toggleItem}
            key={orgUnit.getIn(['supplier', 'id'])}
            supplierId={orgUnit.getIn(['supplier', 'id'])}
            searchId={searchId}
            vetId={vetId}
            redirectFrom={redirectFrom}
            attachmentTypes={attachmentTypes}
            relationshipTypes={relationshipTypes}
          />
        )
      )}
      {numOrgUnits > orgUnits.size && (
        <div className='bg-near-white bb b--black-10 w-100 pointer'>
          <button
            className='flex ph3 pv2 items-center justify-between bn bg-transparent w-100'
            onClick={() => getAllChildren(orgUnit)}
            aria-expanded={false}
          >
            <h5
              className={`f6 ma0 dib fw4 mr2`}
              style={{ wordBreak: 'break-word' }}
            >
              <FormattedMessage
                id='SearchResultGroupContainer.NotMatchSearch'
                defaultMessage='{restOfSiblings} related {restOfSiblings, plural, one {company} other {companies}}'
                values={{
                  restOfSiblings: numOrgUnits - orgUnits.size
                }}
              />
            </h5>
            <div>
              <img src={ImageArrowDown} alt='' />
            </div>
          </button>
        </div>
      )}
    </div>
  ) : (
    <SearchResultItem
      supplierId={orgUnits.getIn([0, 'supplier', 'id'])}
      searchId={searchId}
      vetId={vetId}
      redirectFrom={redirectFrom}
      attachmentTypes={attachmentTypes}
      relationshipTypes={relationshipTypes}
    />
  )
}

export default connect(
  (state: RootState, props: { orgName: string; index: number }) => {
    return {
      orgUnits: searchSelectors.getOrgUnitsByGroupIndex(state, props.index),
      attachmentTypes: settingsSelectors.getAttachmentTypes(state),
      relationshipTypes: settingsSelectors.getRelationshipAttributes(state)
    }
  },
  {
    getAllChildren
  }
)(SearchResultGroup)
