import { call, select } from 'redux-saga/effects'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import posts from 'shared/utils/api/posts'

export function* clearVoteSaga(action) {
  yield call(posts.clearVote, action.payload)
  const userId = yield select(sessionSelectors.getUserId)
  return yield { postId: action.payload, userId }
}

export default createApiRequestSaga(clearVoteSaga)
