import React from 'react'
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts'

const colors = ['#02C0B5', '#FFC33A', '#006098', '#072B48', '#E8173B']

type Props = {
  data: Array<{ name: string; value: number }>
  totalLabel: string
}

const CompletedGauge = (props: Props) => {
  const { data, totalLabel } = props
  const arrowData = data.map(d => ({ value: d.value, name: 'hideLegend' }))
  const totalValue = data.reduce((total, d) => total + d.value, 0)

  const renderLegendText = (value: string, entry: any) => {
    return value !== 'hideLegend' ? (
      <span className='black f7'>{value}</span>
    ) : null
  }

  const Arrow = ({
    cx,
    cy,
    midAngle,
    endAngle,
    maxRadius,
    outerRadius,
    value,
    ...rest
  }) => {
    const width = cx * 2
    const textBoxWidth = 100
    const textBoxHeight = 25
    const RADIAN = Math.PI / 180
    const sin = Math.sin(-RADIAN * endAngle)
    const cos = Math.cos(-RADIAN * endAngle)
    const mx = cx + (outerRadius + width * 0.05) * cos
    const my = cy + (outerRadius + width * 0.05) * sin
    const tx = mx - textBoxWidth / 2
    const ty = my - textBoxHeight
    return (
      <g>
        <line
          x1={cx - outerRadius - 50}
          y1={cy}
          x2={cx + outerRadius + 50}
          y2={cy}
          stroke='#999'
          fill='none'
          strokeLinecap='round'
          strokeWidth={6}
        />
        <circle cx={cx} cy={cy} r={width * 0.02} fill='#222' stroke='none' />
        <path
          id='arrow'
          d={`M${cx},${cy}L${mx},${my}`}
          strokeWidth='4'
          stroke='#222'
          fill='none'
          strokeLinecap='round'
        />
        <svg width={textBoxWidth} height={textBoxHeight} x={tx} y={ty}>
          <text
            x='50%'
            y='50%'
            dominantBaseline='middle'
            textAnchor='middle'
            className='f7'
          >
            {value.toLocaleString()}
          </text>
        </svg>
        <svg
          width={textBoxWidth * 3}
          height={textBoxHeight}
          x={cx - textBoxWidth * 1.5}
          y={cy + 16}
        >
          <text
            x='50%'
            y='50%'
            dominantBaseline='middle'
            textAnchor='middle'
            className='f7'
          >
            {totalLabel}: {totalValue.toLocaleString() || 0}
          </text>
        </svg>
      </g>
    )
  }

  return (
    <ResponsiveContainer width='100%' height={314}>
      <PieChart>
        <Legend
          verticalAlign='top'
          align='left'
          iconType={'circle'}
          formatter={renderLegendText}
          wrapperStyle={{ marginTop: '1rem', marginLeft: '2rem' }}
        />
        <Pie
          data={data}
          dataKey='value'
          cx='50%'
          cy='80%'
          labelLine={false}
          startAngle={180}
          endAngle={0}
          innerRadius='90%'
          outerRadius='110%'
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Pie
          data={arrowData}
          dataKey='value'
          cx='50%'
          cy='80%'
          labelLine={false}
          startAngle={180}
          endAngle={0}
          stroke='none'
          activeIndex={0}
          activeShape={Arrow}
          outerRadius='100%'
          fill='none'
          legendType='none'
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default CompletedGauge
