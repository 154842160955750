import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'
import createReduxAction from 'shared/utils/createReduxAction'

export const searchSupplier = createReduxAction<
  typeof actionTypes.SEARCH_SUPPLIER,
  string,
  {
    vetId?: string
    refKey: string
    queryString?: string
    locationQuery?: string
  }
>(actionTypes.SEARCH_SUPPLIER)

export const clearSearchKeyword = createAction(actionTypes.CLEAR_SEARCH_KEYWORD)
export const addFilterOption = createAction(actionTypes.ADD_FILTER_OPTION)
export const removeFilterOption = createAction(
  actionTypes.REMOVE_FILTER_OPTION,
  (payload, meta) => payload,
  (payload, meta) => meta
)
export const setFilterOptions = createAction(actionTypes.SET_FILTER_OPTIONS)
export const toggleFilterOption = createAction(actionTypes.TOGGLE_FILTER_OPTION)
export const changeFilterOption = createAction(actionTypes.CHANGE_FILTER_OPTION)
export const mergeFilterOption = createAction(actionTypes.MERGE_FILTER_OPTION)

export const updateQueryString = createAction(actionTypes.UPDATE_QUERY_STRING)

export const addCollection = createAction(
  actionTypes.ADD_COLLECTION,
  (payload, meta) => payload,
  (payload, meta) => meta
)
export const removeCollection = createAction(actionTypes.REMOVE_COLLECTION)
export const clearCollection = createReduxAction<
  typeof actionTypes.CLEAR_COLLECTION,
  string
>(actionTypes.CLEAR_COLLECTION)
export const loadCollection = createAction(actionTypes.LOAD_COLLECTION)

export const updateScrollPosition = createAction(
  actionTypes.UPDATE_SCROLL_POSITION
)

export const toggleSearchDetails = createAction(
  actionTypes.TOGGLE_SEARCH_DETAILS
)

export const openAddNoteDialog = createAction(actionTypes.OPEN_ADD_NOTE_DIALOG)
export const openAddTagDialog = createAction(actionTypes.OPEN_ADD_TAG_DIALOG)
export const closeAddTagDialog = createAction(actionTypes.CLOSE_ADD_TAG_DIALOG)
export const addTagToSupplier = createAction(actionTypes.ADD_TAG_TO_SUPPLIER)
export const removeTagFromSupplier = createAction(
  actionTypes.REMOVE_TAG_FROM_SUPPLIER
)
export const removeLabelFromSupplier = createAction(
  actionTypes.REMOVE_LABEL_FROM_SUPPLIER
)

export const updateSupplierReview = createAction(
  actionTypes.UPDATE_SUPPLIER_REVIEW
)
export const closeSupplierReviewDialog = createAction(
  actionTypes.CLOSE_SUPPLIER_REVIEW_DIALOG
)

export const toggleFilterPanel = createAction(actionTypes.TOGGLE_FILTER_PANEL)
export const clearFilterPanels = createAction(actionTypes.CLEAR_FILTER_PANELS)
export const setFilterPanels = createAction(actionTypes.SET_FILTER_PANELS)

export const openFiltersDialog = createAction(actionTypes.OPEN_FILTERS_DIALOG)
export const closeFiltersDialog = createAction(actionTypes.CLOSE_FILTERS_DIALOG)

export const searchLocation = createAction(
  actionTypes.SEARCH_LOCATION,
  payload => '',
  payload => ({ locationQuery: payload })
)

export const inviteSupplier = createAction(actionTypes.INVITE_SUPPLIER)
export const openInviteSupplierDialog = createAction(
  actionTypes.OPEN_INVITE_SUPPLIER_DIALOG
)
export const closeInviteSupplierDialog = createAction(
  actionTypes.CLOSE_INVITE_SUPPLIER_DIALOG
)
export const foundExistingOrgs = createAction(actionTypes.FOUND_EXISTING_ORGS)
export const clearFoundOrgs = createAction(actionTypes.CLEAR_FOUND_ORGS)

export const getAllChildren = createAction(actionTypes.GET_ORG_CHILDREN)

export const clearErrorMessage = createAction(actionTypes.CLEAR_ERROR_MESSAGE)

export const sendSearchFeedback = createAction(actionTypes.SEND_SEARCH_FEEDBACK)

export const loadSowJob = createAction(actionTypes.LOAD_SOW_JOB)
export const setTabFilter = createAction(actionTypes.SET_TAB_FILTER)
