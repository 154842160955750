import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import communities from 'shared/utils/api/community'

export function* answerQuestionSaga(action) {
  const { membershipId, questionId, response } = action.payload

  yield call(communities.updateMembership, {
    membershipId,
    membership: {
      response: {
        modified: {
          date: new Date()
        },
        responses: {
          [questionId]: {
            response,
            question: questionId
          }
        }
      }
    }
  })

  return {
    membershipId,
    questionId,
    response
  }
}

export default createApiRequestSaga(answerQuestionSaga)
