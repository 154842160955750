import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import { FormattedMessage } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'
import RootState from 'shared/models/RootState'
import { getCustomRules } from '../../store/diversityReportSelectors'
import startCase from 'lodash.startcase'

type Props = {
  filteredCountries: List<string>
  filteredCategories: List<string>
  filteredSpendGroups: List<string>
  useSpendGroup: boolean
  customRules?: CustomRulesType
  rulesCount: number
}

export const SpendFiltersSummary = (props: Props) => {
  return (
    <>
      {props.filteredCountries && props.filteredCountries.size > 0 && (
        <Tooltip title={props.filteredCountries.join(', ')} tabIndex={0}>
          <span
            className='dib underline f7 fw4 mid-gray mr3 mb2'
            style={{ cursor: 'default' }}
          >
            <FormattedMessage
              id='SpendFiltersSummary.SelectedCountries'
              defaultMessage='Filtered by Country: {filteredCountries}'
              values={{ filteredCountries: props.filteredCountries.size }}
            />
          </span>
        </Tooltip>
      )}
      {props.useSpendGroup &&
        props.filteredSpendGroups &&
        props.filteredSpendGroups.size > 0 && (
          <Tooltip title={props.filteredSpendGroups.join(', ')} tabIndex={0}>
            <span
              className='dib underline f7 fw4 mid-gray mr3 mb2'
              style={{ cursor: 'default' }}
            >
              <FormattedMessage
                id='SpendFiltersSummary.SelectedSpendGroups'
                defaultMessage='Filtered by Business Unit: {filteredSpendGroups}'
                values={{
                  filteredSpendGroups: props.filteredSpendGroups.size
                }}
              />
            </span>
          </Tooltip>
        )}
      {!props.useSpendGroup &&
        props.filteredCategories &&
        props.filteredCategories.size > 0 && (
          <Tooltip title={props.filteredCategories.join(', ')} tabIndex={0}>
            <span
              className='dib underline f7 fw4 mid-gray mr3 mb2'
              style={{ cursor: 'default' }}
            >
              <FormattedMessage
                id='SpendFiltersSummary.SelectedCategories'
                defaultMessage='Filtered by Category: {filteredCategories}'
                values={{
                  filteredCategories: props.filteredCategories.size
                }}
              />
            </span>
          </Tooltip>
        )}
      {props.rulesCount > 0 && props.customRules && (
        <CustomRulesSummary customRules={props.customRules}>
          <span
            className='dib underline f7 fw4 mid-gray mr3 mb2'
            style={{ cursor: 'default' }}
          >
            <FormattedMessage
              id='SpendFiltersSummary.CustomRules'
              defaultMessage='Custom Rules: {rulesCount}'
              values={{
                rulesCount: props.rulesCount
              }}
            />
          </span>
        </CustomRulesSummary>
      )}
    </>
  )
}

export type CustomRulesType = {
  certAgencies: List<string> | undefined
  subCategories: List<string> | undefined
  countries: List<string> | undefined
  requireAttachmentAgencies: List<string> | undefined
  requireCertAgencies: List<string> | undefined
  excludeMyTeam: 'ignore' | undefined
  includeMyTeam: 'includeExpired' | 'ignore' | undefined
  includeTealbook: 'includeValid' | 'anyAgencies' | undefined
  requireSelfCertified: boolean | undefined
  requireNotVerifiedByTealbook: boolean | undefined
}

type CustomRulesSummaryProps = {
  customRules?: CustomRulesType
  children: ReactElement<any>
}

export const CustomRulesSummary = (props: CustomRulesSummaryProps) => {
  const { customRules = {}, children } = props

  const generateTitleText = () => {
    const ruleKeys = Object.keys(customRules).filter(key => customRules[key])

    return (
      <div className='pb2'>
        {ruleKeys.map(key => {
          switch (key) {
            case 'certAgencies':
              return (
                <div key={key} className='pt3'>
                  <span className='fw6'>
                    <FormattedMessage id='DialogFilter.CertifyingAgencies' />:
                  </span>
                  &nbsp;
                  {customRules[key]?.join(', ')}
                </div>
              )
            case 'subCategories':
              return (
                <div key={key} className='pt3'>
                  <span className='fw6'>
                    <FormattedMessage id='DialogFilter.TypeOfCertificates' />:
                  </span>
                  &nbsp;
                  {customRules[key]?.map(v => v.toUpperCase()).join(', ')}
                </div>
              )
            case 'countries':
              return (
                <div key={key} className='pt3'>
                  <span className='fw6'>
                    <FormattedMessage id='DialogFilter.SpendCountries' />:
                  </span>
                  &nbsp;
                  {customRules[key]?.map(v => startCase(v)).join(', ')}
                </div>
              )
            case 'requireAttachmentAgencies':
              return (
                <div key={key} className='pt3'>
                  <span className='fw6'>
                    <FormattedMessage id='DialogFilter.IncludeAttachment' />:
                  </span>
                  &nbsp;
                  {customRules[key]?.join(', ')}
                </div>
              )
            case 'requireCertAgencies':
              return (
                <div key={key} className='pt3'>
                  <span className='fw6'>
                    <FormattedMessage
                      id='CustomRulesSummary.requireCertAgencies'
                      defaultMessage='Attestation for these Certifying Agencies'
                    />
                    :
                  </span>
                  &nbsp;
                  {customRules[key]?.join(', ')}
                </div>
              )
            case 'excludeMyTeam':
              return (
                <div key={key} className='pt3'>
                  <span className='fw6'>
                    <FormattedMessage id='DialogFilter.CertificatesRejectedMyCompany' />
                    :
                  </span>
                  &nbsp;
                  <FormattedMessage id='DialogFilter.Rejected1' />
                </div>
              )
            case 'includeMyTeam':
              return (
                <div key={key} className='pt3'>
                  <span className='fw6'>
                    <FormattedMessage id='DialogFilter.CertificatesVerifiedMyCompany' />
                    :
                  </span>
                  &nbsp;
                  {customRules[key] === 'anyAgencies' ? (
                    <FormattedMessage id='DialogFilter.MyCompany1' />
                  ) : (
                    <FormattedMessage id='DialogFilter.MyCompany2' />
                  )}
                </div>
              )
            case 'includeTealbook':
              return (
                <div key={key} className='pt3'>
                  <span className='fw6'>
                    <FormattedMessage id='DialogFilter.CertificatesVerifiedTealbook' />
                    :
                  </span>
                  &nbsp;
                  {customRules[key] === 'anyAgencies' ? (
                    <FormattedMessage id='DialogFilter.Tealbook1' />
                  ) : (
                    <FormattedMessage id='DialogFilter.Tealbook2' />
                  )}
                </div>
              )
            case 'requireSelfCertified':
              return (
                <div key={key} className='pt3'>
                  <FormattedMessage
                    id='CustomRulesSummary.requireSelfCertified'
                    defaultMessage='Attestation for Self Certified'
                  />
                </div>
              )
            case 'requireNotVerifiedByTealbook':
              return (
                <div key={key} className='pt3'>
                  <FormattedMessage
                    id='CustomRulesSummary.requireNotVerifiedByTealbook'
                    defaultMessage='Attestation where Certificates not verified by TealBook'
                  />
                </div>
              )
            default:
              return null
          }
        })}
      </div>
    )
  }

  return <Tooltip title={generateTitleText()}>{children}</Tooltip>
}

export default connect((state: RootState) => {
  const customRules = getCustomRules(state)
  const rulesCount = !!customRules
    ? Object.keys(customRules).filter(key => !!customRules[key]).length
    : 0

  return {
    filteredCountries: state.getIn(['buyer', 'insights', 'spend', 'countries']),
    filteredCategories: state.getIn([
      'buyer',
      'insights',
      'spend',
      'categories'
    ]),
    filteredSpendGroups: state.getIn([
      'buyer',
      'insights',
      'spend',
      'spendgroups'
    ]),
    useSpendGroup: state.getIn(['buyer', 'insights', 'spend', 'useSpendGroup']),
    customRules: getCustomRules(state),
    rulesCount
  }
})(SpendFiltersSummary)
