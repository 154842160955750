import { all, takeEvery } from 'redux-saga/effects'
import takeFirst from 'shared/utils/sagas/takeFirst'
import * as actionTypes from '../actionTypes'
import createPostSaga from './createPostSaga'
import loadPostsSaga from './loadPostsSaga'
import addCommentSaga from './addCommentSaga'
import deleteCommentSaga from './deleteCommentSaga'
import upVoteSaga from './upVoteSaga'
import clearVoteSaga from './clearVoteSaga'
import deletePostSaga from './deletePostSaga'
import markPostReadSaga from './markPostReadSaga'
import getRecentSuppliersSaga from './getRecentSuppliersSaga'
import loadPostsByTag from './loadPostsByTagSaga'
import getRecentSuppliersTagsSaga from './getRecentSuppliersTagsSaga'
import getSupplierCommentsAndTagsForExportSaga from './getSupplierCommentsAndTagsForExportSaga'

export default function* rootSaga() {
  yield all([
    takeFirst(actionTypes.CREATE_POST, createPostSaga),
    takeEvery(actionTypes.LOAD_POSTS, loadPostsSaga),
    takeFirst(actionTypes.DELETE_POST, deletePostSaga),
    takeFirst(actionTypes.ADD_POST_COMMENT, addCommentSaga),
    takeFirst(actionTypes.DELETE_POST_COMMENT, deleteCommentSaga),
    takeFirst(actionTypes.UP_VOTE, upVoteSaga),
    takeFirst(actionTypes.CLEAR_VOTE, clearVoteSaga),
    takeFirst(actionTypes.MARK_POST_READ, markPostReadSaga),
    takeFirst(actionTypes.GET_RECENT_SUPPLIERS, getRecentSuppliersSaga),
    takeFirst(actionTypes.LOAD_POST_BY_TAG, loadPostsByTag),
    takeFirst(
      actionTypes.GET_RECENT_SUPPLIERS_TAGS,
      getRecentSuppliersTagsSaga
    ),
    takeFirst(
      actionTypes.GET_SUPPLIER_COMMENTS_AND_TAGS_FOR_EXPORT,
      getSupplierCommentsAndTagsForExportSaga
    )
  ])
}
