import React, { Component, FormEvent } from 'react'
import SearchBar from '../../components/SearchBar'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { SEARCH_FOR_ORGANIZATION } from '../../store/actionTypes'
import connectReduxForm from 'shared/utils/connectReduxForm'
import orgsSelectors from '../../store/selectors/orgsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { fromJS } from 'immutable'

type Props = {
  q: string
  searchBuyers: boolean
  isClientConcierge: boolean
  isSupplierConcierge: boolean
  hideClientSearch: boolean
  handleSubmit: (
    onSubmit: (submit: { q: string; searchBuyers: string }) => void
  ) => (event: FormEvent<HTMLFormElement>) => void
  onSubmit: (submit: { q: string; searchBuyers: string }) => void
  searchExactDomain: boolean
}
export class SearchBarContainer extends Component<Props> {
  handleChangeSearchClients = () => {
    const { onSubmit, searchBuyers, q } = this.props

    onSubmit(
      fromJS({
        q,
        searchBuyers: !searchBuyers
      })
    )
  }
  handleChangeSearchByDomain = () => {
    const { onSubmit, searchExactDomain, q } = this.props
    onSubmit(
      fromJS({
        q,
        searchExactDomain: !searchExactDomain
      })
    )
  }

  render() {
    const { handleSubmit, onSubmit, ...rest } = this.props
    return (
      <SearchBar
        onSearch={handleSubmit(onSubmit)}
        onChangeSearchClients={this.handleChangeSearchClients}
        onChangeSearchExactDomain={this.handleChangeSearchByDomain}
        {...rest}
      />
    )
  }
}

const formName = 'dataPortal/searchSupplier'

export default connectReduxForm(
  state => ({
    initialValues: {
      q: orgsSelectors.getQ(state),
      searchBuyers: orgsSelectors.getSearchBuyers(state),
      searchExactDomain: orgsSelectors.getSearchExactDomain(state)
    },
    q: orgsSelectors.getQ(state),
    searchBuyers: orgsSelectors.getSearchBuyers(state),
    isClientConcierge: sessionSelectors.userHasRole(state, [
      'clientConcierge',
      'orgAdmin'
    ]),
    isSupplierConcierge: sessionSelectors.userHasRole(state, [
      'supplierConcierge',
      'orgAdmin'
    ]),
    searchExactDomain: orgsSelectors.getSearchExactDomain(state)
  }),
  {
    onSubmit: createSubmitFormAction(formName, SEARCH_FOR_ORGANIZATION)
  },
  {
    form: formName,
    enableReinitialize: true
  },
  SearchBarContainer
)
