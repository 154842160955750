import React, { ChangeEvent, useState } from 'react'
import Divider from 'shared/components/Divider'
import Input from 'shared/components/Input'
import Select from 'shared/components/Select'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import withStyles from '@material-ui/styles/withStyles'
import Button from 'shared/components/Button'

const StyleCheckbox = withStyles({
  root: {
    padding: 3
  }
})(Checkbox)

export type LocationType = {
  name: string
  address: string
  phone: string
  type: string
  primary: boolean
}

type EditLocationProps = {
  onUpdate: (l: LocationType) => void
  onCancel?: () => void
} & Partial<LocationType>

export const EditLocation = (props: EditLocationProps) => {
  const [name, setName] = useState<string>(props.name || '')
  const [address, setAddress] = useState<string>(props.address || '')
  const [type, setType] = useState<string>(props.type || '')
  const [phone, setPhone] = useState<string>(props.phone || '')
  const [primary, setPrimary] = useState<boolean>(props.primary || false)

  const handleSave = () => {
    props.onUpdate({
      name,
      address,
      type,
      phone,
      primary
    })
  }

  return (
    <div>
      <div className={'flex items-top mb1'}>
        <label
          htmlFor='name'
          className={`f7 fw6 lh-copy mid-gray pt2`}
          style={{ flex: '0 0 120px' }}
        >
          Name
        </label>
        <Input
          name='name'
          wrapperClassName='w-100'
          placeholder='Location Name'
          value={name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setName(e.currentTarget.value)
          }
        />
      </div>
      <div className={'flex items-top mb1'}>
        <label
          htmlFor='address'
          className={`f7 fw6 lh-copy mid-gray pt2`}
          style={{ flex: '0 0 120px' }}
        >
          Address
        </label>
        <Input
          name='address'
          wrapperClassName='w-100'
          placeholder='eg. 38 Camden Street Toronto, ON, Canada M5V 1V1'
          value={address}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress(e.currentTarget.value)
          }
        />
      </div>
      <div className={'flex items-top mb1'}>
        <label
          htmlFor='type'
          className={`f7 fw6 lh-copy mid-gray pt2`}
          style={{ flex: '0 0 120px' }}
        >
          Type
        </label>
        <Select
          id='type'
          value={type}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setType(e.currentTarget.value)
          }
        >
          <option value=''>Location Type</option>
          <option value='headquarters'>Headquarters</option>
          <option value='remittance'>Remittance</option>
          <option value='retailOffice'>Retail Office</option>
          <option value='distributionCenter'>Distribution Center</option>
          <option value='regionalOffice'>Regional Office</option>
          <option value='warehouse'>Warehouse</option>
          <option value='salesOffice'>Sales Office</option>
        </Select>
      </div>
      <div className={'flex items-top mb1'}>
        <label
          htmlFor='phone'
          className={`f7 fw6 lh-copy mid-gray pt2`}
          style={{ flex: '0 0 120px' }}
        >
          Phone
        </label>
        <Input
          name='phone'
          wrapperClassName='w-100'
          value={phone}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPhone(e.currentTarget.value)
          }
        />
      </div>
      <div className='flex items-center justify-between'>
        <FormControlLabel
          style={{ marginLeft: 120 }}
          label='Primary'
          control={
            <StyleCheckbox
              aria-label='primary location'
              name='primary'
              checked={primary}
              color='primary'
              size='small'
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPrimary(e.currentTarget.checked)
              }
            />
          }
        />
        <div>
          <Button
            aria-label='Done'
            autoSize
            size='small'
            disabled={!address}
            onClick={handleSave}
          >
            Done
          </Button>
          {props.onCancel && (
            <Button
              aria-label='Cancel'
              autoSize
              secondary
              size='small'
              className='ml2'
              onClick={props.onCancel}
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
      <Divider className='mv2' />
    </div>
  )
}

export default EditLocation
