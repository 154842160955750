import { connect } from 'react-redux'
import OurTeamSection from '../../components/OurTeamSection'
import companySelectors from '../../../shared/selectors/companySelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { removeContact } from '../../actions'
import RootState from 'shared/models/RootState'
import usersSelectors from 'shared/selectors/usersSelectors'
import { createSelector } from 'reselect'

type Props = {
  location?: any
}

const getSupplierContacts = createSelector(
  companySelectors.getSupplierContacts,
  usersSelectors.getUsers,
  (contacts, users) => {
    return contacts.filter(
      contact =>
        !!contact.get('user') &&
        !!users.get(contact.get('user')) &&
        !users.get(contact.get('user'))?.get('blocked')
    )
  }
)

export default connect(
  (state: RootState, props: Props) => {
    const isTealbot: boolean = sessionSelectors.userHasRole(state, 'tealbot')
    const isAdmin: boolean = sessionSelectors.userHasRole(
      state,
      'supplierAdmin'
    )
    return {
      contacts: getSupplierContacts(state),
      isEmailDomain: companySelectors.getCompanyField(state, 'isEmailDomain'),
      isTealbot,
      isAdmin
    }
  },
  {
    onRemoveContact: removeContact
  }
)(OurTeamSection)
