import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import suppliers from 'shared/utils/api/suppliers'
import fileToUrl from 'shared/utils/data/fileToUrl'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Scrollable from 'shared/components/Scrollable'
import MenuList from '@material-ui/core/MenuList'
import Loading from 'shared/components/Loading/Loading'
import Input from 'shared/components/Input'
import Text from 'shared/components/Text'
import AddIcon from '@material-ui/icons/AddCircle'
import ListItem from '@material-ui/core/ListItem'
import useThemeColors from 'shared/utils/useThemeColors'
import OrgUnit from './OrgUnit'
import { addJobRow, addNewOrg } from '../../actions'
import jobsSelectors from '../../selectors/jobsSelectors'
import RootState from 'shared/models/RootState'

type Props = {
  jobId: string
}

const SowLookup = ({ jobId }: Props) => {
  const colors = useThemeColors()
  const dispatch = useDispatch()
  const anchorElRef = useRef<HTMLInputElement>(null)
  const numAcceptedOrgs = useSelector(
    (state: RootState) => jobsSelectors.getSowAcceptList(state)?.length || 0
  )

  const [searchString, setSearchString] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [suggestedSuppliers, setSuggestedSuppliers] = useState<Array<any>>([])
  const [debounceSearchString] = useDebounce(searchString, 250)

  const showPopover = isLoading || debounceSearchString.length > 2

  useEffect(() => {
    if (!!debounceSearchString && debounceSearchString.length > 2) {
      setIsLoading(true)

      const searchParams = {
        q: debounceSearchString,
        limit: 10
      }
      suppliers.suggestSuppliers(searchParams).then(response => {
        setIsLoading(false)
        const cleanSuppliers = response.map(({ logo, ...rest }) => ({
          logoUrl: fileToUrl(logo),
          ...rest
        }))
        setSuggestedSuppliers(cleanSuppliers)
      })
    }
  }, [debounceSearchString])

  const handleNewSupplierClick = () => {
    setSearchString('')
    dispatch(addNewOrg())
  }

  const handleSupplierClick = (orgUnitId: string) => {
    dispatch(addJobRow({ jobId, orgUnitId, rowNum: numAcceptedOrgs }))
    setSearchString('')
  }

  return (
    <div className='pa3 pr0-l'>
      <Input
        aria-label='Search'
        placeholder={'Search for a supplier'}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setSearchString(e.currentTarget.value)
        }
        value={searchString}
        input={{
          ref: anchorElRef
        }}
      />
      <Popper
        className='br1 ba b--black-10 bg-white'
        style={{ zIndex: 9999 }}
        transition
        placement='bottom-start'
        open={showPopover}
        anchorEl={anchorElRef.current}
        modifiers={{
          flip: {
            enabled: true
          }
        }}
      >
        <ClickAwayListener
          mouseEvent={showPopover && 'onMouseUp'}
          onClickAway={() => setSearchString('')}
        >
          <Paper>
            {isLoading ? (
              <div className='w5 mv3'>
                <Loading />
              </div>
            ) : (
              <Scrollable maxHeight={300}>
                <MenuList>
                  {suggestedSuppliers.map(supplier => (
                    <ListItem
                      key={supplier.id}
                      button
                      onClick={() => handleSupplierClick(supplier.id)}
                    >
                      <OrgUnit
                        name={supplier.name}
                        logoUrl={supplier.logoUrl}
                        websiteUrl={supplier.websiteUrl}
                      />
                    </ListItem>
                  ))}
                  <ListItem onClick={handleNewSupplierClick} button>
                    <div className='flex items-center mv2'>
                      <AddIcon
                        fontSize='small'
                        color='primary'
                        className='mr2'
                      />
                      <Text className={colors.primaryText}>
                        Add a new supplier
                      </Text>
                    </div>
                  </ListItem>
                </MenuList>
              </Scrollable>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export default SowLookup
