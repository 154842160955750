import { all, call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import users from 'shared/utils/api/users'

export function* bulkUpdateRolesSaga(action) {
  const { updatedUsers, missingDefaultRole } = action.payload

  const newRoles = Object.entries<{ roles: string[] }>(updatedUsers)
  yield all(
    newRoles.map(([id, user]) => {
      return call(users.updateUserRoles, id, user.roles)
    })
  )

  const bulkErrorMessage =
    missingDefaultRole && !!missingDefaultRole.length
      ? {
          activity: 'roles',
          listOfUsersWithErrors: missingDefaultRole
        }
      : undefined

  return yield { updatedUsers, bulkErrorMessage }
}

export default createApiRequestSaga(bulkUpdateRolesSaga)
