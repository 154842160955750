import React, { Component } from 'react'
import { connect } from 'react-redux'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import VetInvitationsPage, {
  VetInvitationProps
} from '../../components/VetInvitationsPage'
import { removeCriteria, updateVet, removeAttachment } from '../../actions'
import Loading from 'shared/components/Loading'
import { RecordOf } from 'immutable'

type Props = {
  vet: RecordOf<VetInvitationProps>
  updateVet: (vetId: string) => void
  isLoading: boolean
  removeCriteria: () => void
  removeAttachment: () => void
  readonly: boolean
}

export class VetInvitationsPageContainer extends Component<Props> {
  handleUpdateVet = changes => {
    const { vet, updateVet } = this.props
    updateVet({
      vetId: vet.get('id'),
      ...changes
    })
  }

  render() {
    const {
      vet,
      isLoading,
      removeCriteria,
      removeAttachment,
      readonly
    } = this.props
    return isLoading ? (
      <Loading />
    ) : (
      <VetInvitationsPage
        vet={vet}
        readonly={readonly}
        onRemoveCriteria={removeCriteria}
        onUpdateVet={this.handleUpdateVet}
        onRemoveAttachment={removeAttachment}
      />
    )
  }
}

export const mapStateToProps = state => {
  const vet = currentVetSelectors.getVet(state)
  return {
    vet,
    isLoading: currentVetSelectors.isLoading(state),
    readonly:
      currentVetSelectors.isReadonly(state) ||
      currentVetSuppliersSelectors.hasInvited(state)
  }
}

export default connect(mapStateToProps, {
  updateVet,
  removeCriteria,
  removeAttachment
})(VetInvitationsPageContainer)
