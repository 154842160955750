import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

const createActions = prefix => ({
  cancelInvitingUser: createAction(
    `${prefix}/${actionTypes.CANCEL_INVITING_USER}`
  ),
  selectNewUser: createAction(
    `${prefix}/${actionTypes.SELECT_NEW_USER}`,
    (payload, meta) => payload,
    (payload, meta) => meta
  ),
  selectUser: createAction(`${prefix}/${actionTypes.SELECT_USER}`)
})

export default createActions
