import { Map, fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { requestSuccess, LOGOUT } from 'shared/actionTypes'

const defaultState = Map({
  data: Map({}),
  isFetching: false,
  errorMessage: ''
})

const vetSuppliersByVetReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.ADD_INTERNAL_COMMENT_SUCCESS:
      return state.updateIn(
        ['data', action.payload.vetBuyerSupplierId, 'vetBS', 'comments'],
        comments => comments.push(fromJS(action.payload.newNote))
      )

    case requestSuccess(actionTypes.REMOVE_INTERNAL_COMMENT):
      return state.updateIn(
        ['data', action.payload.vetBuyerSupplierId, 'vetBS', 'comments'],
        comments => comments.delete(action.payload.index)
      )

    case requestSuccess(actionTypes.UPDATE_INTERNAL_COMMENT):
      return state.updateIn(
        ['data', action.payload.vetBuyerSupplierId, 'vetBS', 'comments'],
        comments =>
          comments.set(action.payload.index, fromJS(action.payload.note))
      )

    case actionTypes.LOAD_VET_SUCCESS:
      return state.set('data', fromJS(action.payload.vetBuyerSupplier))

    case actionTypes.ADD_VET_SUPPLIER:
    case actionTypes.APPROVE_VET_SUPPLIER:
    case actionTypes.INVITE_VET_SUPPLIER:
    case actionTypes.CLEAR_ERROR_MESSAGE:
      return state.set('errorMessage', '')

    case actionTypes.ADD_VET_SUPPLIER_SUCCESS:
    case actionTypes.APPROVE_VET_SUPPLIER_SUCCESS:
    case actionTypes.INVITE_VET_SUPPLIER_SUCCESS:
      return state.mergeIn(['data'], fromJS(action.payload.vetBuyerSupplier))

    case actionTypes.INVITE_VET_SUPPLIER_FAILURE:
      return state.set(
        'errorMessage',
        'Only users with the BuyerVetApprover role are allowed to invite suppliers.'
      )

    case actionTypes.REMOVE_VET_SUPPLIER_SUCCESS:
      return state.deleteIn(['data', action.payload.vetBuyerSupplierId])

    case requestSuccess(actionTypes.UP_VOTE):
    case requestSuccess(actionTypes.DOWN_VOTE):
      return state.mergeIn(
        ['data', action.payload.vetBuyerSupplierId, 'vetBS'],
        fromJS(action.payload.voteResults)
      )

    case requestSuccess(actionTypes.CHOOSE_VET_SUPPLIER):
    case requestSuccess(actionTypes.REJECT_VET_SUPPLIER):
      return state.mergeIn(
        ['data', action.payload.vetBuyerSupplierId, 'vetBS', 'collaboration'],
        fromJS(action.payload.collaboration)
      )

    case requestSuccess(actionTypes.UPDATE_VET_BUYER_SUPPLIER):
      const { vetBuyerSupplierId, responseFile, ...rest } = action.payload

      return responseFile
        ? state.setIn(
            ['data', vetBuyerSupplierId, 'vetBS', 'responseFile'],
            fromJS(responseFile)
          )
        : state.mergeIn(['data', vetBuyerSupplierId, 'vetBS'], fromJS(rest))

    case requestSuccess(actionTypes.REMOVE_RESPONSE_FILE):
      return state.updateIn(
        ['data', action.payload.vetBuyerSupplierId, 'vetBS', 'responseFile'],
        responseFile => {
          const fileIndex = responseFile
            ? responseFile.findIndex(
                file => file.get('fileName') === action.payload.fileName
              )
            : -1
          return fileIndex !== -1
            ? responseFile.delete(fileIndex)
            : responseFile
        }
      )

    case requestSuccess(actionTypes.ADD_CONTACT_SEND_TO):
    case requestSuccess(actionTypes.REMOVE_CONTACT_SEND_TO):
      if (!action.payload.vetBuyerSupplierId) {
        return state
      }
      // update list of users related to vet
      let newState = state
      if (action.payload.newUser) {
        newState = newState.setIn(
          [
            'data',
            action.payload.vetBuyerSupplierId,
            'users',
            action.payload.newUser.id
          ],
          fromJS(action.payload.newUser)
        )
      }

      // update list of contacts for vet
      return newState.setIn(
        [
          'data',
          action.payload.vetBuyerSupplierId,
          'vetBS',
          'collaboration',
          'sendToContacts'
        ],
        fromJS(action.payload.sendToContacts)
      )
    case requestSuccess(actionTypes.UPDATE_CONTACT_SEND_TO):
      return action.payload.vetBuyerSupplierId
        ? state.updateIn(
            [
              'data',
              action.payload.vetBuyerSupplierId,
              'vetBS',
              'collaboration',
              'sendToContacts'
            ],
            contacts => {
              const { user1, user2 } = action.payload
              return contacts.map(contact => {
                return user1 && contact.get('user') === user1.user
                  ? fromJS(user1)
                  : user2 && contact.get('user') === user2.user
                  ? fromJS(user2)
                  : contact
              })
            }
          )
        : state
    case LOGOUT:
      return defaultState

    default:
      return state
  }
}

export default vetSuppliersByVetReducer
