import React from 'react'
import { Route, Switch, Redirect } from 'react-router'
import { NavLink } from 'shared/components/AppBar'
import Divider from 'shared/components/Divider'
import NavBar from 'shared/components/NavBar'
import paths from '../../routes/paths'
import MlContainer from './MlContainer'
import SerpContainer from './SerpContainer'

const SupplierMatchingContainer = () => {
  return (
    <>
      <div className='bg-white pl4'>
        <NavBar className='mw8 center'>
          <NavLink label='SERP' to={paths.supplierMatchingSerp} />
          <NavLink label='ML' to={paths.supplierMatchingMl} />
        </NavBar>
      </div>

      <Divider />

      <Switch>
        <Route
          exact
          path={paths.supplierMatchingSerp}
          component={SerpContainer}
        />
        <Route exact path={paths.supplierMatchingMl} component={MlContainer} />
        <Redirect from={paths.basePath} to={paths.supplierMatchingSerp} />
      </Switch>
    </>
  )
}

export default SupplierMatchingContainer
