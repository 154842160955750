import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as Step1 } from './signup.svg'
import { ReactComponent as Step2 } from './add-cert.svg'
import { ReactComponent as Step3 } from './add-agency.svg'
import { ReactComponent as Step4 } from './dbe.svg'
import { ReactComponent as Step5 } from './location.svg'
import { ReactComponent as Step6 } from './verify.svg'
import Divider from 'shared/components/Divider'
import Text from 'shared/components/Text'

const SelfCertify = () => {
  return (
    <div className='mw8 center ph3 pt2 pb4 pb5-ns'>
      <ol className='list pa0 flex-ns justify-between flex-wrap'>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h3 className='mb0 f4'>1.</h3>
            <Step1 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='SelfCertify.Instructions1'
                defaultMessage={`Sign up for a free TealBook account with your email, log in, and navigate to your Profile page.`}
              />
            </p>
          </div>
        </li>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h3 className='mb0 f4'>2.</h3>
            <Step2 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='SelfCertify.Instructions2'
                defaultMessage={`Head to the Qualifications section, and in the Add a Certification drop down menu, select Diversity.`}
              />
            </p>
          </div>
        </li>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h3 className='mb0 f4'>3.</h3>
            <Step3 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='SelfCertify.Instructions3'
                defaultMessage={`Under Certification Agency, select Self Certify.`}
              />
            </p>
          </div>
        </li>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h3 className='mb0 f4'>4.</h3>
            <Step4 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='SelfCertify.Instructions4'
                defaultMessage={`From the Name of Certification drop down menu, select the appropriate Certification Name.`}
              />
            </p>
          </div>
        </li>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h3 className='mb0 f4'>5.</h3>
            <Step5 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='SelfCertify.Instructions5'
                defaultMessage={`Fill in additional details including your certifying location (out of locations currently listed on your Supplier Profile), and the certification subtype (if applicable).`}
              />
            </p>
          </div>
        </li>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h3 className='mb0 f4'>6.</h3>
            <Step6 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='SelfCertify.Instructions6'
                defaultMessage={`Verify that the certified information is correct, and then click the Add button. Once you have clicked Add, you will see a link to a downloadable certificate generated by TealBook.`}
              />
            </p>
          </div>
        </li>
      </ol>
      <Divider className='mv4' />
      <Text>
        <FormattedMessage
          id='SelfCertify.Footer1'
          defaultMessage={`1. Our certificates are valid for one year, and require a re-certification process annually.`}
        />
      </Text>
      <Text>
        <FormattedMessage
          id='SelfCertify.Footer2'
          defaultMessage={`2. The Self Certification process is only available for a Diversity Qualification, and not the other Qualification types (i.e. Quality, Information Security, Social Responsibility and Sustainability, and Food).`}
        />
      </Text>
    </div>
  )
}

export default SelfCertify
