import { call } from 'redux-saga/effects'
import insights from 'shared/utils/api/insight'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* loadSpendSaga(action) {
  const { diverse, preferred, ...rest } = action.payload
  return yield call(insights.loadBuyerSpend, {
    diverse,
    preferred,
    ...rest
  })
}

export default createApiRequestSaga(loadSpendSaga)
