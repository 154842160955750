import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call } from 'redux-saga/effects'
import cards from 'shared/utils/api/cards'
import Immutable from 'immutable'

export function* removeContactRelationshipSaga(action) {
  const { contactId, supplierId } = Immutable.isImmutable(action.payload)
    ? action.payload.toJS()
    : action.payload
  yield call(cards.deleteContactConnections, supplierId, {
    contactId
  })
  return yield {
    contactId,
    supplierId
  }
}

export default createApiRequestSaga(removeContactRelationshipSaga)
