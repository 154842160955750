import React, { useState, useMemo, ChangeEvent } from 'react'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'
import insightsSelectors from 'buyer/Insights/store/insightsSelectors'
import Input from 'shared/components/Input'
import Text from 'shared/components/Text'
import Paper from 'shared/components/Paper'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import { List } from 'immutable'
import dateFormat from 'shared/utils/dateFormat'
import Link from 'shared/components/Link'
import parsePath from 'shared/utils/parsePath'
import paths from 'buyer/routes/paths'
import useThemeColors from 'shared/utils/useThemeColors'
import { useLocation } from 'react-router-dom'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg'
import { useDispatch } from 'react-redux'
import { updateEsgFilterValue } from '../../store/actions'

export const messages = defineMessages({
  filterPlaceholder: {
    id: 'CompletedSuppliers.FilterPlaceholder',
    defaultMessage: 'Search for a Supplier'
  }
})

const CompletedSuppliers = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const colors = useThemeColors()
  const location = useLocation()

  const completedSuppliers = useSelector(
    insightsSelectors.getSustainabilityReportCompletedSuppliers
  )
  const filterValue = useSelector(insightsSelectors.getEsgReportFilterValue)

  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')
  const [sortBy, setSortBy] = useState<'name' | 'userName' | 'attestedDate'>(
    'name'
  )

  const handleFilterValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateEsgFilterValue(e.currentTarget.value))
  }

  const sortedSuppliers: List<any> = useMemo(() => {
    return completedSuppliers
      ?.filter(supplier => {
        return (
          !filterValue ||
          supplier
            .get('name')
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        )
      })
      .sort((supplier1, supplier2) => {
        let sortValue1, sortValue2

        if (sortBy === 'attestedDate') {
          sortValue1 = new Date(supplier1.get('attestedDate'))
          sortValue2 = new Date(supplier2.get('attestedDate'))
        } else {
          sortValue1 = supplier1.get(sortBy)
          sortValue2 = supplier2.get(sortBy)
        }

        if (sortDirection === 'ASC') {
          return sortValue1 > sortValue2 ? 1 : -1
        } else {
          return sortValue1 < sortValue2 ? 1 : -1
        }
      })
  }, [completedSuppliers, sortBy, sortDirection, filterValue])

  const getRow = ({ index }) => {
    return (
      sortedSuppliers && sortedSuppliers.size > 0 && sortedSuppliers.get(index)
    )
  }

  const handleSortChange = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  return completedSuppliers?.size > 0 ? (
    <>
      <div className='flex justify-between mb3'>
        <div className='w-50'>
          <Input
            ariaLabel='Search'
            value={filterValue}
            onChange={handleFilterValueChange}
            placeholder={intl.formatMessage(messages.filterPlaceholder)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className='w50'></div>
      </div>
      <Paper noPadding>
        <Table
          rowGetter={getRow}
          rowCount={sortedSuppliers.size}
          sort={handleSortChange}
          sortDirection={sortDirection}
          sortBy={sortBy}
        >
          <Column
            label={
              <FormattedMessage
                id='CompletedSuppliers.SupplierName'
                defaultMessage='Supplier Name'
              />
            }
            dataKey='name'
            width={300}
          />
          <Column
            label={
              <FormattedMessage
                id='CompletedSuppliers.CompletionDate'
                defaultMessage='Completion Date'
              />
            }
            dataKey='attestedDate'
            width={150}
            cellRenderer={({ cellData }: TableCellProps) =>
              dateFormat(cellData)
            }
          />
          <Column
            label={
              <FormattedMessage
                id='CompletedSuppliers.CompletionBy'
                defaultMessage='Completion By'
              />
            }
            dataKey='userName'
            width={300}
          />
          <Column
            disableSort
            label={
              <FormattedMessage
                id='CompletedSuppliers.Action'
                defaultMessage='Action'
              />
            }
            dataKey='id'
            width={150}
            cellRenderer={({ cellData }: TableCellProps) => (
              <div>
                <Link
                  to={`${parsePath(paths.supplierHealthSafety, {
                    supplierId: cellData
                  })}?redirectFrom=${location.pathname}`}
                  className={colors.primaryText}
                >
                  <FormattedMessage
                    id='CompletedSuppliers.ViewResponses'
                    defaultMessage='View Responses'
                  />
                </Link>
              </div>
            )}
          />
        </Table>
        {!sortedSuppliers.size && (
          <Text className='tc pv3'>
            <FormattedMessage
              id='CompletedSuppliers.NoMatch'
              defaultMessage='Your search did not match any suppliers'
            />
          </Text>
        )}
      </Paper>
    </>
  ) : null
}

export default CompletedSuppliers
