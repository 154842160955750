import React from 'react'
import PageSection from 'shared/components/PageSection'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import surveySelectors from '../../../Survey/selectors/surveySelectors'
import ParticipateSurvey from './ParticipateSurvey'
import SurveyResponse from './SurveyResponse'
import RootState from 'shared/models/RootState'

const SustainabilitySection = () => {
  const surveyId = useSelector(surveySelectors.getEsgSurveyId)
  const survey = useSelector((state: RootState) =>
    surveySelectors.getSurveyById(state, surveyId)
  )
  const attestedDate = !!survey?.get('attestedDate')

  return (
    <PageSection
      title={
        <FormattedMessage
          id='SustainabilitySection.SustainabilityTitle'
          defaultMessage='Sustainability Survey'
        />
      }
    >
      {!attestedDate && <ParticipateSurvey inProgress={!!surveyId} />}
      {!!attestedDate && <SurveyResponse />}
    </PageSection>
  )
}

export default SustainabilitySection
