import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  ReactNode
} from 'react'
import imgShader from 'shared/assets/images/shader.svg'
import imgShaderReverse from 'shared/assets/images/shader-reverse.svg'

type Props = {
  maxHeight?: number
  scroll?: boolean
  children?: ReactNode
  labelledBy?: string
}

const Scrollable = (props: Props) => {
  const { maxHeight = 400, scroll, children, labelledBy } = props
  const scrollArea = useRef<HTMLDivElement>(null)
  const [showTopShader, setShowTopShader] = useState<boolean>(false)
  const [showBottomShader, setShowBottomShader] = useState<boolean>(false)

  const { scrollHeight = 0 } = scrollArea.current || {}

  const checkScrollPosition = useCallback(() => {
    const { clientHeight = 0, scrollHeight = 0, scrollTop = 0 } =
      scrollArea.current || {}
    const gap = 10
    const topShader = scrollHeight > clientHeight && scrollTop > gap
    const bottomShader =
      scrollHeight > clientHeight &&
      clientHeight + scrollTop + gap < scrollHeight

    setShowTopShader(topShader)
    setShowBottomShader(bottomShader)
  }, [])

  useEffect(() => {
    const area = scrollArea.current
    scrollArea.current?.addEventListener('scroll', checkScrollPosition)

    return () => {
      area?.removeEventListener('scroll', checkScrollPosition)
    }
  }, [checkScrollPosition])

  useEffect(() => {
    // make sure the content is render before doing the initial check and check when content changes
    setTimeout(checkScrollPosition, 200)
  }, [checkScrollPosition, scrollHeight])

  return (
    <div className='relative'>
      {showTopShader && (
        <img
          src={imgShaderReverse}
          alt=''
          style={{
            width: '100%',
            height: '30px',
            position: 'absolute',
            top: 0
          }}
        />
      )}
      <div
        ref={scrollArea}
        className={
          (scrollArea.current && scrollArea.current.scrollHeight > maxHeight) ||
          scroll
            ? 'overflow-scroll'
            : ''
        }
        style={{
          maxHeight,
          paddingBottom: 2,
          overflowX: 'auto'
        }}
        aria-labelledby={labelledBy}
        role='group'
      >
        {children}
      </div>
      {showBottomShader && (
        <img
          src={imgShader}
          alt=''
          style={{
            width: '100%',
            height: '30px',
            position: 'absolute',
            bottom: 0
          }}
        />
      )}
    </div>
  )
}

export default Scrollable
