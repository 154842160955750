import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import jobsSelectors from '../../selectors/jobsSelectors'
import JobsNav from '../JobsNav'
import Divider from 'shared/components/Divider'
import paths from '../../routes'
import parsePath from 'shared/utils/parsePath'
import Grid from '@material-ui/core/Grid'
import JobCard from '../JobCard'
import Page from 'shared/components/Page'
import { reserveJob, releaseJob, openJob, deleteJob } from '../../actions'
import { useDispatch } from 'react-redux'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import RootState from 'shared/models/RootState'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import Text from 'shared/components/Text'
import Loading from 'shared/components/Loading'

const JobsList = () => {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const loading = useSelector((state: RootState) =>
    state.getIn(['jobs', 'loading'])
  )
  const availableJobs = useSelector(jobsSelectors.getAvailableJobs)
  const completeJobs = useSelector(jobsSelectors.getCompleteJobs)
  const activeJobs = useSelector(jobsSelectors.getActiveJobs)
  const hasDeleteAccess = useSelector((state: RootState) =>
    sessionSelectors.userHasRole(state, ['orgAdmin', 'serverAdmin'])
  )
  const [removeJobId, setRemoveJobId] = useState<string>('')

  useEffect(() => {
    if (!params.show) {
      if (!!activeJobs.size) {
        history.push(parsePath(paths.list, { show: 'active' }))
      } else if (!!availableJobs.size) {
        history.push(parsePath(paths.list, { show: 'available' }))
      } else if (!!completeJobs.size) {
        history.push(parsePath(paths.list, { show: 'complete' }))
      }
    }
  }, [params.show, activeJobs, availableJobs, completeJobs, history])

  const handleReserve = useCallback(
    jobId => {
      dispatch(reserveJob(jobId))
    },
    [dispatch]
  )

  const handleRelease = useCallback(
    jobId => {
      dispatch(releaseJob(jobId))
    },
    [dispatch]
  )

  const handleOpenJob = useCallback(
    jobId => {
      dispatch(openJob({ jobId }))
    },
    [dispatch]
  )

  const handleDeleteJob = useCallback(
    jobId => {
      dispatch(deleteJob(jobId))
    },
    [dispatch]
  )

  const showJobs = useMemo(() => {
    return params.show === 'active'
      ? activeJobs
      : params.show === 'available'
      ? availableJobs
      : params.show === 'complete'
      ? completeJobs
      : availableJobs
  }, [params.show, activeJobs, availableJobs, completeJobs])

  return (
    <>
      <JobsNav />
      <Divider />
      <Page title='Jobs List'>
        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={3}>
            {showJobs?.map(jobId => (
              <Grid key={jobId} item md={4} xs={12} sm={6}>
                <JobCard
                  jobId={jobId}
                  onRelease={handleRelease}
                  onReserve={handleReserve}
                  onClick={handleOpenJob}
                  onDelete={hasDeleteAccess && setRemoveJobId}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Page>
      <ConfirmationDialog
        open={!!removeJobId}
        onClose={() => setRemoveJobId('')}
        onConfirm={() => {
          handleDeleteJob(removeJobId)
          setRemoveJobId('')
        }}
      >
        <Text>Are you sure you want to remove this job?</Text>
      </ConfirmationDialog>
    </>
  )
}

export default JobsList
