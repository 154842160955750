import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import fileToUrl from 'shared/utils/data/fileToUrl'
import moment from 'moment'
import certAgencyRank from 'shared/utils/data/certAgencyRank'

const apiRoute = `${apiRoutes.authService}/insight`

export const getForBuyer = ({ interval }) =>
  api.get(`${apiRoute}/buyer`, { interval }).then(({ stats }) => ({
    companyStats: {
      numberOfUsers: stats.users?.numUsers,
      numberOfVets: stats.vets?.numVets,
      numberOfLogins: stats.users?.monthlyUserLogins,
      numberOfConnectedSuppliers: stats.suppliers?.numConnected,
      numberOfExistingSuppliers: stats.suppliers?.numExisting,
      numberOfSearches:
        stats.search && stats.search.length > 1
          ? stats.search[1].numSearches
          : 0
    },
    mostActiveUsers: stats.users?.leaderboard.map(parseUsersLeaderboard),
    mostActiveSuppliers: stats.suppliers?.leaderboard.map(
      parseSuppliersLeaderboard
    ),
    searchGraph: getLastMonths(stats.search)
      ?.map(parseSearchData)
      .reverse(),
    companyTags: parseTerms(stats.terms),
    commonSearchTerms: parseSearchTerms(stats.terms?.search)
  }))

export const loadBuyerSpend = ({ diverse, preferred, ...restParams }) => {
  return api
    .get(`${apiRoute}/v2/buyer/spend`, {
      ...restParams,
      diverse: diverse ? diverse : undefined,
      preferred: preferred ? preferred : undefined
    })
    .then(({ overview, totalSpend: manualTotalSpend, ...rest }) => {
      const { byCategory = {}, byCountry = {}, bySpendGroup = {} } = overview
      let totalSpend = 0,
        countryTotalSpend = 0
      if (Object.keys(byCategory).length > Object.keys(bySpendGroup).length) {
        Object.keys(bySpendGroup).forEach(key => {
          totalSpend += bySpendGroup[key].totalAmount
        })
      } else {
        Object.keys(byCategory).forEach(key => {
          totalSpend += byCategory[key].totalAmount
        })
      }
      Object.keys(byCountry).forEach(key => {
        countryTotalSpend += byCountry[key].totalAmount
      })

      return {
        ...overview,
        manualTotalSpend,
        totalSpend,
        countryTotalSpend,
        ...rest
      }
    })
}

export const getTotalSpend = (range: {
  startDate?: string
  endDate?: string
  startYear?: number
  startQuarter?: number
  endYear?: number
  endQuarter?: number
}) => {
  return api.get(`${apiRoute}/buyer/spend/total`, range)
}

export const loadSpendPreview = ({ diverse, preferred, ...restParams }) => {
  return api.get(`${apiRoute}/v2/buyer/spend/preview`, {
    ...restParams,
    diverse: diverse ? diverse : undefined,
    preferred: preferred ? preferred : undefined
  })
  // .then(({ preview }) => preview)
}

export const getDiverseReport = ({ preferred, ...restParams }) => {
  return api
    .get(`${apiRoute}/v2/buyer/spend/diverse`, {
      ...restParams,
      preferred: preferred ? preferred : undefined
    })
    .then(({ details, ...rest }) => {
      const parseDetails =
        details &&
        details.reduce((result, item) => {
          const { certifications, ...restItem } = item
          const subCategories = certifications
            .map(cert => cert.subCategory)
            .filter((v, i, a) => a.indexOf(v) === i)
          const certAgencies = certifications
            .map(cert => cert.agencies && cert.agencies[0].certAgency)
            .filter((v, i, a) => v && a.indexOf(v) === i)

          result.push({
            certifications: certifications
              .map(({ agencies, ...restCert }) => {
                const confirmAgencies = agencies.map(
                  ({ expiration, confirmed, info, ...restAgency }) => {
                    return {
                      expiration,
                      info,
                      confirmed:
                        !info?.potential && !!restAgency.certAgency
                          ? moment(expiration).isAfter(
                              moment(restParams.startDate),
                              'day'
                            )
                          : confirmed,
                      ...restAgency
                    }
                  }
                )
                return {
                  agencies: confirmAgencies,
                  ...restCert
                }
              })
              .sort(
                (cert1, cert2) =>
                  certAgencyRank(cert2.agencies[0].certAgency) -
                  certAgencyRank(cert1.agencies[0].certAgency)
              ),
            subCategories,
            certAgencies,
            ...restItem
          })
          return result
        }, [])

      return { ...rest, details: parseDetails }
    })
}

export const getBuyerSpendYears = () => {
  return api.get(`${apiRoute}/v2/buyer/spend/years`).then(result => {
    const { range, quarters } = result

    const { minSpendDate, maxSpendDate } = range || {}
    let years: Array<string> = []
    if (quarters) {
      quarters.forEach(q => {
        if (!years.includes(q.year)) {
          years.push(q.year)
        }
      })
    }
    return {
      minSpendDate: minSpendDate && moment(minSpendDate.substr(0, 10)),
      maxSpendDate: maxSpendDate && moment(maxSpendDate.substr(0, 10)),
      years: years.sort()
    }
  })
}

export const loadSupplierInsight = () => {
  return api.get(`${apiRoute}/supplier`).then(({ stats }) => {
    const {
      buyer: {
        existing,
        preferred,
        contactConnections,
        viewDetails,
        ...buyerRest
      },
      related,
      ...rest
    } = stats

    return {
      stats: {
        buyer: {
          existing: existing.map(({ logo, ...rest }) => ({
            logoUrl: fileToUrl(logo),
            ...rest
          })),
          preferred: preferred.map(({ logo, ...rest }) => ({
            logoUrl: fileToUrl(logo),
            ...rest
          })),
          connections: parseContactConnections(contactConnections),
          connectionCount: contactConnections && contactConnections.length,
          viewDetails: viewDetails,
          ...buyerRest
        },
        appearanceDetails: parseRelated(related),
        ...rest
      },
      orgUnits: existing.reduce((result, org) => {
        result[org.id] = Object.assign({}, org, { domains: [org.domain] })
        return result
      }, {})
    }
  })
}

type Terms = { [key: string]: { name: string; count: number } }
type StatsTerms = {
  queryMatch: number
  query: { [key: string]: number }
  offerings: Terms
  differentiators: Terms
}
function parseTerms(terms: StatsTerms) {
  return Object.entries(
    Object.assign({}, terms.differentiators, terms.offerings)
  )
    .map(([tagKey, tag]) => ({
      value: tag.name,
      count: tag.count
    }))
    .sort((tagA, tagB) => tagB.count - tagA.count)
    .slice(0, 30)
}

function parseSuppliersLeaderboard({
  supplierId,
  name,
  bucketName,
  fileName,
  views,
  viewsThisMonth,
  viewsLastMonth
}) {
  return {
    profilePictureUrl: fileToUrl({ bucketName, fileName }),
    id: supplierId,
    name,
    views,
    viewsThisMonth,
    viewsLastMonth
  }
}

function parseBuyersLeaderboard({ logo, orgUnit, ...rest }) {
  return {
    profilePictureUrl: fileToUrl(logo),
    id: orgUnit,
    ...rest
  }
}

function parseUsersLeaderboard({
  // user info
  userId,
  firstName,
  lastName,
  fileName,
  bucketName,

  // metrics
  Connections,
  Contacts,
  Follows,
  ProfileViews,
  Ratings,
  Searches,
  Sessions,
  Tags,
  Vets
}) {
  return {
    profilePictureUrl: fileToUrl({ bucketName, fileName }),
    id: userId,
    firstName,
    lastName,
    numberOfContacts: Contacts,
    numberOfRatings: Ratings,
    numberOfTags: Tags,
    numberOfConnections: Connections,
    numberOfFollows: Follows,
    numberOfProfileViews: ProfileViews,
    numberOfSearches: Searches,
    numberOfSessions: Sessions,
    numberOfVets: Vets
  }
}

function parseSearchData({ month, numSearches }) {
  return {
    monthYear: moment(month).format('MMM YY'),
    numberOfSearches: numSearches
  }
}

function findNumSearches(searchStats, date) {
  const monthStats = searchStats.find(
    stat => Math.round(date.diff(moment(stat.month), 'month', true)) === 0
  )
  return monthStats?.numSearches || 0
}

function getLastMonths(searchStats) {
  if (!searchStats || searchStats.length === 0) {
    return searchStats
  }

  const maxLengthOfSearchStats = 10
  let newSearchStats: Array<{ month; numSearches }> = []
  let now = moment().date(1)

  for (let i = 0; i < maxLengthOfSearchStats; i++) {
    let date = moment(now).subtract(i, 'month')
    let numberOfSearches = findNumSearches(searchStats, date)
    newSearchStats.push({
      numSearches: numberOfSearches,
      month: date.format('YYYY-MM-DD')
    })
  }

  const noData = !newSearchStats.some(({ numSearches }) => numSearches > 0)

  return noData ? [] : newSearchStats
}

function parsePostData({ month, ...rest }) {
  return {
    month: moment(month).format('MMM YYYY'),
    ...rest
  }
}

function parseSearchTerms(searchTerms: Terms) {
  return Object.entries(searchTerms)
    .map(([tagKey, tag]) => ({
      value: tag.name,
      count: tag.count
    }))
    .sort((tagA, tagB) => tagB.count - tagA.count)
    .slice(0, 30)
}

function parseRelated(related) {
  const lastRanking =
    related &&
    related.sort(
      ({ searchViewsLastMonth: viewsA }, { searchViewsLastMonth: viewsB }) =>
        viewsB - viewsA
    )
  const currentRanking =
    related &&
    related
      .sort(
        ({ searchViewsThisMonth: viewsA }, { searchViewsThisMonth: viewsB }) =>
          viewsB - viewsA
      )
      .map((entry, index) => {
        entry.previousRank =
          lastRanking.findIndex(
            entryLast => entryLast.orgUnit === entry.orgUnit
          ) + 1
        entry.currentRank = index + 1
        entry.logoUrl = entry.fileName
          ? fileToUrl(entry)
          : `https://logo.clearbit.com/${entry.domain}`
        return entry
      })
  return currentRanking
}

function parseContactConnections(contactConnections) {
  if (!contactConnections || contactConnections.length === 0) {
    return {}
  }

  return contactConnections.reduce((result, connection) => {
    const {
      clientUserFirstName,
      clientUserLastName,
      clientUserId,
      clientUserBucketName,
      clientUserFileName,
      clientUserTitle,
      clientOrgUnitId,
      clientName,
      clientBucketName,
      clientFileName,
      clientDomain,
      supplierUserId
    } = connection

    if (!result[clientOrgUnitId]) {
      result[clientOrgUnitId] = {
        name: clientName,
        id: clientOrgUnitId,
        logoUrl: clientFileName
          ? fileToUrl({
              bucketName: clientBucketName,
              fileName: clientFileName
            })
          : `https://logo.clearbit.com/${clientDomain}`,
        users: {}
      }
    }

    const clientUsers = result[clientOrgUnitId]['users']
    if (!clientUsers[clientUserId]) {
      clientUsers[clientUserId] = {
        fullName: `${clientUserFirstName} ${clientUserLastName}`,
        title: clientUserTitle,
        id: clientUserId,
        avatarUrl:
          clientUserFileName &&
          fileToUrl({
            bucketName: clientUserBucketName,
            fileName: clientUserFileName
          }),
        connectedTo: {}
      }
    }

    const clientUserConnectedTo = clientUsers[clientUserId]['connectedTo']
    if (!clientUserConnectedTo[supplierUserId]) {
      clientUserConnectedTo[supplierUserId] = supplierUserId
    }

    return result
  }, {})
}

export const getCommunityInsight = communityId =>
  api.get(`${apiRoute}/community/${communityId}`).then(({ stats }) => {
    const {
      suppliers: { leaderboard, ...rest }
    } = stats

    return {
      supplierStats: {
        ...rest
      },
      mostActiveBuyers: stats.buyers.leaderboard
        .map(parseBuyersLeaderboard)
        .sort((org1, org2) => org2.totalConnections - org1.totalConnections),
      mostActiveSuppliers: leaderboard
        .map(parseSuppliersLeaderboard)
        .sort((org1, org2) => org2.viewsThisMonth - org1.viewsThisMonth),
      postGraph: stats.posts.map(parsePostData).reverse(),
      supplierTags: parseTerms(stats.terms).reduce((result, term) => {
        result[term.value] = term.count
        return result
      }, {}),
      commonSearchTerms: parseSearchTerms(stats.terms.search).reduce(
        (result, term) => {
          result[term.value] = term.count
          return result
        },
        {}
      )
    }
  })

export const getConsolidationData = filter =>
  api.get(`${apiRoute}/buyer/consolidated`, filter)

export const getInternalStats = ({ keys, ...params }) =>
  api.get(`${apiRoute}/getInternalStats/${keys}`, params)

export const internalStatKeys = (type: string) =>
  api.get(`${apiRoute}/internalStatKeys`, { type })

export const getSnapshots = () => api.get(`${apiRoute}/buyer/snapshot/list`)
export const saveSnapshot = snapshot =>
  api.post(`${apiRoute}/buyer/snapshot`, snapshot)
export const saveSnapshotLineItems = payload =>
  api.post(`${apiRoute}/buyer/snapshot/lineItems`, payload)
export const getSnapshot = snapshotId =>
  api.get(`${apiRoute}/buyer/snapshot/${snapshotId}`)
export const getSnapshotLineItems = snapshotId =>
  api.get(`${apiRoute}/buyer/snapshot/${snapshotId}/lineItems`)
export const deleteSnapshot = snapshotId =>
  api.remove(`${apiRoute}/buyer/snapshot/${snapshotId}`)

export const getStatLog = (statId: string) =>
  api.get(`${apiRoute}/stat/exportLogs/${statId}`)
