import React, { ReactNode } from 'react'
import MaterialButton, { ButtonProps } from '@material-ui/core/Button'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => ({
  root: {
    transition: 'filter 0.3s linear',
    '&:hover, &:focus': {
      filter: 'brightness(0.9)'
    }
  },
  focusVisible: {
    outline: 'auto black'
  }
})

type Props = {
  type?: string
  label?: string | ReactNode
  disabled?: boolean
  secondary?: boolean
  caution?: boolean
  autoSizeOff?: boolean
  className?: string
  wrapperStyle?: object
  onClick?: (any: any) => void
  component?: ReactNode
  to?: string
} & ButtonProps &
  WithStyles<typeof styles>

/**
 * Used to wrap img and icons and allow them to act like buttons without visual changes
 */
const IconButton = React.forwardRef((props: Props, ref: any) => {
  const {
    onClick,
    type = 'button',
    disabled,
    label,
    secondary,
    caution,
    autoSizeOff,
    wrapperStyle,
    style,
    ...otherParams
  } = props

  return (
    <MaterialButton
      ref={ref}
      onClick={onClick}
      type={type}
      disabled={disabled}
      fullWidth={autoSizeOff}
      style={Object.assign(
        { padding: 0 },
        style,
        wrapperStyle,
        caution && { color: '#e00b0b' }
      )}
      disableFocusRipple
      {...otherParams}
    >
      {label || props.children}
    </MaterialButton>
  )
})

export default withStyles(styles)(IconButton)
