import React, { Component } from 'react'
import Page from 'shared/components/Page'
import { List } from 'immutable'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import CommunityCardContainer from 'shared/containers/CommunityCardContainer'
import { FormattedMessage } from 'react-intl'
import parsePath from 'shared/utils/parsePath'
import paths from '../../routes/paths'
import supplierPaths from '../../../routes/paths'
import SubTitleText from 'shared/components/SubTitleText'
import storageManager from 'shared/utils/storageManager'
import CloseIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import CommunityCard from 'shared/components/CommunityCard'
import TealbookSquareLogo from 'shared/assets/images/tealbook-logo.png'
import { CSSTransition } from 'react-transition-group'
import Hidden from '@material-ui/core/Hidden'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import TealbotHelpIcon from 'shared/components/TealbotHelpIcon'

const styles = {
  dl: 'ma0',
  dt: 'fw6',
  dd: 'mb2 ml3'
}
type Props = {
  isPremium: boolean
  invitedIds: List<string>
  acceptedIds: List<string>
  recommendedIds: List<string>
  numberTealbookClients: number
  onAcceptCommunity: ({ commId: string }) => void
  onDeclineCommunity: ({ commId: string }) => void
  onLeaveCommunity: (payload: {
    communityId?: string
    comPlanMembershipId?: string
  }) => void
  onViewForm: ({ commId: string }) => void
}
type State = {
  doNotShow?: boolean
}

class CommunitiesPage extends Component<Props, State> {
  state: State = {
    doNotShow: storageManager.getItem('doNotShowSupplierCommunitiesInfo')
  }

  handleDoNotShow = () => {
    storageManager.setItem('doNotShowSupplierCommunitiesInfo', true)
    this.setState({ doNotShow: true })
  }

  handleShow = () => {
    storageManager.setItem('doNotShowSupplierCommunitiesInfo', false)
    this.setState({ doNotShow: false })
  }

  render() {
    const {
      invitedIds,
      acceptedIds,
      recommendedIds,
      numberTealbookClients,
      onAcceptCommunity,
      onDeclineCommunity,
      onLeaveCommunity,
      onViewForm
    } = this.props
    const { doNotShow } = this.state

    return (
      <Page title='Communities'>
        <ScrollToPosition />
        <div className='flex'>
          <div>
            <SubTitleText>
              <FormattedMessage
                id='CommunitiesPage.SupplierCommunitiesInfo'
                defaultMessage='Communities help connect your company to existing and prospective customers. Joining a community indicates that your company is interested in doing business with the buyers who belong to that community.'
              />
            </SubTitleText>
            <SubTitleText>
              <FormattedMessage
                id='CommunitiesPage.SupplierCommunitiesInfo2'
                defaultMessage='Open a community to see the companies who belong to it. They will be looking at your company profile to find you and qualify your company for business opportunities. That is why we have been encouraging you to keep it up-to-date.'
              />
            </SubTitleText>
          </div>
          {doNotShow && (
            <CSSTransition
              in={doNotShow}
              timeout={100}
              classNames='tealbotHelp'
              unmountOnExit
            >
              <div className='mt3 ml2 mr4 w4 fr'>
                <TealbotHelpIcon
                  show={doNotShow}
                  imageClassName='pt1 mh3 dim pointer'
                  onClick={this.handleShow}
                />
              </div>
            </CSSTransition>
          )}
        </div>

        {
          <CSSTransition
            in={!doNotShow}
            timeout={300}
            classNames='tealbotHelp'
            unmountOnExit
          >
            <div className='bg-white br1 ba b--black-10 mb3 flex'>
              <div className='pa3-5 lh-copy f7 fw4 mid-gray ma0'>
                <dl className={styles.dl}>
                  <dt className={styles.dt}>
                    <FormattedMessage
                      id='CommunitiesPage.SupplierCommunitiesInfoDT1'
                      defaultMessage='My Communities'
                    />
                    :
                  </dt>
                  <dd className={styles.dd}>
                    <FormattedMessage
                      id='CommunitiesPage.SupplierCommunitiesInfoDD1'
                      defaultMessage='After you join a community, it will be listed under this heading.'
                    />
                  </dd>
                  <dt className={styles.dt}>
                    <FormattedMessage
                      id='CommunitiesPage.SupplierCommunitiesInfoDT2'
                      defaultMessage='Invited'
                    />
                    :
                  </dt>
                  <dd className={styles.dd}>
                    <FormattedMessage
                      id='CommunitiesPage.SupplierCommunitiesInfoDD2'
                      defaultMessage='These are communities where you have been specifically invited by the company that manages the community. The invitation shows a strong interest from them in keeping connected to your company.'
                    />
                  </dd>
                  <dt className={styles.dt}>
                    <FormattedMessage
                      id='CommunitiesPage.SupplierCommunitiesInfoDT3'
                      defaultMessage='Recommended'
                    />
                    :
                  </dt>
                  <dd className={styles.dd}>
                    <FormattedMessage
                      id='CommunitiesPage.SupplierCommunitiesInfoDD3'
                      defaultMessage='These are communities that are available for any supplier to join. Pick the ones that apply to your business.'
                    />
                  </dd>
                </dl>
              </div>

              <Hidden smDown>
                <div className='ml3 w4 self-center'>
                  <img src={TealbotCircleImage} alt='tealbot-tip' />
                </div>
              </Hidden>

              <div className='pa2'>
                <IconButton
                  onClick={this.handleDoNotShow}
                  style={{
                    width: 32,
                    height: 32
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </CSSTransition>
        }

        {invitedIds && invitedIds.size > 0 && (
          <div className='cf pa2'>
            <Label>
              <FormattedMessage
                id='CommunitiesPage.InvitedLabel'
                defaultMessage='Invited'
              />
            </Label>
            {invitedIds.map(commId => (
              <CommunityCardContainer
                key={commId}
                communityId={commId}
                linkTo={parsePath(paths.community, { communityId: commId })}
              >
                <div>
                  <Button
                    onClick={() => onAcceptCommunity({ commId })}
                    label={
                      <FormattedMessage
                        id='CommunitiesPage.AcceptButton'
                        defaultMessage='Accept'
                      />
                    }
                    autoSize
                    className='mr2'
                  />
                  <Button
                    onClick={() => onDeclineCommunity({ commId })}
                    label={
                      <FormattedMessage
                        id='CommunitiesPage.NotInterestedButton'
                        defaultMessage='Not Interested'
                      />
                    }
                    autoSize
                    secondary
                  />
                </div>
              </CommunityCardContainer>
            ))}
          </div>
        )}

        <div className='cf pa2'>
          <Label>
            <FormattedMessage
              id='CommunitiesPage.MyCommunitiesLabel'
              defaultMessage='My Communities'
            />
          </Label>

          <CommunityCard
            name='Tealbook'
            linkTo={supplierPaths.target}
            logoUrl={TealbookSquareLogo}
            isMember
            numberBuyers={numberTealbookClients}
            description={
              <FormattedMessage
                id='CommunitiesPage.TealbookDescription'
                defaultMessage='Every buyer and supplier in TealBook is automatically made a member of this community.'
              />
            }
          />

          {acceptedIds &&
            acceptedIds.size > 0 &&
            acceptedIds.map(commId => (
              <CommunityCardContainer
                key={commId}
                isMember
                communityId={commId}
                onViewForm={() => onViewForm({ commId })}
                onLeaveCommunity={onLeaveCommunity}
                linkTo={parsePath(paths.community, { communityId: commId })}
              />
            ))}
        </div>

        {recommendedIds && recommendedIds.size > 0 && (
          <div className='cf pa2'>
            <Label>
              <FormattedMessage
                id='CommunitiesPage.RecommendedLabel'
                defaultMessage='Recommended'
              />
            </Label>
            {recommendedIds.map(commId => (
              <CommunityCardContainer
                key={commId}
                communityId={commId}
                linkTo={parsePath(paths.community, { communityId: commId })}
              >
                <Button
                  onClick={() => onAcceptCommunity({ commId })}
                  label={
                    <FormattedMessage
                      id='CommunitiesPage.ConnectButton'
                      defaultMessage='Join'
                    />
                  }
                  autoSize
                />
              </CommunityCardContainer>
            ))}
          </div>
        )}
      </Page>
    )
  }
}

export default CommunitiesPage
