import { call } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* removeAllContactsSaga(action) {
  const userIdArray = action.payload.map(id => id.user)
  yield call(supplier.removeAllContacts, userIdArray)
  return yield action.payload
}

export default createApiRequestSaga(removeAllContactsSaga)
