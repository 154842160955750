import { connect } from 'react-redux'
import CertCard from 'shared/components/CertCard'
import supplierRelationships from '../../../shared/selectors/supplierRelationshipsSelectors'
import RootState from 'shared/models/RootState'

type ContainerProps = {
  category: string
  subCategory: string
  timeStamp: string
}
export default connect((state: RootState, props: ContainerProps) => {
  const { category, subCategory, timeStamp } = props
  return {
    validations: supplierRelationships.getCertificationValidationsByKey(
      state,
      `${category}-${subCategory}-${timeStamp || ''}`
    )
  }
})(CertCard)
