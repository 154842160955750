import React, { Component, Fragment } from 'react'
import { Field } from 'redux-form/immutable'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import Grid from '@material-ui/core/Grid'
import Checkbox from 'shared/components/Checkbox'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'

type Props = {
  address: string
  onSubmit: () => void
  onCancel: () => void
}

class CompanyLocationAddForm extends Component<Props> {
  render() {
    const { onSubmit, onCancel, address } = this.props

    return (
      <form className='bg-near-white ph2 pb3' onSubmit={onSubmit}>
        <div className='ph2'>
          <Label htmlFor='address'>
            <FormattedMessage
              id='CompanyLocationAddForm.AddressLabel'
              defaultMessage='Address'
            />
          </Label>
          <FormattedMessage
            id='CompanyLoctionAddForm.AddressPlaceholder'
            defaultMessage='Physical address (e.g. 30 Camden St., Toronto, ON)'
          >
            {message => (
              <Field
                component={Input}
                name='address'
                className='mb2'
                placeholder={message as string}
                required
              />
            )}
          </FormattedMessage>
        </div>

        {!!address && (
          <Fragment>
            <Grid container>
              <Grid item sm className='ph2'>
                <Label htmlFor='description'>
                  <FormattedMessage
                    id='CompanyLocationAddForm.NameLabel'
                    defaultMessage='Name'
                  />
                </Label>
                <FormattedMessage
                  id='CompanyLoctionAddForm.AddressDescriptionPlaceholder'
                  defaultMessage='Description of this address (e.g. Toronto, Headquarter)'
                >
                  {message => (
                    <Field
                      component={Input}
                      name='description'
                      placeholder={message as string}
                      required
                    />
                  )}
                </FormattedMessage>
              </Grid>

              <Grid item sm className='ph2'>
                <Label htmlFor='phone'>
                  <FormattedMessage
                    id='CompanyLocationAddForm.PhoneLabel'
                    defaultMessage='Phone'
                  />
                </Label>
                <FormattedMessage
                  id='CompanyLoctionAddForm.PhoneNumberPlaceholder'
                  defaultMessage='Phone number'
                >
                  {message => (
                    <Field
                      component={Input}
                      name='phone'
                      placeholder={message as string}
                    />
                  )}
                </FormattedMessage>
              </Grid>
            </Grid>

            <Field
              name='isPrimary'
              component={Checkbox}
              label='Primary Address'
              className='ml2 mt3'
            />

            <div className='tr mr2'>
              <Button
                label={<FormattedMessage id='CancelButton' />}
                onClick={onCancel}
                secondary
                autoSize
              />
              <Button
                type='submit'
                label={
                  <FormattedMessage
                    id='CompanyLoctionAddForm.AddButton'
                    defaultMessage='Add'
                  />
                }
                autoSize
                className='mt3 ml3'
              />
            </div>
          </Fragment>
        )}
      </form>
    )
  }
}

export default CompanyLocationAddForm
