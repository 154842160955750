import { defineMessages } from 'react-intl'

const agencyDescriptionMessages = defineMessages({
  mbe: {
    id: 'CertificationForm.NewDefMBE',
    defaultMessage:
      'To qualify as a Minority Business Enterprise (MBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by, one or more members of a socially and economically disadvantaged minority group. You can select which group best applies to you from the list below.'
  },
  wbe: {
    id: 'CertificationForm.DefWBE',
    defaultMessage:
      'To qualify as a Women Owned Business Enterprise (WBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by women.'
  },
  sbe: {
    id: 'CertificationForm.DefSBE',
    defaultMessage:
      'To qualify as a Small Business Enterprise (SBE), you are attesting that your company meets the federal definition of a small business in your country of operation. The maximum number of employees for some countries are listed below.'
  },
  vbe: {
    id: 'CertificationForm.DefVBE',
    defaultMessage:
      'To qualify as a Veteran Business Enterprise (VBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by veterans. In the USA, this specifically requires these veterans be US citizens who have served in the US military, naval or air service. The business cannot be a branch or subsidiary of a foreign corporation, foreign firm or other foreign-based business.'
  },
  dvbe: {
    id: 'CertificationForm.DefDVBE',
    defaultMessage:
      'To qualify as a Disabled Veteran Business Enterprise (DVBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by veterans with at least a 10% disability. In the USA, this specifically requires these veterans be US citizens who have served in the US military, naval or air service. The business cannot be a branch or subsidiary of a foreign corporation, foreign firm or other foreign-based business.'
  },
  lgbtbe: {
    id: 'CertificationForm.DefLGBTBE',
    defaultMessage:
      'To qualify as a LGBT Business Enterprise (LGBTBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by an LGBT person or persons. The business must exercise independence from any non-LGBT business enterprise. In the US, these persons must be U.S. citizens or lawful permanent residents, have its principal place of business (headquarters) in the United States and been formed as a legal entity in the United States.'
  },
  cab: {
    id: 'CertificationForm.DefCAB',
    defaultMessage:
      'To qualify as a Certified Aboriginal Business (CAB), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by a person or persons who have aboriginal status in their country of operation.'
  },
  hub: {
    id: 'CertificationForm.DefHUB',
    defaultMessage:
      'To qualify as a HUB Zone Certified Business (HUB), you are attesting that your business  is operating in a certified historically under-utilized business zone in the United States. There are 7,000 qualified census tracts (HUD) and 900 qualified non- metropolitan counties.'
  },
  pwd: {
    id: 'CertificationForm.DefPWD',
    defaultMessage:
      'To qualify as a People With Disability business (PWD), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by one or more disabled persons.'
  },
  dbe: {
    id: 'CertificationForm.DefDBE',
    defaultMessage:
      'To qualify as a Disadvantaged Business Enterprise (DBE), you are attesting that your business meets all of the government standards that award eligibility as a DBE in your country of operation. These standards  may include being owned by women, minority, disabled and other disadvantaged persons as a result of economic disadvantages with respect to education, employment, residence or business location or social disadvantage and lack of business training.'
  },
  wle: {
    id: 'CertificationForm.DefWLE',
    defaultMessage:
      'To qualify as a Women Led Business Enterprise (WLE), you are attesting that your business is managed and daily business operations are controlled by one or more women.'
  },
  lsa: {
    id: 'CertificationForm.DefLSA',
    defaultMessage:
      'To qualify as a Labor Surplus Area (LSA) business, you are attesting that your business operates in the U.S. in a jurisdiction where the average unemployment number is 20% higher than the average U.S. unemployment rate in the last two calendar years.'
  },
  '8a': {
    id: 'CertificationForm.Def8A',
    defaultMessage:
      'To qualify as an 8(A) Designation business (8A), you are attesting that your business is recognized by the U.S. Small Business Association (SBA) as owned and controlled at least 51% by socially and economically disadvantaged individuals.'
  },
  ancit: {
    id: 'CertificationForm.ANCIT',
    defaultMessage:
      'To qualify as a Alaskan Native Corporations and Indian Tribes business (ANCIT), you are attesting that your business is one of the corporations created by the Alaska Native Claims Settlement Act (ANCSA) of 1971 or owned by an American Indian or Alaska Native tribal entity that is recognized as having a government‐to‐government relationship with the United States.'
  },
  bcorp: {
    id: 'CertificationForm.BCORP',
    defaultMessage:
      'To qualify as a Certified Benefit Corporation, you are attesting that your business has been set up as a Benefit Corporation under applicable law or been certified by B Lab, an international certifying agency.'
  },
  dobe: {
    id: 'CertificationForm.DOBE',
    defaultMessage:
      'To qualify as a Disability Owned Business Enterprise (DOBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by one or more disabled persons.'
  },
  dve: {
    id: 'CertificationForm.DVE',
    defaultMessage:
      'To qualify as a Disadvantaged Veteran Enterprise (DVE), you are attesting that your business meets all of the government standards that award eligibility as a DBE in your country of operation and that our business is at least 51% owned by, and has management and daily business operations that are controlled by veterans. In the USA, this specifically requires these veterans be US citizens who have served in the US military, naval or air service. The business cannot be a branch or subsidiary of a foreign corporation, foreign firm or other foreign-based business.'
  },
  hbcu: {
    id: 'CertificationForm.HBCU',
    defaultMessage:
      'To qualify as a Historically Black Colleges and Universities (HBCU), you are attesting that your business is one of the institutions of higher education in the United States that were established before the Civil Rights Act of 1964 with the intention of primarily serving the African-American community.'
  },
  sdb: {
    id: 'CertificationForm.SBD',
    defaultMessage:
      'To qualify as a Small Disadvantaged Business  (SDB), you are attesting that your business meets all of the government standards that award eligibility as a Disadvantaged Business Enterprise (DBE) and a Small Business Enterprise (SBE) in your country of operation. The DBE standards  may include being owned by women, minority, disabled and other disadvantaged persons as a result of economic disadvantages with respect to education, employment, residence or business location or social disadvantage and lack of business training.'
  },
  vosb: {
    id: 'CertificationForm.VOSB',
    defaultMessage:
      'To qualify as a Veteran Owned Small Business  (VOSB), you are attesting that your business meets all of the government standards that award eligibility as a Small Business Enterprise (SBE) in your country of operation. You are also attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by veterans. In the USA, this specifically requires these veterans be US citizens who have served in the US military, naval or air service. The business cannot be a branch or subsidiary of a foreign corporation, foreign firm or other foreign-based business.'
  },
  sdvbe: {
    id: 'CertificationForm.SDVBE',
    defaultMessage:
      'To qualify as a Service Disabled Veteran Business Enterprise (SDVBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by veterans with at least a 10% disability incurred during active duty in the military. In the USA, this specifically requires these veterans be US citizens who have served in the US military, naval or air service. The business cannot be a branch or subsidiary of a foreign corporation, foreign firm or other foreign-based business.'
  }
})

export default agencyDescriptionMessages
