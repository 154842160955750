import React, { Component, ReactElement } from 'react'
import { connect } from 'react-redux'
import camelCase from 'lodash.camelcase'
import companySelectors from '../../../shared/selectors/companySelectors'
import statsSelectors from '../../../shared/selectors/statsSelectors'
import Text from 'shared/components/Text'
import Pill from 'shared/components/Pill'
import WidgetWrapper from '../../components/WidgetWrapper'
import { Map, RecordOf } from 'immutable'
import { addTags, removeTags } from '../../../Company/actions'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'

const styles = {
  widgetInnerBox: 'cf pa3 bg-white br2 gray pt2-ns ba b--black-10',
  text: 'mb3 normal'
}

type Props = {
  title: string | ReactElement<FormattedMessage>
  subtitle: string | ReactElement<FormattedMessage>
  fieldName: string
  addTags: (params: { fieldName: string; newTag: string }) => void
  removeTags: (params: {
    fieldName: string
    tagToRemove: string[] | string
  }) => void
  tags: Map<string, string>
  endorsedTags: Map<
    string,
    RecordOf<{
      name: string
      count: number
    }>
  >
}

export class TagsEndorsement extends Component<Props> {
  handleToggleTag = (key: string, tag: string) => {
    const { tags, fieldName, addTags, removeTags } = this.props
    if (tags && tags.has(key)) {
      removeTags({
        fieldName,
        tagToRemove: tag
      })
    } else {
      addTags({
        fieldName,
        newTag: tag
      })
    }
  }

  render() {
    const { title, subtitle, endorsedTags, tags } = this.props

    return endorsedTags && endorsedTags.size > 0 ? (
      <WidgetWrapper title={title}>
        <div>
          <Text className={styles.text}>{subtitle}</Text>
          <Text className={styles.text}>
            <FormattedMessage
              id='TagsEndorsement.ToggleTags'
              defaultMessage='Toggle tags to add / remove them from your public profile.'
            />
          </Text>
          <Text className={styles.text}>
            <FormattedMessage
              id='TagsEndorsement.ToggleTagsColorDescription'
              defaultMessage='{teal} = shared with all buyers, {gray} = visible only to the buyer which added the tag'
              values={{
                teal: <span className='bg-teal pa2 br3 white'>teal</span>,
                gray: <span className='bg-light-gray pa2 br3'>gray</span>
              }}
            />
          </Text>
          <div className={styles.widgetInnerBox}>
            {endorsedTags.entrySeq().map(([key, tag]) => (
              <Pill
                key={key}
                label={tag.get('name')}
                count={tag.get('count')}
                color={tags && tags.has(key) && 'primary'}
                onToggle={() => this.handleToggleTag(key, tag.get('name'))}
              />
            ))}
          </div>
        </div>
      </WidgetWrapper>
    ) : null
  }
}

type ContainerProps = {
  fieldName: string
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const tags = companySelectors.getCompanySupplierField(
      state,
      props.fieldName
    )
    const tagsSet = tags && tags.map(tag => camelCase(tag)).toSet()

    return {
      tags: tagsSet,
      endorsedTags: statsSelectors.getTermsField(state, props.fieldName)
    }
  },
  { addTags, removeTags }
)(TagsEndorsement)
