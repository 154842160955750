import { fromJS, List } from 'immutable'

const maxLogItems = 100

type ActionsLogState = List<string>

const actionsLogReducer = (state: ActionsLogState = fromJS([]), action) => {
  // only log it if the action has a type
  // and if is not a redux-form action
  let logMessage = ''
  if (action.type && !action.type.startsWith('@@redux-form')) {
    logMessage =
      action.type === '@@router/LOCATION_CHANGE'
        ? `${action.type} to ${action.payload.location.pathname}${action.payload.location.search}`
        : action.type
  }

  return logMessage
    ? state.size < maxLogItems
      ? state.push(logMessage)
      : state.shift().push(logMessage)
    : state
}

export default actionsLogReducer
