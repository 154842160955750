import React, { Component, Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import OrgLogo from 'shared/components/OrgLogo'
import CommunitiesList from '../CommunitiesList'
import Popover from '@material-ui/core/Popover'
import { FormattedMessage } from 'react-intl'
import paths from 'supplier/routes/paths'
import parsePath from 'shared/utils/parsePath'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import Divider from 'shared/components/Divider'
import UserListItem from 'shared/components/UserListItem'
import Scrollable from 'shared/components/Scrollable'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import CommunitiesIcon from 'shared/assets/icons/communities.svg'
import { List, Map, RecordOf } from 'immutable'
import UploadedFile from 'shared/models/UploadedFile'
import Community from 'shared/models/Community'
import Address from 'shared/components/Address'
import buyerPaths from 'buyer/routes/paths'
import Tooltip from 'shared/components/Tooltip'

type Props = {
  name: string
  logoUrl?: string
  clientId?: string
  websiteUrl?: string
  connections?: Object
  contacts?: List<
    RecordOf<{
      user: string
      firstName: string
      lastName: string
      email: string
      profilePicture: RecordOf<UploadedFile>
    }>
  >
  vets?: Object
  followers?: Object
  views?: Object
  communities?: Map<string, RecordOf<Community>>
  clientIsPrivate?: boolean
  message?: string
  address?: RecordOf<{
    address: string
  }>
  description?: string
} & RouteComponentProps

type State = {
  communitiesAnchorEl: any
}

export class TargetClient extends Component<Props, State> {
  state = {
    communitiesAnchorEl: null
  }

  handleClick = () => {
    const { clientId, history } = this.props

    if (clientId) {
      history.push(paths.clientProfile.replace(':orgUnitId', clientId))
    }
  }

  handleOpenPopover = e => {
    this.setState({
      communitiesAnchorEl: e.currentTarget
    })
  }

  handleClosePopover = () => {
    this.setState({
      communitiesAnchorEl: null
    })
  }

  render() {
    const {
      logoUrl,
      name,
      websiteUrl,
      clientId,
      description,
      // connections,
      // followers,
      // views,
      // vets,
      communities,
      message,
      address,
      contacts,
      clientIsPrivate
    } = this.props

    const { communitiesAnchorEl } = this.state

    return (
      <div
        className={'bg-white ba b--black-10'}
        style={{ height: clientIsPrivate ? 64 : 285 }}
      >
        <div
          className={`flex items-center ${
            !clientId || clientIsPrivate ? '' : 'dim pointer'
          }`}
          style={{ height: 64 }}
          onClick={clientId ? this.handleClick : undefined}
        >
          <div className='pa2 flex-shrink-0' style={{ width: 64 }}>
            <OrgLogo url={logoUrl} name={name} />
          </div>
          <div className='pa2 flex-auto'>
            <Tooltip title={name}>
              <div className='f5 truncate' style={{ cursor: 'default' }}>
                {name}
              </div>
            </Tooltip>
            {websiteUrl && (
              <div className='f6 gray mt1 truncate'>
                {websiteUrl.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '')}
              </div>
            )}
          </div>
        </div>

        {!clientIsPrivate && <Divider className='mb2' />}

        {!clientIsPrivate && (
          <div className='flex-auto mh2'>
            <Scrollable maxHeight={200}>
              {description && <Text>{description}</Text>}
              {address && address.get('address') && (
                <Address location={address} className='mt3 truncate' />
              )}
              {communities && communities.size > 0 && (
                <Text className='mt2'>
                  <span
                    className='pointer dim'
                    onClick={this.handleOpenPopover}
                  >
                    <img
                      src={CommunitiesIcon}
                      alt='Communities'
                      className='w1-5 mr2 v-mid'
                    />
                    <FormattedMessage
                      id='TargetClient.communities'
                      defaultMessage={`{communitiesCount} {communitiesCount, plural, one {community} other {communities}}`}
                      values={{ communitiesCount: communities.size }}
                    />
                  </span>
                </Text>
              )}
              {message && (
                <Fragment>
                  <Label>
                    <FormattedMessage
                      id='TargetClient.CommunityMessage'
                      defaultMessage='Community Message'
                    />
                  </Label>
                  <Text>{message}</Text>
                </Fragment>
              )}
              {contacts && contacts.size > 0 && (
                <Fragment>
                  <Label>
                    <FormattedMessage
                      id='TargetClient.contacts'
                      defaultMessage='{numberOfContacts, plural, one {Contact} other {Contacts}}'
                      values={{
                        numberOfContacts: contacts.size
                      }}
                    />
                  </Label>
                  {contacts.map(contact => (
                    <UserListItem
                      key={contact.get('user')}
                      firstName={contact.get('firstName')}
                      lastName={contact.get('lastName')}
                      email={contact.get('email')}
                      profilePictureUrl={fileToUrl(
                        contact.get('profilePicture')
                      )}
                      profileUrl={parsePath(
                        !clientId ? buyerPaths.userprofile : paths.userProfile,
                        {
                          userId: contact.get('user')
                        }
                      )}
                      className='mt2'
                      mini
                    />
                  ))}
                </Fragment>
              )}
            </Scrollable>
          </div>
        )}
        <Popover
          open={Boolean(communitiesAnchorEl)}
          onClose={this.handleClosePopover}
          anchorEl={communitiesAnchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom'
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
        >
          <CommunitiesList communities={communities} />
        </Popover>
      </div>
    )
  }
}

export default withRouter(TargetClient)
