import { combineReducers } from 'redux-immutable'
import orgsReducer from './orgsReducer'
import userEmailReducer from './userEmailReducer'
import connectUserToOrgReducer from './connectUserToOrgReducer'
import reasonKeyReducer from './reasonKeyReducer'
import dataQualityReducer from './dataQualityReducer'

const rootReducer = combineReducers({
  orgs: orgsReducer,
  userEmail: userEmailReducer,
  connectUserToOrg: connectUserToOrgReducer,
  reasonKeyReducer: reasonKeyReducer,
  dataQuality: dataQualityReducer
})

export default rootReducer
