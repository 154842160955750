import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import UtilitiesPage from '../../components/UtilitiesPage'
import { UPLOAD_FILE_TO_DATA_LOADER } from '../../store/actionTypes'
import { getFormSubmitErrors } from 'shared/reducers'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { syncDiversity } from '../../store/actions'

const formName = 'dataPortal/utilities/uploadFile'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)

export default connectReduxForm(
  state => ({
    isOrgAdmin: sessionSelectors.userHasRole(state, 'orgAdmin'),
    errorMessage: formSubmitErrorsSelector(state),
    initialValues: {
      fromUtilities: true
    }
  }),
  {
    onSubmit: createSubmitFormAction(formName, UPLOAD_FILE_TO_DATA_LOADER),
    syncDiversity
  },
  {
    form: formName
  },
  UtilitiesPage
)
