import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import sortStrings from 'shared/utils/sortStrings'

import sessionSelectors from 'shared/selectors/sessionSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'

export const getAll = state => state.getIn(['buyer', 'cards', 'byId'])

export const getById = (state, cardId) =>
  state.getIn(['buyer', 'cards', 'byId', cardId])

export const getByUserSupplier = (state, supplierId, userId) => {
  const cardId = state.getIn([
    'buyer',
    'cards',
    'bySupplier',
    supplierId,
    userId
  ])
  return getById(state, cardId)
}

export const getBySupplier = (state, supplierId) => {
  const userId = sessionSelectors.getUserId(state)
  return getByUserSupplier(state, supplierId, userId) || fromJS({})
}

export const getAllBySupplier = (state, supplierId) => {
  return state.getIn(['buyer', 'cards', 'bySupplier', supplierId])
}

export const getAllCardsBySupplier = createSelector(
  getAllBySupplier,
  getAll,
  (cardIds, cards) => {
    return cards && cardIds && cardIds.map(cardId => cards.get(cardId))
  }
)

export const getCardsByContactConnection = (state, supplierId, userId) => {
  const cards = getAllCardsBySupplier(state, supplierId)
  return (
    cards &&
    cards.filter(card => {
      return card.getIn(['contacts', userId, 'connected'])
    })
  )
}

export const getCardsByColleagueContactConnection = createSelector(
  getCardsByContactConnection,
  sessionSelectors.getUserId,
  (cards, userId) => {
    return (
      cards &&
      cards.filter(card => {
        return card.get('owner') !== userId
      })
    )
  }
)
export const getConnectedCardIdsBySupplier = createSelector(
  getAllBySupplier, // UserId: CardId
  getAll, // CardId: Card
  usersSelectors.getUsers, // UserId: User
  (cardIds, cards, users) => {
    return (
      cards &&
      cardIds &&
      cardIds
        .filter(cardId => cards.getIn([cardId, 'connected']))
        .sort((cardIdA, cardIdB) => {
          const cardA = cards.get(cardIdA)
          const cardB = cards.get(cardIdB)
          const userA = users.get(cardA.get('owner'))
          const userB = users.get(cardB.get('owner'))

          return userA && userB
            ? sortStrings(
                `${userA.get('firstName')} ${userA.get('lastName')}`,
                `${userB.get('firstName')} ${userB.get('lastName')}`
              )
            : 0
        })
        .keySeq()
        .toList()
    )
  }
)

export const getAllContactsBySupplier = createSelector(
  getAllBySupplier,
  getAll,
  (cardIds, cards) => {
    return (
      cards &&
      cardIds &&
      cards
        .filter(card => cardIds.toList().includes(card.get('id')))
        .map(card => {
          const contactOwner = card.get('owner')
          const contacts = card.get('contacts')
          return (
            contacts &&
            contacts.map(contact => contact.set('contactOwner', contactOwner))
          )
        })
        .toList()
        .reduce((result, card) => {
          return result.merge(card)
        }, fromJS({}))
    )
  }
)
