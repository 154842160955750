import React from 'react'
import { defaultMemoize, createSelector } from 'reselect'
import qs from 'qs'
import { fromJS, List } from 'immutable'
import startCase from 'lodash.startcase'
import { FormattedMessage } from 'react-intl'

export const getParsedQuery = defaultMemoize(queryString =>
  queryString
    ? fromJS(qs.parse(queryString, { ignoreQueryPrefix: true }))
    : fromJS({})
)

export const getFilterPills = defaultMemoize(queryString => {
  const filters = getParsedQuery(queryString).get('filter', fromJS({}))
  let pills = fromJS({})

  filters.entrySeq().forEach(([filterKey, filterValue]) => {
    if (['city', 'state', 'country'].includes(filterKey)) {
      filterValue.valueSeq().forEach(locationValue => {
        let label
        if (List.isList(locationValue)) {
          const joinItems = locationValue.map(i => {
            const location = i.split('::')
            return location[location.length - 1]
          })
          label =
            joinItems.size > 1
              ? `Any of ${joinItems.join(', ')}`
              : joinItems.join()
        } else {
          const location = locationValue.split('::')
          label = location[location.length - 1]
        }
        pills = pills.set(filterKey, {
          label,
          value: locationValue
        })
      })
    } else if (filterKey === 'roles' && filterValue && filterValue.size > 0) {
      filterValue.forEach(value => {
        pills = pills.set(`roles.${value}`, {
          label: (
            <FormattedMessage
              id='searchContactsSelectors.roleFilter'
              defaultMessage='{label} Role'
              values={{
                label: startCase(value)
              }}
            />
          ),
          value
        })
      })
    } else if (filterValue && filterValue.size > 0) {
      const value = filterValue.first()
      let label = startCase(value)
      if (filterKey === 'connected') {
        label = value === 'true' ? 'Connected' : 'Not Connected'
      } else if (filterKey === 'isPublic') {
        label = value === 'true' ? 'Public' : 'Not Public'
      } else if (filterKey === 'haveMessage') {
        label = value === 'true' ? 'Have Message' : "Don't have messsage"
      }

      pills = pills.set(filterKey, {
        label,
        value
      })
    }
  })

  return pills
})

export const getLocationResults = createSelector(
  state => state.getIn(['buyer', 'contactsSearch', 'locationSearchResults']),
  results => {
    if (!results || results.size === 0) {
      return List([])
    }

    const country = results.get('country') || List([])
    const state = results.get('state') || List([])
    const city = results.get('city') || List([])

    return country
      .concat(state.concat(city))
      .filter(location => {
        return !location
          .get('key')
          .split('::')
          .includes('')
      })
      .map(location => {
        const keyArray = location.get('key').split('::')
        return {
          value: location.get('key'),
          label: `${keyArray.reverse().join(', ')} (${location
            .get('doc_count')
            .toLocaleString()})`
        }
      })
  }
)

export const isSearchingLocations = state =>
  state.getIn(['buyer', 'contactsSearch', 'isSearchingLocations'])

export const getFiltersToShow = state =>
  state
    .getIn(['buyer', 'contactsSearch', 'resultFilters'], fromJS({}))
    .map(filters => filters && filters.size > 0)
