import React from 'react'
import VetSupplierListItem from '../../containers/VetSupplierListItem'
import Checkbox from 'shared/components/Checkbox'
import VetBuyerSupplier from 'shared/models/VetBuyerSupplier'
import { List } from 'immutable'
type Props = {
  vbsToEmail: List<VetBuyerSupplier>
  selectedVbsId: string
  onChangeSupplier: (vetId: string) => void
}
const ListForFollowUpEmail = (props: Props) => (
  <>
    {props.vbsToEmail.map(vbs => {
      const vbsId = vbs.getIn(['vetBS', 'id'])
      return (
        <div key={vbsId} className='flex justify-between'>
          <VetSupplierListItem vbsId={vbsId} followUpEmail />
          <div className='mt4'>
            <Checkbox
              className='mv2'
              checked={vbsId === props.selectedVbsId}
              onChange={() =>
                props.onChangeSupplier(
                  props.selectedVbsId === vbsId ? '' : vbsId
                )
              }
            />
          </div>
        </div>
      )
    })}
  </>
)
export default ListForFollowUpEmail
