import React from 'react'
import linkifyHtml from 'linkifyjs/html'
import { markdown } from 'markdown'
import { generatePath } from 'react-router'
import buyerPaths from 'buyer/routes/paths'
import Link from 'shared/components/Link'
import { FormattedMessage } from 'react-intl'

type Props = {
  body: string
  isSelfCertification?: boolean
}

const PostBody = (props: Props) => {
  if (props.isSelfCertification) {
    const indexOfOrgUnitId = props.body.indexOf('OrgUnit-')
    const selfCertifyBody =
      indexOfOrgUnitId > 0
        ? props.body.substring(0, indexOfOrgUnitId)
        : props.body
    const selfCertifiedOrgUnitId =
      indexOfOrgUnitId > 0 ? props.body.substring(indexOfOrgUnitId) : ''

    return (
      <div
        className='rte-readonly-wrapper mt3 f7 lh-copy'
        style={{ wordWrap: 'break-word' }}
      >
        {selfCertifyBody}
        {selfCertifiedOrgUnitId && (
          <div>
            <Link
              to={`${generatePath(buyerPaths.supplierProfile, {
                supplierId: selfCertifiedOrgUnitId
              })}?scrollToDiversity=true`}
              className='teal pointer dim'
            >
              <FormattedMessage
                id='PostBody.SeeCertification'
                defaultMessage='See the certification here.'
              />
            </Link>
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      className='rte-readonly-wrapper mt3 f7 lh-copy'
      style={{ wordWrap: 'break-word' }}
      dangerouslySetInnerHTML={{
        __html: linkifyHtml(markdown.toHTML(props.body))
      }}
    />
  )
}

export default PostBody
