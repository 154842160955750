import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles({
  medal: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    width: '2rem',
    height: '2rem',
    borderRadius: '100%',
    color: 'black',
    borderWidth: '0.2em',
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  goldMedal: {
    boxShadow: 'inset 0 0 0 #b67d05, 2px 2px 0 rgb(0 0 0 / 8%)',
    borderColor: '#fadd40',
    textShadow: '0 0 4px white',
    background: 'linear-gradient(to bottom right, #f9ad0e 50%, #e89f06 50%)'
  },
  silverMedal: {
    boxShadow: 'inset 0 0 0 #a7b2b8, 2px 2px 0 rgb(0 0 0 / 8%)',
    borderColor: '#edeff1',
    textShadow: '0 0 4px white',
    background: 'linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%)'
  },
  bronzeMedal: {
    boxShadow: 'inset 0 0 0 #955405, 2px 2px 0 rgb(0 0 0 / 8%)',
    borderColor: '#f7bb23',
    textShadow: '0 0 4px white',
    background: 'linear-gradient(to bottom right, #df7e08 50%, #c67007 50%)'
  }
})

type Props = {
  place: number
}

function Badge(props: Props) {
  const classes = useStyles()
  let medalStyles
  let medalText

  if (props.place < 2) {
    medalText = '1st'
    medalStyles = classNames(classes.medal, classes.goldMedal)
  } else if (props.place < 3) {
    medalText = '2nd'
    medalStyles = classNames(classes.medal, classes.silverMedal)
  } else if (props.place < 4) {
    medalText = '3rd'
    medalStyles = classNames(classes.medal, classes.bronzeMedal)
  }

  return medalText && medalStyles ? (
    <div className={medalStyles}>
      <span>{medalText}</span>
    </div>
  ) : (
    <div style={{ width: '2rem', height: '2rem' }}></div>
  )
}

export default Badge
