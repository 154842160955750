import React from 'react'
import { useSelector } from 'react-redux'
import jobsSelectors from '../../selectors/jobsSelectors'
import RootState from 'shared/models/RootState'
import OrgLogo from 'shared/components/OrgLogo'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import useThemeColors from 'shared/utils/useThemeColors'
import JobQuestion from './JobQuestion'

type Props = {
  jobId: string
  rowNum: number
}

const JobOrgUnitCard = (props: Props) => {
  const { jobId, rowNum } = props
  const jobOrgUnit = useSelector((state: RootState) =>
    jobsSelectors.getOrgUnitByRowNum(state, rowNum)
  )
  const questions = useSelector((state: RootState) =>
    jobsSelectors.getQuestions(state, rowNum, jobId)
  )

  const theme = useThemeColors()

  const handleLinkClick = (url: string) => {
    const width = window.screen.availWidth - window.outerWidth
    const height = window.screen.availHeight
    const left = window.screen.availWidth - width
    const top = 0
    window.open(
      url,
      'companyWebsite',
      `height=${height}, width=${
        width > 360 ? width : 360
      }, top=${top}, left=${left}`
    )
  }
  return jobOrgUnit ? (
    <div className='bg-white ba br b--black-10 pa2 pa3-ns'>
      {jobOrgUnit?.get('html') ? (
        <div
          dangerouslySetInnerHTML={{ __html: jobOrgUnit.get('html') || '' }}
        />
      ) : (
        <>
          <div className='flex justify-between'>
            {jobOrgUnit.get('logoUrl') && (
              <div
                className='pa1 pointer v-mid ba b--black-10 br2 bg-white flex items-center'
                style={{
                  height: '50px',
                  width: '50px',
                  minWidth: '50px',
                  maxHeight: '50px'
                }}
              >
                <OrgLogo url={jobOrgUnit.get('logoUrl')} />
              </div>
            )}
            <div className='ph2 flex-auto'>
              <h5 className='f4 mid-gray ma0 dtc-ns mw6-ns'>
                {jobOrgUnit.get('name')}
              </h5>
              <div className='truncate'>
                <span
                  className={`${theme.primaryText} underline dim pointer f7 fw4`}
                  onClick={() => handleLinkClick(jobOrgUnit.get('websiteUrl'))}
                >
                  {jobOrgUnit.get('websiteUrl')}
                </span>
              </div>
              {jobOrgUnit.get('linkedInUrl') && (
                <div className='truncate'>
                  <span
                    className={`${theme.primaryText} underline dim pointer f7 fw4`}
                    onClick={() =>
                      handleLinkClick(jobOrgUnit.get('linkedInUrl'))
                    }
                  >
                    {jobOrgUnit.get('linkedInUrl')}
                  </span>
                </div>
              )}
            </div>
          </div>
          {jobOrgUnit.get('description') && (
            <>
              <Label>Description</Label>
              <Text>{jobOrgUnit.get('description')}</Text>
            </>
          )}
          {jobOrgUnit.get('longDescription') && (
            <>
              <Label>Long Description</Label>
              <Text>{jobOrgUnit.get('longDescription')}</Text>
            </>
          )}
        </>
      )}
      {questions?.map((q, index) => (
        <JobQuestion key={`q${index}`} rowNum={rowNum} {...q?.toJS()} />
      ))}
    </div>
  ) : null
}

export default JobOrgUnitCard
