import React, { Component } from 'react'
import classNames from 'classnames'

type Props = {
  className: string
}
class H2 extends Component<Props> {
  render() {
    return (
      <h2 className={classNames('f5 fw2 black-70', this.props.className)}>
        {this.props.children}
      </h2>
    )
  }
}

export default H2
