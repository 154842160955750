import React, { FC, useState, MouseEvent } from 'react'
import Page from 'shared/components/Page'
import Button from 'shared/components/Button'
import FileInput from 'shared/components/FileInput'
import Card from 'shared/components/Card'
import Label from 'shared/components/Label'
import Warning from 'shared/components/Warning'
import { Field } from 'redux-form/immutable'
import InputLabel from '@material-ui/core/InputLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Main from 'shared/components/Layout/Main'
import Divider from 'shared/components/Divider'

type Props = {
  handleSubmit: () => void
  errorMessage: string
  submitSucceeded: boolean
  isOrgAdmin: boolean
  syncDiversity: () => void
}

const UtilitiesPage: FC<Props> = ({
  handleSubmit,
  errorMessage,
  submitSucceeded,
  isOrgAdmin,
  syncDiversity
}) => {
  const [isSyncPressed, setIsSyncPressed] = useState<boolean>(false)

  const handleSyncDiversity = (e: MouseEvent<HTMLButtonElement>) => {
    setIsSyncPressed(true)
    syncDiversity()
  }

  return (
    <Page title='Utilities'>
      <Main>
        <Card>
          <form onSubmit={handleSubmit}>
            <InputLabel>Actions</InputLabel>
            <Field
              name='uploadPath'
              className='mb3'
              required
              component={({
                input: { onChange, value },
                meta: { error },
                ...rest
              }) => (
                <RadioGroup onChange={onChange} value={value} {...rest}>
                  <FormControlLabel
                    control={
                      <Radio
                        color='primary'
                        inputProps={{
                          name: 'uploadPath',
                          required: true
                        }}
                      />
                    }
                    value='util/bannedLoad'
                    label='Banned Company Load'
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color='primary'
                        inputProps={{
                          name: 'uploadPath',
                          required: true
                        }}
                      />
                    }
                    value='user/campaign/bulk'
                    label='Bulk Campaign'
                  />
                  {error}
                </RadioGroup>
              )}
            />
            <Field
              component={FileInput}
              accept='.csv, .xlsx, .json'
              className='mt3'
              name='attachedFile'
              limit={1000}
              required
            />
            <Warning
              message='Make sure the file meets the required format'
              className='mt2 mb4'
            />

            {submitSucceeded && <Label>File uploaded successfully.</Label>}
            {errorMessage && (
              <Warning message={errorMessage} className='mt4 mb3' />
            )}

            <Button
              label='Upload File'
              type='submit'
              autoSize
              className='mt3'
            />
          </form>
          {isOrgAdmin && (
            <div className='mt4'>
              <InputLabel>Other Actions</InputLabel>
              <Divider className='mt1 mb3' />
              <Button
                autoSize
                disabled={isSyncPressed}
                onClick={handleSyncDiversity}
              >
                {!isSyncPressed ? 'Diversity Sync' : 'Sync in progress...'}
              </Button>
            </div>
          )}
        </Card>
      </Main>
    </Page>
  )
}

export default UtilitiesPage
