import React, { MouseEvent } from 'react'
import CloseIcon from '../../assets/icons/clear.svg'
import { FormattedMessage, defineMessages } from 'react-intl'
import bowser from 'bowser'
import Rating from '../Rating'
import { useIntl } from 'react-intl'

const browser = bowser.getParser(window.navigator.userAgent)
const isMobile = !!browser.satisfies({
  mobile: {
    safari: '>9',
    'android browser': '>3.10'
  }
})

const styles = {
  wrapper: 'dib',
  label: 'mid-gray db mb1 f7 fw6',
  miniLabel: 'mid-gray db mb1 mt3 f7 fw6',
  reactStarsWrapper: 'dib v-mid',
  ratingMessage: 'f7 gray v-mid'
}

export type Props = {
  value: number
  label?: string
  fourStarsBased?: boolean
  mini?: boolean
  edit?: boolean
  starsLabel?: string[]
  required?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  onChange?: any
  name?: string
}

const StarRating = (props: Props) => {
  const intl = useIntl()
  const [hover, setHover] = React.useState(-1)

  const handleClear = () => {
    if (props.onChange) {
      props.onChange(0)
    }
  }

  const getRatingMessage = () => {
    const defaultStarsLabel = defineMessages({
      0.5: { id: 'StarRating.verypoor', defaultMessage: 'Very Poor' },
      1.0: { id: 'StarRating.verypoor+', defaultMessage: 'Very Poor+' },
      1.5: { id: 'StarRating.poor', defaultMessage: 'Poor' },
      2.0: { id: 'StarRating.poor+', defaultMessage: 'Poor+' },
      2.5: { id: 'StarRating.average', defaultMessage: 'Average' },
      3.0: { id: 'StarRating.average+', defaultMessage: 'Average+' },
      3.5: { id: 'StarRating.verygood', defaultMessage: 'Very Good' },
      4.0: { id: 'StarRating.verygood+', defaultMessage: 'Very Good+' },
      4.5: { id: 'StarRating.excellent', defaultMessage: 'Excellent' },
      5.0: { id: 'StarRating.excellent+', defaultMessage: 'Excellent+' }
    })

    const { value, fourStarsBased, starsLabel } = props
    const rating = fourStarsBased ? value + 1 : value

    const ratingMessages = starsLabel || defaultStarsLabel

    return value === 0 && hover === -1 ? (
      <FormattedMessage
        id='StarsRating.SelectYourRating'
        defaultMessage='Select your rating'
      />
    ) : (
      intl.formatMessage(ratingMessages[hover !== -1 ? hover : rating])
    )
  }

  const {
    value,
    mini,
    fourStarsBased,
    label,
    edit = false,
    required,
    onChange,
    onClick,
    name
  } = props

  return (
    <div className={styles.wrapper}>
      {label && (
        <label className={mini ? styles.miniLabel : styles.label}>
          {label}
          {required && <span className='red ml1'>*</span>}
        </label>
      )}
      <div
        className={`relative ${!mini ? 'mb2' : ''}`}
        tabIndex={onClick ? undefined : 0}
        aria-label={
          onClick
            ? undefined
            : `Rating is ${value} out of ${fourStarsBased ? 4 : 5}`
        }
      >
        {onClick && (
          <button
            className='absolute pointer w-100 h-100 z-1 bn bg-transparent'
            onClick={onClick}
            aria-label={`Rate this supplier, Rating is ${value} out of ${
              fourStarsBased ? 4 : 5
            }`}
          />
        )}
        <div className={styles.reactStarsWrapper}>
          <Rating
            name={edit ? name : undefined}
            max={fourStarsBased ? 4 : 5}
            value={value}
            size={mini ? 'small' : 'medium'}
            readOnly={edit ? false : true}
            precision={isMobile ? 1 : 0.5}
            onChangeActive={(event, newHover) => {
              setHover(newHover)
            }}
            onChange={(e, val) => {
              if (onChange) {
                onChange(val)
              }
            }}
          />
        </div>

        {edit && !required && !!value && (
          <div
            className='dib tl ml2 v-mid'
            style={{
              width: 42,
              height: 25,
              paddingTop: 3
            }}
          >
            <img
              src={CloseIcon}
              style={{ width: 22 }}
              className='pointer'
              onClick={handleClear}
              alt='clear ratings'
            />
          </div>
        )}

        {edit && (
          <span className={styles.ratingMessage}>{getRatingMessage()}</span>
        )}
      </div>
    </div>
  )
}

export default StarRating
