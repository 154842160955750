import React, { Component } from 'react'
import { connect } from 'react-redux'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import AddInternalComment from '../AddInternalComment'
import UserCommentContainer from 'shared/containers/UserCommentContainer'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { removeInternalComment } from '../../actions'
import { UPDATE_INTERNAL_COMMENT } from '../../actionTypes'
import createEditForm from 'shared/utils/createEditForm'
import { defineMessages, injectIntl, IntlShape } from 'react-intl'
import { RecordOf, List } from 'immutable'
import startCase from 'lodash.startcase'
import RootState from 'shared/models/RootState'

const formName = 'vets/InternalComments/userComment'

const messages = defineMessages({
  internalMessage: {
    id: 'InternalComments.InternalComments',
    defaultMessage: 'Internal Comments'
  },
  suggested: {
    id: 'InternalComments.Suggested',
    defaultMessage: 'Suggested'
  },
  approved: {
    id: 'InternalComments.Approved',
    defaultMessage: 'Approved'
  },
  invited: {
    id: 'InternalComments.Invited',
    defaultMessage: 'Invited'
  }
})

type Props = {
  vetId: string
  vetBuyerSupplierId: string
  currentUserId: string
  isTealbot: boolean
  comments: List<
    RecordOf<{ user: string; date: string; note: string; type?: string }>
  >
  removeInternalComment: (arg: {
    vetBuyerSupplierId: string
    index: number
  }) => void
  intl: IntlShape
}

export class InternalComments extends Component<Props> {
  formIndex = 0

  render() {
    const {
      vetBuyerSupplierId,
      currentUserId,
      isTealbot,
      comments,
      removeInternalComment,
      intl
    } = this.props

    let userCommentIndex = 0

    return (
      <div className='w-100 dt bg-near-white'>
        <div className='dt w-100 mb3 ph4 pt3'>
          <h5 className='f7 fw6 ma0 dtc'>
            {intl.formatMessage(messages.internalMessage)}
          </h5>
        </div>
        {comments &&
          comments.map((comment, index) => {
            const commentIsEditable =
              (currentUserId === comment.get('user') || isTealbot) &&
              !comment.get('type')

            const uCommentIndex = userCommentIndex
            if (commentIsEditable) {
              userCommentIndex = userCommentIndex + 1
            }
            const EditableUserCommentContainer =
              (currentUserId === comment.get('user') || isTealbot) &&
              !comment.get('type')
                ? createEditForm(
                    {
                      formName: `${formName}${this.formIndex++}`,
                      submitActionType: UPDATE_INTERNAL_COMMENT,
                      stopEditModeOnSave: true,

                      onRemove: () =>
                        removeInternalComment({
                          vetBuyerSupplierId,
                          index: commentIsEditable ? uCommentIndex : index,
                          ...comment.toJS()
                        })
                    },
                    UserCommentContainer
                  )
                : UserCommentContainer

            return (
              <EditableUserCommentContainer
                key={comment.get('date')}
                userId={comment.get('user')}
                userProfileUrl={parsePath(paths.userprofile, {
                  userId: comment.get('user')
                })}
                comment={
                  comment.get('type')
                    ? messages[comment.get('type') || '']
                      ? intl.formatMessage(messages[comment.get('type') || ''])
                      : startCase(comment.get('type'))
                    : comment.get('note')
                }
                date={comment.get('date')}
                initialValues={{
                  index: commentIsEditable ? uCommentIndex : index,
                  vetBuyerSupplierId,
                  ...comment.toJS(),
                  comment: comment.get('note')
                }}
              />
            )
          })}
        <AddInternalComment className='pa4-ns pa2' />
      </div>
    )
  }
}

type ContainerProps = {
  vetId: string
  vetBuyerSupplierId: string
}
export default connect(
  (state: RootState, props: ContainerProps) => {
    const user = sessionSelectors.getUser(state)
    const comments = currentVetSuppliersSelectors.getVetSupplierComments(
      state,
      props.vetId,
      props.vetBuyerSupplierId
    )

    return {
      isTealbot: user && user.get('roles').includes('tealbot'),
      currentUserId: user && user.get('id'),
      comments
    }
  },
  { removeInternalComment }
)(injectIntl(InternalComments))
