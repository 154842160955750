import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import qs from 'qs'
import Loading from 'shared/components/Loading'

type Props = {} & RouteComponentProps

const RedirectWindowTop: FC<Props> = ({ location }) => {
  const search = location.search
  const { url } =
    search &&
    qs.parse(search, {
      ignoreQueryPrefix: true
    })

  useEffect(() => {
    if (url) {
      window.top.location.href = url
    }
  }, [url])

  return (
    <div className='pa4 tc'>
      <Loading />
    </div>
  )
}

export default RedirectWindowTop
