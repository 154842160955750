import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Column } from 'react-virtualized'
import Table from 'shared/components/Table'
import InsightTitle from 'shared/components/InsightTitle'
import startCase from 'lodash.startcase'
import Button from 'shared/components/Button'
import exportData from 'shared/utils/exportData'
import { List } from 'immutable'
import { ClientUsers } from './InternalStats'

type Props = {
  stat: string
  value: List<ClientUsers>
}
const ClientUsersTable = ({ stat, value }: Props) => {
  return (
    <div key={stat}>
      <div className='flex justify-between items-center mt4'>
        <InsightTitle>{startCase(stat)}</InsightTitle>
        <Button
          onClick={() => {
            exportData.exportCSV(
              value.toJS(),
              'Users Recently Logged into Suppliers who are Connected to a Client',
              true
            )
          }}
        >
          <FormattedMessage id='InternalStats.Export' defaultMessage='Export' />
        </Button>
      </div>
      <div className='bg-white br1 ba b--black-10'>
        <Table
          rowGetter={({ index }) => value && !!value.size && value.get(index)}
          rowCount={value.size}
        >
          <Column
            label={
              <FormattedMessage
                id='InternalStats.name'
                defaultMessage='Company Name'
              />
            }
            width={150}
            dataKey='name'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.orgUnitId'
                defaultMessage='Orgunit ID'
              />
            }
            width={150}
            dataKey='orgUnitId'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.firstName'
                defaultMessage='First Name'
              />
            }
            width={130}
            dataKey='firstName'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.lastName'
                defaultMessage='Last Name'
              />
            }
            width={130}
            dataKey='lastName'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.email'
                defaultMessage='Email'
              />
            }
            width={170}
            dataKey='email'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.firstLogin'
                defaultMessage='First Login'
              />
            }
            width={150}
            dataKey='firstLogin'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.lastLogin'
                defaultMessage='Last Login'
              />
            }
            width={150}
            dataKey='lastLogin'
          />
        </Table>
      </div>
    </div>
  )
}

export default ClientUsersTable
