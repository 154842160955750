import React from 'react'
import tealbookContributors from '../../utils/tealbookContributors.json'
import Page from 'shared/components/Page'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import startCase from 'lodash.startcase'
import { fromJS, List, RecordOf } from 'immutable'
import PageSection from 'shared/components/PageSection'
import tealbotControl from 'shared/assets/images/tealbot-control.png'
import SchoolTwoToneIcon from '@material-ui/icons/SchoolTwoTone'
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone'
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone'
import GroupTwoToneIcon from '@material-ui/icons/GroupTwoTone'
import Product from 'shared/assets/icons/Product.svg'
import Data from 'shared/assets/icons/Data.svg'

// **tealbookContributors.json will be amended by developers joining tealbook.
// **There's a link to it here: https://tealbook.atlassian.net/wiki/spaces/DEV/pages/1305149513/Dev+Onboarding

const TealbookContributorsContainer = () => {
  const contributors = fromJS(tealbookContributors)

  const renderContributors = (
    contributors: RecordOf<{ staff: List<string>; intern: List<string> }>,
    title: string
  ) => {
    return (
      <Grid item xs={6}>
        <div className='flex items-center mb2 h3'>
          {title === 'dataScience' && (
            <AssessmentTwoToneIcon className='mr2' fontSize='large' />
          )}
          {title === 'softwareDevelopment' && (
            <BuildTwoToneIcon className='mr2' fontSize='large' />
          )}
          {title === 'dataQuality' && (
            <img alt='Data' src={Data} style={{ width: 40, marginRight: 4 }} />
          )}
          {title === 'product' && (
            <img
              alt='Product'
              src={Product}
              style={{ width: 40, marginRight: 4 }}
            />
          )}
          <Label noPadding>{startCase(title)}</Label>
        </div>

        <div className='pt2 flex items-center'>
          <GroupTwoToneIcon className='mr2' />
          <Text>
            <FormattedMessage
              id='TealbookContributorsContainer.StaffAndContractor'
              defaultMessage='Staff and Contractors'
            />
          </Text>
        </div>

        {contributors
          .get('staff')
          ?.sort()
          .map(s => (
            <Text secondary>{s}</Text>
          ))}

        {contributors.get('intern') && (
          <>
            <div className='pt2 flex items-center'>
              <SchoolTwoToneIcon className='mr2' />
              <Text>
                <FormattedMessage
                  id='TealbookContributorsContainer.Interns'
                  defaultMessage='Interns'
                />
              </Text>
            </div>

            {contributors
              .get('intern')
              .sort()
              .map(i => (
                <Text secondary>{i}</Text>
              ))}
          </>
        )}
      </Grid>
    )
  }

  return (
    <Page
      title={
        <FormattedMessage
          id='TealbookContributorsContainer.TealbookContributors'
          defaultMessage='Tealbook Contributors'
        />
      }
    >
      <PageSection
        title={
          <FormattedMessage
            id='TealbookContributorsContainer.TealbookCreators'
            defaultMessage='Tealbook Creators'
          />
        }
        subtitle={
          <FormattedMessage
            id='TealbookContributorsContainer.PastAndPresent'
            defaultMessage='Past and present data scientists and software developers who have brought TealBook into being.'
          />
        }
      >
        {
          <img
            alt='tealbotControl'
            src={tealbotControl}
            className='w5 db center mv3'
          />
        }

        <Grid container spacing={3}>
          <Grid item xs>
            {renderContributors(contributors.get('dataScience'), 'dataScience')}
          </Grid>
          <Grid item xs>
            {renderContributors(
              contributors.get('softwareDevelopment'),
              'softwareDevelopment'
            )}
          </Grid>
          <Grid item xs>
            {renderContributors(contributors.get('dataQuality'), 'dataQuality')}
          </Grid>
          <Grid item xs>
            {renderContributors(contributors.get('product'), 'product')}
          </Grid>
        </Grid>
      </PageSection>
    </Page>
  )
}

export default TealbookContributorsContainer
