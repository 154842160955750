import { call, select } from 'redux-saga/effects'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import createApiRequest from 'shared/utils/sagas/createApiRequestSaga'
import updateCardSaga from '../../../SupplierProfile/sagas/updateCardSaga'

// import parseCard from 'shared/utils/data/parseCard'

export function* connectToSupplierSaga(action) {
  const { supplierId, ...options } = action.payload
  const card = yield select(cardsSelectors.getBySupplier, supplierId)

  return yield call(updateCardSaga, {
    supplierId,
    cardId: card.get('id'),
    cardChanges: {
      connected: true
    },
    ...options
  })
}

export default createApiRequest(connectToSupplierSaga)
