import React, { FunctionComponent, useEffect } from 'react'
import Page from 'shared/components/Page'
import AskNewQuestion from './VetQuestionForm'
import VetQuestions from './VetQuestions'
import PageSection from 'shared/components/PageSection'
import { loadVetQuestions } from '../../actions'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import Loading from 'shared/components/Loading'
import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import { FormattedMessage } from 'react-intl'

type Props = {
  vetBuyerSupplierId?: string
  isLoadingQuestions: boolean
  isLoadingVet: boolean
  loadVetQuestions: typeof loadVetQuestions
}

const VetQandAPage: FunctionComponent<Props> = props => {
  const { vetBuyerSupplierId, loadVetQuestions } = props

  useEffect(() => {
    if (vetBuyerSupplierId) {
      loadVetQuestions(vetBuyerSupplierId)
    }
  }, [vetBuyerSupplierId, loadVetQuestions])

  if (props.isLoadingVet || props.isLoadingQuestions) {
    return <Loading />
  }

  return (
    <Page title='Questions &amp; Answers'>
      <VetQuestions />
      <PageSection
        title={
          <FormattedMessage
            id='VetQuestionForm.AskQuestionTitle'
            defaultMessage='Ask Question'
          />
        }
        subtitle={
          <FormattedMessage
            id='VetQuestionForm.AskQuestionSubtitle'
            defaultMessage='Your question and the answer to it may be shared anonymously with all suppliers participating in this RFI.'
          />
        }
      >
        <AskNewQuestion />
      </PageSection>
    </Page>
  )
}

export default connect(
  (state: RootState) => ({
    isLoadingVet: vetBuyerSupplierSelectors.getIsLoadingVet(state),
    isLoadingQuestions: state.getIn([
      'supplier',
      'currentVet',
      'isLoadingQuestions'
    ]),
    vetBuyerSupplierId: state.getIn([
      'supplier',
      'currentVet',
      'vetBuyerSupplier',
      'id'
    ])
  }),
  {
    loadVetQuestions
  }
)(VetQandAPage)
