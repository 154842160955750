import React, { Component } from 'react'
import Paper from 'shared/components/Paper'

class InsightPaper extends Component {
  render() {
    return (
      <Paper noPadding>
        <div className='pa3'>{this.props.children}</div>
      </Paper>
    )
  }
}

export default InsightPaper
