import React, { useState, useMemo } from 'react'
import PageSection from 'shared/components/PageSection'
import Page from 'shared/components/Page'
import Select from 'shared/components/Select'
import { List, RecordOf } from 'immutable'
import Task from 'shared/models/Task'
import Card from 'shared/components/Card'
import Text from 'shared/components/Text'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { startCase } from 'lodash'
import BulkOutreach from '../BulkOutreach'
import useIsFeatureEnabled from 'shared/utils/featureFlags/useIsFeatureEnabled'
import OutreachTable from './OutreachTable'

const messages = defineMessages({
  ExpiringContract: {
    id: 'SupplierOutreach.ExpiringContract',
    defaultMessage: 'Contract Renewal'
  },
  EcoVadisAssessment: {
    id: 'SupplierOutreach.EcoVadisAssessment',
    defaultMessage: 'EcoVadis Assessment'
  },
  SelfCertifyRequest: {
    id: 'SupplierOutreach.SelfCertifyRequest',
    defaultMessage: 'Self Certify'
  },
  ESGSurveyRequest: {
    id: 'SupplierOutreach.ESGSurveyRequest',
    defaultMessage: 'Sustainability Survey'
  }
})

type Props = {
  supplierOutreachTasks: List<RecordOf<Task>>
}

const SupplierOutreach = (props: Props) => {
  const intl = useIntl()

  const [taskType, setTaskType] = useState<string>('')

  const openTasks = useMemo(() => {
    return props.supplierOutreachTasks.filter(
      task =>
        !task.get('archived') && (!taskType || task.get('type') === taskType)
    )
  }, [props.supplierOutreachTasks, taskType])

  const closedTasks = useMemo(() => {
    return props.supplierOutreachTasks.filter(
      task =>
        !!task.get('archived') && (!taskType || task.get('type') === taskType)
    )
  }, [props.supplierOutreachTasks, taskType])

  const handleFilterByTaskType = e => {
    setTaskType(e.target.value)
  }

  const requestTypeFilterOptions = taskTypeName => {
    let filterOption
    if (taskTypeName === 'ExpiringContract') {
      filterOption = intl.formatMessage(messages.ExpiringContract)
    } else if (taskTypeName === 'EcoVadisAssessment') {
      filterOption = intl.formatMessage(messages.EcoVadisAssessment)
    } else if (taskTypeName === 'SelfCertifyRequest') {
      filterOption = intl.formatMessage(messages.SelfCertifyRequest)
    } else if (taskTypeName === 'ESGSurveyRequest') {
      filterOption = intl.formatMessage(messages.ESGSurveyRequest)
    } else {
      filterOption = startCase(taskTypeName)
    }
    return filterOption
  }

  const taskTypesSelector = (tasks: List<RecordOf<Task>>) => {
    //array of sorted task types without duplicates used to populate Select component
    return tasks.reduce<Array<string>>((result, curr) => {
      if (result.indexOf(curr.get('type')) < 0) {
        result.push(curr.get('type'))
      }
      return result.sort()
    }, [])
  }

  const esgSurveyPhase2Access = useIsFeatureEnabled('ESGSurveyPhase2')

  return (
    <>
      <Page title='SupplierOutreach'>
        {esgSurveyPhase2Access && <BulkOutreach />}
        <PageSection
          noPadding
          title={
            <div className='tr'>
              <span>
                <FormattedMessage
                  id='SupplierOutreach.OpenRequests'
                  defaultMessage='Open Requests'
                />
              </span>
            </div>
          }
          actions={
            <div className='w6 dib ml2'>
              <Select fullWidth onChange={handleFilterByTaskType}>
                <FormattedMessage
                  id='SupplierOutreach.AllRequestTypes'
                  defaultMessage='All Request Types'
                >
                  {msg => <option value=''>{msg}</option>}
                </FormattedMessage>
                {taskTypesSelector(props.supplierOutreachTasks).map(
                  (typeName, index) => (
                    <option key={index} value={typeName}>
                      {requestTypeFilterOptions(typeName)}
                    </option>
                  )
                )}
              </Select>
            </div>
          }
        >
          {openTasks.size > 0 && <OutreachTable tasks={openTasks} />}
        </PageSection>
        {openTasks && openTasks.size < 1 && (
          <Card>
            <Text style={{ textAlign: 'center' }}>
              <FormattedMessage
                id='SupplierOutreach.OpenRequestsInfo'
                defaultMessage='There are no open requests'
              />
            </Text>
          </Card>
        )}

        <PageSection
          noPadding
          title={
            <div className='tr'>
              <span>
                <FormattedMessage
                  id='SupplierOutreach.ClosedRequests'
                  defaultMessage='Closed Requests'
                />
              </span>
            </div>
          }
        >
          {closedTasks.size > 0 && (
            <OutreachTable tasks={closedTasks} isClosed />
          )}
        </PageSection>
        {closedTasks && closedTasks.size < 1 && (
          <Card>
            <Text style={{ textAlign: 'center' }}>
              <FormattedMessage
                id='SupplierOutreach.ClosedRequestsInfo'
                defaultMessage='There are no closed requests'
              />
            </Text>
          </Card>
        )}
      </Page>
    </>
  )
}

export default SupplierOutreach
