import { call, select, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import supplier from 'shared/utils/api/supplier'
import users from 'shared/utils/api/users'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import Immutable, { fromJS } from 'immutable'
import FormError from 'shared/utils/FormError'
import { messageUser } from 'shared/actions'

//2 emails: users.invite api calls email method but not sending optional message here,
// messageUser action sends email with optional message

export function* addVetContactSaga(action) {
  let { user, message } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  let isCreatingNewUser
  const currentUser = yield select(sessionSelectors.getUser)
  const orgUnitId = currentUser.get('orgUnitId')

  if (!user.id) {
    if (!user.email) {
      throw new FormError('Please select a user')
    }
    user.roles = ['supplier']
    user = yield call(users.inviteUser, { user, orgUnitId })
    isCreatingNewUser = true
  }

  const vetBuyerSupplier = yield select(
    vetBuyerSupplierSelectors.getVetBuyerSupplier
  )
  let sendToContacts = vetBuyerSupplier.getIn(
    ['collaboration', 'sendToContacts'],
    fromJS([])
  )

  let response = {}
  if (
    sendToContacts.size === 0 ||
    sendToContacts.findIndex(c => c.get('user') === user.id) === -1
  ) {
    sendToContacts = sendToContacts.push({ user: user.id })

    response = yield call(
      supplier.updateVetProposal,
      Object.assign(
        {},
        { vetBuyerSupplierId: vetBuyerSupplier.get('id') },
        { sendToContacts: sendToContacts.toJS() }
      )
    )

    if (message && currentUser.get('id') !== user.id) {
      yield put(
        messageUser(
          fromJS({
            userToMessageId: user.id,
            message,
            orgUnitId
          })
        )
      )
    }
  }

  return {
    user,
    isCreatingNewUser,
    ...response
  }
}

export default createApiRequestSaga(addVetContactSaga, { notifyOnError: true })
