import { createSelector } from 'reselect'
import { List } from 'immutable'
import currentVetSuppliersSelectors from '../currentVetSuppliersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { fromJS } from 'immutable'

export const getTeamIds = state => getVet(state).get('users')

export const getTeam = createSelector(
  getTeamIds,
  state => state.getIn(['users', 'byId']),
  function(userList, allContacts) {
    var newUsers = []
    if (userList && allContacts) {
      userList.forEach(item => {
        const userId = item.user || item.get('user')
        if (allContacts.has(userId))
          newUsers.push(allContacts.get(userId).toJS())
      })
    }
    return List(newUsers)
  }
)

export const getTeamMemberIds = createSelector(
  state => getVet(state).get('users'),
  users => (users ? users.map(user => user.user || user.get('user')) : List([]))
)

export const currentUserIsOwner = createSelector(
  state => getVetField(state, 'users'),
  sessionSelectors.getUserId,
  (veTeamUsers, currentUserId) => {
    const owner = veTeamUsers && veTeamUsers.find(user => user.lead)
    return owner ? owner.user === currentUserId : false
  }
)

export const isLoading = state =>
  state.getIn(['buyer', 'currentVet', 'isFetching'])
export const getVet = state => state.getIn(['buyer', 'currentVet', 'data'])

export const getVetField = (state, field) =>
  state.getIn(['buyer', 'currentVet', 'data', field])
export const isReadonly = state => {
  const decision = getVetField(state, 'decision')
  return decision === 'Cancelled' || decision === 'Closed'
}

export const canDelete = createSelector(
  getVet,
  currentVetSuppliersSelectors.getVetSuppliers,
  (vet, vetSuppliers) =>
    vet.get('decision') === 'Cancelled' &&
    !vetSuppliers.some(vetSupplier => !!vetSupplier.getIn(['vetBS', 'invited']))
)

export const getMissingRequiredFields = createSelector(getVet, vet => {
  const required = {
    name: "RFI's Title",
    description: 'Summary',
    responseDate: 'Response By',
    decisionDate: 'Completion Date'
  }
  const missing = Object.keys(required).reduce((result, field) => {
    if (!vet.get(field)) {
      result.push(required[field])
    }
    return result
  }, [])
  return missing
})

export const getAdditions = createSelector(
  state => getVetField(state, 'additions'),
  additions => additions
)

export const getMilestones = createSelector(
  state => state.getIn(['buyer', 'currentVet', 'data', 'created', 'date']),
  state =>
    state.getIn(['buyer', 'currentVet', 'data', 'supplierFirstSuggestDate']),
  state => state.getIn(['buyer', 'currentVet', 'data', 'supplierEnabledDate']),
  state =>
    state.getIn(['buyer', 'currentVet', 'data', 'supplierFirstInviteDate']),
  state =>
    state.getIn(['buyer', 'currentVet', 'data', 'supplierFirstResponseDate']),
  state => state.getIn(['buyer', 'currentVet', 'data', 'responseDate']),
  state => state.getIn(['buyer', 'currentVet', 'data', 'decisionDate']),
  state =>
    state.getIn(['buyer', 'currentVet', 'data', 'cancelled', 'date']) ||
    state.getIn(['buyer', 'currentVet', 'data', 'closed', 'date']) ||
    state.getIn(['buyer', 'currentVet', 'data', 'plannedCompletionDate']),
  (
    created,
    suggested,
    enabledCommunication,
    invited,
    responded,
    responseBy,
    decision,
    completed
  ) => {
    const list = fromJS([
      {
        type: 'created',
        date: created
      },
      {
        type: 'suggested',
        date: suggested
      },
      {
        type: 'enabled',
        date: enabledCommunication
      },
      {
        type: 'invited',
        date: enabledCommunication && invited
      },
      {
        type: 'responded',
        date: enabledCommunication && responded
      },
      {
        type: 'responseBy',
        date: enabledCommunication && responseBy
      },
      {
        type: 'decision',
        date: enabledCommunication && decision
      },
      {
        type: 'completed',
        date: completed
      }
    ])
    return list
      .filter(milestone => milestone.get('date'))
      .sort((m1, m2) => {
        return new Date(m1.get('date')) > new Date(m2.get('date'))
          ? 1
          : new Date(m1.get('date')) < new Date(m2.get('date'))
          ? -1
          : 0
      })
  }
)
export const getSupplierApprovedAfterResponseDate = createSelector(
  getVet,
  currentVetSuppliersSelectors.getVetSuppliers,
  (vet, vetSuppliers) =>
    vetSuppliers.find(
      vetSupplier =>
        vetSupplier.getIn(['vetBS', 'approved', 'date']) >=
        new Date(vet.get('decisionDate'))
    )
)
export const getDecision = createSelector(getVet, vet => vet.get('decision'))
