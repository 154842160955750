import React, { Component, Fragment } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import { isSubmitting } from 'redux-form/immutable'
import { getFormSubmitErrors } from 'shared/reducers/selectors'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import AddCommentForm, {
  AddCommentFormProps
} from 'shared/components/AddCommentForm'
import { Prompt } from 'react-router'
import { injectIntl, defineMessages, IntlShape } from 'react-intl'

const messages = defineMessages({
  warning: {
    id: 'createAddCommentForm.Warning',
    defaultMessage:
      'You have unsaved comment. Are you sure you want to navigate away?'
  }
})

interface Props extends AddCommentFormProps {
  ignorePrompt: boolean
  reset: () => void
  handleSubmit: () => void
  intl: IntlShape
  messageTopicId: string
  dirty: boolean
}

export const createAddCommentForm = ({ formName, submitFormActionType }) => {
  class AddCommentFormContainer extends Component<Props> {
    componentDidUpdate(prevProps) {
      const { reset } = this.props
      if (prevProps.messageTopicId !== this.props.messageTopicId) {
        reset()
      }
    }

    render() {
      const { handleSubmit, intl, ignorePrompt = false, ...rest } = this.props

      return (
        <Fragment>
          <AddCommentForm {...rest} onSubmit={handleSubmit} />
          <Prompt
            when={!ignorePrompt && this.props.dirty}
            message={intl.formatMessage(messages.warning)}
          />
        </Fragment>
      )
    }
  }

  const isSubmittingSelector = isSubmitting(formName)
  const formErrorSelector = getFormSubmitErrors(formName)

  return connectReduxForm(
    state => ({
      isSubmitting: isSubmittingSelector(state),
      errorMessage: formErrorSelector(state),
      profilePictureUrl: sessionSelectors
        .getUser(state)
        .get('profilePictureUrl'),
      firstName: sessionSelectors.getUser(state).get('firstName'),
      lastName: sessionSelectors.getUser(state).get('lastName')
    }),
    {
      onSubmit: createSubmitFormAction(formName, submitFormActionType)
    },
    {
      form: formName,
      enableReinitialize: true
    },
    injectIntl(AddCommentFormContainer)
  )
}

export default createAddCommentForm
