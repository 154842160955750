import React, { Component } from 'react'
import iconTrendUp from 'shared/assets/icons/upward-trend.svg'
import iconTrendDown from 'shared/assets/icons/downward-trend.svg'
import iconUnchange from 'shared/assets/icons/nochange.svg'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import Link from 'shared/components/Link'

const styles = {
  icon: 'mr3-ns mr1 w1'
}

type Props = {
  recentTrend: number
  linkTo?: string
  supplierName?: string
}

class SpendTrend extends Component<Props> {
  renderTrend() {
    const { recentTrend, linkTo, supplierName } = this.props
    if (recentTrend > 10) {
      return (
        <Tooltip
          title={
            <FormattedMessage
              id='SpendTrend.'
              defaultMessage='Spend Increasing'
            />
          }
        >
          {linkTo ? (
            <Link
              to={linkTo}
              aria-label={`Link to ${supplierName}'s Spend Overview Section`}
            >
              <img
                src={iconTrendUp}
                alt='Spend Increasing'
                className={styles.icon}
              />
            </Link>
          ) : (
            <img
              src={iconTrendUp}
              alt='Spend Increasing'
              className={styles.icon}
            />
          )}
        </Tooltip>
      )
    } else if (recentTrend < -10) {
      return (
        <Tooltip
          title={
            <FormattedMessage
              id='SpendTrend.SpendDecreasing'
              defaultMessage='Spend Decreasing'
            />
          }
        >
          {linkTo ? (
            <Link
              to={linkTo}
              aria-label={`Link to ${supplierName}'s Spend Overview Section`}
            >
              <img
                src={iconTrendDown}
                alt='Spend Decreasing'
                className={styles.icon}
              />
            </Link>
          ) : (
            <img
              src={iconTrendDown}
              alt='Spend Decreasing'
              className={styles.icon}
            />
          )}
        </Tooltip>
      )
    } else {
      return (
        <Tooltip
          title={
            <FormattedMessage
              id='SpendTrend.SpendUnchanged'
              defaultMessage='Spend Unchanged'
            />
          }
        >
          {linkTo ? (
            <Link
              to={linkTo}
              aria-label={`Link to ${supplierName}'s Spend Overview Section`}
            >
              <img
                src={iconUnchange}
                alt='Spend Unchanged'
                className={styles.icon}
              />
            </Link>
          ) : (
            <img
              src={iconUnchange}
              alt='Spend Unchanged'
              className={styles.icon}
            />
          )}
        </Tooltip>
      )
    }
  }

  render() {
    const { recentTrend } = this.props

    return recentTrend !== undefined ? this.renderTrend() : null
  }
}

export default SpendTrend
