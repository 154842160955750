import { connect } from 'react-redux'
import searchSelectors from '../../../Search/selectors/searchSelectors'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import VetNavBar from '../../components/VetNavBar'
import RootState from 'shared/models/RootState'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'

type ContainerProps = {
  vetId: string
}
export default connect((state: RootState, props: ContainerProps) => {
  const vetBuyerSuppliers = currentVetSuppliersSelectors.getConversation(state)
  return {
    communicationIsEnabled: currentVetSelectors.getVetField(
      state,
      'inviteSuppliers'
    ),
    vetBuyerSupplierId:
      vetBuyerSuppliers.size > 0
        ? vetBuyerSuppliers.first().getIn(['vetBS', 'id'])
        : undefined,
    queryString: searchSelectors.getQueryStringByRef(state, props.vetId) || '',
    defaultFilters: settingsSelectors.getDefaultFilters(state)
  }
})(VetNavBar)
