import React from 'react'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import ChatContactSelect from '../../components/ChatContactSelect'
import profileSelectors from '../../../SupplierProfile/selectors/profileSelectors'
import {
  cancelAddingOutreachContact,
  openAddOutreachContactForm
} from '../../../../buyer/SupplierProfile/actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import { messageUser } from 'shared/actions'
import { List, RecordOf } from 'immutable'
import User from 'shared/models/User'

type Props = {
  contacts: List<RecordOf<User>>
  activeUserId: string
  taskId: string
  supplierId: string
  isChatContactSelectOpen: boolean
  clientName: string
  messageUser: (arg: string) => void
  handleToggleChatContactSelectDialog: () => void
  onContactSupplier: (
    taskId: string,
    status: string,
    currentUserId: string,
    selectedRequestContactId: string
  ) => void
  onClickNewUser: () => void
  onClickCancel: () => void
}

const ChatContactSelectContainer = (props: Props) => {
  const { handleToggleChatContactSelectDialog, onClickCancel } = props
  const handleCancel = () => {
    handleToggleChatContactSelectDialog()
  }
  const handleCancelNewUserForm = () => {
    onClickCancel()
    handleToggleChatContactSelectDialog()
  }
  return (
    <ChatContactSelect
      handleCancel={handleCancel}
      onMessageUser={props.messageUser}
      handleCancelNewUserForm={handleCancelNewUserForm}
      {...props}
    />
  )
}
type ContainerProps = {
  supplierId: string
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const contacts = profileSelectors.getContactsForOutreachRequest(state)
    const orgUnitId = sessionSelectors.getOrgUnitId(state)
    const orgUnit = orgsSelectors.getById(state, orgUnitId)
    return {
      contacts,
      clientName: orgUnit.get('name')
    }
  },
  {
    onClickCancel: cancelAddingOutreachContact,
    onClickNewUser: openAddOutreachContactForm,
    messageUser
  }
)(ChatContactSelectContainer)
