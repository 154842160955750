import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import { Field, formValueSelector } from 'redux-form/immutable'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import { Link } from 'react-router-dom'
import paths from 'accounts/routes/paths'
import { FormattedMessage } from 'react-intl'
import auth from 'shared/utils/api/auth'
import Warning from 'shared/components/Warning'
import Divider from 'shared/components/Divider'
import Select from 'shared/components/Select'
import { supportedLanguages } from '../../../i18n'
import LanguageIcon from '@material-ui/icons/Language'
import { InjectedFormProps } from 'redux-form'
import RootState from 'shared/models/RootState'
import { RouteComponentProps } from 'react-router-dom'
import qs from 'qs'

type Props = {
  email: string
  confirmEmail: string
  loginOptions: string
  client?: string
  handleSubmit: () => void
  onSubmit: () => void
  language: string
  onChangeLanguage: (lang: string) => void
} & InjectedFormProps &
  Pick<RouteComponentProps, 'location'>

type State = {
  showPersonalEmailWarning: boolean
}

export class RegistrationPage1 extends Component<Props, State> {
  state: State = {
    showPersonalEmailWarning: false
  }

  handleOnBlurEmail = () => {
    const [, emailDomain] = this.props.email
      ? this.props.email.toLowerCase().split('@')
      : ['', '']

    if (emailDomain) {
      auth
        .checkOrgUnitByEmail(this.props.email)
        .then(({ forbidden }) => {
          this.setState({
            showPersonalEmailWarning: !!forbidden
          })
        })
        // since we are only calling this api to show a warning
        // we can swallow the error
        .catch(console.log)
    }
  }

  render() {
    const {
      handleSubmit,
      language,
      onChangeLanguage,
      loginOptions,
      client
    } = this.props

    return (
      <>
        <form className='tl' onSubmit={handleSubmit}>
          <Label htmlFor='email' className='db mb1 f7 fw6' required>
            <FormattedMessage id='LoginForm.email' />
          </Label>
          <FormattedMessage
            id='RegistrationPage1.enterEmailAddress'
            defaultMessage='Enter email address'
          >
            {placeholder => (
              <Field
                name='email'
                component={Input}
                type='email'
                required
                placeholder={placeholder as string}
                onBlur={this.handleOnBlurEmail}
              />
            )}
          </FormattedMessage>

          <FormattedMessage
            id='RegistrationPage1.ConfirmEmailAddress'
            defaultMessage='Confirm email address'
          >
            {placeholder => (
              <Field
                name='confirmEmail'
                component={Input}
                type='email'
                required
                placeholder={placeholder as string}
                className='mt2'
                validate={(value, allValues) =>
                  value !== allValues.get('email') ? (
                    <FormattedMessage
                      id='RegistrationPage1.emailsDontMatch'
                      defaultMessage={`The email addresses don't match.`}
                    />
                  ) : (
                    undefined
                  )
                }
              />
            )}
          </FormattedMessage>

          {this.state.showPersonalEmailWarning && (
            <Warning
              className='mt3'
              message={
                <FormattedMessage
                  id='RegistrationPage1.PersonalEmailWarning'
                  defaultMessage={
                    'This looks like a personal email. Please change it to your work email.'
                  }
                />
              }
            />
          )}

          <Button
            type='submit'
            label={
              <FormattedMessage
                id='RegistrationPage1.signUp'
                defaultMessage='Sign Up'
              />
            }
            className='mt3'
            disabled={
              !this.props.email || this.props.email !== this.props.confirmEmail
            }
          />
        </form>
        <Divider className='mv3' />
        <div className='mt3 flex justify-between items-center'>
          <div className='flex items-center w-50'>
            <LanguageIcon className='mr2' />
            <Select
              name='language'
              onChange={e => onChangeLanguage(e.currentTarget.value)}
              value={language}
            >
              {supportedLanguages.map(({ key, title }) => (
                <option key={key} value={key}>
                  {title}
                </option>
              ))}
            </Select>
          </div>
          <div className='tr'>
            <span className='f7 fw4 mid-gray'>
              <FormattedMessage id='EnterEmailForm.alreadyHaveAnAccount' />
            </span>
            <Link
              to={`${paths.login}${
                loginOptions ? `?options=${loginOptions}` : ''
              }${client ? `&client=${client}` : ''}`}
            >
              <b className='f7 fw6 dib teal no-underline underline-hover pl1 pointer'>
                <FormattedMessage id='EnterEmailForm.loginNow' />
              </b>
            </Link>
          </div>
        </div>
      </>
    )
  }
}

const formName = 'accounts/registration'
const selector = formValueSelector(formName)

export default connectReduxForm(
  (state: RootState, props: Pick<RouteComponentProps, 'location'>) => {
    const { options, email, client } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    })

    return {
      email: selector(state, 'email'),
      confirmEmail: selector(state, 'confirmEmail'),
      loginOptions: options,
      client,
      initialValues: {
        email
      }
    }
  },
  {},
  {
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  },
  RegistrationPage1
)
