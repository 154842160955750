import * as actionTypes from '../../actionTypes'
import { ADD_SUPPLIER_COMMENT } from '../../../buyer/SupplierProfile/actionTypes'
import { requestSuccess } from 'shared/actionTypes'
import { fromJS } from 'immutable'

/**
 * comments: {
 *   by{messageKind}Id {
 *     [{Kind}Id]: [
 *       {
 *         id: string
 *         createdDate: Date
 *         createdBy: string
 *         body: string
 *         isPrivate: boolean
 *       }
 *     ]
 *   }
 * }
 */
export const messagesReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case requestSuccess(actionTypes.LOAD_COMMENTS):
      return state.setIn(
        [`by${action.payload.messageKind}Id`, action.payload.ancestorId],
        fromJS(action.payload.messages)
      )

    case requestSuccess(actionTypes.ADD_COMMENT):
    case requestSuccess(ADD_SUPPLIER_COMMENT):
      return state.updateIn(
        [`by${action.payload.messageKind}Id`, action.payload.ancestorId],
        messages =>
          messages
            ? messages.push(fromJS(action.payload.newMessage))
            : fromJS([action.payload.newMessage])
      )

    case requestSuccess(actionTypes.UPDATE_COMMENT):
      const indexUpdate = state
        .getIn([`by${action.payload.messageKind}Id`, action.payload.ancestorId])
        .findIndex(message => message.get('id') === action.payload.messageId)

      return indexUpdate === -1
        ? state
        : state.setIn(
            [
              `by${action.payload.messageKind}Id`,
              action.payload.ancestorId,
              indexUpdate
            ],
            fromJS(action.payload.newMessage)
          )

    case requestSuccess(actionTypes.REMOVE_COMMENT):
      const indexRemove = state
        .getIn([`by${action.payload.messageKind}Id`, action.payload.ancestorId])
        .findIndex(message => message.get('id') === action.payload.messageId)

      return indexRemove === -1
        ? state
        : state.deleteIn([
            `by${action.payload.messageKind}Id`,
            action.payload.ancestorId,
            indexRemove
          ])

    default:
      return state
  }
}

export default messagesReducer
