import { fromJS, List, Map, RecordOf, Record } from 'immutable'
import { requestSuccess } from 'shared/actionTypes'
import * as actionTypes from '../../actionTypes'
import { LOAD_SOW_JOB } from 'buyer/Search/actionTypes'
import Job, {
  JobOrgUnitAnswers,
  JobOrgUnit,
  JobQuestion
} from 'shared/models/Job'

export type Jobs = {
  list: List<string>
  byId: Map<string, RecordOf<Job>>
  jobRows: List<RecordOf<JobOrgUnit>>
  jobQuestions: List<RecordOf<JobQuestion>>
  jobResults: List<RecordOf<JobOrgUnitAnswers>>
  loading: boolean
  loadingJob: boolean
  loadingSowJob: boolean
  currentOrgUnitId: string
  submittingAnswer: boolean
}

export const JobsRecord = Record<Jobs>({
  list: List([]),
  byId: Map({}),
  jobRows: List([]),
  jobQuestions: List([]),
  jobResults: List([]),
  loading: false,
  loadingJob: false,
  loadingSowJob: false,
  currentOrgUnitId: '',
  submittingAnswer: false
})

const jobsReducer = (state = new JobsRecord(), action) => {
  let resultIndex
  switch (action.type) {
    case LOAD_SOW_JOB:
    case actionTypes.ADD_JOB_ROW:
      return state.set('loadingSowJob', true)

    case requestSuccess(LOAD_SOW_JOB):
      return state
        .set('loadingSowJob', false)
        .set('jobResults', fromJS(action.payload.results))
        .set('jobRows', fromJS(action.payload.rows))
        .setIn(['byId', action.payload.job.id], fromJS(action.payload.job))

    case actionTypes.GET_JOBS_LIST:
      return state.set('loading', true)

    case requestSuccess(actionTypes.GET_JOBS_LIST):
      return state
        .set('loading', false)
        .set('list', fromJS(action.payload?.list))
        .mergeIn(['byId'], fromJS(action.payload?.byId))

    case requestSuccess(actionTypes.LOAD_NEW_JOB):
      return state
        .updateIn(['list'], list => list.push(action.payload.id))
        .setIn(['byId', action.payload.id], fromJS(action.payload))

    case actionTypes.UPDATE_JOB_ANSWERS:
      return state.set('submittingAnswer', true)

    case actionTypes.ADD_NEW_ORG:
      return state.set('currentOrgUnitId', 'New')

    case actionTypes.SELECT_ORG_UNIT:
      return state.set('currentOrgUnitId', action.payload)

    case requestSuccess(actionTypes.UPDATE_JOB_ANSWERS):
      const { jobId, done, ...answer } = action.payload
      const jobResults = state.get('jobResults')
      let rowResultIndex = jobResults.findIndex(
        result => result.get('rowNum') === answer.rowNum
      )

      return rowResultIndex !== -1
        ? state
            .setIn(['jobResults', rowResultIndex], fromJS(answer))
            .updateIn(['byId', jobId, 'status'], status =>
              done ? 'complete' : status
            )
            .set('submittingAnswer', false)
        : state
            .setIn(['jobResults', jobResults.size], fromJS(answer))
            .updateIn(['byId', jobId, 'status'], status =>
              done ? 'complete' : status
            )
            .updateIn(
              ['byId', jobId, 'numComplete'],
              numComplete => jobResults.size + 1
            )
            .set('submittingAnswer', false)

    case requestSuccess(actionTypes.RESERVE_JOB):
    case requestSuccess(actionTypes.RELEASE_JOB):
    case requestSuccess(actionTypes.UPDATE_JOB_STATUS):
      return state.setIn(['byId', action.payload.id], fromJS(action.payload))

    case requestSuccess(actionTypes.ADD_JOB_ROW):
      const { jobId: addJobId, jobResult, ...rest } = action.payload
      return !!addJobId
        ? state
            .update('jobRows', rows => rows.push(fromJS(rest)))
            .set('currentOrgUnitId', rest.orgUnitId)
            .set('loadingSowJob', false)
            .update('jobResults', results => results.push(fromJS(jobResult)))
        : state

    case actionTypes.OPEN_JOB:
      return state
        .set('loadingJob', true)
        .set('jobRows', List([]))
        .set('jobResults', List([]))

    case requestSuccess(actionTypes.OPEN_JOB):
      return action.payload.jobId
        ? state
            .setIn(['byId', action.payload.jobId], fromJS(action.payload.job))
            .set('jobRows', fromJS(action.payload.rows))
            .set('jobQuestions', fromJS(action.payload.questions))
            .set('jobResults', fromJS(action.payload.jobResults))
            .set('loadingJob', false)
            .set('currentOrgUnitId', '')
        : state.set('loadingJob', false)

    case requestSuccess(actionTypes.BULK_DELETE_RESULTS):
      return state
        .setIn(['byId', action.payload, 'status'], 'inProgress')
        .setIn(['byId', action.payload, 'numComplete'], 0)

    case requestSuccess(actionTypes.DELETE_JOB):
      return state
        .deleteIn(['byId', action.payload])
        .updateIn(['list'], list => list.filter(id => id !== action.payload))

    case requestSuccess(actionTypes.UPDATE_SOW_ORG):
      const { metadata } = action.payload
      resultIndex = state
        .get('jobResults')
        .findIndex(
          result =>
            result.get('orgUnitId') === action.payload.jobResult.orgUnitId
        )
      return resultIndex === -1
        ? state
            .updateIn(['jobResults'], results =>
              results.push(fromJS(action.payload.jobResult))
            )
            .set('currentOrgUnitId', action.payload.jobResult.orgUnitId)
            .setIn(['byId', action.payload.jobId, 'metadata'], fromJS(metadata))
        : state
            .setIn(
              ['jobResults', resultIndex],
              fromJS(action.payload.jobResult)
            )
            .set('currentOrgUnitId', action.payload.jobResult.orgUnitId)
            .setIn(['byId', action.payload.jobId, 'metadata'], fromJS(metadata))

    case requestSuccess(actionTypes.REORDER_ORGS_POSITION):
    case requestSuccess(actionTypes.REJECT_SOW_ORG):
      let newResults = state.get('jobResults')
      // update a list of change results
      action.payload.results.forEach(org => {
        const index = newResults.findIndex(
          r => r.get('orgUnitId') === org.orgUnitId
        )
        const { rowNum, rejectReason } = org
        const updateObj = rejectReason ? { rowNum, rejectReason } : { rowNum }

        newResults =
          index === -1
            ? newResults.push(fromJS(org))
            : newResults.update(index, r => r.merge(fromJS(updateObj)))
      })

      return state
        .set('jobResults', newResults)
        .update('currentOrgUnitId', currentOrgUnitId =>
          !!action.payload.rejectReason ? '' : currentOrgUnitId
        )

    default:
      return state
  }
}

export default jobsReducer
