import React, { FC, ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Switch from 'shared/components/Switch'
import { List, RecordOf } from 'immutable'

type Props = {
  pricing:
    | RecordOf<{
        currency: string
        lineItems: List<
          RecordOf<{
            name: string
            description: string
            quantity: number
            UOM: string
            id: string
          }>
        >
      }>
    | undefined
  proposalPrices: { [k: string]: number | '' }
  quoteByUnitPrice: boolean
  onChangePrice: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeQuoteByUnitPrice: () => void
}

const VetResponsePricing: FC<Props> = ({
  pricing,
  proposalPrices,
  quoteByUnitPrice,
  onChangePrice,
  onChangeQuoteByUnitPrice
}) => {
  const lineItems = pricing && pricing.get('lineItems')

  return (
    <>
      <Label>
        <FormattedMessage
          id='VetResponsePricing.ResponsePricing'
          defaultMessage='Pricing'
        />
      </Label>
      <div className='mt3'>
        <Switch
          label='Quote by unit price'
          onChange={onChangeQuoteByUnitPrice}
          checked={quoteByUnitPrice}
        />
      </div>
      <div className='flex pv2 bb b--black-10'>
        <div className='flex-auto'>
          <Label className='lh-copy f7 fw4 mid-gray ma0 fw6'>
            <FormattedMessage
              id='VetResponsePricing.Currency'
              defaultMessage='Currency ({currency})'
              values={{
                currency: pricing && pricing.get('currency')
              }}
            />
          </Label>
        </div>
        <div className='w-10'>
          <Label className='lh-copy f7 fw4 mid-gray ma0 fw6'>
            <FormattedMessage
              id='VetResponsePricing.Qty'
              defaultMessage='Qty'
            />
          </Label>
        </div>
        <div className='w-10'>
          <Label className='lh-copy f7 fw4 mid-gray ma0 fw6'>
            <FormattedMessage
              id='VetResponsePricing.UOM'
              defaultMessage='UOM'
            />
          </Label>
        </div>
        <div className='w-20'>
          <Label className='lh-copy f7 fw4 mid-gray ma0 fw6'>
            {quoteByUnitPrice ? (
              <FormattedMessage
                id='VetResponsePricing.UnitPrice'
                defaultMessage='Unit Price'
              />
            ) : (
              <FormattedMessage
                id='VetResponsePricing.TotalPrice'
                defaultMessage='Total Price'
              />
            )}
          </Label>
        </div>
        {quoteByUnitPrice && (
          <div className='w-20 ml2 mr2 tr'>
            <Label className='lh-copy f7 fw4 mid-gray ma0 fw6'>
              <FormattedMessage id='VetResponsePricing.TotalPrice' />
            </Label>
          </div>
        )}
      </div>

      {lineItems &&
        lineItems.map(lineItem => (
          <div
            key={lineItem.get('id')}
            className='flex pv2 items-center bb b--black-10'
          >
            <div className='flex-auto'>
              <Text>{lineItem.get('name')}</Text>
              <Text secondary>{lineItem.get('description')}</Text>
            </div>
            <div className='w-10'>
              <Text>{lineItem.get('quantity')}</Text>
            </div>
            <div className='w-10'>
              <Text>{lineItem.get('UOM')}</Text>
            </div>
            <div className='w-20'>
              <Input
                onChange={onChangePrice}
                name={lineItem.get('id')}
                type='number'
                value={proposalPrices[lineItem.get('id')]}
              />
            </div>
            {quoteByUnitPrice && (
              <div className='w-20 ml2 mr2 tr'>
                {proposalPrices[lineItem.get('id')] && (
                  <Text>
                    {(
                      lineItem.get('quantity') *
                      Number(proposalPrices[lineItem.get('id')])
                    ).toLocaleString()}
                  </Text>
                )}
              </div>
            )}
          </div>
        ))}
    </>
  )
}

export default VetResponsePricing
