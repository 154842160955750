import { call } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'
import users from 'shared/utils/api/users'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* updateContactSaga(action) {
  const { id: userId, contactFor, isSustainabilityContact } = action.payload
  if (contactFor !== undefined) {
    yield call(supplier.addContact, { userId, contactFor })
  }
  let user
  if (isSustainabilityContact !== undefined) {
    user = yield call(users.updateById, userId, { isSustainabilityContact })
  }
  return yield { ...action.payload, user }
}

export default createApiRequestSaga(updateContactSaga)
