import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import Tooltip from 'shared/components/Tooltip'
import { Transition } from 'react-transition-group'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import IconButton from '../IconButton'

const useStyles = makeStyles(theme => ({
  tealbotHelpIconCSSEntering: {
    animationName: '$pulse',
    animationDuration: '3s'
  },

  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1,1)'
    },
    '15%': {
      transform: 'scale(1.05, 1.05)'
    },
    '31%': {
      transform: 'scale(1,1)'
    },
    '47%': {
      transform: 'scale(1.05, 1.05)'
    },
    '63%': {
      transform: 'scale(1,1)'
    },
    '79%': {
      transform: 'scale(1.05, 1.05)'
    },
    '100%': {
      transform: 'scale(1,1)'
    }
  }
}))

type Props = {
  show: boolean
  className?: string
  title?: ReactNode
  placement?: any
  imageClassName: string
  onClick: (any) => void
}

const TealbotHelpIcon = (props: Props) => {
  const {
    show,
    className = '',
    title,
    placement,
    imageClassName,
    onClick
  } = props

  const classes = useStyles()

  return (
    <Transition in={show} timeout={3000} appear>
      <div className={clsx(className, classes.tealbotHelpIconCSSEntering)}>
        <Tooltip
          title={
            title ? (
              title
            ) : (
              <FormattedMessage
                id='TealbotHelpIcon.NeedHelp'
                defaultMessage='Need help?'
              />
            )
          }
          placement={placement}
        >
          <IconButton onClick={onClick}>
            <img
              src={TealbotCircleImage}
              alt='tealbot-tip'
              className={imageClassName}
            />
          </IconButton>
        </Tooltip>
      </div>
    </Transition>
  )
}

export default TealbotHelpIcon
