import React, { ReactNode } from 'react'
import classNames from 'classnames'

const styles = {
  nav: 'dt w-100 pv3 border-box nowrap',
  ul: 'list flex ma0 pa0'
}

type Props = {
  className?: string
  listClassName?: string
  children: ReactNode
  style?: any
  listStyle?: any
}
const NavBar = (props: Props) => {
  const { className, listClassName, children, style, listStyle } = props

  return (
    <nav className={classNames(className, styles.nav)} style={style}>
      <ul className={classNames(listClassName, styles.ul)} style={listStyle}>
        {children}
      </ul>
    </nav>
  )
}

export default NavBar
