import createSubmitFormAction from '../createSubmitFormAction'
import TagsFormContainer from 'shared/containers/TagsFormContainer'
import { isSubmitting, getFormSubmitErrors } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form/immutable'
import RootState from 'shared/models/RootState'

type CreateTagsFormOptions = {
  formName: string
  addTagActionType: string
  removeTagActionType: string
  tagsSelector: any
  fieldName: string
  [key: string]: any
}

const createTagsForm = ({
  formName,
  addTagActionType,
  removeTagActionType,
  tagsSelector,
  fieldName,
  ...rest
}: CreateTagsFormOptions) => {
  const formIsSubmitting = isSubmitting(formName)
  const formErrors = getFormSubmitErrors(formName) as (state: RootState) => any

  return connect(
    (state: RootState, props: any) => ({
      tags: tagsSelector(state, fieldName),
      saving: formIsSubmitting(state),
      errorMessage: formErrors(state) && formErrors(state).get('errors'),
      fieldName,
      ...rest
    }),
    {
      onSubmitNewTag: createSubmitFormAction(formName, addTagActionType),
      onDeleteTag: createSubmitFormAction(formName, removeTagActionType)
    }
  )(
    reduxForm({
      form: formName
    })(TagsFormContainer)
  )
}

export default createTagsForm
