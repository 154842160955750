import { call } from 'redux-saga/effects'
//import partnerConsumer from 'shared/utils/api/partnerConsumer'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import buyer from 'shared/utils/api/buyer'

export function* linkBuyerWithIntegrationSaga(action) {
  const {
    username,
    password,
    integrator
    //initialAssessmentLink
  } = action.payload

  // 1) check buyer credentials against Ecovadis api
  let credsSaved = yield call(
    buyer.savePartnerCredentials,
    username,
    password,
    integrator
  )

  //2) if initialAssessmentLink and credsSaved = success. No more actions for buyer
  // if !initialAssessmentLink, response = list of suppliers with a relationship with buyer. Buyer can pick which suppliers to get assessment

  let response

  // PHASE 2
  // if (!credsSaved.devErrorMessage && !initialAssessmentLink) {
  //   response = yield call(partnerConsumer.listAssessmentSuppliers)
  // }

  return { integrator, username, credsSaved, response }
}
export default createApiRequestSaga(linkBuyerWithIntegrationSaga, {
  notifyOnError: true
})
