import { createSelector } from 'reselect'

export const getVet = state =>
  state.getIn(['supplier', 'currentVet']).get('vet')
export const getVetBuyerSupplier = state =>
  state.getIn(['supplier', 'currentVet']).get('vetBuyerSupplier')
export const getVetCompanyName = state =>
  state.getIn(['supplier', 'currentVet']).get('companyName')
export const getVetCompanyOrgUnit = state =>
  state.getIn(['supplier', 'currentVet', 'vet', 'parents', 'OrgUnit'])
export const getIsLoadingVet = state =>
  state.getIn(['supplier', 'currentVet']).get('isLoading')
export const getVets = state => state.getIn(['supplier', 'vets'])

export const getVetsList = createSelector(
  state => state.getIn(['supplier', 'vets', 'items']),
  vets => {
    return vets.sort((v1, v2) => {
      return v1.lastUpdate > v2.lastUpdate ? -1 : 1
    })
  }
)

export const getVetContacts = state => {
  return state.getIn([
    'supplier',
    'currentVet',
    'vetBuyerSupplier',
    'collaboration',
    'sendToContacts'
  ])
}
export const getVetsByBuyer = (state, orgUnit) => {
  const items = getVets(state).get('items')
  return items.filter(item => item.get('companyOrgUnit') === orgUnit)
}
export const getActiveVetsByBuyer = createSelector(getVetsByBuyer, vets => {
  return vets
    .filter(vet => {
      switch (vet.get('status')) {
        case 'Chosen':
        case 'Not Chosen':
        case 'Declined':
        case 'Accepted':
        case 'Cancelled':
        case 'Closed':
          return false
        default:
          return true
      }
    })
    .sort((vetA, vetB) => {
      return new Date(vetA.get('statusDate')) > new Date(vetB.get('statusDate'))
        ? -1
        : 1
    })
})
export const getInActiveVetsByBuyer = createSelector(getVetsByBuyer, vets => {
  return vets
    .filter(vet => {
      switch (vet.get('status')) {
        case 'Chosen':
        case 'Not Chosen':
        case 'Declined':
        case 'Accepted':
        case 'Cancelled':
        case 'Closed':
          return true
        default:
          return false
      }
    })
    .sort((vetA, vetB) => {
      return new Date(vetA.get('statusDate')) > new Date(vetB.get('statusDate'))
        ? -1
        : 1
    })
})
