import { Record } from 'immutable'
import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'
import { reducer as formReducer } from 'redux-form/immutable'
import { LOGOUT, requestSuccess } from './shared/actionTypes'
import { BECOME_TEALBOT } from './data-portal/store/actionTypes'
import sessionReducer from 'shared/reducers/sessionReducer'
import usersReducer from 'shared/reducers/usersReducer'
import editMode from './shared/reducers/editModeReducer'
import notifications from './shared/reducers/notificationsReducer'
import messagesReducer from './shared/reducers/messagesReducer'
import questionsReducer from './shared/reducers/questionsReducer'
import actionsLogReducer from './shared/reducers/actionsLogReducer'
import orgsReducer from './shared/reducers/orgsReducer'
import messReducer from './shared/reducers/messReducer'
import dataReducer from './shared/reducers/dataReducer'

import supplierRootReducer from './supplier/rootReducer'
import buyerRootReducer from './buyer/rootReducer'
import dataPortalRootReducer from './data-portal/store/reducers'
import adminRootReducer from './admin/reducers/rootReducer'
import postsReducer from './posting/reducers/postsReducer'
import jobsReducer from './jobs/reducers/jobsReducer'
import i18nReducer from './i18n/store/reducer'

import mobileContactsReducer from './accounts/reducers/mobileContactsReducer'

const StateRecord = Record({
  mContacts: undefined,
  router: undefined,
  form: undefined,
  users: undefined,
  session: undefined,
  questions: undefined,
  actionsLog: undefined,
  supplier: undefined,
  buyer: undefined,
  dataPortal: undefined,
  admin: undefined,
  orgs: undefined,
  mess: undefined,
  data: undefined,
  i18n: undefined,
  jobs: undefined,
  editMode: undefined,
  notifications: undefined,
  messages: undefined,
  posts: undefined
})

const createRootReducer = history => {
  const appReducer = combineReducers(
    {
      mContacts: mobileContactsReducer,
      router: connectRouter(history),
      form: formReducer,
      users: usersReducer,
      session: sessionReducer,
      questions: questionsReducer,
      actionsLog: actionsLogReducer,
      supplier: supplierRootReducer,
      buyer: buyerRootReducer,
      dataPortal: dataPortalRootReducer,
      admin: adminRootReducer,
      orgs: orgsReducer,
      mess: messReducer,
      data: dataReducer,
      i18n: i18nReducer,
      jobs: jobsReducer,
      editMode,
      notifications,
      messages: messagesReducer,
      posts: postsReducer
    },
    StateRecord
  )

  return (state, action) => {
    if (
      action.type === LOGOUT ||
      action.type === requestSuccess(BECOME_TEALBOT)
    ) {
      // keep the current parts of the state
      state = StateRecord({
        router: state.router,
        actionsLog: state.actionsLog,
        notifications: state.notifications
      })
    }

    return appReducer(state, action)
  }
}

export default createRootReducer
