import React from 'react'
import useThemeColors from 'shared/utils/useThemeColors'

type HandleElementProps = {
  isSelected: boolean
  noDrag: boolean
  isInvisible?: boolean
}

const HandleElement = (props: HandleElementProps) => {
  const colors = useThemeColors()
  const { isSelected, noDrag, isInvisible } = props
  return (
    <div
      className={`${
        !isInvisible
          ? isSelected
            ? colors.primaryContained
            : 'bg-light-gray'
          : ''
      } mr2 dib fl`}
      style={{ height: 50, width: 16, cursor: noDrag ? 'default' : 'grab' }}
    />
  )
}

export default HandleElement
