import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import RequestSupplierRating from '../../components/EcovadisRating/RequestSupplierRating'
import { getByField } from 'buyer/SupplierProfile/selectors/integrationSelectors/integrationSelectors'
import RequestSupplierRatingProgress from 'buyer/SupplierProfile/components/EcovadisRating/RequestSupplierRatingProgress'
import EcovadisRatingReport from 'buyer/SupplierProfile/components/EcovadisRating/EcovadisRatingReport'
import Label from 'shared/components/Label'
import RootState from 'shared/models/RootState'
import Loading from 'shared/components/Loading'
import Button from 'shared/components/Button'
import { EcovadisScorecard } from 'buyer/SupplierProfile/containers/EcovadisRatingContainer/EcovadisRatingContainer'

type Props = {
  ecovadisAssessmentStatus: string | undefined
  ecovadisScorecard: EcovadisScorecard
}
const EcovadisRating = ({
  ecovadisAssessmentStatus,
  ecovadisScorecard
}: Props) => {
  const loadingEcovadisAssessmentStatus = useSelector((state: RootState) =>
    getByField(state, 'loadingEcovadisAssessmentStatus')
  )
  const isSubmittingRequest = useSelector((state: RootState) =>
    getByField(state, 'isSubmittingRequest')
  )
  const actionRequired: boolean = useSelector((state: RootState) =>
    getByField(state, 'actionRequired')
  )
  const [openRequestRatingDialog, setOpenRequestRatingDialog] = useState<
    boolean
  >(false)

  if (loadingEcovadisAssessmentStatus) {
    return <Loading />
  } else {
    return (
      <>
        {!ecovadisAssessmentStatus && (
          <>
            {/* has buyer creds, but no relationship with buyer */}
            <div className='flex flex-column items-center pb5 pt2'>
              <Label className='ns-mb5 mb4'>
                <FormattedMessage
                  id='RequestSupplierRating.RequestSupplierRating'
                  defaultMessage='You need to request that the supplier share this information with you'
                />
              </Label>
              {isSubmittingRequest ? (
                <Button
                  label={
                    <FormattedMessage
                      id='EcovadisRatingContainer.SubmittingRequest'
                      defaultMessage='Submitting Request...'
                    />
                  }
                  onClick={() => setOpenRequestRatingDialog(true)}
                  disabled={true}
                />
              ) : (
                <Button
                  label={
                    <FormattedMessage
                      id='RequestSupplierRating.RequestSupplierButton'
                      defaultMessage='Request EcoVadis rating for this Supplier'
                    />
                  }
                  disabled={false}
                  onClick={() => setOpenRequestRatingDialog(true)}
                />
              )}
            </div>
            <RequestSupplierRating
              openRequestRatingDialog={openRequestRatingDialog}
              onHandleOpenDialog={setOpenRequestRatingDialog}
            />
          </>
        )}

        {ecovadisAssessmentStatus &&
          ecovadisAssessmentStatus !== 'resultsAvailable' && (
            // after request rating, show progress of request
            <div className='flex flex-column items-center pb6'>
              <Label className='ns-mb6 mb5 mt4'>
                <FormattedMessage
                  id='EcovadisRatingContainer.RequestProgressDetails'
                  defaultMessage='Request Progress Details'
                />
              </Label>
              <RequestSupplierRatingProgress />
              {actionRequired && (
                <Label className='red mt3 tc'>
                  <FormattedMessage
                    id='EcovadisRatingContainer.actionRequired'
                    defaultMessage='Action Required: Please contact EcoVadis for more information'
                  />
                </Label>
              )}
            </div>
          )}
        {ecovadisAssessmentStatus &&
          ecovadisAssessmentStatus === 'resultsAvailable' && (
            <>
              {!!ecovadisScorecard.size ? (
                // show ecovadis report for supplier
                <EcovadisRatingReport ecovadisScorecard={ecovadisScorecard} />
              ) : (
                <Loading />
              )}
            </>
          )}
      </>
    )
  }
}

export default EcovadisRating
