import { connect } from 'react-redux'
import Question from '../../components/Question'
import RootState from 'shared/models/RootState'

type Props = {
  questionId: string
  membershipId: string
  communityId: string
}

export default connect((state: RootState, props: Props) => {
  const question = state.questions.byId[props.questionId]

  return {
    name: props.questionId,
    label: question?.label || '',
    placeholder: question?.placeholder,
    type: question?.type || '',
    options: question?.responseOptions,
    membershipId: props.membershipId,
    communityId: props.communityId
  }
})(Question)
