import React, { Component, ReactNode, MouseEvent } from 'react'

const styles = {
  textWrapper: 'v-mid dtc mid-gray',
  paddingTextWrapper: 'mid-gray pl2 flex-auto',
  rightContent: 'flex justify-end v-mid',
  primary: {
    wrapper: 'pv3 flex justify-between items-center',
    leftAvatarWrapper: 'w2-5 h2-5 flex-none',
    primaryText: 'ma0 f5 fw6',
    secondaryText: 'dib f6 gray',
    rightButtonWrapper: 'flex justify-end w5-ns'
  },
  mini: {
    wrapper: 'mt3 flex justify-between items-center',
    leftAvatarWrapper: 'w2 h2 flex-none',
    primaryText: 'ma0 f7 fw4',
    secondaryText: 'f9 mt1',
    rightButtonWrapper: 'flex justify-end w3'
  }
}

export type ListItemProps = {
  primaryText: string | ReactNode
  secondaryText?: string | ReactNode
  leftAvatar?: ReactNode
  avatarAlignTop?: boolean
  rightContent?: ReactNode
  rightButton?: ReactNode
  mini?: boolean
  className?: string
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
}

class ListItem extends Component<ListItemProps> {
  render() {
    const {
      leftAvatar,
      avatarAlignTop,
      primaryText,
      secondaryText,
      rightButton,
      mini,
      className,
      rightContent,
      onClick
    } = this.props

    const listStyles = mini ? styles.mini : styles.primary

    return (
      <div
        className={
          className
            ? `flex justify-between items-center ${className}`
            : listStyles.wrapper
        }
        onClick={onClick}
      >
        {leftAvatar && (
          <div
            className={`${listStyles.leftAvatarWrapper} ${
              avatarAlignTop ? '' : 'v-mid'
            }`}
          >
            {leftAvatar}
          </div>
        )}

        <div
          className={
            leftAvatar ? styles.paddingTextWrapper : styles.textWrapper
          }
        >
          <h5 className={listStyles.primaryText}>{primaryText}</h5>
          {secondaryText && (
            <div className={listStyles.secondaryText}>{secondaryText}</div>
          )}
        </div>

        {rightButton && (
          <div className={listStyles.rightButtonWrapper}>{rightButton}</div>
        )}

        {rightContent && (
          <div className={styles.rightContent}>{rightContent}</div>
        )}
      </div>
    )
  }
}

export default ListItem
