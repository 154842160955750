import React, { Component } from 'react'
import StarsRating from '../StarsRating'
import classNames from 'classnames'

const styles = {
  errorWrapper: 'b--red'
}

type Props = {
  className: string
  input: string
  meta: { touched: boolean; error: string }
  value: number
}

class StarsRatingInput extends Component<Props> {
  static defaultProps = {
    meta: {}
  }

  render() {
    const {
      className,
      input,
      meta: { touched, error },
      ...otherProps
    } = this.props

    return (
      <div
        className={classNames(
          className,
          touched && error && styles.errorWrapper
        )}
      >
        <StarsRating {...input} {...otherProps} edit />

        {touched && error && <div className='f7 fw3 red mt2'>{error}</div>}
      </div>
    )
  }
}

export default StarsRatingInput
