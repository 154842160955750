import React from 'react'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'

const styles = {
  list: 'f7 fw4 mid-gray ma0 mt2'
}

const GlobalMessagingSBE = () => {
  return (
    <>
      <Text className='mt2'>
        <FormattedMessage
          id='GlobalMessagingSBE.DefSBE'
          defaultMessage='To qualify as a Small Business Enterprise (SBE), you are attesting that your company meets the federal definition of a small business in your country of operation. The maximum number of employees for some countries are listed below.'
        />
      </Text>
      <ul>
        <li className={styles.list}>
          <FormattedMessage
            id='GlobalMessagingSBE.DefSBE2'
            defaultMessage='Canada: Fewer than 100 employees'
          />
        </li>
        <li className={styles.list}>
          <FormattedMessage
            id='GlobalMessagingSBE.DefSBE3'
            defaultMessage='UK: Fewer than 250 employees'
          />
        </li>
        <li className={styles.list}>
          <FormattedMessage
            id='GlobalMessagingSBE.DefSBE4'
            defaultMessage='EU: Fewer than 50 employees'
          />
        </li>
        <li className={styles.list}>
          <FormattedMessage
            id='GlobalMessagingSBE.DefSBE5'
            defaultMessage='Australia: Fewer than 200 employees'
          />
        </li>
      </ul>
    </>
  )
}

export default GlobalMessagingSBE
