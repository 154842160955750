import SalesConfig from '../../components/SalesConfig'
import connectReduxForm from 'shared/utils/connectReduxForm'
import RootState from 'shared/models/RootState'
import { formValueSelector } from 'redux-form/immutable'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import fileToUrl from 'shared/utils/data/fileToUrl'
import { updateSettings } from '../../actions'

const formName = 'salesConfig'
const selector = formValueSelector(formName)

export default connectReduxForm(
  (state: RootState) => {
    const salesConfiguration = settingsSelectors.getSalesConfiguration(state)
    const isUpdating = settingsSelectors.getField(state, 'isUpdating')
    const customLogo = state.getIn(['buyer', 'settings', 'logoFile'])
    const defaultLicenseType: string | undefined =
      salesConfiguration.defaultLicenseType

    return {
      isUpdating,
      customLogoUrl: customLogo && fileToUrl(customLogo),
      inputClientOf: selector(state, 'clientOf'),
      defaultLicenseType:
        defaultLicenseType &&
        `${defaultLicenseType[0].toUpperCase()}${defaultLicenseType.substr(1)}`,
      initialValues: salesConfiguration
    }
  },
  {
    updateSettings
  },
  {
    form: formName,
    enableReinitialize: true
  },
  SalesConfig
)
