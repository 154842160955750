import { connect } from 'react-redux'
import routingSelectors from 'shared/selectors/routingSelectors'
import MessagesNavLink from '../../components/MessagesNavLink'
import messagesSelectors from '../../selectors/messagesSelectors'
import RootState from 'shared/models/RootState'

export default connect((state: RootState) => ({
  numberOfUnreadMessages: messagesSelectors.getNumberOfUnreadMessages(state),
  pathname: routingSelectors.getPathname(state)
}))(MessagesNavLink)
