import React from 'react'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import ShowQuestionResponse from './ShowQuestionResponse'
import ScrollToPosition from 'shared/components/ScrollToPosition'

const pageId = 'environment'

const Environment = () => {
  return (
    <div>
      <ScrollToPosition />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='iso14001'
        question={<FormattedMessage id='Environment.iso14001' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='greenhouseGasInventory'
        question={<FormattedMessage id='Environment.greenhouseGasInventory' />}
      >
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='greenhouseGasInventory'
          questionId='scopeEmissions'
          question={<FormattedMessage id='Environment.scopeEmissionsScope' />}
          childAnswer
        />
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='greenhouseGasInventory'
          questionId='howFrequently'
          question={<FormattedMessage id='Environment.howFrequently' />}
          longAnswer
          childAnswer
        />
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='greenhouseGasInventory'
          questionId='thirdPartyCert'
          question={<FormattedMessage id='Environment.thirdPartyCert' />}
          childAnswer
        />
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='greenhouseGasInventory'
          questionId='ghgInventory'
          question={
            <FormattedMessage id='Environment.GhgInventoryManagement' />
          }
          childAnswer
        />
      </ShowQuestionResponse>
      <ShowQuestionResponse
        pageId={pageId}
        questionId='ghgReductionTarget'
        question={<FormattedMessage id='Environment.ghgReductionTarget' />}
      >
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='ghgReductionTarget'
          questionId='targetDateNumber'
          question={<FormattedMessage id='Environment.targetDateNumber' />}
          longAnswer
          childAnswer
        />
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='ghgReductionTarget'
          questionId='validatedBySBTi'
          question={<FormattedMessage id='Environment.validatedBySBTi' />}
          childAnswer
        />
      </ShowQuestionResponse>
      <ShowQuestionResponse
        pageId={pageId}
        questionId='iso50001'
        question={<FormattedMessage id='Environment.iso50001' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='energyConsumptionTracking'
        question={
          <FormattedMessage id='Environment.energyConsumptionTracking' />
        }
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='waterUsageTracking'
        question={<FormattedMessage id='Environment.waterUsageTracking' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='wastePrograms'
        question={<FormattedMessage id='Environment.wastePrograms' />}
      />
      <Text className='fw6 mt4'>
        <FormattedMessage id='Environment.goalsTargets' />
      </Text>
      <ShowQuestionResponse
        pageId={pageId}
        parentQuestionId='goalsOrTargets'
        questionId='improvingEnergyEfficiency'
        question={
          <FormattedMessage id='Environment.improvingEnergyEfficiency' />
        }
      />
      <ShowQuestionResponse
        pageId={pageId}
        parentQuestionId='goalsOrTargets'
        questionId='managingWaterUsage'
        question={<FormattedMessage id='Environment.managingWaterUsage' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        parentQuestionId='goalsOrTargets'
        questionId='managingWaste'
        question={<FormattedMessage id='Environment.managingWaste' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='negativeEnvironmentalImpacts'
        question={
          <FormattedMessage
            id='Environment.negativeEnvironmentalImpacts'
            values={{
              b: b => <span className='fw8 underline'>{b}</span>
            }}
          />
        }
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='note'
        question={<FormattedMessage id='optionalAdditionalNote' />}
        longAnswer
      />
    </div>
  )
}

export default Environment
