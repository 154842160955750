import React from 'react'
import { connect } from 'react-redux'
import { DiversityReportOverview } from '../DiversityReport/DiversityReportOverview/DiversityReportOverview'
import RootState from 'shared/models/RootState'
import {
  selectAllTier2Categories,
  selectTier2Category,
  clearAllTier2Categories,
  setMatchAnyCategories
} from '../../store/actions'
import { RecordOf, List } from 'immutable'
import Divider from 'shared/components/Divider'
import { OverviewSpendList } from '../../store/diversityReportSelectors'
import DiversityCategory from 'shared/models/DiversityCategory'
import { BaseRulesType } from '../../store/diversityReportReducer'
import insightsSelectors from '../../store/insightsSelectors'
import Tier2DiversitySummaryContainer from './Tier2DiversitySummaryContainer'

type Props = {
  loading: boolean
  overviewSpend: OverviewSpendList
  selectedCategories: List<DiversityCategory>
  totalSpend: number
  selectDiversityCategory: (subCategory: DiversityCategory | '') => void
  selectAllDiversityCategories: (
    subCategories: List<DiversityCategory | ''>
  ) => void
  clearAllDiversityCategories: () => void
  setMatchAnyCategories: (payload: boolean) => void
  baseRules: RecordOf<BaseRulesType>
  tier2?: boolean
  range: string
  isMatchAny: boolean
}

const CategoryOverview = (props: Props) => {
  const { range, ...rest } = props
  return (
    <>
      <Tier2DiversitySummaryContainer
        isLoading={rest.loading}
        totalSpend={rest.totalSpend}
        range={range}
      />
      <Divider className='mt3' />
      <DiversityReportOverview {...rest} />
    </>
  )
}

type ConnectProps = {
  range: string
}

export default connect(
  (state: RootState, props: ConnectProps) => {
    const overviewSpend = insightsSelectors.getTier2Overview(state, props.range)
    return {
      tier2: true,
      loading: insightsSelectors.isLoadingTier2Data(state),
      selectedCategories: insightsSelectors.getTier2SelectedCategories(state),
      overviewSpend,
      totalSpend: state.getIn([
        'buyer',
        'insights',
        'tier2',
        'totalSpend',
        props.range
      ]),
      isMatchAny: state.getIn([
        'buyer',
        'diversityReport',
        'isMatchAnyCategories'
      ]),
      baseRules: state.getIn([
        'buyer',
        'diversityReport',
        'diverseQualificationRules',
        'baseRules'
      ])
    }
  },
  {
    selectDiversityCategory: selectTier2Category,
    selectAllDiversityCategories: selectAllTier2Categories,
    clearAllDiversityCategories: clearAllTier2Categories,
    setMatchAnyCategories
  }
)(CategoryOverview)
