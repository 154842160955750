import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Text from 'shared/components/Text'
import { SpendItemType } from './ReportRowDetails'

type SingleDetailsProps = {
  lineItems: SpendItemType[]
}

const SingleDetails = (props: SingleDetailsProps) => {
  const { lineItems } = props
  const intl = useIntl()

  return lineItems.length > 0 ? (
    <>
      <div className='dt w-100 pb2 mb2 bb b--black-20'>
        <div className='dtc w-50'>
          <Text className='fw6'>
            <FormattedMessage
              id='SingleDetails.Supplier'
              defaultMessage='Supplier'
            />
          </Text>
        </div>
        <div className='dtc w-50 tr'>
          <Text className='fw6'>
            <FormattedMessage id='SingleDetails.Value' defaultMessage='Value' />
          </Text>
        </div>
      </div>
      {lineItems.map(item => (
        <div key={item.name} className='dt w-100' role='row'>
          <div className='dtc w-50'>
            <Text>{item.name}</Text>
          </div>
          <div className='dtc w-50 tr'>
            <Text>
              {intl.formatNumber(item.totalAmount, {
                style: 'currency',
                signDisplay: 'auto',
                currency: 'USD',
                maximumFractionDigits: 0
              })}
            </Text>
          </div>
        </div>
      ))}
    </>
  ) : (
    <Text className='fw6'>
      <FormattedMessage id='SingleDetails.NoData' defaultMessage='No Data' />
    </Text>
  )
}

export default SingleDetails
