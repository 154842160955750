import React, { Component, ReactNode } from 'react'
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { Badge } from '@material-ui/core'

const styles = {
  navLink: 'link f7 f6-ns mr3 mr4-ns pb3 nb3 focus-outline',
  activeNavLink: 'bw1 bb fw6'
}

const CustomBadge = withStyles({
  badge: {
    top: -2,
    right: -2,
    width: 8,
    height: 8,
    backgroundColor: '#e00b0b'
  }
})(Badge)

export const iconLabelStyle = {
  icon: {
    transition: 'all .15s ease-in',
    filter:
      'brightness(0) saturate(100%) invert(32%) sepia(0%) saturate(0%) hue-rotate(261deg) brightness(95%) contrast(83%)', //#555555 for svg
    '&:focus, &:hover': {
      filter: 'brightness(0) saturate(100%)' //#000000 for svg
    }
  }
}

const useStyles = {
  navLink: {
    color: '#555555',
    '&:hover': {
      color: '#000000'
    }
  },
  ...iconLabelStyle
}

const componentStyles = theme => useStyles

type Props = {
  label: string | ReactNode
  numberOfNotifications?: number
  className?: string
  activeClassName?: string
  iconSrc?: string
  classes: any
} & NavLinkProps

class NavLink extends Component<Props> {
  renderLabelOrIcon() {
    const { iconSrc, label, classes } = this.props
    return iconSrc ? (
      <img
        src={iconSrc}
        alt={label as string}
        className={'v-mid ' + classes.icon}
        style={{
          width: 18,
          height: 18
        }}
      />
    ) : (
      label
    )
  }
  render() {
    const {
      label,
      className,
      activeClassName,
      numberOfNotifications,
      iconSrc,
      classes,
      ...rest
    } = this.props
    return (
      <li>
        <RouterNavLink
          {...rest}
          className={classNames(styles.navLink, className, classes.navLink)}
          activeClassName={classNames(styles.activeNavLink, activeClassName)}
        >
          {numberOfNotifications && numberOfNotifications > 0 ? (
            <CustomBadge variant='dot' badgeContent={1}>
              {this.renderLabelOrIcon()}
            </CustomBadge>
          ) : (
            this.renderLabelOrIcon()
          )}
        </RouterNavLink>
      </li>
    )
  }
}

export default withStyles(componentStyles)(NavLink)
