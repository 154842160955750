import React, { Component } from 'react'
import { connect } from 'react-redux'
import companySelectors from '../../../shared/selectors/companySelectors'
import communitiesSelectors from '../../../Communities/selectors/communitiesSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { loadCommunities } from '../../../Communities/actions'
import {
  addTags,
  removeTags,
  addSuggestedTags,
  saveCompanyInfo,
  removeAllContacts
} from '../../actions'
import CompanyProfilePage from '../../components/CompanyProfilePage'
import Loading from 'shared/components/Loading'
import { List } from 'immutable'
import dataSelectors from 'shared/selectors/dataSelectors'
import { createSelector } from 'reselect'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'shared/models/RootState'

type Props = {
  isLoading: boolean
  editingHeader: boolean
  hasCustomers: boolean
  hasCommunities: boolean
  hasSimilarSuppliers: boolean
  offerings: List<string>
  differentiators: List<string>
  suggestedOfferings: List<string>
  suggestedDifferentiators: List<string>
  onAddTags: (tagsToAdd: { fieldName: string; newTag: string }) => void
  onAddSuggestedTags: (params: {
    offerings: string[]
    differentiators: string[]
  }) => void
  onRemoveTags: (params: {
    fieldName: string
    tagToRemove: string[] | string
  }) => void
  loadCommunities: () => void
  referenceScores: { [key: string]: number }
  onSaveCompanyInfo: (locations: {
    supplier: {
      locations: string[]
    }
  }) => void
  hasLocations: boolean
  hasContacts: boolean
  onRemoveAllContacts: typeof removeAllContacts
  contacts: List<string>
  isTealbot: boolean
} & RouteComponentProps

export class CompanyProfilePageContainer extends Component<Props> {
  componentDidMount() {
    this.props.loadCommunities()
  }

  render() {
    const { isLoading, ...rest } = this.props

    return isLoading ? (
      <Loading />
    ) : (
      <div className='bg-white'>
        <CompanyProfilePage {...rest} />
      </div>
    )
  }
}

const createSuggestedTagsSelector = (tagFieldName: string) =>
  createSelector(
    (state: RootState) =>
      companySelectors.getCompanySupplierField(state, tagFieldName),
    (state: RootState) =>
      state.getIn(['supplier', 'stats', 'terms', tagFieldName]),
    (tags, endorsedTags) =>
      endorsedTags
        ? endorsedTags
            .filter(
              eTag =>
                !tags.some(
                  t => t.toLowerCase() === eTag.get('name').toLowerCase()
                )
            )
            .map(tag => tag.get('name'))
            .toList()
        : List()
  )

const getSuggestedOfferings = createSuggestedTagsSelector('offerings')
const getSuggestedDifferentiators = createSuggestedTagsSelector(
  'differentiators'
)

export default connect(
  (state: RootState) => {
    const locations = companySelectors.getCompanySupplierField(
      state,
      'locations'
    )
    const contacts = companySelectors.getSupplierContacts(state)
    return {
      isLoading: companySelectors.isLoading(state),
      editingHeader: companySelectors.isEditingHeader(state),
      hasCustomers: dataSelectors.getSupplierClientsID(state).size > 0,
      hasCommunities:
        (communitiesSelectors.getAcceptedIds(state) || List([])).size > 0,
      hasSimilarSuppliers: companySelectors.hasSimilarSuppliers(state),
      differentiators: companySelectors.getCompanySupplierField(
        state,
        'differentiators'
      ),
      offerings: companySelectors.getCompanySupplierField(state, 'offerings'),
      suggestedOfferings: getSuggestedOfferings(state),
      suggestedDifferentiators: getSuggestedDifferentiators(state),
      referenceScores: companySelectors.getReferenceScores(state),
      contacts,
      hasContacts: contacts && contacts.size > 0,
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      hasLocations: locations && locations.size > 0
    }
  },
  {
    loadCommunities,
    onRemoveTags: removeTags,
    onAddSuggestedTags: addSuggestedTags,
    onAddTags: addTags,
    onSaveCompanyInfo: saveCompanyInfo,
    onRemoveAllContacts: removeAllContacts
  }
)(CompanyProfilePageContainer)
