import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import PageSideSection from 'shared/components/PageSideSection'
import VetTeamListContainer from '../../containers/VetTeamListContainer'
import Scrollable from 'shared/components/Scrollable'
import AddVetTeamMember from '../../containers/AddVetTeamMember'
import { FormattedMessage } from 'react-intl'

type Props = {
  vetIsReadonly?: boolean
} & RouteComponentProps

class VetTeamCard extends Component<Props> {
  render() {
    const { vetIsReadonly } = this.props

    return (
      <PageSideSection
        title={
          <FormattedMessage id='VetTeamCards.Team' defaultMessage='Team' />
        }
        noPadding
      >
        <div className='ph3-5 pb3-5'>
          <Scrollable>
            <VetTeamListContainer mini />
          </Scrollable>
        </div>
        {!vetIsReadonly && (
          <AddVetTeamMember
            userLookupLabel={
              <FormattedMessage
                id='VetTeamCards.AddAMember'
                defaultMessage='Add a member'
              />
            }
          />
        )}
      </PageSideSection>
    )
  }
}

export default withRouter(VetTeamCard)
