import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { List } from 'immutable'
import RootState from 'shared/models/RootState'
import Page from 'shared/components/Page'
import Paper from 'shared/components/Paper'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid'
import Text from 'shared/components/Text'
import Divider from 'shared/components/Divider'
import { FormattedMessage } from 'react-intl'
import {
  loadSpend,
  loadSpendDetail,
  getSpendYears,
  generateDiversityReport,
  setDiversityCategory
} from '../../store/actions'
import moment, { Moment } from 'moment'
import { withStyles, makeStyles } from '@material-ui/styles'
import insightsSelectors from '../../store/insightsSelectors'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import { getCurrentGrouping } from '../../store/diversityReportSelectors'
import paths from '../../routes/paths'
import YearSpendOverview from '../YearSpendOverview'
import YearSpendDetail from '../YearSpendDetail'
import parsePath from 'shared/utils/parsePath'
import buyerPaths from '../../../routes/paths'
import DiversityReportButtonFilters from '../DiversityReport/DiversityReportButtonFilters'
import DiversityReport from '../DiversityReport'
import qs from 'qs'
import ImageArrowDown from 'shared/assets/icons/arrow-down.svg'
import ImageArrowUp from 'shared/assets/icons/arrow-up.svg'
import IconDot from '@material-ui/icons/FiberManualRecord'
import {
  getDateFromQuarter,
  generateMarks,
  getTextFromQuarter,
  getQuarterFromDate
} from 'shared/utils/sliderUtilsForSpendAndLoader'
import { useLocation } from 'react-router'
import DiversitySummaryContainer from '../DiversityReport/DiversitySummaryContainer'
import { Skeleton } from '@material-ui/lab'
import numberFormat from 'shared/utils/numberFormat'
import useThemeColors from 'shared/utils/useThemeColors'
import ExportDiversitySnapshot from '../../components/ExportDiversitySnapshot'

const CustomSlider = withStyles({
  root: {
    marginTop: 14,
    marginLeft: 8,
    width: '90%'
  },
  mark: {
    height: 8,
    width: 1,
    marginTop: -3
  },
  markLabel: {
    marginLeft: '6px',
    fontSize: '12px'
  }
})(Slider)

const useStyles = makeStyles({
  hoverOutline: {
    '&:focus': {
      outline: 'auto black'
    },
    '&:hover': {
      background: '#DCF6F4'
    }
  }
})

export type Overview = {
  index: number
  category: string
  country: string
  spendgroup: string
  totalAmount: number
  diverseAmount: number
  numSuppliers: number
  preferredAmount: number
  diversePercentage: number
  preferredPercentage: number
}

type Props = {
  years: List<string>
  yearEnd: string
  minSpendDate: Moment | undefined
  maxSpendDate: Moment | undefined
  setDiversityCategory: (params) => void
  loadSpend: (params) => void
  loadSpendDetail: (params) => void
  generateDiversityReport: (params) => void
  totalSpend: number
  selectedSpend: number
  isLoadingSpend: boolean
  isLoadingDetail: boolean
  isLoadingDiverseSpend: boolean
  hasSpendGroup: boolean
  useSpendGroup: boolean
  countries: List<string>
  categories: List<string>
  spendgroups: List<string>
  qualifiedCountries: List<string>
  currentGrouping: string
  acceptCertBasedOnReportStartDate: boolean
}

export const SpendPage = (props: Props) => {
  const history = useHistory()
  const colors = useThemeColors()
  const styles = useStyles()
  const {
    minSpendDate,
    maxSpendDate,
    totalSpend,
    selectedSpend,
    isLoadingSpend,
    isLoadingDetail,
    isLoadingDiverseSpend,
    years,
    yearEnd,
    loadSpend,
    loadSpendDetail,
    generateDiversityReport,
    setDiversityCategory,
    useSpendGroup,
    countries,
    categories,
    spendgroups,
    qualifiedCountries,
    currentGrouping,
    acceptCertBasedOnReportStartDate
  } = props

  const location = useLocation()
  const isDiverseSpend = location.pathname === paths.spendDiverse
  const { show } = qs.parse(location.search, {
    ignoreQueryPrefix: true
  })
  const [showFilters, setShowFilters] = useState<boolean>(!isDiverseSpend)
  const [fromQuarter, setFromQuarter] = useState<number>(0)
  const [toQuarter, setToQuarter] = useState<number>(0)

  const [startDate, setStartDate] = useState<number>(fromQuarter)
  const [endDate, setEndDate] = useState<number>(toQuarter)

  const defaultValue = [fromQuarter, toQuarter]

  const supplierLink = supplierId => {
    return parsePath(buyerPaths.supplierProfile, { supplierId })
  }

  const isFiltersActive = useMemo(() => {
    return (
      (useSpendGroup ? spendgroups.size > 0 : categories.size > 0) ||
      countries.size > 0
    )
  }, [useSpendGroup, spendgroups.size, categories.size, countries.size])

  useEffect(() => {
    setShowFilters(!isDiverseSpend)
  }, [isDiverseSpend])

  useEffect(() => {
    // figure out start of the slider, if max date more than a year after min date
    // set fromQuarter a year before max date
    // else set fromQuarter to min date
    if (maxSpendDate && minSpendDate) {
      const rangeOverYear = maxSpendDate.diff(minSpendDate, 'years', true)
      const from =
        rangeOverYear >= 1
          ? getQuarterFromDate(
              moment(maxSpendDate).subtract(3, 'quarters'),
              yearEnd
            )
          : getQuarterFromDate(minSpendDate, yearEnd)

      setFromQuarter(from)
      setToQuarter(getQuarterFromDate(maxSpendDate, yearEnd))
    }
  }, [maxSpendDate, minSpendDate, yearEnd])

  useEffect(() => {
    if (fromQuarter && toQuarter) {
      loadSpend({
        startDate: getDateFromQuarter(fromQuarter, false, yearEnd),
        endDate: getDateFromQuarter(toQuarter, true, yearEnd)
      })
    }
  }, [loadSpend, fromQuarter, toQuarter, yearEnd])

  useEffect(() => {
    if (fromQuarter && toQuarter) {
      if (isDiverseSpend) {
        generateDiversityReport({
          startDate: getDateFromQuarter(fromQuarter, false, yearEnd),
          endDate: getDateFromQuarter(toQuarter, true, yearEnd),
          countries:
            qualifiedCountries?.size > 0
              ? qualifiedCountries.toJS()
              : countries.toJS(),
          categories: useSpendGroup ? undefined : categories.toJS(),
          spendGroups: useSpendGroup ? spendgroups.toJS() : undefined,
          startDateExpiration: acceptCertBasedOnReportStartDate
        })
      } else {
        loadSpendDetail({
          startDate: getDateFromQuarter(fromQuarter, false, yearEnd),
          endDate: getDateFromQuarter(toQuarter, true, yearEnd),
          countries: countries.toJS(),
          categories: useSpendGroup ? undefined : categories.toJS(),
          spendGroups: useSpendGroup ? spendgroups.toJS() : undefined,
          groupByCategory: !useSpendGroup
        })
      }
    }
  }, [
    isDiverseSpend,
    loadSpendDetail,
    generateDiversityReport,
    qualifiedCountries,
    useSpendGroup,
    fromQuarter,
    toQuarter,
    countries,
    categories,
    spendgroups,
    yearEnd,
    acceptCertBasedOnReportStartDate
  ])

  const handleChange = (event, value: [number, number]) => {
    setStartDate(value[0])
    setEndDate(value[1])
  }

  const handleChangeCommitted = (event, value: [number, number]) => {
    if (value.length === 2) {
      setFromQuarter(value[0])
      setToQuarter(value[1])
    }
  }

  const scrollToDetails = () => {
    document.getElementById('details')?.scrollIntoView({ behavior: 'smooth' })
  }

  return years && years.size > 0 ? (
    <Page
      title={
        <FormattedMessage
          id='SpendPage.SpendPage'
          defaultMessage='Spend Page'
        />
      }
    >
      <ScrollToPosition />
      <Paper>
        <div className='flex justify-between items-center flex-wrap'>
          <div className='w-100 w-50-ns'>
            {!!fromQuarter && !!toQuarter && (
              <h3
                className='mt3 mb0 f5 mid-gray fw6 flex items-center justify-between pr3'
                id='reportRange'
              >
                {fromQuarter === toQuarter ? (
                  getTextFromQuarter(fromQuarter)
                ) : (
                  <FormattedMessage
                    id='Spend.DateRangeReport'
                    defaultMessage='Report on {fromQuarter} to {toQuarter}'
                    values={{
                      fromQuarter: getTextFromQuarter(fromQuarter),
                      toQuarter: getTextFromQuarter(toQuarter)
                    }}
                  />
                )}
                {yearEnd && yearEnd !== '12/31' && (
                  <span
                    className={`dib white pa2 f8 ${colors.primaryContained}`}
                  >
                    <FormattedMessage
                      id='SpendPage.YearEnd'
                      defaultMessage='Year End'
                    />
                    &nbsp;{yearEnd}
                  </span>
                )}
              </h3>
            )}
            {minSpendDate && maxSpendDate && (
              <CustomSlider
                aria-labelledby='reportRange'
                step={25}
                min={getQuarterFromDate(minSpendDate, yearEnd)}
                max={getQuarterFromDate(maxSpendDate, yearEnd)}
                onChange={handleChange}
                value={
                  startDate && endDate ? [startDate, endDate] : defaultValue
                }
                onChangeCommitted={handleChangeCommitted}
                marks={generateMarks(
                  getQuarterFromDate(minSpendDate, yearEnd),
                  getQuarterFromDate(maxSpendDate, yearEnd),
                  startDate && endDate ? [startDate, endDate] : defaultValue
                )}
                getAriaValueText={getTextFromQuarter}
                orientation='horizontal'
              />
            )}
          </div>
          <div className='w-100 w-25-ns'>
            <div className='tr f7'>
              <FormattedMessage
                id='Spend.TotalSpend'
                defaultMessage='Total Spend'
              />
            </div>
            <div className='tr f5 fw6 '>
              {isLoadingSpend ? (
                <Skeleton animation='wave' height={23} />
              ) : (
                `$${numberFormat(totalSpend || 0, 0)}`
              )}
              {isDiverseSpend && !isLoadingDiverseSpend ? (
                <ExportDiversitySnapshot
                  startDate={startDate ? startDate : defaultValue[0]}
                  endDate={endDate ? endDate : defaultValue[1]}
                />
              ) : null}
            </div>
          </div>
        </div>
        <Divider className='mt1' />
        <button
          className={`mt2 flex items-center justify-between pointer bg-transparent bn ${styles.hoverOutline} w-100`}
          aria-label={
            (showFilters ? 'Collapse Filters, ' : 'Expand Filters, ') +
            (isFiltersActive
              ? `Filters on
            ${
              !(isLoadingSpend || isLoadingDetail || isLoadingDiverseSpend)
                ? `, Selected Spend: $${numberFormat(selectedSpend || 0, 0)}`
                : ''
            }`
              : 'Filters off')
          }
          aria-expanded={showFilters}
          onClick={() => setShowFilters(!showFilters)}
        >
          <img
            src={showFilters ? ImageArrowUp : ImageArrowDown}
            className='mr2 mv3'
            alt={showFilters ? 'Collapse Filters' : 'Expand Filters'}
          />
          <span className='f7 mr1'>
            <FormattedMessage
              id='SpendPage.Filters'
              defaultMessage='Filters:'
            />
          </span>

          {isFiltersActive ? (
            <>
              <IconDot style={{ color: 'green' }} fontSize='small' />
              <span className='f7 ml1'>
                <FormattedMessage
                  id='SpendPage.ShowFiltersOn'
                  defaultMessage='On'
                />
              </span>
            </>
          ) : (
            <>
              <IconDot style={{ color: '#555555' }} fontSize='small' />
              <span className='f7 ml1'>
                <FormattedMessage
                  id='SpendPage.ShowFiltersOff'
                  defaultMessage='Off'
                />
              </span>
            </>
          )}

          <div className='flex-auto flex justify-end'>
            {isFiltersActive && (
              <div className='w-50 w-25-ns' aria-live='polite'>
                {isLoadingSpend || isLoadingDetail || isLoadingDiverseSpend ? (
                  <div className='tr f7'>
                    <Skeleton animation='wave' height={40} />
                  </div>
                ) : (
                  <>
                    <div className='tr f7'>
                      <FormattedMessage
                        id='Spend.SelectedSpend'
                        defaultMessage='Selected Spend'
                      />
                    </div>
                    <div className='tr f5 fw6'>
                      {`$${numberFormat(selectedSpend || 0, 0)}`}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </button>
        {showFilters && <YearSpendOverview />}
        {isDiverseSpend && (
          <>
            <Divider className='mv2' />
            <Grid container spacing={3} alignItems='center'>
              <Grid item md={6}>
                <DiversityReportButtonFilters
                  disabled={isLoadingDiverseSpend}
                />
              </Grid>
              <Grid item md={6}>
                <Text>
                  <FormattedMessage
                    id='Spend.QualificationHelp'
                    defaultMessage='Use Qualification Rules to determine the certifications, agencies, locations, and other specifications for marking suppliers as Qualified or Potential.'
                  />
                </Text>
              </Grid>
            </Grid>
          </>
        )}
        {isDiverseSpend && (
          <DiversitySummaryContainer
            isLoading={isLoadingDiverseSpend}
            onQualifiedClick={() => {
              history.push(`${paths.spendDiverse}?show=qualified`)
              scrollToDetails()
            }}
            onPotentialClick={() => {
              history.push(`${paths.spendDiverse}?show=potential`)
              scrollToDetails()
            }}
            onDisqualifiedClick={() => {
              history.push(`${paths.spendDiverse}?show=disqualified`)
              scrollToDetails()
            }}
            onMbeClick={
              currentGrouping === 'subCategory'
                ? () => {
                    setDiversityCategory('mbe')
                    history.push(`${paths.spendDiverse}?show=qualified`)
                    scrollToDetails()
                  }
                : undefined
            }
            onSbeClick={
              currentGrouping === 'subCategory'
                ? () => {
                    setDiversityCategory('sbe')
                    history.push(`${paths.spendDiverse}?show=qualified`)
                    scrollToDetails()
                  }
                : undefined
            }
            onVbeClick={
              currentGrouping === 'subCategory'
                ? () => {
                    setDiversityCategory('vbe')
                    history.push(`${paths.spendDiverse}?show=qualified`)
                    scrollToDetails()
                  }
                : undefined
            }
            onWbeClick={
              currentGrouping === 'subCategory'
                ? () => {
                    setDiversityCategory('wbe')
                    history.push(`${paths.spendDiverse}?show=qualified`)
                    scrollToDetails()
                  }
                : undefined
            }
          />
        )}
      </Paper>
      {isDiverseSpend ? (
        <DiversityReport show={show || 'qualified'} />
      ) : (
        <YearSpendDetail
          type={useSpendGroup ? 'spendgroup' : 'category'}
          supplierLink={supplierLink}
        />
      )}
    </Page>
  ) : (
    <Page title={<FormattedMessage id='SpendPage.SpendPage' />}>
      <Paper>
        <h5 className='mt3-5 tc mid-gray f7 black fw6 ma0'>
          <FormattedMessage
            id='SpendPage.ProvideTealbookWithYourHistoricalSpend'
            defaultMessage='Provide TealBook with your historical spend data to unlock new insights'
          />
        </h5>
      </Paper>
    </Page>
  )
}

export default connect(
  (state: RootState) => {
    return {
      years: insightsSelectors.getSpendYears(state),
      yearEnd: state.getIn(['buyer', 'settings', 'yearEnd']),
      minSpendDate: insightsSelectors.getMinSpendDate(state),
      maxSpendDate: insightsSelectors.getMaxSpendDate(state),
      totalSpend: insightsSelectors.getTotalSpend(state),
      selectedSpend: insightsSelectors.getSelectedSpend(state),
      isLoadingSpend: insightsSelectors.isLoadingSpend(state),
      isLoadingDetail: state.getIn(['buyer', 'insights', 'isLoadingDetail']),
      isLoadingDiverseSpend: state.get('buyer').get('diversityReport').loading,
      useSpendGroup: !!insightsSelectors.getSpendField(state, 'useSpendGroup'),
      countries: insightsSelectors.getSpendField(state, 'countries'),
      categories: insightsSelectors.getSpendField(state, 'categories'),
      spendgroups: insightsSelectors.getSpendField(state, 'spendgroups'),
      qualifiedCountries: state.getIn([
        'buyer',
        'diversityReport',
        'diverseQualificationRules',
        'baseRules',
        'countries'
      ]),
      currentGrouping: getCurrentGrouping(state),
      acceptCertBasedOnReportStartDate: settingsSelectors.getAcceptCertBasedOnReportStartDate(
        state
      )
    }
  },
  {
    getSpendYears,
    loadSpend,
    loadSpendDetail,
    generateDiversityReport,
    setDiversityCategory
  }
)(SpendPage)
