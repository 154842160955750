import moment from 'moment'

export const getDateFromQuarter = (
  quarter: number,
  endDate?: boolean,
  yearEnd = '12/31'
) => {
  // eg. yearEnd = 11/20
  // 2019 q1 begins 2019/12/20, q2 begins 2020/02/20, q3 begins 2020/05/20, q4 begins 2020/08/20
  const year = Math.trunc(quarter / 100)
  const fiscalStart =
    yearEnd === '2/28' || yearEnd === '2/29'
      ? moment(new Date(`${year}/3/1`))
      : moment(new Date(`${year}/${yearEnd}`)).add(1, 'day')

  const quarterNumber = (quarter - year * 100) / 25 + 1
  const quarterDate =
    quarterNumber === 1
      ? moment(
          `${year}/${fiscalStart.month() + 1}/${fiscalStart.date()}`,
          'YYYY/MM/DD'
        )
      : quarterNumber === 2
      ? moment(
          `${year}/${fiscalStart.month() + 1}/${fiscalStart.date()}`,
          'YYYY/MM/DD'
        ).add(1, 'quarter')
      : quarterNumber === 3
      ? moment(
          `${year}/${fiscalStart.month() + 1}/${fiscalStart.date()}`,
          'YYYY/MM/DD'
        ).add(2, 'quarters')
      : moment(
          `${year}/${fiscalStart.month() + 1}/${fiscalStart.date()}`,
          'YYYY/MM/DD'
        ).add(3, 'quarters')

  return !endDate
    ? quarterDate.format('YYYY-MM-DD')
    : quarterDate
        .add(1, 'quarter')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
}

export const getQuarterFromDate = (date: moment.Moment, yearEnd = '12/31') => {
  const fiscalStart = moment(new Date(`${date.year()}/${yearEnd}`)).add(
    1,
    'day'
  )

  if (fiscalStart.isSameOrAfter(date, 'day')) {
    fiscalStart.subtract(1, 'year')
  }

  const quarterNumber = moment(date).isSameOrAfter(
    moment(fiscalStart).add(3, 'quarters')
  )
    ? Number(`${fiscalStart.year()}75`)
    : moment(date).isSameOrAfter(moment(fiscalStart).add(2, 'quarters'))
    ? Number(`${fiscalStart.year()}50`)
    : moment(date).isSameOrAfter(moment(fiscalStart).add(1, 'quarters'))
    ? Number(`${fiscalStart.year()}25`)
    : Number(`${fiscalStart.year()}00`)

  return quarterNumber
}

export const generateMarks = (
  start: number,
  end: number,
  current: number[]
) => {
  let marks: Array<{ value: number; label: undefined | number }> = []
  const [currentStart, currentEnd] = current
  const quarterMap = {
    '00': 'Q1',
    '25': 'Q2',
    '50': 'Q3',
    '75': 'Q4'
  }
  while (start <= end) {
    marks.push({
      value: start,
      label:
        start % 100 === 0 && start === currentEnd
          ? `${start / 100} ${quarterMap[currentEnd.toString().substr(4)]}`
          : start % 100 === 0
          ? start / 100
          : start === currentEnd
          ? quarterMap[currentEnd.toString().substr(4)]
          : start === currentStart
          ? quarterMap[currentStart.toString().substr(4)]
          : undefined
    })
    start += 25
  }
  return marks
}

export const getTextFromQuarter = (quarterValue?: number): string => {
  if (!quarterValue) {
    return ''
  }
  const year = Math.trunc(quarterValue / 100)
  const quarterNumber = (quarterValue - year * 100) / 25 + 1
  return `${year} Q${quarterNumber}`
}
