import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { removeVetContact } from '../../actions'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import { List, RecordOf } from 'immutable'

const CustomIconButton = withStyles({
  root: {
    height: 32,
    width: 32
  }
})(IconButton)

type Props = {
  readonly?: boolean
  removeVetContact: (user: string) => void
  supplierContacts: List<
    RecordOf<{
      user: string
    }>
  >
}

export class VetContactListContainer extends Component<Props> {
  render() {
    const { readonly, supplierContacts, removeVetContact } = this.props
    return supplierContacts ? (
      <Fragment>
        {supplierContacts.map(user => (
          <UserListItemContainer
            key={user.get('user')}
            userId={user.get('user')}
            mini
            profileUrl={parsePath(paths.userProfile, {
              userId: user.get('user')
            })}
            rightButton={
              !readonly && (
                <CustomIconButton
                  onClick={() => removeVetContact(user.get('user'))}
                  className='dim'
                >
                  <CloseIcon />
                </CustomIconButton>
              )
            }
          />
        ))}
      </Fragment>
    ) : null
  }
}

export default connect(
  state => {
    const vetBuyerSupplier = vetBuyerSupplierSelectors.getVetBuyerSupplier(
      state
    )
    return {
      supplierContacts:
        vetBuyerSupplier &&
        vetBuyerSupplier.getIn(['collaboration', 'sendToContacts'])
    }
  },
  { removeVetContact }
)(VetContactListContainer)
