import { call, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { notify } from 'shared/actions'
import tier2 from 'shared/utils/api/tier2'

export function* createTier2CommunitySaga() {
  const response = yield call(tier2.createTier2Community)

  yield put(
    notify({
      message: `The Tier 2 community has been created`
    })
  )

  return { byId: { [response.id]: response } }
}

export default createApiRequestSaga(createTier2CommunitySaga, {
  notifyOnError: true
})
