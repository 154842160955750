import React, {
  ChangeEvent,
  useCallback,
  useState,
  useEffect,
  useMemo
} from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'
import Input from 'shared/components/Input'
import Text from 'shared/components/Text'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { MetricUnit } from '../MetricOrCustomQuestionList/MetricOrCustomQuestionList'

export const placeholderAndTextMessages = defineMessages({
  placeholder: {
    id: 'MetricQuestion.enter',
    defaultMessage: 'Enter'
  },
  placeholderYear: {
    id: 'MetricQuestion.enterYear',
    defaultMessage: 'Enter year'
  },
  placeholderMetric: {
    id: 'MetricQuestion.selectMetric',
    defaultMessage: 'Select metric or type a new one'
  },
  byText: {
    id: 'MetricQuestion.byText',
    defaultMessage: ' by '
  },
  basedOnText: {
    id: 'MetricQuestion.basedOnYear',
    defaultMessage: ' based on baseline projections performed in '
  }
})

export const dropdownMessages = defineMessages({
  reduction: {
    id: 'MetricQuestion.reduction',
    defaultMessage: 'reduction in'
  },
  increase: {
    id: 'MetricQuestion.increase',
    defaultMessage: 'increase in'
  },
  energyIntensity: {
    id: 'MetricQuestion.energyIntensity',
    defaultMessage: 'Energy Intensity'
  },
  energyProductivity: {
    id: 'MetricQuestion.energyProductivity',
    defaultMessage: 'Energy Productivity'
  },
  primaryEnergyConsumption: {
    id: 'MetricQuestion.primaryEnergyConsumption',
    defaultMessage: 'Primary Energy Consumption'
  },
  waterConsumption: {
    id: 'MetricQuestion.waterConsumption',
    defaultMessage: 'Total Water Consumption'
  },
  solidWaste: {
    id: 'MetricQuestion.solidWaste',
    defaultMessage: 'Solid Waste'
  },
  hazardousWaste: {
    id: 'MetricQuestion.hazardousWaste',
    defaultMessage: 'Hazardous Waste'
  },
  recycling: {
    id: 'MetricQuestion.recycling',
    defaultMessage: 'Recycling'
  },
  foodWaste: {
    id: 'MetricQuestion.foodWaste',
    defaultMessage: 'Food Waste'
  },
  materialsConsumption: {
    id: 'MetricQuestion.materialsConsumption',
    defaultMessage: 'Materials Consumption'
  },
  totalWaste: {
    id: 'MetricQuestion.totalWaste',
    defaultMessage: 'Total Waste'
  },
  methaneEmissions: {
    id: 'MetricQuestion.methaneEmissions',
    defaultMessage: 'Methane Emissions'
  },
  carbonEmissions: {
    id: 'MetricQuestion.carbonEmissions',
    defaultMessage: 'Carbon Emissions'
  },
  gasEmissions: {
    id: 'MetricQuestion.gasEmissions',
    defaultMessage: 'All Greenhouse Gas Emissions'
  }
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightMargin: {
      marginRight: theme.spacing(1)
    },
    marginBothSides: {
      margin: theme.spacing(1)
    },
    inputFont: {
      fontSize: '0.825rem'
    },
    inputYearWidth: {
      width: '85px'
    },
    inputPercentWidth: {
      width: '80px'
    }
  })
)

const dropdownStyle = (width: string) => {
  return {
    placeholder: base => ({
      ...base,
      fontSize: '0.825rem',
      color: '#C0C0C0'
    }),
    control: base => ({
      ...base,
      fontSize: '0.825rem',
      width
    }),
    menuList: base => ({
      ...base,
      fontSize: '0.825rem'
    })
  }
}

export interface Option {
  label: string
  value: number
}

type Props = {
  pageId: string
  parentQuestionId?: string
  questionId: string
  className?: string
  fieldName?: 'answer' | 'reason'
  metric: MetricUnit
  metricIndex: number
  onMetricChange: any
}

const MetricQuestion = (props: Props) => {
  const intl = useIntl()
  const classes = useStyles()

  const {
    parentQuestionId,
    questionId,
    className,
    metric,
    metricIndex,
    onMetricChange
  } = props

  const [percentage, setPercentage] = useState<string>('')
  const [reductionOrIncrease, setReductionOrIncrease] = useState<Option>()
  const [metricType, setMetricType] = useState<Option>()
  const [futureYear, setFutureYear] = useState<string>('')
  const [pastYear, setPastYear] = useState<string>('')

  const percentageSign = '% '

  const reduceOrIncreaseOptions: Option[] = [
    { label: intl.formatMessage(dropdownMessages.reduction), value: 1 },
    { label: intl.formatMessage(dropdownMessages.increase), value: 2 }
  ]

  const energyEfficiencyMetrics: Option[] = useMemo(() => {
    return [
      { label: intl.formatMessage(dropdownMessages.energyIntensity), value: 1 },
      {
        label: intl.formatMessage(dropdownMessages.energyProductivity),
        value: 2
      },
      {
        label: intl.formatMessage(dropdownMessages.primaryEnergyConsumption),
        value: 3
      }
    ]
  }, [intl])

  const waterUsageMetrics: Option[] = useMemo(() => {
    return [
      { label: intl.formatMessage(dropdownMessages.waterConsumption), value: 1 }
    ]
  }, [intl])

  const wasteMetrics: Option[] = useMemo(() => {
    return [
      { label: intl.formatMessage(dropdownMessages.solidWaste), value: 1 },
      { label: intl.formatMessage(dropdownMessages.hazardousWaste), value: 2 },
      { label: intl.formatMessage(dropdownMessages.recycling), value: 3 },
      { label: intl.formatMessage(dropdownMessages.foodWaste), value: 4 },
      {
        label: intl.formatMessage(dropdownMessages.materialsConsumption),
        value: 5
      },
      { label: intl.formatMessage(dropdownMessages.totalWaste), value: 6 }
    ]
  }, [intl])

  const emissions: Option[] = useMemo(() => {
    return [
      {
        label: intl.formatMessage(dropdownMessages.methaneEmissions),
        value: 1
      },
      { label: intl.formatMessage(dropdownMessages.carbonEmissions), value: 2 },
      { label: intl.formatMessage(dropdownMessages.gasEmissions), value: 3 }
    ]
  }, [intl])

  const metrics = useMemo(() => {
    return parentQuestionId === 'goalsOrTargets'
      ? questionId === 'improvingEnergyEfficiency'
        ? energyEfficiencyMetrics
        : questionId === 'managingWaterUsage'
        ? waterUsageMetrics
        : questionId === 'managingWaste'
        ? wasteMetrics
        : []
      : parentQuestionId === 'ghgReductionTarget' &&
        questionId === 'targetDateNumber'
      ? emissions
      : []
  }, [
    parentQuestionId,
    questionId,
    energyEfficiencyMetrics,
    waterUsageMetrics,
    wasteMetrics,
    emissions
  ])

  const searchValue = useCallback((inputValue: string, list: Option[]) => {
    let opt = list.find(option => {
      return option.label === inputValue
    })
    return opt === undefined ? 0 : opt.value
  }, [])

  useEffect(() => {
    const isInputEmpty =
      percentage === '' &&
      reductionOrIncrease === undefined &&
      metricType === undefined &&
      futureYear === '' &&
      pastYear === ''
    if (metric.value && isInputEmpty) {
      let indexIncreaseOrReduct = metric.value.indexOf(
        intl.formatMessage(dropdownMessages.increase)
      )
      if (indexIncreaseOrReduct === -1) {
        indexIncreaseOrReduct = metric.value.indexOf(
          intl.formatMessage(dropdownMessages.reduction)
        )
      }
      const indexByText = metric.value.indexOf(
        intl.formatMessage(placeholderAndTextMessages.byText)
      )
      const indexBasedOnText = metric.value.indexOf(
        intl.formatMessage(placeholderAndTextMessages.basedOnText)
      )

      if (
        indexIncreaseOrReduct !== -1 &&
        indexByText > indexIncreaseOrReduct &&
        indexBasedOnText > indexByText
      ) {
        const percentSubString = metric.value.substr(
          0,
          indexIncreaseOrReduct - percentageSign.length
        )
        setPercentage(percentSubString)

        let metricStart
        if (
          metric.value
            .substr(indexIncreaseOrReduct)
            .startsWith(intl.formatMessage(dropdownMessages.increase))
        ) {
          setReductionOrIncrease({
            label: intl.formatMessage(dropdownMessages.increase),
            value: 2
          })
          metricStart =
            indexIncreaseOrReduct +
            intl.formatMessage(dropdownMessages.increase).length +
            1
        } else {
          setReductionOrIncrease({
            label: intl.formatMessage(dropdownMessages.reduction),
            value: 1
          })
          metricStart =
            indexIncreaseOrReduct +
            intl.formatMessage(dropdownMessages.reduction).length +
            1
        }

        const metricLength = indexByText - metricStart
        const metricLabel = metric.value.substr(metricStart, metricLength)
        setMetricType({
          label: metricLabel,
          value: searchValue(metricLabel, metrics)
        })

        const futureYearStart =
          indexByText +
          intl.formatMessage(placeholderAndTextMessages.byText).length
        const futureYearLength = indexBasedOnText - futureYearStart
        setFutureYear(metric.value.substr(futureYearStart, futureYearLength))

        const pastYearStart =
          indexBasedOnText +
          intl.formatMessage(placeholderAndTextMessages.basedOnText).length
        setPastYear(metric.value.substr(pastYearStart))
      }
    }
  }, [
    metric,
    intl,
    searchValue,
    metrics,
    percentage,
    reductionOrIncrease,
    metricType,
    futureYear,
    pastYear
  ])

  const isReductionOnly =
    parentQuestionId === 'ghgReductionTarget' ||
    (parentQuestionId === 'goalsOrTargets' &&
      questionId === 'managingWaterUsage')

  const debouncedSaveSurveyResponse = useDebouncedCallback(() => {
    if (
      percentage !== '' &&
      (reductionOrIncrease || isReductionOnly) &&
      metricType &&
      futureYear !== '' &&
      pastYear !== ''
    ) {
      const value =
        percentage +
        percentageSign +
        (isReductionOnly
          ? intl.formatMessage(dropdownMessages.reduction)
          : reductionOrIncrease?.label) +
        ' ' +
        metricType.label +
        intl.formatMessage(placeholderAndTextMessages.byText) +
        futureYear +
        intl.formatMessage(placeholderAndTextMessages.basedOnText) +
        pastYear

      onMetricChange(
        { value: value, source: 'metric', key: metric.key },
        metricIndex
      )
    }
  }, 500)

  const handlePercentageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPercentage(e.target.value)
      debouncedSaveSurveyResponse.callback()
    },
    [debouncedSaveSurveyResponse]
  )

  const handleReductionOrIncrease = useCallback(
    (e: Option) => {
      setReductionOrIncrease(e)
      debouncedSaveSurveyResponse.callback()
    },
    [debouncedSaveSurveyResponse]
  )

  const handleMetricChange = useCallback(
    (e: Option) => {
      setMetricType(e)
      debouncedSaveSurveyResponse.callback()
    },
    [debouncedSaveSurveyResponse]
  )

  const handleFutureYearChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFutureYear(e.target.value)
      debouncedSaveSurveyResponse.callback()
    },
    [debouncedSaveSurveyResponse]
  )

  const handlePastYearChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPastYear(e.target.value)
      debouncedSaveSurveyResponse.callback()
    },
    [debouncedSaveSurveyResponse]
  )

  return (
    <div className={className}>
      <div className='flex justify items-center flex-wrap'>
        <Input
          aria-label='percentage input'
          value={percentage}
          onChange={handlePercentageChange}
          required
          placeholder={intl.formatMessage(
            placeholderAndTextMessages.placeholder
          )}
          className={classes.inputFont + ' ' + classes.inputPercentWidth}
        />
        <Text className={classes.rightMargin}>{percentageSign}</Text>
        {isReductionOnly && (
          <Text className={classes.rightMargin}>
            {intl.formatMessage(dropdownMessages.reduction)}
          </Text>
        )}
        {!isReductionOnly && (
          <Select
            aria-label='reduction or increase'
            onChange={handleReductionOrIncrease}
            value={reductionOrIncrease}
            required
            className={classes.rightMargin}
            options={reduceOrIncreaseOptions}
            styles={dropdownStyle('130px')}
          />
        )}
        <Creatable
          aria-label='metric dropdown'
          onChange={handleMetricChange}
          options={metrics}
          value={metricType}
          placeholder={intl.formatMessage(
            placeholderAndTextMessages.placeholderMetric
          )}
          styles={dropdownStyle('250px')}
          formatCreateLabel={inputValue => {
            return (
              <FormattedMessage
                id='MetricQuestion.CreateLabel'
                defaultMessage='Add {hashtag}'
                values={{ hashtag: inputValue }}
              />
            )
          }}
        />
        <Text className={classes.marginBothSides}>
          {intl.formatMessage(placeholderAndTextMessages.byText)}
        </Text>
        <Input
          aria-label='future year input'
          value={futureYear}
          onChange={handleFutureYearChange}
          required
          placeholder={intl.formatMessage(
            placeholderAndTextMessages.placeholderYear
          )}
          className={classes.inputFont + ' ' + classes.inputYearWidth}
        />
        <Text className={classes.marginBothSides}>
          {intl.formatMessage(placeholderAndTextMessages.basedOnText)}
        </Text>
        <Input
          aria-label='past year input'
          value={pastYear}
          onChange={handlePastYearChange}
          required
          placeholder={intl.formatMessage(
            placeholderAndTextMessages.placeholderYear
          )}
          className={classes.inputFont + ' ' + classes.inputYearWidth}
        />
      </div>
    </div>
  )
}

export default MetricQuestion
