import React, { Component, Fragment, ReactElement } from 'react'
import { Map, List, RecordOf } from 'immutable'
import Button from 'shared/components/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import Text from 'shared/components/Text'
import VetSupplierListItem from '../../containers/VetSupplierListItem'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import parsePath from 'shared/utils/parsePath'
import paths from '../../routes/paths'
import { translatedFilterKeys } from 'shared/utils/data/defineMessages'
import camelCase from 'lodash.camelcase'
import AddContactSendContainer from '../../containers/AddContactSendContainer'

type Props = {
  vetId: string
  currentUrl: string
  vetApproval: boolean
  hasInvited: boolean
  vetIsReadonly: boolean
  inviteSuppliers: boolean
  missingRequired: Array<string>
  invalidResponseDate: boolean
  isApprover: boolean
  isOwner: boolean
  redirectFrom: string
  vetBuyerSuppliers: Map<
    string,
    List<
      RecordOf<{
        vetBS: {
          id: string
          collaboration: { status: string }
        }
      }>
    >
  >
  push: (parsePath: string) => void
  closeAddContactDialog: () => void
  onRemoveVetSupplier: (params: { vetBuyerSupplierId: string }) => void
  onApproveVetSupplier: (params: { vetBuyerSupplierIds: string[] }) => void
  onInviteVetSupplier: (params: { vetBuyerSupplierIds: string[] }) => void
  onUpdateVetSupplier: (params: {
    vetBuyerSupplierId: string
    doNotContact?: boolean
    incumbent?: boolean
  }) => void
  selectNewUser: (value: string, { orgUnitId: string }) => void
  removeContactSendTo: (arg: {
    vetBuyerSupplierId: string
    userId: string
  }) => void
  updateContactSendTo: (arg: {
    vetBuyerSupplierId: string
    user1: {
      user: string
      primary?: boolean
      secondary?: boolean
    }
    user2?: {
      user: string
      primary?: boolean
      secondary?: boolean
    }
  }) => void
  intl: IntlShape
  selectedVetBSId?: string
  onClick?: (vetBuyerSupplierId: string) => void
}

type State = {
  dialogOpen: undefined | string
  message: undefined | string | ReactElement<FormattedMessage>
  secondMessage?: undefined | string | ReactElement<FormattedMessage>
  action: undefined | (() => void)
  vbsIds: string[]
  addingContact: boolean
  orgUnitId: undefined | string
}

export class VetSupplierList extends Component<Props, State> {
  state = {
    dialogOpen: undefined,
    message: undefined,
    secondMessage: undefined,
    action: undefined,
    vbsIds: [],
    addingContact: false,
    orgUnitId: undefined
  }

  closeDialog = () => {
    this.setState({
      dialogOpen: undefined,
      orgUnitId: undefined
    })
  }

  handleAddContact = ({ supplierId, value }) => {
    const { selectNewUser } = this.props

    selectNewUser(value, { orgUnitId: supplierId })
  }

  handleRemoveContact = ({ vetBuyerSupplierId, userId }) => {
    const { removeContactSendTo } = this.props
    removeContactSendTo({ vetBuyerSupplierId, userId })
  }

  closeAddContactDialog = () => {
    const { closeAddContactDialog } = this.props

    closeAddContactDialog()
    this.setState({
      orgUnitId: undefined
    })
  }

  confirmAllAction = action => {
    if (!action || (action === 'invite' && !this.checkRequiredForInvite())) {
      return
    }

    const { vetBuyerSuppliers, vetApproval, hasInvited } = this.props
    const status =
      action === 'approve'
        ? 'Suggested'
        : vetApproval
        ? 'Approved'
        : 'Suggested'
    const translateAction =
      action === 'approve' ? (
        <FormattedMessage
          id='VetSupplierList.Approve'
          defaultMessage='approve'
        />
      ) : (
        <FormattedMessage id='VetSupplierList.Invite' defaultMessage='invite' />
      )
    const vbsList = vetBuyerSuppliers && vetBuyerSuppliers.get(status)
    const vbsIds =
      (vbsList && vbsList.map(vbs => vbs.getIn(['vetBS', 'id'])).toArray()) ||
      []

    this.setState({
      message: (
        <FormattedMessage
          id='VetSupplierList.AreYouSureAboutAddingSupplier'
          defaultMessage='Are you sure you want to {translateAction} {vbsValues} supplier(s)?'
          values={{ translateAction, vbsValues: vbsIds.length }}
        />
      ),
      secondMessage:
        action !== 'approve' && hasInvited ? (
          ''
        ) : (
          <FormattedMessage
            id='VetSupplierList.confirmInviteSupplierNew'
            defaultMessage='After inviting suppliers you will not be able to edit invitation details. Any changes will need to be communicated to the supplier(s) using the "Additions" section below.'
          />
        ),
      action:
        action === 'approve' ? this.handleApproveAll : this.handleInviteAll,
      vbsIds,
      dialogOpen: 'confirm'
    })
  }

  handleApproveAll = () => {
    const { onApproveVetSupplier } = this.props
    onApproveVetSupplier({ vetBuyerSupplierIds: this.state.vbsIds })
    this.closeDialog()
  }

  handleInviteAll = () => {
    const { onInviteVetSupplier, vetBuyerSuppliers } = this.props

    const approvedSuppliers = vetBuyerSuppliers.get('Approved') || List([])
    const suggestedSuppliers = vetBuyerSuppliers.get('Suggested') || List([])
    const suppliersInVet = approvedSuppliers.concat(suggestedSuppliers)
    const vbsIdsWithContacts = this.state.vbsIds.filter(vbsId =>
      suppliersInVet.some(vbs => {
        if (vbs.getIn(['vetBS', 'id']) === vbsId) {
          const sendToContacts =
            vbs.getIn(['vetBS', 'collaboration', 'sendToContacts']) || List([])
          return sendToContacts.size > 0
        }
        return false
      })
    )
    onInviteVetSupplier({ vetBuyerSupplierIds: vbsIdsWithContacts })
    this.closeDialog()
  }

  checkRequiredForInvite = () => {
    const {
      missingRequired = [],
      invalidResponseDate,
      isApprover,
      isOwner
    } = this.props

    if (
      (!(isApprover || isOwner) && missingRequired.length > 0) ||
      (!(isApprover || isOwner) && invalidResponseDate)
    ) {
      const message =
        missingRequired.length > 0 ? (
          <>
            <FormattedMessage
              id='VetSupplierList.NoApproverRequiredFieldsAreMissing1b'
              defaultMessage='Only RFI Owner or Approver is allowed to invite suppliers. Contact your administrator if you need access to this role.'
              values={{ missingRequiredValues: missingRequired.join(', ') }}
            />
            <p className='pt-2'>
              <FormattedMessage
                id='VetSupplierList.NoApproverRequiredFieldsAreMissing2'
                defaultMessage='Required fields are missing {missingRequiredValues}. Please fill them in so suppliers can be invited.'
                values={{ missingRequiredValues: missingRequired.join(', ') }}
              />
            </p>
          </>
        ) : (
          <>
            <FormattedMessage
              id='VetSupplierList.NoApproverInvalidResponseDate1b'
              defaultMessage='Only RFI Owner or Approver is allowed to invite suppliers. Contact your administrator if you need access to this role.'
            />
            <p className='pt-2'>
              <FormattedMessage
                id='VetSupplierList.NoApproverInvalidResponseDate2'
                defaultMessage='You cannot invite a new supplier when response date is before today.'
              />
            </p>
          </>
        )
      this.setState({
        message,
        secondMessage: '',
        dialogOpen: 'required'
      })

      return false
    }

    if (missingRequired.length > 0 || invalidResponseDate) {
      const message =
        missingRequired.length > 0 ? (
          <FormattedMessage
            id='VetSupplierList.RequiredFieldsAreMissing'
            defaultMessage='Required fields are missing {missingRequiredValues}. Please fill them in so suppliers can be invited.'
            values={{ missingRequiredValues: missingRequired.join(', ') }}
          />
        ) : (
          <FormattedMessage
            id='VetSupplierList.InvalidResponseDate'
            defaultMessage='You cannot invite a new supplier when response date is before today.'
          />
        )
      this.setState({
        message,
        secondMessage: '',
        dialogOpen: 'required'
      })

      return false
    }

    return true
  }

  handleInviteSupplier = ({ vetBuyerSupplierIds }) => {
    const {
      hasInvited,
      inviteSuppliers,
      onInviteVetSupplier,
      isApprover
    } = this.props

    if (!inviteSuppliers) {
      this.setState({
        message: (
          <FormattedMessage
            id='VetSupplierList.infoInviteSupplier'
            defaultMessage='Please enable supplier communications on Invitation tab before inviting a supplier.'
          />
        ),
        secondMessage: undefined,
        dialogOpen: 'info'
      })
      return
    }

    if (!this.checkRequiredForInvite()) {
      return
    }

    if (!this.checkRequiredForInvite()) {
      return
    }
    if (!hasInvited && isApprover) {
      this.setState({
        message: (
          <FormattedMessage
            id='VetSupplierList.confirmInviteSupplier'
            defaultMessage='Are you sure you want to invite this supplier?'
          />
        ),
        secondMessage: (
          <FormattedMessage id='VetSupplierList.confirmInviteSupplierNew' />
        ),
        action: this.handleInviteAll,
        vbsIds: vetBuyerSupplierIds,
        dialogOpen: 'confirm'
      })
    } else {
      onInviteVetSupplier({ vetBuyerSupplierIds })
    }
  }

  handleGotoConversation = vetBuyerSupplierId => {
    const { vetId, redirectFrom, push } = this.props
    push(
      `${parsePath(paths.vetInbox, { vetId, vetBuyerSupplierId })}${
        redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
      }`
    )
  }

  renderVetBuyerSupplierCategory = category => {
    const {
      vetBuyerSuppliers,
      vetIsReadonly,
      vetApproval,
      inviteSuppliers,
      currentUrl,
      intl,
      onApproveVetSupplier,
      onRemoveVetSupplier,
      onInviteVetSupplier,
      onUpdateVetSupplier,
      isApprover,
      isOwner,
      selectedVetBSId,
      updateContactSendTo,
      onClick
    } = this.props

    if (vetBuyerSuppliers && vetBuyerSuppliers.has(category)) {
      const vbsList = vetBuyerSuppliers.get(category)

      return (
        <Fragment key={category}>
          <div className='bb f7 fw6 pt3 b--mid-gray mid-gray dt w-100'>
            <div className='dtc'>
              {intl.formatMessage(
                translatedFilterKeys.collaborationStatus[camelCase(category)]
              )}
            </div>
            <div className='dtc tr'>
              {(isApprover || isOwner) &&
                (category === 'Suggested' || category === 'Approved') &&
                !vetIsReadonly && (
                  <>
                    {vetApproval && category === 'Suggested' ? (
                      isApprover ? (
                        <Button
                          autoSize
                          variant='text'
                          size='small'
                          disabled={!isApprover}
                          label={
                            <FormattedMessage
                              id='VetSupplierList.ApproveAll'
                              defaultMessage='Approve All'
                            />
                          }
                          onClick={() => this.confirmAllAction('approve')}
                        />
                      ) : null
                    ) : inviteSuppliers ? (
                      <Button
                        autoSize
                        variant='text'
                        size='small'
                        label={
                          <FormattedMessage
                            id='VetSupplierList.InviteAll'
                            defaultMessage='Invite All'
                          />
                        }
                        onClick={() => this.confirmAllAction('invite')}
                        disabled={
                          vbsList &&
                          !vbsList.some(
                            vbs =>
                              (
                                vbs.getIn([
                                  'vetBS',
                                  'collaboration',
                                  'sendToContacts'
                                ]) || List([])
                              ).size > 0
                          )
                        }
                      />
                    ) : null}
                  </>
                )}
            </div>
          </div>
          {vbsList &&
            vbsList.map(vbs => (
              <VetSupplierListItem
                key={vbs.getIn(['vetBS', 'id'])}
                vbsId={vbs.getIn(['vetBS', 'id'])}
                highlight={vbs.getIn(['vetBS', 'id']) === selectedVetBSId}
                onClick={onClick}
                vetIsReadonly={vetIsReadonly}
                currentUrl={currentUrl}
                vetApproval={vetApproval}
                isOwner={isOwner}
                isApprover={isApprover}
                inviteSuppliers={inviteSuppliers}
                onRemoveVetSupplier={onRemoveVetSupplier}
                onInviteVetSupplier={
                  onInviteVetSupplier && this.handleInviteSupplier
                }
                onApproveVetSupplier={onApproveVetSupplier}
                onUpdateVetSupplier={onUpdateVetSupplier}
                onGotoConversation={this.handleGotoConversation}
                onAddContact={
                  vbs.getIn(['vetBS', 'collaboration', 'status']) !== 'Declined'
                    ? this.handleAddContact
                    : undefined
                }
                onRemoveContact={
                  !vbs.getIn(['vetBS', 'invited'])
                    ? this.handleRemoveContact
                    : undefined
                }
                onUpdateContact={updateContactSendTo}
              />
            ))}
        </Fragment>
      )
    } else {
      return null
    }
  }

  render() {
    const categoryOrder = [
      'Chosen',
      'Not Chosen',
      'Accepted',
      'Accepted With Condition',
      'Meet Criteria',
      'Interested',
      'Opened',
      'Invited',
      'Declined',
      'Approved',
      'Suggested',
      'Closed',
      'Cancelled',
      'Do Not Contact'
    ]

    return (
      <div>
        {categoryOrder.map(order => this.renderVetBuyerSupplierCategory(order))}
        <Dialog
          open={!!this.state.dialogOpen}
          onClose={this.closeDialog}
          fullWidth
        >
          <DialogTitle>
            {this.state.dialogOpen === 'confirm' ? (
              <FormattedMessage
                id='VetSupplierList.Confirmation'
                defaultMessage='Confirmation'
              />
            ) : (
              <FormattedMessage
                id='VetSupplierList.Information'
                defaultMessage='Information'
              />
            )}
          </DialogTitle>
          <DialogContent>
            <Text className='mt3'>{this.state.message}</Text>
            {this.state.secondMessage && (
              <Text className='mt3'>{this.state.secondMessage}</Text>
            )}
          </DialogContent>
          <DialogActions>
            {this.state.dialogOpen === 'confirm' && (
              <Button
                autoSize
                label={
                  <FormattedMessage
                    id='VetSupplierList.OK'
                    defaultMessage='OK'
                  />
                }
                onClick={this.state.action}
              />
            )}
            <Button
              autoSize
              secondary={this.state.dialogOpen === 'confirm'}
              label={
                this.state.dialogOpen === 'confirm' ? (
                  <FormattedMessage id='CancelButton' />
                ) : (
                  <FormattedMessage
                    id='VetSupplierList.Close'
                    defaultMessage='Close'
                  />
                )
              }
              onClick={this.closeDialog}
            />
          </DialogActions>
        </Dialog>
        <AddContactSendContainer />
      </div>
    )
  }
}

export default injectIntl(VetSupplierList)
