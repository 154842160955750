import supplierPaths from '../../routes/paths'

const basePath = supplierPaths.company

const paths = {
  basePath,
  company: basePath
}

export default paths
