import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { getFormSubmitErrors } from 'shared/reducers'
import OutcomeDialog from '../../components/Outcome/OutcomeDialog'
import { CHOOSE_VET_SUPPLIER, REJECT_VET_SUPPLIER } from '../../actionTypes'

type Props = {
  open: boolean
  outcome: string
  error: string
  vetBuyerSupplierId: string
  reset: () => void
  onRequestClose: () => void
  acceptSupplier: (values: string) => void
  rejectSupplier: (values: string) => void
  handleSubmit: (onSubmit: (values: any) => void) => () => void
}

class OutcomeDialogContainer extends Component<Props> {
  onSubmit = values => {
    const { acceptSupplier, rejectSupplier, onRequestClose, reset } = this.props
    if (values.get('outcome') === 'accept') {
      acceptSupplier(values.delete('outcome'))
    } else {
      rejectSupplier(values.delete('outcome'))
    }
    reset()
    onRequestClose()
  }

  handleRequestClose = () => {
    const { onRequestClose, reset } = this.props
    reset()
    onRequestClose()
  }

  render() {
    const { handleSubmit, error, open, ...props } = this.props

    return (
      <OutcomeDialog
        onSubmit={handleSubmit(this.onSubmit)}
        error={error}
        open={open}
        {...props}
        onRequestClose={this.handleRequestClose}
      />
    )
  }
}

const formName = 'vets/OutcomeDialog'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)

export default connectReduxForm(
  (state, props) => ({
    errorMessage: formSubmitErrorsSelector(state),
    initialValues: {
      vetBuyerSupplierId: props.vetBuyerSupplierId,
      outcome: props.outcome
    }
  }),
  {
    acceptSupplier: createSubmitFormAction(formName, CHOOSE_VET_SUPPLIER),
    rejectSupplier: createSubmitFormAction(formName, REJECT_VET_SUPPLIER)
  },
  {
    form: formName,
    enableReinitialize: true
  },
  OutcomeDialogContainer
)
