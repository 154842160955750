import React, { Fragment, useState, ChangeEvent } from 'react'
import { useLocation, Redirect } from 'react-router'
import buyerPaths from 'buyer/routes/paths'
import supplierPaths from 'supplier/routes/paths'
import parsePath from 'shared/utils/parsePath'
import { Field } from 'redux-form/immutable'
import Input from 'shared/components/Input'
import ProfileSubmitButton from 'shared/components/ProfileSubmitButton'
import CancelButton from 'shared/components/CancelButton'
import Button from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import Card from 'shared/components/Card'
import Text from 'shared/components/Text'
import ImageFileInput from 'shared/components/ImageFileInput'
import { validateLinkedIn } from 'shared/utils/data/validate'
import Label from 'shared/components/Label'
import RadioButton from 'shared/components/RadioButton'
import RadioButtonGroup from 'shared/components/RadioButton/RadioButtonGroup'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { supportedLanguages } from '../../../i18n'
import Select from 'shared/components/Select'
import Switch from 'shared/components/Switch'
import InfoOutline from '@material-ui/icons/InfoOutlined'
import Tooltip from 'shared/components/Tooltip'
import DeleteAccount from '../DeleteAccount'
import Divider from 'shared/components/Divider'
import { List, RecordOf } from 'immutable'
import LanguageIcon from '@material-ui/icons/Language'
import Confetti from 'react-confetti'
import useWindowSize from 'shared/utils/useWindowSize'
import canDeleteUser from 'shared/utils/canDeleteUser'
import User from 'shared/models/User'

const placeholders = defineMessages({
  address: {
    id: 'EditUserProfile.AddressPlaceholder',
    defaultMessage: 'Physical address (e.g. 38 Camden St, Toronto, ON, M5V 1V1)'
  }
})

type Props = {
  onSubmit: () => void
  onCancel: () => void
  errorMessage: string
  pristine: boolean
  profilePictureUrl: string
  isCurrentUser: boolean
  isTealbot: boolean
  isOrgAdmin: boolean
  isBuyerAdmin: boolean
  isSupplierAdmin: boolean
  status: string
  roles: List<string>
  messageDeliver: string
  userId: string
  currentUser: RecordOf<User>
  canEditUser: boolean
}

export const EditUserProfile = (props: Props) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [confetti, setConfetti] = useState<boolean>(false)
  const intl = useIntl()
  const size = useWindowSize()
  const location = useLocation()

  const toggleDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog)
  }

  const renderFormField = (label, inputField, options = {}) => {
    inputField =
      typeof inputField === 'string' ? (
        <Field name={inputField} component={Input} type='text' {...options} />
      ) : (
        inputField
      )
    return (
      <div className='mb3'>
        <div className='ma0 mb2 f7 fw6'>{label}</div>
        {inputField}
      </div>
    )
  }

  const {
    onSubmit,
    errorMessage,
    onCancel,
    profilePictureUrl,
    isCurrentUser,
    isTealbot,
    isOrgAdmin,
    isBuyerAdmin,
    isSupplierAdmin,
    status,
    roles,
    messageDeliver,
    currentUser,
    canEditUser
  } = props

  return canEditUser ? (
    <div>
      <form onSubmit={onSubmit}>
        {/* this doesnt work properly <Prompt
            message='Are you sure you want to leave?'
            when={!pristine} /> */}
        <div className='mw8 w-100 center dt ph3 ph3-ns ph3-m ph0-ns'>
          <div className='pt2 pb2 border-box tr'>
            <CancelButton onClick={onCancel} />
            <ProfileSubmitButton />
          </div>
          <Card>
            <div className='cf tl'>
              <div className='dib w-50-ns w-100 w-100-m fl pr2-ns'>
                <div className='mb3'>
                  <div className='ma0 mb2 f7 fw6'>
                    <FormattedMessage
                      id='EditUserProfile.ProfilePicture'
                      defaultMessage='Profile Picture'
                    />
                  </div>
                  <Field
                    component={ImageFileInput}
                    imageUrl={profilePictureUrl}
                    name='profilePicture'
                    avatar
                  />
                </div>
                {renderFormField(
                  <FormattedMessage
                    id='EditUserProfile.FirstName'
                    defaultMessage='First Name'
                  />,
                  'firstName',
                  { required: 1 }
                )}
                {renderFormField(
                  <FormattedMessage
                    id='EditUserProfile.LastName'
                    defaultMessage='Last Name'
                  />,
                  'lastName',
                  { required: 1 }
                )}
                {renderFormField(
                  <FormattedMessage
                    id='EditUserProfile.CompanyPosition'
                    defaultMessage='Company Position'
                  />,
                  'title'
                )}
                {renderFormField(
                  <FormattedMessage
                    id='EditUserProfile.Department'
                    defaultMessage='Department'
                  />,
                  'department'
                )}
                <div className='mb3'>
                  <div className='ma0 mb2 f7 fw6'>
                    <FormattedMessage
                      id='EditUserProfile.Language'
                      defaultMessage='Language'
                    />
                  </div>
                  <div className='flex items-center'>
                    <LanguageIcon className='mr2' />
                    <Field name='language' component={Select}>
                      {supportedLanguages.map(({ key, title }) => (
                        <option key={key} value={key}>
                          {title}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
                <Label className='db mb1 f7 fw6'>
                  <FormattedMessage
                    id='EditUserProfile.Happy'
                    defaultMessage='Happy'
                  />
                  <Tooltip
                    placement='right-start'
                    title={
                      <div className='w5'>
                        <FormattedMessage
                          id='EditUserProfile.HappyMessage'
                          defaultMessage='Turns on some fun features in TealBook'
                        />
                      </div>
                    }
                  >
                    <InfoOutline
                      className='v-mid dib'
                      style={{ height: '19px' }}
                    />
                  </Tooltip>
                  <Field
                    component='input'
                    name='happy'
                    type='checkbox'
                    className='ml1'
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setConfetti(!!e.target.checked)
                    }
                  />
                </Label>
              </div>
              <div className='dib w-50-ns w-100 w-100-m fl pl2-ns'>
                {renderFormField(
                  <FormattedMessage
                    id='EditUserProfile.OfficeNumber'
                    defaultMessage='Office Number'
                  />,
                  'officePhone'
                )}
                {renderFormField(
                  <FormattedMessage
                    id='EditUserProfile.MobileNumber'
                    defaultMessage='Mobile Number'
                  />,
                  'mobilePhone'
                )}
                {renderFormField(
                  <FormattedMessage
                    id='EditUserProfile.Location'
                    defaultMessage='Location'
                  />,
                  'address',
                  {
                    placeholder: intl.formatMessage(placeholders.address)
                  }
                )}
                <FormattedMessage
                  id='EditUserProfile.LinkedInURL'
                  defaultMessage='Enter your LinkedIn public profile URL. e.g. www.linkedin.com/in/yourlinkedin'
                >
                  {message =>
                    renderFormField(
                      <FormattedMessage
                        id='EditUserProfile.LinkedInProfile'
                        defaultMessage='LinkedIn Profile'
                      />,
                      'linkedInUrl',
                      {
                        placeholder: message,
                        validate: validateLinkedIn
                      }
                    )
                  }
                </FormattedMessage>

                {roles &&
                  roles.includes('supplier') &&
                  (isSupplierAdmin || isTealbot || isCurrentUser) && (
                    <>
                      <div className='mb3'>
                        <div className='ma0 mb2 f7 fw6'>
                          <FormattedMessage
                            id='EditUserProfile.SustainabilityContact'
                            defaultMessage='Sustainability Contact'
                          />
                        </div>
                        <label className='ma0 mb2 f7'>
                          <div className='dib pr2'>
                            <Field
                              className='dib'
                              component={Switch}
                              name='isSustainabilityContact'
                            />
                          </div>
                          <FormattedMessage
                            id='EditUserProfile.AnswerSustainabilityAnswer'
                            defaultMessage='Responsible for sustainability related questions'
                          />
                        </label>
                      </div>
                    </>
                  )}

                {roles &&
                  roles.includes('supplier') &&
                  (isTealbot || isCurrentUser) && (
                    <>
                      <div className='mb3'>
                        <div className='ma0 mb2 f7 fw6'>
                          <FormattedMessage
                            id='EditUserProfile.PublicProfile'
                            defaultMessage='Public Profile'
                          />
                        </div>
                        <label className='ma0 mb2 f7'>
                          <div className='dib pr2'>
                            <Field
                              className='dib'
                              component={Switch}
                              name='isPublic'
                            />
                          </div>
                          <FormattedMessage
                            id='EditUserProfile.VisibleToEveryone'
                            defaultMessage='Allow my profile to be visible to everyone'
                          />
                        </label>
                      </div>
                    </>
                  )}

                {(isTealbot ||
                  isOrgAdmin ||
                  isBuyerAdmin ||
                  isSupplierAdmin) && (
                  <div className='mb3 cf'>
                    <div className='ma0 mb2 f7 fw6'>
                      <FormattedMessage
                        id='EditUerProfile.UserSatus'
                        defaultMessage='User Status'
                      />
                    </div>
                    <Text>{status}</Text>
                  </div>
                )}

                {(isTealbot || isBuyerAdmin) && roles.includes('buyer') && (
                  <div className='mb3'>
                    <div className='ma0 mb2 f7 fw6'>
                      <FormattedMessage
                        id='EditUserProfile.LicenseType'
                        defaultMessage='License Type'
                      />
                    </div>
                    <Field name='licenseType' component={Select}>
                      <option value='business'>
                        {intl.formatMessage({ id: 'Business' })}
                      </option>
                      <option value='professional'>
                        {intl.formatMessage({ id: 'Professional' })}
                      </option>
                      <option value='advanced'>
                        {intl.formatMessage({ id: 'Advanced' })}
                      </option>
                      <option value='elite'>
                        {intl.formatMessage({ id: 'Elite' })}
                      </option>
                    </Field>
                  </div>
                )}

                {isCurrentUser && (
                  <Fragment>
                    <div className='mb3'>
                      <RadioButtonGroup
                        title={
                          <FormattedMessage
                            id='EditUserProfile.NotifiedMessage'
                            defaultMessage='I would like to be notified when I get a message from another user:'
                          />
                        }
                        stack
                      >
                        <Field
                          component={RadioButton}
                          name='options.message.frequency'
                          value='Right Away'
                          label={
                            <FormattedMessage
                              id='EditUserProfile.RightAway'
                              defaultMessage='Right Away'
                            />
                          }
                          type='radio'
                          required
                        />
                        <Field
                          component={RadioButton}
                          name='options.message.frequency'
                          value='Daily'
                          label={
                            <FormattedMessage
                              id='EditUserProfile.Daily'
                              defaultMessage='Daily'
                            />
                          }
                          type='radio'
                        />
                        <Field
                          component={RadioButton}
                          name='options.message.frequency'
                          value='Weekly'
                          label={
                            <FormattedMessage
                              id='EditUserProfile.Weekly'
                              defaultMessage='Weekly'
                            />
                          }
                          type='radio'
                        />
                        <Field
                          component={RadioButton}
                          name='options.message.frequency'
                          value='Never'
                          label={
                            <FormattedMessage
                              id='EditUserProfile.Never'
                              defaultMessage='Never'
                            />
                          }
                          type='radio'
                        />
                      </RadioButtonGroup>
                      {messageDeliver === 'Never' && (
                        <div className='red f6'>
                          <FormattedMessage
                            id='EditUserProfile.MessageFrequencyWarning'
                            defaultMessage='Warning! This option will prevent us from letting you know when customers try to contact you.'
                          />
                        </div>
                      )}
                    </div>
                    <div className='mb3'>
                      <div className='ma0 mb2 f7 fw6'>
                        <FormattedMessage
                          id='EditUserProfile.Notification'
                          defaultMessage='Notification'
                        />
                      </div>
                      <label className='ma0 mb2 f7'>
                        <div className='dib pr2'>
                          <Field
                            className='dib'
                            component={Checkbox}
                            name='disableAllEmails'
                            type='checkbox'
                          />
                        </div>
                        <FormattedMessage
                          id='EditUserProfile.UnsubscribeToEmails'
                          defaultMessage='I would like to unsubscribe emails and updates from TealBook.'
                        />
                      </label>
                    </div>
                  </Fragment>
                )}
                <Divider />
                {(canDeleteUser(roles.toJS(), currentUser.get('roles')) ||
                  isCurrentUser) && (
                  <>
                    <div className='mt3 cf'>
                      <Button onClick={toggleDeleteDialog} autoSize secondary>
                        <FormattedMessage
                          id='EditUserProfile.DeleteAccountUpper'
                          defaultMessage='DELETE ACCOUNT'
                        />
                      </Button>
                    </div>
                    <DeleteAccount
                      userId={props.userId}
                      open={openDeleteDialog}
                      onClose={toggleDeleteDialog}
                      hardDelete={isTealbot}
                    />
                  </>
                )}
              </div>
            </div>
          </Card>
        </div>
      </form>
      <p>{errorMessage}</p>
      {confetti && (
        <Confetti
          width={size.width}
          height={size.height}
          recycle={false}
          numberOfPieces={1000}
        />
      )}
    </div>
  ) : (
    <Redirect
      to={parsePath(
        location.pathname.startsWith('/client')
          ? buyerPaths.userprofile
          : supplierPaths.userProfile,
        { userId: props.userId }
      )}
    />
  )
}

export default EditUserProfile
