import React from 'react'
import JobDetails from '../JobDetails'
import JobSow from '../JobSow'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import jobsSelectors from '../../selectors/jobsSelectors'
import RootState from 'shared/models/RootState'

const Job = () => {
  const params = useParams<{
    jobId: string
    row: string
  }>()

  const jobType = useSelector((state: RootState) => {
    const job = jobsSelectors.getJobById(state, params.jobId)
    return !!job && job.get('type')
  })

  return jobType === 'hil' ? <JobSow /> : <JobDetails />
}

export default Job
