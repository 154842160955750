import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import ResponseMessage from '../../components/ResponseMessage'
import fileToUrlImmutable from 'shared/utils/data/fileToUrlImmutable'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import parsePath from 'shared/utils/parsePath'
import paths from '../../../routes/paths'
import InternalComments from '../InternalComments'
import ResponseFileContainer from '../ResponseFileContainer'
import camelCase from 'lodash.camelcase'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import { fromJS, List, RecordOf, Map } from 'immutable'
import RootState from 'shared/models/RootState'
import User from 'shared/models/User'
import { updateVetBuyerSupplier } from '../../actions'

type VetResponseProps = {
  id: string
  primaryLocation: number
  collaboration: {
    status: string
    proposal: { submitted: { date: Date; user: string } }
  }
}

export type ContactType = {
  user: string
  primary?: boolean
  secondary?: boolean
}

type Props = {
  vetBS: RecordOf<VetResponseProps>
  users: Map<string, RecordOf<User>>
  emails: List<string>
  sendToContacts: Map<string, RecordOf<ContactType>>
  primaryContact?: RecordOf<ContactType>
  secondaryContact?: RecordOf<ContactType>
  readonly: boolean
  pricing: Map<
    string,
    Map<
      string,
      RecordOf<{
        currency: string
        lineItems: List<
          RecordOf<{
            name: string
            description: string
            quantity: number
            UOM: string
            id: string
          }>
        >
      }>
    >
  >
  responseDate: string
  supplier: RecordOf<{ id: string; domains: List<string>; name: string }>
  updateVetBuyerSupplier: ({
    vetBuyerSupplierId: string,
    primaryLocation: number
  }) => void
} & RouteComponentProps

export class ResponseMessageContainer extends Component<Props> {
  handleSupplierNameClick = supplierId => {
    const { history } = this.props
    history.push(parsePath(paths.supplierProfile, { supplierId }))
  }

  getResponse() {
    const { vetBS, supplier, users } = this.props
    const status = vetBS.getIn(['collaboration', 'status'])
    const date =
      vetBS.getIn([camelCase(status), 'date']) ||
      vetBS.getIn(['collaboration', 'proposal', 'submitted', 'date'])
    const userId = vetBS.getIn([
      'collaboration',
      'proposal',
      'submitted',
      'user'
    ])
    const user = users.get(userId)
    const savedLogo = fileToUrlImmutable(supplier.getIn(['supplier', 'logo']))
    const domain = supplier.get('domains')
    return {
      vetBuyerSupplierId: vetBS.get('id'),
      primaryLocation: vetBS.get('primaryLocation'),
      supplierId: supplier.get('id'),
      supplierName: supplier.get('name'),
      locations: supplier.getIn(['supplier', 'locations']),
      logoUrl:
        savedLogo ||
        (domain && domain.size > 0
          ? `https://logo.clearbit.com/${domain.first()}`
          : ''),
      name: vetBS.getIn(['collaboration', 'proposal', 'name']),
      user: user && user.toJS(),
      date: date && new Date(date),
      documents:
        vetBS.getIn(['collaboration', 'proposal', 'documents']) || fromJS([]),
      description: vetBS.getIn(['collaboration', 'proposal', 'description']),
      status: status,
      comment: vetBS.getIn(['collaboration', 'proposal', 'comment'])
    }
  }

  render() {
    const {
      vetBS,
      emails,
      readonly,
      pricing,
      responseDate,
      updateVetBuyerSupplier,
      sendToContacts,
      primaryContact,
      secondaryContact
    } = this.props

    if (!vetBS) return null
    const response = this.getResponse()

    return (
      <div>
        <ResponseMessage
          vetBuyerSupplierId={response.vetBuyerSupplierId}
          supplierName={response.supplierName}
          logoUrl={response.logoUrl}
          locations={response.locations}
          name={response.name}
          user={response.user}
          date={response.date}
          documents={response.documents}
          description={response.description}
          status={response.status}
          comment={response.comment}
          readonly={readonly}
          onSupplierNameClick={() =>
            this.handleSupplierNameClick(response.supplierId)
          }
          proposalPricing={
            vetBS.getIn(['collaboration', 'proposal', 'pricing']) || fromJS({})
          }
          pricing={pricing}
          responseDate={responseDate}
          emails={emails}
          submitted={
            !!vetBS.getIn(['collaboration', 'proposal', 'submitted']) || false
          }
          updateVetBuyerSupplier={updateVetBuyerSupplier}
          primaryLocation={response.primaryLocation}
          sendToContacts={sendToContacts}
          primaryContact={primaryContact}
          secondaryContact={secondaryContact}
        />
        <ResponseFileContainer
          vetBuyerSupplierId={response.vetBuyerSupplierId}
        />
        <InternalComments
          vetId={vetBS.getIn(['parents', 'Vet'])}
          vetBuyerSupplierId={response.vetBuyerSupplierId}
        />
      </div>
    )
  }
}

type ContainerProps = {
  selectedVetBSId: string
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const vetBS = currentVetSuppliersSelectors
      .getVetSupplierById(state, props.selectedVetBSId)
      .get('vetBS')
    const supplier = currentVetSuppliersSelectors
      .getVetSupplierById(state, props.selectedVetBSId)
      .get('supplier')
    const emails = currentVetSuppliersSelectors
      .getVetSupplierById(state, props.selectedVetBSId)
      .get('email')
    const users = usersSelectors.getUsers(state)
    const vet = currentVetSelectors.getVet(state)
    const sendToContacts = currentVetSuppliersSelectors.getSendToContactsMap(
      state,
      props.selectedVetBSId
    )

    return {
      vetBS,
      supplier,
      emails,
      sendToContacts,
      primaryContact: currentVetSuppliersSelectors.getSupplierPrimaryContact(
        state,
        props.selectedVetBSId
      ),
      secondaryContact: currentVetSuppliersSelectors.getSupplierSecondaryContact(
        state,
        props.selectedVetBSId
      ),
      users,
      readonly: currentVetSelectors.isReadonly(state),
      pricing: vet && vet.get('pricing'),
      responseDate: vet && vet.get('responseDate')
    }
  },
  {
    updateVetBuyerSupplier
  }
)(withRouter(ResponseMessageContainer))
