import React, { FunctionComponent, ReactNode } from 'react'

type Props = {
  className?: string
  children: string | ReactNode
}

const InsightTitle: FunctionComponent<Props> = props => {
  return (
    <h3 className={props.className ? props.className : 'f5 fw3 pl3 mt3'}>
      {props.children}
    </h3>
  )
}

export default InsightTitle
