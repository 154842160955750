import React from 'react'
import OrgLogo from 'shared/components/OrgLogo'
import Text from 'shared/components/Text'

type OrgUnitProps = {
  name: string
  logoUrl?: string
  websiteUrl?: string
}

export const OrgUnit = (props: OrgUnitProps) => {
  const { logoUrl, name, websiteUrl } = props
  return (
    <div className='flex items-center'>
      <div
        className='pa1 v-mid ba b--black-10 br2 bg-white flex items-center mr2'
        style={{
          height: '50px',
          width: '50px',
          minWidth: '50px',
          maxHeight: '50px'
        }}
      >
        <OrgLogo url={logoUrl} name={name} />
      </div>
      <div className='flex-auto'>
        <Text className='fw6 truncate'>{name}</Text>
        {websiteUrl && <Text secondary>{websiteUrl}</Text>}
      </div>
    </div>
  )
}

export default OrgUnit
