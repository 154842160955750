import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { fromJS, List } from 'immutable'
import DataPortalAppBar from './components/DataPortalAppBar'
import createRoutes from './routes/createRoutes'
import { searchForOrganization } from './store/actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import apiRoutes from 'shared/routes/apiRoutes'

type Props = {
  roles: List<string>
  searchForOrganization: (searchForOrg: {
    q: string
    searchBuyers: string
  }) => void
}

export class App extends Component<Props> {
  componentDidMount() {
    const { roles, searchForOrganization } = this.props

    searchForOrganization(
      fromJS({
        q: '',
        searchBuyers: !roles.some(
          role => role === 'supplierConcierge' || role === 'orgAdmin'
        )
      })
    )
  }

  render() {
    return (
      <Fragment>
        <DataPortalAppBar roles={this.props.roles} />
        <div className={apiRoutes.inProduction ? 'bg-washed-red' : ''}>
          {createRoutes(this.props.roles)}
        </div>
      </Fragment>
    )
  }
}

export default connect(
  state => ({
    roles: sessionSelectors.getUser(state).get('roles')
  }),
  {
    searchForOrganization
  }
)(App)
