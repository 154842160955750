import VetsRecordFactory from './VetsRecordFactory'
import * as actionTypes from '../../actionTypes'
import { requestSuccess, LOGOUT } from 'shared/actionTypes'
import { fromJS } from 'immutable'

const vetsReducer = (state = VetsRecordFactory({}), action) => {
  switch (action.type) {
    case requestSuccess(actionTypes.CREATE_VET):
      return state.merge({
        items: state.items.merge({
          [action.payload.newVet.id]: fromJS(action.payload.newVet)
        })
      })
    case actionTypes.LOAD_VETS:
      return state.merge({
        isFetching: true,
        fetchErrorMessage: ''
      })
    case actionTypes.LOAD_VETS_SUCCESS:
      return VetsRecordFactory({
        items: fromJS(action.payload.vets),
        isFetching: false
      })
    case actionTypes.LOAD_VETS_FAILURE:
      return state.merge({
        isFetching: false,
        fetchErrorMessage: action.payload.message
      })
    case LOGOUT:
      return VetsRecordFactory({})
    default:
      return state
  }
}

export default vetsReducer
