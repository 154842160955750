import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'

const apiRoute = `${apiRoutes.authService}/partnerConsumer`

export const requestEcovadisSupplierRating = ({
  supplierContact = null,
  buyerContact,
  suppliers,
  bulkAssessment = false,
  supplierName = null,
  country
}) => {
  return api.post(`${apiRoute}/requestEcovadisAssessment`, {
    supplierContact,
    buyerContact,
    suppliers,
    bulkAssessment,
    supplierName,
    country
  })
}

export const listAssessmentSuppliers = () =>
  api.get(`${apiRoute}/listAssessmentSuppliers`)

export const retrieveEcovadisAssessmentStatus = ({ supplierId }) => {
  return api.get(`${apiRoute}/retrieveEcovadisAssessmentStatus/${supplierId}`)
}

export const retrieveEcovadisScorecard = ({ supplierId }) => {
  return api.get(`${apiRoute}/retrieveEcovadisScorecard/${supplierId}`)
}
