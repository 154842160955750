import React, { useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import paths from '../../routes/paths'
import { useSelector, useDispatch } from 'react-redux'
import insightsSelectors from '../../store/insightsSelectors'
import { getSnapshot } from '../../store/actions'
import RootState from 'shared/models/RootState'
import ReportTable from './ViewReportTable'
import QualificationRulesInfo from './QualificationRulesInfo'
import FiltersInfo from './FIltersInfo'
import Loading from 'shared/components/Loading'

const ViewReport = () => {
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const handleClose = () => {
    history.push(paths.reportSnapshot)
  }

  const report = useSelector((state: RootState) =>
    insightsSelectors.getSavedReportById(state, params.report1)
  )

  const isLoading = useSelector(insightsSelectors.isSnapshotsLoading)

  useEffect(() => {
    if (params.report1) {
      dispatch(getSnapshot(params.report1))
    }
  }, [dispatch, params.report1])

  if (!params.report1) {
    return null
  }

  if (!report && isLoading) {
    return <Loading />
  }

  return report ? (
    <div className='pa4'>
      <div className='flex items-center justify-between mb3'>
        <h5 className='ma0 f3 mid-gray fw6'>
          <FormattedMessage id='ViewReport' defaultMessage='View Report' />
        </h5>
        <IconButton onClick={handleClose} aria-label='Close Report'>
          <CloseIcon fontSize='large' />
        </IconButton>
      </div>

      <div className='flex justify-between mb3'>
        <div className='flex-auto'>
          <span className='dib bg-light-gray br2 pa2 mr2'>{report.name}</span>
        </div>
        {(!!report.filters?.countries.length ||
          !!report.filters?.categories.length ||
          !!report.filters?.businessUnits.length) && (
          <FiltersInfo filters={report.filters} />
        )}
        <QualificationRulesInfo rules={report.rules} />
      </div>

      <ReportTable
        report1Header={
          report.reportingPeriod[0] !== report.reportingPeriod[1]
            ? `${report.reportingPeriod[0]} - ${report.reportingPeriod[1]}`
            : report.reportingPeriod[0]
        }
        report1={report}
        subCategories={Object.keys(report.overview)}
      />
    </div>
  ) : null
}

export default ViewReport
