import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import { parseSupplierRelationship } from 'shared/utils/data/parseSupplierRelationships'

const apiRoute = `${apiRoutes.authService}/supplierRelationships`

// create by buyer
export const createByBuyer = ({ supplierId, supplierRelationship }) => {
  return api
    .post(`${apiRoute}/${supplierId}`, { ...supplierRelationship })
    .then(parseSupplierRelationship)
}

export const create = ({ ...data }) => {
  return api.post(`${apiRoute}`, { ...data })
}

export const updateSupplierRelationship = ({
  supplierRelationshipId,
  ...rest
}) => {
  return api.put(`${apiRoute}/${supplierRelationshipId}`, { ...rest })
}

export const addCertificationValidation = ({
  supplierRelationshipId,
  ...rest
}) => {
  return api
    .post(`${apiRoute}/${supplierRelationshipId}/certificationVal`, { ...rest })
    .then(parseSupplierRelationship)
}

export const deleteCertificationValidation = ({
  supplierRelationshipId,
  category,
  subCategory,
  date
}) => {
  return api.remove(
    `${apiRoute}/${supplierRelationshipId}/${category}/${subCategory}/${date}/certificationVal`
  )
}

export const requestUserToVerifySupplier = ({
  supplierRelationshipId,
  ...rest
}) => api.post(`${apiRoute}/${supplierRelationshipId}/requestVerify`, rest)
