import React, { Component, Fragment } from 'react'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'
import Tealbot from 'shared/assets/icons/tealbot-circle.png'

const styles = {
  border: 'bg-white br1 ba b--black-10 pa3 td',
  noBorder: 'bg-white flex',
  tealbot: 'dtc w3 pr2',
  miniTealbot: 'w3 mr1'
}
type Props = {
  mini?: boolean
  noLabel?: boolean
  noBorder?: boolean
}

class TealbotTips extends Component<Props> {
  render() {
    const { children, mini, noLabel, noBorder } = this.props

    return (
      <div className={mini || noBorder ? styles.noBorder : styles.border}>
        <div className={mini ? styles.miniTealbot : styles.tealbot}>
          <img src={Tealbot} alt='Tealbot' className='dib w-100' />
        </div>

        {mini ? (
          noLabel ? (
            <div className='v-top'>{children}</div>
          ) : (
            <Fragment>
              <div className='v-mid'>
                <Label className='db mb1 f7 fw6' role='heading' aria-level={5}>
                  <FormattedMessage
                    id='TealbotTips.TealbotTipsLabel'
                    defaultMessage='Tealbot Tips'
                  />
                </Label>
              </div>
              {children}
            </Fragment>
          )
        ) : (
          <div className='dtc v-top'>
            {!noLabel && (
              <Label className='db mb1 f7 fw6' role='heading' aria-level={5}>
                <FormattedMessage
                  id='TealbotTips.TealbotTipsLabel'
                  defaultMessage='Tealbot Tips'
                />
              </Label>
            )}
            <div>{children}</div>
          </div>
        )}
      </div>
    )
  }
}

export default TealbotTips
