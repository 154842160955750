import React, { useState, useEffect } from 'react'
import FilterCheckboxListContainer from '../../containers/FilterCheckboxListContainer'
import Divider from 'shared/components/Divider'
import Text from 'shared/components/Text'
import Switch from 'shared/components/Switch'
import Button from 'shared/components/Button'
import { FormattedMessage, MessageDescriptor } from 'react-intl'
import { List, Map, RecordOf, fromJS } from 'immutable'
import NaicsSearchContainer from 'shared/containers/NaicsSearchContainer'

type Props = {
  firstLevelKey: string
  secondLevelKey?: string
  thirdLevelKey?: string
  isFilteredFirstLevel: boolean
  isFilteredSecondLevel?: boolean
  isFilteredThirdLevel?: boolean
  showMatchAny: boolean
  firstLevelOptions: List<RecordOf<{ value: string; label: string }>>
  secondLevelOptions?: List<RecordOf<{ value: string; label: string }>>
  thirdLevelOptions?: List<RecordOf<{ value: string; label: string }>>
  onMergeFilterOption: (params: Map<string, List<any>>) => void
  selectedFirstLevel: List<string | List<string>>
  selectedSecondLevel?: List<string | List<string>>
  selectedThirdLevel?: List<string | List<string>>
  initialConditions: Map<string, string | List<string>>
  translatedMessages?: {
    [key: string]: MessageDescriptor
  }
  showValue?: boolean
  onToggleFilterOption: (args: { key: string; value: string }) => void
  category?: string
}

const FilterHierarchy = (props: Props) => {
  const {
    firstLevelKey,
    secondLevelKey = '',
    thirdLevelKey = '',
    isFilteredFirstLevel,
    isFilteredSecondLevel = false,
    isFilteredThirdLevel = false,
    firstLevelOptions,
    secondLevelOptions = List([]),
    thirdLevelOptions = List([]),
    selectedFirstLevel,
    selectedSecondLevel = List([]),
    selectedThirdLevel = List([]),
    initialConditions,
    showMatchAny,
    translatedMessages,
    showValue,
    onMergeFilterOption,
    onToggleFilterOption,
    category
  } = props

  const isOr =
    List.isList(selectedFirstLevel.first()) ||
    (!!selectedSecondLevel && List.isList(selectedSecondLevel.first())) ||
    (!!selectedThirdLevel && List.isList(selectedThirdLevel.first()))

  const [matchAny, setMatchAny] = useState<boolean>(isOr)
  const [changed, setChanged] = useState<boolean>(false)
  const [conditions, setConditions] = useState<
    Map<string, string | List<string>>
  >(initialConditions)

  useEffect(() => {
    if ((!conditions || !conditions.equals(initialConditions)) && !changed) {
      setConditions(initialConditions)
    }
  }, [conditions, initialConditions, changed])

  const toggleMatchAny = () => {
    let first, second, third
    if (matchAny) {
      // toggle off
      setMatchAny(false)
      setChanged(false)

      first = conditions.get(firstLevelKey)
      second = conditions.get(secondLevelKey)
      third = conditions.get(thirdLevelKey)

      if (
        first.size > 1 ||
        (List.isList(second) && second.size > 1) ||
        (List.isList(third) && third.size > 1)
      ) {
        onMergeFilterOption(
          fromJS({
            [firstLevelKey]: [],
            [secondLevelKey]: [],
            [thirdLevelKey]: []
          })
        )
        setConditions(
          Map({
            [firstLevelKey]: List([]),
            [secondLevelKey]: List([]),
            [thirdLevelKey]: List([])
          })
        )
      } else {
        onMergeFilterOption(
          fromJS({
            [firstLevelKey]: first,
            [secondLevelKey]: second,
            [thirdLevelKey]: third
          })
        )
      }
    } else {
      // toggle on
      first = selectedFirstLevel
      second = selectedSecondLevel
      third = selectedThirdLevel

      setMatchAny(true)
      setChanged(true)
      setConditions(
        Map({
          [firstLevelKey]: first,
          [secondLevelKey]: second,
          [thirdLevelKey]: third
        })
      )
    }
  }

  const handleOnChange = data => {
    const cond = conditions.get(data.key)
    let index = List.isList(cond) && cond.findIndex(v => data.value === v)
    if (!index && index !== 0) index = -1

    // if removing, make sure lower level is clear too
    if (index !== -1 && data.key === firstLevelKey) {
      setConditions(
        conditions
          .updateIn([secondLevelKey], c => {
            return c.filter(v => !v.startsWith(data.value))
          })
          .updateIn([thirdLevelKey], c => {
            return c.filter(v => !v.startsWith(data.value))
          })
          .updateIn([data.key], c => {
            return index !== -1 ? c.delete(index) : c.push(data.value)
          })
      )
    } else if (index !== -1 && data.key === secondLevelKey) {
      setConditions(
        conditions
          .updateIn([thirdLevelKey], c => {
            return c.filter(v => !v.startsWith(data.value))
          })
          .updateIn([data.key], c => {
            return index !== -1 ? c.delete(index) : c.push(data.value)
          })
      )
    } else if (List.isList(cond)) {
      setConditions(
        conditions.updateIn([data.key], c => {
          return index !== -1 ? c.delete(index) : c.push(data.value)
        })
      )
    }
    setChanged(true)
  }
  const handleSearchInput = (data: string) => {
    let key: string = ''
    if (data.includes('-')) {
      key = 'sector'
    } else if (data.length <= 2) {
      key = 'sector'
    } else if (data.length <= 3) {
      key = 'subsector'
    } else {
      key = 'group'
    }
    onToggleFilterOption({ key, value: data })
    setChanged(true)
  }

  const handleApplyClick = () => {
    onMergeFilterOption(conditions.map(v => List([v])))
    setChanged(false)
  }

  const getSelected = levelKey => {
    const cond = conditions && conditions.get(levelKey)
    return matchAny && cond && List.isList(cond) && cond.size > 0
      ? cond
      : undefined
  }

  return (
    <>
      {showMatchAny && (
        <div className='flex items-center justify-between mb2'>
          <Text secondary={!matchAny}>
            <FormattedMessage
              id='FilterLocationContainer.matchAny'
              defaultMessage='Matches any of...'
            />
          </Text>
          <Switch
            checked={matchAny}
            onChange={toggleMatchAny}
            ariaLabel={`Matches any of selected ${category}`}
          />
        </div>
      )}
      <>
        <FilterCheckboxListContainer
          filterKey={firstLevelKey}
          list={firstLevelOptions}
          selected={getSelected(firstLevelKey)}
          onChange={matchAny ? handleOnChange : undefined}
          selectedOnly={
            !matchAny || isFilteredSecondLevel || isFilteredThirdLevel
          }
          translatedMessages={translatedMessages}
          showValue={showValue}
          category={category}
        />

        {isFilteredFirstLevel && secondLevelOptions.size > 0 && (
          <>
            <Divider className='mv1' />
            <div className='pl2'>
              <FilterCheckboxListContainer
                filterKey={secondLevelKey}
                list={secondLevelOptions}
                selected={getSelected(secondLevelKey)}
                onChange={matchAny ? handleOnChange : undefined}
                selectedOnly={!matchAny || isFilteredThirdLevel}
                translatedMessages={translatedMessages}
                showValue={showValue}
                category={category}
              />
            </div>
          </>
        )}

        {((isFilteredSecondLevel && thirdLevelOptions.size > 0) ||
          (isFilteredFirstLevel &&
            secondLevelOptions.size === 0 &&
            thirdLevelOptions.size > 0)) && (
          <>
            <Divider className='mv1' />
            <div className='pl3'>
              <FilterCheckboxListContainer
                filterKey={thirdLevelKey}
                list={thirdLevelOptions}
                selected={getSelected(thirdLevelKey)}
                onChange={matchAny ? handleOnChange : undefined}
                selectedOnly={!matchAny}
                translatedMessages={translatedMessages}
                showValue={showValue}
                category={category}
              />
            </div>
          </>
        )}
      </>

      {matchAny && (
        <div className='tr'>
          <Divider className='mt1 mb2' />
          <Button
            label={
              <FormattedMessage
                id='FilterLocationContainer.apply'
                defaultMessage='Apply'
              />
            }
            autoSize
            disabled={!changed}
            onClick={handleApplyClick}
          />
        </div>
      )}
      {!matchAny && (
        <>
          <Divider className='mv2' />
          <NaicsSearchContainer onSearchInput={handleSearchInput} buyerSearch />
        </>
      )}
    </>
  )
}

export default FilterHierarchy
