import React, { Component } from 'react'
import withUser from '../../utils/withUser'
import Avatar from '../../components/Avatar'
import Tooltip from 'shared/components/Tooltip'
import Link from 'shared/components/Link'

type Props = {
  profilePictureUrl: string
  email: string
  firstName: string
  lastName: string
  userId: string
  profileUrl: string
}
class UserAvatarContainer extends Component<Props> {
  render() {
    const {
      profilePictureUrl,
      email,
      firstName,
      lastName,
      userId,
      profileUrl,
      ...rest
    } = this.props
    const fullName = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`

    return (
      <>
        <Tooltip title={fullName} aria-label='Name'>
          <Link to={profileUrl}>
            <Avatar
              {...rest}
              url={profilePictureUrl}
              name={`${firstName || ''} ${lastName || ''}`}
            />
          </Link>
        </Tooltip>
      </>
    )
  }
}

export default withUser(UserAvatarContainer)
