import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import certificationCategories from 'shared/models/CertificationCategories'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'

export type RulesType = {
  baseRules_certAgencies: string[]
  baseRules_countries: string[]
  baseRules_subCategories: string[]
  includeRules_attestation_selfCertified: boolean
  includeRules_attestation_notVerifiedByTealbook: boolean
  includeRules_attestation_certAgencies: string[]
  includeRules_attestation_certAgenciesSelected: boolean
  includeRules_completeness_attachmentAgencies: string[]
  includeRules_completeness_attachmentAgenciesSelected: boolean
  includeRules_myTeam: string
  includeRules_tealbook: string
  excludeRules_myTeam: string
}

type Props = {
  rules: RulesType
}

const RulesSummary = (props: Props) => {
  const intl = useIntl()

  const {
    baseRules_certAgencies,
    baseRules_countries,
    baseRules_subCategories,
    includeRules_attestation_selfCertified,
    includeRules_attestation_notVerifiedByTealbook,
    includeRules_attestation_certAgencies,
    includeRules_attestation_certAgenciesSelected,
    includeRules_completeness_attachmentAgencies,
    includeRules_completeness_attachmentAgenciesSelected,
    includeRules_myTeam,
    includeRules_tealbook,
    excludeRules_myTeam
  } = props.rules

  return (
    <div className='dtc w-50'>
      <div className='ph2'>
        {baseRules_subCategories && baseRules_subCategories.length > 0 && (
          <>
            <Label>
              <FormattedMessage
                id='DialogFilter.TypeOfCertificates'
                defaultMessage='Type of Certificates'
              />
            </Label>
            <Text>
              {baseRules_subCategories
                .map(subCategory =>
                  certificationCategories.diversity.subCategoriesAcronyms[
                    subCategory
                  ]
                    ? intl.formatMessage(
                        certificationCategories.diversity.subCategoriesAcronyms[
                          subCategory
                        ]
                      )
                    : subCategory.toUpperCase()
                )
                .join(', ')}
            </Text>
          </>
        )}
        {baseRules_certAgencies && baseRules_certAgencies.length > 0 && (
          <>
            <Label>
              <FormattedMessage
                id='DialogFilter.CertifyingAgencies'
                defaultMessage='Certifying Agencies'
              />
            </Label>
            <Text>{baseRules_certAgencies.join(', ')}</Text>
          </>
        )}
        {baseRules_countries && baseRules_countries.length > 0 && (
          <>
            <Label>
              <FormattedMessage
                id='DialogFilter.SpendCountries'
                defaultMessage='Spend Location'
              />
            </Label>
            <Text>
              {baseRules_countries[0] === 'nonUS' ? (
                <FormattedMessage
                  id='DialogFilter.nonUS'
                  defaultMessage='Non U.S.'
                />
              ) : (
                <FormattedMessage
                  id='DialogFilter.UnitedStates'
                  defaultMessage='United States'
                />
              )}
            </Text>
          </>
        )}
        <Label>
          <FormattedMessage
            id='DialogFilter.CertificatesVerifiedMyCompany'
            defaultMessage='Certificates Verified by My Company'
          />
        </Label>
        <Text>
          {includeRules_myTeam === 'ignore' ? (
            <FormattedMessage
              id='DialogFilter.MyCompanyIgnore'
              defaultMessage='Ignore Verified by my company'
            />
          ) : includeRules_myTeam === 'includeExpired' ? (
            <FormattedMessage
              id='DialogFilter.MyCompanyInclude1'
              defaultMessage='Include only if certificates meet selected {certAgencies} requirements. Please note that these certificates could be expired.                    '
              values={{
                certAgencies: (
                  <span className='f7 fw8 mid-gray'>
                    <FormattedMessage id='DialogFilter.CertifyingAgencies' />
                  </span>
                )
              }}
            />
          ) : (
            <FormattedMessage
              id='DialogFilter.MyCompanyDoNotMeetInclude1'
              defaultMessage='Include even if certificates do not meet selected {certAgencies} requirements. Please note that these certificates could be expired.                    '
              values={{
                certAgencies: (
                  <span className='f7 fw8 mid-gray'>
                    <FormattedMessage id='DialogFilter.CertifyingAgencies' />
                  </span>
                )
              }}
            />
          )}
        </Text>
        <Label>
          <FormattedMessage
            id='DialogFilter.CertificatesRejectedMyCompany'
            defaultMessage='Certificates Rejected by My Company'
          />
        </Label>
        <Text>
          {excludeRules_myTeam === 'rejected' ? (
            <FormattedMessage
              id='DialogFilter.MyCompanyRejected1'
              defaultMessage='Exclude even if certificates meet selected {certAgencies}requirements. Please note that these certificates could be expired.'
              values={{
                certAgencies: (
                  <span className='f7 fw8 mid-gray'>
                    <FormattedMessage id='DialogFilter.CertifyingAgencies' />
                  </span>
                )
              }}
            />
          ) : (
            <FormattedMessage
              id='DialogFilter.MyCompanyRejected2'
              defaultMessage='Ignore Rejected by my company'
            />
          )}
        </Text>
        <Label>
          <FormattedMessage
            id='DialogFilter.CertificatesVerifiedTealbook'
            defaultMessage='Certificates Verified by TealBook'
          />
        </Label>
        <Text>
          {includeRules_tealbook === 'ignore' ? (
            <FormattedMessage
              id='DialogFilter.TealbookIgnore'
              defaultMessage='Ignore Verified by TealBook'
            />
          ) : includeRules_tealbook === 'includeValid' ? (
            <FormattedMessage
              id='DialogFilter.TealbookInclude1'
              defaultMessage='Include only if certificates meet selected {certAgencies} requirements.'
              values={{
                certAgencies: (
                  <span className='f7 fw8 mid-gray'>
                    <FormattedMessage id='DialogFilter.CertifyingAgencies' />
                  </span>
                )
              }}
            />
          ) : (
            <FormattedMessage
              id='DialogFilter.TealbookDoNotMeetInclude1'
              defaultMessage='Include even if certificates do not meet selected {certAgencies} requirements.'
              values={{
                certAgencies: (
                  <span className='f7 fw8 mid-gray'>
                    <FormattedMessage id='DialogFilter.CertifyingAgencies' />
                  </span>
                )
              }}
            />
          )}
        </Text>
        {includeRules_completeness_attachmentAgenciesSelected &&
          includeRules_completeness_attachmentAgencies.length > 0 && (
            <>
              <Label>
                <FormattedMessage
                  id='DialogFilter.Completeness'
                  defaultMessage='Completeness Rules'
                />
              </Label>
              <Text>
                <FormattedMessage
                  id='DialogFilter.IncludeAttachment'
                  defaultMessage='Only include certificates with an attachment for these agencies'
                />
                :
              </Text>
              <Text secondary>
                {includeRules_completeness_attachmentAgencies.join(', ')}
              </Text>
            </>
          )}
        {((includeRules_attestation_certAgenciesSelected &&
          includeRules_attestation_certAgencies.length > 0) ||
          includeRules_attestation_notVerifiedByTealbook ||
          includeRules_attestation_selfCertified) && (
          <>
            <Label>
              <FormattedMessage
                id='DialogFilter.Attestation'
                defaultMessage='Attestation Rules'
              />
            </Label>
            {includeRules_attestation_selfCertified && (
              <Text>
                <FormattedMessage
                  id='DialogFilter.Attestation1'
                  defaultMessage='Require for Self Certified'
                />
              </Text>
            )}
            {includeRules_attestation_notVerifiedByTealbook && (
              <Text>
                <FormattedMessage
                  id='DialogFilter.Attestation2'
                  defaultMessage='Require where Certificates not verified by TealBook'
                />
              </Text>
            )}
            {includeRules_attestation_certAgencies.length > 0 && (
              <>
                <Text>
                  <FormattedMessage
                    id='DialogFilter.RequireAttestationAgencies'
                    defaultMessage='Require for these Certifying Agencies'
                  />
                  :
                </Text>
                <Text secondary>
                  {includeRules_attestation_certAgencies.join(', ')}
                </Text>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default RulesSummary
