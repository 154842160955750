import React, { Component } from 'react'
import NavLink from '../NavBar/NavLink'
import supplierPaths from 'supplier/routes/paths'
import buyerPaths from 'buyer/routes/paths'
import { injectIntl, defineMessages, IntlShape } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'

const messages = defineMessages({
  tealboard: {
    id: 'TealboardNavLink.News',
    defaultMessage: 'News'
  },
  tealboardTip: {
    id: 'TealboardNavLink.tealboardTip',
    defaultMessage: 'Post and read short articles'
  }
})

type Props = {
  unread: boolean
  to: string
  pathname: string
  intl: IntlShape
}

export class TealboardNavLink extends Component<Props> {
  render() {
    const { unread, to, pathname, intl } = this.props
    return (
      <Tooltip title={intl.formatMessage(messages.tealboardTip)}>
        <NavLink
          label={intl.formatMessage(messages.tealboard)}
          to={to}
          numberOfNotifications={unread ? 1 : undefined}
          isActive={() =>
            [supplierPaths.tealboard, buyerPaths.tealboard].some(
              paths => pathname === paths
            )
          }
        />
      </Tooltip>
    )
  }
}

export default injectIntl(TealboardNavLink)
