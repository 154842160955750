import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import { getFormValues } from 'redux-form/immutable'
import { createSelector } from 'reselect'
import AddUserContactForm from 'shared/components/AddUserContactForm'
import profileSelectors from '../../../SupplierProfile/selectors/profileSelectors'
import { RecordOf } from 'immutable'
import { ADD_OUTREACH_CONTACT } from '../../../../buyer/SupplierProfile/actionTypes'
import newOutreachContactSelectors from '../../../SupplierProfile/selectors/newOutreachContactSelectors'
import createAddForm from 'shared/utils/createAddForm'
import sessionSelectors from 'shared/selectors/sessionSelectors'

const formName = 'buyer/supplierProfile/addContact'
const formValueSelector = getFormValues(formName)

type FormData = RecordOf<{
  user: RecordOf<{
    email: string
  }>
  sendMessage: string
  ignoreEmail: boolean
}>

type Props = {
  defaultMessage: string
  requestSelfCertify?: boolean
  requestESGSurvey?: boolean
  resendOutreachRequest?: boolean
  shareESGSurveyLink?: boolean
  contactSupplierForOutreach?: boolean
  taskId?: string
  supplierId?: string
  supplierName?: string
  relationshipId?: string
  onClickCancel: () => void
}

const getInitialValues = createSelector(
  [
    newOutreachContactSelectors.getSelectedUser,
    newOutreachContactSelectors.getNewUser,
    (state, ignoreEmail) => ignoreEmail
  ],
  (selectedUser, newUser, ignoreEmail) => ({
    user: selectedUser || newUser,
    isPrivate: false,
    message: 'I would like to add you as a contact in TealBook.',
    sendMessage: false,
    ignoreEmail
  })
)

export default connect((state: RootState, props: Props) => {
  const selectedUser = newOutreachContactSelectors.getSelectedUser(state)
  const addSuccess = newOutreachContactSelectors.addSuccess(state)
  const formValues = formValueSelector(state) as FormData
  const user = sessionSelectors.getUser(state)

  return {
    caption: 'Add Contact',
    email: formValues && formValues.getIn(['user', 'email']),
    enablePublicPrivate: true,
    domains: profileSelectors.getDataField(state, 'domains') || [],
    addingContact: newOutreachContactSelectors.isAddingContact(state),
    creatingNewUser: newOutreachContactSelectors.isCreatingNewUser(state),
    forContact: true,
    addSuccess,
    addedContactId: selectedUser && selectedUser.get('id'),
    addedContactName:
      selectedUser &&
      `${selectedUser.get('firstName') || ''} ${selectedUser.get('lastName') ||
        ''}`,

    initialValues: getInitialValues(state, props.requestESGSurvey || props.requestSelfCertify),
    hideLookup: true,
    defaultMessage: props.defaultMessage,
    requestSelfCertify: props.requestSelfCertify,
    requestESGSurvey: props.requestESGSurvey,
    resendOutreachRequest: props.resendOutreachRequest,
    shareESGSurveyLink: props.shareESGSurveyLink,
    contactSupplierForOutreach: props.contactSupplierForOutreach,
    taskId: props.taskId,
    currentUserId: user && user.get('id'),
    onClickCancel: props.onClickCancel
  }
})(
  createAddForm(
    {
      form: formName,
      submitActionType: ADD_OUTREACH_CONTACT
    },
    AddUserContactForm
  )
)
