import React, { FC, ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'

type Props = {
  value: number | string
  description: string | ReactElement<FormattedMessage>
  hidePercentage?: boolean
  percentage: number | undefined
  color?: string
  ringColor?: 'white' | 'near-white' | 'moon-gray' | 'gold'
  wrapperClasses?: string
}

const QuickFactLine: FC<Props> = ({
  value,
  hidePercentage = false,
  percentage,
  description,
  color = '#00B8A1',
  ringColor = 'white',
  wrapperClasses
}) => {
  return (
    <div
      className={`flex justify-between items-center ${
        wrapperClasses ? wrapperClasses : ''
      }`}
    >
      <div className='flex-auto'>
        <div className='f7 fw6 lh-copy teal'>
          {typeof value === 'number' ? value.toLocaleString() : value}
        </div>
        <div
          className={`bt bw b--moon-gray lh-copy f7 fw4 mid-gray ${
            hidePercentage ? 'tr' : ''
          }`}
        >
          {description}
        </div>
      </div>
      {!hidePercentage && (
        <div
          className={`flex items-center justify-center br-100 ba b--${ringColor} bw1`}
          style={{ flex: '0 0 48px', height: 48, width: 48, background: color }}
        >
          <span className='white f9 fw4'>
            {percentage ? (
              `${percentage}%`
            ) : (
              <FormattedMessage id='QuickFactLine.NA' defaultMessage='N/A' />
            )}
          </span>
        </div>
      )}
    </div>
  )
}

export default QuickFactLine
