import React from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes/paths'
import { Redirect, Route, Switch } from 'react-router'
import InternalStatsContainer from 'shared/containers/InternalStatsContainer'
import InsightNavBarWrapper from 'shared/components/InsightNavBarWrapper'
import { useSelector } from 'react-redux'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { List } from 'immutable'

const getDefaultRoute = roles =>
  roles.includes('clientConcierge') ? paths.clientStats : paths.supplierStats

const Statistics = () => {
  const roles: List<string> = useSelector(state =>
    sessionSelectors.getUser(state).get('roles')
  )
  return (
    <>
      <InsightNavBarWrapper>
        <NavBar>
          {roles.includes('clientConcierge') && (
            <NavLink label='Client Stats' to={paths.clientStats} />
          )}
          {roles.includes('supplierConcierge') && (
            <NavLink label='Supplier Stats' to={paths.supplierStats} />
          )}
          {roles.includes('clientConcierge') && (
            <NavLink label='Alliances Stats' to={paths.alliancesStats} />
          )}
        </NavBar>
      </InsightNavBarWrapper>
      <Switch>
        {roles.includes('clientConcierge') && (
          <Route path={paths.clientStats}>
            <InternalStatsContainer type='buyerConcierge' />
          </Route>
        )}
        {roles.includes('supplierConcierge') && (
          <Route path={paths.supplierStats}>
            <InternalStatsContainer type='supplierConcierge' />
          </Route>
        )}
        {roles.includes('clientConcierge') && (
          <Route path={paths.alliancesStats}>
            <InternalStatsContainer type='alliancesStats' />
          </Route>
        )}
        <Redirect from={paths.basePath} to={getDefaultRoute(roles)} />
      </Switch>
    </>
  )
}

export default Statistics
