import React, { FormEvent, useState } from 'react'
import { login } from 'accounts/actions'
import TealbookLogoWrapper from '../../components/TealbookLogoWrapper'
import { RouteComponentProps } from 'react-router'
import qs from 'qs'
import users from 'shared/utils/api/users'
import { fromJS } from 'immutable'
import Card from 'shared/components/Card'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import { FormattedMessage } from 'react-intl'
import Warning from 'shared/components/Warning'
import { useDispatch } from 'react-redux'

type Props = RouteComponentProps

const LoginForScanner = ({ location }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPasword] = useState<string>('')

  const dispatch = useDispatch()
  const { client } = qs.parse(location.search, {
    ignoreQueryPrefix: true
  })

  const handleSubmitForScanner = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    users.getAllByEmail(email).then(result => {
      if (!!result.length) {
        setErrorMessage('')
        dispatch(
          login(
            fromJS({
              username: email,
              password,
              orgUnitId: result[0].orgUnitId
            })
          )
        )
      } else {
        setErrorMessage(`We can't find ${email}`)
      }
    })
  }
  return (
    <TealbookLogoWrapper clientOf={client}>
      <form className='tl' onSubmit={handleSubmitForScanner}>
        <Card>
          <Label htmlFor='username' className='db mb1 f7 fw6'>
            <FormattedMessage id='LoginForm.email' />
          </Label>
          <Input
            name='username'
            type='email'
            placeholder='email@xyz.com'
            required
            value={email}
            onChange={e => setEmail(e.currentTarget.value)}
          />
          <Label htmlFor='password'>
            <FormattedMessage
              id='LoginForm.password'
              defaultMessage='Password'
            />
          </Label>
          <FormattedMessage id='LoginForm.password'>
            {message => (
              <Input
                name='password'
                type='password'
                placeholder={message as string}
                required
                value={password}
                onChange={e => setPasword(e.currentTarget.value)}
              />
            )}
          </FormattedMessage>

          <Button
            className='mv3'
            type='submit'
            label={
              <FormattedMessage id='LoginForm.login' defaultMessage='Log In' />
            }
          />
          {errorMessage && <Warning className='mv3' message={errorMessage} />}
        </Card>
      </form>
    </TealbookLogoWrapper>
  )
}

export default LoginForScanner
