import React, { Component } from 'react'
import Page from '../Page'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import ExternalLink from 'shared/components/ExternalLink'
export class FAQ extends Component {
  render() {
    return (
      <Page title='FAQ'>
        <Paper className='pa3-5'>
          <Typography>
            <ExternalLink
              className={'f5 underline pointer'}
              href='https://tealbook.com/terms'
            >
              <FormattedMessage
                id='FAQ.termsOfService'
                defaultMessage='Terms of Service'
              />
            </ExternalLink>
          </Typography>
          <Typography>
            <ExternalLink
              className={'f5 underline pointer'}
              href='https://tealbook.com/community/'
            >
              <FormattedMessage
                id='FAQ.communityGuidelines'
                defaultMessage='Community Guidelines'
              />
            </ExternalLink>
          </Typography>
          <Typography>
            <ExternalLink
              className={'f5 underline pointer'}
              href='https://tealbook.com/privacy/'
            >
              <FormattedMessage
                id='FAQ.privacy'
                defaultMessage='Privacy Policy'
              />
            </ExternalLink>
          </Typography>
          <Typography variant='h6' gutterBottom>
            <FormattedMessage
              id='FAQ.SignedUpForTealbook'
              defaultMessage='I just signed up for TealBook. How can I start generating visibility from my profile?'
            />
          </Typography>
          <Typography paragraph>
            <FormattedMessage
              id='FAQ.WelcomeToTealbook'
              defaultMessage='Welcome to TealBook! In order to get the most out of your experience, make sure buyers have as much information about you as possible. Enrich your profile by:'
            />
            <ul>
              <li>
                <FormattedMessage
                  id='FAQ.AddingAnAddressAndDescription'
                  defaultMessage='Adding an address and brief description'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.LinkSocialMedia'
                  defaultMessage='Linking to your social media feeds and blog'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.ListOfOffereings'
                  defaultMessage='Adding a complete list of offerings'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.MarketableSlideDeck'
                  defaultMessage='Adding a marketable slide deck'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.ContactInfo'
                  defaultMessage='Adding contact info for team members'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.IncludingTags'
                  defaultMessage='Including tags for what your business is known for'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.WhereYouDoBusiness'
                  defaultMessage='Adding where you do business'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.LinkingBlogAndVideos'
                  defaultMessage='Linking to your blog and adding videos'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.ProfileCompleteness'
                  defaultMessage='Checking your profile completeness meter on the right hand side of your profile, and follow the tips to improve your score'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.Webinar'
                  defaultMessage='Signing up for our weekly (free!) supplier optimization webinar where you can learn from a TealBook expert and ask them questions'
                />
              </li>
            </ul>
          </Typography>

          <Typography variant='h6' gutterBottom>
            <FormattedMessage
              id='FAQ.InsightOnProfile'
              defaultMessage='How can I get insights on my profile?'
            />
          </Typography>
          <Typography paragraph>
            <FormattedMessage
              id='FAQ.InsightTab'
              defaultMessage='By clicking on the "Insight" tab on the top right hand side of your profile, you will be able to access and view a detailed dashboard, including:'
            />
            <ul>
              <li>
                <FormattedMessage
                  id='FAQ.ProfileViews'
                  defaultMessage='How many times your profile has been viewed'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.LastViewed'
                  defaultMessage='Date your profile was last viewed'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.NumberOfSearches'
                  defaultMessage='How many searches your profile has appeared in'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.PlacementInSearchResult'
                  defaultMessage='Best placement in search results'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.MedianPlacementInSearchResults'
                  defaultMessage='Median placement in search results'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.KeyWordSearch'
                  defaultMessage='Times keywords matched search terms'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.CustomerEndorsedOfferings'
                  defaultMessage='Your customer endorsed offerings'
                />
              </li>
              <li>
                <FormattedMessage
                  id='FAQ.CustomerConnections'
                  defaultMessage='Customer connections'
                />
              </li>
            </ul>
          </Typography>

          <Typography variant='h6' gutterBottom>
            <FormattedMessage
              id='FAQ.WhoCanViewProfile'
              defaultMessage='Who can see my profile?'
            />
          </Typography>
          <Typography paragraph>
            <FormattedMessage
              id='FAQ.BuyersViewYourProfile'
              defaultMessage="All of TealBook's enterprise buyer clients can see your profile and add it to
              their personal TealBook account. Other suppliers are not able to see your profile,
              and you are not able to see theirs."
            />
          </Typography>

          <Typography variant='h6' gutterBottom>
            <FormattedMessage
              id='FAQ.WhatIsAnRFI'
              defaultMessage='What is an RFI?'
            />
          </Typography>
          <Typography paragraph>
            <FormattedMessage
              id='FAQ.WhatAnRFIs'
              defaultMessage='When new opportunities arise, buyers use TealBook to find the suppliers most
              suited for the requirements. Buyers can invite you to participate in what is
              called an RFI, or Request for Information, to help them identify the best
              potential partners for each opportunity. You can easily accept or decline an RFI,
              and the process ensures that you are well-suited for a need before you spend
              precious time and resources preparing a RFP. Our RFI process is informed by
              the information in your profile as well as by the recommendations and
              endorsements of the TealBook buyer community.'
            />
          </Typography>

          <Typography variant='h6' gutterBottom>
            <FormattedMessage
              id='FAQ.WhatIsNews'
              defaultMessage='What is News and what should I post there?'
            />
          </Typography>
          <Typography paragraph>
            <FormattedMessage
              id='FAQ.WhatANewsIs'
              defaultMessage='News is our activity feed that you can use to share your business news,
              updates, changes in capabilities, offerings and events. You can select which
              community you would like your posts to be visible to. By actively engaging
              with News, you can expand visibility to existing and potential clients
              and keep them up-to-date with your business. Make your posts more appealing
              by including an image. Posts on News should be informative, relevant and
              appropriate.'
            />
          </Typography>

          <Typography variant='h6' gutterBottom>
            <FormattedMessage
              id='FAQ.WhatIsACommunity'
              defaultMessage='What are communities in TealBook?'
            />
          </Typography>
          <Typography paragraph>
            <FormattedMessage
              id='FAQ.WhatIsACommunityDescription'
              defaultMessage={`You’ll notice when you log in to TealBook there is a "Communities" tab on the
              top right hand side. By joining a TealBook community, you indicate to buyers
              that you are especially interested in doing business in this particular area.
              Buyers use these communities in supplier discovery, so joining ones most
              suited to your area of expertise can increase your chances of being seen prior
              to sourcing events. Additionally, when you post to News, you can select
              which community you would like the post to visible to.`}
            />
          </Typography>

          <Typography variant='h6' gutterBottom>
            <FormattedMessage
              id='FAQ.DiverseSupplier'
              defaultMessage='I’m a diverse supplier. Can I showcase that on my TealBook profile?'
            />
          </Typography>
          <Typography paragraph>
            <FormattedMessage
              id='FAQ.ShowingDiversity'
              defaultMessage='Yes! TealBook is a game changer for diversity suppliers. Your TealBook profile
              allows you to display diversity certifications directly on your profile,
              preventing the need for companies to search in a separate portal for you.
              Easily add your diversity status, certification, and affiliations right on
              your TealBook profile.'
            />
          </Typography>

          <Typography variant='h6' gutterBottom>
            <FormattedMessage
              id='FAQ.HowIsTealbookDifferent'
              defaultMessage='How is TealBook different than a business directory?'
            />
          </Typography>
          <Typography paragraph>
            <FormattedMessage
              id='FAQ.WhyTealbookIsDifferent'
              defaultMessage='Tealbook uses machine learning to recommend new suppliers to buyers based on
              those they already do business with. This means that by optimizing your
              TealBook profile, you can expand visibility to potential buyers who your
              capabilities are well suited for. You also have control over your profile
              and what information appears there, allowing you to update based on changing
              capabilities, as well as target your profile to your ideal clients.'
            />
          </Typography>

          <Typography variant='h6' gutterBottom>
            <FormattedMessage
              id='FAQ.Contact'
              defaultMessage='Can I contact someone at TealBook to ask a question not found here?'
            />
          </Typography>
          <Typography paragraph>
            <FormattedMessage
              id='FAQ.ContactUs'
              defaultMessage='Absolutely! Feel free to reach out to us at support@tealbook.com and we will
              set up a call to discuss how we can support your efforts.'
            />
          </Typography>
        </Paper>
      </Page>
    )
  }
}

export default FAQ
