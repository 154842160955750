import React, { Component, FunctionComponent, ReactNode } from 'react'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'

type PricingItemRowProps = {
  name: ReactNode
  description?: string
  qty: ReactNode
  price: ReactNode
  unitPrice: ReactNode
  isHeader?: boolean
}

type PricingTableProps = {
  pricing: any
  proposalPricing: any
  description?: string
}

export const PricingItemRow: FunctionComponent<PricingItemRowProps> = ({
  name,
  description,
  qty,
  price,
  unitPrice,
  isHeader
}) => (
  <div className='dt w-100 pv2 bb b--black-10'>
    <div className={`dtc`}>
      <Text className={`${isHeader ? 'fw6' : 'fw4'}`}>{name}</Text>
      {description && <Text secondary>{description}</Text>}
    </div>
    <div className={`dtc w-10`}>
      <Text className={`${isHeader ? 'fw6' : 'fw4'}`}>{qty}</Text>
    </div>
    {unitPrice !== undefined && (
      <div className={`dtc w-20 tr`}>
        <Text className={`${isHeader ? 'fw6' : 'fw4'}`}>{unitPrice}</Text>
      </div>
    )}
    <div className={`dtc w-20 tr`}>
      <Text className={`${isHeader ? 'fw6' : 'fw4'}`}>{price}</Text>
    </div>
  </div>
)

class PricingTable extends Component<PricingTableProps> {
  render() {
    const { pricing, proposalPricing } = this.props

    return (
      <div className='gray f6 mt4'>
        <PricingItemRow
          name={
            <FormattedMessage id='PricingTable.Name' defaultMessage='Name' />
          }
          qty={<FormattedMessage id='PricingTable.Qty' defaultMessage='Qty' />}
          unitPrice={
            proposalPricing.get('unit') ? (
              <FormattedMessage
                id='PricingTable.UnitPrice'
                defaultMessage='Unit Price'
              />
            ) : (
              undefined
            )
          }
          price={
            <FormattedMessage
              id='PricingTable.ExtendedPrice'
              defaultMessage='Extended Price'
            />
          }
          isHeader
        />
        {proposalPricing.get('lineItems') && (
          <>
            {proposalPricing.get('lineItems').map(item => {
              const pricingItem = pricing
                .get('lineItems')
                .find(pItem => pItem.get('id') === item.get('id'))
              if (!pricingItem) {
                return null
              }
              const enteredPrice = item.get('amount') || 0
              const actualPrice = proposalPricing.get('unit')
                ? pricingItem.get('quantity') * enteredPrice
                : enteredPrice

              return (
                <PricingItemRow
                  key={item.get('id')}
                  name={pricingItem.get('name')}
                  description={pricingItem.get('description')}
                  qty={`${pricingItem
                    .get('quantity')
                    .toLocaleString()} ${pricingItem.get('UOM')}`}
                  unitPrice={
                    proposalPricing.get('unit')
                      ? enteredPrice.toLocaleString()
                      : undefined
                  }
                  price={actualPrice.toLocaleString()}
                />
              )
            })}
            <Text className='mv2 fw6 tr'>
              <FormattedMessage
                id='PricingTable.Total'
                defaultMessage='Total'
              />
              &nbsp;
              {proposalPricing
                .get('lineItems')
                .reduce((total, item) => {
                  const pricingItem = pricing
                    .get('lineItems')
                    .find(pItem => pItem.get('id') === item.get('id'))
                  if (!pricingItem) {
                    return null
                  }
                  const enteredPrice = item.get('amount') || 0
                  return proposalPricing.get('unit')
                    ? pricingItem.get('quantity') * enteredPrice + total
                    : enteredPrice + total
                }, 0)
                .toLocaleString()}
              &nbsp;({pricing.get('currency')})
            </Text>
          </>
        )}
      </div>
    )
  }
}

export default PricingTable
