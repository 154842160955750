import React, { FC } from 'react'
import { connect } from 'react-redux'
import statsSelectors from '../../../shared/selectors/statsSelectors'
import TagCloud from 'shared/components/TagCloud'
import Text from 'shared/components/Text'
import classNames from 'classnames'
import WidgetWrapper from '../../components/WidgetWrapper'
import { FormattedMessage } from 'react-intl'
import { Map } from 'immutable'

const styles = {
  widgetInnerBox: 'cf ph2-ns bg-white br2 ba b--black-10',
  widgetInnerCell: 'fl w-100 w-third-ns pa2',
  statValue: 'teal f2 f3-m lh-copy',
  tagCloudtitle: 'mv3 normal'
}

type Props = {
  topPlacement: number
  medianPlacement: number
  keywordsMatched: number
  words: Map<string, number>
}

export const SearchMap: FC<Props> = ({
  topPlacement,
  medianPlacement,
  keywordsMatched,
  words
}) => {
  return topPlacement || medianPlacement || keywordsMatched ? (
    <WidgetWrapper
      title={
        <FormattedMessage
          id='SearchMap.SearchPopularity'
          defaultMessage='Search Popularity'
        />
      }
    >
      <div className={styles.widgetInnerBox}>
        <div
          className={classNames(styles.widgetInnerCell, 'br-ns b--black-10')}
        >
          <div className={styles.statValue}>{topPlacement || 'N/A'}</div>
          <Text>
            <FormattedMessage
              id='SearchMap.BestPlacement'
              defaultMessage='Best placement'
            />
          </Text>
        </div>
        <div
          className={classNames(styles.widgetInnerCell, 'br-ns b--black-10')}
        >
          <div className={styles.statValue}>{medianPlacement || 'N/A'}</div>
          <Text>
            <FormattedMessage
              id='SearchMap.MedianPlacement'
              defaultMessage='Median placement'
            />
          </Text>
        </div>
        <div className={styles.widgetInnerCell}>
          <div className={styles.statValue}>
            {(keywordsMatched && keywordsMatched.toLocaleString()) || '0'}
          </div>
          <Text>
            <FormattedMessage
              id='SearchMap.TimesKeywordsMatched'
              defaultMessage='Times keywords matched'
            />
          </Text>
        </div>
      </div>
      {words && words.size > 0 && (
        <div>
          <Text className={styles.tagCloudtitle} role='heading' aria-level={3}>
            <FormattedMessage
              id='SearchMap.KeywordsThatMatchedText'
              defaultMessage='Keywords that matched'
            />
          </Text>
          <div className={classNames(styles.widgetInnerBox, 'tc pv2')}>
            <TagCloud
              tags={
                words.toJS() as {
                  [key: string]: number
                }
              }
              colors={[
                '#1E6A77',
                '#009CB3',
                '#24CDDA',
                '#00B8A1',
                '#F1CD5A',
                '#DB7905',
                '#BD3D20',
                '#541C33'
              ]}
            />
          </div>
        </div>
      )}
    </WidgetWrapper>
  ) : null
}

export default connect(state => {
  const searchStats = statsSelectors.getSearchStats(state)

  return {
    topPlacement: searchStats && searchStats.topPlacement,
    medianPlacement: searchStats && searchStats.medianPlacement,
    keywordsMatched: statsSelectors.getTermsQueryMatch(state),
    words: statsSelectors.getTermsQuery(state)
  }
})(SearchMap)
