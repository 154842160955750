import React from 'react'
import TaskListContainer from './containers/TaskListContainer'
import TaskDetailsContainer from './containers/TaskDetailsContainer'
import { Route, Switch } from 'react-router'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import Divider from 'shared/components/Divider'

const App = () => {
  const basePath = '/data-portal/tasks'

  return (
    <>
      <div className='bg-white pl4'>
        <NavBar className='mw8 center'>
          <NavLink label='Your Tasks' to={basePath} />
        </NavBar>
      </div>

      <Divider />

      <Switch>
        <Route exact path={`${basePath}/:taskId`}>
          {({ match }) => (
            <TaskDetailsContainer taskId={match?.params.taskId} />
          )}
        </Route>
        <Route path={basePath} component={TaskListContainer} />
      </Switch>
    </>
  )
}

export default App
