import React, { useState, ChangeEvent, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RootState from 'shared/models/RootState'
import Text from 'shared/components/Text'
import Select from 'shared/components/Select'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import Divider from 'shared/components/Divider'
import { DatePicker } from '@material-ui/pickers'
import {
  loadTier2DiverseSummary,
  postTier2DiverseSummary,
  removeTier2DiverseSummary
} from '../../store/actions'
import { DiverseSummaryType } from '../../store/tier2Reducer'
import Loading from 'shared/components/Loading'
import moment, { Moment } from 'moment'
import SummaryRow from './SummaryRow'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import dataSelectors from 'shared/selectors/dataSelectors'
import { List, RecordOf, OrderedMap } from 'immutable'
import { UploadHistoryType } from '../LoadPage/LoadPage'
import dateFormat from 'shared/utils/dateFormat'
import Warning from 'shared/components/Warning'

type Props = {
  country?: string
}

const LoadSummary = ({ country }: Props) => {
  const dispatch = useDispatch()
  const completedStagingHistory:
    | OrderedMap<string, List<RecordOf<UploadHistoryType>>>
    | undefined = useSelector(state => dataSelectors.getCompletedStaging(state))
  const diverseSummary: DiverseSummaryType = useSelector((state: RootState) =>
    state.getIn(['supplier', 'tier2', 'diverseSummary'])
  )

  const [summaryBy] = useState<'month' | 'quarter'>('quarter')
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)
  const [selectedMonthlyInputDate, setSelectedMonthlyInputDate] = useState<
    Moment
  >(moment())
  const [selectedYear, setSelectedYear] = useState<number>(0)
  const [selectedQuarter, setSelectedQuarter] = useState<number>(0)
  const [isAdding, setIsAdding] = useState<boolean>(false)
  const [removeId, setRemoveId] = useState<number | undefined>(undefined)
  const [stagingId, setStagingId] = useState<string>('')

  useEffect(() => {
    dispatch(loadTier2DiverseSummary())
  }, [dispatch])

  const handleAccept = (d: Moment) => {
    if (summaryBy === 'month') {
      setSelectedMonthlyInputDate(d)
      setIsAdding(true)
    } else {
      setSelectedYear(d.year())
    }
  }

  const handleSelectQuarter = (quarter: number) => {
    setSelectedQuarter(quarter)
    setIsAdding(true)
  }
  const saveSummary = (
    subCategories: Array<{
      subCategory: string
      subType?: string
      spend: number
    }>
  ) => {
    const fiscalStart = moment(new Date(`${selectedYear}/1/1`))
    let summary
    if (summaryBy === 'quarter') {
      const startDate =
        selectedQuarter > 1
          ? fiscalStart.add(selectedQuarter - 1, 'quarter')
          : fiscalStart
      const dates = [startDate.format('YYYY/MM/DD')]

      summary = {
        year: selectedYear,
        quarter: selectedQuarter,
        stagingId: stagingId ? stagingId.split('|')[1] : undefined,
        dates,
        domain: 'missing.link',
        category: 'diversity',
        subCategories,
        needDivide: false // true
      }
      setSelectedYear(0)
      setSelectedQuarter(0)
      setStagingId('')
    } else {
      selectedMonthlyInputDate.date(fiscalStart.date())

      summary = {
        dates: [selectedMonthlyInputDate.format('YYYY/MM/DD')],
        domain: 'missing.link',
        category: 'diversity',
        subCategories,
        needDivide: false
      }
    }
    dispatch(postTier2DiverseSummary(summary))

    setIsAdding(false)
  }

  const removeSummary = loadId => {
    setRemoveId(loadId)
  }

  const handleRemoveSummary = () => {
    dispatch(removeTier2DiverseSummary(removeId))
    setRemoveId(undefined)
  }

  const handleStagingSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value) {
      const parseValue = e.currentTarget.value.split('|')
      if (parseValue.length === 2) {
        const quarter = parseValue[0].split('-')
        setSelectedYear(Number(quarter[0]))
        setSelectedQuarter(Number(quarter[1]))
        setStagingId(e.currentTarget.value)
      }
    } else {
      setSelectedQuarter(0)
      setSelectedYear(0)
      setStagingId('')
    }
  }
  return (
    <div>
      <Text className='mt3'>
        <FormattedMessage
          id='LoadSummary.spendCountryStatement'
          defaultMessage='Please note: all spend entered on this screen is assumed to be US spend only.'
        />
      </Text>
      {country !== 'United States' && (
        <Warning
          className='mt3'
          message={
            <FormattedMessage
              id='LoadSummary.spendCountryWarning'
              defaultMessage="This supplier's primary location is non US"
            />
          }
        />
      )}
      <div className='mt3 flex items-center justify-between'>
        {completedStagingHistory && (
          <Select value={stagingId} onChange={handleStagingSelectChange}>
            <FormattedMessage
              id='LoadSummary.NewLoad'
              defaultMessage='New Load'
            >
              {message => <option value=''>{message}</option>}
            </FormattedMessage>
            {completedStagingHistory
              .entrySeq()
              .map(([quarter, stagingList]) => {
                return stagingList.map(staging => (
                  <option
                    key={staging.get('id')}
                    value={`${quarter}|${staging.get('id')}`}
                  >
                    {quarter} (
                    {`${
                      staging.get('clientFileName')
                        ? `${staging.get(
                            'clientFileName'
                          )} uploaded ${dateFormat(staging.get('uploadDate'))}`
                        : dateFormat(staging.get('uploadDate'))
                    }`}
                    )
                  </option>
                ))
              })}
          </Select>
        )}

        <div className='flex-auto tr'>
          {!stagingId && (
            <Button
              autoSize
              onClick={() => setOpenDatePicker(true)}
              disabled={isAdding}
            >
              {summaryBy === 'month' ? (
                <FormattedMessage
                  id='LoadSummary.AddMonth'
                  defaultMessage='Add a Month'
                />
              ) : (
                <FormattedMessage
                  id='LoadSummary.AddQuarter'
                  defaultMessage='Add a Quarter'
                />
              )}
            </Button>
          )}
          {stagingId && (
            <Button
              autoSize
              onClick={() => setIsAdding(true)}
              disabled={isAdding}
            >
              <FormattedMessage
                id='LoadSummary.AppendToTheLoad'
                defaultMessage='Append to the Load'
              />
            </Button>
          )}
          <DatePicker
            value={selectedMonthlyInputDate}
            views={summaryBy === 'month' ? ['year', 'month'] : ['year']}
            TextFieldComponent={props => <span />}
            open={openDatePicker}
            openTo='year'
            autoOk
            onAccept={handleAccept}
            onClose={() => setOpenDatePicker(false)}
            onChange={() => null}
            minDate={moment().subtract(3, 'years')}
            maxDate={moment()}
          />
        </div>
      </div>
      <Divider className='mv3' />
      {diverseSummary.isLoading ? (
        <Loading />
      ) : (
        <div>
          {isAdding && (
            <div className='ph3 pt3 mb3 br3 ba b--black-10 bg-near-white'>
              <SummaryRow
                quarter={
                  summaryBy === 'quarter'
                    ? `${selectedYear}-Q${selectedQuarter}`
                    : undefined
                }
                date={selectedMonthlyInputDate.toISOString()}
                initalSubCategories={[]}
                onCancel={() => setIsAdding(false)}
                onSave={saveSummary}
              />
            </div>
          )}
          {diverseSummary.data
            ?.sort((s1, s2) =>
              moment(s2.dates[0]).isAfter(moment(s1.dates[0])) ? -1 : 0
            )
            .map(summaryRow => {
              return (
                <SummaryRow
                  key={summaryRow.loadId}
                  isReadOnly
                  quarter={`${summaryRow.year}-Q${summaryRow.quarter}`}
                  date={summaryRow.dates[0]}
                  initalSubCategories={summaryRow.subCategories}
                  onRemove={() => removeSummary(summaryRow.loadId)}
                />
              )
            })}
        </div>
      )}
      <ConfirmationDialog
        open={removeId !== undefined}
        onClose={() => setRemoveId(undefined)}
        onConfirm={handleRemoveSummary}
      >
        <Text>
          <FormattedMessage
            id='LoadSummary.RemoveSummary'
            defaultMessage='Are you sure you want to remove this section?'
          />
        </Text>
      </ConfirmationDialog>
      <Dialog
        open={!!selectedYear && !selectedQuarter}
        onClose={() => {
          setSelectedYear(0)
          setSelectedQuarter(0)
        }}
      >
        <div className='pa3'>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div
                className='dim pointer h3 flex items-center justify-center'
                onClick={() => handleSelectQuarter(1)}
              >
                <FormattedMessage id='LoadSummary.Q1' defaultMessage='Q1' />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className='dim pointer h3 flex items-center justify-center'
                onClick={() => handleSelectQuarter(2)}
              >
                <FormattedMessage id='LoadSummary.Q2' defaultMessage='Q2' />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className='dim pointer h3 flex items-center justify-center'
                onClick={() => handleSelectQuarter(3)}
              >
                <FormattedMessage id='LoadSummary.Q3' defaultMessage='Q3' />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className='dim pointer h3 flex items-center justify-center'
                onClick={() => handleSelectQuarter(4)}
              >
                <FormattedMessage id='LoadSummary.Q4' defaultMessage='Q4' />
              </div>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  )
}

export default LoadSummary
