import React, { Component } from 'react'
import Page from '../Page'
import TitleText from '../TitleText'
import Text from '../Text'
import { FormattedMessage } from 'react-intl'

const styles = {
  phoneAndEmail: 'teal f7 fw4 dim no-underline pointer'
}

class PageNotFound extends Component {
  render() {
    return (
      <Page title='Account Needs Support' className='tc'>
        <TitleText>
          <FormattedMessage
            id='PageNotFound.ThePageYouAreLookingForDoesNotExist'
            defaultMessage='The page you are looking for does not exist'
          />
        </TitleText>

        <div className='mt4 mw6 center pa4 ba b--black-10 bg-white'>
          <Text>
            <FormattedMessage
              id='PageNotFound.IfYouAreUnableToFineThePageYouAreLooking'
              defaultMessage='If you are unable to find the page you are looking for feel free to contact support at'
            />
            &nbsp;
            <span className={styles.phoneAndEmail}>1-866-620-6640</span>&nbsp;
            <FormattedMessage id='PageNotFound.OrAt' defaultMessage='or at' />
            &nbsp;
            <a
              className={styles.phoneAndEmail}
              href='mailto:support@tealbook.com'
            >
              support@tealbook.com
            </a>
            &nbsp;
            <FormattedMessage
              id='PageNotFound.'
              defaultMessage='and we will be able to help.'
            />
          </Text>
        </div>
      </Page>
    )
  }
}

export default PageNotFound
