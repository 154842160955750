import React from 'react'

type Props = {
  height?: number | string
  width?: number | string
}

const PlaceholderLine = (props: Props) => {
  const { height, width } = props
  return (
    <div
      className='br1 bg-light-gray loading'
      style={{
        height: height ? height : '1em',
        width: width ? width : '100%',
        borderRadius: 4
      }}
    ></div>
  )
}

export default PlaceholderLine
