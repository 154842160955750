import { call, select, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import users from 'shared/utils/api/users'
import reverseParsedUser from 'shared/utils/data/reverseParsedUser'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import { notify } from 'shared/actions'

//1 email: users.invite api calls email method and sends optional message

export function* addUserSaga(action) {
  const { user, message } = action.payload.toJS()
  const orgUnitId = yield select(sessionSelectors.getOrgUnitId)
  const hasSupplierRole = yield select(sessionSelectors.userHasRole, 'supplier')
  const colleagues = yield select(usersSelectors.getColleagues)

  let newUser
  if (!colleagues.some(colleague => user.email === colleague.get('email'))) {
    newUser = yield call(users.inviteUser, {
      user: reverseParsedUser(
        !hasSupplierRole ? Object.assign({}, user, { roles: ['buyer'] }) : user
      ),
      orgUnitId,
      message: message ? message : undefined
    })
  } else {
    yield put(
      notify({
        message: 'User already exists'
      })
    )
  }

  return yield {
    user: newUser
  }
}

export default createApiRequestSaga(addUserSaga)
