import { call, select } from 'redux-saga/effects'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import cards from 'shared/utils/api/cards'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* clearVoteResponseSaga(action) {
  const cardId = action.payload
  const userId = yield select(sessionSelectors.getUserId)
  yield call(cards.clearVoteResponse, cardId)

  return yield { cardId, userId }
}

export default createApiRequestSaga(clearVoteResponseSaga)
