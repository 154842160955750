import React from 'react'
import { Route, Switch } from 'react-router-dom'
import paths from './paths'
import VetsPage from '../components/VetsPage'
import VetHeaderContainer from '../containers/VetHeaderContainer'
import VetInvitationsPageContainer from '../containers/VetInvitationsPageContainer'
import VetInboxPageContainer from '../containers/VetInboxPageContainer'
import VetActivitiesContainer from '../containers/VetActivitiesContainer'
import NewVetContainer from '../containers/NewVetContainer'
import VetSearch from '../components/VetSearch'
import VetDetailsPage from '../components/VetDetailsPage'
import QandAPage from '../components/QandAPage/QandAPage'

const createRoutes = () => (
  <>
    <Switch>
      <Route path={paths.newVet} component={NewVetContainer} />
      <Route path={paths.basePath} exact component={VetsPage} />
      <Route path={paths.vet} component={VetHeaderContainer} />
    </Switch>
    <Switch>
      <Route path={paths.vetSearch} component={VetSearch} />
      <Route path={paths.vetDetails} component={VetDetailsPage} />
      <Route
        path={paths.vetInvitation}
        component={VetInvitationsPageContainer}
      />
      <Route path={paths.vetQA} component={QandAPage} />
      <Route path={paths.vetInbox} component={VetInboxPageContainer} />
      <Route path={paths.vetActivities} component={VetActivitiesContainer} />
    </Switch>
  </>
)

export default createRoutes
