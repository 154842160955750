import { Record, fromJS } from 'immutable'
import fullNameParser from 'shared/utils/data/fullName/parser'

import {
  SELECT_NEW_USER_FOR_CONTACT,
  SELECT_USER_FOR_CONTACT,
  ADD_CONTACT,
  CANCEL_ADDING_CONTACT
} from '../../actionTypes'
import { requestSuccess } from 'shared/actionTypes'

const NewContactRecord = Record({
  selectedUserId: '',
  newUser: fromJS({}),
  addSuccess: false
})

const newContactReducer = (state = new NewContactRecord(), action) => {
  switch (action.type) {
    case SELECT_USER_FOR_CONTACT:
      return state.set('selectedUserId', action.payload.get('id'))

    case SELECT_NEW_USER_FOR_CONTACT:
      const { ...userInfo } = fullNameParser(action.payload)

      return state.set('newUser', fromJS({ ...userInfo }))

    case CANCEL_ADDING_CONTACT:
      return new NewContactRecord()

    case requestSuccess(ADD_CONTACT):
      return state
        .set('addSuccess', true)
        .set('selectedUserId', action.payload.userId)

    default:
      return state
  }
}

export default newContactReducer
