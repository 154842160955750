import React, { Component } from 'react'
import { List, RecordOf } from 'immutable'
import ListItem from '../List/ListItem'
import vetsPaths from 'buyer/Vets/routes/paths'
import dataFormat from 'shared/utils/dateFormat'
import Link from 'shared/components/Link'
import paths from '../../../buyer/routes/paths'
import Avatar from 'shared/components/Avatar'
import withUser from 'shared/utils/withUser'
import Tooltip from 'shared/components/Tooltip'
import { injectIntl, IntlShape } from 'react-intl'
import vetStatusMessages from 'i18n/defaultMessages/VetStatusMessages'
import camelCase from 'lodash.camelcase'

type OwnerProps = {
  firstName: string
  lastName: string
  profilePictureUrl: string
  userId: string
}

const Owner = withUser((props: OwnerProps) => {
  return (
    <Tooltip title={`${props.firstName} ${props.lastName}`}>
      <Link to={paths.userprofile} params={{ userId: props.userId }}>
        <Avatar
          url={props.profilePictureUrl}
          className='w-100'
          name={`${props.firstName || ''} ${props.lastName || ''}`}
        />
      </Link>
    </Tooltip>
  )
})

type Props = {
  intl: IntlShape
  vets: List<
    RecordOf<{
      date: string
      id?: string
      lead?: string
      name: string
      status: string
    }>
  >
}

export class VetsList extends Component<Props> {
  getColorForStatus(status) {
    switch (status) {
      case 'chosen':
        return 'dark-green'

      case 'notChosen':
        return 'dark-red'

      default:
        return 'gray'
    }
  }

  renderListItem() {
    const { vets } = this.props
    return vets.map(vet => {
      const leadUserId = vet.get('lead')
      return (
        <ListItem
          key={vet.get('id')}
          leftAvatar={leadUserId && <Owner userId={leadUserId} />}
          primaryText={
            vet.get('id') ? (
              <Link
                to={vetsPaths.vetDetails}
                params={{ vetId: vet.get('id') || '' }}
              >
                <div className='f7 fw6 mid-gray ma0'>{vet.get('name')}</div>
              </Link>
            ) : (
              <div className='f7 fw6 gray ma0'>{vet.get('name')}</div>
            )
          }
          secondaryText={
            <div className='flex justify-between'>
              <span
                className={`f8 fw6 mr2 ${this.getColorForStatus(
                  vet.get('status')
                )}`}
              >
                {vetStatusMessages[camelCase(vet.get('status'))]
                  ? this.props.intl.formatMessage(
                      vetStatusMessages[camelCase(vet.get('status'))]
                    )
                  : camelCase(vet.get('status'))}
              </span>
              <span className='f8 fw4 gray'>{dataFormat(vet.get('date'))}</span>
            </div>
          }
          mini
        />
      )
    })
  }

  render() {
    const { vets } = this.props

    return vets.size ? <div>{this.renderListItem()}</div> : null
  }
}

export default injectIntl(VetsList)
