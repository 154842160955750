import startCase from 'lodash.startcase'

const ValidNames = ['name', 'description', 'offerings']

export type SearchStringObject = {
  includes?: Array<string>
  excludes?: Array<string>
  includesName?: Array<string>
  excludesName?: Array<string>
  includesDescription?: Array<string>
  excludesDescription?: Array<string>
  includesOfferings?: Array<string>
  excludesOfferings?: Array<string>
}

export type OptionStrings = {
  includes: string
  excludes: string
  includesName: string
  excludesName: string
  includesDescription: string
  excludesDescription: string
  includesOfferings: string
  excludesOfferings: string
}

export const getTerms = (queryStr: string = '') => {
  let openQuote = false
  let parsed: any = []
  let text = ''
  for (let idx = 0; idx < queryStr.length; idx++) {
    let ch = queryStr[idx]
    if (ch === '"' && !openQuote && text.length === 0) {
      openQuote = true
      text += ch
    } else if (ch === '"' && openQuote) {
      openQuote = false
      text += ch
      if (text.length > 0) parsed.push(text.trim())
      text = ''
    } else if (ch === ' ' && !openQuote) {
      if (text.length > 0) parsed.push(text.trim())
      text = ''
    } else if (
      ch === '"' &&
      !openQuote &&
      ((text.length === 1 && queryStr[idx - 1] === '-') ||
        (text.length > 1 &&
          queryStr[idx - 1] === ':' &&
          (ValidNames.includes(text.substring(0, text.length - 1)) ||
            (text[0] === '-' &&
              ValidNames.includes(text.substring(1, text.length - 1))))))
    ) {
      openQuote = true
      text += ch
    } else text += ch
  }

  if (text.length > 0) parsed.push(text.trim())

  return parsed
}

export const stringToObject: (s: string) => SearchStringObject = (
  searchString: string
) => {
  const terms = getTerms(searchString)
  const searchObj = terms.reduce(
    (searchObj: SearchStringObject, term: string) => {
      const isExcludes = term[0] === '-'
      const isAttribute = term.includes(':')
      const parseTerm = term.split(':')
      const option = isAttribute
        ? isExcludes
          ? parseTerm[0].substr(1)
          : parseTerm[0]
        : ''
      const text = isAttribute
        ? parseTerm[1]
        : isExcludes
        ? parseTerm[0].substr(1)
        : parseTerm[0]

      const prop = isExcludes
        ? `excludes${startCase(option)}`
        : `includes${startCase(option)}`

      searchObj[prop] = searchObj[prop] ? [...searchObj[prop], text] : [text]
      return searchObj
    },
    {}
  )

  return searchObj
}

export const qStringToOptionStrings = (qString: string) => {
  const searchOptionsObj = stringToObject(qString)

  return {
    includes: searchOptionsObj?.includes
      ? searchOptionsObj.includes?.join(' ')
      : '',
    excludes: searchOptionsObj?.excludes
      ? searchOptionsObj.excludes?.join(' ')
      : '',
    includesName: searchOptionsObj?.includesName
      ? searchOptionsObj.includesName?.join(' ')
      : '',
    excludesName: searchOptionsObj?.excludesName
      ? searchOptionsObj.excludesName?.join(' ')
      : '',
    includesDescription: searchOptionsObj?.includesDescription
      ? searchOptionsObj.includesDescription?.join(' ')
      : '',
    excludesDescription: searchOptionsObj?.excludesDescription
      ? searchOptionsObj.excludesDescription?.join(' ')
      : '',
    includesOfferings: searchOptionsObj?.includesOfferings
      ? searchOptionsObj.includesOfferings?.join(' ')
      : '',
    excludesOfferings: searchOptionsObj?.excludesOfferings
      ? searchOptionsObj.excludesOfferings?.join(' ')
      : ''
  }
}

export const optionStringsToQString = (optionStrings: OptionStrings) => {
  let qString = ''
  for (const option in optionStrings) {
    const terms = getTerms(optionStrings[option] || '')
    switch (option) {
      case 'includes':
        qString += !!terms?.length ? `${terms.join(' ')} ` : ''
        break
      case 'excludes':
        qString += !!terms?.length
          ? `${terms?.map(o => `-${o}`).join(' ')} `
          : ''
        break
      case 'includesName':
        qString += !!terms?.length
          ? `${terms?.map(o => `name:${o}`).join(' ')} `
          : ''
        break
      case 'excludesName':
        qString += !!terms?.length
          ? `${terms?.map(o => `-name:${o}`).join(' ')} `
          : ''
        break
      case 'includesDescription':
        qString += !!terms?.length
          ? `${terms?.map(o => `description:${o}`).join(' ')} `
          : ''
        break
      case 'excludesDescription':
        qString += !!terms?.length
          ? `${terms?.map(o => `-description:${o}`).join(' ')} `
          : ''
        break
      case 'includesOfferings':
        qString += !!terms?.length
          ? `${terms?.map(o => `offerings:${o}`).join(' ')} `
          : ''
        break
      case 'excludesOfferings':
        qString += !!terms?.length
          ? `${terms?.map(o => `-offerings:${o}`).join(' ')} `
          : ''
        break
    }
  }
  return qString.trim()
}
