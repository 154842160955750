import React, { FC, ReactNode } from 'react'
import Paper from '@material-ui/core/Paper'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 1),
      boxShadow: 'none',
      border: 'solid 1px rgba(0, 0, 0, 0.1)'
    }
  })
)

type Props = {
  topLine?: ReactNode
  bottomLine?: ReactNode
  value: number | string
}

const QuickFactBox: FC<Props> = ({ topLine, bottomLine, value }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      {topLine && <div className='lh-copy fw4 mid-gray ma0 f9'>{topLine}</div>}
      <div className='tc f4 fw4 teal'>
        {typeof value === 'number' ? value.toLocaleString() : value}
      </div>
      {bottomLine && (
        <div className='lh-copy fw4 mid-gray ma0 f9 tr'>{bottomLine}</div>
      )}
    </Paper>
  )
}

export default QuickFactBox
