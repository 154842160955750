import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import ExternalLink from 'shared/components/ExternalLink'
import Label from 'shared/components/Label'

const BuyerRequestAccess = () => {
  return (
    <div className='flex flex-column items-center pb5'>
      <Label className='ns-mb5 mb4'>
        <FormattedMessage
          id='BuyerRequestAccess.EncourageEcovadisSignUp'
          defaultMessage='You do not have an EcoVadis Integration Account'
        />
      </Label>
      <ExternalLink href={'https://invite.ecovadis.com/en/tealbook'}>
        <Button
          label={
            <FormattedMessage
              id='BuyerRequestAccess.requestEcovadisAccount'
              defaultMessage='Request EcoVadis Account Access'
            />
          }
        />
      </ExternalLink>
    </div>
  )
}

export default BuyerRequestAccess
