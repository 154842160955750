import React from 'react'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import paths from '../../../routes/paths'
import { Link } from 'react-router-dom'

type Props = {
  inProgress?: boolean
}

const ParticipateSurvey = (props: Props) => {
  return (
    <>
      <Text className='mt3-5 mb3'>
        <FormattedMessage
          id='ParticipateSurvey.Text1'
          defaultMessage={`You're invited to participate in our sustainability survey.`}
        />
      </Text>
      <Text className='mb3'>
        <FormattedMessage
          id='ParticipateSurvey.Text2'
          defaultMessage={`TealBook has launched a sustainability survey to capture information about your company's Environment, Social, Governance (ESG) efforts. This survey is an opportunity for you to share your organization's sustainability efforts with potential buyers, and the results will be publicly available to buyers on the TealBook platform. By taking just a few minutes to complete this survey, you could attract more business from sustainability-minded companies. Click the button below to get started.`}
        />
      </Text>
      <div
        className={`flex items-center ${
          props.inProgress ? 'justify-between' : 'justify-end'
        }`}
      >
        {props.inProgress && (
          <Text className='fw6'>
            <FormattedMessage
              id='ParticipateSurvey.InProgress'
              defaultMessage={`This survey is in progress.`}
            />
          </Text>
        )}
        <Button component={Link} to={paths.esgSurvey}>
          {!!props.inProgress ? (
            <FormattedMessage
              id='ParticipateSurvey.ContinueSurvey'
              defaultMessage={`Continue the Survey Now`}
            />
          ) : (
            <FormattedMessage
              id='ParticipateSurvey.CompleteSurvey'
              defaultMessage={`Complete the Survey Now`}
            />
          )}
        </Button>
      </div>
    </>
  )
}

export default ParticipateSurvey
