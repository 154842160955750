import React, { useMemo } from 'react'
import { notify } from 'shared/actions'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import storageManager from 'shared/utils/storageManager'
import collectionSelectors from '../../Search/selectors/collectionSelectors'
import { Map, RecordOf } from 'immutable'
import { ButtonProps } from '@material-ui/core'
import { connect } from 'react-redux'
import partner from 'shared/utils/api/partner'

type Props = {
  collection: Map<
    string,
    RecordOf<{
      id: string
      name: string
      logoUrl: string
      domain: string
      relatedCompany: boolean
    }>
  >
  className?: string
  autoSize?: boolean
  notify: typeof notify
} & Pick<ButtonProps, 'size'>

const SendPunchoutSuppliersButton = (props: Props) => {
  const punchoutPayload = useMemo(() => storageManager.getItem('punchout'), [])

  const { collection, ...rest } = props

  if (!punchoutPayload) {
    return null
  }

  return (
    <Button
      {...rest}
      onClick={() => {
        const cart =
          collection &&
          collection
            .toList()
            .toJS()
            .map(({ id, name, domain }) => ({
              id,
              name,
              domain
            }))

        partner
          .sendPunchoutCart({
            punchoutPayload,
            cart
          })
          .catch((error: Error) => {
            props.notify(error.message)
          })
      }}
    >
      <FormattedMessage
        id='CartContainer.SendSuppliers'
        defaultMessage='Send Suppliers'
      />
    </Button>
  )
}

export default connect(
  state => ({
    collection: collectionSelectors.getCollection(state)
  }),
  {
    notify
  }
)(SendPunchoutSuppliersButton)
