import { call, select } from 'redux-saga/effects'
import createApiRequestSaga from '../../utils/sagas/createApiRequestSaga'
import api from '../../utils/api'
import apiRoutes from '../../routes/apiRoutes'
import { fromJS } from 'immutable'
import sessionSelectors from 'shared/selectors/sessionSelectors'

export function* loadOrgUnitClientFilesSaga(action) {
  const { entity, query } = action.payload
  const orgUnitId = yield select(sessionSelectors.getOrgUnitId)
  const orgUnitClientFiles = yield call(
    api.get,
    `${apiRoutes.loader}/${query}/${orgUnitId}`
  )
  return yield {
    entity,
    data: fromJS({
      [entity]: orgUnitClientFiles
    })
  }
}

export default createApiRequestSaga(loadOrgUnitClientFilesSaga)
