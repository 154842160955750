import React from 'react'
import { FormattedMessage } from 'react-intl'
import ShowQuestionResponse from './ShowQuestionResponse'
import ScrollToPosition from 'shared/components/ScrollToPosition'

const pageId = 'privacy'

const Privacy = () => {
  return (
    <div>
      <ScrollToPosition />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='complyGDPR'
        question={<FormattedMessage id='Privacy.complyGDPR' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='designatedPrivacy'
        question={<FormattedMessage id='Privacy.designatedPrivacy' />}
      >
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='designatedPrivacy'
          questionId='jobTitle'
          question={<FormattedMessage id='Privacy.jobTitle' />}
          longAnswer
          childAnswer
        />
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='designatedPrivacy'
          questionId='jobTitleReportTo'
          question={<FormattedMessage id='Privacy.jobTitleReportTo' />}
          longAnswer
          childAnswer
        />
      </ShowQuestionResponse>
      <ShowQuestionResponse
        pageId={pageId}
        questionId='note'
        question={<FormattedMessage id='optionalAdditionalNote' />}
        longAnswer
      />
    </div>
  )
}

export default Privacy
