import React from 'react'
import AppBarContainer from 'shared/containers/AppBarContainer'
import BuyerNavSearch from '../../containers/BuyerNavSearch'
import NavBar from 'shared/components/NavBar'
import NavLink, { iconLabelStyle } from 'shared/components/NavBar/NavLink'
import UserNavContainer from 'shared/containers/UserNavContainer'
import paths from '../../../routes/paths'
import MessagesNavLinkContainer from 'shared/containers/MessagesNavLinkContainer'
import TealboardNavLinkContainer from 'shared/containers/TealboardNavLinkContainer'
import CartContainer from '../../containers/CartContainer'
import Hidden from '@material-ui/core/Hidden'
import createIsActiveFunc from 'shared/utils/createIsActiveFunc'
import { defineMessages, useIntl } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'
import PeopleIcon from 'shared/assets/icons/contacts.svg'
import parsePath from 'shared/utils/parsePath'
import { AccessControl } from 'shared/models/AccessControl'
import useUserAccess from 'shared/utils/useUserAccess'
import { LicenseAccess } from 'buyer/shared/reducers/settingsReducer'
import ExternalLink from 'shared/components/ExternalLink'
import HelpIcon from 'shared/assets/icons/doubt.svg'
import externalPaths from 'shared/routes/externalPaths'
import { makeStyles } from '@material-ui/core'

const isSuppliersActive = createIsActiveFunc(paths.search)
const isVetActive = createIsActiveFunc(paths.vets)
const isPeopleActive = createIsActiveFunc(paths.people)

const messages = defineMessages({
  suppliers: {
    id: 'BuyerAppBar.suppliers',
    defaultMessage: 'Suppliers'
  },
  suppliersTip: {
    id: 'BuyerAppBar.suppliersTip',
    defaultMessage: 'Search for suppliers'
  },
  insight: {
    id: 'BuyerAppBar.insight',
    defaultMessage: 'Insight'
  },
  insightTip: {
    id: 'BuyerAppBar.insightTip',
    defaultMessage: 'View information and statistics about your company'
  },
  communities: {
    id: 'BuyerAppBar.communities',
    defaultMessage: 'Communities'
  },
  communitiesTip: {
    id: 'BuyerAppBar.communitiesTip',
    defaultMessage: 'Manage your communities of suppliers'
  },
  contacts: {
    id: 'BuyerAppBar.People',
    defaultMessage: 'Contacts'
  },
  contactsTip: {
    id: 'BuyerAppBar.PeopleTip',
    defaultMessage: 'Find or manage your contacts'
  },
  vetTip: {
    id: 'BuyerAppBar.vetTip',
    defaultMessage: 'Review or create lists of suppliers and simple RFIs'
  },
  rfi: {
    id: 'BuyerAppBar.rfi',
    defaultMessage: 'RFI'
  },
  knowledgeBase: {
    id: 'BuyerAppBar.knowledgeBase',
    defaultMessage: 'Knowledge Base'
  },
  knowledgeBaseTip: {
    id: 'BuyerAppBar.knowledgeBaseTip',
    defaultMessage: 'Visit our Knowledge Base'
  }
})

type Props = {
  pathname: string
  showSearchBar?: boolean
  queryString?: string
  accessControl: AccessControl
  peopleQueryString?: string
}
const useStyles = makeStyles({
  icon: iconLabelStyle.icon,
  contents: { display: 'contents' }
})

/**
 * This is a AppBar with 2 navLinks and a userNav containing a MenuItem linking to the supplier app in it<br/>
 * The 2 navLinks are Tealboard, and Vets
 */
export const BuyerAppBar = (props: Props) => {
  const {
    showSearchBar,
    queryString,
    pathname,
    peopleQueryString,
    accessControl
  } = props

  const intl = useIntl()
  const userAccess = useUserAccess() as LicenseAccess
  const classes = useStyles()

  return (
    <AppBarContainer
      userNav={accessControl.access === 'full' && <UserNavContainer />}
      homeUrl={paths.basePath}
      pathname={pathname}
      hideMenuNav={accessControl.access === 'punchout'}
    >
      <Hidden smDown>
        <div className='flex-auto'>{showSearchBar && <BuyerNavSearch />}</div>

        {accessControl.access === 'full' && (
          <NavBar className={classes.contents}>
            <Tooltip title={intl.formatMessage(messages.suppliersTip)}>
              <NavLink
                to={`${paths.search}${queryString}`}
                isActive={isSuppliersActive}
                label={intl.formatMessage(messages.suppliers)}
              />
            </Tooltip>

            {userAccess.accessRFI && (
              <Tooltip title={intl.formatMessage(messages.vetTip)}>
                <NavLink
                  to={paths.vets}
                  isActive={isVetActive}
                  label={intl.formatMessage(messages.rfi)}
                />
              </Tooltip>
            )}

            {userAccess.accessInsights && (
              <Tooltip title={intl.formatMessage(messages.insightTip)}>
                <NavLink
                  to={paths.insight}
                  label={intl.formatMessage(messages.insight)}
                />
              </Tooltip>
            )}

            {userAccess.accessNews && (
              <TealboardNavLinkContainer to={paths.tealboard} />
            )}

            {userAccess.accessCommunities && (
              <Tooltip title={intl.formatMessage(messages.communitiesTip)}>
                <NavLink
                  to={paths.communities}
                  label={intl.formatMessage(messages.communities)}
                />
              </Tooltip>
            )}

            {userAccess.accessContacts && (
              <Tooltip title={intl.formatMessage(messages.contactsTip)}>
                <NavLink
                  to={`${paths.people}${peopleQueryString}`}
                  iconSrc={PeopleIcon}
                  isActive={isPeopleActive}
                  label={intl.formatMessage(messages.contacts)}
                />
              </Tooltip>
            )}

            {userAccess.accessChat && (
              <MessagesNavLinkContainer
                to={parsePath(paths.messages, { messageTopicId: '' })}
              />
            )}
            <li>
              <Tooltip title={intl.formatMessage(messages.knowledgeBaseTip)}>
                <div>
                  <ExternalLink
                    href={externalPaths.knowledgeBaseLink}
                    className={`no-underline f7 mr3 mr4-l' + ' ${classes.icon}`}
                    title={intl.formatMessage(messages.knowledgeBaseTip)}
                  >
                    <img
                      src={HelpIcon}
                      alt={intl.formatMessage(messages.knowledgeBase)}
                      className='v-mid'
                      style={{ width: 20, height: 20 }}
                    />
                  </ExternalLink>
                </div>
              </Tooltip>
            </li>
          </NavBar>
        )}
        <CartContainer />
      </Hidden>
    </AppBarContainer>
  )
}

export default BuyerAppBar
