import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '../DialogActions'
import DialogTitle from '../DialogTitle'
import Button from '../Button'
import Text from '../Text'
import { FormattedMessage } from 'react-intl'
import ExternalLink from 'shared/components/ExternalLink'

type Props = {
  onSync: () => void
  onDontSync: () => void
}

type State = {
  open: boolean
}

class SyncContactsDialog extends Component<Props, State> {
  state: State = {
    open: true
  }

  toggleDialog = () => {
    this.setState({
      open: !this.state.open
    })
  }

  handleClickSync = () => {
    this.props.onSync()
    this.toggleDialog()
  }

  handleClickDontSync = () => {
    this.props.onDontSync()
    this.toggleDialog()
  }

  render() {
    const { open } = this.state

    return (
      <Dialog open={open}>
        <DialogTitle>
          <FormattedMessage
            id='SyncContactsDialog.UploadAndSyncContacts'
            defaultMessage='Upload and Sync Contacts'
          />
        </DialogTitle>
        <DialogContent>
          <Text className='mt3'>
            <FormattedMessage
              id='SyncContactsDialog.Message1'
              defaultMessage='Would you like to upload and synchronize your contacts to TealBook? If you agree by clicking below, we add them as contacts for you in TealBook and connect you to companies they work for.'
            />
          </Text>
          <Text className='mt3'>
            <FormattedMessage
              id='SyncContactsDialog.Message2'
              defaultMessage='We take your privacy seriously. TealBook will not share your contact information with anyone or reach out to your contacts without your permission. You can review our complete <link>privacy policy</link> from the application menu at anytime.'
              values={{
                link: str => (
                  <ExternalLink
                    className='teal f7 fw6 dim no-underline pointer'
                    href={`https://tealbook.com/privacy/`}
                  >
                    {str}
                  </ExternalLink>
                )
              }}
            />
          </Text>
          <Text className='mt3'>
            <FormattedMessage
              id='SyncContactsDialog.Message3'
              defaultMessage='Click the Agree button to give us permission to load your contacts or click the Skip button to continue using TealBook without loading them.'
            />
          </Text>
        </DialogContent>

        <DialogActions>
          <Button
            label={
              <FormattedMessage
                id='SyncContactsDialog.Agree'
                defaultMessage='Agree'
              />
            }
            autoSize
            onClick={this.handleClickSync}
          />
          <Button
            label={
              <FormattedMessage
                id='SyncContactsDialog.Skip'
                defaultMessage='Skip'
              />
            }
            autoSize
            secondary
            onClick={this.handleClickDontSync}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export default SyncContactsDialog
