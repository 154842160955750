import { put, select } from 'redux-saga/effects'
import searchSelectors from '../../selectors/searchSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import { push } from 'connected-react-router'
import qs from 'qs'
import { List } from 'immutable'
import mergeDeepOverwriteLists from 'shared/utils/mergeDeepOverwriteLists'
import CertificationCategories from 'shared/models/CertificationCategories'

const availableCertifications = Object.keys(CertificationCategories)

const isMergeable = a =>
  a &&
  typeof a === 'object' &&
  typeof a.mergeWith === 'function' &&
  !List.isList(a)

export default function* updateQueryStringSaga(action) {
  const q = yield select(searchSelectors.getQ)
  const fixedFilters = yield select(settingsSelectors.getFixedFilters)
  const filter = yield select(filterOptionsSelectors.getFilterOptions)
  const scope = yield select(filterOptionsSelectors.getFilterScope)
  const sort = yield select(filterOptionsSelectors.getSort)
  const pathname = yield select(routingSelectors.getPathname)
  const attachments = filter.get('attachments')
  let certificationExpiredExcluded = yield select(
    filterOptionsSelectors.getByKey,
    'certificationExpiredExcluded'
  )
  const attachmentsExpiredExcluded = yield select(
    filterOptionsSelectors.getByKey,
    'attachmentsExpiredExcluded'
  )
  const similarSuppliers = yield select(
    filterOptionsSelectors.getByKey,
    'similarSuppliers'
  )
  const isSupplier = !pathname.includes('client')

  certificationExpiredExcluded = certificationExpiredExcluded || List([])

  // Include pill = Include Potential and Expired pill
  // get available certifications that haven't been filtered
  const notFilteredQuals = availableCertifications.filter(
    cert => filter && !filter.get(cert)
  )
  // if a qualification isn't being filtered, then Include isn't filtered either
  notFilteredQuals.forEach(qual => {
    if (!certificationExpiredExcluded.includes(qual)) {
      certificationExpiredExcluded = certificationExpiredExcluded.push(qual)
    }
  })
  // get Include pill selected by Remove action
  const removeIncludePotentailExpiredPill =
    action.type === 'REMOVE_FILTER_OPTION' &&
    action.payload.key === 'certificationExpiredExcluded'
      ? action.payload.value
      : undefined

  // add cert back to certificationExpiredExcluded filter if Include pill removed
  if (
    removeIncludePotentailExpiredPill &&
    !certificationExpiredExcluded.includes(removeIncludePotentailExpiredPill)
  ) {
    certificationExpiredExcluded = certificationExpiredExcluded.push(
      removeIncludePotentailExpiredPill
    )
  }

  const queryString = {
    q,
    filter:
      isSupplier && isMergeable(filter) && isMergeable(fixedFilters)
        ? mergeDeepOverwriteLists(filter, fixedFilters).toJS()
        : filter.toJS(),
    sort: sort === 'Similarity' && !similarSuppliers ? 'Relevance' : sort,
    scope,
    certificationExpiredExcluded:
      certificationExpiredExcluded.size >= 0 &&
      certificationExpiredExcluded.toJS(),
    attachmentsExpiredExcluded:
      attachments && attachments.size > 0 && attachmentsExpiredExcluded
        ? true
        : undefined,
    similarSuppliers:
      similarSuppliers && similarSuppliers.size > 0
        ? similarSuppliers && similarSuppliers.toJS()
        : undefined
  }
  yield put(push(`${pathname}?${qs.stringify(queryString)}`))
}
