// these agencies don't get translated
// because they exist in a single language

const divesityAgencies: Array<{
  value: string
  subCategory: string
}> = [
  {
    value: `Women‘s Business Enterprise National Council (WBENC)`,
    subCategory: 'wbe'
  },
  {
    value: `National Minority Supplier Development Council (NMSDC)`,
    subCategory: 'mbe'
  },
  {
    value: `National LGBT Chamber of Commerce (NGLCC)`,
    subCategory: 'lgbtbe'
  },
  {
    value: `National Veteran Business Development Council (NVBDC)`,
    subCategory: 'vbe'
  },
  {
    value: `Disability:IN (USBLN)`,
    subCategory: 'dobe'
  },
  {
    value: `WeConnect International`,
    subCategory: 'wbe'
  },
  {
    value: `Réseau des Femmes d'affaires du Québec (RFAQ)`,
    subCategory: 'wbe'
  },
  {
    value: `Canadian Council for Aboriginal Business (CCAB))`,
    subCategory: 'cab'
  },
  {
    value: `Crown-Indigenous Relations and Northern Affairs Canada (CIRNAC)`,
    subCategory: 'cab'
  }
]

export default divesityAgencies
