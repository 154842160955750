import * as actionTypes from './shared/actionTypes'
import { takeLatest, takeEvery, all, fork, call } from 'redux-saga/effects'
import takeFirst from 'shared/utils/sagas/takeFirst'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import suggestSupplierSaga from './shared/sagas/suggestSupplierSaga'
import connectToSupplierSaga from './shared/sagas/connectToSupplierSaga'
import unfollowSupplierSaga from './shared/sagas/unfollowSupplierSaga'
import {
  LOAD_INSIGHTS,
  LOAD_SPEND,
  LOAD_SPEND_DETAIL,
  GET_SPEND_YEARS,
  GENERATE_DIVERSITY_REPORT,
  LOAD_CONSOLIDATION,
  GET_TIER2_AGENCIES,
  GET_TIER2_RANGE,
  LOAD_TIER2_DATA,
  LOAD_TIER2_SUPPLIER_LIST,
  SEARCH_TIER2_SUPPLIERS,
  CREATE_TIER2_COMMUNITY,
  INVITE_TIER2_GROUP,
  EXPORT_TIER2_SUPPLIER_TABLE,
  GET_SNAPSHOT_LIST,
  SAVE_SNAPSHOT,
  GET_SNAPSHOT,
  GET_SNAPSHOT_LINE_ITEMS,
  BULK_DELETE_SNAPSHOTS,
  LOAD_SUSTAINABILITY_REPORT_OVERVIEW,
  GET_ESG_REPORT_PAGE,
  GET_BULK_ESG_SUPPLIERS
} from './Insights/store/actionTypes'
import vetRootSaga from './Vets/sagas/rootSaga'
import searchRootSaga from './Search/sagas/rootSaga'
import communitiesRootSaga from './Communities/sagas/rootSaga'
import getOrgSettingsSaga from './shared/sagas/getOrgSettingsSaga'
import searchContactsSaga from './shared/sagas/searchContactsSaga'
import supplierProfileRootSaga from './SupplierProfile/sagas/rootSaga'
import loadInsightsSaga from './Insights/store/loadInsightsSaga'
import loadSpendSaga from './Insights/store/loadSpendSaga'
import loadSpendPreviewSaga from './Insights/store/loadSpendPreviewSaga'
import getSpendYearsSaga from './Insights/store/getSpendYearsSaga'
import searchSimilarSuppliers from './shared/sagas/searchSimilarSuppliersSaga'
import contactsSagas from './Contacts/rootSaga'
import insight from 'shared/utils/api/insight'
import tier2 from 'shared/utils/api/tier2'
import survey from 'shared/utils/api/survey'
import loadTier2DataSaga from './Insights/store/sagas/loadTier2DataSaga'
import searchTier2SuppliersSaga from './Insights/store/sagas/searchTier2SuppliersSaga'
import createTier2CommunitySaga from './Insights/store/sagas/createTier2CommunitySaga'
import inviteTier2GroupSaga from './Insights/store/sagas/inviteTier2GroupSaga'
import exportTier2SupplierTableSaga from './Insights/store/sagas/exportTier2SupplierTableSaga'
import saveSnapshotSaga from './Insights/store/saveSnapshotSaga'
import loadSnapshotSaga from './Insights/store/loadSnapshotSaga'
import deleteSnapshotsSaga from './Insights/store/deleteSnapshotsSaga'

export default function* rootSaga() {
  yield fork(vetRootSaga)
  yield fork(searchRootSaga)
  yield fork(supplierProfileRootSaga)
  yield fork(communitiesRootSaga)
  yield fork(contactsSagas)
  yield all([
    takeLatest(
      [actionTypes.SEARCH_CONTACTS, actionTypes.SEARCH_LOCATIONS],
      searchContactsSaga
    ),
    takeFirst(actionTypes.UNFOLLOW_SUPPLIER, unfollowSupplierSaga),
    takeFirst(actionTypes.CONNECT_TO_SUPPLIER, connectToSupplierSaga),
    takeEvery(actionTypes.SUGGEST_SUPPLIER, suggestSupplierSaga),
    takeLatest(actionTypes.GET_SETTINGS, getOrgSettingsSaga),
    takeFirst(LOAD_INSIGHTS, loadInsightsSaga),
    takeLatest(LOAD_SPEND, loadSpendSaga),
    takeLatest(LOAD_SPEND_DETAIL, loadSpendPreviewSaga),
    takeFirst(GET_SPEND_YEARS, getSpendYearsSaga),
    takeFirst(actionTypes.SEARCH_SIMILAR_SUPPLIERS, searchSimilarSuppliers),
    takeFirst(
      LOAD_CONSOLIDATION,
      createApiRequestSaga(insight.getConsolidationData, {
        usePayload: true
      })
    ),
    takeLatest(
      GENERATE_DIVERSITY_REPORT,
      createApiRequestSaga(insight.getDiverseReport, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(GET_TIER2_RANGE, createApiRequestSaga(tier2.getTier2Range)),
    takeFirst(GET_TIER2_AGENCIES, createApiRequestSaga(tier2.getTier2Agencies)),
    takeLatest(LOAD_TIER2_DATA, loadTier2DataSaga),
    takeLatest(
      LOAD_TIER2_SUPPLIER_LIST,
      createApiRequestSaga(tier2.tier2SupplierList)
    ),
    takeLatest(SEARCH_TIER2_SUPPLIERS, searchTier2SuppliersSaga),
    takeLatest(CREATE_TIER2_COMMUNITY, createTier2CommunitySaga),
    takeFirst(INVITE_TIER2_GROUP, inviteTier2GroupSaga),
    takeFirst(EXPORT_TIER2_SUPPLIER_TABLE, exportTier2SupplierTableSaga),
    takeFirst(GET_SNAPSHOT_LIST, createApiRequestSaga(insight.getSnapshots)),
    takeFirst(
      GET_SNAPSHOT_LINE_ITEMS,
      createApiRequestSaga(function*(action) {
        return yield call(insight.getSnapshotLineItems, action.payload)
      })
    ),
    takeFirst(BULK_DELETE_SNAPSHOTS, deleteSnapshotsSaga),
    takeEvery(GET_SNAPSHOT, loadSnapshotSaga),
    takeFirst(SAVE_SNAPSHOT, saveSnapshotSaga),
    takeFirst(
      LOAD_SUSTAINABILITY_REPORT_OVERVIEW,
      createApiRequestSaga(survey.loadSustainabilityReportOverview)
    ),
    takeLatest(
      GET_ESG_REPORT_PAGE,
      createApiRequestSaga(survey.getSustainabilityReportSummaryDetail, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(
      GET_BULK_ESG_SUPPLIERS,
      createApiRequestSaga(survey.getIncompleteEgsExistingSuppliers, {
        usePayload: true,
        notifyOnError: true
      })
    )
  ])
}
