import React, { Component } from 'react'
import AppBarContainer from 'shared/containers/AppBarContainer'
import NavLink, { iconLabelStyle } from 'shared/components/NavBar/NavLink'
import UserNavContainer from 'shared/containers/UserNavContainer'
import MessagesNavLinkContainer from 'shared/containers/MessagesNavLinkContainer'
import TealboardNavLinkContainer from 'shared/containers/TealboardNavLinkContainer'
import VetNavLinkContainer from 'shared/containers/VetNavLinkContainer'
import paths from '../../../routes/paths'
import Hidden from '@material-ui/core/Hidden'
import { injectIntl, defineMessages, IntlShape } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'
import createIsActiveFunc from 'shared/utils/createIsActiveFunc'
import DiversityNavLinkContainer from '../../containers/DiversityNavLinkContainer'
import ExternalLink from 'shared/components/ExternalLink'
import HelpIcon from 'shared/assets/icons/doubt.svg'
import externalPaths from 'shared/routes/externalPaths'
import NavBar from 'shared/components/NavBar'
import { createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const isSuppliersActive = createIsActiveFunc(paths.search)

const messages = defineMessages({
  company: {
    id: 'SupplierAppBar.Company',
    defaultMessage: 'Company'
  },
  companyTip: {
    id: 'SupplierAppBar.CompanyTip',
    defaultMessage: "View and update your company's profile"
  },
  insight: {
    id: 'SupplierAppBar.Insight',
    defaultMessage: 'Insight'
  },
  insightTip: {
    id: 'SupplierAppBar.InsightTip',
    defaultMessage: 'Review information and statistics about your company'
  },
  communities: {
    id: 'SupplierAppBar.Communities',
    defaultMessage: 'Communities'
  },
  communitiesTip: {
    id: 'SupplierAppBar.CommunitiesTip',
    defaultMessage: 'Join communities to indicate your interests'
  },
  suppliers: {
    id: 'SupplierAppBar.Suppliers',
    defaultMessage: 'Suppliers'
  },
  suppliersTip: {
    id: 'SupplierAppBar.SuppliersTip',
    defaultMessage: 'Search suppliers within your communities'
  },
  diversity: {
    id: 'SupplierAppBar.diversity',
    defaultMessage: 'Diversity'
  },
  diversityTip: {
    id: 'SupplierAppBar.diversityTip',
    defaultMessage: 'Review your Tier 2 information'
  },
  knowledgeBase: {
    id: 'SupplierAppBar.knowledgeBase',
    defaultMessage: 'Knowledge Base'
  },
  knowledgeBaseTip: {
    id: 'SupplierAppBar.knowledgeBaseTip',
    defaultMessage: 'Visit our Knowledge Base'
  }
})

type Props = {
  showInsightTab: boolean
  showSuppliersTab: boolean
  queryString: string
  pathname: string
  intl: IntlShape
  showDiversityTab: boolean
} & WithStyles<typeof styles>

const styles = () =>
  createStyles({
    contents: {
      display: 'contents'
    },
    ...iconLabelStyle
  })

/**
 * AppBar for suppliers (has communites, Vet, and Company links as well as a userNav dropdown with Buyer App and My account included)
 */
export class SupplierAppBar extends Component<Props> {
  render() {
    const {
      pathname,
      showInsightTab,
      showSuppliersTab,
      queryString,
      intl,
      showDiversityTab
    } = this.props
    return (
      <AppBarContainer
        userNav={<UserNavContainer />}
        homeUrl={paths.basePath}
        pathname={pathname}
      >
        <Hidden smDown>
          <NavBar
            className={this.props.classes.contents}
            listClassName='ml-auto'
          >
            <Tooltip title={intl.formatMessage(messages.companyTip)}>
              <NavLink
                to={paths.company}
                label={intl.formatMessage(messages.company)}
              />
            </Tooltip>

            <VetNavLinkContainer to={paths.vets} />

            {showInsightTab && (
              <Tooltip title={intl.formatMessage(messages.insightTip)}>
                <NavLink
                  to={paths.insight}
                  label={intl.formatMessage(messages.insight)}
                />
              </Tooltip>
            )}

            {showDiversityTab && (
              <DiversityNavLinkContainer to={paths.diversityInvitation} />
            )}

            <TealboardNavLinkContainer to={paths.tealboard} />

            <Tooltip title={intl.formatMessage(messages.communitiesTip)}>
              <NavLink
                to={paths.communities}
                label={intl.formatMessage(messages.communities)}
                isActive={(match, location: { pathname: string }) =>
                  location.pathname.includes(paths.communities) ||
                  location.pathname.includes(paths.target)
                }
              />
            </Tooltip>

            {showSuppliersTab && (
              <Tooltip title={intl.formatMessage(messages.suppliersTip)}>
                <NavLink
                  to={`${paths.search}${queryString || ''}`}
                  label={intl.formatMessage(messages.suppliers)}
                  isActive={isSuppliersActive}
                />
              </Tooltip>
            )}
            <MessagesNavLinkContainer to={paths.messages} />
            <li>
              <ExternalLink
                href={externalPaths.knowledgeBaseLink}
                className={`no-underline f7 mr3 mr4-l ${this.props.classes.icon}`}
              >
                <Tooltip title={intl.formatMessage(messages.knowledgeBaseTip)}>
                  <img
                    src={HelpIcon}
                    alt={intl.formatMessage(messages.knowledgeBase)}
                    className='v-mid'
                    style={{ width: 20, height: 20 }}
                  />
                </Tooltip>
              </ExternalLink>
            </li>
          </NavBar>
        </Hidden>
      </AppBarContainer>
    )
  }
}

export default injectIntl(withStyles(styles)(SupplierAppBar))
