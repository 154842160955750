import React, { useEffect } from 'react'
import { loadInternalStats, loadInternalStatKeys } from 'shared/actions'
import { useDispatch, useSelector } from 'react-redux'
import insightsSelectors from 'buyer/Insights/store/insightsSelectors'
import InternalStats from 'shared/components/InternalStats'
import Loading from 'shared/components/Loading'
import Page from 'shared/components/Page'
import Label from 'shared/components/Label'
import RootState from 'shared/models/RootState'

type Props = {
  type: string
}
const InternalStatsContainer = ({ type }: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadInternalStatKeys(type))
  }, [dispatch, type])

  const handleQuery = (
    keys: string[],
    startDate?: string,
    endDate?: string,
    selectedOrgId?: string
  ) =>
    dispatch(
      loadInternalStats({ keys, type, startDate, endDate, selectedOrgId })
    )

  const isLoading = useSelector(insightsSelectors.isLoadingInternalStats)
  const internalStats = useSelector((state: RootState) =>
    insightsSelectors.getInternalStats(state, type)
  )
  const internalStatKeys = useSelector(insightsSelectors.getInternalStatKeys)
  const isLoadingKeys = useSelector(insightsSelectors.isLoadingKeys)

  if (isLoadingKeys) {
    return <Loading />
  } else if (internalStatKeys && !!internalStatKeys.size) {
    return (
      <InternalStats
        internalStatKeys={internalStatKeys}
        internalStats={internalStats}
        isLoading={isLoading}
        onHandleQuery={handleQuery}
      />
    )
  } else {
    return (
      <Page title='coming soon'>
        <Label className='f4'>Coming Soon</Label>
      </Page>
    )
  }
}

export default InternalStatsContainer
