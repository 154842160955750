import React, { Component } from 'react'
import { connect } from 'react-redux'
import MessageBox from '../../components/MessageBox'
import usersSelectors from 'shared/selectors/usersSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { unselectUserToMessage, selectUserToMessage } from 'shared/actions'
import buyerPaths from 'buyer/routes/paths'
import supplierPaths from 'supplier/routes/paths'
import parsePath from 'shared/utils/parsePath'
import RootState from 'shared/models/RootState'

type Props = {
  messageTopicId: string
  toProfilePictureUrl: string
  toFirstName: string
  toLastName: string
  toEmail: string
  toUserUrl: string
  unselectUserToMessage: () => void
  updatePath: (id?: string) => void
  selectUserToMessage: ({ messageTopicId: string }) => void
  dialog?: boolean
}

export class MessageBoxContainer extends Component<Props> {
  componentDidMount() {
    const { selectUserToMessage, messageTopicId } = this.props
    if (messageTopicId) {
      selectUserToMessage({ messageTopicId })
    }
  }

  handleCancelClick = () => {
    const { unselectUserToMessage, updatePath } = this.props
    updatePath()
    unselectUserToMessage()
  }

  render() {
    return <MessageBox {...this.props} onClickCancel={this.handleCancelClick} />
  }
}

type ContainerProps = {
  messageTopicId: string
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const messageTopicId = props.messageTopicId
    const conversationWithUser =
      messageTopicId &&
      state.getIn([
        'mess',
        'topicsById',
        messageTopicId,
        'conversationWithUser'
      ])
    const userToMessageId = state.getIn(['mess', 'userToMessage'])
    const user = usersSelectors.getById(
      state,
      conversationWithUser || userToMessageId
    )
    const orgUnitId = user.get('orgUnitId')
    const org = orgsSelectors.getById(state, orgUnitId)
    const isClientApp = routingSelectors.getPathname(state).includes('client')
    const myOrgUnitId = sessionSelectors.getOrgUnitId(state)

    return {
      userIsBlocked: user.get('blocked'),
      toProfilePictureUrl: user.get('profilePictureUrl'),
      toFirstName: user.get('firstName') || '',
      toLastName: user.get('lastName') || '',
      toEmail: user.get('email'),
      toTitle: user.get('title') || '',
      toUserUrl:
        user.has('id') &&
        (isClientApp
          ? parsePath(buyerPaths.userprofile, { userId: user.get('id') })
          : parsePath(supplierPaths.userProfile, { userId: user.get('id') })),
      toOrg: org && (org.get('name') || ''),
      toOrgUrl:
        orgUnitId && orgUnitId !== myOrgUnitId
          ? isClientApp
            ? parsePath(buyerPaths.supplierProfile, { supplierId: orgUnitId })
            : parsePath(supplierPaths.clientProfile, { orgUnitId: orgUnitId })
          : ''
    }
  },
  {
    unselectUserToMessage,
    selectUserToMessage
  }
)(MessageBoxContainer)
