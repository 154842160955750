import React, { FC, ReactElement } from 'react'
import Paper from 'shared/components/Paper'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'

const styles = {
  vetSection: 'pt4',
  header: 'pl3-5 pb3 flex justify-between items-center',
  title: 'f5-l f6 fw6 ma0'
}

type Props = {
  title?: string | ReactElement<FormattedMessage>
  subtitle?: string | ReactElement<FormattedMessage> | undefined
  noPadding?: boolean
  actions?: false | undefined | ReactElement
  noPaper?: boolean
}

const PageSection: FC<Props> = ({
  title,
  subtitle,
  noPadding,
  actions,
  noPaper,
  children
}) => {
  return (
    <section className={styles.vetSection}>
      <header className={styles.header}>
        <div>
          <h2 className={styles.title}>{title}</h2>
          {subtitle && (
            <Text secondary className='mt1'>
              {subtitle}
            </Text>
          )}
        </div>
        {actions && <div>{actions}</div>}
      </header>
      <main>
        {noPaper && children}
        {!noPaper && children && (
          <Paper noPadding={noPadding}>{children}</Paper>
        )}
      </main>
    </section>
  )
}

export default PageSection
