import qs from 'qs'
import { fromJS } from 'immutable'

const getRouting = state => state.get('router')
const getLocation = state => getRouting(state).get('location') || fromJS({})

export const getSearchParam = (state, paramName: string) => {
  const search: string = getSearch(state)

  if (search) {
    if (paramName === 'requestUrl' && search.includes('requestUrl')) {
      const start = search.indexOf(paramName) + paramName.length + 1
      return search.substr(start)
    } else {
      const searchObj = qs.parse(search, {
        ignoreQueryPrefix: true,
        arrayLimit: 100
      })
      return searchObj[paramName]
    }
  }
}

export const getPathname = state => getLocation(state).get('pathname') || ''
export const getSearch = state => getLocation(state).get('search')
export const isClientApp = state => getPathname(state).includes('client')
export const getHash = state => getLocation(state).get('hash') || ''
