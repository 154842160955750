import React, { useMemo, useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import paths from '../../routes/paths'
import { useSelector, useDispatch } from 'react-redux'
import insightsSelectors from '../../store/insightsSelectors'
import { getSnapshot } from '../../store/actions'
import RootState from 'shared/models/RootState'
import dateFormat from 'shared/utils/dateFormat'
import ReportTable from './CompareReportTable'
import QualificationRulesInfo from './QualificationRulesInfo'
import FiltersInfo from './FIltersInfo'
import Loading from 'shared/components/Loading'

const CompareReports = () => {
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const report1 = useSelector((state: RootState) =>
    insightsSelectors.getSavedReportById(state, params.report1)
  )
  const report2 = useSelector((state: RootState) =>
    insightsSelectors.getSavedReportById(state, params.report2)
  )

  const isLoading = useSelector(insightsSelectors.isSnapshotsLoading)

  useEffect(() => {
    if (params.report1 && params.report2) {
      dispatch(getSnapshot(params.report1))
      dispatch(getSnapshot(params.report2))
    }
  }, [dispatch, params.report1, params.report2])

  const handleClose = () => {
    history.push(paths.reportSnapshot)
  }

  const showReport = !!report1 && !!report2

  const isSameReportingPeriod =
    report1?.reportingPeriod[0] === report2?.reportingPeriod[0] &&
    report1?.reportingPeriod[1] === report2?.reportingPeriod[1]

  // compare 2 reports and find the delta, and unique union of subCategories
  const [reportsDelta, uniqueSubCategories] = useMemo(() => {
    let outputReportsDelta: { [key: string]: any } = {}
    let outputUniqueSubCategories: string[] = []

    if (report1 && report2) {
      const subCategories = Object.keys(report1.overview).concat(
        Object.keys(report2.overview)
      )
      outputUniqueSubCategories = subCategories.filter(
        (subCategory, index) => subCategories.indexOf(subCategory) === index
      )

      outputReportsDelta.totalDiverseSpend =
        (report2.totalDiverseSpend || 0) - (report1.totalDiverseSpend || 0)
      outputReportsDelta.disqualifiedSpend =
        (report2.disqualifiedSpend || 0) - (report1.disqualifiedSpend || 0)
      outputReportsDelta.potentialSpend =
        (report2.potentialSpend || 0) - (report1.potentialSpend || 0)
      outputReportsDelta.qualifiedSpend =
        (report2.qualifiedSpend || 0) - (report1.qualifiedSpend || 0)

      outputReportsDelta.overview = {}
      outputUniqueSubCategories.forEach(subCategory => {
        outputReportsDelta.overview[subCategory] = {
          qualifiedAmount:
            (report2.overview[subCategory]?.qualifiedAmount || 0) -
            (report1.overview[subCategory]?.qualifiedAmount || 0),
          potentialAmount:
            (report2.overview[subCategory]?.potentialAmount || 0) -
            (report1.overview[subCategory]?.potentialAmount || 0),
          disqualifiedAmount:
            (report2.overview[subCategory]?.disqualifiedAmount || 0) -
            (report1.overview[subCategory]?.disqualifiedAmount || 0)
        }
      })
    }

    return [outputReportsDelta, outputUniqueSubCategories]
  }, [report1, report2])

  const report1Period =
    report1?.reportingPeriod[0] !== report1?.reportingPeriod[1]
      ? `${report1?.reportingPeriod[0]} - ${report1?.reportingPeriod[1]}`
      : report1?.reportingPeriod[0]

  const report1Header = isSameReportingPeriod ? (
    <FormattedMessage
      id='CompareReports.Before'
      defaultMessage='Before ({date})'
      values={{
        date: dateFormat(report1?.created.date, undefined, true)
      }}
    />
  ) : (
    report1Period
  )

  const report2Period =
    report2?.reportingPeriod[0] !== report2?.reportingPeriod[1]
      ? `${report2?.reportingPeriod[0]} - ${report2?.reportingPeriod[1]}`
      : report2?.reportingPeriod[0]

  if (!(report1 && report2) && isLoading) {
    return <Loading />
  }

  return report1 && report2 ? (
    <div className='pa4'>
      <div className='flex items-center justify-between mb3'>
        <h5 className='ma0 f3 mid-gray fw6'>
          <FormattedMessage
            id='CompareReports'
            defaultMessage='Compare Reports'
          />
        </h5>
        <IconButton onClick={handleClose} aria-label='Close Report'>
          <CloseIcon fontSize='large' />
        </IconButton>
      </div>

      {showReport && (
        <div className='flex justify-between mb3'>
          <div className='flex-auto'>
            {isSameReportingPeriod ? (
              <>
                <span className='dib bg-light-gray br2 pa2 mr2'>
                  {report1.name}
                </span>
                <span className='dib bg-light-gray br2 pa2 mr2'>
                  {report2.name}
                </span>
                &mdash;&nbsp;
                <FormattedMessage
                  id='CompareReports.SamePeriod'
                  defaultMessage='Compare the period ({period}) at a different point in time.'
                  values={{
                    period: report1Period
                  }}
                />
              </>
            ) : (
              <>
                <span className='dib bg-light-gray br2 pa2 mr2'>
                  {report1.name}
                </span>
                <span className='dib bg-light-gray br2 pa2 mr2'>
                  {report2.name}
                </span>
                &mdash;&nbsp;
                <FormattedMessage
                  id='CompareReports.DifferentPeriod'
                  defaultMessage='Compare two different periods'
                />
              </>
            )}
          </div>
          {report1.filters && report2.filters && (
            <FiltersInfo filters={report1.filters} filters2={report2.filters} />
          )}
          <QualificationRulesInfo
            rules={report1.rules}
            rules2={report2.rules}
          />
        </div>
      )}

      {showReport && (
        <ReportTable
          report1Header={report1Header}
          report2Header={
            isSameReportingPeriod ? (
              <FormattedMessage
                id='CompareReports.After'
                defaultMessage='After ({date})'
                values={{
                  date: dateFormat(report2.created.date, undefined, true)
                }}
              />
            ) : (
              report2Period
            )
          }
          deltaHeader={
            <FormattedMessage
              id='CompareReports.Shift'
              defaultMessage='Shift'
            />
          }
          report1={report1}
          report2={report2}
          reportsDelta={reportsDelta}
          subCategories={uniqueSubCategories}
        />
      )}
    </div>
  ) : null
}

export default CompareReports
