import React, { Component, Fragment } from 'react'
import { RecordOf, List, Map } from 'immutable'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import PageSideSection from 'shared/components/PageSideSection'
import DiversitySectionContainer from '../../containers/DiversitySectionContainer'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import SupplierProfileDetailsSection from 'shared/components/SupplierProfileDetailsSection'
import SupplierTags from '../../containers/SupplierTags'
import OfferingsAddTagForm from '../../containers/OfferingsAddTagForm'
import DifferentiatorsAddTagForm from '../../containers/DifferentiatorsAddTagForm'
import MutualConnections from '../../containers/MutualConnections'
import CommunitiesContainer from '../../containers/CommunitiesContainer'
import InviteToCommunitiesDialogContainer from '../../../shared/containers/InviteToCommunitiesDialogContainer'
import SimilarSuppliers from '../../containers/SimilarSuppliers'
import SocialFeedContainer from '../../containers/SocialFeedContainer'
import Scrollable from 'shared/components/Scrollable'
import Divider from 'shared/components/Divider'
import ConnectTeamMemberContainer from '../../containers/ConnectTeamMemberContainer'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import TextArea from 'shared/components/TextArea'
import CorporateSectionContainer from '../../containers/CorporateSectionContainer'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import OrgUnitListItem from 'shared/components/OrgUnitListItem'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import TealbotTips from 'shared/components/TealbotTips'
import PitchDeck from 'shared/components/PitchDeck'
import CompanyLocation, {
  CompanyLocationProps
} from 'shared/components/CompanyLocation'
import Grid from '@material-ui/core/Grid'
import Handshake from 'shared/assets/icons/handshake.svg'
import ErrorIcon from '@material-ui/icons/Error'
import ReferenceScores from 'shared/components/ReferenceScores'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'
import Switch from 'shared/components/Switch'
import Avatar from 'shared/components/Avatar'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import ContactsSection from '../ContactsSection'
import Certification from 'shared/models/Certification'
import Contact from 'shared/models/Contact'
import ResourceLinks from 'shared/components/ResourceLinks'
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import { AccessControl } from 'shared/models/AccessControl'
import Pill from 'shared/components/Pill'
import EcovadisRatingContainer from '../../containers/EcovadisRatingContainer'
import Paper from 'shared/components/Paper'
import isFeatureEnabled from 'shared/utils/featureFlags/isFeatureEnabled'
import NavTab from 'shared/components/NavTab'
import routes from 'buyer/SupplierProfile/routes'
import parsePath from 'shared/utils/parsePath'
import { RouteComponentProps } from 'react-router-dom'
import qs from 'qs'
import ecovadisLogo from 'shared/assets/images/ecovadis-logo-transparent.png'
import { SurveyResponse } from 'supplier/Survey/reducers/surveyReducer/surveyReducer'
import SustainabilitySection from '../SustainabilitySection'

type Props = {
  supplierId: string
  accessControl: AccessControl
  certifications: Map<string, Certification>
  blogUrl?: string
  videoUrls?: List<string>
  contacts: List<RecordOf<Contact>>
  longDescription: string
  pitchDeckUrl: string
  serviceAreas: List<string>
  hasDetails: boolean
  hasMutualConnections: boolean
  hasCommunities: boolean
  hasClientCommunities: boolean
  hasSimilarSuppliers: boolean
  isEditingContact: boolean
  isTealbot: boolean
  isBuyerAdmin: boolean
  contactToEdit: RecordOf<Contact>
  customers: List<RecordOf<{ id: string; buyer: string; buyerName: string }>>
  scrollToDiversity: boolean
  scrollToReference: boolean
  scrollToTeam: boolean
  scrollToSustainabilitySurvey: boolean
  referenceScores: {
    cardCount: number
    contactsCount: number
    orgCount: number
    relationshipDetailsCount: number
    completeness: number
  }
  vetTotalCount: number
  userProfilePicture: string
  currentUserOrgUnitName: string
  userFirstName: string
  userLastName: string
  userTitle: string
  userEmail: string
  referenceRequests: List<
    RecordOf<{ created: RecordOf<{ user: string }>; status: string }>
  >
  currentUserId: string
  supplierName: string
  currentUserOrgUnitId: string
  currentSupplierRelationship: RecordOf<{
    id: string
    buyer: string
    buyerName: string
  }>
  locations: List<RecordOf<CompanyLocationProps>>
  hasSocialFeed: boolean
  mobile: boolean
  showFollowing: boolean
  connected: boolean
  onUpdateContact: () => void
  onCancelEdit: () => void
  onRemoveContact: () => void
  onRemoveContactRelationship: () => void
  onEditContact: () => void
  connectToSupplier: (arg: { supplierId: string }) => void
  onVerifySupplier: (arg: {
    supplierRelationshipId: string
    verifyClient: string
  }) => void
  loadSurveyResponseDetails: (surveyId: string) => void
  requestBuyerForReference: (arg: {
    shareCompanyName: boolean
    shareNameAndTitle: boolean
    shareProfilePicture: boolean
    companyName: string
    userName: string
    userTitle: string
    details: string
    supplierName: string
  }) => void
  internalCategory?: List<string>
  differentiatorsTagCount: number
  offeringsTagCount: number
  ecovadisReportEnabled: boolean
  surveyResponse: RecordOf<SurveyResponse>
  loadActiveESGRequest: (supplierId: string) => void
} & WithWidthProps &
  RouteComponentProps

export class ProfilePage extends Component<Props> {
  diversitySection: any = {}
  referenceSection: any = {}
  teamSection: any = {}
  sustainabilitySurvey: any = {}

  state = {
    openInviteDialog: false,
    supplierRelationshipToVerifyId: '',
    openRequestReferenceDialog: false,
    shareNameAndTitle: true,
    shareCompanyName: true,
    shareProfilePicture: !!this.props.userProfilePicture,
    requestReferenceDetails: ''
  }

  componentDidMount() {
    const scrollTo: string = this.props.scrollToDiversity
      ? 'diversitySection'
      : this.props.scrollToReference
      ? 'referenceSection'
      : this.props.scrollToTeam
      ? 'teamSection'
      : this.props.scrollToSustainabilitySurvey
      ? 'sustainabilitySurvey'
      : ''

    if (scrollTo) {
      setTimeout(() => this.scrollToSection(scrollTo), 500)
    }

    if (this.props.surveyResponse) {
      this.props.loadSurveyResponseDetails(this.props.surveyResponse?.get('id'))
    }
    this.props.loadActiveESGRequest(this.props.supplierId)
  }

  scrollToSection = (section: string) => {
    if (this[section]) {
      document.documentElement.scrollTop = this[section].offsetTop - 60
      document.body.scrollTop = this[section].offsetTop - 60
    }
  }

  handleOpenDialog = dialogName => {
    this.setState({
      [`open${dialogName}`]: true
    })
  }

  handleCloseDialog = dialogName => {
    this.setState({
      [`open${dialogName}`]: false,
      userToMessageId: '',
      connectContact: false
    })
  }

  handleSelectSupplierToVerify = srId => {
    this.setState({
      supplierRelationshipToVerifyId: srId
    })
  }

  handleVerifySupplier = verifyClient => {
    this.props.onVerifySupplier({
      supplierRelationshipId: this.state.supplierRelationshipToVerifyId,
      verifyClient
    })

    this.setState({
      supplierRelationshipToVerifyId: ''
    })
  }

  toggleRequestReferenceDialog = () => {
    const { userProfilePicture } = this.props
    this.setState({
      openRequestReferenceDialog: !this.state.openRequestReferenceDialog,
      ...(this.state.openRequestReferenceDialog
        ? {
            shareCompanyName: true,
            shareNameAndTitle: true,
            shareProfilePicture: !!userProfilePicture,
            requestReferenceDetails: ''
          }
        : {})
    })
  }

  handleRequestReferenceDetailsChange = e => {
    this.setState({
      requestReferenceDetails: e.currentTarget.value
    })
  }

  handleSubmitReferenceRequest = e => {
    e.preventDefault()

    this.props.requestBuyerForReference({
      shareCompanyName: this.state.shareCompanyName,
      shareNameAndTitle: this.state.shareNameAndTitle,
      shareProfilePicture: this.state.shareProfilePicture,
      companyName: this.props.currentUserOrgUnitName,
      userName: `${this.props.userFirstName} ${this.props.userLastName}`,
      userTitle: this.props.userTitle,
      details: this.state.requestReferenceDetails,
      supplierName: this.props.supplierName
    })
    this.setState({
      openRequestReferenceDialog: false
    })
  }

  render() {
    const {
      currentUserOrgUnitId,
      currentSupplierRelationship,
      supplierId,
      supplierName,
      certifications,
      locations,
      contacts,
      onRemoveContact,
      onRemoveContactRelationship,
      onEditContact,
      isEditingContact,
      isTealbot,
      isBuyerAdmin,
      contactToEdit,
      longDescription,
      pitchDeckUrl,
      serviceAreas,
      hasDetails,
      hasCommunities,
      hasClientCommunities,
      hasSimilarSuppliers,
      onUpdateContact,
      hasSocialFeed,
      onCancelEdit,
      customers,
      mobile,
      showFollowing,
      connectToSupplier,
      connected,
      referenceScores,
      vetTotalCount,
      userFirstName,
      userLastName,
      userProfilePicture,
      userTitle,
      currentUserOrgUnitName,
      referenceRequests,
      currentUserId,
      width,
      accessControl,
      internalCategory,
      differentiatorsTagCount,
      offeringsTagCount,
      userEmail,
      ecovadisReportEnabled
    } = this.props
    const {
      shareNameAndTitle,
      shareCompanyName,
      shareProfilePicture,
      requestReferenceDetails
    } = this.state
    const firstVideoUrl = this.props.videoUrls && this.props.videoUrls.get(0)
    const matchMobile = width && ['xs', 'sm'].includes(width)
    const isEcoVadisRatingFeatureEnabled = isFeatureEnabled({
      feature: 'ecovadisRating',
      userEmail: userEmail
    })

    let queryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })

    const baseQueryParams = {
      ...queryParams,
      profileTab: ''
    }
    const ecovadisRatingQueryParams = {
      ...queryParams,
      profileTab: 'ecovadisRating'
    }

    return (
      <Page
        title={
          <FormattedMessage
            id='ProfilePage.SupplierProfile'
            defaultMessage='Supplier Profile'
          />
        }
      >
        <ScrollToPosition to={300} strict='up' />
        <Main>
          {/* feature accessbile to tealbookers only, and 'sustainabilityReportEnabled' defaulted to True for now in buyer-manager => extractSettings  */}
          {isEcoVadisRatingFeatureEnabled && ecovadisReportEnabled ? (
            <>
              <div style={{ whiteSpace: 'nowrap' }}>
                <NavTab
                  key={'aboutUs'}
                  to={`${parsePath(routes.basePath, {
                    supplierId
                  })}?${qs.stringify(baseQueryParams)}`}
                  isActive={(match, location) => !queryParams.profileTab}
                >
                  <FormattedMessage
                    id='ProfilePage.AboutUs'
                    defaultMessage='About Us'
                  />
                </NavTab>
                <NavTab
                  key='ecovadisRating'
                  to={`${parsePath(routes.basePath, {
                    supplierId
                  })}?${qs.stringify(ecovadisRatingQueryParams)}`}
                  isActive={(match, location) => queryParams.profileTab}
                >
                  <img
                    alt='ecovadisLogo'
                    src={ecovadisLogo}
                    style={{ width: 65 }}
                  />
                </NavTab>
              </div>
              {!queryParams.profileTab && (
                <Paper>
                  <Scrollable>
                    <Text className='mt3-5'>{longDescription}</Text>
                  </Scrollable>

                  {pitchDeckUrl && (
                    <div className='tc mt3-5'>
                      <PitchDeck url={pitchDeckUrl} mobile={mobile} />
                    </div>
                  )}
                </Paper>
              )}
              {queryParams.profileTab && <EcovadisRatingContainer />}
            </>
          ) : (
            <>
              {(pitchDeckUrl || longDescription) && (
                <PageSection
                  title={
                    <FormattedMessage
                      id='ProfilePage.AboutUs'
                      defaultMessage='About Us'
                    />
                  }
                >
                  <Scrollable>
                    <Text className='mt3-5'>{longDescription}</Text>
                  </Scrollable>

                  {pitchDeckUrl && (
                    <div className='tc mt3-5'>
                      <PitchDeck url={pitchDeckUrl} mobile={mobile} />
                    </div>
                  )}
                </PageSection>
              )}
            </>
          )}

          <PageSection
            title={
              accessControl.access === 'punchout' ? (
                <FormattedMessage
                  id='ProfilePage.WhatTheyAreKnownFor'
                  defaultMessage='What they are known for'
                />
              ) : (
                <FormattedMessage
                  id='ProfilePage.WhatYouKnowThemFor'
                  defaultMessage='What you know them for'
                />
              )
            }
            noPadding
          >
            <Scrollable maxHeight={500}>
              <SupplierTags
                tagsFieldName='offerings'
                readOnly={accessControl.access === 'punchout'}
              />
            </Scrollable>
            {accessControl.access === 'full' && (
              <OfferingsAddTagForm numberOfTags={offeringsTagCount} />
            )}
          </PageSection>

          {accessControl.access === 'full' && (
            <PageSection
              title={
                <FormattedMessage
                  id='ProfilePage.WhatDifferentiatesThem'
                  defaultMessage='What differentiates them'
                />
              }
              noPadding
            >
              <Scrollable maxHeight={500}>
                <SupplierTags tagsFieldName='differentiators' />
              </Scrollable>
              <DifferentiatorsAddTagForm
                numberOfTags={differentiatorsTagCount}
              />
            </PageSection>
          )}

          {locations && locations.size > 0 && (
            <PageSection
              title={
                <FormattedMessage
                  id='ProfilePage.Locations'
                  defaultMessage='Locations'
                />
              }
              noPadding
            >
              <Scrollable>
                <Grid container className='pa2'>
                  {locations.map((location, index) => (
                    <Grid key={index} item xs={12} sm={6} className='pa3'>
                      <CompanyLocation
                        address={location.get('address')}
                        description={location.get('description')}
                        phone={location.get('phone')}
                        name={location.get('name')}
                        poBox={location.get('poBox')}
                        houseNumber={location.get('houseNumber')}
                        level={location.get('level')}
                        unit={location.get('unit')}
                        road={location.get('road')}
                        suburb={location.get('suburb')}
                        city={location.get('city')}
                        stateDistrict={location.get('stateDistrict')}
                        state={location.get('state')}
                        postalCode={location.get('postalCode')}
                        country={location.get('country')}
                        isPrimary={location.get('isPrimary')}
                        lat={location.get('lat')}
                        long={location.get('long')}
                        publicView
                      />
                    </Grid>
                  ))}
                </Grid>
              </Scrollable>
            </PageSection>
          )}

          {(accessControl.access !== 'punchout' ||
            (contacts && contacts.size > 0)) && (
            <div
              ref={section => {
                this.teamSection = section
              }}
            >
              <PageSection
                title={
                  <FormattedMessage
                    id='ProfilePage.OurTeam'
                    defaultMessage='Our Team'
                  />
                }
                noPadding
              >
                <ContactsSection
                  currentUserId={currentUserId}
                  contacts={contacts}
                  isEditingContact={isEditingContact}
                  isTealbot={isTealbot}
                  isBuyerAdmin={isBuyerAdmin}
                  supplierId={supplierId}
                  onRemoveContact={onRemoveContact}
                  onRemoveContactRelationship={onRemoveContactRelationship}
                  onEditContact={onEditContact}
                  onUpdateContact={onUpdateContact}
                  onCancelEdit={onCancelEdit}
                  contactToEdit={contactToEdit}
                  supplierName={supplierName}
                  readOnly={accessControl.access === 'punchout'}
                  hideAddNew={accessControl.access === 'punchout'}
                  hideConnections={accessControl.access === 'punchout'}
                />
              </PageSection>
            </div>
          )}

          <div
            ref={section => {
              this.sustainabilitySurvey = section
            }}
          >
            <SustainabilitySection supplierId={supplierId} />
          </div>

          {certifications && certifications.size > 0 && (
            <div
              ref={section => {
                this.diversitySection = section
              }}
            >
              <PageSection
                title={
                  <FormattedMessage
                    id='ProfilePage.Qualifications'
                    defaultMessage='Qualifications'
                  />
                }
              >
                <DiversitySectionContainer />
              </PageSection>
            </div>
          )}

          {(this.props.blogUrl || firstVideoUrl) && (
            <PageSection
              title={
                <FormattedMessage
                  id='ProfilePage.Resources'
                  defaultMessage='Resources'
                />
              }
            >
              <ResourceLinks
                blogUrl={this.props.blogUrl}
                videoUrl={firstVideoUrl}
              />
            </PageSection>
          )}

          <ClearbitCredit />
        </Main>

        <Aside>
          <div className='pt4-l'>
            <CorporateSectionContainer />
            {accessControl.access === 'punchout' &&
              internalCategory &&
              internalCategory.size > 0 && (
                <PageSideSection
                  title={
                    <FormattedMessage
                      id='ProfilePage.InternalCategories'
                      defaultMessage='Internal Categories'
                    />
                  }
                >
                  <Scrollable>
                    {internalCategory.sort().map(category => (
                      <Pill label={category} />
                    ))}
                  </Scrollable>
                </PageSideSection>
              )}
            {hasDetails && (
              <PageSideSection
                title={
                  <FormattedMessage
                    id='ProfilePage.ServiceAreas'
                    defaultMessage='Service Areas'
                  />
                }
              >
                <Scrollable>
                  <SupplierProfileDetailsSection serviceAreas={serviceAreas} />
                </Scrollable>
              </PageSideSection>
            )}

            {accessControl.access === 'full' && (
              <div
                ref={section => {
                  this.referenceSection = section
                }}
              >
                <PageSideSection
                  title={
                    <FormattedMessage
                      id='ProfilePage.ReferenceScores'
                      defaultMessage='Reference Scores'
                    />
                  }
                >
                  <ReferenceScores
                    buyers={referenceScores.relationshipDetailsCount}
                    contacts={referenceScores.contactsCount}
                    orgNum={referenceScores.orgCount}
                    followers={referenceScores.cardCount}
                    vets={vetTotalCount}
                    completeness={referenceScores.completeness}
                  />

                  {referenceRequests && referenceRequests.size > 0 && (
                    <Scrollable>
                      <Text className='mt3 pt1 fw6'>Reference Requests:</Text>
                      {referenceRequests.map((refRequest, index) => (
                        <UserListItemContainer
                          key={index}
                          userId={refRequest.get('created').get('user')}
                          secondaryText={refRequest.get('status')}
                          mini
                        />
                      ))}
                    </Scrollable>
                  )}

                  {(!referenceRequests ||
                    !referenceRequests.some(
                      refRequest =>
                        refRequest.get('created').get('user') === currentUserId
                    )) && (
                    <Button
                      onClick={this.toggleRequestReferenceDialog}
                      className='mt3'
                      label={
                        <FormattedMessage
                          id='ProfilePage.requestReference'
                          defaultMessage='Request Reference'
                        />
                      }
                    />
                  )}
                </PageSideSection>
              </div>
            )}

            {accessControl.access === 'full' && (
              <PageSideSection
                title={
                  <FormattedMessage
                    id='ProfilePage.Followers'
                    defaultMessage='Followers'
                  />
                }
              >
                <Scrollable>
                  <MutualConnections />
                </Scrollable>
                <ConnectTeamMemberContainer />
              </PageSideSection>
            )}

            {((customers && customers.size > 0) ||
              currentSupplierRelationship) && (
              <PageSideSection
                title={
                  <FormattedMessage
                    id='ProfilePage.Customers'
                    defaultMessage='Customers'
                  />
                }
              >
                {currentSupplierRelationship && (
                  <Fragment>
                    <OrgUnitListItem
                      key={currentSupplierRelationship.get('id')}
                      orgUnitId={currentSupplierRelationship.get('buyer')}
                      name={currentSupplierRelationship.get('buyerName')}
                      secondaryText={
                        currentSupplierRelationship.getIn([
                          'buyerCollaboration',
                          'verifyClient'
                        ]) === 'agree' ? (
                          <CheckCircleIcon className='teal' />
                        ) : (
                          currentSupplierRelationship.get('buyer') ===
                            currentUserOrgUnitId && (
                            <Fragment>
                              {currentSupplierRelationship.getIn([
                                'buyerCollaboration',
                                'verifyClient'
                              ]) === 'disagree' ? (
                                <div className='red flex'>
                                  <ErrorIcon />
                                  <span className='flex-auto ml1'>
                                    <FormattedMessage
                                      id='ProfilePage.RelationshipCouldNotBeConfirmed'
                                      defaultMessage='Relationship could not be confirmed.'
                                    />
                                  </span>
                                </div>
                              ) : (
                                currentSupplierRelationship.getIn([
                                  'supplierCollaboration',
                                  'requestVerify'
                                ]) && (
                                  <button
                                    className='fw4 no-underline dim dib b--transparent pointer teal f6 pa0'
                                    onClick={() =>
                                      this.handleSelectSupplierToVerify(
                                        currentSupplierRelationship.get('id')
                                      )
                                    }
                                  >
                                    <FormattedMessage
                                      id='ProfilePage.Agree'
                                      defaultMessage='Agree?'
                                    />
                                  </button>
                                )
                              )}
                            </Fragment>
                          )
                        )
                      }
                    />
                    <Divider className='mv2' />
                  </Fragment>
                )}
                <Scrollable>
                  {customers.map(customer => (
                    <OrgUnitListItem
                      key={customer.get('id')}
                      orgUnitId={customer.get('buyer')}
                      name={customer.get('buyerName')}
                      secondaryText={
                        customer.getIn([
                          'buyerCollaboration',
                          'verifyClient'
                        ]) === 'agree' ? (
                          <CheckCircleIcon className='teal' />
                        ) : (
                          customer.get('buyer') === currentUserOrgUnitId && (
                            <Fragment>
                              {customer.getIn([
                                'buyerCollaboration',
                                'verifyClient'
                              ]) === 'disagree' ? (
                                <div className='red flex'>
                                  <ErrorIcon />
                                  <span className='flex-auto ml1'>
                                    <FormattedMessage
                                      id='ProfilePage.RelationshipCouldNotBeConfirmed'
                                      defaultMessage='Relationship could not be confirmed.'
                                    />
                                  </span>
                                </div>
                              ) : (
                                customer.getIn([
                                  'supplierCollaboration',
                                  'requestVerify'
                                ]) && (
                                  <button
                                    className='fw4 no-underline dim dib b--transparent pointer teal f6 pa0'
                                    onClick={() =>
                                      this.handleSelectSupplierToVerify(
                                        customer.get('id')
                                      )
                                    }
                                  >
                                    <FormattedMessage
                                      id='ProfilePage.Agree'
                                      defaultMessage='Agree?'
                                    />
                                  </button>
                                )
                              )}
                            </Fragment>
                          )
                        )
                      }
                    />
                  ))}
                </Scrollable>

                {customers &&
                  customers.some(
                    c =>
                      c.getIn(['supplierCollaboration', 'requestVerify']) &&
                      (!c.getIn(['buyerCollaboration', 'verifyClient']) ||
                        c.getIn(['buyerCollaboration', 'verifyClient']) ===
                          'unspecified')
                  ) && (
                    <div className='mt4'>
                      <TealbotTips mini noBorder noLabel>
                        <Text>
                          <FormattedMessage
                            id='ProfilePage.ConfirmYourBusinessRelationshipToHelpYourSuppliers'
                            defaultMessage='Confirm your business relationship to help your supplier'
                          />
                        </Text>
                      </TealbotTips>
                    </div>
                  )}
              </PageSideSection>
            )}
            {showFollowing && (
              <PageSideSection
                title={
                  <FormattedMessage
                    id='ProfilePage.FollowingYou'
                    defaultMessage='Following you'
                  />
                }
                hideTitle
              >
                <div className='pt3-5'>
                  <img
                    src={Handshake}
                    className='v-mid mr2'
                    alt='following'
                    style={{ width: 50 }}
                  />
                  <h5 className='f7 fw6 dib v-mid ma0'>
                    {connected ? (
                      <FormattedMessage
                        id='ProfilePage.YouAreConnected'
                        defaultMessage='You are connected!'
                      />
                    ) : (
                      <FormattedMessage
                        id='ProfilePage.SupplierIsFollowingYou'
                        defaultMessage='Supplier is following you'
                      />
                    )}
                  </h5>
                </div>
                {!connected && (
                  <Text className='mt1'>
                    <FormattedMessage
                      id='ProfilePage.Please'
                      defaultMessage='Please'
                    />{' '}
                    <span
                      className='teal dim pointer'
                      onClick={() => connectToSupplier({ supplierId })}
                    >
                      <FormattedMessage
                        id='ProfilePage.FollowUS'
                        defaultMessage='follow us'
                      />
                    </span>
                    .{' '}
                    <FormattedMessage
                      id='ProfilePage.WeAreInterestedInConnecting'
                      defaultMessage='We are interested in connecting.'
                    />
                  </Text>
                )}
              </PageSideSection>
            )}
            {accessControl.access === 'full' && hasCommunities && (
              <PageSideSection
                title={
                  <FormattedMessage
                    id='ProfilePage.OurCommunities'
                    defaultMessage='Our Communities'
                  />
                }
              >
                <Scrollable>
                  <CommunitiesContainer supplierId={supplierId} />
                </Scrollable>
                {hasClientCommunities && (
                  <div className='mt3'>
                    <Divider />
                    <Button
                      secondary
                      label={
                        <FormattedMessage
                          id='ProfilePage.InviteToCommunity'
                          defaultMessage='Invite to community'
                        />
                      }
                      className='mt3'
                      onClick={() => this.handleOpenDialog('InviteDialog')}
                    />
                  </div>
                )}
              </PageSideSection>
            )}

            {accessControl.access === 'full' &&
              !hasCommunities &&
              hasClientCommunities && (
                <PageSideSection title='Communities'>
                  <div className='mt3 dt w-100'>
                    <Text>
                      <FormattedMessage
                        id='ProfilePage.ThisSupplierIsntCurrentlyConnectedToAnyCommunities'
                        defaultMessage="This supplier isn't currently connected to any communities."
                      />
                    </Text>
                    <Button
                      secondary
                      label={
                        <FormattedMessage
                          id='ProfilePage.InviteToCommunity'
                          defaultMessage='Invite to community'
                        />
                      }
                      className='mt3'
                      onClick={() => this.handleOpenDialog('InviteDialog')}
                    />
                  </div>
                </PageSideSection>
              )}

            {hasSimilarSuppliers && <SimilarSuppliers />}

            {hasSocialFeed && <SocialFeedContainer />}
          </div>
        </Aside>

        <InviteToCommunitiesDialogContainer
          supplierId={supplierId}
          open={this.state.openInviteDialog}
          onCloseInviteDialog={() => this.handleCloseDialog('InviteDialog')}
        />

        <Dialog
          onClose={() => this.handleSelectSupplierToVerify('')}
          open={!!this.state.supplierRelationshipToVerifyId}
        >
          <DialogTitle>
            <FormattedMessage
              id='ProfilePage.Confirmation'
              defaultMessage='Confirmation'
            />
          </DialogTitle>
          <DialogContent>
            <div className='mt3'>
              <FormattedMessage
                id='ProfilePage.HasYourCompanyWorkedWith'
                defaultMessage='Has your company worked with {supplierName}?'
                values={{ supplierName }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              label={
                <FormattedMessage id='ProfilePage.Yes' defaultMessage='Yes' />
              }
              autoSize
              onClick={() => this.handleVerifySupplier('agree')}
            />
            <Button
              label={<FormattedMessage id='No' />}
              secondary
              autoSize
              onClick={() => this.handleVerifySupplier('disagree')}
            />
          </DialogActions>
        </Dialog>

        <Dialog
          onClose={this.toggleRequestReferenceDialog}
          open={this.state.openRequestReferenceDialog}
          fullWidth
          disableBackdropClick
          fullScreen={matchMobile}
        >
          <form onSubmit={this.handleSubmitReferenceRequest}>
            <DialogTitle>
              <FormattedMessage
                id='ProfilePage.buyerReferenceInquiry'
                defaultMessage='Buyer Reference Inquiry'
              />
            </DialogTitle>
            <DialogContent>
              <Label>
                <FormattedMessage
                  id='ProfilePage.whatCanWeShare'
                  defaultMessage='What can we share with the reference buyer?'
                />
              </Label>
              <div className='flex items-center pt3-ns pt4'>
                <Text className='flex-auto'>
                  {shareProfilePicture ? (
                    userProfilePicture ? (
                      <>
                        <Avatar
                          url={userProfilePicture}
                          className='w2 mr2'
                          name={`${userFirstName || ''} ${userLastName || ''}`}
                        />
                        <FormattedMessage
                          id='ProfilePage.ProfilePicture'
                          defaultMessage='Profile Picture'
                        />
                      </>
                    ) : (
                      <FormattedMessage
                        id='ProfilePage.NoProfilePicture'
                        defaultMessage={`Currently you don't a profile picture. Please update your profile.`}
                      />
                    )
                  ) : (
                    <FormattedMessage
                      id='ProfilePage.PictureWithheld'
                      defaultMessage='Profile Picture Withheld'
                    />
                  )}
                </Text>
                <Switch
                  onChange={() =>
                    this.setState({
                      shareProfilePicture: !this.state.shareProfilePicture
                    })
                  }
                  checked={shareProfilePicture}
                />
              </div>
              <div className='flex items-center pt3'>
                <Text className='flex-auto'>
                  {shareNameAndTitle ? (
                    `${userFirstName ? userFirstName : ''}${
                      userLastName ? ` ${userLastName}` : ''
                    }${userTitle ? `, ${userTitle}` : ''}`
                  ) : (
                    <FormattedMessage
                      id='ProfilePage.NameWithheld'
                      defaultMessage='Name Withheld'
                    />
                  )}
                </Text>
                <Switch
                  onChange={() =>
                    this.setState({
                      shareNameAndTitle: !this.state.shareNameAndTitle
                    })
                  }
                  checked={shareNameAndTitle}
                />
              </div>
              <div className='flex items-center pt3 pb3 pb0-ns'>
                <Text className='flex-auto'>
                  {shareCompanyName ? (
                    currentUserOrgUnitName
                  ) : (
                    <FormattedMessage
                      id='ProfilePage.CompanyWithheld'
                      defaultMessage='Company Withheld'
                    />
                  )}
                </Text>
                <Switch
                  onChange={() =>
                    this.setState({
                      shareCompanyName: !this.state.shareCompanyName
                    })
                  }
                  checked={shareCompanyName}
                />
              </div>

              <Label>
                <FormattedMessage
                  id='ProfilePage.provideDetails'
                  defaultMessage='Provide some details to help us find the right information for you'
                />
              </Label>
              <TextArea
                onChange={this.handleRequestReferenceDetailsChange}
                value={requestReferenceDetails}
              />
            </DialogContent>
            <DialogActions className='fixed static-ns bottom-1'>
              <Button
                size={matchMobile ? 'large' : undefined}
                label={
                  <FormattedMessage
                    id='ProfilePage.submitInquiry'
                    defaultMessage='Submit your inquiry'
                  />
                }
                autoSize
                type='submit'
              />
              <Button
                size={matchMobile ? 'large' : undefined}
                label={<FormattedMessage id='CancelButton' />}
                secondary
                autoSize
                onClick={this.toggleRequestReferenceDialog}
              />
            </DialogActions>
          </form>
        </Dialog>
      </Page>
    )
  }
}

export default withWidth()(ProfilePage)
