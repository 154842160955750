import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import NotificationContainer from '../NotificationContainer'
import sessionSelectors from '../../selectors/sessionSelectors'
import SyncContactsDialog from '../../components/SyncContactsDialog'
import mobileContactsSelector from 'accounts/selectors/mobileContactsSelector'
import contacts from '../../utils/api/contacts'
import { updateUser } from '../../actions'
import termsAndConditionsLastUpdatedDate from 'shared/utils/termsAndConditionsLastUpdatedDate'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import { notify } from 'shared/actions'
import TealbotSupport from 'shared/components/TealbotSupport'
import useSmallScreen from 'shared/utils/useSmallScreen'
import { FormattedMessage } from 'react-intl'
import chatbot from 'shared/utils/chatbot'
import AppRoutes from './AppRoutes'
import RootState from '../../models/RootState'
import Loading from '../../components/Loading'
import UserOnboardingPageContainer from '../UserOnboardingPageContainer'
import AgreeToUpdatedTermsPage from '../../components/AgreeToUpdatedTermsPage'

type Props = {
  mobileContacts: any
  userId: string
  updateUser: ({ id: string, termsAcceptedDate: Date }) => void
  userStatusIsKnown: boolean
  userNeedsOnboarding: boolean
  userNeedsToAgreeToTerms: boolean
  noChatbot: boolean
  syncContacts: boolean
  noTermsAndConditions: boolean
  notify: typeof notify
}

export const AppContainer = (props: Props) => {
  const acceptingTerms = false
  const isSmallScreen = useSmallScreen()
  const { notify } = props

  const notifyWhenOffline = useCallback(() => {
    notify({
      message: 'Network Connection Lost'
    })
  }, [notify])

  const handleSync = () => {
    // sync contacts using Android flow
    if (window.Android && window.Android.syncContacts) {
      window.Android.syncContacts()
    } else if (props.mobileContacts) {
      contacts.uploadContacts(props.mobileContacts.toJS())
    }
  }

  const handleDontSync = () => {
    contacts.postponeContactsSync()
  }

  const handleAcceptTerms = () => {
    const { userId, updateUser } = props

    if (!acceptingTerms) {
      updateUser({
        id: userId,
        termsAcceptedDate: new Date().toISOString()
      })
    }
  }

  useEffect(() => {
    // notify whenever the client loses network error
    window.addEventListener('offline', notifyWhenOffline)
    return () => {
      window.removeEventListener('offline', notifyWhenOffline)
    }
  }, [notifyWhenOffline])

  return (
    <>
      {!props.userStatusIsKnown ? (
        <Loading />
      ) : props.userNeedsToAgreeToTerms ? (
        <AgreeToUpdatedTermsPage
          onAcceptTerms={handleAcceptTerms}
          noTermsAndConditions={props.noTermsAndConditions}
        />
      ) : props.userNeedsOnboarding ? (
        <UserOnboardingPageContainer />
      ) : (
        <AppRoutes />
      )}

      <NotificationContainer />

      {props.syncContacts && (
        <SyncContactsDialog onSync={handleSync} onDontSync={handleDontSync} />
      )}

      {!isSmallScreen && !props.noChatbot && (
        <TealbotSupport
          title={
            <FormattedMessage id='TealbotChat' defaultMessage='Tealbot Chat' />
          }
          onClick={() => chatbot.open()}
        />
      )}
    </>
  )
}

export default connect(
  (state: RootState) => {
    const loggedIn = sessionSelectors.isLoggedIn(state)
    const loggedInUser = sessionSelectors.getUser(state)
    const currentUserOrgUnit = orgsSelectors.getById(
      state,
      sessionSelectors.getOrgUnitId(state)
    )
    const mobileContacts = mobileContactsSelector.get(state)
    const syncContacts =
      sessionSelectors.isLoggedIn(state) &&
      sessionSelectors.userHasAccessToApp(state, 'buyer') &&
      contacts.shouldSyncContacts() &&
      mobileContacts?.size > 0

    return {
      mobileContacts,
      syncContacts,
      userId: loggedInUser.get('id'),
      noTermsAndConditions: !!currentUserOrgUnit.get('noTAndCs'),
      userNeedsToAgreeToTerms:
        loggedIn &&
        !sessionSelectors.userHasRole(state, 'tealbot') &&
        // the terms have changed since the last time the user accepted them
        (!loggedInUser.get('termsAcceptedDate') ||
          new Date(loggedInUser.get('termsAcceptedDate')) <
            termsAndConditionsLastUpdatedDate),
      userStatusIsKnown: sessionSelectors.statusIsKnown(state),
      userNeedsOnboarding:
        sessionSelectors.isLoggedIn(state) &&
        (!loggedInUser.get('firstName') || !loggedInUser.get('lastName')),
      noChatbot:
        !sessionSelectors.userHasRole(state, ['buyer', 'supplier']) &&
        sessionSelectors.userHasRole(state, 'clickWorker')
    }
  },
  {
    updateUser,
    notify
  }
)(AppContainer)
