import React, { Component } from 'react'
import { connect } from 'react-redux'
import profileSelectors from '../../selectors/profileSelectors'
import RootState from 'shared/models/RootState'

type Props = {
  hasCoverUrl: boolean
}
export default function withResetPagePosition(WrappedComponent) {
  return connect((state: RootState) => ({
    hasCoverUrl: !!profileSelectors.getDataField(state, 'coverUrl')
  }))(
    class extends Component<Props> {
      componentDidMount() {
        const { hasCoverUrl } = this.props
        const scrollTopLimit = hasCoverUrl ? 615 : 460
        const scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop

        if (scrollTop > scrollTopLimit) {
          document.documentElement.scrollTop = scrollTopLimit
          document.body.scrollTop = scrollTopLimit
        }
      }

      render() {
        return <WrappedComponent {...this.props} />
      }
    }
  )
}
