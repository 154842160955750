import { call } from 'redux-saga/effects'
import cards from 'shared/utils/api/cards'

export default function* updateCardSaga({
  cardId,
  supplierId,
  cardChanges,
  ...options
}) {
  // create a card if is not there yet
  let newCard
  if (!cardId) {
    newCard = yield call(cards.create, {
      supplier: supplierId,
      ...cardChanges
    })
  } else {
    yield call(cards.update, cardId, cardChanges)
  }

  return yield {
    supplierId,
    cardId,
    cardChanges,
    newCard,
    ...options
  }
}
