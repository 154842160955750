import React, { memo } from 'react'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import ListItem from 'shared/components/List/ListItem'
import Button from 'shared/components/Button'
import { Map } from 'immutable'
import OrgLogo from 'shared/components/OrgLogo'
import Link from 'shared/components/Link'
import parsePath from 'shared/utils/parsePath'
import supplierPaths from '../../../routes/paths'
import { Tier2Communities } from '../../containers/Tier2InvitationContainer/Tier2InvitationContainer'
import Loading from 'shared/components/Loading'

type Props = {
  isLoading: boolean
  communities: Map<string, Tier2Communities>
  onHandleAcceptInvitation: (communityId: string) => void
  onHandleDeclineInvitation: (communityId: string) => void
}

const Tier2Invitation = memo(
  ({
    isLoading,
    communities,
    onHandleAcceptInvitation,
    onHandleDeclineInvitation
  }: Props) => {
    const renderRow = buyer => (
      <div className='ba b--light-gray pa2 mv3 ph3' key={buyer.get('buyerId')}>
        <ListItem
          leftAvatar={
            <Link
              to={`${parsePath(supplierPaths.clientProfile, {
                orgUnitId: buyer.get('buyerId')
              })}`}
            >
              <div className='pa1 pointer ba b--black-10 br2 bg-white flex items-center h2-5 w2-5'>
                <OrgLogo url={buyer.get('buyerLogo')} />
              </div>
            </Link>
          }
          primaryText={
            <Link
              to={`${parsePath(supplierPaths.clientProfile, {
                orgUnitId: buyer.get('buyerId')
              })}`}
            >
              {buyer.get('buyerName')}
            </Link>
          }
          secondaryText={
            <Text>
              <FormattedMessage
                id='Tier2Invitation.InvitedToShare'
                defaultMessage='has invited you to share your Diversity Spend with them.'
              />
            </Text>
          }
          rightContent={
            <div>
              {buyer.get('status') === 'Invited' && (
                <>
                  <Button
                    onClick={() =>
                      onHandleAcceptInvitation(buyer.get('communityId'))
                    }
                    label={
                      <FormattedMessage
                        id='Tier2Invitation.AcceptButton'
                        defaultMessage='Accept'
                      />
                    }
                    autoSize
                    className='mr2'
                  />
                  <Button
                    onClick={() =>
                      onHandleDeclineInvitation(buyer.get('communityId'))
                    }
                    label={
                      <FormattedMessage
                        id='Tier2Invitation.DeclineButton'
                        defaultMessage='Decline'
                      />
                    }
                    autoSize
                    secondary
                  />
                </>
              )}
              {buyer.get('status') === 'Accepted' && (
                <Text>
                  <FormattedMessage
                    id='Tier2Invitation.Accepted'
                    defaultMessage='Accepted'
                  />
                </Text>
              )}
              {buyer.get('status') === 'Declined' && (
                <Text>
                  <FormattedMessage
                    id='Tier2Invitation.Declined'
                    defaultMessage='Declined'
                  />
                </Text>
              )}
            </div>
          }
        />
      </div>
    )

    return (
      <>
        <div className='mb2'>
          <Label>
            <FormattedMessage
              id='Tier2Invitation.Accepting'
              defaultMessage='Accepting customer invitations'
            />
          </Label>
        </div>
        <Text>
          <FormattedMessage
            id='Tier2Invitation.AllocationUponInviteAccept'
            defaultMessage='Accept invitations from your customers to share diversity data with them. You can then load spend data for periods, review it and allocate your diversity spend to customers. We only share summary diversity spend information with your customers after you complete this process.'
          />
        </Text>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {communities.has('Invited') && (
              <>
                <Label>
                  <FormattedMessage
                    id='Tier2Invitation.Invited'
                    defaultMessage='Invited'
                  />
                </Label>
                {communities.get('Invited')?.map(renderRow)}
              </>
            )}

            {communities.has('Accepted') && (
              <>
                <Label>
                  <FormattedMessage id='Tier2Invitation.Accepted' />
                </Label>
                {communities.get('Accepted')?.map(renderRow)}
              </>
            )}

            {communities.has('Declined') && (
              <>
                <Label>
                  <FormattedMessage id='Tier2Invitation.Declined' />
                </Label>
                {communities.get('Declined')?.map(renderRow)}
              </>
            )}
          </>
        )}
      </>
    )
  }
)

export default Tier2Invitation
