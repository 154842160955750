import React, { Component, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import ExternalLink from 'shared/components/ExternalLink'
import { List } from 'immutable'

type Props = {
  name: string
  description: string
  criticalCriteria: List<string>
  attachment: string
  isDeleted: boolean
}

class VetLinkedToTask extends Component<Props> {
  render() {
    const {
      name,
      description,
      criticalCriteria,
      attachment,
      isDeleted
    } = this.props

    return (
      <Fragment>
        <Typography variant='subtitle1'>Name</Typography>
        <Typography color='textSecondary' paragraph>
          {name}
        </Typography>

        <Typography variant='subtitle1'>Summary</Typography>
        <Typography color='textSecondary' paragraph>
          {description}
        </Typography>

        {criticalCriteria && criticalCriteria.size > 0 && (
          <Fragment>
            <Typography variant='subtitle1'>Critical Criteria</Typography>
            <Typography color='textSecondary' paragraph>
              {criticalCriteria.map(crit => (
                <span key={crit}>
                  crit
                  <br />
                </span>
              ))}
            </Typography>
          </Fragment>
        )}

        {attachment && (
          <Fragment>
            <Typography variant='subtitle1'>Attachment</Typography>
            <Typography color='textSecondary' paragraph>
              <ExternalLink href={attachment}>{attachment}</ExternalLink>
            </Typography>
          </Fragment>
        )}

        {isDeleted && <div className='mt3'>This vet has been deleted</div>}
      </Fragment>
    )
  }
}

export default VetLinkedToTask
