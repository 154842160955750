import React, {
  Component,
  ReactElement,
  forwardRef,
  ComponentProps
} from 'react'
import Link from '../Link'
import MenuItem from 'shared/components/MenuItem'
import { FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/core'

type Props = {
  label: ReactElement<FormattedMessage> | string
  linkTo?: string
  onClick?: (e: any) => void
  linkParams?: { [key: string]: string }
  focus?: boolean
  classes?: any
}

const MenuItemLink = forwardRef<HTMLAnchorElement, ComponentProps<typeof Link>>(
  (props, ref) => <Link innerRef={ref} {...props} />
)

const styles = theme => ({
  hoverState: {
    '&:focus-visible, &:hover': {
      color: 'white',
      backgroundColor: '#008584'
    }
  }
})

class UserNavItem extends Component<Props> {
  render() {
    const { linkTo, linkParams, label, onClick, focus, classes } = this.props

    const linkProps = linkTo
      ? {
          to: linkTo,
          component: MenuItemLink,
          params: linkParams
        }
      : {}

    return (
      <MenuItem
        className={`f7 no-underline hover-teal ${classes.hoverState}`}
        style={{
          paddingTop: 4,
          paddingBottom: 4
        }}
        onClick={onClick}
        autoFocus={!!focus ? focus : false}
        {...linkProps}
      >
        {typeof label === 'string' ? (
          <span className='black'>{label}</span>
        ) : (
          label
        )}
      </MenuItem>
    )
  }
}

export default withStyles(styles)(UserNavItem)
