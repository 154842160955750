import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import Certificate from 'supplier/Survey/components/Certificate'
import { RecordOf } from 'immutable'
import Certification from 'shared/models/Certification'
import profileSelectors from '../../selectors/profileSelectors'

type Props = {
  certTimeStamp: string
}

export default connect((state: RootState, props: Props) => {
  const certificate: RecordOf<Certification> = profileSelectors.getCertificationByTimeStamp(
    state,
    props.certTimeStamp
  )
  return {
    certificate
  }
})(Certificate)
