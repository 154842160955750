import { defineMessages } from 'react-intl'

const classificationCodeSchemas = defineMessages({
  naics: {
    id: 'ClassificationCodeSchemas.NAICS',
    defaultMessage: 'NAICS Code'
  },
  unspsc: {
    id: 'ClassificationCodeSchemas.UNSPSC',
    defaultMessage: 'UNSPSC Code'
  }
})

export default classificationCodeSchemas
