import { call, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import suppliers from 'shared/utils/api/suppliers'
import cards from 'shared/utils/api/cards'
import { notify } from 'shared/actions'
import supplier from 'shared/utils/api/supplier'

export function* createOrgSaga(action) {
  const {
    websiteUrl,
    name,
    email,
    orgUnitId,
    ignoreMatchingOrgs
  } = action.payload.toJS()

  // see if the entered domain belongs to an existing org unit
  let foundOrgUnits
  if (!orgUnitId && websiteUrl && !ignoreMatchingOrgs) {
    foundOrgUnits = yield call(supplier.getBuyerByDomain, websiteUrl)
  }

  let newOrgUnit
  if (!orgUnitId && (!foundOrgUnits || !foundOrgUnits.found)) {
    // create the supplier
    newOrgUnit = yield call(suppliers.create, {
      name,
      websiteUrl
    })

    if (newOrgUnit.status === false) {
      yield put(
        notify({
          message: newOrgUnit.message
        })
      )
    }
  }

  let notificationMessage
  if (email && (!foundOrgUnits || !foundOrgUnits.found)) {
    // create the card for the user and the new org
    yield call(cards.createOnBehalf, {
      email,
      orgId: newOrgUnit ? newOrgUnit.id : orgUnitId || foundOrgUnits.names[0].id
    })
    notificationMessage = `Organization "${name}" and a card for "${email}" were created`
  } else if (newOrgUnit) {
    notificationMessage = `The organization "${name}" has been created`
  }

  yield put(
    notify({
      message: notificationMessage
    })
  )

  return yield {
    matchingOrgs: foundOrgUnits && foundOrgUnits.found && foundOrgUnits.names
  }
}

export default createApiRequestSaga(createOrgSaga, {
  dontResetForm: true
})
