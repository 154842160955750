import React, { useState, useRef } from 'react'
import Label from 'shared/components/Label'
import ImageFileInput from 'shared/components/ImageFileInput'
import { useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import fileToUrl from 'shared/utils/data/fileToUrl'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import Text from 'shared/components/Text'

const CustomLogoInput = () => {
  const customLogo = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'logoFile'])
  )
  const newLogoFile = useRef<string | File | Blob | null>()
  const [submitting, setSubmitting] = useState<boolean>(false)

  return (
    <>
      <Label>Upload a Custom Logo</Label>
      <ImageFileInput
        cropHeight={160}
        cropWidth={480}
        logo
        submitOnCrop
        imageUrl={customLogo && fileToUrl(customLogo)}
        actionButtonLabel='Crop &amp; Save'
        onChange={file => {
          newLogoFile.current = file
        }}
        onCrop={() => {
          setSubmitting(true)
          api
            .postFile(`${apiRoutes.authService}/buyer/logo`, {
              logo: newLogoFile.current
            })
            .catch(console.error)
            .then(() => {
              newLogoFile.current = ''
              setSubmitting(false)
            })
        }}
      />
      {submitting && <Text>submitting...</Text>}
    </>
  )
}

export default CustomLogoInput
