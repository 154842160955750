import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { loadTier2SupplierList } from '../../store/actions'
import { useDispatch } from 'react-redux'
import insightsSelectors from '../../store/insightsSelectors'
import { searchTier2Suppliers } from '../../store/actions'
import Tier2InviteSupplier from '../../components/Tier2InviteSupplier'
import { fromJS, RecordOf, List } from 'immutable'
import { inviteToCommunities } from 'buyer/Communities/actions'

import InviteToTier2Dialog from '../../../shared/components/InviteToTier2Dialog'

type Props = {
  tier2Community: List<RecordOf<{ id: string }>>
}
export const Tier2InviteSupplierContainer = ({ tier2Community }: Props) => {
  const [companyName, setCompanyName] = useState<string>('')
  const [inviteSupplierDialogOpen, setInviteSupplierDialogOpen] = useState<
    boolean
  >(false)
  const [orgUnit, setOrgUnit] = useState<
    RecordOf<{
      id: string
      logo: string
      name: string
      domains: List<string>
    }>
  >(fromJS({}))

  const dispatch = useDispatch()

  useEffect(() => {
    if (!!tier2Community.size) {
      dispatch(loadTier2SupplierList())
    }
  }, [dispatch, tier2Community.size])

  const isLoading = useSelector(insightsSelectors.isLoadingSupplierList)
  const tier2Suppliers = useSelector(insightsSelectors.getTier2Suppliers)
  const loadingSuggestions = useSelector(
    insightsSelectors.isLoadingLookUpSuggestions
  )
  const suggestQuery = useSelector(insightsSelectors.getQueryString)
  const suggestedSuppliers = useSelector(
    insightsSelectors.getTier2SupplierSearchResultsCleaned
  )
  const handleClickSuggestedOrgUnit = useCallback(orgUnit => {
    setCompanyName('')
    setInviteSupplierDialogOpen(true)
    setOrgUnit(orgUnit)
  }, [])

  const handleInvite = useCallback(() => {
    const tier2CommId = tier2Community && tier2Community.getIn([0, 'id'])
    dispatch(
      inviteToCommunities(
        fromJS({
          supplierId: orgUnit.get('id'),
          communities: { [tier2CommId]: true },
          tier2: true
        })
      )
    )
    setInviteSupplierDialogOpen(false)
    setOrgUnit(fromJS({}))
  }, [dispatch, tier2Community, orgUnit])

  const handleCompanyNameChange = useCallback(
    companyName => {
      setCompanyName(companyName)
      if (companyName.length > 2) {
        dispatch(searchTier2Suppliers(companyName))
      }
    },
    [dispatch]
  )
  const handleCloseTier2Dialog = () => setInviteSupplierDialogOpen(false)
  const handleOnClose = useCallback(() => {
    setCompanyName('')
  }, [])

  const open = !!companyName && !!suggestQuery

  return (
    <>
      <Tier2InviteSupplier
        tier2Suppliers={tier2Suppliers}
        isLoading={isLoading}
        loadingSuggestions={loadingSuggestions}
        suggestedSuppliers={suggestedSuppliers}
        suggestQuery={suggestQuery}
        onClose={handleOnClose}
        onHandleClickSuggestedOrgUnit={handleClickSuggestedOrgUnit}
        open={open}
        onChangeCompanyName={handleCompanyNameChange}
        companyName={companyName}
      />

      <InviteToTier2Dialog
        orgUnit={orgUnit}
        inviteSupplierDialogOpen={inviteSupplierDialogOpen}
        onHandleCloseTier2Dialog={handleCloseTier2Dialog}
        onHandleInvite={handleInvite}
      />
    </>
  )
}

export default Tier2InviteSupplierContainer
