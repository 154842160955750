import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../Button'

/**
 * The submit button for a form
 */

type Props = {
  label: string
  additionalClass?: string
  handleDisabled?: boolean
  onClick?: () => void
}

class ProfileSubmitButton extends Component<Props> {
  static defaultProps = {
    label: (
      <FormattedMessage
        id='ProfileSubmitButton.SaveButton'
        defaultMessage='Save'
      />
    )
  }
  render() {
    const { label, onClick, additionalClass, handleDisabled } = this.props
    return (
      <Button
        type='submit'
        onClick={onClick}
        disabled={handleDisabled}
        size='small'
        autoSize
        className={`ml2 ${additionalClass}`}
      >
        {label}
      </Button>
    )
  }
}

export default ProfileSubmitButton
