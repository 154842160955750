import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Column } from 'react-virtualized'
import Table from 'shared/components/Table'
import InsightTitle from 'shared/components/InsightTitle'
import startCase from 'lodash.startcase'
import Button from 'shared/components/Button'
import exportData from 'shared/utils/exportData'
import { RecordOf, List } from 'immutable'

type Props = {
  stat: string
  value: List<RecordOf<{ internalCategory: string; numSuppliers: number }>>
}
const CategoriesTable = ({ stat, value }: Props) => {
  const tableExportTitle =
    stat !== 'categories1'
      ? 'Categories with 10 or more Suppliers'
      : 'Categories with 1 Supplier'
  return (
    <div key={stat}>
      <div className='flex mt4 justify-between items-center mt4'>
        <InsightTitle>{startCase(stat)}</InsightTitle>
        <Button
          onClick={() => {
            exportData.exportCSV(value.toJS(), `${tableExportTitle}`, true)
          }}
        >
          <FormattedMessage id='InternalStats.Export' defaultMessage='Export' />
        </Button>
      </div>
      <div className='bg-white br1 ba b--black-10'>
        <Table
          rowGetter={({ index }) => value && !!value.size && value.get(index)}
          rowCount={value.size}
        >
          <Column
            label={
              <FormattedMessage
                id='InternalStats.internalCategory'
                defaultMessage='Internal Category'
              />
            }
            width={400}
            dataKey='internalCategory'
          />
          {stat !== 'categories1' && (
            <Column
              label={
                <FormattedMessage
                  id='InternalStats.numSuppliers'
                  defaultMessage='Number of Suppliers'
                />
              }
              width={150}
              dataKey='numSuppliers'
            />
          )}
        </Table>
      </div>
    </div>
  )
}

export default CategoriesTable
