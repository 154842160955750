import React from 'react'
import AppBarContainer from 'shared/containers/AppBarContainer'
import UserNavContainer from 'shared/containers/UserNavContainer'
import { NavLink } from 'shared/components/AppBar'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { useSelector } from 'react-redux'
import paths from '../../routes'
import RootState from 'shared/models/RootState'

const JobsAppBar = () => {
  const canLoadJob = useSelector((state: RootState) =>
    sessionSelectors.userHasRole(state, ['serverAdmin', 'orgAdmin'])
  )
  const isClickWorker = useSelector((state: RootState) =>
    sessionSelectors.userHasRole(state, 'clickWorker')
  )
  return (
    <AppBarContainer
      userNav={<UserNavContainer hideMyAccount />}
      homeUrl={paths.basePath}
      hideMenuNav={!canLoadJob}
    >
      <div className='flex-auto' />
      {canLoadJob && <NavLink to={paths.loader} label={'Loader'} />}
      {canLoadJob && <NavLink to={paths.results} label={'Results'} />}
      {(isClickWorker || canLoadJob) && (
        <NavLink to={paths.jobs} label={'Jobs'} />
      )}
    </AppBarContainer>
  )
}

export default JobsAppBar
