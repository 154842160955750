import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

export const createPost = createAction(actionTypes.CREATE_POST)
export const loadPosts = createAction(actionTypes.LOAD_POSTS)
export const upVote = createAction(
  actionTypes.UP_VOTE,
  (payload, meta) => payload,
  (payload, meta) => meta
)
export const clearVote = createAction(actionTypes.CLEAR_VOTE)
export const deletePost = createAction(actionTypes.DELETE_POST)
export const deleteComment = createAction(actionTypes.DELETE_POST_COMMENT)
export const markPostRead = createAction(actionTypes.MARK_POST_READ)
export const clearUnread = createAction(actionTypes.CLEAR_UNREAD)
export const getRecentSuppliers = createAction(actionTypes.GET_RECENT_SUPPLIERS)
export const loadPostsByTag = createAction(actionTypes.LOAD_POST_BY_TAG)
export const getRecentSuppliersTags = createAction(
  actionTypes.GET_RECENT_SUPPLIERS_TAGS
)
export const getSupplierCommentsAndTagsForExport = createAction(
  actionTypes.GET_SUPPLIER_COMMENTS_AND_TAGS_FOR_EXPORT
)
