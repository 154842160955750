import React from 'react'
import NavBar from 'shared/components/NavBar'
import NavTab from 'shared/components/NavTab'
import { useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import CompletedSuppliers from './CompletedSuppliers'
import OpenSuppliers from './OpenSuppliers'

const SupplierRequests = () => {
  const location = useLocation()
  const showComplete = location.search !== '?show=open'

  return (
    <div className='pv3'>
      <NavBar style={{ paddingBottom: 0 }}>
        <NavTab
          to={`${location.pathname}?show=complete`}
          isActive={(match, loc) =>
            !loc.search || loc.search === `?show=complete`
          }
          backgroundColor='white'
        >
          <FormattedMessage
            id='SupplierRequests.SustainabilityComplete'
            defaultMessage='Supplier Survey Completion Summary'
          />
        </NavTab>
        <NavTab
          to={`${location.pathname}?show=open`}
          isActive={(match, loc) => loc.search === `?show=open`}
          backgroundColor='white'
        >
          <FormattedMessage
            id='SupplierRequests.SustainabilityOpen'
            defaultMessage='Sustainability Survey Open Requests'
          />
        </NavTab>
      </NavBar>
      <div className='b--black-10 ba pa3' style={{ marginTop: -1 }}>
        {showComplete && <CompletedSuppliers />}
        {!showComplete && <OpenSuppliers />}
      </div>
    </div>
  )
}

export default SupplierRequests
