import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import parseRelationship from '../../data/parseRelationship'
import parseAttachment from '../../data/parseRelationshipAttachment'
import attachmentReverseParser from '../../data/relationshipAttachment/reverseParser'

const apiRoute = `${apiRoutes.authService}/relationships`

export const create = (supplierId, relationship) => {
  return api
    .post(`${apiRoute}`, {
      ...relationship,
      supplier: supplierId
    })
    .then(parseRelationship)
}

export const update = (relationshipId, relationshipChanges) => {
  return api.put(`${apiRoute}/${relationshipId}`, relationshipChanges)
}

export const deleteRelationship = relationshipId => {
  return api.remove(`${apiRoute}/${relationshipId}`)
}

export const addAttachment = (relationshipId, attachment) => {
  return api
    .postFile(
      `${apiRoute}/${relationshipId}/attachment`,
      attachmentReverseParser(attachment)
    )
    .then(parseAttachment)
}

export const updateAttachment = (relationshipId, attachment) => {
  return api
    .putFile(
      `${apiRoute}/${relationshipId}/attachment`,
      attachmentReverseParser(attachment)
    )
    .then(parseAttachment)
}

export const deleteAttachment = (relationshipId, attachment) => {
  return api.remove(
    `${apiRoute}/${relationshipId}/attachment`,
    attachmentReverseParser(attachment)
  )
}

export const getBySupplier = supplierId => {
  return api.get(`${apiRoute}/supplier`, {
    supplierId
  })
}

export const transferSpend = ({
  transferFromRelationshipId,
  internalSupplierId,
  transferToOrgUnitId
}) => {
  return api
    .post(
      `${apiRoute}/admin/transferRelationship/${transferFromRelationshipId}/internalid/${internalSupplierId}/to/${transferToOrgUnitId}`
    )
    .then(res => {
      return {
        transferFromRelationshipId,
        internalSupplierId,
        transferToOrgUnitId
      }
    })
}
