import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { List, RecordOf, fromJS } from 'immutable'
import PageSection from 'shared/components/PageSection'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import RootState from 'shared/models/RootState'
import { createSelector } from 'reselect'
import usersSelectors from 'shared/selectors/usersSelectors'
import VetQuestion from './VetQuestion'
import parseNameFromUser from 'shared/utils/parseNameFromUser'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

type Props = {
  questions: List<
    RecordOf<{
      id: string
      userProfilePictureUrl: string
      userName: string
      dateCreated: string
      description: string
      response?: string
      responseIsPublic?: boolean
      canEdit?: boolean
      responseDate?: string
    }>
  >
}

export const VetQuestions: FunctionComponent<Props> = props => {
  if (!props.questions || props.questions.size < 1) {
    return null
  }

  return (
    <PageSection
      noPadding
      title={
        <FormattedMessage
          id='supplier/VetQuestions.QA'
          defaultMessage='Questions &amp; Answers'
        />
      }
    >
      {props.questions.map(question => (
        <VetQuestion
          key={question.get('id')}
          id={question.get('id')}
          userProfilePictureUrl={question.get('userProfilePictureUrl')}
          userName={question.get('userName')}
          dateCreated={question.get('dateCreated')}
          description={question.get('description')}
          response={question.get('response')}
          responseIsPublic={question.get('responseIsPublic')}
          responseDate={question.get('responseDate')}
          canEdit={question.get('canEdit')}
        />
      ))}
    </PageSection>
  )
}

type QuestionRecord = RecordOf<{
  id: string
  question: string
  response: RecordOf<{
    response: string
    isPublic: boolean
    created: RecordOf<{
      user: string
      date: string
    }>
  }>
  createdInOrgUnit: string
  created: RecordOf<{
    user: string
    date: string
  }>
}>

const getQuestions = createSelector(
  state => state.getIn(['supplier', 'currentVet', 'vetQuestions']),
  usersSelectors.getUsers,
  sessionSelectors.getUserId,
  sessionSelectors.getOrgUnitId,
  (
    questions: List<QuestionRecord> = fromJS([]),
    allUsers,
    currentUserId,
    currentOrgUnitId
  ) =>
    questions
      .sort((q1, q2) =>
        moment(q1.getIn(['created', 'date'])).isAfter(
          moment(q2.getIn(['created', 'date']))
        )
          ? 1
          : -1
      )
      .map(question => {
        const user =
          question.get('createdInOrgUnit') === currentOrgUnitId &&
          allUsers.get(question.getIn(['created', 'user']))

        return fromJS({
          id: question.get('id'),
          userProfilePictureUrl: user && user.get('profilePictureUrl'),
          userName:
            user &&
            parseNameFromUser(
              user.get('email'),
              user.get('firstName'),
              user.get('lastName')
            ),
          dateCreated: question.getIn(['created', 'date']),
          description: question.get('question'),
          response: question.getIn(['response', 'response']),
          responseIsPublic: question.getIn(['response', 'isPublic']),
          responseDate: question.getIn(['response', 'created', 'date']),
          canEdit:
            currentUserId === question.getIn(['created', 'user']) &&
            !question.getIn(['response', 'response'])
        })
      })
)

export default connect((state: RootState) => ({
  questions: getQuestions(state)
}))(VetQuestions)
