import React, { MouseEvent, useState, ReactNode } from 'react'
import Text from 'shared/components/Text'
import { SortableHandle } from 'react-sortable-hoc'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import HandleElement from './HandleElement'
import OrgUnit from './OrgUnit'

const DragHandleElement = SortableHandle(HandleElement)

type Props = {
  orgUnitId: string
  rowNum?: number
  isSelected?: boolean
  isDisabled: boolean
  name: string
  logoUrl?: string
  onClick?: () => void
  onMenuDeprioritize?: (
    ele: HTMLElement,
    orgUnitId: string,
    rowNum: number
  ) => void
  onMenuPrioritize?: (ele: HTMLElement, orgUnitId: string) => void
  oldRowNum?: number
  children?: ReactNode
  hideHandle?: boolean
}

const SowSideOrg = (props: Props) => {
  const {
    orgUnitId,
    rowNum,
    name,
    logoUrl,
    isSelected,
    isDisabled,
    onClick,
    onMenuDeprioritize,
    onMenuPrioritize,
    oldRowNum,
    children,
    hideHandle
  } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleDeprioritizeClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (!!anchorEl && onMenuDeprioritize && rowNum !== undefined) {
      onMenuDeprioritize(anchorEl, orgUnitId, rowNum)
    }
    setAnchorEl(null)
  }

  const handlePrioritizeClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (!!anchorEl && onMenuPrioritize) {
      onMenuPrioritize(anchorEl, orgUnitId)
    }
    setAnchorEl(null)
  }

  return (
    <div
      aria-label='supplier'
      className={`bg-white mv3 ${
        isDisabled || !onClick ? '' : 'pointer underline-hover'
      }`}
      onClick={onClick}
    >
      {!hideHandle && (
        <DragHandleElement
          isSelected={!!isSelected}
          noDrag={isDisabled || !onClick}
          isInvisible={!onClick}
        />
      )}
      <div className='flex items-center justify-between'>
        <div className='flex-auto'>
          <OrgUnit logoUrl={logoUrl} name={name} />
          {children && <div>{children}</div>}
        </div>
        {(onMenuDeprioritize || onMenuPrioritize) && (
          <>
            <IconButton
              aria-label='menu'
              onClick={(e: MouseEvent<HTMLElement>) =>
                setAnchorEl(e.currentTarget)
              }
              disabled={isDisabled}
            >
              <MoreVertIcon fontSize='small' />
            </IconButton>
          </>
        )}
        {rowNum !== undefined && (
          <div
            className={
              oldRowNum !== undefined ? 'w3 flex items-center justify-end' : ''
            }
          >
            {oldRowNum !== undefined && oldRowNum !== rowNum && (
              <>
                <div
                  className='f9 white bg-gray br-pill w1 h1 flex items-center justify-center mr2 mr0-l'
                  style={{ minWidth: '1rem' }}
                >
                  {oldRowNum + 1}
                </div>
                &rArr;
              </>
            )}
            <div
              className='f9 white bg-orange br-pill w1 h1 flex items-center justify-center mr2 mr0-l'
              style={{ minWidth: '1rem' }}
            >
              {rowNum + 1}
            </div>
          </div>
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {onMenuDeprioritize && (
          <MenuItem onClick={handleDeprioritizeClick} aria-label='deprioritize'>
            <Text>Deprioritize</Text>
          </MenuItem>
        )}
        {onMenuPrioritize && (
          <MenuItem onClick={handlePrioritizeClick} aria-label='prioritize'>
            <Text>Prioritize</Text>
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default SowSideOrg
