import { call, put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import vets from 'shared/utils/api/vets'
import paths from '../../routes/paths'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { addVetSupplier } from '../../actions'
import parsePath from 'shared/utils/parsePath'
import Immutable from 'immutable'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'

export function* startVetSaga(action) {
  // action may contain payload with list of supplier ids
  // { suppliers: Array }

  const { suppliers, queryString, ...rest } = Immutable.Iterable.isIterable(
    action.payload
  )
    ? action.payload.toJS()
    : action.payload

  const buyerTermsAndConditions = yield select(
    settingsSelectors.getField,
    'buyerTermsAndConditions'
  )

  const newVet = yield call(vets.createNewVet, {
    ...rest,
    vetDetails: {
      termsAndConditionsSource: buyerTermsAndConditions ? 'Buyer' : 'Default',
      termsAndConditions: buyerTermsAndConditions
    }
  })

  if (suppliers && suppliers.length > 0) {
    yield put(addVetSupplier({ vetId: newVet.id, supplierIds: suppliers }))
  }

  yield put(push(parsePath(paths.vetDetails, { vetId: newVet.id })))

  return yield { newVet, queryString }
}

export default createApiRequestSaga(startVetSaga)
