import React, { Component } from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import { FormattedMessage } from 'react-intl'
import { Map } from 'immutable'
import qs from 'qs'

type Props = {
  vetId: string
  queryString: string
  redirectFrom: string
  vetBuyerSupplierId: string
  pathname: string
  communicationIsEnabled?: boolean
  defaultFilters: Map<string, any>
}

class VetNavBar extends Component<Props> {
  render() {
    const {
      vetId,
      queryString,
      redirectFrom,
      vetBuyerSupplierId,
      defaultFilters
    } = this.props
    const pathToSearch = parsePath(paths.vetSearch, { vetId })
    const pathToDetails = parsePath(paths.vetDetails, { vetId })
    const pathToInvitation = parsePath(paths.vetInvitation, { vetId })
    const pathToConversation = parsePath(paths.vetInbox, {
      vetId,
      vetBuyerSupplierId
    })
    const pathToActivities = parsePath(paths.vetActivities, { vetId })
    const pathToQA = paths.vetQA.replace(':vetId', vetId)
    const defaultFiltersQS = qs.stringify({
      filter: defaultFilters && defaultFilters.toJS()
    })
    const qString = queryString || `?${defaultFiltersQS}`

    return (
      <NavBar>
        <NavLink
          label={
            <FormattedMessage id='VetNavBar.Details' defaultMessage='Details' />
          }
          to={`${pathToDetails}${
            redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
          }`}
          isActive={() => this.props.pathname === pathToDetails}
        />
        <NavLink
          label={
            <FormattedMessage
              id='VetNavBar.Suppliers'
              defaultMessage='Suppliers'
            />
          }
          to={`${pathToSearch}${qString}${
            redirectFrom
              ? `${qString ? '&' : '?'}redirectFrom=${redirectFrom}`
              : ''
          }`}
          isActive={() => this.props.pathname.includes(pathToSearch)}
        />
        <NavLink
          label={
            <FormattedMessage
              id='VetNavBar.Invitation'
              defaultMessage='Invitation'
            />
          }
          to={`${pathToInvitation}${
            redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
          }`}
          isActive={() =>
            this.props.pathname.includes(
              parsePath(paths.vetInvitation, { vetId })
            )
          }
        />

        {this.props.communicationIsEnabled && (
          <NavLink
            label={
              <FormattedMessage
                id='VetNavBar.QandA'
                defaultMessage='Q &amp; A'
              />
            }
            to={`${pathToQA}${
              redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
            }`}
            isActive={() => this.props.pathname.includes(pathToQA)}
          />
        )}

        <NavLink
          label={
            <FormattedMessage
              id='VetNavBar.Conversation'
              defaultMessage='Review'
            />
          }
          to={`${pathToConversation}${
            redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
          }`}
          isActive={() =>
            this.props.pathname.includes(parsePath(paths.vetInbox, { vetId }))
          }
        />
        <NavLink
          label={
            <FormattedMessage
              id='VetNavBar.Activities'
              defaultMessage='Activities'
            />
          }
          to={`${pathToActivities}${
            redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
          }`}
          isActive={() =>
            this.props.pathname.includes(
              parsePath(paths.vetActivities, { vetId })
            )
          }
        />
      </NavBar>
    )
  }
}

export default VetNavBar
