import React, { Component, Fragment } from 'react'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import UserListItem from 'shared/components/UserListItem'
import dateFormat from 'shared/utils/dateFormat'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Button from 'shared/components/Button'
import Link from 'shared/components/Link'
import { List } from 'immutable'
import truncate from 'lodash.truncate'
import removeMarkdown from 'remove-markdown'
import { FormattedMessage } from 'react-intl'
import 'intersection-observer'
import Observer from 'react-intersection-observer'
import posts from 'shared/utils/api/posts'
import Linkify from 'react-linkify'
import Divider from 'shared/components/Divider'
import scrollTo from 'shared/utils/scrollTo'
import PostBody from './PostBody'

const styles = {
  wrapper: 'bg-white bt bl br b--black-10 ph3-5 pb3-5 pt1 br--top',
  image: 'mt3',
  title: 'lh-copy f5 fw6',
  body: 'mt2',
  toggleViewLess: 'pa0-ns mt3'
}

const PREVIEW_LENGTH = 80

type Props = {
  id: string
  orgName: string
  firstName: string
  lastName: string
  role: string
  profilePictureUrl: string
  createdDate: string
  title: string
  body: string
  tags: List<string>
  imageUrl: string
  orgProfileUrl: string
  hasDeleteAccess: boolean
  userId: string
  currentUserId: string
  currentUserEmail: string
  currentUserName: string
  email: string
  profileUrl: string
  communityName: string
  audience: string
  onDeleteClick: (arg: string) => void
  onPostRead: (arg: string) => void
  onReportClick: ({
    action,
    postId,
    postTitle,
    postBody,
    postDate,
    postUserId,
    postUserName,
    postUserEmail,
    postOrganization,
    reportUserId,
    reportUserEmail,
    reportUserName: string
  }) => void
  push: (path: string) => void
}

type State = {
  showFullPost: boolean
  isReportDialogOpen: boolean
  isDeleteDialogOpen: boolean
}
class PostItem extends Component<Props, State> {
  state = {
    showFullPost: false,
    isReportDialogOpen: false,
    isDeleteDialogOpen: false
  }

  shouldShowViewMore() {
    const { body } = this.props
    const { showFullPost } = this.state
    return body.length > PREVIEW_LENGTH && !showFullPost
  }

  truncateBody() {
    const { body } = this.props
    const splitBody = removeMarkdown(body)
      // Markdown does not support having the signs ++
      .replace(/[+{2}\\]/g, '')
      .split('\n')

    return splitBody.length > 1
      ? splitBody[0].length > PREVIEW_LENGTH
        ? truncate(splitBody[0], {
            length: PREVIEW_LENGTH,
            separator: /,? +/
          })
        : `${splitBody[0]}...`
      : truncate(splitBody[0], {
          length: PREVIEW_LENGTH,
          separator: /,? +/
        })
  }

  toggleViewMore = () => {
    const { onPostRead, id } = this.props
    const { showFullPost } = this.state

    if (!showFullPost) {
      onPostRead(id)
    }

    this.setState({
      showFullPost: !showFullPost
    })
  }

  handleDeletePost = () => {
    const { id, onDeleteClick } = this.props
    onDeleteClick(id)
  }

  handleReportPost = () => {
    const {
      id,
      title,
      body,
      createdDate,
      userId,
      currentUserId,
      currentUserEmail,
      currentUserName,
      onReportClick,
      orgName,
      firstName,
      lastName,
      email
    } = this.props
    onReportClick({
      action: 'Report on post',
      postId: id,
      postTitle: title,
      postBody: body,
      postDate: createdDate,
      postUserId: userId,
      postUserName: `${firstName} ${lastName}`,
      postUserEmail: email,
      postOrganization: orgName,
      reportUserId: currentUserId,
      reportUserEmail: currentUserEmail,
      reportUserName: currentUserName
    })

    this.setState({
      isReportDialogOpen: true
    })
  }

  handleDialogClose = () => {
    this.setState({
      isReportDialogOpen: false,
      isDeleteDialogOpen: false
    })
  }

  confirmDeletion = () => {
    this.setState({
      isDeleteDialogOpen: true
    })
  }

  handleDeleteConfirmed = () => {
    this.handleDeletePost()
    this.setState({
      isDeleteDialogOpen: false
    })
  }

  handleTagClick = (tag: string) => {
    const { push } = this.props
    if (push) {
      push(tag)
      scrollTo(null, 0)
    }
  }

  render() {
    const {
      id,
      title,
      body,
      tags,
      imageUrl,
      createdDate,
      orgName,
      firstName,
      lastName,
      role,
      profilePictureUrl,
      orgProfileUrl,
      profileUrl,
      communityName,
      audience,
      hasDeleteAccess,
      onPostRead
    } = this.props

    const bodyIsEmpty =
      !body ||
      // react-rte adds spaces instead of empty values
      // see https://github.com/sstur/react-rte/issues/277
      body.replace(/\n/g, '').length === 1
    const isSelfCertifyPost = tags && tags.includes('#selfcertification')
    return (
      <Observer
        tag='div'
        triggerOnce
        threshold={0.5}
        onChange={inView => {
          if (inView) {
            posts.putPostImpression(id).catch(console.log)
            if (!this.shouldShowViewMore()) {
              onPostRead(id)
            }
          }
        }}
      >
        <div className={styles.wrapper}>
          <UserListItem
            profileUrl={profileUrl}
            firstName={firstName}
            lastName={lastName}
            profilePictureUrl={profilePictureUrl}
            secondaryText={
              <div>
                <Text>
                  {role}&nbsp;
                  <FormattedMessage id='PostItem.AtText' defaultMessage='at' />
                  &nbsp;
                  {orgProfileUrl ? (
                    <Link to={orgProfileUrl}>{orgName}</Link>
                  ) : (
                    orgName
                  )}
                  {communityName && (
                    <Fragment>
                      &nbsp;
                      <FormattedMessage
                        id='PostItem.ToText'
                        defaultMessage='to'
                      />
                      &nbsp;{communityName}
                    </Fragment>
                  )}
                  &nbsp;{audience}
                </Text>
                <Text>{dateFormat(createdDate)}</Text>
              </div>
            }
            rightButton={
              <DropDownMenu>
                {hasDeleteAccess && (
                  <MenuItem onClick={this.confirmDeletion}>
                    <FormattedMessage
                      id='PostItem.DeletePostMenueItem'
                      defaultMessage='Delete post'
                    />
                  </MenuItem>
                )}
                <MenuItem onClick={this.handleReportPost}>
                  <FormattedMessage
                    id='PostItem.ReportPostMenueItem'
                    defaultMessage='Report post'
                  />
                </MenuItem>
              </DropDownMenu>
            }
          />
          {title && <Label className={styles.title}>{title}</Label>}
          {!bodyIsEmpty && (
            <div>
              {isSelfCertifyPost ? (
                <PostBody body={body} isSelfCertification />
              ) : this.shouldShowViewMore() ? (
                <Text className={styles.body}>
                  <Linkify>{this.truncateBody()}</Linkify>
                  <Button
                    autoSize
                    size='small'
                    variant='text'
                    label={
                      <FormattedMessage
                        id='PostItem.ViewMoreButton'
                        defaultMessage='Read more'
                      />
                    }
                    onClick={this.toggleViewMore}
                  />
                </Text>
              ) : (
                <PostBody body={body} />
              )}
            </div>
          )}
          {imageUrl && (
            <div className='tc'>
              <img
                src={imageUrl}
                alt=''
                className={styles.image}
                style={{ maxWidth: '100%' }}
              />
            </div>
          )}
          {tags && (
            <>
              <Divider className='mv2' />
              <Text>
                {tags.map(tag => (
                  <span
                    key={tag}
                    className='teal pointer dim mr2'
                    onClick={() => this.handleTagClick(tag)}
                  >
                    {tag}
                  </span>
                ))}
              </Text>
            </>
          )}
          <Dialog
            onClose={this.handleDialogClose}
            open={this.state.isReportDialogOpen}
            fullWidth
          >
            <DialogTitle>
              <FormattedMessage
                id='PostItem.ReportAPostTitle'
                defaultMessage='Report a post'
              />
            </DialogTitle>

            <DialogContent className='mt3'>
              <FormattedMessage
                id='PostItem.ThankYouForReportingPostDialog'
                defaultMessage='Thank you for submitting a report to TealBook. Our team will be reviewing it as soon as possible.'
              />
            </DialogContent>

            <DialogActions>
              <Button
                autoSize
                label={
                  <FormattedMessage
                    id='PostItem.DoneDialog'
                    defaultMessage='Done'
                  />
                }
                onClick={this.handleDialogClose}
              />
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.isDeleteDialogOpen}
            onClose={this.handleDialogClose}
            fullWidth
          >
            <DialogTitle>
              <FormattedMessage
                id='PostItem.ConfirmationDialog'
                defaultMessage='Confirmation'
              />
            </DialogTitle>
            <DialogContent className='mt3'>
              <FormattedMessage
                id='PostItem.DeletingPostDialog'
                defaultMessage='Are you sure you want to delete your post?'
              />
            </DialogContent>
            <DialogActions>
              <Button
                label={
                  <FormattedMessage
                    id='PostItem.OKButton'
                    defaultMessage='OK'
                  />
                }
                onClick={this.handleDeleteConfirmed}
                autoSize
              />
              <Button
                label={<FormattedMessage id='CancelButton' />}
                onClick={this.handleDialogClose}
                secondary
                autoSize
              />
            </DialogActions>
          </Dialog>
        </div>
      </Observer>
    )
  }
}

export default PostItem
