import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select } from 'redux-saga/effects'
import cards from 'shared/utils/api/cards'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import profileSelectors from '../../selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import mapValues from 'lodash.mapvalues'
import parseCard from 'shared/utils/data/parseCard'
import { fromJS } from 'immutable'

export function* updateContactSaga(action) {
  const {
    user: { id: userId },
    contactFor,
    location,
    phone,
    isPrivate,
    connected,
    connectFor,
    supplierId
  } = action.payload.toJS()

  const ownerId = yield select(sessionSelectors.getUserId)

  const currentSupplierId = yield supplierId ||
    select(profileSelectors.getDataField, 'id')

  let currentCard = yield select(
    cardsSelectors.getBySupplier,
    currentSupplierId
  )

  // if my card is not in state, check the server
  if (!currentCard || !currentCard.get('id')) {
    const cardsById = mapValues(
      yield call(cards.getBySupplierId, currentSupplierId),
      parseCard
    )
    const cardsValue = Object.values(cardsById)

    currentCard =
      cardsValue.length > 0 && cardsValue.filter(card => card.owner === ownerId)
    if (!currentCard || currentCard.length === 0) {
      currentCard = fromJS({})
    } else {
      currentCard = fromJS(currentCard[0])
    }
  }

  let cardId = currentCard && currentCard.get('id')
  const contact = {
    contactFor,
    location,
    phone,
    isPrivate,
    connected,
    connectFor
  }
  const cleanContact = Object.keys(contact).reduce((result, prop) => {
    if (typeof contact[prop] !== 'undefined') {
      result[prop] = contact[prop]
    }
    return result
  }, {})

  const contacts = currentCard
    .mergeIn(['contacts', userId], {
      user: userId,
      ...cleanContact
    })
    .get('contacts')
    .toList()
    .toJS()

  // create a card if is not there yet
  let newCard
  if (
    !currentCard ||
    currentCard.size === 0 ||
    currentCard.get('placeholderCard') ||
    currentCard.get('id').endsWith('-1')
  ) {
    newCard = yield call(cards.create, {
      supplier: currentSupplierId,
      contacts: [
        {
          user: userId,
          ...cleanContact
        }
      ]
    })
    cardId = newCard.id
  } else {
    yield call(cards.update, cardId, {
      contacts
    })
  }

  return yield {
    userId,
    cardId,
    ownerId,
    ...cleanContact,
    newCard
  }
}

export default createApiRequestSaga(updateContactSaga)
