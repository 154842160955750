import React, { Component, Fragment } from 'react'
import { fromJS, RecordOf } from 'immutable'
import { connect } from 'react-redux'
import Switch from 'shared/components/Switch'
import Paper from 'shared/components/Paper'
import { FormattedMessage } from 'react-intl'
import currentVetSelectors from '../../../selectors/currentVetSelectors'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import Select from 'shared/components/Select'
import Button from 'shared/components/Button'
import TextArea from 'shared/components/TextArea'
import Divider from 'shared/components/Divider'
import { updateVet } from '../../../actions'
import Text from 'shared/components/Text'
import currentVetSuppliersSelectors from '../../../selectors/currentVetSuppliersSelectors'
import RootState from 'shared/models/RootState'

const defaultLineItem = {
  name: '',
  quantity: 1,
  UOM: 'each',
  description: ''
}

type VetPricingSectionProps = {
  id: string
  includePricing: boolean
}

type Props = {
  vet: RecordOf<VetPricingSectionProps>
  readonly: boolean
  updateVet: (arg: {
    vetId: string
    includePricing?: boolean
    pricing?: { currency?: string; lineItems?: string }
  }) => void
}

class PricingSection extends Component<Props> {
  state = {
    newLineItem: defaultLineItem
  }

  handleTogglePricing = () => {
    const { vet, updateVet } = this.props
    updateVet({
      vetId: vet.get('id'),
      includePricing: !vet.get('includePricing')
    })
  }

  handleChangePricingItem = e => {
    this.setState({
      newLineItem: {
        ...this.state.newLineItem,
        [e.currentTarget.name]: e.currentTarget.value
      }
    })
  }

  handleCurrencyChange = e => {
    const { vet, updateVet } = this.props
    updateVet({
      vetId: vet.get('id'),
      pricing: {
        currency: e.currentTarget.value
      }
    })
  }

  handlePricingLineItemChange = lineItem => changes => {
    const { vet, updateVet } = this.props
    const lineItems = vet.getIn(['pricing', 'lineItems']) || fromJS([])
    const isAddingNewItem = !lineItem || !lineItem.get('id')

    // if adding a new line item then set some defaults
    const newLineItem =
      isAddingNewItem &&
      fromJS({
        id: Math.random()
          .toString(36)
          .substr(2, 9),
        quantity: 1,
        UOM: 'each'
      }).merge(changes)

    const lineItemToUpdateIndex =
      !isAddingNewItem &&
      lineItems.findIndex(item => item.get('id') === lineItem.get('id'))

    updateVet({
      vetId: vet.get('id'),
      pricing: {
        lineItems: isAddingNewItem
          ? lineItems.push(newLineItem).toJS()
          : lineItems.mergeIn([lineItemToUpdateIndex], changes).toJS()
      }
    })
  }

  handleRemovePricingLineItem = lineItem => () => {
    const { vet, updateVet } = this.props
    const lineItems = vet.getIn(['pricing', 'lineItems']) || fromJS([])

    updateVet({
      vetId: vet.get('id'),
      pricing: {
        lineItems: lineItems.filter(
          item => item.get('id') !== lineItem.get('id')
        )
      }
    })
  }

  handleAddRequestItem = e => {
    e.preventDefault()
    const { vet, updateVet } = this.props
    const lineItems = vet.getIn(['pricing', 'lineItems']) || fromJS([])
    const newItem = fromJS({
      id: Math.random()
        .toString(36)
        .substr(2, 9)
    }).merge(this.state.newLineItem)

    updateVet({
      vetId: vet.get('id'),
      pricing: {
        lineItems: lineItems.push(newItem).toJS()
      }
    })

    this.setState({
      newLineItem: defaultLineItem
    })
  }

  renderPricingItem = lineItem => {
    const { readonly } = this.props

    return (
      <form onSubmit={this.handleAddRequestItem}>
        {!lineItem.get('id') && <Divider className='mv3' />}
        <div className='flex'>
          <div className='flex-auto mr3'>
            <div className='flex'>
              <div className='flex-auto'>
                {!lineItem.get('id') ? (
                  <FormattedMessage
                    id='PricingSection.NamePlaceholder'
                    defaultMessage='Nom'
                  >
                    {message => (
                      <Input
                        name='name'
                        value={lineItem.get('name')}
                        onChange={this.handleChangePricingItem}
                        required
                        placeholder={message}
                      />
                    )}
                  </FormattedMessage>
                ) : (
                  <Text>{lineItem.get('name')}</Text>
                )}
              </div>
              <div className='w-20 mh2'>
                {!lineItem.get('id') ? (
                  <FormattedMessage
                    id='PricingSection.QuantityPlaceholder'
                    defaultMessage='Quantity'
                  >
                    {message => (
                      <Input
                        name='quantity'
                        value={lineItem.get('quantity')}
                        onChange={this.handleChangePricingItem}
                        placeholder={message}
                        type='number'
                      />
                    )}
                  </FormattedMessage>
                ) : (
                  <Text>{lineItem.get('quantity')}</Text>
                )}
              </div>
              <div className='w-20'>
                {!lineItem.get('id') ? (
                  <Input
                    name='UOM'
                    value={lineItem.get('UOM')}
                    onChange={this.handleChangePricingItem}
                    placeholder='UOM'
                  />
                ) : (
                  <Text>{lineItem.get('UOM')}</Text>
                )}
              </div>
            </div>
            <div className='mv2'>
              {!lineItem.get('id') ? (
                <TextArea
                  name='description'
                  value={lineItem && lineItem.get('description')}
                  placeholder='Description'
                  onChange={this.handleChangePricingItem}
                />
              ) : (
                <Text>{lineItem.get('description')}</Text>
              )}
            </div>
          </div>
          {lineItem.get('id') && !readonly ? (
            <div>
              <Button
                label='Remove'
                onClick={this.handleRemovePricingLineItem(lineItem)}
                autoSize
                caution
                size='small'
              />
            </div>
          ) : (
            <div style={{ marginRight: 66 }} />
          )}
        </div>

        {!lineItem.get('id') && (
          <Button
            label={
              <FormattedMessage
                id='PricingSection.AddRequestItem'
                defaultMessage='Add Request Item'
              />
            }
            autoSize
            className='mt2'
            type='submit'
          />
        )}
      </form>
    )
  }

  render() {
    const { vet, readonly } = this.props
    const { newLineItem } = this.state
    const pricingLineItems = vet.getIn(['pricing', 'lineItems']) || fromJS([])

    if (readonly && !vet.get('includePricing')) {
      return null
    }

    return (
      <Fragment>
        <div className='pl3-5 pb2 flex pt4 items-center'>
          <span className='flex-auto f5-l f6 fw6 ma0 pb2'>
            <FormattedMessage
              id='VetInvitationsPage.Pricing'
              defaultMessage='Request Pricing'
            />
          </span>
          {!readonly && (
            <Switch
              checked={vet.get('includePricing')}
              onChange={this.handleTogglePricing}
            />
          )}
        </div>

        {vet.get('includePricing') && (
          <Paper>
            <Label>
              <FormattedMessage
                id='PricingSection.Currency'
                defaultMessage='Currency'
              />
            </Label>
            {!readonly ? (
              <Select
                fullWidth
                onChange={this.handleCurrencyChange}
                value={vet.getIn(['pricing', 'currency'])}
              >
                <FormattedMessage
                  id='PricingSection.selectCurrency'
                  defaultMessage='Please select a currency'
                >
                  {message => <option value=''>{message}</option>}
                </FormattedMessage>

                <option value='USD'>USD</option>
                <option value='CAD'>CAD</option>
                <option value='EUR'>EUR</option>
                <option value='GBP'>GBP</option>
              </Select>
            ) : (
              <Text>
                {vet.getIn(['pricing', 'currency']) || 'No currency selected'}
              </Text>
            )}

            {(!readonly || pricingLineItems.size > 0) && (
              <div className='flex'>
                <div className='flex-auto flex mr3'>
                  <div className='flex-auto'>
                    <Label>
                      <FormattedMessage
                        id='PricingSection.Name'
                        defaultMessage='Name'
                      />
                    </Label>
                  </div>
                  <div className='w-20 mh2'>
                    <Label>
                      <FormattedMessage
                        id='PricingSection.Qty'
                        defaultMessage='Qty'
                      />
                    </Label>
                  </div>
                  <div className='w-20'>
                    <Label>
                      <FormattedMessage
                        id='PricingSection.UOM'
                        defaultMessage='UOM'
                      />
                    </Label>
                  </div>
                </div>
                <div style={{ marginRight: 66 }} />
              </div>
            )}

            {pricingLineItems.map(lineItem => (
              <Fragment key={lineItem.get('id')}>
                <Divider className='mv2' />
                {this.renderPricingItem(lineItem)}
              </Fragment>
            ))}

            {!readonly && this.renderPricingItem(fromJS(newLineItem))}
          </Paper>
        )}
      </Fragment>
    )
  }
}

export default connect(
  (state: RootState) => ({
    vet: currentVetSelectors.getVet(state),
    readonly:
      currentVetSelectors.isReadonly(state) ||
      currentVetSuppliersSelectors.hasInvited(state)
  }),
  {
    updateVet
  }
)(PricingSection)
