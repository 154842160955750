import React, { Component, Fragment } from 'react'
import SearchResultsItemContainer from '../../containers/SearchResultsItemContainer'
import Loading from 'shared/components/Loading'
import SortByContainer from '../../containers/SortByContainer'
import { FormattedMessage } from 'react-intl'
import SearchFilterPillsContainer from '../../containers/SearchFilterPillsContainer'
import Text from 'shared/components/Text'
import ConnectMessageDialogContainer from 'buyer/shared/containers/ConnectMessageDialogContainer'

type Props = {
  numberOfResults: number
  resultIds: string[]
  isSearching: boolean
}

type State = {
  openConnectDialog: boolean
  connectSupplierId?: string
  connectUserId: string
}

class SearchResults extends Component<Props, State> {
  state: State = {
    openConnectDialog: false,
    connectSupplierId: undefined,
    connectUserId: ''
  }

  toggleConnectDialog = (
    connectSupplierId?: string,
    connectUserId?: string
  ) => {
    this.setState({
      openConnectDialog: !this.state.openConnectDialog,
      connectSupplierId,
      connectUserId: connectUserId || ''
    })
  }

  render() {
    const { isSearching, numberOfResults, resultIds } = this.props

    return (
      <Fragment>
        <div className='flex items-end mb2 mt2'>
          <div className='flex-auto'>
            <SearchFilterPillsContainer />
          </div>
          <div className='flex items-center'>
            <div className='flex items-center'>
              <SortByContainer />
            </div>
          </div>
        </div>

        {isSearching && <Loading />}

        {!isSearching && numberOfResults < 1 && (
          <Text className='mt3'>
            <FormattedMessage
              id='Contacts/SearchResults.noContactsFound'
              defaultMessage='No contacts were found, please try a different search'
            />
          </Text>
        )}

        {!isSearching && numberOfResults > 0 && (
          <div className='bt br bl b--light-gray'>
            {resultIds.map(userId => (
              <SearchResultsItemContainer
                key={userId}
                userId={userId}
                connectUser={this.toggleConnectDialog}
              />
            ))}
          </div>
        )}

        <ConnectMessageDialogContainer
          supplierId={this.state.connectSupplierId}
          userId={this.state.connectUserId}
          open={this.state.openConnectDialog}
          onClose={this.toggleConnectDialog}
          connectContact
        />
      </Fragment>
    )
  }
}

export default SearchResults
