import jsPDF from 'jspdf-yworks'
import certificateBackground from './certificate.jpg'
import moment from 'moment'
import parseLocationToString from 'shared/utils/data/parseLocationToString'
import NaicsStd from 'shared/utils/getNaicsStd'
import { naicsFootnote } from 'shared/models/NaicsStd'

const certDictionary = {
  sbe: {
    name: 'Small Business Enterprise',
    def:
      'To qualify as a Small Business Enterprise (SBE), you are attesting that your company meets the federal definition of a small business in your country of operation.'
  },
  mbe: {
    name: 'Minority-owned Business Enterprise',
    def:
      'To qualify as a Minority Business Enterprise (MBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by, one or more members of a socially and economically disadvantaged minority group. The definition for qualifying minority groups varies by country. In the U.S., this is specifically U.S. citizens who are African Americans (Black), Hispanic Americans, Native Americans, Asian-Pacific Americans and Indian Sub-Continent Americans.'
  },
  wbe: {
    name: 'Women-owned Business Enterprise',
    def:
      'To qualify as a Women Owned Business Enterprise (WBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by women.'
  },
  vbe: {
    name: 'Veteran-owned Business Enterprise',
    def:
      'To qualify as a Veteran Business Enterprise (VBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by veterans. In the USA, this specifically requires these veterans be US citizens who have served in the US military, naval or air service. The business cannot be a branch or subsidiary of a foreign corporation, foreign firm or other foreign-based business.'
  },
  dobe: {
    name: 'Disability Owned Business Enterprise',
    def:
      'To qualify as a Disability Owned Business Enterprise (DOBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by one or more disabled persons.'
  },
  dbe: {
    name: 'Disadvantaged Business Enterprise',
    def:
      'To qualify as a Disadvantaged Business Enterprise (DBE), you are attesting that your business meets all of the government standards that award eligibility as a DBE in your country of operation. These standards  may include being owned by women, minority, disabled and other disadvantaged persons as a result of economic disadvantages with respect to education, employment, residence or business location or social disadvantage and lack of business training.'
  },
  lgbtbe: {
    name: 'LGBT Owned Business Enterprise',
    def:
      'To qualify as a LGBT Business Enterprise (LGBTBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by an LGBT person or persons. The business must exercise independence from any non-LGBT business enterprise. In the US, these persons must be U.S. citizens or lawful permanent residents, have its principal place of business (headquarters) in the United States and been formed as a legal entity in the United States.'
  },
  '8a': {
    name: '8(A) Designation',
    def:
      'To qualify as an 8(A) Designation business (8A), you are attesting that your business is recognized by the U.S. Small Business Association (SBA) as owned and controlled at least 51% by socially and economically disadvantaged individuals.'
  },
  hub: {
    name: 'HubZone Certified',
    def:
      'To qualify as a HUB Zone Certified Business (HUB), you are attesting that your business  is operating in a certified historically under-utilized business zone in the United States. There are 7,000 qualified census tracts (HUD) and 900 qualified non- metropolitan counties.'
  },
  lsa: {
    name: 'Labour Surplus Area',
    def:
      'To qualify as a Labor Surplus Area (LSA) business, you are attesting that your business operates in the U.S. in a jurisdiction where the average unemployment number is 20% higher than the average U.S. unemployment rate in the last two calendar years.'
  },
  dvbe: {
    name: 'Disabled Veteran Business Enterprise',
    def:
      'To qualify as a Disabled Veteran Business Enterprise (DVBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by veterans with at least a 10% disability. In the USA, this specifically requires these veterans be US citizens who have served in the US military, naval or air service. The business cannot be a branch or subsidiary of a foreign corporation, foreign firm or other foreign-based business.'
  },
  sdvbe: {
    name: 'Service Disabled Veteran Business Enterprise',
    def:
      'To qualify as a Service Disabled Veteran Business Enterprise (SDVBE), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by veterans with at least a 10% disability incurred during active duty in the military. In the USA, this specifically requires these veterans be US citizens who have served in the US military, naval or air service. The business cannot be a branch or subsidiary of a foreign corporation, foreign firm or other foreign-based business.'
  },
  dve: {
    name: 'Disadvantaged Veteran Enterprise',
    def:
      'To qualify as a Disadvantaged Veteran Enterprise (DVE), you are attesting that your business meets all of the government standards that award eligibility as a DBE in your country of operation and that our business is at least 51% owned by, and has management and daily business operations that are controlled by veterans. In the USA, this specifically requires these veterans be US citizens who have served in the US military, naval or air service. The business cannot be a branch or subsidiary of a foreign corporation, foreign firm or other foreign-based business.'
  },
  sdb: {
    name: 'Small Disadvantaged Business',
    def:
      'To qualify as a Small Disadvantaged Business (SDB), you are attesting that your business meets all of the government standards that award eligibility as a Disadvantaged Business Enterprise (DBE) and a Small Business Enterprise (SBE) in your country of operation. The DBE standards  may include being owned by women, minority, disabled and other disadvantaged persons as a result of economic disadvantages with respect to education, employment, residence or business location or social disadvantage and lack of business training.'
  },
  vosb: {
    name: 'Veteran Owned Small Business',
    def:
      'To qualify as a Veteran Owned Small Business (VOSB), you are attesting that your business meets all of the government standards that award eligibility as a Small Business Enterprise (SBE) in your country of operation. You are also attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by veterans. In the USA, this specifically requires these veterans be US citizens who have served in the US military, naval or air service. The business cannot be a branch or subsidiary of a foreign corporation, foreign firm or other foreign-based business.'
  },
  ancit: {
    name: 'Alaskan Native Corporations and Indian Tribes',
    def:
      'To qualify as a Alaskan Native Corporations and Indian Tribes business (ANCIT), you are attesting that your business is one of the corporations created by the Alaska Native Claims Settlement Act (ANCSA) of 1971 or owned by an American Indian or Alaska Native tribal entity that is recognized as having a government‐to‐government relationship with the United States.'
  },
  wle: {
    name: 'Woman Led Business Enterprise',
    def:
      'To qualify as a Women Led Business Enterprise (WLE), you are attesting that your business is managed and daily business operations are controlled by one or more women.'
  },
  cab: {
    name: 'Certified Aboriginal Business',
    def:
      'To qualify as a Certified Aboriginal Business (CAB), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by a person or persons who have aboriginal status in their country of operation.'
  },
  bcorp: {
    name: 'Certified Benefit Corporation',
    def:
      'To qualify as a Certified Benefit Corporation, you are attesting that your business has been set up as a Benefit Corporation under applicable law or been certified by B Lab, an international certifying agency.'
  },
  pwd: {
    name: 'People With Disability',
    def:
      'To qualify as a People With Disability business (PWD), you are attesting that your business is at least 51% owned by, and has management and daily business operations that are controlled by one or more disabled persons.'
  },
  hbcu: {
    name: 'Historically Black Colleges and Universities',
    def:
      'To qualify as a Historically Black Colleges and Universities (HBCU), you are attesting that your business is one of the institutions of higher education in the United States that were established before the Civil Rights Act of 1964 with the intention of primarily serving the African-American community.'
  },
  hud: {
    name: 'U.S. Department of Housing and Urban Development',
    def: `To qualify as a U.S. Department of Housing and Urban Development Section 3 Business (HUD), you are attesting that your business meets at least one of the following definitions: a) 51% or more owned by residents of public housing or persons whose income does not exceed HUD's local area low-income limits (i.e. Section 3 residents); or b) Comprised of 30% or more full-time employees who are Section 3 residents; or c) Can provide evidence of a firm commitment to award 25% or more of sub-contracts to businesses that meet a. or b. Refer to the HUD portal for https://portalapps.hud.gov/Sec3BusReg/BRegistry/RegisterBusiness for more information`
  }
}

type cert = {
  subCategory: string
  company: string
  signBy: string
  firstCertified?: Date
  signOn: Date
  email: string
  title?: string
  certifyingLocation?: any
  certNaics?: Array<{ code: string; title: string; primary: boolean }>
}

function toDataURL(url: string, callback: (data) => void) {
  var xhr = new XMLHttpRequest()
  xhr.onload = function() {
    var reader = new FileReader()
    reader.onloadend = function() {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

const generateTealbookCertificate = (data: cert) => {
  const {
    subCategory,
    company,
    signBy,
    firstCertified,
    signOn,
    email,
    title,
    certifyingLocation,
    certNaics
  } = data

  return new Promise((resolve, reject) => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [1640, 1148]
    })

    const footnotes: Array<string> = []

    toDataURL(certificateBackground, dataURL => {
      try {
        const showFirstCertified =
          firstCertified && moment(signOn).isAfter(firstCertified, 'day')
        doc.addImage(
          dataURL,
          'JPG',
          0,
          0,
          doc.internal.pageSize.width,
          doc.internal.pageSize.height
        )
        doc.setFont('helvetica', 'bold')
        doc.setFontSize(56)
        doc.text(
          certDictionary[subCategory].name,
          doc.internal.pageSize.width / 2,
          350,
          { align: 'center' }
        )
        doc.text(company, doc.internal.pageSize.width / 2, 450, {
          align: 'center'
        })

        doc.setFontStyle('normal')
        doc.setFontSize(22)
        doc.text(certDictionary[subCategory].def, 200, 565, { maxWidth: 1240 })

        if (certifyingLocation) {
          doc.setFontStyle('bold')
          doc.text(
            `Certifying Country: ${certifyingLocation.components.country}`,
            200,
            500
          )
          doc.setFontStyle('normal')
          doc.text(parseLocationToString(certifyingLocation), 200, 525)
        }

        if (subCategory === 'sbe') {
          if (
            certifyingLocation &&
            certifyingLocation.components.countryAbbrev === 'US'
          ) {
            doc.setFontStyle('bold')
            doc.text('IMPORTANT NOTICE FOR US BUSINESSES', 200, 720)
            doc.setFontStyle('normal')
            doc.text(
              "Under 15 U.S.C. 645(d), any person who misrepresents firm's status as a small business in order to obtain a subcontract, that is to be included as a part or all of a goal contained in subcontracting plan required pursuant to a section (8d) of Small Business Act, shall (1)be punished by imposition of a fine, imprisonment, or both; (2) be subject to administrative remedies, and (3) ineligible for participation in progress conducted under the authority of the act.",
              200,
              742,
              { maxWidth: 1240 }
            )

            if (certNaics) {
              const index = certNaics.findIndex(naics => !!naics.primary)
              const primaryNasic = index !== -1 && certNaics[index]

              if (primaryNasic) {
                const rule = NaicsStd.getNaicsRules(primaryNasic.code).toJS()
                doc.setFontStyle('bold')
                doc.text(
                  `Primary Business: ${primaryNasic.code} - ${primaryNasic.title}`,
                  200,
                  605
                )
                doc.setFontStyle('normal')
                if (rule.footnote) {
                  footnotes.push(rule.footnote)
                }
                if (rule.revenue) {
                  doc.text(
                    `To qualify, your annual revenue must be less than $${rule.revenue}M USD. ${rule.footnote}`,
                    380,
                    630,
                    { maxWidth: 1240 }
                  )
                  doc.circle(360, 625, 5, 'F')
                }
                if (rule.employees) {
                  doc.text(
                    `To qualify, your company must have fewer than ${rule.employees} employees. ${rule.footnote}`,
                    380,
                    rule.revenue ? 655 : 630,
                    { maxWidth: 1240 }
                  )
                  doc.circle(360, rule.revenue ? 650 : 625, 5, 'F')
                }
              }
            }
          } else {
            doc.setFontStyle('normal')
            doc.text('Canada: Fewer than 100 employees', 300, 605, {
              maxWidth: 1240
            })
            doc.circle(280, 600, 5, 'F')
            doc.text('UK: Fewer than 250 employees', 300, 630, {
              maxWidth: 1240
            })
            doc.circle(280, 625, 5, 'F')
            doc.text('EU: Fewer than 50 employees', 300, 655, {
              maxWidth: 1240
            })
            doc.circle(280, 650, 5, 'F')
            doc.text('Australia: Fewer than 200 employees', 300, 680, {
              maxWidth: 1240
            })
            doc.circle(280, 675, 5, 'F')
          }
        }

        doc.setFontStyle('bold')
        doc.text('SUPPLIER VERIFICATION', 200, 820)
        doc.setFontStyle('normal')
        doc.text(
          "I hereby certify that all information provided above is true and complete to the best of my knowledge. The undersigned certifies that they are authorized to sign on behalf of the Company listed above and that all the information above is true and accurate. This information I am sharing should be treated as valid until an updated form is submitted. It is the supplier's responsibility to notify TealBook if supplier's size, classification, ownership or other relevant information changes. The supplier may submit changes at any time on this same form. *",
          200,
          842,
          { maxWidth: 1240 }
        )

        doc.text(
          `I have the authority to attest to this on behalf of ${company}`,
          200,
          940,
          { maxWidth: 1240 }
        )

        doc.setFontSize(28)
        doc.setFontStyle('bold')
        doc.text('Signed by:', 200, 975)
        if (showFirstCertified) {
          doc.text('First certified:', 1100, 975)
        }
        doc.text('Date:', 1100, showFirstCertified ? 1003 : 975)
        doc.text('Expires:', 1100, showFirstCertified ? 1031 : 1003)
        doc.setFontStyle('normal')
        doc.text(`${title ? `${signBy}, ${title}` : signBy}`, 320, 975)
        if (showFirstCertified) {
          doc.text(moment(firstCertified).format('LL'), 1250, 975)
        }
        doc.text(
          moment(signOn).format('LL'),
          1250,
          showFirstCertified ? 1003 : 975
        )
        doc.text(
          moment(signOn)
            .add(1, 'y')
            .format('LL'),
          1250,
          showFirstCertified ? 1031 : 1003
        )
        doc.setTextColor('#1E6A77')
        doc.textWithLink(email, 320, 1003, { url: `mailto:${email}` })

        const otherCertNaics =
          certNaics && certNaics.filter(naics => !naics.primary)
        if (
          subCategory === 'sbe' &&
          certifyingLocation &&
          certifyingLocation.components.countryAbbrev === 'US' &&
          otherCertNaics &&
          otherCertNaics.length > 0
        ) {
          doc.addPage()
          doc.setTextColor('#000000')
          doc.setFontSize(28)
          doc.setFontStyle('bold')
          doc.text('Other Qualified NAICS Codes:', 200, 100)
          doc.setFontSize(22)
          otherCertNaics.forEach((naics, index) => {
            const rule = NaicsStd.getNaicsRules(naics.code).toJS()
            doc.setFontStyle('bold')
            doc.text(`${naics.code} - ${naics.title}`, 200, index * 100 + 150)
            doc.setFontStyle('normal')
            if (rule.footnote) {
              footnotes.push(rule.footnote)
            }
            if (rule.revenue) {
              doc.text(
                `To qualify, your annual revenue must be less than $${rule.revenue}M USD. ${rule.footnote}`,
                230,
                index * 100 + 175,
                { maxWidth: 1240 }
              )
              doc.circle(210, index * 100 + 170, 5, 'F')
            }
            if (rule.employees) {
              doc.text(
                `To qualify, your company must have fewer than ${rule.employees} employees. ${rule.footnote}`,
                230,
                index * 100 + (rule.revenue ? 200 : 175),
                { maxWidth: 1240 }
              )
              doc.circle(210, index * 100 + (rule.revenue ? 195 : 170), 5, 'F')
            }
          })
        }
        const uniqueFootnotes = footnotes
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort((v1, v2) => parseInt(v1) - parseInt(v2))

        if (
          certifyingLocation &&
          certifyingLocation.components.countryAbbrev === 'US' &&
          uniqueFootnotes.length > 0
        ) {
          doc.addPage()
          doc.setTextColor('#000000')
          doc.setFontSize(28)
          doc.setFontStyle('bold')
          doc.text('Footnotes:', 200, 100)
          doc.setFontSize(22)
          doc.setFontStyle('normal')
          let footnotesText: string = ''
          const a = 'a'.charCodeAt(0)
          uniqueFootnotes.forEach(key => {
            let noteText = `${key}. `
            const notes: Array<string> = naicsFootnote[key]
            notes.forEach((note, index) => {
              noteText += `${
                notes.length > 1 ? `${String.fromCharCode(a + index)}) ` : ''
              }${note}  `
            })
            footnotesText += lineBreak(noteText, 170)
            footnotesText += '\n\n'
          })
          doc.text(footnotesText, 200, 150)
        }

        const blobPdf = new Blob([doc.output('blob')], {
          type: 'application/pdf'
        })
        blobPdf['filename'] = 'certificate.pdf'
        resolve(blobPdf)
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  })
}

export default generateTealbookCertificate

function lineBreak(text: string, max: number = 80) {
  if (text == null) {
    return ''
  }
  if (text.length <= max) {
    return text
  }
  const nextNewLine = /\n/.exec(text)

  const lineLength = nextNewLine ? nextNewLine.index : text.length
  if (lineLength <= max) {
    const line = text.substr(0, lineLength)
    const rest = text.substr(lineLength + 1)
    return line + '\n' + lineBreak(rest, max)
  } else {
    let line = text.substr(0, max)
    let rest = text.substr(max)
    const res = /([\s])[^\s]*$/.exec(line)

    if (res) {
      line = text.substr(0, res.index)
      rest = text.substr(res.index + 1)
    } else {
      line = line + '-'
    }
    return line + '\n' + lineBreak(rest, max)
  }
}
