import React from 'react'
import { FormattedMessage } from 'react-intl'
import ExternalLink from 'shared/components/ExternalLink'

const MessagingHud = () => {
  return (
    <>
      <FormattedMessage
        id='MessagingHud.1'
        defaultMessage='To qualify as a U.S. Department of Housing and Urban Development Section 3 Business (HUD), you are attesting that your business meets at least one of the following definitions:'
      />
      <ol type='a'>
        <li>
          <FormattedMessage
            id='MessagingHud.2'
            defaultMessage={`51% or more owned by residents of public housing or persons whose income does not exceed HUD's local area low-income limits (i.e. Section 3 residents); or`}
          />
        </li>
        <li>
          <FormattedMessage
            id='MessagingHud.3'
            defaultMessage='Comprised of 30% or more full-time employees who are Section 3 residents; or'
          />
        </li>
        <li>
          <FormattedMessage
            id='MessagingHud.4'
            defaultMessage='Can provide evidence of a firm commitment to award 25% or more of sub-contracts to businesses that meet a. or b.'
          />
        </li>
      </ol>
      <FormattedMessage
        id='MessagingHud.5'
        defaultMessage='Refer to the HUD portal for {link} for more information'
        values={{
          link: (
            <ExternalLink href='https://portalapps.hud.gov/Sec3BusReg/BRegistry/RegisterBusiness'>
              https://portalapps.hud.gov/Sec3BusReg/BRegistry/RegisterBusiness
            </ExternalLink>
          )
        }}
      />
    </>
  )
}

export default MessagingHud
