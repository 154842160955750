import React from 'react'
import Paper from 'shared/components/Paper'
import Benefit from './Benefit'
import TealbotCoins from 'shared/assets/images/tealbot-coins.svg'
import TealbotControl from 'shared/assets/images/tealbot-control.svg'
import TealbotTarget from 'shared/assets/images/tealbot-target.svg'
import TealbotCertificate from 'shared/assets/images/tealbot-certificate.svg'
import Page from 'shared/components/Page'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

type Props = {
  onDone: () => void
}

const benefits = [
  {
    title: 'Access a Huge Market',
    subtitle: (
      <>
        Our clients spend <span className='teal'>over $100 billion</span> each
        year.
      </>
    ),
    graphic: TealbotCoins
  },
  {
    title: 'Take Control',
    subtitle: (
      <>
        Updating <span className='teal'>your profile</span> increases your
        chance of being found.
      </>
    ),
    graphic: TealbotControl
  },
  {
    title: 'Get Targeted',
    subtitle: (
      <>
        Your capabilities match you to{' '}
        <span className='teal'>the right opportunities</span>.
      </>
    ),
    graphic: TealbotTarget
  },
  {
    title: 'Leverage your Uniqueness',
    subtitle: (
      <>
        Share your <span className='teal'>qualifications</span> as small,
        diverse or ISO certified.
      </>
    ),
    graphic: TealbotCertificate
  }
]

const SupplierBenefits = ({ onDone }: Props) => {
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    afterChange: (index: number) => {
      if (index === benefits.length - 1 && !!onDone) {
        setTimeout(() => onDone(), 3000)
      }
    }
  }

  return (
    <Page title='Creating New Organization' className='tc'>
      <h2 className='f4 f3-m f2-l fw2 black-70 mb0'>
        Your organization is being created.
      </h2>
      <p className='lh-copy fw4 mid-gray ma0 mv3 f6 f5-ns fw6'>
        While waiting, check out the benefits of using TealBook...
      </p>
      <div className='center mw6'>
        <Paper noPadding>
          <div className='pa3 pa4-ns'>
            <Slider {...settings}>
              {benefits.map((benefit, index) => (
                <Benefit
                  key={benefit.title}
                  title={benefit.title}
                  subtitle={benefit.subtitle}
                  graphic={benefit.graphic}
                />
              ))}
            </Slider>
          </div>
        </Paper>
      </div>
    </Page>
  )
}

export default SupplierBenefits
