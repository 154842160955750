import React, { Component, RefObject, createRef } from 'react'
import dateFormat from 'shared/utils/dateFormat'
import Avatar from 'shared/components/Avatar'
import moment from 'moment'
import Link from 'shared/components/Link'
import SharedSupplierMessage from '../../containers/SharedSupplierMessage'
import ESGSurveyMessageSummary from '../../../buyer/SupplierProfile/components/ESGSurveyMessageSummary'
import parsePath from 'shared/utils/parsePath'
import paths from '../../../supplier/routes/paths'
import buyerPaths from 'buyer/routes/paths'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import Linkify from 'react-linkify'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import ForwardSurveyRequestDialogContainer from './../../containers/ForwardSurveyRequestDialogContainer'

const styles = {
  date: 'f8 gray mt1',
  supplierProfileWrapper: 'cf',
  supplierProfile: 'white mt3 mw6',
  avatar: 'dtc w2 v-top',
  message: {
    wrapper: 'dt w-100 ph3 mt1',
    body: 'br3 f7 pa2 dib mw5 mw6-ns bg-light-gray mt3 lh-copy',
    bodyWrapper: 'dtc ml2 ph2',
    linkText: 'bg-light-gray f7 fw4 dim underline pointer',
    float: 'fl'
  },
  myMessage: {
    wrapper: 'dt w-100 ph3 tr mt1',
    body: 'br3 f7 pa2 dib mw5 mw6-ns tl bg-teal white mt3 lh-copy',
    bodyWrapper: 'dtc mr2 ph2',
    linkText: 'bg-teal white f7 fw4 dim underline pointer',
    float: 'fr'
  }
}

type Props = {
  body: string
  createdDate: Date
  profileUrl: string
  profilePictureUrl: string
  mine: boolean
  supplierId: string
  shareESGSurveyLink?: boolean
  isMessageBeingReadByReceiver: boolean
  callbackOnMount?: (ref: RefObject<HTMLDivElement>) => void
  handleForwardSurveyRequest: () => void
  firstName: string
  lastName: string
}

class Message extends Component<Props> {
  ref = createRef<HTMLDivElement>()

  componentDidMount() {
    const { callbackOnMount } = this.props
    if (callbackOnMount) {
      callbackOnMount(this.ref)
    }
  }

  renderAvatar(profileUrl, profilePictureUrl, name) {
    return (
      <div className={styles.avatar}>
        <Link to={profileUrl}>
          <Avatar
            url={profilePictureUrl}
            className='v-top w2 mt3'
            name={name}
          />
        </Link>
      </div>
    )
  }

  render() {
    const {
      body,
      createdDate,
      profileUrl,
      profilePictureUrl,
      mine,
      supplierId,
      shareESGSurveyLink,
      isMessageBeingReadByReceiver,
      handleForwardSurveyRequest,
      firstName,
      lastName
    } = this.props

    const messageStyles = mine ? styles.myMessage : styles.message

    const componentDecorator = (href, text, key) => (
      <a
        className={messageStyles.linkText}
        href={href}
        key={key}
        target='_blank'
        rel='noopener noreferrer'
      >
        {text}
      </a>
    )

    return (
      <div className={messageStyles.wrapper} ref={this.ref}>
        {!mine &&
          this.renderAvatar(
            profileUrl,
            profilePictureUrl,
            `${firstName || ''} ${lastName || ''}`
          )}

        <div className={messageStyles.bodyWrapper}>
          {body && (
            <Linkify componentDecorator={componentDecorator}>
              <div
                className={messageStyles.body}
                style={{ wordWrap: 'break-word' }}
              >
                {body}
              </div>
            </Linkify>
          )}

          {supplierId && (
            <div className={styles.supplierProfileWrapper}>
              <div
                className={classNames(
                  styles.supplierProfile,
                  messageStyles.float
                )}
              >
                <SharedSupplierMessage
                  supplierId={supplierId}
                  profileUrl={parsePath(buyerPaths.supplierProfile, {
                    supplierId
                  })}
                />
              </div>
            </div>
          )}
          {shareESGSurveyLink && (
            <div className={styles.supplierProfileWrapper}>
              <div
                className={classNames(
                  styles.supplierProfile,
                  messageStyles.float
                )}
              >
                {isMessageBeingReadByReceiver && (
                  <div className='flex'>
                    <ForwardSurveyRequestDialogContainer
                      messageToForward={body}
                    />
                    <ESGSurveyMessageSummary
                      name={`Sustainability Survey Link`}
                      description={`Link to the Sustainability Survey Form`}
                      surveyUrl={paths.esgSurvey}
                    />
                    <div className='self-center ml2'>
                      <DropDownMenu>
                        <MenuItem onClick={() => handleForwardSurveyRequest()}>
                          <FormattedMessage
                            id='Message.Forward'
                            defaultMessage='Forward'
                          />
                        </MenuItem>
                      </DropDownMenu>
                    </div>
                  </div>
                )}
                {!isMessageBeingReadByReceiver && (
                  <ESGSurveyMessageSummary
                    name={`Sustainability Survey Link`}
                    description={`Link to the Sustainability Survey Form`}
                  />
                )}
              </div>
            </div>
          )}
          <div className={styles.date}>
            <FormattedMessage
              id='Message.createdAt'
              defaultMessage={`{date} at {time}`}
              values={{
                date: dateFormat(createdDate),
                time: moment(createdDate).format('hh:mm A')
              }}
            />
          </div>
        </div>

        {mine &&
          this.renderAvatar(
            profileUrl,
            profilePictureUrl,
            `${firstName} ${lastName}`
          )}
      </div>
    )
  }
}

export default Message
