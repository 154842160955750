import React, { Component, ReactNode } from 'react'
import separatorImage from 'shared/assets/images/open-book.svg'
import Text from 'shared/components/Text'

type Props = {
  featureImage: string
  title: string | ReactNode
  className: string
}
class FeatureBox extends Component<Props> {
  render() {
    const { featureImage, title, children, ...rest } = this.props
    return (
      <div {...rest}>
        {featureImage && (
          <img className='w4' alt='feature' src={featureImage} />
        )}
        {title && <div className='teal mb1'>{title}</div>}
        <img className='mb2' alt='separator' src={separatorImage} />
        <Text className='tl'>{children}</Text>
      </div>
    )
  }
}

export default FeatureBox
