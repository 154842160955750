import { call, takeEvery } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

const createUpdateTagsSaga = ({
  apiAddTags,
  apiDeleteTags,
  ADD_TAGS,
  REMOVE_TAGS,
  SET_TAGS
}) => {
  function* addTagSaga(action) {
    const { fieldName, newTag, ...rest } = action.payload
    const newTags = newTag
      .split(',')
      .map(tag => tag.trim())
      .filter(tag => !!tag)
    if (newTag) {
      yield call(apiAddTags, fieldName, newTags)
      return yield { fieldName, newTags, ...rest }
    }
  }

  function* removeTagsSaga(action) {
    const { fieldName, tagToRemove } = action.payload
    if (tagToRemove) {
      yield call(apiDeleteTags, fieldName, tagToRemove)
      return yield action.payload
    }
  }

  function* setTagsSaga(action) {
    const { fieldName, tagToRemove, newTags } = action.payload

    // remove tags
    if (tagToRemove) {
      yield call(apiDeleteTags, fieldName, tagToRemove)
    }

    // set the new tags
    if (newTags) {
      yield call(apiAddTags, fieldName, newTags)
    }

    return yield action.payload
  }

  return function* updateTagsSaga(action) {
    yield takeEvery(ADD_TAGS, createApiRequestSaga(addTagSaga))
    yield takeEvery(REMOVE_TAGS, createApiRequestSaga(removeTagsSaga))

    if (SET_TAGS) {
      yield takeEvery(SET_TAGS, createApiRequestSaga(setTagsSaga))
    }
  }
}

export default createUpdateTagsSaga
