import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import companySelectors from '../../../shared/selectors/companySelectors'
import List from 'shared/components/List'
import SimilarSupplierListItem from './SimilarSupplierListItem'
import PageSideSection from 'shared/components/PageSideSection'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import Scrollable from 'shared/components/Scrollable'
import { FormattedMessage } from 'react-intl'

export class SimilarSuppliers extends Component {
  render() {
    const { suppliers } = this.props

    return (
      <Fragment>
        <PageSideSection
          title={
            <FormattedMessage
              id='SimilarSuppliers.SimilarSuppliers'
              defaultMessage='Similar Suppliers'
            />
          }
        >
          <Scrollable>
            <List>
              {suppliers &&
                suppliers.map(supplier => (
                  <SimilarSupplierListItem
                    key={supplier.get('orgUnit')}
                    supplierId={supplier.get('orgUnit')}
                  />
                ))}
            </List>
          </Scrollable>
        </PageSideSection>

        <ClearbitCredit />
      </Fragment>
    )
  }
}

export default connect(state => ({
  suppliers: companySelectors.getSimilarSuppliers(state)
}))(SimilarSuppliers)
