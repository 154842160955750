import React from 'react'
import {
  BarChart,
  Bar,
  Legend,
  XAxis,
  YAxis,
  Label,
  LabelList,
  ResponsiveContainer
} from 'recharts'
import { useIntl, defineMessages } from 'react-intl'

const messages = defineMessages({
  outstanding: {
    id: 'InvitationsChart.outstanding',
    defaultMessage: 'Outstanding'
  },
  inProgress: {
    id: 'InvitationsChart.inProgress',
    defaultMessage: 'In Progress'
  },
  completedSurvey: {
    id: 'InvitationsChart.completedSurvey',
    defaultMessage: 'Completed Survey'
  }
})

type Props = {
  data: Array<{
    name: string
    outstanding: number
    inProgress: number
    completedSurvey: number
  }>
  totalLabel: string
}

const InvitationsChart = (props: Props) => {
  const intl = useIntl()
  const { data, totalLabel } = props
  const totalValue =
    data[0].outstanding + data[0].inProgress + data[0].completedSurvey

  const renderLegendText = (value: string, entry: any) => {
    return value !== 'hideLegend' ? (
      <span className='black f7'>{intl.formatMessage(messages[value])}</span>
    ) : null
  }

  const renderTotalValue = props => {
    const { viewBox } = props
    return (
      <svg
        width={viewBox.width}
        height={viewBox.height + 32}
        x={viewBox.x}
        y={viewBox.y}
      >
        <line
          x1={5}
          y1={3}
          x2={viewBox.width - 19}
          y2={3}
          stroke='#999'
          fill='none'
          strokeLinecap='round'
          strokeWidth={6}
        />
        <text
          x='50%'
          y='50%'
          dominantBaseline='middle'
          textAnchor='middle'
          className='f7'
        >
          {totalLabel}: {totalValue.toLocaleString() || 0}
        </text>
      </svg>
    )
  }

  const renderYAxis = props => {
    const { viewBox } = props
    const lineLength = 100
    const yStart = viewBox.height / 2 - lineLength / 2
    const yEnd = yStart + lineLength

    return (
      <svg
        width={viewBox.width}
        height={viewBox.height}
        x={viewBox.width - 1}
        y={viewBox.y}
      >
        <line
          x1={0}
          y1={yStart}
          x2={0}
          y2={yEnd}
          stroke='#999'
          fill='none'
          strokeLinecap='round'
          strokeWidth={1}
        />
      </svg>
    )
  }

  const renderLabel = props => {
    const { position, width, height, x, y, value } = props

    const labelHeight = 34
    const labelWidth = 100
    const labelX = x + width / 2
    const labelY = position === 'top' ? y - labelHeight : y + height
    const lineLength = 25
    const lineX = 0
    const lineY = position === 'top' ? labelHeight : 0
    const lineToY = position === 'top' ? lineY - lineLength : lineY + lineLength
    const textY = position === 'top' ? 6 : 14

    return (
      <svg width={labelWidth} height={labelHeight} x={labelX} y={labelY}>
        <g>
          <line
            x1={lineX}
            y1={lineY}
            x2={lineX}
            y2={lineToY}
            stroke='#777'
            fill='none'
            strokeLinecap='round'
            strokeWidth={2}
          />
          <foreignObject width={100} height={20} y={textY} x={4}>
            <div className='f7 fw6' style={{ width: 100 }}>
              {value?.toLocaleString()}
            </div>
          </foreignObject>
        </g>
      </svg>
    )
  }

  return (
    <ResponsiveContainer width='100%' height={314}>
      <BarChart
        data={data}
        layout='vertical'
        margin={{ right: 60, bottom: 35 }}
      >
        <Legend
          verticalAlign='top'
          align='left'
          iconType={'circle'}
          formatter={renderLegendText}
          wrapperStyle={{
            marginTop: '1rem',
            marginLeft: '2rem',
            top: 5,
            left: 5
          }}
        />
        <YAxis type='category' tick={false} axisLine={false}>
          <Label content={renderYAxis} offset={0} />
        </YAxis>
        <XAxis type='number' tick={false} axisLine={false}>
          <Label content={renderTotalValue} />
        </XAxis>
        <Bar
          dataKey='outstanding'
          fill='#E9EBEF'
          stackId={'stack'}
          barSize={50}
        >
          <LabelList
            dataKey='outstanding'
            position='top'
            content={renderLabel}
          />
        </Bar>
        <Bar dataKey='inProgress' fill='#F1CD5A' stackId={'stack'} barSize={50}>
          <LabelList
            dataKey='inProgress'
            position='bottom'
            content={renderLabel}
          />
        </Bar>
        <Bar
          dataKey='completedSurvey'
          fill='#25C281'
          stackId={'stack'}
          barSize={50}
        >
          <LabelList
            dataKey='completedSurvey'
            position='top'
            content={renderLabel}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default InvitationsChart
