import React, { useState } from 'react'
import Text from 'shared/components/Text'
import Pill from 'shared/components/Pill'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as IconPrivate } from 'shared/assets/icons/private.svg'
import { List, RecordOf } from 'immutable'
import ImageArrowDown from 'shared/assets/icons/arrow-down.svg'
import ImageArrowUp from 'shared/assets/icons/arrow-up.svg'
import ImageNewNote from 'shared/assets/icons/new-note.svg'
import ImageNewTag from 'shared/assets/icons/new-tag.svg'
import Button from 'shared/components/Button'
import IconButton from 'shared/components/IconButton'
import { makeStyles } from '@material-ui/core'
import { iconLabelStyle } from 'shared/components/NavBar/NavLink'

type Props = {
  supplierId: string
  cardId: string
  description: string
  website: string
  tags?: List<
    RecordOf<{
      key: string
      name: string
      count: number
      mine: boolean
      label: boolean
    }>
  >
  labels?: List<
    RecordOf<{
      key: string
      name: string
    }>
  >
  isTealbot?: boolean
  disableActions?: boolean
  onAddTagsClick: (any) => void
  onAddNoteClick: (any) => void
  addTagToSupplier: (param: {
    supplierId: string
    cardId: string
    tagsFieldName: string
    newTag: string
  }) => void
  removeTagFromSupplier: (param: {
    supplierId: string
    cardId: string
    removeTag: string
  }) => void
  removeLabelFromSupplier: (param: {
    fieldName: string
    tagToRemove: string
    supplierId: string
  }) => void
}

const showMinimumTags = 5

const useStyles = makeStyles({
  icon: iconLabelStyle.icon,
  hoverOutline: {
    '&:hover': {
      outline: 'auto black'
    }
  }
})

const SupplierListDetails = (props: Props) => {
  const {
    website,
    description,
    tags,
    labels,
    onAddTagsClick,
    onAddNoteClick,
    isTealbot,
    disableActions
  } = props

  const [showAllTags, setShowAllTags] = useState<boolean>(false)

  const classes = useStyles()

  const toggleShowTags = () => {
    setShowAllTags(!showAllTags)
  }

  const onTagClick = (adding, tag) => {
    const {
      supplierId,
      cardId,
      addTagToSupplier,
      removeTagFromSupplier
    } = props
    if (adding) {
      addTagToSupplier({
        supplierId,
        cardId,
        tagsFieldName: 'offerings',
        newTag: tag
      })
    } else {
      removeTagFromSupplier({ supplierId, cardId, removeTag: tag })
    }
  }

  const onLabelClick = tag => {
    const { supplierId, removeLabelFromSupplier } = props

    removeLabelFromSupplier({
      fieldName: 'attributes',
      tagToRemove: tag,
      supplierId
    })
  }

  const cleanWebsite = (website = ''): boolean | string | null => {
    const clean = website.match(/^(?:https?:)?(?:\/\/)?([^/?]+)/gim)
    return clean && clean.length > 0 && clean[0]
  }

  //filters tags for undefined names -> tags made before new 'addTag' that corrects for this at time of input
  const filteredTags = tags && tags.filter(tag => !!tag.get('name'))
  const totalNumTags = (labels?.size || 0) + (filteredTags?.size || 0)
  const showExpandCollapse = totalNumTags > showMinimumTags

  return (
    <>
      {description && <Text className='mt2'>{description}</Text>}
      {website && (
        <Text className='mt2 gray truncate mw5 mw-100-ns'>
          {cleanWebsite(website)}
        </Text>
      )}
      <div className='flex justify-between'>
        <div className='mt2'>
          {labels &&
            labels
              .sort((labelA, labelB) => {
                return labelA.get('name').toUpperCase() <
                  labelB.get('name').toUpperCase()
                  ? -1
                  : 1
              })
              .take(showAllTags ? labels.size : showMinimumTags)
              .map(label => (
                <Pill
                  key={label.get('key')}
                  label={label.get('name')}
                  icon={<IconPrivate fill='#ffffff' />}
                  customColor='bg-green white'
                  onToggle={
                    !disableActions
                      ? () => onLabelClick(label.get('name'))
                      : undefined
                  }
                />
              ))}
          {filteredTags &&
            filteredTags
              .sort((tagA, tagB) => {
                let isMine
                if (tagA.get('mine') === tagB.get('mine')) {
                  isMine = 0
                } else if (tagB.get('mine')) {
                  isMine = 1
                } else {
                  isMine = -1
                }
                return isMine || tagB.get('count') - tagA.get('count')
              })
              .take(
                showAllTags
                  ? filteredTags.size
                  : Math.max(0, showMinimumTags - (labels?.size || 0))
              )
              .map(tag =>
                tag.get('mine') ? (
                  <Pill
                    key={tag.get('key')}
                    label={tag.get('name')}
                    count={tag.get('count')}
                    color='primary'
                    onToggle={
                      !isTealbot && !disableActions
                        ? () => onTagClick(false, tag.get('name'))
                        : undefined
                    }
                    role='button'
                    aria-pressed={true}
                  />
                ) : (
                  <Pill
                    key={tag.get('key')}
                    label={tag.get('name')}
                    count={tag.get('count')}
                    onToggle={
                      !disableActions
                        ? () => onTagClick(true, tag.get('name'))
                        : undefined
                    }
                    role='button'
                    aria-pressed={false}
                  />
                )
              )}
          {totalNumTags > showMinimumTags && !showAllTags && (
            <Button
              autoSize
              variant='text'
              onClick={toggleShowTags}
              className={classes.hoverOutline}
            >
              <FormattedMessage
                id='SupplierListDetails.MoreTags'
                defaultMessage='...{more} more tags'
                values={{
                  more: totalNumTags - showMinimumTags
                }}
              />
            </Button>
          )}
        </div>
        <div
          style={{
            flex: '0 0 90px',
            textAlign: 'right',
            marginTop: !!totalNumTags ? '20px' : 'auto'
          }}
        >
          {showExpandCollapse && (
            <Tooltip
              title={
                <FormattedMessage
                  id='SupplierListDetails.ExpandCollapse'
                  defaultMessage='Expand / Collapse'
                />
              }
            >
              <IconButton onClick={toggleShowTags} aria-expanded={showAllTags}>
                <img
                  src={showAllTags ? ImageArrowUp : ImageArrowDown}
                  alt='Expand / Collapse'
                  className={`dib pointer mh1 ${classes.icon}`}
                  style={{ width: 20 }}
                />
              </IconButton>
            </Tooltip>
          )}
          {!disableActions && (
            <>
              <Tooltip
                title={
                  <FormattedMessage
                    id='SupplierListDetails.AddTags'
                    defaultMessage='Add Tags'
                  />
                }
              >
                <IconButton onClick={onAddTagsClick}>
                  <img
                    src={ImageNewTag}
                    alt='Add Tags'
                    className={`dib pointer mh1 ${classes.icon}`}
                    style={{ width: 20 }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  <FormattedMessage
                    id='SupplierListDetails.AddNote'
                    defaultMessage='Add Note'
                  />
                }
              >
                <IconButton onClick={onAddNoteClick}>
                  <img
                    src={ImageNewNote}
                    alt='Add Note'
                    className={`dib pointer mh1 ${classes.icon}`}
                    style={{ width: 20 }}
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default SupplierListDetails
