import React, { Component } from 'react'
import debounce from 'debounce'
import VetNavBarContainer from '../../containers/VetNavBarContainer'
import Divider from 'shared/components/Divider'
import exportData from 'shared/utils/exportData'
import DropdownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import { List, RecordOf, fromJS } from 'immutable'
import CloseIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import Text from 'shared/components/Text'
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  IntlShape
} from 'react-intl'
import camelCase from 'lodash.camelcase'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import VetContactUsDialogContainer from '../../containers/VetContactUsDialogContainer'
import { CSSTransition } from 'react-transition-group'
import Hidden from '@material-ui/core/Hidden'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from 'shared/components/Button'
import Switch from 'shared/components/Switch'
import ExportDoc from 'shared/components/ExportDoc'
import VetExportLayout from '../../containers/VetExportLayout'
import storageManager from 'shared/utils/storageManager'
import TealbotHelpIcon from 'shared/components/TealbotHelpIcon'

const messages = defineMessages({
  inProgress: {
    id: 'buyerStatus.InProgress',
    defaultMessage: 'In Progress'
  },
  sent: {
    id: 'buyerStatus.Sent',
    defaultMessage: 'Sent'
  },
  reviewing: {
    id: 'buyerStatus.Reviewing',
    defaultMessage: 'Reviewing'
  },
  closed: {
    id: 'buyerStatus.Closed',
    defaultMessage: 'Closed'
  },
  cancelled: {
    id: 'buyerStatus.Cancelled',
    defaultMessage: 'Cancelled'
  },
  deleteMessage: {
    id: 'VetHeader.AreYouSureYouWantToDeleteThisRFI',
    defaultMessage: 'Are you sure you want to delete this RFI?'
  },
  cancelMessage: {
    id: 'VetHeader.ConfirmCancelRFI',
    defaultMessage: 'Are you sure you want to cancel this RFI?'
  },
  closeMessage: {
    id: 'VetHeader.ConfirmCloseRFI',
    defaultMessage: 'Are you sure you want to close this RFI?'
  },
  reopenMessage: {
    id: 'VetHeader.ConfirmReopen',
    defaultMessage: 'Are you sure you want to reopen this RFI?'
  }
})

const styles = {
  nameInput:
    'bg-transparent ba b--transparent border-box pa2 w-100 br1 mid-gray hover-border-moon-gray focus-bg-white focus-ba',
  nameInputWrapper: 'dtc ma0',
  secondaryDetailsWrapper: 'dtc tr v-mid w5',
  secondaryLine: 'f8 fw4 gray ma0 mb1',
  secondaryLabel: 'fw6 mr2'
}

type Props = {
  redirectFrom: string
  isMobile: boolean
  pathname: string
  id: string
  vetId: string
  vetName: string
  hasResponse: boolean
  vetCreatedBy: string
  decision: string
  vetDateCreated: string
  vetExportData: {
    columns: string[]
    data: Array<any>
  }
  contactsExportData: List<
    RecordOf<{
      supplierName: string
      exportContacts: List<
        RecordOf<{
          user: string
          email: string
          primary?: boolean
          secondary?: boolean
        }>
      >
    }>
  >
  isTealbot: boolean
  readonly: boolean
  canClose: boolean
  canDelete: boolean
  canReopen: boolean
  onUpdateVet: (payload) => void
  onCancelVet: (id: string) => void
  onCloseVet: (id: string) => void
  onDeleteVet: (id: string) => void
  onReopenVet: (id: string) => void
  intl: IntlShape
}

type State = {
  vetName: string
  openConfirmationDialog: boolean
  confirmAction: string
  openContactUsDialog: boolean
  dontShowVetDetailsInfo: boolean
  openExportEmailsDialog: boolean
  groupBySupplier: boolean
  primaryOnly: boolean
  exportVet: boolean
}

export class VetHeader extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      vetName: this.props.vetName,
      openConfirmationDialog: false,
      confirmAction: '',
      openContactUsDialog: false,
      openExportEmailsDialog: false,
      groupBySupplier: false,
      primaryOnly: false,
      dontShowVetDetailsInfo: !!storageManager.getItem(
        'dontShowVetDetailsInfoStorageKey'
      ),
      exportVet: false
    }
  }

  debounceNameChange = debounce(vetDets => {
    this.props.onUpdateVet(vetDets)
  }, 6000)

  handleNameBlur = () => {
    this.debounceNameChange.clear()
    const { id, onUpdateVet } = this.props
    onUpdateVet({
      vetId: id,
      name: this.state.vetName
    })
  }

  handleNameChange = e => {
    this.setState({
      vetName: e.target.value
    })

    this.debounceNameChange({
      vetId: this.props.id,
      name: e.target.value
    })
  }
  handleClickCloseVetDetails = dontShowVetDetailsInfo => {
    storageManager.setItem(
      'dontShowVetDetailsInfoStorageKey',
      dontShowVetDetailsInfo
    )
    this.setState({
      dontShowVetDetailsInfo
    })
  }

  toggleConfirmationDialog = () => {
    this.setState({
      openConfirmationDialog: !this.state.openConfirmationDialog
    })
  }
  toggleContactUsDialog = () => {
    this.setState({
      openContactUsDialog: !this.state.openContactUsDialog
    })
  }
  confirmAction = action => {
    this.setState({
      confirmAction: action,
      openConfirmationDialog: true
    })
  }

  executeAction = () => {
    const { confirmAction } = this.state
    const { id, onDeleteVet, onCancelVet, onCloseVet, onReopenVet } = this.props

    if (confirmAction === 'delete') {
      onDeleteVet(id)
    } else if (confirmAction === 'cancel') {
      onCancelVet(id)
    } else if (confirmAction === 'close') {
      onCloseVet(id)
    } else if (confirmAction === 'reopen') {
      onReopenVet(id)
    }

    this.setState({
      confirmAction: '',
      openConfirmationDialog: false
    })
  }

  renderStatus = decision => {
    const { hasResponse } = this.props

    let color = ''
    if (decision === 'In Progress') {
      color = 'blue'
    } else if (decision === 'Sent') {
      color = hasResponse ? 'gold' : 'green'
    } else if (decision === 'Closed' || decision === 'Cancelled') {
      color = 'red'
    }

    const { intl } = this.props
    if (color === 'gold') {
      decision = 'Reviewing'
    }
    return (
      <div className={`dib br2 pa1 v-mid ${color}`}>
        {messages[camelCase(decision)]
          ? intl.formatMessage(messages[camelCase(decision)])
          : decision}
      </div>
    )
  }

  exportEmails = () => {
    const { groupBySupplier, primaryOnly } = this.state
    const { contactsExportData } = this.props

    const contactsToExport = primaryOnly
      ? contactsExportData.map(supplier =>
          supplier.updateIn(['exportContacts'], exportContacts =>
            exportContacts
              .filter(
                contact => contact.get('primary') || contact.get('secondary')
              )
              .map(contact => contact.get('email'))
          )
        )
      : contactsExportData.map(supplier =>
          supplier.updateIn(['exportContacts'], exportContacts =>
            exportContacts.map(contact => contact.get('email'))
          )
        )

    const formatOutput = groupBySupplier
      ? contactsToExport
      : contactsToExport.reduce((result, supplier) => {
          return result.updateIn([0, 'emails'], emails =>
            emails.concat(supplier.get('exportContacts'))
          )
        }, fromJS([{ emails: [] }]))

    exportData.exportCSV(
      formatOutput.toJS(),
      'export-emails',
      groupBySupplier,
      groupBySupplier ? 'csv' : 'txt',
      '; '
    )
  }

  toggleExportVet = () => {
    this.setState({
      exportVet: !this.state.exportVet
    })
  }

  render() {
    const {
      id,
      vetExportData,
      decision,
      canDelete,
      canClose,
      canReopen,
      readonly,
      redirectFrom,
      isMobile,
      isTealbot,
      contactsExportData,
      intl
    } = this.props

    const {
      vetName,
      dontShowVetDetailsInfo,
      openContactUsDialog,
      openExportEmailsDialog,
      groupBySupplier,
      primaryOnly
    } = this.state

    return (
      <>
        <CSSTransition
          in={!dontShowVetDetailsInfo}
          timeout={300}
          classNames='tealbotHelp'
          unmountOnExit
        >
          <div className='bg-white br1 ba b--black-10 mt3 flex mw8 center'>
            <div className='pa3-5 flex-auto'>
              <Text>
                <FormattedMessage
                  id='VetDetailsPage.RFItabsToOrganize'
                  defaultMessage='Welcome to RFI, there are five tabs to organize information:'
                />
              </Text>
              <ul>
                <li>
                  <Text>
                    <FormattedMessage
                      id='VetDetailsPage.DetailTab'
                      defaultMessage='Details: Provide an internal description, categorization and budget details, if applicable'
                    />
                  </Text>
                </li>
                <li>
                  <Text>
                    <FormattedMessage
                      id='VetDetailsPage.SuppliersTab'
                      defaultMessage='Suppliers: Use search tools to find and add new suppliers to your selection'
                    />
                  </Text>
                </li>
                <li>
                  <Text>
                    <FormattedMessage
                      id='VetDetailsPage.InvitationTab'
                      defaultMessage='Invitation: If you decide to collaborate with suppliers, this tab helps you manage the description, instructions and details suppliers see when you invite them'
                    />
                  </Text>
                </li>
                <li>
                  <Text>
                    <FormattedMessage
                      id='VetDetailsPage.ConversationTab'
                      defaultMessage='Review: Review suppliers and/or supplier submissions and then provide your feedback'
                    />
                  </Text>
                </li>
                <li>
                  <Text>
                    <FormattedMessage
                      id='VetDetailsPage.ActivityTab'
                      defaultMessage='Activities: Record overall notes and see the history of events and activities related to the RFI'
                    />
                  </Text>
                </li>
              </ul>
            </div>

            <Hidden smDown>
              <div className='ml3-ns w4 self-center'>
                <img src={TealbotCircleImage} alt='tealbot-tip' />
              </div>
            </Hidden>

            <div className='pa2'>
              <IconButton
                onClick={() => this.handleClickCloseVetDetails(true)}
                style={{
                  width: 32,
                  height: 32
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </CSSTransition>

        <header
          className='bg-tb-gray'
          style={{
            position: 'sticky',
            top: 50,
            zIndex: 50
          }}
        >
          <div className='mw8 w-100 center border-box pt3 pb1 dt ph3'>
            <div className={styles.nameInputWrapper}>
              <FormattedMessage
                id='VetHeader.UntitledRFIBuyer'
                defaultMessage='Untitled RFI'
              >
                {(message: string) => (
                  <input
                    value={vetName}
                    name='name'
                    type='text'
                    maxLength={150}
                    placeholder={message}
                    onChange={this.handleNameChange}
                    onBlur={this.handleNameBlur}
                    className={styles.nameInput}
                    disabled={readonly}
                  />
                )}
              </FormattedMessage>
            </div>

            <div className={styles.secondaryDetailsWrapper}>
              <CSSTransition
                in={dontShowVetDetailsInfo}
                timeout={100}
                classNames='tealbotHelp'
                unmountOnExit
              >
                <TealbotHelpIcon
                  show={dontShowVetDetailsInfo}
                  className='dib'
                  imageClassName='w2 v-mid pr2 dim pointer'
                  onClick={() => this.handleClickCloseVetDetails(false)}
                />
              </CSSTransition>
              {this.renderStatus(decision)}
              <DropdownMenu>
                <MenuItem onClick={this.toggleExportVet}>
                  <FormattedMessage
                    id='VetHeader.ExportRFI'
                    defaultMessage='Export RFI'
                  />
                </MenuItem>
                {vetExportData.data.length > 0 && !isMobile && (
                  <MenuItem
                    onClick={() =>
                      exportData.exportToCsv({
                        columns: vetExportData.columns,
                        data: vetExportData.data,
                        fileName: vetName
                      })
                    }
                  >
                    <FormattedMessage
                      id='VetHeader.ExportSuppliers'
                      defaultMessage='Export Suppliers'
                    />
                  </MenuItem>
                )}
                {isTealbot && contactsExportData.size > 0 && (
                  <MenuItem
                    onClick={() =>
                      this.setState({ openExportEmailsDialog: true })
                    }
                  >
                    <FormattedMessage
                      id='VetHeader.ExportEmails'
                      defaultMessage='Export Emails'
                    />
                  </MenuItem>
                )}
                {canClose && (
                  <MenuItem onClick={() => this.confirmAction('close')}>
                    <FormattedMessage
                      id='VetHeader.Close'
                      defaultMessage='Close'
                    />
                  </MenuItem>
                )}
                {(canReopen || canClose) && (
                  <MenuItem onClick={() => this.confirmAction('cancel')}>
                    <FormattedMessage id='CancelButton' />
                  </MenuItem>
                )}

                {canDelete && (
                  <MenuItem onClick={() => this.confirmAction('delete')}>
                    <FormattedMessage
                      id='VetHeader.Delete'
                      defaultMessage='Delete'
                    />
                  </MenuItem>
                )}
                {canReopen && (
                  <MenuItem onClick={() => this.confirmAction('reopen')}>
                    <FormattedMessage
                      id='VetHeader.Reopen'
                      defaultMessage='Reopen'
                    />
                  </MenuItem>
                )}
              </DropdownMenu>
            </div>
          </div>
          <div className='mw8 center ph3'>
            <VetNavBarContainer
              vetId={id}
              redirectFrom={redirectFrom}
              pathname={this.props.pathname}
            />
          </div>
          <Divider />
        </header>

        <ConfirmationDialog
          open={this.state.openConfirmationDialog}
          onClose={this.toggleConfirmationDialog}
          onConfirm={this.executeAction}
        >
          {this.state.confirmAction && (
            <Text>
              {intl.formatMessage(
                messages[`${this.state.confirmAction}Message`]
              )}
            </Text>
          )}
        </ConfirmationDialog>

        <VetContactUsDialogContainer
          notOnVet
          open={openContactUsDialog}
          onRequestClose={this.toggleContactUsDialog}
        />
        <Dialog
          open={openExportEmailsDialog}
          onClose={() => this.setState({ openExportEmailsDialog: false })}
        >
          <DialogTitle>
            <FormattedMessage
              id='VetDetailsPage.ExportContacts'
              defaultMessage='Export Emails'
            />
          </DialogTitle>
          <DialogContent>
            <div className='pt3'>
              <div className='mb2'>
                <Switch
                  label={
                    <FormattedMessage
                      id='VetDetailsPage.ExportGroupBySupplier'
                      defaultMessage='Group by supplier'
                    />
                  }
                  labelFontLight
                  checked={groupBySupplier}
                  onChange={() =>
                    this.setState({ groupBySupplier: !groupBySupplier })
                  }
                />
              </div>
              <div>
                <Switch
                  label={
                    <FormattedMessage
                      id='VetDetailsPage.ExportPrimaryOnly'
                      defaultMessage='Only export primary/secondary contact'
                    />
                  }
                  checked={primaryOnly}
                  labelFontLight
                  onChange={() => this.setState({ primaryOnly: !primaryOnly })}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoSize onClick={this.exportEmails}>
              <FormattedMessage
                id='VetDetailsPage.Export'
                defaultMessage='Export'
              />
            </Button>
            <Button
              autoSize
              secondary
              onClick={() => this.setState({ openExportEmailsDialog: false })}
            >
              <FormattedMessage id='CancelButton' />
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.exportVet && (
          <ExportDoc
            onSuccess={this.toggleExportVet}
            filename={vetName}
            exportComponent={<VetExportLayout />}
          />
        )}
      </>
    )
  }
}

export default injectIntl(VetHeader)
