import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

export const loadVet = createAction(actionTypes.LOAD_VET)
export const loadVetSuccess = createAction(actionTypes.LOAD_VET_SUCCESS)
export const loadVetFailure = createAction(actionTypes.LOAD_VET_FAILURE)

export const openVet = createAction(actionTypes.OPEN_VET)
export const declineVet = createAction(actionTypes.DECLINE_VET)
export const acceptVet = createAction(actionTypes.ACCEPT_VET)
export const updateVetProposal = createAction(actionTypes.UPDATE_VET_PROPOSAL)
export const showInterestVet = createAction(actionTypes.SHOW_INTEREST_VET)
export const acceptVetCriteria = createAction(actionTypes.ACCEPT_VET_CRITERIA)

export const uploadVetPresentation = createAction(
  actionTypes.UPLOAD_VET_PRESENTATION
)
export const removeVetPresentation = createAction(
  actionTypes.REMOVE_VET_PRESENTATION
)

export const addVetContact = createAction(actionTypes.ADD_VET_CONTACT)
export const removeVetContact = createAction(actionTypes.REMOVE_VET_CONTACT)
export const addVetQuestion = createAction(actionTypes.ADD_VET_QUESTION)
export const loadVetQuestions = createAction(actionTypes.LOAD_VET_QUESTIONS)
export const updateVetQuestion = createAction(actionTypes.UPDATE_VET_QUESTION)
export const removeVetQuestion = createAction(actionTypes.REMOVE_VET_QUESTION)
