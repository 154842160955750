import usersSelectors from '../../selectors/usersSelectors'
import RootState from 'shared/models/RootState'
import { RecordOf, fromJS } from 'immutable'
import User from 'shared/models/User'
import { createSelector } from 'reselect'
import sessionSelectors from '../../selectors/sessionSelectors'
import { LicenseAccess } from 'buyer/shared/reducers/settingsReducer'
import jwtDecode from 'jwt-decode'
import storageManager from '../../utils/storageManager'

const getSession = (state: RootState) => state.get('session')

export const isLoggedIn = state => !!getSession(state).loggedIn
export const statusIsKnown = state => !!getSession(state).statusIsKnown
export const getUserId = state => getSession(state).userId

export const getUser = state => {
  const userId = getUserId(state)
  return usersSelectors.getById(state, userId)
}

export const getOrgUnitId = state => {
  const user = getUser(state)
  return user.get('orgUnitId')
}

export const getUserProfileFormValues = createSelector(
  getUser,
  (user): RecordOf<User> => {
    return fromJS({
      id: user.get('id'),
      firstName: user.get('firstName'),
      lastName: user.get('lastName'),
      title: user.get('title'),
      department: user.get('department'),
      officePhone: user.get('officePhone'),
      mobilePhone: user.get('mobilePhone'),
      location: user.get('location'),
      linkedInUrl: user.get('linkedInUrl'),
      disableAllEmails: user.get('disableAllEmails')
    })
  }
)

type AppName = 'buyer' | 'supplier' | 'data-portal' | 'click-worker' | 'admin'
export const userHasAccessToApp = (state: RootState, appName: AppName) => {
  // admin app requires specific roles
  if (appName === 'admin') {
    return userHasRole(state, ['tealbot', 'buyerAdmin', 'supplierAdmin'])
  } else if (appName === 'data-portal') {
    return userHasRole(state, [
      'serverAdmin',
      'clientConcierge',
      'supplierConcierge',
      'orgAdmin',
      'partner'
    ])
  } else if (appName === 'click-worker') {
    return userHasRole(state, ['clickWorker', 'serverAdmin', 'orgAdmin', 'hil'])
  } else {
    // role and app names are the same
    return userHasRole(state, appName)
  }
}

export function userHasRole(state, role) {
  const userRoles = getUser(state).get('roles')
  if (!userRoles || !userRoles.size) {
    return false
  }

  if (typeof role === 'string') {
    return userRoles.some(r => r === role)
  } else if (role.length || role.size) {
    return role.some(r => userHasRole(state, r))
  }
}

export const userHasNoRole = state => {
  const roles = getUser(state).get('roles')
  //check if user doesn't have buyer or supplier role
  const supplierBuyerRole = roles.some(r => {
    return r === 'buyer' || r === 'supplier' || r === 'clickWorker'
  })
  return !roles || roles.size < 1 || !supplierBuyerRole
}

export const getAccessControl = state =>
  state.get('session').get('accessControl')

export const getUserAccess = createSelector(
  state => storageManager.getItem('accessToken') || '',
  state => sessionSelectors.userHasRole(state, 'buyer'),
  state => sessionSelectors.userHasRole(state, 'tealbot'),
  (accessToken, isBuyer, isTealbot): Partial<LicenseAccess> => {
    if (!accessToken) {
      return {}
    }
    const { accessRoles } = jwtDecode(accessToken)

    if (isTealbot) {
      return {
        accessNonRelatedSuppliers: true,
        accessSupplierSpend: true,
        accessExportBasic: true,
        accessExportAdvanced: true,
        accessExportAll: true,
        accessRFI: true,
        accessInsights: true,
        accessContacts: true,
        accessChat: true,
        accessNews: true,
        accessCommunities: true,
        accessDiversityReport: true,
        accessTier2: true
      }
    }

    return isBuyer
      ? accessRoles.reduce((config, access) => {
          config[access] = true
          return config
        }, {})
      : {}
  }
)

// const punchoutUsers = [
//   'User-5999286588276736-5629499534213120-5724160613416960',
//   'User-6459434134536192-6584922073071616-6501101055115264',
//   'User-6596550562807808-6098063756754944-6578994581995520',
//   'User-4770197769158656-6595675169619968-6401592341954560'
// ]
// export const getAccessControl = createSelector(getUserId, userId => {
//   return new AccessControlRecord({
//     access: punchoutUsers.includes(userId) ? 'punchout' : 'full'
//   })
// })
