import React from 'react'
import { FormattedMessage } from 'react-intl'
import Page from '../Page'
import TitleText from '../TitleText'
import Text from '../Text'
import AppBarContainer from '../../containers/AppBarContainer'
import UserNavContainer from '../../containers/UserNavContainer'

const MissingAgencyPage = () => {
  return (
    <div>
      <AppBarContainer userNav={<UserNavContainer />} homeUrl='/missing-agency'>
        <div className='flex-auto' />
      </AppBarContainer>
      <Page title={'Missing Agency'} className='tc'>
        <TitleText>
          <FormattedMessage
            id='MissingAgencyPage.Certificate Removed'
            defaultMessage='Certificate Removed'
          />
        </TitleText>
        <div className='mt4 mw6 center pa4 ba b--black-10 bg-white'>
          <Text>
            <FormattedMessage
              id='MissingAgencyPage.CertificateRemoved'
              defaultMessage="The certificate for this supplier has been removed from the source site, we think it's expired. So it can no longer be reviewed there."
            />
          </Text>
        </div>
      </Page>
    </div>
  )
}

export default MissingAgencyPage
