import { connect } from 'react-redux'
import { List } from 'immutable'
import dataSelectors from 'shared/selectors/dataSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import SupplierOnboardingPage from '../../components/SupplierOnboardingPage'
import qs from 'qs'
import withWidth from '@material-ui/core/withWidth'
import routingSelectors from 'shared/selectors/routingSelectors'

export default connect(state => {
  const targetClients = dataSelectors.getByEntity(state, 'targetClients')
  const publicTargets = targetClients && targetClients.get('publicTargets')
  const user = sessionSelectors.getUser(state)
  const search = routingSelectors.getSearch(state)
  const { step } = qs.parse(search, { ignoreQueryPrefix: true })
  return {
    buyers: publicTargets || List([]),
    firstName: user && user.get('firstName'),
    step: !isNaN(step) ? Number(step) : undefined
  }
})(withWidth()(SupplierOnboardingPage))
