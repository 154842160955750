import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import GetVerifiedForm from '../../../Company/containers/GetVerifiedForm'
import { FormattedMessage } from 'react-intl'

type Props = {
  open: boolean
  onClose: () => void
}

class GetVerifiedDialog extends Component<Props> {
  render() {
    const { open, onClose, ...rest } = this.props
    return (
      <Dialog open={open} onClose={onClose} {...rest}>
        <DialogTitle>
          <FormattedMessage
            id='GetVerifiedDialog.title'
            defaultMessage='Invite your contact to verify your company'
          />
        </DialogTitle>
        <DialogContent>
          <GetVerifiedForm />
        </DialogContent>
      </Dialog>
    )
  }
}

export default GetVerifiedDialog
