import React, { Component } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import iconPublic from 'shared/assets/icons/public.svg'
import iconPrivate from 'shared/assets/icons/private.svg'
import { FormattedMessage } from 'react-intl'

const styles = {
  selectMenu: {
    border: '1px #ccc solid',
    lineHeight: '26px',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5
  }
}

type Props = {
  onChange?: (value: boolean) => void
  value?: boolean
  input?: { name: string; onChange: (value: boolean) => void; value: boolean }
  meta?: { touched: boolean; error: string }
  classes?: { selectMenu: string }
  className?: string
}

export class SelectPublicPrivate extends Component<Props> {
  handleChange = e => {
    const { onChange, input } = this.props
    const value = e.target.value === 'private'
    if (onChange) {
      onChange(value)
    }

    if (input && input.onChange) {
      input.onChange(value)
    }
  }

  handleBlur = e => {
    e.stopPropagation()
  }

  render() {
    const { input, className, meta, classes, ...rest } = this.props
    const value =
      this.props.value || (input && input.value) ? 'private' : 'public'

    return (
      <div className={classNames('dib', className)}>
        <Select
          {...input}
          {...rest}
          onChange={this.handleChange}
          value={value}
          classes={classes}
          MenuProps={{
            style: {
              zIndex: 1600
            }
          }}
          disableUnderline
          autoWidth
          onBlur={this.handleBlur}
        >
          <MenuItem value='public'>
            <div className='f7 fw4'>
              <img src={iconPublic} alt='Public' className='w1 mr2' />
              <span className='mid-gray'>
                <FormattedMessage
                  id='SelectPublicPrivate.MyCompany'
                  defaultMessage='My Company'
                />
              </span>
            </div>
          </MenuItem>

          <MenuItem value='private'>
            <div className='f7 fw4'>
              <img src={iconPrivate} alt='Private' className='w1 mr2' />
              <span className='mid-gray'>
                <FormattedMessage
                  id='SelectPublicPrivate.Personal'
                  defaultMessage='Personal'
                />
              </span>
            </div>
          </MenuItem>
        </Select>
      </div>
    )
  }
}

export default withStyles(styles)(SelectPublicPrivate)
