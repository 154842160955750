import React from 'react'
import StarIcon from '@material-ui/icons/Star'
import IconButton from '@material-ui/core/IconButton'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import Divider from 'shared/components/Divider'
import Tooltip from 'shared/components/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import ClearIcon from '@material-ui/icons/Clear'
import startCase from 'lodash.startcase'

type LocationProps = {
  location: string
  onEdit?: () => void
  onDelete?: () => void
}

export const Location = (props: LocationProps) => {
  const { onEdit, onDelete, location } = props
  const { name, phone, address, type, primary } = JSON.parse(location)

  return (
    <div>
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <Label className='f7 fw6'>{name || 'Unnamed Location'}</Label>
          {primary && (
            <Tooltip title='Primary Location'>
              <StarIcon
                aria-label='primary'
                className='ml2'
                fontSize='small'
                color='primary'
              />
            </Tooltip>
          )}
        </div>
        <div>
          {onEdit && (
            <IconButton aria-label='edit' onClick={onEdit}>
              <EditIcon fontSize='small' color='primary' />
            </IconButton>
          )}
          {onDelete && (
            <IconButton aria-label='remove' onClick={onDelete}>
              <ClearIcon fontSize='small' />
            </IconButton>
          )}
        </div>
      </div>
      <Text>{address}</Text>
      {type && <Text>{startCase(type)}</Text>}
      {phone && <Text>{phone}</Text>}
      <Divider className='mv2' />
    </div>
  )
}

export default Location
