import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import Divider from 'shared/components/Divider'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Text from 'shared/components/Text'
import Scrollable from 'shared/components/Scrollable'
import ListForFollowUpEmail from '../ListForFollowUpEmail'
import Select from 'shared/components/Select'
import VetBuyerSupplier, { SendToContact } from 'shared/models/VetBuyerSupplier'
import { RecordOf, List, Map } from 'immutable'
type Props = {
  vbsToEmail: List<VetBuyerSupplier>
  vbs: Map<string, VetBuyerSupplier>
  currentUserEmail: string
  vetResponseDate: Date
  vetName: string
  vetId: string
}
const FollowUpEmail = (props: Props) => {
  const {
    vbsToEmail,
    vbs,
    currentUserEmail,
    vetResponseDate,
    vetName,
    vetId
  } = props
  const [openDialog, setOpenDialog] = useState(false)
  const [emailing, setEmailing] = useState<
    'primary' | 'primaryAndSecondary' | 'all'
  >('all')
  const [selectedVbsId, setSelectedVbsId] = useState<string>('')
  const getBCC = () => {
    const vetBs = vbs.get(selectedVbsId)
    if (vetBs && emailing) {
      const sendToContacts: List<RecordOf<SendToContact>> =
        vetBs.getIn(['vetBS', 'collaboration', 'sendToContacts']) || List([])
      const users = vetBs.get('users') || Map({})
      // this list of emails to contact is based on who the user wants to email:
      // everyone, primary, or primary and secondary contact
      return sendToContacts
        .filter(contact => {
          const user = contact.get('user') && users.get(contact.get('user'))
          return (
            user &&
            !!user.get('email') &&
            (emailing === 'all' ||
              (emailing === 'primary' && contact.get('primary')) ||
              contact.get('primary') ||
              contact.get('secondary'))
          )
        })
        .map(contact => {
          const user = users.get(contact.get('user'))
          return user && user.get('email')
        })
        .join()
    }
  }

  const handleComposeEmail = () => {
    if (emailing) {
      setOpenDialog(false)
      const subject = `${vetId} - ${vetName} from TealBook`
      const emailBody = `Hello,%0D%0A%0D%0AYou should have received an email from TealBook our proprietary supplier discovery and vetting platform about a new RFI.%0D%0A%0D%0ATo be considered, we are requesting you review your company profile in TealBook and then respond to the RFI. This is completely free to your company.%0D%0A%0D%0ATo register, visit 'https://app.tealbook.com/accounts/register'.%0D%0A%0D%0ASet up or update your company profile:%0D%0A%0D%0A  - Add tags for all the capabilities where your company has expertise.%0D%0A  - Upload a sales deck, if you have one (10 slide max).%0D%0A  - Add the appropriate contact information for this opportunity to the “Our Team” section of the profile.%0D%0A  - Click on RFI in the menu, open the RFI and follow the prompts.%0D%0A%0D%0APlease submit your response by ${new Date(
        vetResponseDate
      )}%0D%0A%0D%0AFor additional support with setting your TealBook profile contact support@tealbook.com.%0D%0A%0D%0AThanks in advance, we look forward to learning more about your company during this process.`

      const bcc = getBCC()
      window.location.href = `mailto:${currentUserEmail}?bcc=${bcc}&subject=${subject}&body=${emailBody}`
    }
  }

  const vetbs = vbs.get(selectedVbsId)
  const sendToContacts: List<RecordOf<SendToContact>> =
    (vetbs && vetbs.getIn(['vetBS', 'collaboration', 'sendToContacts'])) ||
    List([])
  return (
    <>
      <Divider className='mt4' />
      <Button
        className='mt3'
        label={
          <FormattedMessage
            id='VetSupplierListContainer.FollowUpEmail'
            defaultMessage='Send follow up email'
          />
        }
        onClick={() => setOpenDialog(true)}
      />
      <Dialog open={openDialog} fullWidth onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          <FormattedMessage
            id='FollowUpEmail.sendFollowUpEmail'
            defaultMessage='Send follow up email'
          />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            id='FollowUpEmail.chooseSuppliersToEmail'
            defaultMessage='Choose which suppliers to email'
          >
            {message => <Text className='fw6 f6'>{message}</Text>}
          </FormattedMessage>
          <Scrollable>
            <ListForFollowUpEmail
              onChangeSupplier={setSelectedVbsId}
              vbsToEmail={vbsToEmail}
              selectedVbsId={selectedVbsId}
            />
          </Scrollable>
          <Divider className='mt4' />
          <div className='f7 fw6 pt3 mid-gray dt w-100 mb3'>
            <div className='dtc'>Recipients</div>
          </div>
          <Select
            name='emailing'
            value={emailing}
            onChange={e => setEmailing(e.target.value)}
            fullWidth
          >
            {sendToContacts.some(contact => !!contact.get('primary')) && (
              <FormattedMessage
                id='FollowUpEmail.primaryContact'
                defaultMessage='Primary Contact'
              >
                {message => <option value='primary'>{message}</option>}
              </FormattedMessage>
            )}
            {sendToContacts.some(contact => !!contact.get('secondary')) && (
              <FormattedMessage
                id='FollowUpEmail.primarySecondaryContact'
                defaultMessage='Primary and Secondary Contact'
              >
                {message => (
                  <option value='primaryAndSecondary'>{message}</option>
                )}
              </FormattedMessage>
            )}
            {sendToContacts.size > 0 && (
              <FormattedMessage
                id='FollowUpEmail.allContacts'
                defaultMessage='All Contacts'
              >
                {message => <option value='all'>{message}</option>}
              </FormattedMessage>
            )}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            label={
              <FormattedMessage
                id='FollowUpEmail.ComposeEmail'
                defaultMessage='Compose Email'
              />
            }
            autoSize
            onClick={handleComposeEmail}
          />
          <Button
            label={<FormattedMessage id='CancelButton' />}
            autoSize
            secondary
            onClick={() => setOpenDialog(false)}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
export default FollowUpEmail
