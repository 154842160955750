import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import TextArea from 'shared/components/TextArea'
import Button from 'shared/components/Button'
import Avatar from 'shared/components/Avatar'
import {
  addVetQuestion,
  updateVetQuestion,
  removeVetQuestion
} from 'supplier/Vets/actions'
import RootState from 'shared/models/RootState'
import { FormattedMessage } from 'react-intl'

type Props = {
  vetBuyerSupplierId: string
  currentOrgUnitId: string
  profilePictureUrl?: string
  isEditing?: boolean
  onCancelEditing?: () => void
  questionId?: string
  description?: string
  firstName: string
  lastName: string
  addVetQuestion: typeof addVetQuestion
  updateVetQuestion: typeof updateVetQuestion
  removeVetQuestion: typeof removeVetQuestion
}

const VetQuestionForm: FunctionComponent<Props> = props => {
  const [description, setDescription] = useState<string>(
    props.description || ''
  )

  return (
    <div className='flex'>
      <div className='w2-5 mt3-5'>
        <Avatar
          url={props.profilePictureUrl}
          name={`${props.firstName || ''} ${props.lastName || ''}`}
        />
      </div>

      <form
        className='mt3-5 flex-auto pl3-5'
        onSubmit={e => {
          e.preventDefault()
          if (props.questionId) {
            props.updateVetQuestion({
              vetBuyerSupplierId: props.vetBuyerSupplierId,
              questionId: props.questionId,
              description
            })
          } else {
            props.addVetQuestion({
              vetBuyerSupplierId: props.vetBuyerSupplierId,
              description,
              isPublic: true,
              createdInOrgUnit: props.currentOrgUnitId
            })
          }
          if (props.onCancelEditing) {
            props.onCancelEditing()
          }
          setDescription('')
        }}
      >
        <TextArea
          value={description}
          onChange={e => setDescription(e.currentTarget.value)}
          required
        />
        <div className='flex items-center mt3'>
          <Button
            label={
              props.isEditing ? (
                <FormattedMessage
                  id='supplier/VetQuestionForm.Update'
                  defaultMessage='Update'
                />
              ) : (
                <FormattedMessage
                  id='supplier/VetQuestionForm.Ask'
                  defaultMessage='Ask'
                />
              )
            }
            color='primary'
            autoSize
            className='mr3'
            type='submit'
          />
          {props.isEditing && (
            <>
              <Button
                label={<FormattedMessage id='CancelButton' />}
                autoSize
                onClick={props.onCancelEditing}
                color='secondary'
              />
              <div className='flex-auto tr'>
                <Button
                  label={
                    <FormattedMessage
                      id='supplier/VetQuestionForm.Remove'
                      defaultMessage='Remove'
                    />
                  }
                  autoSize
                  onClick={() => {
                    props.removeVetQuestion({
                      vetBuyerSupplierId: props.vetBuyerSupplierId,
                      questionId: props.questionId
                    })
                  }}
                  color='secondary'
                  caution
                />
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default connect(
  (state: RootState) => ({
    vetBuyerSupplierId: state.getIn([
      'supplier',
      'currentVet',
      'vetBuyerSupplier',
      'id'
    ]),
    currentOrgUnitId: sessionSelectors.getOrgUnitId(state),
    profilePictureUrl: sessionSelectors.getUser(state).get('profilePictureUrl'),
    firstName: sessionSelectors.getUser(state).get('firstName'),
    lastName: sessionSelectors.getUser(state).get('lastName')
  }),
  {
    addVetQuestion,
    updateVetQuestion,
    removeVetQuestion
  }
)(VetQuestionForm)
