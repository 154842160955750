import React, { Component } from 'react'
import Avatar from 'shared/components/Avatar'
import Text from 'shared/components/Text'
import Link from 'shared/components/Link'
import paths from '../../../routes/paths'

type Props = {
  profilePictureUrl: string
  firstName: string
  lastName: string
  userId: string
}

class ConnectedTo extends Component<Props> {
  render() {
    const { profilePictureUrl, firstName, lastName, userId } = this.props
    return (
      <div className='dt w-100 mt1'>
        <div className='dtc w1-5'>
          <Link to={paths.userProfile} params={{ userId }}>
            <Avatar
              url={profilePictureUrl}
              className='dim pointer'
              name={`${firstName || ''} ${lastName || ''}`}
            />
          </Link>
        </div>
        <div className='dtc pl2'>
          <Text>
            {firstName} {lastName}
          </Text>
        </div>
      </div>
    )
  }
}

export default ConnectedTo
