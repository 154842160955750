import { fromJS, Record, RecordOf, List, Map } from 'immutable'
import { LOGIN } from 'accounts/actionTypes'
import * as sharedActionTypes from '../../actionTypes'
import {
  LOAD_SUPPLIER_PROFILE,
  ADD_CONTACT as ADD_SUPPLIER_CONTACT,
  ADD_OUTREACH_CONTACT
} from 'buyer/SupplierProfile/actionTypes'
import {
  LOAD_VET,
  ADD_VET_TEAM_MEMBER,
  ADD_VET_SUPPLIER_SUCCESS,
  ADD_CONTACT_SEND_TO
} from 'buyer/Vets/actionTypes'
import { ADD_CONTACT, UPDATE_CONTACT } from 'supplier/Company/actionTypes'
import { LOAD_COMMUNITIES } from 'supplier/Communities/actionTypes'
import { LOAD_POSTS } from 'posting/actionTypes'
import { ADD_VET_CONTACT } from 'supplier/Vets/actionTypes'
import {
  ADD_USER,
  BULK_ADD_USERS,
  BULK_DELETE_USERS,
  BULK_UPDATE_STATUS,
  BULK_UPDATE_ROLES
} from 'admin/actionTypes'
import { SEARCH_CONTACTS } from 'buyer/shared/actionTypes'
import { LOAD_VET_QUESTIONS } from 'buyer/Vets/actionTypes'
import parseUser from 'shared/utils/data/parseUser'
import mapValues from 'lodash.mapvalues'
import User from 'shared/models/User'
import { ADD_SURVEY_COLLABORATE_CONTACT } from 'supplier/shared/actionTypes'

type UserStateProps = {
  byId: Map<string, RecordOf<User>>
  colleaguesIds: List<string>
  allIdsBySupplier: Map<string, List<string>>
  isFetching: boolean
}

export type UsersState = RecordOf<UserStateProps>

// TODO: use user records
// const UserRecordFactory = Record<User>({
//   firstName: '',
//   lastName: '',
//   active: true,
//   blocked: false,
//   date: null,
//   isBuyer: false,
//   isSupplier: false,
//   email: '',
//   id: '',
//   lastModifiedBy: null,
//   lastModifiedDate: null,
//   officePhone: '',
//   orgId: '',
//   orgUnitId: '',
//   profilePictureUrl: '',
//   status: 'Blocked',
//   roles: List([])
// })

export const UsersRecordFactory = Record<UserStateProps>({
  byId: Map({}),
  colleaguesIds: List([]),
  allIdsBySupplier: Map({}),
  isFetching: false
})

const usersReducer = (
  state: UsersState = UsersRecordFactory(),
  action
): UsersState => {
  let users
  switch (action.type) {
    case sharedActionTypes.requestSuccess(LOGIN):
      return state.update('byId', byId =>
        byId.set(action.payload.userInfo.id, fromJS(action.payload.userInfo))
      )

    case sharedActionTypes.SELECT_USER_TO_MESSAGE:
      return action.payload.user
        ? state.update('byId', usersById =>
            usersById.set(
              action.payload.userToMessage,
              fromJS(action.payload.user)
            )
          )
        : state

    case ADD_VET_SUPPLIER_SUCCESS:
      const vbsIds =
        action.payload.vetBuyerSupplier &&
        Object.keys(action.payload.vetBuyerSupplier)
      users =
        vbsIds &&
        vbsIds.reduce((result, vbsId) => {
          return Object.assign(
            {},
            result,
            mapValues(action.payload.vetBuyerSupplier[vbsId].users, parseUser)
          )
        }, {})

      return users ? state.mergeIn(['byId'], fromJS(users)) : state

    case sharedActionTypes.requestSuccess(sharedActionTypes.LOAD_ALL_USERS):
      return state
        .set('colleaguesIds', fromJS(Object.keys(action.payload)))
        .mergeIn(['byId'], fromJS(action.payload))

    case sharedActionTypes.requestSuccess(ADD_OUTREACH_CONTACT):
    case sharedActionTypes.requestSuccess(ADD_SUPPLIER_CONTACT):
      return action.payload.newUser
        ? state
            .updateIn(
              ['allIdsBySupplier', action.payload.newUser.orgUnitId],
              ids => ids.push(action.payload.userId)
            )
            .setIn(
              ['byId', action.payload.userId],
              fromJS(action.payload.newUser)
            )
        : state

    case sharedActionTypes.requestSuccess(ADD_CONTACT_SEND_TO):
      return action.payload.newUser
        ? state.mergeIn(
            ['byId', action.payload.newUser.id],
            fromJS(action.payload.newUser)
          )
        : state

    case sharedActionTypes.requestSuccess(ADD_SURVEY_COLLABORATE_CONTACT):
    case sharedActionTypes.requestSuccess(ADD_VET_TEAM_MEMBER):
    case sharedActionTypes.requestSuccess(ADD_CONTACT):
    case sharedActionTypes.requestSuccess(ADD_VET_CONTACT):
      return action.payload.isCreatingNewUser
        ? state
            .update('colleaguesIds', colleaguesIds =>
              colleaguesIds.push(action.payload.user.id)
            )
            .setIn(
              ['byId', action.payload.user.id],
              fromJS(action.payload.user)
            )
        : state

    case sharedActionTypes.requestSuccess(UPDATE_CONTACT):
      return action.payload.user
        ? state.setIn(
            ['byId', action.payload.user.id],
            fromJS(action.payload.user)
          )
        : state

    case sharedActionTypes.requestSuccess(sharedActionTypes.LOAD_ORG):
    case sharedActionTypes.requestSuccess(LOAD_SUPPLIER_PROFILE):
      return state
        .setIn(
          ['allIdsBySupplier', action.payload.supplier.id],
          fromJS(Object.keys(action.payload.users))
        )
        .mergeIn(['byId'], fromJS(action.payload.users))

    case sharedActionTypes.requestSuccess(LOAD_VET):
      return state
        .mergeIn(
          ['allIdsBySupplier'],
          fromJS(action.payload.usersAllIdsBySupplier)
        )
        .mergeIn(['byId'], fromJS(action.payload.usersById))

    case sharedActionTypes.LOAD_USER:
      return state.set('isFetching', true)

    case sharedActionTypes.requestSuccess(sharedActionTypes.LOAD_USER):
      return action.payload.user
        ? state
            .update('byId', usersById =>
              usersById.set(
                action.payload.user.id,
                fromJS(action.payload.user).merge(
                  usersById.get(action.payload.user.id)
                )
              )
            )
            .set('isFetching', false)
        : state.set('isFetching', false)

    case sharedActionTypes.requestFailure(sharedActionTypes.LOAD_USER):
      return state.set('isFetching', false)

    case sharedActionTypes.requestSuccess(sharedActionTypes.UPDATE_USER):
      return state.mergeIn(['byId', action.payload.id], fromJS(action.payload))

    case sharedActionTypes.requestSuccess(sharedActionTypes.UPDATE_USER_ROLES):
      return state.setIn(
        ['byId', action.payload.userId, 'roles'],
        fromJS(action.payload.roles)
      )

    case sharedActionTypes.requestSuccess(LOAD_COMMUNITIES):
    case sharedActionTypes.requestSuccess(LOAD_POSTS):
    case sharedActionTypes.requestSuccess(
      sharedActionTypes.LOAD_MESSAGE_TOPICS
    ):
      users = action.payload.usersById || action.payload.usersWithConversation
      return state.update('byId', usersById =>
        usersById.mergeWith((oldUser, newUser) => {
          // prevent removing previously loaded data
          return newUser.merge(oldUser)
        }, fromJS(users))
      )

    case sharedActionTypes.requestSuccess(sharedActionTypes.DELETE_DATA):
      return action.payload.entity === 'users'
        ? state
            .deleteIn(['byId', action.payload.recordId])
            .update('colleaguesIds', ids =>
              ids.filter(id => id !== action.payload.recordId)
            )
        : state

    case sharedActionTypes.requestSuccess(ADD_USER):
      return action.payload.user
        ? state
            .setIn(
              ['byId', action.payload.user.id],
              fromJS(action.payload.user)
            )
            .update('colleaguesIds', ids =>
              ids
                ? ids.push(action.payload.user.id)
                : fromJS([action.payload.user.id])
            )
        : state

    case sharedActionTypes.requestSuccess(BULK_ADD_USERS):
      let idList: List<string> = List()
      let usersList = Map({})
      action.payload.successList.forEach(user => {
        idList = idList.push(user.id)
        usersList = usersList.set(user.id, fromJS(user))
      })

      return state
        .mergeDeepIn(['byId'], usersList)
        .mergeIn(['colleaguesIds'], idList)

    case sharedActionTypes.requestSuccess(SEARCH_CONTACTS):
      return action.payload.resultsById
        ? state.mergeDeepIn(
            ['byId'],
            fromJS(mapValues(action.payload.resultsById, parseUser))
          )
        : state

    case sharedActionTypes.requestSuccess(LOAD_VET_QUESTIONS):
      return action.payload.expanded.User
        ? state.mergeDeepIn(
            ['byId'],
            fromJS(mapValues(action.payload.expanded.User, parseUser))
          )
        : state

    case sharedActionTypes.requestSuccess(BULK_UPDATE_STATUS):
      return state.mergeDeepIn(['byId'], fromJS(action.payload.updatedUsers))

    case sharedActionTypes.requestSuccess(BULK_DELETE_USERS):
      return !!action.payload.listOfUserIds.length
        ? state
            .update('byId', usersById =>
              usersById.deleteAll(action.payload.listOfUserIds)
            )
            .update('colleaguesIds', ids =>
              ids.filter(id => !action.payload.listOfUserIds.includes(id))
            )
        : state

    case sharedActionTypes.requestSuccess(BULK_UPDATE_ROLES):
      return state.updateIn(['byId'], ids =>
        ids.map((users, id) =>
          action.payload.updatedUsers[id]
            ? users.set('roles', fromJS(action.payload.updatedUsers[id].roles))
            : users
        )
      )

    default:
      return state
  }
}

export default usersReducer
