import React, { Component } from 'react'
import { connect } from 'react-redux'
import Votes from '../../components/Votes'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { upVote, downVote } from '../../actions'
import { RecordOf, List } from 'immutable'

type Props = {
  vetBuyerSupplierId: string
  userId: string
  votes: RecordOf<{
    score: number
    upVotes: List<string>
    downVotes: List<string>
  }>
  readonly: boolean
  upVote: (vetBuyerSupplierId: string) => void
  downVote: (vetBuyerSupplierId: string) => void
}

class VotesContainer extends Component<Props> {
  onUpVote = () => this.props.upVote(this.props.vetBuyerSupplierId)

  onDownVote = () => this.props.downVote(this.props.vetBuyerSupplierId)

  render() {
    const { userId, votes, readonly } = this.props

    return (
      <div className='dtc w2 v-top'>
        <Votes
          userId={userId}
          readonly={readonly}
          score={votes.score}
          upVotes={votes.upVotes}
          downVotes={votes.downVotes}
          onUpVote={this.onUpVote}
          onDownVote={this.onDownVote}
        />
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  return {
    userId: sessionSelectors.getUserId(state),
    votes: currentVetSuppliersSelectors.getVoteResults(
      state,
      props.vetBuyerSupplierId
    ),
    readonly: currentVetSelectors.isReadonly(state)
  }
}
export default connect(mapStateToProps, { upVote, downVote })(VotesContainer)
