import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import AddTagForm from '../../components/AddTagForm'
import { ADD_TAG } from '../../actionTypes'
import { FormattedMessage } from 'react-intl'

type Props = {
  handleSubmit: (arg: string) => () => void
  addTag: string
  numberOfTags: number
}
export class DifferentiatorsAddTagForm extends Component<Props> {
  render() {
    const { handleSubmit, addTag, numberOfTags } = this.props

    return (
      <FormattedMessage
        id='DifferentiatorsAddTagForm.Differentiator'
        defaultMessage='Differentiator (ex: Innovative or ISO 9001)'
      >
        {message => (
          <AddTagForm
            inputId='differentiatorsNewTag'
            onSubmit={handleSubmit(addTag)}
            inputPlaceholder={message}
            footerNote={
              <FormattedMessage
                id='DifferentiatorsAddTagForm.TagsWillAlwaysRemainAnonymous'
                defaultMessage='NOTE: Your tags will always remain anonymous outside of your company.'
              />
            }
            numberOfTags={numberOfTags}
          />
        )}
      </FormattedMessage>
    )
  }
}

const formName = 'buyer/supplier/differentiators'

export default connectReduxForm(
  undefined,
  {
    addTag: createSubmitFormAction(formName, ADD_TAG)
  },
  {
    form: formName,
    initialValues: {
      tagsFieldName: 'differentiators'
    }
  },
  DifferentiatorsAddTagForm
)
