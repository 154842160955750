import React, { useState, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import numberFormat from 'shared/utils/numberFormat'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AddDiverseSupplierSpend from './AddDiverseSupplierSpend'
import SpendAllocationItem from './SpendAllocationItem'
import Tooltip from 'shared/components/Tooltip'
import ActionInfoOutline from '@material-ui/icons/InfoOutlined'
import SpendAllocationPreview from './SpendAllocationPreview'
import { useSelector } from 'react-redux'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import Text from 'shared/components/Text'
import ExternalLink from 'shared/components/ExternalLink'
import moment from 'moment'

type DiverseSupplierSpendAllocation = {
  id: string
  name: string
  spend: number
  allocation?: number
}

export type SpendAllocationProps = {
  quarter: number
  year: number
  totalDiverseSpend: number
  overallAllocation?: number
  diverseSupplierAllocations?: DiverseSupplierSpendAllocation[]
  communityPlanMembershipId: string
  onShareSpendAllocation: (spendAllocation: {
    communityPlanMembershipId: string
    quarter: number
    year: number
    overallAllocation?: number
    diverseSupplierAllocations: DiverseSupplierSpendAllocation[]
    attestedBy: string
    attestedDate: string
  }) => void
}

const SpendAllocation = (props: SpendAllocationProps) => {
  const { diverseSupplierAllocations } = props
  const initialAllocationValues = useMemo(
    () => diverseSupplierAllocations?.filter(ds => !!ds.allocation),
    [diverseSupplierAllocations]
  )
  const initialDiverseSuppliersToSuggest = useMemo(
    () => diverseSupplierAllocations?.filter(ds => !ds.allocation),
    [diverseSupplierAllocations]
  )

  const userId = useSelector(sessionSelectors.getUserId)

  const [overallAllocationValue, setOverallAllocationValue] = useState<
    number | undefined
  >(props.overallAllocation)
  const [allocationValues, setAllocationValues] = useState<
    DiverseSupplierSpendAllocation[]
  >(initialAllocationValues || [])
  const [diverseSuppliersToSuggest, setDiverseSuppliersToSuggest] = useState(
    initialDiverseSuppliersToSuggest || []
  )
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isAttest, setIsAttest] = useState<boolean>(false)
  const [showAttest, setShowAttest] = useState<boolean>(false)

  const { totalSelectedDiverseSpend, totalAllocatedDiverseSpend } = useMemo(
    () =>
      allocationValues.reduce(
        (agg, supplier) => ({
          totalSelectedDiverseSpend:
            agg.totalSelectedDiverseSpend + supplier.spend,
          totalAllocatedDiverseSpend:
            agg.totalAllocatedDiverseSpend +
            supplier.spend * ((supplier.allocation || 0) / 100)
        }),
        {
          totalSelectedDiverseSpend: 0,
          totalAllocatedDiverseSpend: 0
        }
      ),
    [allocationValues]
  )

  const overallSpendAmount = props.totalDiverseSpend - totalSelectedDiverseSpend
  const overallAllocatedSpend =
    overallSpendAmount * ((overallAllocationValue || 0) / 100)
  const canSubmitForm =
    isEditing || (!initialAllocationValues?.length && !props.overallAllocation)

  const handleShareConfirm = () => {
    setIsEditing(false)
    setShowAttest(false)
    props.onShareSpendAllocation({
      quarter: props.quarter,
      year: props.year,
      communityPlanMembershipId: props.communityPlanMembershipId,
      diverseSupplierAllocations: allocationValues,
      overallAllocation: overallAllocationValue,
      attestedBy: userId,
      attestedDate: moment().toISOString()
    })
  }

  const handleShareCancel = () => {
    setShowAttest(false)
    setIsAttest(false)
  }

  return (
    <>
      <form
        className='mt4'
        onSubmit={e => {
          e.preventDefault()
          if (canSubmitForm) {
            setShowAttest(true)
          } else {
            setIsEditing(true)
          }
        }}
      >
        <div className='flex mt3 fw6 f5 mid-gray items-center mt1 pa2 bg-near-white'>
          <h3 className='flex-auto mb0 mt0 fw6 f5'>
            <FormattedMessage
              id='SpendAllocation.DiverseSpendTitle'
              defaultMessage='{year} - Q{quarter} Diverse Spend'
              values={{
                year: props.year,
                quarter: props.quarter
              }}
            />
          </h3>
          <span>${numberFormat(props.totalDiverseSpend, 2)}</span>
        </div>
        <hr />

        <SpendAllocationItem
          title={
            <div className='fw6 flex items-center'>
              <FormattedMessage
                id='SpendAllocation.Indirect'
                defaultMessage='Indirect'
              />
              <Tooltip
                title={
                  <FormattedMessage
                    id='SpendAllocation.IndirectTooltip'
                    defaultMessage='This is the total diverse spend still available for allocation in the quarter after removing any direct spend.'
                  />
                }
              >
                <ActionInfoOutline className='ml2' fontSize='small' />
              </Tooltip>
            </div>
          }
          spendAmount={overallSpendAmount}
          allocation={overallAllocationValue}
          onChangeAllocation={setOverallAllocationValue}
          readOnly={!canSubmitForm}
        />

        {allocationValues.map(supplierAllocation => (
          <SpendAllocationItem
            key={supplierAllocation.id}
            title={
              <>
                {canSubmitForm && (
                  <IconButton
                    onClick={() => {
                      setDiverseSuppliersToSuggest(oldValue => [
                        ...oldValue,
                        supplierAllocation
                      ])
                      setAllocationValues(oldAllocations =>
                        oldAllocations.filter(
                          item => item.id !== supplierAllocation.id
                        )
                      )
                    }}
                  >
                    <CloseIcon aria-label='Close icon' fontSize='small' />
                  </IconButton>
                )}
                <span className='ml2'>{supplierAllocation.name}</span>
              </>
            }
            allocation={supplierAllocation.allocation}
            spendAmount={supplierAllocation.spend}
            onChangeAllocation={newAllocationValue =>
              setAllocationValues(oldAllocations =>
                oldAllocations.map(item =>
                  item.id === supplierAllocation.id
                    ? {
                        ...supplierAllocation,
                        allocation: newAllocationValue
                      }
                    : item
                )
              )
            }
            required
            readOnly={!canSubmitForm}
          />
        ))}

        <div className='flex items-center mt3 f5 fw6 mid-gray'>
          <div className='w-70 tr'>
            <FormattedMessage
              id='SpendAllocation.Total'
              defaultMessage='Total'
            />
          </div>
          <div className='flex-auto tr'>
            <FormattedMessage
              id='SpendAllocation.Shared'
              defaultMessage='Shared: {sharedSpendAmount}'
              values={{
                sharedSpendAmount: `$${numberFormat(
                  totalAllocatedDiverseSpend + overallAllocatedSpend,
                  2
                )}`
              }}
            />
          </div>
        </div>

        <div className='flex items-center mt3'>
          {canSubmitForm && (
            <h5 className='f7 fw6 mr4 flex items-center'>
              <FormattedMessage
                id='SpendAllocation.DirectSpend'
                defaultMessage='Direct Spend'
              />
              <Tooltip
                title={
                  <FormattedMessage
                    id='SpendAllocation.DirectSpendTooltip'
                    defaultMessage={
                      'Select diverse suppliers whose spend you can directly allocate to the customer. For each one, you can set the percentage. As you allocate direct spend, it is removed from the Indirect total.'
                    }
                  />
                }
              >
                <ActionInfoOutline className='ml2' fontSize='small' />
              </Tooltip>
            </h5>
          )}
          <div className='flex-auto'>
            {canSubmitForm && diverseSupplierAllocations && (
              <AddDiverseSupplierSpend
                readOnly={!canSubmitForm}
                diverseSuppliers={diverseSuppliersToSuggest}
                onClickSuggestedSupplier={newAllocationValue => {
                  setDiverseSuppliersToSuggest(oldValue =>
                    oldValue.filter(val => val.id !== newAllocationValue.id)
                  )
                  setAllocationValues(oldAllocationValues => [
                    ...oldAllocationValues,
                    newAllocationValue
                  ])
                }}
              />
            )}
          </div>
          <div className='w-30 flex items-center justify-end'>
            <SpendAllocationPreview
              communityPlanMembershipId={props.communityPlanMembershipId}
              year={props.year}
              quarter={props.quarter}
              overallAllocation={overallAllocationValue}
              diverseSupplierAllocations={allocationValues}
            />
            <Button
              type='submit'
              className='ml3'
              disabled={canSubmitForm && overallAllocationValue === undefined}
              label={
                !canSubmitForm ? (
                  <FormattedMessage
                    id='SpendAllocation.Edit'
                    defaultMessage='Edit'
                  />
                ) : (
                  <FormattedMessage
                    id='SpendAllocation.Share'
                    defaultMessage='Share'
                  />
                )
              }
            />
          </div>
        </div>
      </form>
      <ConfirmationDialog
        open={showAttest}
        onClose={handleShareCancel}
        disabled={!isAttest}
        onConfirm={handleShareConfirm}
      >
        <Text>
          <input
            type='checkbox'
            className='mr2 v-mid'
            checked={isAttest}
            onChange={() => setIsAttest(() => !isAttest)}
          />
          <FormattedMessage
            id='SpendAllocation.Reaffirms'
            defaultMessage='Supplier reaffirms its compliance with the {terms}, including the warranty of accuracy of all data provided.'
            values={{
              terms: (
                <ExternalLink href={`https://tealbook.com/terms`}>
                  <FormattedMessage
                    id='UserProfile.termsOfService'
                    defaultMessage='Terms of Service'
                  />
                </ExternalLink>
              )
            }}
          />
        </Text>
      </ConfirmationDialog>
    </>
  )
}

export default SpendAllocation
