import React, { useState, useMemo } from 'react'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import PageSection from 'shared/components/PageSection'
import Page from 'shared/components/Page'
import apiRoutes from '../../../shared/routes/apiRoutes'
import api from '../../../shared/utils/api'
import { List, Map } from 'immutable'
import Select from 'shared/components/Select'
import FileSaver from 'file-saver'

type Props = {
  clientFiles: List<Map<string, string>>
}

const ClientFilesDownload = ({ clientFiles }: Props) => {
  const [clientNameState, setClientName] = useState<string>('')
  const visibleFiles = useMemo(
    () =>
      clientFiles.filter(file => {
        if (!clientNameState) {
          return true
        }
        if (file && file.get('clientName') === clientNameState) {
          return true
        }
        return false
      }),
    [clientFiles, clientNameState]
  )

  const getRow = ({ index }) => {
    return visibleFiles && visibleFiles.get(index)
  }

  const renderActionsColumn = ({ cellData, rowData }: TableCellProps) => {
    const fileName = rowData.get('fileName')
    return (
      <div onClick={event => event.stopPropagation()}>
        <span
          className='pointer near-black hover-teal no-underline'
          onClick={() => handleDownload(cellData, fileName)}
        >
          Download
        </span>
      </div>
    )
  }
  const handleDownload = (fullFileName, fileName) => {
    api
      .download(`${apiRoutes.loader}/loader/clientFiles/${fullFileName}`)
      .then(response => response.blob())
      .then(blob => {
        FileSaver.saveAs(blob, `${fileName}`)
      })
      .catch(err => console.log('err', err))
  }
  const handleFilterByClientName = e => {
    setClientName(e.target.value)
  }
  const selectByClientName = useMemo(
    () =>
      clientFiles &&
      clientFiles
        .reduce<Array<string>>((result, currentItem) => {
          let currentClient = currentItem && currentItem.get('clientName')
          if (currentClient && result.indexOf(currentClient) < 0) {
            result.push(currentClient)
          }
          return result
        }, [])
        .sort(),
    [clientFiles]
  )
  return (
    <Page title='ClientFiles'>
      <PageSection
        noPadding
        title={
          <div className='tr'>
            <span>Client Files</span>
            <div className='w5 dib ml2'>
              <Select fullWidth onChange={handleFilterByClientName}>
                <option value=''>All Clients</option>
                {selectByClientName.map(name => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        }
      >
        {visibleFiles.size > 0 && (
          <Table
            minWidth={990}
            rowGetter={getRow}
            rowCount={visibleFiles.size}
            rowClassName='pointer dim'
          >
            <Column
              label='Date Created'
              dataKey='date'
              width={100}
              cellRenderer={({ cellData }: TableCellProps) =>
                cellData.slice(0, 10)
              }
            />
            <Column label='Client Name' dataKey='clientName' width={200} />
            <Column label='File Name' dataKey='fileName' width={200} />
            <Column label='Uploaded By' dataKey='email' width={250} />
            <Column
              dataKey='fullFileName'
              width={100}
              cellRenderer={renderActionsColumn}
            />
          </Table>
        )}
      </PageSection>
    </Page>
  )
}

export default ClientFilesDownload
