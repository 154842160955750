const basePath = '/data-portal'

const paths = {
  basePath,
  connectUsers: `${basePath}/connect-users`,
  tealbot: `${basePath}/tealbot`,
  tasks: `${basePath}/tasks`,
  loader: `${basePath}/loader`,
  utilities: `${basePath}/utilities`,
  indexing: `${basePath}/indexing`,
  forbiddenDomains: `${basePath}/forbidden-domains`,
  clientFiles: `${basePath}/download`,
  clientStats: `${basePath}/statistics/clientStats`,
  supplierStats: `${basePath}/statistics/supplierStats`,
  alliancesStats: `${basePath}/statistics/alliancesStats`,
  statistics: `${basePath}/statistics`,
  supplierMatching: `${basePath}/supplier-matching`,
  supplierMatchingSerp: `${basePath}/supplier-matching/serp`,
  supplierMatchingMl: `${basePath}/supplier-matching/Ml`,
  dataQualityService: `${basePath}/data-quality-service`,
  dataQualityServiceResults: `${basePath}/data-quality-service/results`
}

export default paths
