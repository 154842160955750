import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import job from 'shared/utils/api/job'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import parsePath from 'shared/utils/parsePath'
import paths from '../routes'

export function* openJobSaga(action) {
  const { jobId, rowNum } = action.payload

  // load job details and existing answers
  const jobDetails = yield call(job.getJobById, jobId)
  const jobResults = yield call(job.getJobResultsById, jobId)

  // determine number of rows and which rows have answers
  const numRows = jobDetails?.rows.length
  const answeredRows = jobResults?.map(result => result.rowNum)

  const { rows, questions, ...restDetails } = jobDetails

  // determine which row to open based on what complete
  let openRow
  if (jobDetails.type !== 'hil') {
    openRow =
      answeredRows?.length >= numRows
        ? -1
        : isNaN(Number(rowNum)) || Number(rowNum) >= numRows
        ? 0
        : Number(rowNum)

    while (
      openRow !== -1 &&
      answeredRows?.length &&
      answeredRows.includes(openRow)
    ) {
      if (openRow >= numRows) {
        openRow = 0
      } else {
        openRow++
      }
    }
  }

  // parse url based on openRow
  let url
  url = parsePath(paths.detail, { jobId, row: openRow })

  yield put(push(url))

  return yield {
    jobId,
    job: restDetails,
    rows,
    questions,
    jobResults
  }
}

export default createApiRequestSaga(openJobSaga)
