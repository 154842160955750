import React, { Component, ReactNode } from 'react'
import Page from 'shared/components/Page'
import Button from 'shared/components/Button'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Divider from 'shared/components/Divider'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'

type Props = {
  onDropIndex: () => void
  onReIndexSuppliers: () => void
  onReIndexUsers: () => void
  onAddUserMapping: () => void
  onBackupDatastore: () => void
  onFlushCache: (flushKey: string) => void
  onRunPatch: (patchBody: string) => void
  inProduction?: boolean
}

type DialogProps = {
  type?:
    | 'dropIndex'
    | 'flushCache'
    | 'reindexSuppliers'
    | 'reindexUsers'
    | 'addUserMapping'
    | 'backupDatastore'
    | 'patch'
  title?: string
  content?: ReactNode
  buttonLabel?: string
}

type State = {
  openDialog: boolean
  selectedDialog: DialogProps
  flushKey: string
  patchBody: string
}

class IndexingPage extends Component<Props, State> {
  state: State = {
    flushKey: 'daily.*',
    patchBody: '',
    openDialog: false,
    selectedDialog: {}
  }

  handleOpenDialog = dialog => {
    this.setState({
      openDialog: true,
      selectedDialog: dialog
    })
  }

  handleCloseDialog = () => {
    this.setState({
      openDialog: false
    })
  }

  handleClickDialogAction = () => {
    const { selectedDialog } = this.state
    const {
      onDropIndex,
      onReIndexSuppliers,
      onReIndexUsers,
      onAddUserMapping,
      onBackupDatastore,
      onFlushCache
    } = this.props

    if (selectedDialog.type === 'dropIndex') {
      onDropIndex()
    } else if (selectedDialog.type === 'reindexSuppliers') {
      onReIndexSuppliers()
    } else if (selectedDialog.type === 'reindexUsers') {
      onReIndexUsers()
    } else if (selectedDialog.type === 'addUserMapping') {
      onAddUserMapping()
    } else if (selectedDialog.type === 'backupDatastore') {
      onBackupDatastore()
    } else if (selectedDialog.type === 'flushCache') {
      onFlushCache(this.state.flushKey)
    } else if (selectedDialog.type === 'patch') {
      this.props.onRunPatch(this.state.patchBody)
    }

    this.handleCloseDialog()
  }

  renderSection = (dialog: DialogProps) => {
    return (
      <Grid item xs={12} sm={6} key={dialog.type}>
        <form
          onSubmit={e => {
            e.preventDefault()
            this.handleOpenDialog(dialog)
          }}
        >
          <Card className='h-100'>
            <CardContent>
              <Typography variant='h5' gutterBottom>
                {dialog.title}
              </Typography>
              <div style={{ minHeight: 50 }}>
                <Typography>{dialog.content}</Typography>
              </div>
              {dialog.type === 'flushCache' && (
                <>
                  <Label className='db mb1 f7 fw6'>Flush Key</Label>
                  <Input
                    value={this.state.flushKey}
                    onChange={e =>
                      this.setState({ flushKey: e.currentTarget.value })
                    }
                  />
                </>
              )}
              {dialog.type === 'patch' && (
                <>
                  <Label className='db mb1 f7 fw6'>Name of Patch (path)</Label>
                  <Input
                    required
                    value={this.state.patchBody}
                    onChange={e =>
                      this.setState({ patchBody: e.currentTarget.value })
                    }
                  />
                </>
              )}
            </CardContent>
            <CardActions style={{ paddingBottom: 12 }}>
              <Button type='submit' size='large'>
                {dialog.buttonLabel}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Grid>
    )
  }

  render() {
    const { inProduction } = this.props
    const { openDialog, selectedDialog } = this.state

    return (
      <Page title='Indexing'>
        <Typography variant='h6' gutterBottom>
          Suppliers
        </Typography>
        <Grid container spacing={2} className='pb3'>
          {this.renderSection({
            type: 'dropIndex',
            title: 'Drop Index',
            content:
              'This will drop all of the suppliers indexed in Elastic search. Please proceed with caution.',
            buttonLabel: 'Drop Index'
          })}

          {this.renderSection({
            type: 'reindexSuppliers',
            title: 'Re-Index Suppliers',
            content:
              'This will re-index all of the suppliers indexed in Elastic search. Please proceed with caution.',
            buttonLabel: 'Re-index all Suppliers'
          })}
        </Grid>

        <Typography variant='h6' gutterBottom>
          Users
        </Typography>
        <Grid container spacing={2} className='pb3'>
          {this.renderSection({
            type: 'addUserMapping',
            title: 'Drop User Index',
            content:
              'This will drop all of the users indexed in Elastic search. Please proceed with caution.',
            buttonLabel: 'Drop User Index'
          })}

          {this.renderSection({
            type: 'reindexUsers',
            title: 'Re-Index Users',
            content:
              'This will re-index all of the users indexed in Elastic search. Please proceed with caution.',
            buttonLabel: 'Re-index all Users'
          })}
        </Grid>

        <Typography variant='h6' gutterBottom>
          Server
        </Typography>
        <Grid container spacing={2}>
          {this.renderSection({
            type: 'flushCache',
            title: 'Flush The Cache',
            content:
              'This will flush the cache on server. Please proceed with caution.',
            buttonLabel: 'Flush The Cache'
          })}

          {this.renderSection({
            type: 'patch',
            title: 'Patch',
            content: 'This will run a patch.',
            buttonLabel: 'Run the Patch'
          })}
        </Grid>

        {inProduction && (
          <>
            <Divider className='mv4 ' />
            <Typography variant='h6' gutterBottom>
              Server
            </Typography>
            <Grid container spacing={2}>
              {this.renderSection({
                type: 'backupDatastore',
                title: 'Backup',
                content:
                  'Make a backup of the production datastore and deploy in BigQuery and Development system.',
                buttonLabel: 'Backup'
              })}
            </Grid>
          </>
        )}

        <Dialog open={openDialog} onClose={this.handleCloseDialog}>
          <DialogTitle>{selectedDialog.title}</DialogTitle>
          <DialogContent className='mt3'>
            {selectedDialog.content}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickDialogAction}>
              {selectedDialog.buttonLabel}
            </Button>
            <Button secondary onClick={this.handleCloseDialog}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    )
  }
}

export default IndexingPage
