import supplierPaths from '../../routes/paths'

const basePath = supplierPaths.communities

const paths = {
  basePath,
  community: `${basePath}/:communityId`,
  communityFormBasePath: `${basePath}/:communityId/form`,
  beginCommunityForm: `${basePath}/:communityId/begin-form`,
  communityForm: `${basePath}/:communityId/form/:pageNum`,
  finishCommunityForm: `${basePath}/:communityId/finish-form`
}

export default paths
