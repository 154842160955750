import React, { Component } from 'react'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import Main from 'shared/components/Layout/Main'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'

type Props = {
  name: string
  onCancel: () => void
  onBegin: () => void
}

class BeginCommunityFormPage extends Component<Props> {
  render() {
    const { name, onBegin, onCancel } = this.props

    return (
      <Page
        title={
          <FormattedMessage
            id='BeginCommunityFormPage.BeginCommunityFormTitle'
            defaultMessage='Begin Community Form for {name}'
            values={{ name }}
          />
        }
      >
        <Main>
          <PageSection
            title={
              <FormattedMessage
                id='BeginCommunityFormPage.CommunitySignUpTitle'
                defaultMessage='{name} Community Sign Up'
                values={{ name }}
              />
            }
          >
            <Text className='mt3-5'>
              <FormattedMessage
                id='BeginCommunityFormPage.ConnectToCommunity'
                defaultMessage='To connect to the {name} community please complete the sign up
                questionnaire. This allows {name} to get a better understanding
                of your company.'
                values={{ name }}
              />
            </Text>
            <div className='mt3-5'>
              <Button
                label={
                  <FormattedMessage
                    id='BeginCommunityFormPage.BeginLabel'
                    defaultMessage='Begin'
                  />
                }
                onClick={onBegin}
                autoSize
                className='mr3'
              />
              <Button
                label={<FormattedMessage id='CancelButton' />}
                onClick={onCancel}
                secondary
                autoSize
              />
            </div>
          </PageSection>
        </Main>
      </Page>
    )
  }
}

export default BeginCommunityFormPage
