import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import dataSelectors from '../../selectors/dataSelectors'
import ListItem, { ListItemProps } from '../../components/List/ListItem'
import OrgLogo from '../../components/OrgLogo'
import fileToUrlImmutable from '../../utils/data/fileToUrlImmutable'
import Link from '../../components/Link'
import ExternalLink from '../../components/ExternalLink'
import { List } from 'immutable'

export type OrgUnitProps = {
  id?: string
  name?: string
  profileUrl?: string
  logoUrl?: string
  address?: string
  domains?: List<string>
  showDetails?: boolean
  isClient?: boolean
} & Partial<ListItemProps>

export class OrgUnit extends Component<OrgUnitProps> {
  renderWithLink(children) {
    const { profileUrl } = this.props

    return profileUrl ? <Link to={profileUrl}>{children}</Link> : children
  }

  render() {
    const { name, logoUrl, address, domains, showDetails, ...rest } = this.props

    return (
      <ListItem
        mini
        leftAvatar={this.renderWithLink(<OrgLogo url={logoUrl} name={name} />)}
        primaryText={this.renderWithLink(name)}
        secondaryText={
          showDetails && (
            <Fragment>
              {domains && domains.size > 0 && (
                <div className='mb2'>
                  {domains.map(domain => (
                    <ExternalLink
                      key={domain}
                      href={domain}
                      className='gray f8 fw3 dim no-underline pointer hover-teal pr3 dib'
                    >
                      {domain}
                    </ExternalLink>
                  ))}
                </div>
              )}
              <div>{address}</div>
            </Fragment>
          )
        }
        {...rest}
      />
    )
  }
}

export default connect((state, props: OrgUnitProps) => {
  const orgUnitDataKey = props.isClient ? 'buyer' : 'supplier'
  const orgUnit = dataSelectors.getById(state, 'orgUnits', props.id)

  const domains = orgUnit.get('domains')
  const logoUrl =
    fileToUrlImmutable(orgUnit.getIn([orgUnitDataKey, 'logo'])) ||
    fileToUrlImmutable(orgUnit.getIn(['supplier', 'logo'])) ||
    (domains && domains.size > 0
      ? `https://logo.clearbit.com/${domains.first()}`
      : '')

  return {
    name: orgUnit.get('name') || props.name,
    address: orgUnit.getIn([orgUnitDataKey, 'locations', 0, 'address']),
    domains,
    logoUrl
  }
})(OrgUnit)
