import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import MyVets from '../../components/MyVets'
import { loadVets } from 'supplier/shared/actions'
import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import paths from '../../routes/paths'
import supplierPaths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import { RouteComponentProps } from 'react-router'
import { RecordOf, List, fromJS } from 'immutable'

type Props = {
  loadVets: () => void
  vets: List<
    RecordOf<{
      companyName: string
      companyOrgUnit: string
      key: string
      lastUpdate: Date
      name: string
      status: string
      statusDate: string
      submitResponseBy: Date
    }>
  >
  isLoading: boolean
} & RouteComponentProps

export const MyVetsContainer: FunctionComponent<Props> = props => {
  const { loadVets } = props

  useEffect(() => {
    loadVets()
  }, [loadVets])

  const handleClickVet = id => {
    const { vets } = props
    const status =
      vets && (vets.find(i => i.get('key') === id) || fromJS({})).get('status')
    switch (status) {
      case 'Invited':
        props.history.push(
          `${parsePath(paths.vetTerms, {
            vetBuyerSupplierId: id
          })}?redirectFrom=${supplierPaths.vets}`
        )
        break
      case 'Chosen':
      case 'Not Chosen':
      case 'Declined':
      case 'Accepted':
      case 'Cancelled':
      case 'Closed':
        props.history.push(
          `${parsePath(paths.vetOutcome, {
            vetBuyerSupplierId: id
          })}?redirectFrom=${supplierPaths.vets}`
        )
        break
      default:
        props.history.push(
          `${parsePath(paths.vetDetails, {
            vetBuyerSupplierId: id
          })}?redirectFrom=${supplierPaths.vets}`
        )
        break
    }
  }

  const { vets, isLoading } = props
  return (
    <MyVets onClickVet={handleClickVet} vets={vets} isLoading={isLoading} />
  )
}

export default connect(
  state => ({
    vets: vetBuyerSupplierSelectors.getVetsList(state),
    isLoading: vetBuyerSupplierSelectors.getVets(state).get('isLoading')
  }),
  {
    loadVets
  }
)(MyVetsContainer)
