import { connect } from 'react-redux'
import DiversityReportSummary from 'shared/components/DiversityReportSummary'
import insightsSelectors from '../../store/insightsSelectors'
import RootState from 'shared/models/RootState'

type ConnectProps = {
  range: string
  totalSpend: number
  isLoading: boolean
}

export default connect((state: RootState, props: ConnectProps) => {
  const overview = insightsSelectors.getTier2Overview(state, props.range)
  const uniqueQualifiedTier2Spend = insightsSelectors.getUniqueQualifiedTier2Spend(
    state,
    props.range
  )
  const spendItems = overview.spendItems
  const mbe =
    spendItems && spendItems.find(item => item.get('subCategory') === 'mbe')
  const sbe =
    spendItems && spendItems.find(item => item.get('subCategory') === 'sbe')
  const wbe =
    spendItems && spendItems.find(item => item.get('subCategory') === 'wbe')
  const vbe =
    spendItems && spendItems.find(item => item.get('subCategory') === 'vbe')
  return {
    tier2Spend: true,
    qualifiedSpend: uniqueQualifiedTier2Spend,
    mbeSpend: mbe && mbe.get('qualifiedAmount'),
    sbeSpend: sbe && sbe.get('qualifiedAmount'),
    wbeSpend: wbe && wbe.get('qualifiedAmount'),
    vbeSpend: vbe && vbe.get('qualifiedAmount'),
    mbeCount: mbe && mbe.get('count'),
    sbeCount: sbe && sbe.get('count'),
    wbeCount: wbe && wbe.get('count'),
    vbeCount: vbe && vbe.get('count')
  }
})(DiversityReportSummary)
