import { fromJS } from 'immutable'
import { CONTACTS_UPLOAD } from '../../actionTypes'

const mobileContactsReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case CONTACTS_UPLOAD:
      const contacts = fromJS(action.payload || []).filter(c => c.size > 0)
      return state.set('mobileContacts', contacts)
    default:
      return state
  }
}

export default mobileContactsReducer
