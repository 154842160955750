import React, { Component } from 'react'
import { connect } from 'react-redux'
import vetBuyerSupplierSelectors from '../../../Vets/selectors/vetBuyerSupplierSelectors'
import PageSideSection from 'shared/components/PageSideSection'
import Scrollable from 'shared/components/Scrollable'
import ListItem from 'shared/components/List/ListItem'
import Link from 'shared/components/Link'
import paths from 'supplier/Vets/routes/paths'
import Text from 'shared/components/Text'
import dateFormat from 'shared/utils/dateFormat'
import Vet from 'shared/models/Vet'
import { RecordOf, List } from 'immutable'

type Props = {
  activeVets: List<RecordOf<Vet>>
  inactiveVets: List<RecordOf<Vet>>
} & ContainerProps

export class VetsByBuyerContainer extends Component<Props> {
  render() {
    const { activeVets, inactiveVets } = this.props

    return (activeVets && activeVets.size > 0) ||
      (inactiveVets && inactiveVets.size > 0) ? (
      <PageSideSection title={`RFIs`} style={{ marginTop: 1 }}>
        <Scrollable>
          {activeVets && activeVets.size > 0 && (
            <div className='bb f7 fw6 pt3 b--mid-gray mid-gray dt w-100'>
              <div className='dtc'>Active</div>
            </div>
          )}
          {activeVets.map(vet => {
            return (
              <ListItem
                key={vet.get('key')}
                primaryText={
                  <Link
                    to={paths.vetDetails}
                    params={{ vetBuyerSupplierId: vet.key }}
                  >
                    <h5 className='f7 fw4 dim teal ma0'>{vet.get('name')}</h5>
                  </Link>
                }
                secondaryText={
                  <Text secondary>
                    {vet.get('status')}{' '}
                    {vet.get('statusDate') &&
                      ` on ${dateFormat(vet.get('statusDate'))}`}
                  </Text>
                }
                mini
              />
            )
          })}
          {inactiveVets && inactiveVets.size > 0 && (
            <div className='bb f7 fw6 pt3 b--mid-gray mid-gray dt w-100'>
              <div className='dtc'>Inactive</div>
            </div>
          )}
          {inactiveVets.map(vet => {
            return (
              <ListItem
                key={vet.get('key')}
                primaryText={
                  <Link
                    to={paths.vetDetails}
                    params={{ vetBuyerSupplierId: vet.key }}
                  >
                    <h5 className='f7 fw4 dim teal ma0'>{vet.get('name')}</h5>
                  </Link>
                }
                secondaryText={
                  <Text secondary>
                    {vet.get('status')}{' '}
                    {vet.get('statusDate') &&
                      ` on ${dateFormat(vet.get('statusDate'))}`}
                  </Text>
                }
                mini
              />
            )
          })}
        </Scrollable>
      </PageSideSection>
    ) : null
  }
}

type ContainerProps = {
  orgUnit: string
}

export default connect((state, props: ContainerProps) => {
  const activeVets = vetBuyerSupplierSelectors.getActiveVetsByBuyer(
    state,
    props.orgUnit
  )
  const inactiveVets = vetBuyerSupplierSelectors.getInActiveVetsByBuyer(
    state,
    props.orgUnit
  )

  return {
    activeVets,
    inactiveVets
  }
})(VetsByBuyerContainer)
