import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import AddTagForm from '../../components/AddTagForm'
import { ADD_TAG } from '../../actionTypes'
import { FormattedMessage } from 'react-intl'

type Props = {
  handleSubmit: (arg: string) => () => void
  addTag: string
  numberOfTags: number
}
export class PrivateOfferingsAddTagForm extends Component<Props> {
  render() {
    const { handleSubmit, addTag, numberOfTags } = this.props

    return (
      <FormattedMessage
        id='PrivateOfferingsAddTagForm.PrivateOfferings'
        defaultMessage='Offering (ex: Marketing)'
      >
        {message => (
          <AddTagForm
            onSubmit={handleSubmit(addTag)}
            inputPlaceholder={message}
            footerNote={
              <FormattedMessage
                id="PrivateOfferingsAddTagForm.NoteYourTagsAren'tVisibile"
                defaultMessage="NOTE: These tags aren't visible outside of your company."
              />
            }
            numberOfTags={numberOfTags}
          />
        )}
      </FormattedMessage>
    )
  }
}

const formName = 'buyer/supplier/privateOfferings'

export default connectReduxForm(
  undefined,
  {
    addTag: createSubmitFormAction(formName, ADD_TAG)
  },
  {
    form: formName,
    initialValues: {
      tagsFieldName: 'privateOfferings'
    }
  },
  PrivateOfferingsAddTagForm
)
