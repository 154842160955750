import { createSelector } from 'reselect'
import RootState from 'shared/models/RootState'
import Job, { JobOrgUnit, JobOrgUnitAnswers } from 'shared/models/Job'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { List, RecordOf, Map } from 'immutable'
import User from 'shared/models/User'

export const isLoading = (state: RootState) => state.getIn(['jobs', 'loading'])

export const getJobById: (
  state: RootState,
  jobId: string
) => undefined | RecordOf<Job> = (state: RootState, jobId: string) =>
  state.getIn(['jobs', 'byId', jobId])

const isJobVisible = (user: RecordOf<User>, job: RecordOf<Job>) => {
  if (
    job.get('owners')?.includes(user.get('id')) ||
    job.get('acceptedEmails')?.includes(user.get('email')) ||
    user.get('roles').includes('orgAdmin')
  ) {
    return true
  }
  if (
    job.get('type') === 'hil' &&
    user.get('roles').includes('hil') &&
    job.get('status') === 'available'
  ) {
    return true
  }
  if (
    job.get('type') !== 'hil' &&
    user.get('roles').includes('clickWorker') &&
    job.get('status') === 'available'
  ) {
    return true
  }

  return false
}

export const getJobs = createSelector(
  (state: RootState) => state.getIn(['jobs', 'byId']),
  sessionSelectors.getUser,
  (byId: Map<string, RecordOf<Job>>, user: RecordOf<User>) => {
    const jobs = byId?.toList()
    return jobs
      ?.filter((j: RecordOf<Job>) => isJobVisible(user, j))
      .sort((j1, j2) => (j1.get('name') > j2.get('name') ? 1 : -1))
  }
)

export const getAvailableJobs = createSelector(getJobs, jobs =>
  jobs.filter(j => j.get('status') === 'available').map(j => j.get('id'))
)

export const getActiveJobs = createSelector(getJobs, jobs =>
  jobs.filter(j => j.get('status') === 'inProgress').map(j => j.get('id'))
)

export const getCompleteJobs = createSelector(getJobs, jobs =>
  jobs.filter(j => j.get('status') === 'complete').map(j => j.get('id'))
)

export const getAllCompleteJobs = createSelector(getJobs, jobs =>
  jobs.filter(j => j.get('status') === 'complete')
)

export const getRows = (state: RootState) => state.getIn(['jobs', 'jobRows'])

export const getNumRows = (state: RootState) => getRows(state)?.size || 0

export const getOrgUnitByRowNum = createSelector(
  getRows,
  (state: RootState, rowNum) => rowNum,
  (rows: List<RecordOf<JobOrgUnit>>, rowNum) => {
    return rows?.find(row => row.get('rowNum') === Number(rowNum))
  }
)

export const getOrgUnitClassificationCodes = createSelector(
  getOrgUnitByRowNum,
  orgUnit => {
    return orgUnit?.get('classificationCodes')
  }
)
export const getRowsResults: (
  state: RootState
) => List<RecordOf<JobOrgUnitAnswers>> = (state: RootState) =>
  state.getIn(['jobs', 'jobResults'])

export const getRowsAnswered = createSelector<
  RootState,
  List<RecordOf<JobOrgUnitAnswers>>,
  List<number>
>(getRowsResults, (rows: List<RecordOf<JobOrgUnitAnswers>>) => {
  return rows.map(row => row.get('rowNum'))
})

export const getAnswersByRowNum = createSelector(
  getRowsResults,
  (state: RootState, rowNum: number) => rowNum,
  (rows: List<RecordOf<JobOrgUnitAnswers>>, rowNum: number) => {
    const row = rows?.find(row => row.get('rowNum') === rowNum)
    return row?.get('answers')
  }
)

export const getQuestions = createSelector(
  (state: RootState, rowNum: number, jobId: string) => getJobById(state, jobId),
  (state: RootState, rowNum: number, jobId: string) => rowNum,
  (state: RootState) => state.getIn(['jobs', 'jobQuestions']),
  (job, rowNum, questions) => {
    return job?.get('type') === 'oneToOne'
      ? questions.get(rowNum)
        ? List([questions.get(rowNum)])
        : List([])
      : questions
  }
)

export const createValidationRulesSelector = (jobId, rowNum) =>
  createSelector(
    (state: RootState) => state.getIn(['jobs', 'byId', jobId]),
    (state: RootState) => state.getIn(['jobs', 'jobQuestions']),
    (state: RootState) => Number(rowNum),
    (job, questions, rowNum) => {
      const rowQuestions =
        job?.get('type') === 'oneToOne'
          ? questions.get(rowNum)
            ? List([questions.get(rowNum)])
            : List([])
          : questions

      return rowQuestions
        .filter(question => question.get('required'))
        .reduce(
          (validation, question) => {
            validation.required.push(question.get('questionId'))
            return validation
          },
          {
            required: []
          }
        )
    }
  )

export const getSowList = createSelector(
  (state: RootState) => state.getIn(['jobs', 'jobRows']),
  (state: RootState) => state.getIn(['jobs', 'jobResults']),
  (rows, results) => {
    if (rows.size === 0) {
      return rows
    }
    // combine rows with results
    const combineList = rows.concat(results)
    // reduce and normalize list
    const cleanList = combineList.reduce((reduction, item) => {
      const itemObj = item.toJS()
      // normalize object
      const { answers, ...restResult } = itemObj
      const { locations, ...restItem } = answers ? answers : itemObj
      const parseLocations = locations
        ? { locations: locations.map(l => JSON.stringify(l)) }
        : {}

      const cleanItemObj = {
        ...restItem,
        ...restResult,
        ...parseLocations
      }

      // check current org already in the reduction list
      const index = reduction.findIndex(
        r => r.orgUnitId === cleanItemObj.orgUnitId
      )
      if (index === -1) {
        reduction.push(cleanItemObj)
      } else {
        reduction[index] = Object.assign({}, reduction[index], cleanItemObj)
      }
      return reduction
    }, [])
    return cleanList.sort((org1, org2) => {
      return Number.isNaN(org1.rowNum)
        ? 1
        : Number.isNaN(org2.rowNum)
        ? 1
        : org1.rowNum - org2.rowNum
    })
  }
)

export const getSowAcceptList = createSelector(getSowList, orgList => {
  return orgList.filter(org => !org.rejectReason)
})

export const getSowRejectList = createSelector(getSowList, orgList => {
  return orgList.filter(org => !!org.rejectReason)
})

export const getResultIdsByOrgUnitId = createSelector(
  getRowsResults,
  (rows: List<RecordOf<JobOrgUnitAnswers>>) => {
    return rows.reduce((resultsByOrgUnitId, row) => {
      return !!row.get('orgUnitId')
        ? resultsByOrgUnitId.set(row.get('orgUnitId') as string, row.get('id'))
        : resultsByOrgUnitId
    }, Map({}))
  }
)

export const getSowOrg = createSelector(
  getSowList,
  (state: RootState, orgUnitId: string) => orgUnitId,
  (orgList, orgUnitId) => {
    return orgList?.find(org => org.orgUnitId === orgUnitId) || {}
  }
)
