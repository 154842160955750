import { call } from 'redux-saga/effects'
import { getVetById } from 'shared/utils/api/supplier/supplier'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* loadVetBuyerSupplierSaga(action) {
  let response = yield call(getVetById, action.payload)
  if (response && !response.orgUnit) response.orgUnit = { name: 'Anonymous' }
  return yield response
}

export default createApiRequestSaga(loadVetBuyerSupplierSaga)
