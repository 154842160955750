import React, { Component, ChangeEvent } from 'react'
import OrgLogo from 'shared/components/OrgLogo'

type Props = {
  name: string
  logoUrl: string
  checked: boolean
  disabled: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  orgUnitId: string
}

class SelectSupplierListItem extends Component<Props> {
  render() {
    const { orgUnitId, name, logoUrl, checked, disabled, onChange } = this.props
    return (
      <div key={orgUnitId} className='mb2 dt w-100'>
        <div className='dtc v-mid w1-5'>
          <input
            type='checkbox'
            value={orgUnitId}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
          />
        </div>
        <div
          className='logo dtc v-mid ba b--black-10 br2 tc bg-white ml1'
          style={{ height: '24px', width: '24px', padding: '2px' }}
        >
          <OrgLogo url={logoUrl} className='v-mid' />
        </div>
        <div className='v-mid mid-gray dtc pl2'>
          <h5 className='name f7 fw3 mid-gray ma0 truncate'>{name}</h5>
        </div>
      </div>
    )
  }
}

export default SelectSupplierListItem
