import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { ADD_TAGS, REMOVE_TAGS } from '../../actionTypes'
import { updateTags } from '../../actions'
import companySelectors from '../../../shared/selectors/companySelectors'
import RadioButton from 'shared/components/RadioButton'
import Label from 'shared/components/Label'
import createTagsForm from 'shared/utils/createTagsForm'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'

const tagsFieldName = 'serviceAreas'
const ServiceAreasTagsForm = createTagsForm({
  formName: 'supplier/company/serviceAreas',
  fieldName: tagsFieldName,
  addLabel: (
    <FormattedMessage
      id='DetailsSectionContainer.AddServiceArea'
      defaultMessage='Add a Service Area'
    />
  ),
  addTagActionType: ADD_TAGS,
  removeTagActionType: REMOVE_TAGS,
  tagsSelector: companySelectors.getCompanySupplierField
})

type Props = {
  isGlobal: boolean
  serviceAreas: List<string>
  updateTags: (args: {
    fieldName: string
    tagToRemove: Array<string>
    newTags?: Array<string>
  }) => void
}

export const DetailsSectionContainer: FunctionComponent<Props> = props => {
  const handleToggleIsGlobal = e => {
    const { isGlobal, serviceAreas, updateTags } = props
    const clickedIsGlobal = e.target.value

    // if we are switching to global set it to global
    if (clickedIsGlobal === 'yes' && !isGlobal) {
      updateTags({
        fieldName: tagsFieldName,
        tagToRemove: serviceAreas.toJS(),
        newTags: ['Global']
      })

      // clear the tags if switching from global to not global
    } else if (clickedIsGlobal === 'no' && isGlobal) {
      updateTags({
        fieldName: tagsFieldName,
        tagToRemove: ['Global']
      })
    }
  }

  const { isGlobal } = props

  return (
    <>
      <div className='ph3-5'>
        <Label>
          <FormattedMessage
            id='DetailSectionContainer.WhereDoYouDoBusiness'
            defaultMessage='Where do you do business?'
          />
        </Label>
        <div className='mt2 mb3'>
          <RadioButton
            label={
              <FormattedMessage
                id='DetailsSectionContainer.GlobalButton'
                defaultMessage='Global'
              />
            }
            value='yes'
            checked={isGlobal}
            onChange={handleToggleIsGlobal}
          />

          <RadioButton
            className='ml1'
            label={
              <FormattedMessage
                id='DetailsSectionContainer.SpecifyButton'
                defaultMessage='Specify'
              />
            }
            value='no'
            checked={!isGlobal}
            onChange={handleToggleIsGlobal}
          />
        </div>
      </div>

      {!isGlobal && <ServiceAreasTagsForm />}
    </>
  )
}

export default connect(
  state => {
    const serviceAreas = companySelectors.getCompanySupplierField(
      state,
      tagsFieldName
    )

    return {
      serviceAreas,
      isGlobal:
        serviceAreas &&
        serviceAreas.size === 1 &&
        serviceAreas.first().toLowerCase() === 'global'
    }
  },
  {
    updateTags
  }
)(DetailsSectionContainer)
