import UploadedFile from 'shared/models/UploadedFile'
import Immutable, { RecordOf } from 'immutable'

const fileToUrl = (file?: UploadedFile | RecordOf<UploadedFile>) => {
  if (file) {
    const fileObject = Immutable.isImmutable(file) ? file.toJS() : file
    const bucketName = fileObject.bucketName
    const fileName = fileObject.fileName
    if (bucketName && fileName) {
      return `https://storage.googleapis.com/${bucketName}/${fileName}`
    }
  }
}

export default fileToUrl
