import React, { ReactNode, useMemo } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import createTheme from 'styles/createTheme'
import { useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'

type Props = {
  children: ReactNode
}
const CustomMuiThemeProvider = (props: Props) => {
  const themeOptions = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'themeOptions'])
  )
  const muiTheme = useMemo(() => createTheme(themeOptions?.toJS()), [
    themeOptions
  ])
  return <MuiThemeProvider theme={muiTheme} {...props} />
}

export default CustomMuiThemeProvider
