import { call } from 'redux-saga/effects'
import Immutable from 'immutable'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import community from 'shared/utils/api/community'
import fileToUrl from 'shared/utils/data/fileToUrl'
import communityParser from 'shared/utils/data/community/parser'

export function* updateCommunitySaga(action) {
  const { communityId, logo, ...rest } = Immutable.isImmutable(action.payload)
    ? action.payload.toJS()
    : action.payload

  let response
  if (logo) {
    response = yield call(community.addLogo, { communityId, logo })
    return yield {
      deepMerge: true,
      byId: {
        [communityId]: { logoUrl: fileToUrl(response) }
      }
    }
  } else {
    response = yield call(community.updateCommunity, { communityId, ...rest })
    return yield {
      byId: {
        [response.id]: communityParser(response)
      }
    }
  }
}

export default createApiRequestSaga(updateCommunitySaga)
