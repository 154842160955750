import React from 'react'
import { useSelector } from 'react-redux'
import jobsSelector from '../../selectors/jobsSelectors'
import RootState from 'shared/models/RootState'
import Text from 'shared/components/Text'
import UserStack from 'shared/components/UserStack'
import useThemeColors from 'shared/utils/useThemeColors'
import Button from 'shared/components/Button'
import { ReactComponent as Check } from 'shared/assets/icons/check-solid.svg'
import CloseIcon from '@material-ui/icons/Close'
import { CategoryPills } from '../JobSow/SowHeader'

type Props = {
  jobId: string
  onClick: (jobId: string) => void
  onReserve: (jobId: string) => void
  onRelease: (jobId: string) => void
  onDelete?: (jobId: string) => void
}

const JobCard = (props: Props) => {
  const { jobId, onReserve, onRelease, onClick, onDelete } = props

  const theme = useThemeColors()
  const job = useSelector((state: RootState) =>
    jobsSelector.getJobById(state, jobId)
  )
  const queryString = job?.get('queryString')

  return job ? (
    <div className='bg-white ba br b--black-10 pa2'>
      <div className='flex items-top justify-between'>
        <div className='mb2'>
          {job.get('status') === 'inProgress' ? (
            <div
              className={`db tc f7 fw6 lh-copy dim pointer ${theme.primaryText}`}
              onClick={() => onClick(job.get('id'))}
            >
              {job.get('name')}
            </div>
          ) : (
            <div className={`lh-copy f7 fw4 mid-gray ma0 fw6 tc`}>
              {job.get('name')}
            </div>
          )}
        </div>
        {onDelete && (
          <CloseIcon
            aria-label='delete'
            className='dim pointer'
            onClick={() => onDelete(job.get('id'))}
          />
        )}
      </div>
      {queryString && <CategoryPills queryString={queryString} />}
      <div className='flex items-center justify-between mt3'>
        {job.get('status') === 'available' ? (
          <Button size='small' onClick={() => onReserve(job.get('id'))}>
            Reserve
          </Button>
        ) : job.get('status') === 'inProgress' ? (
          <>
            <div>
              <Button
                size='small'
                secondary
                onClick={() => onRelease(job.get('id'))}
              >
                Release
              </Button>
            </div>
            <div className='pl2 flex-auto'>
              <UserStack users={job.get('owners')} />
            </div>
          </>
        ) : (
          <div className='pl2 flex-auto'>
            <UserStack users={job.get('owners')} />
          </div>
        )}
        {job.get('type') !== 'hil' && (
          <div className='flex'>
            {job.get('status') === 'complete' && (
              <span className='mr1'>
                <Check height={12} fill='#31b800' />
              </span>
            )}
            <Text>
              {job.get('numComplete') || 0}/{job.get('numRows')}
            </Text>
          </div>
        )}
      </div>
    </div>
  ) : null
}

export default JobCard
