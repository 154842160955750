import { call } from 'redux-saga/effects'
import staging from 'shared/utils/api/staging'
import suppliers from 'shared/utils/api/suppliers'
import supplier from 'shared/utils/api/supplier'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* remapSupplierSaga(action) {
  const { stagingRowId, name, domain, orgUnitId } = action.payload
  let foundOrgUnitId, newOrgUnitId, data

  // no orgUnit provide, try looking up by domain
  if (!orgUnitId) {
    const response = yield call(supplier.getBuyerByDomain, domain)
    if (response.found) {
      foundOrgUnitId = response.names[0].id
    } else {
      // not found, create a new orgUnit
      const response = yield call(suppliers.create, {
        name: name || domain,
        websiteUrl: domain
      })
      newOrgUnitId = response.id
    }
  }

  if (orgUnitId || foundOrgUnitId || newOrgUnitId) {
    data = yield call(staging.remapStagingRow, {
      stagingRowId,
      orgUnitId: orgUnitId || foundOrgUnitId || newOrgUnitId
    })
  }

  return yield { ...data, status: 'found' }
}

export default createApiRequestSaga(remapSupplierSaga)
