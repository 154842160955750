import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadData } from 'shared/actions'
import dataSelectors from 'shared/selectors/dataSelectors'
import ChangeHistory from '../../components/ChangeHistory'
import RootState from 'shared/models/RootState'
import { RecordOf, List } from 'immutable'
import Loading from 'shared/components/Loading'

export const changeHistoryParser = data => {
  return data.reduce((result, curr) => {
    let label = curr.label
    let details = curr.details
    let date = curr.created.date
    result.push({ label, details, date })
    return result
  }, [])
}

type BuyerChangeEvents = List<
  RecordOf<{
    label: string
    details: RecordOf<{
      user?: string
      keys?: List<string>
      prevVals?: string
      newVals?: string
      status?: string
      filename?: string
      progress?: string
      errorList?: List<string>
    }>
    date: string
  }>
>
type Props = {
  loadData: typeof loadData
  buyerChangeEvents: BuyerChangeEvents
  isLoadingChangeEvents: boolean
}

const ChangeHistoryContainer = ({ loadData, buyerChangeEvents }: Props) => {
  useEffect(() => {
    loadData({
      entity: 'changeHistory',
      query: 'buyer/getBuyerEvents',
      resultParser: changeHistoryParser
    })
  }, [loadData])

  return buyerChangeEvents.size < 1 ? (
    <Loading />
  ) : (
    <ChangeHistory buyerChangeEvents={buyerChangeEvents} />
  )
}

export default connect(
  (state: RootState) => ({
    buyerChangeEvents: dataSelectors.getByEntity(state, 'changeHistory')
  }),
  {
    loadData
  }
)(ChangeHistoryContainer)
