import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import dateFormat from 'shared/utils/dateFormat'
import Avatar from 'shared/components/Avatar'
import Button from 'shared/components/Button'
import usersSelectors from 'shared/selectors/usersSelectors'
import RootState from 'shared/models/RootState'
import parseNameFromUser from 'shared/utils/parseNameFromUser'
import TextArea from 'shared/components/TextArea'
import { answerVetQuestion } from '../../actions'
import UserListItem from 'shared/components/UserListItem'
import moment from 'moment'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import RadioButton from 'shared/components/RadioButton'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'

type Props = {
  vetBuyerSupplierId: string
  id: string
  createdDate: string
  createdByName: string
  createdByCompanyName: string
  createdByProfilePictureUrl: string
  description: string
  response?: string
  responseDate?: string
  responseByEmail?: string
  responseByFirstName?: string
  responseByLastName?: string
  responseByProfilePictureUrl?: string
  canAnswer?: boolean
  responseIsPublic?: boolean
  answerVetQuestion: typeof answerVetQuestion
}

const VetQuestion: FunctionComponent<Props> = props => {
  const [editResponse, setEditResponse] = useState<boolean>(false)
  const [response, setResponse] = useState<string>(props.response || '')
  const [responseIsPublic, setResponseIsPublic] = useState<boolean>(
    !!props.responseIsPublic
  )
  const [updateDescription, setUpdateDescription] = useState<string>(
    props.description
  )

  return (
    <div className='flex bb b--black-10 pa3-5'>
      <Avatar
        url={props.createdByProfilePictureUrl}
        className='w2-5 h2-5'
        name={props.createdByName}
      />
      <div className='ml3 flex-auto f7'>
        <div className='mt1 flex items-center'>
          <div className='flex-auto'>
            <div className='fw6 mid-gray'>{props.createdByName}</div>
            <div className='mt1 gray'>
              <FormattedMessage
                id='buyer.VetQuestion.createdDate'
                defaultMessage='at {companyName} on {date}'
                values={{
                  companyName: props.createdByCompanyName,
                  date: moment(props.createdDate).format('MM/DD/YYYY')
                }}
              />
            </div>
          </div>

          {props.response && !editResponse && (
            <div className='fw6'>
              {props.responseIsPublic ? 'Public' : 'Private'}
            </div>
          )}
        </div>
        {!editResponse && (
          <>
            <div className='mt3 fw7'>{props.description}</div>
            <div className='mt2 mid-gray'>
              {!!props.response ? (
                props.response
              ) : (
                <FormattedMessage
                  id='VetQuestions.AwaitingAnswer'
                  defaultMessage='Awaiting answer'
                />
              )}
            </div>
          </>
        )}
        {editResponse && (
          <form
            onSubmit={e => {
              e.preventDefault()
              //answer and edit question
              props.answerVetQuestion({
                vetBuyerSupplierId: props.vetBuyerSupplierId,
                questionId: props.id,
                response,
                isPublic: responseIsPublic,
                description: updateDescription,
                buyerEditing: true
              })
              setEditResponse(false)
            }}
          >
            <Label>
              <FormattedMessage
                id='VetQuestions.EditQuestion'
                defaultMessage="Would you like to edit the supplier's question? You can do that here."
              />
            </Label>
            <Text>
              <FormattedMessage
                id='VetQuestions.EditQuestionNote'
                defaultMessage="Note: editing the supplier's question cannot be undone."
              />
            </Text>
            <TextArea
              value={updateDescription}
              onChange={e => setUpdateDescription(e.currentTarget.value)}
              additionalClass='mt2'
              required
            />
            <Label>
              <FormattedMessage
                id='VetQuestions.Response'
                defaultMessage='Your response'
              />
            </Label>
            <TextArea
              value={response}
              onChange={e => setResponse(e.currentTarget.value)}
              additionalClass='mt2'
              required
            />

            <div className='mt3'>
              <RadioButton
                label={
                  <FormattedMessage
                    id='VetQuestion.dontShareQuestion'
                    defaultMessage='I would like {companyName} to be the only supplier that can see this question and answer'
                    values={{
                      companyName: props.createdByCompanyName
                    }}
                  />
                }
                checked={!responseIsPublic}
                onChange={() => setResponseIsPublic(false)}
              />
            </div>

            <div className='mt2'>
              <RadioButton
                label={
                  <FormattedMessage
                    id='VetQuestion.shareQuestion2'
                    defaultMessage='I would like to share this question and answer among other suppliers on this RFI'
                  />
                }
                checked={responseIsPublic}
                onChange={() => setResponseIsPublic(true)}
              />
            </div>

            <div className='fw6 mt3 flex items-center'>
              <Button
                label={props.response ? 'Update' : 'Answer'}
                onClick={() => setEditResponse(true)}
                color='primary'
                autoSize
                className='mr3'
                type='submit'
              />

              <Button
                label='Cancel'
                onClick={() => {
                  setResponse(props.response || '')
                  setEditResponse(false)
                }}
                secondary
                autoSize
              />
            </div>
          </form>
        )}

        {!editResponse && (
          <div className='tr'>
            {props.responseDate && (
              <div className='dib mr5'>
                <UserListItem
                  profilePictureUrl={props.responseByProfilePictureUrl || ''}
                  firstName={props.responseByFirstName || ''}
                  lastName={props.responseByLastName || ''}
                  email={props.responseByEmail}
                  secondaryText={`Responded ${dateFormat(props.createdDate)}`}
                  className='tl'
                  mini
                />
              </div>
            )}
            {!props.response && (
              <Button
                label='Answer'
                onClick={() => setEditResponse(true)}
                color='primary'
                autoSize
              />
            )}
            {!!props.response && (
              <Button
                label='Edit'
                onClick={() => setEditResponse(true)}
                secondary
                autoSize
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

type ContainerProps = {
  createdBy: string
  responseBy: string
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const createdByUser = usersSelectors.getById(state, props.createdBy)
    const orgUnit = orgsSelectors.getById(state, createdByUser.get('orgUnitId'))
    const respondedByUser = usersSelectors.getById(state, props.responseBy)

    return {
      createdByProfilePictureUrl: createdByUser.get('profilePictureUrl'),
      createdByName: parseNameFromUser(
        createdByUser.get('email'),
        createdByUser.get('firstName'),
        createdByUser.get('lastName')
      ),
      createdByCompanyName: orgUnit.get('name'),
      responseByEmail: respondedByUser.get('email'),
      responseByFirstName: respondedByUser.get('firstName'),
      responseByLastName: respondedByUser.get('lastName'),
      responseByProfilePictureUrl: respondedByUser.get('profilePictureUrl')
    }
  },
  {
    answerVetQuestion
  }
)(VetQuestion)
