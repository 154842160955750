import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import CommunityHeader from 'shared/components/CommunityHeader'
import {
  updateCommunity,
  inviteExisting,
  loadCommunityInsight,
  getCommunity
} from '../../actions'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import CommunityNavBar from '../../components/CommunityNavBar'
import Divider from 'shared/components/Divider'
import { RouteComponentProps } from 'react-router'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import Tier2CommunityLogo from 'shared/assets/icons/tier2-community-logo.svg'

type Props = {
  communityId: string
  loadCommunityInsight: typeof loadCommunityInsight
  loadCommunity: typeof getCommunity
  updateCommunity: typeof updateCommunity
  inviteExisting: typeof inviteExisting
  isTealbot: boolean
  isPrivate: boolean
  hasForm: boolean
  name: string
  tier2: boolean
  logoUrl: string
  description: string
  longDescription: string
  supplierSearchable: boolean
  allowInterSupplierPosting: boolean
}
export const CommunityHeaderContainer = (props: Props) => {
  const {
    communityId,
    isTealbot,
    isPrivate,
    hasForm,
    name,
    tier2,
    logoUrl,
    description,
    longDescription,
    inviteExisting,
    supplierSearchable,
    allowInterSupplierPosting,
    loadCommunity,
    loadCommunityInsight
  } = props

  useEffect(() => {
    loadCommunityInsight(communityId)
    loadCommunity(communityId)
  }, [communityId, loadCommunityInsight, loadCommunity])

  const handleUpdateCommunity = changes => {
    const { updateCommunity, communityId } = props

    updateCommunity({ communityId, ...changes })
  }

  return (
    <Fragment>
      <ScrollToPosition />
      <CommunityHeader
        communityId={communityId}
        isTealbot={isTealbot}
        onUpdate={handleUpdateCommunity}
        name={name}
        isPrivate={isPrivate}
        supplierSearchable={supplierSearchable}
        allowInterSupplierPosting={allowInterSupplierPosting}
        logoUrl={tier2 ? Tier2CommunityLogo : logoUrl}
        description={description}
        longDescription={longDescription}
        inviteExisting={inviteExisting}
      />

      <div style={{ position: 'sticky', top: 50 }} className='bg-tb-gray z-1'>
        <div className='mw8 center ph3'>
          {communityId && (
            <CommunityNavBar communityId={communityId} hasForm={hasForm} />
          )}
        </div>
        <Divider />
      </div>
    </Fragment>
  )
}
type ContainerProps = RouteComponentProps<{
  communityId: string
  membershipId: string
}>
export default connect(
  (state, props: ContainerProps) => {
    const { communityId, membershipId } = props.match.params
    const isTealbot = sessionSelectors.userHasRole(state, 'tealbot')
    const community = communitiesSelectors.getCommunityById(state, communityId)

    return {
      isTealbot,
      communityId: communityId,
      membershipId: membershipId,
      tier2: community && community.get('tier2'),
      logoUrl: community && community.get('logoUrl'),
      name: community && community.get('name'),
      isPrivate: community && community.get('isPrivate'),
      supplierSearchable: community && community.get('supplierSearchable'),
      allowInterSupplierPosting:
        (community && community.get('allowInterSupplierPosting')) === undefined
          ? true
          : community && community.get('allowInterSupplierPosting'),
      description: community && community.get('description'),
      longDescription: community && community.get('longDescription'),
      hasForm: !!community && community.get('form')
    }
  },
  {
    updateCommunity,
    inviteExisting,
    loadCommunityInsight,
    loadCommunity: getCommunity
  }
)(CommunityHeaderContainer)
