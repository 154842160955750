import React, { Component } from 'react'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'

const CustomBadge = withStyles({
  badge: {
    top: 3,
    right: 4,
    minWidth: 8,
    height: 8,
    backgroundColor: '#e00b0b'
  }
})(Badge)

type Props = {
  numberOfNotifications?: number
}

class CustomListItemText extends Component<Props> {
  render() {
    const { numberOfNotifications, children } = this.props

    return numberOfNotifications ? (
      <CustomBadge badgeContent={<span />}>
        <ListItemText>{children}</ListItemText>
      </CustomBadge>
    ) : (
      <ListItemText>{children}</ListItemText>
    )
  }
}

export default CustomListItemText
