import React, { Component, ReactNode } from 'react'
import { connect } from 'react-redux'
import Paper from 'shared/components/Paper'
import vetsSelectors from 'buyer/Vets/selectors/vetsSelectors'
import VetsList from 'shared/components/VetsList'
import { loadVets } from 'buyer/Vets/actions'
import Scrollable from 'shared/components/Scrollable'
import { List, RecordOf } from 'immutable'

type Props = {
  userId: string
  shouldLoadVets: boolean
  title: ReactNode | string
  loadVets: typeof loadVets
  vets: List<
    RecordOf<{
      date: string
      id: string
      lead: string
      name: string
      status: string
    }>
  >
}

export class VetsListContainer extends Component<Props> {
  componentDidMount() {
    const { shouldLoadVets, loadVets } = this.props
    if (shouldLoadVets) {
      loadVets()
    }
  }

  render() {
    const { vets, title } = this.props
    return vets.size > 0 ? (
      <div className='pt4-ns pt0'>
        <Paper>
          <h4 className='ma0 mb3 f7 fw6 mt3-5'>{title}</h4>
          <Scrollable>
            <VetsList vets={vets} />
          </Scrollable>
        </Paper>
      </div>
    ) : null
  }
}
type ContainerProps = {
  userId: string
}
export default connect(
  (state, props: ContainerProps) => ({
    shouldLoadVets: vetsSelectors.getItems(state).size === 0,
    vets: vetsSelectors.getVetsByUserId(state, props.userId)
  }),
  { loadVets }
)(VetsListContainer)
