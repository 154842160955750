import { select, call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import profileSelectors from '../../selectors/profileSelectors'
import relationships from 'shared/utils/api/relationships'

export function* updateAttachmentSaga(action) {
  const newAttachment = action.payload.toJS()
  const relationship = yield select(profileSelectors.getRelationship)
  let relationshipId = relationship && relationship.get('id')

  // create a relationship if we need to
  let newRelationship
  if (!relationshipId) {
    const currentSupplierId = yield select(profileSelectors.getDataField, 'id')
    newRelationship = yield call(relationships.create, currentSupplierId)
    relationshipId = newRelationship.id
  }

  // add or edit the attachemnt
  const createdAttachment = yield call(
    relationships.addAttachment,
    relationshipId,
    newAttachment
  )

  return yield {
    newRelationship,
    relationshipId,
    attachment: createdAttachment
  }
}

export default createApiRequestSaga(updateAttachmentSaga)
