import React, { Component } from 'react'
import createRoutes from './routes/createRoutes'
import { loadCommunities } from './actions'
import { connect } from 'react-redux'
import Loading from 'shared/components/Loading'
import RootState from 'shared/models/RootState'

type Props = {
  loading: boolean
}

class Communities extends Component<Props> {
  render() {
    return this.props.loading ? <Loading /> : createRoutes()
  }
}

export default connect(
  (state: RootState) => ({
    loading: state.getIn(['supplier', 'communities', 'isLoading'])
  }),
  {
    loadCommunities
  }
)(Communities)
