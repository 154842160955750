import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import startCase from 'lodash.startcase'

export const messages = defineMessages({
  Priority: {
    id: 'RequestStatusByCell.Priority',
    defaultMessage: 'Priority'
  },
  Registration: {
    id: 'RequestStatusByCell.EcoVadisAssessmentRegistration',
    defaultMessage: 'Registration'
  },
  Questionnaire: {
    id: 'RequestStatusByCell.EcoVadisAssessmentQuestionnaire',
    defaultMessage: 'Questionnaire'
  },
  'Expert Analysis': {
    id: 'RequestStatusByCell.EcoVadisAssessmentExpertAnalysis',
    defaultMessage: 'Expert Analysis'
  },
  Results: {
    id: 'RequestStatusByCell.Results',
    defaultMessage: 'Results'
  },
  Cancelled: {
    id: 'RequestStatusByCell.EcoVadisAssessmentCancelled',
    defaultMessage: 'Cancelled'
  },
  Accepted: {
    id: 'RequestStatusByCell.Accepted',
    defaultMessage: 'Accepted'
  },
  Invited: {
    id: 'RequestStatusByCell.Invited',
    defaultMessage: 'Invited'
  },
  Created: {
    id: 'RequestStatusByCell.Created',
    defaultMessage: 'Created'
  }
})

export const parseStatus = (outreachType, outreachStatus) => {
  if (outreachType === 'ExpiringContract') {
    return 'Priority'
  } else if (outreachType === 'EcoVadisAssessment') {
    return outreachStatus
  } else if (
    (outreachType === 'SelfCertifyRequest' ||
      outreachType === 'ESGSurveyRequest') &&
    outreachStatus === 'InProgress'
  ) {
    return 'Accepted'
  } else if (
    outreachType === 'SelfCertifyRequest' ||
    outreachType === 'ESGSurveyRequest'
  ) {
    return 'Invited'
  } else {
    return 'Created'
  }
}

const RequestStatusByCell = ({ outreachType, outreachStatus }) => {
  const intl = useIntl()
  const requestStatus = parseStatus(outreachType, outreachStatus)
  let color
  if (requestStatus === 'Priority') {
    color = 'bg-red'
  } else if (
    requestStatus === 'Results Available' ||
    requestStatus === 'Accepted'
  ) {
    color = 'bg-teal'
  } else {
    color = 'bg-moon-gray'
  }

  return (
    <div className={`dib br2 pv2 ph2 tc white ${color}`}>
      {messages[requestStatus]
        ? intl.formatMessage(messages[requestStatus])
        : startCase(outreachType)}
    </div>
  )
}

export default RequestStatusByCell
