import { fromJS, RecordOf, List, Map } from 'immutable'
import { createSelector } from 'reselect'
import RootState from '../../models/RootState'
import User from '../../models/User'

export const getUsers = (state: RootState): Map<string, RecordOf<User>> =>
  state.getIn(['users', 'byId'])

const defaultUser = fromJS({ roles: [] })
export const getById = (state: RootState, userId: string): RecordOf<User> => {
  return state.getIn(['users', 'byId', userId]) || defaultUser
}

export const getAllIdsBySupplier = (
  state: RootState,
  supplierId: string
): List<string> => {
  return state.getIn(['users', 'allIdsBySupplier', supplierId])
}

const defaultColleagueIds = fromJS([])
export const getColleagueIds = (state: RootState): List<string> =>
  state.getIn(['users', 'colleaguesIds']) || defaultColleagueIds

export const getColleagues = createSelector(
  getColleagueIds,
  getUsers,
  (colleagueIds, allUsers) => {
    return colleagueIds
      .map(id => allUsers.get(id))
      .filter(
        user =>
          user &&
          user.get('email') !== 'concierge@tealbook.com' &&
          !user.get('email').startsWith('tealbot@') &&
          (user.get('status') || '').toLowerCase() !== 'blocked'
      )
      .sort(sortUsersByName)
  }
)

export const getColleaguesForAdmin = createSelector(
  getColleagueIds,
  getUsers,
  (colleagueIds, allUsers) => {
    return colleagueIds
      .map(id => allUsers.get(id))
      .filter(
        user =>
          user &&
          user.get('email') !== 'concierge@tealbook.com' &&
          !user.get('email').startsWith('tealbot@')
      )
      .sort(sortUsersByName)
  }
)
export const getActiveColleagueIds = createSelector(getColleagues, colleagues =>
  colleagues.map(colleague => colleague && colleague.get('id'))
)

export const getColleaguesExcludingUser = createSelector(
  getColleagues,
  (state: RootState, userId: string) => userId,
  (colleagues, userId) => {
    return (
      colleagues && colleagues.filter(user => user && user.get('id') !== userId)
    )
  }
)

export const getColleaguesExcludingUsers = createSelector(
  getColleagues,
  (state: RootState, userIds) => userIds,
  (colleagues, userIds) => {
    return (
      colleagues &&
      colleagues.filter(
        user => !userIds || !userIds.includes(user && user.get('id'))
      )
    )
  }
)

export const getBySupplier = createSelector(
  getAllIdsBySupplier,
  getUsers,
  (userIdsBySupplier, users) => {
    return (
      userIdsBySupplier &&
      userIdsBySupplier
        .map(userId => users.get(userId))
        .filter(user => user && user.get('email') !== 'concierge@tealbook.com')
        .sort(sortUsersByName)
    )
  }
)

export const getUserProfileFormValues = createSelector(
  getById,
  (state: RootState) =>
    state.getIn(['buyer', 'settings', 'defaultLicenseType']),
  (user, defaultLicenseType) => {
    return (
      user &&
      fromJS({
        id: user.get('id'),
        firstName: user.get('firstName'),
        lastName: user.get('lastName'),
        title: user.get('title'),
        department: user.get('department'),
        officePhone: user.get('officePhone'),
        mobilePhone: user.get('mobilePhone'),
        address: user.getIn(['location', 'address']),
        linkedInUrl: user.get('linkedInUrl'),
        disableAllEmails: user.get('disableAllEmails'),
        isPublic: user.get('isPublic'),
        isSustainabilityContact: user.get('isSustainabilityContact'),
        options: user.get('options') || { message: { frequency: 'Daily' } },
        language: user.get('language') || 'en',
        licenseType: user.get('roles')?.includes('buyer')
          ? user.get('licenseType') || defaultLicenseType
          : undefined,
        happy: user.get('happy')
      })
    )
  }
)

const sortUsersByName = (userA: RecordOf<User>, userB: RecordOf<User>) => {
  const fullNameA = `${userA.get('firstName')} ${userA.get('lastName')}`
    .trim()
    .toLowerCase()
  const fullNameB = `${userB.get('firstName')} ${userB.get('lastName')}`
    .trim()
    .toLowerCase()
  return fullNameA > fullNameB ? 1 : -1
}
