import React from 'react'
import PostBySection, { PostBySectionProps } from './PostBySection'
import PostBody from '../PostItem/PostBody'

type Props = {
  title: string
  body?: string
  tags?: string[]
  imageUrl?: string
} & PostBySectionProps

const Post = (props: Props) => {
  const { title, body, imageUrl } = props
  return (
    <div className='mb3'>
      <PostBySection {...props} />
      {imageUrl ? (
        <div className='relative mt2'>
          <img src={imageUrl} alt='' style={{ maxWidth: '100%' }} />
          <div
            className='pa3 f4 fw6 absolute lh-copy bottom-0 left-0 right-0'
            style={{
              backgroundColor: 'rgba(248, 248, 250,.75)'
            }}
          >
            {title}
          </div>
        </div>
      ) : (
        <div className='mt2 pa3 f4 fw6 lh-copy bg-near-white'>{title}</div>
      )}

      {body && (
        <PostBody
          body={body}
          isSelfCertification={props.tags?.includes('#selfcertification')}
        />
      )}
    </div>
  )
}

export default Post
