export const REGISTER = 'REGISTER'
export const CREATE_PASSWORD = 'CREATE_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const CHANGE_PASSWORD = 'accounts/CHANGE_PASSWORD'
export const LOGIN = 'LOGIN'
export const IFRAME_LOGIN = 'IFRAME_LOGIN'
export const REQUEST_TO_REGISTER = 'accounts/REQUEST_TO_REGISTER'
export const CONTACTS_UPLOAD = 'accounts/CONTACTS_UPLOAD'
export const SAVE_MOBILE_CONTACTS = 'accounts/SAVE_MOBILE_CONTACTS'
export const DISPLAY_WAITING_TO_CREATE_ORG =
  'accounts/DISPLAY_WAITING_TO_CREATE_ORG'
