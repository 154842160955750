import { Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useSmallScreen = () => {
  return useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'), {
    noSsr: true
  })
}

export default useSmallScreen
