import React, { Component } from 'react'
import Page from '../Page'
import TitleText from '../TitleText'
import AppBarContainer from '../../containers/AppBarContainer'
import UserNavContainer from '../../containers/UserNavContainer'
import apiRoutes from '../../routes/apiRoutes'
import qs from 'qs'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps } from 'react-router'

type Props = RouteComponentProps

export class VerifyClientPage extends Component<Props> {
  componentDidMount() {
    const { location } = this.props

    const { token, action } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })

    if (token && action) {
      fetch(
        `${apiRoutes.authService}/open/verifyClient/${global.encodeURIComponent(
          token
        )}/${action}`,
        {
          method: 'POST'
        }
      )
    }
  }

  render() {
    return (
      <div>
        <AppBarContainer
          userNav={<UserNavContainer />}
          homeUrl='/verify-client'
        >
          <div className='flex-auto' />
        </AppBarContainer>
        <Page
          title={
            <FormattedMessage
              id='VerifyClientPage.VerifyClient'
              defaultMessage='Verify Client'
            />
          }
          className='tc'
        >
          <TitleText>
            <FormattedMessage
              id='VerifyClientPage.ThankYouFOrCOnfirmingYourBusiness'
              defaultMessage='Thank you for confirming your business relationship!'
            />
          </TitleText>
          <p className='mt4 f4 gray'>
            <FormattedMessage
              id='VerifyClientPage.toLearnMore'
              defaultMessage='To learn more about TealBook and what we have to offer, visit {tealbookUrl}'
              values={{
                tealbookUrl: (
                  <a
                    href='https://tealbook.com/'
                    className='f4 teal fw4 dim no-underline pointer'
                  >
                    tealbook.com
                  </a>
                )
              }}
            />
          </p>
        </Page>
      </div>
    )
  }
}

export default VerifyClientPage
