import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from 'react'
import { connect } from 'react-redux'
import companySelectors from '../../selectors/companySelectors'
import TealbotTips from 'shared/components/TealbotTips'
import CompletenessPercentage from 'shared/components/CompletenessPercentage'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import CompanyWizard from '../CompanyWizard'
import { FormattedMessage } from 'react-intl'
import { createSelector } from 'reselect'
import { Map } from 'immutable'
import Encouragement from './Encouragement'
import RootState from 'shared/models/RootState'

const style = {
  root: {
    zIndex: 0
  }
}

const messageMapping = {
  description: (
    <FormattedMessage
      id='CompletenessContainer.DescriptionMessage'
      defaultMessage='Start by adding a brief description of your business and linking to social media accounts. This is the first information buyers see.'
    />
  ),
  aboutUs: (
    <FormattedMessage
      id='CompletenessContainer.AboutUsMessage'
      defaultMessage='Improve your profile score by filling in your About Us section and let buyers know about your offerings and value proposition.'
    />
  ),
  team: (
    <FormattedMessage
      id='CompletenessContainer.TeamMessage'
      defaultMessage='Add a team member and their contact info to make it easier for buyers to reach you.'
    />
  ),
  skills: (
    <FormattedMessage
      id='CompletenessContainer.SkillsMessage'
      defaultMessage='Improve your profile score by adding tags to let buyers know your skills and what you are known for.'
    />
  ),
  locations: (
    <FormattedMessage
      id='CompletenessContainer.LocationsMessage'
      defaultMessage='Let buyers know the areas or countries where you do business.'
    />
  ),
  address: (
    <FormattedMessage
      id='CompletenessContainer.AddressMessage'
      defaultMessage='Improve your profile score by adding your head office address.'
    />
  ),
  resources: (
    <FormattedMessage
      id='CompletenessContainer.ResourcesMessage'
      defaultMessage='Engage with buyers by adding resources for them to view, like your certificates (especially any related to diversity!), blog or videos.'
    />
  ),
  customers: (
    <FormattedMessage
      id='CompletenessContainer.CustomersMessage'
      defaultMessage='Give prospective customers confidence by listing some of your current customers.'
    />
  )
}

type Props = {
  mini?: boolean
  missing: string[]
  totalPercentage: number
}

export const CompletenessContainer: FunctionComponent<Props> = props => {
  const [index, setIndex] = useState<number>(0)
  const [isWizardOpen, setIsWizardOpen] = useState<boolean>(false)
  const [wizardForms, setWizardForms] = useState<Array<string>>([])

  const { missing, totalPercentage, mini } = props

  useEffect(() => {
    // make sure to loop back to the first element
    // after going pass the last one
    if (index + 1 > missing.length) {
      setIndex(0)
    }
  }, [missing, index])

  const nextTip = useCallback(() => {
    setIndex(index + 1)
  }, [index])

  const toggleWizardDialog = () => {
    setIsWizardOpen(!isWizardOpen)
    setWizardForms(isWizardOpen ? [] : props.missing)
  }

  return (
    <>
      <div className='mb3'>
        <CompletenessPercentage percentage={totalPercentage} />
      </div>
      <TealbotTips mini={mini}>
        <Text>
          {totalPercentage > 50 && (
            <Encouragement totalPercentage={totalPercentage} />
          )}
          {totalPercentage === 100 ? (
            <FormattedMessage
              id='CompletenessContainer.MainPofileAreasMessage'
              defaultMessage="You've covered all the main profile areas. If you want ideas on how to keep improving your profile please reach out to a representative at TealBook."
            />
          ) : (
            <span className='fw6 mr1'>
              {
                <FormattedMessage
                  id='CompletenessContainer.PercentageProfileComplete'
                  defaultMessage='Your profile is {totalPercentage}% complete.'
                  values={{ totalPercentage }}
                />
              }
            </span>
          )}
          {missing && missing.length > 0 && messageMapping[missing[index]]}
        </Text>
      </TealbotTips>
      {missing && missing.length > 0 && (
        <div className='mt2 tr'>
          <Button
            label={
              <FormattedMessage
                id='CompletenessContainer.AddToProfile2'
                defaultMessage='Add to Profile'
              />
            }
            autoSize
            onClick={toggleWizardDialog}
          />
          {missing.length > 1 && (
            <Button
              label={
                <FormattedMessage
                  id='CompletenessContainer.NextTipLabel'
                  defaultMessage='Next Tip'
                />
              }
              autoSize
              onClick={nextTip}
              secondary
              className='ml3'
            />
          )}
        </div>
      )}

      <Dialog
        style={style.root}
        open={isWizardOpen}
        onClose={toggleWizardDialog}
      >
        <DialogTitle>
          <FormattedMessage
            id='CompletenessContainer.TealbotTips'
            defaultMessage='Tealbot Tips'
          />
        </DialogTitle>
        <DialogContent className='mt3'>
          {isWizardOpen && (
            <CompanyWizard
              forms={wizardForms}
              index={index}
              messageMapping={messageMapping}
              onCloseClick={toggleWizardDialog}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

const getMissingFields = createSelector(
  companySelectors.getProfileCompleteness,
  (completeness: Map<string, number>) =>
    completeness &&
    completeness
      .filter(percentage => percentage === 0)
      .keySeq()
      .toArray()
      .sort()
)

const getTotalPercentage = createSelector(
  companySelectors.getProfileCompleteness,
  (completeness: Map<string, number>) =>
    completeness &&
    completeness.toList().reduce((result, percent) => result + percent, 0)
)

export default connect((state: RootState) => ({
  totalPercentage: getTotalPercentage(state),
  missing: getMissingFields(state)
}))(CompletenessContainer)
