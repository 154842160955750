import React, { useEffect } from 'react'
import LoadPage, {
  UploadHistoryType
} from 'supplier/Insight/components/LoadPage'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import dataSelectors from 'shared/selectors/dataSelectors'
import { List, RecordOf, OrderedMap } from 'immutable'
import {
  uploadSpendFile,
  loadStagingHistory,
  notify,
  removeSpend,
  publishSpend
} from 'shared/actions'
import Page from 'shared/components/Page'

type Props = {
  isUploading: boolean
  uploadError: boolean
  uploadHistory?: OrderedMap<string, List<RecordOf<UploadHistoryType>>>
  uploadSpendFile: (payload: {
    file: File
    year: number
    quarter: number
    listType: string
  }) => void
  publishSpend: (payload: { stagingId: string; listType: string }) => void
  removeSpend: (payload: {
    stagingId: string
    listType: string
    uploadHistory?: OrderedMap<string, List<RecordOf<UploadHistoryType>>>
    activeQuarter: string
  }) => void
  loadStagingHistory: (payload: { listType: string }) => void
  notify: (payload: { message: string }) => void
  isLoading: boolean
}

const MlContainer = (props: Props) => {
  const { loadStagingHistory } = props
  useEffect(() => {
    loadStagingHistory({ listType: 'ml' })
  }, [loadStagingHistory])

  return (
    <Page title='Ml'>
      <LoadPage {...props} isMl />
    </Page>
  )
}

export default connect(
  (state: RootState) => {
    return {
      isUploading: state.getIn(['admin', 'upload', 'isUploading']),
      uploadError: state.getIn(['admin', 'upload', 'uploadError']),
      uploadHistory: dataSelectors.getStagingHistory(state),
      isLoading: state.getIn(['data', 'isLoading', 'stagingHistory'])
    }
  },
  {
    uploadSpendFile,
    publishSpend,
    removeSpend,
    loadStagingHistory,
    notify
  }
)(MlContainer)
