import React, { Component } from 'react'
import UserListItem, { UserListItemProps } from 'shared/components/UserListItem'
import Text from 'shared/components/Text'
import dateFormat from 'shared/utils/dateFormat'

type Props = {
  createdDate?: Date
  orgName: string
  title: string
} & Omit<UserListItemProps, 'secondaryText'>

class AssignedBy extends Component<Props> {
  render() {
    const { createdDate, orgName, title, ...rest } = this.props

    return (
      <UserListItem
        {...rest}
        secondaryText={
          <Text>
            {title} at {orgName} on {dateFormat(createdDate)}
          </Text>
        }
      />
    )
  }
}

export default AssignedBy
