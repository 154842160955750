import React, { FunctionComponent } from 'react'
import ExternalLink from 'shared/components/ExternalLink'
import Text from 'shared/components/Text'
import parseLocationToString from 'shared/utils/data/parseLocationToString'
import { RecordOf } from 'immutable'
// import PlaceIcon from 'shared/assets/icons/place.svg'
import PlaceIcon from 'shared/components/PlaceIcon'
import Tooltip from 'shared/components/Tooltip'
import Location from 'shared/models/Location'
import Immutable from 'immutable'

type Props = {
  className?: string
  noIcon?: boolean
  location: RecordOf<Location> | string
}

const Address: FunctionComponent<Props> = props => {
  const { className, noIcon = false } = props

  const location =
    typeof props.location === 'string'
      ? { address: props.location }
      : Immutable.isImmutable(props.location)
      ? props.location.toJS()
      : props.location

  return location && location.address ? (
    <Text className={className}>
      {!(location.components && !!location.components.lat) ? (
        <Tooltip title={<div className='w5'>{location.address}</div>}>
          <span className='flex items-center'>
            {!noIcon && <PlaceIcon color='primary' />}
            {parseLocationToString(location)}
          </span>
        </Tooltip>
      ) : (
        <ExternalLink
          href={`https://www.google.ca/maps/place/${parseLocationToString(
            location,
            { ignorePostcode: true }
          )}/@${location.components.lat},${location.components.long}`}
          className='gray no-underline dim flex items-center'
        >
          {!noIcon && <PlaceIcon color='primary' />}
          {parseLocationToString(location)}
        </ExternalLink>
      )}
    </Text>
  ) : null
}

export default Address
