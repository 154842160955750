import { takeLatest } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import updateCertificationSaga from '../updateCertificationSaga'

import {
  REMOVE_CERTIFICATION,
  ADD_CERTIFICATION,
  UPDATE_CERTIFICATION
} from '../../actionTypes'

export default function* updateDiversityCertificationSaga(action) {
  yield takeLatest(ADD_CERTIFICATION, updateCertificationSaga)
  yield takeLatest(UPDATE_CERTIFICATION, updateCertificationSaga)
  yield takeLatest(
    REMOVE_CERTIFICATION,
    createApiRequestSaga(supplier.removeCertification, {
      usePayload: true,
      returnPayload: true
    })
  )
}
