import React, { Component, FormEvent } from 'react'
import qs from 'qs'
import Page from 'shared/components/Page'
import SubTitleText from 'shared/components/SubTitleText'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { CREATE_VET } from '../../actionTypes'
import { FormattedMessage } from 'react-intl'
import { Field, formValueSelector, isSubmitting } from 'redux-form/immutable'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import routingSelectors from 'shared/selectors/routingSelectors'
import collectionSelectors from '../../../Search/selectors/collectionSelectors'
import { List } from 'immutable'
import { RouteComponentProps } from 'react-router'

type Props = {
  name: string
  queryString: string
  isSubmitting: boolean
  includeSuppliers: boolean
  collection: List<string>
  createVet: (formValues: string) => void
  handleSubmit: (
    handleSubmit: (formValues: any) => void
  ) => ((event: FormEvent<HTMLFormElement>) => void) | undefined
} & RouteComponentProps

export class NewVetContainer extends Component<Props> {
  handleSubmit = formValues => {
    const { collection, includeSuppliers, createVet, queryString } = this.props
    const suppliers = collection.keySeq().toArray()

    if (includeSuppliers) {
      createVet(
        formValues
          .set('suppliers', suppliers)
          .set('queryString', `?${qs.stringify(queryString)}`)
      )
    } else {
      createVet(formValues)
    }
  }

  handleCancelClick = () => {
    const { history } = this.props
    history.goBack()
  }

  render() {
    const { handleSubmit, name, isSubmitting } = this.props
    return (
      <Page
        title={
          <FormattedMessage
            id='NewVetContainer.NewRFI'
            defaultMessage='New RFI'
          />
        }
      >
        <div className='w-70 center'>
          <SubTitleText>
            <FormattedMessage
              id='NewVetContainer.SubtitleRFI'
              defaultMessage='Create a new RFI to run a pre-qualification process or RFI with submission from Suppliers'
            />
          </SubTitleText>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <Label required>
              <FormattedMessage
                id='NewVetContainer.Name'
                defaultMessage='Name'
              />
            </Label>
            <FormattedMessage
              id='NewVetContainer.WhatIsTheNameForThisVet'
              defaultMessage='Provide a descriptive name'
            >
              {placeholder => (
                <Field
                  component={Input}
                  name='name'
                  maxLength={150}
                  required
                  placeholder={placeholder as string}
                />
              )}
            </FormattedMessage>
            <Label>
              <FormattedMessage
                id='NewVetContainer.Summary'
                defaultMessage='Summary'
              />
            </Label>
            <FormattedMessage
              id='NewVetContainer.SummaryPlaceholder'
              defaultMessage='Provide a short description (optional)'
            >
              {// This should go to internalDescription when the new UI ready. For now it goes to vetDetails.description
              placeholder => (
                <Field
                  component={TextArea}
                  name='internalDescription'
                  maxLength={500}
                  height={96}
                  placeholder={placeholder as string}
                />
              )}
            </FormattedMessage>
            <FormattedMessage
              id='NewVetContainer.Continue'
              defaultMessage='Continue'
            >
              {label => (
                <Button
                  type='submit'
                  label={label}
                  autoSize
                  className='mr3'
                  disabled={!name || isSubmitting}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id='CancelButton'>
              {label => (
                <Button
                  label={label}
                  autoSize
                  secondary
                  onClick={this.handleCancelClick}
                />
              )}
            </FormattedMessage>
          </form>
        </div>
      </Page>
    )
  }
}

const formName = 'vets/NewVet'
const selector = formValueSelector(formName)

export default connectReduxForm(
  (state, props) => {
    const queryString = routingSelectors.getSearch(state)
    const { includeSuppliers, ...restQS } = qs.parse(queryString, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })

    return {
      initialValues: {
        name: ''
      },
      collection: collectionSelectors.getCollection(state),
      name: selector(state, 'name'),
      isSubmitting: isSubmitting(formName)(state),
      includeSuppliers,
      queryString: restQS
    }
  },
  {
    createVet: createSubmitFormAction(formName, CREATE_VET)
  },
  {
    form: formName
  },
  NewVetContainer
)
