import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

export const verifySupplier = createAction(actionTypes.VERIFY_SUPPLIER)
export const updateCard = createAction(actionTypes.UPDATE_CARD)
export const loadSupplierProfile = createAction(
  actionTypes.LOAD_SUPPLIER_PROFILE,
  (payload, meta) => payload,
  (payload, meta) => meta || {}
)
export const removeTag = createAction(actionTypes.REMOVE_TAG)
export const deletePrivateOfferings = createAction(
  actionTypes.DELETE_PRIVATE_OFFERINGS
)
export const addTag = createAction(actionTypes.ADD_TAG)
export const getVetBuyerSuppliers = createAction(
  actionTypes.GET_VET_BUYER_SUPPLIERS
)
export const addToVet = createAction(actionTypes.ADD_TO_VET)
export const addPersonalRelationship = createAction(
  actionTypes.ADD_PERSONAL_RELATIONSHIP
)
export const batchAddPersonalRelationship = createAction(
  actionTypes.BATCH_ADD_PERSONAL_RELATIONSHIP
)
export const updateRelationship = createAction(actionTypes.UPDATE_RELATIONSHIP)
export const removeRelationship = createAction(actionTypes.REMOVE_RELATIONSHIP)

export const cancelAddingContact = createAction(
  actionTypes.CANCEL_ADDING_CONTACT
)

export const cancelAddingOutreachContact = createAction(
  actionTypes.CANCEL_ADDING_OUTREACH_CONTACT
)
export const selectNewUserForContact = createAction(
  actionTypes.SELECT_NEW_USER_FOR_CONTACT
)
export const openAddOutreachContactForm = createAction(
  actionTypes.OPEN_ADD_OUTREACH_CONTACT_FORM
)
export const selectUserForContact = createAction(
  actionTypes.SELECT_USER_FOR_CONTACT
)
export const removeContact = createAction(actionTypes.REMOVE_CONTACT)
export const removeContactRelationship = createAction(
  actionTypes.REMOVE_CONTACT_RELATIONSHIP
)
export const editContact = createAction(actionTypes.EDIT_CONTACT)
export const cancelEditContact = createAction(actionTypes.CANCEL_EDIT_CONTACT)
export const updateContact = createAction(actionTypes.UPDATE_CONTACT)

export const removeRelationshipAttachment = createAction(
  actionTypes.REMOVE_RELATIONSHIP_ATTACHMENT
)
export const requestForProfileUpdate = createAction(
  actionTypes.REQUEST_FOR_PROFILE_UPDATE
)

export const requestforSelfCertify = createAction(
  actionTypes.REQUEST_FOR_SELF_CERTIFY
)
export const requestForESGSurvey = createAction(
  actionTypes.REQUEST_FOR_ESG_SURVEY
)
export const upvoteResponse = createAction(actionTypes.UPVOTE_RESPONSE)
export const clearVoteResponse = createAction(actionTypes.CLEAR_VOTE_RESPONSE)

export const deleteReview = createAction(actionTypes.DELETE_REVIEW)

export const loadSocialFeed = createAction(actionTypes.LOAD_SOCIAL_FEED)

export const addCertificationValidation = createAction(
  actionTypes.ADD_CERTIFICATION_VALIDATION
)
export const removeCertificationValidation = createAction(
  actionTypes.REMOVE_CERTIFICATION_VALIDATION
)

export const addSupplierComment = createAction(actionTypes.ADD_SUPPLIER_COMMENT)
export const updateSupplierStatus = createAction(
  actionTypes.UPDATE_SUPPLIER_STATUS
)

type RequestBuyerForReferencePayload = {
  shareCompanyName: boolean
  shareNameAndTitle: boolean
  companyName: string
  userName: string
  userTitle: string
  details: string
  supplierName: string
}
export const requestBuyerForReference = createAction<
  RequestBuyerForReferencePayload
>(actionTypes.REQUEST_BUYER_REFERENCE)

export const addContact = createAction(actionTypes.ADD_CONTACT)
export const addOutreachContact = createAction(actionTypes.ADD_OUTREACH_CONTACT)

export const requestEcovadisSupplierRating = createAction(
  actionTypes.REQUEST_ECOVADIS_SUPPLIER_RATING
)

export const retrieveEcovadisAssessmentStatus = createAction(
  actionTypes.RETRIEVE_ECOVADIS_ASSESSMENT_STATUS
)

export const retrieveEcovadisScorecard = createAction(
  actionTypes.RETRIEVE_ECOVADIS_SCORECARD
)

export const loadSurvey = createAction(actionTypes.LOAD_SURVEY)

export const loadSurveyResponse = createAction(actionTypes.LOAD_SURVEY_RESPONSE)

export const openESGRequestDialog = createAction(
  actionTypes.OPEN_ESG_REQUEST_DIALOG
)
export const closeESGRequestDialog = createAction(
  actionTypes.CLOSE_ESG_REQUEST_DIALOG
)

export const loadESGRequestDetails = createAction(
  actionTypes.LOAD_ESG_REQUEST_DETAILS
)

export const verifyESGAttachment = createAction(
  actionTypes.VERIFY_ESG_ATTACHMENT
)
export const openVerifyESGAttachmentDialog = createAction(
  actionTypes.OPEN_VERIFY_ESG_ATTACHMENT_DIALOG
)
export const closeVerifyESGAttachmentDialog = createAction(
  actionTypes.CLOSE_VERIFY_ESG_ATTACHMENT_DIALOG
)
export const transferSpend = createAction(actionTypes.TRANSFER_SPEND)
