import React from 'react'
import { List, RecordOf } from 'immutable'
import TagsForm from '../../components/TagsForm'
import { TagsFormProps } from '../../components/TagsForm'
import { Prompt } from 'react-router'
import { defineMessages, useIntl } from 'react-intl'
import { InjectedFormProps } from 'redux-form'

const messages = defineMessages({
  offerings: {
    id: 'TagFormContainer.WarningOfferings',
    defaultMessage:
      'You have unsaved skill. Are you sure you want to navigate away?'
  },
  differentiators: {
    id: 'TagFormContainer.Warningddifferentiators',
    defaultMessage:
      'You have unsaved differentiator. Are you sure you want to navigate away?'
  }
})

type FormData = {
  newTag: string
}

type Props = {
  /**
   * Name of the field that will reference the object holding the tag values
   */
  fieldName: string
  /**
   * List of tags to display
   */
  tags?: List<string>
  /**
   * Gets called when the new tag form is submitted
   *
   * @param {string} newTag to be added to the list
   */
  onSubmitNewTag: (details: { newTag: string; fieldName: string }) => void
  /**
   * Gets called when the remove tags form is submitted
   *
   * @param {List(string)} tagToRemove list of tags to removes
   */
  onDeleteTag: (details: { tagToRemove: string; fieldName: string }) => void
} & InjectedFormProps<RecordOf<FormData>> &
  TagsFormProps

/**
 * Provide local state management to a tags form
 *
 * @author Eduardo Duran
 */
export const TagsFormContainer = (props: Props) => {
  const intl = useIntl()
  const {
    handleSubmit,
    change,
    dirty,
    fieldName,
    onSubmitNewTag,
    onDeleteTag,
    ...rest
  } = props

  const handleSubmitNewTag = (formValues: RecordOf<FormData>) => {
    onSubmitNewTag({
      newTag: formValues.get('newTag'),
      fieldName: fieldName
    })
    change('newTag', '')
  }

  const handleDeleteTag = (tag: string) => {
    onDeleteTag({
      tagToRemove: tag,
      fieldName: fieldName
    })
  }

  return (
    <>
      <TagsForm
        {...rest}
        onSubmitNewTag={handleSubmit(handleSubmitNewTag)}
        onDeleteTag={handleDeleteTag}
      />
      <Prompt
        when={dirty}
        message={
          messages[fieldName]
            ? intl.formatMessage(messages[fieldName])
            : fieldName
        }
      />
    </>
  )
}

export default TagsFormContainer
