import { call } from 'redux-saga/effects'
import staging from 'shared/utils/api/staging'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* publishSpendSaga(action) {
  const { stagingId, listType } = action.payload
  return yield call(
    listType !== 'supplierTier2Load'
      ? staging.publishClientSpend
      : staging.publishSupplierSpend,
    { stagingId, listType }
  )
}

export default createApiRequestSaga(publishSpendSaga)
