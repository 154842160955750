import * as actionTypes from '../../actionTypes'
import { fromJS } from 'immutable'
import { requestSuccess } from 'shared/actionTypes'
import mergeDeepOverwriteLists from '../../../../shared/utils/mergeDeepOverwriteLists'

const initialState = fromJS({
  isLoading: true
})

/**
 * communities: {
 *   byId,
 *   membershipsById,
 *   invitedIds: [...ids],
 *   recommendedIds: [...ids],
 *   acceptedIds: [...ids],
 *   isLoading: false,
 *   loadingErrors: undefined
 *   loadingCommunityFormPage: true
 * }
 */
const communitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_COMMUNITIES:
      return state.set('isLoading', true)

    case actionTypes.LOAD_COMMUNITY:
      return state.set('loadingCommunityFormPage', true)

    case requestSuccess(actionTypes.LOAD_COMMUNITY):
      return state
        .updateIn(['byId', action.payload.community.id], community =>
          mergeDeepOverwriteLists(community, fromJS(action.payload.community))
        )
        .setIn(
          ['membershipsById', action.payload.membership.id],
          fromJS(action.payload.membership)
        )
        .setIn(['loadingCommunityFormPage'], false)

    case requestSuccess(actionTypes.LOAD_COMMUNITIES):
      return state.merge(fromJS(action.payload)).set('isLoading', false)

    case requestSuccess(actionTypes.ACCEPT_COMMUNITY):
      return state
        .update('invitedIds', ids => filterOutId(ids, action.payload.commId))
        .update('recommendedIds', ids =>
          filterOutId(ids, action.payload.commId)
        )
        .update('acceptedIds', ids => ids.push(action.payload.commId))
        .setIn(
          ['byId', action.payload.commId, 'membershipId'],
          action.payload.communityPlanMembership.id
        )
        .setIn(
          ['membershipsById', action.payload.communityPlanMembership.id],
          fromJS(action.payload.communityPlanMembership)
        )

    case requestSuccess(actionTypes.DECLINE_COMMUNITY):
      return state
        .update('invitedIds', ids => filterOutId(ids, action.payload.commId))
        .update('recommendedIds', ids => ids.push(action.payload.commId))
        .setIn(
          ['membershipsById', action.payload.communityPlanMembership.id],
          fromJS(action.payload.communityPlanMembership)
        )

    case requestSuccess(actionTypes.LEAVE_COMMUNITY):
      return state
        .update('acceptedIds', ids =>
          filterOutId(ids, action.payload.communityId)
        )
        .deleteIn(['membershipsById', action.payload.comPlanMembershipId])
    case actionTypes.ANSWER_QUESTION:
      const { questionId, response, membershipId } = action.payload
      return state.mergeIn(
        ['membershipsById', membershipId, 'response', 'responses', questionId],
        fromJS({
          response,
          question: questionId
        })
      )

    case requestSuccess(actionTypes.SUBMIT_COMMUNITY_FORM_RESPONSE):
      return state.setIn(
        ['membershipsById', action.payload.membershipId, 'response', 'status'],
        'submitted'
      )

    case requestSuccess(actionTypes.VIEW_COMMUNITY_FORM_PAGE):
      return state.setIn(
        [
          'membershipsById',
          action.payload.membershipId,
          'response',
          'pagesViewed',
          action.payload.pageId
        ],
        fromJS({
          viewed: action.payload.userId
        })
      )

    default:
      return state
  }
}

function filterOutId(ids, commId) {
  return ids.filter(id => id !== commId)
}

export default communitiesReducer
