import React, { useState } from 'react'
import PageSideSection from 'shared/components/PageSideSection'
import Button from 'shared/components/Button'
import AutoSaveInput from 'shared/components/AutoSaveInput'
import Input from 'shared/components/Input'
import InputWithLabel from 'shared/components/InputWithLabel'
import Select from 'shared/components/Select'
import Divider from 'shared/components/Divider'
import EditButton from 'shared/components/EditProfileButton'
import { FormattedMessage } from 'react-intl'
import formValidators from 'shared/utils/formValidators'
import NaicsSearchContainer from 'shared/containers/NaicsSearchContainer'
import ClassificationCode from 'shared/models/ClassificationCode'
import { Map, List } from 'immutable'
import Label from 'shared/components/Label'
import ClassificationCodesContainer from 'shared/containers/ClassificationCodesContainer'
import Tooltip from 'shared/components/Tooltip'

type Props = {
  name: string
  numberOfEmployees: string
  yearFounded: number
  annualRevenue: string
  taxId: string
  dunsNumber: string
  lei: string
  naics: Map<string, List<ClassificationCode>>
  onUpdateParentOrg: (changes: { [fieldName: string]: Object }) => void
  onUpdateSupplier: (changes: { [fieldName: string]: Object }) => void
  isTealbot: boolean
  isEditable: boolean
}

const CorporateSection = (props: Props) => {
  const [editing, setEditing] = useState<boolean>(false)

  const today = new Date()

  const handleChangeNumberOfEmployees = e => {
    props.onUpdateParentOrg({
      [e.target.name]: e.target.value
    })
  }
  const handleChangeAnnualRevenue = e => {
    props.onUpdateParentOrg({
      [e.target.name]: e.target.value
    })
  }
  const yearFoundedValidator = (val: string) => {
    const year = parseInt(val, 10)
    return year < 1500 ? (
      <FormattedMessage
        id='CorporateSection.TooFarInPast'
        defaultMessage='Cannot be that far back in the past'
      />
    ) : year > today.getFullYear() ? (
      <FormattedMessage
        id='CorporateSection.TooFarInFuture'
        defaultMessage='Cannot be in the future'
      />
    ) : (
      undefined
    )
  }

  const handleSaveCompanyInfo = (value: Object) => {
    props.onUpdateSupplier({
      supplier: {
        ...value
      }
    })
  }

  const normalizeInteger = (val: string) => val && parseInt(val, 10)
  return (
    <PageSideSection
      title={
        <FormattedMessage
          id='CorporateSection.CorporateTitle'
          defaultMessage='Corporate'
        />
      }
      actions={
        (props.isEditable || props.isTealbot) &&
        (editing ? (
          <Button
            label={
              <FormattedMessage
                id='CorporateSection.DoneButton'
                defaultMessage='Done'
              />
            }
            autoSize
            onClick={() => setEditing(!editing)}
          />
        ) : (
          <EditButton onClick={() => setEditing(!editing)} />
        ))
      }
    >
      <FormattedMessage
        id='CorporateSection.ParentCompanyPlaceholder'
        defaultMessage='What is the name of your parent company?'
      >
        {(message: string) => (
          <InputWithLabel
            label={
              <FormattedMessage
                id='CorporateSection.ParentCompnayNameLabel'
                defaultMessage="Parent Company's name"
              />
            }
            displayValue={props.name}
            placeholder={message}
            editMode={props.isTealbot && editing}
            inputComponent={
              <AutoSaveInput
                component={Input}
                value={props.name}
                name='name'
                maxLength={150}
                onSave={props.onUpdateParentOrg}
              />
            }
            required={false}
          />
        )}
      </FormattedMessage>

      <FormattedMessage
        id='CorporateSection.NumberOfEmployeesPlaceholder'
        defaultMessage='How many employees do you have in your company?'
      >
        {(message: string) => (
          <InputWithLabel
            label={
              <FormattedMessage
                id='CorporateSection.NumberofEmployees'
                defaultMessage='Number of employees'
              />
            }
            displayValue={props.numberOfEmployees}
            placeholder={message}
            editMode={editing}
            inputComponent={
              <Select
                fullWidth
                value={props.numberOfEmployees}
                name='employeeNumber'
                onChange={handleChangeNumberOfEmployees}
              >
                <option value=''>Please select an option</option>
                <option>1-10</option>
                <option>10-25</option>
                <option>25-50</option>
                <option>50-100</option>
                <option>100-200</option>
                <option>200-500</option>
                <option>500-1,000</option>
                <option>1,000-10,000</option>
                <option>10,000-100,000</option>
                <option>100,000+</option>
              </Select>
            }
            required={false}
          />
        )}
      </FormattedMessage>

      <FormattedMessage
        id='CorporateSection.YearFoundedPlaceholder'
        defaultMessage='What year was your company founded?'
      >
        {(message: string) => (
          <InputWithLabel
            label={
              <FormattedMessage
                id='CorporateSection.YearFoundedLabel'
                defaultMessage='Year founded'
              />
            }
            displayValue={'' + props.yearFounded}
            placeholder={message}
            editMode={editing}
            inputComponent={
              <AutoSaveInput
                component={Input}
                type='number'
                value={props.yearFounded}
                name='yearFounded'
                onSave={props.onUpdateParentOrg}
                validate={[yearFoundedValidator]}
                normalize={normalizeInteger}
              />
            }
            required={false}
          />
        )}
      </FormattedMessage>

      <FormattedMessage
        id='CorporateSection.AnnualRevenueSupplier'
        defaultMessage='What is your annual revenue?'
      >
        {(message: string) => (
          <InputWithLabel
            label={
              <FormattedMessage
                id='CorporateSection.AnnualRevenueLabel'
                defaultMessage='Annual revenue'
              />
            }
            displayValue={props.annualRevenue}
            placeholder={message}
            editMode={editing}
            inputComponent={
              <Select
                fullWidth
                value={props.annualRevenue}
                name='yearlyRevenue'
                onChange={handleChangeAnnualRevenue}
              >
                <option value=''>Please select an option</option>
                <option>{'$0-$1M'}</option>
                <option>{'$1M-$10M'}</option>
                <option>{'$10M–$50M'}</option>
                <option>{'$50M-$100M'}</option>
                <option>{'$100M-$250M'}</option>
                <option>{'$250M-$500M'}</option>
                <option>{'$500M-$1B'}</option>
                <option>{'$1B-$10B'}</option>
                <option>{'>$10B'}</option>
              </Select>
            }
            required={false}
          />
        )}
      </FormattedMessage>

      <Divider className='mt3' />

      <FormattedMessage
        id='CorporateSection.DUNSNumberPlaceholder'
        defaultMessage='What is your DUNS #?'
      >
        {(message: string) => (
          <InputWithLabel
            label={
              <FormattedMessage
                id='CorporateSection.DUNSNumberLabel'
                defaultMessage='DUNS #'
              />
            }
            displayValue={props.dunsNumber}
            placeholder={message}
            editMode={editing}
            inputComponent={
              <AutoSaveInput
                component={Input}
                value={props.dunsNumber}
                name='dunsNumber'
                onSave={handleSaveCompanyInfo}
                validate={[formValidators.validateDUNS]}
              />
            }
            required={false}
          />
        )}
      </FormattedMessage>

      <FormattedMessage
        id='CorporateSection.TaxIDPlaceholder'
        defaultMessage='What is your Tax ID?'
      >
        {(message: string) => (
          <InputWithLabel
            label={
              <FormattedMessage
                id='CorporateSection.TaxIDLabel'
                defaultMessage='Tax ID'
              />
            }
            displayValue={props.taxId}
            placeholder={message}
            editMode={editing}
            inputComponent={
              <AutoSaveInput
                component={Input}
                value={props.taxId}
                name='taxId'
                onSave={handleSaveCompanyInfo}
                validate={[formValidators.validateTIN]}
                normalize={value => value.toUpperCase()}
              />
            }
            required={false}
          />
        )}
      </FormattedMessage>

      <FormattedMessage
        id='CorporateSection.LEIPlaceholder'
        defaultMessage='What is your LEI?'
      >
        {(message: string) => (
          <InputWithLabel
            label={
              <Tooltip
                title={
                  <FormattedMessage
                    id='CorporateSection.LEIHelp'
                    defaultMessage='The Legal Entity Identifier (LEI) is a unique global identifier for legal entities participating in financial transactions. It is a 20-character, alpha-numeric code based on the ISO 17442 standard developed by the International Organization for Standardization (ISO). Any company can request an LEI from one of the registered providers.'
                  />
                }
              >
                <span>
                  <FormattedMessage
                    id='CorporateSection.LEILabel'
                    defaultMessage='LEI'
                  />
                </span>
              </Tooltip>
            }
            displayValue={props.lei}
            placeholder={
              <>
                <Tooltip
                  title={
                    <FormattedMessage
                      id='CorporateSection.LEIHelp'
                      defaultMessage='The Legal Entity Identifier (LEI) is a unique global identifier for legal entities participating in financial transactions. It is a 20-character, alpha-numeric code based on the ISO 17442 standard developed by the International Organization for Standardization (ISO). Any company can request an LEI from one of the registered providers.'
                    />
                  }
                >
                  {<span>{message}</span>}
                </Tooltip>
              </>
            }
            editMode={editing}
            inputComponent={
              <AutoSaveInput
                component={Input}
                value={props.lei}
                name='lei'
                onSave={handleSaveCompanyInfo}
                validate={[formValidators.validateLEI]}
                normalize={value => value.toUpperCase()}
              />
            }
            required={false}
          />
        )}
      </FormattedMessage>
      <div>
        {!!props.naics && (
          <>
            {(editing || props.naics.size > 0) && <Divider className='mt3' />}
            <ClassificationCodesContainer
              classificationCodesBySchema={props.naics}
              schemaOrder={['naics']}
              editable={editing}
            />
          </>
        )}
        {editing && !!props.naics && props.naics.isEmpty() && (
          <Label>
            <FormattedMessage
              id='CorporateSection.NAICS'
              defaultMessage='NAICS Code'
            />
          </Label>
        )}
        {editing && <NaicsSearchContainer />}
      </div>
    </PageSideSection>
  )
}

export default CorporateSection
