import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import AutoSaveInput from 'shared/components/AutoSaveInput'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import companySelectors from '../../selectors/companySelectors'
import { saveCompanyInfo } from '../../../Company/actions'
import { validateUrl } from 'shared/utils/data/validate'

type Props = {
  saveCompanyInfo: () => void
  videoUrl: string
  blogUrl: string
}

export class ResourcesForm extends Component<Props> {
  render() {
    const { saveCompanyInfo, videoUrl, blogUrl } = this.props

    return (
      <Fragment>
        <Label>Video URL</Label>
        <AutoSaveInput
          name='supplierVideoUrl'
          value={videoUrl}
          normalize={v => ({ links: { videoUrls: [v] } })}
          component={Input}
          validate={[validateUrl]}
          placeholder='e.g. https://www.youtube.com/watch?v=vQaVdw-pMRY'
          onSave={saveCompanyInfo}
        />
        <Label>Blog URL</Label>
        <AutoSaveInput
          name='supplierBlogUrl'
          value={blogUrl}
          normalize={v => ({ links: { blogUrl: v } })}
          component={Input}
          validate={[validateUrl]}
          placeholder='e.g. https://tealbook.com/blog/'
          onSave={saveCompanyInfo}
        />
      </Fragment>
    )
  }
}

export default connect(
  state => {
    const links = companySelectors.getCompanySupplierField(state, 'links')
    return {
      videoUrl: links.getIn(['videoUrls', 0]),
      blogUrl: links.get('blogUrl')
    }
  },
  { saveCompanyInfo }
)(ResourcesForm)
