import React, { useState, useMemo } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Select from 'react-select'
import useSmallScreen from 'shared/utils/useSmallScreen'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import Button from 'shared/components/Button'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import { useDispatch } from 'react-redux'
import { notify } from 'shared/actions'
import { fromJS, List, RecordOf } from 'immutable'
import AddOutreachContactForm from '../../containers/AddOutreachContactForm'
import AddIcon from '@material-ui/icons/AddCircle'
import User from 'shared/models/User'
import ESGSurveyMessageSummary from '../../../SupplierProfile/components/ESGSurveyMessageSummary'
import sorting from 'shared/utils/sortContactsByNameAndInput'
import ContactItem from 'shared/components/ContactItem'

const messages = defineMessages({
  esgSurvey: {
    id: 'ESGSurveyDialog.PleaseCompleteESGSurvey',
    defaultMessage: `{name} has invited you to complete TealBook’s sustainability survey to capture information about your company’s Environment, Social, Governance (ESG) efforts. This survey is an opportunity for you to share your organization’s sustainability efforts with potential buyers, and the results will be publicly available to buyers on the TealBook platform. By taking just a few minutes to complete this survey, you could attract more business from sustainability-minded companies. Click the button below to get started.`
  }
})

type Props = {
  contacts: List<RecordOf<User>>
  activeUserId: string
  requestedContactId: string
  taskId: string
  clientName: string
  isESGSurveyDialogOpen: boolean
  shareESGSurveyLink: boolean
  requestedContact: RecordOf<User>
  handleToggleESGSurveyDialog: (value: boolean) => void
  handleResendSupplierOutreachRequest: (
    taskId: string,
    status: string,
    currentUserId: string,
    selectedRequestContactId: string
  ) => void
  handleCancel: () => void
  onMessageUser: (arg: string) => void
  onClickNewUser: () => void
  onClickCancel: () => void
}

export const ESGSurveyDialog = (props: Props) => {
  const {
    contacts,
    activeUserId,
    requestedContactId,
    taskId,
    clientName,
    isESGSurveyDialogOpen,
    requestedContact,
    shareESGSurveyLink,
    handleToggleESGSurveyDialog,
    handleResendSupplierOutreachRequest,
    handleCancel,
    onMessageUser,
    onClickNewUser,
    onClickCancel
  } = props
  const dispatch = useDispatch()
  const matchMobile = useSmallScreen()
  const intl = useIntl()
  const [requestESGSurveyMessage, setrequestESGSurveyMessage] = useState<
    string
  >(intl.formatMessage(messages.esgSurvey, { name: clientName }))
  const [selectInput, setSelectInput] = useState<string>('')
  const [selectedContactId, setSelectedContactId] = useState<string>(
    requestedContactId
  )
  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false)
  const handleOnClickAddUserButton = () => {
    setOpenNewContactForm(true)
    onClickNewUser()
    handleToggleESGSurveyDialog(false)
  }
  const handleCancelESGNewUserForm = () => {
    onClickCancel()
    handleToggleESGSurveyDialog(true)
  }
  const handleSelectInputChange = (inputValue: string) => {
    setSelectInput(inputValue)
  }

  const sortedContactsBySustainability:
    | List<RecordOf<User>>
    | List<undefined> = useMemo(
    () => sorting.sortContactsBySustainabilityContact(contacts, selectInput),
    [contacts, selectInput]
  )

  const filterOption = props => {
    const user = props.data
    const userFullName = `${user.firstName &&
      user.firstName.trim()} ${user.lastName &&
      user.lastName.trim()}`.toLowerCase()

    return userFullName.includes(selectInput.toLowerCase())
  }

  const formatOptionLabel = (user, data) => {
    if (!user) {
      return null
    }
    return (
      <ContactItem
        key={user.id}
        firstName={user.firstName}
        lastName={user.lastName}
        profilePictureUrl={user.profilePictureUrl}
        title={user.title}
        isSustainabilityContact={user.isSustainabilityContact}
        isEsgSurvey={true}
      />
    )
  }

  const handleESGSurveyMessage = e => {
    setrequestESGSurveyMessage(e.target.value)
  }
  const handleContactSelected = (data, metaAction) => {
    setSelectedContactId(data.id)
    setSelectInput('')
  }
  const handleESGSurveyRequest = e => {
    e.preventDefault()
    onMessageUser(
      fromJS({
        userToMessageId: selectedContactId,
        message: requestESGSurveyMessage,
        shareESGSurveyLink
      })
    )
    handleResendSupplierOutreachRequest(
      taskId,
      'WaitingForClient',
      activeUserId,
      selectedContactId
    )
    dispatch(notify({ message: 'Message succesfully sent' }))

    handleToggleESGSurveyDialog(false)
  }
  return (
    <>
      <Dialog
        onClose={() => handleToggleESGSurveyDialog(false)}
        open={isESGSurveyDialogOpen}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
      >
        <form onSubmit={handleESGSurveyRequest}>
          <DialogTitle>
            <FormattedMessage
              id='ESGSurveyDialog.RequestESGSurvey'
              defaultMessage='Sustainability Survey Request'
            />
          </DialogTitle>
          <DialogContent className='mt3'>
            <Text>
              <FormattedMessage
                id='ESGSurveyDialog.ContactSelectInstruction'
                defaultMessage='Indicate a Contact you would like to complete this request'
              />
            </Text>
            <Label>
              <FormattedMessage
                id='ESGSurveyDialog.ContactSelect'
                defaultMessage='Contacts'
              />
            </Label>
            <Select
              aria-label='Select Contact'
              InputValue={selectInput}
              onInputChange={handleSelectInputChange}
              onChange={handleContactSelected}
              placeholder={
                (
                  <FormattedMessage
                    id='UserLookup.Select Contact'
                    defaultMessage='Type in Contact Name'
                  />
                ) as any
              }
              options={sortedContactsBySustainability.toJS()}
              defaultValue={requestedContact.toJS()}
              formatOptionLabel={formatOptionLabel}
              filterOption={filterOption}
              getOptionValue={opt => opt.id || opt.value}
              noOptionsMessage={inputValue => 'No Contacts Found'}
              controlShouldRenderValue={true}
              styles={{
                control: base => ({
                  ...base,
                  height: 30
                }),
                input: base => ({
                  ...base,
                  fontSize: '.875rem',
                  height: 30
                }),
                placeholder: base => ({
                  ...base,
                  fontSize: '.875rem'
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                noOptionsMessage: base => ({
                  ...base,
                  fontSize: '.875rem'
                }),
                option: (base, { isDisabled, isFocused }) => ({
                  ...base,
                  backgroundColor: isDisabled
                    ? 'inherit'
                    : isFocused
                    ? '#E0E0E0'
                    : 'inherit',
                  ':hover': {
                    ...base[':hover'],
                    backgroundColor: !isDisabled && '#e0e0e0'
                  }
                })
              }}
              menuPortalTarget={document.body}
            />
            <div className='mb3 mt3'>
              <div className='dib w-35'>
                <Button
                  color='primary'
                  variant='text'
                  startIcon={<AddIcon />}
                  label={
                    <FormattedMessage
                      id='ESGSurveyDialog.AddContact'
                      defaultMessage='Add a New Contact'
                    />
                  }
                  onClick={() => handleOnClickAddUserButton()}
                />
              </div>
            </div>
            <Label htmlFor='message'>
              <FormattedMessage
                id='ESGSurveyDialog.AddMessage'
                defaultMessage='Add Message'
              />
            </Label>
            <TextArea
              id='message'
              value={requestESGSurveyMessage}
              onChange={handleESGSurveyMessage}
              maxLength={500}
            />
            <ESGSurveyMessageSummary
              name={`Sustainability Survey Link`}
              description={`Link to the Sustainability Survey Form`}
            />
          </DialogContent>
          <DialogActions className='fixed static-ns bottom-1'>
            <Button
              size={matchMobile ? 'large' : undefined}
              label={
                <FormattedMessage
                  id='ESGSurveyDialog.Send'
                  defaultMessage='Send'
                />
              }
              disabled={!selectedContactId || !requestESGSurveyMessage}
              type='submit'
            />
            <Button
              size={matchMobile ? 'large' : undefined}
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleCancel}
              secondary
              className='mr3'
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>
      {openNewContactForm && (
        <AddOutreachContactForm
          defaultMessage={intl.formatMessage(messages.esgSurvey, {
            name: clientName
          })}
          resendOutreachRequest={true}
          shareESGSurveyLink={shareESGSurveyLink}
          taskId={taskId}
          onClickCancel={handleCancelESGNewUserForm}
        />
      )}
    </>
  )
}
export default ESGSurveyDialog
