const basePath = '/supplier'

const paths = {
  basePath,
  faq: `${basePath}/faq`,
  company: `${basePath}/company`,
  target: `${basePath}/target`,
  dashboard: `${basePath}/dashboard`,
  communities: `${basePath}/communities`,
  userProfile: `${basePath}/users/:userId`,
  editUserProfile: `${basePath}/users/:userId/edit`,
  vets: `${basePath}/vets`,
  insight: `${basePath}/insight`,
  tealboard: `${basePath}/tealboard`,
  messages: `${basePath}/messages/`,
  messageTopic: `${basePath}/messages/:messageTopicId`,
  clientProfile: `${basePath}/clients/:orgUnitId`,
  subscriptionPage: `${basePath}/subscription`,
  onboarding: `${basePath}/onboarding`,
  search: `${basePath}/search`,
  supplierProfile: `${basePath}/suppliers/:supplierId`,
  diversity: `${basePath}/diversity`,
  diversityInvitation: `${basePath}/diversity/invitation`,
  contributors: `${basePath}/contributors`,
  esgSurvey: `${basePath}/esg-survey`,
  healthSafety: `${basePath}/esg-survey/health-safety`,
  environment: `${basePath}/esg-survey/environment`,
  diversityInclusion: `${basePath}/esg-survey/diversity-inclusion`,
  governance: `${basePath}/esg-survey/governance`,
  privacy: `${basePath}/esg-survey/privacy`
}

export default paths
