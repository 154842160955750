import React, { Component, ReactElement } from 'react'
import Link from '../Link'
import Logo from '../OrgLogo'
import Text from '../Text'
import imgCheck from 'shared/assets/icons/check.svg'
import Button from '../Button'
import Scrollable from '../Scrollable'
import OrgLogo from 'shared/components/OrgLogo'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import { FormattedMessage } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Tooltip from 'shared/components/Tooltip'

const styles = {
  default: {
    logo: {
      className: 'pa1 dtc v-mid br2 tc ba b--black-10 flex items-center',
      style: { height: 75, width: 75, overflow: 'hidden' }
    },
    name: 'f5 fw6 ma0 mt3',
    descriptionWrapper: 'overflow-y-auto',
    description: 'f6 fw4 mid-gray ma0 lh-copy mt3'
  }
}

type Props = {
  name: string
  nsWidth?: string
  logoUrl?: string
  description?: string | ReactElement<FormattedMessage>
  numberBuyers?: number
  linkTo?: string
  isMember?: boolean
  hasForm?: boolean
  hasCompletedForm?: boolean
  isPrivate?: boolean
  supplierSearchable?: boolean
  onViewForm?: () => void
  childrenHeight?: number
  communityId?: string
  comPlanMembershipId?: string
  onLeaveCommunity?: (payload: {
    communityId?: string
    comPlanMembershipId?: string
  }) => void
}

type State = {
  isLeaveDialogOpen: boolean
}

class CommunityCard extends Component<Props, State> {
  state: State = {
    isLeaveDialogOpen: false
  }

  toggleLeaveDialog = () => {
    this.setState({
      isLeaveDialogOpen: !this.state.isLeaveDialogOpen
    })
  }

  handleLeaveCommunity = (payload: {
    communityId?: string
    comPlanMembershipId?: string
  }) => {
    if (this.props.onLeaveCommunity) {
      this.props.onLeaveCommunity(payload)
      this.toggleLeaveDialog()
    }
  }

  render() {
    const {
      logoUrl,
      name,
      description,
      isMember,
      hasForm,
      hasCompletedForm,
      onViewForm,
      linkTo,
      childrenHeight,
      communityId,
      comPlanMembershipId,
      onLeaveCommunity,
      numberBuyers,
      isPrivate,
      supplierSearchable,
      nsWidth
    } = this.props

    const cardStyle = styles.default

    const { isLeaveDialogOpen } = this.state

    return (
      <div className={`pa2 fl w-100 ${nsWidth ? nsWidth : 'w-third-ns'}`}>
        <div className='bg-white ba b--black-10 br2 pa3'>
          <div className='mv2 cf'>
            <div style={{ minHeight: 220 }}>
              <div className='flex justify-between'>
                <div
                  className={cardStyle.logo.className}
                  style={cardStyle.logo.style}
                >
                  {linkTo ? (
                    <Link to={linkTo}>
                      <Logo url={logoUrl} square />
                    </Link>
                  ) : (
                    <Logo url={logoUrl} square />
                  )}
                </div>
                <div className='dib tr'>
                  {isMember && (
                    <div>
                      <img src={imgCheck} alt='check' className='v-mid mr2' />
                      <span
                        className='ma0 f7 fw4 teal v-mid mr1'
                        style={{ lineHeight: '30px' }}
                      >
                        <FormattedMessage
                          id='CommunityCard.MemberLabel'
                          defaultMessage='Member'
                        />
                      </span>
                      {onLeaveCommunity && (
                        <>
                          <DropDownMenu>
                            <MenuItem onClick={this.toggleLeaveDialog}>
                              <FormattedMessage
                                id='CommunityCard.LeaveLabel'
                                defaultMessage='Leave'
                              />
                            </MenuItem>
                          </DropDownMenu>
                          <Dialog
                            open={isLeaveDialogOpen}
                            onClose={this.toggleLeaveDialog}
                            fullWidth
                          >
                            <DialogTitle>
                              <FormattedMessage
                                id='CommunityCard.Confirmation'
                                defaultMessage='Confirmation'
                              />
                            </DialogTitle>
                            <DialogContent>
                              <Text className='mt3'>
                                <FormattedMessage
                                  id='CommunityCard.ConfirmLeaveCommunity'
                                  defaultMessage='Are you sure you want to leave {name}'
                                  values={{ name }}
                                />
                              </Text>
                            </DialogContent>
                            <DialogActions>
                              <FormattedMessage
                                id='CommunityCard.Leave'
                                defaultMessage='Leave'
                              >
                                {label => (
                                  <Button
                                    autoSize
                                    caution
                                    label={label}
                                    className='mr3'
                                    onClick={() =>
                                      this.handleLeaveCommunity({
                                        communityId,
                                        comPlanMembershipId
                                      })
                                    }
                                  />
                                )}
                              </FormattedMessage>
                              <FormattedMessage id='CancelButton'>
                                {label => (
                                  <Button
                                    autoSize
                                    secondary
                                    label={label}
                                    onClick={this.toggleLeaveDialog}
                                  />
                                )}
                              </FormattedMessage>
                            </DialogActions>
                          </Dialog>
                        </>
                      )}
                    </div>
                  )}
                  {numberBuyers && numberBuyers > 1 && (
                    <div className='flex items-center justify-end f7 fw4 mid-gray mt1'>
                      <span className='dib w1-5 mr1'>
                        <OrgLogo />
                      </span>{' '}
                      {numberBuyers}
                    </div>
                  )}
                </div>
              </div>

              {linkTo ? (
                <Link to={linkTo}>
                  <h5 className={cardStyle.name}>{name}</h5>
                  <span className='f9 fw6'>
                    {supplierSearchable && (
                      <Tooltip
                        title={
                          <FormattedMessage
                            id='CommunityCard.CooperativeDescription'
                            defaultMessage='Suppliers share their profiles with one another'
                          />
                        }
                      >
                        <span>
                          <FormattedMessage
                            id='CommunityCard.Cooperative'
                            defaultMessage='Cooperative'
                          />
                          &nbsp;
                        </span>
                      </Tooltip>
                    )}

                    {isPrivate ? (
                      <FormattedMessage
                        id='CommunityCard.Invitation-Only'
                        defaultMessage='Invitation-Only Community'
                      />
                    ) : (
                      <FormattedMessage
                        id='CommunityCard.OpenToEveryone'
                        defaultMessage='Open to Everyone Community'
                      />
                    )}
                  </span>
                </Link>
              ) : (
                <h5 className={cardStyle.name}>{name}</h5>
              )}

              {description && (
                <Scrollable maxHeight={90}>
                  <Text className={cardStyle.description}>{description}</Text>
                </Scrollable>
              )}
            </div>

            <div style={{ height: childrenHeight || 35 }}>
              <Scrollable maxHeight={childrenHeight}>
                {this.props.children}
                {isMember && hasForm && (
                  <Button
                    onClick={onViewForm}
                    label={
                      hasCompletedForm ? (
                        <FormattedMessage
                          id='CommunityCard.ViewMore'
                          defaultMessage='View Form'
                        />
                      ) : (
                        <FormattedMessage
                          id='CommunityCard.CompleteForm'
                          defaultMessage='Complete Form'
                        />
                      )
                    }
                    autoSize
                  />
                )}
              </Scrollable>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CommunityCard
