import React, { FC, useState, FormEvent } from 'react'
import Card from 'shared/components/Card'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import FileInput from 'shared/components/FileInput'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import LinearProgress from '@material-ui/core/LinearProgress'

type Props = {
  buyerType: string
  isBuyDataAdmin: boolean
  isUploading: boolean
  uploadClientFile: (file: File) => void
}

const FileUpload: FC<Props> = ({
  isBuyDataAdmin,
  isUploading,
  uploadClientFile
}) => {
  const [file, setFile] = useState<File | undefined>(undefined)

  const handleFileChange = (file: File) => {
    setFile(file)
  }
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (file) {
      uploadClientFile(file)
    }
    setFile(undefined)
  }

  return isBuyDataAdmin ? (
    <Card>
      <form onSubmit={handleSubmit}>
        <Label className='db f7 fw6 mb2'>
          <FormattedMessage
            id='FileUpload.FileLabel'
            defaultMessage='Upload a file for Tealbook'
          />
        </Label>
        {!isUploading ? (
          <>
            <FileInput
              accept='.csv, .xlsx, .xls'
              limit={1000}
              onChange={handleFileChange}
              aria-label='file-input'
            />
            <Button
              label={
                <FormattedMessage
                  id='FileUpload.UploadFile'
                  defaultMessage='Upload File'
                />
              }
              type='submit'
              autoSize
              className='mt3'
            />
          </>
        ) : (
          <>
            <LinearProgress />
            <Text>
              <FormattedMessage
                id='FileUpload.InProgress'
                defaultMessage='Upload in progress...'
              />
            </Text>
          </>
        )}
      </form>
    </Card>
  ) : null
}

export default FileUpload
