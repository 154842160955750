import React, { Component, Fragment } from 'react'
import { Field } from 'redux-form/immutable'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import Button from 'shared/components/Button'
import Switch from 'shared/components/Switch'
import ImageFileInput from 'shared/components/ImageFileInput'
import Warning from 'shared/components/Warning'
import formValidators from 'shared/utils/formValidators'
import { FormattedMessage } from 'react-intl'
import { InjectedFormProps } from 'redux-form'

type Props = {
  errorMessage: string
  onAfterSubmit: () => void
  onSubmit: (params: string) => void
} & InjectedFormProps

type State = {
  isCropping: boolean
}

class CreateCommunityForm extends Component<Props, State> {
  state = {
    isCropping: false
  }

  handleSubmit = formValues => {
    const { onSubmit, onAfterSubmit } = this.props

    onSubmit(formValues)
    if (onAfterSubmit) {
      onAfterSubmit()
    }
  }

  startCropping = () => {
    this.setState({
      isCropping: true
    })
  }

  stopCropping = () => {
    this.setState({
      isCropping: false
    })
  }

  render() {
    const { errorMessage, handleSubmit } = this.props
    const { isCropping } = this.state

    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <Label required>
          <FormattedMessage
            id='CreateCommunityForm.Logo'
            defaultMessage='Logo'
          />
        </Label>
        <Field
          component={ImageFileInput}
          required
          name='logo'
          logo
          onOpenCropper={this.startCropping}
          onCrop={this.stopCropping}
          onCancel={this.stopCropping}
        />

        {!isCropping && (
          <Fragment>
            <Label required>
              <FormattedMessage
                id='CreateCommunityForm.CommunityName'
                defaultMessage="Community's Name"
              />
            </Label>
            <Field
              component={Input}
              id='name'
              name='name'
              maxLength={150}
              required
            />

            <Label required>
              <FormattedMessage
                id='CreateCommunityForm.Description'
                defaultMessage='Description'
              />
            </Label>
            <Field
              component={Input}
              id='description'
              maxLength={500}
              name='description'
              required
            />
            <>
              <Label htmlFor='longDescription'>
                <FormattedMessage
                  id='CreateCommunityForm.LongDescription'
                  defaultMessage='Long Description'
                />
              </Label>
              <Field
                component={TextArea}
                id='longDescription'
                name='longDescription'
                maxLength={1000}
              />

              <Label htmlFor='isPrivate'>
                <FormattedMessage
                  id='CreateCommunityForm.Private'
                  defaultMessage='Private'
                />
              </Label>
              <Field component={Switch} id='isPrivate' name='isPrivate' />

              <Label>
                <FormattedMessage
                  id='CreateCommunityForm.FormJson'
                  defaultMessage='Form (must be JSON)'
                />
              </Label>
              <Field
                component={TextArea}
                name='form'
                validate={[formValidators.isJSON]}
              />
            </>

            {errorMessage && <Warning message={errorMessage} />}

            <Button
              type='submit'
              label={
                <FormattedMessage
                  id='CreateCommunityForm.Create'
                  defaultMessage='Create'
                />
              }
              autoSize
              className='mt4'
            />
          </Fragment>
        )}
      </form>
    )
  }
}

export default CreateCommunityForm
