import { Component } from 'react'
import createRoutes from './routes/createRoutes'

class Vet extends Component {
  render() {
    return createRoutes()
  }
}

export default Vet
