import { call, select } from 'redux-saga/effects'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import posts from 'shared/utils/api/posts'

export function* upVoteSaga(action) {
  const postId = action.payload
  const { markRead } = action.meta

  if (markRead) {
    yield call(posts.markPostRead, postId)
  }

  yield call(posts.upVote, postId)
  const userId = yield select(sessionSelectors.getUserId)
  return yield { postId, userId, markRead }
}

export default createApiRequestSaga(upVoteSaga)
