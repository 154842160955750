import fileToUrl from './fileToUrl'

const parseRelationshipAttachment = ({
  expiry,
  document,
  created,
  ...rest
}) => ({
  expirationDate: expiry && new Date(expiry),
  documentFileUrl: fileToUrl(document),
  createdBy: created && created.user,
  createdDate: created && new Date(created.date),
  ...rest
})

export default parseRelationshipAttachment
