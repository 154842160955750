import { call, put } from 'redux-saga/effects'
import { notify } from 'shared/actions'
import partnerConsumer from 'shared/utils/api/partnerConsumer'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* requestEcovadisSupplierRatingSaga(action) {
  const response = yield call(
    partnerConsumer.requestEcovadisSupplierRating,
    action.payload
  )

  if (response.unknownError) {
    yield put(
      notify({
        message: `${response.unknownError.Message} Please try again.`
      })
    )
  }
  return response
}

export default createApiRequestSaga(requestEcovadisSupplierRatingSaga)
