import React, { Component, ReactNode } from 'react'
import classNames from 'classnames'

const styles = {
  fieldset: 'ba b--transparent pa0 ma0',
  legend: 'f6 fw6 pa0 mb2 mid-gray mr3',
  inlineLegend: 'lh-copy fl',
  stackRadioButton: 'db mv2 pv1'
}

type Props = {
  title: string | ReactNode
  inline?: boolean
  stack?: boolean
  className?: string
  required?: boolean
  children: Array<ReactNode>
}

class RadioButtonGroup extends Component<Props> {
  render() {
    const { title, children, inline, stack, className, required } = this.props
    const legendStyles = classNames(
      styles.legend,
      inline && styles.inlineLegend
    )

    return (
      <fieldset className={classNames(styles.fieldset, className)}>
        <legend className={legendStyles}>
          {title}
          {required && <span className='ml1 red'>*</span>}
        </legend>
        {children &&
          children.map((c, index) => (
            <div
              key={index}
              className={stack ? styles.stackRadioButton : 'dib'}
            >
              {c}
            </div>
          ))}
      </fieldset>
    )
  }
}

export default RadioButtonGroup
