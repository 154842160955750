import React from 'react'
import {
  NavLink,
  NavLinkProps,
  useRouteMatch,
  useLocation
} from 'react-router-dom'
import useThemeColors from 'shared/utils/useThemeColors'

type Props = {
  backgroundColor?: string
} & NavLinkProps

const NavTab = (props: Props) => {
  const colors = useThemeColors()
  const match = useRouteMatch()
  const location = useLocation()

  const isActive = props.isActive && props.isActive(match, location)
  const backgroundColor = props.backgroundColor || 'transparent'
  return (
    <NavLink to={`${props.to}`}>
      <div className={`dib ${!isActive ? 'dim' : ''}`}>
        <div
          className={`dib v-mid ph1 bt bl br mid-gray f7 f6-ns tc pt1 b--black-10 br2 br--top ${
            !isActive ? 'bg-near-white bb' : 'fw6'
          }`}
          style={
            isActive
              ? {
                  borderBottom: `1px solid ${backgroundColor}`
                }
              : undefined
          }
        >
          <div
            className={`${isActive ? colors.primaryContained : ''} br1 mb1`}
            style={{ height: 4 }}
          ></div>
          <div className='ph2 pb2'>{props.children}</div>
        </div>
      </div>
    </NavLink>
  )
}

export default NavTab
