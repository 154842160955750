import { withStyles } from '@material-ui/core/styles'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'

export default withStyles({
  root: {
    minWidth: 'auto'
  },
  selected: {
    fontSize: '0.75rem !important'
  }
})(BottomNavigationAction)
