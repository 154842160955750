import React, { ReactElement } from 'react'
import ReportRow from './ReportRow'
import { FormattedMessage, useIntl } from 'react-intl'
import certificationCategories from 'shared/models/CertificationCategories'
import Text from 'shared/components/Text'

type Props = {
  report1Header: string | ReactElement<FormattedMessage>
  report2Header: string | ReactElement<FormattedMessage>
  deltaHeader: string | ReactElement<FormattedMessage>
  report1: { [key: string]: any }
  report2: { [key: string]: any }
  reportsDelta: { [key: string]: any }
  subCategories: string[]
}

const ReportTable = (props: Props) => {
  const intl = useIntl()

  const {
    report1Header,
    report2Header,
    deltaHeader,
    report1,
    report2,
    reportsDelta,
    subCategories
  } = props

  return (
    <div className='pa4 ba b--black-10 bg-white'>
      <ReportRow
        separator
        bold
        before={report1Header}
        after={report2Header}
        shift={deltaHeader}
      />
      <ReportRow
        label={
          <FormattedMessage
            id='CompareReports.DiverseTotalSpend'
            defaultMessage='Diverse Total Spend'
          />
        }
        before={report1.totalDiverseSpend}
        after={report2.totalDiverseSpend}
        shift={reportsDelta.totalDiverseSpend}
      />
      <ReportRow
        label={
          <FormattedMessage
            id='CompareReports.QualifiedSpend'
            defaultMessage='Qualified Spend'
          />
        }
        color='dark-green'
        before={report1.qualifiedSpend}
        after={report2.qualifiedSpend}
        shift={reportsDelta.qualifiedSpend}
      />
      <ReportRow
        label={
          <FormattedMessage
            id='CompareReports.PotentialSpend'
            defaultMessage='Potential Spend'
          />
        }
        color='dark-blue'
        before={report1.potentialSpend}
        after={report2.potentialSpend}
        shift={reportsDelta.potentialSpend}
      />
      <ReportRow
        label={
          <FormattedMessage
            id='CompareReports.DisqualifiedSpend'
            defaultMessage='Disqualified Spend'
          />
        }
        separator
        color='dark-red'
        before={report1.disqualifiedSpend}
        after={report2.disqualifiedSpend}
        shift={reportsDelta.disqualifiedSpend}
      />
      {subCategories.map(subCategory => {
        const subCategoryLabel = certificationCategories.diversity
          .subCategoriesAcronyms[subCategory.toLowerCase()]
          ? intl.formatMessage(
              certificationCategories.diversity.subCategoriesAcronyms[
                subCategory.toLowerCase()
              ]
            )
          : subCategory
        const report1Overview = report1.overview[subCategory]
        const report2Overview = report2.overview[subCategory]
        const deltaOverview = reportsDelta.overview[subCategory]

        return (
          <div key={subCategory}>
            <ReportRow
              label={subCategoryLabel}
              useToggle
              subCategory={subCategory}
              lineItems1={report1.lineItems}
              lineItems2={report2.lineItems}
              before={
                <div className='flex justify-between'>
                  <Text className='dark-green w-third fw6'>
                    {intl.formatNumber(report1Overview?.qualifiedAmount || 0, {
                      style: 'currency',
                      signDisplay: 'auto',
                      currency: 'USD',
                      maximumFractionDigits: 0
                    })}
                  </Text>
                  <Text className='dark-blue w-third fw6'>
                    {intl.formatNumber(report1Overview?.potentialAmount || 0, {
                      style: 'currency',
                      signDisplay: 'auto',
                      currency: 'USD',
                      maximumFractionDigits: 0
                    })}
                  </Text>
                  <Text className='dark-red w-third fw6'>
                    {intl.formatNumber(
                      report1Overview?.disqualifiedAmount || 0,
                      {
                        style: 'currency',
                        signDisplay: 'auto',
                        currency: 'USD',
                        maximumFractionDigits: 0
                      }
                    )}
                  </Text>
                </div>
              }
              after={
                <div className='flex justify-between'>
                  <Text className='dark-green w-third fw6'>
                    {intl.formatNumber(report2Overview?.qualifiedAmount || 0, {
                      style: 'currency',
                      signDisplay: 'auto',
                      currency: 'USD',
                      maximumFractionDigits: 0
                    })}
                  </Text>
                  <Text className='dark-blue w-third fw6'>
                    {intl.formatNumber(report2Overview?.potentialAmount || 0, {
                      style: 'currency',
                      signDisplay: 'auto',
                      currency: 'USD',
                      maximumFractionDigits: 0
                    })}
                  </Text>
                  <Text className='dark-red w-third fw6'>
                    {intl.formatNumber(
                      report2Overview?.disqualifiedAmount || 0,
                      {
                        style: 'currency',
                        signDisplay: 'auto',
                        currency: 'USD',
                        maximumFractionDigits: 0
                      }
                    )}
                  </Text>
                </div>
              }
              shift={
                <div className='flex justify-between'>
                  <Text className='dark-green w-third fw6'>
                    {intl.formatNumber(deltaOverview?.qualifiedAmount || 0, {
                      style: 'currency',
                      signDisplay: 'auto',
                      currencySign: 'accounting',
                      currency: 'USD',
                      maximumFractionDigits: 0
                    })}
                  </Text>
                  <Text className='dark-blue w-third fw6'>
                    {intl.formatNumber(deltaOverview?.potentialAmount || 0, {
                      style: 'currency',
                      signDisplay: 'auto',
                      currencySign: 'accounting',
                      currency: 'USD',
                      maximumFractionDigits: 0
                    })}
                  </Text>
                  <Text className='dark-red w-third fw6'>
                    {intl.formatNumber(deltaOverview?.disqualifiedAmount || 0, {
                      style: 'currency',
                      signDisplay: 'auto',
                      currencySign: 'accounting',
                      currency: 'USD',
                      maximumFractionDigits: 0
                    })}
                  </Text>
                </div>
              }
            />
          </div>
        )
      })}
    </div>
  )
}

export default ReportTable
