import {
  OPEN_REMAP_DIALOG,
  CLOSE_REMAP_DIALOG,
  REMAP_SUPPLIER
} from 'shared/actionTypes'
import { INVITE_SUPPLIER } from 'buyer/Search/actionTypes'
import { requestSuccess, requestFailure } from 'shared/actionTypes'
import { fromJS } from 'immutable'

type LoaderState = {
  isRemapDialogOpen: boolean
  isRemapping: boolean
  isInviting: boolean
  remapError: string
}

const defaultState: LoaderState = {
  isRemapDialogOpen: false,
  isRemapping: false,
  isInviting: false,
  remapError: ''
}

const loaderReducer = (state = fromJS(defaultState), action) => {
  switch (action.type) {
    case OPEN_REMAP_DIALOG:
      return state.merge(
        fromJS({
          isRemapDialogOpen: true,
          remapError: ''
        })
      )
    case CLOSE_REMAP_DIALOG:
      return state.set('isRemapDialogOpen', false)
    case REMAP_SUPPLIER:
      return state.merge(
        fromJS({
          remapError: '',
          isRemapping: true
        })
      )
    case INVITE_SUPPLIER:
      return state.merge(
        fromJS({
          remapError: '',
          isInviting: true
        })
      )
    case requestFailure(REMAP_SUPPLIER):
    case requestFailure(INVITE_SUPPLIER):
      return state.merge(
        fromJS({
          remapError: action.payload,
          isRemapping: false,
          isInviting: false
        })
      )
    case requestSuccess(INVITE_SUPPLIER):
      return state.set('isInviting', false)
    case requestSuccess(REMAP_SUPPLIER):
      return state.set('isRemapDialogOpen', false).set('isRemapping', false)
    default:
      return state
  }
}

export default loaderReducer
