import React, { Component } from 'react'
import OrgLogo from 'shared/components/OrgLogo'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomMenuItem = withStyles({
  gutters: {
    paddingLeft: 8,
    paddingRight: 8
  }
})(ListItem)

const CustomListItemText = withStyles({
  multiline: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 8,
    maxWidth: 300
  }
})(ListItemText)

const CustomListItemIcon = withStyles(theme => ({
  root: {
    minWidth: 16,
    width: 32,
    paddingRight: theme.spacing(1)
  }
}))(ListItemIcon)

type Props = {
  name: string
  logoUrl?: string
  websiteUrl?: string
  onClick: () => void
  id?: string
  orgUnitId?: string
}

class SuggestSupplierItem extends Component<Props> {
  render() {
    const { name, logoUrl, websiteUrl, onClick } = this.props
    return (
      <CustomMenuItem button onClick={onClick}>
        <CustomListItemIcon>
          <OrgLogo url={logoUrl} name={name} />
        </CustomListItemIcon>
        <CustomListItemText
          primary={name}
          primaryTypographyProps={{
            className: 'truncate f7'
          }}
          secondary={websiteUrl}
          secondaryTypographyProps={{
            className: 'truncate f8'
          }}
        />
      </CustomMenuItem>
    )
  }
}

export default SuggestSupplierItem
