import React, { Component, Fragment, FormEvent } from 'react'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Divider from 'shared/components/Divider'
import {
  addVetSupplierByEmail,
  inviteSupplierFromVet,
  resetAddByEmail,
  addVetSupplier
} from '../../actions'
import { ADD_VET_SUPPLIER_BY_EMAIL } from '../../actionTypes'
import { injectIntl, defineMessages, IntlShape } from 'react-intl'
import { Field } from 'redux-form/immutable'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogAction from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import InviteSupplierFormContainer from '../../../Search/containers/InviteSupplierFormContainer'
import OrgUnitPicker from 'shared/components/OrgUnitPicker'
import isEmail from 'validator/lib/isEmail'
import { RecordOf, List } from 'immutable'
import OrgUnit from 'shared/models/OrgUnit'
import { defaultMemoize } from 'reselect'

const messages = defineMessages({
  addByEmail: {
    id: 'AddVetBuyerSupplierByEmail.AddByEmail',
    defaultMessage: 'Add supplier by email'
  },
  enterContactEmail: {
    id: 'AddVetBuyerSupplierByEmail.EnterContactEmail',
    defaultMessage: `Enter contact's email`
  },
  addSupplier: {
    id: 'AddVetBuyerSupplierByEmail.AddRFISupplier',
    defaultMessage: 'Add to RFI'
  },
  matchFound: {
    id: 'AddVetBuyerSupplierByEmail.MatchFound',
    defaultMessage: 'Suppliers Found'
  },
  exactMatchFoundMessage: {
    id: 'AddVetBuyerSupplierByEmail.ExactMatchFoundMessage',
    defaultMessage:
      'We found the following suppliers with user that matches the email.'
  },
  alternativeMatchMessage: {
    id: 'AddVetBuyerSupplierByEmail.pleaseSelectSupplier',
    defaultMessage:
      'Please select the supplier you wish to add or if you don’t see a match add a new supplier.'
  },
  matchFoundMessage: {
    id: 'AddVetBuyerSupplierByEmail.MatchFoundMessage',
    defaultMessage:
      'We also found the following suppliers relate to the given email domain, or you can add a new supplier.'
  },
  notFound: {
    id: 'AddVetBuyerSupplierByEmail.NotFound',
    defaultMessage: 'Supplier Not Found'
  },
  notFoundMessage: {
    id: 'AddVetBuyerSupplierByEmail.NotFoundMessage',
    defaultMessage: 'No supplier found. Would you like to add a new supplier?'
  },
  createSupplier: {
    id: 'AddVetBuyerSupplierByEmail.CreateSupplier',
    defaultMessage: 'Invite Supplier'
  },
  createSupplierButton: {
    id: 'AddVetBuyerSupplierByEmail.CreateSupplierButton',
    defaultMessage: 'Add a new supplier'
  },
  closeButton: {
    id: 'AddVetBuyerSupplierByEmail.Close',
    defaultMessage: 'Close'
  }
})

type Props = {
  vetId: string
  openDialog: boolean
  invalid: boolean
  addByEmailResult: string
  creatingSupplier: string
  supplierIdsInVet: List<string>
  matchingOrgs: List<RecordOf<OrgUnit>>
  exactMatchingOrgs: List<RecordOf<OrgUnit>>
  matchesOrgUsers: RecordOf<{ orgUnitId: string }>
  reset: () => void
  resetAddByEmail: () => void
  inviteSupplierFromVet: () => void
  submitVetSupplierByEmail: (values: string) => void
  handleSubmit: (
    handleSubmit: (values: any) => void
  ) => (event: FormEvent<HTMLFormElement>) => void
  addVetSupplier: (arg: {
    vetId: string
    sendToContact: string
    supplierIds: [string]
    email: string
  }) => void
  intl: IntlShape
}

export class AddVetBuyerSupplierByEmail extends Component<Props> {
  state = {
    email: ''
  }

  handleClickMatchingOrg = orgUnitId => {
    const {
      vetId,
      matchesOrgUsers,
      supplierIdsInVet,
      addVetSupplier,
      resetAddByEmail
    } = this.props

    const { email } = this.state

    if (!supplierIdsInVet || !supplierIdsInVet.includes(orgUnitId)) {
      const userId = matchesOrgUsers && matchesOrgUsers.get(orgUnitId)
      addVetSupplier({
        vetId,
        sendToContact: userId,
        supplierIds: [orgUnitId],
        email
      })
    }
    resetAddByEmail()
  }

  handleClickCreateSupplier = () => {
    const { inviteSupplierFromVet } = this.props
    inviteSupplierFromVet()
  }

  handleSubmit = values => {
    const { reset, submitVetSupplierByEmail } = this.props

    this.setState({ email: values.get('email') })
    submitVetSupplierByEmail(values)
    reset()
  }

  isValidEmail = value => {
    return value && isEmail(value) ? undefined : 'Please enter a valid email!'
  }

  render() {
    const {
      intl,
      handleSubmit,
      openDialog,
      resetAddByEmail,
      addByEmailResult,
      matchingOrgs,
      exactMatchingOrgs,
      supplierIdsInVet,
      vetId,
      creatingSupplier
    } = this.props
    const { email } = this.state

    return (
      <Fragment>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          {supplierIdsInVet && supplierIdsInVet.size > 0 && (
            <Divider className='mt4' />
          )}
          <Label>{intl.formatMessage(messages.addByEmail)}</Label>
          <Field
            component={Input}
            type='email'
            required
            name='email'
            placeholder={intl.formatMessage(messages.enterContactEmail)}
            validate={this.isValidEmail}
          />
          <Button
            disabled={this.props.invalid}
            type='submit'
            className='mt3'
            label={intl.formatMessage(messages.addSupplier)}
          />
        </form>
        <Dialog
          open={openDialog}
          onClose={resetAddByEmail}
          fullWidth
          disableBackdropClick
        >
          <DialogTitle onClose={resetAddByEmail}>
            {addByEmailResult &&
              intl.formatMessage(
                messages[creatingSupplier ? 'createSupplier' : addByEmailResult]
              )}
          </DialogTitle>
          <DialogContent className='mt3'>
            {!creatingSupplier && addByEmailResult === 'matchFound' && (
              <Fragment>
                {exactMatchingOrgs && (
                  <Fragment>
                    <Text className='mv2'>
                      {intl.formatMessage(messages.exactMatchFoundMessage)}
                    </Text>
                    <OrgUnitPicker
                      orgs={exactMatchingOrgs}
                      onClick={this.handleClickMatchingOrg}
                    />
                    {matchingOrgs && matchingOrgs.size > 0 && (
                      <Divider className='mv3' />
                    )}
                  </Fragment>
                )}
                {matchingOrgs && matchingOrgs.size > 0 && (
                  <Fragment>
                    <Text className='mv2'>
                      {intl.formatMessage(
                        exactMatchingOrgs
                          ? messages.alternativeMatchMessage
                          : messages.matchFoundMessage
                      )}
                    </Text>
                    <OrgUnitPicker
                      orgs={matchingOrgs}
                      onClick={this.handleClickMatchingOrg}
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
            {!creatingSupplier && addByEmailResult === 'notFound' && (
              <Text className='mt2'>
                {intl.formatMessage(messages.notFoundMessage)}
              </Text>
            )}
            {creatingSupplier && (
              <InviteSupplierFormContainer
                vetId={vetId}
                contactEmail={email}
                onCancel={resetAddByEmail}
              />
            )}
          </DialogContent>
          {!creatingSupplier && addByEmailResult && (
            <DialogAction>
              <Button
                autoSize
                label={intl.formatMessage(messages.createSupplierButton)}
                onClick={this.handleClickCreateSupplier}
                className='mr3'
              />
              <Button
                autoSize
                secondary
                label={intl.formatMessage(messages.closeButton)}
                onClick={resetAddByEmail}
              />
            </DialogAction>
          )}
        </Dialog>
      </Fragment>
    )
  }
}

const getInitialValues = defaultMemoize(vetId => ({
  vetId
}))

const formName = 'buyer/vet/addByEmail'

export default connectReduxForm(
  state => {
    const vetId = currentVetSelectors.getVetField(state, 'id')
    const addByEmailResult = state.getIn([
      'buyer',
      'currentVet',
      'addByEmail',
      'result'
    ])
    const creatingSupplier = state.getIn([
      'buyer',
      'currentVet',
      'addByEmail',
      'creatingSupplier'
    ])
    const matchingOrgs =
      addByEmailResult === 'matchFound'
        ? state.getIn(['buyer', 'currentVet', 'addByEmail', 'matches'])
        : undefined
    const matchesOrgUsers = state.getIn([
      'buyer',
      'currentVet',
      'addByEmail',
      'matchesOrgUsers'
    ])
    const exactMatchingOrgs = state.getIn([
      'buyer',
      'currentVet',
      'addByEmail',
      'exactMatches'
    ])

    return {
      vetId,
      initialValues: getInitialValues(vetId),
      openDialog: !!addByEmailResult,
      creatingSupplier,
      addByEmailResult,
      matchingOrgs,
      exactMatchingOrgs,
      matchesOrgUsers,
      supplierIdsInVet: currentVetSuppliersSelectors.getSupplierIdsInVet(state)
    }
  },
  {
    addVetSupplier,
    addVetSupplierByEmail,
    inviteSupplierFromVet,
    resetAddByEmail,
    submitVetSupplierByEmail: createSubmitFormAction(
      formName,
      ADD_VET_SUPPLIER_BY_EMAIL
    )
  },
  {
    form: formName
  },
  injectIntl(AddVetBuyerSupplierByEmail)
)
