export const UPLOAD_PROFILE_PICTURE = 'UPLOAD_PROFILE_PICTURE'

export const SAVE_OTHER_COMPANY_INFO = 'SAVE_OTHER_COMPANY_INFO'

export const SAVE_COMPANY_INFO = 'SAVE_COMPANY_INFO'
export const UPDATE_PARENT_ORG = 'supplier/company/UPDATE_PARENT_ORG'
export const START_EDITING_COMPANY_HEADER = 'START_EDITING_COMPANY_HEADER'
export const STOP_EDITING_COMPANY_HEADER = 'STOP_EDITING_COMPANY_HEADER'
export const DELETE_LOGO = 'supplier/company/DELETE_LOGO'
export const DELETE_PITCHDECK = 'supplier/company/DELETE_PITCHDECK'

export const START_COMPANY_EDIT_MODE = 'deprecated/START_COMPANY_EDIT_MODE'
export const START_COMPANY_READ_MODE = 'deprecated/START_COMPANY_READ_MODE'

export const ADD_TAGS = 'suppliers/company/ADD_TAGS'
export const REMOVE_TAGS = 'suppliers/company/REMOVE_TAGS'
export const UPDATE_TAGS = 'suppliers/company/UPDATE_TAGS'

export const ADD_CONTACT = 'supplier/company/ADD_CONTACT'
export const REMOVE_CONTACT = 'supplier/company/REMOVE_CONTACT'
export const EDIT_CONTACT = 'supplier/company/EDIT_CONTACT'
export const UPDATE_CONTACT = 'supplier/company/UPDATE_CONTACT'
export const REMOVE_ALL_CONTACTS = 'supplier/company/REMOVE_ALL_CONTACTS'
export const SELECT_USER_FOR_CONTACT =
  'supplier/company/SELECT_USER_FOR_CONTACT'
export const SELECT_NEW_USER_FOR_CONTACT =
  'supplier/company/SELECT_NEW_USER_FOR_CONTACT'
export const CANCEL_ADDING_CONTACT = 'supplier/company/CANCEL_ADDING_CONTACT'

export const ADD_CERTIFICATION = 'supplier/company/ADD_CERTIFICATION'
export const UPDATE_CERTIFICATION = 'supplier/company/UPDATE_CERTIFICATION'
export const REMOVE_CERTIFICATION = 'supplier/company/REMOVE_CERTIFICATION'

export const ADD_CUSTOMER = 'supplier/company/ADD_CUSTOMER'
export const REMOVE_CUSTOMER = 'supplier/company/REMOVE_CUSTOMER'
export const GET_VERIFIED = 'supplier/company/GET_VERIFIED'
export const OPEN_NEW_CUSTOMER_DIALOG =
  'supplier/company/OPEN_NEW_CUSTOMER_DIALOG'
export const CLOSE_NEW_CUSTOMER_DIALOG =
  'supplier/company/CLOSE_NEW_CUSTOMER_DIALOG'
export const OPEN_GET_VERIFIED_DIALOG =
  'supplier/company/OPEN_GET_VERIFIED_DIALOG'
export const CLOSE_GET_VERIFIED_DIALOG =
  'supplier/company/CLOSE_GET_VERIFIED_DIALOG'

export const REMOVE_BACKGROUND_PICTURE =
  'supplier/company/REMOVE_BACKGROUND_PICTURE'

export const UPLOAD_FILE = 'supplier/company/UPLOAD_FILE'

export const ADD_DOMAIN = 'supplier/company/ADD_DOMAIN'
export const ADD_SUGGESTED_TAGS = 'supplier/company/ADD_SUGGESTED_TAGS'

export const ADD_CLASSIFICATION_CODE =
  'supplier/company/ADD_CLASSIFICATION_CODE'
export const REMOVE_CLASSIFICATION_CODE =
  'supplier/company/REMOVE_CLASSIFICATION_CODE'

export const CLOSE_CONGRATS_SURVEY_DIALOG =
  'supplier/company/CLOSE_CONGRATS_SURVEY_DIALOG'
