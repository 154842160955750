const parser = (userInfoString = '') => {
  const cleanupUserInfo = userInfoString.replace(/(\t| +|,|;)/gi, ' ')
  const userInfo = cleanupUserInfo.split(' ').filter(info => !!info)
  const emails = userInfo.filter(info => info.includes('@'))
  const email = emails.length > 0 ? emails[0] : undefined
  const names = userInfo.filter(info => !info.includes('@'))

  const firstName = names.length > 0 ? names[0] : undefined
  const lastName =
    names.length > 1 ? names.slice(1, names.length).join(' ') : undefined

  return {
    firstName,
    lastName,
    email
  }
}

export default parser
