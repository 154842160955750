import { fromJS, List, Map, RecordOf } from 'immutable'
import { requestSuccess, requestFailure } from 'shared/actionTypes'
import * as actionTypes from '../../actionTypes'

export type BodOutput = Map<
  string,
  RecordOf<{
    'Possible columns': List<string>
    'Proposed Column Map': string
  }>
>

export type BodResponse = RecordOf<{
  output: BodOutput
  status: string
  test: string
}>

type DataQuality = RecordOf<{
  loading: boolean
  uploading: boolean
  submittingColumnsMapping: boolean
  bdoResponse: BodResponse
  jobs: List<any>
  jobStatus: any
}>

const defaultState: DataQuality = fromJS({
  loading: false,
  uploading: false,
  submittingColumnsMapping: false,
  bdoResponse: {},
  jobs: [],
  jobStatus: {}
})

const dataQualityReducer = (state = defaultState, action) => {
  let job, jobIndex
  switch (action.type) {
    case actionTypes.UPLOAD_DATA_QUALITY_FILE:
      return state.set('uploading', true).set('bdoResponse', fromJS({}))

    case requestSuccess(actionTypes.UPLOAD_DATA_QUALITY_FILE):
      return state
        .set('uploading', false)
        .set('bdoResponse', fromJS(action.payload))

    case requestFailure(actionTypes.UPLOAD_DATA_QUALITY_FILE):
      return state.set('uploading', false).set('bdoResponse', fromJS({}))

    case actionTypes.GET_DATA_QUALITY_JOBS:
      return state.set('loading', true)

    case requestSuccess(actionTypes.GET_DATA_QUALITY_JOBS):
      return state.set('loading', false).set('jobs', fromJS(action.payload))

    case requestFailure(actionTypes.GET_DATA_QUALITY_JOBS):
      return state.set('loading', false).set('jobs', fromJS([]))

    case actionTypes.CONFIRM_COLUMNS_MAPPING:
      return state.set('submittingColumnsMapping', true)

    case requestFailure(actionTypes.CONFIRM_COLUMNS_MAPPING):
      return state.set('submittingColumnsMapping', false)

    case requestSuccess(actionTypes.CONFIRM_COLUMNS_MAPPING):
      jobIndex = state
        .get('jobs')
        .findIndex(job => job.get('id') === action.payload.jobId)

      return jobIndex !== -1
        ? state
            .set('submittingColumnsMapping', false)
            .set('bdoResponse', fromJS({}))
            .updateIn(['jobs', jobIndex, 'status'], status => 'analyzing')
        : state
            .set('submittingColumnsMapping', false)
            .set('bdoResponse', fromJS({}))

    case requestSuccess(actionTypes.GET_JOB_STATUS):
      jobIndex = state
        .get('jobs')
        .findIndex(job => job.get('id') === action.payload.jobId)
      job = jobIndex !== -1 && state.getIn(['jobs', jobIndex])

      return job?.get('status') !== action.payload.jobStatus.status
        ? state
            .set('jobStatus', fromJS(action.payload.jobStatus))
            .updateIn(
              ['jobs', jobIndex, 'status'],
              status => action.payload.jobStatus.status
            )
            .updateIn(['jobs', jobIndex, 'attachments'], attachments =>
              fromJS(action.payload.jobStatus.attachments)
            )
        : state.set('jobStatus', fromJS(action.payload.jobStatus))

    case actionTypes.CLEAR_JOB_STATUS:
      return state.set('jobStatus', fromJS({}))

    default:
      return state
  }
}

export default dataQualityReducer
