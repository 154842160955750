import { call, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'
import { notify } from 'shared/actions'

function* chooseVetSupplierSaga(action) {
  // { vetBuyerSupplierId, notes, nextSteps } = action.payload
  const { vetBuyerSupplierId } = action.payload.toJS()
  const collaboration = yield call(
    vets.chooseVetBuyerSupplier,
    action.payload.toJS()
  )
  yield put(
    notify({
      message: 'Your message has been sent.'
    })
  )
  return yield { vetBuyerSupplierId, collaboration }
}

export default createApiRequestSaga(chooseVetSupplierSaga)
