import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, List, RecordOf, fromJS } from 'immutable'
import usersSelectors from 'shared/selectors/usersSelectors'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import Avatar from 'shared/components/Avatar'
import Text from 'shared/components/Text'
import Popover from '@material-ui/core/Popover'
import Link from 'shared/components/Link'
import paths from 'buyer/routes/paths'
import UserStack from 'shared/components/UserStack'
import { FormattedMessage } from 'react-intl'
import User from 'shared/models/User'

const styles = {
  wrapper: 'v-mid pl4 pointer bn bg-transparent',
  noPaddingWrapper: 'v-mid pointer bn bg-transparent'
}
type Cards = List<
  RecordOf<{
    attributes?: string
    connected?: boolean
    contacts?: List<
      RecordOf<{
        connectFor?: string
        connected: boolean
        date: string
        isPrivate: boolean
        source: string
        user: string
      }>
    >
    differentiators?: Map<string, string>
    id: string
    offerings?: Map<string, string>
    owner: string
    supplier: string
  }>
>

type Props = {
  supplierId: string
  userId: string
  contactConnection: boolean
  noPadding: boolean
  rightAlign: boolean
  mini: boolean
  connections: List<string>
  cacheUsers: Map<string, RecordOf<User>>
  cards: Cards
}

export class ConnectionsStackContainer extends Component<Props> {
  state = {
    anchorEl: undefined,
    showPopover: false
  }

  generateTooltipContent() {
    const { cacheUsers, userId } = this.props
    const cards = filterCardsByUserId(this.props.cards, this.props.userId)
    const connections =
      this.props.connections || (cards && cards.keySeq().toList())

    return connections.map(c => {
      const cacheUsersResults =
        cacheUsers.get(c) || (fromJS({}) as RecordOf<User>)
      return cacheUsers.has(c) ? (
        <div key={c} className='w-auto mb1'>
          {/* {cacheUsersResults = cacheUsers.get(c)} */}
          <Link
            to={paths.userprofile}
            params={{ userId: cacheUsersResults.get('id') }}
          >
            <Avatar
              url={cacheUsersResults.get('profilePictureUrl')}
              className='w1-5 ba b--white bw1 mr1 v-mid dib'
              name={`${cacheUsersResults.get('firstName') ||
                ''} ${cacheUsersResults.get('lastName') || ''}`}
              tabIndex={-1}
            />
            <div className='v-mid dib'>
              <Text className='hover-teal'>
                {cacheUsersResults.get('firstName')}{' '}
                {cacheUsersResults.get('lastName')}
              </Text>
              {cards && (
                <Text secondary>
                  {cards.getIn([c, 'contacts', userId, 'connectFor'])}
                </Text>
              )}
            </div>
          </Link>
        </div>
      ) : null
    })
  }

  handleClick = e => {
    e.preventDefault()
    this.setState({
      anchorEl: e.currentTarget,
      showPopover: true
    })
  }

  handleRequestClose = () => {
    this.setState({
      showPopover: false
    })
  }

  render() {
    const { noPadding, mini, rightAlign } = this.props
    const { showPopover, anchorEl } = this.state

    const cards = filterCardsByUserId(this.props.cards, this.props.userId)
    const connections =
      this.props.connections || (cards && cards.keySeq().toList())

    if (!connections || connections.size === 0) return null

    return (
      <div className='dib w-100'>
        <button
          className={noPadding ? styles.noPaddingWrapper : styles.wrapper}
          onClick={this.handleClick}
          aria-label={`${connections.size} connections`}
        >
          {cards ? (
            <Text className='f6 dib hover-teal'>
              <FormattedMessage
                id='ConnectionsStackContainer.Connections'
                defaultMessage='{connectionsSizeValue} connections'
                values={{ connectionsSizeValue: connections.size }}
              />
            </Text>
          ) : (
            <UserStack users={connections} mini={mini} />
          )}
        </button>
        <Popover
          open={showPopover}
          onClose={this.handleRequestClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: rightAlign ? 'right' : 'left',
            vertical: 'bottom'
          }}
          transformOrigin={{
            horizontal: rightAlign ? 'right' : 'left',
            vertical: 'top'
          }}
        >
          <div className='dib pv1 ph2 lh-copy f7 fw4 mid-gray' role='listbox'>
            {this.generateTooltipContent()}
          </div>
        </Popover>
      </div>
    )
  }
}

function filterCardsByUserId(cards, userId) {
  return (
    cards &&
    cards.filter(card => {
      return card.getIn(['contacts', userId, 'connected'])
    })
  )
}

export const mapStateToProps = (state, props) => {
  const { contactConnection, supplierId } = props
  let connections
  let cards
  if (contactConnection) {
    cards = cardsSelectors.getAllCardsBySupplier(state, supplierId)
  } else {
    connections = cardsSelectors.getConnectedCardIdsBySupplier(
      state,
      supplierId
    )
  }

  return {
    cacheUsers: usersSelectors.getUsers(state),
    connections,
    cards
  }
}

export default connect(mapStateToProps)(ConnectionsStackContainer)
