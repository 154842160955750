import React, { Component } from 'react'
import { fromJS, RecordOf } from 'immutable'
import { connect } from 'react-redux'
import editModeForm from 'shared/utils/editModeForm'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import { SUBMIT_SUPPLIER_REVIEW } from '../../actionTypes'
import { closeSupplierReviewDialog } from '../../actions'
import { getFormValues } from 'redux-form/immutable'
import searchSelectors from '../../selectors/searchSelectors'
import WriteReviewForm from '../../../SupplierProfile/components/WriteReviewForm'
import RootState from 'shared/models/RootState'

const today = new Date()

type Props = {
  formValueIsPreferred: boolean
  reviewOptions: RecordOf<{ preferred: boolean; rating: boolean }>
  closeSupplierReviewDialog: () => void
  onSubmit: () => void
}
export class ReviewDialogContainer extends Component<Props> {
  render() {
    const {
      formValueIsPreferred,
      reviewOptions,
      closeSupplierReviewDialog,
      onSubmit,
      ...rest
    } = this.props

    return (
      <WriteReviewForm
        isPreferred={formValueIsPreferred}
        enablePreferred={reviewOptions.get('preferred')}
        enableRatings={reviewOptions.get('rating')}
        onClickCancelWritingReview={closeSupplierReviewDialog}
        onSubmit={onSubmit}
        {...rest}
      />
    )
  }
}

type FormData = RecordOf<{
  ratingResponses: RecordOf<{
    preferred: boolean
  }>
}>

const formName = 'buyer/search/reviewsForm'
const formValueSelector = getFormValues(formName)

const ReviewsDialogContainerEditForm = editModeForm({
  formName,
  submitActionType: SUBMIT_SUPPLIER_REVIEW,
  stopEditModeOnSave: true
})(ReviewDialogContainer)

export default connect(
  (state: RootState) => {
    const ratingResponses =
      searchSelectors.getReviewCard(state).get('ratingResponses') || fromJS({})

    const formValues = formValueSelector(state) as FormData

    return {
      hasReviewedBefore: !!ratingResponses.get('date'),
      reviewOptions: settingsSelectors.getReviewOptions(state),
      formValueIsPreferred:
        formValues &&
        formValues.getIn(['ratingResponses', 'preferred']) === 'preferred',
      initialValues: {
        ratingResponses: {
          date: today,
          comment: ratingResponses.get('comment'),
          responses: ratingResponses.get('responses') || fromJS({}),
          preferred: ratingResponses.get('preferred'),
          preferredCategories: ratingResponses.get('preferredCategories')
        }
      }
    }
  },
  { closeSupplierReviewDialog }
)(ReviewsDialogContainerEditForm)
