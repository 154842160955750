import React, {
  ChangeEvent,
  ReactNode,
  ReactElement,
  useState,
  useEffect
} from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { saveSurveyResponse } from '../../actions'
import RootState from 'shared/models/RootState'
import surveySelectors from '../../selectors/surveySelectors'
import Text from 'shared/components/Text'
import TextQuestion from '../TextQuestion'

const messages = defineMessages({
  reasonPlaceholder: {
    id: 'ParentQuestion.ReasonPlaceholder',
    defaultMessage: 'Please provide reason'
  },
  true: {
    id: 'ParentQuestion.True',
    defaultMessage: 'True'
  },
  false: {
    id: 'ParentQuestion.False',
    defaultMessage: 'False'
  },
  yes: {
    id: 'ParentQuestion.Yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'ParentQuestion.No',
    defaultMessage: 'No'
  },
  preferNotToAnswer: {
    id: 'ParentQuestion.PreferNotToAnswer',
    defaultMessage: 'Prefer Not To Answer'
  },
  preferNotToAnswerOption1: {
    id: 'ParentQuestion.Option1',
    defaultMessage:
      'We do not do this at this time and we do not plan to do this anytime soon.'
  },
  preferNotToAnswerOption2: {
    id: 'ParentQuestion.Option2',
    defaultMessage:
      'We do not do this at this time but we plan to start doing this within a year.'
  },
  preferNotToAnswerOption3: {
    id: 'ParentQuestion.Option3',
    defaultMessage: 'This question is not relevant to my company.'
  },
  preferNotToAnswerOption4: {
    id: 'ParentQuestion.Option4',
    defaultMessage: 'This question is not relevant to my industry.'
  },
  preferNotToAnswerOther: {
    id: 'ParentQuestion.Other',
    defaultMessage: 'Other'
  }
})

const noOptions = [
  'We do not do this at this time and we do not plan to do this anytime soon.',
  'We do not do this at this time but we plan to start doing this within a year.'
]
const preferNotToAnswerOptions = [
  'This question is not relevant to my company.',
  'This question is not relevant to my industry.'
]

type Props = {
  pageId: string
  parentQuestionId?: string
  questionId: string
  answerType?: 'text' | 'list'
  question: string | ReactElement<FormattedMessage>
  additionalInfo?: ReactNode
  hideAdditionalOnResponse?: boolean
  disableNoSubQuestion?: boolean
  useTrueFalse?: boolean
  childrenOnFalse?: boolean
  children?: ReactNode
}

const ParentQuestion = (props: Props) => {
  const {
    pageId,
    parentQuestionId,
    questionId,
    question,
    additionalInfo,
    answerType = 'text',
    disableNoSubQuestion,
    children,
    useTrueFalse,
    childrenOnFalse,
    hideAdditionalOnResponse
  } = props

  const dispatch = useDispatch()
  const intl = useIntl()
  const surveyId = useSelector(surveySelectors.getEsgSurveyId)
  const response = useSelector((state: RootState) => {
    return surveySelectors.getResponseDetailByIds(
      state,
      pageId,
      questionId,
      parentQuestionId
    )
  })

  const [reasonState, setReasonState] = useState<string>('')

  useEffect(() => {
    if (response?.get('reason')) {
      if (
        (response?.get('answer') === 'preferNotToAnswer' &&
          preferNotToAnswerOptions.includes(response.get('reason'))) ||
        (response?.get('answer') === 'no' &&
          noOptions.includes(response.get('reason')))
      ) {
        setReasonState(response.get('reason'))
      } else {
        setReasonState('Other')
      }
    }
  }, [response])

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    let answer = e.currentTarget.value
    setReasonState('')
    dispatch(
      saveSurveyResponse({
        surveyId,
        surveyResponseDetailId: response?.get('id'),
        pageId,
        questionId,
        parentQuestionId,
        answer,
        answerType,
        reason: ''
      })
    )
  }

  const handleReasonAnswerChange = (e: ChangeEvent<HTMLInputElement>) => {
    let reason = e.currentTarget.value
    setReasonState(reason)
    if (reason === 'Other') {
      dispatch(
        saveSurveyResponse({
          surveyId,
          surveyResponseDetailId: response?.get('id'),
          pageId,
          questionId,
          parentQuestionId,
          reason: ''
        })
      )
    } else {
      dispatch(
        saveSurveyResponse({
          surveyId,
          surveyResponseDetailId: response?.get('id'),
          pageId,
          questionId,
          parentQuestionId,
          reason
        })
      )
    }
  }

  return (
    <section className='mb3'>
      <div className='flex justify-between items-start'>
        <div className='mv2 pt1 w-two-thirds'>
          <Text className='fw6'>{question}</Text>
          {(!hideAdditionalOnResponse ||
            (additionalInfo && !response?.get('answer'))) && (
            <div>{additionalInfo}</div>
          )}
        </div>
        <div className='flex items-center justify-end w-third'>
          <RadioGroup
            color='primary'
            aria-label='answer'
            name='answer'
            value={response?.get('answer') || ''}
            onChange={handleRadioChange}
            row
          >
            {useTrueFalse && (
              <FormControlLabel
                control={<Radio color='primary' size='small' value={'true'} />}
                label={intl.formatMessage(messages.true)}
              />
            )}
            {useTrueFalse && (
              <FormControlLabel
                control={<Radio color='primary' size='small' value={'false'} />}
                label={intl.formatMessage(messages.false)}
              />
            )}
            {!useTrueFalse && (
              <FormControlLabel
                control={<Radio color='primary' size='small' value={'yes'} />}
                label={intl.formatMessage(messages.yes)}
              />
            )}
            {!useTrueFalse && (
              <FormControlLabel
                control={<Radio color='primary' size='small' value={'no'} />}
                label={intl.formatMessage(messages.no)}
              />
            )}
            {!useTrueFalse && (
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    size='small'
                    value={'preferNotToAnswer'}
                  />
                }
                label={intl.formatMessage(messages.preferNotToAnswer)}
              />
            )}
          </RadioGroup>
        </div>
      </div>

      {(response?.get('answer') === 'preferNotToAnswer' ||
        (response?.get('answer') === 'no' && !disableNoSubQuestion)) && (
        <>
          <RadioGroup
            color='primary'
            aria-label='reason'
            name='reason'
            value={reasonState}
            onChange={handleReasonAnswerChange}
          >
            {response?.get('answer') === 'no' && (
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    size='small'
                    value={messages.preferNotToAnswerOption1.defaultMessage}
                  />
                }
                label={intl.formatMessage(messages.preferNotToAnswerOption1)}
              />
            )}
            {response?.get('answer') === 'no' && (
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    size='small'
                    value={messages.preferNotToAnswerOption2.defaultMessage}
                  />
                }
                label={intl.formatMessage(messages.preferNotToAnswerOption2)}
              />
            )}
            {response?.get('answer') === 'preferNotToAnswer' && (
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    size='small'
                    value={messages.preferNotToAnswerOption3.defaultMessage}
                  />
                }
                label={intl.formatMessage(messages.preferNotToAnswerOption3)}
              />
            )}
            {response?.get('answer') === 'preferNotToAnswer' && (
              <FormControlLabel
                control={
                  <Radio
                    color='primary'
                    size='small'
                    value={messages.preferNotToAnswerOption4.defaultMessage}
                  />
                }
                label={intl.formatMessage(messages.preferNotToAnswerOption4)}
              />
            )}
            <FormControlLabel
              control={
                <Radio
                  color='primary'
                  size='small'
                  value={messages.preferNotToAnswerOther.defaultMessage}
                />
              }
              label={intl.formatMessage(messages.preferNotToAnswerOther)}
            />
          </RadioGroup>
          {reasonState === 'Other' && (
            <div className='w-50-ns'>
              <TextQuestion
                pageId={pageId}
                questionId={questionId}
                parentQuestionId={parentQuestionId}
                placeholder={intl.formatMessage(messages.reasonPlaceholder)}
                fieldType='textarea'
                fieldName='reason'
              />
            </div>
          )}
        </>
      )}
      {((!childrenOnFalse &&
        (response?.get('answer') === 'yes' ||
          response?.get('answer') === 'true')) ||
        (childrenOnFalse && response?.get('answer') === 'false')) && (
        <div className='ml3'>{children}</div>
      )}
    </section>
  )
}

export default ParentQuestion
