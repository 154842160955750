import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call } from 'redux-saga/effects'
import messageTopics from '../../utils/api/messageTopics'

export function* readMessageSaga(action) {
  const topicId = action.payload

  yield call(messageTopics.readMessages, topicId)

  return yield {
    topicId
  }
}

export default createApiRequestSaga(readMessageSaga)
