import React, { FormEvent, FunctionComponent, useState } from 'react'
import DialogContactUs from '../../components/DialogContactUs'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { getFormSubmitErrors } from 'shared/reducers'
import { SubmissionError } from 'redux-form/immutable'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { RecordOf } from 'immutable'
import { createSelector } from 'reselect'

type Props = {
  open: boolean
  errorMessage: any
  error: string
  reset: () => void
  onRequestClose: () => void
  additionalInitialValues: any
  initialize: (initialValues: any) => void
  initialValues: RecordOf<{
    sendTo: string
    name: string
    email: string
    phoneNumber: string
  }>
  sendMessage: (values: any) => void
  handleSubmit: (
    saveInformation: (values: any) => void
  ) => (event: FormEvent<HTMLFormElement>) => void
}
export const DialogContactUsContainer: FunctionComponent<Props> = props => {
  const [successMessage, setsuccessMessage] = useState(false)

  const saveInformation = values => {
    const { sendMessage, reset } = props
    if (!values.get('email') || !values.get('message')) {
      throw new SubmissionError({ _error: 'Required field(s) missing' })
    }
    reset()
    sendMessage(values)

    setsuccessMessage(true)
  }

  const handleRequestClose = () => {
    const { onRequestClose } = props
    setsuccessMessage(false)
    onRequestClose()
  }

  const { handleSubmit, errorMessage, error, initialValues, open } = props

  return (
    <DialogContactUs
      initialValues={initialValues}
      onSubmit={handleSubmit(saveInformation)}
      error={error}
      open={open}
      successMessageState={successMessage}
      onRequestClose={handleRequestClose}
      errorMessage={errorMessage}
    >
      {props.children}
    </DialogContactUs>
  )
}

const formName = 'shared/contactMessage'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)
const getInitalValues = createSelector(
  sessionSelectors.getUser,
  (state, additionalInitialValues) => additionalInitialValues,
  (user: any, additionalInitialValues) => ({
    sendTo: 'support',
    name: `${user.get('firstName') || ''} ${user.get('lastName') || ''}`,
    email: user.get('email') || '',
    phoneNumber: user.get('mobilePhone') || user.get('officePhone') || '',
    ...additionalInitialValues
  })
)

export const mapStateToProp = (state, props) => {
  return {
    errorMessage: formSubmitErrorsSelector(state),
    initialValues: getInitalValues(state, props.additionalInitialValues)
  }
}

export default connectReduxForm(
  mapStateToProp,
  {
    sendMessage: createSubmitFormAction(formName, 'SEND_CONTACT_US_MESSAGE')
  },
  {
    form: formName,
    enableReinitialize: true
  },
  DialogContactUsContainer
)
