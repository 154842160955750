import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'

export function* updateVetTeamSaga(action) {
  const { vetId, user1, user2 } = action.payload

  if (user1.user) {
    yield call(vets.updateTeamMember, { vetId, ...user1 })
  }
  if (user2.user) {
    yield call(vets.updateTeamMember, { vetId, ...user2 })
  }
  return yield action.payload
}

export default createApiRequestSaga(updateVetTeamSaga)
