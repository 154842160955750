import { connect } from 'react-redux'
import searchSelectors from '../../selectors/searchSelectors'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import FilterHOC from '../FilterHOC'
import FilterHierarchy from '../../components/FilterHierarchy'
import RootState from 'shared/models/RootState'
import naicsMessages from 'shared/models/Naics'

type Props = {
  showMatchAny: boolean
  category?: string
}

export default connect(
  (state: RootState, props: Props) => {
    const firstLevelKey = 'sector'
    const secondLevelKey = 'subsector'
    const thirdLevelKey = 'group'

    const selectedFirstLevel = filterOptionsSelectors.getFilterOption(
      state,
      firstLevelKey
    )
    const selectedSecondLevel = filterOptionsSelectors.getFilterOption(
      state,
      secondLevelKey
    )
    const selectedThirdLevel = filterOptionsSelectors.getFilterOption(
      state,
      thirdLevelKey
    )
    const initialConditions = filterOptionsSelectors.getFilterOptionLevels(
      state,
      firstLevelKey,
      secondLevelKey,
      thirdLevelKey
    )

    return {
      firstLevelKey,
      secondLevelKey,
      thirdLevelKey,
      selectedFirstLevel,
      selectedSecondLevel,
      selectedThirdLevel,
      initialConditions,
      isFilteredFirstLevel: selectedFirstLevel.size > 0,
      isFilteredSecondLevel: selectedSecondLevel.size > 0,
      isFilteredThirdLevel: selectedThirdLevel.size > 0,
      firstLevelOptions: searchSelectors.getNaicsAggregationsByKey(
        state,
        firstLevelKey
      ),
      secondLevelOptions: searchSelectors.getNaicsAggregationsByKey(
        state,
        secondLevelKey
      ),
      thirdLevelOptions: searchSelectors.getNaicsAggregationsByKey(
        state,
        thirdLevelKey
      ),
      translatedMessages: naicsMessages,
      showValue: true
    }
  },
  {
    ...FilterHOC
  }
)(FilterHierarchy)
