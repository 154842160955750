import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Column } from 'react-virtualized'
import Table from 'shared/components/Table'
import InsightTitle from 'shared/components/InsightTitle'
import Button from 'shared/components/Button'
import exportData from 'shared/utils/exportData'
import { List } from 'immutable'
import { RelationshipSupplierReport } from './InternalStats'

type Props = {
  stat: string
  value: List<RelationshipSupplierReport>
  orgName: string
}

const RelationshipSuppliersTable = ({ stat, value, orgName }: Props) => {
  return (
    <div key={stat}>
      <div className='flex justify-between items-center mt4'>
        <InsightTitle>
          <FormattedMessage
            id='InternalStats.suppliersWithRelationshipToClient'
            defaultMessage='Suppliers With Relationship To {orgName}'
            values={{ orgName }}
          />
        </InsightTitle>
        <Button
          onClick={() => {
            exportData.exportCSV(
              value.toJS(),
              'Suppliers With Relationship To ' + orgName,
              true
            )
          }}
        >
          <FormattedMessage id='InternalStats.Export' defaultMessage='Export' />
        </Button>
      </div>
      <div className='bg-white br1 ba b--black-10'>
        <Table
          rowGetter={({ index }) => value && !!value.size && value.get(index)}
          rowCount={value.size}
        >
          <Column
            label={
              <FormattedMessage
                id='InternalStats.internalSupId'
                defaultMessage='Internal Supplier Id'
              />
            }
            width={170}
            dataKey='internalSupId'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.domains'
                defaultMessage='Domains'
              />
            }
            width={300}
            dataKey='domains'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.name'
                defaultMessage='Company Name'
              />
            }
            width={300}
            dataKey='name'
          />
          <Column
            label={
              <FormattedMessage
                id='InternalStats.orgUnitId'
                defaultMessage='Orgunit ID'
              />
            }
            width={350}
            dataKey='OrgUnitID'
          />
        </Table>
      </div>
    </div>
  )
}

export default RelationshipSuppliersTable
