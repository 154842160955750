import React from 'react'
import CertificationForm from '../CertificationForm'
import AttachmentCard from 'shared/components/AttachmentCard'
import Divider from 'shared/components/Divider'
import { ReactComponent as WarningIcon } from 'shared/assets/icons/warning.svg'
import Tooltip from 'shared/components/Tooltip'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import { List, Map, RecordOf } from 'immutable'
import { CategoryType } from 'shared/models/CertificationCategories'
import Location from 'shared/models/Location'
import { InjectedFormProps } from 'redux-form'
import CertAttributes from 'shared/components/CertAttributes'
import {
  Company,
  Info,
  CertNaics
} from 'buyer/Insights/store/diversityReportReducer'
import parseLocationToString from 'shared/utils/data/parseLocationToString'

type Props = {
  // certification props
  isTealbot: boolean
  category: CategoryType
  subCategory: string
  certificateUrl: string
  certExpiration: Date
  sourceURL: string
  certAgency: string
  certificateNumber: string
  supplierCertified: string
  lastModifiedTimeStamp: string
  subTypes?: List<string>
  certifyingLocation?: RecordOf<Location>
  certNaics?: List<RecordOf<CertNaics>>
  certifyingLocationKey?: string
  qualifiedNaics?: Map<string, boolean>
  primaryNaics?: string
  supplierName?: string
  company?: RecordOf<Company>
  info?: RecordOf<Info>
  confirmed: boolean
  editMode: boolean
  // change: (field: String, value: any) => void

  // actions
  onRemove?: () => void
  onRenew?: () => void
  onEdit: () => void
  onSubmit: () => void
  onCancel: () => void
} & InjectedFormProps

const CertificationEditForm = (props: Props) => {
  const {
    isTealbot,
    category,
    subCategory,
    certificateUrl,
    certExpiration,
    sourceURL,
    certAgency,
    certificateNumber,
    supplierCertified,
    lastModifiedTimeStamp,
    onRenew,
    onRemove,
    editMode,
    onSubmit,
    onEdit,
    onCancel,
    subTypes,
    certifyingLocation,
    certNaics,
    certifyingLocationKey,
    qualifiedNaics,
    primaryNaics,
    supplierName,
    company,
    info,
    confirmed,
    ...rest
  } = props

  return (
    <>
      {!editMode && (
        <>
          <AttachmentCard
            category={category}
            subCategory={subCategory}
            subTypes={subTypes}
            status={
              confirmed
                ? 'confirmed'
                : info?.get('potential')
                ? 'potential'
                : undefined
            }
            alertCodes={info?.get('alertCodes')}
            expirationDate={certExpiration}
            attachmentUrl={certificateUrl}
            sourceUrl={sourceURL}
            certAgency={certAgency}
            certificateNumber={certificateNumber}
            onRemove={onRemove}
            onRenew={onRenew}
            onEdit={onEdit}
            lastModifiedTimeStamp={lastModifiedTimeStamp}
            additionalAttributes={
              !supplierCertified && (
                <Tooltip
                  title={
                    <FormattedMessage
                      id='CertificationEditForm.NotYetVerified'
                      defaultMessage='Not yet verified by your company.'
                    />
                  }
                >
                  <WarningIcon
                    className='w1 ml2 v-mid'
                    style={{ height: 18 }}
                  />
                </Tooltip>
              )
            }
            supplierName={supplierName}
          >
            {certifyingLocation && (
              <>
                <Text>
                  <FormattedMessage
                    id='CertifyingCountry'
                    defaultMessage='Certifying Country'
                  />
                  : {certifyingLocation.getIn(['components', 'country'])}
                </Text>
              </>
            )}
            {/* extra code until patch is ran: company?.getIn(['locations', 0]) || company?.get('address') */}
            <CertAttributes
              certName={company?.get('name')}
              certAddress={
                parseLocationToString(company?.get('location')) ||
                parseLocationToString(company?.getIn(['locations', 0])) ||
                company?.get('address')
              }
              certDuns={company?.get('duns')}
              certParentDuns={company?.get('parentDuns')}
              potential={info?.get('potential')}
              alertCodes={info?.get('alertCodes')}
              infoCodes={info?.get('infoCodes')}
              validationCodes={info?.get('validationCodes')}
              websiteUrl={company?.get('websiteUrl')}
              contact={company?.get('contact')}
              naicsCodes={certNaics}
            />
          </AttachmentCard>
        </>
      )}

      {editMode && (
        <div className='bg-near-white ph3-5 pb3'>
          <CertificationForm
            editMode={editMode}
            isTealbot={isTealbot}
            category={category}
            subCategory={subCategory}
            certAgency={certAgency}
            certNaics={certNaics}
            certifyingLocation={certifyingLocation}
            certifyingLocationKey={certifyingLocationKey}
            qualifiedNaics={qualifiedNaics}
            primaryNaics={primaryNaics}
            onCancel={onCancel}
            onSubmit={onSubmit}
            disableCategories
            subTypes={subTypes}
            {...rest}
          />
        </div>
      )}
      <Divider className='mt3' />
    </>
  )
}

export default CertificationEditForm
