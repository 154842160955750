import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'
import Task from 'shared/models/Task'
import { List } from 'immutable'

export type LogoutPayload = { isTealbot: boolean } | void
export const logout = createAction<LogoutPayload>(actionTypes.LOGOUT)

export const sendContactUsMessage = createAction(
  actionTypes.SEND_CONTACT_US_MESSAGE
)

export const startEditMode = createAction(actionTypes.START_EDIT_MODE)
export const stopEditMode = createAction(actionTypes.STOP_EDIT_MODE)

export const requestSuccess = (actionType: string, payload?: any) => ({
  type: actionTypes.requestSuccess(actionType),
  payload
})

export const requestFailure = (actionType: string, payload?: any) => ({
  type: actionTypes.requestFailure(actionType),
  payload
})

type LoadDataPayload<
  Entity extends string = any,
  ApiResult = any,
  ParsedData = any
> = {
  entity: Entity
  query?: string
  resultParser?: (data: ApiResult) => ParsedData
}
export type LoadDataPayloads =
  | LoadDataPayload
  | LoadDataPayload<'tasks'>
  | LoadDataPayload<'supplierOutreachTasks'>
  | LoadDataPayload<'supplierRelationships'>
  | LoadDataPayload<'forbiddenDomains', { domains: List<string> }>
  | LoadDataPayload<'targetClients'>
  | LoadDataPayload<'changeHistory'>

export const loadData = createAction<LoadDataPayloads>(actionTypes.LOAD_DATA)

type UpdateDataPayload<
  Entity extends string,
  T,
  ApiResult = {},
  ParsedData = {}
> = {
  entity: Entity
  record: T
  endpoint?: string
  redirectTo?: string
  resultParser?: (data: ApiResult) => ParsedData
}
type UpdatePayloads =
  | UpdateDataPayload<'tasks', Partial<Task>>
  | UpdateDataPayload<'supplierOutreachTasks', Partial<Task>>
  | UpdateDataPayload<'forbiddenDomains', { domains: Array<string> }>

type DeleteDataPayload<Entity extends string, Query = {}> = {
  entity: Entity
  endpoint?: string
  recordId: string
  fieldName?: string
  query: Query
  onSuccess?: any
}
type DeleteDataPayloads = DeleteDataPayload<
  'users',
  { reason?: string; hardDelete?: boolean }
>

export const updateData = createAction<UpdatePayloads>(actionTypes.UPDATE_DATA)
export const deleteData = createAction<DeleteDataPayloads>(
  actionTypes.DELETE_DATA
)

export const loadUser = createAction<string>(actionTypes.LOAD_USER)
export const updateUser = createAction(actionTypes.UPDATE_USER)
export const updateUserRoles = createAction(actionTypes.UPDATE_USER_ROLES)
export const loadUserMetrics = createAction(actionTypes.LOAD_USER_METRICS)
export const loadAllUsers = createAction(actionTypes.LOAD_ALL_USERS)

export const notify = createAction(actionTypes.NOTIFY)
export const clearNotifications = createAction(actionTypes.CLEAR_NOTIFICATIONS)

type LoadComments = { ancestorId: string; messageKind: string }
export const loadComments = createAction<LoadComments>(
  actionTypes.LOAD_COMMENTS
)
type RemoveComments = {
  ancestorId: string
  messageId: string
  messageKind: string
}
export const removeComment = createAction<RemoveComments>(
  actionTypes.REMOVE_COMMENT
)

export const loadOrg = createAction(actionTypes.LOAD_ORG)

export const loadMessageTopics = createAction(actionTypes.LOAD_MESSAGE_TOPICS)
export const selectUserToMessage = createAction(
  actionTypes.SELECT_USER_TO_MESSAGE
)
export const unselectUserToMessage = createAction(
  actionTypes.UNSELECT_USER_TO_MESSAGE
)
export const readMessage = createAction(actionTypes.READ_MESSAGE)
export const messageUser = createAction(actionTypes.MESSAGE_USER)

export const suggestOrgUnit = createAction(actionTypes.SUGGEST_ORG_UNIT)
export const createConciergeRequest = createAction(
  actionTypes.CREATE_CONCIERGE_REQUEST
)

export const preload = createAction(actionTypes.PRELOAD)

type LoadClientFilePayload<Entity extends string> = {
  entity: Entity
  query: string
}
export type LoadClientFilePayloads = LoadClientFilePayload<
  'clientFiles' | 'orgUnitClientFiles'
>
export const loadClientFiles = createAction<LoadClientFilePayloads>(
  actionTypes.LOAD_CLIENT_FILES
)
export const loadOrgUnitClientFiles = createAction<LoadClientFilePayloads>(
  actionTypes.LOAD_ORGUNIT_CLIENT_FILES
)

export const loadStagingHistory = createAction(actionTypes.LOAD_STAGING_HISTORY)
export const uploadSpendFile = createAction(actionTypes.UPLOAD_SPEND_FILE)
export const updateFileHistory = createAction(actionTypes.UPDATE_FILE_HISTORY)
export const publishSpend = createAction(actionTypes.PUBLISH_SPEND)
export const removeSpend = createAction(actionTypes.REMOVE_SPEND)
export const remapSupplier = createAction(actionTypes.REMAP_SUPPLIER)
export const openRemapDialog = createAction(actionTypes.OPEN_REMAP_DIALOG)
export const closeRemapDialog = createAction(actionTypes.CLOSE_REMAP_DIALOG)

export const loadInternalStats = createAction(actionTypes.LOAD_INTERNAL_STATS)
export const loadInternalStatKeys = createAction(
  actionTypes.LOAD_INTERNAL_STAT_KEYS
)
export const closeSupplierOutreachRequest = createAction(
  actionTypes.CLOSE_SUPPLIER_OUTREACH_REQUEST
)
export const resendSupplierOutreachRequest = createAction(
  actionTypes.RESEND_SUPPLIER_OUTREACH_REQUEST
)
export const contactSupplierForOutreachRequest = createAction(
  actionTypes.CONTACT_SUPPLIER_FOR_OUTREACH_REQUEST
)
export const openForwardSurveyRequestDialog = createAction(
  actionTypes.OPEN_FORWARD_SURVEY_REQUEST_DIALOG
)
export const closeForwardSurveyRequestDialog = createAction(
  actionTypes.CLOSE_FORWARD_SURVEY_REQUEST_DIALOG
)
