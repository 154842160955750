import React, { Component } from 'react'
import { connect } from 'react-redux'
import FilterHOC from '../FilterHOC'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import { Skeleton } from '@material-ui/lab'
import searchSelectors from '../../selectors/searchSelectors'
import RootState from '../../../../shared/models/RootState'

type Props = {
  label: string
  checked: boolean
  onChangeFilterOption: (params: { key: string; value: boolean }) => void
  showSkeleton?: boolean
} & ContainerProps

export class FilterBooleanContainer extends Component<Props> {
  handleOnChange = e => {
    const { onChangeFilterOption, filterKey } = this.props
    onChangeFilterOption({ key: filterKey, value: e.target.checked })
  }

  render() {
    const { label, filterKey, checked, showSkeleton } = this.props

    return (
      <div className='pv2'>
        {showSkeleton && <Skeleton variant='rect' width='100%' height={18} />}
        {!showSkeleton && (
          <>
            <input
              type='checkbox'
              id={filterKey}
              value={filterKey}
              onChange={this.handleOnChange}
              className='outline-0 b--black-10 mr1'
              checked={checked}
            />
            <label htmlFor={filterKey} className='f7 fw3 mid-gray'>
              {label}
            </label>
          </>
        )}
      </div>
    )
  }
}

type ContainerProps = {
  filterKey: string
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    return {
      showSkeleton: searchSelectors.isAggregating(state),
      checked: filterOptionsSelectors.getByKey(state, props.filterKey) || false
    }
  },
  {
    ...FilterHOC
  }
)(FilterBooleanContainer)
