import { fromJS, OrderedMap } from 'immutable'
import { createSelector } from 'reselect'
import usersSelectors from 'shared/selectors/usersSelectors'

export const getByEntity = (state, entityName) =>
  state.getIn(['data', entityName], fromJS({}))

export const getById = (state, entityName, entityId) => {
  return state.getIn(['data', entityName, entityId], fromJS({}))
}

export const isLoading = (state, entityName) =>
  !!state.getIn(['data', 'isLoading', entityName])

// supplier relationships selectors
const supplierRelationshipEntityName = 'supplierRelationships'
const orgUnitsEntityName = 'orgUnits'

export const getSupplierClients = createSelector(
  state => getByEntity(state, supplierRelationshipEntityName),
  state => getByEntity(state, orgUnitsEntityName),
  (supplierRelationships, orgUnits) =>
    !supplierRelationships
      ? fromJS([])
      : supplierRelationships
          .toList()
          .filter(item => !!item.getIn(['supplierCollaboration', 'isClient']))
          .sort((relA, relB) => {
            const buyerA = orgUnits.getIn([relA.get('buyer'), 'name']) || ''
            const buyerB = orgUnits.getIn([relB.get('buyer'), 'name']) || ''
            return buyerA.toUpperCase() > buyerB.toUpperCase() ? 1 : -1
          })
)

export const getSupplierClientsID = createSelector(
  getSupplierClients,
  clients => clients.map(item => item.get('id'))
)

export const getSupplierRelationshipByBuyerId = createSelector(
  (state, buyerId) => getByEntity(state, supplierRelationshipEntityName),
  (state, buyerId) => buyerId,
  (supplierRelationships, buyerId) => {
    return (
      supplierRelationships &&
      supplierRelationships.find(sr => sr.get('buyer') === buyerId)
    )
  }
)

export const getClientFiles = state => state.getIn(['data', 'clientFiles'])

export const getOrgUnitClientFiles = state =>
  state.getIn(['data', 'orgUnitClientFiles'])

const createStagingHistoryByStatusSelector = status => {
  return createSelector(
    state => state.getIn(['data', 'stagingHistory']),
    usersSelectors.getUsers,
    (stagingHistory, users) => {
      return (
        stagingHistory &&
        stagingHistory
          .filter(
            staging =>
              !!staging.get('clientFileName') &&
              (!status || staging.get('status') === status)
          )
          .map(staging =>
            staging.updateIn(['uploadBy'], uploadBy => {
              const user = users.get(uploadBy)
              return user
                ? `${user.get('firstName') || ''} ${user.get('lastName') || ''}`
                : ''
            })
          )
          .sort((staging1, staging2) => {
            if (
              `${staging1.get('year')}${staging1.get('quarter')}` >
              `${staging2.get('year')}${staging2.get('quarter')}`
            ) {
              return -1
            } else if (
              `${staging1.get('year')}${staging1.get('quarter')}` <
              `${staging2.get('year')}${staging2.get('quarter')}`
            ) {
              return 1
            } else {
              return staging1.get('uploadDate') > staging2.get('uploadDate')
                ? 1
                : -1
            }
          })
          .reduce((result, staging) => {
            return result.has(
              `${staging.get('year')}-${staging.get('quarter')}`
            )
              ? result.updateIn(
                  [`${staging.get('year')}-${staging.get('quarter')}`],
                  list => list.push(staging)
                )
              : result.set(
                  `${staging.get('year')}-${staging.get('quarter')}`,
                  fromJS([staging])
                )
          }, OrderedMap({}))
      )
    }
  )
}

export const getCompletedStaging = createStagingHistoryByStatusSelector(
  'complete'
)
export const getStagingHistory = createStagingHistoryByStatusSelector()

export const getStagingSerp = createSelector(
  state => state.getIn(['data', 'stagingHistory']),
  usersSelectors.getUsers,
  (stagingHistory, users) => {
    return (
      stagingHistory &&
      stagingHistory
        .filter(staging => !!staging.get('clientFileName'))
        .map(staging =>
          staging.updateIn(['uploadBy'], uploadBy => {
            const user = users.get(uploadBy)
            return user
              ? `${user.get('firstName') || ''} ${user.get('lastName') || ''}`
              : ''
          })
        )
        .sort((staging1, staging2) => {
          return staging1.get('uploadDate') < staging2.get('uploadDate')
            ? 1
            : -1
        })
    )
  }
)
