import { connect } from 'react-redux'
import postsSelectors from '../../selectors/postsSelectors'
import fileToUrl from 'shared/utils/data/fileToUrl'
import usersSelectors from 'shared/selectors/usersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { deletePost } from '../../actions'
import { sendContactUsMessage } from 'shared/actions'
import buyerPaths from 'buyer/routes/paths'
import supplierPaths from 'supplier/routes/paths'
import parsePath from 'shared/utils/parsePath'
import RootState from 'shared/models/RootState'
import { ComponentType } from 'react'
import Community from 'shared/models/Community'
import { RecordOf, List } from 'immutable'
import { push } from 'connected-react-router'

type Props = {
  postId: string
  isClientApp: boolean
}

function withPost<P>(component: ComponentType<P>) {
  return connect(
    (state: RootState, props: Props) => {
      const post = postsSelectors.getById(state, props.postId)
      const body = post && post.get('body')
      const image = post && post.get('image')
      const tags = post && post.get('tags')
      const orgUnit = post && post.get('orgUnit')
      const userId = post && post.getIn(['created', 'user'])
      const user = userId && usersSelectors.getById(state, userId)
      const currentUser = sessionSelectors.getUser(state)
      const currentOrgUnitId = currentUser && currentUser.get('orgUnitId')
      const hasDeleteAccess =
        currentUser &&
        (currentUser.get('id') === userId ||
          currentUser.get('roles').includes('tealbot'))
      const communities = postsSelectors.getCommunities(state)
      const targetCommunities: List<any> =
        post &&
        communities &&
        communities.filter((community: RecordOf<Community>) => {
          return post.get('community') === 'string'
            ? community.get('id') === post.get('community')
            : post.get('community').includes(community.get('id'))
        })
      const communityName =
        targetCommunities &&
        targetCommunities.map(c => c.get('name')).join(', ')
      const visibility = post && post.get('visibility')

      // special case: Internal if
      // buyer app
      // private community
      // owner of community
      // owner of post
      // visibility is buyer
      // no target communities
      const audience =
        post && targetCommunities && visibility.size === 1
          ? visibility.get(0) === 'supplier'
            ? 'Suppliers'
            : props.isClientApp &&
              targetCommunities.some(c => c.get('isPrivate')) &&
              targetCommunities.some(
                c =>
                  c
                    .get('buyers')
                    .findIndex(
                      value => value.get('orgUnitId') === currentOrgUnitId
                    ) !== -1
              ) &&
              orgUnit === currentOrgUnitId
            ? 'Internal'
            : targetCommunities.size < 1 && !post.get('broadcast')
            ? 'Internal'
            : 'Buyers'
          : ''

      return {
        id: props.postId,
        title: post && post.get('title'),
        body,
        tags,
        imageUrl: image && fileToUrl(image.toJS()),
        userId,
        currentUserId: currentUser.get('id'),
        currentUserEmail: currentUser.get('email'),
        currentUserName: `${currentUser.get('firstName')} ${currentUser.get(
          'lastName'
        )}`,
        orgName: post && post.getIn(['expanded', 'OrgUnit', orgUnit, 'name']),

        // link to the supplier profile if the user has access to the buyer app
        orgProfileUrl:
          post &&
          sessionSelectors.userHasAccessToApp(state, 'buyer') &&
          parsePath(buyerPaths.supplierProfile, { supplierId: orgUnit }),

        profileUrl:
          post &&
          (props.isClientApp
            ? parsePath(buyerPaths.userprofile, { userId: userId })
            : parsePath(supplierPaths.userProfile, { userId: userId })),

        firstName: user && user.get('firstName'),
        lastName: user && user.get('lastName'),
        email: user && user.get('email'),
        role: (user && user.get('title')) || '',
        profilePictureUrl: user && user.get('profilePictureUrl'),
        createdDate: post && post.getIn(['created', 'date']),
        hasDeleteAccess,
        communityName,
        audience
      }
    },
    {
      push,
      onDeleteClick: deletePost,
      onReportClick: sendContactUsMessage
    }
    // @ts-ignore
  )(component)
}

export default withPost
