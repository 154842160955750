import React, { ReactChild } from 'react'
import TealbookLogoWrapper from '../../components/TealbookLogoWrapper'
import FeatureBox from 'shared/components/FeatureBox'
import { FormattedMessage } from 'react-intl'
import imageOneProfile from 'shared/assets/images/one-profile.png'
import imageInsights from 'shared/assets/images/insights.png'
import imageBusinessOpp from 'shared/assets/images/business-opp.png'
import imageDiversityCertification from 'shared/assets/images/diversity-certification.png'
import imageCompanyXYZ from 'shared/assets/images/company-xyz.png'

const styles = {
  featureWrapper: 'fl w-100 w-50-m w-25-l pb3 pa3-m pa4-l'
}

type Props = {
  children: ReactChild
  client?: string
  iframe?: boolean
}

const DefaultLandingPage = (props: Props) => {
  const { children, client, iframe } = props

  return (
    <div>
      <div>
        <div className='bg-teal pt2 pb4'>
          <TealbookLogoWrapper white clientOf={client} small={iframe}>
            <div className='mb3'>
              <h5 className='f5 f3-l fw4 mv3 white'>
                <FormattedMessage
                  id='RegistrationWizard.createTealbookAccount'
                  defaultMessage='Create Your Free TealBook Account!'
                />
              </h5>
            </div>
            {children}
          </TealbookLogoWrapper>
        </div>
        <div className='mw8 center pa3 cf'>
          <h5 className='f5 f3-l fw4 mt3 mb0 mid-gray'>
            <FormattedMessage
              id='RegistrationWizard.growYourBusiness'
              defaultMessage='Grow Your Business With TealBook!'
            />
          </h5>
          <section className='fl w-100'>
            <FeatureBox
              featureImage={imageOneProfile}
              title={
                <FormattedMessage
                  id='RegistrationWizard.selfManagedProfile'
                  defaultMessage='Self-Managed Profile'
                />
              }
              className={styles.featureWrapper}
            >
              <FormattedMessage
                id='RegistrationWizard.selfManagedProfileDescription'
                defaultMessage={`
                A single, self-managed profile contains all of your information and capabilities
                Your profile grows with your business and allows you to keep customers up-to-date
                Link to your website, share news and connect social media accounts`}
              />
            </FeatureBox>
            <FeatureBox
              featureImage={imageInsights}
              title={
                <FormattedMessage
                  id='RegistrationWizard.insights'
                  defaultMessage='Insights'
                />
              }
              className={styles.featureWrapper}
            >
              <FormattedMessage
                id='RegistrationWizard.insightsDescription'
                defaultMessage={`
                Access powerful insights like profile views, search result appearances, customer connections, keywords used to find you and more
                Leverage your insights and dynamically tailor your business's profile to your ideal potential customers`}
              />
            </FeatureBox>

            <FeatureBox
              featureImage={imageBusinessOpp}
              title={
                <FormattedMessage
                  id='RegistrationWizard.newBusiness'
                  defaultMessage='New Business'
                />
              }
              className={styles.featureWrapper}
            >
              <FormattedMessage
                id='RegistrationWizard.newBusinessDescription'
                defaultMessage={`
                Be easily found and instantly qualified for relevant business opportunities by both new and existing customers
                Significantly decrease the time and effort associated with the cost of looking for new opportunities`}
              />
            </FeatureBox>

            <FeatureBox
              featureImage={imageDiversityCertification}
              title={
                <FormattedMessage
                  id='RegistrationWizard.diversityCertifications'
                  defaultMessage='Diversity Certifications'
                />
              }
              className={styles.featureWrapper}
            >
              <FormattedMessage
                id='RegistrationWizard.diversityCertificationDescription'
                defaultMessage={`
                No more separate portals! Showcase certifications and affiliations right on your profile, and easily be found by customers looking to scale their supplier diversity efforts
                Be visible in search results where customers can filter by specific diversity certifications`}
              />
            </FeatureBox>
          </section>
          <img src={imageCompanyXYZ} alt='company xyz' />
        </div>
      </div>
    </div>
  )
}

export default DefaultLandingPage
