import { connect } from 'react-redux'
import sessionSelectors from '../../selectors/sessionSelectors'
import AppBar from 'shared/components/AppBar'
import withWidth from '@material-ui/core/withWidth'
import qs from 'qs'
import { withRouter, RouteComponentProps } from 'react-router'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import searchSelectors from 'buyer/Search/selectors/searchSelectors'
import RootState from 'shared/models/RootState'

export default withRouter(
  connect((state: RootState, props: RouteComponentProps) => {
    const hasAccessToBuyerApp = sessionSelectors.userHasAccessToApp(
      state,
      'buyer'
    )
    const hasAccessToSupplierApp = sessionSelectors.userHasAccessToApp(
      state,
      'supplier'
    )
    const hasAccessToDataPortal = sessionSelectors.userHasAccessToApp(
      state,
      'data-portal'
    )

    const queryParams = qs.parse(props.history.location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })

    const {
      redirectFrom,
      filter,
      scope,
      sort,
      q,
      certificationExpiredExcluded,
      attachmentsExpiredExcluded,
      similarSuppliers
    } = queryParams
    const queryString = qs.stringify({
      filter,
      scope,
      sort,
      q,
      certificationExpiredExcluded,
      attachmentsExpiredExcluded,
      similarSuppliers
    })

    const defaultFilters = settingsSelectors.getDefaultFilters(state)
    const refQueryString:
      | string
      | undefined = searchSelectors.getQueryStringByRef(state, 'buyerSearch')
    const buyerUrlQueryString: string = refQueryString
      ? refQueryString
      : defaultFilters.size > 0
      ? `?${qs.stringify({ filter: defaultFilters.toJS() })}`
      : ''

    return {
      loggedIn: sessionSelectors.isLoggedIn(state),
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      buyerUrlQueryString,
      showBuyerUrl: hasAccessToBuyerApp,
      showSupplierUrl: hasAccessToSupplierApp,
      showDataPortalUrl: hasAccessToDataPortal,
      showAdminUrl: sessionSelectors.userHasAccessToApp(state, 'admin'),
      showClickWorkerUrl:
        sessionSelectors.userHasRole(state, 'clickWorker') &&
        sessionSelectors.userHasRole(state, ['orgAdmin', 'serverAdmin']),
      redirectFrom:
        redirectFrom && redirectFrom.indexOf('search') !== -1
          ? `${redirectFrom}&${queryString}`
          : redirectFrom
    }
  })(withWidth()(AppBar))
)
