import { put } from 'redux-saga/effects'
import { SET_COLLECTION } from '../../actionTypes'
import storageManager from 'shared/utils/storageManager'

function* loadCollectionSaga() {
  const collection = storageManager.getItem('cart')

  if (collection && Object.keys(collection).length > 0) {
    yield put({ type: SET_COLLECTION, payload: collection })
  }
}

export default loadCollectionSaga
