import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Column } from 'react-virtualized'
import Table from 'shared/components/Table'
import InsightTitle from 'shared/components/InsightTitle'
import startCase from 'lodash.startcase'
import Button from 'shared/components/Button'
import exportData from 'shared/utils/exportData'
import Select from 'shared/components/Select'
import Text from 'shared/components/Text'
import { CommunitySuppliers } from './InternalStats'
import { List } from 'immutable'
import moment from 'moment'

type Props = {
  stat: string
  communitySuppliers: List<CommunitySuppliers>
}
const CommunitySuppliersTable = ({ stat, communitySuppliers }: Props) => {
  const [communitySelected, setCommunitySelected] = useState<string>('')
  const [fromDateSelected, setFromDateSelected] = useState<string>('')
  const [toDateSelected, setToDateSelected] = useState<string>('')

  const filterByCommunitySelected = useMemo(() => {
    return communitySuppliers && !!communitySuppliers.size
      ? communitySuppliers
          .reduce<string[]>((results, currentItem) => {
            let communitySelected = currentItem.get('communityName')
            if (results.indexOf(communitySelected) < 0) {
              results.push(communitySelected)
            }
            return results
          }, [])
          .sort()
      : undefined
  }, [communitySuppliers])

  const filterByFromDateSelected = useMemo(() => {
    return communitySuppliers && !!communitySuppliers.size
      ? communitySuppliers
          .reduce<string[]>((results, currentItem) => {
            let date = currentItem.getIn(['membershipDate', 'value'])
            let fromDateSelected = !!date && moment(date).format('L')
            if (fromDateSelected && results.indexOf(fromDateSelected) < 0) {
              results.push(fromDateSelected)
            }
            return results
          }, [])
          .sort(
            (a, b) =>
              moment(a, 'MM/DD/YYYY').valueOf() -
              moment(b, 'MM/DD/YYYY').valueOf()
          )
      : undefined
  }, [communitySuppliers])

  const filterByToDateSelected = useMemo(() => {
    return communitySuppliers && !!communitySuppliers.size
      ? communitySuppliers
          .reduce<string[]>((results, currentItem) => {
            let date = currentItem.getIn(['membershipDate', 'value'])
            let toDateSelected = !!date && moment(date).format('L')
            if (toDateSelected && results.indexOf(toDateSelected) < 0) {
              results.push(toDateSelected)
            }
            return results
          }, [])
          .sort(
            (a, b) =>
              moment(b, 'MM/DD/YYYY').valueOf() -
              moment(a, 'MM/DD/YYYY').valueOf()
          )
      : undefined
  }, [communitySuppliers])

  const visibleCommunitySelected = useMemo(() => {
    return communitySuppliers && !!communitySuppliers.size
      ? communitySuppliers
          .filter(stat => {
            let currentCommunity = stat.get('communityName')
            let date = stat.getIn(['membershipDate', 'value'])
            let currentDate = !!date && moment(date).format('L')
            let currentDateIsAfterSelectedStartDate =
              currentDate &&
              moment(currentDate, 'MM/DD/YYYY').isAfter(
                moment(fromDateSelected, 'MM/DD/YYYY')
              )
            let currentDateIsEqualSelectedStartDate =
              currentDate &&
              moment(currentDate, 'MM/DD/YYYY').isSame(
                moment(fromDateSelected, 'MM/DD/YYYY')
              )
            let currentDateIsBeforeSelectedEndDate =
              currentDate &&
              moment(currentDate, 'MM/DD/YYYY').isBefore(
                moment(toDateSelected, 'MM/DD/YYYY')
              )
            let currentDateIsEqualSelectedEndDate =
              currentDate &&
              moment(currentDate, 'MM/DD/YYYY').isSame(
                moment(toDateSelected, 'MM/DD/YYYY')
              )
            if (
              currentCommunity &&
              currentCommunity === communitySelected &&
              currentDate &&
              (currentDateIsAfterSelectedStartDate ||
                currentDateIsEqualSelectedStartDate) &&
              (currentDateIsBeforeSelectedEndDate ||
                currentDateIsEqualSelectedEndDate)
            ) {
              return true
            }
            if (
              currentCommunity &&
              currentCommunity === communitySelected &&
              currentDate &&
              (currentDateIsAfterSelectedStartDate ||
                currentDateIsEqualSelectedStartDate) &&
              !toDateSelected
            ) {
              return true
            }
            if (
              currentCommunity &&
              currentCommunity === communitySelected &&
              currentDate &&
              (currentDateIsBeforeSelectedEndDate ||
                currentDateIsEqualSelectedEndDate) &&
              !fromDateSelected
            ) {
              return true
            }
            if (
              currentDate &&
              (currentDateIsAfterSelectedStartDate ||
                currentDateIsEqualSelectedStartDate) &&
              (currentDateIsBeforeSelectedEndDate ||
                currentDateIsEqualSelectedEndDate) &&
              !communitySelected
            ) {
              return true
            }
            if (
              currentCommunity &&
              currentCommunity === communitySelected &&
              !fromDateSelected &&
              !toDateSelected
            ) {
              return true
            }
            if (
              currentDate &&
              (currentDateIsAfterSelectedStartDate ||
                currentDateIsEqualSelectedStartDate) &&
              !communitySelected &&
              !toDateSelected
            ) {
              return true
            }
            if (
              currentDate &&
              (currentDateIsBeforeSelectedEndDate ||
                currentDateIsEqualSelectedEndDate) &&
              !communitySelected &&
              !fromDateSelected
            ) {
              return true
            }
            if (!communitySelected && !fromDateSelected && !toDateSelected) {
              return true
            }
            return false
          })
          .sort((a, b) => {
            let date1 = a.getIn(['membershipDate', 'value'])
            let date2 = b.getIn(['membershipDate', 'value'])

            return (
              moment(date2, 'MM/DD/YYYY').valueOf() -
              moment(date1, 'MM/DD/YYYY').valueOf()
            )
          })
      : undefined
  }, [communitySuppliers, communitySelected, fromDateSelected, toDateSelected])

  const onChangeCommunitySelected = e => setCommunitySelected(e.target.value)
  const onChangeFromDateSelected = e => setFromDateSelected(e.target.value)
  const onChangeToDateSelected = e => setToDateSelected(e.target.value)

  return (
    <>
      {visibleCommunitySelected && (
        <div key={stat}>
          <div className='flex justify-between items-center mt4'>
            <InsightTitle>{startCase(stat)}</InsightTitle>
            <div className='flex items-end'>
              <Select
                fullWidth
                name='CommunityName'
                onChange={onChangeCommunitySelected}
              >
                <option value=''>All Communities</option>
                {filterByCommunitySelected &&
                  filterByCommunitySelected.map(stat => (
                    <option key={stat} value={stat}>
                      {startCase(stat)}
                    </option>
                  ))}
              </Select>
              <Select
                fullWidth
                name='FromDate'
                onChange={onChangeFromDateSelected}
              >
                <option value=''>No Start Date Selected</option>
                {filterByFromDateSelected &&
                  filterByFromDateSelected.map((stat, index) => (
                    <option key={index} value={stat}>
                      {stat}
                    </option>
                  ))}
              </Select>
              <Select fullWidth name='ToDate' onChange={onChangeToDateSelected}>
                <option value=''>No End Date Selected</option>
                {filterByToDateSelected &&
                  filterByToDateSelected.map((stat, index) => (
                    <option key={index} value={stat}>
                      {stat}
                    </option>
                  ))}
              </Select>
              <div className='ml2'>
                <Button
                  onClick={() => {
                    exportData.exportCSV(
                      visibleCommunitySelected
                        .toJS()
                        .map(
                          ({
                            communityName,
                            membershipDate,
                            supplierName,
                            domain,
                            emails
                          }) => ({
                            communityName,
                            membershipDate: moment(membershipDate.value).format(
                              'L'
                            ),
                            supplierName,
                            domain,
                            emails
                          })
                        ),
                      'Community Suppliers',
                      true
                    )
                  }}
                >
                  <FormattedMessage
                    id='InternalStats.Export'
                    defaultMessage='Export'
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className='bg-white br1 ba b--black-10'>
            <Table
              rowGetter={({ index }) =>
                !!visibleCommunitySelected.size &&
                visibleCommunitySelected.get(index)
              }
              rowCount={visibleCommunitySelected.size}
              rowHeight={row => 70}
            >
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.communityName'
                    defaultMessage='Community Name'
                  />
                }
                width={200}
                dataKey='communityName'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.membershipDate'
                    defaultMessage='Membership Date'
                  />
                }
                width={200}
                dataKey='membershipDate'
                cellRenderer={({ cellData }) =>
                  cellData && (
                    <Text>{moment(cellData.get('value')).format('L')}</Text>
                  )
                }
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.supplierName'
                    defaultMessage='Supplier Name'
                  />
                }
                width={200}
                dataKey='supplierName'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.domain'
                    defaultMessage='Domain'
                  />
                }
                width={200}
                dataKey='domain'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.emails'
                    defaultMessage='Emails'
                  />
                }
                width={200}
                dataKey='emails'
              />
            </Table>
          </div>
        </div>
      )}
    </>
  )
}

export default CommunitySuppliersTable
