import React, { Component } from 'react'
import { connect } from 'react-redux'
import startCase from 'lodash.startcase'
import camelCase from 'lodash.camelcase'
import Pill from 'shared/components/Pill'
import Text from 'shared/components/Text'
import FilterHOC from '../FilterHOC'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import searchSelectors from '../../selectors/searchSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import Popover from '@material-ui/core/Popover'
import RootState from 'shared/models/RootState'
import Immutable, { RecordOf, List, Map } from 'immutable'
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  IntlShape
} from 'react-intl'
import { translatedFilterKeys } from 'shared/utils/data/defineMessages'
import translateCertificationType from 'shared/utils/translateCertificationType'
import User from 'shared/models/User'
import translatedNaicsKeys from 'shared/models/Naics'
import CertificationCategories, {
  CategoryType
} from 'shared/models/CertificationCategories'

const availableCertifications = Object.keys(CertificationCategories)

const messages = defineMessages({
  noSpend: {
    id: 'FiltersSummaryContainer.NoSpend',
    defaultMessage: 'No Spend'
  },
  veryLowSpend: {
    id: 'FiltersSummaryContainer.VeryLowSpend',
    defaultMessage: 'Very Low Spend'
  },
  lowSpend: {
    id: 'FiltersSummaryContainer.LowSpend',
    defaultMessage: 'Low Spend'
  },
  mediumSpend: {
    id: 'FiltersSummaryContainer.MediumSpend',
    defaultMessage: 'Medium Spend'
  },
  highSpend: {
    id: 'FiltersSummaryContainer.HighSpend',
    defaultMessage: 'High Spend'
  },
  highestSpend: {
    id: 'FiltersSummaryContainer.HighestSpend',
    defaultMessage: 'Highest Spend'
  },
  anyOf: {
    id: 'FiltersSummaryContainer.AnyOf',
    defaultMessage: 'Any of'
  },
  unspecified: {
    id: 'FiltersSummaryContainer.Unspecified',
    defaultMessage: 'Unspecified'
  },
  hasEmail: {
    id: 'FiltersSummaryContainer.HasEmail',
    defaultMessage: 'Has email'
  },
  noEmail: {
    id: 'FiltersSummaryContainer.NoEmail',
    defaultMessage: 'No email'
  },
  recentlyUpdated: {
    id: 'FiltersSummaryContainer.RecentlyUpdated',
    defaultMessage: 'Recently updated'
  },
  noUpdate: {
    id: 'FiltersSummaryContainer.NoUpdate',
    defaultMessage: 'No update'
  },
  followedBy: {
    id: 'FiltersSummaryContainer.FollowedBy',
    defaultMessage: 'Followed by'
  },
  followedByAnyOf: {
    id: 'FiltersSummaryContainer.FollowedByAnyOf',
    defaultMessage: 'Followed by any of'
  },
  certificationExpiredExcluded: {
    id: 'FiltersSummaryContainer.certificationExpiredExcluded',
    defaultMessage: 'Including Expired and Potential'
  }
})

const percentileBucketMapping = [
  'noSpend',
  'veryLowSpend',
  'lowSpend',
  'mediumSpend',
  'highSpend',
  'highestSpend'
]

type Props = {
  removeFilterOption: (
    param: { key: string; value: string } | { key: string },
    meta?: { fuzzyMatch: string }
  ) => void
  onChangeFilterOption: (params: {
    key: string
    value?: string | boolean
  }) => void
  fixedFilters: Map<string, any>
  communitiesName: Map<string, string>
  relationshipAttributes?: Map<string, Map<string, string>>
  attachmentTypes?: Map<string, Map<string, string>>
  filters: Map<string, List<string | List<string>>>
  scope: string
  certificationExpiredExcluded: List<string>
  attachmentsExpiredExcluded: string
  similarSuppliers: List<string>
  similarSuppliersName: Map<string, string>
  fixedFiltersMessage: string
  users: Map<string, RecordOf<User>>
  intl: IntlShape
}

export class FiltersSummaryContainer extends Component<Props> {
  state = {
    popoverOpen: false,
    popoverAnchorEl: null
  }

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    })
  }

  handleNoDelete = x => {
    this.setState({
      popoverAnchorEl: x,
      popoverOpen: true
    })
  }

  handleRequestDelete(key, value) {
    const { removeFilterOption } = this.props
    removeFilterOption({ key, value })
    if (key === 'communities') {
      removeFilterOption({ key: 'communityStatus' })
    } else if (key === 'country') {
      removeFilterOption({ key: 'state' })
      removeFilterOption({ key: 'city' })
    } else if (key === 'state') {
      removeFilterOption({ key: 'city' })
    } else if (availableCertifications.includes(key)) {
      if (value === key) {
        removeFilterOption({ key })
      } else {
        removeFilterOption({ key, value }, { fuzzyMatch: `subTypes.${value}.` })
      }
    } else if (key === 'scope') {
      removeFilterOption({ key: 'connectedUserId' })
    } else if (key === 'sector') {
      removeFilterOption({ key: 'subsector' })
      removeFilterOption({ key: 'group' })
    } else if (key === 'subsector') {
      removeFilterOption({ key: 'group' })
    }
  }

  renderPill(label, key, value, title?) {
    const { fixedFilters } = this.props
    const noDelete =
      fixedFilters &&
      fixedFilters.get(key) &&
      fixedFilters.get(key).includes(value)

    return (
      <Pill
        key={value ? `${key}-${value}` : key}
        label={label || value}
        title={title}
        onRequestDelete={
          noDelete
            ? x => this.handleNoDelete(x)
            : () => this.handleRequestDelete(key, value)
        }
      />
    )
  }

  renderSummary(filters: Map<string, List<string | List<string>>>) {
    const {
      communitiesName,
      relationshipAttributes,
      attachmentTypes,
      users,
      intl
    } = this.props
    return filters
      .entrySeq()
      .filter(([k, v]) => !!v)
      .map(([k, v]) => {
        const translatedMessages = {
          ...translatedFilterKeys[k],
          ...translatedNaicsKeys
        }
        switch (k) {
          case 'diversity':
          case 'quality':
          case 'security':
          case 'sustainability':
          case 'food':
            // use CertificationCategories as the single source of truth for up to date categorizations. Back-end devs update CertificationCategories
            // translatedFilterKeys is not up to date for Certification Categories
            return v.valueSeq().map(item => {
              let label: string = ''
              let title: string | undefined = ''
              // I'm not seeing eviidence that 'item' would be List<string>>. I've only seen it as string while testing
              if (Immutable.List.isList(item)) {
                const joinItems = item.map(i => {
                  let subType: string | undefined = ''
                  let subCategory: string = i
                  // i can be: "subTypes.mbe.[sub type]"
                  if (i.startsWith('subTypes')) {
                    const filterValueSections = i.split('.')
                    subCategory = filterValueSections[1]
                    subType = camelCase(filterValueSections[2])
                  }

                  // we append this when using sub types
                  const translatedSubCategoryAcronym = translateCertificationType(
                    {
                      intl,
                      categoryType: k,
                      subCategoryType: subCategory,
                      useAcronym: true
                    }
                  )

                  const label = translateCertificationType({
                    intl,
                    categoryType: k,
                    subCategoryType: subCategory,
                    subType,
                    useAcronym: subCategory !== k
                  })
                  const title =
                    subCategory !== k
                      ? translateCertificationType({
                          intl,
                          categoryType: k,
                          subCategoryType: subCategory,
                          subType
                        })
                      : undefined

                  return {
                    label: subType
                      ? `${translatedSubCategoryAcronym} - ${label}`
                      : label,
                    title: subType
                      ? `${translatedSubCategoryAcronym} - ${title}`
                      : title
                  }
                })
                label =
                  joinItems.size > 1
                    ? `${intl.formatMessage(messages.anyOf)} ${joinItems
                        .map(i => i.label)
                        .join(', ')}`
                    : joinItems.map(i => i.label).join()
                title =
                  joinItems.size > 1
                    ? `${intl.formatMessage(messages.anyOf)} ${joinItems
                        .map(i => i.title)
                        .join(', ')}`
                    : joinItems.map(i => i.title).join()
              } else {
                //item === string
                let subType: string | undefined = ''
                let subCategory: string = ''
                // item can be: "subTypes.mbe.[sub type]"
                if (item.startsWith('subTypes')) {
                  const filterValueSections = item.split('.')
                  subCategory = filterValueSections[1]
                  subType = camelCase(filterValueSections[2])
                }
                const translatedSubCategoryAcronym = translateCertificationType(
                  {
                    intl,
                    categoryType: k,
                    subCategoryType: subCategory,
                    useAcronym: true
                  }
                )
                const labels = translateCertificationType({
                  intl,
                  categoryType: k,
                  subCategoryType: item !== k ? item : undefined,
                  subType,
                  useAcronym: subCategory !== k
                })

                const titles =
                  item !== k
                    ? translateCertificationType({
                        intl,
                        categoryType: k,
                        subCategoryType: item,
                        subType
                      })
                    : undefined
                label = subType
                  ? `${translatedSubCategoryAcronym} - ${labels}`
                  : labels

                title = subType
                  ? `${translatedSubCategoryAcronym} - ${titles}`
                  : titles
              }

              return this.renderPill(label, k, item, title)
            })
          case 'communities':
            return v.valueSeq().map(item => {
              let label
              if (Immutable.List.isList(item)) {
                const joinItems = item.map(i => communitiesName.get(i))
                label =
                  joinItems.size > 1
                    ? `${intl.formatMessage(messages.anyOf)} ${joinItems.join(
                        ', '
                      )}`
                    : joinItems.join()
              } else {
                label = communitiesName.get(item)
              }
              return label ? this.renderPill(label, k, item) : null
            })
          case 'relationshipRating':
            return v.toString() === '0'
              ? this.renderPill(
                  <FormattedMessage
                    id='FiltersSummaryContainer.Unrated'
                    defaultMessage='Unrated'
                  />,
                  'relationshipRating',
                  `Unrated`
                )
              : this.renderPill(
                  <FormattedMessage
                    id='FiltersSummaryContainer.Rating'
                    defaultMessage='{rating} stars'
                    values={{ rating: v }}
                  />,
                  'relationshipRating',
                  `${v} stars`
                )
          case 'recentPercentile':
            return v.valueSeq().map(item => {
              let label
              if (Immutable.List.isList(item)) {
                const joinItems = item.map(i =>
                  intl.formatMessage(messages[percentileBucketMapping[i]])
                )
                label =
                  joinItems.size > 1
                    ? `${intl.formatMessage(messages.anyOf)} ${joinItems.join(
                        ', '
                      )}`
                    : joinItems.join()
              } else {
                label = intl.formatMessage(
                  messages[percentileBucketMapping[item]]
                )
              }
              return this.renderPill(label, k, item)
            })
          case 'city':
          case 'state':
          case 'country':
            return v.valueSeq().map(item => {
              let label
              if (Immutable.List.isList(item)) {
                const joinItems = item.map(i => {
                  const location = i.split('::')
                  return (
                    (translatedMessages &&
                    translatedMessages[camelCase(location[location.length - 1])]
                      ? intl.formatMessage(
                          translatedMessages[
                            camelCase(location[location.length - 1])
                          ]
                        )
                      : location[location.length - 1]) ||
                    intl.formatMessage(messages.unspecified)
                  )
                })
                label =
                  joinItems.size > 1
                    ? `${intl.formatMessage(messages.anyOf)} ${joinItems.join(
                        ', '
                      )}`
                    : joinItems.join()
              } else {
                const location = item.split('::')
                label =
                  (translatedMessages &&
                  translatedMessages[camelCase(location[location.length - 1])]
                    ? intl.formatMessage(
                        translatedMessages[
                          camelCase(location[location.length - 1])
                        ]
                      )
                    : location[location.length - 1]) ||
                  intl.formatMessage(messages.unspecified)
              }
              return this.renderPill(label, k, item)
            })
          case 'emailAvailable':
            return v.valueSeq().map(item => {
              const label =
                item === 'true'
                  ? intl.formatMessage(messages.hasEmail)
                  : intl.formatMessage(messages.noEmail)
              return this.renderPill(label, k, item)
            })
          case 'supplierUpdated':
            return v.valueSeq().map(item => {
              const label =
                item === 'true'
                  ? intl.formatMessage(messages.recentlyUpdated)
                  : intl.formatMessage(messages.noUpdate)
              return this.renderPill(label, k, item)
            })
          case 'warnings':
            return v.valueSeq().map(item => {
              const label =
                translatedMessages && translatedMessages[camelCase(item)]
                  ? intl.formatMessage(translatedMessages[camelCase(item)])
                  : startCase(item).replace(
                      /\w\S*/g,
                      (txt: string) =>
                        `${txt.charAt(0).toUpperCase()}${txt
                          .substr(1)
                          .toLowerCase()}`
                    )
              return this.renderPill(label, k, item)
            })
          case 'attributes':
          case 'attachments':
            return v.valueSeq().map(item => {
              let label, buyerAtt, buyerLabel
              if (Immutable.List.isList(item)) {
                const joinItems = item.map(i => {
                  buyerAtt =
                    k === 'attributes'
                      ? relationshipAttributes && relationshipAttributes.get(i)
                      : attachmentTypes && attachmentTypes.get(i)
                  buyerLabel = buyerAtt && buyerAtt.get('label')
                  return translatedMessages &&
                    translatedMessages[camelCase(buyerLabel || i)]
                    ? intl.formatMessage(
                        translatedMessages[camelCase(buyerLabel || i)]
                      )
                    : buyerLabel || startCase(i)
                })
                label =
                  joinItems.size > 1
                    ? `${intl.formatMessage(messages.anyOf)} ${joinItems.join(
                        ', '
                      )}`
                    : joinItems.join()
              } else {
                buyerAtt =
                  k === 'attributes'
                    ? relationshipAttributes && relationshipAttributes.get(item)
                    : attachmentTypes && attachmentTypes.get(item)
                buyerLabel = buyerAtt && buyerAtt.get('label')
                label =
                  translatedMessages &&
                  translatedMessages[camelCase(buyerLabel || item)]
                    ? intl.formatMessage(
                        translatedMessages[camelCase(buyerLabel || item)]
                      )
                    : buyerLabel || startCase(item)
              }
              return this.renderPill(label, k, item)
            })
          case 'connectedUserId':
            return v.valueSeq().map(item => {
              let label, userInfo
              if (Immutable.List.isList(item)) {
                const joinItems = item.map(i => {
                  userInfo = users.get(i)
                  return userInfo
                    ? `${userInfo.get('firstName')} ${userInfo.get('lastName')}`
                    : ' '
                })
                label =
                  joinItems.size > 1
                    ? `${intl.formatMessage(
                        messages.followedByAnyOf
                      )} ${joinItems.join(', ')}`
                    : `${intl.formatMessage(
                        messages.followedBy
                      )} ${joinItems.join()}`
              } else {
                userInfo = users.get(item)
                label = userInfo
                  ? `${intl.formatMessage(messages.followedBy)} ${userInfo.get(
                      'firstName'
                    )} ${userInfo.get('lastName')}`
                  : ' '
              }

              return this.renderPill(label, k, item)
            })
          case 'sector':
          case 'subsector':
          case 'group':
            return v.valueSeq().map(item => {
              let label, title, formatTitleId
              if (Immutable.List.isList(item)) {
                const joinLabels = item.map(i => `NAICS ${i}`)
                const joinTitles = item.map(i => {
                  formatTitleId =
                    translatedMessages &&
                    (translatedMessages[i] || translatedMessages[camelCase(i)])
                  return formatTitleId
                    ? intl.formatMessage(formatTitleId)
                    : null
                })
                label =
                  joinLabels.size > 1
                    ? `${intl.formatMessage(messages.anyOf)} ${joinLabels.join(
                        ', '
                      )}`
                    : joinLabels.join()
                title =
                  joinTitles.size > 1
                    ? `${intl.formatMessage(messages.anyOf)} ${joinTitles.join(
                        ', '
                      )}`
                    : joinTitles.join()
              } else {
                formatTitleId =
                  translatedMessages &&
                  (translatedMessages[item] ||
                    translatedMessages[camelCase(item)])
                label = `NAICS ${item}`
                title = formatTitleId ? intl.formatMessage(formatTitleId) : null
              }
              return this.renderPill(label, k, item, title)
            })
          default:
            return v.valueSeq().map(item => {
              let label, formatMessageId
              if (Immutable.List.isList(item)) {
                const joinItems = item.map(i => {
                  formatMessageId =
                    translatedMessages &&
                    (translatedMessages[i] || translatedMessages[camelCase(i)])
                  return formatMessageId
                    ? intl.formatMessage(formatMessageId)
                    : startCase(i)
                })
                label =
                  joinItems.size > 1
                    ? `${intl.formatMessage(messages.anyOf)} ${joinItems.join(
                        ', '
                      )}`
                    : joinItems.join()
              } else {
                formatMessageId =
                  translatedMessages &&
                  (translatedMessages[item] ||
                    translatedMessages[camelCase(item)])
                label = formatMessageId
                  ? intl.formatMessage(formatMessageId)
                  : startCase(item)
              }
              return this.renderPill(label, k, item)
            })
        }
      })
  }

  renderConnection(scope) {
    const connectionMap = {
      All: 'All',
      ColleagueConnections: "My Colleague's Suppliers",
      MyConnections: 'My Suppliers'
    }

    if (scope === 'All') {
      return null
    }
    return (
      <Pill
        key='scope'
        label={connectionMap[scope]}
        onRequestDelete={() => this.handleRequestDelete('scope', scope)}
      />
    )
  }

  render() {
    const {
      filters,
      scope,
      certificationExpiredExcluded,
      attachmentsExpiredExcluded,
      similarSuppliers,
      similarSuppliersName,
      onChangeFilterOption,
      fixedFiltersMessage,
      fixedFilters,
      intl
    } = this.props
    const { popoverOpen, popoverAnchorEl } = this.state
    return (
      <div className='mt2'>
        {this.renderConnection(scope)}
        {this.renderSummary(filters.merge(fixedFilters))}
        {attachmentsExpiredExcluded && (
          <Pill
            key='attachmentsExpiredExcluded'
            label='Only Non-Expired Attachments'
            onRequestDelete={() =>
              onChangeFilterOption({
                key: 'attachmentsExpiredExcluded',
                value: false
              })
            }
          />
        )}
        {certificationExpiredExcluded &&
          certificationExpiredExcluded.map((cert: CategoryType) => {
            return (
              <Pill
                key={`certificationExpiredExcluded-${cert}`}
                label={`${intl.formatMessage(
                  messages.certificationExpiredExcluded
                )} ${translateCertificationType({ intl, categoryType: cert })}`}
                onRequestDelete={() =>
                  this.handleRequestDelete('certificationExpiredExcluded', cert)
                }
              />
            )
          })}
        {similarSuppliers && (
          <Pill
            key='similarSuppliers'
            label={`Similar to ${similarSuppliers.size} Suppliers`}
            onRequestDelete={() =>
              onChangeFilterOption({
                key: 'similarSuppliers',
                value: undefined
              })
            }
            title={similarSuppliers
              .map(supplierId => (
                <Text key={supplierId}>
                  {similarSuppliersName.getIn([supplierId, 'name'])}
                </Text>
              ))
              .toArray()}
          />
        )}
        <Popover
          open={popoverOpen}
          anchorEl={popoverAnchorEl}
          onClose={this.togglePopover}
          anchorOrigin={{
            vertical: 10,
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div className='dib lh-copy f7 fw4 mid-gray ph2 pv1'>
            {fixedFiltersMessage || (
              <FormattedMessage id='fixedFilterDefaultMessage' />
            )}
          </div>
        </Popover>
      </div>
    )
  }
}

export default connect(
  (state: RootState) => {
    const similarSuppliers = filterOptionsSelectors.getByKey(
      state,
      'similarSuppliers'
    )
    return {
      fixedFilters: settingsSelectors.getFixedFilters(state),
      fixedFiltersMessage: settingsSelectors.getFixedFiltersMessage(state),
      filters: filterOptionsSelectors.getFilterOptions(state),
      scope: filterOptionsSelectors.getFilterScope(state),
      communitiesName: searchSelectors.getCommunitiesName(state),
      relationshipAttributes: settingsSelectors.getRelationshipAttributes(
        state
      ),
      attachmentTypes: settingsSelectors.getAttachmentTypes(state),
      certificationExpiredExcluded: filterOptionsSelectors.getCertificationExpiredExcludedForSummary(
        state
      ),
      attachmentsExpiredExcluded: filterOptionsSelectors.getByKey(
        state,
        'attachmentsExpiredExcluded'
      ),
      similarSuppliers,
      similarSuppliersName: orgsSelectors.getOrgsName(state, similarSuppliers),
      users: usersSelectors.getUsers(state)
    }
  },
  {
    ...FilterHOC
  }
)(injectIntl(FiltersSummaryContainer))
