import React, { Component, Fragment } from 'react'
import Avatar from 'shared/components/Avatar'
import UserStackItem from 'shared/components/UserStackItem'
import Link from 'shared/components/Link'
import paths from '../../../routes/paths'
import PrivateConnectIcon from 'shared/assets/icons/connected-private.svg'
import SingleConnectionIcon from 'shared/assets/icons/connected-one.svg'
import MultiConnectionIcon from 'shared/assets/icons/connected-multi.svg'
import BuyerIcon from 'shared/assets/icons/buyer.svg'
import SupplierIcon from 'shared/assets/icons/supplier.svg'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import IphoneIcon from '@material-ui/icons/PhoneIphone'
import LocationIcon from '@material-ui/icons/LocationOn'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import Popover from '@material-ui/core/Popover'
import BlockIcon from '@material-ui/icons/Block'
import { List, RecordOf, fromJS } from 'immutable'
import { ReactComponent as ExistingIcon } from 'shared/assets/icons/existing.svg'
import Address from 'shared/components/Address'
import MessagesIcon from 'shared/assets/icons/messages.svg'
import Button from 'shared/components/Button'

export type SearchResultsItemProps = {
  userId: string
  firstName?: string
  lastName?: string
  profilePictureUrl?: string
  supplierId?: string
  orgUnitId: string
  companyName?: string
  title?: string
  email: string
  user: RecordOf<{
    status: string
  }>
  phone?: string
  mobilePhone?: string
  address?: string
  contacts?: List<
    RecordOf<{
      user: string
      id: string
      profilePictureUrl?: string
      firstName?: string
      lastName?: string
    }>
  >
  isConnected: boolean
  isPrivate: boolean
  colleagueIsConnected: boolean
  contactFor: string
  hasRelationship: boolean
  isBlocked: boolean
  isBuyer: boolean
  isSupplier: boolean
  onClickMessage?: () => void
  updateContact: (
    payload: RecordOf<{
      supplierId?: string
      user: { id: string }
      connected: boolean
      connectFor: string
    }>
  ) => void
  connectUser: (connectSupplierId: string, connectUserId: string) => void
}

class SearchResultsItem extends Component<SearchResultsItemProps> {
  state = {
    anchorEl: null
  }

  onClosePopover = () => {
    this.setState({
      anchorEl: null
    })
  }

  onOpenPopover = e => {
    this.setState({
      anchorEl: e.target
    })
  }

  handleDisconnect = () => {
    const { updateContact, userId, orgUnitId } = this.props
    updateContact(
      fromJS({
        supplierId: orgUnitId,
        user: { id: userId },
        connected: false,
        isPrivate: true,
        connectFor: ''
      })
    )
  }

  handleConnect = () => {
    const { connectUser, userId, orgUnitId } = this.props
    connectUser(orgUnitId, userId)
  }

  render() {
    const {
      userId,
      user,
      firstName,
      lastName,
      profilePictureUrl,
      supplierId,
      companyName,
      title,
      email,
      phone,
      mobilePhone,
      address,
      contacts,
      isConnected,
      isPrivate,
      colleagueIsConnected,
      contactFor,
      onClickMessage,
      hasRelationship,
      isBuyer,
      isSupplier,
      isBlocked
    } = this.props

    const { anchorEl } = this.state

    const companyNameWithLink = supplierId ? (
      <Link to={paths.supplierProfile} params={{ supplierId }} className='teal'>
        {companyName}
      </Link>
    ) : (
      companyName
    )

    return (
      <div className='pa3 flex items-start bg-white bb b--light-gray'>
        <div style={{ flexBasis: '4em', minWidth: '4em' }}>
          <Link to={paths.userprofile} params={{ userId }}>
            <Avatar
              url={profilePictureUrl}
              className='w-100'
              name={`${firstName || ''} ${lastName || ''}`}
            />
          </Link>
        </div>

        <div className='flex-auto'>
          <div className='flex items-center flex-wrap justify-between'>
            <div className='flex items-center flex-auto'>
              {(firstName || lastName) && (
                <Link
                  to={paths.userprofile}
                  params={{ userId }}
                  className='ml3'
                >
                  {firstName} {lastName}
                </Link>
              )}

              {user.get('status') &&
                user.get('status').toLowerCase() === 'blocked' && (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id='SearchResultsItem.Inactive'
                        defaultMessage='Inactive'
                      />
                    }
                  >
                    <BlockIcon fontSize='small' className='ml2' />
                  </Tooltip>
                )}

              {isConnected &&
                (isPrivate ? (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id='SearchResultsItem.YourPrivateConnection'
                        defaultMessage='Your private connection'
                      />
                    }
                  >
                    <img
                      src={PrivateConnectIcon}
                      alt='private connection'
                      className='w1 ml2'
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id='SearchResultsItem.YourSharedConnection'
                        defaultMessage='Your shared connection'
                      />
                    }
                  >
                    <img
                      src={SingleConnectionIcon}
                      alt='you connection'
                      className='w1 ml2'
                    />
                  </Tooltip>
                ))}

              {colleagueIsConnected && (
                <Tooltip
                  title={
                    <FormattedMessage
                      id='SearchResultsItem.ColleaguesConnection'
                      defaultMessage='Colleagues connection'
                    />
                  }
                >
                  <img
                    src={MultiConnectionIcon}
                    alt='colleagues connection'
                    className='w1 ml2'
                  />
                </Tooltip>
              )}

              {isBuyer && (
                <Tooltip
                  title={
                    <FormattedMessage
                      id='SearchResultsItem.Buyer'
                      defaultMessage='Buyer'
                    />
                  }
                >
                  <img src={BuyerIcon} alt='buyer' className='w1 ml2' />
                </Tooltip>
              )}
              {isSupplier && (
                <Tooltip
                  title={
                    <FormattedMessage
                      id='SearchResultsItem.Supplier'
                      defaultMessage='Supplier'
                    />
                  }
                >
                  <img src={SupplierIcon} alt='supplier' className='w1 ml2' />
                </Tooltip>
              )}
            </div>
            <div className='flex items-center flex-auto justify-end'>
              <div className='w3 tr pointer' onClick={this.onOpenPopover}>
                {contacts &&
                  contacts
                    .take(3)
                    .map(contact => (
                      <UserStackItem
                        key={contact.get('user')}
                        profilePictureUrl={contact.get('profilePictureUrl')}
                        mini
                        firstName={contact.get('firstName')}
                        lastName={contact.get('lastName')}
                      />
                    ))}
                {contacts && contacts.size > 3 && (
                  <div
                    className='bg-light-gray mid-gray br-100 ba b--black-10 f9 fw4 tc dib w1 h1 v-mid'
                    style={{ lineHeight: '14px' }}
                  >
                    {contacts.size - 3}
                  </div>
                )}
              </div>

              {isConnected ? (
                <Button
                  label={
                    <FormattedMessage
                      id='SearchResultsItem.Disconnect'
                      defaultMessage='Disconnect'
                    />
                  }
                  autoSize
                  secondary
                  size='small'
                  className='ml2'
                  onClick={this.handleDisconnect}
                />
              ) : (
                !isBlocked && (
                  <Button
                    label={
                      <FormattedMessage
                        id='SearchResultsItem.Connect'
                        defaultMessage='Connect'
                      />
                    }
                    autoSize
                    size='small'
                    className='ml2'
                    onClick={this.handleConnect}
                  />
                )
              )}

              {!isBlocked && (
                <Button
                  onClick={onClickMessage}
                  secondary
                  autoSize
                  size='small'
                  style={{ paddingTop: '.35rem', paddingBottom: '.25rem' }}
                  className='ml2'
                  label={
                    <img src={MessagesIcon} alt='message' className='w1' />
                  }
                />
              )}
            </div>
          </div>

          <div className='gray f6 mv1 ml3'>
            {title || companyNameWithLink}
            {title && companyName && (
              <Fragment>
                {' '}
                <FormattedMessage
                  id='SearchResultsItem.At'
                  defaultMessage='at {companyNameWithLink}'
                  values={{ companyNameWithLink }}
                />
              </Fragment>
            )}
            {hasRelationship && (
              <Tooltip
                title={
                  <FormattedMessage
                    id='SearchResultsItem.Existing'
                    defaultMessage='Existing'
                  />
                }
              >
                <ExistingIcon className='w1 ml2' />
              </Tooltip>
            )}
          </div>

          {!!email &&
            (isBlocked ? (
              <div className='flex items-center f6 fw4 gray truncate ml3'>
                <EmailIcon style={{ width: '.875rem' }} />
                <span className={`ml2 f6 fw4 strike`}>{email}</span>
              </div>
            ) : (
              <a className={`no-underline pointer`} href={`mailto:${email}`}>
                <div className='flex items-center f6 fw4 gray truncate dim ml3'>
                  <EmailIcon style={{ width: '.875rem' }} />
                  <span className={`ml2 teal`}>{email}</span>
                </div>
              </a>
            ))}

          {!!phone && (
            <div className='flex items-center f6 fw4 gray ml3'>
              <PhoneIcon style={{ width: '.875rem' }} />
              <span className='ml2'>{phone}</span>
            </div>
          )}

          {!!mobilePhone && (
            <div className='flex items-center f6 fw4 gray ml3'>
              <IphoneIcon style={{ width: '.875rem' }} />
              <span className='ml2'>{mobilePhone}</span>
            </div>
          )}

          {!!address && (
            <div className='flex items-center f6 fw4 gray ml3'>
              <LocationIcon style={{ width: '.875rem' }} />
              <Address noIcon location={address} className='ml2 dib' />
            </div>
          )}

          {contactFor && (
            <div className='flex items-center f6 fw4 gray ml3 mt2'>
              <FormattedMessage
                id='SearchResultsItem.ConnectFor'
                defaultMessage='Contact for {contactFor}'
                values={{ contactFor }}
              />
            </div>
          )}
        </div>
        <Popover
          open={Boolean(anchorEl)}
          onClose={this.onClosePopover}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <div className='dib pv1 ph2 lh-copy f7 fw4 mid-gray'>
            {contacts &&
              contacts.map(contact => (
                <div key={contact.get('id')} className='w-auto mb1'>
                  <Link
                    to={paths.userprofile}
                    params={{ userId: contact.get('id') }}
                  >
                    <Avatar
                      url={contact.get('profilePictureUrl')}
                      className='w1-5 ba b--white bw1 mr1 v-mid dib'
                      name={`${contact.get('firstName') || ''} ${contact.get(
                        'lastName'
                      ) || ''}`}
                    />
                    <div className='v-mid dib'>
                      <Text className='hover-teal'>
                        {contact.get('firstName')} {contact.get('lastName')}
                      </Text>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </Popover>
      </div>
    )
  }
}

export default SearchResultsItem
