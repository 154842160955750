import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import RadioButtonGroup from 'shared/components/RadioButton/RadioButtonGroup'
import RadioButton from 'shared/components/RadioButton'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { logout, deleteData } from 'shared/actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import Warning from 'shared/components/Warning'
import RootState from 'shared/models/RootState'
import Text from 'shared/components/Text'
import adminPaths from 'admin/routes/paths'
import { useHistory } from 'react-router'

type Props = {
  open: boolean
  onClose: () => void
  userId?: string
  hardDelete?: boolean
}

export const DeleteAccount = ({
  userId,
  hardDelete,
  open = false,
  onClose
}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentUserId = useSelector(sessionSelectors.getUserId)
  const user = useSelector((state: RootState) =>
    usersSelectors.getById(state, userId || '')
  )
  const email = user?.get('email')
  const [deleteUserConfirmTextfield, setDeleteUserConfirmTextfield] = useState(
    ''
  )
  const [deleteReason, setDeleteReason] = useState('')
  const [deleteReasonOther, setDeleteReasonOther] = useState('')

  const disableDeleteUserButton =
    deleteUserConfirmTextfield !== email ||
    !deleteReason ||
    (deleteReason === 'Other' && !deleteReasonOther)

  const handleChangeDeleteReasonOther = e => {
    setDeleteReasonOther(e.currentTarget.value)
  }

  const handleChangeConfirmUserDelete = e => {
    setDeleteUserConfirmTextfield(e.currentTarget.value)
  }

  const handleChangeDeleteReason = e => {
    setDeleteReason(e.currentTarget.value)
    setDeleteReasonOther('')
  }

  const handleSubmit = e => {
    e.stopPropagation()
    e.preventDefault()
    if (!userId || disableDeleteUserButton) {
      return
    }
    onClose()
    setDeleteReason('')
    setDeleteReasonOther('')
    setDeleteUserConfirmTextfield('')
    dispatch(
      deleteData({
        entity: 'users',
        recordId: userId,
        query: {
          reason: deleteReason === 'Other' ? deleteReasonOther : deleteReason,
          hardDelete
        },
        onSuccess: () => {
          if (userId === currentUserId) {
            dispatch(logout())
          } else {
            history.push(adminPaths.users)
          }
        }
      })
    )
  }

  if (!userId) {
    return null
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>
        <FormattedMessage
          id='EditUserProfile.DeletingUser'
          defaultMessage='Deleting user'
        />
      </DialogTitle>
      <DialogContent className='mt3'>
        <form onSubmit={handleSubmit}>
          <RadioButtonGroup
            title={
              userId === currentUserId ? (
                <FormattedMessage
                  id='EditUserProfile.WhyDeletingAccount'
                  defaultMessage='Why are you deleting your account?'
                />
              ) : (
                <FormattedMessage
                  id='EditUserProfile.WhyDeletingThisAccount'
                  defaultMessage='Why are you deleting this account?'
                />
              )
            }
            stack
          >
            <RadioButton
              required={!deleteReason}
              label={
                userId === currentUserId ? (
                  <FormattedMessage
                    id='EditUserProfile.LeavingCompany'
                    defaultMessage='I am leaving this company'
                  />
                ) : (
                  <FormattedMessage
                    id='EditUserProfile.LeavingThisCompany'
                    defaultMessage='No longer with this company'
                  />
                )
              }
              value='I am leaving this company'
              name='deleteReason'
              onChange={handleChangeDeleteReason}
            />
            <RadioButton
              required={!deleteReason}
              label={
                userId === currentUserId ? (
                  <FormattedMessage
                    id='EditUserProfile.NoLongerUseEmail'
                    defaultMessage='I no longer use this email address'
                  />
                ) : (
                  <FormattedMessage
                    id='EditUserProfile.NoLongerUseThisEmail'
                    defaultMessage='No longer use this email address'
                  />
                )
              }
              value='I no longer use this email address'
              name='deleteReason'
              onChange={handleChangeDeleteReason}
            />
            <RadioButton
              required={!deleteReason}
              label={
                <FormattedMessage
                  id='EditUserProfile.RightToBeForgotten'
                  defaultMessage='Exercise the right to be forgotten'
                />
              }
              value='Exercise the right to be forgotten'
              name='deleteReason'
              onChange={handleChangeDeleteReason}
            />
            <RadioButton
              required={!deleteReason}
              label={
                <FormattedMessage
                  id='EditUserProfile.Other'
                  defaultMessage='Other'
                />
              }
              value='Other'
              name='deleteReason'
              onChange={handleChangeDeleteReason}
            />
          </RadioButtonGroup>

          {deleteReason === 'Other' && (
            <>
              <Label htmlFor='deleteReasonOther'>
                <FormattedMessage
                  id='EditUserProfile.OtherExplain'
                  defaultMessage='If other, please help us improve by sharing your reason:'
                />
              </Label>
              <Input
                name='deleteReasonOther'
                onChange={handleChangeDeleteReasonOther}
                required
              />
            </>
          )}

          <Label htmlFor='confirmEmail'>
            {userId === currentUserId ? (
              <FormattedMessage
                id='EditUserProfile.EnterEmailToConfirm'
                defaultMessage='Please enter your email to confirm.'
              />
            ) : (
              <FormattedMessage
                id='EditUserProfile.EnterThisEmailToConfirm'
                defaultMessage='Please enter his/her email to confirm.'
              />
            )}
          </Label>
          <Text>{email}</Text>
          <Input
            name='confirmEmail'
            onChange={handleChangeConfirmUserDelete}
            required
          />
          <Warning
            className='mt3'
            message={
              userId === currentUserId ? (
                <FormattedMessage
                  id='DeleteAccount.loggedOut'
                  defaultMessage='You will not be able to Log in again'
                />
              ) : (
                <FormattedMessage
                  id='DeleteAccount.loggedThisOut'
                  defaultMessage='The user will not be able to Log in again'
                />
              )
            }
          />

          <div className='mv3'>
            <Button
              type='submit'
              label={
                <FormattedMessage
                  id='EditUserProfile.Delete'
                  defaultMessage='Delete'
                />
              }
              disabled={disableDeleteUserButton}
              autoSize
              style={{
                backgroundColor: disableDeleteUserButton
                  ? ''
                  : 'rgb(220, 0, 78)'
              }}
            />
            <Button
              label={<FormattedMessage id='CancelButton' />}
              autoSize
              secondary
              onClick={onClose}
              className='ml3'
            />
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteAccount
