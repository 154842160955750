import { call, fork } from 'redux-saga/effects'
import { LOGOUT } from '../../actionTypes'
import takeFirst from '../../utils/sagas/takeFirst'
import logoutSaga from '../logoutSaga'
import validateTokenSaga from '../validateTokenSaga'
import autoRefreshTokensSaga from './autoRefreshTokensSaga'
import inactiveLogoutSaga from './inactiveLogoutSaga'

export default function* authSaga() {
  yield fork(autoRefreshTokensSaga)
  yield fork(inactiveLogoutSaga)
  yield call(validateTokenSaga)
  yield takeFirst(LOGOUT, logoutSaga)
}
