import React, { Component } from 'react'
import { connect } from 'react-redux'
import VetHeader from '../../components/VetHeader'
import { loadVet } from '../../actions'
import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import qs from 'qs'
import Link from 'shared/components/Link'
import paths from '../../../routes/paths'

export class VetHeaderContainer extends Component {
  componentDidMount() {
    this.props.onLoadVet(this.props.match.params.vetBuyerSupplierId)
  }

  render() {
    const {
      vet,
      vetBuyerSupplier,
      companyName,
      redirectFrom,
      isLoading
    } = this.props
    return !isLoading && vet.size ? (
      <VetHeader
        vetBuyerSupplierId={vetBuyerSupplier.get('id')}
        vetName={vet.get('name')}
        vetCreatedBy={
          vet.getIn(['vetDetails', 'anonymousInvitation']) ? (
            'Anonymous'
          ) : (
            <Link
              to={paths.clientProfile}
              params={{ orgUnitId: vet.getIn(['parents', 'OrgUnit']) }}
            >
              <h5 className='dib f7 fw4 dim teal ma0'>{companyName}</h5>
            </Link>
          )
        }
        vetResponseBy={vet.getIn(['vetDetails', 'responseDate'])}
        vetStatus={vetBuyerSupplier.getIn(['collaboration', 'status'])}
        redirectFrom={redirectFrom}
      />
    ) : null
  }
}

export default connect(
  (state, props) => {
    const {
      location: { search }
    } = props
    const queryParams = qs.parse(search, { ignoreQueryPrefix: true })

    return {
      isLoading: vetBuyerSupplierSelectors.getIsLoadingVet(state),
      redirectFrom: queryParams.redirectFrom,
      vet: vetBuyerSupplierSelectors.getVet(state),
      vetBuyerSupplier: vetBuyerSupplierSelectors.getVetBuyerSupplier(state),
      companyName: vetBuyerSupplierSelectors.getVetCompanyName(state)
    }
  },
  {
    onLoadVet: loadVet
  }
)(VetHeaderContainer)
