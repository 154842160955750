import { RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'

export type EcovadisCredentials = RecordOf<{
  reportEnabled: boolean
  credentialsSubmitted: boolean
  initialAssessmentLink: boolean | Date
  username?: string
}>

export const getIntegrations = (state: RootState) =>
  settingsSelectors.getField(state, 'integrations')

export const getEcovadisCredentials = (state: RootState) =>
  getIntegrations(state)?.get('ecovadis')

export const getEcovadisReportEnabled = (state: RootState) =>
  getEcovadisCredentials(state)?.get('reportEnabled') || false

export const getByField = (state: RootState, fieldName: string) =>
  state.getIn(['admin', 'integrations', fieldName])
