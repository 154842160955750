import React, { Component } from 'react'
import { connect } from 'react-redux'
import SortBy from '../../components/SortBy'
import { withRouter } from 'react-router'
import qs from 'qs'
import { RouteComponentProps } from 'react-router'

type Props = {
  sort: any
} & RouteComponentProps

class SortByContainer extends Component<Props> {
  handleChange = e => {
    const { history } = this.props
    const query = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    history.push({
      search: qs.stringify({
        ...query,
        sort: e.currentTarget.value
      })
    })
  }

  render() {
    return <SortBy value={this.props.sort} onChange={this.handleChange} />
  }
}

type ContainerProps = { location: { search: string } }
export default withRouter(
  connect((state, props: ContainerProps) => {
    const { sort } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    })

    return {
      sort
    }
  })(SortByContainer)
)
