import React, { Component, Fragment } from 'react'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import { Field } from 'redux-form/immutable'
import Text from 'shared/components/Text'
import Checkbox from 'shared/components/Checkbox'
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape
} from 'react-intl'
import Loading from 'shared/components/Loading'
import OrgUnitPicker from 'shared/components/OrgUnitPicker'
import { List, RecordOf } from 'immutable'
import OrgUnit from 'shared/models/OrgUnit'
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth'
import DialogActions from '../../../../shared/components/DialogActions/DialogActions'

const messages = defineMessages({
  BLACKLISTED_DOMAIN: {
    id: 'InviteSupplierForm.BLACKLISTED_DOMAIN',
    defaultMessage:
      'We were unable to add the supplier you requested because the domain has been blacklisted.'
  },
  DOMAIN_FOR_SALE: {
    id: 'InviteSupplierForm.DOMAIN_FOR_SALE',
    defaultMessage:
      'We were unable to add the supplier you requested because the domain has been listed for sale.'
  },
  INAPPROPRIATE_CONTENT_DOMAIN: {
    id: 'InviteSupplierForm.INAPPROPRIATE_CONTENT_DOMAIN',
    defaultMessage:
      'We were unable to add the supplier you requested because the domain has potentially inappropriate content.'
  },
  UNKNOWN_REASON: {
    id: 'InviteSupplierForm.UNKNOWN_REASON',
    defaultMessage:
      'We were unable to add the supplier you requested for an unknown reason.'
  },
  UNREACHABLE_DOMAIN: {
    id: 'InviteSupplierForm.UNREACHABLE_DOMAIN',
    defaultMessage:
      'We were unable to add the supplier you requested because the domain was unreachable or timed out.'
  }
})

type Props = {
  hasSubmitted: boolean
  isSubmitting: boolean
  addingCustomer: boolean
  messageSent: boolean
  matchingOrgs: List<RecordOf<OrgUnit>>
  contactEmail: string
  errorMessage: string
  newSupplierCreated: () => void
  onClickNoneOfTheAbove: () => void
  onClickConciergeTask: () => void
  onClickEditFields: (params: any) => void
  onClickMatchingOrg?: (orgUnitId?: string) => void
  onCancel: () => void
  onSubmit: () => void
  intl: IntlShape
} & WithWidthProps

class InviteSupplierForm extends Component<Props> {
  validateContactWebsite = (value, allValues, props, name) => {
    if (!value && !allValues.get('contactEmail')) {
      return (
        <FormattedMessage
          id='InviteSupplierForm.AtLeastOneOfTheseValuesIsRequired'
          defaultMessage='At least one of these values is required.'
        />
      )
    }
  }

  validateContactEmail = (value, allValues, props, name) => {
    if (!value && !allValues.get('companyWebsite')) {
      return (
        <FormattedMessage
          id='InviteSupplierForm.AtLeastOneOfTheseValuesIsRequired'
          defaultMessage='At least one of these values is required.'
        />
      )
    }
  }

  render() {
    const {
      onCancel,
      onSubmit,
      hasSubmitted,
      isSubmitting,
      messageSent,
      errorMessage,
      newSupplierCreated,
      matchingOrgs,
      addingCustomer,
      onClickNoneOfTheAbove,
      onClickMatchingOrg,
      onClickConciergeTask,
      onClickEditFields,
      contactEmail,
      width,
      intl
    } = this.props
    const matchMobile = width && ['xs', 'sm'].includes(width)
    return (
      <form onSubmit={onSubmit}>
        {isSubmitting ? (
          <Loading className='tc mt3' />
        ) : !hasSubmitted && !errorMessage ? (
          <Fragment>
            {!addingCustomer && (
              <Text>
                <FormattedMessage
                  id='InviteSupplierForm.ThankYouForTakingTheTimeToInviteASupplierToTealbook'
                  defaultMessage='Thank you for taking the time to invite a supplier to TealBook. Please help us with some key information.'
                />
              </Text>
            )}

            <Label required>
              <FormattedMessage
                id='InviteSupplierForm.CompanyName'
                defaultMessage='Company Name'
              />
            </Label>
            <Field name='companyName' component={Input} required />

            <div className='dib w-100 w-40-ns v-top'>
              <Label required htmlFor='companyWebsite'>
                <FormattedMessage
                  id='InviteSupplierForm.CompanyWebsite'
                  defaultMessage='Company Website'
                />
              </Label>
              <Field
                name='companyWebsite'
                component={Input}
                validate={this.validateContactWebsite}
              />
            </div>

            <div className='dib w-100 w-20-ns tc'>
              <Label>
                <FormattedMessage
                  id='InviteSupplierForm.Or'
                  defaultMessage='or'
                />
              </Label>
            </div>

            <div className='dib w-100 w-40-ns v-top'>
              <Label required>
                <FormattedMessage
                  id='InviteSupplierForm.CompanyContactEmail'
                  defaultMessage='Company Contact Email'
                />
              </Label>
              <Field
                name='contactEmail'
                type='email'
                component={Input}
                validate={this.validateContactEmail}
              />
            </div>

            {contactEmail && (
              <Fragment>
                <Label>
                  <FormattedMessage
                    id='InviteSupplierForm.CompanyContactName'
                    defaultMessage='Company Contact Name (optional)'
                  />
                </Label>
                <Field name='contactName' component={Input} />
              </Fragment>
            )}

            {!addingCustomer && (
              <div className='mt3'>
                <Field
                  name='autoConnect'
                  component={Checkbox}
                  type='checkbox'
                  label={
                    <FormattedMessage
                      id='InviteSupplierForm.IWouldLikeToAutomaticallyConnectToThisSupplier'
                      defaultMessage='I would like to automatically connect to this supplier once they have been added to TealBook.'
                    />
                  }
                />
              </div>
            )}
            <DialogActions className='mt4-ns fixed static-ns bottom-2'>
              <Button
                size={matchMobile ? 'large' : undefined}
                type='submit'
                label={
                  addingCustomer ? (
                    <FormattedMessage
                      id='InviteSupplierForm.Add'
                      defaultMessage='Add'
                    />
                  ) : (
                    <FormattedMessage
                      id='InviteSupplierForm.Invite'
                      defaultMessage='Invite'
                    />
                  )
                }
                autoSize
                className='mr3'
              />
              <Button
                size={matchMobile ? 'large' : undefined}
                onClick={onCancel}
                label={<FormattedMessage id='CancelButton' />}
                secondary
                autoSize
              />
            </DialogActions>
          </Fragment>
        ) : (
          <Fragment>
            {!hasSubmitted && errorMessage ? (
              <Fragment>
                <Label className='mt3'>
                  {messages[errorMessage]
                    ? intl.formatMessage(messages[errorMessage])
                    : errorMessage}
                  :
                </Label>
                <DialogActions className='mt4-ns fixed static-ns bottom-2'>
                  <Button
                    size={matchMobile ? 'large' : undefined}
                    onClick={onClickConciergeTask}
                    label={
                      <FormattedMessage
                        id='InviteSupplierForm.AskForHelp'
                        defaultMessage='Ask for Help'
                      />
                    }
                    autoSize
                    className='mr3'
                  />
                  <Button
                    size={matchMobile ? 'large' : undefined}
                    onClick={onClickEditFields}
                    label={
                      <FormattedMessage
                        id='InviteSupplierForm.TryAgain'
                        defaultMessage='Try Again'
                      />
                    }
                    autoSize
                    secondary
                  />
                </DialogActions>
              </Fragment>
            ) : (
              <>
                {(!matchingOrgs || matchingOrgs.size < 1) && (
                  <Fragment>
                    {addingCustomer ? (
                      <Text className='mt3'>
                        <FormattedMessage
                          id='InviteSupplierForm.TheCustomerHasBeenAdded'
                          defaultMessage='The customer has been added'
                        />
                      </Text>
                    ) : (
                      <Text className='mt3'>
                        {messageSent ? (
                          <FormattedMessage
                            id='InviteSupplierForm.ThankYouForSendingAMessage'
                            defaultMessage='Thank you for sending a message. We will get back to you within 1 business day.'
                          />
                        ) : (
                          <FormattedMessage
                            id='InviteSupplierForm.ThankYouForInvitingASupplierToTealbook'
                            defaultMessage='Thank you for inviting a supplier to TealBook. We will send you a notification when they have been added.'
                          />
                        )}
                      </Text>
                    )}
                    <Button
                      onClick={onCancel}
                      label={
                        <FormattedMessage
                          id='InviteSupplierForm.Done'
                          defaultMessage='Done'
                        />
                      }
                      autoSize
                      className='mt4'
                    />
                  </Fragment>
                )}

                {matchingOrgs && matchingOrgs.size > 0 && (
                  <Fragment>
                    <Text className='mt3'>
                      <FormattedMessage
                        id='InviteSupplierForm.WeHaveFoundTheOrganizationYouWereLookingFor'
                        defaultMessage='We have found the organization you were looking for.
                          Please select one of the following.'
                      />
                    </Text>
                    <OrgUnitPicker
                      orgs={matchingOrgs}
                      onClick={onClickMatchingOrg}
                    />

                    {!newSupplierCreated && (
                      <DialogActions className='mt4-ns fixed static-ns bottom-2'>
                        <Button
                          secondary
                          label={
                            <FormattedMessage
                              id='InviteSupplierForm.NoneOfTheAbove'
                              defaultMessage='None of the above'
                            />
                          }
                          onClick={onClickNoneOfTheAbove}
                        />
                      </DialogActions>
                    )}
                  </Fragment>
                )}
              </>
            )}
          </Fragment>
        )}
      </form>
    )
  }
}

export default withWidth()(injectIntl(InviteSupplierForm))
