import { call, select } from 'redux-saga/effects'
import profileSelectors from '../../selectors/profileSelectors'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import updateCardSaga from '../updateCardSaga'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import camelCase from 'lodash.camelcase'

export function* addPersonalRelationshipSaga(action) {
  const { fieldName, newTag, supplierId } = action.payload
  // get current supplier and corresponding card
  const currentSupplierId = !supplierId
    ? yield select(profileSelectors.getDataField, 'id')
    : supplierId
  const currentSupplierCard = yield select(
    cardsSelectors.getBySupplier,
    currentSupplierId
  )
  const attributes = currentSupplierCard.get(fieldName)
  const cardTags = attributes ? attributes.toJS() : {}

  cardTags[camelCase(newTag)] = newTag.trim()

  return yield call(updateCardSaga, {
    supplierId: currentSupplierId,
    cardId: currentSupplierCard ? currentSupplierCard.get('id') : undefined,
    cardChanges: {
      [fieldName]: cardTags
    }
  })
}

export default createApiRequestSaga(addPersonalRelationshipSaga)
