import { connect } from 'react-redux'
import { updateParentOrg, saveCompanyInfo } from '../../actions'
import companySelectors from '../../../shared/selectors/companySelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import CorporateSection from '../../components/CorporateSection'

export default connect(
  state => {
    const parentOrg = companySelectors.getParentOrg(state)
    const OrgId = companySelectors.getOrgId(state)
    const user = sessionSelectors.getUser(state)
    const isTealbot = user && user.get('roles').includes('tealbot')
    const isEditable = OrgId === (parentOrg && parentOrg.get('id'))

    return {
      name: (parentOrg && parentOrg.get('name')) || '',
      numberOfEmployees: (parentOrg && parentOrg.get('employeeNumber')) || '',
      yearFounded: (parentOrg && parentOrg.get('yearFounded')) || '',
      annualRevenue: (parentOrg && parentOrg.get('yearlyRevenue')) || '',
      dunsNumber: companySelectors.getCompanySupplierField(state, 'dunsNumber'),
      taxId: companySelectors.getCompanySupplierField(state, 'taxId'),
      lei: companySelectors.getCompanySupplierField(state, 'lei'),
      naics: companySelectors.getClassificationCodesBySchema(state, 'naics'),
      isTealbot,
      isEditable
    }
  },
  {
    onUpdateParentOrg: updateParentOrg,
    onUpdateSupplier: saveCompanyInfo
  }
)(CorporateSection)
