import { put, delay, select, race, take } from 'redux-saga/effects'
import { logout } from '../../actions'
import { requestSuccess } from '../../actionTypes'
import { GET_SETTINGS } from 'buyer/shared/actionTypes'
import sessionSelectors from 'shared/selectors/sessionSelectors'

export default function* inactiveLogoutSaga() {
  let minutesToDelay = 0

  while (true) {
    const { userIsInactive, orgSettings } = yield race({
      userIsInactive: delay((minutesToDelay || 30) * 60 * 1000),
      orgSettings: take(requestSuccess(GET_SETTINGS)),
      userIsActive: take('*')
    })

    if (orgSettings?.payload.inactiveLogoutTimeout) {
      const user = yield select(sessionSelectors.getUser)
      minutesToDelay = user?.get('roles')?.includes('tealbot')
        ? 0
        : orgSettings.payload.inactiveLogoutTimeout
    } else if (userIsInactive) {
      const user = yield select(sessionSelectors.getUser)
      if (
        user &&
        (minutesToDelay > 0 || user.get('roles')?.includes('tealbot'))
      ) {
        minutesToDelay = 0
        yield put(logout())
      }
    }
  }
}
