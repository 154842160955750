import { Component } from 'react'
import createRoutes from './routes/createRoutes'

class SupplierApp extends Component {
  render() {
    return createRoutes()
  }
}

export default SupplierApp
