import React, { Component, Fragment } from 'react'
import InsightPaper from 'shared/components/InsightPaper'
import InsightTitle from 'shared/components/InsightTitle'
import Scrollable from 'shared/components/Scrollable'

type Props = {
  maxHeight?: number
  title: string | React.ReactNode
}

export class InsightWidget extends Component<Props> {
  render() {
    const { children, maxHeight = 300, title } = this.props

    return (
      <Fragment>
        <InsightTitle>{title}</InsightTitle>
        <InsightPaper>
          <Scrollable maxHeight={maxHeight}>
            <div style={{ minHeight: maxHeight - 2 }}>{children}</div>
          </Scrollable>
        </InsightPaper>
      </Fragment>
    )
  }
}

export default InsightWidget
