import { call, all } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import apiRoutes from 'shared/routes/apiRoutes'
import api from 'shared/utils/api'

export function* bulkDeleteUsersSaga(action) {
  const { listOfUserIds, query } = action.payload

  yield all(
    listOfUserIds.map(id =>
      call(api.remove, `${apiRoutes.authService}/users/${id}`, undefined, query)
    )
  )

  return yield {
    listOfUserIds
  }
}

export default createApiRequestSaga(bulkDeleteUsersSaga)
