import React from 'react'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import ForwardSurveyRequestDialog from '../../components/ForwardSurveyRequestDialog'
import companySelectors from '../../../supplier/shared/selectors/companySelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import { messageUser } from 'shared/actions'
import {
  openAddCollaborateContactForm,
  cancelAddingSurveyCollaborateContact,
  addSurveyCollaborateContact
} from '../../../supplier/shared/actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'

type Props = {
  contacts: any
  supplierId: string
  messageToForward: string
  messageUser: (arg: string) => void
  onClickNewUser: () => void
  onClickCancel: () => void
  addSurveyCollaborateContact: (user: { id: string }) => void
}

const ForwardSurveyRequestDialogContainer = (props: Props) => {
  return (
    <ForwardSurveyRequestDialog
      onMessageUser={props.messageUser}
      shareESGSurveyLink={true}
      onAddSurveyCollaborateContact={props.addSurveyCollaborateContact}
      {...props}
    />
  )
}

export default connect(
  (state: RootState) => {
    const currentUserId = sessionSelectors.getUserId(state)
    const contacts = usersSelectors.getColleaguesExcludingUser(
      state,
      currentUserId
    )
    const supplierId = companySelectors.getOrgId(state)
    return {
      contacts,
      supplierId
    }
  },
  {
    messageUser,
    onClickNewUser: openAddCollaborateContactForm,
    onClickCancel: cancelAddingSurveyCollaborateContact,
    addSurveyCollaborateContact: addSurveyCollaborateContact
  }
)(ForwardSurveyRequestDialogContainer)
