import { put, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { inviteToCommunities } from '../../actions'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import Immutable, { fromJS } from 'immutable'

export function* batchInviteToCommunitiesSaga(action) {
  const { communities, supplierIds } = Immutable.isImmutable(action.payload)
    ? action.payload.toJS()
    : action.payload

  for (let i = 0; i < supplierIds.length; i++) {
    const supplierCommunityIds = yield select(
      communitiesSelectors.getBySupplierId,
      supplierIds[i]
    )
    let copyCommunities
    if (supplierCommunityIds) {
      copyCommunities = Object.assign({}, communities)
      supplierCommunityIds.toArray().forEach(commId => {
        copyCommunities[commId] = false
      })
    }
    yield put(
      inviteToCommunities(
        fromJS({
          communities: copyCommunities || communities,
          supplierId: supplierIds[i]
        })
      )
    )
  }
}

export default createApiRequestSaga(batchInviteToCommunitiesSaga)
