import React, { useState, ChangeEvent, FormEvent } from 'react'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/BorderColor'
import camelCase from 'lodash.camelcase'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'

const messages = defineMessages({
  placeholderCategory: {
    id: 'CategoriesJsonEditor.PlaceholderCategory',
    defaultMessage: 'Enter a new category.'
  },
  placeholderSubCategory: {
    id: 'CategoriesJsonEditor.PlaceholderSubCategories',
    defaultMessage: 'Enter a list of subcategory separated by a comma.'
  }
})

type Props = {
  fieldName: string
  value: string
  onUpdate: (value) => void
  isReadonly?: boolean
}

const CategoriesJsonEditor = (props: Props) => {
  const intl = useIntl()
  const [newCategory, setNewCategory] = useState<string>('')
  const [newSubCategories, setNewSubCategories] = useState<string>('')
  const [editCategory, setEditCategory] = useState<string>('')
  const [editSubCategories, setEditSubCategories] = useState<string>('')

  const [categories, setCategories] = useState<
    | {
        categoryId: string
        name: string
        subCategories: Array<{
          categoryId: string
          name: string
        }>
      }[]
    | undefined
  >(() => {
    try {
      const valueJson = JSON.parse(props.value)
      return valueJson
    } catch (e) {
      console.error(e)
    }
  })

  const [removingId, setRemovingId] = useState<string | undefined>(undefined)
  const [editingId, setEditingId] = useState<string | undefined>(undefined)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const key = camelCase(newCategory)
    const subCategories = newSubCategories.split(',').map(s => {
      return {
        categoryId: `${key}-${camelCase(s)}`,
        name: s.trim()
      }
    })
    const newCategories = categories
      ? [...categories, { categoryId: key, name: newCategory, subCategories }]
      : [{ categoryId: key, name: newCategory, subCategories }]

    props.onUpdate({
      [props.fieldName]: newCategories
    })
    setCategories(newCategories)
    setNewCategory('')
    setNewSubCategories('')
  }

  const handleEditClick = (categoryId: string) => {
    const category = categories?.find(
      category => category.categoryId === categoryId
    )
    if (category) {
      setEditCategory(category.name)
      setEditSubCategories(
        category?.subCategories?.map(subCategory => subCategory.name).join(', ')
      )
      setEditingId(categoryId)
    }
  }

  const handleUpdateClick = () => {
    if (categories) {
      const categoryIndex = categories?.findIndex(
        category => category.categoryId === editingId
      )
      if (categoryIndex !== -1) {
        categories[categoryIndex] = {
          categoryId: camelCase(editCategory),
          name: editCategory,
          subCategories: [
            ...editSubCategories.split(',').map(sub => ({
              categoryId: camelCase(sub),
              name: sub.trim()
            }))
          ]
        }
        const newCategories = [...categories]
        props.onUpdate({
          [props.fieldName]: newCategories
        })
      }
    }

    setEditCategory('')
    setEditSubCategories('')
    setEditingId(undefined)
  }

  const handleRemoveClick = (categoryId: string) => {
    setRemovingId(categoryId)
  }

  const removeCategory = () => {
    if (categories) {
      const categoryIndex = categories?.findIndex(
        category => category.categoryId === removingId
      )
      if (categoryIndex !== -1) {
        categories.splice(categoryIndex, 1)
        const newCategories = [...categories]
        props.onUpdate({
          [props.fieldName]: newCategories
        })
      }
    }

    setRemovingId(undefined)
  }

  const handleCancelClick = () => {
    setEditCategory('')
    setEditSubCategories('')
    setEditingId(undefined)
  }

  return (
    <>
      {!props.isReadonly && (
        <form onSubmit={handleSubmit}>
          <div className='flex items-top justify-between mt1'>
            <Input
              style={{ width: 200 }}
              value={newCategory}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setNewCategory(e.currentTarget.value)
              }
              placeholder={intl.formatMessage(messages.placeholderCategory)}
            />
            <div className='flex-auto flex items-top justify-between'>
              <div className='flex-auto ml2'>
                <TextArea
                  height={130}
                  value={newSubCategories}
                  placeholder={intl.formatMessage(
                    messages.placeholderSubCategory
                  )}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    setNewSubCategories(e.currentTarget.value)
                  }
                />
              </div>
              <Button
                autoSize
                type='submit'
                className='ml2'
                disabled={
                  !newCategory || !newSubCategories || editingId !== undefined
                }
              >
                <FormattedMessage id='Add' defaultMessage='Add' />
              </Button>
            </div>
          </div>
        </form>
      )}
      <div className='mt2 br1 ba b--black-10'>
        <Table stickyHeader size='small'>
          <TableHead className='bg-light-gray pv3'>
            <TableRow>
              <TableCell style={{ width: 200 }}>
                <FormattedMessage
                  id='CategoriesJsonEditor.Category'
                  defaultMessage='Category'
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id='CategoriesJsonEditor.SubCategories'
                  defaultMessage='Subcategories'
                />
              </TableCell>
              {!props.isReadonly && (
                <TableCell style={{ width: 100 }}>&nbsp;</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {categories
              ?.sort((cat1, cat2) => (cat1.name > cat2.name ? 1 : -1))
              .map(category => (
                <TableRow key={category.categoryId}>
                  <TableCell align='left' style={{ verticalAlign: 'top' }}>
                    {editingId === category.categoryId ? (
                      <Input
                        wrapperClassName='w-100'
                        value={editCategory}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setEditCategory(e.currentTarget.value)
                        }
                      />
                    ) : (
                      <Text>{category.name}</Text>
                    )}
                  </TableCell>
                  <TableCell align='left' style={{ verticalAlign: 'top' }}>
                    {editingId === category.categoryId ? (
                      <TextArea
                        height={130}
                        value={editSubCategories}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                          setEditSubCategories(e.currentTarget.value)
                        }
                      />
                    ) : (
                      <Text>
                        {category.subCategories
                          ?.sort((sub1, sub2) =>
                            sub1.name > sub2.name ? 1 : -1
                          )
                          .map(sub => sub.name)
                          .join(', ')}
                      </Text>
                    )}
                  </TableCell>
                  {!props.isReadonly && (
                    <TableCell align='right' style={{ verticalAlign: 'top' }}>
                      {editingId === undefined ? (
                        <>
                          <EditIcon
                            fontSize='small'
                            onClick={() => handleEditClick(category.categoryId)}
                            className='pointer dim mr2'
                          />
                          <CloseIcon
                            onClick={() =>
                              handleRemoveClick(category.categoryId)
                            }
                            className='pointer dim'
                          />
                        </>
                      ) : editingId === category.categoryId ? (
                        <>
                          <Button
                            size='small'
                            disabled={!editCategory && !editSubCategories}
                            onClick={handleUpdateClick}
                          >
                            <FormattedMessage
                              id='Update'
                              defaultMessage='Update'
                            />
                          </Button>
                          <Button
                            secondary
                            size='small'
                            onClick={handleCancelClick}
                            className='mt2'
                          >
                            <FormattedMessage
                              id='Cancel'
                              defaultMessage='Cancel'
                            />
                          </Button>
                        </>
                      ) : null}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <ConfirmationDialog
        open={removingId !== undefined}
        onClose={() => setRemovingId(undefined)}
        onConfirm={removeCategory}
      >
        <Text>
          <FormattedMessage
            id='CategoriesJsonEditor.ConfirmRemove'
            defaultMessage='Are you sure you want to remove this?'
          />
        </Text>
      </ConfirmationDialog>
    </>
  )
}

export default CategoriesJsonEditor
