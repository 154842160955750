import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core'
import { useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import { getByField } from 'buyer/SupplierProfile/selectors/integrationSelectors/integrationSelectors'

const messages = defineMessages({
  registration: {
    id: 'RequestSupplierRatingProgress.Registration',
    defaultMessage: 'Registration'
  },
  questionnaire: {
    id: 'RequestSupplierRatingProgress.Questionnaire',
    defaultMessage: 'Questionnaire'
  },
  expertAnalysis: {
    id: 'RequestSupplierRatingProgress.ExpertAnalysis',
    defaultMessage: 'Expert Analysis'
  },
  results: {
    id: 'RequestSupplierRatingProgress.Results',
    defaultMessage: 'Results'
  },
  cancelled: {
    id: 'RequestSupplierRatingProgress.Cancelled',
    defaultMessage: 'Cancelled'
  }
})
const CustomConnector = withStyles({
  active: {
    '& $line': {
      backgroundColor: '#00BCAB'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#00BCAB'
    }
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector)

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent'
  },
  stepIcon: {
    color: '#eaeaf0'
  }
}))

const RequestSupplierRatingProgress = () => {
  const intl = useIntl()
  const classes = useStyles()

  const ecovadisAssessmentStatus:
    | 'registration'
    | 'questionnaire'
    | 'expertAnalysis'
    | 'results'
    | 'cancelled' = useSelector((state: RootState) =>
    getByField(state, 'ecovadisAssessmentStatus')
  )

  const stepsActive = [
    'registration',
    'questionnaire',
    'expertAnalysis',
    'results'
  ]

  const stepsCancelled = ['registration', 'cancelled']
  const steps =
    ecovadisAssessmentStatus !== 'cancelled' ? stepsActive : stepsCancelled

  const stepProgress = () => {
    if (ecovadisAssessmentStatus === 'registration') {
      return 0
    } else if (ecovadisAssessmentStatus === 'questionnaire') {
      return 1
    } else if (ecovadisAssessmentStatus === 'expertAnalysis') {
      return 2
    } else if (ecovadisAssessmentStatus === 'cancelled') {
      return 1
    } else {
      return 3
    }
  }

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={stepProgress()}
        alternativeLabel
        style={{ backgroundColor: 'transparent', padding: 0 }}
        connector={<CustomConnector />}
      >
        {steps.map((label, index) => {
          const stepProps = { completed: false }
          const labelProps = { error: false }

          if (label === 'cancelled') {
            labelProps.error = true
          }
          if (index < stepProgress()) {
            stepProps.completed = true
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                {...labelProps}
                StepIconProps={{
                  classes: { root: classes.stepIcon }
                }}
              >
                {intl.formatMessage(messages[label])}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}

export default RequestSupplierRatingProgress
