import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PageSideSection from 'shared/components/PageSideSection'
import ListItem from 'shared/components/List/ListItem'
import OrgLogo from 'shared/components/OrgLogo'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import DatePicker from 'shared/components/DatePicker'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import { FormattedMessage } from 'react-intl'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import dateFormat from 'shared/utils/dateFormat'
import { updateVet } from '../../actions'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import fileToUrlImmutable from 'shared/utils/data/fileToUrlImmutable'
import Switch from 'shared/components/Switch'
import RootState from 'shared/models/RootState'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'

const CustomerLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'rgb(189, 189, 189)'
  },
  barColorPrimary: {
    backgroundColor: '#24CDDA'
  }
})(LinearProgress)

type Props = {
  forInvitation?: boolean
  orgLogo: string
  orgName: string
  categorization: string
  anonymousInvitation: boolean
  id: string
  vetId: string
  userId: string
  dateCreated: Date
  plannedCompletionDate: Date
  decisionDate: Date
  readonly: boolean
  isPrivate: boolean
  updateVet: (any) => void
}

export class VetInfoCardContainer extends Component<Props> {
  handleCompletionDateChange = (date?: moment.Moment) => {
    const { id, updateVet } = this.props

    updateVet({
      vetId: id,
      plannedCompletionDate: date ? date.toDate() : undefined
    })
  }

  handleAnonymousInvitationChange = () => {
    const { id, updateVet, anonymousInvitation } = this.props
    updateVet({
      vetId: id,
      anonymousInvitation: !anonymousInvitation
    })
  }

  handlePublicChange = () => {
    const { id, updateVet, isPrivate } = this.props
    updateVet({
      vetId: id,
      isPrivate: !isPrivate
    })
  }

  renderProgress = () => {
    const { dateCreated, plannedCompletionDate } = this.props
    const today = new Date()

    if (!dateCreated || !plannedCompletionDate) {
      return null
    } else {
      return (
        <CustomerLinearProgress
          style={{ height: 8 }}
          variant='determinate'
          value={
            today.getTime() > plannedCompletionDate.getTime()
              ? 100
              : ((today.getTime() - dateCreated.getTime()) /
                  (plannedCompletionDate.getTime() - dateCreated.getTime())) *
                100
          }
        />
      )
    }
  }

  render() {
    const {
      vetId,
      userId,
      dateCreated,
      plannedCompletionDate,
      decisionDate,
      readonly,
      forInvitation,
      orgName,
      orgLogo,
      categorization,
      anonymousInvitation,
      isPrivate
    } = this.props
    return (
      <PageSideSection
        title={
          <FormattedMessage
            id='VetInfoCardContainer.VetId'
            defaultMessage='{vetId}'
            values={{ vetId }}
          />
        }
        noPadding
      >
        <div className='ph3-5 pb3-5'>
          {forInvitation ? (
            <ListItem
              mini
              leftAvatar={
                !anonymousInvitation && <OrgLogo url={orgLogo} name={orgName} />
              }
              primaryText={anonymousInvitation ? categorization : orgName}
              secondaryText={
                anonymousInvitation && (
                  <FormattedMessage
                    id='VetInfoCardContainer.AnonymousMessage'
                    defaultMessage='Being anonymous is not recommended'
                  >
                    {message => <span className='red'>{message}</span>}
                  </FormattedMessage>
                )
              }
            />
          ) : (
            <FormattedMessage
              id='VetInfoCardContainer.CreatedBy'
              defaultMessage='Created by'
            >
              {prefix => (
                <UserListItemContainer
                  prefix={prefix}
                  key={userId}
                  userId={userId}
                  mini
                  profileUrl={parsePath(paths.userprofile, { userId })}
                  secondaryText={dateCreated && dateFormat(dateCreated)}
                />
              )}
            </FormattedMessage>
          )}

          {!readonly && isPrivate && (
            <div className='mt3 flex items-center'>
              <div className='dib f7 fw6 mr2'>
                <FormattedMessage
                  id='VetInfoCardContainer.RFIisPrivate'
                  defaultMessage='This RFI is private'
                />
              </div>
              <IconButton onClick={this.handlePublicChange}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          {!readonly &&
            (forInvitation ? (
              <div className='mt3'>
                <FormattedMessage
                  id='VetInfoCardContainer.WouldYouLikeToRemainAnonymous'
                  defaultMessage='Stay Anonymous'
                >
                  {label => (
                    <Switch
                      label={label}
                      checked={anonymousInvitation}
                      onChange={this.handleAnonymousInvitationChange}
                    />
                  )}
                </FormattedMessage>
              </div>
            ) : (
              <Fragment>
                <Label>
                  <FormattedMessage
                    id='VetInfoCardContainer.PlannedDate'
                    defaultMessage='Planned Completion Date'
                  />
                </Label>
                {this.renderProgress()}
                <DatePicker
                  name='plannedCompletionDate'
                  disablePast
                  value={plannedCompletionDate}
                  onChange={newDate =>
                    newDate
                      ? this.handleCompletionDateChange(
                          newDate
                            .hour(23)
                            .minute(59)
                            .second(59)
                        )
                      : this.handleCompletionDateChange()
                  }
                />
                {plannedCompletionDate &&
                  decisionDate &&
                  decisionDate > plannedCompletionDate && (
                    <div className='red f9 mt2'>
                      <FormattedMessage
                        id='VetInfoCardContainer.PlannedDateMessage'
                        defaultMessage='Planned Completion Date is before the Decision Date for suppliers'
                      />
                    </div>
                  )}
              </Fragment>
            ))}
          {readonly && plannedCompletionDate && (
            <Fragment>
              <Label>
                <FormattedMessage id='VetInfoCardContainer.PlannedDate' />
              </Label>
              {this.renderProgress()}
              <Text>
                {plannedCompletionDate && dateFormat(plannedCompletionDate)}
              </Text>
            </Fragment>
          )}
        </div>
      </PageSideSection>
    )
  }
}

export default connect(
  (state: RootState) => {
    const vet = currentVetSelectors.getVet(state)
    const plannedCompletionDate = vet && vet.getIn(['plannedCompletionDate'])
    const decisionDate = vet && vet.getIn(['decisionDate'])
    const domains = settingsSelectors.getField(state, 'domains')
    const orgLogo =
      fileToUrlImmutable(settingsSelectors.getField(state, 'logo')) ||
      (domains && domains.size > 0
        ? `https://logo.clearbit.com/${domains.first()}`
        : '')
    const orgName = settingsSelectors.getName(state)
    const categorization = settingsSelectors.getCategorization(state)

    return {
      orgLogo,
      orgName,
      categorization,
      anonymousInvitation: vet && vet.get('anonymousInvitation'),
      id: vet && vet.get('id'),
      vetId: vet && vet.get('vetId'),
      isPrivate: vet && vet.get('isPrivate'),
      userId: vet && vet.getIn(['created', 'user']),
      dateCreated: vet && new Date(vet.getIn(['created', 'date'])),
      plannedCompletionDate:
        plannedCompletionDate && new Date(plannedCompletionDate),
      decisionDate: decisionDate && new Date(decisionDate),
      readonly: currentVetSelectors.isReadonly(state)
    }
  },
  { updateVet }
)(VetInfoCardContainer)
