import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import parseCard from '../../data/parseCard'

const apiRoute = `${apiRoutes.authService}/cards`

export const create = card => api.post(apiRoute, card).then(parseCard)

type CreateForColleagueProps = {
  userId: string
  orgUnitId: string
  contactFor: string
}
export const createForColleague = (params: CreateForColleagueProps) => {
  return api.post(`${apiRoute}/createColleagueCard`, params).then(parseCard)
}

export const createOnBehalf = ({ email, orgId, contacts }) =>
  api.put(`${apiRoute}/${orgId}/concierge`, {
    email,
    newCard: {
      supplier: orgId,
      connected: true,
      contacts
    }
  })

export const get = cardId => api.get(`${apiRoute}/${cardId}`)
export const getBySupplierId = supplierId =>
  api.get(`${apiRoute}/supplier/${supplierId}`)

export const update = (cardId, card) => api.put(`${apiRoute}/${cardId}`, card)

export const deleteContactConnections = (supplierId, contactId) =>
  api.remove(`${apiRoute}/${supplierId}/deleteContactConnections`, contactId)

export const remove = cardId => api.remove(`${apiRoute}/${cardId}`)

export const deletePrivateOffering = (supplierId, privateOfferingTag) =>
  api.remove(`${apiRoute}/${supplierId}/deletePrivateOffering`, {
    privateOffering: privateOfferingTag
  })

export const upvoteResponse = cardId =>
  api.put(`${apiRoute}/${cardId}/upvoteResponse`)
export const clearVoteResponse = cardId =>
  api.put(`${apiRoute}/${cardId}/clearVoteResponse`)

export const deleteRatingResponses = cardId =>
  api.remove(`${apiRoute}/${cardId}/ratingResponses`)
