import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import mapKeys from 'lodash.mapkeys'
import parseMessage from '../../data/parseMessage'

const apiRoute = `${apiRoutes.authService}/messageTopic`

export const getByUserId = userId => api.get(`${apiRoute}/${userId}`)
export const getAll = () =>
  api
    .get(apiRoute)
    .then(messageTopics => mapKeys(messageTopics, topic => topic.id))

export const create = params => api.post(apiRoute, params)

export const sendMessage = ({
  topicId,
  message,
  supplierToShare,
  shareESGSurveyLink
}) => {
  return api
    .post(`${apiRoute}/${topicId}`, {
      body: message,
      supplierProfileId: supplierToShare,
      shareESGSurveyLink: shareESGSurveyLink
    })
    .then(parseMessage)
}

export const readMessages = topicId =>
  api.put(`${apiRoute}/${topicId}/markRead`)
export const close = topicId => api.put(`${apiRoute}/close`)
