import { call, select } from 'redux-saga/effects'
import createApiSagaRequest from 'shared/utils/sagas/createApiRequestSaga'
import jobsSelectors from '../selectors/jobsSelectors'
import job from 'shared/utils/api/job'

export function* updateSowOrgSaga(action) {
  const { jobId: currentJobId, orgUnitId } = action.payload

  const currentJob = yield select(jobsSelectors.getJobById, currentJobId)
  const metadata = currentJob?.get('metadata')?.toJS() || {}
  let modifiedOrgUnit = metadata.modifiedOrgUnit || {}

  const resultIdsByOrgUnitId = yield select(
    jobsSelectors.getResultIdsByOrgUnitId
  )
  const jobResultId = resultIdsByOrgUnitId.get(orgUnitId)

  let response

  if (!jobResultId) {
    response = yield call(job.createJobResult, {
      ignoreComplete: true,
      ...action.payload
    })
  } else {
    const { jobId, ...restUpdatePayload } = action.payload
    response = yield call(job.updateJobResult, {
      jobResultId,
      ...restUpdatePayload
    })
  }

  modifiedOrgUnit[response.orgUnitId] = Object.keys(response.answers).length
  yield call(job.updateMetadata, { jobId: currentJobId, modifiedOrgUnit })

  return yield {
    jobId: currentJobId,
    metadata: { modifiedOrgUnit },
    jobResult: response
  }
}

export default createApiSagaRequest(updateSowOrgSaga)
