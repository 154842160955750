import React from 'react'
import DropDownMenu from 'shared/components/DropDownMenu'
import parsePath from 'shared/utils/parsePath'
import buyerPaths from 'buyer/routes/paths'
import Link from 'shared/components/Link'
import { MenuItem } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import routes from 'buyer/SupplierProfile/routes'
import { selectUserToMessage } from 'shared/actions'
import { useDispatch } from 'react-redux'

const ActionsColumn = ({
  rowData,
  handleContractUploadAction,
  handleCloseAction,
  handleResendRequestAction,
  handleResendESGSurveyRequestAction,
  handleChatContactSelectAction
}) => {
  const dispatch = useDispatch()
  const status = rowData.get('status')
  const requestType = rowData.get('type')
  const requestIsOpen = status !== 'Cancelled' && status !== 'Completed'
  return (
    <div onClick={e => e.stopPropagation()} className='tc'>
      <DropDownMenu>
        {requestIsOpen && (
          <div>
            {requestType === 'ExpiringContract' && (
              <div>
                <MenuItem
                  onClick={() =>
                    handleContractUploadAction(
                      rowData.get('id'),
                      rowData.get('supplierId'),
                      rowData.get('expiringAttachment'),
                      rowData.get('relationshipId')
                    )
                  }
                >
                  <FormattedMessage
                    id={'ActionsColumn.uploadContract'}
                    defaultMessage='Upload New Contract'
                  />
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleChatContactSelectAction(
                      rowData.get('id'),
                      rowData.get('supplierId')
                    )
                  }
                >
                  <FormattedMessage
                    id={'ActionsColumn.contactSupplier'}
                    defaultMessage='Contact Supplier'
                  />
                </MenuItem>
              </div>
            )}

            {(requestType === 'EcoVadisAssessment' ||
              requestType === 'ExpiringContract') && (
              <MenuItem onClick={() => handleCloseAction(rowData)}>
                <FormattedMessage
                  id={'ActionsColumn.closeRequest'}
                  defaultMessage='Close Request'
                />
              </MenuItem>
            )}
            {requestType === 'EcoVadisAssessment' && (
              <MenuItem>
                <Link
                  to={`${parsePath(routes.basePath, {
                    supplierId: rowData.get('supplierId')
                  })}?profileTab=ecovadisRating`}
                >
                  <FormattedMessage
                    id={'ActionsColumn.GoToEcoVadisRating'}
                    defaultMessage='Go to EcoVadis Rating'
                  />
                </Link>
              </MenuItem>
            )}

            {requestType === 'SelfCertifyRequest' && (
              <div>
                <MenuItem onClick={() => handleCloseAction(rowData)}>
                  <FormattedMessage
                    id={'ActionsColumn.closeRequestNonResponsive'}
                    defaultMessage='Close Request As Non Responsive'
                  />
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleResendRequestAction(
                      rowData.get('id'),
                      rowData.get('requestedContactId'),
                      rowData.get('supplierId')
                    )
                  }
                >
                  <FormattedMessage
                    id={'ActionsColumn.ResendInviteToNewContact'}
                    defaultMessage='Resend the Invitation'
                  />
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    dispatch(
                      selectUserToMessage({
                        userToMessage: rowData.get('requestedContactId')
                      })
                    )
                  }
                >
                  <Link
                    to={parsePath(buyerPaths.messages, { messageTopicId: '' })}
                  >
                    <FormattedMessage
                      id={'ActionsColumn.ViewCommunicationHistory'}
                      defaultMessage='View Communication History'
                    />
                  </Link>
                </MenuItem>
              </div>
            )}
            {requestType === 'ESGSurveyRequest' && (
              <div>
                <MenuItem onClick={() => handleCloseAction(rowData)}>
                  <FormattedMessage
                    id={'ActionsColumn.closeRequestNonResponsive'}
                    defaultMessage='Close Request As Non Responsive'
                  />
                </MenuItem>
                {rowData.get('status') !== 'InProgress' && (
                  <MenuItem
                    onClick={() =>
                      handleResendESGSurveyRequestAction(
                        rowData.get('id'),
                        rowData.get('requestedContactId'),
                        rowData.get('supplierId')
                      )
                    }
                  >
                    <FormattedMessage
                      id={'ActionsColumn.ResendRequestToNewContact'}
                      defaultMessage='Resend the Request'
                    />
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() =>
                    dispatch(
                      selectUserToMessage({
                        userToMessage: rowData.get('requestedContactId')
                      })
                    )
                  }
                >
                  <Link
                    to={parsePath(buyerPaths.messages, { messageTopicId: '' })}
                  >
                    <FormattedMessage
                      id={'ActionsColumn.ViewCommunicationHistory'}
                      defaultMessage='View Communication History'
                    />
                  </Link>
                </MenuItem>
              </div>
            )}
          </div>
        )}

        {!requestIsOpen && (
          <div>
            <MenuItem>
              <Link
                to={parsePath(buyerPaths.supplierProfile, {
                  supplierId: rowData.get('supplierId')
                })}
              >
                <FormattedMessage
                  id={'ActionsColumn.viewProfile'}
                  defaultMessage='View Profile'
                />
              </Link>
            </MenuItem>
            {requestType === 'ESGSurveyRequest' && status === 'Completed' && (
              <MenuItem>
                <Link
                  to={parsePath(buyerPaths.supplierEsgSurvey, {
                    supplierId: rowData.get('supplierId')
                  })}
                >
                  <FormattedMessage
                    id={'ActionsColumn.ViewSurveyResponses'}
                    defaultMessage='View Survey Responses'
                  />
                </Link>
              </MenuItem>
            )}
          </div>
        )}
      </DropDownMenu>
    </div>
  )
}

export default ActionsColumn
