import React, { useState } from 'react'
import Tab from '@material-ui/core/Tab'
import PeopleIcon from '@material-ui/icons/People'
import UploadIcon from '@material-ui/icons/CloudUpload'
import OldLoaderIcon from '@material-ui/icons/ArrowUpward'
import HistoryIcon from '@material-ui/icons/History'
import SalesIcon from '@material-ui/icons/MonetizationOn'
import TechIcon from '@material-ui/icons/Tune'
import AppIcon from '@material-ui/icons/Widgets'
import paths from '../../routes/paths'
import TabNav from 'shared/components/TabNav'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

type Props = {
  availableTabs: { [key: string]: boolean }
}

const AdminTabs = ({ availableTabs }: Props) => {
  const location = useLocation()
  const currentPath = location.pathname

  const [selectedTab, setSelectedTab] = useState(() => {
    const appConfigPath = currentPath.startsWith(paths.appConfig)
      ? paths.appConfig
      : ''
    const techConfigPath = currentPath.startsWith(paths.techConfig)
      ? paths.techConfig
      : ''
    return appConfigPath || techConfigPath || currentPath
  })

  const handleChange = newValue => {
    setSelectedTab(newValue)
  }

  return (
    <TabNav selectedTab={selectedTab}>
      <Tab
        value={paths.users}
        icon={<PeopleIcon />}
        label={<FormattedMessage id='AdminTabs.Users' defaultMessage='Users' />}
        onClick={() => handleChange(paths.users)}
      />
      {availableTabs[paths.salesConfig] && (
        <Tab
          value={paths.salesConfig}
          icon={<SalesIcon />}
          label={
            <FormattedMessage
              id='AdminTabs.SalesConfig'
              defaultMessage='Sales Config'
            />
          }
          onClick={() => handleChange(paths.salesConfig)}
        />
      )}
      {availableTabs[paths.appConfig] && (
        <Tab
          value={paths.appConfig}
          icon={<AppIcon />}
          label={
            <FormattedMessage
              id='AdminTabs.AppConfig'
              defaultMessage='App Config'
            />
          }
          onClick={() => handleChange(paths.appConfig)}
        />
      )}
      {availableTabs[paths.techConfig] && (
        <Tab
          value={paths.techConfig}
          icon={<TechIcon />}
          label={
            <FormattedMessage
              id='AdminTabs.TechConfig'
              defaultMessage='Tech Config'
            />
          }
          onClick={() => handleChange(paths.techConfig)}
        />
      )}
      {availableTabs[paths.fileUpload] && (
        <Tab
          value={paths.fileUpload}
          icon={<UploadIcon />}
          label={
            <FormattedMessage
              id='AdminTabs.FileUpload'
              defaultMessage='Upload'
            />
          }
          onClick={() => handleChange(paths.fileUpload)}
        />
      )}
      {availableTabs[paths.changeHistory] && (
        <Tab
          value={paths.changeHistory}
          icon={<HistoryIcon />}
          label={
            <FormattedMessage
              id='AdminTabs.ChangeHistory'
              defaultMessage='Change History'
            />
          }
          onClick={() => handleChange(paths.changeHistory)}
        />
      )}
      {availableTabs[paths.loader] && (
        <Tab
          value={paths.loader}
          icon={<OldLoaderIcon />}
          label={'Old Loader'}
          onClick={() => handleChange(paths.loader)}
        />
      )}
    </TabNav>
  )
}
export default AdminTabs
