import DiversityIcon from '../assets/icons/diversity.svg'
import QualityIcon from '../assets/icons/quality.svg'
import SecurityIcon from '../assets/icons/security.svg'
import SustainabilityIcon from '../assets/icons/sustainable.svg'
import FoodIcon from '../assets/icons/foods.svg'
import { defineMessages, MessageDescriptor } from 'react-intl'

const categoriesMessages = defineMessages<CategoryType>({
  diversity: {
    id: 'CertificationCategories.diversity',
    defaultMessage: 'Diversity'
  },
  quality: {
    id: 'CertificationCategories.quality',
    defaultMessage: 'Quality'
  },
  security: {
    id: 'CertificationCategories.security',
    defaultMessage: 'Information Security'
  },
  sustainability: {
    id: 'CertificationCategories.sustainability',
    defaultMessage: 'Social Responsibility and Sustainability'
  },
  food: {
    id: 'CertificationCategories.food',
    defaultMessage: 'Food'
  }
})

type CertCategories = {
  [key in CategoryType]: {
    title: MessageDescriptor
    icon: string
    subCategories: {
      [key: string]: MessageDescriptor
    }
    subCategoriesAcronyms: {
      [key: string]: MessageDescriptor
    }
    subTypes?: {
      [key: string]: {
        [key: string]: MessageDescriptor
      }
    }
  }
}

const certificationCategories: CertCategories = {
  diversity: {
    title: categoriesMessages.diversity,
    icon: DiversityIcon,
    subCategories: defineMessages({
      ancit: {
        id: 'CertificationCategories.diversity.ancit',
        defaultMessage: 'Alaskan Native Corporations and Indian Tribes'
      },
      bcorp: {
        id: 'CertificationCategories.diversity.bcorp',
        defaultMessage: 'Certified Benefit Corporation'
      },
      cab: {
        id: 'CertificationCategories.diversity.cab',
        defaultMessage: 'Certified Aboriginal Business'
      },
      dbe: {
        id: 'CertificationCategories.diversity.dbe',
        defaultMessage: 'Disadvantaged Business Enterprise'
      },
      dvbe: {
        id: 'CertificationCategories.diversity.dvbe',
        defaultMessage: 'Disabled Veteran Business Enterprise'
      },
      dve: {
        id: 'CertificationCategories.diversity.dve',
        defaultMessage: 'Disadvantaged Veteran Enterprise'
      },
      hbcu: {
        id: 'CertificationCategories.diversity.hbcu',
        defaultMessage: 'Historically Black Colleges and Universities'
      },
      hud: {
        id: 'CertificationCategories.diversity.hud',
        defaultMessage: 'U.S. Department of Housing and Urban Development'
      },
      hub: {
        id: 'CertificationCategories.diversity.hub',
        defaultMessage: 'HubZone Certified'
      },
      lgbtbe: {
        id: 'CertificationCategories.diversity.lgbtbe',
        defaultMessage: 'LGBT Owned Business Enterprise'
      },
      lsa: {
        id: 'CertificationCategories.diversity.lsa',
        defaultMessage: 'Labour Surplus Area'
      },
      mbe: {
        id: 'CertificationCategories.diversity.mbe',
        defaultMessage: 'Minority-owned Business Enterprise'
      },
      pwd: {
        id: 'CertificationCategories.diversity.pwd',
        defaultMessage: 'People With Disability'
      },
      sbe: {
        id: 'CertificationCategories.diversity.sbe',
        defaultMessage: 'Small Business Enterprise'
      },
      sdb: {
        id: 'CertificationCategories.diversity.sdb',
        defaultMessage: 'Small Disadvantaged Business'
      },
      sdvbe: {
        id: 'CertificationCategories.diversity.sdvbe',
        defaultMessage: 'Service Disabled Veteran Business Enterprise'
      },
      vbe: {
        id: 'CertificationCategories.diversity.vbe',
        defaultMessage: 'Veteran-owned Business Enterprise'
      },
      wle: {
        id: 'CertificationCategories.diversity.wle',
        defaultMessage: 'Woman Led Business Enterprise'
      },
      wbe: {
        id: 'CertificationCategories.diversity.wbe',
        defaultMessage: 'Women-owned Business Enterprise'
      },
      vosb: {
        id: 'CertificationCategories.diversity.vosb',
        defaultMessage: 'Veteran Owned Small Business'
      }
    }),
    subCategoriesAcronyms: defineMessages({
      ancit: {
        id: 'CertificationCategories.diversity.acronym.ancit',
        defaultMessage: 'ANCIT'
      },
      bcorp: {
        id: 'CertificationCategories.diversity.acronym.bcorp',
        defaultMessage: 'BCORP'
      },
      cab: {
        id: 'CertificationCategories.diversity.acronym.cab',
        defaultMessage: 'CAB'
      },
      dbe: {
        id: 'CertificationCategories.diversity.acronym.dbe',
        defaultMessage: 'DBE'
      },
      dvbe: {
        id: 'CertificationCategories.diversity.acronym.dvbe',
        defaultMessage: 'DVBE'
      },
      dve: {
        id: 'CertificationCategories.diversity.acronym.dve',
        defaultMessage: 'DVE'
      },
      hub: {
        id: 'CertificationCategories.diversity.acronym.hub',
        defaultMessage: 'HUB'
      },
      hbcu: {
        id: 'CertificationCategories.diversity.acronym.hbcu',
        defaultMessage: 'HBCU'
      },
      hud: {
        id: 'CertificationCategories.diversity.acronym.hud',
        defaultMessage: 'HUD'
      },
      lgbtbe: {
        id: 'CertificationCategories.diversity.acronym.lgbtbe',
        defaultMessage: 'LGBTBE'
      },
      lsa: {
        id: 'CertificationCategories.diversity.acronym.lsa',
        defaultMessage: 'LSA'
      },
      mbe: {
        id: 'CertificationCategories.diversity.acronym.mbe',
        defaultMessage: 'MBE'
      },
      pwd: {
        id: 'CertificationCategories.diversity.acronym.pwd',
        defaultMessage: 'PWD'
      },
      sbe: {
        id: 'CertificationCategories.diversity.acronym.sbe',
        defaultMessage: 'SBE'
      },
      sdb: {
        id: 'CertificationCategories.diversity.acronym.sdb',
        defaultMessage: 'SDB'
      },
      sdvbe: {
        id: 'CertificationCategories.diversity.acronym.sdvbe',
        defaultMessage: 'SDVBE'
      },
      vbe: {
        id: 'CertificationCategories.diversity.acronym.vbe',
        defaultMessage: 'VBE'
      },
      wbe: {
        id: 'CertificationCategories.diversity.acronym.wbe',
        defaultMessage: 'WBE'
      },
      wle: {
        id: 'CertificationCategories.diversity.acronym.wle',
        defaultMessage: 'WLE'
      },
      vosb: {
        id: 'CertificationCategories.diversity.acronym.vosb',
        defaultMessage: 'VOSB'
      }
    }),
    // ***filter out Other when user selects a subType when sharing a Cert on their profile. Other is only being used for Search results
    subTypes: {
      mbe: defineMessages({
        africanAmerican: {
          id: 'CertificationCategories.diversity.africanAmerican',
          defaultMessage: 'African American'
        },
        alaskanNativeCorporation: {
          id: 'CertificationCategories.diversity.alaskanNativeCorporation',
          defaultMessage: 'Alaskan Native Corporation'
        },
        asianAmerican: {
          id: 'CertificationCategories.diversity.asianAmerican',
          defaultMessage: 'Asian American'
        },
        asianIndianAmerican: {
          id: 'CertificationCategories.diversity.asianIndianAmerican',
          defaultMessage: 'Asian Indian American'
        },
        asianPacificAmerican: {
          id: 'CertificationCategories.diversity.asianPacificAmerican',
          defaultMessage: 'Asian Pacific American'
        },
        hispanicAmerican: {
          id: 'CertificationCategories.diversity.hispanicAmerican',
          defaultMessage: 'Hispanic American'
        },
        iberianPeninsulaAmerican: {
          id: 'CertificationCategories.diversity.iberianPeninsulaAmerican',
          defaultMessage: 'Iberian Peninsula American'
        },
        minorityInstitution: {
          id: 'CertificationCategories.diversity.minorityInstitution',
          defaultMessage: 'Minority Institution'
        },
        nativeAmerican: {
          id: 'CertificationCategories.diversity.nativeAmerican',
          defaultMessage: 'Native American'
        },
        nativeHawaiian: {
          id: 'CertificationCategories.diversity.nativeHawaiian',
          defaultMessage: 'Native Hawaiian'
        },
        portugueseAmerican: {
          id: 'CertificationCategories.diversity.portugueseAmerican',
          defaultMessage: 'Portuguese American'
        },
        subcontinentAsianAmerican: {
          id: 'CertificationCategories.diversity.subcontinentAsianAmerican',
          defaultMessage: 'Subcontinent Asian American'
        },
        triballyOwned: {
          id: 'CertificationCategories.diversity.triballyOwned',
          defaultMessage: 'Tribally Owned'
        },
        other: {
          id: 'CertificationCategories.diversity.other',
          defaultMessage: 'Other'
        }
      }),
      cab: defineMessages({
        alaskanNativeCorporation: {
          id: 'CertificationCategories.diversity.alaskanNativeCorporation',
          defaultMessage: 'Alaskan Native Corporation'
        },
        triballyOwned: {
          id: 'CertificationCategories.diversity.triballyOwned',
          defaultMessage: 'Tribally Owned'
        }
      }),
      hub: defineMessages({
        africanAmerican: {
          id: 'CertificationCategories.diversity.africanAmerican',
          defaultMessage: 'African American'
        },
        asianAmerican: {
          id: 'CertificationCategories.diversity.asianAmerican',
          defaultMessage: 'Asian American'
        },
        asianPacificAmerican: {
          id: 'CertificationCategories.diversity.asianPacificAmerican',
          defaultMessage: 'Asian Pacific American'
        },
        hispanicAmerican: {
          id: 'CertificationCategories.diversity.hispanicAmerican',
          defaultMessage: 'Hispanic American'
        },
        nativeAmerican: {
          id: 'CertificationCategories.diversity.nativeAmerican',
          defaultMessage: 'Native American'
        }
      }),
      dbe: defineMessages({
        '8a': {
          id: 'CertificationCategories.diversity.8A',
          defaultMessage: '8A'
        },
        africanAmerican: {
          id: 'CertificationCategories.diversity.africanAmerican',
          defaultMessage: 'African American'
        },
        asianAmerican: {
          id: 'CertificationCategories.diversity.asianAmerican',
          defaultMessage: 'Asian American'
        },
        asianPacificAmerican: {
          id: 'CertificationCategories.diversity.asianPacificAmerican',
          defaultMessage: 'Asian Pacific American'
        },
        hispanicAmerican: {
          id: 'CertificationCategories.diversity.hispanicAmerican',
          defaultMessage: 'Hispanic American'
        },
        nativeAmerican: {
          id: 'CertificationCategories.diversity.nativeAmerican',
          defaultMessage: 'Native American'
        },
        portugueseAmerican: {
          id: 'CertificationCategories.diversity.portugueseAmerican',
          defaultMessage: 'Portuguese American'
        },
        subcontinentAsianAmerican: {
          id: 'CertificationCategories.diversity.subcontinentAsianAmerican',
          defaultMessage: 'Subcontinent Asian American'
        }
      }),
      sbe: defineMessages({
        msme: {
          id: 'CertificationCategories.diversity.msme',
          defaultMessage: 'Micro, Small and Medium Enterprises'
        }
      })
    }
  },
  quality: {
    icon: QualityIcon,
    title: categoriesMessages.quality,
    subCategories: defineMessages({
      iso9000: {
        id: 'CertificationCategories.quality.iso9000',
        defaultMessage: 'ISO 9000/9001: Quality management systems'
      },

      iso10007: {
        id: 'CertificationCategories.quality.iso10007',
        defaultMessage:
          'ISO 10007: Quality management - Guidelines for configuration management'
      },
      iso13485: {
        id: 'CertificationCategories.quality.iso13485',
        defaultMessage:
          'ISO 13485: Medical devices - Quality management systems'
      },
      iso16949: {
        id: 'CertificationCategories.quality.iso16949',
        defaultMessage: 'ISO 16949: Quality Management Systems'
      },
      iso17025: {
        id: 'CertificationCategories.quality.iso17025',
        defaultMessage:
          'ISO 17025: General requirements for the competence of testing and calibration laboratories'
      },
      iso17034: {
        id: 'CertificationCategories.quality.iso17034',
        defaultMessage:
          'ISO 17034: General requirements for the competence of reference material producers'
      },
      iso22000: {
        id: 'CertificationCategories.quality.iso22000',
        defaultMessage: 'ISO 22000: Food Safety Management'
      },
      iso31000: {
        id: 'CertificationCategories.quality.iso31000',
        defaultMessage: 'ISO 31000: Risk Management'
      },
      gmp: {
        id: 'CertificationCategories.quality.gmp',
        defaultMessage: 'Good Manufacturing Practice'
      },
      ncqa: {
        id: 'CertificationCategories.quality.ncqa',
        defaultMessage: 'National Committee for Quality Assurance in Healthcare'
      },
      urac: {
        id: 'CertificationCategories.quality.urac',
        defaultMessage: 'Quality in Medical Care'
      }
    }),
    subCategoriesAcronyms: defineMessages({
      iso9000: {
        id: 'CertificationCategories.quality.acronym.iso9000',
        defaultMessage: 'ISO9000/9001'
      },
      iso10007: {
        id: 'CertificationCategories.quality.acronym.iso10007',
        defaultMessage: 'ISO10007'
      },
      iso13485: {
        id: 'CertificationCategories.quality.acronym.iso13485',
        defaultMessage: 'ISO13485'
      },
      iso16949: {
        id: 'CertificationCategories.quality.acronym.iso16949',
        defaultMessage: 'ISO16949'
      },
      iso17025: {
        id: 'CertificationCategories.quality.acronym.iso17025',
        defaultMessage: 'ISO17025'
      },
      iso17034: {
        id: 'CertificationCategories.quality.acronym.iso17034',
        defaultMessage: 'ISO17034'
      },
      iso22000: {
        id: 'CertificationCategories.quality.acronym.iso22000',
        defaultMessage: 'ISO22000'
      },
      iso27000: {
        id: 'CertificationCategories.quality.acronym.iso27000',
        defaultMessage: 'ISO27000'
      },
      iso31000: {
        id: 'CertificationCategories.quality.acronym.iso31000',
        defaultMessage: 'ISO31000'
      },
      gmp: {
        id: 'CertificationCategories.quality.acronym.gmp',
        defaultMessage: 'GMP'
      },
      ncqa: {
        id: 'CertificationCategories.quality.acronym.ncqa',
        defaultMessage: 'NCQA'
      },
      urac: {
        id: 'CertificationCategories.quality.acronym.urac',
        defaultMessage: 'URAC'
      }
    }),
    subTypes: {
      gmp: defineMessages({
        cGmp: {
          id: 'CertificationCategories.quality.cGmp',
          defaultMessage: 'Current Good Manufacturing Practice (cGMP)'
        },
        euGmp: {
          id: 'CertificationCategories.quality.euGmp',
          defaultMessage: 'European Good Manufacturing Practice (EU GMP)'
        },
        whoGmp: {
          id: 'CertificationCategories.quality.whoGmp',
          defaultMessage: 'WHO Good Manufacturing Practice (WHO GMP)'
        }
      })
    }
  },

  security: {
    icon: SecurityIcon,
    title: categoriesMessages.security,
    subCategories: defineMessages({
      iso27000: {
        id: 'CertificationCategories.security.iso27000',
        defaultMessage:
          'ISO/IEC 27000:Information Technology - Security Techniques'
      },
      iso27002: {
        id: 'CertificationCategories.security.iso27002',
        defaultMessage:
          'ISO/IEC 27002: Information Technology - Security Techniques - Code of Practice for Information Security Controls'
      },
      nist: {
        id: 'CertificationCategories.security.nist',
        defaultMessage: 'Cybersecurity Standards, Guidelines and Best Practices'
      },
      gdpr: {
        id: 'CertificationCategories.security.gdpr',
        defaultMessage: 'GDPR Compliance'
      },
      hipaa: {
        id: 'CertificationCategories.security.hipaa',
        defaultMessage: 'Health Insurance Portability and Accountability Act'
      },
      soc1: {
        id: 'CertificationCategories.security.soc1',
        defaultMessage: 'Service and Organization Controls 1, Type II Report'
      },
      soc2: {
        id: 'CertificationCategories.security.soc2',
        defaultMessage: 'Service and Organization Controls 2, Type II Report'
      }
    }),
    subCategoriesAcronyms: defineMessages({
      iso27000: {
        id: 'CertificationCategories.security.acronym.iso27000',
        defaultMessage: 'ISO/IEC27000'
      },
      iso27002: {
        id: 'CertificationCategories.security.acronym.iso27002',
        defaultMessage: 'ISO/IEC27002'
      },
      nist: {
        id: 'CertificationCategories.security.acronym.nist',
        defaultMessage: 'NIST'
      },
      gdpr: {
        id: 'CertificationCategories.security.acronym.gdpr',
        defaultMessage: 'GDPR'
      },
      hipaa: {
        id: 'CertificationCategories.security.acronym.hipaa',
        defaultMessage: 'HIPAA'
      },
      soc1: {
        id: 'CertificationCategories.security.acronym.soc1',
        defaultMessage: 'SOC1'
      },
      soc2: {
        id: 'CertificationCategories.security.acronym.soc2',
        defaultMessage: 'SOC2'
      }
    })
  },

  sustainability: {
    title: categoriesMessages.sustainability,
    icon: SustainabilityIcon,
    subCategories: defineMessages({
      iso14000: {
        id: 'CertificationCategories.sustainability.iso14000',
        defaultMessage: 'ISO 14000: Environmental Management'
      },
      iso14001: {
        id: 'CertificationCategories.sustainability.iso14001',
        defaultMessage: 'ISO 14001: Environmental Management'
      },
      iso20121: {
        id: 'CertificationCategories.sustainability.iso20121',
        defaultMessage: 'ISO 20121: Event sustainability'
      },
      iso26000: {
        id: 'CertificationCategories.sustainability.iso26000',
        defaultMessage: 'ISO 26000: Social responsibility'
      },
      iso45001: {
        id: 'CertificationCategories.sustainability.iso45001',
        defaultMessage: 'ISO 45001: Occupational Health & Safety'
      },
      iso50001: {
        id: 'CertificationCategories.sustainability.iso50001',
        defaultMessage: 'ISO 50001: Energy Management'
      },
      animalWelfare: {
        id: 'CertificationCategories.sustainability.animalWelfare',
        defaultMessage: 'Animal Welfare'
      },
      emissionReduction: {
        id: 'CertificationCategories.sustainability.emissionReduction',
        defaultMessage: 'Emission Reduction'
      },
      environment: {
        id: 'CertificationCategories.sustainability.environment',
        defaultMessage: 'Environment'
      },
      genderEquality: {
        id: 'CertificationCategories.sustainability.genderEquality',
        defaultMessage: 'Gender Equality'
      },
      sube: {
        id: 'CertificationCategories.sustainability.sube',
        defaultMessage: 'Sustainable Business Enterprise'
      },
      sustainableSourcing: {
        id: 'CertificationCategories.sustainability.sustainableSourcing',
        defaultMessage: 'Sustainable Sourcing'
      }
    }),
    subCategoriesAcronyms: defineMessages({
      iso14000: {
        id: 'CertificationCategories.sustainability.acronym.iso14000',
        defaultMessage: 'ISO14000'
      },
      iso14001: {
        id: 'CertificationCategories.sustainability.acronym.iso14001',
        defaultMessage: 'ISO14001'
      },
      iso20121: {
        id: 'CertificationCategories.sustainability.acronym.iso20121',
        defaultMessage: 'ISO20121'
      },
      iso26000: {
        id: 'CertificationCategories.sustainability.acronym.iso26000',
        defaultMessage: 'ISO26000'
      },
      iso45001: {
        id: 'CertificationCategories.sustainability.acronym.iso45001',
        defaultMessage: 'ISO45001'
      },
      iso50001: {
        id: 'CertificationCategories.sustainability.acronym.iso50001',
        defaultMessage: 'ISO50001'
      },
      animalWelfare: {
        id: 'CertificationCategories.sustainability.acronym.animalWelfare',
        defaultMessage: 'Animal Welfare'
      },
      emissionReduction: {
        id: 'CertificationCategories.sustainability.acronym.emissionReduction',
        defaultMessage: 'Emission Reduction'
      },
      environment: {
        id: 'CertificationCategories.sustainability.acronym.environment',
        defaultMessage: 'Environment'
      },
      genderEquality: {
        id: 'CertificationCategories.sustainability.acronym.genderEquality',
        defaultMessage: 'Gender Equality'
      },
      sube: {
        id: 'CertificationCategories.sustainability.acronym.sube',
        defaultMessage: 'SUBE'
      },
      sustainableSourcing: {
        id:
          'CertificationCategories.sustainability.acronym.sustainableSourcing',
        defaultMessage: 'Sustainable Sourcing'
      }
    }),
    subTypes: {
      environment: defineMessages({
        ancientForestFriendly: {
          id: 'CertificationCategories.sustainability.ancientForestFriendly',
          defaultMessage: 'Ancient Forest Friendly'
        },
        chainOfCustody: {
          id: 'CertificationCategories.sustainability.chainOfCustody',
          defaultMessage: 'Chain of Custody'
        },
        cleaningServices: {
          id: 'CertificationCategories.sustainability.cleaningServices',
          defaultMessage: 'Cleaning Services'
        },
        controlledWood: {
          id: 'CertificationCategories.sustainability.controlledWood',
          defaultMessage: 'Controlled Wood'
        },
        constructionMaterialsAndBuildingEquipment: {
          id:
            'CertificationCategories.sustainability.constructionMaterialsAndBuildingEquipment',
          defaultMessage: 'Construction Materials and Building Equipment'
        },
        environmentalInnovation: {
          id: 'CertificationCategories.sustainability.environmentalInnovation',
          defaultMessage: 'Environmental Innovation'
        },
        foodPackagingAndPaper: {
          id: 'CertificationCategories.sustainability.foodPackagingAndPaper',
          defaultMessage: 'Food Packaging and Paper'
        },
        forestManagement: {
          id: 'CertificationCategories.sustainability.forestManagement',
          defaultMessage: 'Forest Management'
        },
        hotels: {
          id: 'CertificationCategories.sustainability.hotels',
          defaultMessage: 'Hotels'
        },
        householdCleaningProducts: {
          id:
            'CertificationCategories.sustainability.householdCleaningProducts',
          defaultMessage: 'Household Cleaning Products'
        },
        industrialAndInstitutionalCleaningProducts: {
          id:
            'CertificationCategories.sustainability.industrialAndInstitutionalCleaningProducts',
          defaultMessage: 'Industrial and Institutional Cleaning Products'
        },
        printingAndWritingPaper: {
          id: 'CertificationCategories.sustainability.printingAndWritingPaper',
          defaultMessage: 'Printing and Writing Paper'
        },
        restaurants: {
          id: 'CertificationCategories.sustainability.restaurants',
          defaultMessage: 'Restaurants'
        },
        sanitaryPaperProducts: {
          id: 'CertificationCategories.sustainability.sanitaryPaperProducts',
          defaultMessage: 'Sanitary Paper Products'
        }
      }),
      emissionReduction: defineMessages({
        scienceBasedTargets: {
          id: 'CertificationCategories.sustainability.scienceBasedTargets',
          defaultMessage: 'Science Based Targets'
        }
      }),
      animalWelfare: defineMessages({
        aaalac: {
          id: 'CertificationCategories.sustainability.aaalac',
          defaultMessage:
            'Association for Assessment and Accreditation of Laboratory Animal Care'
        },
        leapingBunny: {
          id: 'CertificationCategories.sustainability.leapingBunny',
          defaultMessage: 'Leaping Bunny'
        }
      }),
      sustainableSourcing: defineMessages({
        rainforestAlliance: {
          id: 'CertificationCategories.sustainability.rainforestAlliance',
          defaultMessage: 'Rainforest Alliance'
        },
        gots: {
          id: 'CertificationCategories.sustainability.gots',
          defaultMessage: 'Global Organic Textile Standard'
        }
      })
    }
  },
  food: {
    title: categoriesMessages.food,
    icon: FoodIcon,
    subCategories: defineMessages({
      drc: {
        id: 'CertificationCategories.food.drc',
        defaultMessage: 'Fruit and Vegetable Dispute Resolution Corporation'
      },
      gfsi: {
        id: 'CertificationCategories.food.gfsi',
        defaultMessage: 'Global Food Safety Initiative'
      },
      glutenFree: {
        id: 'CertificationCategories.food.glutenFree',
        defaultMessage: 'Gluten Free'
      },
      organic: {
        id: 'CertificationCategories.food.organic',
        defaultMessage: 'Certified Organic'
      },
      plantBased: {
        id: 'CertificationCategories.food.plantBased',
        defaultMessage: 'Plant Based'
      },
      responsibleSourcing: {
        id: 'CertificationCategories.food.responsibleSourcing',
        defaultMessage: 'Responsible Sourcing'
      }
    }),
    subCategoriesAcronyms: defineMessages({
      drc: {
        id: 'CertificationCategories.food.acronym.drc',
        defaultMessage: 'DRC'
      },
      gfsi: {
        id: 'CertificationCategories.food.acronym.gfsi',
        defaultMessage: 'GFSI'
      },
      glutenFree: {
        id: 'CertificationCategories.food.acronym.glutenFree',
        defaultMessage: 'Gluten Free'
      },
      organic: {
        id: 'CertificationCategories.food.acronym.organic',
        defaultMessage: 'Organic'
      },
      plantBased: {
        id: 'CertificationCategories.food.acronym.plantBased',
        defaultMessage: 'Plant Based'
      },
      responsibleSourcing: {
        id: 'CertificationCategories.food.acronym.responsibleSourcing',
        defaultMessage: 'Responsible Sourcing'
      }
    }),
    subTypes: {
      gfsi: defineMessages({
        agentsAndBrokers: {
          id: 'CertificationCategories.food.agentsAndBrokers',
          defaultMessage: 'Agents and Brokers'
        },
        bap: {
          id: 'CertificationCategories.food.bap',
          defaultMessage: 'Best Aquaculture Practices'
        },
        brc: {
          id: 'CertificationCategories.food.brc',
          defaultMessage: 'BRC'
        },
        canadagap: {
          id: 'CertificationCategories.food.canadagap',
          defaultMessage: 'Canada GAP'
        },
        consumerProducts: {
          id: 'CertificationCategories.food.consumerProducts',
          defaultMessage: 'Consumer Products'
        },
        foodSafetyManagementProgram: {
          id: 'CertificationCategories.food.foodSafetyManagementProgram',
          defaultMessage: 'Food Safety Management Program'
        },
        food: {
          id: 'CertificationCategories.food.food',
          defaultMessage: 'Food'
        },
        foodPackagingAndPackagingMaterials: {
          id: 'CertificationCategories.food.foodPackagingAndPackagingMaterials',
          defaultMessage: 'Food Packaging and Packaging Materials'
        },
        gap: {
          id: 'CertificationCategories.food.gap',
          defaultMessage: 'Good Agricultural Practices'
        },
        ifs: {
          id: 'CertificationCategories.food.ifs',
          defaultMessage: 'International Featured Standards'
        },
        storageAndDistribution: {
          id: 'CertificationCategories.food.storageAndDistribution',
          defaultMessage: 'Storage and Distribution'
        }
      })
    }
  }
}

export type CategoryType =
  | 'diversity'
  | 'quality'
  | 'security'
  | 'sustainability'
  | 'food'

export type SubCategoryType<T extends CategoryType> = T extends 'diversity'
  ?
      | 'sbe'
      | 'mbe'
      | 'wbe'
      | 'vbe'
      | 'dobe'
      | 'dbe'
      | 'pwd'
      | 'lgbtbe'
      | 'hub'
      | 'lsa'
      | 'dvbe'
      | 'sdvbe'
      | 'dve'
      | 'sdb'
      | 'ancit'
      | 'wle'
      | 'cab'
      | 'bcorp'
  : T extends 'sustainability'
  ?
      | 'iso26000'
      | 'iso20121'
      | 'iso14000'
      | 'iso45001'
      | 'iso14001'
      | 'iso50001'
      | 'sube'
      | 'genderEquality'
      | 'emissionReduction'
      | 'environment'
      | 'animalWelfare'
  : T extends 'security'
  ? 'iso27000' | 'iso27002' | 'nist' | 'gdpr' | 'hipaa' | 'soc1' | 'soc2'
  : T extends 'quality'
  ?
      | 'ncqa'
      | 'urac'
      | 'gmp'
      | 'iso9000'
      | 'iso10007'
      | 'iso16949'
      | 'iso22000'
      | 'iso13485'
      | 'iso31000'
      | 'iso17025'
      | 'iso17034'
  : T extends 'food'
  ?
      | 'organic'
      | 'gfsi'
      | 'drc'
      | 'plantBased'
      | 'glutenFree'
      | 'responsibleSourcing'
  : never

export default certificationCategories
