import React from 'react'
import healthSafety from 'shared/assets/images/esg/icon-health-safety.svg'
import environment from 'shared/assets/images/esg/icon-environment.svg'
import diversity from 'shared/assets/images/esg/icon-diversity.svg'
import governance from 'shared/assets/images/esg/icon-governance.svg'
import privacy from 'shared/assets/images/esg/icon-privacy.svg'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import paths from 'buyer/routes/paths'
import parsePath from 'shared/utils/parsePath'
import { useHistory } from 'react-router'
import dateFormat from 'shared/utils/dateFormat'
import Divider from 'shared/components/Divider'

type Props = {
  supplierId: string
  attestedDate: Date
}

const SustainabilitySurvey = ({ supplierId, attestedDate }: Props) => {
  const history = useHistory()
  return (
    <>
      <div className='flex justify-end mt3-5'>
        <Text>
          <FormattedMessage
            id='SustainabilitySurvey.LastUpdated'
            defaultMessage='Last Updated: {date}'
            values={{
              date: dateFormat(attestedDate)
            }}
          />
        </Text>
      </div>
      <Divider />
      <div className='flex items-center justify-between flex-wrap mt3'>
        <div className='flex flex-wrap'>
          <div className='ma2 mr4-ns flex flex-column items-center'>
            <img src={healthSafety} alt='Health and Safety' className='w2-5' />
            <Text>
              <FormattedMessage
                id='SustainabilitySurvey.HealthSafety'
                defaultMessage='Health and Safety'
              />
            </Text>
          </div>
          <div className='ma2 mr4-ns flex flex-column items-center'>
            <img
              src={environment}
              alt='Environment'
              className='w2-5'
              style={{ transform: 'scaleX(-1)' }}
            />
            <Text>
              <FormattedMessage
                id='SustainabilitySurvey.Environment'
                defaultMessage='Environment'
              />
            </Text>
          </div>
          <div className='ma2 mr4-ns flex flex-column items-center'>
            <img src={diversity} alt='Diversity' className='w2-5' />
            <Text>
              <FormattedMessage
                id='SustainabilitySurvey.Diversity'
                defaultMessage='Diversity'
              />
            </Text>
          </div>
          <div className='ma2 mr4-ns flex flex-column items-center'>
            <img src={governance} alt='Governance' className='w2-5' />
            <Text>
              <FormattedMessage
                id='SustainabilitySurvey.Governance'
                defaultMessage='Governance'
              />
            </Text>
          </div>
          <div className='ma2 mr4-ns flex flex-column items-center'>
            <img src={privacy} alt='Privacy' className='w2-5' />
            <Text>
              <FormattedMessage
                id='SustainabilitySurvey.Privacy'
                defaultMessage='Privacy'
              />
            </Text>
          </div>
        </div>
        <div className='flex-auto flex justify-end'>
          <Button
            autoSize
            onClick={() =>
              history.push(
                parsePath(paths.supplierEsgSurvey, {
                  supplierId
                })
              )
            }
          >
            <FormattedMessage
              id='SustainabilitySurvey.ViewResponses'
              defaultMessage='View Responses'
            />
          </Button>
        </div>
      </div>
    </>
  )
}

export default SustainabilitySurvey
