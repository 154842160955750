import React, { Component, Fragment, CSSProperties } from 'react'
import classNames from 'classnames'
import Truncate from 'react-truncate'
import Button from '../Button'
import { FormattedMessage } from 'react-intl'

const styles = {
  primary: 'lh-copy f7 fw4 mid-gray ma0',
  secondary: 'lh-copy ma0 f7 fw4 gray'
}

type Props = {
  secondary?: boolean
  truncate?: boolean
  truncateLines?: number
  className?: string
  style?: CSSProperties
  hideShowMoreButton?: boolean
  role?: string
}
/**
 * Display any kind of text
 */
class Text extends Component<Props> {
  state = {
    isTruncated: false,
    showMore: false
  }

  handleTruncate = isTruncated => {
    if (isTruncated !== this.state.isTruncated) {
      this.setState({
        isTruncated
      })
    }
  }

  toggleShowMore = () => {
    this.setState({
      showMore: !this.state.showMore
    })
  }

  renderChildren() {
    const { children } = this.props

    if (typeof children === 'string' && children.indexOf('\n') > -1) {
      return children.split('\n').map((item, i) => (
        <span key={i}>
          {item}
          <br />
        </span>
      ))
    } else {
      return children
    }
  }

  render() {
    const {
      secondary,
      className,
      truncate,
      truncateLines,
      style,
      hideShowMoreButton = false,
      ...rest
    } = this.props
    const { showMore, isTruncated } = this.state

    const textClassName = classNames(
      secondary ? styles.secondary : styles.primary,
      className
    )

    return (
      <p
        className={textClassName}
        style={style ? style : { overflowWrap: 'break-word' }}
        {...rest}
      >
        {truncate && (
          <Fragment>
            {!showMore && (
              <Truncate
                onTruncate={this.handleTruncate}
                lines={truncateLines || 1}
              >
                {this.renderChildren()}
              </Truncate>
            )}

            {showMore && this.renderChildren()}

            {isTruncated && !hideShowMoreButton && (
              <Button
                autoSize
                size='small'
                variant='text'
                label={
                  showMore ? (
                    <FormattedMessage
                      id='Text.See Less'
                      defaultMessage='See less'
                    />
                  ) : (
                    <FormattedMessage id='Text.More' defaultMessage='More' />
                  )
                }
                onClick={this.toggleShowMore}
              />
            )}
          </Fragment>
        )}

        {!truncate && this.renderChildren()}
      </p>
    )
  }
}

export default Text
