import React, { ReactNode } from 'react'
import classNames from 'classnames'
import {
  makeStyles,
  TextField,
  OutlinedTextFieldProps
} from '@material-ui/core'

const styles = {
  input: 'f6 ba b--black-10 border-box pa2 w-100 br1 input-reset',
  inputWithIcon: 'pl4',
  inputError: 'b--light-red',
  disabled: 'bg-near-white gray',
  errorMessage: 'f7 fw3 red mt2 dib'
}

const useStyles = makeStyles({
  outlined: {
    transform: 'translate(14px, 10px) scale(1)'
  },
  placeholder: {
    '&::placeholder': {
      color: '#707070',
      opacity: 1
    }
  }
})

/**
 * This component displays an html text box like:
 * <input/>
 * with extra error handling, usually through React Forms Passing values <br/>
 * When used with redux forms the value of the text box is saved to the redux form state
 * If assigning aria-label and it is used for testing, use ariaLabel props as it connects directly to <inpiut> of textfield
 */

type Props = {
  input?: any
  placeholder?: string | ReactNode
  meta?: { error?: string | ReactNode; touched?: boolean }
  wrapperClassName?: string
  readOnly?: boolean
  leftIcon?: string
  leftIconAlt?: string
  className?: string
  autoComplete?: string
  value?: any
  id?: string
  name?: string
  type?: string
  accept?: string
  maxLength?: number
  required?: boolean
  defaultValue?: string
  label?: string
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  onKeyPress?: (e: any) => void
  onClick?: () => void
  onFocus?: () => void
  style?: any
  min?: number
  max?: number
  step?: number
  minLength?: number
  disabled?: boolean
  focus?: boolean
  ariaLabel?: string
} & Pick<OutlinedTextFieldProps, 'InputProps'>

const Input = React.forwardRef((props: Props, ref: any) => {
  const classes = useStyles()
  const {
    input,
    meta = {},
    wrapperClassName = '',
    readOnly,
    leftIcon,
    leftIconAlt,
    className,
    placeholder,
    focus,
    ariaLabel,
    ...otherProps
  } = props

  const inputClassName = classNames(
    styles.input,
    leftIcon && styles.inputWithIcon,
    readOnly && styles.disabled,
    meta.touched && meta.error && styles.inputError,
    className
  )

  const inputId = (input && (input.id || input.name)) || props.id || props.name

  return (
    <div className={wrapperClassName}>
      <TextField
        ref={ref}
        autoComplete='off'
        variant='outlined'
        className={inputClassName}
        InputLabelProps={{
          className: classes.outlined
        }}
        style={
          leftIcon
            ? {
                backgroundImage: `url(${leftIcon})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center left 10px'
              }
            : undefined
        }
        placeholder={placeholder as string}
        id={inputId}
        autoFocus={focus}
        inputProps={{
          readOnly: readOnly,
          'aria-label': ariaLabel,
          className: classes.placeholder
        }}
        {...input}
        {...otherProps}
      />

      {meta.touched && meta.error && (
        <span className={styles.errorMessage}>{meta.error}</span>
      )}
    </div>
  )
})

export default Input
