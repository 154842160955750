import React, { Component } from 'react'
import { List } from 'immutable'
import imgUpArrow from 'shared/assets/icons/arrow-up-gray.svg'
import imgUpArrowTeal from 'shared/assets/icons/arrow-up-teal.svg'
import imgDownArrow from 'shared/assets/icons/arrow-down-gray.svg'
import imgDownArrowRed from 'shared/assets/icons/arrow-down-red.svg'

const styles = {
  positive: 'ma0 tc f7 fw6 teal',
  zero: 'ma0 tc f7 fw6 gray',
  negative: 'ma0 tc f7 fw6 red'
}

type Props = {
  userId: string
  score: number
  readonly: boolean
  upVotes: List<string>
  downVotes: List<string>
  onUpVote: () => void
  onDownVote: () => void
}

class Votes extends Component<Props> {
  render() {
    const {
      userId,
      score,
      upVotes,
      downVotes,
      readonly,
      onUpVote,
      onDownVote
    } = this.props
    const scoreStyle =
      score === 0 ? styles.zero : score > 0 ? styles.positive : styles.negative

    const upVotedByUser = upVotes.includes(userId)
    const downVotedByUser = downVotes.includes(userId)

    return (
      <div className='dtc v-mid w2 pr3'>
        <img
          className={
            upVotedByUser || readonly ? 'w-100 fb' : 'w-100 fb pointer dim'
          }
          onClick={upVotedByUser || readonly ? undefined : onUpVote}
          src={upVotedByUser ? imgUpArrowTeal : imgUpArrow}
          alt='Up vote'
        />

        <h5 className={scoreStyle}>{score}</h5>

        <img
          className={
            downVotedByUser || readonly ? 'w-100 fb' : 'w-100 fb pointer dim'
          }
          onClick={downVotedByUser || readonly ? undefined : onDownVote}
          src={downVotedByUser ? imgDownArrowRed : imgDownArrow}
          alt='Down vote'
        />
      </div>
    )
  }
}

export default Votes
