import React, { Component } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { List } from 'react-virtualized'
import Page from 'shared/components/Page'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import Loading from 'shared/components/Loading'
import Button from 'shared/components/Button'
import Fab from '@material-ui/core/Fab'
import ContentAdd from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import Text from 'shared/components/Text'
import debounce from 'debounce'
import { FormattedMessage } from 'react-intl'
import { List as ImmutableList } from 'immutable'
import exportData from 'shared/utils/exportData'
import numberFormat from 'shared/utils/numberFormat'

type Props = {
  domains?: ImmutableList<string>
  onAddDomain: (newForbiddenDomain: string) => void
}

type State = {
  visibleDomains?: ImmutableList<string>
  newForbiddenDomain: string
  filter: string
  openDialog: boolean
  checkedDomains: Array<string>
  forbiddenDomain: string
}

class ForbiddenDomains extends Component<Props, State> {
  refs: any

  state: State = {
    visibleDomains: undefined,
    newForbiddenDomain: '',
    filter: '',
    openDialog: false,
    checkedDomains: [],
    forbiddenDomain: ''
  }

  updateVisibleDomains = debounce((domains, filter) => {
    this.setState({
      visibleDomains: domains.filter(d => d.indexOf(filter) > -1)
    })
  }, 100)

  changeFilter = e => {
    this.setState({
      filter: e.currentTarget.value
    })

    if (this.props.domains && this.props.domains.size > 0) {
      this.updateVisibleDomains(this.props.domains, e.currentTarget.value)
    }
  }

  toggleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
      forbiddenDomain: ''
    })
  }

  handleAddDomain = e => {
    e.preventDefault()

    const { domains, onAddDomain } = this.props
    const { newForbiddenDomain } = this.state

    if (domains && !domains.includes(newForbiddenDomain)) {
      onAddDomain(newForbiddenDomain)
    }

    this.setState({
      openDialog: false,
      forbiddenDomain: '',
      filter: ''
    })
  }

  handleChangeNewDomain = e => {
    this.setState({
      newForbiddenDomain: e.currentTarget.value
    })
  }

  handleClear = () => {
    this.setState({
      newForbiddenDomain: ''
    })
  }

  handleToggle = value => () => {
    const { checkedDomains } = this.state
    const currentIndex = checkedDomains.indexOf(value)
    const newCheckedDomains = [...checkedDomains]

    if (currentIndex === -1) {
      newCheckedDomains.push(value)
    } else {
      newCheckedDomains.splice(currentIndex, 1)
    }

    this.setState({
      checkedDomains: newCheckedDomains
    })

    this.refs.List.forceUpdateGrid()
  }

  render() {
    const {
      newForbiddenDomain,
      openDialog,
      visibleDomains,
      filter
    } = this.state

    const domains = visibleDomains || this.props.domains

    return (
      <Page
        title={
          <FormattedMessage
            id='ForbiddenDomains.ForbiddenDomains'
            defaultMessage='Forbidden Domains'
          />
        }
      >
        <FormattedMessage
          id='ForbiddenDomains.SearchDoamin'
          defaultMessage='Search domain...'
        >
          {message => (
            <Input
              placeholder={message as string}
              onChange={this.changeFilter}
              className='mb3'
              value={filter}
            />
          )}
        </FormattedMessage>

        {this.props.domains && this.props.domains.size > 0 && (
          <div className='mb2 tr'>
            <Text className='mr3 dib'>
              {numberFormat(this.props.domains.size)} domains
            </Text>
            <Button
              label='Export'
              onClick={() =>
                this.props.domains &&
                exportData.exportCSV(
                  this.props.domains
                    .map(domain => ({
                      domain
                    }))
                    .toJS(),
                  'Tealbook Forbidden Domains'
                )
              }
              autoSize
            />
          </div>
        )}

        {domains && domains.size > 0 && (
          <List
            ref='List'
            width={700}
            height={640}
            rowCount={domains.size}
            rowHeight={50}
            rowRenderer={({ index, key, style }) => {
              const { visibleDomains } = this.state

              const domains = visibleDomains || this.props.domains
              const domain = domains?.get(index)

              return (
                <ListItem key={key} style={style as any} dense>
                  <ListItemText>{domain}</ListItemText>
                </ListItem>
              )
            }}
          />
        )}

        {!domains && <Loading />}

        <div className='fixed bottom-1 bottom-2-ns tr w-100 mw8 pr6-ns pr4'>
          <Fab onClick={this.toggleDialog} color='primary'>
            <ContentAdd color='inherit' />
          </Fab>
        </div>

        <Dialog open={openDialog} onClose={this.toggleDialog}>
          <form onSubmit={this.handleAddDomain}>
            <DialogContent>
              <Label className='mb3 db'>
                <FormattedMessage
                  id='ForbiddenDomains.AddANewForbiddenDomain'
                  defaultMessage='Add a new Forbidden Domain'
                />
              </Label>
              <FormattedMessage
                id='ForbiddenDomains.EnterAForbiddenDomain'
                defaultMessage='Enter a forbidden domain'
              >
                {message => (
                  <Input
                    placeholder={message as string}
                    name='newForbiddenDomain'
                    value={newForbiddenDomain}
                    onChange={this.handleChangeNewDomain}
                    required
                  />
                )}
              </FormattedMessage>
            </DialogContent>
            <DialogActions>
              <Button type='submit'>
                <FormattedMessage
                  id='ForbiddenDomains.Add'
                  defaultMessage='Add'
                />
              </Button>
              <Button onClick={this.handleClear} secondary>
                <FormattedMessage
                  id='ForbiddenDomains.Clear'
                  defaultMessage='Clear'
                />
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Page>
    )
  }
}

export default ForbiddenDomains
