import { call, select } from 'redux-saga/effects'
import vets from 'shared/utils/api/vets'
import users from 'shared/utils/api/users'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import FormError from 'shared/utils/FormError'

//2 emails: users.invite api calls email method but not sending optional message here,
// vets.addTeamMember sends email with optional message

export function* addVetTeamMemberSaga(action) {
  const vetId = yield select(currentVetSelectors.getVetField, 'id')
  const vetTeam = yield select(currentVetSelectors.getTeamIds)
  let { user, message } = action.payload.toJS()

  let isCreatingNewUser
  if (!user.id) {
    if (!user.email) {
      throw new FormError('Please select a user')
    }
    const currentUser = yield select(sessionSelectors.getUser)
    const orgUnitId = currentUser.get('orgUnitId')
    user.roles = ['buyer']
    user = yield call(users.inviteUser, { user, orgUnitId })
    isCreatingNewUser = true
  }

  if (!vetTeam.some(u => u.user === user.id)) {
    yield call(vets.addTeamMember, {
      vetId,
      userId: user.id,
      message
    })
  }

  return {
    user,
    isCreatingNewUser
  }
}

export default createApiRequestSaga(addVetTeamMemberSaga)
