export const UPDATE_SETTINGS = 'admin/UPDATE_SETTINGS'

export const OPEN_USER_DIALOG = 'admin/OPEN_ADD_USER_DIALOG'
export const CLOSE_USER_DIALOG = 'admin/CLOSE_USER_DIALOG'
export const ADD_USER = 'admin/ADD_USER'
export const BULK_ADD_USERS = 'admin/BULK_ADD_USERS'
export const LOAD_USERS = 'LOAD_USERS'

export const START_EDITING_USER = 'START_EDITING_USER'
export const CANCEL_EDITING_USER = 'CANCEL_EDITING_USER'

export const LOAD_CLIENT_DATA = 'admin/LOAD_CLIENT_DATA'

export const UPLOAD_CLIENT_FILE = 'admin/UPLOAD_CLIENT_FILE'
export const BULK_DELETE_USERS = 'admin/BULK_DELETE_USERS'
export const BULK_UPDATE_STATUS = 'admin/BULK_UPDATE_STATUS'
export const BULK_UPDATE_ROLES = 'admin/BULK_UPDATE_ROLES'
export const SELECT_BULK_USERS = 'admin/SELECT_BULK_USERS'
export const RESEND_USER_INVITE = 'admin/RESEND_USER_INVITE'

export const GENERATE_USER_TOKEN = 'admin/GENERATE_USER_TOKEN'
export const LINK_BUYER_WITH_INTEGRATION = 'admin/LINK_BUYER_WITH_INTEGRATION'

export const CLOSE_REQUEST_RATING_DIALOG = 'admin/CLOSE_REQUEST_RATING_DIALOG'
export const CLOSE_BULK_ASSESSMENT_ERRORS_DIALOG =
  'admin/CLOSE_BULK_ASSESSMENT_ERRORS_DIALOG'

export const LOAD_WEBHOOK_CONFIGS = 'admin/LOAD_WEBHOOK_CONFIGS'
export const SAVE_WEBHOOK_CONFIG = 'admin/SAVE_WEBHOOK_CONFIG'
