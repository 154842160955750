import { createSelector, defaultMemoize } from 'reselect'
import { connect } from 'react-redux'
import { List, fromJS } from 'immutable'
import BuyerProfile from '../../components/BuyerProfile'
import { loadData, messageUser } from 'shared/actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import dataSelectors from 'shared/selectors/dataSelectors'
import { loadVets, followClient } from 'supplier/shared/actions'
import {
  addCustomer,
  removeCustomer,
  openGetVerifiedDialog,
  closeGetVerifiedDialog
} from 'supplier/Company/actions'
import vetBuyerSupplierSelectors from 'supplier/Vets/selectors/vetBuyerSupplierSelectors'
import companySelectors from '../../selectors/companySelectors'
import statsSelectors from '../../selectors/statsSelectors'
import RootState from 'shared/models/RootState'
import { RouteComponentProps } from 'react-router-dom'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import parseLocationToString from 'shared/utils/data/parseLocationToString'
import { loadTier2AllocatedSpend } from '../../../Insight/store/actions'

const contactsSelector = createSelector(
  (state, orgUnitId) =>
    dataSelectors.getById(state, 'orgUnits', orgUnitId).get('communities'),
  (state, orgUnitId) =>
    dataSelectors.getById(state, 'orgUnits', orgUnitId).get('contacts'),
  (state, orgUnitId) =>
    dataSelectors.getById(state, 'orgUnits', orgUnitId).get('users'),
  (communities, contacts, users) => {
    let buyerContacts = {}
    if (communities) {
      communities.forEach(community => {
        community.get('contacts') &&
          community.get('contacts').forEach(contact => {
            const user = community.getIn(['users', contact.get('user')])
            if (user) {
              buyerContacts[user.get('id')] = contact
                .merge(user)
                .update('communities', communities =>
                  communities
                    ? communities.push(community.get('name'))
                    : fromJS([community.get('name')])
                )
            }
          })
      })
    }

    if (contacts && contacts.size > 0) {
      contacts.forEach(c => {
        const user = users.find(u => u.get('id') === c.get('user'))
        buyerContacts[user.get('id')] = c.merge(user)
      })
    }

    return List(Object.values(buyerContacts))
  }
)

const supplierRelationshipSelector = createSelector(
  state => dataSelectors.getByEntity(state, 'supplierRelationships'),
  (state, clientId) => clientId,
  (supplierRelationships, clientId) =>
    supplierRelationships.toList().find(rel => rel.get('buyer') === clientId) ||
    fromJS({})
)

const numberOfConnectionsSelector = defaultMemoize(
  connectedUsers =>
    connectedUsers &&
    connectedUsers.reduce(
      (total, user) => total + user.get('connectedTo').size,
      0
    )
)

const getAllocatedSpendByOrgId = defaultMemoize(
  (state: RootState, orgUnitId: string) => {
    const buyersAllocatedTo = state.getIn([
      'supplier',
      'tier2',
      'allocatedSpend',
      'buyers'
    ])
    return (
      buyersAllocatedTo &&
      buyersAllocatedTo.find(buyer => buyer.orgUnitId === orgUnitId)
    )
  }
)

type ContainerProps = RouteComponentProps<{ orgUnitId: string }>

export default connect(
  (state: RootState, props: ContainerProps) => {
    const orgUnit = dataSelectors.getById(
      state,
      'orgUnits',
      props.match.params.orgUnitId
    )
    const supplierRelationships = dataSelectors.getByEntity(
      state,
      'supplierRelationships'
    )
    const contacts = contactsSelector(state, props.match.params.orgUnitId)
    const supplierRelationship = supplierRelationshipSelector(
      state,
      props.match.params.orgUnitId
    )
    const following =
      supplierRelationship &&
      supplierRelationship.getIn(['supplierCollaboration', 'following'])
    const isClient =
      supplierRelationship &&
      supplierRelationship.getIn(['supplierCollaboration', 'isClient'])
    const requestVerify =
      supplierRelationship &&
      supplierRelationship.getIn(['supplierCollaboration', 'requestVerify'])
    const verifyClient =
      supplierRelationship &&
      supplierRelationship.getIn(['buyerCollaboration', 'verifyClient'])

    const domains = orgUnit.get('domains')
    const allConnections = statsSelectors.getConnections(state) || fromJS({})
    const connections = allConnections.getIn([
      props.match.params.orgUnitId,
      'users'
    ])

    const language = state.get('i18n').locale
    const description = orgUnit.get('buyerDescription')
    const allocatedSpend = getAllocatedSpendByOrgId(
      state,
      props.match.params.orgUnitId
    )
    return {
      loadingOrgUnit: !orgUnit.size,
      isPremium: companySelectors.isPremium(state),
      clientId: orgUnit.get('id'),
      contacts,
      supplierId: companySelectors.getCompanyField(state, 'id'),
      supplierRelationships,
      supplierRelationshipId:
        supplierRelationship && supplierRelationship.get('id'),
      following,
      numberOfConnections: numberOfConnectionsSelector(connections),
      connections,
      isClient,
      requestVerify,
      verifyClient,
      communities: orgUnit.get('communities'),
      userOrgUnitId: sessionSelectors.getOrgUnitId(state),
      name: orgUnit.get('name'),
      domains,
      websiteUrl:
        orgUnit.getIn(['links', 'websiteUrl']) || (domains && domains.first()),
      logoUrl:
        fileToUrl(
          orgUnit.get('logo') ||
            orgUnit.getIn(['buyer', 'logo']) ||
            orgUnit.getIn(['supplier', 'logo'])
        ) ||
        (domains && domains.size > 0
          ? `https://logo.clearbit.com/${domains.first()}`
          : ''),
      address: parseLocationToString(
        orgUnit.getIn(['locations', 0], fromJS({}))
      ),
      description:
        !description || typeof description === 'string'
          ? description
          : description.get(language) ||
            description.get('en') ||
            description
              .toList()
              .filter((d: string) => !!d)
              .first(),
      publicView: true,
      hasVets: vetBuyerSupplierSelectors.getVets(state).get('items').size > 0,
      verifyingOrgUnitId: state.getIn([
        'supplier',
        'company',
        'verifyingOrgUnitId'
      ]),
      allocatedSpend: allocatedSpend && allocatedSpend.spend
    }
  },
  {
    loadVets,
    loadData,
    messageUser,
    followClient,
    addCustomer,
    removeCustomer,
    openGetVerifiedDialog,
    closeGetVerifiedDialog,
    loadTier2AllocatedSpend
  }
)(BuyerProfile)
