import RootState from 'shared/models/RootState'
import { createSelector } from 'reselect'

export const isUploading = (state: RootState) => {
  return state.getIn(['dataPortal', 'dataQuality', 'uploading'])
}

export const getColumnsMapping = (state: RootState) =>
  state.getIn(['dataPortal', 'dataQuality', 'bdoResponse', 'output'])

export const getUploadJobId = (state: RootState) => {
  const bdoResponse = state
    .getIn(['dataPortal', 'dataQuality', 'bdoResponse'])
    .toJS()
  const jobId = Object.keys(bdoResponse).find(key => key.startsWith('Job-'))
  return jobId
}

export const getUploadFilename = (state: RootState) => {
  const jobId = getUploadJobId(state)
  return (
    jobId && state.getIn(['dataPortal', 'dataQuality', 'bdoResponse', jobId])
  )
}

export const isSubmittingColumnsMapping = (state: RootState) => {
  return state.getIn(['dataPortal', 'dataQuality', 'submittingColumnsMapping'])
}

export const getJobs = (state: RootState) =>
  state.getIn(['dataPortal', 'dataQuality', 'jobs'])

export const isLoading = (state: RootState) =>
  state.getIn(['dataPortal', 'dataQuality', 'loading'])

export const getJob = createSelector(
  (state: RootState, jobId) => jobId,
  getJobs,
  (jobId, jobs) => {
    return jobs?.find(job => job.get('id') === jobId)
  }
)

export const getJobStatus = (state: RootState) =>
  state.getIn(['dataPortal', 'dataQuality', 'jobStatus'])

export const getJobStatusSummary = createSelector(getJobStatus, jobStatus =>
  jobStatus.get('dqRowsStatus')
)

export const getJobDerelictRows = createSelector(getJobStatus, jobStatus =>
  jobStatus.get('derelictRows')
)
