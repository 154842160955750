import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'
import suppliers from 'shared/utils/api/suppliers'
import Immutable from 'immutable'

export function* saveCompanyInfoSaga(action) {
  const {
    pitchDeckFile,
    alternativeNames,
    status,
    ...infoToUpdate
  } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  let updatedOrgUnit = yield call(supplier.update, {
    alternativeNames: parseAlternativeNames(alternativeNames),
    ...infoToUpdate
  })

  if (pitchDeckFile) {
    const newPitchDeckFile = yield call(
      supplier.uploadFile,
      'pitchDeckFile',
      pitchDeckFile
    )
    updatedOrgUnit.supplier.pitchDeckFile = newPitchDeckFile
  }

  if (status) {
    updatedOrgUnit = yield call(suppliers.updateStatus, {
      supplierId: updatedOrgUnit.id,
      status,
      websiteWarning: status !== 'Inactive' ? '' : undefined
    })
  }

  // don't try to onboard after first save
  supplier.onboard()

  return yield updatedOrgUnit
}

export default createApiRequestSaga(saveCompanyInfoSaga)

const parseAlternativeNames = alternativeNames =>
  alternativeNames && alternativeNames.filter(name => !!name)
