import { ZendeskAPI } from 'react-zendesk'

const chatbot = {
  initialize: (user: {
    name: string
    email: string
    language: 'en' | 'fr' | 'es'
    organization: string
    roles: string
    userId: string
    orgUnitId: string
  }) => {
    if (ZendeskAPI) {
      ZendeskAPI('webWidget', 'close')
      ZendeskAPI('webWidget', 'reset')
      ZendeskAPI('webWidget', 'setLocale', user.language)
      ZendeskAPI('webWidget', 'identify', {
        name: user.name,
        email: user.email,
        organization: user.organization,
        roles: user.roles,
        userId: user.userId,
        orgUnitId: user.orgUnitId
      })
      // ZendeskAPI('webWidget', 'chat:addTags', [user.organization, user.orgUnitId])
      // ZendeskAPI('webWidget', 'updateSettings', {
      //   webWidget: {
      //     contactForm: {
      //       fields: [
      //         { id: 'description', prefill: { '*': 'My field text' } },
      //         { id: 360039159231, prefill: { '*': user.orgUnitId } }
      //       ]
      //     }
      //   }
      // })
      ZendeskAPI('webWidget', 'prefill', {
        name: {
          value: user.name,
          readOnly: true // optional
        },
        email: {
          value: user.email,
          readOnly: true // optional
        },
        description: {
          value: user.roles,
          readOnly: true
        }
      })
    }
  },
  open: () => {
    if (ZendeskAPI) {
      ZendeskAPI('webWidget', 'show')
      ZendeskAPI('webWidget', 'open')
      const tealbotSupport: HTMLDivElement | null = document.querySelector(
        '#tealbotSupport'
      )
      if (tealbotSupport) {
        tealbotSupport.style.opacity = '1'
      }
    }
  },
  updateSettings: settings => {
    if (ZendeskAPI) {
      ZendeskAPI('webWidget', 'updateSettings', settings)
    }
  }
}

export default chatbot
