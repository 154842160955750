import React, { FC, Fragment } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf, Map } from 'immutable'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import RootState from 'shared/models/RootState'
import dateFormat from 'shared/utils/dateFormat'
import parseLocationToString from 'shared/utils/data/parseLocationToString'
import Location from 'shared/models/Location'
import startCase from 'lodash.startcase'

const styles = {
  tableStyle: {
    width: '100%',
    fontFamily: 'sans-serif',
    fontSize: 14,
    border: '1px solid #7f7f7f'
  },
  tableHeaderStyle: {
    background: '#7f7f7f',
    color: 'white',
    fontWeight: 600,
    padding: '10px 5px'
  },
  tableSubHeaderStyle: {
    background: '#cccccc',
    fontWeight: 600,
    padding: '10px 5px'
  },
  labelCellStyle: {
    border: '1px solid #7f7f7f',
    padding: 5,
    fontWeight: 600,
    width: '25%',
    verticalAlign: 'top'
  },
  valueCellStyle: {
    border: '1px solid #7f7f7f',
    padding: 5,
    verticalAlign: 'top'
  },
  valueLineThroughCellStyle: {
    border: '1px solid #7f7f7f',
    padding: 5,
    verticalAlign: 'top',
    textDecoration: 'line-through'
  }
}

type Props = {
  companyName: string
  createdBy: string
  createdOn: string
  vet: RecordOf<{
    name: string
    internalDescription: string
    plannedCompletionDate: string
    category: string
    department: string
    location: string
    requestedBy: string
    projectEstimate?: string
    projectBudget: string
    spendType: string
    spendStart: Date
    spendEnd: Date
    approvedBudget: number
    inviteSuppliers: boolean
    anonymousInvitation: boolean
    description: string
    detailedReponseRequested: boolean
    responseInstructions: string
    decision: string
    budget: string
    decisionDate: Date
    responseDate: Date
    criticalCriteria: List<string>
  }>
  suppliersInGroup: Map<
    string,
    List<
      RecordOf<{
        supplier: {
          name: string
          domains: List<string>
          locations: List<Location>
        }
        vetBS: {
          primaryLocation?: number
        }
        keyContacts: List<
          RecordOf<{
            user: string
            name: string
            email: string
            phone: string
          }>
        >
      }>
    >
  >
  additions: List<
    RecordOf<{
      active: boolean
      type: string
      date: string
      title: string
      description: string
      attachment: string
    }>
  >
}

export const VetExportLayout: FC<Props> = ({
  companyName,
  createdBy,
  createdOn,
  vet,
  suppliersInGroup,
  additions
}) => {
  return (
    <Fragment>
      <h2 style={{ fontFamily: 'sans-serif', textAlign: 'center' }}>
        <FormattedMessage
          id='VetExportLayout.Header'
          defaultMessage='RFI Discovery'
        />
      </h2>
      <table style={styles.tableStyle}>
        <tbody>
          <tr>
            <td style={styles.labelCellStyle}>
              <FormattedMessage
                id='VetExportLayout.Title'
                defaultMessage='Title'
              />
            </td>
            <td style={styles.valueCellStyle}>{vet.get('name')}</td>
          </tr>
          <tr>
            <td style={styles.labelCellStyle}>
              <FormattedMessage
                id='VetExportLayout.Company'
                defaultMessage='Company Name'
              />
            </td>
            <td style={styles.valueCellStyle}>{companyName}</td>
          </tr>
          <tr>
            <td style={styles.labelCellStyle}>
              <FormattedMessage
                id='VetExportLayout.CreatedBy'
                defaultMessage='Created By'
              />
            </td>
            <td style={styles.valueCellStyle}>{createdBy}</td>
          </tr>
          <tr>
            <td style={styles.labelCellStyle}>
              <FormattedMessage
                id='VetExportLayout.CreatedDate'
                defaultMessage='Created On'
              />
            </td>
            <td style={styles.valueCellStyle}>{createdOn}</td>
          </tr>
          {!!vet.get('plannedCompletionDate') && (
            <tr>
              <td style={styles.labelCellStyle}>
                <FormattedMessage
                  id='VetExportLayout.CompletionDate'
                  defaultMessage='Planned Completion On'
                />
              </td>
              <td style={styles.valueCellStyle}>
                {dateFormat(vet.get('plannedCompletionDate'), undefined, true)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <h3 style={{ fontFamily: 'sans-serif' }}>
        <FormattedMessage
          id='VetExportLayout.InternalOverview'
          defaultMessage='Details - Internal Overview'
        />
      </h3>
      <table style={styles.tableStyle}>
        <tbody>
          <tr>
            <td style={styles.labelCellStyle}>
              <FormattedMessage
                id='VetExportLayout.DetailsSummary'
                defaultMessage='Summary'
              />
            </td>
            <td style={styles.valueCellStyle}>
              {vet.get('internalDescription')}
            </td>
          </tr>
          <tr>
            <td style={styles.labelCellStyle}>
              <FormattedMessage
                id='VetExportLayout.DetailDepartment'
                defaultMessage='Department'
              />
            </td>
            <td style={styles.valueCellStyle}>{vet.get('department')}</td>
          </tr>
          {vet.get('category') && (
            <tr>
              <td style={styles.labelCellStyle}>
                <FormattedMessage
                  id='VetExportLayout.DetailCategory'
                  defaultMessage='Category'
                />
              </td>
              <td style={styles.valueCellStyle}>
                {startCase(vet.get('category').split('-')[0])}
              </td>
            </tr>
          )}
          {vet.get('category') && vet.get('category').split('-').length > 1 && (
            <tr>
              <td style={styles.labelCellStyle}>
                <FormattedMessage
                  id='VetExportLayout.DetailSubCategory'
                  defaultMessage='Subcategory'
                />
              </td>
              <td style={styles.valueCellStyle}>
                {startCase(vet.get('category').split('-')[1])}
              </td>
            </tr>
          )}
          {!!vet.get('spendType') && (
            <tr>
              <td style={styles.labelCellStyle}>
                <FormattedMessage
                  id='VetExportLayout.DetailSpendType'
                  defaultMessage='Spend Type'
                />
              </td>
              <td style={styles.valueCellStyle}>{vet.get('spendType')}</td>
            </tr>
          )}
          {!!vet.get('spendStart') && (
            <tr>
              <td style={styles.labelCellStyle}>
                <FormattedMessage
                  id='VetExportLayout.DetailSpendStart'
                  defaultMessage='Spend Start'
                />
              </td>
              <td style={styles.valueCellStyle}>
                {dateFormat(vet.get('spendStart'), undefined, true)}
              </td>
            </tr>
          )}
          {!!vet.get('spendEnd') && (
            <tr>
              <td style={styles.labelCellStyle}>
                <FormattedMessage
                  id='VetExportLayout.DetailSpendEnd'
                  defaultMessage='Spend End'
                />
              </td>
              <td style={styles.valueCellStyle}>
                {dateFormat(vet.get('spendEnd'), undefined, true)}
              </td>
            </tr>
          )}
          {!!vet.get('projectEstimate') && (
            <tr>
              <td style={styles.labelCellStyle}>
                <FormattedMessage
                  id='VetExportLayout.DetailProjectEstimate'
                  defaultMessage='Project Estimate'
                />
              </td>
              <td style={styles.valueCellStyle}>
                {vet.get('projectEstimate')}
              </td>
            </tr>
          )}
          {!!vet.get('approvedBudget') && (
            <tr>
              <td style={styles.labelCellStyle}>
                <FormattedMessage
                  id='VetExportLayout.DetailApprovedBudget'
                  defaultMessage='Approved Budget'
                />
              </td>
              <td style={styles.valueCellStyle}>{vet.get('approvedBudget')}</td>
            </tr>
          )}
        </tbody>
      </table>
      {!!vet.get('inviteSuppliers') && (
        <Fragment>
          <h3 style={{ fontFamily: 'sans-serif' }}>
            <FormattedMessage
              id='VetExportLayout.Invitation'
              defaultMessage='Invitation'
            />
          </h3>
          <table style={styles.tableStyle}>
            <tbody>
              <tr>
                <td style={styles.labelCellStyle}>
                  <FormattedMessage
                    id='VetExportLayout.InvitationSummary'
                    defaultMessage='Summary'
                  />
                </td>
                <td style={styles.valueCellStyle}>{vet.get('description')}</td>
              </tr>
              <tr>
                <td style={styles.labelCellStyle}>
                  <FormattedMessage
                    id='VetExportLayout.InvitationBudget'
                    defaultMessage='Budget'
                  />
                </td>
                <td style={styles.valueCellStyle}>{vet.get('budget')}</td>
              </tr>
              <tr>
                <td style={styles.labelCellStyle}>
                  <FormattedMessage
                    id='VetExportLayout.InvitationLocation'
                    defaultMessage='Location'
                  />
                </td>
                <td style={styles.valueCellStyle}>{vet.get('location')}</td>
              </tr>
              <tr>
                <td style={styles.labelCellStyle}>
                  <FormattedMessage
                    id='VetExportLayout.InvitationAnonymous'
                    defaultMessage='Stay Anonymous'
                  />
                </td>
                <td style={styles.valueCellStyle}>
                  {vet.get('anonymousInvitation') ? (
                    <FormattedMessage
                      id='VetExportLayout.Yes'
                      defaultMessage='Yes'
                    />
                  ) : (
                    <FormattedMessage id='No' />
                  )}
                </td>
              </tr>
              <tr>
                <td style={styles.labelCellStyle}>
                  <FormattedMessage
                    id='VetExportLayout.InvitationResponseBy'
                    defaultMessage='Response By'
                  />
                </td>
                <td style={styles.valueCellStyle}>
                  {dateFormat(vet.get('responseDate'), undefined, true)}
                </td>
              </tr>
              <tr>
                <td style={styles.labelCellStyle}>
                  <FormattedMessage
                    id='VetExportLayout.InvitationDetailedResponse'
                    defaultMessage='Detailed Response'
                  />
                </td>
                <td style={styles.valueCellStyle}>
                  {vet.get('detailedReponseRequested') ? (
                    <FormattedMessage id='VetExportLayout.Yes' />
                  ) : (
                    <FormattedMessage id='VetExportLayout.No' />
                  )}
                </td>
              </tr>
              {vet.get('detailedReponseRequested') && (
                <tr>
                  <td style={styles.labelCellStyle}>
                    <FormattedMessage
                      id='VetExportLayout.InvitationResponseInstructions'
                      defaultMessage='Response Instructions'
                    />
                  </td>
                  <td style={styles.valueCellStyle}>
                    {vet.get('responseInstructions')}
                  </td>
                </tr>
              )}
              {vet.get('criticalCriteria') &&
                vet.get('criticalCriteria').size > 0 && (
                  <tr>
                    <td style={styles.labelCellStyle}>
                      <FormattedMessage
                        id='VetExportLayout.InvitationCriteria'
                        defaultMessage='Critical Criteria'
                      />
                    </td>
                    <td style={styles.valueCellStyle}>
                      {vet.get('criticalCriteria').join(', ')}
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          {additions && additions.size > 0 && (
            <Fragment>
              <h3 style={{ fontFamily: 'sans-serif' }}>
                <FormattedMessage
                  id='VetExportLayout.Additions'
                  defaultMessage='Additions'
                />
              </h3>
              <table style={styles.tableStyle}>
                <thead>
                  <tr>
                    <th style={styles.tableHeaderStyle}>
                      <FormattedMessage
                        id='VetExportLayout.AdditionsType'
                        defaultMessage='Type'
                      />
                    </th>
                    <th style={styles.tableHeaderStyle}>
                      <FormattedMessage
                        id='VetExportLayout.AdditionsDate'
                        defaultMessage='Date'
                      />
                    </th>
                    <th style={styles.tableHeaderStyle}>
                      <FormattedMessage
                        id='VetExportLayout.AdditionsTitle'
                        defaultMessage='Title'
                      />
                    </th>
                    <th style={styles.tableHeaderStyle}>
                      <FormattedMessage
                        id='VetExportLayout.AdditionsDescription'
                        defaultMessage='Description'
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {additions.map((addition, index) => (
                    <tr key={`addition-${index}`}>
                      <td
                        style={
                          addition.get('active')
                            ? styles.valueCellStyle
                            : styles.valueLineThroughCellStyle
                        }
                      >
                        {addition.get('type')}
                      </td>
                      <td
                        style={
                          addition.get('active')
                            ? styles.valueCellStyle
                            : styles.valueLineThroughCellStyle
                        }
                      >
                        {dateFormat(addition.get('date'), undefined, true)}
                      </td>
                      <td
                        style={
                          addition.get('active')
                            ? styles.valueCellStyle
                            : styles.valueLineThroughCellStyle
                        }
                      >
                        {addition.get('title')}
                      </td>
                      <td
                        style={
                          addition.get('active')
                            ? styles.valueCellStyle
                            : styles.valueLineThroughCellStyle
                        }
                      >
                        {addition.get('description')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Fragment>
          )}
        </Fragment>
      )}
      {suppliersInGroup && suppliersInGroup.size > 0 && (
        <Fragment>
          <h3 style={{ fontFamily: 'sans-serif' }}>
            <FormattedMessage
              id='VetExportLayout.Suppliers'
              defaultMessage='Suppliers on RFI'
            />
          </h3>
          <table style={styles.tableStyle}>
            <thead>
              <tr>
                <th style={styles.tableHeaderStyle}>
                  <FormattedMessage
                    id='VetExportLayout.SuppliersName'
                    defaultMessage='Name'
                  />
                </th>
                <th style={styles.tableHeaderStyle}>
                  <FormattedMessage
                    id='VetExportLayout.SuppliersDomain'
                    defaultMessage='Domain'
                  />
                </th>
                <th style={styles.tableHeaderStyle}>
                  <FormattedMessage
                    id='VetExportLayout.SuppliersAddress'
                    defaultMessage='Address'
                  />
                </th>
                <th style={styles.tableHeaderStyle}>
                  <FormattedMessage
                    id='VetExportLayout.SupplierPhone'
                    defaultMessage='Supplier Phone'
                  />
                </th>
                <th style={styles.tableHeaderStyle}>
                  <FormattedMessage
                    id='VetExportLayout.SuppliersIncumbent'
                    defaultMessage='Incumbent'
                  />
                </th>
                <th style={styles.tableHeaderStyle}>
                  <FormattedMessage
                    id='VetExportLayout.SuppliersContact'
                    defaultMessage='Contact'
                  />
                </th>
                <th style={styles.tableHeaderStyle}>
                  <FormattedMessage
                    id='VetExportLayout.SuppliersEmail'
                    defaultMessage='Email'
                  />
                </th>
                <th style={styles.tableHeaderStyle}>
                  <FormattedMessage
                    id='VetExportLayout.Phone'
                    defaultMessage='Phone'
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {suppliersInGroup
                .map((suppliers, group) => (
                  <Fragment key={group}>
                    <tr>
                      <td colSpan={8} style={styles.tableSubHeaderStyle}>
                        {group}
                      </td>
                    </tr>
                    {suppliers.map((supplier, index) => (
                      <Fragment key={`supplier-${index}`}>
                        <tr>
                          <td
                            style={styles.valueCellStyle}
                            rowSpan={supplier.getIn(['keyContacts']).size}
                          >
                            {supplier.getIn(['supplier', 'name'])}
                          </td>
                          <td
                            style={styles.valueCellStyle}
                            rowSpan={supplier.getIn(['keyContacts']).size}
                          >
                            {supplier.getIn(['supplier', 'domains']).join(', ')}
                          </td>
                          <td
                            style={styles.valueCellStyle}
                            rowSpan={supplier.getIn(['keyContacts']).size}
                          >
                            {supplier.getIn([
                              'supplier',
                              'supplier',
                              'locations'
                            ]).size !== 0
                              ? parseLocationToString(
                                  supplier.getIn([
                                    'supplier',
                                    'supplier',
                                    'locations',
                                    supplier.getIn([
                                      'vetBS',
                                      'primaryLocation'
                                    ]) || 0
                                  ])
                                )
                              : ''}
                          </td>
                          <td
                            style={styles.valueCellStyle}
                            rowSpan={supplier.getIn(['keyContacts']).size}
                          >
                            {currentVetSuppliersSelectors.getPhoneForSupplier(
                              supplier
                            )}
                          </td>
                          <td
                            style={styles.valueCellStyle}
                            rowSpan={supplier.getIn(['keyContacts']).size}
                          >
                            {!!supplier.getIn(['vetBS', 'incumbent']) ? (
                              <FormattedMessage
                                id='VetExportLayout.Yes'
                                defaultMessage='Yes'
                              />
                            ) : (
                              <FormattedMessage id='No' />
                            )}
                          </td>
                          <td style={styles.valueCellStyle}>
                            {supplier.getIn(['keyContacts', 0])
                              ? supplier.getIn(['keyContacts', 0]).get('name')
                              : ''}
                          </td>
                          <td style={styles.valueCellStyle}>
                            {supplier.getIn(['keyContacts', 0])
                              ? supplier.getIn(['keyContacts', 0]).get('email')
                              : ''}
                          </td>
                          <td style={styles.valueCellStyle}>
                            {supplier.getIn(['keyContacts', 0])
                              ? supplier.getIn(['keyContacts', 0]).get('phone')
                              : ''}
                          </td>
                        </tr>
                        {supplier.getIn(['keyContacts', 1]) && (
                          <tr
                            key={supplier.getIn(['keyContacts', 1]).get('user')}
                          >
                            <td style={styles.valueCellStyle}>
                              {supplier.getIn(['keyContacts', 1]).get('name')}
                            </td>
                            <td style={styles.valueCellStyle}>
                              {supplier.getIn(['keyContacts', 1]).get('email')}
                            </td>
                            <td style={styles.valueCellStyle}>
                              {supplier.getIn(['keyContacts', 1]).get('phone')}
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    ))}
                  </Fragment>
                ))
                .toList()}
            </tbody>
          </table>
        </Fragment>
      )}
    </Fragment>
  )
}

export default connect((state: RootState) => {
  const vet = currentVetSelectors.getVet(state)
  const user =
    vet && usersSelectors.getById(state, vet.getIn(['created', 'user']))

  return {
    companyName: settingsSelectors.getName(state),
    createdBy: user && `${user.get('firstName')} ${user.get('lastName')}`,
    createdOn:
      vet && dateFormat(vet.getIn(['created', 'date']), undefined, true),
    vet,
    suppliersInGroup: currentVetSuppliersSelectors.getVetSuppliersInGroup(
      state
    ),
    additions: currentVetSelectors.getAdditions(state)
  }
})(VetExportLayout)
