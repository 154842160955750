import React from 'react'
import { FormattedMessage } from 'react-intl'
import ShowQuestionResponse from './ShowQuestionResponse'
import ScrollToPosition from 'shared/components/ScrollToPosition'

const pageId = 'diversityInclusion'

const DiversityInclusion = () => {
  return (
    <div>
      <ScrollToPosition />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='diversityCommitment'
        question={
          <FormattedMessage id='DiversityInclusion.diversityCommitment' />
        }
      >
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='diversityCommitment'
          questionId='publishedExternally'
          question={
            <FormattedMessage id='DiversityInclusion.publishedExternally' />
          }
          childAnswer
        >
          <ShowQuestionResponse
            pageId={pageId}
            parentQuestionId='publishedExternally'
            questionId='publishedExternallyLink'
            question={<FormattedMessage id='DiversityInclusion.ExternalLink' />}
            longAnswer
          />
        </ShowQuestionResponse>
      </ShowQuestionResponse>
      <ShowQuestionResponse
        pageId={pageId}
        questionId='formalPolicies'
        question={<FormattedMessage id='DiversityInclusion.diversityPolices' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='diversityTraining'
        question={
          <FormattedMessage id='DiversityInclusion.diversityTraining' />
        }
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='integrateProcess'
        question={<FormattedMessage id='DiversityInclusion.integrateProcess' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='diversityCertification'
        question={
          <FormattedMessage id='DiversityInclusion.diversityCertification' />
        }
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='51PercentOwned'
        question={<FormattedMessage id='DiversityInclusion.51Percent' />}
      >
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='51PercentOwned'
          questionId='groups'
          longAnswer
          childAnswer
        />
      </ShowQuestionResponse>
      <ShowQuestionResponse
        pageId={pageId}
        questionId='note'
        question={<FormattedMessage id='optionalAdditionalNote' />}
        longAnswer
      />
    </div>
  )
}

export default DiversityInclusion
