import { call, put } from 'redux-saga/effects'
import { Map } from 'immutable'
import vets from 'shared/utils/api/vets'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { notify } from 'shared/actions'

export function* updateVetBuyerSupplierSaga(action) {
  const payload =
    action.payload instanceof Map ? action.payload.toJS() : action.payload
  const { vetBuyerSupplierId, responseFile, ...rest } = payload

  let uploadResponse
  if (responseFile) {
    uploadResponse = yield call(vets.uploadResponseFile, {
      vetBuyerSupplierId,
      responseFile
    })
    yield put(
      notify({
        message: `${responseFile.name} has been uploaded`
      })
    )
  } else {
    yield call(vets.updateVetBuyerSupplierFlags, {
      vetBuyerSupplierId,
      ...rest
    })
  }
  return yield { vetBuyerSupplierId, responseFile: uploadResponse, ...rest }
}

export default createApiRequestSaga(updateVetBuyerSupplierSaga, {
  notifyOnError: true
})
