import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { removeVetTeamMember, updateVetTeamMember } from '../../actions'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import Loading from 'shared/components/Loading'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import { FormattedMessage } from 'react-intl'
import DropdownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import { RecordOf, List } from 'immutable'

type Props = {
  vetId: string
  isVetApprover: boolean
  isOwner: boolean
  users: List<
    RecordOf<{
      lead: boolean
      requestedBy: boolean
      subscribed: boolean
      user: string
    }>
  >
  currentUserId: string
  isLoading: boolean
  mini: boolean
  vetIsReadonly: boolean
  removeVetTeamMember: (removeTeamMember: {
    userId: string
    vetId: string
  }) => void
  updateVetTeamMember: (updateTeamMemeber: {
    vetId: string
    user1: {}
    user2: {}
  }) => void
}

export class VetTeamListContainer extends Component<Props> {
  handleDelete = userIdToRemove => {
    const { removeVetTeamMember, vetId } = this.props

    removeVetTeamMember({
      userId: userIdToRemove,
      vetId
    })
  }

  handleMemberAttributeChange = (user, attribute) => {
    const { users, updateVetTeamMember, vetId } = this.props
    const previousUser = users.find(user => !!user[attribute]) || {}

    updateVetTeamMember({
      vetId,
      user1: Object.assign({}, user, { [attribute]: true }),
      user2: Object.assign({}, previousUser, { [attribute]: false })
    })
  }

  render() {
    const {
      isLoading,
      users,
      currentUserId,
      mini,
      vetIsReadonly,
      isOwner,
      isVetApprover
    } = this.props

    return isLoading ? (
      <Loading />
    ) : (
      <div>
        {users &&
          users.map(user => (
            <UserListItemContainer
              key={user.user}
              userId={user.user}
              mini={mini}
              profileUrl={parsePath(paths.userprofile, { userId: user.user })}
              highlightAvatar={user.lead ? 'b--gold' : 'b--white'}
              rightButton={
                !vetIsReadonly &&
                !(
                  user.lead &&
                  user.requestedBy &&
                  (currentUserId === user.user || user.lead || user.requestedBy)
                ) && (
                  <DropdownMenu>
                    {(isOwner || isVetApprover) && !user.lead && (
                      <MenuItem
                        onClick={() =>
                          this.handleMemberAttributeChange(user, 'lead')
                        }
                      >
                        <FormattedMessage
                          id='VetTeamListContainer.SetOwner'
                          defaultMessage='Set Owner'
                        />
                      </MenuItem>
                    )}
                    {!user.requestedBy && (
                      <MenuItem
                        onClick={() =>
                          this.handleMemberAttributeChange(user, 'requestedBy')
                        }
                      >
                        <FormattedMessage
                          id='VetTeamListContainer.SetRequestedBy'
                          defaultMessage='Set Requested By'
                        />
                      </MenuItem>
                    )}
                    {(isOwner || isVetApprover) &&
                      currentUserId !== user.user &&
                      !user.lead &&
                      !user.requestedBy && (
                        <MenuItem onClick={() => this.handleDelete(user.user)}>
                          <FormattedMessage
                            id='VetTeamListContainer.Remove'
                            defaultMessage='Remove'
                          />
                        </MenuItem>
                      )}
                  </DropdownMenu>
                )
              }
              secondaryText={
                <Fragment>
                  {user.lead && (
                    <FormattedMessage
                      id='VetTeamListContainer.Owner'
                      defaultMessage='Owner'
                    />
                  )}
                  {user.lead && user.requestedBy && ', '}
                  {user.requestedBy && (
                    <FormattedMessage
                      id='VetTeamListContainer.Requested'
                      defaultMessage='Requested'
                    />
                  )}
                </Fragment>
              }
            />
          ))}
      </div>
    )
  }
}

export default connect(
  state => ({
    vetId: currentVetSelectors.getVetField(state, 'id'),
    currentUserId: sessionSelectors.getUserId(state),
    isVetApprover: sessionSelectors.userHasRole(state, [
      'buyerVetApprover',
      'buyerAdmin'
    ]),
    isOwner: currentVetSelectors.currentUserIsOwner(state),
    vetIsReadonly: currentVetSelectors.isReadonly(state),
    users: currentVetSelectors.getTeamIds(state),
    isLoading: currentVetSelectors.isLoading(state)
  }),
  {
    removeVetTeamMember,
    updateVetTeamMember
  }
)(VetTeamListContainer)
