import React, { Component } from 'react'
import Text from '../Text'
import ExternalLink from '../ExternalLink'
import { FormattedMessage } from 'react-intl'

type Props = {
  className?: string
  agreeToTerms?: boolean
  noTermsAndConditions?: boolean
  onChange?: () => void
}

class AgreeToTerms extends Component<Props> {
  render() {
    const { agreeToTerms, onChange, noTermsAndConditions, ...rest } = this.props

    return (
      <Text {...rest}>
        <input
          className='mr2'
          type='checkbox'
          checked={agreeToTerms}
          onChange={onChange}
          required
        />

        {!noTermsAndConditions && (
          <FormattedMessage
            id='AgreeToTerms.terms'
            description={`I agree to TealBook's Community Guidelines and Terms of Service.`}
            defaultMessage='{agreeToTealbook} {communityGuidelines} and {termsOfService}.'
            values={{
              agreeToTealbook: (
                <span className='mr1'>
                  <FormattedMessage
                    id='AgreeToTerms.terms.agreeToTealbook'
                    defaultMessage={`I agree to TealBook's`}
                  />
                </span>
              ),
              communityGuidelines: (
                <ExternalLink href='https://tealbook.com/community/'>
                  <FormattedMessage
                    id='AgreeToTerms.terms.communityGuidelines'
                    defaultMessage='Community Guidelines'
                  />
                </ExternalLink>
              ),
              termsOfService: (
                <ExternalLink href='https://tealbook.com/terms'>
                  <FormattedMessage
                    id='AgreeToTerms.terms.termsOfService'
                    defaultMessage='Terms of Service'
                  />
                </ExternalLink>
              )
            }}
          />
        )}

        {noTermsAndConditions && (
          <FormattedMessage
            id='AgreeToTerms.noNeedToAgreeToTerms'
            description={`Tealbook's Community Guidelines `}
            defaultMessage={`Tealbook's {communityGuidelines}`}
            values={{
              communityGuidelines: (
                <ExternalLink href='https://tealbook.com/community/'>
                  <FormattedMessage
                    id='AgreeToTerms.terms.communityGuidelines'
                    defaultMessage='Community Guidelines'
                  />
                </ExternalLink>
              )
            }}
          />
        )}
      </Text>
    )
  }
}

export default AgreeToTerms
