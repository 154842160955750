import { call } from 'redux-saga/effects'
import vets from 'shared/utils/api/vets'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* removeVetSupplier(action) {
  yield call(vets.removeVetSupplier, action.payload)
  return yield action.payload
}

export default createApiRequestSaga(removeVetSupplier)
