import React, { Component } from 'react'
import { connect } from 'react-redux'
import profileSelectors from '../../../selectors/profileSelectors'
import ListItem from 'shared/components/List/ListItem'
import OrgLogo from 'shared/components/OrgLogo'
import paths from '../../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import fileToUrl from 'shared/utils/data/fileToUrl'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from 'shared/components/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import imgUpArrow from 'shared/assets/icons/arrow-up-gray.svg'
import imgDownArrow from 'shared/assets/icons/arrow-down-gray.svg'
import { FormattedMessage } from 'react-intl'
import { withRouter, RouteComponentProps } from 'react-router'
import RootState from 'shared/models/RootState'

const CustomIconButton = withStyles({
  root: {
    width: 20,
    height: 20
  }
})(IconButton)

type Props = {
  supplierId: string
  name: string
  logoUrl: string
  hasBetaRole: boolean
  onClickThumbs: (arg: {
    supplierId: string
    similar?: string
    click?: boolean
  }) => void
} & RouteComponentProps

export class SimilarSupplierListItem extends Component<Props> {
  handleClickThumbsUp = () => {
    this.triggerClickSimilarThumbs(true)
  }

  handleClickThumbsDown = () => {
    this.triggerClickSimilarThumbs(false)
  }

  handleSupplierClick = () => {
    const {
      history: { push },
      supplierId,
      onClickThumbs
    } = this.props
    onClickThumbs({
      supplierId,
      click: true
    })
    push(parsePath(paths.supplierProfile, { supplierId }))
  }

  triggerClickSimilarThumbs = similar => {
    const { supplierId, onClickThumbs } = this.props

    onClickThumbs({
      supplierId,
      similar
    })
  }

  render() {
    const { name, logoUrl, hasBetaRole } = this.props

    return name ? (
      <ListItem
        mini
        leftAvatar={
          <span className='pointer dim' onClick={this.handleSupplierClick}>
            <OrgLogo url={logoUrl} name={name} />
          </span>
        }
        primaryText={
          <span className='pointer dim' onClick={this.handleSupplierClick}>
            {name}
          </span>
        }
        rightButton={
          hasBetaRole && (
            <div className='flex flex-column justify-end'>
              <CustomIconButton onClick={this.handleClickThumbsUp}>
                <Tooltip
                  title={
                    <FormattedMessage
                      id='SimilarSupplierListItem.Agree'
                      defaultMessage='Agree'
                    />
                  }
                >
                  <img
                    className={'w-100 fb pointer dim'}
                    src={imgUpArrow}
                    alt='Agree'
                  />
                </Tooltip>
              </CustomIconButton>
              <CustomIconButton onClick={this.handleClickThumbsDown}>
                <Tooltip
                  title={
                    <FormattedMessage
                      id='SimilarSupplierListItem.Disagree'
                      defaultMessage='Disagree'
                    />
                  }
                >
                  <img
                    className={'w-100 fb pointer dim'}
                    src={imgDownArrow}
                    alt='Disagree'
                  />
                </Tooltip>
              </CustomIconButton>
            </div>
          )
        }
      />
    ) : null
  }
}

type ContainerProps = {
  supplierId: string
}
export default connect((state: RootState, props: ContainerProps) => {
  const supplier = profileSelectors.getSimilarSupplierById(
    state,
    props.supplierId
  )

  if (!supplier) {
    return {}
  }

  const domains = supplier.get('domains')
  const logo = supplier.getIn(['supplier', 'logo'])

  return {
    name: supplier.get('name'),
    logoUrl:
      (logo && fileToUrl(logo)) ||
      (domains && domains.size > 0
        ? `https://logo.clearbit.com/${domains.first()}`
        : '')
  }
})(withRouter(SimilarSupplierListItem))
