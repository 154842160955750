import { call } from 'redux-saga/effects'
import tier2 from 'shared/utils/api/tier2'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import exportData from 'shared/utils/exportData'
import moment from 'moment'

export function* exportTier2SupplierTableSaga(action) {
  const { status } = action.payload
  const tier2Suppliers = yield call(tier2.exportTier2SupplierList)

  const visibleSuppliers = tier2Suppliers.filter(supplier => {
    if (!status) {
      return true
    }
    if (supplier.status === status) {
      return true
    }
    return false
  })
  const data =
    visibleSuppliers &&
    visibleSuppliers.map(currentItem => {
      const { supplier, status, statusDate } = currentItem
      const supplierName = supplier?.name
      const website = supplier?.domains.join(', ')
      const lastUpdated = moment(statusDate).format('MMMM Do YYYY')
      let spendPeriods = []
      if (currentItem.tier2) {
        spendPeriods = currentItem.tier2.map(spend =>
          spend.quarter ? `${spend.year} Q${spend.quarter}` : spend.year
        )
      }

      let primaryContact =
        supplier?.primaryContact &&
        `${supplier.primaryContact.firstName || ''} ${supplier.primaryContact
          .lastName || ''}`
      let primaryContactEmail =
        supplier?.primaryContact && supplier.primaryContact.email
      let officePhone = supplier?.officePhone

      return {
        supplierName,
        website,
        primaryContact,
        primaryContactEmail,
        officePhone,
        status,
        lastUpdated,
        spendPeriods
      }
    })
  data && exportData.exportCSV(data, 'Tier 2 Suppliers', true)
}

export default createApiRequestSaga(exportTier2SupplierTableSaga)
