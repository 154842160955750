import search from './searchReducer'
import filterOptions from './filterOptionsReducer'
import filterPanels from './filterPanelsReducer'
import collection from './collectionReducer'
import inviteSupplier from './inviteSupplierReducer'
import tabFilter from './tabFilterReducer'

const rootReducer = {
  search,
  filterOptions,
  tabFilter,
  filterPanels,
  collection,
  inviteSupplier
}

export default rootReducer
