import React, { Component } from 'react'
import ImageOneStar from 'shared/assets/icons/one-star.svg'
import ImageThreeStars from 'shared/assets/icons/three-stars.svg'
import ImageThreeStarsGray from 'shared/assets/icons/three-stars-gray.svg'

const colors = [
  '#FFE915',
  '#D4E22E',
  '#BEDE3B',
  '#A2D94C',
  '#8AD459',
  '#73D067',
  '#5ECD74',
  '#47C982',
  '#2CC491',
  '#00BCAB'
]

type Props = {
  percentage: number
}

class CompletenessPercentage extends Component<Props> {
  static defaultProps = {
    percentage: 0
  }

  render() {
    const { percentage } = this.props
    const hightlight = Math.round(percentage / 10)

    return (
      <div className='relative h2'>
        <div className='dib w2 absolute' style={{ top: 0, left: 0 }}>
          <img src={ImageOneStar} alt='One star' className='w-100' />
        </div>
        <div className='ph3' style={{ paddingTop: '5px' }}>
          {colors.map((color, index) => {
            return (
              <div key={color} className='dib w-10 pr1'>
                <div
                  style={{
                    height: '10px',
                    overflow: 'hidden',
                    backgroundColor: index + 1 <= hightlight ? color : '#eeeeee'
                  }}
                >
                  &nbsp;
                </div>
              </div>
            )
          })}
        </div>
        <div className='dib w2 absolute' style={{ top: 0, right: 0 }}>
          <img
            src={hightlight < 10 ? ImageThreeStarsGray : ImageThreeStars}
            alt='Three star'
            className='w-100'
          />
        </div>
      </div>
    )
  }
}

export default CompletenessPercentage
