import React, { FunctionComponent, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PaymentForm from '../PaymentForm'
import Text from 'shared/components/Text'
import DialogTitle from 'shared/components/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Verified from 'shared/assets/icons/verified.svg'
import makeAsyncScriptLoader from 'react-async-script'
import { StripeProvider, Elements } from 'react-stripe-elements'
import supplier from 'shared/utils/api/supplier'
import companySelectors from 'supplier/shared/selectors/companySelectors'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import Loading from 'shared/components/Loading'

type Props = {
  defaultCountryAbbrev: string
  defaultStateAbbrev: string
  paymentCompleted: string
  Stripe: any
  open: boolean
  onClose: () => void
}

export const PaymentDialog: FunctionComponent<Props> = props => {
  const [stripePublicKey, setStripePublicKey] = useState<string>('')

  useEffect(() => {
    supplier.getStripePublicKey().then(({ publicKey }) => {
      setStripePublicKey(publicKey)
    })
  }, [])

  const {
    open,
    onClose,
    defaultCountryAbbrev,
    defaultStateAbbrev,
    paymentCompleted
  } = props

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>
        <FormattedMessage
          id='PaymentDialog.SubscribeToTealbookPremium'
          defaultMessage='Subscribe to TealBook premium'
        />
      </DialogTitle>
      <DialogContent>
        {!stripePublicKey || !props.Stripe ? (
          <Loading />
        ) : !paymentCompleted ? (
          <StripeProvider apiKey={stripePublicKey}>
            <Elements>
              <PaymentForm
                defaultCountryAbbrev={defaultCountryAbbrev}
                defaultStateAbbrev={defaultStateAbbrev}
                onCancel={onClose}
              />
            </Elements>
          </StripeProvider>
        ) : (
          <div className='tc'>
            <img src={Verified} alt='Verified' className='dib w3 mt4' />
            <h5 className='f3 teal ma0 mv3'>
              <FormattedMessage
                id='PaymentDialog.ThankYou'
                defaultMessage='Thank you!'
              />
            </h5>
            <Text>
              <FormattedMessage
                id='PaymentDialog.YouHaveSubscribeToTealbookPremium'
                defaultMessage='You have successfully subscribed TealBook premium license. Refer to the subscription section in your TealBook menu for more information.'
              />
            </Text>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default makeAsyncScriptLoader('https://js.stripe.com/v3/', {
  globalName: 'Stripe'
})(
  connect((state: RootState) => {
    const locations = companySelectors.getCompanySupplierField(
      state,
      'locations'
    )
    const defaultCountryAbbrev =
      locations && locations.getIn(['0', 'components', 'countryAbbrev'])
    const defaultStateAbbrev =
      locations && locations.getIn(['0', 'components', 'stateAbbrev'])

    return {
      defaultCountryAbbrev,
      defaultStateAbbrev,
      paymentCompleted: state.getIn(['supplier', 'company', 'paymentCompleted'])
    }
  })(PaymentDialog)
)
