import React, { Component } from 'react'
import Avatar from 'shared/components/Avatar'

const styles = {
  default: {
    avatar: 'w1-5 h1-5 ba b--white bw1 nl3 v-mid dib'
  },
  mini: {
    avatar: 'ba b--white v-mid bw1 w1 h1 nl2'
  }
}

type Props = {
  profilePictureUrl?: string
  mini?: boolean
  firstName?: string
  lastName?: string
}

class UserStackItem extends Component<Props> {
  render() {
    const { profilePictureUrl, mini, firstName, lastName } = this.props
    const style = mini ? styles.mini : styles.default

    return profilePictureUrl ? (
      <Avatar
        url={profilePictureUrl}
        className={style.avatar}
        name={`${firstName || ''} ${lastName || ''}`}
      />
    ) : (
      <Avatar className={style.avatar} />
    )
  }
}

export default UserStackItem
