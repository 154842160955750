import { Record, fromJS, Map, List } from 'immutable'

const VetsRecord = Record({
  items: Map(),
  editTeam: false,
  users: List(),
  isFetching: true,
  fetchErrorMessage: undefined
})

const VetsRecordFactory = ({ items, ...rest } = {}) => {
  items = items || {}

  return new VetsRecord({
    items: fromJS(items),
    ...rest
  })
}

export default VetsRecordFactory
