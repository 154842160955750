import { call, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import relationships from 'shared/utils/api/relationships'
import { notify } from 'shared/actions'

export function* renewAttachmentSaga(action) {
  const newAttachment = action.payload.toJS()
  // supplierId is gotten when action is triggered from supplier outreach page
  const {
    supplierId,
    expiringAttachmentRelationshipId,
    expiringAttachment
  } = action.meta
  let relationshipId
  const existingRelationship = yield call(
    relationships.getBySupplier,
    supplierId
  )
  relationshipId = existingRelationship.id
  // set attachment type to contract if it is a contract renewal supplier outreach request
  newAttachment.type = 'contract'
  // create a relationship if we need to for buyerDataAdmin
  let newRelationship
  if (!relationshipId) {
    newRelationship = yield call(relationships.create, supplierId)
    relationshipId = newRelationship.id
  }

  // add the new attachemnt
  const createdAttachment = yield call(
    relationships.addAttachment,
    relationshipId,
    newAttachment
  )
  // delete expiring attachment
  yield call(
    relationships.deleteAttachment,
    expiringAttachmentRelationshipId,
    expiringAttachment
  )
  yield put(
    notify({
      message: 'Contract Uploaded Successfully'
    })
  )

  return yield {
    newRelationship,
    relationshipId,
    attachment: createdAttachment
  }
}

export default createApiRequestSaga(renewAttachmentSaga)
