import React, { Component, ReactNode } from 'react'
import InsightWidget from 'shared/components/InsightWidget'
import ActiveOrgUnit from 'shared/components/ActiveOrgUnit'
import { List, RecordOf } from 'immutable'

type OrgUnit = {
  id: string
  profilePictureUrl: string
  profileUrl: string
  name: string
  categorization: string
  totalVets: string
  totalSearches: string
  totalConnections: string
  viewsLastMonth: number
  viewsThisMonth: number
  views: number
}

type Props = {
  title: string | ReactNode
  noData: string | ReactNode
  orgUnits?: List<RecordOf<OrgUnit>>
  secondaryTextRenderer?: (org: RecordOf<OrgUnit>) => ReactNode
}

export class LeaderboardOrgUnitWidget extends Component<Props> {
  render() {
    const { title, orgUnits, noData, secondaryTextRenderer } = this.props

    return (
      <InsightWidget title={title}>
        {orgUnits && orgUnits.size > 0 ? (
          orgUnits.map((org, index) => (
            <ActiveOrgUnit
              key={`${org.get('id')}-${index}`}
              place={index + 1}
              logoUrl={org.get('profilePictureUrl')}
              profileUrl={org.get('profileUrl')}
              name={org.get('name') || org.get('categorization')}
              secondaryText={
                secondaryTextRenderer && secondaryTextRenderer(org)
              }
            />
          ))
        ) : (
          <div className='tc pv3'>{noData}</div>
        )}
      </InsightWidget>
    )
  }
}

export default LeaderboardOrgUnitWidget
