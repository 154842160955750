import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import qs from 'qs'
import parsePath from 'shared/utils/parsePath'
import buyerPaths from 'buyer/routes/paths'
import vetPaths from 'buyer/Vets/routes/paths'
import OrgLogo from 'shared/components/OrgLogo'
import Link from 'shared/components/Link'
import Select from 'shared/components/Select'
import Input from 'shared/components/Input'
import Divider from 'shared/components/Divider'
import Button from 'shared/components/Button'
import Scrollable from 'shared/components/Scrollable'
import collectionSelectors from '../../selectors/collectionSelectors'
import currentVetSuppliersSelectors from '../../../Vets/selectors/currentVetSuppliersSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { addVetSupplier } from 'buyer/Vets/actions'
import { batchAddPersonalRelationship } from 'buyer/SupplierProfile/actions'
import { removeCollection, clearCollection } from 'buyer/Search/actions'
import BatchInviteToCommunitiesDialogContainer from '../../../shared/containers/BatchInviteToCommunitiesDialogContainer'
import SelectSuppliersDialog from '../../../shared/components/SelectSuppliersDialog'
import { FormattedMessage } from 'react-intl'
import { searchSimilarSuppliers } from '../../../shared/actions'
import RootState from 'shared/models/RootState'
import { Map, List, RecordOf } from 'immutable'
import { AccessControl } from 'shared/models/AccessControl'
import BatchInviteToTier2Container from '../../../shared/containers/BatchInviteToTier2Container'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import SendPunchoutSuppliersButton from '../../../shared/containers/SendPunchoutSuppliersButton'
import uniq from 'lodash.uniq'
import { withRouter, RouteComponentProps } from 'react-router'

const SIMILAR_SUPPLIERS_LIMIT = 20

type Props = {
  userId: string
  isTealbot: boolean
  accessControl: AccessControl
  queryString: string
  currentUrl: string
  collection: Map<
    string,
    RecordOf<{
      id: string
      name: string
      logoUrl: string
      relatedCompany: boolean
    }>
  >
  hasSimilarSuppliers: boolean
  vetBuyerSuppliers: List<
    RecordOf<{
      supplier: RecordOf<{ id: string }>
    }>
  >
  addVetSupplier: (payload: { vetId: string; supplierIds: string[] }) => void
  removeCollection: (payload: { userId: string; supplierId: string }) => void
  clearCollection: typeof clearCollection
  batchAddPersonalRelationship: (payload: {
    supplierIds: string[]
    fieldName: string
    newTag: string
  }) => void
  searchSimilarSuppliers: (payload: string[]) => void
  toggleOpenCart?: () => void
  noWrapper?: boolean
  cartOpen?: boolean
  disableActions?: boolean
  diversityReportEnabled: boolean
} & ContainerProps &
  RouteComponentProps

type State = {
  action: string
  openInviteDialog: boolean
  openSuppliersDialog: boolean
  showInputListName: boolean
  listName: string
  openInviteTier2Dialog: boolean
}

export class Collection extends Component<Props, State> {
  state: State = {
    action: this.props.hasSimilarSuppliers
      ? 'similarSuppliers'
      : this.props.vetId
      ? 'addToVet'
      : 'startAVet',
    openInviteDialog: false,
    openSuppliersDialog: false,
    showInputListName: false,
    listName: '',
    openInviteTier2Dialog: false
  }
  handleClearAll = () => {
    const { clearCollection, userId, toggleOpenCart } = this.props
    clearCollection(userId)
    if (toggleOpenCart) {
      toggleOpenCart()
    }
  }

  handleStartNewVet = () => {
    const parsedQueryString = qs.parse(this.props.queryString, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })
    parsedQueryString['includeSuppliers'] = 1
    this.props.history.push({
      pathname: vetPaths.newVet,
      search: qs.stringify(parsedQueryString)
    })
  }

  handleAddToVet = () => {
    const { collection, vetBuyerSuppliers, addVetSupplier, vetId } = this.props
    const suppliers = collection.keySeq().toArray()
    const vetSuppliers =
      vetBuyerSuppliers &&
      vetBuyerSuppliers.map(vbs => vbs.get('supplier').get('id')).toArray()
    const newSuppliers = suppliers.filter(
      supplier => !vetSuppliers || !vetSuppliers.includes(supplier)
    )

    if (newSuppliers.length > 0 && vetId) {
      addVetSupplier({ vetId, supplierIds: newSuppliers })
    }
  }

  handleRemoveSupplier = supplierId => {
    const { removeCollection, userId, toggleOpenCart } = this.props
    removeCollection({ supplierId, userId })
    if (toggleOpenCart) {
      toggleOpenCart()
    }
  }

  handleActionChange = e => {
    this.setState({
      action: e.target.value,
      showInputListName: e.target.value === 'saveAsList',
      listName: ''
    })
  }

  handleListNameChange = e => {
    this.setState({
      listName: e.target.value
    })
  }

  handleSaveAsList = () => {
    const { collection, batchAddPersonalRelationship } = this.props
    const newTag = this.state.listName
    const supplierIds = collection.keySeq().toArray()
    const fieldName = 'attributes'

    if (newTag) {
      batchAddPersonalRelationship({
        supplierIds,
        fieldName,
        newTag
      })

      this.setState({
        listName: ''
      })
    }
  }

  handleSimilarSuppliers = () => {
    const { collection, searchSimilarSuppliers, accessControl } = this.props
    const supplierIds = collection.keySeq().toArray()

    if (supplierIds.length < SIMILAR_SUPPLIERS_LIMIT) {
      if (accessControl.access === 'punchout') {
        const query = qs.parse(this.props.queryString, {
          ignoreQueryPrefix: true,
          arrayLimit: 100
        })
        const similarSuppliers = query.similarSuppliers || []
        const newQuery = {
          ...query,
          similarSuppliers: uniq(similarSuppliers.concat(supplierIds))
        }

        this.props.history.push({
          search: qs.stringify(newQuery)
        })
      } else {
        searchSimilarSuppliers(supplierIds)
      }
    } else {
      this.toggleDialogSuppliersDialog()
    }
  }

  toggleInviteDialog = () => {
    const { toggleOpenCart } = this.props
    if (toggleOpenCart && this.state.openInviteDialog) {
      toggleOpenCart()
    }
    this.setState({
      openInviteDialog: !this.state.openInviteDialog
    })
  }

  toggleDialogSuppliersDialog = () => {
    const { toggleOpenCart } = this.props
    if (toggleOpenCart && this.state.openSuppliersDialog) {
      toggleOpenCart()
    }
    this.setState({
      openSuppliersDialog: !this.state.openSuppliersDialog
    })
  }

  toggleTier2InviteDialog = () => {
    const { toggleOpenCart } = this.props
    if (toggleOpenCart && this.state.openInviteTier2Dialog) {
      toggleOpenCart()
    }
    this.setState({
      openInviteTier2Dialog: !this.state.openInviteTier2Dialog
    })
  }

  handleOKClick = (actionOk?: string) => {
    const action = actionOk || this.state.action
    const { toggleOpenCart } = this.props
    if (action === 'startAVet') {
      this.handleStartNewVet()
      if (toggleOpenCart) {
        toggleOpenCart()
      }
    } else if (action === 'addToCommunity') {
      this.setState({
        openInviteDialog: true
      })
    } else if (action === 'saveAsList') {
      this.handleSaveAsList()
      if (toggleOpenCart) {
        toggleOpenCart()
      }
    } else if (action === 'similarSuppliers') {
      this.handleSimilarSuppliers()
      if (toggleOpenCart) {
        toggleOpenCart()
      }
    } else if (action === 'addToVet') {
      this.handleAddToVet()
      if (toggleOpenCart) {
        toggleOpenCart()
      }
    } else if (action === 'addToTier2') {
      this.setState({
        openInviteTier2Dialog: true
      })
    }
  }

  renderCollection(collection) {
    const { currentUrl, toggleOpenCart } = this.props
    const list = collection.entrySeq().map(([k, v]) => {
      const profileUrl = `${parsePath(buyerPaths.supplierProfile, {
        supplierId: k
      })}?redirectFrom=${currentUrl}`
      const logoUrl = v.get('logoUrl')
      return (
        <div key={k} className='pointer dn mb3' style={{ display: 'block' }}>
          <div
            className='logo pa1 dtc v-mid ba b--black-10 br2 tc bg-white'
            style={{ height: '50px', width: '50px' }}
          >
            <Link
              to={profileUrl}
              onClick={() => {
                if (toggleOpenCart) {
                  toggleOpenCart()
                }
              }}
            >
              <OrgLogo url={logoUrl} />
            </Link>
          </div>
          <div className='v-mid mid-gray mw4-5-l mw6-m mw4-5 dtc pl2'>
            <Link
              to={profileUrl}
              onClick={() => {
                if (toggleOpenCart) {
                  toggleOpenCart()
                }
              }}
            >
              <h5 className='name f7 fw3 mid-gray ma0 truncate'>
                {v.get('name')}
              </h5>
            </Link>
            <Button
              size='small'
              variant='text'
              caution
              autoSize
              style={{ padding: 0 }}
              onClick={() => this.handleRemoveSupplier(k)}
            >
              <FormattedMessage
                id='CollectionContainer.Remove'
                defaultMessage='Remove'
              />
            </Button>
          </div>
        </div>
      )
    })
    return list
  }

  render() {
    const {
      collection,
      vetId,
      noWrapper,
      searchSimilarSuppliers,
      isTealbot,
      accessControl,
      disableActions,
      diversityReportEnabled
    } = this.props
    return (
      <Fragment>
        {collection.size > 0 && (
          <div
            className={
              noWrapper ? '' : 'pa3 bg-white ba b--black-10 br2 mb4 mt4 mt0-ns'
            }
          >
            <div className='flex items-center justify-between mb3'>
              <div className='f7 fw6'>
                <FormattedMessage
                  id='CollectionContainer.ListSize'
                  defaultMessage='List {collectoinSizeValue}'
                  values={{ collectoinSizeValue: collection.size }}
                />
              </div>
              <Button
                variant='text'
                caution
                size='small'
                autoSize
                onClick={this.handleClearAll}
              >
                <FormattedMessage
                  id='CollectionContainer.RemoveAll'
                  defaultMessage='Remove all'
                />
              </Button>
            </div>
            <Scrollable>{this.renderCollection(collection)}</Scrollable>
            {!disableActions && (
              <>
                <div className='mb3'>
                  <Divider />
                </div>
                {this.state.showInputListName && (
                  <FormattedMessage
                    id='CollectionContainer.EnterALabelForList'
                    defaultMessage='Enter a label for list'
                  >
                    {message => (
                      <Input
                        placeholder={message as string}
                        onChange={this.handleListNameChange}
                        value={this.state.listName}
                        className='mb2'
                      />
                    )}
                  </FormattedMessage>
                )}
                {accessControl.access === 'punchout' ? (
                  <>
                    <div className='db mt2'>
                      <Button
                        secondary
                        label={
                          <FormattedMessage
                            id='CollectionContainer.FindSimilarSupplier'
                            defaultMessage='Find similar suppliers'
                          />
                        }
                        onClick={() => this.handleOKClick('similarSuppliers')}
                      />
                    </div>
                    <div className='db mt2'>
                      <SendPunchoutSuppliersButton />
                    </div>
                  </>
                ) : (
                  <>
                    <div className='db'>
                      <Select
                        fullWidth
                        onChange={this.handleActionChange}
                        value={this.state.action}
                      >
                        {collection.some(c => c.get('relatedCompany')) && (
                          <FormattedMessage id='CollectionContainer.FindSimilarSupplier'>
                            {message => (
                              <option value='similarSuppliers'>
                                {message}
                              </option>
                            )}
                          </FormattedMessage>
                        )}
                        {!vetId ? (
                          <Fragment>
                            <FormattedMessage
                              id='CollectionContainer.StartedAnRFI'
                              defaultMessage='Start an RFI'
                            >
                              {message => (
                                <option value='startAVet'>{message}</option>
                              )}
                            </FormattedMessage>
                          </Fragment>
                        ) : (
                          <FormattedMessage
                            id='CollectionContainer.AddToRFI'
                            defaultMessage='Add to RFI'
                          >
                            {message => (
                              <option value='addToVet'>{message}</option>
                            )}
                          </FormattedMessage>
                        )}
                        <FormattedMessage
                          id='CollectionContainer.AddToCommunity'
                          defaultMessage='Add to community'
                        >
                          {message => (
                            <option value='addToCommunity'>{message}</option>
                          )}
                        </FormattedMessage>
                        {!isTealbot && (
                          <FormattedMessage
                            id='CollectionContainer.SaveAsPersonalRelationship'
                            defaultMessage='Save as personal relationship'
                          >
                            {message => (
                              <option value='saveAsList'>{message}</option>
                            )}
                          </FormattedMessage>
                        )}
                        {diversityReportEnabled && (
                          <FormattedMessage
                            id='CollectionContainer.AddToTier2'
                            defaultMessage='Add to Tier 2'
                          >
                            {message => (
                              <option value='addToTier2'>{message}</option>
                            )}
                          </FormattedMessage>
                        )}
                      </Select>
                    </div>
                    <div className='db mt2'>
                      <Button
                        label={
                          <FormattedMessage
                            id='CollectionContainer.Ok'
                            defaultMessage='OK'
                          />
                        }
                        onClick={() => this.handleOKClick()}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
        <BatchInviteToCommunitiesDialogContainer
          supplierIds={collection.keySeq().toArray()}
          open={this.state.openInviteDialog}
          onCloseInviteDialog={this.toggleInviteDialog}
        />
        <SelectSuppliersDialog
          supplierIds={collection.keySeq().toArray()}
          open={this.state.openSuppliersDialog}
          limit={SIMILAR_SUPPLIERS_LIMIT}
          message={
            <FormattedMessage
              id='CollectionContainer.ToOptimizeTheResult'
              defaultMessage='To optimize the result, please select no more than 20 suppliers to perform similar search.'
            />
          }
          onClose={this.toggleDialogSuppliersDialog}
          onContinue={supplierIds => searchSimilarSuppliers(supplierIds)}
        />
        <BatchInviteToTier2Container
          suppliers={collection.valueSeq().toArray()}
          inviteSupplierDialogOpen={this.state.openInviteTier2Dialog}
          onCloseTier2Dialog={this.toggleTier2InviteDialog}
        />
      </Fragment>
    )
  }
}

type ContainerProps = {
  vetId?: string
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const collection = collectionSelectors.getCollection(state)
    const accessControl: AccessControl = sessionSelectors.getAccessControl(
      state
    )

    return {
      userId: sessionSelectors.getUserId(state),
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      collection,
      hasSimilarSuppliers: collection
        .toList()
        .some((c: RecordOf<{ relatedCompany: boolean }>) =>
          c.get('relatedCompany')
        ),
      vetBuyerSuppliers:
        props.vetId &&
        currentVetSuppliersSelectors.getVetSuppliersInList(state),
      currentUrl: `${routingSelectors.getPathname(
        state
      )}${routingSelectors.getSearch(state)}`,
      queryString: routingSelectors.getSearch(state),
      accessControl,
      diversityReportEnabled: settingsSelectors.getSetting(
        state,
        'diversityReportEnabled'
      )
    }
  },
  {
    addVetSupplier,
    batchAddPersonalRelationship,
    removeCollection,
    clearCollection,
    searchSimilarSuppliers
  }
)(withRouter(Collection))
