import SearchResults from '../../components/SearchResults'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import RootState from 'shared/models/RootState'

export default withRouter(
  connect((state: RootState, props: RouteComponentProps) => ({
    isSearching: state.getIn(['buyer', 'contactsSearch', 'isSearching']),
    resultIds: state.getIn(['buyer', 'contactsSearch', 'resultIds']),
    numberOfResults: state.getIn(['buyer', 'contactsSearch', 'numberOfResults'])
  }))(SearchResults)
)
