import React, { ChangeEvent } from 'react'
import Label from 'shared/components/Label'
import TextArea from 'shared/components/TextArea'

type Props = {
  questionId: string
  text?: string
  required?: boolean
  value?: string
  onChange: (questionId, value) => void
}

const TextQuestion = (props: Props) => {
  const { questionId, text, required, value = '', onChange } = props

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(questionId, e.currentTarget.value)
  }

  return (
    <>
      {text && <Label required={required}>{text}</Label>}
      <TextArea name={questionId} value={value} onChange={handleChange} />
    </>
  )
}

export default TextQuestion
