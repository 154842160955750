import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'

export function* removeAttachmentSaga(action) {
  const { vetId, fileName } = action.payload
  yield call(vets.removeDocument, { vetId, fileName })
  return yield action.payload
}

export default createApiRequestSaga(removeAttachmentSaga)
