import React, { Fragment, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import ExternalLink from '../ExternalLink'
import iconFile from 'shared/assets/icons/file.svg'
import dateFormat from 'shared/utils/dateFormat'
import ImageVerified from 'shared/assets/icons/verified.svg'
import ImageExpired from 'shared/assets/icons/expired.svg'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import Tooltip from 'shared/components/Tooltip'
import ImageWarning from 'shared/assets/icons/warning.svg'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { List, RecordOf } from 'immutable'
import CertificationCategories, {
  CategoryType
} from 'shared/models/CertificationCategories'
import camelCase from 'lodash.camelcase'
import moment from 'moment'
import useThemeColors from 'shared/utils/useThemeColors'
import startCase from 'lodash.startcase'
import { ReactComponent as Check } from 'shared/assets/icons/check-solid.svg'
import { ReactComponent as Question } from 'shared/assets/icons/question-solid.svg'
import { alertCodes as codeMessages } from 'shared/utils/data/defineMessages'
import truncate from 'lodash.truncate'
import { alertCodesDescriptions } from 'shared/utils/data/defineMessages'
import usersSelectors from 'shared/selectors/usersSelectors'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import RootState from 'shared/models/RootState'

const messages = defineMessages({
  supplier: { id: 'CertCard.Supplier', defaultMessage: 'supplier' }
})

const styles = {
  wrapper: 'dib w-100 mt3 br1 ba b--black-10 pa2',
  expirationDate: 'f7 fw3 mid-gray dib mt1',
  attachmentUrl:
    'f7 fw3 near-black no-underline underline-hover pointer dim mt1',
  sourceUrl: 'f7 fw3 mid-gray mt1',
  note: 'f7 fw4 mid-gray mt1 ma0 mr2 word-wrap',
  removeButton:
    'f7 fw2 dim red pointer ph0 pt2 mb2 bg-transparent b--transparent',
  createdByWrapper: 'dib w-50 w-30-ns v-top mt3',
  rightWrapper: 'dib w-100 w-30-ns v-top mt3 tr',
  avatarWrapper: 'dib w2',
  avatar: 'br-100 w-100',
  contentWrapper: 'pl2 v-top dib',
  createdByName: 'f8 fw6 ma0 mid-gray pointer lh-copy dib',
  createdDate: 'f8 fw4 ma0 gray lh-copy tl'
}

type Props = {
  category?: CategoryType
  subCategory?: string
  additionalAttributes?: string | ReactNode
  expirationDate: Date
  lastModified?: string
  supplierCertified?: string
  attachmentUrl?: string
  sourceUrl?: string
  note?: string
  certAgency?: string
  rightContent?: string | ReactNode
  certificateNumber?: string
  subTypes?: List<string>
  validations?: List<
    RecordOf<{
      confirmed: boolean
      companyName: string
      date: undefined
      validationExpires: string
    }>
  >
  children?: ReactNode
  supplierName?: string
  status?: 'confirmed' | 'potential'
  alertCodes?: List<string>
  esgSurveyDate?: string
}

const CertCard = (props: Props) => {
  const {
    category,
    subCategory,
    additionalAttributes,
    lastModified,
    supplierCertified,
    expirationDate,
    attachmentUrl,
    sourceUrl,
    note,
    certAgency,
    certificateNumber,
    validations,
    rightContent,
    subTypes,
    children,
    supplierName,
    alertCodes,
    esgSurveyDate
  } = props

  const colors = useThemeColors()
  const intl = useIntl()
  const LastUpdatedBy: string = useSelector((state: RootState) => {
    const user =
      supplierCertified && usersSelectors.getById(state, supplierCertified)
    return user && user.has('id')
      ? `${user.get('firstName')} ${user.get('lastName')}`
      : intl.formatMessage(messages.supplier)
  })

  const validationWithExpiry =
    validations &&
    validations
      .filter(v => v.get('confirmed') && v.get('validationExpires'))
      .sort((v1, v2) => {
        return new Date(v2.get('validationExpires')) <
          new Date(v1.get('validationExpires'))
          ? -1
          : 1
      })

  let latestExpiry = expirationDate
  if (validationWithExpiry && validationWithExpiry.size > 0) {
    if (
      !expirationDate ||
      new Date(validationWithExpiry.getIn([0, 'validationExpires'])) >
        expirationDate
    ) {
      latestExpiry = new Date(
        validationWithExpiry.getIn([0, 'validationExpires'])
      )
    }
  }

  return (
    <div>
      <div className={`${styles.wrapper} ${rightContent ? 'w-70-ns' : ''}`}>
        <div>
          {lastModified && (
            <Tooltip
              title={
                supplierCertified ? (
                  <>
                    <FormattedMessage
                      id='CertCard.LastUpdatedBy'
                      defaultMessage='Last updated by {name} on {date}'
                      values={{
                        name: LastUpdatedBy,
                        date: dateFormat(lastModified, undefined, true)
                      }}
                    />
                    {esgSurveyDate && (
                      <>
                        <br />
                        <FormattedMessage
                          id='CertCard.DerivedFromESG'
                          defaultMessage='Derived from Sustainability Survey on {date}'
                          values={{
                            date: dateFormat(esgSurveyDate, undefined, true)
                          }}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <FormattedMessage
                    id='CertCard.LoadCert'
                    defaultMessage='Loaded by ML on {date}'
                    values={{
                      date: dateFormat(lastModified, undefined, true)
                    }}
                  />
                )
              }
            >
              <InfoIcon fontSize='small' className='dim v-mid ml1 fr' />
            </Tooltip>
          )}
          <div className='flex items-center'>
            {props.category === 'diversity' &&
              props.status &&
              (props.status === 'confirmed' ? (
                <Tooltip
                  title={
                    <FormattedMessage
                      id='CertAttributes.ConfirmedHelp'
                      defaultMessage='Qualified: Certificate is active and passed automated quality assurance.'
                    />
                  }
                >
                  <span className='mr1 self-start'>
                    <Check height={12} fill='#31b800' />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <>
                      <FormattedMessage
                        id='CertAttributes.PotentialHelp'
                        defaultMessage='Potential: Issues with certificate are '
                      />
                      <br />
                      {alertCodes &&
                        alertCodes?.map(code => (
                          <p key={code}>
                            {`${
                              codeMessages[code]
                                ? intl.formatMessage(codeMessages[code])
                                : startCase(code)
                            } (${code}): ${
                              codeMessages[code]
                                ? intl.formatMessage(
                                    alertCodesDescriptions[code]
                                  )
                                : startCase(code)
                            }`}
                          </p>
                        ))}
                    </>
                  }
                >
                  <span className='mr1 self-start'>
                    <Question height={12} fill='#fcba03' />
                  </span>
                </Tooltip>
              ))}
            {certAgency && (
              <h5 className='f7 fw6 mv0 flex-auto'>
                {certAgency === 'Self Certify' &&
                  (supplierName ? (
                    <>
                      <FormattedMessage
                        id='AttachmentCard.SelfCertifiedBy'
                        defaultMessage='Self Certified by'
                      />
                      : {supplierName}
                    </>
                  ) : (
                    <FormattedMessage
                      id='AttachmentCard.SelfCertified'
                      defaultMessage='Self Certified'
                    />
                  ))}
                {certAgency !== 'Self Certify' && (
                  <FormattedMessage
                    id='AttachmentCard.CertifiedBy'
                    defaultMessage='Certified by {certAgency}'
                    values={{ certAgency }}
                  />
                )}
              </h5>
            )}
            {latestExpiry &&
              (latestExpiry.setHours(23, 59, 59, 99) <
                new Date().setHours(0, 0, 0, 0) ||
                (expirationDate &&
                  moment(expirationDate).isBefore(latestExpiry, 'day'))) && (
                <Tooltip
                  title={
                    expirationDate &&
                    moment(expirationDate).isBefore(latestExpiry, 'day') ? (
                      <FormattedMessage
                        id='AttachmentCard.ConflictingInformation'
                        defaultMessage='Conflicting information on expiration date: {expirationDate}'
                        values={{
                          expirationDate: dateFormat(
                            latestExpiry,
                            'MM/DD/YYYY',
                            true
                          )
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id='AttachmentCard.ExpiredOn'
                        defaultMessage='Expired on {expirationDate}'
                        values={{
                          expirationDate: dateFormat(
                            latestExpiry,
                            'MM/DD/YYYY',
                            true
                          )
                        }}
                      />
                    )
                  }
                  aria-label='Expired'
                  placement='right'
                >
                  <img
                    src={ImageExpired}
                    alt='Expired'
                    className='self-start ml1'
                    style={{ height: 13 }}
                  />
                </Tooltip>
              )}
            {validations &&
              validations.size > 0 &&
              !validations.some(v => !v.get('confirmed')) &&
              (validations && validations.size > 0 ? (
                <Tooltip
                  title={
                    <Fragment>
                      <Label noPadding>
                        <FormattedMessage
                          id='AttachmentCard.ConfirmedBy'
                          defaultMessage='Confirmed by'
                        />
                      </Label>
                      {validations
                        .map(v => (
                          <Text key={v.get('companyName')}>
                            <FormattedMessage
                              id='AttachmentCard.ConfirmedDate'
                              defaultMessage='{companyName} on {date}'
                              values={{
                                companyName: v.get('companyName'),
                                date: dateFormat(
                                  v.get('date'),
                                  'MM/DD/YYYY',
                                  true
                                )
                              }}
                            />
                          </Text>
                        ))
                        .toArray()}
                    </Fragment>
                  }
                >
                  <img
                    src={ImageVerified}
                    alt='Verified'
                    className='w1 self-start ml1'
                  />
                </Tooltip>
              ) : (
                <img
                  src={ImageVerified}
                  alt='Verified'
                  className='w1 self-start ml1'
                />
              ))}
            {validations &&
              validations.filter(v => !v.get('confirmed')).size > 0 && (
                <Tooltip
                  title={
                    <Fragment>
                      <Label noPadding>
                        <FormattedMessage
                          id='AttachmentCard.InvalidatedBy'
                          defaultMessage='Invalidated by'
                        />
                      </Label>
                      {validations
                        .filter(v => !v.get('confirmed'))
                        .map(v => (
                          <Text key={v.get('companyName')}>
                            <FormattedMessage
                              id='AttachmentCard.ConfirmedDate'
                              values={{
                                companyName: v.get('companyName'),
                                date: dateFormat(
                                  v.get('date'),
                                  'MM/DD/YYYY',
                                  true
                                )
                              }}
                            />
                          </Text>
                        ))
                        .toArray()}
                      {validations.filter(v => v.get('confirmed')).size > 0 && (
                        <Fragment>
                          <Label>
                            <FormattedMessage
                              id='AttachmentCard.ConfirmedBy'
                              defaultMessage='Confirmed by'
                            />
                          </Label>
                          {validations
                            .filter(v => v.get('confirmed'))
                            .map(v => (
                              <Text key={v.get('companyName')}>
                                {v.get('companyName')} (
                                {dateFormat(v.get('date'))})
                              </Text>
                            ))
                            .toArray()}
                        </Fragment>
                      )}
                    </Fragment>
                  }
                >
                  <img
                    src={ImageWarning}
                    alt='Verified'
                    className='w1 self-start ml1'
                  />
                </Tooltip>
              )}
          </div>
          {sourceUrl && (
            <Text>
              <FormattedMessage
                id='AttachmentCard.Source'
                defaultMessage='Source'
              />{' '}
              {sourceUrl.includes('beta.sam.gov') ? (
                ': The System for Award Management (SAM)'
              ) : (
                <ExternalLink
                  href={sourceUrl}
                  className={`${styles.attachmentUrl} ${colors.primaryHover}`}
                >
                  {truncate(sourceUrl, { length: 60 })}
                </ExternalLink>
              )}
            </Text>
          )}
          {additionalAttributes}
          <div>
            {attachmentUrl && (
              <ExternalLink href={attachmentUrl}>
                <Text
                  className={`dim no-underline pointer ${colors.primaryText}`}
                >
                  <img
                    src={iconFile}
                    alt='Attachment'
                    className='h1 dib v-mid'
                  />{' '}
                  <span className='v-mid'>
                    <FormattedMessage
                      id='AttachmentCard.DownloadAttachment'
                      defaultMessage='Download Attachment'
                    />
                  </span>
                </Text>
              </ExternalLink>
            )}
          </div>
          {category && subCategory && subTypes && subTypes.size > 0 && (
            <Text>
              {subTypes
                .map(sType => {
                  const sTypeKey = camelCase(sType)
                  const subTypesMessagesBySubCategory =
                    CertificationCategories[category] &&
                    CertificationCategories[category].subTypes
                  const subTypesMessages =
                    subTypesMessagesBySubCategory &&
                    subTypesMessagesBySubCategory[subCategory]

                  return subTypesMessages && subTypesMessages[sTypeKey]
                    ? intl.formatMessage(subTypesMessages[sTypeKey])
                    : sType
                })
                .join(', ')}
            </Text>
          )}

          {expirationDate && (
            <Text>
              <FormattedMessage
                id='AttachmentCard.ExpiresOn'
                defaultMessage='Expires on {expirationDate}'
                values={{
                  expirationDate: dateFormat(expirationDate, 'MM/DD/YYYY', true)
                }}
              />
            </Text>
          )}

          {certificateNumber && (
            <Text>
              <FormattedMessage
                id='AttachmentCard.CertificateNumber'
                defaultMessage='Certificate number {certificateNumber}'
                values={{ certificateNumber }}
              />
            </Text>
          )}

          {note && <div className={styles.note}>{note}</div>}

          {children}
        </div>
      </div>
      {rightContent && (
        <div className={styles.rightWrapper}>{rightContent}</div>
      )}
    </div>
  )
}

export default CertCard
