import { call } from 'redux-saga/effects'
import user from 'shared/utils/api/user'
import users from 'shared/utils/api/users'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* changePasswordSaga(action) {
  const {
    oldPassword,
    newPassword,
    confirmNewPassword,
    userId
  } = action.payload.toJS()

  if (userId) {
    return yield call(users.changePassword, {
      userId,
      password: newPassword
    })
  } else {
    return yield call(user.updatePassword, {
      oldPassword,
      newPassword,
      confirmNewPassword
    })
  }
}

export default createApiRequestSaga(changePasswordSaga)
