import { connect } from 'react-redux'
import UserLookup from '../../components/UserLookup'
import usersSelectors from 'shared/selectors/usersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import RootState from 'shared/models/RootState'

type Props = {
  isMulti?: boolean
}

export default connect((state: RootState, props: Props) => {
  const users = usersSelectors.getColleaguesExcludingUser(
    state,
    sessionSelectors.getUserId(state)
  )

  return {
    users,
    noOptionsMessage: `We couldn't find the person. Invite by using an email address.`
  }
})(UserLookup)
