import React, { useRef, useEffect } from 'react'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import PageSideSection from 'shared/components/PageSideSection'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import RelationshipAttributes from '../../containers/RelationshipAttributes'
import RelationshipAttachments from '../../containers/RelationshipAttachments'
import SupplierVetsContainer from '../../containers/SupplierVetsContainer'
import ColleaguesTags from '../../containers/ColleaguesTags'
import AddAttachmentForm from '../../containers/AddAttachmentForm'
import CustomAttributes from '../../containers/CustomAttributes'
import StandardAttributes from '../../containers/StandardAttributes'
import ReviewsSectionContainer from '../../containers/ReviewsSectionContainer'
import CommentsAboutSupplier from '../../containers/CommentsAboutSupplier'
import Scrollable from 'shared/components/Scrollable'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps } from 'react-router-dom'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import { List, RecordOf } from 'immutable'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import SupplierTags from 'buyer/SupplierProfile/containers/SupplierTags'
import PrivateOfferingsAddTagForm from 'buyer/SupplierProfile/containers/PrivateOfferingsAddTagForm'
import useUserAccess from 'shared/utils/useUserAccess'
import { LicenseAccess } from 'buyer/shared/reducers/settingsReducer'

type Props = {
  hasEndorsements: boolean
  hasAttachments: boolean
  hasCustomAttributes: boolean
  hasStandardAttributes: boolean
  scrollToReviews: boolean
  scrollToActivities: boolean
  scrollToRelationship: boolean
  scrollToAttachment: boolean
  scrollToAttribute: boolean
  loadTier2SupplierList: () => void
  sharedTier2Quarters: List<RecordOf<{ year: string; quarter: string }>>
  hasBeenInvitedToTier2: boolean
  isTier2Enabled: boolean
  privateOfferingsTagCount: number
} & RouteComponentProps

const MyCompanyPage = (props: Props) => {
  const {
    scrollToReviews,
    scrollToActivities,
    scrollToRelationship,
    scrollToAttachment,
    scrollToAttribute,
    loadTier2SupplierList,
    isTier2Enabled,
    hasEndorsements,
    hasCustomAttributes,
    hasStandardAttributes,
    hasAttachments,
    sharedTier2Quarters,
    hasBeenInvitedToTier2,
    privateOfferingsTagCount
  } = props

  const reviewSection = useRef<HTMLDivElement>(null)
  const activitiesSection = useRef<HTMLDivElement>(null)
  const relationshipSection = useRef<HTMLDivElement>(null)
  const attachmentSection = useRef<HTMLDivElement>(null)
  const attributeSection = useRef<HTMLDivElement>(null)

  const userAccess = useUserAccess() as LicenseAccess

  useEffect(() => {
    if (scrollToReviews) {
      setTimeout(() => scrollToSection(reviewSection?.current), 500)
    } else if (scrollToActivities) {
      setTimeout(() => scrollToSection(activitiesSection?.current), 500)
    } else if (scrollToRelationship) {
      setTimeout(() => scrollToSection(relationshipSection?.current), 500)
    } else if (scrollToAttachment) {
      setTimeout(() => scrollToSection(attachmentSection?.current), 500)
    } else if (scrollToAttribute) {
      setTimeout(() => scrollToSection(attributeSection?.current), 500)
    }
    if (isTier2Enabled) {
      loadTier2SupplierList()
    }
  }, [
    scrollToAttribute,
    scrollToAttachment,
    scrollToRelationship,
    scrollToActivities,
    scrollToReviews,
    isTier2Enabled,
    loadTier2SupplierList
  ])

  const scrollToSection = section => {
    if (section) {
      document.documentElement.scrollTop = section.offsetTop - 100
      document.body.scrollTop = section.offsetTop - 100
    }
  }

  return (
    <Page
      title={
        <FormattedMessage
          id='MyCompanyPage.MyCompany'
          defaultMessage='My Company'
        />
      }
    >
      <ScrollToPosition to={300} strict='up' />
      <Main>
        <div ref={reviewSection}>
          <ReviewsSectionContainer />
        </div>

        {(hasCustomAttributes || hasStandardAttributes) && (
          <div ref={attributeSection}>
            <PageSection
              title={
                <FormattedMessage
                  id='MyCompanyPage.Specific'
                  defaultMessage='Specific'
                />
              }
            >
              {userAccess.accessSupplierSpend && <StandardAttributes />}
              <CustomAttributes />
            </PageSection>
          </div>
        )}
        <PageSection
          title={
            <FormattedMessage
              id='MyCompanyPage.InternalOfferings'
              defaultMessage='Internal Offerings'
            />
          }
          noPadding
        >
          <Scrollable maxHeight={500}>
            <SupplierTags tagsFieldName='privateOfferings' />
          </Scrollable>
          <PrivateOfferingsAddTagForm numberOfTags={privateOfferingsTagCount} />
        </PageSection>

        <div ref={activitiesSection}>
          <CommentsAboutSupplier />
        </div>

        <div ref={relationshipSection}>
          <RelationshipAttributes />
        </div>

        <div ref={attachmentSection}>
          <PageSection
            noPadding
            title={
              <FormattedMessage
                id='MyCompanyPage.Attachments'
                defaultMessage='Attachments'
              />
            }
          >
            {hasAttachments && (
              <div className='ph3-5 pb3'>
                <RelationshipAttachments />
              </div>
            )}
            <AddAttachmentForm />
          </PageSection>
        </div>

        <ClearbitCredit />
      </Main>

      <Aside className={hasCustomAttributes ? 'pt3-l' : 'pt4-l'}>
        {hasEndorsements && (
          <PageSideSection
            title={
              <FormattedMessage
                id='MyCompanyPage.EndorsementsByColleaguesWithinYourCompany'
                defaultMessage='Endorsements by colleagues within your company'
              />
            }
          >
            <Scrollable>
              <ColleaguesTags />
            </Scrollable>
          </PageSideSection>
        )}

        {hasBeenInvitedToTier2 && userAccess.accessTier2 && (
          <PageSideSection title='Tier2' hideTitle>
            <Label>
              <FormattedMessage
                id='MyCompanyPage.Tier2'
                defaultMessage='Tier 2 Diversity'
              />
            </Label>
            <Text className='mt2' secondary>
              <FormattedMessage
                id='MyCompanyPage.Tier2Invitation'
                defaultMessage='Invited to participate in Tier 2'
              />
            </Text>
            {sharedTier2Quarters &&
              !!sharedTier2Quarters.size &&
              sharedTier2Quarters.map(item => (
                <>
                  <Text className='mt2'>
                    <FormattedMessage
                      id='MyCompanyPage.SharedQuarters'
                      defaultMessage='Shared Tier 2 Quarters'
                    />
                  </Text>
                  <Text
                    className='mt2'
                    secondary
                    key={`${item.get('year')}-${item.get('quarter')}`}
                  >
                    {item.get('year')} &ndash; Q{item.get('quarter')}&nbsp;
                  </Text>
                </>
              ))}
          </PageSideSection>
        )}
        {userAccess.accessRFI && <SupplierVetsContainer />}
      </Aside>
    </Page>
  )
}

export default MyCompanyPage
