import React, { useState, useMemo } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Select from 'react-select'
import useSmallScreen from 'shared/utils/useSmallScreen'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import Button from 'shared/components/Button'
import ListItem from 'shared/components/List/ListItem'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import { useDispatch } from 'react-redux'
import Avatar from 'shared/components/Avatar'
import { notify } from 'shared/actions'
import { fromJS, List, RecordOf } from 'immutable'
import AddOutreachContactForm from '../../containers/AddOutreachContactForm'
import AddIcon from '@material-ui/icons/AddCircle'
import User from 'shared/models/User'

type ChatWithContactProps = {
  firstName: string
  lastName: string
  title?: string
  profilePictureUrl?: string
}
export const ContactItem = (props: ChatWithContactProps) => {
  const { firstName, lastName, profilePictureUrl, title } = props

  return (
    <ListItem
      leftAvatar={
        <Avatar
          url={profilePictureUrl}
          className='mt1 w1-5'
          name={`${firstName || ''} ${lastName || ''}`}
        />
      }
      primaryText={
        <div className='flex items-center f7-5'>
          <span>
            {firstName} {lastName}
          </span>
        </div>
      }
      secondaryText={
        title ? <div className='f9 ma0 gray'>{title}</div> : undefined
      }
      className='ma0'
      mini
    />
  )
}

type Props = {
  contacts: List<RecordOf<User>>
  activeUserId: string
  requestedContactId: string
  taskId: string
  clientName: string
  isSelfCertifyDialogOpen: boolean
  requestedContact: RecordOf<User>
  handleToggleSelfCertifyDialog: () => void
  handleResendSupplierOutreachRequest: (
    taskId: string,
    status: string,
    currentUserId: string,
    selectedRequestContactId: string
  ) => void
  handleCancel: () => void
  onMessageUser: (arg: string) => void
  onClickNewUser: () => void
  handleCancelSelfCertifyNewUserForm: () => void
}

export const SelfCertifyDialog = (props: Props) => {
  const {
    contacts,
    activeUserId,
    requestedContactId,
    taskId,
    clientName,
    isSelfCertifyDialogOpen,
    requestedContact,
    handleToggleSelfCertifyDialog,
    handleResendSupplierOutreachRequest,
    handleCancel,
    onMessageUser,
    onClickNewUser,
    handleCancelSelfCertifyNewUserForm
  } = props
  const intl = useIntl()
  const dispatch = useDispatch()
  const matchMobile = useSmallScreen()
  const messages = defineMessages({
    selfCertify: {
      id: 'selfCertify.PleaseSelfCertify',
      defaultMessage: `Please log in to TealBook and complete the self-certification process to comply with {name}'s diversity program requirements. If you have any questions, please let us know by logging in and responding to this message. If you require assistance with the self-certification process, have a look at our detailed process guide here https://www.tealbook.com/resource/supplier-self-certification`
    }
  })
  const [requestSelfCertifyMessage, setRequestSelfCertifyMessage] = useState<
    string
  >(intl.formatMessage(messages.selfCertify, { name: clientName }))
  const [selectInput, setSelectInput] = useState<string>('')
  const [selectedContactId, setSelectedContactId] = useState<string>(
    requestedContactId
  )
  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false)
  const handleOnClickAddUserButton = () => {
    setOpenNewContactForm(true)
    onClickNewUser()
    handleToggleSelfCertifyDialog()
  }
  const handleSelectInputChange = (inputValue: string) => {
    setSelectInput(inputValue)
  }
  const sortContacts = useMemo(() => {
    return !contacts
      ? []
      : !selectInput
      ? contacts.toJS()
      : contacts
          .sort((userA, userB) => {
            let fullNameA = `${userA.get('firstName') &&
              userA.get('firstName').trim()} ${userA.get('lastName') &&
              userA.get('lastName').trim()}`.toLowerCase()
            let fullNameB = `${userB.get('firstName') &&
              userB.get('firstName').trim()} ${userB.get('lastName') &&
              userB.get('lastName').trim()}`.toLowerCase()
            fullNameA = fullNameA.startsWith(selectInput)
              ? `0${fullNameA}`
              : fullNameA
            fullNameB = fullNameB.startsWith(selectInput)
              ? `0${fullNameB}`
              : fullNameB

            if (fullNameA > fullNameB) {
              return 1
            }
            if (fullNameA < fullNameB) {
              return -1
            }
            return 0
          })
          .toJS()
  }, [contacts, selectInput])
  const filterOption = props => {
    const user = props.data
    const userFullName = `${user.firstName &&
      user.firstName.trim()} ${user.lastName &&
      user.lastName.trim()}`.toLowerCase()

    return userFullName.includes(selectInput.toLowerCase())
  }

  const formatOptionLabel = (user, data) => {
    if (!user) {
      return null
    }
    return (
      <ContactItem
        key={user.id}
        firstName={user.firstName}
        lastName={user.lastName}
        profilePictureUrl={user.profilePictureUrl}
        title={user.title}
      />
    )
  }

  const handleSelfCertifyMessage = e => {
    setRequestSelfCertifyMessage(e.target.value)
  }
  const handleContactSelected = (data, metaAction) => {
    setSelectedContactId(data.id)
    setSelectInput('')
  }
  const handleSelfCertifyRequest = e => {
    e.preventDefault()
    onMessageUser(
      fromJS({
        userToMessageId: selectedContactId,
        message: requestSelfCertifyMessage
      })
    )
    handleResendSupplierOutreachRequest(
      taskId,
      'WaitingForClient',
      activeUserId,
      selectedContactId
    )
    dispatch(notify({ message: 'Message succesfully sent' }))

    handleToggleSelfCertifyDialog()
  }
  return (
    <>
      <Dialog
        onClose={handleToggleSelfCertifyDialog}
        open={isSelfCertifyDialogOpen}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
      >
        <form onSubmit={handleSelfCertifyRequest}>
          <DialogTitle>
            <FormattedMessage
              id='SupplierProfileHeader.RequestSelfCertify'
              defaultMessage='Self Certification Request'
            />
          </DialogTitle>
          <DialogContent className='mt3'>
            <Text>
              <FormattedMessage
                id='SupplierOutreach.ContactSelectInstruction'
                defaultMessage='Indicate a Contact you would like to complete this request'
              />
            </Text>
            <Label>
              <FormattedMessage
                id='SelfCertify.ContactSelect'
                defaultMessage='Contacts'
              />
            </Label>
            <Select
              aria-label='Select Contact'
              InputValue={selectInput}
              onInputChange={handleSelectInputChange}
              onChange={handleContactSelected}
              placeholder={
                (
                  <FormattedMessage
                    id='UserLookup.Select Contact'
                    defaultMessage='Type in Contact Name'
                  />
                ) as any
              }
              options={sortContacts as any}
              defaultValue={requestedContact.toJS()}
              formatOptionLabel={formatOptionLabel}
              filterOption={filterOption}
              getOptionValue={opt => opt.id || opt.value}
              noOptionsMessage={inputValue => 'No Contacts Found'}
              controlShouldRenderValue={true}
              styles={{
                control: base => ({
                  ...base,
                  height: 30
                }),
                input: base => ({
                  ...base,
                  fontSize: '.875rem',
                  height: 30
                }),
                placeholder: base => ({
                  ...base,
                  fontSize: '.875rem'
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                noOptionsMessage: base => ({
                  ...base,
                  fontSize: '.875rem'
                }),
                option: (base, { isDisabled, isFocused }) => ({
                  ...base,
                  backgroundColor: isDisabled
                    ? 'inherit'
                    : isFocused
                    ? '#E0E0E0'
                    : 'inherit',
                  ':hover': {
                    ...base[':hover'],
                    backgroundColor: !isDisabled && '#e0e0e0'
                  }
                })
              }}
              menuPortalTarget={document.body}
            />
            <div className='mb3 mt3'>
              <div className='dib w-35'>
                <Button
                  color='primary'
                  variant='text'
                  startIcon={<AddIcon />}
                  label={
                    <FormattedMessage
                      id='SelfCertify.AddContact'
                      defaultMessage='Add a New Contact'
                    />
                  }
                  onClick={() => handleOnClickAddUserButton()}
                />
              </div>
            </div>
            <Label htmlFor='message'>
              <FormattedMessage
                id='SelfCertify.AddMessage'
                defaultMessage='Add Message'
              />
            </Label>
            <TextArea
              id='message'
              value={requestSelfCertifyMessage}
              onChange={handleSelfCertifyMessage}
              maxLength={500}
            />
          </DialogContent>
          <DialogActions className='fixed static-ns bottom-1'>
            <Button
              size={matchMobile ? 'large' : undefined}
              label={
                <FormattedMessage id='SelfCertify.Send' defaultMessage='Send' />
              }
              disabled={!selectedContactId || !requestSelfCertifyMessage}
              type='submit'
            />
            <Button
              size={matchMobile ? 'large' : undefined}
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleCancel}
              secondary
              className='mr3'
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>
      {openNewContactForm && (
        <AddOutreachContactForm
          defaultMessage={intl.formatMessage(messages.selfCertify, {
            name: clientName
          })}
          resendOutreachRequest={true}
          taskId={taskId}
          onClickCancel={handleCancelSelfCertifyNewUserForm}
        />
      )}
    </>
  )
}
export default SelfCertifyDialog
