import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import suppliers from 'shared/utils/api/suppliers'

export function* getRecentSuppliersTagsSaga() {
  let cards = yield call(suppliers.getRecentSuppliersTags, false)
  return cards.reduce((result, currentCard) => {
    const supplierId = currentCard.supplier.id
    const created = currentCard.created
    const privateOfferings = currentCard.privateOfferings
    const offerings = currentCard.offerings
    const differentiators = currentCard.differentiators

    return {
      ...result,
      [supplierId]: {
        offerings: offerings.reduce(
          (result, currentOffering) => ({
            ...result,
            [currentOffering]: created
          }),
          {}
        ),
        differentiators: differentiators.reduce(
          (result, currentDifferentiator) => ({
            ...result,
            [currentDifferentiator]: created
          }),
          {}
        ),
        privateOfferings: privateOfferings.reduce(
          (result, currentPrivateOffering) => ({
            ...result,
            [currentPrivateOffering]: created
          }),
          {}
        ),
        supplierInfo: currentCard.supplier
      }
    }
  }, {})
}

export default createApiRequestSaga(getRecentSuppliersTagsSaga)
