import { call } from 'redux-saga/effects'
import community from 'shared/utils/api/community'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* removeFromCommunitySaga(action) {
  const { communityId, supplierId } = action.payload

  yield call(community.removeFromCommunity, { communityId, supplierId })
  return yield action.payload
}

export default createApiRequestSaga(removeFromCommunitySaga)
