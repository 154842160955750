import { defineMessages } from 'react-intl'

export const buyerRoles = defineMessages({
  buyer: {
    id: 'EditUserProfile.BuyerRole',
    defaultMessage: 'Buyer'
  },
  buyerVetApprover: {
    id: 'EditUserProfile.BuyerRFIApproverRole',
    defaultMessage: 'Buyer RFI Approver'
  },
  buyerAdmin: {
    id: 'EditUserProfile.BuyerAdminRole',
    defaultMessage: 'Buyer Admin'
  },
  buyerDataAdmin: {
    id: 'EditUserProfile.BuyerDataAdminRole',
    defaultMessage: 'Buyer Data Admin'
  },
  buyerDataExport: {
    id: 'EditUserProfile.BuyerDataExportRole',
    defaultMessage: 'Buyer Data Export'
  }
})

export const buyerRolesDescription = defineMessages({
  buyer: {
    id: 'EditUserProfile.BuyerRoleDescription',
    defaultMessage:
      'Search for suppliers, view supplier profiles, create notes, follow, tag and connect'
  },
  buyerVetApprover: {
    id: 'EditUserProfile.BuyerRFIApproverRoleDescription',
    defaultMessage:
      'Approve the suppliers that will be invited to RFIs and manage RFIs that they did not create.'
  },
  buyerAdmin: {
    id: 'EditUserProfile.BuyerAdminRoleDescription',
    defaultMessage: 'Manage user roles and user data to control user access.'
  },
  buyerDataAdmin: {
    id: 'EditUserProfile.BuyerDataAdminRoleDescription',
    defaultMessage: 'Manage buyer entered corporate settings and data.'
  },
  buyerDataExport: {
    id: 'EditUserProfile.BuyerDataExportRoleDescription',
    defaultMessage: 'Access tools to export corporate data from TealBook.'
  }
})

export const supplierRoles = defineMessages({
  supplier: {
    id: 'EditUserProfile.SupplierRole',
    defaultMessage: 'Supplier'
  },
  supplierAdmin: {
    id: 'EditUserProfile.SupplierAdminRole',
    defaultMessage: 'Supplier Admin'
  }
})

export const supplierRolesDescription = defineMessages({
  supplier: {
    id: 'EditUserProfile.RFISupplierRoleDescription',
    defaultMessage:
      "Can use the Supplier Application to manage your company's profile and respond to RFIs"
  },
  supplierAdmin: {
    id: 'EditUserProfile.SupplierAdminRoleDescription',
    defaultMessage: 'Manage user roles and user data to control user access.'
  }
})

export const orgAdminRoles = defineMessages({
  orgAdmin: {
    id: 'EditUserProfile.OrganizationAdminRole',
    defaultMessage: 'Organization Admin'
  },
  clientConcierge: {
    id: 'EditUserProfile.ClientConciergeRole',
    defaultMessage: 'Client Concierge'
  },
  supplierConcierge: {
    id: 'EditUserProfile.SupplierConciergeRole',
    defaultMessage: 'Supplier Concierge'
  },
  partner: {
    id: 'EditUserProfile.Partner',
    defaultMessage: 'Partner'
  },
  beta: {
    id: 'EditUserProfile.BetaRole',
    defaultMessage: 'Beta'
  },
  clickWorker: {
    id: 'EditUserProfile.ClickWorker',
    defaultMessage: 'Click Worker'
  },
  hil: {
    id: 'EditUserProfile.Hil',
    defaultMessage: 'HIL'
  }
})

export const orgAdminRolesDescription = defineMessages({
  // orgAdmin: {
  //   id: 'EditUserProfile.OrganizationAdminRoleDescription',
  //   defaultMessage: 'Organization Admin'
  // },
  // serverAdmin: {
  //   id: 'EditUserProfile.ServerAdminRoleDescription',
  //   defaultMessage: 'Server Admin'
  // },
  // clientConcierge: {
  //   id: 'EditUserProfile.ClientConciergeRoleDescription',
  //   defaultMessage: 'Client Concierge'
  // },
  // supplierConcierge: {
  //   id: 'EditUserProfile.SupplierConciergeRoleDescription',
  //   defaultMessage: 'Supplier Concierge'
  // },
  // beta: {
  //   id: 'EditUserProfile.BetaRole',
  //   defaultMessage: 'Beta'
  // }
  hil: {
    id: 'EditUserProfile.HilRoleDescription',
    defaultMessage: 'Human in the Loop'
  }
})
export const tealbotRoles = defineMessages({
  partner: {
    id: 'EditUserProfile.Partner',
    defaultMessage: 'Partner'
  },
  integrationPartner: {
    id: 'EditUserProfile.IntegrationPartner',
    defaultMessage: 'Integration Partner'
  },
  clickWorker: {
    id: 'EditUserProfile.ClickWorker',
    defaultMessage: 'Click Worker'
  },
  hil: {
    id: 'EditUserProfile.Hil',
    defaultMessage: 'HIL'
  }
})
export const tealbotRolesDescription = defineMessages({})
