import React, { Component } from 'react'
import Search from '../../../Search'

type Props = {
  disableSuggest: boolean
}

class SearchPage extends Component<Props> {
  render() {
    return (
      <div className='mw8 w-100 center border-box pt3-ns ph3 ph3-ns ph4-m ph0-ns cf'>
        <Search disableSuggest={this.props.disableSuggest} />
      </div>
    )
  }
}

export default SearchPage
