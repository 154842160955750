import React, { Fragment, ReactNode } from 'react'
import ExternalLink from '../ExternalLink'
import iconFile from 'shared/assets/icons/file.svg'
import dateFormat from 'shared/utils/dateFormat'
import Avatar from '../Avatar'
import Link from 'shared/components/Link'
import ImageVerified from 'shared/assets/icons/verified.svg'
import ImageExpired from 'shared/assets/icons/expired.svg'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import Tooltip from 'shared/components/Tooltip'
import ImageWarning from 'shared/assets/icons/warning.svg'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { List, RecordOf } from 'immutable'
import CertificationCategories, {
  CategoryType
} from 'shared/models/CertificationCategories'
import camelCase from 'lodash.camelcase'
import moment from 'moment'
import { translatedFilterKeys } from 'shared/utils/data/defineMessages'
import useThemeColors from 'shared/utils/useThemeColors'
import startCase from 'lodash.startcase'
import { ReactComponent as Check } from 'shared/assets/icons/check-solid.svg'
import { ReactComponent as Question } from 'shared/assets/icons/question-solid.svg'
import { alertCodes as codeMessages } from 'shared/utils/data/defineMessages'
import truncate from 'lodash.truncate'
import { alertCodesDescriptions } from 'shared/utils/data/defineMessages'

const styles = {
  attachmentWrapper: 'dib w-100 mt3',
  expirationDate: 'f7 fw3 mid-gray dib mt1',
  attachmentUrl:
    'f7 fw3 near-black no-underline underline-hover pointer dim mt1',
  sourceUrl: 'f7 fw3 mid-gray mt1',
  note: 'f7 fw4 mid-gray mt1 ma0 mr2 word-wrap',
  removeButton:
    'f7 fw2 dim red pointer ph0 pt2 mb2 bg-transparent b--transparent',
  createdByWrapper: 'dib w-50 w-30-ns v-top mt3',
  rightWrapper: 'dib w-100 w-30-ns v-top mt3 tr',
  avatarWrapper: 'dib w2',
  avatar: 'br-100 w-100',
  contentWrapper: 'pl2 v-top dib',
  createdByName: 'f8 fw6 ma0 mid-gray pointer lh-copy dib',
  createdDate: 'f8 fw4 ma0 gray lh-copy tl'
}

type Props = {
  category?: CategoryType
  subCategory?: string
  additionalAttributes?: string | ReactNode
  expirationDate: Date
  attachmentUrl?: string
  sourceUrl?: string
  note?: string
  createdByFirstName?: string
  createdByLastName?: string
  createdByProfileUrl?: string
  createdByProfilePictureUrl?: string
  createdDate?: Date
  certAgency?: string
  rightContent?: string | ReactNode
  certificateNumber?: string
  lastModifiedTimeStamp?: string
  subTypes?: List<string>
  validations?: List<
    RecordOf<{
      confirmed: boolean
      companyName: string
      date: undefined
      validationExpires: string
    }>
  >
  onEdit?: () => void
  onRemove?: () => void
  onRenew?: () => void
  intl: IntlShape
  type?: string
  label?: string
  children?: ReactNode
  supplierName?: string
  status?: 'confirmed' | 'potential'
  alertCodes?: List<string>
}

const AttachmentCard = (props: Props) => {
  const {
    category,
    subCategory,
    additionalAttributes,
    expirationDate,
    attachmentUrl,
    sourceUrl,
    note,
    onRemove,
    onEdit,
    onRenew,
    createdByFirstName,
    createdByLastName,
    createdByProfileUrl,
    createdByProfilePictureUrl,
    createdDate,
    certAgency,
    certificateNumber,
    lastModifiedTimeStamp,
    validations,
    rightContent,
    subTypes,
    intl,
    children,
    supplierName,
    alertCodes
  } = props

  const colors = useThemeColors()

  const validationWithExpiry =
    validations &&
    validations
      .filter(v => v.get('confirmed') && v.get('validationExpires'))
      .sort((v1, v2) => {
        return new Date(v2.get('validationExpires')) <
          new Date(v1.get('validationExpires'))
          ? -1
          : 1
      })

  const latestExpiry =
    validationWithExpiry && validationWithExpiry.size > 0
      ? !expirationDate
        ? new Date(validationWithExpiry.getIn([0, 'validationExpires']))
        : new Date(validationWithExpiry.getIn([0, 'validationExpires'])) >
          expirationDate
        ? new Date(validationWithExpiry.getIn([0, 'validationExpires']))
        : expirationDate
      : expirationDate

  const translatedAttachmentLabel =
    props.label && translatedFilterKeys['attachments'][camelCase(props.label)]

  return (
    <div>
      <div
        className={`${styles.attachmentWrapper} ${
          createdDate || rightContent ? 'w-70-ns' : ''
        }`}
      >
        <div>
          {props.category === 'diversity' &&
            props.status &&
            (props.status === 'confirmed' ? (
              <Tooltip
                title={
                  <FormattedMessage
                    id='CertAttributes.ConfirmedHelp'
                    defaultMessage='Qualified: Certificate is active and passed automated quality assurance.'
                  />
                }
              >
                <span className='mr1'>
                  <Check height={12} fill='#31b800' />
                </span>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <>
                    <FormattedMessage
                      id='CertAttributes.PotentialHelp'
                      defaultMessage='Potential: Issues with certificate are '
                    />
                    <br />
                    {alertCodes &&
                      alertCodes?.map(code => (
                        <p key={code}>
                          {`${
                            codeMessages[code]
                              ? intl.formatMessage(codeMessages[code])
                              : startCase(code)
                          } (${code}): ${
                            codeMessages[code]
                              ? intl.formatMessage(alertCodesDescriptions[code])
                              : startCase(code)
                          }`}
                        </p>
                      ))}
                  </>
                }
              >
                <span className='mr1'>
                  <Question height={12} fill='#fcba03' />
                </span>
              </Tooltip>
            ))}
          <h5 className='f7 fw6 mv0 dib v-mid'>
            {props.category &&
            subCategory &&
            CertificationCategories[props.category] &&
            CertificationCategories[props.category].subCategories[subCategory]
              ? intl.formatMessage(
                  CertificationCategories[props.category].subCategories[
                    subCategory
                  ]
                )
              : subCategory}

            {props.type && translatedAttachmentLabel
              ? intl.formatMessage(translatedAttachmentLabel)
              : props.label
              ? props.label
              : startCase(props.type)}
          </h5>
          {latestExpiry &&
            (latestExpiry.setHours(23, 59, 59, 99) <
              new Date().setHours(0, 0, 0, 0) ||
              (expirationDate &&
                moment(expirationDate).isBefore(latestExpiry, 'day'))) && (
              <Tooltip
                title={
                  expirationDate &&
                  moment(expirationDate).isBefore(latestExpiry, 'day') ? (
                    <FormattedMessage
                      id='AttachmentCard.ConflictingInformation'
                      defaultMessage='Conflicting information on expiration date: {expirationDate}'
                      values={{
                        expirationDate: dateFormat(
                          latestExpiry,
                          'MM/DD/YYYY',
                          true
                        )
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id='AttachmentCard.ExpiredOn'
                      defaultMessage='Expired on {expirationDate}'
                      values={{
                        expirationDate: dateFormat(
                          latestExpiry,
                          'MM/DD/YYYY',
                          true
                        )
                      }}
                    />
                  )
                }
                aria-label='Expired'
                placement='right'
              >
                <img
                  src={ImageExpired}
                  alt='Expired'
                  className='w1 dib v-mid ml1'
                />
              </Tooltip>
            )}
          {validations &&
            validations.size > 0 &&
            !validations.some(v => !v.get('confirmed')) &&
            (validations && validations.size > 0 ? (
              <Tooltip
                title={
                  <Fragment>
                    <Label noPadding>
                      <FormattedMessage
                        id='AttachmentCard.ConfirmedBy'
                        defaultMessage='Confirmed by'
                      />
                    </Label>
                    {validations
                      .map(v => (
                        <Text key={v.get('companyName')}>
                          <FormattedMessage
                            id='AttachmentCard.ConfirmedDate'
                            defaultMessage='{companyName} on {date}'
                            values={{
                              companyName: v.get('companyName'),
                              date: dateFormat(
                                v.get('date'),
                                'MM/DD/YYYY',
                                true
                              )
                            }}
                          />
                        </Text>
                      ))
                      .toArray()}
                  </Fragment>
                }
              >
                <img
                  src={ImageVerified}
                  alt='Verified'
                  className='w1 dib v-mid ml1'
                />
              </Tooltip>
            ) : (
              <img
                src={ImageVerified}
                alt='Verified'
                className='w1 dib v-mid ml1'
              />
            ))}
          {validations &&
            validations.filter(v => !v.get('confirmed')).size > 0 && (
              <Tooltip
                title={
                  <Fragment>
                    <Label noPadding>
                      <FormattedMessage
                        id='AttachmentCard.InvalidatedBy'
                        defaultMessage='Invalidated by'
                      />
                    </Label>
                    {validations
                      .filter(v => !v.get('confirmed'))
                      .map(v => (
                        <Text key={v.get('companyName')}>
                          <FormattedMessage
                            id='AttachmentCard.ConfirmedDate'
                            values={{
                              companyName: v.get('companyName'),
                              date: dateFormat(
                                v.get('date'),
                                'MM/DD/YYYY',
                                true
                              )
                            }}
                          />
                        </Text>
                      ))
                      .toArray()}
                    {validations.filter(v => v.get('confirmed')).size > 0 && (
                      <Fragment>
                        <Label>
                          <FormattedMessage
                            id='AttachmentCard.ConfirmedBy'
                            defaultMessage='Confirmed by'
                          />
                        </Label>
                        {validations
                          .filter(v => v.get('confirmed'))
                          .map(v => (
                            <Text key={v.get('companyName')}>
                              {v.get('companyName')} (
                              {dateFormat(v.get('date'))})
                            </Text>
                          ))
                          .toArray()}
                      </Fragment>
                    )}
                  </Fragment>
                }
              >
                <img
                  src={ImageWarning}
                  alt='Verified'
                  className='w1 dib v-mid ml1'
                />
              </Tooltip>
            )}
          {additionalAttributes}
          <div>
            {attachmentUrl && (
              <ExternalLink href={attachmentUrl}>
                <Text
                  className={`dim no-underline pointer ${colors.primaryText}`}
                >
                  <img
                    src={iconFile}
                    alt='Attachment'
                    className='h1 dib v-mid'
                  />{' '}
                  <span className='v-mid'>
                    <FormattedMessage
                      id='AttachmentCard.DownloadAttachment'
                      defaultMessage='Download Attachment'
                    />
                  </span>
                </Text>
              </ExternalLink>
            )}
          </div>
        </div>
        {category && subCategory && subTypes && subTypes.size > 0 && (
          <Text>
            {subTypes
              .map(sType => {
                const sTypeKey = camelCase(sType)
                const subTypesMessagesBySubCategory =
                  CertificationCategories[category] &&
                  CertificationCategories[category].subTypes
                const subTypesMessages =
                  subTypesMessagesBySubCategory &&
                  subTypesMessagesBySubCategory[subCategory]

                return subTypesMessages && subTypesMessages[sTypeKey]
                  ? intl.formatMessage(subTypesMessages[sTypeKey])
                  : sType
              })
              .join(', ')}
          </Text>
        )}

        {expirationDate && (
          <Text>
            <FormattedMessage
              id='AttachmentCard.ExpiresOn'
              defaultMessage='Expires on {expirationDate}'
              values={{
                expirationDate: dateFormat(expirationDate, 'MM/DD/YYYY', true)
              }}
            />
          </Text>
        )}

        {sourceUrl && (
          <Text>
            <FormattedMessage
              id='AttachmentCard.Source'
              defaultMessage='Source'
            />{' '}
            <ExternalLink
              href={sourceUrl}
              className={`${styles.attachmentUrl} ${colors.primaryHover}`}
            >
              {truncate(sourceUrl, { length: 60 })}
            </ExternalLink>
          </Text>
        )}

        {certificateNumber && (
          <Text>
            <FormattedMessage
              id='AttachmentCard.CertificateNumber'
              defaultMessage='Certificate number {certificateNumber}'
              values={{ certificateNumber }}
            />
          </Text>
        )}

        {certAgency &&
          (certAgency === 'Self Certify' ? (
            <Text>
              {supplierName ? (
                <>
                  <FormattedMessage
                    id='AttachmentCard.SelfCertifiedBy'
                    defaultMessage='Self Certified by'
                  />
                  : {supplierName}
                </>
              ) : (
                <FormattedMessage
                  id='AttachmentCard.SelfCertified'
                  defaultMessage='Self Certified'
                />
              )}
            </Text>
          ) : (
            <Text>
              <FormattedMessage
                id='AttachmentCard.CertifiedBy'
                defaultMessage='Certified by {certAgency}'
                values={{ certAgency }}
              />
            </Text>
          ))}

        {note && <div className={styles.note}>{note}</div>}

        {lastModifiedTimeStamp && (
          <Text className={onRenew ? 'red' : ''}>
            <FormattedMessage
              id='AttachmentCard.LastUpdated'
              defaultMessage='Last updated: {lastModifiedTimeStamp}'
              values={{
                lastModifiedTimeStamp: dateFormat(lastModifiedTimeStamp)
              }}
            />
          </Text>
        )}

        {children}

        {onEdit && (
          <Button
            autoSize
            size='small'
            secondary
            onClick={onEdit}
            className='mr3 mv2'
          >
            <FormattedMessage
              id='AttachmentCard.EditButton'
              defaultMessage='Edit'
            />
          </Button>
        )}

        {onRemove && (
          <Button
            size='small'
            className='mr3 mv2'
            caution
            autoSize
            onClick={onRemove}
          >
            <FormattedMessage
              id='AttachmentCard.RemoveButton'
              defaultMessage='Remove'
            />
          </Button>
        )}

        {onRenew && (
          <Button size='small' className='mv2' autoSize onClick={onRenew}>
            <FormattedMessage
              id='AttachmentCard.RenewButton'
              defaultMessage='Renew'
            />
          </Button>
        )}
      </div>

      {createdDate && (
        <div className={styles.createdByWrapper}>
          <div className={styles.avatarWrapper}>
            {createdByProfileUrl && (
              <Link to={createdByProfileUrl}>
                <Avatar
                  url={createdByProfilePictureUrl}
                  className={styles.avatar}
                  name={`${createdByFirstName || ''} ${createdByLastName ||
                    ''}`}
                />
              </Link>
            )}
          </div>
          <div className={styles.contentWrapper}>
            {createdByProfileUrl && (
              <Link to={createdByProfileUrl}>
                <h5 className={styles.createdByName}>
                  {createdByFirstName} {createdByLastName}
                </h5>
              </Link>
            )}
            <h5 className={styles.createdDate}>{dateFormat(createdDate)}</h5>
          </div>
        </div>
      )}
      {rightContent && (
        <div className={styles.rightWrapper}>{rightContent}</div>
      )}
    </div>
  )
}

export default injectIntl(AttachmentCard)
