import React, { Component } from 'react'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import PageSection from 'shared/components/PageSection'
import Page from 'shared/components/Page'
import CompleteByDate from '../CompleteByDate'
import Select from 'shared/components/Select'
import DropDownMenu from 'shared/components/DropDownMenu'
import { List, RecordOf } from 'immutable'
import Task from 'shared/models/Task'
import { Dialog, DialogContent, MenuItem } from '@material-ui/core'
import TaskDetailsContainer from '../../containers/TaskDetailsContainer'
import DialogTitle from 'shared/components/DialogTitle'
import { FormattedMessage } from 'react-intl'
import { defaultMemoize } from 'reselect'
import Loading from 'shared/components/Loading'
import AddNewTaskDialog from './AddNewTaskDialog'

type Props = {
  tasks: List<RecordOf<Task>>
  onCancel: (cellData: string) => void
  onComplete: (cellData: string) => void
  onChangeShowTasks: (status: string) => void
  onInProgress: (cellData: string) => void
  onWaitingForClient: (cellData: string) => void
  isLoading?: boolean
}

type State = {
  sortBy: 'responseDate' | 'dateCreated' | string
  sortDirection: 'ASC' | 'DESC'
  visibleTasks: List<RecordOf<Task>>
  selectedTaskId: string
  taskType?: string
  selectedIndex: number
  isTaskDetailsDialogOpen: boolean
  showOpen: boolean
  showCancelled: boolean
  showCompleted: boolean
  showInProgress: boolean
  showWaitingForClient: boolean
  valueAdded?: string
}

const sortTasks = ({ visibleTasks, sortBy, sortDirection }) => {
  const sortedTasks = visibleTasks.sort(
    (task1: RecordOf<Task>, task2: RecordOf<Task>) => {
      switch (sortBy) {
        case 'responseDate':
        case 'dateCreated':
          return (
            new Date(task1.get(sortBy)).valueOf() -
            new Date(task2.get(sortBy)).valueOf()
          )

        default:
          return task1.get(sortBy) < task2.get(sortBy) ? -1 : 1
      }
    }
  )

  return sortDirection === 'ASC' ? sortedTasks : sortedTasks.reverse()
}
//filter by 'status' and 'type' of task
const filterTasks = (tasks, status?, taskType?, valueAdded?) => {
  const showCompleted = status ? status === 'completed' : false
  const showCancelled = status ? status === 'cancelled' : false
  const showOpen = status ? status === 'open' : false
  const showInProgress = status ? status === 'inProgress' : false
  const showWaitingForClient = status ? status === 'waitingForClient' : false
  const showValueAddedYes = valueAdded && valueAdded === 'yes'
  const showValueAddedNo = valueAdded && valueAdded === 'no'
  return tasks.filter(
    task =>
      ((showCompleted && task.get('status') === 'Completed') ||
        (showCancelled && task.get('status') === 'Cancelled') ||
        (showInProgress && task.get('status') === 'InProgress') ||
        (showWaitingForClient && task.get('status') === 'WaitingForClient') ||
        (showOpen &&
          ['Created', 'InProgress', 'WaitingForClient'].includes(
            task.get('status')
          ))) &&
      (!taskType ||
        task.get('type') === taskType ||
        (taskType === 'AllTasks' ? task.get('type') : false)) &&
      (!valueAdded ||
        (showValueAddedYes && task.get('valueAdd')) ||
        (showValueAddedNo && task.get('valueAdd') === false))
  )
}

class TaskList extends Component<Props, State> {
  state: State = {
    visibleTasks: List([]),
    sortBy: 'responseDate',
    sortDirection: 'DESC',
    taskType: '',
    selectedTaskId: '',
    selectedIndex: 0,
    isTaskDetailsDialogOpen: false,
    showCompleted: false,
    showCancelled: false,
    showInProgress: false,
    showWaitingForClient: false,
    showOpen: true,
    valueAdded: ''
  }

  static getDerivedStateFromProps(props, state) {
    if (state.showOpen) {
      return {
        ...state,
        visibleTasks: sortTasks({
          ...state,
          visibleTasks: filterTasks(
            props.tasks,
            'open',
            state.taskType,
            state.valueAdded
          )
        })
      }
    }
    if (state.showCancelled) {
      return {
        ...state,
        visibleTasks: sortTasks({
          ...state,
          visibleTasks: filterTasks(
            props.tasks,
            'cancelled',
            state.taskType,
            state.valueAdded
          )
        })
      }
    }
    if (state.showCompleted) {
      return {
        ...state,
        visibleTasks: sortTasks({
          ...state,
          visibleTasks: filterTasks(
            props.tasks,
            'completed',
            state.taskType,
            state.valueAdded
          )
        })
      }
    }
    if (state.showInProgress) {
      return {
        ...state,
        visibleTasks: sortTasks({
          ...state,
          visibleTasks: filterTasks(
            props.tasks,
            'inProgress',
            state.taskType,
            state.valueAdded
          )
        })
      }
    }
    if (state.showWaitingForClient) {
      return {
        ...state,
        visibleTasks: sortTasks({
          ...state,
          visibleTasks: filterTasks(
            props.tasks,
            'waitingForClient',
            state.taskType,
            state.valueAdded
          )
        })
      }
    }
  }

  dialogContentRef: HTMLElement | null

  handleFilterByTasks = e => {
    const { tasks } = this.props
    const taskType = e.target.value
    this.setState({
      visibleTasks: filterTasks(tasks, null, taskType),
      taskType: taskType
    })
  }

  handleChangeFilter = e => {
    const status = e.target.value
    const { onChangeShowTasks, tasks } = this.props

    onChangeShowTasks(status)

    this.setState({
      showCompleted: status === 'completed',
      showCancelled: status === 'cancelled',
      showInProgress: status === 'inProgress',
      showWaitingForClient: status === 'waitingForClient',
      showOpen: status === 'open',
      visibleTasks: filterTasks(tasks, status)
    })
  }
  handleChangeFilterByValueAdded = e => {
    const { tasks } = this.props
    const valueAddedInput = e.target.value
    this.setState({
      valueAdded: valueAddedInput,
      visibleTasks: filterTasks(tasks, null, null, valueAddedInput),
      taskType: ''
    })
  }

  renderCompleteByCell = ({ cellData, rowData }: TableCellProps) => {
    return <CompleteByDate date={cellData} status={rowData.get('status')} />
  }

  getRow = ({ index }) => {
    const { visibleTasks } = this.state
    return visibleTasks.size > 0
      ? visibleTasks.get(index)
      : this.props.tasks.get(index)
  }

  handleRowClick = ({ index }) => {
    const { visibleTasks, isTaskDetailsDialogOpen } = this.state
    this.setState({
      selectedTaskId: visibleTasks.getIn([index, 'id']),
      selectedIndex: index,
      isTaskDetailsDialogOpen: !isTaskDetailsDialogOpen
    })
  }

  nextTask = () => {
    const { visibleTasks, selectedIndex } = this.state
    //check size of visibleTasks, if empty close dialog
    if (visibleTasks.size > 1) {
      //from selectedIndex, go down list until reach end, then close dialog
      if (selectedIndex + 1 < visibleTasks.size) {
        this.setState({
          selectedTaskId: visibleTasks.getIn([selectedIndex + 1, 'id'])
        })
        if (this.dialogContentRef) {
          this.dialogContentRef.scrollIntoView({
            block: 'start'
          })
        }
      } else {
        this.toggleTaskDetailsDialog()
      }
    } else {
      this.toggleTaskDetailsDialog()
    }
  }

  handleComplete = () => {
    this.nextTask()
  }

  handleCancel = () => {
    this.nextTask()
  }

  handleInProgress = () => {
    // Method stubbed out for possible future use.
    // Want the view to stay as in progress so that the concierge can continue reviewing the details.
    // Switching to In Progress will be the first action in most cases.
  }

  handleWaitingForClient = () => {
    this.nextTask()
  }

  toggleTaskDetailsDialog = () => {
    const { isTaskDetailsDialogOpen } = this.state

    this.setState({
      isTaskDetailsDialogOpen: !isTaskDetailsDialogOpen
    })
  }

  handleSortChange = ({ sortBy, sortDirection }) => {
    const { visibleTasks } = this.state

    this.setState({
      sortBy,
      sortDirection,
      visibleTasks: sortTasks({
        sortBy,
        sortDirection,
        visibleTasks
      })
    })
  }

  taskTypesSelector = (tasks: List<RecordOf<Task>>) => {
    //array of sorted task types without duplicates used to populate Select component
    const filteredByStatus = this.taskTypesFilteredByStatus(tasks)
    const filteredByValueAdded = this.tasksFilteredByValueAdded(
      filteredByStatus
    )
    return filteredByValueAdded.reduce<Array<string>>((result, curr) => {
      if (result.indexOf(curr.get('type')) < 0) {
        result.push(curr.get('type'))
      }
      return result.sort()
    }, [])
  }

  taskTypesFilteredByStatus = defaultMemoize((tasks: List<RecordOf<Task>>) => {
    //filter archived tasks by completed or cancelled
    if (this.state.showCancelled) {
      return tasks.filter(status => status.get('status') === 'Cancelled')
    }
    if (this.state.showCompleted) {
      return tasks.filter(status => status.get('status') === 'Completed')
    } else {
      return tasks
    }
  })

  tasksFilteredByValueAdded = (tasks: List<RecordOf<Task>>) => {
    if (this.state.valueAdded === 'yes') {
      return tasks.filter(valueAdded => valueAdded.get('valueAdd'))
    }
    if (this.state.valueAdded === 'no') {
      return tasks.filter(valueAdded => valueAdded.get('valueAdd') === false)
    } else {
      return tasks
    }
  }
  renderActionsColumn = ({ cellData, rowData }: TableCellProps) => {
    const {
      onCancel,
      onComplete,
      onInProgress,
      onWaitingForClient
    } = this.props

    return (
      <div onClick={e => e.stopPropagation()}>
        <DropDownMenu>
          {rowData.get('status') !== 'Completed' && (
            <MenuItem onClick={() => onComplete(cellData)}>Complete</MenuItem>
          )}
          {rowData.get('status') !== 'Cancelled' && (
            <MenuItem onClick={() => onCancel(cellData)}>Cancel</MenuItem>
          )}
          {rowData.get('status') !== 'InProgress' && (
            <MenuItem onClick={() => onInProgress(cellData)}>
              Task In Progress
            </MenuItem>
          )}
          {rowData.get('status') !== 'WaitingForClient' && (
            <MenuItem onClick={() => onWaitingForClient(cellData)}>
              Task Waiting For Client
            </MenuItem>
          )}
        </DropDownMenu>
      </div>
    )
  }

  render() {
    const {
      visibleTasks,
      sortBy,
      sortDirection,
      isTaskDetailsDialogOpen
    } = this.state

    return (
      <>
        <Page title='Tasks'>
          <PageSection
            noPadding
            title={
              <div className='tr'>
                <span>Show</span>
                <div className='w4 dib ml2'>
                  <Select fullWidth onChange={this.handleChangeFilter}>
                    <option value='open'>Open</option>
                    <option value='completed'>Completed</option>
                    <option value='cancelled'>Cancelled</option>
                    <option value='inProgress'>In Progress</option>
                    <option value='waitingForClient'>Waiting For Client</option>
                  </Select>
                </div>
                <div className='w4 dib ml2'>
                  <Select
                    fullWidth
                    onChange={this.handleChangeFilterByValueAdded}
                  >
                    <option value=''>Value Added</option>
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                  </Select>
                </div>
                <div className='w6 dib ml2'>
                  <Select fullWidth onChange={this.handleFilterByTasks}>
                    <option value='AllTasks'>All Tasks</option>
                    {this.taskTypesSelector(this.props.tasks).map(
                      (typeName, index) => (
                        <option key={index} value={typeName}>
                          {typeName}
                        </option>
                      )
                    )}
                  </Select>
                </div>
              </div>
            }
            actions={<AddNewTaskDialog />}
          >
            {this.props.isLoading && <Loading />}

            {visibleTasks.size > 0 && !this.props.isLoading && (
              <Table
                minWidth={990}
                rowGetter={this.getRow}
                rowCount={visibleTasks.size}
                rowClassName='pointer dim'
                onRowClick={this.handleRowClick}
                sort={this.handleSortChange}
                sortDirection={sortDirection}
                sortBy={sortBy}
              >
                <Column
                  label='ID'
                  dataKey='taskId'
                  width={70}
                  cellRenderer={({ cellData }: TableCellProps) =>
                    cellData.replace('TASK-', '#')
                  }
                />
                <Column
                  label='Complete By'
                  dataKey='responseDate'
                  width={175}
                  cellRenderer={this.renderCompleteByCell}
                />
                <Column label='Task' dataKey='type' width={125} />
                <Column label='Created By' dataKey='createdBy' width={150} />
                <Column label='Created Org' dataKey='orgUnitName' width={150} />
                <Column label='Status' dataKey='status' width={100} />
                <Column
                  label='Date Created'
                  dataKey='dateCreated'
                  width={150}
                />
                <Column
                  disableSort
                  dataKey='id'
                  width={30}
                  cellRenderer={this.renderActionsColumn}
                />
              </Table>
            )}
          </PageSection>

          {visibleTasks && visibleTasks.size < 1 && !this.props.isLoading && (
            <div>There are no tasks</div>
          )}
        </Page>
        <Dialog
          open={isTaskDetailsDialogOpen}
          onClose={this.toggleTaskDetailsDialog}
          maxWidth='md'
          fullWidth
        >
          <DialogTitle onClose={this.toggleTaskDetailsDialog}>
            <FormattedMessage
              id='TaskList.TaskDetails'
              defaultMessage='Task Details'
            />
          </DialogTitle>
          <DialogContent>
            <div
              ref={node => {
                this.dialogContentRef = node
              }}
            >
              <TaskDetailsContainer
                taskId={this.state.selectedTaskId}
                onComplete={this.handleComplete}
                onCancel={this.handleCancel}
                onInProgress={this.handleInProgress}
                onWaitingForClient={this.handleWaitingForClient}
              />
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default TaskList
