import React, { useState } from 'react'
import Page from 'shared/components/Page'
import InsightTitle from 'shared/components/InsightTitle'
import { FormattedMessage } from 'react-intl'
import Paper from 'shared/components/Paper'
import Checkbox from 'shared/components/Checkbox'
import startCase from 'lodash.startcase'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import { Column } from 'react-virtualized'
import Table from 'shared/components/Table'
import exportData from 'shared/utils/exportData'
import { Map, RecordOf, List } from 'immutable'
import ClientUsersTable from './ClientUsersTable'
import CategoriesTable from './CategoriesTable'
import Top80Table from './Top80Table'
import UserEngagementTable from './UserEngagementTable'
import CommunitySuppliersTable from './CommunitySuppliersTable'
import UserReconciliationTable from './UserReconciliationTable'
import RelationshipSuppliersTable from './RelationshipSuppliersTable'
import DatePicker from '../DatePicker'
import moment, { Moment } from 'moment'
import TealbotPage from 'data-portal/components/TealbotPage'

export type UserEngagement = RecordOf<{
  months: List<
    RecordOf<{
      Apr: number
      Aug: number
      Dec: number
      Feb: number
      Jan: number
      Jul: number
      Jun: number
      Mar: number
      May: number
      Nov: number
      Oct: number
      Sep: number
    }>
  >
  buyerRole: boolean
  domain: string
  email: string
  firstName: string
  label: string
  lastName: string
  name: string
  status: string
  supplierRole: boolean
  tealbotRole: boolean
  total: number
  year: number
}>
export type ClientUsers = RecordOf<{
  name: string
  orgUnitId: string
  firstName: string
  lastName: string
  email: string
  firstLogin: null | string
  lastLogin: null | string
}>

export type CommunitySuppliers = RecordOf<{
  communityName: string
  membershipDate: RecordOf<{
    value: string
  }>
  supplierName: string
  domain: string
  emails: string
}>

export type UserReconciliationReport = RecordOf<{
  classificationCodes: string
  month: string
  numClassificationCodes: string
  numSearches: number
  partner: string
  user: string
  year: number
}>

export type RelationshipSupplierReport = RecordOf<{
  internalSupId: string
  domains: string
  name: string
  OrgUnitID: string
}>

type SelectedOrgForRelationshipSupplierReport = {
  orgId: string
  orgName: string
}

type Props = {
  internalStats: Map<string, any>
  internalStatKeys: Map<string, string>
  isLoading: boolean
  onHandleQuery: (
    selectedQueries: string[],
    startDate: string,
    endDate: string,
    selectedOrgId: string
  ) => void
}
const InternalStats = ({
  internalStats,
  internalStatKeys,
  onHandleQuery,
  isLoading
}: Props) => {
  const [selectedQueries, setSelectedQueries] = useState<string[]>([])
  const [startDate, setStartDate] = useState<Moment>(
    moment().subtract(1, 'week')
  )
  const [endDate, setEndDate] = useState<Moment>(moment())
  const [
    selectedOrgForRelationshipSuppliersStat,
    setSelectedOrgForRelationshipSuppliersStat
  ] = useState<SelectedOrgForRelationshipSupplierReport>()

  const handleSelectOrg = (orgId, orgName) => {
    setSelectedOrgForRelationshipSuppliersStat({
      orgId: orgId,
      orgName: orgName
    })
  }

  return (
    <>
      <Page title='Internal Stats'>
        <Paper>
          <Label className='db pt3-5 mb1 f7 fw6'>
            <FormattedMessage
              id='InternalStats.Queries'
              defaultMessage='Select your queries'
            />
          </Label>
          <div className='flex justify-between'>
            <div>
              <div className='flex-wrap flex'>
                {!!internalStatKeys &&
                  !!internalStatKeys.size &&
                  internalStatKeys
                    .entrySeq()
                    .map(([key, value]) => (
                      <Checkbox
                        inline
                        key={key}
                        label={startCase(value)}
                        labelFontLight
                        checked={selectedQueries.includes(key)}
                        className='mv2'
                        onChange={() =>
                          selectedQueries.includes(key)
                            ? setSelectedQueries(prevState =>
                                prevState.filter(query => query !== key)
                              )
                            : setSelectedQueries(prevState => [
                                ...prevState,
                                key
                              ])
                        }
                      />
                    ))}
              </div>
              {(selectedQueries.includes('diversityCreatedCertifications') ||
                selectedQueries.includes('diversityExpiredCertifications') ||
                selectedQueries.includes('supplierReport') ||
                selectedQueries.includes('clientUsers')) && (
                <div>
                  <Label>Date Range In Between</Label>
                  <div className='mt3 flex justify-between items-center'>
                    <DatePicker
                      noKeyboard
                      label='Start Date'
                      name='startDate'
                      value={startDate.toDate()}
                      onChange={(newDate: Moment) => setStartDate(newDate)}
                      maxDate={endDate.toDate()}
                    />
                    <DatePicker
                      noKeyboard
                      label='End Date'
                      name='endDate'
                      value={endDate.toDate()}
                      onChange={(newDate: Moment) => {
                        if (newDate.isBefore(endDate)) {
                          setStartDate(newDate)
                          setEndDate(newDate)
                        } else {
                          setEndDate(newDate)
                        }
                      }}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
              )}
              {selectedQueries.includes('suppliersWithRelationshipToClient') &&
                !internalStats?.get('suppliersWithRelationshipToClient') && (
                  <TealbotPage
                    onSelectOrgForRelationshipSuppliersStat={(
                      orgId: string,
                      orgName: string
                    ) => handleSelectOrg(orgId, orgName)}
                  />
                )}
            </div>
            {!isLoading && (
              <Button
                onClick={() =>
                  onHandleQuery(
                    selectedQueries,
                    startDate.format('YYYY-MM-DD'),
                    endDate.format('YYYY-MM-DD'),
                    selectedOrgForRelationshipSuppliersStat
                      ? selectedOrgForRelationshipSuppliersStat.orgId
                      : ''
                  )
                }
                label={
                  <FormattedMessage
                    id='InternalStats.Query'
                    defaultMessage='Query'
                  />
                }
                disabled={
                  !!selectedQueries.length
                    ? selectedQueries.includes(
                        'suppliersWithRelationshipToClient'
                      ) &&
                      (!selectedOrgForRelationshipSuppliersStat ||
                        selectedOrgForRelationshipSuppliersStat.orgId === '')
                      ? true
                      : false
                    : true
                }
                className='w5'
              />
            )}
            {isLoading && (
              <Button
                disabled
                label={
                  <FormattedMessage
                    id='InternalStats.Querying'
                    defaultMessage='Querying...'
                  />
                }
                className='w5'
              />
            )}
          </div>
        </Paper>

        {!!internalStats &&
          !!internalStats.size &&
          internalStats.entrySeq().map(([stat, value]) => {
            if (stat === 'userEngagement') {
              return (
                <UserEngagementTable
                  key={stat}
                  stat={stat}
                  userEngagement={internalStats.get('userEngagement')}
                />
              )
            }
            if (stat === 'communitySuppliers') {
              return (
                <CommunitySuppliersTable
                  key={stat}
                  stat={stat}
                  communitySuppliers={internalStats.get('communitySuppliers')}
                />
              )
            }
            if (stat === 'categories1' || stat === 'categories10') {
              return <CategoriesTable key={stat} stat={stat} value={value} />
            }
            if (stat === 'clientUsers') {
              return <ClientUsersTable key={stat} stat={stat} value={value} />
            }
            if (stat === 'top80') {
              return <Top80Table key={stat} stat={stat} value={value} />
            }
            if (stat === 'userReconciliationReport') {
              return (
                <UserReconciliationTable
                  key={stat}
                  stat={stat}
                  userReconciliationReport={internalStats.get(
                    'userReconciliationReport'
                  )}
                />
              )
            }
            if (stat === 'suppliersWithRelationshipToClient') {
              return (
                <RelationshipSuppliersTable
                  key={stat}
                  stat={stat}
                  value={value}
                  orgName={
                    selectedOrgForRelationshipSuppliersStat?.orgName || ''
                  }
                />
              )
            } else {
              //when a query key has been added by back-end but not addressed in front-end
              const datakeys = value?.first()?.keySeq()
              return (
                <div key={stat}>
                  {datakeys && datakeys.size > 0 ? (
                    <>
                      <div className='flex justify-between items-center mt4'>
                        <InsightTitle>{startCase(stat)}</InsightTitle>
                        <Button
                          onClick={() => {
                            exportData.exportCSV(
                              value.toJS(),
                              `${startCase(stat)}`,
                              true
                            )
                          }}
                        >
                          <FormattedMessage
                            id='InternalStats.Export'
                            defaultMessage='Export'
                          />
                        </Button>
                      </div>
                      <div className='bg-white br1 ba b--black-10'>
                        <Table
                          rowGetter={({ index }) =>
                            value && !!value.size && value.get(index)
                          }
                          rowCount={value.size}
                        >
                          {datakeys.map((key, index) => {
                            return (
                              <Column
                                key={`${key}-${index}`}
                                label={key}
                                width={datakeys.size < 4 ? 300 : 150}
                                dataKey={key}
                              />
                            )
                          })}
                        </Table>
                      </div>
                    </>
                  ) : (
                    <div className='flex justify-between items-center mt4'>
                      <FormattedMessage
                        id='InternalStats.NoData'
                        defaultMessage='No data for {queryName}'
                        values={{
                          queryName: startCase(stat)
                        }}
                      />
                    </div>
                  )}
                </div>
              )
            }
          })}
      </Page>
    </>
  )
}

export default InternalStats
