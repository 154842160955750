import { fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { requestSuccess, LOGOUT } from 'shared/actionTypes'

/**
 * tags: {
 *   [fieldName]: {
 *     [tag name in camel case]: {
 *       name: {String} actual label of tag
 *       count: {Number} time this tag has been upvoted
 *       supplierTagged: {Boolean}
 *       colleagueTagged: {Boolean}
 *       userTagged: {Boolean} whether this has been tagged by the current user
 *         this is used to mark a tag "toggled" in the ui
 *       colleagues: [userId] List of userIds
 *     }
 *   }
 * }
 */
const tagsCountByNameReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case requestSuccess(actionTypes.LOAD_SUPPLIER_PROFILE):
    case requestSuccess(actionTypes.ADD_TAG):
    case requestSuccess(actionTypes.REMOVE_TAG):
      return fromJS(action.payload.combinedTags)
    case requestSuccess(actionTypes.DELETE_PRIVATE_OFFERINGS):
      return state.deleteIn([
        action.payload.tagsFieldName,
        action.payload.tagKey
      ])
    case LOGOUT:
      return fromJS({})
    default:
      return state
  }
}

export default tagsCountByNameReducer
