import React, { FunctionComponent } from 'react'
import Label from '../Label'
import Text from '../Text'
import { FormattedMessage } from 'react-intl'
import ExternalLink from 'shared/components/ExternalLink'

const CpiFAQ: FunctionComponent = () => (
  <>
    <Label>
      <FormattedMessage
        id='CpiFAQ.RatingMean'
        defaultMessage='What does my Corruption Perceptions Index rating mean?'
      />
    </Label>
    <Text className='mt3'>
      <FormattedMessage
        id='CpiFAQ.RatingMeanText'
        defaultMessage='Each year, Transparency International releases a ranking of 180 countries and territories based on their perceived levels of corruption in the public sector. The information used to compile the index is from experts and businesspeople, and ranks countries on a scale of 0 to 100.'
      />
    </Text>
    <Label>
      <FormattedMessage
        id='CpiFAQ.Interpret'
        defaultMessage={`How do I interpret my country's rating?`}
      />
    </Label>
    <Text className='mt3'>
      <FormattedMessage
        id='CpiFAQ.InterpretText'
        defaultMessage='A rating of 0 is considered very corrupt, while a rating of 100 is considered very clean. For context, more than two-thirds of the 180 countries included on the 2018 list scored less than 50. The average score was 43.'
      />
    </Text>
    <Label>
      <FormattedMessage
        id='CpiFAQ.Data'
        defaultMessage={`Where does TealBook get their data from?`}
      />
    </Label>
    <Text className='mt3'>
      <FormattedMessage
        id='CpiFAQ.DataText'
        defaultMessage='Tealbook gets their data from Transparency International. You can view the index in full {here}.'
        values={{
          here: (
            <ExternalLink href='https://www.transparency.org/cpi2018'>
              <FormattedMessage id='CpiFAQ.Here' defaultMessage='here' />
            </ExternalLink>
          )
        }}
      />
    </Text>
    <Label>
      <FormattedMessage
        id='CpiFAQ.Unhappy'
        defaultMessage={`What can I do if I am unhappy with my rating?`}
      />
    </Label>
    <Text className='mt3'>
      <FormattedMessage
        id='CpiFAQ.UnhappyText'
        defaultMessage='Use this as an opportunity to explain on your TealBook profile what your company does to mitigate the issues with corruption in your location. This could include diligence procedures, policies and standards your company uses to reduce the influence of corruption for you and your partners.'
      />
    </Text>
  </>
)

export default CpiFAQ
