import React, { ChangeEvent, useState, useEffect, FormEvent } from 'react'
import SowSideOrg from './SowSideOrg'
import { SowJobOrgUnit } from 'shared/models/Job'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Card from '@material-ui/core/Card'
import { useSelector, useDispatch } from 'react-redux'
import { rejectSowOrg, updateSowOrg, reorderOrgsPosition } from '../../actions'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextArea from 'shared/components/TextArea'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import withStyles from '@material-ui/styles/withStyles'
import Divider from 'shared/components/Divider'
import CloseIcon from '@material-ui/icons/Clear'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import jobsSelectors from 'jobs/selectors/jobsSelectors'
import SowSideSortList from './SowSideSortList'
import RootState from 'shared/models/RootState'

const StyleRadio = withStyles({
  root: {
    padding: 3
  }
})(Radio)

type Props = {
  disabled: boolean
  jobId: string
  onClick: (orgUnitId: string) => void
}

const SowSide = (props: Props) => {
  const dispatch = useDispatch()
  const acceptOrgs = useSelector(jobsSelectors.getSowAcceptList)
  const rejectOrgs = useSelector(jobsSelectors.getSowRejectList)

  const currentOrgUnitId = useSelector(
    (state: RootState) =>
      state.getIn(['jobs', 'currentOrgUnitId']) ||
      (acceptOrgs.length > 0 && acceptOrgs[0].orgUnitId)
  )
  const { jobId, disabled, onClick } = props
  const [sortableOrgs, setSortableOrgs] = useState<Array<SowJobOrgUnit>>(
    acceptOrgs
  )
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [orgUnitId, setOrgUnitId] = useState<string>('')
  const [rowNum, setRowNum] = useState<number>(-1)
  const [reason, setReason] = useState<string>('')
  const [otherReason, setOtherReason] = useState<string>('')

  useEffect(() => {
    setSortableOrgs(acceptOrgs)
  }, [acceptOrgs])

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return
    }
    dispatch(
      reorderOrgsPosition({
        jobId,
        newIndex,
        oldIndex
      })
    )
    // update local state to reflect the UI (use local state for the responsiveness)
    const newOrgs = [...(sortableOrgs as Array<SowJobOrgUnit>)]
    const startIndex = oldIndex < 0 ? newOrgs.length + oldIndex : oldIndex

    if (startIndex >= 0 && startIndex < newOrgs.length) {
      const endIndex = newIndex < 0 ? newOrgs.length + newIndex : newIndex

      const [item] = newOrgs.splice(oldIndex, 1)
      newOrgs.splice(endIndex, 0, item)
    }

    setSortableOrgs(newOrgs)
  }

  const handleDeprioritizeClick = (
    ele: HTMLElement,
    orgUnitId: string,
    row: number
  ) => {
    setAnchorEl(ele)
    setOrgUnitId(orgUnitId)
    setRowNum(row)
  }

  const handlePrioritizeClick = (ele: HTMLElement, orgUnitId: string) => {
    dispatch(
      updateSowOrg({
        jobId,
        orgUnitId,
        rejectReason: '',
        rowNum: sortableOrgs.length
      })
    )
  }

  const handleRejectOrg = ({ orgUnitId, rejectReason }) => {
    dispatch(
      rejectSowOrg(
        {
          jobId,
          newIndex: sortableOrgs.length - 1,
          oldIndex: rowNum
        },
        {
          rejectReason
        }
      )
    )
    closePopup()
  }

  const closePopup = () => {
    setAnchorEl(null)
    setReason('')
    setOtherReason('')
    setRowNum(-1)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleRejectOrg({
      orgUnitId,
      rejectReason: otherReason || reason
    })
  }

  return (
    <div>
      <SowSideSortList
        orgs={sortableOrgs}
        currentOrgUnitId={currentOrgUnitId}
        onSortEnd={handleSortEnd}
        useDragHandle
        disabled={disabled}
        onClick={onClick}
        onDeprioritize={handleDeprioritizeClick}
      />
      <Divider className='mv3 nr4-l' />
      {rejectOrgs.length > 0 && (
        <h5 className='f6 fw4 ph3 mid-gray'>Disqualified List</h5>
      )}
      {rejectOrgs?.map(org => (
        <SowSideOrg
          key={org.orgUnitId}
          orgUnitId={org.orgUnitId}
          isSelected={false}
          isDisabled={false}
          name={org.name}
          logoUrl={
            org.logoUrl ||
            (org.domains ? `https://logo.clearbit.com/${org.domains[0]}` : '')
          }
          onMenuPrioritize={handlePrioritizeClick}
        />
      ))}
      <div className='mr2 mr0-l'>
        <ClearbitCredit />
      </div>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement='right-start'
        className='z-999'
      >
        <ClickAwayListener onClickAway={closePopup}>
          <Card className='ph4 pv3'>
            <form onSubmit={handleSubmit}>
              <div className='flex items-center justify-between mb2'>
                <Label noPadding>Supplier should be deprioritized due to</Label>
                <CloseIcon className='pointer dim' onClick={closePopup} />
              </div>
              <RadioGroup
                color='primary'
                aria-label='reason'
                name='reason'
                value={reason}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setReason(e.currentTarget.value)
                }
              >
                <FormControlLabel
                  control={
                    <StyleRadio
                      color='primary'
                      size='small'
                      value={'The Supplier is not reputable/financially stable'}
                    />
                  }
                  label={'The Supplier is not reputable/financially stable'}
                />
                <FormControlLabel
                  control={
                    <StyleRadio
                      color='primary'
                      size='small'
                      value={
                        'The Supplier talks about an offerings(s), but it is not what they do'
                      }
                    />
                  }
                  label={
                    'The Supplier talks about an offerings(s), but it is not what they do'
                  }
                />
                <FormControlLabel
                  control={
                    <StyleRadio
                      color='primary'
                      size='small'
                      value={`Tealbook search results don't make sense with specific Supplier included`}
                    />
                  }
                  label={`Tealbook search results don't make sense with specific Supplier included`}
                />
                <FormControlLabel
                  control={
                    <StyleRadio color='primary' size='small' value={'Other'} />
                  }
                  label={'Other'}
                />
              </RadioGroup>
              {reason === 'Other' && (
                <TextArea
                  aria-label='other reason'
                  value={otherReason}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    setOtherReason(e.currentTarget.value)
                  }
                />
              )}
              <Divider className='mv3' />
              <div className='flex justify-end'>
                <Button
                  type='submit'
                  autoSize
                  disabled={!reason || (reason === 'Other' && !otherReason)}
                >
                  Submit
                </Button>
              </div>
            </form>
          </Card>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export default SowSide
