import { fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'

const defaultState = fromJS({
  email: '',
  editMode: true
})

const userEmailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_USER_EMAIL:
      return state.merge({
        email: action.payload.get('email'),
        editMode: false
      })
    case actionTypes.EDIT_USER_EMAIL:
      return state.set('editMode', true)
    default:
      return state
  }
}

export default userEmailReducer
