import React, { FC, useState, ChangeEvent, FormEvent } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import SupplierMessageSummary from '../SupplierMessageSummary'
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  IntlShape
} from 'react-intl'
import UserLookupContainer from 'messages/containers/UserLookupContainer'
import { List } from 'immutable'

const messages = defineMessages({
  share: {
    id: 'ShareProfileDialog.IThoughtYouMightBeInterested',
    defaultMessage: 'I thought you might be interested in this company.'
  }
})

type Props = {
  supplierName: string
  logoUrl: string
  description: string
  matchMobile?: boolean
  open: boolean
  fullScreen?: boolean
  buyerDomains: List<string>
  handleShareSupplier: (params: {
    userToMessage: Array<string>
    message: string
  }) => void
  onClose: () => void
  intl: IntlShape
}

export const ShareProfileDialog: FC<Props> = ({
  supplierName,
  logoUrl,
  description,
  open,
  fullScreen,
  handleShareSupplier,
  matchMobile,
  buyerDomains,
  intl,
  ...restProps
}) => {
  const [userToMessage, setUserToMessage] = useState<Array<string>>([])
  const [message, setMessage] = useState<string>(
    intl.formatMessage(messages.share)
  )

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    handleShareSupplier({
      userToMessage,
      message
    })
  }

  const handleClickUser = (userIds: Array<string>) => {
    setUserToMessage([...userIds])
  }

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      fullWidth
      disableBackdropClick
      {...restProps}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <FormattedMessage
            id='SupplierProfileHeader.ShareACompany'
            defaultMessage='Share a company'
          />
        </DialogTitle>
        <DialogContent>
          <div className='bg-near-white pa3-5 bb bt b--light-gray mb3'>
            <div className='bg-white pb3 ph3 ba b--light-gray'>
              <Label>
                <FormattedMessage
                  id='SupplierProfileHeader.WhoDoYouWantToShareWith'
                  defaultMessage='Who do you want to share with?'
                />
              </Label>
              <FormattedMessage
                id='SupplierProfileHeader.WhoDoYouWantToShareWith'
                defaultMessage='Who do you want to share with?'
              >
                {message => (
                  <UserLookupContainer
                    isMulti
                    placeholder={message}
                    onClickUser={handleClickUser}
                    domains={buyerDomains}
                  />
                )}
              </FormattedMessage>

              <div className='mb4'>
                <Label>
                  <FormattedMessage
                    id='SupplierProfileHeader.Message'
                    defaultMessage='Message'
                  />
                </Label>
                <TextArea
                  value={message}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    setMessage(e.currentTarget.value)
                  }
                  maxLength={150}
                />
              </div>

              <SupplierMessageSummary
                name={supplierName}
                logoUrl={logoUrl}
                description={description}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className='fixed static-ns bottom-1'>
          <Button
            size={matchMobile ? 'large' : undefined}
            label={
              <FormattedMessage
                id='SupplierProfileHeader.Send'
                defaultMessage='Send'
              />
            }
            type='submit'
            disabled={!userToMessage || userToMessage.length === 0}
          />
          <Button
            size={matchMobile ? 'large' : undefined}
            label={<FormattedMessage id='CancelButton' />}
            onClick={restProps.onClose}
            secondary
            autoSize
          />
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default injectIntl(ShareProfileDialog)
