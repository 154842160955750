import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import posts from 'shared/utils/api/posts'

export function* deleteCommentSaga(action) {
  const { postId, index } = action.payload
  yield call(posts.deleteComment, { postId, index })
  return yield { postId, index }
}

export default createApiRequestSaga(deleteCommentSaga)
