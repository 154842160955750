import React, { useState, ChangeEvent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import Table from 'shared/components/Table'
import Paper from 'shared/components/Paper'
import { Column, TableCellProps } from 'react-virtualized'
import insightsSelectors from '../../store/insightsSelectors'
import { Tier2Item } from '../../store/insightsReducer'
import { useIntl, defineMessages } from 'react-intl'
import { List, RecordOf } from 'immutable'
import dateFormat from 'shared/utils/dateFormat'
import translateCertificationType from 'shared/utils/translateCertificationType'
import paths from '../../../SupplierProfile/routes'
import parsePath from 'shared/utils/parsePath'
import Link from 'shared/components/Link'
import Text from 'shared/components/Text'
import exportData from 'shared/utils/exportData'
import RootState from 'shared/models/RootState'
import numberFormat from 'shared/utils/numberFormat'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import DropdownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'

const messages = defineMessages({
  suppliersAllocation: {
    id: 'SupplierAllocation.SuppliersAllocation',
    defaultMessage: 'Suppliers Allocation'
  },
  findSupplier: {
    id: 'SupplierAllocation.FindSupplier',
    defaultMessage: 'Find Supplier'
  },
  export: {
    id: 'ExportSpend',
    defaultMessage: 'Export Spend'
  },
  supplier: {
    id: 'SupplierAllocation.Supplier',
    defaultMessage: 'Supplier'
  },
  tier2Type: {
    id: 'SupplierAllocation.Tier2Type',
    defaultMessage: 'Tier 2 Type'
  },
  spend: {
    id: 'SupplierAllocation.Spend',
    defaultMessage: 'Spend'
  },
  lastUpdated: {
    id: 'SupplierAllocation.LastUpdated',
    defaultMessage: 'Last Updated'
  },
  exportUniqueSpend: {
    id: 'SupplierAllocation.ExportUniqueSpend',
    defaultMessage: 'Export Unique Spend'
  },
  exportingUniqueSpend: {
    id: 'SupplierAllocation.ExportingUniqueSpend',
    defaultMessage: 'Exporting unique spend....'
  }
})

type Props = {
  range: string
}

const SupplierAllocation = (props: Props) => {
  const intl = useIntl()
  const isLoading = useSelector(insightsSelectors.isLoadingTier2Data)
  const data: List<RecordOf<Tier2Item>> = useSelector((state: RootState) =>
    insightsSelectors.getSupplierAllocation(state, props.range)
  )
  const baseRules = useSelector((state: RootState) =>
    state.getIn([
      'buyer',
      'diversityReport',
      'diverseQualificationRules',
      'baseRules'
    ])
  )
  const subCategories = baseRules && baseRules.get('subCategories').toJS()
  const certAgencies = baseRules && baseRules.get('certAgencies').toJS()
  const countries = baseRules && baseRules.get('countries').toJS()
  const startYear = props.range.slice(0, 4)
  const startQuarter = props.range.slice(4, 5)
  const endYear = props.range.slice(5, 9)
  const endQuarter = props.range.slice(9)

  const [findSupplier, setFindSupplier] = useState<string>('')
  const [exportingUniqueSpend, setExportingUniqueSpend] = useState<boolean>(
    false
  )

  const filteredData = useMemo(() => {
    if (!data) {
      return List()
    }
    return data.filter(item => {
      return (
        !findSupplier ||
        item
          .getIn(['supplier', 'name'])
          .toUpperCase()
          .includes(findSupplier.toUpperCase())
      )
    })
  }, [findSupplier, data])

  const renderSupplierCell = ({
    cellData,
    rowData,
    dataKey
  }: TableCellProps) => {
    return (
      <>
        <Link
          to={parsePath(paths.basePath, { supplierId: cellData.get('id') })}
        >
          {cellData.get('name')}
        </Link>
        <Text secondary>{cellData.get('domains').first()}</Text>
      </>
    )
  }

  const renderSubCategoryCell = ({ cellData }: TableCellProps) => {
    const acronym = translateCertificationType({
      intl,
      categoryType: 'diversity',
      subCategoryType: cellData,
      useAcronym: true
    })
    const title = translateCertificationType({
      intl,
      categoryType: 'diversity',
      subCategoryType: cellData
    })

    return `${acronym} (${title})`
  }

  const exportTier2Data = () => {
    exportData.exportCSV(
      filteredData
        .map(row => {
          return {
            supplierName: row.getIn(['supplier', 'name']),
            domains: row.getIn(['supplier', 'domains']),
            tier2Type: translateCertificationType({
              intl,
              categoryType: 'diversity',
              subCategoryType: row.get('tier2Type'),
              useAcronym: true
            }),
            spend: row.get('spend'),
            lastUpdated: dateFormat(row.get('lastUpdate'), undefined, true)
          }
        })
        .toJS(),
      'tier2-data'
    )
  }
  const exportUniqueSpend = ({
    startYear,
    startQuarter,
    endYear,
    endQuarter,
    subCategories,
    certAgencies,
    countries
  }) => {
    setExportingUniqueSpend(true)
    return api
      .post(`${apiRoutes.authService}/tier2/exportUniqueSpend`, {
        startYear,
        startQuarter,
        endYear,
        endQuarter,
        subCategories,
        certAgencies,
        countries
      })
      .then(response => {
        let data = response
          .sort((supA, supB) => {
            if (supA.supplier.name > supB.supplier.name) {
              return 1
            }
            if (supA.supplier.name < supB.supplier.name) {
              return -1
            }
            if (supA.year > supB.year) {
              return 1
            }
            if (supA.year < supB.year) {
              return -1
            }
            if (supA.quarter > supB.quarter) {
              return 1
            }
            if (supA.quarter < supB.quarter) {
              return -1
            } else {
              return 0
            }
          })
          .map((item, index) => {
            return {
              'Supplier name': item.supplier.name,
              'Tier 2 Supplier': index + 1,
              'Aggregate Input': item.aggregateInput ? 'Y' : 'N',
              'Tier 2 Spend': numberFormat(item.spend),
              'TealBook Certifications': item.certs,
              Year: item.year,
              Quarter: item.quarter
            }
          })
        exportData.exportCSV(data, 'Tier 2 Unique Spend')
        setExportingUniqueSpend(false)
        return response
      })
      .catch(err => {
        return err
      })
  }
  return !isLoading && !!data?.size ? (
    <section>
      <Label>{intl.formatMessage(messages.suppliersAllocation)}</Label>
      <div className='flex justify-between items-center mv2'>
        <Input
          wrapperClassName='w5'
          value={findSupplier}
          placeholder={intl.formatMessage(messages.findSupplier)}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFindSupplier(e.currentTarget.value)
          }
        />

        {filteredData.size > 0 && (
          <DropdownMenu>
            <MenuItem onClick={exportTier2Data}>
              {intl.formatMessage(messages.export)}
            </MenuItem>
            {exportingUniqueSpend ? (
              <MenuItem disabled={true} className='mb2'>
                {intl.formatMessage(messages.exportingUniqueSpend)}
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() =>
                  exportUniqueSpend({
                    startYear,
                    startQuarter,
                    endYear,
                    endQuarter,
                    subCategories,
                    certAgencies,
                    countries
                  })
                }
              >
                {intl.formatMessage(messages.exportUniqueSpend)}
              </MenuItem>
            )}
          </DropdownMenu>
        )}
      </div>

      <Paper noPadding>
        <Table
          rowGetter={({ index }) => filteredData.get(index)}
          rowCount={filteredData.size}
        >
          <Column
            label={intl.formatMessage(messages.supplier)}
            dataKey='supplier'
            width={250}
            cellRenderer={renderSupplierCell}
          />
          <Column
            label={intl.formatMessage(messages.tier2Type)}
            dataKey='tier2Type'
            flexGrow={1}
            width={150}
            cellRenderer={renderSubCategoryCell}
          />
          <Column
            label={intl.formatMessage(messages.spend)}
            dataKey='spend'
            width={150}
            headerClassName='tr'
            cellRenderer={({ cellData }: TableCellProps) => (
              <div className='tr'>
                {cellData && `$${numberFormat(cellData, 2)}`}
              </div>
            )}
          />
          <Column
            label={intl.formatMessage(messages.lastUpdated)}
            dataKey='lastUpdate'
            width={150}
            headerClassName='tr'
            cellRenderer={({ cellData }: TableCellProps) => (
              <div className='tr'>{dateFormat(cellData)}</div>
            )}
          />
        </Table>
      </Paper>
    </section>
  ) : null
}

export default SupplierAllocation
