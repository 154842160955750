import { call, select, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import community from 'shared/utils/api/community'
import communitySelectors from '../../selectors/communitiesSelectors'
import { notify } from 'shared/actions'

export function* batchInviteToTier2Saga(action) {
  const { selectedIds, tier2CommId } = action.payload

  const communitiesById = yield select(communitySelectors.getCommunities)
  const tier2Community =
    communitiesById &&
    communitiesById.filter((community, id) => community.get('tier2'))

  if (tier2Community.size === 0) {
    return yield put(
      notify({
        message: `Please Enable Tier 2 in the Insight tab, Tier 2 page`
      })
    )
  } else {
    return yield call(community.batchInviteToTier2, {
      comId: tier2CommId,
      supplierIds: selectedIds
    })
  }
}

export default createApiRequestSaga(batchInviteToTier2Saga)
