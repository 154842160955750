export const getSelectedUser = state =>
  state.getIn(['supplier', 'newContact', 'selectedUser'])

export const isCreatingNewUser = state => {
  return !state.getIn(['supplier', 'newContact', 'selectedUser', 'id'])
}

export const isAddingContact = state => {
  return state.getIn(['supplier', 'newContact', 'isAddingContact'])
}
