import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import community from 'shared/utils/api/community'

export function* getCommunitySaga(action) {
  const comId = action.payload

  return yield call(community.getClientCommunity, comId)
}

export default createApiRequestSaga(getCommunitySaga)
