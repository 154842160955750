import React from 'react'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import { AllocatedSpendState } from '../../store/tier2Reducer'
import SpendAllocation, { SpendAllocationProps } from './SpendAllocation'
import { OrgUnit } from 'shared/containers/OrgUnit/OrgUnit'

type Props = {
  allocatedSpend: AllocatedSpendState
  spendByQuarter: Array<{
    year: number
    quarter: number
    totalDiverseSpend: number
  }>
} & Pick<SpendAllocationProps, 'onShareSpendAllocation'>

const ShareTier2 = ({
  allocatedSpend,
  spendByQuarter,
  onShareSpendAllocation
}: Props) => (
  <>
    <div className='mb2'>
      <Label>
        <FormattedMessage
          id='ShareTier2.AllocatingShare'
          defaultMessage='Allocate diversity spend to customers'
        />
      </Label>
    </div>
    <Text>
      <FormattedMessage
        id='ShareTier2.ShareExplanation'
        defaultMessage='We will only share your spend information if you specifically select the customer to share it with. If you choose to share, your customer will be able to see a summary of the diverse spend per quarter you allocated to them grouped by diversity category. We do not share information about your suppliers with your customers.'
      />
    </Text>
    {allocatedSpend.data?.map(item => (
      <div className='ph3 pb3 b--light-gray ba mt3' key={item.buyerOrgUnitId}>
        <OrgUnit id={item.buyerOrgUnitId} name={item.buyerName} />
        {spendByQuarter.map(spend => {
          const allocatedSpendYear = item.allocatedSpend[spend.year]
          const allocatedSpendQuarter =
            allocatedSpendYear && allocatedSpendYear[spend.quarter]
          return (
            <SpendAllocation
              key={`${spend.year}${spend.quarter}`}
              {...spend}
              {...allocatedSpendQuarter}
              communityPlanMembershipId={item.communityPlanMembershipId}
              onShareSpendAllocation={onShareSpendAllocation}
            />
          )
        })}
      </div>
    ))}
  </>
)

export default ShareTier2
