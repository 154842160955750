/**
 * API base url to be used when making request to that service
 */

type ApiRoutes = {
  authService: string
  loader: string
  inProduction: boolean
}

// routes configuration
let apiRoutes: ApiRoutes = {
  authService: 'https://dev.tealbook.com/api/auth',
  loader: 'https://dev.tealbook.com/api/loader',
  inProduction: false
}

// if (process.env.NODE_ENV === 'development') {
//   console.log('connected to local auth and loader service')
//   apiRoutes = Object.assign({}, apiRoutes, {
//     authService: 'http://localhost:3001/api/auth',
//     loader: 'http://localhost:3002/api/loader'
//   })
// }

// production overrides for routes configuration
if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_ENV === 'prd') {
    apiRoutes = Object.assign({}, apiRoutes, {
      authService: 'https://app.tealbook.com/api/auth',
      loader: 'https://app.tealbook.com/api/loader',
      inProduction: true
    })
  } else {
    if (
      process.env.REACT_APP_ENV &&
      process.env.REACT_APP_ENV.startsWith('pr-')
    ) {
      apiRoutes = Object.assign({}, apiRoutes, {
        authService: `https://${process.env.REACT_APP_ENV}.tealbook.com/api/auth-${process.env.REACT_APP_ENV}`,
        loader: `https://${process.env.REACT_APP_ENV}.tealbook.com/api/loader-${process.env.REACT_APP_ENV}`,
        inProduction: false
      })
    } else {
      apiRoutes = Object.assign({}, apiRoutes, {
        authService: `https://${process.env.REACT_APP_ENV}.tealbook.com/api/auth`,
        loader: `https://${process.env.REACT_APP_ENV}.tealbook.com/api/loader`,
        inProduction: false
      })
    }
  }
}

export default apiRoutes
