import React, { Component } from 'react'
import CommentButton from 'shared/components/CommentButton'
import LikeButton from 'shared/components/LikeButton'
import PostCommentsListContainer from '../../containers/PostCommentsListContainer'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'

const styles = {
  wrapper: 'ba b--black-10 mb3 br--bottom',
  actionsWrapper: 'bg-white ph3-5 pv2-5'
}

type Props = {
  id: string
  liked: boolean
  numComments: number
  numLikes: number
  numReads: number
  numViews: number
  hasRead?: boolean
  likeAsRead: boolean
  postId: string
  onUpVote: (id: string, { markRead: boolean }) => void
  onClearVote: (arg: string) => void
  onPostRead?: (id: string) => void
}
class PostItemActions extends Component<Props> {
  state = {
    showComments: false
  }

  handleLikeClick = () => {
    const { onUpVote, onClearVote, liked, id, hasRead, likeAsRead } = this.props
    if (liked) {
      onClearVote(id)
    } else {
      onUpVote(id, { markRead: likeAsRead && !hasRead })
    }
  }

  handleCommentsClick = () => {
    const { onPostRead, id } = this.props
    if (onPostRead) {
      onPostRead(id)
    }
    this.setState({
      showComments: !this.state.showComments
    })
  }

  render() {
    const {
      liked,
      postId,
      numComments,
      numLikes,
      numReads,
      numViews
    } = this.props
    const { showComments } = this.state

    return (
      <div className={styles.wrapper}>
        <div className={styles.actionsWrapper}>
          <LikeButton
            noCountLabel={
              !numLikes ? (
                <FormattedMessage
                  id='PostItemActions.LikeButton'
                  defaultMessage='Like'
                />
              ) : (
                <FormattedMessage
                  id='PostItemActions.LikesCount'
                  defaultMessage={`{ numLikesValue, plural, one { {numLikesValue} Like} other { {numLikesValue} Likes} }`}
                  values={{ numLikesValue: numLikes }}
                />
              )
            }
            noMargin
            liked={liked}
            onClick={this.handleLikeClick}
          />
          <CommentButton
            noCountLabel={
              !numComments ? (
                <FormattedMessage
                  id='PostItemActions.CommentButton'
                  defaultMessage='Comment'
                />
              ) : (
                <FormattedMessage
                  id='PostItemActions.CommentsCount'
                  defaultMessage={`{ numCommentsValue, plural, one { {numCommentsValue} Comment} other { {numCommentsValue} Comments} }`}
                  values={{ numCommentsValue: numComments }}
                />
              )
            }
            noMargin
            commented={showComments}
            onClick={this.handleCommentsClick}
          />

          {!!numViews && (
            <Text className='gray mr4 dib'>
              <FormattedMessage
                id='PostItemActions.ViewsCount'
                defaultMessage={
                  '{ numViews, plural, one { {numViews} View} other { {numViews} Views} }'
                }
                values={{
                  numViews:
                    numReads && numReads > numViews ? numReads : numViews
                }}
              />
            </Text>
          )}

          {!!numReads && (
            <Text className='gray dib'>
              <FormattedMessage
                id='PostItemActions.ReadsCount'
                defaultMessage={
                  '{ numReads, plural, one { {numReads} Read} other { {numReads} Reads} }'
                }
                values={{ numReads }}
              />
            </Text>
          )}
        </div>
        {showComments && <PostCommentsListContainer postId={postId} />}
      </div>
    )
  }
}

export default PostItemActions
