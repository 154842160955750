import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import jobsSelectors from '../../selectors/jobsSelectors'
import RootState from 'shared/models/RootState'
import Button from 'shared/components/Button'
import Pill from 'shared/components/Pill'
import { updateJobStatus } from '../../actions'
import qs from 'qs'
import startCase from 'lodash.startcase'
import { Prompt } from 'react-router-dom'

const searchStringToLabels = queryString => {
  const { q, filter } = qs.parse(queryString, { ignoreQueryPrefix: true })
  const filters = q && filter && { q, ...filter }
  const naicsKeys = ['sector', 'subsector', 'group']

  let pills: Array<string | Array<string>> = []
  for (const property in filters) {
    // assuming not filtering NAICS OR
    const value = naicsKeys.includes(property)
      ? filters[property].map(v => `NAICS ${v}`)
      : filters[property]
    pills.push(value)
  }
  return pills?.flat(1).filter(p => !!p)
}

type CategoryPillsProps = {
  queryString: string
}

export const CategoryPills = ({ queryString }: CategoryPillsProps) => {
  const pills: Array<string | Array<string>> = searchStringToLabels(queryString)

  return (
    <>
      {pills?.map(value => {
        return typeof value === 'string' ? (
          <Pill key={value} label={startCase(value)} />
        ) : (
          <Pill key={value.join('')} label={`Any of ${value?.join(', ')}`} />
        )
      })}
    </>
  )
}

type Props = {
  jobId: string
}

const SowHeader = (props: Props) => {
  const dispatch = useDispatch()
  const { jobId } = props
  const queryString = useSelector((state: RootState) => {
    const job = jobsSelectors.getJobById(state, jobId)
    return job?.get('queryString') || ''
  })

  const [complete, setComplete] = useState(false)
  const [delayPrompt, setDelayPrompt] = useState(true)

  useEffect(() => {
    setTimeout(() => setDelayPrompt(false), 2000)
  }, [])

  const handleCompleteClick = () => {
    setComplete(true)
    dispatch(
      updateJobStatus({
        jobId,
        status: 'complete'
      })
    )
  }

  return (
    <div>
      <h5 className='f5 fw6 near-black mv2'>Verify the Top 10 Suppliers</h5>
      <div className='flex justify-between'>
        <div className='flex-auto'>
          <CategoryPills queryString={queryString} />
        </div>
        <div>
          <Button autoSize onClick={handleCompleteClick}>
            Complete Task
          </Button>
        </div>
      </div>
      {!complete && (
        <Prompt
          when={!delayPrompt}
          message={`Are you sure you want to navigate away before completing the task?`}
        />
      )}
    </div>
  )
}

export default SowHeader
