import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import AutoSaveInput from 'shared/components/AutoSaveInput'
import TextArea from 'shared/components/TextArea'
import FileInput from 'shared/components/FileInput'
import Label from 'shared/components/Label'
import companySelectors from '../../selectors/companySelectors'
import { saveCompanyInfo } from '../../../Company/actions'

type Props = {
  saveCompanyInfo: () => void
  longDescription: string
  pitchDeckFileUrl: string
}

export class AboutUsForm extends Component<Props> {
  render() {
    const { saveCompanyInfo, longDescription, pitchDeckFileUrl } = this.props

    return (
      <Fragment>
        {pitchDeckFileUrl && (
          <div className='tc nr4 mt2'>
            <iframe
              title='Pitch Deck'
              src={`https://docs.google.com/gview?url=${pitchDeckFileUrl}&embedded=true`}
              className='b--none br1 w-75'
              style={{ height: '100px' }}
            />
          </div>
        )}
        <Label>Pitch Deck</Label>
        <FileInput
          name='pitchDeckFile'
          component={FileInput}
          accept='.pdf,.pptx,.ppt'
          autoSave
          onSave={saveCompanyInfo}
        />
        <Label>Company Description</Label>
        <AutoSaveInput
          name='longDescription'
          value={longDescription}
          component={TextArea}
          maxLength={1000}
          onSave={saveCompanyInfo}
        />
      </Fragment>
    )
  }
}

export default connect(
  state => ({
    longDescription: companySelectors.getCompanyField(state, 'longDescription'),
    pitchDeckFileUrl: companySelectors.getPitchDeck(state)
  }),
  { saveCompanyInfo }
)(AboutUsForm)
