import { select, call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import profileSelectors from '../../selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import supplierRelationships from 'shared/utils/api/supplierRelationships'
import supplierRelationshipsSelectors from '../../../shared/selectors/supplierRelationshipsSelectors'

export function* addCertificationValidationSaga(action) {
  let {
    diversityReport,
    supplierRelationshipId,
    supplierId,
    ...certificationValidation
  } = action.payload

  const userId = yield select(sessionSelectors.getUserId)
  const orgUnitId = yield select(sessionSelectors.getOrgUnitId)

  if (!diversityReport && !supplierRelationshipId) {
    const supplierRelationship = yield select(
      supplierRelationshipsSelectors.getSupplierRelationshipForCurrentSupplier
    )
    supplierRelationshipId =
      supplierRelationship && supplierRelationship.get('id')
  }

  // create a relationship if we need to
  let isNewRelationship
  let response
  if (!supplierRelationshipId) {
    isNewRelationship = true
    if (!supplierId) {
      supplierId = yield select(profileSelectors.getDataField, 'id')
    }
    response = yield call(supplierRelationships.createByBuyer, {
      supplierId,
      supplierRelationship: {
        buyer: orgUnitId,
        buyerCollaboration: {
          certificationValidation: []
        }
      }
    })
    supplierRelationshipId = response.id
  }

  if (supplierRelationshipId) {
    response = yield call(supplierRelationships.addCertificationValidation, {
      supplierRelationshipId,
      userId,
      ...certificationValidation
    })
  }

  return yield {
    isNewRelationship,
    certificationValidation,
    supplierRelationship: response
  }
}

export default createApiRequestSaga(addCertificationValidationSaga)
