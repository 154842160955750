import React, { useState, useEffect, useMemo } from 'react'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import SelectConnectionRole from '../SelectConnectionRole'
import Button from 'shared/components/Button'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import { FormattedMessage } from 'react-intl'
import camelCase from 'lodash.camelcase'
import { useIntl } from 'react-intl'
import { messages } from '../SelectConnectionRole/SelectConnectionRole'
import { useSelector } from 'react-redux'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import RootState from 'shared/models/RootState'

type Props = {
  // card props
  userId: string
  cardId: string
  contactFor: string

  canEditRole: boolean
  onDone: Function
}

const ConnectionItem = (props: Props) => {
  const [editingContactFor, setEditingContactFor] = useState<boolean>(false)
  const [contactFor, setContactFor] = useState<string>(props.contactFor)

  const intl = useIntl()
  const followerRoles = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'followerRoles')
  )

  useEffect(() => {
    setContactFor(props.contactFor)
  }, [props.contactFor])

  const handleClickEdit = () => {
    setEditingContactFor(true)
  }

  const handleClickDone = () => {
    const { onDone, cardId } = props
    setEditingContactFor(false)

    onDone({
      id: cardId,
      contactFor
    })
  }

  const handleConnectionRoleChange = e => {
    setContactFor(e.target.value)
  }

  const { userId, canEditRole } = props

  const roleLabel = useMemo(() => {
    return (
      contactFor &&
      (!followerRoles?.get(contactFor)
        ? // handle old data without using key mapping
          messages[camelCase(contactFor)]
          ? intl.formatMessage(messages[camelCase(contactFor)])
          : contactFor
        : // handle new data using key mapping
        messages[camelCase(followerRoles.getIn([contactFor, 'label']))]
        ? intl.formatMessage(
            messages[camelCase(followerRoles.getIn([contactFor, 'label']))]
          )
        : followerRoles.getIn([contactFor, 'label']))
    )
  }, [contactFor, followerRoles, intl])

  return (
    <UserListItemContainer
      userId={userId}
      profileUrl={parsePath(paths.userprofile, { userId })}
      secondaryText={
        editingContactFor ? (
          <div>
            <SelectConnectionRole
              value={camelCase(contactFor)}
              onChange={handleConnectionRoleChange}
            />
            <Button
              onClick={handleClickDone}
              label={
                <FormattedMessage
                  id='ConnectionItem.Done'
                  defaultMessage='Done'
                />
              }
              autoSize
              className='mt2'
            />
          </div>
        ) : (
          roleLabel || (canEditRole && 'Add your role')
        )
      }
      rightContent={
        canEditRole &&
        !editingContactFor && (
          <Button
            size='small'
            label={
              <FormattedMessage
                id='ConnectionItem.Edit'
                defaultMessage='Edit'
              />
            }
            secondary
            autoSize
            onClick={handleClickEdit}
          />
        )
      }
      mini
    />
  )
}

export default ConnectionItem
