import React, { Component } from 'react'
import VideosEditForm from './VideosEditForm'
import BlogUrlEditForm from './BlogUrlEditForm'

class ResourcesSection extends Component {
  render() {
    return (
      <div>
        <VideosEditForm />
        <BlogUrlEditForm />
      </div>
    )
  }
}

export default ResourcesSection
