import sessionSelectors from 'shared/selectors/sessionSelectors'
import usersSelectors from '../usersSelectors'
import { createSelector } from 'reselect'
import { Map } from 'immutable'
import orgsSelectors from '../orgsSelectors'

export const getAllByKindId = createSelector(
  (state, kind, id, sortOrder = 'asc') =>
    state.getIn(['messages', `by${kind}Id`, id]),
  (state, kind, id, sortOrder = 'asc') => sortOrder,
  (messages, sortOrder) => {
    return (
      messages &&
      messages.sort((messageA, messageB) => {
        const diff =
          new Date(messageA.get('createdDate')) -
          new Date(messageB.get('createdDate'))
        return sortOrder === 'asc' ? diff : -1 * diff
      })
    )
  }
)

export const getMineByKindId = createSelector(
  getAllByKindId,
  sessionSelectors.getUserId,
  (messages, userId) => {
    return (
      messages &&
      messages.filter(message => message.get('createdBy') === userId)
    )
  }
)

export const getUserToMessage = state => state.getIn(['mess', 'userToMessage'])

export const getConversations = createSelector(
  state => state.getIn(['mess', 'topicsById']),
  topics =>
    topics
      .filter(topic => !topic.get('closed'))
      .toList()
      .sort((topicA, topicB) => {
        if (!topicA.get('lastMessageDate')) {
          return 1
        }

        if (!topicB.get('lastMessageDate')) {
          return -1
        }

        return topicB.get('lastMessageDate') - topicA.get('lastMessageDate')
      })
)

export const getTopicIdByUserId = (state, userId) => {
  return state.getIn(['mess', 'topicsByUser', userId])
}

export const getConversationWithUser = createSelector(
  state => state.getIn(['mess', 'byTopic']),
  getTopicIdByUserId,
  (messagesByTopic, topicId) => {
    return (
      topicId &&
      messagesByTopic.get(topicId) &&
      messagesByTopic
        .get(topicId)
        .sort(
          (mess1, mess2) =>
            new Date(mess1.get('createdDate')) -
            new Date(mess2.get('createdDate'))
        )
    )
  }
)

export const getNumberOfUnreadMessages = createSelector(
  getConversations,
  conversations =>
    conversations.reduce(
      (sum, convo) => sum + (convo.get('numUnreadMessages') || 0),
      0
    )
)

export const getUsersThatCanMessage = createSelector(
  usersSelectors.getUsers,
  usersSelectors.getColleagueIds,
  sessionSelectors.getUserId,
  state => state.getIn(['mess', 'userIdsToMessage']),
  orgsSelectors.getOrgs,
  (allUsers, colleagueIds, currentUserId, otherUserIdsToMessage, allOrgs) => {
    return (
      colleagueIds
        .concat(otherUserIdsToMessage)

        // set the org unit name for each user
        .map(userId => {
          const user = allUsers.get(userId)
          const orgUnitName = allOrgs.getIn([user.get('orgUnitId'), 'name'])
          return user.set('orgUnitName', orgUnitName)
        })

        // remove duplicate users
        .reduce((agg, user) => agg.set(user.get('id'), user), Map({}))

        // remove blocked users
        .filter(user => !user.get('blocked'))

        // remove current user
        .delete(currentUserId)

        //sort by name
        .sort((userA, userB) => {
          return `${userA.get('firstName') || ''}${userA.get('lastName') ||
            ''}`.toLowerCase() >
            `${userB.get('firstName') || ''}${userB.get('lastName') ||
              ''}`.toLowerCase()
            ? 1
            : -1
        })

        // convert back to list
        .toList()
    )
  }
)

export const isLoadingConversation = state =>
  state.getIn(['mess', 'loadingConversation'])
