import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserCommentContainer from 'shared/containers/UserCommentContainer'
import { loadComments, removeComment } from 'shared/actions'
import messagesSelectors from 'shared/selectors/messagesSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import profileSelectors from '../../selectors/profileSelectors'
import relationshipsSelectors from '../../../shared/selectors/relationshipsSelectors'
import paths from 'buyer/routes/paths'
import parsePath from 'shared/utils/parsePath'
import PageSection from 'shared/components/PageSection'
import Button from 'shared/components/Button'
import createEditForm from 'shared/utils/createEditForm'
import { UPDATE_COMMENT } from 'shared/actionTypes'
import createAddCommentForm from 'shared/utils/createAddCommentForm'
import { ADD_SUPPLIER_COMMENT } from '../../actionTypes'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'

const MyCompanyPageNewComment = createAddCommentForm({
  formName: 'buyer/myCompanyPage/addCommentAboutSupplier',
  submitFormActionType: ADD_SUPPLIER_COMMENT
})

const PersonalPageNewComment = createAddCommentForm({
  formName: 'buyer/personalPage/addCommentAboutSupplier',
  submitFormActionType: ADD_SUPPLIER_COMMENT
})

type Props = {
  relationshipId: string
  comments: any
  currentUserId: string
  removeComment: (arg: {
    ancestorId: string
    messageId: string
    messageKind: string
  }) => void
  loadComments: (arg: { ancestorId: string; messageKind: string }) => void
} & ContainerProps

export class CommentsAboutSupplier extends Component<Props> {
  state = {
    showAll: false
  }

  formIndex = 0

  componentDidMount() {
    const { loadComments, relationshipId, comments, onlyMine } = this.props

    if (relationshipId && (!onlyMine || (onlyMine && !comments))) {
      loadComments({ ancestorId: relationshipId, messageKind: 'Relationship' })
    }
  }

  handleShowAll = () => {
    this.setState({ showAll: true })
  }

  render() {
    const {
      onlyMine,
      comments,
      relationshipId,
      currentUserId,
      removeComment
    } = this.props

    const { showAll } = this.state

    const formName = onlyMine
      ? 'buyer/personalPage/updateCommentsAboutSupplier/supplierComment'
      : 'buyer/myCompanyPage/updateCommentsAboutSupplier/supplierComment'

    return (
      <PageSection
        title={
          onlyMine ? (
            <FormattedMessage
              id='CommentsAboutSupplier.Comments'
              defaultMessage='Comments'
            />
          ) : (
            <FormattedMessage
              id='CommentsAboutSupplier.Activities'
              defaultMessage='Activities'
            />
          )
        }
        noPadding
      >
        {comments && comments.size > 5 && !showAll && (
          <div className='pa2 bb b--black-10'>
            <Button
              variant='text'
              autoSize
              label={
                <FormattedMessage
                  id='CommentsAboutSupplier.ViewMoreComments'
                  defaultMessage='View {commentsSizeValues} more comments'
                  values={{ commentsSizeValues: comments.size - 5 }}
                />
              }
              onClick={this.handleShowAll}
            />
          </div>
        )}

        {comments &&
          comments.takeLast(showAll ? comments.size : 5).map(comment => {
            const EditableUserCommentContainer =
              currentUserId === comment.get('createdBy')
                ? createEditForm(
                    {
                      formName: `${formName}${this.formIndex++}`,
                      submitActionType: UPDATE_COMMENT,
                      stopEditModeOnSave: true,
                      onRemove: () =>
                        removeComment({
                          ancestorId: relationshipId,
                          messageId: comment.get('id'),
                          messageKind: 'Relationship'
                        })
                    },
                    UserCommentContainer
                  )
                : UserCommentContainer

            return (
              <EditableUserCommentContainer
                key={comment.get('id')}
                userId={comment.get('createdBy')}
                userProfileUrl={parsePath(paths.userprofile, {
                  userId: comment.get('createdBy')
                })}
                date={comment.get('createdDate')}
                comment={comment.get('body')}
                enablePublicPrivate
                isPrivate={comment.get('isPrivate')}
                initialValues={{
                  ancestorId: relationshipId,
                  comment: comment.get('body'),
                  messageId: comment.get('id'),
                  isPrivate: comment.get('isPrivate'),
                  messageKind: 'Relationship'
                }}
              />
            )
          })}

        <div className='pa4'>
          {onlyMine && (
            <PersonalPageNewComment
              enablePublicPrivate
              initialValues={{
                ancestorId: relationshipId,
                isPrivate: true,
                messageKind: 'Relationship'
              }}
            />
          )}

          {!onlyMine && (
            <MyCompanyPageNewComment
              enablePublicPrivate
              initialValues={{
                ancestorId: relationshipId,
                isPrivate: false,
                messageKind: 'Relationship'
              }}
            />
          )}
        </div>
      </PageSection>
    )
  }
}

type ContainerProps = {
  onlyMine?: boolean
}
export default connect(
  (state: RootState, props: ContainerProps) => {
    const supplierId = profileSelectors.getDataField(state, 'id')
    const relationship = relationshipsSelectors.getBySupplier(state, supplierId)
    const relationshipId = relationship && relationship.get('id')
    const comments = props.onlyMine
      ? messagesSelectors.getMineByKindId(state, 'Relationship', relationshipId)
      : messagesSelectors.getAllByKindId(state, 'Relationship', relationshipId)
    const currentUserId = sessionSelectors.getUserId(state)

    return {
      comments,
      currentUserId,
      relationshipId
    }
  },
  {
    loadComments,
    removeComment
  }
)(CommentsAboutSupplier)
