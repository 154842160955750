import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as SpotlightLogo } from './spotlight.svg'
import { List } from 'immutable'
import SpotlightCard from './SpotlightCard'
import Loading from 'shared/components/Loading'
import { RouteComponentProps } from 'react-router'
import Link from 'shared/components/Link'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Button from 'shared/components/Button'
import Post from './Post'
import PostItemActions from '../PostItemActions'
import withPost from '../../containers/withPost'
import withPostActions from '../../containers/withPostActions'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import useSmallScreen from 'shared/utils/useSmallScreen'

const SpotlightCardContainer = withPost(SpotlightCard)
const PostContainer = withPost(Post)
const PostActionsContainer = withPostActions(PostItemActions)

type Props = {
  tag: string
  posts: List<any>
  trendingTags: List<string>
  isClientApp: boolean
  sendContactUsMessage: (params) => void
  markPostRead: (postId: string) => void
  contributorsUrl: string
} & Partial<Pick<RouteComponentProps, 'history'>>

const Spotlight = (props: Props) => {
  const [openReportDialog, setOpenReportDialog] = useState<boolean>(false)
  const [showPostId, setShowPostId] = useState<string>('')

  const handleReportPost = (params: any) => {
    props.sendContactUsMessage(params)
    setOpenReportDialog(true)
  }

  const handleOpenPost = (postId: string) => {
    setShowPostId(postId)
    props.markPostRead(postId)
  }

  const isSmallScreen = useSmallScreen()

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: props.posts && props.posts.size > 1 ? 2 : 1,
    slidesToScroll: props.posts && props.posts.size > 1 ? 2 : 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: props.posts && props.posts.size > 1 ? 2 : 1,
          slidesToScroll: props.posts && props.posts.size > 1 ? 2 : 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true
        }
      }
    ]
  }
  return props.trendingTags && props.trendingTags.size > 0 ? (
    <div className='mb3'>
      <div className='flex justify-between items-center f3 fw6'>
        <div className='flex items-center teal'>
          <FormattedMessage id='Spotlight.Title' defaultMessage='Spotlight' />
          <Link to={props.contributorsUrl}>
            <SpotlightLogo width={70} height={35} />
          </Link>
        </div>
        <div className='mid-gray'>{props.tag}</div>
      </div>
      <div
        className='mt1 mb2'
        style={{
          height: 4,
          background:
            'linear-gradient(90deg, rgba(5,188,171,1) 0%, rgba(5,188,171,1) 10%, rgba(255,215,0,1) 30%, rgba(255,215,0,1) 100%)'
        }}
      ></div>
      <div style={{ marginBottom: 30 }}>
        {props.posts ? (
          props.posts.size > 0 ? (
            <Slider {...settings}>
              {props.posts
                .take(isSmallScreen ? 12 : 36)
                .map((post, index) => (
                  <SpotlightCardContainer
                    key={`spotlight-${index}`}
                    postId={post.get('id')}
                    onClick={handleOpenPost}
                    handleReportPost={handleReportPost}
                    isClientApp={props.isClientApp}
                  />
                ))
                .toArray()}
            </Slider>
          ) : (
            <Text className='tc mt3'>
              <FormattedMessage
                id='SpotlightCard.NoPosts'
                defaultMessage='No posts were found'
              />
            </Text>
          )
        ) : (
          <Loading className='tc mv3' />
        )}
      </div>
      {props.trendingTags && props.trendingTags.size > 0 && (
        <div className='bg-white br1 ba b--black-10 pa3'>
          <Label noPadding>
            <FormattedMessage
              id='Spotlight.Trending'
              defaultMessage='Trending Hashtags'
            />
          </Label>
          <Text>
            {props.trendingTags.map(tag => (
              <Link key={tag} to={tag} className='mr2'>
                {tag}
              </Link>
            ))}
          </Text>
        </div>
      )}
      <Dialog
        onClose={() => setOpenReportDialog(false)}
        open={openReportDialog}
        fullWidth
      >
        <DialogTitle>
          <FormattedMessage id='PostItem.ReportAPostTitle' />
        </DialogTitle>
        <DialogContent className='mt3'>
          <FormattedMessage id='PostItem.ThankYouForReportingPostDialog' />
        </DialogContent>
        <DialogActions>
          <Button
            autoSize
            label={<FormattedMessage id='PostItem.DoneDialog' />}
            onClick={() => setOpenReportDialog(false)}
          />
        </DialogActions>
      </Dialog>
      <Dialog onClose={() => setShowPostId('')} open={!!showPostId} fullWidth>
        <DialogTitle onClose={() => setShowPostId('')}>{props.tag}</DialogTitle>
        <DialogContent className='mt3'>
          {!!showPostId && (
            <>
              <PostContainer
                postId={showPostId}
                isClientApp={props.isClientApp}
                handleReportPost={handleReportPost}
              />
              <PostActionsContainer postId={showPostId} />
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  ) : null
}

export default Spotlight
