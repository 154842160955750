import { Record } from 'immutable'
import { AccessControl, AccessControlRecord } from 'shared/models/AccessControl'

export type SessionRecordType = {
  statusIsKnown: boolean
  loggedIn: boolean
  userId: string
  displayCreatingOrgPage: boolean
  partnerConcierge: boolean
  accessControl: AccessControl
}

const SessionRecord = Record<SessionRecordType>({
  statusIsKnown: false,
  loggedIn: false,
  userId: '',
  displayCreatingOrgPage: false,
  partnerConcierge: false,
  accessControl: new AccessControlRecord({
    access: 'full'
  })
})

export default SessionRecord
