import React, { Component, ReactNode } from 'react'
import { NavLink as RouterNavLink } from 'react-router-dom'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'

// tachyons class names
const navLinkClassNames = 'no-underline f7 mr3 mr4-l'
const activeLinkClassNames = 'bw1 bb black fw6 pb3 nb3'

const CustomBadge = withStyles({
  badge: {
    top: -2,
    right: -2,
    width: 8,
    height: 8,
    backgroundColor: '#e00b0b'
  }
})(Badge)

type Props = {
  /* Clickable label to use as the nav link or alt text for icon nav link */
  label: string | ReactNode
  /* Clickable icon to use as the nav link */
  iconSrc?: string
  /* Number of unread notification */
  numberOfNotifications?: number
  to: string
  isActive?: (match, location) => boolean
  classes: any
}

export const iconLabelStyle = {
  icon: {
    transition: 'all .15s ease-in',
    filter:
      'brightness(0) saturate(100%) invert(32%) sepia(0%) saturate(0%) hue-rotate(261deg) brightness(95%) contrast(83%)', //#555555 for svg
    '&:focus, &:hover': {
      filter: 'brightness(0) saturate(100%)' //#000000 for svg
    }
  },
  label: {
    color: '#555555',
    transition: 'all .15s ease-in',
    '&:focus, &:hover': {
      color: '#707070'
    }
  }
}

const styles = theme => iconLabelStyle

/**
 * Just a Link object with special styling and a label prop included <br/> (note: that means you need to provide a path for the "to" prop just like a normal Link)
 */
class NavLink extends Component<Props> {
  renderLabelOrIcon() {
    const { iconSrc, label, classes } = this.props

    return iconSrc ? (
      <img
        src={iconSrc}
        alt={label as string}
        className={'v-mid ' + classes.icon}
        style={{
          width: 18,
          height: 18
          // marginTop: '-3px',
        }}
      />
    ) : (
      label
    )
  }

  render() {
    const {
      numberOfNotifications,
      iconSrc,
      label,
      classes,
      ...rest
    } = this.props
    return (
      <RouterNavLink
        {...rest}
        className={navLinkClassNames + ' ' + classes.label}
        activeClassName={activeLinkClassNames}
      >
        {numberOfNotifications && numberOfNotifications > 0 ? (
          <CustomBadge variant='dot' badgeContent={1}>
            {this.renderLabelOrIcon()}
          </CustomBadge>
        ) : (
          this.renderLabelOrIcon()
        )}
      </RouterNavLink>
    )
  }
}

export default withStyles(styles)(NavLink)
