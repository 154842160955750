import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

export const getJobs = createAction(actionTypes.GET_JOBS_LIST)
export const openJob = createAction(actionTypes.OPEN_JOB)
export const updateJobAnswers = createAction(actionTypes.UPDATE_JOB_ANSWERS)
export const loadNewJob = createAction(actionTypes.LOAD_NEW_JOB)
export const reserveJob = createAction(actionTypes.RESERVE_JOB)
export const releaseJob = createAction(actionTypes.RELEASE_JOB)
export const navToNext = createAction(actionTypes.NAV_TO_NEXT)
export const deleteJob = createAction(actionTypes.DELETE_JOB)
export const deleteJobResult = createAction(actionTypes.DELETE_JOB_RESULT)
export const bulkDeleteJobResults = createAction(
  actionTypes.BULK_DELETE_RESULTS
)
export const updateSowOrg = createAction(actionTypes.UPDATE_SOW_ORG)
export const reorderOrgsPosition = createAction(
  actionTypes.REORDER_ORGS_POSITION
)
export const updateJobStatus = createAction(actionTypes.UPDATE_JOB_STATUS)
export const rejectSowOrg = createAction(
  actionTypes.REJECT_SOW_ORG,
  (payload, meta) => payload,
  (payload, meta) => meta
)
export const lookupOrg = createAction(actionTypes.LOOKUP_ORG)
export const addOrg = createAction(actionTypes.ADD_ORG)
export const addNewOrg = createAction(actionTypes.ADD_NEW_ORG)
export const addJobRow = createAction(actionTypes.ADD_JOB_ROW)
export const selectOrgUnit = createAction(actionTypes.SELECT_ORG_UNIT)
