import { connect } from 'react-redux'
import { ADD_CONTACT_SEND_TO } from '../../actionTypes'
import inviteSupplierContactSelectors from '../../selectors/inviteSupplierContactSelectors'
import AddUserForm from 'shared/components/AddUserForm'
import { getFormValues } from 'redux-form/immutable'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import createInviteUserActions from 'shared/utils/inviteUser/createActions'
import createAddForm from 'shared/utils/createAddForm'
import currentVetSupplierSelectors from '../../selectors/currentVetSuppliersSelectors'
import { RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'

const formName = 'buyer/Vets/AddContactSendContainer'
const formValueSelector = getFormValues(formName)
const { cancelInvitingUser } = createInviteUserActions(
  'inviteVetSupplierContact'
)

type FormValueProps = RecordOf<{
  user: { email: string }
}>
export default connect(
  (state: RootState) => {
    const newUser = inviteSupplierContactSelectors.getNewUser(state)
    const meta = inviteSupplierContactSelectors.getMeta(state)
    const orgUnitId = meta.get('orgUnitId')
    const formValues = formValueSelector(state) as FormValueProps
    const domains =
      orgUnitId && orgsSelectors.getById(state, orgUnitId).get('domains')
    const vetBuyerSupplierId =
      meta.get('vetBuyerSupplierId') ||
      (orgUnitId &&
        currentVetSupplierSelectors
          .getVetSupplierBySupplierId(state, orgUnitId)
          .get('id'))

    return {
      caption: 'Add Contact',
      email: formValues && formValues.getIn(['user', 'email']),
      enablePublicPrivate: false,
      domains,
      addingContact: inviteSupplierContactSelectors.isAddingContact(state),
      creatingNewUser: true,
      hideLookup: true,
      addingMyself: true,
      initialValues: {
        user: newUser,
        orgUnitId,
        vetBuyerSupplierId
      }
    }
  },
  {
    onClickCancel: cancelInvitingUser
  }
)(
  createAddForm(
    {
      form: formName,
      submitActionType: ADD_CONTACT_SEND_TO
    },
    AddUserForm
  )
)
