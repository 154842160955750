import React, { useEffect } from 'react'
import settingsSelectors from '../../../buyer/shared/selectors/settingsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import FileUpload from '../../components/FileUpload'
import FilesHistory from '../../components/FilesHistory'
import { connect } from 'react-redux'
import { uploadClientFile } from '../../actions'
import { loadOrgUnitClientFiles } from '../../../shared/actions'
import dataSelectors from '../../../shared/selectors/dataSelectors'
import RootState from 'shared/models/RootState'
import Main from 'shared/components/Layout/Main'
import { List, Map } from 'immutable'
import Loading from 'shared/components/Loading'

type Props = {
  buyerType: string
  isBuyDataAdmin: boolean
  isUploading: boolean
  orgUnitClientFiles: List<Map<string, string>>
  uploadClientFile: (file: File) => void
  loadOrgUnitClientFiles: typeof loadOrgUnitClientFiles
}

const FileUploadContainer = ({
  buyerType,
  isBuyDataAdmin,
  isUploading,
  orgUnitClientFiles,
  uploadClientFile,
  loadOrgUnitClientFiles
}: Props) => {
  useEffect(() => {
    loadOrgUnitClientFiles({
      entity: 'orgUnitClientFiles',
      query: 'loader/orgUnitClientFiles'
    })
  }, [loadOrgUnitClientFiles])

  return (
    <div className='mt4'>
      <Main>
        <FileUpload
          buyerType={buyerType}
          isBuyDataAdmin={isBuyDataAdmin}
          isUploading={isUploading}
          uploadClientFile={uploadClientFile}
        />
        {orgUnitClientFiles ? (
          <FilesHistory orgUnitClientFiles={orgUnitClientFiles} />
        ) : (
          <Loading />
        )}
      </Main>
    </div>
  )
}
export default connect(
  (state: RootState) => {
    return {
      buyerType: settingsSelectors.getBuyerType(state),
      isBuyDataAdmin: sessionSelectors.userHasRole(state, 'buyerDataAdmin'),
      isUploading: state.getIn(['admin', 'upload', 'isUploading']),
      orgUnitClientFiles: dataSelectors.getOrgUnitClientFiles(state)
    }
  },
  {
    uploadClientFile,
    loadOrgUnitClientFiles
  }
)(FileUploadContainer)
