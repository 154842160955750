import { call, put, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import apiRoutes from 'shared/routes/apiRoutes'
import api from 'shared/utils/api'
import { notify, updateFileHistory } from 'shared/actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import moment from 'moment'

export function* uploadClientFileSaga(action) {
  const file = action.payload
  const serverEndpoint = `${apiRoutes.loader}/loader/signedUrl`
  const uploadLink = yield call(api.get, serverEndpoint, {
    filename: file.name
  })
  const response = yield call(window.fetch, uploadLink.url, {
    body: file,
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    method: 'PUT'
  })
  if (response.ok === true) {
    yield put(
      notify({
        message: 'File Uploaded Successfully'
      })
    )
    // update file history
    const userId = yield select(sessionSelectors.getUserId)
    const linkedOrgUnitId = yield select(sessionSelectors.getOrgUnitId)
    const user = yield select(usersSelectors.getById, userId)
    let uploadLinkElems = uploadLink.url.split('/')
    let fileNameElems = uploadLinkElems[4].split('-')
    let fileIdentifier = fileNameElems[0]
    let timestamp = parseInt(fileIdentifier)
    let fullFileName = [fileIdentifier, userId, file.name].join('-')
    let date = moment(timestamp).format('YYYY-MM-DD')
    let email = user.get('email') || ''
    let uploadedFile = {
      date,
      userId,
      fileName: file.name,
      fullFileName,
      linkedOrgUnitId,
      email
    }
    yield put(updateFileHistory({ uploadedFile }))
    //upload client files to tealbook drive
    yield call(api.post, `${apiRoutes.loader}/loader/uploadClientFileToDrive`, {
      uploadedFile: uploadedFile
    })
  } else {
    yield put(
      notify({
        message: 'File Upload Failed'
      })
    )
  }
  return yield { ok: response.ok }
}

export default createApiRequestSaga(uploadClientFileSaga)
