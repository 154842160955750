import { call, put, select } from 'redux-saga/effects'
import job from 'shared/utils/api/job'
import { push } from 'connected-react-router'
import paths from 'buyer/routes/paths'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import jobsSelectors from '../selectors/jobsSelectors'
import Job from 'shared/models/Job'
import { RecordOf } from 'immutable'
import searchSelectors from 'buyer/Search/selectors/searchSelectors'

export function* updateJobStatusSaga(action) {
  const jobRecord: RecordOf<Job> = yield select(
    jobsSelectors.getJobById,
    action.payload.jobId
  )
  const response = yield call(job.updateJobStatus, action.payload)

  if (jobRecord?.get('type') === 'hil') {
    const queryString = yield select(
      searchSelectors.getQueryStringByRef,
      'buyerSearch'
    )
    yield put(push(`${paths.search}${queryString || ''}`))
  }

  return yield response
}

export default createApiRequestSaga(updateJobStatusSaga)
