import { connect } from 'react-redux'
import DiversityReportSummary from 'shared/components/DiversityReportSummary'
import {
  getOverviewSpendByType,
  getOverviewTotals
} from '../../store/diversityReportSelectors'
import insightsSelectors from '../../store/insightsSelectors'
import RootState from 'shared/models/RootState'

export default connect((state: RootState) => {
  const totalSpend = insightsSelectors.getSelectedSpend(state)
  const overview = getOverviewSpendByType(state)
  const spendItems = overview.spendItems
  const mbe =
    spendItems && spendItems.find(item => item.get('subCategory') === 'mbe')
  const sbe =
    spendItems && spendItems.find(item => item.get('subCategory') === 'sbe')
  const wbe =
    spendItems && spendItems.find(item => item.get('subCategory') === 'wbe')
  const vbe =
    spendItems && spendItems.find(item => item.get('subCategory') === 'vbe')
  const totals = getOverviewTotals(state)

  const qualifiedSpend = totals.qualified?.amount || 0
  const potentialSpend = totals.potential?.amount || 0
  const disqualifiedSpend = totals.disqualified?.amount || 0
  const qualifiedCount = totals.qualified?.count || 0
  const potentialCount = totals.potential?.count || 0
  const disqualifiedCount = totals.disqualified?.count || 0

  return {
    totalSpend,
    qualifiedSpend,
    potentialSpend,
    disqualifiedSpend,
    qualifiedCount,
    potentialCount,
    disqualifiedCount,
    mbeSpend: mbe && mbe.get('qualifiedAmount'),
    sbeSpend: sbe && sbe.get('qualifiedAmount'),
    wbeSpend: wbe && wbe.get('qualifiedAmount'),
    vbeSpend: vbe && vbe.get('qualifiedAmount'),
    mbeCount: mbe && mbe.get('qualifiedCount'),
    sbeCount: sbe && sbe.get('qualifiedCount'),
    wbeCount: wbe && wbe.get('qualifiedCount'),
    vbeCount: vbe && vbe.get('qualifiedCount')
  }
})(DiversityReportSummary)
