const basePath = '/client'

const paths = {
  basePath,
  tealboard: `${basePath}/tealboard`,
  people: `${basePath}/people`,
  vets: `${basePath}/vets`,
  communities: `${basePath}/communities`,
  search: `${basePath}/search`,
  messages: `${basePath}/messages`,
  messageTopic: `${basePath}/messages/:messageTopicId`,
  insight: `${basePath}/insight`,
  userprofile: `${basePath}/users/:userId`,
  editUserProfile: `${basePath}/users/:userId/edit`,
  supplierProfile: `${basePath}/suppliers/:supplierId`,
  supplierProfilePersonal: `${basePath}/suppliers/:supplierId/personal`,
  contributors: `${basePath}/contributors`,
  supplierEsgSurvey: `${basePath}/suppliers/:supplierId/esg-survey`,
  supplierHealthSafety: `${basePath}/suppliers/:supplierId/esg-survey/health-safety`,
  supplierEnvironment: `${basePath}/suppliers/:supplierId/esg-survey/environment`,
  supplierDiversityInclusion: `${basePath}/suppliers/:supplierId/esg-survey/diversity-inclusion`,
  supplierGovernance: `${basePath}/suppliers/:supplierId/esg-survey/governance`,
  supplierPrivacy: `${basePath}/suppliers/:supplierId/esg-survey/privacy`
}

export default paths
