import React, { Component } from 'react'
import editModeForm from '../editModeForm'
import EditForm from '../../components/EditForm'

const createEditFormComponent = (options, WrappedComponent = EditForm) => {
  class EditFormContainer extends Component {
    handleCancel = () => {
      this.props.stopEditMode()
      if (this.props.onCancel) {
        this.props.onCancel()
      }
    }

    handleEdit = () => {
      this.props.startEditMode()
      if (this.props.onEdit) {
        this.props.onEdit()
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onCancel={this.handleCancel}
          onEdit={this.handleEdit}
        />
      )
    }
  }

  return editModeForm({
    ...options,
    stopEditModeOnSave: true
  })(EditFormContainer)
}

export default createEditFormComponent
