const numberFormat = (number, decimal = 0) => {
  return typeof number === 'number'
    ? number.toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      })
    : NaN
}

export default numberFormat
