import React, { Component } from 'react'
import { fromJS } from 'immutable'
import { connect } from 'react-redux'
import ReviewsSection from '../../components/ReviewsSection'
import editModeForm from 'shared/utils/editModeForm'
import profileSelectors from '../../selectors/profileSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { UPDATE_CARD } from '../../actionTypes'
import { deleteReview } from '../../actions'
import { getFormValues } from 'redux-form/immutable'
import RootState from 'shared/models/RootState'
import { RecordOf } from 'immutable'

const today = new Date()

type Props = {
  onDeleteReview: () => void
  startEditMode: () => void
  stopEditMode: () => void
  onSubmit: () => void
  onlyMine?: boolean
  isTealbot: boolean
  editMode: boolean
  hasReviewedBefore: boolean
  averageRating: number
  numberOfReviews: number
  numberOfPreferred: number
  numberOfNotPreferred: number
}

export class ReviewsSectionContainer extends Component<Props> {
  render() {
    const {
      editMode,
      startEditMode,
      stopEditMode,
      onSubmit,
      ...rest
    } = this.props

    return (
      <ReviewsSection
        writingReview={editMode}
        onClickWriteReview={startEditMode}
        onClickCancelWritingReview={stopEditMode}
        onSubmitReview={onSubmit}
        {...rest}
      />
    )
  }
}

const formName = 'buyer/supplierProfile/reviewsForm'
const formValueSelector = getFormValues(formName)

const ReviewsSectionContainerEditForm = editModeForm({
  formName,
  submitActionType: UPDATE_CARD,
  stopEditModeOnSave: true,
  destroyOnUnmount: false
})(ReviewsSectionContainer)

type FormValuesProps = RecordOf<{
  ratingResponses: RecordOf<{
    preferred: string
  }>
}>

type ContainerProps = {
  onlyMine?: boolean
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const myCard = profileSelectors.getMyCard(state)
    const ratings = profileSelectors.getOverallRatings(state)
    const ratingResponses = myCard.get('ratingResponses') || fromJS({})

    const numberOfPreferred = ratings.preferred || 0
    const numberOfNotPreferred = ratings.notPreferred || 0
    const numberOfNoOpinion = ratings.noOpinion || 0
    const formValues = formValueSelector(state) as FormValuesProps

    const user = sessionSelectors.getUser(state)

    return {
      isTealbot: user && user.get('roles').includes('tealbot'),
      averageRating: ratings.averageRating,
      numberOfReviews: ratings.numberOfReviews,
      numberOfPreferred,
      numberOfNotPreferred,
      numberOfNoOpinion,
      isPreferred: numberOfPreferred >= numberOfNotPreferred,
      hasReviewedBefore: !!ratingResponses.get('date'),
      reviewOptions: settingsSelectors.getReviewOptions(state),
      formValueIsPreferred:
        formValues &&
        formValues.getIn(['ratingResponses', 'preferred']) === 'preferred',
      initialValues: {
        id: myCard.get('id'),
        ratingResponses: {
          date: today,
          comment: ratingResponses.get('comment'),
          responses: ratingResponses.get('responses') || fromJS({}),
          preferred: ratingResponses.get('preferred'),
          preferredCategories: ratingResponses.get('preferredCategories')
        }
      }
    }
  },
  { onDeleteReview: deleteReview }
)(ReviewsSectionContainerEditForm)
