import { Map, List, fromJS } from 'immutable'
import { createSelector, defaultMemoize } from 'reselect'
import RootState from 'shared/models/RootState'
import usersSelectors from 'shared/selectors/usersSelectors'
import CertificationCategories from 'shared/models/CertificationCategories'

export const getVetSuppliers = (state: RootState) =>
  state.getIn(['buyer', 'currentVetSuppliers', 'data']) || Map({})
// export const getVetSuppliersInList = (state) => getVetSuppliers(state).toList()
export const getErrorMessage = (state: RootState) =>
  state.getIn(['buyer', 'currentVetSuppliers', 'errorMessage'])
export const getVetSuppliersSortByName = createSelector(getVetSuppliers, vets =>
  vets.toList().sort((vbsA, vbsB) => {
    const nameA = vbsA.getIn(['supplier', 'name']).toLowerCase()
    const nameB = vbsB.getIn(['supplier', 'name']).toLowerCase()

    if (nameA !== nameB) {
      if (nameA > nameB) {
        return 1
      } else {
        return -1
      }
    } else {
      return 0
    }
  })
)

export const getSupplierIdsInVet = createSelector(
  getVetSuppliers,
  vetBuyerSuppliers =>
    vetBuyerSuppliers.toList().map(vbs => vbs.getIn(['supplier', 'id']))
)

export const getVetSuppliersInList = createSelector(getVetSuppliers, vets =>
  vets.toList().sort((vbsA, vbsB) => {
    const statusA = vbsA.getIn(['vetBS', 'collaboration', 'status']) || ''
    const statusB = vbsB.getIn(['vetBS', 'collaboration', 'status']) || ''
    const nameA = vbsA.getIn(['supplier', 'name']) || ''
    const nameB = vbsB.getIn(['supplier', 'name']) || ''

    const order = {
      Suggested: 11,
      Approved: 10,
      Invited: 8,
      Opened: 7,
      Interested: 6,
      'Meet Criteria': 5,
      Accepted: 3,
      'Accepted With Condition': 4,
      Chosen: 1,
      'Not Chosen': 2,
      Cancelled: 13,
      Closed: 12,
      Declined: 9
    }

    if (statusA !== statusB) {
      return order[statusA] > order[statusB] ? 1 : -1
    }
    if (nameA.toLowerCase() !== nameB.toLowerCase()) {
      return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
    } else {
      return 0
    }
  })
)

export const getSupplierIds = createSelector(
  getVetSuppliers,
  vetBuyerSuppliers => {
    return vetBuyerSuppliers.toList().map(vbs => vbs.getIn(['supplier', 'id']))
  }
)

export const getVetSuppliersInGroup = createSelector(
  getVetSuppliers,
  vetBuyerSuppliers => {
    return (
      vetBuyerSuppliers
        // turn to List
        .toList()
        // group them by status
        .reduce((reduction, vetBuyerSupplier) => {
          const status = vetBuyerSupplier.getIn(['vetBS', 'doNotContact'])
            ? 'Do Not Contact'
            : vetBuyerSupplier.getIn(['vetBS', 'collaboration', 'status']) || ''

          // add key contacts
          const sendToContacts =
            vetBuyerSupplier.getIn([
              'vetBS',
              'collaboration',
              'sendToContacts'
            ]) || List([])

          const keyContacts = sendToContacts
            .sort((contact1, contact2) => {
              if (contact1.get('primary')) {
                return -1
              } else if (
                contact1.get('secondary') &&
                !contact2.get('primary')
              ) {
                return -1
              } else if (contact2.get('primary') || contact2.get('secondary')) {
                return 1
              } else {
                return 0
              }
            })
            .map(contact =>
              contact.merge(
                fromJS({
                  name: `${vetBuyerSupplier.getIn([
                    'users',
                    contact.get('user'),
                    'firstName'
                  ])} ${vetBuyerSupplier.getIn([
                    'users',
                    contact.get('user'),
                    'lastName'
                  ])}`,
                  email: vetBuyerSupplier.getIn([
                    'users',
                    contact.get('user'),
                    'email'
                  ]),
                  phone:
                    vetBuyerSupplier.getIn([
                      'users',
                      contact.get('user'),
                      'officePhone'
                    ]) ||
                    vetBuyerSupplier.getIn([
                      'users',
                      contact.get('user'),
                      'mobilePhone'
                    ])
                })
              )
            )
            .take(2)

          return reduction.has(status)
            ? reduction.updateIn([status], vbs =>
                vbs.push(vetBuyerSupplier.set('keyContacts', keyContacts))
              )
            : reduction.set(
                status,
                List([vetBuyerSupplier.set('keyContacts', keyContacts)])
              )
        }, Map())
        // sort vetBuyerSuppliers within each group
        .map(vbs =>
          vbs.sort((vbsA, vbsB) => {
            const incumbentA = vbsA.getIn(['vetBS', 'incumbent']) || false
            const incumbentB = vbsB.getIn(['vetBS', 'incumbent']) || false

            const nameA = vbsA.getIn(['supplier', 'name']) || ''
            const nameB = vbsB.getIn(['supplier', 'name']) || ''

            if (incumbentA !== incumbentB) {
              return incumbentB ? 1 : -1
            } else {
              return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
            }
          })
        )
    )
  }
)

export const getVetSupplierById = createSelector(
  (state: RootState, vetBuyerSupplierId: string) =>
    vetBuyerSupplierId &&
    state.getIn(['buyer', 'currentVetSuppliers', 'data', vetBuyerSupplierId]),
  vbs => vbs || Map({})
)

export const isInCurrentVet = (state: RootState, supplierId: string) =>
  getVetSuppliers(state)
    .toList()
    .findIndex(v => v.getIn(['supplier', 'id']) === supplierId) !== -1

export const getVetSupplierBySupplierId = createSelector(
  getVetSuppliers,
  (state: RootState, supplierId: string) => supplierId,
  (vets, supplierId: string) =>
    vets.toList().find(v => v.getIn(['supplier', 'id']) === supplierId)
)

export const getVetSupplierComments = createSelector(
  (state: RootState, vetId: string, vetBuyerSupplierId: string) =>
    state.getIn([
      'buyer',
      'currentVetSuppliers',
      'data',
      vetBuyerSupplierId,
      'supplier',
      'id'
    ]),
  (state: RootState, vetId: string, vetBuyerSupplierId: string) =>
    state.getIn([
      'buyer',
      'currentVetSuppliers',
      'data',
      vetBuyerSupplierId,
      'vetBS',
      'comments'
    ]),
  (state: RootState, vetId: string) =>
    state.getIn(['messages', `byVetCommentId`, vetId]),
  (supplierId: string, comments, activities) => {
    const relatedActivities =
      activities &&
      activities
        .filter(
          activity =>
            !!activity.get('type') && activity.get('reference') === supplierId
        )
        .map(activity =>
          fromJS({
            type: activity.get('type'),
            note: activity.get('type'),
            user: activity.get('createdBy'),
            date: activity.get('createdDate')
          })
        )

    const combineComments = !comments
      ? relatedActivities
      : !relatedActivities
      ? comments
      : comments.concat(relatedActivities)

    return (
      combineComments &&
      combineComments.sort((commentA, commentB) => {
        const diff =
          new Date(commentA.get('date')) < new Date(commentB.get('date'))
            ? -1
            : 1
        return diff
      })
    )
  }
)

export const getConversation = (state: RootState) => {
  return getVetSuppliersInList(state)
}

export const getConversationCount = (state: RootState) => {
  return getConversation(state).size
}

export const getConversationByGroup = createSelector(
  getConversation,
  (state: RootState, group) => group,
  (conversation, group) => {
    return conversation.filter(vbs => {
      const status = vbs.getIn(['vetBS', 'collaboration', 'status'])
      if (
        group === 'Invited' &&
        (status === 'Invited' ||
          status === 'Opened' ||
          status === 'Interested' ||
          status === 'Meet Criteria')
      ) {
        return true
      } else if (
        group === 'Responded' &&
        (status === 'Submitted' ||
          status === 'Accepted' ||
          status === 'Declined' ||
          status === 'Chosen' ||
          status === 'Not Chosen')
      ) {
        return true
      } else if (group === 'Chosen' && status === 'Chosen') {
        return true
      } else if (
        group === 'Suggested' &&
        (status === 'Suggested' || status === 'Approved')
      ) {
        return true
      } else {
        return false
      }
    })
  }
)

const getUpVotes = (state: RootState, vetBuyerSupplierId: string) => {
  return (
    state.getIn([
      'buyer',
      'currentVetSuppliers',
      'data',
      vetBuyerSupplierId,
      'vetBS',
      'upVotes'
    ]) || List([])
  )
}
const getDownVotes = (state: RootState, vetBuyerSupplierId: string) => {
  return (
    state.getIn([
      'buyer',
      'currentVetSuppliers',
      'data',
      vetBuyerSupplierId,
      'vetBS',
      'downVotes'
    ]) || List([])
  )
}

export const getVoteResults = createSelector(
  getUpVotes,
  getDownVotes,
  (upVotes, downVotes) => ({
    score: upVotes.size - downVotes.size,
    upVotes,
    downVotes
  })
)

export const getVetSupplierStatus = (state: RootState, vetBuyerSupplierId) =>
  state.getIn([
    'buyer',
    'currentVetSuppliers',
    'data',
    vetBuyerSupplierId,
    'vetBS',
    'collaboration',
    'status'
  ])

export const getVetSuppliersExportData = createSelector(
  getVetSuppliers,
  vetSuppliers => {
    const dataToExport = vetSuppliers.toList().reduce(
      (result, vetSupplier) => {
        const primaryLocation = vetSupplier.getIn(['vetBS', 'primaryLocation'])
        const supplierName = vetSupplier.getIn(['supplier', 'name'])
        const supplierDescription = vetSupplier.getIn([
          'supplier',
          'description',
          'en'
        ])
        const supplierDomain =
          vetSupplier.getIn(['supplier', 'domains', 0]) || ''
        const supplierAddress =
          vetSupplier.getIn([
            'supplier',
            'supplier',
            'locations',
            primaryLocation || 0,
            'address'
          ]) || ''

        let subTypeCount = {}
        const supplierDiversityCerts = vetSupplier
          .getIn(['supplier', 'supplier', 'certification'], List([]))
          .filter(cert => cert.get('category') === 'diversity')
          .reduce((groupedDiversityCerts, cert) => {
            const subType = cert.get('subCategory').toUpperCase()
            subTypeCount[subType] = subTypeCount[subType]
              ? 1
              : subTypeCount[subType] + 1

            const subTypeCountSuffix =
              subTypeCount[subType] && subTypeCount[subType] > 1
                ? subTypeCount[subType]
                : ''

            return {
              ...groupedDiversityCerts,
              [`${subType}Certification${subTypeCountSuffix}`]:
                CertificationCategories.diversity.subCategories[
                  cert.get('subCategory')
                ]?.defaultMessage || cert.get('subCategory').toUpperCase(),
              [`${subType}CertificationAgency${subTypeCountSuffix}`]: cert.get(
                'certAgency'
              ),
              [`${subType}SourceURL${subTypeCountSuffix}`]: cert.get(
                'sourceURL'
              ),
              [`${subType}CertificationExpirationDate${subTypeCountSuffix}`]: cert.get(
                'certExpiration'
              )
                ? new Date(cert.get('certExpiration')).toDateString()
                : ''
            }
          }, {})

        const cards = vetSupplier.get('cards').toList()
        const ourPublicContacts = cards
          .map(card => card.get('contacts'))
          .reduce((contacts, card) => {
            return contacts.concat(card)
          }, List([]))
          .filter(contact => !contact.get('isPrivate'))

        const sendToContacts = vetSupplier.getIn([
          'vetBS',
          'collaboration',
          'sendToContacts'
        ])
        const primaryContact = sendToContacts
          ? sendToContacts.findIndex(contact => contact.get('primary'))
          : -1
        const secondaryContact = sendToContacts
          ? sendToContacts.findIndex(contact => contact.get('secondary'))
          : -1

        const contactId =
          primaryContact !== -1
            ? sendToContacts.getIn([primaryContact, 'user'])
            : secondaryContact !== -1
            ? sendToContacts.getIn([secondaryContact, 'user'])
            : ourPublicContacts.size > 0
            ? ourPublicContacts.getIn([0, 'user'])
            : vetSupplier.getIn(['supplier', 'supplier', 'contacts', 0, 'user'])

        const contactName =
          (contactId &&
            `${vetSupplier.getIn(['users', contactId, 'firstName']) ||
              ''} ${vetSupplier.getIn(['users', contactId, 'lastName']) ||
              ''}`) ||
          ''
        const contactEmail =
          (contactId && vetSupplier.getIn(['users', contactId, 'email'])) || ''
        const contactMobilePhoneNumber =
          (contactId &&
            vetSupplier.getIn(['users', contactId, 'mobilePhone'])) ||
          ''
        const contactOfficePhoneNumber =
          (contactId &&
            vetSupplier.getIn(['users', contactId, 'officePhone'])) ||
          ''
        const supplierPhoneNumber = getPhoneForSupplier(vetSupplier)

        const rowToExport = {
          supplierName,
          supplierDescription,
          supplierDomain,
          supplierAddress,
          supplierPhoneNumber,
          contactName,
          contactEmail,
          contactMobilePhoneNumber,
          contactOfficePhoneNumber,
          collaborationStatus: vetSupplier.getIn(
            ['vetBS', 'collaboration', 'status'],
            ''
          ),
          ...supplierDiversityCerts
        }

        return {
          columns: {
            ...result.columns,
            ...rowToExport
          },
          data: [...result.data, rowToExport]
        }
      },
      {
        data: [],
        columns: {}
      }
    )

    return {
      data: dataToExport.data,
      columns: Object.keys(dataToExport.columns)
    }
  }
)

export const getContactsExportData = createSelector(
  getVetSuppliers,
  vetSuppliers =>
    vetSuppliers.toList().reduce((result, vetSupplier) => {
      const supplierName = vetSupplier.getIn(['supplier', 'name'])
      const sendToContacts =
        vetSupplier.getIn(['vetBS', 'collaboration', 'sendToContacts']) ||
        List([])

      const exportContacts = sendToContacts.map(contact =>
        contact.set(
          'email',
          vetSupplier.getIn(['users', contact.get('user'), 'email'])
        )
      )

      return result.push(
        fromJS({
          supplierName,
          exportContacts
        })
      )
    }, List([]))
)

export const getSuppliersToTag = createSelector(
  getVetSuppliers,
  suppliers =>
    suppliers &&
    suppliers
      .toList()
      .filter(
        s =>
          s.getIn(['vetBS', 'collaboration', 'status']) === 'Invited' ||
          s.getIn(['vetBS', 'collaboration', 'status']) === 'Approved' ||
          s.getIn(['vetBS', 'collaboration', 'status']) === 'Chosen'
      )
      .map(s => s.getIn(['supplier', 'id']))
)

export const hasInvited = createSelector(
  getVetSuppliers,
  suppliers =>
    suppliers && suppliers.toList().some(s => !!s.getIn(['vetBS', 'invited']))
)

export const getResponseFile = (
  state: RootState,
  vetBuyerSupplierId: string
) => {
  return getVetSupplierById(state, vetBuyerSupplierId).getIn([
    'vetBS',
    'responseFile'
  ])
}

export const getSendToContactsFromVetSuppliers = defaultMemoize(vetBS => {
  const sendToContacts =
    vetBS && vetBS.getIn(['vetBS', 'collaboration', 'sendToContacts'])
  const users = vetBS && vetBS.getIn(['users'])

  return (
    sendToContacts &&
    users &&
    sendToContacts
      .filter(
        contact =>
          !!contact.get('user') &&
          !!users.get(contact.get('user')) &&
          !users.get(contact.get('user')).get('blocked')
      )
      .sort((c1, c2) => {
        const user1 = users.get(c1.get('user'))
        const user2 = users.get(c2.get('user'))

        return c1 && c2 && user1 && user2
          ? c1.get('primary')
            ? -1
            : c2.get('primary')
            ? 1
            : c1.get('secondary')
            ? -1
            : c2.get('secondary')
            ? 1
            : `${user1.get('firstName')} ${user1.get(
                'lastName'
              )}`.toUpperCase() <
              `${user2.get('firstName')} ${user2.get('lastName')}`.toUpperCase()
            ? -1
            : 1
          : 0
      })
  )
})

export const getSendToContactsMap = createSelector(
  (state: RootState, vbsId: string) =>
    getSendToContactsFromVetSuppliers(getVetSupplierById(state, vbsId)),
  sendToContacts => {
    return (
      sendToContacts && sendToContacts.toMap().mapKeys((k, v) => v.get('user'))
    )
  }
)

export const getSupplierPrimaryContact = createSelector(
  (state: RootState, vbsId: string) =>
    getSendToContactsFromVetSuppliers(getVetSupplierById(state, vbsId)),
  sendToContacts => sendToContacts && sendToContacts.find(c => c.get('primary'))
)

export const getSupplierSecondaryContact = createSelector(
  (state: RootState, vbsId: string) =>
    getSendToContactsFromVetSuppliers(getVetSupplierById(state, vbsId)),
  sendToContacts =>
    sendToContacts && sendToContacts.find(c => c.get('secondary'))
)

export const getLatestContactAdded = createSelector(
  getVetSupplierById,
  usersSelectors.getUsers,
  (vbs, users) => {
    const sendToContacts =
      vbs && vbs.getIn(['vetBS', 'collaboration', 'sendToContacts'])
    const lastContact = sendToContacts && sendToContacts.last()
    const lastContactId = lastContact && lastContact.get('user')
    const user = lastContactId && users.get(lastContactId)
    return (
      user && `${user.get('firstName') || ''} ${user.get('lastName') || ''}`
    )
  }
)

export const getPhoneForSupplier = vetSupplier => {
  let supplierPhoneNumber = vetSupplier.getIn([
    'supplier',
    'supplier',
    'phones',
    'office',
    0
  ])
  if (!supplierPhoneNumber) {
    const locations =
      vetSupplier.getIn(['supplier', 'supplier', 'locations']) || List([])
    const locationWithPhone = locations.find(loc => !!loc.get('phone'))
    supplierPhoneNumber = locationWithPhone && locationWithPhone.get('phone')
  }
  return supplierPhoneNumber || ''
}
const categoryOrder = [
  'Chosen',
  'Accepted',
  'Accepted With Condition',
  'Meet Criteria',
  'Interested',
  'Opened',
  'Invited'
]
export const vbsToEmail = createSelector(
  getVetSuppliersInGroup,
  vbsGroupedByStatus =>
    categoryOrder.reduce<any>((result, currCategory) => {
      if (vbsGroupedByStatus && vbsGroupedByStatus.has(currCategory)) {
        vbsGroupedByStatus.get(currCategory).map(vbs => result.push(vbs))
      }
      return result
    }, [])
)
