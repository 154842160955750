import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

const composeWithEnhancers =
  process.env.NODE_ENV === 'development' ? composeWithDevTools : compose

export default function configureStore(
  rootReducer,
  preloadedState,
  asyncMiddleware = []
) {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithEnhancers(applyMiddleware(...asyncMiddleware))
  )

  store.asyncReducers = {}

  return store
}
