import React, { useMemo } from 'react'
import LoadPage, { UploadHistoryType } from '../../components/LoadPage'
import LoadSummary from '../../components/LoadSummary'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import { List, RecordOf, OrderedMap } from 'immutable'
import {
  uploadSpendFile,
  publishSpend,
  removeSpend,
  loadStagingHistory,
  notify
} from 'shared/actions'
import dataSelectors from 'shared/selectors/dataSelectors'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import Divider from 'shared/components/Divider'
import paths from '../../diversityPaths'
import { Switch, Route } from 'react-router'
import companySelectors from 'supplier/shared/selectors/companySelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'

type Tier2Communities = List<
  RecordOf<{
    communityId: string
    buyerName: string
    status: string
  }>
>

type Props = {
  isUploading: boolean
  isConcierge: boolean
  uploadError: boolean
  uploadHistory?: OrderedMap<string, List<RecordOf<UploadHistoryType>>>
  uploadSpendFile: (payload: {
    file: File
    year: number
    quarter: number
    listType: string
  }) => void
  publishSpend: (payload: { stagingId: string; listType: string }) => void
  removeSpend: (payload: {
    stagingId: string
    listType: string
    uploadHistory?: OrderedMap<string, List<RecordOf<UploadHistoryType>>>
    activeQuarter: string
  }) => void
  communities: Tier2Communities
  loadStagingHistory: (payload: { listType: string }) => void
  notify: (payload: { message: string }) => void
  country?: string
  isLoading: boolean
}
const LoadPageContainer = ({
  isConcierge,
  uploadHistory,
  isUploading,
  uploadError,
  uploadSpendFile,
  publishSpend,
  removeSpend,
  loadStagingHistory,
  communities,
  notify,
  country,
  isLoading
}: Props) => {
  const hasAcceptedInvite = useMemo(
    () =>
      communities &&
      communities.some(supplier => supplier.get('status') === 'Accepted'),
    [communities]
  )
  return !hasAcceptedInvite ? (
    <Text className='mt3'>
      <FormattedMessage
        id='LoadPageContainer.NoDataMessage'
        defaultMessage='You need to accept at least one invitation to start loading spend.'
      />
    </Text>
  ) : (
    <>
      <NavBar>
        <NavLink
          to={paths.diversityLoad}
          label={
            <FormattedMessage
              id='LoadPageContainer.UploadDetail'
              defaultMessage='Upload Detail Spend File'
            />
          }
          isActive={(_, location) => location.pathname === paths.diversityLoad}
        />
        {isConcierge && (
          <NavLink
            to={paths.diversityLoadSummary}
            label={
              <FormattedMessage
                id='LoadPageContainer.ManualSpend'
                defaultMessage='Input Aggregated Quarterly Spend'
              />
            }
            isActive={(_, location) =>
              location.pathname === paths.diversityLoadSummary
            }
          />
        )}
      </NavBar>
      <Divider />
      <Switch>
        <Route exact path={paths.diversityLoad}>
          <LoadPage
            uploadHistory={uploadHistory}
            isUploading={isUploading}
            uploadError={uploadError}
            uploadSpendFile={uploadSpendFile}
            publishSpend={publishSpend}
            removeSpend={removeSpend}
            loadStagingHistory={loadStagingHistory}
            notify={notify}
            isLoading={isLoading}
          />
        </Route>
        {isConcierge && (
          <Route path={paths.diversityLoadSummary}>
            <LoadSummary country={country} />
          </Route>
        )}
      </Switch>
    </>
  )
}

export default connect(
  (state: RootState) => {
    const locations = companySelectors.getCompanySupplierField(
      state,
      'locations'
    )

    return {
      isUploading: state.getIn(['admin', 'upload', 'isUploading']),
      isConcierge: sessionSelectors.userHasRole(state, 'tealbot'),
      uploadError: state.getIn(['admin', 'upload', 'uploadError']),
      uploadHistory: dataSelectors.getStagingHistory(state),
      communities: state.getIn([
        'supplier',
        'tier2',
        'tier2Communities',
        'tier2CommMemberships'
      ]),
      country: locations?.getIn([0, 'components', 'country']),
      isLoading: dataSelectors.isLoading(state, 'stagingHistory')
    }
  },
  { uploadSpendFile, publishSpend, removeSpend, loadStagingHistory, notify }
)(LoadPageContainer)
