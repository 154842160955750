import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select } from 'redux-saga/effects'
import cards from 'shared/utils/api/cards'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import mapKeys from 'lodash.mapkeys'
import mapValues from 'lodash.mapvalues'
import parseCard from 'shared/utils/data/parseCard'

export function* getMyCardBySupplierSaga(action) {
  const { supplierId, supplierName } = action.payload
  const myId = yield select(sessionSelectors.getUserId)
  const cardsById = mapValues(
    yield call(cards.getBySupplierId, supplierId),
    parseCard
  )
  const cardsValue = Object.values(cardsById)
  const bySupplier = {
    [supplierId]: mapValues(
      mapKeys(cardsById, card => card.owner),
      card => card.id
    )
  }
  let myCard
  myCard =
    cardsValue.length > 0 && cardsValue.filter(card => card.owner === myId)
  if (!myCard || myCard.length === 0) {
    myCard = {}
  } else {
    myCard = myCard[0]
  }

  return yield {
    myCard,
    supplierId,
    supplierName,
    cardId: myCard.id,
    cardsById,
    bySupplier
  }
}

export default createApiRequestSaga(getMyCardBySupplierSaga)
