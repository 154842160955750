import { defineMessages } from 'react-intl'

export const translatedFilterKeys = {
  collaborationStatus: defineMessages({
    suggested: {
      id: 'collaborationStatus.Suggested',
      defaultMessage: 'Suggested'
    },
    approved: {
      id: 'collaborationStatus.Approved',
      defaultMessage: 'Approved'
    },
    invited: {
      id: 'collaborationStatus.Invited',
      defaultMessage: 'Invited'
    },
    opened: {
      id: 'collaborationStatus.Opened',
      defaultMessage: 'Opened'
    },
    interested: {
      id: 'collaborationStatus.Interested',
      defaultMessage: 'Interested'
    },
    meetCriteria: {
      id: 'collaborationStatus.MeetCriteria',
      defaultMessage: 'Meet Criteria'
    },
    accepted: {
      id: 'collaborationStatus.Accepted',
      defaultMessage: 'Accepted'
    },
    declined: {
      id: 'collaborationStatus.Declined',
      defaultMessage: 'Declined'
    },
    chosen: {
      id: 'collaborationStatus.Chosen',
      defaultMessage: 'Qualified'
    },
    notChosen: {
      id: 'collaborationStatus.NotChosen',
      defaultMessage: 'Not Qualified'
    },
    cancelled: {
      id: 'collaborationStatus.Cancel',
      defaultMessage: 'Cancelled'
    },
    closed: {
      id: 'collaborationStatus.Closed',
      defaultMessage: 'Closed'
    },
    doNotContact: {
      id: 'collaborationStatus.DoNotContact',
      defaultMessage: 'Do Not Contact'
    }
  }),

  country: defineMessages({
    unitedStates: {
      id: 'country.UnitedStates',
      defaultMessage: 'United States'
    },
    canada: {
      id: 'country.Canada',
      defaultMessage: 'Canada'
    },
    unitedKingdom: {
      id: 'country.UnitedKingdom',
      defaultMessage: 'United Kingdom'
    },
    brazil: {
      id: 'country.Brazil',
      defaultMessage: 'Brazil'
    },
    germany: {
      id: 'country.Germany',
      defaultMessage: 'Germany'
    },
    australia: {
      id: 'country.Australia',
      defaultMessage: 'Australia'
    },
    france: {
      id: 'country.France',
      defaultMessage: 'France'
    },
    italy: {
      id: 'country.Italy',
      defaultMessage: 'Italy'
    },
    southAfrica: {
      id: 'country.SouthAfrica',
      defaultMessage: 'South Africa'
    },
    japan: {
      id: 'country.Japan',
      defaultMessage: 'Japan'
    },
    mexico: {
      id: 'country.Mexico',
      defaultMessage: 'Mexico'
    },
    theNetherlands: {
      id: 'country.TheNetherlands',
      defaultMessage: 'The Netherlands'
    },
    india: {
      id: 'country.India',
      defaultMessage: 'India'
    },
    china: {
      id: 'country.China',
      defaultMessage: 'China'
    },
    poland: {
      id: 'country.Poland',
      defaultMessage: 'Poland'
    },
    malaysia: {
      id: 'country.Malaysia',
      defaultMessage: 'Malaysia'
    },
    spain: {
      id: 'country.Spain',
      defaultMessage: 'Spain'
    },
    chile: {
      id: 'country.Chile',
      defaultMessage: 'Chile'
    },
    sweden: {
      id: 'country.Sweden',
      defaultMessage: 'Sweden'
    },
    colombia: {
      id: 'country.Colombia',
      defaultMessage: 'Colombia'
    },
    singapore: {
      id: 'country.Singapore',
      defaultMessage: 'Singapore'
    },
    russia: {
      id: 'country.Russia',
      defaultMessage: 'Russia'
    },
    belgium: {
      id: 'country.Belgium',
      defaultMessage: 'Belgium'
    },
    switzerland: {
      id: 'country.Switzerland',
      defaultMessage: 'Switzerland'
    },
    argentina: {
      id: 'country.Argentina',
      defaultMessage: 'Argentina'
    },
    puertoRico: {
      id: 'country.PuertoRico',
      defaultMessage: 'Puerto Rico'
    },
    ireland: {
      id: 'country.Ireland',
      defaultMessage: 'Ireland'
    },
    denmark: {
      id: 'country.Denmark',
      defaultMessage: 'Denmark'
    },
    unitedArabEmirates: {
      id: 'country.UnitedArabEmirates',
      defaultMessage: 'United Arab Emirates'
    },
    turkey: {
      id: 'country.Turkey',
      defaultMessage: 'Turkey'
    },
    austria: {
      id: 'country.Austria',
      defaultMessage: 'Austria'
    },
    philippines: {
      id: 'country.Philippines',
      defaultMessage: 'Philippines'
    },
    taiwan: {
      id: 'country.Taiwan',
      defaultMessage: 'Taiwan'
    },
    hungary: {
      id: 'country.Hungary',
      defaultMessage: 'Hungary'
    },
    czechRepublic: {
      id: 'country.CzechRepublic',
      defaultMessage: 'Czech Republic'
    },
    peru: {
      id: 'country.Peru',
      defaultMessage: 'Peru'
    },
    norway: {
      id: 'country.Norway',
      defaultMessage: 'Norway'
    },
    portugal: {
      id: 'country.Portugal',
      defaultMessage: 'Portugal'
    },
    romania: {
      id: 'country.Romania',
      defaultMessage: 'Romania'
    },
    thailand: {
      id: 'country.Thailand',
      defaultMessage: 'Thailand'
    },
    ukraine: {
      id: 'country.Ukraine',
      defaultMessage: 'Ukraine'
    },
    hongKong: {
      id: 'country.HongKong',
      defaultMessage: 'Hong Kong'
    },
    finland: {
      id: 'country.Finland',
      defaultMessage: 'Finland'
    },
    southKorea: {
      id: 'country.SouthKorea',
      defaultMessage: 'South Korea'
    },
    israel: {
      id: 'country.Israel',
      defaultMessage: 'Israel'
    },
    guatemala: {
      id: 'country.Guatemala',
      defaultMessage: 'Guatemala'
    },
    greece: {
      id: 'country.Greece',
      defaultMessage: 'Greece'
    },
    indonesia: {
      id: 'country.Indonesia',
      defaultMessage: 'Indonesia'
    },
    newZealand: {
      id: 'country.NewZealand',
      defaultMessage: 'New Zealand'
    },
    afghanistan: {
      id: 'country.Afghanistan',
      defaultMessage: 'Afghanistan'
    },
    pakistan: {
      id: 'country.Pakistan',
      defaultMessage: 'Pakistan'
    },
    slovakia: {
      id: 'country.Slovakia',
      defaultMessage: 'Slovakia'
    },
    vietnam: {
      id: 'country.Vietnam',
      defaultMessage: 'Vietnam'
    },
    costaRica: {
      id: 'country.CostaRica',
      defaultMessage: 'Costa Rica'
    },
    luxembourg: {
      id: 'country.Luxembourg',
      defaultMessage: 'Luxembourg'
    },
    kenya: {
      id: 'country.Kenya',
      defaultMessage: 'Kenya'
    },
    ecuador: {
      id: 'country.Ecuador',
      defaultMessage: 'Ecuador'
    },
    saudiArabia: {
      id: 'country.SaudiArabia',
      defaultMessage: 'Saudi Arabia'
    },
    croatia: {
      id: 'country.Croatia',
      defaultMessage: 'Croatia'
    },
    cocosKeelingIslands: {
      id: 'country.CocosKeelingIslands',
      defaultMessage: 'Cocos [Keeling] Islands'
    },
    kuwait: {
      id: 'country.Kuwait',
      defaultMessage: 'Kuwait'
    },
    elSalvador: {
      id: 'country.ElSalvador',
      defaultMessage: 'El Salvador'
    },
    kazakhstan: {
      id: 'country.Kazakhstan',
      defaultMessage: 'Kazakhstan'
    },
    slovenia: {
      id: 'country.Slovenia',
      defaultMessage: 'Slovenia'
    },
    jordan: {
      id: 'country.Jordan',
      defaultMessage: 'Jordan'
    },
    bangladesh: {
      id: 'country.Bangladesh',
      defaultMessage: 'Bangladesh'
    },
    lithuania: {
      id: 'country.Lithuania',
      defaultMessage: 'Lithuania'
    },
    venezuela: {
      id: 'country.Venezuela',
      defaultMessage: 'Venezuela'
    },
    britishIndianOceanTerritory: {
      id: 'country.BritishIndianOceanTerritory',
      defaultMessage: 'British Indian Ocean Territory'
    },
    bulgaria: {
      id: 'country.Bulgaria',
      defaultMessage: 'Bulgaria'
    },
    bolivia: {
      id: 'country.Bolivia',
      defaultMessage: 'Bolivia'
    },
    azerbaijan: {
      id: 'country.Azerbaijan',
      defaultMessage: 'Azerbaijan'
    },
    iraq: {
      id: 'country.Iraq',
      defaultMessage: 'Iraq'
    },
    uSVirginIslands: {
      id: 'country.USVirginIslands',
      defaultMessage: 'U.S. Virgin Islands'
    },
    uganda: {
      id: 'country.Uganda',
      defaultMessage: 'Uganda'
    },
    estonia: {
      id: 'country.Estonia',
      defaultMessage: 'Estonia'
    },
    nigeria: {
      id: 'country.Nigeria',
      defaultMessage: 'Nigeria'
    },
    qatar: {
      id: 'country.Qatar',
      defaultMessage: 'Qatar'
    },
    dominicanRepublic: {
      id: 'country.DominicanRepublic',
      defaultMessage: 'Dominican Republic'
    },
    serbia: {
      id: 'country.Serbia',
      defaultMessage: 'Serbia'
    },
    tanzania: {
      id: 'country.Tanzania',
      defaultMessage: 'Tanzania'
    },
    honduras: {
      id: 'country.Honduras',
      defaultMessage: 'Honduras'
    },
    mongolia: {
      id: 'country.Mongolia',
      defaultMessage: 'Mongolia'
    },
    nepal: {
      id: 'country.Nepal',
      defaultMessage: 'Nepal'
    },
    tunisia: {
      id: 'country.Tunisia',
      defaultMessage: 'Tunisia'
    },
    uruguay: {
      id: 'country.Uruguay',
      defaultMessage: 'Uruguay'
    },
    morocco: {
      id: 'country.Morocco',
      defaultMessage: 'Morocco'
    },
    egypt: {
      id: 'country.Egypt',
      defaultMessage: 'Egypt'
    },
    panama: {
      id: 'country.Panama',
      defaultMessage: 'Panama'
    },
    lebanon: {
      id: 'country.Lebanon',
      defaultMessage: 'Lebanon'
    },
    sriLanka: {
      id: 'country.SriLanka',
      defaultMessage: 'Sri Lanka'
    },
    bH: {
      id: 'country.B&H',
      defaultMessage: 'B&H'
    },
    bahrain: {
      id: 'country.Bahrain',
      defaultMessage: 'Bahrain'
    },
    georgia: {
      id: 'country.Georgia',
      defaultMessage: 'Georgia'
    },
    iceland: {
      id: 'country.Iceland',
      defaultMessage: 'Iceland'
    },
    oman: {
      id: 'country.Oman',
      defaultMessage: 'Oman'
    },
    niue: {
      id: 'country.Niue',
      defaultMessage: 'Niue'
    },
    latvia: {
      id: 'country.Latvia',
      defaultMessage: 'Latvia'
    },
    ghana: {
      id: 'country.Ghana',
      defaultMessage: 'Ghana'
    },
    cameroon: {
      id: 'country.Cameroon',
      defaultMessage: 'Cameroon'
    },
    samoa: {
      id: 'country.Samoa',
      defaultMessage: 'Samoa'
    },
    cambodia: {
      id: 'country.Cambodia',
      defaultMessage: 'Cambodia'
    },
    haiti: {
      id: 'country.Haiti',
      defaultMessage: 'Haiti'
    },
    netherlands: {
      id: 'country.Netherlands',
      defaultMessage: 'Netherlands'
    },
    armenia: {
      id: 'country.Armenia',
      defaultMessage: 'Armenia'
    },
    zambia: {
      id: 'country.Zambia',
      defaultMessage: 'Zambia'
    },
    antiguaAndBarbuda: {
      id: 'country.AntiguaAndBarbuda',
      defaultMessage: 'Antigua And Barbuda'
    },
    belarus: {
      id: 'country.Belarus',
      defaultMessage: 'Belarus'
    },
    laos: {
      id: 'country.Laos',
      defaultMessage: 'Laos'
    },
    zimbabwe: {
      id: 'country.Zimbabwe',
      defaultMessage: 'Zimbabwe'
    },
    nicaragua: {
      id: 'country.Nicaragua',
      defaultMessage: 'Nicaragua'
    },
    macedonia: {
      id: 'country.Macedonia',
      defaultMessage: 'Macedonia'
    },
    senegal: {
      id: 'country.Senegal',
      defaultMessage: 'Senegal'
    },
    belize: {
      id: 'country.Belize',
      defaultMessage: 'Belize'
    },
    moldova: {
      id: 'country.Moldova',
      defaultMessage: 'Moldova'
    },
    vietNam: {
      id: 'country.VietNam',
      defaultMessage: 'Viet Nam'
    },
    angola: {
      id: 'country.Angola',
      defaultMessage: 'Angola'
    },
    cyprus: {
      id: 'country.Cyprus',
      defaultMessage: 'Cyprus'
    },
    jamaica: {
      id: 'country.Jamaica',
      defaultMessage: 'Jamaica'
    },
    northernMarianaIslands: {
      id: 'country.NorthernMarianaIslands',
      defaultMessage: 'Northern Mariana Islands'
    },
    ethiopia: {
      id: 'country.Ethiopia',
      defaultMessage: 'Ethiopia'
    },
    malawi: {
      id: 'country.Malawi',
      defaultMessage: 'Malawi'
    },
    mozambique: {
      id: 'country.Mozambique',
      defaultMessage: 'Mozambique'
    },
    bermuda: {
      id: 'country.Bermuda',
      defaultMessage: 'Bermuda'
    },
    trinidadAndTobago: {
      id: 'country.TrinidadAndTobago',
      defaultMessage: 'Trinidad And Tobago'
    },
    kyrgyzstan: {
      id: 'country.Kyrgyzstan',
      defaultMessage: 'Kyrgyzstan'
    },
    lvaLv: {
      id: 'country.LvaLv',
      defaultMessage: 'Lva Lv'
    },
    rwanda: {
      id: 'country.Rwanda',
      defaultMessage: 'Rwanda'
    },
    namibia: {
      id: 'country.Namibia',
      defaultMessage: 'Namibia'
    },
    botswana: {
      id: 'country.Botswana',
      defaultMessage: 'Botswana'
    },
    djibouti: {
      id: 'country.Djibouti',
      defaultMessage: 'Djibouti'
    },
    liberia: {
      id: 'country.Liberia',
      defaultMessage: 'Liberia'
    },
    americanSamoa: {
      id: 'country.AmericanSamoa',
      defaultMessage: 'American Samoa'
    },
    portugalTerritorialWaters: {
      id: 'country.PortugalTerritorialWaters',
      defaultMessage: 'Portugal (Territorial Waters)'
    },
    algeria: {
      id: 'country.Algeria',
      defaultMessage: 'Algeria'
    },
    'federatedStatesOfMicronesia ': {
      id: 'country.FederatedStatesOfMicronesia ',
      defaultMessage: 'Federated States Of Micronesia '
    },
    tokelau: {
      id: 'country.Tokelau',
      defaultMessage: 'Tokelau'
    },
    congo: {
      id: 'country.Congo',
      defaultMessage: 'Congo'
    },
    coTeDivoire: {
      id: 'country.CoTeDIvoire',
      defaultMessage: "CôTe D'ivoire"
    },
    tonga: {
      id: 'country.Tonga',
      defaultMessage: 'Tonga'
    },
    yemen: {
      id: 'country.Yemen',
      defaultMessage: 'Yemen'
    },
    anguilla: {
      id: 'country.Anguilla',
      defaultMessage: 'Anguilla'
    },
    myanmar: {
      id: 'country.Myanmar',
      defaultMessage: 'Myanmar'
    },
    suriname: {
      id: 'country.Suriname',
      defaultMessage: 'Suriname'
    },
    togo: {
      id: 'country.Togo',
      defaultMessage: 'Togo'
    },
    fiji: {
      id: 'country.Fiji',
      defaultMessage: 'Fiji'
    },
    mauritius: {
      id: 'country.Mauritius',
      defaultMessage: 'Mauritius'
    },
    guinea: {
      id: 'country.Guinea',
      defaultMessage: 'Guinea'
    },
    mali: {
      id: 'country.Mali',
      defaultMessage: 'Mali'
    },
    albania: {
      id: 'country.Albania',
      defaultMessage: 'Albania'
    },
    cedex: {
      id: 'country.Cedex',
      defaultMessage: 'Cedex'
    },
    sierraLeone: {
      id: 'country.SierraLeone',
      defaultMessage: 'Sierra Leone'
    },
    burkinaFaso: {
      id: 'country.BurkinaFaso',
      defaultMessage: 'Burkina Faso'
    },
    cuba: {
      id: 'country.Cuba',
      defaultMessage: 'Cuba'
    },
    liechtenstein: {
      id: 'country.Liechtenstein',
      defaultMessage: 'Liechtenstein'
    },
    saintVincentAndTheGrenadines: {
      id: 'country.SaintVincentAndTheGrenadines',
      defaultMessage: 'Saint Vincent And The Grenadines'
    },
    sudan: {
      id: 'country.Sudan',
      defaultMessage: 'Sudan'
    },
    benin: {
      id: 'country.Benin',
      defaultMessage: 'Benin'
    },
    iran: {
      id: 'country.Iran',
      defaultMessage: 'Iran'
    },
    paraguay: {
      id: 'country.Paraguay',
      defaultMessage: 'Paraguay'
    },
    federatedStatesOfMicronesia: {
      id: 'country.FederatedStatesOfMicronesia',
      defaultMessage: 'Federated States Of Micronesia'
    },
    monaco: {
      id: 'country.Monaco',
      defaultMessage: 'Monaco'
    }
  }),

  attributes: defineMessages({
    existing: {
      id: 'attributes.Existing',
      defaultMessage: 'Existing'
    },
    preferred: {
      id: 'attributes.Preferred',
      defaultMessage: 'Preferred'
    },
    doNotUse: {
      id: 'attributes.DoNotUse',
      defaultMessage: 'Do Not Use'
    },
    nonRecruit: {
      id: 'attributes.NonRecruit',
      defaultMessage: 'Non Recruit'
    },
    restricted: {
      id: 'attributes.Restricted',
      defaultMessage: 'Restricted'
    },
    noRelationship: {
      id: 'attributes.NoRelationship',
      defaultMessage: 'No Relationship'
    }
  }),

  recentPercentile: defineMessages({
    '5': {
      id: 'recentPercentile.HighestSpend',
      defaultMessage: 'Highest Spend'
    },
    '4': {
      id: 'recentPercentile.HighSpend',
      defaultMessage: 'High Spend'
    },
    '3': {
      id: 'recentPercentile.MediumSpend',
      defaultMessage: 'Medium Spend'
    },
    '2': {
      id: 'recentPercentile.LowSpend',
      defaultMessage: 'Low Spend'
    },
    '1': {
      id: 'recentPercentile.VeryLowSpend',
      defaultMessage: 'Very Low Spend'
    },
    '0': {
      id: 'recentPercentile.NoSpend',
      defaultMessage: 'No Spend'
    }
  }),

  attachments: defineMessages({
    audit: {
      id: 'attachments.Audit',
      defaultMessage: 'Audit'
    },
    contract: {
      id: 'attachments.Contract',
      defaultMessage: 'Contract'
    },
    certification: {
      id: 'attachments.Certification',
      defaultMessage: 'Certification'
    },
    incidence: {
      id: 'attachments.Incidence',
      defaultMessage: 'Incidence'
    },
    msa: {
      id: 'attachments.MSA',
      defaultMessage: 'MSA'
    },
    other: {
      id: 'attachments.Other',
      defaultMessage: 'Other'
    }
  }),

  warnings: defineMessages({
    none: {
      id: 'warnings.none',
      defaultMessage: 'None'
    },
    inactive: {
      id: 'warnings.inactive',
      defaultMessage: 'InActive'
    },
    unreachableDomain: {
      id: 'warnings.UNREACHABLE_DOMAIN',
      defaultMessage: 'Unreachable Domain'
    },
    unknownReason: {
      id: 'warnings.UNKNOWN_REASON',
      defaultMessage: 'Unknown Reason'
    },
    domainForSale: {
      id: 'warnings.DOMAIN_FOR_SALE',
      defaultMessage: 'Domain For Sale'
    },
    flagCountry: {
      id: 'warnings.flagCountry',
      defaultMessage: 'Flag Country'
    },
    banned: {
      id: 'warnings.banned',
      defaultMessage: 'Banned'
    },
    blacklistedDomain: {
      id: 'warnings.BLACKLISTED_DOMAIN',
      defaultMessage: 'Blacklisted Domain'
    },
    inappropriateContentDomain: {
      id: 'warnings.INAPPROPRIATE_CONTENT_DOMAIN',
      defaultMessage: 'Inappropriate Content Domain'
    }
  }),

  communityStatus: defineMessages({
    invited: {
      id: 'communityStatus.Invited',
      defaultMessage: 'Invited'
    },
    accepted: {
      id: 'communityStatus.Accepted',
      defaultMessage: 'Accepted'
    },
    declined: {
      id: 'communityStatus.Declined',
      defaultMessage: 'Declined'
    }
  }),

  diversityProgram: defineMessages({
    false: {
      id: 'No',
      defaultMessage: 'No'
    },
    true: {
      id: 'diversityProgram.Yes',
      defaultMessage: 'Yes'
    }
  }),

  supplierType: defineMessages({
    basic: {
      id: 'supplierType.basic',
      defaultMessage: 'Premium'
    }
  }),

  emailAvailable: defineMessages({
    false: {
      id: 'No',
      defaultMessage: 'No'
    },
    true: {
      id: 'emailAvailable.Yes',
      defaultMessage: 'Yes'
    }
  }),

  supplierUpdated: defineMessages({
    false: {
      id: 'No',
      defaultMessage: 'No'
    },
    true: {
      id: 'supplierUpdated.Yes',
      defaultMessage: 'Yes'
    }
  }),

  diversity: defineMessages({
    diversity: {
      id: 'diversity.diversity',
      defaultMessage: 'Diversity'
    }
  }),

  security: defineMessages({
    security: {
      id: 'security.security',
      defaultMessage: 'Security'
    }
  }),

  quality: defineMessages({
    quality: {
      id: 'quality.quality',
      defaultMessage: 'Quality'
    }
  }),
  sustainability: defineMessages({
    sustainability: {
      id: 'sustainability.sustainability',
      defaultMessage: 'Sustainability'
    }
  }),
  food: defineMessages({
    food: {
      id: 'food.food',
      defaultMessage: 'Food'
    }
  }),
  internalCategory: defineMessages({
    unclassified: {
      id: 'internalCategory.unclassified',
      defaultMessage: 'Unclassified'
    },
    nonProcurable: {
      id: 'internalCategory.nonProcurable',
      defaultMessage: 'Non Procurable'
    },
    facilitiesAndRelatedServices: {
      id: 'internalCategory.facilitiesAndRelatedServices',
      defaultMessage: 'Facilities And Related Services'
    },
    professionalServices: {
      id: 'internalCategory.professionalServices',
      defaultMessage: 'Professional Services'
    },
    marketingAndSales: {
      id: 'internalCategory.marketingAndSales',
      defaultMessage: 'Marketing and Sales'
    },
    globalEngineering: {
      id: 'internalCategory.globalEngineering',
      defaultMessage: 'Global Engineering'
    },
    itTelecom: {
      id: 'internalCategory.itTelecom',
      defaultMessage: 'IT/ Telecom'
    },
    humanResources: {
      id: 'internalCategory.humanResources',
      defaultMessage: 'Human Resources'
    },
    rDClinical: {
      id: 'internalCategory.rDClinical',
      defaultMessage: 'R&D Clinical'
    },
    businessTravel: {
      id: 'internalCategory.businessTravel',
      defaultMessage: 'Business Travel'
    },
    rDPreClinical: {
      id: 'internalCategory.rDPreClinical',
      defaultMessage: 'R&D Pre-Clinical'
    },
    rawMaterials: {
      id: 'internalCategory.rawMaterials',
      defaultMessage: 'Raw Materials'
    },
    technicalDevelopment: {
      id: 'internalCategory.technicalDevelopment',
      defaultMessage: 'Technical Development'
    },
    worldWideMedical: {
      id: 'internalCategory.worldWideMedical',
      defaultMessage: 'World Wide Medical'
    },
    distributionAndLogistics: {
      id: 'internalCategory.distributionAndLogistics',
      defaultMessage: 'Distribution and Logistics'
    },
    contractManufacturing: {
      id: 'internalCategory.contractManufacturing',
      defaultMessage: 'Contract Manufacturing'
    },
    patientAssistanceServices: {
      id: 'internalCategory.patientAssistanceServices',
      defaultMessage: 'Patient Assistance Services'
    },
    creditCard: {
      id: 'internalCategory.creditCard',
      defaultMessage: 'Credit Card'
    },
    security: {
      id: 'internalCategory.security',
      defaultMessage: 'Security'
    },
    '4PL': {
      id: 'internalCategory.4PL',
      defaultMessage: '4PL'
    },
    apparel: {
      id: 'internalCategory.apparel',
      defaultMessage: 'Apparel'
    },
    buildingMaintenance: {
      id: 'internalCategory.buildingMaintenance',
      defaultMessage: 'Building Maintenance'
    },
    claims: {
      id: 'internalCategory.claims',
      defaultMessage: 'Claims'
    },
    construction: {
      id: 'internalCategory.construction',
      defaultMessage: 'Construction'
    },
    consultant: {
      id: 'internalCategory.consultant',
      defaultMessage: 'Consultant'
    },
    contractors: {
      id: 'internalCategory.contractors',
      defaultMessage: 'Contractors'
    },
    donations: {
      id: 'internalCategory.donations',
      defaultMessage: 'Donations'
    },
    drugScreen: {
      id: 'internalCategory.drugScreen',
      defaultMessage: 'Drug Screen'
    },
    education: {
      id: 'internalCategory.education',
      defaultMessage: 'Education'
    },
    electrical: {
      id: 'internalCategory.electrical',
      defaultMessage: 'Electrical'
    },
    fence: {
      id: 'internalCategory.fence',
      defaultMessage: 'Fence'
    },
    financial: {
      id: 'internalCategory.financial',
      defaultMessage: 'Financial'
    },
    financialServices: {
      id: 'internalCategory.financialServices',
      defaultMessage: 'Financial Services'
    },
    firstAid: {
      id: 'internalCategory.firstAid',
      defaultMessage: 'First Aid'
    },
    foodAndBeverage: {
      id: 'internalCategory.foodAndBeverage',
      defaultMessage: 'Food and Beverage'
    },
    forklift: {
      id: 'internalCategory.forklift',
      defaultMessage: 'Forklift'
    },
    freightAndPostage: {
      id: 'internalCategory.freightAndPostage',
      defaultMessage: 'Freight and Postage'
    },
    hardware: {
      id: 'internalCategory.hardware',
      defaultMessage: 'Hardware'
    },
    hotel: {
      id: 'internalCategory.hotel',
      defaultMessage: 'Hotel'
    },
    janitorialService: {
      id: 'internalCategory.janitorialService',
      defaultMessage: 'Janitorial Service'
    },
    landscaping: {
      id: 'internalCategory.landscaping',
      defaultMessage: 'Landscaping'
    },
    legal: {
      id: 'internalCategory.legal',
      defaultMessage: 'Legal'
    },
    license: {
      id: 'internalCategory.license',
      defaultMessage: 'License'
    },
    lighting: {
      id: 'internalCategory.lighting',
      defaultMessage: 'Lighting'
    },
    locksmith: {
      id: 'internalCategory.locksmith',
      defaultMessage: 'Locksmith'
    },
    mHR: {
      id: 'internalCategory.mHR',
      defaultMessage: 'MHR'
    },
    marketing: {
      id: 'internalCategory.marketing',
      defaultMessage: 'Marketing'
    },
    packagingSupplies: {
      id: 'internalCategory.packagingSupplies',
      defaultMessage: 'Packaging Supplies'
    }
  }),
  certificationExpiredExcluded: defineMessages({
    diversity: {
      id: 'diversity.IncludeExpiredAndPotential',
      defaultMessage: 'Include Expired and Potential'
    },
    quality: {
      id: 'quality.IncludeExpiredAndPotential',
      defaultMessage: 'Include Expired and Potential'
    },
    security: {
      id: 'security.IncludeExpiredAndPotential',
      defaultMessage: 'Include Expired and Potential'
    },
    sustainability: {
      id: 'sustainability.IncludeExpiredAndPotential',
      defaultMessage: 'Include Expired and Potential'
    },
    food: {
      id: 'food.IncludeExpiredAndPotential',
      defaultMessage: 'Include Expired and Potential'
    }
  })
}

export const alertCodes = defineMessages({
  jointVenture: {
    id: 'alertCodes.jointVenture',
    defaultMessage: 'Joint Venture'
  },
  dunsMismatch: {
    id: 'alertCodes.dunsMismatch',
    defaultMessage: 'DUNS Mismatch'
  },
  smallPrimaryOnly: {
    id: 'alertCodes.smallPrimaryOnly',
    defaultMessage: 'Small only for Primary'
  },
  smallNotPrimary: {
    id: 'alertCodes.smallNotPrimary',
    defaultMessage: 'Not Small for Primary'
  },
  smallSubUnit: {
    id: 'alertCodes.smallSubunit',
    defaultMessage: 'Small Subunit'
  },
  nameMismatch: {
    id: 'alertCodes.nameMismatch',
    defaultMessage: 'Name Mismatch'
  },
  notMatchQualificationRulesAgency: {
    id: 'alertCodes.notMatchQualificationRulesAgency',
    defaultMessage: 'Not a Qualifying Agency'
  },
  expired: {
    id: 'alertCodes.expired',
    defaultMessage: 'Expired'
  },
  noAttachmentForGivenAgency: {
    id: 'alertCodes.noAttachmentForGivenAgency',
    defaultMessage: 'Missing Qualifying Attachment'
  },
  requireForSelfCertified: {
    id: 'alertCodes.requireForSelfCertified',
    defaultMessage: 'Require For Self Certified'
  },
  requireNotVerifiedByTealbook: {
    id: 'alertCodes.requireNotVerifiedByTealbook',
    defaultMessage: 'Require Not Verified By TealBook'
  },
  requireAttestationByGivenAgency: {
    id: 'alertCodes.requireAttestationByGivenAgency',
    defaultMessage: 'Missing Qualifying Attestation'
  },
  certOffline: {
    id: 'alertCodes.certOffline1',
    defaultMessage: 'Certificate Offline'
  },
  unknownExpiry: {
    id: 'alertCodes.unknownExpiry',
    defaultMessage: 'Expiry Unknown'
  },
  manualDiverse: {
    id: 'alertCodes.manualDiverse',
    defaultMessage: 'Manual Diverse'
  },
  nonUnique: {
    id: 'alertCodes.nonUnique',
    defaultMessage: 'Not Unique'
  },
  nonUniqueMismatch: {
    id: 'alertCodes.nonUniqueMismatch1',
    defaultMessage: 'Not Unique and Mismatch'
  },
  privateProfile: {
    id: 'alertCodes.privateProfile',
    defaultMessage: 'Private Profile'
  },
  expiryUncertain: {
    id: 'alertCodes.expiryUncertain',
    defaultMessage: 'Expiry Uncertain'
  },
  smallNoNAICS: {
    id: 'alertCodes.smallNoNAICS',
    defaultMessage: 'Certified as Small without NAICS'
  },
  invalidatedByColleague: {
    id: 'alertCodes.invalidatedByColleague',
    defaultMessage: 'Invalidated by Colleague'
  }
})
export const alertCodesDescriptions = defineMessages({
  jointVenture: {
    id: 'alertCodes.desc.jointVenture',
    defaultMessage:
      'Supplier on the certificate is a joint venture, so it may have been mapped to one of the parent organizations and should be indiviually reviewed.'
  },
  dunsMismatch: {
    id: 'alertCodes.desc.dunsMismatch',
    defaultMessage:
      'The DUNS number on the certificate does not match the DUNS number on the supplier profile. This may indicate either a problem with the mapping or that the DUNS number on the supplier is incorrect.'
  },
  smallPrimaryOnly: {
    id: 'alertCodes.desc.smallPrimaryOnly',
    defaultMessage:
      'The supplier is only marked as small for its primary business, not for other lines of business which it provides.'
  },
  smallNotPrimary: {
    id: 'alertCodes.desc.smallNotPrimary',
    defaultMessage:
      'The supplier is not small for its primary line of business, so is unlikely to qualify as small.'
  },
  smallSubUnit: {
    id: 'alertCodes.desc.smallSubUnit',
    defaultMessage:
      'The supplier on the certificate appears to be a subunit or division of a larger company. '
  },
  nameMismatch: {
    id: 'alertCodes.desc.nameMismatch',
    defaultMessage:
      'The name of the supplier on the certificate does not appear to match the name of the supplier on the profile.'
  },
  notMatchQualificationRulesAgency: {
    id: 'alertCodes.desc.notMatchQualificationRulesAgency',
    defaultMessage:
      'Agency does not match the list of qualified agencies. Only applied on the Diversity report based on the qualification rules set by the user.'
  },
  expired: {
    id: 'alertCodes.desc.expired',
    defaultMessage: 'Certificate has expired.'
  },
  noAttachmentForGivenAgency: {
    id: 'alertCodes.desc.noAttachmentForGivenAgency',
    defaultMessage:
      'No attachment was provided for the certificate, but the qualification rules for the user require one.'
  },
  requireForSelfCertified: {
    id: 'alertCodes.requireForSelfCertified1',
    defaultMessage:
      'Qualification rules require attestation by the supplier for Self Certification but none was provided.'
  },
  requireNotVerifiedByTealbook: {
    id: 'alertCodes.requireNotVerifiedByTealbook1',
    defaultMessage:
      'Qualification rules require attestation by the supplier for when Not Verified By TealBook but none was provided.'
  },
  requireAttestationByGivenAgency: {
    id: 'alertCodes.desc.requireAttestationByGivenAgency',
    defaultMessage:
      'Qualification rules require attestation by the supplier for the selected agency but none was provided.'
  },
  certOffline: {
    id: 'alertCodes.desc.certOffline',
    defaultMessage:
      'The certificate is no longer available online for review at the certifying agency.'
  },
  unknownExpiry: {
    id: 'alertCodes.desc.unknownExpiry',
    defaultMessage:
      'No expiry date provided in the certificate. All certificates should have an expiry date.'
  },
  manualDiverse: {
    id: 'alertCodes.manualDiverse',
    defaultMessage: 'Manual Diverse'
  },
  nonUnique: {
    id: 'alertCodes.desc.nonUnique',
    defaultMessage:
      'Supplier has more than one certificate of a given type from a single source. This may indicate a mismapping of multiple suppliers into one profile.'
  },
  nonUniqueMismatch: {
    id: 'alertCodes.desc.nonUniqueMismatch',
    defaultMessage:
      'Supplier has more than one certificate of a given type from a single source and they do not agree on the diversity classification for the supplier. This may indicate a mismapping of multiple suppliers into one profile.'
  },
  privateProfile: {
    id: 'alertCodes.desc.privateProfile',
    defaultMessage:
      'The supplier has chosen not to share the details of its profile on the certifying agency site.'
  },
  expiryUncertain: {
    id: 'alertCodes.desc.expiryUncertain',
    defaultMessage:
      'The supplier certificate expiry does not match expectations according to standard loading rules.'
  },
  smallNoNAICS: {
    id: 'alertCodes.desc.smallNoNAICS',
    defaultMessage:
      "Self certified as a small business using a provider that didn't require NAICS code verification."
  },
  invalidatedByColleague: {
    id: 'alertCodes.desc.invalidatedByColleague',
    defaultMessage: 'Colleague has invalidated this certificate.'
  }
})
