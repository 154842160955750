import { fromJS } from 'immutable'

export const isLoading = state =>
  state.getIn(['supplier', 'stats', 'isLoading'])
export const getStats = state =>
  state.getIn(['supplier', 'stats']) || fromJS({})

export const getProfileStats = state => {
  const search = state.getIn(['supplier', 'stats', 'search'])
  const viewDetails =
    search && search.filter(stat => stat.get('label') === 'viewDetails').get(0)

  return (
    viewDetails && {
      views: viewDetails.get('numViews'),
      lastView: viewDetails.getIn(['recentDate', 'value'])
    }
  )
}

export const getSearchStats = state => {
  const search = state.getIn(['supplier', 'stats', 'search'])
  const searchResult =
    search &&
    search.filter(stat => stat.get('label') === 'searchResultQuery').get(0)

  return (
    searchResult && {
      views: searchResult.get('numViews'),
      lastView: searchResult.getIn(['recentDate', 'value']),
      medianPlacement: searchResult.get('medianIdx') + 1,
      topPlacement: searchResult.get('bestIdx') + 1
    }
  )
}

export const getTermsQuery = state =>
  state.getIn(['supplier', 'stats', 'terms', 'query'])
export const getTermsQueryMatch = state =>
  state.getIn(['supplier', 'stats', 'terms', 'queryMatch'])
export const getTermsField = (state, fieldName) => {
  const termsField = state.getIn(['supplier', 'stats', 'terms', fieldName])
  return (
    termsField &&
    termsField.sort((offerA, offerB) => {
      return offerB.get('count') - offerA.get('count')
    })
  )
}

export const getFollowerCount = state =>
  state.getIn(['supplier', 'stats', 'buyer', 'followerCount'])
export const getFollowers = state =>
  state.getIn(['supplier', 'stats', 'buyer', 'followers'])
export const getBuyerExisting = state =>
  state.getIn(['supplier', 'stats', 'buyer', 'existing'])
export const getBuyerPreferred = state =>
  state.getIn(['supplier', 'stats', 'buyer', 'preferred'])
export const getBuyerViewDetails = state =>
  state.getIn(['supplier', 'stats', 'buyer', 'viewDetails'])
export const getAppearanceDetails = state =>
  state.getIn(['supplier', 'stats', 'appearanceDetails'])
export const getConnectionCount = state =>
  state.getIn(['supplier', 'stats', 'buyer', 'connectionCount'])
export const getConnections = state =>
  state.getIn(['supplier', 'stats', 'buyer', 'connections'])
export const getClientUser = (state, clientId, userId) =>
  state.getIn([
    'supplier',
    'stats',
    'buyer',
    'connections',
    clientId,
    'users',
    userId
  ])
