import React from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes/paths'
import { FormattedMessage } from 'react-intl'
import createIsActiveFunc from 'shared/utils/createIsActiveFunc'
import useUserAccess from 'shared/utils/useUserAccess'
import useIsFeatureEnabled from 'shared/utils/featureFlags/useIsFeatureEnabled'

const isTier2Active = createIsActiveFunc([
  paths.supplierListTier2,
  paths.spendOverviewTier2
])

type Props = {
  diversityReportEnabled: boolean
  isTealbot: boolean
}

const InsightNavBar = ({ diversityReportEnabled, isTealbot }: Props) => {
  const userAccess = useUserAccess()
  const supplierOutreachAccess = useIsFeatureEnabled('supplierOutreach')
  const reportSnapshot = useIsFeatureEnabled('reportSnapshot')
  const esgSurveyPhase2Access = useIsFeatureEnabled('ESGSurveyPhase2')
  return (
    <NavBar>
      <NavLink
        label={
          <FormattedMessage
            id='InsightNavBar.General'
            defaultMessage='General'
          />
        }
        to={paths.general}
      />
      <NavLink
        label={
          <FormattedMessage
            id='InsightNavBar.Consolidation'
            defaultMessage='Consolidation'
          />
        }
        to={paths.consolidation}
      />
      {userAccess.accessSupplierSpend && (
        <NavLink
          label={
            <FormattedMessage id='InsightNavBar.Spend' defaultMessage='Spend' />
          }
          to={paths.spend}
          exact
        />
      )}
      {userAccess.accessSupplierSpend &&
        diversityReportEnabled &&
        userAccess.accessDiversityReport && (
          <NavLink
            label={
              <FormattedMessage
                id='InsightNavBar.DiversityReport'
                defaultMessage='Diversity Report'
              />
            }
            to={paths.spendDiverse}
          />
        )}
      {userAccess.accessSupplierSpend &&
        diversityReportEnabled &&
        userAccess.accessTier2 && (
          <NavLink
            label={
              <FormattedMessage
                id='InsightNavBar.Tier2Diversity'
                defaultMessage='Tier 2 Diversity'
              />
            }
            to={paths.supplierListTier2}
            isActive={isTier2Active}
          />
        )}
      {diversityReportEnabled && reportSnapshot && (
        <NavLink
          label={
            <FormattedMessage
              id='InsightNavBar.SaveReports'
              defaultMessage='Saved Reports'
            />
          }
          to={paths.reportSnapshot}
        />
      )}
      {isTealbot && (
        <NavLink
          label={
            <FormattedMessage
              id='InsightNavBar.InternalStats'
              defaultMessage='Internal Stats'
            />
          }
          to={paths.internalStats}
        />
      )}
      {supplierOutreachAccess && (
        <NavLink
          label={
            <FormattedMessage
              id='InsightNavBar.SupplierOutreach'
              defaultMessage='Supplier Outreach'
            />
          }
          to={paths.supplierOutreach}
        />
      )}
      {esgSurveyPhase2Access && (
        <NavLink
          label={
            <FormattedMessage
              id='InsightNavBar.SustainabilityReport'
              defaultMessage='Sustainability Report'
            />
          }
          to={paths.sustainabilityReport}
        />
      )}
    </NavBar>
  )
}

export default InsightNavBar
