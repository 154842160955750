import React, { Component } from 'react'
import Avatar from '../../../shared/components/Avatar'
import ListItem from '../../../shared/components/List/ListItem'
import EnterMessageForm from '../../containers/EnterMessageForm'
import ConversationWithUser from '../../containers/ConversationWithUser'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { FormattedMessage } from 'react-intl'
import Link from 'shared/components/Link'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import createStyles from '@material-ui/core/styles/createStyles'
import BlockIcon from '@material-ui/icons/Block'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

const styles = (theme: Theme) =>
  createStyles({
    heading: {
      position: 'sticky',
      top: 50,
      [theme.breakpoints.down('sm')]: {
        position: 'sticky',
        top: 0
      }
    },
    messageInputFixed: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#FFFFFF',
        borderStyle: 'solid',
        borderWidth: 'thin',
        borderColor: 'lightGray',
        width: '100%'
      }
    }
  })

type Props = {
  messageTopicId: string
  toFirstName: string
  toLastName: string
  toEmail: string
  toProfilePictureUrl: string
  toTitle?: string
  toOrg?: string
  toUserUrl: string
  toOrgUrl?: string
  onClickCancel: (ev: React.MouseEvent) => void
  userIsBlocked?: boolean
  dialog?: boolean
} & WithStyles<typeof styles>

class MessageBox extends Component<Props> {
  render() {
    const {
      messageTopicId,
      toFirstName,
      toLastName,
      toEmail,
      toProfilePictureUrl,
      toTitle,
      toOrg,
      toUserUrl,
      toOrgUrl,
      userIsBlocked,
      onClickCancel,
      dialog
    } = this.props

    return (
      <>
        <div
          className={`bg-white ba b--black-10 z-1 ${this.props.classes.heading}`}
        >
          {(toFirstName || toFirstName || toEmail) && (
            <ListItem
              className='pa3'
              mini
              leftAvatar={
                <Link to={toUserUrl}>
                  <Avatar
                    url={toProfilePictureUrl}
                    className='w2'
                    name={`${toFirstName || ''} ${toLastName || ''}`}
                  />
                </Link>
              }
              primaryText={
                <Link to={toUserUrl} className='flex items-center'>
                  {toFirstName || toLastName
                    ? `${toFirstName} ${toLastName}`
                    : toEmail}
                  {userIsBlocked && (
                    <BlockIcon style={{ fontSize: '16px' }} className='ml1' />
                  )}
                </Link>
              }
              secondaryText={
                <>
                  {toTitle}{' '}
                  {toOrg && (
                    <div className='f7'>
                      <FormattedMessage
                        id='MessageBox.At'
                        defaultMessage='at'
                      />{' '}
                      {toOrgUrl ? <Link to={toOrgUrl}>{toOrg}</Link> : toOrg}
                    </div>
                  )}
                  {userIsBlocked && (
                    <div className='mt1'>
                      <FormattedMessage
                        id='MessageBox.userIsBlocked'
                        defaultMessage='User has been blocked.'
                      />
                    </div>
                  )}
                </>
              }
              rightButton={
                <IconButton onClick={onClickCancel}>
                  <CloseIcon />
                </IconButton>
              }
            />
          )}
        </div>
        <div className='bg-white br1-ns ba-ns b--black-10 mb5 pb5 mb0-ns pb0-ns'>
          <ConversationWithUser dialog={dialog} />
          <div className={`pa3-5 ${this.props.classes.messageInputFixed}`}>
            <FormattedMessage
              id='MessageBox.TypeAMessage'
              defaultMessage='Type a Message'
            >
              {message => (
                <EnterMessageForm
                  inputPlaceholder={message}
                  noSubmitButton
                  messageTopicId={messageTopicId}
                  ignorePrompt={!messageTopicId}
                  disabled={userIsBlocked}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(MessageBox)
