import createAddCommentForm from 'shared/utils/createAddCommentForm'
import { ADD_COMMENT } from 'shared/actionTypes'
import { FunctionComponent } from 'react'

type Props = {
  initialValues: {
    ancestorId: string
    isPrivate: boolean
    messageKind: string
  }
  enablePublicPrivate: boolean
}
const AddCommentFormContainer: FunctionComponent<Props> = createAddCommentForm({
  formName: 'accounts/addCommentAboutUser',
  submitFormActionType: ADD_COMMENT
})

export default AddCommentFormContainer
