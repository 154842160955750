import React, { Component, ReactNode, createRef } from 'react'
import { Link } from 'react-router-dom'
import AppLogoContainer from '../../containers/AppLogoContainer'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/NavigateBefore'
import { injectIntl, defineMessages, IntlShape } from 'react-intl'
import { RouteComponentProps } from 'react-router'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import buyerPaths from 'buyer/routes/paths'
import supplierPaths from 'supplier/routes/paths'
import dataPortalPaths from 'data-portal/routes/paths'
import adminPaths from 'admin/routes/paths'
import jobsPaths from 'jobs/routes'
import { FormattedMessage } from 'react-intl'
import Menu from '@material-ui/core/Menu'
import UserNavItem from '../UserNav/UserNavItem'
import TealbookSquareLogo from 'shared/assets/images/tealbook-logo.png'
import Logo from 'shared/components/OrgLogo'
import { withStyles } from '@material-ui/styles'

const messages = defineMessages({
  back: {
    id: 'AppBar.Back',
    defaultMessage: 'Back'
  },
  suppliers: {
    id: 'AppBar.Suppliers',
    defaultMessage: 'Suppliers'
  },
  tealboard: {
    id: 'AppBar.News',
    defaultMessage: 'News'
  },
  messages: {
    id: 'AppBar.Messages',
    defaultMessage: 'Messages'
  },
  communities: {
    id: 'AppBar.Communities',
    defaultMessage: 'Communities'
  },
  user: {
    id: 'AppBar.User',
    defaultMessage: 'User'
  },
  supplier: {
    id: 'AppBar.Supplier',
    defaultMessage: 'Supplier'
  },
  company: {
    id: 'AppBar.Company',
    defaultMessage: 'Company'
  },
  insight: {
    id: 'AppBar.Insight',
    defaultMessage: 'Insight'
  },
  contacts: {
    id: 'AppBar.Contacts',
    defaultMessage: 'Contacts'
  }
})

/**
 * Creates a standard html AppBar, but with a tealbook logo linked to root on the far left and an optional userNav on the far right (custom styling included)
 */

type Props = {
  /** Adds the option to pass in a navigation object (eg dropdown menu) */
  userNav?: ReactNode
  /** If true the AppBar will display its children and the userNav */
  loggedIn: boolean
  isTealbot?: boolean
  buyerUrlQueryString: string
  /** Home url to navigate to when user clicks on the logo */
  homeUrl: string
  pathname?: string
  width: string
  redirectFrom: string
  showBuyerUrl: boolean
  showSupplierUrl: boolean
  showDataPortalUrl: boolean
  showAdminUrl: boolean
  showClickWorkerUrl?: boolean
  hideMenuNav?: boolean
  classes?: any
  intl: IntlShape
} & RouteComponentProps

type State = {
  openNavMenu: boolean
  isMenuOpenedWithKeyboard: boolean
}

const styles = theme => ({
  focusVisible: {
    color: '#fdfdfc',
    backgroundColor: '#555555'
  }
})

export class AppBar extends Component<Props, State> {
  static defaultProps = {
    homeUrl: '/'
  }

  menuNavElement = createRef<HTMLElement>()

  state: State = {
    openNavMenu: false,
    isMenuOpenedWithKeyboard: false
  }

  toggleNavMenu = () =>
    this.setState({
      openNavMenu: !this.state.openNavMenu,
      isMenuOpenedWithKeyboard: false
    })

  toggleNavMenuKeydown = e => {
    if (e.key === 'Enter') {
      this.setState({
        isMenuOpenedWithKeyboard: !this.state.openNavMenu,
        openNavMenu: !this.state.openNavMenu
      })
    }
  }

  handleListKeyDown = e => {
    if (e.key === 'Tab') {
      e.preventDefault()
      this.setState({
        openNavMenu: !this.state.openNavMenu
      })
    } else if (e.key === 'Escape') {
      this.setState({
        openNavMenu: !this.state.openNavMenu
      })
    } else if (
      (e.key === 'ArrowDown' || e.key === 'ArrowUp') &&
      !this.state.isMenuOpenedWithKeyboard
    ) {
      this.setState({
        isMenuOpenedWithKeyboard: true
      })
    }
  }

  isBackButtonVisible = () => {
    const { pathname, width } = this.props

    if (
      (width === 'xs' || width === 'sm') &&
      pathname &&
      !(
        (!pathname.includes('vets') && pathname.endsWith('/search')) ||
        pathname.endsWith('/vets') ||
        pathname.endsWith('/tealboard') ||
        pathname.endsWith('/insight') ||
        pathname.endsWith('/general') ||
        pathname.endsWith('/spend') ||
        pathname.endsWith('/messages') ||
        pathname.endsWith('/communities') ||
        pathname.endsWith('/users') ||
        pathname.endsWith('/suppliers') ||
        pathname.endsWith('/company') ||
        pathname.endsWith('/people')
      )
    ) {
      return true
    }
    return false
  }

  handleBackClick = () => {
    const { history, redirectFrom } = this.props
    if (redirectFrom) {
      history.push(redirectFrom)
    } else {
      history.goBack()
    }
  }

  renderTitle() {
    const { pathname, intl } = this.props
    let title
    if (!pathname) {
      return null
    }

    if (pathname.includes('vets')) {
      title = 'RFI'
    } else if (pathname.includes('search')) {
      title = intl.formatMessage(messages.suppliers)
    } else if (pathname.includes('tealboard')) {
      title = intl.formatMessage(messages.tealboard)
    } else if (pathname.includes('messages')) {
      title = intl.formatMessage(messages.messages)
    } else if (
      pathname.includes('communities') ||
      pathname.includes('target')
    ) {
      title = intl.formatMessage(messages.communities)
    } else if (pathname.includes('users')) {
      title = intl.formatMessage(messages.user)
    } else if (pathname.includes('suppliers')) {
      title = intl.formatMessage(messages.supplier)
    } else if (pathname.includes('company')) {
      title = intl.formatMessage(messages.company)
    } else if (pathname.includes('insight')) {
      title = intl.formatMessage(messages.insight)
    } else if (pathname.includes('people')) {
      title = intl.formatMessage(messages.contacts)
    }

    return <div className='flex-auto tc truncate'>{title}</div>
  }

  render() {
    const {
      loggedIn,
      isTealbot,
      userNav,
      homeUrl,
      width,
      showBuyerUrl,
      showSupplierUrl,
      showDataPortalUrl,
      showAdminUrl,
      showClickWorkerUrl,
      hideMenuNav,
      buyerUrlQueryString,
      intl,
      classes
    } = this.props

    const showMenuNav =
      !hideMenuNav &&
      [showBuyerUrl, showSupplierUrl, showDataPortalUrl, showAdminUrl].filter(
        url => !!url
      ).length > 1 &&
      !this.isBackButtonVisible()

    return (
      <>
        <nav
          className='bb b--black-10 bg-white fixed top-0 w-100'
          style={{
            zIndex: 100,
            backgroundColor: isTealbot ? '#DCF6F4' : '#FFFFFF'
          }}
        >
          <div
            className='flex items-center mw8 center pv2 ph1 ph2-ns'
            style={{ height: '50px' }}
          >
            {showMenuNav && (
              <div className='ml2 ml0-ns mr2'>
                <IconButton
                  id='toggleNavMenu'
                  title='Toggle Menu'
                  onClick={this.toggleNavMenu}
                  onKeyDown={this.toggleNavMenuKeydown}
                  buttonRef={this.menuNavElement}
                  aria-expanded={this.state.openNavMenu}
                  focusVisibleClassName={classes.focusVisible}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            )}

            <Hidden smDown>
              <Link
                to={homeUrl}
                className='no-underline dim mr3'
                aria-label='Tealbook Logo'
                title='Home Page Link'
              >
                <AppLogoContainer />
              </Link>
            </Hidden>
            {this.isBackButtonVisible() && (
              <Button
                style={{ padding: 0, minWidth: 'auto', minHeight: 'auto' }}
                onClick={this.handleBackClick}
              >
                <ArrowBack />
                {intl.formatMessage(messages.back)}
              </Button>
            )}
            {(width === 'xs' || width === 'sm') && (
              <Link
                to={homeUrl}
                className=' tc truncate'
                aria-label='Tealbook Logo'
                title='Home Page Link'
                style={{ margin: 'auto' }}
              >
                <Logo url={TealbookSquareLogo} square width={'30px'} />
              </Link>
            )}
            {loggedIn && (
              <>
                {this.props.children}

                {userNav && (
                  <div className='no-underline pointer'>{userNav}</div>
                )}
              </>
            )}
          </div>
        </nav>
        <div style={{ height: 50 }} />

        {showMenuNav && (
          <Menu
            open={this.state.openNavMenu}
            onClose={this.toggleNavMenu}
            anchorEl={this.menuNavElement.current}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            getContentAnchorEl={null}
            onKeyDown={this.handleListKeyDown}
          >
            {showBuyerUrl && (
              <UserNavItem
                linkTo={`${buyerPaths.search}${buyerUrlQueryString}`}
                onClick={this.toggleNavMenu}
                label={
                  <FormattedMessage
                    id='AppBar.buyerApp'
                    defaultMessage='Buyer'
                  />
                }
                focus={this.state.isMenuOpenedWithKeyboard}
              />
            )}

            {showSupplierUrl && (
              <UserNavItem
                linkTo={supplierPaths.company}
                onClick={this.toggleNavMenu}
                label={
                  <FormattedMessage
                    id='AppBar.supplierApp'
                    defaultMessage='Supplier'
                  />
                }
                focus={!showBuyerUrl && this.state.isMenuOpenedWithKeyboard}
              />
            )}

            {showDataPortalUrl && (
              <UserNavItem
                linkTo={dataPortalPaths.basePath}
                onClick={this.toggleNavMenu}
                label={
                  <FormattedMessage
                    id='AppBar.concierge'
                    defaultMessage='Concierge'
                  />
                }
                focus={
                  !showBuyerUrl &&
                  !showSupplierUrl &&
                  this.state.isMenuOpenedWithKeyboard
                }
              />
            )}

            {showAdminUrl && (
              <UserNavItem
                linkTo={adminPaths.users}
                onClick={this.toggleNavMenu}
                label={
                  <FormattedMessage
                    id='AppBar.adminApp'
                    defaultMessage='Admin'
                  />
                }
                focus={
                  !showBuyerUrl &&
                  !showSupplierUrl &&
                  !showDataPortalUrl &&
                  this.state.isMenuOpenedWithKeyboard
                }
              />
            )}

            {showClickWorkerUrl && (
              <UserNavItem
                linkTo={jobsPaths.basePath}
                onClick={this.toggleNavMenu}
                label={
                  <FormattedMessage
                    id='AppBar.ClickWorker'
                    defaultMessage='Click Worker'
                  />
                }
                focus={
                  !showBuyerUrl &&
                  !showSupplierUrl &&
                  !showDataPortalUrl &&
                  showAdminUrl &&
                  this.state.isMenuOpenedWithKeyboard
                }
              />
            )}
          </Menu>
        )}
      </>
    )
  }
}

export default withStyles(styles)(injectIntl(AppBar))
