import cards from './cardsReducer'
import relationships from './relationshipsReducer'
import supplierRelationships from './supplierRelationshipsReducer'
import userMetrics from './userMetricsReducer'
import contactsSearch from './contactsSearchReducer'

const rootReducer = {
  cards,
  relationships,
  userMetrics,
  supplierRelationships,
  contactsSearch
}

export default rootReducer
