import { select, call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import profileSelectors from '../../selectors/profileSelectors'
import relationships from 'shared/utils/api/relationships'

function* updateRelationshipSaga(action) {
  const relationshipChanges = action.payload.toJS()
  const relationship = yield select(profileSelectors.getRelationship)

  let newRelationship
  if (!relationship) {
    const currentSupplierId = yield select(profileSelectors.getDataField, 'id')
    newRelationship = yield call(
      relationships.create,
      currentSupplierId,
      relationshipChanges
    )
  } else {
    yield call(
      relationships.update,
      relationship.get('id'),
      relationshipChanges
    )
  }

  return yield {
    newRelationship,
    relationshipId: relationship && relationship.get('id'),
    relationshipChanges
  }
}

export default createApiRequestSaga(updateRelationshipSaga)
