import { defaultMemoize } from 'reselect'
import { List, RecordOf } from 'immutable'
import { Overview } from './SpendPage'

const sortSpendData = defaultMemoize(
  (
    data: List<RecordOf<Overview>>,
    sortBy: keyof Overview = 'totalAmount',
    sortDirection: 'DESC' | 'ASC' = 'DESC'
  ) => {
    return data.sort((dataA: RecordOf<Overview>, dataB: RecordOf<Overview>) => {
      const valueA =
        sortBy === 'category' || sortBy === 'country' || sortBy === 'spendgroup'
          ? dataA.get(sortBy) && dataA.get(sortBy).toLowerCase()
          : dataA.get(sortBy)
      const valueB =
        sortBy === 'category' || sortBy === 'country' || sortBy === 'spendgroup'
          ? dataB.get(sortBy) && dataB.get(sortBy).toLowerCase()
          : dataB.get(sortBy)

      if (!valueA || !valueB) {
        return 1
      }

      return sortDirection === 'ASC'
        ? valueA < valueB
          ? -1
          : 1
        : valueA > valueB
        ? -1
        : 1
    })
  }
)

export default sortSpendData
