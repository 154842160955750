import { call } from 'redux-saga/effects'
import insight from 'shared/utils/api/insight'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* loadCommunityInsightSaga(action) {
  const response = yield call(insight.getCommunityInsight, action.payload)
  return yield {
    communityId: action.payload,
    communityInsight: response
  }
}

export default createApiRequestSaga(loadCommunityInsightSaga)
