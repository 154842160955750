import { call, put, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import Immutable from 'immutable'
import users from 'shared/utils/api/users'
import vets from 'shared/utils/api/vets'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import createInviteUserActions from 'shared/utils/inviteUser/createActions'

const { cancelInvitingUser } = createInviteUserActions(
  'inviteVetSupplierContact'
)

//1 email: users.invite api calls email method and sends optional message

export function* addContactSendSaga(action) {
  const {
    orgUnitId,
    userId,
    user,
    sendMessage,
    message
  } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  let newUser
  if (!userId) {
    newUser = yield call(users.inviteUser, {
      user,
      orgUnitId,
      message: sendMessage ? message : undefined
    })
  }

  const vbs = yield select(
    currentVetSuppliersSelectors.getVetSupplierBySupplierId,
    orgUnitId
  )
  const vetBuyerSupplierId = vbs.getIn(['vetBS', 'id'])
  const sendToContacts = yield call(vets.addSupplierContact, {
    vetBuyerSupplierId,
    userId: userId || newUser.id
  })

  yield put(cancelInvitingUser())

  return yield {
    vetBuyerSupplierId,
    sendToContacts,
    newUser
  }
}

export default createApiRequestSaga(addContactSendSaga)
