import * as actionTypes from './actionTypes'
import { takeLatest, takeEvery, all, put, call } from 'redux-saga/effects'
import takeFirst from 'shared/utils/sagas/takeFirst'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import job from 'shared/utils/api/job'
import openJobSaga from './sagas/openJobSaga'
import submitAnswersSaga from './sagas/submitAnswersSaga'
import navToNextSaga from './sagas/navToNextSaga'
import reorderOrgsPositionSaga from './sagas/reorderOrgsPositionSaga'
import updateSowOrgSaga from './sagas/updateSowOrgSaga'
import addJobRowSaga from './sagas/addJobRowSaga'
import updateJobStatusSaga from './sagas/updateJobStatusSaga'

import { openJob } from './actions'

export default function* rootSaga() {
  yield all([
    takeLatest(
      actionTypes.GET_JOBS_LIST,
      createApiRequestSaga(job.getJobs, { notifyOnError: true })
    ),
    takeFirst(
      actionTypes.RELEASE_JOB,
      createApiRequestSaga(job.releaseJob, {
        notifyOnError: true,
        usePayload: true
      })
    ),
    takeFirst(
      actionTypes.RESERVE_JOB,
      createApiRequestSaga(
        function*(action) {
          const response = yield call(job.reserveJob, action.payload)
          yield put(openJob({ jobId: action.payload }))
          return yield response
        },
        { notifyOnError: true }
      )
    ),
    takeFirst(actionTypes.UPDATE_JOB_STATUS, updateJobStatusSaga),
    takeFirst(actionTypes.REJECT_SOW_ORG, reorderOrgsPositionSaga),
    takeFirst(actionTypes.REORDER_ORGS_POSITION, reorderOrgsPositionSaga),
    takeFirst(actionTypes.UPDATE_SOW_ORG, updateSowOrgSaga),
    takeFirst(actionTypes.ADD_JOB_ROW, addJobRowSaga),
    takeFirst(actionTypes.UPDATE_JOB_ANSWERS, submitAnswersSaga),
    takeFirst(
      actionTypes.LOAD_NEW_JOB,
      createApiRequestSaga(
        function*(action) {
          const { body } = action.payload.toJS()
          return yield call(job.loadJob, { body: JSON.parse(body) })
        },
        { notifyOnError: true }
      )
    ),
    takeFirst(
      actionTypes.DELETE_JOB,
      createApiRequestSaga(job.removeJob, {
        notifyOnError: true,
        usePayload: true,
        returnPayload: true
      })
    ),
    takeEvery(
      actionTypes.DELETE_JOB_RESULT,
      createApiRequestSaga(job.removeJobResult, {
        notifyOnError: true,
        usePayload: true
      })
    ),
    takeFirst(
      actionTypes.BULK_DELETE_RESULTS,
      createApiRequestSaga(
        function*(action) {
          const results = yield call(job.getJobResultsById, action.payload)
          const resultIds = results?.map(result => result.id)

          for (let i = 0; i < resultIds.length; i++) {
            yield call(job.removeJobResult, resultIds[i])
          }

          return yield action.payload
        },
        {
          notifyOnError: true
        }
      )
    ),
    takeFirst(actionTypes.OPEN_JOB, openJobSaga),
    takeFirst(actionTypes.NAV_TO_NEXT, navToNextSaga)
  ])
}
