import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import VetNavBar from '../../components/VetNavBar'

export const mapStateToProps = state => {
  const pathname = routingSelectors.getPathname(state).split('/')
  const page = pathname[pathname.length - 1]
  const vetBuyerSupplier = vetBuyerSupplierSelectors.getVetBuyerSupplier(state)
  const status =
    vetBuyerSupplier && vetBuyerSupplier.getIn(['collaboration', 'status'])
  const vet = vetBuyerSupplierSelectors.getVet(state)

  return {
    page,
    status,
    opened: !!vetBuyerSupplier.getIn(['collaboration', 'proposal', 'opened']),
    enableQandA: !!vet.getIn(['vetDetails', 'enableQandA'])
  }
}

export default withRouter(connect(mapStateToProps)(VetNavBar))
