import React, { Component, PureComponent } from 'react'
import { connect } from 'react-redux'
import InsightWidget from 'shared/components/InsightWidget'
import { FormattedMessage } from 'react-intl'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts'
import { List, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'

type PostGraph = RecordOf<{
  supplierPosts: string
  buyerPosts: string
  posts: string
}>

type Props = {
  x?: number
  y?: number
  payload?: { value: number }
  postGraph?: List<PostGraph>
}

class CustomizedAxisTick extends PureComponent<Props> {
  render() {
    const { x, y, payload } = this.props
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor='end'
          fill='#666'
          transform='rotate(-35)'
        >
          {payload && payload.value}
        </text>
      </g>
    )
  }
}

export class PostingStatContainer extends Component<Props> {
  formatYAxisUnit = (value: number) => value.toLocaleString()

  render() {
    const { postGraph } = this.props
    return (
      <InsightWidget
        title={
          <FormattedMessage
            id='PostingStatContainer.Posts'
            defaultMessage='Posts'
          />
        }
      >
        {postGraph && postGraph.size > 0 ? (
          <div className='tc'>
            <div className='flex justify-between mb3 items-center'>
              <span>
                <FormattedMessage
                  id='PostingStatContainer.CurrentMonth'
                  defaultMessage='This Month'
                />
              </span>
              <div className='flex flex-column pa2 bg-near-white'>
                <span className='f7 mid-gray'>
                  <FormattedMessage
                    id='PostingStatContainer.SupplierPosts'
                    defaultMessage='Supplier Posts'
                  />
                </span>
                <span style={{ color: '#F1CD5A' }}>
                  {postGraph.last<PostGraph>().get('supplierPosts')}
                </span>
              </div>
              <div className='flex flex-column pa2 bg-near-white'>
                <span className='f7 mid-gray'>
                  <FormattedMessage
                    id='PostingStatContainer.BuyerPosts'
                    defaultMessage='Buyer Posts'
                  />
                </span>
                <span style={{ color: '#0082BC' }}>
                  {postGraph.last<PostGraph>().get('buyerPosts')}
                </span>
              </div>
              <div className='flex flex-column pa2 bg-near-white'>
                <span className='f7 mid-gray'>
                  <FormattedMessage
                    id='PostingStatContainer.TotalPosts'
                    defaultMessage='Total Posts'
                  />
                </span>
                <span style={{ color: '#00B8A1' }}>
                  {postGraph.last<PostGraph>().get('posts')}
                </span>
              </div>
            </div>
            <div style={{ height: 220, fontSize: 11 }}>
              <ResponsiveContainer>
                <LineChart
                  data={postGraph.skipLast(1).toJS()}
                  margin={{
                    top: 0,
                    right: 20,
                    left: 0,
                    bottom: 20
                  }}
                >
                  <Legend verticalAlign='top' height={32} />
                  <XAxis
                    dataKey='month'
                    tick={<CustomizedAxisTick />}
                    axisLine={{
                      stroke: '#d7d7d7'
                    }}
                    interval={1}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={this.formatYAxisUnit}
                    label={{
                      value: '# of posts',
                      angle: -90,
                      position: 'insideLeft'
                    }}
                    allowDecimals={false}
                  />
                  <CartesianGrid stroke='#d7d7d7' vertical={false} />
                  <Line
                    dataKey='posts'
                    name='# of Total Posts'
                    stroke='#00B8A1'
                    strokeWidth={2}
                    dot={{
                      fill: 'white',
                      fillOpacity: 1,
                      stroke: '#00B8A1',
                      strokeWidth: 2,
                      r: 5
                    }}
                  />
                  <Line
                    dataKey='buyerPosts'
                    name='# of Buyer Posts'
                    stroke='#0082BC'
                    strokeWidth={2}
                    dot={{
                      fill: 'white',
                      fillOpacity: 1,
                      stroke: '#0082BC',
                      strokeWidth: 2,
                      r: 5
                    }}
                  />
                  <Line
                    dataKey='supplierPosts'
                    name='# of Supplier Posts'
                    stroke='#F1CD5A'
                    strokeWidth={2}
                    dot={{
                      fill: 'white',
                      fillOpacity: 1,
                      stroke: '#F1CD5A',
                      strokeWidth: 2,
                      r: 5
                    }}
                  />
                  <Tooltip />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className='tc pv3'>
            <FormattedMessage
              id='PostingStatContainer.NoPost'
              defaultMessage='No Post to the Community'
            />
          </div>
        )}
      </InsightWidget>
    )
  }
}

type ContainerProps = { communityId: string }
export default connect((state: RootState, props: ContainerProps) => ({
  postGraph: state.getIn([
    'buyer',
    'insights',
    'communityById',
    props.communityId,
    'postGraph'
  ])
}))(PostingStatContainer)
