import { select, call } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import companySelectors from '../../../shared/selectors/companySelectors'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import generateTealbookCertificate from 'shared/utils/generateTealbookCertificate'
import Immutable from 'immutable'
import moment from 'moment'
import Naics from 'shared/models/Naics'
import posts from 'shared/utils/api/posts'

export function* updateCertificationSaga(action) {
  const {
    supplierCertified,
    certAgency,
    certificateNumber,
    certExpiration,
    category,
    subCategory,
    cert,
    timeStamp,
    qualifiedNaics,
    certifyingLocationKey,
    primaryNaics,
    subTypes,
    diversityAgencyOther,
    ...rest
  } = Immutable.isImmutable(action.payload)
    ? action.payload.toJS()
    : action.payload

  const userId = yield select(sessionSelectors.getUserId)
  const company = yield select(companySelectors.getCompanyField, 'name')
  const supplierId = yield select(companySelectors.getCompanyField, 'id')
  const supplierData = yield select(
    companySelectors.getCompanyField,
    'supplier'
  )
  const locations = yield select(companySelectors.getValidLocations)
  const naics = yield select(
    companySelectors.getClassificationCodesBySchemaAsCodeList,
    'naics'
  )

  const certNaics =
    qualifiedNaics &&
    Object.keys(qualifiedNaics)
      .filter(code => qualifiedNaics[code])
      .map(code => ({
        code,
        title: Naics[code] ? Naics[code].defaultMessage : code,
        primary: code === primaryNaics
      }))

  const certifyingLocation = locations
    ?.find(location => location.get('location') === certifyingLocationKey)
    ?.toJS()

  let certTealbook = undefined
  let info = {}
  let companyInfo = {}
  if (
    category === 'diversity' &&
    certAgency === 'Self Certify' &&
    supplierCertified
  ) {
    const user = yield select(usersSelectors.getById, userId)
    certTealbook = yield generateTealbookCertificate({
      subCategory,
      company,
      signBy: `${user.get('firstName')} ${user.get('lastName')}`,
      signOn: new Date(),
      email: user.get('email'),
      title: user.get('title'),
      firstCertified: timeStamp && new Date(timeStamp),
      certNaics,
      certifyingLocation
    })

    companyInfo = {
      company: {
        name: company,
        location: certifyingLocation,
        websiteUrl: supplierData?.getIn(['links', 'websiteUrl']),
        contact: {
          name: `${user.get('firstName')} ${user.get('lastName')}`,
          email: user.get('email'),
          phone: user.get('officePhone')
        }
      }
    }
    if (certNaics && naics && naics.size > certNaics.length) {
      info = {
        info: {
          potential: false,
          alertCodes: ['primarySmallOnly']
        }
      }
    }
  }

  const result = yield call(supplier.setCertification, {
    supplierCertified: supplierCertified ? userId : '',
    supplierName: certAgency === 'Self Certify' ? company : undefined,
    lastModifiedTimeStamp: new Date().toISOString(),
    certAgency: diversityAgencyOther
      ? `Other: ${diversityAgencyOther.trim()}`
      : certAgency && certAgency.trim(),
    certificateNumber: certificateNumber && certificateNumber.trim(),
    certExpiration:
      certAgency !== 'Self Certify'
        ? typeof certExpiration === 'string'
          ? certExpiration
          : certExpiration?.toISOString()
        : moment()
            .add(1, 'year')
            .toISOString(),
    category,
    subCategory,
    timeStamp,
    cert: certTealbook ? certTealbook : cert,
    certifyingLocation:
      certAgency === 'Self Certify' ? certifyingLocation : undefined,
    certNaics:
      certAgency === 'Self Certify' &&
      subCategory === 'sbe' &&
      certifyingLocation &&
      certifyingLocation.components.countryAbbrev === 'US'
        ? certNaics && certNaics.map(({ code, primary }) => ({ code, primary }))
        : undefined,
    subTypes: typeof subTypes === 'string' ? [subTypes] : subTypes,
    diversityAgencyOther: diversityAgencyOther && diversityAgencyOther.trim(),
    ...companyInfo,
    ...info,
    ...rest
  })
  if (result && certAgency === 'Self Certify') {
    const community = ['Community-4973465121718272'] // Post auto published self certification posts to the News and Press Releases community.
    const postImageFile = null
    const title = `${company} Self Certifies as ${subCategory.toUpperCase()}`
    const body = `Tealbook is excited to announce that ${company} has completed self-certification as a ${subCategory.toUpperCase()}. Review their Profile to learn more about opportunities to work with ${company}. ${supplierId}`
    const tags = ['#selfcertification', '#diversity']
    const visibility = ['buyer']
    const anonymous = true
    const broadcast = false
    yield call(posts.create, {
      visibility,
      title,
      body,
      broadcast,
      community,
      postImageFile,
      tags,
      anonymous
    })
  }
  return yield result
}

export default createApiRequestSaga(updateCertificationSaga)
