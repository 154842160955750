import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { getFormSubmitErrors } from 'shared/reducers'
import { formValueSelector } from 'redux-form/immutable'
import companySelectors from '../../../shared/selectors/companySelectors'
import { SAVE_COMPANY_INFO } from '../../actionTypes'
import { stopEditingCompanyHeader, addDomain } from '../../actions'
import { EditCompanyProfileHeader } from '../../components/EditCompanyProfileHeader/EditCompanyProfileHeader'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { diff } from 'deep-object-diff'
import RootState from 'shared/models/RootState'
import { List, Map, RecordOf } from 'immutable'
import { InjectedFormProps } from 'redux-form'
import startCase from 'lodash.startcase'

type Props = {
  errorMessage: string
  domainsOptions: List<RecordOf<{ value: string; label: string }>>
  initialValues: Map<string, any>
  formDomainsValue: List<string>
  website: string
  isTealbot: boolean
  flagCountryList?: List<
    RecordOf<{
      country: string
      status: boolean
    }>
  >
  sanctionedCompany: List<string>
  onCancel: () => void
  saveCompanyInfo: (change: any) => void
  addDomain: (newDomain: string) => void
  language: string
} & InjectedFormProps

export const mergeArrayDiff = (changedValues, formValues, fieldName) => {
  const [fieldName1, fieldName2] = fieldName.split('.')
  if (
    !changedValues[fieldName1] ||
    (fieldName2 && !changedValues[fieldName1][fieldName2])
  ) {
    return changedValues
  }

  if (!fieldName2) {
    return {
      ...changedValues,
      [fieldName1]: formValues[fieldName1]
    }
  } else {
    return {
      ...changedValues,
      [fieldName1]: {
        ...changedValues[fieldName1],
        [fieldName2]: formValues[fieldName1][fieldName2]
      }
    }
  }
}

export class EditCompanyProfileHeaderContainer extends Component<Props> {
  triggerSubmit = fValues => {
    const { initialValues, saveCompanyInfo } = this.props
    const formValues = fValues.toJS()

    let changedValues: {
      alternativeNames?: Array<string>
      domains?: Array<string>
      description?: Map<string, string>
      status?: string
      supplier?: {
        websiteWarning?: string
        bannedCompanyIds?: Array<string>
        flagCountry?: Array<{ country: string; status: boolean }>
      }
    } = diff(initialValues.toJS(), formValues)

    changedValues = mergeArrayDiff(changedValues, formValues, 'description')
    changedValues = mergeArrayDiff(
      changedValues,
      formValues,
      'alternativeNames'
    )
    changedValues = mergeArrayDiff(changedValues, formValues, 'domains')
    changedValues = mergeArrayDiff(
      changedValues,
      formValues,
      'supplier.flagCountry'
    )

    if (changedValues.supplier && changedValues.supplier.bannedCompanyIds) {
      changedValues = {
        ...changedValues,
        supplier: {
          ...changedValues.supplier,
          bannedCompanyIds: formValues.supplier.bannedCompanyIds
        }
      }
    }
    // set the status to active if clearing website warning
    if (
      changedValues.supplier &&
      changedValues.supplier.websiteWarning === '' &&
      formValues.status === 'Inactive'
    ) {
      changedValues = {
        ...changedValues,
        status: 'Active'
      }
    }
    saveCompanyInfo(changedValues)
  }

  render() {
    const {
      onCancel,
      handleSubmit,
      errorMessage,
      isTealbot,
      domainsOptions,
      website,
      addDomain,
      formDomainsValue,
      change,
      flagCountryList,
      sanctionedCompany,
      language,
      initialValues
    } = this.props
    return (
      <EditCompanyProfileHeader
        onSubmit={handleSubmit(this.triggerSubmit)}
        errorMessage={errorMessage}
        onCancel={onCancel}
        isTealbot={isTealbot}
        domainsOptions={domainsOptions}
        addDomain={addDomain}
        formDomainsValue={formDomainsValue}
        changeFieldValue={change}
        website={website}
        flagCountryList={flagCountryList}
        sanctionedCompany={sanctionedCompany}
        websiteWarning={
          isTealbot && initialValues
            ? initialValues.getIn(['supplier', 'websiteWarning'])
            : ''
        }
        language={language}
        supplierType={
          initialValues &&
          startCase(initialValues.getIn(['supplier', 'supplierType']))
        }
      />
    )
  }
}

const formName = 'supplier/company/header'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)
const selector = formValueSelector(formName)

export default connectReduxForm(
  (state: RootState) => {
    return {
      errorMessage: formSubmitErrorsSelector(state),
      domainsOptions: companySelectors.getCompanyDomainsOptions(state),
      initialValues: companySelectors.getCompanyHeaderInitialValues(state),
      formDomainsValue: selector(state, 'domains'),
      website: companySelectors.getLink(state, 'websiteUrl'),
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      flagCountryList: companySelectors.getCompanySupplierField(
        state,
        'flagCountry'
      ),
      sanctionedCompany: selector(state, 'supplier.bannedCompanyIds'),
      language: state.get('i18n').locale
    }
  },
  {
    onCancel: stopEditingCompanyHeader,
    saveCompanyInfo: createSubmitFormAction(formName, SAVE_COMPANY_INFO),
    addDomain
  },
  {
    form: formName
  },
  EditCompanyProfileHeaderContainer
)
