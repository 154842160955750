import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import tealboard from 'shared/utils/api/tealboard'

export function* loadSocialFeedSaga(action) {
  const { twitter, supplierId } = action.payload
  return yield call(tealboard.getSocialFeed, { twitter }, supplierId)
}

export default createApiRequestSaga(loadSocialFeedSaga)
