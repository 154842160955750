import { call } from 'redux-saga/effects'
import user from 'shared/utils/api/user'

export function* uploadProfilePictureSaga(action) {
  var FD = new FormData()
  FD.append('profilePicture', action.payload.file)
  yield call(user.uploadProfilePicture, FD)
}

export default uploadProfilePictureSaga
