import React, { Component, ReactNode } from 'react'
import Tooltip from 'shared/components/Tooltip'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

type Props = {
  label: string | ReactNode
  icon?: string
  value?: number
  alt?: string
  classes: any
}

const useStyles = {
  tooltip: {
    '&:focus-visible': {
      outline: 'auto',
      outlineColor: 'black'
    }
  }
}

const componentStyles = theme => useStyles

class ActiveUserMetric extends Component<Props> {
  render() {
    const { label, value, icon, alt, classes } = this.props

    return value ? (
      icon ? (
        <>
          <Tooltip
            title={label}
            tabIndex={0}
            className={classNames(classes.tooltip)}
            role='tooltip'
          >
            {<img src={icon} alt={alt ? alt : ''} className='w1 mr1' />}
          </Tooltip>
          <span
            className='mt1 f7 fw1 pr3 flex items-center'
            style={{ color: '#555555' }}
          >
            {value.toLocaleString()}
          </span>
        </>
      ) : (
        <span className='mt1 f7 fw1 pr3' style={{ color: '#555555' }}>
          {value.toLocaleString()} {label}
        </span>
      )
    ) : null
  }
}

export default withStyles(componentStyles)(ActiveUserMetric)
