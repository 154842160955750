import { call, all } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import users from 'shared/utils/api/users'

export function* bulkUpdateStatusSaga(action) {
  const { listOfUsers, status } = action.payload

  const entries = Object.entries<{ lastLogin: Date }>(listOfUsers)
  let updatedUsers = {}
  yield all(
    entries.map(([id, { lastLogin }]) => {
      if (status === 'block') {
        updatedUsers = { ...updatedUsers, [id]: { status: 'Blocked' } }
        return call(users.updateById, id, {
          status: 'Blocked'
        })
      } else if (status === 'unblock') {
        updatedUsers = {
          ...updatedUsers,
          [id]: { status: lastLogin ? 'Normal' : 'Registered' }
        }
        return call(users.updateById, id, {
          status: lastLogin ? 'Normal' : 'Registered'
        })
      }
      return updatedUsers
    })
  )

  return yield {
    updatedUsers
  }
}

export default createApiRequestSaga(bulkUpdateStatusSaga)
