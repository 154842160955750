import React, { Component } from 'react'
import paths from '../../../routes/paths'
import UserNavItem from '../UserNavItem'
import { FormattedMessage } from 'react-intl'

type Props = {
  baseUrl: string
  userId: string
  onClick: () => void
  focus?: boolean
}

class MyAccountUserNavItem extends Component<Props> {
  render() {
    const { baseUrl, onClick, userId, focus } = this.props

    if (!userId) {
      return null
    }

    return (
      <UserNavItem
        label={
          <FormattedMessage
            id='MyAccountUserNavItem.MyAccount'
            defaultMessage='My Account'
          />
        }
        linkTo={`${baseUrl}${paths.userProfile}`}
        linkParams={{ userId }}
        onClick={onClick}
        focus={!!focus ? focus : false}
      />
    )
  }
}

export default MyAccountUserNavItem
