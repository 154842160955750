import React, { FunctionComponent, useState } from 'react'
import OutcomeDialogContainer from '../../containers/OutcomeDialogContainer'
import withUser from 'shared/utils/withUser'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Avatar from 'shared/components/Avatar'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import camelCase from 'lodash.camelcase'
import { translatedFilterKeys } from 'shared/utils/data/defineMessages'

type UserProps = {
  profilePictureUrl: string
  firstName: string
  lastName: string
}

const User = withUser((props: UserProps) => (
  <div className='dt'>
    <div className='v-mid w2 h2 dtc'>
      <Avatar
        url={props.profilePictureUrl}
        className='w-100'
        name={`${props.firstName} ${props.lastName}`}
      />
    </div>
    <div className='v-mid dtc mid-gray pl3'>
      <h5 className='ma0 f7 fw4'>{`${props.firstName} ${props.lastName}`}</h5>
    </div>
  </div>
))

type Props = {
  vetBuyerSupplierId: string
  status: string
  userId: string
  buyerResponseComment: string
  buyerResponseNextSteps: string
  intl: IntlShape
}

export const Outcome: FunctionComponent<Props> = props => {
  const [outcomeDialogOpen, setOutcomeDialogOpen] = useState<boolean>(false)
  const [outcome, setOutcome] = useState<string>('')

  const onRequestClose = () => {
    setOutcomeDialogOpen(false)
  }

  const onAcceptClick = () => {
    setOutcomeDialogOpen(true)
    setOutcome('accept')
  }

  const onRejectClick = () => {
    setOutcomeDialogOpen(true)
    setOutcome('reject')
  }

  const {
    vetBuyerSupplierId,
    status,
    userId,
    buyerResponseComment,
    buyerResponseNextSteps,
    intl
  } = props

  const showOnlyStatus = ['Chosen', 'Not Chosen']
  return (
    <div className='dtc v-mid'>
      {status === 'Accepted' ? (
        <div>
          <Button
            autoSize
            label={
              <FormattedMessage id='Outcome.Include' defaultMessage='Include' />
            }
            onClick={onAcceptClick}
          />
          <h5 className='f8 fw6 ma0 dib mid-gray mh3 mb1'>
            <FormattedMessage id='Outcome.Or' defaultMessage='or' />
          </h5>
          <Button
            autoSize
            caution
            label={
              <FormattedMessage id='Outcome.Exclude' defaultMessage='Exclude' />
            }
            onClick={onRejectClick}
          />
        </div>
      ) : (
        showOnlyStatus.includes(status) && (
          <>
            <div style={{ paddingTop: 10 }}>
              <div className='dib v-mid'>
                <User userId={userId} />
              </div>
              <div className='f7 fw6 ma0 dib v-mid mid-gray mh2'>
                {' '}
                -{' '}
                {intl.formatMessage(
                  translatedFilterKeys.collaborationStatus[camelCase(status)]
                )}
              </div>
            </div>
            {buyerResponseComment && (
              <>
                <Label>
                  <FormattedMessage
                    id='Outcome.Response'
                    defaultMessage='Response'
                  />
                </Label>
                <Text>{buyerResponseComment}</Text>
              </>
            )}
            {buyerResponseNextSteps && (
              <>
                <Label>
                  <FormattedMessage
                    id='Outcome.NextStep'
                    defaultMessage='Next Step'
                  />
                </Label>
                <Text>{buyerResponseNextSteps}</Text>
              </>
            )}
          </>
        )
      )}

      <OutcomeDialogContainer
        open={outcomeDialogOpen}
        outcome={outcome}
        onRequestClose={onRequestClose}
        vetBuyerSupplierId={vetBuyerSupplierId}
      />
    </div>
  )
}

export default injectIntl(Outcome)
