import React, { Component } from 'react'
import { connect } from 'react-redux'
import CommunityCard from 'shared/components/CommunityCard'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import Tooltip from 'shared/components/Tooltip'
import Divider from 'shared/components/Divider'
import Link from 'shared/components/Link'
import Text from 'shared/components/Text'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import paths from '../../routes/paths'
import buyerPaths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import { FormattedMessage } from 'react-intl'
import { RecordOf, List } from 'immutable'
import Tier2CommunityLogo from 'shared/assets/icons/tier2-community-logo.svg'

type Props = {
  community: RecordOf<{
    id: string
    name: string
    description: string
    logoUrl: string
    isPrivate: boolean
    supplierSearchable: boolean
    tier2?: boolean
  }>
  contacts: List<string>
  message: string
  publicProfile: boolean
  isTealbot?: boolean
} & ContainerProps

export class CommunityCardContainer extends Component<Props> {
  render() {
    const {
      community,
      contacts,
      message,
      publicProfile,
      isTealbot
    } = this.props

    return (
      <CommunityCard
        logoUrl={
          community.get('tier2') ? Tier2CommunityLogo : community.get('logoUrl')
        }
        name={community.get('name')}
        description={community.get('description')}
        linkTo={parsePath(paths.community, {
          communityId: community.get('id')
        })}
        childrenHeight={110}
        isPrivate={community.get('isPrivate')}
        supplierSearchable={community.get('supplierSearchable')}
      >
        <Divider className='mb2' />
        <Tooltip
          title={
            publicProfile ? (
              <FormattedMessage
                id='CommunityCardContainer.YourCompanyNameAndDescriptionVisable'
                defaultMessage='Your company name and description is visible to suppliers who join this community.'
              />
            ) : (
              <FormattedMessage
                id='CommunityCardContainer.YourCompanyNameandDescriptionHidden'
                defaultMessage='Your company name and description will be hidden from suppliers even if they join this community.'
              />
            )
          }
        >
          <span className='lh-copy f7 fw6 mid-gray ma0 ml3 fr'>
            {publicProfile ? (
              <FormattedMessage
                id='CommunityCardContainer.Visable'
                defaultMessage='Visible'
              />
            ) : (
              <FormattedMessage
                id='CommunityCardContainer.Hidden'
                defaultMessage='Hidden'
              />
            )}
          </span>
        </Tooltip>
        {message && (
          <div className='mb2'>
            <Text>{message}</Text>
          </div>
        )}
        <div className='mb1'>
          {contacts && contacts.size > 0 ? (
            contacts.map(contact => {
              return (
                <UserListItemContainer
                  mini
                  key={contact}
                  profileUrl={parsePath(buyerPaths.userprofile, {
                    userId: contact
                  })}
                  userId={contact}
                  className='mt1 mb2'
                />
              )
            })
          ) : (
            <Link
              to={paths.community}
              params={{ communityId: community.get('id') }}
            >
              <Text className='i teal'>
                {isTealbot &&
                  (publicProfile ? (
                    <FormattedMessage
                      id='CommunityCardContainer.AddAContactToRepresentThisCommunity'
                      defaultMessage='Add a contact to represent this community'
                    />
                  ) : (
                    <FormattedMessage
                      id='CommunityCardContainer.MakeCompnayVisbaleTOAddCustomMessageAndContacts'
                      defaultMessage='Make company visible to add custom message and contacts to this community'
                    />
                  ))}
              </Text>
            </Link>
          )}
        </div>
      </CommunityCard>
    )
  }
}

type ContainerProps = {
  communityId: string
}

export default connect((state, props: ContainerProps) => {
  const { communityId } = props
  const orgUnitId = sessionSelectors.getOrgUnitId(state)
  const community = communitiesSelectors.getCommunityById(state, communityId)
  const buyers = community && community.get('buyers')
  const buyer = buyers && buyers.find(b => b.get('orgUnitId') === orgUnitId)
  const contacts = buyer && buyer.get('contacts')
  const message = buyer && buyer.get('message')
  const publicProfile = buyer && buyer.get('publicProfile')

  return {
    community,
    contacts: contacts && contacts.map(c => c.get('user')),
    message,
    publicProfile
  }
})(CommunityCardContainer)
