import { fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'

const defaultState = fromJS({
  email: '',
  editEmail: true,
  selectedOrgId: '',
  contacts: [],
  selectedUser: undefined
})

const connectUserToOrgReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_SUGGESTED_USER:
      return state.set('selectedUser', action.payload)

    case actionTypes.ADD_CONTACT:
      return state
        .update('contacts', contacts =>
          contacts
            .filter(
              c =>
                !action.payload.getIn(['user', 'id']) ||
                c.getIn(['user', 'id']) !== action.payload.getIn(['user', 'id'])
            )
            .push(action.payload)
        )
        .set('selectedUser', undefined)

    case actionTypes.REMOVE_CONTACT:
      return state.removeIn(['contacts', action.payload])

    case actionTypes.STOP_CONNECTING_USER_TO_ORG:
      return state.merge({
        selectedOrgId: '',
        contacts: fromJS([])
      })

    case actionTypes.START_CONNECTING_USER_TO_ORG:
      return state.set('selectedOrgId', action.payload)

    case actionTypes.CHANGE_USER_EMAIL:
      return state.merge({
        email: action.payload.get('email'),
        editMode: false
      })

    case actionTypes.EDIT_USER_EMAIL:
      return state.set('editEmail', true)

    default:
      return state
  }
}

export default connectUserToOrgReducer
