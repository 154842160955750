import React, { Component } from 'react'
import { connect } from 'react-redux'
import profileSelectors from '../../selectors/profileSelectors'
import OrgLogo from 'shared/components/OrgLogo'
import Link from 'shared/components/Link'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import fileToUrl from 'shared/utils/data/fileToUrl'
import Tooltip from 'shared/components/Tooltip'
import RootState from 'shared/models/RootState'

type Props = {
  supplierId: string
  name: string
  logoUrl: string
  domain: string
  address: string
  disableLink?: boolean
}

export class SiblingListItem extends Component<Props> {
  renderItem() {
    const {
      supplierId,
      name,
      logoUrl,
      domain,
      address,
      disableLink
    } = this.props

    return (
      <div className='mt3 w-100'>
        <div className='v-mid w3 w-20-ns dib'>
          <div className='dib w2'>
            {disableLink ? (
              <OrgLogo url={logoUrl} name={name} />
            ) : (
              <Link to={parsePath(paths.supplierProfile, { supplierId })}>
                <OrgLogo url={logoUrl} name={name} />
              </Link>
            )}
          </div>
        </div>
        <div className='v-mid w-70 w-80-ns dib'>
          <div className='ma0 f7 fw4 truncate'>
            {disableLink ? (
              name
            ) : (
              <Link to={parsePath(paths.supplierProfile, { supplierId })}>
                {name}
              </Link>
            )}
          </div>
          <div className='f9 mt1 truncate'>
            {domain} - {address}
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { name, address } = this.props

    return name ? (
      address ? (
        <Tooltip title={address}>{this.renderItem()}</Tooltip>
      ) : (
        this.renderItem()
      )
    ) : null
  }
}

type ContainerProps = {
  supplierId: string
}
export default connect((state: RootState, props: ContainerProps) => {
  const supplier = profileSelectors.getSiblingSupplierById(
    state,
    props.supplierId
  )

  if (!supplier) {
    return {}
  }

  const domain = supplier.getIn(['domains', '0'])
  const logo = supplier.getIn(['supplier', 'logo'])
  const address = supplier.getIn(['supplier', 'locations', '0', 'address'])

  return {
    name: supplier.get('name'),
    domain: domain,
    logoUrl:
      (logo && fileToUrl(logo.toJS())) ||
      (domain ? `https://logo.clearbit.com/${domain}` : ''),
    address
  }
})(SiblingListItem)
