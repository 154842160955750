import { connect } from 'react-redux'
import AppLogo from '../../components/TealbookLogo'
import sessionSelectors from '../../selectors/sessionSelectors'
import routingSelectors from '../../selectors/routingSelectors'
import companySelectors from 'supplier/shared/selectors/companySelectors'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import RootState from 'shared/models/RootState'
import fileToUrl from 'shared/utils/data/fileToUrl'

type Props = {
  clientOf?: string
}

export default connect((state: RootState, props: Props) => {
  const customLogo = settingsSelectors.getField(state, 'logoFile')

  const clientOf =
    props.clientOf ||
    companySelectors.getCompanyField(state, 'clientOf') ||
    settingsSelectors.getField(state, 'clientOf')

  let isLoadingLogo = false
  if (
    (!clientOf || (clientOf === 'custom' && !customLogo)) &&
    sessionSelectors.isLoggedIn(state)
  ) {
    isLoadingLogo = routingSelectors.getPathname(state).includes('/supplier/')
      ? companySelectors.isLoading(state)
      : !!settingsSelectors.getField(state, 'isLoading')
  }

  return {
    customLogoUrl: fileToUrl(customLogo),
    clientOf,
    isLoadingLogo
  }
})(AppLogo)
