import { call, select, all } from 'redux-saga/effects'
import job from 'shared/utils/api/job'
import { JobOrgUnit } from 'shared/models/Job'
import jobsSelectors from '../selectors/jobsSelectors'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* reorderOrgsPositionSaga(action) {
  const { oldIndex, newIndex } = action.payload
  const { rejectReason } = action.meta || {} // if saga is called by rejecting supplier
  const orgs: Array<JobOrgUnit> = yield select(jobsSelectors.getSowAcceptList)
  const resultIdsByOrgUnitId = yield select(
    jobsSelectors.getResultIdsByOrgUnitId
  )
  // update select org position
  const selectedOrg = orgs[oldIndex]

  const selectedResultId =
    !!selectedOrg && resultIdsByOrgUnitId.get(selectedOrg.orgUnitId)

  if (!selectedOrg) {
    return yield []
  }

  let result, otherResults
  if (selectedResultId) {
    result = yield call(job.updateJobResult, {
      jobResultId: selectedResultId,
      rowNum: rejectReason ? 1000 : newIndex,
      rejectReason
    })
  }

  // shift affect orgs position up or down in position
  const step = newIndex < oldIndex ? 1 : -1
  const affectedOrgs =
    step > 0
      ? orgs.slice(newIndex, oldIndex) // move up, affect orgs after new position to old position
      : orgs.slice(oldIndex + 1, newIndex + 1) // move down, affect orgs after old position to new position

  const orgEffects = affectedOrgs.map(org => {
    const affectedResultId = resultIdsByOrgUnitId.get(org.orgUnitId)
    return call(job.updateJobResult, {
      jobResultId: affectedResultId,
      rowNum: org.rowNum + step
    })
  })
  otherResults = yield all(orgEffects)
  otherResults?.push(result)

  return yield { results: otherResults, rejectReason }
}

export default createApiRequestSaga(reorderOrgsPositionSaga)
