import React, {
  ReactElement,
  ChangeEvent,
  useState,
  useEffect,
  useCallback
} from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FormattedMessage } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'
import { useSelector, useDispatch } from 'react-redux'
import { saveSurveyResponse } from '../../actions'
import RootState from 'shared/models/RootState'
import surveySelectors from '../../selectors/surveySelectors'
import Input from 'shared/components/Input'
import Text from 'shared/components/Text'

type Props = {
  pageId: string
  parentQuestionId?: string
  questionId: string
  options: string[]
  question?: string | ReactElement<FormattedMessage>
  className?: string
  fieldName?: 'answer' | 'reason'
  otherLabel?: string
}

const CheckboxQuestion = (props: Props) => {
  const dispatch = useDispatch()
  const {
    pageId,
    parentQuestionId,
    questionId,
    question,
    className,
    fieldName = 'answer',
    options,
    otherLabel
  } = props

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([])
  const [checkedOther, setCheckedOther] = useState<boolean>(false)
  const [other, setOther] = useState<string>('')

  const debouncedSaveSurveyResponse = useDebouncedCallback((payload: any) => {
    dispatch(saveSurveyResponse(payload))
  }, 500)

  const surveyId = useSelector(surveySelectors.getEsgSurveyId)
  const response = useSelector((state: RootState) => {
    return surveySelectors.getResponseDetailByIds(
      state,
      pageId,
      questionId,
      parentQuestionId
    )
  })

  const saveResponse = useCallback(
    (answer: string[]) => {
      debouncedSaveSurveyResponse.callback({
        surveyId,
        surveyResponseDetailId: response?.get('id'),
        pageId,
        questionId,
        parentQuestionId,
        [fieldName]: answer.join('|'),
        [`${fieldName}Type`]: 'list'
      })
    },
    [
      debouncedSaveSurveyResponse,
      fieldName,
      pageId,
      parentQuestionId,
      questionId,
      response,
      surveyId
    ]
  )

  const handleOtherCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    setCheckedOther(checked)

    let tempSelected = [...selectedCheckboxes]
    if (checked && !!other) {
      tempSelected.push(other)
    } else {
      tempSelected = tempSelected.filter(option => options.includes(option))
    }

    saveResponse(tempSelected)
  }

  const handleOtherFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setOther(value)
    let tempSelected = [...selectedCheckboxes]
    if (checkedOther) {
      if (!!value) {
        tempSelected.push(value)
      }
      saveResponse(tempSelected)
    }
  }

  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget

    const index = selectedCheckboxes.indexOf(value)
    const newSelectedCheckboxes =
      index === -1
        ? [...selectedCheckboxes, value]
        : [...selectedCheckboxes.filter((s, i) => i !== index)]

    setSelectedCheckboxes(newSelectedCheckboxes)

    if (checkedOther && !!other) {
      newSelectedCheckboxes.push(other)
    }
    saveResponse(newSelectedCheckboxes)
  }

  useEffect(() => {
    // update initial state
    if (!selectedCheckboxes.length && response && response.get(fieldName)) {
      const selected = response.get(fieldName).split('|')
      const partOfOptions = selected.filter(s => options.includes(s))
      const notInOptions = selected.filter(s => !options.includes(s))
      setSelectedCheckboxes(partOfOptions)
      if (notInOptions.length > 0) {
        setCheckedOther(true)
        setOther(notInOptions.toString())
      }
    }
  }, [response, fieldName, options, selectedCheckboxes.length])

  return (
    <div className={className}>
      {question && <Text className='fw6'>{question}</Text>}
      <div>
        {options.map(option => (
          <div key={option}>
            <FormControlLabel
              control={<Checkbox color='primary' size='small' value={option} />}
              label={option}
              onChange={handleOptionChange}
              checked={selectedCheckboxes.includes(option)}
            />
          </div>
        ))}
        <div className='flex items-center'>
          <FormControlLabel
            control={
              <Checkbox
                aria-label='other checkbox'
                color='primary'
                size='small'
                value={'other'}
              />
            }
            label={`${otherLabel || 'Other'}: `}
            onChange={handleOtherCheckboxChange}
            checked={checkedOther}
          />
          <Input
            value={other}
            aria-label='other option'
            onChange={handleOtherFieldChange}
            placeholder='Please specify'
            wrapperClassName='ml2 w5'
          />
        </div>
      </div>
    </div>
  )
}

export default CheckboxQuestion
