import React, { Component } from 'react'
import { connect } from 'react-redux'
import VetInboxPage from '../../components/VetInboxPage'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import Loading from 'shared/components/Loading'
import qs from 'qs'
import { List } from 'immutable'
import { RouteComponentProps } from 'react-router'
import { loadComments } from 'shared/actions'
import { loadVet } from '../../actions'
import RootState from 'shared/models/RootState'

type Props = {
  redirectFrom: string
  vetBuyerSuppliers: List<string>
  vetBuyerSupplierId: string
  isLoading: boolean
  conversationCount: number
  match: { params: { vetId: string } }
  loadComments: (params: { ancestorId: string; messageKind: string }) => void
  loadVet: typeof loadVet
} & RouteComponentProps

export class VetInboxPageContainer extends Component<Props> {
  componentDidMount() {
    this.props.loadVet(this.props.match.params.vetId, true)
  }

  render() {
    const {
      redirectFrom,
      vetBuyerSuppliers,
      vetBuyerSupplierId,
      isLoading,
      conversationCount,
      history: { push },
      match: {
        params: { vetId }
      },
      loadComments
    } = this.props
    return isLoading ? (
      <Loading />
    ) : (
      <div>
        <VetInboxPage
          conversationCount={conversationCount}
          push={push}
          vetId={vetId}
          vetBuyerSuppliers={vetBuyerSuppliers}
          vetBuyerSupplierId={vetBuyerSupplierId}
          redirectFrom={redirectFrom}
          loadComments={loadComments}
        />
      </div>
    )
  }
}

type ContainerProps = {
  match: { params: { vetBuyerSupplierId: string } }
} & RouteComponentProps

export default connect(
  (state: RootState, props: ContainerProps) => {
    const conversationCount = currentVetSuppliersSelectors.getConversationCount(
      state
    )
    const {
      location: { search }
    } = props
    const queryParams = qs.parse(search, { ignoreQueryPrefix: true })

    return {
      conversationCount,
      isLoading: currentVetSelectors.isLoading(state),
      vetBuyerSuppliers: currentVetSuppliersSelectors.getConversation(state),
      vetBuyerSupplierId: props.match.params.vetBuyerSupplierId,
      redirectFrom: queryParams.redirectFrom
    }
  },
  {
    loadComments,
    loadVet
  }
)(VetInboxPageContainer)
