import { call, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { notify } from 'shared/actions'
import community from 'shared/utils/api/community'

export function* joinCommunitySaga(action) {
  const { communityId, orgUnitId, name } = action.payload

  yield call(community.addClient, { communityId, orgUnitId })
  yield put(
    notify({
      message: `"${name}" has joined community "${communityId}"`
    })
  )
}

export default createApiRequestSaga(joinCommunitySaga, { notifyOnError: true })
