import { connect } from 'react-redux'
import SearchResults from '../../components/SearchResults'
import searchSelectors from '../../selectors/searchSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import { showMoreSuppliers } from '../../../shared/actions'
import { closeAddTagDialog, closeSupplierReviewDialog } from '../../actions'
import RootState from 'shared/models/RootState'

export default connect(
  (state: RootState) => ({
    isSearching: searchSelectors.isSearching(state),
    searchId: searchSelectors.getSearchId(state),
    resultsInGroup: searchSelectors.getResultsInGroup(state),
    scrollPosition: searchSelectors.getScrollPosition(state),
    currentUrl: `${routingSelectors.getPathname(
      state
    )}${routingSelectors.getSearch(state)}`,
    isAddTagDialogOpen: state.getIn(['buyer', 'search', 'isAddTagDialogOpen']),
    isAddNoteDialogOpen: state.getIn([
      'buyer',
      'search',
      'isAddNoteDialogOpen'
    ]),
    isReviewDialogOpen: state.getIn(['buyer', 'search', 'isReviewDialogOpen']),
    reviewSupplierName: state.getIn(['buyer', 'search', 'reviewSupplierName']),
    selectedSupplierId: searchSelectors.getSupplierId(state),
    selectedCardId: searchSelectors.getCardId(state),
    numberOfSearchResults: searchSelectors.getResultsCount(state),
    numberOfSuppliersToShow: state.getIn([
      'buyer',
      'search',
      'numberOfSuppliersToShow'
    ])
  }),
  {
    onClickShowMore: showMoreSuppliers,
    onCloseAddTagDialog: closeAddTagDialog,
    onCloseSupplierReviewDialog: closeSupplierReviewDialog
  }
)(SearchResults)
