import { put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import qs from 'qs'
import { clearSearchKeyword } from '../../../Search/actions'
import paths from '../../../routes/paths'
import vetPaths from '../../../Vets/routes/paths'
import routingSelectors from 'shared/selectors/routingSelectors'
import currentVetSelectors from '../../../Vets/selectors/currentVetSelectors'
import parsePath from 'shared/utils/parsePath'

function* searchSimilarSuppliersSaga(action) {
  const similarSuppliers: string[] = action.payload
  const filterState = {
    sort: 'Similarity',
    scope: 'All',
    similarSuppliers
  }

  const querySimilarSuppliers: string[] | undefined = yield select(
    routingSelectors.getSearchParam,
    'similarSuppliers'
  )

  const pathname = yield select(routingSelectors.getPathname)
  const onVetPage = pathname.includes('client/vets/')
  let vetSearchPath
  if (onVetPage) {
    const vetId = yield select(currentVetSelectors.getVetField, 'id')
    vetSearchPath = parsePath(vetPaths.vetSearch, { vetId })
  }

  // only trigger a similar search if the list is different from before
  if (
    !querySimilarSuppliers ||
    querySimilarSuppliers.length === 0 ||
    similarSuppliers.length !== querySimilarSuppliers.length ||
    similarSuppliers.some(
      supplierId => querySimilarSuppliers.indexOf(supplierId) === -1
    )
  ) {
    yield put(clearSearchKeyword())

    const queryString = {
      q: '',
      ...filterState
    }
    yield put(
      push(
        `${vetSearchPath ? vetSearchPath : paths.search}?${qs.stringify(
          queryString
        )}`
      )
    )
  }
}

export default searchSimilarSuppliersSaga
