import orgsSelectors from 'shared/selectors/orgsSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'

export const getSelectedOrg = state => {
  return orgsSelectors.getById(state, getProp(state, 'selectedOrgId'))
}

export const getEmail = state => getProp(state, 'email')
export const getEmailEditMode = state => getProp(state, 'emailEdit')
export const getSelectedUser = state => getProp(state, 'selectedUser')
export const getContacts = state => getProp(state, 'contacts')

export const getUsersOfSelectedOrg = state => {
  const selectedOrgId = getProp(state, 'selectedOrgId')
  return usersSelectors.getBySupplier(state, selectedOrgId)
}

function getProp(state, field) {
  return state.getIn(['dataPortal', 'connectUserToOrg', field])
}
