import React from 'react'
import { Route, Switch, Redirect } from 'react-router'
import paths from './paths'
import CreateCardPageContainer from '../containers/CreateCardPageContainer'
import TealbotPage from '../components/TealbotPage'
import IndexingPageContainer from '../containers/IndexingPageContainer'
import LoaderPageContainer from '../containers/LoaderPageContainer'
import UtilitiesPageContainer from '../containers/UtilitiesPageContainer'
import TasksApp from 'tasks/App'
import ForbiddenDomainsContainer from '../containers/ForbiddenDomainsContainer'
import ClientFilesDownloadContainer from '../containers/ClientFilesDownloadContainer'
import Statistics from '../containers/Statistics'
import SupplierMatchingContainer from 'data-portal/containers/SupplierMatchingContainer'
import DataQualityService from '../components/DataQualityService'

const tealbotPageRoles = [
  'clientConcierge',
  'supplierConcierge',
  'orgAdmin',
  'partner'
]

const tasksPageRoles = ['orgAdmin', 'clientConcierge']

const statisticsRoles = ['clientConcierge', 'supplierConcierge']

const getDefaultRoute = roles =>
  roles.some(r => tealbotPageRoles.includes(r)) ? paths.tealbot : paths.indexing

const createRoutes = roles => (
  <Switch>
    {roles.includes('orgAdmin') && (
      <Route
        path={paths.forbiddenDomains}
        component={ForbiddenDomainsContainer}
      />
    )}

    {roles.some(r => tealbotPageRoles.includes(r)) && (
      <Route path={paths.tealbot} component={TealbotPage} />
    )}

    {roles.includes('orgAdmin') && (
      <Route path={paths.connectUsers} component={CreateCardPageContainer} />
    )}

    {roles.some(r => tasksPageRoles.includes(r)) && (
      <Route path={paths.tasks} component={TasksApp} />
    )}

    {roles.includes('orgAdmin') && (
      <Route path={paths.loader} component={LoaderPageContainer} />
    )}

    {(roles.includes('orgAdmin') || roles.includes('serverAdmin')) && (
      <Route path={paths.utilities} component={UtilitiesPageContainer} />
    )}

    {roles.includes('serverAdmin') && (
      <Route path={paths.indexing} component={IndexingPageContainer} />
    )}

    {roles.includes('clientConcierge') && (
      <Route
        path={paths.clientFiles}
        component={ClientFilesDownloadContainer}
      />
    )}
    {roles.some(r => statisticsRoles.includes(r)) && (
      <Route path={paths.statistics}>
        <Statistics />
      </Route>
    )}
    {roles.some(r => statisticsRoles.includes(r)) && (
      <Route
        path={paths.supplierMatching}
        component={SupplierMatchingContainer}
      />
    )}
    {roles.some(r => tealbotPageRoles.includes(r)) && (
      <Route path={paths.dataQualityService} component={DataQualityService} />
    )}
    <Redirect from={paths.basePath} to={getDefaultRoute(roles)} />
  </Switch>
)

export default createRoutes
