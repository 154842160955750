import React from 'react'
import Page from '../Page'
import TitleText from '../TitleText'
import Text from '../Text'
import { FormattedMessage } from 'react-intl'
import AppBarContainer from '../../containers/AppBarContainer'
import UserNavContainer from '../../containers/UserNavContainer'

const PrivateProfilePage = () => {
  return (
    <div>
      <AppBarContainer
        userNav={<UserNavContainer />}
        homeUrl='/private-profile'
      >
        <div className='flex-auto' />
      </AppBarContainer>
      <Page title={'PrivateProfile'} className='tc'>
        <TitleText>
          <FormattedMessage
            id='PrivateProfilePage.PrivateCertificateTitle'
            defaultMessage='Private Certificate'
          />
        </TitleText>
        <div className='mt4 mw6 center pa4 ba b--black-10 bg-white'>
          <Text>
            <FormattedMessage
              id='PrivateProfilePage.PrivateCertificate'
              defaultMessage='This supplier has chosen not to share their SAM certification and/or profile. Please contact the supplier directly for more information.'
            />
          </Text>
        </div>
      </Page>
    </div>
  )
}
export default PrivateProfilePage
