export default function sortStrings(nameA = '', nameB = '', desc) {
  const parsedNameA = nameA.trim().toLowerCase()
  const parsedNameB = nameB.trim().toLowerCase()

  // calculate in ascending direction
  const ascDirection = parsedNameA > parsedNameB ? 1 : -1

  // inverse it if descending
  return desc ? ascDirection * -1 : ascDirection
}
