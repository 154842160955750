import React, { useState, ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'
import Button from 'shared/components/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import FileInput from 'shared/components/FileInput'
import DatePicker from 'shared/components/DatePicker'
import CertificationCategories from 'shared/models/CertificationCategories'
import companySelectors from '../../../../shared/selectors/companySelectors'
import { updateCertificate } from '../../../actions'
import Certification from 'shared/models/Certification'
import startCase from 'lodash.startcase'

type Props = {
  timeStamp: string
  certification: RecordOf<Certification>
  open: boolean
  onClose: () => void
  updateCertificate: (params: {
    timeStamp: string | boolean
    category: string
    subCategory: string
    cert: File | undefined
    certExpiration: string | undefined
    supplierCertified: boolean
    certAgency?: string
    sourceURL?: string
    certificateNumber?: string
  }) => void
}

const DiversityRenewDialog = (props: Props) => {
  const intl = useIntl()
  const [cert, setCert] = useState<File | undefined>(undefined)
  const [certExpirationState, setCertExpiration] = useState<string | undefined>(
    undefined
  )
  const [supplierCertified, setSupplierCertified] = useState<boolean>(false)

  const handleCertChange = cert => {
    setCert(cert)
  }

  const handleCertExpirationChange = value => {
    setCertExpiration(value ? value.toISOString() : undefined)
  }

  const handleSupplierCertifiedChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSupplierCertified(event.target.checked)
  }

  const handleRenewConfirmClick = () => {
    const { updateCertificate, certification, onClose } = props

    if (certification) {
      const {
        timeStamp,
        category,
        subCategory,
        certExpiration,
        certAgency,
        sourceURL,
        certificateNumber
      } = certification.toJS()

      updateCertificate({
        timeStamp,
        category,
        subCategory,
        certAgency,
        sourceURL,
        certificateNumber,
        cert,
        certExpiration: certExpirationState || certExpiration,
        supplierCertified
      })
    }

    onClose()
  }

  const translatedSubCategory =
    CertificationCategories[props.certification.get('category')] &&
    CertificationCategories[props.certification.get('category')].subCategories[
      props.certification.get('subCategory')
    ]
      ? intl.formatMessage(
          CertificationCategories[props.certification.get('category')]
            .subCategories[props.certification.get('subCategory')]
        )
      : startCase(props.certification.get('subCategory'))
  return props.certification ? (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        <FormattedMessage
          id='DiversityRenewDialog.RenewalTitle'
          defaultMessage='Renew the Certification'
        />
      </DialogTitle>
      <DialogContent>
        <Text>
          <FormattedMessage
            id='DiversityRenewDialog.RenewalText'
            defaultMessage='Your company has represent itself as a <b>{subCategoryFull} ({subCategory})</b>. Can you confirm that you still meet the requirements to qualify for this certification as of the current date? If so, read the supplier verification text below and click the Confirm button to electronically sign this attestation.'
            values={{
              b: msg => <b>{msg}</b>,
              subCategory: props.certification.get('subCategory').toUpperCase(),
              subCategoryFull: translatedSubCategory
            }}
          />
        </Text>
        <Label htmlFor='supplierCertified'>
          <FormattedMessage id='CertificationForm.SupplierVerification' />
        </Label>
        <Text>
          <input
            id='supplierCertified'
            name='supplierCertified'
            type='checkbox'
            className='mr3'
            checked={supplierCertified}
            onChange={handleSupplierCertifiedChange}
          />
          <FormattedMessage id='CertificationForm.SupplierVerificationMessage' />
        </Text>
        <Label htmlFor='cert'>
          <FormattedMessage
            id='DiversityRenewDialog.NewCertificate'
            defaultMessage='New Certificate (Optional)'
          />
        </Label>
        <FileInput
          name='cert'
          accept='.pdf, .png, .jpg, .jpeg, .svg, .gif'
          onChange={handleCertChange}
        />
        <Label htmlFor='certExpiration'>
          <FormattedMessage
            id='DiversityRenewDialog.NewCertificationExpiration'
            defaultMessage='New Expiration Date (Optional)'
          />
        </Label>
        <DatePicker
          id='certExpiration'
          name='certExpiration'
          onChange={handleCertExpirationChange}
          value={certExpirationState}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoSize
          disabled={!supplierCertified}
          onClick={handleRenewConfirmClick}
        >
          <FormattedMessage
            id='DiversityRenewDialog.ConfirmButton'
            defaultMessage='Confirm'
          />
        </Button>
        <Button autoSize secondary onClick={() => props.onClose()}>
          <FormattedMessage id='CancelButton' />
        </Button>
      </DialogActions>
    </Dialog>
  ) : null
}

export default connect(
  (state: RootState, props: { timeStamp: string }) => {
    return {
      certification: companySelectors.getCertificationByTimeStamp(
        state,
        props.timeStamp
      )
    }
  },
  {
    updateCertificate
  }
)(DiversityRenewDialog)
