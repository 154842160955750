import { call, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import camelCase from 'lodash.camelcase'
import parseTags from 'shared/utils/data/parseTags'
import cards from 'shared/utils/api/cards'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import Immutable, { fromJS } from 'immutable'
import { getMyCardBySupplierSaga } from '../getMyCardBySupplierSaga/getMyCardBySupplierSaga'

export function* addTagToSupplierSaga(action) {
  const {
    tagsFieldName,
    newTag,
    supplierId,
    cardId
  } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  let card
  /* try to get my card from state */
  card = yield select(cardsSelectors.getById, cardId)

  /* if no card or card is not a full object, try fetch it */
  if (!card || !card.has('supplier')) {
    const { myCard } = yield call(getMyCardBySupplierSaga, {
      payload: { supplierId }
    })
    card = fromJS(myCard)
  }

  const isNewCard =
    !card ||
    card.size === 0 ||
    card.get('placeholderCard') ||
    card.get('id').endsWith('-1')

  const newTags = newTag
    .split(',')
    .map(tag => tag.trim())
    .filter(
      tag =>
        !!tag && (isNewCard || !card.get(tagsFieldName).has(camelCase(tag)))
    )

  let updatedCard
  if (isNewCard) {
    // no existing card, create a new one
    updatedCard = yield call(cards.create, {
      supplier: supplierId,
      [tagsFieldName]: newTags
    })
  } else {
    // update the card's tags if there are new tags
    if (newTags.length > 0) {
      // combined tags have more than original tags
      updatedCard = yield call(cards.update, card.get('id'), {
        [tagsFieldName]: card
          .get(tagsFieldName)
          .toList()
          .toJSON()
          .concat(newTags)
      })
    } else {
      updatedCard = card.toJS()
    }
  }

  return yield {
    supplierId: supplierId,
    cardId: updatedCard.id,
    tagsFieldName,
    newTags: newTags.reduce((result, tag) => {
      result[camelCase(tag)] = {
        name: tag,
        count: 1
      }
      return result
    }, {}),
    tags: [
      ...Object.values(updatedCard.offerings),
      ...Object.values(updatedCard.differentiators)
    ].map(tag => camelCase(tag)),
    cardChanges: {
      [tagsFieldName]: parseTags(Object.values(updatedCard[tagsFieldName]))
    },
    newCard: isNewCard && updatedCard
  }
}

export default createApiRequestSaga(addTagToSupplierSaga)
