import React, { Component, ReactNode } from 'react'
import classNames from 'classnames'

type Props = {
  children: ReactNode
  className?: string
}

/**
 * A normal text tag intended for use as a title (this is just an h2 with custom formatting)
 */
class TitleText extends Component<Props> {
  render() {
    const { className, children } = this.props

    return (
      <h2 className={classNames('f3 f3-m f2-l fw2 black-70 mb0', className)}>
        {children}
      </h2>
    )
  }
}

export default TitleText
