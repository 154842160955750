import React, { Component, ReactElement } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Clear'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'

const DialogTitleWithStyles = withStyles({
  root: {
    borderBottom: '1px solid rgba( 0, 0, 0, .1 )',
    padding: '12px 24px'
  }
})(DialogTitle)

type Props = {
  onClose?: () => void
  customCloseLabel?: string | ReactElement<FormattedMessage>
  id?: string
}

class CustomDialogTitle extends Component<Props> {
  render() {
    const { children, onClose, customCloseLabel, ...restProps } = this.props
    return (
      <DialogTitleWithStyles {...restProps} disableTypography>
        <div className='flex justify-between items-center'>
          <h5 className='dib truncate v-mid w-90 fw6 f6 mv0'>{children}</h5>
          {customCloseLabel ? (
            <Button
              autoSize
              size='small'
              label={customCloseLabel}
              onClick={onClose}
            />
          ) : (
            onClose && (
              <CloseIcon
                aria-label='close dialog'
                className='pointer dim'
                onClick={onClose}
              />
            )
          )}
        </div>
      </DialogTitleWithStyles>
    )
  }
}

export default CustomDialogTitle
