import React, { useState, MouseEvent, useEffect, useMemo } from 'react'
import Checkbox from 'shared/components/ReduxFormMaterialCheckbox'
import Radio from '@material-ui/core/Radio'
import RadioGroup from 'shared/components/ReduxFormMaterialRadioGroup'
import Paper from 'shared/components/Paper'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import CustomLogoInput from '../CustomLogoInput'
import { FormattedMessage, useIntl } from 'react-intl'
import startCase from 'lodash.startcase'
import { Field } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Map, RecordOf } from 'immutable'
import Select from 'shared/components/Select'
import { LicenseType } from 'shared/models/User'
import LicenseTable from './LicenseTable'

type Prop = {
  isUpdating: boolean
  initialValues: RecordOf<{
    buyerType: string
    categorization: string
    accountId: string
    diversityReportEnabled: boolean
    integrationsEnabled?: boolean
    integrationPartner: boolean
    showConcierge: string
    clientOf: string
    defaultLicenseType: LicenseType
    licenseConfig: RecordOf<{
      business: Map<string, boolean>
      professional: Map<string, boolean>
      advanced: Map<string, boolean>
      elite: Map<string, boolean>
    }>
  }>
  inputClientOf: string
  defaultLicenseType?: string
  customLogoUrl?: string
  updateSettings: (settings: { [key: string]: any }) => void
} & InjectedFormProps

const SalesConfig = (props: Prop) => {
  const {
    initialValues,
    inputClientOf,
    customLogoUrl,
    defaultLicenseType,
    isUpdating,
    updateSettings
  } = props
  const [editMode, setEditMode] = useState<boolean>(false)
  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false)

  const isSaveCompleted = useMemo(() => {
    return isSaveClicked && !isUpdating
  }, [isSaveClicked, isUpdating])

  const intl = useIntl()

  const handleEditClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setEditMode(true)
  }

  const handleSubmit = values => {
    updateSettings(values.toJS())
    setIsSaveClicked(true)
  }

  useEffect(() => {
    if (isSaveCompleted) {
      setEditMode(false)
      setIsSaveClicked(false)
    }
  }, [isSaveCompleted])

  return (
    <Paper className='mt4 pt3-5'>
      <form onSubmit={props.handleSubmit(handleSubmit)}>
        <div className='flex justify-between'>
          <div>
            <Label noPadding>
              <FormattedMessage
                id='SalesConfig.BuyerType'
                defaultMessage='Buyer Type'
              />
            </Label>
            <Text>{startCase(initialValues?.get('buyerType'))}</Text>
          </div>
          {!editMode ? (
            <Button type='button' autoSize onClick={handleEditClick}>
              <FormattedMessage id='EditButton' defaultMessage='Edit' />
            </Button>
          ) : (
            <Button autoSize type='submit' disabled={isUpdating}>
              {isUpdating ? (
                <FormattedMessage
                  id='SalesConfig.Saving'
                  defaultMessage='Saving...'
                />
              ) : (
                <FormattedMessage
                  id='SalesConfig.SaveConfiguration'
                  defaultMessage='Save Configuration'
                />
              )}
            </Button>
          )}
        </div>
        <Label>
          <FormattedMessage
            id='Settings.Categorization'
            defaultMessage='Categorization'
          />
        </Label>
        {editMode ? (
          <Field name='categorization' className='mt2' component={Input} />
        ) : (
          <Text>{initialValues?.get('categorization')}</Text>
        )}

        <Label>
          <FormattedMessage
            id='Settings.SalesforceAccountId'
            defaultMessage='Salesforce Account Id'
          />
        </Label>
        {editMode ? (
          <Field name='accountId' className='mt2' component={Input} />
        ) : (
          <Text>
            {initialValues?.get('accountId') || (
              <FormattedMessage
                id='Settings.NotSet'
                defaultMessage='<Not Set>'
              />
            )}
          </Text>
        )}

        <div
          className={`flex ${!editMode ? 'flex-column' : 'mt3 items-center'}`}
        >
          <Label className={`db f7 fw6 ${!editMode ? 'mt3 mb1' : ''}`}>
            <FormattedMessage
              id='Settings.EnableDiversityReport'
              defaultMessage='Enable Diversity Report'
            />
          </Label>
          {editMode ? (
            <Field
              color='primary'
              name='diversityReportEnabled'
              component={Checkbox}
            />
          ) : (
            <Text>
              {initialValues?.get('diversityReportEnabled') ? (
                <FormattedMessage id='Yes' defaultMessage='Yes' />
              ) : (
                <FormattedMessage id='No' defaultMessage='No' />
              )}
            </Text>
          )}
        </div>
        {/* defaulted to True for now in buyer-manager => extractSettings */}
        <div
          className={`flex ${!editMode ? 'flex-column' : 'mt3 items-center'}`}
        >
          <Label className={`db f7 fw6 ${!editMode ? 'mt3 mb1' : ''}`}>
            <FormattedMessage
              id='Settings.IntegrationsEnabled'
              defaultMessage='Integrations Enabled'
            />
          </Label>
          {editMode ? (
            <Field
              color='primary'
              name='integrationsEnabled'
              component={Checkbox}
            />
          ) : (
            <Text>
              {initialValues?.get('integrationsEnabled') ? (
                <FormattedMessage id='Yes' defaultMessage='Yes' />
              ) : (
                <FormattedMessage id='No' defaultMessage='No' />
              )}
            </Text>
          )}
        </div>
        <div className={`flex ${!editMode ? 'flex-column' : 'items-center'}`}>
          <Label className={`db f7 fw6 ${!editMode ? 'mt3 mb1' : ''}`}>
            <FormattedMessage
              id='Settings.IntegrationPartner'
              defaultMessage='Integration Partner'
            />
          </Label>
          {editMode ? (
            <Field
              color='primary'
              name='integrationPartner'
              component={Checkbox}
            />
          ) : (
            <Text>
              {initialValues?.get('integrationPartner') ? (
                <FormattedMessage id='Yes' defaultMessage='Yes' />
              ) : (
                <FormattedMessage id='No' defaultMessage='No' />
              )}
            </Text>
          )}
        </div>
        <Label>
          <FormattedMessage
            id='SalesConfig.ShowConcierge'
            defaultMessage='Show Concierge'
          />
        </Label>
        {editMode ? (
          <Field name='showConcierge' component={RadioGroup}>
            <FormControlLabel
              value='yes'
              control={<Radio color='primary' size='small' />}
              label={
                <FormattedMessage id='SalesConfig.Yes' defaultMessage='Yes' />
              }
            />
            <FormControlLabel
              value='hide'
              control={<Radio color='primary' size='small' />}
              label={
                <FormattedMessage id='SalesConfig.Hide' defaultMessage='Hide' />
              }
            />
            <FormControlLabel
              value='upsell'
              control={<Radio color='primary' size='small' />}
              label={
                <FormattedMessage
                  id='SalesConfig.Upsell'
                  defaultMessage='Upsell'
                />
              }
            />
          </Field>
        ) : (
          <Text>
            {initialValues?.get('showConcierge') || (
              <FormattedMessage
                id='Settings.NotSet'
                defaultMessage='<Not Set>'
              />
            )}
          </Text>
        )}

        <Label>
          <FormattedMessage
            id='SalesConfig.ClientOf'
            defaultMessage='Client of'
          />
        </Label>
        {editMode ? (
          <Field name='clientOf' component={RadioGroup}>
            <FormControlLabel
              value='tealbook'
              control={<Radio color='primary' size='small' />}
              label={
                <FormattedMessage
                  id='SalesConfig.Tealbook'
                  defaultMessage='tealbook'
                />
              }
            />
            <FormControlLabel
              value='ccab'
              control={<Radio color='primary' size='small' />}
              label={
                <FormattedMessage id='SalesConfig.CCAB' defaultMessage='CCAB' />
              }
            />
            <FormControlLabel
              value='ivalua'
              control={<Radio color='primary' size='small' />}
              label={
                <FormattedMessage
                  id='SalesConfig.ivalua'
                  defaultMessage='iValua'
                />
              }
            />
            <FormControlLabel
              value='rfaq'
              control={<Radio color='primary' size='small' />}
              label={
                <FormattedMessage id='SalesConfig.RFAQ' defaultMessage='RFAQ' />
              }
            />
            <FormControlLabel
              value='custom'
              control={<Radio color='primary' size='small' />}
              label={
                <FormattedMessage
                  id='SalesConfig.Custom'
                  defaultMessage='Custom'
                />
              }
            />
          </Field>
        ) : (
          <Text>
            {initialValues?.get('clientOf') || (
              <FormattedMessage
                id='Settings.NotSet'
                defaultMessage='<Not Set>'
              />
            )}
          </Text>
        )}

        {inputClientOf === 'custom' &&
          (editMode ? (
            <CustomLogoInput />
          ) : customLogoUrl ? (
            <img src={customLogoUrl} alt='Cover' className='w5' />
          ) : null)}

        <Label>
          <FormattedMessage
            id='SalesConfig.DefaultUserLicense'
            defaultMessage='Default User License'
          />
        </Label>
        {editMode ? (
          <Field name='defaultLicenseType' component={Select}>
            <option value='business'>
              {intl.formatMessage({ id: 'Business' })}
            </option>
            <option value='professional'>
              {intl.formatMessage({ id: 'Professional' })}
            </option>
            <option value='advanced'>
              {intl.formatMessage({ id: 'Advanced' })}
            </option>
            <option value='elite'>{intl.formatMessage({ id: 'Elite' })}</option>
          </Field>
        ) : (
          <Text>
            {defaultLicenseType &&
              intl.formatMessage({ id: `${defaultLicenseType}` })}
          </Text>
        )}

        <Label>
          <FormattedMessage
            id='SalesConfig.LicenseConfiguration'
            defaultMessage='License Configuration'
          />
        </Label>

        <div className='mt2 br1 ba b--black-10'>
          <LicenseTable editMode={editMode} initialValues={initialValues} />
        </div>
      </form>
    </Paper>
  )
}

export default SalesConfig
