import { select, call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import profileSelectors from '../../selectors/profileSelectors'
import relationships from 'shared/utils/api/relationships'
import { addMessageSaga } from 'shared/sagas/addMessageSaga/addMessageSaga'
import Immutable from 'immutable'

export function* addSupplierCommentSaga(action) {
  const {
    newNote,
    isPrivate,
    messageKind,
    relationship: givenRelationship,
    supplierId: givenSupplierId
  } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload
  const relationship = yield givenRelationship ||
    select(profileSelectors.getRelationship)

  let ancestorId
  let newRelationship
  if (!relationship || !relationship.get('id')) {
    const currentSupplierId = yield givenSupplierId ||
      select(profileSelectors.getDataField, 'id')
    newRelationship = yield call(relationships.create, currentSupplierId, {})
    ancestorId = newRelationship.id
  } else {
    ancestorId = relationship.get('id')
  }

  const newMessage = yield call(addMessageSaga, {
    payload: { ancestorId, newNote, isPrivate, messageKind }
  })

  return yield { newRelationship, ...newMessage }
}

export default createApiRequestSaga(addSupplierCommentSaga)
