import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { LocationSearchContainer } from '../../../Search/containers/LocationSearchContainer/LocationSearchContainer'
import { connect } from 'react-redux'
import searchContactsSelectors from '../../../shared/selectors/searchContactsSelectors'
import { searchLocations } from '../../../shared/actions'
import qs from 'qs'
import { fromJS, RecordOf, List } from 'immutable'

function addFilterIfDoesntExist(filtersArray = fromJS([]), value) {
  return filtersArray.filter(val => val !== value).push(value)
}

type Query = RecordOf<{
  scope: string
  filter: {
    country?: List<string>
    state?: List<string>
    city?: List<string>
    source?: List<string>
    isPublic?: List<string>
    roles?: List<string>
    scope?: string
  }
}>

type Props = {
  query: Query
  searchLocations: (param: string) => void
  results: List<{ value: string; label: string }>
  isLocationSearching: boolean
} & RouteComponentProps

class SearchLocationContainer extends Component<Props> {
  handleSearchLocations = locationQuery => {
    const queryString = qs.stringify({
      ...this.props.query.toJS(),
      locationQuery,
      agg: false,
      limit: 0
    })
    this.props.searchLocations(queryString)
  }

  handleToggleFilterOption = ({ key, value }) => {
    const { history, query } = this.props

    const newQuery = query.updateIn(['filter'], (oldFilters = fromJS({})) => {
      const splitValue = value.split('::')

      switch (key) {
        case 'country':
          return oldFilters.set(
            'country',
            addFilterIfDoesntExist(oldFilters.get('country'), value)
          )

        case 'state':
          return oldFilters.merge({
            country: addFilterIfDoesntExist(
              oldFilters.get('country'),
              splitValue[0]
            ),
            state: addFilterIfDoesntExist(oldFilters.get('state'), value)
          })

        case 'city':
          return oldFilters.merge({
            country: addFilterIfDoesntExist(
              oldFilters.get('country'),
              splitValue[0]
            ),
            state: addFilterIfDoesntExist(
              oldFilters.get('state'),
              `${splitValue[0]}::${splitValue[1]}`
            ),
            city: addFilterIfDoesntExist(oldFilters.get('city'), value)
          })

        default:
          return oldFilters
      }
    })

    history.push({
      search: qs.stringify(newQuery.toJS())
    })
  }

  render() {
    return (
      <LocationSearchContainer
        {...this.props}
        searchLocation={this.handleSearchLocations}
        toggleFilterOption={this.handleToggleFilterOption}
      />
    )
  }
}

type ContainerProps = {
  location: { search: string }
}
export default withRouter(
  connect(
    (state, props: ContainerProps) => ({
      query: searchContactsSelectors.getParsedQuery(props.location.search),
      isLocationSearching: searchContactsSelectors.isSearchingLocations(state),
      results: searchContactsSelectors.getLocationResults(state)
    }),
    {
      searchLocations
    }
  )(SearchLocationContainer)
)
