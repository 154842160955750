import React, { Component, ReactNode } from 'react'
import ImageArrowDown from 'shared/assets/icons/arrow-down.svg'
import ImageArrowUp from 'shared/assets/icons/arrow-up.svg'

type Props = {
  filterLabel: string | ReactNode
  isCollapse: boolean
  onClick: () => void
}

class SearchFilter extends Component<Props> {
  render() {
    const { isCollapse, filterLabel, children, onClick } = this.props

    return (
      <>
        <button
          className='mid-gray dib ma0 w-100 dt pointer bg-white pa3 br-0 bb-0 bl-0 bt  b--black-10'
          onClick={onClick}
          aria-expanded={!isCollapse}
        >
          <h5 className='f7 fw6 ma0 dib w-90 v-mid tl'>{filterLabel}</h5>
          <div className='dib w-10 tr'>
            <img
              src={!isCollapse ? ImageArrowUp : ImageArrowDown}
              className='v-mid'
              alt=''
            />
          </div>
        </button>
        <div>
          {children && !isCollapse && <div className='pa3 pt0'>{children}</div>}
        </div>
      </>
    )
  }
}

export default SearchFilter
