import React, { Component, Fragment } from 'react'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import PageSideSection from 'shared/components/PageSideSection'
import SiblingSuppliers from '../../../buyer/SupplierProfile/containers/SiblingSuppliers'
import Divider from 'shared/components/Divider'
import { FormattedMessage } from 'react-intl'
import ClassificationCodes from 'shared/components/ClassificationCodes'
import ClassificationCode from 'shared/models/ClassificationCode'
import { Map, List } from 'immutable'
import Tooltip from 'shared/components/Tooltip'

type Props = {
  isSupplierApp?: boolean
  name?: string
  numberOfEmployees?: string | number
  yearFounded?: number
  annualRevenue?: string
  dunsNumber?: number
  lei?: number | string
  taxId?: number
  classificationCodesBySchema?: Map<string, List<ClassificationCode>>
  schemaOrder?: Array<string>
}

class CorporateSection extends Component<Props> {
  render() {
    const {
      name,
      numberOfEmployees,
      yearFounded,
      annualRevenue,
      dunsNumber,
      lei,
      taxId,
      isSupplierApp,
      classificationCodesBySchema,
      schemaOrder
    } = this.props

    return name ||
      numberOfEmployees ||
      yearFounded ||
      annualRevenue ||
      dunsNumber ||
      taxId ||
      lei ||
      classificationCodesBySchema ? (
      <PageSideSection
        title={
          <FormattedMessage
            id='CorporateSection.Corporate'
            defaultMessage='Corporate'
          />
        }
      >
        {name && (
          <Fragment>
            <Label>
              <FormattedMessage
                id='CorporateSection.ParentCompany'
                defaultMessage='Parent Company'
              />
            </Label>
            <Text>{name}</Text>
          </Fragment>
        )}

        {!!numberOfEmployees && (
          <Fragment>
            <Label>
              <FormattedMessage
                id='CorporateSection.NumberOfEmployees'
                defaultMessage='Number of employees'
              />
            </Label>
            <Text>{numberOfEmployees}</Text>
          </Fragment>
        )}

        {!!yearFounded && (
          <Fragment>
            <Label>
              <FormattedMessage
                id='CorporateSection.YearFounded'
                defaultMessage='Year founded'
              />
            </Label>
            <Text>{yearFounded}</Text>
          </Fragment>
        )}

        {!!annualRevenue && (
          <Fragment>
            <Label>
              <FormattedMessage
                id='CorporateSection.AnnualRevenue'
                defaultMessage='Annual revenue'
              />
            </Label>
            <Text>{annualRevenue}</Text>
          </Fragment>
        )}

        <SiblingSuppliers isSupplierApp={isSupplierApp} />

        {(!!dunsNumber || !!taxId) && <Divider className='mt3' />}

        {!!dunsNumber && (
          <Fragment>
            <Label>
              <FormattedMessage
                id='CorporateSection.DUNSNumber'
                defaultMessage='DUNS #'
              />
            </Label>
            <Text>{dunsNumber}</Text>
          </Fragment>
        )}

        {!!taxId && (
          <Fragment>
            <Label>
              <FormattedMessage
                id='CorporateSection.TaxId'
                defaultMessage='Tax ID'
              />
            </Label>
            <Text>{taxId}</Text>
          </Fragment>
        )}

        {!!lei && (
          <>
            <Tooltip title={<FormattedMessage id='CorporateSection.LEIHelp' />}>
              <label className='db pt3 mb1 f7 fw6'>
                <FormattedMessage
                  id='CorporateSection.LEI'
                  defaultMessage='LEI'
                />
              </label>
            </Tooltip>
            <Text>{lei}</Text>
          </>
        )}
        {!!classificationCodesBySchema && (
          <ClassificationCodes
            classificationCodesBySchema={classificationCodesBySchema}
            schemaOrder={schemaOrder}
          />
        )}
      </PageSideSection>
    ) : null
  }
}

export default CorporateSection
