import { call, put } from 'redux-saga/effects'
import community from 'shared/utils/api/community'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import tier2 from 'shared/utils/api/tier2'
import { getTier2Communities } from '../../../Insight/store/actions'

export function* declineCommunitySaga(action) {
  const { commId, isTier2 } = action.payload
  const communityPlanMembership = isTier2
    ? yield call(tier2.declineTier2Invite, commId)
    : yield call(community.declineCommunity, commId)
  yield put(getTier2Communities())

  return yield {
    communityPlanMembership,
    commId
  }
}

export default createApiRequestSaga(declineCommunitySaga)
