import { savePreferredLanguage } from '../i18n'
import { CHANGE_LANGUAGE } from './actionTypes'

export const changeLanguage = language => {
  savePreferredLanguage(language)

  return {
    type: CHANGE_LANGUAGE,
    payload: language
  }
}
