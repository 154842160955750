import React, { FunctionComponent } from 'react'
import { List, Map, RecordOf } from 'immutable'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts'
import Label from 'shared/components/Label'
import Paper from 'shared/components/Paper'
import { defineMessages, injectIntl, IntlShape } from 'react-intl'

const messages = defineMessages({
  annually: {
    id: 'SpendOverviewGraph.Annually',
    defaultMessage: 'Annual Spend'
  },
  quarterly: {
    id: 'SpendOverviewGraph.Quarterly',
    defaultMessage: 'Quarter Spend'
  }
})
const formatUnit = (value: number) =>
  `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return payload ? (
      <Paper>
        <Label>{`${label}`}</Label>
        {payload.map(line => (
          <div key={line.name} className='mv2' style={{ color: line.color }}>
            {line.name}: {formatUnit(line.value)}
          </div>
        ))}
      </Paper>
    ) : null
  }

  return null
}

type Props = {
  data: Map<
    string,
    RecordOf<{
      quarterly: List<
        RecordOf<{
          category: string
          amount: number
        }>
      >
      annually: List<
        RecordOf<{
          category: string
          amount: number
        }>
      >
    }>
  >
  intl: IntlShape
}

export const SpendOverviewGraph: FunctionComponent<Props> = props => {
  const data = props.data && props.data.toJS()
  const showGraph = data['annually'] && data['annually'].length > 0
  const showQuarterly =
    data['annually'] &&
    data['quarterly'] &&
    data['annually'].length < data['quarterly'].length

  return showGraph ? (
    <div style={{ height: 420, fontSize: 11 }}>
      <ResponsiveContainer>
        <LineChart
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 0
          }}
        >
          <XAxis
            dataKey='category'
            allowDuplicatedCategory={false}
            axisLine={{
              stroke: '#d7d7d7'
            }}
          />
          <Tooltip content={CustomTooltip} />
          <Legend verticalAlign='top' height={36} />
          <CartesianGrid stroke='#d7d7d7' vertical={false} />
          {showQuarterly && (
            <Line
              dataKey={'amount'}
              connectNulls
              yAxisId='right'
              data={data['quarterly']}
              name={props.intl.formatMessage(messages['quarterly'])}
              key={'quarterly'}
              stroke={'#F5A623'}
              strokeWidth={2}
              dot={{
                fill: 'white',
                fillOpacity: 1,
                stroke: '#F5A623',
                strokeWidth: 2,
                r: 5
              }}
            />
          )}
          {showQuarterly && (
            <YAxis
              yAxisId='right'
              orientation='right'
              tickLine={false}
              tick={{ fill: '#F5A623' }}
              tickFormatter={formatUnit}
            />
          )}
          <Line
            dataKey={'amount'}
            connectNulls
            yAxisId='left'
            data={data['annually']}
            name={props.intl.formatMessage(messages['annually'])}
            key={'annually'}
            stroke={'#4A90E2'}
            strokeWidth={2}
            dot={{
              fill: 'white',
              fillOpacity: 1,
              stroke: '#4A90E2',
              strokeWidth: 2,
              r: 5
            }}
          />
          <YAxis
            yAxisId='left'
            tickLine={false}
            tick={{ fill: '#4A90E2' }}
            tickFormatter={formatUnit}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  ) : null
}

export default injectIntl(SpendOverviewGraph)
