import { fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { requestSuccess, LOGOUT } from 'shared/actionTypes'

const defaultState = fromJS({
  isLoading: false,
  isUploading: false,
  uploadError: false
})

const statsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SUPPLIER_INSIGHT:
      return state.set('isLoading', true)

    case requestSuccess(actionTypes.LOAD_SUPPLIER_INSIGHT):
      return defaultState.merge(fromJS(action.payload.stats))

    case LOGOUT:
      return defaultState

    default:
      return state
  }
}

export default statsReducer
