import React from 'react'
import ParentQuestion from '../ParentQuestion'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import TextQuestion from '../TextQuestion'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import DropdownQuestion from '../DropdownQuestion'

const messages = defineMessages({
  placeholderAdditionalInfo: {
    id: 'placeholderAdditionalInfo',
    defaultMessage: 'Please provide any additional details.'
  }
})

const pageId = 'privacy'

const Privacy = () => {
  const intl = useIntl()
  return (
    <div>
      <ScrollToPosition />
      <ParentQuestion
        pageId={pageId}
        questionId='complyGDPR'
        question={
          <FormattedMessage
            id='Privacy.complyGDPR'
            defaultMessage='Q1. Does your company comply with the EU’s General Data Protection Regulation (GDPR)?'
          />
        }
      />
      <ParentQuestion
        pageId={pageId}
        questionId='designatedPrivacy'
        question={
          <FormattedMessage
            id='Privacy.designatedPrivacy'
            defaultMessage='Q2. Does your company have a designated Privacy Officer or similar position?'
          />
        }
      >
        <div className='pt3'>
          <DropdownQuestion
            className='mb3'
            pageId={pageId}
            parentQuestionId='designatedPrivacy'
            questionId='jobTitle'
            question={
              <FormattedMessage
                id='Privacy.jobTitle'
                defaultMessage='What is their title?'
              />
            }
          />
          <DropdownQuestion
            pageId={pageId}
            parentQuestionId='designatedPrivacy'
            questionId='jobTitleReportTo'
            question={
              <FormattedMessage
                id='Privacy.jobTitleReportTo'
                defaultMessage='To whom (what position title) do they report?'
              />
            }
          />
        </div>
      </ParentQuestion>
      <TextQuestion
        pageId={pageId}
        questionId='note'
        fieldType='textarea'
        question={
          <FormattedMessage
            id='optionalAdditionalNote'
            defaultMessage='Additional information (optional)'
          />
        }
        placeholder={intl.formatMessage(messages.placeholderAdditionalInfo)}
      />
    </div>
  )
}

export default Privacy
