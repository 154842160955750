import React, { useState, useCallback, ChangeEvent } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Button, Dialog, DialogContent } from '@material-ui/core'
import DialogTitle from 'shared/components/DialogTitle'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import TextArea from 'shared/components/TextArea'
import DialogActions from 'shared/components/DialogActions'
import Select from 'shared/components/Select'
import { useDispatch } from 'react-redux'
import { updateData } from 'shared/actions'

type FormValues = {
  origin: string
  requestDetails: string
  urgency: string
}

const initialFormValues: FormValues = {
  origin: '',
  requestDetails: '',
  urgency: 'low'
}

type Props = {
  onAddNewTask: (newTaskValues: FormValues) => void
}

const AddNewTaskDialog = (props: Props) => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues)

  const handleChangeInput = (fieldName: keyof FormValues) => (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    setFormValues({
      ...formValues,
      [fieldName]: e.currentTarget.value
    })

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        onClick={() => setDialogIsOpen(true)}
        startIcon={<AddIcon />}
      >
        CREATE TASK
      </Button>
      <Dialog
        open={dialogIsOpen}
        onExited={() => setFormValues(initialFormValues)}
        fullWidth
      >
        <form
          onSubmit={e => {
            e.preventDefault()
            props.onAddNewTask(formValues)
            setDialogIsOpen(false)
          }}
        >
          <DialogTitle>
            Create a new <span className='fw7'>Support Request</span> task.
          </DialogTitle>
          <DialogContent>
            <Label>Origin of request</Label>
            <Input
              value={formValues.origin}
              name='origin'
              onChange={handleChangeInput('origin')}
            />

            <Label>Request Details</Label>
            <TextArea
              value={formValues.requestDetails}
              name='requestDetails'
              onChange={handleChangeInput('requestDetails')}
              required
            />

            <Label>Urgency</Label>
            <Select name='urgency' onChange={handleChangeInput('urgency')}>
              <option value='low'>Low</option>
              <option value='medium'>Medium</option>
              <option value='High'>High</option>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button
              type='submit'
              variant='contained'
              className='mr3'
              classes={{ root: 'mr3' }}
              color='primary'
            >
              Submit
            </Button>
            <Button onClick={() => setDialogIsOpen(false)}>Cancel</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

const AddNewTaskDialogContainer = () => {
  const dispatch = useDispatch()
  const handleAddNewTask = useCallback(
    newTaskValues => {
      const threeDaysFromToday = new Date()
      threeDaysFromToday.setDate(threeDaysFromToday.getDate() + 3)
      dispatch(
        updateData({
          entity: 'tasks',
          record: {
            form: newTaskValues,
            type: 'SupportRequest',
            responseDate: threeDaysFromToday
          }
        })
      )
    },
    [dispatch]
  )

  return <AddNewTaskDialog onAddNewTask={handleAddNewTask} />
}

export default AddNewTaskDialogContainer
