import React, { Component, Fragment } from 'react'
import { List, RecordOf } from 'immutable'
import ListItem from 'shared/components/List/ListItem'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import OrgLogo from 'shared/components/OrgLogo'
import OrgUnit from 'shared/models/OrgUnit'

type Props = {
  orgs: List<RecordOf<OrgUnit>>
  onClick?: (arg: string | undefined) => void
}

class OrgUnitPicker extends Component<Props> {
  render() {
    const { orgs, onClick } = this.props

    return (
      <Fragment>
        {orgs &&
          orgs.map(orgUnit => (
            <div
              key={orgUnit.get('id')}
              onClick={() => {
                if (onClick) {
                  onClick(orgUnit.get('id'))
                }
              }}
              className='pointer hover-bg-near-white'
            >
              <ListItem
                className='pa2'
                leftAvatar={
                  <OrgLogo
                    url={fileToUrl(orgUnit.getIn(['supplier', 'logo']))}
                    name={orgUnit.get('name')}
                  />
                }
                primaryText={orgUnit.get('name')}
              />
            </div>
          ))}
      </Fragment>
    )
  }
}

export default OrgUnitPicker
