import React, { ChangeEvent } from 'react'
import Label from 'shared/components/Label'
import { List } from 'immutable'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const StyleRadio = withStyles({
  root: {
    padding: 0
  }
})(Radio)

type Props = {
  questionId: string
  answers?: List<string>
  text?: string
  required?: boolean
  value?: string
  onChange: (questionId, value) => void
}

const MultipleChoiceQuestion = (props: Props) => {
  const { questionId, answers, text, required, value, onChange } = props

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(questionId, e.currentTarget.value)
  }

  return (
    <>
      {text && <Label required={required}>{text}</Label>}
      {answers?.map((ans, index) => (
        <div key={`${questionId}-${index}`}>
          <FormControlLabel
            key={`radio-${index}`}
            control={<StyleRadio size='small' color='primary' />}
            value={ans}
            label={ans}
            checked={value === ans}
            onChange={handleChange}
          />
        </div>
      ))}
    </>
  )
}

export default MultipleChoiceQuestion
