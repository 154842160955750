import fileToUrl from '../fileToUrl'

export default function communityParser(community, membership) {
  const {
    logo,
    created,
    modified,
    buyers,

    // remove these properties
    parents,
    expanded,

    ...rest
  } = community

  return {
    membershipId: membership && membership.id,
    logoUrl: fileToUrl(logo),
    createdDate: created && new Date(created.date),
    modifiedDate: modified && new Date(modified.date),
    buyers:
      buyers &&
      buyers.map(buyer => {
        const orgUnit = expanded.OrgUnit
          ? expanded.OrgUnit[buyer.orgUnitId]
          : {}
        return {
          ...buyer,
          ...orgUnit,
          contacts: buyer.contacts
            ? buyer.contacts.map(contact => {
                const user = expanded.User ? expanded.User[contact.user] : {}
                return { ...contact, ...user }
              })
            : [],
          message: buyer.message
        }
      }),
    ...rest
  }
}
