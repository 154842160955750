import React, {
  useRef,
  ReactElement,
  MouseEvent,
  KeyboardEvent,
  forwardRef
} from 'react'
import classNames from 'classnames'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import useThemeColors from '../../utils/useThemeColors'
import { makeStyles } from '@material-ui/core'

type Props = {
  label: string | ReactElement<HTMLElement> | ReactElement<FormattedMessage>
  icon?: ReactElement<HTMLElement>
  title?:
    | string
    | ReactElement<HTMLElement>
    | ReactElement<FormattedMessage>
    | ReactElement<HTMLElement>[]
  onRequestDelete?: (ref: HTMLSpanElement | null) => void
  onToggle?: (e: MouseEvent | KeyboardEvent | undefined) => void
  count?: number
  color?: 'primary' | 'primaryLight' | false
  customColor?: string
  squareCorner?: boolean
  role?: string
}
const useStyles = makeStyles({
  hoverOutline: {
    '&:hover, &:focus': {
      outline: '2px solid black'
    }
  }
})

const Pill = forwardRef<HTMLDivElement, Omit<Props, 'title'>>((props, ref) => {
  const colors = useThemeColors()
  const xElement = useRef<HTMLSpanElement>(null)
  const classes = useStyles()

  const {
    onRequestDelete,
    label,
    icon,
    count,
    onToggle,
    color,
    customColor,
    squareCorner,
    ...rest
  } = props

  const pillColor =
    color === 'primary'
      ? colors.primaryContained
      : color === 'primaryLight'
      ? colors.primaryLightContained
      : customColor || 'bg-light-gray'

  const pillClassName = classNames(
    classes.hoverOutline,
    pillColor,
    squareCorner ? 'br2' : 'br-pill',
    'f7 dib w-auto pv2 ph3 mr2 mt2 bn',
    onToggle && 'pointer',
    onRequestDelete && 'fw1 mid-gray mb1'
  )
  const handleKeydown = e => {
    if (!!onToggle && e.key === 'Enter') {
      onToggle(e)
    }
  }

  return (
    // you need the ...rest props for the Tooltip to work
    <div
      {...rest}
      className={pillClassName}
      onClick={onToggle}
      onKeyDown={handleKeydown}
      ref={ref}
      tabIndex={0}
    >
      {icon && <span className='mr2'>{icon}</span>}
      {label}
      {!!count && <span className='fw6'> - {count}</span>}

      {onRequestDelete && (
        <span
          ref={xElement}
          onClick={e => {
            e.stopPropagation()
            onRequestDelete(xElement.current)
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.stopPropagation()
              onRequestDelete(xElement.current)
            }
          }}
          className='pl2 f4 fw4 black dib v-mid pointer'
          style={{ lineHeight: '0' }}
        >
          ×
        </span>
      )}
    </div>
  )
})

const PillWithTooltip = (props: Props) => {
  const { title, ...rest } = props

  if (title) {
    return (
      <Tooltip
        PopperProps={{
          style: { pointerEvents: 'none' },
          modifiers: {
            preventOverflow: { boundariesElement: 'window' }
          }
        }}
        title={title}
      >
        <Pill {...rest} />
      </Tooltip>
    )
  }

  return <Pill {...rest} />
}

export default PillWithTooltip
