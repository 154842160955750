import React from 'react'
import Text from 'shared/components/Text'
import ExternalLink from 'shared/components/ExternalLink'
import { AttachmentType } from '../../reducers/surveyReducer/surveyReducer'
import { RecordOf } from 'immutable'
import fileToUrl from 'shared/utils/data/fileToUrl'
import startCase from 'lodash.startcase'

type Props = {
  attachment: RecordOf<AttachmentType>
}

const Attachment = ({ attachment }: Props) => {
  return (
    attachment && (
      <div className='mv2 truncate'>
        {attachment.get('docType') && (
          <Text className='fw6' style={{ color: 'black' }}>
            {startCase(attachment.get('docType'))}
          </Text>
        )}
        <Text className='truncate'>
          <ExternalLink href={fileToUrl(attachment) as string}>
            {attachment.get('description')}
          </ExternalLink>
        </Text>
      </div>
    )
  )
}

export default Attachment
