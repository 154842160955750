import { combineReducers } from 'redux-immutable'
import editUser from './editUserReducer'
import addUser from './addUserReducer'
import upload from './uploadReducer'
import loader from './loaderReducer'
import integrations from './integrationsReducer'

const rootReducer = combineReducers({
  editUser,
  addUser,
  upload,
  loader,
  integrations
})

export default rootReducer
