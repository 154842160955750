import React from 'react'
import { Route, Switch } from 'react-router'
import { connect } from 'react-redux'
import SupplierProfilePage from './containers/SupplierProfilePageContainer'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import RootState from 'shared/models/RootState'
import ProfilePageContainer from './containers/ProfilePageContainer'
import PersonalPageContainer from './containers/PersonalPageContainer'
import MyCompanyPageContainer from './containers/MyCompanyPageContainer'
import withResetPagePosition from './containers/withResetPagePosition'
import routes from './routes'
import FormResponse from './containers/FormResponse'
import CustomPage from './containers/CustomPage'

const ProfilePageWithResetPagePos = withResetPagePosition(ProfilePageContainer)
const MyCompanyPageWithResetPagePos = withResetPagePosition(
  MyCompanyPageContainer
)
const PersonalPageWithResetPagePos = withResetPagePosition(
  PersonalPageContainer
)
const FormResponseWithResetPagePos = withResetPagePosition(FormResponse)
const CustomPageWithResetPagePos = withResetPagePosition(CustomPage)

type Props = {
  isTealbot: boolean
  hasSupplierProfileCustomPage: boolean
}

const SupplierProfile = (props: Props) => (
  <SupplierProfilePage>
    <Switch>
      <Route
        exact
        path={routes.basePath}
        component={ProfilePageWithResetPagePos}
      />
      <Route
        path={routes.myCompany}
        component={MyCompanyPageWithResetPagePos}
      />
      {!props.isTealbot && (
        <Route
          path={routes.personal}
          component={PersonalPageWithResetPagePos}
        />
      )}
      <Route
        path={routes.formResponse}
        component={FormResponseWithResetPagePos}
      />
      {props.hasSupplierProfileCustomPage && (
        <Route path={routes.custom}>
          <CustomPageWithResetPagePos />
        </Route>
      )}
    </Switch>
  </SupplierProfilePage>
)

export default connect((state: RootState) => ({
  isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
  hasSupplierProfileCustomPage: !!state.getIn([
    'buyer',
    'settings',
    'supplierProfileCustomPage'
  ])
}))(SupplierProfile)
