import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import mapKeys from 'lodash.mapkeys'
import mapValues from 'lodash.mapvalues'
import communityParser from '../../data/community/parser'
import parseUser from 'shared/utils/data/parseUser'

const apiRoute = `${apiRoutes.authService}/community`

export const createCommunity = community => {
  return api.post(`${apiRoute}`, { ...community }).then(response => response)
}

export const removeClientFromCommunity = ({ orgUnitId, communityId }) => {
  return api.remove(`${apiRoute}/${communityId}/${orgUnitId}/removeClient`)
}

export const updateCommunity = ({ communityId, ...rest }) => {
  return api
    .put(`${apiRoute}/${communityId}/update`, { community: { ...rest } })
    .then(response => response)
}

export const addLogo = ({ communityId, logo }) => {
  return api
    .putFile(`${apiRoute}/${communityId}/addLogo`, { logo })
    .then(response => response)
}

export const addClient = ({ communityId, ...communityBuyer }) => {
  return api
    .put(`${apiRoute}/${communityId}/addClient`, {
      buyer: { ...communityBuyer }
    })
    .then(response => response)
}

export const getSupplierCommunities = (showPublic = true) => {
  return api
    .get(`${apiRoute}/supplier/list/${showPublic ? 'true' : 'false'}`)
    .then(({ invited, accepted, recommended }) => {
      const invitedCommunities = parseListResponse(invited)
      const acceptedCommunities = parseListResponse(accepted)
      const recommendedCommunities = parseListResponse(recommended)

      const usersById = {
        ...invited.reduce((result, invite) => {
          const Users = invite.community.expanded.User
            ? mapValues(invite.community.expanded.User, parseUser)
            : {}
          result = { ...result, ...Users }
          return result
        }, {}),
        ...accepted.reduce((result, accept) => {
          const Users = accept.community.expanded.User
            ? mapValues(accept.community.expanded.User, parseUser)
            : {}
          result = { ...result, ...Users }
          return result
        }, {}),
        ...recommended.reduce((result, recommend) => {
          const Users = recommend.community.expanded.User
            ? mapValues(recommend.community.expanded.User, parseUser)
            : {}
          result = { ...result, ...Users }
          return result
        }, {})
      }

      return {
        byId: {
          ...invitedCommunities,
          ...acceptedCommunities,
          ...recommendedCommunities
        },
        membershipsById: {
          ...extractMemberships(invited),
          ...extractMemberships(accepted),
          ...extractMemberships(recommended)
        },
        invitedIds: invited.map(res => res.community.id),
        acceptedIds: accepted.map(res => res.community.id),
        recommendedIds: recommended.map(res => res.community.id),
        usersById
      }
    })
}

export const acceptCommunity = comId => {
  return api
    .post(`${apiRoute}/supplier/accept/${comId}`)
    .then(response => response)
}

export const declineCommunity = comId => {
  return api
    .post(`${apiRoute}/supplier/decline/${comId}`)
    .then(response => response)
}

export const leaveCommunity = comPlanMembershipId => {
  return api.remove(`${apiRoute}/${comPlanMembershipId}`)
}

export const getClientCommunities = () => {
  return api.get(`${apiRoute}/list`).then(response => {
    const responseWithQuestions = response.reduce(
      (result, currentCommunity) => {
        let questions = currentCommunity.expanded.Question
        if (questions) {
          return { ...result, ...questions }
        }
        return result
      },
      {}
    )
    const communities = mapKeys(
      mapValues(response, res => communityParser(res)),
      community => community.id
    )

    return {
      byId: { ...communities },
      questionsById: { ...responseWithQuestions }
    }
  })
}

export const getClientCommunity = comId => {
  return api.get(`${apiRoute}/lookup/${comId}`).then(response => {
    return {
      byId: {
        [response.id]: communityParser(response)
      },
      questionsById: response.expanded && response.expanded.Question
    }
  })
}

export const removeFromCommunity = ({ communityId, supplierId }) => {
  return api.remove(`${apiRoute}/${communityId}/${supplierId}`).catch(e => {
    return e
  })
}

export const inviteAllExisting = ({ communityId, sendEmail }) => {
  return api.post(`${apiRoute}/invite/existing`, { communityId, sendEmail })
}

export const inviteToCommunity = ({ comId, supplierId }) => {
  return api.post(`${apiRoute}/invite/${comId}`, { supplierId }).catch(e => {
    return e
  })
}

export const inviteToCommunityIframe = ({ client, inviteToComId }) => {
  return api.post(`${apiRoute}/inviteIframe/${client}/${inviteToComId}`)
}

export const invitesToCommunities = ({ comIds, supplierId }) => {
  return Promise.all(
    comIds.map(comId => inviteToCommunity({ comId, supplierId }))
  ).then(results => {
    const successInvites = results.filter(result => !result.statusCode)
    const bySupplierId = successInvites.map(
      membership => membership.parents.Community
    )
    const membershipsById = mapKeys(
      mapValues(successInvites, membership => membership),
      membership => membership.id
    )

    return {
      membershipsById,
      membershipsBySupplierId: { [supplierId]: Object.keys(membershipsById) },
      bySupplierId: { [supplierId]: bySupplierId }
    }
  })
}

export const batchInviteToTier2 = ({ comId, supplierIds }) => {
  return Promise.all(
    supplierIds.map(supplierId => inviteToCommunity({ comId, supplierId }))
  )
}

export const getById = communityId => {
  return api
    .get(`${apiRoute}/supplier/lookup/${communityId}`)
    .then(({ community, communityPlanMembership }) => ({
      community: communityParser(community, communityPlanMembership),
      questionsById: community.expanded && community.expanded.Question,
      membership: communityPlanMembership
    }))
}

export const updateMembership = ({ membershipId, membership }) => {
  return api.post(`${apiRoute}/supplier/update/${membershipId}`, membership)
}

function parseListResponse(result) {
  return mapKeys(
    mapValues(result, res =>
      communityParser(res.community, res.communityPlanMembership)
    ),
    community => community.id
  )
}

function extractMemberships(result) {
  const memberships = result.filter(res => !!res.communityPlanMembership)

  if (memberships.length) {
    return mapKeys(
      mapValues(memberships, res => res.communityPlanMembership),
      comm => comm.id
    )
  }
}
