import React, { Component, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'

type Props = {
  title: string
  icon?: string
}

class CertificationCategoryTitle extends Component<Props> {
  render() {
    const { title, icon } = this.props

    return (
      <Fragment>
        <Typography variant='subtitle1'>
          {title}

          {icon && (
            <div className='dib w1 ml2'>
              <img src={icon} alt={title} className='v-mid' />
            </div>
          )}
        </Typography>

        <hr
          style={{
            height: 1
          }}
          className='bg-dark-gray w-100 ma0 pa0 bn mt1'
        />
      </Fragment>
    )
  }
}

export default CertificationCategoryTitle
