import React, { Component, ComponentProps } from 'react'
import AddUserForm from '../AddUserForm'
import Label from '../Label'
import Input from '../Input'
import { Field } from 'redux-form/immutable'
import { FormattedMessage } from 'react-intl'
import User from 'shared/models/User'
import { RecordOf } from 'immutable'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

function ReduxFormPhoneInput({ input: { value, onChange, onBlur }, meta }) {
  return (
    <PhoneInput
      value={value}
      meta={meta}
      inputComponent={Input}
      defaultCountry={'CA'}
      countryOptionsOrder={['US', 'CA', 'AU', '|', '...']}
      type='tel'
      international
      placeholder='Enter phone number'
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}

function verifyPhoneNumber(value, allValues) {
  return value ? (
    isPossiblePhoneNumber(value) || value === '+1' ? (
      undefined
    ) : (
      <FormattedMessage
        id='AddUserContactForm.InvalidPhonenumber'
        defaultMessage='Invalid phone number'
      />
    )
  ) : (
    undefined
  )
}
type Props = {
  initialValues: RecordOf<User>
} & ComponentProps<typeof AddUserForm>

class AddUserContactForm extends Component<Props> {
  render() {
    const {
      creatingNewUser,
      sendCollaborateRequest,
      initialValues,
      caption,
      ...rest
    } = this.props
    return (
      <AddUserForm
        {...rest}
        creatingNewUser={creatingNewUser}
        sendCollaborateRequest={sendCollaborateRequest}
        caption={
          caption || (
            <FormattedMessage
              id='AddUserContactForm.AddContact'
              defaultMessage='Add Contact'
            />
          )
        }
        userLookupLabel={
          <FormattedMessage
            id='AddUserContactForm.InviteAContact'
            defaultMessage='Invite a contact'
          />
        }
      >
        {(creatingNewUser ||
          !(
            initialValues.getIn(['user', 'mobilePhone']) ||
            initialValues.getIn(['user', 'officePhone'])
          )) && (
          <div>
            <Label>
              <FormattedMessage
                id='AddUserContactForm.Phone'
                defaultMessage='Phone'
              />
            </Label>
            <Field
              component={ReduxFormPhoneInput}
              name='phone'
              validate={verifyPhoneNumber}
              normalize={value => {
                return value === '+1' ? '' : value
              }}
            />
          </div>
        )}
        {(creatingNewUser ||
          !(
            initialValues.getIn(['user', 'location']) ||
            initialValues.getIn(['user', 'location'])
          )) && (
          <div>
            <Label>
              <FormattedMessage
                id='AddUserContactForm.Location'
                defaultMessage='Location'
              />
            </Label>
            <Field component={Input} name='location' />
          </div>
        )}
        {!sendCollaborateRequest && (
          <>
            <Label>
              <FormattedMessage
                id='AddUserContactForm.WhyWouldThisPersonBeContacted'
                defaultMessage='Why would this person be contacted?'
              />
            </Label>
            <Field component={Input} name='contactFor' />
          </>
        )}
      </AddUserForm>
    )
  }
}

export default AddUserContactForm
