import React, { useMemo } from 'react'
import { Route, Redirect, Switch } from 'react-router'
import sessionSelectors from '../../selectors/sessionSelectors'
import AccountsApp from 'accounts/App'
import AdminApp from 'admin/App'
import BuyerApp from 'buyer/App'
import DataPortalApp from 'data-portal/App'
import SupplierApp from 'supplier/App'
import JobsApp from 'jobs/App'
import BadAccountPage from '../../components/BadAccountPage'
import VerifyClientPage from '../../components/VerifyClientPage'
import MissingAgencyPage from '../../components/MissingAgencyPage'
import RedirectWindowTop from '../../components/RedirectWindowTop'
import accountsPaths from 'accounts/routes/paths'
import supplierPaths from 'supplier/routes/paths'
import dataPortalPaths from 'data-portal/routes/paths'
import buyerPaths from 'buyer/routes/paths'
import adminPaths from 'admin/routes/paths'
import jobsPaths from 'jobs/routes'
import paths from '../../routes/paths'
import qs from 'qs'
import PrivateProfilePage from '../../components/PrivateProfilePage'
import { useSelector } from 'react-redux'
import PrivateRoute from './PrivateRoute'
import auth from '../../utils/api/auth'
import LoginWithTokenPage from '../../components/LoginWithTokenPage'

const AppRoutes = () => {
  const userIsLoggedIn = useSelector(sessionSelectors.isLoggedIn)
  const user = useSelector(sessionSelectors.getUser)
  const roles = user?.get('roles')

  const defaultPath = useMemo(() => {
    if (!roles || roles.size === 0) {
      return '/bad-account'
    } else if (roles.some(r => r === 'buyer')) {
      return buyerPaths.basePath
    } else if (roles.some(r => r === 'supplier')) {
      return supplierPaths.basePath
    } else if (roles.some(r => r === 'clickWorker')) {
      return jobsPaths.basePath
    } else {
      return accountsPaths.basePath
    }
  }, [roles])

  return (
    <Switch>
      <PrivateRoute
        path={buyerPaths.basePath}
        children={<BuyerApp />}
        mustHaveAccessToApp='buyer'
      />
      <PrivateRoute
        path={supplierPaths.basePath}
        children={<SupplierApp />}
        mustHaveAccessToApp='supplier'
      />
      <PrivateRoute
        path={dataPortalPaths.basePath}
        children={<DataPortalApp />}
        mustHaveAccessToApp='data-portal'
      />
      <PrivateRoute
        path={adminPaths.basePath}
        children={<AdminApp />}
        mustHaveAccessToApp='admin'
      />

      <PrivateRoute
        path={jobsPaths.basePath}
        children={<JobsApp />}
        mustHaveAccessToApp='click-worker'
      />

      <Route
        path={accountsPaths.basePath}
        render={({ location }) => {
          const { options } = qs.parse(location.search, {
            ignoreQueryPrefix: true
          })
          const forIframe = options && options.includes('iframe')
          return userIsLoggedIn && !forIframe ? (
            <Redirect to={defaultPath} />
          ) : (
            <AccountsApp />
          )
        }}
      />
      <Route path='/punchout'>
        <LoginWithTokenPage loginCallback={auth.loginWithPunchoutToken} />
      </Route>
      <Route path='/bad-account' component={BadAccountPage} />
      <Route path='/verify-client' component={VerifyClientPage} />
      <Route path='/missing-agency' component={MissingAgencyPage} />
      <Route path='/private-profile' component={PrivateProfilePage} />
      <Route path={paths.redirectWindowTop} component={RedirectWindowTop} />
      <Redirect from='/' to={accountsPaths.basePath} />
    </Switch>
  )
}

export default AppRoutes
