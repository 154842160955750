import { defineMessages } from 'react-intl'

const naics = defineMessages({
  '11': {
    id: 'naics-11',
    defaultMessage: 'Agriculture, Forestry, Fishing and Hunting'
  },
  '21': {
    id: 'naics-21',
    defaultMessage: 'Mining, Quarrying, and Oil and Gas Extraction'
  },
  '22': {
    id: 'naics-22',
    defaultMessage: 'Utilities'
  },
  '23': {
    id: 'naics-23',
    defaultMessage: 'Construction'
  },
  '42': {
    id: 'naics-42',
    defaultMessage: 'Wholesale Trade'
  },
  '51': {
    id: 'naics-51',
    defaultMessage: 'Information'
  },
  '52': {
    id: 'naics-52',
    defaultMessage: 'Finance and Insurance'
  },
  '53': {
    id: 'naics-53',
    defaultMessage: 'Real Estate and Rental and Leasing'
  },
  '54': {
    id: 'naics-54',
    defaultMessage: 'Professional, Scientific, and Technical Services'
  },
  '55': {
    id: 'naics-55',
    defaultMessage: 'Management of Companies and Enterprises'
  },
  '56': {
    id: 'naics-56',
    defaultMessage:
      'Administrative and Support and Waste Management and Remediation Services'
  },
  '61': {
    id: 'naics-61',
    defaultMessage: 'Educational Services'
  },
  '62': {
    id: 'naics-62',
    defaultMessage: 'Health Care and Social Assistance'
  },
  '71': {
    id: 'naics-71',
    defaultMessage: 'Arts, Entertainment, and Recreation'
  },
  '72': {
    id: 'naics-72',
    defaultMessage: 'Accommodation and Food Services'
  },
  '81': {
    id: 'naics-81',
    defaultMessage: 'Other Services (except Public Administration)'
  },
  '92': {
    id: 'naics-92',
    defaultMessage: 'Public Administration'
  },
  '111': {
    id: 'naics-111',
    defaultMessage: 'Crop Production'
  },
  '112': {
    id: 'naics-112',
    defaultMessage: 'Animal Production and Aquaculture'
  },
  '113': {
    id: 'naics-113',
    defaultMessage: 'Forestry and Logging'
  },
  '114': {
    id: 'naics-114',
    defaultMessage: 'Fishing, Hunting and Trapping'
  },
  '115': {
    id: 'naics-115',
    defaultMessage: 'Support Activities for Agriculture and Forestry'
  },
  '211': {
    id: 'naics-211',
    defaultMessage: 'Oil and Gas Extraction'
  },
  '212': {
    id: 'naics-212',
    defaultMessage: 'Mining (except Oil and Gas)'
  },
  '213': {
    id: 'naics-213',
    defaultMessage: 'Support Activities for Mining'
  },
  '221': {
    id: 'naics-221',
    defaultMessage: 'Utilities'
  },
  '236': {
    id: 'naics-236',
    defaultMessage: 'Construction of Buildings'
  },
  '237': {
    id: 'naics-237',
    defaultMessage: 'Heavy and Civil Engineering Construction'
  },
  '238': {
    id: 'naics-238',
    defaultMessage: 'Specialty Trade Contractors'
  },
  '311': {
    id: 'naics-311',
    defaultMessage: 'Food Manufacturing'
  },
  '312': {
    id: 'naics-312',
    defaultMessage: 'Beverage and Tobacco Product Manufacturing'
  },
  '313': {
    id: 'naics-313',
    defaultMessage: 'Textile Mills'
  },
  '314': {
    id: 'naics-314',
    defaultMessage: 'Textile Product Mills'
  },
  '315': {
    id: 'naics-315',
    defaultMessage: 'Apparel Manufacturing'
  },
  '316': {
    id: 'naics-316',
    defaultMessage: 'Leather and Allied Product Manufacturing'
  },
  '321': {
    id: 'naics-321',
    defaultMessage: 'Wood Product Manufacturing'
  },
  '322': {
    id: 'naics-322',
    defaultMessage: 'Paper Manufacturing'
  },
  '323': {
    id: 'naics-323',
    defaultMessage: 'Printing and Related Support Activities'
  },
  '324': {
    id: 'naics-324',
    defaultMessage: 'Petroleum and Coal Products Manufacturing'
  },
  '325': {
    id: 'naics-325',
    defaultMessage: 'Chemical Manufacturing'
  },
  '326': {
    id: 'naics-326',
    defaultMessage: 'Plastics and Rubber Products Manufacturing'
  },
  '327': {
    id: 'naics-327',
    defaultMessage: 'Nonmetallic Mineral Product Manufacturing'
  },
  '331': {
    id: 'naics-331',
    defaultMessage: 'Primary Metal Manufacturing'
  },
  '332': {
    id: 'naics-332',
    defaultMessage: 'Fabricated Metal Product Manufacturing'
  },
  '333': {
    id: 'naics-333',
    defaultMessage: 'Machinery Manufacturing'
  },
  '334': {
    id: 'naics-334',
    defaultMessage: 'Computer and Electronic Product Manufacturing'
  },
  '335': {
    id: 'naics-335',
    defaultMessage:
      'Electrical Equipment, Appliance, and Component Manufacturing'
  },
  '336': {
    id: 'naics-336',
    defaultMessage: 'Transportation Equipment Manufacturing'
  },
  '337': {
    id: 'naics-337',
    defaultMessage: 'Furniture and Related Product Manufacturing'
  },
  '339': {
    id: 'naics-339',
    defaultMessage: 'Miscellaneous Manufacturing'
  },
  '423': {
    id: 'naics-423',
    defaultMessage: 'Merchant Wholesalers, Durable Goods'
  },
  '424': {
    id: 'naics-424',
    defaultMessage: 'Merchant Wholesalers, Nondurable Goods'
  },
  '425': {
    id: 'naics-425',
    defaultMessage: 'Wholesale Electronic Markets and Agents and Brokers'
  },
  '441': {
    id: 'naics-441',
    defaultMessage: 'Motor Vehicle and Parts Dealers'
  },
  '442': {
    id: 'naics-442',
    defaultMessage: 'Furniture and Home Furnishings Stores'
  },
  '443': {
    id: 'naics-443',
    defaultMessage: 'Electronics and Appliance Stores'
  },
  '444': {
    id: 'naics-444',
    defaultMessage:
      'Building Material and Garden Equipment and Supplies Dealers'
  },
  '445': {
    id: 'naics-445',
    defaultMessage: 'Food and Beverage Stores'
  },
  '446': {
    id: 'naics-446',
    defaultMessage: 'Health and Personal Care Stores'
  },
  '447': {
    id: 'naics-447',
    defaultMessage: 'Gasoline Stations'
  },
  '448': {
    id: 'naics-448',
    defaultMessage: 'Clothing and Clothing Accessories Stores'
  },
  '451': {
    id: 'naics-451',
    defaultMessage: 'Sporting Goods, Hobby, Musical Instrument, and Book Stores'
  },
  '452': {
    id: 'naics-452',
    defaultMessage: 'General Merchandise Stores'
  },
  '453': {
    id: 'naics-453',
    defaultMessage: 'Miscellaneous Store Retailers'
  },
  '454': {
    id: 'naics-454',
    defaultMessage: 'Nonstore Retailers'
  },
  '481': {
    id: 'naics-481',
    defaultMessage: 'Air Transportation'
  },
  '482': {
    id: 'naics-482',
    defaultMessage: 'Rail Transportation'
  },
  '483': {
    id: 'naics-483',
    defaultMessage: 'Water Transportation'
  },
  '484': {
    id: 'naics-484',
    defaultMessage: 'Truck Transportation'
  },
  '485': {
    id: 'naics-485',
    defaultMessage: 'Transit and Ground Passenger Transportation'
  },
  '486': {
    id: 'naics-486',
    defaultMessage: 'Pipeline Transportation'
  },
  '487': {
    id: 'naics-487',
    defaultMessage: 'Scenic and Sightseeing Transportation'
  },
  '488': {
    id: 'naics-488',
    defaultMessage: 'Support Activities for Transportation'
  },
  '491': {
    id: 'naics-491',
    defaultMessage: 'Postal Service'
  },
  '492': {
    id: 'naics-492',
    defaultMessage: 'Couriers and Messengers'
  },
  '493': {
    id: 'naics-493',
    defaultMessage: 'Warehousing and Storage'
  },
  '511': {
    id: 'naics-511',
    defaultMessage: 'Publishing Industries (except Internet)'
  },
  '512': {
    id: 'naics-512',
    defaultMessage: 'Motion Picture and Sound Recording Industries'
  },
  '515': {
    id: 'naics-515',
    defaultMessage: 'Broadcasting (except Internet)'
  },
  '517': {
    id: 'naics-517',
    defaultMessage: 'Telecommunications'
  },
  '518': {
    id: 'naics-518',
    defaultMessage: 'Data Processing, Hosting, and Related Services'
  },
  '519': {
    id: 'naics-519',
    defaultMessage: 'Other Information Services'
  },
  '521': {
    id: 'naics-521',
    defaultMessage: 'Monetary Authorities-Central Bank'
  },
  '522': {
    id: 'naics-522',
    defaultMessage: 'Credit Intermediation and Related Activities'
  },
  '523': {
    id: 'naics-523',
    defaultMessage:
      'Securities, Commodity Contracts, and Other Financial Investments and Related Activities'
  },
  '524': {
    id: 'naics-524',
    defaultMessage: 'Insurance Carriers and Related Activities'
  },
  '525': {
    id: 'naics-525',
    defaultMessage: 'Funds, Trusts, and Other Financial Vehicles'
  },
  '531': {
    id: 'naics-531',
    defaultMessage: 'Real Estate'
  },
  '532': {
    id: 'naics-532',
    defaultMessage: 'Rental and Leasing Services'
  },
  '533': {
    id: 'naics-533',
    defaultMessage:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)'
  },
  '541': {
    id: 'naics-541',
    defaultMessage: 'Professional, Scientific, and Technical Services'
  },
  '551': {
    id: 'naics-551',
    defaultMessage: 'Management of Companies and Enterprises'
  },
  '561': {
    id: 'naics-561',
    defaultMessage: 'Administrative and Support Services'
  },
  '562': {
    id: 'naics-562',
    defaultMessage: 'Waste Management and Remediation Services'
  },
  '611': {
    id: 'naics-611',
    defaultMessage: 'Educational Services'
  },
  '621': {
    id: 'naics-621',
    defaultMessage: 'Ambulatory Health Care Services'
  },
  '622': {
    id: 'naics-622',
    defaultMessage: 'Hospitals'
  },
  '623': {
    id: 'naics-623',
    defaultMessage: 'Nursing and Residential Care Facilities'
  },
  '624': {
    id: 'naics-624',
    defaultMessage: 'Social Assistance'
  },
  '711': {
    id: 'naics-711',
    defaultMessage: 'Performing Arts, Spectator Sports, and Related Industries'
  },
  '712': {
    id: 'naics-712',
    defaultMessage: 'Museums, Historical Sites, and Similar Institutions'
  },
  '713': {
    id: 'naics-713',
    defaultMessage: 'Amusement, Gambling, and Recreation Industries'
  },
  '721': {
    id: 'naics-721',
    defaultMessage: 'Accommodation'
  },
  '722': {
    id: 'naics-722',
    defaultMessage: 'Food Services and Drinking Places'
  },
  '811': {
    id: 'naics-811',
    defaultMessage: 'Repair and Maintenance'
  },
  '812': {
    id: 'naics-812',
    defaultMessage: 'Personal and Laundry Services'
  },
  '813': {
    id: 'naics-813',
    defaultMessage:
      'Religious, Grantmaking, Civic, Professional, and Similar Organizations'
  },
  '814': {
    id: 'naics-814',
    defaultMessage: 'Private Households'
  },
  '921': {
    id: 'naics-921',
    defaultMessage:
      'Executive, Legislative, and Other General Government Support'
  },
  '922': {
    id: 'naics-922',
    defaultMessage: 'Justice, Public Order, and Safety Activities'
  },
  '923': {
    id: 'naics-923',
    defaultMessage: 'Administration of Human Resource Programs'
  },
  '924': {
    id: 'naics-924',
    defaultMessage: 'Administration of Environmental Quality Programs'
  },
  '925': {
    id: 'naics-925',
    defaultMessage:
      'Administration of Housing Programs, Urban Planning, and Community Development'
  },
  '926': {
    id: 'naics-926',
    defaultMessage: 'Administration of Economic Programs'
  },
  '927': {
    id: 'naics-927',
    defaultMessage: 'Space Research and Technology'
  },
  '928': {
    id: 'naics-928',
    defaultMessage: 'National Security and International Affairs'
  },
  '1111': {
    id: 'naics-1111',
    defaultMessage: 'Oilseed and Grain Farming'
  },
  '1112': {
    id: 'naics-1112',
    defaultMessage: 'Vegetable and Melon Farming'
  },
  '1113': {
    id: 'naics-1113',
    defaultMessage: 'Fruit and Tree Nut Farming'
  },
  '1114': {
    id: 'naics-1114',
    defaultMessage: 'Greenhouse, Nursery, and Floriculture Production'
  },
  '1119': {
    id: 'naics-1119',
    defaultMessage: 'Other Crop Farming'
  },
  '1121': {
    id: 'naics-1121',
    defaultMessage: 'Cattle Ranching and Farming'
  },
  '1122': {
    id: 'naics-1122',
    defaultMessage: 'Hog and Pig Farming'
  },
  '1123': {
    id: 'naics-1123',
    defaultMessage: 'Poultry and Egg Production'
  },
  '1124': {
    id: 'naics-1124',
    defaultMessage: 'Sheep and Goat Farming'
  },
  '1125': {
    id: 'naics-1125',
    defaultMessage: 'Aquaculture'
  },
  '1129': {
    id: 'naics-1129',
    defaultMessage: 'Other Animal Production'
  },
  '1131': {
    id: 'naics-1131',
    defaultMessage: 'Timber Tract Operations'
  },
  '1132': {
    id: 'naics-1132',
    defaultMessage: 'Forest Nurseries and Gathering of Forest Products'
  },
  '1133': {
    id: 'naics-1133',
    defaultMessage: 'Logging'
  },
  '1141': {
    id: 'naics-1141',
    defaultMessage: 'Fishing'
  },
  '1142': {
    id: 'naics-1142',
    defaultMessage: 'Hunting and Trapping'
  },
  '1151': {
    id: 'naics-1151',
    defaultMessage: 'Support Activities for Crop Production'
  },
  '1152': {
    id: 'naics-1152',
    defaultMessage: 'Support Activities for Animal Production'
  },
  '1153': {
    id: 'naics-1153',
    defaultMessage: 'Support Activities for Forestry'
  },
  '2111': {
    id: 'naics-2111',
    defaultMessage: 'Oil and Gas Extraction'
  },
  '2121': {
    id: 'naics-2121',
    defaultMessage: 'Coal Mining'
  },
  '2122': {
    id: 'naics-2122',
    defaultMessage: 'Metal Ore Mining'
  },
  '2123': {
    id: 'naics-2123',
    defaultMessage: 'Nonmetallic Mineral Mining and Quarrying'
  },
  '2131': {
    id: 'naics-2131',
    defaultMessage: 'Support Activities for Mining'
  },
  '2211': {
    id: 'naics-2211',
    defaultMessage: 'Electric Power Generation, Transmission and Distribution'
  },
  '2212': {
    id: 'naics-2212',
    defaultMessage: 'Natural Gas Distribution'
  },
  '2213': {
    id: 'naics-2213',
    defaultMessage: 'Water, Sewage and Other Systems'
  },
  '2361': {
    id: 'naics-2361',
    defaultMessage: 'Residential Building Construction'
  },
  '2362': {
    id: 'naics-2362',
    defaultMessage: 'Nonresidential Building Construction'
  },
  '2371': {
    id: 'naics-2371',
    defaultMessage: 'Utility System Construction'
  },
  '2372': {
    id: 'naics-2372',
    defaultMessage: 'Land Subdivision'
  },
  '2373': {
    id: 'naics-2373',
    defaultMessage: 'Highway, Street, and Bridge Construction'
  },
  '2379': {
    id: 'naics-2379',
    defaultMessage: 'Other Heavy and Civil Engineering Construction'
  },
  '2381': {
    id: 'naics-2381',
    defaultMessage: 'Foundation, Structure, and Building Exterior Contractors'
  },
  '2382': {
    id: 'naics-2382',
    defaultMessage: 'Building Equipment Contractors'
  },
  '2383': {
    id: 'naics-2383',
    defaultMessage: 'Building Finishing Contractors'
  },
  '2389': {
    id: 'naics-2389',
    defaultMessage: 'Other Specialty Trade Contractors'
  },
  '3111': {
    id: 'naics-3111',
    defaultMessage: 'Animal Food Manufacturing'
  },
  '3112': {
    id: 'naics-3112',
    defaultMessage: 'Grain and Oilseed Milling'
  },
  '3113': {
    id: 'naics-3113',
    defaultMessage: 'Sugar and Confectionery Product Manufacturing'
  },
  '3114': {
    id: 'naics-3114',
    defaultMessage:
      'Fruit and Vegetable Preserving and Specialty Food Manufacturing'
  },
  '3115': {
    id: 'naics-3115',
    defaultMessage: 'Dairy Product Manufacturing'
  },
  '3116': {
    id: 'naics-3116',
    defaultMessage: 'Animal Slaughtering and Processing'
  },
  '3117': {
    id: 'naics-3117',
    defaultMessage: 'Seafood Product Preparation and Packaging'
  },
  '3118': {
    id: 'naics-3118',
    defaultMessage: 'Bakeries and Tortilla Manufacturing'
  },
  '3119': {
    id: 'naics-3119',
    defaultMessage: 'Other Food Manufacturing'
  },
  '3121': {
    id: 'naics-3121',
    defaultMessage: 'Beverage Manufacturing'
  },
  '3122': {
    id: 'naics-3122',
    defaultMessage: 'Tobacco Manufacturing'
  },
  '3131': {
    id: 'naics-3131',
    defaultMessage: 'Fiber, Yarn, and Thread Mills'
  },
  '3132': {
    id: 'naics-3132',
    defaultMessage: 'Fabric Mills'
  },
  '3133': {
    id: 'naics-3133',
    defaultMessage: 'Textile and Fabric Finishing and Fabric Coating Mills'
  },
  '3141': {
    id: 'naics-3141',
    defaultMessage: 'Textile Furnishings Mills'
  },
  '3149': {
    id: 'naics-3149',
    defaultMessage: 'Other Textile Product Mills'
  },
  '3151': {
    id: 'naics-3151',
    defaultMessage: 'Apparel Knitting Mills'
  },
  '3152': {
    id: 'naics-3152',
    defaultMessage: 'Cut and Sew Apparel Manufacturing'
  },
  '3159': {
    id: 'naics-3159',
    defaultMessage: 'Apparel Accessories and Other Apparel Manufacturing'
  },
  '3161': {
    id: 'naics-3161',
    defaultMessage: 'Leather and Hide Tanning and Finishing'
  },
  '3162': {
    id: 'naics-3162',
    defaultMessage: 'Footwear Manufacturing'
  },
  '3169': {
    id: 'naics-3169',
    defaultMessage: 'Other Leather and Allied Product Manufacturing'
  },
  '3211': {
    id: 'naics-3211',
    defaultMessage: 'Sawmills and Wood Preservation'
  },
  '3212': {
    id: 'naics-3212',
    defaultMessage: 'Veneer, Plywood, and Engineered Wood Product Manufacturing'
  },
  '3219': {
    id: 'naics-3219',
    defaultMessage: 'Other Wood Product Manufacturing'
  },
  '3221': {
    id: 'naics-3221',
    defaultMessage: 'Pulp, Paper, and Paperboard Mills'
  },
  '3222': {
    id: 'naics-3222',
    defaultMessage: 'Converted Paper Product Manufacturing'
  },
  '3231': {
    id: 'naics-3231',
    defaultMessage: 'Printing and Related Support Activities'
  },
  '3241': {
    id: 'naics-3241',
    defaultMessage: 'Petroleum and Coal Products Manufacturing'
  },
  '3251': {
    id: 'naics-3251',
    defaultMessage: 'Basic Chemical Manufacturing'
  },
  '3252': {
    id: 'naics-3252',
    defaultMessage:
      'Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing'
  },
  '3253': {
    id: 'naics-3253',
    defaultMessage:
      'Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing'
  },
  '3254': {
    id: 'naics-3254',
    defaultMessage: 'Pharmaceutical and Medicine Manufacturing'
  },
  '3255': {
    id: 'naics-3255',
    defaultMessage: 'Paint, Coating, and Adhesive Manufacturing'
  },
  '3256': {
    id: 'naics-3256',
    defaultMessage:
      'Soap, Cleaning Compound, and Toilet Preparation Manufacturing'
  },
  '3259': {
    id: 'naics-3259',
    defaultMessage: 'Other Chemical Product and Preparation Manufacturing'
  },
  '3261': {
    id: 'naics-3261',
    defaultMessage: 'Plastics Product Manufacturing'
  },
  '3262': {
    id: 'naics-3262',
    defaultMessage: 'Rubber Product Manufacturing'
  },
  '3271': {
    id: 'naics-3271',
    defaultMessage: 'Clay Product and Refractory Manufacturing'
  },
  '3272': {
    id: 'naics-3272',
    defaultMessage: 'Glass and Glass Product Manufacturing'
  },
  '3273': {
    id: 'naics-3273',
    defaultMessage: 'Cement and Concrete Product Manufacturing'
  },
  '3274': {
    id: 'naics-3274',
    defaultMessage: 'Lime and Gypsum Product Manufacturing'
  },
  '3279': {
    id: 'naics-3279',
    defaultMessage: 'Other Nonmetallic Mineral Product Manufacturing'
  },
  '3311': {
    id: 'naics-3311',
    defaultMessage: 'Iron and Steel Mills and Ferroalloy Manufacturing'
  },
  '3312': {
    id: 'naics-3312',
    defaultMessage: 'Steel Product Manufacturing from Purchased Steel'
  },
  '3313': {
    id: 'naics-3313',
    defaultMessage: 'Alumina and Aluminum Production and Processing'
  },
  '3314': {
    id: 'naics-3314',
    defaultMessage:
      'Nonferrous Metal (except Aluminum) Production and Processing'
  },
  '3315': {
    id: 'naics-3315',
    defaultMessage: 'Foundries'
  },
  '3321': {
    id: 'naics-3321',
    defaultMessage: 'Forging and Stamping'
  },
  '3322': {
    id: 'naics-3322',
    defaultMessage: 'Cutlery and Handtool Manufacturing'
  },
  '3323': {
    id: 'naics-3323',
    defaultMessage: 'Architectural and Structural Metals Manufacturing'
  },
  '3324': {
    id: 'naics-3324',
    defaultMessage: 'Boiler, Tank, and Shipping Container Manufacturing'
  },
  '3325': {
    id: 'naics-3325',
    defaultMessage: 'Hardware Manufacturing'
  },
  '3326': {
    id: 'naics-3326',
    defaultMessage: 'Spring and Wire Product Manufacturing'
  },
  '3327': {
    id: 'naics-3327',
    defaultMessage:
      'Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing'
  },
  '3328': {
    id: 'naics-3328',
    defaultMessage: 'Coating, Engraving, Heat Treating, and Allied Activities'
  },
  '3329': {
    id: 'naics-3329',
    defaultMessage: 'Other Fabricated Metal Product Manufacturing'
  },
  '3331': {
    id: 'naics-3331',
    defaultMessage:
      'Agriculture, Construction, and Mining Machinery Manufacturing'
  },
  '3332': {
    id: 'naics-3332',
    defaultMessage: 'Industrial Machinery Manufacturing'
  },
  '3333': {
    id: 'naics-3333',
    defaultMessage: 'Commercial and Service Industry Machinery Manufacturing'
  },
  '3334': {
    id: 'naics-3334',
    defaultMessage:
      'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing'
  },
  '3335': {
    id: 'naics-3335',
    defaultMessage: 'Metalworking Machinery Manufacturing'
  },
  '3336': {
    id: 'naics-3336',
    defaultMessage:
      'Engine, Turbine, and Power Transmission Equipment Manufacturing'
  },
  '3339': {
    id: 'naics-3339',
    defaultMessage: 'Other General Purpose Machinery Manufacturing'
  },
  '3341': {
    id: 'naics-3341',
    defaultMessage: 'Computer and Peripheral Equipment Manufacturing'
  },
  '3342': {
    id: 'naics-3342',
    defaultMessage: 'Communications Equipment Manufacturing'
  },
  '3343': {
    id: 'naics-3343',
    defaultMessage: 'Audio and Video Equipment Manufacturing'
  },
  '3344': {
    id: 'naics-3344',
    defaultMessage: 'Semiconductor and Other Electronic Component Manufacturing'
  },
  '3345': {
    id: 'naics-3345',
    defaultMessage:
      'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing'
  },
  '3346': {
    id: 'naics-3346',
    defaultMessage: 'Manufacturing and Reproducing Magnetic and Optical Media'
  },
  '3351': {
    id: 'naics-3351',
    defaultMessage: 'Electric Lighting Equipment Manufacturing'
  },
  '3352': {
    id: 'naics-3352',
    defaultMessage: 'Household Appliance Manufacturing'
  },
  '3353': {
    id: 'naics-3353',
    defaultMessage: 'Electrical Equipment Manufacturing'
  },
  '3359': {
    id: 'naics-3359',
    defaultMessage: 'Other Electrical Equipment and Component Manufacturing'
  },
  '3361': {
    id: 'naics-3361',
    defaultMessage: 'Motor Vehicle Manufacturing'
  },
  '3362': {
    id: 'naics-3362',
    defaultMessage: 'Motor Vehicle Body and Trailer Manufacturing'
  },
  '3363': {
    id: 'naics-3363',
    defaultMessage: 'Motor Vehicle Parts Manufacturing'
  },
  '3364': {
    id: 'naics-3364',
    defaultMessage: 'Aerospace Product and Parts Manufacturing'
  },
  '3365': {
    id: 'naics-3365',
    defaultMessage: 'Railroad Rolling Stock Manufacturing'
  },
  '3366': {
    id: 'naics-3366',
    defaultMessage: 'Ship and Boat Building'
  },
  '3369': {
    id: 'naics-3369',
    defaultMessage: 'Other Transportation Equipment Manufacturing'
  },
  '3371': {
    id: 'naics-3371',
    defaultMessage:
      'Household and Institutional Furniture and Kitchen Cabinet Manufacturing'
  },
  '3372': {
    id: 'naics-3372',
    defaultMessage: 'Office Furniture (including Fixtures) Manufacturing'
  },
  '3379': {
    id: 'naics-3379',
    defaultMessage: 'Other Furniture Related Product Manufacturing'
  },
  '3391': {
    id: 'naics-3391',
    defaultMessage: 'Medical Equipment and Supplies Manufacturing'
  },
  '3399': {
    id: 'naics-3399',
    defaultMessage: 'Other Miscellaneous Manufacturing'
  },
  '4231': {
    id: 'naics-4231',
    defaultMessage:
      'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers'
  },
  '4232': {
    id: 'naics-4232',
    defaultMessage: 'Furniture and Home Furnishing Merchant Wholesalers'
  },
  '4233': {
    id: 'naics-4233',
    defaultMessage:
      'Lumber and Other Construction Materials Merchant Wholesalers'
  },
  '4234': {
    id: 'naics-4234',
    defaultMessage:
      'Professional and Commercial Equipment and Supplies Merchant Wholesalers'
  },
  '4235': {
    id: 'naics-4235',
    defaultMessage: 'Metal and Mineral (except Petroleum) Merchant Wholesalers'
  },
  '4236': {
    id: 'naics-4236',
    defaultMessage:
      'Household Appliances and Electrical and Electronic Goods Merchant Wholesalers'
  },
  '4237': {
    id: 'naics-4237',
    defaultMessage:
      'Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers'
  },
  '4238': {
    id: 'naics-4238',
    defaultMessage: 'Machinery, Equipment, and Supplies Merchant Wholesalers'
  },
  '4239': {
    id: 'naics-4239',
    defaultMessage: 'Miscellaneous Durable Goods Merchant Wholesalers'
  },
  '4241': {
    id: 'naics-4241',
    defaultMessage: 'Paper and Paper Product Merchant Wholesalers'
  },
  '4242': {
    id: 'naics-4242',
    defaultMessage: "Drugs and Druggists' Sundries Merchant Wholesalers"
  },
  '4243': {
    id: 'naics-4243',
    defaultMessage: 'Apparel, Piece Goods, and Notions Merchant Wholesalers'
  },
  '4244': {
    id: 'naics-4244',
    defaultMessage: 'Grocery and Related Product Merchant Wholesalers'
  },
  '4245': {
    id: 'naics-4245',
    defaultMessage: 'Farm Product Raw Material Merchant Wholesalers'
  },
  '4246': {
    id: 'naics-4246',
    defaultMessage: 'Chemical and Allied Products Merchant Wholesalers'
  },
  '4247': {
    id: 'naics-4247',
    defaultMessage: 'Petroleum and Petroleum Products Merchant Wholesalers'
  },
  '4248': {
    id: 'naics-4248',
    defaultMessage:
      'Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers'
  },
  '4249': {
    id: 'naics-4249',
    defaultMessage: 'Miscellaneous Nondurable Goods Merchant Wholesalers'
  },
  '4251': {
    id: 'naics-4251',
    defaultMessage: 'Wholesale Electronic Markets and Agents and Brokers'
  },
  '4411': {
    id: 'naics-4411',
    defaultMessage: 'Automobile Dealers'
  },
  '4412': {
    id: 'naics-4412',
    defaultMessage: 'Other Motor Vehicle Dealers'
  },
  '4413': {
    id: 'naics-4413',
    defaultMessage: 'Automotive Parts, Accessories, and Tire Stores'
  },
  '4421': {
    id: 'naics-4421',
    defaultMessage: 'Furniture Stores'
  },
  '4422': {
    id: 'naics-4422',
    defaultMessage: 'Home Furnishings Stores'
  },
  '4431': {
    id: 'naics-4431',
    defaultMessage: 'Electronics and Appliance Stores'
  },
  '4441': {
    id: 'naics-4441',
    defaultMessage: 'Building Material and Supplies Dealers'
  },
  '4442': {
    id: 'naics-4442',
    defaultMessage: 'Lawn and Garden Equipment and Supplies Stores'
  },
  '4451': {
    id: 'naics-4451',
    defaultMessage: 'Grocery Stores'
  },
  '4452': {
    id: 'naics-4452',
    defaultMessage: 'Specialty Food Stores'
  },
  '4453': {
    id: 'naics-4453',
    defaultMessage: 'Beer, Wine, and Liquor Stores'
  },
  '4461': {
    id: 'naics-4461',
    defaultMessage: 'Health and Personal Care Stores'
  },
  '4471': {
    id: 'naics-4471',
    defaultMessage: 'Gasoline Stations'
  },
  '4481': {
    id: 'naics-4481',
    defaultMessage: 'Clothing Stores'
  },
  '4482': {
    id: 'naics-4482',
    defaultMessage: 'Shoe Stores'
  },
  '4483': {
    id: 'naics-4483',
    defaultMessage: 'Jewelry, Luggage, and Leather Goods Stores'
  },
  '4511': {
    id: 'naics-4511',
    defaultMessage: 'Sporting Goods, Hobby, and Musical Instrument Stores'
  },
  '4512': {
    id: 'naics-4512',
    defaultMessage: 'Book Stores and News Dealers'
  },
  '4522': {
    id: 'naics-4522',
    defaultMessage: 'Department Stores'
  },
  '4523': {
    id: 'naics-4523',
    defaultMessage:
      'General Merchandise Stores, including Warehouse Clubs and Supercenters'
  },
  '4531': {
    id: 'naics-4531',
    defaultMessage: 'Florists'
  },
  '4532': {
    id: 'naics-4532',
    defaultMessage: 'Office Supplies, Stationery, and Gift Stores'
  },
  '4533': {
    id: 'naics-4533',
    defaultMessage: 'Used Merchandise Stores'
  },
  '4539': {
    id: 'naics-4539',
    defaultMessage: 'Other Miscellaneous Store Retailers'
  },
  '4541': {
    id: 'naics-4541',
    defaultMessage: 'Electronic Shopping and Mail-Order Houses'
  },
  '4542': {
    id: 'naics-4542',
    defaultMessage: 'Vending Machine Operators'
  },
  '4543': {
    id: 'naics-4543',
    defaultMessage: 'Direct Selling Establishments'
  },
  '4811': {
    id: 'naics-4811',
    defaultMessage: 'Scheduled Air Transportation'
  },
  '4812': {
    id: 'naics-4812',
    defaultMessage: 'Nonscheduled Air Transportation'
  },
  '4821': {
    id: 'naics-4821',
    defaultMessage: 'Rail Transportation'
  },
  '4831': {
    id: 'naics-4831',
    defaultMessage: 'Deep Sea, Coastal, and Great Lakes Water Transportation'
  },
  '4832': {
    id: 'naics-4832',
    defaultMessage: 'Inland Water Transportation'
  },
  '4841': {
    id: 'naics-4841',
    defaultMessage: 'General Freight Trucking'
  },
  '4842': {
    id: 'naics-4842',
    defaultMessage: 'Specialized Freight Trucking'
  },
  '4851': {
    id: 'naics-4851',
    defaultMessage: 'Urban Transit Systems'
  },
  '4852': {
    id: 'naics-4852',
    defaultMessage: 'Interurban and Rural Bus Transportation'
  },
  '4853': {
    id: 'naics-4853',
    defaultMessage: 'Taxi and Limousine Service'
  },
  '4854': {
    id: 'naics-4854',
    defaultMessage: 'School and Employee Bus Transportation'
  },
  '4855': {
    id: 'naics-4855',
    defaultMessage: 'Charter Bus Industry'
  },
  '4859': {
    id: 'naics-4859',
    defaultMessage: 'Other Transit and Ground Passenger Transportation'
  },
  '4861': {
    id: 'naics-4861',
    defaultMessage: 'Pipeline Transportation of Crude Oil'
  },
  '4862': {
    id: 'naics-4862',
    defaultMessage: 'Pipeline Transportation of Natural Gas'
  },
  '4869': {
    id: 'naics-4869',
    defaultMessage: 'Other Pipeline Transportation'
  },
  '4871': {
    id: 'naics-4871',
    defaultMessage: 'Scenic and Sightseeing Transportation, Land'
  },
  '4872': {
    id: 'naics-4872',
    defaultMessage: 'Scenic and Sightseeing Transportation, Water'
  },
  '4879': {
    id: 'naics-4879',
    defaultMessage: 'Scenic and Sightseeing Transportation, Other'
  },
  '4881': {
    id: 'naics-4881',
    defaultMessage: 'Support Activities for Air Transportation'
  },
  '4882': {
    id: 'naics-4882',
    defaultMessage: 'Support Activities for Rail Transportation'
  },
  '4883': {
    id: 'naics-4883',
    defaultMessage: 'Support Activities for Water Transportation'
  },
  '4884': {
    id: 'naics-4884',
    defaultMessage: 'Support Activities for Road Transportation'
  },
  '4885': {
    id: 'naics-4885',
    defaultMessage: 'Freight Transportation Arrangement'
  },
  '4889': {
    id: 'naics-4889',
    defaultMessage: 'Other Support Activities for Transportation'
  },
  '4911': {
    id: 'naics-4911',
    defaultMessage: 'Postal Service'
  },
  '4921': {
    id: 'naics-4921',
    defaultMessage: 'Couriers and Express Delivery Services'
  },
  '4922': {
    id: 'naics-4922',
    defaultMessage: 'Local Messengers and Local Delivery'
  },
  '4931': {
    id: 'naics-4931',
    defaultMessage: 'Warehousing and Storage'
  },
  '5111': {
    id: 'naics-5111',
    defaultMessage: 'Newspaper, Periodical, Book, and Directory Publishers'
  },
  '5112': {
    id: 'naics-5112',
    defaultMessage: 'Software Publishers'
  },
  '5121': {
    id: 'naics-5121',
    defaultMessage: 'Motion Picture and Video Industries'
  },
  '5122': {
    id: 'naics-5122',
    defaultMessage: 'Sound Recording Industries'
  },
  '5151': {
    id: 'naics-5151',
    defaultMessage: 'Radio and Television Broadcasting'
  },
  '5152': {
    id: 'naics-5152',
    defaultMessage: 'Cable and Other Subscription Programming'
  },
  '5173': {
    id: 'naics-5173',
    defaultMessage: 'Wired and Wireless Telecommunications Carriers'
  },
  '5174': {
    id: 'naics-5174',
    defaultMessage: 'Satellite Telecommunications'
  },
  '5179': {
    id: 'naics-5179',
    defaultMessage: 'Other Telecommunications'
  },
  '5182': {
    id: 'naics-5182',
    defaultMessage: 'Data Processing, Hosting, and Related Services'
  },
  '5191': {
    id: 'naics-5191',
    defaultMessage: 'Other Information Services'
  },
  '5211': {
    id: 'naics-5211',
    defaultMessage: 'Monetary Authorities-Central Bank'
  },
  '5221': {
    id: 'naics-5221',
    defaultMessage: 'Depository Credit Intermediation'
  },
  '5222': {
    id: 'naics-5222',
    defaultMessage: 'Nondepository Credit Intermediation'
  },
  '5223': {
    id: 'naics-5223',
    defaultMessage: 'Activities Related to Credit Intermediation'
  },
  '5231': {
    id: 'naics-5231',
    defaultMessage:
      'Securities and Commodity Contracts Intermediation and Brokerage'
  },
  '5232': {
    id: 'naics-5232',
    defaultMessage: 'Securities and Commodity Exchanges'
  },
  '5239': {
    id: 'naics-5239',
    defaultMessage: 'Other Financial Investment Activities'
  },
  '5241': {
    id: 'naics-5241',
    defaultMessage: 'Insurance Carriers'
  },
  '5242': {
    id: 'naics-5242',
    defaultMessage:
      'Agencies, Brokerages, and Other Insurance Related Activities'
  },
  '5251': {
    id: 'naics-5251',
    defaultMessage: 'Insurance and Employee Benefit Funds'
  },
  '5259': {
    id: 'naics-5259',
    defaultMessage: 'Other Investment Pools and Funds'
  },
  '5311': {
    id: 'naics-5311',
    defaultMessage: 'Lessors of Real Estate'
  },
  '5312': {
    id: 'naics-5312',
    defaultMessage: 'Offices of Real Estate Agents and Brokers'
  },
  '5313': {
    id: 'naics-5313',
    defaultMessage: 'Activities Related to Real Estate'
  },
  '5321': {
    id: 'naics-5321',
    defaultMessage: 'Automotive Equipment Rental and Leasing'
  },
  '5322': {
    id: 'naics-5322',
    defaultMessage: 'Consumer Goods Rental'
  },
  '5323': {
    id: 'naics-5323',
    defaultMessage: 'General Rental Centers'
  },
  '5324': {
    id: 'naics-5324',
    defaultMessage:
      'Commercial and Industrial Machinery and Equipment Rental and Leasing'
  },
  '5331': {
    id: 'naics-5331',
    defaultMessage:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)'
  },
  '5411': {
    id: 'naics-5411',
    defaultMessage: 'Legal Services'
  },
  '5412': {
    id: 'naics-5412',
    defaultMessage:
      'Accounting, Tax Preparation, Bookkeeping, and Payroll Services'
  },
  '5413': {
    id: 'naics-5413',
    defaultMessage: 'Architectural, Engineering, and Related Services'
  },
  '5414': {
    id: 'naics-5414',
    defaultMessage: 'Specialized Design Services'
  },
  '5415': {
    id: 'naics-5415',
    defaultMessage: 'Computer Systems Design and Related Services'
  },
  '5416': {
    id: 'naics-5416',
    defaultMessage: 'Management, Scientific, and Technical Consulting Services'
  },
  '5417': {
    id: 'naics-5417',
    defaultMessage: 'Scientific Research and Development Services'
  },
  '5418': {
    id: 'naics-5418',
    defaultMessage: 'Advertising, Public Relations, and Related Services'
  },
  '5419': {
    id: 'naics-5419',
    defaultMessage: 'Other Professional, Scientific, and Technical Services'
  },
  '5511': {
    id: 'naics-5511',
    defaultMessage: 'Management of Companies and Enterprises'
  },
  '5611': {
    id: 'naics-5611',
    defaultMessage: 'Office Administrative Services'
  },
  '5612': {
    id: 'naics-5612',
    defaultMessage: 'Facilities Support Services'
  },
  '5613': {
    id: 'naics-5613',
    defaultMessage: 'Employment Services'
  },
  '5614': {
    id: 'naics-5614',
    defaultMessage: 'Business Support Services'
  },
  '5615': {
    id: 'naics-5615',
    defaultMessage: 'Travel Arrangement and Reservation Services'
  },
  '5616': {
    id: 'naics-5616',
    defaultMessage: 'Investigation and Security Services'
  },
  '5617': {
    id: 'naics-5617',
    defaultMessage: 'Services to Buildings and Dwellings'
  },
  '5619': {
    id: 'naics-5619',
    defaultMessage: 'Other Support Services'
  },
  '5621': {
    id: 'naics-5621',
    defaultMessage: 'Waste Collection'
  },
  '5622': {
    id: 'naics-5622',
    defaultMessage: 'Waste Treatment and Disposal'
  },
  '5629': {
    id: 'naics-5629',
    defaultMessage: 'Remediation and Other Waste Management Services'
  },
  '6111': {
    id: 'naics-6111',
    defaultMessage: 'Elementary and Secondary Schools'
  },
  '6112': {
    id: 'naics-6112',
    defaultMessage: 'Junior Colleges'
  },
  '6113': {
    id: 'naics-6113',
    defaultMessage: 'Colleges, Universities, and Professional Schools'
  },
  '6114': {
    id: 'naics-6114',
    defaultMessage: 'Business Schools and Computer and Management Training'
  },
  '6115': {
    id: 'naics-6115',
    defaultMessage: 'Technical and Trade Schools'
  },
  '6116': {
    id: 'naics-6116',
    defaultMessage: 'Other Schools and Instruction'
  },
  '6117': {
    id: 'naics-6117',
    defaultMessage: 'Educational Support Services'
  },
  '6211': {
    id: 'naics-6211',
    defaultMessage: 'Offices of Physicians'
  },
  '6212': {
    id: 'naics-6212',
    defaultMessage: 'Offices of Dentists'
  },
  '6213': {
    id: 'naics-6213',
    defaultMessage: 'Offices of Other Health Practitioners'
  },
  '6214': {
    id: 'naics-6214',
    defaultMessage: 'Outpatient Care Centers'
  },
  '6215': {
    id: 'naics-6215',
    defaultMessage: 'Medical and Diagnostic Laboratories'
  },
  '6216': {
    id: 'naics-6216',
    defaultMessage: 'Home Health Care Services'
  },
  '6219': {
    id: 'naics-6219',
    defaultMessage: 'Other Ambulatory Health Care Services'
  },
  '6221': {
    id: 'naics-6221',
    defaultMessage: 'General Medical and Surgical Hospitals'
  },
  '6222': {
    id: 'naics-6222',
    defaultMessage: 'Psychiatric and Substance Abuse Hospitals'
  },
  '6223': {
    id: 'naics-6223',
    defaultMessage:
      'Specialty (except Psychiatric and Substance Abuse) Hospitals'
  },
  '6231': {
    id: 'naics-6231',
    defaultMessage: 'Nursing Care Facilities (Skilled Nursing Facilities)'
  },
  '6232': {
    id: 'naics-6232',
    defaultMessage:
      'Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities'
  },
  '6233': {
    id: 'naics-6233',
    defaultMessage:
      'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly'
  },
  '6239': {
    id: 'naics-6239',
    defaultMessage: 'Other Residential Care Facilities'
  },
  '6241': {
    id: 'naics-6241',
    defaultMessage: 'Individual and Family Services'
  },
  '6242': {
    id: 'naics-6242',
    defaultMessage:
      'Community Food and Housing, and Emergency and Other Relief Services'
  },
  '6243': {
    id: 'naics-6243',
    defaultMessage: 'Vocational Rehabilitation Services'
  },
  '6244': {
    id: 'naics-6244',
    defaultMessage: 'Child Day Care Services'
  },
  '7111': {
    id: 'naics-7111',
    defaultMessage: 'Performing Arts Companies'
  },
  '7112': {
    id: 'naics-7112',
    defaultMessage: 'Spectator Sports'
  },
  '7113': {
    id: 'naics-7113',
    defaultMessage: 'Promoters of Performing Arts, Sports, and Similar Events'
  },
  '7114': {
    id: 'naics-7114',
    defaultMessage:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures'
  },
  '7115': {
    id: 'naics-7115',
    defaultMessage: 'Independent Artists, Writers, and Performers'
  },
  '7121': {
    id: 'naics-7121',
    defaultMessage: 'Museums, Historical Sites, and Similar Institutions'
  },
  '7131': {
    id: 'naics-7131',
    defaultMessage: 'Amusement Parks and Arcades'
  },
  '7132': {
    id: 'naics-7132',
    defaultMessage: 'Gambling Industries'
  },
  '7139': {
    id: 'naics-7139',
    defaultMessage: 'Other Amusement and Recreation Industries'
  },
  '7211': {
    id: 'naics-7211',
    defaultMessage: 'Traveler Accommodation'
  },
  '7212': {
    id: 'naics-7212',
    defaultMessage: 'RV (Recreational Vehicle) Parks and Recreational Camps'
  },
  '7213': {
    id: 'naics-7213',
    defaultMessage:
      "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
  },
  '7223': {
    id: 'naics-7223',
    defaultMessage: 'Special Food Services'
  },
  '7224': {
    id: 'naics-7224',
    defaultMessage: 'Drinking Places (Alcoholic Beverages)'
  },
  '7225': {
    id: 'naics-7225',
    defaultMessage: 'Restaurants and Other Eating Places'
  },
  '8111': {
    id: 'naics-8111',
    defaultMessage: 'Automotive Repair and Maintenance'
  },
  '8112': {
    id: 'naics-8112',
    defaultMessage: 'Electronic and Precision Equipment Repair and Maintenance'
  },
  '8113': {
    id: 'naics-8113',
    defaultMessage:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance'
  },
  '8114': {
    id: 'naics-8114',
    defaultMessage: 'Personal and Household Goods Repair and Maintenance'
  },
  '8121': {
    id: 'naics-8121',
    defaultMessage: 'Personal Care Services'
  },
  '8122': {
    id: 'naics-8122',
    defaultMessage: 'Death Care Services'
  },
  '8123': {
    id: 'naics-8123',
    defaultMessage: 'Drycleaning and Laundry Services'
  },
  '8129': {
    id: 'naics-8129',
    defaultMessage: 'Other Personal Services'
  },
  '8131': {
    id: 'naics-8131',
    defaultMessage: 'Religious Organizations'
  },
  '8132': {
    id: 'naics-8132',
    defaultMessage: 'Grantmaking and Giving Services'
  },
  '8133': {
    id: 'naics-8133',
    defaultMessage: 'Social Advocacy Organizations'
  },
  '8134': {
    id: 'naics-8134',
    defaultMessage: 'Civic and Social Organizations'
  },
  '8139': {
    id: 'naics-8139',
    defaultMessage:
      'Business, Professional, Labor, Political, and Similar Organizations'
  },
  '8141': {
    id: 'naics-8141',
    defaultMessage: 'Private Households'
  },
  '9211': {
    id: 'naics-9211',
    defaultMessage:
      'Executive, Legislative, and Other General Government Support'
  },
  '9221': {
    id: 'naics-9221',
    defaultMessage: 'Justice, Public Order, and Safety Activities'
  },
  '9231': {
    id: 'naics-9231',
    defaultMessage: 'Administration of Human Resource Programs'
  },
  '9241': {
    id: 'naics-9241',
    defaultMessage: 'Administration of Environmental Quality Programs'
  },
  '9251': {
    id: 'naics-9251',
    defaultMessage:
      'Administration of Housing Programs, Urban Planning, and Community Development'
  },
  '9261': {
    id: 'naics-9261',
    defaultMessage: 'Administration of Economic Programs'
  },
  '9271': {
    id: 'naics-9271',
    defaultMessage: 'Space Research and Technology'
  },
  '9281': {
    id: 'naics-9281',
    defaultMessage: 'National Security and International Affairs'
  },
  '48-49': {
    id: 'naics-48-49',
    defaultMessage: 'Transportation and Warehousing'
  },
  '31-33': {
    id: 'naics-31-33',
    defaultMessage: 'Manufacturing'
  },
  '44-45': {
    id: 'naics-44-45',
    defaultMessage: 'Retail Trade'
  }
})

export default naics
