import startCase from 'lodash.startcase'
import { IntlShape } from 'react-intl'
import CertificationCategories, {
  CategoryType
} from '../models/CertificationCategories'

type Props = {
  intl: IntlShape
  categoryType?: CategoryType
  subCategoryType?: string //SubCategoryType<CategoryType>,
  subType?: string
  useAcronym?: boolean
}

const translateCertificationType = ({
  intl,
  categoryType,
  subCategoryType,
  subType,
  useAcronym
}: Props): string => {
  const category = categoryType && CertificationCategories[categoryType]
  if (category && subCategoryType && subType) {
    return category.subTypes &&
      category.subTypes[subCategoryType] &&
      category.subTypes[subCategoryType][subType]
      ? intl.formatMessage(category.subTypes[subCategoryType][subType])
      : startCase(subType)
  } else if (category && subCategoryType) {
    const message = useAcronym
      ? category.subCategoriesAcronyms[subCategoryType]
      : category.subCategories[subCategoryType]
    return message ? intl.formatMessage(message) : startCase(subCategoryType)
  } else if (category) {
    return intl.formatMessage(category.title)
  }

  return startCase(categoryType)
}

export default translateCertificationType
