import React, { Component, ReactElement } from 'react'
import Paper from 'shared/components/Paper'
import { FormattedMessage } from 'react-intl'

type Props = {
  metricLabel: string | ReactElement<FormattedMessage>
  metricValue: number
}

class CompanyStat extends Component<Props> {
  render() {
    const { metricLabel, metricValue } = this.props

    const value =
      typeof metricValue === 'number'
        ? metricValue.toLocaleString()
        : metricValue

    return (
      <Paper>
        <div className='pt3 tc'>
          <div className='f2 teal'>{value}</div>
          <div className='gray f7'>{metricLabel}</div>
        </div>
      </Paper>
    )
  }
}

export default CompanyStat
