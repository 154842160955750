type BaseAction<ActionType extends string> = {
  type: ActionType
  error?: Error
}

type ActionWithPayload<ActionType extends string, Payload> = BaseAction<
  ActionType
> & {
  payload: Payload
}

type ActionWithPayloadAndMeta<
  ActionType extends string,
  Payload,
  Meta
> = ActionWithPayload<ActionType, Payload> & {
  meta: Meta
}

type ReduxAction<ActionType extends string, Payload, Meta> = {
  type: ActionType
  payload?: Payload
  meta?: Meta
  error?: Error
}

function createReduxAction<ActionType extends string>(
  actionType: ActionType
): () => BaseAction<ActionType>
function createReduxAction<ActionType extends string, Payload>(
  actionType: string
): (payload: Payload) => ActionWithPayload<ActionType, Payload>
function createReduxAction<ActionType extends string, Payload, Meta>(
  actionType: ActionType
): (
  payload: Payload,
  meta: Meta
) => ActionWithPayloadAndMeta<ActionType, Payload, Meta>
function createReduxAction<
  ActionType extends string,
  Payload = void,
  Meta = void
>(actionType: ActionType) {
  return (
    payload: Payload,
    meta: Meta
  ): ReduxAction<ActionType, Payload, Meta> => ({
    type: actionType,
    payload,
    meta
  })
}

export default createReduxAction
