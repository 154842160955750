import React, { Component, Fragment } from 'react'
import { Field } from 'redux-form/immutable'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Warning from 'shared/components/Warning'
import RadioButton from 'shared/components/RadioButton'
import Text from 'shared/components/Text'
import ListItem from 'shared/components/List/ListItem'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import OrgLogo from 'shared/components/OrgLogo'
import Scrollable from 'shared/components/Scrollable'
import { FormattedMessage } from 'react-intl'
import OrgUnit from 'shared/models/OrgUnit'
import { List, RecordOf } from 'immutable'
import Loading from 'shared/components/Loading'

type Props = {
  errorMessage: string
  matchingOrgs: List<RecordOf<OrgUnit>>
  email: string
  onCancel: () => void
  onSubmit: () => void
  isSearching: boolean
}

class CreateOrganizationForm extends Component<Props> {
  render() {
    const {
      errorMessage,
      matchingOrgs,
      onSubmit,
      email,
      onCancel,
      isSearching
    } = this.props

    return (
      <>
        <form onSubmit={onSubmit}>
          <Label htmlFor='name'>
            <FormattedMessage
              id='CreateOrganizationForm.CompanysName'
              defaultMessage="Company's Name"
            />
          </Label>
          <Field
            component={Input}
            id='name'
            name='name'
            maxLength={150}
            required
          />
          <Label htmlFor='websiteUrl'>
            <FormattedMessage
              id='CreateOrganizationForm.CompanyDomain'
              defaultMessage='Company Domain (Website URL or Email Address)'
            />
          </Label>
          <Field
            component={Input}
            id='websiteUrl'
            name='websiteUrl'
            readOnly={matchingOrgs.size > 0}
            required
          />

          {matchingOrgs && matchingOrgs.size > 0 && (
            <Fragment>
              {email ? (
                <Fragment>
                  <Label>
                    <FormattedMessage
                      id='CreateOrganizationForm.MultipleWebsites'
                      defaultMessage='Multiple organizations with the specified website were found.
                      Please select one and submit again'
                    />
                  </Label>
                  <Scrollable maxHeight={200}>
                    {matchingOrgs.map(orgUnit => (
                      <div className='db ph2 pv3' key={orgUnit.get('id')}>
                        <Field
                          component={RadioButton}
                          type='radio'
                          name='orgUnitId'
                          value={orgUnit.get('id')}
                          label={orgUnit.get('name')}
                          key={orgUnit.get('id')}
                          required
                        />
                      </div>
                    ))}
                  </Scrollable>
                  <div className='db ph2 pv2'>
                    <Field
                      component={RadioButton}
                      type='radio'
                      name='orgUnitId'
                      value=''
                      label='None of the above'
                      required
                    />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <Text className='mt3'>
                    <FormattedMessage
                      id='CreateOrganizationForm.CreateANewOrganization'
                      defaultMessage='Multiple organizations with the specified website were found.
                      Please submit again if you would still like to create a new Organization'
                    />
                  </Text>
                  <Scrollable maxHeight={200}>
                    {matchingOrgs.map(orgUnit => (
                      <ListItem
                        key={orgUnit.get('id')}
                        leftAvatar={
                          <OrgLogo
                            url={fileToUrl(orgUnit.getIn(['supplier', 'logo']))}
                            name={orgUnit.get('name')}
                          />
                        }
                        primaryText={orgUnit.get('name')}
                      />
                    ))}
                  </Scrollable>
                </Fragment>
              )}
            </Fragment>
          )}

          {errorMessage && <Warning message={errorMessage} className='mt3' />}

          <Button
            type='submit'
            label={
              <FormattedMessage
                id='CreateOrganizationForm.Submit'
                defaultMessage='Submit'
              />
            }
            autoSize
            className='mt3 mr3'
          />
          <Button
            label={<FormattedMessage id='CancelButton' />}
            autoSize
            secondary
            onClick={onCancel}
          />
        </form>
        {isSearching && <Loading />}
      </>
    )
  }
}

export default CreateOrganizationForm
