import React, { Component } from 'react'
import UserProfile from 'myaccount/components/UserProfile'
import { connect } from 'react-redux'
import {
  loadUser,
  selectUserToMessage,
  updateUser,
  updateUserRoles
} from 'shared/actions'
import usersSelectors from 'shared/selectors/usersSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import cardsSelectors from 'buyer/shared/selectors/cardsSelectors'
import { Map, List, RecordOf } from 'immutable'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import messagesSelectors from 'shared/selectors/messagesSelectors'
import sharedPaths from '../../routes/paths'
import parsePath from '../../utils/parsePath'
import { supportedLanguages } from '../../../i18n'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import { getCardsBySupplierId } from 'buyer/shared/actions'
import Loading from 'shared/components/Loading'
import { updateContact } from 'buyer/SupplierProfile/actions'
import moment from 'moment'
import RootState from 'shared/models/RootState'
import Location from 'shared/models/Location'
import { RouteComponentProps } from 'react-router-dom'
import { loadPosts, markPostRead } from 'posting/actions'
import postsSelectors from 'posting/selectors/postsSelectors'
import PageNotFound from 'shared/components/PageNotFound'
import { LicenseType } from 'shared/models/User'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'

type Props = {
  firstName: string
  lastName: string
  email: string
  roles: List<string>
  isPublic: boolean
  status?: string
  location?: RecordOf<Location> | string
  officePhone: string
  mobilePhone: string
  title: string
  department: string
  linkedInUrl: string
  profilePictureUrl: string
  disableAllEmails: boolean
  options: RecordOf<{
    message: {
      frequency: string
    }
  }>
  blocked: boolean
  showComments: boolean
  showMetrics: boolean
  myContact: Map<any, any>
  contactFor: string
  canEditProfile: boolean
  canChangePassword: boolean
  canMessage: boolean
  topicId: string
  isCurrentUser: boolean
  isCurrentUserBuyer: boolean
  isTealbot: boolean
  isBuyerAdmin: boolean
  isSupplierAdmin: boolean
  isOrgAdmin: boolean
  isPrivate: boolean
  isConnected: boolean
  isBuyer: boolean
  isSupplier: boolean
  colleagueIsConnected: boolean
  language: string
  isClientApp: boolean
  orgUnit: Map<any, any>
  connectedCards?: List<Map<string, any>>
  lastLogin: Date
  firstLogin: string
  feed: List<any>
  isFetching: boolean
  created: Date
  licenseType?: LicenseType

  loadUser: typeof loadUser
  loadPosts: (params: { userId?: string; orgUnitId?: string }) => void
  markPostRead: (postId: string) => void
  getCardsBySupplierId: (params: { supplierId: string }) => void
  selectUserToMessage: (params: { userToMessage: string }) => void
  updateContact: (params: any) => void
  updateUserStatus: (params: { id: string; status: string }) => void
  updateUserRoles: (params: {
    userId: string
    roles: string | Array<string>
  }) => void
} & ConnectProps

export class UserProfileContainer extends Component<Props> {
  componentDidMount() {
    const { userId, loadUser, loadPosts } = this.props
    loadUser(userId)
    loadPosts({ userId })
  }

  handleEdit = () => {
    const { history, basePath, userId } = this.props
    history.push(
      parsePath(`${basePath}${sharedPaths.editUserProfile}`, {
        userId
      })
    )
  }

  handleUpdateStatus = status => {
    const { updateUserStatus, userId } = this.props
    updateUserStatus({ id: userId, status })
  }

  handleUpdateRoles = roles => {
    const { updateUserRoles, userId } = this.props
    updateUserRoles({ userId, roles })
  }

  handleClickMessage = () => {
    const {
      userId,
      topicId,
      selectUserToMessage,
      basePath,
      history
    } = this.props

    if (topicId) {
      history.push(`${basePath}/messages/${topicId}`)
    } else {
      selectUserToMessage({
        userToMessage: userId
      })
      history.push(`${basePath}/messages`)
    }
  }

  render() {
    return this.props.isFetching && !this.props.email ? (
      <Loading />
    ) : !(this.props.email || this.props.firstName || this.props.lastName) ? (
      <PageNotFound />
    ) : (
      <UserProfile
        onEdit={this.handleEdit}
        onClickMessage={this.handleClickMessage}
        onUpdateStatus={this.handleUpdateStatus}
        onUpdateRoles={this.handleUpdateRoles}
        {...this.props}
      />
    )
  }
}

type ConnectProps = {
  userId: string
  basePath: string
} & Pick<RouteComponentProps, 'history'>

export default connect(
  (state: RootState, props: ConnectProps) => {
    const userId = props.userId
    const userInfo = usersSelectors.getById(state, userId) || Map({})
    const colleaguesIds = usersSelectors.getColleagueIds(state)
    const myCard = cardsSelectors.getBySupplier(
      state,
      userInfo.get('orgUnitId')
    )
    const myContact = myCard && myCard.getIn(['contacts', userId])
    const connectedCards =
      userInfo.get('orgUnitId') &&
      cardsSelectors.getCardsByContactConnection(
        state,
        userInfo.get('orgUnitId'),
        userId
      )
    const myUserId = sessionSelectors.getUserId(state)
    const isCurrentUserBuyer = sessionSelectors.userHasRole(state, 'buyer')
    const isCurrentUser = myUserId === userId
    const isConnected = myContact && myContact.get('connected')
    const isPrivate = myContact && myContact.get('isPrivate') !== false
    const colleagueIsConnected =
      connectedCards &&
      connectedCards.toList().filter(card => card.get('owner') !== myUserId)
        .size > 0
    const isTealbot = sessionSelectors.userHasRole(state, 'tealbot')
    const isAColleague = colleaguesIds.includes(userId) && !isCurrentUser
    const isClientApp = routingSelectors.getPathname(state).includes('client')
    const isBuyerAdmin = sessionSelectors.userHasRole(state, 'buyerAdmin')
    const isSupplierAdmin = sessionSelectors.userHasRole(state, 'supplierAdmin')
    const isOrgAdmin = sessionSelectors.userHasRole(state, 'orgAdmin')

    // people can change their own passwords
    // and tealbot can change other people's password
    const canModifyPassword =
      (isCurrentUser && !isTealbot) || (isTealbot && isAColleague)

    // same rules as modifying password, but also buyerAdmin users
    const canModifyUser =
      canModifyPassword ||
      (isAColleague &&
        sessionSelectors.userHasRole(state, ['buyerAdmin', 'supplierAdmin']))
    const lang = supportedLanguages.find(
      lang => lang.key === userInfo.get('language')
    )
    const orgUnit = orgsSelectors.getById(state, userInfo.get('orgUnitId'))
    const feed = postsSelectors.getPostsByUserId(state, userId)
    const isFetching = state.getIn(['users', 'isFetching'])
    const defaultLicenseType = settingsSelectors.getDefaultLicenseType(state)

    return {
      userId,
      firstName: userInfo.get('firstName'),
      lastName: userInfo.get('lastName'),
      email: userInfo.get('email'),
      roles: userInfo.get('roles'),
      isPublic: userInfo.get('isPublic'),
      status: userInfo.get('status'),
      location: userInfo.get('location'),
      officePhone: userInfo.get('officePhone'),
      mobilePhone: userInfo.get('mobilePhone'),
      title: userInfo.get('title'),
      department: userInfo.get('department'),
      linkedInUrl: userInfo.get('linkedInUrl'),
      profilePictureUrl: userInfo.get('profilePictureUrl'),
      disableAllEmails: userInfo.get('disableAllEmails'),
      options: userInfo.get('options'),
      blocked: userInfo.get('blocked') || userInfo.get('status') === 'Blocked',
      showComments:
        (isClientApp || isCurrentUserBuyer) && !isCurrentUser && !isAColleague,
      showMetrics:
        (isClientApp || isCurrentUserBuyer) && (isAColleague || isCurrentUser),
      myContact,
      contactFor: myContact && myContact.get('contactFor'),
      canEditProfile: canModifyUser,
      canChangePassword: canModifyPassword,
      canMessage: !isCurrentUser,
      topicId: messagesSelectors.getTopicIdByUserId(state, userId),
      isCurrentUser,
      isCurrentUserBuyer,
      isTealbot,
      isBuyerAdmin,
      isSupplierAdmin,
      isOrgAdmin,
      isPrivate,
      isConnected,
      isBuyer: userInfo.get('isBuyer'),
      isSupplier: userInfo.get('isSupplier'),
      colleagueIsConnected: !!colleagueIsConnected,
      language: lang ? lang.title : 'English',
      licenseType: userInfo.get('isBuyer')
        ? userInfo.get('licenseType') || defaultLicenseType
        : undefined,
      isClientApp,
      orgUnit,
      connectedCards,
      lastLogin: userInfo && userInfo.get('lastLogin'),
      firstLogin:
        userInfo.get('firstLogin') &&
        moment(userInfo.get('firstLogin')).format('MM/YYYY'),
      feed,
      isFetching,
      created: userInfo.get('createdDate')
    }
  },
  {
    loadUser,
    loadPosts,
    markPostRead,
    getCardsBySupplierId,
    selectUserToMessage,
    updateContact,
    updateUserStatus: updateUser,
    updateUserRoles
  }
)(UserProfileContainer)
