import React, { ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RootState from 'shared/models/RootState'
import { List, RecordOf } from 'immutable'
import useThemeColors from 'shared/utils/useThemeColors'
import { setTabFilter } from '../../actions'
import { naicsShortTitle } from 'shared/models/Naics'
import { useIntl, FormattedMessage } from 'react-intl'

type TabProps = {
  label: string | ReactElement<FormattedMessage>
  value: string
  isActive: boolean
}

const Tab = (props: TabProps) => {
  const colors = useThemeColors()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { label, value, isActive } = props

  return (
    <div className={`dib ${!isActive ? 'dim' : ''}`}>
      <div
        className={`dib v-mid ph1 bt bl br mid-gray f7 f6-ns tc pt1 b--black-10 br2 br--top ${
          !isActive ? 'bg-near-white bb pointer' : 'fw6'
        }`}
        style={
          isActive
            ? {
                borderBottom: '1px solid #f8f8fa'
              }
            : undefined
        }
        onClick={
          !isActive
            ? () => {
                dispatch(setTabFilter(value))
              }
            : undefined
        }
      >
        <div
          className={`${isActive ? colors.primaryContained : ''} br1 mb1`}
          style={{ height: 4 }}
        ></div>
        <div className='ph2 pb2'>
          {naicsShortTitle[value]
            ? intl.formatMessage(naicsShortTitle[value])
            : label}
        </div>
      </div>
    </div>
  )
}

export const getAvailableTabs = (state: RootState) =>
  state.getIn(['buyer', 'tabFilter', 'availableTabs'])
export const getCurrentTab = (state: RootState) =>
  state.getIn(['buyer', 'tabFilter', 'currentTab'])

const TabFilter = () => {
  const availableTabs: List<RecordOf<{
    value: string
    label: string
  }>> = useSelector(getAvailableTabs)
  const currentTab: string = useSelector(getCurrentTab)

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <Tab
        label={<FormattedMessage id='TabFilter.All' defaultMessage='All' />}
        value=''
        isActive={currentTab === ''}
      />
      {availableTabs.take(3).map(tab => (
        <Tab
          key={tab.value}
          label={tab.label}
          value={tab.value}
          isActive={currentTab === tab.value}
        />
      ))}
    </div>
  )
}

export default TabFilter
