import React, { Component } from 'react'
import classNames from 'classnames'

const styles = {
  main: 'w-100 w-70-l fl'
}

type Props = {
  className?: string
}
class Main extends Component<Props> {
  render() {
    return (
      <main
        {...this.props}
        className={classNames(styles.main, this.props.className)}
      />
    )
  }
}

export default Main
