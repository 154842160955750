import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import dateFormat from 'shared/utils/dateFormat'
import fileToUrl from 'shared/utils/data/fileToUrl'
import moment from 'moment'
import truncate from 'lodash.truncate'
import removeMarkdown from 'remove-markdown'

const apiRoute = `${apiRoutes.authService}/suppliers`

export const formatTwitter = id => {
  if (!id) return undefined

  if (id.includes('@')) {
    return id.slice(id.lastIndexOf('@') + 1, id.length)
  } else if (id.includes('twitter.com')) {
    return id.slice(id.lastIndexOf('twitter.com/') + 12, id.length)
  }
  return id
}

export const formatSocialMediaRequests = results => {
  let socialMediaRequests = []
  for (let i = 0; i < results.length; i++) {
    let links = results[i].supplier.supplier.links
    if (links.twitter) {
      let twitter = formatTwitter(links.twitter)
      if (twitter.replace(/_/g, 'g').match(/^[a-zA-Z]+$/)) {
        socialMediaRequests.push({
          id: twitter,
          type: 'twitter',
          supplierId: results[i].supplier.id,
          name: results[i].supplier.name
        })
      }
    }
  }
  return socialMediaRequests
}

export const getPosts = (scope, ids = [], posts = []) => {
  const searchParams = {
    q: '',
    agg: false,
    social: true,
    scope: scope,
    sort: 'Connection'
  }
  const requestLimit = 10000
  return new Promise((resolve, reject) => {
    api.get(`${apiRoute}/search`, searchParams).then(results => {
      let socialIds = formatSocialMediaRequests(results.hits).slice(
        0,
        requestLimit
      )
      var promises = []
      let response = { ids: [] }
      for (var k = 0; k < socialIds.length; k++) {
        response.ids[k] = socialIds[k].supplierId
      }
      if (
        JSON.stringify(
          ids.sort((a, b) => {
            return a > b ? -1 : 1
          })
        ) ===
        JSON.stringify(
          response.ids.sort((a, b) => {
            return a > b ? -1 : 1
          })
        )
      ) {
        resolve(null)
      } else {
        for (var j = 0; j < socialIds.length; j++) {
          var promise = api.get(
            `${apiRoutes.authService}/` +
              socialIds[j].type +
              `/` +
              socialIds[j].id
          ) // localhost placeholder
          promises.push(promise)
        }
        Promise.all(promises.map(p => p.catch(() => undefined)))
          .then(values => {
            if (values) {
              for (var i = 0; i < values.length; i++) {
                if (values[i]) {
                  for (var j = 0; j < values[i].length; j++) {
                    values[i][j].supplierId = socialIds[i].supplierId
                    values[i][j].name = socialIds[i].name
                  }
                }
              }
            }
            var posts = [].concat.apply([], values)
            posts = posts.filter(result => result && result.text)
            response.posts = posts
            resolve(response)
          })
          .catch(err => console.log(err))
      }
    })
  })
}

export const getTwitterFeed = (twitterId, orgUnitId) => {
  return api
    .get(`${apiRoutes.authService}/twitter/${twitterId}`, { orgUnitId })
    .then(results => {
      return results.map(post => {
        const { iso, date, ...rest } = post
        return {
          date: `${dateFormat(moment(iso))} at ${moment(iso).format(
            'hh:mm a'
          )}`,
          iso,
          ...rest
        }
      })
    })
    .catch(e => {
      return { error: e }
    })
}

export const getTealboardFeed = supplierId => {
  return api
    .get(`${apiRoutes.authService}/posts?orgUnitId=${supplierId}`)
    .then(results => {
      return results.map(post => {
        const { image, ...rest } = post

        return {
          supplierId,
          date: `${dateFormat(post.created.date)} at ${moment(
            post.created.date
          ).format('hh:mm a')}`,
          iso: post.created.date,
          logo: null,
          pageName: null,
          postId: post.id,
          text:
            post.title ||
            truncate(removeMarkdown(post.body), {
              length: 50,
              separator: /,? +/
            }),
          type: 'tealboard',
          imageUrl: image && fileToUrl(image),
          ...rest
        }
      })
    })
    .catch(e => {
      return { error: e }
    })
}

export const getSocialFeed = (social, supplierId) => {
  const promises = []
  promises.push(getTealboardFeed(supplierId))
  if (social.twitter) {
    promises.push(getTwitterFeed(social.twitter, supplierId))
  }
  return Promise.all(promises).then(results => {
    const successResults = results.filter(result => {
      return Array.isArray(result) && result.length > 0
    })

    return successResults
      .reduce((output, result) => {
        return output.concat(result)
      }, [])
      .sort((postA, postB) =>
        moment(postB.iso).isBefore(moment(postA.iso)) ? 1 : -1
      )
  })
}
