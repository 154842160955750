import vets from './vetsReducer'
import currentVet from './currentVetReducer'
import currentVetSuppliers from './currentVetSuppliersReducer'
import createInviteUserReducer from 'shared/utils/inviteUser/createReducer'
import { ADD_VET_TEAM_MEMBER, ADD_CONTACT_SEND_TO } from '../actionTypes'

const rootReducer = {
  vets,
  currentVet,
  currentVetSuppliers,
  inviteVetTeamMember: createInviteUserReducer(
    'inviteVetTeamMember',
    ADD_VET_TEAM_MEMBER
  ),
  inviteVetSupplierContact: createInviteUserReducer(
    'inviteVetSupplierContact',
    ADD_CONTACT_SEND_TO
  )
}

export default rootReducer
