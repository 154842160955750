import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import LoaderPage from '../../components/LoaderPage'
import { UPLOAD_FILE_TO_DATA_LOADER } from '../../store/actionTypes'
import { getFormSubmitErrors } from 'shared/reducers'

const formName = 'dataPortal/loader/uploadFile'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)

export default connectReduxForm(
  state => ({
    errorMessage: formSubmitErrorsSelector(state)
  }),
  {
    onSubmit: createSubmitFormAction(formName, UPLOAD_FILE_TO_DATA_LOADER)
  },
  {
    form: formName
  },
  LoaderPage
)
