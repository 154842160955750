import React, { Component } from 'react'
import { connect } from 'react-redux'
import FinishCommunityFormPage from '../../components/FinishCommunityFormPage'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import communitiesPaths from '../../routes/paths'
import RootState from 'shared/models/RootState'
import { RouteComponentProps } from 'react-router'

type Props = {
  name: string
} & RouteComponentProps

export class FinishCommunityFormPageContainer extends Component<Props> {
  handleContinue = () => {
    const { history } = this.props
    history.push(communitiesPaths.basePath)
  }

  render() {
    return (
      <FinishCommunityFormPage
        {...this.props}
        onContinue={this.handleContinue}
      />
    )
  }
}

type ContainerProps = RouteComponentProps<{ communityId: string }>

export default connect((state: RootState, props: ContainerProps) => {
  const communityId = props.match.params.communityId
  const community = communitiesSelectors.getById(state, communityId)

  return {
    name: community && community.get('name')
  }
})(FinishCommunityFormPageContainer)
