import React, { ChangeEvent } from 'react'
import Text from 'shared/components/Text'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import { useSelector } from 'react-redux'
import jobsSelectors from '../../selectors/jobsSelectors'
import RootState from 'shared/models/RootState'

const StyleRadio = withStyles({
  root: {
    padding: 0
  }
})(Radio)

type Props = {
  questionId: string
  rowNum: number
  value?: any
  onChange: (questionId, value) => void
}

const NaicsQuestion = (props: Props) => {
  const { questionId = 'naics', rowNum, value = {}, onChange } = props
  const naicsCodes = useSelector((state: RootState) =>
    jobsSelectors.getOrgUnitClassificationCodes(state, rowNum)
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const code = e.currentTarget.name.split('.')[1]
    const newValue = Object.assign({}, value, { [code]: e.currentTarget.value })
    onChange(questionId, newValue)
  }

  return (
    <div className='bg-white ba br b--black-10 mt3'>
      <Table size='small' aria-label='Their Industries / Categories'>
        <TableHead>
          <TableRow>
            <TableCell>Their Industries / Categories</TableCell>
            <TableCell style={{ width: 250 }}>
              <div className='db'>
                <div className='dib w-third'>Correct</div>
                <div className='dib w-third'>Unsure</div>
                <div className='dib w-third'>Incorrect</div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {naicsCodes?.map(code => (
            <TableRow key={code.get('code')}>
              <TableCell>
                <Text>{code.get('name')}</Text>
              </TableCell>
              <TableCell style={{ width: 250 }}>
                <div className='db'>
                  <div className='dib w-third'>
                    <StyleRadio
                      name={`${questionId}.${code.get('code')}`}
                      color='primary'
                      size='small'
                      value='keep'
                      onChange={handleChange}
                      checked={value && value[code.get('code')] === 'keep'}
                    />
                  </div>
                  <div className='dib w-third'>
                    <StyleRadio
                      name={`${questionId}.${code.get('code')}`}
                      color='primary'
                      size='small'
                      value='unsure'
                      onChange={handleChange}
                      checked={value && value[code.get('code')] === 'unsure'}
                    />
                  </div>
                  <div className='dib w-third'>
                    <StyleRadio
                      name={`${questionId}.${code.get('code')}`}
                      color='primary'
                      size='small'
                      value='remove'
                      onChange={handleChange}
                      checked={value && value[code.get('code')] === 'remove'}
                    />
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default NaicsQuestion
