import { select, call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import profileSelectors from '../../selectors/profileSelectors'
import relationships from 'shared/utils/api/relationships'

export function* removeAttachmentSaga(action) {
  const attachment = action.payload.toJS()
  const relationship = yield select(profileSelectors.getRelationship)
  const relationshipId = relationship.get('id')

  // add or edit the attachemnt
  yield call(relationships.deleteAttachment, relationshipId, attachment)

  return yield {
    relationshipId,
    attachment
  }
}

export default createApiRequestSaga(removeAttachmentSaga)
