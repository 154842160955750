import React, { FunctionComponent, ReactElement } from 'react'
import Tealbot from 'shared/assets/icons/tealbot-circle.png'
import TealbookLogoWrapper from 'accounts/components/TealbookLogoWrapper'
import { FormattedMessage } from 'react-intl'

type Props = {
  title: string | ReactElement<FormattedMessage>
  subtitle: string | ReactElement<FormattedMessage>
  hideLogo?: boolean
}

const OnboardingHeader: FunctionComponent<Props> = props => {
  return (
    <>
      {!props.hideLogo && <TealbookLogoWrapper />}
      <div className='ph5-ns'>
        <h2 className='f4 f4-m f3-l fw2 mt2 black-70 mb0 tc'>{props.title}</h2>
        <div className='flex items-center mv3'>
          <img src={Tealbot} alt='Tealbot' className='dib w3 h3 mr3' />
          <span className='lh-copy f5 fw4 mid-gray ma0 f5'>
            {props.subtitle}
          </span>
        </div>
      </div>
    </>
  )
}

export default OnboardingHeader
