import React, { useMemo } from 'react'
import ParentQuestion from '../ParentQuestion'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import companySelectors from '../../../shared/selectors/companySelectors'
import { useSelector } from 'react-redux'
import AttachmentQuestion from '../AttachmentQuestion'
import TextQuestion from '../TextQuestion'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import Text from 'shared/components/Text'

const messages = defineMessages({
  hazardIdentification: {
    id: 'HealthSafety.docTypeHazardIdentification',
    defaultMessage: 'Hazard identification policy/process/procedure'
  },
  riskAssessment: {
    id: 'HealthSafety.docTypeRiskAssessment',
    defaultMessage: 'Risk assessment policy/process/procedure'
  },
  incidentInvestigation: {
    id: 'HealthSafety.docTypeIncidentInvestigation',
    defaultMessage: 'Incident investigation policy/process/procedure'
  },
  emergencyResponse: {
    id: 'HealthSafety.docTypeEmergencyResponse',
    defaultMessage: 'Emergency response policy/process/procedure'
  },
  workerParticipation: {
    id: 'HealthSafety.docTypeWorkerParticipation',
    defaultMessage:
      'Worker participation on occupational health and safety  policy/process/procedure'
  },
  workerConsultation: {
    id: 'HealthSafety.docTypeWorkerConsultation',
    defaultMessage:
      'Worker consultation on occupational health and safety  policy/process/procedure'
  },
  workerCommunication: {
    id: 'HealthSafety.docTypeWorkerCommunication',
    defaultMessage:
      'Worker communication and training on occupational health and safety policy/process/procedure'
  },
  other: {
    id: 'other',
    defaultMessage: 'Other'
  },
  placeholderAdditionalInfo: {
    id: 'placeholderAdditionalInfo',
    defaultMessage: 'Please provide any additional details.'
  }
})

const pageId = 'healthSafety'

const HealthSafety = () => {
  const intl = useIntl()
  const certifications = useSelector(companySelectors.getCertifications)
  const iso45001 = useMemo(() => {
    const sustainability = certifications?.find(c => c[0] === 'sustainability')
    return sustainability?.[1]?.filter(c => c.get('subCategory') === 'iso45001')
  }, [certifications])
  const emergencyResponseDocTypes = [
    {
      value: 'hazardIdentification',
      label: intl.formatMessage(messages.hazardIdentification)
    },
    {
      value: 'riskAssessment',
      label: intl.formatMessage(messages.riskAssessment)
    },
    {
      value: 'incidentInvestigation',
      label: intl.formatMessage(messages.incidentInvestigation)
    },
    {
      value: 'emergencyResponse',
      label: intl.formatMessage(messages.emergencyResponse)
    },
    { value: 'other', label: intl.formatMessage(messages.other) }
  ]
  const healthSafetyDocTypes = [
    {
      value: 'workerParticipation',
      label: intl.formatMessage(messages.workerParticipation)
    },
    {
      value: 'workerConsultation',
      label: intl.formatMessage(messages.workerConsultation)
    },
    {
      value: 'workerCommunication',
      label: intl.formatMessage(messages.workerCommunication)
    },
    { value: 'other', label: intl.formatMessage(messages.other) }
  ]

  return (
    <div>
      <ScrollToPosition />
      <ParentQuestion
        pageId={pageId}
        questionId='iso45001'
        question={
          <FormattedMessage
            id='HealthSafety.iso45001'
            defaultMessage='Q1. Has an occupational health and safety management system been implemented? (e.g., ISO 45001)'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='iso45001'
          suggestion={iso45001}
          certCategory='sustainability'
          certSubCategory='iso45001'
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='emergencyResponsePolicies'
        question={
          <FormattedMessage
            id='HealthSafety.hazardIdentification'
            defaultMessage='Q2. Are policies, processes, and procedures in place for hazard identification, risk assessment, incident investigation, and emergency response?'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='emergencyResponsePolicies'
          type='doc'
          multiple
          uploadDocTypes={emergencyResponseDocTypes}
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='healthSafetyPolicies'
        question={
          <FormattedMessage
            id='HealthSafety.workerParticipation'
            defaultMessage='Q3. Are policies, processes, and procedures in place for worker participation, consultation, communication and training on occupational health and safety?'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='healthSafetyPolicies'
          type='doc'
          multiple
          uploadDocTypes={healthSafetyDocTypes}
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='laggingIndicators'
        question={
          <FormattedMessage
            id='HealthSafety.laggingIndicators'
            defaultMessage='Q4. Are lagging indicators for work-related injuries measured and tracked (e.g., total recordable injury frequency (TRIF) and lost time injury frequency (LTIF))? If so, please provide performance statistics for the past 3 years.'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='laggingIndicators'
          type='doc'
          multiple
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='leadingIndicators'
        question={
          <FormattedMessage
            id='HealthSafety.leadingIndicators'
            defaultMessage='Q5. Are leading indicators measured and tracked (e.g., near misses, hazard identifications, inspections)? If so, please provide performance statistics for the past 3 years.'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='leadingIndicators'
          type='doc'
          multiple
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='liabilityPrograms'
        question={
          <FormattedMessage
            id='HealthSafety.liabilityInsurance'
            defaultMessage='Q6. Are liability insurance and workers’ compensation programs (in accordance with local laws and regulations) in place and in good standing?'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='liabilityPrograms'
          type='doc'
          multiple
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='occupationalChargesViolations'
        disableNoSubQuestion
        question={
          <FormattedMessage
            id='HealthSafety.chargeOccupationalSafety'
            defaultMessage='Q7. Your organization <b>has not</b> had any charges, violations, or orders related to occupational health and safety.'
            values={{
              b: b => <span className='fw8 black'>{b}</span>
            }}
          />
        }
        additionalInfo={
          <div className='ml3 mt2'>
            <Text secondary>
              <FormattedMessage
                id='HealthSafety.chargeOccupationalSafetyInfo1'
                defaultMessage='True, my organization <b>has not</b> had any charges, violations, or orders related to occupational health and safety.'
                values={{
                  b: b => <span className='fw8 underline'>{b}</span>
                }}
              />
            </Text>
            <Text secondary>
              <FormattedMessage
                id='HealthSafety.chargeOccupationalSafetyInfo2'
                defaultMessage='False, my organization <b>has</b> had charges, violations, or orders related to occupational health and safety.'
                values={{
                  b: b => <span className='fw8 underline'>{b}</span>
                }}
              />
            </Text>
          </div>
        }
        useTrueFalse
        childrenOnFalse
      >
        <>
          <Text>
            <FormattedMessage
              id='HealthSafety.chargeOccupationalSafetyInfo2b'
              defaultMessage='Please specify for the past 3 years.'
            />
          </Text>
          <AttachmentQuestion
            pageId={pageId}
            questionId='occupationalChargesViolations'
            type='doc'
            multiple
          />
        </>
      </ParentQuestion>
      <TextQuestion
        pageId={pageId}
        questionId='note'
        fieldType='textarea'
        question={
          <FormattedMessage
            id='optionalAdditionalNote'
            defaultMessage='Additional information (optional)'
          />
        }
        placeholder={intl.formatMessage(messages.placeholderAdditionalInfo)}
      />
    </div>
  )
}

export default HealthSafety
