import PunchoutPayload from '../../models/PunchoutPayload'

type StorageValues = {
  accessToken: string
  refreshToken: string
  tealbotAccessToken: string
  tealbotRefreshToken: string
  preferredLanguage: string
  tealbotUrl: string
  alreadyOnboarded: boolean
  spotlightTag: string
  reasonKey: {
    selectedOption: string
    otherReasonText: string
  }

  // SpendOverviewTable Custom column options
  countrySpendCustomColumn: string
  categorySpendCustomColumn: string
  spendgroupSpendCustomColumn: string

  // last logged in user used for login
  lastLoggedInUser: any

  // Search shopping cart (list of suppliers)
  cart: Array<{
    id: string
  }>

  // Whether to show help sections across the app
  doNotShowTier2BuyerHelp: boolean
  doNotShowAddTagHint: boolean
  doNotShowBuyerCommunitiesInfo: boolean
  doNotShowSupplierCommunitiesInfo: boolean
  doNotShowSupplierVetDescription: boolean
  doNotShowTier2SupplierHelp: boolean
  dontShowVetDetailsInfoStorageKey: boolean
  doNotShowVetInfo: boolean
  doNotShowAddCriteriaHint: boolean

  // a Date of when the tags suggestions was last ignored
  ignoreTagsSuggestions: string

  // Diversity Report rules filter
  baseRules: any
  includeRules: any
  excludeRules: any

  // Punchout setup object
  punchout: PunchoutPayload

  // Export columns
  exportColumns: Array<string>

  snapshots: any[]
  // temporary storage
  survey: any
}

export const getItem = <K extends keyof StorageValues>(key: K) => {
  const value = localStorage.getItem(key)
  if (value) {
    try {
      return JSON.parse(value) as StorageValues[K]
    } catch (error) {
      // parsing strings fail until they get stringified
      return value as StorageValues[K]
    }
  }
}

export const setItem = <K extends keyof StorageValues>(
  key: K,
  value: StorageValues[K]
) => {
  try {
    const stringifiedValue = JSON.stringify(value)
    localStorage.setItem(key, stringifiedValue)
  } catch (error) {
    console.error(error)
  }
}

export const removeItem = (key: keyof StorageValues) =>
  localStorage.removeItem(key)
