import React, { FunctionComponent, useState } from 'react'
import { ReactComponent as CpiIcon } from 'shared/assets/icons/cpi.svg'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import CpiFAQ from 'shared/components/CpiFAQ'
import Popover from '@material-ui/core/Popover'

type Props = {
  countryCpi: number
  country: string
}

const getColor = (value: number): string => {
  //value from 0 to 100
  const hue = (value * 0.6).toString(10)
  return `hsl(${hue},100%,50%)`
}

const LocationIndex: FunctionComponent<Props> = props => {
  const [openFAQ, setOpenFAQ] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  return (
    <>
      <Tooltip
        title={
          <>
            <FormattedMessage
              id='LocationIndex.tooltip'
              defaultMessage='Corruption Perception Index for {country}: {countryCpi}'
              values={{ countryCpi: props.countryCpi, country: props.country }}
            />
            <br />
            <br />
            <FormattedMessage
              id='LocationIndex.tooltipDescription'
              defaultMessage='Highly Corrupt (0-25), Corrupt (26-45), Average (46-55), Clean (56-75), Very Clean (75+)'
            />
          </>
        }
      >
        <span
          className='pointer dim'
          ref={anchor => {
            setAnchorEl(anchor)
          }}
          onClick={() => setOpenFAQ(true)}
        >
          <CpiIcon
            color={getColor(props.countryCpi)}
            height='16px'
            width='16px'
          />
        </span>
      </Tooltip>
      <Popover
        open={openFAQ}
        onClose={() => setOpenFAQ(false)}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <div className='pa3' style={{ maxWidth: 420 }}>
          <CpiFAQ />
        </div>
      </Popover>
    </>
  )
}

export default LocationIndex
