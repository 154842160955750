import React, { FC, ReactElement } from 'react'
import InsightTitle from 'shared/components/InsightTitle'
import InsightPaper from 'shared/components/InsightPaper'
import { FormattedMessage } from 'react-intl'

type Props = {
  title: string | ReactElement<FormattedMessage>
}

const WidgetWrapper: FC<Props> = ({ title, children }) => {
  return (
    <>
      <InsightTitle>{title}</InsightTitle>
      <InsightPaper>{children}</InsightPaper>
    </>
  )
}

export default WidgetWrapper
