import React, { Component } from 'react'
import { connect } from 'react-redux'
import Pill from 'shared/components/Pill'
import Text from 'shared/components/Text'
import { removeTag, addTag, deletePrivateOfferings } from '../../actions'
import withUser from 'shared/utils/withUser'
import UserListItem from 'shared/components/UserListItem'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import RootState from 'shared/models/RootState'
import { RecordOf, List } from 'immutable'

const Colleague = withUser(UserListItem)

type Tag = RecordOf<{
  colleagueTagged: boolean
  colleagues: List<string>
  count: number
  name: string
  userTagged?: boolean
}>
type Props = {
  tagsFieldName: string
  isTealbot: boolean
  readOnly?: boolean
  tags: any //Map<string, Tag>
  removeTag: (arg: { tagsFieldName: string; tagKey: string }) => void
  deletePrivateOfferings: (arg: {
    tagsFieldName: string
    tagKey: string
    tagName: string
  }) => void
  addTag: (arg: {
    tagsFieldName: string
    newTag: string
    ignoreSplit: boolean
    isToggled: boolean
  }) => void
  isBuyerAdmin: boolean
}

export class SupplierTags extends Component<Props> {
  handleToggleTag = (tagKey, tag) => {
    const { tagsFieldName, removeTag, addTag } = this.props

    // remove if already tagged by user
    if (tag.get('userTagged')) {
      removeTag({
        tagsFieldName,
        tagKey
      })
    } else {
      addTag({
        tagsFieldName,
        newTag: tag.get('name'),
        ignoreSplit: true,
        isToggled: true
      })
    }
  }

  sortTags = (aTag, bTag) => {
    if (aTag.get('userTagged') && !bTag.get('userTagged')) {
      return -1
    } else if (!aTag.get('userTagged') && bTag.get('userTagged')) {
      return 1
    } else if (aTag.get('colleagueTagged') && !bTag.get('colleagueTagged')) {
      return -1
    } else if (!aTag.get('colleagueTagged') && bTag.get('colleagueTagged')) {
      return 1
    }
    {
      const countDiff = bTag.get('count') - aTag.get('count')
      const nameDiff =
        bTag.get('name').toUpperCase() > aTag.get('name').toUpperCase() ? -1 : 1
      return countDiff !== 0 ? countDiff : nameDiff
    }
  }
  renderTagDetail = tag => {
    const colleaguesCount = tag.get('colleagues').size
    const othersCount = tag.get('count') - colleaguesCount
    return (
      <div className='pa2'>
        <Text className='fw6'>{`${colleaguesCount} from my company${
          othersCount ? `, ${othersCount} from others` : ''
        }`}</Text>
        {tag.get('colleagues').map(user => {
          return <Colleague key={user} userId={user} mini className='mt2' />
        })}
      </div>
    )
  }

  render() {
    const {
      tags,
      isTealbot,
      readOnly,
      isBuyerAdmin,
      tagsFieldName,
      deletePrivateOfferings
    } = this.props
    //filters tags for undefined names -> tags made before new 'addTag' that corrects for this at time of input
    const filteredTags = tags && tags.filter((tag: Tag) => !!tag.get('name'))
    return filteredTags && filteredTags.size > 0 ? (
      <div className='mt3 ph3-5 pb3-5'>
        {filteredTags
          .sort(this.sortTags)
          .entrySeq()
          .map(([key, tag]) => (
            <Pill
              key={key}
              label={tag.get('name')}
              count={tag.get('count')}
              color={
                tag.get('userTagged')
                  ? 'primary'
                  : tag.get('colleagueTagged') && 'primaryLight'
              }
              onToggle={
                !readOnly && !(tag.get('userTagged') && isTealbot)
                  ? () => this.handleToggleTag(key, tag)
                  : undefined
              }
              title={
                tag.get('colleagues') && tag.get('colleagues').size > 0
                  ? this.renderTagDetail(tag)
                  : undefined
              }
              onRequestDelete={
                isBuyerAdmin && tagsFieldName === 'privateOfferings'
                  ? () =>
                      deletePrivateOfferings({
                        tagsFieldName,
                        tagKey: key,
                        tagName: tag.get('name')
                      })
                  : undefined
              }
            />
          ))}
      </div>
    ) : null
  }
}

type ContainerProps = {
  tagsFieldName: string
}
export default connect(
  (state: RootState, props: ContainerProps) => ({
    isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
    tags: state.getIn([
      'buyer',
      'supplierProfile',
      'combinedTags',
      props.tagsFieldName
    ]),
    isBuyerAdmin: sessionSelectors.userHasRole(state, 'buyerAdmin')
  }),
  {
    removeTag,
    deletePrivateOfferings,
    addTag
  }
)(SupplierTags)
