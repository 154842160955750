import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { formValueSelector } from 'redux-form/immutable'
import AttachmentForm from 'shared/components/AttachmentForm'
import { UPDATE_RELATIONSHIP_ATTACHMENT } from '../../actionTypes'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import { List, Map } from 'immutable'

const formName = 'supplierProfile/newAttachment'
const valueSelector = formValueSelector(formName)

type Props = {
  type: string
  attachmentTypes: List<Map<string, string>>
  reset: () => void
  handleSubmit: () => void
}

export class AddAttachmentForm extends Component<Props> {
  render() {
    const { reset, handleSubmit, ...rest } = this.props

    return (
      <div className='bg-near-white ph3-5 pb3-5'>
        <AttachmentForm {...rest} onSubmit={handleSubmit} onCancel={reset} />
      </div>
    )
  }
}

export default connectReduxForm(
  state => ({
    attachmentTypes: settingsSelectors.getAttachmentTypesList(state),
    type: valueSelector(state, 'type')
  }),
  {
    onSubmit: createSubmitFormAction(formName, UPDATE_RELATIONSHIP_ATTACHMENT)
  },
  {
    form: formName
  },
  AddAttachmentForm
)
