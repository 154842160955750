import { combineReducers, Reducer } from 'redux'
import * as actionTypes from './actionTypes'
import { requestSuccess } from 'shared/actionTypes'
import { fromJS } from 'immutable'

export type AllocatedSpendState = {
  isLoading: boolean
  data: Array<{
    buyerOrgUnitId: string
    buyerName: string
    communityPlanMembershipId: string
    allocatedSpend: {
      [year: number]: {
        [quarter: number]: {
          overallAllocation: number
          diverseSupplierAllocations: Array<{
            id: string
            allocation: number
            spend: number
            name: string
            logoUrl?: string
          }>
        }
      }
    }
  }>
}

const allocatedSpendDefaultState: AllocatedSpendState = {
  isLoading: false,
  data: []
}

const allocatedSpend: Reducer<AllocatedSpendState> = (
  state = allocatedSpendDefaultState,
  action
) => {
  switch (action.type) {
    case actionTypes.LOAD_TIER2_ALLOCATED_SPEND:
      return {
        ...state,
        isLoading: true
      }

    case requestSuccess(actionTypes.LOAD_TIER2_ALLOCATED_SPEND):
      return {
        ...state,
        isLoading: false,
        data: action.payload
      }

    case requestSuccess(actionTypes.UPDATE_TIER2_ALLOCATED_SPEND):
      return {
        ...state,
        data: state.data.map(buyer =>
          buyer.communityPlanMembershipId ===
          action.payload.communityPlanMembershipId
            ? {
                ...buyer,
                allocatedSpend: {
                  ...buyer.allocatedSpend,
                  [action.payload.year]: {
                    ...buyer.allocatedSpend[action.payload.year],
                    [action.payload.quarter]: action.payload
                  }
                }
              }
            : buyer
        )
      }

    default:
      return state
  }
}

export type Tier2CommunityState = {
  isLoadingCommunities: boolean
  tier2CommMemberships: any
}
const tier2CommunityDefaultState: Tier2CommunityState = {
  isLoadingCommunities: false,
  tier2CommMemberships: []
}

const tier2Communities = (
  state = fromJS(tier2CommunityDefaultState),
  action
) => {
  switch (action.type) {
    case actionTypes.GET_TIER2_COMMUNITIES:
      return state.set('isLoadingCommunities', true)

    case requestSuccess(actionTypes.GET_TIER2_COMMUNITIES):
      return state
        .set('isLoadingCommunities', false)
        .set('tier2CommMemberships', fromJS(action.payload))

    default:
      return state
  }
}

export type SummaryRowType = {
  loadId: string
  year?: number
  quarter?: number
  dates: string[] // ['2019-12-1', '2020-1-1', '2020-2-1']
  domain: string // 'missing.ink',
  category: string //'diversity',
  subCategories: Array<{
    subCategory: string
    subType?: string
    spend: number
  }>
  needDivide: boolean
  stagingId?: string
}

export type DiverseSummaryType = {
  isLoading: boolean
  data: Array<SummaryRowType>
}

const diverseSummaryDefaultState: DiverseSummaryType = {
  isLoading: false,
  data: []
}

const diverseSummary = (state = diverseSummaryDefaultState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_TIER2_DIVERSE_SUMMARY:
      return {
        ...state,
        isLoading: true
      }

    case requestSuccess(actionTypes.LOAD_TIER2_DIVERSE_SUMMARY):
      return {
        isLoading: false,
        data: action.payload
      }

    case requestSuccess(actionTypes.POST_TIER2_DIVERSE_SUMMARY):
      const updateData = [...state.data, action.payload]
      return {
        ...state,
        data: updateData
      }

    case requestSuccess(actionTypes.REMOVE_TIER2_DIVERSE_SUMMARY):
      const index = state.data.findIndex(d => d.loadId === action.payload)
      state.data.splice(index, 1)
      return {
        ...state,
        data: [...state.data]
      }
    default:
      return state
  }
}

export default combineReducers({
  allocatedSpend,
  tier2Communities,
  diverseSummary
})
