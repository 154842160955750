import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getTier2Communities } from '../../store/actions'
import { acceptCommunity, declineCommunity } from '../../../Communities/actions'
import Tier2Invitation from '../../components/Tier2Invitation'
import RootState from 'shared/models/RootState'
import { List, Map, RecordOf } from 'immutable'
import { createSelector } from 'reselect'

const getCommunitiesByStatus = createSelector(
  (state: RootState) =>
    state.getIn([
      'supplier',
      'tier2',
      'tier2Communities',
      'tier2CommMemberships'
    ]),
  communities =>
    communities
      .sort((communityA, communityB) =>
        communityA.get('buyerName').toLowerCase() >
        communityB.get('buyerName').toLowerCase()
          ? 1
          : -1
      )
      .reduce((result, community) => {
        return result.has(community.get('status'))
          ? result.updateIn([community.get('status')], status =>
              status.push(community)
            )
          : result.set(community.get('status'), List([community]))
      }, Map({}))
)

export type Tier2Community = {
  buyerId: string
  buyerName: string
  buyerLogo: string
  communityId: string
  status: string
}

export type Tier2Communities = List<RecordOf<Tier2Community>>

const Tier2InvitationContainer = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getTier2Communities())
  }, [dispatch])

  const communities = useSelector(getCommunitiesByStatus)

  const isLoading = useSelector((state: RootState) =>
    state.getIn([
      'supplier',
      'tier2',
      'tier2Communities',
      'isLoadingCommunities'
    ])
  )

  const handleAcceptInvitation = useCallback(
    (commId: string) => {
      dispatch(acceptCommunity({ commId, isTier2: true }))
    },
    [dispatch]
  )
  const handleDeclineInvitation = useCallback(
    (commId: string) => {
      dispatch(declineCommunity({ commId, isTier2: true }))
    },
    [dispatch]
  )

  return (
    <Tier2Invitation
      isLoading={isLoading}
      communities={communities}
      onHandleAcceptInvitation={handleAcceptInvitation}
      onHandleDeclineInvitation={handleDeclineInvitation}
    />
  )
}

export default Tier2InvitationContainer
