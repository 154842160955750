import React, { Component } from 'react'
import NavLink from '../NavBar/NavLink'
import MessagesIcon from 'shared/assets/icons/messages.svg'
import { injectIntl, defineMessages, IntlShape } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'

const messages = defineMessages({
  messages: {
    id: 'MessagesNavLink.messages',
    defaultMessage: 'messages'
  },
  messagesTip: {
    id: 'MessagesNavLink.messagesTip',
    defaultMessage: 'Chat with other users'
  }
})

type Props = {
  to: string
  numberOfUnreadMessages: number
  intl: IntlShape
}

export class MessagesNavLink extends Component<Props> {
  render() {
    const { numberOfUnreadMessages, to, intl } = this.props

    return (
      <Tooltip title={intl.formatMessage(messages.messagesTip)}>
        <NavLink
          label={intl.formatMessage(messages.messages)}
          to={to}
          iconSrc={MessagesIcon}
          numberOfNotifications={numberOfUnreadMessages}
        />
      </Tooltip>
    )
  }
}

export default injectIntl(MessagesNavLink)
