import React from 'react'
import { Field } from 'redux-form/immutable'
import { List, Map } from 'immutable'
import FileInput from 'shared/components/FileInput'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import DatePicker from 'shared/components/DatePicker'
import SelectFromList from 'shared/components/SelectFromList'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from 'shared/components/Button'
import { translatedFilterKeys } from 'shared/utils/data/defineMessages'
import camelCase from 'lodash.camelcase'

type Props = {
  attachmentTypes: List<Map<string, string>>
  type: string
  onSubmit: () => void
  onCancel: () => void
}

const AttachmentForm = (props: Props) => {
  const { attachmentTypes, type, onSubmit, onCancel } = props
  const intl = useIntl()
  const translatedMessages = translatedFilterKeys['attachments']
  const translatedAttachmentTypes = attachmentTypes.map(a => {
    const label = a.get('label')
    const formatMessageId = label
      ? translatedMessages[camelCase(label)]
      : undefined
    return a.update('label', l =>
      formatMessageId ? intl.formatMessage(formatMessageId) : l
    )
  })

  return (
    <form onSubmit={onSubmit}>
      <Label>
        <FormattedMessage
          id='AttachmentForm.AddAttachment'
          defaultMessage='Add an Attachment'
        />
      </Label>
      <FormattedMessage
        id='AttachmentForm.'
        defaultMessage='Select an attachment type'
      >
        {message => (
          <Field
            name='type'
            component={SelectFromList}
            placeholder={message as string}
            options={translatedAttachmentTypes}
            required
          />
        )}
      </FormattedMessage>
      {type && (
        <>
          <Label>
            <FormattedMessage
              id='AttachmentForm.ExpirationDate'
              defaultMessage='Expiration Date'
            />
          </Label>
          <Field
            name='expirationDate'
            component={DatePicker}
            normalize={value => {
              return value && value.toISOString()
            }}
          />

          <Label>
            <FormattedMessage
              id='AttachmentForm.Document'
              defaultMessage='Document'
            />
          </Label>
          <Field
            name='file'
            component={FileInput}
            accept='.pdf, .doc, .docx'
            className='bg-white'
          />

          <Label>
            <FormattedMessage id='AttachmentForm.Note' defaultMessage='Note' />
          </Label>
          <Field name='note' component={Input} />
          <div className='tr mt3'>
            <Button
              label={<FormattedMessage id='CancelButton' />}
              autoSize
              secondary
              onClick={onCancel}
              className='mr3'
            />
            <Button
              label={
                <FormattedMessage
                  id='CertificationForm.SubmitButton'
                  defaultMessage='Add'
                />
              }
              type='submit'
              autoSize
            />
          </div>
        </>
      )}
    </form>
  )
}

export default AttachmentForm
