import { call } from 'redux-saga/effects'
import vets from 'shared/utils/api/vets'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* addCriteriaSaga(action) {
  const { vetId, criticalCriteria } = action.payload.toJS()

  yield call(vets.addCriteria, { vetId, criticalCriteria })

  return yield criticalCriteria
}

export default createApiRequestSaga(addCriteriaSaga)
