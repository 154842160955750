import { connect } from 'react-redux'
import routingSelectors from 'shared/selectors/routingSelectors'
import TealboardNavLink from '../../components/TealboardNavLink'
import postsSelectors from '../../../posting/selectors/postsSelectors'
import RootState from 'shared/models/RootState'

export default connect((state: RootState) => ({
  unread: postsSelectors.getUnread(state),
  pathname: routingSelectors.getPathname(state)
}))(TealboardNavLink)
