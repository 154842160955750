import { call, put } from 'redux-saga/effects'
import users from 'shared/utils/api/users'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { notify } from 'shared/actions'

export function* resendUserInviteSaga(action) {
  const { userId, currentOrgUnit } = action.payload
  yield call(users.resendUserInvite, {
    orgUnitIdInvitingTo: currentOrgUnit,
    userIdsToResendInviteTo: userId
  })

  yield put(
    notify({
      message: 'Invitations have been re-sent'
    })
  )
  return action
}

export default createApiRequestSaga(resendUserInviteSaga, {
  notifyOnError: true
})
