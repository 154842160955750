import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadClientFiles } from '../../../shared/actions'
import ClientFilesDownload from '../../components/ClientFilesDownload'
import dataSelectors from '../../../shared/selectors/dataSelectors'
import Loading from 'shared/components/Loading'
import { List, Map } from 'immutable'

type Props = {
  clientFiles: List<Map<string, string>>
  loadClientFiles: typeof loadClientFiles
}

const ClientFilesDownloadContainer = ({
  clientFiles,
  loadClientFiles
}: Props) => {
  useEffect(() => {
    loadClientFiles({
      entity: 'clientFiles',
      query: 'loader/clientFiles'
    })
  }, [loadClientFiles])

  return clientFiles ? (
    <ClientFilesDownload clientFiles={clientFiles} />
  ) : (
    <Loading />
  )
}

export default connect(
  state => {
    return {
      clientFiles: dataSelectors.getClientFiles(state)
    }
  },
  {
    loadClientFiles
  }
)(ClientFilesDownloadContainer)
