import React, { FunctionComponent, useState } from 'react'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf } from 'immutable'
import {
  setCustomRulesType,
  changeDiversityQualificationRules
} from '../../../store/actions'
import RootState from 'shared/models/RootState'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import {
  IncludeRulesType,
  ExcludeRulesType,
  BaseRulesType,
  defaultExcludeRules,
  defaultBaseRules,
  defaultIncludeRules,
  baseRules as baseRulesRecord,
  includeRules as includeRulesRecord,
  excludeRules as excludeRulesRecord
} from '../../../store/diversityReportReducer'
import useSmallScreen from 'shared/utils/useSmallScreen'
import {
  getCustomRules,
  getAvailableAuthorities
} from '../../../store/diversityReportSelectors'
import {
  CustomRulesType,
  CustomRulesSummary
} from '../../SpendFiltersSummary/SpendFiltersSummary'
import storageManager from 'shared/utils/storageManager'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import { CorporateRulesType } from 'buyer/shared/reducers/settingsReducer'
import QualificationRulesContent from './QualificationRulesContent'

type Props = {
  authorities: List<string>
  baseRulesState: RecordOf<BaseRulesType>
  includeRulesState: RecordOf<IncludeRulesType>
  excludeRulesState: RecordOf<ExcludeRulesType>
  onApply?: () => void
  tier2?: boolean
  customRules?: CustomRulesType
  rulesCount?: number
  disabled?: boolean
  corporateRules?: RecordOf<CorporateRulesType>
  customRulesType?: string
  setCustomRulesType: (payload) => void
  changeDiversityQualificationRules: (payload) => void
}

const DiversityReportButtonFilters: FunctionComponent<Props> = props => {
  const {
    authorities,
    baseRulesState,
    includeRulesState,
    excludeRulesState,
    onApply,
    tier2,
    customRules,
    rulesCount,
    corporateRules,
    customRulesType,
    setCustomRulesType,
    changeDiversityQualificationRules
  } = props

  const [baseRules, setBaseRules] = useState<RecordOf<BaseRulesType>>(
    baseRulesState
  )
  const [includeRules, setIncludeRules] = useState<RecordOf<IncludeRulesType>>(
    includeRulesState
  )
  const [excludeRules, setExcludeRules] = useState<RecordOf<ExcludeRulesType>>(
    excludeRulesState
  )

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const isSmallScreen = useSmallScreen()
  const handleApply = () => {
    let includeRulesUpdate = includeRules

    includeRulesUpdate = includeRules
      .update('attestation', values =>
        values.get('certAgencies').size === 0
          ? values.set('certAgenciesSelected', false)
          : values
      )
      .update('completeness', values =>
        values.get('attachmentAgencies').size === 0
          ? values.set('attachmentAgenciesSelected', false)
          : values
      )

    setIncludeRules(
      includeRules
        .update('attestation', values =>
          values.get('certAgencies').size === 0
            ? values.set('certAgenciesSelected', false)
            : values
        )
        .update('completeness', values =>
          values.get('attachmentAgencies').size === 0
            ? values.set('attachmentAgenciesSelected', false)
            : values
        )
    )
    storageManager.setItem('baseRules', baseRules.toJS())
    storageManager.setItem('includeRules', includeRulesUpdate.toJS())
    storageManager.setItem('excludeRules', excludeRules.toJS())

    changeDiversityQualificationRules({
      baseRules,
      includeRules: includeRulesUpdate,
      excludeRules
    })
    setIsDialogOpen(false)
    setCustomRulesType('user')
    if (onApply) {
      onApply()
    }
  }

  const handleCancel = () => {
    setBaseRules(baseRulesState)
    setIncludeRules(includeRulesState)
    setExcludeRules(excludeRulesState)
    setIsDialogOpen(false)
  }
  const handleClearUserRules = () => {
    storageManager.removeItem('baseRules')
    storageManager.removeItem('includeRules')
    storageManager.removeItem('excludeRules')
    const baseRules = corporateRules
      ? corporateRules.get('baseRules')
      : defaultBaseRules
    const includeRules = corporateRules
      ? corporateRules.get('includeRules')
      : defaultIncludeRules
    const excludeRules = corporateRules
      ? corporateRules.get('excludeRules')
      : defaultExcludeRules

    changeDiversityQualificationRules({
      baseRules,
      includeRules,
      excludeRules
    })
    setBaseRules(baseRulesRecord(baseRules))
    setIncludeRules(includeRulesRecord(includeRules))
    setExcludeRules(excludeRulesRecord(excludeRules))
    if (onApply) {
      onApply()
    }
    setCustomRulesType(corporateRules ? 'corporate' : undefined)
  }
  return (
    <>
      <div className='flex'>
        <div className='w-100'>
          <Button
            disabled={!!props.disabled}
            label={
              rulesCount && rulesCount > 0 ? (
                <CustomRulesSummary customRules={customRules}>
                  <span
                    className='underline'
                    style={{
                      color: props.disabled ? 'rgba(0, 0, 0, 0.26)' : 'green'
                    }}
                  >
                    <FormattedMessage
                      id='DiversityReport.QualificationRulesTitle'
                      defaultMessage='{title} Qualification Rules'
                      values={{
                        title:
                          customRulesType === 'user'
                            ? 'My'
                            : customRulesType === 'corporate'
                            ? 'Corporate'
                            : undefined
                      }}
                    />
                    :&nbsp;{rulesCount}
                  </span>
                </CustomRulesSummary>
              ) : (
                <FormattedMessage
                  id='DiversityReport.QualificationRules'
                  defaultMessage='Qualification Rules'
                />
              )
            }
            secondary
            onClick={() => setIsDialogOpen(true)}
          />
        </div>
        {customRulesType === 'user' && (
          <div className='ml2 w-30'>
            <Button
              label={
                <FormattedMessage
                  id='DiversityReport.Reset'
                  defaultMessage='Reset Rules'
                />
              }
              secondary
              onClick={handleClearUserRules}
            />
          </div>
        )}
      </div>
      <Dialog
        fullWidth
        fullScreen={isSmallScreen}
        open={isDialogOpen}
        onClose={handleCancel}
      >
        <DialogTitle>
          <FormattedMessage
            id='DialogFilter.QualificationRulesTitle'
            defaultMessage='Qualification Rules'
          />
        </DialogTitle>
        {baseRules && includeRules && excludeRules && (
          <DialogContent>
            <QualificationRulesContent
              tier2={tier2}
              baseRules={baseRules}
              includeRules={includeRules}
              excludeRules={excludeRules}
              onChangeBaseRules={setBaseRules}
              onChangeIncludeRules={setIncludeRules}
              onChangeExcludeRules={setExcludeRules}
              authorities={authorities}
            />
          </DialogContent>
        )}
        <DialogActions>
          <div className='mr2'>
            <Button onClick={handleApply}>
              <FormattedMessage
                id='DialogFilter.Apply'
                defaultMessage='Apply'
              />
            </Button>
          </div>
          <Button onClick={handleCancel} secondary>
            <FormattedMessage id='CancelButton' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

type ConnectProps = {
  authorities?: List<string>
}

export default connect(
  (state: RootState, props: ConnectProps) => {
    const authorities = props.authorities
      ? props.authorities
      : getAvailableAuthorities(state)
    const baseRulesState = state.getIn([
      'buyer',
      'diversityReport',
      'diverseQualificationRules',
      'baseRules'
    ])
    const includeRulesState = state.getIn([
      'buyer',
      'diversityReport',
      'diverseQualificationRules',
      'includeRules'
    ])
    const excludeRulesState = state.getIn([
      'buyer',
      'diversityReport',
      'diverseQualificationRules',
      'excludeRules'
    ])
    const customRules = getCustomRules(state)
    const rulesCount = !!customRules
      ? Object.keys(customRules).filter(key => !!customRules[key]).length
      : 0

    const corporateRules = settingsSelectors.getField(
      state,
      'corporateDiversityQualificationRules'
    )

    const customRulesType = state.getIn([
      'buyer',
      'diversityReport',
      'customRulesType'
    ])
    return {
      authorities,
      baseRulesState,
      includeRulesState,
      excludeRulesState,
      customRules,
      rulesCount,
      corporateRules,
      customRulesType
    }
  },
  {
    setCustomRulesType,
    changeDiversityQualificationRules
  }
)(DiversityReportButtonFilters)
