import React, { Component } from 'react'
import UserListItem from 'shared/components/UserListItem'
import ActiveUserMetric from '../ActiveUserMetric'
import Badge from 'shared/components/Badge'
import { FormattedMessage } from 'react-intl'
import iconRFI from 'shared/assets/icons/rfi.svg'
import iconConnection from 'shared/assets/icons/connected-one.svg'
import iconTag from 'shared/assets/icons/tag.svg'
import iconContact from 'shared/assets/icons/contacts.svg'
import iconRating from 'shared/assets/icons/ratings.svg'
import iconFollow from 'shared/assets/icons/org-unit.svg'
import iconView from 'shared/assets/icons/view.svg'
import iconSearch from 'shared/assets/icons/search.svg'
import iconSession from 'shared/assets/icons/login.svg'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Link from 'shared/components/Link'

type Props = {
  profileUrl: string
  profilePictureUrl: string
  firstName: string
  lastName: string
  numberOfConnections: number
  numberOfTags: number
  numberOfContacts: number
  numberOfRatings: number
  numberOfFollows: number
  numberOfProfileViews: number
  numberOfSearches: number
  numberOfSessions: number
  numberOfVets: number
  place: number
  classes: any
}

const useStyles = {
  link: {
    '&:focus-visible': {
      outline: 'auto',
      outlineColor: 'black'
    }
  }
}

const componentStyles = theme => useStyles

class ActiveUser extends Component<Props> {
  render() {
    const {
      profileUrl,
      profilePictureUrl,
      firstName,
      lastName,
      numberOfConnections,
      numberOfTags,
      numberOfContacts,
      numberOfRatings,
      numberOfFollows,
      numberOfProfileViews,
      numberOfSearches,
      numberOfSessions,
      numberOfVets,
      place,
      classes
    } = this.props

    return (
      <li>
        <Link to={profileUrl} className={classNames(classes.link)}>
          <div className='dt w-100'>
            <div className='dtc v-mid pr3 w2'>
              <Badge place={place} />
            </div>
            <div className='dtc'>
              <UserListItem
                profilePictureUrl={profilePictureUrl}
                firstName={firstName}
                lastName={lastName}
                secondaryText={
                  <div className='flex flex-wrap'>
                    <ActiveUserMetric
                      label={
                        <FormattedMessage
                          id='ActiveUser.RFIs'
                          defaultMessage='RFIs'
                        />
                      }
                      icon={iconRFI}
                      value={numberOfVets}
                      alt='RFIs'
                    />
                    <ActiveUserMetric
                      label={
                        <FormattedMessage
                          id='ActiveUser.Connections'
                          defaultMessage='Connections'
                        />
                      }
                      icon={iconConnection}
                      value={numberOfConnections}
                      alt='Connections'
                    />
                    <ActiveUserMetric
                      label={
                        <FormattedMessage
                          id='ActiveUser.Tags'
                          defaultMessage='Tags'
                        />
                      }
                      icon={iconTag}
                      value={numberOfTags}
                      alt='Tags'
                    />
                    <ActiveUserMetric
                      label={
                        <FormattedMessage
                          id='ActiveUser.Follows'
                          defaultMessage='Follows'
                        />
                      }
                      icon={iconFollow}
                      value={numberOfFollows}
                      alt='Follows'
                    />
                    <ActiveUserMetric
                      label={
                        <FormattedMessage
                          id='ActiveUser.Views'
                          defaultMessage='Profile Views'
                        />
                      }
                      icon={iconView}
                      value={numberOfProfileViews}
                      alt='Profile Views'
                    />
                    <ActiveUserMetric
                      label={
                        <FormattedMessage
                          id='ActiveUser.Contacts'
                          defaultMessage='Contacts'
                        />
                      }
                      icon={iconContact}
                      value={numberOfContacts}
                      alt='Contacts'
                    />
                    <ActiveUserMetric
                      label={
                        <FormattedMessage
                          id='ActiveUser.Ratings'
                          defaultMessage='Ratings'
                        />
                      }
                      icon={iconRating}
                      value={numberOfRatings}
                      alt='Ratings'
                    />
                    <ActiveUserMetric
                      label={
                        <FormattedMessage
                          id='ActiveUser.Searches'
                          defaultMessage='Searches'
                        />
                      }
                      icon={iconSearch}
                      value={numberOfSearches}
                      alt='Searches'
                    />
                    <ActiveUserMetric
                      label={
                        <FormattedMessage
                          id='ActiveUser.Logins'
                          defaultMessage='Logins'
                        />
                      }
                      icon={iconSession}
                      value={numberOfSessions}
                      alt='Logins'
                    />
                  </div>
                }
              />
            </div>
          </div>
        </Link>
      </li>
    )
  }
}

export default withStyles(componentStyles)(ActiveUser)
