import React, { FC } from 'react'
import { connect } from 'react-redux'
import OrgUnit from 'shared/containers/OrgUnit'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import dataSelectors from 'shared/selectors/dataSelectors'
import paths from '../../../../routes/paths'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { FormattedMessage } from 'react-intl'
import { openGetVerifiedDialog, removeCustomer } from '../../../actions'
import Button from 'shared/components/Button'
import RootState from 'shared/models/RootState'
import { RecordOf } from 'immutable'

type Props = {
  client: RecordOf<{
    id: string
    buyer: string
    buyerName: string
  }>
  removeCustomer: (arg: { supplierRelationshipId: string }) => void
  openGetVerifiedDialog: (buyerId: string) => void
}

export const ClientListItemContainer: FC<Props> = props => {
  const handleRemoveCustomer = () => {
    const { removeCustomer, client } = props

    removeCustomer({ supplierRelationshipId: client.get('id') })
  }

  const handleClickGetVerified = () => {
    const { client, openGetVerifiedDialog } = props

    openGetVerifiedDialog(client.get('buyer'))
  }

  const { client } = props

  return (
    <OrgUnit
      id={client.get('buyer')}
      name={client.get('buyerName')}
      isClient
      profileUrl={
        client.get('buyer') &&
        paths.clientProfile.replace(':orgUnitId', client.get('buyer'))
      }
      rightButton={
        <IconButton
          aria-label='Close button'
          onClick={handleRemoveCustomer}
          style={{
            width: 32,
            height: 32
          }}
        >
          <CloseIcon />
        </IconButton>
      }
      secondaryText={
        client.getIn(['buyerCollaboration', 'verifyClient']) === 'agree' ? (
          <CheckCircleIcon aria-label='Check icon' className='teal' />
        ) : client.getIn(['buyerCollaboration', 'verifyClient']) ===
          'disagree' ? (
          <div className='red flex'>
            <ErrorIcon aria-label='Error icon' />
            <span className='flex-auto ml1'>
              <FormattedMessage
                id='ClientListItemContainer.RelationshipNotConfirmed'
                defaultMessage='Relationship could not be confirmed.'
              />
            </span>
          </div>
        ) : client.getIn(['supplierCollaboration', 'requestVerify']) ? (
          <span className='f6 gray'>
            <FormattedMessage
              id='ClientListItemContainer.PendingStatus'
              defaultMessage='Pending'
            />
          </span>
        ) : (
          !!client.get('buyer') && (
            <Button
              size='small'
              secondary
              autoSize
              onClick={handleClickGetVerified}
            >
              <FormattedMessage
                id='ClientListItemContainer.GetVerifiedButton'
                defaultMessage='Get Verified'
              />
            </Button>
          )
        )
      }
    />
  )
}

type ConnectedProps = {
  supplierRelationshipId: string
}

export default connect(
  (state: RootState, props: ConnectedProps) => ({
    client: dataSelectors.getById(
      state,
      'supplierRelationships',
      props.supplierRelationshipId
    )
  }),
  {
    removeCustomer,
    openGetVerifiedDialog
  }
)(ClientListItemContainer)
