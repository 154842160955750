import React, { FC } from 'react'
import PitchDeckEditForm from './PitchDeckEditForm'
import CompanyDescriptionEditForm from './CompanyDescriptionEditForm'
import PageSection from 'shared/components/PageSection'
import { FormattedMessage } from 'react-intl'

const AboutUsSection: FC = () => {
  return (
    <PageSection
      title={
        <FormattedMessage
          id='AboutUsSection.AboutUsTitle'
          defaultMessage='About Us'
        />
      }
    >
      <CompanyDescriptionEditForm />
      <PitchDeckEditForm />
    </PageSection>
  )
}

export default AboutUsSection
