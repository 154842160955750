import React from 'react'
import Input from '../Input'
import TextArea from '../TextArea'
import Select from '../Select'
import DatePicker from 'shared/components/DatePicker'
import Checkbox from '../Checkbox'

type InputType<T extends string, V, OtherProps = {}> = {
  type: T
  value: V
  onChange: (changedValue: { [key: string]: V }) => void
} & OtherProps

export type InputTypeProps =
  | InputType<'text' | 'textarea' | 'date', string>
  | InputType<'boolean', boolean>
  | InputType<
      'number',
      number,
      {
        min: number
      }
    >
  | InputType<
      'select',
      string,
      {
        options: string[]
      }
    >
  | InputType<
      'multipleSelect',
      string[],
      {
        options: string[]
      }
    >

type Props = {
  name: string
  placeholder?: string
  required?: boolean
} & InputTypeProps

const DynamicInput = (props: Props) => {
  switch (props.type) {
    case 'number':
    case 'text':
      return (
        <Input
          type={props.type}
          value={props.value}
          placeholder={props.placeholder}
          required={props.required}
          onChange={e => {
            props.onChange({
              [props.name]: e.target.value
            })
          }}
        />
      )

    case 'boolean':
      return (
        <Checkbox
          name={props.name}
          label='Yes'
          checked={props.value}
          onChange={() => {
            props.onChange({
              [props.name]: !props.value
            })
          }}
        />
      )

    case 'multipleSelect':
      return (
        <>
          {props.options?.map(option => (
            <Checkbox
              key={option}
              name={option}
              value={option}
              label={option}
              checked={props.value.includes(option)}
              onChange={e => {
                if (e.target.checked) {
                  props.onChange({
                    [props.name]: [...props.value, option]
                  })
                } else {
                  props.onChange({
                    [props.name]: props.value.filter(r => r !== e.target.value)
                  })
                }
              }}
            />
          ))}
        </>
      )

    case 'select':
      return (
        <Select
          value={props.value}
          required={props.required}
          fullWidth
          onChange={e =>
            props.onChange({
              [props.name]: e.currentTarget.value
            })
          }
        >
          <option key='A' value='' />
          {props.options?.map(option => (
            <option key={option}>{option}</option>
          ))}
        </Select>
      )

    case 'textarea':
      return (
        <TextArea
          required={props.required}
          value={props.value}
          onChange={e =>
            props.onChange({
              [props.name]: e.target.value
            })
          }
        />
      )

    case 'date':
      return (
        <DatePicker
          name={props.name}
          value={props.value}
          onChange={value => {
            props.onChange({
              [props.name]: value
            })
          }}
          required={props.required}
        />
      )
  }
}

export default DynamicInput
