import React from 'react'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import time from 'shared/assets/images/time.png'
import barChart from 'shared/assets/images/bar-chart.png'
import checklist from 'shared/assets/images/checklist.png'

type Props = {
  handleExitInstructionsPage: () => void
}

const SurveyInstructions = (props: Props) => {
  return (
    <>
      <div
        className='lh-copy f5 fw1'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '52%',
          margin: 'auto'
        }}
      >
        <div className='dib v-top tc'>
          <img
            alt='numSurveyQuestions'
            src={checklist}
            className='w-25 db center mv3'
          />
          <figcaption className='db'>
            {' '}
            Survey includes <span className='fw6'>30 questions </span>{' '}
          </figcaption>
        </div>
        <div className='dib v-top tc'>
          <img
            alt='timeToCompleteSurvey'
            src={time}
            className='w-25 db center mv3'
          />
          <figcaption className='db'>
            {' '}
            It takes approximately <span className='fw6'> 90 mins </span>to
            finish
          </figcaption>
        </div>
        <div className='dib v-top tc'>
          <img
            alt='responsesAvailability'
            src={barChart}
            className='w-25 db center mv3'
          />
          <figcaption className='db'>
            {' '}
            <span className='fw6'>Responses </span> available after submission
          </figcaption>
        </div>
      </div>
      <div className='flex-auto ml6'>
        <div className='lh-copy f5 ma0 mt4'>
          <Label className='db pt3 mb1 f5 fw6'>
            <FormattedMessage
              id='SurveyInstructions.Label'
              defaultMessage='Instructions'
            />
          </Label>
          <ol>
            <li>
              <FormattedMessage
                id='SurveyInstructionsOne'
                defaultMessage='There are a total of 5 sections.'
              />
            </li>
            <li>
              <FormattedMessage
                id='SurveyInstructionsTwo'
                defaultMessage='All questions must be answered before the survey can be submitted.'
              />
            </li>
            <li>
              <FormattedMessage
                id='SurveyInstructionsThree'
                defaultMessage='Do not enter any PII (Personal Identifiable Information).'
              />
            </li>
            <li>
              <FormattedMessage
                id='SurveyInstructionsFour'
                defaultMessage='Your progress will be automatically saved, therefore you can exit the session and complete it at a later date.'
              />
            </li>
            <li>
              <FormattedMessage
                id='SurveyInstructionsFive'
                defaultMessage='You can invite other members of your organization to collaborate on the survey at any time.'
              />
            </li>
          </ol>
        </div>
      </div>
      <div className='center tc mt5'>
        <Button
          onClick={props.handleExitInstructionsPage}
          label={
            <FormattedMessage
              id='SurveyInstructions.Continue'
              defaultMessage='Continue'
            />
          }
          autoSize
          size='large'
        />
      </div>
    </>
  )
}

export default SurveyInstructions
