import * as api from '../api'
import auth from '../auth'
import apiRoutes from '../../../routes/apiRoutes'
import { PasswordRules } from '../../../../admin/components/PasswordRulesSetting/PasswordRulesSetting'
import PunchoutPayload from '../../../models/PunchoutPayload'
import ApiError from '../ApiError'

const apiRoute = `${apiRoutes.authService}/partner`

export type PartnerRecord = {
  id: string
  partner: string
  client: string
  sharedSecret: string
  partnerContact: string
  status: string
  partnerDomain: string
  partnerName: string
  secretLocked: boolean
  active: boolean
  created: { user: string; date: string }
  sharedSecretRules?: PasswordRules
}

export const create = (partner: {
  partner: string
  partnerContact: string
  client: string
  sharedSecret: string
}) => api.post(apiRoute, partner)

export const updatePartner = ({ partnerId, ...partner }) => {
  return api.put(`${apiRoute}/${partnerId}`, partner)
}

export const unblockPartner = (partnerId: string) => {
  return api.post(`${apiRoute}/${partnerId}/unlock`)
}

export const removePartner = (partnerId: string) => {
  return api.remove(`${apiRoute}/${partnerId}`)
}

export const getPartnerProviders = () => {
  return api.get(`${apiRoute}/list`)
}

export const getPartnersList = (orgUnitId: string) => {
  return api.get(`${apiRoute}/${orgUnitId}/list`)
}

type PunchoutCartProps = {
  punchoutPayload?: PunchoutPayload
  cart: Array<{
    id: string
    name: string
    domain: string
  }>
}
export const sendPunchoutCart = ({
  punchoutPayload,
  cart
}: PunchoutCartProps) => {
  if (!punchoutPayload) {
    return Promise.resolve()
  }
  return api
    .post(`${apiRoutes.authService}/partner/punchoutReturn`, {
      setupPayload: punchoutPayload,
      cart: cart
    })
    .then(punchoutResult => {
      const returnMethod = punchoutPayload.returnMethod
      if (returnMethod.method === 'crossOriginPostMessage') {
        const targetWindow = window.opener
        if (!targetWindow) {
          throw new ApiError({
            clientErrorMessage:
              'No parent window detected. Make sure to launch TealBook from your application.'
          })
        }
        targetWindow.postMessage(punchoutResult, '*')
      }

      auth.logout().then(() => {
        if (returnMethod.method === 'browserFormPost') {
          api.postForm(returnMethod.url, punchoutResult)
        } else if (returnMethod.closeOnSend) {
          window.close()
        }
      })
    })
    .catch((err: Error) => {
      console.error(err)
      let errorMessage = 'Unexpected error.'
      if (err instanceof ApiError) {
        errorMessage = err.message
      }
      throw new Error(errorMessage)
    })
}
