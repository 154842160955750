const parseFilters = filters => {
  return Array.isArray(filters)
    ? filters.reduce((result, filter) => {
        result = Object.assign({}, result, filter)
        return result
      }, {})
    : filters
}

export default parseFilters
