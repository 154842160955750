import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import ContactCard from '../../components/ContactCard'
import usersSelectors from '../../selectors/usersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import cardsSelectors from 'buyer/shared/selectors/cardsSelectors'
import { fromJS, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'
import User from 'shared/models/User'
import Contact from 'shared/models/Contact'

type Props = {
  baseUrl: string
  userId: string
  isCurrentUser?: boolean
  isEditable?: boolean
  isEditing?: boolean
  isTealbot?: boolean
  isBuyerAdmin?: boolean
  connected?: boolean
  user: RecordOf<User>
  contact?: RecordOf<Contact>
  disableOpen?: boolean
  onRemove?: () => void
  onDelete?: () => void
  onEdit?: () => void
  onUpdate?: (additionalValues: any) => void
  onClickConnect?: (additionalValues: any) => void
  onUnfollow?: () => void
  onClickMessage?: (additionalValues: any) => void
} & RouteComponentProps

export class ContactCardContainer extends Component<Props> {
  onProfileClick = () => {
    const {
      baseUrl,
      userId,
      history: { push }
    } = this.props
    push(`${baseUrl}/users/${userId}`)
  }

  render() {
    const {
      location,
      disableOpen,
      contact = fromJS({}),
      user,
      ...rest
    } = this.props

    return (
      <ContactCard
        {...rest}
        contact={{
          ...contact.toJSON(),
          firstName: user.get('firstName'),
          lastName: user.get('lastName'),
          title: user.get('title'),
          email: user.get('email'),
          phone:
            user.get('mobilePhone') ||
            user.get('officePhone') ||
            contact.get('phone'),
          location:
            user.getIn(['location', 'address']) || contact.get('location'),
          profilePictureUrl: user.get('profilePictureUrl'),
          blocked: user.get('blocked')
        }}
        contactOwner={contact.get('contactOwner')}
        onProfileClick={disableOpen ? undefined : this.onProfileClick}
      />
    )
  }
}

type ContainerProps = {
  contact?: RecordOf<Contact>
  userId: string
}

export default connect((state: RootState, props: ContainerProps) => {
  const { contact, userId } = props
  const currentUser = sessionSelectors.getUserId(state)
  const user = usersSelectors.getById(state, userId)
  const contactOwner =
    contact && usersSelectors.getById(state, contact.get('contactOwner'))
  const colleagueIsConnected =
    !!user.get('orgUnitId') &&
    !!cardsSelectors.getCardsByColleagueContactConnection(
      state,
      user.get('orgUnitId'),
      userId
    )?.size
  return user
    ? {
        user,
        currentUser,
        contactOwnerFirstName: contactOwner && contactOwner.get('firstName'),
        contactOwnerLastName: contactOwner && contactOwner.get('lastName'),
        colleagueIsConnected
      }
    : undefined
})(withRouter(ContactCardContainer))
