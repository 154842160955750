import React, { Component, ReactNode } from 'react'
import classNames from 'classnames'

const styles = {
  title: '',
  clipTitle: 'clip',
  wrapper: 'mw8 center pa3 cf pb5'
}

type Props = {
  title: string | ReactNode
  showTitle?: boolean
  className?: string
  children: ReactNode
}

class Page extends Component<Props> {
  render() {
    const { title, showTitle, children, className } = this.props
    const titleClassName = showTitle ? styles.title : styles.clipTitle

    return (
      <section className={classNames(styles.wrapper, className)}>
        <h1
          className={titleClassName}
          aria-hidden={showTitle ? 'false' : 'true'}
        >
          {title}
        </h1>
        {children}
      </section>
    )
  }
}

export default Page
