import React, { ReactNode } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import Label from 'shared/components/Label'
import ImageFileInput from 'shared/components/ImageFileInput'
import { FormattedMessage } from 'react-intl'
import useSmallScreen from 'shared/utils/useSmallScreen'

type Props = {
  open: boolean
  onChange: Function
  onCancel: () => void
  onUpload: Function
  title: string | ReactNode
  label?: string | ReactNode
  imageAttributes: any
  fieldName: string
  noResize?: boolean
}

const UploadImageDialog = (props: Props) => {
  const {
    open,
    onChange,
    onCancel,
    title,
    label,
    imageAttributes,
    fieldName,
    noResize
  } = props

  const handleCrop = () => {
    const { onUpload, fieldName } = props
    onUpload(fieldName)
  }

  // check screen height to adjust dialog content to render without scroll
  const isSmallScreen = useSmallScreen()

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      disableBackdropClick
      fullWidth
      fullScreen={isSmallScreen}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {label && <Label noPadding={isSmallScreen}>{label}</Label>}
        <ImageFileInput
          solidBackground={fieldName === 'logo'}
          noResize={noResize}
          onChange={onChange}
          onCancel={onCancel}
          onCrop={handleCrop}
          actionButtonLabel={
            <FormattedMessage
              id='UploadImageDialog.Upload'
              defaultMessage='Upload'
            />
          }
          submitOnCrop
          {...imageAttributes}
        />
      </DialogContent>
    </Dialog>
  )
}

export default UploadImageDialog
