import React from 'react'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import ShowQuestionResponse from './ShowQuestionResponse'
import ScrollToPosition from 'shared/components/ScrollToPosition'

const pageId = 'governance'

const Governance = () => {
  return (
    <div>
      <ScrollToPosition />
      <Text className='fw6'>
        <FormattedMessage id='Governance.policies' />
      </Text>
      <div className='ml3'>
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='polices'
          questionId='codeOfConduct'
          question={<FormattedMessage id='Governance.codeOfConduct' />}
        />
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='polices'
          questionId='antiCorruption'
          question={<FormattedMessage id='Governance.antiCorruption' />}
        />
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='polices'
          questionId='childForceLabour'
          question={<FormattedMessage id='Governance.childForceLabour' />}
        />
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='polices'
          questionId='whistleblower'
          question={<FormattedMessage id='Governance.whistleblower' />}
        />
      </div>
      <ShowQuestionResponse
        pageId={pageId}
        questionId='complianceOfficer'
        question={<FormattedMessage id='Governance.complianceOfficer' />}
      >
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='complianceOfficer'
          questionId='jobTitle'
          question={<FormattedMessage id='Governance.jobTitle' />}
          longAnswer
          childAnswer
        />
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='complianceOfficer'
          questionId='jobTitleReportTo'
          question={<FormattedMessage id='Governance.jobTitleReportTo' />}
          longAnswer
          childAnswer
        />
      </ShowQuestionResponse>
      <ShowQuestionResponse
        pageId={pageId}
        questionId='activeEngagementProgram'
        question={<FormattedMessage id='Governance.activeEngagementProgram' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='requireAdhereCodeOfConduct'
        question={
          <FormattedMessage id='Governance.requireAdhereCodeOfConduct' />
        }
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='annualEsgReport'
        question={<FormattedMessage id='Governance.annualEsgReport' />}
      />
      <ShowQuestionResponse
        pageId={pageId}
        questionId='useSustainabilityReportingFramework'
        question={
          <FormattedMessage id='Governance.sustainabilityReportingFramework' />
        }
      >
        <ShowQuestionResponse
          pageId={pageId}
          parentQuestionId='useSustainabilityReportingFramework'
          questionId='frameworks'
          childAnswer
        />
      </ShowQuestionResponse>
      <ShowQuestionResponse
        pageId={pageId}
        questionId='note'
        question={<FormattedMessage id='optionalAdditionalNote' />}
        longAnswer
      />
    </div>
  )
}

export default Governance
