import { getLocaleFromBrowser, getMessagesByLocale } from '../i18n'
import { CHANGE_LANGUAGE } from './actionTypes'
import { LOGOUT, requestSuccess } from 'shared/actionTypes'
import { LOGIN } from 'accounts/actionTypes'

const defaultState = {
  locale: getLocaleFromBrowser(),
  messages: getMessagesByLocale()
}

const i18nReducer = (state = defaultState, action) => {
  switch (action.type) {
    case requestSuccess(LOGIN):
      return action.payload.userInfo && action.payload.userInfo.language
        ? {
            locale: action.payload.userInfo.language,
            messages: getMessagesByLocale(action.payload.userInfo.language)
          }
        : state

    case CHANGE_LANGUAGE:
      return {
        locale: action.payload,
        messages: getMessagesByLocale(action.payload)
      }

    // the locale might have changed from the point of
    // initializing the app and logging out
    case LOGOUT:
      return {
        locale: getLocaleFromBrowser(),
        messages: getMessagesByLocale()
      }

    default:
      return state
  }
}

export default i18nReducer
