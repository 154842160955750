import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { RENEW_RELATIONSHIP_ATTACHMENT } from '../../../SupplierProfile/actionTypes'
import ContractFormDialog from '../../components/ContractFormDialog'
import { RecordOf } from 'immutable'
import Attachment from './../SupplierOutreach'

const formName = 'supplierProfile/newAttachment'

type Props = {
  type: string
  supplierId: string
  activeUserId: string
  expiringAttachmentRelationshipId: string
  taskId: string
  expiringAttachment?: RecordOf<typeof Attachment>
  isContractFormDialogOpen: boolean
  reset: () => void
  handleSubmit: (formValues: any) => () => void
  renewContract: (
    formValues: any,
    { supplierId, expiringAttachmentRelationshipId, expiringAttachment: any }
  ) => void
  handleToggleContractFormDialog: () => void
  handleCompleteSupplierOutreachRequest: (
    taskId: string,
    status: string,
    currentUserId: string
  ) => void
}

export class ContractFormDialogContainer extends Component<Props> {
  handleSubmit = formValues => {
    const {
      renewContract,
      supplierId,
      taskId,
      handleToggleContractFormDialog,
      handleCompleteSupplierOutreachRequest,
      activeUserId,
      expiringAttachment,
      expiringAttachmentRelationshipId
    } = this.props
    renewContract(formValues, {
      supplierId,
      expiringAttachmentRelationshipId,
      expiringAttachment
    })
    handleToggleContractFormDialog()
    handleCompleteSupplierOutreachRequest(taskId, 'Completed', activeUserId)
  }

  handleCancel = () => {
    const { reset, handleToggleContractFormDialog } = this.props
    reset()
    handleToggleContractFormDialog()
  }

  render() {
    const { reset, handleSubmit, ...rest } = this.props

    return (
      <ContractFormDialog
        {...rest}
        onSubmit={handleSubmit(this.handleSubmit)}
        onCancel={this.handleCancel}
      />
    )
  }
}

export default connectReduxForm(
  state => ({}),
  {
    renewContract: createSubmitFormAction(
      formName,
      RENEW_RELATIONSHIP_ATTACHMENT
    )
  },
  {
    form: formName
  },
  ContractFormDialogContainer
)
