import React, { Component } from 'react'
import { connect } from 'react-redux'
import usersSelectors from 'shared/selectors/usersSelectors'
import PageSideSection from 'shared/components/PageSideSection'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import UserLookup from 'shared/components/UserLookup'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import { List } from 'immutable'

const CustomIconButton = withStyles({
  root: {
    height: 32,
    width: 32
  }
})(IconButton)

type Props = {
  onUpdate: Function
  contacts: List<string>
  users: any
  isTealbot: boolean
}

export class CommunityContacts extends Component<Props> {
  handleAddContact = userId => {
    const { onUpdate, contacts } = this.props

    onUpdate({
      contacts: contacts
        ? contacts
            .push(userId)
            .toSet()
            .toList()
            .map(id => ({ user: id }))
            .toJS()
        : [{ user: userId }]
    })
  }

  handleRemoveContact = userId => {
    const { onUpdate, contacts } = this.props
    onUpdate({
      contacts:
        contacts &&
        contacts
          .filter(id => id !== userId)
          .map(id => ({ user: id }))
          .toJS()
    })
  }

  render() {
    const { contacts, users, isTealbot } = this.props

    return isTealbot || (contacts && contacts.size > 0) ? (
      <PageSideSection
        title={
          <FormattedMessage
            id='CommunityContacts.CommunityContacts'
            defaultMessage='Community Contacts'
          />
        }
        noPadding
        className='pt4 mt4-ns'
      >
        <div className='ph3-5 pb2'>
          {contacts &&
            contacts.size > 0 &&
            contacts.map(contact => {
              return (
                <UserListItemContainer
                  mini
                  key={contact}
                  userId={contact}
                  profileUrl={parsePath(paths.userprofile, { userId: contact })}
                  rightButton={
                    isTealbot && (
                      <CustomIconButton
                        onClick={() => this.handleRemoveContact(contact)}
                        className='dim'
                      >
                        <CloseIcon />
                      </CustomIconButton>
                    )
                  }
                  className='mt1 mb2'
                />
              )
            })}
        </div>
        {isTealbot && (
          <div className='bg-near-white ph3-5 pb3-5'>
            <UserLookup
              label={
                <FormattedMessage
                  id='CommunityContacts.AddAContact'
                  defaultMessage='Add a contact'
                />
              }
              users={users}
              onClickSuggestedUser={user =>
                this.handleAddContact(user.get('id'))
              }
            />
          </div>
        )}
      </PageSideSection>
    ) : null
  }
}

export default connect((state: RootState) => ({
  users: usersSelectors.getColleagues(state)
}))(CommunityContacts)
