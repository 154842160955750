import React, { FC, ReactElement } from 'react'
import startCase from 'lodash.startcase'
import Text from 'shared/components/Text'
import Pill from 'shared/components/Pill'
import { FormattedMessage } from 'react-intl'
import { Map } from 'immutable'

type Props = {
  label: ReactElement<FormattedMessage> | string
  viewValue?: Map<string, number>
}

const ViewCount: FC<Props> = props => {
  const viewValueSize = props.viewValue && props.viewValue.entrySeq().size

  return !!viewValueSize ? (
    <>
      <Pill label={props.label} />
      <div className='pl4 mt2'>
        <div className='pl3 bl bw2 b--black-50'>
          {props.viewValue &&
            props.viewValue
              .entrySeq()
              .sort(
                ([key, viewCount1], [key2, viewCount2]) =>
                  viewCount1 - viewCount2
              )
              .map(([key, viewCount]) => {
                return (
                  <div className='mv2 hover-bg-near-white' key={key}>
                    <div className='w-100 w-80-ns v-mid dib'>
                      <Text>{startCase(key)}</Text>
                    </div>
                    <div className='w-100 w-20-ns v-mid dib'>
                      <Text className='fw6'>
                        <span className='teal'>{viewCount}</span>&nbsp;
                        <FormattedMessage
                          id='ViewDetails.ProfileViewsText'
                          defaultMessage={`{ viewCount, plural, one {time} other {times} }`}
                          values={{ viewCount }}
                        />
                      </Text>
                    </div>
                  </div>
                )
              })}
        </div>
      </div>
    </>
  ) : null
}

export default ViewCount
