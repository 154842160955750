import React, { Component, Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import qs from 'qs'
import Pill from 'shared/components/Pill'
import searchContactsSelectors from '../../../shared/selectors/searchContactsSelectors'
import { fromJS, List, Map, RecordOf } from 'immutable'

function removeLocationFilters(filtersForKey = fromJS([]), filtersToRemove) {
  return List.isList(filtersForKey.first())
    ? filtersForKey.filter(manyFilters =>
        manyFilters.some(
          item => !filtersToRemove.some(val => item.startsWith(val))
        )
      )
    : filtersForKey.filter(item => !item.startsWith(filtersToRemove))
}

type Props = {
  query: Map<string, string>
  filters: Map<string, RecordOf<{ value: string; label: string }>>
} & RouteComponentProps

class SearchFilterPillsContainer extends Component<Props> {
  handleRemoveFilter = (filterKey, filterValue) => () => {
    const { history, query } = this.props

    const newQuery = query.updateIn(['filter'], fromJS({}), filters => {
      if (['country', 'state', 'city'].includes(filterKey)) {
        return filters.merge({
          country: removeLocationFilters(filters.get('country'), filterValue),
          state: removeLocationFilters(filters.get('state'), filterValue),
          city: removeLocationFilters(filters.get('city'), filterValue)
        })
      } else if (filterKey.startsWith('roles.')) {
        const value = filterKey.split('.')[1]
        return filters.update('roles', roles => roles.filter(r => r !== value))
      } else {
        return filters.remove(filterKey)
      }
    })

    history.push({
      search: qs.stringify(newQuery.toJS())
    })
  }

  removeMyConnections = () => {
    const newQuery = this.props.query.delete('scope')
    this.props.history.push({
      search: qs.stringify(newQuery.toJS())
    })
  }

  render() {
    const { filters } = this.props

    return (
      <Fragment>
        {filters.entrySeq().map(([filterKey, item]) => (
          <Pill
            key={item.value}
            label={item.label || 'Unspecified'}
            onRequestDelete={this.handleRemoveFilter(filterKey, item.value)}
          />
        ))}
      </Fragment>
    )
  }
}

type ContainerProps = {
  location: string
}
export default withRouter(
  connect((state, props: ContainerProps) => ({
    filters: searchContactsSelectors.getFilterPills(props.location.search),
    query: searchContactsSelectors.getParsedQuery(props.location.search)
  }))(SearchFilterPillsContainer)
)
