import React, { Component, ComponentType } from 'react'
import editModeForm from '../editModeForm'
import AddForm, { AddFormProps } from '../../components/AddForm'

interface Props extends AddFormProps {
  stopEditMode: () => void
  startEditMode: () => void
}

function createAddForm<P extends AddFormProps>(
  options,
  //@ts-ignore
  WrappedComponent: ComponentType<P> = AddForm
) {
  class AddFormEditMode extends Component<Props> {
    handleClickCancel = () => {
      this.props.stopEditMode()
      if (this.props.onClickCancel) {
        this.props.onClickCancel()
      }
    }

    handleClickAdd = () => {
      this.props.startEditMode()
      if (this.props.onClickAdd) {
        this.props.onClickAdd()
      }
    }

    render() {
      const { stopEditMode, startEditMode, ...rest } = this.props

      return (
        <WrappedComponent
          {...(rest as P)}
          onClickCancel={this.handleClickCancel}
          onClickAdd={this.handleClickAdd}
        />
      )
    }
  }

  return editModeForm(options)(AddFormEditMode)
}

export default createAddForm
