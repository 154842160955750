import React, { useState, ReactElement } from 'react'
import { connect, useDispatch } from 'react-redux'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import TextArea from 'shared/components/TextArea'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import Checkbox from 'shared/components/Checkbox'
import Button from 'shared/components/Button'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { messageUser, updateData } from 'shared/actions'
import {
  updateContact,
  requestforSelfCertify,
  requestForESGSurvey,
  cancelAddingOutreachContact
} from '../../../SupplierProfile/actions'
import { fromJS, RecordOf } from 'immutable'
import useSmallScreen from 'shared/utils/useSmallScreen'
import {
  notify,
  resendSupplierOutreachRequest,
  contactSupplierForOutreachRequest
} from 'shared/actions'
import ESGSurveyMessageSummary from '../../../SupplierProfile/components/ESGSurveyMessageSummary'
import { cancelAddingSurveyCollaborateContact } from '../../../../supplier/shared/actions'

const messages = defineMessages({
  profilePageKeepInContact: {
    id: 'ConnectMessageDialog.KeepInContact',
    defaultMessage: 'I wanted to keep in contact with you through TealBook.'
  },
  notificationForNewlyAddedContact: {
    id: 'ConnectMessageDialog.notificationForNewlyAddedContact',
    defaultMessage: 'Message succesfully sent to newly added contact'
  }
})

type Props = {
  supplierId?: string
  userId: string
  description?: string | ReactElement<FormattedMessage>
  connectContact: boolean
  open: boolean
  defaultMessage?: string
  requestSelfCertify?: boolean
  resendOutreachRequest?: boolean
  requestESGSurvey?: boolean
  shareESGSurveyLink?: boolean
  contactSupplierForOutreach?: boolean
  sendCollaborateRequest?: boolean
  taskId?: string
  currentUserId?: string
  supplierName?: string
  relationshipId?: string
  onClose: () => void
  messageUser: (
    payload: RecordOf<{
      userToMessageId: string
      message: string
    }>
  ) => void
  updateContact: (
    payload: RecordOf<{
      supplierId?: string
      user: { id: string }
      connected: boolean
      contactFor: string
    }>
  ) => void
  notify: typeof notify
}

export const ConnectMessageDialog = (props: Props) => {
  const dispatch = useDispatch()
  const {
    open,
    description,
    supplierId,
    userId,
    connectContact,
    defaultMessage,
    requestSelfCertify,
    requestESGSurvey,
    resendOutreachRequest,
    shareESGSurveyLink,
    contactSupplierForOutreach,
    sendCollaborateRequest,
    taskId,
    currentUserId,
    supplierName,
    relationshipId,
    messageUser,
    updateContact,
    onClose,
    notify
  } = props
  const intl = useIntl()
  const [message, setMessage] = useState<string>(
    defaultMessage || intl.formatMessage(messages.profilePageKeepInContact)
  )
  const [contactFor, setContactFor] = useState<string>('')
  const [isPrivate, setIsPrivate] = useState<boolean>(false)
  const [sendMessage, setSendMessage] = useState<boolean>(false)
  const handleChange = e => {
    if (e.currentTarget.name === 'message') {
      setMessage(e.currentTarget.value)
    } else if (e.currentTarget.name === 'contactFor') {
      setContactFor(e.currentTarget.value)
    } else if (e.currentTarget.name === 'sendMessage') {
      setSendMessage(e.currentTarget.checked)
    } else if (e.currentTarget.name === 'isPrivate') {
      setIsPrivate(e.currentTarget.checked)
    }
  }

  const handleSendMessage = e => {
    e.preventDefault()
    if (
      !contactSupplierForOutreach &&
      !resendOutreachRequest &&
      !requestSelfCertify &&
      !requestESGSurvey &&
      !sendCollaborateRequest &&
      (!connectContact || sendMessage)
    ) {
      messageUser(
        fromJS({
          userToMessageId: userId,
          message
        })
      )
      notify({
        message: intl.formatMessage(messages.notificationForNewlyAddedContact)
      })
      onClose()
    }

    if (contactSupplierForOutreach && (!connectContact || sendMessage)) {
      messageUser(
        fromJS({
          userToMessageId: userId,
          message
        })
      )
      dispatch(
        contactSupplierForOutreachRequest({
          taskId,
          status: 'WaitingForClient',
          currentUserId,
          requestedContactId: userId
        })
      )
      dispatch(
        updateData({
          entity: 'tasks',
          record: {
            id: taskId,
            status: 'WaitingForClient',
            form: {
              requestedContactId: userId
            }
          }
        })
      )
      notify({
        message: intl.formatMessage(messages.notificationForNewlyAddedContact)
      })
      dispatch(cancelAddingOutreachContact())
    }
    if (resendOutreachRequest && (!connectContact || sendMessage)) {
      messageUser(
        fromJS({
          userToMessageId: userId,
          message,
          shareESGSurveyLink
        })
      )
      dispatch(
        resendSupplierOutreachRequest({
          taskId,
          status: 'WaitingForClient',
          currentUserId,
          requestedContactId: userId
        })
      )
      dispatch(
        updateData({
          entity: 'tasks',
          record: {
            id: taskId,
            status: 'WaitingForClient',
            form: {
              requestedContactId: userId
            }
          }
        })
      )
      notify({
        message: intl.formatMessage(messages.notificationForNewlyAddedContact)
      })
      dispatch(cancelAddingOutreachContact())
    }
    if (requestSelfCertify && (!connectContact || sendMessage)) {
      messageUser(
        fromJS({
          userToMessageId: userId,
          message
        })
      )
      dispatch(
        requestforSelfCertify({
          supplierId: supplierId,
          relationshipId: relationshipId,
          supplierName: supplierName,
          requestedContactId: userId
        })
      )
      notify({
        message: intl.formatMessage(messages.notificationForNewlyAddedContact)
      })
      dispatch(cancelAddingOutreachContact())
    }
    if (requestESGSurvey && (!connectContact || sendMessage)) {
      messageUser(
        fromJS({
          userToMessageId: userId,
          message,
          shareESGSurveyLink
        })
      )
      dispatch(
        requestForESGSurvey({
          supplierId: supplierId,
          relationshipId: relationshipId,
          supplierName: supplierName,
          requestedContactId: userId
        })
      )
      notify({
        message: intl.formatMessage(messages.notificationForNewlyAddedContact)
      })
      dispatch(cancelAddingOutreachContact())
    }
    if (sendCollaborateRequest && (!connectContact || sendMessage)) {
      messageUser(
        fromJS({
          userToMessageId: userId,
          message,
          shareESGSurveyLink
        })
      )
      notify({
        message: intl.formatMessage(messages.notificationForNewlyAddedContact)
      })
      dispatch(cancelAddingSurveyCollaborateContact())
    }
    if (connectContact) {
      updateContact(
        fromJS({
          supplierId,
          user: { id: userId },
          connected: true,
          contactFor,
          isPrivate
        })
      )
      onClose()
    }
  }
  const matchMobile = useSmallScreen()

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      disableBackdropClick
      fullScreen={matchMobile}
      onExited={() => {
        setMessage(intl.formatMessage(messages.profilePageKeepInContact))
        setContactFor('')
        setIsPrivate(true)
        setSendMessage(false)
      }}
    >
      <DialogTitle>
        {connectContact ? (
          <FormattedMessage
            id='ConnectMessageDialog.ConnectContact'
            defaultMessage='Connect to the user'
          />
        ) : (
          <FormattedMessage
            id='ConnectMessageDialog.SendMessage'
            defaultMessage='Send a message'
          />
        )}
      </DialogTitle>
      <DialogContent>
        <form id='messageDialog' onSubmit={handleSendMessage}>
          {description && <Label>{description}</Label>}
          <div className='mb3-ns mb4 pt3 pt0-ns'>
            <UserListItemContainer mini userId={userId} />
          </div>
          <div className='bg-near-white pa3'>
            {connectContact && (
              <div className='mb2-ns mb4 mt3 mt0-ns'>
                <FormattedMessage
                  id='ConnectMessageDialog.WhyWouldThisPersonBeContacted'
                  defaultMessage='Why would this person be contacted?'
                >
                  {message => (
                    <Input
                      name='contactFor'
                      value={contactFor}
                      onChange={handleChange}
                      placeholder={message as string}
                    />
                  )}
                </FormattedMessage>
              </div>
            )}
            {connectContact && (
              <div className='mb2'>
                <Checkbox
                  name='isPrivate'
                  label={
                    <FormattedMessage
                      id='ConnectMessageDialog.KeptPrivate'
                      defaultMessage='Keep connection private'
                    />
                  }
                  checked={isPrivate}
                  onChange={handleChange}
                />
              </div>
            )}
            {connectContact && (
              <div className='mb2'>
                <Checkbox
                  name='sendMessage'
                  label={
                    <FormattedMessage
                      id='ConnectMessageDialog.SendAMessage'
                      defaultMessage='Send a message'
                    />
                  }
                  checked={sendMessage}
                  onChange={handleChange}
                />
              </div>
            )}
            {(!connectContact || (connectContact && sendMessage)) && (
              <div className='bg-white pa3 ba b--light-gray'>
                <TextArea
                  name='message'
                  value={message}
                  onChange={handleChange}
                  maxLength={500}
                  required={!connectContact || (connectContact && sendMessage)}
                />
              </div>
            )}
            {shareESGSurveyLink && (
              <ESGSurveyMessageSummary
                name={`Sustainability Survey Link`}
                description={`Link to the Sustainability Survey Form`}
              />
            )}
          </div>
        </form>
      </DialogContent>
      <DialogActions className='fixed static-ns bottom-1'>
        <Button
          size={matchMobile ? 'large' : undefined}
          label={
            connectContact ? (
              <FormattedMessage
                id='ConnectMessageDialog.Connect'
                defaultMessage='Connect'
              />
            ) : (
              <FormattedMessage
                id='ConnectMessageDialog.Send'
                defaultMessage='Send'
              />
            )
          }
          type='submit'
          form='messageDialog'
        />
        <Button
          size={matchMobile ? 'large' : undefined}
          label={<FormattedMessage id='CancelButton' />}
          onClick={onClose}
          secondary
          autoSize
        />
      </DialogActions>
    </Dialog>
  )
}

export default connect(null, {
  messageUser,
  updateContact,
  notify
})(ConnectMessageDialog)
