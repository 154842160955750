import { fromJS } from 'immutable'
import { requestSuccess, requestFailure } from 'shared/actionTypes'
import * as actionTypes from '../../actionTypes'
import { UPDATE_CONTACT } from '../../../SupplierProfile/actionTypes'
import qs from 'qs'

const defaultQuery = qs.stringify({
  scope: 'MyConnections'
})

const initialState = fromJS({
  resultIds: [],
  resultsById: {},
  resultFilters: {},
  numberOfResults: 0,
  lastQuery: `?${defaultQuery}`,
  isSearching: false,
  searchingError: undefined,
  locationSearchResults: {},
  isSearchingLocations: false,
  userHasNoContacts: undefined,
  hasSearched: false
})

const contactsSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_CONTACTS:
      return state.merge({
        lastQuery: action.payload,
        isSearching: true,
        searchingError: undefined,
        hasSearched: true
      })

    case requestSuccess(actionTypes.SEARCH_CONTACTS):
      return state.merge(fromJS(action.payload)).set('isSearching', false)

    case requestFailure(actionTypes.SEARCH_CONTACTS):
      return state.merge({
        searchingError: action.payload,
        isSearching: false
      })

    case actionTypes.SEARCH_LOCATIONS:
      return state.merge({
        isSearchingLocations: true,
        locationSearchResults: fromJS({})
      })

    case requestSuccess(actionTypes.SEARCH_LOCATIONS):
      return state.merge({
        isSearchingLocations: false,
        locationSearchResults: fromJS(action.payload.resultFilters)
      })

    case requestSuccess(UPDATE_CONTACT):
      const {
        userId,
        cardId,
        ownerId,
        connected,
        newCard,
        ...restChanges
      } = action.payload
      const contact = state.getIn(['resultsById', userId])
      const cards = contact && contact.get('contacts')
      const cardIndex =
        cards && cards.findIndex(card => card.get('user') === ownerId)

      return !contact || connected === undefined
        ? state
        : cardIndex !== -1
        ? state.deleteIn(['resultsById', userId, 'contacts', cardIndex])
        : state.updateIn(['resultsById', userId, 'contacts'], contacts => {
            return contacts
              ? contacts.push(
                  fromJS({ user: ownerId, connected, ...restChanges })
                )
              : fromJS([{ user: ownerId, connected, ...restChanges }])
          })

    default:
      return state
  }
}

export default contactsSearchReducer
