import React, { Component } from 'react'

/**
 * SubTitleText is just a html h5 tag with custom formatting
 */

class SubTitleText extends Component {
  render() {
    return (
      <h5 className='f6 f4-m f4-l fw4 gray mv2 mv3-ns lh-title'>
        {this.props.children}
      </h5>
    )
  }
}

export default SubTitleText
