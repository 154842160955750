import { call, put, select, all } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { notify } from 'shared/actions'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import cards from 'shared/utils/api/cards'
import { fromJS, Set } from 'immutable'

export function* tagSuppliersSaga(action) {
  const { suppliersToTag, keywords } = action.payload

  yield all(
    suppliersToTag
      .toJS()
      .map(supplierId => call(tagSupplier, supplierId, keywords))
  )

  yield put(
    notify({
      message: `Suppliers have been tagged by tealbot`
    })
  )
}

function* tagSupplier(supplierId, keywords) {
  const tagsFieldName = 'offerings'
  const currentCard = yield select(cardsSelectors.getBySupplier, supplierId)

  if (
    !currentCard ||
    currentCard.size === 0 ||
    currentCard.get('placeholderCard') ||
    currentCard.get('id').endsWith('-1')
  ) {
    // create a card if is not there yet
    yield call(cards.create, {
      supplier: supplierId,
      [tagsFieldName]: keywords.toJS()
    })
  } else {
    // update the card's tags if the tag is not there
    const cardTags = currentCard
      .getIn([tagsFieldName], fromJS({}))
      .toList()
      .toSet()
    const combinedTags = cardTags.union(Set(keywords))

    if (cardTags.size < combinedTags.size) {
      // combined tags have more than original tags
      yield call(cards.update, currentCard.get('id'), {
        [tagsFieldName]: combinedTags.toJS()
      })
    }
  }
}

export default createApiRequestSaga(tagSuppliersSaga)
