import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'

export function* removeVetTeamSaga(action) {
  const { vetId, userId } = action.payload
  yield call(vets.removeTeamMember, { vetId, userId })
  return yield action.payload
}

export default createApiRequestSaga(removeVetTeamSaga)
