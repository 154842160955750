const basePath = '/jobs'

const paths = {
  basePath,
  jobs: `${basePath}/list`,
  list: `${basePath}/list/:show?`,
  detail: `${basePath}/details/:jobId/:row?`,
  loader: `${basePath}/loader`,
  results: `${basePath}/results`,
  userprofile: `${basePath}/users/:userId`,
  editUserProfile: `${basePath}/users/:userId/edit`
}

export default paths
