const fileToUrl = file => {
  if (file && file.size > 0) {
    const bucketName = file.get('bucketName')
    const fileName = file.get('fileName')
    if (bucketName && fileName) {
      return `https://storage.googleapis.com/${bucketName}/${fileName}`
    }
  }
}

export default fileToUrl
