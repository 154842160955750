import { call, put } from 'redux-saga/effects'
import staging from 'shared/utils/api/staging'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { push } from 'connected-react-router'

export function* removeSpendSaga(action) {
  const { stagingId, listType, uploadHistory, activeQuarter } = action.payload
  const response = yield call(staging.removeSupplierSpend, {
    stagingId,
    listType
  })

  if (uploadHistory && activeQuarter) {
    const availableQuarters = uploadHistory
      .keySeq()
      .filter(key => key !== activeQuarter)
    const getActiveQuarter = uploadHistory
      .get(activeQuarter)
      .filter(staging => staging.get('id') !== stagingId)

    // if staging rows left in activeQuarter from uploadHistoy make that active tab, else if other years available in uploadHistory make the latest year/quarter the active tab
    if (Array.isArray(response) && response.length) {
      if (getActiveQuarter.size) {
        yield put(push(`?show=${activeQuarter}`))
      } else if (availableQuarters.size) {
        yield put(push(`?show=${availableQuarters.first()}`))
      }
    }
  }
  return response
}

export default createApiRequestSaga(removeSpendSaga)
