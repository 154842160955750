import { call } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'
import users from 'shared/utils/api/users'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import Immutable from 'immutable'

export function* addVetSupplierByEmailSaga(action) {
  const { email } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  let domainFound = false
  let matches
  let exactMatches
  let matchesOrgUsers

  const checkUsers = yield call(users.getAllByEmail, email)
  if (checkUsers.length > 0) {
    domainFound = true
    matchesOrgUsers = checkUsers.reduce((orgUsers, user) => {
      orgUsers[user.orgUnitId] = user.userId
      return orgUsers
    }, {})
    exactMatches = checkUsers.map(user => {
      return {
        id: user.orgUnitId,
        name: user.orgUnitName,
        supplier: {
          logo: user.orgUnitLogo
        }
      }
    })
  }

  const checkDomain = yield call(supplier.getBuyerByDomain, email)
  if (checkDomain.found) {
    domainFound = true
    matches = checkDomain.names.filter(org => {
      return (
        !exactMatches ||
        !exactMatches.find(userOrg => {
          return userOrg.id === org.id
        })
      )
    })
  }

  if (domainFound) {
    return yield {
      result: 'matchFound',
      matches,
      matchesOrgUsers,
      exactMatches
    }
  } else {
    return yield {
      result: 'notFound'
    }
  }
}

export default createApiRequestSaga(addVetSupplierByEmailSaga)
