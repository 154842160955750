import * as actionTypes from '../../actionTypes'
import { fromJS } from 'immutable'
import { EXIT_MESSAGES_APP } from 'messages/actionTypes'

const defaultState = fromJS({
  userIdsToMessage: [], // ids of users that the current user can message
  userToMessage: '',
  topicsById: {},
  topicsByUser: {},
  byTopic: {},
  openForwardSurveyRequestDialog: false
})

const messReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.requestSuccess(actionTypes.LOAD_MESSAGE_TOPICS):
      return state
        .mergeIn(
          ['userIdsToMessage'],
          fromJS(Object.keys(action.payload.usersWithConversation))
        )
        .mergeIn(['topicsById'], fromJS(action.payload.topicsById))
        .mergeIn(['topicsByUser'], fromJS(action.payload.topicsByUser))

    case actionTypes.SELECT_USER_TO_MESSAGE:
      return state.merge({
        userToMessage: action.payload.userToMessage,
        loadingConversation: action.payload.loading
      })

    case EXIT_MESSAGES_APP:
    case actionTypes.UNSELECT_USER_TO_MESSAGE:
      return state.merge({
        userToMessage: undefined,
        loadingConversation: false
      })

    case actionTypes.requestSuccess(actionTypes.SELECT_USER_TO_MESSAGE):
      return action.payload.topicId
        ? state
            .setIn(
              ['byTopic', action.payload.topicId],
              fromJS(action.payload.messages)
            )
            .set('loadingConversation', false)
            .set('userToMessage', action.payload.userId)
        : state.set('loadingConversation', false)

    case actionTypes.requestSuccess(actionTypes.MESSAGE_USER):
      // ignore if there are no messages
      if (!action.payload.message) {
        return state
      }

      const newState = action.payload.newTopic
        ? state
            .setIn(
              ['topicsById', action.payload.newTopic.id],
              fromJS(action.payload.newTopic)
            )
            .setIn(
              ['topicsByUser', action.payload.userToMessageId],
              action.payload.newTopic.id
            )
        : state

      return newState
        .updateIn(['byTopic', action.payload.topicId], messages =>
          messages
            ? messages.push(fromJS(action.payload.message))
            : fromJS([action.payload.message])
        )
        .mergeIn(['topicsById', action.payload.topicId], {
          lastMessage: action.payload.message.body,
          lastMessageBy: action.payload.message.createdBy,
          lastMessageDate: action.payload.message.createdDate
        })

    case actionTypes.requestSuccess(actionTypes.READ_MESSAGE):
      return state.setIn(
        ['topicsById', action.payload.topicId, 'numUnreadMessages'],
        0
      )
    case actionTypes.OPEN_FORWARD_SURVEY_REQUEST_DIALOG:
      return state.set('openForwardSurveyRequestDialog', true)

    case actionTypes.CLOSE_FORWARD_SURVEY_REQUEST_DIALOG:
      return state.set('openForwardSurveyRequestDialog', false)

    default:
      return state
  }
}

export default messReducer
