import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import searchSelectors from '../../selectors/searchSelectors'
import Button from 'shared/components/Button'
import { loadSowJob } from '../../actions'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import CircularProgress from '@material-ui/core/CircularProgress'

const SowJobButton = () => {
  const dispatch = useDispatch()
  const supplierIds = useSelector(searchSelectors.getSupplierIds)
  const loading = useSelector((state: RootState) =>
    state.getIn(['jobs', 'loadingSowJob'])
  )

  const handleButtonClick = () => {
    if (supplierIds && supplierIds.size > 0) {
      dispatch(loadSowJob(supplierIds.take(10).toJS()))
    }
  }

  return (
    <Button autoSize size='small' onClick={handleButtonClick}>
      {loading ? (
        <CircularProgress
          color='secondary'
          disableShrink
          size={22}
          thickness={4}
        />
      ) : (
        <FormattedMessage id='SowJobButton.Verify' defaultMessage='Verify' />
      )}
    </Button>
  )
}

export default SowJobButton
