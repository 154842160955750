import React, { Component, ReactNode } from 'react'
import { connect } from 'react-redux'
import { List, RecordOf } from 'immutable'
import FilterHOC from '../FilterHOC'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import searchSelectors from '../../selectors/searchSelectors'
import CheckboxList from 'shared/components/CheckboxList'
import Divider from 'shared/components/Divider'
import CertificationCategories, {
  CategoryType
} from 'shared/models/CertificationCategories'
import Tooltip from 'shared/components/Tooltip'
import Text from 'shared/components/Text'
import Switch from 'shared/components/Switch'
import Button from 'shared/components/Button'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import toggleArrayItem from 'shared/utils/toggleArrayItem'
import { defaultMemoize } from 'reselect'
import RootState from 'shared/models/RootState'
import { Skeleton } from '@material-ui/lab'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import translateCertificationType from 'shared/utils/translateCertificationType'

type AggregationsValueType = {
  key: string
  doc_count: number
}

type Props = {
  selected: List<string | List<string>>
  disabled: List<string>
  certificationSubCategories: List<RecordOf<AggregationsValueType>>
  subTypes: List<RecordOf<AggregationsValueType>>
  categorySelected: boolean
  categoryDisabled: boolean
  categoryCount: number
  intl: IntlShape
  onToggleFilterOption: (args: { key: string; value: string }) => void
  onChangeFilterOption: (args: {
    key: string
    value: string[] | Array<string[]>
  }) => void
  showSkeleton?: boolean
  filterCategory?: string
} & ContainerProps

type State = {
  changed: boolean
  matchAny: boolean
  conditions: string[]
  subTypesConditions: string[]
}

export class FilterCertificationContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const { selected } = props
    let matchAny = false
    let conditions: string[] = []
    let subTypesConditions: string[] = []
    if (List.isList(selected) && selected.size > 0) {
      const firstSelectedItem = selected.first()
      if (List.isList(firstSelectedItem)) {
        matchAny = true
        conditions = firstSelectedItem.toJS()
      }
    }

    this.state = {
      changed: false,
      matchAny,
      conditions,
      subTypesConditions
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.matchAny && !state.changed && props.selected.size === 0) {
      return {
        conditions: []
      }
    }

    return null
  }

  handleOnChange = v => {
    const { onToggleFilterOption, category } = this.props
    const { matchAny, conditions } = this.state

    if (matchAny) {
      this.setState({
        conditions: toggleArrayItem(conditions, v),
        changed: true
      })
    } else {
      onToggleFilterOption({ key: category, value: v })
    }
  }

  handleCategoryOnChange = e => {
    const { onChangeFilterOption, category } = this.props
    e.preventDefault()
    onChangeFilterOption({
      key: category,
      value: e.target.checked ? [category] : []
    })
  }

  handleApplyClick = () => {
    const { onChangeFilterOption, category } = this.props
    const { conditions } = this.state

    onChangeFilterOption({
      key: category,
      value: [conditions.filter(c => c !== category)]
    })
    this.setState({
      changed: false
    })
  }

  toggleMatchAny = () => {
    const { onChangeFilterOption, selected, category } = this.props
    const { matchAny } = this.state

    if (matchAny) {
      // toggle off
      this.setState({
        matchAny: false,
        changed: false
      })
      let resetValue = selected.toJS()
      if (selected.size > 0) {
        const firstSelectedVal = selected.first()
        if (List.isList(firstSelectedVal)) {
          resetValue = firstSelectedVal
            .push(category)
            .toSet()
            .toList()
            .toJS()
        }
      }
      onChangeFilterOption({ key: category, value: resetValue })
    } else {
      // toggle on
      this.setState({
        matchAny: true,
        changed: true,
        conditions: selected.toJS()
      })
    }
  }

  render() {
    const {
      showMatchAny,
      category,
      categoryLabel,
      disabled,
      selected,
      categorySelected,
      categoryDisabled,
      categoryCount,
      categoryLabelTip,
      showSkeleton,
      intl,
      filterCategory
    } = this.props
    const { matchAny, changed, conditions } = this.state

    const titleMap =
      CertificationCategories[category] &&
      Object.entries(CertificationCategories[category].subCategories).reduce<{
        [key: string]: string
      }>(
        (result, [key, subCategoryMessage]) => ({
          ...result,
          [key]: this.props.intl.formatMessage(subCategoryMessage)
        }),
        {}
      )
    const list = parseCertificationSubCategories(
      this.props.certificationSubCategories,
      category,
      intl
    )
    const subTypesList =
      this.props.selected &&
      this.props.subTypes &&
      parseSubTypesSubCategories(
        this.props.subTypes,
        this.props.category,
        intl,
        this.state.matchAny
          ? List(conditions)
          : (this.props.selected as List<string>)
      )
    return (
      <div>
        {showSkeleton && (
          <div className='pt1'>
            <Skeleton variant='rect' width='100%' height={18} />
          </div>
        )}
        {!showSkeleton && (
          <>
            <div className='pv1'>
              <fieldset style={{ display: 'contents' }}>
                <legend aria-label={filterCategory} />
                <input
                  type='checkbox'
                  id={category}
                  value={category}
                  onChange={this.handleCategoryOnChange}
                  checked={categorySelected}
                  disabled={categoryDisabled}
                />
              </fieldset>{' '}
              <Tooltip title={categoryLabelTip || categoryLabel}>
                <label htmlFor={category} className='f7 fw3 mid-gray pointer'>
                  {categoryLabel} ({categoryCount})
                </label>
              </Tooltip>
            </div>
            {categorySelected && (
              <div>
                <Divider className='mv1' />
                <div className='pl2'>
                  {showMatchAny && (
                    <div className='flex items-center justify-between mb1'>
                      <Text secondary={!matchAny}>
                        <FormattedMessage
                          id='FilterCertificationContainer.matchAny'
                          defaultMessage='Matches any of...'
                        />
                      </Text>
                      <Switch
                        checked={matchAny}
                        onChange={this.toggleMatchAny}
                      />
                    </div>
                  )}
                  <CheckboxList
                    titleMap={titleMap}
                    list={list}
                    selected={matchAny ? List(conditions) : selected}
                    disabled={disabled}
                    onChange={this.handleOnChange}
                    filterKey={'certifications'}
                    showSkeleton={showSkeleton}
                    category={filterCategory}
                  />

                  {subTypesList &&
                    subTypesList.size > 0 &&
                    selected &&
                    selected.size > 0 && (
                      <>
                        <Divider className='mv1' />
                        <div className='pl2'>
                          <CheckboxList
                            list={subTypesList}
                            selected={matchAny ? List(conditions) : selected}
                            onChange={this.handleOnChange}
                            filterKey={'certifications'}
                            showSkeleton={showSkeleton}
                            category={filterCategory}
                          />
                        </div>
                      </>
                    )}
                  {matchAny && (
                    <div className='tr'>
                      <Divider className='mt1 mb2' />
                      <Button
                        label={
                          <FormattedMessage
                            id='FilterCertificationContainer.apply'
                            defaultMessage='Apply'
                          />
                        }
                        autoSize
                        disabled={!changed}
                        onClick={this.handleApplyClick}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

const parseSubTypesSubCategories = defaultMemoize(
  (
    subTypes: List<RecordOf<AggregationsValueType>>,
    category,
    intl: IntlShape,
    selected: List<string>
  ) => {
    if (subTypes) {
      return subTypes
        .filter(type => {
          // filter out the subTypes filter not belong to selected subCategory
          const parseKey = type.get('key').split('.')
          return selected.includes(parseKey[1])
        })
        .map(type => {
          const parseKey = type.get('key').split('.')
          const acronym = translateCertificationType({
            intl,
            categoryType: category,
            subCategoryType: parseKey[1],
            useAcronym: true
          })
          const label = translateCertificationType({
            intl,
            categoryType: category,
            subCategoryType: parseKey[1],
            subType: parseKey[2]
          })
          return {
            value: type.get('key'),
            label: `${acronym} - ${label}`,
            count: type.get('doc_count')
          }
        })
    }
  }
)

const parseCertificationSubCategories = defaultMemoize(
  (
    subCategories: List<RecordOf<AggregationsValueType>>,
    category: CategoryType,
    intl: IntlShape
  ) => {
    return subCategories
      .filter(subCat => subCat.get('key') !== category)
      .map(subCat => {
        const label = translateCertificationType({
          categoryType: category,
          subCategoryType: subCat.get('key'),
          intl,
          useAcronym: true
        })
        return {
          value: subCat.get('key'),
          label: label,
          count: subCat.get('doc_count')
        }
      })
  }
)

type ContainerProps = {
  category: CategoryType
  categoryLabel: string | ReactNode
  showMatchAny?: boolean
  categoryLabelTip?: string | ReactNode
}

export default injectIntl(
  connect(
    (state: RootState, props: ContainerProps) => {
      const disabled = settingsSelectors.getFixedFilter(state, props.category)
      const selected = disabled
        ? filterOptionsSelectors
            .getFilterOption(state, props.category)
            .merge(disabled)
        : filterOptionsSelectors.getFilterOption(state, props.category)
      const showSkeleton: boolean = searchSelectors.isAggregating(state)
      const certificationRootCategory = searchSelectors.getCertificationRootCategory(
        state,
        props.category
      )
      return {
        showSkeleton,
        certificationSubCategories: searchSelectors.getCertificationCategory(
          state,
          props.category
        ),
        subTypes: searchSelectors.getCertSubTypes(state, props.category),
        selected,
        disabled,
        categoryCount:
          certificationRootCategory &&
          certificationRootCategory.get('doc_count').toLocaleString(),
        categorySelected:
          (selected && selected.size > 0) ||
          settingsSelectors.getFixedFilter(state, props.category),
        categoryDisabled: settingsSelectors.getFixedFilter(
          state,
          props.category
        )
      }
    },
    {
      ...FilterHOC
    }
  )(FilterCertificationContainer)
)
