import { createSelector } from 'reselect'

export const getQ = state => state.getIn(['dataPortal', 'orgs', 'q'])
export const getSearchBuyers = state =>
  state.getIn(['dataPortal', 'orgs', 'searchBuyers'])

export const getAll = state => state.getIn(['dataPortal', 'orgs', 'result'])
export const getAllSortedByName = createSelector(
  getAll,
  orgs =>
    orgs &&
    orgs.sort((org1, org2) =>
      org1.get('name').toUpperCase() < org2.get('name').toUpperCase() ? -1 : 1
    )
)

export const getTotal = state => state.getIn(['dataPortal', 'orgs', 'total'])
export const isFetching = state =>
  state.getIn(['dataPortal', 'orgs', 'isFetching'])
export const isCreatingOrg = state =>
  state.getIn(['dataPortal', 'orgs', 'isCreating'])
export const getMatchingOrgs = state =>
  state.getIn(['dataPortal', 'orgs', 'matchingOrgs'])
export const isSearchingOrg = state =>
  state.getIn(['dataPortal', 'orgs', 'isSearching'])
export const getSearchExactDomain = state =>
  state.getIn(['dataPortal', 'orgs', 'searchExactDomain'])
