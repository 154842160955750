import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import staging from 'shared/utils/api/staging'

export const loadClientData = data => {
  const endpointUrl = data.enableNewLoader
    ? 'client/client'
    : 'client/clientLegacy'
  return api.postFile(`${apiRoutes.loader}/${endpointUrl}`, data)
}

export const getStatus = () => api.get(`${apiRoutes.authService}/stat/status`)

export const getClientLoaderStatus = (labels?: string) =>
  api.get(
    `${apiRoutes.authService}/stat/loaderStats${
      labels ? `?label=${labels}` : ''
    }`
  )

export const uploadSpend = data =>
  api.postFile(`${apiRoutes.loader}/client/client`, data).then(() => {
    return staging.getStagingHistory({
      listType: data.listType
    })
  })
