import React, { useState, useEffect } from 'react'
import SurveyBanner from './SurveyBanner'
import EsgNav from './EsgNav'
import EsgFooter from './EsgFooter'
import { FormattedMessage } from 'react-intl'
import paths from '../../../routes/paths'
import { Switch, Route, Redirect } from 'react-router-dom'
import DiversityInclusion from './DiversityInclusion'
import Environment from './Environment'
import Governance from './Governance'
import HealthSafety from './HealthSafety'
import Privacy from './Privacy'
import { saveSurvey, loadSurveyResponse } from '../../actions'
import surveySelectors from '../../selectors/surveySelectors'
import { useSelector, useDispatch } from 'react-redux'
import ReadonlyHealthSafety from 'buyer/SupplierProfile/components/EsgSurvey/HealthSafety'
import ReadonlyEnvironment from 'buyer/SupplierProfile/components/EsgSurvey/Environment'
import ReadonlyDiversityInclusion from 'buyer/SupplierProfile/components/EsgSurvey/DiversityInclusion'
import ReadonlyGovernance from 'buyer/SupplierProfile/components/EsgSurvey/Governance'
import ReadonlyPrivacy from 'buyer/SupplierProfile/components/EsgSurvey/Privacy'
import RootState from 'shared/models/RootState'
import CollaborateInviteDialogContainer from '../../containers/CollaborateInviteDialogContainer'
import SurveyInstructions from './SurveyInstructions'

const EsgSurvey = () => {
  const dispatch = useDispatch()
  const surveyId = useSelector(surveySelectors.getEsgSurveyId)
  const hasSubmitted = useSelector((state: RootState) =>
    surveySelectors.hasSubmitted(state, surveyId)
  )
  const [showInstructions, setShowInstructions] = useState<boolean>(false)
  const handleExitInstructionsPage = () => {
    setShowInstructions(false)
  }
  useEffect(() => {
    // save survey container if it does not exist
    if (!surveyId) {
      dispatch(saveSurvey({ type: 'esg' }))
      setShowInstructions(true)
    } else {
      dispatch(loadSurveyResponse(surveyId))
    }
  }, [dispatch, surveyId])

  return (
    <>
      <div className='mb3 mw8 center'>
        <SurveyBanner
          title={
            <FormattedMessage
              id='EsgSurvey.Title'
              defaultMessage={'Sustainability Survey'}
            />
          }
          showCloseButton={hasSubmitted}
          showInstructions={showInstructions}
        />
      </div>
      {showInstructions && (
        <SurveyInstructions
          handleExitInstructionsPage={handleExitInstructionsPage}
        />
      )}
      {!showInstructions && (
        <>
          <EsgNav />
          {surveyId && (
            <div className='mb3 mw8 center'>
              <Switch>
                <Route
                  path={paths.healthSafety}
                  render={props =>
                    hasSubmitted ? <ReadonlyHealthSafety /> : <HealthSafety />
                  }
                />
                <Route
                  path={paths.environment}
                  render={props =>
                    hasSubmitted ? <ReadonlyEnvironment /> : <Environment />
                  }
                />
                <Route
                  path={paths.diversityInclusion}
                  render={props =>
                    hasSubmitted ? (
                      <ReadonlyDiversityInclusion />
                    ) : (
                      <DiversityInclusion />
                    )
                  }
                />
                <Route
                  path={paths.governance}
                  render={props =>
                    hasSubmitted ? <ReadonlyGovernance /> : <Governance />
                  }
                />
                <Route
                  path={paths.privacy}
                  render={props =>
                    hasSubmitted ? <ReadonlyPrivacy /> : <Privacy />
                  }
                />
                <Redirect to={paths.healthSafety} />
              </Switch>
            </div>
          )}
          {!hasSubmitted && <EsgFooter />}
        </>
      )}
      <CollaborateInviteDialogContainer />
    </>
  )
}

export default EsgSurvey
