import React from 'react'
import { useSelector } from 'react-redux'
import { RecordOf, List } from 'immutable'
import Warning from 'shared/components/Warning'
import { FormattedMessage } from 'react-intl'
import editUserSelectors from '../../selectors/editUserSelectors'

type Props = {
  bulkErrorMessage?: RecordOf<{
    activity: string
    listOfUsersWithErrors: List<string>
  }>
}
const BulkErrorMessage = ({ bulkErrorMessage }: Props) => {
  return bulkErrorMessage &&
    !!bulkErrorMessage.get('listOfUsersWithErrors').size ? (
    <Warning
      message={
        <>
          {bulkErrorMessage.get('activity') === 'roles' && (
            <FormattedMessage
              id='UserList.ErrorMessageRoles'
              defaultMessage='Users must have either a supplier or buyer role. 
          {numberNotUpdated, plural, 
            one {This user was not updated: {listOfNotUpdated}} 
            other {These users were not updated: {listOfNotUpdated}}}'
              values={{
                numberNotUpdated: bulkErrorMessage.get('listOfUsersWithErrors')
                  .size,
                listOfNotUpdated: bulkErrorMessage
                  .get('listOfUsersWithErrors')
                  .join(', ')
              }}
            />
          )}
          {bulkErrorMessage.get('activity') === 'delete' && (
            <FormattedMessage
              id='UserList.ErrorMessageDelete'
              defaultMessage='Active users cannot be deleted. 
          {numberNotUpdated, plural, 
            one {This user was not updated: {listOfNotUpdated}} 
            other {These users were not updated: {listOfNotUpdated}}}'
              values={{
                numberNotUpdated: bulkErrorMessage.get('listOfUsersWithErrors')
                  .size,
                listOfNotUpdated: bulkErrorMessage
                  .get('listOfUsersWithErrors')
                  .join(', ')
              }}
            />
          )}
        </>
      }
    />
  ) : null
}

const BulkErrorMessageContainer = () => {
  const bulkErrorMessage: RecordOf<{
    activity: string
    listOfUsersWithErrors: List<string>
  }> = useSelector(editUserSelectors.getBulkErrorMessage)
  return <BulkErrorMessage bulkErrorMessage={bulkErrorMessage} />
}
export default BulkErrorMessageContainer
