import React, { useState, ChangeEvent } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import Checkbox from 'shared/components/Checkbox'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import OrgLogo from 'shared/components/OrgLogo'
import { RecordOf, List } from 'immutable'

type Props = {
  orgUnit?: RecordOf<{
    id: string
    logo: string
    name: string
    domains: List<string>
  }>
  inviteSupplierDialogOpen: boolean
  onHandleCloseTier2Dialog: () => void
  onHandleInvite: any
  suppliers?: Array<
    RecordOf<{
      id: string
      name: string
      logoUrl: string
      relatedCompany: boolean
    }>
  >
  onSelectSuppliers?: (supplierId: string) => void
  selectedIds?: string[]
  supplierIds?: string[]
}
const InviteToTier2Dialog = ({
  orgUnit,
  inviteSupplierDialogOpen,
  onHandleCloseTier2Dialog,
  onHandleInvite,
  suppliers,
  onSelectSuppliers,
  selectedIds,
  supplierIds
}: Props) => {
  const [confirmedInvite, setConfirmedInvite] = useState<boolean>(false)

  const handleConfirmed = () => {
    setConfirmedInvite(false)
    onHandleInvite()
  }

  return (
    <Dialog
      open={inviteSupplierDialogOpen}
      onClose={() => onHandleCloseTier2Dialog()}
      fullWidth
    >
      <DialogTitle>
        <FormattedMessage
          id='Tier2InviteSupplierContainer.ConfirmInvite'
          defaultMessage='Inviting a Supplier to Tier 2'
        />
      </DialogTitle>
      <DialogContent>
        <Text>
          <FormattedMessage
            id='Tier2InviteSupplierContainer.InvitingSupplier'
            defaultMessage="Please confirm the supplier(s) you've selected to invite."
          />
        </Text>
        {orgUnit && (
          <div className='flex items-center mt2' key={orgUnit.get('id')}>
            <div>
              <Checkbox
                checked={confirmedInvite}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmedInvite(e.currentTarget.checked)
                }
              />
            </div>
            <div className='ml2 mr2 w2'>
              <OrgLogo url={orgUnit && orgUnit.get('logo')} />
            </div>
            <Label noPadding>{orgUnit && orgUnit.get('name')}</Label>
          </div>
        )}
        {supplierIds && selectedIds && onSelectSuppliers && (
          <div className='flex items-center mt2'>
            <div>
              <Checkbox
                checked={selectedIds.length === supplierIds.length}
                onChange={() => onSelectSuppliers('allSuppliers')}
              />
            </div>
            <Text className='ml2'>Select All</Text>
          </div>
        )}
        {selectedIds &&
          onSelectSuppliers &&
          suppliers &&
          suppliers.map(supplier => (
            <div key={supplier.get('id')} className='flex items-center mt2'>
              <div>
                <Checkbox
                  checked={selectedIds.includes(supplier.get('id'))}
                  onChange={() => onSelectSuppliers(supplier.get('id'))}
                />
              </div>
              <div className='ml2 mr2 w2'>
                <OrgLogo url={supplier.get('logoUrl')} />
              </div>
              <Label noPadding>{supplier.get('name')}</Label>
            </div>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmed}
          disabled={selectedIds ? !selectedIds.length : !confirmedInvite}
        >
          <FormattedMessage
            id='Tier2InviteSupplierContainer.Invite'
            defaultMessage='Invite'
          />
        </Button>
        <Button onClick={() => onHandleCloseTier2Dialog()} secondary>
          <FormattedMessage id='CancelButton' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InviteToTier2Dialog
