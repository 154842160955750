import startCase from 'lodash.startcase'

const parseCollaboration = ({
  status,
  proposal: {
    created: proposalCreated,
    modified: proposalModified,
    opened,
    interested,
    meetCriteria,
    submitted,
    ...restProposal
  },
  ...restCollaboration
}) => {
  return {
    status: startCase(status),
    proposal: {
      created: proposalCreated && {
        user: proposalCreated.user,
        date: new Date(proposalCreated.date)
      },
      modified: proposalModified && {
        user: proposalModified.user,
        date: new Date(proposalModified.date)
      },
      opened: opened && { user: opened.user, date: new Date(opened.date) },
      interested: interested && {
        user: interested.user,
        date: new Date(interested.date)
      },
      meetCriteria: meetCriteria && {
        user: meetCriteria.user,
        date: new Date(meetCriteria.date)
      },
      submitted: submitted && {
        user: submitted.user,
        date: new Date(submitted.date)
      },
      ...restProposal
    },
    ...restCollaboration
  }
}

export default parseCollaboration
