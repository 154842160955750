import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from 'react'
import { List, Map, RecordOf } from 'immutable'
import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import ShowcaseBuyers from './ShowcaseBuyers'
import Button from 'shared/components/Button'
import TealbookLogo from 'shared/components/TealbookLogo'
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  IntlShape
} from 'react-intl'
import OnboardingHeader from './OnboardingHeader'
import OnboardingCompanyContainer from '../../containers/OnboardingCompanyContainer'
import DiversitySectionContainer from '../../../Company/containers/DiversitySectionContainer'
import Paper from 'shared/components/Paper'
import createTagsForm from 'shared/utils/createTagsForm'
import { ADD_TAGS, REMOVE_TAGS } from '../../../Company/actionTypes'
import companySelectors from '../../selectors/companySelectors'
import Locations from '../../../Company/containers/Locations'
import CompanyLocationAddFormContainer from '../../../Company/containers/CompanyLocationAddFormContainer'
import OnboardingNext from './OnboardingNext'
import imgShader from 'shared/assets/images/shader.svg'
import imgShaderReverse from 'shared/assets/images/shader-reverse.svg'
import SupplierBenefits from './SupplierBenefits'
import { useHistory } from 'react-router'
import paths from '../../../routes/paths'
import qs from 'qs'

const messages = defineMessages({
  companyLabel: {
    id: 'SupplierOnboardingPage.CompanyLabel',
    defaultMessage: 'Company'
  },
  certificationsLabel: {
    id: 'SupplierOnboardingPage.CertificationsLabel',
    defaultMessage: 'Certifications'
  },
  offeringsLabel: {
    id: 'SupplierOnboardingPage.OfferingsLabel',
    defaultMessage: 'Offerings'
  },
  locationLabel: {
    id: 'SupplierOnboardingPage.LocationLabel',
    defaultMessage: 'Location'
  },
  nextLabel: {
    id: 'SupplierOnboardingPage.NextLabel',
    defaultMessage: 'Done'
  },
  companyTitle: {
    id: 'SupplierOnboardingPage.CompanyTitle',
    defaultMessage: 'Getting to Know your Company'
  },
  companySubtitle: {
    id: 'SupplierOnboardingPage.CompanySubtitle',
    defaultMessage:
      'We want to make sure we got your company’s name right. The short description is shown right next to your name (so you don’t need to include your company name in it). The long description gives you an opportunity to provide more detail.'
  },
  certificationsTitle: {
    id: 'SupplierOnboardingPage.CertificationsTitle',
    defaultMessage: 'Certifications'
  },
  certificationsSubtitle: {
    id: 'SupplierOnboardingPage.CertificationsSubtitle',
    defaultMessage:
      'The companies using TealBook are very interested in your certifications, primarily for diversity but also for security, quality and sustainability. Add the ones that apply and upload a copy of the certificate if you have it.'
  },
  offeringsTitle: {
    id: 'SupplierOnboardingPage.OfferingsTitle',
    defaultMessage: 'Promote your Offerings and Capabilities'
  },
  offeringsSubtitle: {
    id: 'SupplierOnboardingPage.OfferingsSubtitle',
    defaultMessage:
      'We use tags to help companies find the goods and services you offer. Provide five or more short, descriptive tags that use language you think best describes your offerings. We recommend using initial capitals (Window Cleaning, Exterior Painting), and don’t support the use of symbols, like % or &.'
  },
  locationTitle: {
    id: 'SupplierOnboardingPage.LocationTitle',
    defaultMessage: 'Where are you Located?'
  },
  locationSubtitle: {
    id: 'SupplierOnboardingPage.LocationSubtitle',
    defaultMessage:
      'Tealbook is used all around the world. The addresses you provide here will help companies determine if you can provide what they need where they need it.'
  },
  nextTitle: {
    id: 'SupplierOnboardingPage.NextTitle',
    defaultMessage: 'What’s Next?'
  },
  nextSubtitle: {
    id: 'SupplierOnboardingPage.NextSubtitle',
    defaultMessage:
      'You are in control of your corporate profile. The more information you put in it, the more useful it will be to existing and prospective customers. We recommend that you:'
  },
  skipToCompany: {
    id: 'SupplierOnboardingPage.SkipToCompany',
    defaultMessage: 'Skip to company profile'
  }
})

const OfferingsTagsForm = createTagsForm({
  formName: 'supplier/company/offerings',
  fieldName: 'offerings',
  addTagActionType: ADD_TAGS,
  removeTagActionType: REMOVE_TAGS,
  tagsSelector: companySelectors.getCompanySupplierField,
  limit: 100
})

const StyleStepper = withStyles({
  root: {
    padding: '0 0 0 24px'
  }
})(Stepper)

type Props = {
  width: string
  step?: number
  firstName: string
  buyers: List<
    RecordOf<{
      id: string
      name: string
      logo: Map<
        string,
        RecordOf<{
          fileName: string
          bucketName: string
        }>
      >
    }>
  >
  intl: IntlShape
}

export const SupplierOnboardingPage: FunctionComponent<Props> = props => {
  const history = useHistory()
  const documentElement: HTMLElement = document.documentElement || document.body

  const [activeStep, setActiveStep] = useState<number>(props.step || 0)
  const [showcase, setShowcase] = useState<boolean>(props.step === undefined)
  const [showBenefits, setShowBenefits] = useState<boolean>(
    props.step === undefined
  )
  const [upShadow, setUpShadow] = useState<boolean>(false)
  const [downShadow, setDownShadow] = useState<boolean>(false)
  const [completed, setCompleted] = useState<{ [key: number]: boolean }>(
    props.step ? { [props.step]: true } : {}
  )

  const updateShadows = useCallback(() => {
    const innerHeight: number = window.innerHeight
    const scrollHeight: number = documentElement.scrollHeight
    const scrollTop: number = documentElement.scrollTop

    if (scrollTop > 0) {
      setUpShadow(true)
    } else {
      setUpShadow(false)
    }

    if (scrollHeight - scrollTop > innerHeight) {
      setDownShadow(true)
    } else {
      setDownShadow(false)
    }
  }, [documentElement.scrollHeight, documentElement.scrollTop])

  const { step } = props

  useEffect(() => {
    if (step !== undefined) {
      setActiveStep(step)
      setCompleted(completed => ({
        ...completed,
        [step]: true
      }))
      setShowcase(false)
    } else {
      setShowcase(true)
    }
  }, [step])

  useEffect(() => {
    window.addEventListener('scroll', updateShadows)
    window.addEventListener('resize', updateShadows)
    return () => {
      window.removeEventListener('scroll', updateShadows)
      window.removeEventListener('resize', updateShadows)
    }
  }, [updateShadows])

  const moveStepper = (step: number) => {
    documentElement.scrollTop = 0
    if (step < 0 || step > steps.length) {
      return
    }

    if (step === steps.length) {
      history.push(paths.company)
    } else {
      setActiveStep(step)
      setCompleted(Object.assign({}, completed, { [step]: true }))
      updatePathname(step)
      setTimeout(updateShadows, 250)
    }
  }

  const startWizard = () => {
    documentElement.scrollTop = 0
    updatePathname(0)
    setShowcase(false)
  }

  const updatePathname = (step: number) =>
    history.push({ search: qs.stringify({ step }) })

  const steps = ['company', 'location', 'certifications', 'offerings', 'next']

  return (
    <div className='bg-white' style={{ height: '100%', minHeight: '100vh' }}>
      {showBenefits ? (
        <SupplierBenefits onDone={() => setShowBenefits(false)} />
      ) : showcase ? (
        <ShowcaseBuyers
          buyers={props.buyers}
          firstName={props.firstName}
          onContinue={startWizard}
        />
      ) : (
        <>
          <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <div className='bg-white bb b--black-10'>
              <div className='bb b--black-10 pv3 ph3 ph0-ns'>
                <div className='mw8 center flex justify-between items-center'>
                  <TealbookLogo />
                  <div style={{ flexGrow: 1 }}>
                    <StyleStepper nonLinear activeStep={activeStep}>
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepButton
                            onClick={() => moveStepper(index)}
                            completed={completed[index]}
                          >
                            {['xs', 'sm'].includes(props.width)
                              ? ''
                              : props.intl.formatMessage(
                                  messages[`${steps[index]}Label`]
                                )}
                          </StepButton>
                        </Step>
                      ))}
                    </StyleStepper>
                  </div>
                </div>
              </div>
              <div className='mw8 center pa3 cf'>
                <OnboardingHeader
                  hideLogo
                  title={props.intl.formatMessage(
                    messages[`${steps[activeStep]}Title`]
                  )}
                  subtitle={props.intl.formatMessage(
                    messages[`${steps[activeStep]}Subtitle`]
                  )}
                />
              </div>
            </div>
            {upShadow && (
              <img
                src={imgShaderReverse}
                alt=''
                style={{
                  width: '100%',
                  height: '30px'
                }}
              />
            )}
          </div>
          <div className='mw8 center mv3'>
            <div className='w-100 w-70-ns center ph3 ph0-ns'>
              <Paper noPadding>{getStepContent(activeStep)}</Paper>
            </div>
          </div>
          <div style={{ height: 100 }} />
          <div className='fixed bottom-0 left-0 right-0'>
            {downShadow && (
              <img
                src={imgShader}
                alt=''
                style={{
                  width: '100%',
                  height: '30px',
                  position: 'relative',
                  marginBottom: '-3px'
                }}
              />
            )}
            <div className='bg-white bt b--black-10 pv3 ph3'>
              <div className='mw8 center tr'>
                <div className='flex items-center justify-between'>
                  <span
                    className='pointer gray hover-teal'
                    onClick={() => history.push(paths.company)}
                  >
                    {props.intl.formatMessage(messages.skipToCompany)}
                  </span>
                  <div>
                    <Button
                      autoSize
                      className='ml3'
                      size='large'
                      disabled={activeStep === 0}
                      onClick={() => moveStepper(activeStep - 1)}
                      secondary
                    >
                      <FormattedMessage
                        id='SupplierOnboardingPage.Back'
                        defaultMessage='Back'
                      />
                    </Button>
                    <Button
                      autoSize
                      className='ml3'
                      size='large'
                      onClick={() => moveStepper(activeStep + 1)}
                    >
                      {activeStep === steps.length - 1 ? (
                        <FormattedMessage
                          id='SupplierOnboardingPage.Done'
                          defaultMessage='Done'
                        />
                      ) : (
                        <FormattedMessage
                          id='SupplierOnboardingPage.Next'
                          defaultMessage='Next'
                        />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <OnboardingCompanyContainer />
    case 1:
      return (
        <>
          <Locations />
          <CompanyLocationAddFormContainer />
        </>
      )
    case 2:
      return <DiversitySectionContainer />
    case 3:
      return (
        <FormattedMessage
          id='SupplierOnboardingPage.Skill'
          defaultMessage='Skill (ex: Advertising or Digital Marketing)'
        >
          {message => (
            <OfferingsTagsForm
              addLabel={
                <FormattedMessage
                  id='SupplierOnboardingPage.AddASkill'
                  defaultMessage='Add a skill'
                />
              }
              inputPlaceholder={message}
            />
          )}
        </FormattedMessage>
      )
    case 4:
      return <OnboardingNext />
    default:
      return null
  }
}

export default injectIntl(SupplierOnboardingPage)
