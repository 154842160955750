import moment from 'moment'
import User from '../models/User'

const w: any = window

const identifyUser = (user: User) => {
  if (process.env.NODE_ENV === 'production') {
    const isTealbot = user.roles?.some(r => r === 'tealbot')

    // identify user
    w._uiq.push([
      'identify',
      user.id,
      {
        user_name: user.firstName + ' ' + user.lastName,
        user_email: user.email,
        user_title: user.title,
        account_id: user.orgUnitId,
        account_name: user.orgUnitName,
        signup_date: moment(user.createdDate).format('YYYY-MM-DD'),
        is_admin:
          isTealbot ||
          user.roles?.some(r => r === 'buyerAdmin' || r === 'supplierAdmin'),
        user_type: isTealbot
          ? 'concierge'
          : user.roles?.some(r => r === 'buyer')
          ? 'buyer'
          : user.roles?.some(r => r === 'supplier')
          ? 'supplier'
          : 'unknown',
        internal_user: isTealbot || user.email.endsWith('@tealbook.com')
      }
    ])

    // start tracker
    w._uiq.push(['startTracker'])
  }
}

const analytics = {
  identifyUser
}

export default analytics
