import { fromJS } from 'immutable'
import * as searchActionTypes from 'buyer/Search/actionTypes'
import { LOGOUT } from 'shared/actionTypes'
import fileToUrl from 'shared/utils/data/fileToUrl'
import storageManager from 'shared/utils/storageManager'

const collectionReducer = (state = fromJS({}), action) => {
  let nextState
  switch (action.type) {
    case searchActionTypes.SET_COLLECTION:
      return state.merge(fromJS(action.payload))
    case searchActionTypes.ADD_COLLECTION:
      nextState = state.set(
        action.payload.supplierId,
        fromJS(trimSupplier(action.meta))
      )
      storageManager.setItem('cart', nextState.toJS())
      return nextState
    case searchActionTypes.REMOVE_COLLECTION:
      nextState = state.delete(action.payload.supplierId)
      storageManager.setItem('cart', nextState.toJS())
      return nextState
    case searchActionTypes.CLEAR_COLLECTION:
      storageManager.removeItem('cart')
      return fromJS({})
    case LOGOUT:
      return fromJS({})
    default:
      return state
  }
}

export default collectionReducer

const trimSupplier = ({
  id,
  name,
  logoUrl,
  domains,
  supplier,
  relatedCompany
}) => {
  if (!logoUrl) {
    const clearbitUrl = domains ? `https://logo.clearbit.com/${domains[0]}` : ''
    logoUrl = (supplier && fileToUrl(supplier.logo)) || clearbitUrl
  }

  return {
    id,
    name,
    domain: (domains && domains[0]) || '',
    logoUrl,
    relatedCompany
  }
}
