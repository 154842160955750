import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { getFormSubmitErrors } from 'shared/reducers'
import InviteToCommunitiesDialog from '../../components/InviteToCommunitesDialog'
import communitiesSelectors from '../../../Communities/selectors/communitiesSelectors'
import { formValueSelector } from 'redux-form/immutable'
import { defaultMemoize } from 'reselect'
import { BATCH_INVITE_TO_COMMUNITIES } from '../../../Communities/actionTypes'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form/immutable'
import RootState from 'shared/models/RootState'

const formName = 'buyer/batchInviteSupplierCommunities'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)
const selector = formValueSelector(formName)

type Props = {
  open: boolean
  supplierIds: string[]
  onCloseInviteDialog: () => void
}

const getInitialValues = defaultMemoize(supplierIds => ({
  supplierIds
}))

export default connect(
  (state: RootState, props: Props) => {
    const communities = selector(state, 'communities')
    return {
      initialValues: getInitialValues(props.supplierIds),
      hasSelected:
        !!communities &&
        communities
          .valueSeq()
          .toJS()
          .some(c => c === true),
      errorMessage: formSubmitErrorsSelector(state),
      availableCommunities: communitiesSelectors.getCommunitiesLessTier2(state)
    }
  },
  {
    inviteToCommunities: createSubmitFormAction(
      formName,
      BATCH_INVITE_TO_COMMUNITIES
    )
  }
)(
  reduxForm({
    form: formName,
    enableReinitialize: true
  })(InviteToCommunitiesDialog)
)
