import React, { FunctionComponent } from 'react'
import { RecordOf, List } from 'immutable'
import Grid from '@material-ui/core/Grid'
import CompanyLocation, {
  CompanyLocationProps
} from 'shared/components/CompanyLocation'

type Props = {
  publicView?: boolean
  locations: List<RecordOf<CompanyLocationProps>>
}
const LocationsGrid: FunctionComponent<Props> = props => {
  return (
    <Grid container className='pa2'>
      {props.locations.map((location, index) => (
        <Grid key={index} item xs={12} sm={6} className='pa3'>
          <CompanyLocation
            address={location.get('address')}
            description={location.get('description')}
            phone={location.get('phone')}
            name={location.get('name')}
            poBox={location.get('poBox')}
            houseNumber={location.get('houseNumber')}
            level={location.get('level')}
            unit={location.get('unit')}
            road={location.get('road')}
            suburb={location.get('suburb')}
            city={location.get('city')}
            stateDistrict={location.get('stateDistrict')}
            state={location.get('state')}
            postalCode={location.get('postalCode')}
            country={location.get('country')}
            isPrimary={location.get('isPrimary')}
            lat={location.get('lat')}
            long={location.get('long')}
            publicView={props.publicView}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default LocationsGrid
