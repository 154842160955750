import React, { useState } from 'react'
import { List, RecordOf, Map } from 'immutable'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles
} from '@material-ui/core'
import IntegratorRow from './IntegratorRow'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
//import BatchAssessmentContainer from 'admin/containers/BatchAssessmentContainer'

const messages = defineMessages({
  errorCredentials: {
    id: 'AppConfigIntegrations.errorCredentials',
    defaultMessage: 'Invalid username or password. Please try again.'
  }
})

export const CustomTableCell = withStyles({
  root: {
    borderBottom: 'none'
  }
})(TableCell)

export type Credentials = {
  username: string
  password: string
}
export type Integrations = {
  reportEnabled: boolean
  credentialsSubmitted?: boolean
  initialAssessmentLink?: Date
  username?: string
}
type Props = {
  currentIntegrator: string
  errorCredentials: boolean
  isLoadingSupplierList: boolean
  integrations: Map<string, RecordOf<Integrations>>
  linkBuyerWithIntegration: (params: {
    username: string
    password: string
    integrator: string
    initialAssessmentLink?: Date
  }) => void
  isConcierge: boolean
  batchAssessmentSupplierErrors: List<
    RecordOf<{
      supplier: string
      name: string
      domains: string[]
      error: string
    }>
  >
}
const AppConfigIntegrations = ({
  currentIntegrator,
  errorCredentials,
  isLoadingSupplierList,
  integrations,
  batchAssessmentSupplierErrors,
  isConcierge,
  linkBuyerWithIntegration
}: Props) => {
  const intl = useIntl()

  const [credentials, setCredentials] = useState<{
    [integrator: string]: Credentials
  }>({ '': { username: '', password: '' } })

  const showPasswordLabel = integrations.find(
    cred => cred.get('reportEnabled') && !cred.get('credentialsSubmitted')
  )
  const showAllLabels = integrations.find(cred => cred.get('reportEnabled'))

  const handleLinkIntegration = (integrator: string) => {
    const linkingPayload = {
      username: credentials[integrator].username,
      password: credentials[integrator].password,
      integrator,
      initialAssessmentLink: integrations.getIn([
        integrator,
        'initialAssessmentLink'
      ])
    }
    //call ecovadis integration api to verify credentials
    linkBuyerWithIntegration(linkingPayload)
  }

  return (
    <>
      <Table size='small'>
        <TableBody>
          <TableRow>
            <CustomTableCell>
              <div className='pv2'>
                <FormattedMessage
                  id='AppConfigIntegrations.Integration'
                  defaultMessage='Integration'
                />
              </div>
            </CustomTableCell>
            {showAllLabels && (
              <>
                <CustomTableCell>
                  <div className='pv2'>
                    <FormattedMessage
                      id='AppConfigIntegrations.Username'
                      defaultMessage='Username'
                    />
                  </div>
                </CustomTableCell>
                {showPasswordLabel ? (
                  <>
                    <CustomTableCell>
                      <div className='pv2'>
                        <FormattedMessage
                          id='AppConfigIntegrations.Password'
                          defaultMessage='Password'
                        />
                      </div>
                    </CustomTableCell>
                    <CustomTableCell>
                      <div className='pv2'>
                        <FormattedMessage
                          id='AppConfigIntegrations.LinkAccount'
                          defaultMessage='Link Account'
                        />
                      </div>
                    </CustomTableCell>
                  </>
                ) : (
                  <>
                    <CustomTableCell>
                      <div className='pv2'></div>
                    </CustomTableCell>
                    <CustomTableCell>
                      <div className='pv2'></div>
                    </CustomTableCell>
                  </>
                )}
                <CustomTableCell>
                  <div className='pv2'>
                    <FormattedMessage
                      id='AppConfigIntegrations.Status'
                      defaultMessage='Status'
                    />
                  </div>
                </CustomTableCell>
              </>
            )}
          </TableRow>
          {integrations.entrySeq().map(([integrator, integrationConfig]) => {
            return (
              <IntegratorRow
                key={integrator}
                integrator={integrator}
                credentials={credentials}
                onChangeCredentials={setCredentials}
                onHandleLinkIntegration={handleLinkIntegration}
                isLoadingSupplierList={isLoadingSupplierList}
                batchAssessmentSupplierErrors={batchAssessmentSupplierErrors}
                currentIntegrator={currentIntegrator}
                errorCredentials={
                  errorCredentials
                    ? intl.formatMessage(messages.errorCredentials)
                    : undefined
                }
                integrationConfig={integrationConfig}
                isConcierge={isConcierge}
              />
            )
          })}
        </TableBody>
      </Table>
      {/* Phase 2
      <BatchAssessmentContainer /> */}
    </>
  )
}

export default AppConfigIntegrations
