import { connect } from 'react-redux'
import searchSelectors from '../../../Search/selectors/searchSelectors'
import BuyerAppBar from '../../components/BuyerAppBar'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import qs from 'qs'
import RootState from 'shared/models/RootState'

export default connect((state: RootState) => {
  const defaultFilters = settingsSelectors.getDefaultFilters(state)
  const refQueryString:
    | string
    | undefined = searchSelectors.getQueryStringByRef(state, 'buyerSearch')
  const queryString: string = refQueryString
    ? refQueryString
    : defaultFilters.size > 0
    ? `?${qs.stringify({ filter: defaultFilters.toJS() })}`
    : ''
  const peopleQueryString: string = state.getIn([
    'buyer',
    'contactsSearch',
    'lastQuery'
  ])

  return {
    accessControl: sessionSelectors.getAccessControl(state),
    queryString,
    peopleQueryString
  }
})(BuyerAppBar)
