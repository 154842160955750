import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import Text from 'shared/components/Text'
import relationshipsSelectors from '../../../shared/selectors/relationshipsSelectors'
import { List, RecordOf } from 'immutable'

const styles = {
  attributeWrapper: 'mb2 f6',
  label: 'fw6 dark-gray pr3 w-100 w-25-ns dib v-top',
  value: 'w-100 w-70-ns dib'
}

type Props = {
  customAttributes: List<RecordOf<{ label: string; value: string }>>
}
export class CustomAttributes extends Component<Props> {
  formatValue(value) {
    if (typeof value === 'number') {
      return value && value.toLocaleString()
    } else {
      return value
    }
  }

  render() {
    const { customAttributes } = this.props

    return customAttributes && customAttributes.size > 0 ? (
      <div className='mt3-5'>
        {customAttributes &&
          customAttributes.map((attr, index) => (
            <div className={styles.attributeWrapper} key={index}>
              <Text>
                <span className={styles.label}>{attr.get('label')}</span>
                <span className={styles.value}>
                  {this.formatValue(attr.get('value'))}
                </span>
              </Text>
            </div>
          ))}
      </div>
    ) : null
  }
}

type ContainerProps = RouteComponentProps<{ supplierId: string }>

export default withRouter(
  connect((state, props: ContainerProps) => ({
    customAttributes: relationshipsSelectors.getCustomAttributes(
      state,
      props.match.params.supplierId
    )
  }))(CustomAttributes)
)
