import { connect } from 'react-redux'
import ConversationListItem from '../../components/ConversationListItem'
import usersSelectors from 'shared/selectors/usersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import RootState from 'shared/models/RootState'

type ContainerProps = {
  userId: string
  lastMessageBy: string
}

export default connect((state: RootState, props: ContainerProps) => {
  const user = usersSelectors.getById(state, props.userId)
  const lastMessageMine =
    sessionSelectors.getUserId(state) === props.lastMessageBy
  const orgUnit = user && orgsSelectors.getById(state, user.get('orgUnitId'))

  return {
    firstName: user.get('firstName'),
    lastName: user.get('lastName'),
    email: user.get('email'),
    title: user.get('title'),
    profilePictureUrl: user.get('profilePictureUrl'),
    blocked: user.get('blocked'),
    lastMessageMine,
    orgName: orgUnit && orgUnit.get('name')
  }
})(ConversationListItem)
