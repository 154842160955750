import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import { ReactComponent as TealbotSupportIcon } from './tealbot-support.svg'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'

type Props = {
  onClick: () => void
  title?: string | ReactElement<FormattedMessage>
}

const TealbotSupport = (props: Props) => {
  const hideChat = useSelector((state: RootState) =>
    settingsSelectors.getField(state, 'hideChat')
  )

  return hideChat ? null : (
    <button
      id='tealbotSupport'
      className='bg-inherit bn dib pointer fixed right-1 bottom-1 flex flex-column items-center hover-swing-child z-4'
      onClick={props.onClick}
    >
      <div className='dib pl2 w3 h3 bg-white pa1 br-pill shadow-2 swing position-absolute'>
        <TealbotSupportIcon />
      </div>
      {props.title && (
        <div className='dib br-pill pv1 ph2 mt1 f7 bg-white shadow-2'>
          {props.title}
        </div>
      )}
    </button>
  )
}

export default TealbotSupport
