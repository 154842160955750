import React from 'react'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import PageSection from 'shared/components/PageSection'
import Page from 'shared/components/Page'
import { List, Map } from 'immutable'

type Props = {
  orgUnitClientFiles: List<Map<string, string>>
}

const FilesHistory = ({ orgUnitClientFiles }: Props) => {
  const getRow = ({ index }) => {
    return orgUnitClientFiles && orgUnitClientFiles.get(index)
  }

  return (
    <Page title='Upload History'>
      <PageSection
        noPadding
        title={
          <div className='tr'>
            <span>Upload History</span>
          </div>
        }
      >
        {orgUnitClientFiles.size > 0 && (
          <Table
            minWidth={690}
            rowGetter={getRow}
            rowCount={orgUnitClientFiles.size}
            rowClassName='pointer dim'
          >
            <Column label='File Name' dataKey='fileName' width={220} />
            <Column
              label='Upload Date'
              dataKey='date'
              width={150}
              cellRenderer={({ cellData }: TableCellProps) =>
                cellData.slice(0, 10)
              }
            />
            <Column label='Uploaded By' dataKey='email' width={200} />
          </Table>
        )}
      </PageSection>
    </Page>
  )
}

export default FilesHistory
