import React, { ReactNode } from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell, Label } from 'recharts'

type Props = {
  label?: ReactNode
  percentage: number
  value?: string | number
}

const ProgressPie = ({ percentage = 0, label, value }: Props) => {
  const color = 'var(--main)'
  const backgroundColor = 'var(--light-gray)'
  const degree = 90 - percentage * 3.6

  return (
    <div className='w-100 h-100'>
      {label && <div className='f7 fw6 gray tc mb1'>{label}</div>}
      <ResponsiveContainer aspect={1} maxHeight={200}>
        <PieChart>
          <Pie
            data={[{ percentage }]}
            dataKey='percentage'
            innerRadius='85%'
            outerRadius='100%'
            startAngle={90}
            endAngle={-270}
            isAnimationActive={false}
          >
            <Cell fill={backgroundColor} />
            <Label
              position='center'
              content={
                <text
                  x='50%'
                  y='50%'
                  dy={10}
                  fontSize={24}
                  fill={color}
                  textAnchor='middle'
                >
                  {value || `${percentage}%`}
                </text>
              }
            />
          </Pie>
          <Pie
            data={[{ percentage }]}
            dataKey='percentage'
            innerRadius='85%'
            outerRadius='100%'
            startAngle={90}
            endAngle={degree}
          >
            <Cell fill={color} />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ProgressPie
