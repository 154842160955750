import React, { Component } from 'react'
import AddUserForm, { AddUserFormProps } from 'shared/components/AddUserForm'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import { Field } from 'redux-form/immutable'
import { FormattedMessage } from 'react-intl'

type Props = AddUserFormProps

class AddUserTeamForm extends Component<Props> {
  render() {
    const { creatingNewUser, domains } = this.props
    return (
      <AddUserForm
        {...this.props}
        userLookupLabel={
          <FormattedMessage
            id='AddUSerTeam.AddATeamMemberService'
            defaultMessage='Add a team member or service account. ie. marketing@ {domain}'
            values={{ domain: !!domains.size && domains.first() }}
          />
        }
      >
        {creatingNewUser && (
          <div>
            <Label>
              <FormattedMessage
                id='AddUserTeamForm.MobilePhoneLabel'
                defaultMessage='Mobile Phone'
              />
            </Label>
            <Field component={Input} name='user[mobilePhone]' />
            <Label>
              <FormattedMessage
                id='AddUserTeamForm.OfficePhoneLabel'
                defaultMessage='Office Phone'
              />
            </Label>
            <Field component={Input} name='user[officePhone]' />
            <Label>
              <FormattedMessage
                id='AddUserTeamForm.LocationLabel'
                defaultMessage='Location'
              />
            </Label>
            <Field component={Input} name='user[location]' />
          </div>
        )}
        <Label>
          <FormattedMessage
            id='AddUserTeamForm.WhyWouldThisPersonBeContactedLabel'
            defaultMessage='Why would this person be contacted?'
          />
        </Label>
        <Field component={Input} name='contactFor' />
      </AddUserForm>
    )
  }
}

export default AddUserTeamForm
