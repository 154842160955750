import { call, select } from 'redux-saga/effects'
import users from 'shared/utils/api/users'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import mapKeys from 'lodash.mapkeys'
import qs from 'qs'

export function* searchContactsSaga(action) {
  const { cq, agg = true, scope, filter, ...rest } = qs.parse(action.payload, {
    ignoreQueryPrefix: true
  })

  let userHasNoContacts = yield select(state =>
    state.getIn(['buyer', 'contactsSearch', 'userHasNoContacts'])
  )
  if (userHasNoContacts === undefined) {
    const myConnectionsResult = yield call(users.search, {
      scope: 'MyConnections',
      agg
    })

    userHasNoContacts = myConnectionsResult.users.length === 0
  }

  const result = yield call(users.search, {
    ...rest,
    q: cq,
    scope: userHasNoContacts ? '' : scope,
    agg,
    filter
  })

  const resultsById = mapKeys(result.users, user => user.id)

  return yield {
    numberOfResults: result.total.value,
    resultIds: result.users.map(({ id }) => id),
    resultsById,
    resultFilters: result.aggregations,
    userHasNoContacts
  }
}

export default createApiRequestSaga(searchContactsSaga)
