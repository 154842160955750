import React, { ComponentType, memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import orgsSelectors from '../../selectors/orgsSelectors'

type Props = {
  orgUnitId: string
}

type InjectedProps = {
  name: string
  website: string
  logoUrl: string
}

const withOrg = <T extends {}>(Component: ComponentType<T>) =>
  memo((props: Props & Omit<T, keyof InjectedProps>) => {
    const orgUnit = useSelector(state =>
      orgsSelectors.getById(state, props.orgUnitId)
    )
    const orgUnitValues = useMemo(() => {
      const domains = orgUnit && orgUnit.get('domains')
      return {
        name: orgUnit.get('name'),
        websiteUrl: orgUnit.get('websiteUrl'),
        logoUrl:
          orgUnit.get('logoUrl') ||
          (domains && domains.size > 0
            ? `https://logo.clearbit.com/${domains.first()}`
            : '')
      }
    }, [orgUnit])
    return <Component {...((orgUnitValues as unknown) as T)} {...props} />
  })

export default withOrg
