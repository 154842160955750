import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import createSubmitSaga from 'shared/utils/sagas/createSubmitSaga'
import auth from 'shared/utils/api/auth'
import paths from 'accounts/routes/paths'
import Immutable from 'immutable'

export function* resetPasswordSaga(action) {
  const { email, orgUnitId, userId } = Immutable.Iterable.isIterable(
    action.payload
  )
    ? action.payload.toJS()
    : action.payload
  yield call(auth.resetPassword, { email, orgUnitId, userId })
  yield put(push(`${paths.resetPasswordComplete}/${email}`))
}

export default createSubmitSaga(resetPasswordSaga)
