import { Record, fromJS } from 'immutable'
import fullNameParser from 'shared/utils/data/fullName/parser'

import {
  SELECT_NEW_USER_FOR_CONTACT,
  SELECT_USER_FOR_CONTACT,
  ADD_CONTACT,
  CANCEL_ADDING_CONTACT
} from '../../actionTypes'
import { requestSuccess } from 'shared/actionTypes'

const NewContactRecord = Record({
  selectedUser: fromJS({}),
  isAddingContact: false
})

const newContactReducer = (state = new NewContactRecord(), action) => {
  switch (action.type) {
    case SELECT_USER_FOR_CONTACT:
      return state.merge({
        selectedUser: action.payload,
        isAddingContact: true
      })
    case SELECT_NEW_USER_FOR_CONTACT:
      const { ...userInfo } = fullNameParser(action.payload)

      return action.payload
        ? state.merge({
            selectedUser: fromJS({ ...userInfo }),
            isAddingContact: true
          })
        : state.merge({
            isAddingContact: true
          })
    case CANCEL_ADDING_CONTACT:
    case requestSuccess(ADD_CONTACT):
      return new NewContactRecord()
    default:
      return state
  }
}

export default newContactReducer
