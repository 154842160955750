import React from 'react'
import { Route, RouteProps, Redirect, useLocation } from 'react-router'
import accountsPaths from 'accounts/routes/paths'
import { useSelector } from 'react-redux'
import sessionSelectors from '../../selectors/sessionSelectors'
import RootState from '../../models/RootState'

type Props = {
  mustHaveAccessToApp:
    | 'buyer'
    | 'supplier'
    | 'data-portal'
    | 'click-worker'
    | 'admin'
} & Pick<RouteProps, 'path' | 'children'>

const PrivateRoute = ({ mustHaveAccessToApp, children, ...rest }: Props) => {
  const userIsLoggedIn = useSelector(sessionSelectors.isLoggedIn)
  const userHasAccessToApp = useSelector((state: RootState) =>
    sessionSelectors.userHasAccessToApp(state, mustHaveAccessToApp)
  )
  const location = useLocation()
  const requestUrl =
    location.pathname !== '/' &&
    location.pathname !== accountsPaths.login &&
    `${location.pathname}${location.search}`

  return (
    <Route {...rest}>
      {!userIsLoggedIn ? (
        <Redirect
          to={`${accountsPaths.login}${requestUrl &&
            `?requestUrl=${requestUrl}`}`}
        />
      ) : !userHasAccessToApp ? (
        <Redirect to='/' />
      ) : (
        children
      )}
    </Route>
  )
}

export default PrivateRoute
