import { call } from 'redux-saga/effects'
import messageTopics from '../../utils/api/messageTopics'
import createApiRequestSaga from '../../utils/sagas/createApiRequestSaga'
import mapValues from 'lodash.mapvalues'
import mapKeys from 'lodash.mapkeys'
import parseUser from '../../utils/data/parseUser'
import parseSupplier from '../../utils/data/parseSupplier'

export function* loadMessageTopicsSaga(action) {
  const { userInfo } = action.payload
  const currentUserId = userInfo.id

  const topicsById: Array<{
    id: string
    created: {
      user: string
      date: string
    }
    users: {
      [key: string]: {
        closed: boolean
      }
    }
    snippet: string
    lastWrite?: {
      user: string
      note: string
      date: string
    }
    expanded: any
  }> = yield call(messageTopics.getAll)

  return yield {
    topicsById: mapValues(topicsById, ({ id, created, users, lastWrite }) => ({
      id,
      createdDate: new Date(created.date),
      createdBy: created.user,
      conversationWithUser: Object.keys(users).filter(
        userId => userId !== currentUserId
      )[0],
      closed: users[currentUserId].closed,
      numUnreadMessages: users[currentUserId].numUnreadMessages,
      lastMessage: lastWrite && lastWrite.note,
      lastMessageBy: lastWrite && lastWrite.user,
      lastMessageDate: lastWrite && lastWrite.date && new Date(lastWrite.date)
    })),
    topicsByUser: mapValues(
      mapKeys(topicsById, topic => {
        return Object.keys(topic.users).filter(
          userId => userId !== currentUserId
        )[0]
      }),
      topic => topic.id
    ),
    usersWithConversation: Object.values(topicsById).reduce(
      (userIds, topic) =>
        Object.assign(userIds, mapValues(topic.expanded.Users, parseUser)),
      {}
    ),
    orgUnitsById: Object.values(topicsById).reduce(
      (orgUnitsById, topic) =>
        Object.assign(
          orgUnitsById,
          mapValues(topic.expanded.OrgUnit, parseSupplier)
        ),
      {}
    )
  }
}

export default createApiRequestSaga(loadMessageTopicsSaga)
