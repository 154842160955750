import { connect } from 'react-redux'
import MyAccountUserNavItem from '../../components/UserNav/MyAccountUserNavItem'
import sessionSelectors from '../../selectors/sessionSelectors'
import routingSelectors from '../../selectors/routingSelectors'
import supplierPaths from 'supplier/routes/paths'
import buyerPaths from 'buyer/routes/paths'
import RootState from 'shared/models/RootState'

export default connect(
  (state: RootState) => ({
    userId: sessionSelectors.getUserId(state),
    baseUrl: routingSelectors.isClientApp(state)
      ? buyerPaths.basePath
      : supplierPaths.basePath
  }),
  undefined,
  undefined,
  {
    forwardRef: true
  }
)(MyAccountUserNavItem)
