import React, { ReactElement, ChangeEvent, useState, useCallback } from 'react'
import Input from 'shared/components/Input'
import Text from 'shared/components/Text'
import Checkbox from '@material-ui/core/Checkbox'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettings } from '../../actions'
import RootState from 'shared/models/RootState'
import { useDebouncedCallback } from 'use-debounce'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import { RecordOf } from 'immutable'
import withStyles from '@material-ui/styles/withStyles'
import Label from 'shared/components/Label'
import { notify } from 'shared/actions'

const messages = defineMessages({
  invalidMin: {
    id: 'PasswordRulesSetting.InvalidMin',
    defaultMessage: 'Invalid Rule (Minimum Characters > Maximum Characters)'
  },
  invalidSum: {
    id: 'PasswordRulesSetting.InvalidSum',
    defaultMessage: 'Invalid Rule (Sum of Minimum Rules > Maximum Characters)'
  }
})

const StyleCheckbox = withStyles({
  root: {
    padding: 3
  }
})(Checkbox)

export type PasswordRules = {
  alphaLowerCount: number
  alphaUpperCount: number
  max: number
  min: number
  numericCount: number
  specialCount: number
  previousUniqueCount: number
  blockCount: number
  checkCommon: boolean
  user: boolean
}

type Props = {
  fieldName?: string
  label?: string | ReactElement<FormattedMessage>
}

const PasswordRulesSetting = (props: Props) => {
  const { fieldName = 'passwordRules', label } = props
  const dispatch = useDispatch()
  const intl = useIntl()
  const isReadonly = useSelector(
    (state: RootState) => !!state.get('session').get('partnerConcierge')
  )
  const passwordRules: RecordOf<PasswordRules> = useSelector(
    (state: RootState) => settingsSelectors.getSetting(state, fieldName)
  )
  const [editPasswordRules, setEditPasswordRules] = useState<PasswordRules>(
    () => {
      return Object.assign(
        {
          min: 8,
          max: 12,
          alphaUpperCount: 1,
          alphaLowerCount: 1,
          numericCount: 1,
          specialCount: 0,
          previousUniqueCount: 1,
          checkCommon: true,
          user: false,
          blockCount: 5
        },
        passwordRules?.toJS()
      )
    }
  )

  const debouncedUpdateSettings = useDebouncedCallback((settings: any) => {
    dispatch(updateSettings(settings))
  }, 200)

  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.currentTarget
      const newPasswordRules = Object.assign({}, editPasswordRules, {
        [name]: checked
      })

      setEditPasswordRules(newPasswordRules)
      debouncedUpdateSettings.callback({
        [fieldName]: newPasswordRules
      })
    },
    [debouncedUpdateSettings, editPasswordRules, fieldName]
  )

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.currentTarget
      const newPasswordRules = Object.assign({}, editPasswordRules, {
        [name]: Number(value)
      })

      const {
        min,
        max,
        alphaLowerCount,
        alphaUpperCount,
        numericCount,
        specialCount
      } = newPasswordRules
      if (min > max) {
        dispatch(notify({ message: intl.formatMessage(messages.invalidMin) }))
      } else if (
        alphaLowerCount + alphaUpperCount + numericCount + specialCount >
        max
      ) {
        dispatch(notify({ message: intl.formatMessage(messages.invalidSum) }))
      } else {
        setEditPasswordRules(newPasswordRules)
        debouncedUpdateSettings.callback({
          [fieldName]: newPasswordRules
        })
      }
    },
    [debouncedUpdateSettings, editPasswordRules, intl, dispatch, fieldName]
  )

  return (
    <>
      <Label noPadding>
        {label || (
          <FormattedMessage
            id='PasswordRulesSetting.PasswordRules'
            defaultMessage='Password Rules'
          />
        )}
      </Label>
      <div className='bg-white bt br bl b--black-10 f7 pa2 flex items-center justify-between'>
        <Text className='w5'>
          <FormattedMessage
            id='PasswordRulesSetting.Min'
            defaultMessage='Minimum Number of Characters'
          />
        </Text>
        <Input
          wrapperClassName='w3'
          name='min'
          type='number'
          value={editPasswordRules?.min}
          onChange={handleInputChange}
          readOnly={isReadonly}
        />
      </div>
      <div className='bg-white bt br bl b--black-10 f7 pa2 flex items-center justify-between'>
        <Text className='w5'>
          <FormattedMessage
            id='PasswordRulesSetting.Max'
            defaultMessage='Maximum Number of Characters'
          />
        </Text>
        <Input
          wrapperClassName='w3'
          name='max'
          type='number'
          value={editPasswordRules?.max}
          onChange={handleInputChange}
          readOnly={isReadonly}
        />
      </div>
      <div className='bg-white bt br bl b--black-10 f7 pa2 flex items-center justify-between'>
        <Text className='w5'>
          <FormattedMessage
            id='PasswordRulesSetting.LowercaseCount'
            defaultMessage='Minimum Number of Lowercase Characters'
          />
        </Text>
        <Input
          wrapperClassName='w3'
          name='alphaLowerCount'
          type='number'
          value={editPasswordRules?.alphaLowerCount}
          onChange={handleInputChange}
          readOnly={isReadonly}
        />
      </div>
      <div className='bg-white bt br bl b--black-10 f7 pa2 flex items-center justify-between'>
        <Text className='w5'>
          <FormattedMessage
            id='PasswordRulesSetting.UppercaseCount'
            defaultMessage='Minimum Number of Uppercase Characters'
          />
        </Text>
        <Input
          wrapperClassName='w3'
          name='alphaUpperCount'
          type='number'
          value={editPasswordRules?.alphaUpperCount}
          onChange={handleInputChange}
          readOnly={isReadonly}
        />
      </div>
      <div className='bg-white bt br bl b--black-10 f7 pa2 flex items-center justify-between'>
        <Text className='w5'>
          <FormattedMessage
            id='PasswordRulesSetting.NumericCount'
            defaultMessage='Minimum Number of Numeric Characters'
          />
        </Text>
        <Input
          wrapperClassName='w3'
          name='numericCount'
          type='number'
          value={editPasswordRules?.numericCount}
          onChange={handleInputChange}
          readOnly={isReadonly}
        />
      </div>
      <div className='bg-white bt br bl b--black-10 f7 pa2 flex items-center justify-between'>
        <Text className='w5'>
          <FormattedMessage
            id='PasswordRulesSetting.SpecialCount'
            defaultMessage='Minimum Number of Special Characters'
          />
        </Text>
        <Input
          wrapperClassName='w3'
          name='specialCount'
          type='number'
          value={editPasswordRules?.specialCount}
          onChange={handleInputChange}
          readOnly={isReadonly}
        />
      </div>
      <div className='bg-white bt br bl b--black-10 f7 pa2 flex items-center justify-between'>
        <Text className='w5'>
          <FormattedMessage
            id='PasswordRulesSetting.PreviousUniqueCount'
            defaultMessage='Number of Previous Unique Count'
          />
        </Text>
        <Input
          wrapperClassName='w3'
          name='previousUniqueCount'
          type='number'
          value={editPasswordRules?.previousUniqueCount}
          onChange={handleInputChange}
          readOnly={isReadonly}
        />
      </div>
      <div className='bg-white bt br bl b--black-10 f7 pa2 flex items-center justify-between'>
        <Text className='w5'>
          <FormattedMessage
            id='PasswordRulesSetting.BlockCount'
            defaultMessage='Number of Block Count'
          />
        </Text>
        <Input
          wrapperClassName='w3'
          name='blockCount'
          type='number'
          value={editPasswordRules?.blockCount}
          onChange={handleInputChange}
          readOnly={isReadonly}
        />
      </div>
      <div className='bg-white ba b--black-10 f7 pa2 flex items-center justify-between'>
        <Text className='w5'>
          <FormattedMessage
            id='PasswordRulesSetting.CheckCommon'
            defaultMessage='Check Common'
          />
        </Text>
        <StyleCheckbox
          name='checkCommon'
          size='small'
          color='primary'
          checked={editPasswordRules?.checkCommon}
          onChange={handleCheckboxChange}
          readOnly={isReadonly}
        />
      </div>
    </>
  )
}

export default PasswordRulesSetting
