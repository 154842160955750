import { call } from 'redux-saga/effects'
import createApiRequestSaga from '../../utils/sagas/createApiRequestSaga'
import api from '../../utils/api'
import apiRoutes from '../../routes/apiRoutes'
import mapKeys from 'lodash.mapkeys'
import { fromJS } from 'immutable'
import extractExpandedEntities from '../../utils/data/extractExpandedEntities'

const defaultParser = data => mapKeys(data.map(cleanData), ({ id }) => id)

export function* loadDataSaga(action) {
  const { entity, query, resultParser } = action.payload

  const endpointUrl = query || entity
  const data = yield call(api.get, `${apiRoutes.authService}/${endpointUrl}`)
  const otherEntities = extractExpandedEntities(data)

  const parsedData = resultParser ? resultParser(data) : defaultParser(data)

  return yield {
    entity,
    data: fromJS({
      [entity]: parsedData,
      ...otherEntities
    })
  }
}

function cleanData({ expanded, ...rest }) {
  return rest
}

export default createApiRequestSaga(loadDataSaga, {
  notifyOnError: true
})
