import { fromJS } from 'immutable'
import { requestSuccess, LOAD_USER_METRICS, LOGOUT } from 'shared/actionTypes'

/**
 *
 * userMetrics: {
 *   tags { tag: count },
 *   suppliers: [
 *     {
 *       id,
 *       cardId,
 *       cardCreated,
 *       tagCount
 *     }
 *   ]
 * }
 */

const userMetricsReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case requestSuccess(LOAD_USER_METRICS):
      return action.payload ? fromJS(action.payload) : state

    case LOAD_USER_METRICS:
    case LOGOUT:
      return fromJS({})
    default:
      return state
  }
}

export default userMetricsReducer
