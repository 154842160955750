import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import communitiesPaths from '../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import communities from 'shared/utils/api/community'

export function* submitCommunityFormResponseSaga(action) {
  const { membershipId, communityId } = action.payload

  // submit the response
  yield call(communities.updateMembership, {
    membershipId,
    membership: {
      response: {
        status: 'submitted'
      }
    }
  })

  yield put(
    push(
      parsePath(communitiesPaths.finishCommunityForm, {
        communityId
      })
    )
  )

  return {
    membershipId
  }
}

export default createApiRequestSaga(submitCommunityFormResponseSaga)
