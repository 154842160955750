import { createSelector } from 'reselect'
import { fromJS, Map } from 'immutable'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import RootState from 'shared/models/RootState'
import SupplierState from 'shared/models/SupplierState'

export const getSupplierRelationshipForCurrentSupplier = createSelector(
  sessionSelectors.getOrgUnitId,
  (state: RootState) =>
    state.getIn(['buyer', 'supplierRelationships', 'byId']) || fromJS({}),
  (buyerId, relsById) =>
    relsById.toList().find(rel => rel.get('buyer') === buyerId)
)

export const getClients = createSelector(
  (state: RootState) =>
    state.getIn(['buyer', 'supplierRelationships', 'byId']) || fromJS({}),
  orgsSelectors.getOrgs,
  (relsById, orgs: Map<string, SupplierState>) =>
    relsById
      .toList()
      .filter(rel => !!rel.getIn(['supplierCollaboration', 'isClient']))
      .sort((relA, relB) => {
        const buyerA = (orgs && orgs.getIn([relA.get('buyer'), 'name'])) || ''
        const buyerB = (orgs && orgs.getIn([relB.get('buyer'), 'name'])) || ''
        return buyerA.toUpperCase() > buyerB.toUpperCase() ? 1 : -1
      })
)

export const getCertificationValidation = (state: RootState) => {
  const relationship = getSupplierRelationshipForCurrentSupplier(state)
  return (
    relationship &&
    relationship.getIn(['buyerCollaboration', 'certificationValidation'])
  )
}

export const getCertificationValidationsByKey = createSelector(
  (state: RootState) =>
    state.getIn(['buyer', 'supplierRelationships', 'byId']) || fromJS({}),
  (state: RootState, key: string) => key,
  (relsById, key: string) => {
    return relsById
      .toList()
      .filter(
        rel =>
          !!rel.getIn([
            'buyerCollaboration',
            'certificationValidation',
            key,
            '0'
          ])
      )
      .map(rel => {
        const buyer = rel.get('buyer')
        return fromJS({
          confirmed: rel.getIn([
            'buyerCollaboration',
            'certificationValidation',
            key,
            '0',
            'confirmed'
          ]),
          date: rel.getIn([
            'buyerCollaboration',
            'certificationValidation',
            key,
            '0',
            'date'
          ]),
          validationExpires: rel.getIn([
            'buyerCollaboration',
            'certificationValidation',
            key,
            '0',
            'validationExpires'
          ]),
          companyName:
            rel.get('buyerName') ||
            rel.getIn(['expanded', 'OrgUnit', buyer, 'name'])
        })
      })
      .sort((v1, v2) => {
        return new Date(v2.get('date')) < new Date(v1.get('date')) ? -1 : 1
      })
  }
)
