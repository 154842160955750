const naicsStd = {
  '111110': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111120': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111130': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111140': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111150': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111160': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111191': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111199': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111211': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111219': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111310': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111320': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111331': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111332': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111333': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111334': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111335': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111336': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111339': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111411': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111419': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111421': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111422': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111910': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111920': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111930': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111940': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111991': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111992': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '111998': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112111': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112112': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '112120': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112210': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112310': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '112320': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112330': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112340': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112390': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112410': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112420': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112511': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112512': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112519': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112910': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112920': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112930': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '112990': {
    revenue: '0.75',
    employees: '0',
    footnote: '0'
  },
  '113110': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '113210': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '113310': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '114111': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '114112': {
    revenue: '5.5',
    employees: '0',
    footnote: '0'
  },
  '114119': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '114210': {
    revenue: '5.5',
    employees: '0',
    footnote: '0'
  },
  '115111': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '115112': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '115113': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '115114': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '115115': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '115116': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '115210': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '115310': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '211120': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '211130': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '212111': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '212112': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '212113': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '212210': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '212221': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '212222': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '212230': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '212291': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '212299': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '212311': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '212312': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '212313': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '212319': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '212321': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '212322': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '212324': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '212325': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '212391': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '212392': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '212393': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '212399': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '213111': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '213112': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '213113': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '213114': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '213115': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '221111': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '221112': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '221113': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '221114': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '221115': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '221116': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '221117': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '221118': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '221121': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '221122': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '221210': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '221310': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '221320': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '221330': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '236115': {
    revenue: '36.5',
    employees: '0',
    footnote: '0'
  },
  '236116': {
    revenue: '36.5',
    employees: '0',
    footnote: '0'
  },
  '236117': {
    revenue: '36.5',
    employees: '0',
    footnote: '0'
  },
  '236118': {
    revenue: '36.5',
    employees: '0',
    footnote: '0'
  },
  '236210': {
    revenue: '36.5',
    employees: '0',
    footnote: '0'
  },
  '236220': {
    revenue: '36.5',
    employees: '0',
    footnote: '0'
  },
  '237110': {
    revenue: '36.5',
    employees: '0',
    footnote: '0'
  },
  '237120': {
    revenue: '36.5',
    employees: '0',
    footnote: '0'
  },
  '237130': {
    revenue: '36.5',
    employees: '0',
    footnote: '0'
  },
  '237210': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '237310': {
    revenue: '36.5',
    employees: '0',
    footnote: '0'
  },
  '237990': {
    revenue: '36.5',
    employees: '0',
    footnote: '0'
  },
  '238110': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238120': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238130': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238140': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238150': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238160': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238170': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238190': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238210': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238220': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238290': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238310': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238320': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238330': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238340': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238350': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238390': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238910': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '238990': {
    revenue: '15',
    employees: '0',
    footnote: '13'
  },
  '311111': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311119': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '311211': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311212': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '311213': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '311221': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '311224': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311225': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311230': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311313': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '311314': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311340': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311351': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '311352': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311411': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311412': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '311421': {
    revenue: '0',
    employees: '1000',
    footnote: '3'
  },
  '311422': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '311423': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '311511': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311512': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '311513': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '311514': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '311520': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311611': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311612': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311613': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '311615': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '311710': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '311811': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '311812': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311813': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '311821': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '311824': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '311830': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '311911': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '311919': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '311920': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '311930': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '311941': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '311942': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '311991': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '311999': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '312111': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '312112': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '312113': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '312120': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '312130': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '312140': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '312230': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '313110': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '313210': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '313220': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '313230': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '313240': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '313310': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '313320': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '314110': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '314120': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '314910': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '314994': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '314999': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '315110': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '315190': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '315210': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '315220': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '315240': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '315280': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '315990': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '316110': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '316210': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '316992': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '316998': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '321113': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '321114': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '321211': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '321212': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '321213': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '321214': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '321219': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '321911': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '321912': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '321918': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '321920': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '321991': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '321992': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '321999': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '322110': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '322121': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '322122': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '322130': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '322211': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '322212': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '322219': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '322220': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '322230': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '322291': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '322299': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '323111': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '323113': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '323117': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '323120': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '324110': {
    revenue: '0',
    employees: '1500',
    footnote: '4'
  },
  '324121': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '324122': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '324191': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '324199': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '325110': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325120': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325130': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325180': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325193': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325194': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '325199': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '325211': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '325212': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325220': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325311': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325312': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '325314': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '325320': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325411': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325412': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '325413': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '325414': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '325510': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325520': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '325611': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '325612': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '325613': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '325620': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '325910': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '325920': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '325991': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '325992': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '325998': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '326111': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '326112': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '326113': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '326121': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '326122': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '326130': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '326140': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '326150': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '326160': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '326191': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '326199': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '326211': {
    revenue: '0',
    employees: '1500',
    footnote: '5'
  },
  '326212': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '326220': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '326291': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '326299': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '327110': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '327120': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '327211': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '327212': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '327213': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '327215': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '327310': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '327320': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '327331': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '327332': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '327390': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '327410': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '327420': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '327910': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '327991': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '327992': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '327993': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '327999': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '331110': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '331210': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '331221': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '331222': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '331313': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '331314': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '331315': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '331318': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '331410': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '331420': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '331491': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '331492': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '331511': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '331512': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '331513': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '331523': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '331524': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '331529': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332111': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332112': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332114': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332117': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332119': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332215': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332216': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332311': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332312': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332313': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332321': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332322': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332323': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332410': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332420': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332431': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '332439': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332510': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332613': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332618': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332710': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332721': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332722': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332811': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332812': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332813': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332911': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332912': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '332913': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '332919': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '332991': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '332992': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '332993': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '332994': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '332996': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '332999': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '333111': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '333112': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '333120': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '333131': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333132': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '333241': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333242': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '333243': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333244': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '333249': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333314': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333316': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '333318': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '333413': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333414': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333415': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '333511': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333514': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333515': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333517': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333519': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333611': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '333612': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '333613': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '333618': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '333912': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '333914': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '333921': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '333922': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333923': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '333924': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '333991': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333992': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '333993': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333994': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333995': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '333996': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '333997': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '333999': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '334111': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '334112': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '334118': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '334210': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '334220': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '334290': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '334310': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '334412': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '334413': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '334416': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '334417': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '334418': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '334419': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '334510': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '334511': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '334512': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '334513': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '334514': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '334515': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '334516': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '334517': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '334519': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '334613': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '334614': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '335110': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '335121': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '335122': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '335129': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '335210': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '335220': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '335311': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '335312': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '335313': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '335314': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '335911': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '335912': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '335921': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '335929': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '335931': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '335932': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '335991': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '335999': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '336111': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '336112': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '336120': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '336211': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '336212': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '336213': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '336214': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '336310': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '336320': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '336330': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '336340': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '336350': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '336360': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '336370': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '336390': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '336411': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '336412': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '336413': {
    revenue: '0',
    employees: '1250',
    footnote: '7'
  },
  '336414': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '336415': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '336419': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '336510': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '336611': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '336612': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '336991': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '336992': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '336999': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '337110': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '337121': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '337122': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '337124': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '337125': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '337127': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '337211': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '337212': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '337214': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '337215': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '337910': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '337920': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '339112': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '339113': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '339114': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '339115': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '339116': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '339910': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '339920': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '339930': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '339940': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '339950': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '339991': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '339992': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '339993': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '339994': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '339995': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '339999': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '423110': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '423120': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '423130': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '423140': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423210': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423220': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423310': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '423320': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '423330': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '423390': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423410': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '423420': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '423430': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '423440': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423450': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '423460': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '423490': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '423510': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '423520': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423610': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '423620': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '423690': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '423710': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '423720': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '423730': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '423740': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423810': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '423820': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423830': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423840': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423850': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423860': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '423910': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423920': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '423930': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423940': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '423990': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '424110': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '424120': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '424130': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '424210': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '424310': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '424320': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '424330': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '424340': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '424410': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '424420': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '424430': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '424440': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '424450': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '424460': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '424470': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '424480': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '424490': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '424510': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '424520': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '424590': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '424610': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '424690': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '424710': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '424720': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '424810': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '424820': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '424910': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '424920': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '424930': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '424940': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '424950': {
    revenue: '0',
    employees: '150',
    footnote: '0'
  },
  '424990': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '425110': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '425120': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '441110': {
    revenue: '0',
    employees: '200',
    footnote: '0'
  },
  '441120': {
    revenue: '25',
    employees: '0',
    footnote: '0'
  },
  '441210': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '441222': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '441228': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '441310': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '441320': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '442110': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '442210': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '442291': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '442299': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '443141': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '443142': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '444110': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '444120': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '444130': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '444190': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '444210': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '444220': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '445110': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '445120': {
    revenue: '29.5',
    employees: '0',
    footnote: '0'
  },
  '445210': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '445220': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '445230': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '445291': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '445292': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '445299': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '445310': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '446110': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '446120': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '446130': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '446191': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '446199': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '447110': {
    revenue: '29.5',
    employees: '0',
    footnote: '0'
  },
  '447190': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '448110': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '448120': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '448130': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '448140': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '448150': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '448190': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '448210': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '448310': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '448320': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '451110': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '451120': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '451130': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '451140': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '451211': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '451212': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '452210': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '452311': {
    revenue: '29.5',
    employees: '0',
    footnote: '0'
  },
  '452319': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '453110': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '453210': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '453220': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '453310': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '453910': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '453920': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '453930': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '453991': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '453998': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '454110': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '454210': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '454310': {
    revenue: '0',
    employees: '100',
    footnote: '0'
  },
  '454390': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '481111': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '481112': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '481211': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '481212': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '481219': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '482111': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '482112': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '483111': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '483112': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '483113': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '483114': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '483211': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '483212': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '484110': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '484121': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '484122': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '484210': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '484220': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '484230': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '485111': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '485112': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '485113': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '485119': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '485210': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '485310': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '485320': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '485410': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '485510': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '485991': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '485999': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '486110': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '486210': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '486910': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '486990': {
    revenue: '37.5',
    employees: '0',
    footnote: '0'
  },
  '487110': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '487210': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '487990': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '488111': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '488119': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '488190': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '488210': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '488310': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '488320': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '488330': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '488390': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '488410': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '488490': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '488510': {
    revenue: '15',
    employees: '0',
    footnote: '10'
  },
  '488991': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '488999': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '491110': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '492110': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '492210': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '493110': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '493120': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '493130': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '493190': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '511110': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '511120': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '511130': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '511140': {
    revenue: '0',
    employees: '1250',
    footnote: '0'
  },
  '511191': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '511199': {
    revenue: '0',
    employees: '500',
    footnote: '0'
  },
  '511210': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '512110': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '512120': {
    revenue: '32',
    employees: '0',
    footnote: '0'
  },
  '512131': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '512132': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '512191': {
    revenue: '32',
    employees: '0',
    footnote: '0'
  },
  '512199': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '512230': {
    revenue: '0',
    employees: '750',
    footnote: '0'
  },
  '512240': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '512250': {
    revenue: '0',
    employees: '250',
    footnote: '0'
  },
  '512290': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '515111': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '515112': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '515120': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '515210': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '517311': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '517312': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '517410': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '517911': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '517919': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '518210': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '519110': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '519120': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '519130': {
    revenue: '0',
    employees: '1000',
    footnote: '0'
  },
  '519190': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '522110': {
    revenue: '0',
    employees: '0',
    footnote: '8'
  },
  '522120': {
    revenue: '0',
    employees: '0',
    footnote: '8'
  },
  '522130': {
    revenue: '0',
    employees: '0',
    footnote: '8'
  },
  '522190': {
    revenue: '0',
    employees: '0',
    footnote: '8'
  },
  '522210': {
    revenue: '0',
    employees: '0',
    footnote: '8'
  },
  '522220': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '522291': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '522292': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '522293': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '522294': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '522298': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '522310': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '522320': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '522390': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '523110': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '523120': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '523130': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '523140': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '523210': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '523910': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '523920': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '523930': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '523991': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '523999': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '524113': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '524114': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '524126': {
    revenue: '0',
    employees: '1500',
    footnote: '0'
  },
  '524127': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '524128': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '524130': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '524210': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '524291': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '524292': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '524298': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '525110': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '525120': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '525190': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '525910': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '525920': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '525990': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '531110': {
    revenue: '27.5',
    employees: '0',
    footnote: '9'
  },
  '531120': {
    revenue: '27.5',
    employees: '0',
    footnote: '9'
  },
  '531130': {
    revenue: '27.5',
    employees: '0',
    footnote: '9'
  },
  '531190': {
    revenue: '27.5',
    employees: '0',
    footnote: '9'
  },
  '531210': {
    revenue: '7.5',
    employees: '0',
    footnote: '10'
  },
  '531311': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '531312': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '531320': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '531390': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '532111': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '532112': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '532120': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '532210': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '532281': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '532282': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '532283': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '532284': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '532289': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '532310': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '532411': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '532412': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '532420': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '532490': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '533110': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '541110': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '541191': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '541199': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '541211': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '541213': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '541214': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '541219': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '541310': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541320': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541330': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541340': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541350': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541360': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541370': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541380': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541410': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541420': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541430': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541490': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541511': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '541512': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '541513': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '541519': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '541611': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541612': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541613': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541614': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541618': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541620': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541690': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541713': {
    revenue: '0',
    employees: '1000',
    footnote: '11'
  },
  '541714': {
    revenue: '0',
    employees: '1000',
    footnote: '11'
  },
  '541715': {
    revenue: '0',
    employees: '1000',
    footnote: '11'
  },
  '541720': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '541810': {
    revenue: '15',
    employees: '0',
    footnote: '10'
  },
  '541820': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541830': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541840': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541850': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541860': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541870': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541890': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541910': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '541921': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541922': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541930': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541940': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '541990': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '551111': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '551112': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '561110': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '561210': {
    revenue: '38.5',
    employees: '0',
    footnote: '12'
  },
  '561311': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '561312': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '561320': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '561330': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '561410': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '561421': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '561422': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '561431': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '561439': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '561440': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '561450': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '561491': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '561492': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '561499': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '561510': {
    revenue: '20.5',
    employees: '0',
    footnote: '10'
  },
  '561520': {
    revenue: '20.5',
    employees: '0',
    footnote: '10'
  },
  '561591': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '561599': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '561611': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '561612': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '561613': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '561621': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '561622': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '561710': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '561720': {
    revenue: '18',
    employees: '0',
    footnote: '0'
  },
  '561730': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '561740': {
    revenue: '5.5',
    employees: '0',
    footnote: '0'
  },
  '561790': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '561910': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '561920': {
    revenue: '11',
    employees: '0',
    footnote: '10'
  },
  '561990': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '562111': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '562112': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '562119': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '562211': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '562212': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '562213': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '562219': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '562910': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '562920': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '562991': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '562998': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '611110': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '611210': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '611310': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '611410': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '611420': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '611430': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '611511': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '611512': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '611513': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '611519': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '611610': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '611620': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '611630': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '611691': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '611692': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '611699': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '611710': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '621111': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '621112': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '621210': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '621310': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '621320': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '621330': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '621340': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '621391': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '621399': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '621410': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '621420': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '621491': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '621492': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '621493': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '621498': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '621511': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '621512': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '621610': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '621910': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '621991': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '621999': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '622110': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '622210': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '622310': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '623110': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '623210': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '623220': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '623311': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '623312': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '623990': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '624110': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '624120': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '624190': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '624210': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '624221': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '624229': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '624230': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '624310': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '624410': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '711110': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '711120': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '711130': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '711190': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '711211': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '711212': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '711219': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '711310': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '711320': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '711410': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '711510': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '712110': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '712120': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '712130': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '712190': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '713110': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '713120': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '713210': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '713290': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '713910': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '713920': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '713930': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '713940': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '713950': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '713990': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '721110': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '721120': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '721191': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '721199': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '721211': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '721214': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '721310': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '722310': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '722320': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '722330': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '722410': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '722511': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '722513': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '722514': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '722515': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811111': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811112': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811113': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811118': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811121': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811122': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '811191': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811192': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811198': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811211': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811212': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '811213': {
    revenue: '11',
    employees: '0',
    footnote: '0'
  },
  '811219': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '811310': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811411': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811412': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '811420': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811430': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '811490': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '812111': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '812112': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '812113': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '812191': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '812199': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '812210': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '812220': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '812310': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '812320': {
    revenue: '5.5',
    employees: '0',
    footnote: '0'
  },
  '812331': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '812332': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '812910': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '812921': {
    revenue: '20.5',
    employees: '0',
    footnote: '0'
  },
  '812922': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '812930': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '812990': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '813110': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '813211': {
    revenue: '32.5',
    employees: '0',
    footnote: '0'
  },
  '813212': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '813219': {
    revenue: '38.5',
    employees: '0',
    footnote: '0'
  },
  '813311': {
    revenue: '27.5',
    employees: '0',
    footnote: '0'
  },
  '813312': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '813319': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '813410': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '813910': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '813920': {
    revenue: '15',
    employees: '0',
    footnote: '0'
  },
  '813930': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '813940': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  },
  '813990': {
    revenue: '7.5',
    employees: '0',
    footnote: '0'
  }
}

export const naicsFootnote = {
  '1': ['[Reserved].'],
  '2': [
    'Dredging: To be considered small for purposes of Government procurement, a firm must perform at least 40 percent of the volume dredged with its own equipment or equipment owned by another small dredging concern.'
  ],
  '3': [
    'For purposes of Government procurement for food canning and preserving, the standard of 500 employees excludes agricultural labor as defined in section 3306(k) of the Internal Revenue Code, 26 U.S.C. 3306(k).'
  ],
  '4': [
    'To qualify as small for purposes of Government procurement, the petroleum refiner, including its affiliates, must be a concern that has either no more than 1,500 employees or no more than 200,000 barrels per calendar day total Operable Atmospheric Crude Oil Distillation capacity.  Capacity includes all domestic and foreign affiliates, all owned or leased facilities, and all facilities under a processing agreement or an arrangement such as an exchange agreement or a throughput.  To qualify under the capacity size standard, the firm, together with its affiliates, must be primarily engaged in refining crude petroleum into refined petroleum products.  A firm\'s "primary industry" is determined in accordance with 13 CFR § 121.107.'
  ],
  '5': [
    'For Government procurement, a firm is small for bidding on a contract for pneumatic tires within Census NAICS Product Classification codes 3262111 and 3262113, provided that:',
    'the value of tires within Census NAICS Product Classification codes 3262113 that it manufactured in the United States during the previous calendar year is more than 50 percent of the value of its total worldwide manufacture, ',
    'the value of pneumatic tires within Census Classification codes 30111 and 30112 comprising its total worldwide manufacture during the preceding calendar year was less than 5 percent of the value of all such tires manufactured in the United States during that period, and ',
    'the value of the principal product that it manufactured, produced, or sold worldwide during the preceding calendar year is less than 10 percent of the total value of such products manufactured or otherwise produced or sold in the United States during that period.'
  ],
  '6': [
    'For rebuilding machinery or equipment on a factory basis, or equivalent, use the NAICS code for a newly manufactured product. Concerns performing major rebuilding or overhaul activities do not necessarily have to meet the criteria for being a "manufacturer" although the activities may be classified under a manufacturing NAICS code. Ordinary repair services or preservation are not considered rebuilding.'
  ],
  '7': [
    'Contracts for the rebuilding or overhaul of aircraft ground support equipment on a contract basis are classified under NAICS code 336413.'
  ],
  '8': [
    'A financial institution\'s assets are determined by averaging the assets reported on its four quarterly financial statements for the preceding year.  "Assets" means the assets defined according to the Federal Financial Institutions Examination Council 041 call report form for NAICS Codes 522110, 522120, 522190, and 522210 and the National Credit Union Administration 5300 call report form for NAICS code 522130.'
  ],
  '9': [
    'Leasing of building space to the Federal Government by Owners: For Government procurement, a size standard of $38.5 million in gross receipts applies to the owners of building space leased to the Federal Government. The standard does not apply to an agent.'
  ],
  '10': [
    'As measured by total revenues, but excluding funds received in trust for an unaffiliated third party, such as bookings or sales subject to commissions.  The commissions received are included as revenue.'
  ],
  '11': [
    'Research and Development" means laboratory or other physical research and development. It does not include economic, educational, engineering, operations, systems, or other nonphysical research; or computer programming, data processing, commercial and/or "medical laboratory testing.',
    'For research and development contracts requiring the delivery of a manufactured product, the appropriate size standard is that of the manufacturing industry.',
    'For purposes of the Small Business Innovation Research (SBIR) and Small Business Transfer Technology (STTR) programs, the term "research" or "research and development" means any activity which is (A) a systematic, intensive study directed toward greater knowledge or understanding of the subject studied; (B) a systematic study directed specifically toward applying new knowledge to meet a recognized need; or (C) a systematic application of knowledge toward the production of useful materials, devices, and systems or methods, including design, development, and improvement of prototypes and new processes to meet specific requirements. See 15 U.S.C. § 638(e)(5) and section 3 of the SBIR and STTR policy directives available at www.sbir.gov.  For size eligibility requirements for the SBIR and STTR programs, see § 121.702 of these regulations. ',
    '"Research and Development" for guided missiles and space vehicles includes evaluations and simulation, and other services requiring thorough knowledge of complete missiles and spacecraft.'
  ],
  '12': [
    'Facilities Support Services:',
    'If one or more activities of Facilities Support Services as defined in paragraph (b) (below in this footnote) can be identified with a specific industry and that industry accounts for 50% or more of the value of an entire procurement, then the proper classification of the procurement is that of the specific industry, not Facilities Support Services.',
    '"Facilities Support Services" requires the performance of three or more separate activities in the areas of services or specialty trade contractors industries.  If services are performed, these service activities must each be in a separate NAICS industry.  If the procurement requires the use of specialty trade contractors (plumbing, painting, plastering, carpentry, etc.), all such specialty trade contractors activities are considered a single activity and classified as "Building and Property Specialty Trade Services."  Since "Building and Property Specialty Trade Services" is only one activity, two additional activities of separate NAICS industries are required for a procurement to be classified as "Facilities Support Services."'
  ],
  '13': [
    'Building and Property Specialty Trade Services:  If a procurement requires the use of multiple specialty trade contractors (i.e., plumbing, painting, plastering, carpentry, etc.), and no specialty trade accounts for 50% or more of the value of the procurement, all such specialty trade contractors activities are considered a single activity and classified as Building and Property Specialty Trade Services.'
  ],
  '14': [
    'Environmental Remediation Services:',
    "For SBA assistance as a small business concern in the industry of Environmental Remediation Services, other than for Government procurement, a concern must be engaged primarily in furnishing a range of services for the remediation of a contaminated environment to an acceptable condition including, but not limited to, preliminary assessment, site inspection, testing, remedial investigation, feasibility studies, regulatory compliance, remedial design, containment, remedial action, removal of contaminated materials, nuclear remediation, storage of contaminated materials and security and site closeouts.  If one of such activities accounts for 50 percent or more of a concern's total revenues, employees, or other related factors, the concern's primary industry is that of the particular industry and not the Environmental Remediation Services Industry.",
    'For purposes of classifying a Government procurement as Environmental Remediation Services, the general purpose of the procurement must be to restore or directly support the restoration of a contaminated environment.  This includes activities such as preliminary assessment, site inspection, testing, remedial investigation, feasibility studies, regulatory compliance, remedial design, remediation services, containment, nuclear remediation, and removal of contaminated materials or security and site closeouts.  The general purpose of the procurement need not necessarily include remedial actions.  Also, the procurement must be composed of activities in three or more separate industries with separate NAICS codes or, in some instances (e.g., engineering), smaller sub-components of NAICS codes with separate and distinct size standards. These activities may include, but are not limited to, separate activities in industries such as: Heavy Construction; Special Trade Contractors; Engineering Services; Architectural Services; Management Consulting Services; Hazardous and Other Waste Collection;  Remediation Services; Testing Laboratories; and Research and Development  in the Physical, Engineering, and Life Sciences.  If any activity in the procurement can be identified with a separate NAICS code, or component of a code with a separate distinct size standard, and that industry accounts for 50 percent or more of the value of the entire procurement, then the proper size standard is the one for that particular industry, and not the Environmental Remediation Service size standard.'
  ],
  '15': ['[Removed and Reserved]'],
  '16': [
    'Job Corps Centers.  For classifying a Federal procurement, the purpose of the solicitation must be for the management and operation of a U.S. Department of Labor Job Corps Center.  The activities involved include admissions activities, life skills training, educational activities, comprehensive career preparation activities, career development activities, career transition activities, as well as the management and support functions and services needed to operate and maintain the facility.  For SBA assistance as a small business concern, other than for Federal Government procurements, a concern must be primarily engaged in providing the services to operate and maintain Federal Job Corps Centers.'
  ],
  '17': [
    'Support Activities for Forestry – Forest Fire Suppression and Fuels Management Services are two components of Support Activities for Forestry.  Forest Fire Suppression includes establishments which provide services to fight forest fires.  These firms usually have fire-fighting crews and equipment.  Fuels Management Services firms provide services to clear land of hazardous materials that would fuel forest fires.  The treatments used by these firms may include prescribed fire, mechanical removal, establishing fuel breaks, thinning, pruning, and piling.'
  ],
  '18': [
    'An Information Technology Value Added Reseller (ITVAR) provides a total solution to information technology acquisitions by providing multi-vendor hardware and software along with significant value added services.  Significant value added services consist of, but are not limited to, configuration consulting and design, systems integration, installation of multi-vendor computer equipment, customization of hardware or software, training, product technical support, maintenance, and end user support.  For purposes of Government procurement, an information technology procurement classified under this exception and 150-employee size standard must consist of at least 15% and not more than 50% of value added services, as measured by the total contract price.  In addition, the offeror must comply with the manufacturing performance requirements, or comply with the non-manufacturer rule by supplying the products of small business concerns, unless SBA has issued a class or contract specific waiver of the non-manufacturer rule.  If the contract consists of less than 15% of value added services, then it must be classified under a NAICS manufacturing industry.  If the contract consists of more than 50% of value added services, then it must be classified under the NAICS industry that best describes the predominate service of the procurement.'
  ],
  '19': [
    'Small business size standards are not established for this sector.  Establishments in the Public Administration sector are Federal, State, and local government agencies which administer and oversee government programs and activities that are not performed by private establishments.  Concerns performing operational services for the administration of a government program are classified under the NAICS private sector industry based on the activities performed.  Similarly, procurements for these types of services are classified under the NAICS private sector industry that best describes the activities to be performed.  For example, if a government agency issues a procurement for law enforcement services, the requirement would be classified using one of the NAICS industry codes under NAICS industry 56161, Investigation, Guard, and Armored Car Services.'
  ]
}

export default naicsStd
