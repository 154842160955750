import React, { Component, ReactNode } from 'react'
import SearchFilter from '../../components/SearchFilter'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { toggleFilterPanel } from '../../actions'
import filterPanelsSelectors from '../../selectors/filterPanelsSelectors'

type Props = {
  toggleFilterPanel: (args: {
    ref: string
    key: string
    value: boolean
  }) => void
  isCollapse: boolean
  filterLabel: string | ReactNode
} & ContainerProps

export class SearchFilterContainer extends Component<Props> {
  handleOnClick = () => {
    const { toggleFilterPanel, panelId, isCollapse, match } = this.props
    toggleFilterPanel({
      ref: match.params.vetId || 'suppliers',
      key: panelId,
      value: !isCollapse
    })
  }

  render() {
    const { ...props } = this.props
    return <SearchFilter {...props} onClick={this.handleOnClick} />
  }
}

type ContainerProps = {
  panelId: string
} & RouteComponentProps<{ vetId: string }>

export default withRouter(
  connect(
    (state, props: ContainerProps) => {
      const ref = props.match.params.vetId || 'suppliers'
      const panelId = props.panelId
      return {
        isCollapse: filterPanelsSelectors.isPanelCollapse(state, ref, panelId)
      }
    },
    { toggleFilterPanel }
  )(SearchFilterContainer)
)
