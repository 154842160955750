import React, { FC, useEffect } from 'react'
import { createSelector } from 'reselect'
import { connect } from 'react-redux'
import { loadData, LoadDataPayloads } from 'shared/actions'
import dataSelectors from 'shared/selectors/dataSelectors'
import ClientListItemContainer from '../ClientListItemContainer'
import Text from 'shared/components/Text'
import TealbotTips from 'shared/components/TealbotTips'
import { FormattedMessage } from 'react-intl'
import Scrollable from 'shared/components/Scrollable'
import { List, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'

type Props = {
  orgUnitId?: string
  clients: List<string>
  askForVerification: boolean
  loadData: (arg: LoadDataPayloads) => void
}

export const ClientListComponent: FC<Props> = props => {
  const { orgUnitId, loadData, clients, askForVerification } = props

  useEffect(() => {
    loadData({
      entity: 'supplierRelationships',
      query: orgUnitId && `supplierRelationships?supplierId=${orgUnitId}`
    })
  }, [orgUnitId, loadData])

  if (!clients || clients.size < 1) {
    return null
  }

  return (
    <div className='ph3 pb3'>
      <Scrollable>
        {clients.map(id => (
          <ClientListItemContainer key={id} supplierRelationshipId={id} />
        ))}
      </Scrollable>
      {askForVerification && (
        <div className='mt3'>
          <TealbotTips mini noBorder noLabel>
            <Text>
              <FormattedMessage
                id='ClientListContainer.AslYourCustomers'
                defaultMessage='Ask your customer to verify to increase your credibility score!'
              />
            </Text>
          </TealbotTips>
        </div>
      )}
    </div>
  )
}

export const askForVerification = createSelector(
  dataSelectors.getSupplierClients,
  (
    clients: List<
      RecordOf<{
        supplierCollaboration: {
          requestVerify: boolean
        }
      }>
    >
  ) =>
    clients &&
    clients.some(c => !c.getIn(['supplierCollaboration', 'requestVerify']))
)

export default connect(
  (state: RootState) => ({
    clients: dataSelectors.getSupplierClientsID(state),
    askForVerification: askForVerification(state)
  }),
  {
    loadData
  }
)(ClientListComponent)
