import React, { useMemo, memo } from 'react'
import NavLink from 'shared/components/NavBar/NavLink'
import { FormattedMessage } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'
import { useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'

type Props = {
  notification: boolean
} & ContainerProps

const DiversityNavLink = memo(({ notification, to }: Props) => {
  return (
    <Tooltip
      title={
        <FormattedMessage
          id='DiversityNavLinkContainer.diversityTip'
          defaultMessage='Review your Tier 2 information'
        />
      }
    >
      <NavLink
        to={to}
        label={
          <FormattedMessage
            id='DiversityNavLinkContainer.Diversity'
            defaultMessage='Diversity'
          />
        }
        numberOfNotifications={notification ? 1 : undefined}
        isActive={(_, location) => location.pathname.includes('diversity')}
      />
    </Tooltip>
  )
})

type ContainerProps = {
  to: string
}
const DiversityNavLinkContainer = ({ to }: ContainerProps) => {
  const getTier2Communities = useSelector((state: RootState) =>
    state.getIn([
      'supplier',
      'tier2',
      'tier2Communities',
      'tier2CommMemberships'
    ])
  )
  const notification = useMemo(
    () =>
      getTier2Communities &&
      getTier2Communities.some(
        supplier => supplier.get('status') === 'Invited'
      ),
    [getTier2Communities]
  )
  return <DiversityNavLink notification={notification} to={to} />
}

export default DiversityNavLinkContainer
