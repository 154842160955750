import { call, put } from 'redux-saga/effects'
import vets from 'shared/utils/api/vets'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import createInviteUserActions from 'shared/utils/inviteUser/createActions'

const { selectNewUser } = createInviteUserActions('inviteVetSupplierContact')

export function* addVetSupplier(action) {
  const { vetId, sendToContact, email } = action.payload

  if (vetId) {
    const vetBuyerSupplier = yield call(vets.addVetSupplier, action.payload)
    const vetBuyerSupplierId = Object.keys(vetBuyerSupplier)[0]

    if (sendToContact) {
      const {
        vetBS: {
          collaboration: { sendToContacts }
        }
      } = vetBuyerSupplier[vetBuyerSupplierId]
      const found = sendToContacts.find(
        contact => contact.user === sendToContact
      )

      let newSendToContacts
      if (!found) {
        newSendToContacts = yield call(vets.addSupplierContact, {
          vetBuyerSupplierId,
          userId: sendToContact
        })
        vetBuyerSupplier[
          vetBuyerSupplierId
        ].vetBS.collaboration.sendToContacts = newSendToContacts
      }
    } else if (email) {
      const {
        supplier: { id: orgUnitId }
      } = vetBuyerSupplier[vetBuyerSupplierId]

      yield put(selectNewUser(email, { orgUnitId, vetBuyerSupplierId }))
    }
    return yield { vetId, vetBuyerSupplier }
  }
}

export default createApiRequestSaga(addVetSupplier)
