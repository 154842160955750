import { fromJS } from 'immutable'
import { NOTIFY, CLEAR_NOTIFICATIONS } from '../../actionTypes'

const defaultState = fromJS({
  message: ''
})

const notificationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case NOTIFY:
      return fromJS(action.payload)
    case CLEAR_NOTIFICATIONS:
      return defaultState
    default:
      return state
  }
}

export default notificationsReducer
