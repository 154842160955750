import React, { Component } from 'react'
import AppBarContainer from 'shared/containers/AppBarContainer'
import { NavLink } from 'shared/components/AppBar'
import UserNavContainer from 'shared/containers/UserNavContainer'
import paths from '../../routes/paths'
import { List } from 'immutable'

const tealbotPageRoles = [
  'clientConcierge',
  'supplierConcierge',
  'orgAdmin',
  'partner'
]
const tasksPageRoles = ['orgAdmin', 'clientConcierge']
const statisticsRoles = ['clientConcierge', 'supplierConcierge']

/**
 * AppBar for suppliers (has communites, Vet, and Company links as well as a userNav dropdown with Buyer App and My account included)
 */

type Props = {
  roles: List<string>
}
class DataPortalAppBar extends Component<Props> {
  render() {
    const { roles } = this.props

    return (
      <AppBarContainer userNav={<UserNavContainer />} homeUrl={paths.basePath}>
        <div className='flex-auto' />

        {roles.includes('orgAdmin') && (
          <NavLink label='Forbidden Domains' to={paths.forbiddenDomains} />
        )}

        {roles.some(r => tealbotPageRoles.includes(r)) && (
          <NavLink label='tealbot' to={paths.tealbot} />
        )}

        {roles.some(r => tasksPageRoles.includes(r)) && (
          <NavLink label='Tasks' to={paths.tasks} />
        )}

        {roles.includes('orgAdmin') && (
          <NavLink label='Connect Users' to={paths.connectUsers} />
        )}

        {roles.includes('orgAdmin') && (
          <NavLink label='Loader' to={paths.loader} />
        )}

        {(roles.includes('orgAdmin') || roles.includes('serverAdmin')) && (
          <NavLink label='Utilities' to={paths.utilities} />
        )}

        {roles.includes('serverAdmin') && (
          <NavLink label='Server' to={paths.indexing} />
        )}
        {roles.includes('clientConcierge') && (
          <NavLink label='Client Files' to={paths.clientFiles} />
        )}
        {roles.some(r => statisticsRoles.includes(r)) && (
          <NavLink label='Statistics' to={paths.statistics} />
        )}
        {roles.some(r => statisticsRoles.includes(r)) && (
          <NavLink label='Supplier Matching' to={paths.supplierMatching} />
        )}
        {roles.some(r => statisticsRoles.includes(r)) && (
          <NavLink label='Data Quality' to={paths.dataQualityService} />
        )}
      </AppBarContainer>
    )
  }
}

export default DataPortalAppBar
