import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import users from 'shared/utils/api/users'
import Immutable from 'immutable'

export function* updateUserRolesSaga(action) {
  const { userId, roles } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  yield call(users.updateUserRoles, userId, roles)
  return yield { userId, roles }
}

export default createApiRequestSaga(updateUserRolesSaga)
