import usersSelectors from '../../../selectors/usersSelectors'

const createSelectors = inviteUserSelector => {
  function getSelectedUserId(state) {
    const inviteUser = inviteUserSelector(state)
    return inviteUser.get('selectedUserId')
  }

  const getSelectedUser = state => {
    const selectedUserId = getSelectedUserId(state)
    return selectedUserId && usersSelectors.getById(state, selectedUserId)
  }

  const getNewUser = state => {
    const inviteUser = inviteUserSelector(state)
    return inviteUser.get('newUser')
  }

  const getMeta = state => {
    const inviteUser = inviteUserSelector(state)
    return inviteUser.get('meta')
  }

  const isCreatingNewUser = state =>
    !getSelectedUserId(state) && getNewUser(state).size > 0

  const isAddingContact = state => {
    return !!getSelectedUserId(state) || isCreatingNewUser(state)
  }

  return {
    getSelectedUser,
    getNewUser,
    getMeta,
    isCreatingNewUser,
    isAddingContact
  }
}

export default createSelectors
