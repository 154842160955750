import { call, put } from 'redux-saga/effects'
import insight from 'shared/utils/api/insight'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { requestSuccess } from '../../../shared/actionTypes'
import { DELETE_SNAPSHOT } from './actionTypes'

export function* deleteSnapshotsSaga(action) {
  const snapshotIds: string[] = action.payload || []

  for (const id of snapshotIds) {
    yield call(insight.deleteSnapshot, id)
    yield put({ type: requestSuccess(DELETE_SNAPSHOT), payload: id })
  }

  return yield snapshotIds
}

export default createApiRequestSaga(deleteSnapshotsSaga)
