import React, { Component } from 'react'
import { connect } from 'react-redux'
import ColleagueCardContainer from '../ColleagueCardContainer'
import profileSelectors from '../../selectors/profileSelectors'
import { List } from 'immutable'

type Props = {
  colleaguesCards: List<string>
}

class ColleaguesTags extends Component<Props> {
  render() {
    const { colleaguesCards } = this.props

    return (
      <div>
        {colleaguesCards &&
          colleaguesCards.map(cardId => (
            <ColleagueCardContainer key={cardId} cardId={cardId} />
          ))}
      </div>
    )
  }
}

export default connect(state => ({
  colleaguesCards: profileSelectors.getColleaguesCards(state)
}))(ColleaguesTags)
