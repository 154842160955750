import React, { ReactNode, FormEvent } from 'react'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import Button from 'shared/components/Button'

type Props = {
  header?: string
  onSave: () => void
  onCancel: () => void
  onEdit: () => void
  children: ReactNode
  isEditing?: boolean
  size?: 'default' | 'large'
  readonly?: boolean
}

const SowMainSection = (props: Props) => {
  const {
    header,
    readonly,
    children,
    size = 'default',
    isEditing,
    onSave,
    onCancel,
    onEdit
  } = props

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='flex items-center justify-between'>
        {header && (
          <h5
            className={`${size === 'large' ? 'f5' : 'f6'} fw6 near-black mv3`}
          >
            {header}
          </h5>
        )}
        {!readonly && (
          <div>
            {!!isEditing ? (
              <>
                <Button autoSize variant='text' onClick={onSave}>
                  Save
                </Button>
                <IconButton onClick={onCancel} aria-label='cancel'>
                  <CancelIcon fontSize='small' color='error' />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={onEdit} aria-label='edit'>
                <EditIcon fontSize='small' color='primary' />
              </IconButton>
            )}
          </div>
        )}
      </div>
      <div>{children}</div>
    </form>
  )
}

export default SowMainSection
