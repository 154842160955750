export const CREATE_POST = 'posting/CREATE_POST'
export const LOAD_POSTS = 'posting/LOAD_POSTS'
export const UP_VOTE = 'posting/UP_VOTE'
export const CLEAR_VOTE = 'posting/CLEAR_VOTE'
export const DELETE_POST = 'posting/DELETE_POST'
export const ADD_POST_COMMENT = 'posting/ADD_POST_COMMENT'
export const DELETE_POST_COMMENT = 'posting/DELETE_POST_COMMENT'
export const MARK_POST_READ = 'posting/MARK_POST_READ'
export const CLEAR_UNREAD = 'posting/CLEAR_UNREAD'
export const GET_RECENT_SUPPLIERS = 'posting/GET_RECENT_SUPPLIERS'
export const LOAD_POST_BY_TAG = 'posting/LOAD_POST_BY_TAG'
export const GET_RECENT_SUPPLIERS_TAGS = 'posting/GET_RECENT_SUPPLIERS_TAGS'
export const GET_SUPPLIER_COMMENTS_AND_TAGS_FOR_EXPORT =
  'posting/GET_SUPPLIER_COMMENTS_AND_TAGS_FOR_EXPORT'
