import usersSelectors from 'shared/selectors/usersSelectors'
import RootState from 'shared/models/RootState'

export const getSelectedUser = (state: RootState) => {
  const selectedUserId = getSelectedUserId(state)
  return selectedUserId && usersSelectors.getById(state, selectedUserId)
}

export const getNewUser = (state: RootState) => {
  return state.getIn([
    'buyer',
    'supplierProfile',
    'newOutreachContact',
    'newUser'
  ])
}

export const isCreatingNewUser = (state: RootState) =>
  !getSelectedUserId(state) && getNewUser(state).size > 0

export const isAddingContact = (state: RootState) => {
  return (
    !addSuccess(state) &&
    (!!getSelectedUserId(state) || isCreatingNewUser(state))
  )
}

export const addSuccess = (state: RootState) =>
  state.getIn(['buyer', 'supplierProfile', 'newOutreachContact', 'addSuccess'])

function getSelectedUserId(state: RootState) {
  return state.getIn([
    'buyer',
    'supplierProfile',
    'newOutreachContact',
    'selectedUserId'
  ])
}
