import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import orgsSelectors from 'shared/selectors/orgsSelectors'

export const getById = (state, communityId) =>
  state.getIn(['supplier', 'communities', 'byId', communityId]) || fromJS({})

const createIdsSelector = (communityIdStatus, tier2) =>
  createSelector(
    state => state.getIn(['supplier', 'communities', communityIdStatus]),
    state => state.getIn(['supplier', 'communities', 'byId']),
    (ids, communitiesById) =>
      ids &&
      ids.filter(id =>
        tier2
          ? !!communitiesById.getIn([id, 'tier2'])
          : !communitiesById.getIn([id, 'tier2'])
      )
  )

export const getInvitedIds = createIdsSelector('invitedIds', false)
export const getAcceptedIds = createIdsSelector('acceptedIds', false)
export const getRecommendedIds = createIdsSelector('recommendedIds', false)
export const getTier2AcceptedIds = createIdsSelector('acceptedIds', true)

export const isLoadingCommunityFormPage = state =>
  state.getIn(['supplier', 'communities', 'loadingCommunityFormPage'])

export const getAllAccepted = createSelector(
  getAcceptedIds,
  state => state.getIn(['supplier', 'communities', 'byId']),
  (acceptedIds, allCommunities) =>
    acceptedIds && acceptedIds.map(id => allCommunities.get(id))
)

export const getFormPage = (state, commId, pageNum) => {
  return (
    state.getIn([
      'supplier',
      'communities',
      'byId',
      commId,
      'form',
      'pages',
      parseInt(pageNum, 0) - 1
    ]) || fromJS({})
  )
}

export const getMembershipByCommunityId = (state, commId) => {
  const community = getById(state, commId) || fromJS({})
  const membershipId = community && community.get('membershipId')

  return (
    state.getIn(['supplier', 'communities', 'membershipsById', membershipId]) ||
    fromJS({})
  )
}

export const getPagesProgress = createSelector(
  getById,
  getMembershipByCommunityId,
  (community, membership) => {
    const pages = community.getIn(['form', 'pages'])
    return (
      pages &&
      pages.map(page => ({
        id: page.get('id'),
        title: page.get('title'),
        viewed: membership.hasIn(['response', 'pagesViewed', page.get('id')]),
        isMissingResponse: computePageIsMissingResponse(membership, page)
      }))
    )
  }
)

export const formIsMissingResponse = createSelector(
  getById,
  getMembershipByCommunityId,
  (community, membership) =>
    community &&
    community.hasIn(['form', 'pages']) &&
    community
      .getIn(['form', 'pages'])
      .some(page => computePageIsMissingResponse(membership, page))
)

export const pageIsMissingResponse = createSelector(
  getMembershipByCommunityId,
  getFormPage,
  computePageIsMissingResponse
)

function computePageIsMissingResponse(membership, page) {
  return (
    page.get('questions') &&
    page
      .get('questions')
      .some(
        question =>
          question.get('required') &&
          responseIsMissing(
            membership.getIn([
              'response',
              'responses',
              question.get('question'),
              'response'
            ])
          )
      )
  )
}

function responseIsMissing(response) {
  return (
    response === undefined ||
    // strings cannot be empty
    (typeof response === 'string' && !response) ||
    // arrays or objects cannot be empty
    (typeof response === 'object' && !response.size)
  )
}

export const getBuyers = createSelector(
  (state, communityId, isPublic) =>
    state.getIn(['supplier', 'communities', 'byId', communityId, 'buyers']),
  (state, communityId, isPublic) => isPublic,
  (buyers, isPublic) => {
    return (
      buyers &&
      buyers
        .filter(buyer =>
          isPublic
            ? buyer.get('name')
            : !buyer.get('name') && buyer.get('categorization')
        )
        .sort((buyerA, buyerB) => {
          const nameA = isPublic
            ? buyerA.get('name')
            : buyerA.get('categorization')
          const nameB = isPublic
            ? buyerB.get('name')
            : buyerB.get('categorization')
          if (nameA.toLowerCase() > nameB.toLowerCase()) {
            return 1
          } else {
            return -1
          }
        })
    )
  }
)

export const getTier2Communities = createSelector(
  state => state.getIn(['supplier', 'communities', 'byId']),
  state => state.getIn(['supplier', 'communities', 'membershipsById']),
  orgsSelectors.getOrgs,
  (communities, membershipsById, orgsById) =>
    communities &&
    communities
      .filter(community => !!community.get('tier2'))
      .reduce((result, currentItem) => {
        const active =
          membershipsById &&
          membershipsById.get(currentItem.getIn(['membershipId'])).get('active')
        const buyerName = currentItem.getIn(['buyers', 0, 'name'])
          ? currentItem.getIn(['buyers', 0, 'name'])
          : orgsById.getIn([
              currentItem.getIn(['buyers', 0, 'orgUnitId']),
              'name'
            ])
        const status =
          membershipsById &&
          membershipsById.get(currentItem.getIn(['membershipId'])).get('status')
        const communityId = currentItem.get('id')
        if (active) {
          result.push(fromJS({ buyerName, status, communityId }))
        }
        return result
      }, [])
)

export const isLoadingTier2Invitations = state =>
  state.getIn(['supplier', 'communities', 'isLoading'])
