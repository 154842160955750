import { call, select } from 'redux-saga/effects'
import users from 'shared/utils/api/users'
import usersSelectors from 'shared/selectors/usersSelectors'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* loadUserSaga(action) {
  const user = yield select(usersSelectors.getById, action.payload)

  if (user.get('email')) {
    return false
  }

  const { user: loadUser, orgUnitsById } = yield call(
    users.getUserById,
    action.payload
  )
  return {
    user: loadUser,
    orgUnitsById
  }
}

export default createApiRequestSaga(loadUserSaga)
