import React, { ChangeEvent } from 'react'
import { List, RecordOf } from 'immutable'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'
import ExternalLink from 'shared/components/ExternalLink'
import Tooltip from 'shared/components/Tooltip'

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: string | List<string>
  matches: List<
    RecordOf<{
      domains: List<string>
      name: string
      foundSource: string
      confidence: number
      matchedOn: string
    }>
  >
}

const MatchesSection = (props: Props) => {
  const { matches, value, onChange } = props

  return (
    <>
      <div className='flex justify-between'>
        <div className='flex-auto fw6'>
          <Label>
            <FormattedMessage
              id='RemapSpendItemDialog.PossibleMatch'
              defaultMessage='Possible Match'
            />
          </Label>
        </div>
        <div className='w-30'>
          <Label>
            <FormattedMessage
              id='RemapSpendItemDialog.Confidence'
              defaultMessage='Confidence'
            />
          </Label>
        </div>
      </div>

      {matches &&
        matches.map(match => {
          return (
            <div key={match.get('name')} className='flex justify-between mv2'>
              <div className='flex-auto flex items-center'>
                <input
                  name='remapDomain'
                  type='radio'
                  value={match.getIn(['domains', 0])}
                  checked={
                    typeof value === 'string'
                      ? match.get('domains').includes(value)
                      : value.some(v => match.get('domains').includes(v))
                  }
                  className='mr2'
                  onChange={onChange}
                />
                <span className='f7 fw4 mid-gray'>
                  {match.get('name') && (
                    <>
                      <span className='fw6'>{match.get('name')}</span>
                      <br />
                    </>
                  )}
                  {match.get('domains').map((domain, index) => {
                    return (
                      <span key={`${index}-${domain}`}>
                        {index > 0 && ', '}
                        <ExternalLink href={domain}>{domain}</ExternalLink>
                      </span>
                    )
                  })}
                </span>
              </div>
              <div className='w-30'>
                <Text>
                  <Tooltip title={match.get('confidence').toFixed(0)}>
                    <span>
                      {match.get('confidence') >= 90 ? (
                        <FormattedMessage
                          id='RemapSpendItemDialog.VeryHigh'
                          defaultMessage='Very High'
                        />
                      ) : match.get('confidence') >= 80 ? (
                        <FormattedMessage
                          id='RemapSpendItemDialog.High'
                          defaultMessage='High'
                        />
                      ) : match.get('confidence') >= 70 ? (
                        <FormattedMessage
                          id='RemapSpendItemDialog.Somewhat'
                          defaultMessage='Somewhat'
                        />
                      ) : match.get('confidence') >= 50 ? (
                        <FormattedMessage
                          id='RemapSpendItemDialog.Low'
                          defaultMessage='Low'
                        />
                      ) : (
                        <FormattedMessage
                          id='RemapSpendItemDialog.NotApplicable'
                          defaultMessage='Not Applicable'
                        />
                      )}
                    </span>
                  </Tooltip>
                </Text>
              </div>
            </div>
          )
        })}
    </>
  )
}

export default MatchesSection
