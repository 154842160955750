import React, { Component, ChangeEvent } from 'react'

type Props = {
  placeholder?: string
  additionalClass?: string
  id?: string
  input?: { value: any }
  meta?: { error?: string; touched?: boolean }
  resize?:
    | 'vertical'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'block'
    | 'both'
    | 'horizontal'
    | 'inline'
    | 'none'
  height?: string | number
  maxLength?: number
  value?: any
  name?: string
  className?: string
  required?: boolean
  readOnly?: boolean
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
}
/**
 * Creates a textarea element
 */
class TextArea extends Component<Props> {
  render() {
    const {
      input,
      meta = {},
      resize,
      height,
      additionalClass = '',
      maxLength,
      ...rest
    } = this.props

    const length = input
      ? input.value
        ? input.value.length
        : 0
      : rest.value
      ? rest.value.length
      : 0

    return (
      <div>
        <textarea
          {...input}
          maxLength={maxLength}
          className={`f6 ba b--black-70 br2 pv2-5 pa2 w-100 border-box ${additionalClass}`}
          style={{
            resize: resize || 'vertical',
            height: height || 70
          }}
          {...rest}
        />
        {maxLength && (
          <div className='f7 fw4 gray tr'>{`${length} / ${maxLength}`}</div>
        )}

        {meta.touched && meta.error && (
          <span className='f7 red'>{meta.error}</span>
        )}
      </div>
    )
  }
}

export default TextArea
