import React from 'react'
import CreditCardErrorCode from './CreditCardErrorCode'
import { FormattedMessage } from 'react-intl'
type Props = {
  code: CreditCardErrorCode | ''
  message: string
}

const CreditCardErrorMessage = (props: Props) => {
  if (props.code === 'incomplete_number') {
    return (
      <FormattedMessage
        id='CreditCardErrorMessage.IncompleteNumber'
        defaultMessage='Your card number is incomplete.'
      />
    )
  }

  if (props.code === 'invalid_number') {
    return (
      <FormattedMessage
        id='CreditCardErrorMessage.InvalidNumber'
        defaultMessage='Your card number is invalid.'
      />
    )
  }

  if (props.message) {
    return <>{props.message}</>
  }

  return (
    <FormattedMessage
      id='CreditCardErrorMessage.ThereWasAnError'
      defaultMessage='There seems to be an issue. Please try again later.'
    />
  )
}

export default CreditCardErrorMessage
