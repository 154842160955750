import bowser from 'bowser'

const browser = bowser.getParser(window.navigator.userAgent)
const isValidBrowser = browser.satisfies({
  // declare browsers per OS
  windows: {
    'internet explorer': '>10'
  },
  macos: {
    safari: '>10.1'
  },

  // per platform (mobile, desktop or tablet)
  mobile: {
    safari: '>9',
    'android browser': '>3.10'
  },

  // or in general
  chrome: '>20.1.1432',
  firefox: '>31',
  opera: '>22',
  edge: '>15'
})

const isBrowserSupported = () => isValidBrowser

export default isBrowserSupported
