// cast the dates to Date objects
import parseCollaboration from './parseCollaboration'

const parseVetBS = ({
  comments,
  created,
  modified,
  suggested,
  approved,
  invited,
  closed,
  cancelled,
  collaboration,
  ...rest
}) => {
  return {
    comments:
      comments &&
      comments.map(({ date, ...rest }) => ({ date: new Date(date), ...rest })),
    created: created && { user: created.user, date: new Date(created.date) },
    modified: modified && {
      user: modified.user,
      date: new Date(modified.date)
    },
    suggested: suggested && {
      user: suggested.user,
      date: new Date(suggested.date)
    },
    approved: approved && {
      user: approved.user,
      date: new Date(approved.date)
    },
    invited: invited && { user: invited.user, date: new Date(invited.date) },
    closed: closed && { user: closed.user, date: new Date(closed.date) },
    cancelled: cancelled && {
      user: cancelled.user,
      date: new Date(cancelled.date)
    },
    collaboration: parseCollaboration(collaboration),
    ...rest
  }
}

export default parseVetBS
