import React, { FunctionComponent } from 'react'
import Tooltip from 'shared/components/Tooltip'
import classnames from 'classnames'
import { ReactComponent as Unreachable } from 'shared/assets/icons/unreachable.svg'
import { ReactComponent as Unknown } from 'shared/assets/icons/unknown.svg'
import { ReactComponent as Inappropriate } from 'shared/assets/icons/inappropriate.svg'
import { ReactComponent as ForSale } from 'shared/assets/icons/forsale.svg'
import { ReactComponent as BlackListed } from 'shared/assets/icons/blacklisted.svg'
import { defineMessages, injectIntl, IntlShape } from 'react-intl'

export const messages = defineMessages({
  UNREACHABLE_DOMAIN: {
    id: 'WebsiteWarning.UNREACHABLE_DOMAIN',
    defaultMessage: 'Unreachable domain'
  },
  UNKNOWN_REASON: {
    id: 'WebsiteWarning.UNKNOWN_REASON',
    defaultMessage: 'Unknown reason'
  },
  INAPPROPRIATE_CONTENT_DOMAIN: {
    id: 'WebsiteWarning.INAPPROPRIATE_CONTENT_DOMAIN',
    defaultMessage: 'Inappropriate content'
  },
  DOMAIN_FOR_SALE: {
    id: 'WebsiteWarning.DOMAIN_FOR_SALE',
    defaultMessage: 'Domain for sale'
  },
  BLACKLISTED_DOMAIN: {
    id: 'WebsiteWarning.BLACKLISTED_DOMAIN',
    defaultMessage: 'Blacklisted domain'
  }
})

export type WebsiteWarningType =
  | 'UNREACHABLE_DOMAIN'
  | 'UNKNOWN_REASON'
  | 'INAPPROPRIATE_CONTENT_DOMAIN'
  | 'DOMAIN_FOR_SALE'
  | 'BLACKLISTED_DOMAIN'

type Props = {
  warning: WebsiteWarningType
  className?: string
  intl: IntlShape
}

export const WebsiteWarning: FunctionComponent<Props> = props => {
  const { warning } = props
  if (
    !(
      warning === 'UNREACHABLE_DOMAIN' ||
      warning === 'UNKNOWN_REASON' ||
      warning === 'INAPPROPRIATE_CONTENT_DOMAIN' ||
      warning === 'DOMAIN_FOR_SALE' ||
      warning === 'BLACKLISTED_DOMAIN'
    )
  ) {
    return null
  }

  return (
    <div
      className={classnames('dib v-mid', props.className)}
      style={{ width: 16 }}
    >
      <Tooltip title={props.intl.formatMessage(messages[props.warning])}>
        {props.warning === 'UNREACHABLE_DOMAIN' ? (
          <Unreachable width='100%' height='100%' />
        ) : warning === 'UNKNOWN_REASON' ? (
          <Unknown width='100%' height='100%' />
        ) : warning === 'INAPPROPRIATE_CONTENT_DOMAIN' ? (
          <Inappropriate width='100%' height='100%' />
        ) : warning === 'DOMAIN_FOR_SALE' ? (
          <ForSale width='100%' height='100%' />
        ) : (
          <BlackListed width='100%' height='100%' />
        )}
      </Tooltip>
    </div>
  )
}

export default injectIntl(WebsiteWarning)
