import React, { Component } from 'react'
import { connect } from 'react-redux'
import searchSelectors from '../../selectors/searchSelectors'
import Pill from 'shared/components/Pill'
import { List, RecordOf } from 'immutable'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import qs from 'qs'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import { withStyles } from '@material-ui/core'

type Props = {
  q: string
  suggestion: List<RecordOf<{ value: string; label: string }>>
  filters: any
  classes: any
} & RouteComponentProps

const styles = theme => ({
  hideScrollbar: {
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
})

export class SuggestionContainer extends Component<Props> {
  handleClickAdditionalTerm = (suggestedQuery: string) => () => {
    const { q, ...restOfQueryString } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })
    const additionalTerm =
      suggestedQuery.indexOf(' ') === -1
        ? suggestedQuery
        : `"${suggestedQuery}"`

    const newQ = q ? `${q} ${additionalTerm}` : additionalTerm

    this.props.history.push({
      search: qs.stringify({
        ...restOfQueryString,
        q: newQ,
        filter: this.props.filters && this.props.filters.toJS()
      })
    })
  }

  render() {
    const { q = '', suggestion, classes } = this.props

    return (
      <ul
        className={
          'flex flex-wrap flex-column overflow-x-auto pa0 list ' +
          classes?.hideScrollbar
        }
        style={{ height: 42 }}
        aria-label='Search suggestions'
      >
        {suggestion.map(suggest => {
          const key = suggest.value
          const label = suggest.label
          return (
            q.toLowerCase().indexOf(suggest.value.toLowerCase()) === -1 && (
              <li key={key}>
                <Pill
                  key={key}
                  label={label}
                  onToggle={this.handleClickAdditionalTerm(label)}
                  squareCorner
                  role='button'
                  aria-pressed={false}
                />
              </li>
            )
          )
        })}
      </ul>
    )
  }
}

export const mapStateToProps = state => {
  return {
    q: searchSelectors.getQ(state),
    filters: filterOptionsSelectors.getFilterOptions(state),
    suggestion: searchSelectors.getSuggestion(state)
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(SuggestionContainer))
)
