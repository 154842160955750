import React, { Component, ReactNode } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Button from 'shared/components/Button'
import { injectIntl, defineMessages, IntlShape } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'ConfirmationDialog.Title',
    defaultMessage: 'Confirmation'
  },
  confirm: {
    id: 'ConfirmationDialog.Confirm',
    defaultMessage: 'Confirm'
  },
  cancel: {
    id: 'ConfirmationDialog.Cancel',
    defaultMessage: 'Cancel'
  }
})

type Props = {
  open: boolean
  title?: string
  confirmLabel?: string | ReactNode
  onClose: () => void
  onConfirm: () => void
  intl: IntlShape
  hideActions?: boolean
  disabled?: boolean
}

export class ConfirmationDialog extends Component<Props> {
  handleOnConfirm = () => {
    const { onConfirm, onClose } = this.props
    if (onConfirm) {
      onConfirm()
    }
    onClose()
  }

  render() {
    const {
      title,
      children,
      open,
      onClose,
      confirmLabel,
      intl,
      hideActions,
      disabled
    } = this.props

    return (
      <Dialog onClose={onClose} open={open} fullWidth>
        <DialogTitle>{title || intl.formatMessage(messages.title)}</DialogTitle>
        <DialogContent className='mt3'>{children}</DialogContent>
        {!hideActions && (
          <DialogActions>
            <Button
              autoSize
              disabled={disabled}
              label={confirmLabel || intl.formatMessage(messages.confirm)}
              onClick={this.handleOnConfirm}
            />
            <Button
              autoSize
              secondary
              label={intl.formatMessage(messages.cancel)}
              onClick={onClose}
            />
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

export default injectIntl(ConfirmationDialog)
