import React, { useState } from 'react'
import { Field } from 'redux-form/immutable'
import FileInput from 'shared/components/FileInput'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import DatePicker from 'shared/components/DatePicker'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import { Dialog, DialogContent } from '@material-ui/core'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Text from 'shared/components/Text'

type Props = {
  type: string
  isContractFormDialogOpen: boolean
  onSubmit: () => void
  onCancel: () => void
  handleToggleContractFormDialog: () => void
}

const ContractFormDialog = (props: Props) => {
  const {
    isContractFormDialogOpen,
    onSubmit,
    onCancel,
    handleToggleContractFormDialog
  } = props
  const [isDateInThePast, setIsDateInThePast] = useState<boolean>(false)
  const onChangeDate = e => {
    let value = e
    value.toISOString() < new Date().toISOString()
      ? setIsDateInThePast(true)
      : setIsDateInThePast(false)
  }
  return (
    <Dialog
      open={isContractFormDialogOpen}
      onClose={handleToggleContractFormDialog}
      fullWidth
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>
          <Text>
            <FormattedMessage
              id='SupplierOutreach.ContractFormDialog'
              defaultMessage='Upload New Contract'
            />
          </Text>
        </DialogTitle>
        <DialogContent>
          <Label required htmlFor='file'>
            <FormattedMessage
              id='ContractForm.Document'
              defaultMessage='Document'
            />
          </Label>
          <Field
            name='file'
            component={FileInput}
            accept='.pdf, .doc, .docx'
            className='bg-white'
            required
          />
          <Label required htmlFor='expirationDate'>
            <FormattedMessage
              id='ContractForm.ExpirationDate'
              defaultMessage='Expiration Date'
            />
          </Label>
          <Field
            name='expirationDate'
            component={DatePicker}
            onChange={onChangeDate}
            normalize={value => {
              return value && value.toISOString()
            }}
            required
          />
          {isDateInThePast && (
            <p className='f7 helvetica m0 red mt1 mb0 tl fw4'>
              <FormattedMessage
                id='ContractForm.ExpirationDateMessage'
                defaultMessage='Date selected is in the past'
              />
            </p>
          )}
          <Label>
            <FormattedMessage id='ContractForm.Note' defaultMessage='Note' />
          </Label>
          <Field name='note' component={Input} />
        </DialogContent>
        <DialogActions>
          <Button
            label={
              <FormattedMessage
                id='ContractForm.SubmitButton'
                defaultMessage='Add'
              />
            }
            type='submit'
            autoSize
          />
          &nbsp;
          <Button
            label={<FormattedMessage id='CancelButton' />}
            autoSize
            secondary
            onClick={onCancel}
            className='mr3'
          />
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ContractFormDialog
