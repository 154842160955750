import { Record, List, Map, fromJS } from 'immutable'

const VetRecord = Record({
  id: '',
  vetId: '',
  isPrivate: true,
  name: '',
  description: '',
  users: List(),
  anonymousInvitation: undefined,
  detailedReponseRequested: undefined,
  responseInstructions: '',
  inviteSuppliers: false,
  created: Map(),
  modified: Map(),
  cancelled: Map(),
  closed: Map(),
  statusHistory: List(),
  decision: '',
  budget: '',
  decisionDate: undefined,
  responseDate: undefined,
  challenge: '',
  desiredOutcome: '',
  targetAudience: '',
  deliverables: '',
  productCategory: '',
  productSubcategory: '',
  location: '',
  customLocation: '',
  businessFunction: '',
  criticalCriteria: List(),
  document: List([]),
  links: List(),
  conciergeFields: Map(),
  keywords: List(),
  internalDescription: '',
  plannedCompletionDate: '',
  category: '',
  department: '',
  projectEstimate: '',
  spendType: '',
  spendStart: undefined,
  spendEnd: undefined,
  approvedBudget: 0,
  pricing: Map({}),
  projectBackground: List([]),
  includeBudget: false,
  includePricing: false,
  termsAndConditionsSource: 'Default',
  termsAndConditions: '',
  additions: List([]),
  numberOfInvitedSuppliers: 0,
  numberOfRespondedSuppliers: 0,
  numberOfSuggestedSuppliers: 0,
  supplierFirstSuggestDate: undefined,
  supplierEnabledDate: undefined,
  supplierFirstResponseDate: undefined,
  supplierFirstInviteDate: undefined,
  enableQandA: false
})

const VetRecordFactory = ({
  users,
  projectBackground,
  created,
  modified,
  cancelled,
  closed,
  statusHistory,
  vetDetails = {},
  links,
  keywords,
  conciergeFields,
  ...rest
}) => {
  return new VetRecord({
    ...rest,
    users: List(users),
    created: Map(created),
    modified: Map(modified),
    cancelled: Map(cancelled),
    closed: Map(closed),
    statusHistory: List(statusHistory),
    criticalCriteria: List(vetDetails.criticalCriteria),
    document: fromJS(vetDetails.document),
    anonymousInvitation: vetDetails.anonymousInvitation,
    decision: vetDetails.decision,
    budget: vetDetails.budget,
    decisionDate: vetDetails.decisionDate,
    responseDate: vetDetails.responseDate,
    targetAudience: vetDetails.targetAudience,
    deliverables: vetDetails.deliverables,
    description: vetDetails.description,
    productCategory: vetDetails.productCategory,
    productSubcategory: vetDetails.productSubcategory,
    location: vetDetails.location,
    customLocation: vetDetails.customLocation,
    businessFunction: vetDetails.businessFunction,
    detailedReponseRequested: vetDetails.detailedReponseRequested,
    responseInstructions: vetDetails.responseInstructions,
    includePricing: vetDetails.includePricing,
    pricing: fromJS(vetDetails.pricing),
    inviteSuppliers: vetDetails.inviteSuppliers,
    termsAndConditionsSource: vetDetails.termsAndConditionsSource,
    termsAndConditions: vetDetails.termsAndConditions,
    enableQandA: vetDetails.enableQandA,
    links: fromJS(links),
    conciergeFields: conciergeFields && fromJS(conciergeFields),
    keywords: List(keywords),
    projectBackground: fromJS(projectBackground),
    additions: fromJS(vetDetails.additions)
  })
}

export default VetRecordFactory
