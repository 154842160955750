import React, { Component } from 'react'
import AlertIcon from '@material-ui/icons/ErrorOutline'
import classNames from 'classnames'

const styles = {
  wrapper: 'dt',
  message: 'dtc f6 v-mid pl2'
}

/**
 * Creates an html label with additional styling for an html element passed through props
 */

type Props = {
  /** Indicates the id or name of the element you want to create a label for */
  message: any
  className?: string
}

class Warning extends Component<Props> {
  render() {
    const { message, className } = this.props

    return (
      <div className={classNames(className, styles.wrapper)}>
        <AlertIcon />
        {!Array.isArray(message) ? (
          <div className={styles.message}>{message}</div>
        ) : (
          <div className={styles.message}>
            {message.map((msg, index) => (
              <div key={`err-${index}`}>{msg}</div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

export default Warning
