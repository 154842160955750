import { call, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import searchSelectors from '../../selectors/searchSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import profileSelectors from '../../../SupplierProfile/selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import updateCardSaga from '../../../SupplierProfile/sagas/updateCardSaga'
import Immutable, { fromJS } from 'immutable'

export function* submitSupplierReviewSaga(action) {
  const { ...cardChanges } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  const userId = yield select(sessionSelectors.getUserId)
  const cardId = yield select(searchSelectors.getCardId)
  const supplierId = yield select(searchSelectors.getSupplierId)

  const updateCardSagaResponse = yield call(updateCardSaga, {
    cardId,
    supplierId,
    cardChanges
  })

  const buyerQuestions = yield select(settingsSelectors.getRatingQuestions)

  let cards = yield select(cardsSelectors.getAllCardsBySupplier, supplierId) ||
    fromJS({})
  if (cardId) {
    cards = cards.mergeIn([userId], fromJS(cardChanges))
  } else {
    cards = cards.set(userId, fromJS(updateCardSagaResponse.newCard))
  }
  const cardsWithReviews = cards.filter(card => {
    const responses = card.getIn(['ratingResponses', 'responses'])
    const preferred = card.getIn(['ratingResponses', 'preferred'])
    return (!!responses && responses.size > 0) || !!preferred
  })
  const overallRatings =
    (cardsWithReviews &&
      cardsWithReviews.reduce((result, card) => {
        const cardReview = profileSelectors.calculateCardReview(
          buyerQuestions,
          card
        )
        return result + cardReview
      }, 0)) ||
    0
  const relationshipRating =
    cardsWithReviews && cardsWithReviews.size > 0
      ? Math.round((overallRatings / cardsWithReviews.size) * 2) / 2
      : overallRatings

  return yield { ...updateCardSagaResponse, relationshipRating, supplierId }
}

export default createApiRequestSaga(submitSupplierReviewSaga)
