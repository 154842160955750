import { select, call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import cards from 'shared/utils/api/cards'
import profileSelectors from '../../selectors/profileSelectors'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { fromJS } from 'immutable'

export function* removeTagSaga(action) {
  const { tagsFieldName, tagKey } = action.payload

  const currentSupplierId = yield select(profileSelectors.getDataField, 'id')
  const currentCard = yield select(
    cardsSelectors.getBySupplier,
    currentSupplierId
  )
  const combinedTags = yield select(state =>
    state.getIn(['buyer', 'supplierProfile', 'combinedTags'])
  )
  const userId = yield select(sessionSelectors.getUserId)

  const updatedTags = currentCard
    .deleteIn([tagsFieldName, tagKey])
    .get(tagsFieldName)
    .toList()
    .toJS()

  /* no card, throw an error */
  if (
    !currentCard ||
    currentCard.size === 0 ||
    currentCard.get('placeholderCard') ||
    currentCard.get('id').endsWith('-1')
  ) {
    throw new Error('Try to remove tag without a real card')
  }

  yield call(cards.update, currentCard.get('id'), {
    [tagsFieldName]: updatedTags
  })

  const updateTag = combinedTags.getIn([tagsFieldName, tagKey])
  const removeTag =
    updateTag &&
    !updateTag.get('supplierTagged') &&
    updateTag.get('count') === 1

  return yield {
    combinedTags: removeTag
      ? combinedTags.deleteIn([tagsFieldName, tagKey]).toJS()
      : combinedTags
          .updateIn([tagsFieldName, tagKey], value =>
            !value
              ? value
              : value.merge(
                  fromJS({
                    count: value.get('count') - 1,
                    userTagged: false,
                    colleagues:
                      value.get('colleagues') &&
                      value.get('colleagues').filter(user => user !== userId)
                  })
                )
          )
          .toJS(),
    cardId: currentCard.get('id'),
    tagsFieldName,
    tagKey
  }
}

export default createApiRequestSaga(removeTagSaga)
