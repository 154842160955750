import React, { Component, ReactElement } from 'react'
import SearchContainer from './containers/SearchContainer'

type Props = {
  vetId?: string
  disableSuggest?: boolean
  topSideContent?: ReactElement<HTMLElement>
}
class Search extends Component<Props> {
  render() {
    const { disableSuggest, vetId, topSideContent } = this.props
    return (
      <SearchContainer
        vetId={vetId}
        refKey={vetId || 'buyerSearch'}
        disableSuggest={disableSuggest}
        topSideContent={topSideContent}
      >
        {this.props.children}
      </SearchContainer>
    )
  }
}

export default Search
