import { Component } from 'react'
import scrollTo from 'shared/utils/scrollTo'

type Props = {
  duration?: number
}

class ScrollToTopOnMount extends Component<Props> {
  componentDidMount() {
    const { duration = 100 } = this.props
    scrollTo(null, 0, duration)
  }

  render() {
    return null
  }
}

export default ScrollToTopOnMount
