import { call, put } from 'redux-saga/effects'
import tier2 from 'shared/utils/api/tier2'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { loadTier2SupplierList } from '../../actions'

function* inviteTier2GroupSaga(action) {
  yield call(tier2.inviteTier2ByGroup, { recentPercentile: action.payload })
  yield put(loadTier2SupplierList())
}

export default createApiRequestSaga(inviteTier2GroupSaga)
