import React from 'react'
import { getFormValues } from 'redux-form/immutable'
import { connect } from 'react-redux'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { REGISTER } from '../../actionTypes'
import Button from 'shared/components/Button'
import { getFormSubmitErrors } from 'shared/reducers'
import Warning from 'shared/components/Warning'
import { FormattedMessage } from 'react-intl'
import OrgLogo from 'shared/components/OrgLogo'
import ListItem from 'shared/components/List/ListItem'
import fileToUrl from 'shared/utils/data/fileToUrl'
import UploadedFile from 'shared/models/UploadedFile'

type MatchedOrgUnit = {
  id: string
  supplier: { logo: UploadedFile }
  name: string
  domains: string[]
  noTAndCs?: boolean
  samlRedirectUrl?: string
  alternativeNames: string[]
}

type Props = {
  register: (formValues: any) => void
  formValues: any
  errorMessage: string
  nextPage: () => void
  matchedOrgs: MatchedOrgUnit[]
}

export const RegistrationPage2 = (props: Props) => {
  const handleClickMatchedOrg = (orgUnit: MatchedOrgUnit) => () => {
    if (orgUnit.samlRedirectUrl) {
      window.location.href = orgUnit.samlRedirectUrl
    } else {
      props.register(
        props.formValues
          .set('orgUnitId', orgUnit.id)
          .set('noTAndCs', orgUnit.noTAndCs)
      )
    }
  }
  return (
    <>
      {props.matchedOrgs && props.matchedOrgs.length > 0 && (
        <>
          <p className='f5 fw4 dib mid-gray mt0'>
            <FormattedMessage
              id='RegistrationPage2.selectOrg'
              defaultMessage='Please select which organization you are part of'
            />
          </p>
          {props.matchedOrgs.map(orgUnit => (
            <div
              key={orgUnit.id}
              className='pointer tl'
              onClick={handleClickMatchedOrg(orgUnit)}
            >
              <ListItem
                leftAvatar={
                  <OrgLogo
                    url={fileToUrl(orgUnit.supplier && orgUnit.supplier.logo)}
                    name={orgUnit.name}
                  />
                }
                primaryText={orgUnit.name}
                secondaryText={
                  orgUnit.alternativeNames &&
                  !!orgUnit.alternativeNames.length && (
                    <>
                      <FormattedMessage
                        id='RegistrationPage2.alternativeNamesOrgs'
                        defaultMessage='You may recognize the organization as: '
                      />
                      {orgUnit.alternativeNames &&
                        orgUnit.alternativeNames.join(', ')}
                    </>
                  )
                }
              />
            </div>
          ))}
        </>
      )}
      {props.errorMessage && (
        <Warning className='mt3' message={props.errorMessage} />
      )}
      <Button
        className='mt3'
        secondary
        onClick={props.nextPage}
        label={
          <FormattedMessage
            id='RegistrationPage2.NoneOfTheAbove'
            defaultMessage='None of the above'
          />
        }
      />
    </>
  )
}

const formName = 'accounts/registration'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)
const formValues = getFormValues(formName)

export default connect(
  state => ({
    errorMessage: formSubmitErrorsSelector(state),
    formValues: formValues(state)
  }),
  {
    register: createSubmitFormAction(formName, REGISTER)
  }
)(RegistrationPage2)
