import isFeatureEnabled, { Feature } from './isFeatureEnabled'
import sessionSelectors from '../../selectors/sessionSelectors'
import { useSelector } from 'react-redux'

const useIsFeatureEnabled = (feature: Feature) => {
  const user = useSelector(sessionSelectors.getUser)
  return isFeatureEnabled({
    feature,
    userEmail: user.get('email')
  })
}

export default useIsFeatureEnabled
