import withCard from 'shared/utils/withCard'
import ConnectionItem from '../../components/ConnectionItem'
import { connect } from 'react-redux'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { updateCard } from '../../actions'

type Props = {
  userId: string
}

export default withCard(
  connect(
    (state, props: Props) => ({
      canEditRole:
        props.userId && sessionSelectors.getUserId(state) === props.userId
    }),
    {
      onDone: updateCard
    }
  )(ConnectionItem)
)
