import React, { useState } from 'react'
import AddCertificationForm from '../../../Company/components/DiversitySection/AddCertificationForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { ADD_CERTIFICATION } from '../../../Company/actionTypes'
import { formValueSelector } from 'redux-form/immutable'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { reduxForm } from 'redux-form/immutable'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import { FormattedMessage } from 'react-intl'
import { InjectedFormProps } from 'redux-form'
import { CategoryType } from 'shared/models/CertificationCategories'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'

const today = new Date().toISOString()

type Props = {
  category: CategoryType
  subCategory: string
  certAgency: string
  disableCategories: boolean
  editMode: boolean
  hasSuggestion?: boolean
} & InjectedFormProps

const AddCertContainer = (props: Props) => {
  const [openCertDialog, setOpenCertDialog] = useState<boolean>(false)
  const { reset, handleSubmit, hasSuggestion, ...restProps } = props

  const handleSubmitClick = values => {
    handleSubmit(values)
    setOpenCertDialog(false)
  }

  const handleCancelClick = () => {
    reset()
    setOpenCertDialog(false)
  }

  return (
    <>
      <div className='br1 b--light-silver ba pa2 w-50-ns flex justify-between items-center'>
        <Text>
          {hasSuggestion ? (
            <FormattedMessage
              id='AddCertContainer.UploadPolicyWithSuggestion'
              defaultMessage='Or upload a certificate'
            />
          ) : (
            <FormattedMessage
              id='AddCertContainer.UploadPolicy'
              defaultMessage='Upload a certificate'
            />
          )}
        </Text>
        <Button onClick={() => setOpenCertDialog(true)}>
          <FormattedMessage
            id='AddCertContainer.Upload'
            defaultMessage='Upload'
          />
        </Button>
      </div>

      <Dialog open={openCertDialog} onClose={() => setOpenCertDialog(false)}>
        <DialogTitle>
          <FormattedMessage
            id='AddCertContainer.AddCert'
            defaultMessage='Add a certificate'
          />
        </DialogTitle>
        <DialogContent>
          <AddCertificationForm
            reset={handleCancelClick}
            handleSubmit={handleSubmitClick}
            {...restProps}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

const formName = 'supplier/company/addCertification'
const valueSelector = formValueSelector(formName)

type ContainerProps = {
  certCategory?:
    | 'diversity'
    | 'quality'
    | 'security'
    | 'sustainability'
    | 'food'
  certSubCategory?: string
  hasSuggestion?: boolean
}

export default connect(
  (state, props: ContainerProps) => {
    return {
      category: valueSelector(state, 'category'),
      subCategory: valueSelector(state, 'subCategory'),
      certAgency: valueSelector(state, 'certAgency'),
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      subTypes: valueSelector(state, 'subTypes'),
      qualifiedNaics: valueSelector(state, 'qualifiedNaics'),
      certifyingLocationKey: valueSelector(state, 'certifyingLocationKey'),
      primaryNaics: valueSelector(state, 'primaryNaics'),
      initialValues: {
        category: props.certCategory,
        subCategory: props.certSubCategory,
        esgSurveyDate: today
      }
    }
  },
  {
    onSubmit: createSubmitFormAction(formName, ADD_CERTIFICATION)
  }
)(reduxForm({ form: formName, enableReinitialize: true })(AddCertContainer))
