import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import searchSelectors from '../../selectors/searchSelectors'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import LocationSearchContainer from '../LocationSearchContainer'
import FilterCheckboxListContainer from '../FilterCheckboxListContainer'
import Divider from 'shared/components/Divider'
import FilterHOC from '../FilterHOC'
import Text from 'shared/components/Text'
import Switch from 'shared/components/Switch'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { List, Map } from 'immutable'

type Props = {
  isFilteredCountry: boolean
  isFilteredState: boolean
  isFilteredCity: boolean
  country: any
  state: any
  city: any
  showMatchAny: boolean
  onMergeFilterOption: (params: {
    country: any[]
    state: any[]
    city: any[]
  }) => void
  selectedCountry: List<{}>
  selectedState: List<{}>
  selectedCity: List<{}>
  category: string
}

type State = {
  matchAny: boolean
  changed: boolean
  conditions: any
  // conditions: RecordOf<{
  //   country: List<{}>
  //   state: List<{}>
  //   city: List<{}>
  // }>
}

export class FilterLocationContainer extends Component<Props, State> {
  constructor(props) {
    super(props)

    const { selectedCountry, selectedState, selectedCity } = props

    const isOr =
      List.isList(selectedCountry.first()) ||
      List.isList(selectedState.first()) ||
      List.isList(selectedCity.first())

    this.state = {
      changed: false,
      matchAny: isOr,
      conditions: isOr
        ? Map({
            country: selectedCountry.first()
              ? selectedCountry.first()
              : List([]),
            state: selectedState.first() ? selectedState.first() : List([]),
            city: selectedCity.first() ? selectedCity.first() : List([])
          })
        : Map({
            country: List([]),
            state: List([]),
            city: List([])
          })
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { selectedCountry, selectedState, selectedCity } = props
    if (state.matchAny && !state.changed) {
      return {
        conditions: Map({
          country:
            selectedCountry.size === 0 ? List([]) : selectedCountry.first(),
          state: selectedState.size === 0 ? List([]) : selectedState.first(),
          city: selectedCity.size === 0 ? List([]) : selectedCity.first()
        })
      }
    }

    return null
  }

  toggleMatchAny = () => {
    const {
      onMergeFilterOption,
      selectedCountry,
      selectedState,
      selectedCity
    } = this.props
    const { matchAny, conditions } = this.state

    let country, state, city
    if (matchAny) {
      // toggle off
      this.setState({
        matchAny: false,
        changed: false
      })

      country = conditions.get('country')
      state = conditions.get('state')
      city = conditions.get('city')

      if (country.size > 1 || state.size > 1 || city.size > 1) {
        onMergeFilterOption({ country: [], state: [], city: [] })
      } else {
        onMergeFilterOption({ country, state, city })
      }
    } else {
      // toggle on
      country = selectedCountry.size > 0 ? selectedCountry : List([])
      state = selectedState.size > 0 ? selectedState : List([])
      city = selectedCity.size > 0 ? selectedCity : List([])

      this.setState({
        matchAny: true,
        changed: true,
        conditions: Map({
          country,
          state,
          city
        })
      })
    }
  }

  handleOnChange = data => {
    const { conditions } = this.state
    const index = conditions.get(data.key).findIndex(v => data.value === v)

    // if removing, make sure lower level is clear too
    if (index !== -1 && data.key === 'country') {
      this.setState({
        changed: true,
        conditions: conditions
          .updateIn(['state'], c => {
            return c.filter(v => !v.startsWith(data.value))
          })
          .updateIn(['city'], c => {
            return c.filter(v => !v.startsWith(data.value))
          })
          .updateIn([data.key], c => {
            return index !== -1 ? c.delete(index) : c.push(data.value)
          })
      })
    } else if (index !== -1 && data.key === 'state') {
      this.setState({
        changed: true,
        conditions: conditions
          .updateIn(['city'], c => {
            return c.filter(v => !v.startsWith(data.value))
          })
          .updateIn([data.key], c => {
            return index !== -1 ? c.delete(index) : c.push(data.value)
          })
      })
    } else {
      this.setState({
        changed: true,
        conditions: conditions.updateIn([data.key], c =>
          index !== -1 ? c.delete(index) : c.push(data.value)
        )
      })
    }
  }

  handleApplyClick = () => {
    const { onMergeFilterOption } = this.props
    const { conditions } = this.state

    onMergeFilterOption(conditions.map(v => List([v])))

    this.setState({
      changed: false
    })
  }

  render() {
    const {
      showMatchAny,
      isFilteredCountry,
      isFilteredState,
      isFilteredCity,
      country,
      state,
      city,
      category
    } = this.props
    const { matchAny, changed, conditions } = this.state

    return (
      <Fragment>
        {showMatchAny && (
          <div className='flex items-center justify-between mb2'>
            <Text secondary={!matchAny}>
              <FormattedMessage
                id='FilterLocationContainer.matchAny'
                defaultMessage='Matches any of...'
              />
            </Text>
            <Switch
              checked={matchAny}
              onChange={this.toggleMatchAny}
              ariaLabel={`Matches any of selected ${category}`}
            />
          </div>
        )}
        <Fragment>
          <FilterCheckboxListContainer
            filterKey='country'
            list={country}
            selected={
              matchAny &&
              conditions.get('country').size > 0 &&
              conditions.get('country')
            }
            onChange={matchAny ? this.handleOnChange : undefined}
            selectedOnly={!matchAny || isFilteredState || isFilteredCity}
            category={category}
          />

          {isFilteredCountry && state.size > 0 && (
            <Fragment>
              <Divider className='mv1' />
              <div className='pl2'>
                <FilterCheckboxListContainer
                  filterKey='state'
                  list={state}
                  selected={
                    matchAny &&
                    conditions.get('state').size > 0 &&
                    conditions.get('state')
                  }
                  onChange={matchAny ? this.handleOnChange : undefined}
                  selectedOnly={!matchAny || isFilteredCity}
                  category={category}
                />
              </div>
            </Fragment>
          )}

          {((isFilteredState && city.size > 0) ||
            (isFilteredCountry && state.size === 0 && city.size > 0)) && (
            <Fragment>
              <Divider className='mv1' />
              <div className='pl3'>
                <FilterCheckboxListContainer
                  filterKey='city'
                  list={city}
                  selected={
                    matchAny &&
                    conditions.get('city').size > 0 &&
                    conditions.get('city')
                  }
                  onChange={matchAny ? this.handleOnChange : undefined}
                  selectedOnly={!matchAny}
                  category={category}
                />
              </div>
            </Fragment>
          )}
        </Fragment>

        {matchAny && (
          <div className='tr'>
            <Divider className='mt1 mb2' />
            <Button
              label={
                <FormattedMessage
                  id='FilterLocationContainer.apply'
                  defaultMessage='Apply'
                />
              }
              autoSize
              disabled={!changed}
              onClick={this.handleApplyClick}
            />
          </div>
        )}

        {!isFilteredCity && !matchAny && (
          <Fragment>
            <Divider className='mv2' />
            <LocationSearchContainer />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default connect(
  state => {
    const selectedCountry = filterOptionsSelectors.getFilterOption(
      state,
      'country'
    )
    const selectedState = filterOptionsSelectors.getFilterOption(state, 'state')
    const selectedCity = filterOptionsSelectors.getFilterOption(state, 'city')
    return {
      selectedCountry,
      selectedState,
      selectedCity,
      isFilteredCountry: selectedCountry.size > 0,
      isFilteredState: selectedState.size > 0,
      isFilteredCity: selectedCity.size > 0,
      country: searchSelectors.getCountryAggregations(state),
      state: searchSelectors.getStateAggregations(state),
      city: searchSelectors.getCityAggregations(state)
    }
  },
  {
    ...FilterHOC
  }
)(FilterLocationContainer)
