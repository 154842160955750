import React, { Component } from 'react'
import { Field } from 'redux-form/immutable'
import RadioButton from 'shared/components/RadioButton'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'

class PreferredQuestionField extends Component {
  render() {
    return (
      <div>
        <Label noPadding required>
          <FormattedMessage
            id='PreferredQuestionField.DoYouThinkThisShouldBeAPreferredSupplier'
            defaultMessage='Do you think this should be a liked supplier?'
          />
        </Label>
        <Field
          component={RadioButton}
          type='radio'
          name='ratingResponses.preferred'
          label={
            <FormattedMessage
              id='PreferredQuestionField.Yes'
              defaultMessage='Yes'
            />
          }
          value='preferred'
          required
        />
        <Field
          component={RadioButton}
          type='radio'
          className='ml1'
          name='ratingResponses.preferred'
          label={<FormattedMessage id='No' />}
          value='notPreferred'
        />
        <Field
          component={RadioButton}
          type='radio'
          className='ml1'
          name='ratingResponses.preferred'
          label={
            <FormattedMessage
              id='PreferredQuestionField.NoOption'
              defaultMessage='No Opinion'
            />
          }
          value='noOpinion'
        />
      </div>
    )
  }
}

export default PreferredQuestionField
