import { connect } from 'react-redux'
import FindSupplier from '../../components/FindSupplier'
import orgsSelectors from '../../store/selectors/orgsSelectors'
import userEmailSelectors from '../../store/selectors/userEmailSelectors'
import { startCreatingOrg, stopCreatingOrg } from '../../store/actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'

export default connect(
  state => ({
    canAddSupplier: sessionSelectors.userHasRole(state, [
      'orgAdmin',
      'supplierConcierge'
    ]),
    selectedUserEmail: userEmailSelectors.getEmail(state),
    isSearching: orgsSelectors.isFetching(state),
    resultsCount: orgsSelectors.getTotal(state),
    isCreatingOrg: orgsSelectors.isCreatingOrg(state)
  }),
  {
    startCreatingOrg,
    stopCreatingOrg
  }
)(FindSupplier)
