import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import CommunityFormProgressItem from '../../components/CommunityFormProgressItem'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import Link from 'shared/components/Link'
import communitiesPaths from '../../routes/paths'

type Page = {
  id: string
  title: string
  viewed: boolean
  isMissingResponse: boolean
}

type Props = {
  pages: Page[]
  communityId: string
  pageNum: string
}

export const CommunityFormProgress = (props: Props) => (
  <div>
    {props.pages &&
      props.pages.map((page, index) => (
        <Link
          key={page.id}
          to={communitiesPaths.communityForm}
          params={{ communityId: props.communityId, pageNum: '' + (index + 1) }}
        >
          <CommunityFormProgressItem
            title={page.title}
            currentPage={props.pageNum === '' + (index + 1)}
            completed={page.viewed && !page.isMissingResponse}
            missingRequired={page.isMissingResponse}
          />
        </Link>
      ))}
  </div>
)

export default withRouter(
  connect(
    (
      state,
      props: RouteComponentProps<{ communityId: string; pageNum: string }>
    ) => {
      const communityId = props.match.params.communityId
      const pageNum = props.match.params.pageNum

      return {
        communityId,
        pageNum,
        pages: communitiesSelectors.getPagesProgress(state, communityId)
      }
    }
  )(CommunityFormProgress)
)
