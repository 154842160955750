import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import filterOptionsSelectors from '../../../Search/selectors/filterOptionsSelectors'
import VetSupplierList from '../../components/VetSupplierList'
import PageSideSection from 'shared/components/PageSideSection'
import Scrollable from 'shared/components/Scrollable'
import withVetSupplierActions from '../withVetSupplierActions'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import {
  setFilterOptions,
  searchSupplier,
  updateQueryString,
  clearSearchKeyword
} from 'buyer/Search/actions'
import qs from 'qs'
import SelectSuppliersDialog from '../../../shared/components/SelectSuppliersDialog'
import { FormattedMessage } from 'react-intl'
import {
  clearErrorMessage,
  removeContactSendTo,
  updateContactSendTo
} from '../../actions'
import AddVetBuyerSupplierByEmail from '../AddVetBuyerSupplierByEmail'
import FollowUpEmailContainer from '../FollowUpEmailContainer'
import { RecordOf, Map, List } from 'immutable'
import RootState from 'shared/models/RootState'

const SIMILAR_SUPPLIERS_LIMIT = 20
const VetSupplierListWithActions = withVetSupplierActions(VetSupplierList)

type Props = {
  className?: string
  setFilterOptions: (filterOptions: {
    sort: string
    scope: string
    similarSuppliers: string
  }) => void
  updateQueryString: () => void
  clearSearchKeyword: () => void
  vetSuppliers: Map<
    string,
    List<
      RecordOf<{
        vetBS: {
          id: string
          collaboration: { status: string }
        }
      }>
    >
  >
  supplierIds: List<string>
  supplierCount: number
  vetIsReadonly: boolean
  errorMessage: string
  clearErrorMessage: () => void
  hasInvited: boolean
  removeContactSendTo: (arg: {
    vetBuyerSupplierId: string
    userId: string
  }) => void
  updateContactSendTo: (arg: {
    vetBuyerSupplierId: string
    user1: {
      user: string
      primary?: boolean
      secondary?: boolean
    }
    user2?: {
      user: string
      primary?: boolean
      secondary?: boolean
    }
  }) => void
  selectedVetBSId?: string
  onClick?: (vetBuyerSupplierId: string) => void
} & RouteComponentProps<{ vetId: string }>

export class VetSupplierListContainer extends Component<Props> {
  state = {
    openSuppliersDialog: false
  }

  toggleDialog = dialog => {
    this.setState({
      [dialog]: !this.state[dialog]
    })
  }

  searchSimilarSupplier = supplierIds => {
    const {
      setFilterOptions,
      updateQueryString,
      clearSearchKeyword,
      location
    } = this.props
    const {
      scope = 'All',
      similarSuppliers: previousSupplierIds = []
    } = qs.parse(location.search, { ignoreQueryPrefix: true })

    if (previousSupplierIds.toString() !== supplierIds.toString()) {
      setFilterOptions({
        sort: 'Similarity',
        scope,
        similarSuppliers: supplierIds
      })
      clearSearchKeyword()
      updateQueryString()
    }
  }

  handleSimilarSuppliers = () => {
    const { supplierIds } = this.props

    if (supplierIds.size < SIMILAR_SUPPLIERS_LIMIT) {
      this.searchSimilarSupplier(supplierIds.toArray())
    } else {
      this.toggleDialog('openSuppliersDialog')
    }
  }

  render() {
    const {
      history: { push },
      location,
      vetSuppliers,
      className,
      vetIsReadonly,
      errorMessage,
      clearErrorMessage,
      hasInvited,
      removeContactSendTo,
      updateContactSendTo,
      supplierIds,
      supplierCount,
      selectedVetBSId,
      onClick
    } = this.props
    const vetId = this.props.match.params.vetId
    const isOnSearch = location.pathname.includes('search')

    return (
      <Fragment>
        <PageSideSection
          title={
            supplierCount === 0 ? (
              <FormattedMessage
                id='VetSupplierListContainer.Supplier'
                defaultMessage='Supplier'
              />
            ) : (
              <FormattedMessage
                id='VetSupplierListContainer.VetSupplierSize'
                defaultMessage='{vetSuppliersSize, plural, one {Supplier ({vetSuppliersSize})} other {Suppliers ({vetSuppliersSize})}}'
                values={{ vetSuppliersSize: supplierCount }}
              />
            )
          }
          className={className}
        >
          {supplierCount > 0 && (
            <Fragment>
              <Scrollable>
                {/* @ts-ignore */}
                <VetSupplierListWithActions
                  vetId={vetId}
                  vetBuyerSuppliers={vetSuppliers}
                  currentUrl={`${location.pathname}${location.search}`}
                  vetIsReadonly={vetIsReadonly}
                  hasInvited={hasInvited}
                  removeContactSendTo={removeContactSendTo}
                  updateContactSendTo={updateContactSendTo}
                  push={push}
                  selectedVetBSId={selectedVetBSId}
                  onClick={onClick}
                />
              </Scrollable>
              {isOnSearch && (
                <Button
                  className='mt3'
                  label={
                    <FormattedMessage
                      id='VetSupplierListContainer.FindSimilarSuppliers'
                      defaultMessage='Find similar suppliers'
                    />
                  }
                  onClick={this.handleSimilarSuppliers}
                />
              )}
            </Fragment>
          )}
          {hasInvited && <FollowUpEmailContainer />}

          {!vetIsReadonly && <AddVetBuyerSupplierByEmail />}
        </PageSideSection>
        <SelectSuppliersDialog
          supplierIds={supplierIds && supplierIds.toArray()}
          open={this.state.openSuppliersDialog}
          limit={SIMILAR_SUPPLIERS_LIMIT}
          message={
            <FormattedMessage
              id='VetSupplierListContainer.ToOptimizeResult'
              defaultMessage='To optimize the result, please select no more than 20 suppliers to perform similar search.'
            />
          }
          onClose={() => this.toggleDialog('openSuppliersDialog')}
          onContinue={selectedIds => this.searchSimilarSupplier(selectedIds)}
        />
        <Dialog open={!!errorMessage} onClose={clearErrorMessage} fullWidth>
          <DialogTitle>
            <FormattedMessage
              id='VetSupplierListContainer.ErrorMessageCaption'
              defaultMessage='Operation could not be completed'
            />
          </DialogTitle>
          <DialogContent>
            <Text className='mt3'>{errorMessage}</Text>
          </DialogContent>
          <DialogActions>
            <FormattedMessage
              id='VetSupplierListContainer.ErrorMessageClose'
              defaultMessage='Close'
            >
              {label => (
                <Button autoSize label={label} onClick={clearErrorMessage} />
              )}
            </FormattedMessage>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

export default connect(
  (state: RootState) => {
    return {
      errorMessage: currentVetSuppliersSelectors.getErrorMessage(state),
      vetSuppliers: currentVetSuppliersSelectors.getVetSuppliersInGroup(state),
      supplierIds: currentVetSuppliersSelectors.getSupplierIds(state),
      supplierCount: currentVetSuppliersSelectors.getVetSuppliers(state).size,
      similarSuppliers: filterOptionsSelectors.getByKey(
        state,
        'similarSuppliers'
      ),
      vetIsReadonly: currentVetSelectors.isReadonly(state),
      hasInvited: currentVetSuppliersSelectors.hasInvited(state)
    }
  },
  {
    setFilterOptions,
    searchSupplier,
    updateQueryString,
    clearSearchKeyword,
    clearErrorMessage,
    removeContactSendTo,
    updateContactSendTo
  }
)(withRouter(VetSupplierListContainer))
