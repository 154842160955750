import React, { ReactElement, useState, useEffect, ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import TextArea from 'shared/components/TextArea'
import Button from 'shared/components/Button'
import Warning from 'shared/components/Warning'

type Props = {
  label?: ReactElement<FormattedMessage> | string
  description?: ReactElement<FormattedMessage> | string
  fieldName: string
  value: string
  isReadOnly?: boolean
  onUpdate: (value) => void
}

const JsonSettings = (props: Props) => {
  const [value, setValue] = useState<string>('')
  const [error, setError] = useState<string | null>('')

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const { label, description, fieldName, isReadOnly, onUpdate } = props

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    setError(null)
    setValue(value)
  }

  const handleUpdate = () => {
    let json
    try {
      json = JSON.parse(value)

      onUpdate({
        [fieldName]: json
      })
    } catch (err) {
      setError('Invalid JSON format')
    }
  }

  return (
    <>
      {label && <Label>{label}</Label>}
      {description && <Text>{description}</Text>}
      <TextArea
        name={fieldName}
        value={value}
        onChange={handleChange}
        readOnly={isReadOnly}
        height={130}
      />
      {error && <Warning message={error} className='mv2' />}
      <Button
        autoSize
        label={
          <FormattedMessage id='JsonSettings.Update' defaultMessage='Update' />
        }
        onClick={handleUpdate}
        disabled={isReadOnly}
      />
    </>
  )
}

export default JsonSettings
