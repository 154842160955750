import { call, select, put, all } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import routingSelectors from 'shared/selectors/routingSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import job from 'shared/utils/api/job'
import paths from 'jobs/routes'
import { push } from 'connected-react-router'
import parsePath from 'shared/utils/parsePath'
import suppliers from 'shared/utils/api/suppliers'

export const loadSowJobSaga = function*(action) {
  const orgUnitIds = action.payload
  const currentOrgUnit = yield select(orgsSelectors.getCurrentUserOrgUnit)
  const queryString: string = yield select(routingSelectors.getSearch)

  // get org units data to populate the job
  const exportResponse = yield call(suppliers.downloadExportFile, {
    supplierIds: orgUnitIds,
    exportColumns: [
      'name',
      'domain',
      'phone',
      'website',
      'shortDescription',
      'longDescription',
      'offerings',
      'addresses',
      'size',
      'revenue',
      'classificationCodes',
      'yearFounded',
      'parentOrgName',
      'serviceAreas'
    ],
    exportType: 'json'
  })

  const orgsMap = exportResponse
    .map(
      ({
        id,
        longdesc,
        shortdesc,
        phone,
        revenue,
        websiteURL,
        address,
        foundingYear,
        ...restOrg
      }) => {
        return {
          ...restOrg,
          description: shortdesc,
          longDescription: longdesc,
          yearlyRevenue: revenue,
          orgUnitId: id,
          locations: address.map(loc => ({
            address: loc
          })),
          phoneNumber: phone,
          websiteUrl: websiteURL,
          yearFounded: foundingYear
        }
      }
    )
    .reduce((result, org) => {
      result[org.orgUnitId] = org
      return result
    }, {})

  // make sure the order is kept
  const orgsData = orgUnitIds.map(orgUnitId => orgsMap[orgUnitId])

  const jobData = {
    name: `HIL job created on ${new Date().toDateString()}`,
    queryString,
    type: 'hil',
    orgUnitId: currentOrgUnit?.get('id'),
    orgUnitName: currentOrgUnit?.get('name'),
    rows: orgsData
  }

  const response = yield call(job.loadJob, { body: jobData })
  const reserveResponse = yield call(job.reserveJob, response.id)

  // create result record for each row
  const results = yield all(
    orgUnitIds.map((orgUnitId, index) => {
      return call(job.createJobResult, {
        jobId: response.id,
        orgUnitId,
        rowNum: index,
        answers: {},
        ignoreComplete: true
      })
    })
  )

  yield put(push(parsePath(paths.detail, { jobId: response.id })))

  return yield {
    job: reserveResponse,
    results: results || [],
    rows: jobData.rows || []
  }
}

export default createApiRequestSaga(loadSowJobSaga)
