import React from 'react'
import RootState from 'shared/models/RootState'
import usersSelectors from 'shared/selectors/usersSelectors'
import { useSelector } from 'react-redux'

type Props = {
  outreachRequestedContactId: string
  outreachType: string
  supplierName: string
}

const RequestSentToCell = ({
  outreachRequestedContactId,
  outreachType,
  supplierName
}: Props) => {
  const email: string = useSelector((state: RootState) => {
    const user =
      state.getIn(['data', 'users', outreachRequestedContactId]) ||
      usersSelectors.getById(state, outreachRequestedContactId)
    return user?.get('email')
  })

  if (outreachType === 'ExpiringContract' && !outreachRequestedContactId) {
    return <div>{supplierName}</div>
  } else {
    return <div>{email}</div>
  }
}

export default RequestSentToCell
