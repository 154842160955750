import React, { Component } from 'react'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import List from '@material-ui/core/List'
import Avatar from '@material-ui/core/Avatar'
import { FormattedMessage } from 'react-intl'
import Link from 'shared/components/Link'
import paths from '../../../Communities/routes/paths'
import { Map, RecordOf } from 'immutable'
import Community from 'shared/models/Community'

type Props = {
  communities?: Map<string, RecordOf<Community>>
}

class CommunitiesList extends Component<Props> {
  render() {
    const { communities } = this.props

    return (
      <List className='ph3' style={{ width: 360 }}>
        {communities &&
          communities.entrySeq().map(([id, community]) => (
            <ListItem key={id}>
              <Link
                to={paths.community}
                params={{ communityId: id }}
                className='dim'
              >
                <ListItemAvatar>
                  <Avatar
                    src={fileToUrl(community.get('logo'))}
                    alt={community.get('name')}
                    style={{ borderRadius: 0, height: 'auto' }}
                  />
                </ListItemAvatar>
              </Link>
              <ListItemText
                primary={
                  <div className='flex'>
                    <span className='flex-auto'>
                      <Link to={paths.community} params={{ communityId: id }}>
                        {community.get('name')}
                      </Link>
                    </span>
                    {!!community.get('isMember') && (
                      <span className='teal'>
                        <FormattedMessage
                          id='CommunitiesList.member'
                          defaultMessage='Member'
                        />
                      </span>
                    )}
                  </div>
                }
                secondary={community.get('description')}
              />
            </ListItem>
          ))}
      </List>
    )
  }
}

export default CommunitiesList
