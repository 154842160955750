import React, { useMemo, useState, useEffect } from 'react'
import { Field, isSubmitting, formValueSelector } from 'redux-form/immutable'
import { getFormSubmitErrors } from 'shared/reducers'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { CHANGE_PASSWORD } from 'accounts/actionTypes'
import Warning from 'shared/components/Warning'
import Text from 'shared/components/Text'
import formValidators from 'shared/utils/formValidators'
import PasswordRules from 'shared/components/PasswordRules'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import { FormattedMessage } from 'react-intl'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'

type Props = {
  handleSubmit: () => void
  isTealbot: boolean
  userId: string
  orgUnitId: string
  errorMessage: string
  newPassword: string
  invalid: boolean
  submitting: string
}

type IPasswordRules = {
  max?: number
  specialCount?: number
  numericCount?: number
  min?: number
  alphaLowerCount?: number
  alphaUpperCount?: number
  previousUniqueCount?: number
}

const confirmPasswordsValidator = formValidators.createConfirmValidator(
  'newPassword',
  'passwords'
)

export const ChangePassword = (props: Props) => {
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState<
    boolean
  >(false)
  const [
    changedPasswordSuccessfully,
    setChangedPasswordSuccessfully
  ] = useState<boolean>(false)
  const [passwordRules, setPasswordRules] = useState<IPasswordRules>({})

  const passwordRulesValidator = useMemo(
    () => formValidators.createPasswordRulesValidator(passwordRules),
    [passwordRules]
  )

  const {
    handleSubmit,
    newPassword,
    invalid,
    isTealbot,
    orgUnitId,
    submitting,
    errorMessage
  } = props

  useEffect(() => {
    if (!isTealbot && orgUnitId) {
      api
        .get(`${apiRoutes.authService}/passwordRules`, {
          orgUnitId
        })
        .then(rules => {
          setPasswordRules(rules)
        })
    }
  }, [isTealbot, orgUnitId])

  useEffect(() => {
    if (!submitting && !errorMessage) {
      setChangedPasswordSuccessfully(true)
    }
  }, [submitting, errorMessage])

  const toggleDialog = () => {
    setOpenChangePasswordDialog(!openChangePasswordDialog)
    setChangedPasswordSuccessfully(false)
  }

  return (
    <>
      <Button
        label={
          <FormattedMessage
            id='ChangePassword.ChangeMyPassword'
            defaultMessage='Change my password'
          />
        }
        className='mt3 mt0-ns mb3 mb4-ns'
        onClick={toggleDialog}
      />
      <Dialog open={openChangePasswordDialog} disableBackdropClick fullWidth>
        <DialogTitle>
          <FormattedMessage
            id='ChangePassword.ChangeYourPassword'
            defaultMessage='Change your password'
          />
        </DialogTitle>
        <DialogContent className='mt3'>
          {!changedPasswordSuccessfully && (
            <form onSubmit={handleSubmit}>
              <Field
                name='username'
                autoComplete='email'
                component='input'
                style={{ display: 'none' }}
              />
              <div className='db cf'>
                <div className='dib v-top w-100 w-50-ns'>
                  {!isTealbot && (
                    <>
                      <Label
                        required
                        className='db pt0 mb1 f7 fw6'
                        htmlFor='oldPassword'
                      >
                        <FormattedMessage
                          id='ChangePassword.CurrentPassword'
                          defaultMessage='Current password'
                        />
                      </Label>
                      <Field
                        name='oldPassword'
                        type='password'
                        component={Input}
                        required
                      />
                    </>
                  )}

                  <Label required htmlFor='newPassword'>
                    <FormattedMessage
                      id='ChangePassword.NewPassword'
                      defaultMessage='New Password'
                    />
                  </Label>
                  <Field
                    name='newPassword'
                    component={Input}
                    type='password'
                    validate={passwordRulesValidator}
                    required
                  />

                  <Label required htmlFor='confirmNewPassword'>
                    <FormattedMessage
                      id='ChangePassword.ConfirmNewPassword'
                      defaultMessage='Confirm New Password'
                    />
                  </Label>
                  <Field
                    name='confirmNewPassword'
                    component={Input}
                    type='password'
                    validate={confirmPasswordsValidator}
                    required
                    className='mb1'
                  />

                  {errorMessage && (
                    <Warning message={errorMessage} className='mv1' />
                  )}
                </div>
                <div className='dib v-top w-100 mt3 w-50-ns pl4-ns mt0-ns'>
                  <PasswordRules
                    passwordRules={passwordRules}
                    password={newPassword}
                  />
                </div>
              </div>
              <Button
                label={
                  <FormattedMessage
                    id='ChangePassword.Submit'
                    defaultMessage='Submit'
                  />
                }
                type='submit'
                className='mt3 mr3'
                disabled={invalid}
                autoSize
              />
              <Button
                label={<FormattedMessage id='CancelButton' />}
                type='button'
                onClick={toggleDialog}
                secondary
                autoSize
              />
            </form>
          )}

          {changedPasswordSuccessfully && (
            <>
              <Text className='mb3'>
                <FormattedMessage
                  id='ChangePassword.PasswordWasChangedSuccessfully'
                  defaultMessage='Password was changed successfully.'
                />
              </Text>
              <Button label='Close' onClick={toggleDialog} autoSize />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

const formName = 'userProfile/changePassword'
const errorSelector = getFormSubmitErrors(formName)
const submitting = isSubmitting(formName)
const selector = formValueSelector(formName)

export default connectReduxForm(
  (state, props) => {
    const user = usersSelectors.getById(state, props.userId)
    return {
      orgUnitId: sessionSelectors.getOrgUnitId(state),
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      errorMessage: errorSelector(state),
      submitting: submitting(state),
      newPassword: selector(state, 'newPassword'),
      initialValues: {
        userId:
          sessionSelectors.userHasRole(state, 'tealbot') && user.get('id'),
        username: user && user.get('email')
      }
    }
  },
  {
    onSubmit: createSubmitFormAction(formName, CHANGE_PASSWORD)
  },
  {
    form: formName
  },
  ChangePassword
)
