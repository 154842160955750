import { fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { requestSuccess } from 'shared/actionTypes'

const defaultState = fromJS({
  saving: false,
  user: undefined,
  saveError: undefined,
  bulkUsersSelected: fromJS({}),
  bulkErrorMessage: undefined
})

const editUserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.START_EDITING_USER:
      return state.set('user', fromJS(action.payload))
    case actionTypes.CANCEL_EDITING_USER:
      return state.set('user', undefined)

    case actionTypes.SELECT_BULK_USERS:
      return state.set('bulkUsersSelected', fromJS(action.payload))

    case requestSuccess(actionTypes.RESEND_USER_INVITE):
    case requestSuccess(actionTypes.BULK_UPDATE_STATUS):
    case requestSuccess(actionTypes.BULK_DELETE_USERS):
    case requestSuccess(actionTypes.BULK_UPDATE_ROLES):
      return state
        .set('bulkUsersSelected', fromJS({}))
        .set(
          'bulkErrorMessage',
          fromJS(
            action.payload.bulkErrorMessage
              ? action.payload.bulkErrorMessage
              : undefined
          )
        )

    default:
      return state
  }
}

export default editUserReducer
