import { combineReducers } from 'redux-immutable'
import profile from './profileReducer'
import combinedTags from './combinedTagsReducer'
import vetBuyerSuppliers from './vetBuyerSuppliersReducer'
import newContact from './newContactReducer'
import newOutreachContact from './newOutreachContactReducer'
import editContact from './editContactReducer'
import survey from './surveyReducer'

const rootReducer = combineReducers({
  profile,
  combinedTags,
  vetBuyerSuppliers,
  newContact,
  newOutreachContact,
  editContact,
  survey
})

export default rootReducer
