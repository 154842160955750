import React from 'react'
import Link from 'shared/components/Link'

const styles = {
  wrapper: 'dt-ns w-100 ba b--light-gray br3 pa3 tl',
  logoWrapper: 'dtc-ns w4 w3-ns v-mid-ns pl3 pl0-ns pt2 pt0-ns pb3 pb0-ns',
  rightWrapper: 'dtc v-top pl3',
  name: 'fw6',
  description: 'pv2 f6'
}

type Props = {
  name: string
  description: string
  surveyUrl?: string
}

const ESGSurveyMessageSummary = (props: Props) => {
  const withLink = node => {
    const { surveyUrl } = props

    return surveyUrl ? (
      <Link to={surveyUrl}>{node}</Link>
    ) : (
      <div className='near-black'>{node}</div>
    )
  }
  const { name, description } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.rightWrapper}>
        {withLink(
          <div className='dib'>
            <div className={styles.name}>{name}</div>
            <div className={styles.description}>{description}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ESGSurveyMessageSummary
