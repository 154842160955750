import VetAdditions from '../../components/VetAdditions'
import { connect } from 'react-redux'
import { addVetAddition, cancelVetAddition } from '../../actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import RootState from 'shared/models/RootState'

export default connect(
  (state: RootState) => ({
    vetId: currentVetSelectors.getVetField(state, 'id'),
    additions: currentVetSelectors.getAdditions(state),
    canModifyAdditions:
      !currentVetSelectors.isReadonly(state) &&
      ['In Progress', 'Sent'].includes(
        currentVetSelectors.getVetField(state, 'decision')
      ) &&
      (currentVetSelectors.currentUserIsOwner(state) ||
        sessionSelectors.userHasRole(state, 'buyerVetApprover')),
    allowWordDocs: settingsSelectors.getAllowWordDocs(state)
  }),
  {
    addVetAddition,
    cancelVetAddition
  }
)(VetAdditions)
