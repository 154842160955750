import React, { useState, ChangeEvent } from 'react'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import Checkbox from '@material-ui/core/Checkbox'
import withStyles from '@material-ui/styles/withStyles'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettings } from '../../actions'
import RootState from 'shared/models/RootState'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { List } from 'immutable'
import ConciergePartnersSetting from '../ConciergePartnersSetting'
import PasswordRulesSetting from '../PasswordRulesSetting'

const StyleCheckbox = withStyles({
  root: {
    padding: 3
  }
})(Checkbox)

const AppConfigSecurity = () => {
  const dispatch = useDispatch()
  const isReadonly = useSelector(
    (state: RootState) => !!state.get('session').get('partnerConcierge')
  )
  const anonymous = useSelector(settingsSelectors.getAnonymous)
  const publicProfile = useSelector(settingsSelectors.publicProfile)
  const allowWordDocs = useSelector(settingsSelectors.getAllowWordDocs)
  const hideChat = useSelector(
    (state: RootState) => !!settingsSelectors.getSetting(state, 'hideChat')
  )
  const isIntegrationPartner = useSelector(
    (state: RootState) =>
      !!settingsSelectors.getSetting(state, 'integrationPartner')
  )
  const defaultRoles: List<string> = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'defaultRoles')
  )
  const inactiveLogoutTimeout:
    | number
    | undefined = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'inactiveLogoutTimeout')
  )

  const [inactiveTimeout, setInactiveTimeout] = useState<string | undefined>(
    () => inactiveLogoutTimeout?.toString()
  )

  const handleUpdateSettings = (settings: { [key: string]: any }) => {
    dispatch(updateSettings(settings))
  }

  const handleTimeoutChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!Number.isNaN(parseInt(e.currentTarget.value))) {
      setInactiveTimeout(e.currentTarget.value)
      dispatch(
        updateSettings({ inactiveLogoutTimeout: Number(e.currentTarget.value) })
      )
    }
  }

  const handleOptionsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value } = e.currentTarget
    if (name === 'defaultRoles') {
      const index = defaultRoles?.findIndex(role => role === value)
      if (index !== undefined) {
        const newDefaultRoles =
          index === -1
            ? defaultRoles.push(value).toJS()
            : defaultRoles.splice(index, 1).toJS()

        handleUpdateSettings({
          defaultRoles: newDefaultRoles
        })
      }
    } else {
      handleUpdateSettings({
        [name]: checked
      })
    }
  }

  return (
    <>
      <Label noPadding>
        <FormattedMessage id='Options' defaultMessage='Options' />
      </Label>
      <FormControlLabel
        label={
          <FormattedMessage
            id='AppConfigSecurity.ShareSuppliers'
            defaultMessage='Share your company name with suppliers'
          />
        }
        control={
          <StyleCheckbox
            color='primary'
            size='small'
            name='publicProfile'
            checked={publicProfile}
            onChange={handleOptionsChange}
            disabled={isReadonly}
          />
        }
      />
      <br />
      <FormControlLabel
        label={
          <FormattedMessage
            id='AppConfigSecurity.ShareName'
            defaultMessage='Share your company name with other clients'
          />
        }
        control={
          <StyleCheckbox
            color='primary'
            size='small'
            name='anonymous'
            checked={!anonymous}
            onChange={handleOptionsChange}
            disabled={isReadonly}
          />
        }
      />
      <br />
      <FormControlLabel
        label={
          <FormattedMessage
            id='AppConfigSecurity.AllowWordDoc'
            defaultMessage='Allow Word Document Attachments: .doc, .docx'
          />
        }
        control={
          <StyleCheckbox
            color='primary'
            size='small'
            name='allowWordDocs'
            checked={allowWordDocs}
            onChange={handleOptionsChange}
            disabled={isReadonly}
          />
        }
      />
      <br />
      <FormControlLabel
        label={
          <FormattedMessage
            id='AppConfigSecurity.HideChatbot'
            defaultMessage={`Hide Chatbot`}
          />
        }
        control={
          <StyleCheckbox
            color='primary'
            size='small'
            name='hideChat'
            checked={hideChat}
            onChange={handleOptionsChange}
            disabled={isReadonly}
          />
        }
      />
      <Label>
        <FormattedMessage
          id='AppConfigSecurity.defaultRoles'
          defaultMessage='Default Roles'
        />
      </Label>
      <FormControlLabel
        label={
          <FormattedMessage
            id='AppConfigSecurity.Buyer'
            defaultMessage={`Buyer`}
          />
        }
        control={
          <StyleCheckbox
            color='primary'
            size='small'
            name='defaultRoles'
            value='buyer'
            checked={defaultRoles?.includes('buyer')}
            onChange={handleOptionsChange}
            disabled={isReadonly}
          />
        }
      />
      <FormControlLabel
        label={
          <FormattedMessage
            id='AppConfigSecurity.BuyerVetApprover'
            defaultMessage={`Buyer Vet Approver`}
          />
        }
        control={
          <StyleCheckbox
            color='primary'
            size='small'
            name='defaultRoles'
            value='buyerVetApprover'
            checked={defaultRoles?.includes('buyerVetApprover')}
            onChange={handleOptionsChange}
            disabled={isReadonly}
          />
        }
      />
      <FormControlLabel
        label={
          <FormattedMessage
            id='AppConfigSecurity.BuyerAdmin'
            defaultMessage={`Buyer Admin`}
          />
        }
        control={
          <StyleCheckbox
            color='primary'
            size='small'
            name='defaultRoles'
            value='buyerAdmin'
            checked={defaultRoles?.includes('buyerAdmin')}
            onChange={handleOptionsChange}
            disabled={isReadonly}
          />
        }
      />
      <FormControlLabel
        label={
          <FormattedMessage
            id='AppConfigSecurity.BuyerDataAdmin'
            defaultMessage={`Buyer Data Admin`}
          />
        }
        control={
          <StyleCheckbox
            color='primary'
            size='small'
            name='defaultRoles'
            value='buyerDataAdmin'
            checked={defaultRoles?.includes('buyerDataAdmin')}
            onChange={handleOptionsChange}
            disabled={isReadonly}
          />
        }
      />
      <FormControlLabel
        label={
          <FormattedMessage
            id='AppConfigSecurity.Supplier'
            defaultMessage={`Supplier`}
          />
        }
        control={
          <StyleCheckbox
            color='primary'
            size='small'
            name='defaultRoles'
            value='supplier'
            checked={defaultRoles?.includes('supplier')}
            onChange={handleOptionsChange}
            disabled={isReadonly}
          />
        }
      />
      <FormControlLabel
        label={
          <FormattedMessage
            id='AppConfigSecurity.SupplierAdmin'
            defaultMessage={`Supplier Admin`}
          />
        }
        control={
          <StyleCheckbox
            color='primary'
            size='small'
            name='defaultRoles'
            value='supplierAdmin'
            checked={defaultRoles?.includes('supplierAdmin')}
            onChange={handleOptionsChange}
            disabled={isReadonly}
          />
        }
      />
      <div className='w-100 w-50-ns dib v-top pr2-ns'>
        <Label htmlFor='inactiveTimeout'>
          <FormattedMessage
            id='AppConfigSecurity.InactiveLogoutTimeout'
            defaultMessage='Inactive Logout Timeout (minutes)'
          />
        </Label>
        <Input
          type='number'
          value={inactiveTimeout}
          onChange={handleTimeoutChange}
          name='inactiveTimeout'
        />
        <div className='mt3'>
          <PasswordRulesSetting />
        </div>
      </div>
      <div className='w-100 w-50-ns dib v-top pl2-ns mt3 mt0-ns'>
        {!isReadonly && <ConciergePartnersSetting />}
        {isIntegrationPartner && (
          <div className='mt3'>
            <PasswordRulesSetting
              fieldName='sharedSecretRules'
              label={
                <FormattedMessage
                  id='AppConfigSecurity.SharedSecretRules'
                  defaultMessage='Shared Secret Rules'
                />
              }
            />
          </div>
        )}
      </div>
    </>
  )
}

export default AppConfigSecurity
