import React, { Component } from 'react'
import CompanyLocation from 'shared/components/CompanyLocation'
import CompanyLocationEditForm from '../../components/CompanyLocationEditForm'
import Button from 'shared/components/Button'
import Grid from '@material-ui/core/Grid'
import { reduxForm } from 'redux-form/immutable'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import companySelectors from '../../../shared/selectors/companySelectors'
import { saveCompanyInfo } from '../../actions'
import Scrollable from 'shared/components/Scrollable'

const CompanyLocationEditFormContainer = reduxForm({
  form: 'supplier/company/locationEdit'
})(CompanyLocationEditForm)

class Locations extends Component {
  state = {
    editLocationIndex: null
  }

  handleRemoveLocation = locationIndex => {
    const { saveCompanyInfo, locations } = this.props

    saveCompanyInfo({
      supplier: {
        locations: locations.remove(locationIndex).toJS()
      }
    })
  }

  handleEditClick = (location, locationIndex) => {
    this.setState({
      editLocationIndex: locationIndex
    })
  }

  handleSubmit = formValues => {
    const { saveCompanyInfo, locations } = this.props
    const { editLocationIndex } = this.state
    const { isPrimary, address, description, phone } = formValues.toJS()

    const editedLocationValues = {
      address,
      description,
      phone
    }

    saveCompanyInfo({
      supplier: {
        locations: isPrimary
          ? locations
              .remove(editLocationIndex)
              .unshift(editedLocationValues)
              .toJS()
          : locations.set(editLocationIndex, editedLocationValues).toJS()
      }
    })

    this.setState({
      editLocationIndex: null
    })
  }

  handleCancelEdit = () => {
    this.setState({
      editLocationIndex: null
    })
  }

  render() {
    const { locations } = this.props

    const { editLocationIndex } = this.state

    if (!locations || locations.size < 1) {
      return null
    }

    return (
      <div className='pa2'>
        <Scrollable>
          <Grid container>
            {locations.map((location, index) => (
              <Grid key={index} item xs={12} sm={6} className='pa2'>
                {editLocationIndex !== index && (
                  <div className='pa2'>
                    <CompanyLocation
                      address={location.get('address')}
                      description={location.get('description')}
                      phone={location.get('phone')}
                      name={location.getIn(['components', 'house'])}
                      poBox={location.getIn(['components', 'po_box'])}
                      houseNumber={location.getIn([
                        'components',
                        'house_number'
                      ])}
                      level={location.getIn(['components', 'level'])}
                      unit={location.getIn(['components', 'unit'])}
                      road={location.getIn(['components', 'road'])}
                      suburb={location.getIn(['components', 'suburb'])}
                      city={location.getIn(['components', 'city'])}
                      stateDistrict={location.getIn([
                        'components',
                        'state_district'
                      ])}
                      state={
                        location.getIn(['components', 'state']) ||
                        location.getIn(['components', 'stateAbbrev'])
                      }
                      postalCode={location.getIn(['components', 'postcode'])}
                      country={location.getIn(['components', 'country'])}
                      isPrimary={index === 0}
                      lat={location.getIn(['components', 'lat'])}
                      long={location.getIn(['components', 'long'])}
                    />

                    <Button
                      label={
                        <FormattedMessage
                          id='Locations.EditButton'
                          defaultMessage='Edit'
                        />
                      }
                      autoSize
                      size='small'
                      secondary
                      onClick={() => this.handleEditClick(location, index)}
                      className='mr3 mt2'
                    />

                    <Button
                      caution
                      size='small'
                      className='mt2'
                      autoSize
                      onClick={() => this.handleRemoveLocation(index)}
                    >
                      <FormattedMessage
                        id='Locations.RemoveButton'
                        defaultMessage='Remove'
                      />
                    </Button>
                  </div>
                )}

                {editLocationIndex === index && (
                  <CompanyLocationEditFormContainer
                    onCancel={this.handleCancelEdit}
                    onSubmit={this.handleSubmit}
                    initialValues={location}
                    isPrimary={index === 0}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Scrollable>
      </div>
    )
  }
}

export default connect(
  state => ({
    locations: companySelectors.getCompanySupplierField(state, 'locations')
  }),
  {
    saveCompanyInfo
  }
)(Locations)
