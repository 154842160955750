import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  ReactElement
} from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { saveSurveyResponse } from '../../actions'
import RootState from 'shared/models/RootState'
import surveySelectors from '../../selectors/surveySelectors'
import Creatable from 'react-select/creatable'
import Text from 'shared/components/Text'

export const placeholderAndTextMessages = defineMessages({
  placeholderFrequency: {
    id: 'DropdownQuestion.selectFrequency',
    defaultMessage: 'Select frequency or type a new one'
  },
  placeholderTitle: {
    id: 'DropdownQuestion.selectTitle',
    defaultMessage: 'Select title or type a new one'
  }
})

export const dropdownMessages = defineMessages({
  annually: {
    id: 'DropdownQuestion.annually',
    defaultMessage: 'Annually'
  },
  semiAnnually: {
    id: 'DropdownQuestion.semiAnnually',
    defaultMessage: 'Semi-annually'
  },
  monthly: {
    id: 'DropdownQuestion.monthly',
    defaultMessage: 'Monthly'
  },
  complianceManager: {
    id: 'DropdownQuestion.complianceManager',
    defaultMessage: 'Compliance Manager'
  },
  businessComplianceOfficer: {
    id: 'DropdownQuestion.businessComplianceOfficer',
    defaultMessage: 'Business Compliance Officer'
  },
  complianceOfficer: {
    id: 'DropdownQuestion.complianceOfficer',
    defaultMessage: 'Compliance Officer'
  },
  complianceDirector: {
    id: 'DropdownQuestion.complianceDirector',
    defaultMessage: 'Director of Compliance'
  },
  seniorComplianceOfficer: {
    id: 'DropdownQuestion.seniorComplianceOfficer',
    defaultMessage: 'Senior Compliance Officer'
  },
  privacyManager: {
    id: 'DropdownQuestion.privacyManager',
    defaultMessage: 'Privacy Manager'
  },
  privacyOperations: {
    id: 'DropdownQuestion.privacyOperations',
    defaultMessage: 'Privacy Operations Officer'
  },
  privacyOfficer: {
    id: 'DropdownQuestion.privacyOfficer',
    defaultMessage: 'Privacy Officer'
  },
  chiefPrivacyOfficer: {
    id: 'DropdownQuestion.chiefPrivacyOfficer',
    defaultMessage: 'Chief Privacy Officer'
  },
  privacyDirector: {
    id: 'DropdownQuestion.privacyDirector',
    defaultMessage: 'Director of Privacy'
  },
  seniorPrivacyOfficer: {
    id: 'DropdownQuestion.seniorPrivacyOfficer',
    defaultMessage: 'Senior Privacy Officer'
  }
})

const dropdownStyle = {
  placeholder: base => ({
    ...base,
    fontSize: '0.825rem',
    color: '#C0C0C0'
  }),
  control: base => ({
    ...base,
    fontSize: '0.825rem',
    width: '300px'
  }),
  menuList: base => ({
    ...base,
    fontSize: '0.825rem'
  })
}

export interface Option {
  label: string
  value: number
}

type Props = {
  pageId: string
  parentQuestionId?: string
  questionId: string
  className?: string
  fieldName?: 'answer' | 'reason'
  question?: string | ReactElement<FormattedMessage>
}

const DropdownQuestion = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    pageId,
    parentQuestionId,
    questionId,
    question,
    className,
    fieldName = 'answer'
  } = props

  const [frequency, setFrequency] = useState<Option>()
  const [title, setTitle] = useState<Option>()
  const [reportTo, setReportTo] = useState<Option>()

  const frequencies = useMemo(() => {
    return [
      { label: intl.formatMessage(dropdownMessages.annually), value: 1 },
      { label: intl.formatMessage(dropdownMessages.semiAnnually), value: 2 },
      { label: intl.formatMessage(dropdownMessages.monthly), value: 3 }
    ]
  }, [intl])

  const complianceTitles = useMemo(() => {
    return [
      {
        label: intl.formatMessage(dropdownMessages.complianceManager),
        value: 1
      },
      {
        label: intl.formatMessage(dropdownMessages.businessComplianceOfficer),
        value: 2
      },
      {
        label: intl.formatMessage(dropdownMessages.complianceOfficer),
        value: 3
      }
    ]
  }, [intl])

  const privacyTitles = useMemo(() => {
    return [
      { label: intl.formatMessage(dropdownMessages.privacyManager), value: 1 },
      {
        label: intl.formatMessage(dropdownMessages.privacyOperations),
        value: 2
      },
      { label: intl.formatMessage(dropdownMessages.privacyOfficer), value: 3 }
    ]
  }, [intl])

  const titles = useMemo(() => {
    return parentQuestionId === 'complianceOfficer'
      ? complianceTitles
      : parentQuestionId === 'designatedPrivacy'
      ? privacyTitles
      : []
  }, [parentQuestionId, complianceTitles, privacyTitles])

  const complianceReportToTitles = useMemo(() => {
    return [
      {
        label: intl.formatMessage(dropdownMessages.complianceDirector),
        value: 1
      },
      {
        label: intl.formatMessage(dropdownMessages.seniorComplianceOfficer),
        value: 2
      }
    ]
  }, [intl])

  const privacyReportToTitles = useMemo(() => {
    return [
      {
        label: intl.formatMessage(dropdownMessages.chiefPrivacyOfficer),
        value: 1
      },
      {
        label: intl.formatMessage(dropdownMessages.privacyDirector),
        value: 2
      },
      {
        label: intl.formatMessage(dropdownMessages.seniorPrivacyOfficer),
        value: 3
      }
    ]
  }, [intl])

  const reportToTitles = useMemo(() => {
    return parentQuestionId === 'complianceOfficer'
      ? complianceReportToTitles
      : parentQuestionId === 'designatedPrivacy'
      ? privacyReportToTitles
      : []
  }, [parentQuestionId, complianceReportToTitles, privacyReportToTitles])

  const surveyId = useSelector(surveySelectors.getEsgSurveyId)
  const response = useSelector((state: RootState) => {
    return surveySelectors.getResponseDetailByIds(
      state,
      pageId,
      questionId,
      parentQuestionId
    )
  })

  const searchValue = useCallback((inputValue: string, list: Option[]) => {
    let opt = list.find(option => {
      return option.label === inputValue
    })
    return opt === undefined ? 0 : opt.value
  }, [])

  useEffect(() => {
    if (response) {
      const inputValue = response.get(fieldName)

      if (inputValue) {
        if (questionId === 'howFrequently') {
          setFrequency({
            label: inputValue,
            value: searchValue(inputValue, frequencies)
          })
        } else if (questionId === 'jobTitle') {
          setTitle({
            label: inputValue,
            value: searchValue(inputValue, titles)
          })
        } else if (questionId === 'jobTitleReportTo') {
          setReportTo({
            label: inputValue,
            value: searchValue(inputValue, reportToTitles)
          })
        }
      }
    }
  }, [
    response,
    fieldName,
    questionId,
    frequencies,
    titles,
    reportToTitles,
    searchValue
  ])

  const saveSurvey = (value: string) => {
    dispatch(
      saveSurveyResponse({
        surveyId,
        surveyResponseDetailId: response?.get('id'),
        pageId,
        questionId,
        parentQuestionId,
        [fieldName]: value,
        answerType: 'text'
      })
    )
  }

  const handleFrequencyChange = (e: Option) => {
    setFrequency(e)
    saveSurvey(e.label)
  }

  const handleTitleChange = (e: Option) => {
    setTitle(e)
    saveSurvey(e.label)
  }

  const handleReportToChange = (e: Option) => {
    setReportTo(e)
    saveSurvey(e.label)
  }

  const createLabelFormat = (inputValue: string) => {
    return (
      <FormattedMessage
        id='DropdownQuestion.CreateLabel'
        defaultMessage='Add {hashtag}'
        values={{ hashtag: inputValue }}
      />
    )
  }

  return (
    <div className={className}>
      {question && <Text className='fw6 mb2'>{question}</Text>}
      {questionId === 'howFrequently' && (
        <Creatable
          onChange={handleFrequencyChange}
          options={frequencies}
          value={frequency}
          placeholder={intl.formatMessage(
            placeholderAndTextMessages.placeholderFrequency
          )}
          styles={dropdownStyle}
          aria-label='frequency dropdown'
          formatCreateLabel={createLabelFormat}
        />
      )}
      {questionId === 'jobTitle' && (
        <Creatable
          onChange={handleTitleChange}
          options={titles}
          value={title}
          placeholder={intl.formatMessage(
            placeholderAndTextMessages.placeholderTitle
          )}
          styles={dropdownStyle}
          aria-label='job title dropdown'
          formatCreateLabel={createLabelFormat}
        />
      )}
      {questionId === 'jobTitleReportTo' && (
        <Creatable
          onChange={handleReportToChange}
          options={reportToTitles}
          value={reportTo}
          placeholder={intl.formatMessage(
            placeholderAndTextMessages.placeholderTitle
          )}
          styles={dropdownStyle}
          aria-label='job title to report to dropdown'
          formatCreateLabel={createLabelFormat}
        />
      )}
    </div>
  )
}

export default DropdownQuestion
