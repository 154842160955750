import React, { FC } from 'react'
import { RecordOf } from 'immutable'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import Page from 'shared/components/Page'
import Main from 'shared/components/Layout/Main'
import PageSection from 'shared/components/PageSection'
import Loading from 'shared/components/Loading'
import dateFormat from 'shared/utils/dateFormat'
import classNames from 'classnames'
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  IntlShape
} from 'react-intl'
import camelCase from 'lodash.camelcase'
import { translatedFilterKeys } from 'shared/utils/data/defineMessages'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import User from 'shared/models/User'

const styles = {
  standard: 'f7 fw6 db mb2 tl',
  declined: 'red',
  chosen: 'teal'
}

const pendingMessage = defineMessages({
  pending: {
    id: 'VetOutcomePage.Pending',
    defaultMessage: 'Pending'
  }
})

type Props = {
  isLoading: boolean
  buyerName: string
  status: string
  decisionDate: string
  buyerResponseComment: string
  buyerResponseNextSteps: string
  submittedDate: string
  submittedBy: RecordOf<User>
  intl: IntlShape
}

export const VetOutcomePage: FC<Props> = ({
  status,
  decisionDate,
  isLoading,
  buyerResponseComment,
  buyerResponseNextSteps,
  buyerName,
  submittedDate,
  submittedBy,
  intl
}) => {
  const statusText =
    status === 'Accepted'
      ? intl.formatMessage(pendingMessage.pending)
      : intl.formatMessage(
          translatedFilterKeys.collaborationStatus[camelCase(status)]
        )

  let statusMessage, statusClass
  if (statusText === 'Pending') {
    statusClass = styles.standard
    statusMessage = (
      <FormattedMessage
        id='VetOutcomePage.RFIPendingStatusMessage'
        defaultMessage='Currently, there is a pending decision on the RFI.'
      />
    )
  } else if (statusText === 'Declined' && submittedBy) {
    statusClass = classNames(styles.standard, styles.declined)
    statusMessage = (
      <FormattedMessage
        id='VetOutcomePage.DeclinedStatusMessage'
        defaultMessage='{firstNameValue} {lastNameValue} declined to pursue this opportunity on behalf of your company on {submittedDateValue}'
        values={{
          submittedDateValue: dateFormat(submittedDate),
          lastNameValue: submittedBy.get('lastName'),
          firstNameValue: submittedBy.get('firstName')
        }}
      />
    )
  } else if (statusText === 'Chosen') {
    statusClass = classNames(styles.standard, styles.chosen)
    statusMessage = buyerResponseComment || (
      <FormattedMessage
        id='VetOutcomePage.RFIChosenStatusMessage'
        defaultMessage='Congratulations, your response was chosen to participate in the next stage of this RFI!'
      />
    )
  } else if (statusText === 'Not Chosen') {
    statusClass = classNames(styles.standard, styles.declined)
    statusMessage = buyerResponseComment || (
      <FormattedMessage
        id='VetOutcomePage.NotChosenStatusMessage'
        defaultMessage='Your response was reviewed but it was not selected to proceed to the next stage.'
      />
    )
  }

  let nextStepMessage
  if (buyerName !== 'Anonymous') {
    nextStepMessage = (
      <FormattedMessage
        id='VetOutComePage.NextStepsForSupplierFromBuyer'
        defaultMessage='Next steps from {buyerName}'
        values={{ buyerName }}
      />
    )
  } else {
    nextStepMessage = (
      <FormattedMessage
        id='VetOutComePage.NextStepsForSupplier'
        defaultMessage='Next steps:'
      />
    )
  }

  return !isLoading ? (
    <>
      <ScrollToPosition />
      <Page
        title={
          <FormattedMessage
            id='VetOutcomePage.OutcomeTitle'
            defaultMessage='Outcome'
          />
        }
      >
        <Main>
          <PageSection
            title={
              <FormattedMessage
                id='VetOutcomePage.OutcomeTitle'
                defaultMessage='Outcome'
              />
            }
          >
            {statusText === 'Pending' && decisionDate && (
              <div className='pt3 td w-100'>
                <Label>
                  <FormattedMessage
                    id='VetOutcomePage.ProjectDecisionDateLabel'
                    defaultMessage='Project decision date'
                  />
                </Label>
                <Text>{dateFormat(decisionDate)}</Text>
              </div>
            )}
            <div className='pt3 td w-100'>
              <Label className={statusClass}>{statusText}</Label>
              <Text>{statusMessage}</Text>
            </div>
            {buyerResponseNextSteps && (
              <div className='pt3 td w-100'>
                <Label>{nextStepMessage}</Label>
                <Text>{buyerResponseNextSteps}</Text>
              </div>
            )}
          </PageSection>
        </Main>
      </Page>
    </>
  ) : (
    <Loading />
  )
}

export default injectIntl(VetOutcomePage)
