import supplierPaths from '../../routes/paths'

const basePath = supplierPaths.vets
const vetBasePath = `${basePath}/:vetBuyerSupplierId`

const paths = {
  basePath,
  vet: vetBasePath,
  vetDetails: `${vetBasePath}/details`,
  vetQA: `${vetBasePath}/qanda`,
  vetResponse: `${vetBasePath}/response`,
  vetTerms: `${vetBasePath}/terms`,
  vetOutcome: `${vetBasePath}/outcome`
}

export default paths
