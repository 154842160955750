import { connect } from 'react-redux'
import AttachmentCard from '../../components/AttachmentCard'
import usersSelectors from '../../selectors/usersSelectors'
import RootState from 'shared/models/RootState'

type Props = {
  userId: string
}

const AttachmentCardContainer = connect((state: RootState, props: Props) => {
  const user = usersSelectors.getById(state, props.userId)

  return {
    createdByFirstName: user.get('firstName'),
    createdByLastName: user.get('lastName'),
    createdByProfilePictureUrl: user.get('profilePictureUrl')
  }
})(AttachmentCard)

export default AttachmentCardContainer
