import React, { ChangeEvent, useRef, useState } from 'react'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Scrollable from 'shared/components/Scrollable'

type Props = {
  keywords: Array<string>
  onAdd: (keyword: string) => void
  onDelete: (index: number) => void
  buttonLabel?: string
  placeholder?: string
}

const Keywords = (props: Props) => {
  const { keywords, onAdd, onDelete, placeholder, buttonLabel } = props
  const [input, setInput] = useState<string>('')

  const inputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setInput(e.currentTarget.value)

  const handleAddKeyword = () => {
    if (input) {
      onAdd(input)
      setInput('')
      inputRef.current?.focus()
    }
  }

  const handleRemoveKeyword = (index: number) => {
    onDelete(index)
  }

  return (
    <>
      <Scrollable maxHeight={200}>
        {keywords.map((k, i) => (
          <div key={k} className='flex justify-between mb2 dim'>
            <Text>{k}</Text>
            <IconButton
              aria-label='remove'
              onClick={() => handleRemoveKeyword(i)}
            >
              <ClearIcon fontSize='small' />
            </IconButton>
          </div>
        ))}
      </Scrollable>
      <div className='flex justify-between mt2'>
        <Input
          input={{
            ref: inputRef
          }}
          value={input}
          onChange={handleInputChange}
          wrapperClassName='flex-auto pr3'
          placeholder={placeholder}
        />
        {buttonLabel && (
          <Button onClick={handleAddKeyword}>{buttonLabel}</Button>
        )}
      </div>
    </>
  )
}

export default Keywords
