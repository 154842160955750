const basePath = '/accounts'

const paths = {
  basePath,
  register: `${basePath}/register`,
  registrationComplete: `${basePath}/registration-complete`,
  requestRegistrationComplete: `${basePath}/request-registration-complete`,
  login: `${basePath}/login`,
  createPassword: `${basePath}/create-password`,
  resetPassword: `${basePath}/reset-password`,
  resetPasswordComplete: `${basePath}/reset-password-complete`,
  sso: `${basePath}/sso`,
  loginForScanner: `${basePath}/loginForScanner`
}

export default paths
