import React, { Component } from 'react'
import createSubmitFormAction from '../../utils/createSubmitFormAction'
import { getFormSubmitErrors } from '../../reducers'
import { UPDATE_USER } from '../../actionTypes'
import EditUserProfile from 'myaccount/components/EditUserProfile'
import sharedPaths from '../../routes/paths'
import usersSelectors from '../../selectors/usersSelectors'
import parsePath from '../../utils/parsePath'
import sessionSelectors from '../../selectors/sessionSelectors'
import { loadUser } from '../../actions'
import { getFormValues, reduxForm } from 'redux-form/immutable'
import { History } from 'history'
import { RecordOf, List } from 'immutable'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import { InjectedFormProps } from 'redux-form'
import User from 'shared/models/User'

type Props = {
  errorMessage: string
  pristine: boolean
  profilePictureUrl: string
  isCurrentUser: boolean
  isTealbot: boolean
  isOrgAdmin: boolean
  isBuyerAdmin: boolean
  isSupplierAdmin: boolean
  status: string
  roles: List<string>
  messageDeliver: string
  loadUser: typeof loadUser
  handleSubmit: (formValues: any) => () => void
  updateUser: (formValues: string, { history: any, basePath: string }) => void
  currentUser: RecordOf<User>
  canEditUser: boolean
} & ContainerProps

export class EditUserProfileContainer extends Component<
  Props & InjectedFormProps
> {
  componentDidMount() {
    const { userId, loadUser } = this.props
    loadUser(userId)
  }

  handleCancel = () => {
    const { history, basePath, userId } = this.props
    history.push(
      parsePath(`${basePath}${sharedPaths.userProfile}`, {
        userId
      })
    )
  }

  handleSubmit = formValues => {
    const { updateUser, history, basePath } = this.props

    updateUser(formValues, {
      history,
      basePath
    })
  }

  render() {
    const { handleSubmit, ...rest } = this.props

    return (
      <>
        <EditUserProfile
          {...rest}
          onSubmit={handleSubmit(this.handleSubmit)}
          onCancel={this.handleCancel}
        />
      </>
    )
  }
}

type FormData = RecordOf<{
  options: RecordOf<{
    message: RecordOf<{
      frequency: string
    }>
  }>
}>

const formName = 'shared/editUser'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)
const getFormValuesSelector = getFormValues(formName)

type ContainerProps = {
  userId: string
  history: History
  basePath: string
}
export default connect(
  (state: RootState, props: ContainerProps) => {
    const userId = props.userId
    const user = usersSelectors.getById(state, userId)
    const initialValues = usersSelectors.getUserProfileFormValues(state, userId)
    const formValues = getFormValuesSelector(state) as FormData
    const colleagueIds = usersSelectors.getColleagueIds(state)
    const isColleague = !!colleagueIds && colleagueIds.includes(userId)
    const isCurrentUser = userId === sessionSelectors.getUserId(state)
    const isBuyerAdmin = sessionSelectors.userHasRole(state, 'buyerAdmin')
    const isOrgAdmin = sessionSelectors.userHasRole(state, 'orgAdmin')
    const isSupplierAdmin = sessionSelectors.userHasRole(state, 'supplierAdmin')
    const isTealbot = sessionSelectors.userHasRole(state, 'tealbot')

    return {
      userId,
      language: state.get('i18n').locale,
      errorMessage: formSubmitErrorsSelector(state),
      profilePictureUrl: user.get('profilePictureUrl'),
      isCurrentUser,
      isBuyerAdmin,
      isOrgAdmin,
      isSupplierAdmin,
      isTealbot,
      canEditUser:
        isCurrentUser ||
        (isColleague && (isTealbot || isBuyerAdmin || isSupplierAdmin)),
      firstName: user && user.get('firstName'),
      lastName: user && user.get('lastName'),
      email: user && user.get('email'),
      roles: user && user.get('roles'),
      status: user && user.get('status'),
      messageDeliver:
        formValues && formValues.getIn(['options', 'message', 'frequency']),
      initialValues,
      currentUser: sessionSelectors.getUser(state)
    }
  },
  {
    loadUser,
    updateUser: createSubmitFormAction(formName, UPDATE_USER)
  }
)(
  reduxForm({
    form: formName,
    enableReinitialize: true
  })(EditUserProfileContainer)
)
