import { call } from 'redux-saga/effects'
import createApiRequestSaga from '../../utils/sagas/createApiRequestSaga'
import api from '../../utils/api'
import apiRoutes from '../../routes/apiRoutes'
import { fromJS } from 'immutable'

export function* loadClientFilesSaga(action) {
  const { entity, query } = action.payload

  const clientFiles = yield call(api.get, `${apiRoutes.loader}/${query}`)
  return yield {
    entity,
    data: fromJS({
      [entity]: clientFiles
    })
  }
}

export default createApiRequestSaga(loadClientFilesSaga)
