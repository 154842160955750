import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import dateFormat from 'shared/utils/dateFormat'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import FileSaver from 'file-saver'
import RootState from 'shared/models/RootState'

type Question = { type: string; label: string }

type Props = {
  question: Question | undefined
  response: any //string, Date, object, undefined, number
} & ContainerProps

const ViewAnswer = memo(
  ({ question, response, membershipId, questionId }: Props) => {
    const handleDownload = originalFileName => {
      api
        .download(
          `${apiRoutes.authService}/community/supplier/download/${questionId}/${membershipId}`
        )
        .then(response => response.blob())
        .then(blob => {
          FileSaver.saveAs(blob, `${originalFileName}`)
        })
        .catch(err => console.log('err', err))
    }
    switch (question?.type) {
      case 'date':
        return (
          <div>
            <Label>{question.label}</Label>
            <Text>{response && dateFormat(response)}</Text>
          </div>
        )
      case 'number':
        return (
          <div>
            <Label>{question.label}</Label>
            <Text>{response && parseFloat(response).toLocaleString()}</Text>
          </div>
        )
      case 'boolean':
        return (
          <div>
            <Label>{question.label}</Label>
            <Text>
              {response ? (
                <FormattedMessage id='ViewAnswer.Yes' defaultMessage='Yes' />
              ) : (
                <FormattedMessage id='No' />
              )}
            </Text>
          </div>
        )
      case 'multipleSelect':
        return (
          <div>
            <Label>{question.label}</Label>
            <Text>{response?.join(', ')}</Text>
          </div>
        )
      case 'range':
        const from = response?.from
        const to = response?.to
        return (
          <div>
            <Label>{question.label}</Label>
            <Text>
              {from && parseFloat(from).toLocaleString()} to{' '}
              {to && parseFloat(to).toLocaleString()}
            </Text>
          </div>
        )
      case 'file':
        return (
          <>
            <Label>{question.label}</Label>
            <div className='flex justify-between items-center'>
              <Text>{response?.description}</Text>
              <Button
                autoSize
                className=''
                onClick={() => handleDownload(response?.description)}
              >
                <FormattedMessage
                  id='ViewAnswers.DownloadFile'
                  defaultMessage='Download File'
                />
              </Button>
            </div>
          </>
        )
      default:
        return (
          <div>
            <Label>{question?.label}</Label>
            <Text>{response}</Text>
          </div>
        )
    }
  }
)

type ContainerProps = {
  membershipId: string
  questionId: string
}
const ViewAnswerContainer = ({ membershipId, questionId }: ContainerProps) => {
  const membership = useSelector((state: RootState) =>
    communitiesSelectors.getMembershipById(state, membershipId)
  )?.toJS()
  const question = useSelector(
    (state: RootState) => state.questions.byId[questionId]
  )
  const response = membership?.response.responses[questionId]?.response
  return (
    <ViewAnswer
      membershipId={membershipId}
      questionId={questionId}
      question={question}
      response={response}
    />
  )
}

export default ViewAnswerContainer
