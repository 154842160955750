import { Record, fromJS } from 'immutable'
import { requestSuccess } from 'shared/actionTypes'
import * as actionTypes from '../actionTypes'
import fullNameParser from 'shared/utils/data/fullName/parser'

const InviteUserRecord = Record({
  selectedUserId: '',
  newUser: fromJS({}),
  meta: fromJS({})
})

const createReducer = (actionTypePrefix, inviteUserActionType) => {
  const inviteUserReducer = (state = new InviteUserRecord(), action) => {
    switch (action.type) {
      case `${actionTypePrefix}/${actionTypes.SELECT_USER}`:
        return state.set('selectedUserId', action.payload.get('id'))

      case `${actionTypePrefix}/${actionTypes.SELECT_NEW_USER}`:
        const { ...userInfo } = fullNameParser(action.payload)
        const { meta } = action
        return state
          .set('newUser', fromJS({ ...userInfo }))
          .set('meta', meta ? fromJS(meta) : fromJS({}))

      case `${actionTypePrefix}/${actionTypes.CANCEL_INVITING_USER}`:
      case requestSuccess(inviteUserActionType):
        return new InviteUserRecord()

      default:
        return state
    }
  }

  return inviteUserReducer
}

export default createReducer
