import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import equalsIgnoreOrders from 'shared/utils/equalsIgnoreOrder'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/WarningOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import startCase from 'lodash.startcase'
import Text from 'shared/components/Text'

type Props = {
  filters: {
    countries: string[]
    categories: string[]
    businessUnits: string[]
  }
  filters2?: {
    countries: string[]
    categories: string[]
    businessUnits: string[]
  }
}

const FiltersInfo = (props: Props) => {
  const { filters, filters2 } = props
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const isFiltersSame = useMemo(() => {
    if (filters && filters2) {
      // check if both reports have the same qualification rules

      return (
        equalsIgnoreOrders(filters.countries, filters2.countries) &&
        equalsIgnoreOrders(filters.categories, filters2.categories) &&
        equalsIgnoreOrders(filters.businessUnits, filters2.businessUnits)
      )
    }
    return true
  }, [filters, filters2])

  return (
    <>
      <h5 className='ma0 f5 mid-gray fw6 flex items-center mr3'>
        <FormattedMessage id='FiltersInfo.Filters' defaultMessage='Filters' />
        {isFiltersSame ? (
          <InfoIcon
            aria-label='Filters Info'
            className='dim pointer'
            onClick={() => setIsDialogOpen(true)}
          />
        ) : (
          <WarningIcon
            aria-label='Filters Warning'
            className='dim pointer'
            color='error'
            onClick={() => setIsDialogOpen(true)}
          />
        )}
      </h5>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        fullWidth
      >
        <DialogTitle onClose={() => setIsDialogOpen(false)}>
          <FormattedMessage
            id='FiltersInfo.AppliedFilters'
            defaultMessage='Applied Filters'
          />
        </DialogTitle>
        <DialogContent>
          <div className='mv2'>
            <div className={`dib ${filters2 ? 'w-50' : 'w-100'}`}>
              {Object.entries(filters).map(([key, value]) =>
                value.length ? (
                  <Text>
                    <span className='fw6'>{startCase(key)}:</span>{' '}
                    {value?.join(', ')}
                  </Text>
                ) : null
              )}
            </div>
            {filters2 && (
              <div className='dib w-50'>
                {Object.entries(filters2).map(([key, value]) =>
                  value.length ? (
                    <Text>
                      <span className='fw6'>{startCase(key)}:</span>{' '}
                      {value?.join(', ')}
                    </Text>
                  ) : null
                )}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default FiltersInfo
