import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import { isSubmitting } from 'redux-form/immutable'
import { getFormSubmitErrors } from 'shared/reducers/selectors'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import AddCommentForm from 'shared/components/AddCommentForm'
import { ADD_INTERNAL_COMMENT } from '../../actionTypes'
import sessionSelectors from 'shared/selectors/sessionSelectors'

type Props = {
  profilePictureUrl: string
  inputPlaceholder: string
  errorMessage: string
  isSubmitting: boolean
  className: string
  enablePublicPrivate: boolean
  noSubmitButton: boolean
  disabled: boolean
  firstName: string
  lastName: string
  addButtonLabel: () => void
  onCancel: () => void
  handleSubmit: () => void
}
export class AddInternalComment extends Component<Props> {
  render() {
    const { handleSubmit, ...rest } = this.props

    return <AddCommentForm {...rest} onSubmit={handleSubmit} />
  }
}

const formName = 'buyer/currentVetSupplier/addInternalComment'
const isSubmittingSelector = isSubmitting(formName)
const formErrorSelector = getFormSubmitErrors(formName)

export default connectReduxForm(
  (state, props) => ({
    isSubmitting: isSubmittingSelector(state),
    errorMessage: formErrorSelector(state),
    profilePictureUrl: sessionSelectors.getUser(state).get('profilePictureUrl'),
    firstName: sessionSelectors.getUser(state).get('firstName'),
    lastName: sessionSelectors.getUser(state).get('lastName')
  }),
  {
    onSubmit: createSubmitFormAction(formName, ADD_INTERNAL_COMMENT)
  },
  {
    form: formName
  },
  AddInternalComment
)
