import React, { Component } from 'react'
import { connect } from 'react-redux'
import VetOutcomePage from '../../components/VetOutcomePage'
import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'

export class VetOutcomePageContainer extends Component {
  render() {
    const {
      vet,
      vetBuyerSupplier,
      buyerName,
      isLoading,
      submittedDate,
      submittedBy
    } = this.props
    return vet.size ? (
      <VetOutcomePage
        isLoading={isLoading}
        buyerName={buyerName}
        status={vetBuyerSupplier.getIn(['collaboration', 'status'])}
        decisionDate={vet.getIn(['vetDetails', 'decisionDate'])}
        buyerResponseComment={vetBuyerSupplier.getIn([
          'collaboration',
          'buyerResponseComment'
        ])}
        buyerResponseNextSteps={vetBuyerSupplier.getIn([
          'collaboration',
          'buyerResponseNextSteps'
        ])}
        submittedDate={submittedDate}
        submittedBy={submittedBy}
      />
    ) : null
  }
}

export const mapStateToProps = state => {
  const vetBuyerSupplier = vetBuyerSupplierSelectors.getVetBuyerSupplier(state)
  const submitted =
    vetBuyerSupplier &&
    vetBuyerSupplier.getIn(['collaboration', 'proposal', 'submitted'])

  return {
    vet: vetBuyerSupplierSelectors.getVet(state),
    vetBuyerSupplier,
    buyerName: vetBuyerSupplierSelectors.getVetCompanyName(state),
    submittedDate: submitted && submitted.get('date'),
    submittedBy:
      submitted && usersSelectors.getById(state, submitted.get('user')),
    isLoading: vetBuyerSupplierSelectors.getIsLoadingVet(state)
  }
}

export default connect(mapStateToProps)(VetOutcomePageContainer)
