import React, { useMemo } from 'react'
import Divider from 'shared/components/Divider'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation, useParams } from 'react-router'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import qs from 'qs'

const navigation = [
  ['health-safety', paths.supplierHealthSafety],
  ['environment', paths.supplierEnvironment],
  ['diversity-inclusion', paths.supplierDiversityInclusion],
  ['governance', paths.supplierGovernance],
  ['privacy', paths.supplierPrivacy]
]

type Props = {
  redirectFrom?: string
}

const EsgFooter = ({ redirectFrom }: Props) => {
  const history = useHistory()
  const location = useLocation()
  const params: { supplierId: string } = useParams()
  const { supplierId } = params

  const currentPos = useMemo(() => {
    const index = navigation.findIndex(nav =>
      location.pathname.endsWith(nav[0])
    )
    return index !== -1 ? index : 0
  }, [location.pathname])

  const handleNextClick = () => {
    if (currentPos < 5) {
      history.push(
        `${parsePath(navigation[currentPos + 1][1], { supplierId })}${
          redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''
        }`
      )
    }
  }

  const handlePrevClick = () => {
    if (currentPos > 0) {
      history.push(
        `${parsePath(navigation[currentPos - 1][1], { supplierId })}${
          redirectFrom ? `?${qs.stringify({ redirectFrom })}` : ''
        }`
      )
    }
  }

  return (
    <div className='pa3 mt3 mt3-5-ns mb6'>
      <div className='mw8 center'>
        <Divider className='mv3' />
        <div className='flex justify-between'>
          <Button
            size='large'
            onClick={handlePrevClick}
            disabled={currentPos === 0}
          >
            <FormattedMessage
              id='EsgFooter.Previous'
              defaultMessage='Previous'
            />
          </Button>
          <Button
            size='large'
            onClick={handleNextClick}
            disabled={currentPos === 4}
          >
            <FormattedMessage id='EsgFooter.Next' defaultMessage='Next' />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EsgFooter
