import React, { Component } from 'react'
import { connect } from 'react-redux'
import companySelectors from '../../../../shared/selectors/companySelectors'
import ListItem from 'shared/components/List/ListItem'
import OrgLogo from 'shared/components/OrgLogo'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'

export class SimilarSupplierListItem extends Component {
  render() {
    const { name, logoUrl } = this.props

    return name ? (
      <ListItem
        mini
        leftAvatar={<OrgLogo url={logoUrl} alt={name} />}
        primaryText={name}
      />
    ) : null
  }
}

export default connect((state, props) => {
  const supplier = companySelectors.getOrgUnitById(state, props.supplierId)

  if (!supplier) {
    return {}
  }

  const domains = supplier.get('domains')
  const logo = supplier.getIn(['supplier', 'logo'])

  return {
    name: supplier.get('name'),
    logoUrl:
      (logo && fileToUrl(logo)) ||
      (domains && domains.size > 0
        ? `https://logo.clearbit.com/${domains.first()}`
        : '')
  }
})(SimilarSupplierListItem)
