import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import RootState from 'shared/models/RootState'
import { getUserAccess } from 'shared/selectors/sessionSelectors/sessionSelectors'
import mergeFilters from 'shared/utils/mergeFilters/mergeFilters'

export const getField = (state: RootState, fieldName: string) =>
  state.getIn(['buyer', 'settings', fieldName])
export const getName = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'name'])
export const getSettings = (state: RootState) =>
  state.getIn(['buyer', 'settings'])
export const vetApproval = (state: RootState) =>
  !!state.getIn(['buyer', 'settings', 'vetApproval'])
export const hasConcierge = (state: RootState) =>
  !!state.getIn(['buyer', 'settings', 'hasConcierge'])
export const publicProfile = (state: RootState) =>
  !!state.getIn(['buyer', 'settings', 'publicProfile'])
export const getBuyerType = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'buyerType'])
export const getClientOf = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'clientOf'])
export const getAnonymous = (state: RootState) =>
  !!state.getIn(['buyer', 'settings', 'anonymous'])
export const getCategorization = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'categorization'])
export const getYearEnd = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'yearEnd']) || '12/31'

export const getAllowWordDocs = (state: RootState) =>
  !!state.getIn(['buyer', 'settings', 'allowWordDocs'])

export const getSetting = (state: RootState, key: string) =>
  state.getIn(['buyer', 'settings', key])

const mapCategory = categories => {
  if (!categories || categories.size === 0) {
    return fromJS([])
  }

  return fromJS([categories.first().delete('subCategories')])
    .concat(mapCategory(categories.first().get('subCategories')))
    .concat(mapCategory(categories.rest()))
}
export const getCategoryMap = createSelector(
  (state: RootState) => state.getIn(['buyer', 'settings', 'categories']),
  categories =>
    categories &&
    mapCategory(categories).reduce(
      (result, category) => result.set(category.get('categoryId'), category),
      fromJS({})
    )
)

const emptyOrgFixedFilters = fromJS({})
export const getOrgFixedFilters = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'fixedFilters']) || emptyOrgFixedFilters
export const getOrgFixedFiltersMessage = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'fixedFiltersMessage'])
const getOrgDefaultRelevance = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'defaultRelevance'])

const emptyOrgDefaultFilters = fromJS({})
export const getOrgDefaultFilters = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'defaultFilters']) || emptyOrgDefaultFilters

export const getDefaultFilters = createSelector(
  getOrgDefaultFilters,
  sessionSelectors.getUser,
  getOrgFixedFilters,
  (orgDefaultFilters, user, orgFixedFilters) => {
    const isTealbot = user.get('roles', fromJS([])).includes('tealbot')
    const userDefaultFilters = isTealbot
      ? orgFixedFilters
      : user.get('defaultFilters')

    if (userDefaultFilters) {
      return orgDefaultFilters.merge(userDefaultFilters)
    } else {
      return orgDefaultFilters
    }
  }
)

export const getFixedFilters = createSelector(
  getOrgFixedFilters,
  getUserAccess,
  sessionSelectors.getUser,
  routingSelectors.getPathname,
  state =>
    state.getIn([
      'supplier',
      'company',
      'data',
      'supplier',
      'searchableCommunities'
    ]),
  (
    orgFixedFilters,
    userAccess,
    user,
    pathname: string,
    searchableCommunities
  ) => {
    const userFixedFilters = user.get('fixedFilters') || fromJS({})

    if (pathname.indexOf('supplier/') !== -1 && searchableCommunities) {
      return fromJS({
        communities: [searchableCommunities]
      })
    }

    let fixedFilters = orgFixedFilters.merge(userFixedFilters)
    if (!userAccess.accessNonRelatedSuppliers) {
      fixedFilters = fromJS(
        mergeFilters(fixedFilters.toJS(), {
          attributes: ['someRelationship']
        })
      )
    }

    return fixedFilters
  }
)

export const getFixedFilter = (state: RootState, key) => {
  return getFixedFilters(state).get(key)
}

export const getFixedFiltersMessage = createSelector(
  getOrgFixedFiltersMessage,
  sessionSelectors.getUser,
  (orgFixedFiltersMessage, user: any) => {
    const userFixedFiltersMessage = user.get('fixedFiltersMessage')
    return userFixedFiltersMessage || orgFixedFiltersMessage
  }
)

export const getDefaultRelevance = createSelector(
  getOrgDefaultRelevance,
  sessionSelectors.getUser,
  (orgDefaultRelevance, user: any) => {
    const userDefaultRelevance = user.get('defaultRelevance')
    return userDefaultRelevance || orgDefaultRelevance
  }
)

export const createCustomAttributesListSelector = attr =>
  createSelector(
    (state: RootState) => state.getIn(['buyer', 'settings', attr]),
    attrs =>
      attrs
        .toOrderedMap()
        .sort((attA, attB) => attA.get('order') - attB.get('order'))
        .map((v, k) => v.set('key', k))
        .toList()
  )

export const getFollowerRolesList = createCustomAttributesListSelector(
  'followerRoles'
)

export const getAttachmentTypes = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'attachmentTypes'])

export const getAttachmentTypesList = createCustomAttributesListSelector(
  'attachmentTypes'
)

export const getRelationshipAttributes = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'relationshipAttributes'])

export const getRelationshipAttributesList = createCustomAttributesListSelector(
  'relationshipAttributes'
)

export const getRatingQuestions = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'ratingQuestions'])

export const getReviewOptions = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'reviewOptions'])

export const getLicenseConfig = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'licenseConfig'])

export const getDefaultLicenseType = (state: RootState) =>
  state.getIn(['buyer', 'settings', 'defaultLicenseType']) || 'elite'

export const getSalesConfiguration = createSelector(
  (state: RootState) => state.getIn(['buyer', 'settings']),
  settings => {
    return {
      buyerType: settings?.get('buyerType'),
      categorization: settings?.get('categorization'),
      accountId: settings?.get('accountId'),
      diversityReportEnabled: settings?.get('diversityReportEnabled'),
      integrationPartner: settings?.get('integrationPartner') || false,
      showConcierge: settings?.get('showConcierge'),
      clientOf: settings?.get('clientOf'),
      defaultLicenseType: settings?.get('defaultLicenseType'),
      licenseConfig: settings?.get('licenseConfig'),
      integrationsEnabled: settings?.get('integrationsEnabled')
    }
  }
)

export const getWebhooks = (state: RootState) =>
  state.getIn([
    'buyer',
    'settings',
    'integrationPartnerConfigs',
    'webhookConfig'
  ])
export const getWebhookByKey = (state: RootState, key: string) =>
  state.getIn([
    'buyer',
    'settings',
    'integrationPartnerConfigs',
    'webhookConfig',
    key
  ])

export const getAcceptCertBasedOnReportStartDate = (state: RootState) => {
  return state.getIn(['buyer', 'settings', 'acceptCertBasedOnReportStartDate'])
}
