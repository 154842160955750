import React, { FC } from 'react'
import { connect } from 'react-redux'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import Page from 'shared/components/Page'
import ProfileCompleteness from '../../components/ProfileCompleteness'
import ViewsStatistics from '../ViewsStatistics'
import SearchMap from '../SearchMap'
import Connections from '../Connections'
import TagsEndorsement from '../TagsEndorsement'
import Loading from 'shared/components/Loading'
import statsSelectors from '../../../shared/selectors/statsSelectors'
import { FormattedMessage } from 'react-intl'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import RootState from 'shared/models/RootState'

type Props = {
  isLoading: boolean
  hasStats: boolean
  showConnections: boolean
}

export const InsightPage: FC<Props> = ({
  isLoading,
  hasStats,
  showConnections
}) => {
  return isLoading ? (
    <Loading />
  ) : hasStats ? (
    <Page title='Insight'>
      <ScrollToPosition />
      <Main>
        <ProfileCompleteness />
        <ViewsStatistics />
        <SearchMap />
        <TagsEndorsement
          title={
            <FormattedMessage
              id='InsightPage.CustomerEndorsedOfferingsTitle'
              defaultMessage='Customer Endorsed Offerings'
            />
          }
          subtitle={
            <FormattedMessage
              id='InsightPage.CustomerEndorsedOfferingsSubtitle'
              defaultMessage='These offerings have been endorsed by existing and/or propspective customers.'
            />
          }
          fieldName='offerings'
        />
        <TagsEndorsement
          title={
            <FormattedMessage
              id='InsightPage.CustomerEndorsedDifferentiatorsTitle'
              defaultMessage='Customer Endorsed Differentiators'
            />
          }
          subtitle={
            <FormattedMessage
              id='InsightPage.CustomerEndorsedDifferentiatorsSubTitle'
              defaultMessage='These differentiators have been endorsed by existing and/or propspective customers.'
            />
          }
          fieldName='differentiators'
        />
      </Main>
      {showConnections && (
        <Aside>
          <Connections />
        </Aside>
      )}
    </Page>
  ) : null
}

export default connect((state: RootState) => {
  const existing = statsSelectors.getBuyerExisting(state)
  const preferred = statsSelectors.getBuyerPreferred(state)

  return {
    isLoading: statsSelectors.isLoading(state),
    hasStats: statsSelectors.getStats(state).size > 1,
    showConnections:
      !!statsSelectors.getFollowerCount(state) ||
      !!statsSelectors.getConnectionCount(state) ||
      (existing && !!existing.size) ||
      (preferred && !!preferred.size)
  }
})(InsightPage)
