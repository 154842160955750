import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import ExternalLink from 'shared/components/ExternalLink'
import VideoIcon from '@material-ui/icons/OndemandVideo'
import RssFeedIcon from '@material-ui/icons/RssFeed'

type Props = {
  blogUrl?: string
  videoUrl?: string
}

const ResourceLinks: FunctionComponent<Props> = props => {
  return (
    <>
      {props.videoUrl && (
        <>
          <h3 className='f7 fw6 mid-gray ma0 mb2 mt3'>
            <FormattedMessage id='ResourceLinks.Video' defaultMessage='Video' />
          </h3>
          <ExternalLink href={props.videoUrl}>
            <div className='flex items-center dim'>
              <VideoIcon fontSize='large' />
              <span className='ml2'>
                <FormattedMessage
                  id='ResourceLinks.OpenVideoLink'
                  defaultMessage='Open video'
                />
              </span>
            </div>
          </ExternalLink>
        </>
      )}

      {props.blogUrl && (
        <>
          <h3 className='f7 fw6 mid-gray ma0 mb2 mt3'>
            <FormattedMessage id='ResourceLinks.Blog' defaultMessage='Blog' />
          </h3>
          <ExternalLink href={props.blogUrl}>
            <div className='flex items-center dim'>
              <RssFeedIcon fontSize='large' />
              <span className='ml2'>
                <FormattedMessage
                  id='ResourceLinks.VisitOurBlogText'
                  defaultMessage='Visit our blog'
                />
              </span>
            </div>
          </ExternalLink>
        </>
      )}
    </>
  )
}

export default ResourceLinks
