import React, { Component, Fragment } from 'react'
import InsightPaper from 'shared/components/InsightPaper'
import InsightTitle from 'shared/components/InsightTitle'
import { FormattedMessage } from 'react-intl'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import { List, RecordOf } from 'immutable'

type SearchGraphDataItem = RecordOf<{
  monthYear: string
  numberOfSearches: number
}>
type Props = {
  searchGraphData: List<SearchGraphDataItem>
}

export class NumberOfSearchesGraph extends Component<Props> {
  formatYAxisUnit = (value: number) => value.toLocaleString()

  render() {
    const { searchGraphData } = this.props
    const numberOfSearchesThisMonth =
      searchGraphData &&
      searchGraphData
        .last<SearchGraphDataItem>()
        .get('numberOfSearches')
        .toLocaleString()

    return (
      <>
        <InsightTitle>
          <FormattedMessage
            id='NumberOfSearchesGraph.Searches'
            defaultMessage='Searches'
          />
        </InsightTitle>
        <InsightPaper>
          {searchGraphData && (
            <Fragment>
              <span className='mt3 ml3 f2 dib' style={{ color: '#028383' }}>
                {numberOfSearchesThisMonth}
              </span>
              <span className='ml2 f6 fw4'>
                <FormattedMessage
                  id='NumberOfSearchesGraph.SearchesThisMonth'
                  defaultMessage='Searches This Month'
                />
              </span>
            </Fragment>
          )}

          {searchGraphData.size > 2 && (
            <div className='h5 mt4'>
              <ResponsiveContainer width='95%'>
                <AreaChart data={searchGraphData.skipLast(1).toJS()}>
                  <defs>
                    <linearGradient
                      id='numberOfSearchesColor'
                      x1='0'
                      y1='0'
                      x2='0'
                      y2='1'
                    >
                      <stop stopColor='#025A5A' />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey='monthYear'
                    tickLine={false}
                    padding={{
                      left: 10,
                      right: 17
                    }}
                    axisLine={{
                      stroke: 'black'
                    }}
                    stroke='black'
                    interval={1}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={this.formatYAxisUnit}
                    stroke='black'
                  />
                  <CartesianGrid stroke='#707070' vertical={false} />
                  <Area
                    type='monotone'
                    dataKey='numberOfSearches'
                    name='Number of Searches'
                    stroke='black'
                    strokeWidth={2}
                    fill='url(#numberOfSearchesColor)'
                    dot={{
                      fill: 'white',
                      fillOpacity: 1,
                      stroke: '#028383',
                      strokeWidth: 2,
                      r: 5
                    }}
                  />
                  <Tooltip
                    formatter={(value: number) => value.toLocaleString()}
                    cursor={{ stroke: 'black', strokeWidth: 1 }}
                    isAnimationActive={false}
                    offset={0}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}
        </InsightPaper>
      </>
    )
  }
}

export default NumberOfSearchesGraph
