import React, { ReactNode } from 'react'
import useThemeColors from '../../utils/useThemeColors'

type Props = {
  href: string
  children: ReactNode
  className?: string
  title?: string
  role?: string
}

const ExternalLink = (props: Props) => {
  const colors = useThemeColors()
  const { children, href = '', className, ...rest } = props
  return (
    <a
      {...rest}
      target='_blank'
      rel='noopener noreferrer'
      className={
        className ||
        `${colors.primaryText} ${colors.primaryHover} f7 fw4 no-underline pointer`
      }
      href={href.match(/^https?:\/\//i) ? href : `http://${href}`}
    >
      {children}
    </a>
  )
}

export default ExternalLink
