import { FC, useEffect } from 'react'
import scrollTo from 'shared/utils/scrollTo'

type Props = {
  to?: number
  animate?: boolean
  trigger?: boolean
  strict?: 'up' | 'down'
}

const ScrollToTop: FC<Props> = ({
  to = 0,
  animate = true,
  strict,
  trigger
}) => {
  useEffect(() => {
    if (
      !strict ||
      (strict === 'up' &&
        (document.documentElement.scrollTop || document.body.scrollTop) > to) ||
      (strict === 'down' &&
        (document.documentElement.scrollTop || document.body.scrollTop) < to)
    ) {
      if (animate) {
        try {
          window.scroll({
            top: to,
            behavior: 'smooth'
          })
        } catch (e) {
          scrollTo(undefined, to, 500)
        }
      } else {
        window.scrollTo(0, 0)
      }
    }
  }, [to, animate, strict, trigger])

  return null
}

export default ScrollToTop
