import React, { Component, ReactNode } from 'react'
import TitleText from 'shared/components/TitleText'
import SubTitleText from 'shared/components/SubTitleText'
import TealbookLogoWrapper from '../TealbookLogoWrapper'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import paths from 'accounts/routes/paths'

/**
 * Used for when registration is complete, contains one TitleText and one SubTitleText
 */
type Props = {
  thankYouMessage: string | ReactNode
  subMessage: string | ReactNode
}
class RegistrationComplete extends Component<Props> {
  render() {
    const { thankYouMessage, subMessage } = this.props

    return (
      <div className='mw7 tc ph3 center'>
        <TealbookLogoWrapper />
        <TitleText>{thankYouMessage}.</TitleText>
        <SubTitleText>{subMessage}</SubTitleText>
        <Link to={paths.login}>
          <b className='f5 fw6 dib teal no-underline underline-hover pl1 pointer'>
            <FormattedMessage
              id='RegistrationComplete.Login'
              defaultMessage='Back to Login'
            />
          </b>
        </Link>
      </div>
    )
  }
}

export default RegistrationComplete
