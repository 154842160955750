import React, { Component } from 'react'
import { Field } from 'redux-form/immutable'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import { connect } from 'react-redux'
import createAddForm from 'shared/utils/createAddForm'
import { UPDATE_CONTACT } from '../../actionTypes'
import usersSelectors from 'shared/selectors/usersSelectors'
import Warning from 'shared/components/Warning'
import Button from 'shared/components/Button'
import SelectPublicPrivate from 'shared/components/SelectPublicPrivate'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import { RecordOf } from 'immutable'
import Contact from 'shared/models/Contact'
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth'

type Props = {
  errorMessage: string
  onClickCancel: () => void
  onSubmit: () => void
} & WithWidthProps

export class UpdateContactForm extends Component<Props> {
  render() {
    const { onClickCancel, errorMessage, onSubmit, width } = this.props

    const matchMobile = width && ['xs', 'sm'].includes(width)

    return (
      <form onSubmit={onSubmit}>
        <Label>
          <FormattedMessage
            id='UpdateContactForm.FirstName'
            defaultMessage='First Name'
          />
        </Label>
        <Field component={Input} name='user[firstName]' required readOnly />

        <Label>
          <FormattedMessage
            id='UpdateContactForm.LastName'
            defaultMessage='Last Name'
          />
        </Label>
        <Field component={Input} name='user[lastName]' readOnly />

        <Label>
          <FormattedMessage
            id='UpdateContactForm.Email'
            defaultMessage='Email'
          />
        </Label>
        <Field
          component={Input}
          name='user[email]'
          type='email'
          required
          readOnly
        />

        <Label>
          <FormattedMessage
            id='UpdateContactForm.Phone'
            defaultMessage='Phone'
          />
        </Label>
        <Field component={Input} name='phone' />

        <Label>
          <FormattedMessage
            id='UpdateContactForm.Location'
            defaultMessage='Location'
          />
        </Label>
        <Field component={Input} name='location' />

        <Label>
          <FormattedMessage
            id='UpdateContactForm.WhyWouldThisPersonBeContacted'
            defaultMessage='Why would this person be contacted?'
          />
        </Label>
        <Field component={Input} name='contactFor' />

        <div className='mt3-ns mv3 fixed static-ns bottom-1'>
          <Button
            size={matchMobile ? 'large' : undefined}
            type='submit'
            autoSize
            label={
              <FormattedMessage
                id='UpdateContactForm.Save'
                defaultMessage='Save'
              />
            }
          />
          <Button
            onClick={onClickCancel}
            secondary
            autoSize
            className='mh2'
            size={matchMobile ? 'large' : undefined}
            label={<FormattedMessage id='CancelButton' />}
          />
          <Field
            name='isPrivate'
            component={SelectPublicPrivate}
            className='v-mid ml2-ns ml4'
          />
        </div>

        {errorMessage && <Warning message={errorMessage} className='mt4' />}
      </form>
    )
  }
}

type ContainerProps = {
  contact?: RecordOf<Contact>
  onClickCancel: () => void
}
export default connect((state: RootState, props: ContainerProps) => {
  const { user: userId, ...contact } = (props.contact &&
    props.contact.toJSON()) || { user: '' }
  const user = usersSelectors.getById(state, userId)
  return {
    initialValues: {
      user: user && user.toJS(),
      ...contact
    }
  }
})(
  createAddForm(
    {
      formName: 'buyer/supplierProfile/updateContact',
      submitActionType: UPDATE_CONTACT
    },
    withWidth()(UpdateContactForm)
  )
)
