import CreateCommunityForm from '../../components/CreateCommunityForm'
import { getFormSubmitErrors } from 'shared/reducers'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { CREATE_COMMUNITY } from '../../store/actionTypes'

const formName = 'dataPortal/newCommunity'

const formSubmitErrorsSelector = getFormSubmitErrors(formName)

export default connectReduxForm(
  (state, props) => ({
    errorMessage: formSubmitErrorsSelector(state),
    initialValues: {
      buyers: [{ orgUnitId: props.orgUnitId }],
      isPrivate: true
    }
  }),
  {
    onSubmit: createSubmitFormAction(formName, CREATE_COMMUNITY)
  },
  {
    form: formName
  },
  CreateCommunityForm
)
