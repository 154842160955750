import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'

const ReduxFormMaterialRadioGroup = ({ input, meta, ...rest }) => {
  return (
    <div>
      <RadioGroup
        {...input}
        {...rest}
        onChange={(event, value) => input.onChange(value)}
      />
      {meta.touched && meta.invalid && meta.error && (
        <span className='f7 red'>{meta.error}</span>
      )}
    </div>
  )
}

export default ReduxFormMaterialRadioGroup
