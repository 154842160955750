import React, { useState, FormEvent } from 'react'
import supplierRelationshipsSelectors from '../../../shared/selectors/supplierRelationshipsSelectors'
import Button from 'shared/components/Button'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import connectReduxForm from 'shared/utils/connectReduxForm'
import ValidationForm from './ValidationForm'
import { formValueSelector } from 'redux-form/immutable'
import usersSelectors from 'shared/selectors/usersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import {
  addCertificationValidation,
  removeCertificationValidation
} from '../../actions'
import Popover from '@material-ui/core/Popover'
import Avatar from 'shared/components/Avatar'
import ListItem from 'shared/components/List/ListItem'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import dateFormat from 'shared/utils/dateFormat'
import { RecordOf } from 'immutable'
import User from 'shared/models/User'
import RootState from 'shared/models/RootState'
import CertificationCategories, {
  CategoryType
} from 'shared/models/CertificationCategories'
import useThemeColors from 'shared/utils/useThemeColors'

type ValidationType = {
  category: string
  subCategory: string
  timeStamp: string
  date?: string
  confirmed?: boolean
  certificationNumber?: string
  validationExpires?: string
  note?: string
}
type Props = {
  supplierId: string
  category: CategoryType
  subCategory: string
  timeStamp: string
  certificateNumber: string
  confirmed: boolean
  user: RecordOf<User>
  currentUserId: string
  validation: RecordOf<ValidationType>
  reset: () => void
  handleSubmit: (
    arg: (formValues: any) => void
  ) => (event: FormEvent<HTMLFormElement>) => void | undefined
  removeCertificationValidation: (arg: ValidationType) => void
  addCertificationValidation: (arg: ValidationType) => void
  intl: IntlShape
}

export const ValidationContainer = (props: Props) => {
  const colors = useThemeColors()
  const [validateDialogOpen, setValidateDialogOpen] = useState<boolean>(false)
  const [popover, setPopover] = useState<{
    open: boolean
    anchorEl: any
  }>({
    open: false,
    anchorEl: null
  })
  const {
    validation,
    confirmed,
    user,
    currentUserId,
    category,
    subCategory,
    timeStamp,
    handleSubmit,
    addCertificationValidation,
    removeCertificationValidation
  } = props

  const handleRemoveValidation = () => {
    removeCertificationValidation({
      category: validation.get('category'),
      subCategory: validation.get('subCategory'),
      timeStamp: validation.get('timeStamp'),
      date: validation.get('date')
    })
    togglePopover()
  }

  const handleValidateConfirm = formValues => {
    const {
      confirmed,
      validationExpires,
      certificationNumber,
      note
    } = formValues.toJS()

    const validation = { category, subCategory, timeStamp, confirmed, note }
    if (confirmed) {
      validation['validationExpires'] = validationExpires
      validation['certificationNumber'] = certificationNumber
    }

    addCertificationValidation(validation)
    handleCloseValidateDialog()
  }

  const handleCloseValidateDialog = () => {
    setValidateDialogOpen(false)
  }

  const handleOpenValidateDialog = () => {
    props.reset()
    setValidateDialogOpen(true)
    setPopover({
      ...popover,
      open: false
    })
  }

  const togglePopover = (e?) => {
    setPopover(po => ({
      anchorEl: e ? e.currentTarget : null,
      open: !po.open
    }))
  }

  return (
    <>
      {!validation ? (
        <div
          className={`${colors.primaryText} f7 fw4 dim no-underline pointer`}
          onClick={handleOpenValidateDialog}
        >
          <FormattedMessage
            id='ValidationContainer.verifyThis'
            defaultMessage='Can you verify this?'
          />
        </div>
      ) : validation.get('confirmed') ? (
        <Button
          onClick={togglePopover}
          autoSize
          size='small'
          label={
            <FormattedMessage
              id='ValidationContainer.verified'
              defaultMessage='Verified...'
            />
          }
        />
      ) : (
        <Button
          onClick={togglePopover}
          caution
          autoSize
          label={
            <FormattedMessage
              id='ValidationContainer.invalid'
              defaultMessage='Invalid...'
            />
          }
        />
      )}
      <Dialog
        fullWidth
        open={validateDialogOpen}
        onClose={handleCloseValidateDialog}
      >
        <form onSubmit={handleSubmit(handleValidateConfirm)}>
          <DialogTitle>
            <FormattedMessage
              id='ValidationContainer.dialogCaption'
              defaultMessage='Verify a certification'
            />
          </DialogTitle>
          <DialogContent>
            <ValidationForm
              title={
                CertificationCategories[props.category] &&
                CertificationCategories[props.category].subCategories[
                  props.subCategory
                ]
                  ? props.intl.formatMessage(
                      CertificationCategories[props.category].subCategories[
                        props.subCategory
                      ]
                    )
                  : props.subCategory
              }
              confirmed={confirmed}
            />
          </DialogContent>
          <DialogActions>
            <Button
              label={
                <FormattedMessage
                  id='ValidationContainer.Ok'
                  defaultMessage='OK'
                />
              }
              type='submit'
              autoSize
            />
            <Button
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleCloseValidateDialog}
              secondary
              autoSize
              className='ml3'
            />
          </DialogActions>
        </form>
      </Dialog>
      {validation && (
        <Popover
          open={popover.open}
          anchorEl={popover.anchorEl}
          onClose={togglePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <div style={{ minWidth: 250 }}>
            {user && (
              <ListItem
                className='pa3 pb0'
                mini
                leftAvatar={
                  <Avatar
                    url={user.get('profilePictureUrl')}
                    className='w2'
                    name={`${user.get('firstName') || ''} ${user.get(
                      'lastName'
                    ) || ''}`}
                  />
                }
                primaryText={
                  <span>
                    {user.get('firstName')} {user.get('lastName')}
                  </span>
                }
                secondaryText={
                  <FormattedMessage
                    id='ValidationContainer.userVerifiedDate'
                    defaultMessage={`verified on {date}`}
                    values={{ date: dateFormat(validation.get('date')) }}
                  />
                }
              />
            )}
            <div className='ph3 nmt2'>
              <Label>
                <FormattedMessage
                  id='ValidationContainer.certificationLabel'
                  defaultMessage={`Certification`}
                />
              </Label>
              {validation.get('confirmed') ? (
                <Text>
                  <FormattedMessage
                    id='ValidationContainer.certificationValid'
                    defaultMessage={`Valid`}
                  />
                </Text>
              ) : (
                <Text>
                  <FormattedMessage
                    id='ValidationContainer.certificationInvalid'
                    defaultMessage={`Invalid`}
                  />
                </Text>
              )}

              {validation.get('confirmed') &&
                validation.get('certificationNumber') && (
                  <>
                    <Label>
                      <FormattedMessage
                        id='ValidationContainer.certificationNumberLabel'
                        defaultMessage={`Certificate Number`}
                      />
                    </Label>
                    <Text>{validation.get('certificationNumber')}</Text>
                  </>
                )}

              {validation.get('confirmed') &&
                validation.get('validationExpires') && (
                  <>
                    <Label>
                      <FormattedMessage
                        id='ValidationContainer.validationExpiresLabel'
                        defaultMessage={`Valid Until`}
                      />
                    </Label>
                    <Text>
                      {dateFormat(validation.get('validationExpires'))}
                    </Text>
                  </>
                )}
              <Label>
                <FormattedMessage
                  id='ValidationContainer.noteLabel'
                  defaultMessage={`Note`}
                />
              </Label>
              <Text>{validation.get('note')}</Text>
            </div>
            <div className='ph3 pv2 bt b--light-gray tr mt2'>
              <Button
                onClick={handleOpenValidateDialog}
                autoSize
                label={
                  <FormattedMessage
                    id='ValidationContainer.addNew'
                    defaultMessage='Add New'
                  />
                }
              />
              {currentUserId === user.get('id') && (
                <Button
                  onClick={handleRemoveValidation}
                  autoSize
                  caution
                  className='ml3'
                  label={
                    <FormattedMessage
                      id='ValidationContainer.remove'
                      defaultMessage='Remove'
                    />
                  }
                />
              )}
            </div>
          </div>
        </Popover>
      )}
    </>
  )
}

const formName = 'supplier/certification/validation'
const selector = formValueSelector(formName)

type ContainerProps = {
  supplierId: string
  category: string
  subCategory: string
  timeStamp: string
}

export default connectReduxForm(
  (state: RootState, props: ContainerProps) => {
    const { category, subCategory, timeStamp } = props
    const certificationValidation = supplierRelationshipsSelectors.getCertificationValidation(
      state
    )

    const validations =
      certificationValidation &&
      certificationValidation.get(
        `${category}-${subCategory}-${timeStamp || ''}`
      )
    const validation = validations && validations.first()
    const user =
      validation && usersSelectors.getById(state, validation.get('userId'))
    const currentUserId = sessionSelectors.getUserId(state)

    return {
      confirmed: selector(state, 'confirmed'),
      validation: validations && validations.first(),
      user,
      currentUserId
    }
  },
  {
    addCertificationValidation,
    removeCertificationValidation
  },
  {
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
    initialValues: {
      confirmed: true
    }
  },
  injectIntl(ValidationContainer)
)
