import DialogActions from '@material-ui/core/DialogActions'
import { withStyles } from '@material-ui/core/styles'

export default withStyles({
  root: {
    justifyContent: 'left',
    margin: 24,
    padding: 0
  },
  spacing: {
    '& > :not(:first-child)': {
      marginLeft: 16
    }
  }
})(DialogActions)
