import { connect } from 'react-redux'
import FollowUpEmail from 'buyer/Vets/components/FollowUpEmail/FollowUpEmail'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import RootState from 'shared/models/RootState'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'

export default connect((state: RootState, props) => {
  const currentUser = sessionSelectors.getUser(state)
  const vet = currentVetSelectors.getVet(state)
  return {
    vbsToEmail: currentVetSuppliersSelectors.vbsToEmail(state),
    currentUserEmail: currentUser && currentUser.get('email'),
    vbs: currentVetSuppliersSelectors.getVetSuppliers(state),
    vetResponseDate: vet.get('responseDate'),
    vetName: vet.get('name'),
    vetId: vet.get('vetId')
  }
})(FollowUpEmail)
