import React, { Component, ReactNode, ChangeEvent } from 'react'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Scrollable from 'shared/components/Scrollable'
import toggleArrayItem from 'shared/utils/toggleArrayItem'
import SelectSupplierListItem from './SelectSupplierListItem'
import withOrg from 'shared/utils/withOrg'
import { FormattedMessage } from 'react-intl'

type Props = {
  supplierIds: string[]
  limit: number
  message: string | ReactNode
  open: boolean
  onClose: () => void
  onContinue: (selected: string[]) => void
}

type State = {
  selected: string[]
}

const SelectSupplierListItemWithOrg = withOrg(SelectSupplierListItem)

class SelectSuppliersDialog extends Component<Props, State> {
  state: State = {
    selected: []
  }

  handleContinueClick = () => {
    const { onContinue, onClose } = this.props
    const { selected } = this.state
    onContinue(selected)
    onClose()
  }

  handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      selected: toggleArrayItem(this.state.selected, e.target.value)
    })
  }

  render() {
    const { open = false, limit, message, supplierIds, onClose } = this.props
    const { selected } = this.state

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <FormattedMessage
            id='SelectSupplierDialog.SelectSuppliers'
            defaultMessage='Select suppliers'
          />
        </DialogTitle>
        <DialogContent>
          {message && <Text className='mt3'>{message}</Text>}
          {limit && selected.length > 0 && (
            <Text className='mt3'>
              <FormattedMessage
                id='SelectSupplierDialog.SelectSupplier'
                defaultMessage='Selected {selectedLengthValue} suppliers'
                values={{ selectedLengthValue: selected.length }}
              />
            </Text>
          )}
          <div className='mt1'>
            <Scrollable>
              {supplierIds &&
                supplierIds.map(supplierId => {
                  return (
                    <SelectSupplierListItemWithOrg
                      key={supplierId}
                      orgUnitId={supplierId}
                      checked={selected.includes(supplierId)}
                      disabled={
                        limit > 0 &&
                        limit === selected.length &&
                        !selected.includes(supplierId)
                      }
                      onChange={this.handleCheckboxChange}
                    />
                  )
                })}
            </Scrollable>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            label={
              <FormattedMessage
                id='SelectSuppliersDialog.Continue'
                defaultMessage='Continue'
              />
            }
            autoSize
            onClick={this.handleContinueClick}
          />
          <Button
            label={<FormattedMessage id='CancelButton' />}
            secondary
            autoSize
            onClick={onClose}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export default SelectSuppliersDialog
