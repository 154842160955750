import React, { ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import { List } from 'immutable'

type Props = {
  onChangeInput: (e: ChangeEvent<HTMLInputElement>, name: string) => void
  emailError?: string
  domains?: List<string>
  isConcierge?: boolean
  buyerContact?: {
    firstName: string
    lastName: string
    email: string
  }
  showAddSupplierContactForm?: boolean
  selectedSupplierContact?: {
    title?: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
  }
}
const ContactForm = ({
  onChangeInput,
  emailError,
  domains,
  isConcierge,
  buyerContact,
  showAddSupplierContactForm,
  selectedSupplierContact
}: Props) => {
  const parsedDomains = domains?.map(domain => {
    const parsedDomain = domain.toLowerCase().split('@')
    return parsedDomain[parsedDomain.length - 1]
  })
  return (
    <>
      <Label>
        <FormattedMessage
          id='RequestSupplierRating.FirstName'
          defaultMessage='First Name'
        />
      </Label>
      <Input
        name='firstName'
        required={!selectedSupplierContact?.email && showAddSupplierContactForm}
        onChange={e => onChangeInput(e, 'firstName')}
        value={
          !isConcierge && !showAddSupplierContactForm
            ? buyerContact?.firstName || ''
            : undefined
        }
      />

      <Label>
        <FormattedMessage
          id='RequestSupplierRating.LastName'
          defaultMessage='Last Name'
        />
      </Label>
      <Input
        name='lastName'
        required={!selectedSupplierContact?.email && showAddSupplierContactForm}
        onChange={e => onChangeInput(e, 'lastName')}
        value={
          !isConcierge && !showAddSupplierContactForm
            ? buyerContact?.lastName || ''
            : undefined
        }
      />

      <Label>
        <FormattedMessage
          id='RequestSupplierRating.Email'
          defaultMessage='Email'
        />
      </Label>
      <Input
        name='email'
        required={!selectedSupplierContact?.email && showAddSupplierContactForm}
        type='email'
        onChange={e => onChangeInput(e, 'email')}
        meta={{
          error: !!emailError ? emailError : undefined,
          touched: true
        }}
        value={
          !isConcierge && !showAddSupplierContactForm
            ? buyerContact?.email || ''
            : undefined
        }
      />
      {showAddSupplierContactForm && (
        <>
          <Text className='light-silver i'>
            <FormattedMessage
              id='RequestSupplierRating.ValidEmail'
              defaultMessage='Valid email: {parsedDomains}'
              values={{
                parsedDomains: parsedDomains?.join(', ')
              }}
            />
          </Text>

          <Label>
            <FormattedMessage
              id='RequestSupplierRating.PhoneNumner'
              defaultMessage='Phone Number'
            />
          </Label>
          <Input
            name='phoneNumber'
            onChange={e => onChangeInput(e, 'phoneNumber')}
            required={!selectedSupplierContact?.email}
          />
          <Label>
            <FormattedMessage
              id='RequestSupplierRating.JobTitle'
              defaultMessage='Job Title'
            />
          </Label>
          <Input name='title' onChange={e => onChangeInput(e, 'title')} />
        </>
      )}
    </>
  )
}
export default ContactForm
