import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { List, RecordOf } from 'immutable'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import FileInput from 'shared/components/FileInput'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Table from 'shared/components/Table'
import { Column, TableCellProps } from 'react-virtualized'
import dateFormat from 'shared/utils/dateFormat'
import DeleteIcon from '@material-ui/icons/Clear'
import startCase from 'lodash.startcase'
import Link from 'shared/components/Link'
import parsePath from 'shared/utils/parsePath'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import Warning from 'shared/components/Warning'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import Tooltip from 'shared/components/Tooltip'
import FileSaver from 'file-saver'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import ReviewSpendItems from 'supplier/Insight/components/LoadPage/ReviewSpendItems'
import supplierPaths from '../../../supplier/routes/paths'
import Loading from 'shared/components/Loading'

const messages = defineMessages({
  complete: { id: 'Complete', defaultMessage: 'Complete' },
  processing: { id: 'Processing', defaultMessage: 'Processing' },
  error: { id: 'Error', defaultMessage: 'Error' }
})

export type UploadHistoryType = {
  id: string
  clientFileName: string
  year: number
  quarter: string
  uploadDate: Date
  uploadBy: string
  status: string
}

type Props = {
  isUploading: boolean
  uploadError: boolean
  uploadSpendFile: (payload: { file: File; listType: string }) => void
  removeSpend: (payload: { stagingId: string; listType: string }) => void
  loadStagingHistory: (payload: { listType: string }) => void
  notify: (payload: { message: string }) => void
  uploadHistorySerp: List<RecordOf<UploadHistoryType>>
  isLoading: boolean
}

export const SerpSupplierMatching = (props: Props) => {
  const {
    isUploading,
    uploadError,
    uploadSpendFile,
    removeSpend,
    loadStagingHistory,
    notify,
    uploadHistorySerp,
    isLoading
  } = props

  const intl = useIntl()

  const [uploadFile, setUploadFile] = useState<File | null>(null)
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined)

  const [confirmRemoveStaging, setConfirmRemoveStaging] = useState<string>('')
  const [recomputeTrendsPressed, setRecomputeTrendsPressed] = useState<boolean>(
    false
  )
  const [computeTrendsError, setComputeTrendsError] = useState<boolean>(false)
  const [exportStagingPressed, setExportStagingPressed] = useState<string>('')

  const lastComplete = useMemo(() => {
    return uploadHistorySerp && uploadHistorySerp.size
      ? uploadHistorySerp.findLastIndex(
          history => history.get('status') === 'complete'
        )
      : -1
  }, [uploadHistorySerp])

  const isAnyProcessing = useMemo(() => {
    return (
      !!uploadHistorySerp &&
      uploadHistorySerp.some(history => history.get('status') === 'processing')
    )
  }, [uploadHistorySerp])

  const highlightIndex = useMemo(() => {
    return typeof selectedRow === 'number' ? selectedRow : lastComplete
  }, [selectedRow, lastComplete])

  const handleIntervalCall = useCallback(() => {
    if (isAnyProcessing) {
      loadStagingHistory({ listType: 'serp' })
    }
  }, [isAnyProcessing, loadStagingHistory])

  useEffect(() => {
    if (!isUploading && !uploadError) {
      setUploadFile(null)
    }
  }, [isUploading, uploadError])

  useEffect(() => {
    const interval = global.setInterval(() => {
      handleIntervalCall()
    }, 60000)
    return () => global.clearInterval(interval)
  }, [handleIntervalCall])

  const handleFileUpload = () => {
    if (uploadFile) {
      uploadSpendFile({
        file: uploadFile,
        listType: 'serp'
      })
    }
  }

  const renderActionColumn = ({ cellData, rowData }: TableCellProps) => {
    const fullFileName = rowData.get('fileName')
    const uploadUserId = rowData.get('userId')
    return (
      <div onClick={event => event.stopPropagation()}>
        <Tooltip
          title={
            <FormattedMessage
              id='SerpSupplierMatching.DownloadLoadFile'
              defaultMessage='Click here to download this file, so you can compare with mapping below.'
            />
          }
        >
          {
            <span
              className='pointer near-black hover-teal no-underline'
              onClick={() =>
                handleSideBySideDownload(cellData, fullFileName, uploadUserId)
              }
            >
              {cellData}
            </span>
          }
        </Tooltip>
      </div>
    )
  }

  const handleSideBySideDownload = (fileName, fullFileName, uploadUserId) => {
    api
      .download(
        `${apiRoutes.loader}/loader/tier2LoadFile/${fullFileName}/${uploadUserId}`
      )
      .then(response => response.blob())
      .then(blob => FileSaver.saveAs(blob, `${fileName}`))
      .catch(err =>
        notify({
          message: err.message
        })
      )
  }

  const handleStagingDownload = rowData => {
    api
      .get(`${apiRoutes.loader}/util/stagingDownload/${rowData.get('id')}/serp`)
      .then(response => {
        const [fileName] = rowData.get('clientFileName').split('.')
        FileSaver.saveAs(response.url, `Review Mapping-${fileName}`)
        setExportStagingPressed('')
      })
      .catch(err => {
        setExportStagingPressed('')
        notify({
          message: err.message
        })
      })
  }
  return (
    <div>
      <div className='mt3'>
        <div className={`w-100 dib mb3 w-50-ns`}>
          <div className='pr4-ns'>
            <Text>
              <FormattedMessage
                id='SerpSupplierMatching.Instruction1'
                defaultMessage="On this page, you can use the SERP API for supplier matching. Required fields are 'internalSupplierId', and 'name', on a tab named 'Details'.  You can download the template to review the fields required for the upload and format your file with identical column headings and tabs. After uploading, the file will be processed and then made available for review below."
              />
            </Text>
            <div className='flex items-end'>
              <form
                method='get'
                action={`/downloads/SERP-SupplierMatchingTemplate.xlsx`}
              >
                <Button secondary autoSize type='submit' className='mt3'>
                  <FormattedMessage
                    id='LoadPage.DownloadSupplierMatchingTemplate'
                    defaultMessage='Download Supplier Matching Template'
                  />
                </Button>
              </form>

              <Button
                className='ml2'
                autoSize
                disabled={recomputeTrendsPressed}
                onClick={() => {
                  api
                    .post(`${apiRoutes.loader}/util/computeTrends`)
                    .then(response => {
                      if (response) {
                        setRecomputeTrendsPressed(false)
                      } else {
                        setRecomputeTrendsPressed(false)
                        setComputeTrendsError(true)
                      }
                    })
                }}
              >
                {!recomputeTrendsPressed ? (
                  <FormattedMessage
                    id='Loader.RecomputeTrends'
                    defaultMessage='Recompute Trends'
                  />
                ) : (
                  <FormattedMessage
                    id='Loader.ComputeTrendsProcessing'
                    defaultMessage='Computing Trends in Progress...'
                  />
                )}
              </Button>
            </div>
            {computeTrendsError && (
              <Warning
                message={
                  <FormattedMessage
                    id='LoadPage.RecomputeError'
                    defaultMessage='Something went wrong, please contact support.'
                  />
                }
              />
            )}
          </div>
        </div>
        <div className='w-100 dib v-top w-50-ns'>
          <FileInput
            small
            accept='.csv, .xlsx, .xls'
            limit={32}
            name='spendFile'
            isUploading={isUploading}
            value={uploadFile ? uploadFile.name : ''}
            onChange={(file: File | null) => setUploadFile(file)}
          />
          {uploadError && (
            <Warning
              message={
                <FormattedMessage
                  id='LoadPage.Error'
                  defaultMessage='Oops, something went wrong. Please try again later.'
                />
              }
            />
          )}
          <div className='fr'>
            <Button
              autoSize
              disabled={!uploadFile || isUploading}
              onClick={handleFileUpload}
              className='mt2 mb3'
            >
              <FormattedMessage id='LoadPage.Upload' defaultMessage='Upload' />
            </Button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {uploadHistorySerp && uploadHistorySerp.size > 0 && (
            <>
              <div className='bg-white br1 ba b--black-10 overflow-hidden'>
                <Table
                  rowGetter={({ index }) => uploadHistorySerp.get(index)}
                  rowCount={uploadHistorySerp.size}
                  onRowClick={({ rowData, index }) => {
                    if (rowData.get('status') === 'complete') {
                      setSelectedRow(index)
                    }
                  }}
                  highlightIndexes={
                    typeof highlightIndex === 'number'
                      ? List([highlightIndex])
                      : undefined
                  }
                >
                  <Column
                    label={
                      <FormattedMessage
                        id='LoadPage.Filename'
                        defaultMessage='Filename'
                      />
                    }
                    dataKey='clientFileName'
                    width={200}
                    cellRenderer={renderActionColumn}
                  />
                  <Column
                    label={
                      <FormattedMessage
                        id='LoadPage.UploadDate'
                        defaultMessage='Upload Date'
                      />
                    }
                    dataKey='uploadDate'
                    width={200}
                    cellRenderer={({ cellData }: TableCellProps) =>
                      dateFormat(cellData)
                    }
                  />
                  <Column
                    label={
                      <FormattedMessage
                        id='LoadPage.User'
                        defaultMessage='User'
                      />
                    }
                    dataKey='uploadBy'
                    width={200}
                    cellRenderer={({ cellData, rowData }: TableCellProps) => (
                      <Link
                        to={parsePath(supplierPaths.userProfile, {
                          userId: rowData.get('userId')
                        })}
                      >
                        {cellData}
                      </Link>
                    )}
                  />
                  <Column
                    label={
                      <FormattedMessage
                        id='LoadPage.Status'
                        defaultMessage='Status'
                      />
                    }
                    dataKey='status'
                    width={200}
                    cellRenderer={({ rowData }: TableCellProps) => {
                      const { status, fileLoadErrors } = rowData?.toJS()
                      return (
                        <span
                          className={
                            status === 'complete'
                              ? 'green'
                              : status === 'error'
                              ? 'red'
                              : ''
                          }
                        >
                          {messages[status]
                            ? intl.formatMessage(messages[status])
                            : startCase(status)}
                          {status === 'error' &&
                            fileLoadErrors &&
                            fileLoadErrors.length && (
                              <Tooltip title={fileLoadErrors.join()}>
                                <InfoIcon className='mr2 v-mid' />
                              </Tooltip>
                            )}
                        </span>
                      )
                    }}
                  />
                  <Column
                    label={
                      <FormattedMessage
                        id='LoadPage.Row'
                        defaultMessage='# of Rows'
                      />
                    }
                    dataKey='rowCount'
                    width={140}
                    cellRenderer={({ cellData, rowData }: TableCellProps) =>
                      cellData !== undefined && cellData.toLocaleString()
                    }
                  />
                  <Column
                    label={
                      <FormattedMessage
                        id='LoadPage.Actions'
                        defaultMessage='Actions'
                      />
                    }
                    dataKey='status'
                    width={260}
                    flexGrow={1}
                    headerClassName='tr'
                    cellRenderer={({ cellData, rowData }: TableCellProps) => {
                      return cellData === 'complete' ? (
                        <div className='flex items-center justify-end'>
                          {rowData.get('rowCount') > 0 && (
                            <Button
                              className='ml2'
                              autoSize
                              onClick={e => {
                                e.stopPropagation()
                                handleStagingDownload(rowData)
                                setExportStagingPressed(rowData.get('id'))
                              }}
                              disabled={
                                exportStagingPressed === rowData.get('id')
                              }
                            >
                              {exportStagingPressed !== rowData.get('id') ? (
                                <FormattedMessage
                                  id='LoadPage.Export'
                                  defaultMessage='Export'
                                />
                              ) : (
                                <FormattedMessage
                                  id='LoadPage.Exporting1'
                                  defaultMessage='Exporting'
                                />
                              )}
                            </Button>
                          )}
                          <DeleteIcon
                            className='ml3 pointer dim'
                            onClick={e => {
                              e.stopPropagation()
                              setConfirmRemoveStaging(rowData.get('id'))
                            }}
                          />
                        </div>
                      ) : null
                    }}
                  />
                </Table>
              </div>
              {uploadHistorySerp.some(
                history => history.get('status') === 'processing'
              ) && (
                <Text className='mt3 flex items-center'>
                  <InfoOutlined className='mr2' />
                  <FormattedMessage
                    id='LoadPage.ProcessingMessageHours'
                    defaultMessage='A file can take up to 3 hours to process.'
                  />
                </Text>
              )}
            </>
          )}
        </>
      )}
      <ConfirmationDialog
        open={!!confirmRemoveStaging}
        onClose={() => setConfirmRemoveStaging('')}
        onConfirm={() =>
          removeSpend({ stagingId: confirmRemoveStaging, listType: 'serp' })
        }
      >
        <Text>
          <FormattedMessage
            id='LoadPage.ConfirmDeleteStaging1'
            defaultMessage='Are you sure you want to remove the this spend?'
          />
        </Text>
      </ConfirmationDialog>
      {highlightIndex > -1 && (
        <ReviewSpendItems
          stagingId={uploadHistorySerp?.getIn([highlightIndex, 'id'])}
          isEditable={
            uploadHistorySerp?.getIn([highlightIndex, 'status']) !== 'published'
          }
          entity='stagingRow'
          label={
            uploadHistorySerp
              ? uploadHistorySerp.getIn([highlightIndex, 'clientFileName'])
              : ''
          }
        />
      )}
    </div>
  )
}

export default SerpSupplierMatching
