import React, { ReactElement, useEffect, useState } from 'react'
import Toggle from 'shared/components/Switch'
import Text from 'shared/components/Text'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { createStyles, IconButton, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Input from 'shared/components/Input'
import { useDebouncedCallback } from 'use-debounce/lib'
import MetricQuestion from '../MetricQuestion'
import useSmallScreen from 'shared/utils/useSmallScreen'
import { MetricUnit } from '../MetricOrCustomQuestionList/MetricOrCustomQuestionList'

const useStyles = makeStyles(() =>
  createStyles({
    textInputWidth: {
      width: '800px'
    },
    textInputMobileWidth: {
      width: '350px'
    }
  })
)

const messages = defineMessages({
  placeholder: {
    id: 'MetricOrCustomQuestion.provideDetail',
    defaultMessage: 'Please provide detail'
  }
})

type Props = {
  isCustomEntrySelected: boolean
  onToggleChange: () => void
  pageId: string
  parentQuestionId?: string
  questionId: string
  fieldName?: 'answer' | 'reason'
  placeholder?: string
  className?: string
  question?: string | ReactElement<FormattedMessage>
  metric: MetricUnit
  metricIndex: number
  onMetricChange: any
  onDeleteMetric: any
}

const MetricOrCustomQuestion = (props: Props) => {
  const intl = useIntl()
  const classes = useStyles()
  const isMobile = useSmallScreen()

  const {
    isCustomEntrySelected,
    onToggleChange,
    placeholder,
    question,
    metricIndex,
    metric,
    onMetricChange,
    onDeleteMetric,
    ...rest
  } = props

  const [customInput, setCustomInput] = useState<string>('')
  const [customInputIsEmpty, setCustomInputIsEmpty] = useState<boolean>(false)

  useEffect(() => {
    if (!customInput && !customInputIsEmpty) {
      setCustomInput(metric.value)
    }
  }, [metric, customInput, customInputIsEmpty])

  const debouncedSaveSurveyResponse = useDebouncedCallback((value: string) => {
    onMetricChange(
      { value, source: 'customText', key: metric.key },
      metricIndex
    )
  }, 500)

  return (
    <div className='mb3'>
      {question && <Text className='fw6'>{question}</Text>}
      <div className='flex items-center mb2'>
        <Toggle
          checked={isCustomEntrySelected}
          onChange={() => {
            setCustomInput('')
            setCustomInputIsEmpty(true)
            onToggleChange()
          }}
        />
        <Text className='ml2'>
          <FormattedMessage
            id='MetricOrCustomQuestion.enterCustomAnswer'
            defaultMessage='Enter custom answer'
          />
        </Text>
      </div>
      <div className='flex items-center flex-wrap'>
        {!isCustomEntrySelected && (
          <MetricQuestion
            metricIndex={metricIndex}
            metric={metric}
            onMetricChange={onMetricChange}
            {...rest}
          />
        )}
        {isCustomEntrySelected && (
          <Input
            aria-label='custom input'
            value={customInput}
            onChange={e => {
              setCustomInput(e.target.value)
              if (e.target.value === '') {
                setCustomInputIsEmpty(true)
              } else {
                setCustomInputIsEmpty(false)
              }
              debouncedSaveSurveyResponse.callback(e.target.value)
            }}
            required
            placeholder={
              placeholder || intl.formatMessage(messages.placeholder)
            }
            className={
              isMobile ? classes.textInputMobileWidth : classes.textInputWidth
            }
          />
        )}
        {metricIndex !== 0 && (
          <div className='ml2'>
            <IconButton
              onClick={() => onDeleteMetric(metricIndex)}
              title='Delete row'
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default MetricOrCustomQuestion
