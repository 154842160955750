import React, { useMemo } from 'react'
import ParentQuestion from '../ParentQuestion'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import companySelectors from '../../../shared/selectors/companySelectors'
import { useSelector } from 'react-redux'
import AttachmentQuestion from '../AttachmentQuestion'
import TextQuestion from '../TextQuestion'
import Text from 'shared/components/Text'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import MetricOrCustomQuestionList from '../MetricOrCustomQuestionList'
import DropdownQuestion from '../DropdownQuestion'

const messages = defineMessages({
  placeholderTarget: {
    id: 'Environment.target',
    defaultMessage: 'e.g., 25% reduction by 2030'
  },
  placeholderEfficiency: {
    id: 'Environment.efficiency',
    defaultMessage:
      'e.g. 30% reduction of projected primary energy consumption in 2030, based on baseline projections performed in 2007.'
  },
  placeholderWater: {
    id: 'Environment.water',
    defaultMessage:
      'e.g. 10-30% reduction of total water consumption and related utility costs by 2025.'
  },
  placeholderWaste: {
    id: 'Environment.waste',
    defaultMessage: 'e.g. reducing food waste by 50% by 2025.'
  },
  energyConsumptionTracking: {
    id: 'Environment.docTypeEnergyConsumptionTracking',
    defaultMessage: 'Energy consumption tracking'
  },
  onSiteEnergyAudit: {
    id: 'Environment.docTypeOnSiteEnergyAudit',
    defaultMessage: 'On-site energy audit'
  },
  reduceEnergyProgram: {
    id: 'Environment.docTypeReduceEnergyPrograms',
    defaultMessage: 'Program to reduce energy use'
  },
  waterUsageTracking: {
    id: 'Environment.docTypeWaterUsageTracking',
    defaultMessage: 'Water usage tracking'
  },
  waterUsageAudit: {
    id: 'Environment.docTypeWaterUsageAudits',
    defaultMessage: 'Water usage audit'
  },
  waterConservationProgram: {
    id: 'Environment.docTypeWaterConservationProgram',
    defaultMessage: 'Water conservation program'
  },
  wasteAudit: {
    id: 'Environment.docTypeWasteAudit',
    defaultMessage: 'Waste audit'
  },
  wasteReductionProgram: {
    id: 'Environment.docTypeWasteReductionProgram',
    defaultMessage: 'Waste reduction/diversion program'
  },
  placeholderAdditionalInfo: {
    id: 'placeholderAdditionalInfo',
    defaultMessage: 'Please provide any additional details.'
  }
})

const pageId = 'environment'

const Environment = () => {
  const intl = useIntl()
  const certifications = useSelector(companySelectors.getCertifications)
  const iso140001 = useMemo(() => {
    const sustainability = certifications?.find(c => c[0] === 'sustainability')
    return sustainability?.[1]?.filter(c => c.get('subCategory') === 'iso14001')
  }, [certifications])
  const iso50001 = useMemo(() => {
    const sustainability = certifications?.find(c => c[0] === 'sustainability')
    return sustainability?.[1]?.filter(c => c.get('subCategory') === 'iso50001')
  }, [certifications])
  const wasteProgramsDocTypes = [
    { value: 'wasteAudit', label: intl.formatMessage(messages.wasteAudit) },
    {
      value: 'wasteReductionProgram',
      label: intl.formatMessage(messages.wasteReductionProgram)
    }
  ]
  const waterProgramsDocTypes = [
    {
      value: 'waterUsageTracking',
      label: intl.formatMessage(messages.waterUsageTracking)
    },
    {
      value: 'waterUsageAudit',
      label: intl.formatMessage(messages.waterUsageAudit)
    },
    {
      value: 'waterConservationProgram',
      label: intl.formatMessage(messages.waterConservationProgram)
    }
  ]
  const energyProgramsDocTypes = [
    {
      value: 'energyConsumptionTracking',
      label: intl.formatMessage(messages.energyConsumptionTracking)
    },
    {
      value: 'onSiteEnergyAudit',
      label: intl.formatMessage(messages.onSiteEnergyAudit)
    },
    {
      value: 'reduceEnergyProgram',
      label: intl.formatMessage(messages.reduceEnergyProgram)
    }
  ]

  return (
    <div>
      <ScrollToPosition />
      <ParentQuestion
        pageId={pageId}
        questionId='iso14001'
        question={
          <FormattedMessage
            id='Environment.iso14001'
            defaultMessage='Q1. Is there an environmental management system in place? (e.g., ISO 14001)'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='iso14001'
          suggestion={iso140001}
          certCategory='sustainability'
          certSubCategory='iso14001'
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='greenhouseGasInventory'
        question={
          <FormattedMessage
            id='Environment.greenhouseGasInventory'
            defaultMessage='Q2. Has a greenhouse gas (GHG) inventory (carbon footprint) been completed in accordance with the GHG Protocol Corporate Standard?'
          />
        }
      >
        <ParentQuestion
          pageId={pageId}
          parentQuestionId='greenhouseGasInventory'
          questionId='scopeEmissions'
          question={
            <FormattedMessage
              id='Environment.scopeEmissionsScope'
              defaultMessage='Does the carbon footprint include scope 1, 2, and 3 emissions?'
            />
          }
          additionalInfo={
            <div className='ml3 mt2'>
              <Text secondary>
                <FormattedMessage
                  id='Environment.scopeEmissions1'
                  defaultMessage='<b>Scope 1 emissions:</b> direct GHG emissions from sources owned/controlled by the company (ex. fuel combustion in vehicles and furnaces)'
                  values={{
                    b: b => <span className='fw8'>{b}</span>
                  }}
                />
              </Text>
              <Text secondary>
                <FormattedMessage
                  id='Environment.scopeEmissions2'
                  defaultMessage='<b>Scope 2 emissions:</b> indirect GHG emissions resulting from purchased sources (i.e. electricity, heating, cooling etc.)'
                  values={{
                    b: b => <span className='fw8'>{b}</span>
                  }}
                />
              </Text>
              <Text secondary>
                <FormattedMessage
                  id='Environment.scopeEmissions3'
                  defaultMessage='<b>Scope 3 emissions (aka value chain emissions):</b> emissions from all sources outside the boundaries of scopes 1 & 2'
                  values={{
                    b: b => <span className='fw8'>{b}</span>
                  }}
                />
              </Text>
            </div>
          }
        />
        <DropdownQuestion
          className='mb3'
          pageId={pageId}
          parentQuestionId='greenhouseGasInventory'
          questionId='howFrequently'
          question={
            <FormattedMessage
              id='Environment.howFrequently'
              defaultMessage='How frequently is this inventory completed?'
            />
          }
        />
        <ParentQuestion
          pageId={pageId}
          parentQuestionId='greenhouseGasInventory'
          questionId='thirdPartyCert'
          question={
            <FormattedMessage
              id='Environment.thirdPartyCert'
              defaultMessage='Has this inventory been verified by a third party?'
            />
          }
        >
          <AttachmentQuestion
            pageId={pageId}
            parentQuestionId='greenhouseGasInventory'
            questionId='thirdPartyCert'
            type='doc'
          />
        </ParentQuestion>
        <ParentQuestion
          pageId={pageId}
          parentQuestionId='greenhouseGasInventory'
          questionId='ghgInventory'
          question={
            <FormattedMessage
              id='Environment.GhgInventoryManagement'
              defaultMessage='Does your company have an official GHG inventory management plan in place?'
            />
          }
        >
          <AttachmentQuestion
            pageId={pageId}
            parentQuestionId='greenhouseGasInventory'
            questionId='ghgInventory'
            type='doc'
          />
        </ParentQuestion>
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='ghgReductionTarget'
        question={
          <FormattedMessage
            id='Environment.ghgReductionTarget'
            defaultMessage='Q3. Has your company set a publicly reported GHG reduction target?'
          />
        }
      >
        <div className='pt3'>
          <MetricOrCustomQuestionList
            pageId={pageId}
            parentQuestionId='ghgReductionTarget'
            questionId='targetDateNumber'
            question={
              <FormattedMessage
                id='Environment.targetDateNumber'
                defaultMessage='What is the reduction target and when do you plan to reach it?'
              />
            }
            placeholder={intl.formatMessage(messages.placeholderTarget)}
          />
          <ParentQuestion
            pageId={pageId}
            parentQuestionId='ghgReductionTarget'
            questionId='validatedBySBTi'
            question={
              <FormattedMessage
                id='Environment.validatedBySBTi'
                defaultMessage='Has this target been validated and published by the Science-Based Targets Initiative (SBTi)?'
              />
            }
          />
        </div>
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='iso50001'
        question={
          <FormattedMessage
            id='Environment.iso50001'
            defaultMessage='Q4. Is there an energy management system in place? (e.g., ISO 50001)'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='iso50001'
          suggestion={iso50001}
          certCategory='sustainability'
          certSubCategory='iso50001'
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='energyConsumptionTracking'
        question={
          <FormattedMessage
            id='Environment.energyConsumptionTracking'
            defaultMessage='Q5. Have any of the following been completed: energy consumption tracking, on-site energy audits or programs to reduce energy use? If so, please specify.'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='energyConsumptionTracking'
          type='doc'
          multiple
          uploadDocTypes={energyProgramsDocTypes}
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='waterUsageTracking'
        question={
          <FormattedMessage
            id='Environment.waterUsageTracking'
            defaultMessage='Q6. Have any of the following been completed: water usage tracking, water usage audits or water conservation programs? If so, please specify.'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='waterUsageTracking'
          type='doc'
          multiple
          uploadDocTypes={waterProgramsDocTypes}
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='wastePrograms'
        question={
          <FormattedMessage
            id='Environment.wastePrograms'
            defaultMessage='Q7. Have any of the following been completed: waste audits or waste reduction/diversion programs (e.g., use of recycled or recyclable materials)? If so, please specify.'
          />
        }
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='wastePrograms'
          type='doc'
          multiple
          uploadDocTypes={wasteProgramsDocTypes}
        />
      </ParentQuestion>
      <Text className='fw6'>
        <FormattedMessage
          id='Environment.goalsTargets'
          defaultMessage='Q8. Are there goals or targets in place for any of the following. If so, please specify.'
        />
      </Text>
      <ParentQuestion
        pageId={pageId}
        parentQuestionId='goalsOrTargets'
        questionId='improvingEnergyEfficiency'
        question={
          <FormattedMessage
            id='Environment.improvingEnergyEfficiency'
            defaultMessage='Improving energy efficiency'
          />
        }
      >
        <MetricOrCustomQuestionList
          pageId={pageId}
          parentQuestionId='goalsOrTargets'
          questionId='improvingEnergyEfficiency'
          fieldName='reason'
          placeholder={intl.formatMessage(messages.placeholderEfficiency)}
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        parentQuestionId='goalsOrTargets'
        questionId='managingWaterUsage'
        question={
          <FormattedMessage
            id='Environment.managingWaterUsage'
            defaultMessage='Managing water usage'
          />
        }
      >
        <MetricOrCustomQuestionList
          pageId={pageId}
          parentQuestionId='goalsOrTargets'
          questionId='managingWaterUsage'
          fieldName='reason'
          placeholder={intl.formatMessage(messages.placeholderWater)}
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        parentQuestionId='goalsOrTargets'
        questionId='managingWaste'
        question={
          <FormattedMessage
            id='Environment.managingWaste'
            defaultMessage='Managing waste'
          />
        }
      >
        <MetricOrCustomQuestionList
          pageId={pageId}
          parentQuestionId='goalsOrTargets'
          questionId='managingWaste'
          fieldName='reason'
          placeholder={intl.formatMessage(messages.placeholderWaste)}
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='negativeEnvironmentalImpacts'
        disableNoSubQuestion
        question={
          <FormattedMessage
            id='Environment.negativeEnvironmentalImpacts'
            defaultMessage='Q9. Your organization <b>has not</b> been involved in any significant actual and/or potential negative environmental impacts (including charges, violations or orders), including to human health, biodiversity, land, vegetation, water bodies, and groundwater (e.g. spills) or others.'
            values={{
              b: b => <span className='fw8 black'>{b}</span>
            }}
          />
        }
        additionalInfo={
          <div className='ml3 mt2'>
            <Text secondary>
              <FormattedMessage
                id='Environment.negativeEnvironmentalImpactsInfo1'
                defaultMessage='True, my organization <b>has not</b> been involved in any significant actual and/or potential negative environmental impacts.'
                values={{
                  b: b => <span className='fw8 underline'>{b}</span>
                }}
              />
            </Text>
            <Text secondary>
              <FormattedMessage
                id='Environment.negativeEnvironmentalImpactsInfo2'
                defaultMessage='False, my organization <b>has</b> been involved in significant actual and/or potential negative environmental impacts.'
                values={{
                  b: b => <span className='fw8 underline'>{b}</span>
                }}
              />
            </Text>
          </div>
        }
        useTrueFalse
        childrenOnFalse
      >
        <>
          <Text>
            <FormattedMessage
              id='HealthSafety.chargeOccupationalSafetyInfo2b'
              defaultMessage='Please specify performance statistics, ideally for the past 3 years, and any older incidents that have not yet been remediated.'
            />
          </Text>
          <AttachmentQuestion
            pageId={pageId}
            questionId='negativeEnvironmentalImpacts'
            type='doc'
            multiple
          />
        </>
      </ParentQuestion>
      <TextQuestion
        pageId={pageId}
        questionId='note'
        fieldType='textarea'
        question={
          <FormattedMessage
            id='optionalAdditionalNote'
            defaultMessage='Additional information (optional)'
          />
        }
        placeholder={intl.formatMessage(messages.placeholderAdditionalInfo)}
      />
    </div>
  )
}

export default Environment
