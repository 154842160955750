import React from 'react'
import { Route, Switch } from 'react-router'
import CompanyProfilePageContainer from '../containers/CompanyProfilePageContainer'
import paths from './paths'

const createRoutes = () => (
  <Switch>
    <Route path={paths.basePath} component={CompanyProfilePageContainer} />
  </Switch>
)

export default createRoutes
