import React, { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import Text from 'shared/components/Text'
import { ReactComponent as HealthSafety } from 'shared/assets/images/esg/icon-health-safety.svg'
import { ReactComponent as Environment } from 'shared/assets/images/esg/icon-environment.svg'
import { ReactComponent as DiversityInclusion } from 'shared/assets/images/esg/icon-diversity.svg'
import { ReactComponent as Governance } from 'shared/assets/images/esg/icon-governance.svg'
import { ReactComponent as Privacy } from 'shared/assets/images/esg/icon-privacy.svg'

type Props = {
  className?: string
  backgroundColor: string
  label: string | ReactElement<FormattedMessage>
  total: number
  healthSafety: number
  environment: number
  diversityInclusion: number
  governance: number
  privacy: number
}

const FactsCard = (props: Props) => {
  const {
    className,
    backgroundColor,
    label,
    total,
    healthSafety,
    environment,
    diversityInclusion,
    governance,
    privacy
  } = props
  return !!total ? (
    <div
      className={classnames('pa3 br4', className)}
      style={{ height: 250, backgroundColor }}
    >
      <h6 className='f2 ma0 fw4'>{total.toString().padStart(2, '0')}</h6>
      <label className='db f6 fw6 mt1 mb3'>{label}</label>
      {!!healthSafety && (
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <HealthSafety width={24} />
            <Text className='ml2'>
              <FormattedMessage
                id='FactsCard.HealthSafety'
                defaultMessage={'Health & Safety'}
              />
            </Text>
          </div>
          <Text className='black fw6'>
            {healthSafety.toString().padStart(2, '0')}
          </Text>
        </div>
      )}
      {!!environment && (
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <Environment width={24} />
            <Text className='ml2'>
              <FormattedMessage
                id='FactsCard.Environment'
                defaultMessage={'Environment'}
              />
            </Text>
          </div>
          <Text className='black fw6'>
            {environment.toString().padStart(2, '0')}
          </Text>
        </div>
      )}
      {!!diversityInclusion && (
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <DiversityInclusion width={24} />
            <Text className='ml2'>
              <FormattedMessage
                id='FactsCard.DiversityInclusion'
                defaultMessage={'Diversity Inclusion'}
              />
            </Text>
          </div>
          <Text className='black fw6'>
            {diversityInclusion.toString().padStart(2, '0')}
          </Text>
        </div>
      )}
      {!!governance && (
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <Governance width={24} />
            <Text className='ml2'>
              <FormattedMessage
                id='FactsCard.Governance'
                defaultMessage={'Governance'}
              />
            </Text>
          </div>
          <Text className='black fw6'>
            {governance.toString().padStart(2, '0')}
          </Text>
        </div>
      )}
      {!!privacy && (
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <Privacy width={24} />
            <Text className='ml2'>
              <FormattedMessage
                id='FactsCard.Privacy'
                defaultMessage={'Privacy'}
              />
            </Text>
          </div>
          <Text className='black fw6'>
            {privacy.toString().padStart(2, '0')}
          </Text>
        </div>
      )}
    </div>
  ) : null
}

export default FactsCard
