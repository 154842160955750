import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

export const suggestSupplier = createAction(actionTypes.SUGGEST_SUPPLIER)
export const showMoreSuppliers = createAction(actionTypes.SHOW_MORE_SUPPLIERS)
export const getOrgSettings = createAction(actionTypes.GET_SETTINGS)

export const connectToSupplier = createAction(
  actionTypes.CONNECT_TO_SUPPLIER,
  (payload, meta) => payload,
  (payload, meta) => meta
)
export const unfollowSupplier = createAction(actionTypes.UNFOLLOW_SUPPLIER)

export const searchSimilarSuppliers = createAction(
  actionTypes.SEARCH_SIMILAR_SUPPLIERS
)

export const searchContacts = createAction(actionTypes.SEARCH_CONTACTS)
export const searchLocations = createAction(actionTypes.SEARCH_LOCATIONS)

export const getCardsBySupplierId = createAction(
  actionTypes.GET_CARDS_BY_SUPPLIER_ID
)
