import React, { Component } from 'react'
import Button from 'shared/components/Button'
import UserLookup from 'shared/components/UserLookup'
import Label from 'shared/components/Label'
import SelectConnectionRole from '../SelectConnectionRole'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import CloseIcon from 'shared/assets/icons/close.svg'
import Warning from 'shared/components/Warning'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf } from 'immutable'
import User from 'shared/models/User'

type Props = {
  usersWithoutCards: List<RecordOf<User>>
  onDone: (param: {
    userId: string
    contactFor: string
    connected: boolean
  }) => void
}

type State = {
  showMissingUserError: boolean
  selectedUser?: string
  connectingTeamMember: boolean
  connectionRole: string
}

const initialState: State = {
  showMissingUserError: false,
  selectedUser: undefined,
  connectingTeamMember: false,
  connectionRole: ''
}

class ConnectTeamMember extends Component<Props, State> {
  state: State = initialState

  handleClickConnectTeamMember = () => {
    this.setState({
      connectingTeamMember: true
    })
  }

  handleClickClose = () => {
    this.setState({
      selectedUser: undefined,
      connectionRole: ''
    })
  }

  handleClickCancel = () => {
    this.setState(initialState)
  }

  handleClickUser = user => {
    this.setState({
      selectedUser: user.get('id'),
      showMissingUserError: false
    })
  }

  handleChangeRole = e => {
    this.setState({
      connectionRole: e.target.value
    })
  }

  handleClickDone = () => {
    const { selectedUser, connectionRole } = this.state

    if (selectedUser) {
      this.props.onDone({
        userId: selectedUser,
        contactFor: connectionRole,
        connected: true
      })

      this.setState(initialState)
    } else {
      this.setState({
        showMissingUserError: true
      })
    }
  }

  render() {
    const { usersWithoutCards } = this.props

    const {
      connectionRole,
      selectedUser,
      connectingTeamMember,
      showMissingUserError
    } = this.state

    return (
      <div className='mt3'>
        {!connectingTeamMember && (
          <Button
            label={
              <FormattedMessage
                id='ConnectTeamMember.ConnectATeamMember'
                defaultMessage='Connect a team member'
              />
            }
            secondary
            onClick={this.handleClickConnectTeamMember}
          />
        )}

        {connectingTeamMember && (
          <div className='bg-near-white pb3 ph3'>
            <Label>
              <FormattedMessage
                id='ConnectTeamMember.Name'
                defaultMessage='Name'
              />
            </Label>

            {!selectedUser && (
              <UserLookup
                users={usersWithoutCards}
                onClickSuggestedUser={this.handleClickUser}
              />
            )}

            {selectedUser && (
              <UserListItemContainer
                userId={selectedUser}
                rightButton={
                  <img
                    src={CloseIcon}
                    onClick={this.handleClickClose}
                    className='pa3 pointer'
                    alt='close'
                  />
                }
                mini
              />
            )}

            <Label>
              <FormattedMessage
                id='ConnectTeamMember.Role'
                defaultMessage='Role'
              />
            </Label>
            <SelectConnectionRole
              value={connectionRole}
              onChange={this.handleChangeRole}
            />

            {showMissingUserError && (
              <Warning
                message={
                  <FormattedMessage
                    id='ConnectTeamMember.PleaseSelectAUs'
                    defaultMessage='Please select a user'
                  />
                }
                className='mt3'
              />
            )}

            <Button
              label={
                <FormattedMessage
                  id='ConnectTeamMember.Done'
                  defaultMessage='Done'
                />
              }
              onClick={this.handleClickDone}
              autoSize
              className='mt3'
            />

            <Button
              label={<FormattedMessage id='CancelButton' />}
              secondary
              onClick={this.handleClickCancel}
              autoSize
              className='ml3'
            />
          </div>
        )}
      </div>
    )
  }
}

export default ConnectTeamMember
