import { connect } from 'react-redux'
import VetLinkedToTask from '../../components/VetLinkedToTask'
import dataSelectors from 'shared/selectors/dataSelectors'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'

type Props = {
  vetId: string
}

export default connect((state, props: Props) => {
  const vet = dataSelectors.getById(state, 'vets', props.vetId)

  return {
    name: vet.get('name') || 'Untitled',
    description: vet.getIn(['vetDetails', 'description']),
    criticalCriteria: vet.getIn(['vetDetails', 'criticalCriteria']),
    attachment: fileToUrl(vet.getIn(['vetDetails', 'document'])),
    isDeleted: !vet.get('active')
  }
})(VetLinkedToTask)
