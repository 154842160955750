import { reduxForm } from 'redux-form/immutable'
import { connect } from 'react-redux'

const connectReduxForm = (
  mapStateToProps,
  mapDispatchToProps,
  reduxFormOptions,
  component
) =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm(reduxFormOptions)(component))

export default connectReduxForm
