import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'

export function* removeCriteriaSaga(action) {
  const { vetId, index, criticalCriteria } = action.payload

  yield call(vets.removeCriteria, { vetId, criticalCriteria })
  return yield index
}

export default createApiRequestSaga(removeCriteriaSaga)
