import React, { Component, ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf } from 'immutable'

const limit = 10

type Props = {
  items: List<
    RecordOf<{
      key: string
      key_as_string?: string
      doc_count: number
    }>
  >
  selectedItems: List<string>
  isSearching: boolean
  onChange: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined
  labelFormatter?: (params: string) => string
}

export class FilterCheckboxList extends Component<Props> {
  state = {
    showMore: false
  }

  toggleShowMore = () => {
    this.setState({
      showMore: !this.state.showMore
    })
  }

  render() {
    const {
      items,
      selectedItems,
      isSearching,
      onChange,
      labelFormatter
    } = this.props

    const { showMore } = this.state

    const limitItems =
      items && items.size > limit && !showMore ? items.take(limit) : items

    return (
      <>
        {limitItems.map(item => {
          let name: string = ''
          let label: string = ''
          if (item.has('key_as_string')) {
            name = item.get('key_as_string') || ''
            label = item.get('key_as_string') === 'true' ? 'Yes' : 'No'
          } else {
            name = item.get('key')
            label =
              item
                .get('key')
                .split('::')
                .pop() || 'Unspecified'
          }

          if (labelFormatter) {
            label = labelFormatter(label)
          }

          if (item.get('doc_count')) {
            label = `${label} (${item.get('doc_count').toLocaleString()})`
          }

          return (
            <label
              key={item.get('key')}
              className='f7 fw3 mid-gray pointer pv1 db'
            >
              <input
                type='checkbox'
                onChange={onChange}
                className='b--black-10 mr1'
                name={name}
                checked={selectedItems.includes(name)}
                disabled={isSearching}
              />
              {label}
            </label>
          )
        })}

        {items.size > limit && !showMore && (
          <button
            className='f8 fw3 gray underline-hover pointer mt2 mb0 lh-copy dib v-mid bn'
            onClick={this.toggleShowMore}
          >
            <FormattedMessage
              id='Contacts/FilterCheckboxList.SeeMore'
              defaultMessage='See more {rightIcon}'
              values={{ rightIcon: <span>&raquo;</span> }}
            />
          </button>
        )}
      </>
    )
  }
}

export default FilterCheckboxList
