import { fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { requestSuccess } from 'shared/actionTypes'

//check whether POST to sendOnetimeKey and SMS to user was successful
const defaultState = fromJS({
  // reasonItems: {
  //   orgUnitId: '',
  //   orgName: '',
  // }
  openDialog: false
})

const reasonKeyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case requestSuccess(actionTypes.GET_REASON_KEY):
      return state.merge({
        reasonItems: fromJS(action.payload),
        openDialog: true
      })
    case actionTypes.CLOSE_LOGIN_DIALOG:
      return state.merge({
        reasonItems: undefined,
        openDialog: false
      })
    default:
      return state
  }
}

export default reasonKeyReducer
