import { call, take, race, put, delay } from 'redux-saga/effects'
import jwtDecode from 'jwt-decode'
import { LOGIN } from 'accounts/actionTypes'
import { LOGOUT, requestSuccess } from '../../actionTypes'
import { logout } from '../../actions'
import auth from '../../utils/api/auth'
import storageManager from '../../utils/storageManager'

export default function* autoRefreshTokens() {
  yield take(requestSuccess(LOGIN))
  while (true) {
    const accessToken = storageManager.getItem('accessToken') as string

    // calculate how long it'll take the token to expire
    const { exp } = jwtDecode(accessToken)
    const expiresIn = exp - Math.floor(Date.now() / 1000)

    // refresh token 3 minutes before the token expires
    const refreshIn = expiresIn - 3 * 60

    // race between having to refresh or logout action
    const { loggedOut } = yield race({
      refresh: delay(refreshIn * 1000), // in miliseconds
      loggedOut: take(LOGOUT)
    })

    // the user logged out before the token expired
    if (loggedOut) {
      yield take(requestSuccess(LOGIN))
      continue
    }

    // try to refresh the tokens
    const refreshToken = storageManager.getItem('refreshToken')
    const newTokens = yield call(auth.refreshTokens, refreshToken)
    if (!newTokens) {
      // logout and wait for login before starting again
      yield put(logout())
      yield take(requestSuccess(LOGIN))
    }
  }
}
