import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserCommentContainer from 'shared/containers/UserCommentContainer'
import { loadComments, removeComment } from 'shared/actions'
import messagesSelectors from 'shared/selectors/messagesSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import paths from 'buyer/routes/paths'
import parsePath from 'shared/utils/parsePath'
import PageSection from 'shared/components/PageSection'
import Button from 'shared/components/Button'
import AddCommentAboutUserForm from '../AddCommentAboutUserForm'
import createEditForm from 'shared/utils/createEditForm'
import { UPDATE_COMMENT } from 'shared/actionTypes'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf } from 'immutable'

const formName = 'myaccount/updateCommentsAboutUser/userComment'

type Props = {
  loadComments: typeof loadComments
  removeComment: typeof removeComment
  userId: string
  comments: List<
    RecordOf<{
      id: string
      createdDate: string
      createdBy: string
      body: string
      isPrivate: boolean
    }>
  >
  currentUserId: string
}

type State = {
  showAll: boolean
}

export class CommentsAboutUser extends Component<Props, State> {
  state: State = {
    showAll: false
  }

  formIndex = 0

  componentDidMount() {
    const { loadComments, userId } = this.props

    loadComments({ ancestorId: userId, messageKind: 'User' })
  }

  handleShowAll = () => {
    this.setState({ showAll: true })
  }

  render() {
    const { comments, userId, currentUserId, removeComment } = this.props
    const { showAll } = this.state

    return (
      <PageSection
        title={
          <FormattedMessage
            id='CommentsAboutUser.Comments'
            defaultMessage='Comments'
          />
        }
        noPadding
      >
        {comments && comments.size > 5 && !showAll && (
          <div className='pa2 bb b--black-10'>
            <Button
              variant='text'
              autoSize
              label={`View ${comments.size - 5} more comments`}
              onClick={this.handleShowAll}
            />
          </div>
        )}

        {comments &&
          comments.takeLast(showAll ? comments.size : 5).map(comment => {
            const EditableUserCommentContainer =
              currentUserId === comment.get('createdBy')
                ? createEditForm(
                    {
                      formName: `${formName}${this.formIndex++}`,
                      submitActionType: UPDATE_COMMENT,
                      stopEditModeOnSave: true,
                      onRemove: () =>
                        removeComment({
                          ancestorId: userId,
                          messageId: comment.get('id'),
                          messageKind: 'User'
                        })
                    },
                    UserCommentContainer
                  )
                : UserCommentContainer

            return (
              <EditableUserCommentContainer
                key={comment.get('id')}
                userId={comment.get('createdBy')}
                userProfileUrl={parsePath(paths.userprofile, {
                  userId: comment.get('createdBy')
                })}
                date={comment.get('createdDate')}
                comment={comment.get('body')}
                enablePublicPrivate
                isPrivate={comment.get('isPrivate')}
                initialValues={{
                  ancestorId: userId,
                  comment: comment.get('body'),
                  messageId: comment.get('id'),
                  isPrivate: comment.get('isPrivate'),
                  messageKind: 'User'
                }}
              />
            )
          })}
        <div className='pa4'>
          <AddCommentAboutUserForm
            enablePublicPrivate
            initialValues={{
              ancestorId: userId,
              isPrivate: false,
              messageKind: 'User'
            }}
          />
        </div>
      </PageSection>
    )
  }
}

type ContainerProps = {
  userId: string
}
export default connect(
  (state, props: ContainerProps) => {
    const comments = messagesSelectors.getAllByKindId(
      state,
      'User',
      props.userId
    )
    return {
      comments,
      currentUserId: sessionSelectors.getUserId(state)
    }
  },
  {
    loadComments,
    removeComment
  }
)(CommentsAboutUser)
