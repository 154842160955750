import React from 'react'
import RootState from 'shared/models/RootState'
import usersSelectors from 'shared/selectors/usersSelectors'
import { useSelector } from 'react-redux'

type Props = {
  completedByUserId: string
}

const CompletedByUser = ({ completedByUserId }: Props) => {
  const userName: string = useSelector((state: RootState) => {
    const user =
      state.getIn(['data', 'users', completedByUserId]) ||
      usersSelectors.getById(state, completedByUserId)
    return user
      ? `${user.get('firstName') || ''} ${user.get('lastName') || ''}`
      : ''
  })

  return <div>{userName}</div>
}

export default CompletedByUser
