import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'

const supplierRelationshipsEntity = 'supplierRelationships'

export function* updateCustomizePitchDeckSaga(action) {
  const {
    supplierRelationshipId,
    supplierRelationship,
    pitchDeckFile,
    clientId
  } = action.payload

  // create or update the supplier relationship record
  let newSupplierRelationship
  if (!supplierRelationshipId) {
    newSupplierRelationship = yield call(
      api.post,
      `${apiRoutes.authService}/${supplierRelationshipsEntity}`,
      {
        ...supplierRelationship,

        // need to pass the buyer id when creating it
        buyer: clientId
      }
    )
  } else {
    newSupplierRelationship = yield call(
      api.put,
      `${apiRoutes.authService}/${supplierRelationshipsEntity}/${supplierRelationshipId}`,
      supplierRelationship
    )
  }

  // upload the pitch deck file if one was submitted
  if (pitchDeckFile && newSupplierRelationship && newSupplierRelationship.id) {
    const newPitchDeckFile = yield call(
      api.putFile,
      `${apiRoutes.authService}/${supplierRelationshipsEntity}/${newSupplierRelationship.id}/pitchDeckFile`,
      { pitchDeckFile }
    )

    newSupplierRelationship.supplierCollaboration = {
      ...newSupplierRelationship.supplierCollaboration,
      pitchDeckFile: newPitchDeckFile
    }
  }

  return yield newSupplierRelationship
}

export default createApiRequestSaga(updateCustomizePitchDeckSaga, {
  notifyOnError: true
})
