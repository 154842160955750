import React, { Component, Fragment } from 'react'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import AutoSaveInput from 'shared/components/AutoSaveInput'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Switch from 'shared/components/Switch'
import CommunityContacts from '../../containers/CommunityContacts'
import Loading from 'shared/components/Loading'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'
import ScrollToPosition from 'shared/components/ScrollToPosition'

type Props = {
  updateCommunityBuyer: (param: string) => void
  communityId: string
  orgUnitId: string
  message: string
  publicProfile: boolean
  contacts: List<string>
  isLoading: boolean
  isTealbot: boolean
}

class Community extends Component<Props> {
  handleUpdateCommunityBuyer = changes => {
    const {
      updateCommunityBuyer,
      communityId,
      orgUnitId,
      message,
      publicProfile,
      contacts
    } = this.props
    const transContacts = contacts && contacts.map(id => ({ user: id })).toJS()

    updateCommunityBuyer({
      communityId,
      orgUnitId,
      ...Object.assign(
        {},
        { message, publicProfile, contacts: transContacts },
        changes
      )
    })
  }

  render() {
    const {
      isLoading,
      isTealbot,
      message,
      publicProfile,
      contacts
    } = this.props
    return isLoading ? (
      Loading
    ) : (
      <Fragment>
        <ScrollToPosition to={220} strict='up' />
        <Page
          title={
            <FormattedMessage
              id='Community.Community'
              defaultMessage='Community'
            />
          }
        >
          <Main>
            <PageSection
              title={
                <FormattedMessage
                  id='Community.CompanyInformation'
                  defaultMessage='Company Information'
                />
              }
            >
              <Label>
                <FormattedMessage
                  id='Community.CompanyIdentity'
                  defaultMessage='Company Identity'
                />
              </Label>
              {isTealbot ? (
                <Switch
                  label={
                    <FormattedMessage
                      id='Community.VisibleToSupplier'
                      defaultMessage='Visible to community members'
                    />
                  }
                  labelRight
                  labelFontLight
                  checked={publicProfile}
                  name='publicProfile'
                  onChange={() =>
                    this.handleUpdateCommunityBuyer({
                      publicProfile: !publicProfile,
                      message: '',
                      contacts: []
                    })
                  }
                />
              ) : (
                <Text>
                  {publicProfile ? (
                    <FormattedMessage
                      id='Community.VisibleToSupplier'
                      defaultMessage='Visible to community members'
                    />
                  ) : (
                    <FormattedMessage
                      id='Community.HiddemFromSupplier'
                      defaultMessage='Hidden from community members'
                    />
                  )}
                </Text>
              )}
              {publicProfile && (
                <Fragment>
                  <Label>
                    <FormattedMessage
                      id='Community.MessageToSupplier'
                      defaultMessage='Message to Members'
                    />
                  </Label>
                  {isTealbot ? (
                    <FormattedMessage
                      id='Community.EnterAMessageToSuppliersInCommunity'
                      defaultMessage='Enter a message to members in this community'
                    >
                      {placeholder => (
                        <AutoSaveInput
                          component={TextArea}
                          maxLength={200}
                          value={message}
                          name='message'
                          placeholder={placeholder}
                          onSave={this.handleUpdateCommunityBuyer}
                        />
                      )}
                    </FormattedMessage>
                  ) : (
                    <Text>
                      {message || (
                        <FormattedMessage
                          id='Community/contactSupportForMessageToMembers'
                          defaultMessage='Contact TealBook support to record your message to members'
                        />
                      )}
                    </Text>
                  )}
                </Fragment>
              )}
            </PageSection>
          </Main>
          <Aside>
            {publicProfile && (
              <CommunityContacts
                contacts={contacts}
                onUpdate={this.handleUpdateCommunityBuyer}
                isTealbot={isTealbot}
              />
            )}
          </Aside>
        </Page>
      </Fragment>
    )
  }
}

export default Community
