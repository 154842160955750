import React, { Component } from 'react'
import { connect } from 'react-redux'
import ColleagueCard from '../../components/ColleagueCard'
import usersSelectors from 'shared/selectors/usersSelectors'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import RootState from 'shared/models/RootState'
import { List } from 'immutable'

type Props = {
  offerings: List<string>
  differentiators: List<string>
  userId: string
  firstName: string
  lastName: string
  profilePictureUrl: string
}
export class ColleagueCardContainer extends Component<Props> {
  render() {
    const {
      offerings,
      differentiators,
      userId,
      firstName,
      lastName,
      profilePictureUrl
    } = this.props

    const tags =
      (offerings && offerings.merge(differentiators)) || differentiators

    return (
      <ColleagueCard
        tags={tags && tags.toList()}
        userId={userId}
        firstName={firstName}
        lastName={lastName}
        profilePictureUrl={profilePictureUrl}
      />
    )
  }
}

type ContainerProps = {
  cardId: string
}
export default connect((state: RootState, props: ContainerProps) => {
  const card = cardsSelectors.getById(state, props.cardId)
  const user = usersSelectors.getById(state, card.get('owner'))

  return {
    userId: card.get('owner'),
    firstName: user.get('firstName'),
    lastName: user.get('lastName'),
    profilePictureUrl: user.get('profilePictureUrl'),
    offerings: card.get('offerings'),
    differentiators: card.get('differentiators')
  }
})(ColleagueCardContainer)
