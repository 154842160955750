import { connect } from 'react-redux'
import companySelectors from '../../../shared/selectors/companySelectors'
import {
  startEditingCompanyHeader,
  removeBackgroundPicture,
  uploadFile,
  deleteLogo
} from '../../actions'
import CompanyProfileHeader from '../../components/CompanyProfileHeader'
import RootState from 'shared/models/RootState'
import sessionSelectors from 'shared/selectors/sessionSelectors'

export default connect(
  (state: RootState) => ({
    ...companySelectors.getCompanyHeaderInfo(state),
    isTealbot: sessionSelectors.userHasRole(state, 'tealbot')
  }),
  {
    onEdit: startEditingCompanyHeader,
    removeBackgroundPicture,
    uploadFile,
    onDeleteLogo: deleteLogo
  }
)(CompanyProfileHeader)
