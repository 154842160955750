import React, { Component, Fragment } from 'react'
import Page from 'shared/components/Page'
import { FormattedMessage } from 'react-intl'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import Grid from '@material-ui/core/Grid'
import TargetClient from '../../../Target/components/TargetClient'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import Divider from 'shared/components/Divider'
import { List, RecordOf } from 'immutable'
import { PrivateClient, PublicClient } from 'shared/models/SupplierState'
import Loading from 'shared/components/Loading'
import ScrollToPosition from 'shared/components/ScrollToPosition'

type Props = {
  isLoading: boolean
  isClientApp?: boolean
  language: string
  publicClients: List<RecordOf<PublicClient>>
  privateClients: List<RecordOf<PrivateClient>>
}

class CommunityDetails extends Component<Props> {
  render() {
    const {
      publicClients,
      privateClients,
      isLoading,
      isClientApp,
      language
    } = this.props
    const hasPrivateClients = privateClients && privateClients.size > 0
    const hasPublicClients = publicClients && publicClients.size > 0

    if (isLoading) {
      return <Loading />
    }
    return (
      <Page title={'Community Details'}>
        {isClientApp && <ScrollToPosition to={220} strict='up' />}
        {!hasPrivateClients && !hasPublicClients && (
          <section className='pt4'>
            <header className='pl3-5 pb2 dt w-100'>
              <div className='f5-l f6 fw6 ma0 pb2 dtc v-btm'>
                <FormattedMessage
                  id='CommunityDetailsContainer.NoBuyersYet'
                  defaultMessage="There aren't any buyers in this community yet"
                />
              </div>
            </header>
          </section>
        )}
        {publicClients && publicClients.size > 0 && (
          <Fragment>
            <section className='pt4'>
              <header className='pl3-5 pb2 dt w-100'>
                <div className='f5-l f6 fw6 ma0 pb2 dtc v-btm'>
                  <FormattedMessage
                    id='CommunityDetailsContainer.PublicBuyers'
                    defaultMessage='Public Buyers'
                  />
                </div>
              </header>
            </section>
            <Grid container spacing={2}>
              {publicClients &&
                publicClients.map(
                  client =>
                    client && (
                      <Grid item md={4} sm={6} xs={12} key={client.get('id')}>
                        <TargetClient
                          clientId={!isClientApp ? client.get('id') : undefined}
                          name={client.get('name')}
                          description={
                            !client.get('buyerDescription') ||
                            typeof client.get('buyerDescription') === 'string'
                              ? client.get('buyerDescription')
                              : client.getIn(['buyerDescription', language]) ||
                                client.getIn(['buyerDescription', 'en'])
                          }
                          websiteUrl={
                            client.getIn(['links', 'websiteUrl']) ||
                            (client.get('domains') &&
                              client.get('domains').size > 0 &&
                              client.get('domains').first())
                          }
                          message={client.get('message')}
                          contacts={client.get('contacts')}
                          logoUrl={
                            fileToUrl(client.get('logo')) ||
                            (client.get('domains') &&
                            client.get('domains').size > 0
                              ? `https://logo.clearbit.com/${client
                                  .get('domains')
                                  .first()}`
                              : '')
                          }
                          address={client.getIn(['locations', '0'])}
                        />
                      </Grid>
                    )
                )}
            </Grid>
          </Fragment>
        )}

        {publicClients &&
          publicClients.size > 0 &&
          privateClients &&
          privateClients.size > 0 && <Divider className='mv4' />}

        {privateClients && privateClients.size > 0 && (
          <Fragment>
            <section className='pt4'>
              <header className='pl3-5 pb2 dt w-100'>
                <div className='f5-l f6 fw6 ma0 pb2 dtc v-btm'>
                  <FormattedMessage
                    id='CommunityDetailsContainer.PrivateBuyers'
                    defaultMessage='Private Buyers'
                  />
                </div>
              </header>
            </section>
            <Grid container spacing={2}>
              {privateClients &&
                privateClients.map((client, i) => (
                  <Grid item md={4} sm={6} xs={12} key={i}>
                    <TargetClient
                      name={client.get('categorization')}
                      clientIsPrivate
                    />
                  </Grid>
                ))}
            </Grid>
          </Fragment>
        )}
        <ClearbitCredit />
      </Page>
    )
  }
}

export default CommunityDetails
