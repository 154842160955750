import React, { useEffect } from 'react'
import JobsAppBar from './components/JobsAppBar'
import { getJobs } from './actions'
import { useDispatch } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router'
import paths from './routes'
import { useSelector } from 'react-redux'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import JobsList from './components/JobsList'
import JobLoader from './components/JobLoader'
import JobsResults from './components/JobsResults'
import Job from './components/Job'
import RootState from 'shared/models/RootState'
import UserProfileContainer from 'shared/containers/UserProfileContainer'
import EditUserProfileContainer from 'shared/containers/EditUserProfileContainer'

const App = () => {
  const dispatch = useDispatch()

  const canLoadJob = useSelector((state: RootState) =>
    sessionSelectors.userHasRole(state, ['serverAdmin', 'orgAdmin'])
  )

  useEffect(() => {
    dispatch(getJobs())
  }, [dispatch])

  return (
    <>
      <JobsAppBar />
      <div className='mw8 center ph3'>
        <Switch>
          <Route path={paths.list} component={JobsList} />
          <Route path={paths.detail} component={Job} />
          <Route
            path={paths.userprofile}
            render={props => (
              <UserProfileContainer
                basePath={paths.basePath}
                userId={props.match.params.userId}
                history={props.history}
              />
            )}
          />
          <Route
            path={paths.editUserProfile}
            render={props => (
              <EditUserProfileContainer
                basePath={paths.basePath}
                userId={props.match.params.userId}
                history={props.history}
              />
            )}
          />
          {canLoadJob && <Route path={paths.loader} component={JobLoader} />}
          {canLoadJob && <Route path={paths.results} component={JobsResults} />}
          <Redirect from={paths.basePath} to={paths.jobs} />
        </Switch>
      </div>
    </>
  )
}

export default App
