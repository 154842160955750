import React, { ChangeEvent, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Page from 'shared/components/Page'
import Grid from '@material-ui/core/Grid'
import QuickFactBox from 'shared/components/QuickFactBox'
import QuickFactLine from 'shared/components/QuickFactLine'
import ProgressBar from 'shared/components/ProgressBar'
import ProgressPie from 'shared/components/ProgressPie'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import InsightTitle from 'shared/components/InsightTitle'
import { FormattedMessage } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { loadConsolidation } from '../../store/actions'
import insightsSelectors from '../../store/insightsSelectors'
import Loading from 'shared/components/Loading'
import TopConsolidatedProfiles from '../TopConsolidatedProfiles'
import RootState from 'shared/models/RootState'
import WarningProfiles from '../WarningProfiles'
import useSmallScreen from 'shared/utils/useSmallScreen'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import Select from 'shared/components/Select'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 1),
      boxShadow: 'none',
      border: 'solid 1px rgba(0, 0, 0, 0.1)'
    }
  })
)

const styles = {
  list: 'lh-copy f7 fw4 mid-gray ma0'
}

export const ConsolidationPage = () => {
  const dispatch = useDispatch()
  const isLoadingConsolidation = useSelector((state: RootState) =>
    state.getIn(['buyer', 'insights', 'isLoadingConsolidation'])
  )
  const classes = useStyles()
  const isMobile = useSmallScreen()
  const consolidationData = useSelector(insightsSelectors.getConsolidation)
  const completeness = consolidationData.get('completeness')

  const attributes = useSelector(settingsSelectors.getRelationshipAttributes)
  const [filter, setFilter] = useState<string>('')

  const {
    buyerAddresses,
    buyerEmails,
    buyerTags,
    internalIdsMatched = 0,
    internalIdsUnMatched = 0,
    profilesAddresses,
    profilesConsolidated = 0,
    profilesEmails,
    profilesTags,
    profilesMultipleIds = 0
  } = consolidationData?.toJS() || {}

  useEffect(() => {
    dispatch(loadConsolidation({ relationship: filter }))
  }, [dispatch, filter])

  return (
    <Page title='Consolidation'>
      <ScrollToPosition />
      <div className='flex items-center justify-end'>
        <label
          htmlFor='getRelationshipAttributes'
          className='mid-gray f7 fw6 mr2 mv0'
        >
          <FormattedMessage
            id='ConsolidationPage.Filter'
            defaultMessage='Corporate Relationship'
          />
        </label>
        <Select
          id='getRelationshipAttributes'
          value={filter}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setFilter(e.currentTarget.value)
          }
        >
          <FormattedMessage id='All' defaultMessage='All'>
            {message => <option value=''>{message}</option>}
          </FormattedMessage>
          {attributes?.entrySeq().map(([key, value]) => (
            <option value={key} key={key}>
              {value.get('label')}
            </option>
          ))}
        </Select>
      </div>

      <section aria-live='polite'>
        {isLoadingConsolidation ? (
          <Loading />
        ) : (
          <>
            {!profilesConsolidated ? (
              <>
                <InsightTitle>
                  <FormattedMessage id='ConsolidationPage.QuickFacts' />
                </InsightTitle>
                <Paper className={classes.root}>
                  <div className='pa3-5'>
                    <h5 className='mid-gray f7 black fw6 ma0'>
                      <FormattedMessage
                        id='ConsolidationPage.NoData'
                        defaultMessage='If you provide TealBook with information about your current suppliers, we can:'
                      />
                    </h5>
                    <ul>
                      <li className={styles.list}>
                        <FormattedMessage
                          id='ConsolidationPage.ProvideTealbookWeCan1'
                          defaultMessage='<bold>Consolidate:</bold> Remove the duplicates and map suppliers that have been purchased or merged.'
                          values={{
                            bold: str => <b>{str}</b>
                          }}
                        />
                      </li>
                      <li className={styles.list}>
                        <FormattedMessage
                          id='ConsolidationPage.ProvideTealbookWeCan2'
                          defaultMessage='<bold>Match:</bold> Link your suppliers to their descriptive profiles.'
                          values={{
                            bold: str => <b>{str}</b>
                          }}
                        />
                      </li>
                      <li className={styles.list}>
                        <FormattedMessage
                          id='ConsolidationPage.ProvideTealbookWeCan3'
                          defaultMessage='<bold>Complete:</bold> Enrich your supplier profiles with tags, addresses and contacts
                        Once this process is complete, check this page get insight into your results.'
                          values={{
                            bold: str => <b>{str}</b>
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                </Paper>
              </>
            ) : (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <InsightTitle>
                      <div className='flex justify-between'>
                        <FormattedMessage
                          id='ConsolidationPage.QuickFacts'
                          defaultMessage='Quick Facts'
                        />
                      </div>
                    </InsightTitle>
                    <Grid container spacing={1}>
                      {internalIdsUnMatched + internalIdsMatched > 0 && (
                        <Grid item xs={12} sm={6}>
                          <QuickFactBox
                            topLine={
                              <FormattedMessage
                                id='ConsolidationPage.Provided'
                                defaultMessage='Provided'
                              />
                            }
                            bottomLine={
                              <FormattedMessage
                                id='ConsolidationPage.SupplierIds'
                                defaultMessage='Supplier IDs'
                              />
                            }
                            value={internalIdsUnMatched + internalIdsMatched}
                          />
                        </Grid>
                      )}
                      {internalIdsMatched > 0 && (
                        <Grid item xs={12} sm={6}>
                          <QuickFactBox
                            topLine={
                              internalIdsMatched /
                                (internalIdsUnMatched + internalIdsMatched) >
                              0 ? (
                                <FormattedMessage
                                  id='ConsolidationPage.MatchedPercentage'
                                  defaultMessage='Matched {percentage}%'
                                  values={{
                                    percentage: Number(
                                      (internalIdsMatched /
                                        (internalIdsUnMatched +
                                          internalIdsMatched)) *
                                        100
                                    ).toFixed(2)
                                  }}
                                />
                              ) : (
                                <FormattedMessage
                                  id='ConsolidationPage.Matched'
                                  defaultMessage='Matched'
                                />
                              )
                            }
                            bottomLine={
                              <FormattedMessage id='ConsolidationPage.SupplierIds' />
                            }
                            value={internalIdsMatched}
                          />
                        </Grid>
                      )}
                      {profilesConsolidated > 0 && (
                        <Grid item xs={12} sm={6}>
                          <QuickFactBox
                            topLine={
                              (internalIdsMatched - profilesConsolidated) /
                                internalIdsMatched >
                              0 ? (
                                <FormattedMessage
                                  id='ConsolidationPage.ConsolidatedPercentage'
                                  defaultMessage='Consolidated {percentage}%'
                                  values={{
                                    percentage: Number(
                                      ((internalIdsMatched -
                                        profilesConsolidated) /
                                        internalIdsMatched) *
                                        100
                                    ).toFixed(2)
                                  }}
                                />
                              ) : (
                                <FormattedMessage
                                  id='ConsolidationPage.Consolidated'
                                  defaultMessage='Consolidated'
                                />
                              )
                            }
                            bottomLine={
                              <FormattedMessage
                                id='ConsolidationPage.UniqueProfiles'
                                defaultMessage='Unique Profiles'
                              />
                            }
                            value={profilesConsolidated}
                          />
                        </Grid>
                      )}
                      {profilesConsolidated > 0 && (
                        <Grid item xs={12} sm={6}>
                          <QuickFactBox
                            topLine={
                              (profilesConsolidated -
                                completeness.get('diverse')) /
                                profilesConsolidated >
                              0 ? (
                                <FormattedMessage
                                  id='ConsolidationPage.NoCertificationsPercentage'
                                  defaultMessage='No Certification {percentage}%'
                                  values={{
                                    percentage: Number(
                                      ((profilesConsolidated -
                                        completeness.get('diverse')) /
                                        profilesConsolidated) *
                                        100
                                    ).toFixed(2)
                                  }}
                                />
                              ) : (
                                <FormattedMessage
                                  id='ConsolidationPage.OutOfAll'
                                  defaultMessage='From Unique Profiles'
                                />
                              )
                            }
                            bottomLine={
                              <FormattedMessage id='ConsolidationPage.UniqueProfiles' />
                            }
                            value={
                              profilesConsolidated - completeness.get('diverse')
                            }
                          />
                        </Grid>
                      )}
                      {profilesMultipleIds > 0 && (
                        <Grid item xs={12} sm={6}>
                          <QuickFactBox
                            topLine={
                              <FormattedMessage
                                id='ConsolidationPage.WithMultipleIDs'
                                defaultMessage='With Multiple IDs'
                              />
                            }
                            bottomLine={
                              <FormattedMessage
                                id='ConsolidationPage.SupplierProfiles'
                                defaultMessage='Supplier Profiles'
                              />
                            }
                            value={profilesMultipleIds}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <div className='mt3'>
                      <InsightTitle>
                        <FormattedMessage
                          id='ConsolidationPage.SupplierProfileEnrichment'
                          defaultMessage='Supplier Profile Enrichment'
                        />
                      </InsightTitle>
                      <Paper className={classes.root}>
                        <div
                          style={{
                            height: isMobile ? '' : 175
                          }}
                        >
                          {!!profilesTags && (
                            <QuickFactLine
                              description={
                                <FormattedMessage
                                  id='ConsolidationPage.TagsAdded'
                                  defaultMessage='Tags Added'
                                />
                              }
                              value={profilesTags}
                              hidePercentage={
                                !(buyerTags || buyerAddresses || buyerEmails)
                              }
                              percentage={
                                buyerTags &&
                                Math.round(
                                  ((profilesTags - buyerTags) / buyerTags) * 100
                                )
                              }
                              wrapperClasses='ma2'
                              ringColor='moon-gray'
                            />
                          )}
                          {!!profilesAddresses && (
                            <QuickFactLine
                              description={
                                <FormattedMessage
                                  id='ConsolidationPage.AddressesAdded'
                                  defaultMessage='Addresses Added'
                                />
                              }
                              value={profilesAddresses}
                              hidePercentage={
                                !(buyerTags || buyerAddresses || buyerEmails)
                              }
                              percentage={
                                buyerAddresses &&
                                Math.round(
                                  ((profilesAddresses - buyerAddresses) /
                                    buyerAddresses) *
                                    100
                                )
                              }
                              wrapperClasses='ma2'
                              ringColor='moon-gray'
                              color='#78909A'
                            />
                          )}
                          {!!profilesEmails && (
                            <QuickFactLine
                              description={
                                <FormattedMessage
                                  id='ConsolidationPage.ContactsAdded'
                                  defaultMessage='Contacts Added'
                                />
                              }
                              value={profilesEmails}
                              hidePercentage={
                                !(buyerTags || buyerAddresses || buyerEmails)
                              }
                              percentage={
                                buyerEmails &&
                                Math.round(
                                  ((profilesEmails - buyerEmails) /
                                    buyerEmails) *
                                    100
                                )
                              }
                              wrapperClasses='ma2'
                              ringColor='moon-gray'
                            />
                          )}
                        </div>
                      </Paper>
                    </div>
                    <WarningProfiles />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InsightTitle>
                      <FormattedMessage
                        id='ConsolidationPage.Completeness'
                        defaultMessage='Completeness'
                      />
                    </InsightTitle>
                    {!!profilesConsolidated && completeness && (
                      <Paper className={classes.root}>
                        <div className='lh-copy f7 fw4 mid-gray ma2'>
                          <FormattedMessage
                            id='ConsolidationPage.CompletenessMessage'
                            defaultMessage='Out of {number} Supplier Profiles'
                            values={{
                              number: profilesConsolidated.toLocaleString()
                            }}
                          />
                        </div>
                        <div className='mh2 ba b--black-10 br1'>
                          <div className='white pa2 f7 fw4 bg-teal'>
                            <FormattedMessage
                              id='ConsolidationPage.ProfilesWith'
                              defaultMessage='Profiles with...'
                            />
                          </div>
                          <div className='pa2'>
                            <ProgressBar
                              label={
                                <FormattedMessage
                                  id='ConsolidationPage.Address'
                                  defaultMessage='Address'
                                />
                              }
                              percentage={Math.round(
                                (completeness.get('addresses') /
                                  profilesConsolidated) *
                                  100
                              )}
                              value={completeness.get('addresses')}
                            />
                            <ProgressBar
                              label={
                                <FormattedMessage
                                  id='ConsolidationPage.Contact'
                                  defaultMessage='Contact'
                                />
                              }
                              percentage={Math.round(
                                (completeness.get('contacts') /
                                  profilesConsolidated) *
                                  100
                              )}
                              value={completeness.get('contacts')}
                            />
                            <ProgressBar
                              label={
                                <FormattedMessage
                                  id='ConsolidationPage.Tag'
                                  defaultMessage='Tag'
                                />
                              }
                              percentage={Math.round(
                                (completeness.get('tags') /
                                  profilesConsolidated) *
                                  100
                              )}
                              value={completeness.get('tags')}
                            />
                            <ProgressBar
                              label={
                                <FormattedMessage
                                  id='ConsolidationPage.NAICS'
                                  defaultMessage='NAICS'
                                />
                              }
                              percentage={Math.round(
                                (completeness.get('naics') /
                                  profilesConsolidated) *
                                  100
                              )}
                              value={completeness.get('naics')}
                            />
                            <ProgressBar
                              label={
                                <FormattedMessage
                                  id='ConsolidationPage.Description'
                                  defaultMessage='Description'
                                />
                              }
                              percentage={Math.round(
                                (completeness.get('description') /
                                  profilesConsolidated) *
                                  100
                              )}
                              value={completeness.get('description')}
                            />
                          </div>
                        </div>
                        <div className='w-100 flex flex-wrap justify-center'>
                          <div className='dib w-100 w-25-ns pt3 pb2'>
                            <ProgressPie
                              label={
                                <FormattedMessage
                                  id='ConsolidationPage.OverallScore'
                                  defaultMessage='Overall Average'
                                />
                              }
                              percentage={Math.round(
                                ((completeness.get('description') +
                                  completeness.get('naics') +
                                  completeness.get('tags') +
                                  completeness.get('contacts') +
                                  completeness.get('addresses')) /
                                  5 /
                                  profilesConsolidated) *
                                  100
                              )}
                            />
                          </div>
                        </div>
                      </Paper>
                    )}
                  </Grid>
                </Grid>
                <TopConsolidatedProfiles />
              </>
            )}
          </>
        )}
      </section>
    </Page>
  )
}

export default ConsolidationPage
