import React, { Component } from 'react'
import CreateOrganizationForm from '../../components/CreateOrganizationForm'
import { getFormSubmitErrors } from 'shared/reducers'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { CREATE_ORG } from '../../store/actionTypes'
import orgsSelectors from '../../store/selectors/orgsSelectors'
import { stopCreatingOrg } from '../../store/actions'
import { SubmissionError } from 'redux-form/immutable'
import { List, RecordOf } from 'immutable'
import OrgUnit from 'shared/models/OrgUnit'
import { InjectedFormProps } from 'redux-form'

type Props = {
  isSearching: boolean
  errorMessage: string
  email: string
  matchingOrgs: List<RecordOf<OrgUnit>>
  onCancel: () => void
  createOrg: (formValues: any) => void
} & InjectedFormProps

class CreateOrganizationFormContainer extends Component<Props> {
  handleSubmit = formValues => {
    const { createOrg, matchingOrgs } = this.props

    if (matchingOrgs && matchingOrgs.size > 0) {
      const found = matchingOrgs.find(name => {
        return (
          formValues.get('name') &&
          name.get('name').toUpperCase() ===
            formValues.get('name').toUpperCase()
        )
      })

      if (found) {
        return new Promise(resolve => {
          throw new SubmissionError({
            name: 'Company name is not unique.',
            _error: 'Submission failed!'
          })
        })
      } else {
        createOrg(formValues.set('ignoreMatchingOrgs', true))
      }
    } else {
      createOrg(formValues)
    }
  }

  render() {
    const { handleSubmit, isSearching, ...rest } = this.props

    return (
      <>
        <CreateOrganizationForm
          {...rest}
          onSubmit={handleSubmit(this.handleSubmit)}
          isSearching={isSearching}
        />
      </>
    )
  }
}

const formName = 'dataPortal/newOrg'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)

export default connectReduxForm(
  (state, props) => ({
    errorMessage: formSubmitErrorsSelector(state),
    matchingOrgs: orgsSelectors.getMatchingOrgs(state),
    isSearching: orgsSelectors.isSearchingOrg(state),
    initialValues: {
      orgUnitId: '',
      email: props && props.email
    }
  }),
  {
    createOrg: createSubmitFormAction(formName, CREATE_ORG),
    onCancel: () => stopCreatingOrg()
  },
  {
    form: formName
  },
  CreateOrganizationFormContainer
)
