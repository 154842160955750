import React, { Component } from 'react'
import { connect } from 'react-redux'
import postsSelectors from '../../selectors/postsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import createAddCommentForm from 'shared/utils/createAddCommentForm'
import { ADD_POST_COMMENT } from '../../actionTypes'
import { deleteComment } from '../../actions'
import withUser from 'shared/utils/withUser'
import Comment from '../../components/Comment'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'

const CommentWithUser = withUser(Comment)

type Props = {
  postId: string
  currentUserId: string
  isTealbot: boolean
  deleteComment: ({ postId: string, index: number }) => void
  comments: List<
    RecordOf<{
      user: string
      date: string
      note: string
    }>
  >
}

type State = {
  showAll: boolean
}

export class PostCommentsListContainer extends Component<Props, State> {
  state = {
    showAll: false
  }

  handleShowAll = () => {
    this.setState({ showAll: true })
  }

  handleDeleteComment = index => {
    const { postId, deleteComment } = this.props
    deleteComment({ postId, index })
  }

  render() {
    const { postId, comments, currentUserId, isTealbot } = this.props
    const { showAll } = this.state

    const EnterCommentForm = createAddCommentForm({
      formName: `posting/enterComment/${postId}`,
      submitFormActionType: ADD_POST_COMMENT
    })

    return (
      <div className='pa3-5 bg-near-white'>
        {comments && comments.size > 5 && !showAll && (
          <div className='pb2'>
            <Button
              variant='text'
              autoSize
              label={
                <FormattedMessage
                  id='PostCommentsListContainer.ViewMoreComments'
                  defaultMessage='View {viewMoreComments} more comments'
                  values={{ viewMoreComments: comments.size - 5 }}
                />
              }
              onClick={this.handleShowAll}
            />
          </div>
        )}
        {comments &&
          comments
            .takeLast(showAll ? comments.size : 5)
            .map((comment, index) => {
              return (
                <CommentWithUser
                  key={`${postId}-comment-${index}`}
                  userId={comment.get('user')}
                  createdDate={comment.get('date')}
                  body={comment.get('note')}
                  onDelete={
                    comment.get('user') === currentUserId || isTealbot
                      ? () => this.handleDeleteComment(index)
                      : undefined
                  }
                />
              )
            })}
        <FormattedMessage
          id='PostCommentsListContainer.AddCommentPlaceHolder'
          defaultMessage='Add a comment...'
        >
          {message => (
            <EnterCommentForm inputPlaceholder={message} noSubmitButton />
          )}
        </FormattedMessage>
      </div>
    )
  }
}

export default connect(
  (state: RootState, props: { postId: string }) => {
    const currentUser = sessionSelectors.getUser(state)
    const currentUserId = currentUser && currentUser.get('id')
    const isTealbot =
      currentUser && currentUser.get('roles').includes('tealbot')
    const comments = postsSelectors.getPostComments(state, props.postId)

    return {
      currentUserId,
      isTealbot,
      comments
    }
  },
  { deleteComment }
)(PostCommentsListContainer)
