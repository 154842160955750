import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import TealbotTips from 'shared/components/TealbotTips'
import Scrollable from 'shared/components/Scrollable'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import createTagsForm from 'shared/utils/createTagsForm'
import { ADD_TAGS, REMOVE_TAGS } from '../../../Company/actionTypes'
import { addTags } from '../../../Company/actions'
import companySelectors from '../../selectors/companySelectors'
import OurCustomersSectionContainer from '../../../Company/containers/OurCustomersSectionContainer'
import DescriptionForm from './DescriptionForm'
import AboutUsForm from './AboutUsForm'
import AddressForm from './AddressForm'
import ResourcesForm from './ResourcesForm'
import OurTeamSectionContainer from '../../../Company/containers/OurTeamSectionContainer'
import DetailsSectionContainer from '../../../Company/containers/DetailsSectionContainer'
import { FormattedMessage } from 'react-intl'

type Props = {
  forms: Array<string>
  messageMapping: Object
  index: number
  onCloseClick: () => void
  addTags: (params: { fieldName: string; newTag: string }) => void
}

const styles = {
  wrapper: 'bg-white br1 ba b--black-10 overflow-hidden ph3-5 pb3-5',
  noPaddingWrapper: 'bg-white br1 ba b--black-10'
}

const OfferingsTagsForm = createTagsForm({
  formName: 'supplier/company/offerings',
  fieldName: 'offerings',
  inputPlaceholder: 'Skill (ex: Advertising or Digital Marketing)',
  addLabel: 'Add a skill',
  addTagActionType: ADD_TAGS,
  removeTagActionType: REMOVE_TAGS,
  tagsSelector: companySelectors.getCompanySupplierField,
  limit: 100
})

export class CompanyWizard extends Component<Props> {
  state = {
    index: 0,
    tag: ''
  }

  componentDidMount() {
    this.setState({
      index: this.props.index
    })
  }

  nextForm = () => {
    const { forms, addTags } = this.props
    const { index, tag } = this.state

    if (forms[index] === 'skills' && tag !== '') {
      addTags({
        fieldName: 'offerings',
        newTag: tag
      })
    }

    this.setState({
      index: index + 1 === forms.length ? 0 : index + 1,
      tag: ''
    })
  }

  handleTagFieldChange = tag => {
    this.setState({
      tag
    })
  }

  loadForm(form) {
    switch (form) {
      case 'description':
        return (
          <div className={styles.wrapper}>
            <DescriptionForm />
          </div>
        )
      case 'aboutUs':
        return (
          <div className={styles.wrapper}>
            <AboutUsForm />
          </div>
        )
      case 'address':
        return (
          <div className={styles.wrapper}>
            <AddressForm />
          </div>
        )
      case 'resources':
        return (
          <div className={styles.wrapper}>
            <ResourcesForm />
          </div>
        )
      case 'skills':
        return (
          <div className={styles.noPaddingWrapper}>
            <OfferingsTagsForm
              showButton
              onChange={(event, newValue, previousValue) => {
                if (typeof newValue === 'string') {
                  this.handleTagFieldChange(newValue)
                }
              }}
            />
          </div>
        )
      case 'locations':
        return (
          <div className={styles.noPaddingWrapper}>
            <DetailsSectionContainer />
          </div>
        )
      case 'customers':
        return (
          <div className={styles.noPaddingWrapper}>
            <OurCustomersSectionContainer />
          </div>
        )
      case 'team':
        return (
          <div className={styles.noPaddingWrapper}>
            <OurTeamSectionContainer />
          </div>
        )
      default:
        return form
    }
  }

  render() {
    const { forms, messageMapping } = this.props
    const { index } = this.state

    return forms && forms.length ? (
      <Fragment>
        <TealbotTips noLabel noBorder>
          <Text>{messageMapping[forms[index]]}</Text>
        </TealbotTips>
        <Scrollable>{this.loadForm(forms[index])}</Scrollable>
        <div className='mv3'>
          {forms && forms.length > 1 && (
            <Button
              label={
                <FormattedMessage
                  id='CompanyWizard.NextTip'
                  defaultMessage='Next Tip'
                />
              }
              autoSize
              onClick={this.nextForm}
              className='mr3'
            />
          )}
          <Button
            label={
              <FormattedMessage id='CompanyWizard.Done' defaultMessage='Done' />
            }
            autoSize
            onClick={this.props.onCloseClick}
            secondary
          />
        </div>
      </Fragment>
    ) : null
  }
}

export default connect(null, { addTags })(CompanyWizard)
