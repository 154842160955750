import React, { Component, HTMLProps } from 'react'

type Props = {
  color: string
  size: number
  direction: string
} & HTMLProps<HTMLSpanElement>

class Triangle extends Component<Props> {
  render() {
    const { color, size, direction: d, style, ...rest } = this.props
    const baseStyle = {
      width: 0,
      height: 0,
      display: 'inline-block',
      borderStyle: 'solid',
      borderColor: `${d === 'down' ? color : 'transparent'} ${
        d === 'left' ? color : 'transparent'
      } ${d === 'up' ? color : 'transparent'} ${
        d === 'right' ? color : 'transparent'
      }`,
      borderWidth: `${d === 'up' ? 0 : size}px ${d === 'right' ? 0 : size}px ${
        d === 'down' ? 0 : size
      }px ${d === 'left' ? 0 : size}px`
    }
    const mergeStyle = Object.assign({}, baseStyle, style)

    return <span style={mergeStyle} {...rest} />
  }
}

export default Triangle
