export type Feature =
  | 'supplierOutreach'
  | 'reportSnapshot'
  | 'ecovadisRating'
  | 'selfCertificationRequest'
  | 'ESGSurveyRequest'
  | 'ESGSurveyPhase2'

const appTeam = [
  'ed@tealbook.com',
  'william@tealbook.com',
  'ali@tealbook.com',
  'able@tealbook.com',
  'chukwuka@tealbook.com',
  'dana@tealbook.com',
  'nakul@tealbook.com',
  'elizabeth@tealbook.com',
  'carina.yang@tealbook.com',
  'jason.zhou@tealbook.com'
]
const prodTeam = [
  'mouli@tealbook.com',
  'shelley@tealbook.com',
  'lucas@tealbook.com',
  'amanda@tealbook.com',
  'suroor@tealbook.com',
  'sarah@tealbook.com',
  'judy.lei@tealbook.com',
  'derek.bell@tealbook.com',
  'soozin.kang@tealbook.com'
]
const supplierExperienceTeam = [
  'marina@tealbook.com',
  'abhay@tealbook.com',
  'amil.baljic@tealbook.com'
]
const enabledFeaturesByUserEmail: { [key in Feature]: string[] } = {
  supplierOutreach: [
    'concierge@tealbook.com',
    'jfranco@tealbook.com',
    'tkoopmans@tealbook.com',
    'lisa@tealbook.com',
    'jfranco@tealbook.com',
    'sheri.rouse@parkland.ca'
  ]
    .concat(prodTeam)
    .concat(appTeam)
    .concat(supplierExperienceTeam),
  selfCertificationRequest: [
    'concierge@tealbook.com',
    'jfranco@tealbook.com',
    'tkoopmans@tealbook.com',
    'lisa@tealbook.com'
  ]
    .concat(prodTeam)
    .concat(appTeam)
    .concat(supplierExperienceTeam),
  reportSnapshot: [
    'concierge@tealbook.com',
    'lisa@tealbook.com',
    'carlo@tealbook.com',
    'jillian@tealbook.com',
    'mtsoi@ea.com',
    'jessica.kiser@ttec.com',
    'tuhina.nandi@ttec.com'
  ]
    .concat(appTeam)
    .concat(prodTeam),
  ecovadisRating: [
    'concierge@tealbook.com',
    'tyler@tealbook.com',
    'jfranco@tealbook.com'
  ]
    .concat(appTeam)
    .concat(prodTeam),
  ESGSurveyRequest: ['concierge@tealbook.com', 'sheri.rouse@parkland.ca']
    .concat(appTeam)
    .concat(prodTeam)
    .concat(supplierExperienceTeam),
  ESGSurveyPhase2: ['concierge@tealbook.com']
    .concat(appTeam)
    .concat(prodTeam)
    .concat(supplierExperienceTeam)
}

type Options = {
  feature: Feature
  userEmail: string
}
const isFeatureEnabled = ({ feature, userEmail }: Options): boolean =>
  enabledFeaturesByUserEmail[feature].some(email => email === userEmail)

export default isFeatureEnabled
