import { Record, fromJS } from 'immutable'
import fullNameParser from 'shared/utils/data/fullName/parser'

import {
  OPEN_ADD_COLLABORATE_CONTACT_FORM,
  CANCEL_ADDING_SURVEY_COLLABORATE_CONTACT,
  ADD_SURVEY_COLLABORATE_CONTACT
} from '../../actionTypes'
import { requestSuccess } from 'shared/actionTypes'

const NewSurveyCollaborateContactRecord = Record({
  selectedUserId: '',
  newUser: fromJS({}),
  addSuccess: false
})

const newCollaborateContactReducer = (
  state = new NewSurveyCollaborateContactRecord(),
  action
) => {
  switch (action.type) {
    case OPEN_ADD_COLLABORATE_CONTACT_FORM:
      const { ...userInfo } = fullNameParser(action.payload)

      return state.set('newUser', fromJS({ ...userInfo }))

    case CANCEL_ADDING_SURVEY_COLLABORATE_CONTACT:
      return new NewSurveyCollaborateContactRecord()

    case requestSuccess(ADD_SURVEY_COLLABORATE_CONTACT):
      return state
        .set('addSuccess', true)
        .set('selectedUserId', action.payload.user.id)

    default:
      return state
  }
}

export default newCollaborateContactReducer
