import React, { Component, SyntheticEvent } from 'react'
import Popover from '@material-ui/core/Popover'
import searchFaq from 'shared/assets/images/search-faq.png'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import CompanyDetailsFaqImage from './company-details-faq.png'

const styles = {
  wrapper: 'pa3',
  faqHeader: 'f5 fw6 v-mid ma0 mv2',
  faqText: 'mb2',
  faqListGroup: 'pl4',
  faqList: 'lh-copy f7 fw4 mid-gray ma0 mb2',
  spacing: 'mr2'
}

type Props = {
  open: boolean
  anchorEl: HTMLElement | null
  onClose: (event: SyntheticEvent<HTMLElement>) => void
}

export class SearchFaq extends Component<Props> {
  render() {
    return (
      <Popover
        {...this.props}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className={styles.wrapper} style={{ maxWidth: 600 }}>
          <h5 className={styles.faqHeader}>
            <FormattedMessage
              id='SearchContainer.FaqHeader1'
              defaultMessage='Supplier Search'
            />
          </h5>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1'
              defaultMessage='Tealbook helps you find the right suppliers in seconds using our advanced search capability. It is easy to use and gives you access to hundreds of thousands of supplier profiles. You can open the Supplier Search page at any time by clicking on the Suppliers link in the menu.'
            />
          </Text>
          <img src={searchFaq} alt='Search screen' style={{ width: '100%' }} />

          <Label>
            <FormattedMessage
              id='SearchContainer.CompanyDetails'
              defaultMessage='Company Details'
            />
          </Label>
          <img
            src={CompanyDetailsFaqImage}
            alt='Company Details FAQ'
            style={{ width: '100%' }}
          />

          <Label>
            <FormattedMessage
              id='SearchContainer.FaqSubHeader1-1'
              defaultMessage='Searching: Company Name'
            />
          </Label>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-1-1'
              defaultMessage='If you know the name of the company you are searching for, just type it into the search box. If you pause, TealBook will list companies that match the name in a drop down. You can click on a name to display the profile for that company.'
            />
          </Text>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-1-2'
              defaultMessage='Note: If you press enter or click the Search button after typing in the name of a company, the list will display all companies related the words in that name.'
            />
          </Text>
          <Label>
            <FormattedMessage
              id='SearchContainer.FaqSubHeader1-2'
              defaultMessage='Searching: Key Words'
            />
          </Label>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-2-1'
              defaultMessage='You can perform a search for any words, just type them into the search field and press Enter or click the Search button. You can use words from the name of the company, or words describing what they do or sell.'
            />
          </Text>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-2-2'
              defaultMessage='The search is not case sensitive. It will find all suppliers who have used the words you enter to describe their company and/or offerings. The order of the words is ignored unless you put them in double quotes.'
            />
          </Text>
          <Label>
            <FormattedMessage
              id='SearchContainer.FaqSubHeader1-3'
              defaultMessage='Searching: Popular Searches'
            />
          </Label>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-3-1'
              defaultMessage='Below the search field you will see a list of terms. These are the most popular search terms relative to your current search. They will change as you refine your search with key words or filters. To add one of these terms to the search, just click on it. The list will re-build automatically.'
            />
          </Text>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-3-2'
              defaultMessage='The search is not case sensitive. It will find all suppliers who have used the words you enter to describe.'
            />
          </Text>
          <Label>
            <FormattedMessage
              id='SearchContainer.FaqSubHeader1-4'
              defaultMessage='Searching: Filters'
            />
          </Label>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-4-1'
              defaultMessage='Tealbook supports a broad range of filters to quickly find the suppliers you are looking for. You will see a number next to each filter, this is the number of suppliers that will be returned if you pick that filter.'
            />
          </Text>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-4-2'
              defaultMessage='Note: TealBook does not show filter options that would return no results, so the list of available filters will change as you modify your search.'
            />
          </Text>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-4-3'
              defaultMessage='The available filters include:'
            />
          </Text>
          <ul className={styles.faqListGroup}>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-4-3-1b'
                  defaultMessage='Corporate Relationship:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-4-3-1'
                defaultMessage='Lets you limit the list to show only results based on your company’s relationship with them, this includes the “Existing” option you used in the previous exercise.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-4-3-2b'
                  defaultMessage='Location:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-4-3-2'
                defaultMessage='Enables you to filter by location. It opens set to country and showing the top matches (by number of suppliers). If you click on a country, it will provide additional options (usually states or provinces) within the country. If you pick a state or province, it will display cities. Alternatively, you can type in the name of a country, city or state in the search area in this filter and then press Enter or click the adjacent Search button to see the list of locations matching what you entered. Click the one you want.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-4-3-3b'
                  defaultMessage='Connection:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-4-3-3'
                defaultMessage='The connection filter allows you to limit the results to suppliers you are personally following or those your colleagues are following.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-4-3-4b'
                  defaultMessage='Certifications:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-4-3-4'
                defaultMessage='Limits the list to suppliers who have the selected certification. If you select a category of certification, TealBook will show you the options within it. For example, if you select Diversity you will see the available diversity certifications, like SBE (Small Business) and WBE (Woman-Owned Business).'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-4-3-5b'
                  defaultMessage='Attachments:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-4-3-5'
                defaultMessage='Limits the list to suppliers where someone in your company has attached a specified type of file to their profile. If you select this filter you will get an additional option to limit the list to suppliers where there is no expiry date on the attachment or the expiry date has not yet passed.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-4-3-6b'
                  defaultMessage='Communities:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-4-3-6'
                defaultMessage='Limits the list to suppliers who have joined or been invited to a specified community.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-4-3-7b'
                  defaultMessage='Community Status:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-4-3-7'
                defaultMessage='Only visible if a community is selected, this filter allows you to limit based on whether the suppliers have been a pending invitation, accepted the invitation or declined it.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-4-3-8b'
                  defaultMessage='Personal Relationship:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-4-3-8'
                defaultMessage='Only visible if you have created personal tags or saved lists.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-4-3-9b'
                  defaultMessage='Internal Category:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-4-3-9'
                defaultMessage='Allows you to quickly filter suppliers based on the category your company has assigned them to.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-4-3-10b'
                  defaultMessage='Rating:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-4-3-10'
                defaultMessage='Limit the results based on the rating the supplier has been assigned by your company.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-4-3-11b'
                  defaultMessage='Spend Percentile:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-4-3-11'
                defaultMessage='Allows you to select the percentiles of spend to display, like High or Low.'
              />
            </li>
          </ul>
          <Label>
            <FormattedMessage
              id='SearchContainer.FaqSubHeader1-5'
              defaultMessage='Searching: Similar Suppliers'
            />
          </Label>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-5-1'
              defaultMessage='For many suppliers, TealBook has analyzed their profiles and found similar suppliers using our machine learning algorithms.'
            />
          </Text>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-5-2'
              defaultMessage='To find similar suppliers to one or more companies:'
            />
          </Text>
          <ol className={styles.faqListGroup}>
            <li className={styles.faqList}>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-5-2-1'
                defaultMessage='Add the suppliers to your basket by clicking the basket icon below their names in the search list or on their profile page.'
              />
            </li>
            <li className={styles.faqList}>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-5-2-2'
                defaultMessage='Go to you Search list, you will see the list of selected suppliers on the right side of the list.'
              />
            </li>
            <li className={styles.faqList}>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-5-2-3'
                defaultMessage='Select the Find Similar Suppliers from the options below the selected suppliers and click OK.'
              />
            </li>
          </ol>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-5-3'
              defaultMessage='You can now apply filters, or search terms, to narrow your results.'
            />
          </Text>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-5-4'
              defaultMessage='Note: You add new suppliers to your list of selected suppliers at any time and re-run the Find Similar Suppliers action.'
            />
          </Text>
          <Label>
            <FormattedMessage
              id='SearchContainer.FaqSubHeader1-6'
              defaultMessage='Searching: Sorting Results'
            />
          </Label>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-6-1'
              defaultMessage='In TealBook the results of a search can be sorted in three ways:'
            />
          </Text>
          <ul className={styles.faqListGroup}>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-6-1-1b'
                  defaultMessage='Connection:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-6-1-1'
                defaultMessage='This lists the companies by relevance to the search but with those with the strongest connection to your company or companies like you at the top.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-6-1-2b'
                  defaultMessage='Relevance:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-6-1-2'
                defaultMessage='This ignores connection and focuses the sort only on relevance to the search terms. This is the default.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-6-1-3b'
                  defaultMessage='Name:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-6-1-3'
                defaultMessage='This sorts the returned list by the name of the company.'
              />
            </li>
            <li className={styles.faqList}>
              <b className={styles.spacing}>
                <FormattedMessage
                  id='SearchContainer.FaqParagraph1-6-1-4b'
                  defaultMessage='Spend:'
                />
              </b>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-6-1-4'
                defaultMessage='Sorts from highest to lowest spend, only applicable once your company has loaded spend data.'
              />
            </li>
          </ul>
          <Label>
            <FormattedMessage
              id='SearchContainer.FaqSubHeader1-7'
              defaultMessage='Exporting the Suppliers'
            />
          </Label>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-7-1'
              defaultMessage='You can export a list of suppliers by:'
            />
          </Text>
          <ol className={styles.faqListGroup}>
            <li className={styles.faqList}>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-7-1-1'
                defaultMessage='Add the suppliers to your basket by clicking the basket icon below their names in the search list or on their profile page.'
              />
            </li>
            <li className={styles.faqList}>
              <FormattedMessage
                id='SearchContainer.FaqParagraph1-7-1-2'
                defaultMessage='Go to you Search list, you will see the list of selected suppliers on the right side of the list.'
              />
            </li>
            <li className={styles.faqList}>
              <FormattedMessage
                id='SearchContainer.RFIFaqParagraph1-7-1-3a'
                defaultMessage='Select the Start an RFI from the options below the selected suppliers and click OK.'
              />
            </li>
            <li className={styles.faqList}>
              <FormattedMessage
                id='SearchContainer.RFIFaqParagraph1-7-1-4'
                defaultMessage='Click on the … in the upper right of the RFI page and select Export Suppliers.'
              />
            </li>
          </ol>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph1-7-2'
              defaultMessage='The results will download as a CSV file.'
            />
          </Text>
          <h5 className={styles.faqHeader}>
            <FormattedMessage
              id='SearchContainer.FaqHeader2'
              defaultMessage='Frequently Asked Questions'
            />
          </h5>
          <Label>
            <FormattedMessage
              id='SearchContainer.FaqSubHeader2-1'
              defaultMessage='I found a supplier in a search but the word I used was nowhere in their record. Why did they show up?'
            />
          </Label>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph2-1-1'
              defaultMessage='For many suppliers, our search includes text pulled from their websites that we couldn’t format nicely, so we don’t show it but do use it in matching search terms.'
            />
          </Text>
          <Label>
            <FormattedMessage
              id='SearchContainer.FaqSubHeader2-2'
              defaultMessage='How does TealBook determine the order to list suppliers in the search results?'
            />
          </Label>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph2-2-1'
              defaultMessage='We look at each supplier to see how relevant they are based on where the term was used in their profile and how frequently, has the term be reinforced by anyone, how connected is their company to yours and how connected are they to other TealBook clients.'
            />
          </Text>
          <Label>
            <FormattedMessage
              id='SearchContainer.FaqSubHeader2-3'
              defaultMessage='I selected a supplier, why isn’t the similar suppliers action listed?'
            />
          </Label>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph2-3-1'
              defaultMessage='The supplier was probably added to TealBook recently. We have millions of records in TealBook and it takes a while to work through the new ones and build out their similar suppliers. '
            />
          </Text>
          <Label>
            <FormattedMessage
              id='SearchContainer.FaqSubHeader2-4'
              defaultMessage='I use the similar suppliers feature, why did TealBook list a supplier that is not similar?'
            />
          </Label>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='SearchContainer.FaqParagraph2-4-1'
              defaultMessage='For many suppliers, our search includes text pulled from their websites that we couldn’t format nicely, so we don’t show it but do use it in matching search terms.'
            />
          </Text>
        </div>
      </Popover>
    )
  }
}

export default SearchFaq
