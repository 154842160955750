import React, {
  ComponentType,
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  MouseEvent,
  ChangeEvent
} from 'react'
import Input from '../Input'
import Text from '../Text'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as UploadIcon } from 'shared/assets/icons/upload.svg'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckIcon from '@material-ui/icons/Check'

const isDragDropSupport = () => {
  const div = document.createElement('div')
  return 'draggable' in div || ('ondragstart' in div && 'ondrop' in div)
}

/**
 * This component displays an html text box like:
 * <input/>
 * with extra error handling, usually through React Forms Passing values <br/>
 * When used with redux forms the value of the text box is saved to the redux form state
 */
type Props = {
  input?: {
    value?: string
    onChange: (file: string | File | Blob | null) => void
  }
  limit?: number
  accept: string
  name?: string
  autoSave?: boolean
  required?: boolean
  small?: boolean
  value?: string
  isUploading?: boolean
  className?: string
  wrapperClassName?: string
  onSave?: (name: { [x: string]: string | null }) => void
  onChange?: (file: string | File | Blob | null) => void
  component?: ComponentType
}

const styles = {
  notDroppable: {
    background: '#F5FAF9'
  },
  droppable: {
    background: '#F5FAF9',
    outline: '2px dashed #707070',
    outlineOffset: -10
  },
  dragover: {
    background: '#FFFFFF',
    outline: '2px dashed #707070',
    outlineOffset: -15
  }
}

const FileInput: FunctionComponent<Props> = props => {
  const [fileTooBig, setFileTooBig] = useState<boolean>(false)
  const [invalidFileType, setInvalidFileType] = useState<boolean>(false)
  const [dragDrop] = useState<boolean>(isDragDropSupport())
  const [isDragOver, setIsDragOver] = useState<boolean>(false)
  const [filename, setFilename] = useState<string>('')

  const inputRef = useRef<HTMLInputElement>(null)

  const {
    input,
    value,
    onChange,
    limit = 32,
    accept,
    small,
    name,
    autoSave,
    onSave,
    wrapperClassName,
    required,
    small: unused,
    isUploading,
    ...otherProps
  } = props

  const inputValue = input && input.value
  useEffect(() => {
    if (inputRef.current && !value && !inputValue) {
      inputRef.current.value = ''
      setFilename('')
    }
  }, [value, inputValue])

  const handleFile = (file?: any) => {
    const bytesLimit = limit * 1048576

    if (file) {
      const acceptedFileTypes = accept
        .split(',')
        .map(ext => ext.toLowerCase().trim())
      if (
        !acceptedFileTypes.some(fType =>
          file.name.toLowerCase().endsWith(fType)
        )
      ) {
        setInvalidFileType(true)
        setFilename('')
        if (inputRef.current) {
          inputRef.current.value = ''
        }
        file = null
      } else if (file.size > bytesLimit) {
        setFileTooBig(true)
        setFilename('')
        if (inputRef.current) {
          inputRef.current.value = ''
        }
        file = null
      } else {
        setFileTooBig(false)
        setInvalidFileType(false)
        setFilename(file.name)
      }
    } else {
      setFilename('')
    }

    if (onChange) {
      onChange(file)
    }

    if (input && input.onChange) {
      input.onChange(file)
    }

    if (autoSave && onSave && name) {
      onSave({
        [name]: file
      })
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    handleFile(file)
  }

  const handleDragOver = (e: MouseEvent) => {
    e.preventDefault()
    setIsDragOver(true)
  }

  const handleDragLeave = (e: MouseEvent) => {
    e.preventDefault()
    setIsDragOver(false)
  }

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragOver(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFile(e.dataTransfer.files[0])
    }
  }

  const handleClearSelected = () => {
    setFilename('')
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    if (onChange) {
      onChange(null)
    }

    if (input && input.onChange) {
      input.onChange(null)
    }

    if (autoSave && onSave && name) {
      onSave({
        [name]: null
      })
    }
  }

  const errorMessage = fileTooBig ? (
    <FormattedMessage
      id='FileInput.TheFileIsTooBig'
      defaultMessage='The file is too big.'
    />
  ) : invalidFileType ? (
    <FormattedMessage
      id='FileInput.InvalidFileType'
      defaultMessage='Invalid file type'
    />
  ) : (
    ''
  )

  return (
    <div className={wrapperClassName}>
      <div
        className={`${small ? 'pa3' : 'pa3-5'} tc br1`}
        style={
          dragDrop
            ? isDragOver
              ? styles.dragover
              : styles.droppable
            : styles.notDroppable
        }
        onDragOver={handleDragOver}
        onDragEnter={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className={small ? 'flex justify-between items-center tl' : ''}>
          {isUploading ? (
            <div className='animate flex justify-center w-100 pv2'>
              <UploadIcon height={'64'} />
            </div>
          ) : (
            <>
              <div className={small ? 'ml2' : 'tc'}>
                <label htmlFor={name || 'fileInput'} className='dim pointer'>
                  <UploadIcon height={small ? '32' : '64'} />
                </label>
              </div>
              <div className={small ? 'flex-auto pl3' : ''}>
                <label className='dib mv2 pointer dib dim'>
                  <Input
                    name={name || 'fileInput'}
                    accept={accept}
                    required={!filename && required}
                    {...otherProps}
                    type='file'
                    onChange={handleChange}
                    input={{
                      ref: inputRef
                    }}
                    style={{
                      width: 0,
                      height: 0.1,
                      opacity: 0,
                      overflow: 'hidden',
                      position: 'absolute',
                      zIndex: -1
                    }}
                    ariaLabel='File Input'
                  />

                  {filename ? (
                    filename
                  ) : dragDrop ? (
                    <FormattedMessage
                      id='FileInput.DragDrop'
                      defaultMessage='Choose a file or drag it here.'
                    />
                  ) : (
                    <FormattedMessage
                      id='FileInput.ChooseAFile'
                      defaultMessage='Choose a file.'
                    />
                  )}
                </label>
                {filename && (
                  <div className='dib mh2 dim v-mid pointer teal'>
                    {autoSave ? (
                      <CheckIcon />
                    ) : (
                      <CancelIcon
                        aria-label='cancel'
                        onClick={() => handleClearSelected()}
                      />
                    )}
                  </div>
                )}
                {errorMessage && (
                  <div className='f6 fw6 red mb2'>{errorMessage}</div>
                )}
                <div className={small ? 'mb2' : ''}>
                  <Text>
                    <FormattedMessage
                      id='FileInput.MaximumFileSize'
                      defaultMessage='Maximum file size is {limit} MB.'
                      values={{ limit: limit.toLocaleString() }}
                    />
                    <br />
                    <FormattedMessage
                      id='FileInput.SupportedFilesUpdate'
                      defaultMessage='Supported files: {accept}'
                      values={{ accept }}
                    />
                  </Text>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default FileInput
