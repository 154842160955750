import React, { useMemo, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Text from 'shared/components/Text'
import { diff } from 'deep-object-diff'
import Scrollable from 'shared/components/Scrollable'
import startCase from 'lodash.startcase'
import { SpendItemType } from './ReportRowDetails'

type CompareDetailsProps = {
  subCategory: string
  lineItems1: SpendItemType[]
  lineItems2: SpendItemType[]
  teamExclude: boolean
}

const CompareDetails = (props: CompareDetailsProps) => {
  const { subCategory, lineItems1, lineItems2, teamExclude } = props
  const intl = useIntl()

  const certificationKey = `${subCategory} Certification`
  const verificationKey = `${subCategory} Verification`

  const sortFunction = useCallback((item1, item2) => {
    if (item1.name?.toLowerCase() === item2.name?.toLowerCase()) {
      return 0
    } else {
      return item1.name?.toLowerCase() > item2.name?.toLowerCase() ? 1 : -1
    }
  }, [])

  const getValueTextClass = item => {
    const isInvalid = teamExclude && item[verificationKey] === 'Invalid'
    const isQualified = item[certificationKey] === 'Qualified'
    let textClass = ''
    if (isInvalid) {
      textClass = 'dark-red fw6'
    } else if (isQualified) {
      textClass = 'dark-green fw6'
    } else {
      textClass = 'dark-blue fw6'
    }
    return textClass
  }

  const getDiffValueTextClass = diff => {
    const verification = diff[verificationKey]?.after || diff.verification
    const certification = diff[certificationKey]?.after || diff.certification
    const isInvalid = teamExclude && verification === 'Invalid'
    const isQualified = certification === 'Qualified'
    let textClass = 'fw6'

    // if certification no change and totalAmount no change and not Invalid, no color change
    if (!diff[certificationKey] && !diff['totalAmount'] && !isInvalid) {
      return textClass
    }

    if (isInvalid) {
      textClass = 'dark-red fw6'
    } else if (isQualified) {
      textClass = 'dark-green fw6'
    } else {
      textClass = 'dark-blue fw6'
    }
    return textClass
  }

  const [addedList, removedList, diffList] = useMemo(() => {
    const report1ByDomain = lineItems1?.reduce((domainMap, supplier) => {
      domainMap[supplier.domain] = supplier
      return domainMap
    }, {})
    const report2ByDomain = lineItems2?.reduce((domainMap, supplier) => {
      domainMap[supplier.domain] = supplier
      return domainMap
    }, {})

    // added to report 2
    const notInReport1 = lineItems2?.filter(item2 => {
      const index = lineItems1?.findIndex(item => item.domain === item2.domain)
      return index === -1
    })

    // removed from report 2
    const notInReport2 = lineItems1?.filter(item1 => {
      const index = lineItems2?.findIndex(item => item.domain === item1.domain)
      return index === -1
    })

    // domains in both reports
    const intersectDomains = lineItems1
      ?.filter(item1 => {
        const index = lineItems2?.findIndex(
          item2 => item1.domain === item2.domain
        )
        return index !== -1
      })
      .map(item => item.domain)

    // compare the diff in the supplier between 2 reports
    let intersectDiffs: Array<{
      name: string
      certification: 'Qualified' | 'Potential' | 'No'
      verification: 'Valid' | 'Invalid' | ''
      amount: number
      [key: string]: any
    }> = []
    intersectDomains?.forEach(domain => {
      // only extract the dff for specific keys
      const diffKeys = Object.keys(
        diff(report1ByDomain[domain], report2ByDomain[domain])
      ).filter(key => {
        return (
          key === 'totalAmount' ||
          (key.startsWith(subCategory) &&
            (key.endsWith('Validation') ||
              key.endsWith('Verification') ||
              key.endsWith('Expiry') ||
              key.endsWith('Certification') ||
              key.endsWith('CertAgency')))
        )
      })
      // only add to the list if any of those keys have changed
      if (diffKeys.length > 0) {
        const item = diffKeys.reduce(
          (result, key) => {
            result[key] = {
              before: report1ByDomain[domain][key],
              after: report2ByDomain[domain][key]
            }
            return result
          },
          {
            // original value from report 1 (before)
            name: report1ByDomain[domain]?.name,
            certification: report1ByDomain[domain][certificationKey],
            verification: report1ByDomain[domain][verificationKey],
            amount: report1ByDomain[domain]?.totalAmount
          }
        )
        intersectDiffs.push(item)
      }
    })

    const diffs = intersectDiffs.sort(sortFunction)

    return [notInReport1, notInReport2, diffs]
  }, [
    lineItems1,
    lineItems2,
    certificationKey,
    verificationKey,
    subCategory,
    sortFunction
  ])

  return addedList.length + removedList.length + diffList.length > 0 ? (
    <>
      <div className='dt w-100 pb2 mb2 bb b--black-20'>
        <div className='dtc w-30'>
          <Text className='fw6'>
            <FormattedMessage
              id='CompareDetails.Supplier'
              defaultMessage='Supplier'
            />
          </Text>
        </div>
        <div className='dtc w-20'>
          <Text className='fw6'>
            <FormattedMessage
              id='CompareDetails.Status'
              defaultMessage='Status'
            />
          </Text>
        </div>
        <div className='dtc w-30'>
          <Text className='fw6'>
            <FormattedMessage
              id='CompareDetails.Changes'
              defaultMessage='Changes'
            />
          </Text>
        </div>
        <div className='dtc w-20 tr'>
          <Text className='fw6'>
            <FormattedMessage
              id='CompareDetails.Value'
              defaultMessage='Value'
            />
          </Text>
        </div>
      </div>
      <Scrollable>
        {addedList.map(add => {
          const textClass = getValueTextClass(add)

          return (
            <div key={add.name} className='dt w-100' role='row'>
              <div className='dtc w-30'>
                <Text>{add.name}</Text>
              </div>
              <div className='dtc w-20'>
                <Text className='fw6'>
                  <FormattedMessage
                    id='CompareDetails.AddedTo'
                    defaultMessage='Added to {certification}'
                    values={{
                      certification: add[certificationKey]
                    }}
                  />
                </Text>
              </div>
              <div className='dtc w-30'>
                <Text className='fw6'>&ndash;</Text>
              </div>
              <div className='dtc w-20 tr'>
                <Text className={textClass}>
                  {intl.formatNumber(add.totalAmount, {
                    style: 'currency',
                    signDisplay: 'auto',
                    currencySign: 'accounting',
                    currency: 'USD',
                    maximumFractionDigits: 0
                  })}
                </Text>
              </div>
            </div>
          )
        })}
        {removedList.map(remove => {
          const textClass = getValueTextClass(remove)

          return (
            <div key={remove.name} className='dt w-100' role='row'>
              <div className='dtc w-30'>
                <Text>{remove.name}</Text>
              </div>
              <div className='dtc w-20'>
                <Text className='fw6'>
                  <FormattedMessage
                    id='CompareDetails.RemovedFrom'
                    defaultMessage='Removed from {certification}'
                    values={{
                      certification: remove[certificationKey]
                    }}
                  />
                </Text>
              </div>
              <div className='dtc w-30'>
                <Text className='fw6'>&ndash;</Text>
              </div>
              <div className='dtc w-20 tr'>
                <Text className={textClass}>
                  {intl.formatNumber(remove.totalAmount * -1, {
                    style: 'currency',
                    signDisplay: 'auto',
                    currencySign: 'accounting',
                    currency: 'USD',
                    maximumFractionDigits: 0
                  })}
                </Text>
              </div>
            </div>
          )
        })}
        {diffList.map(diffKeys => {
          const {
            name,
            verification,
            certification,
            amount,
            ...restDiff
          } = diffKeys
          const textClass = getDiffValueTextClass(diffKeys)

          return (
            <div key={name} className='dt w-100' role='row'>
              <div className='dtc w-30'>
                <Text>{name}</Text>
              </div>
              <div className='dtc w-20'>
                <Text className='fw6'>
                  <FormattedMessage
                    id='CompareDetails.ChangedIn'
                    defaultMessage='Changed in {certification}'
                    values={{
                      certification
                    }}
                  />
                </Text>
              </div>
              <div className='dtc w-30'>
                {Object.keys(restDiff).map(key => (
                  <Text key={key} className='fw6'>
                    {startCase(key)}:&nbsp;
                    {(typeof restDiff[key].before === 'number'
                      ? intl.formatNumber(restDiff[key].before || 0, {
                          style: 'currency',
                          signDisplay: 'auto',
                          currencySign: 'accounting',
                          currency: 'USD',
                          maximumFractionDigits: 0
                        })
                      : restDiff[key].before) || `""`}
                    &nbsp;&rArr;&nbsp;
                    {(typeof restDiff[key].after === 'number'
                      ? intl.formatNumber(restDiff[key].after || 0, {
                          style: 'currency',
                          signDisplay: 'auto',
                          currencySign: 'accounting',
                          currency: 'USD',
                          maximumFractionDigits: 0
                        })
                      : restDiff[key].after) || `""`}
                  </Text>
                ))}
              </div>
              <div className='dtc w-20 tr'>
                {// totalAmount changed but certification does not, subtract the difference
                diffKeys.totalAmount !== undefined ? (
                  diffKeys[certificationKey] ? (
                    <Text className={textClass}>
                      {intl.formatNumber(diffKeys.totalAmount?.after || 0, {
                        style: 'currency',
                        signDisplay: 'auto',
                        currencySign: 'accounting',
                        currency: 'USD',
                        maximumFractionDigits: 0
                      })}
                    </Text>
                  ) : (
                    <Text className={textClass}>
                      {intl.formatNumber(
                        (diffKeys.totalAmount?.after || 0) -
                          (diffKeys.totalAmount?.before || 0),
                        {
                          style: 'currency',
                          signDisplay: 'auto',
                          currencySign: 'accounting',
                          currency: 'USD',
                          maximumFractionDigits: 0
                        }
                      )}
                    </Text>
                  )
                ) : (
                  <Text className={textClass}>
                    {intl.formatNumber(amount || 0, {
                      style: 'currency',
                      signDisplay: 'auto',
                      currencySign: 'accounting',
                      currency: 'USD',
                      maximumFractionDigits: 0
                    })}
                  </Text>
                )}
              </div>
            </div>
          )
        })}
      </Scrollable>
    </>
  ) : (
    <Text className='fw6'>
      <FormattedMessage
        id='CompareDetails.NoChange'
        defaultMessage='No Change'
      />
    </Text>
  )
}

export default CompareDetails
