import { fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { INVITE_SUPPLIER_FROM_VET } from '../../../Vets/actionTypes'
import { requestSuccess, requestFailure } from 'shared/actionTypes'
import { CLEAR_ERROR_MESSAGE } from '../../actionTypes'
import { OPEN_REMAP_DIALOG } from 'shared/actionTypes'

export const initialState = fromJS({
  openInviteSupplierDialog: false,
  supplierInvitedSuccessfully: false,
  isSubmitting: false,
  matchingOrgs: fromJS([])
})

const inviteSupplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_INVITE_SUPPLIER_DIALOG:
      return state.merge({
        openInviteSupplierDialog: true,
        supplierInvitedSuccessfully: false
      })
    // matchingOrgs.names and matchingOrgs.cleanDomain used in SearchForm for Tier 2 remapping
    case actionTypes.FOUND_EXISTING_ORGS:
      return state.set('matchingOrgs', fromJS(action.payload))

    case OPEN_REMAP_DIALOG:
    case actionTypes.CLEAR_FOUND_ORGS:
      return state.set('matchingOrgs', fromJS([]))

    case actionTypes.INVITE_SUPPLIER:
      return state.merge({ isSubmitting: true, newSupplierCreated: false })

    case INVITE_SUPPLIER_FROM_VET:
      return state.merge({
        supplierInvitedSuccessfully: false,
        isSubmitting: false
      })

    case CLEAR_ERROR_MESSAGE:
      return state.set('supplierInvitedErrorMessage', '')

    case requestSuccess(actionTypes.INVITE_SUPPLIER):
      return action.payload.ignoreMatchingOrgs
        ? state.merge({ matchingOrgs: fromJS([]), isSubmitting: false })
        : state.merge({
            supplierInvitedSuccessfully: true,
            supplierInvitedErrorMessage: '',
            isSubmitting: false
          })

    case requestFailure(actionTypes.INVITE_SUPPLIER):
      return state.merge({
        supplierInvitedSuccessfully: false,
        supplierInvitedErrorMessage: action.payload,
        isSubmitting: false
      })

    case actionTypes.CLOSE_INVITE_SUPPLIER_DIALOG:
      return initialState

    default:
      return state
  }
}

export default inviteSupplierReducer
