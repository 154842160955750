import { call, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import jobsSelectors from '../selectors/jobsSelectors'
import job from 'shared/utils/api/job'
import { JobOrgUnit } from 'shared/models/Job'
import { List, RecordOf } from 'immutable'
import suppliers from 'shared/utils/api/suppliers'

export function* addJobRowSaga(action) {
  const { jobId, orgUnitId, rowNum } = action.payload
  const jobRows: List<RecordOf<JobOrgUnit>> = yield select(
    jobsSelectors.getRows
  )

  if (jobRows && !!jobRows.find(row => row.get('orgUnitId') === orgUnitId)) {
    return yield {}
  }

  const response = yield call(suppliers.downloadExportFile, {
    supplierIds: [orgUnitId],
    exportColumns: [
      'name',
      'domain',
      'phone',
      'website',
      'shortDescription',
      'longDescription',
      'offerings',
      'addresses',
      'size',
      'revenue',
      'classificationCodes',
      'yearFounded',
      'parentOrgName',
      'serviceAreas'
    ],
    exportType: 'json'
  })

  const row = response.map(
    ({
      id,
      longdesc,
      shortdesc,
      phone,
      revenue,
      websiteURL,
      address,
      foundingYear,
      ...restOrg
    }) => {
      return {
        ...restOrg,
        description: shortdesc,
        longDescription: longdesc,
        parentOrgName: '',
        yearlyRevenue: revenue,
        orgUnitId: id,
        locations: address.map(loc => ({
          address: loc
        })),
        phoneNumber: phone,
        websiteUrl: websiteURL,
        yearFounded: foundingYear,
        manualAdded: true
      }
    }
  )[0]

  yield call(job.addJobRow, { jobId, row })
  const jobResult = yield call(job.createJobResult, {
    ignoreComplete: true,
    jobId,
    orgUnitId,
    rowNum,
    answers: {}
  })
  return yield {
    jobId,
    jobResult,
    ...Object.assign({}, row)
  }
}

export default createApiRequestSaga(addJobRowSaga)
