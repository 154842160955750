import { call } from 'redux-saga/effects'
import suppliers from 'shared/utils/api/suppliers'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* searchTier2SuppliersSaga(action) {
  const response = yield call(suppliers.searchTier2Suppliers, {
    q: action.payload,
    agg: false,
    filter: { attributes: ['existing'] },
    scope: 'All',
    sort: 'SupplierName',
    limit: 10,
    supplierNameOnly: true
  })
  return yield {
    results: response.hits,
    resultsCount: response.total
  }
}

export default createApiRequestSaga(searchTier2SuppliersSaga)
