import React, { Component, Fragment } from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'

type Props = {
  locale: string
  messages: Record<string, string>
}
class ConnectedIntlProvider extends Component<Props> {
  render() {
    const { locale, ...rest } = this.props

    return (
      <IntlProvider
        {...rest}
        key={locale}
        locale={locale}
        textComponent={Fragment}
      />
    )
  }
}

export default connect((state: RootState) => ({
  messages: state.get('i18n').messages,
  locale: state.get('i18n').locale
}))(ConnectedIntlProvider)
