import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import createTagsForm from 'shared/utils/createTagsForm'
import companySelectors from '../../../shared/selectors/companySelectors'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import PageSideSection from 'shared/components/PageSideSection'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import AboutUsSection from '../AboutUsSection'
import CompanyProfileHeaderContainer from '../../containers/CompanyProfileHeaderContainer'
import EditCompanyProfileHeaderContainer from '../../containers/EditCompanyProfileHeaderContainer'
import OurTeamSectionContainer from '../../containers/OurTeamSectionContainer'
import DiversitySectionContainer from '../../containers/DiversitySectionContainer'
import DetailsSection from '../DetailsSection'
import CommunitiesSection from '../../containers/CommunitiesSection'
import { ADD_TAGS, REMOVE_TAGS } from '../../actionTypes'
import ResourcesSection from '../ResourcesSection'
import SimilarSuppliers from '../../containers/SimilarSuppliers'
import CorporateSectionContainer from '../../containers/CorporateSectionContainer'
import OurCustomersSectionContainer from '../../containers/OurCustomersSectionContainer'
import CompletenessContainer from '../../../shared/containers/CompletenessContainer'
import Locations from '../../containers/Locations'
import CompanyLocationAddFormContainer from '../../containers/CompanyLocationAddFormContainer'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'
import Pill from 'shared/components/Pill'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import ReferenceScores from 'shared/components/ReferenceScores'
import { removeAllContacts } from '../../actions'
import storageManager from 'shared/utils/storageManager'
import { RouteComponentProps } from 'react-router-dom'
import qs from 'qs'
import SustainabilitySection from '../SustainabilitySection'
import CongratsSurveyCompleteDialog from '../CongratsSurveyCompleteDialog'

const OfferingsTagsForm = createTagsForm({
  formName: 'supplier/company/offerings',
  fieldName: 'offerings',
  addTagActionType: ADD_TAGS,
  removeTagActionType: REMOVE_TAGS,
  tagsSelector: companySelectors.getCompanySupplierField,
  limit: 100
})

const DifferentiatorsTagsForm = createTagsForm({
  formName: 'supplier/company/differentiators',
  fieldName: 'differentiators',
  addTagActionType: ADD_TAGS,
  removeTagActionType: REMOVE_TAGS,
  tagsSelector: companySelectors.getCompanySupplierField,
  limit: 100
})

type Props = {
  editingHeader: boolean
  hasCommunities: boolean
  hasSimilarSuppliers: boolean
  offerings: List<string>
  differentiators: List<string>
  suggestedOfferings: List<string>
  suggestedDifferentiators: List<string>
  onAddTags: (tagsToAdd: { fieldName: string; newTag: string }) => void
  onAddSuggestedTags: (params: {
    offerings: string[]
    differentiators: string[]
  }) => void
  onRemoveTags: (params: {
    fieldName: string
    tagToRemove: string[] | string
  }) => void
  hasCustomers: boolean
  referenceScores: { [key: string]: number }
  onSaveCompanyInfo: (locations: {
    supplier: {
      locations: string[]
    }
  }) => void
  hasLocations: boolean
  hasContacts: boolean
  onRemoveAllContacts: typeof removeAllContacts
  contacts: List<string>
  isTealbot: boolean
  attachmentTypes?: Map<string, Map<string, string>>
  relationshipTypes?: Map<string, Map<string, string>>
} & RouteComponentProps

const CompanyProfilePage: FunctionComponent<Props> = props => {
  const ourTeamSection = useRef<HTMLDivElement>(null)
  const { scrollToOurTeamSection } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  })

  const [deleteAllFieldName, setDeleteAllFieldName] = useState<string>('')
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState<boolean>(false)
  const [openSuggestedTagsDialog, setOpenSuggestedTagsDialog] = useState<
    boolean
  >(false)

  useEffect(() => {
    if (!!scrollToOurTeamSection) {
      setTimeout(() => scrollToSection(ourTeamSection), 400)
    }
  }, [ourTeamSection, scrollToOurTeamSection])

  useEffect(() => {
    const ignoreTagsSuggestionsDate = storageManager.getItem(
      'ignoreTagsSuggestions'
    )
    const weekAgo = new Date()
    weekAgo.setDate(weekAgo.getDate() - 7)

    if (
      (!ignoreTagsSuggestionsDate ||
        new Date(ignoreTagsSuggestionsDate) < weekAgo) &&
      (props.suggestedDifferentiators.size > 0 ||
        props.suggestedOfferings.size > 0)
    ) {
      setOpenSuggestedTagsDialog(true)
    }
  }, [props.suggestedDifferentiators, props.suggestedOfferings])

  const scrollToSection = section => {
    document.documentElement.scrollTop = section.current.offsetTop - 100
    document.body.scrollTop = section.current.offsetTop - 100
  }

  const toggleDeleteAllDialog = () => {
    setConfirmDeleteDialog(!confirmDeleteDialog)
  }

  const handleOnDeleteAllClick = fieldName => {
    setDeleteAllFieldName(fieldName)
    toggleDeleteAllDialog()
  }

  const handleDeleteAllTagsOrLocations = () => {
    const {
      onRemoveTags,
      onSaveCompanyInfo,
      onRemoveAllContacts,
      contacts
    } = props
    if (deleteAllFieldName === 'locations') {
      onSaveCompanyInfo({
        supplier: {
          locations: []
        }
      })
    } else if (deleteAllFieldName === 'contacts') {
      onRemoveAllContacts(contacts.toJS())
    } else if (
      deleteAllFieldName === 'offerings' ||
      deleteAllFieldName === 'differentiators'
    ) {
      onRemoveTags({
        tagToRemove:
          deleteAllFieldName === 'offerings'
            ? props.offerings.toJS()
            : props.differentiators.toJS(),
        fieldName: deleteAllFieldName
      })
    }
    toggleDeleteAllDialog()
  }
  const handleCloseSuggestedTagsDialog = () => {
    setOpenSuggestedTagsDialog(false)
    storageManager.setItem('ignoreTagsSuggestions', new Date().toISOString())
  }

  const handleToggleSuggestedTag = (
    fieldName: 'offerings' | 'differentiators',
    tag: string
  ) => () => {
    props.onAddTags({
      fieldName,
      newTag: tag
    })

    // if there wont be more suggested tags after adding the last one
    // then hide the dialog
    if (
      (fieldName === 'offerings' &&
        props.suggestedOfferings.size === 1 &&
        props.suggestedDifferentiators.size === 0) ||
      (fieldName === 'differentiators' &&
        props.suggestedDifferentiators.size === 1 &&
        props.suggestedOfferings.size === 0)
    ) {
      handleCloseSuggestedTagsDialog()
    }
  }

  return (
    <div>
      {!props.editingHeader && <CompanyProfileHeaderContainer />}
      <Page
        title={
          <FormattedMessage
            id='CompanyProfilePage.CompanyProfileTitle'
            defaultMessage='Company Profile'
          />
        }
      >
        <ScrollToPosition />
        {props.editingHeader && <EditCompanyProfileHeaderContainer />}

        <Main>
          <PageSection
            title={
              <FormattedMessage
                id='CompanyProfilePage.ProfileCompleteness'
                defaultMessage='Profile Completeness'
              />
            }
          >
            <div className='mt3'>
              <CompletenessContainer />
            </div>
          </PageSection>
          <AboutUsSection />

          <PageSection
            actions={
              props.offerings &&
              props.offerings.size > 5 && (
                <Button
                  caution
                  size='small'
                  label={
                    <FormattedMessage
                      id='CompanyProfilePage.DeleteAllButton'
                      defaultMessage='Delete all'
                    />
                  }
                  onClick={() => handleOnDeleteAllClick('offerings')}
                />
              )
            }
            noPadding
            title={
              <FormattedMessage
                id='CompanyProfilePage.WhatWeAreKnownForTitle'
                defaultMessage='What we are known for'
              />
            }
          >
            <FormattedMessage
              id='CompanyProfilePage.Skill'
              defaultMessage='Skill (ex: Advertising or Digital Marketing)'
            >
              {message => (
                <OfferingsTagsForm
                  addLabel={
                    <FormattedMessage
                      id='CompanyProfilePage.AddASkill'
                      defaultMessage='Add a skill'
                    />
                  }
                  inputPlaceholder={message}
                />
              )}
            </FormattedMessage>
          </PageSection>

          <PageSection
            actions={
              props.differentiators &&
              props.differentiators.size > 0 && (
                <Button
                  caution
                  autoSize
                  size='small'
                  label={
                    <FormattedMessage
                      id='CompanyProfilePage.DeleteAllButton'
                      defaultMessage='Delete all'
                    />
                  }
                  onClick={() => handleOnDeleteAllClick('differentiators')}
                />
              )
            }
            noPadding
            title={
              <FormattedMessage
                id='CompanyProfilePage.WhatSifferentiatesUsTitle'
                defaultMessage='What differentiates us'
              />
            }
          >
            <FormattedMessage
              id='CompanyProfilePage.Differentiator'
              defaultMessage='Differentiator (ex: Innovative or ISO 9001)'
            >
              {message => (
                <DifferentiatorsTagsForm
                  addLabel={
                    <FormattedMessage
                      id='CompanyProfilePage.AddASWordOrDescriptivePhrase'
                      defaultMessage='Add a word or descriptive phrase that differentiates you'
                    />
                  }
                  inputPlaceholder={message}
                />
              )}
            </FormattedMessage>
          </PageSection>

          {/* Anchor tag to scroll to Locations section */}
          <a
            id='Locations'
            href='#locations'
            style={{
              display: 'block',
              height: 51,
              marginTop: -51,
              visibility: 'hidden'
            }}
          >
            <FormattedMessage
              id='CompanyProfilePage.Locations'
              defaultMessage='Locations'
            />
          </a>
          <PageSection
            actions={
              props.hasLocations && (
                <Button
                  caution
                  autoSize
                  size='small'
                  label={
                    <FormattedMessage
                      id='CompanyProfilePage.DeleteAllButton'
                      defaultMessage='Delete all'
                    />
                  }
                  onClick={() => handleOnDeleteAllClick('locations')}
                />
              )
            }
            title={
              <FormattedMessage
                id='CompanyProfilePage.LocationsTitle'
                defaultMessage='Locations'
              />
            }
            noPadding
          >
            <Locations />
            <CompanyLocationAddFormContainer />
          </PageSection>

          <PageSection
            actions={
              props.hasContacts &&
              props.isTealbot && (
                <Button
                  caution
                  autoSize
                  size='small'
                  label={
                    <FormattedMessage
                      id='CompanyProfilePage.DeleteAllButton'
                      defaultMessage='Delete all'
                    />
                  }
                  onClick={() => handleOnDeleteAllClick('contacts')}
                />
              )
            }
            title={
              <FormattedMessage
                id='CompanyProfilePage.OurTeamTitle'
                defaultMessage='Our Team'
              />
            }
            noPadding
          >
            <div ref={ourTeamSection}>
              <OurTeamSectionContainer location={props.location} />
            </div>
          </PageSection>

          <SustainabilitySection />
          <PageSection
            title={
              <FormattedMessage
                id='CompanyProfilePage.QualificationsTitle'
                defaultMessage='Qualifications'
              />
            }
            noPadding
          >
            <DiversitySectionContainer />
          </PageSection>

          <PageSection
            title={
              <FormattedMessage
                id='CompanyProfilePage.ResourcesTitle'
                defaultMessage='Resources'
              />
            }
          >
            <ResourcesSection />
          </PageSection>
        </Main>

        <Aside>
          <div className='pt4-l'>
            <CorporateSectionContainer />

            <DetailsSection />

            <PageSideSection
              title={
                <FormattedMessage
                  id='CompanyProfilePage.ReferenceScores'
                  defaultMessage='Reference Scores'
                />
              }
            >
              <ReferenceScores
                isSupplier
                buyers={props.referenceScores.buyers}
                contacts={props.referenceScores.contacts}
                followers={props.referenceScores.followers}
                vets={props.referenceScores.vets}
                completeness={props.referenceScores.completeness}
              />
            </PageSideSection>

            <PageSideSection
              title={
                <FormattedMessage
                  id='CompanyProfilePage.OurCustomersTitle'
                  defaultMessage='Our Customers'
                />
              }
              noPadding
              hideTitle={!props.hasCustomers}
            >
              <OurCustomersSectionContainer />
            </PageSideSection>

            {props.hasCommunities && <CommunitiesSection />}

            {props.hasSimilarSuppliers && <SimilarSuppliers />}
          </div>
        </Aside>
      </Page>
      <Dialog
        open={confirmDeleteDialog}
        onClose={toggleDeleteAllDialog}
        fullWidth
      >
        <DialogTitle>
          <FormattedMessage
            id='CompanyProfilePage.ConfirmationDialog'
            defaultMessage='Confirmation'
          />
        </DialogTitle>
        <DialogContent className='mt3'>
          <FormattedMessage
            id='CompanyProfilePage.ConfirmDeleteAllDialog'
            defaultMessage='Are you sure you want to delete all {deleteAllFieldName}?'
            values={{ deleteAllFieldName }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoSize
            caution
            label={
              <FormattedMessage
                id='CompanyProfilePage.DeleteAllButton'
                defaultMessage='Delete all'
              />
            }
            onClick={handleDeleteAllTagsOrLocations}
          />
          <Button
            secondary
            autoSize
            label={<FormattedMessage id='CancelButton' />}
            onClick={toggleDeleteAllDialog}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSuggestedTagsDialog}
        onClose={handleCloseSuggestedTagsDialog}
      >
        <DialogTitle>
          <FormattedMessage
            id='CompanyProfilePage.ReviewEndorsements'
            defaultMessage='Review Endorsements from Companies'
          />
        </DialogTitle>
        <DialogContent className='mt2'>
          <p className='fw6 f6 mt0 mb3-5'>
            <FormattedMessage
              id='CompanyProfilePage.suggestedTagsInstructions'
              defaultMessage={`The following tags are endorsed by other companies. Click
                on the tag to add to your company profile. By adding them,
                other client users will be able to see what you are known for.`}
            />
          </p>
          {props.suggestedOfferings.size > 0 && (
            <>
              <div className='fw6 f6 pt1'>
                <FormattedMessage
                  id='CompanyProfilePage.Offerings'
                  defaultMessage='Offerings'
                />
              </div>
              <div className='mt2 mb3'>
                {props.suggestedOfferings.map(tag => (
                  <Pill
                    key={tag}
                    label={tag}
                    onToggle={handleToggleSuggestedTag('offerings', tag)}
                  />
                ))}
              </div>
            </>
          )}

          {props.suggestedDifferentiators.size > 0 && (
            <>
              <div className='fw6 f6'>
                <FormattedMessage
                  id='CompanyProfilePage.Differentiators'
                  defaultMessage='Differentiators'
                />
              </div>
              <div className='mt2'>
                {props.suggestedDifferentiators.map(tag => (
                  <Pill
                    key={tag}
                    label={tag}
                    onToggle={handleToggleSuggestedTag('differentiators', tag)}
                  />
                ))}
              </div>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            autoSize
            label={
              <FormattedMessage
                id='CompanyProfilePage.AddAllSuggestedTags'
                defaultMessage='Add All'
              />
            }
            onClick={() => {
              props.onAddSuggestedTags({
                offerings: props.suggestedOfferings.toJSON(),
                differentiators: props.suggestedDifferentiators.toJSON()
              })
              handleCloseSuggestedTagsDialog()
            }}
          />
          <Button
            secondary
            autoSize
            label={
              <FormattedMessage
                id='CompanyProfilePage.DoneButton'
                defaultMessage='Done'
              />
            }
            onClick={handleCloseSuggestedTagsDialog}
          />
        </DialogActions>
      </Dialog>
      <CongratsSurveyCompleteDialog />
    </div>
  )
}

export default CompanyProfilePage
