import React, { Component } from 'react'
import Page from 'shared/components/Page'
import Loading from 'shared/components/Loading'

class CreatingOrgpage extends Component {
  render() {
    return (
      <Page title='Creating New Organization' className='tc'>
        <h2 className='f4 f3-m f2-l fw2 black-70 mb0'>
          Your organization is being created.
        </h2>
        <p className='lh-copy fw4 mid-gray ma0 mv3 f6 f5-ns fw6'>
          Please wait. This may take a minute to complete
        </p>
        <Loading />
      </Page>
    )
  }
}

export default CreatingOrgpage
