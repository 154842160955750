import React from 'react'
import classNames from 'classnames'
import { WebsiteWarningType, messages } from 'shared/components/WebsiteWarning'
import Tooltip from 'shared/components/Tooltip'
import { useIntl, FormattedMessage } from 'react-intl'

type Props = {
  status: string
  mini?: boolean
  websiteWarning?: WebsiteWarningType
}

const styles = {
  container: 'br2 tc mt1 white fw1 truncate',
  fontSize: {
    mini: 'f9',
    default: 'f7'
  },
  padding: {
    mini: 'ph0 pv1',
    default: 'pa2'
  },
  bg: {
    acquired: 'bg-blue',
    inactive: 'bg-red'
  }
}

const SupplierStatus = (props: Props) => {
  const { status, mini, websiteWarning } = props
  const intl = useIntl()
  return status && status !== 'Active' ? (
    <Tooltip
      title={
        websiteWarning && messages[websiteWarning] ? (
          intl.formatMessage(messages[websiteWarning])
        ) : (
          <FormattedMessage
            id='SupplierStatus.undefined'
            defaultMessage='Manual'
          />
        )
      }
    >
      <div
        className={classNames([
          styles.container,
          styles.bg[status.toLowerCase()],
          styles.fontSize[mini ? 'mini' : 'default'],
          styles.padding[mini ? 'mini' : 'default']
        ])}
        style={{ width: mini ? '50px' : 'auto' }}
      >
        {status}
      </div>
    </Tooltip>
  ) : null
}

export default SupplierStatus
