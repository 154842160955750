import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import createApiRequestSaga from '../../utils/sagas/createApiRequestSaga'
import api from '../../utils/api'
import apiRoutes from '../../routes/apiRoutes'
import { fromJS } from 'immutable'
import extractExpandedEntities from '../../utils/data/extractExpandedEntities'

export function* updateDataSaga(action) {
  const {
    entity, // TODO: update this key to entityName
    record, // TODO: update this key to body
    endpoint,
    resultParser,
    redirectTo
  } = action.payload

  const requestFunction = record && record.id ? api.put : api.post

  const endpointUrl = `${apiRoutes.authService}/${endpoint || entity}`
  const dataId = record && record.id

  delete record.id
  const resultData = dataId
    ? yield call(requestFunction, `${endpointUrl}/${dataId}`, record)
    : yield call(requestFunction, `${endpointUrl}`, record)

  const otherEntities = extractExpandedEntities(resultData)
  const parsedData = resultParser ? resultParser(resultData) : resultData

  if (redirectTo) {
    yield put(push(redirectTo))
  }

  return yield {
    entity,
    data: fromJS({
      ...otherEntities,
      [entity]:
        dataId || resultData.id
          ? {
              [dataId || resultData.id]: parsedData
            }
          : parsedData
    })
  }
}

export default createApiRequestSaga(updateDataSaga, {
  notifyOnError: true
})
