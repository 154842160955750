import { call, put, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import supplier from 'shared/utils/api/supplier'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { addVetContact } from '../../actions'
import { push } from 'connected-react-router'
import paths from '../../routes/paths'
import parsePath from 'shared/utils/parsePath'

export function* showInterestedSaga(action) {
  const response = yield call(supplier.showInterestVet, action.payload)
  const status = response.vetBuyerSupplier.collaboration.status
  const opened = !!response.vetBuyerSupplier.collaboration.proposal.opened

  if (status === 'Interested') {
    const userId = yield select(sessionSelectors.getUserId)
    yield put(addVetContact({ user: { id: userId } }))
  }

  if (status === 'Invited' && !opened) {
    yield put(
      push(
        parsePath(paths.vetTerms, {
          vetBuyerSupplierId: response.vetBuyerSupplier.id
        })
      )
    )
  } else {
    yield put(
      push(
        parsePath(paths.vetResponse, {
          vetBuyerSupplierId: response.vetBuyerSupplier.id
        })
      )
    )
  }

  return response
}

export default createApiRequestSaga(showInterestedSaga, { notifyOnError: true })
