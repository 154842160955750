import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select, put } from 'redux-saga/effects'
import messageTopics from 'shared/utils/api/messageTopics'
import usersSelectors from 'shared/selectors/usersSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { push } from 'connected-react-router'
import buyerPaths from 'buyer/routes/paths'
import supplierPaths from 'supplier/routes/paths'
import parsePath from 'shared/utils/parsePath'
import users from 'shared/utils/api/users'

export function* messageUserSaga(action) {
  let {
    message,
    userToMessageId,
    orgUnitId,
    supplierToShare,
    shareESGSurveyLink
  } = action.payload.toJS()

  if (!userToMessageId) {
    userToMessageId = yield select(state =>
      state.getIn(['mess', 'userToMessage'])
    )
  } else if (userToMessageId.includes('@') && orgUnitId) {
    // given email address, create user
    const isBuyer = yield select(sessionSelectors.userHasRole, 'buyer')
    const user = yield call(users.inviteUser, {
      orgUnitId,
      user: {
        email: userToMessageId,
        roles: isBuyer ? ['buyer'] : ['supplier']
      }
    })
    userToMessageId = user.id
  }

  if (!orgUnitId && userToMessageId) {
    const userToMessage = yield select(usersSelectors.getById, userToMessageId)
    orgUnitId = userToMessage.get('orgUnitId')
  }

  let topicId = yield select(state =>
    state.getIn(['mess', 'topicsByUser', userToMessageId])
  )

  // we need the id of the user to message and either a message or a supplier to share
  if (!userToMessageId || !orgUnitId || (!message && !supplierToShare)) {
    return yield {}
  }

  let newTopic
  if (!topicId) {
    const createdTopic = yield call(messageTopics.create, {
      userIds: userToMessageId,
      orgUnitIds: [orgUnitId]
    })

    newTopic = {
      id: createdTopic.id,
      conversationWithUser: userToMessageId,
      closed: false,
      createdDate: createdTopic.created.date
        ? new Date(createdTopic.created.date)
        : undefined,
      createdBy: createdTopic.created.user
    }
    topicId = newTopic.id
  }

  const newMessage = yield call(messageTopics.sendMessage, {
    topicId,
    message,
    supplierToShare,
    shareESGSurveyLink
  })

  const pathname = yield select(routingSelectors.getPathname)
  const isClientApp = pathname.includes('client')
  const isOnMessaging = pathname.includes('messages')
  const hasTopic = pathname.includes('MessageTopic')

  if (isOnMessaging && !hasTopic) {
    yield put(
      push(
        parsePath(
          isClientApp ? buyerPaths.messageTopic : supplierPaths.messageTopic,
          { messageTopicId: topicId }
        )
      )
    )
  }

  return yield {
    topicId,
    userToMessageId,
    newTopic,
    message: newMessage
  }
}

export default createApiRequestSaga(messageUserSaga)
