import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import Pill from 'shared/components/Pill'
import { List } from 'immutable'

type Props = {
  tags: List<string>
}

export class PersonalTags extends Component<Props> {
  render() {
    const { tags } = this.props
    return <div>{tags && tags.map(tag => <Pill label={tag} key={tag} />)}</div>
  }
}

export const mapStateToProps = (state, props) => {
  const card = cardsSelectors.getBySupplier(
    state,
    props.match.params.supplierId
  )

  // TODO move this into a reselect selector
  const offerings = card.get('offerings')
  const differentiators = card.get('differentiators')
  const tags = offerings && offerings.merge(differentiators).toList()

  return {
    tags
  }
}

export default withRouter(connect(mapStateToProps)(PersonalTags))
