import * as api from '../api'
import apiRoutes from 'shared/routes/apiRoutes'
import parseUser from '../../data/parseUser'
import reverseParsedUser from '../../data/reverseParsedUser'
import fileToUrl from '../../data/fileToUrl'
import chatbot from 'shared/utils/chatbot'

const apiRoute = `${apiRoutes.authService}/user`

export const get = () =>
  api.get(apiRoute).then(user => {
    const { expanded, parents } = user
    const orgUnit =
      expanded && expanded.OrgUnit && expanded.OrgUnit[parents.OrgUnit]

    chatbot.initialize({
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      organization: orgUnit.name,
      roles: user.roles.join(','),
      userId: user.id,
      orgUnitId: orgUnit.id,
      language:
        user.language && ['en', 'fr', 'es'].includes(user.language)
          ? user.language
          : 'en'
    })

    return {
      userInfo: parseUser(user),
      orgUnit
    }
  })

export const update = user => api.put(apiRoute, reverseParsedUser(user))

export const updatePassword = passwords =>
  api.put(`${apiRoutes.authService}/password`, passwords)

export const uploadProfilePicture = profilePicture => {
  return api
    .postFile(`${apiRoute}/profilePicture`, {
      profilePicture
    })
    .then(fileToUrl)
}

export const loginAsTealbot = (orgUnitId, onetimeKey, reason) =>
  api.post(`${apiRoute}/concierge/${orgUnitId}`, { onetimeKey, reason })

export const generateUserToken = parameters => {
  return api.postFile(`${apiRoute}/campaign/bulk/community`, parameters)
}
