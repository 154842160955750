import React, { Component } from 'react'
import Pill from 'shared/components/Pill'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'

const styles = {
  title: 'f7 fw6 mid-gray mt2 ma0',
  tags: 'mt2',
  missingInfo: 'f7 fw4 gray ma0 i'
}

type Props = {
  serviceAreas: List<string>
}

class SupplierProfileDetailsSection extends Component<Props> {
  render() {
    const { serviceAreas } = this.props

    return (
      <div>
        {serviceAreas && serviceAreas.size > 0 ? (
          <div>
            <h5 className={styles.title}>
              <FormattedMessage
                id='SupplierProfileDetailsSection.WeDoBusiness'
                defaultMessage='We do business in'
              />
            </h5>
            <div className={styles.tags}>
              {serviceAreas.map(servArea => (
                <Pill key={servArea} label={servArea} />
              ))}
            </div>
          </div>
        ) : (
          <p className={styles.missingInfo}>
            <FormattedMessage
              id='SupplierProfileDetailsSection.InWhichLocationsDoYouDoBusiness'
              defaultMessage='In which locations do you do business?'
            />
          </p>
        )}
      </div>
    )
  }
}

export default SupplierProfileDetailsSection
