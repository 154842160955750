import React from 'react'
import { useSelector } from 'react-redux'
import surveySelectors from '../../selectors/surveySelectors'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import paths from 'buyer/routes/paths'
import parsePath from 'shared/utils/parsePath'
import dateFormat from 'shared/utils/dateFormat'
import Typography from '@material-ui/core/Typography'
import FactsCard from './FactsCard'
import { Link } from 'react-router-dom'

const SustainabilityQuickFacts = () => {
  const params: { supplierId: string } = useParams()
  const survey = useSelector(surveySelectors.getEsgSurvey)
  const quickFacts = useSelector(surveySelectors.getEsgQuickFacts)
  const { supplierId } = params

  return (
    <div className='mt3-5'>
      <div className='flex justify-between items-center'>
        <Typography component='h3' variant='subtitle1'>
          <FormattedMessage
            id='SustainabilityQuickFacts.Title'
            defaultMessage='Quick Facts'
          />
        </Typography>
        <Text>
          <FormattedMessage
            id='SustainabilityQuickFacts.LastUpdated'
            defaultMessage='Last Updated:'
          />{' '}
          {dateFormat(survey?.get('attestedDate'))}
        </Text>
      </div>
      <hr
        style={{
          height: 1
        }}
        className='bg-dark-gray w-100 ma0 pa0 bn mt1'
      />
      <div className='mt3 flex-ns'>
        <div className='dib w-100 w-third-ns mr3-ns mv2'>
          <FactsCard
            backgroundColor='#F2F4F4'
            label={
              <FormattedMessage
                id='SustainabilityQuickFacts.Certs'
                defaultMessage={'Certificates added'}
              />
            }
            {...quickFacts.certificatesAdded}
          />
        </div>
        <div className='dib w-100 w-third-ns mh3-ns mv2'>
          <FactsCard
            backgroundColor='#FAEED3'
            label={
              <FormattedMessage
                id='SustainabilityQuickFacts.Docs'
                defaultMessage={'Documents uploaded'}
              />
            }
            {...quickFacts.documentsAdded}
          />
        </div>
        <div className='dib w-100 w-third-ns ml3-ns mv2'>
          <FactsCard
            backgroundColor='#C7EDEB'
            label={
              <FormattedMessage
                id='SustainabilityQuickFacts.Positives'
                defaultMessage={'No. of positive responses'}
              />
            }
            {...quickFacts.positiveResponses}
          />
        </div>
      </div>
      <div className='mt3 flex items-center justify-between'>
        <Text>
          <FormattedMessage
            id='SustainabilityQuickFacts.Message'
            defaultMessage='This supplier has completed the Sustainability Survey'
          />
        </Text>
        <Button
          autoSize
          component={Link}
          to={parsePath(paths.supplierEsgSurvey, { supplierId })}
        >
          <FormattedMessage
            id='SustainabilityQuickFacts.ViewResponses'
            defaultMessage='View Responses'
          />
        </Button>
      </div>
    </div>
  )
}

export default SustainabilityQuickFacts
