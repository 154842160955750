import React, { ChangeEvent, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Checkbox from 'shared/components/Checkbox'
import Label from 'shared/components/Label'
import Scrollable from 'shared/components/Scrollable'
import CertificationCategories from 'shared/models/CertificationCategories'
import DiversityCategory from 'shared/models/DiversityCategory'
import RadioButton from 'shared/components/RadioButton'
import RadioButtonGroup from 'shared/components/RadioButton/RadioButtonGroup'
import { fromJS, List, RecordOf } from 'immutable'
import { agencyGroup } from 'shared/utils/data/certAgencyRank'
import {
  IncludeRulesType,
  ExcludeRulesType,
  BaseRulesType
} from '../../../store/diversityReportReducer'

type AgenciesListProps = {
  name: string
  authorities: List<string>
  values: List<string>
  onChange: (value: string) => void
}

const AgenciesList = (props: AgenciesListProps) => {
  const { name, authorities, values, onChange } = props
  return (
    <div className='ba br1 ph2 pv1 b--moon-gray' style={{ height: 160 }}>
      <Scrollable maxHeight={150}>
        {authorities &&
          authorities.map((option: string) => (
            <Checkbox
              key={option}
              name={name}
              label={option}
              labelFontLight
              className='mv2'
              checked={values && values.includes(option)}
              onChange={() => onChange(option)}
            />
          ))}
      </Scrollable>
    </div>
  )
}

const styles = {
  label: 'f6 fw6 pa0 mt3 mb2 mid-gray'
}

type Props = {
  tier2?: boolean
  baseRules: RecordOf<BaseRulesType>
  includeRules: RecordOf<IncludeRulesType>
  excludeRules: RecordOf<ExcludeRulesType>
  onChangeBaseRules: (baseRules: any) => void
  onChangeIncludeRules: (includeRules: any) => void
  onChangeExcludeRules: (excludeRules: any) => void
  authorities: List<string>
}
const QualificationRulesContent = ({
  tier2,
  baseRules,
  includeRules,
  excludeRules,
  onChangeBaseRules,
  onChangeIncludeRules,
  onChangeExcludeRules,
  authorities
}: Props) => {
  const intl = useIntl()

  const handleBaseRulesChange = (
    key: 'subCategories' | 'certAgencies' | 'countries',
    value
  ) => {
    onChangeBaseRules(
      baseRules.update(key, values => {
        return key === 'countries'
          ? value
            ? List([value])
            : List([])
          : values.includes(value)
          ? values.filter(v => v !== value)
          : values.push(value)
      })
    )
  }
  const handleExcludeRulesChange = (name, value) => {
    const path = name.split('.').slice(1)
    onChangeExcludeRules(
      excludeRules.updateIn(path, ruleValue => {
        return List.isList(ruleValue)
          ? ruleValue.includes(value)
            ? ruleValue.filter(v => v !== value)
            : ruleValue.push(value)
          : value
      })
    )
  }

  const handleIncludeRulesChange = (name, value) => {
    const path = name.split('.').slice(1)
    onChangeIncludeRules(
      includeRules.updateIn(path, ruleValue => {
        return List.isList(ruleValue)
          ? ruleValue.includes(value)
            ? ruleValue.filter(v => v !== value)
            : ruleValue.push(value)
          : value
      })
    )
  }
  const otherAgencies = useMemo(() => {
    return authorities
      .filter(
        a => !agencyGroup.top.includes(a) && !agencyGroup.state.includes(a)
      )
      .sort()
  }, [authorities])

  return (
    <>
      <div>
        <Label className={styles.label}>
          <FormattedMessage
            id='DialogFilter.TypeOfCertificates'
            defaultMessage='Type of Certificates'
          />
        </Label>
        <Checkbox
          label={
            <FormattedMessage
              id='DialogFilter.AnyType'
              defaultMessage='Any Types'
            />
          }
          labelFontLight
          className='mv2'
          checked={baseRules.get('allSubCategories')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.currentTarget.checked) {
              onChangeBaseRules(
                baseRules.merge({
                  subCategories: List([]),
                  allSubCategories: true
                })
              )
            } else {
              onChangeBaseRules(
                baseRules.merge({
                  subCategories: List([]),
                  allSubCategories: false
                })
              )
            }
          }}
        />
      </div>

      <div className='ba br1 ph2 pv1 b--moon-gray' style={{ height: 160 }}>
        <Scrollable maxHeight={150}>
          {fromJS(
            Object.keys(CertificationCategories.diversity.subCategories)
          ).map((key: DiversityCategory, index) => {
            const translatedCategoryAcronym =
              CertificationCategories.diversity.subCategoriesAcronyms[key] &&
              intl.formatMessage(
                CertificationCategories.diversity.subCategoriesAcronyms[key]
              )
            const translatedSubCategory =
              CertificationCategories.diversity.subCategories[key] &&
              intl.formatMessage(
                CertificationCategories.diversity.subCategories[key]
              )

            return (
              key && (
                <div className='flex justify-between'>
                  <Checkbox
                    label={`${translatedCategoryAcronym ||
                      key.toUpperCase()} (${translatedSubCategory || key})`}
                    value={key}
                    labelFontLight
                    key={key}
                    className='mv2'
                    checked={
                      baseRules.get('allSubCategories') ||
                      baseRules.get('subCategories').includes(key)
                    }
                    onChange={e => {
                      if (e.currentTarget.checked) {
                        onChangeBaseRules(
                          baseRules
                            .update('subCategories', values => {
                              return baseRules.get('subCategories').size + 1 ===
                                fromJS(
                                  Object.keys(
                                    CertificationCategories.diversity
                                      .subCategories
                                  )
                                ).size
                                ? List([])
                                : values.push(key)
                            })
                            .set(
                              'allSubCategories',
                              baseRules.get('subCategories').size + 1 ===
                                fromJS(
                                  Object.keys(
                                    CertificationCategories.diversity
                                      .subCategories
                                  )
                                ).size
                            )
                        )
                      } else {
                        onChangeBaseRules(
                          baseRules
                            .update('subCategories', values => {
                              return baseRules.get('allSubCategories')
                                ? fromJS(
                                    Object.keys(
                                      CertificationCategories.diversity
                                        .subCategories
                                    )
                                  ).filter(v => v !== key)
                                : values.filter(v => v !== key)
                            })
                            .set('allSubCategories', false)
                        )
                      }
                    }}
                  />
                </div>
              )
            )
          })}
        </Scrollable>
      </div>

      <Label className={`${styles.label} mt3`}>
        <FormattedMessage
          id='DialogFilter.CertifyingAgencies'
          defaultMessage='Certifying Agencies'
        />
      </Label>
      <Checkbox
        label={
          <FormattedMessage
            id='DialogFilter.TopAgencies'
            defaultMessage='Top Agencies'
          />
        }
        labelFontLight
        className='mv2'
        checked={agencyGroup.top.every(a => baseRules.certAgencies.includes(a))}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.currentTarget.checked) {
            const combineAgencies = [
              ...baseRules.get('certAgencies').toJS(),
              ...agencyGroup.top
            ]
            const uniqueAgencies = combineAgencies.filter(
              (a, index) => combineAgencies.indexOf(a) === index
            )
            onChangeBaseRules(() =>
              baseRules.set('certAgencies', List([...uniqueAgencies]))
            )
          } else {
            onChangeBaseRules(() =>
              baseRules.updateIn(['certAgencies'], certAgencies =>
                certAgencies?.filter(a => !agencyGroup.top.includes(a))
              )
            )
          }
        }}
      />
      {!agencyGroup.top.every(a => baseRules.certAgencies.includes(a)) && (
        <AgenciesList
          name='attestation.certAgencies'
          authorities={List([...agencyGroup.top])}
          values={baseRules.get('certAgencies')}
          onChange={value => handleBaseRulesChange('certAgencies', value)}
        />
      )}
      <Checkbox
        label={
          <FormattedMessage
            id='DialogFilter.StateAgencies'
            defaultMessage='State Agencies'
          />
        }
        labelFontLight
        className='mv2'
        checked={agencyGroup.state.every(a =>
          baseRules.certAgencies.includes(a)
        )}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.currentTarget.checked) {
            const combineAgencies = [
              ...baseRules.get('certAgencies').toJS(),
              ...agencyGroup.state
            ]
            const uniqueAgencies = combineAgencies.filter(
              (a, index) => combineAgencies.indexOf(a) === index
            )
            onChangeBaseRules(() =>
              baseRules.set('certAgencies', List([...uniqueAgencies]))
            )
          } else {
            onChangeBaseRules(() =>
              baseRules.updateIn(['certAgencies'], certAgencies =>
                certAgencies?.filter(a => !agencyGroup.state.includes(a))
              )
            )
          }
        }}
      />
      {!agencyGroup.state.every(a => baseRules.certAgencies.includes(a)) && (
        <AgenciesList
          name='attestation.certAgencies'
          authorities={List([...agencyGroup.state])}
          values={baseRules.get('certAgencies')}
          onChange={value => handleBaseRulesChange('certAgencies', value)}
        />
      )}
      {!!otherAgencies.size && (
        <>
          <Checkbox
            label={
              <FormattedMessage
                id='DialogFilter.OtherAgencies'
                defaultMessage='Other Agencies'
              />
            }
            labelFontLight
            className='mv2'
            checked={otherAgencies.every(a =>
              baseRules.certAgencies.includes(a)
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.currentTarget.checked) {
                const combineAgencies = [
                  ...baseRules.get('certAgencies').toJS(),
                  ...otherAgencies.toJS()
                ]
                const uniqueAgencies = combineAgencies.filter(
                  (a, index) => combineAgencies.indexOf(a) === index
                )
                onChangeBaseRules(() =>
                  baseRules.set('certAgencies', List([...uniqueAgencies]))
                )
              } else {
                onChangeBaseRules(() =>
                  baseRules.updateIn(['certAgencies'], certAgencies =>
                    certAgencies?.filter(a => !otherAgencies.includes(a))
                  )
                )
              }
            }}
          />
          {!otherAgencies.every(a => baseRules.certAgencies.includes(a)) && (
            <AgenciesList
              name='attestation.certAgencies'
              authorities={otherAgencies}
              values={baseRules.get('certAgencies')}
              onChange={value => handleBaseRulesChange('certAgencies', value)}
            />
          )}
        </>
      )}
      <div className='mt3'>
        <RadioButtonGroup
          required
          title={
            <FormattedMessage
              id='DialogFilter.SpendCountries'
              defaultMessage='Spend Location'
            />
          }
        >
          <RadioButton
            required
            className='flex mv1'
            label={
              <FormattedMessage
                id='DialogFilter.Anywhere'
                defaultMessage='Anywhere'
              />
            }
            value=''
            checked={
              !baseRules.get('countries') ||
              baseRules.get('countries')?.size === 0
            }
            name='countries'
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleBaseRulesChange('countries', e.currentTarget.value)
            }
          />
          <RadioButton
            required
            className='flex mv1'
            label={
              <FormattedMessage
                id='DialogFilter.UnitedStates'
                defaultMessage='United States'
              />
            }
            value='United States'
            checked={baseRules.getIn(['countries', 0]) === 'United States'}
            name='countries'
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleBaseRulesChange('countries', e.currentTarget.value)
            }
          />
          <RadioButton
            required
            className='flex mv1'
            label={
              <FormattedMessage
                id='DialogFilter.nonUS'
                defaultMessage='Non U.S.'
              />
            }
            value='nonUS'
            checked={baseRules.getIn(['countries', 0]) === 'nonUS'}
            name='countries'
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleBaseRulesChange('countries', e.currentTarget.value)
            }
          />
        </RadioButtonGroup>
      </div>
      {!tier2 && (
        <>
          <div className='mt3'>
            <RadioButtonGroup
              required
              title={
                <FormattedMessage
                  id='DialogFilter.CertificatesVerifiedMyCompany'
                  defaultMessage='Certificates Verified by My Company'
                />
              }
            >
              <RadioButton
                required
                className='flex mv1'
                label={
                  <FormattedMessage
                    id='DialogFilter.MyCompanyDoNotMeetInclude1'
                    defaultMessage='Include even if certificates do not meet selected {certAgencies} requirements. Please note that these certificates could be expired.                    '
                    values={{
                      certAgencies: (
                        <span className='f7 fw8 mid-gray'>
                          <FormattedMessage id='DialogFilter.CertifyingAgencies' />
                        </span>
                      )
                    }}
                  />
                }
                value='anyAgencies'
                checked={includeRules.myTeam === 'anyAgencies'}
                name='includeRules.myTeam'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleIncludeRulesChange(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
              />
              <RadioButton
                required
                className='flex mv1'
                label={
                  <FormattedMessage
                    id='DialogFilter.MyCompanyInclude1'
                    defaultMessage='Include only if certificates meet selected {certAgencies} requirements. Please note that these certificates could be expired.                    '
                    values={{
                      certAgencies: (
                        <span className='f7 fw8 mid-gray'>
                          <FormattedMessage id='DialogFilter.CertifyingAgencies' />
                        </span>
                      )
                    }}
                  />
                }
                value='includeExpired'
                checked={includeRules.myTeam === 'includeExpired'}
                name='includeRules.myTeam'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleIncludeRulesChange(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
              />
              <RadioButton
                required
                className='flex mv1'
                label={
                  <FormattedMessage
                    id='DialogFilter.MyCompanyIgnore'
                    defaultMessage='Ignore Verified by my company'
                  />
                }
                value='ignore'
                checked={includeRules.myTeam === 'ignore'}
                name='includeRules.myTeam'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleIncludeRulesChange(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
              />
            </RadioButtonGroup>
          </div>
          <div className='mt3'>
            <RadioButtonGroup
              required
              title={
                <FormattedMessage
                  id='DialogFilter.CertificatesRejectedMyCompany'
                  defaultMessage='Certificates Rejected by My Company'
                />
              }
            >
              <RadioButton
                required
                className='flex mv1'
                label={
                  <FormattedMessage
                    id='DialogFilter.MyCompanyRejected1'
                    defaultMessage='Exclude even if certificates meet selected {certAgencies}requirements. Please note that these certificates could be expired.'
                    values={{
                      certAgencies: (
                        <span className='f7 fw8 mid-gray'>
                          <FormattedMessage id='DialogFilter.CertifyingAgencies' />
                        </span>
                      )
                    }}
                  />
                }
                value='rejected'
                checked={excludeRules.myTeam === 'rejected'}
                name='excludeRules.myTeam'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleExcludeRulesChange(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
              />
              <RadioButton
                required
                className='flex mv1'
                label={
                  <FormattedMessage
                    id='DialogFilter.MyCompanyRejected2'
                    defaultMessage='Ignore Rejected by my company'
                  />
                }
                value='ignore'
                checked={excludeRules.myTeam === 'ignore'}
                name='excludeRules.myTeam'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleExcludeRulesChange(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
              />
            </RadioButtonGroup>
          </div>
          <div className='mt3'>
            <RadioButtonGroup
              required
              title={
                <FormattedMessage
                  id='DialogFilter.CertificatesVerifiedTealbook'
                  defaultMessage='Certificates Verified by TealBook'
                />
              }
            >
              <RadioButton
                required
                className='flex mv1'
                label={
                  <FormattedMessage
                    id='DialogFilter.TealbookDoNotMeetInclude1'
                    defaultMessage='Include even if certificates do not meet selected {certAgencies} requirements.'
                    values={{
                      certAgencies: (
                        <span className='f7 fw8 mid-gray'>
                          <FormattedMessage id='DialogFilter.CertifyingAgencies' />
                        </span>
                      )
                    }}
                  />
                }
                name='includeRules.tealbook'
                value='anyAgencies'
                checked={includeRules.tealbook === 'anyAgencies'}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleIncludeRulesChange(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
              />
              <RadioButton
                required
                className='flex mv1'
                label={
                  <FormattedMessage
                    id='DialogFilter.TealbookInclude1'
                    defaultMessage='Include only if certificates meet selected {certAgencies} requirements.'
                    values={{
                      certAgencies: (
                        <span className='f7 fw8 mid-gray'>
                          <FormattedMessage id='DialogFilter.CertifyingAgencies' />
                        </span>
                      )
                    }}
                  />
                }
                name='includeRules.tealbook'
                value='includeValid'
                checked={includeRules.tealbook === 'includeValid'}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleIncludeRulesChange(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
              />
              <RadioButton
                required
                className='flex mv1'
                label={
                  <FormattedMessage
                    id='DialogFilter.TealbookIgnore'
                    defaultMessage='Ignore Verified by TealBook'
                  />
                }
                name='includeRules.tealbook'
                value='ignore'
                checked={includeRules.tealbook === 'ignore'}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleIncludeRulesChange(
                    e.currentTarget.name,
                    e.currentTarget.value
                  )
                }
              />
            </RadioButtonGroup>
          </div>
          <div className='mt3'>
            <Label className={styles.label}>
              <FormattedMessage
                id='DialogFilter.Completeness'
                defaultMessage='Completeness Rules'
              />
            </Label>
            <Checkbox
              label={
                <FormattedMessage
                  id='DialogFilter.IncludeAttachment'
                  defaultMessage='Only include certificates with an attachment for these agencies'
                />
              }
              labelFontLight
              className='mv2'
              checked={includeRules.getIn([
                'completeness',
                'attachmentAgenciesSelected'
              ])}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!e.currentTarget.checked) {
                  onChangeIncludeRules(
                    includeRules
                      .setIn(['completeness', 'attachmentAgencies'], List([]))
                      .setIn(
                        ['completeness', 'attachmentAgenciesSelected'],
                        false
                      )
                  )
                }
                if (e.currentTarget.checked) {
                  onChangeIncludeRules(
                    includeRules.setIn(
                      ['completeness', 'attachmentAgenciesSelected'],
                      true
                    )
                  )
                }
              }}
            />
            {includeRules.getIn([
              'completeness',
              'attachmentAgenciesSelected'
            ]) && (
              <AgenciesList
                name='includeRules.completeness.attachmentAgencies'
                authorities={authorities}
                values={includeRules.getIn([
                  'completeness',
                  'attachmentAgencies'
                ])}
                onChange={value =>
                  handleIncludeRulesChange(
                    'includeRules.completeness.attachmentAgencies',
                    value
                  )
                }
              />
            )}
          </div>
          <div className='mt3'>
            <Label className={styles.label}>
              <FormattedMessage
                id='DialogFilter.Attestation'
                defaultMessage='Attestation Rules'
              />
            </Label>
            <Checkbox
              label={
                <FormattedMessage
                  id='DialogFilter.Attestation1'
                  defaultMessage='Require for Self Certified'
                />
              }
              labelFontLight
              className='mv2'
              name='includeRules.attestation.selfCertified'
              checked={includeRules.attestation.get('selfCertified')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleIncludeRulesChange(
                  e.currentTarget.name,
                  e.currentTarget.checked
                )
              }
            />
            <Checkbox
              label={
                <FormattedMessage
                  id='DialogFilter.Attestation2'
                  defaultMessage='Require where Certificates not verified by TealBook'
                />
              }
              labelFontLight
              className='mv2'
              name='includeRules.attestation.notVerifiedByTealbook'
              checked={includeRules.attestation.get('notVerifiedByTealbook')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleIncludeRulesChange(
                  e.currentTarget.name,
                  e.currentTarget.checked
                )
              }
            />
            <Checkbox
              label={
                <FormattedMessage
                  id='DialogFilter.RequireAttestationAgencies'
                  defaultMessage='Require for these Certifying Agencies'
                />
              }
              labelFontLight
              className='mv2'
              checked={includeRules.getIn([
                'attestation',
                'certAgenciesSelected'
              ])}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!e.currentTarget.checked) {
                  onChangeIncludeRules(
                    includeRules
                      .setIn(['attestation', 'certAgencies'], List([]))
                      .setIn(['attestation', 'certAgenciesSelected'], false)
                  )
                }
                if (e.currentTarget.checked) {
                  onChangeIncludeRules(
                    includeRules.setIn(
                      ['attestation', 'certAgenciesSelected'],
                      true
                    )
                  )
                }
              }}
            />
            {includeRules.getIn(['attestation', 'certAgenciesSelected']) && (
              <AgenciesList
                name='includeRules.attestation.certAgencies'
                authorities={authorities}
                values={includeRules.getIn(['attestation', 'certAgencies'])}
                onChange={value =>
                  handleIncludeRulesChange(
                    'includeRules.attestation.certAgencies',
                    value
                  )
                }
              />
            )}
          </div>
        </>
      )}
    </>
  )
}
export default QualificationRulesContent
