import React, { ChangeEvent, useState } from 'react'
import Button from 'shared/components/Button'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import { useSelector, useDispatch } from 'react-redux'
import surveySelectors from '../../selectors/surveySelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import {
  closeVerifyESGAttachmentDialog,
  verifyESGAttachment
} from '../../actions'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Label from 'shared/components/Label'
import TextArea from 'shared/components/TextArea'
import { RecordOf } from 'immutable'
import {
  AttachmentType,
  CertType
} from 'supplier/Survey/reducers/surveyReducer/surveyReducer'

const messages = defineMessages({
  yes: {
    id: 'VerifyAttachmentDialog.yes',
    defaultMessage: 'Yes, it does.'
  },
  no: {
    id: 'VerifyAttachmentDialog.no',
    defaultMessage: 'No, it does not.'
  }
})

type Props = {
  surveyResponseDetailId: string
  attachmentType: 'cert' | 'doc'
  attachment: RecordOf<AttachmentType> | RecordOf<CertType>
}
const VerifyAttachmentDialog = ({
  surveyResponseDetailId,
  attachmentType,
  attachment
}: Props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const userId = useSelector(sessionSelectors.getUserId)
  const isOpen = useSelector(surveySelectors.isVerifyAttachmentDialogOpen)

  const [confirmed, setConfirmed] = useState<'true' | 'false' | ''>('')
  const [notes, setNotes] = useState<string>('')

  let key
  if (attachmentType === 'cert') {
    key = (attachment as RecordOf<CertType>).get('timeStamp')
  } else {
    key = (attachment as RecordOf<AttachmentType>).get('fileName')
  }

  const handleCancelClick = () => {
    setConfirmed('')
    setNotes('')
    dispatch(closeVerifyESGAttachmentDialog())
  }

  const handleOkClick = () => {
    dispatch(
      verifyESGAttachment({
        surveyResponseDetailId,
        attachment: attachment?.toJS(),
        validation: {
          orgUnitId,
          userId,
          timeStamp: new Date().toISOString(),
          confirmed: confirmed === 'true',
          notes
        }
      })
    )
    setConfirmed('')
    setNotes('')
  }

  const handleConfirmedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmed(event.currentTarget.value as 'true' | 'false')
  }

  const handleNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.currentTarget.value)
  }

  return (
    <>
      <Dialog fullWidth open={isOpen === key} onClose={handleCancelClick}>
        <DialogTitle>
          <FormattedMessage
            id='VerifyAttachmentDialog.VerifyDocTitle'
            defaultMessage={'Verify a document'}
          />
        </DialogTitle>
        <DialogContent>
          <Label>
            <FormattedMessage
              id='VerifyAttachmentDialog.ConfirmedQuestion'
              defaultMessage={
                'Does this document adequately answer the associated question?'
              }
            />
          </Label>
          <RadioGroup
            color='primary'
            aria-label='Confirmed'
            name='confirmed'
            value={confirmed}
            onChange={handleConfirmedChange}
          >
            <FormControlLabel
              control={<Radio color='primary' size='small' value={'true'} />}
              label={intl.formatMessage(messages.yes)}
            />
            <FormControlLabel
              control={<Radio color='primary' size='small' value={'false'} />}
              label={intl.formatMessage(messages.no)}
            />
          </RadioGroup>
          <Label htmlFor='notes'>
            <FormattedMessage
              id='VerifyAttachmentDialog.Notes'
              defaultMessage={'Notes'}
            />
          </Label>
          <TextArea
            aria-label='Notes'
            name='notes'
            value={notes}
            onChange={handleNoteChange}
            maxLength={200}
          />
        </DialogContent>
        <DialogActions>
          <Button autoSize disabled={!confirmed} onClick={handleOkClick}>
            <FormattedMessage id='OK' defaultMessage={'OK'} />
          </Button>
          <Button autoSize secondary onClick={handleCancelClick}>
            <FormattedMessage id='Cancel' defaultMessage={'Cancel'} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default VerifyAttachmentDialog
