import React, { Component, ReactNode } from 'react'
import { Field } from 'redux-form/immutable'
import { Link } from 'react-router-dom'
import paths from 'accounts/routes/paths'
import Button from 'shared/components/Button'
import Card from 'shared/components/Card'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import Warning from 'shared/components/Warning'
import { FormattedMessage } from 'react-intl'

/**
 * EnterEmailForm is used when you want to have the user prompted for only an email adress and nothing else, it has an area at the bottom devoted to going back to the login page
 */
type Props = {
  /** handelSubmit is called in the event that the submit button is pressed */
  handleSubmit: () => void
  /** this text appears on the submit button (eg. reset password) */
  submitLabel: string | ReactNode
  /** this text is displayed below the title, intended to be used to pass errors to the user */
  errorMessage: string | ReactNode
  loginOptions?: string
  client?: string
}

class EnterEmailForm extends Component<Props> {
  render() {
    const {
      handleSubmit,
      submitLabel,
      errorMessage,
      loginOptions,
      client
    } = this.props

    return (
      <form className='tl' onSubmit={handleSubmit}>
        <Card>
          <Label htmlFor='email' className='db mb1 f7 fw6'>
            <FormattedMessage id='LoginForm.email' />
          </Label>
          <Field name='email' component={Input} type='email' required />

          <Button type='submit' label={submitLabel} className='mt4' />

          {errorMessage && (
            <div className='mt4'>
              {errorMessage === 'User not found' ? (
                <Link
                  to={paths.register}
                  className='f5 fw6 dib teal no-underline underline-hover pl1 pointer'
                >
                  <FormattedMessage
                    id='EnterEmailForm.pleaseRegisterHere'
                    defaultMessage='Email was not found, please register here'
                  />
                </Link>
              ) : (
                <Warning message={errorMessage} />
              )}
            </div>
          )}
        </Card>

        <div className='pt4 tc'>
          <span className='f5 fw4 mid-gray'>
            <FormattedMessage
              id='EnterEmailForm.alreadyHaveAnAccount'
              defaultMessage='Already have an account?'
            />
          </span>
          <Link
            to={`${paths.login}${
              loginOptions ? `?options=${loginOptions}` : ''
            }${client ? `&client=${client}` : ''}`}
          >
            <b className='f5 fw6 dib teal no-underline underline-hover pl1 pointer'>
              <FormattedMessage
                id='EnterEmailForm.loginNow'
                defaultMessage='Log in now!'
              />
            </b>
          </Link>
        </div>
      </form>
    )
  }
}

export default EnterEmailForm
