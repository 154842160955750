import { connect } from 'react-redux'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import { fromJS } from 'immutable'
import AssignedBy from '../../components/AssignedBy'
import { Map } from 'immutable'

type Props = {
  userId: string
}

export default connect((state: Map<string, {}>, props: Props) => {
  const user = state.getIn(['data', 'users', props.userId], fromJS({}))
  const org = state.getIn(
    ['data', 'orgUnits', user.getIn(['parents', 'OrgUnit'])],
    fromJS({})
  )

  return {
    profilePictureUrl: fileToUrl(user.get('profilePicture')),
    firstName: user.get('firstName'),
    lastName: user.get('lastName'),
    email: user.get('email'),
    title: user.get('title'),
    orgName: org.get('name')
  }
})(AssignedBy)
