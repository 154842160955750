import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import MyCompanyPage from '../../components/MyCompanyPage'
import profileSelectors from '../../selectors/profileSelectors'
import qs from 'qs'
import RootState from 'shared/models/RootState'
import { loadTier2SupplierList } from '../../../Insights/store/actions'
import insightsSelectors from 'buyer/Insights/store/insightsSelectors'
import communitiesSelectors from '../../../Communities/selectors/communitiesSelectors'
import cardsSelectors from 'buyer/shared/selectors/cardsSelectors'

export default connect(
  (state: RootState, props: RouteComponentProps<{ supplierId: string }>) => {
    const relationship = profileSelectors.getRelationship(state)
    const customAttrs = relationship && relationship.get('customAttributes')
    const attachments = relationship && relationship.get('attachments')
    const standardAttrs =
      relationship &&
      (relationship.get('spend') ||
        relationship.get('internalSupplierId') ||
        relationship.get('internalCategory'))
    const queryParams = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })
    const tier2Supplier = insightsSelectors.getTier2SupplierById(
      state,
      props.match.params.supplierId
    )
    const supplierId = profileSelectors.getDataField(state, 'id')
    const card = cardsSelectors.getBySupplier(state, supplierId)
    const privateOfferingsTags = card.get('privateOfferings')
    return {
      hasEndorsements: profileSelectors.hasConnections(state),
      hasCustomAttributes: !!(customAttrs && customAttrs.size > 0),
      hasAttachments: !!(attachments && attachments.size > 0),
      hasStandardAttributes: !!standardAttrs,
      scrollToReviews: queryParams && !!queryParams.scrollToReviews,
      scrollToAttachment: queryParams && !!queryParams.scrollToAttachment,
      scrollToActivities: queryParams && !!queryParams.scrollToActivities,
      scrollToRelationship: queryParams && !!queryParams.scrollToRelationship,
      scrollToAttribute: queryParams && !!queryParams.scrollToAttribute,
      sharedTier2Quarters: tier2Supplier && tier2Supplier.get('tier2'),
      hasBeenInvitedToTier2:
        tier2Supplier && tier2Supplier.get('status') !== 'Declined',
      isTier2Enabled: !!communitiesSelectors.getTier2Community(state),
      privateOfferingsTagCount:
        privateOfferingsTags && privateOfferingsTags.size
    }
  },
  { loadTier2SupplierList }
)(withRouter(MyCompanyPage))
