import React, { Component } from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes/paths'
import { FormattedMessage } from 'react-intl'
import parsePath from 'shared/utils/parsePath'

type Props = {
  communityId: string
  hasForm: boolean
}

class CommunityNavBar extends Component<Props> {
  render() {
    const { communityId, hasForm } = this.props
    const pathToCompany = parsePath(paths.community, { communityId })

    return (
      <NavBar>
        <NavLink
          label={
            <FormattedMessage
              id='CommunityNavBar.Details'
              defaultMessage='Details'
            />
          }
          to={pathToCompany}
          isActive={(match, location) => location.pathname === pathToCompany}
        />
        <NavLink
          label={
            <FormattedMessage
              id='CommunityNavBar.Buyers'
              defaultMessage='Buyers'
            />
          }
          to={paths.communityBuyers.replace(':communityId', communityId)}
          // isActive={(match, location) => location.pathname === pathToCompany}
        />
        {hasForm && (
          <NavLink
            label={
              <FormattedMessage
                id='CommunityNavBar.Form'
                defaultMessage='Form'
              />
            }
            to={parsePath(paths.communityForm, {
              communityId,
              membershipId: 'form'
            })}
          />
        )}
        <NavLink
          label={
            <FormattedMessage
              id='CommunityNavBar.Metrics'
              defaultMessage='Metrics'
            />
          }
          to={parsePath(paths.communityMetrics, { communityId })}
        />
      </NavBar>
    )
  }
}

export default CommunityNavBar
