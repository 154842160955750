import React, { FC } from 'react'
import { List, RecordOf } from 'immutable'
import { useHistory } from 'react-router'
import VetTermsPage from '../../components/VetTermsPage'
import { loadVet, openVet, declineVet } from '../../actions'
import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import { connect } from 'react-redux'
import paths from '../../routes/paths'
import parsePath from 'shared/utils/parsePath'

type declineVetProps = {
  vetBuyerSupplierId: string
  description: string
}

type VetProps = {
  name: string
  id: string
  vetDetails: RecordOf<{
    decisionDate: string
    responseDate: string
    termsAndConditionsSource: string
    termsAndConditions: string
  }>
}

type Props = {
  vet: List<RecordOf<VetProps>>
  terms: string
  match: { params: { vetBuyerSupplierId: string } }
  onDeclineVet: (arg: declineVetProps) => void
  onOpenVet: (vetBuyerSupplierId: string) => void
}

export const VetTermsPageContainer: FC<Props> = props => {
  const history = useHistory()
  const onOpenVet = () => {
    props.onOpenVet(props.match.params.vetBuyerSupplierId)
    history.push(
      parsePath(paths.vetDetails, {
        vetBuyerSupplierId: props.match.params.vetBuyerSupplierId
      })
    )
  }

  const onDeclineVet = description => {
    props.onDeclineVet({
      vetBuyerSupplierId: props.match.params.vetBuyerSupplierId,
      description
    })
    history.push(
      parsePath(paths.vetResponse, {
        vetBuyerSupplierId: props.match.params.vetBuyerSupplierId
      })
    )
  }
  const { vet, terms } = props
  return (
    <div>
      {vet.size > 0 && (
        <VetTermsPage
          terms={terms}
          onOpenVet={onOpenVet}
          onDeclineVet={onDeclineVet}
        />
      )}
    </div>
  )
}

export default connect(
  state => {
    const vet = vetBuyerSupplierSelectors.getVet(state)
    const termsAndConditionsSource =
      vet && vet.getIn(['vetDetails', 'termsAndConditionsSource'])
    const terms =
      termsAndConditionsSource &&
      termsAndConditionsSource !== 'Default' &&
      vet.getIn(['vetDetails', 'termsAndConditions'], '')

    return {
      vet,
      terms
    }
  },
  {
    onOpenVet: openVet,
    onDeclineVet: declineVet,
    onLoadVet: loadVet
  }
)(VetTermsPageContainer)
