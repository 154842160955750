import React, { Component } from 'react'
import { connect } from 'react-redux'
import InsightWidget from 'shared/components/InsightWidget'
import paths from '../../../routes/paths'
import qs from 'qs'
import { defineMessages, injectIntl, IntlShape } from 'react-intl'
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Cell
} from 'recharts'
import RootState from 'shared/models/RootState'
import { RecordOf } from 'immutable'

const INVITE_COLORS = ['#00B8A1', '#F1CD5A', '#D0021B']
const FORM_COLORS = ['#24CDDA', '#EEEEEE', '#B8E986']
const messages = defineMessages({
  title: {
    id: 'SupplierStatContainer.SupplierStatContainer',
    defaultMessage: 'Suppliers'
  },
  noSupplier: {
    id: 'SupplierStatContainer.NoSupplier',
    defaultMessage: 'No Supplier in the Community'
  },
  invitesPending: {
    id: 'SupplierStatContainer.Pending',
    defaultMessage: 'Pending'
  },
  invitesAccepted: {
    id: 'SupplierStatContainer.Accepted',
    defaultMessage: 'Accepted'
  },
  invitesDeclined: {
    id: 'SupplierStatContainer.Declined',
    defaultMessage: 'Declined'
  },
  invitationSent: {
    id: 'SupplierStatContainer.InvitationsSent',
    defaultMessage: 'Invitations Sent'
  },
  supplierMembers: {
    id: 'SupplierStatContainer.SupplierMembers',
    defaultMessage: 'Supplier Members'
  },
  suppliersDeclined: {
    id: 'SupplierStatContainer.InvitationsDeclined',
    defaultMessage: 'Suppliers Declined'
  },
  formsCompleted: {
    id: 'SupplierStatContainer.FormsCompleted',
    defaultMessage: 'Forms Completed'
  },
  formsIncomplete: {
    id: 'SupplierStatContainer.FormsIncomplete',
    defaultMessage: 'Forms Incomplete'
  }
})
type Props = {
  push: (path: string) => void
  intl: IntlShape
  supplierStats: RecordOf<{
    invitesAccepted: number
    invitesPending: number
    invitesDeclined: number
    formsCompleted: number
    formsIncomplete: number
  }>
} & ContainerProps

export class SupplierStatContainer extends Component<Props> {
  handleClick = status => {
    const { push, communityId } = this.props

    const queryString = qs.stringify({
      filter: {
        communities: [communityId],
        communityStatus: status && [status]
      }
    })

    push(`${paths.search}?${queryString}`)
  }

  renderStat = (label, value, handleClick?) => (
    <div
      className={`mb3 ${handleClick ? 'pointer dim' : ''}`}
      onClick={() => handleClick()}
    >
      <div className='f3 teal'>{value}</div>
      <div className='gray f8'>{label}</div>
    </div>
  )

  render() {
    const { supplierStats, intl } = this.props
    const overallInvites = [
      {
        name: intl.formatMessage(messages['invitesAccepted']),
        filterValue: 'Accepted',
        value: supplierStats.get('invitesAccepted')
      },
      {
        name: intl.formatMessage(messages['invitesPending']),
        filterValue: 'Invited',
        value: supplierStats.get('invitesPending')
      },
      {
        name: intl.formatMessage(messages['invitesDeclined']),
        filterValue: 'Declined',
        value: supplierStats.get('invitesDeclined')
      }
    ]

    const overallForm = (supplierStats.get('formsCompleted') ||
      supplierStats.get('formsIncomplete')) && [
      {
        name: intl.formatMessage(messages['formsCompleted']),
        value: supplierStats.get('formsCompleted')
      },
      {
        name: intl.formatMessage(messages['formsIncomplete']),
        value: supplierStats.get('formsIncomplete')
      }
    ]

    return (
      <InsightWidget title={intl.formatMessage(messages['title'])}>
        {supplierStats.get('suppliersTotal', 0) > 0 ? (
          <div className='flex justify-between items-center'>
            <div className='pt3 tc'>
              {this.renderStat(
                intl.formatMessage(messages['invitationSent']),
                supplierStats.get('suppliersTotal', 0).toLocaleString(),
                this.handleClick
              )}
              {supplierStats.get('formsCompleted', 0) > 0 &&
                this.renderStat(
                  intl.formatMessage(messages['formsCompleted']),
                  supplierStats.get('formsCompleted', 0).toLocaleString()
                )}
            </div>
            <div style={{ width: '70%', height: 295, fontSize: 11 }}>
              <ResponsiveContainer>
                <PieChart>
                  <Legend verticalAlign='top' height={36} />
                  <Tooltip
                    formatter={(value, name, props) => value.toLocaleString()}
                  />
                  <Pie
                    data={overallInvites}
                    dataKey='value'
                    nameKey='name'
                    startAngle={180}
                    endAngle={-180}
                    cx='50%'
                    cy='50%'
                    outerRadius={80}
                    fill='#F1CD5A'
                    onClick={({ filterValue }) => {
                      if (filterValue) {
                        this.handleClick(filterValue)
                      }
                    }}
                  >
                    {overallInvites.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={INVITE_COLORS[index % INVITE_COLORS.length]}
                      />
                    ))}
                  </Pie>
                  {overallForm && (
                    <Pie
                      data={overallForm}
                      dataKey='value'
                      nameKey='name'
                      startAngle={180}
                      endAngle={-180}
                      cx='50%'
                      cy='50%'
                      innerRadius={90}
                      outerRadius={110}
                      fill='#00B8A1'
                    >
                      {overallInvites.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={FORM_COLORS[index % FORM_COLORS.length]}
                        />
                      ))}
                    </Pie>
                  )}
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className='pt3 tc'>
            {intl.formatMessage(messages['noSupplier'])}
          </div>
        )}
      </InsightWidget>
    )
  }
}
type ContainerProps = {
  communityId: string
}
export default connect((state: RootState, props: ContainerProps) => ({
  supplierStats: state.getIn([
    'buyer',
    'insights',
    'communityById',
    props.communityId,
    'supplierStats'
  ])
}))(injectIntl(SupplierStatContainer))
