import React, { ReactElement } from 'react'
import ReportRow from './ReportRow'
import { FormattedMessage, useIntl } from 'react-intl'
import certificationCategories from 'shared/models/CertificationCategories'

type Props = {
  report1Header: string | ReactElement<FormattedMessage>
  report1: { [key: string]: any }
  subCategories: string[]
}

const ReportTable = (props: Props) => {
  const intl = useIntl()
  const { report1Header, report1, subCategories } = props

  return (
    <div className='pa4 ba b--black-10 bg-white'>
      <ReportRow separator bold before={report1Header} />
      <ReportRow
        label={
          <FormattedMessage
            id='CompareReports.DiverseTotalSpend'
            defaultMessage='Diverse Total Spend'
          />
        }
        before={report1.totalDiverseSpend}
      />
      <ReportRow
        label={
          <FormattedMessage
            id='CompareReports.QualifiedSpend'
            defaultMessage='Qualified Spend'
          />
        }
        color='dark-green'
        before={report1.qualifiedSpend}
      />
      <ReportRow
        label={
          <FormattedMessage
            id='CompareReports.PotentialSpend'
            defaultMessage='Potential Spend'
          />
        }
        color='dark-blue'
        before={report1.potentialSpend}
      />
      <ReportRow
        label={
          <FormattedMessage
            id='CompareReports.DisqualifiedSpend'
            defaultMessage='Disqualified Spend'
          />
        }
        separator
        color='dark-red'
        before={report1.disqualifiedSpend}
      />
      {subCategories.map(subCategory => {
        const subCategoryLabel = certificationCategories.diversity
          .subCategoriesAcronyms[subCategory.toLowerCase()]
          ? intl.formatMessage(
              certificationCategories.diversity.subCategoriesAcronyms[
                subCategory.toLowerCase()
              ]
            )
          : subCategory
        const report1Overview = report1?.overview[subCategory]
        return (
          <div key={subCategory}>
            <ReportRow
              label={
                <FormattedMessage
                  id='CompareReports.Qualified'
                  defaultMessage='Qualified {subCategory}'
                  values={{ subCategory: subCategoryLabel }}
                />
              }
              useToggle
              type='qualified'
              subCategory={subCategory}
              lineItems1={report1?.lineItems}
              before={report1Overview?.qualifiedAmount}
            />
            <ReportRow
              label={
                <FormattedMessage
                  id='CompareReports.Potential'
                  defaultMessage='Potential {subCategory}'
                  values={{ subCategory: subCategoryLabel }}
                />
              }
              useToggle
              type='potential'
              subCategory={subCategory}
              lineItems1={report1?.lineItems}
              before={report1Overview?.potentialAmount}
            />
            <ReportRow
              label={
                <FormattedMessage
                  id='CompareReports.Disqualified'
                  defaultMessage='Disqualified {subCategory}'
                  values={{ subCategory: subCategoryLabel }}
                />
              }
              separator
              useToggle
              type='disqualified'
              subCategory={subCategory}
              lineItems1={report1?.lineItems}
              before={report1Overview?.disqualifiedAmount}
            />
          </div>
        )
      })}
    </div>
  )
}

export default ReportTable
