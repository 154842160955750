import { fromJS } from 'immutable'
import { createSelector } from 'reselect'

export const getVetBuyerSuppliers = state =>
  state.getIn(['buyer', 'supplierProfile', 'vetBuyerSuppliers', 'data']) ||
  fromJS({})
export const getVetBuyerSuppliersInList = createSelector(
  getVetBuyerSuppliers,
  vetBuyerSuppliers =>
    vetBuyerSuppliers
      .toList()
      .sort((v1, v2) => {
        const dateV1 = new Date(v1.get('created'))
        const dateV2 = new Date(v2.get('created'))

        return dateV1 > dateV2 ? -1 : 1
      })
      .map(vetBS =>
        fromJS({
          id: vetBS.get('vetId'),
          lead: vetBS.get('lead'),
          name: vetBS.get('name') || 'Untitled',
          date: vetBS.get('created'),
          status: vetBS.get('status')
        })
      )
)

export const getVetTotalCount = state =>
  state.getIn([
    'buyer',
    'supplierProfile',
    'vetBuyerSuppliers',
    'totalCount'
  ]) || 0
