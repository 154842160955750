import React, { ChangeEvent, ReactNode } from 'react'
import Input from 'shared/components/Input'
import numberFormat from 'shared/utils/numberFormat'

type Props = {
  title: ReactNode
  spendAmount: number
  allocation?: number
  onChangeAllocation: (newValue?: number) => void
  required?: boolean
  readOnly?: boolean
}

const SpendAllocationItem = (props: Props) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const percentage = e.target.value
    if (percentage.length > 0) {
      const allocate =
        Number(percentage) > 100
          ? 100
          : Number(percentage) < 0
          ? 0
          : Number(percentage)
      props.onChangeAllocation(allocate)
    } else {
      props.onChangeAllocation()
    }
  }

  return (
    <div className='flex items-center mt2 f7'>
      <div className='w-40'>{props.title}</div>
      <div className='w-30 tr'>
        <span>${numberFormat(props.spendAmount, 2)}</span>
        <span className='ml3'>&times;</span>
        <Input
          value={props.allocation !== undefined ? props.allocation : ''}
          onChange={handleOnChange}
          wrapperClassName='dib ml3 mr2'
          className='f7'
          type='number'
          min={0}
          max={100}
          step={0.0001}
          maxLength={3}
          required={props.required}
          readOnly={props.readOnly}
        />
        <span>&#37;</span>
      </div>
      <span className='flex-auto tr'>
        {props.allocation
          ? `$${numberFormat(props.spendAmount * (props.allocation / 100), 2)}`
          : ''}
      </span>
    </div>
  )
}

export default SpendAllocationItem
