import React, { ChangeEvent, useState } from 'react'
import DialogTitle from 'shared/components/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import { FormattedMessage } from 'react-intl'
import useSmallScreen from 'shared/utils/useSmallScreen'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import Switch from 'shared/components/Switch'
import { RecordOf } from 'immutable'
import Contact from 'shared/models/Contact'
import usersSelectors from 'shared/selectors/usersSelectors'
import { useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'

type Props = {
  open: boolean
  onSubmit: (contact: {
    id: string
    isSustainabilityContact?: boolean
    contactFor?: string
  }) => void
  onClose: () => void
  contact: RecordOf<Contact>
}
const UpdateContactDialog = (props: Props) => {
  const isMobile = useSmallScreen()
  const { open, onSubmit, onClose, contact } = props
  const user = useSelector((state: RootState) =>
    usersSelectors.getById(state, contact.get('user'))
  )

  const [isSustainabilityContact, setIsSustainabilityContact] = useState<
    boolean
  >(() => !!user.get('isSustainabilityContact'))
  const [contactFor, setContactFor] = useState<string>(() =>
    contact.get('contactFor')
  )

  const handleUpdateClick = () => {
    let payload = {
      id: contact.get('user')
    }
    if (user.get('isSustainabilityContact') !== isSustainabilityContact) {
      payload = Object.assign(payload, { isSustainabilityContact })
    }
    if (contact.get('contactFor') !== contactFor) {
      payload = Object.assign(payload, { contactFor })
    }

    onSubmit(payload)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle>
        <FormattedMessage
          id='UpdateContactDialog.EditTeamMember'
          defaultMessage='Edit team member'
        />
      </DialogTitle>
      <DialogContent>
        <Label>
          <FormattedMessage
            id='UpdateContactDialog.ContactName'
            defaultMessage='Name'
          />
        </Label>
        <Text>
          {user?.get('firstName') || ''} {user?.get('lastName') || ''}
        </Text>
        <div className='mt3'>
          <div className='dib pr2'>
            <Switch
              label={
                <FormattedMessage
                  id='UpdateContactDialog.AnswerSustainabilityAnswer'
                  defaultMessage='Responsible for sustainability related questions'
                />
              }
              checked={isSustainabilityContact}
              onChange={() => setIsSustainabilityContact(checked => !checked)}
            />
          </div>
        </div>
        <Label htmlFor='contactFor'>
          <FormattedMessage
            id='UpdateContactDialog.ContactFor'
            defaultMessage='Contact For'
          />
        </Label>
        <Input
          name='contactFor'
          value={contactFor || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setContactFor(e.currentTarget.value)
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdateClick}>
          <FormattedMessage id='Update' defaultMessage='Update' />
        </Button>
        <Button secondary onClick={onClose}>
          <FormattedMessage id='Cancel' defaultMessage='Cancel' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateContactDialog
