import { createMuiTheme } from '@material-ui/core'

type CustomThemeOptions = Partial<{
  primaryColor: string
  primaryLightColor: string
}>

const createTheme = (options?: CustomThemeOptions) =>
  createMuiTheme({
    palette: {
      primary: {
        light: options?.primaryLightColor || '#dcf6f4',
        main: options?.primaryColor || '#028383',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#999999',
        contrastText: '#555555'
      }
    },
    overrides: {
      MuiFormControl: {
        root: {
          width: '100%'
        }
      },
      MuiInputBase: {
        root: {
          fontFamily: '"Open Sans", sans-serif'
        },
        input: {
          height: 'auto',
          fontSize: '0.825rem'
        }
      },
      MuiOutlinedInput: {
        root: {
          padding: '0.5rem'
        },
        input: {
          padding: 0
        },
        adornedStart: {
          paddingLeft: '0.5rem'
        },
        notchedOutline: {
          borderRadius: 2,
          borderColor: 'rgba( 0, 0, 0, .1 )',
          '&:hover': {
            borderColor: 'rgba( 0, 0, 0, .1 )'
          }
        }
      },
      MuiIconButton: {
        root: {
          width: 24,
          height: 24,
          padding: 0
        }
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: 0,
          marginRight: 0
        },
        label: {
          fontSize: '0.825rem'
        }
      },
      MuiButton: {
        root: {
          minWidth: 'auto'
        },
        label: {
          textTransform: 'none'
        }
      },
      MuiMenuItem: {
        root: {
          fontSize: '.875rem'
        }
      },
      MuiExpansionPanel: {
        root: {
          boxShadow: 'none',
          border: '1px solid rgba(0,0,0,.1)'
        }
      }
    }
  })

export default createTheme
