import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { diff } from 'deep-object-diff'
import equalsIgnoreOrders from 'shared/utils/equalsIgnoreOrder'
import RulesSummary, { RulesType } from './RulesSummary'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/WarningOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'

type Props = {
  rules: RulesType
  rules2?: RulesType
}

const QualificationRulesInfo = (props: Props) => {
  const { rules, rules2 } = props
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const isRulesSame = useMemo(() => {
    if (rules && rules2) {
      // check if both reports have the same qualification rules
      const {
        baseRules_certAgencies: report1CertAgencies,
        baseRules_countries: report1Countries,
        baseRules_subCategories: report1SubCategories,
        includeRules_attestation_certAgencies: report1AttestationCertAgencies,
        includeRules_completeness_attachmentAgencies: report1CompletenessAgencies,
        ...report1RestRules
      } = rules as RulesType
      const {
        baseRules_certAgencies: report2CertAgencies,
        baseRules_countries: report2Countries,
        baseRules_subCategories: report2SubCategories,
        includeRules_attestation_certAgencies: report2AttestationCertAgencies,
        includeRules_completeness_attachmentAgencies: report2CompletenessAgencies,
        ...report2RestRules
      } = rules2 as RulesType

      return (
        equalsIgnoreOrders(report1CertAgencies, report2CertAgencies) &&
        equalsIgnoreOrders(report1Countries, report2Countries) &&
        equalsIgnoreOrders(report1SubCategories, report2SubCategories) &&
        equalsIgnoreOrders(
          report1AttestationCertAgencies,
          report2AttestationCertAgencies
        ) &&
        equalsIgnoreOrders(
          report1CompletenessAgencies,
          report2CompletenessAgencies
        ) &&
        Object.keys(diff(report1RestRules, report2RestRules)).length === 0
      )
    }
    return true
  }, [rules, rules2])

  return (
    <>
      <h5 className='ma0 f5 mid-gray fw6 flex items-center'>
        <FormattedMessage
          id='QualificationRulesInfo.Rules'
          defaultMessage='Qualification Rules'
        />
        {isRulesSame ? (
          <InfoIcon
            aria-label='Rules Info'
            className='dim pointer'
            onClick={() => setIsDialogOpen(true)}
          />
        ) : (
          <WarningIcon
            aria-label='Rules Warning'
            className='dim pointer'
            color='error'
            onClick={() => setIsDialogOpen(true)}
          />
        )}
      </h5>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        fullWidth
      >
        <DialogTitle onClose={() => setIsDialogOpen(false)}>
          <FormattedMessage
            id='QualificationRulesInfo.Rules'
            defaultMessage='Qualification Rules'
          />
        </DialogTitle>
        <DialogContent>
          <div className='dt'>
            <RulesSummary rules={rules} />
            {!isRulesSame && rules2 && <RulesSummary rules={rules2} />}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default QualificationRulesInfo
