import React, { ReactElement, ReactNode } from 'react'
import Grid from '@material-ui/core/Grid'
import { ReactComponent as MBE } from 'shared/assets/icons/mbe.svg'
import { ReactComponent as SBE } from 'shared/assets/icons/sbe.svg'
import { ReactComponent as VBE } from 'shared/assets/icons/vbe.svg'
import { ReactComponent as WBE } from 'shared/assets/icons/wbe.svg'
import { FormattedMessage } from 'react-intl'
import Skeleton from '@material-ui/lab/Skeleton'
import useTheme from 'shared/utils/useThemeColors'
import numberFormat from 'shared/utils/numberFormat'
import BusinessIcon from '@material-ui/icons/Business'
import Tooltip from 'shared/components/Tooltip'
import { makeStyles, Theme } from '@material-ui/core'

type TotalBoxProps = {
  isLoading?: boolean
  label: ReactElement<FormattedMessage>
  colorClass?: string
  value: number
  count: number
  htmlColor?: string
}
type HoverProps = {
  background?: string
  backgroundOnHover?: string
}

const useStyles = makeStyles<Theme, HoverProps>({
  hoverDarken: {
    transition: 'all .15s',
    filter: 'brightness(90%)',
    '&:hover, &:focus': {
      filter: 'brightness(65%)'
    }
  },
  hoverDarkenLight: props => ({
    transition: 'all .15s',
    background: props.background,
    '&:hover, &:focus': {
      background: props.backgroundOnHover
    }
  }),
  backgroundColor: props => ({
    background: props.background
  })
})
const TotalBox = (props: TotalBoxProps) => {
  const htmlColor = props.htmlColor ? props.htmlColor : ''
  const colorClass = props.colorClass ? props.colorClass : ''
  return (
    <>
      {props.isLoading ? (
        <Skeleton animation='wave' height={23} />
      ) : (
        <div
          className={`${colorClass} f4 fw6 tl`}
          style={{ color: `${htmlColor}` }}
        >
          {`$${numberFormat(props.value || 0, 0)}`}
        </div>
      )}
      <div
        className={`${colorClass} f7 flex justify-between items-center`}
        style={{ color: `${htmlColor}` }}
      >
        {props.label}
        {!props.isLoading && (
          <div className='flex items-center'>
            <Tooltip
              title={
                <FormattedMessage
                  id='DiversityReportSummary.NumSuppliers'
                  defaultMessage='Number of Suppliers: {count}'
                  values={{ count: numberFormat(props.count) }}
                />
              }
            >
              <BusinessIcon
                className={colorClass}
                style={{ color: `${htmlColor}` }}
                fontSize='small'
              />
            </Tooltip>
            {numberFormat(props.count)}
          </div>
        )}
      </div>
    </>
  )
}

type Props = {
  totalSpend?: number
  tier2Spend?: boolean
  qualifiedSpend?: number
  qualifiedCount?: number
  potentialSpend?: number
  potentialCount?: number
  disqualifiedSpend?: number
  disqualifiedCount?: number
  mbeSpend?: number
  sbeSpend?: number
  wbeSpend?: number
  vbeSpend?: number
  mbeCount?: number
  sbeCount?: number
  wbeCount?: number
  vbeCount?: number
  isLoading?: boolean
  onQualifiedClick?: () => void
  onPotentialClick?: () => void
  onDisqualifiedClick?: () => void
  onMbeClick?: () => void
  onWbeClick?: () => void
  onVbeClick?: () => void
  onSbeClick?: () => void
  supplierReportPage?: boolean
}

const DiversityReportSummary = (props: Props) => {
  const {
    isLoading,
    totalSpend = 0,
    tier2Spend,
    qualifiedSpend = 0,
    qualifiedCount = 0,
    potentialSpend = 0,
    potentialCount = 0,
    disqualifiedSpend = 0,
    disqualifiedCount = 0,
    mbeSpend = 0,
    sbeSpend = 0,
    wbeSpend = 0,
    vbeSpend = 0,
    mbeCount = 0,
    sbeCount = 0,
    wbeCount = 0,
    vbeCount = 0,
    onQualifiedClick,
    onPotentialClick,
    onDisqualifiedClick,
    onMbeClick,
    onWbeClick,
    onVbeClick,
    onSbeClick,
    supplierReportPage
  } = props

  const colors = useTheme()
  const styles = useStyles({})

  return !!totalSpend || !!qualifiedSpend ? (
    <>
      {!tier2Spend && !supplierReportPage && (
        <div className='bg-near-white pa2 flex justify-between flex-wrap mv3'>
          <div className={`w-100 w-25-ns mid-gray br-ns b--black-20 bw1 ph2`}>
            <TotalBox
              isLoading={isLoading}
              colorClass={'mid-gray'}
              label={
                <FormattedMessage
                  id='DiversityReportSummary.DiverseTotal'
                  defaultMessage='Diverse Total'
                />
              }
              value={qualifiedSpend + potentialSpend + disqualifiedSpend}
              count={qualifiedCount + potentialCount + disqualifiedCount}
            />
          </div>
          <button
            className={`w-100 w-25-ns mid-gray br-ns b--black-20 bw1 ph2 bn bg-transparent ${
              !isLoading && onQualifiedClick
                ? `pointer ${styles.hoverDarken}`
                : ''
            }`}
            onClick={!isLoading ? onQualifiedClick : undefined}
            aria-disabled={isLoading || !onQualifiedClick}
          >
            <TotalBox
              isLoading={isLoading}
              colorClass={colors.primaryText}
              label={
                <FormattedMessage
                  id='DiversityReportSummary.DiverseQualified'
                  defaultMessage='Diverse Qualified'
                />
              }
              value={qualifiedSpend}
              count={qualifiedCount}
            />
          </button>
          <button
            className={`w-100 w-25-ns mid-gray br-ns b--black-20 bw1 ph2 bn bg-transparent ${
              !isLoading && onPotentialClick
                ? `pointer ${styles.hoverDarken}`
                : ''
            }`}
            onClick={!isLoading ? onPotentialClick : undefined}
            aria-disabled={isLoading || !onPotentialClick}
          >
            <TotalBox
              isLoading={isLoading}
              htmlColor={'#AC6206'}
              label={
                <FormattedMessage
                  id='DiversityReportSummary.DiversePotential'
                  defaultMessage='Diverse Potential'
                />
              }
              value={potentialSpend}
              count={potentialCount}
            />
          </button>
          <button
            className={`w-100 w-25-ns mid-gray ph2 bn bg-transparent ${
              !isLoading && onDisqualifiedClick
                ? `pointer ${styles.hoverDarken}`
                : ''
            }`}
            onClick={!isLoading ? onDisqualifiedClick : undefined}
            aria-disabled={isLoading || !onDisqualifiedClick}
          >
            <TotalBox
              isLoading={isLoading}
              colorClass={'dark-red'}
              label={
                <FormattedMessage
                  id='DiversityReportSummary.DiverseDisqualified'
                  defaultMessage='Diverse Disqualified'
                />
              }
              value={disqualifiedSpend}
              count={disqualifiedCount}
            />
          </button>
        </div>
      )}
      {tier2Spend && (
        <div className='bg-near-white pa2 flex justify-between flex-wrap mv3'>
          <div
            className={`w-100 w-50-ns mid-gray br-ns b--black-20 bw1 ph2 pv2`}
          >
            <div className='f4 fw6'>${numberFormat(totalSpend)}</div>
            <div className='f7'>
              <FormattedMessage
                id='DiversityReportSummary.TotalSpend'
                defaultMessage='TotalSpend'
              />
            </div>
          </div>
          <div
            className={`w-100 w-50-ns mid-gray pv2 ph2 ph3-ns ${colors.primaryText}`}
          >
            {isLoading ? (
              <Skeleton animation='wave' height={23} />
            ) : (
              <div className='f4 fw6'>${numberFormat(qualifiedSpend)}</div>
            )}
            <div className='f7'>
              <FormattedMessage
                id='DiversityReportSummary.Tier2Qualified'
                defaultMessage='Tier 2 spending with small and diverse supplier'
              />
            </div>
          </div>
        </div>
      )}
      <h5 className='db mb3 f7 fw6'>
        <FormattedMessage
          id='DiversityReportSummary.QuickSummary'
          defaultMessage='Quick Summary'
        />
      </h5>

      <Grid container spacing={2} className='mt3'>
        {!!totalSpend && (
          <>
            <Grid item xs={12} md={6}>
              <div className='bg-teal pa3'>
                <div className='flex items-center'>
                  <div
                    className='f3 mr3 white'
                    style={{ marginTop: '0.125rem', marginBottom: '0.125rem' }}
                  >
                    {props.isLoading ? (
                      <Skeleton animation='wave' height={27} width={67} />
                    ) : (
                      `${((qualifiedSpend / totalSpend) * 100).toFixed(1)}%`
                    )}
                  </div>
                  <div className='f7 fw4 white'>
                    <FormattedMessage
                      id='DiversityReportSummary.SelectedSpend'
                      defaultMessage='Of your selected spend is with diverse and small businesses'
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className={`${colors.primaryLightContained} pa3 flex justify-between`}
              >
                <div className={`w-25 tc f7 fw4 mid-gray br b--white bw1`}>
                  {props.isLoading ? (
                    <Skeleton
                      animation='wave'
                      height={15}
                      width={30}
                      className='center'
                    />
                  ) : (
                    <div className='fw6'>
                      {((mbeSpend / totalSpend) * 100).toFixed(1)}%
                    </div>
                  )}
                  <FormattedMessage
                    id='DiversityReportSummary.MBE'
                    defaultMessage='minority'
                  />
                </div>
                <div className={`w-25 tc f7 fw4 mid-gray br b--white bw1`}>
                  {props.isLoading ? (
                    <Skeleton
                      animation='wave'
                      height={15}
                      width={30}
                      className='center'
                    />
                  ) : (
                    <div className='fw6'>
                      {((wbeSpend / totalSpend) * 100).toFixed(1)}%
                    </div>
                  )}
                  <FormattedMessage
                    id='DiversityReportSummary.WBE'
                    defaultMessage='women'
                  />
                </div>
                <div className={`w-25 tc f7 fw4 mid-gray br b--white bw1`}>
                  {props.isLoading ? (
                    <Skeleton
                      animation='wave'
                      height={15}
                      width={30}
                      className='center'
                    />
                  ) : (
                    <div className='fw6'>
                      {((vbeSpend / totalSpend) * 100).toFixed(1)}%
                    </div>
                  )}
                  <FormattedMessage
                    id='DiversityReportSummary.VBE'
                    defaultMessage='veteran'
                  />
                </div>
                <div className={`w-25 tc f7 fw4 mid-gray`}>
                  {props.isLoading ? (
                    <Skeleton
                      animation='wave'
                      height={15}
                      width={30}
                      className='center'
                    />
                  ) : (
                    <div className='fw6'>
                      {((sbeSpend / totalSpend) * 100).toFixed(1)}%
                    </div>
                  )}
                  <FormattedMessage
                    id='DiversityReportSummary.SBE'
                    defaultMessage='small'
                  />
                </div>
              </div>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={3}>
          <SummaryBox
            backgroundColor='#AC6206'
            backgroundColorOnHover='#784404'
            label={
              <FormattedMessage
                id='DiversityReportSummary.MBECount'
                defaultMessage='Minority Owned'
              />
            }
            value={mbeCount}
            icon={<MBE fill='white' height={32} />}
            isLoading={isLoading}
            onClick={!isLoading ? onMbeClick : undefined}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryBox
            backgroundColor='#A64D78'
            backgroundColorOnHover='#743553'
            label={
              <FormattedMessage
                id='DiversityReportSummary.WBECount'
                defaultMessage='Women Owned'
              />
            }
            value={wbeCount}
            icon={<WBE fill='white' height={32} />}
            isLoading={isLoading}
            onClick={!isLoading ? onWbeClick : undefined}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryBox
            backgroundColor='#367BA6'
            backgroundColorOnHover='#255674'
            label={
              <FormattedMessage
                id='DiversityReportSummary.VBECount'
                defaultMessage='Veteran Owned'
              />
            }
            value={vbeCount}
            icon={<VBE fill='white' height={32} />}
            isLoading={isLoading}
            onClick={!isLoading ? onVbeClick : undefined}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryBox
            backgroundColor='#434343'
            backgroundColorOnHover='#2e2e2e'
            label={
              <FormattedMessage
                id='DiversityReportSummary.SBECount'
                defaultMessage='Small Businesses'
              />
            }
            value={sbeCount}
            icon={<SBE fill='white' height={32} />}
            isLoading={isLoading}
            onClick={!isLoading ? onSbeClick : undefined}
          />
        </Grid>
      </Grid>
    </>
  ) : null
}

type BoxProps = {
  value: number
  label: string | ReactElement<FormattedMessage>
  icon: ReactNode
  backgroundColor: string
  backgroundColorOnHover: string
  isLoading?: boolean
  onClick: (() => void) | undefined
}
const SummaryBox = (props: BoxProps) => {
  const styles = useStyles({
    background: props.backgroundColor,
    backgroundOnHover: props.backgroundColorOnHover
  })

  const renderContent = () => (
    <>
      <div className='f7 fw4 flex-auto tl'>
        {props.isLoading ? (
          <Skeleton animation='wave' height={15} width={30} />
        ) : (
          <div className='fw6 tl'>{props.value}</div>
        )}
        {props.label}
      </div>
      <div className='w2-5 h2-5 flex items-center justify-end tr'>
        {props.icon}
      </div>
    </>
  )

  return props.onClick ? (
    <button
      className={`flex pa3 justify-between items-center white bn bg-transparent w-100 shadow-hover ${styles.hoverDarkenLight}`}
      onClick={props.onClick}
      aria-disabled={props.isLoading || !props.onClick}
    >
      {renderContent()}
    </button>
  ) : (
    <div
      className={`flex pa3 justify-between items-center white bn bg-transparent w-100 ${styles.backgroundColor}`}
      aria-disabled={props.isLoading || !props.onClick}
    >
      {renderContent()}
    </div>
  )
}

export default DiversityReportSummary
