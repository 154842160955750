import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useMemo
} from 'react'
import { List, RecordOf, OrderedMap } from 'immutable'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import FileInput from 'shared/components/FileInput'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Table from 'shared/components/Table'
import { Column, TableCellProps } from 'react-virtualized'
import dateFormat from 'shared/utils/dateFormat'
import Select from 'shared/components/Select'
import ReviewSpendItems from './ReviewSpendItems'
import DeleteIcon from '@material-ui/icons/Clear'
import startCase from 'lodash.startcase'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import NavBar from 'shared/components/NavBar'
import paths from '../../diversityPaths'
import Divider from 'shared/components/Divider'
import Link from 'shared/components/Link'
import supplierPaths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import NavTab from 'shared/components/NavTab'
import Warning from 'shared/components/Warning'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import Tooltip from 'shared/components/Tooltip'
import FileSaver from 'file-saver'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import dataPortalPaths from '../../../../data-portal/routes/paths'
import Loading from 'shared/components/Loading'

const messages = defineMessages({
  complete: { id: 'Complete', defaultMessage: 'Complete' },
  processing: { id: 'Processing', defaultMessage: 'Processing' },
  error: { id: 'Error', defaultMessage: 'Error' },
  published: { id: 'Published', defaultMessage: 'Published' }
})

export type UploadHistoryType = {
  id: string
  clientFileName: string
  year: number
  quarter: string
  uploadDate: Date
  uploadBy: string
  status: string
}

type Props = {
  isUploading: boolean
  uploadError: boolean
  uploadHistory?: OrderedMap<string, List<RecordOf<UploadHistoryType>>>
  uploadSpendFile: (payload: {
    file: File
    year: number
    quarter: number
    listType: string
  }) => void
  publishSpend: (payload: { stagingId: string; listType: string }) => void
  removeSpend: (payload: {
    stagingId: string
    listType: string
    uploadHistory?: OrderedMap<string, List<RecordOf<UploadHistoryType>>>
    activeQuarter: string
  }) => void
  loadStagingHistory: (payload: { listType: string }) => void
  isMl?: boolean
  notify: (payload: { message: string }) => void
  isLoading: boolean
}

export const LoadPage = (props: Props) => {
  const {
    uploadHistory,
    isUploading,
    uploadError,
    uploadSpendFile,
    publishSpend,
    removeSpend,
    loadStagingHistory,
    isMl,
    notify,
    isLoading
  } = props
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  const today = useMemo(() => new Date(), [])

  const [uploadQuarter, setUploadQuarter] = useState<string>(
    Math.floor(today.getMonth() / 3 + 1).toString()
  )
  const [uploadYear, setUploadYear] = useState<string>(
    today.getFullYear().toString()
  )
  const [uploadFile, setUploadFile] = useState<File | null>(null)
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined)

  const [confirmRemoveStaging, setConfirmRemoveStaging] = useState<string>('')
  const [recomputeTrendsPressed, setRecomputeTrendsPressed] = useState<boolean>(
    false
  )
  const [computeTrendsError, setComputeTrendsError] = useState<boolean>(false)
  const [exportStagingPressed, setExportStagingPressed] = useState<string>('')

  const activeQuarter = useMemo(() => {
    const { show } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })
    return show
  }, [location.search])

  const activeHistory = useMemo(() => {
    return uploadHistory && uploadHistory.get(activeQuarter)
  }, [activeQuarter, uploadHistory])

  const lastComplete = useMemo(() => {
    return activeHistory
      ? activeHistory.findLastIndex(
          history =>
            history.get('status') === 'published' ||
            history.get('status') === 'complete'
        )
      : -1
  }, [activeHistory])

  const isAnyProcessing = useMemo(() => {
    return (
      !!activeHistory &&
      activeHistory.some(history => history.get('status') === 'processing')
    )
  }, [activeHistory])

  const highlightIndex = useMemo(() => {
    return typeof selectedRow === 'number' ? selectedRow : lastComplete
  }, [selectedRow, lastComplete])

  const handleIntervalCall = useCallback(() => {
    if (isAnyProcessing) {
      loadStagingHistory({
        listType: isMl ? 'ml' : 'supplierTier2Load'
      })
    }
  }, [isAnyProcessing, loadStagingHistory, isMl])

  useEffect(() => {
    if (!isUploading && !uploadError) {
      setUploadFile(null)
    }
  }, [isUploading, uploadError])

  useEffect(() => {
    if (
      !activeQuarter &&
      uploadHistory &&
      uploadHistory.size > 0 &&
      !location.pathname.includes('Ml')
    ) {
      history.push(`?show=${uploadHistory.keySeq().first()}`)
    }
  }, [activeQuarter, history, uploadHistory, location.pathname])

  useEffect(() => {
    setSelectedRow(undefined)
  }, [activeQuarter])

  useEffect(() => {
    const interval = global.setInterval(() => {
      handleIntervalCall()
    }, 60000)
    return () => global.clearInterval(interval)
  }, [handleIntervalCall])

  const handleFileUpload = () => {
    if (uploadFile) {
      uploadSpendFile({
        file: uploadFile,
        year: Number(uploadYear),
        quarter: Number(uploadQuarter),
        listType: isMl ? 'ml' : 'supplierTier2Load'
      })
    }
  }

  const renderActionColumn = ({ cellData, rowData }: TableCellProps) => {
    const fullFileName = rowData.get('fileName')
    const uploadUserId = rowData.get('userId')
    return (
      <div onClick={event => event.stopPropagation()}>
        <Tooltip
          title={
            <FormattedMessage
              id='LoadPage.DownloadLoadFile'
              defaultMessage='Click here to download this file, so you can compare with mapping below.'
            />
          }
        >
          {
            <span
              className='pointer near-black hover-teal no-underline'
              onClick={() =>
                handleSideBySideDownload(cellData, fullFileName, uploadUserId)
              }
            >
              {cellData}
            </span>
          }
        </Tooltip>
      </div>
    )
  }

  const handleSideBySideDownload = (fileName, fullFileName, uploadUserId) => {
    api
      .download(
        `${apiRoutes.loader}/loader/tier2LoadFile/${fullFileName}/${uploadUserId}`
      )
      .then(response => response.blob())
      .then(blob => FileSaver.saveAs(blob, `${fileName}`))
      .catch(err =>
        notify({
          message: err.message
        })
      )
  }

  const handleStagingDownload = rowData => {
    let listType = isMl ? 'ml' : 'supplierTier2Load'
    api
      .get(
        `${apiRoutes.loader}/util/stagingDownload/${rowData.get(
          'id'
        )}/${listType}`
      )
      .then(response => {
        const [fileName] = rowData.get('clientFileName').split('.')
        FileSaver.saveAs(response.url, `Review Mapping-${fileName}`)
        setExportStagingPressed('')
      })
      .catch(err => {
        setExportStagingPressed('')
        notify({
          message: err.message
        })
      })
  }
  return (
    <div>
      <div className='mt3'>
        <div className={`w-100 dib mb3 w-50-ns`}>
          <div className='pr4-ns'>
            {isMl ? (
              <Text>
                <FormattedMessage
                  id='ML.Instruction'
                  defaultMessage='On this page, you can use the in-house ML API for supplier matching. First, download the template to review the fields required for the upload and format your spend with identical column headings. Once you have populated the file, go ahead and upload. After uploading, the file will be processed and then made available for review below.'
                />
              </Text>
            ) : (
              <Text>
                <FormattedMessage
                  id='LoadPage.Instruction'
                  defaultMessage='On this page, you can upload and review each quarter of spend you want to share. First, download the template to review the fields required for the upload and format your spend with identical column headings. Once you have populated the file, use the upload to select a quarter and submit the file. After uploading, the file will be processed and then made available for review below.'
                />
              </Text>
            )}

            <div className='flex items-end'>
              <form method='get' action={`/downloads/tier2SpendTemplate.xlsx`}>
                <Button secondary autoSize type='submit' className='mt3'>
                  <FormattedMessage
                    id='LoadPage.DownloadTemplate'
                    defaultMessage='Download Spend Template'
                  />
                </Button>
              </form>
              {isMl && (
                <Button
                  className='ml2'
                  autoSize
                  disabled={recomputeTrendsPressed}
                  onClick={() => {
                    api
                      .post(`${apiRoutes.loader}/util/computeTrends`)
                      .then(response => {
                        if (response) {
                          setRecomputeTrendsPressed(false)
                        } else {
                          setRecomputeTrendsPressed(false)
                          setComputeTrendsError(true)
                        }
                      })
                  }}
                >
                  {!recomputeTrendsPressed ? (
                    <FormattedMessage
                      id='Loader.RecomputeTrends'
                      defaultMessage='Recompute Trends'
                    />
                  ) : (
                    <FormattedMessage
                      id='Loader.ComputeTrendsProcessing'
                      defaultMessage='Computing Trends in Progress...'
                    />
                  )}
                </Button>
              )}
            </div>
            {computeTrendsError && (
              <Warning
                message={
                  <FormattedMessage
                    id='LoadPage.RecomputeError'
                    defaultMessage='Something went wrong, please contact support.'
                  />
                }
              />
            )}
          </div>
        </div>
        <div className={`w-100 dib v-top w-50-ns`}>
          <Label noPadding>
            <FormattedMessage
              id='LoadPage.UploadLabel'
              defaultMessage='Select a quarter and upload your file'
            />
          </Label>
          <FileInput
            small
            accept='.csv, .xlsx, .xls'
            limit={32}
            name='spendFile'
            isUploading={isUploading}
            value={uploadFile ? uploadFile.name : ''}
            onChange={(file: File | null) => setUploadFile(file)}
          />
          {uploadError && (
            <Warning
              message={
                <FormattedMessage
                  id='LoadPage.Error'
                  defaultMessage='Oops, something went wrong. Please try again later.'
                />
              }
            />
          )}
          <div className='mt2 flex items-center justify-between'>
            <div className='w-100 mr3'>
              <Select
                disabled={isUploading}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setUploadYear(e.currentTarget.value)
                }
                value={uploadYear}
                fullWidth
              >
                <option value={`${today.getFullYear()}`}>
                  {today.getFullYear()}
                </option>
                <option value={`${today.getFullYear() - 1}`}>
                  {today.getFullYear() - 1}
                </option>
                <option value={`${today.getFullYear() - 2}`}>
                  {today.getFullYear() - 2}
                </option>
                <option value={`${today.getFullYear() - 3}`}>
                  {today.getFullYear() - 3}
                </option>
                <option value={`${today.getFullYear() - 4}`}>
                  {today.getFullYear() - 4}
                </option>
              </Select>
            </div>
            <div className='w-100 mr3'>
              <Select
                disabled={isUploading}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setUploadQuarter(e.currentTarget.value)
                }
                value={uploadQuarter}
                fullWidth
              >
                <option value='1'>Q1</option>
                <option value='2'>Q2</option>
                <option value='3'>Q3</option>
                <option value='4'>Q4</option>
              </Select>
            </div>
            <Button
              autoSize
              disabled={!uploadFile || isUploading}
              onClick={handleFileUpload}
            >
              <FormattedMessage id='LoadPage.Upload' defaultMessage='Upload' />
            </Button>
          </div>
        </div>
      </div>
      {uploadHistory && uploadHistory.size > 0 && (
        <>
          <Divider className='mt2' />
          <div
            className='overflow-x-auto overflow-y-hidden'
            style={{ paddingBottom: 3 }}
          >
            <NavBar>
              {uploadHistory.keySeq().map(key => (
                <NavTab
                  key={key}
                  to={`${
                    isMl
                      ? dataPortalPaths.supplierMatchingMl
                      : paths.diversityLoad
                  }?show=${key}`}
                  isActive={(match, location) =>
                    location.search === `?show=${key}`
                  }
                >
                  {key.split('-').join('-Q')}
                </NavTab>
              ))}
            </NavBar>
          </div>
        </>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        activeHistory &&
        activeHistory.size > 0 && (
          <>
            <div className='bg-white br1 ba b--black-10 overflow-hidden'>
              <Table
                rowGetter={({ index }) => activeHistory.get(index)}
                rowCount={activeHistory.size}
                onRowClick={({ rowData, index }) => {
                  if (
                    rowData.get('status') === 'complete' ||
                    rowData.get('status') === 'published'
                  ) {
                    setSelectedRow(index)
                  }
                }}
                highlightIndexes={
                  typeof highlightIndex === 'number'
                    ? List([highlightIndex])
                    : undefined
                }
              >
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.Filename'
                      defaultMessage='Filename'
                    />
                  }
                  dataKey='clientFileName'
                  width={200}
                  cellRenderer={renderActionColumn}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.UploadDate'
                      defaultMessage='Upload Date'
                    />
                  }
                  dataKey='uploadDate'
                  width={200}
                  cellRenderer={({ cellData }: TableCellProps) =>
                    dateFormat(cellData)
                  }
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.User'
                      defaultMessage='User'
                    />
                  }
                  dataKey='uploadBy'
                  width={200}
                  cellRenderer={({ cellData, rowData }: TableCellProps) => (
                    <Link
                      to={parsePath(supplierPaths.userProfile, {
                        userId: rowData.get('userId')
                      })}
                    >
                      {cellData}
                    </Link>
                  )}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.Status'
                      defaultMessage='Status'
                    />
                  }
                  dataKey='status'
                  width={200}
                  cellRenderer={({ rowData }: TableCellProps) => {
                    const { status, fileLoadErrors } = rowData?.toJS()
                    return (
                      <span
                        className={
                          status === 'complete'
                            ? 'green'
                            : status === 'error'
                            ? 'red'
                            : ''
                        }
                      >
                        {messages[status]
                          ? intl.formatMessage(messages[status])
                          : startCase(status)}
                        {status === 'error' &&
                          fileLoadErrors &&
                          fileLoadErrors.length && (
                            <Tooltip title={fileLoadErrors.join()}>
                              <InfoIcon className='mr2 v-mid' />
                            </Tooltip>
                          )}
                      </span>
                    )
                  }}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.Row'
                      defaultMessage='# of Rows'
                    />
                  }
                  dataKey='rowCount'
                  width={140}
                  cellRenderer={({ cellData, rowData }: TableCellProps) =>
                    cellData !== undefined && cellData.toLocaleString()
                  }
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.Actions'
                      defaultMessage='Actions'
                    />
                  }
                  dataKey='status'
                  width={260}
                  flexGrow={1}
                  headerClassName='tr'
                  cellRenderer={({ cellData, rowData }: TableCellProps) => {
                    return cellData === 'complete' ||
                      cellData === 'published' ? (
                      <div className='flex items-center justify-end'>
                        {cellData === 'complete' && !isMl && (
                          <Button
                            autoSize
                            onClick={e => {
                              e.stopPropagation()
                              publishSpend({
                                stagingId: rowData.get('id'),
                                listType: 'supplierTier2Load'
                              })
                            }}
                          >
                            <FormattedMessage
                              id='LoadPage.Publish'
                              defaultMessage='Publish'
                            />
                          </Button>
                        )}
                        {rowData.get('rowCount') > 0 && (
                          <Button
                            className='ml2'
                            autoSize
                            onClick={e => {
                              e.stopPropagation()
                              handleStagingDownload(rowData)
                              setExportStagingPressed(rowData.get('id'))
                            }}
                            disabled={
                              exportStagingPressed === rowData.get('id')
                            }
                          >
                            {exportStagingPressed !== rowData.get('id') ? (
                              <FormattedMessage
                                id='LoadPage.Export'
                                defaultMessage='Export'
                              />
                            ) : (
                              <FormattedMessage
                                id='LoadPage.Exporting1'
                                defaultMessage='Exporting'
                              />
                            )}
                          </Button>
                        )}
                        {cellData === 'complete' && (
                          <DeleteIcon
                            className='ml3 pointer dim'
                            onClick={e => {
                              e.stopPropagation()
                              setConfirmRemoveStaging(rowData.get('id'))
                            }}
                          />
                        )}
                      </div>
                    ) : null
                  }}
                />
              </Table>
            </div>
            {activeHistory.some(
              history => history.get('status') === 'processing'
            ) && (
              <>
                {isMl ? (
                  <Text className='mt3 flex items-center'>
                    <InfoOutlined className='mr2' />
                    <FormattedMessage
                      id='LoadPage.ProcessingMessageHours'
                      defaultMessage='A file can take up to 3 hours to process.'
                    />
                  </Text>
                ) : (
                  <Text className='mt3 flex items-center'>
                    <InfoOutlined className='mr2' />
                    <FormattedMessage
                      id='LoadPage.ProcessingMessage'
                      defaultMessage='A file can take up to an hour to process.'
                    />
                  </Text>
                )}
              </>
            )}
          </>
        )
      )}
      <ConfirmationDialog
        open={!!confirmRemoveStaging}
        onClose={() => setConfirmRemoveStaging('')}
        onConfirm={() =>
          removeSpend({
            stagingId: confirmRemoveStaging,
            listType: isMl ? 'ml' : 'supplierTier2Load',
            uploadHistory,
            activeQuarter
          })
        }
      >
        <Text>
          <FormattedMessage
            id='LoadPage.ConfirmDeleteStaging1'
            defaultMessage='Are you sure you want to remove the this spend?'
          />
        </Text>
      </ConfirmationDialog>
      {highlightIndex > -1 && activeHistory && activeHistory.size > 0 && (
        <ReviewSpendItems
          stagingId={activeHistory?.getIn([highlightIndex, 'id'])}
          isEditable={
            activeHistory?.getIn([highlightIndex, 'status']) !== 'published'
          }
          entity='stagingRow'
          label={
            activeHistory
              ? `${activeHistory.getIn([
                  highlightIndex,
                  'year'
                ])}-Q${activeHistory.getIn([
                  highlightIndex,
                  'quarter'
                ])} - ${activeHistory.getIn([
                  highlightIndex,
                  'clientFileName'
                ])}`
              : ''
          }
        />
      )}
    </div>
  )
}

export default LoadPage
