import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, RecordOf, Map } from 'immutable'
import UserStackItem from 'shared/components/UserStackItem'
import usersSelectors from 'shared/selectors/usersSelectors'
import User from 'shared/models/User'
import RootState from 'shared/models/RootState'

type Props = {
  users?: List<string>
  cacheUsers?: Map<string, RecordOf<User>>
  mini?: boolean
  limit?: number
}

export class UserStack extends Component<Props> {
  render() {
    const { limit = 3, users, cacheUsers, mini } = this.props

    if (!users || users.size === 0 || !cacheUsers || cacheUsers.size === 0) {
      return null
    }

    const avatars = users.take(limit).map(c => {
      const user = cacheUsers.get(c)
      return cacheUsers.has(c) ? (
        <UserStackItem
          key={c}
          profilePictureUrl={user && user.get('profilePictureUrl')}
          mini={mini}
          firstName={user && user.get('firstName')}
          lastName={user && user.get('lastName')}
        />
      ) : (
        <UserStackItem key={c} mini={mini} />
      )
    })

    return (
      <div className='dt w-100 pl2'>
        {avatars}
        {users.size > limit && (
          <div
            className='bg-light-gray mid-gray br-100 ba b--black-10 f9 fw4 tc dib w1 h1 v-mid'
            style={{ lineHeight: '14px' }}
          >
            {users.size - limit}
          </div>
        )}
      </div>
    )
  }
}

export default connect((state: RootState) => ({
  cacheUsers: usersSelectors.getUsers(state)
}))(UserStack)
