import React, { Component } from 'react'
import SearchForm from '../../components/SearchForm'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import qs from 'qs'
import { searchContacts } from '../../../shared/actions'
import { FormattedMessage } from 'react-intl'

type Props = {
  initialValue: string
  searchContacts: (param: string) => void
} & RouteComponentProps

class SearchFormContainer extends Component<Props> {
  state = {
    value: this.props.initialValue || ''
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({
        value: this.props.initialValue || ''
      })
    }
  }

  handleSubmit = e => {
    const { history, searchContacts, initialValue } = this.props

    const { value } = this.state

    e.preventDefault()

    const query = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    const newQuery = {
      ...query,
      cq: value
    }

    if (initialValue === value) {
      searchContacts(qs.stringify(newQuery))
    } else {
      history.push({
        search: qs.stringify(newQuery)
      })
    }
  }

  handleChange = value => {
    this.setState({
      value
    })
  }

  handleClear = () => {
    const { history } = this.props

    this.setState({
      value: ''
    })

    const query = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    this.props.history.push({
      search: qs.stringify({
        ...query,
        cq: ''
      })
    })
  }

  render() {
    return (
      <FormattedMessage
        id='SearchFormContainer.SearchForPeople'
        defaultMessage='Search for People'
      >
        {message => (
          <SearchForm
            {...this.props}
            value={this.state.value}
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
            onClear={this.handleClear}
            placeholder={message}
          />
        )}
      </FormattedMessage>
    )
  }
}

export default withRouter(
  connect(
    (state, props: RouteComponentProps) => {
      const { cq } = qs.parse(props.location.search, {
        ignoreQueryPrefix: true
      })

      return {
        initialValue: cq
      }
    },
    {
      searchContacts
    }
  )(SearchFormContainer)
)
