import React from 'react'
import Divider from 'shared/components/Divider'

const InsightNavBarWrapper = ({ children }) => {
  return (
    <>
      <div className='mw8 center ph3'>
        <div
          className='bg-tb-gray overflow-x-auto overflow-y-hidden'
          style={{
            position: 'fixed',
            top: 51,
            width: '100%',
            zIndex: 4,
            paddingBottom: '3px'
          }}
        >
          {children}
        </div>

        <div
          style={{
            height: 51,
            width: 100
          }}
        />
      </div>

      <div
        style={{
          position: 'fixed',
          top: 104,
          width: '100%',
          height: 1
        }}
      >
        <Divider />
      </div>
    </>
  )
}

export default InsightNavBarWrapper
