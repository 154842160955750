import React, { Component } from 'react'
import PageSideSection from 'shared/components/PageSideSection'
import Scrollable from 'shared/components/Scrollable'
import DetailsSectionContainer from '../../containers/DetailsSectionContainer'
import { FormattedMessage } from 'react-intl'

class DetailsSection extends Component {
  render() {
    return (
      <PageSideSection
        title={
          <FormattedMessage
            id='DetailsSection.ServiceAreas'
            defaultMessage='Service Areas'
          />
        }
        noPadding
      >
        <Scrollable>
          <DetailsSectionContainer />
        </Scrollable>
      </PageSideSection>
    )
  }
}

export default DetailsSection
