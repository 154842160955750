import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import TagCloud from 'shared/components/TagCloud'
import InsightPaper from 'shared/components/InsightPaper'
import InsightTitle from 'shared/components/InsightTitle'
import paths from '../../../routes/paths'
import qs from 'qs'
import { FormattedMessage } from 'react-intl'
import { Map } from 'immutable'

type Props = {
  commonSearchTerms: Map<string, number>
} & RouteComponentProps

export class CommonSearchTerms extends Component<Props> {
  linkParser = (tag: string) => {
    const queryString = qs.stringify({
      q: tag
    })

    return `${paths.search}?${queryString}`
  }

  render() {
    const { commonSearchTerms } = this.props

    return (
      <div>
        <InsightTitle>
          <FormattedMessage
            id='CommonSearchTerms.CommonSearchTerms'
            defaultMessage='Common Search Terms'
          />
        </InsightTitle>
        <InsightPaper>
          {commonSearchTerms && commonSearchTerms.size > 0 && (
            <div className='tc pv3'>
              <TagCloud
                colors={['#025F5F', '#005A8F', '#062B48', '#A7112A', '#555555']}
                tags={commonSearchTerms.toJSON()}
                linkParser={this.linkParser}
              />
            </div>
          )}
        </InsightPaper>
      </div>
    )
  }
}

export default withRouter(CommonSearchTerms)
