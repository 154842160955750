import React, { Component } from 'react'
import AppLogoContainer from 'shared/containers/AppLogoContainer'

type Props = {
  white?: boolean
  small?: boolean
  clientOf?: string
}

class TealbookLogoWrapper extends Component<Props> {
  render() {
    const { children, small, ...rest } = this.props
    return (
      <div className={`mw6 center ${small ? 'ph3' : 'ph4'} cf`}>
        <div className={`${small ? 'mw4 mv3' : 'mw5 mv4'} center`}>
          <AppLogoContainer className='w-100' {...rest} />
        </div>
        {children}
      </div>
    )
  }
}

export default TealbookLogoWrapper
