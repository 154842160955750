import React, { Component } from 'react'
import classNames from 'classnames'

type Props = {
  className: string
  label: string
  style?: { backgroundColor: string }
}
class CircleBadge extends Component<Props> {
  render() {
    const { className, label, children, ...rest } = this.props

    return (
      <div className='flex flex-column items-center'>
        <div className={classNames('white br-100 dt tc', className)} {...rest}>
          <span className='dtc v-mid'>{children}</span>
        </div>
        {label && (
          <span className='lh-copy fw4 mid-gray ma0 mt1 f8'>{label}</span>
        )}
      </div>
    )
  }
}

export default CircleBadge
