import React, { useState, useEffect, ReactElement } from 'react'
import { RecordOf, List } from 'immutable'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Divider from 'shared/components/Divider'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dialog, DialogContent } from '@material-ui/core'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import { SpendLineItemType } from './ReviewSpendItems'
import OrgLogo from 'shared/components/OrgLogo'
import { useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import Warning from 'shared/components/Warning'
import Loading from 'shared/components/Loading'
import ExternalLink from 'shared/components/ExternalLink'
import { messages } from 'shared/components/WebsiteWarning'
import MatchesSection from './MatchesSection'
import SearchForm from './SearchForm'
import { useDispatch } from 'react-redux'
import { closeRemapDialog } from 'shared/actions'

type Props = {
  spendLineItem?: RecordOf<SpendLineItemType>
  onRemap: (newMapping) => void
}

const RemapSpendItemDialog = (props: Props) => {
  const { spendLineItem, onRemap } = props
  const intl = useIntl()
  const dispatch = useDispatch()

  const [selectedOption, setSelectedOption] = useState<string | List<string>>(
    ''
  )
  const [touch, setTouch] = useState<boolean>(false)
  const [display, setDisplay] = useState<'matches' | 'form'>('matches')

  const remapError = useSelector(
    (state: RootState) => state.getIn(['admin', 'loader', 'remapError']) || ''
  )
  const isRemapping = useSelector(
    (state: RootState) =>
      state.getIn(['admin', 'loader', 'isRemapping']) || false
  )

  const isOpen = useSelector((state: RootState) =>
    state.getIn(['admin', 'loader', 'isRemapDialogOpen'])
  )

  useEffect(() => {
    if (spendLineItem) {
      if (
        spendLineItem.get('status') !== 'found' &&
        !spendLineItem.getIn(['matches', 0])
      ) {
        setDisplay('form')
      } else {
        setDisplay('matches')
      }

      setSelectedOption(spendLineItem.getIn(['domains']))
    }
  }, [spendLineItem])

  const handleDisplayChange = (display: 'matches' | 'form') => {
    setDisplay(display)
  }

  const handleCloseDialog = () => {
    dispatch(closeRemapDialog())
  }

  const renderHeaderRow = (
    label: string | ReactElement<FormattedMessage>,
    value: string | number
  ) => {
    return !!value ? (
      <>
        <div className='dib w-30'>
          <Text>{label}:</Text>
        </div>
        <div className='dib w-70'>
          <Text>{value}</Text>
        </div>
      </>
    ) : null
  }

  return spendLineItem ? (
    <Dialog open={isOpen} onClose={handleCloseDialog} fullWidth>
      <DialogTitle>
        <FormattedMessage
          id='RemapSpendItemDialog.TitleRemap'
          defaultMessage='Remap a Spend Company'
        />
      </DialogTitle>
      <DialogContent>
        {renderHeaderRow(
          <FormattedMessage
            id='RemapSpendItemDialog.SupplierId'
            defaultMessage='Supplier ID'
          />,
          spendLineItem.getIn(['header', 'internalSupplierId', 0])
        )}
        {renderHeaderRow(
          <FormattedMessage
            id='RemapSpendItemDialog.Name'
            defaultMessage='Name'
          />,
          spendLineItem.getIn(['header', 'name'])
        )}
        {renderHeaderRow(
          <FormattedMessage
            id='RemapSpendItemDialog.Domains'
            defaultMessage='Domain'
          />,
          spendLineItem.getIn(['header', 'domains', 0])
        )}
        {renderHeaderRow(
          <FormattedMessage
            id='RemapSpendItemDialog.Address'
            defaultMessage='Address'
          />,
          spendLineItem.getIn(['header', 'address'])
        )}
        {renderHeaderRow(
          <FormattedMessage
            id='RemapSpendItemDialog.Phone'
            defaultMessage='Phone'
          />,
          spendLineItem.getIn(['header', 'phone'])
        )}
        {renderHeaderRow(
          <FormattedMessage
            id='RemapSpendItemDialog.Email'
            defaultMessage='Email'
          />,
          spendLineItem.getIn(['header', 'email'])
        )}
        <Divider className='mv2' />
        {isRemapping ? (
          <div className='tc pt2'>
            <Text>
              <FormattedMessage
                id='RemapSpendItemDialog.Remapping'
                defaultMessage='Remapping...'
              />
            </Text>
            <Loading />
          </div>
        ) : (
          <>
            {display === 'matches' && (
              <>
                {spendLineItem.get('status') === 'found' && (
                  <>
                    <div className='db pv2 mb1 f7 fw6'>
                      <FormattedMessage
                        id='RemapSpendItemDialog.CurrentMappedTo'
                        defaultMessage='Currently Mapped to Company'
                      />
                    </div>
                    <div className='flex items-center'>
                      <div className='w2 h2 mr2 flex items-center'>
                        <OrgLogo url={spendLineItem.get('logoUrl')} />
                      </div>
                      <div className='flex-auto f7'>
                        <div className='fw6'>{spendLineItem.get('name')}</div>
                        {spendLineItem
                          .get('domains')
                          ?.map((domain: string, index: number) => (
                            <span key={domain}>
                              {index > 0 && ', '}
                              <ExternalLink href={domain}>
                                {domain}
                              </ExternalLink>
                            </span>
                          ))}
                      </div>
                    </div>
                  </>
                )}
                {!!spendLineItem.getIn(['matches', 0]) && (
                  <MatchesSection
                    matches={spendLineItem.get('matches')}
                    value={selectedOption}
                    onChange={e => setSelectedOption(e.currentTarget.value)}
                  />
                )}
                <Button
                  className='mt3'
                  size='small'
                  secondary
                  autoSize
                  onClick={() => handleDisplayChange('form')}
                >
                  <FormattedMessage
                    id='NoneOfTheAbove'
                    defaultMessage='None of the above'
                  />
                </Button>
              </>
            )}

            {display === 'form' && (
              <SearchForm
                companyName={spendLineItem.getIn(['header', 'name'])}
                companyWebsite={spendLineItem.getIn(['header', 'domains', 0])}
                contactEmail={spendLineItem.getIn(['header', 'email'])}
                onChange={value => setSelectedOption(value)}
              />
            )}

            {remapError && !touch && (
              <div className='mt3'>
                <Warning
                  message={
                    messages[remapError]
                      ? intl.formatMessage(messages[remapError])
                      : remapError
                  }
                />
              </div>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            typeof selectedOption !== 'string' || !selectedOption || isRemapping
          }
          onClick={() => {
            const orgUnitId = (selectedOption as string).startsWith('OrgUnit-')
              ? selectedOption
              : undefined
            const domain = !(selectedOption as string).startsWith('OrgUnit-')
              ? selectedOption
              : undefined

            setTouch(false)
            onRemap({
              stagingRowId: spendLineItem.get('id'),
              domain,
              orgUnitId
            })
          }}
        >
          <FormattedMessage
            id='RemapSpendItemDialog.Remap'
            defaultMessage='Remap'
          />
        </Button>
        <Button disabled={isRemapping} onClick={handleCloseDialog} secondary>
          <FormattedMessage id='CancelButton' />
        </Button>
      </DialogActions>
    </Dialog>
  ) : null
}

export default RemapSpendItemDialog
