import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserCommentContainer from 'shared/containers/UserCommentContainer'
import { loadComments, removeComment } from 'shared/actions'
import messagesSelectors from 'shared/selectors/messagesSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import paths from 'buyer/routes/paths'
import parsePath from 'shared/utils/parsePath'
import PageSection from 'shared/components/PageSection'
import Button from 'shared/components/Button'
import createEditForm from 'shared/utils/createEditForm'
import { UPDATE_COMMENT } from 'shared/actionTypes'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf } from 'immutable'

type Props = {
  comments: List<
    RecordOf<{
      id: string
      createdBy: string
      createdDate: string
      isPrivate: boolean
      body: string
    }>
  >
  currentUserId: string
  loadComments: typeof loadComments
  removeComment: typeof removeComment
  enablePrivacy?: boolean
} & ContainerProps

type State = {
  showAll: boolean
}

export class CommentList extends Component<Props, State> {
  state: State = {
    showAll: false
  }

  formIndex = 0

  componentDidMount() {
    const { loadComments, kindId, kindName } = this.props

    loadComments({
      ancestorId: kindId,
      messageKind: kindName
    })
  }

  handleShowAll = () => {
    this.setState({ showAll: true })
  }

  render() {
    const {
      comments,
      kindId,
      kindName,
      currentUserId,
      removeComment,
      enablePrivacy
    } = this.props

    const { showAll } = this.state

    return (
      <PageSection title='Comments' noPadding>
        {comments && comments.size > 5 && !showAll && (
          <div className='pa2 bb b--black-10'>
            <Button
              variant='text'
              autoSize
              label={
                <FormattedMessage
                  id='CommentList.ViewComments'
                  defaultMessage='View {commentSizeValue} more comments'
                  values={{ commentSizeValue: comments.size - 5 }}
                />
              }
              onClick={this.handleShowAll}
            />
          </div>
        )}

        {comments &&
          comments.takeLast(showAll ? comments.size : 5).map(comment => {
            const EditableUserCommentContainer =
              currentUserId === comment.get('createdBy')
                ? createEditForm(
                    {
                      formName: `comments/updateComment/${this.formIndex++}`,
                      submitActionType: UPDATE_COMMENT,
                      stopEditModeOnSave: true,
                      onRemove: () =>
                        removeComment({
                          ancestorId: kindId,
                          messageId: comment.get('id'),
                          messageKind: kindName
                        })
                    },
                    UserCommentContainer
                  )
                : UserCommentContainer

            return (
              <EditableUserCommentContainer
                key={comment.get('id')}
                userId={comment.get('createdBy')}
                userProfileUrl={parsePath(paths.userprofile, {
                  userId: comment.get('createdBy')
                })}
                date={comment.get('createdDate')}
                comment={comment.get('body')}
                enablePublicPrivate={enablePrivacy}
                isPrivate={comment.get('isPrivate')}
                initialValues={{
                  ancestorId: kindId,
                  messageKind: kindName,
                  comment: comment.get('body'),
                  messageId: comment.get('id'),
                  isPrivate: comment.get('isPrivate')
                }}
              />
            )
          })}
      </PageSection>
    )
  }
}

type ContainerProps = {
  kindName: string
  kindId: string
}

export default connect(
  (state, props: ContainerProps) => ({
    comments: messagesSelectors.getAllByKindId(
      state,
      props.kindName,
      props.kindId
    ),
    currentUserId: sessionSelectors.getUserId(state)
  }),
  {
    loadComments,
    removeComment
  }
)(CommentList)
