import { select, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import jobsSelectors from '../selectors/jobsSelectors'
import parsePath from 'shared/utils/parsePath'
import paths from '../routes'
import { List, RecordOf } from 'immutable'
import { JobOrgUnit } from 'shared/models/Job'

function* navToNext(action) {
  const { jobId, rowNum, ignoreAnswered = false, answeredRows } = action.payload

  let ansRows: List<number>
  if (!answeredRows) {
    ansRows = yield select(jobsSelectors.getRowsAnswered)
  } else {
    ansRows = answeredRows
  }
  const jobRows: List<RecordOf<JobOrgUnit>> = yield select(
    jobsSelectors.getRows
  )

  const done: boolean = !!(answeredRows?.size >= jobRows?.size)

  let openRow: number = 0
  if (!done) {
    openRow = rowNum < jobRows?.size - 1 ? rowNum + 1 : 0 // next row to open
    while (!ignoreAnswered && ansRows?.includes(openRow)) {
      if (openRow === jobRows?.size - 1) {
        openRow = 0
      } else {
        openRow++
      }
    }
  }

  let url: string
  if (!done) {
    url = parsePath(paths.detail, { jobId, row: openRow })
  } else {
    url = paths.jobs
  }

  yield put(push(url))
}

export default navToNext
