import { call, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import camelCase from 'lodash.camelcase'
import cards from 'shared/utils/api/cards'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import Immutable, { fromJS } from 'immutable'
import { getMyCardBySupplierSaga } from '../getMyCardBySupplierSaga/getMyCardBySupplierSaga'

export function* removeTagFromSupplierSaga(action) {
  const {
    tagsFieldName,
    removeTag,
    supplierId,
    cardId
  } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  const removeTrimTag = removeTag.trim()
  const tagKey = camelCase(removeTrimTag)

  /* try to get my card from state */
  let card = yield select(cardsSelectors.getById, cardId)

  /* if no card or card is not a full object, try fetch it */
  if (!card || !card.has('supplier')) {
    const { myCard } = yield call(getMyCardBySupplierSaga, {
      payload: { supplierId }
    })
    card = fromJS(myCard)
  }

  /* no card, throw an error */
  if (
    !card ||
    card.size === 0 ||
    card.get('placeholderCard') ||
    card.get('id').endsWith('-1')
  ) {
    throw new Error('Try to remove a tag without a real card')
  }

  /* if tagsFieldName not defined, try to figure out */
  let offerings
  if (card && !tagsFieldName) {
    offerings = card.hasIn(['offerings', tagKey])
  }
  const fieldName =
    tagsFieldName || (offerings ? 'offerings' : 'differentiators')
  const updatedTags = card
    .deleteIn([fieldName, tagKey])
    .get(fieldName)
    .toList()
    .toJS()

  yield call(cards.update, card.get('id'), {
    [fieldName]: updatedTags
  })

  return yield {
    supplierId: supplierId,
    cardId: card.get('id'),
    tagsFieldName: fieldName,
    tagKey
  }
}

export default createApiRequestSaga(removeTagFromSupplierSaga)
