import React, { FunctionComponent } from 'react'
import { fromJS, List, Map, RecordOf } from 'immutable'
import Grid from '@material-ui/core/Grid'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
import OnboardingHeader from './OnboardingHeader'

type Props = {
  firstName: string
  buyers: List<
    RecordOf<{
      id: string
      name: string
      logo: Map<
        string,
        RecordOf<{
          fileName: string
          bucketName: string
        }>
      >
    }>
  >
  onContinue: () => void
}

const ShowcaseBuyers: FunctionComponent<Props> = props => {
  return (
    <div className='mw8 center pa3 cf pb5'>
      <OnboardingHeader
        title={
          <FormattedMessage
            id='ShowcaseBuyers.Title'
            defaultMessage='Connecting you to Opportunities'
          />
        }
        subtitle={
          <FormattedMessage
            id='ShowcaseBuyers.Subtitle'
            defaultMessage='Great to meet you, {firstName}. Here are some of the organizations using TealBook to learn about suppliers and connect to companies like yours.'
            values={{ firstName: props.firstName }}
          />
        }
      />
      <div className='bg-white ba b--black-10 pv4'>
        {props.buyers.size > 0 ? (
          <Grid container justify='center' spacing={3}>
            {props.buyers
              .filter(buyer => buyer.get('logo', fromJS({})).size > 0)
              .map(buyer => (
                <Grid key={buyer.get('id')} item>
                  <div className={'w4 h4 flex items-center'}>
                    <img
                      className='v-mid'
                      alt={buyer.get('name')}
                      src={fileToUrl(buyer.get('logo'))}
                    />
                  </div>
                </Grid>
              ))}
          </Grid>
        ) : (
          <Loading className='tc' />
        )}
      </div>
      <div className='mt4 tc'>
        <Button autoSize onClick={props.onContinue} size='large'>
          <FormattedMessage
            id='ShowcaseBuyers.GetStarted'
            defaultMessage='Get Started'
          />
        </Button>
      </div>
    </div>
  )
}

export default ShowcaseBuyers
