import React from 'react'
import { Route, Switch } from 'react-router-dom'
import paths from './paths'
import MyVetsContainer from '../containers/MyVetsContainer'
import VetHeaderContainer from '../containers/VetHeaderContainer'
import VetDetailsPageContainer from '../containers/VetDetailsPageContainer'
import VetTermsPageContainer from '../containers/VetTermsPageContainer'
import VetResponsePageContainer from '../containers/VetResponsePageContainer'
import VetOutcomePageContainer from '../containers/VetOutcomePageContainer'
import VetQandAPage from '../components/VetQandAPage'

const createRoutes = () => (
  <>
    <Route path={paths.basePath} exact component={MyVetsContainer} />
    <Route path={paths.vet} component={VetHeaderContainer} />
    <Switch>
      <Route path={paths.vetDetails} component={VetDetailsPageContainer} />
      <Route path={paths.vetQA} component={VetQandAPage} />
      <Route path={paths.vetTerms} component={VetTermsPageContainer} />
      <Route path={paths.vetResponse} component={VetResponsePageContainer} />
      <Route path={paths.vetOutcome} component={VetOutcomePageContainer} />
    </Switch>
  </>
)

export default createRoutes
