import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import {
  openInviteSupplierDialog,
  closeInviteSupplierDialog
} from '../../actions'
import PageSideSection from 'shared/components/PageSideSection'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import InviteSupplierFormContainer from '../../containers/InviteSupplierFormContainer'
import paths from '../../../routes/paths'
import { FormattedMessage } from 'react-intl'
import Rootstate from 'shared/models/RootState'
import useSmallScreen from 'shared/utils/useSmallScreen'

type Props = {
  buttonOnly?: boolean
  openDialog: boolean
  closeInviteSupplierDialog: () => void
  openInviteSupplierDialog: () => void
}

const InviteSupplier = (props: Props) => {
  const history = useHistory()
  const isSmallScreen = useSmallScreen()
  const {
    buttonOnly,
    openDialog,
    openInviteSupplierDialog,
    closeInviteSupplierDialog
  } = props

  const handleClickMatchingOrg = useCallback(
    (orgUnitId: string) => {
      closeInviteSupplierDialog()
      history.push(paths.supplierProfile.replace(':supplierId', orgUnitId))
    },
    [closeInviteSupplierDialog, history]
  )

  return (
    <>
      {buttonOnly ? (
        <Button
          label={
            <FormattedMessage
              id='InviteSupplier.InviteASupplierToTealbook'
              defaultMessage='Invite a supplier to TealBook'
            />
          }
          autoSize
          className='mv3'
          onClick={openInviteSupplierDialog}
        />
      ) : (
        <PageSideSection
          title={
            <FormattedMessage
              id='InviteSupplier.InviteASupplier'
              defaultMessage='Invite a supplier'
            />
          }
        >
          <Text>
            <FormattedMessage
              id='InviteSupplier.CanNotSeemToFindASupplier'
              defaultMessage="Can't seem to find a supplier or want to invite a supplier to TealBook?"
            />
          </Text>
          <Button
            label={
              <FormattedMessage
                id='InviteSupplier.InviteASupplierToTealbook'
                defaultMessage='Invite a supplier to TealBook'
              />
            }
            className='mt3'
            onClick={openInviteSupplierDialog}
          />
        </PageSideSection>
      )}
      <Dialog
        open={openDialog}
        onClose={closeInviteSupplierDialog}
        fullWidth
        disableBackdropClick
        fullScreen={isSmallScreen}
      >
        <DialogTitle onClose={closeInviteSupplierDialog}>
          <FormattedMessage
            id='InviteSupplier.InviteSupplierToTealbook'
            defaultMessage='Invite supplier to TealBook'
          />
        </DialogTitle>
        <DialogContent className='mt3'>
          <InviteSupplierFormContainer
            onCancel={closeInviteSupplierDialog}
            onClickMatchingOrg={handleClickMatchingOrg}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default connect(
  (state: Rootstate) => ({
    openDialog: state.getIn([
      'buyer',
      'inviteSupplier',
      'openInviteSupplierDialog'
    ])
  }),
  {
    openInviteSupplierDialog,
    closeInviteSupplierDialog
  }
)(InviteSupplier)
