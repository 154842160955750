import React from 'react'
import {
  useIntl,
  defineMessages,
  FormattedMessage,
  FormattedDate
} from 'react-intl'

type Props = {
  outreachType: string
  outreachStatus: string
  contractExpiryDate: Date
  certificationAgency: string
  certificationSubCategory: string
  actionRequiredEcovadis?: boolean
  archivedTask?: boolean
}
const NotesByCell = ({
  outreachType,
  outreachStatus,
  contractExpiryDate,
  certificationAgency,
  certificationSubCategory,
  actionRequiredEcovadis,
  archivedTask
}: Props) => {
  const intl = useIntl()
  const messages = defineMessages({
    ContractRequestClosed: {
      id: 'NotesByCell.ContractRequestClosed',
      defaultMessage: 'Contract Closed'
    },
    ContractRequestRenewed: {
      id: 'NotesByCell.ContractRequestRenewed',
      defaultMessage: 'Contract Renewed'
    },
    selfCertifySent: {
      id: 'NotesByCell.selfCertifySent',
      defaultMessage: `Self Certify Requested`
    },
    selfCertifyResent: {
      id: 'NotesByCell.selfCertifyResent',
      defaultMessage: `Self Certify Request Resent`
    },
    selfCertifyCancelled: {
      id: 'NotesByCell.selfCertifyCancelled',
      defaultMessage: `Self Certify Request Cancelled`
    },
    supplierInvitedRatedEcoVadis: {
      id: 'NotesByCell.InvitedRatedEcoVadis',
      defaultMessage: 'The Supplier is invited to be rated by EcoVadis'
    },
    actionRequiredEcovadis: {
      id: 'NotesByCell.EcoVadisActionRequired',
      defaultMessage:
        'Action Required: Please contact EcoVadis for more information.'
    },
    esgSurveySent: {
      id: 'NotesByCell.esgSurveySent',
      defaultMessage: `Sustainability Survey Requested`
    },
    esgSurveyResent: {
      id: 'NotesByCell.esgSurveyResent',
      defaultMessage: `Sustainability Survey Request Resent`
    },
    esgSurveyCancelled: {
      id: 'NotesByCell.esgSurveyCancelled',
      defaultMessage: `Sustainability Survey Request Cancelled`
    }
  })
  const status = outreachStatus
  let notes
  if (
    outreachType === 'ExpiringContract' &&
    (status === 'Created' || status === 'WaitingForClient')
  ) {
    notes = (
      <>
        <FormattedMessage
          id='NotesByCell.ContractRequestCreated'
          defaultMessage='Contract Expiring on '
        />{' '}
        <FormattedDate
          value={contractExpiryDate}
          year='numeric'
          month='long'
          day='numeric'
          weekday='long'
        />
      </>
    )
  } else if (outreachType === 'ExpiringContract' && status === 'Cancelled') {
    notes = intl.formatMessage(messages.ContractRequestClosed)
  } else if (outreachType === 'ExpiringContract' && status === 'Completed') {
    notes = intl.formatMessage(messages.ContractRequestRenewed)
  } else if (outreachType === 'SelfCertifyRequest' && status === 'Cancelled') {
    notes = notes = intl.formatMessage(messages.selfCertifyCancelled)
  } else if (
    outreachType === 'SelfCertifyRequest' &&
    status === 'WaitingForClient'
  ) {
    notes = notes = intl.formatMessage(messages.selfCertifyResent)
  } else if (outreachType === 'SelfCertifyRequest' && status === 'Completed') {
    let certAgency = certificationAgency
    let subCategory = certificationSubCategory.toUpperCase()
    notes = ` ${certAgency} for ${subCategory} is complete`
  } else if (outreachType === 'SelfCertifyRequest') {
    notes = intl.formatMessage(messages.selfCertifySent)
  } else if (outreachType === 'ESGSurveyRequest' && status === 'Cancelled') {
    notes = notes = intl.formatMessage(messages.esgSurveyCancelled)
  } else if (
    outreachType === 'ESGSurveyRequest' &&
    status === 'WaitingForClient'
  ) {
    notes = notes = intl.formatMessage(messages.esgSurveyResent)
  } else if (outreachType === 'ESGSurveyRequest' && status === 'InProgress') {
    notes = ` Sustainability Survey Started`
  } else if (outreachType === 'ESGSurveyRequest' && status === 'Completed') {
    notes = ` Sustainability Survey is complete`
  } else if (outreachType === 'ESGSurveyRequest') {
    notes = intl.formatMessage(messages.esgSurveySent)
  } else if (outreachType === 'EcoVadisAssessment') {
    if (actionRequiredEcovadis) {
      notes = intl.formatMessage(messages.actionRequiredEcovadis)
    }
    if (archivedTask) {
      notes = status
    } else {
      notes = intl.formatMessage(messages.supplierInvitedRatedEcoVadis)
    }
  }
  return <div className={actionRequiredEcovadis ? 'red' : ''}>{notes}</div>
}

export default NotesByCell
