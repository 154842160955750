export const LOAD_VET = 'supplier/LOAD_VET'
export const LOAD_VET_SUCCESS = 'supplier/LOAD_VET_SUCCESS'
export const LOAD_VET_FAILURE = 'supplier/LOAD_VET_FAILURE'

export const OPEN_VET = 'supplier/OPEN_VET'
export const DECLINE_VET = 'supplier/DECLINE_VET'
export const ACCEPT_VET = 'supplier/ACCEPT_VET'
export const UPDATE_VET_PROPOSAL = 'supplier/UPDATE_VET_PROPOSAL'
export const SHOW_INTEREST_VET = 'supplier/SHOW_INTEREST_VET'
export const ACCEPT_VET_CRITERIA = 'supplier/ACCEPT_VET_CRITERIA'
export const UPLOAD_VET_PRESENTATION = 'supplier/UPLOAD_VET_PRESENTATION'
export const REMOVE_VET_PRESENTATION = 'supplier/REMOVE_VET_PRESENTATION'

export const ADD_VET_CONTACT = 'supplier/ADD_VET_CONTACT'
export const REMOVE_VET_CONTACT = 'supplier/REMOVE_VET_CONTACT'
export const ADD_VET_QUESTION = 'supplier/ADD_VET_QUESTION'
export const LOAD_VET_QUESTIONS = 'supplier/LOAD_VET_QUESTIONS'
export const UPDATE_VET_QUESTION = 'supplier/UPDATE_VET_QUESTION'
export const REMOVE_VET_QUESTION = 'supplier/REMOVE_VET_QUESTION'
