import React, { Component, SyntheticEvent } from 'react'
import Popover from '@material-ui/core/Popover'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'

const styles = {
  wrapper: 'pa3',
  faqHeader: 'f5 fw6 v-mid ma0 mv2',
  faqText: 'mb2',
  spacing: 'mr2'
}

type Props = {
  open: boolean
  anchorEl: HTMLElement | null
  onClose: (event: SyntheticEvent<HTMLElement>) => void
}

export class CollaborationFaq extends Component<Props> {
  render() {
    return (
      <Popover
        {...this.props}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div className={styles.wrapper} style={{ maxWidth: 300 }}>
          <h5 className={styles.faqHeader}>
            <FormattedMessage
              id='CollaborationFaq.FaqHeader1'
              defaultMessage='Sustainability Contact'
            />
          </h5>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='CollaborationFaq.FaqParagraph1'
              defaultMessage='Are you the right person to contact about sustainability at your organization?'
            />
          </Text>
          <Text className={styles.faqText}>
            <FormattedMessage
              id='CollaborationFaq.FaqParagraph2'
              defaultMessage='You can invite another member of your organization to collaborate by clicking the add collaborators button at any point throughout the survey.'
            />
          </Text>
        </div>
      </Popover>
    )
  }
}

export default CollaborationFaq
