import parseUserNote from './parseUserNote'
import parseRelationshipAttachment from './parseRelationshipAttachment'

function parseRelationship(relationship) {
  const {
    attachments,
    notes,
    customAttributes,
    attributes,
    expanded,
    spend,
    peerTags,
    ...rest
  } = relationship

  let spendDetails
  if (expanded) {
    spendDetails = expanded['RelationshipSpendLineItems']
  }

  let quarterly, annually
  if (spendDetails) {
    let tally
    tally = spendDetails
      .sort((itemA, itemB) => {
        return new Date(itemA.spendDate) > new Date(itemB.spendDate) ? 1 : -1
      })
      .reduce((result, entry) => {
        const spendDate = new Date(entry.spendDate)
        const year = spendDate.getUTCFullYear()
        const quarter = Math.floor((spendDate.getUTCMonth() + 1) / 4) + 1

        if (result[year]) {
          result[year]['total'] += entry.amount
        } else {
          result[year] = {
            total: entry.amount,
            quarter: {
              [year]: null
            }
          }
        }
        const category = quarter === 1 ? year : `${year}-Q${quarter}`
        if (result[year]['quarter'][category]) {
          result[year]['quarter'][category] += entry.amount
        } else {
          result[year]['quarter'][category] = entry.amount
        }

        return result
      }, {})

    annually = Object.keys(tally).map(year => ({
      category: year,
      amount: tally[year]['total']
    }))

    quarterly = []
    Object.keys(tally).forEach(year => {
      Object.keys(tally[year]['quarter']).forEach(quarter => {
        quarterly.push({
          category: quarter,
          amount: tally[year]['quarter'][quarter]
        })
      })
    })
    quarterly = quarterly.sort((spendA, spendB) => {
      if (spendA.category > spendB.category) {
        return 1
      } else {
        return -1
      }
    })
  }

  const customAttributesList =
    customAttributes &&
    Object.values(customAttributes).sort(
      (attrA, attrB) => attrA.order - attrB.order
    )

  const parseAttributes =
    attributes &&
    Object.keys(attributes).reduce((result, key) => {
      if (attributes[key]) {
        return Object.assign({}, result, { [key]: attributes[key] })
      } else {
        return result
      }
    }, {})

  const cleanPeerTags =
    peerTags &&
    Object.entries(peerTags)
      // filter out empty tags
      .filter(([key, value]) => !!value.name)
      .reduce((result, [key, value]) => {
        result[key] = value
        return result
      }, {})

  return {
    attachments: attachments && attachments.map(parseRelationshipAttachment),
    notes: notes && notes.map(parseUserNote),
    customAttributes: customAttributesList,
    attributes: parseAttributes,
    spend:
      spend &&
      Object.assign({}, spend, {
        details: spendDetails,
        overview: { quarterly, annually }
      }),
    peerTags: cleanPeerTags,
    ...rest
  }
}

export default parseRelationship
