import React, { Component, FormEvent } from 'react'
import SubTitleText from 'shared/components/SubTitleText'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import Select from 'shared/components/Select'
import { Field } from 'redux-form/immutable'
import { FormattedMessage } from 'react-intl'

const styles = {
  wrapper: 'mw6 center mt5',
  submitButton: 'mt4',
  warning: 'mt3'
}

type Props = {
  isClientApp: boolean
  handleSubmit: (
    handleSubmit: (values: any) => void
  ) => (event: FormEvent<HTMLFormElement>) => void
  onSubmit: (values: any, { onBoarding: boolean }) => void
}

class UserOnboardingPage extends Component<Props> {
  handleOnSumbit = values => {
    const { onSubmit } = this.props
    onSubmit(values, { onBoarding: true })
  }

  render() {
    const { handleSubmit, isClientApp } = this.props

    return (
      <div className={styles.wrapper}>
        <form onSubmit={handleSubmit(this.handleOnSumbit)}>
          <SubTitleText>
            <FormattedMessage
              id='UserOnboardingPage.LetUsKnowYourName'
              defaultMessage='Let us know your name to make your experience much better'
            />
          </SubTitleText>

          <Label required htmlFor='firstName'>
            <FormattedMessage
              id='UserOnboardingPage.FirstName'
              defaultMessage='First Name'
            />
          </Label>
          <Field component={Input} id='firstName' name='firstName' required />

          <Label required htmlFor='lastName'>
            <FormattedMessage
              id='UserOnboardingPage.LastName'
              defaultMessage='Last Name'
            />
          </Label>
          <Field component={Input} id='lastName' name='lastName' required />

          <Label htmlFor='title'>
            <FormattedMessage
              id='UserOnboardingPage.CompanyName'
              defaultMessage='Company Position'
            />
          </Label>
          <Field
            component={Input}
            id='title'
            name='title'
            maxLength={150}
            placeholder='What is your role in the company? (eg. Account Manager)'
          />

          {isClientApp && (
            <div>
              <Label htmlFor='department'>
                <FormattedMessage
                  id='UserOnboardingPage.Department'
                  defaultMessage='Department'
                />
              </Label>
              <Field
                component={Input}
                id='department'
                name='department'
                placeholder='Which department do you work in? (eg. Sales)'
              />

              <Label htmlFor='officePhone'>
                <FormattedMessage
                  id='UserOnboardingPage.OfficeNumber'
                  defaultMessage='Office Number'
                />
              </Label>
              <Field
                component={Input}
                id='officePhone'
                name='officePhone'
                placeholder='Your office phone number'
              />
            </div>
          )}

          <Label htmlFor='foundTealbook' required>
            <FormattedMessage
              id='UserOnboardingPage.HowDidYouFindTealbook'
              defaultMessage='How did you find TealBook?'
            />
          </Label>
          <Field
            required
            component={Select}
            id='foundTealbook'
            name='foundTealbook'
          >
            <FormattedMessage
              id='UserOnboardingPage.selectAnOption'
              defaultMessage='Select an option'
            >
              {message => <option value=''>{message}</option>}
            </FormattedMessage>

            <FormattedMessage
              id='UserOnboardingPage.EmailInvitation'
              defaultMessage='Email invitation'
            >
              {message => <option value='Email invitation'>{message}</option>}
            </FormattedMessage>

            <FormattedMessage
              id='UserOnboardingPage.ClientRequest'
              defaultMessage='Client request'
            >
              {message => <option value='Client request'>{message}</option>}
            </FormattedMessage>

            <FormattedMessage
              id='UserOnboardingPage.FoundItMyself'
              defaultMessage='Found it myself'
            >
              {message => <option value='Found it myself'>{message}</option>}
            </FormattedMessage>

            <FormattedMessage
              id='UserOnboardingPage.ColleagueRecomendation'
              defaultMessage='Colleague recommendation'
            >
              {message => (
                <option value='Colleague recommendation'>{message}</option>
              )}
            </FormattedMessage>

            <FormattedMessage
              id='UserOnboardingPage.Other'
              defaultMessage='Other'
            >
              {message => <option value='Other'>{message}</option>}
            </FormattedMessage>
          </Field>

          <Button
            type='submit'
            label={
              <FormattedMessage
                id='UserOnboardingPage.Continue'
                defaultMessage='Continue'
              />
            }
            className={styles.submitButton}
          />
        </form>
      </div>
    )
  }
}

export default UserOnboardingPage
