import fileToUrl from './fileToUrl'
import parseFilters from './parseFilters'
import User from '../../models/User'

const parseUser = (user): User => {
  const {
    created,
    department,
    email,
    firstName,
    lastName,
    id,
    lastLogin,
    location,
    links,
    notificationSettings,
    roles,
    title,
    phones,
    parents,
    profilePicture,
    expanded,
    status,
    fixedFilters,
    defaultFilters,
    blocked,
    ...rest
  } = user

  const orgUnit =
    expanded && expanded.OrgUnit && expanded.OrgUnit[parents.OrgUnit]
  const isSupplier =
    (expanded && expanded.isSupplier) || (roles && roles.includes('supplier'))
  const isBuyer =
    (expanded && expanded.isBuyer) || (roles && roles.includes('buyer'))

  return {
    blocked,
    createdDate: created && new Date(created.date),
    department,
    email,
    firstName,
    lastName,
    id,
    lastLogin: lastLogin && new Date(lastLogin),
    location,
    roles,
    title,
    mobilePhone: phones && phones.mobile,
    officePhone: phones && phones.office,
    orgUnitId: parents && parents.OrgUnit,
    linkedInUrl: links && links.linkedInUrl,
    facebookUrl: links && links.facebookUrl,
    profilePictureUrl:
      fileToUrl(profilePicture) || (links && links.profilePictureUrl),
    orgUnitName: orgUnit && orgUnit.name,
    status,
    fixedFilters: parseFilters(fixedFilters),
    defaultFilters: parseFilters(defaultFilters),
    isSupplier,
    isBuyer,
    disableAllEmails:
      notificationSettings && notificationSettings.disableAllEmails,
    messageFrequency:
      notificationSettings &&
      notificationSettings.options &&
      notificationSettings.options.message &&
      notificationSettings.options.message.frequency,
    ...rest
  }
}

export default parseUser
