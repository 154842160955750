import React, { Component } from 'react'
import Loading from 'shared/components/Loading'
import Fab from '@material-ui/core/Fab'
import ContentAdd from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import { FormattedMessage } from 'react-intl'

const styles = {
  resultsCount: 'f6 f5-m f5-l fw4 gray mt4 mb3',
  addButton: 'fixed bottom-1 bottom-2-ns tr w-100 mw8 pr6-ns pr4'
}

type Props = {
  selectedUserEmail: string
  isCreatingOrg: boolean
  isSearching: boolean
  orgSearchResults: any
  createOrgForm: any
  startCreatingOrg: () => void
  stopCreatingOrg: () => void
  resultsCount: number
  canAddSupplier: boolean
}

class FindSupplier extends Component<Props> {
  render() {
    const {
      selectedUserEmail,
      isCreatingOrg,
      isSearching,
      resultsCount,
      startCreatingOrg,
      stopCreatingOrg,
      orgSearchResults,
      createOrgForm,
      canAddSupplier
    } = this.props

    const showAddButton = Number.isInteger(resultsCount)

    return (
      <div>
        {isSearching ? (
          <Loading />
        ) : resultsCount > 0 ? (
          <div>
            <h5 className={styles.resultsCount}>
              {resultsCount.toLocaleString()}{' '}
              <FormattedMessage
                id='FindSupplier.CompaniesWereFound'
                defaultMessage='companies were found.'
              />
            </h5>
            {orgSearchResults}
          </div>
        ) : Number.isInteger(resultsCount) ? (
          <h5 className={styles.resultsCount}>
            <FormattedMessage
              id='FindSupplier.NoComanyWasFound'
              defaultMessage='No company was found.'
            />
          </h5>
        ) : null}

        {canAddSupplier && showAddButton && (
          <div className={styles.addButton}>
            <Fab onClick={() => startCreatingOrg()} color='primary'>
              <ContentAdd color='inherit' />
            </Fab>
          </div>
        )}

        <Dialog
          open={isCreatingOrg}
          onClose={() => stopCreatingOrg()}
          fullWidth
        >
          <DialogTitle>
            <FormattedMessage
              id='FindSupplier.CreateANewSupplier'
              defaultMessage='Create a new Supplier and automatically connect it to {selectedUserEmail}'
              values={{ selectedUserEmail }}
            />
          </DialogTitle>
          <DialogContent className='mt3'>{createOrgForm}</DialogContent>
        </Dialog>
      </div>
    )
  }
}

export default FindSupplier
