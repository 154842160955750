import React, { useState, useMemo } from 'react'
import { fromJS } from 'immutable'
import LookupSuppliers from 'shared/components/LookupSuppliers'
import { useDebounce } from 'use-debounce'
import { FormattedMessage } from 'react-intl'

type DiverseSupplier = {
  id: string
  name: string
  spend: number
  allocation?: number
  logoUrl?: string
}

type Props = {
  onClickSuggestedSupplier: (supplier: DiverseSupplier) => void
  diverseSuppliers: DiverseSupplier[]
  readOnly?: boolean
}

const AddDiverseSupplierSpend = ({
  onClickSuggestedSupplier,
  diverseSuppliers,
  readOnly
}: Props) => {
  const [companyName, setCompanyName] = useState('')
  const [debouncedCompanyName] = useDebounce(companyName, 200)

  const visibleSuggestedSuppliers = useMemo(
    () =>
      fromJS(
        diverseSuppliers.filter(
          supplier =>
            debouncedCompanyName.toLowerCase() &&
            supplier.name.toLowerCase().includes(debouncedCompanyName)
        )
      ),
    [diverseSuppliers, debouncedCompanyName]
  )

  return (
    <FormattedMessage
      id='AddDiverseSupplierSpend.AddDiverseSupplier'
      defaultMessage='Add a supplier to allocate direct spend'
    >
      {placeholder => (
        <LookupSuppliers<DiverseSupplier>
          companyName={companyName}
          onChangeCompanyName={setCompanyName}
          placeholder={placeholder}
          onClose={() => setCompanyName('')}
          suggestedSuppliers={visibleSuggestedSuppliers}
          open={visibleSuggestedSuppliers.size > 0}
          readOnly={readOnly}
          onHandleClickSuggestedOrgUnit={orgUnit => {
            setCompanyName('')
            onClickSuggestedSupplier(orgUnit.toJS())
          }}
        />
      )}
    </FormattedMessage>
  )
}

export default AddDiverseSupplierSpend
