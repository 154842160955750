import React, { Component } from 'react'
import Button from 'shared/components/Button'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import RatingQuestionFields from '../../containers/RatingQuestionFields'
import { Field } from 'redux-form/immutable'
import PreferredQuestionField from '../PreferredQuestionField'
import TagsInput from 'shared/components/TagsInput2'
import { FormattedMessage } from 'react-intl'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth'

type Props = {
  onSubmit: () => void
  onClickCancelWritingReview: () => void
  enableRatings?: boolean
  enablePreferred?: boolean
  isPreferred?: boolean
} & WithWidthProps

class WriteReviewForm extends Component<Props> {
  render() {
    const {
      enableRatings,
      enablePreferred,
      isPreferred,
      onSubmit,
      onClickCancelWritingReview,
      width
    } = this.props

    const matchMobile = width && ['xs', 'sm'].includes(width)

    return (
      <form
        onSubmit={onSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <DialogContent>
          {enableRatings && <RatingQuestionFields noTitle />}

          {enablePreferred && <PreferredQuestionField />}

          {enablePreferred && isPreferred && (
            <Field
              name='ratingResponses.preferredCategories'
              addButtonLabel={
                <FormattedMessage
                  id='WriteReviewForm.WhichCategories'
                  defaultMessage='Which categories should they be liked for?'
                />
              }
              component={TagsInput}
            />
          )}

          <div className='mt3 mt0-ns'>
            <Label htmlFor='comment'>
              <FormattedMessage
                id='WriteReviewForm.LeaveAComment'
                defaultMessage='Leave a comment'
              />
            </Label>
          </div>
          <FormattedMessage
            id='WriteReviewForm.TellPeopleAboutYourExperience'
            defaultMessage='Tell people about your experience: describe how they work or their quality, recommendations for your colleagues?'
          >
            {message => (
              <Field
                component={TextArea}
                id='comment'
                name='ratingResponses.comment'
                maxLength={1000}
                placeholder={message as string}
              />
            )}
          </FormattedMessage>
        </DialogContent>
        <DialogActions className='fixed static-ns bottom-1'>
          <Button
            size={matchMobile ? 'large' : undefined}
            label={
              <FormattedMessage
                id='WriteReviewForm.Submit'
                defaultMessage='Submit'
              />
            }
            autoSize
            type='submit'
          />
          <Button
            size={matchMobile ? 'large' : undefined}
            label={<FormattedMessage id='CancelButton' />}
            autoSize
            onClick={onClickCancelWritingReview}
            secondary
          />
        </DialogActions>
      </form>
    )
  }
}

export default withWidth()(WriteReviewForm)
