import React, { useState, ChangeEvent, useEffect, FormEvent } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Label from 'shared/components/Label'
import Select from 'shared/components/Select'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/BorderColor'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import { PasswordRules } from '../PasswordRulesSetting/PasswordRulesSetting'
import partnerApi from 'shared/utils/api/partner'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import Rootstate from 'shared/models/RootState'
import { useSelector } from 'react-redux'
import isEmail from 'validator/lib/isEmail'
import Tooltip from 'shared/components/Tooltip'
import generatePassword from 'shared/utils/generatePassword'
import { useDispatch } from 'react-redux'
import { notify } from 'shared/actions'

type Provider = {
  id: string
  name: string
  sharedSecretRules: PasswordRules
}

const cleanPartner = ({ expanded, parents, siblings, ...partners }) => partners

const PartnersSetting = () => {
  const dispatch = useDispatch()
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnit = useSelector((state: Rootstate) =>
    orgsSelectors.getById(state, orgUnitId)
  )
  const [partnerId, setPartnerId] = useState<string>('')
  const [partnerMetadata, setPartnerMetadata] = useState<string | null>()
  const [email, setEmail] = useState<string>('')
  const [providers, setProviders] = useState<{ [key: string]: Provider }>({})
  const [partners, setPartners] = useState<Array<partnerApi.PartnerRecord>>([])
  const [editId, setEditId] = useState<string | undefined>(undefined)
  const [removeId, setRemoveId] = useState<string | undefined>(undefined)
  const [editContact, setEditContact] = useState<string>('')
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [regenerating, setRegenerating] = useState<string>('')

  useEffect(() => {
    partnerApi.getPartnerProviders().then((response?: Array<Provider>) => {
      const mappedProviders = response
        ? response.reduce((result, provider) => {
            result[provider.id] = provider
            return result
          }, {})
        : {}
      setProviders(mappedProviders)
    })
    partnerApi.getPartnersList(orgUnitId).then(response => {
      setPartners(response.map(cleanPartner))
    })
  }, [orgUnitId])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    const provider = providers[partnerId]

    if (provider) {
      const sharedSecret = generatePassword(provider.sharedSecretRules)
      setIsSaving(true)
      partnerApi
        .create({
          partner: partnerId,
          partnerContact: email,
          client: orgUnitId,
          sharedSecret
        })
        .then(response => {
          const partner = cleanPartner(response) as partnerApi.PartnerRecord
          setPartners(() => [...partners, { ...partner, sharedSecret }])
          setEmail('')
          setIsSaving(false)
        })
        .catch(e => {
          dispatch(
            notify({
              message: e.message
            })
          )
          setIsSaving(false)
        })
    }
  }

  const regenerateSecret = (id: string) => {
    const index = partners.findIndex(p => p.id === id)
    const partner = partners[index]

    if (partner) {
      setRegenerating(id)
      const newSecret = generatePassword(partner.sharedSecretRules)
      partnerApi
        .updatePartner({
          partnerId: id,
          sharedSecret: newSecret
        })
        .then(response => {
          partners[index].sharedSecret = newSecret
          setPartners([...partners])
          setRegenerating('')
        })
        .catch(e => {
          dispatch(
            notify({
              message: e.message
            })
          )
          setRegenerating('')
        })
    }
  }

  const copyToClipboard = text => {
    const listener = function(e) {
      e.preventDefault()
      e.clipboardData.setData('text/plain', text)
    }
    document.addEventListener('copy', listener)
    document.execCommand('copy')
    document.removeEventListener('copy', listener)
  }

  const copySharedSecret = secret => {
    copyToClipboard(secret)
    dispatch(
      notify({
        message: 'Integration Identity node copied'
      })
    )
  }

  const handleEditClick = (id: string) => {
    const partner = partners.find(p => p.id === id)

    if (partner) {
      setEditContact(partner.partnerContact)
      setEditId(id)
    }
  }

  const confirmRemove = () => {
    if (removeId) {
      partnerApi
        .removePartner(removeId)
        .then(response => {
          setPartners(() => {
            const index = partners.findIndex(p => p.id === removeId)
            return partners.filter((p, i) => i !== index)
          })
        })
        .finally(() => {
          setRemoveId(undefined)
        })
    }
  }

  const handleRemoveClick = (id: string) => {
    if (id) {
      setRemoveId(id)
    }
  }

  const handleUpdateClick = () => {
    const index = partners.findIndex(p => p.id === editId)

    if (index !== -1) {
      setIsSaving(true)
      partnerApi
        .updatePartner({
          partnerId: editId,
          partnerContact: editContact
        })
        .then(response => {
          partners[index].partnerContact = editContact
          setPartners([...partners])
          setEditId(undefined)
        })
        .finally(() => {
          setIsSaving(false)
        })
    }
  }

  const handleCancelClick = () => {
    setEditId(undefined)
  }

  const handleUnblockClick = (id: string) => {
    const index = partners.findIndex(p => p.id === id)

    if (index !== -1) {
      partnerApi.unblockPartner(id).then(response => {
        const { status, secretLocked } = response
        setPartners(() =>
          partners.map((p, i) =>
            i !== index ? p : { ...p, status, secretLocked }
          )
        )
      })
    }
  }

  const getPartnerMetadata = (partnerDomain, sharedSecret) => {
    const clientDomain = orgUnit.get('domains').toJS()
    let metadata = {
      identity: {
        from: {
          domain: clientDomain[0]
        },
        to: {
          domain: 'tealbook.com'
        },
        sender: {
          domain: partnerDomain,
          sharedSecret: sharedSecret
        }
      }
    }
    return JSON.stringify(metadata, null, 2)
  }

  return (
    <>
      <Label>Partners Shared Secret</Label>
      <form onSubmit={handleSubmit}>
        <div className='flex items-top justify-between mt1'>
          <Select
            value={partnerId}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              setPartnerId(e.currentTarget.value)
            }
          >
            <option value=''>Select a integration partner</option>
            {Object.keys(providers).map(key => (
              <option key={providers[key].id} value={providers[key].id}>
                {providers[key].name}
              </option>
            ))}
          </Select>
          <div className='flex-auto flex items-top justify-between'>
            <div className='flex-auto ml2'>
              <Input
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.currentTarget.value)
                }
                placeholder='Contact Email'
                type='email'
              />
            </div>
            <Button
              autoSize
              type='submit'
              className='ml2'
              disabled={
                isSaving ||
                !partnerId ||
                !isEmail(email) ||
                !!partners.find(p => p.partner === partnerId)
              }
              aria-label='add'
            >
              Add
            </Button>
          </div>
        </div>
      </form>
      <div className='mt2 br1 ba b--black-10'>
        <Table stickyHeader size='small'>
          <TableHead className='bg-light-gray pv3'>
            <TableRow>
              <TableCell style={{ width: 200 }}>Partner</TableCell>
              <TableCell>Shared Secret</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Status</TableCell>
              <TableCell style={{ width: 100 }}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partners.map(partner => (
              <TableRow key={partner.id}>
                <TableCell align='left'>
                  <Text>{partner.partnerName}</Text>
                </TableCell>
                <TableCell align='left' width={240}>
                  <Text>{partner.sharedSecret}</Text>
                  {!editId && !partner.secretLocked && (
                    <>
                      <Button
                        disabled={regenerating === partner.id}
                        autoSize
                        size='small'
                        onClick={() => regenerateSecret(partner.id)}
                        aria-label='regenerate'
                      >
                        Regenerate
                      </Button>
                      &nbsp;
                      <Button
                        aria-label='identity'
                        className='mr2'
                        size='small'
                        onClick={() => {
                          setPartnerMetadata(
                            getPartnerMetadata(
                              partner.partnerDomain,
                              partner.sharedSecret
                            )
                          )
                        }}
                        autoSize
                        secondary
                      >
                        Identity
                      </Button>
                    </>
                  )}
                </TableCell>
                <TableCell align='left'>
                  {editId === partner.id ? (
                    <Input
                      value={editContact}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        setEditContact(e.currentTarget.value)
                      }
                      ariaLabel='edit contact'
                    />
                  ) : (
                    <Text>{partner.partnerContact}</Text>
                  )}
                </TableCell>
                <TableCell align='left'>
                  {partner.secretLocked ? (
                    <Tooltip title={partner.status}>
                      <Button
                        autoSize
                        size='small'
                        onClick={() => handleUnblockClick(partner.id)}
                        aria-label='unblock'
                      >
                        Unblock
                      </Button>
                    </Tooltip>
                  ) : (
                    <Text>{partner.status}</Text>
                  )}
                </TableCell>
                <TableCell align='right'>
                  {editId === undefined && !partner.secretLocked ? (
                    <>
                      <span
                        className='pointer dim mr2'
                        role='button'
                        aria-label='edit'
                        onClick={() => handleEditClick(partner.id)}
                      >
                        <EditIcon fontSize='small' />
                      </span>
                      <span
                        className='pointer dim'
                        role='button'
                        aria-label='remove'
                        onClick={() => handleRemoveClick(partner.id)}
                      >
                        <CloseIcon fontSize='small' />
                      </span>
                    </>
                  ) : editId === partner.id ? (
                    <>
                      <Button
                        size='small'
                        disabled={!isEmail(editContact) || isSaving}
                        onClick={handleUpdateClick}
                        aria-label='update'
                      >
                        Update
                      </Button>
                      <Button
                        secondary
                        size='small'
                        onClick={handleCancelClick}
                        className='mt2'
                        disabled={isSaving}
                        aria-label='cancel'
                      >
                        Cancel
                      </Button>
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Dialog open={!!partnerMetadata} onClose={() => setPartnerMetadata(null)}>
        <DialogTitle>Partner Metadata</DialogTitle>
        <DialogContent>
          <pre style={{ wordBreak: 'break-word' }}>{partnerMetadata}</pre>
        </DialogContent>
        <DialogActions>
          <Button
            label='Copy'
            onClick={() => {
              copySharedSecret(partnerMetadata)
            }}
          />
          <Button
            label='Close'
            onClick={() => setPartnerMetadata(null)}
            secondary
          />
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={removeId !== undefined}
        onClose={() => setRemoveId(undefined)}
        onConfirm={confirmRemove}
      >
        <Text>Are you sure you want to remove this?</Text>
      </ConfirmationDialog>
    </>
  )
}

export default PartnersSetting
