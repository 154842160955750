import { Record, fromJS } from 'immutable'
import { requestSuccess, requestFailure, LOGOUT } from 'shared/actionTypes'
import * as actionTypes from '../../actionTypes'

const ProfileRecord = Record({
  data: undefined,
  socialFeed: [],
  isFetching: false,
  fetchErrorMessage: undefined
})

const profileReducer = (state = new ProfileRecord(), action) => {
  switch (action.type) {
    case actionTypes.LOAD_SUPPLIER_PROFILE:
      return state.merge({
        isFetching: true,
        fetchErrorMessage: undefined
      })

    case requestSuccess(actionTypes.LOAD_SUPPLIER_PROFILE):
      return new ProfileRecord({
        data: fromJS(action.payload.supplier)
      })

    case requestSuccess(actionTypes.UPDATE_SUPPLIER_STATUS):
      return state.setIn(['data', 'status'], action.payload.status)

    case requestFailure(actionTypes.LOAD_SUPPLIER_PROFILE):
      return state.merge({
        isFetching: false,
        fetchErrorMessage: action.payload
      })

    case requestSuccess(actionTypes.LOAD_SOCIAL_FEED):
      return state.set('socialFeed', fromJS(action.payload))

    case LOGOUT:
      return new ProfileRecord()
    default:
      return state
  }
}

export default profileReducer
