import React from 'immutable'
import Paper from 'shared/components/Paper'

type Props = {
  value: number
  label: string
  isPercentage?: boolean
}

const OverviewBox = (props: Props) => {
  const { value = 0, label, isPercentage } = props

  const roundDecimal = num => {
    return (
      (Math.round(Number((Math.abs(num) * 100).toPrecision(15))) / 100) *
      Math.sign(num)
    )
  }

  return (
    <Paper noPadding>
      <div className='pv3 ph3'>
        <div className='f3 fw6 mb1'>
          {isPercentage ? `${roundDecimal(value)}%` : value.toLocaleString()}
        </div>
        <div className='h3'>
          <label className='f7 fw4'>{label}</label>
        </div>
      </div>
    </Paper>
  )
}

export default OverviewBox
