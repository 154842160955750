import React from 'react'
import Paper from 'shared/components/Paper'
import Divider from 'shared/components/Divider'
import TechWebhookConfig from './TechWebhookConfig'
import TechConfigNav from './TechConfigNav'
import TechSettings from './TechSettings'
import { Switch, Route, Redirect } from 'react-router'
import paths from '../../routes/paths'

type Props = {
  isTealbot: boolean
}

const TechConfig = ({ isTealbot }: Props) => {
  return (
    <>
      {isTealbot && (
        <>
          <TechConfigNav />
          <Divider />
        </>
      )}
      <Paper className='mt4 pt3-5'>
        <Switch>
          {isTealbot && (
            <Route exact path={paths.techConfig} component={TechSettings} />
          )}
          <Route path={paths.techWebhooks} component={TechWebhookConfig} />
          <Redirect to={paths.techWebhooks} />
        </Switch>
      </Paper>
    </>
  )
}

export default TechConfig
