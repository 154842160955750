import React, { Component, ReactNode, ChangeEvent } from 'react'
import { connect } from 'react-redux'
import orgsSelectors from '../../store/selectors/orgsSelectors'
import userEmailSelectors from '../../store/selectors/userEmailSelectors'
import {
  makeSupplierABuyer,
  deleteSupplier,
  joinCommunity,
  moveSupplier,
  removeFromCommunity
} from '../../store/actions'
import OrgSearchResultItem from '../../components/OrgSearchResultItem'
import DropDownMenu from 'shared/components/DropDownMenu'
import Select from 'shared/components/Select'
import Input from 'shared/components/Input'
import Checkbox from 'shared/components/Checkbox'
import Label from 'shared/components/Label'
import MenuItem from 'shared/components/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import Button from 'shared/components/Button'
import startCase from 'lodash.startcase'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import CreateCommunityFormContainer from '../CreateCommunityFormContainer'
import { List, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'
import BecomeTealbotDialog from 'shared/containers/BecomeTealbotDialog/BecomeTealbotDialog'
import { getReasonKey } from '../../../data-portal/store/actions'

type State = {
  openDialog: boolean
  orgUnitId: string
  orgName: string
  buyerType: string
  action: string
  communityId: string
  parentId: string
  ignoreCards: boolean
  replacementOrgUnitId: string
  filteredBuyerType: string
  oneTimeKey?: number
  selectedOrg: string
}

type Props = {
  searchOn: string
  orgs: List<
    RecordOf<{
      id: string
      name: string
      logoUrl: string
      domains: Array<string>
      rightButton: ReactNode
      publicProfile: boolean
      hasConcierge: boolean
      categorization: string
      isClient: boolean
      fixedFilters: any
      defaultFilters: any
      showConcierge: string
      clientOf: string
      vetApproval: boolean
      partners?: List<string>
      buyerType: string
    }>
  >
  showExtraOptions: boolean
  selectedUserEmail: string
  onClickConfirmChanges: (payload: {
    orgUnitId: string
    buyerType: string
    action: string
  }) => void
  onClickConfirmDeleteSupplier: (payload: {
    orgUnitId: string
    ignoreCards?: boolean
    replacementOrgUnitId?: string
  }) => void
  onClickJoinCommunity: (payload: {
    orgUnitId: string
    communityId: string
    name: string
  }) => void
  onClickRemoveFromCommunity: (payload: {
    orgUnitId: string
    communityId: string
    name: string
  }) => void
  onClickMoveSupplier: (payload: {
    orgUnitId: string
    parentId: string
  }) => void
  mobilePhone: string
  onGetReasonKey: (payload: {
    orgUnitId: string
    orgName: string
    userMobilePhone: string
  }) => void
  onSelectOrgForRelationshipSuppliersStat?: (
    orgId: string,
    orgName: string
  ) => void
}

export class TealbotOrgSearchResults extends Component<Props, State> {
  state = {
    action: '',
    orgUnitId: '',
    openDialog: false,
    orgName: '',
    buyerType: '',
    communityId: '',
    parentId: '',
    ignoreCards: false,
    replacementOrgUnitId: '',
    filteredBuyerType: '',
    selectedOrg: ''
  }

  handleBuyerTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      buyerType: e.currentTarget.value
    })
  }

  handleClickAction = actionParams => {
    this.setState({
      openDialog: true,
      ...actionParams
    })
  }

  handleClickMakeBuyer = ({ id, name }) => {
    this.setState({
      action: 'make',
      openDialog: true,
      orgUnitId: id,
      orgName: name,
      buyerType: ''
    })
  }

  handleConvertDemoToCorporate = ({ id, name }) => {
    this.setState({
      action: 'convert',
      openDialog: true,
      orgUnitId: id,
      orgName: name,
      buyerType: 'corporate'
    })
  }
  handleClickDeleteSupplier = ({ id, name }) => {
    this.setState({
      action: 'delete',
      openDialog: true,
      orgUnitId: id,
      orgName: name,
      ignoreCards: false
    })
  }

  handleClickMoveSupplier = () => {
    const { onClickMoveSupplier } = this.props
    const { orgUnitId, parentId } = this.state

    if (this.state.parentId) {
      onClickMoveSupplier({ orgUnitId, parentId })
      this.setState({ parentId: '' })
      this.handleCloseDialog()
    }
  }

  handleClickCreateCommunity = ({ id, name }) => {
    this.setState({
      action: 'create',
      openDialog: true,
      orgUnitId: id,
      orgName: name
    })
  }

  handleClickConfirmDeleteSupplier = () => {
    const { onClickConfirmDeleteSupplier } = this.props
    const { orgUnitId, ignoreCards, replacementOrgUnitId } = this.state

    onClickConfirmDeleteSupplier({
      orgUnitId,
      ignoreCards: ignoreCards || undefined,
      replacementOrgUnitId
    })

    this.handleCloseDialog()
  }

  handleCloseDialog = () => {
    this.setState({
      action: '',
      orgUnitId: '',
      orgName: '',
      openDialog: false,
      buyerType: '',
      communityId: '',
      ignoreCards: false,
      replacementOrgUnitId: ''
    })
  }

  handleClickConfirmChanges = () => {
    const { onClickConfirmChanges } = this.props
    const { orgUnitId, buyerType, action } = this.state

    onClickConfirmChanges({
      orgUnitId,
      buyerType,
      action
    })

    this.handleCloseDialog()
  }

  handleClickRemoveBuyer = ({ id, name }) => {
    this.setState({
      action: 'remove',
      openDialog: true,
      orgUnitId: id,
      orgName: name,
      buyerType: 'supplierOnly'
    })
  }

  handleCommunityIdChange = e => {
    this.setState({
      communityId: e.target.value
    })
  }

  handleClickJoinCommunity = () => {
    const { onClickJoinCommunity } = this.props
    const { orgUnitId, communityId, orgName } = this.state

    if (orgUnitId && communityId) {
      onClickJoinCommunity({
        orgUnitId,
        communityId,
        name: orgName
      })
    }

    this.handleCloseDialog()
  }

  handleClickRemoveFromCommunity = () => {
    const { onClickRemoveFromCommunity } = this.props
    const { orgUnitId, communityId, orgName } = this.state

    if (orgUnitId && communityId) {
      onClickRemoveFromCommunity({
        orgUnitId,
        communityId,
        name: orgName
      })
    }

    this.handleCloseDialog()
  }

  toggleIgnoreCards = () => {
    this.setState({
      ignoreCards: !this.state.ignoreCards
    })
  }

  handleReplacementOrgUnitChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      replacementOrgUnitId: e.currentTarget.value
    })
  }
  filterByBuyerType = () => {
    const { orgs } = this.props
    return orgs
      .reduce<Array<string>>((result, currentOrg) => {
        if (result.indexOf(currentOrg.get('buyerType')) < 0) {
          result.push(currentOrg.get('buyerType'))
        }
        return result
      }, [])
      .sort()
  }
  handleFilterByBuyerType = e => {
    this.setState({ filteredBuyerType: e.target.value })
  }

  visibleOrgs = () => {
    const { orgs } = this.props
    const { filteredBuyerType } = this.state
    return filteredBuyerType
      ? orgs && orgs.filter(org => org.get('buyerType') === filteredBuyerType)
      : orgs
  }

  handleTealbotLogin = ({ e, id, orgName }) => {
    const { mobilePhone, onGetReasonKey } = this.props

    //POST for oneTime pin code
    //saving orgUnitId and orgName to redux state so can pre-populate dialog once SMS successfully sent
    onGetReasonKey({
      orgUnitId: id,
      orgName: orgName,
      userMobilePhone: mobilePhone
    })
  }

  render() {
    const { searchOn, showExtraOptions } = this.props
    const {
      openDialog,
      action,
      orgName,
      orgUnitId,
      parentId,
      buyerType,
      communityId,
      ignoreCards,
      replacementOrgUnitId,
      selectedOrg
    } = this.state
    const { onSelectOrgForRelationshipSuppliersStat } = this.props

    return (
      <>
        {searchOn === 'buyer' && (
          <div className='w6 dib mb2'>
            <Select fullWidth onChange={this.handleFilterByBuyerType}>
              <option value=''>All Buyers</option>
              {this.filterByBuyerType().map((typeName, index) => (
                <option key={index} value={typeName}>
                  {startCase(typeName)}
                </option>
              ))}
            </Select>
          </div>
        )}
        <div className='ba b--light-gray ph3 bg-white pb4'>
          {this.visibleOrgs &&
            this.visibleOrgs().map(org => (
              <OrgSearchResultItem
                key={org.get('id')}
                id={org.get('id')}
                name={org.get('name')}
                logoUrl={org.get('logoUrl')}
                domains={org.get('domains')}
                publicProfile={org.get('publicProfile')}
                hasConcierge={org.get('hasConcierge')}
                showConcierge={org.get('showConcierge')}
                categorization={org.get('categorization')}
                fixedFilters={org.get('fixedFilters')}
                defaultFilters={org.get('defaultFilters')}
                vetApproval={org.get('vetApproval')}
                clientOf={org.get('clientOf')}
                isClient={searchOn === 'buyer'}
                partners={org.get('partners')}
                rightButton={
                  <div className='dib'>
                    <Button
                      className='mr2'
                      label={
                        onSelectOrgForRelationshipSuppliersStat
                          ? selectedOrg === org.get('id')
                            ? 'Selected'
                            : 'Select'
                          : 'Login'
                      }
                      autoSize
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        if (onSelectOrgForRelationshipSuppliersStat) {
                          const selectedOrgId =
                            selectedOrg === org.get('id') ? '' : org.get('id')
                          const selectedOrgName = selectedOrgId.length
                            ? org.get('name')
                            : ''
                          onSelectOrgForRelationshipSuppliersStat(
                            selectedOrgId,
                            selectedOrgName
                          )
                          this.setState({
                            selectedOrg: selectedOrgId
                          })
                        } else {
                          this.handleTealbotLogin({
                            e: e,
                            id: org.get('id'),
                            orgName: org.get('name')
                          })
                        }
                      }}
                    />
                    {showExtraOptions &&
                      !onSelectOrgForRelationshipSuppliersStat && (
                        <DropDownMenu>
                          {searchOn === 'supplier' && (
                            <MenuItem
                              onClick={() =>
                                this.handleClickMakeBuyer({
                                  id: org.get('id'),
                                  name: org.get('name')
                                })
                              }
                            >
                              Make Buyer
                            </MenuItem>
                          )}
                          {searchOn === 'supplier' && (
                            <MenuItem
                              onClick={() =>
                                this.handleClickDeleteSupplier({
                                  id: org.get('id'),
                                  name: org.get('name')
                                })
                              }
                            >
                              Delete Supplier
                            </MenuItem>
                          )}
                          {searchOn === 'supplier' && (
                            <MenuItem
                              onClick={() =>
                                this.handleClickAction({
                                  action: 'move',
                                  orgUnitId: org.get('id'),
                                  orgName: org.get('name')
                                })
                              }
                            >
                              Move Supplier
                            </MenuItem>
                          )}

                          {searchOn === 'buyer' && (
                            <MenuItem
                              onClick={() =>
                                this.handleClickRemoveBuyer({
                                  id: org.get('id'),
                                  name: org.get('name')
                                })
                              }
                            >
                              Remove Buyer Access
                            </MenuItem>
                          )}
                          {searchOn === 'buyer' &&
                            org.get('buyerType') === 'demo' && (
                              <MenuItem
                                onClick={() =>
                                  this.handleConvertDemoToCorporate({
                                    id: org.get('id'),
                                    name: org.get('name')
                                  })
                                }
                              >
                                Convert Demo Buyer to Corporate Buyer
                              </MenuItem>
                            )}
                          {searchOn === 'buyer' && (
                            <MenuItem
                              onClick={() =>
                                this.handleClickCreateCommunity({
                                  id: org.get('id'),
                                  name: org.get('name')
                                })
                              }
                            >
                              Create Community
                            </MenuItem>
                          )}
                          {searchOn === 'buyer' && (
                            <MenuItem
                              onClick={() =>
                                this.handleClickAction({
                                  action: 'join',
                                  orgUnitId: org.get('id'),
                                  orgName: org.get('name')
                                })
                              }
                            >
                              Join a Community
                            </MenuItem>
                          )}
                          {searchOn === 'buyer' && (
                            <MenuItem
                              onClick={() =>
                                this.handleClickAction({
                                  action: 'removeFromCommunity',
                                  orgUnitId: org.get('id'),
                                  orgName: org.get('name')
                                })
                              }
                            >
                              Remove from a Community
                            </MenuItem>
                          )}
                        </DropDownMenu>
                      )}
                  </div>
                }
              />
            ))}

          <Dialog open={openDialog} onClose={this.handleCloseDialog} fullWidth>
            <DialogTitle>{orgName}</DialogTitle>
            <DialogContent className='mv3'>
              {action === 'move' && (
                <div>
                  <Label className='db mb3 f5 fw6' required>
                    Which orgUnit ID or domain would you like to move to?
                  </Label>
                  <Input
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      this.setState({
                        parentId: e.target.value
                      })
                    }
                    value={parentId}
                  />
                  <div className='mt3'>
                    <Button
                      label='Move'
                      autoSize
                      className='mr3'
                      onClick={this.handleClickMoveSupplier}
                    />
                    <Button
                      label='Cancel'
                      secondary
                      autoSize
                      onClick={this.handleCloseDialog}
                    />
                  </div>
                </div>
              )}
              {action === 'make' && (
                <div>
                  <Label className='db mb3 f5 fw6' required>
                    What type of buyer?
                  </Label>
                  <Select onChange={this.handleBuyerTypeChange}>
                    <option value=''>Select a type</option>
                    <option value='corporate'>Corporate</option>
                    <option value='punchoutOnly'>Punchout Only</option>
                    <option value='demo'>Demo</option>
                  </Select>

                  {buyerType && (
                    <div>
                      <p className='db mb1 f5 fw6 mt4'>
                        Are you sure you want to make this supplier{' '}
                        <span>a {startCase(buyerType)} Buyer</span>?
                      </p>
                      <div className='mt3'>
                        <Button
                          label='Yes'
                          autoSize
                          className='mr3'
                          onClick={this.handleClickConfirmChanges}
                        />
                        <Button
                          label='No'
                          secondary
                          autoSize
                          onClick={this.handleCloseDialog}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {action === 'remove' && (
                <div>
                  <p className='db mb1 f5 fw6'>
                    Are you sure you want to remove buyer access from this
                    company?
                  </p>
                  <div className='mt3'>
                    <Button
                      label='Yes'
                      autoSize
                      className='mr3'
                      onClick={this.handleClickConfirmChanges}
                    />
                    <Button
                      label='No'
                      secondary
                      autoSize
                      onClick={this.handleCloseDialog}
                    />
                  </div>
                </div>
              )}
              {action === 'convert' && (
                <div>
                  <p className='db mb1 f5 fw6'>
                    Are you sure you want to convert this company from a Demo to
                    Corporate Buyer?
                  </p>
                  <div className='mt3'>
                    <Button
                      label='Yes'
                      autoSize
                      className='mr3'
                      onClick={this.handleClickConfirmChanges}
                    />
                    <Button
                      label='No'
                      secondary
                      autoSize
                      onClick={this.handleCloseDialog}
                    />
                  </div>
                </div>
              )}
              {action === 'delete' && (
                <div>
                  <p className='db mb1 f5 fw6'>
                    Are you sure you want to delete from this company?
                  </p>
                  <Label>Replacement OrgUnit Id</Label>
                  <Input
                    onChange={this.handleReplacementOrgUnitChange}
                    value={replacementOrgUnitId}
                  />
                  <div className='mt3 mb4'>
                    <Checkbox
                      label='Ignore cards'
                      onChange={this.toggleIgnoreCards}
                      checked={ignoreCards}
                    />
                  </div>
                  <div className='mt3'>
                    <Button
                      label='Yes'
                      autoSize
                      className='mr3'
                      onClick={this.handleClickConfirmDeleteSupplier}
                    />
                    <Button
                      label='No'
                      secondary
                      autoSize
                      onClick={this.handleCloseDialog}
                    />
                  </div>
                </div>
              )}
              {action === 'create' && (
                <CreateCommunityFormContainer
                  onAfterSubmit={this.handleCloseDialog}
                  orgUnitId={orgUnitId}
                />
              )}
              {action === 'join' && (
                <div>
                  <Label className='db mb3 f5 fw6' required>
                    What is the Community ID to join?
                  </Label>
                  <Input
                    onChange={this.handleCommunityIdChange}
                    value={communityId}
                  />
                  <div className='mt3'>
                    <Button
                      label='Join'
                      autoSize
                      className='mr3'
                      onClick={this.handleClickJoinCommunity}
                    />
                    <Button
                      label='Cancel'
                      secondary
                      autoSize
                      onClick={this.handleCloseDialog}
                    />
                  </div>
                </div>
              )}
              {action === 'removeFromCommunity' && (
                <div>
                  <Label className='db mb3 f5 fw6' required>
                    What is the Community ID to remove from?
                  </Label>
                  <Input
                    onChange={this.handleCommunityIdChange}
                    value={communityId}
                  />
                  <div className='mt3'>
                    <Button
                      label='Remove'
                      autoSize
                      className='mr3'
                      onClick={this.handleClickRemoveFromCommunity}
                    />
                    <Button
                      label='Cancel'
                      secondary
                      autoSize
                      onClick={this.handleCloseDialog}
                    />
                  </div>
                </div>
              )}
            </DialogContent>
          </Dialog>

          {/* assign reason for being concierge */}
          {/* input SMS PIN */}
          <BecomeTealbotDialog />
        </div>
      </>
    )
  }
}

export default connect(
  (state: RootState) => {
    const searchOn = orgsSelectors.getSearchBuyers(state) ? 'buyer' : 'supplier'
    const user = sessionSelectors.getUser(state)
    return {
      searchOn,
      orgs:
        searchOn === 'buyer'
          ? orgsSelectors.getAllSortedByName(state)
          : orgsSelectors.getAll(state),
      showExtraOptions: sessionSelectors.userHasRole(state, 'orgAdmin'),
      selectedUserEmail: userEmailSelectors.getEmail(state),
      mobilePhone: user && user.get('mobilePhone')
    }
  },
  {
    onClickConfirmChanges: makeSupplierABuyer,
    onClickConfirmDeleteSupplier: deleteSupplier,
    onClickJoinCommunity: joinCommunity,
    onClickMoveSupplier: moveSupplier,
    onClickRemoveFromCommunity: removeFromCommunity,
    onGetReasonKey: getReasonKey
  }
)(TealbotOrgSearchResults)
