import React, { FunctionComponent, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import CommunityFormPage from '../../components/CommunityFormPage'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import parsePath from 'shared/utils/parsePath'
import communityPaths from '../../routes/paths'
import {
  submitCommunityFormResponse,
  viewCommunityFormPage,
  loadCommunity
} from '../../actions'
import Loading from 'shared/components/Loading'
import { RouteComponentProps } from 'react-router'

type Props = {
  formIsMissingResponse: boolean
  pageNum: number
  membershipId: string
  pageId: string
  lastPage: boolean
  isLoadingCommunityFormPage: boolean
  loadCommunity: ({ communityId: string }) => void
  match: { params: { communityId: string } }
  viewCommunityFormPage: (args: {
    membershipId: string
    pageId: string
    userId: boolean
  }) => void
  submitCommunityFormResponse: (arg: {
    communityId: string
    membershipId: string
  }) => void
} & RouteComponentProps

export const CommunityFormPageContainer: FunctionComponent<Props> = props => {
  const [touched, setTouched] = useState<boolean>(false)

  const {
    loadCommunity,
    match,
    membershipId,
    pageId,
    viewCommunityFormPage,
    formIsMissingResponse,
    isLoadingCommunityFormPage
  } = props

  useEffect(() => {
    loadCommunity({
      communityId: match.params.communityId
    })
  }, [loadCommunity, match.params.communityId])

  useEffect(() => {
    viewCommunityFormPage({
      membershipId,
      pageId,
      userId: true
    })
  }, [membershipId, pageId, viewCommunityFormPage])

  const handleClickBack = () => {
    const { history, match, pageNum } = props
    // go back to communities page if in first page
    if (pageNum === 1) {
      if (communityPaths.basePath) {
        history.push(communityPaths.basePath)
      }
    } else {
      // go back a page
      history.push(
        parsePath(communityPaths.communityForm, {
          pageNum: pageNum - 1,
          communityId: match.params.communityId
        })
      )
    }
  }

  const handleClickContinue = () => {
    const {
      history,
      match,
      membershipId,
      pageNum,
      lastPage,
      submitCommunityFormResponse,
      formIsMissingResponse
    } = props

    if (lastPage) {
      if (!formIsMissingResponse) {
        submitCommunityFormResponse({
          communityId: match.params.communityId,
          membershipId
        })
      }
      setTouched(true)
    } else {
      history.push(
        parsePath(communityPaths.communityForm, {
          pageNum: pageNum + 1,
          communityId: match.params.communityId
        })
      )
    }
  }

  const handleClickSaveForLater = () => {
    const { history } = props
    if (communityPaths.basePath) {
      history.push(communityPaths.basePath)
    }
  }

  return isLoadingCommunityFormPage ? (
    <Loading />
  ) : (
    <CommunityFormPage
      {...props}
      onClickBack={handleClickBack}
      onClickContinue={handleClickContinue}
      onClickSaveForLater={handleClickSaveForLater}
      formIsMissingResponse={formIsMissingResponse && touched}
    />
  )
}

type ContainerProps = {
  match: { params: { communityId: string; pageNum: string } }
}
export default connect(
  (state, props: ContainerProps) => {
    const commId = props.match.params.communityId
    const pageNum = parseInt(props.match.params.pageNum, 0)

    const community = communitiesSelectors.getById(state, commId)
    const pages = community.getIn(['form', 'pages'])
    const page = community && community.getIn(['form', 'pages', pageNum - 1])

    return {
      membershipId: community.get('membershipId'),
      communityName: community && community.get('name'),
      pageTitle: page && page.get('title'),
      pageId: page && page.get('id'),
      lastPage: pages && pages.size === pageNum,
      formIsMissingResponse: communitiesSelectors.formIsMissingResponse(
        state,
        commId
      ),
      pageNum,
      isLoadingCommunityFormPage: communitiesSelectors.isLoadingCommunityFormPage(
        state
      )
    }
  },
  {
    loadCommunity,
    submitCommunityFormResponse,
    viewCommunityFormPage
  }
)(CommunityFormPageContainer)
