import { call } from 'redux-saga/effects'
import createApiRequestSaga from '../../utils/sagas/createApiRequestSaga'
import api from '../../utils/api'
import apiRoutes from '../../routes/apiRoutes'

export function* deleteDataSaga(action) {
  const {
    entity,
    endpoint,
    recordId,
    fieldName,
    query,
    onSuccess
  } = action.payload

  let endpointUrl = `${apiRoutes.authService}/${endpoint || entity}`
  if (!endpoint && recordId) {
    endpointUrl = `${endpointUrl}/${recordId}`
  }

  yield call(api.remove, endpointUrl, undefined, query)

  if (onSuccess) {
    yield call(onSuccess)
  }

  return yield {
    entity,
    recordId,
    fieldName
  }
}

export default createApiRequestSaga(deleteDataSaga, {
  notifyOnError: true
})
