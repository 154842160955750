import React, { FunctionComponent } from 'react'
import Loading from 'shared/components/Loading'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import { connect } from 'react-redux'
import { List, RecordOf } from 'immutable'
import VetQuestion from './VetQuestion'
import { createSelector } from 'reselect'
import moment from 'moment'
import PageSection from 'shared/components/PageSection'

type Question = {
  vetBuyerSupplierId: string
  id: string
  created: RecordOf<{
    user: string
    date: string
  }>
  createdInOrgUnit: string
  isPublic: boolean
  question: string
  response?: RecordOf<{
    isPublic: boolean
    response: string
    created: RecordOf<{
      date: string
      user: string
    }>
  }>
}

type Props = {
  isLoadingVetQuestions: boolean
  vetQuestions: List<RecordOf<Question>>
  unansweredVetQuestions: List<RecordOf<Question>>
}

export const VetQuestions: FunctionComponent<Props> = props => {
  if (props.isLoadingVetQuestions) {
    return <Loading />
  }

  return (
    <>
      {props.unansweredVetQuestions.size > 0 && (
        <PageSection
          noPadding
          title={
            <FormattedMessage
              id='VetQuestions.UnansweredQuestions'
              defaultMessage='Unanswered Questions'
            />
          }
        >
          {props.unansweredVetQuestions.map(question => (
            <VetQuestion
              key={question.get('id')}
              vetBuyerSupplierId={question.get('vetBuyerSupplierId')}
              id={question.get('id')}
              createdBy={question.getIn(['created', 'user'])}
              createdDate={question.getIn(['created', 'date'])}
              description={question.get('question')}
              responseBy={question.getIn(['response', 'created', 'user'])}
              responseDate={question.getIn(['response', 'created', 'date'])}
              response={question.getIn(['response', 'response'])}
              responseIsPublic={question.getIn(['response', 'isPublic'])}
            />
          ))}
        </PageSection>
      )}
      {props.vetQuestions.size > 0 && (
        <PageSection
          noPadding
          title={
            <FormattedMessage
              id='VetQuestions.AnsweredQuestions'
              defaultMessage='Answered Questions'
            />
          }
        >
          {props.vetQuestions.map(question => (
            <VetQuestion
              key={question.get('id')}
              vetBuyerSupplierId={question.get('vetBuyerSupplierId')}
              id={question.get('id')}
              createdBy={question.getIn(['created', 'user'])}
              createdDate={question.getIn(['created', 'date'])}
              description={question.get('question')}
              responseBy={question.getIn(['response', 'created', 'user'])}
              responseDate={question.getIn(['response', 'created', 'date'])}
              response={question.getIn(['response', 'response'])}
              responseIsPublic={question.getIn(['response', 'isPublic'])}
            />
          ))}
        </PageSection>
      )}
    </>
  )
}

// sort the questions by unanswered first
const getQuestions = createSelector(
  (state: RootState, unanswered: boolean) =>
    state.getIn(['buyer', 'currentVet', 'vetQuestions']),
  (state: RootState, unanswered: boolean) => unanswered,
  (questions: List<RecordOf<Question>>, unanswered: boolean) =>
    questions
      .filter(q => !q.getIn(['response', 'response']) === unanswered)
      .sort((q1, q2) =>
        moment(q1.getIn(['created', 'date'])).isAfter(
          moment(q2.getIn(['created', 'date']))
        )
          ? 1
          : -1
      )
)

export default connect((state: RootState) => ({
  vetQuestions: getQuestions(state, false),
  unansweredVetQuestions: getQuestions(state, true),
  isLoadingVetQuestions: state.getIn([
    'buyer',
    'currentVet',
    'isLoadingVetQuestions'
  ])
}))(VetQuestions)
