import React from 'react'
import CompletedGauge from './CompletedGauge'
import InvitationsChart from './InvitationsChart'
import Paper from 'shared/components/Paper'
import OverviewBox from './OverviewBox'
import InsightTitle from 'shared/components/InsightTitle'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'
import insightsSelectors from 'buyer/Insights/store/insightsSelectors'
import { SustainabilityReportOverview } from '../../store/insightsReducer'
import { RecordOf } from 'immutable'

const messages = defineMessages({
  completed: {
    id: 'SustainabilityReport.Completed',
    defaultMessage: 'Completed Survey'
  },
  outstanding: {
    id: 'SustainabilityReport.Outstanding',
    defaultMessage: 'Outstanding'
  },
  totalParticipated: {
    id: 'SustainabilityReport.totalParticipated',
    defaultMessage:
      'of your suppliers have participated in the sustainability survey'
  },
  totalAttachments: {
    id: 'SustainabilityReport.totalAttachments',
    defaultMessage: 'Total number of certificates & documents uploaded'
  },
  totalPositiveResponses: {
    id: 'SustainabilityReport.totalPositiveResponses',
    defaultMessage: 'Positive actions taken towards sustainability'
  },
  chartTitleTotalSuppliers: {
    id: 'SustainabilityReport.chartTitleTotalSuppliers',
    defaultMessage: 'Total Suppliers'
  },
  chartTitleTotalSuppliersInvited: {
    id: 'SustainabilityReport.chartTitleTotalSuppliersInvited',
    defaultMessage: 'Total Suppliers Invited'
  }
})

const Overview = () => {
  const intl = useIntl()
  const overview: RecordOf<SustainabilityReportOverview> = useSelector(
    insightsSelectors.getSustainabilityReportOverview
  )

  const pieData = [
    {
      name: intl.formatMessage(messages.completed),
      value: overview?.get('numCompletedSurveys') || 0
    },
    {
      name: intl.formatMessage(messages.outstanding),
      value:
        (overview?.get('numExistingSuppliers') || 0) -
        (overview?.get('numCompletedSurveys') || 0)
    }
  ]

  return (
    <>
      <InsightTitle>
        <FormattedMessage
          id='SustainabilityReport.Overview'
          defaultMessage='Overview'
        />
      </InsightTitle>
      <div>
        <div className='dib w-100 w-50-ns pr3-ns'>
          <Paper noPadding>
            <CompletedGauge
              data={pieData}
              totalLabel={intl.formatMessage(messages.chartTitleTotalSuppliers)}
            />
          </Paper>
        </div>
        <div className='dib w-100 mt3 mt0-ns w-50-ns pl3-ns'>
          <Paper noPadding>
            <InvitationsChart
              data={[
                {
                  name: 'something',
                  outstanding:
                    overview?.get('numInvitations', 0) -
                    overview?.get('numCompletedInvitations', 0) -
                    overview?.get('numInProgressStatusInvitations'),
                  inProgress: overview?.get('numInProgressStatusInvitations'),
                  completedSurvey: overview?.get('numCompletedInvitations', 0)
                }
              ]}
              totalLabel={intl.formatMessage(
                messages.chartTitleTotalSuppliersInvited
              )}
            />
          </Paper>
        </div>
      </div>
      <div className='mt3 flex-ns'>
        <div className='dib w-100 w-third-ns pr3-ns'>
          <OverviewBox
            label={intl.formatMessage(messages.totalParticipated)}
            isPercentage
            value={
              (overview.get('numCompletedSurveys') /
                overview.get('numExistingSuppliers')) *
              100
            }
          />
        </div>
        <div className='dib w-100 w-third-ns ph2-ns mv3 mv0-ns'>
          <OverviewBox
            label={intl.formatMessage(messages.totalAttachments)}
            value={overview.get('numCerts')}
          />
        </div>
        <div className='dib w-100 w-third-ns pl3-ns'>
          <OverviewBox
            label={intl.formatMessage(messages.totalPositiveResponses)}
            value={overview.get('numPositiveActions')}
          />
        </div>
      </div>
    </>
  )
}

export default Overview
