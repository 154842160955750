import React, { useState, ReactNode } from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import {
  setSpendCategory,
  setSpendCountry,
  setSpendSpendGroup
} from '../../store/actions'
import insightsSelectors from '../../store/insightsSelectors'
import SpendOverviewTable from '../SpendOverviewTable'
import SpendOverviewPie from '../SpendOverviewPie'
import iconPieChart from 'shared/assets/icons/chart-pie-solid.svg'
import iconTable from 'shared/assets/icons/table-solid.svg'
import IconButton from 'shared/components/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  table: {
    id: 'SpendOverviewData.tableSettings',
    defaultMessage: '{graphName} Table'
  },
  pieChart: {
    id: 'SpendOverviewData.tableSelectAll',
    defaultMessage: '{graphName} Pie Chart'
  },
  spendGroup: {
    id: 'SpendOverviewData.spendGroup',
    defaultMessage: 'Spend Group'
  },
  spendCountries: {
    id: 'SpendOverviewData.spendCountries',
    defaultMessage: 'Spend Countries'
  },
  spendCategories: {
    id: 'SpendOverviewData.spendCountries',
    defaultMessage: 'Spend Categories'
  }
})

const colors = [
  '#377eb8',
  '#66a61e',
  '#984ea3',
  '#00d2d5',
  '#ff7f00',
  '#af8d00',
  '#7f80cd',
  '#b3e900',
  '#c42e60',
  '#a65628',
  '#f781bf',
  '#8dd3c7',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#fccde5',
  '#bc80bd',
  '#ffed6f',
  '#c4eaff',
  '#cf8c00',
  '#1b9e77',
  '#d95f02',
  '#e7298a',
  '#e6ab02',
  '#a6761d',
  '#0097ff',
  '#00d067',
  '#525252',
  '#737373',
  '#969696',
  '#bdbdbd',
  '#f43600',
  '#4ba93b',
  '#5779bb',
  '#927acc',
  '#97ee3f',
  '#bf3947',
  '#9f5b00',
  '#f48758',
  '#8caed6',
  '#f2b94f',
  '#eff26e',
  '#e43872',
  '#d9b100',
  '#9d7a00',
  '#698cff'
]

const useStyles = makeStyles({
  icon: {
    transition: 'all .15s ease-in',
    filter:
      'brightness(0) saturate(100%) invert(32%) sepia(0%) saturate(0%) hue-rotate(261deg) brightness(95%) contrast(83%)', //#555555 for svg
    '&:hover': {
      filter: 'brightness(0) saturate(100%)' //#000000 for svg
    }
  }
})

type Props = {
  selectedValues: List<string>
  setSpendCategory: (value) => void
  setSpendCountry: (value) => void
  setSpendSpendGroup: (value) => void
  customTitle?: ReactNode
  countryTitle?: ReactNode
} & ContainerProps

const SpendOverviewData = (props: Props) => {
  const intl = useIntl()
  const classes = useStyles()
  const { type, customTitle, countryTitle } = props
  const [highlightIndex, setHighlighIndex] = useState<number | undefined>(
    undefined
  )
  const [isPieChart, setIsPieChart] = useState<boolean>(false)

  const handleChange = value => {
    if (type === 'category') {
      props.setSpendCategory(value || 'null')
    } else if (type === 'country') {
      props.setSpendCountry(value || 'null')
    } else {
      props.setSpendSpendGroup(value || 'null')
    }
  }

  let graphName: string
  if (type === 'spendgroup') {
    graphName = intl.formatMessage(messages.spendGroup)
  } else if (type === 'country') {
    graphName = intl.formatMessage(messages.spendCountries)
  } else {
    graphName = intl.formatMessage(messages.spendCategories)
  }

  return isPieChart ? (
    <SpendOverviewPie
      type={type}
      title={customTitle || countryTitle}
      values={props.selectedValues}
      changeIndex={setHighlighIndex}
      changeValues={handleChange}
      colors={colors}
      highlightIndex={highlightIndex}
      additionalActions={
        <IconButton
          onClick={() => setIsPieChart(!isPieChart)}
          aria-label={
            isPieChart
              ? intl.formatMessage(messages.table, { graphName: graphName })
              : intl.formatMessage(messages.pieChart, { graphName: graphName })
          }
          wrapperStyle={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
        >
          <img
            src={isPieChart ? iconTable : iconPieChart}
            alt={
              isPieChart
                ? intl.formatMessage(messages.table, { graphName: graphName })
                : intl.formatMessage(messages.pieChart, {
                    graphName: graphName
                  })
            }
            className={'w1 v-mid dib pointer ' + classes.icon}
          />
        </IconButton>
      }
    />
  ) : (
    <SpendOverviewTable
      type={type}
      title={customTitle || countryTitle}
      values={props.selectedValues}
      changeIndex={setHighlighIndex}
      changeValues={handleChange}
      additionalActions={
        <IconButton
          onClick={() => setIsPieChart(!isPieChart)}
          aria-label={
            isPieChart
              ? intl.formatMessage(messages.table, { graphName: graphName })
              : intl.formatMessage(messages.pieChart, { graphName: graphName })
          }
          wrapperStyle={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
        >
          <img
            src={isPieChart ? iconTable : iconPieChart}
            alt={
              isPieChart
                ? intl.formatMessage(messages.table, { graphName: graphName })
                : intl.formatMessage(messages.pieChart, {
                    graphName: graphName
                  })
            }
            className={'w1 v-mid dib pointer ' + classes.icon}
          />
        </IconButton>
      }
    />
  )
}

type ContainerProps = {
  type: 'category' | 'country' | 'spendgroup'
}

export default connect(
  (state, props: ContainerProps) => ({
    selectedValues:
      props.type === 'category'
        ? insightsSelectors.getSpendField(state, 'categories')
        : props.type === 'country'
        ? insightsSelectors.getSpendField(state, 'countries')
        : insightsSelectors.getSpendField(state, 'spendgroups')
  }),
  {
    setSpendCategory,
    setSpendCountry,
    setSpendSpendGroup
  }
)(SpendOverviewData)
