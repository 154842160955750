import React, { ReactElement, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import surveySelectors from '../../selectors/surveySelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import supplierSurveySelectors from 'supplier/Survey/selectors/surveySelectors'
import Text from 'shared/components/Text'
import startCase from 'lodash.startcase'
import SurveyCertificateContainer from '../../containers/SurveyCertificateContainer'
import CertificateContainer from 'supplier/Survey/containers/CertificateContainer'
import Attachment from 'supplier/Survey/components/Attachment'
import { RecordOf } from 'immutable'
import {
  AttachmentType,
  CertType
} from 'supplier/Survey/reducers/surveyReducer/surveyReducer'
import routingSelectors from 'shared/selectors/routingSelectors'
import VerifyAttachment from './VerifyAttachment'

type Props = {
  pageId: string
  parentQuestionId?: string
  questionId: string
  question?: string | ReactElement<FormattedMessage>
  longAnswer?: boolean
  childAnswer?: boolean
  children?: ReactNode
}

const ShowQuestionResponse = (props: Props) => {
  const {
    question,
    pageId,
    parentQuestionId,
    questionId,
    longAnswer,
    childAnswer,
    children
  } = props

  const isSupplierApp = useSelector((state: RootState) =>
    routingSelectors.getPathname(state).includes('supplier/')
  )
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const response = useSelector((state: RootState) => {
    const key = `${pageId}-${parentQuestionId || ''}-${questionId}`
    return isSupplierApp
      ? supplierSurveySelectors.getResponseDetailByIds(
          state,
          pageId,
          questionId,
          parentQuestionId
        )
      : surveySelectors.getSurveyResponseDetailByKey(state, key)
  })

  const attachmentType = response?.get('attachmentType')

  if (!response) {
    return null
  }

  return (
    <>
      {question && (
        <div className='flex justify-between items-start mt4 mb2'>
          <div className='w-two-thirds'>
            <Text>{question}</Text>
          </div>
          {!longAnswer && response?.get('answerType') !== 'list' && (
            <div className='flex items-center justify-end w-third'>
              <div className={`w-90 ${childAnswer ? '' : 'pl1'}`}>
                <Text>
                  <FormattedMessage
                    id='ShowQuestionResponse.Answer'
                    defaultMessage='Answer'
                  />
                  :{' '}
                  <span className={'black fw6'}>
                    {startCase(response?.get('answer'))}
                  </span>
                </Text>
              </div>
            </div>
          )}
        </div>
      )}
      {(longAnswer || response?.get('answerType') === 'list' || !question) &&
        (response?.get('answerType') === 'list' ? (
          <ul className='pl3'>
            {response
              ?.get('answer')
              .split('|')
              .map(ans => (
                <li key={ans}>
                  <Text className={'fw6'} style={{ color: 'black' }}>
                    {ans}
                  </Text>
                </li>
              ))}
          </ul>
        ) : (
          <Text className={'fw6'} style={{ color: 'black' }}>
            {response?.get('answer')}
          </Text>
        ))}
      {attachmentType &&
        response?.get('attachments') &&
        response?.get('attachments').size > 0 &&
        response?.get('attachments').map((attachment, index) => (
          <div key={`attachment-${index}`} className='flex'>
            <div className='br1 b--light-silver ba ph2 pv1 w-two-thirds-ns flex justify-between items-center mv1'>
              {attachmentType === 'cert' && isSupplierApp && (
                <CertificateContainer
                  certTimeStamp={(attachment as RecordOf<CertType>).get(
                    'timeStamp'
                  )}
                />
              )}
              {attachmentType === 'cert' && !isSupplierApp && (
                <SurveyCertificateContainer
                  certTimeStamp={(attachment as RecordOf<CertType>).get(
                    'timeStamp'
                  )}
                />
              )}
              {attachmentType !== 'cert' && (
                <Attachment
                  attachment={attachment as RecordOf<AttachmentType>}
                />
              )}
            </div>
            <div className='ml4-ns'>
              {attachmentType !== 'cert' &&
                response?.getIn(['parents', 'OrgUnit']) !== orgUnitId && (
                  <VerifyAttachment
                    surveyResponseDetailId={response.get('id')}
                    attachmentType={attachmentType}
                    attachment={attachment}
                  />
                )}
            </div>
          </div>
        ))}
      {response?.get('reason') && response?.get('reasonType') !== 'list' && (
        <Text className={'fw6'} style={{ color: 'black' }}>
          {response?.get('reason')}
        </Text>
      )}
      {response?.get('reason') && response?.get('reasonType') === 'list' && (
        <ul className='pl3'>
          {response
            ?.get('reason')
            .split('|')
            .map(ans => (
              <li key={ans}>
                <Text className={'fw6'} style={{ color: 'black' }}>
                  {ans}
                </Text>
              </li>
            ))}
        </ul>
      )}
      {children && <div className='ml3'>{children}</div>}
    </>
  )
}

export default ShowQuestionResponse
