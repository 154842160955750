import React, { useState } from 'react'
import Button from 'shared/components/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import tier2 from 'shared/utils/api/tier2'
import numberFormat from 'shared/utils/numberFormat'
import Text from 'shared/components/Text'
import translateCertificationType from 'shared/utils/translateCertificationType'

type Props = {
  communityPlanMembershipId: string
  year: number
  quarter: number
  overallAllocation?: number
  diverseSupplierAllocations: Array<{
    id: string
    spend: number
    name: string
    allocation?: number
  }>
}

type PreviewState = {
  loading: boolean
  data?: Array<{
    cert: string
    spend: number
  }>
}
const SpendAllocationPreview = (props: Props) => {
  const intl = useIntl()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [preview, setPreview] = useState<PreviewState>({
    loading: false
  })

  return (
    <>
      <Button
        disabled={preview.loading}
        label={
          preview.loading ? (
            <FormattedMessage
              id='SpendAllocation.Loading'
              defaultMessage='Loading...'
            />
          ) : (
            <FormattedMessage
              id='SpendAllocation.Preview'
              defaultMessage='Preview'
            />
          )
        }
        secondary
        onClick={() => {
          setPreview({
            loading: true
          })
          tier2.getSpendAllocationPreview(props).then(result => {
            setPreview({
              loading: false,
              data: result
            })
            setOpenDialog(true)
          })
        }}
      />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
        <DialogTitle onClose={() => setOpenDialog(false)}>
          <FormattedMessage
            id='SpendAllocationPreview.PreviewShare1'
            defaultMessage='Preview Share'
          />
        </DialogTitle>
        <DialogContent>
          <Text>
            <FormattedMessage
              id='SpendAllocationPreview.PreviewShareMessage'
              defaultMessage='The following information is a preview of what you will be sharing with TealBook for {year} {quarter}'
              values={{
                year: props.year,
                quarter: `Q${props.quarter}`
              }}
            />
          </Text>
          {preview.data?.length ? (
            <ul className='mv3 pa0'>
              {preview.data.map(previewItem => (
                <li className='flex fw6 mt2 f7'>
                  <span>
                    {translateCertificationType({
                      categoryType: 'diversity',
                      subCategoryType: previewItem.cert,
                      intl
                    })}
                    &nbsp; (
                    {translateCertificationType({
                      categoryType: 'diversity',
                      subCategoryType: previewItem.cert,
                      useAcronym: true,
                      intl
                    })}
                    )
                  </span>
                  <span className='flex-auto tr'>
                    ${numberFormat(previewItem.spend, 2)}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <Text>
              <FormattedMessage
                id='SpendAllocationPreview.NoDataToPreview'
                defaultMessage='There is no allocated spend data to preview.'
              />
            </Text>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            label={<FormattedMessage id='CloseButton' />}
            onClick={() => setOpenDialog(false)}
            autoSize
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SpendAllocationPreview
