import React, { FC } from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import { FormattedMessage } from 'react-intl'

type Props = {
  status: string
  vetBuyerSupplierId: string
  redirectFrom: string
  opened: boolean
  enableQandA: boolean
}

const VetNavBar: FC<Props> = ({
  status,
  vetBuyerSupplierId,
  redirectFrom,
  opened,
  enableQandA
}) => {
  const pathToDetails = parsePath(paths.vetDetails, { vetBuyerSupplierId })
  const pathToResponse = parsePath(paths.vetResponse, { vetBuyerSupplierId })
  const pathToTerms = parsePath(paths.vetTerms, { vetBuyerSupplierId })
  const pathToOutcome = parsePath(paths.vetOutcome, { vetBuyerSupplierId })
  const pathToQandA = paths.vetQA.replace(
    ':vetBuyerSupplierId',
    vetBuyerSupplierId
  )

  return (
    <NavBar>
      {status === 'Invited' && (
        <NavLink
          label={
            <FormattedMessage
              id='VetNavBar.TermsLabel'
              defaultMessage='Terms'
            />
          }
          to={`${pathToTerms}${
            redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
          }`}
          isActive={(match, location) => location.pathname === pathToTerms}
        />
      )}

      {opened && (
        <NavLink
          label={
            <FormattedMessage
              id='VetNavBar.DetailsLabel'
              defaultMessage='Details'
            />
          }
          to={`${pathToDetails}${
            redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
          }`}
          isActive={(match, location) => location.pathname === pathToDetails}
        />
      )}

      {opened && enableQandA && (
        <NavLink
          label={
            <FormattedMessage id='VetNavBar.QandA' defaultMessage='Q &amp; A' />
          }
          to={`${pathToQandA}${
            redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
          }`}
          isActive={(match, location) => location.pathname === pathToQandA}
        />
      )}

      {(opened || status === 'Declined') && (
        <NavLink
          label={
            <FormattedMessage
              id='VetNavBar.OurResponseLabel'
              defaultMessage='Our Response'
            />
          }
          to={`${pathToResponse}${
            redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
          }`}
          isActive={(match, location) => location.pathname === pathToResponse}
        />
      )}

      {!(
        status === 'Opened' ||
        status === 'Interested' ||
        status === 'Meet Criteria' ||
        status === 'Invited'
      ) && (
        <NavLink
          label={
            <FormattedMessage
              id='VetNavBar.OutcomeLabel'
              defaultMessage='Outcome'
            />
          }
          to={`${pathToOutcome}${
            redirectFrom ? `?redirectFrom=${redirectFrom}` : ''
          }`}
          isActive={(match, location) => location.pathname === pathToOutcome}
        />
      )}
    </NavBar>
  )
}

export default VetNavBar
