import React from 'react'
import Popper, { PopperPlacementType } from '@material-ui/core/Popper'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      pointerEvents: 'none',
      padding: theme.spacing(1),
      backgroundColor: 'white',
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)'
    }
  })
)

type Props = {
  children: React.ReactElement
  content: React.ReactElement
  id: string
  styles?: string
}

const CustomPopper = (props: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [placement, setPlacement] = React.useState<PopperPlacementType>()
  const { children, content, id, styles } = props

  const handlePopperOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget)
    setPlacement('bottom-end')
  }

  const handlePopperClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <div
        aria-owns={open ? id : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopperOpen}
        onMouseLeave={handlePopperClose}
      >
        {children}
      </div>

      <Popper
        id={id}
        className={`${classes.popper} ${styles}`}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
      >
        {content}
      </Popper>
    </>
  )
}

export default CustomPopper
