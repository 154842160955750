import { defineMessages } from 'react-intl'

const naics = defineMessages({
  '11': {
    id: 'naics-11',
    defaultMessage: 'Agriculture, Forestry, Fishing and Hunting'
  },
  '21': {
    id: 'naics-21',
    defaultMessage: 'Mining, Quarrying, and Oil and Gas Extraction'
  },
  '22': {
    id: 'naics-22',
    defaultMessage: 'Utilities'
  },
  '23': {
    id: 'naics-23',
    defaultMessage: 'Construction'
  },
  '31': {
    id: 'naics-31',
    defaultMessage: 'Manufacturing'
  },
  '32': {
    id: 'naics-32',
    defaultMessage: 'Manufacturing'
  },
  '33': {
    id: 'naics-33',
    defaultMessage: 'Manufacturing'
  },
  '42': {
    id: 'naics-42',
    defaultMessage: 'Wholesale Trade'
  },
  '44': {
    id: 'naics-44',
    defaultMessage: 'Retail Trade'
  },
  '45': {
    id: 'naics-45',
    defaultMessage: 'Retail Trade'
  },
  '48': {
    id: 'naics-48',
    defaultMessage: 'Transportation and Warehousing'
  },
  '49': {
    id: 'naics-49',
    defaultMessage: 'Transportation and Warehousing'
  },
  '51': {
    id: 'naics-51',
    defaultMessage: 'Information'
  },
  '52': {
    id: 'naics-52',
    defaultMessage: 'Finance and Insurance'
  },
  '53': {
    id: 'naics-53',
    defaultMessage: 'Real Estate and Rental and Leasing'
  },
  '54': {
    id: 'naics-54',
    defaultMessage: 'Professional, Scientific, and Technical Services'
  },
  '55': {
    id: 'naics-55',
    defaultMessage: 'Management of Companies and Enterprises'
  },
  '56': {
    id: 'naics-56',
    defaultMessage:
      'Administrative and Support and Waste Management and Remediation Services'
  },
  '61': {
    id: 'naics-61',
    defaultMessage: 'Educational Services'
  },
  '62': {
    id: 'naics-62',
    defaultMessage: 'Health Care and Social Assistance'
  },
  '71': {
    id: 'naics-71',
    defaultMessage: 'Arts, Entertainment, and Recreation'
  },
  '72': {
    id: 'naics-72',
    defaultMessage: 'Accommodation and Food Services'
  },
  '81': {
    id: 'naics-81',
    defaultMessage: 'Other Services (except Public Administration)'
  },
  '92': {
    id: 'naics-92',
    defaultMessage: 'Public Administration'
  },
  '111': {
    id: 'naics-111',
    defaultMessage: 'Crop Production'
  },
  '112': {
    id: 'naics-112',
    defaultMessage: 'Animal Production and Aquaculture'
  },
  '113': {
    id: 'naics-113',
    defaultMessage: 'Forestry and Logging'
  },
  '114': {
    id: 'naics-114',
    defaultMessage: 'Fishing, Hunting and Trapping'
  },
  '115': {
    id: 'naics-115',
    defaultMessage: 'Support Activities for Agriculture and Forestry'
  },
  '211': {
    id: 'naics-211',
    defaultMessage: 'Oil and Gas Extraction'
  },
  '212': {
    id: 'naics-212',
    defaultMessage: 'Mining (except Oil and Gas)'
  },
  '213': {
    id: 'naics-213',
    defaultMessage: 'Support Activities for Mining'
  },
  '221': {
    id: 'naics-221',
    defaultMessage: 'Utilities'
  },
  '236': {
    id: 'naics-236',
    defaultMessage: 'Construction of Buildings'
  },
  '237': {
    id: 'naics-237',
    defaultMessage: 'Heavy and Civil Engineering Construction'
  },
  '238': {
    id: 'naics-238',
    defaultMessage: 'Specialty Trade Contractors'
  },
  '311': {
    id: 'naics-311',
    defaultMessage: 'Food Manufacturing'
  },
  '312': {
    id: 'naics-312',
    defaultMessage: 'Beverage and Tobacco Product Manufacturing'
  },
  '313': {
    id: 'naics-313',
    defaultMessage: 'Textile Mills'
  },
  '314': {
    id: 'naics-314',
    defaultMessage: 'Textile Product Mills'
  },
  '315': {
    id: 'naics-315',
    defaultMessage: 'Apparel Manufacturing'
  },
  '316': {
    id: 'naics-316',
    defaultMessage: 'Leather and Allied Product Manufacturing'
  },
  '321': {
    id: 'naics-321',
    defaultMessage: 'Wood Product Manufacturing'
  },
  '322': {
    id: 'naics-322',
    defaultMessage: 'Paper Manufacturing'
  },
  '323': {
    id: 'naics-323',
    defaultMessage: 'Printing and Related Support Activities'
  },
  '324': {
    id: 'naics-324',
    defaultMessage: 'Petroleum and Coal Products Manufacturing'
  },
  '325': {
    id: 'naics-325',
    defaultMessage: 'Chemical Manufacturing'
  },
  '326': {
    id: 'naics-326',
    defaultMessage: 'Plastics and Rubber Products Manufacturing'
  },
  '327': {
    id: 'naics-327',
    defaultMessage: 'Nonmetallic Mineral Product Manufacturing'
  },
  '331': {
    id: 'naics-331',
    defaultMessage: 'Primary Metal Manufacturing'
  },
  '332': {
    id: 'naics-332',
    defaultMessage: 'Fabricated Metal Product Manufacturing'
  },
  '333': {
    id: 'naics-333',
    defaultMessage: 'Machinery Manufacturing'
  },
  '334': {
    id: 'naics-334',
    defaultMessage: 'Computer and Electronic Product Manufacturing'
  },
  '335': {
    id: 'naics-335',
    defaultMessage:
      'Electrical Equipment, Appliance, and Component Manufacturing'
  },
  '336': {
    id: 'naics-336',
    defaultMessage: 'Transportation Equipment Manufacturing'
  },
  '337': {
    id: 'naics-337',
    defaultMessage: 'Furniture and Related Product Manufacturing'
  },
  '339': {
    id: 'naics-339',
    defaultMessage: 'Miscellaneous Manufacturing'
  },
  '423': {
    id: 'naics-423',
    defaultMessage: 'Merchant Wholesalers, Durable Goods'
  },
  '424': {
    id: 'naics-424',
    defaultMessage: 'Merchant Wholesalers, Nondurable Goods'
  },
  '425': {
    id: 'naics-425',
    defaultMessage: 'Wholesale Electronic Markets and Agents and Brokers'
  },
  '441': {
    id: 'naics-441',
    defaultMessage: 'Motor Vehicle and Parts Dealers'
  },
  '442': {
    id: 'naics-442',
    defaultMessage: 'Furniture and Home Furnishings Stores'
  },
  '443': {
    id: 'naics-443',
    defaultMessage: 'Electronics and Appliance Stores'
  },
  '444': {
    id: 'naics-444',
    defaultMessage:
      'Building Material and Garden Equipment and Supplies Dealers'
  },
  '445': {
    id: 'naics-445',
    defaultMessage: 'Food and Beverage Stores'
  },
  '446': {
    id: 'naics-446',
    defaultMessage: 'Health and Personal Care Stores'
  },
  '447': {
    id: 'naics-447',
    defaultMessage: 'Gasoline Stations'
  },
  '448': {
    id: 'naics-448',
    defaultMessage: 'Clothing and Clothing Accessories Stores'
  },
  '451': {
    id: 'naics-451',
    defaultMessage: 'Sporting Goods, Hobby, Musical Instrument, and Book Stores'
  },
  '452': {
    id: 'naics-452',
    defaultMessage: 'General Merchandise Stores'
  },
  '453': {
    id: 'naics-453',
    defaultMessage: 'Miscellaneous Store Retailers'
  },
  '454': {
    id: 'naics-454',
    defaultMessage: 'Nonstore Retailers'
  },
  '481': {
    id: 'naics-481',
    defaultMessage: 'Air Transportation'
  },
  '482': {
    id: 'naics-482',
    defaultMessage: 'Rail Transportation'
  },
  '483': {
    id: 'naics-483',
    defaultMessage: 'Water Transportation'
  },
  '484': {
    id: 'naics-484',
    defaultMessage: 'Truck Transportation'
  },
  '485': {
    id: 'naics-485',
    defaultMessage: 'Transit and Ground Passenger Transportation'
  },
  '486': {
    id: 'naics-486',
    defaultMessage: 'Pipeline Transportation'
  },
  '487': {
    id: 'naics-487',
    defaultMessage: 'Scenic and Sightseeing Transportation'
  },
  '488': {
    id: 'naics-488',
    defaultMessage: 'Support Activities for Transportation'
  },
  '491': {
    id: 'naics-491',
    defaultMessage: 'Postal Service'
  },
  '492': {
    id: 'naics-492',
    defaultMessage: 'Couriers and Messengers'
  },
  '493': {
    id: 'naics-493',
    defaultMessage: 'Warehousing and Storage'
  },
  '511': {
    id: 'naics-511',
    defaultMessage: 'Publishing Industries (except Internet)'
  },
  '512': {
    id: 'naics-512',
    defaultMessage: 'Motion Picture and Sound Recording Industries'
  },
  '515': {
    id: 'naics-515',
    defaultMessage: 'Broadcasting (except Internet)'
  },
  '517': {
    id: 'naics-517',
    defaultMessage: 'Telecommunications'
  },
  '518': {
    id: 'naics-518',
    defaultMessage: 'Data Processing, Hosting, and Related Services'
  },
  '519': {
    id: 'naics-519',
    defaultMessage: 'Other Information Services'
  },
  '521': {
    id: 'naics-521',
    defaultMessage: 'Monetary Authorities-Central Bank'
  },
  '522': {
    id: 'naics-522',
    defaultMessage: 'Credit Intermediation and Related Activities'
  },
  '523': {
    id: 'naics-523',
    defaultMessage:
      'Securities, Commodity Contracts, and Other Financial Investments and Related Activities'
  },
  '524': {
    id: 'naics-524',
    defaultMessage: 'Insurance Carriers and Related Activities'
  },
  '525': {
    id: 'naics-525',
    defaultMessage: 'Funds, Trusts, and Other Financial Vehicles'
  },
  '531': {
    id: 'naics-531',
    defaultMessage: 'Real Estate'
  },
  '532': {
    id: 'naics-532',
    defaultMessage: 'Rental and Leasing Services'
  },
  '533': {
    id: 'naics-533',
    defaultMessage:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)'
  },
  '541': {
    id: 'naics-541',
    defaultMessage: 'Professional, Scientific, and Technical Services'
  },
  '551': {
    id: 'naics-551',
    defaultMessage: 'Management of Companies and Enterprises'
  },
  '561': {
    id: 'naics-561',
    defaultMessage: 'Administrative and Support Services'
  },
  '562': {
    id: 'naics-562',
    defaultMessage: 'Waste Management and Remediation Services'
  },
  '611': {
    id: 'naics-611',
    defaultMessage: 'Educational Services'
  },
  '621': {
    id: 'naics-621',
    defaultMessage: 'Ambulatory Health Care Services'
  },
  '622': {
    id: 'naics-622',
    defaultMessage: 'Hospitals'
  },
  '623': {
    id: 'naics-623',
    defaultMessage: 'Nursing and Residential Care Facilities'
  },
  '624': {
    id: 'naics-624',
    defaultMessage: 'Social Assistance'
  },
  '711': {
    id: 'naics-711',
    defaultMessage: 'Performing Arts, Spectator Sports, and Related Industries'
  },
  '712': {
    id: 'naics-712',
    defaultMessage: 'Museums, Historical Sites, and Similar Institutions'
  },
  '713': {
    id: 'naics-713',
    defaultMessage: 'Amusement, Gambling, and Recreation Industries'
  },
  '721': {
    id: 'naics-721',
    defaultMessage: 'Accommodation'
  },
  '722': {
    id: 'naics-722',
    defaultMessage: 'Food Services and Drinking Places'
  },
  '811': {
    id: 'naics-811',
    defaultMessage: 'Repair and Maintenance'
  },
  '812': {
    id: 'naics-812',
    defaultMessage: 'Personal and Laundry Services'
  },
  '813': {
    id: 'naics-813',
    defaultMessage:
      'Religious, Grantmaking, Civic, Professional, and Similar Organizations'
  },
  '814': {
    id: 'naics-814',
    defaultMessage: 'Private Households'
  },
  '921': {
    id: 'naics-921',
    defaultMessage:
      'Executive, Legislative, and Other General Government Support'
  },
  '922': {
    id: 'naics-922',
    defaultMessage: 'Justice, Public Order, and Safety Activities'
  },
  '923': {
    id: 'naics-923',
    defaultMessage: 'Administration of Human Resource Programs'
  },
  '924': {
    id: 'naics-924',
    defaultMessage: 'Administration of Environmental Quality Programs'
  },
  '925': {
    id: 'naics-925',
    defaultMessage:
      'Administration of Housing Programs, Urban Planning, and Community Development'
  },
  '926': {
    id: 'naics-926',
    defaultMessage: 'Administration of Economic Programs'
  },
  '927': {
    id: 'naics-927',
    defaultMessage: 'Space Research and Technology'
  },
  '928': {
    id: 'naics-928',
    defaultMessage: 'National Security and International Affairs'
  },
  '1111': {
    id: 'naics-1111',
    defaultMessage: 'Oilseed and Grain Farming'
  },
  '1112': {
    id: 'naics-1112',
    defaultMessage: 'Vegetable and Melon Farming'
  },
  '1113': {
    id: 'naics-1113',
    defaultMessage: 'Fruit and Tree Nut Farming'
  },
  '1114': {
    id: 'naics-1114',
    defaultMessage: 'Greenhouse, Nursery, and Floriculture Production'
  },
  '1119': {
    id: 'naics-1119',
    defaultMessage: 'Other Crop Farming'
  },
  '1121': {
    id: 'naics-1121',
    defaultMessage: 'Cattle Ranching and Farming'
  },
  '1122': {
    id: 'naics-1122',
    defaultMessage: 'Hog and Pig Farming'
  },
  '1123': {
    id: 'naics-1123',
    defaultMessage: 'Poultry and Egg Production'
  },
  '1124': {
    id: 'naics-1124',
    defaultMessage: 'Sheep and Goat Farming'
  },
  '1125': {
    id: 'naics-1125',
    defaultMessage: 'Aquaculture'
  },
  '1129': {
    id: 'naics-1129',
    defaultMessage: 'Other Animal Production'
  },
  '1131': {
    id: 'naics-1131',
    defaultMessage: 'Timber Tract Operations'
  },
  '1132': {
    id: 'naics-1132',
    defaultMessage: 'Forest Nurseries and Gathering of Forest Products'
  },
  '1133': {
    id: 'naics-1133',
    defaultMessage: 'Logging'
  },
  '1141': {
    id: 'naics-1141',
    defaultMessage: 'Fishing'
  },
  '1142': {
    id: 'naics-1142',
    defaultMessage: 'Hunting and Trapping'
  },
  '1151': {
    id: 'naics-1151',
    defaultMessage: 'Support Activities for Crop Production'
  },
  '1152': {
    id: 'naics-1152',
    defaultMessage: 'Support Activities for Animal Production'
  },
  '1153': {
    id: 'naics-1153',
    defaultMessage: 'Support Activities for Forestry'
  },
  '2111': {
    id: 'naics-2111',
    defaultMessage: 'Oil and Gas Extraction'
  },
  '2121': {
    id: 'naics-2121',
    defaultMessage: 'Coal Mining'
  },
  '2122': {
    id: 'naics-2122',
    defaultMessage: 'Metal Ore Mining'
  },
  '2123': {
    id: 'naics-2123',
    defaultMessage: 'Nonmetallic Mineral Mining and Quarrying'
  },
  '2131': {
    id: 'naics-2131',
    defaultMessage: 'Support Activities for Mining'
  },
  '2211': {
    id: 'naics-2211',
    defaultMessage: 'Electric Power Generation, Transmission and Distribution'
  },
  '2212': {
    id: 'naics-2212',
    defaultMessage: 'Natural Gas Distribution'
  },
  '2213': {
    id: 'naics-2213',
    defaultMessage: 'Water, Sewage and Other Systems'
  },
  '2361': {
    id: 'naics-2361',
    defaultMessage: 'Residential Building Construction'
  },
  '2362': {
    id: 'naics-2362',
    defaultMessage: 'Nonresidential Building Construction'
  },
  '2371': {
    id: 'naics-2371',
    defaultMessage: 'Utility System Construction'
  },
  '2372': {
    id: 'naics-2372',
    defaultMessage: 'Land Subdivision'
  },
  '2373': {
    id: 'naics-2373',
    defaultMessage: 'Highway, Street, and Bridge Construction'
  },
  '2379': {
    id: 'naics-2379',
    defaultMessage: 'Other Heavy and Civil Engineering Construction'
  },
  '2381': {
    id: 'naics-2381',
    defaultMessage: 'Foundation, Structure, and Building Exterior Contractors'
  },
  '2382': {
    id: 'naics-2382',
    defaultMessage: 'Building Equipment Contractors'
  },
  '2383': {
    id: 'naics-2383',
    defaultMessage: 'Building Finishing Contractors'
  },
  '2389': {
    id: 'naics-2389',
    defaultMessage: 'Other Specialty Trade Contractors'
  },
  '3111': {
    id: 'naics-3111',
    defaultMessage: 'Animal Food Manufacturing'
  },
  '3112': {
    id: 'naics-3112',
    defaultMessage: 'Grain and Oilseed Milling'
  },
  '3113': {
    id: 'naics-3113',
    defaultMessage: 'Sugar and Confectionery Product Manufacturing'
  },
  '3114': {
    id: 'naics-3114',
    defaultMessage:
      'Fruit and Vegetable Preserving and Specialty Food Manufacturing'
  },
  '3115': {
    id: 'naics-3115',
    defaultMessage: 'Dairy Product Manufacturing'
  },
  '3116': {
    id: 'naics-3116',
    defaultMessage: 'Animal Slaughtering and Processing'
  },
  '3117': {
    id: 'naics-3117',
    defaultMessage: 'Seafood Product Preparation and Packaging'
  },
  '3118': {
    id: 'naics-3118',
    defaultMessage: 'Bakeries and Tortilla Manufacturing'
  },
  '3119': {
    id: 'naics-3119',
    defaultMessage: 'Other Food Manufacturing'
  },
  '3121': {
    id: 'naics-3121',
    defaultMessage: 'Beverage Manufacturing'
  },
  '3122': {
    id: 'naics-3122',
    defaultMessage: 'Tobacco Manufacturing'
  },
  '3131': {
    id: 'naics-3131',
    defaultMessage: 'Fiber, Yarn, and Thread Mills'
  },
  '3132': {
    id: 'naics-3132',
    defaultMessage: 'Fabric Mills'
  },
  '3133': {
    id: 'naics-3133',
    defaultMessage: 'Textile and Fabric Finishing and Fabric Coating Mills'
  },
  '3141': {
    id: 'naics-3141',
    defaultMessage: 'Textile Furnishings Mills'
  },
  '3149': {
    id: 'naics-3149',
    defaultMessage: 'Other Textile Product Mills'
  },
  '3151': {
    id: 'naics-3151',
    defaultMessage: 'Apparel Knitting Mills'
  },
  '3152': {
    id: 'naics-3152',
    defaultMessage: 'Cut and Sew Apparel Manufacturing'
  },
  '3159': {
    id: 'naics-3159',
    defaultMessage: 'Apparel Accessories and Other Apparel Manufacturing'
  },
  '3161': {
    id: 'naics-3161',
    defaultMessage: 'Leather and Hide Tanning and Finishing'
  },
  '3162': {
    id: 'naics-3162',
    defaultMessage: 'Footwear Manufacturing'
  },
  '3169': {
    id: 'naics-3169',
    defaultMessage: 'Other Leather and Allied Product Manufacturing'
  },
  '3211': {
    id: 'naics-3211',
    defaultMessage: 'Sawmills and Wood Preservation'
  },
  '3212': {
    id: 'naics-3212',
    defaultMessage: 'Veneer, Plywood, and Engineered Wood Product Manufacturing'
  },
  '3219': {
    id: 'naics-3219',
    defaultMessage: 'Other Wood Product Manufacturing'
  },
  '3221': {
    id: 'naics-3221',
    defaultMessage: 'Pulp, Paper, and Paperboard Mills'
  },
  '3222': {
    id: 'naics-3222',
    defaultMessage: 'Converted Paper Product Manufacturing'
  },
  '3231': {
    id: 'naics-3231',
    defaultMessage: 'Printing and Related Support Activities'
  },
  '3241': {
    id: 'naics-3241',
    defaultMessage: 'Petroleum and Coal Products Manufacturing'
  },
  '3251': {
    id: 'naics-3251',
    defaultMessage: 'Basic Chemical Manufacturing'
  },
  '3252': {
    id: 'naics-3252',
    defaultMessage:
      'Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing'
  },
  '3253': {
    id: 'naics-3253',
    defaultMessage:
      'Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing'
  },
  '3254': {
    id: 'naics-3254',
    defaultMessage: 'Pharmaceutical and Medicine Manufacturing'
  },
  '3255': {
    id: 'naics-3255',
    defaultMessage: 'Paint, Coating, and Adhesive Manufacturing'
  },
  '3256': {
    id: 'naics-3256',
    defaultMessage:
      'Soap, Cleaning Compound, and Toilet Preparation Manufacturing'
  },
  '3259': {
    id: 'naics-3259',
    defaultMessage: 'Other Chemical Product and Preparation Manufacturing'
  },
  '3261': {
    id: 'naics-3261',
    defaultMessage: 'Plastics Product Manufacturing'
  },
  '3262': {
    id: 'naics-3262',
    defaultMessage: 'Rubber Product Manufacturing'
  },
  '3271': {
    id: 'naics-3271',
    defaultMessage: 'Clay Product and Refractory Manufacturing'
  },
  '3272': {
    id: 'naics-3272',
    defaultMessage: 'Glass and Glass Product Manufacturing'
  },
  '3273': {
    id: 'naics-3273',
    defaultMessage: 'Cement and Concrete Product Manufacturing'
  },
  '3274': {
    id: 'naics-3274',
    defaultMessage: 'Lime and Gypsum Product Manufacturing'
  },
  '3279': {
    id: 'naics-3279',
    defaultMessage: 'Other Nonmetallic Mineral Product Manufacturing'
  },
  '3311': {
    id: 'naics-3311',
    defaultMessage: 'Iron and Steel Mills and Ferroalloy Manufacturing'
  },
  '3312': {
    id: 'naics-3312',
    defaultMessage: 'Steel Product Manufacturing from Purchased Steel'
  },
  '3313': {
    id: 'naics-3313',
    defaultMessage: 'Alumina and Aluminum Production and Processing'
  },
  '3314': {
    id: 'naics-3314',
    defaultMessage:
      'Nonferrous Metal (except Aluminum) Production and Processing'
  },
  '3315': {
    id: 'naics-3315',
    defaultMessage: 'Foundries'
  },
  '3321': {
    id: 'naics-3321',
    defaultMessage: 'Forging and Stamping'
  },
  '3322': {
    id: 'naics-3322',
    defaultMessage: 'Cutlery and Handtool Manufacturing'
  },
  '3323': {
    id: 'naics-3323',
    defaultMessage: 'Architectural and Structural Metals Manufacturing'
  },
  '3324': {
    id: 'naics-3324',
    defaultMessage: 'Boiler, Tank, and Shipping Container Manufacturing'
  },
  '3325': {
    id: 'naics-3325',
    defaultMessage: 'Hardware Manufacturing'
  },
  '3326': {
    id: 'naics-3326',
    defaultMessage: 'Spring and Wire Product Manufacturing'
  },
  '3327': {
    id: 'naics-3327',
    defaultMessage:
      'Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing'
  },
  '3328': {
    id: 'naics-3328',
    defaultMessage: 'Coating, Engraving, Heat Treating, and Allied Activities'
  },
  '3329': {
    id: 'naics-3329',
    defaultMessage: 'Other Fabricated Metal Product Manufacturing'
  },
  '3331': {
    id: 'naics-3331',
    defaultMessage:
      'Agriculture, Construction, and Mining Machinery Manufacturing'
  },
  '3332': {
    id: 'naics-3332',
    defaultMessage: 'Industrial Machinery Manufacturing'
  },
  '3333': {
    id: 'naics-3333',
    defaultMessage: 'Commercial and Service Industry Machinery Manufacturing'
  },
  '3334': {
    id: 'naics-3334',
    defaultMessage:
      'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing'
  },
  '3335': {
    id: 'naics-3335',
    defaultMessage: 'Metalworking Machinery Manufacturing'
  },
  '3336': {
    id: 'naics-3336',
    defaultMessage:
      'Engine, Turbine, and Power Transmission Equipment Manufacturing'
  },
  '3339': {
    id: 'naics-3339',
    defaultMessage: 'Other General Purpose Machinery Manufacturing'
  },
  '3341': {
    id: 'naics-3341',
    defaultMessage: 'Computer and Peripheral Equipment Manufacturing'
  },
  '3342': {
    id: 'naics-3342',
    defaultMessage: 'Communications Equipment Manufacturing'
  },
  '3343': {
    id: 'naics-3343',
    defaultMessage: 'Audio and Video Equipment Manufacturing'
  },
  '3344': {
    id: 'naics-3344',
    defaultMessage: 'Semiconductor and Other Electronic Component Manufacturing'
  },
  '3345': {
    id: 'naics-3345',
    defaultMessage:
      'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing'
  },
  '3346': {
    id: 'naics-3346',
    defaultMessage: 'Manufacturing and Reproducing Magnetic and Optical Media'
  },
  '3351': {
    id: 'naics-3351',
    defaultMessage: 'Electric Lighting Equipment Manufacturing'
  },
  '3352': {
    id: 'naics-3352',
    defaultMessage: 'Household Appliance Manufacturing'
  },
  '3353': {
    id: 'naics-3353',
    defaultMessage: 'Electrical Equipment Manufacturing'
  },
  '3359': {
    id: 'naics-3359',
    defaultMessage: 'Other Electrical Equipment and Component Manufacturing'
  },
  '3361': {
    id: 'naics-3361',
    defaultMessage: 'Motor Vehicle Manufacturing'
  },
  '3362': {
    id: 'naics-3362',
    defaultMessage: 'Motor Vehicle Body and Trailer Manufacturing'
  },
  '3363': {
    id: 'naics-3363',
    defaultMessage: 'Motor Vehicle Parts Manufacturing'
  },
  '3364': {
    id: 'naics-3364',
    defaultMessage: 'Aerospace Product and Parts Manufacturing'
  },
  '3365': {
    id: 'naics-3365',
    defaultMessage: 'Railroad Rolling Stock Manufacturing'
  },
  '3366': {
    id: 'naics-3366',
    defaultMessage: 'Ship and Boat Building'
  },
  '3369': {
    id: 'naics-3369',
    defaultMessage: 'Other Transportation Equipment Manufacturing'
  },
  '3371': {
    id: 'naics-3371',
    defaultMessage:
      'Household and Institutional Furniture and Kitchen Cabinet Manufacturing'
  },
  '3372': {
    id: 'naics-3372',
    defaultMessage: 'Office Furniture (including Fixtures) Manufacturing'
  },
  '3379': {
    id: 'naics-3379',
    defaultMessage: 'Other Furniture Related Product Manufacturing'
  },
  '3391': {
    id: 'naics-3391',
    defaultMessage: 'Medical Equipment and Supplies Manufacturing'
  },
  '3399': {
    id: 'naics-3399',
    defaultMessage: 'Other Miscellaneous Manufacturing'
  },
  '4231': {
    id: 'naics-4231',
    defaultMessage:
      'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers'
  },
  '4232': {
    id: 'naics-4232',
    defaultMessage: 'Furniture and Home Furnishing Merchant Wholesalers'
  },
  '4233': {
    id: 'naics-4233',
    defaultMessage:
      'Lumber and Other Construction Materials Merchant Wholesalers'
  },
  '4234': {
    id: 'naics-4234',
    defaultMessage:
      'Professional and Commercial Equipment and Supplies Merchant Wholesalers'
  },
  '4235': {
    id: 'naics-4235',
    defaultMessage: 'Metal and Mineral (except Petroleum) Merchant Wholesalers'
  },
  '4236': {
    id: 'naics-4236',
    defaultMessage:
      'Household Appliances and Electrical and Electronic Goods Merchant Wholesalers'
  },
  '4237': {
    id: 'naics-4237',
    defaultMessage:
      'Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers'
  },
  '4238': {
    id: 'naics-4238',
    defaultMessage: 'Machinery, Equipment, and Supplies Merchant Wholesalers'
  },
  '4239': {
    id: 'naics-4239',
    defaultMessage: 'Miscellaneous Durable Goods Merchant Wholesalers'
  },
  '4241': {
    id: 'naics-4241',
    defaultMessage: 'Paper and Paper Product Merchant Wholesalers'
  },
  '4242': {
    id: 'naics-4242',
    defaultMessage: "Drugs and Druggists' Sundries Merchant Wholesalers"
  },
  '4243': {
    id: 'naics-4243',
    defaultMessage: 'Apparel, Piece Goods, and Notions Merchant Wholesalers'
  },
  '4244': {
    id: 'naics-4244',
    defaultMessage: 'Grocery and Related Product Merchant Wholesalers'
  },
  '4245': {
    id: 'naics-4245',
    defaultMessage: 'Farm Product Raw Material Merchant Wholesalers'
  },
  '4246': {
    id: 'naics-4246',
    defaultMessage: 'Chemical and Allied Products Merchant Wholesalers'
  },
  '4247': {
    id: 'naics-4247',
    defaultMessage: 'Petroleum and Petroleum Products Merchant Wholesalers'
  },
  '4248': {
    id: 'naics-4248',
    defaultMessage:
      'Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers'
  },
  '4249': {
    id: 'naics-4249',
    defaultMessage: 'Miscellaneous Nondurable Goods Merchant Wholesalers'
  },
  '4251': {
    id: 'naics-4251',
    defaultMessage: 'Wholesale Electronic Markets and Agents and Brokers'
  },
  '4411': {
    id: 'naics-4411',
    defaultMessage: 'Automobile Dealers'
  },
  '4412': {
    id: 'naics-4412',
    defaultMessage: 'Other Motor Vehicle Dealers'
  },
  '4413': {
    id: 'naics-4413',
    defaultMessage: 'Automotive Parts, Accessories, and Tire Stores'
  },
  '4421': {
    id: 'naics-4421',
    defaultMessage: 'Furniture Stores'
  },
  '4422': {
    id: 'naics-4422',
    defaultMessage: 'Home Furnishings Stores'
  },
  '4431': {
    id: 'naics-4431',
    defaultMessage: 'Electronics and Appliance Stores'
  },
  '4441': {
    id: 'naics-4441',
    defaultMessage: 'Building Material and Supplies Dealers'
  },
  '4442': {
    id: 'naics-4442',
    defaultMessage: 'Lawn and Garden Equipment and Supplies Stores'
  },
  '4451': {
    id: 'naics-4451',
    defaultMessage: 'Grocery Stores'
  },
  '4452': {
    id: 'naics-4452',
    defaultMessage: 'Specialty Food Stores'
  },
  '4453': {
    id: 'naics-4453',
    defaultMessage: 'Beer, Wine, and Liquor Stores'
  },
  '4461': {
    id: 'naics-4461',
    defaultMessage: 'Health and Personal Care Stores'
  },
  '4471': {
    id: 'naics-4471',
    defaultMessage: 'Gasoline Stations'
  },
  '4481': {
    id: 'naics-4481',
    defaultMessage: 'Clothing Stores'
  },
  '4482': {
    id: 'naics-4482',
    defaultMessage: 'Shoe Stores'
  },
  '4483': {
    id: 'naics-4483',
    defaultMessage: 'Jewelry, Luggage, and Leather Goods Stores'
  },
  '4511': {
    id: 'naics-4511',
    defaultMessage: 'Sporting Goods, Hobby, and Musical Instrument Stores'
  },
  '4512': {
    id: 'naics-4512',
    defaultMessage: 'Book Stores and News Dealers'
  },
  '4522': {
    id: 'naics-4522',
    defaultMessage: 'Department Stores'
  },
  '4523': {
    id: 'naics-4523',
    defaultMessage:
      'General Merchandise Stores, including Warehouse Clubs and Supercenters'
  },
  '4531': {
    id: 'naics-4531',
    defaultMessage: 'Florists'
  },
  '4532': {
    id: 'naics-4532',
    defaultMessage: 'Office Supplies, Stationery, and Gift Stores'
  },
  '4533': {
    id: 'naics-4533',
    defaultMessage: 'Used Merchandise Stores'
  },
  '4539': {
    id: 'naics-4539',
    defaultMessage: 'Other Miscellaneous Store Retailers'
  },
  '4541': {
    id: 'naics-4541',
    defaultMessage: 'Electronic Shopping and Mail-Order Houses'
  },
  '4542': {
    id: 'naics-4542',
    defaultMessage: 'Vending Machine Operators'
  },
  '4543': {
    id: 'naics-4543',
    defaultMessage: 'Direct Selling Establishments'
  },
  '4811': {
    id: 'naics-4811',
    defaultMessage: 'Scheduled Air Transportation'
  },
  '4812': {
    id: 'naics-4812',
    defaultMessage: 'Nonscheduled Air Transportation'
  },
  '4821': {
    id: 'naics-4821',
    defaultMessage: 'Rail Transportation'
  },
  '4831': {
    id: 'naics-4831',
    defaultMessage: 'Deep Sea, Coastal, and Great Lakes Water Transportation'
  },
  '4832': {
    id: 'naics-4832',
    defaultMessage: 'Inland Water Transportation'
  },
  '4841': {
    id: 'naics-4841',
    defaultMessage: 'General Freight Trucking'
  },
  '4842': {
    id: 'naics-4842',
    defaultMessage: 'Specialized Freight Trucking'
  },
  '4851': {
    id: 'naics-4851',
    defaultMessage: 'Urban Transit Systems'
  },
  '4852': {
    id: 'naics-4852',
    defaultMessage: 'Interurban and Rural Bus Transportation'
  },
  '4853': {
    id: 'naics-4853',
    defaultMessage: 'Taxi and Limousine Service'
  },
  '4854': {
    id: 'naics-4854',
    defaultMessage: 'School and Employee Bus Transportation'
  },
  '4855': {
    id: 'naics-4855',
    defaultMessage: 'Charter Bus Industry'
  },
  '4859': {
    id: 'naics-4859',
    defaultMessage: 'Other Transit and Ground Passenger Transportation'
  },
  '4861': {
    id: 'naics-4861',
    defaultMessage: 'Pipeline Transportation of Crude Oil'
  },
  '4862': {
    id: 'naics-4862',
    defaultMessage: 'Pipeline Transportation of Natural Gas'
  },
  '4869': {
    id: 'naics-4869',
    defaultMessage: 'Other Pipeline Transportation'
  },
  '4871': {
    id: 'naics-4871',
    defaultMessage: 'Scenic and Sightseeing Transportation, Land'
  },
  '4872': {
    id: 'naics-4872',
    defaultMessage: 'Scenic and Sightseeing Transportation, Water'
  },
  '4879': {
    id: 'naics-4879',
    defaultMessage: 'Scenic and Sightseeing Transportation, Other'
  },
  '4881': {
    id: 'naics-4881',
    defaultMessage: 'Support Activities for Air Transportation'
  },
  '4882': {
    id: 'naics-4882',
    defaultMessage: 'Support Activities for Rail Transportation'
  },
  '4883': {
    id: 'naics-4883',
    defaultMessage: 'Support Activities for Water Transportation'
  },
  '4884': {
    id: 'naics-4884',
    defaultMessage: 'Support Activities for Road Transportation'
  },
  '4885': {
    id: 'naics-4885',
    defaultMessage: 'Freight Transportation Arrangement'
  },
  '4889': {
    id: 'naics-4889',
    defaultMessage: 'Other Support Activities for Transportation'
  },
  '4911': {
    id: 'naics-4911',
    defaultMessage: 'Postal Service'
  },
  '4921': {
    id: 'naics-4921',
    defaultMessage: 'Couriers and Express Delivery Services'
  },
  '4922': {
    id: 'naics-4922',
    defaultMessage: 'Local Messengers and Local Delivery'
  },
  '4931': {
    id: 'naics-4931',
    defaultMessage: 'Warehousing and Storage'
  },
  '5111': {
    id: 'naics-5111',
    defaultMessage: 'Newspaper, Periodical, Book, and Directory Publishers'
  },
  '5112': {
    id: 'naics-5112',
    defaultMessage: 'Software Publishers'
  },
  '5121': {
    id: 'naics-5121',
    defaultMessage: 'Motion Picture and Video Industries'
  },
  '5122': {
    id: 'naics-5122',
    defaultMessage: 'Sound Recording Industries'
  },
  '5151': {
    id: 'naics-5151',
    defaultMessage: 'Radio and Television Broadcasting'
  },
  '5152': {
    id: 'naics-5152',
    defaultMessage: 'Cable and Other Subscription Programming'
  },
  '5173': {
    id: 'naics-5173',
    defaultMessage: 'Wired and Wireless Telecommunications Carriers'
  },
  '5174': {
    id: 'naics-5174',
    defaultMessage: 'Satellite Telecommunications'
  },
  '5179': {
    id: 'naics-5179',
    defaultMessage: 'Other Telecommunications'
  },
  '5182': {
    id: 'naics-5182',
    defaultMessage: 'Data Processing, Hosting, and Related Services'
  },
  '5191': {
    id: 'naics-5191',
    defaultMessage: 'Other Information Services'
  },
  '5211': {
    id: 'naics-5211',
    defaultMessage: 'Monetary Authorities-Central Bank'
  },
  '5221': {
    id: 'naics-5221',
    defaultMessage: 'Depository Credit Intermediation'
  },
  '5222': {
    id: 'naics-5222',
    defaultMessage: 'Nondepository Credit Intermediation'
  },
  '5223': {
    id: 'naics-5223',
    defaultMessage: 'Activities Related to Credit Intermediation'
  },
  '5231': {
    id: 'naics-5231',
    defaultMessage:
      'Securities and Commodity Contracts Intermediation and Brokerage'
  },
  '5232': {
    id: 'naics-5232',
    defaultMessage: 'Securities and Commodity Exchanges'
  },
  '5239': {
    id: 'naics-5239',
    defaultMessage: 'Other Financial Investment Activities'
  },
  '5241': {
    id: 'naics-5241',
    defaultMessage: 'Insurance Carriers'
  },
  '5242': {
    id: 'naics-5242',
    defaultMessage:
      'Agencies, Brokerages, and Other Insurance Related Activities'
  },
  '5251': {
    id: 'naics-5251',
    defaultMessage: 'Insurance and Employee Benefit Funds'
  },
  '5259': {
    id: 'naics-5259',
    defaultMessage: 'Other Investment Pools and Funds'
  },
  '5311': {
    id: 'naics-5311',
    defaultMessage: 'Lessors of Real Estate'
  },
  '5312': {
    id: 'naics-5312',
    defaultMessage: 'Offices of Real Estate Agents and Brokers'
  },
  '5313': {
    id: 'naics-5313',
    defaultMessage: 'Activities Related to Real Estate'
  },
  '5321': {
    id: 'naics-5321',
    defaultMessage: 'Automotive Equipment Rental and Leasing'
  },
  '5322': {
    id: 'naics-5322',
    defaultMessage: 'Consumer Goods Rental'
  },
  '5323': {
    id: 'naics-5323',
    defaultMessage: 'General Rental Centers'
  },
  '5324': {
    id: 'naics-5324',
    defaultMessage:
      'Commercial and Industrial Machinery and Equipment Rental and Leasing'
  },
  '5331': {
    id: 'naics-5331',
    defaultMessage:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)'
  },
  '5411': {
    id: 'naics-5411',
    defaultMessage: 'Legal Services'
  },
  '5412': {
    id: 'naics-5412',
    defaultMessage:
      'Accounting, Tax Preparation, Bookkeeping, and Payroll Services'
  },
  '5413': {
    id: 'naics-5413',
    defaultMessage: 'Architectural, Engineering, and Related Services'
  },
  '5414': {
    id: 'naics-5414',
    defaultMessage: 'Specialized Design Services'
  },
  '5415': {
    id: 'naics-5415',
    defaultMessage: 'Computer Systems Design and Related Services'
  },
  '5416': {
    id: 'naics-5416',
    defaultMessage: 'Management, Scientific, and Technical Consulting Services'
  },
  '5417': {
    id: 'naics-5417',
    defaultMessage: 'Scientific Research and Development Services'
  },
  '5418': {
    id: 'naics-5418',
    defaultMessage: 'Advertising, Public Relations, and Related Services'
  },
  '5419': {
    id: 'naics-5419',
    defaultMessage: 'Other Professional, Scientific, and Technical Services'
  },
  '5511': {
    id: 'naics-5511',
    defaultMessage: 'Management of Companies and Enterprises'
  },
  '5611': {
    id: 'naics-5611',
    defaultMessage: 'Office Administrative Services'
  },
  '5612': {
    id: 'naics-5612',
    defaultMessage: 'Facilities Support Services'
  },
  '5613': {
    id: 'naics-5613',
    defaultMessage: 'Employment Services'
  },
  '5614': {
    id: 'naics-5614',
    defaultMessage: 'Business Support Services'
  },
  '5615': {
    id: 'naics-5615',
    defaultMessage: 'Travel Arrangement and Reservation Services'
  },
  '5616': {
    id: 'naics-5616',
    defaultMessage: 'Investigation and Security Services'
  },
  '5617': {
    id: 'naics-5617',
    defaultMessage: 'Services to Buildings and Dwellings'
  },
  '5619': {
    id: 'naics-5619',
    defaultMessage: 'Other Support Services'
  },
  '5621': {
    id: 'naics-5621',
    defaultMessage: 'Waste Collection'
  },
  '5622': {
    id: 'naics-5622',
    defaultMessage: 'Waste Treatment and Disposal'
  },
  '5629': {
    id: 'naics-5629',
    defaultMessage: 'Remediation and Other Waste Management Services'
  },
  '6111': {
    id: 'naics-6111',
    defaultMessage: 'Elementary and Secondary Schools'
  },
  '6112': {
    id: 'naics-6112',
    defaultMessage: 'Junior Colleges'
  },
  '6113': {
    id: 'naics-6113',
    defaultMessage: 'Colleges, Universities, and Professional Schools'
  },
  '6114': {
    id: 'naics-6114',
    defaultMessage: 'Business Schools and Computer and Management Training'
  },
  '6115': {
    id: 'naics-6115',
    defaultMessage: 'Technical and Trade Schools'
  },
  '6116': {
    id: 'naics-6116',
    defaultMessage: 'Other Schools and Instruction'
  },
  '6117': {
    id: 'naics-6117',
    defaultMessage: 'Educational Support Services'
  },
  '6211': {
    id: 'naics-6211',
    defaultMessage: 'Offices of Physicians'
  },
  '6212': {
    id: 'naics-6212',
    defaultMessage: 'Offices of Dentists'
  },
  '6213': {
    id: 'naics-6213',
    defaultMessage: 'Offices of Other Health Practitioners'
  },
  '6214': {
    id: 'naics-6214',
    defaultMessage: 'Outpatient Care Centers'
  },
  '6215': {
    id: 'naics-6215',
    defaultMessage: 'Medical and Diagnostic Laboratories'
  },
  '6216': {
    id: 'naics-6216',
    defaultMessage: 'Home Health Care Services'
  },
  '6219': {
    id: 'naics-6219',
    defaultMessage: 'Other Ambulatory Health Care Services'
  },
  '6221': {
    id: 'naics-6221',
    defaultMessage: 'General Medical and Surgical Hospitals'
  },
  '6222': {
    id: 'naics-6222',
    defaultMessage: 'Psychiatric and Substance Abuse Hospitals'
  },
  '6223': {
    id: 'naics-6223',
    defaultMessage:
      'Specialty (except Psychiatric and Substance Abuse) Hospitals'
  },
  '6231': {
    id: 'naics-6231',
    defaultMessage: 'Nursing Care Facilities (Skilled Nursing Facilities)'
  },
  '6232': {
    id: 'naics-6232',
    defaultMessage:
      'Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities'
  },
  '6233': {
    id: 'naics-6233',
    defaultMessage:
      'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly'
  },
  '6239': {
    id: 'naics-6239',
    defaultMessage: 'Other Residential Care Facilities'
  },
  '6241': {
    id: 'naics-6241',
    defaultMessage: 'Individual and Family Services'
  },
  '6242': {
    id: 'naics-6242',
    defaultMessage:
      'Community Food and Housing, and Emergency and Other Relief Services'
  },
  '6243': {
    id: 'naics-6243',
    defaultMessage: 'Vocational Rehabilitation Services'
  },
  '6244': {
    id: 'naics-6244',
    defaultMessage: 'Child Day Care Services'
  },
  '7111': {
    id: 'naics-7111',
    defaultMessage: 'Performing Arts Companies'
  },
  '7112': {
    id: 'naics-7112',
    defaultMessage: 'Spectator Sports'
  },
  '7113': {
    id: 'naics-7113',
    defaultMessage: 'Promoters of Performing Arts, Sports, and Similar Events'
  },
  '7114': {
    id: 'naics-7114',
    defaultMessage:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures'
  },
  '7115': {
    id: 'naics-7115',
    defaultMessage: 'Independent Artists, Writers, and Performers'
  },
  '7121': {
    id: 'naics-7121',
    defaultMessage: 'Museums, Historical Sites, and Similar Institutions'
  },
  '7131': {
    id: 'naics-7131',
    defaultMessage: 'Amusement Parks and Arcades'
  },
  '7132': {
    id: 'naics-7132',
    defaultMessage: 'Gambling Industries'
  },
  '7139': {
    id: 'naics-7139',
    defaultMessage: 'Other Amusement and Recreation Industries'
  },
  '7211': {
    id: 'naics-7211',
    defaultMessage: 'Traveler Accommodation'
  },
  '7212': {
    id: 'naics-7212',
    defaultMessage: 'RV (Recreational Vehicle) Parks and Recreational Camps'
  },
  '7213': {
    id: 'naics-7213',
    defaultMessage:
      "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
  },
  '7223': {
    id: 'naics-7223',
    defaultMessage: 'Special Food Services'
  },
  '7224': {
    id: 'naics-7224',
    defaultMessage: 'Drinking Places (Alcoholic Beverages)'
  },
  '7225': {
    id: 'naics-7225',
    defaultMessage: 'Restaurants and Other Eating Places'
  },
  '8111': {
    id: 'naics-8111',
    defaultMessage: 'Automotive Repair and Maintenance'
  },
  '8112': {
    id: 'naics-8112',
    defaultMessage: 'Electronic and Precision Equipment Repair and Maintenance'
  },
  '8113': {
    id: 'naics-8113',
    defaultMessage:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance'
  },
  '8114': {
    id: 'naics-8114',
    defaultMessage: 'Personal and Household Goods Repair and Maintenance'
  },
  '8121': {
    id: 'naics-8121',
    defaultMessage: 'Personal Care Services'
  },
  '8122': {
    id: 'naics-8122',
    defaultMessage: 'Death Care Services'
  },
  '8123': {
    id: 'naics-8123',
    defaultMessage: 'Drycleaning and Laundry Services'
  },
  '8129': {
    id: 'naics-8129',
    defaultMessage: 'Other Personal Services'
  },
  '8131': {
    id: 'naics-8131',
    defaultMessage: 'Religious Organizations'
  },
  '8132': {
    id: 'naics-8132',
    defaultMessage: 'Grantmaking and Giving Services'
  },
  '8133': {
    id: 'naics-8133',
    defaultMessage: 'Social Advocacy Organizations'
  },
  '8134': {
    id: 'naics-8134',
    defaultMessage: 'Civic and Social Organizations'
  },
  '8139': {
    id: 'naics-8139',
    defaultMessage:
      'Business, Professional, Labor, Political, and Similar Organizations'
  },
  '8141': {
    id: 'naics-8141',
    defaultMessage: 'Private Households'
  },
  '9211': {
    id: 'naics-9211',
    defaultMessage:
      'Executive, Legislative, and Other General Government Support'
  },
  '9221': {
    id: 'naics-9221',
    defaultMessage: 'Justice, Public Order, and Safety Activities'
  },
  '9231': {
    id: 'naics-9231',
    defaultMessage: 'Administration of Human Resource Programs'
  },
  '9241': {
    id: 'naics-9241',
    defaultMessage: 'Administration of Environmental Quality Programs'
  },
  '9251': {
    id: 'naics-9251',
    defaultMessage:
      'Administration of Housing Programs, Urban Planning, and Community Development'
  },
  '9261': {
    id: 'naics-9261',
    defaultMessage: 'Administration of Economic Programs'
  },
  '9271': {
    id: 'naics-9271',
    defaultMessage: 'Space Research and Technology'
  },
  '9281': {
    id: 'naics-9281',
    defaultMessage: 'National Security and International Affairs'
  },
  '11111': {
    id: 'naics-11111',
    defaultMessage: 'Soybean Farming'
  },
  '11112': {
    id: 'naics-11112',
    defaultMessage: 'Oilseed (except Soybean) Farming'
  },
  '11113': {
    id: 'naics-11113',
    defaultMessage: 'Dry Pea and Bean Farming'
  },
  '11114': {
    id: 'naics-11114',
    defaultMessage: 'Wheat Farming'
  },
  '11115': {
    id: 'naics-11115',
    defaultMessage: 'Corn Farming'
  },
  '11116': {
    id: 'naics-11116',
    defaultMessage: 'Rice Farming'
  },
  '11119': {
    id: 'naics-11119',
    defaultMessage: 'Other Grain Farming'
  },
  '11121': {
    id: 'naics-11121',
    defaultMessage: 'Vegetable and Melon Farming'
  },
  '11131': {
    id: 'naics-11131',
    defaultMessage: 'Orange Groves'
  },
  '11132': {
    id: 'naics-11132',
    defaultMessage: 'Citrus (except Orange) Groves'
  },
  '11133': {
    id: 'naics-11133',
    defaultMessage: 'Noncitrus Fruit and Tree Nut Farming'
  },
  '11141': {
    id: 'naics-11141',
    defaultMessage: 'Food Crops Grown Under Cover'
  },
  '11142': {
    id: 'naics-11142',
    defaultMessage: 'Nursery and Floriculture Production'
  },
  '11191': {
    id: 'naics-11191',
    defaultMessage: 'Tobacco Farming'
  },
  '11192': {
    id: 'naics-11192',
    defaultMessage: 'Cotton Farming'
  },
  '11193': {
    id: 'naics-11193',
    defaultMessage: 'Sugarcane Farming'
  },
  '11194': {
    id: 'naics-11194',
    defaultMessage: 'Hay Farming'
  },
  '11199': {
    id: 'naics-11199',
    defaultMessage: 'All Other Crop Farming'
  },
  '11211': {
    id: 'naics-11211',
    defaultMessage: 'Beef Cattle Ranching and Farming, including Feedlots'
  },
  '11212': {
    id: 'naics-11212',
    defaultMessage: 'Dairy Cattle and Milk Production'
  },
  '11213': {
    id: 'naics-11213',
    defaultMessage: 'Dual-Purpose Cattle Ranching and Farming'
  },
  '11221': {
    id: 'naics-11221',
    defaultMessage: 'Hog and Pig Farming'
  },
  '11231': {
    id: 'naics-11231',
    defaultMessage: 'Chicken Egg Production'
  },
  '11232': {
    id: 'naics-11232',
    defaultMessage: 'Broilers and Other Meat Type Chicken Production'
  },
  '11233': {
    id: 'naics-11233',
    defaultMessage: 'Turkey Production'
  },
  '11234': {
    id: 'naics-11234',
    defaultMessage: 'Poultry Hatcheries'
  },
  '11239': {
    id: 'naics-11239',
    defaultMessage: 'Other Poultry Production'
  },
  '11241': {
    id: 'naics-11241',
    defaultMessage: 'Sheep Farming'
  },
  '11242': {
    id: 'naics-11242',
    defaultMessage: 'Goat Farming'
  },
  '11251': {
    id: 'naics-11251',
    defaultMessage: 'Aquaculture'
  },
  '11291': {
    id: 'naics-11291',
    defaultMessage: 'Apiculture'
  },
  '11292': {
    id: 'naics-11292',
    defaultMessage: 'Horses and Other Equine Production'
  },
  '11293': {
    id: 'naics-11293',
    defaultMessage: 'Fur-Bearing Animal and Rabbit Production'
  },
  '11299': {
    id: 'naics-11299',
    defaultMessage: 'All Other Animal Production'
  },
  '11311': {
    id: 'naics-11311',
    defaultMessage: 'Timber Tract Operations'
  },
  '11321': {
    id: 'naics-11321',
    defaultMessage: 'Forest Nurseries and Gathering of Forest Products'
  },
  '11331': {
    id: 'naics-11331',
    defaultMessage: 'Logging'
  },
  '11411': {
    id: 'naics-11411',
    defaultMessage: 'Fishing'
  },
  '11421': {
    id: 'naics-11421',
    defaultMessage: 'Hunting and Trapping'
  },
  '11511': {
    id: 'naics-11511',
    defaultMessage: 'Support Activities for Crop Production'
  },
  '11521': {
    id: 'naics-11521',
    defaultMessage: 'Support Activities for Animal Production'
  },
  '11531': {
    id: 'naics-11531',
    defaultMessage: 'Support Activities for Forestry'
  },
  '21112': {
    id: 'naics-21112',
    defaultMessage: 'Crude Petroleum Extraction'
  },
  '21113': {
    id: 'naics-21113',
    defaultMessage: 'Natural Gas Extraction'
  },
  '21211': {
    id: 'naics-21211',
    defaultMessage: 'Coal Mining'
  },
  '21221': {
    id: 'naics-21221',
    defaultMessage: 'Iron Ore Mining'
  },
  '21222': {
    id: 'naics-21222',
    defaultMessage: 'Gold Ore and Silver Ore Mining'
  },
  '21223': {
    id: 'naics-21223',
    defaultMessage: 'Copper, Nickel, Lead, and Zinc Mining'
  },
  '21229': {
    id: 'naics-21229',
    defaultMessage: 'Other Metal Ore Mining'
  },
  '21231': {
    id: 'naics-21231',
    defaultMessage: 'Stone Mining and Quarrying'
  },
  '21232': {
    id: 'naics-21232',
    defaultMessage:
      'Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying'
  },
  '21239': {
    id: 'naics-21239',
    defaultMessage: 'Other Nonmetallic Mineral Mining and Quarrying'
  },
  '21311': {
    id: 'naics-21311',
    defaultMessage: 'Support Activities for Mining'
  },
  '22111': {
    id: 'naics-22111',
    defaultMessage: 'Electric Power Generation'
  },
  '22112': {
    id: 'naics-22112',
    defaultMessage: 'Electric Power Transmission, Control, and Distribution'
  },
  '22121': {
    id: 'naics-22121',
    defaultMessage: 'Natural Gas Distribution'
  },
  '22131': {
    id: 'naics-22131',
    defaultMessage: 'Water Supply and Irrigation Systems'
  },
  '22132': {
    id: 'naics-22132',
    defaultMessage: 'Sewage Treatment Facilities'
  },
  '22133': {
    id: 'naics-22133',
    defaultMessage: 'Steam and Air-Conditioning Supply'
  },
  '23611': {
    id: 'naics-23611',
    defaultMessage: 'Residential Building Construction'
  },
  '23621': {
    id: 'naics-23621',
    defaultMessage: 'Industrial Building Construction'
  },
  '23622': {
    id: 'naics-23622',
    defaultMessage: 'Commercial and Institutional Building Construction'
  },
  '23711': {
    id: 'naics-23711',
    defaultMessage: 'Water and Sewer Line and Related Structures Construction'
  },
  '23712': {
    id: 'naics-23712',
    defaultMessage: 'Oil and Gas Pipeline and Related Structures Construction'
  },
  '23713': {
    id: 'naics-23713',
    defaultMessage:
      'Power and Communication Line and Related Structures Construction'
  },
  '23721': {
    id: 'naics-23721',
    defaultMessage: 'Land Subdivision'
  },
  '23731': {
    id: 'naics-23731',
    defaultMessage: 'Highway, Street, and Bridge Construction'
  },
  '23799': {
    id: 'naics-23799',
    defaultMessage: 'Other Heavy and Civil Engineering Construction'
  },
  '23811': {
    id: 'naics-23811',
    defaultMessage: 'Poured Concrete Foundation and Structure Contractors'
  },
  '23812': {
    id: 'naics-23812',
    defaultMessage: 'Structural Steel and Precast Concrete Contractors'
  },
  '23813': {
    id: 'naics-23813',
    defaultMessage: 'Framing Contractors'
  },
  '23814': {
    id: 'naics-23814',
    defaultMessage: 'Masonry Contractors'
  },
  '23815': {
    id: 'naics-23815',
    defaultMessage: 'Glass and Glazing Contractors'
  },
  '23816': {
    id: 'naics-23816',
    defaultMessage: 'Roofing Contractors'
  },
  '23817': {
    id: 'naics-23817',
    defaultMessage: 'Siding Contractors'
  },
  '23819': {
    id: 'naics-23819',
    defaultMessage:
      'Other Foundation, Structure, and Building Exterior Contractors'
  },
  '23821': {
    id: 'naics-23821',
    defaultMessage:
      'Electrical Contractors and Other Wiring Installation Contractors'
  },
  '23822': {
    id: 'naics-23822',
    defaultMessage: 'Plumbing, Heating, and Air-Conditioning Contractors'
  },
  '23829': {
    id: 'naics-23829',
    defaultMessage: 'Other Building Equipment Contractors'
  },
  '23831': {
    id: 'naics-23831',
    defaultMessage: 'Drywall and Insulation Contractors'
  },
  '23832': {
    id: 'naics-23832',
    defaultMessage: 'Painting and Wall Covering Contractors'
  },
  '23833': {
    id: 'naics-23833',
    defaultMessage: 'Flooring Contractors'
  },
  '23834': {
    id: 'naics-23834',
    defaultMessage: 'Tile and Terrazzo Contractors'
  },
  '23835': {
    id: 'naics-23835',
    defaultMessage: 'Finish Carpentry Contractors'
  },
  '23839': {
    id: 'naics-23839',
    defaultMessage: 'Other Building Finishing Contractors'
  },
  '23891': {
    id: 'naics-23891',
    defaultMessage: 'Site Preparation Contractors'
  },
  '23899': {
    id: 'naics-23899',
    defaultMessage: 'All Other Specialty Trade Contractors'
  },
  '31111': {
    id: 'naics-31111',
    defaultMessage: 'Animal Food Manufacturing'
  },
  '31121': {
    id: 'naics-31121',
    defaultMessage: 'Flour Milling and Malt Manufacturing'
  },
  '31122': {
    id: 'naics-31122',
    defaultMessage: 'Starch and Vegetable Fats and Oils Manufacturing'
  },
  '31123': {
    id: 'naics-31123',
    defaultMessage: 'Breakfast Cereal Manufacturing'
  },
  '31131': {
    id: 'naics-31131',
    defaultMessage: 'Sugar Manufacturing'
  },
  '31134': {
    id: 'naics-31134',
    defaultMessage: 'Nonchocolate Confectionery Manufacturing'
  },
  '31135': {
    id: 'naics-31135',
    defaultMessage: 'Chocolate and Confectionery Manufacturing'
  },
  '31141': {
    id: 'naics-31141',
    defaultMessage: 'Frozen Food Manufacturing'
  },
  '31142': {
    id: 'naics-31142',
    defaultMessage: 'Fruit and Vegetable Canning, Pickling, and Drying'
  },
  '31151': {
    id: 'naics-31151',
    defaultMessage: 'Dairy Product (except Frozen) Manufacturing'
  },
  '31152': {
    id: 'naics-31152',
    defaultMessage: 'Ice Cream and Frozen Dessert Manufacturing'
  },
  '31161': {
    id: 'naics-31161',
    defaultMessage: 'Animal Slaughtering and Processing'
  },
  '31171': {
    id: 'naics-31171',
    defaultMessage: 'Seafood Product Preparation and Packaging'
  },
  '31181': {
    id: 'naics-31181',
    defaultMessage: 'Bread and Bakery Product Manufacturing'
  },
  '31182': {
    id: 'naics-31182',
    defaultMessage: 'Cookie, Cracker, and Pasta Manufacturing'
  },
  '31183': {
    id: 'naics-31183',
    defaultMessage: 'Tortilla Manufacturing'
  },
  '31191': {
    id: 'naics-31191',
    defaultMessage: 'Snack Food Manufacturing'
  },
  '31192': {
    id: 'naics-31192',
    defaultMessage: 'Coffee and Tea Manufacturing'
  },
  '31193': {
    id: 'naics-31193',
    defaultMessage: 'Flavoring Syrup and Concentrate Manufacturing'
  },
  '31194': {
    id: 'naics-31194',
    defaultMessage: 'Seasoning and Dressing Manufacturing'
  },
  '31199': {
    id: 'naics-31199',
    defaultMessage: 'All Other Food Manufacturing'
  },
  '31211': {
    id: 'naics-31211',
    defaultMessage: 'Soft Drink and Ice Manufacturing'
  },
  '31212': {
    id: 'naics-31212',
    defaultMessage: 'Breweries'
  },
  '31213': {
    id: 'naics-31213',
    defaultMessage: 'Wineries'
  },
  '31214': {
    id: 'naics-31214',
    defaultMessage: 'Distilleries'
  },
  '31223': {
    id: 'naics-31223',
    defaultMessage: 'Tobacco Manufacturing'
  },
  '31311': {
    id: 'naics-31311',
    defaultMessage: 'Fiber, Yarn, and Thread Mills'
  },
  '31321': {
    id: 'naics-31321',
    defaultMessage: 'Broadwoven Fabric Mills'
  },
  '31322': {
    id: 'naics-31322',
    defaultMessage: 'Narrow Fabric Mills and Schiffli Machine Embroidery'
  },
  '31323': {
    id: 'naics-31323',
    defaultMessage: 'Nonwoven Fabric Mills'
  },
  '31324': {
    id: 'naics-31324',
    defaultMessage: 'Knit Fabric Mills'
  },
  '31331': {
    id: 'naics-31331',
    defaultMessage: 'Textile and Fabric Finishing Mills'
  },
  '31332': {
    id: 'naics-31332',
    defaultMessage: 'Fabric Coating Mills'
  },
  '31411': {
    id: 'naics-31411',
    defaultMessage: 'Carpet and Rug Mills'
  },
  '31412': {
    id: 'naics-31412',
    defaultMessage: 'Curtain and Linen Mills'
  },
  '31491': {
    id: 'naics-31491',
    defaultMessage: 'Textile Bag and Canvas Mills'
  },
  '31499': {
    id: 'naics-31499',
    defaultMessage: 'All Other Textile Product Mills'
  },
  '31511': {
    id: 'naics-31511',
    defaultMessage: 'Hosiery and Sock Mills'
  },
  '31519': {
    id: 'naics-31519',
    defaultMessage: 'Other Apparel Knitting Mills'
  },
  '31521': {
    id: 'naics-31521',
    defaultMessage: 'Cut and Sew Apparel Contractors'
  },
  '31522': {
    id: 'naics-31522',
    defaultMessage: 'Men’s and Boys’ Cut and Sew Apparel Manufacturing'
  },
  '31524': {
    id: 'naics-31524',
    defaultMessage:
      'Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing'
  },
  '31528': {
    id: 'naics-31528',
    defaultMessage: 'Other Cut and Sew Apparel Manufacturing'
  },
  '31599': {
    id: 'naics-31599',
    defaultMessage: 'Apparel Accessories and Other Apparel Manufacturing'
  },
  '31611': {
    id: 'naics-31611',
    defaultMessage: 'Leather and Hide Tanning and Finishing'
  },
  '31621': {
    id: 'naics-31621',
    defaultMessage: 'Footwear Manufacturing'
  },
  '31699': {
    id: 'naics-31699',
    defaultMessage: 'Other Leather and Allied Product Manufacturing'
  },
  '32111': {
    id: 'naics-32111',
    defaultMessage: 'Sawmills and Wood Preservation'
  },
  '32121': {
    id: 'naics-32121',
    defaultMessage: 'Veneer, Plywood, and Engineered Wood Product Manufacturing'
  },
  '32191': {
    id: 'naics-32191',
    defaultMessage: 'Millwork'
  },
  '32192': {
    id: 'naics-32192',
    defaultMessage: 'Wood Container and Pallet Manufacturing'
  },
  '32199': {
    id: 'naics-32199',
    defaultMessage: 'All Other Wood Product Manufacturing'
  },
  '32211': {
    id: 'naics-32211',
    defaultMessage: 'Pulp Mills'
  },
  '32212': {
    id: 'naics-32212',
    defaultMessage: 'Paper Mills'
  },
  '32213': {
    id: 'naics-32213',
    defaultMessage: 'Paperboard Mills'
  },
  '32221': {
    id: 'naics-32221',
    defaultMessage: 'Paperboard Container Manufacturing'
  },
  '32222': {
    id: 'naics-32222',
    defaultMessage: 'Paper Bag and Coated and Treated Paper Manufacturing'
  },
  '32223': {
    id: 'naics-32223',
    defaultMessage: 'Stationery Product Manufacturing'
  },
  '32229': {
    id: 'naics-32229',
    defaultMessage: 'Other Converted Paper Product Manufacturing'
  },
  '32311': {
    id: 'naics-32311',
    defaultMessage: 'Printing'
  },
  '32312': {
    id: 'naics-32312',
    defaultMessage: 'Support Activities for Printing'
  },
  '32411': {
    id: 'naics-32411',
    defaultMessage: 'Petroleum Refineries'
  },
  '32412': {
    id: 'naics-32412',
    defaultMessage:
      'Asphalt Paving, Roofing, and Saturated Materials Manufacturing'
  },
  '32419': {
    id: 'naics-32419',
    defaultMessage: 'Other Petroleum and Coal Products Manufacturing'
  },
  '32511': {
    id: 'naics-32511',
    defaultMessage: 'Petrochemical Manufacturing'
  },
  '32512': {
    id: 'naics-32512',
    defaultMessage: 'Industrial Gas Manufacturing'
  },
  '32513': {
    id: 'naics-32513',
    defaultMessage: 'Synthetic Dye and Pigment Manufacturing'
  },
  '32518': {
    id: 'naics-32518',
    defaultMessage: 'Other Basic Inorganic Chemical Manufacturing'
  },
  '32519': {
    id: 'naics-32519',
    defaultMessage: 'Other Basic Organic Chemical Manufacturing'
  },
  '32521': {
    id: 'naics-32521',
    defaultMessage: 'Resin and Synthetic Rubber Manufacturing'
  },
  '32522': {
    id: 'naics-32522',
    defaultMessage:
      'Artificial and Synthetic Fibers and Filaments Manufacturing'
  },
  '32531': {
    id: 'naics-32531',
    defaultMessage: 'Fertilizer Manufacturing'
  },
  '32532': {
    id: 'naics-32532',
    defaultMessage: 'Pesticide and Other Agricultural Chemical Manufacturing'
  },
  '32541': {
    id: 'naics-32541',
    defaultMessage: 'Pharmaceutical and Medicine Manufacturing'
  },
  '32551': {
    id: 'naics-32551',
    defaultMessage: 'Paint and Coating Manufacturing'
  },
  '32552': {
    id: 'naics-32552',
    defaultMessage: 'Adhesive Manufacturing'
  },
  '32561': {
    id: 'naics-32561',
    defaultMessage: 'Soap and Cleaning Compound Manufacturing'
  },
  '32562': {
    id: 'naics-32562',
    defaultMessage: 'Toilet Preparation Manufacturing'
  },
  '32591': {
    id: 'naics-32591',
    defaultMessage: 'Printing Ink Manufacturing'
  },
  '32592': {
    id: 'naics-32592',
    defaultMessage: 'Explosives Manufacturing'
  },
  '32599': {
    id: 'naics-32599',
    defaultMessage: 'All Other Chemical Product and Preparation Manufacturing'
  },
  '32611': {
    id: 'naics-32611',
    defaultMessage:
      'Plastics Packaging Materials and Unlaminated Film and Sheet Manufacturing'
  },
  '32612': {
    id: 'naics-32612',
    defaultMessage:
      'Plastics Pipe, Pipe Fitting, and Unlaminated Profile Shape Manufacturing'
  },
  '32613': {
    id: 'naics-32613',
    defaultMessage:
      'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing'
  },
  '32614': {
    id: 'naics-32614',
    defaultMessage: 'Polystyrene Foam Product Manufacturing'
  },
  '32615': {
    id: 'naics-32615',
    defaultMessage:
      'Urethane and Other Foam Product (except Polystyrene) Manufacturing'
  },
  '32616': {
    id: 'naics-32616',
    defaultMessage: 'Plastics Bottle Manufacturing'
  },
  '32619': {
    id: 'naics-32619',
    defaultMessage: 'Other Plastics Product Manufacturing'
  },
  '32621': {
    id: 'naics-32621',
    defaultMessage: 'Tire Manufacturing'
  },
  '32622': {
    id: 'naics-32622',
    defaultMessage: 'Rubber and Plastics Hoses and Belting Manufacturing'
  },
  '32629': {
    id: 'naics-32629',
    defaultMessage: 'Other Rubber Product Manufacturing'
  },
  '32711': {
    id: 'naics-32711',
    defaultMessage: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing'
  },
  '32712': {
    id: 'naics-32712',
    defaultMessage: 'Clay Building Material and Refractories Manufacturing'
  },
  '32721': {
    id: 'naics-32721',
    defaultMessage: 'Glass and Glass Product Manufacturing'
  },
  '32731': {
    id: 'naics-32731',
    defaultMessage: 'Cement Manufacturing'
  },
  '32732': {
    id: 'naics-32732',
    defaultMessage: 'Ready-Mix Concrete Manufacturing'
  },
  '32733': {
    id: 'naics-32733',
    defaultMessage: 'Concrete Pipe, Brick, and Block Manufacturing'
  },
  '32739': {
    id: 'naics-32739',
    defaultMessage: 'Other Concrete Product Manufacturing'
  },
  '32741': {
    id: 'naics-32741',
    defaultMessage: 'Lime Manufacturing'
  },
  '32742': {
    id: 'naics-32742',
    defaultMessage: 'Gypsum Product Manufacturing'
  },
  '32791': {
    id: 'naics-32791',
    defaultMessage: 'Abrasive Product Manufacturing'
  },
  '32799': {
    id: 'naics-32799',
    defaultMessage: 'All Other Nonmetallic Mineral Product Manufacturing'
  },
  '33111': {
    id: 'naics-33111',
    defaultMessage: 'Iron and Steel Mills and Ferroalloy Manufacturing'
  },
  '33121': {
    id: 'naics-33121',
    defaultMessage:
      'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel'
  },
  '33122': {
    id: 'naics-33122',
    defaultMessage: 'Rolling and Drawing of Purchased Steel'
  },
  '33131': {
    id: 'naics-33131',
    defaultMessage: 'Alumina and Aluminum Production and Processing'
  },
  '33141': {
    id: 'naics-33141',
    defaultMessage: 'Nonferrous Metal (except Aluminum) Smelting and Refining'
  },
  '33142': {
    id: 'naics-33142',
    defaultMessage: 'Copper Rolling, Drawing, Extruding, and Alloying'
  },
  '33149': {
    id: 'naics-33149',
    defaultMessage:
      'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding, and Alloying'
  },
  '33151': {
    id: 'naics-33151',
    defaultMessage: 'Ferrous Metal Foundries'
  },
  '33152': {
    id: 'naics-33152',
    defaultMessage: 'Nonferrous Metal Foundries'
  },
  '33211': {
    id: 'naics-33211',
    defaultMessage: 'Forging and Stamping'
  },
  '33221': {
    id: 'naics-33221',
    defaultMessage: 'Cutlery and Handtool Manufacturing'
  },
  '33231': {
    id: 'naics-33231',
    defaultMessage: 'Plate Work and Fabricated Structural Product Manufacturing'
  },
  '33232': {
    id: 'naics-33232',
    defaultMessage: 'Ornamental and Architectural Metal Products Manufacturing'
  },
  '33241': {
    id: 'naics-33241',
    defaultMessage: 'Power Boiler and Heat Exchanger Manufacturing'
  },
  '33242': {
    id: 'naics-33242',
    defaultMessage: 'Metal Tank (Heavy Gauge) Manufacturing'
  },
  '33243': {
    id: 'naics-33243',
    defaultMessage:
      'Metal Can, Box, and Other Metal Container (Light Gauge) Manufacturing'
  },
  '33251': {
    id: 'naics-33251',
    defaultMessage: 'Hardware Manufacturing'
  },
  '33261': {
    id: 'naics-33261',
    defaultMessage: 'Spring and Wire Product Manufacturing'
  },
  '33271': {
    id: 'naics-33271',
    defaultMessage: 'Machine Shops'
  },
  '33272': {
    id: 'naics-33272',
    defaultMessage: 'Turned Product and Screw, Nut, and Bolt Manufacturing'
  },
  '33281': {
    id: 'naics-33281',
    defaultMessage: 'Coating, Engraving, Heat Treating, and Allied Activities'
  },
  '33291': {
    id: 'naics-33291',
    defaultMessage: 'Metal Valve Manufacturing'
  },
  '33299': {
    id: 'naics-33299',
    defaultMessage: 'All Other Fabricated Metal Product Manufacturing'
  },
  '33311': {
    id: 'naics-33311',
    defaultMessage: 'Agricultural Implement Manufacturing'
  },
  '33312': {
    id: 'naics-33312',
    defaultMessage: 'Construction Machinery Manufacturing'
  },
  '33313': {
    id: 'naics-33313',
    defaultMessage: 'Mining and Oil and Gas Field Machinery Manufacturing'
  },
  '33324': {
    id: 'naics-33324',
    defaultMessage: 'Industrial Machinery Manufacturing'
  },
  '33331': {
    id: 'naics-33331',
    defaultMessage: 'Commercial and Service Industry Machinery Manufacturing'
  },
  '33341': {
    id: 'naics-33341',
    defaultMessage:
      'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing'
  },
  '33351': {
    id: 'naics-33351',
    defaultMessage: 'Metalworking Machinery Manufacturing'
  },
  '33361': {
    id: 'naics-33361',
    defaultMessage:
      'Engine, Turbine, and Power Transmission Equipment Manufacturing'
  },
  '33391': {
    id: 'naics-33391',
    defaultMessage: 'Pump and Compressor Manufacturing'
  },
  '33392': {
    id: 'naics-33392',
    defaultMessage: 'Material Handling Equipment Manufacturing'
  },
  '33399': {
    id: 'naics-33399',
    defaultMessage: 'All Other General Purpose Machinery Manufacturing'
  },
  '33411': {
    id: 'naics-33411',
    defaultMessage: 'Computer and Peripheral Equipment Manufacturing'
  },
  '33421': {
    id: 'naics-33421',
    defaultMessage: 'Telephone Apparatus Manufacturing'
  },
  '33422': {
    id: 'naics-33422',
    defaultMessage:
      'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing'
  },
  '33429': {
    id: 'naics-33429',
    defaultMessage: 'Other Communications Equipment Manufacturing'
  },
  '33431': {
    id: 'naics-33431',
    defaultMessage: 'Audio and Video Equipment Manufacturing'
  },
  '33441': {
    id: 'naics-33441',
    defaultMessage: 'Semiconductor and Other Electronic Component Manufacturing'
  },
  '33451': {
    id: 'naics-33451',
    defaultMessage:
      'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing'
  },
  '33461': {
    id: 'naics-33461',
    defaultMessage: 'Manufacturing and Reproducing Magnetic and Optical Media'
  },
  '33511': {
    id: 'naics-33511',
    defaultMessage: 'Electric Lamp Bulb and Part Manufacturing'
  },
  '33512': {
    id: 'naics-33512',
    defaultMessage: 'Lighting Fixture Manufacturing'
  },
  '33521': {
    id: 'naics-33521',
    defaultMessage: 'Small Electrical Appliance Manufacturing'
  },
  '33522': {
    id: 'naics-33522',
    defaultMessage: 'Major Household Appliance Manufacturing'
  },
  '33531': {
    id: 'naics-33531',
    defaultMessage: 'Electrical Equipment Manufacturing'
  },
  '33591': {
    id: 'naics-33591',
    defaultMessage: 'Battery Manufacturing'
  },
  '33592': {
    id: 'naics-33592',
    defaultMessage: 'Communication and Energy Wire and Cable Manufacturing'
  },
  '33593': {
    id: 'naics-33593',
    defaultMessage: 'Wiring Device Manufacturing'
  },
  '33599': {
    id: 'naics-33599',
    defaultMessage: 'All Other Electrical Equipment and Component Manufacturing'
  },
  '33611': {
    id: 'naics-33611',
    defaultMessage: 'Automobile and Light Duty Motor Vehicle Manufacturing'
  },
  '33612': {
    id: 'naics-33612',
    defaultMessage: 'Heavy Duty Truck Manufacturing'
  },
  '33621': {
    id: 'naics-33621',
    defaultMessage: 'Motor Vehicle Body and Trailer Manufacturing'
  },
  '33631': {
    id: 'naics-33631',
    defaultMessage:
      'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing'
  },
  '33632': {
    id: 'naics-33632',
    defaultMessage:
      'Motor Vehicle Electrical and Electronic Equipment Manufacturing'
  },
  '33633': {
    id: 'naics-33633',
    defaultMessage:
      'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing'
  },
  '33634': {
    id: 'naics-33634',
    defaultMessage: 'Motor Vehicle Brake System Manufacturing'
  },
  '33635': {
    id: 'naics-33635',
    defaultMessage:
      'Motor Vehicle Transmission and Power Train Parts Manufacturing'
  },
  '33636': {
    id: 'naics-33636',
    defaultMessage: 'Motor Vehicle Seating and Interior Trim Manufacturing'
  },
  '33637': {
    id: 'naics-33637',
    defaultMessage: 'Motor Vehicle Metal Stamping'
  },
  '33639': {
    id: 'naics-33639',
    defaultMessage: 'Other Motor Vehicle Parts Manufacturing'
  },
  '33641': {
    id: 'naics-33641',
    defaultMessage: 'Aerospace Product and Parts Manufacturing'
  },
  '33651': {
    id: 'naics-33651',
    defaultMessage: 'Railroad Rolling Stock Manufacturing'
  },
  '33661': {
    id: 'naics-33661',
    defaultMessage: 'Ship and Boat Building'
  },
  '33699': {
    id: 'naics-33699',
    defaultMessage: 'Other Transportation Equipment Manufacturing'
  },
  '33711': {
    id: 'naics-33711',
    defaultMessage: 'Wood Kitchen Cabinet and Countertop Manufacturing'
  },
  '33712': {
    id: 'naics-33712',
    defaultMessage: 'Household and Institutional Furniture Manufacturing'
  },
  '33721': {
    id: 'naics-33721',
    defaultMessage: 'Office Furniture (including Fixtures) Manufacturing'
  },
  '33791': {
    id: 'naics-33791',
    defaultMessage: 'Mattress Manufacturing'
  },
  '33792': {
    id: 'naics-33792',
    defaultMessage: 'Blind and Shade Manufacturing'
  },
  '33911': {
    id: 'naics-33911',
    defaultMessage: 'Medical Equipment and Supplies Manufacturing'
  },
  '33991': {
    id: 'naics-33991',
    defaultMessage: 'Jewelry and Silverware Manufacturing'
  },
  '33992': {
    id: 'naics-33992',
    defaultMessage: 'Sporting and Athletic Goods Manufacturing'
  },
  '33993': {
    id: 'naics-33993',
    defaultMessage: 'Doll, Toy, and Game Manufacturing'
  },
  '33994': {
    id: 'naics-33994',
    defaultMessage: 'Office Supplies (except Paper) Manufacturing'
  },
  '33995': {
    id: 'naics-33995',
    defaultMessage: 'Sign Manufacturing'
  },
  '33999': {
    id: 'naics-33999',
    defaultMessage: 'All Other Miscellaneous Manufacturing'
  },
  '42311': {
    id: 'naics-42311',
    defaultMessage: 'Automobile and Other Motor Vehicle Merchant Wholesalers'
  },
  '42312': {
    id: 'naics-42312',
    defaultMessage: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers'
  },
  '42313': {
    id: 'naics-42313',
    defaultMessage: 'Tire and Tube Merchant Wholesalers'
  },
  '42314': {
    id: 'naics-42314',
    defaultMessage: 'Motor Vehicle Parts (Used) Merchant Wholesalers'
  },
  '42321': {
    id: 'naics-42321',
    defaultMessage: 'Furniture Merchant Wholesalers'
  },
  '42322': {
    id: 'naics-42322',
    defaultMessage: 'Home Furnishing Merchant Wholesalers'
  },
  '42331': {
    id: 'naics-42331',
    defaultMessage:
      'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers'
  },
  '42332': {
    id: 'naics-42332',
    defaultMessage:
      'Brick, Stone, and Related Construction Material Merchant Wholesalers'
  },
  '42333': {
    id: 'naics-42333',
    defaultMessage:
      'Roofing, Siding, and Insulation Material Merchant Wholesalers'
  },
  '42339': {
    id: 'naics-42339',
    defaultMessage: 'Other Construction Material Merchant Wholesalers'
  },
  '42341': {
    id: 'naics-42341',
    defaultMessage: 'Photographic Equipment and Supplies Merchant Wholesalers'
  },
  '42342': {
    id: 'naics-42342',
    defaultMessage: 'Office Equipment Merchant Wholesalers'
  },
  '42343': {
    id: 'naics-42343',
    defaultMessage:
      'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers'
  },
  '42344': {
    id: 'naics-42344',
    defaultMessage: 'Other Commercial Equipment Merchant Wholesalers'
  },
  '42345': {
    id: 'naics-42345',
    defaultMessage:
      'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers'
  },
  '42346': {
    id: 'naics-42346',
    defaultMessage: 'Ophthalmic Goods Merchant Wholesalers'
  },
  '42349': {
    id: 'naics-42349',
    defaultMessage:
      'Other Professional Equipment and Supplies Merchant Wholesalers'
  },
  '42351': {
    id: 'naics-42351',
    defaultMessage: 'Metal Service Centers and Other Metal Merchant Wholesalers'
  },
  '42352': {
    id: 'naics-42352',
    defaultMessage: 'Coal and Other Mineral and Ore Merchant Wholesalers'
  },
  '42361': {
    id: 'naics-42361',
    defaultMessage:
      'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers'
  },
  '42362': {
    id: 'naics-42362',
    defaultMessage:
      'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers'
  },
  '42369': {
    id: 'naics-42369',
    defaultMessage: 'Other Electronic Parts and Equipment Merchant Wholesalers'
  },
  '42371': {
    id: 'naics-42371',
    defaultMessage: 'Hardware Merchant Wholesalers'
  },
  '42372': {
    id: 'naics-42372',
    defaultMessage:
      'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers'
  },
  '42373': {
    id: 'naics-42373',
    defaultMessage:
      'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers'
  },
  '42374': {
    id: 'naics-42374',
    defaultMessage: 'Refrigeration Equipment and Supplies Merchant Wholesalers'
  },
  '42381': {
    id: 'naics-42381',
    defaultMessage:
      'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers'
  },
  '42382': {
    id: 'naics-42382',
    defaultMessage:
      'Farm and Garden Machinery and Equipment Merchant Wholesalers'
  },
  '42383': {
    id: 'naics-42383',
    defaultMessage: 'Industrial Machinery and Equipment Merchant Wholesalers'
  },
  '42384': {
    id: 'naics-42384',
    defaultMessage: 'Industrial Supplies Merchant Wholesalers'
  },
  '42385': {
    id: 'naics-42385',
    defaultMessage:
      'Service Establishment Equipment and Supplies Merchant Wholesalers'
  },
  '42386': {
    id: 'naics-42386',
    defaultMessage:
      'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers'
  },
  '42391': {
    id: 'naics-42391',
    defaultMessage:
      'Sporting and Recreational Goods and Supplies Merchant Wholesalers'
  },
  '42392': {
    id: 'naics-42392',
    defaultMessage: 'Toy and Hobby Goods and Supplies Merchant Wholesalers'
  },
  '42393': {
    id: 'naics-42393',
    defaultMessage: 'Recyclable Material Merchant Wholesalers'
  },
  '42394': {
    id: 'naics-42394',
    defaultMessage:
      'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers'
  },
  '42399': {
    id: 'naics-42399',
    defaultMessage: 'Other Miscellaneous Durable Goods Merchant Wholesalers'
  },
  '42411': {
    id: 'naics-42411',
    defaultMessage: 'Printing and Writing Paper Merchant Wholesalers'
  },
  '42412': {
    id: 'naics-42412',
    defaultMessage: 'Stationery and Office Supplies Merchant Wholesalers'
  },
  '42413': {
    id: 'naics-42413',
    defaultMessage: 'Industrial and Personal Service Paper Merchant Wholesalers'
  },
  '42421': {
    id: 'naics-42421',
    defaultMessage: "Drugs and Druggists' Sundries Merchant Wholesalers"
  },
  '42431': {
    id: 'naics-42431',
    defaultMessage:
      'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers'
  },
  '42432': {
    id: 'naics-42432',
    defaultMessage:
      "Men's and Boys' Clothing and Furnishings Merchant Wholesalers"
  },
  '42433': {
    id: 'naics-42433',
    defaultMessage:
      "Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers"
  },
  '42434': {
    id: 'naics-42434',
    defaultMessage: 'Footwear Merchant Wholesalers'
  },
  '42441': {
    id: 'naics-42441',
    defaultMessage: 'General Line Grocery Merchant Wholesalers'
  },
  '42442': {
    id: 'naics-42442',
    defaultMessage: 'Packaged Frozen Food Merchant Wholesalers'
  },
  '42443': {
    id: 'naics-42443',
    defaultMessage:
      'Dairy Product (except Dried or Canned) Merchant Wholesalers'
  },
  '42444': {
    id: 'naics-42444',
    defaultMessage: 'Poultry and Poultry Product Merchant Wholesalers'
  },
  '42445': {
    id: 'naics-42445',
    defaultMessage: 'Confectionery Merchant Wholesalers'
  },
  '42446': {
    id: 'naics-42446',
    defaultMessage: 'Fish and Seafood Merchant Wholesalers'
  },
  '42447': {
    id: 'naics-42447',
    defaultMessage: 'Meat and Meat Product Merchant Wholesalers'
  },
  '42448': {
    id: 'naics-42448',
    defaultMessage: 'Fresh Fruit and Vegetable Merchant Wholesalers'
  },
  '42449': {
    id: 'naics-42449',
    defaultMessage: 'Other Grocery and Related Products Merchant Wholesalers'
  },
  '42451': {
    id: 'naics-42451',
    defaultMessage: 'Grain and Field Bean Merchant Wholesalers'
  },
  '42452': {
    id: 'naics-42452',
    defaultMessage: 'Livestock Merchant Wholesalers'
  },
  '42459': {
    id: 'naics-42459',
    defaultMessage: 'Other Farm Product Raw Material Merchant Wholesalers'
  },
  '42461': {
    id: 'naics-42461',
    defaultMessage:
      'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers'
  },
  '42469': {
    id: 'naics-42469',
    defaultMessage: 'Other Chemical and Allied Products Merchant Wholesalers'
  },
  '42471': {
    id: 'naics-42471',
    defaultMessage: 'Petroleum Bulk Stations and Terminals'
  },
  '42472': {
    id: 'naics-42472',
    defaultMessage:
      'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)'
  },
  '42481': {
    id: 'naics-42481',
    defaultMessage: 'Beer and Ale Merchant Wholesalers'
  },
  '42482': {
    id: 'naics-42482',
    defaultMessage: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers'
  },
  '42491': {
    id: 'naics-42491',
    defaultMessage: 'Farm Supplies Merchant Wholesalers'
  },
  '42492': {
    id: 'naics-42492',
    defaultMessage: 'Book, Periodical, and Newspaper Merchant Wholesalers'
  },
  '42493': {
    id: 'naics-42493',
    defaultMessage:
      "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers"
  },
  '42494': {
    id: 'naics-42494',
    defaultMessage: 'Tobacco and Tobacco Product Merchant Wholesalers'
  },
  '42495': {
    id: 'naics-42495',
    defaultMessage: 'Paint, Varnish, and Supplies Merchant Wholesalers'
  },
  '42499': {
    id: 'naics-42499',
    defaultMessage: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers'
  },
  '42511': {
    id: 'naics-42511',
    defaultMessage: 'Business to Business Electronic Markets'
  },
  '42512': {
    id: 'naics-42512',
    defaultMessage: 'Wholesale Trade Agents and Brokers'
  },
  '44111': {
    id: 'naics-44111',
    defaultMessage: 'New Car Dealers'
  },
  '44112': {
    id: 'naics-44112',
    defaultMessage: 'Used Car Dealers'
  },
  '44121': {
    id: 'naics-44121',
    defaultMessage: 'Recreational Vehicle Dealers'
  },
  '44122': {
    id: 'naics-44122',
    defaultMessage: 'Motorcycle, Boat, and Other Motor Vehicle Dealers'
  },
  '44131': {
    id: 'naics-44131',
    defaultMessage: 'Automotive Parts and Accessories Stores'
  },
  '44132': {
    id: 'naics-44132',
    defaultMessage: 'Tire Dealers'
  },
  '44211': {
    id: 'naics-44211',
    defaultMessage: 'Furniture Stores'
  },
  '44221': {
    id: 'naics-44221',
    defaultMessage: 'Floor Covering Stores'
  },
  '44229': {
    id: 'naics-44229',
    defaultMessage: 'Other Home Furnishings Stores'
  },
  '44314': {
    id: 'naics-44314',
    defaultMessage: 'Electronics and Appliance Stores'
  },
  '44411': {
    id: 'naics-44411',
    defaultMessage: 'Home Centers'
  },
  '44412': {
    id: 'naics-44412',
    defaultMessage: 'Paint and Wallpaper Stores'
  },
  '44413': {
    id: 'naics-44413',
    defaultMessage: 'Hardware Stores'
  },
  '44419': {
    id: 'naics-44419',
    defaultMessage: 'Other Building Material Dealers'
  },
  '44421': {
    id: 'naics-44421',
    defaultMessage: 'Outdoor Power Equipment Stores'
  },
  '44422': {
    id: 'naics-44422',
    defaultMessage: 'Nursery, Garden Center, and Farm Supply Stores'
  },
  '44511': {
    id: 'naics-44511',
    defaultMessage: 'Supermarkets and Other Grocery (except Convenience) Stores'
  },
  '44512': {
    id: 'naics-44512',
    defaultMessage: 'Convenience Stores'
  },
  '44521': {
    id: 'naics-44521',
    defaultMessage: 'Meat Markets'
  },
  '44522': {
    id: 'naics-44522',
    defaultMessage: 'Fish and Seafood Markets'
  },
  '44523': {
    id: 'naics-44523',
    defaultMessage: 'Fruit and Vegetable Markets'
  },
  '44529': {
    id: 'naics-44529',
    defaultMessage: 'Other Specialty Food Stores'
  },
  '44531': {
    id: 'naics-44531',
    defaultMessage: 'Beer, Wine, and Liquor Stores'
  },
  '44611': {
    id: 'naics-44611',
    defaultMessage: 'Pharmacies and Drug Stores'
  },
  '44612': {
    id: 'naics-44612',
    defaultMessage: 'Cosmetics, Beauty Supplies, and Perfume Stores'
  },
  '44613': {
    id: 'naics-44613',
    defaultMessage: 'Optical Goods Stores'
  },
  '44619': {
    id: 'naics-44619',
    defaultMessage: 'Other Health and Personal Care Stores'
  },
  '44711': {
    id: 'naics-44711',
    defaultMessage: 'Gasoline Stations with Convenience Stores'
  },
  '44719': {
    id: 'naics-44719',
    defaultMessage: 'Other Gasoline Stations'
  },
  '44811': {
    id: 'naics-44811',
    defaultMessage: "Men's Clothing Stores"
  },
  '44812': {
    id: 'naics-44812',
    defaultMessage: "Women's Clothing Stores"
  },
  '44813': {
    id: 'naics-44813',
    defaultMessage: "Children's and Infants' Clothing Stores"
  },
  '44814': {
    id: 'naics-44814',
    defaultMessage: 'Family Clothing Stores'
  },
  '44815': {
    id: 'naics-44815',
    defaultMessage: 'Clothing Accessories Stores'
  },
  '44819': {
    id: 'naics-44819',
    defaultMessage: 'Other Clothing Stores'
  },
  '44821': {
    id: 'naics-44821',
    defaultMessage: 'Shoe Stores'
  },
  '44831': {
    id: 'naics-44831',
    defaultMessage: 'Jewelry Stores'
  },
  '44832': {
    id: 'naics-44832',
    defaultMessage: 'Luggage and Leather Goods Stores'
  },
  '45111': {
    id: 'naics-45111',
    defaultMessage: 'Sporting Goods Stores'
  },
  '45112': {
    id: 'naics-45112',
    defaultMessage: 'Hobby, Toy, and Game Stores'
  },
  '45113': {
    id: 'naics-45113',
    defaultMessage: 'Sewing, Needlework, and Piece Goods Stores'
  },
  '45114': {
    id: 'naics-45114',
    defaultMessage: 'Musical Instrument and Supplies Stores'
  },
  '45121': {
    id: 'naics-45121',
    defaultMessage: 'Book Stores and News Dealers'
  },
  '45221': {
    id: 'naics-45221',
    defaultMessage: 'Department Stores'
  },
  '45231': {
    id: 'naics-45231',
    defaultMessage:
      'General Merchandise Stores, including Warehouse Clubs and Supercenters'
  },
  '45311': {
    id: 'naics-45311',
    defaultMessage: 'Florists'
  },
  '45321': {
    id: 'naics-45321',
    defaultMessage: 'Office Supplies and Stationery Stores'
  },
  '45322': {
    id: 'naics-45322',
    defaultMessage: 'Gift, Novelty, and Souvenir Stores'
  },
  '45331': {
    id: 'naics-45331',
    defaultMessage: 'Used Merchandise Stores'
  },
  '45391': {
    id: 'naics-45391',
    defaultMessage: 'Pet and Pet Supplies Stores'
  },
  '45392': {
    id: 'naics-45392',
    defaultMessage: 'Art Dealers'
  },
  '45393': {
    id: 'naics-45393',
    defaultMessage: 'Manufactured (Mobile) Home Dealers'
  },
  '45399': {
    id: 'naics-45399',
    defaultMessage: 'All Other Miscellaneous Store Retailers'
  },
  '45411': {
    id: 'naics-45411',
    defaultMessage: 'Electronic Shopping and Mail-Order Houses'
  },
  '45421': {
    id: 'naics-45421',
    defaultMessage: 'Vending Machine Operators'
  },
  '45431': {
    id: 'naics-45431',
    defaultMessage: 'Fuel Dealers'
  },
  '45439': {
    id: 'naics-45439',
    defaultMessage: 'Other Direct Selling Establishments'
  },
  '48111': {
    id: 'naics-48111',
    defaultMessage: 'Scheduled Air Transportation'
  },
  '48121': {
    id: 'naics-48121',
    defaultMessage: 'Nonscheduled Air Transportation'
  },
  '48211': {
    id: 'naics-48211',
    defaultMessage: 'Rail Transportation'
  },
  '48311': {
    id: 'naics-48311',
    defaultMessage: 'Deep Sea, Coastal, and Great Lakes Water Transportation'
  },
  '48321': {
    id: 'naics-48321',
    defaultMessage: 'Inland Water Transportation'
  },
  '48411': {
    id: 'naics-48411',
    defaultMessage: 'General Freight Trucking, Local'
  },
  '48412': {
    id: 'naics-48412',
    defaultMessage: 'General Freight Trucking, Long-Distance'
  },
  '48421': {
    id: 'naics-48421',
    defaultMessage: 'Used Household and Office Goods Moving'
  },
  '48422': {
    id: 'naics-48422',
    defaultMessage: 'Specialized Freight (except Used Goods) Trucking, Local'
  },
  '48423': {
    id: 'naics-48423',
    defaultMessage:
      'Specialized Freight (except Used Goods) Trucking, Long-Distance'
  },
  '48511': {
    id: 'naics-48511',
    defaultMessage: 'Urban Transit Systems'
  },
  '48521': {
    id: 'naics-48521',
    defaultMessage: 'Interurban and Rural Bus Transportation'
  },
  '48531': {
    id: 'naics-48531',
    defaultMessage: 'Taxi Service'
  },
  '48532': {
    id: 'naics-48532',
    defaultMessage: 'Limousine Service'
  },
  '48541': {
    id: 'naics-48541',
    defaultMessage: 'School and Employee Bus Transportation'
  },
  '48551': {
    id: 'naics-48551',
    defaultMessage: 'Charter Bus Industry'
  },
  '48599': {
    id: 'naics-48599',
    defaultMessage: 'Other Transit and Ground Passenger Transportation'
  },
  '48611': {
    id: 'naics-48611',
    defaultMessage: 'Pipeline Transportation of Crude Oil'
  },
  '48621': {
    id: 'naics-48621',
    defaultMessage: 'Pipeline Transportation of Natural Gas'
  },
  '48691': {
    id: 'naics-48691',
    defaultMessage: 'Pipeline Transportation of Refined Petroleum Products'
  },
  '48699': {
    id: 'naics-48699',
    defaultMessage: 'All Other Pipeline Transportation'
  },
  '48711': {
    id: 'naics-48711',
    defaultMessage: 'Scenic and Sightseeing Transportation, Land'
  },
  '48721': {
    id: 'naics-48721',
    defaultMessage: 'Scenic and Sightseeing Transportation, Water'
  },
  '48799': {
    id: 'naics-48799',
    defaultMessage: 'Scenic and Sightseeing Transportation, Other'
  },
  '48811': {
    id: 'naics-48811',
    defaultMessage: 'Airport Operations'
  },
  '48819': {
    id: 'naics-48819',
    defaultMessage: 'Other Support Activities for Air Transportation'
  },
  '48821': {
    id: 'naics-48821',
    defaultMessage: 'Support Activities for Rail Transportation'
  },
  '48831': {
    id: 'naics-48831',
    defaultMessage: 'Port and Harbor Operations'
  },
  '48832': {
    id: 'naics-48832',
    defaultMessage: 'Marine Cargo Handling'
  },
  '48833': {
    id: 'naics-48833',
    defaultMessage: 'Navigational Services to Shipping'
  },
  '48839': {
    id: 'naics-48839',
    defaultMessage: 'Other Support Activities for Water Transportation'
  },
  '48841': {
    id: 'naics-48841',
    defaultMessage: 'Motor Vehicle Towing'
  },
  '48849': {
    id: 'naics-48849',
    defaultMessage: 'Other Support Activities for Road Transportation'
  },
  '48851': {
    id: 'naics-48851',
    defaultMessage: 'Freight Transportation Arrangement'
  },
  '48899': {
    id: 'naics-48899',
    defaultMessage: 'Other Support Activities for Transportation'
  },
  '49111': {
    id: 'naics-49111',
    defaultMessage: 'Postal Service'
  },
  '49211': {
    id: 'naics-49211',
    defaultMessage: 'Couriers and Express Delivery Services'
  },
  '49221': {
    id: 'naics-49221',
    defaultMessage: 'Local Messengers and Local Delivery'
  },
  '49311': {
    id: 'naics-49311',
    defaultMessage: 'General Warehousing and Storage'
  },
  '49312': {
    id: 'naics-49312',
    defaultMessage: 'Refrigerated Warehousing and Storage'
  },
  '49313': {
    id: 'naics-49313',
    defaultMessage: 'Farm Product Warehousing and Storage'
  },
  '49319': {
    id: 'naics-49319',
    defaultMessage: 'Other Warehousing and Storage'
  },
  '51111': {
    id: 'naics-51111',
    defaultMessage: 'Newspaper Publishers'
  },
  '51112': {
    id: 'naics-51112',
    defaultMessage: 'Periodical Publishers'
  },
  '51113': {
    id: 'naics-51113',
    defaultMessage: 'Book Publishers'
  },
  '51114': {
    id: 'naics-51114',
    defaultMessage: 'Directory and Mailing List Publishers'
  },
  '51119': {
    id: 'naics-51119',
    defaultMessage: 'Other Publishers'
  },
  '51121': {
    id: 'naics-51121',
    defaultMessage: 'Software Publishers'
  },
  '51211': {
    id: 'naics-51211',
    defaultMessage: 'Motion Picture and Video Production'
  },
  '51212': {
    id: 'naics-51212',
    defaultMessage: 'Motion Picture and Video Distribution'
  },
  '51213': {
    id: 'naics-51213',
    defaultMessage: 'Motion Picture and Video Exhibition'
  },
  '51219': {
    id: 'naics-51219',
    defaultMessage:
      'Postproduction Services and Other Motion Picture and Video Industries'
  },
  '51223': {
    id: 'naics-51223',
    defaultMessage: 'Music Publishers'
  },
  '51224': {
    id: 'naics-51224',
    defaultMessage: 'Sound Recording Studios'
  },
  '51225': {
    id: 'naics-51225',
    defaultMessage: 'Record Production and Distribution'
  },
  '51229': {
    id: 'naics-51229',
    defaultMessage: 'Other Sound Recording Industries'
  },
  '51511': {
    id: 'naics-51511',
    defaultMessage: 'Radio Broadcasting'
  },
  '51512': {
    id: 'naics-51512',
    defaultMessage: 'Television Broadcasting'
  },
  '51521': {
    id: 'naics-51521',
    defaultMessage: 'Cable and Other Subscription Programming'
  },
  '51731': {
    id: 'naics-51731',
    defaultMessage: 'Wired and Wireless Telecommunications Carriers'
  },
  '51741': {
    id: 'naics-51741',
    defaultMessage: 'Satellite Telecommunications'
  },
  '51791': {
    id: 'naics-51791',
    defaultMessage: 'Other Telecommunications'
  },
  '51821': {
    id: 'naics-51821',
    defaultMessage: 'Data Processing, Hosting, and Related Services'
  },
  '51911': {
    id: 'naics-51911',
    defaultMessage: 'News Syndicates'
  },
  '51912': {
    id: 'naics-51912',
    defaultMessage: 'Libraries and Archives'
  },
  '51913': {
    id: 'naics-51913',
    defaultMessage:
      'Internet Publishing and Broadcasting and Web Search Portals'
  },
  '51919': {
    id: 'naics-51919',
    defaultMessage: 'All Other Information Services'
  },
  '52111': {
    id: 'naics-52111',
    defaultMessage: 'Monetary Authorities-Central Bank'
  },
  '52211': {
    id: 'naics-52211',
    defaultMessage: 'Commercial Banking'
  },
  '52212': {
    id: 'naics-52212',
    defaultMessage: 'Savings Institutions'
  },
  '52213': {
    id: 'naics-52213',
    defaultMessage: 'Credit Unions'
  },
  '52219': {
    id: 'naics-52219',
    defaultMessage: 'Other Depository Credit Intermediation'
  },
  '52221': {
    id: 'naics-52221',
    defaultMessage: 'Credit Card Issuing'
  },
  '52222': {
    id: 'naics-52222',
    defaultMessage: 'Sales Financing'
  },
  '52229': {
    id: 'naics-52229',
    defaultMessage: 'Other Nondepository Credit Intermediation'
  },
  '52231': {
    id: 'naics-52231',
    defaultMessage: 'Mortgage and Nonmortgage Loan Brokers'
  },
  '52232': {
    id: 'naics-52232',
    defaultMessage:
      'Financial Transactions Processing, Reserve, and Clearinghouse Activities'
  },
  '52239': {
    id: 'naics-52239',
    defaultMessage: 'Other Activities Related to Credit Intermediation'
  },
  '52311': {
    id: 'naics-52311',
    defaultMessage: 'Investment Banking and Securities Dealing'
  },
  '52312': {
    id: 'naics-52312',
    defaultMessage: 'Securities Brokerage'
  },
  '52313': {
    id: 'naics-52313',
    defaultMessage: 'Commodity Contracts Dealing'
  },
  '52314': {
    id: 'naics-52314',
    defaultMessage: 'Commodity Contracts Brokerage'
  },
  '52321': {
    id: 'naics-52321',
    defaultMessage: 'Securities and Commodity Exchanges'
  },
  '52391': {
    id: 'naics-52391',
    defaultMessage: 'Miscellaneous Intermediation'
  },
  '52392': {
    id: 'naics-52392',
    defaultMessage: 'Portfolio Management'
  },
  '52393': {
    id: 'naics-52393',
    defaultMessage: 'Investment Advice'
  },
  '52399': {
    id: 'naics-52399',
    defaultMessage: 'All Other Financial Investment Activities'
  },
  '52411': {
    id: 'naics-52411',
    defaultMessage: 'Direct Life, Health, and Medical Insurance Carriers'
  },
  '52412': {
    id: 'naics-52412',
    defaultMessage:
      'Direct Insurance (except Life, Health, and Medical) Carriers'
  },
  '52413': {
    id: 'naics-52413',
    defaultMessage: 'Reinsurance Carriers'
  },
  '52421': {
    id: 'naics-52421',
    defaultMessage: 'Insurance Agencies and Brokerages'
  },
  '52429': {
    id: 'naics-52429',
    defaultMessage: 'Other Insurance Related Activities'
  },
  '52511': {
    id: 'naics-52511',
    defaultMessage: 'Pension Funds'
  },
  '52512': {
    id: 'naics-52512',
    defaultMessage: 'Health and Welfare Funds'
  },
  '52519': {
    id: 'naics-52519',
    defaultMessage: 'Other Insurance Funds'
  },
  '52591': {
    id: 'naics-52591',
    defaultMessage: 'Open-End Investment Funds'
  },
  '52592': {
    id: 'naics-52592',
    defaultMessage: 'Trusts, Estates, and Agency Accounts'
  },
  '52599': {
    id: 'naics-52599',
    defaultMessage: 'Other Financial Vehicles'
  },
  '53111': {
    id: 'naics-53111',
    defaultMessage: 'Lessors of Residential Buildings and Dwellings'
  },
  '53112': {
    id: 'naics-53112',
    defaultMessage:
      'Lessors of Nonresidential Buildings (except Miniwarehouses)'
  },
  '53113': {
    id: 'naics-53113',
    defaultMessage: 'Lessors of Miniwarehouses and Self-Storage Units'
  },
  '53119': {
    id: 'naics-53119',
    defaultMessage: 'Lessors of Other Real Estate Property'
  },
  '53121': {
    id: 'naics-53121',
    defaultMessage: 'Offices of Real Estate Agents and Brokers'
  },
  '53131': {
    id: 'naics-53131',
    defaultMessage: 'Real Estate Property Managers'
  },
  '53132': {
    id: 'naics-53132',
    defaultMessage: 'Offices of Real Estate Appraisers'
  },
  '53139': {
    id: 'naics-53139',
    defaultMessage: 'Other Activities Related to Real Estate'
  },
  '53211': {
    id: 'naics-53211',
    defaultMessage: 'Passenger Car Rental and Leasing'
  },
  '53212': {
    id: 'naics-53212',
    defaultMessage:
      'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing'
  },
  '53221': {
    id: 'naics-53221',
    defaultMessage: 'Consumer Electronics and Appliances Rental'
  },
  '53228': {
    id: 'naics-53228',
    defaultMessage: 'Other Consumer Goods Rental'
  },
  '53231': {
    id: 'naics-53231',
    defaultMessage: 'General Rental Centers'
  },
  '53241': {
    id: 'naics-53241',
    defaultMessage:
      'Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing'
  },
  '53242': {
    id: 'naics-53242',
    defaultMessage: 'Office Machinery and Equipment Rental and Leasing'
  },
  '53249': {
    id: 'naics-53249',
    defaultMessage:
      'Other Commercial and Industrial Machinery and Equipment Rental and Leasing'
  },
  '53311': {
    id: 'naics-53311',
    defaultMessage:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)'
  },
  '54111': {
    id: 'naics-54111',
    defaultMessage: 'Offices of Lawyers'
  },
  '54112': {
    id: 'naics-54112',
    defaultMessage: 'Offices of Notaries'
  },
  '54119': {
    id: 'naics-54119',
    defaultMessage: 'Other Legal Services'
  },
  '54121': {
    id: 'naics-54121',
    defaultMessage:
      'Accounting, Tax Preparation, Bookkeeping, and Payroll Services'
  },
  '54131': {
    id: 'naics-54131',
    defaultMessage: 'Architectural Services'
  },
  '54132': {
    id: 'naics-54132',
    defaultMessage: 'Landscape Architectural Services'
  },
  '54133': {
    id: 'naics-54133',
    defaultMessage: 'Engineering Services'
  },
  '54134': {
    id: 'naics-54134',
    defaultMessage: 'Drafting Services'
  },
  '54135': {
    id: 'naics-54135',
    defaultMessage: 'Building Inspection Services'
  },
  '54136': {
    id: 'naics-54136',
    defaultMessage: 'Geophysical Surveying and Mapping Services'
  },
  '54137': {
    id: 'naics-54137',
    defaultMessage: 'Surveying and Mapping (except Geophysical) Services'
  },
  '54138': {
    id: 'naics-54138',
    defaultMessage: 'Testing Laboratories'
  },
  '54141': {
    id: 'naics-54141',
    defaultMessage: 'Interior Design Services'
  },
  '54142': {
    id: 'naics-54142',
    defaultMessage: 'Industrial Design Services'
  },
  '54143': {
    id: 'naics-54143',
    defaultMessage: 'Graphic Design Services'
  },
  '54149': {
    id: 'naics-54149',
    defaultMessage: 'Other Specialized Design Services'
  },
  '54151': {
    id: 'naics-54151',
    defaultMessage: 'Computer Systems Design and Related Services'
  },
  '54161': {
    id: 'naics-54161',
    defaultMessage: 'Management Consulting Services'
  },
  '54162': {
    id: 'naics-54162',
    defaultMessage: 'Environmental Consulting Services'
  },
  '54169': {
    id: 'naics-54169',
    defaultMessage: 'Other Scientific and Technical Consulting Services'
  },
  '54171': {
    id: 'naics-54171',
    defaultMessage:
      'Research and Development in the Physical, Engineering, and Life Sciences'
  },
  '54172': {
    id: 'naics-54172',
    defaultMessage:
      'Research and Development in the Social Sciences and Humanities'
  },
  '54181': {
    id: 'naics-54181',
    defaultMessage: 'Advertising Agencies'
  },
  '54182': {
    id: 'naics-54182',
    defaultMessage: 'Public Relations Agencies'
  },
  '54183': {
    id: 'naics-54183',
    defaultMessage: 'Media Buying Agencies'
  },
  '54184': {
    id: 'naics-54184',
    defaultMessage: 'Media Representatives'
  },
  '54185': {
    id: 'naics-54185',
    defaultMessage: 'Outdoor Advertising'
  },
  '54186': {
    id: 'naics-54186',
    defaultMessage: 'Direct Mail Advertising'
  },
  '54187': {
    id: 'naics-54187',
    defaultMessage: 'Advertising Material Distribution Services'
  },
  '54189': {
    id: 'naics-54189',
    defaultMessage: 'Other Services Related to Advertising'
  },
  '54191': {
    id: 'naics-54191',
    defaultMessage: 'Marketing Research and Public Opinion Polling'
  },
  '54192': {
    id: 'naics-54192',
    defaultMessage: 'Photographic Services'
  },
  '54193': {
    id: 'naics-54193',
    defaultMessage: 'Translation and Interpretation Services'
  },
  '54194': {
    id: 'naics-54194',
    defaultMessage: 'Veterinary Services'
  },
  '54199': {
    id: 'naics-54199',
    defaultMessage: 'All Other Professional, Scientific, and Technical Services'
  },
  '55111': {
    id: 'naics-55111',
    defaultMessage: 'Management of Companies and Enterprises'
  },
  '56111': {
    id: 'naics-56111',
    defaultMessage: 'Office Administrative Services'
  },
  '56121': {
    id: 'naics-56121',
    defaultMessage: 'Facilities Support Services'
  },
  '56131': {
    id: 'naics-56131',
    defaultMessage:
      'Employment Placement Agencies and Executive Search Services'
  },
  '56132': {
    id: 'naics-56132',
    defaultMessage: 'Temporary Help Services'
  },
  '56133': {
    id: 'naics-56133',
    defaultMessage: 'Professional Employer Organizations'
  },
  '56141': {
    id: 'naics-56141',
    defaultMessage: 'Document Preparation Services'
  },
  '56142': {
    id: 'naics-56142',
    defaultMessage: 'Telephone Call Centers'
  },
  '56143': {
    id: 'naics-56143',
    defaultMessage: 'Business Service Centers'
  },
  '56144': {
    id: 'naics-56144',
    defaultMessage: 'Collection Agencies'
  },
  '56145': {
    id: 'naics-56145',
    defaultMessage: 'Credit Bureaus'
  },
  '56149': {
    id: 'naics-56149',
    defaultMessage: 'Other Business Support Services'
  },
  '56151': {
    id: 'naics-56151',
    defaultMessage: 'Travel Agencies'
  },
  '56152': {
    id: 'naics-56152',
    defaultMessage: 'Tour Operators'
  },
  '56159': {
    id: 'naics-56159',
    defaultMessage: 'Other Travel Arrangement and Reservation Services'
  },
  '56161': {
    id: 'naics-56161',
    defaultMessage: 'Investigation, Guard, and Armored Car Services'
  },
  '56162': {
    id: 'naics-56162',
    defaultMessage: 'Security Systems Services'
  },
  '56171': {
    id: 'naics-56171',
    defaultMessage: 'Exterminating and Pest Control Services'
  },
  '56172': {
    id: 'naics-56172',
    defaultMessage: 'Janitorial Services'
  },
  '56173': {
    id: 'naics-56173',
    defaultMessage: 'Landscaping Services'
  },
  '56174': {
    id: 'naics-56174',
    defaultMessage: 'Carpet and Upholstery Cleaning Services'
  },
  '56179': {
    id: 'naics-56179',
    defaultMessage: 'Other Services to Buildings and Dwellings'
  },
  '56191': {
    id: 'naics-56191',
    defaultMessage: 'Packaging and Labeling Services'
  },
  '56192': {
    id: 'naics-56192',
    defaultMessage: 'Convention and Trade Show Organizers'
  },
  '56199': {
    id: 'naics-56199',
    defaultMessage: 'All Other Support Services'
  },
  '56211': {
    id: 'naics-56211',
    defaultMessage: 'Waste Collection'
  },
  '56221': {
    id: 'naics-56221',
    defaultMessage: 'Waste Treatment and Disposal'
  },
  '56291': {
    id: 'naics-56291',
    defaultMessage: 'Remediation Services'
  },
  '56292': {
    id: 'naics-56292',
    defaultMessage: 'Materials Recovery Facilities'
  },
  '56299': {
    id: 'naics-56299',
    defaultMessage: 'All Other Waste Management Services'
  },
  '61111': {
    id: 'naics-61111',
    defaultMessage: 'Elementary and Secondary Schools'
  },
  '61121': {
    id: 'naics-61121',
    defaultMessage: 'Junior Colleges'
  },
  '61131': {
    id: 'naics-61131',
    defaultMessage: 'Colleges, Universities, and Professional Schools'
  },
  '61141': {
    id: 'naics-61141',
    defaultMessage: 'Business and Secretarial Schools'
  },
  '61142': {
    id: 'naics-61142',
    defaultMessage: 'Computer Training'
  },
  '61143': {
    id: 'naics-61143',
    defaultMessage: 'Professional and Management Development Training'
  },
  '61151': {
    id: 'naics-61151',
    defaultMessage: 'Technical and Trade Schools'
  },
  '61161': {
    id: 'naics-61161',
    defaultMessage: 'Fine Arts Schools'
  },
  '61162': {
    id: 'naics-61162',
    defaultMessage: 'Sports and Recreation Instruction'
  },
  '61163': {
    id: 'naics-61163',
    defaultMessage: 'Language Schools'
  },
  '61169': {
    id: 'naics-61169',
    defaultMessage: 'All Other Schools and Instruction'
  },
  '61171': {
    id: 'naics-61171',
    defaultMessage: 'Educational Support Services'
  },
  '62111': {
    id: 'naics-62111',
    defaultMessage: 'Offices of Physicians'
  },
  '62121': {
    id: 'naics-62121',
    defaultMessage: 'Offices of Dentists'
  },
  '62131': {
    id: 'naics-62131',
    defaultMessage: 'Offices of Chiropractors'
  },
  '62132': {
    id: 'naics-62132',
    defaultMessage: 'Offices of Optometrists'
  },
  '62133': {
    id: 'naics-62133',
    defaultMessage: 'Offices of Mental Health Practitioners (except Physicians)'
  },
  '62134': {
    id: 'naics-62134',
    defaultMessage:
      'Offices of Physical, Occupational and Speech Therapists, and Audiologists'
  },
  '62139': {
    id: 'naics-62139',
    defaultMessage: 'Offices of All Other Health Practitioners'
  },
  '62141': {
    id: 'naics-62141',
    defaultMessage: 'Family Planning Centers'
  },
  '62142': {
    id: 'naics-62142',
    defaultMessage: 'Outpatient Mental Health and Substance Abuse Centers'
  },
  '62149': {
    id: 'naics-62149',
    defaultMessage: 'Other Outpatient Care Centers'
  },
  '62151': {
    id: 'naics-62151',
    defaultMessage: 'Medical and Diagnostic Laboratories'
  },
  '62161': {
    id: 'naics-62161',
    defaultMessage: 'Home Health Care Services'
  },
  '62191': {
    id: 'naics-62191',
    defaultMessage: 'Ambulance Services'
  },
  '62199': {
    id: 'naics-62199',
    defaultMessage: 'All Other Ambulatory Health Care Services'
  },
  '62211': {
    id: 'naics-62211',
    defaultMessage: 'General Medical and Surgical Hospitals'
  },
  '62221': {
    id: 'naics-62221',
    defaultMessage: 'Psychiatric and Substance Abuse Hospitals'
  },
  '62231': {
    id: 'naics-62231',
    defaultMessage:
      'Specialty (except Psychiatric and Substance Abuse) Hospitals'
  },
  '62311': {
    id: 'naics-62311',
    defaultMessage: 'Nursing Care Facilities (Skilled Nursing Facilities)'
  },
  '62321': {
    id: 'naics-62321',
    defaultMessage:
      'Residential Intellectual and Developmental Disability Facilities'
  },
  '62322': {
    id: 'naics-62322',
    defaultMessage: 'Residential Mental Health and Substance Abuse Facilities'
  },
  '62331': {
    id: 'naics-62331',
    defaultMessage:
      'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly'
  },
  '62399': {
    id: 'naics-62399',
    defaultMessage: 'Other Residential Care Facilities'
  },
  '62411': {
    id: 'naics-62411',
    defaultMessage: 'Child and Youth Services'
  },
  '62412': {
    id: 'naics-62412',
    defaultMessage: 'Services for the Elderly and Persons with Disabilities'
  },
  '62419': {
    id: 'naics-62419',
    defaultMessage: 'Other Individual and Family Services'
  },
  '62421': {
    id: 'naics-62421',
    defaultMessage: 'Community Food Services'
  },
  '62422': {
    id: 'naics-62422',
    defaultMessage: 'Community Housing Services'
  },
  '62423': {
    id: 'naics-62423',
    defaultMessage: 'Emergency and Other Relief Services'
  },
  '62431': {
    id: 'naics-62431',
    defaultMessage: 'Vocational Rehabilitation Services'
  },
  '62441': {
    id: 'naics-62441',
    defaultMessage: 'Child Day Care Services'
  },
  '71111': {
    id: 'naics-71111',
    defaultMessage: 'Theater Companies and Dinner Theaters'
  },
  '71112': {
    id: 'naics-71112',
    defaultMessage: 'Dance Companies'
  },
  '71113': {
    id: 'naics-71113',
    defaultMessage: 'Musical Groups and Artists'
  },
  '71119': {
    id: 'naics-71119',
    defaultMessage: 'Other Performing Arts Companies'
  },
  '71121': {
    id: 'naics-71121',
    defaultMessage: 'Spectator Sports'
  },
  '71131': {
    id: 'naics-71131',
    defaultMessage:
      'Promoters of Performing Arts, Sports, and Similar Events with Facilities'
  },
  '71132': {
    id: 'naics-71132',
    defaultMessage:
      'Promoters of Performing Arts, Sports, and Similar Events without Facilities'
  },
  '71141': {
    id: 'naics-71141',
    defaultMessage:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures'
  },
  '71151': {
    id: 'naics-71151',
    defaultMessage: 'Independent Artists, Writers, and Performers'
  },
  '71211': {
    id: 'naics-71211',
    defaultMessage: 'Museums'
  },
  '71212': {
    id: 'naics-71212',
    defaultMessage: 'Historical Sites'
  },
  '71213': {
    id: 'naics-71213',
    defaultMessage: 'Zoos and Botanical Gardens'
  },
  '71219': {
    id: 'naics-71219',
    defaultMessage: 'Nature Parks and Other Similar Institutions'
  },
  '71311': {
    id: 'naics-71311',
    defaultMessage: 'Amusement and Theme Parks'
  },
  '71312': {
    id: 'naics-71312',
    defaultMessage: 'Amusement Arcades'
  },
  '71321': {
    id: 'naics-71321',
    defaultMessage: 'Casinos (except Casino Hotels)'
  },
  '71329': {
    id: 'naics-71329',
    defaultMessage: 'Other Gambling Industries'
  },
  '71391': {
    id: 'naics-71391',
    defaultMessage: 'Golf Courses and Country Clubs'
  },
  '71392': {
    id: 'naics-71392',
    defaultMessage: 'Skiing Facilities'
  },
  '71393': {
    id: 'naics-71393',
    defaultMessage: 'Marinas'
  },
  '71394': {
    id: 'naics-71394',
    defaultMessage: 'Fitness and Recreational Sports Centers'
  },
  '71395': {
    id: 'naics-71395',
    defaultMessage: 'Bowling Centers'
  },
  '71399': {
    id: 'naics-71399',
    defaultMessage: 'All Other Amusement and Recreation Industries'
  },
  '72111': {
    id: 'naics-72111',
    defaultMessage: 'Hotels (except Casino Hotels) and Motels'
  },
  '72112': {
    id: 'naics-72112',
    defaultMessage: 'Casino Hotels'
  },
  '72119': {
    id: 'naics-72119',
    defaultMessage: 'Other Traveler Accommodation'
  },
  '72121': {
    id: 'naics-72121',
    defaultMessage: 'RV (Recreational Vehicle) Parks and Recreational Camps'
  },
  '72131': {
    id: 'naics-72131',
    defaultMessage:
      "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
  },
  '72231': {
    id: 'naics-72231',
    defaultMessage: 'Food Service Contractors'
  },
  '72232': {
    id: 'naics-72232',
    defaultMessage: 'Caterers'
  },
  '72233': {
    id: 'naics-72233',
    defaultMessage: 'Mobile Food Services'
  },
  '72241': {
    id: 'naics-72241',
    defaultMessage: 'Drinking Places (Alcoholic Beverages)'
  },
  '72251': {
    id: 'naics-72251',
    defaultMessage: 'Restaurants and Other Eating Places'
  },
  '81111': {
    id: 'naics-81111',
    defaultMessage:
      'Automotive Mechanical and Electrical Repair and Maintenance'
  },
  '81112': {
    id: 'naics-81112',
    defaultMessage: 'Automotive Body, Paint, Interior, and Glass Repair'
  },
  '81119': {
    id: 'naics-81119',
    defaultMessage: 'Other Automotive Repair and Maintenance'
  },
  '81121': {
    id: 'naics-81121',
    defaultMessage: 'Electronic and Precision Equipment Repair and Maintenance'
  },
  '81131': {
    id: 'naics-81131',
    defaultMessage:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance'
  },
  '81141': {
    id: 'naics-81141',
    defaultMessage:
      'Home and Garden Equipment and Appliance Repair and Maintenance'
  },
  '81142': {
    id: 'naics-81142',
    defaultMessage: 'Reupholstery and Furniture Repair'
  },
  '81143': {
    id: 'naics-81143',
    defaultMessage: 'Footwear and Leather Goods Repair'
  },
  '81149': {
    id: 'naics-81149',
    defaultMessage: 'Other Personal and Household Goods Repair and Maintenance'
  },
  '81211': {
    id: 'naics-81211',
    defaultMessage: 'Hair, Nail, and Skin Care Services'
  },
  '81219': {
    id: 'naics-81219',
    defaultMessage: 'Other Personal Care Services'
  },
  '81221': {
    id: 'naics-81221',
    defaultMessage: 'Funeral Homes and Funeral Services'
  },
  '81222': {
    id: 'naics-81222',
    defaultMessage: 'Cemeteries and Crematories'
  },
  '81231': {
    id: 'naics-81231',
    defaultMessage: 'Coin-Operated Laundries and Drycleaners'
  },
  '81232': {
    id: 'naics-81232',
    defaultMessage: 'Drycleaning and Laundry Services (except Coin-Operated)'
  },
  '81233': {
    id: 'naics-81233',
    defaultMessage: 'Linen and Uniform Supply'
  },
  '81291': {
    id: 'naics-81291',
    defaultMessage: 'Pet Care (except Veterinary) Services'
  },
  '81292': {
    id: 'naics-81292',
    defaultMessage: 'Photofinishing'
  },
  '81293': {
    id: 'naics-81293',
    defaultMessage: 'Parking Lots and Garages'
  },
  '81299': {
    id: 'naics-81299',
    defaultMessage: 'All Other Personal Services'
  },
  '81311': {
    id: 'naics-81311',
    defaultMessage: 'Religious Organizations'
  },
  '81321': {
    id: 'naics-81321',
    defaultMessage: 'Grantmaking and Giving Services'
  },
  '81331': {
    id: 'naics-81331',
    defaultMessage: 'Social Advocacy Organizations'
  },
  '81341': {
    id: 'naics-81341',
    defaultMessage: 'Civic and Social Organizations'
  },
  '81391': {
    id: 'naics-81391',
    defaultMessage: 'Business Associations'
  },
  '81392': {
    id: 'naics-81392',
    defaultMessage: 'Professional Organizations'
  },
  '81393': {
    id: 'naics-81393',
    defaultMessage: 'Labor Unions and Similar Labor Organizations'
  },
  '81394': {
    id: 'naics-81394',
    defaultMessage: 'Political Organizations'
  },
  '81399': {
    id: 'naics-81399',
    defaultMessage:
      'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)'
  },
  '81411': {
    id: 'naics-81411',
    defaultMessage: 'Private Households'
  },
  '92111': {
    id: 'naics-92111',
    defaultMessage: 'Executive Offices'
  },
  '92112': {
    id: 'naics-92112',
    defaultMessage: 'Legislative Bodies'
  },
  '92113': {
    id: 'naics-92113',
    defaultMessage: 'Public Finance Activities'
  },
  '92114': {
    id: 'naics-92114',
    defaultMessage: 'Executive and Legislative Offices, Combined'
  },
  '92115': {
    id: 'naics-92115',
    defaultMessage: 'American Indian and Alaska Native Tribal Governments'
  },
  '92119': {
    id: 'naics-92119',
    defaultMessage: 'Other General Government Support'
  },
  '92211': {
    id: 'naics-92211',
    defaultMessage: 'Courts'
  },
  '92212': {
    id: 'naics-92212',
    defaultMessage: 'Police Protection'
  },
  '92213': {
    id: 'naics-92213',
    defaultMessage: 'Legal Counsel and Prosecution'
  },
  '92214': {
    id: 'naics-92214',
    defaultMessage: 'Correctional Institutions'
  },
  '92215': {
    id: 'naics-92215',
    defaultMessage: 'Parole Offices and Probation Offices'
  },
  '92216': {
    id: 'naics-92216',
    defaultMessage: 'Fire Protection'
  },
  '92219': {
    id: 'naics-92219',
    defaultMessage: 'Other Justice, Public Order, and Safety Activities'
  },
  '92311': {
    id: 'naics-92311',
    defaultMessage: 'Administration of Education Programs'
  },
  '92312': {
    id: 'naics-92312',
    defaultMessage: 'Administration of Public Health Programs'
  },
  '92313': {
    id: 'naics-92313',
    defaultMessage:
      "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)"
  },
  '92314': {
    id: 'naics-92314',
    defaultMessage: "Administration of Veterans' Affairs"
  },
  '92411': {
    id: 'naics-92411',
    defaultMessage:
      'Administration of Air and Water Resource and Solid Waste Management Programs'
  },
  '92412': {
    id: 'naics-92412',
    defaultMessage: 'Administration of Conservation Programs'
  },
  '92511': {
    id: 'naics-92511',
    defaultMessage: 'Administration of Housing Programs'
  },
  '92512': {
    id: 'naics-92512',
    defaultMessage:
      'Administration of Urban Planning and Community and Rural Development'
  },
  '92611': {
    id: 'naics-92611',
    defaultMessage: 'Administration of General Economic Programs'
  },
  '92612': {
    id: 'naics-92612',
    defaultMessage: 'Regulation and Administration of Transportation Programs'
  },
  '92613': {
    id: 'naics-92613',
    defaultMessage:
      'Regulation and Administration of Communications, Electric, Gas, and Other Utilities'
  },
  '92614': {
    id: 'naics-92614',
    defaultMessage: 'Regulation of Agricultural Marketing and Commodities'
  },
  '92615': {
    id: 'naics-92615',
    defaultMessage:
      'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors'
  },
  '92711': {
    id: 'naics-92711',
    defaultMessage: 'Space Research and Technology'
  },
  '92811': {
    id: 'naics-92811',
    defaultMessage: 'National Security'
  },
  '92812': {
    id: 'naics-92812',
    defaultMessage: 'International Affairs'
  },
  '111110': {
    id: 'naics-111110',
    defaultMessage: 'Soybean Farming'
  },
  '111120': {
    id: 'naics-111120',
    defaultMessage: 'Oilseed (except Soybean) Farming'
  },
  '111130': {
    id: 'naics-111130',
    defaultMessage: 'Dry Pea and Bean Farming'
  },
  '111140': {
    id: 'naics-111140',
    defaultMessage: 'Wheat Farming'
  },
  '111150': {
    id: 'naics-111150',
    defaultMessage: 'Corn Farming'
  },
  '111160': {
    id: 'naics-111160',
    defaultMessage: 'Rice Farming'
  },
  '111191': {
    id: 'naics-111191',
    defaultMessage: 'Oilseed and Grain Combination Farming'
  },
  '111199': {
    id: 'naics-111199',
    defaultMessage: 'All Other Grain Farming'
  },
  '111211': {
    id: 'naics-111211',
    defaultMessage: 'Potato Farming'
  },
  '111219': {
    id: 'naics-111219',
    defaultMessage: 'Other Vegetable (except Potato) and Melon Farming'
  },
  '111310': {
    id: 'naics-111310',
    defaultMessage: 'Orange Groves'
  },
  '111320': {
    id: 'naics-111320',
    defaultMessage: 'Citrus (except Orange) Groves'
  },
  '111331': {
    id: 'naics-111331',
    defaultMessage: 'Apple Orchards'
  },
  '111332': {
    id: 'naics-111332',
    defaultMessage: 'Grape Vineyards'
  },
  '111333': {
    id: 'naics-111333',
    defaultMessage: 'Strawberry Farming'
  },
  '111334': {
    id: 'naics-111334',
    defaultMessage: 'Berry (except Strawberry) Farming'
  },
  '111335': {
    id: 'naics-111335',
    defaultMessage: 'Tree Nut Farming'
  },
  '111336': {
    id: 'naics-111336',
    defaultMessage: 'Fruit and Tree Nut Combination Farming'
  },
  '111339': {
    id: 'naics-111339',
    defaultMessage: 'Other Noncitrus Fruit Farming'
  },
  '111411': {
    id: 'naics-111411',
    defaultMessage: 'Mushroom Production'
  },
  '111419': {
    id: 'naics-111419',
    defaultMessage: 'Other Food Crops Grown Under Cover'
  },
  '111421': {
    id: 'naics-111421',
    defaultMessage: 'Nursery and Tree Production'
  },
  '111422': {
    id: 'naics-111422',
    defaultMessage: 'Floriculture Production'
  },
  '111910': {
    id: 'naics-111910',
    defaultMessage: 'Tobacco Farming'
  },
  '111920': {
    id: 'naics-111920',
    defaultMessage: 'Cotton Farming'
  },
  '111930': {
    id: 'naics-111930',
    defaultMessage: 'Sugarcane Farming'
  },
  '111940': {
    id: 'naics-111940',
    defaultMessage: 'Hay Farming'
  },
  '111991': {
    id: 'naics-111991',
    defaultMessage: 'Sugar Beet Farming'
  },
  '111992': {
    id: 'naics-111992',
    defaultMessage: 'Peanut Farming'
  },
  '111998': {
    id: 'naics-111998',
    defaultMessage: 'All Other Miscellaneous Crop Farming'
  },
  '112111': {
    id: 'naics-112111',
    defaultMessage: 'Beef Cattle Ranching and Farming'
  },
  '112112': {
    id: 'naics-112112',
    defaultMessage: 'Cattle Feedlots'
  },
  '112120': {
    id: 'naics-112120',
    defaultMessage: 'Dairy Cattle and Milk Production'
  },
  '112130': {
    id: 'naics-112130',
    defaultMessage: 'Dual-Purpose Cattle Ranching and Farming'
  },
  '112210': {
    id: 'naics-112210',
    defaultMessage: 'Hog and Pig Farming'
  },
  '112310': {
    id: 'naics-112310',
    defaultMessage: 'Chicken Egg Production'
  },
  '112320': {
    id: 'naics-112320',
    defaultMessage: 'Broilers and Other Meat Type Chicken Production'
  },
  '112330': {
    id: 'naics-112330',
    defaultMessage: 'Turkey Production'
  },
  '112340': {
    id: 'naics-112340',
    defaultMessage: 'Poultry Hatcheries'
  },
  '112390': {
    id: 'naics-112390',
    defaultMessage: 'Other Poultry Production'
  },
  '112410': {
    id: 'naics-112410',
    defaultMessage: 'Sheep Farming'
  },
  '112420': {
    id: 'naics-112420',
    defaultMessage: 'Goat Farming'
  },
  '112511': {
    id: 'naics-112511',
    defaultMessage: 'Finfish Farming and Fish Hatcheries'
  },
  '112512': {
    id: 'naics-112512',
    defaultMessage: 'Shellfish Farming'
  },
  '112519': {
    id: 'naics-112519',
    defaultMessage: 'Other Aquaculture'
  },
  '112910': {
    id: 'naics-112910',
    defaultMessage: 'Apiculture'
  },
  '112920': {
    id: 'naics-112920',
    defaultMessage: 'Horses and Other Equine Production'
  },
  '112930': {
    id: 'naics-112930',
    defaultMessage: 'Fur-Bearing Animal and Rabbit Production'
  },
  '112990': {
    id: 'naics-112990',
    defaultMessage: 'All Other Animal Production'
  },
  '113110': {
    id: 'naics-113110',
    defaultMessage: 'Timber Tract Operations'
  },
  '113210': {
    id: 'naics-113210',
    defaultMessage: 'Forest Nurseries and Gathering of Forest Products'
  },
  '113310': {
    id: 'naics-113310',
    defaultMessage: 'Logging'
  },
  '114111': {
    id: 'naics-114111',
    defaultMessage: 'Finfish Fishing'
  },
  '114112': {
    id: 'naics-114112',
    defaultMessage: 'Shellfish Fishing'
  },
  '114119': {
    id: 'naics-114119',
    defaultMessage: 'Other Marine Fishing'
  },
  '114210': {
    id: 'naics-114210',
    defaultMessage: 'Hunting and Trapping'
  },
  '115111': {
    id: 'naics-115111',
    defaultMessage: 'Cotton Ginning'
  },
  '115112': {
    id: 'naics-115112',
    defaultMessage: 'Soil Preparation, Planting, and Cultivating'
  },
  '115113': {
    id: 'naics-115113',
    defaultMessage: 'Crop Harvesting, Primarily by Machine'
  },
  '115114': {
    id: 'naics-115114',
    defaultMessage: 'Postharvest Crop Activities (except Cotton Ginning)'
  },
  '115115': {
    id: 'naics-115115',
    defaultMessage: 'Farm Labor Contractors and Crew Leaders'
  },
  '115116': {
    id: 'naics-115116',
    defaultMessage: 'Farm Management Services'
  },
  '115210': {
    id: 'naics-115210',
    defaultMessage: 'Support Activities for Animal Production'
  },
  '115310': {
    id: 'naics-115310',
    defaultMessage: 'Support Activities for Forestry'
  },
  '211120': {
    id: 'naics-211120',
    defaultMessage: 'Crude Petroleum Extraction'
  },
  '211130': {
    id: 'naics-211130',
    defaultMessage: 'Natural Gas Extraction'
  },
  '212111': {
    id: 'naics-212111',
    defaultMessage: 'Bituminous Coal and Lignite Surface Mining'
  },
  '212112': {
    id: 'naics-212112',
    defaultMessage: 'Bituminous Coal Underground Mining'
  },
  '212113': {
    id: 'naics-212113',
    defaultMessage: 'Anthracite Mining'
  },
  '212210': {
    id: 'naics-212210',
    defaultMessage: 'Iron Ore Mining'
  },
  '212221': {
    id: 'naics-212221',
    defaultMessage: 'Gold Ore Mining'
  },
  '212222': {
    id: 'naics-212222',
    defaultMessage: 'Silver Ore Mining'
  },
  '212230': {
    id: 'naics-212230',
    defaultMessage: 'Copper, Nickel, Lead, and Zinc Mining'
  },
  '212291': {
    id: 'naics-212291',
    defaultMessage: 'Uranium-Radium-Vanadium Ore Mining'
  },
  '212299': {
    id: 'naics-212299',
    defaultMessage: 'All Other Metal Ore Mining'
  },
  '212311': {
    id: 'naics-212311',
    defaultMessage: 'Dimension Stone Mining and Quarrying'
  },
  '212312': {
    id: 'naics-212312',
    defaultMessage: 'Crushed and Broken Limestone Mining and Quarrying'
  },
  '212313': {
    id: 'naics-212313',
    defaultMessage: 'Crushed and Broken Granite Mining and Quarrying'
  },
  '212319': {
    id: 'naics-212319',
    defaultMessage: 'Other Crushed and Broken Stone Mining and Quarrying'
  },
  '212321': {
    id: 'naics-212321',
    defaultMessage: 'Construction Sand and Gravel Mining'
  },
  '212322': {
    id: 'naics-212322',
    defaultMessage: 'Industrial Sand Mining'
  },
  '212324': {
    id: 'naics-212324',
    defaultMessage: 'Kaolin and Ball Clay Mining'
  },
  '212325': {
    id: 'naics-212325',
    defaultMessage: 'Clay and Ceramic and Refractory Minerals Mining'
  },
  '212391': {
    id: 'naics-212391',
    defaultMessage: 'Potash, Soda, and Borate Mineral Mining'
  },
  '212392': {
    id: 'naics-212392',
    defaultMessage: 'Phosphate Rock Mining'
  },
  '212393': {
    id: 'naics-212393',
    defaultMessage: 'Other Chemical and Fertilizer Mineral Mining'
  },
  '212399': {
    id: 'naics-212399',
    defaultMessage: 'All Other Nonmetallic Mineral Mining'
  },
  '213111': {
    id: 'naics-213111',
    defaultMessage: 'Drilling Oil and Gas Wells'
  },
  '213112': {
    id: 'naics-213112',
    defaultMessage: 'Support Activities for Oil and Gas Operations'
  },
  '213113': {
    id: 'naics-213113',
    defaultMessage: 'Support Activities for Coal Mining'
  },
  '213114': {
    id: 'naics-213114',
    defaultMessage: 'Support Activities for Metal Mining'
  },
  '213115': {
    id: 'naics-213115',
    defaultMessage:
      'Support Activities for Nonmetallic Minerals (except Fuels) Mining'
  },
  '221111': {
    id: 'naics-221111',
    defaultMessage: 'Hydroelectric Power Generation'
  },
  '221112': {
    id: 'naics-221112',
    defaultMessage: 'Fossil Fuel Electric Power Generation'
  },
  '221113': {
    id: 'naics-221113',
    defaultMessage: 'Nuclear Electric Power Generation'
  },
  '221114': {
    id: 'naics-221114',
    defaultMessage: 'Solar Electric Power Generation'
  },
  '221115': {
    id: 'naics-221115',
    defaultMessage: 'Wind Electric Power Generation'
  },
  '221116': {
    id: 'naics-221116',
    defaultMessage: 'Geothermal Electric Power Generation'
  },
  '221117': {
    id: 'naics-221117',
    defaultMessage: 'Biomass Electric Power Generation'
  },
  '221118': {
    id: 'naics-221118',
    defaultMessage: 'Other Electric Power Generation'
  },
  '221121': {
    id: 'naics-221121',
    defaultMessage: 'Electric Bulk Power Transmission and Control'
  },
  '221122': {
    id: 'naics-221122',
    defaultMessage: 'Electric Power Distribution'
  },
  '221210': {
    id: 'naics-221210',
    defaultMessage: 'Natural Gas Distribution'
  },
  '221310': {
    id: 'naics-221310',
    defaultMessage: 'Water Supply and Irrigation Systems'
  },
  '221320': {
    id: 'naics-221320',
    defaultMessage: 'Sewage Treatment Facilities'
  },
  '221330': {
    id: 'naics-221330',
    defaultMessage: 'Steam and Air-Conditioning Supply'
  },
  '236115': {
    id: 'naics-236115',
    defaultMessage:
      'New Single-Family Housing Construction (except For-Sale Builders)'
  },
  '236116': {
    id: 'naics-236116',
    defaultMessage:
      'New Multifamily Housing Construction (except For-Sale Builders)'
  },
  '236117': {
    id: 'naics-236117',
    defaultMessage: 'New Housing For-Sale Builders'
  },
  '236118': {
    id: 'naics-236118',
    defaultMessage: 'Residential Remodelers'
  },
  '236210': {
    id: 'naics-236210',
    defaultMessage: 'Industrial Building Construction'
  },
  '236220': {
    id: 'naics-236220',
    defaultMessage: 'Commercial and Institutional Building Construction'
  },
  '237110': {
    id: 'naics-237110',
    defaultMessage: 'Water and Sewer Line and Related Structures Construction'
  },
  '237120': {
    id: 'naics-237120',
    defaultMessage: 'Oil and Gas Pipeline and Related Structures Construction'
  },
  '237130': {
    id: 'naics-237130',
    defaultMessage:
      'Power and Communication Line and Related Structures Construction'
  },
  '237210': {
    id: 'naics-237210',
    defaultMessage: 'Land Subdivision'
  },
  '237310': {
    id: 'naics-237310',
    defaultMessage: 'Highway, Street, and Bridge Construction'
  },
  '237990': {
    id: 'naics-237990',
    defaultMessage: 'Other Heavy and Civil Engineering Construction'
  },
  '238110': {
    id: 'naics-238110',
    defaultMessage: 'Poured Concrete Foundation and Structure Contractors'
  },
  '238120': {
    id: 'naics-238120',
    defaultMessage: 'Structural Steel and Precast Concrete Contractors'
  },
  '238130': {
    id: 'naics-238130',
    defaultMessage: 'Framing Contractors'
  },
  '238140': {
    id: 'naics-238140',
    defaultMessage: 'Masonry Contractors'
  },
  '238150': {
    id: 'naics-238150',
    defaultMessage: 'Glass and Glazing Contractors'
  },
  '238160': {
    id: 'naics-238160',
    defaultMessage: 'Roofing Contractors'
  },
  '238170': {
    id: 'naics-238170',
    defaultMessage: 'Siding Contractors'
  },
  '238190': {
    id: 'naics-238190',
    defaultMessage:
      'Other Foundation, Structure, and Building Exterior Contractors'
  },
  '238210': {
    id: 'naics-238210',
    defaultMessage:
      'Electrical Contractors and Other Wiring Installation Contractors'
  },
  '238220': {
    id: 'naics-238220',
    defaultMessage: 'Plumbing, Heating, and Air-Conditioning Contractors'
  },
  '238290': {
    id: 'naics-238290',
    defaultMessage: 'Other Building Equipment Contractors'
  },
  '238310': {
    id: 'naics-238310',
    defaultMessage: 'Drywall and Insulation Contractors'
  },
  '238320': {
    id: 'naics-238320',
    defaultMessage: 'Painting and Wall Covering Contractors'
  },
  '238330': {
    id: 'naics-238330',
    defaultMessage: 'Flooring Contractors'
  },
  '238340': {
    id: 'naics-238340',
    defaultMessage: 'Tile and Terrazzo Contractors'
  },
  '238350': {
    id: 'naics-238350',
    defaultMessage: 'Finish Carpentry Contractors'
  },
  '238390': {
    id: 'naics-238390',
    defaultMessage: 'Other Building Finishing Contractors'
  },
  '238910': {
    id: 'naics-238910',
    defaultMessage: 'Site Preparation Contractors'
  },
  '238990': {
    id: 'naics-238990',
    defaultMessage: 'All Other Specialty Trade Contractors'
  },
  '311111': {
    id: 'naics-311111',
    defaultMessage: 'Dog and Cat Food Manufacturing'
  },
  '311119': {
    id: 'naics-311119',
    defaultMessage: 'Other Animal Food Manufacturing'
  },
  '311211': {
    id: 'naics-311211',
    defaultMessage: 'Flour Milling'
  },
  '311212': {
    id: 'naics-311212',
    defaultMessage: 'Rice Milling'
  },
  '311213': {
    id: 'naics-311213',
    defaultMessage: 'Malt Manufacturing'
  },
  '311221': {
    id: 'naics-311221',
    defaultMessage: 'Wet Corn Milling'
  },
  '311224': {
    id: 'naics-311224',
    defaultMessage: 'Soybean and Other Oilseed Processing'
  },
  '311225': {
    id: 'naics-311225',
    defaultMessage: 'Fats and Oils Refining and Blending'
  },
  '311230': {
    id: 'naics-311230',
    defaultMessage: 'Breakfast Cereal Manufacturing'
  },
  '311313': {
    id: 'naics-311313',
    defaultMessage: 'Beet Sugar Manufacturing'
  },
  '311314': {
    id: 'naics-311314',
    defaultMessage: 'Cane Sugar Manufacturing'
  },
  '311340': {
    id: 'naics-311340',
    defaultMessage: 'Nonchocolate Confectionery Manufacturing'
  },
  '311351': {
    id: 'naics-311351',
    defaultMessage: 'Chocolate and Confectionery Manufacturing from Cacao Beans'
  },
  '311352': {
    id: 'naics-311352',
    defaultMessage: 'Confectionery Manufacturing from Purchased Chocolate'
  },
  '311411': {
    id: 'naics-311411',
    defaultMessage: 'Frozen Fruit, Juice, and Vegetable Manufacturing'
  },
  '311412': {
    id: 'naics-311412',
    defaultMessage: 'Frozen Specialty Food Manufacturing'
  },
  '311421': {
    id: 'naics-311421',
    defaultMessage: 'Fruit and Vegetable Canning'
  },
  '311422': {
    id: 'naics-311422',
    defaultMessage: 'Specialty Canning'
  },
  '311423': {
    id: 'naics-311423',
    defaultMessage: 'Dried and Dehydrated Food Manufacturing'
  },
  '311511': {
    id: 'naics-311511',
    defaultMessage: 'Fluid Milk Manufacturing'
  },
  '311512': {
    id: 'naics-311512',
    defaultMessage: 'Creamery Butter Manufacturing'
  },
  '311513': {
    id: 'naics-311513',
    defaultMessage: 'Cheese Manufacturing'
  },
  '311514': {
    id: 'naics-311514',
    defaultMessage: 'Dry, Condensed, and Evaporated Dairy Product Manufacturing'
  },
  '311520': {
    id: 'naics-311520',
    defaultMessage: 'Ice Cream and Frozen Dessert Manufacturing'
  },
  '311611': {
    id: 'naics-311611',
    defaultMessage: 'Animal (except Poultry) Slaughtering'
  },
  '311612': {
    id: 'naics-311612',
    defaultMessage: 'Meat Processed from Carcasses'
  },
  '311613': {
    id: 'naics-311613',
    defaultMessage: 'Rendering and Meat Byproduct Processing'
  },
  '311615': {
    id: 'naics-311615',
    defaultMessage: 'Poultry Processing'
  },
  '311710': {
    id: 'naics-311710',
    defaultMessage: 'Seafood Product Preparation and Packaging'
  },
  '311811': {
    id: 'naics-311811',
    defaultMessage: 'Retail Bakeries'
  },
  '311812': {
    id: 'naics-311812',
    defaultMessage: 'Commercial Bakeries'
  },
  '311813': {
    id: 'naics-311813',
    defaultMessage: 'Frozen Cakes, Pies, and Other Pastries Manufacturing'
  },
  '311821': {
    id: 'naics-311821',
    defaultMessage: 'Cookie and Cracker Manufacturing'
  },
  '311824': {
    id: 'naics-311824',
    defaultMessage:
      'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour'
  },
  '311830': {
    id: 'naics-311830',
    defaultMessage: 'Tortilla Manufacturing'
  },
  '311911': {
    id: 'naics-311911',
    defaultMessage: 'Roasted Nuts and Peanut Butter Manufacturing'
  },
  '311919': {
    id: 'naics-311919',
    defaultMessage: 'Other Snack Food Manufacturing'
  },
  '311920': {
    id: 'naics-311920',
    defaultMessage: 'Coffee and Tea Manufacturing'
  },
  '311930': {
    id: 'naics-311930',
    defaultMessage: 'Flavoring Syrup and Concentrate Manufacturing'
  },
  '311941': {
    id: 'naics-311941',
    defaultMessage:
      'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing'
  },
  '311942': {
    id: 'naics-311942',
    defaultMessage: 'Spice and Extract Manufacturing'
  },
  '311991': {
    id: 'naics-311991',
    defaultMessage: 'Perishable Prepared Food Manufacturing'
  },
  '311999': {
    id: 'naics-311999',
    defaultMessage: 'All Other Miscellaneous Food Manufacturing'
  },
  '312111': {
    id: 'naics-312111',
    defaultMessage: 'Soft Drink Manufacturing'
  },
  '312112': {
    id: 'naics-312112',
    defaultMessage: 'Bottled Water Manufacturing'
  },
  '312113': {
    id: 'naics-312113',
    defaultMessage: 'Ice Manufacturing'
  },
  '312120': {
    id: 'naics-312120',
    defaultMessage: 'Breweries'
  },
  '312130': {
    id: 'naics-312130',
    defaultMessage: 'Wineries'
  },
  '312140': {
    id: 'naics-312140',
    defaultMessage: 'Distilleries'
  },
  '312230': {
    id: 'naics-312230',
    defaultMessage: 'Tobacco Manufacturing'
  },
  '313110': {
    id: 'naics-313110',
    defaultMessage: 'Fiber, Yarn, and Thread Mills'
  },
  '313210': {
    id: 'naics-313210',
    defaultMessage: 'Broadwoven Fabric Mills'
  },
  '313220': {
    id: 'naics-313220',
    defaultMessage: 'Narrow Fabric Mills and Schiffli Machine Embroidery'
  },
  '313230': {
    id: 'naics-313230',
    defaultMessage: 'Nonwoven Fabric Mills'
  },
  '313240': {
    id: 'naics-313240',
    defaultMessage: 'Knit Fabric Mills'
  },
  '313310': {
    id: 'naics-313310',
    defaultMessage: 'Textile and Fabric Finishing Mills'
  },
  '313320': {
    id: 'naics-313320',
    defaultMessage: 'Fabric Coating Mills'
  },
  '314110': {
    id: 'naics-314110',
    defaultMessage: 'Carpet and Rug Mills'
  },
  '314120': {
    id: 'naics-314120',
    defaultMessage: 'Curtain and Linen Mills'
  },
  '314910': {
    id: 'naics-314910',
    defaultMessage: 'Textile Bag and Canvas Mills'
  },
  '314994': {
    id: 'naics-314994',
    defaultMessage: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills'
  },
  '314999': {
    id: 'naics-314999',
    defaultMessage: 'All Other Miscellaneous Textile Product Mills'
  },
  '315110': {
    id: 'naics-315110',
    defaultMessage: 'Hosiery and Sock Mills'
  },
  '315190': {
    id: 'naics-315190',
    defaultMessage: 'Other Apparel Knitting Mills'
  },
  '315210': {
    id: 'naics-315210',
    defaultMessage: 'Cut and Sew Apparel Contractors'
  },
  '315220': {
    id: 'naics-315220',
    defaultMessage: 'Men’s and Boys’ Cut and Sew Apparel Manufacturing'
  },
  '315240': {
    id: 'naics-315240',
    defaultMessage:
      'Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing'
  },
  '315280': {
    id: 'naics-315280',
    defaultMessage: 'Other Cut and Sew Apparel Manufacturing'
  },
  '315990': {
    id: 'naics-315990',
    defaultMessage: 'Apparel Accessories and Other Apparel Manufacturing'
  },
  '316110': {
    id: 'naics-316110',
    defaultMessage: 'Leather and Hide Tanning and Finishing'
  },
  '316210': {
    id: 'naics-316210',
    defaultMessage: 'Footwear Manufacturing'
  },
  '316992': {
    id: 'naics-316992',
    defaultMessage: "Women's Handbag and Purse Manufacturing"
  },
  '316998': {
    id: 'naics-316998',
    defaultMessage: 'All Other Leather Good and Allied Product Manufacturing'
  },
  '321113': {
    id: 'naics-321113',
    defaultMessage: 'Sawmills'
  },
  '321114': {
    id: 'naics-321114',
    defaultMessage: 'Wood Preservation'
  },
  '321211': {
    id: 'naics-321211',
    defaultMessage: 'Hardwood Veneer and Plywood Manufacturing'
  },
  '321212': {
    id: 'naics-321212',
    defaultMessage: 'Softwood Veneer and Plywood Manufacturing'
  },
  '321213': {
    id: 'naics-321213',
    defaultMessage: 'Engineered Wood Member (except Truss) Manufacturing'
  },
  '321214': {
    id: 'naics-321214',
    defaultMessage: 'Truss Manufacturing'
  },
  '321219': {
    id: 'naics-321219',
    defaultMessage: 'Reconstituted Wood Product Manufacturing'
  },
  '321911': {
    id: 'naics-321911',
    defaultMessage: 'Wood Window and Door Manufacturing'
  },
  '321912': {
    id: 'naics-321912',
    defaultMessage: 'Cut Stock, Resawing Lumber, and Planing'
  },
  '321918': {
    id: 'naics-321918',
    defaultMessage: 'Other Millwork (including Flooring)'
  },
  '321920': {
    id: 'naics-321920',
    defaultMessage: 'Wood Container and Pallet Manufacturing'
  },
  '321991': {
    id: 'naics-321991',
    defaultMessage: 'Manufactured Home (Mobile Home) Manufacturing'
  },
  '321992': {
    id: 'naics-321992',
    defaultMessage: 'Prefabricated Wood Building Manufacturing'
  },
  '321999': {
    id: 'naics-321999',
    defaultMessage: 'All Other Miscellaneous Wood Product Manufacturing'
  },
  '322110': {
    id: 'naics-322110',
    defaultMessage: 'Pulp Mills'
  },
  '322121': {
    id: 'naics-322121',
    defaultMessage: 'Paper (except Newsprint) Mills'
  },
  '322122': {
    id: 'naics-322122',
    defaultMessage: 'Newsprint Mills'
  },
  '322130': {
    id: 'naics-322130',
    defaultMessage: 'Paperboard Mills'
  },
  '322211': {
    id: 'naics-322211',
    defaultMessage: 'Corrugated and Solid Fiber Box Manufacturing'
  },
  '322212': {
    id: 'naics-322212',
    defaultMessage: 'Folding Paperboard Box Manufacturing'
  },
  '322219': {
    id: 'naics-322219',
    defaultMessage: 'Other Paperboard Container Manufacturing'
  },
  '322220': {
    id: 'naics-322220',
    defaultMessage: 'Paper Bag and Coated and Treated Paper Manufacturing'
  },
  '322230': {
    id: 'naics-322230',
    defaultMessage: 'Stationery Product Manufacturing'
  },
  '322291': {
    id: 'naics-322291',
    defaultMessage: 'Sanitary Paper Product Manufacturing'
  },
  '322299': {
    id: 'naics-322299',
    defaultMessage: 'All Other Converted Paper Product Manufacturing'
  },
  '323111': {
    id: 'naics-323111',
    defaultMessage: 'Commercial Printing (except Screen and Books)'
  },
  '323113': {
    id: 'naics-323113',
    defaultMessage: 'Commercial Screen Printing'
  },
  '323117': {
    id: 'naics-323117',
    defaultMessage: 'Books Printing'
  },
  '323120': {
    id: 'naics-323120',
    defaultMessage: 'Support Activities for Printing'
  },
  '324110': {
    id: 'naics-324110',
    defaultMessage: 'Petroleum Refineries'
  },
  '324121': {
    id: 'naics-324121',
    defaultMessage: 'Asphalt Paving Mixture and Block Manufacturing'
  },
  '324122': {
    id: 'naics-324122',
    defaultMessage: 'Asphalt Shingle and Coating Materials Manufacturing'
  },
  '324191': {
    id: 'naics-324191',
    defaultMessage: 'Petroleum Lubricating Oil and Grease Manufacturing'
  },
  '324199': {
    id: 'naics-324199',
    defaultMessage: 'All Other Petroleum and Coal Products Manufacturing'
  },
  '325110': {
    id: 'naics-325110',
    defaultMessage: 'Petrochemical Manufacturing'
  },
  '325120': {
    id: 'naics-325120',
    defaultMessage: 'Industrial Gas Manufacturing'
  },
  '325130': {
    id: 'naics-325130',
    defaultMessage: 'Synthetic Dye and Pigment Manufacturing'
  },
  '325180': {
    id: 'naics-325180',
    defaultMessage: 'Other Basic Inorganic Chemical Manufacturing'
  },
  '325193': {
    id: 'naics-325193',
    defaultMessage: 'Ethyl Alcohol Manufacturing'
  },
  '325194': {
    id: 'naics-325194',
    defaultMessage:
      'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing'
  },
  '325199': {
    id: 'naics-325199',
    defaultMessage: 'All Other Basic Organic Chemical Manufacturing'
  },
  '325211': {
    id: 'naics-325211',
    defaultMessage: 'Plastics Material and Resin Manufacturing'
  },
  '325212': {
    id: 'naics-325212',
    defaultMessage: 'Synthetic Rubber Manufacturing'
  },
  '325220': {
    id: 'naics-325220',
    defaultMessage:
      'Artificial and Synthetic Fibers and Filaments Manufacturing'
  },
  '325311': {
    id: 'naics-325311',
    defaultMessage: 'Nitrogenous Fertilizer Manufacturing'
  },
  '325312': {
    id: 'naics-325312',
    defaultMessage: 'Phosphatic Fertilizer Manufacturing'
  },
  '325314': {
    id: 'naics-325314',
    defaultMessage: 'Fertilizer (Mixing Only) Manufacturing'
  },
  '325320': {
    id: 'naics-325320',
    defaultMessage: 'Pesticide and Other Agricultural Chemical Manufacturing'
  },
  '325411': {
    id: 'naics-325411',
    defaultMessage: 'Medicinal and Botanical Manufacturing'
  },
  '325412': {
    id: 'naics-325412',
    defaultMessage: 'Pharmaceutical Preparation Manufacturing'
  },
  '325413': {
    id: 'naics-325413',
    defaultMessage: 'In-Vitro Diagnostic Substance Manufacturing'
  },
  '325414': {
    id: 'naics-325414',
    defaultMessage: 'Biological Product (except Diagnostic) Manufacturing'
  },
  '325510': {
    id: 'naics-325510',
    defaultMessage: 'Paint and Coating Manufacturing'
  },
  '325520': {
    id: 'naics-325520',
    defaultMessage: 'Adhesive Manufacturing'
  },
  '325611': {
    id: 'naics-325611',
    defaultMessage: 'Soap and Other Detergent Manufacturing'
  },
  '325612': {
    id: 'naics-325612',
    defaultMessage: 'Polish and Other Sanitation Good Manufacturing'
  },
  '325613': {
    id: 'naics-325613',
    defaultMessage: 'Surface Active Agent Manufacturing'
  },
  '325620': {
    id: 'naics-325620',
    defaultMessage: 'Toilet Preparation Manufacturing'
  },
  '325910': {
    id: 'naics-325910',
    defaultMessage: 'Printing Ink Manufacturing'
  },
  '325920': {
    id: 'naics-325920',
    defaultMessage: 'Explosives Manufacturing'
  },
  '325991': {
    id: 'naics-325991',
    defaultMessage: 'Custom Compounding of Purchased Resins'
  },
  '325992': {
    id: 'naics-325992',
    defaultMessage:
      'Photographic Film, Paper, Plate, and Chemical Manufacturing'
  },
  '325998': {
    id: 'naics-325998',
    defaultMessage:
      'All Other Miscellaneous Chemical Product and Preparation Manufacturing'
  },
  '326111': {
    id: 'naics-326111',
    defaultMessage: 'Plastics Bag and Pouch Manufacturing'
  },
  '326112': {
    id: 'naics-326112',
    defaultMessage:
      'Plastics Packaging Film and Sheet (including Laminated) Manufacturing'
  },
  '326113': {
    id: 'naics-326113',
    defaultMessage:
      'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing'
  },
  '326121': {
    id: 'naics-326121',
    defaultMessage: 'Unlaminated Plastics Profile Shape Manufacturing'
  },
  '326122': {
    id: 'naics-326122',
    defaultMessage: 'Plastics Pipe and Pipe Fitting Manufacturing'
  },
  '326130': {
    id: 'naics-326130',
    defaultMessage:
      'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing'
  },
  '326140': {
    id: 'naics-326140',
    defaultMessage: 'Polystyrene Foam Product Manufacturing'
  },
  '326150': {
    id: 'naics-326150',
    defaultMessage:
      'Urethane and Other Foam Product (except Polystyrene) Manufacturing'
  },
  '326160': {
    id: 'naics-326160',
    defaultMessage: 'Plastics Bottle Manufacturing'
  },
  '326191': {
    id: 'naics-326191',
    defaultMessage: 'Plastics Plumbing Fixture Manufacturing'
  },
  '326199': {
    id: 'naics-326199',
    defaultMessage: 'All Other Plastics Product Manufacturing'
  },
  '326211': {
    id: 'naics-326211',
    defaultMessage: 'Tire Manufacturing (except Retreading)'
  },
  '326212': {
    id: 'naics-326212',
    defaultMessage: 'Tire Retreading'
  },
  '326220': {
    id: 'naics-326220',
    defaultMessage: 'Rubber and Plastics Hoses and Belting Manufacturing'
  },
  '326291': {
    id: 'naics-326291',
    defaultMessage: 'Rubber Product Manufacturing for Mechanical Use'
  },
  '326299': {
    id: 'naics-326299',
    defaultMessage: 'All Other Rubber Product Manufacturing'
  },
  '327110': {
    id: 'naics-327110',
    defaultMessage: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing'
  },
  '327120': {
    id: 'naics-327120',
    defaultMessage: 'Clay Building Material and Refractories Manufacturing'
  },
  '327211': {
    id: 'naics-327211',
    defaultMessage: 'Flat Glass Manufacturing'
  },
  '327212': {
    id: 'naics-327212',
    defaultMessage: 'Other Pressed and Blown Glass and Glassware Manufacturing'
  },
  '327213': {
    id: 'naics-327213',
    defaultMessage: 'Glass Container Manufacturing'
  },
  '327215': {
    id: 'naics-327215',
    defaultMessage: 'Glass Product Manufacturing Made of Purchased Glass'
  },
  '327310': {
    id: 'naics-327310',
    defaultMessage: 'Cement Manufacturing'
  },
  '327320': {
    id: 'naics-327320',
    defaultMessage: 'Ready-Mix Concrete Manufacturing'
  },
  '327331': {
    id: 'naics-327331',
    defaultMessage: 'Concrete Block and Brick Manufacturing'
  },
  '327332': {
    id: 'naics-327332',
    defaultMessage: 'Concrete Pipe Manufacturing'
  },
  '327390': {
    id: 'naics-327390',
    defaultMessage: 'Other Concrete Product Manufacturing'
  },
  '327410': {
    id: 'naics-327410',
    defaultMessage: 'Lime Manufacturing'
  },
  '327420': {
    id: 'naics-327420',
    defaultMessage: 'Gypsum Product Manufacturing'
  },
  '327910': {
    id: 'naics-327910',
    defaultMessage: 'Abrasive Product Manufacturing'
  },
  '327991': {
    id: 'naics-327991',
    defaultMessage: 'Cut Stone and Stone Product Manufacturing'
  },
  '327992': {
    id: 'naics-327992',
    defaultMessage: 'Ground or Treated Mineral and Earth Manufacturing'
  },
  '327993': {
    id: 'naics-327993',
    defaultMessage: 'Mineral Wool Manufacturing'
  },
  '327999': {
    id: 'naics-327999',
    defaultMessage:
      'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing'
  },
  '331110': {
    id: 'naics-331110',
    defaultMessage: 'Iron and Steel Mills and Ferroalloy Manufacturing'
  },
  '331210': {
    id: 'naics-331210',
    defaultMessage:
      'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel'
  },
  '331221': {
    id: 'naics-331221',
    defaultMessage: 'Rolled Steel Shape Manufacturing'
  },
  '331222': {
    id: 'naics-331222',
    defaultMessage: 'Steel Wire Drawing'
  },
  '331313': {
    id: 'naics-331313',
    defaultMessage: 'Alumina Refining and Primary Aluminum Production'
  },
  '331314': {
    id: 'naics-331314',
    defaultMessage: 'Secondary Smelting and Alloying of Aluminum'
  },
  '331315': {
    id: 'naics-331315',
    defaultMessage: 'Aluminum Sheet, Plate, and Foil Manufacturing'
  },
  '331318': {
    id: 'naics-331318',
    defaultMessage: 'Other Aluminum Rolling, Drawing, and Extruding'
  },
  '331410': {
    id: 'naics-331410',
    defaultMessage: 'Nonferrous Metal (except Aluminum) Smelting and Refining'
  },
  '331420': {
    id: 'naics-331420',
    defaultMessage: 'Copper Rolling, Drawing, Extruding, and Alloying'
  },
  '331491': {
    id: 'naics-331491',
    defaultMessage:
      'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding'
  },
  '331492': {
    id: 'naics-331492',
    defaultMessage:
      'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)'
  },
  '331511': {
    id: 'naics-331511',
    defaultMessage: 'Iron Foundries'
  },
  '331512': {
    id: 'naics-331512',
    defaultMessage: 'Steel Investment Foundries'
  },
  '331513': {
    id: 'naics-331513',
    defaultMessage: 'Steel Foundries (except Investment)'
  },
  '331523': {
    id: 'naics-331523',
    defaultMessage: 'Nonferrous Metal Die-Casting Foundries'
  },
  '331524': {
    id: 'naics-331524',
    defaultMessage: 'Aluminum Foundries (except Die-Casting)'
  },
  '331529': {
    id: 'naics-331529',
    defaultMessage: 'Other Nonferrous Metal Foundries (except Die-Casting)'
  },
  '332111': {
    id: 'naics-332111',
    defaultMessage: 'Iron and Steel Forging'
  },
  '332112': {
    id: 'naics-332112',
    defaultMessage: 'Nonferrous Forging'
  },
  '332114': {
    id: 'naics-332114',
    defaultMessage: 'Custom Roll Forming'
  },
  '332117': {
    id: 'naics-332117',
    defaultMessage: 'Powder Metallurgy Part Manufacturing'
  },
  '332119': {
    id: 'naics-332119',
    defaultMessage:
      'Metal Crown, Closure, and Other Metal Stamping (except Automotive)'
  },
  '332215': {
    id: 'naics-332215',
    defaultMessage:
      'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing'
  },
  '332216': {
    id: 'naics-332216',
    defaultMessage: 'Saw Blade and Handtool Manufacturing'
  },
  '332311': {
    id: 'naics-332311',
    defaultMessage: 'Prefabricated Metal Building and Component Manufacturing'
  },
  '332312': {
    id: 'naics-332312',
    defaultMessage: 'Fabricated Structural Metal Manufacturing'
  },
  '332313': {
    id: 'naics-332313',
    defaultMessage: 'Plate Work Manufacturing'
  },
  '332321': {
    id: 'naics-332321',
    defaultMessage: 'Metal Window and Door Manufacturing'
  },
  '332322': {
    id: 'naics-332322',
    defaultMessage: 'Sheet Metal Work Manufacturing'
  },
  '332323': {
    id: 'naics-332323',
    defaultMessage: 'Ornamental and Architectural Metal Work Manufacturing'
  },
  '332410': {
    id: 'naics-332410',
    defaultMessage: 'Power Boiler and Heat Exchanger Manufacturing'
  },
  '332420': {
    id: 'naics-332420',
    defaultMessage: 'Metal Tank (Heavy Gauge) Manufacturing'
  },
  '332431': {
    id: 'naics-332431',
    defaultMessage: 'Metal Can Manufacturing'
  },
  '332439': {
    id: 'naics-332439',
    defaultMessage: 'Other Metal Container Manufacturing'
  },
  '332510': {
    id: 'naics-332510',
    defaultMessage: 'Hardware Manufacturing'
  },
  '332613': {
    id: 'naics-332613',
    defaultMessage: 'Spring Manufacturing'
  },
  '332618': {
    id: 'naics-332618',
    defaultMessage: 'Other Fabricated Wire Product Manufacturing'
  },
  '332710': {
    id: 'naics-332710',
    defaultMessage: 'Machine Shops'
  },
  '332721': {
    id: 'naics-332721',
    defaultMessage: 'Precision Turned Product Manufacturing'
  },
  '332722': {
    id: 'naics-332722',
    defaultMessage: 'Bolt, Nut, Screw, Rivet, and Washer Manufacturing'
  },
  '332811': {
    id: 'naics-332811',
    defaultMessage: 'Metal Heat Treating'
  },
  '332812': {
    id: 'naics-332812',
    defaultMessage:
      'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers'
  },
  '332813': {
    id: 'naics-332813',
    defaultMessage:
      'Electroplating, Plating, Polishing, Anodizing, and Coloring'
  },
  '332911': {
    id: 'naics-332911',
    defaultMessage: 'Industrial Valve Manufacturing'
  },
  '332912': {
    id: 'naics-332912',
    defaultMessage: 'Fluid Power Valve and Hose Fitting Manufacturing'
  },
  '332913': {
    id: 'naics-332913',
    defaultMessage: 'Plumbing Fixture Fitting and Trim Manufacturing'
  },
  '332919': {
    id: 'naics-332919',
    defaultMessage: 'Other Metal Valve and Pipe Fitting Manufacturing'
  },
  '332991': {
    id: 'naics-332991',
    defaultMessage: 'Ball and Roller Bearing Manufacturing'
  },
  '332992': {
    id: 'naics-332992',
    defaultMessage: 'Small Arms Ammunition Manufacturing'
  },
  '332993': {
    id: 'naics-332993',
    defaultMessage: 'Ammunition (except Small Arms) Manufacturing'
  },
  '332994': {
    id: 'naics-332994',
    defaultMessage:
      'Small Arms, Ordnance, and Ordnance Accessories Manufacturing'
  },
  '332996': {
    id: 'naics-332996',
    defaultMessage: 'Fabricated Pipe and Pipe Fitting Manufacturing'
  },
  '332999': {
    id: 'naics-332999',
    defaultMessage:
      'All Other Miscellaneous Fabricated Metal Product Manufacturing'
  },
  '333111': {
    id: 'naics-333111',
    defaultMessage: 'Farm Machinery and Equipment Manufacturing'
  },
  '333112': {
    id: 'naics-333112',
    defaultMessage:
      'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing'
  },
  '333120': {
    id: 'naics-333120',
    defaultMessage: 'Construction Machinery Manufacturing'
  },
  '333131': {
    id: 'naics-333131',
    defaultMessage: 'Mining Machinery and Equipment Manufacturing'
  },
  '333132': {
    id: 'naics-333132',
    defaultMessage: 'Oil and Gas Field Machinery and Equipment Manufacturing'
  },
  '333241': {
    id: 'naics-333241',
    defaultMessage: 'Food Product Machinery Manufacturing'
  },
  '333242': {
    id: 'naics-333242',
    defaultMessage: 'Semiconductor Machinery Manufacturing'
  },
  '333243': {
    id: 'naics-333243',
    defaultMessage: 'Sawmill, Woodworking, and Paper Machinery Manufacturing'
  },
  '333244': {
    id: 'naics-333244',
    defaultMessage: 'Printing Machinery and Equipment Manufacturing'
  },
  '333249': {
    id: 'naics-333249',
    defaultMessage: 'Other Industrial Machinery Manufacturing'
  },
  '333314': {
    id: 'naics-333314',
    defaultMessage: 'Optical Instrument and Lens Manufacturing'
  },
  '333316': {
    id: 'naics-333316',
    defaultMessage: 'Photographic and Photocopying Equipment Manufacturing'
  },
  '333318': {
    id: 'naics-333318',
    defaultMessage:
      'Other Commercial and Service Industry Machinery Manufacturing'
  },
  '333413': {
    id: 'naics-333413',
    defaultMessage:
      'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing'
  },
  '333414': {
    id: 'naics-333414',
    defaultMessage: 'Heating Equipment (except Warm Air Furnaces) Manufacturing'
  },
  '333415': {
    id: 'naics-333415',
    defaultMessage:
      'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing'
  },
  '333511': {
    id: 'naics-333511',
    defaultMessage: 'Industrial Mold Manufacturing'
  },
  '333514': {
    id: 'naics-333514',
    defaultMessage:
      'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing'
  },
  '333515': {
    id: 'naics-333515',
    defaultMessage: 'Cutting Tool and Machine Tool Accessory Manufacturing'
  },
  '333517': {
    id: 'naics-333517',
    defaultMessage: 'Machine Tool Manufacturing'
  },
  '333519': {
    id: 'naics-333519',
    defaultMessage:
      'Rolling Mill and Other Metalworking Machinery Manufacturing'
  },
  '333611': {
    id: 'naics-333611',
    defaultMessage: 'Turbine and Turbine Generator Set Units Manufacturing'
  },
  '333612': {
    id: 'naics-333612',
    defaultMessage:
      'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing'
  },
  '333613': {
    id: 'naics-333613',
    defaultMessage: 'Mechanical Power Transmission Equipment Manufacturing'
  },
  '333618': {
    id: 'naics-333618',
    defaultMessage: 'Other Engine Equipment Manufacturing'
  },
  '333912': {
    id: 'naics-333912',
    defaultMessage: 'Air and Gas Compressor Manufacturing'
  },
  '333914': {
    id: 'naics-333914',
    defaultMessage:
      'Measuring, Dispensing, and Other Pumping Equipment Manufacturing'
  },
  '333921': {
    id: 'naics-333921',
    defaultMessage: 'Elevator and Moving Stairway Manufacturing'
  },
  '333922': {
    id: 'naics-333922',
    defaultMessage: 'Conveyor and Conveying Equipment Manufacturing'
  },
  '333923': {
    id: 'naics-333923',
    defaultMessage:
      'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing'
  },
  '333924': {
    id: 'naics-333924',
    defaultMessage:
      'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing'
  },
  '333991': {
    id: 'naics-333991',
    defaultMessage: 'Power-Driven Handtool Manufacturing'
  },
  '333992': {
    id: 'naics-333992',
    defaultMessage: 'Welding and Soldering Equipment Manufacturing'
  },
  '333993': {
    id: 'naics-333993',
    defaultMessage: 'Packaging Machinery Manufacturing'
  },
  '333994': {
    id: 'naics-333994',
    defaultMessage: 'Industrial Process Furnace and Oven Manufacturing'
  },
  '333995': {
    id: 'naics-333995',
    defaultMessage: 'Fluid Power Cylinder and Actuator Manufacturing'
  },
  '333996': {
    id: 'naics-333996',
    defaultMessage: 'Fluid Power Pump and Motor Manufacturing'
  },
  '333997': {
    id: 'naics-333997',
    defaultMessage: 'Scale and Balance Manufacturing'
  },
  '333999': {
    id: 'naics-333999',
    defaultMessage:
      'All Other Miscellaneous General Purpose Machinery Manufacturing'
  },
  '334111': {
    id: 'naics-334111',
    defaultMessage: 'Electronic Computer Manufacturing'
  },
  '334112': {
    id: 'naics-334112',
    defaultMessage: 'Computer Storage Device Manufacturing'
  },
  '334118': {
    id: 'naics-334118',
    defaultMessage:
      'Computer Terminal and Other Computer Peripheral Equipment Manufacturing'
  },
  '334210': {
    id: 'naics-334210',
    defaultMessage: 'Telephone Apparatus Manufacturing'
  },
  '334220': {
    id: 'naics-334220',
    defaultMessage:
      'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing'
  },
  '334290': {
    id: 'naics-334290',
    defaultMessage: 'Other Communications Equipment Manufacturing'
  },
  '334310': {
    id: 'naics-334310',
    defaultMessage: 'Audio and Video Equipment Manufacturing'
  },
  '334412': {
    id: 'naics-334412',
    defaultMessage: 'Bare Printed Circuit Board Manufacturing'
  },
  '334413': {
    id: 'naics-334413',
    defaultMessage: 'Semiconductor and Related Device Manufacturing'
  },
  '334416': {
    id: 'naics-334416',
    defaultMessage:
      'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing'
  },
  '334417': {
    id: 'naics-334417',
    defaultMessage: 'Electronic Connector Manufacturing'
  },
  '334418': {
    id: 'naics-334418',
    defaultMessage:
      'Printed Circuit Assembly (Electronic Assembly) Manufacturing'
  },
  '334419': {
    id: 'naics-334419',
    defaultMessage: 'Other Electronic Component Manufacturing'
  },
  '334510': {
    id: 'naics-334510',
    defaultMessage:
      'Electromedical and Electrotherapeutic Apparatus Manufacturing'
  },
  '334511': {
    id: 'naics-334511',
    defaultMessage:
      'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing'
  },
  '334512': {
    id: 'naics-334512',
    defaultMessage:
      'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use'
  },
  '334513': {
    id: 'naics-334513',
    defaultMessage:
      'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables'
  },
  '334514': {
    id: 'naics-334514',
    defaultMessage: 'Totalizing Fluid Meter and Counting Device Manufacturing'
  },
  '334515': {
    id: 'naics-334515',
    defaultMessage:
      'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals'
  },
  '334516': {
    id: 'naics-334516',
    defaultMessage: 'Analytical Laboratory Instrument Manufacturing'
  },
  '334517': {
    id: 'naics-334517',
    defaultMessage: 'Irradiation Apparatus Manufacturing'
  },
  '334519': {
    id: 'naics-334519',
    defaultMessage: 'Other Measuring and Controlling Device Manufacturing'
  },
  '334613': {
    id: 'naics-334613',
    defaultMessage: 'Blank Magnetic and Optical Recording Media Manufacturing'
  },
  '334614': {
    id: 'naics-334614',
    defaultMessage:
      'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing'
  },
  '335110': {
    id: 'naics-335110',
    defaultMessage: 'Electric Lamp Bulb and Part Manufacturing'
  },
  '335121': {
    id: 'naics-335121',
    defaultMessage: 'Residential Electric Lighting Fixture Manufacturing'
  },
  '335122': {
    id: 'naics-335122',
    defaultMessage:
      'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing'
  },
  '335129': {
    id: 'naics-335129',
    defaultMessage: 'Other Lighting Equipment Manufacturing'
  },
  '335210': {
    id: 'naics-335210',
    defaultMessage: 'Small Electrical Appliance Manufacturing'
  },
  '335220': {
    id: 'naics-335220',
    defaultMessage: 'Major Household Appliance Manufacturing'
  },
  '335311': {
    id: 'naics-335311',
    defaultMessage:
      'Power, Distribution, and Specialty Transformer Manufacturing'
  },
  '335312': {
    id: 'naics-335312',
    defaultMessage: 'Motor and Generator Manufacturing'
  },
  '335313': {
    id: 'naics-335313',
    defaultMessage: 'Switchgear and Switchboard Apparatus Manufacturing'
  },
  '335314': {
    id: 'naics-335314',
    defaultMessage: 'Relay and Industrial Control Manufacturing'
  },
  '335911': {
    id: 'naics-335911',
    defaultMessage: 'Storage Battery Manufacturing'
  },
  '335912': {
    id: 'naics-335912',
    defaultMessage: 'Primary Battery Manufacturing'
  },
  '335921': {
    id: 'naics-335921',
    defaultMessage: 'Fiber Optic Cable Manufacturing'
  },
  '335929': {
    id: 'naics-335929',
    defaultMessage: 'Other Communication and Energy Wire Manufacturing'
  },
  '335931': {
    id: 'naics-335931',
    defaultMessage: 'Current-Carrying Wiring Device Manufacturing'
  },
  '335932': {
    id: 'naics-335932',
    defaultMessage: 'Noncurrent-Carrying Wiring Device Manufacturing'
  },
  '335991': {
    id: 'naics-335991',
    defaultMessage: 'Carbon and Graphite Product Manufacturing'
  },
  '335999': {
    id: 'naics-335999',
    defaultMessage:
      'All Other Miscellaneous Electrical Equipment and Component Manufacturing'
  },
  '336111': {
    id: 'naics-336111',
    defaultMessage: 'Automobile Manufacturing'
  },
  '336112': {
    id: 'naics-336112',
    defaultMessage: 'Light Truck and Utility Vehicle Manufacturing'
  },
  '336120': {
    id: 'naics-336120',
    defaultMessage: 'Heavy Duty Truck Manufacturing'
  },
  '336211': {
    id: 'naics-336211',
    defaultMessage: 'Motor Vehicle Body Manufacturing'
  },
  '336212': {
    id: 'naics-336212',
    defaultMessage: 'Truck Trailer Manufacturing'
  },
  '336213': {
    id: 'naics-336213',
    defaultMessage: 'Motor Home Manufacturing'
  },
  '336214': {
    id: 'naics-336214',
    defaultMessage: 'Travel Trailer and Camper Manufacturing'
  },
  '336310': {
    id: 'naics-336310',
    defaultMessage:
      'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing'
  },
  '336320': {
    id: 'naics-336320',
    defaultMessage:
      'Motor Vehicle Electrical and Electronic Equipment Manufacturing'
  },
  '336330': {
    id: 'naics-336330',
    defaultMessage:
      'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing'
  },
  '336340': {
    id: 'naics-336340',
    defaultMessage: 'Motor Vehicle Brake System Manufacturing'
  },
  '336350': {
    id: 'naics-336350',
    defaultMessage:
      'Motor Vehicle Transmission and Power Train Parts Manufacturing'
  },
  '336360': {
    id: 'naics-336360',
    defaultMessage: 'Motor Vehicle Seating and Interior Trim Manufacturing'
  },
  '336370': {
    id: 'naics-336370',
    defaultMessage: 'Motor Vehicle Metal Stamping'
  },
  '336390': {
    id: 'naics-336390',
    defaultMessage: 'Other Motor Vehicle Parts Manufacturing'
  },
  '336411': {
    id: 'naics-336411',
    defaultMessage: 'Aircraft Manufacturing'
  },
  '336412': {
    id: 'naics-336412',
    defaultMessage: 'Aircraft Engine and Engine Parts Manufacturing'
  },
  '336413': {
    id: 'naics-336413',
    defaultMessage: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing'
  },
  '336414': {
    id: 'naics-336414',
    defaultMessage: 'Guided Missile and Space Vehicle Manufacturing'
  },
  '336415': {
    id: 'naics-336415',
    defaultMessage:
      'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing'
  },
  '336419': {
    id: 'naics-336419',
    defaultMessage:
      'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing'
  },
  '336510': {
    id: 'naics-336510',
    defaultMessage: 'Railroad Rolling Stock Manufacturing'
  },
  '336611': {
    id: 'naics-336611',
    defaultMessage: 'Ship Building and Repairing'
  },
  '336612': {
    id: 'naics-336612',
    defaultMessage: 'Boat Building'
  },
  '336991': {
    id: 'naics-336991',
    defaultMessage: 'Motorcycle, Bicycle, and Parts Manufacturing'
  },
  '336992': {
    id: 'naics-336992',
    defaultMessage:
      'Military Armored Vehicle, Tank, and Tank Component Manufacturing'
  },
  '336999': {
    id: 'naics-336999',
    defaultMessage: 'All Other Transportation Equipment Manufacturing'
  },
  '337110': {
    id: 'naics-337110',
    defaultMessage: 'Wood Kitchen Cabinet and Countertop Manufacturing'
  },
  '337121': {
    id: 'naics-337121',
    defaultMessage: 'Upholstered Household Furniture Manufacturing'
  },
  '337122': {
    id: 'naics-337122',
    defaultMessage: 'Nonupholstered Wood Household Furniture Manufacturing'
  },
  '337124': {
    id: 'naics-337124',
    defaultMessage: 'Metal Household Furniture Manufacturing'
  },
  '337125': {
    id: 'naics-337125',
    defaultMessage: 'Household Furniture (except Wood and Metal) Manufacturing'
  },
  '337127': {
    id: 'naics-337127',
    defaultMessage: 'Institutional Furniture Manufacturing'
  },
  '337211': {
    id: 'naics-337211',
    defaultMessage: 'Wood Office Furniture Manufacturing'
  },
  '337212': {
    id: 'naics-337212',
    defaultMessage: 'Custom Architectural Woodwork and Millwork Manufacturing'
  },
  '337214': {
    id: 'naics-337214',
    defaultMessage: 'Office Furniture (except Wood) Manufacturing'
  },
  '337215': {
    id: 'naics-337215',
    defaultMessage: 'Showcase, Partition, Shelving, and Locker Manufacturing'
  },
  '337910': {
    id: 'naics-337910',
    defaultMessage: 'Mattress Manufacturing'
  },
  '337920': {
    id: 'naics-337920',
    defaultMessage: 'Blind and Shade Manufacturing'
  },
  '339112': {
    id: 'naics-339112',
    defaultMessage: 'Surgical and Medical Instrument Manufacturing'
  },
  '339113': {
    id: 'naics-339113',
    defaultMessage: 'Surgical Appliance and Supplies Manufacturing'
  },
  '339114': {
    id: 'naics-339114',
    defaultMessage: 'Dental Equipment and Supplies Manufacturing'
  },
  '339115': {
    id: 'naics-339115',
    defaultMessage: 'Ophthalmic Goods Manufacturing'
  },
  '339116': {
    id: 'naics-339116',
    defaultMessage: 'Dental Laboratories'
  },
  '339910': {
    id: 'naics-339910',
    defaultMessage: 'Jewelry and Silverware Manufacturing'
  },
  '339920': {
    id: 'naics-339920',
    defaultMessage: 'Sporting and Athletic Goods Manufacturing'
  },
  '339930': {
    id: 'naics-339930',
    defaultMessage: 'Doll, Toy, and Game Manufacturing'
  },
  '339940': {
    id: 'naics-339940',
    defaultMessage: 'Office Supplies (except Paper) Manufacturing'
  },
  '339950': {
    id: 'naics-339950',
    defaultMessage: 'Sign Manufacturing'
  },
  '339991': {
    id: 'naics-339991',
    defaultMessage: 'Gasket, Packing, and Sealing Device Manufacturing'
  },
  '339992': {
    id: 'naics-339992',
    defaultMessage: 'Musical Instrument Manufacturing'
  },
  '339993': {
    id: 'naics-339993',
    defaultMessage: 'Fastener, Button, Needle, and Pin Manufacturing'
  },
  '339994': {
    id: 'naics-339994',
    defaultMessage: 'Broom, Brush, and Mop Manufacturing'
  },
  '339995': {
    id: 'naics-339995',
    defaultMessage: 'Burial Casket Manufacturing'
  },
  '339999': {
    id: 'naics-339999',
    defaultMessage: 'All Other Miscellaneous Manufacturing'
  },
  '423110': {
    id: 'naics-423110',
    defaultMessage: 'Automobile and Other Motor Vehicle Merchant Wholesalers'
  },
  '423120': {
    id: 'naics-423120',
    defaultMessage: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers'
  },
  '423130': {
    id: 'naics-423130',
    defaultMessage: 'Tire and Tube Merchant Wholesalers'
  },
  '423140': {
    id: 'naics-423140',
    defaultMessage: 'Motor Vehicle Parts (Used) Merchant Wholesalers'
  },
  '423210': {
    id: 'naics-423210',
    defaultMessage: 'Furniture Merchant Wholesalers'
  },
  '423220': {
    id: 'naics-423220',
    defaultMessage: 'Home Furnishing Merchant Wholesalers'
  },
  '423310': {
    id: 'naics-423310',
    defaultMessage:
      'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers'
  },
  '423320': {
    id: 'naics-423320',
    defaultMessage:
      'Brick, Stone, and Related Construction Material Merchant Wholesalers'
  },
  '423330': {
    id: 'naics-423330',
    defaultMessage:
      'Roofing, Siding, and Insulation Material Merchant Wholesalers'
  },
  '423390': {
    id: 'naics-423390',
    defaultMessage: 'Other Construction Material Merchant Wholesalers'
  },
  '423410': {
    id: 'naics-423410',
    defaultMessage: 'Photographic Equipment and Supplies Merchant Wholesalers'
  },
  '423420': {
    id: 'naics-423420',
    defaultMessage: 'Office Equipment Merchant Wholesalers'
  },
  '423430': {
    id: 'naics-423430',
    defaultMessage:
      'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers'
  },
  '423440': {
    id: 'naics-423440',
    defaultMessage: 'Other Commercial Equipment Merchant Wholesalers'
  },
  '423450': {
    id: 'naics-423450',
    defaultMessage:
      'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers'
  },
  '423460': {
    id: 'naics-423460',
    defaultMessage: 'Ophthalmic Goods Merchant Wholesalers'
  },
  '423490': {
    id: 'naics-423490',
    defaultMessage:
      'Other Professional Equipment and Supplies Merchant Wholesalers'
  },
  '423510': {
    id: 'naics-423510',
    defaultMessage: 'Metal Service Centers and Other Metal Merchant Wholesalers'
  },
  '423520': {
    id: 'naics-423520',
    defaultMessage: 'Coal and Other Mineral and Ore Merchant Wholesalers'
  },
  '423610': {
    id: 'naics-423610',
    defaultMessage:
      'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers'
  },
  '423620': {
    id: 'naics-423620',
    defaultMessage:
      'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers'
  },
  '423690': {
    id: 'naics-423690',
    defaultMessage: 'Other Electronic Parts and Equipment Merchant Wholesalers'
  },
  '423710': {
    id: 'naics-423710',
    defaultMessage: 'Hardware Merchant Wholesalers'
  },
  '423720': {
    id: 'naics-423720',
    defaultMessage:
      'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers'
  },
  '423730': {
    id: 'naics-423730',
    defaultMessage:
      'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers'
  },
  '423740': {
    id: 'naics-423740',
    defaultMessage: 'Refrigeration Equipment and Supplies Merchant Wholesalers'
  },
  '423810': {
    id: 'naics-423810',
    defaultMessage:
      'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers'
  },
  '423820': {
    id: 'naics-423820',
    defaultMessage:
      'Farm and Garden Machinery and Equipment Merchant Wholesalers'
  },
  '423830': {
    id: 'naics-423830',
    defaultMessage: 'Industrial Machinery and Equipment Merchant Wholesalers'
  },
  '423840': {
    id: 'naics-423840',
    defaultMessage: 'Industrial Supplies Merchant Wholesalers'
  },
  '423850': {
    id: 'naics-423850',
    defaultMessage:
      'Service Establishment Equipment and Supplies Merchant Wholesalers'
  },
  '423860': {
    id: 'naics-423860',
    defaultMessage:
      'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers'
  },
  '423910': {
    id: 'naics-423910',
    defaultMessage:
      'Sporting and Recreational Goods and Supplies Merchant Wholesalers'
  },
  '423920': {
    id: 'naics-423920',
    defaultMessage: 'Toy and Hobby Goods and Supplies Merchant Wholesalers'
  },
  '423930': {
    id: 'naics-423930',
    defaultMessage: 'Recyclable Material Merchant Wholesalers'
  },
  '423940': {
    id: 'naics-423940',
    defaultMessage:
      'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers'
  },
  '423990': {
    id: 'naics-423990',
    defaultMessage: 'Other Miscellaneous Durable Goods Merchant Wholesalers'
  },
  '424110': {
    id: 'naics-424110',
    defaultMessage: 'Printing and Writing Paper Merchant Wholesalers'
  },
  '424120': {
    id: 'naics-424120',
    defaultMessage: 'Stationery and Office Supplies Merchant Wholesalers'
  },
  '424130': {
    id: 'naics-424130',
    defaultMessage: 'Industrial and Personal Service Paper Merchant Wholesalers'
  },
  '424210': {
    id: 'naics-424210',
    defaultMessage: "Drugs and Druggists' Sundries Merchant Wholesalers"
  },
  '424310': {
    id: 'naics-424310',
    defaultMessage:
      'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers'
  },
  '424320': {
    id: 'naics-424320',
    defaultMessage:
      "Men's and Boys' Clothing and Furnishings Merchant Wholesalers"
  },
  '424330': {
    id: 'naics-424330',
    defaultMessage:
      "Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers"
  },
  '424340': {
    id: 'naics-424340',
    defaultMessage: 'Footwear Merchant Wholesalers'
  },
  '424410': {
    id: 'naics-424410',
    defaultMessage: 'General Line Grocery Merchant Wholesalers'
  },
  '424420': {
    id: 'naics-424420',
    defaultMessage: 'Packaged Frozen Food Merchant Wholesalers'
  },
  '424430': {
    id: 'naics-424430',
    defaultMessage:
      'Dairy Product (except Dried or Canned) Merchant Wholesalers'
  },
  '424440': {
    id: 'naics-424440',
    defaultMessage: 'Poultry and Poultry Product Merchant Wholesalers'
  },
  '424450': {
    id: 'naics-424450',
    defaultMessage: 'Confectionery Merchant Wholesalers'
  },
  '424460': {
    id: 'naics-424460',
    defaultMessage: 'Fish and Seafood Merchant Wholesalers'
  },
  '424470': {
    id: 'naics-424470',
    defaultMessage: 'Meat and Meat Product Merchant Wholesalers'
  },
  '424480': {
    id: 'naics-424480',
    defaultMessage: 'Fresh Fruit and Vegetable Merchant Wholesalers'
  },
  '424490': {
    id: 'naics-424490',
    defaultMessage: 'Other Grocery and Related Products Merchant Wholesalers'
  },
  '424510': {
    id: 'naics-424510',
    defaultMessage: 'Grain and Field Bean Merchant Wholesalers'
  },
  '424520': {
    id: 'naics-424520',
    defaultMessage: 'Livestock Merchant Wholesalers'
  },
  '424590': {
    id: 'naics-424590',
    defaultMessage: 'Other Farm Product Raw Material Merchant Wholesalers'
  },
  '424610': {
    id: 'naics-424610',
    defaultMessage:
      'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers'
  },
  '424690': {
    id: 'naics-424690',
    defaultMessage: 'Other Chemical and Allied Products Merchant Wholesalers'
  },
  '424710': {
    id: 'naics-424710',
    defaultMessage: 'Petroleum Bulk Stations and Terminals'
  },
  '424720': {
    id: 'naics-424720',
    defaultMessage:
      'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)'
  },
  '424810': {
    id: 'naics-424810',
    defaultMessage: 'Beer and Ale Merchant Wholesalers'
  },
  '424820': {
    id: 'naics-424820',
    defaultMessage: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers'
  },
  '424910': {
    id: 'naics-424910',
    defaultMessage: 'Farm Supplies Merchant Wholesalers'
  },
  '424920': {
    id: 'naics-424920',
    defaultMessage: 'Book, Periodical, and Newspaper Merchant Wholesalers'
  },
  '424930': {
    id: 'naics-424930',
    defaultMessage:
      "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers"
  },
  '424940': {
    id: 'naics-424940',
    defaultMessage: 'Tobacco and Tobacco Product Merchant Wholesalers'
  },
  '424950': {
    id: 'naics-424950',
    defaultMessage: 'Paint, Varnish, and Supplies Merchant Wholesalers'
  },
  '424990': {
    id: 'naics-424990',
    defaultMessage: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers'
  },
  '425110': {
    id: 'naics-425110',
    defaultMessage: 'Business to Business Electronic Markets'
  },
  '425120': {
    id: 'naics-425120',
    defaultMessage: 'Wholesale Trade Agents and Brokers'
  },
  '441110': {
    id: 'naics-441110',
    defaultMessage: 'New Car Dealers'
  },
  '441120': {
    id: 'naics-441120',
    defaultMessage: 'Used Car Dealers'
  },
  '441210': {
    id: 'naics-441210',
    defaultMessage: 'Recreational Vehicle Dealers'
  },
  '441222': {
    id: 'naics-441222',
    defaultMessage: 'Boat Dealers'
  },
  '441228': {
    id: 'naics-441228',
    defaultMessage: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers'
  },
  '441310': {
    id: 'naics-441310',
    defaultMessage: 'Automotive Parts and Accessories Stores'
  },
  '441320': {
    id: 'naics-441320',
    defaultMessage: 'Tire Dealers'
  },
  '442110': {
    id: 'naics-442110',
    defaultMessage: 'Furniture Stores'
  },
  '442210': {
    id: 'naics-442210',
    defaultMessage: 'Floor Covering Stores'
  },
  '442291': {
    id: 'naics-442291',
    defaultMessage: 'Window Treatment Stores'
  },
  '442299': {
    id: 'naics-442299',
    defaultMessage: 'All Other Home Furnishings Stores'
  },
  '443141': {
    id: 'naics-443141',
    defaultMessage: 'Household Appliance Stores'
  },
  '443142': {
    id: 'naics-443142',
    defaultMessage: 'Electronics Stores'
  },
  '444110': {
    id: 'naics-444110',
    defaultMessage: 'Home Centers'
  },
  '444120': {
    id: 'naics-444120',
    defaultMessage: 'Paint and Wallpaper Stores'
  },
  '444130': {
    id: 'naics-444130',
    defaultMessage: 'Hardware Stores'
  },
  '444190': {
    id: 'naics-444190',
    defaultMessage: 'Other Building Material Dealers'
  },
  '444210': {
    id: 'naics-444210',
    defaultMessage: 'Outdoor Power Equipment Stores'
  },
  '444220': {
    id: 'naics-444220',
    defaultMessage: 'Nursery, Garden Center, and Farm Supply Stores'
  },
  '445110': {
    id: 'naics-445110',
    defaultMessage: 'Supermarkets and Other Grocery (except Convenience) Stores'
  },
  '445120': {
    id: 'naics-445120',
    defaultMessage: 'Convenience Stores'
  },
  '445210': {
    id: 'naics-445210',
    defaultMessage: 'Meat Markets'
  },
  '445220': {
    id: 'naics-445220',
    defaultMessage: 'Fish and Seafood Markets'
  },
  '445230': {
    id: 'naics-445230',
    defaultMessage: 'Fruit and Vegetable Markets'
  },
  '445291': {
    id: 'naics-445291',
    defaultMessage: 'Baked Goods Stores'
  },
  '445292': {
    id: 'naics-445292',
    defaultMessage: 'Confectionery and Nut Stores'
  },
  '445299': {
    id: 'naics-445299',
    defaultMessage: 'All Other Specialty Food Stores'
  },
  '445310': {
    id: 'naics-445310',
    defaultMessage: 'Beer, Wine, and Liquor Stores'
  },
  '446110': {
    id: 'naics-446110',
    defaultMessage: 'Pharmacies and Drug Stores'
  },
  '446120': {
    id: 'naics-446120',
    defaultMessage: 'Cosmetics, Beauty Supplies, and Perfume Stores'
  },
  '446130': {
    id: 'naics-446130',
    defaultMessage: 'Optical Goods Stores'
  },
  '446191': {
    id: 'naics-446191',
    defaultMessage: 'Food (Health) Supplement Stores'
  },
  '446199': {
    id: 'naics-446199',
    defaultMessage: 'All Other Health and Personal Care Stores'
  },
  '447110': {
    id: 'naics-447110',
    defaultMessage: 'Gasoline Stations with Convenience Stores'
  },
  '447190': {
    id: 'naics-447190',
    defaultMessage: 'Other Gasoline Stations'
  },
  '448110': {
    id: 'naics-448110',
    defaultMessage: "Men's Clothing Stores"
  },
  '448120': {
    id: 'naics-448120',
    defaultMessage: "Women's Clothing Stores"
  },
  '448130': {
    id: 'naics-448130',
    defaultMessage: "Children's and Infants' Clothing Stores"
  },
  '448140': {
    id: 'naics-448140',
    defaultMessage: 'Family Clothing Stores'
  },
  '448150': {
    id: 'naics-448150',
    defaultMessage: 'Clothing Accessories Stores'
  },
  '448190': {
    id: 'naics-448190',
    defaultMessage: 'Other Clothing Stores'
  },
  '448210': {
    id: 'naics-448210',
    defaultMessage: 'Shoe Stores'
  },
  '448310': {
    id: 'naics-448310',
    defaultMessage: 'Jewelry Stores'
  },
  '448320': {
    id: 'naics-448320',
    defaultMessage: 'Luggage and Leather Goods Stores'
  },
  '451110': {
    id: 'naics-451110',
    defaultMessage: 'Sporting Goods Stores'
  },
  '451120': {
    id: 'naics-451120',
    defaultMessage: 'Hobby, Toy, and Game Stores'
  },
  '451130': {
    id: 'naics-451130',
    defaultMessage: 'Sewing, Needlework, and Piece Goods Stores'
  },
  '451140': {
    id: 'naics-451140',
    defaultMessage: 'Musical Instrument and Supplies Stores'
  },
  '451211': {
    id: 'naics-451211',
    defaultMessage: 'Book Stores'
  },
  '451212': {
    id: 'naics-451212',
    defaultMessage: 'News Dealers and Newsstands'
  },
  '452210': {
    id: 'naics-452210',
    defaultMessage: 'Department Stores'
  },
  '452311': {
    id: 'naics-452311',
    defaultMessage: 'Warehouse Clubs and Supercenters'
  },
  '452319': {
    id: 'naics-452319',
    defaultMessage: 'All Other General Merchandise Stores'
  },
  '453110': {
    id: 'naics-453110',
    defaultMessage: 'Florists'
  },
  '453210': {
    id: 'naics-453210',
    defaultMessage: 'Office Supplies and Stationery Stores'
  },
  '453220': {
    id: 'naics-453220',
    defaultMessage: 'Gift, Novelty, and Souvenir Stores'
  },
  '453310': {
    id: 'naics-453310',
    defaultMessage: 'Used Merchandise Stores'
  },
  '453910': {
    id: 'naics-453910',
    defaultMessage: 'Pet and Pet Supplies Stores'
  },
  '453920': {
    id: 'naics-453920',
    defaultMessage: 'Art Dealers'
  },
  '453930': {
    id: 'naics-453930',
    defaultMessage: 'Manufactured (Mobile) Home Dealers'
  },
  '453991': {
    id: 'naics-453991',
    defaultMessage: 'Tobacco Stores'
  },
  '453998': {
    id: 'naics-453998',
    defaultMessage:
      'All Other Miscellaneous Store Retailers (except Tobacco Stores)'
  },
  '454110': {
    id: 'naics-454110',
    defaultMessage: 'Electronic Shopping and Mail-Order Houses'
  },
  '454210': {
    id: 'naics-454210',
    defaultMessage: 'Vending Machine Operators'
  },
  '454310': {
    id: 'naics-454310',
    defaultMessage: 'Fuel Dealers'
  },
  '454390': {
    id: 'naics-454390',
    defaultMessage: 'Other Direct Selling Establishments'
  },
  '481111': {
    id: 'naics-481111',
    defaultMessage: 'Scheduled Passenger Air Transportation'
  },
  '481112': {
    id: 'naics-481112',
    defaultMessage: 'Scheduled Freight Air Transportation'
  },
  '481211': {
    id: 'naics-481211',
    defaultMessage: 'Nonscheduled Chartered Passenger Air Transportation'
  },
  '481212': {
    id: 'naics-481212',
    defaultMessage: 'Nonscheduled Chartered Freight Air Transportation'
  },
  '481219': {
    id: 'naics-481219',
    defaultMessage: 'Other Nonscheduled Air Transportation'
  },
  '482111': {
    id: 'naics-482111',
    defaultMessage: 'Line-Haul Railroads'
  },
  '482112': {
    id: 'naics-482112',
    defaultMessage: 'Short Line Railroads'
  },
  '483111': {
    id: 'naics-483111',
    defaultMessage: 'Deep Sea Freight Transportation'
  },
  '483112': {
    id: 'naics-483112',
    defaultMessage: 'Deep Sea Passenger Transportation'
  },
  '483113': {
    id: 'naics-483113',
    defaultMessage: 'Coastal and Great Lakes Freight Transportation'
  },
  '483114': {
    id: 'naics-483114',
    defaultMessage: 'Coastal and Great Lakes Passenger Transportation'
  },
  '483211': {
    id: 'naics-483211',
    defaultMessage: 'Inland Water Freight Transportation'
  },
  '483212': {
    id: 'naics-483212',
    defaultMessage: 'Inland Water Passenger Transportation'
  },
  '484110': {
    id: 'naics-484110',
    defaultMessage: 'General Freight Trucking, Local'
  },
  '484121': {
    id: 'naics-484121',
    defaultMessage: 'General Freight Trucking, Long-Distance, Truckload'
  },
  '484122': {
    id: 'naics-484122',
    defaultMessage:
      'General Freight Trucking, Long-Distance, Less Than Truckload'
  },
  '484210': {
    id: 'naics-484210',
    defaultMessage: 'Used Household and Office Goods Moving'
  },
  '484220': {
    id: 'naics-484220',
    defaultMessage: 'Specialized Freight (except Used Goods) Trucking, Local'
  },
  '484230': {
    id: 'naics-484230',
    defaultMessage:
      'Specialized Freight (except Used Goods) Trucking, Long-Distance'
  },
  '485111': {
    id: 'naics-485111',
    defaultMessage: 'Mixed Mode Transit Systems'
  },
  '485112': {
    id: 'naics-485112',
    defaultMessage: 'Commuter Rail Systems'
  },
  '485113': {
    id: 'naics-485113',
    defaultMessage: 'Bus and Other Motor Vehicle Transit Systems'
  },
  '485119': {
    id: 'naics-485119',
    defaultMessage: 'Other Urban Transit Systems'
  },
  '485210': {
    id: 'naics-485210',
    defaultMessage: 'Interurban and Rural Bus Transportation'
  },
  '485310': {
    id: 'naics-485310',
    defaultMessage: 'Taxi Service'
  },
  '485320': {
    id: 'naics-485320',
    defaultMessage: 'Limousine Service'
  },
  '485410': {
    id: 'naics-485410',
    defaultMessage: 'School and Employee Bus Transportation'
  },
  '485510': {
    id: 'naics-485510',
    defaultMessage: 'Charter Bus Industry'
  },
  '485991': {
    id: 'naics-485991',
    defaultMessage: 'Special Needs Transportation'
  },
  '485999': {
    id: 'naics-485999',
    defaultMessage: 'All Other Transit and Ground Passenger Transportation'
  },
  '486110': {
    id: 'naics-486110',
    defaultMessage: 'Pipeline Transportation of Crude Oil'
  },
  '486210': {
    id: 'naics-486210',
    defaultMessage: 'Pipeline Transportation of Natural Gas'
  },
  '486910': {
    id: 'naics-486910',
    defaultMessage: 'Pipeline Transportation of Refined Petroleum Products'
  },
  '486990': {
    id: 'naics-486990',
    defaultMessage: 'All Other Pipeline Transportation'
  },
  '487110': {
    id: 'naics-487110',
    defaultMessage: 'Scenic and Sightseeing Transportation, Land'
  },
  '487210': {
    id: 'naics-487210',
    defaultMessage: 'Scenic and Sightseeing Transportation, Water'
  },
  '487990': {
    id: 'naics-487990',
    defaultMessage: 'Scenic and Sightseeing Transportation, Other'
  },
  '488111': {
    id: 'naics-488111',
    defaultMessage: 'Air Traffic Control'
  },
  '488119': {
    id: 'naics-488119',
    defaultMessage: 'Other Airport Operations'
  },
  '488190': {
    id: 'naics-488190',
    defaultMessage: 'Other Support Activities for Air Transportation'
  },
  '488210': {
    id: 'naics-488210',
    defaultMessage: 'Support Activities for Rail Transportation'
  },
  '488310': {
    id: 'naics-488310',
    defaultMessage: 'Port and Harbor Operations'
  },
  '488320': {
    id: 'naics-488320',
    defaultMessage: 'Marine Cargo Handling'
  },
  '488330': {
    id: 'naics-488330',
    defaultMessage: 'Navigational Services to Shipping'
  },
  '488390': {
    id: 'naics-488390',
    defaultMessage: 'Other Support Activities for Water Transportation'
  },
  '488410': {
    id: 'naics-488410',
    defaultMessage: 'Motor Vehicle Towing'
  },
  '488490': {
    id: 'naics-488490',
    defaultMessage: 'Other Support Activities for Road Transportation'
  },
  '488510': {
    id: 'naics-488510',
    defaultMessage: 'Freight Transportation Arrangement'
  },
  '488991': {
    id: 'naics-488991',
    defaultMessage: 'Packing and Crating'
  },
  '488999': {
    id: 'naics-488999',
    defaultMessage: 'All Other Support Activities for Transportation'
  },
  '491110': {
    id: 'naics-491110',
    defaultMessage: 'Postal Service'
  },
  '492110': {
    id: 'naics-492110',
    defaultMessage: 'Couriers and Express Delivery Services'
  },
  '492210': {
    id: 'naics-492210',
    defaultMessage: 'Local Messengers and Local Delivery'
  },
  '493110': {
    id: 'naics-493110',
    defaultMessage: 'General Warehousing and Storage'
  },
  '493120': {
    id: 'naics-493120',
    defaultMessage: 'Refrigerated Warehousing and Storage'
  },
  '493130': {
    id: 'naics-493130',
    defaultMessage: 'Farm Product Warehousing and Storage'
  },
  '493190': {
    id: 'naics-493190',
    defaultMessage: 'Other Warehousing and Storage'
  },
  '511110': {
    id: 'naics-511110',
    defaultMessage: 'Newspaper Publishers'
  },
  '511120': {
    id: 'naics-511120',
    defaultMessage: 'Periodical Publishers'
  },
  '511130': {
    id: 'naics-511130',
    defaultMessage: 'Book Publishers'
  },
  '511140': {
    id: 'naics-511140',
    defaultMessage: 'Directory and Mailing List Publishers'
  },
  '511191': {
    id: 'naics-511191',
    defaultMessage: 'Greeting Card Publishers'
  },
  '511199': {
    id: 'naics-511199',
    defaultMessage: 'All Other Publishers'
  },
  '511210': {
    id: 'naics-511210',
    defaultMessage: 'Software Publishers'
  },
  '512110': {
    id: 'naics-512110',
    defaultMessage: 'Motion Picture and Video Production'
  },
  '512120': {
    id: 'naics-512120',
    defaultMessage: 'Motion Picture and Video Distribution'
  },
  '512131': {
    id: 'naics-512131',
    defaultMessage: 'Motion Picture Theaters (except Drive-Ins)'
  },
  '512132': {
    id: 'naics-512132',
    defaultMessage: 'Drive-In Motion Picture Theaters'
  },
  '512191': {
    id: 'naics-512191',
    defaultMessage: 'Teleproduction and Other Postproduction Services'
  },
  '512199': {
    id: 'naics-512199',
    defaultMessage: 'Other Motion Picture and Video Industries'
  },
  '512230': {
    id: 'naics-512230',
    defaultMessage: 'Music Publishers'
  },
  '512240': {
    id: 'naics-512240',
    defaultMessage: 'Sound Recording Studios'
  },
  '512250': {
    id: 'naics-512250',
    defaultMessage: 'Record Production and Distribution'
  },
  '512290': {
    id: 'naics-512290',
    defaultMessage: 'Other Sound Recording Industries'
  },
  '515111': {
    id: 'naics-515111',
    defaultMessage: 'Radio Networks'
  },
  '515112': {
    id: 'naics-515112',
    defaultMessage: 'Radio Stations'
  },
  '515120': {
    id: 'naics-515120',
    defaultMessage: 'Television Broadcasting'
  },
  '515210': {
    id: 'naics-515210',
    defaultMessage: 'Cable and Other Subscription Programming'
  },
  '517311': {
    id: 'naics-517311',
    defaultMessage: 'Wired Telecommunications Carriers'
  },
  '517312': {
    id: 'naics-517312',
    defaultMessage: 'Wireless Telecommunications Carriers (except Satellite)'
  },
  '517410': {
    id: 'naics-517410',
    defaultMessage: 'Satellite Telecommunications'
  },
  '517911': {
    id: 'naics-517911',
    defaultMessage: 'Telecommunications Resellers'
  },
  '517919': {
    id: 'naics-517919',
    defaultMessage: 'All Other Telecommunications'
  },
  '518210': {
    id: 'naics-518210',
    defaultMessage: 'Data Processing, Hosting, and Related Services'
  },
  '519110': {
    id: 'naics-519110',
    defaultMessage: 'News Syndicates'
  },
  '519120': {
    id: 'naics-519120',
    defaultMessage: 'Libraries and Archives'
  },
  '519130': {
    id: 'naics-519130',
    defaultMessage:
      'Internet Publishing and Broadcasting and Web Search Portals'
  },
  '519190': {
    id: 'naics-519190',
    defaultMessage: 'All Other Information Services'
  },
  '521110': {
    id: 'naics-521110',
    defaultMessage: 'Monetary Authorities-Central Bank'
  },
  '522110': {
    id: 'naics-522110',
    defaultMessage: 'Commercial Banking'
  },
  '522120': {
    id: 'naics-522120',
    defaultMessage: 'Savings Institutions'
  },
  '522130': {
    id: 'naics-522130',
    defaultMessage: 'Credit Unions'
  },
  '522190': {
    id: 'naics-522190',
    defaultMessage: 'Other Depository Credit Intermediation'
  },
  '522210': {
    id: 'naics-522210',
    defaultMessage: 'Credit Card Issuing'
  },
  '522220': {
    id: 'naics-522220',
    defaultMessage: 'Sales Financing'
  },
  '522291': {
    id: 'naics-522291',
    defaultMessage: 'Consumer Lending'
  },
  '522292': {
    id: 'naics-522292',
    defaultMessage: 'Real Estate Credit'
  },
  '522293': {
    id: 'naics-522293',
    defaultMessage: 'International Trade Financing'
  },
  '522294': {
    id: 'naics-522294',
    defaultMessage: 'Secondary Market Financing'
  },
  '522298': {
    id: 'naics-522298',
    defaultMessage: 'All Other Nondepository Credit Intermediation'
  },
  '522310': {
    id: 'naics-522310',
    defaultMessage: 'Mortgage and Nonmortgage Loan Brokers'
  },
  '522320': {
    id: 'naics-522320',
    defaultMessage:
      'Financial Transactions Processing, Reserve, and Clearinghouse Activities'
  },
  '522390': {
    id: 'naics-522390',
    defaultMessage: 'Other Activities Related to Credit Intermediation'
  },
  '523110': {
    id: 'naics-523110',
    defaultMessage: 'Investment Banking and Securities Dealing'
  },
  '523120': {
    id: 'naics-523120',
    defaultMessage: 'Securities Brokerage'
  },
  '523130': {
    id: 'naics-523130',
    defaultMessage: 'Commodity Contracts Dealing'
  },
  '523140': {
    id: 'naics-523140',
    defaultMessage: 'Commodity Contracts Brokerage'
  },
  '523210': {
    id: 'naics-523210',
    defaultMessage: 'Securities and Commodity Exchanges'
  },
  '523910': {
    id: 'naics-523910',
    defaultMessage: 'Miscellaneous Intermediation'
  },
  '523920': {
    id: 'naics-523920',
    defaultMessage: 'Portfolio Management'
  },
  '523930': {
    id: 'naics-523930',
    defaultMessage: 'Investment Advice'
  },
  '523991': {
    id: 'naics-523991',
    defaultMessage: 'Trust, Fiduciary, and Custody Activities'
  },
  '523999': {
    id: 'naics-523999',
    defaultMessage: 'Miscellaneous Financial Investment Activities'
  },
  '524113': {
    id: 'naics-524113',
    defaultMessage: 'Direct Life Insurance Carriers'
  },
  '524114': {
    id: 'naics-524114',
    defaultMessage: 'Direct Health and Medical Insurance Carriers'
  },
  '524126': {
    id: 'naics-524126',
    defaultMessage: 'Direct Property and Casualty Insurance Carriers'
  },
  '524127': {
    id: 'naics-524127',
    defaultMessage: 'Direct Title Insurance Carriers'
  },
  '524128': {
    id: 'naics-524128',
    defaultMessage:
      'Other Direct Insurance (except Life, Health, and Medical) Carriers'
  },
  '524130': {
    id: 'naics-524130',
    defaultMessage: 'Reinsurance Carriers'
  },
  '524210': {
    id: 'naics-524210',
    defaultMessage: 'Insurance Agencies and Brokerages'
  },
  '524291': {
    id: 'naics-524291',
    defaultMessage: 'Claims Adjusting'
  },
  '524292': {
    id: 'naics-524292',
    defaultMessage: 'Third Party Administration of Insurance and Pension Funds'
  },
  '524298': {
    id: 'naics-524298',
    defaultMessage: 'All Other Insurance Related Activities'
  },
  '525110': {
    id: 'naics-525110',
    defaultMessage: 'Pension Funds'
  },
  '525120': {
    id: 'naics-525120',
    defaultMessage: 'Health and Welfare Funds'
  },
  '525190': {
    id: 'naics-525190',
    defaultMessage: 'Other Insurance Funds'
  },
  '525910': {
    id: 'naics-525910',
    defaultMessage: 'Open-End Investment Funds'
  },
  '525920': {
    id: 'naics-525920',
    defaultMessage: 'Trusts, Estates, and Agency Accounts'
  },
  '525990': {
    id: 'naics-525990',
    defaultMessage: 'Other Financial Vehicles'
  },
  '531110': {
    id: 'naics-531110',
    defaultMessage: 'Lessors of Residential Buildings and Dwellings'
  },
  '531120': {
    id: 'naics-531120',
    defaultMessage:
      'Lessors of Nonresidential Buildings (except Miniwarehouses)'
  },
  '531130': {
    id: 'naics-531130',
    defaultMessage: 'Lessors of Miniwarehouses and Self-Storage Units'
  },
  '531190': {
    id: 'naics-531190',
    defaultMessage: 'Lessors of Other Real Estate Property'
  },
  '531210': {
    id: 'naics-531210',
    defaultMessage: 'Offices of Real Estate Agents and Brokers'
  },
  '531311': {
    id: 'naics-531311',
    defaultMessage: 'Residential Property Managers'
  },
  '531312': {
    id: 'naics-531312',
    defaultMessage: 'Nonresidential Property Managers'
  },
  '531320': {
    id: 'naics-531320',
    defaultMessage: 'Offices of Real Estate Appraisers'
  },
  '531390': {
    id: 'naics-531390',
    defaultMessage: 'Other Activities Related to Real Estate'
  },
  '532111': {
    id: 'naics-532111',
    defaultMessage: 'Passenger Car Rental'
  },
  '532112': {
    id: 'naics-532112',
    defaultMessage: 'Passenger Car Leasing'
  },
  '532120': {
    id: 'naics-532120',
    defaultMessage:
      'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing'
  },
  '532210': {
    id: 'naics-532210',
    defaultMessage: 'Consumer Electronics and Appliances Rental'
  },
  '532281': {
    id: 'naics-532281',
    defaultMessage: 'Formal Wear and Costume Rental'
  },
  '532282': {
    id: 'naics-532282',
    defaultMessage: 'Video Tape and Disc Rental'
  },
  '532283': {
    id: 'naics-532283',
    defaultMessage: 'Home Health Equipment Rental'
  },
  '532284': {
    id: 'naics-532284',
    defaultMessage: 'Recreational Goods Rental'
  },
  '532289': {
    id: 'naics-532289',
    defaultMessage: 'All Other Consumer Goods Rental'
  },
  '532310': {
    id: 'naics-532310',
    defaultMessage: 'General Rental Centers'
  },
  '532411': {
    id: 'naics-532411',
    defaultMessage:
      'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing'
  },
  '532412': {
    id: 'naics-532412',
    defaultMessage:
      'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing'
  },
  '532420': {
    id: 'naics-532420',
    defaultMessage: 'Office Machinery and Equipment Rental and Leasing'
  },
  '532490': {
    id: 'naics-532490',
    defaultMessage:
      'Other Commercial and Industrial Machinery and Equipment Rental and Leasing'
  },
  '533110': {
    id: 'naics-533110',
    defaultMessage:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)'
  },
  '541110': {
    id: 'naics-541110',
    defaultMessage: 'Offices of Lawyers'
  },
  '541120': {
    id: 'naics-541120',
    defaultMessage: 'Offices of Notaries'
  },
  '541191': {
    id: 'naics-541191',
    defaultMessage: 'Title Abstract and Settlement Offices'
  },
  '541199': {
    id: 'naics-541199',
    defaultMessage: 'All Other Legal Services'
  },
  '541211': {
    id: 'naics-541211',
    defaultMessage: 'Offices of Certified Public Accountants'
  },
  '541213': {
    id: 'naics-541213',
    defaultMessage: 'Tax Preparation Services'
  },
  '541214': {
    id: 'naics-541214',
    defaultMessage: 'Payroll Services'
  },
  '541219': {
    id: 'naics-541219',
    defaultMessage: 'Other Accounting Services'
  },
  '541310': {
    id: 'naics-541310',
    defaultMessage: 'Architectural Services'
  },
  '541320': {
    id: 'naics-541320',
    defaultMessage: 'Landscape Architectural Services'
  },
  '541330': {
    id: 'naics-541330',
    defaultMessage: 'Engineering Services'
  },
  '541340': {
    id: 'naics-541340',
    defaultMessage: 'Drafting Services'
  },
  '541350': {
    id: 'naics-541350',
    defaultMessage: 'Building Inspection Services'
  },
  '541360': {
    id: 'naics-541360',
    defaultMessage: 'Geophysical Surveying and Mapping Services'
  },
  '541370': {
    id: 'naics-541370',
    defaultMessage: 'Surveying and Mapping (except Geophysical) Services'
  },
  '541380': {
    id: 'naics-541380',
    defaultMessage: 'Testing Laboratories'
  },
  '541410': {
    id: 'naics-541410',
    defaultMessage: 'Interior Design Services'
  },
  '541420': {
    id: 'naics-541420',
    defaultMessage: 'Industrial Design Services'
  },
  '541430': {
    id: 'naics-541430',
    defaultMessage: 'Graphic Design Services'
  },
  '541490': {
    id: 'naics-541490',
    defaultMessage: 'Other Specialized Design Services'
  },
  '541511': {
    id: 'naics-541511',
    defaultMessage: 'Custom Computer Programming Services'
  },
  '541512': {
    id: 'naics-541512',
    defaultMessage: 'Computer Systems Design Services'
  },
  '541513': {
    id: 'naics-541513',
    defaultMessage: 'Computer Facilities Management Services'
  },
  '541519': {
    id: 'naics-541519',
    defaultMessage: 'Other Computer Related Services'
  },
  '541611': {
    id: 'naics-541611',
    defaultMessage:
      'Administrative Management and General Management Consulting Services'
  },
  '541612': {
    id: 'naics-541612',
    defaultMessage: 'Human Resources Consulting Services'
  },
  '541613': {
    id: 'naics-541613',
    defaultMessage: 'Marketing Consulting Services'
  },
  '541614': {
    id: 'naics-541614',
    defaultMessage:
      'Process, Physical Distribution, and Logistics Consulting Services'
  },
  '541618': {
    id: 'naics-541618',
    defaultMessage: 'Other Management Consulting Services'
  },
  '541620': {
    id: 'naics-541620',
    defaultMessage: 'Environmental Consulting Services'
  },
  '541690': {
    id: 'naics-541690',
    defaultMessage: 'Other Scientific and Technical Consulting Services'
  },
  '541713': {
    id: 'naics-541713',
    defaultMessage: 'Research and Development in Nanotechnology'
  },
  '541714': {
    id: 'naics-541714',
    defaultMessage:
      'Research and Development in Biotechnology (except Nanobiotechnology)'
  },
  '541715': {
    id: 'naics-541715',
    defaultMessage:
      'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)'
  },
  '541720': {
    id: 'naics-541720',
    defaultMessage:
      'Research and Development in the Social Sciences and Humanities'
  },
  '541810': {
    id: 'naics-541810',
    defaultMessage: 'Advertising Agencies'
  },
  '541820': {
    id: 'naics-541820',
    defaultMessage: 'Public Relations Agencies'
  },
  '541830': {
    id: 'naics-541830',
    defaultMessage: 'Media Buying Agencies'
  },
  '541840': {
    id: 'naics-541840',
    defaultMessage: 'Media Representatives'
  },
  '541850': {
    id: 'naics-541850',
    defaultMessage: 'Outdoor Advertising'
  },
  '541860': {
    id: 'naics-541860',
    defaultMessage: 'Direct Mail Advertising'
  },
  '541870': {
    id: 'naics-541870',
    defaultMessage: 'Advertising Material Distribution Services'
  },
  '541890': {
    id: 'naics-541890',
    defaultMessage: 'Other Services Related to Advertising'
  },
  '541910': {
    id: 'naics-541910',
    defaultMessage: 'Marketing Research and Public Opinion Polling'
  },
  '541921': {
    id: 'naics-541921',
    defaultMessage: 'Photography Studios, Portrait'
  },
  '541922': {
    id: 'naics-541922',
    defaultMessage: 'Commercial Photography'
  },
  '541930': {
    id: 'naics-541930',
    defaultMessage: 'Translation and Interpretation Services'
  },
  '541940': {
    id: 'naics-541940',
    defaultMessage: 'Veterinary Services'
  },
  '541990': {
    id: 'naics-541990',
    defaultMessage: 'All Other Professional, Scientific, and Technical Services'
  },
  '551111': {
    id: 'naics-551111',
    defaultMessage: 'Offices of Bank Holding Companies'
  },
  '551112': {
    id: 'naics-551112',
    defaultMessage: 'Offices of Other Holding Companies'
  },
  '551114': {
    id: 'naics-551114',
    defaultMessage: 'Corporate, Subsidiary, and Regional Managing Offices'
  },
  '561110': {
    id: 'naics-561110',
    defaultMessage: 'Office Administrative Services'
  },
  '561210': {
    id: 'naics-561210',
    defaultMessage: 'Facilities Support Services'
  },
  '561311': {
    id: 'naics-561311',
    defaultMessage: 'Employment Placement Agencies'
  },
  '561312': {
    id: 'naics-561312',
    defaultMessage: 'Executive Search Services'
  },
  '561320': {
    id: 'naics-561320',
    defaultMessage: 'Temporary Help Services'
  },
  '561330': {
    id: 'naics-561330',
    defaultMessage: 'Professional Employer Organizations'
  },
  '561410': {
    id: 'naics-561410',
    defaultMessage: 'Document Preparation Services'
  },
  '561421': {
    id: 'naics-561421',
    defaultMessage: 'Telephone Answering Services'
  },
  '561422': {
    id: 'naics-561422',
    defaultMessage: 'Telemarketing Bureaus and Other Contact Centers'
  },
  '561431': {
    id: 'naics-561431',
    defaultMessage: 'Private Mail Centers'
  },
  '561439': {
    id: 'naics-561439',
    defaultMessage: 'Other Business Service Centers (including Copy Shops)'
  },
  '561440': {
    id: 'naics-561440',
    defaultMessage: 'Collection Agencies'
  },
  '561450': {
    id: 'naics-561450',
    defaultMessage: 'Credit Bureaus'
  },
  '561491': {
    id: 'naics-561491',
    defaultMessage: 'Repossession Services'
  },
  '561492': {
    id: 'naics-561492',
    defaultMessage: 'Court Reporting and Stenotype Services'
  },
  '561499': {
    id: 'naics-561499',
    defaultMessage: 'All Other Business Support Services'
  },
  '561510': {
    id: 'naics-561510',
    defaultMessage: 'Travel Agencies'
  },
  '561520': {
    id: 'naics-561520',
    defaultMessage: 'Tour Operators'
  },
  '561591': {
    id: 'naics-561591',
    defaultMessage: 'Convention and Visitors Bureaus'
  },
  '561599': {
    id: 'naics-561599',
    defaultMessage: 'All Other Travel Arrangement and Reservation Services'
  },
  '561611': {
    id: 'naics-561611',
    defaultMessage: 'Investigation Services'
  },
  '561612': {
    id: 'naics-561612',
    defaultMessage: 'Security Guards and Patrol Services'
  },
  '561613': {
    id: 'naics-561613',
    defaultMessage: 'Armored Car Services'
  },
  '561621': {
    id: 'naics-561621',
    defaultMessage: 'Security Systems Services (except Locksmiths)'
  },
  '561622': {
    id: 'naics-561622',
    defaultMessage: 'Locksmiths'
  },
  '561710': {
    id: 'naics-561710',
    defaultMessage: 'Exterminating and Pest Control Services'
  },
  '561720': {
    id: 'naics-561720',
    defaultMessage: 'Janitorial Services'
  },
  '561730': {
    id: 'naics-561730',
    defaultMessage: 'Landscaping Services'
  },
  '561740': {
    id: 'naics-561740',
    defaultMessage: 'Carpet and Upholstery Cleaning Services'
  },
  '561790': {
    id: 'naics-561790',
    defaultMessage: 'Other Services to Buildings and Dwellings'
  },
  '561910': {
    id: 'naics-561910',
    defaultMessage: 'Packaging and Labeling Services'
  },
  '561920': {
    id: 'naics-561920',
    defaultMessage: 'Convention and Trade Show Organizers'
  },
  '561990': {
    id: 'naics-561990',
    defaultMessage: 'All Other Support Services'
  },
  '562111': {
    id: 'naics-562111',
    defaultMessage: 'Solid Waste Collection'
  },
  '562112': {
    id: 'naics-562112',
    defaultMessage: 'Hazardous Waste Collection'
  },
  '562119': {
    id: 'naics-562119',
    defaultMessage: 'Other Waste Collection'
  },
  '562211': {
    id: 'naics-562211',
    defaultMessage: 'Hazardous Waste Treatment and Disposal'
  },
  '562212': {
    id: 'naics-562212',
    defaultMessage: 'Solid Waste Landfill'
  },
  '562213': {
    id: 'naics-562213',
    defaultMessage: 'Solid Waste Combustors and Incinerators'
  },
  '562219': {
    id: 'naics-562219',
    defaultMessage: 'Other Nonhazardous Waste Treatment and Disposal'
  },
  '562910': {
    id: 'naics-562910',
    defaultMessage: 'Remediation Services'
  },
  '562920': {
    id: 'naics-562920',
    defaultMessage: 'Materials Recovery Facilities'
  },
  '562991': {
    id: 'naics-562991',
    defaultMessage: 'Septic Tank and Related Services'
  },
  '562998': {
    id: 'naics-562998',
    defaultMessage: 'All Other Miscellaneous Waste Management Services'
  },
  '611110': {
    id: 'naics-611110',
    defaultMessage: 'Elementary and Secondary Schools'
  },
  '611210': {
    id: 'naics-611210',
    defaultMessage: 'Junior Colleges'
  },
  '611310': {
    id: 'naics-611310',
    defaultMessage: 'Colleges, Universities, and Professional Schools'
  },
  '611410': {
    id: 'naics-611410',
    defaultMessage: 'Business and Secretarial Schools'
  },
  '611420': {
    id: 'naics-611420',
    defaultMessage: 'Computer Training'
  },
  '611430': {
    id: 'naics-611430',
    defaultMessage: 'Professional and Management Development Training'
  },
  '611511': {
    id: 'naics-611511',
    defaultMessage: 'Cosmetology and Barber Schools'
  },
  '611512': {
    id: 'naics-611512',
    defaultMessage: 'Flight Training'
  },
  '611513': {
    id: 'naics-611513',
    defaultMessage: 'Apprenticeship Training'
  },
  '611519': {
    id: 'naics-611519',
    defaultMessage: 'Other Technical and Trade Schools'
  },
  '611610': {
    id: 'naics-611610',
    defaultMessage: 'Fine Arts Schools'
  },
  '611620': {
    id: 'naics-611620',
    defaultMessage: 'Sports and Recreation Instruction'
  },
  '611630': {
    id: 'naics-611630',
    defaultMessage: 'Language Schools'
  },
  '611691': {
    id: 'naics-611691',
    defaultMessage: 'Exam Preparation and Tutoring'
  },
  '611692': {
    id: 'naics-611692',
    defaultMessage: 'Automobile Driving Schools'
  },
  '611699': {
    id: 'naics-611699',
    defaultMessage: 'All Other Miscellaneous Schools and Instruction'
  },
  '611710': {
    id: 'naics-611710',
    defaultMessage: 'Educational Support Services'
  },
  '621111': {
    id: 'naics-621111',
    defaultMessage: 'Offices of Physicians (except Mental Health Specialists)'
  },
  '621112': {
    id: 'naics-621112',
    defaultMessage: 'Offices of Physicians, Mental Health Specialists'
  },
  '621210': {
    id: 'naics-621210',
    defaultMessage: 'Offices of Dentists'
  },
  '621310': {
    id: 'naics-621310',
    defaultMessage: 'Offices of Chiropractors'
  },
  '621320': {
    id: 'naics-621320',
    defaultMessage: 'Offices of Optometrists'
  },
  '621330': {
    id: 'naics-621330',
    defaultMessage: 'Offices of Mental Health Practitioners (except Physicians)'
  },
  '621340': {
    id: 'naics-621340',
    defaultMessage:
      'Offices of Physical, Occupational and Speech Therapists, and Audiologists'
  },
  '621391': {
    id: 'naics-621391',
    defaultMessage: 'Offices of Podiatrists'
  },
  '621399': {
    id: 'naics-621399',
    defaultMessage: 'Offices of All Other Miscellaneous Health Practitioners'
  },
  '621410': {
    id: 'naics-621410',
    defaultMessage: 'Family Planning Centers'
  },
  '621420': {
    id: 'naics-621420',
    defaultMessage: 'Outpatient Mental Health and Substance Abuse Centers'
  },
  '621491': {
    id: 'naics-621491',
    defaultMessage: 'HMO Medical Centers'
  },
  '621492': {
    id: 'naics-621492',
    defaultMessage: 'Kidney Dialysis Centers'
  },
  '621493': {
    id: 'naics-621493',
    defaultMessage: 'Freestanding Ambulatory Surgical and Emergency Centers'
  },
  '621498': {
    id: 'naics-621498',
    defaultMessage: 'All Other Outpatient Care Centers'
  },
  '621511': {
    id: 'naics-621511',
    defaultMessage: 'Medical Laboratories'
  },
  '621512': {
    id: 'naics-621512',
    defaultMessage: 'Diagnostic Imaging Centers'
  },
  '621610': {
    id: 'naics-621610',
    defaultMessage: 'Home Health Care Services'
  },
  '621910': {
    id: 'naics-621910',
    defaultMessage: 'Ambulance Services'
  },
  '621991': {
    id: 'naics-621991',
    defaultMessage: 'Blood and Organ Banks'
  },
  '621999': {
    id: 'naics-621999',
    defaultMessage: 'All Other Miscellaneous Ambulatory Health Care Services'
  },
  '622110': {
    id: 'naics-622110',
    defaultMessage: 'General Medical and Surgical Hospitals'
  },
  '622210': {
    id: 'naics-622210',
    defaultMessage: 'Psychiatric and Substance Abuse Hospitals'
  },
  '622310': {
    id: 'naics-622310',
    defaultMessage:
      'Specialty (except Psychiatric and Substance Abuse) Hospitals'
  },
  '623110': {
    id: 'naics-623110',
    defaultMessage: 'Nursing Care Facilities (Skilled Nursing Facilities)'
  },
  '623210': {
    id: 'naics-623210',
    defaultMessage:
      'Residential Intellectual and Developmental Disability Facilities'
  },
  '623220': {
    id: 'naics-623220',
    defaultMessage: 'Residential Mental Health and Substance Abuse Facilities'
  },
  '623311': {
    id: 'naics-623311',
    defaultMessage: 'Continuing Care Retirement Communities'
  },
  '623312': {
    id: 'naics-623312',
    defaultMessage: 'Assisted Living Facilities for the Elderly'
  },
  '623990': {
    id: 'naics-623990',
    defaultMessage: 'Other Residential Care Facilities'
  },
  '624110': {
    id: 'naics-624110',
    defaultMessage: 'Child and Youth Services'
  },
  '624120': {
    id: 'naics-624120',
    defaultMessage: 'Services for the Elderly and Persons with Disabilities'
  },
  '624190': {
    id: 'naics-624190',
    defaultMessage: 'Other Individual and Family Services'
  },
  '624210': {
    id: 'naics-624210',
    defaultMessage: 'Community Food Services'
  },
  '624221': {
    id: 'naics-624221',
    defaultMessage: 'Temporary Shelters'
  },
  '624229': {
    id: 'naics-624229',
    defaultMessage: 'Other Community Housing Services'
  },
  '624230': {
    id: 'naics-624230',
    defaultMessage: 'Emergency and Other Relief Services'
  },
  '624310': {
    id: 'naics-624310',
    defaultMessage: 'Vocational Rehabilitation Services'
  },
  '624410': {
    id: 'naics-624410',
    defaultMessage: 'Child Day Care Services'
  },
  '711110': {
    id: 'naics-711110',
    defaultMessage: 'Theater Companies and Dinner Theaters'
  },
  '711120': {
    id: 'naics-711120',
    defaultMessage: 'Dance Companies'
  },
  '711130': {
    id: 'naics-711130',
    defaultMessage: 'Musical Groups and Artists'
  },
  '711190': {
    id: 'naics-711190',
    defaultMessage: 'Other Performing Arts Companies'
  },
  '711211': {
    id: 'naics-711211',
    defaultMessage: 'Sports Teams and Clubs'
  },
  '711212': {
    id: 'naics-711212',
    defaultMessage: 'Racetracks'
  },
  '711219': {
    id: 'naics-711219',
    defaultMessage: 'Other Spectator Sports'
  },
  '711310': {
    id: 'naics-711310',
    defaultMessage:
      'Promoters of Performing Arts, Sports, and Similar Events with Facilities'
  },
  '711320': {
    id: 'naics-711320',
    defaultMessage:
      'Promoters of Performing Arts, Sports, and Similar Events without Facilities'
  },
  '711410': {
    id: 'naics-711410',
    defaultMessage:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures'
  },
  '711510': {
    id: 'naics-711510',
    defaultMessage: 'Independent Artists, Writers, and Performers'
  },
  '712110': {
    id: 'naics-712110',
    defaultMessage: 'Museums'
  },
  '712120': {
    id: 'naics-712120',
    defaultMessage: 'Historical Sites'
  },
  '712130': {
    id: 'naics-712130',
    defaultMessage: 'Zoos and Botanical Gardens'
  },
  '712190': {
    id: 'naics-712190',
    defaultMessage: 'Nature Parks and Other Similar Institutions'
  },
  '713110': {
    id: 'naics-713110',
    defaultMessage: 'Amusement and Theme Parks'
  },
  '713120': {
    id: 'naics-713120',
    defaultMessage: 'Amusement Arcades'
  },
  '713210': {
    id: 'naics-713210',
    defaultMessage: 'Casinos (except Casino Hotels)'
  },
  '713290': {
    id: 'naics-713290',
    defaultMessage: 'Other Gambling Industries'
  },
  '713910': {
    id: 'naics-713910',
    defaultMessage: 'Golf Courses and Country Clubs'
  },
  '713920': {
    id: 'naics-713920',
    defaultMessage: 'Skiing Facilities'
  },
  '713930': {
    id: 'naics-713930',
    defaultMessage: 'Marinas'
  },
  '713940': {
    id: 'naics-713940',
    defaultMessage: 'Fitness and Recreational Sports Centers'
  },
  '713950': {
    id: 'naics-713950',
    defaultMessage: 'Bowling Centers'
  },
  '713990': {
    id: 'naics-713990',
    defaultMessage: 'All Other Amusement and Recreation Industries'
  },
  '721110': {
    id: 'naics-721110',
    defaultMessage: 'Hotels (except Casino Hotels) and Motels'
  },
  '721120': {
    id: 'naics-721120',
    defaultMessage: 'Casino Hotels'
  },
  '721191': {
    id: 'naics-721191',
    defaultMessage: 'Bed-and-Breakfast Inns'
  },
  '721199': {
    id: 'naics-721199',
    defaultMessage: 'All Other Traveler Accommodation'
  },
  '721211': {
    id: 'naics-721211',
    defaultMessage: 'RV (Recreational Vehicle) Parks and Campgrounds'
  },
  '721214': {
    id: 'naics-721214',
    defaultMessage: 'Recreational and Vacation Camps (except Campgrounds)'
  },
  '721310': {
    id: 'naics-721310',
    defaultMessage:
      "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
  },
  '722310': {
    id: 'naics-722310',
    defaultMessage: 'Food Service Contractors'
  },
  '722320': {
    id: 'naics-722320',
    defaultMessage: 'Caterers'
  },
  '722330': {
    id: 'naics-722330',
    defaultMessage: 'Mobile Food Services'
  },
  '722410': {
    id: 'naics-722410',
    defaultMessage: 'Drinking Places (Alcoholic Beverages)'
  },
  '722511': {
    id: 'naics-722511',
    defaultMessage: 'Full-Service Restaurants'
  },
  '722513': {
    id: 'naics-722513',
    defaultMessage: 'Limited-Service Restaurants'
  },
  '722514': {
    id: 'naics-722514',
    defaultMessage: 'Cafeterias, Grill Buffets, and Buffets'
  },
  '722515': {
    id: 'naics-722515',
    defaultMessage: 'Snack and Nonalcoholic Beverage Bars'
  },
  '811111': {
    id: 'naics-811111',
    defaultMessage: 'General Automotive Repair'
  },
  '811112': {
    id: 'naics-811112',
    defaultMessage: 'Automotive Exhaust System Repair'
  },
  '811113': {
    id: 'naics-811113',
    defaultMessage: 'Automotive Transmission Repair'
  },
  '811118': {
    id: 'naics-811118',
    defaultMessage:
      'Other Automotive Mechanical and Electrical Repair and Maintenance'
  },
  '811121': {
    id: 'naics-811121',
    defaultMessage:
      'Automotive Body, Paint, and Interior Repair and Maintenance'
  },
  '811122': {
    id: 'naics-811122',
    defaultMessage: 'Automotive Glass Replacement Shops'
  },
  '811191': {
    id: 'naics-811191',
    defaultMessage: 'Automotive Oil Change and Lubrication Shops'
  },
  '811192': {
    id: 'naics-811192',
    defaultMessage: 'Car Washes'
  },
  '811198': {
    id: 'naics-811198',
    defaultMessage: 'All Other Automotive Repair and Maintenance'
  },
  '811211': {
    id: 'naics-811211',
    defaultMessage: 'Consumer Electronics Repair and Maintenance'
  },
  '811212': {
    id: 'naics-811212',
    defaultMessage: 'Computer and Office Machine Repair and Maintenance'
  },
  '811213': {
    id: 'naics-811213',
    defaultMessage: 'Communication Equipment Repair and Maintenance'
  },
  '811219': {
    id: 'naics-811219',
    defaultMessage:
      'Other Electronic and Precision Equipment Repair and Maintenance'
  },
  '811310': {
    id: 'naics-811310',
    defaultMessage:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance'
  },
  '811411': {
    id: 'naics-811411',
    defaultMessage: 'Home and Garden Equipment Repair and Maintenance'
  },
  '811412': {
    id: 'naics-811412',
    defaultMessage: 'Appliance Repair and Maintenance'
  },
  '811420': {
    id: 'naics-811420',
    defaultMessage: 'Reupholstery and Furniture Repair'
  },
  '811430': {
    id: 'naics-811430',
    defaultMessage: 'Footwear and Leather Goods Repair'
  },
  '811490': {
    id: 'naics-811490',
    defaultMessage: 'Other Personal and Household Goods Repair and Maintenance'
  },
  '812111': {
    id: 'naics-812111',
    defaultMessage: 'Barber Shops'
  },
  '812112': {
    id: 'naics-812112',
    defaultMessage: 'Beauty Salons'
  },
  '812113': {
    id: 'naics-812113',
    defaultMessage: 'Nail Salons'
  },
  '812191': {
    id: 'naics-812191',
    defaultMessage: 'Diet and Weight Reducing Centers'
  },
  '812199': {
    id: 'naics-812199',
    defaultMessage: 'Other Personal Care Services'
  },
  '812210': {
    id: 'naics-812210',
    defaultMessage: 'Funeral Homes and Funeral Services'
  },
  '812220': {
    id: 'naics-812220',
    defaultMessage: 'Cemeteries and Crematories'
  },
  '812310': {
    id: 'naics-812310',
    defaultMessage: 'Coin-Operated Laundries and Drycleaners'
  },
  '812320': {
    id: 'naics-812320',
    defaultMessage: 'Drycleaning and Laundry Services (except Coin-Operated)'
  },
  '812331': {
    id: 'naics-812331',
    defaultMessage: 'Linen Supply'
  },
  '812332': {
    id: 'naics-812332',
    defaultMessage: 'Industrial Launderers'
  },
  '812910': {
    id: 'naics-812910',
    defaultMessage: 'Pet Care (except Veterinary) Services'
  },
  '812921': {
    id: 'naics-812921',
    defaultMessage: 'Photofinishing Laboratories (except One-Hour)'
  },
  '812922': {
    id: 'naics-812922',
    defaultMessage: 'One-Hour Photofinishing'
  },
  '812930': {
    id: 'naics-812930',
    defaultMessage: 'Parking Lots and Garages'
  },
  '812990': {
    id: 'naics-812990',
    defaultMessage: 'All Other Personal Services'
  },
  '813110': {
    id: 'naics-813110',
    defaultMessage: 'Religious Organizations'
  },
  '813211': {
    id: 'naics-813211',
    defaultMessage: 'Grantmaking Foundations'
  },
  '813212': {
    id: 'naics-813212',
    defaultMessage: 'Voluntary Health Organizations'
  },
  '813219': {
    id: 'naics-813219',
    defaultMessage: 'Other Grantmaking and Giving Services'
  },
  '813311': {
    id: 'naics-813311',
    defaultMessage: 'Human Rights Organizations'
  },
  '813312': {
    id: 'naics-813312',
    defaultMessage: 'Environment, Conservation and Wildlife Organizations'
  },
  '813319': {
    id: 'naics-813319',
    defaultMessage: 'Other Social Advocacy Organizations'
  },
  '813410': {
    id: 'naics-813410',
    defaultMessage: 'Civic and Social Organizations'
  },
  '813910': {
    id: 'naics-813910',
    defaultMessage: 'Business Associations'
  },
  '813920': {
    id: 'naics-813920',
    defaultMessage: 'Professional Organizations'
  },
  '813930': {
    id: 'naics-813930',
    defaultMessage: 'Labor Unions and Similar Labor Organizations'
  },
  '813940': {
    id: 'naics-813940',
    defaultMessage: 'Political Organizations'
  },
  '813990': {
    id: 'naics-813990',
    defaultMessage:
      'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)'
  },
  '814110': {
    id: 'naics-814110',
    defaultMessage: 'Private Households'
  },
  '921110': {
    id: 'naics-921110',
    defaultMessage: 'Executive Offices'
  },
  '921120': {
    id: 'naics-921120',
    defaultMessage: 'Legislative Bodies'
  },
  '921130': {
    id: 'naics-921130',
    defaultMessage: 'Public Finance Activities'
  },
  '921140': {
    id: 'naics-921140',
    defaultMessage: 'Executive and Legislative Offices, Combined'
  },
  '921150': {
    id: 'naics-921150',
    defaultMessage: 'American Indian and Alaska Native Tribal Governments'
  },
  '921190': {
    id: 'naics-921190',
    defaultMessage: 'Other General Government Support'
  },
  '922110': {
    id: 'naics-922110',
    defaultMessage: 'Courts'
  },
  '922120': {
    id: 'naics-922120',
    defaultMessage: 'Police Protection'
  },
  '922130': {
    id: 'naics-922130',
    defaultMessage: 'Legal Counsel and Prosecution'
  },
  '922140': {
    id: 'naics-922140',
    defaultMessage: 'Correctional Institutions'
  },
  '922150': {
    id: 'naics-922150',
    defaultMessage: 'Parole Offices and Probation Offices'
  },
  '922160': {
    id: 'naics-922160',
    defaultMessage: 'Fire Protection'
  },
  '922190': {
    id: 'naics-922190',
    defaultMessage: 'Other Justice, Public Order, and Safety Activities'
  },
  '923110': {
    id: 'naics-923110',
    defaultMessage: 'Administration of Education Programs'
  },
  '923120': {
    id: 'naics-923120',
    defaultMessage: 'Administration of Public Health Programs'
  },
  '923130': {
    id: 'naics-923130',
    defaultMessage:
      "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)"
  },
  '923140': {
    id: 'naics-923140',
    defaultMessage: "Administration of Veterans' Affairs"
  },
  '924110': {
    id: 'naics-924110',
    defaultMessage:
      'Administration of Air and Water Resource and Solid Waste Management Programs'
  },
  '924120': {
    id: 'naics-924120',
    defaultMessage: 'Administration of Conservation Programs'
  },
  '925110': {
    id: 'naics-925110',
    defaultMessage: 'Administration of Housing Programs'
  },
  '925120': {
    id: 'naics-925120',
    defaultMessage:
      'Administration of Urban Planning and Community and Rural Development'
  },
  '926110': {
    id: 'naics-926110',
    defaultMessage: 'Administration of General Economic Programs'
  },
  '926120': {
    id: 'naics-926120',
    defaultMessage: 'Regulation and Administration of Transportation Programs'
  },
  '926130': {
    id: 'naics-926130',
    defaultMessage:
      'Regulation and Administration of Communications, Electric, Gas, and Other Utilities'
  },
  '926140': {
    id: 'naics-926140',
    defaultMessage: 'Regulation of Agricultural Marketing and Commodities'
  },
  '926150': {
    id: 'naics-926150',
    defaultMessage:
      'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors'
  },
  '927110': {
    id: 'naics-927110',
    defaultMessage: 'Space Research and Technology'
  },
  '928110': {
    id: 'naics-928110',
    defaultMessage: 'National Security'
  },
  '928120': {
    id: 'naics-928120',
    defaultMessage: 'International Affairs'
  },
  '48-49': {
    id: 'naics-48-49',
    defaultMessage: 'Transportation and Warehousing'
  },
  '31-33': {
    id: 'naics-31-33',
    defaultMessage: 'Manufacturing'
  },
  '44-45': {
    id: 'naics-44-45',
    defaultMessage: 'Retail Trade'
  }
})

export const naicsShortTitle = defineMessages({
  '11': {
    id: 'shortForm-11',
    defaultMessage: 'Agri'
  },
  '21': {
    id: 'shortForm-21',
    defaultMessage: 'Mining,'
  },
  '22': {
    id: 'shortForm-22',
    defaultMessage: 'Utilities'
  },
  '23': {
    id: 'shortForm-23',
    defaultMessage: 'Construction'
  },
  '31': {
    id: 'shortForm-31',
    defaultMessage: 'Manufacturing'
  },
  '32': {
    id: 'shortForm-32',
    defaultMessage: 'Material Manufacture'
  },
  '33': {
    id: 'shortForm-33',
    defaultMessage: 'Metal Manufacture'
  },
  '42': {
    id: 'shortForm-42',
    defaultMessage: 'Wholesale'
  },
  '44': {
    id: 'shortForm-44',
    defaultMessage: 'Retail'
  },
  '45': {
    id: 'shortForm-45',
    defaultMessage: 'Retail'
  },
  '48': {
    id: 'shortForm-48',
    defaultMessage: 'Transportation'
  },
  '49': {
    id: 'shortForm-49',
    defaultMessage: 'Transportation'
  },
  '51': {
    id: 'shortForm-51',
    defaultMessage: 'Information'
  },
  '52': {
    id: 'shortForm-52',
    defaultMessage: 'Finance'
  },
  '53': {
    id: 'shortForm-53',
    defaultMessage: 'Real Estate, Rental, Leasing'
  },
  '54': {
    id: 'shortForm-54',
    defaultMessage: 'Professional Services'
  },
  '55': {
    id: 'shortForm-55',
    defaultMessage: 'Management'
  },
  '56': {
    id: 'shortForm-56',
    defaultMessage: 'Waste Mgmt'
  },
  '61': {
    id: 'shortForm-61',
    defaultMessage: 'Educational'
  },
  '62': {
    id: 'shortForm-62',
    defaultMessage: 'Health Care'
  },
  '71': {
    id: 'shortForm-71',
    defaultMessage: 'Arts and Rec'
  },
  '72': {
    id: 'shortForm-72',
    defaultMessage: 'Accommodation'
  },
  '81': {
    id: 'shortForm-81',
    defaultMessage: 'Other'
  },
  '92': {
    id: 'shortForm-92',
    defaultMessage: 'Public Admin'
  },
  '48-49': {
    id: 'shortForm-48-49',
    defaultMessage: 'Transportation'
  },
  '31-33': {
    id: 'shortForm-31-33',
    defaultMessage: 'Manufacturing'
  },
  '44-45': {
    id: 'shortForm-44-45',
    defaultMessage: 'Retail'
  }
})

export default naics
