import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Immutable, { fromJS, RecordOf, List } from 'immutable'
import createAddCommentForm from 'shared/utils/createAddCommentForm'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import messagesSelectors from 'shared/selectors/messagesSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import paths from 'buyer/routes/paths'
import parsePath from 'shared/utils/parsePath'
import createEditForm from 'shared/utils/createEditForm'
import UserCommentContainer from 'shared/containers/UserCommentContainer'
import Page from 'shared/components/Page'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import Button from 'shared/components/Button'
import Switch from 'shared/components/Switch'
import Text from 'shared/components/Text'
import {
  injectIntl,
  defineMessages,
  IntlShape,
  FormattedMessage
} from 'react-intl'
import { loadComments, removeComment } from 'shared/actions'
import { ADD_COMMENT, UPDATE_COMMENT } from 'shared/actionTypes'
import dateFormat from 'shared/utils/dateFormat'
import withOrg from 'shared/utils/withOrg'
import OrgLogo from 'shared/components/OrgLogo'
import Tooltip from 'shared/components/Tooltip'
import Link from 'shared/components/Link'
import Loading from 'shared/components/Loading'
import startCase from 'lodash.startcase'
import Milestones from '../../components/Milestones'
import { RouteComponentProps } from 'react-router'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import UserAvatarContainer from 'shared/containers/UserAvatarContainer'
import PageSection from 'shared/components/PageSection'

const NewVetComment = createAddCommentForm({
  formName: 'buyer/vet/addVetComment',
  submitFormActionType: ADD_COMMENT
})

const messages = defineMessages({
  internalComments: {
    id: 'VetActivitiesContainer.Title',
    defaultMessage: 'Internal Comments'
  },
  buttonAdd: {
    id: 'VetActivitiesContainer.Add',
    defaultMessage: 'Add'
  },
  buttonShowAll: {
    id: 'VetActivitiesContainer.ShowAll',
    defaultMessage: 'View {sizeValues} more entries'
  },
  showNotesOnly: {
    id: 'VetActivitiesContainer.NotesOnly',
    defaultMessage: 'Hide Event Activities'
  },
  suggested: {
    id: 'VetActivitiesContainer.Suggested',
    defaultMessage: 'Suggested'
  },
  approved: {
    id: 'VetActivitiesContainer.Approved',
    defaultMessage: 'Approved'
  },
  invited: {
    id: 'VetActivitiesContainer.Invited',
    defaultMessage: 'Invited'
  },
  enableInvitation: {
    id: 'VetActivitiesContainer.EnableInvitation',
    defaultMessage: 'Enabled Invitations'
  },
  activitiesAndComments: {
    id: 'VetActivitiesContainer.ActivitiesAndComments',
    defaultMessage: 'Activities & Comments'
  }
})

type SupplierProps = {
  name: string
  orgUnitId: string
  redirectFrom: string
  logoUrl: string
}

const Supplier = withOrg((props: SupplierProps) => (
  <Tooltip title={props.name}>
    <Link
      to={`${parsePath(paths.supplierProfile, {
        supplierId: props.orgUnitId
      })}?redirectFrom=${props.redirectFrom}`}
    >
      <span className='dib flex items-center pa1 mr2 mv1 v-mid ba b--black-10 br2 bg-white h2 w2'>
        <OrgLogo url={props.logoUrl} name={props.name} />
      </span>
    </Link>
  </Tooltip>
))

const transformActivities = activities => {
  const updatedActivities =
    activities &&
    activities.reduce((reduction, value) => {
      if (reduction.size === 0) {
        return reduction.push(value)
      } else {
        if (!value.get('type') || value.get('type') === 'ownerChanged') {
          // user notes
          return reduction.push(value)
        } else if (
          value.get('createdBy') !== reduction.last().get('createdBy') ||
          value.get('type') !== reduction.last().get('type') ||
          dateFormat(value.get('createdDate')) !==
            dateFormat(reduction.last().get('createdDate'))
        ) {
          return reduction.push(value)
        } else {
          return value.get('reference')
            ? reduction.updateIn([reduction.size - 1, 'reference'], reference =>
                Immutable.List.isList(reference)
                  ? reference.push(value.get('reference'))
                  : fromJS([reference, value.get('reference')])
              )
            : reduction
        }
      }
    }, fromJS([]))
  return updatedActivities
}

type Props = {
  loadComments: (loadVetComments: {
    ancestorId: string
    messageKind: string
  }) => void
  vetId: string
  isLoading: boolean
  currentUserId: string
  removeComment: (removeVetComment: {
    ancestorId: string
    messageKind: string
    messageId: string
  }) => void
  milestones: List<RecordOf<{ type: string; date: string | Date }>>
  intl: IntlShape
} & RouteComponentProps

type State = {
  showAll: boolean
  showOnlyNotes: boolean
  updatedActivities?: List<
    RecordOf<{
      id: string
      type: string
      createdBy: string
      createdDate: string
      body: string
    }>
  >
}

export class VetActivitiesContainer extends Component<Props, State> {
  state: State = {
    showAll: false,
    showOnlyNotes: false,
    updatedActivities: undefined
  }

  formIndex = 0

  componentDidMount() {
    const { loadComments, vetId } = this.props

    if (vetId) {
      loadComments({ ancestorId: vetId, messageKind: 'VetComment' })
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.activities) {
      if (props.activities !== state.activities) {
        return {
          updatedActivities: transformActivities(props.activities)
        }
      } else {
        return {
          activities: props.activities
        }
      }
    }

    return null
  }

  renderOwnerChanged = activity => {
    return (
      <div className='flex items-center mt2'>
        <Text>
          <FormattedMessage
            id='VetActivitiesContainer.ChangedOwner'
            defaultMessage='Changed Owner to'
          />
        </Text>
        <div className='dib w2 ml2'>
          <UserAvatarContainer
            userId={activity.get('reference')}
            profileUrl={paths.userprofile.replace(
              ':userId',
              activity.get('reference')
            )}
          />
        </div>
      </div>
    )
  }

  renderCustomContent = activity => {
    const { intl, location } = this.props

    return (
      <Fragment>
        <Text className='mt1'>
          {messages[activity.get('type')]
            ? intl.formatMessage(messages[activity.get('type')])
            : startCase(activity.get('type'))}
          {activity.get('reference') && `:`}
        </Text>
        {activity.get('reference') && (
          <div className='flex items-center flex-wrap'>
            {Immutable.List.isList(activity.get('reference')) ? (
              activity
                .get('reference')
                .map(orgUnitId => (
                  <Supplier
                    key={orgUnitId}
                    orgUnitId={orgUnitId}
                    redirectFrom={location.pathname}
                  />
                ))
            ) : (
              <Supplier
                orgUnitId={activity.get('reference')}
                redirectFrom={location.pathname}
              />
            )}
          </div>
        )}
      </Fragment>
    )
  }

  handleShowAll = () => {
    this.setState({ showAll: true })
  }

  handleShowOnlyNotesChange = () => {
    this.setState({ showOnlyNotes: !this.state.showOnlyNotes })
  }

  render() {
    const {
      intl,
      isLoading,
      vetId,
      currentUserId,
      removeComment,
      milestones
    } = this.props
    const { showAll, showOnlyNotes, updatedActivities } = this.state
    const formName = 'buyer/vets/containers/VetActivitiesContainer'
    const showActivities =
      updatedActivities && showOnlyNotes
        ? updatedActivities.filter(activity =>
            showOnlyNotes ? !activity.get('type') : true
          )
        : updatedActivities

    return !isLoading ? (
      <Page title={intl.formatMessage(messages.activitiesAndComments)}>
        <ScrollToPosition />
        <Main>
          {updatedActivities && updatedActivities.size > 0 ? (
            <PageSection
              noPadding
              title={intl.formatMessage(messages.activitiesAndComments)}
              actions={
                <div>
                  <Switch
                    label={intl.formatMessage(messages.showNotesOnly)}
                    checked={showOnlyNotes}
                    onChange={this.handleShowOnlyNotesChange}
                  />
                </div>
              }
            >
              {showActivities && showActivities.size > 5 && !showAll && (
                <div className='pa2 bb b--black-10'>
                  <Button
                    variant='text'
                    size='small'
                    autoSize
                    label={intl.formatMessage(messages.buttonShowAll, {
                      sizeValues: showActivities.size - 5
                    })}
                    onClick={this.handleShowAll}
                  />
                </div>
              )}
              {showActivities &&
                showActivities
                  .takeLast(showAll ? showActivities.size : 5)
                  .map(activity => {
                    const EditableUserCommentContainer =
                      currentUserId === activity.get('createdBy') &&
                      !activity.get('type')
                        ? createEditForm(
                            {
                              formName: `${formName}${this.formIndex++}`,
                              submitActionType: UPDATE_COMMENT,
                              stopEditModeOnSave: true,
                              onRemove: () =>
                                removeComment({
                                  ancestorId: vetId,
                                  messageId: activity.get('id'),
                                  messageKind: 'VetComment'
                                })
                            },
                            UserCommentContainer
                          )
                        : UserCommentContainer

                    return (
                      <EditableUserCommentContainer
                        key={activity.get('id')}
                        userId={activity.get('createdBy')}
                        userProfileUrl={parsePath(paths.userprofile, {
                          userId: activity.get('createdBy')
                        })}
                        date={activity.get('createdDate')}
                        comment={activity.get('body')}
                        customContent={
                          activity.get('type') === 'ownerChanged'
                            ? this.renderOwnerChanged(activity)
                            : !!activity.get('type')
                            ? this.renderCustomContent(activity)
                            : undefined
                        }
                        initialValues={{
                          ancestorId: vetId,
                          comment: activity.get('body'),
                          messageId: activity.get('id'),
                          isPrivate: false,
                          messageKind: 'VetComment'
                        }}
                      />
                    )
                  })}
              <div className='pa4-ns pa3'>
                <NewVetComment
                  addButtonLabel={intl.formatMessage(messages.buttonAdd)}
                  initialValues={{
                    ancestorId: vetId,
                    isPrivate: false,
                    messageKind: 'VetComment'
                  }}
                />
              </div>
            </PageSection>
          ) : (
            <div className='tc ma3'>
              <h6 className='f7 mid-gray fw6 dib pb0 ma0'>
                <FormattedMessage
                  id='VetActivitiesContainer.NoActivity'
                  defaultMessage='No Activities'
                />
              </h6>
            </div>
          )}
        </Main>
        <Aside className='mt4-l'>
          <Milestones milestones={milestones} />
        </Aside>
      </Page>
    ) : (
      <Loading />
    )
  }
}

type ContainerProps = {
  match: { params: { vetId: string } }
} & RouteComponentProps

export default connect(
  (state, props: ContainerProps) => {
    return {
      isLoading: currentVetSelectors.isLoading(state),
      vetId: props.match.params.vetId,
      currentUserId: sessionSelectors.getUserId(state),
      activities: messagesSelectors.getAllByKindId(
        state,
        'VetComment',
        props.match.params.vetId
      ),
      milestones: currentVetSelectors.getMilestones(state)
    }
  },
  {
    loadComments,
    removeComment
  }
)(injectIntl(VetActivitiesContainer))
