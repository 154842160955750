import React, { Component } from 'react'
import { connect } from 'react-redux'
import InsightPaper from 'shared/components/InsightPaper'
import InsightTitle from 'shared/components/InsightTitle'
import ActiveSupplier from '../../components/ActiveSupplier'
import insightsSelectors from '../../store/insightsSelectors'
import Scrollable from 'shared/components/Scrollable'
import parsePath from 'shared/utils/parsePath'
import paths from '../../../routes/paths'
import { FormattedMessage } from 'react-intl'
import { RecordOf, List } from 'immutable'
import Grid from '@material-ui/core/Grid'

type Props = {
  activeSuppliers: List<
    RecordOf<{
      id: string
      name: string
      profilePictureUrl: string
      views: number
      viewsLastMonth: number
      viewsThisMonth: number
    }>
  >
}
export class MostActiveSuppliers extends Component<Props> {
  render() {
    const { activeSuppliers } = this.props

    if (activeSuppliers.size < 1) {
      return null
    }

    return (
      <Grid item md={6} xs={12}>
        <InsightTitle>
          <FormattedMessage
            id='MostActiveSuppliers.Trending'
            defaultMessage='Trending'
          />
        </InsightTitle>
        <InsightPaper>
          <Scrollable maxHeight={345}>
            <ol className='list ma0 pa0'>
              {activeSuppliers &&
                activeSuppliers.map((supplier, index) => (
                  <ActiveSupplier
                    key={supplier.get('id')}
                    profilePictureUrl={supplier.get('profilePictureUrl')}
                    profileUrl={parsePath(paths.supplierProfile, {
                      supplierId: supplier.get('id')
                    })}
                    name={supplier.get('name')}
                    viewsThisMonth={supplier.get('viewsThisMonth')}
                    viewsLastMonth={supplier.get('viewsLastMonth')}
                    place={index + 1}
                  />
                ))}
            </ol>
          </Scrollable>
        </InsightPaper>
      </Grid>
    )
  }
}

export default connect(state => ({
  activeSuppliers: insightsSelectors.getMostActiveSuppliers(state)
}))(MostActiveSuppliers)
