import React, { Component, ReactNode } from 'react'
import Badge from 'shared/components/Badge'
import ListItem from 'shared/components/List/ListItem'
import OrgLogo from 'shared/components/OrgLogo'
import Link from 'shared/components/Link'

type Props = {
  logoUrl: string
  name: string
  place: number
  profileUrl: string
  secondaryText: ReactNode
}

class ActiveOrgUnit extends Component<Props> {
  render() {
    const { logoUrl, profileUrl, name, place, ...rest } = this.props

    return (
      <div className='dt w-100'>
        <div className='dtc v-mid pr3 w2'>
          <Badge place={place} />
        </div>
        <div className='dtc'>
          {profileUrl ? (
            <ListItem
              className='mv2'
              leftAvatar={
                <Link to={profileUrl} className='dim'>
                  <OrgLogo url={logoUrl} />
                </Link>
              }
              primaryText={<Link to={profileUrl}>{name}</Link>}
              {...rest}
            />
          ) : (
            <ListItem
              className='mv2'
              leftAvatar={<OrgLogo url={logoUrl} />}
              primaryText={name}
              {...rest}
            />
          )}
        </div>
      </div>
    )
  }
}

export default ActiveOrgUnit
