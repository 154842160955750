import {
  GET_COMMUNITIES,
  GET_COMMUNITY,
  INVITE_TO_COMMUNITIES,
  UPDATE_COMMUNITY_BUYER,
  UPDATE_COMMUNITY,
  REMOVE_FROM_COMMUNITY
} from '../../actionTypes'
import { LOAD_SUPPLIER_PROFILE } from '../../../SupplierProfile/actionTypes'
import { requestSuccess, LOGOUT } from 'shared/actionTypes'
import { fromJS, Map, List, Record, RecordOf } from 'immutable'
import Community from 'shared/models/Community'
import CommunityPlanMembership from 'shared/models/CommunityPlanMembership'
import { CREATE_TIER2_COMMUNITY } from 'buyer/Insights/store/actionTypes'

export type CommunitiesStateProps = {
  isLoading: boolean
  byId: Map<string, RecordOf<Community>>
  membershipsById: Map<string, RecordOf<CommunityPlanMembership>>
  bySupplierId: Map<string, List<string>>
  membershipsBySupplierId: Map<string, List<string>>
}

type CommunitiesState = RecordOf<CommunitiesStateProps>

export const CommunitiesFactory = Record<CommunitiesStateProps>({
  isLoading: false,
  byId: Map({}),
  membershipsById: Map({}),
  bySupplierId: Map({}),
  membershipsBySupplierId: Map({})
})

export const communitiesReducer = (
  state: CommunitiesState = CommunitiesFactory(),
  action
): CommunitiesState => {
  switch (action.type) {
    case GET_COMMUNITY:
    case GET_COMMUNITIES:
      return state.set('isLoading', true)

    case requestSuccess(GET_COMMUNITY):
    case requestSuccess(GET_COMMUNITIES):
      return state
        .mergeIn(['byId'], fromJS(action.payload.byId))
        .set('isLoading', false)

    case requestSuccess(INVITE_TO_COMMUNITIES):
      const supplierId = Object.keys(action.payload.bySupplierId).toString()
      return state
        .mergeIn(['membershipsById'], fromJS(action.payload.membershipsById))
        .updateIn(['membershipsBySupplierId', supplierId], value =>
          !value
            ? fromJS(action.payload.membershipsBySupplierId[supplierId])
            : value.concat(
                fromJS(action.payload.membershipsBySupplierId[supplierId])
              )
        )
        .updateIn(['bySupplierId', supplierId], value =>
          !value
            ? fromJS(action.payload.bySupplierId[supplierId])
            : value.concat(fromJS(action.payload.bySupplierId[supplierId]))
        )

    case requestSuccess(LOAD_SUPPLIER_PROFILE):
      return state
        .mergeIn(['byId'], fromJS(action.payload.communities.byId))
        .mergeIn(
          ['membershipsById'],
          fromJS(action.payload.communities.membershipsById)
        )
        .mergeIn(
          ['bySupplierId'],
          fromJS(action.payload.communities.bySupplierId)
        )
        .mergeIn(
          ['membershipsBySupplierId'],
          fromJS(action.payload.communities.membershipsBySupplierId)
        )

    case requestSuccess(CREATE_TIER2_COMMUNITY):
    case requestSuccess(UPDATE_COMMUNITY_BUYER):
    case requestSuccess(UPDATE_COMMUNITY):
      return action.payload.deepMerge
        ? state.mergeDeepIn(['byId'], fromJS(action.payload.byId))
        : state.mergeIn(['byId'], fromJS(action.payload.byId))

    case requestSuccess(REMOVE_FROM_COMMUNITY):
      // @ts-ignore
      const membershipId = state
        .get('membershipsById')
        .toList()
        .find(membership => {
          return (
            membership.get('supplier') === action.payload.supplierId &&
            membership.getIn(['parents', 'Community']) ===
              action.payload.communityId
          )
        })
        .get('id')

      const indexMembershipsBySupplierId = state
        .getIn(['membershipsBySupplierId', action.payload.supplierId])
        .indexOf(membershipId)

      return state
        .deleteIn([
          'bySupplierId',
          action.payload.supplierId,
          action.payload.communityId
        ])
        .deleteIn(['membershipsById', membershipId])
        .deleteIn([
          'membershipsBySupplierId',
          action.payload.supplierId,
          indexMembershipsBySupplierId
        ])
    case LOGOUT:
      return fromJS({})

    default:
      return state
  }
}

export default communitiesReducer
