import React, { FC, ReactNode } from 'react'

type Props = {
  title: string
  subtitle: string | ReactNode
  graphic: string
}

export const Benefit: FC<Props> = props => {
  return (
    <div className=''>
      <h2 className='f4 f3-ns fw6 teal mt0 mb3'>{props.title}</h2>
      <div className='lh-copy f6 f4-ns fw6 mid-gray mb3 mb4-ns tl tc-ns'>
        {props.subtitle}
      </div>
      <div style={{ height: 200 }}>
        <img src={props.graphic} alt={props.title} className={'w-100 h-100'} />
      </div>
    </div>
  )
}

export default Benefit
