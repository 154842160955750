import React, {
  Fragment,
  useEffect,
  FunctionComponent,
  useState,
  ReactNode
} from 'react'
import ExternalLink from 'shared/components/ExternalLink'
import { FormattedMessage } from 'react-intl'
import Loading from 'shared/components/Loading'
import bowser from 'bowser'

const browser = bowser.getParser(window.navigator.userAgent)
const browserInfo = browser.getBrowser()
const osName = browser.getOSName(true) // optional argument: toLowerCase => set to true

type Props = {
  url: string
  mobile?: boolean
  height?: number
  postfix?: string
  label?: string
}

const PitchDeck: FunctionComponent<Props> = props => {
  const { url, mobile, height = 335, postfix, label } = props
  const [fileOverLimit, setFileOverLimit] = useState<boolean | null>(null)

  useEffect(() => {
    if (fileOverLimit === null && url) {
      const http = new XMLHttpRequest()
      http.open('HEAD', url)
      http.onreadystatechange = () => {
        if (http.status === 200) {
          const fileSize = Number(http.getResponseHeader('content-length'))
          // 1 MB in bytes * 10
          if (fileSize > 1048576 * 10) {
            setFileOverLimit(true)
          } else {
            setFileOverLimit(false)
          }
        } else {
          setFileOverLimit(true)
        }
      }
      http.send()

      return () => {
        http.abort()
      }
    }
  }, [url, fileOverLimit])

  // android has issues showing the attached files
  if (
    osName === 'android' ||
    (browserInfo.name === 'Internet Explorer' &&
      browserInfo.version &&
      browserInfo.version.startsWith('11'))
  ) {
    return <DownloadAttachment url={url} postfix={postfix} label={label} />
  }

  if (url.endsWith('pdf')) {
    return (
      <Fragment>
        <object
          data={url}
          width='100%'
          height={mobile ? undefined : height}
          type='application/pdf'
        >
          <b>
            <FormattedMessage
              id='PitchDeck.PitchDeck'
              defaultMessage='Pitch deck'
            />
          </b>
          <br />
          <FormattedMessage
            id='PitchDeck.ThisBrowserDoesNotSupportPDFs'
            defaultMessage='This browser does not support PDFs.'
          />
          <br />
          <ExternalLink href={url}>
            <FormattedMessage
              id='PitchDeck.PleaseDownloadPdfToView'
              defaultMessage='Please download to view it.'
            />
          </ExternalLink>
        </object>
      </Fragment>
    )
  }

  if (fileOverLimit === null) {
    return <Loading />
  }

  if (
    !fileOverLimit &&
    (url.endsWith('pptx') ||
      url.endsWith('ppt') ||
      url.endsWith('xlsx') ||
      url.endsWith('doc') ||
      url.endsWith('docx'))
  ) {
    return (
      <>
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
          width='100%'
          height={height}
          frameBorder='0'
          title='Pitch Deck'
          onError={() => console.error('error')}
        />
        <FormattedMessage
          id='PitchDeck.DownloadLink'
          defaultMessage='Download the file'
        >
          {text => (
            <DownloadAttachment
              url={url}
              postfix={postfix}
              label={label || text}
              noWrapper
            />
          )}
        </FormattedMessage>
      </>
    )
  } else {
    return <DownloadAttachment url={url} postfix={postfix} label={label} />
  }
}

type DownloadAttachmentProps = {
  url: string
  postfix?: string
  label?: string | ReactNode
  noWrapper?: boolean
}
const DownloadAttachment: FunctionComponent<DownloadAttachmentProps> = props => {
  return (
    <div className={props.noWrapper ? '' : 'pa3-5 bg-near-white'}>
      <FormattedMessage
        id='PitchDeck.DownloadAttachment'
        defaultMessage='Download Attachment'
      >
        {text => (
          <ExternalLink href={props.url}>
            {props.label
              ? props.label
              : `${text} ${props.postfix ? props.postfix : ''}`}
          </ExternalLink>
        )}
      </FormattedMessage>
    </div>
  )
}

export default PitchDeck
