import React, { FunctionComponent, ReactElement, MouseEvent } from 'react'
import { List, RecordOf } from 'immutable'
import User from 'shared/models/User'
import Table from 'shared/components/Table'
import { Column, TableCellProps } from 'react-virtualized'
import { injectIntl, IntlShape } from 'react-intl'
import ListItem from '../List/ListItem'
import Avatar from '../Avatar'
import Button from '../Button'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import parsePath from 'shared/utils/parsePath'
import supplierPaths from '../../../supplier/routes/paths'
import buyerPaths from '../../../buyer/routes/paths'

type Props = {
  users: List<RecordOf<User>>
  action?: (user: User) => void
  actionLabel?: string | ReactElement<FormattedMessage>
  isClientApp?: boolean
  intl: IntlShape
} & RouteComponentProps

export const UserPickList: FunctionComponent<Props> = props => {
  const { users, action, actionLabel, isClientApp } = props

  const renderUserCell = ({ rowData }: TableCellProps) => {
    return (
      <ListItem
        mini
        primaryText={`${rowData.get('firstName') || ''} ${rowData.get(
          'lastName'
        ) || ''}`}
        secondaryText={rowData.get('email')}
        leftAvatar={
          <Avatar
            url={rowData.get('profilePictureUrl')}
            name={`${rowData.get('firstName') || ''} ${rowData.get(
              'lastName'
            ) || ''}`}
          />
        }
        className='ma0'
      />
    )
  }

  const renderActionCell = ({ rowData }: TableCellProps) => {
    return (
      <Button
        size='small'
        onClick={(e: MouseEvent) => {
          e.stopPropagation()
          props.action && props.action(rowData)
        }}
      >
        {actionLabel}
      </Button>
    )
  }

  return users && users.size > 0 ? (
    <div className='bg-white br1 ba b--black-10'>
      <Table
        onRowClick={({
          rowData
        }: {
          rowData: RecordOf<{
            id: string
          }>
        }) => {
          props.history.push(
            isClientApp
              ? parsePath(buyerPaths.userprofile, { userId: rowData.get('id') })
              : parsePath(supplierPaths.userProfile, {
                  userId: rowData.get('id')
                })
          )
        }}
        rowGetter={({ index }) => users.get(index)}
        rowCount={users.size}
      >
        <Column
          dataKey=''
          width={200}
          label='User'
          cellRenderer={renderUserCell}
        />
        <Column dataKey='title' width={150} label='Title' />
        <Column
          dataKey='department'
          width={150}
          label='Department'
          flexGrow={1}
        />
        {action && (
          <Column dataKey='' width={100} cellRenderer={renderActionCell} />
        )}
      </Table>
    </div>
  ) : null
}

export default injectIntl(withRouter(UserPickList))
