import { fromJS } from 'immutable'
import { requestSuccess, requestFailure } from 'shared/actionTypes'
import * as actionTypes from '../../actionTypes'

const defaultState = fromJS({
  q: '',
  searchBuyers: false,
  result: [],
  total: undefined,
  isFetching: false,
  fetchErrorMessage: undefined,
  isCreating: false,
  matchingOrgs: fromJS([]),
  isSearching: false,
  searchExactDomain: false
})

const orgsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_FOR_ORGANIZATION:
      const { q, searchBuyers, searchExactDomain } = action.payload.toJS()
      return state.merge({
        q,
        searchBuyers,
        isFetching: true,
        fetchErrorMessage: undefined,
        searchExactDomain
      })

    case requestSuccess(actionTypes.SEARCH_FOR_ORGANIZATION):
      return state.merge({
        result: fromJS(action.payload.orgs),
        total: action.payload.totalOrgs,
        isFetching: false,
        fetchErrorMessage: undefined
      })

    case requestFailure(actionTypes.SEARCH_FOR_ORGANIZATION):
      return state.merge({
        isFetching: false,
        fetchErrorMessage: action.payload.message
      })

    case actionTypes.START_CREATING_ORG:
      return state.merge({
        isCreating: true
      })

    case actionTypes.CREATE_ORG:
      return state.merge({
        isSearching: true
      })

    case requestSuccess(actionTypes.CREATE_ORG):
      return action.payload.matchingOrgs
        ? state.merge({
            matchingOrgs: fromJS(action.payload.matchingOrgs),
            isSearching: false
          })
        : state.merge({
            isCreating: false,
            isSearching: false,
            matchingOrgs: fromJS([])
          })

    case actionTypes.STOP_CREATING_ORG:
      return state.merge({
        isCreating: false,
        matchingOrgs: fromJS([])
      })

    case actionTypes.MAKE_SUPPLIER_A_BUYER:
      const includesOrgUnitId = state
        .get('result')
        .some(val => val.get('id') === action.payload.orgUnitId)
      if (action.payload.action === 'remove' && includesOrgUnitId) {
        return state.updateIn(['result'], result => {
          const deleteIndex = result.findIndex(
            res => res.get('id') === action.payload.orgUnitId
          )
          return result.delete(deleteIndex)
        })
      }
      return state
    default:
      return state
  }
}

export default orgsReducer
