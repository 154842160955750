import React, { ReactNode, useState } from 'react'
import classnames from 'classnames'
import ReportRowDetails, { SpendItemType } from './ReportRowDetails'
import Divider from 'shared/components/Divider'
import { useIntl } from 'react-intl'
import PlusIcon from '@material-ui/icons/Add'
import MinusIcon from '@material-ui/icons/Remove'
import IconButton from '@material-ui/core/IconButton'

type Props = {
  label?: ReactNode | string
  color?: string
  separator?: boolean
  bold?: boolean
  useToggle?: boolean
  before: ReactNode | string | number
  after?: ReactNode | string | number
  shift?: ReactNode | string | number
  subCategory?: string
  type?: 'qualified' | 'potential' | 'disqualified'
  lineItems1?: Array<SpendItemType>
  lineItems2?: Array<SpendItemType>
}

const CompareReportRow = ({
  label,
  color = 'black',
  before,
  after,
  shift,
  separator,
  useToggle,
  bold,
  subCategory,
  type
}: Props) => {
  const intl = useIntl()
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const icon = showDetails ? <MinusIcon /> : <PlusIcon />
  const rowClassName = classnames(['dt', 'w-100', color], { fw6: !!bold })

  return (
    <>
      <div className={rowClassName}>
        <div className='dtc w-25 fw6 pv2 ph2 v-mid'>
          <div className='flex items-center'>
            {useToggle && (
              <IconButton
                color='primary'
                aria-label='Expand/Collapse Details'
                onClick={() => setShowDetails(() => !showDetails)}
              >
                {icon}
              </IconButton>
            )}
            {label}
          </div>
        </div>
        <div className='dtc w-25 pv2 ph2 tr v-mid'>
          {typeof before === 'number'
            ? intl.formatNumber(before, {
                style: 'currency',
                signDisplay: 'auto',
                currency: 'USD',
                maximumFractionDigits: 0
              })
            : before}
        </div>
        {after !== undefined && (
          <div className='dtc w-25 pv2 ph2 tr v-mid'>
            {typeof after === 'number'
              ? intl.formatNumber(after, {
                  style: 'currency',
                  signDisplay: 'auto',
                  currency: 'USD',
                  maximumFractionDigits: 0
                })
              : after}
          </div>
        )}
        {shift !== undefined && (
          <div className='dtc w-25 pv2 ph2 tr v-mid bg-washed-yellow'>
            {typeof shift === 'number'
              ? intl.formatNumber(shift, {
                  style: 'currency',
                  signDisplay: 'auto',
                  currencySign: 'accounting',
                  currency: 'USD',
                  maximumFractionDigits: 0
                })
              : shift}
          </div>
        )}
      </div>
      {!!showDetails && subCategory && (
        <ReportRowDetails subCategory={subCategory} type={type} />
      )}
      {!!separator && <Divider />}
    </>
  )
}

export default CompareReportRow
