import { connect } from 'react-redux'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import CommunityDetails from '../../components/CommunityDetails'

export default connect((state, props) => {
  const communityId = props.match.params.communityId
  const language = state.get('i18n').locale
  return {
    language,
    publicClients: communitiesSelectors.getBuyers(state, communityId, true),
    privateClients: communitiesSelectors.getBuyers(state, communityId, false)
  }
})(CommunityDetails)
