export const LOAD_VETS = 'vets/LOAD_VETS'
export const LOAD_VETS_SUCCESS = 'vets/LOAD_VETS_SUCCESS'
export const LOAD_VETS_FAILURE = 'vets/LOAD_VETS_FAILURE'

export const START_NEW_VET = 'vets/START_NEW_VET'
export const CREATE_VET = 'vets/CREATE_VET'
export const CLOSE_VET = 'vets/CLOSE_VET'
export const CANCEL_VET = 'vets/CANCEL_VET'
export const DELETE_VET = 'vets/DELETE_VET'
export const REOPEN_VET = 'vets/REOPEN_VET'

export const UPDATE_VET = 'vets/UPDATE_VET'
export const UPDATE_VET_SUCCESS = 'vets/UPDATE_VET_SUCCESS'
export const UPDATE_VET_FAILURE = 'vets/UPDATE_VET_FAILURE'

export const LOAD_VET = 'vets/LOAD_VET'
export const LOAD_VET_SUCCESS = 'vets/LOAD_VET_SUCCESS'
export const LOAD_VET_FAILURE = 'vets/LOAD_VET_FAILURE'

export const ADD_VET_SUPPLIER = 'vets/ADD_VET_SUPPLIER'
export const ADD_VET_SUPPLIER_SUCCESS = 'vets/ADD_VET_SUPPLIER_SUCCESS'
export const ADD_VET_SUPPLIER_FAILURE = 'vets/ADD_VET_SUPPLIER_FAILURE'

export const ADD_VET_SUPPLIER_BY_EMAIL = 'vets/ADD_VET_SUPPLIER_BY_EMAIL'
export const INVITE_SUPPLIER_FROM_VET = 'vets/INVITE_SUPPLIER_FROM_VET'
export const RESET_ADD_BY_EMAIL = 'vet/RESET_ADD_BY_EMAIL'

export const REMOVE_VET_SUPPLIER = 'vets/REMOVE_VET_SUPPLIER'
export const REMOVE_VET_SUPPLIER_SUCCESS = 'vets/REMOVE_VET_SUPPLIER_SUCCESS'
export const REMOVE_VET_SUPPLIER_FAILURE = 'vets/REMOVE_VET_SUPPLIER_FAILURE'

export const INVITE_VET_SUPPLIER = 'vets/INVITE_VET_SUPPLIER'
export const INVITE_VET_SUPPLIER_SUCCESS = 'vets/INVITE_VET_SUPPLIER_SUCCESS'
export const INVITE_VET_SUPPLIER_FAILURE = 'vets/INVITE_VET_SUPPLIER_FAILURE'

export const ADD_VET_TEAM_MEMBER = 'vets/ADD_VET_TEAM_MEMBER'
export const REMOVE_VET_TEAM_MEMBER = 'vets/REMOVE_VET_TEAM_MEMBER'
export const UPDATE_VET_TEAM_MEMBER = 'vets/UPDATE_VET_TEAM_MEMBER'

export const APPROVE_VET_SUPPLIER = 'vets/APPROVE_VET_SUPPLIER'
export const APPROVE_VET_SUPPLIER_SUCCESS = 'vets/APPROVE_VET_SUPPLIER_SUCCESS'
export const APPROVE_VET_SUPPLIER_FAILURE = 'vets/APPROVE_VET_SUPPLIER_FAILURE'

export const ADD_INTERNAL_COMMENT = 'vets/ADD_INTERNAL_COMMENT'
export const ADD_INTERNAL_COMMENT_SUCCESS = 'vets/ADD_INTERNAL_COMMENT_SUCCESS'
export const ADD_INTERNAL_COMMENT_FAILURE = 'vets/ADD_INTERNAL_COMMENT_FAILURE'
export const UPDATE_INTERNAL_COMMENT = 'vets/UPDATE_INTERNAL_COMMENT'
export const REMOVE_INTERNAL_COMMENT = 'vets/REMOVE_INTERNAL_COMMENT'
export const TAG_SUPPLIERS = 'vets/TAG_SUPPLIERS'

export const ADD_CRITERIA = 'vets/ADD_CRITERIA'
export const REMOVE_CRITERIA = 'vets/REMOVE_CRITERIA'
export const UPDATE_CRITERIA = 'vets/UPDATE_CRITERIA'

export const UP_VOTE = 'vets/UP_VOTE'
export const DOWN_VOTE = 'vets/DOWN_VOTE'

export const CHOOSE_VET_SUPPLIER = 'vets/CHOOSE_VET_SUPPLIER'
export const REJECT_VET_SUPPLIER = 'vets/REJECT_VET_SUPPLIER'

export const REMOVE_ATTACHMENT = 'vets/REMOVE_ATTACHMENT'
export const DELETE_PROJECT_BACKGROUND = 'vets/DELETE_PROJECT_BACKGROUND'

export const CLEAR_ERROR_MESSAGE = 'vets/CLEAR_ERROR_MESSAGE'

export const UPDATE_VET_BUYER_SUPPLIER = 'buyer/vets/UPDATE_VET_BUYER_SUPPLIER'
export const REMOVE_RESPONSE_FILE = 'buyer/vets/REMOVE_RESPONSE_FILE'
export const ADD_VET_ADDITION = 'buyer/vets/ADD_VET_ADDITION'
export const CANCEL_VET_ADDITION = 'buyer/vets/CANCEL_VET_ADDITION'

export const ADD_CONTACT_SEND_TO = 'buyer/vets/ADD_CONTACT_SEND_TO'
export const REMOVE_CONTACT_SEND_TO = 'buyer/vets/REMOVE_CONTACT_SEND_TO'
export const UPDATE_CONTACT_SEND_TO = 'buyer/vets/UPDATE_CONTACT_SEND_TO'
export const LOAD_VET_QUESTIONS = 'buyer/vets/LOAD_VET_QUESTIONS'
export const ANSWER_VET_QUESTION = 'buyer/vets/ANSWER_VET_QUESTION'
