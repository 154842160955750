import { call, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import supplier from 'shared/utils/api/supplier'
import { notify } from 'shared/actions'

export function* uploadVetPresentationSaga(action) {
  const {
    file: { name }
  } = action.payload

  const response = yield call(supplier.uploadVetResponse, action.payload)

  if (response) {
    yield put(
      notify({
        message: `${name} has been uploaded`
      })
    )
  }

  return response
}

export default createApiRequestSaga(uploadVetPresentationSaga, {
  notifyOnError: true
})
