import React, { useState } from 'react'
import Page from 'shared/components/Page'
import Button from 'shared/components/Button'
import FileInput from 'shared/components/FileInput'
import Card from 'shared/components/Card'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import { useDispatch } from 'react-redux'
import { uploadDataQualityFile } from '../../store/actions'
import { useSelector } from 'react-redux'
import dataQualitySelectors from '../../store/selectors/dataQualitySelectors'
import DataQualityColumnsMapping from './DataQualityColumnsMapping'
import { BodOutput } from '../../store/reducers/dataQualityReducer/dataQualityReducer'

const DataQualityServiceUpload = () => {
  const dispatch = useDispatch()
  const [file, setFile] = useState<File | null>(null)
  const isUploading = useSelector(dataQualitySelectors.isUploading)
  const columnsMapping: BodOutput = useSelector(
    dataQualitySelectors.getColumnsMapping
  )
  const jobId = useSelector(dataQualitySelectors.getUploadJobId)
  const filename = useSelector(dataQualitySelectors.getUploadFilename)

  const handleFileChange = (selectFile: File) => {
    setFile(selectFile)
  }

  const handleFileUpload = () => {
    if (file) {
      dispatch(uploadDataQualityFile({ file }))
    }
  }

  return (
    <Page title='Data Quality Service - Upload'>
      <Main>
        <Card>
          <label className='db mb1 f7 fw6'>Upload Vendor Master File</label>
          <FileInput
            accept='.xlsx, .csv'
            className='mt3'
            name='file'
            onChange={handleFileChange}
          />
          <div className='mt3'>
            <Button
              autoSize
              onClick={handleFileUpload}
              disabled={isUploading || !file}
            >
              Upload
            </Button>
          </div>
          {jobId && filename && columnsMapping && (
            <DataQualityColumnsMapping
              jobId={jobId}
              filename={filename}
              columnsMapping={columnsMapping}
            />
          )}
        </Card>
      </Main>
      <Aside></Aside>
    </Page>
  )
}

export default DataQualityServiceUpload
