import React, { Component, ImgHTMLAttributes } from 'react'
import profileSvg from 'shared/assets/icons/profile.svg'

/**
 * Displays an image or a default image if Url is null
 */
type Props = {
  /** Url to display as image for Avatar */
  url: string
  /** The css class name of the image element */
  className?: string
  /** Define color of highlight class */
  name?: string
  highlight?: string
  style?: { width: string }
  /** Alternate text of avatar */
  alt?: string
}
class Avatar extends Component<Props & ImgHTMLAttributes<HTMLImageElement>> {
  static defaultProps = {
    url: profileSvg
  }

  state = {
    error: false,
    width: '',
    height: ''
  }

  handleOnError = e => {
    // if src is broken, load default
    this.setState({ error: true })
  }

  handleOnLoad = e => {
    this.setState({ width: e.target.width, height: e.target.height })
  }

  render() {
    const { url, className, name, highlight, style } = this.props
    const { width, height } = this.state
    return (
      <img
        className={`br-100 dib v-mid ${className || ''} ${
          highlight ? `ba bw1 ${highlight}` : ''
        }`}
        alt={
          !this.state.error && url !== profileSvg && name
            ? `Avatar of ${name}`
            : ''
        }
        src={this.state.error ? profileSvg : url || profileSvg}
        onError={this.handleOnError}
        onLoad={this.handleOnLoad}
        height={width !== height ? width : undefined}
        width={width !== height ? width : undefined}
        style={style}
      />
    )
  }
}

export default Avatar
