import React, {
  FunctionComponent,
  useState,
  useEffect,
  ChangeEvent
} from 'react'
import { List } from 'immutable'
import { connect } from 'react-redux'
import FilterHOC from '../FilterHOC'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import Rating from 'shared/components/Rating'
import { Skeleton } from '@material-ui/lab'
import searchSelectors from '../../selectors/searchSelectors'
import RootState from '../../../../shared/models/RootState'

export type Props = {
  fourStarsBased?: boolean
  relationshipRating: number
  showSkeleton?: boolean
  onChangeFilterOption: (params: { key: string; value?: number }) => void
  category?: string
}

export const FilterRelationshipRatingContainer: FunctionComponent<Props> = props => {
  const [unrated, setUnrated] = useState<boolean>(
    props.relationshipRating === 0
  )
  const [value, setValue] = useState<number>(props.relationshipRating)

  useEffect(() => {
    setValue(props.relationshipRating)
    setUnrated(props.relationshipRating === 0)
  }, [props.relationshipRating])

  const handleOnChange = (event, newValue) => {
    const { onChangeFilterOption } = props
    setValue(newValue)
    if (event.type === 'click' || event.key === 'Enter') {
      onChangeFilterOption({ key: 'relationshipRating', value })
    }
  }
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleOnChange(e, value)
    }
  }
  const handleRatingHover = (event, newValue) => {
    if (newValue === -1) {
      setValue(props.relationshipRating)
    } else {
      setValue(newValue)
    }
  }

  const handleResetRating = () => {
    const { onChangeFilterOption } = props
    onChangeFilterOption({ key: 'relationshipRating', value: undefined })
    setValue(0)
  }

  const handleUnrated = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChangeFilterOption } = props
    if (e.currentTarget.checked) {
      onChangeFilterOption({ key: 'relationshipRating', value: 0 })
    } else {
      onChangeFilterOption({ key: 'relationshipRating', value: undefined })
    }
    setValue(0)
    setUnrated(e.currentTarget.checked)
  }

  const { fourStarsBased, showSkeleton, category } = props

  return (
    <div>
      {!unrated && (
        <>
          {showSkeleton && (
            <div className='pt1'>
              <Skeleton variant='rect' width='100%' height={18} />
            </div>
          )}
          {!showSkeleton && (
            <div className='flex items-center'>
              <div className='w-40-ns w-32 dib v-mid'>
                <Rating
                  key={`rating-edit`}
                  name='filter-rating'
                  max={fourStarsBased ? 4 : 5}
                  value={value}
                  size={'small'}
                  onKeyDown={handleKeyDown}
                  onChange={handleOnChange}
                  onChangeActive={handleRatingHover}
                  aria-label={`Filter rating: ${value} out of ${
                    fourStarsBased ? 4 : 5
                  } and up `}
                />
              </div>
              <Label className='f7 fw3 mid-gray dib v-mid mr1'>
                &amp;&nbsp;
                <FormattedMessage
                  id='FilterRelationshipRatingContainer.Up'
                  defaultMessage='Up'
                />
              </Label>
              {value > 0 && (
                <Button
                  label={
                    <FormattedMessage
                      id='FilterRelationshipRatingContainer.Clear'
                      defaultMessage='Clear'
                    />
                  }
                  secondary
                  autoSize
                  size={'small'}
                  className='flex-auto tr'
                  onClick={handleResetRating}
                />
              )}
            </div>
          )}
        </>
      )}
      {showSkeleton && (
        <div className='pt1'>
          <Skeleton variant='rect' width='100%' height={18} />
        </div>
      )}
      {!showSkeleton && (
        <div className={!unrated ? 'pt2' : ''}>
          <fieldset style={{ display: 'contents' }}>
            <legend aria-label={category} />
            <input
              type='checkbox'
              id='unrated'
              onChange={handleUnrated}
              className='b--black-10 mr1'
              checked={unrated}
            />
            <label htmlFor='unrated' className='f7 fw3 mid-gray'>
              <FormattedMessage
                id='FilterRelationshipRatingContainer.UnratedSuppliers'
                defaultMessage='Unrated Suppliers'
              />
            </label>
          </fieldset>
        </div>
      )}
    </div>
  )
}

export default connect(
  (state: RootState) => {
    const relationshipRating = filterOptionsSelectors.getFilterOption(
      state,
      'relationshipRating'
    )

    return {
      showSkeleton: searchSelectors.isAggregating(state),
      relationshipRating: List.isList(relationshipRating)
        ? null
        : parseFloat(relationshipRating)
    }
  },
  {
    ...FilterHOC
  }
)(FilterRelationshipRatingContainer)
