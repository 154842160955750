import { call } from 'redux-saga/effects'
import buyer from 'shared/utils/api/buyer'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* getOrgSettingsSaga() {
  const {
    expanded,
    reviewOptions = { rating: true, preferred: true },
    defaultFilters,
    fixedFilters,
    ...rest
  } = yield call(buyer.getSettings)
  const buyerOrg = yield call(buyer.get)

  return yield {
    name: buyerOrg.name,
    clientOf: buyerOrg.clientOf,
    domains: buyerOrg.domains,
    logo: buyerOrg.logo,
    questionsById: expanded.Question,
    reviewOptions,
    defaultFilters,
    fixedFilters,
    ...rest
  }
}

export default createApiRequestSaga(getOrgSettingsSaga)
