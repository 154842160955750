import { fork, call, takeEvery, takeLatest, all, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import paths from './routes/paths'
import loadCompanySaga from './shared/sagas/loadCompanySaga'
import loadSupplierInsightSaga from './shared/sagas/loadSupplierInsightSaga'
import loadVetsSaga from './Vets/sagas/loadVetsSaga'
import loadVetBuyerSupplierSaga from './Vets/sagas/loadVetBuyerSupplierSaga'
import addVetContactSaga from './Vets/sagas/addVetContactSaga'
import removeVetContactSaga from './Vets/sagas/removeVetContactSaga'
import showInterestedSaga from './Vets/sagas/showInterestedSaga'
import uploadVetPresentationSaga from './Vets/sagas/uploadVetPresentationSaga'
import companySagas from './Company/sagas'
import communitiesSagas from './Communities/sagas'
import takeFirst from 'shared/utils/sagas/takeFirst'
import vets from 'shared/utils/api/vets'
import fileToUrl from 'shared/utils/data/fileToUrl'
import staging from 'shared/utils/api/staging'
import addSurveyCollaborateContactSaga from 'supplier/shared/sagas/addSurveyCollaborateContactSaga'

import {
  LOAD_COMPANY,
  LOAD_VETS,
  LOAD_SUPPLIER_INSIGHT,
  UPDATE_CUSTOMIZE_PITCH_DECK,
  FOLLOW_CLIENT,
  PROCESS_PAYMENT,
  CANCEL_SUBSCRIPTION,
  ADD_SURVEY_COLLABORATE_CONTACT
  // LOAD_STAGING_HISTORY,
  // UPLOAD_SPEND_FILE,
  // PUBLISH_SPEND,
  // REMOVE_SPEND,
  // REMAP_SUPPLIER
} from './shared/actionTypes'
import {
  LOAD_VET,
  OPEN_VET,
  DECLINE_VET,
  ACCEPT_VET,
  SHOW_INTEREST_VET,
  ACCEPT_VET_CRITERIA,
  UPLOAD_VET_PRESENTATION,
  REMOVE_VET_PRESENTATION,
  UPDATE_VET_PROPOSAL,
  ADD_VET_CONTACT,
  REMOVE_VET_CONTACT,
  LOAD_VET_QUESTIONS,
  UPDATE_VET_QUESTION,
  REMOVE_VET_QUESTION,
  ADD_VET_QUESTION
} from './Vets/actionTypes'
import {
  LOAD_TIER2_ALLOCATED_SPEND,
  UPDATE_TIER2_ALLOCATED_SPEND,
  GET_TIER2_COMMUNITIES,
  LOAD_TIER2_DIVERSE_SUMMARY,
  POST_TIER2_DIVERSE_SUMMARY,
  REMOVE_TIER2_DIVERSE_SUMMARY
} from './Insight/store/actionTypes'
import {
  LOAD_ALL_SURVEY,
  SAVE_SURVEY,
  LOAD_SURVEY_RESPONSE,
  SAVE_SURVEY_RESPONSE,
  UPLOAD_SURVEY_ATTACHMENT,
  REMOVE_SURVEY_ATTACHMENT,
  SUBMIT_SURVEY,
  TAKE_ESG_SURVEY,
  EDIT_SURVEY,
  REMOVE_SURVEY
} from './Survey/actionTypes'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import supplier from 'shared/utils/api/supplier'
import updateCustomizePitchDeckSaga from './shared/sagas/updateCustomizePitchDeckSaga'
import processPaymentSaga from './shared/sagas/processPaymentSaga'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import tier2Api from 'shared/utils/api/tier2'
import survey from 'shared/utils/api/survey'
import saveSurveyResponseSaga from './Survey/sagas/saveSurveyResponseSaga'

export default function* rootSaga() {
  yield all([
    takeEvery(LOAD_COMPANY, loadCompanySaga),
    takeLatest(LOAD_SUPPLIER_INSIGHT, loadSupplierInsightSaga),
    takeLatest(LOAD_VETS, loadVetsSaga),
    takeLatest(LOAD_VET, loadVetBuyerSupplierSaga),
    takeFirst(
      [UPDATE_CUSTOMIZE_PITCH_DECK, FOLLOW_CLIENT],
      updateCustomizePitchDeckSaga
    ),
    takeFirst(PROCESS_PAYMENT, processPaymentSaga),
    takeFirst(
      CANCEL_SUBSCRIPTION,
      createApiRequestSaga(supplier.cancelSubscription)
    ),
    takeFirst(
      OPEN_VET,
      createApiRequestSaga(supplier.openVet, {
        usePayload: true,
        notifyOnError: true
      })
    ),

    takeFirst(ADD_VET_CONTACT, addVetContactSaga),
    takeFirst(REMOVE_VET_CONTACT, removeVetContactSaga),
    takeFirst(ADD_SURVEY_COLLABORATE_CONTACT, addSurveyCollaborateContactSaga),
    takeFirst(
      DECLINE_VET,
      createApiRequestSaga(supplier.declineVet, {
        usePayload: true,
        notifyOnError: true
      })
    ),

    takeFirst(
      ACCEPT_VET,
      createApiRequestSaga(supplier.acceptVet, {
        usePayload: true,
        notifyOnError: true
      })
    ),

    takeLatest(
      UPDATE_VET_PROPOSAL,
      createApiRequestSaga(supplier.updateVetProposal, {
        usePayload: true,
        notifyOnError: true
      })
    ),

    takeFirst(SHOW_INTEREST_VET, showInterestedSaga),

    takeFirst(
      ACCEPT_VET_CRITERIA,
      createApiRequestSaga(supplier.acceptVetCriteria, {
        usePayload: true,
        notifyOnError: true
      })
    ),

    takeFirst(UPLOAD_VET_PRESENTATION, uploadVetPresentationSaga),

    takeFirst(
      REMOVE_VET_PRESENTATION,
      createApiRequestSaga(supplier.removeVetPresentation, {
        usePayload: true,
        returnPayload: true,
        notifyOnError: true
      })
    ),

    takeLatest(
      LOAD_VET_QUESTIONS,
      createApiRequestSaga(vets.getVetQuestionsForSupplier, {
        usePayload: true
      })
    ),

    takeFirst(
      ADD_VET_QUESTION,
      createApiRequestSaga(vets.addVetQuestion, {
        usePayload: true
      })
    ),

    takeFirst(
      UPDATE_VET_QUESTION,
      createApiRequestSaga(vets.updateVetQuestion, {
        usePayload: true
      })
    ),

    takeFirst(
      REMOVE_VET_QUESTION,
      createApiRequestSaga(vets.removeVetQuestion, {
        usePayload: true,
        returnPayload: true
      })
    ),

    takeLatest(
      LOAD_TIER2_ALLOCATED_SPEND,
      createApiRequestSaga(tier2Api.getSpendAllocation)
    ),

    takeLatest(
      UPDATE_TIER2_ALLOCATED_SPEND,
      createApiRequestSaga(function*(action) {
        const allocatedSpend = {
          ...action.payload,
          diverseSupplierAllocations: action.payload.diverseSupplierAllocations.map(
            allocValue => ({
              id: allocValue.id,
              allocation: allocValue.allocation || 0
            })
          )
        }
        yield call(
          api.post,
          `${apiRoutes.authService}/tier2/allocated`,
          allocatedSpend
        )
        return yield action.payload
      })
    ),
    takeLatest(
      GET_TIER2_COMMUNITIES,
      createApiRequestSaga(function*() {
        const response = yield call(
          api.get,
          `${apiRoutes.authService}/tier2/memberships`
        )
        return response.map(currentBuyer => {
          const orgUnits = Object.values<{
            name: string
            id: string
            logo: any
          }>(currentBuyer.community.expanded.OrgUnit)
          const buyer = orgUnits && orgUnits[0]
          return {
            communityId: currentBuyer.community.id,
            buyerName: buyer?.name,
            buyerLogo: fileToUrl(buyer?.logo),
            buyerId: buyer?.id,
            status: currentBuyer.communityPlanMembership.status
          }
        })
      })
    ),
    takeLatest(
      LOAD_TIER2_DIVERSE_SUMMARY,
      createApiRequestSaga(function*() {
        const response = yield call(staging.getDiverseSummaryList)
        return yield response
      })
    ),
    takeFirst(
      REMOVE_TIER2_DIVERSE_SUMMARY,
      createApiRequestSaga(function*(action) {
        yield call(staging.removeDiverseSummary, action.payload)
        return yield action.payload
      })
    ),
    takeFirst(
      POST_TIER2_DIVERSE_SUMMARY,
      createApiRequestSaga(function*(action) {
        let response = yield call(staging.createDiverseSummary, action.payload)
        response = { ...response.diversityReference, loadId: response.loadId }
        return yield response
      })
    ),
    takeFirst(
      LOAD_ALL_SURVEY,
      createApiRequestSaga(survey.loadAllSurvey, {
        notifyOnError: true
      })
    ),
    takeLatest(
      LOAD_SURVEY_RESPONSE,
      createApiRequestSaga(survey.loadSurveyResponseDetails, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(
      UPLOAD_SURVEY_ATTACHMENT,
      createApiRequestSaga(survey.uploadSurveyAttachment, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(
      SUBMIT_SURVEY,
      createApiRequestSaga(survey.submitSurvey, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(SAVE_SURVEY_RESPONSE, saveSurveyResponseSaga),
    takeFirst(
      SAVE_SURVEY,
      createApiRequestSaga(survey.saveSurvey, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(
      TAKE_ESG_SURVEY,
      createApiRequestSaga(function*(action) {
        const response = yield call(survey.saveSurvey, {
          surveyId: undefined,
          type: 'esg'
        })
        yield put(push(paths.esgSurvey))
        return yield response
      })
    ),
    takeFirst(
      REMOVE_SURVEY_ATTACHMENT,
      createApiRequestSaga(function*(action) {
        yield call(survey.removeSurveyAttachment, action.payload)
        return yield action.payload
      })
    ),
    takeFirst(
      EDIT_SURVEY,
      createApiRequestSaga(function*(action) {
        return yield call(survey.editSurvey, action.payload)
      })
    ),
    takeFirst(
      REMOVE_SURVEY,
      createApiRequestSaga(function*(action) {
        yield call(survey.removeSurvey, action.payload)
        return yield action.payload
      })
    ),
    fork(companySagas),
    fork(communitiesSagas)
  ])
}

export function triggerAction(action) {
  return function* saga({ payload }) {
    yield put(action(payload))
  }
}
