import { call, select, put } from 'redux-saga/effects'
import { Map, fromJS, Set } from 'immutable'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import vets from 'shared/utils/api/vets'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { notify } from 'shared/actions'

export function* updateVetSaga(action) {
  const payload =
    action.payload instanceof Map ? action.payload.toJS() : action.payload

  const {
    vetId,
    document: file,
    desiredOutcome,
    challenge,
    name,
    links,
    keywords,
    tagsFieldName,
    newTag,
    internalDescription,
    plannedCompletionDate,
    category,
    department,
    projectEstimate,
    spendType,
    approvedBudget,
    spendStart,
    spendEnd,
    projectBackground,
    includeBudget,
    isPrivate,
    conciergeFields,
    ...restPayload
  } = payload

  let newKeywords
  if (tagsFieldName) {
    newKeywords = yield select(
      currentVetSelectors.getVetField,
      tagsFieldName
    ) || fromJS([])
    // newKeywords = newKeywords.push(newTag).toSet().toList().toJS()
    newKeywords = newKeywords
      .toSet()
      .union(
        Set(
          newTag
            .split(',')
            .map(tag => tag.trim())
            .filter(tag => !!tag)
        )
      )
      .toList()
      .toJS()
  }

  let response
  response = yield call(vets.updateVet, {
    vetId,
    desiredOutcome,
    challenge,
    name,
    links,
    internalDescription,
    plannedCompletionDate,
    category,
    department,
    projectEstimate,
    spendType,
    approvedBudget,
    spendStart,
    spendEnd,
    keywords: newKeywords || keywords,
    includeBudget,
    isPrivate,
    conciergeFields,
    vetDetails: restPayload
  })

  if (projectBackground) {
    const projectBackgroundResponse = yield call(vets.uploadProjectBackground, {
      vetId,
      projectBackground
    })
    response.vet.projectBackground = projectBackgroundResponse

    yield put(
      notify({
        message: `${projectBackground.name} has been uploaded`
      })
    )
  }
  // response: { vet, vetBuyerSupplier }

  if (file) {
    const fileResponse = yield call(vets.uploadDocument, { vetId, file })
    response.vet.vetDetails.document = fileResponse
  }

  return yield response
}

export default createApiRequestSaga(updateVetSaga, {
  notifyOnError: true
})
