import { call } from 'redux-saga/effects'
import community from 'shared/utils/api/community'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* inviteExistingSaga(action) {
  const { communityId, sendEmail } = action.payload
  return yield call(community.inviteAllExisting, { communityId, sendEmail })
}

export default createApiRequestSaga(inviteExistingSaga)
