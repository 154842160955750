import * as actionTypes from '../../actionTypes'
import { requestSuccess } from 'shared/actionTypes'
import { fromJS } from 'immutable'

const initialState = fromJS({
  userDialogOpen: false
})

const newUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_USER_DIALOG:
      return state.set('userDialogOpen', true)
    case actionTypes.CLOSE_USER_DIALOG:
      return state.set('userDialogOpen', false)
    case requestSuccess(actionTypes.ADD_USER):
    case requestSuccess(actionTypes.BULK_ADD_USERS):
      return state.set('userDialogOpen', false)
    default:
      return state
  }
}

export default newUserReducer
