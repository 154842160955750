import { call } from 'redux-saga/effects'
import insight from 'shared/utils/api/insight'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* loadSnapshotSaga(action) {
  const snapshot = yield call(insight.getSnapshot, action.payload)
  const lineItems = yield call(insight.getSnapshotLineItems, snapshot.id)

  return yield { snapshot, lineItems }
}

export default createApiRequestSaga(loadSnapshotSaga)
