const createSubmitFormAction = (formName, actionType, stopEditModeOnSave) => {
  return (payload, meta) => ({
    type: actionType,
    payload,
    meta: {
      ...meta,
      formName,
      stopEditModeOnSave
    }
  })
}

export default createSubmitFormAction
