import * as actionTypes from '../../actionTypes'
import { LOGOUT, requestSuccess } from 'shared/actionTypes'
import { Record, Map, fromJS, List } from 'immutable'

const VetBuyerSupplierRecord = Record({
  vet: Map({}),
  vetBuyerSupplier: Map({}),
  companyName: '',
  isLoading: false,
  vetQuestions: List(),
  isLoadingQuestions: false
})

export const currentVetReducer = (
  state = new VetBuyerSupplierRecord(),
  action
) => {
  switch (action.type) {
    case requestSuccess(actionTypes.SHOW_INTEREST_VET):
    case requestSuccess(actionTypes.ACCEPT_VET_CRITERIA):
    case requestSuccess(actionTypes.ACCEPT_VET):
    case requestSuccess(actionTypes.DECLINE_VET):
    case requestSuccess(actionTypes.OPEN_VET):
    case actionTypes.LOAD_VET_SUCCESS:
      const vetInfo = action.payload
      const companyName = vetInfo && vetInfo.orgUnit && vetInfo.orgUnit.name

      return state.merge({
        vet: fromJS(vetInfo.vet),
        vetBuyerSupplier: fromJS(vetInfo.vetBuyerSupplier),
        companyName: companyName || 'Anonymous',
        isLoading: false
      })
    case actionTypes.LOAD_VET:
      return state.merge({ isLoading: true })

    case requestSuccess(actionTypes.UPDATE_VET_PROPOSAL):
      return state
        .setIn(
          ['vetBuyerSupplier', 'collaboration', 'sendToContacts'],
          fromJS(action.payload.sendToContacts)
        )
        .setIn(
          ['vetBuyerSupplier', 'collaboration', 'proposal'],
          fromJS(action.payload.proposal)
        )

    case requestSuccess(actionTypes.UPLOAD_VET_PRESENTATION):
      return state.setIn(
        ['vetBuyerSupplier', 'collaboration', 'proposal', 'documents'],
        fromJS(action.payload)
      )

    case requestSuccess(actionTypes.REMOVE_VET_PRESENTATION):
      const indexDocument = state
        .getIn(
          ['vetBuyerSupplier', 'collaboration', 'proposal', 'documents'],
          fromJS([])
        )
        .findIndex(
          document => document.get('fileName') === action.payload.fileName
        )

      return indexDocument !== -1
        ? state.deleteIn([
            'vetBuyerSupplier',
            'collaboration',
            'proposal',
            'documents',
            indexDocument
          ])
        : state

    case requestSuccess(actionTypes.ADD_VET_CONTACT):
    case requestSuccess(actionTypes.REMOVE_VET_CONTACT):
      return action.payload.sendToContacts
        ? state.setIn(
            ['vetBuyerSupplier', 'collaboration', 'sendToContacts'],
            fromJS(action.payload.sendToContacts)
          )
        : state

    case actionTypes.LOAD_VET_QUESTIONS:
      return state.set('isLoadingQuestions', true)

    case requestSuccess(actionTypes.LOAD_VET_QUESTIONS):
      return state
        .set('vetQuestions', fromJS(action.payload))
        .set('isLoadingQuestions', false)

    case requestSuccess(actionTypes.ADD_VET_QUESTION):
      return state.update('vetQuestions', vetQuestions =>
        vetQuestions
          ? vetQuestions.push(fromJS(action.payload))
          : fromJS([action.payload])
      )

    case requestSuccess(actionTypes.UPDATE_VET_QUESTION):
      return state.update('vetQuestions', vetQuestions => {
        const vetQuestionIndex = vetQuestions.findIndex(
          question => question.get('id') === action.payload.id
        )
        return vetQuestions.update(vetQuestionIndex, vetQuestion =>
          vetQuestion.merge(action.payload)
        )
      })

    case requestSuccess(actionTypes.REMOVE_VET_QUESTION):
      return state.update('vetQuestions', vetQuestions =>
        vetQuestions.filter(
          question => question.get('id') !== action.payload.questionId
        )
      )

    case LOGOUT:
      return new VetBuyerSupplierRecord()
    default:
      return state
  }
}

export default currentVetReducer
