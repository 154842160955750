import React, { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'

const styles = {
  small: 16,
  medium: 24,
  large: 32
}

type Props = {
  countryAbbrev: string
  toolTipTitle?: string | ReactElement<FormattedMessage>
  size?: 'small' | 'medium' | 'large'
}

const CountryFlag = (props: Props) => {
  const { countryAbbrev, toolTipTitle, size = 'medium' } = props

  const renderFlag = () => (
    <div
      role='img'
      aria-label={countryAbbrev}
      className='ba b--moon-gray'
      style={{
        backgroundImage: `url(${`/images/flags/${
          countryAbbrev && countryAbbrev !== 'null' ? countryAbbrev : 'unknown'
        }.png`})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: 'white',
        width: styles[size],
        height: (styles[size] * 2) / 3 + 2
      }}
    />
  )
  return toolTipTitle ? (
    <Tooltip title={toolTipTitle}>{renderFlag()}</Tooltip>
  ) : (
    renderFlag()
  )
}

export default CountryFlag
