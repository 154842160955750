import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

type InviteExisting = { communityId: string; sendEmail: boolean }
type UpdateCommunity = { communityId: string; logo: string }
type LoadCommunityInsight = string
type GetCommunity = string

export const getCommunities = createAction(actionTypes.GET_COMMUNITIES)
export const getCommunity = createAction<GetCommunity>(
  actionTypes.GET_COMMUNITY
)
export const inviteToCommunities = createAction(
  actionTypes.INVITE_TO_COMMUNITIES
)
export const inviteExisting = createAction<InviteExisting>(
  actionTypes.INVITE_EXISTING
)
export const batchInviteToCommunities = createAction(
  actionTypes.BATCH_INVITE_TO_COMMUNITIES
)
export const removeFromCommunity = createAction(
  actionTypes.REMOVE_FROM_COMMUNITY
)

export const updateCommunityBuyer = createAction(
  actionTypes.UPDATE_COMMUNITY_BUYER
)
export const updateCommunity = createAction<UpdateCommunity>(
  actionTypes.UPDATE_COMMUNITY
)

export const loadCommunityInsight = createAction<LoadCommunityInsight>(
  actionTypes.LOAD_COMMUNITY_INSIGHT
)

export const batchInviteToTier2 = createAction(
  actionTypes.BATCH_INVITE_TO_TIER2
)
