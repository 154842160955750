import {
  addFilterOption,
  removeFilterOption,
  toggleFilterOption,
  changeFilterOption,
  mergeFilterOption
} from '../../actions'

const filterActions = {
  addFilterOption,
  removeFilterOption,
  onToggleFilterOption: toggleFilterOption,
  onChangeFilterOption: changeFilterOption,
  onMergeFilterOption: mergeFilterOption
}

export default filterActions
