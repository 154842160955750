import { call, select, fork, join } from 'redux-saga/effects'
import suppliers from 'shared/utils/api/suppliers'
import searchSelectors from '../../selectors/searchSelectors'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import parseSupplier from '../../../../shared/utils/data/parseSupplier'
import parseCard from 'shared/utils/data/parseCard'
import mapValues from 'lodash.mapvalues'
import mapKeys from 'lodash.mapkeys'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { searchSupplier } from '../../actions'
import routingSelectors from 'shared/selectors/routingSelectors'

export function* searchSupplierSaga(
  action: ReturnType<typeof searchSupplier>,
  aggregateQuery: boolean,
  nonAggregatedQueryTask: any // a forked task
) {
  const q = yield select(searchSelectors.getQ)
  const filter = yield select(filterOptionsSelectors.getFilterOptions)
  const scope = yield select(filterOptionsSelectors.getFilterScope)
  const sort = yield select(filterOptionsSelectors.getSort)
  const certificationExpiredExcluded = yield select(
    filterOptionsSelectors.getByKey,
    'certificationExpiredExcluded'
  )
  const attachmentsExpiredExcluded = yield select(
    filterOptionsSelectors.getByKey,
    'attachmentsExpiredExcluded'
  )
  const similarSuppliers = yield select(
    filterOptionsSelectors.getByKey,
    'similarSuppliers'
  )
  const vetId = action.meta.vetId
  const refKey = action.meta.refKey
  const queryString = action.meta.queryString
  const locationQuery = action.meta.locationQuery
  const currentUserId = yield select(sessionSelectors.getUserId)
  const pathname = yield select(routingSelectors.getPathname)
  const isSupplierApp: boolean = pathname.startsWith('/supplier')

  const response = yield call(
    suppliers.searchSuppliers,
    {
      q,
      agg: !!aggregateQuery,
      filter: filter.toJS(),
      scope,
      sort: locationQuery === undefined ? sort : 'None',
      limit: !aggregateQuery ? 100 : 0,
      locationQuery,
      certificationExpiredExcluded: certificationExpiredExcluded
        ? certificationExpiredExcluded.toJS()
        : undefined,
      attachmentsExpiredExcluded,
      similarSuppliers: similarSuppliers ? similarSuppliers.toJS() : undefined,
      supplierSearch: isSupplierApp ? 1 : undefined
    },
    vetId,
    currentUserId
  )
  let cardsById = {}
  let cardsIdsBySupplierByUser = {}
  let orgUnitsById = {}
  response.hits.forEach(hit => {
    cardsById = Object.assign({}, cardsById, mapValues(hit.cards, parseCard))
    cardsIdsBySupplierByUser = Object.assign({}, cardsIdsBySupplierByUser, {
      [hit.supplier.id]: mapValues(
        mapKeys(hit.cards, card => card.parents.User),
        card => card.id
      )
    })
    orgUnitsById[hit.supplier.id] = parseSupplier(hit.supplier)
  })

  // wait for the non aggregated query to finish before returning
  // the aggregated one
  if (nonAggregatedQueryTask) {
    yield join(nonAggregatedQueryTask)
  }

  return yield {
    results: response.hits,
    resultsCount: response.total.value,
    aggregations: response.aggregations,
    communities: response.communities,
    cardsById,
    cardsIdsBySupplierByUser,
    queryString,
    vetId,
    refKey,
    searchId: response.searchId,
    orgUnitsById,
    aggregateQuery,
    isSupplierApp
  }
}

const searchSaga: any = createApiRequestSaga(searchSupplierSaga as any)

export default function* searchSupplierSagaWrapper(
  action: ReturnType<typeof searchSupplier>
) {
  /**
   * Since doing an initial query with aggregation takes over 7 seconds,
   * we want to do two separate queries in parallel, one aggregated
   * and one not aggregated, this will allow us to display some initial
   * data before the aggregated one comes back
   */
  const nonAggQuery = yield fork(searchSaga, action, false)
  if (!!action.meta.locationQuery) {
    return nonAggQuery
  } else {
    return yield fork(searchSaga, action, true, nonAggQuery)
  }
}
