import React, {
  Fragment,
  ChangeEvent,
  ReactNode,
  useState,
  useEffect,
  MouseEvent
} from 'react'
import { connect } from 'react-redux'
import { List, RecordOf, Map } from 'immutable'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import insightsSelectors from '../../store/insightsSelectors'
import Text from 'shared/components/Text'
import iconSelectAll from 'shared/assets/icons/select-all.svg'
import iconClearAll from 'shared/assets/icons/clear-all.svg'
import iconSetting from 'shared/assets/icons/setting.svg'
import numberFormat from 'shared/utils/numberFormat'
import Menu from '@material-ui/core/Menu'
import MenuItem from 'shared/components/MenuItem'
import storageManager from 'shared/utils/storageManager'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import RootState from 'shared/models/RootState'
import sortSpendData from '../SpendPage/sortSpendData'
import { Overview } from '../SpendPage/SpendPage'
import CountryFlag from 'shared/components/CountryFlag'
import startCase from 'lodash.startcase'
import PlaceholderLine from 'shared/components/PlaceholderLoading'
import IconButton from 'shared/components/IconButton'
import { makeStyles } from '@material-ui/core/styles'

const messages = defineMessages({
  tableSettings: {
    id: 'SpendOverviewTable.tableSettings',
    defaultMessage: 'Change the second column of {tableName} Table'
  },
  tableSelectAll: {
    id: 'SpendOverviewTable.tableSelectAll',
    defaultMessage: 'Select all rows in {tableName} Table'
  },
  tableClearAll: {
    id: 'SpendOverviewTable.tableClearAll',
    defaultMessage: 'Unselect all rows for {tableName} Table'
  },
  spendGroup: {
    id: 'SpendOverviewTable.spendGroup',
    defaultMessage: 'Spend Group'
  },
  spendCountries: {
    id: 'SpendOverviewTable.spendCountries',
    defaultMessage: 'Spend Countries'
  },
  spendCategories: {
    id: 'SpendOverviewTable.spendCategories',
    defaultMessage: 'Spend Categories'
  },
  notApplicable: {
    id: 'SpendOverviewTable.notApplicable',
    defaultMessage: 'Not applicable'
  }
})

const customLabels = {
  percentage: '%',
  diversePercentage: '% diverse',
  diverseAmount: 'Diverse',
  preferredPercentage: '% preferred',
  preferredAmount: 'Preferred'
}

const useStyles = makeStyles({
  icon: {
    transition: 'all .15s ease-in',
    filter:
      'brightness(0) saturate(100%) invert(32%) sepia(0%) saturate(0%) hue-rotate(261deg) brightness(95%) contrast(83%)', //#555555 for svg
    '&:hover': {
      filter: 'brightness(0) saturate(100%)' //#000000 for svg
    }
  }
})

type SortDirection = 'ASC' | 'DESC'
type SortBy = keyof Overview

type Props = {
  type: 'country' | 'category' | 'spendgroup'
  colors?: Array<string>
  values: List<string>
  changeIndex: (index?: number) => void
  changeValues: (values: string | string[]) => void
  tableData: List<RecordOf<Overview>>
  additionalActions?: ReactNode
  title?: ReactNode
  total: number
  isLoadingSpend?: boolean
} & ContainerProps

export const SpendOverviewTable = (props: Props) => {
  const intl = useIntl()
  const classes = useStyles()
  const storageKey =
    props.type === 'country'
      ? 'countrySpendCustomColumn'
      : props.type === 'category'
      ? 'categorySpendCustomColumn'
      : 'spendgroupSpendCustomColumn'

  const [customColumn, setCustomColumn] = useState<string>(() => {
    const storedColumn = storageManager.getItem(storageKey)
    return !storedColumn || storedColumn?.startsWith('diverse')
      ? 'percentage'
      : storedColumn
  })
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<any>(null)
  const [settingMenu, setSettingMenu] = useState<boolean>(false)
  const [sortBy, setSortBy] = useState<keyof Overview>('totalAmount')
  const [sortDirection, setSortDirection] = useState<
    'ASC' | 'DESC' | undefined
  >('DESC')
  const [sortedData, setSortedData] = useState<List<RecordOf<Overview>>>(
    props.tableData || List([])
  )

  useEffect(() => {
    if (props.tableData && props.tableData.size > 0) {
      setSortedData(sortSpendData(props.tableData, sortBy, sortDirection))
    }
  }, [sortBy, sortDirection, props.tableData])

  const toggleMenuOpen = () => {
    setSettingMenu(!settingMenu)
  }

  const handleSettingClick = (e: MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(e.currentTarget)
    setSettingMenu(!settingMenu)
  }

  const handleCustomColumn = value => {
    setSettingMenu(!settingMenu)
    setCustomColumn(value)
    storageManager.setItem(storageKey, value)
  }

  const getRow = ({ index }) => {
    return sortedData.size > 0 ? sortedData.get(index) : Map({})
  }

  const handleSortChange = ({
    sortBy,
    sortDirection
  }: {
    sortBy: SortBy
    sortDirection: SortDirection
  }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  const handleRowClick = ({ rowData }: { rowData: RecordOf<any> }) => {
    props.changeValues(rowData.get(props.type))
  }

  const handleSelectAll = () => {
    const values = sortedData.map(row => row.get(props.type)).toArray()
    props.changeValues(values)
  }

  const handleClearAll = () => {
    props.changeValues([])
  }

  const renderCategoryCell = ({ cellData }: TableCellProps) => {
    const { isLoadingSpend } = props
    return isLoadingSpend ? (
      <PlaceholderLine />
    ) : (
      cellData || (
        <FormattedMessage id='SpendOverviewTable.NA' defaultMessage='N/A' />
      )
    )
  }

  const renderCountryCell = ({ rowData }: TableCellProps) => {
    const { isLoadingSpend } = props
    return isLoadingSpend ? (
      <PlaceholderLine />
    ) : (
      <div className='flex items-center'>
        <CountryFlag
          toolTipTitle={rowData.get('country')}
          countryAbbrev={rowData.get('countryAbbrev')}
        />
        <span className='ml2'>
          {startCase(rowData.get('country')) || (
            <FormattedMessage
              id='SpendOverviewTable.Unknown'
              defaultMessage='Unknown'
            />
          )}
        </span>
      </div>
    )
  }

  const renderBusinessUnitCell = ({ cellData }: TableCellProps) => {
    const { isLoadingSpend } = props
    return isLoadingSpend ? (
      <PlaceholderLine />
    ) : (
      cellData || <FormattedMessage id='SpendOverviewTable.NA' />
    )
  }

  const renderSpendCell = ({ cellData }: TableCellProps) => {
    const spend = typeof cellData === 'number' ? cellData : 0
    const { isLoadingSpend } = props
    return isLoadingSpend ? <PlaceholderLine /> : numberFormat(spend)
  }

  const renderColorCell = ({ cellData }: TableCellProps) => {
    const { colors } = props

    return (
      <span
        className='dib br1 v-mid'
        style={{
          background:
            cellData !== undefined
              ? colors && colors[cellData % colors.length]
              : '',
          width: 6,
          height: 24
        }}
      />
    )
  }

  const renderCheckboxCell = ({ rowData }: { rowData: RecordOf<Overview> }) => {
    const value = rowData.get(props.type) || 'null'
    const index = rowData.get('index')
    const { values } = props

    return (
      <input
        type='checkbox'
        data-index={index}
        value={value}
        checked={!!value && values && values.includes(value)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => e.preventDefault()}
        aria-label={
          value === 'null' ? intl.formatMessage(messages.notApplicable) : value
        }
      />
    )
  }

  const {
    changeIndex,
    total,
    type,
    additionalActions,
    colors,
    isLoadingSpend,
    title
  } = props

  let tableName: string
  if (type === 'spendgroup') {
    tableName = intl.formatMessage(messages.spendGroup)
  } else if (type === 'country') {
    tableName = intl.formatMessage(messages.spendCountries)
  } else {
    tableName = intl.formatMessage(messages.spendCategories)
  }

  return sortedData && sortedData.size > 0 ? (
    <Fragment>
      <div className='mb2 flex justify-between items-center'>
        {title}
        <div className='tr'>
          {additionalActions}
          <IconButton
            onClick={handleSelectAll}
            aria-label={intl.formatMessage(messages.tableSelectAll, {
              tableName: tableName
            })}
            wrapperStyle={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
          >
            <img
              src={iconSelectAll}
              alt={intl.formatMessage(messages.tableSelectAll, {
                tableName: tableName
              })}
              className={'w1 v-mid dib pointer ' + classes.icon}
            />
          </IconButton>
          <IconButton
            onClick={handleClearAll}
            aria-label={intl.formatMessage(messages.tableClearAll, {
              tableName: tableName
            })}
            wrapperStyle={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
          >
            <img
              src={iconClearAll}
              alt={intl.formatMessage(messages.tableClearAll, {
                tableName: tableName
              })}
              className={'w1 v-mid dib pointer ' + classes.icon}
            />
          </IconButton>
          <IconButton
            onClick={handleSettingClick}
            aria-label={intl.formatMessage(messages.tableSettings, {
              tableName: tableName
            })}
            wrapperStyle={{ marginLeft: '0.5rem' }}
            aria-expanded={settingMenu}
          >
            <img
              src={iconSetting}
              alt={intl.formatMessage(messages.tableSettings, {
                tableName: tableName
              })}
              className={'w1 v-mid dib pointer ' + classes.icon}
            />
          </IconButton>
        </div>
      </div>
      <div className='bg-white br1 ba b--black-10 overflow-hidden'>
        <Table
          height={364}
          rowGetter={getRow}
          rowCount={sortedData.size}
          sort={handleSortChange}
          sortDirection={sortDirection}
          sortBy={sortBy}
          onRowMouseOver={({ rowData }) => {
            changeIndex(rowData.get('index'))
          }}
          onRowMouseOut={() => changeIndex(undefined)}
          onRowClick={handleRowClick}
        >
          <Column
            width={34}
            dataKey={'index'}
            disableSort
            cellRenderer={renderCheckboxCell}
          />
          {colors && (
            <Column
              width={20}
              dataKey={'index'}
              disableSort
              cellRenderer={renderColorCell}
            />
          )}
          <Column
            label={
              type === 'category' ? (
                <FormattedMessage
                  id='SpendOverviewTable.Category'
                  defaultMessage='Category'
                />
              ) : type === 'country' ? (
                <FormattedMessage
                  id='SpendOverviewTable.Country'
                  defaultMessage='Country'
                />
              ) : (
                <FormattedMessage
                  id='SpendOverviewTable.BusinessUnit'
                  defaultMessage='Business Unit'
                />
              )
            }
            dataKey={type.toLowerCase()}
            cellRenderer={
              type === 'category'
                ? renderCategoryCell
                : type === 'country'
                ? renderCountryCell
                : renderBusinessUnitCell
            }
            width={300}
          />
          <Column
            label={customLabels[customColumn]}
            dataKey={customColumn}
            width={100}
            cellRenderer={renderSpendCell}
            className={customLabels[customColumn].includes('%') ? 'tl' : 'tr'}
            headerClassName={
              customLabels[customColumn].includes('%') ? 'tl' : 'tr'
            }
          />
          <Column
            label={
              <FormattedMessage
                id='SpendOverviewTable.Spend'
                defaultMessage='Spend'
              />
            }
            dataKey={'totalAmount'}
            width={200}
            cellRenderer={renderSpendCell}
            className='tr'
            headerClassName='tr'
          />
        </Table>

        <div className='bt b--black-10 f6 fw6 mid-gray ttc pa2 pv3 bg-near-white flex justify-end'>
          {isLoadingSpend ? (
            <PlaceholderLine width='150px' />
          ) : (
            numberFormat(total)
          )}
        </div>
      </div>
      <Menu
        anchorEl={popoverAnchorEl}
        open={settingMenu}
        onClose={toggleMenuOpen}
        id='settings-menu'
      >
        <MenuItem
          selected={customColumn === 'percentage'}
          onClick={() => handleCustomColumn('percentage')}
          aria-current={customColumn === 'percentage'}
        >
          <FormattedMessage
            id='SpendOverviewTable.PercentageTotal'
            defaultMessage='% total'
          />
        </MenuItem>
        {/* <MenuItem
          selected={customColumn === 'diversePercentage'}
          onClick={() => handleCustomColumn('diversePercentage')}
        >
          <FormattedMessage
            id='SpendOverviewTable.PercentageDiverse'
            defaultMessage='% diverse'
          />
        </MenuItem>
        <MenuItem
          selected={customColumn === 'diverseAmount'}
          onClick={() => handleCustomColumn('diverseAmount')}
        >
          <FormattedMessage
            id='SpendOverviewTable.AmountDiverse'
            defaultMessage='Amount diverse'
          />
        </MenuItem> */}
        <MenuItem
          selected={customColumn === 'preferredPercentage'}
          onClick={() => handleCustomColumn('preferredPercentage')}
          aria-current={customColumn === 'preferredPercentage'}
        >
          <FormattedMessage
            id='SpendOverviewTable.PercentagePreferred'
            defaultMessage='% preferred'
          />
        </MenuItem>
        <MenuItem
          selected={customColumn === 'preferredAmount'}
          onClick={() => handleCustomColumn('preferredAmount')}
          aria-current={customColumn === 'preferredAmount'}
        >
          <FormattedMessage
            id='SpendOverviewTable.AmountPreferred'
            defaultMessage='Amount preferred'
          />
        </MenuItem>
      </Menu>
    </Fragment>
  ) : (
    <Text>
      {type === 'category' ? (
        <FormattedMessage
          id='SpendOverviewTable.SupplierCategories'
          defaultMessage='Supplier Categories'
        />
      ) : type === 'country' ? (
        <FormattedMessage
          id='SpendOverviewTable.SpendCountries'
          defaultMessage='Spend Countries'
        />
      ) : (
        <FormattedMessage
          id='SpendOverviewTable.BusinessUnits'
          defaultMessage='Business Units'
        />
      )}
      :{' '}
      <FormattedMessage
        id='SpendOverviewTable.NoData'
        defaultMessage='There was no data found.'
      />
    </Text>
  )
}

type ContainerProps = {
  type: 'country' | 'category' | 'spendgroup'
}

export default connect((state: RootState, props: ContainerProps) => {
  return {
    tableData: insightsSelectors.getSpendTableData(state, props.type),
    total: insightsSelectors.getSpendTotal(state, props.type),
    isLoadingSpend: insightsSelectors.isLoadingSpend(state)
  }
})(SpendOverviewTable)
