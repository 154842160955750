import { call, put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import community from 'shared/utils/api/community'
import tier2 from 'shared/utils/api/tier2'
import communitiesPaths from '../../routes/paths'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import parsePath from 'shared/utils/parsePath'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import { getTier2Communities } from '../../../Insight/store/actions'

export function* acceptCommunitySaga(action) {
  const { commId, isTier2 } = action.payload
  const communityPlanMembership = isTier2
    ? yield call(tier2.acceptTier2Invite, commId)
    : yield call(community.acceptCommunity, commId)
  const communityBeingAccepted = yield select(
    communitiesSelectors.getById,
    commId
  )
  const supplierSearchable = communityBeingAccepted.has('supplierSearchable')

  if (communityBeingAccepted.has('form')) {
    yield put(
      push(
        parsePath(communitiesPaths.beginCommunityForm, {
          communityId: commId
        })
      )
    )
  }

  yield put(getTier2Communities())
  return yield {
    communityPlanMembership,
    commId,
    supplierSearchable
  }
}

export default createApiRequestSaga(acceptCommunitySaga)
