import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'
import Immutable from 'immutable'

export function* updateInternalCommentSaga(action) {
  const {
    index,
    vetBuyerSupplierId,
    comment,
    ...noteObj
  } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  noteObj.note = comment
  yield call(vets.updateBuyerSupplierNotes, { vetBuyerSupplierId, ...noteObj })
  return yield { index, vetBuyerSupplierId, note: noteObj }
}

export default createApiRequestSaga(updateInternalCommentSaga)
