import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { toggleFilterOption } from '../../actions'
import usersSelectors from 'shared/selectors/usersSelectors'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import Scrollable from 'shared/components/Scrollable'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf } from 'immutable'
import RootState from '../../../../shared/models/RootState'
import User from '../../../../shared/models/User'

const PopoverWithStyles = withStyles({
  paper: {
    padding: '1rem',
    minWidth: 200
  }
})(Popover)

type Props = {
  toggleFilterOption: (arg: { key: string; value: string }) => void
  results: List<RecordOf<User>>
  onChange: ({ key: string, value: any }) => void
}

export const ColleagueSearchContainer = (props: Props) => {
  const [colleagueText, setColleagueText] = useState('')
  const [showResults, setShowResults] = useState(false)
  const [anchorEl, setAnchorEl] = useState(undefined)
  const [searchedColleagues, setSearchedColleagues] = useState(props.results)

  const handleColleagueChange = e => {
    setColleagueText(e.target.value)
    setAnchorEl(e.target)
  }

  const handleKeyPress = e => {
    if (e.charCode === 13) {
      handleSearchClick()
    }
  }

  const handleSearchClick = (e?) => {
    const { results } = props

    if (colleagueText) {
      setColleagueText('')
      setShowResults(true)
      setSearchedColleagues(
        results.filter(obj => {
          const firstName = obj.get('firstName')?.toLowerCase() || ''
          const lastName = obj.get('lastName')?.toLowerCase() || ''
          return (
            (firstName + ' ' + lastName).indexOf(
              colleagueText.toLowerCase()
            ) !== -1
          )
        })
      )
    }
  }

  const handleColleagueClick = (key, value) => {
    const { toggleFilterOption, onChange } = props
    handleRequestClose()
    onChange({ key: key, value: value })
    toggleFilterOption({
      key,
      value
    })
  }

  const handleRequestClose = (e?) => {
    setShowResults(false)
  }

  const renderColleague = colleague => {
    const key = 'connectedUserId'
    const id = colleague.get('id')
    const name = colleague.get('firstName') + ' ' + colleague.get('lastName')
    return (
      <div key={name} className='pv1 cf'>
        <input
          type='checkbox'
          id={id}
          onChange={() => handleColleagueClick(key, id)}
          className='b--black-10 fl'
          value={id}
        />
        <label htmlFor={id} className='f7 fw3 mid-gray pointer db ml3 pl1'>
          {name}
        </label>
      </div>
    )
  }

  return (
    <Fragment>
      <Label className='db gray mb1 f7' htmlFor='colleagueSearchInput'>
        <FormattedMessage
          id='ColleagueSearchContainer.SearchForAColleague'
          defaultMessage='Search for a colleague'
        />
      </Label>
      <div className='dib w-70'>
        <FormattedMessage
          id='ColleagueSearchContainer.EnterColleagueName'
          defaultMessage='Enter a colleague&#39;s name'
        >
          {message => (
            <Input
              placeholder={message as string}
              onChange={handleColleagueChange}
              onKeyPress={handleKeyPress}
              value={colleagueText}
              name='colleagueSearchInput'
            />
          )}
        </FormattedMessage>
      </div>
      <div className='dib w-30'>
        <div className='pl2'>
          <Button
            label={
              <FormattedMessage
                id='ColleagueSearchContainer.Search'
                defaultMessage='Search'
              />
            }
            onClick={handleSearchClick}
            aria-label='Search colleague'
          />
        </div>
      </div>
      <PopoverWithStyles
        open={showResults}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={handleRequestClose}
      >
        {searchedColleagues.size > 0 ? (
          <Scrollable maxHeight={200}>
            {searchedColleagues.map(colleague => renderColleague(colleague))}
          </Scrollable>
        ) : (
          <Text>
            <FormattedMessage
              id='ColleagueSearchContainer.NoMatch'
              defaultMessage='No match found'
            />
          </Text>
        )}
      </PopoverWithStyles>
    </Fragment>
  )
}

export default connect(
  (state: RootState) => ({
    results: usersSelectors.getColleagues(state) || List([])
  }),
  {
    toggleFilterOption
  }
)(ColleagueSearchContainer)
