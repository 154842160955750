import React, { Component, Fragment } from 'react'
import CompanyProfileHeader from '../../../Company/components/CompanyProfileHeader'
import CommunityCard from 'shared/components/CommunityCard'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import Loading from 'shared/components/Loading'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import mapKeys from 'lodash.mapkeys'
import ContactCard from 'shared/components/ContactCard'
import { List, Map, fromJS, RecordOf } from 'immutable'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import TextArea from 'shared/components/TextArea'
import Button from 'shared/components/Button'
import CustomizePitchDeckContainer from '../../containers/CustomizePitchDeckContainer'
import VetsByBuyerContainer from '../../containers/VetsByBuyerContainer'
import DropDownMenu from 'shared/components/DropDownMenu'
import PageSideSection from 'shared/components/PageSideSection'
import BuyerConnections from '../BuyerConnections'
import Switch from 'shared/components/Switch'
import MenuItem from 'shared/components/MenuItem'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { FormattedMessage } from 'react-intl'
import GetVerifiedDialog from '../GetVerifiedDialog'
import parsePath from 'shared/utils/parsePath'
import communityPaths from '../../../Communities/routes/paths'
import supplierPaths from '../../../routes/paths'
import ScrollToTopOnMount from 'shared/components/ScrollToTopOnMount'
import TealbookSquareLogo from 'shared/assets/images/tealbook-logo.png'
import { RouteComponentProps } from 'react-router-dom'
import UploadedFile from 'shared/models/UploadedFile'
import Scrollable from 'shared/components/Scrollable'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'

export const targetResultParser = data =>
  mapKeys(data.publicTargets, ({ id }) => id)

type Props = {
  loadingOrgUnit: boolean
  isPremium: boolean
  clientId: string
  contacts: List<
    RecordOf<{
      id: string
      profilePicture: UploadedFile
      firstName: string
      lastName: string
      email: string
      title: string
      location: any
      phones: any
      communities: List<string>
    }>
  >
  supplierId: string
  supplierRelationships: List<any>
  supplierRelationshipId: string
  following: boolean
  numberOfConnections: number
  connections: Map<
    string,
    RecordOf<{
      id: string
      avatarUrl: string
      fullName: string
      title: string
      connectedTo: any
    }>
  >
  isClient: boolean
  requestVerify: boolean
  verifyClient: string
  communities: Map<
    string,
    RecordOf<{
      id: string
      name: string
      description: string
      isMember: boolean
      logo: UploadedFile
    }>
  >
  userOrgUnitId: string
  name: string
  domains: List<string>
  websiteUrl: string
  logoUrl: string
  address: string
  description: string
  publicView: boolean
  hasVets: boolean
  verifyingOrgUnitId: string
  loadVets: () => void
  loadData: (options: any) => void
  messageUser: (
    params: RecordOf<{
      message: string
      userToMessageId: string
      orgUnitId: string
    }>
  ) => void
  followClient: (params: {
    supplierRelationshipId: string
    supplierRelationship: { supplierCollaboration: { following: boolean } }
    supplierId: string
    clientId: string
  }) => void
  addCustomer: (params: { orgUnitId: string }) => void
  removeCustomer: (params: { supplierRelationshipId: string }) => void
  openGetVerifiedDialog: (orgUnitId: string) => void
  closeGetVerifiedDialog: () => void
  loadTier2AllocatedSpend: () => void
  allocatedSpend: [{ year: number; quarter: number; allocation: number }]
} & RouteComponentProps<{ orgUnitId: string }>

type State = {
  userToMessageId: string
  message: string
}

export class BuyerProfile extends Component<Props, State> {
  state = {
    userToMessageId: '',
    message: ''
  }

  componentDidMount() {
    const {
      hasVets,
      loadData,
      loadVets,
      supplierRelationships,
      loadTier2AllocatedSpend
    } = this.props

    loadData({
      entity: 'orgUnits',
      query: `supplier/target?buyerOrgUnitId=${this.props.match.params.orgUnitId}`,
      resultParser: targetResultParser
    })

    if (!supplierRelationships || supplierRelationships.size < 1) {
      loadData({
        entity: 'supplierRelationships'
      })
    }

    if (!hasVets) {
      loadVets()
    }
    loadTier2AllocatedSpend()
  }

  handleClickMessage = userToMessageId => {
    this.setState({
      userToMessageId
    })
  }

  handleClickFollow = following => {
    const {
      followClient,
      clientId,
      supplierRelationshipId,
      supplierId
    } = this.props
    followClient({
      supplierRelationshipId,
      supplierRelationship: { supplierCollaboration: { following } },
      supplierId,
      clientId
    })
  }

  handleCloseMessageDialog = () => {
    this.setState({
      userToMessageId: '',
      message: ''
    })
  }

  handleMessageChange = e => {
    this.setState({
      message: e.currentTarget.value
    })
  }

  handleSendMessage = e => {
    e.preventDefault()

    const { message, userToMessageId } = this.state

    const {
      messageUser,
      match: {
        params: { orgUnitId }
      }
    } = this.props

    messageUser(
      fromJS({
        message,
        userToMessageId,
        orgUnitId
      })
    )

    this.handleCloseMessageDialog()
  }

  handleToggleCustomer = () => {
    const {
      match: {
        params: { orgUnitId }
      },
      isClient,
      addCustomer,
      removeCustomer,
      supplierRelationshipId
    } = this.props
    if (!isClient) {
      addCustomer({
        orgUnitId
      })
    } else {
      removeCustomer({
        supplierRelationshipId
      })
    }
  }

  handleGetVerifiedClick = () => {
    const {
      match: {
        params: { orgUnitId }
      },
      openGetVerifiedDialog
    } = this.props

    openGetVerifiedDialog(orgUnitId)
  }

  render() {
    const {
      loadingOrgUnit,
      domains,
      communities,
      contacts,
      isPremium,
      clientId,
      hasVets,
      numberOfConnections,
      connections,
      following,
      isClient,
      requestVerify,
      verifyClient,
      closeGetVerifiedDialog,
      verifyingOrgUnitId,
      allocatedSpend,
      ...rest
    } = this.props
    const { userToMessageId, message } = this.state
    return (
      <Fragment>
        <ScrollToTopOnMount />
        {loadingOrgUnit ? (
          <Loading />
        ) : (
          <CompanyProfileHeader
            domains={domains && domains.toJS()}
            {...rest}
            rightContent={
              <div className='mt2 tr w4-5'>
                <FormattedMessage
                  id='BuyerProfile.ourCustomer'
                  defaultMessage='Our Customer'
                >
                  {label => (
                    <Switch
                      checked={isClient}
                      onChange={this.handleToggleCustomer}
                      label={label}
                    />
                  )}
                </FormattedMessage>
                {isClient && (
                  <div className='mt2 tl'>
                    {verifyClient === 'agree' ? (
                      <div className='f8 mid-gray flex items-center'>
                        <CheckCircleIcon className='teal' />
                        <div className='flex-auto ml1'>
                          <FormattedMessage
                            id='BuyerProfile.Confirmed'
                            defaultMessage='Customer confirmed.'
                          />
                        </div>
                      </div>
                    ) : verifyClient === 'disagree' ? (
                      <div className='f8 red flex items-center'>
                        <ErrorIcon />
                        <div className='flex-auto ml1'>
                          <FormattedMessage
                            id='BuyerProfile.NotConfirmed'
                            defaultMessage='Relationship could not be confirmed.'
                          />
                        </div>
                      </div>
                    ) : requestVerify ? (
                      <div className='f7 gray tr'>
                        <FormattedMessage
                          id='BuyerProfile.Pending'
                          defaultMessage='Pending'
                        />
                      </div>
                    ) : (
                      <div className='tr'>
                        <button
                          className='fw4 no-underline dim dib b--transparent pointer teal f6 pa0'
                          onClick={this.handleGetVerifiedClick}
                        >
                          <FormattedMessage
                            id='BuyerProfile.GetVerified'
                            defaultMessage='Get Verified'
                          />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            }
          >
            <div className='dtc v-top tr'>
              {!following ? (
                <h5
                  className='f7 fw4 dim mid-gray bg-light-gray br2 ba pointer dib b--none pv2 ph2-5 ma0'
                  onClick={() => this.handleClickFollow(true)}
                >
                  <FormattedMessage
                    id='BuyerProfile.FollowLabel'
                    defaultMessage='Follow'
                  />{' '}
                </h5>
              ) : (
                <Fragment>
                  <h5 className='f7 fw4 teal br2 ba dib b--none ma0 mr2 pv2'>
                    <FormattedMessage
                      id='BuyerProfile.FollowingLabel'
                      defaultMessage='Following'
                    />
                  </h5>
                  <DropDownMenu>
                    <MenuItem onClick={() => this.handleClickFollow(false)}>
                      <FormattedMessage
                        id='BuyerProfile.UnfollowLabel'
                        defaultMessage='Unfollow'
                      />
                    </MenuItem>
                  </DropDownMenu>
                </Fragment>
              )}
            </div>
          </CompanyProfileHeader>
        )}

        <Page title='Client Profile'>
          <div className='db cf'>
            <Main>
              {isPremium && clientId && (
                <CustomizePitchDeckContainer clientId={clientId} />
              )}

              {contacts && contacts.size > 0 && (
                <PageSection
                  title={
                    <FormattedMessage
                      id='BuyerProfile.ContactsTitle'
                      defaultMessage='Contacts'
                    />
                  }
                >
                  <div className='pt3-5'>
                    <Scrollable maxHeight={500}>
                      {contacts.map(contact => (
                        <ContactCard
                          key={contact.get('id')}
                          onClickMessage={this.handleClickMessage}
                          userId={contact.get('id')}
                          baseUrl={supplierPaths.basePath}
                          contact={{
                            profilePictureUrl: fileToUrl(
                              contact.get('profilePicture')
                            ),
                            firstName: contact.get('firstName'),
                            lastName: contact.get('lastName'),
                            title: contact.get('title'),
                            email: contact.get('email'),
                            location: contact.getIn(['location', 'address']),
                            phone: contact.getIn(['phones', 'office']),
                            contactFor:
                              contact.get('communities') &&
                              contact.get('communities').size > 0
                                ? `Contact for ${contact
                                    .get('communities')
                                    .join(', ')} community requests`
                                : ''
                          }}
                        />
                      ))}
                    </Scrollable>
                  </div>
                </PageSection>
              )}

              <div className='pt4'>
                <h2 className='f5-l f6 fw6 pl3-5 pb3 dt ma0 w-100'>
                  <FormattedMessage
                    id='BuyerProfile.CommunitiesTitle'
                    defaultMessage='Communities'
                  />
                </h2>
                <div className='na2'>
                  <CommunityCard
                    name='Tealbook'
                    linkTo={supplierPaths.target}
                    logoUrl={TealbookSquareLogo}
                    isMember
                    description={
                      <FormattedMessage
                        id='BuyerProfile.TealbookDescription'
                        defaultMessage='Every buyer and supplier in TealBook is automatically made a member of this community.'
                      />
                    }
                    nsWidth='w-50-ns'
                  />
                  {communities &&
                    communities.entrySeq().map(([id, community]) => (
                      <CommunityCard
                        key={id}
                        name={community.get('name')}
                        description={community.get('description')}
                        logoUrl={fileToUrl(community.get('logo'))}
                        isMember={community.get('isMember')}
                        linkTo={parsePath(communityPaths.community, {
                          communityId: id
                        })}
                        nsWidth='w-50-ns'
                      />
                    ))}
                </div>
              </div>
            </Main>
            <Aside>
              {allocatedSpend && !!allocatedSpend.length && (
                <PageSideSection title='AllocatedSpend' hideTitle>
                  <Label>
                    <FormattedMessage
                      id='BuyerProfile.AllocatedSpend'
                      defaultMessage='Shared Tier 2 Spend'
                    />
                  </Label>
                  {allocatedSpend.map(item => (
                    <Text className='mt2' secondary>
                      {item.year} &ndash; Q{item.quarter}:&nbsp;
                      {item.allocation}&#37;
                    </Text>
                  ))}
                </PageSideSection>
              )}
              {hasVets && (
                <VetsByBuyerContainer
                  orgUnit={this.props.match.params.orgUnitId}
                />
              )}
              {!!numberOfConnections && (
                <PageSideSection title='Connections' hideTitle>
                  <BuyerConnections
                    numberOfConnections={numberOfConnections}
                    connections={connections}
                    isPremium
                  />
                </PageSideSection>
              )}
            </Aside>
          </div>
        </Page>

        <GetVerifiedDialog
          open={!!verifyingOrgUnitId}
          onClose={closeGetVerifiedDialog}
        />

        <Dialog
          onClose={this.handleCloseMessageDialog}
          open={!!userToMessageId}
          fullWidth
          disableBackdropClick
        >
          <form onSubmit={this.handleSendMessage}>
            <DialogTitle>
              <FormattedMessage
                id='BuyerProfile.MessageButtonLabel'
                defaultMessage='Message'
              />
            </DialogTitle>
            <DialogContent>
              <div className='ph3 mb3'>
                <UserListItemContainer mini userId={userToMessageId} />
              </div>

              <div className='bg-near-white pa3-5 bb bt b--light-gray'>
                <div className='bg-white pa3 ba b--light-gray'>
                  <TextArea
                    name='message'
                    value={message}
                    onChange={this.handleMessageChange}
                    maxLength={150}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button label='Send' type='submit' className='w-auto' />
              <Button
                label='Cancel'
                onClick={this.handleCloseMessageDialog}
                secondary
                autoSize
              />
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    )
  }
}

export default BuyerProfile

// const contactsSelector = createSelector(
//   (state, orgUnitId) =>
//     dataSelectors.getById(state, 'orgUnits', orgUnitId).get('communities'),
//   (state, orgUnitId) =>
//     dataSelectors.getById(state, 'orgUnits', orgUnitId).get('contacts'),
//   (state, orgUnitId) =>
//     dataSelectors.getById(state, 'orgUnits', orgUnitId).get('users'),
//   (communities, contacts, users) => {
//     let buyerContacts = {}
//     if (communities) {
//       communities.forEach(community => {
//         community.get('contacts') &&
//           community.get('contacts').forEach(contact => {
//             const user = community.getIn(['users', contact.get('user')])
//             if (user) {
//               buyerContacts[user.get('id')] = contact
//                 .merge(user)
//                 .update('communities', communities =>
//                   communities
//                     ? communities.push(community.get('name'))
//                     : fromJS([community.get('name')])
//                 )
//             }
//           })
//       })
//     }

//     if (contacts && contacts.size > 0) {
//       contacts.forEach(c => {
//         const user = users.find(u => u.get('id') === c.get('user'))
//         buyerContacts[user.get('id')] = c.merge(user)
//       })
//     }

//     return List(Object.values(buyerContacts))
//   }
// )

// const supplierRelationshipSelector = createSelector(
//   state => dataSelectors.getByEntity(state, 'supplierRelationships'),
//   (state, clientId) => clientId,
//   (supplierRelationships, clientId) =>
//     supplierRelationships.toList().find(rel => rel.get('buyer') === clientId) ||
//     fromJS({})
// )

// const numberOfConnectionsSelector = defaultMemoize(
//   connectedUsers =>
//     connectedUsers &&
//     connectedUsers.reduce(
//       (total, user) => total + user.get('connectedTo').size,
//       0
//     )
// )

// export default connect(
//   (state, props) => {
//     const orgUnit = dataSelectors.getById(
//       state,
//       'orgUnits',
//       props.match.params.orgUnitId
//     )
//     const supplierRelationships = dataSelectors.getByEntity(
//       state,
//       'supplierRelationships'
//     )
//     const contacts = contactsSelector(state, props.match.params.orgUnitId)
//     const supplierRelationship = supplierRelationshipSelector(
//       state,
//       props.match.params.orgUnitId
//     )
//     const following =
//       supplierRelationship &&
//       supplierRelationship.getIn(['supplierCollaboration', 'following'])
//     const isClient =
//       supplierRelationship &&
//       supplierRelationship.getIn(['supplierCollaboration', 'isClient'])
//     const requestVerify =
//       supplierRelationship &&
//       supplierRelationship.getIn(['supplierCollaboration', 'requestVerify'])
//     const verifyClient =
//       supplierRelationship &&
//       supplierRelationship.getIn(['buyerCollaboration', 'verifyClient'])

//     const domains = orgUnit.get('domains')
//     const allConnections = statsSelectors.getConnections(state) || fromJS({})
//     const connections = allConnections.getIn([
//       props.match.params.orgUnitId,
//       'users'
//     ])

//     return {
//       loadingOrgUnit: !orgUnit.size,
//       isPremium: companySelectors.isPremium(state),
//       clientId: orgUnit.get('id'),
//       contacts,
//       supplierId: companySelectors.getCompanyField(state, 'id'),
//       supplierRelationships,
//       supplierRelationshipId:
//         supplierRelationship && supplierRelationship.get('id'),
//       following,
//       numberOfConnections: numberOfConnectionsSelector(connections),
//       connections,
//       isClient,
//       requestVerify,
//       verifyClient,
//       communities: orgUnit.get('communities'),
//       userOrgUnitId: sessionSelectors.getOrgUnitId(state),
//       name: orgUnit.get('name'),
//       domains,
//       websiteUrl:
//         orgUnit.getIn(['links', 'websiteUrl']) || (domains && domains.first()),
//       logoUrl:
//         fileToUrl(
//           orgUnit.get('logo') ||
//             orgUnit.getIn(['buyer', 'logo']) ||
//             orgUnit.getIn(['supplier', 'logo'])
//         ) ||
//         (domains && domains.size > 0
//           ? `https://logo.clearbit.com/${domains.first()}`
//           : ''),
//       // coverUrl: orgUnit.get('coverUrl'),
//       address: parseLocationToString(
//         orgUnit.getIn(['locations', 0], fromJS({}))
//       ),
//       description: orgUnit.get('buyerDescription'),
//       // linkedInUrl: orgUnit.get('linkedUrl'),
//       // facebookUrl: orgUnit.get('facebookUrl'),
//       // twitterId: orgUnit.get('twitterId'),
//       publicView: true,
//       hasVets: vetBuyerSupplierSelectors.getVets(state).get('items').size > 0,
//       verifyingOrgUnitId: state.getIn([
//         'supplier',
//         'company',
//         'verifyingOrgUnitId'
//       ])
//     }
//   },
//   {
//     loadVets,
//     loadData,
//     messageUser,
//     followClient,
//     addCustomer,
//     removeCustomer,
//     openGetVerifiedDialog,
//     closeGetVerifiedDialog
//   }
// )(BuyerProfile)
