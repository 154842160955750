import React, { Component } from 'react'
import VetHeader from '../../components/VetHeader'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import {
  loadVet,
  closeVet,
  cancelVet,
  deleteVet,
  reopenVet
} from '../../actions'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { UPDATE_VET } from '../../actionTypes'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import qs from 'qs'
import { RouteComponentProps } from 'react-router'
import { RecordOf, List } from 'immutable'
import { defaultMemoize } from 'reselect'

const testIsMobile = window.navigator.userAgent
  .toLowerCase()
  .match('iphone|ipad|android')

const isMobile = !!testIsMobile && testIsMobile.length > 0

type Props = {
  decision: string
  redirectFrom: string
  readonly: boolean
  canClose: boolean
  canReopen: boolean
  canDelete: boolean
  match: {
    params: {
      vetId: string
    }
  }
  createdBy: RecordOf<{
    firstName: string
    lastName: string
  }>
  contactsExportData: List<
    RecordOf<{
      supplierName: string
      exportContacts: List<
        RecordOf<{
          user: string
          email: string
          primary?: boolean
          secondary?: boolean
        }>
      >
    }>
  >
  isTealbot: boolean
  vetExportData: List<
    RecordOf<{
      supplierName: string
      supplierDomain: string
      supplierAddress: string
      contactName: string
      contactEmail: string
      contactMobilePhoneNumber: string
      contactOfficePhoneNumber: string
      collaborationStatus: string
    }>
  >
  vet: RecordOf<{
    id: string
    vetId: string
    name: string
    numberOfRespondedSuppliers: number
    created: RecordOf<{
      date: string
    }>
  }>
  onLoadVet: (vetId: string) => void
  onUpdateVet: () => void
  onCancelVet: () => void
  onReopenVet: () => void
  onCloseVet: () => void
  onDeleteVet: () => void
} & RouteComponentProps

export class VetHeaderContainer extends Component<Props> {
  componentDidMount() {
    const {
      match: {
        params: { vetId }
      }
    } = this.props

    if (vetId && vetId.includes('Vet-')) {
      this.props.onLoadVet(vetId)
    }
  }

  render() {
    const {
      vet,
      createdBy,
      onUpdateVet,
      location,
      vetExportData,
      contactsExportData,
      isTealbot,
      decision,
      readonly,
      canClose,
      onCancelVet,
      onReopenVet,
      canReopen,
      canDelete,
      onCloseVet,
      onDeleteVet,
      redirectFrom
    } = this.props

    return vet.id ? (
      <VetHeader
        id={vet.id}
        vetId={vet.vetId}
        vetName={vet.name}
        hasResponse={!!vet.numberOfRespondedSuppliers}
        vetCreatedBy={
          createdBy &&
          `${createdBy.get('firstName')} ${createdBy.get('lastName')}`
        }
        vetDateCreated={vet.created.get('date')}
        vetExportData={vetExportData}
        contactsExportData={contactsExportData}
        isTealbot={isTealbot}
        decision={decision}
        onUpdateVet={onUpdateVet}
        pathname={location.pathname}
        onCloseVet={onCloseVet}
        onCancelVet={onCancelVet}
        onDeleteVet={onDeleteVet}
        onReopenVet={onReopenVet}
        canReopen={canReopen}
        canClose={canClose}
        canDelete={canDelete}
        readonly={readonly}
        redirectFrom={redirectFrom}
        isMobile={isMobile}
      />
    ) : null
  }
}

const formName = 'vet/title'
const getInitialValues = defaultMemoize(vetName => ({
  name: vetName
}))

export default connectReduxForm(
  (state, props) => {
    const vet = currentVetSelectors.getVet(state)
    const createdBy =
      vet.toSeq().size > 0 &&
      usersSelectors.getById(state, vet.getIn(['created', 'user']))
    const {
      location: { search }
    } = props
    const queryParams = qs.parse(search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })

    return {
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      redirectFrom: queryParams.redirectFrom,
      createdBy,
      vet,
      vetExportData: currentVetSuppliersSelectors.getVetSuppliersExportData(
        state
      ),
      contactsExportData: currentVetSuppliersSelectors.getContactsExportData(
        state
      ),
      initialValues: getInitialValues(vet.get('name')),
      decision: vet.get('decision'),
      readonly: currentVetSelectors.isReadonly(state),
      canDelete: currentVetSelectors.canDelete(state),
      canClose:
        (sessionSelectors.userHasRole(state, 'buyerVetApprover') ||
          (createdBy &&
            createdBy.get('id') === sessionSelectors.getUserId(state))) &&
        (vet.get('decision') === 'In Progress' ||
          vet.get('decision') === 'Sent'),
      canReopen:
        (sessionSelectors.userHasRole(state, 'buyerVetApprover') ||
          (createdBy &&
            createdBy.get('id') === sessionSelectors.getUserId(state))) &&
        vet.get('decision') === 'Closed'
    }
  },
  {
    onUpdateVet: createSubmitFormAction(formName, UPDATE_VET),
    onLoadVet: loadVet,
    onCloseVet: closeVet,
    onCancelVet: cancelVet,
    onDeleteVet: deleteVet,
    onReopenVet: reopenVet
  },
  {
    form: formName
  },
  VetHeaderContainer
)
