import { put, select } from 'redux-saga/effects'
import { updateData } from 'shared/actions'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import sessionSelectors from 'shared/selectors/sessionSelectors'

export function* verifySupplierSaga(action) {
  const { supplierRelationshipId, verifyClient } = action.payload
  const currentUserLoggedInId = yield select(sessionSelectors.getUserId)

  yield put(
    updateData({
      entity: 'supplierRelationships',
      record: {
        id: supplierRelationshipId,
        isBuyer: true,
        buyerCollaboration: {
          verifyClient,
          verifyClientDate: new Date(),
          verifyClientUser: currentUserLoggedInId
        }
      }
    })
  )
  return yield action.payload
}

export default createApiRequestSaga(verifySupplierSaga)
