import React, { ChangeEvent } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Select from 'shared/components/Select'
import Input from 'shared/components/Input'
import { FormattedMessage, useIntl } from 'react-intl'
import certificationCategories from 'shared/models/CertificationCategories'

type SubCategoryRowProps = {
  subCategory: string
  subType?: string
  spend?: number
  onSubCategoryChange: (value: string) => void
  onSubTypeChange: (value: string) => void
  onSpendChange: (value: string) => void
  onRemove: () => void
  isReadOnly?: boolean
}
const SubCategoryRow = (props: SubCategoryRowProps) => {
  const {
    isReadOnly,
    subCategory,
    subType = '',
    spend = '',
    onSubCategoryChange,
    onSubTypeChange,
    onSpendChange,
    onRemove
  } = props
  const intl = useIntl()

  return (
    <div className='flex justify-between items-center pb1 mb1'>
      <div className='flex items-center'>
        {!isReadOnly && (
          <CloseIcon className='dim pointer' onClick={onRemove} />
        )}
        <Select
          disabled={isReadOnly}
          value={subCategory}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            onSubCategoryChange(e.currentTarget.value)
          }
        >
          <FormattedMessage
            id='SubCategoryRow.SubCategoryPlaceholder'
            defaultMessage='Please select a Certification'
          >
            {message => (
              <option value='' disabled hidden>
                {message}
              </option>
            )}
          </FormattedMessage>
          {certificationCategories['diversity'] &&
            Object.entries(
              certificationCategories['diversity'].subCategories
            ).map(([key, subCategory]) => (
              <option key={key} value={key}>
                {intl.formatMessage(subCategory)}
              </option>
            ))}
        </Select>
        {!(isReadOnly && !subType) &&
          certificationCategories.diversity.subTypes &&
          certificationCategories.diversity.subTypes[subCategory] && (
            <span className='ml2'>
              <Select
                disabled={isReadOnly}
                value={subType}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  onSubTypeChange(e.currentTarget.value)
                }
              >
                <FormattedMessage
                  id='SubCategoryRow.SelectSubtypePlaceholderOptional'
                  defaultMessage='Please select a Subtype if applicable'
                >
                  {message => (
                    <option value='' disabled hidden>
                      {message}
                    </option>
                  )}
                </FormattedMessage>
                {Object.entries(
                  certificationCategories.diversity.subTypes[subCategory]
                )
                  .filter(subType => !subType.includes('other'))
                  .map(([key, subType]) => (
                    <option key={key} value={key}>
                      {intl.formatMessage(subType)}
                    </option>
                  ))}
              </Select>
            </span>
          )}
      </div>
      <div className='w4'>
        <FormattedMessage
          id='SubCategoryRow.SpendInUSD'
          defaultMessage='Spend in USD'
        >
          {placeholder => (
            <Input
              disabled={isReadOnly}
              type='number'
              value={spend}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onSpendChange(e.currentTarget.value)
              }
              placeholder={placeholder}
            />
          )}
        </FormattedMessage>
      </div>
    </div>
  )
}

export default SubCategoryRow
