import React, { FC } from 'react'
import CompletenessContainer from '../../../shared/containers/CompletenessContainer'
import WidgetWrapper from '../../components/WidgetWrapper'
import { FormattedMessage } from 'react-intl'

export const ProfileCompleteness: FC = () => {
  return (
    <WidgetWrapper
      title={
        <FormattedMessage
          id='ProfileCompleteness.ProfileCompletenessTitle'
          defaultMessage='Profile Completeness'
        />
      }
    >
      <CompletenessContainer />
    </WidgetWrapper>
  )
}

export default ProfileCompleteness
