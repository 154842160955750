import React, { useState, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { clearFoundOrgs, inviteSupplier } from 'buyer/Search/actions'
import isEmail from 'validator/lib/isEmail'
import isURL from 'validator/lib/isURL'
import fileToUrl from 'shared/utils/data/fileToUrl'
import OrgLogo from 'shared/components/OrgLogo'
import { useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import Loading from 'shared/components/Loading'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'
import ExternalLink from 'shared/components/ExternalLink'

type Props = {
  companyName?: string
  companyWebsite?: string
  contactEmail?: string
  onChange: (value: string) => void
}

const SearchForm = (props: Props) => {
  const { onChange } = props
  const [companyName, setCompanyName] = useState<string>(
    props.companyName || ''
  )
  const [companyWebsite, setCompanyWebsite] = useState<string>(
    props.companyWebsite || ''
  )
  const [contactEmail, setContactEmail] = useState<string>(
    props.contactEmail || ''
  )
  const [selectedOrgId, setSelectOrgId] = useState<string>('')

  const dispatch = useDispatch()

  const matchingOrgs = useSelector((state: RootState) =>
    state.getIn(['buyer', 'inviteSupplier', 'matchingOrgs', 'names'])
  )

  const matchingOrgsDomain = useSelector((state: RootState) =>
    state.getIn(['buyer', 'inviteSupplier', 'matchingOrgs', 'cleanDomain'])
  )

  const isInviting = useSelector(
    (state: RootState) =>
      state.getIn(['admin', 'loader', 'isInviting']) || false
  )

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.currentTarget.name) {
      case 'companyName':
        setCompanyName(e.currentTarget.value)
        break
      case 'companyWebsite':
        setCompanyWebsite(e.currentTarget.value)
        break
      case 'contactEmail':
        setContactEmail(e.currentTarget.value)
        break
      default:
        break
    }
  }

  const handleFindClick = () => {
    dispatch(
      inviteSupplier({
        companyName,
        companyWebsite,
        contactEmail,
        noMessage: true,
        allowForbiddenDomain: !!companyWebsite && !contactEmail
      })
    )
  }

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectOrgId(e.currentTarget.value)
    onChange(e.currentTarget.value)
  }

  const handleNoneOfAboveClick = () => {
    setSelectOrgId('')
    onChange('')
    dispatch(clearFoundOrgs())
  }

  return isInviting ? (
    <div className='tc pt2'>
      <Text>
        <FormattedMessage
          id='AdditionalInfoForm.Finding'
          defaultMessage='Finding...'
        />
      </Text>
      <Loading />
    </div>
  ) : matchingOrgs && matchingOrgs.size > 0 ? (
    <div>
      {matchingOrgs.map(org => {
        return (
          <div key={org.get('id')} className='flex justify-between mv2'>
            <div className='flex items-center'>
              <input
                name='remapDomain'
                type='radio'
                value={org.get('id')}
                checked={org.get('id') === selectedOrgId}
                className='mr2'
                onChange={handleRadioChange}
              />
              <div className='w2 h2 mr2 flex items-center'>
                <OrgLogo url={fileToUrl(org.getIn(['supplier', 'logo']))} />
              </div>
              <div className='f7 flex-auto'>
                <div className='fw6'>{org.get('name')}</div>
                {matchingOrgsDomain && (
                  <ExternalLink href={matchingOrgsDomain}>
                    {matchingOrgsDomain}
                  </ExternalLink>
                )}
              </div>
            </div>
          </div>
        )
      })}
      <Button
        className='mt3'
        autoSize
        size='small'
        secondary
        onClick={handleNoneOfAboveClick}
      >
        <FormattedMessage id='NoneOfTheAbove' />
      </Button>
    </div>
  ) : (
    <div>
      <Label required>
        <FormattedMessage
          id='InviteSupplierForm.CompanyName'
          defaultMessage='Company Name'
        />
      </Label>
      <Input
        name='companyName'
        required
        value={companyName}
        onChange={handleInputChange}
      />

      <div className='dib w-100 w-40-ns v-top'>
        <Label required>
          <FormattedMessage
            id='InviteSupplierForm.CompanyWebsite'
            defaultMessage='Company Website'
          />
        </Label>
        <Input
          name='companyWebsite'
          value={companyWebsite}
          onChange={handleInputChange}
          meta={{
            error: isEmail(companyWebsite)
              ? 'Please use a company website here.'
              : '',
            touched: true
          }}
        />
      </div>

      <div className='dib w-100 w-20-ns tc'>
        <Label>
          <FormattedMessage id='InviteSupplierForm.Or' defaultMessage='or' />
        </Label>
      </div>

      <div className='dib w-100 w-40-ns v-top'>
        <Label required>
          <FormattedMessage
            id='InviteSupplierForm.CompanyContactEmail'
            defaultMessage='Company Contact Email'
          />
        </Label>
        <Input
          name='contactEmail'
          type='email'
          value={contactEmail}
          onChange={handleInputChange}
        />
      </div>

      <Button
        className='mt3'
        autoSize
        size='small'
        onClick={handleFindClick}
        disabled={
          !companyName || (!isURL(companyWebsite) && !isEmail(contactEmail))
        }
      >
        <FormattedMessage id='FindButton' defaultMessage='Find' />
      </Button>
    </div>
  )
}

export default SearchForm
