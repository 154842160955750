import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import UserListItem from 'shared/components/UserListItem'
import UserAvatarContainer from 'shared/containers/UserAvatarContainer'
import paths from 'buyer/routes/paths'
import { Map, RecordOf } from 'immutable'

type Props = {
  numberOfConnections: number
  connections: Map<
    string,
    RecordOf<{
      id: string
      avatarUrl: string
      fullName: string
      title: string
      connectedTo: any
    }>
  >
  isPremium: boolean
}

const BuyerConnections: FC<Props> = ({
  isPremium,
  numberOfConnections,
  connections
}) => {
  const [showMore, setShowMore] = useState<boolean>(false)

  return (
    <div className='br2 ba b--black-10 pv2 ph3 mt3-5'>
      <div className='teal f2 f3-m lh-copy'>{numberOfConnections}</div>
      <div className='flex items-center'>
        <div className='dib flex-auto'>
          <Text>
            <FormattedMessage
              id='BuyerConnections.connections'
              defaultMessage='Connections'
            />
          </Text>
        </div>

        {isPremium && (
          <Button
            secondary
            autoSize
            size='small'
            onClick={() => setShowMore(!showMore)}
            label={
              showMore ? (
                <FormattedMessage
                  id='BuyerConnections.showLess'
                  defaultMessage='Show Less'
                />
              ) : (
                <FormattedMessage
                  id='BuyerConnections.showMore'
                  defaultMessage='Show More'
                />
              )
            }
          />
        )}
      </div>

      {showMore && connections && connections.size > 0 && (
        <div>
          {connections.toList().map(connection => (
            <UserListItem
              mini
              avatarAlignTop
              key={connection.get('id')}
              profilePictureUrl={connection.get('avatarUrl')}
              profileUrl={`${paths.basePath}/users/${connection.get('id')}`}
              firstName={connection.get('fullName')}
              lastName={''}
              title={connection.get('title')}
              secondaryText={
                <div>
                  {connection
                    .get('connectedTo')
                    .toList()
                    .map(userId => (
                      <div key={userId} className='dib w1-5 mr1 mb1'>
                        <UserAvatarContainer
                          userId={userId}
                          profileUrl={`${paths.basePath}/users/${userId}`}
                        />
                      </div>
                    ))}
                </div>
              }
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default BuyerConnections
