import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import SearchResultsItem, {
  SearchResultsItemProps
} from '../../components/SearchResultsItem'
import { fromJS, RecordOf, List } from 'immutable'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import { createSelector, defaultMemoize } from 'reselect'
import usersSelectors from 'shared/selectors/usersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import messagesSelectors from 'shared/selectors/messagesSelectors'
import paths from '../../../routes/paths'
import { selectUserToMessage } from 'shared/actions'
import parsePath from 'shared/utils/parsePath'
import RootState from 'shared/models/RootState'
import { updateContact } from 'buyer/SupplierProfile/actions'

type User = RecordOf<{
  orgUnitId: string
}>

type Props = {
  topicId: string
  user: User
  selectUserToMessage: ({ userToMessage: string, user: User }) => void
} & ContainerProps &
  RouteComponentProps &
  SearchResultsItemProps

class SearchResultsItemContainer extends Component<Props> {
  handleClickMessage = () => {
    const { userId, topicId, selectUserToMessage, user, history } = this.props
    selectUserToMessage({
      userToMessage: userId,
      user: user.set('orgUnitId', user.getIn(['parents', 'OrgUnit']))
    })
    history.push(
      parsePath(topicId ? paths.messageTopic : paths.messages, {
        messageTopicId: topicId
      })
    )
  }

  render() {
    return (
      <SearchResultsItem
        {...this.props}
        onClickMessage={this.handleClickMessage}
      />
    )
  }
}

const getContacts = createSelector(
  usersSelectors.getUsers,
  (state: RootState, contacts) => contacts,
  (users, contacts) =>
    contacts &&
    contacts
      .filter(contact => contact.get('connected'))
      .map(contact => contact.merge(users.get(contact.get('user'))))
)

const getUserContact = defaultMemoize(
  (userId, contacts) =>
    contacts && contacts.find(contact => contact.get('user') === userId)
)

type ContainerProps = {
  userId: string
  connectUser: (connectSupplierId: string, connectUserId: string) => void
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const currentUserId = sessionSelectors.getUserId(state)
    const user =
      state.getIn(['buyer', 'contactsSearch', 'resultsById', props.userId]) ||
      fromJS({})
    const userOrgUnitId = user.getIn(['parents', 'OrgUnit'])
    const contacts = getContacts(state, user.get('contacts'))
    const userContact = getUserContact(currentUserId, contacts)

    const topContact =
      contacts &&
      (userContact || contacts.find(contact => !!contact.get('contactFor')))

    const userRoles = user.get('roles') || List([])

    return {
      userId: user.get('id'),
      user,
      supplierId: user.getIn(['parents', 'OrgUnit']),
      firstName: user.get('firstName'),
      lastName: user.get('lastName'),
      profilePictureUrl: fileToUrl(user.get('profilePicture')),
      email: user.get('email'),
      title: user.get('title'),
      orgUnitId: userOrgUnitId,
      companyName: user.getIn(['extended', 'OrgUnit', userOrgUnitId, 'name']),
      phone: user.getIn(['phones', 'office']),
      mobilePhone: user.getIn(['phones', 'mobile']),
      address: user.get('location'),
      isBlocked:
        user.get('status') && user.get('status').toLowerCase() === 'blocked',
      contacts,
      contactFor: topContact && topContact.get('contactFor'),
      isPrivate: userContact && userContact.get('isPrivate'),
      isConnected: userContact && userContact.get('connected'),
      colleagueIsConnected: contacts && contacts.size > 0,
      topicId: messagesSelectors.getTopicIdByUserId(state, user.get('id')),
      hasRelationship: user.getIn([
        'extended',
        'OrgUnit',
        user.getIn(['parents', 'OrgUnit']),
        'existing'
      ]),
      isBuyer: userRoles.includes('buyer'),
      isSupplier: userRoles.includes('supplier')
    }
  },
  {
    selectUserToMessage,
    updateContact
  }
)(withRouter(SearchResultsItemContainer))
