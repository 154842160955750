import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { getEsgReportPage } from '../../store/actions'
import ReportQuestion from './ReportQuestion'
import insightsSelectors from '../../store/insightsSelectors'
import Loading from 'shared/components/Loading'

const pageId = 'environment'

const EnvironmentReport = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(insightsSelectors.isEsgReportPageLoading)

  useEffect(() => {
    dispatch(getEsgReportPage(pageId))
  }, [dispatch])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className='ba b--black-10 bg-light-gray ph3'>
      <ReportQuestion
        label={<FormattedMessage id='Question1' defaultMessage='Question 1' />}
        pageId={pageId}
        questionId='iso14001'
      />
      <ReportQuestion
        label={<FormattedMessage id='Question2' defaultMessage='Question 2' />}
        pageId={pageId}
        questionId='greenhouseGasInventory'
        subQuestionIds={['scopeEmissions', 'thirdPartyCert', 'ghgInventory']}
      />
      <ReportQuestion
        label={<FormattedMessage id='Question3' defaultMessage='Question 3' />}
        pageId={pageId}
        questionId='ghgReductionTarget'
        subQuestionIds={['validatedBySBTi']}
      />
      <ReportQuestion
        label={<FormattedMessage id='Question4' defaultMessage='Question 4' />}
        pageId={pageId}
        questionId='iso50001'
      />
      <ReportQuestion
        label={<FormattedMessage id='Question5' defaultMessage='Question 5' />}
        pageId={pageId}
        questionId='energyConsumptionTracking'
      />
      <ReportQuestion
        label={<FormattedMessage id='Question6' defaultMessage='Question 6' />}
        pageId={pageId}
        questionId='waterUsageTracking'
      />
      <ReportQuestion
        label={<FormattedMessage id='Question7' defaultMessage='Question 7' />}
        pageId={pageId}
        questionId='wastePrograms'
      />
      <ReportQuestion
        label={<FormattedMessage id='Question8' defaultMessage='Question 8' />}
        pageId={pageId}
        questionId='environmentQuestion8'
        subQuestionIds={[
          'improvingEnergyEfficiency',
          'managingWaterUsage',
          'managingWaste'
        ]}
        showSubQuestions
        preStatContent={
          <label className='db f6 lh-copy mb3'>
            <FormattedMessage
              id='EnvironmentReport.Question8'
              defaultMessage='Are there goals or targets in place for any of the following.'
            />
          </label>
        }
      />
      <ReportQuestion
        label={<FormattedMessage id='Question9' defaultMessage='Question 9' />}
        pageId={pageId}
        questionId='negativeEnvironmentalImpacts'
      />
    </div>
  )
}

export default EnvironmentReport
