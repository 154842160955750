import React, { Fragment, useEffect, useRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import OfferingsAddTagForm from '../../containers/OfferingsAddTagForm'
import MyTagsContainer from '../../containers/MyTagsContainer'
import MyNotesContainer from '../../containers/MyNotesContainer'
import ReviewDialogContainer from '../../containers/ReviewDialogContainer'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import supplierProfilePaths from '../../../SupplierProfile/routes'
import Link from 'shared/components/Link'
import parsePath from 'shared/utils/parsePath'
import Loading from 'shared/components/Loading'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf } from 'immutable'
import SearchResultGroupContainer from '../../containers/SearchResultGroupContainer'
import { makeStyles } from '@material-ui/core'

/**
 * SearchResults displays the number of results and the list of results
 */

type Props = {
  vetId?: string
  isSearching: boolean
  currentUrl: string
  scrollPosition: number
  isAddTagDialogOpen: boolean
  isAddNoteDialogOpen: boolean
  isReviewDialogOpen: boolean
  reviewSupplierName: string
  numberOfSuppliersToShow: number
  searchId: string
  numberOfSearchResults: number
  resultsInGroup: List<
    RecordOf<{
      orgUnit: string
      orgName: string
      numOrgUnits: number
      orgUnits: List<any>
    }>
  >
  selectedSupplierId: string
  selectedCardId: string
  onCloseSupplierReviewDialog: () => void
  onClickShowMore: () => void
  onCloseAddTagDialog: () => void
}

const useStyles = makeStyles({
  visuallyHidden: {
    clip: 'rect(1px, 1px, 1px, 1px)',
    clipPath: 'inset(50%)',
    height: '1px',
    width: '1px',
    margin: '-1px'
  }
})

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const SearchResults = (props: Props) => {
  const { scrollPosition, isSearching } = props
  const prevSearchingStatus = usePrevious(isSearching)
  useEffect(() => {
    document.documentElement.scrollTop = scrollPosition
    document.body.scrollTop = scrollPosition
  }, [scrollPosition])
  const classes = useStyles()

  const {
    searchId,
    vetId,
    resultsInGroup,
    isAddTagDialogOpen,
    isAddNoteDialogOpen,
    onCloseAddTagDialog,
    reviewSupplierName,
    isReviewDialogOpen,
    onCloseSupplierReviewDialog,
    selectedSupplierId,
    selectedCardId,
    numberOfSuppliersToShow,
    onClickShowMore,
    currentUrl
  } = props

  let companyUrl =
    selectedSupplierId &&
    parsePath(supplierProfilePaths.myCompany, {
      supplierId: selectedSupplierId
    })

  companyUrl = `${companyUrl}?redirectFrom=${currentUrl}&scrollToReviews=true`

  return (
    <Fragment>
      <div
        className={`absolute overflow-hidden pa0 ${classes.visuallyHidden}`}
        aria-label={
          isSearching
            ? 'Loading...'
            : prevSearchingStatus
            ? 'Results loaded'
            : ''
        }
        role='status'
        aria-live={isSearching ? 'assertive' : 'polite'}
      />
      {isSearching && <Loading />}

      {!isSearching && (
        <>
          <div className='bg-white ba b--black-10 br2 mb1' id='results'>
            {resultsInGroup &&
              resultsInGroup
                .take(numberOfSuppliersToShow)
                .map((group, key) => (
                  <SearchResultGroupContainer
                    key={group.get('orgUnit')}
                    orgUnit={group.get('orgUnit')}
                    orgName={group.get('orgName')}
                    numOrgUnits={group.get('numOrgUnits')}
                    index={key}
                    vetId={vetId}
                    searchId={searchId}
                    redirectFrom={currentUrl}
                  />
                ))}
          </div>
          {resultsInGroup && resultsInGroup.size > numberOfSuppliersToShow && (
            <Button
              onClick={onClickShowMore}
              label={
                <FormattedMessage
                  id='SearchResults.ShowMore'
                  defaultMessage='Show More'
                />
              }
              secondary
            />
          )}
        </>
      )}

      <ClearbitCredit />

      <Dialog
        onClose={onCloseAddTagDialog}
        open={isAddTagDialogOpen || isAddNoteDialogOpen}
        fullWidth
      >
        <DialogTitle onClose={onCloseAddTagDialog}>
          {isAddTagDialogOpen ? (
            <FormattedMessage
              id='SearchResults.WhatDoYouKnowThemFor'
              defaultMessage='What do you know them for'
            />
          ) : (
            <FormattedMessage
              id='SearchResults.MakeANote'
              defaultMessage='Make a note about the supplier'
            />
          )}
        </DialogTitle>
        <DialogContent>
          {isAddTagDialogOpen && (
            <div className='mt3'>
              <MyTagsContainer
                supplierId={selectedSupplierId}
                cardId={selectedCardId}
              />
              <OfferingsAddTagForm
                supplierId={selectedSupplierId}
                cardId={selectedCardId}
              />
            </div>
          )}
          {isAddNoteDialogOpen && (
            <MyNotesContainer supplierId={selectedSupplierId} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseAddTagDialog}>
            {isAddTagDialogOpen ? (
              <FormattedMessage
                id='SearchResults.Close'
                defaultMessage='Close'
              />
            ) : (
              <FormattedMessage
                id='SearchResults.AddNote'
                defaultMessage='Add Note'
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={onCloseSupplierReviewDialog}
        open={isReviewDialogOpen}
        fullWidth
        disableBackdropClick
      >
        <DialogTitle>
          <FormattedMessage
            id='SearchResults.WriteAReviewFor'
            defaultMessage='Write a review for {reviewSupplierName}'
            values={{ reviewSupplierName }}
          />
        </DialogTitle>
        <Link className='db tr mt3 mr3 f7 gray' to={companyUrl}>
          <FormattedMessage
            id='SearchResults.SeeOtherRatingsForThisSupplier'
            defaultMessage='See other ratings for this supplier'
          />
        </Link>

        <ReviewDialogContainer />
      </Dialog>
    </Fragment>
  )
}

export default SearchResults
