import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import profileSelectors from '../../selectors/profileSelectors'
import ListComponent from 'shared/components/List'
import Scrollable from 'shared/components/Scrollable'
import SiblingListItem from './SiblingListItem'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'
import { createSelector } from 'reselect'
import RootState from 'shared/models/RootState'

type Props = {
  isSupplierApp?: boolean
  supplierIds: List<string>
}
export class SiblingSuppliers extends Component<Props> {
  render() {
    const { supplierIds, isSupplierApp } = this.props
    return supplierIds && supplierIds.size > 0 ? (
      <Fragment>
        <Label>
          <FormattedMessage
            id='SiblingSuppliers.Associated'
            defaultMessage='Associated'
          />
        </Label>
        <Scrollable>
          <ListComponent>
            {supplierIds.map(supplierId => (
              <SiblingListItem
                key={supplierId}
                supplierId={supplierId}
                disableLink={isSupplierApp}
              />
            ))}
          </ListComponent>
        </Scrollable>
      </Fragment>
    ) : null
  }
}

const getSiblingSupplierIds = createSelector(
  (state: RootState) => profileSelectors.getDataField(state, 'siblings'),
  siblingSuppliers =>
    siblingSuppliers &&
    siblingSuppliers
      .toList()
      .sort((sibA, sibB) => {
        const nameA = sibA.get('name').toUpperCase()
        const nameB = sibB.get('name').toUpperCase()
        return nameA < nameB ? -1 : 1
      })
      .map(sib => sib.get('id'))
)

export default connect((state: RootState) => {
  return {
    supplierIds: getSiblingSupplierIds(state)
  }
})(SiblingSuppliers)
