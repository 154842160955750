import React, { Component } from 'react'
import { connect } from 'react-redux'
import { saveCompanyInfo, deletePitchDeck } from '../../../actions'
import FileInput from 'shared/components/FileInput'
import Text from 'shared/components/Text'
import companySelectors from '../../../../shared/selectors/companySelectors'
import mobileContactsSelector from 'accounts/selectors/mobileContactsSelector'
import PitchDeck from 'shared/components/PitchDeck'
import MaterialButton from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import Button from 'shared/components/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'

type Props = {
  pitchDeckFileUrl: string
  onDeletePitchDeck: () => void
  mobile: boolean
  saveCompanyInfo: ({ pitchDeckFile: string }) => void
}

export class PitchDeckEditForm extends Component<Props> {
  state = {
    newPitchDeckFile: null,
    openDeleteDialog: false
  }

  handleChangePitchDeck = newPitchDeckFile => {
    this.setState({
      newPitchDeckFile
    })
  }

  handleSavePitchDeck = () => {
    this.props.saveCompanyInfo({
      pitchDeckFile: this.state.newPitchDeckFile
    })

    this.handleCancelSavingPitchDeck()
  }

  handleCancelSavingPitchDeck = () => {
    this.setState({
      newPitchDeckFile: null
    })
  }

  toggleDeleteDialog = () => {
    this.setState({
      openDeleteDialog: !this.state.openDeleteDialog
    })
  }

  handleDeletePitchDeck = () => {
    this.setState({
      openDeleteDialog: false
    })
    this.props.onDeletePitchDeck()
  }

  render() {
    const { pitchDeckFileUrl, mobile } = this.props
    const { newPitchDeckFile, openDeleteDialog } = this.state

    return (
      <div className='pt3-5'>
        {pitchDeckFileUrl ? (
          <PitchDeck url={pitchDeckFileUrl} mobile={mobile} />
        ) : (
          <Text className='teal'>
            <FormattedMessage
              id='PitchDeckEditForm.ShowProspectiveClientsText'
              defaultMessage='Show prospective clients what your company can do for
                them. A pitch deck is fastest way to show what you can do.'
            />
          </Text>
        )}

        {!pitchDeckFileUrl && (
          <>
            <Label className='db mt3 tc'>
              <FormattedMessage
                id='PitchDeckEditForm.AddPitchDeck'
                defaultMessage='Add Pitch Deck'
              />
            </Label>

            <FileInput
              name='pitchDeckFile'
              accept='.pdf,.pptx,.ppt'
              onChange={this.handleChangePitchDeck}
              value=''
            />
          </>
        )}

        {!!pitchDeckFileUrl && (
          <div className='nt5-ns ml4-ns'>
            <MaterialButton
              variant='contained'
              onClick={this.toggleDeleteDialog}
            >
              <DeleteIcon />
              <span className='ml2'>
                <FormattedMessage
                  id='PitchDeckEditForm.RemovePitchDeck'
                  defaultMessage='Remove Pitch Deck'
                />
              </span>
            </MaterialButton>
          </div>
        )}

        <Dialog
          open={!!newPitchDeckFile}
          onClose={this.handleCancelSavingPitchDeck}
        >
          <DialogContent>
            <Text className='mt3'>
              <FormattedMessage
                id='PitchDeckEditForm.SaveTheNewPitch'
                defaultMessage='Save the new pitch deck file?'
              />
            </Text>
          </DialogContent>
          <DialogActions>
            <Button
              secondary
              label={<FormattedMessage id='CancelButton' />}
              onClick={this.handleCancelSavingPitchDeck}
            />
            <Button
              label={
                <FormattedMessage
                  id='PitchDeckEditForm.SaveButton'
                  defaultMessage='Save'
                />
              }
              onClick={this.handleSavePitchDeck}
            />
          </DialogActions>
        </Dialog>

        <Dialog open={openDeleteDialog} onClose={this.toggleDeleteDialog}>
          <DialogTitle>
            <FormattedMessage
              id='PitchDeckEditForm.DeletingUser'
              defaultMessage='Remove this Pitch Deck File?'
            />
          </DialogTitle>
          <DialogContent>
            <Text className='mt3'>
              <FormattedMessage
                id='PitchDeckEditForm.cantUndo'
                defaultMessage={"You won't be able to undo this."}
              />
            </Text>
          </DialogContent>
          <DialogActions>
            <Button
              label={
                <FormattedMessage
                  id='PitchDeckEditForm.Delete'
                  defaultMessage='Delete'
                />
              }
              autoSize
              onClick={this.handleDeletePitchDeck}
              className='bg-red'
            />
            <Button
              label={<FormattedMessage id='CancelButton' />}
              autoSize
              secondary
              onClick={this.toggleDeleteDialog}
            />
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default connect(
  state => ({
    mobile: !!mobileContactsSelector.get(state),
    pitchDeckFileUrl: companySelectors.getPitchDeck(state)
  }),
  {
    saveCompanyInfo,
    onDeletePitchDeck: deletePitchDeck
  }
)(PitchDeckEditForm)
