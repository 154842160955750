import * as actionTypes from 'supplier/shared/actionTypes'
import { Record, List } from 'immutable'
import { LOGOUT } from 'shared/actionTypes'

const VetRecord = Record({
  key: '',
  name: '',
  companyName: '',
  companyOrgUnit: '',
  lastUpdate: '',
  status: '',
  statusDate: '',
  submitResponseBy: ''
})

const VetsRecord = Record({
  items: List([]),
  isLoading: false
})

export const VetRecordFactory = vets => {
  return List(vets.map(vet => new VetRecord(vet)))
}

export const vetsReducer = (state = new VetsRecord(), action) => {
  switch (action.type) {
    case actionTypes.LOAD_VETS_SUCCESS:
      return state.merge({
        items: VetRecordFactory(action.payload),
        isLoading: false
      })
    case actionTypes.LOAD_VETS:
      return state.merge({ isLoading: true })
    case LOGOUT:
      return new VetsRecord()
    default:
      return state
  }
}

export default vetsReducer
