import React, { Component } from 'react'
import Button from '../Button'
import AgreeToTerms from '../AgreeToTerms'
import { FormattedMessage } from 'react-intl'
import Paper from 'shared/components/Paper'
import TealbookLogoWrapper from 'accounts/components/TealbookLogoWrapper'

type Props = {
  onAcceptTerms: () => void
  noTermsAndConditions: boolean
}

class AgreeToUpdatedTermsPage extends Component<Props> {
  state = {
    agreeToTerms: false
  }

  handleAgreeToTermsToggle = () => {
    this.setState({
      agreeToTerms: !this.state.agreeToTerms
    })
  }

  render() {
    const { onAcceptTerms, noTermsAndConditions } = this.props
    const { agreeToTerms } = this.state

    return (
      <TealbookLogoWrapper>
        <Paper>
          <p className='fw3'>
            <FormattedMessage
              id='AgreeToUpdatedTermsPage.serviceTermsUpdated'
              defaultMessage='Our terms of service have been updated'
            />
          </p>

          <AgreeToTerms
            className='mv3'
            agreeToTerms={agreeToTerms}
            onChange={this.handleAgreeToTermsToggle}
            noTermsAndConditions={noTermsAndConditions}
          />

          <Button
            label={
              <FormattedMessage
                id='AgreeToUpdateTermsPage.Continute'
                defaultMessage='Continue'
              />
            }
            onClick={onAcceptTerms}
            disabled={!agreeToTerms}
          />
        </Paper>
      </TealbookLogoWrapper>
    )
  }
}

export default AgreeToUpdatedTermsPage
