import React, { FunctionComponent } from 'react'
import { List, RecordOf } from 'immutable'
import PageSideSection from 'shared/components/PageSideSection'
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  IntlShape
} from 'react-intl'
import ListItem from 'shared/components/List/ListItem'
import dateFormat from 'shared/utils/dateFormat'
import { ReactComponent as IconCreated } from 'shared/assets/icons/vet-milestone-created.svg'
import { ReactComponent as IconSuggested } from 'shared/assets/icons/vet-milestone-added.svg'
import { ReactComponent as IconCompleted } from 'shared/assets/icons/vet-milestone-completed.svg'
import { ReactComponent as IconDecision } from 'shared/assets/icons/vet-milestone-decision.svg'
import { ReactComponent as IconInvited } from 'shared/assets/icons/vet-milestone-sent.svg'
import { ReactComponent as IconResponseBy } from 'shared/assets/icons/vet-milestone-response.svg'
import { ReactComponent as IconResponded } from 'shared/assets/icons/vet-milestone-received.svg'
import { ReactComponent as IconEnabled } from 'shared/assets/icons/vet-milestone-communication.svg'

const messages = defineMessages({
  created: {
    id: 'Milestones.Created',
    defaultMessage: 'RFI created'
  },
  suggested: {
    id: 'Milestones.Suggested',
    defaultMessage: 'Supplier suggested'
  },
  enabled: {
    id: 'Milestones.Enabled',
    defaultMessage: 'Communication enabled'
  },
  invited: {
    id: 'Milestones.Invited',
    defaultMessage: 'Invitation sent'
  },
  responded: {
    id: 'Milestones.Responded',
    defaultMessage: 'Response received'
  },
  responseBy: {
    id: 'Milestones.ResponseBy',
    defaultMessage: 'Response by'
  },
  decision: {
    id: 'Milestones.Decision',
    defaultMessage: 'Decision on'
  },
  completed: {
    id: 'Milestones.Completed',
    defaultMessage: 'Completion on'
  }
})

type Props = {
  milestones: List<
    RecordOf<{
      type: string
      date: string | Date
    }>
  >
  intl: IntlShape
}

export const Milestones: FunctionComponent<Props> = props => {
  const today = new Date()
  const renderIcon = milestone => {
    const colorMap = {
      inactive: '#C6C9CF',
      created: '#F8E71C',
      suggested: '#357EDD',
      enabled: '#357EDD',
      responseBy: '#FFB700',
      decision: '#FFB700',
      completed: '#FF1436',
      default: '#00BCAB'
    }

    const fill =
      new Date(milestone.get('date')) < today
        ? colorMap[milestone.get('type')]
          ? colorMap[milestone.get('type')]
          : colorMap['default']
        : colorMap['inactive']

    switch (milestone.get('type')) {
      case 'created':
        return <IconCreated fill={fill} />
      case 'suggested':
        return <IconSuggested fill={fill} />
      case 'enabled':
        return <IconEnabled fill={fill} />
      case 'invited':
        return <IconInvited fill={fill} />
      case 'responded':
        return <IconResponded fill={fill} />
      case 'responseBy':
        return <IconResponseBy fill={fill} />
      case 'decision':
        return <IconDecision fill={fill} />
      case 'completed':
        return <IconCompleted fill={fill} />
      default:
        return null
    }
  }

  return props.milestones && props.milestones.size > 0 ? (
    <PageSideSection
      title={
        <FormattedMessage
          id='Milestones.Milestones'
          defaultMessage='Milestones'
        />
      }
    >
      {props.milestones.map((milestone, index) => (
        <div key={`milestone-${index}`}>
          <ListItem
            mini
            leftAvatar={renderIcon(milestone)}
            primaryText={
              <span
                className={
                  new Date(milestone.get('date')) < today ? '' : 'gray'
                }
              >
                {props.intl.formatMessage(messages[milestone.get('type')])}
              </span>
            }
            secondaryText={
              <span
                className={
                  new Date(milestone.get('date')) < today ? '' : 'gray'
                }
              >
                {dateFormat(milestone.get('date'))}
              </span>
            }
          />
        </div>
      ))}
    </PageSideSection>
  ) : null
}

export default injectIntl(Milestones)
