import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { searchLocation, toggleFilterOption } from '../../actions'
import searchSelectors from '../../selectors/searchSelectors'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import Scrollable from 'shared/components/Scrollable'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import Loading from 'shared/components/Loading'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'

const PopoverWithStyles = withStyles({
  paper: {
    padding: '1rem',
    minWidth: 200
  }
})(Popover)

type Props = {
  searchLocation: (arg: string) => void
  toggleFilterOption: (arg: { key: string; value: string }) => void
  isLocationSearching: boolean
  results: List<{ value: string; label: string }>
}
export class LocationSearchContainer extends Component<Props> {
  state = {
    locationText: '',
    showResults: false,
    anchorEl: undefined
  }

  handleLocationChange = e => {
    this.setState({
      locationText: e.target.value,
      anchorEl: e.target
    })
  }

  handleKeyPress = e => {
    if (e.charCode === 13) {
      this.handleSearchClick()
    }
  }

  handleSearchClick = (e?) => {
    const { searchLocation } = this.props
    const { locationText } = this.state

    if (locationText) {
      searchLocation(locationText)
      this.setState({
        locationText: '',
        showResults: true
      })
    }
  }

  handleLocationClick = (key, value) => {
    const { toggleFilterOption } = this.props
    this.handleRequestClose()
    toggleFilterOption({
      key,
      value
    })
  }

  handleRequestClose = (e?) => {
    this.setState({
      showResults: false
    })
  }

  renderLocation = location => {
    const valueArray = location.value.split('::')
    const key = valueArray[2] ? 'city' : valueArray[1] ? 'state' : 'country'

    const value =
      key === 'city'
        ? location.value
        : key === 'state'
        ? `${valueArray[0]}::${valueArray[1]}`
        : valueArray[0]

    return (
      <div key={location.value} className='pv1 cf'>
        <input
          type='checkbox'
          id={location.value}
          onChange={() => this.handleLocationClick(key, value)}
          className='b--black-10 fl'
          value={location.value}
        />
        <label
          htmlFor={location.value}
          className='f7 fw3 mid-gray pointer db ml3 pl1'
        >
          {location.label}
        </label>
      </div>
    )
  }

  render() {
    const { isLocationSearching, results } = this.props

    return (
      <Fragment>
        <Label className='db gray mb1 f7' htmlFor='locationSearchInput'>
          <FormattedMessage
            id='LocationSearchContainer.SearchForALocation'
            defaultMessage='Search for a location'
          />
        </Label>
        <div className='dib w-70'>
          <FormattedMessage
            id='LocationSearchContainer.EnterCountryStateOrCity'
            defaultMessage='Enter country, state, or city name'
          >
            {message => (
              <Input
                placeholder={message as string}
                onChange={this.handleLocationChange}
                onKeyPress={this.handleKeyPress}
                value={this.state.locationText}
                name='locationSearchInput'
              />
            )}
          </FormattedMessage>
        </div>
        <div className='dib w-30'>
          <div className='pl2'>
            <Button
              label={
                <FormattedMessage
                  id='LocationSearchContainer.Search'
                  defaultMessage='Search'
                />
              }
              onClick={this.handleSearchClick}
              aria-label='Search location'
            />
          </div>
        </div>
        <PopoverWithStyles
          open={this.state.showResults}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={this.handleRequestClose}
        >
          {isLocationSearching ? (
            <div className='tc'>
              <Loading className='mv2' />
            </div>
          ) : results.size > 0 ? (
            <Scrollable maxHeight={200}>
              {results.map(location => this.renderLocation(location))}
            </Scrollable>
          ) : (
            <Text>
              <FormattedMessage
                id='LocationSearchContainer.NoMatch'
                defaultMessage='No match found'
              />
            </Text>
          )}
        </PopoverWithStyles>
      </Fragment>
    )
  }
}

export default connect(
  state => ({
    isLocationSearching: searchSelectors.isLocationSearching(state),
    results: searchSelectors.getLocationResults(state)
  }),
  {
    searchLocation,
    toggleFilterOption
  }
)(LocationSearchContainer)
