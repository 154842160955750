import moment from 'moment'
import { call } from 'redux-saga/effects'
import insights from 'shared/utils/api/insight'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* loadInternalStatsSaga(action) {
  const { keys, type, startDate, endDate, selectedOrgId } = action.payload
  let response = yield call(insights.getInternalStats, {
    keys,
    type,
    startDate,
    endDate,
    selectedOrgId
  })

  const sortInternalCategory = category =>
    category.sort((a, b) => {
      let catA = a.internalCategory.toLowerCase()
      let catB = b.internalCategory.toLowerCase()
      if (catA < catB) {
        return -1
      }
      if (catA > catB) {
        return 1
      } else {
        return 0
      }
    })

  if (response.categories1) {
    response.categories1 = sortInternalCategory(response.categories1)
  }
  if (response.categories10) {
    response.categories10 = sortInternalCategory(response.categories10)
  }

  let month = []
  if (response.userEngagement) {
    response.userEngagement = response.userEngagement.map(
      ({
        Jan,
        Feb,
        Mar,
        Apr,
        May,
        Jun,
        Jul,
        Aug,
        Sep,
        Oct,
        Nov,
        Dec,
        ...rest
      }) => ({
        months: [
          ...month,
          { Jan },
          { Feb },
          { Mar },
          { Apr },
          { May },
          { Jun },
          { Jul },
          { Aug },
          { Sep },
          { Oct },
          { Nov },
          { Dec }
        ],
        ...rest
      })
    )
  }
  if (response.userReconciliationReport) {
    response.userReconciliationReport = response.userReconciliationReport.map(
      ({ year, month, ...rest }) => {
        return {
          ...rest,
          month: moment()
            .month(month - 1)
            .format('MMM'),
          year
        }
      }
    )
  }
  return { type, response }
}

export default createApiRequestSaga(loadInternalStatsSaga)
