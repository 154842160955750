import React, { Component } from 'react'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import PageSideSection from 'shared/components/PageSideSection'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import PageQuestions from '../../containers/PageQuestions'
import CommunityFormProgress from '../../containers/CommunityFormProgress'
import Warning from 'shared/components/Warning'
import { FormattedMessage } from 'react-intl'

type Props = {
  pageTitle?: string
  communityName?: string
  lastPage: boolean
  formIsMissingResponse: boolean
  onClickContinue: () => void
  onClickBack: () => void
  onClickSaveForLater: () => void
}

class CommunityFormPage extends Component<Props> {
  handleSubmit = e => {
    e.preventDefault()
    this.props.onClickContinue()
  }

  render() {
    const {
      pageTitle,
      communityName,
      onClickBack,
      lastPage,
      formIsMissingResponse,
      onClickSaveForLater
    } = this.props

    return (
      <Page
        title={
          <FormattedMessage
            id='CommunityFormPage.CommunityFormTitle'
            defaultMessage='Community Form'
          />
        }
      >
        <Main>
          <PageSection title={pageTitle}>
            <form onSubmit={this.handleSubmit} noValidate>
              <PageQuestions />

              {lastPage && formIsMissingResponse && (
                <Warning
                  message={
                    <FormattedMessage
                      id='CommunityFormPage.MissingResponse'
                      defaultMessage='Missing response(s)'
                    />
                  }
                  className='mt3-5'
                />
              )}

              <div className='mt3-5'>
                <Button
                  label={
                    lastPage ? (
                      <FormattedMessage
                        id='CommunityFormPage.SubmitButton'
                        defaultMessage='Submit'
                      />
                    ) : (
                      <FormattedMessage
                        id='CommunityFormPage.ContinueButton'
                        defaultMessage='Continue'
                      />
                    )
                  }
                  autoSize
                  className='mr3'
                  type='submit'
                />
                <Button
                  label={
                    <FormattedMessage
                      id='CommunityFormPage.BackButton'
                      defaultMessage='Back'
                    />
                  }
                  onClick={onClickBack}
                  autoSize
                  secondary
                />
              </div>
            </form>
          </PageSection>
        </Main>

        <Aside className='pt4'>
          <PageSideSection
            title={
              <FormattedMessage
                id='CommunityFormPage.CompleteFormMessageTitle'
                defaultMessage='Complete Form Message'
              />
            }
            hideTitle
          >
            <Text className='mt3-5'>
              <FormattedMessage
                id='CommunityFormPage.CompleteThe'
                defaultMessage='Complete the'
              />
              &nbsp;
              <span className='fw8'>
                <FormattedMessage
                  id='CommunityFormPage.CommunityName'
                  defaultMessage='{communityName} Community'
                  values={{ communityName }}
                />
              </span>
              &nbsp;
              <FormattedMessage
                id='CommunityFormPage.FormsToBeFeatured'
                defaultMessage='forms to be featured in the community.'
              />
            </Text>
          </PageSideSection>

          <PageSideSection
            title={
              <FormattedMessage
                id='CommunityFormPage.SummaryTitle'
                defaultMessage='Summary'
              />
            }
          >
            <div className='mt2 mb3'>
              <CommunityFormProgress />
            </div>
            <Button
              label={
                <FormattedMessage
                  id='CommunityFormPage.SaveForLaterLabel'
                  defaultMessage='Save for later'
                />
              }
              onClick={onClickSaveForLater}
            />
          </PageSideSection>
        </Aside>
      </Page>
    )
  }
}

export default CommunityFormPage
