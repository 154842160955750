import React from 'react'
import InsightTitle from 'shared/components/InsightTitle'
import startCase from 'lodash.startcase'
import Text from 'shared/components/Text'
import Paper from 'shared/components/Paper'
import { RecordOf, List } from 'immutable'

type Props = {
  stat: string
  value: List<RecordOf<{ topSpendSuppliers: number }>>
}

const Top80Table = ({ stat, value }: Props) => {
  return (
    <div key={stat}>
      <div className='flex mt4'>
        <InsightTitle>{startCase(stat)}</InsightTitle>
      </div>
      <Paper>
        <Text className='pt3'>{`${value.getIn([
          0,
          'topSpendSuppliers'
        ])} suppliers`}</Text>
      </Paper>
    </div>
  )
}

export default Top80Table
