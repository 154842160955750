import React, { useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RootState from 'shared/models/RootState'
import dataQualitySelectors from '../../store/selectors/dataQualitySelectors'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Divider from 'shared/components/Divider'
import {
  getDataQualityJobStatus,
  clearDataQualityJobStatus
} from '../../store/actions'
import DataQualityColumnsMapping from './DataQualityColumnsMapping'
import Button from 'shared/components/Button'
import api from 'shared/utils/api'
import { apiRoute } from 'shared/utils/api/dqService/dqService'
import FileSaver from 'file-saver'
import { notify } from 'shared/actions'
import { Column } from 'react-virtualized'
import Table from 'shared/components/Table'
import usersSelectors from 'shared/selectors/usersSelectors'
import startCase from 'lodash.startcase'
import { List, RecordOf, Map } from 'immutable'

type Props = {
  jobId: string
}

const DataQualityJobDetail = (props: Props) => {
  const { jobId } = props
  const dispatch = useDispatch()
  const job = useSelector((state: RootState) =>
    dataQualitySelectors.getJob(state, jobId)
  )
  const jobStatus = useSelector(dataQualitySelectors.getJobStatus)
  const jobStatusSummary: List<RecordOf<{
    category: string
    count: string
    progress: string
  }>> = useSelector(dataQualitySelectors.getJobStatusSummary)
  const jobStatusDerelictRows: List<Map<
    string,
    string | null | number
  >> = useSelector(dataQualitySelectors.getJobDerelictRows)
  const users = useSelector(usersSelectors.getUsers)
  const user = users?.get(job?.getIn(['created', 'user']))

  const DerelictRowKeys = useMemo(() => {
    const keys =
      jobStatusDerelictRows?.size > 0
        ? jobStatusDerelictRows
            .get(0)
            ?.keySeq()
            .toJS()
        : []
    return keys
      ?.filter(
        (k: string) =>
          !(
            k.startsWith('reason') ||
            k.startsWith('Internal') ||
            k.startsWith('Company')
          )
      )
      .sort()
  }, [jobStatusDerelictRows])

  const updateJobDetail = useCallback(() => {
    if (
      job?.get('status') === 'analyzing' ||
      job?.get('status') === 'initialized'
    ) {
      dispatch(getDataQualityJobStatus(jobId))
    }
  }, [dispatch, jobId, job])

  useEffect(() => {
    dispatch(getDataQualityJobStatus(jobId))

    const interval = global.setInterval(() => {
      updateJobDetail()
    }, 2000)

    return () => {
      global.clearInterval(interval)
      dispatch(clearDataQualityJobStatus())
    }
  }, [updateJobDetail, dispatch, jobId])

  const handleDownload = downloadPath => {
    const filename = downloadPath.split('/')[2]
    api
      .download(`${apiRoute}/downloadFile/${downloadPath}`)
      .then(response => response.blob())
      .then(blob => FileSaver.saveAs(blob, `${filename}`))
      .catch(err =>
        dispatch(
          notify({
            message: err.message
          })
        )
      )
  }

  const getDerelictRow = ({ index }) => {
    return jobStatusDerelictRows.get(index)
  }
  const getJobStatusRow = ({ index }) => {
    return jobStatusSummary.get(index)
  }
  const sourceFilePath = job?.getIn(['attachments', 'uploadedFilePath'])
  const derelictFilePath = job?.getIn(['attachments', 'derelictFilePath'])
  const completedFilePath = job?.getIn(['attachments', 'completedFilePath'])

  return jobStatus ? (
    <div>
      <label className='f5 fw5 mb3 mt4 db'>
        {job.get('name')} ({jobId})
      </label>
      <Text>
        <span className='fw6 dib w4'>Created By:</span> {user?.get('firstName')}{' '}
        {user?.get('lastName')}
      </Text>
      <Text>
        <span className='fw6 dib w4'>Filename:</span>{' '}
        {job.getIn(['attachments', 'description'])}
      </Text>
      <Text>
        <span className='fw6 dib w4'>Status:</span>{' '}
        {startCase(job.get('status'))}
      </Text>
      {jobStatus.get('status') === 'initialized' &&
        job.getIn(['metadata', 'output']) && (
          <DataQualityColumnsMapping
            jobId={job.get('id')}
            filename={job.getIn(['attachments', 'fileName'])}
            columnsMapping={job.getIn(['metadata', 'output'])}
          />
        )}
      {(jobStatus.get('status') === 'complete' ||
        jobStatus.get('status') === 'analyzing') && (
        <>
          {!!jobStatusSummary?.size && (
            <div>
              <Label>Summary</Label>
              <Divider />
              <div className='ba b--black-10 mt3 mw6 mb3'>
                <Table
                  rowGetter={getJobStatusRow}
                  rowCount={jobStatusSummary.size}
                  headerHeight={35}
                  rowHeight={33}
                >
                  <Column
                    label='Category'
                    dataKey={'category'}
                    width={220}
                    cellRenderer={({ cellData }) => startCase(cellData)}
                  />
                  <Column label='Row Count' dataKey={'count'} width={100} />
                  <Column
                    label='Progress'
                    dataKey={'progress'}
                    width={100}
                    cellRenderer={({ cellData }) => {
                      if (cellData) {
                        return `${cellData}%`
                      }
                    }}
                  />
                </Table>
              </div>
            </div>
          )}
          {jobStatusDerelictRows?.size > 0 && (
            <>
              <Divider />
              <Label>Derelict Rows</Label>
              <div className='ba b--black-10 mt3'>
                <Table
                  rowGetter={getDerelictRow}
                  rowCount={jobStatusDerelictRows.size}
                  minWidth={5000}
                >
                  <Column
                    label={'Internal Supplier ID'}
                    dataKey={'Internal Supplier ID or Vendor Number'}
                    width={200}
                  />
                  <Column
                    label={'Company Name'}
                    dataKey={'Company Name'}
                    width={200}
                  />
                  <Column label={'Reason'} dataKey={'reason'} width={400} />
                  {DerelictRowKeys?.map(key => (
                    <Column label={key} dataKey={key} width={200} />
                  ))}
                </Table>
              </div>
            </>
          )}
          <div className='mt3'>
            {completedFilePath && (
              <Button
                className='mr3'
                autoSize
                onClick={() => handleDownload(completedFilePath)}
              >
                Completed File
              </Button>
            )}
            {derelictFilePath && (
              <Button
                className='mr3'
                autoSize
                onClick={() => handleDownload(derelictFilePath)}
              >
                Derelict File
              </Button>
            )}
            {sourceFilePath && (
              <Button
                secondary
                autoSize
                onClick={() => handleDownload(sourceFilePath)}
              >
                Source File
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  ) : null
}

export default DataQualityJobDetail
