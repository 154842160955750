import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import CompanyLocationAddForm from '../../components/CompanyLocationAddForm'
import { formValueSelector } from 'redux-form/immutable'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import companySelectors from '../../../shared/selectors/companySelectors'
import { SAVE_COMPANY_INFO } from '../../actionTypes'

const formName = 'supplier/company/addCompanyLocation'
const valuesSelector = formValueSelector(formName)

export class CompanyLocationAddFormContainer extends Component {
  handleSubmit = formValues => {
    const { locations, saveCompanyInfo } = this.props

    const { isPrimary, ...newCompanyInfo } = formValues.toJS()

    const newLocations = !locations
      ? [newCompanyInfo]
      : isPrimary
      ? locations.unshift(newCompanyInfo).toJS()
      : locations.push(newCompanyInfo).toJS()

    saveCompanyInfo({
      supplier: {
        locations: newLocations
      }
    })
  }

  render() {
    const { handleSubmit, reset, address } = this.props

    return (
      <CompanyLocationAddForm
        onSubmit={handleSubmit(this.handleSubmit)}
        onCancel={reset}
        address={address}
      />
    )
  }
}

export default connectReduxForm(
  state => ({
    locations: companySelectors.getCompanySupplierField(state, 'locations'),
    address: valuesSelector(state, 'address')
  }),
  {
    saveCompanyInfo: createSubmitFormAction(formName, SAVE_COMPANY_INFO)
  },
  {
    form: formName
  },
  CompanyLocationAddFormContainer
)
