import { connect } from 'react-redux'
import orgsSelectors from '../../selectors/orgsSelectors'
import { OrgUnit } from '../../containers/OrgUnit/OrgUnit'

type Props = {
  orgUnitId: string
  name?: string
}

export default connect((state, props: Props) => {
  const orgUnit = orgsSelectors.getById(state, props.orgUnitId)

  const domains = orgUnit.get('domains')
  const logoUrl =
    orgUnit.get('logoUrl') ||
    (domains && domains.size > 0
      ? `https://logo.clearbit.com/${domains.first()}`
      : '')

  return {
    name: orgUnit.get('name') || props.name,
    logoUrl
  }
})(OrgUnit)
