import { call, select } from 'redux-saga/effects'
import suppliers from 'shared/utils/api/suppliers'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import searchSelectors from '../../selectors/searchSelectors'
import parseSupplier from '../../../../shared/utils/data/parseSupplier'
import parseCard from 'shared/utils/data/parseCard'
import mapValues from 'lodash.mapvalues'
import mapKeys from 'lodash.mapkeys'

export function* getAllChildrenSaga(action) {
  const results = yield select(searchSelectors.getResults)
  const existingHitIds = results.map(entry => entry.getIn(['supplier', 'id']))
  const response = yield call(suppliers.searchSuppliers, {
    filter: { parentOrg: action.payload }
  })

  const newHits = response.hits.reduce((newHits, hit) => {
    if (!existingHitIds.includes(hit.supplier.id)) {
      newHits.push(Object.assign({}, hit, { additional: true }))
    }
    return newHits
  }, [])

  let cardsById = {}
  let cardsIdsBySupplierByUser = {}
  let orgUnitsById = {}
  newHits.forEach(hit => {
    cardsById = Object.assign({}, cardsById, mapValues(hit.cards, parseCard))
    cardsIdsBySupplierByUser = Object.assign({}, cardsIdsBySupplierByUser, {
      [hit.supplier.id]: mapValues(
        mapKeys(hit.cards, card => card.parents.User),
        card => card.id
      )
    })
    orgUnitsById[hit.supplier.id] = parseSupplier(hit.supplier)
  })

  return yield {
    results: newHits,
    cardsById,
    cardsIdsBySupplierByUser,
    orgUnitsById
  }
}

export default createApiRequestSaga(getAllChildrenSaga)
