import * as api from '../api'
import parseUserNote from '../../data/parseUserNote'
import parseVetBS from '../../data/parseVetBS'
import parseCollaboration from '../../data/parseCollaboration'
import parseUser from '../../data/parseUser'
import apiRoutes from '../../../routes/apiRoutes'
import mapValues from 'lodash.mapvalues'

const apiRoute = `${apiRoutes.authService}/vets`

export const uploadProjectBackground = ({ vetId, projectBackground }) =>
  api.postFile(`${apiRoute}/${vetId}/projectBackgroundFile`, {
    document: projectBackground
  })
export const removeProjectBackground = ({ vetId, fileName }) =>
  api.remove(`${apiRoute}/${vetId}/projectBackgroundFile/${fileName}`)

export const uploadDocument = ({ vetId, file }) =>
  api.postFile(`${apiRoute}/${vetId}/documentFile`, { document: file })
export const removeDocument = ({ vetId, fileName }) =>
  api.remove(`${apiRoute}/${vetId}/documentFile/${fileName}`)

export const uploadResponseFile = ({ vetBuyerSupplierId, responseFile }) =>
  api.postFile(`${apiRoute}/${vetBuyerSupplierId}/responseFile`, {
    document: responseFile
  })
export const removeResponseFile = ({ vetBuyerSupplierId, fileName }) =>
  api.remove(`${apiRoute}/${vetBuyerSupplierId}/responseFile/${fileName}`)

export const updateVetBuyerSupplierFlags = ({ vetBuyerSupplierId, ...rest }) =>
  api.put(`${apiRoute}/${vetBuyerSupplierId}/flags`, rest)

export const getOpenVets = () => {
  return api.get(`${apiRoute}`).then(({ byId, sortedByModified }) => ({
    sortedByModified,
    byId: mapValues(
      byId,
      ({ vetDetails: { decisionDate, ...restDetails }, created, ...rest }) => {
        return {
          vetDetails: {
            decisionDate: decisionDate && new Date(decisionDate),
            ...restDetails
          },
          created: {
            date: new Date(created.date),
            user: created.user
          },
          ...rest
        }
      }
    )
  }))
}

export const getVetBSsForSupplier = supplierId => {
  return api.get(`${apiRoute}/${supplierId}/list`)
}

export const createNewVet = props => {
  return api.post(`${apiRoute}`, { ...props })
}

export const getVet = vetId => {
  return api
    .get(`${apiRoute}/${vetId}`)
    .then(({ vetBuyerSupplier, ...rest }) => ({
      vetBuyerSupplier: mapValues(
        vetBuyerSupplier,
        ({ vetBS, users, email, ...rest }) => {
          return {
            vetBS: parseVetBS(vetBS),
            users: mapValues(users, parseUser),
            email:
              email &&
              email.reduce((result, email) => {
                const {
                  id,
                  created,
                  user,
                  active,
                  reference,
                  response,
                  status,
                  type
                } = email

                if (user) {
                  if (!result[user]) {
                    result[user] = []
                  }
                  result[user].push({
                    id,
                    created,
                    user,
                    active,
                    reference,
                    response,
                    status,
                    type
                  })
                }
                return result
              }, {}),
            ...rest
          }
        }
      ),
      ...rest
    }))
}

export const updateVet = ({ vetId, ...rest }) => {
  return api.put(`${apiRoute}/${vetId}`, {
    ...rest
  })
}

export const close = vetId => api.post(`${apiRoute}/${vetId}/close`)
export const reopen = vetId => api.post(`${apiRoute}/${vetId}/reopen`)
export const cancel = vetId => api.post(`${apiRoute}/${vetId}/cancel`)
export const remove = vetId => api.remove(`${apiRoute}/buyer/${vetId}`)

export const addVetSupplier = ({ vetId, supplierIds }) => {
  return api
    .post(`${apiRoute}/${vetId}/suppliers`, { supplierIds })
    .then(vetBuyerSupplier =>
      mapValues(vetBuyerSupplier, ({ vetBS, users, ...rest }) => {
        return {
          vetBS: parseVetBS(vetBS),
          users: mapValues(users, parseUser),
          ...rest
        }
      })
    )
}

export const approveVetSupplier = ({ vetBuyerSupplierIds }) => {
  return api
    .post(`${apiRoute}/approve`, { vetBuyerSupplierIds })
    .then(vetBuyerSupplier =>
      mapValues(vetBuyerSupplier, ({ vetBS, users, ...rest }) => {
        return {
          vetBS: parseVetBS(vetBS),
          users: mapValues(users, parseUser),
          ...rest
        }
      })
    )
}

export const inviteVetSupplier = ({ vetBuyerSupplierIds }) => {
  return api
    .post(`${apiRoute}/invite`, { vetBuyerSupplierIds })
    .then(vetBuyerSupplier =>
      mapValues(vetBuyerSupplier, ({ vetBS, users, ...rest }) => {
        return {
          vetBS: parseVetBS(vetBS),
          users: mapValues(users, parseUser),
          ...rest
        }
      })
    )
}

export const removeVetSupplier = ({ vetBuyerSupplierId }) => {
  return api.remove(`${apiRoute}/${vetBuyerSupplierId}`)
}

export const addCriteria = ({ vetId, criticalCriteria }) => {
  return api.post(`${apiRoute}/${vetId}/criteria`, { criticalCriteria })
}

export const removeCriteria = ({ vetId, criticalCriteria }) => {
  return api.remove(`${apiRoute}/${vetId}/criteria`, { criticalCriteria })
}

export const updateCriteria = ({
  vetId,
  oldCriticalCriteria,
  newCriticalCriteria
}) => {
  return api.put(`${apiRoute}/${vetId}/criteria`, {
    oldCriticalCriteria,
    newCriticalCriteria
  })
}

export const addTeamMember = ({ vetId, userId, message }) => {
  return api.post(`${apiRoute}/${vetId}/team/${userId}`, { message })
}

export const removeTeamMember = ({ vetId, userId }) => {
  return api.remove(`${apiRoute}/${vetId}/team/${userId}`)
}

export const updateTeamMember = ({ vetId, ...rest }) => {
  return api.put(`${apiRoute}/${vetId}/team`, { ...rest })
}

export const addSupplierContact = ({ vetBuyerSupplierId, userId }) => {
  return api.post(`${apiRoute}/buyer/${vetBuyerSupplierId}/addContact`, {
    userId
  })
}

export const removeSupplierContact = ({ vetBuyerSupplierId, userId }) => {
  return api.post(`${apiRoute}/buyer/${vetBuyerSupplierId}/removeContact`, {
    userId
  })
}

export const updateSupplierContact = ({ vetBuyerSupplierId, ...rest }) => {
  return api.put(`${apiRoute}/buyer/${vetBuyerSupplierId}/contact`, { ...rest })
}

export const addBuyerSupplierNotes = ({ vetBuyerSupplierId, note }) => {
  return api
    .post(`${apiRoute}/${vetBuyerSupplierId}/buyerSupplierNotes`, { note })
    .then(response => parseUserNote(response))
}

export const updateBuyerSupplierNotes = ({
  vetBuyerSupplierId,
  ...noteObj
}) => {
  return api
    .put(`${apiRoute}/${vetBuyerSupplierId}/buyerSupplierNotes`, { ...noteObj })
    .then(response => parseUserNote(response))
}

export const removeBuyerSupplierNotes = ({
  vetBuyerSupplierId,
  ...noteObj
}) => {
  return api
    .remove(`${apiRoute}/${vetBuyerSupplierId}/buyerSupplierNotes`, {
      ...noteObj
    })
    .then(response => parseUserNote(response))
}

export const upVoteVetSupplier = ({ vetBuyerSupplierId }) => {
  return api.put(`${apiRoute}/${vetBuyerSupplierId}/upVote`)
}

export const downVoteVetSupplier = ({ vetBuyerSupplierId }) => {
  return api.put(`${apiRoute}/${vetBuyerSupplierId}/downVote`)
}

export const chooseVetBuyerSupplier = ({
  vetBuyerSupplierId,
  notes,
  nextSteps
}) => {
  return api
    .post(`${apiRoute}/${vetBuyerSupplierId}/choose`, { notes, nextSteps })
    .then(response => parseCollaboration(response))
}

export const rejectVetBuyerSupplier = ({ vetBuyerSupplierId, notes }) => {
  return api
    .post(`${apiRoute}/${vetBuyerSupplierId}/reject`, { notes })
    .then(response => parseCollaboration(response))
}

export const addVetAddition = ({ vetId, ...rest }) =>
  api.postFile(`${apiRoute}/${vetId}/addition`, rest)

export const cancelVetAddition = ({ vetId, index }) =>
  api.post(`${apiRoute}/${vetId}/cancelAddition`, {
    index
  })

export const getVetQuestionsForBuyer = vetId =>
  api.get(`${apiRoute}/${vetId}/buyerQuestions`)

export const updateVetQuestionResponse = ({
  vetBuyerSupplierId,
  questionId,
  response,
  isPublic,
  description,
  buyerEditing
}) =>
  api.post(`${apiRoute}/${vetBuyerSupplierId}/questionResponse`, {
    questionId,
    response,
    isPublic,
    description,
    buyerEditing
  })

export const getVetQuestionsForSupplier = vetBuyerSupplierId =>
  api.get(`${apiRoute}/${vetBuyerSupplierId}/supplierQuestions`)

export const addVetQuestion = ({
  vetBuyerSupplierId,
  description,
  isPublic,
  createdInOrgUnit
}) =>
  api.post(`${apiRoute}/${vetBuyerSupplierId}/question`, {
    question: description,
    isPublic,
    createdInOrgUnit
  })

export const updateVetQuestion = ({
  vetBuyerSupplierId,
  questionId,
  description,
  isPublic
}) =>
  api.put(`${apiRoute}/${vetBuyerSupplierId}/question`, {
    id: questionId,
    question: description,
    isPublic
  })

export const removeVetQuestion = ({ vetBuyerSupplierId, questionId }) =>
  api.remove(`${apiRoute}/${vetBuyerSupplierId}/question`, {
    id: questionId
  })
