import React, { Component } from 'react'
import { connect } from 'react-redux'
import companySelectors from '../../../shared/selectors/companySelectors'
import statsSelectors from '../../../shared/selectors/statsSelectors'
import Text from 'shared/components/Text'
import dateFormat from 'shared/utils/dateFormat'
import classNames from 'classnames'
import WidgetWrapper from '../../components/WidgetWrapper'
import ViewDetails from '../ViewDetails'
import AppearanceDetails from '../AppearanceDetails'
import Button from 'shared/components/Button'
import Hidden from '@material-ui/core/Hidden'
import PremiumBadge from 'shared/components/PremiumBadge'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'

const styles = {
  widgetInnerBox: 'ph2-ns bg-white br2 ba b--black-10',
  widgetInnerCell: 'fl-ns w-100 w-third-ns pa2',
  statValue: 'teal f2 f3-m lh-copy',
  borderBottom: 'bb-ns b--black-10',
  borderBottomRight: 'br-ns bb-ns b--black-10',
  borderRight: 'br-ns b--black-10',
  borderSides: 'br-ns bl-ns b--black-10'
}

type Props = {
  isPremium: boolean
  numViews: number
  numSearches: number
  hasViewDetails: boolean
  hasAppearanceDetails: boolean
  lastView: Date
}

type State = {
  showAdditionStats: string | null
}

export class ViewsStatistics extends Component<Props, State> {
  state = {
    showAdditionStats: null
  }

  toggleShowMore = section => {
    this.setState({
      showAdditionStats:
        this.state.showAdditionStats === section ? null : section
    })
  }

  render() {
    const {
      numViews,
      numSearches,
      lastView,
      isPremium,
      hasViewDetails,
      hasAppearanceDetails
    } = this.props
    const { showAdditionStats } = this.state

    return numViews || numSearches || lastView ? (
      <WidgetWrapper
        title={
          <FormattedMessage
            id='ViewsStatistics.CompanyProfileStatistics'
            defaultMessage='Company Profile Statistics'
          />
        }
      >
        <div className={styles.widgetInnerBox}>
          <div className='cf'>
            <div
              className={classNames(
                styles.widgetInnerCell,
                `${
                  showAdditionStats && showAdditionStats !== 'viewDetails'
                    ? styles.borderBottom
                    : ''
                }`
              )}
            >
              <div className={styles.statValue}>
                {isPremium && showAdditionStats === 'viewDetails' && (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id='ViewsStatistics.PremiumContentDeliveredAsPartOfYourTealbookSubscription'
                        defaultMessage='Premium content delivered as part of your TealBook subscription'
                      />
                    }
                  >
                    <PremiumBadge className='fr' />
                  </Tooltip>
                )}
                {numViews.toLocaleString()}
              </div>
              <div className='flex justify-between'>
                <Text style={{ lineHeight: '2em' }}>
                  <FormattedMessage
                    id='ViewsStatistics.ProfileViewText'
                    defaultMessage='Profile views'
                  />
                </Text>
                {isPremium && (numViews > 0 || hasViewDetails) && (
                  <Button
                    className='fr'
                    variant='text'
                    size='small'
                    autoSize
                    label={
                      showAdditionStats === 'viewDetails' ? (
                        <FormattedMessage
                          id='ViewsStatistics.ShowLessLabel'
                          defaultMessage='Show Less'
                        />
                      ) : (
                        <FormattedMessage
                          id='ViewsStatistics.ShowMoreLabel'
                          defaultMessage='Show More'
                        />
                      )
                    }
                    onClick={() => this.toggleShowMore('viewDetails')}
                  />
                )}
              </div>
            </div>
            <Hidden mdUp>
              {showAdditionStats === 'viewDetails' && <ViewDetails />}
            </Hidden>
            <div
              className={classNames(
                styles.widgetInnerCell,
                styles.borderSides,
                `${
                  showAdditionStats && showAdditionStats !== 'appearanceDetails'
                    ? styles.borderBottom
                    : ''
                }`
              )}
            >
              <div className={styles.statValue}>
                {isPremium && showAdditionStats === 'appearanceDetails' && (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id='ViewsStatistics.PremiumContentDelivered'
                        defaultMessage='Premium content delivered as part of your TealBook subscription'
                      />
                    }
                  >
                    <PremiumBadge className='fr' />
                  </Tooltip>
                )}
                {numSearches.toLocaleString()}
              </div>
              <div className='flex justify-between'>
                <Text style={{ lineHeight: '2em' }}>
                  <FormattedMessage
                    id='ViewsStatistics.AppearnacesText'
                    defaultMessage='Appearances'
                  />
                </Text>
                {(numSearches > 9 || hasAppearanceDetails) && (
                  <Button
                    className='fr'
                    variant='text'
                    size='small'
                    autoSize
                    label={
                      showAdditionStats === 'appearanceDetails' ? (
                        <FormattedMessage
                          id='ViewsStatistics.ShowLessLabel'
                          defaultMessage='Show Less'
                        />
                      ) : (
                        <FormattedMessage
                          id='ViewsStatistics.ShowMoreLabel'
                          defaultMessage='Show More'
                        />
                      )
                    }
                    onClick={() => this.toggleShowMore('appearanceDetails')}
                  />
                )}
              </div>
            </div>
            <Hidden mdUp>
              {showAdditionStats === 'appearanceDetails' && (
                <AppearanceDetails />
              )}
            </Hidden>
            <div
              className={classNames(
                styles.widgetInnerCell,
                `${showAdditionStats ? styles.borderBottom : ''}`
              )}
            >
              <div className={styles.statValue}>
                {lastView ? dateFormat(lastView) : 'N/A'}
              </div>
              <Text style={{ lineHeight: '2em' }}>
                <FormattedMessage
                  id='ViewsStatistics.LastViewedText'
                  defaultMessage='Last viewed'
                />
              </Text>
            </div>
          </div>
          <Hidden smDown>
            {showAdditionStats === 'viewDetails' && <ViewDetails />}
            {showAdditionStats === 'appearanceDetails' && <AppearanceDetails />}
          </Hidden>
        </div>
      </WidgetWrapper>
    ) : null
  }
}

export default connect(state => {
  const profileStats = statsSelectors.getProfileStats(state)
  const searchStats = statsSelectors.getSearchStats(state)
  const viewDetails = statsSelectors.getBuyerViewDetails(state)
  const appearanceDetails = statsSelectors.getAppearanceDetails(state)

  return {
    isPremium: companySelectors.isPremium(state),
    hasViewDetails: !!viewDetails && viewDetails.size > 0,
    hasAppearanceDetails: !!appearanceDetails && appearanceDetails.size > 0,
    numViews: (profileStats && profileStats.views) || 0,
    numSearches: (searchStats && searchStats.views) || 0,
    lastView: profileStats && profileStats.lastView
  }
})(ViewsStatistics)
