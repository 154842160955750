import React, { Component } from 'react'
import { connect } from 'react-redux'
import List from 'shared/components/List'
import profileSelectors from '../../selectors/profileSelectors'
import ConnectionItemContainer from '../ConnectionItemContainer'

type Props = {
  cards: Array<string>
}

export class MutualConnections extends Component<Props> {
  render() {
    const { cards } = this.props

    return (
      <List>
        {cards &&
          cards.map(cardId => (
            <ConnectionItemContainer key={cardId} cardId={cardId} />
          ))}
      </List>
    )
  }
}

export default connect(state => ({
  cards: profileSelectors.getConnections(state)
}))(MutualConnections)
