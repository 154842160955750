import React, { useState, ChangeEvent, MouseEvent, useEffect } from 'react'
import Text from 'shared/components/Text'
import Select from 'shared/components/Select'
import Input from 'shared/components/Input'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Button from 'shared/components/Button'
import { saveWebhookConfig } from '../../actions'
import { useDispatch, connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'

const messages = defineMessages({
  batchEnrichment: {
    id: 'WebhookConfig.BatchEnrichment',
    defaultMessage: 'Batch Enrichment'
  },
  punchout: {
    id: 'WebhookConfig.Punchout',
    defaultMessage: 'Punchout'
  }
})

type Props = {
  webhook: 'batchEnrichment' | 'punchout'
  securityType?: 'none' | 'oauth2'
  url?: string
  clientId?: string
  clientSecret?: string
  grantType?: string
}

export const WebhookConfig = ({
  webhook,
  securityType = 'none',
  url = '',
  clientId = '',
  clientSecret = '',
  grantType = ''
}: Props) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [currentSecurityType, setCurrentSecurityType] = useState<
    'none' | 'oauth2'
  >(securityType)
  const [currentUrl, setCurrentUrl] = useState<string>(url)
  const [currentClientId, setCurrentClientId] = useState<string>(clientId)
  const [currentClientSecret, setCurrentClientSecret] = useState<string>(
    clientSecret
  )
  const [currentGrantType, setCurrentGrantType] = useState<string>(grantType)

  useEffect(() => {
    setCurrentSecurityType(securityType)
    setCurrentUrl(url)
    setCurrentClientId(clientId)
    setCurrentClientSecret(clientSecret)
    setCurrentGrantType(grantType)
  }, [securityType, url, clientId, clientSecret, grantType])

  const handleSecurityChange = (e: ChangeEvent<HTMLSelectElement>) =>
    setCurrentSecurityType(e.currentTarget.value as 'none' | 'oauth2')

  const handleSaveClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (currentSecurityType === 'none') {
      dispatch(
        saveWebhookConfig({
          webhook,
          securityType: currentSecurityType,
          config: {
            url: '',
            clientId: '',
            clientSecret: '',
            grantType: ''
          }
        })
      )
    } else {
      dispatch(
        saveWebhookConfig({
          webhook,
          securityType: currentSecurityType,
          config: {
            url: currentUrl,
            clientId: currentClientId,
            clientSecret: currentClientSecret,
            grantType: currentGrantType
          }
        })
      )
    }
  }

  return (
    <div className='db w-100 mv3'>
      <div className='dib w-25 v-top'>
        <Text>{intl.formatMessage(messages[webhook])}</Text>
      </div>
      <div className='dib w-25 v-top'>
        <Select
          aria-label='Security Type'
          value={currentSecurityType}
          onChange={handleSecurityChange}
        >
          <FormattedMessage
            id='WebhookConfig.SecurityNone'
            defaultMessage='None'
          >
            {label => <option value='none'>{label}</option>}
          </FormattedMessage>
          <FormattedMessage
            id='WebhookConfig.SecurityOAuth2'
            defaultMessage='OAuth 2'
          >
            {label => <option value='oauth2'>{label}</option>}
          </FormattedMessage>
        </Select>
      </div>
      <div className='dib w-50 v-top'>
        {currentSecurityType === 'oauth2' ? (
          <>
            <div className='db w-100 mb2'>
              <div className='dib w-20'>
                <label htmlFor='url' className='lh-copy f7 fw4 mid-gray ma0'>
                  <FormattedMessage
                    id='WebhookConfig.Url'
                    defaultMessage='URL'
                  />
                </label>
              </div>
              <div className='dib w-80'>
                <Input
                  name='url'
                  value={currentUrl}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCurrentUrl(e.currentTarget.value)
                  }
                />
              </div>
            </div>
            <div className='db w-100 mb2'>
              <div className='dib w-20'>
                <label
                  htmlFor='clientId'
                  className='lh-copy f7 fw4 mid-gray ma0'
                >
                  <FormattedMessage
                    id='WebhookConfig.ClientId'
                    defaultMessage='Client ID'
                  />
                </label>
              </div>
              <div className='dib w-80'>
                <Input
                  name='clientId'
                  value={currentClientId}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCurrentClientId(e.currentTarget.value)
                  }
                />
              </div>
            </div>
            <div className='db w-100 mb2'>
              <div className='dib w-20'>
                <label
                  htmlFor='clientSecret'
                  className='lh-copy f7 fw4 mid-gray ma0'
                >
                  <FormattedMessage
                    id='WebhookConfig.ClientSecret'
                    defaultMessage='Client Secret'
                  />
                </label>
              </div>
              <div className='dib w-80'>
                <Input
                  name='clientSecret'
                  value={currentClientSecret}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCurrentClientSecret(e.currentTarget.value)
                  }
                />
              </div>
            </div>
            <div className='db w-100 mb2'>
              <div className='dib w-20'>
                <label
                  htmlFor='grantType'
                  className='lh-copy f7 fw4 mid-gray ma0'
                >
                  <FormattedMessage
                    id='WebhookConfig.GrantType'
                    defaultMessage='Grant Type'
                  />
                </label>
              </div>
              <div className='dib w-80'>
                <Input
                  name='grantType'
                  value={currentGrantType}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCurrentGrantType(e.currentTarget.value)
                  }
                />
              </div>
            </div>
          </>
        ) : (
          <Text className='pv2'>&mdash;</Text>
        )}
      </div>
      <div className='tr'>
        <Button autoSize onClick={handleSaveClick}>
          <FormattedMessage id='Save' defaultMessage='Save' />
        </Button>
      </div>
    </div>
  )
}

export default connect(
  (state: RootState, props: { webhook: 'batchEnrichment' | 'punchout' }) => {
    const webhookConfig = settingsSelectors.getWebhookByKey(
      state,
      props.webhook
    )

    return {
      securityType: webhookConfig?.get('securityType'),
      url: webhookConfig?.getIn(['config', 'url']),
      clientId: webhookConfig?.getIn(['config', 'clientId']),
      clientSecret: webhookConfig?.getIn(['config', 'clientSecret']),
      grantType: webhookConfig?.getIn(['config', 'grantType'])
    }
  }
)(WebhookConfig)
