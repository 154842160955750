import React, { Component } from 'react'
import { List, RecordOf } from 'immutable'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import PageSection from 'shared/components/PageSection'
import Page from 'shared/components/Page'
import VetInvitationOverviewContainer from '../../containers/VetInvitationOverviewContainer'
import AddCriteriaForm from '../AddCriteriaForm'
import CriticalCriteriaEditForm from '../CriticalCriteriaEditForm'
import VetSupplierListContainer from '../../containers/VetSupplierListContainer'
import { FormattedMessage } from 'react-intl'
import VetInfoCardContainer from '../../containers/VetInfoCardContainer'
import PricingSection from './PricingSection'
import VetAdditionsContainer from '../../containers/VetAdditionsContainer'
import UploadedFile from 'shared/models/UploadedFile'
import ScrollToPosition from 'shared/components/ScrollToPosition'

export type VetInvitationProps = {
  id: string
  description: string
  budget: string
  location: string
  responseDate: string
  decisionDate: string
  detailedReponseRequested: boolean
  responseInstructions: string
  anonymousInvitation: string
  inviteSuppliers: boolean
  document: List<RecordOf<UploadedFile>>
  termsAndConditionsSource: string
  termsAndConditions: string
  readonly: boolean
  criticalCriteria: List<string>
}

type Props = {
  readonly: boolean
  vet: RecordOf<VetInvitationProps>
  onRemoveAttachment: () => void
  onUpdateVet: (changesToVet: string) => void
  onRemoveCriteria: (removeCriteria: {
    vetId: string
    index: number
    criticalCriteria: string
  }) => void
}

class VetInvitationsPage extends Component<Props> {
  render() {
    const { vet, onRemoveCriteria, readonly } = this.props
    if (!vet.id) return null
    return (
      <Page
        title={
          <FormattedMessage
            id='VetInvitationsPage.Details'
            defaultMessage='Details'
          />
        }
      >
        <ScrollToPosition />
        <Main>
          <VetInvitationOverviewContainer
            vetId={vet.id}
            description={vet.description}
            budget={vet.budget}
            location={vet.location}
            responseDate={
              vet.responseDate ? new Date(vet.responseDate) : undefined
            }
            decisionDate={
              vet.decisionDate ? new Date(vet.decisionDate) : undefined
            }
            detailedReponseRequested={vet.detailedReponseRequested}
            responseInstructions={vet.responseInstructions}
            inviteSuppliers={vet.inviteSuppliers}
            document={vet.document}
            termsAndConditionsSource={vet.termsAndConditionsSource}
            termsAndConditions={vet.termsAndConditions}
          />

          {vet.inviteSuppliers &&
            (readonly &&
            vet.criticalCriteria &&
            vet.criticalCriteria.size === 0 ? null : (
              <PageSection
                noPadding
                title={
                  <FormattedMessage
                    id='VetInvitationsPage.CriticalCriteria'
                    defaultMessage='Critical Criteria'
                  />
                }
              >
                <div
                  className={vet.criticalCriteria.size > 0 ? 'ph3-5 pb3-5' : ''}
                >
                  {/* {
                      vet.criticalCriteria.map((c, index) => (
                        <div className='pt3-5' key={`${index}${c}`}>
                          <p className='lh-copy f7 fw4 mid-gray ma0'>
                            {index + 1}. {c}
                            {!readonly &&
                              <Button
                                className='fr pa0 pl3'
                                style={{ color: '#FF4136' }}
                                autoSize
                                secondary
                                label={<FormattedMessage id='CriticalCriteriaEditForm.Remove' defaultMessage='Remove' />}
                                onClick={() => onRemoveCriteria({ vetId: vet.id, index, criticalCriteria: c })}
                              />
                            }
                          </p>
                        </div>
                      ))
                    } */}
                  <CriticalCriteriaEditForm
                    vetId={vet.id}
                    criticalCriteria={vet.criticalCriteria}
                    onRemoveCriteria={onRemoveCriteria}
                    vetIsReadonly={readonly}
                  />
                </div>

                {!readonly && <AddCriteriaForm vetId={vet.id} />}
              </PageSection>
            ))}

          {vet.inviteSuppliers && <VetAdditionsContainer />}

          {vet.inviteSuppliers && <PricingSection />}
        </Main>

        <Aside className='mt4-l'>
          {vet.inviteSuppliers && <VetInfoCardContainer forInvitation />}
          {vet.inviteSuppliers && (
            <div style={{ paddingTop: 7 }}>
              <VetSupplierListContainer />
            </div>
          )}
        </Aside>
      </Page>
    )
  }
}

export default VetInvitationsPage
