import { Map, List, fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { LOGOUT } from 'shared/actionTypes'

const filterOptionsReducer = (state = Map({}), action) => {
  let key, value, meta
  switch (action.type) {
    case actionTypes.SET_FILTER_OPTIONS:
      return fromJS(action.payload)

    case actionTypes.SET_TAB_FILTER:
      return state.merge(
        fromJS({
          sector: !!action.payload ? [action.payload] : [],
          subsector: [],
          group: []
        })
      )

    case actionTypes.TOGGLE_FILTER_OPTION:
      key = action.payload.key
      value = action.payload.value
      const keyValue = state.get(key)
      const index = keyValue && keyValue.indexOf(value)
      if (key === 'country') {
        // assume only allow 1 location to be filter
        return keyValue && index !== -1
          ? state.merge(
              fromJS({
                country: [],
                state: [],
                city: []
              })
            )
          : state.merge(
              fromJS({
                country: [value],
                state: [],
                city: []
              })
            )
      } else if (key === 'state') {
        // assume only allow 1 location to be filter
        const location = value.split('::')

        return keyValue && index !== -1
          ? state.merge(
              fromJS({
                state: [],
                city: []
              })
            )
          : state.merge(
              fromJS({
                country: [location[0]],
                state: [value],
                city: []
              })
            )
      } else if (key === 'city') {
        // assume only allow 1 location to be filter
        const location = value.split('::')

        return keyValue && index !== -1
          ? state.set(key, List([]))
          : state.merge(
              fromJS({
                country: [location[0]],
                state: [`${location[0]}::${location[1]}`],
                city: [value]
              })
            )
      } else if (key === 'sector') {
        return keyValue && index !== -1
          ? state.merge(
              fromJS({
                sector: [],
                subsector: [],
                group: []
              })
            )
          : state.merge(
              fromJS({
                sector: [value],
                subsector: [],
                group: []
              })
            )
      } else if (key === 'subsector') {
        return keyValue && index !== -1
          ? state.merge(
              fromJS({
                subsector: [],
                group: []
              })
            )
          : state.merge(
              fromJS({
                subsector: [value],
                group: []
              })
            )
      } else if (key === 'scope') {
        return index !== -1 ? state.set(key, 'All') : state.set(key, value)
      } else if (!keyValue) {
        return state.set(key, List([value]))
      } else {
        return index !== -1
          ? state.set(key, state.get(key).delete(index))
          : state.set(key, state.get(key).push(value))
      }

    case actionTypes.CHANGE_FILTER_OPTION:
      key = action.payload.key
      value = action.payload.value
      return state.set(key, fromJS(value))

    case actionTypes.MERGE_FILTER_OPTION:
      return state.merge(key, fromJS(action.payload))

    case actionTypes.ADD_FILTER_OPTION:
      if (state.has(action.payload.key)) {
        const value = state.get(action.payload.key)
        const newValue =
          typeof action.payload.value === 'string'
            ? value.push(action.payload.value)
            : value.concat(fromJS(action.payload.value))

        return state.set(action.payload.key, newValue.toSet().toList())
      } else {
        const newValue =
          typeof action.payload.value === 'string'
            ? List.of(action.payload.value)
            : List(action.payload.value)
        return state.set(action.payload.key, newValue.toSet().toList())
      }

    case actionTypes.REMOVE_FILTER_OPTION:
      meta = action.meta
      if (action.payload.value) {
        const value = state.get(action.payload.key)

        if (!(value instanceof List)) {
          return state.remove(action.payload.key)
        } else {
          const newValue = value.filter(v => {
            return meta && meta.fuzzyMatch
              ? v.indexOf(`${meta.fuzzyMatch}`) === -1 &&
                  v !== action.payload.value
              : v !== action.payload.value
          })
          if (newValue.size > 0) {
            return state.set(action.payload.key, newValue)
          } else {
            return state.remove(action.payload.key)
          }
        }
      } else {
        return state.remove(action.payload.key)
      }

    case LOGOUT:
      return Map({})

    default:
      return state
  }
}

export default filterOptionsReducer
