import React, { ChangeEvent, useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Page from 'shared/components/Page'
import Paper from 'shared/components/Paper'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import insightsSelectors from 'buyer/Insights/store/insightsSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import Table from 'shared/components/Table'
import { Column, TableCellProps } from 'react-virtualized'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import dateFormat from 'shared/utils/dateFormat'
import parsePath from 'shared/utils/parsePath'
import paths from '../../routes/paths'
import Link from 'shared/components/Link'
import moment from 'moment'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import { getSnapshotList, bulkDeleteSnapshots } from '../../store/actions'
import Loading from 'shared/components/Loading'
import classnames from 'classnames'

const ReportSnapshotList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const intl = useIntl()
  const list = useSelector(insightsSelectors.getSavedReports)
  const isLoading = useSelector(insightsSelectors.isSnapshotsLoading)
  const deleting = useSelector(insightsSelectors.deletingSnapshots)
  const users = useSelector(usersSelectors.getUsers)
  const [selectedReports, setSelectedReports] = useState<string[]>([])
  const [confirmDeletion, setConfirmDeletion] = useState<boolean>(false)
  const [sortBy, setSortBy] = useState<string>('name')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')

  useEffect(() => {
    dispatch(getSnapshotList())
  }, [dispatch])

  const sortedList = useMemo(() => {
    return (
      list &&
      list.sort((s1, s2) => {
        const direction = sortDirection === 'ASC' ? 1 : -1
        let value1, value2
        if (sortBy === 'user') {
          const user1 = users?.get(s1[sortBy])
          const user2 = users?.get(s2[sortBy])
          value1 =
            user1 &&
            `${user1.get('firstName')} ${user1.get('firstName')}`.toLowerCase()
          value2 =
            user2 &&
            `${user2.get('firstName')} ${user2.get('firstName')}`.toLowerCase()
        } else {
          value1 =
            typeof s1[sortBy] === 'string'
              ? s1[sortBy].toLowerCase()
              : s1[sortBy]
          value2 =
            typeof s2[sortBy] === 'string'
              ? s2[sortBy].toLowerCase()
              : s2[sortBy]
        }
        return value1 > value2 ? 1 * direction : -1 * direction
      })
    )
  }, [list, users, sortBy, sortDirection])

  const handleSortChange = ({ sortBy: by, sortDirection: direction }) => {
    setSortBy(by)
    setSortDirection(direction)
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const value = e.currentTarget.value
    const index = selectedReports.indexOf(value)
    if (index !== -1) {
      selectedReports.splice(index, 1)
      setSelectedReports([...selectedReports])
    } else {
      setSelectedReports([...selectedReports, value])
    }
  }

  const handleCompare = () => {
    // determine which report goes first based on the reporting period and date created
    // earlier goes first. if same period (reportingPeriod), use created date
    const report1 = list.find(report => report.id === selectedReports[0])
    const report2 = list.find(report => report.id === selectedReports[1])
    const report1Period = report1?.reportingPeriod?.join('-')
    const report2Period = report2?.reportingPeriod?.join('-')
    const isSamePeriod = report1Period === report2Period
    let firstReportId, secondReportId
    if (isSamePeriod) {
      const isBefore = moment(report1?.date).isBefore(moment(report2?.date))
      firstReportId = isBefore ? report1?.id : report2?.id
      secondReportId = isBefore ? report2?.id : report1?.id
    } else {
      const isBefore = report1Period < report2Period
      firstReportId = isBefore ? report1?.id : report2?.id
      secondReportId = isBefore ? report2?.id : report1?.id
    }

    history.push(
      parsePath(paths.reportSnapshotCompare, {
        report1: firstReportId,
        report2: secondReportId
      })
    )
  }

  const handleDelete = () => {
    dispatch(bulkDeleteSnapshots(selectedReports))

    setSelectedReports([])
    setConfirmDeletion(false)
  }

  const renderCheckboxCell = ({ cellData, rowData }: TableCellProps) => {
    const value = cellData
    return (
      <input
        type='checkbox'
        value={value}
        checked={selectedReports.includes(value)}
        onChange={handleCheckboxChange}
        disabled={!!deleting?.get(value)}
      />
    )
  }

  const renderCurrencyCell = ({ cellData, rowData }: TableCellProps) => {
    const id = rowData.id
    const isDeleting = !!deleting?.get(id)

    return (
      <Text secondary={isDeleting} className={isDeleting ? 'strike' : ''}>
        {intl.formatNumber(cellData || 0, {
          style: 'currency',
          signDisplay: 'auto',
          currency: 'USD',
          maximumFractionDigits: 0
        })}
      </Text>
    )
  }

  const renderTextCell = ({ cellData, rowData, dataKey }: TableCellProps) => {
    const url = parsePath(paths.reportSnapshotView, {
      report1: rowData.id
    })
    const id = rowData.id
    const isDeleting = !!deleting?.get(id)
    let value = ''
    switch (dataKey) {
      case 'user':
        const user = users?.get(cellData)
        value = user ? `${user.get('firstName')} ${user.get('lastName')}` : ''
        break
      case 'date':
        value = dateFormat(cellData, undefined, true)
        break
      case 'reportingPeriod':
        value =
          Array.isArray(cellData) &&
          cellData.length > 0 &&
          (cellData[0] === cellData[1]
            ? cellData[0]
            : `${cellData[0]} - ${cellData[1]}`)
        break
      default:
        value = cellData
        break
    }

    const textClassName = classnames({
      strike: isDeleting,
      underline: !isDeleting && dataKey === 'name'
    })

    return (
      <Text secondary={isDeleting} className={textClassName}>
        {!isDeleting && dataKey === 'name' ? (
          <Link className='underline' to={url}>
            {value}
          </Link>
        ) : (
          value
        )}
      </Text>
    )
  }

  return (
    <Page
      title={
        <FormattedMessage
          id='ReportSnapshotList.SavedReports'
          defaultMessage='Saved Reports'
        />
      }
    >
      <ScrollToPosition />
      <Paper>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className='mv3 flex items-center justify-between'>
              <div className='mb0 f5 mid-gray fw6 flex items-center justify-between pr3'>
                <FormattedMessage
                  id='ReportSnapshotList.Reports'
                  defaultMessage='Reports'
                />
              </div>
              <div>
                <Button
                  aria-label='Compare'
                  size='large'
                  autoSize
                  disabled={selectedReports.length !== 2}
                  onClick={handleCompare}
                >
                  <FormattedMessage
                    id='ReportSnapshotList.Compare'
                    defaultMessage='Compare'
                  />
                </Button>
                <Button
                  aria-label='Delete'
                  size='large'
                  autoSize
                  disabled={selectedReports.length === 0}
                  secondary
                  className='ml3'
                  onClick={() => setConfirmDeletion(true)}
                >
                  <FormattedMessage
                    id='ReportSnapshotList.Delete'
                    defaultMessage='Delete'
                  />
                </Button>
              </div>
            </div>
            <Paper noPadding>
              <Table
                rowGetter={({ index }) => {
                  return sortedList.get(index)
                }}
                rowCount={sortedList.size as number}
                sort={handleSortChange}
                sortDirection={sortDirection}
                sortBy={sortBy}
              >
                <Column
                  width={24}
                  dataKey={'id'}
                  disableSort
                  cellRenderer={renderCheckboxCell}
                />
                <Column
                  width={200}
                  dataKey={'name'}
                  label={
                    <FormattedMessage
                      id='ReportSnapshotList.Name'
                      defaultMessage='Name'
                    />
                  }
                  cellRenderer={renderTextCell}
                />
                <Column
                  width={150}
                  dataKey={'user'}
                  label={
                    <FormattedMessage
                      id='ReportSnapshotList.CreatedBy'
                      defaultMessage='Created By'
                    />
                  }
                  cellRenderer={renderTextCell}
                />
                <Column
                  width={100}
                  dataKey={'date'}
                  label={
                    <FormattedMessage
                      id='ReportSnapshotList.CreateOn'
                      defaultMessage='Created On'
                    />
                  }
                  cellRenderer={renderTextCell}
                />
                <Column
                  width={100}
                  dataKey={'qualifiedSpend'}
                  label={
                    <FormattedMessage
                      id='ReportSnapshotList.Qualified'
                      defaultMessage='Qualified'
                    />
                  }
                  cellRenderer={renderCurrencyCell}
                />
                <Column
                  width={100}
                  dataKey={'potentialSpend'}
                  label={
                    <FormattedMessage
                      id='ReportSnapshotList.Potential'
                      defaultMessage='Potential'
                    />
                  }
                  cellRenderer={renderCurrencyCell}
                />
                <Column
                  width={100}
                  dataKey={'disqualifiedSpend'}
                  label={
                    <FormattedMessage
                      id='ReportSnapshotList.Disqualified'
                      defaultMessage='Disqualified'
                    />
                  }
                  cellRenderer={renderCurrencyCell}
                />
                <Column
                  width={150}
                  dataKey={'reportingPeriod'}
                  label={
                    <FormattedMessage
                      id='ReportSnapshotList.ReportingPeriod'
                      defaultMessage='Period'
                    />
                  }
                  cellRenderer={renderTextCell}
                />
              </Table>
              {!sortedList.size && (
                <Text className='mv3 tc'>
                  <FormattedMessage
                    id='ReportSnapshotList.NoReport'
                    defaultMessage='No Saved Report'
                  />
                </Text>
              )}
              <ConfirmationDialog
                open={confirmDeletion}
                onClose={() => setConfirmDeletion(false)}
                onConfirm={handleDelete}
              >
                <Text>
                  <FormattedMessage
                    id='ReportSnapshotList.ConfirmDelete'
                    defaultMessage='Are you sure you want to delete selected { count, plural, one {report} other {reports} }?'
                    values={{
                      count: selectedReports.length
                    }}
                  />
                </Text>
              </ConfirmationDialog>
            </Paper>
          </>
        )}
      </Paper>
    </Page>
  )
}

export default ReportSnapshotList
