import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, put, select } from 'redux-saga/effects'
import users from 'shared/utils/api/users'
import newCollaborateContactSelectors from '../../../shared/selectors/newCollaborateContactSelectors'
import companySelectors from '../../../shared/selectors/companySelectors'
import reverseParsedUser from 'shared/utils/data/reverseParsedUser'
import FormError from 'shared/utils/FormError'
import supplier from 'shared/utils/api/supplier'
import { updateUser } from 'shared/actions'

export function* addSurveyCollaborateContactSaga(action) {
  let { user, message } = action.payload.toJS()
  const isCreatingNewUser = yield select(
    newCollaborateContactSelectors.isCreatingNewUser
  )
  const orgUnitId = yield select(companySelectors.getCompanyField, 'id')
  // need to create non-existing users
  if (isCreatingNewUser) {
    if (!user.email) {
      throw new FormError('Please select a user')
    }
    // creating a new user, need to convert location (string) to location (object)
    if (user.location) {
      user.location = { address: user.location }
    }
    user.isSustainabilityContact = true
    user = yield call(users.inviteUser, {
      user: reverseParsedUser(user),
      orgUnitId,
      message
    })
  } else {
    yield put(
      updateUser({
        id: user.id,
        isSustainabilityContact: true
      })
    )
  }

  yield call(supplier.addContact, {
    userId: user.id,
    contactFor: 'Sustainability'
  })

  return yield {
    user,
    isCreatingNewUser
  }
}

export default createApiRequestSaga(addSurveyCollaborateContactSaga)
