import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { getFormSubmitErrors } from 'shared/reducers'
import InviteToCommunitiesDialog from '../../components/InviteToCommunitesDialog'
import communitiesSelectors from '../../../Communities/selectors/communitiesSelectors'
import profileSelectors from '../../../SupplierProfile/selectors/profileSelectors'
import { formValueSelector } from 'redux-form/immutable'
import { INVITE_TO_COMMUNITIES } from '../../../Communities/actionTypes'
import { createSelector } from 'reselect'
import RootState from 'shared/models/RootState'

const formName = 'buyer/inviteSupplierCommunities'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)
const selector = formValueSelector(formName)

const getInitialValues = createSelector(
  (state: RootState) => profileSelectors.getDataField(state, 'id'),
  supplierId => ({
    supplierId
  })
)
export const mapStateToProps = (state, props) => {
  const communities = selector(state, 'communities')
  return {
    initialValues: getInitialValues(state),
    hasSelected:
      !!communities &&
      communities
        .valueSeq()
        .toJS()
        .some(c => c === true),
    errorMessage: formSubmitErrorsSelector(state),
    availableCommunities: communitiesSelectors.getCommunitiesForInviteLessTier2(
      state,
      props.supplierId
    )
  }
}

export default connectReduxForm(
  mapStateToProps,
  {
    inviteToCommunities: createSubmitFormAction(formName, INVITE_TO_COMMUNITIES)
  },
  {
    form: formName
  },
  InviteToCommunitiesDialog
)
