import { call } from 'redux-saga/effects'
import concierge from 'shared/utils/api/concierge'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import Immutable from 'immutable'

export function* sendMessageSaga(action) {
  const requestBody = Immutable.isImmutable(action.payload)
    ? action.payload.toJS()
    : action.payload

  yield call(concierge.sendConciergeRequest, requestBody)
  return yield requestBody
}

export default createApiRequestSaga(sendMessageSaga)
