import React, { Component } from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes'
import { generatePath } from 'react-router'
import { FormattedMessage } from 'react-intl'

type Props = {
  supplierId: string
  queryString?: string
  pathname: string
  isTealbot: boolean
  hasCommunityFormResponse: boolean
  customPageName?: string
}

class SupplierProfileNavBar extends Component<Props> {
  isActive(pathToCheck) {
    const { pathname } = this.props
    return pathToCheck === pathname
  }

  render() {
    const {
      supplierId,
      queryString,
      isTealbot,
      hasCommunityFormResponse
    } = this.props

    return (
      <NavBar>
        <NavLink
          label={
            <FormattedMessage
              id='SupplierProfileNavBar.Profile'
              defaultMessage='Profile'
            />
          }
          to={{
            pathname: generatePath(paths.basePath, { supplierId }),
            search: queryString
          }}
          exact
        />
        <NavLink
          label={
            <FormattedMessage
              id='SupplierProfileNavBar.MyCompany'
              defaultMessage='My Company'
            />
          }
          to={{
            pathname: generatePath(paths.myCompany, { supplierId }),
            search: queryString
          }}
        />
        {hasCommunityFormResponse && (
          <NavLink
            label={
              <FormattedMessage
                id='SupplierProfileNavBar.FormResponse'
                defaultMessage='Form Response'
              />
            }
            to={{
              pathname: generatePath(paths.formResponse, { supplierId }),
              search: queryString
            }}
          />
        )}

        {!isTealbot && (
          <NavLink
            label={
              <FormattedMessage
                id='SupplierProfileNavBar.Personal'
                defaultMessage='Personal'
              />
            }
            to={{
              pathname: generatePath(paths.personal, { supplierId }),
              search: queryString
            }}
          />
        )}

        {this.props.customPageName && (
          <NavLink
            label={this.props.customPageName}
            to={{
              pathname: generatePath(paths.custom, { supplierId }),
              search: queryString
            }}
          />
        )}
      </NavBar>
    )
  }
}

export default SupplierProfileNavBar
