import React, { useState, useEffect } from 'react'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import Page from 'shared/components/Page'
import SearchFormContainer from './containers/SearchFormContainer'
import SearchResultsContainer from './containers/SearchResultsContainer'
import LocationFiltersContainer from './containers/LocationFiltersContainer'
import FilterCheckboxListContainer from './containers/FilterCheckboxListContainer'
import { connect } from 'react-redux'
import { searchContacts } from '../shared/actions'
import FilterExpansionPanel from './components/FilterExpansionPanel'
import startCase from 'lodash.startcase'
import searchContactsSelectors from '../shared/selectors/searchContactsSelectors'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Text from 'shared/components/Text'
import CameraIcon from '@material-ui/icons/CameraEnhance'
import CloseIcon from 'shared/assets/icons/close.svg'
import { createContactFromBusinessCard } from './actions'
import { notify } from 'shared/actions'
import ErrorPage from 'shared/components/ErrorPage'
import Fab from '@material-ui/core/Fab'
import classNames from 'classnames'
import qs from 'qs'
import numberFormat from 'shared/utils/numberFormat'
import { useHistory } from 'react-router'
import { RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'

type Props = {
  searchContacts: (search: string) => void
  location: { search: string }
  searchingError: boolean
  hasSearched: boolean
  createContactFromBusinessCard: (businessCardImage: any) => void
  notify: ({ message: string }) => void
  query: RecordOf<{ scope: string }>
  myContacts: boolean
  filtersToShow: RecordOf<{
    country: boolean
    state: boolean
    city: boolean
    source: boolean
    haveMessage: boolean
    isPublic: boolean
    roles: boolean
  }>
  userHasNoContacts: boolean
  isSearching: boolean
  numberOfResults: number
  cq: string
}

const ContactsApp = (props: Props) => {
  const history = useHistory()
  const [businessCardImage, setBusinessCardImage] = useState<
    { name: '' } | undefined
  >(undefined)
  const [openNewContactDialog, setOpenNewContactDialog] = useState<boolean>(
    false
  )

  const {
    filtersToShow,
    myContacts,
    userHasNoContacts,
    isSearching,
    numberOfResults,
    cq,
    searchContacts,
    location,
    searchingError,
    hasSearched
  } = props

  useEffect(() => {
    // only trigger on mount if is the first time loading this component
    // or there was previously an error
    if (!hasSearched || searchingError) {
      searchContacts(location.search)
    }
  }, [location.search, hasSearched, searchingError, searchContacts])

  useEffect(() => {
    searchContacts(location.search)
  }, [location.search, searchContacts])

  const toggleNewContactDialog = () => {
    setOpenNewContactDialog(openNewContactDialog => !openNewContactDialog)
    setBusinessCardImage(openNewContactDialog ? businessCardImage : undefined)
  }

  const handleChangeBusinessCardImage = e => {
    setBusinessCardImage(e.target.files[0])
  }

  const handleClickClearBusinessCardImage = () => {
    setBusinessCardImage(undefined)
  }

  const handleSubmit = e => {
    e.preventDefault()
    props.createContactFromBusinessCard(businessCardImage)
    props.notify({
      message:
        'A message has been sent to TealBook, the contact will be added soon'
    })
    setBusinessCardImage(undefined)
    setOpenNewContactDialog(false)
  }

  const handleNavClick = () => {
    const { query } = props

    const newQuery = !myContacts
      ? query.set('scope', 'MyConnections')
      : query.delete('scope')

    history.push({
      search: qs.stringify(newQuery.toJS())
    })
  }

  if (searchingError) {
    return <ErrorPage />
  }

  return (
    <Page
      title={<FormattedMessage id='App.Contacts' defaultMessage='Contacts' />}
      className='mt4'
    >
      <SearchFormContainer />
      {userHasNoContacts !== undefined && !userHasNoContacts && (
        <header className='flex items-center mv2 pt1 bb b--light-gray'>
          <div className='flex flex-auto'>
            <span
              onClick={!myContacts ? handleNavClick : undefined}
              className={classNames(
                'pv3 mid-gray f7 f6-ns mr3 mr4-ns',
                myContacts ? 'bw1 bb fw6' : 'link dim pointer'
              )}
            >
              <FormattedMessage
                id='App.MyContacts'
                defaultMessage='My Contacts'
              />
            </span>

            <span
              onClick={myContacts ? handleNavClick : undefined}
              className={classNames(
                'pv3 f7 f6-ns mr3 mr4-ns',
                !myContacts ? 'bw1 bb fw6' : 'link dim pointer'
              )}
            >
              <FormattedMessage id='App.Everyone' defaultMessage='Everyone' />
            </span>
          </div>
        </header>
      )}

      <div className='pt2-ns w-100 mt3'>
        {isSearching && (
          <FormattedMessage id='App.Searching' defaultMessage='Searching...' />
        )}
        {numberOfResults > 0 && !isSearching && (
          <h6 className='f5 fw3 dib ma0 mid-gray'>
            {cq ? (
              <FormattedMessage
                id='App.numberOfResultsWithQuery'
                defaultMessage='{numberOfResults, plural, one {{formattedNumberOfResults} Result} other {{formattedNumberOfResults} Results}} for {query}'
                values={{
                  numberOfResults,
                  query: <span className='teal'>{cq}</span>,
                  formattedNumberOfResults: numberFormat(numberOfResults)
                }}
              />
            ) : (
              <FormattedMessage
                id='App.numberOfResults'
                defaultMessage='{numberOfResults, plural, one {{formattedNumberOfResults} Result} other {{formattedNumberOfResults} Results}}'
                values={{
                  numberOfResults,
                  formattedNumberOfResults: numberFormat(numberOfResults)
                }}
              />
            )}
          </h6>
        )}
      </div>

      <Main>
        <SearchResultsContainer />
        <div className='fixed bottom-1 right-2 mb5 dn-ns'>
          <Fab color='primary' onClick={toggleNewContactDialog}>
            <AddIcon color='inherit' />
          </Fab>
        </div>
      </Main>

      <Aside className='pt3'>
        {(filtersToShow.get('country') ||
          filtersToShow.get('state') ||
          filtersToShow.get('city')) && (
          <FilterExpansionPanel
            title={
              <FormattedMessage id='App.Location' defaultMessage='Location' />
            }
          >
            <LocationFiltersContainer />
          </FilterExpansionPanel>
        )}

        {filtersToShow.get('source') && (
          <FilterExpansionPanel
            title={<FormattedMessage id='App.Source' defaultMessage='Source' />}
          >
            <FilterCheckboxListContainer
              filterKey='source'
              labelFormatter={startCase}
            />
          </FilterExpansionPanel>
        )}

        {filtersToShow.get('haveMessage') && (
          <FilterExpansionPanel
            title={
              <FormattedMessage
                id='App.HaveMessage'
                defaultMessage='Have Message'
              />
            }
          >
            <FilterCheckboxListContainer filterKey='haveMessage' />
          </FilterExpansionPanel>
        )}

        {filtersToShow.get('isPublic') && (
          <FilterExpansionPanel
            title={<FormattedMessage id='App.Public' defaultMessage='Public' />}
          >
            <FilterCheckboxListContainer filterKey='isPublic' />
          </FilterExpansionPanel>
        )}

        {filtersToShow.get('roles') && (
          <FilterExpansionPanel
            title={
              <FormattedMessage
                id='Contacts/App.Roles'
                defaultMessage='Roles'
              />
            }
          >
            <FilterCheckboxListContainer
              filterKey='roles'
              labelFormatter={startCase}
            />
          </FilterExpansionPanel>
        )}
      </Aside>

      <Dialog open={!!openNewContactDialog} onClose={toggleNewContactDialog}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>
            <FormattedMessage
              id='Contacts/App.createNewContact'
              defaultMessage='Create a new contact'
            />
          </DialogTitle>
          <DialogContent>
            <Text className='mt3'>
              <FormattedMessage
                id='Contacts/App.takeAPicture'
                defaultMessage='Take a picture of a business card to create a contact from it'
              />
            </Text>

            {businessCardImage && (
              <div
                className='mt3 pa2 ba b--light-gray flex'
                onClick={handleClickClearBusinessCardImage}
              >
                <span className='flex-auto'>{businessCardImage.name}</span>
                <img src={CloseIcon} alt='close' />
              </div>
            )}

            {!businessCardImage && (
              <label className='tc mt4 mb3 w-100 db'>
                <input
                  accept='image/*'
                  type='file'
                  onChange={handleChangeBusinessCardImage}
                  capture
                  hidden
                />
                <Fab component='span' color='primary'>
                  <CameraIcon />
                </Fab>
              </label>
            )}
          </DialogContent>

          {!!businessCardImage && (
            <DialogActions className='tr'>
              <Button
                label={<FormattedMessage id='CancelButton' />}
                autoSize
                secondary
                onClick={toggleNewContactDialog}
              />
              <Button
                label={
                  <FormattedMessage
                    id='Contacts/App.Submit'
                    defaultMessage='Submit'
                  />
                }
                autoSize
                className='ml3'
                type='submit'
              />
            </DialogActions>
          )}
        </form>
      </Dialog>
    </Page>
  )
}

type ContainerProps = {
  location: { search: string }
}
export default connect(
  (state: RootState, props: ContainerProps) => ({
    filtersToShow: searchContactsSelectors.getFiltersToShow(state),
    searchingError: state.getIn(['buyer', 'contactsSearch', 'searchingError']),
    hasSearched: state.getIn(['buyer', 'contactsSearch', 'hasSearched']),
    query: searchContactsSelectors.getParsedQuery(props.location.search),
    isSearching: state.getIn(['buyer', 'contactsSearch', 'isSearching']),
    numberOfResults: state.getIn([
      'buyer',
      'contactsSearch',
      'numberOfResults'
    ]),
    cq: searchContactsSelectors.getParsedQuery(props.location.search).get('cq'),
    myContacts:
      searchContactsSelectors
        .getParsedQuery(props.location.search)
        .get('scope') === 'MyConnections',
    userHasNoContacts: state.getIn([
      'buyer',
      'contactsSearch',
      'userHasNoContacts'
    ])
  }),
  {
    searchContacts,
    createContactFromBusinessCard,
    notify
  }
)(ContactsApp)
