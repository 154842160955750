import React, { Fragment, FC, useState } from 'react'
import { connect } from 'react-redux'
import companySelectors from '../../selectors/companySelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import Page from 'shared/components/Page'
import Paper from '@material-ui/core/Paper'
import PremiumBadge from 'shared/components/PremiumBadge'
import Divider from 'shared/components/Divider'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import PaymentDialog from 'shared/containers/PaymentDialog'
import { hidePaymentDialog, showPaymentDialog } from '../../../shared/actions'
import RootState from 'shared/models/RootState'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import { cancelSubscription } from '../../actions'

type Props = {
  isPaymentDialogOpen: boolean
  showPaymentDialog: () => void
  hidePaymentDialog: () => void
  cancelSubscription: () => void
  isPremium: boolean
  signupDate: string
  renewalDate: string
  isLoading: boolean
  isTealbot: boolean
}

export const SubscriptionPage: FC<Props> = ({
  isPaymentDialogOpen,
  showPaymentDialog,
  hidePaymentDialog,
  cancelSubscription,
  isPremium,
  isTealbot,
  signupDate,
  renewalDate,
  isLoading
}) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  return (
    <Page title='Subscription'>
      <Paper className='pa3-5'>
        <PremiumBadge
          text={
            <FormattedMessage
              id='SubscriptionPageContainer.premium'
              defaultMessage='Premium'
            />
          }
        />
        {!isLoading &&
          (isPremium ? (
            <Fragment>
              {signupDate ? (
                <Label>
                  <FormattedMessage
                    id='SubscriptionPageContainer.isPremium.signupDate'
                    defaultMessage={`You are a TealBook premium supplier since {signupDate}.`}
                    values={{
                      signupDate
                    }}
                  />
                </Label>
              ) : (
                <Label>
                  <FormattedMessage
                    id='SubscriptionPageContainer.isPremium.noSignupDate'
                    defaultMessage={`You are a TealBook premium supplier.`}
                    values={{
                      signupDate
                    }}
                  />
                </Label>
              )}
              {renewalDate && (
                <Label>
                  <FormattedMessage
                    id='SubscriptionPageContainer.isPremium.renewalDate'
                    defaultMessage={`Next payment on {renewalDate}, you will be charged $680 (USD) for another year of premium license.`}
                    values={{
                      renewalDate
                    }}
                  />
                </Label>
              )}
              {isTealbot && (
                <div className='tc mt3'>
                  <Button autoSize caution onClick={() => setShowConfirm(true)}>
                    <FormattedMessage
                      id='SubscriptionPageContainer.CancelSubscription'
                      defaultMessage='Cancel Subscription'
                    />
                  </Button>
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Text className='fw6 mt3'>
                <FormattedMessage
                  id='SubscriptionPageContainer.isNotPremium'
                  defaultMessage='Subscribe to TealBook to get access to additional insight.
                For an introductory price of only $680 (USD) per year, you get access to additional insight into the marketplace and your relationships to existing and potential customers.'
                />
              </Text>
              <div className='mt3 tc'>
                <FormattedMessage
                  id='SubscriptionPageContainer.subscribeNow'
                  defaultMessage='Subscribe Now'
                >
                  {label => (
                    <Button
                      autoSize
                      label={label}
                      onClick={showPaymentDialog}
                    />
                  )}
                </FormattedMessage>
              </div>
            </Fragment>
          ))}
        <Divider className='mt3' />
        <Label>
          <FormattedMessage
            id='SubscriptionPageContainer.labelFeatureBenefit'
            defaultMessage='Features and Benefits'
          />
        </Label>
        <Text className='mt3'>
          <FormattedMessage
            id='SubscriptionPageContainer.featureBenefit1'
            defaultMessage='All core TealBook functionality is provided free of charge to suppliers.
            This includes the ability to maintain a company profile and respond to opportunities.
            Subscription is not required to use TealBook as a supplier.'
          />
        </Text>
        <Text className='mt3'>
          <FormattedMessage
            id='SubscriptionPageContainer.featureBenefit2'
            defaultMessage='Subscribers get access to additional insight into how they are viewed by existing and potential customers.
            This includes details on page views and searches.
            Subscribers are highlighted as premium suppliers in search list results.
            Subscribers also get access to advanced options for customizing their messaging for target customers, including the ability to tailor the company description and pitch deck.
            Please note, subscription does not alter the order in which suppliers are listed in search results.'
          />
        </Text>
        <Label>
          <FormattedMessage
            id='SubscriptionPageContainer.labelTermsConditions'
            defaultMessage='Terms and Conditions'
          />
        </Label>
        <Text className='mt3'>
          <FormattedMessage
            id='SubscriptionPageContainer.termsConditions1'
            defaultMessage='Subscriptions are annual. The non-refundable cost is paid in advance by the subscriber.
            If you subscribed in error, contact us within 24 hours of the payment to cancel the service and get the charge refunded.
            There is no refund available after the 24 hour period has passed.'
          />
        </Text>
        <Text className='mt3'>
          <FormattedMessage
            id='SubscriptionPageContainer.termsConditions2'
            defaultMessage='On the one year anniversary of the subscription it will be automatically renewed and charged to the credit card put on record unless it is canceled prior to the renewal date.
            If you miss that date, you can still cancel and get a refund if you notify us within 30 days of the renewal date.'
          />
        </Text>
        <Label>
          <FormattedMessage
            id='SubscriptionPageContainer.labelCancellation'
            defaultMessage='Cancellation'
          />
        </Label>
        <Text className='mt3'>
          <FormattedMessage
            id='SubscriptionPageContainer.cancellation'
            defaultMessage='To cancel the renewal of your subscription or report that you have subscribed in error within 24 hours of payment,
            please use the Contact link on your TealBook menu.
            In the detail area, please provide us a phone number where we can reach you so that we can confirm the cancellation request.'
          />
        </Text>
      </Paper>
      {isPaymentDialogOpen && (
        <PaymentDialog open={isPaymentDialogOpen} onClose={hidePaymentDialog} />
      )}
      <ConfirmationDialog
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={() => {
          cancelSubscription()
          setShowConfirm(false)
        }}
      >
        <FormattedMessage
          id='SubscriptionPageContainer.Confirm'
          defaultMessage='Are you sure you want to cancel your subscription?'
        />
      </ConfirmationDialog>
    </Page>
  )
}

export default connect(
  (state: RootState) => {
    const supplier = companySelectors.getCompanyField(state, 'supplier')
    const isPremium = supplier && supplier.get('supplierType') === 'basic'

    const signupDate =
      supplier &&
      supplier.getIn(['stripe', 'signupDate']) &&
      moment(new Date(supplier.getIn(['stripe', 'signupDate']))).startOf('day')

    const today = moment().startOf('day')
    let renewalDate = signupDate && moment(signupDate).year(today.year())
    if (renewalDate && renewalDate.isSameOrBefore(today)) {
      renewalDate = renewalDate.add(1, 'year')
    }

    return {
      isPaymentDialogOpen: state.getIn([
        'supplier',
        'company',
        'isPaymentDialogOpen'
      ]),
      isLoading: companySelectors.isLoading(state),
      isPremium,
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      signupDate: signupDate && signupDate.format('MM/DD/YYYY'),
      renewalDate: renewalDate && renewalDate.format('MM/DD/YYYY')
    }
  },
  {
    showPaymentDialog,
    hidePaymentDialog,
    cancelSubscription
  }
)(SubscriptionPage)
