import React from 'react'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from 'react-intl'
import ethics from 'shared/assets/icons/law.svg'
import environment from 'shared/assets/icons/leaf.svg'
import procurement from 'shared/assets/icons/link.svg'
import labour from 'shared/assets/icons/people.svg'
import ExternalLink from 'shared/components/ExternalLink'
import Text from 'shared/components/Text'
import { makeStyles } from '@material-ui/core'
import { EcovadisScorecard } from 'buyer/SupplierProfile/containers/EcovadisRatingContainer/EcovadisRatingContainer'

const useStyles = makeStyles({
  root: {
    marginBottom: '20px'
  }
})

type Props = {
  ecovadisScorecard: EcovadisScorecard
}

const EcovadisRatingReport = ({ ecovadisScorecard }: Props) => {
  const classes = useStyles()

  const getNumeratorColour = score => {
    if (score >= 85) {
      return <span style={{ color: '#2f9403' }}>{score}</span>
    }
    if (score >= 65) {
      return <span style={{ color: '#58c84d' }}>{score}</span>
    }
    if (score >= 45) {
      return <span style={{ color: '#b2d934' }}>{score}</span>
    }
    if (score >= 25) {
      return <span style={{ color: '#f9ad27' }}>{score}</span>
    } else {
      return <span style={{ color: '#fa3d18' }}>{score}</span>
    }
  }
  return (
    <div className='pt4'>
      <Grid container spacing={3}>
        <Grid item xs={6} className={classes.root}>
          <div className='flex items-end'>
            <span className='f1'>
              {getNumeratorColour(ecovadisScorecard.get('global_score'))}
            </span>
            <span className='light-gray f2'>/100</span>
            <img
              src={ecovadisScorecard.get('global_trend')}
              alt='Overall Trend'
              className='ml1 mb3'
            />
          </div>
          <div className='lh-copy fw4 mid-gray ma0 ttu f8 secondary'>
            <FormattedMessage
              id='EcovadisRatingReport.OverallScore'
              defaultMessage='Overall Score'
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs className={classes.root}>
          <div className='flex items-end'>
            <span className='f2'>
              {getNumeratorColour(ecovadisScorecard.get('env_score'))}
            </span>
            <span className='light-gray f3'>/100</span>
            <img
              src={ecovadisScorecard.get('env_trend')}
              alt='Environment Trend'
              className='ml1 mb2'
            />
          </div>
          <div className='flex mr2'>
            <img
              src={environment}
              alt='Environment'
              className='mr2'
              style={{ width: '12px', transform: 'scaleX(-1)' }}
            />
            <div className='lh-copy fw4 mid-gray ma0 ttu f8 secondary'>
              <FormattedMessage
                id='EcovadisRatingReport.Environment'
                defaultMessage='Environment'
              />
            </div>
          </div>
        </Grid>
        <Grid item xs className={classes.root}>
          <div className='flex items-end'>
            <span className='f2'>
              {getNumeratorColour(ecovadisScorecard.get('lab_score'))}
            </span>
            <span className='light-gray f3'>/100</span>
            <img
              src={ecovadisScorecard.get('lab_trend')}
              alt='Labour & Human Rights rend'
              className='ml1 mb2'
            />
          </div>
          <div className='flex'>
            <img
              src={labour}
              alt='Labour & Human Rights'
              className='mr2'
              style={{ width: '12px' }}
            />
            <div className='lh-copy fw4 mid-gray ma0 ttu f8 secondary'>
              <FormattedMessage
                id='EcovadisRatingReport.LabourHumanRights'
                defaultMessage='Labour & Human Rights'
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs>
          <div className='flex items-end'>
            <span className='f2'>
              {getNumeratorColour(ecovadisScorecard.get('fbp_score'))}
            </span>
            <span className='light-gray f3'>/100</span>
            <img
              src={ecovadisScorecard.get('fbp_trend')}
              alt='Ethics Trend'
              className='ml1 mb2'
            />
          </div>
          <div className='flex'>
            <img
              src={ethics}
              alt='Ethics'
              className='mr2'
              style={{ width: '12px' }}
            />
            <div className='lh-copy fw4 mid-gray ma0 ttu f8 secondary'>
              <FormattedMessage
                id='EcovadisRatingReport.Ethics'
                defaultMessage='Ethics'
              />
            </div>
          </div>
        </Grid>
        <Grid item xs>
          <div className='flex items-end'>
            <span className='f2'>
              {getNumeratorColour(ecovadisScorecard.get('sup_score'))}
            </span>
            <span className='light-gray f3'>/100</span>
            <img
              src={ecovadisScorecard.get('sup_trend')}
              alt='Sustainable Procurement Trend'
              className='ml1 mb2'
            />
          </div>
          <div className='flex'>
            <img
              src={procurement}
              alt='Sustainable Procurement'
              className='mr2'
              style={{ width: '12px', transform: 'rotate(-45deg)' }}
            />
            <div className='lh-copy fw4 mid-gray ma0 ttu f8 secondary'>
              <FormattedMessage
                id='EcovadisRatingReport.SustainableProcurement'
                defaultMessage='Sustainable Procurement'
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <ExternalLink href={ecovadisScorecard.get('scorecard_link')}>
        <Text secondary className='pt5'>
          <FormattedMessage
            id='EcovadisRatingReport.viewFullReport'
            defaultMessage='View full EcoVadis Sustainability Report'
          />
        </Text>
      </ExternalLink>
    </div>
  )
}

export default EcovadisRatingReport
