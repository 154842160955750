import { createSelector } from 'reselect'
import { Map, fromJS, RecordOf, List } from 'immutable'
import RootState from 'shared/models/RootState'
import { Tier2Item, Snapshot } from '../insightsReducer'

export const isLoading = state =>
  state.getIn(['buyer', 'insights', 'isLoading'])

export const isLoadingSpend = state =>
  state.getIn(['buyer', 'insights', 'isLoadingSpend'])
export const isLoadingDetail = state =>
  state.getIn(['buyer', 'insights', 'isLoadingDetail'])
export const getMostActiveUsers = state =>
  state.getIn(['buyer', 'insights', 'mostActiveUsers'])
export const getMostActiveSuppliers = state =>
  state.getIn(['buyer', 'insights', 'mostActiveSuppliers'])
export const getCompanyStats = state =>
  state.getIn(['buyer', 'insights', 'companyStats'])
export const getCompanyTags = state =>
  state.getIn(['buyer', 'insights', 'companyTags'])
export const getSearchGraph = state =>
  state.getIn(['buyer', 'insights', 'searchGraph'])
export const getCommonSearchTerms = state =>
  state.getIn(['buyer', 'insights', 'commonSearchTerms'])

export const getCommonSearchTermsMap = createSelector(
  getCommonSearchTerms,
  terms => {
    return (
      terms &&
      terms.reduce((result, term) => {
        return result.set(term.get('value'), term.get('count'))
      }, Map())
    )
  }
)

export const getCompanyTagsMap = createSelector(getCompanyTags, terms => {
  return (
    terms &&
    terms.reduce((result, term) => {
      return result.set(term.get('value'), term.get('count'))
    }, Map())
  )
})

export const getSpendOverview = createSelector(
  (state, type: 'country' | 'category' | 'spendgroup') => {
    return state.getIn([
      'buyer',
      'insights',
      'spend',
      'overview',
      type === 'country'
        ? 'byCountry'
        : type === 'category'
        ? 'byCategory'
        : 'bySpendGroup'
    ])
  },
  list => {
    return list && list.toList()
  }
)

export const getSpendTotal = createSelector(
  getSpendOverview,
  overview =>
    overview &&
    overview.reduce((total, category) => {
      return total + (category.get('totalAmount') || 0)
    }, 0)
)
export const getSpendField = (state, fieldName) =>
  state.getIn(['buyer', 'insights', 'spend', fieldName])
export const getSpendYears = state =>
  state.getIn(['buyer', 'insights', 'spend', 'years'])
export const getMinSpendDate = state =>
  state.getIn(['buyer', 'insights', 'spend', 'minSpendDate'])
export const getMaxSpendDate = state =>
  state.getIn(['buyer', 'insights', 'spend', 'maxSpendDate'])
export const getTotalSpend = state =>
  state.getIn(['buyer', 'insights', 'spend', 'overview', 'totalSpend'])
export const getSelectedSpend = state =>
  state.getIn(['buyer', 'insights', 'spend', 'selectedSpend'])
export const getTotalDiverseSpend = state =>
  state.getIn(['buyer', 'insights', 'spend', 'overview', 'totalDiverseSpend'])

export const getSpendCountries = state =>
  state.getIn(['buyer', 'insights', 'spend', 'countries'])
export const getSpendCategories = state =>
  state.getIn(['buyer', 'insights', 'spend', 'categories'])
export const getSpendSpendGroups = state =>
  state.getIn(['buyer', 'insights', 'spend', 'spendgroups'])
export const isUsingSpendGroups = state =>
  state.getIn(['buyer', 'insights', 'spend', 'useSpendGroup'])

export const getSpendPieData = createSelector(
  getSpendOverview,
  (state, type) => type,
  (overview, type) => {
    return (
      overview &&
      overview
        .sort(
          (spend1, spend2) =>
            spend2.get('totalAmount') - spend1.get('totalAmount')
        )
        .map((entry, index) => {
          return Map({
            index,
            name: entry.get(type),
            value: entry.get('totalAmount')
          })
        })
    )
  }
)
export const getSpendTableData = createSelector(
  getSpendOverview,
  (state, type) => type,
  (overview, type) => {
    return (
      overview &&
      overview
        .sort(
          (spend1, spend2) =>
            spend2.get('totalAmount') - spend1.get('totalAmount')
        )
        .map((entry, index) => {
          return Map({
            index,
            ...entry.toJS()
          })
        })
    )
  }
)

export const getSpendDetail = createSelector(
  (state: RootState) => getSpendField(state, 'preview'),
  (preview: Map<string, List<any>>) => {
    return (
      preview &&
      preview.toOrderedMap().sortBy(
        (v: List<any>, k: string) => k,
        (c1: string, c2: string) => (c1 > c2 ? 1 : -1)
      )
    )
  }
)

export const getConsolidation = (state: RootState) => {
  return state.getIn(['buyer', 'insights', 'consolidation']) || fromJS({})
}

export const getProfileWarnings = (state: RootState) => {
  return state.getIn(['buyer', 'insights', 'consolidation', 'profileWarnings'])
}

export const getTier2Suppliers = (state: RootState) =>
  state && state.getIn(['buyer', 'insights', 'tier2', 'supplierList'])

export const getTier2SupplierById = createSelector(
  getTier2Suppliers,
  (_state, supplierId: string) => supplierId,
  (suppliers, supplierId) =>
    suppliers &&
    suppliers.find(
      supplier => supplier && supplier.getIn(['supplier', 'id']) === supplierId
    )
)

export const getTier2Community = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'tier2', 'tier2Community'])

export const getQueryString = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'tier2', 'search', 'queryString'])

export const getTier2SupplierSearchResults = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'tier2', 'search', 'results'])

export const getTier2SupplierSearchResultsCleaned = createSelector(
  getTier2SupplierSearchResults,
  results =>
    results &&
    results.filter(result => result.get('name') !== 'Unmapped Suppliers')
)
export const isLoadingSupplierList = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'isLoadingSupplierList'])

export const isLoadingLookUpSuggestions = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'isLoadingLookUpSuggestions'])

export const isLoadingTier2Data = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'tier2', 'isLoading'])

export const getTier2SelectedCategories = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'tier2', 'selectedCategories'])

export const getTier2Data = (state: RootState, key: string) =>
  state.getIn(['buyer', 'insights', 'tier2', 'data', key])

export const getUniqueQualifiedTier2Spend = (state: RootState, key: string) =>
  state.getIn(['buyer', 'insights', 'tier2', 'uniqueTotalTier2Spend', key])

export const getTier2Overview = createSelector(
  getTier2Data,
  (data: List<Tier2Item>) => {
    let dupCounts: { [key: string]: boolean } = {}
    let qualifiedTotalAmount: number = 0
    let spendBySubCategory: { [key: string]: any } = {}
    if (data) {
      data.forEach((item: RecordOf<Tier2Item>) => {
        qualifiedTotalAmount += item.get('spend')
        if (item.get('subCategories').size > 1) {
          item
            .get('subCategories')
            .forEach(subCategory => (dupCounts[subCategory] = true))
        }
        if (spendBySubCategory[item.get('tier2Type')]) {
          spendBySubCategory[item.get('tier2Type')].qualifiedAmount += item.get(
            'spend'
          )
          spendBySubCategory[item.get('tier2Type')].count += item.get(
            'numSuppliers'
          )
        } else {
          spendBySubCategory[item.get('tier2Type')] = {
            subCategory: item.get('tier2Type'),
            qualifiedAmount: item.get('spend'),
            count: item.get('numSuppliers')
          }
        }
      })
    }
    return {
      spendItems: fromJS(
        Object.values(spendBySubCategory).sort(
          (item1, item2) => item2.qualifiedAmount - item1.qualifiedAmount
        )
      ),
      dupCounts,
      qualifiedTotalAmount
    }
  }
)

export const getSupplierAllocation = createSelector(
  getTier2SelectedCategories,
  getTier2Data,
  (getTier2SelectedCategories, data) => {
    return (
      data &&
      data
        .filter(rowItem => {
          return (
            !getTier2SelectedCategories ||
            getTier2SelectedCategories.size === 0 ||
            getTier2SelectedCategories.includes(rowItem.get('tier2Type'))
          )
        })
        .sort((row1, row2) => {
          return row2.get('spend') - row1.get('spend')
        })
    )
  }
)

export const isLoadingInternalStats = state =>
  state.getIn(['buyer', 'insights', 'isLoadingInternalStats'])

export const isLoadingKeys = state =>
  state.getIn(['buyer', 'insights', 'isLoadingInternalStatKeys'])

export const getInternalStats = (state, type) =>
  state.getIn([
    'buyer',
    'insights',
    'internalStats',
    'internalStatsQueries',
    type
  ])

export const getInternalStatKeys = state =>
  state.getIn(['buyer', 'insights', 'internalStats', 'internalStatsKeys'])

export const getSavedReports = createSelector(
  (state: RootState) =>
    state.getIn(['buyer', 'insights', 'saveReports', 'byId']),
  (snapshots: Map<string, RecordOf<Snapshot>>) => {
    return snapshots
      .valueSeq()
      .filter(s => !!s)
      .map(s => {
        const {
          id,
          created,
          name,
          reportingPeriod,
          potentialSpend,
          qualifiedSpend,
          disqualifiedSpend,
          totalDiverseSpend
        } = s.toJS()

        return {
          id,
          user: created.user,
          date: created.date,
          name,
          reportingPeriod,
          potentialSpend,
          qualifiedSpend,
          disqualifiedSpend,
          totalDiverseSpend
        }
      })
  }
)

export const getSavedReportById = createSelector(
  (state: RootState, id: string) =>
    state.getIn(['buyer', 'insights', 'saveReports', 'byId', id]),
  (state: RootState, id: string) =>
    state.getIn(['buyer', 'insights', 'saveReports', 'SnapshotLineItems', id]),
  (snapshot, lineItems = []) => {
    if (snapshot) {
      return snapshot.set('lineItems', lineItems).toJS()
    }
    return undefined
  }
)

export const getSavedReportRulesById = createSelector(
  (state: RootState, id: string) =>
    state.getIn(['buyer', 'insights', 'saveReports', 'byId', id, 'rules']),
  rules => rules
)

export const getLineItemsBySubcategory = createSelector(
  (state: RootState, id: string) =>
    state.getIn(['buyer', 'insights', 'saveReports', 'SnapshotLineItems', id]),
  (state: RootState, id: string, subCategory: string) => subCategory,
  (lineItems, subCategory) => {
    return lineItems?.filter(
      item => item.get(`${subCategory} Certification`) !== 'No'
    )
  }
)

export const getLineItemsBySubCategoryByType = createSelector(
  getLineItemsBySubcategory,
  getSavedReportRulesById,
  (
    state: RootState,
    id: string,
    subCategory: string,
    type: 'qualified' | 'potential' | 'disqualified' | undefined
  ) => subCategory,
  (
    state: RootState,
    id: string,
    subCategory: string,
    type: 'qualified' | 'potential' | 'disqualified' | undefined
  ) => type,
  (lineItems, rules, subCategory, type) => {
    const { excludeRules_myTeam } = rules?.toJS() || {}
    const teamExclude = excludeRules_myTeam === 'rejected'
    return lineItems
      ?.filter(lineItem => {
        // if myTeamExclude !== rejected, use only Certification
        // else need to check team verification
        if (type === 'qualified') {
          return (
            lineItem.get(`${subCategory} Certification`) === 'Qualified' &&
            (teamExclude
              ? lineItem.get(`${subCategory} Verification`) !== 'Invalid'
              : true)
          )
        } else if (type === 'potential') {
          return (
            lineItem.get(`${subCategory} Certification`) === 'Potential' &&
            (teamExclude
              ? lineItem.get(`${subCategory} Verification`) !== 'Invalid'
              : true)
          )
        } else if (type === 'disqualified') {
          return teamExclude
            ? lineItem.get(`${subCategory} Verification`) === 'Invalid'
            : false
        } else {
          // no type for comparing 2 reports
          return (
            lineItem.get(`${subCategory} Certification`) &&
            lineItem.get(`${subCategory} Certification`) !== 'No'
          )
        }
      })
      .map(item => {
        // update Certification for comparing 2 reports
        return !type &&
          teamExclude &&
          item.get(`${subCategory} Verification`) === 'Invalid'
          ? item.set(`${subCategory} Certification`, 'Disqualified')
          : item
      })
      .sort((item1, item2) => {
        if (
          item1.get('name')?.toLowerCase() === item2.get('name')?.toLowerCase()
        ) {
          return 0
        } else {
          return item1.get('name')?.toLowerCase() >
            item2.get('name')?.toLowerCase()
            ? 1
            : -1
        }
      })
      .toJS()
  }
)

export const isSnapshotsLoading = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'saveReports', 'isLoading'])

export const deletingSnapshots = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'saveReports', 'isDeleting'])

export const isSavingSnapshot = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'saveReports', 'isSaving'])

export const isLoadingSustainabilityReport = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'sustainabilityReport', 'loading'])

export const getSustainabilityReportOverview = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'sustainabilityReport', 'overview'])

export const getSustainabilityReportCompletedSuppliers = (state: RootState) =>
  state.getIn([
    'buyer',
    'insights',
    'sustainabilityReport',
    'completedSuppliers'
  ])

export const getEsgReportFilterValue = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'sustainabilityReport', 'filterValue'])

export const getEsgReportShowQuestions = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'sustainabilityReport', 'showQuestions'])

export const isEsgReportQuestionOpen = (state: RootState, questionId: string) =>
  !!state.getIn([
    'buyer',
    'insights',
    'sustainabilityReport',
    'showQuestions',
    questionId
  ])

export const isEsgReportPageLoading = (state: RootState) =>
  state.getIn(['buyer', 'insights', 'sustainabilityReport', 'isLoadingDetail'])

export const getEsgReportQuestionStat = createSelector(
  (state: RootState, pageId: string, questionId: string) =>
    state.getIn(['buyer', 'insights', 'sustainabilityReport', 'details']),
  (state: RootState, pageId: string, questionId: string) => pageId,
  (state: RootState, pageId: string, questionId: string) => questionId,
  (details, pageId, questionId) => {
    const stat = details.getIn([pageId, questionId])

    return (
      stat && {
        yes: stat.get('yes') || stat.get('true'),
        no: stat.get('no') || stat.get('false'),
        notAnswer: stat.get('preferNotToAnswer'),
        noReasonNoPlan: stat.getIn(['reason', 'no', 'noPlanAnytimeSoon'], 0),
        noReasonWithinYear: stat.getIn(
          ['reason', 'no', 'planToDoWithinAYearCount'],
          0
        ),
        noReasonOther:
          stat.get('no', 0) -
          stat.getIn(['reason', 'no', 'planToDoWithinAYearCount'], 0) -
          stat.getIn(['reason', 'no', 'noPlanAnytimeSoon'], 0),
        notAnswerReasonNotMyCompany: stat.getIn(
          ['reason', 'preferNotToAnswer', 'notRelevantToCompany'],
          0
        ),
        notAnswerReasonNotMyIndustry: stat.getIn(
          ['reason', 'preferNotToAnswer', 'notRelevantToIndustry'],
          0
        ),
        notAnswerReasonOther:
          stat.get('preferNotToAnswer', 0) -
          stat.getIn(
            ['reason', 'preferNotToAnswer', 'notRelevantToCompany'],
            0
          ) -
          stat.getIn(
            ['reason', 'preferNotToAnswer', 'notRelevantToIndustry'],
            0
          )
      }
    )
  }
)
