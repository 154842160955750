import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Spotlight from '../../components/Spotlight'
import RootState from 'shared/models/RootState'
import postsSelectors from '../../selectors/postsSelectors'
import { loadPostsByTag, markPostRead } from '../../actions'
import { List } from 'immutable'
import { sendContactUsMessage } from 'shared/actions'
import routingSelectors from 'shared/selectors/routingSelectors'
import buyerPaths from 'buyer/routes/paths'
import supplierPaths from 'supplier/routes/paths'

type Props = {
  tag: string
  trendingTags: List<string>
  posts: List<any>
  isClientApp: boolean
  loadPostsByTag: (params) => void
  sendContactUsMessage: (params) => void
  markPostRead: (arg: string) => void
  contributorsUrl: string
}

const SpotlightContainer = (props: Props) => {
  const { loadPostsByTag, tag } = props

  useEffect(() => {
    if (tag) {
      loadPostsByTag({ tag })
    }
  }, [loadPostsByTag, tag])
  return <Spotlight {...props} />
}

type ConnectProps = {
  tag: string
}

export default connect(
  (state: RootState, props: ConnectProps) => ({
    trendingTags: postsSelectors.getTrendingTags(state),
    posts: postsSelectors.getPostsByTag(state, props.tag),
    contributorsUrl: routingSelectors.isClientApp(state)
      ? buyerPaths.contributors
      : supplierPaths.contributors
  }),
  { loadPostsByTag, sendContactUsMessage, markPostRead }
)(SpotlightContainer)
