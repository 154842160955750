import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import qs from 'qs'

const apiRoute = `${apiRoutes.authService}/posts`

const parsePost = ({
  expanded: { impressionsCount, markReadCount, upVotesCount, ...restExpanded },
  ...restResponse
}) => ({
  ...restResponse,
  impressionsCount,
  markReadCount,
  upVotesCount,
  expanded: {
    ...restExpanded
  }
})

export const create = post => api.post(apiRoute, post)
export const uploadImage = ({ postId, file }) =>
  api.postFile(`${apiRoute}/${postId}/upload`, { document: file })

export const getAll = params => {
  return api.get(apiRoute, params).then(response => {
    return response.map(parsePost)
  })
}

export const getByTag = params => {
  return api.get(`${apiRoute}?${qs.stringify(params)}`).then(response => {
    return response.map(parsePost)
  })
}

export const getTrendingTags = params => {
  return api
    .get(`${apiRoute}/allTags?${qs.stringify(params)}`)
    .then(response => {
      return response ? response.map(t => t.tag) : []
    })
}

export const addComment = (postId, note) => {
  return api.put(`${apiRoute}/${postId}/comments`, { note })
}

export const deleteComment = ({ postId, index }) => {
  return api.remove(`${apiRoute}/${postId}/comments/${index}`)
}

export const upVote = postId => {
  return api.put(`${apiRoute}/${postId}/upVotes`)
}

export const clearVote = postId => {
  return api.remove(`${apiRoute}/${postId}/upVotes`)
}

export const deletePost = postId => {
  return api.remove(`${apiRoute}/${postId}`)
}

export const markPostRead = postId => {
  return api.post(`${apiRoute}/${postId}`)
}

export const putPostImpression = postId => {
  return api.put(`${apiRoute}/${postId}/impression`)
}
