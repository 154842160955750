import React, { Component } from 'react'
import { Field } from 'redux-form/immutable'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import Button from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import { FormattedMessage } from 'react-intl'

type Props = {
  isPrimary?: boolean
  onCancel: () => void
  handleSubmit: () => void
}

class CompanyLocationEditForm extends Component<Props> {
  render() {
    const { isPrimary, onCancel, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit} className='bg-near-white pa2'>
        <FormattedMessage
          id='CompanyLocationEditForm.DescriptionofThisAddressPlaceholder'
          defaultMessage='Description of this address (e.g. Toronto, Headquarter)'
        >
          {message => (
            <Field
              component={Input}
              name='description'
              placeholder={message as string}
              className='mb2'
              required
            />
          )}
        </FormattedMessage>

        <FormattedMessage
          id='CompanyLocationEditForm.PhysicalAddressPlaceholder'
          defaultMessage='Physical address (e.g. 30 Camden St., Toronto, ON)'
        >
          {message => (
            <Field
              component={TextArea}
              name='address'
              placeholder={message as string}
              required
            />
          )}
        </FormattedMessage>

        <FormattedMessage
          id='CompanyLocationEditForm.PhoneNumberPlaceholder'
          defaultMessage='Phone Number'
        >
          {message => (
            <Field
              component={Input}
              name='phone'
              placeholder={message as string}
              className='mt2'
            />
          )}
        </FormattedMessage>

        <div className='flex mt2'>
          <div className='flex-auto'>
            <Field
              name='isPrimary'
              component={Checkbox}
              label={
                <FormattedMessage
                  id='CompanyLocationEditForm.PrimaryAddressLabel'
                  defaultMessage='Primary Address'
                />
              }
              checked={isPrimary || undefined}
            />
          </div>

          <Button
            label={<FormattedMessage id='CancelButton' />}
            secondary
            autoSize
            onClick={onCancel}
            className='mr3'
          />
          <Button
            label={
              <FormattedMessage
                id='CompanyLocationEditForm.SaveButton'
                defaultMessage='Save'
              />
            }
            type='submit'
            autoSize
          />
        </div>
      </form>
    )
  }
}

export default CompanyLocationEditForm
