import React from 'react'
import { useSelector } from 'react-redux'
import { List, Map, RecordOf } from 'immutable'
import insightsSelectors from '../../store/insightsSelectors'
import InsightTitle from 'shared/components/InsightTitle'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Paper from '@material-ui/core/Paper'
import { ReactComponent as DownloadIcon } from 'shared/assets/icons/download.svg'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import exportData from 'shared/utils/exportData'
import startCase from 'lodash.startcase'
import paths from 'buyer/routes/paths'
import parsePath from 'shared/utils/parsePath'
import { IconButton } from '@material-ui/core'

const messages = defineMessages({
  inactive: {
    id: 'WarningProfiles.Inactive',
    defaultMessage: 'Inactive'
  },
  banned: {
    id: 'WarningProfiles.Banned',
    defaultMessage: 'Company under Sanction'
  },
  flagCountry: {
    id: 'WarningProfiles.FlagCountry',
    defaultMessage: 'Location in Sanctioned Country'
  },
  domainForSale: {
    id: 'WarningProfiles.DomainForSale',
    defaultMessage: 'Domain For Sale'
  },
  inappropriateContentDomain: {
    id: 'WarningProfiles.InappropriateContentDomain',
    defaultMessage: 'Inappropriate Content'
  }
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 1),
      boxShadow: 'none',
      border: 'solid 1px rgba(0, 0, 0, 0.1)'
    }
  })
)

type ProfileWarnings = Map<
  string,
  List<
    RecordOf<{
      orgUnitId: string
      domains: List<string>
      name: string
      internalIds: List<string>
      warning: string
    }>
  >
>

export const WarningProfiles = () => {
  const classes = useStyles()
  const intl = useIntl()
  const profileWarnings: ProfileWarnings = useSelector(
    insightsSelectors.getProfileWarnings
  )

  const downloadWarningProfiles = (warningArgument: string) => {
    const warningProfiles =
      profileWarnings && profileWarnings.get(warningArgument)

    if (warningProfiles) {
      const data = warningProfiles
        .toJS()
        .map(
          ({
            orgUnitId,
            domains,
            name,
            warning,
            internalIds,
            flaggedCountries
          }) => {
            let row: {
              link: string
              domains: string
              name: string
              internalIds: string
              inactiveReason?: string
              flaggedCountries?: string
            } = {
              link: `${window.location.protocol}//${
                window.location.host
              }${parsePath(paths.supplierProfile, { supplierId: orgUnitId })}`,
              domains,
              name,
              internalIds
            }

            if (warningArgument === 'inactive') {
              row = {
                ...row,
                inactiveReason: warning
                  ? startCase(warning.toLowerCase())
                  : 'Manual'
              }
            }

            if (warningArgument === 'flagCountry') {
              row = {
                ...row,
                flaggedCountries: flaggedCountries?.join(', ')
              }
            }
            return row
          }
        )
      exportData.exportCSV(data, startCase(warningArgument))
    }
  }

  if (!profileWarnings) {
    return null
  }

  return (
    <div className='mt3'>
      <InsightTitle>
        <FormattedMessage
          id='WarningProfiles.SuppliersLoadedWithWarnings'
          defaultMessage='Suppliers Loaded with Warnings'
        />
      </InsightTitle>
      <Paper className={classes.root}>
        {profileWarnings
          .map((profiles, key) => {
            const warningMessage = messages[key]
              ? intl.formatMessage(messages[key])
              : key

            return (
              <div key={key} className='flex justify-between items-center'>
                <div className='lh-copy f7 fw4 mid-gray ma2'>
                  {warningMessage} (
                  <span className='teal'>{profiles.size}</span>)
                </div>
                <IconButton
                  aria-label={`download suppliers with warning: ${warningMessage}`}
                  onClick={() => downloadWarningProfiles(key)}
                  className='teal'
                >
                  <DownloadIcon height='16' />
                </IconButton>
              </div>
            )
          })
          .toList()}
      </Paper>
    </div>
  )
}

export default WarningProfiles
