import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import RootState from 'shared/models/RootState'

export const getUserMetrics = (state: RootState) => {
  return state.getIn(['buyer', 'userMetrics']) || fromJS({})
}

export const getUserMetricsTags = createSelector(
  (state: RootState) => getUserMetrics(state).get('tags'),
  tags =>
    tags
      ? tags.reduce((result, value, key) => {
          return result.set(
            key.toLowerCase(),
            result.has(key.toLowerCase()) ? value + value : value
          )
        }, fromJS({}))
      : fromJS({})
)

export const getUserMetricsSuppliers = createSelector(
  (state: RootState) => getUserMetrics(state).get('suppliers'),
  suppliers => {
    return suppliers && suppliers.filter(supplier => !!supplier)
  }
)

export const getUserMetricsSupplierCount = (state: RootState) => {
  const suppliers = getUserMetricsSuppliers(state)
  return suppliers?.size || 0
}
