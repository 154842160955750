import UserOnboardingPage from '../../components/UserOnboardingPage'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { UPDATE_USER } from '../../actionTypes'
import sessionSelectors from '../../selectors/sessionSelectors'
import routingSelectors from '../../selectors/routingSelectors'

const formName = 'shared/userOnboarding'

export default connectReduxForm(
  state => {
    const user = sessionSelectors.getUser(state)
    const isClientApp = routingSelectors.isClientApp(state)

    return {
      isClientApp,
      initialValues: {
        id: user.get('id'),
        firstName: user.get('firstName'),
        lastName: user.get('lastName'),
        title: user.get('title'),
        department: user.get('department'),
        officePhone: user.get('officePhone')
      }
    }
  },
  {
    onSubmit: createSubmitFormAction(formName, UPDATE_USER)
  },
  {
    form: formName
  },
  UserOnboardingPage
)
