import AppConfigIntegrations from 'admin/components/AppConfig/AppConfigIntegrations'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { linkBuyerWithIntegration } from 'admin/actions'
import integrationSelectors from 'buyer/SupplierProfile/selectors/integrationSelectors'

export default connect(
  (state: RootState) => {
    const currentUser = sessionSelectors.getUser(state)
    return {
      integrations: integrationSelectors.getIntegrations(state),
      currentIntegrator: integrationSelectors.getByField(state, 'integrator'),
      errorCredentials: integrationSelectors.getByField(
        state,
        'errorCredentials'
      ),
      isLoadingSupplierList: integrationSelectors.getByField(
        state,
        'isLoadingSupplierList'
      ),
      isConcierge: currentUser.get('email').includes('concierge'),
      batchAssessmentSupplierErrors: integrationSelectors.getByField(
        state,
        'errorSupplierList'
      )
    }
  },
  {
    linkBuyerWithIntegration
  }
)(AppConfigIntegrations)
