import { fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { LOGOUT } from 'shared/actionTypes'

const filterPanelsReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_FILTER_PANEL:
      return state
        .updateIn([action.payload.ref, 'collapse'], fromJS({}), collapse => {
          return !collapse
            ? fromJS({ [action.payload.key]: action.payload.value })
            : collapse.set(action.payload.key, action.payload.value)
        })
        .updateIn([action.payload.ref, 'lastAction'], fromJS({}), () => {
          return action.payload.value ? 'collapse' : 'expand'
        })

    case actionTypes.SET_FILTER_PANELS:
      return state.merge(
        fromJS({
          ...action.payload
        })
      )

    case actionTypes.CLEAR_FILTER_PANELS:
      return state.setIn([action.payload], fromJS({}))

    case LOGOUT:
      return fromJS({})

    default:
      return state
  }
}

export default filterPanelsReducer
