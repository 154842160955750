import React, { FC } from 'react'
import { List, RecordOf, Map } from 'immutable'
import Certification from 'shared/models/Certification'
import SupplierListItemIcons from '../../../shared/components/SupplierListItem/SupplierListItemIcons'
import Reputation from 'shared/components/Reputation'
import { WebsiteWarningType } from 'shared/components/WebsiteWarning'
import ImageArrowDown from 'shared/assets/icons/arrow-down.svg'
import { FormattedMessage } from 'react-intl'
import parsePath from 'shared/utils/parsePath'
import paths from '../../../routes/paths'
import supplierPaths from 'supplier/routes/paths'
import Link from 'shared/components/Link'
import { AccessControl } from 'shared/models/AccessControl'

type Props = {
  onExpand: (supplierId) => void
  id: string
  name: string
  additional?: boolean
  vetId?: string
  searchId: string
  isSupplierApp: boolean
  supplierType: string
  reputation?: number
  websiteUrl?: string
  isCurrentSupplier?: boolean
  redirectFrom?: string
  isPreferred?: boolean
  isDoNotUse?: boolean
  isRestricted?: boolean
  isExisting?: boolean
  isNonRecruit?: boolean
  isPrivate?: boolean
  isSanctionedCompany?: boolean
  isSanctionedCountry?: boolean
  sanctionedCountryList?: List<string>
  attachments?: List<
    RecordOf<{
      createdBy?: string
      createdDate?: string
      documentFileUrl?: string
      expirationDate?: string
      type?: string
    }>
  >
  diversityProgram?: boolean
  websiteWarning?: WebsiteWarningType
  certifications?: List<[string, List<RecordOf<Certification>>]>
  supplierUsers?: List<
    RecordOf<{
      id: string
      firstName: string
      lastName: string
      email: string
      blocked?: boolean
    }>
  >
  attachmentTypes: Map<string, Map<string, string>>
  relationshipTypes: Map<string, Map<string, string>>
  accessControl: AccessControl
}

const SupplierListItemMini: FC<Props> = ({
  id,
  searchId,
  vetId,
  redirectFrom,
  isSupplierApp,
  reputation,
  supplierType,
  name,
  onExpand,
  isPreferred,
  isDoNotUse,
  isRestricted,
  isExisting,
  isNonRecruit,
  attachments,
  isPrivate,
  isSanctionedCountry,
  isSanctionedCompany,
  sanctionedCountryList,
  certifications,
  supplierUsers,
  websiteWarning,
  diversityProgram,
  additional,
  attachmentTypes,
  relationshipTypes,
  accessControl,
  websiteUrl
}) => {
  const queryString = `?${searchId ? `searchId=${searchId}&` : ''}${
    vetId ? `vetId=${vetId}&` : ''
  }redirectFrom=${redirectFrom}`
  const profileUrl = `${parsePath(
    isSupplierApp ? supplierPaths.supplierProfile : paths.supplierProfile,
    {
      supplierId: id
    }
  )}${queryString}`
  const myCompanyUrl = `${parsePath(
    isSupplierApp ? supplierPaths.supplierProfile : paths.supplierProfile,
    {
      supplierId: id
    }
  )}/mycompany${queryString}`

  return (
    <div className='bg-near-white bb b--black-10 w-100'>
      <button
        className={`flex ph3 pv2 items-center pointer bg-transparent bn w-100`}
        aria-label={`Expand supplier: ${name}`}
        aria-expanded={false}
        onClick={() => onExpand(id)}
      >
        <div style={{ height: 16, width: 16 }}>
          {!isSupplierApp &&
            accessControl.access === 'full' &&
            reputation !== undefined &&
            reputation > 40 && (
              <Reputation
                className='mt0'
                mini
                reputation={reputation}
                linkTo={`${profileUrl}&scrollToReference=true`}
              />
            )}
        </div>
        <div className='flex-auto w-100 pl2 flex items-center'>
          <h5
            className={`f6 ma0 dib fw4 mr2`}
            style={{ wordBreak: 'break-word' }}
          >
            <Link
              to={profileUrl}
              arial-label={`Link to ${name}'s (${websiteUrl}) profile`}
            >
              {name}
            </Link>
          </h5>
          <SupplierListItemIcons
            profileUrl={profileUrl}
            myCompanyUrl={myCompanyUrl}
            name={name}
            showPremium={supplierType === 'basic'}
            isPreferred={isPreferred}
            isDoNotUse={isDoNotUse}
            isRestricted={isRestricted}
            isExisting={isExisting}
            isNonRecruit={isNonRecruit}
            attachments={attachments}
            isPrivate={isPrivate}
            isSanctionedCountry={isSanctionedCountry}
            isSanctionedCompany={isSanctionedCompany}
            sanctionedCountryList={sanctionedCountryList}
            certifications={certifications}
            supplierUsers={supplierUsers}
            websiteWarning={websiteWarning}
            diversityProgram={diversityProgram}
            attachmentTypes={attachmentTypes}
            relationshipTypes={relationshipTypes}
            websiteUrl={websiteUrl}
            disableActions={
              isSupplierApp || accessControl.access === 'punchout'
            }
          />
          {additional && (
            <div className='dib br2 ba b--light-silver f9 gray pa1 ml2'>
              <FormattedMessage
                id='SupplierListItemMini.RelatedOrganization'
                defaultMessage='Related Organization'
              />
            </div>
          )}
        </div>
        <div>
          <img src={ImageArrowDown} alt='' />
        </div>
      </button>
    </div>
  )
}

export default SupplierListItemMini
