import { useMemo } from 'react'
import qs from 'qs'
import { useLocation } from 'react-router'

const useQuery = <T>(): Partial<T> => {
  const location = useLocation()
  const locationSearch = location.search
  const parsedQuery = useMemo(
    () =>
      qs.parse(locationSearch, {
        ignoreQueryPrefix: true
      }),
    [locationSearch]
  )
  return parsedQuery
}

export default useQuery
