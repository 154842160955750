import React, { useEffect } from 'react'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'
import WebhookConfig from './WebhookConfig'
import { useDispatch } from 'react-redux'
import { loadWebhookConfigs } from '../../actions'

const TechWebhookConfig = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadWebhookConfigs())
  }, [dispatch])

  return (
    <div>
      <div className='db w-100'>
        <div className='dib w-25'>
          <Label className='db mb1 f7 fw6'>
            <FormattedMessage
              id='AppConfigWebhookConfig.Callbacks'
              defaultMessage='Webhook Callbacks'
            />
          </Label>
        </div>
        <div className='dib w-25'>
          <Label className='db mb1 f7 fw6'>
            <FormattedMessage
              id='AppConfigWebhookConfig.Security'
              defaultMessage='Security'
            />
          </Label>
        </div>
        <div className='dib w-50'>
          <Label className='db mb1 f7 fw6'>
            <FormattedMessage
              id='AppConfigWebhookConfig.KeyValues'
              defaultMessage='Key Values'
            />
          </Label>
        </div>
      </div>
      <WebhookConfig webhook='batchEnrichment' />
      <WebhookConfig webhook='punchout' />
    </div>
  )
}

export default TechWebhookConfig
