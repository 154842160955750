import { defineMessages } from 'react-intl'

export default defineMessages({
  inProgress: {
    id: 'vetStatus.Preparing',
    defaultMessage: 'Preparing'
  },
  interested: {
    id: 'vetStatus.Interested',
    defaultMessage: 'Interested'
  },
  accepted: {
    id: 'vetStatus.Accepted',
    defaultMessage: 'Accepted'
  },
  opened: {
    id: 'vetStatus.Opened',
    defaultMessage: 'Opened'
  },
  suggested: {
    id: 'vetStatus.Suggested',
    defaultMessage: 'Suggested'
  },
  invited: {
    id: 'vetStatus.Invited',
    defaultMessage: 'Invited'
  },
  declined: {
    id: 'vetStatus.Declined',
    defaultMessage: 'Declined'
  },
  chosen: {
    id: 'vetStatus.Chosen',
    defaultMessage: 'Chosen'
  },
  notChosen: {
    id: 'vetStatus.NotChosen',
    defaultMessage: 'Not Chosen'
  },
  sent: {
    id: 'vetStatus.Sent',
    defaultMessage: 'Sent'
  },
  reviewing: {
    id: 'vetStatus.Reviewing',
    defaultMessage: 'Reviewing'
  },
  closed: {
    id: 'vetStatus.Closed',
    defaultMessage: 'Closed'
  },
  cancelled: {
    id: 'vetStatus.Cancelled',
    defaultMessage: 'Cancelled'
  }
})
