import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import ListItem from 'shared/components/List/ListItem'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import communitiesSelectors from '../../../Communities/selectors/communitiesSelectors'
import OrgLogo from 'shared/components/OrgLogo'
import Text from 'shared/components/Text'
import Divider from 'shared/components/Divider'
import paths from '../../../Communities/routes/paths'
import parsePath from 'shared/utils/parsePath'
import { removeFromCommunity } from '../../../Communities/actions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { FormattedMessage } from 'react-intl'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from 'shared/components/Button'
import { RecordOf, List } from 'immutable'
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth'
import { createSelector } from 'reselect'

const viewFormButtonStyles =
  'fw4 no-underline dim dib b--transparent br2 ba pointer fw4 mid-gray bg-light-gray f8 w-auto pv1 ph2'

type Props = {
  memberships: List<
    RecordOf<{
      active: boolean
      created: { date: string; user: string }
      id: string
      parents: { Community: string }
      siblings?: {}
      status: string
      supplier: string
      response?: RecordOf<{ status: string }>
    }>
  >
  isBuyerAdmin: boolean
  myOrgUnitId: string
  communities: List<string>
  invitedCommunities: List<string>
  declinedCommunities: List<string>
  removeFromCommunity: (arg: {
    communityId?: string
    supplierId: string
  }) => void
} & RouteComponentProps &
  ContainerProps &
  WithWidthProps

export class CommunitiesContainer extends Component<Props> {
  state = {
    confirmDialogOpen: false,
    selectedCommunityId: undefined,
    selectedCommunity: undefined
  }

  toggleConfirmDialog = (communityId?, community?) => {
    this.setState({
      confirmDialogOpen: !this.state.confirmDialogOpen,
      selectedCommunityId: communityId,
      selectedCommunity: community
    })
  }

  removeSupplierFromCommunity = () => {
    const { removeFromCommunity, supplierId } = this.props

    const { selectedCommunityId: communityId } = this.state

    removeFromCommunity({ communityId, supplierId })

    this.toggleConfirmDialog()
  }

  renderCommunities(communities) {
    const {
      history: { push },
      memberships,
      removeFromCommunity,
      isBuyerAdmin,
      supplierId,
      myOrgUnitId
    } = this.props

    return communities.map((comm, index) => {
      const hasForm = comm.has('form')

      const membership = memberships.find(
        membership =>
          membership.getIn(['parents', 'Community']) === comm.get('id')
      )
      const response = membership && membership.get('response')
      const hasSubmitted = response && response.get('status') === 'submitted'

      const isCommunityBuyer = !!comm
        .get('buyers')
        .find(buyer => buyer.get('orgUnitId') === myOrgUnitId)

      return (
        <ListItem
          key={comm.get('id')}
          mini
          primaryText={comm.get('name')}
          secondaryText={
            hasForm ? (
              hasSubmitted ? (
                <button
                  className={viewFormButtonStyles}
                  onClick={() => {
                    push(
                      parsePath(paths.communityForm, {
                        communityId: comm.get('id'),
                        membershipId: membership && membership.get('id')
                      })
                    )
                  }}
                >
                  <FormattedMessage
                    id='CommunitiesContainer.viewForm'
                    defaultMessage='View Form'
                  />
                </button>
              ) : (
                <Text secondary>
                  <FormattedMessage
                    id='CommunitiesContainer.formIncomplete'
                    defaultMessage='Form Incomplete'
                  />
                </Text>
              )
            ) : membership && membership.get('status') === 'Invited' ? (
              <Text secondary>
                <FormattedMessage
                  id='CommunitiesContainer.invited'
                  defaultMessage='Invited'
                />
              </Text>
            ) : membership && membership.get('status') === 'Declined' ? (
              <Text secondary>
                <FormattedMessage
                  id='CommunitiesContainer.declined'
                  defaultMessage='Declined'
                />
              </Text>
            ) : null
          }
          leftAvatar={<OrgLogo url={comm.get('logoUrl')} />}
          rightButton={
            isBuyerAdmin &&
            isCommunityBuyer &&
            membership &&
            (membership.get('status') === 'Declined' ? (
              <IconButton
                onClick={() =>
                  removeFromCommunity({
                    communityId: comm.get('id'),
                    supplierId
                  })
                }
                style={{
                  width: 32,
                  height: 32
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <DropDownMenu>
                <MenuItem
                  onClick={() =>
                    this.toggleConfirmDialog(comm.get('id'), comm.get('name'))
                  }
                >
                  <FormattedMessage
                    id='CommunitiesContainer.removeFromCommunity'
                    defaultMessage='Remove from Community'
                  />
                </MenuItem>
              </DropDownMenu>
            ))
          }
        />
      )
    })
  }

  render() {
    const {
      communities,
      invitedCommunities,
      declinedCommunities,
      width
    } = this.props
    const { confirmDialogOpen, selectedCommunity } = this.state

    const matchMobile = width && ['xs', 'sm'].includes(width)

    return (
      <div>
        {communities.size > 0 && this.renderCommunities(communities)}
        {communities.size > 0 && invitedCommunities.size > 0 && (
          <Divider className='mv2' />
        )}
        {invitedCommunities.size > 0 &&
          this.renderCommunities(invitedCommunities)}
        {declinedCommunities.size > 0 &&
          this.renderCommunities(declinedCommunities)}

        <Dialog
          open={confirmDialogOpen}
          onClose={this.toggleConfirmDialog}
          fullWidth
          fullScreen={matchMobile}
        >
          <DialogTitle>
            <FormattedMessage
              id='CommunitiesContainer.confirmation'
              defaultMessage='Confirmation'
            />
          </DialogTitle>
          <DialogContent>
            <div className='mt3-ns mt5'>
              <FormattedMessage
                id='CommunitiesContainer.confirmationMessage1'
                defaultMessage='Are you sure you want to remove {community} from this supplier?'
                values={{
                  community: selectedCommunity
                }}
              />
            </div>
          </DialogContent>
          <DialogActions className='fixed static-ns bottom-1'>
            <FormattedMessage
              id='CommunitiesContainer.confirm'
              defaultMessage='Confirm'
            >
              {label => (
                <Button
                  size={matchMobile ? 'large' : undefined}
                  label={label}
                  autoSize
                  onClick={this.removeSupplierFromCommunity}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id='CancelButton'>
              {label => (
                <Button
                  size={matchMobile ? 'large' : undefined}
                  label={label}
                  autoSize
                  secondary
                  onClick={this.toggleConfirmDialog}
                />
              )}
            </FormattedMessage>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

type ContainerProps = {
  supplierId: string
}

const getAcceptedCommunities = communitiesSelectors.createGetSupplierCommunitiesSelector(
  'Accepted'
)
const getInvitedCommunities = communitiesSelectors.createGetSupplierCommunitiesSelector(
  'Invited'
)
const getDeclinedCommunities = communitiesSelectors.createGetSupplierCommunitiesSelector(
  'Declined'
)
const getMemberships = createSelector(
  (state, supplierId) =>
    communitiesSelectors.getMembershipsBySupplierId(state, supplierId),
  (memberships: any) => memberships.toList()
)

export default connect(
  (state, props: ContainerProps) => ({
    myOrgUnitId: sessionSelectors.getOrgUnitId(state),
    isBuyerAdmin: sessionSelectors.userHasRole(state, 'buyerDataAdmin'),
    communities: getAcceptedCommunities(state, props.supplierId),
    invitedCommunities: getInvitedCommunities(state, props.supplierId),
    declinedCommunities: getDeclinedCommunities(state, props.supplierId),
    memberships: getMemberships(state, props.supplierId)
  }),
  { removeFromCommunity }
)(withWidth()(withRouter(CommunitiesContainer)))
