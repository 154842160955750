import React, { Component, Fragment, ReactNode } from 'react'
import { Field } from 'redux-form/immutable'
import TextArea from '../TextArea'
import Input from '../Input'
import Button from '../Button'
import Avatar from '../Avatar'
import Warning from '../Warning'
import SelectPublicPrivate from '../SelectPublicPrivate'
import { FormattedMessage } from 'react-intl'
import Text from '../Text'

const styles = {
  form: 'dt w-100',
  leftColumn: 'dtc w2 v-top',
  rightColumn: 'dtc pl3 v-top',
  submitButton: 'mt3-ns mt2 mr2'
}

export interface AddCommentFormProps {
  profilePictureUrl: string
  inputPlaceholder: string
  addButtonLabel: string | ReactNode
  errorMessage: string
  isSubmitting: boolean
  className: string
  enablePublicPrivate: boolean
  noSubmitButton: boolean
  disabled: boolean
  firstName: string
  lastName: string
  onSubmit: () => void
  onCancel: () => void
}

class AddCommentForm extends Component<AddCommentFormProps> {
  render() {
    const {
      profilePictureUrl,
      inputPlaceholder,
      addButtonLabel,
      onSubmit,
      onCancel,
      errorMessage,
      className,
      enablePublicPrivate,
      noSubmitButton,
      disabled,
      firstName,
      lastName
    } = this.props

    return (
      <div id='addCommentForm' className={className}>
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.leftColumn}>
            <Avatar
              url={profilePictureUrl}
              className='w2'
              name={`${firstName || ''} ${lastName || ''}`}
            />
          </div>
          <div className={styles.rightColumn}>
            {!noSubmitButton && (
              <div>
                <Field
                  name='newNote'
                  component={TextArea}
                  placeholder={inputPlaceholder}
                  required
                  maxLength='1000'
                />
                <Button
                  label={
                    addButtonLabel || (
                      <FormattedMessage
                        id='AddCommentForm.Comment'
                        defaultMessage='Comment'
                      />
                    )
                  }
                  type='submit'
                  autoSize
                  size='small'
                  className={styles.submitButton}
                />

                {enablePublicPrivate && (
                  <Field
                    name='isPrivate'
                    component={SelectPublicPrivate}
                    className='v-mid'
                  />
                )}
              </div>
            )}

            {noSubmitButton && (
              <Fragment>
                <Field
                  name='message'
                  component={Input}
                  placeholder={
                    disabled
                      ? 'Messages cannot be sent to blocked users'
                      : inputPlaceholder
                  }
                  disabled={disabled}
                  autoComplete='new-message'
                  required
                />
                {!disabled && (
                  <Text className='mt1'>
                    <FormattedMessage
                      id='AddCommentForm.PressEnterToMessage'
                      defaultMessage='Press enter to send message.'
                    />
                  </Text>
                )}
              </Fragment>
            )}

            {onCancel && (
              <Button
                label={<FormattedMessage id='CancelButton' />}
                secondary
                type='reset'
                autoSize
                onClick={onCancel}
              />
            )}
          </div>
        </form>
        {errorMessage && <Warning message={errorMessage} className='mt3' />}
      </div>
    )
  }
}

export default AddCommentForm
