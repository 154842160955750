import supplierBasePath from '../routes/paths'

const basePath = supplierBasePath.diversity

const paths = {
  basePath,
  diversityInvitation: `${basePath}/invitation`,
  diversityLoad: `${basePath}/load`,
  diversityLoadSummary: `${basePath}/load/manual`,
  diversityReport: `${basePath}/report`,
  diversityShare: `${basePath}/share`
}

export default paths
