import React, { Component } from 'react'
import { connect } from 'react-redux'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import RatingQuestionField from 'shared/containers/RatingQuestionField'
import { FormattedMessage } from 'react-intl'
import { RecordOf, List } from 'immutable'
import RootState from 'shared/models/RootState'

type Props = {
  ratingQuestions: List<RecordOf<{ question: string; optional: string }>>
  noTitle: boolean
}
export class RatingQuestionFields extends Component<Props> {
  render() {
    const { ratingQuestions, noTitle } = this.props

    return ratingQuestions && ratingQuestions.size > 0 ? (
      <div>
        {!noTitle && (
          <h3 className='dib fw6 f7 mb2'>
            <FormattedMessage
              id='RatingQuestionsFields.YourRatingForThisSupplier'
              defaultMessage='Your rating for this supplier'
            />
          </h3>
        )}
        {ratingQuestions.map(ratingQuestion => (
          <div key={ratingQuestion.get('question')} className='mb3'>
            <RatingQuestionField
              key={ratingQuestion.get('question')}
              questionId={ratingQuestion.get('question')}
              required={!ratingQuestion.get('optional')}
            />
          </div>
        ))}
      </div>
    ) : null
  }
}

export default connect((state: RootState) => ({
  ratingQuestions: settingsSelectors.getRatingQuestions(state)
}))(RatingQuestionFields)
