import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import { Map, List, RecordOf, fromJS } from 'immutable'
import RootState from 'shared/models/RootState'
import CompanyProfileHeader from 'supplier/Company/components/CompanyProfileHeader'
import Loading from 'shared/components/Loading'
import Divider from 'shared/components/Divider'
import { loadSupplierProfile } from 'buyer/SupplierProfile/actions'
import PageSection from 'shared/components/PageSection'
import PageSideSection from 'shared/components/PageSideSection'
import { FormattedMessage, useIntl } from 'react-intl'
import Scrollable from 'shared/components/Scrollable'
import PitchDeck from 'shared/components/PitchDeck'
import Text from 'shared/components/Text'
import Pill from 'shared/components/Pill'
import Page from 'shared/components/Page'
import Main from 'shared/components/Layout/Main'
import LocationsGrid from 'shared/components/LocationsGrid'
import ContactsGrid from 'shared/components/ContactsGrid'
import CertificationCategoryTitle from 'shared/components/CertificationCategoryTitle'
import AttachmentCard from 'shared/components/AttachmentCard'
import certificationCategories, {
  CategoryType
} from 'shared/models/CertificationCategories'
import paths from '../../../routes/paths'
import qs from 'qs'
import profileSelectors from 'buyer/SupplierProfile/selectors/profileSelectors'
import { RouteComponentProps } from 'react-router-dom'
import ResourceLinks from 'shared/components/ResourceLinks'
import Aside from 'shared/components/Layout/Aside'
import SupplierProfileDetailsSection from 'shared/components/SupplierProfileDetailsSection'
import CorporateSection from 'shared/components/CorporateSection'
import Certification from 'shared/models/Certification'
import { CompanyLocationProps } from 'shared/components/CompanyLocation'
import { createSelector } from 'reselect'
import startCase from 'lodash.startcase'
import ClassificationCode from 'shared/models/ClassificationCode'
import Naics from 'shared/models/Naics'

type Props = {
  supplierId: string
  isLoading: boolean
  org: RecordOf<{
    id: string
    logoUrl: string
    coverUrl?: string
    name: string
    phone?: string
    pitchDeckUrl?: string
    locations: List<RecordOf<CompanyLocationProps>>
    websiteUrl: string
    linkedIn?: string
    facebook?: string
    twitter?: string
    publicView: boolean
    redirectFrom?: string
    description: string
    longDescription: string
    status?: string
    alternativeNames?: List<string>
    domains: Array<string>
    certifications: List<[CategoryType, List<RecordOf<Certification>>]>
    offerings: Map<string, string>
    differentiators: Map<string, string>
    blogUrl?: string
    videoUrl?: string
    serviceAreas: List<string>
    contacts: List<
      RecordOf<{
        user: string
      }>
    >
    dunsNumber?: number
    taxId?: number
    lei?: number | string
  }>
  parentOrg: RecordOf<{
    name: string
    employeeNumber: string | number
    yearFounded: number
    yearlyRevenue: string
  }>
  classificationCodesBySchema?: Map<string, List<ClassificationCode>>
  cpiLocation?: RecordOf<{
    countryCpi: number
    country: string
  }>
  loadSupplierProfile: (supplierId: string, opt?: object) => void
} & RouteComponentProps

const SupplierProfilePageContainer: FunctionComponent<Props> = props => {
  const intl = useIntl()
  const [redirectFrom, setRedirectFrom] = useState<string>('')

  const {
    location: { search },
    supplierId,
    loadSupplierProfile
  } = props

  useEffect(() => {
    const { vetId, searchId, ...restQueryParams } = qs.parse(search, {
      ignoreQueryPrefix: true
    })

    const { filter, scope, sort, q } = restQueryParams
    const queryString = qs.stringify({
      filter,
      scope,
      sort,
      q
    })

    restQueryParams.redirectFrom += queryString ? `&${queryString}` : ''

    setRedirectFrom(restQueryParams.redirectFrom)
    loadSupplierProfile(supplierId, {
      supplier: true,
      vetId,
      searchId
    })
  }, [search, supplierId, loadSupplierProfile])

  return props.isLoading || !props.org || !props.org.get('id') ? (
    <Loading />
  ) : (
    <div className='bg-white' style={{ height: '100%', minHeight: '100vh' }}>
      <CompanyProfileHeader
        logoUrl={props.org.get('logoUrl')}
        coverUrl={props.org.get('coverUrl')}
        name={props.org.get('name')}
        phone={props.org.get('phone')}
        address={props.org.getIn(['locations', '0'])}
        websiteUrl={props.org.get('websiteUrl')}
        linkedInUrl={props.org.get('linkedIn')}
        facebookUrl={props.org.get('facebook')}
        twitterId={props.org.get('twitter')}
        publicView={true}
        alternativeNames={props.org.get('alternativeNames')}
        status={props.org.get('status')}
        description={props.org.get('description')}
        domains={props.org.get('domains')}
        cpiLocation={props.cpiLocation}
        certifications={props.org.get('certifications')}
        redirectFrom={redirectFrom}
        history={props.history}
      />
      <Divider />
      <Page title=''>
        <Main>
          {(props.org.get('pitchDeckUrl') ||
            props.org.get('longDescription')) && (
            <PageSection
              title={
                <FormattedMessage
                  id='ProfilePage.AboutUs'
                  defaultMessage='About Us'
                />
              }
            >
              {props.org.get('longDescription') && (
                <Scrollable>
                  <Text className='mt3-5'>
                    {props.org.get('longDescription')}
                  </Text>
                </Scrollable>
              )}
              {props.org.get('pitchDeckUrl') && (
                <div className='tc mt3-5'>
                  <PitchDeck url={props.org.get('pitchDeckUrl') || ''} />
                </div>
              )}
            </PageSection>
          )}

          {props.org.get('offerings') && props.org.get('offerings').size > 0 && (
            <PageSection
              title={
                <FormattedMessage
                  id='SupplierProfilePageContainer.Offerings'
                  defaultMessage='What they are known for'
                />
              }
              noPadding
            >
              <Scrollable maxHeight={500}>
                <div className='mt3 ph3-5 pb3-5'>
                  {props.org
                    .get('offerings')
                    .entrySeq()
                    .map(([key, tag]) => (
                      <Pill key={key} label={tag} />
                    ))}
                </div>
              </Scrollable>
            </PageSection>
          )}

          {props.org.get('differentiators') &&
            props.org.get('differentiators').size > 0 && (
              <PageSection
                title={
                  <FormattedMessage
                    id='SupplierProfilePageContainer.Differentiators'
                    defaultMessage='What differentiate them'
                  />
                }
                noPadding
              >
                <Scrollable maxHeight={500}>
                  <div className='mt3 ph3-5 pb3-5'>
                    {props.org
                      .get('differentiators')
                      .entrySeq()
                      .map(([key, tag]) => (
                        <Pill key={key} label={tag} />
                      ))}
                  </div>
                </Scrollable>
              </PageSection>
            )}

          {props.org.get('locations') && props.org.get('locations').size > 0 && (
            <PageSection
              title={
                <FormattedMessage
                  id='SupplierProfilePageContainer.Locations'
                  defaultMessage='Locations'
                />
              }
              noPadding
            >
              <Scrollable>
                <LocationsGrid
                  locations={props.org.get('locations')}
                  publicView
                />
              </Scrollable>
            </PageSection>
          )}

          {props.org.get('contacts') && props.org.get('contacts').size > 0 && (
            <PageSection
              title={
                <FormattedMessage
                  id='SupplierProfilePageContainer.Team'
                  defaultMessage='Our Team'
                />
              }
              noPadding
            >
              <Scrollable>
                <ContactsGrid
                  contacts={props.org.get('contacts')}
                  baseUrl={paths.basePath}
                />
              </Scrollable>
            </PageSection>
          )}

          {props.org.get('certifications') &&
            props.org.get('certifications').size > 0 && (
              <PageSection
                title={
                  <FormattedMessage
                    id='SupplierProfilePageContainer.Qualifications'
                    defaultMessage='Qualifications'
                  />
                }
              >
                {props.org.get('certifications').map(([categoryKey, certs]) => (
                  <div key={categoryKey} className='pt3-5'>
                    <CertificationCategoryTitle
                      title={
                        certificationCategories[categoryKey]
                          ? intl.formatMessage(
                              certificationCategories[categoryKey].title
                            )
                          : startCase(categoryKey)
                      }
                      icon={
                        certificationCategories[categoryKey] &&
                        certificationCategories[categoryKey].icon
                      }
                    />
                    {certs &&
                      certs
                        .sort((certA, certB) => {
                          return certA.get('subCategory') >
                            certB.get('subCategory')
                            ? 1
                            : -1
                        })
                        .map((certification, index) => (
                          <div
                            key={`${index}-${categoryKey}${certification.get(
                              'subCategory'
                            )}`}
                          >
                            <AttachmentCard
                              expirationDate={certification.get(
                                'certExpiration'
                              )}
                              attachmentUrl={certification.get(
                                'certificationUrl'
                              )}
                              sourceUrl={certification.get('sourceURL')}
                              certAgency={certification.get('certAgency')}
                              certificateNumber={certification.get(
                                'certificateNumber'
                              )}
                              lastModifiedTimeStamp={certification.get(
                                'lastModifiedTimeStamp'
                              )}
                              supplierName={certification.get('supplierName')}
                            >
                              {certification.get('certifyingLocation') && (
                                <>
                                  <Text>
                                    <FormattedMessage id='CertifyingCountry' />:{' '}
                                    {certification.getIn([
                                      'certifyingLocation',
                                      'components',
                                      'country'
                                    ])}
                                  </Text>
                                </>
                              )}
                              {certification.get('certNaics') &&
                                certification
                                  .get('certNaics')
                                  ?.sort(naics =>
                                    naics.get('primary') ? -1 : 1
                                  )
                                  .map(naics => (
                                    <Text key={naics.get('code')}>
                                      {naics.get('code')} -{' '}
                                      <FormattedMessage
                                        {...Naics[naics.get('code')]}
                                      />
                                      &nbsp;
                                      {naics.get('primary') && (
                                        <FormattedMessage id='Primary'>
                                          {message => (
                                            <strong>({message})</strong>
                                          )}
                                        </FormattedMessage>
                                      )}
                                    </Text>
                                  ))}
                            </AttachmentCard>
                            <Divider className='mt3' />
                          </div>
                        ))}
                  </div>
                ))}
              </PageSection>
            )}

          {(props.org.get('blogUrl') ||
            props.org.getIn(['videoUrls', '0'])) && (
            <PageSection
              title={
                <FormattedMessage
                  id='SupplierProfilePageContainer.Resources'
                  defaultMessage='Resources'
                />
              }
            >
              <ResourceLinks
                blogUrl={props.org.get('blogUrl')}
                videoUrl={props.org.getIn(['videoUrls', '0'])}
              />
            </PageSection>
          )}
        </Main>
        <Aside>
          <div className='pt4-ns'>
            {props.parentOrg && (
              <CorporateSection
                isSupplierApp
                name={
                  props.parentOrg.get('name') !== props.org.get('name')
                    ? props.parentOrg.get('name')
                    : ''
                }
                numberOfEmployees={props.parentOrg.get('employeeNumber')}
                yearFounded={props.parentOrg.get('yearFounded')}
                annualRevenue={props.parentOrg.get('yearlyRevenue')}
                dunsNumber={props.org.get('dunsNumber')}
                taxId={props.org.get('taxId')}
                lei={props.org.get('lei')}
                classificationCodesBySchema={props.classificationCodesBySchema}
              />
            )}
            {props.org.get('serviceAreas') &&
              props.org.get('serviceAreas').size > 0 && (
                <PageSideSection
                  title={
                    <FormattedMessage
                      id='SupplierProfilePageContainer.ServiceAreas'
                      defaultMessage='Service Areas'
                    />
                  }
                >
                  <Scrollable>
                    <SupplierProfileDetailsSection
                      serviceAreas={props.org.get('serviceAreas')}
                    />
                  </Scrollable>
                </PageSideSection>
              )}
          </div>
        </Aside>
      </Page>
    </div>
  )
}

type ConnectProps = {
  match: {
    params: {
      supplierId: string
    }
  }
}

export default connect(
  (state: RootState, props: ConnectProps) => {
    const supplierId = props.match.params.supplierId
    const org = orgsSelectors.getByIdUpdatedData(state, supplierId)
    const isLoading = profileSelectors.isLoading(state)
    const parentOrgId = org && org.get('parentOrg')
    const parentOrg =
      parentOrgId && orgsSelectors.getByIdUpdatedData(state, parentOrgId)

    const getNaics = createSelector(
      (state: RootState) =>
        state.getIn(['orgs', 'byId', supplierId, 'classificationCodes']),
      classificationCodes => {
        return (
          classificationCodes &&
          classificationCodes.filter(
            classification =>
              !!classification.get('schema') &&
              classification.get('schema').toLowerCase() === 'naics'
          )
        )
      }
    )

    const getClassificationCodesBySchema = createSelector(getNaics, naics => {
      let classificationCodesBySchema = Map()
      if (naics && naics.size > 0) {
        classificationCodesBySchema = classificationCodesBySchema.set(
          'naics',
          naics
        )
      }
      return classificationCodesBySchema
    })

    const getCpiLocation = createSelector(
      (state: RootState) =>
        state.getIn(['orgs', 'byId', supplierId, 'locations']),
      locations => {
        const cpiLocation =
          locations &&
          locations.reduce((result, location) => {
            return !result
              ? location
              : location.get('countryCpi', 100) < result.get('countryCpi', 100)
              ? location
              : result
          })

        return cpiLocation
          ? fromJS({
              countryCpi: cpiLocation.get('countryCpi'),
              country: cpiLocation.get('country')
            })
          : fromJS({})
      }
    )
    return {
      isLoading,
      supplierId,
      org,
      parentOrg,
      classificationCodesBySchema: getClassificationCodesBySchema(state),
      cpiLocation: getCpiLocation(state)
    }
  },
  {
    loadSupplierProfile
  }
)(SupplierProfilePageContainer)
