import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import statsSelectors from '../../../shared/selectors/statsSelectors'
import WidgetWrapper from '../../components/WidgetWrapper'
import Text from 'shared/components/Text'
import ListItem from 'shared/components/List/ListItem'
import OrgLogo from 'shared/components/OrgLogo'
import Avatar from 'shared/components/Avatar'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import Scrollable from 'shared/components/Scrollable'
import companySelectors from '../../../shared/selectors/companySelectors'
import Button from 'shared/components/Button'
import PremiumBadge from 'shared/components/PremiumBadge'
import Tooltip from 'shared/components/Tooltip'
import Popover from '@material-ui/core/Popover'
import ClientUserDetail from './ClientUserDetail'
import classNames from 'classnames'
import { closeGetVerifiedDialog } from '../../../Company/actions'
import { FormattedMessage } from 'react-intl'
import { loadData } from 'shared/actions'
import GetVerifiedDialog from '../../../shared/components/GetVerifiedDialog'
import ConnectionListItem from './ConnectionListItem'
import { List, Map, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'

const styles = {
  widgetInnerBox: 'cf ph2-ns bg-white br2 ba b--black-10',
  widgetInnerCell: 'fl w-100 pa2',
  statValue: 'teal f2 f3-m lh-copy',
  text: 'mv3 normal'
}

type Props = {
  loadData: typeof loadData
  existing: List<
    RecordOf<{
      id: string
      name: string
    }>
  >
  preferred: List<
    RecordOf<{
      id: string
      name: string
    }>
  >
  followerCount: number
  followers: List<
    RecordOf<{
      clientCategorization: string
      followers: number
    }>
  >
  connectionCount: number
  connections: Map<
    string,
    RecordOf<{
      id: string
      logoUrl: string
      name: string
      users: List<
        RecordOf<{
          id: string
          avatarUrl: string
        }>
      >
    }>
  >
  isPremium: boolean
  closeGetVerifiedDialog: () => void
  verifyingOrgUnitId
}

type State = {
  showConnectionsMap: boolean
  showFollowingMap: boolean
  clientUserDetailAnchorEl: null
  clientId: string
  showClientUserDetail: string
}

export class Connections extends Component<Props, State> {
  state: State = {
    showConnectionsMap: false,
    showFollowingMap: false,
    clientId: '',
    showClientUserDetail: '',
    clientUserDetailAnchorEl: null
  }

  componentDidMount() {
    const { loadData } = this.props

    loadData({
      entity: 'supplierRelationships'
    })
  }

  toggleClientUserDetail = (e?, clientId?, clientUserId?) => {
    if (!e) {
      this.setState({
        clientId: '',
        showClientUserDetail: '',
        clientUserDetailAnchorEl: null
      })
    } else {
      this.setState({
        clientId,
        showClientUserDetail: clientUserId,
        clientUserDetailAnchorEl: e.target
      })
    }
  }

  getLogoUrl = customer => {
    const logoUrl = customer.get('logoUrl')
    const domain = customer.get('domain')
    return logoUrl || (domain ? `https://logo.clearbit.com/${domain}` : '')
  }

  render() {
    const {
      existing,
      preferred,
      followerCount,
      followers,
      connectionCount,
      connections,
      isPremium,
      closeGetVerifiedDialog,
      verifyingOrgUnitId
    } = this.props
    const {
      showConnectionsMap,
      showFollowingMap,
      clientId,
      showClientUserDetail,
      clientUserDetailAnchorEl
    } = this.state

    return (
      <Fragment>
        <WidgetWrapper
          title={
            <FormattedMessage
              id='Connections.CustomersOnTealbookTitle'
              defaultMessage='Customers on TealBook'
            />
          }
        >
          {!!connectionCount && (
            <div className={styles.widgetInnerBox}>
              <div className={styles.widgetInnerCell}>
                <div className={styles.statValue}>
                  {isPremium && showConnectionsMap && (
                    <Tooltip
                      title={
                        <FormattedMessage
                          id='Connections.PremiumContentTitle'
                          defaultMessage='Premium content delivered as part of your TealBook subscription'
                        />
                      }
                    >
                      <PremiumBadge className='fr' />
                    </Tooltip>
                  )}
                  {(connectionCount && connectionCount.toLocaleString()) || 0}
                </div>
                {isPremium && (
                  <Button
                    className='fr'
                    variant='text'
                    size='small'
                    autoSize
                    label={
                      showConnectionsMap ? (
                        <FormattedMessage
                          id='Connections.ShowLessLabel'
                          defaultMessage='Show Less'
                        />
                      ) : (
                        <FormattedMessage
                          id='Connections.ShowMoreLabel'
                          defaultMessage='Show More'
                        />
                      )
                    }
                    onClick={() =>
                      this.setState({
                        showConnectionsMap: !this.state.showConnectionsMap
                      })
                    }
                  />
                )}
                <Text style={{ lineHeight: '2em' }}>
                  <FormattedMessage
                    id='Connections.ConnectionsText'
                    defaultMessage='Connections'
                  />
                </Text>
              </div>
              {showConnectionsMap && isPremium && (
                <div className={styles.widgetInnerCell}>
                  <Scrollable>
                    {connections.toList().map(client => {
                      return (
                        <ListItem
                          key={client.get('id')}
                          mini
                          className='pv2'
                          leftAvatar={
                            <OrgLogo
                              url={client.get('logoUrl')}
                              name={client.get('name')}
                            />
                          }
                          primaryText={client.get('name')}
                          secondaryText={
                            <div>
                              {client
                                .get('users')
                                .toList()
                                .sort((userA, userB) =>
                                  !userA.get('avatarUrl') &&
                                  userB.get('avatarUrl')
                                    ? 1
                                    : -1
                                )
                                .map(user => {
                                  return (
                                    <div
                                      key={user.get('id')}
                                      className='dib w1-5 mr1 mb1'
                                      onClick={e =>
                                        this.toggleClientUserDetail(
                                          e,
                                          client.get('id'),
                                          user.get('id')
                                        )
                                      }
                                    >
                                      <Avatar
                                        url={user.get('avatarUrl')}
                                        className='pointer dim'
                                      />
                                    </div>
                                  )
                                })}
                            </div>
                          }
                        />
                      )
                    })}
                  </Scrollable>
                  <Popover
                    open={!!showClientUserDetail}
                    onClose={this.toggleClientUserDetail}
                    anchorEl={clientUserDetailAnchorEl}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                  >
                    <ClientUserDetail
                      clientId={clientId}
                      clientUserId={showClientUserDetail}
                    />
                  </Popover>
                </div>
              )}
            </div>
          )}

          {!!followerCount && (
            <div
              className={
                connectionCount
                  ? classNames(styles.widgetInnerBox, 'mt3')
                  : styles.widgetInnerBox
              }
            >
              <div className={styles.widgetInnerCell}>
                <div className={styles.statValue}>
                  {isPremium && showFollowingMap && (
                    <Tooltip
                      title={
                        <FormattedMessage
                          id='Connections.PremiumContentTitle'
                          defaultMessage='Premium content delivered as part of your TealBook subscription'
                        />
                      }
                    >
                      <PremiumBadge className='fr' />
                    </Tooltip>
                  )}
                  {(followerCount && followerCount.toLocaleString()) || 0}
                </div>
                {isPremium && (
                  <Button
                    className='fr'
                    variant='text'
                    size='small'
                    autoSize
                    label={
                      showFollowingMap ? (
                        <FormattedMessage
                          id='Connections.ShowLessLabel'
                          defaultMessage='Show Less'
                        />
                      ) : (
                        <FormattedMessage
                          id='Connections.ShowMoreLabel'
                          defaultMessage='Show More'
                        />
                      )
                    }
                    onClick={() =>
                      this.setState({
                        showFollowingMap: !this.state.showFollowingMap
                      })
                    }
                  />
                )}
                <Text style={{ lineHeight: '2em' }}>
                  <FormattedMessage
                    id='Connections.FollowersText'
                    defaultMessage='Followers'
                  />
                </Text>
              </div>
              {showFollowingMap && isPremium && (
                <div className={styles.widgetInnerCell}>
                  <Scrollable>
                    {followers.map(follower => {
                      return (
                        <ListItem
                          key={follower.get('clientCategorization')}
                          mini
                          className='pv2'
                          primaryText={follower.get('clientCategorization')}
                          secondaryText={
                            <Fragment>
                              <span className='teal'>
                                {follower.get('followers')}
                              </span>
                              &nbsp;
                              <FormattedMessage
                                id='Connections.FollowerCount'
                                defaultMessage={`{ followersValue, plural, one {follower} other {followers} }`}
                                values={{
                                  followersValue: follower.get('followers')
                                }}
                              />
                            </Fragment>
                          }
                        />
                      )
                    })}
                  </Scrollable>
                </div>
              )}
            </div>
          )}

          {preferred && preferred.size > 0 && (
            <div>
              <Text className={styles.text}>
                <FormattedMessage
                  id='Connections.CustomersWhoIdentifyPreferredText'
                  defaultMessage='Customers who identify you as a preferred supplier'
                />
              </Text>
              <div className={styles.widgetInnerBox}>
                <div className={styles.widgetInnerCell}>
                  <Scrollable>
                    {preferred &&
                      preferred.map(customer => {
                        return (
                          <ListItem
                            key={customer.get('id')}
                            mini
                            className='pv2'
                            leftAvatar={
                              <OrgLogo
                                url={this.getLogoUrl(customer)}
                                name={customer.get('name')}
                              />
                            }
                            primaryText={customer.get('name')}
                          />
                        )
                      })}
                  </Scrollable>
                </div>
              </div>
            </div>
          )}

          {existing && existing.size > 0 && (
            <div>
              <Text className={styles.text}>
                <FormattedMessage
                  id='Connections.CustomersWhoIdentifyExistingText'
                  defaultMessage='Customers who identify you as an existing supplier'
                />
              </Text>
              <div className={styles.widgetInnerBox}>
                <div className={styles.widgetInnerCell}>
                  <Scrollable>
                    {existing.map(customer => {
                      return (
                        <ConnectionListItem
                          key={customer.get('id')}
                          orgUnitId={customer.get('id')}
                          orgUnitName={customer.get('name')}
                          orgLogoUrl={this.getLogoUrl(customer)}
                        />
                      )
                    })}
                  </Scrollable>
                </div>
              </div>
            </div>
          )}
        </WidgetWrapper>

        <ClearbitCredit />

        <GetVerifiedDialog
          open={!!verifyingOrgUnitId}
          onClose={closeGetVerifiedDialog}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state: RootState) => ({
    isPremium: companySelectors.isPremium(state),
    orgUnitId: companySelectors.getCompanyField(state, 'id'),
    followerCount: statsSelectors.getFollowerCount(state),
    followers: statsSelectors.getFollowers(state),
    connections: statsSelectors.getConnections(state),
    connectionCount: statsSelectors.getConnectionCount(state),
    existing: statsSelectors.getBuyerExisting(state),
    preferred: statsSelectors.getBuyerPreferred(state),
    verifyingOrgUnitId: state.getIn([
      'supplier',
      'company',
      'verifyingOrgUnitId'
    ])
  }),
  {
    closeGetVerifiedDialog,
    loadData
  }
)(Connections)
