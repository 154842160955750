import { connect } from 'react-redux'
import profileSelectors from '../../selectors/profileSelectors'
import SocialFeed from '../../components/SocialFeed'
import { markPostRead } from 'posting/actions'

export default connect(
  state => ({
    feed: profileSelectors.getSocialFeed(state, 5)
  }),
  { onClickCallback: markPostRead }
)(SocialFeed)
