import React, { useState } from 'react'
import { List, Map, RecordOf } from 'immutable'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import {
  fade,
  makeStyles,
  withStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem'
import Scrollable from 'shared/components/Scrollable'

const MinusSquare = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z' />
    </SvgIcon>
  )
}

const PlusSquare = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z' />
    </SvgIcon>
  )
}

const CloseSquare = (props: SvgIconProps) => {
  return (
    <SvgIcon
      className='close'
      fontSize='inherit'
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z' />
    </SvgIcon>
  )
}

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3
      }
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`
    }
  })
)((props: TreeItemProps) => <TreeItem {...props} />)

const useStyles = makeStyles(
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 400
    }
  })
)

type Node = RecordOf<{
  key: string
  children: List<string>
  displayName: string
}>

type Props = {
  selected?: List<string>
  initialExpanded: Array<string>
  onChangeCheckbox: (...args: any[]) => void
  parentNodeList: List<Node>
  nodeMap: Map<string, Node>
}

type CheckboxTreeItemProps = {
  id: string
  name: string
  children?: React.ReactNode
}

const CheckboxTreeView = (props: Props) => {
  const classes = useStyles()
  const {
    selected,
    initialExpanded = Array<string>(),
    onChangeCheckbox,
    parentNodeList,
    nodeMap
  } = props
  const [expanded, setExpanded] = useState(initialExpanded)

  const CheckboxTreeItem = (treeProps: CheckboxTreeItemProps) => {
    const { id, name, children } = treeProps
    return (
      <StyledTreeItem
        nodeId={id}
        label={
          <label key={id} className='f7 fw3 mid-gray pointer pv1 db'>
            <input
              name={id}
              type='checkbox'
              className='b--black-10 mr1'
              defaultChecked={!!selected?.includes(id)}
            />
            {name}
          </label>
        }
        onLabelClick={event => onChangeCheckbox(id, !!selected?.includes(id))}
        onIconClick={event => {
          const idExists = nodeMap.get(id)
          const childrenExists = idExists && idExists.get('children')
          if (idExists && childrenExists && childrenExists.size > 0) {
            if (expanded.includes(id)) {
              setExpanded(expanded.filter(x => x !== id))
            } else {
              expanded.push(id)
            }
          }
        }}
      >
        {children}
      </StyledTreeItem>
    )
  }

  const renderCheckboxTreeItem = (node: Node) => {
    return (
      <div key={node.get('key')}>
        {' '}
        {node.get('children') && node.get('children').size > 0 ? (
          <CheckboxTreeItem id={node.get('key')} name={node.get('displayName')}>
            {node.get('children').map(child => {
              const temp = nodeMap.get(child)
              return temp ? renderCheckboxTreeItem(temp) : undefined
            })}
          </CheckboxTreeItem>
        ) : (
          <CheckboxTreeItem
            id={node.get('key')}
            name={node.get('displayName')}
          />
        )}
      </div>
    )
  }

  return (
    <Scrollable maxHeight={200}>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        expanded={expanded}
      >
        {parentNodeList.map(element => renderCheckboxTreeItem(element))}
      </TreeView>
    </Scrollable>
  )
}

export default CheckboxTreeView
