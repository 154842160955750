import { Component, createElement, HTMLProps, ReactNode } from 'react'
// import Input from '../Input'
import debounce from 'debounce'

const changeDebounceTime = 500 // miliseconds

export type NormalizeFunction<T> = (val: string) => T

type HtmlInputProps = HTMLProps<HTMLInputElement>

type Props = {
  name: string
  value: any
  required?: boolean
  validate?: Array<(val: any) => ReactNode>
  /**
   * Modify the user input into the desired data to be saved as
   */
  normalize?: NormalizeFunction<any>
  /**
   * Save when the input passes any validation and after it gets parsed
   */
  onSave?: (changes: { [fieldName: string]: any }) => void
  component?: any
  placeholder?: ReactNode | string
  fullWidth?: boolean
} & Pick<HtmlInputProps, Exclude<keyof HtmlInputProps, 'placeholder'>>

class AutoSaveInput extends Component<Props> {
  static defaultProps = {
    value: ''
  }

  state = {
    meta: {
      error: undefined,
      touched: false
    },
    value: this.props.value
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value === undefined) {
      return {
        value: ''
      }
    }
    return null
  }

  // debounce the value change by 1 second
  debounceChange = debounce(value => {
    this.triggerOnSave(value)
  }, changeDebounceTime)

  validate(value) {
    const { validate, required } = this.props

    if (required && !value) {
      return 'Field is required'
    }

    if (validate && validate.length > 0) {
      let error: ReactNode = ''
      validate.some(validateFn => {
        error = validateFn(value)
        return !!error
      })
      return error
    }
  }

  // normalize the value then trigger the onSave event
  triggerOnSave(value) {
    const { normalize, onSave, name } = this.props

    // validate input
    const error = this.validate(value)

    this.setState({
      meta: {
        touched: true,
        error
      }
    })

    if (onSave && !error) {
      const normalizedValue = normalize ? normalize(value) : value
      onSave({
        [name]: normalizedValue
      })
    }
  }

  handleChange = e => {
    const { onChange } = this.props
    const newValue = e.target.value

    this.setState({
      value: newValue
    })

    if (onChange) {
      onChange(e)
    }

    this.debounceChange(newValue)
  }

  render() {
    const { meta, value } = this.state

    const {
      name,
      component,

      // remove invalid input props
      validate,
      normalize,
      onSave,

      ...otherInputProps
    } = this.props

    return createElement(component, {
      ...otherInputProps,
      id: name,
      name,
      value,
      onChange: this.handleChange,
      meta
    })
  }
}

export default AutoSaveInput
