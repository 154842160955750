import React, { Component, ReactNode } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import TagCloud from 'shared/components/TagCloud'
import Paper from 'shared/components/Paper'
import Text from 'shared/components/Text'
import userMetricsSelectors from 'buyer/shared/selectors/userMetricsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { loadUserMetrics } from 'shared/actions'
import paths from 'buyer/routes/paths'
import qs from 'qs'
import { FormattedMessage } from 'react-intl'
import { Map } from 'immutable'
import RootState from 'shared/models/RootState'

type Props = {
  userId: string
  title: string | ReactNode
  loadUserMetrics: typeof loadUserMetrics
  isMyself: boolean
  tags: Map<string, number>
} & RouteComponentProps

export class TagCloudContainer extends Component<Props> {
  loadUserMetrics() {
    const { loadUserMetrics, userId } = this.props
    loadUserMetrics(userId)
  }

  componentDidMount() {
    this.loadUserMetrics()
  }

  componentDidUpdate(nextProps) {
    // load them if a user navigates to another user
    // TODO: move this loading of metrics to the top component of the page
    if (this.props.userId !== nextProps.userId) {
      this.loadUserMetrics()
    }
  }

  linkParser = (tag: string) => {
    const queryString = qs.stringify({
      q: tag
    })

    return `${paths.search}?${queryString}`
  }

  render() {
    const { isMyself, tags, title } = this.props
    const totalCount = tags
      .toList()
      .reduce<number>((total, count) => total + count, 0)
    return isMyself || tags.size > 0 ? (
      <div className='pt0-ns pt4'>
        <Paper>
          <div className='ma0 mb3 f7 fw6 mt3-5'>
            <h5 className='f7 fw6 mv0' id='tagCloudTitle'>
              {title}
            </h5>
            <div
              className='bg-teal fr white br-100 dt tc relative bottom-1'
              style={{ height: '40px', width: '40px' }}
            >
              <span className='dtc v-mid'>{totalCount}</span>
            </div>
          </div>
          <div className='mid-gray'>
            {tags.size > 0 ? (
              <TagCloud
                tags={tags.toJSON()}
                limit={30}
                linkParser={this.linkParser}
                colors={['#025F5F', '#005A8F', '#062B48', '#A7112A', '#555555']}
                aria-labelledby='tagCloudTitle'
              />
            ) : (
              <Text>
                <FormattedMessage
                  id='TagCloudContainer.TagASupplier'
                  defaultMessage='Tag a supplier to start building your interests.'
                />
              </Text>
            )}
          </div>
        </Paper>
      </div>
    ) : null
  }
}

export default connect(
  (state: RootState) => ({
    isMyself:
      userMetricsSelectors.getUserMetrics(state).get('userId') ===
      sessionSelectors.getUserId(state),
    tags: userMetricsSelectors.getUserMetricsTags(state)
  }),
  {
    loadUserMetrics
  }
)(withRouter(TagCloudContainer))
