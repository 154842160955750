import React, { Component, Fragment, ReactNode } from 'react'
import Select, { SelectProps } from 'shared/components/Select'
import { connect } from 'react-redux'
import postsSelectors from '../../selectors/postsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import Community from 'shared/models/Community'
import { List, RecordOf } from 'immutable'
import communitiesSelectors from '../../../buyer/Communities/selectors/communitiesSelectors'

type Props = {
  isPrivate?: boolean
  isMyCommunity?: boolean
  isClientApp?: boolean
  dispatch: ReactNode
  allowInterSupplierPosting?: boolean
} & ContainerProps &
  SelectProps

export class SelectAudience extends Component<Props> {
  render() {
    const {
      isPrivate,
      isMyCommunity,
      isClientApp,
      dispatch,
      communityIds,
      allowInterSupplierPosting,
      ...rest
    } = this.props

    return (
      <Fragment>
        <Select {...rest}>
          <FormattedMessage
            id='SelectAudience.ChooseAudienceOptions'
            defaultMessage='Choose audience'
          >
            {message => <option value=''>{message}</option>}
          </FormattedMessage>

          {allowInterSupplierPosting && (
            <FormattedMessage
              id='SelectAudience.AllOption'
              defaultMessage='All'
            >
              {message => <option value='all'>{message}</option>}
            </FormattedMessage>
          )}

          {isClientApp && isPrivate && isMyCommunity ? (
            <FormattedMessage
              id='SelectAudience.InternalOption'
              defaultMessage='Internal'
            >
              {message => <option value='buyer'>{message}</option>}
            </FormattedMessage>
          ) : (
            <FormattedMessage
              id='SelectAudience.BuyerOption'
              defaultMessage='Buyers'
            >
              {message => <option value='buyer'>{message}</option>}
            </FormattedMessage>
          )}
          {allowInterSupplierPosting && (
            <FormattedMessage
              id='SelectAudience.SupplierOption'
              defaultMessage='Supplier'
            >
              {message => <option value='supplier'>{message}</option>}
            </FormattedMessage>
          )}
        </Select>
      </Fragment>
    )
  }
}
type ContainerProps = {
  communityIds?: string[]
}
export default connect((state: RootState, props: ContainerProps) => {
  const user = sessionSelectors.getUser(state)
  const orgUnitId = user && user.get('orgUnitId')
  const communities: List<any> = postsSelectors.getCommunities(state)
  const firstCommunityId =
    props.communityIds && props.communityIds.length === 1
      ? props.communityIds[0]
      : ''
  const community: RecordOf<Community> | undefined = communities.find(
    (community: RecordOf<Community>) => community.get('id') === firstCommunityId
  )
  const allowInterSupplierPosting: boolean = !props.communityIds?.find(id =>
    communitiesSelectors.getCommunityById(state, id)
      ? communitiesSelectors
          .getCommunityById(state, id)
          .get('allowInterSupplierPosting') === false
      : false
  )
  const isPrivate = community && community.get('isPrivate')
  const isMyCommunity =
    community &&
    community
      .get('buyers')
      .findIndex(value => value.get('orgUnitId') === orgUnitId) !== -1

  return {
    allowInterSupplierPosting,
    isPrivate,
    isMyCommunity
  }
})(SelectAudience)
