import React, { ComponentType, memo } from 'react'
import { useSelector } from 'react-redux'
import usersSelectors from '../../selectors/usersSelectors'
import orgsSelectors from '../../selectors/orgsSelectors'
import RootState from 'shared/models/RootState'

type Props = {
  userId: string
}

type InjectedProps = {
  orgUnitId: string
  orgUnitName: string
  firstName: string
  lastName: string
  profilePictureUrl: string
  email: string
  title?: string
}

const withUser = <P extends {}>(Component: ComponentType<P>) =>
  memo((props: Props & Omit<P, keyof InjectedProps>) => {
    const user = useSelector((state: RootState) =>
      usersSelectors.getById(state, props.userId)
    )
    const orgUnitId = user?.get('orgUnitId')
    const org = useSelector(
      (state: RootState) => orgUnitId && orgsSelectors.getById(state, orgUnitId)
    )

    const userValues = {
      orgUnitId,
      orgUnitName: org?.get('name'),
      firstName: user.get('firstName'),
      lastName: user.get('lastName'),
      profilePictureUrl: user.get('profilePictureUrl'),
      email: user.get('email'),
      title: user.get('title')
    }
    return <Component {...((userValues as unknown) as P)} {...props} />
  })

export default withUser
