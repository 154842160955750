import { connect } from 'react-redux'
import companySelectors from '../../selectors/companySelectors'
import OnboardingCompany from '../../components/SupplierOnboardingPage/OnboardingCompany'
import { saveCompanyInfo, uploadFile } from '../../../Company/actions'
import RootState from 'shared/models/RootState'

export default connect(
  (state: RootState) => {
    const company = companySelectors.getCompanyHeaderInfo(state)
    const description = companySelectors.getMultiLangField(state, 'description')
    const longDescription = companySelectors.getMultiLangField(
      state,
      'longDescription'
    )

    return {
      name: company.name,
      description,
      longDescription,
      logoUrl: company.logoUrl,
      language: state.get('i18n').locale
    }
  },
  {
    saveCompanyInfo,
    uploadFile
  }
)(OnboardingCompany)
