import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import TagCloud from 'shared/components/TagCloud'
import insightsSelectors from '../../store/insightsSelectors'
import InsightPaper from 'shared/components/InsightPaper'
import InsightTitle from 'shared/components/InsightTitle'
import paths from '../../../routes/paths'
import qs from 'qs'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps } from 'react-router'
import Grid from '@material-ui/core/Grid'

type Props = {
  tags: any
} & RouteComponentProps

export class CompanyTags extends Component<Props> {
  linkParser = (tag: string) => {
    const queryString = qs.stringify({
      q: tag
    })

    return `${paths.search}?${queryString}`
  }

  render() {
    const { tags } = this.props

    return tags && tags.size > 0 ? (
      <Grid item md={6} xs={12}>
        <InsightTitle>
          <FormattedMessage
            id='CompanyTags.CompanyTags'
            defaultMessage='Company Tags'
          />
        </InsightTitle>
        <InsightPaper>
          <div className='tc pv3'>
            <TagCloud
              colors={['#025F5F', '#005A8F', '#062B48', '#A7112A', '#555555']}
              tags={tags.toJS()}
              linkParser={this.linkParser}
            />
          </div>
        </InsightPaper>
      </Grid>
    ) : null
  }
}

export default connect(state => ({
  tags: insightsSelectors.getCompanyTagsMap(state)
}))(withRouter(CompanyTags))
