import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Button from 'shared/components/Button'
import RootState from 'shared/models/RootState'
import { FormattedMessage } from 'react-intl'
import { closeCongratsSurveyDialog } from '../../actions'
import { ReactComponent as Tealbot } from 'shared/assets/images/Tealbot-Celebratory.svg'

const CongratsSurveyCompleteDialog = () => {
  const dispatch = useDispatch()
  const open = useSelector((state: RootState) =>
    state.getIn(['supplier', 'survey', 'openCongratsDialog'])
  )

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      dispatch(closeCongratsSurveyDialog())
    }
  }

  const handleDoneClick = event => {
    handleClose(event, 'done')
  }

  return open ? (
    <Dialog
      aria-labelledby='You have completed the Sustainability Survey'
      open={!!open}
      onClose={handleClose}
    >
      <DialogContent>
        <div className='tc'>
          <div className='dib w-100 w-70-ns'>
            <Tealbot />
          </div>
        </div>
        <div className='tc mb3'>
          <Typography variant='h5'>
            <FormattedMessage
              id='CongratsSurveyDialog.Header'
              defaultMessage={'Congratulations'}
            />
          </Typography>
          <p className='lh-copy f5 fw4 mid-gray ma3'>
            <FormattedMessage
              id='CongratsSurveyDialog.Body1'
              defaultMessage={'You have completed the Sustainability Survey!'}
            />
          </p>
          <p className='lh-copy f5 fw4 mid-gray ma3'>
            <FormattedMessage
              id='CongratsSurveyDialog.Body2'
              defaultMessage={`Customers and prospects can now view and learn more about your organization's sustainability initiatives.`}
            />
          </p>
          <Button autoSize onClick={handleDoneClick} aria-label='Close Dialog'>
            <div className='dib ph4'>
              <FormattedMessage
                id='CongratsSurveyDialog.Done'
                defaultMessage={'Done'}
              />
            </div>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  ) : null
}

export default CongratsSurveyCompleteDialog
