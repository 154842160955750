import React, { Component } from 'react'
import { connect } from 'react-redux'
import withUser from 'shared/utils/withUser'
import UserReview from '../../components/UserReview'
import parsePath from 'shared/utils/parsePath'
import paths from '../../../routes/paths'
import profileSelectors from '../../selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import { upvoteResponse, clearVoteResponse } from '../../actions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { RecordOf, List, Map } from 'immutable'
import RootState from 'shared/models/RootState'

type Props = {
  currentUserId: string
  onlyMine?: boolean
  reviewOptions: RecordOf<{
    rating: boolean
    preferred: boolean
  }>
  isTealbot: boolean
  userRatings: List<
    RecordOf<{
      userId: string
      comment: string
      preferred: 'preferred' | 'noOpinion'
      preferredCategories: string[]
      lastUpdated: string
      averageRating: number
      ratings:
        | List<{
            label: string
            value: number
          }>
        | undefined
      cardId: string
      likes: List<Map<string, Date>>
    }>
  >
  onEdit: () => void
  upvoteResponse: (arg: string) => void
  clearVoteResponse: (arg: string) => void
  onDelete: (arg: string | null) => void
}

const UserReviewContainer = withUser(UserReview)

export class UserReviews extends Component<Props> {
  state = {
    openDeleteDialog: false,
    selectedReview: null
  }

  handleLike = (liked, cardId) => {
    const { upvoteResponse, clearVoteResponse } = this.props
    if (liked) {
      clearVoteResponse(cardId)
    } else {
      upvoteResponse(cardId)
    }
  }

  handleDelete = id => {
    this.setState({
      openDeleteDialog: true,
      selectedReview: id
    })
  }

  handleCloseDialog = () => {
    this.setState({
      openDeleteDialog: false,
      selectedReview: null
    })
  }

  confirmDeleteReview = () => {
    const { onDelete } = this.props
    const { selectedReview } = this.state

    if (onDelete) {
      onDelete(selectedReview)
      this.handleCloseDialog()
    }
  }

  render() {
    const {
      userRatings,
      currentUserId,
      onlyMine,
      reviewOptions,
      isTealbot,
      onEdit
    } = this.props

    // filter for current user ratings/reviews in order to display to Personal Page
    const filteredRatings = onlyMine
      ? userRatings &&
        userRatings.filter(rating => rating.get('userId') === currentUserId)
      : userRatings

    return (
      <div>
        {filteredRatings &&
          filteredRatings.map(rating => {
            const isCurrentUserReview = currentUserId === rating.get('userId')
            const hasCurrentUserLiked = !!rating.getIn(['likes', currentUserId])

            return (
              <UserReviewContainer
                key={rating.get('userId')}
                userId={rating.get('userId')}
                userProfileUrl={parsePath(paths.userprofile, {
                  userId: rating.get('userId')
                })}
                comment={rating.get('comment')}
                preferred={rating.get('preferred')}
                preferredCategories={rating.get('preferredCategories')}
                lastUpdated={rating.get('lastUpdated')}
                averageRating={rating.get('averageRating')}
                ratings={rating.get('ratings')}
                reviewOptions={reviewOptions}
                onEdit={isCurrentUserReview ? onEdit : undefined}
                onDelete={
                  isCurrentUserReview || isTealbot
                    ? () => this.handleDelete(rating.get('cardId'))
                    : undefined
                }
                likes={rating.get('likes')}
                currentUserLiked={hasCurrentUserLiked}
                onLike={
                  !isCurrentUserReview
                    ? () =>
                        this.handleLike(
                          hasCurrentUserLiked,
                          rating.get('cardId')
                        )
                    : undefined
                }
              />
            )
          })}

        <Dialog
          open={this.state.openDeleteDialog}
          onClose={this.handleCloseDialog}
        >
          <DialogTitle>
            <FormattedMessage
              id='UserReview.Confirmation'
              defaultMessage='Confirmation'
            />
          </DialogTitle>
          <DialogContent>
            <div className='mt3'>
              <FormattedMessage
                id='UserReview.AreYouSureYouWantToDeleteTheReview'
                defaultMessage='Are you sure you want to delete the review?'
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              label={
                <FormattedMessage id='UserReview.Ok' defaultMessage='OK' />
              }
              onClick={this.confirmDeleteReview}
              autoSize
            />
            <Button
              label={<FormattedMessage id='CancelButton' />}
              onClick={this.handleCloseDialog}
              secondary
              autoSize
            />
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default connect(
  (state: RootState) => ({
    reviewOptions: settingsSelectors.getReviewOptions(state),
    userRatings: profileSelectors.getUserRatings(state),
    currentUserId: sessionSelectors.getUserId(state)
  }),
  { upvoteResponse, clearVoteResponse }
)(UserReviews)
