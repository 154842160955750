import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call } from 'redux-saga/effects'
import message from '../../utils/api/message'

export function* removeMessageSaga(action) {
  const { ancestorId, messageId, messageKind } = action.payload

  yield call(message.deleteMessage, messageId)

  return yield {
    messageKind,
    ancestorId,
    messageId
  }
}

export default createApiRequestSaga(removeMessageSaga)
