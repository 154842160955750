import React, { useContext } from 'react'
import { AnswerType } from 'shared/models/Job'
import NaicsQuestion from './NaicsQuestion'
import TextQuestion from './TextQuestion'
import MultipleChoiceQuestion from './MultipleChoiceQuestion'
import { List } from 'immutable'
import { JobAnswersContext } from '../JobDetails/JobDetails'

type Props = {
  questionId: string
  answerType: AnswerType
  rowNum: number
  required?: boolean
  text?: string
  answers?: List<string>
}

const JobQuestion = (props: Props) => {
  const context = useContext(JobAnswersContext)
  const value = context?.answers[props.questionId]
  const handleChange = context?.handleChange

  switch (props.answerType) {
    case 'NAICS':
      return <NaicsQuestion {...props} value={value} onChange={handleChange} />

    case 'freeForm':
      return <TextQuestion {...props} value={value} onChange={handleChange} />

    case 'multipleChoice':
      return (
        <MultipleChoiceQuestion
          {...props}
          value={value}
          onChange={handleChange}
        />
      )

    default:
      return null
  }
}

export default JobQuestion
