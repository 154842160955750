import { connect } from 'react-redux'
import VetContactUsDialog from '../../components/VetContactUsDialog'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'

export const mapStateToProps = (state, props) => {
  return {
    showConcierge: settingsSelectors.getSetting(state, 'showConcierge'),
    initialValues: props.notOnVet
      ? { sendTo: 'concierge' }
      : {
          vetId: currentVetSelectors.getVetField(state, 'id'),
          nameOfVet: currentVetSelectors.getVetField(state, 'name'),
          summary: currentVetSelectors.getVetField(state, 'description'),
          sendTo: 'concierge'
        }
  }
}

export default connect(mapStateToProps)(VetContactUsDialog)
