import { defineMessages } from 'react-intl'

const esgSurveyReportQuestions = defineMessages({
  'healthSafety-iso45001': {
    id: 'esgSurveyQuestions.iso45001',
    defaultMessage:
      'Has an occupational health and safety management system been implemented? (e.g., ISO 45001)'
  },
  'healthSafety-emergencyResponsePolicies': {
    id: 'esgSurveyQuestions.hazardIdentification',
    defaultMessage:
      'Are policies, processes, and procedures in place for hazard identification, risk assessment, incident investigation, and emergency response?'
  },
  'healthSafety-healthSafetyPolicies': {
    id: 'esgSurveyQuestions.workerParticipation',
    defaultMessage:
      'Are policies, processes, and procedures in place for worker participation, consultation, communication and training on occupational health and safety?'
  },
  'healthSafety-laggingIndicators': {
    id: 'esgSurveyQuestions.laggingIndicators',
    defaultMessage:
      'Are lagging indicators for work-related injuries measured and tracked (e.g., total recordable injury frequency (TRIF) and lost time injury frequency (LTIF))? If so, please provide performance statistics for the past 3 years.'
  },
  'healthSafety-leadingIndicators': {
    id: 'esgSurveyQuestions.leadingIndicators',
    defaultMessage:
      'Are leading indicators measured and tracked (e.g., near misses, hazard identifications, inspections)? If so, please provide performance statistics for the past 3 years.'
  },
  'healthSafety-liabilityPrograms': {
    id: 'esgSurveyQuestions.liabilityInsurance',
    defaultMessage: `Are liability insurance and workers' compensation programs (in accordance with local laws and regulations) in place and in good standing?`
  },
  'healthSafety-occupationalChargesViolations': {
    id: 'esgSurveyQuestions.chargeOccupationalSafety',
    defaultMessage:
      'Your organization <b>has not</b> had any charges, violations, or orders related to occupational health and safety.'
  },
  'environment-iso14001': {
    id: 'esgSurveyQuestions.iso14001',
    defaultMessage:
      'Is there an environmental management system in place? (e.g., ISO 14001)'
  },
  'environment-greenhouseGasInventory': {
    id: 'esgSurveyQuestions.greenhouseGasInventory',
    defaultMessage:
      'Has a greenhouse gas (GHG) inventory (carbon footprint) been completed in accordance with the GHG Protocol Corporate Standard?'
  },
  'environment-scopeEmissions': {
    id: 'esgSurveyQuestions.scopeEmissions',
    defaultMessage:
      'Does the carbon footprint include scope 1, 2, and 3 emissions?'
  },
  'environment-thirdPartyCert': {
    id: 'esgSurveyQuestions.thirdPartyCert',
    defaultMessage: 'Has this inventory been verified by a third party?'
  },
  'environment-ghgInventory': {
    id: 'esgSurveyQuestions.ghgInventory',
    defaultMessage:
      'Does your company have an official GHG inventory management plan in place?'
  },
  'environment-ghgReductionTarget': {
    id: 'esgSurveyQuestions.ghgReductionTarget',
    defaultMessage:
      'Has your company set a publicly reported GHG reduction target?'
  },
  'environment-validatedBySBTi': {
    id: 'esgSurveyQuestions.validatedBySBTi',
    defaultMessage:
      'Has this target been validated and published by the Science-Based Targets Initiative (SBTi)?'
  },
  'environment-iso50001': {
    id: 'esgSurveyQuestions.iso50001',
    defaultMessage:
      'Is there an energy management system in place? (e.g., ISO 50001)'
  },
  'environment-energyConsumptionTracking': {
    id: 'esgSurveyQuestions.energyConsumptionTracking',
    defaultMessage:
      'Have any of the following been completed: energy consumption tracking, on-site energy audits or programs to reduce energy use?'
  },
  'environment-waterUsageTracking': {
    id: 'esgSurveyQuestions.waterUsageTracking',
    defaultMessage:
      'Have any of the following been completed: water usage tracking, water usage audits or water conservation programs?'
  },
  'environment-wastePrograms': {
    id: 'esgSurveyQuestions.wastePrograms',
    defaultMessage:
      'Have any of the following been completed: waste audits or waste reduction/diversion programs (e.g., use of recycled or recyclable materials)?'
  },
  'environment-goalsTargets': {
    id: 'esgSurveyQuestions.goalsTargets',
    defaultMessage:
      'Are there goals or targets in place for any of the following.'
  },
  'environment-improvingEnergyEfficiency': {
    id: 'esgSurveyQuestions.improvingEnergyEfficiency',
    defaultMessage: 'Improving energy efficiency'
  },
  'environment-managingWaterUsage': {
    id: 'esgSurveyQuestions.managingWaterUsage',
    defaultMessage: 'Managing water usage'
  },
  'environment-managingWaste': {
    id: 'esgSurveyQuestions.managingWaste',
    defaultMessage: 'Managing waste'
  },
  'environment-negativeEnvironmentalImpacts': {
    id: 'esgSurveyQuestions.negativeEnvironmentalImpacts',
    defaultMessage:
      'Your organization <b>has not</b> been involved in any significant actual and/or potential negative environmental impacts (including charges, violations or orders), including to human health, biodiversity, land, vegetation, water bodies, and groundwater (e.g. spills) or others.'
  },
  'diversityInclusion-diversityCommitment': {
    id: 'esgSurveyQuestions.diversityCommitment',
    defaultMessage:
      'Does your company have a formal Diversity & Inclusion commitment?'
  },
  'diversityInclusion-publishedExternally': {
    id: 'esgSurveyQuestions.publishedExternally',
    defaultMessage: 'Is this statement published externally?'
  },
  'diversityInclusion-formalPolicies': {
    id: 'esgSurveyQuestions.formalPolicies',
    defaultMessage:
      'Does your company have formal policies that uphold Diversity & Inclusion within your organization?'
  },
  'diversityInclusion-diversityTraining': {
    id: 'esgSurveyQuestions.diversityTraining',
    defaultMessage:
      'Does your company provide training to your employees about Diversity & Inclusion?'
  },
  'diversityInclusion-integrateProcess': {
    id: 'esgSurveyQuestions.integrateProcess',
    defaultMessage:
      'Does your company integrate specific Diversity & Inclusion strategies into your supply chain and procurement processes?'
  },
  'diversityInclusion-diversityCertification': {
    id: 'esgSurveyQuestions.diversityCertification',
    defaultMessage: `Does your company have any recognized Diversity & Inclusion certifications (i.e. Canadian Council for Aboriginal Business Progressive Aboriginal Relations, Canadian Aboriginal and Minority Supplier Council, Women Business Enterprises Canada, Canadian Gay and Lesbian Chamber of Commerce, National Minority Supplier Development Council, Women's Business Enterprise National, National Gay and Lesbian Chamber of Commerce, or other)?`
  },
  'diversityInclusion-51PercentOwned': {
    id: 'esgSurveyQuestions.51PercentOwned',
    defaultMessage: `Are you a current or new supplier that is 51 percent or more owned, controlled, and managed by person(s) who belongs to one or more of the following groups:`
  },
  'governance-codeOfConduct': {
    id: 'esgSurveyQuestions.codeOfConduct',
    defaultMessage: `Code of Conduct or similar compliance and ethics policy`
  },
  'governance-antiCorruption': {
    id: 'esgSurveyQuestions.antiCorruption',
    defaultMessage: `Anti-Corruption (Anti-Bribery) Policy`
  },
  'governance-childForceLabour': {
    id: 'esgSurveyQuestions.childForceLabour',
    defaultMessage: `Child and Forced Labour Policy`
  },
  'governance-whistleblower': {
    id: 'esgSurveyQuestions.whistleblower',
    defaultMessage: `Whistleblower Policy`
  },
  'governance-complianceOfficer': {
    id: 'esgSurveyQuestions.complianceOfficer',
    defaultMessage: `Does your company have a designated Compliance Officer or similar position?`
  },
  'governance-activeEngagementProgram': {
    id: 'esgSurveyQuestions.activeEngagementProgram',
    defaultMessage: `Do you have an active engagement program that you use to determine the interests of your key stakeholders?`
  },
  'governance-requireAdhereCodeOfConduct': {
    id: 'esgSurveyQuestions.requireAdhereCodeOfConduct',
    defaultMessage: `Do you require your contractors and suppliers to adhere to a "Code of Conduct"?`
  },
  'governance-annualEsgReport': {
    id: 'esgSurveyQuestions.annualEsgReport',
    defaultMessage: `Does your organization publish an annual ESG, sustainability or corporate social responsibility report?`
  },
  'governance-useSustainabilityReportingFramework': {
    id: 'esgSurveyQuestions.useSustainabilityReportingFramework',
    defaultMessage: `Do you currently use sustainability reporting framework(s)?`
  },
  'privacy-complyGDPR': {
    id: 'esgSurveyQuestions.complyGDPR',
    defaultMessage: `Does your company comply with the EU's General Data Protection Regulation (GDPR)?`
  },
  'privacy-designatedPrivacy': {
    id: 'esgSurveyQuestions.designatedPrivacy',
    defaultMessage: `Does your company have a designated Privacy Officer or similar position?`
  }
})

export default esgSurveyReportQuestions
