import React from 'react'
import { Rating, RatingProps } from '@material-ui/lab'
import SelectedStar from 'shared/assets/icons/star-selected.svg'
import UnselectedStar from 'shared/assets/icons/star-unselected.svg'
import Icon from '@material-ui/core/Icon'
const CustomRating = (props: RatingProps) => {
  return (
    <Rating
      {...props}
      icon={
        <Icon fontSize='inherit'>
          <img src={SelectedStar} alt='Filled star' />
        </Icon>
      }
      emptyIcon={
        <Icon fontSize='inherit'>
          <img src={UnselectedStar} alt='Empty star' />
        </Icon>
      }
    />
  )
}
export default CustomRating
