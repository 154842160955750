import React, {
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  SyntheticEvent
} from 'react'
import parseSuppliersSearch from 'shared/utils/parseSuppliersSearch'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import { defineMessage, useIntl } from 'react-intl'
import { useLocation, useHistory } from 'react-router'
import qs from 'qs'

const messages = defineMessage({
  placeholder: {
    id: 'SearchOptions.Placeholder',
    defaultMessage: 'Enter word or phrase in quotation marks'
  },
  includes: {
    id: 'SearchOptions.Includes',
    defaultMessage: 'Has the words'
  },
  excludes: {
    id: 'SearchOptions.Excludes',
    defaultMessage: `Doesn't have`
  },
  includesName: {
    id: 'SearchOptions.IncludesName',
    defaultMessage: 'Name contains'
  },
  excludesName: {
    id: 'SearchOptions.ExcludesName',
    defaultMessage: 'Name excludes'
  },
  includesDescription: {
    id: 'SearchOptions.IncludesDescription',
    defaultMessage: 'Description contains'
  },
  excludesDescription: {
    id: 'SearchOptions.ExcludesDescription',
    defaultMessage: 'Description excludes'
  },
  includesOfferings: {
    id: 'SearchOptions.IncludesOfferings',
    defaultMessage: 'Offering contains'
  },
  excludesOfferings: {
    id: 'SearchOptions.ExcludesOfferings',
    defaultMessage: 'Offering excludes'
  },
  search: {
    id: 'SearchOptions.Search',
    defaultMessage: 'Search'
  },
  cancel: {
    id: 'SearchOptions.Cancel',
    defaultMessage: 'Cancel'
  }
})

type OptionRow = {
  name:
    | 'includes'
    | 'excludes'
    | 'includesName'
    | 'excludesName'
    | 'includesDescription'
    | 'excludesDescription'
    | 'includesOfferings'
    | 'excludesOfferings'
  value: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  focus?: boolean
}

const SearchOptionRow = (props: OptionRow) => {
  const { name, value, onChange, ...rest } = props

  const intl = useIntl()

  return (
    <div className='flex items-center flex-wrap mv2'>
      <div className='w-30'>
        <Text>{intl.formatMessage(messages[name])}</Text>
      </div>
      <div className='flex-auto'>
        <Input
          name={name}
          value={value}
          label={intl.formatMessage(messages.placeholder)}
          onChange={onChange}
          {...rest}
        />
      </div>
    </div>
  )
}

type Props = {
  searchString: string
  onDone: () => void
  onDoneKeyboard: () => void
}

const SearchOptions = (props: Props) => {
  const [includes, setIncludes] = useState<string>('')
  const [excludes, setExcludes] = useState<string>('')
  const [includesName, setIncludesName] = useState<string>('')
  const [excludesName, setExcludesName] = useState<string>('')
  const [includesDescription, setIncludesDescription] = useState<string>('')
  const [excludesDescription, setExcludesDescription] = useState<string>('')
  const [includesOfferings, setIncludesOfferings] = useState<string>('')
  const [excludesOfferings, setExcludesOfferings] = useState<string>('')

  const intl = useIntl()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const optionStrings = parseSuppliersSearch.qStringToOptionStrings(
      props.searchString
    )

    setIncludes(optionStrings.includes)
    setExcludes(optionStrings.excludes)
    setIncludesName(optionStrings.includesName)
    setExcludesName(optionStrings.excludesName)
    setIncludesDescription(optionStrings.includesDescription)
    setExcludesDescription(optionStrings.excludesDescription)
    setIncludesOfferings(optionStrings.includesOfferings)
    setExcludesOfferings(optionStrings.excludesOfferings)
  }, [props.searchString])

  const handleValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.name === 'includes') {
      setIncludes(e.currentTarget.value)
    } else if (e.currentTarget.name === 'excludes') {
      setExcludes(e.currentTarget.value)
    } else if (e.currentTarget.name === 'includesName') {
      setIncludesName(e.currentTarget.value)
    } else if (e.currentTarget.name === 'excludesName') {
      setExcludesName(e.currentTarget.value)
    } else if (e.currentTarget.name === 'includesDescription') {
      setIncludesDescription(e.currentTarget.value)
    } else if (e.currentTarget.name === 'excludesDescription') {
      setExcludesDescription(e.currentTarget.value)
    } else if (e.currentTarget.name === 'includesOfferings') {
      setIncludesOfferings(e.currentTarget.value)
    } else if (e.currentTarget.name === 'excludesOfferings') {
      setExcludesOfferings(e.currentTarget.value)
    }
  }, [])

  const handleSearch = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    const q = parseSuppliersSearch.optionStringsToQString({
      includes,
      includesName,
      includesDescription,
      includesOfferings,
      excludes,
      excludesName,
      excludesDescription,
      excludesOfferings
    })
    updateQueryString(q)
    props.onDone()
  }

  const updateQueryString = useCallback(
    (query: string) => {
      const { q, ...rest } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        arrayLimit: 100
      })

      const queryString = Object.assign(
        {},
        {
          q: query
        },
        rest
      )

      history.push({
        pathname: location.pathname,
        search: qs.stringify(queryString)
      })
    },
    [history, location.pathname, location.search]
  )

  return (
    <form
      onSubmit={handleSearch}
      onKeyDown={e => {
        if (e.key === 'Escape' || e.key === 'Esc') {
          e.preventDefault()
          props.onDoneKeyboard()
        } else if (e.key === 'Enter') {
          handleSearch(e)
          props.onDoneKeyboard()
        }
      }}
    >
      <SearchOptionRow
        name='includes'
        value={includes}
        onChange={handleValueChange}
        focus={true}
      />
      <SearchOptionRow
        name='excludes'
        value={excludes}
        onChange={handleValueChange}
      />
      <SearchOptionRow
        name='includesName'
        value={includesName}
        onChange={handleValueChange}
      />
      <SearchOptionRow
        name='excludesName'
        value={excludesName}
        onChange={handleValueChange}
      />
      <SearchOptionRow
        name='includesDescription'
        value={includesDescription}
        onChange={handleValueChange}
      />
      <SearchOptionRow
        name='excludesDescription'
        value={excludesDescription}
        onChange={handleValueChange}
      />
      <SearchOptionRow
        name='includesOfferings'
        value={includesOfferings}
        onChange={handleValueChange}
      />
      <SearchOptionRow
        name='excludesOfferings'
        value={excludesOfferings}
        onChange={handleValueChange}
      />
      <div className='flex justify-end mt3'>
        <Button
          autoSize
          size='large'
          secondary
          className='mr3'
          onClick={props.onDone}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
              e.stopPropagation()
              props.onDoneKeyboard()
            }
          }}
        >
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button autoSize size='large' type='submit'>
          {intl.formatMessage(messages.search)}
        </Button>
      </div>
    </form>
  )
}

export default SearchOptions
