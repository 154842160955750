import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
const apiRoute = `${apiRoutes.authService}/survey`

export const getSurveys = (supplierId: string) => {
  return api.get(`${apiRoute}/surveyResponse/profile/${supplierId}`)
}

export const getSurveyResponses = (surveyId: string) => {
  return api
    .get(`${apiRoute}/surveyResponseDetail/profile/${surveyId}`)
    .then((response: any[]) => {
      // process surveyResponseDetail
      // group by concat ids
      return response?.reduce((result, detail) => {
        const key = `${detail.pageId}-${detail.parentQuestionId || ''}-${
          detail.questionId
        }`
        result[key] = detail
        return result
      }, {})
    })
}

export const loadAllSurvey = () => {
  return api.get(`${apiRoute}/surveyResponse`).then((response: any) => {
    return response.reduce((byId, survey) => {
      byId[survey.id] = survey
      return byId
    }, {})
  })
}

export const saveSurvey = ({ surveyId, ...response }) => {
  return surveyId
    ? api.put(`${apiRoute}/surveyResponse/${surveyId}`, response)
    : api.post(`${apiRoute}/surveyResponse`, response)
}

export const editSurvey = surveyId => {
  return api
    .post(`${apiRoute}/edit/surveyResponse/${surveyId}`)
    .then(response => {
      return {
        response: response.newSurveyResponse,
        responseDetails: response.copiedSurveyResponseDetail.reduce(
          (byId, detail) => {
            byId[detail.id] = detail
            return byId
          },
          {}
        )
      }
    })
}

export const removeSurvey = (surveyId: string) => {
  return api.remove(`${apiRoute}/surveyResponse/${surveyId}`)
}

export const loadSurveyResponseDetails = (surveyId: string) => {
  return api
    .get(`${apiRoute}/surveyResponse/${surveyId}`)
    .then((response: any[]) => {
      return response.reduce((result, detail) => {
        result[detail.id] = detail
        return result
      }, {})
    })
}

export const saveSurveyResponse = ({
  surveyResponseDetailId,
  surveyId,
  ...response
}) => {
  return surveyResponseDetailId
    ? api.put(
        `${apiRoute}/surveyResponseDetail/${surveyResponseDetailId}`,
        response
      )
    : api.post(`${apiRoute}/surveyResponseDetail/${surveyId}`, response)
}
//adds pdf file to survey
export const uploadSurveyAttachment = ({ file, ...rest }) =>
  api
    .postFile(`${apiRoute}/surveyResponseDetail/attachment`, { file, ...rest })
    .then(response => {
      return {
        attachments: response,
        attachmentType: 'doc',
        ...rest
      }
    })

//removes cert and pdf file from survey
export const removeSurveyAttachment = ({
  attachmentType,
  surveyResponseDetailId,
  attachment
}) => {
  return api.remove(
    `${apiRoute}/surveyResponseDetail/attachment/${surveyResponseDetailId}`,
    { attachmentType, attachment }
  )
}

export const submitSurvey = surveyResponseId => {
  return api.post(`${apiRoute}/surveyResponse/submit/${surveyResponseId}`)
}

export const loadSustainabilityReportOverview = () => {
  return api
    .get(`${apiRoute}/surveySummary`)
    .then(({ completedSuppliers, ...rest }) => ({
      overview: {
        numExistingSuppliers: rest.numExistingSuppliers || 0,
        numCompletedSurveys: rest.numCompletedSurveys || 0,
        numInvitations: rest.numInvitations || 0,
        numCompletedInvitations: rest.numCompletedInvitations || 0,
        numCreatedStatusInvitations: rest.numInvitationsByStatus?.Created || 0,
        numInProgressStatusInvitations:
          rest.numInvitationsByStatus?.InProgress || 0,
        numCompletedStatusInvitations:
          rest.numInvitationsByStatus?.Completed || 0,
        numCerts: rest.numCerts || 0,
        numPositiveActions: rest.numPositiveActions || 0
      },
      completedSuppliers
    }))
}

export const getSustainabilityReportSummaryDetail = surveyPageID => {
  return api.get(`${apiRoute}/surveySummaryStats/${surveyPageID}`)
}

export const verifyAttachment = ({ surveyResponseDetailId, ...rest }) => {
  return api.put(
    `${apiRoute}/surveyResponseDetail/verifyAttachment/${surveyResponseDetailId}`,
    rest
  )
}

export const getESGSurveyRequestDetails = supplierID => {
  return api
    .get(
      `${apiRoutes.authService}/tasks/getESGSurveyRequestDetails/${supplierID}`
    )
    .then((response: any[]) => {
      return response.reduce((result, taskDetails) => {
        result[taskDetails.id] = taskDetails
        return result
      }, {})
    })
}

export const getIncompleteEgsExistingSuppliers = () => {
  return api.get(`${apiRoute}/suppliersForBulkOutreach`).then(response => {
    return response.map(({ supplier, companyContacts, ytdSpend }) => {
      // merge 2 lists, filter by unique user id
      const { contacts, ...rest } = supplier
      const mergeContacts = companyContacts.concat(contacts)
      const uniqueContacts = mergeContacts.filter((contact, index, arr) => {
        return (
          (contact.status === 'Registered' || contact.status === 'Normal') &&
          arr.findIndex(c => c.userId === contact.userId) === index
        )
      })
      return { supplier: rest, contacts: uniqueContacts, ytdSpend }
    })
  })
}
