import auth from 'shared/utils/api/auth'

type Result = {
  hasValidToken: boolean
  isValidatingToken: boolean
  showLoading: boolean
  passwordRules: { [key: string]: string }
  user: string
}

const validateToken = (token?: string): Promise<Result> => {
  const defaultResponse: Result = {
    hasValidToken: false,
    isValidatingToken: false,
    showLoading: false,
    passwordRules: {},
    user: ''
  }

  if (!token) {
    return Promise.resolve(defaultResponse)
  }

  // call this api to validate the token
  return auth.getPasswordRules(token).then(response =>
    response.ok
      ? response.json().then(({ user, ...passwordRules }) => ({
          ...defaultResponse,
          hasValidToken: true,
          passwordRules,
          user
        }))
      : defaultResponse
  )
}

export default validateToken
