import React, { Component } from 'react'
import ExternalLink from '../ExternalLink'
import { FormattedMessage } from 'react-intl'

class ClearBitCredit extends Component {
  render() {
    return (
      <ExternalLink
        href='http://clearbit.com'
        className='f8 fw3 gray dib underline-hover hover pointer mv3 fr'
      >
        <FormattedMessage
          id='ClearbitCredit.LogoProvidedByClearbit'
          defaultMessage='Logos provided by Clearbit'
        />
      </ExternalLink>
    )
  }
}

export default ClearBitCredit
