import React, { ReactNode } from 'react'
import classnames from 'classnames'

const paperStyles = 'bg-white br1 ba b--black-10'
const styles = {
  paper: paperStyles,
  paperWithPadding: `${paperStyles} ph3-5 pb3-5`
}

/**
 * A basic container that gives depth to the page
 */

type Props = {
  noPadding?: boolean
  className?: string
  children: ReactNode
}

const Paper = (props: Props) => {
  const classNames = props.noPadding ? styles.paper : styles.paperWithPadding

  return (
    <div className={classnames(classNames, props.className)}>
      {props.children}
    </div>
  )
}

export default Paper
