import React, { FunctionComponent, useState } from 'react'
import dateFormat from 'shared/utils/dateFormat'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import VetQuestionForm from './VetQuestionForm'
import Avatar from 'shared/components/Avatar'

type Props = {
  id: string
  userProfilePictureUrl: string
  userName: string
  dateCreated: string
  description: string
  response?: string
  responseDate?: string
  responseIsPublic?: boolean
  canEdit?: boolean
}

export const VetQuestion: FunctionComponent<Props> = props => {
  const [editQuestion, setEditQuestion] = useState<boolean>(false)

  if (editQuestion) {
    return (
      <VetQuestionForm
        isEditing
        questionId={props.id}
        description={props.description}
        onCancelEditing={() => setEditQuestion(false)}
      />
    )
  }

  return (
    <div className='bb b--black-10 pa3-5'>
      <div className='flex'>
        <Avatar
          url={props.userProfilePictureUrl}
          className='w2-5 h2-5'
          name={props.userName}
        />
        <div className='ml3 flex-auto f7'>
          <div className='mt2'>
            <div className='fw6 mid-gray'>
              {props.userName || (
                <FormattedMessage
                  id='VetQuestions.Anonymous'
                  defaultMessage='Anonymous'
                />
              )}
            </div>
            <div className='mt1 gray'>{dateFormat(props.dateCreated)}</div>
          </div>
          <div className='mt3 fw6'>{props.description}</div>
          <div className='mt2 mid-gray'>
            {props.response || (
              <FormattedMessage
                id='VetQuestions.AwaitingAnswer'
                defaultMessage='Awaiting answer'
              />
            )}
          </div>
        </div>
        <div className='mt2 fw6'>
          {props.canEdit && (
            <Button
              label={
                <FormattedMessage
                  id='VetQuestions.Edit'
                  defaultMessage='Edit'
                />
              }
              onClick={() => setEditQuestion(true)}
              secondary
            />
          )}

          {!props.canEdit && !!props.response && (
            <span className='f7'>
              {props.responseIsPublic ? (
                <FormattedMessage
                  id='VetQuestions.Public'
                  defaultMessage='Public'
                />
              ) : (
                <FormattedMessage
                  id='VetQuestions.Private'
                  defaultMessage='Private'
                />
              )}
            </span>
          )}
        </div>
      </div>
      {props.responseDate && (
        <div className='tr mt3 f7 gray'>
          <FormattedMessage
            id='VetQuestions.Responded'
            defaultMessage='Responded {respondedDate}'
            values={{
              respondedDate: dateFormat(props.responseDate)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default VetQuestion
