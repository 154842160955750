import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import postsApi from 'shared/utils/api/posts'

export function* loadPostsByTagSaga(action) {
  let byTag = {}
  let byId = {}
  const posts: Array<any> = yield call(postsApi.getByTag, action.payload)
  byTag[action.payload.tag] = posts
  byId = posts.reduce((byId, post) => {
    byId[post.id] = post
    return byId
  }, {})

  return yield { byTag, byId }
}

export default createApiRequestSaga(loadPostsByTagSaga)
