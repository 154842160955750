import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select } from 'redux-saga/effects'
import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import { fromJS } from 'immutable'
import supplier from 'shared/utils/api/supplier'

export function* removeVetContactSaga(action) {
  const vetBuyerSupplier = yield select(
    vetBuyerSupplierSelectors.getVetBuyerSupplier
  )
  let sendToContacts = vetBuyerSupplier.getIn(
    ['collaboration', 'sendToContacts'],
    fromJS([])
  )
  const indexContact = sendToContacts.findIndex(
    contact => contact.get('user') === action.payload
  )

  let response
  if (indexContact !== -1) {
    sendToContacts = sendToContacts.delete(indexContact)
    response = yield call(
      supplier.updateVetProposal,
      Object.assign(
        {},
        { vetBuyerSupplierId: vetBuyerSupplier.get('id') },
        { sendToContacts: sendToContacts.toJS() }
      )
    )
  }

  return yield {
    sendToContacts: indexContact !== -1 ? response.sendToContacts : undefined
  }
}

export default createApiRequestSaga(removeVetContactSaga, {
  notifyOnError: true
})
