import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select, put } from 'redux-saga/effects'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import supplierRelationships from 'shared/utils/api/supplierRelationships'
import users from 'shared/utils/api/users'
import { messageUser, updateData } from 'shared/actions'
import { fromJS } from 'immutable'

export function* getVerifiedSaga(action) {
  const {
    user,
    message,
    orgUnitId,
    orgUnitName,
    supplierRelationshipId
  } = action.payload.toJS()

  // try to get the user by its email
  const usersById = yield call(users.getAll, {
    email: user.email,
    orgUnitId
  })

  const userToMessage = Object.values(usersById)[0]

  // if supplierRelationshipId does not exist, create a supplierRelationshipId
  let newSupplierRelationship
  if (!supplierRelationshipId) {
    newSupplierRelationship = yield call(supplierRelationships.create, {
      buyer: orgUnitId,
      buyerName: orgUnitName,
      supplierCollaboration: {
        isClient: true
      }
    })
  }

  // send an email to the user to verify themselves as a client
  yield call(supplierRelationships.requestUserToVerifySupplier, {
    supplierRelationshipId:
      supplierRelationshipId || newSupplierRelationship.id,
    buyerFirstName: user.firstName,
    buyerLastName: user.lastName,
    email: user.email,
    clientName: orgUnitName
  })

  if (userToMessage && userToMessage.id) {
    // send a message to the user
    const supplierToShare = yield select(sessionSelectors.getUser)
    yield put(
      messageUser(
        fromJS({
          message,
          userToMessageId: userToMessage.id,
          orgUnitId: userToMessage.orgUnitId,
          supplierToShare: supplierToShare.get('orgUnitId')
        })
      )
    )
  }

  yield put(
    updateData({
      entity: 'supplierRelationships',
      record: {
        id: supplierRelationshipId || newSupplierRelationship.id,
        supplierCollaboration: {
          isClient: true,
          requestVerify: true,
          contactUserId: userToMessage && userToMessage.id,
          contactName: user.firstName + ' ' + user.lastName,
          contactEmail: user.email
        }
      }
    })
  )

  return yield {}
}

export default createApiRequestSaga(getVerifiedSaga)
