import React from 'react'
import Select from 'shared/components/Select'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import RootState from 'shared/models/RootState'
import camelCase from 'lodash.camelcase'

export const messages = defineMessages({
  select: {
    id: 'SelectConnectionRole.SelectOrEnterA',
    defaultMessage: 'Select or enter a role'
  },
  primaryContact: {
    id: 'SelectConnectionRole.PrimaryContact',
    defaultMessage: 'Primary Contact'
  },
  secondaryContact: {
    id: 'SelectConnectionRole.SecondaryContact',
    defaultMessage: 'Secondary Contact'
  },
  categoryManager: {
    id: 'SelectConnectionRole.CategoryManager',
    defaultMessage: 'Category Manager'
  }
})

type Props = {
  value: string
  onChange: (e: any) => void
}

const SelectConnectionRole = (props: Props) => {
  const followerRoles = useSelector((state: RootState) =>
    settingsSelectors.getFollowerRolesList(state)
  )
  const intl = useIntl()
  return (
    <Select {...props}>
      <option value=''>{intl.formatMessage(messages.select)}</option>
      {followerRoles?.map(role => (
        <option value={role.get('key')} key={role.get('key')}>
          {messages[camelCase(role.get('label'))]
            ? intl.formatMessage(messages[camelCase(role.get('label'))])
            : role.get('label')}
        </option>
      ))}
    </Select>
  )
}

export default SelectConnectionRole
