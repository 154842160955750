import React, { ChangeEvent, memo, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import Button from 'shared/components/Button'
import RadioButton from 'shared/components/RadioButton'
import RadioButtonGroup from 'shared/components/RadioButton/RadioButtonGroup'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import {
  becomeTealbot,
  closeLoginDialog
} from '../../../data-portal/store/actions'
import { useDispatch, useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import { RecordOf } from 'immutable'
import storageManager from '../../utils/storageManager'
import sessionSelectors from 'shared/selectors/sessionSelectors'

type Props = {
  openDialog: boolean
  reasonItems: RecordOf<{
    orgUnitId: string
    orgName: string
    redirectTo?: string
    userMobilePhone: string
  }>
  userMobilePhone?: string
}

type ReasonForConcierge = {
  selectedReason: string
  otherReasonText: string
}

const BecomeTealbotDialog = memo((props: Props) => {
  const { openDialog, reasonItems, userMobilePhone } = props
  const dispatch = useDispatch()
  const lastSelectedReasonForConcierge = storageManager.getItem('reasonKey')

  const [oneTimeKey, setOneTimeKey] = useState<number | ''>('')
  const [reasonForConcierge, setReasonForConcierge] = useState<
    ReasonForConcierge
  >({
    selectedReason: lastSelectedReasonForConcierge?.selectedOption || '',
    otherReasonText:
      lastSelectedReasonForConcierge?.selectedOption === 'otherReason'
        ? lastSelectedReasonForConcierge.otherReasonText
        : ''
  })

  const handleCloseLoginDialog = () => {
    setReasonForConcierge({ selectedReason: '', otherReasonText: '' })
    setOneTimeKey('')
    dispatch(closeLoginDialog())
  }

  const handleReasonForRadio = (e: ChangeEvent<HTMLInputElement>) => {
    let tempArray = { ...reasonForConcierge }
    tempArray.selectedReason = e.currentTarget.value
    // if radiobutton selected isn't other, clear reason text
    tempArray.otherReasonText =
      tempArray.selectedReason !== 'otherReason'
        ? ''
        : tempArray.otherReasonText
    setReasonForConcierge(tempArray)
  }
  const handleReasonForText = (e: ChangeEvent<HTMLInputElement>) => {
    let tempArray = { ...reasonForConcierge }
    //if input text, set radio to 'other'
    tempArray = {
      selectedReason: 'otherReason',
      otherReasonText: e.currentTarget.value
    }
    setReasonForConcierge(tempArray)
  }
  const handleSetKey = (e: ChangeEvent<HTMLInputElement>) => {
    setOneTimeKey(e.currentTarget.value ? Number(e.currentTarget.value) : '')
  }

  const handleSubmit = () => {
    dispatch(
      becomeTealbot({
        id: reasonItems?.get('orgUnitId'),
        name: reasonItems?.get('orgName'),
        onetimeKey: oneTimeKey,
        reason:
          reasonForConcierge.selectedReason === 'otherReason'
            ? reasonForConcierge.otherReasonText
            : reasonForConcierge.selectedReason,
        redirectTo: reasonItems?.get('redirectTo')
      })
    )
    storageManager.setItem('reasonKey', {
      selectedOption: reasonForConcierge.selectedReason,
      otherReasonText: reasonForConcierge.otherReasonText
    })
    handleCloseLoginDialog()
  }

  return (
    <Dialog open={!!openDialog} onClose={handleCloseLoginDialog} fullWidth>
      <DialogTitle onClose={handleCloseLoginDialog}>
        {userMobilePhone
          ? `Tell us why you're logging in as ${reasonItems?.get('orgName')}`
          : 'There was an error trying to log in.'}
      </DialogTitle>
      <DialogContent className='mb3'>
        {!userMobilePhone && (
          <div className='dib pa2 lh-copy f7 fw4 mid-gray'>
            <Text className='hover-teal'>
              We need your mobile number to send you an access code. Please
              visit the My Account page to update your profile.
            </Text>
          </div>
        )}
        {!!reasonItems && (
          <form
            onSubmit={e => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <div className='mtb'>
              <Text className='f6 fw6 pa0 mb2 mid-gray mr3'>
                Enter your 4 digit PIN here.
              </Text>
              <div className='mb3'>
                <Input
                  onChange={handleSetKey}
                  type='number'
                  value={oneTimeKey}
                  required
                  min={4}
                />
              </div>
              <div className='mb4'>
                <RadioButtonGroup
                  title='Please give a reason for becoming concierge.'
                  stack
                >
                  <RadioButton
                    className='flex mv1'
                    label='Supplier requested support'
                    value='requestedSupport'
                    checked={
                      reasonForConcierge.selectedReason === 'requestedSupport'
                    }
                    onChange={handleReasonForRadio}
                  />
                  <RadioButton
                    className='flex mv1'
                    label='Supplier profile content issue'
                    value='contentIssue'
                    checked={
                      reasonForConcierge.selectedReason === 'contentIssue'
                    }
                    onChange={handleReasonForRadio}
                  />
                  <RadioButton
                    className='flex mv1'
                    label='Other: Please Describe'
                    value='otherReason'
                    checked={
                      reasonForConcierge.selectedReason === 'otherReason'
                    }
                    onChange={handleReasonForRadio}
                  />
                </RadioButtonGroup>
                <Input
                  onChange={handleReasonForText}
                  type='text'
                  value={reasonForConcierge.otherReasonText}
                  name='otherReasonText'
                  minLength={10}
                  required={reasonForConcierge.selectedReason === 'otherReason'}
                />
              </div>
              <Button
                id='login-button'
                label='Login'
                autoSize
                className='mr3'
                type='submit'
              />
              <Button
                label='Cancel'
                secondary
                autoSize
                onClick={handleCloseLoginDialog}
              />
            </div>
          </form>
        )}
      </DialogContent>
    </Dialog>
  )
})

const BecomeTealbotDialogContainer = () => {
  const reasonItems = useSelector((state: RootState) =>
    state.getIn(['dataPortal', 'reasonKeyReducer', 'reasonItems'])
  )

  const openDialog = useSelector((state: RootState) =>
    state.getIn(['dataPortal', 'reasonKeyReducer', 'openDialog'])
  )

  const user = useSelector((state: RootState) =>
    sessionSelectors.getUser(state)
  )

  const userMobilePhone = user && user.get('mobilePhone')

  return (
    <BecomeTealbotDialog
      openDialog={openDialog}
      reasonItems={reasonItems}
      userMobilePhone={userMobilePhone}
    />
  )
}
export default BecomeTealbotDialogContainer
