import { Record, fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { LOGOUT, requestFailure, requestSuccess } from 'shared/actionTypes'
import {
  CONNECT_TO_SUPPLIER,
  SHOW_MORE_SUPPLIERS
} from '../../../shared/actionTypes'
import { ADD_PERSONAL_RELATIONSHIP } from '../../../SupplierProfile/actionTypes'
import { CREATE_VET } from '../../../Vets/actionTypes'
import camelCase from 'lodash.camelcase'

export const SearchRecord = Record({
  q: '',
  // resultSupplierIds: fromJS([]),
  resultsKey: '',
  results: fromJS([]),
  resultsCount: 0,
  aggregations: fromJS({}),
  communities: fromJS({}),
  searching: false,
  aggregating: false,
  showDetails: true,
  supplierId: '',
  cardId: '',
  searchId: '',
  reviewCard: undefined,
  reviewSupplierName: '',
  isReviewDialogOpen: false,
  isAddTagDialogOpen: false,
  isAddNoteDialogOpen: false,
  scrollPosition: 0,
  searchError: undefined,
  queryStringByRef: fromJS({}),
  numberOfSuppliersToShow: 10,
  showFilterDialog: false,
  location: fromJS({
    locationQuery: '',
    searching: false,
    results: {}
  })
})

const searchReducer = (state = new SearchRecord(), action) => {
  let resultIndex, peerTags, attributesValues, attributesKeys, card
  switch (action.type) {
    case actionTypes.CLEAR_SEARCH_KEYWORD:
      return state.merge({
        q: action.payload || '',
        resultsCount: 0
      })

    case actionTypes.SEARCH_SUPPLIER:
      if (action.payload.aggregateQuery) {
        return state
      }
      return state.merge({
        q: action.payload || '',
        searching: true,
        aggregating: true,
        scrollPosition: 0,
        searchError: undefined,
        // resultSupplierIds: fromJS([]),
        numberOfSuppliersToShow: 10
      })
    case requestSuccess(actionTypes.SEARCH_SUPPLIER):
      const {
        results,
        resultsCount,
        aggregations,
        communities,
        queryString,
        refKey,
        searchId,
        aggregateQuery
      } = action.payload

      if (aggregateQuery) {
        return state.merge({
          aggregations: fromJS(aggregations),
          aggregating: false,
          communities: fromJS(communities)
        })
      }

      return state
        .merge({
          // resultSupplierIds: fromJS(results.map(r => r.supplier.id)),
          resultsKey: refKey,
          results: fromJS(results),
          resultsCount,
          communities: fromJS(communities),
          searching: false,
          aggregating: true,
          searchError: undefined,
          location: fromJS({
            locationQuery: '',
            searching: false,
            results: {}
          }),
          searchId
        })
        .mergeIn(
          ['queryStringByRef'],
          fromJS({
            [refKey]: queryString
          })
        )

    case requestSuccess(actionTypes.GET_ORG_CHILDREN):
      return state.updateIn(['results'], results =>
        results.concat(fromJS(action.payload.results))
      )

    case requestSuccess(CREATE_VET):
      return action.payload.queryString
        ? state.mergeIn(
            ['queryStringByRef'],
            fromJS({
              [action.payload.newVet.id]: action.payload.queryString
            })
          )
        : state

    case requestFailure(actionTypes.SEARCH_SUPPLIER):
      return state.merge({
        searching: false,
        aggregating: false,
        searchError: action.payload
      })

    case actionTypes.UPDATE_SCROLL_POSITION:
      return state.merge({
        scrollPosition: action.payload
      })

    case actionTypes.TOGGLE_SEARCH_DETAILS:
      return state.set('showDetails', !state.get('showDetails'))

    case requestSuccess(CONNECT_TO_SUPPLIER):
      resultIndex = state
        .get('results')
        .findIndex(
          result =>
            result.getIn(['supplier', 'id']) === action.payload.supplierId
        )

      let searchCard
      let cardId
      if (action.payload.newCard) {
        /* connect from search always try to create a new card */
        /* parseCard converts offerings and differentiators in key/value pair */
        const tags = [
          ...Object.keys(action.payload.newCard.offerings),
          ...Object.keys(action.payload.newCard.differentiators)
        ]
        const parents = { User: action.payload.newCard.owner }
        searchCard = fromJS(
          Object.assign({}, action.payload.newCard, { tags, parents })
        )
        cardId = action.payload.newCard.id
      }

      const newState = action.payload.fromSearch
        ? state.merge({
            supplierId: action.payload.supplierId,
            cardId,
            isAddTagDialogOpen: true
          })
        : state

      return newState.mergeIn(
        ['results', resultIndex, 'cards', cardId],
        searchCard || action.payload.cardChanges
      )

    case requestSuccess(ADD_PERSONAL_RELATIONSHIP):
      const {
        cardChanges: { attributes }
      } = action.payload

      if (!attributes) {
        return state
      }

      resultIndex = state
        .get('results')
        .findIndex(
          result =>
            result.getIn(['supplier', 'id']) === action.payload.supplierId
        )

      const existingCard =
        resultIndex !== -1 &&
        state.getIn(['results', resultIndex, 'cards', action.payload.cardId])

      card = action.payload.newCard
        ? fromJS(
            Object.assign({
              ...action.payload.newCard,
              labels: Object.values(attributes).filter(label => !!label)
            })
          )
        : existingCard
        ? existingCard.set(
            'labels',
            fromJS(Object.values(attributes).filter(label => !!label))
          )
        : null

      return resultIndex !== -1 && card
        ? state
            .setIn(['results', resultIndex, 'cards', card.get('id')], card)
            .setIn(['results', resultIndex, 'myCardId'], card.get('id'))
            .setIn(
              ['results', resultIndex, 'labels'],
              fromJS(
                Object.values(attributes)
                  .filter(label => !!label)
                  .map(label => ({
                    name: label,
                    key: camelCase(label)
                  }))
              )
            )
        : state

    case requestSuccess(actionTypes.REMOVE_LABEL_FROM_SUPPLIER):
      resultIndex = state
        .get('results')
        .findIndex(
          result =>
            result.getIn(['supplier', 'id']) === action.payload.supplierId
        )

      attributesValues = Object.values(
        action.payload.cardChanges.attributes
      ).filter(v => v)
      attributesKeys = attributesValues.map(camelCase)

      return state.updateIn(['results', resultIndex, 'labels'], value => {
        const removeIndex = value.findIndex(v => {
          return !attributesKeys.includes(v.get('key'))
        })
        return removeIndex !== -1 ? value.delete(removeIndex) : value
      })

    case requestSuccess(actionTypes.ADD_TAG_TO_SUPPLIER):
      resultIndex = state
        .get('results')
        .findIndex(
          result =>
            result.getIn(['supplier', 'id']) === action.payload.supplierId
        )

      peerTags =
        state.getIn(['results', resultIndex, 'relationship', 'peerTags']) ||
        fromJS({})

      const newCard = action.payload.newCard
      card = newCard
        ? fromJS({
            connected: true,
            id: newCard.id,
            parents: { User: newCard.owner },
            tags: action.payload.tags
          })
        : state
            .setIn(
              ['results', resultIndex, 'cards', action.payload.cardId, 'tags'],
              fromJS(action.payload.tags)
            )
            .getIn(['results', resultIndex, 'cards', action.payload.cardId])

      return state
        .setIn(['results', resultIndex, 'cards', action.payload.cardId], card)
        .setIn(
          ['results', resultIndex, 'relationship', 'peerTags'],
          peerTags.mergeWith((oldVal, newVal) => {
            return oldVal.merge(
              fromJS({
                count: oldVal.get('count') + 1
              })
            )
          }, fromJS(action.payload.newTags))
        )
        .set('cardId', action.payload.cardId)
        .set('supplierId', action.payload.supplierId)
        .setIn(['results', resultIndex, 'myCardId'], card.get('id'))
        .updateIn(['results', resultIndex, 'tags'], tags => {
          Object.keys(action.payload.newTags).forEach(key => {
            action.payload.newTags[key].mine = true
            action.payload.newTags[key].key = key
          })

          const mapTags = tags.toMap().mapKeys((k, v) => v.get('key'))
          return mapTags
            .mergeWith((oldVal, newVal) => {
              return oldVal.merge(
                fromJS({
                  count: oldVal.get('count') ? oldVal.get('count') + 1 : 1,
                  mine: true
                })
              )
            }, fromJS(action.payload.newTags))
            .toList()
        })

    case requestSuccess(actionTypes.REMOVE_TAG_FROM_SUPPLIER):
      resultIndex = state
        .get('results')
        .findIndex(
          result =>
            result.getIn(['supplier', 'id']) === action.payload.supplierId
        )

      peerTags = state.getIn([
        'results',
        resultIndex,
        'relationship',
        'peerTags'
      ])

      const cardTagsIndex = state
        .getIn(['results', resultIndex, 'cards', action.payload.cardId, 'tags'])
        .findIndex(tag => tag === action.payload.tagKey)

      const tagsIndex2 = findTagIndexByKey(
        state,
        resultIndex,
        action.payload.tagKey
      )

      return state
        .deleteIn([
          'results',
          resultIndex,
          'cards',
          action.payload.cardId,
          'tags',
          cardTagsIndex
        ])
        .mergeIn(
          [
            'results',
            resultIndex,
            'relationship',
            'peerTags',
            action.payload.tagKey
          ],
          {
            count: peerTags.getIn([action.payload.tagKey, 'count']) - 1,
            mine: false
          }
        )
        .mergeIn(['results', resultIndex, 'tags', tagsIndex2], {
          count: peerTags.getIn([action.payload.tagKey, 'count']) - 1,
          mine: false
        })
        .set('cardId', action.payload.cardId)
        .set('supplierId', action.payload.supplierId)

    case actionTypes.OPEN_ADD_NOTE_DIALOG:
      return state.merge({
        isAddNoteDialogOpen: true,
        supplierId: action.payload.supplierId,
        cardId: action.payload.cardId
      })

    case actionTypes.OPEN_ADD_TAG_DIALOG:
      return state.merge({
        isAddTagDialogOpen: true,
        supplierId: action.payload.supplierId,
        cardId: action.payload.cardId
      })

    case actionTypes.CLOSE_ADD_TAG_DIALOG:
      return state.merge(
        fromJS({
          isAddTagDialogOpen: false,
          isAddNoteDialogOpen: false,
          supplierId: '',
          cardId: ''
        })
      )

    case actionTypes.CLOSE_SUPPLIER_REVIEW_DIALOG:
      return state.merge({
        isReviewDialogOpen: false,
        cardId: '',
        supplierId: ''
      })

    case requestSuccess(actionTypes.SUBMIT_SUPPLIER_REVIEW):
      resultIndex = state
        .get('results')
        .findIndex(
          result =>
            result.getIn(['supplier', 'id']) === action.payload.supplierId
        )
      return state
        .set('isReviewDialogOpen', false)
        .setIn(
          ['results', resultIndex, 'relationship', 'relationshipRating'],
          action.payload.relationshipRating
        )

    // case actionTypes.UPDATE_SUPPLIER_REVIEW:
    //   return state.set('supplierId', action.payload.supplierId)

    case requestSuccess(actionTypes.UPDATE_SUPPLIER_REVIEW):
      return state.merge({
        supplierId: action.payload.supplierId,
        cardId: action.payload.cardId,
        reviewCard: fromJS(action.payload.myCard),
        reviewSupplierName: action.payload.supplierName,
        isReviewDialogOpen: true
      })

    case actionTypes.OPEN_FILTERS_DIALOG:
      return state.set('showFilterDialog', true)

    case actionTypes.CLOSE_FILTERS_DIALOG:
      return state.set('showFilterDialog', false)

    case SHOW_MORE_SUPPLIERS:
      return state.set(
        'numberOfSuppliersToShow',
        state.get('numberOfSuppliersToShow') + 10
      )

    case actionTypes.TOGGLE_FILTER_OPTION:
      const key = action.payload.key
      if (key === 'state') {
        return state.deleteIn(['aggregations', 'city'])
      } else if (key === 'country') {
        return state
          .deleteIn(['aggregations', 'city'])
          .deleteIn(['aggregations', 'state'])
      } else {
        return state
      }

    case actionTypes.SEARCH_LOCATION:
      return state.mergeIn(
        ['location'],
        fromJS({
          locationQuery: action.meta.locationQuery,
          searching: true
        })
      )

    case requestSuccess(actionTypes.SEARCH_LOCATION):
      return state.mergeIn(
        ['location'],
        fromJS({
          searching: false,
          results: {
            ...action.payload.aggregations
          }
        })
      )

    case LOGOUT:
      return new SearchRecord()
    default:
      return state
  }
}

export default searchReducer

function findTagIndexByKey(state, resultIndex, tagKey) {
  return state
    .getIn(['results', resultIndex, 'tags'])
    .findIndex(tag => tag.get('key') === tagKey)
}
