import { fromJS } from 'immutable'
import dateFormat from 'shared/utils/dateFormat'
import usersSelectors from 'shared/selectors/usersSelectors'
import { createSelector } from 'reselect'

export const getVets = state => state.getIn(['buyer', 'vets'])

export const getItems = state => getVets(state).get('items')
export const getItemsInList = state => getItems(state).valueSeq()
export const getFlatItemsInList = createSelector(
  getItems,
  usersSelectors.getUsers,
  (items, users) =>
    items
      .toList()
      .sort(
        (a, b) => b.getIn(['created', 'date']) - a.getIn(['created', 'date'])
      )
      .map(i => {
        const createdUserId = i.getIn(['created', 'user'])
        const lead = i.get('users', fromJS([])).find(user => user.get('lead'))
        const leadUserId = lead && lead.get('user')

        const createdUser = createdUserId && users.get(createdUserId)
        const leadUser = leadUserId && users.get(leadUserId)

        return fromJS({
          id: i.get('id'),
          vetId: i.get('vetId'),
          name: i.get('name'),
          date: dateFormat(
            i.getIn(['modified', 'date']) || i.getIn(['created', 'date'])
          ),
          lastModified:
            i.getIn(['modified', 'date']) || i.getIn(['created', 'date']),
          user:
            createdUser &&
            `${createdUser.get('firstName')} ${createdUser.get('lastName')}`,
          owner:
            leadUser &&
            `${leadUser.get('firstName')} ${leadUser.get('lastName')}`,
          createdDate: i.getIn(['created', 'date']),
          modifiedDate: i.getIn(['modified', 'date']),
          decisionDate: i.getIn(['vetDetails', 'decisionDate']),
          plannedCompletionDate: i.get('plannedCompletionDate'),
          status: i.getIn(['vetDetails', 'decision']),
          users: i.get('users'),
          suppliers: [
            i.get('numberOfSuggestedSuppliers', 0),
            i.get('numberOfInvitedSuppliers', 0),
            i.get('numberOfRespondedSuppliers', 0)
          ],
          suggested: i.get('numberOfSuggestedSuppliers', 0),
          invited: i.get('numberOfInvitedSuppliers', 0),
          responded: i.get('numberOfRespondedSuppliers', 0)
        })
      })
)

export const isFetching = state => getVets(state).isFetching

const getByUserId = (state, userId) =>
  getItemsInList(state).filter(vet => {
    return (
      vet.get('users').findIndex(user => user.get('user') === userId) !== -1
    )
  })

export const getVetsByUserId = createSelector(getByUserId, vets => {
  return vets
    .toList()
    .sort((v1, v2) => {
      const dateV1 = new Date(v1.getIn(['created', 'date']))
      const dateV2 = new Date(v2.getIn(['created', 'date']))

      return dateV1 > dateV2 ? -1 : 1
    })
    .map(v =>
      fromJS({
        id: v.get('id'),
        name: v.get('name') || 'Untitled',
        date: v.getIn(['created', 'date']),
        status: v.getIn(['vetDetails', 'decision'])
      })
    )
})
