import { call, delay } from 'redux-saga/effects'
import buyer from 'shared/utils/api/buyer'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import parseFilters from 'shared/utils/data/parseFilters'

export function* updateSettingsSaga(action) {
  const settings = action.payload
  yield delay(200)
  const response = yield call(buyer.updateSettings, settings)

  return yield action.payload.fixedFilters
    ? { fixedFilters: parseFilters(action.payload.fixedFilters) }
    : action.payload.defaultFilters
    ? { defaultFilters: parseFilters(action.payload.defaultFilters) }
    : action.payload.corporateDiversityQualificationRules
    ? {
        corporateDiversityQualificationRules:
          response.corporateDiversityQualificationRules
      }
    : action.payload
}

export default createApiRequestSaga(updateSettingsSaga, {
  notifyOnSuccessMessage: 'Buyer Setting Saved'
})
