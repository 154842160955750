import React, { useCallback } from 'react'
import InsightTier2NavBar from '../../components/InsightTier2NavBar'
import Page from 'shared/components/Page'
import Divider from 'shared/components/Divider'
import { FormattedMessage } from 'react-intl'
import Tier2SpendOverview from '../Tier2SpendOverview'
import Toggle from 'shared/components/Switch'
import Text from 'shared/components/Text'
import PageSection from 'shared/components/PageSection'
import Tier2InviteSupplierContainer from '../../containers/Tier2InviteSupplierContainer'
import paths from '../../routes/paths'
import { useSelector } from 'react-redux'
import { createTier2Community } from '../../store/actions'
import { useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router'
import communitySelectors from '../../../Communities/selectors/communitiesSelectors'
import Paper from 'shared/components/Paper'
import RootState from 'shared/models/RootState'

const Tier2Diversity = () => {
  const dispatch = useDispatch()

  const tier2Community = useSelector((state: RootState) =>
    communitySelectors
      .getCommunitiesList(state)
      .filter(community => community.get('tier2'))
  )

  const handleChange = useCallback(() => {
    dispatch(createTier2Community())
  }, [dispatch])
  return (
    <main>
      <Page title='Tier 2 Diversity'>
        {tier2Community.size === 0 ? (
          <PageSection
            noPadding
            title={
              <div className='flex'>
                <FormattedMessage
                  id='Tier2Diversity.Tier2'
                  defaultMessage='Enable Tier 2'
                />
                <div className='ml3'>
                  <Toggle
                    checked={tier2Community.size > 0}
                    onChange={handleChange}
                  />
                </div>
              </div>
            }
          >
            <Text className='pa3-5'>
              <FormattedMessage
                id='Tier2Diversity.EnablingTier2'
                defaultMessage='Enabling Tier 2 reporting will allow you to invite suppliers to share their diverse spend with your company. Suppliers you invite will receive an email from TealBook asking them to participate.'
              />
            </Text>
          </PageSection>
        ) : (
          <Paper>
            <InsightTier2NavBar />
            <Divider />
            <Switch>
              <Route
                path={paths.supplierListTier2}
                render={() => (
                  <Tier2InviteSupplierContainer
                    tier2Community={tier2Community}
                  />
                )}
              />
              <Route
                path={paths.spendOverviewTier2}
                component={Tier2SpendOverview}
              />
            </Switch>
          </Paper>
        )}
      </Page>
    </main>
  )
}

export default Tier2Diversity
