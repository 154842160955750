import React, { FC, ReactElement, createRef, RefObject, useEffect } from 'react'
import exportData from '../../utils/exportData'

type Props = {
  filename?: string
  exportComponent: ReactElement
  onSuccess?: () => void
}

const ExportDoc: FC<Props> = ({ exportComponent, filename, onSuccess }) => {
  const exportDiv: RefObject<HTMLDivElement> = createRef()

  useEffect(() => {
    if (exportDiv.current) {
      exportData.exportDoc(exportDiv.current, filename)
    }
    if (onSuccess) {
      onSuccess()
    }
  }, [onSuccess, filename, exportDiv])

  return (
    <div ref={exportDiv} className='dn'>
      {exportComponent}
    </div>
  )
}

export default ExportDoc
