import React, { Fragment, MouseEvent, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import SearchFilterContainer from '../SearchFilterContainer'
import FilterCheckboxListContainer from '../FilterCheckboxListContainer'
import FilterScopeContainer from '../FilterScopeContainer'
import FilterRelationshipRatingContainer from '../FilterRelationshipRatingContainer'
import searchSelectors from '../../selectors/searchSelectors'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import filterPanelsSelectors from '../../selectors/filterPanelsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { setFilterPanels, closeFiltersDialog } from '../../actions'
import ImageExpandAll from 'shared/assets/icons/expand-all.svg'
import ImageCollapseAll from 'shared/assets/icons/collapse-all.svg'
import FilterBooleanContainer from '../FilterBooleanContainer'
import Divider from 'shared/components/Divider'
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import Button from 'shared/components/Button'
import FilterCertificationsContainer from '../FilterCertificationsContainer'
import FilterLocationContainer from '../FilterLocationContainer'
import FilterNaicsContainer from '../FilterNaicsContainer'
import { FormattedMessage, useIntl } from 'react-intl'
import { List, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'
import useUserAccess from 'shared/utils/useUserAccess'
import { LicenseAccess } from 'buyer/shared/reducers/settingsReducer'
import IconButton from 'shared/components/IconButton'

const useDialog = ['xs', 'sm']

export const allFilters = [
  'attributes',
  'following',
  'certifications',
  'diversity',
  'quality',
  'security',
  'sustainability',
  'food',
  'attachments',
  'communities',
  'communityStatus',
  'label',
  'relationshipRating',
  'recentPercentile',
  'spendGroups',
  'internalCategory',
  'location',
  'emailAvailable',
  'supplierUpdated',
  'supplierType',
  'warnings',
  'naics',
  'food'
]

type Props = {
  usePanelDefault: boolean
  isFilteredByCommunities: boolean
  isFilteredAttachments?: boolean
  isTealbot: boolean
  hideFollowing: boolean
  hideCommunity: boolean
  hideSpend: boolean
  hideRating: boolean
  showCollapseAll: boolean
  showFilterDialog: boolean
  label: List<{ label: string; value: string }>
  supplierType: List<RecordOf<{ id: string; value: string; label: string }>>
  diversity: List<RecordOf<{ key: string; doc_count: number }>>
  sustainability: List<RecordOf<{ key: string; doc_count: number }>>
  security: List<RecordOf<{ key: string; doc_count: number }>>
  quality: List<RecordOf<{ key: string; doc_count: number }>>
  food: List<RecordOf<{ key: string; doc_count: number }>>
  relationshipRating: List<RecordOf<{ key: string; doc_count: number }>>
  country: List<RecordOf<{ value: string; label: string }>>
  internalCategory: List<RecordOf<{ value: string; label: string }>>
  recentPercentile: List<RecordOf<{ value: string; label: string }>>
  emailAvailable: List<RecordOf<{ id: string; value: string; label: string }>>
  supplierUpdated: List<RecordOf<{ id: string; value: string; label: string }>>
  warnings: List<RecordOf<{ value: string; label: string }>>
  setFilterPanels: (filterPanel: { [x: string]: { collapse: {} } }) => void
  match: { params: { vetId: string } }
  supplierAuthorizations: List<RecordOf<{ value: string; label: string }>>
  corporateRelationship: List<RecordOf<{ value: string; label: string }>>
  communities: List<RecordOf<{ value: string; label: string }>>
  communityStatus: List<RecordOf<{ value: string; label: string }>>
  closeFiltersDialog:
    | ((any: any) => void)
    | ((event: MouseEvent<HTMLButtonElement>) => void)
  spendGroups: List<RecordOf<{ value: string; label: string }>>
  sector: List<RecordOf<{ value: string; label: string }>>
} & RouteComponentProps &
  WithWidthProps

export const SearchFilters = (props: Props) => {
  const intl = useIntl()
  const {
    setFilterPanels,
    usePanelDefault,
    match,
    location,
    showCollapseAll,
    width,
    showFilterDialog,
    closeFiltersDialog
  } = props

  const userAccess = useUserAccess() as LicenseAccess

  const setPanels = (collapse: boolean) => {
    return allFilters.reduce((result, key) => {
      result[key] = collapse
      return result
    }, {})
  }

  const handleDefaultPanels = useCallback(() => {
    let defaultPanels = {}
    if (location.search.includes('country')) {
      defaultPanels['location'] = false
    }
    if (location.search.includes('sector')) {
      defaultPanels['naics'] = false
    }
    if (
      location.search.includes('scope') &&
      !location.search.includes('scope=All')
    ) {
      defaultPanels['following'] = false
    }
    if (
      new RegExp(
        ['diversity', 'quality', 'security', 'sustainability', 'food'].join('|')
      ).test(location.search)
    ) {
      defaultPanels['certifications'] = false
    }
    allFilters.forEach(element => {
      if (location.search.includes(element)) {
        defaultPanels[element] = false
      }
    })

    setFilterPanels({
      [match.params.vetId || 'suppliers']: {
        collapse: defaultPanels
      }
    })
  }, [location.search, match.params.vetId, setFilterPanels])

  useEffect(() => {
    if (usePanelDefault) {
      handleDefaultPanels()
    }
  }, [usePanelDefault, handleDefaultPanels])

  const handleExpandAll = () => {
    setFilterPanels({
      [match.params.vetId || 'suppliers']: {
        collapse: setPanels(false)
      }
    })
  }

  const handleCollapseAll = () => {
    setFilterPanels({
      [match.params.vetId || 'suppliers']: {
        collapse: setPanels(true)
      }
    })
  }

  const renderFilters = () => {
    const {
      supplierAuthorizations,
      corporateRelationship,
      communities,
      communityStatus,
      isFilteredByCommunities,
      isFilteredAttachments,
      label,
      supplierType,
      diversity,
      sustainability,
      security,
      quality,
      food,
      country,
      internalCategory,
      recentPercentile,
      relationshipRating,
      emailAvailable,
      supplierUpdated,
      warnings,
      isTealbot,
      hideFollowing,
      hideCommunity,
      hideRating,
      hideSpend,
      spendGroups,
      sector
    } = props

    const spendPercentilesTitleMap: { [key: string]: string } = {
      '5': '80-100%',
      '4': '60-80%',
      '3': '40-60%',
      '2': '20-40%',
      '1': '0-20%',
      '0': '0%'
    }

    return (
      <Fragment>
        {corporateRelationship && corporateRelationship.size > 0 && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.CorporateRelationship'
                defaultMessage='Corporate Relationship'
              />
            }
            panelId='attributes'
          >
            <FilterCheckboxListContainer
              showMatchAny
              matchAnyListFn={list =>
                list.filter(item => item.value !== 'noRelationship')
              }
              list={corporateRelationship}
              filterKey='attributes'
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.CorporateRelationship',
                defaultMessage: 'Corporate Relationship'
              })}
            />
          </SearchFilterContainer>
        )}

        {country && country.size > 0 && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.Location'
                defaultMessage='Location'
              />
            }
            panelId='location'
          >
            <FilterLocationContainer
              showMatchAny
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.Location',
                defaultMessage: 'Location'
              })}
            />
          </SearchFilterContainer>
        )}

        {!hideFollowing && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.Following'
                defaultMessage='Following'
              />
            }
            panelId='following'
          >
            <FilterScopeContainer
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.Following',
                defaultMessage: 'Following'
              })}
            />
          </SearchFilterContainer>
        )}

        {((diversity && diversity.size > 0) ||
          (quality && quality.size > 0) ||
          (security && security.size > 0) ||
          (sustainability && sustainability.size > 0) ||
          (food && food.size > 0)) && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.Qualification'
                defaultMessage='Qualification'
              />
            }
            panelId='certifications'
          >
            <FilterCertificationsContainer
              showDiversity={diversity.size > 0}
              showQuality={quality.size > 0}
              showSecurity={security.size > 0}
              showSustainability={sustainability.size > 0}
              showFood={food.size > 0}
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.Qualification',
                defaultMessage: 'Qualification'
              })}
            />
          </SearchFilterContainer>
        )}

        {sector && sector.size > 0 && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.NaicsCode'
                defaultMessage='NAICS Code'
              />
            }
            panelId='naics'
          >
            <FilterNaicsContainer
              showMatchAny
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.NaicsCode',
                defaultMessage: 'NAICS Code'
              })}
            />
          </SearchFilterContainer>
        )}

        {supplierAuthorizations && supplierAuthorizations.size > 0 && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.Attachment'
                defaultMessage='Attachment'
              />
            }
            panelId='attachments'
          >
            <FilterCheckboxListContainer
              showMatchAny
              list={supplierAuthorizations}
              filterKey='attachments'
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.Attachment',
                defaultMessage: 'Attachment'
              })}
            />

            {isFilteredAttachments && (
              <Fragment>
                <Divider className='mt3' />
                <FilterBooleanContainer
                  filterKey='attachmentsExpiredExcluded'
                  label='Only Include Non-Expired Attachments'
                />
              </Fragment>
            )}
          </SearchFilterContainer>
        )}

        {!hideCommunity &&
          communities &&
          communities.size > 0 &&
          userAccess.accessCommunities && (
            <SearchFilterContainer
              filterLabel={
                <FormattedMessage
                  id='SearchFiltersContainer.Community'
                  defaultMessage='Community'
                />
              }
              panelId='communities'
            >
              <FilterCheckboxListContainer
                showMatchAny
                list={communities}
                filterKey='communities'
                category={intl.formatMessage({
                  id: 'SearchFiltersContainer.Community',
                  defaultMessage: 'Community'
                })}
              />
            </SearchFilterContainer>
          )}

        {!hideCommunity &&
          isFilteredByCommunities &&
          communityStatus &&
          communityStatus.size > 0 &&
          userAccess.accessCommunities && (
            <SearchFilterContainer
              filterLabel={
                <FormattedMessage
                  id='SearchFiltersContainer.CommunityStatus'
                  defaultMessage='Community Status'
                />
              }
              panelId='communityStatus'
            >
              <FilterCheckboxListContainer
                showMatchAny
                list={communityStatus}
                filterKey='communityStatus'
                category={intl.formatMessage({
                  id: 'SearchFiltersContainer.CommunityStatus',
                  defaultMessage: 'Community Status'
                })}
              />
            </SearchFilterContainer>
          )}

        {label && label.size > 0 && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.PersonalRelationship'
                defaultMessage='Personal Relationship'
              />
            }
            panelId='label'
          >
            <FilterCheckboxListContainer
              showMatchAny
              list={label}
              filterKey='label'
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.PersonalRelationship',
                defaultMessage: 'Personal Relationship'
              })}
            />
          </SearchFilterContainer>
        )}

        {internalCategory && internalCategory.size > 0 && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.InternalCategory'
                defaultMessage='Internal Category'
              />
            }
            panelId='internalCategory'
          >
            <FilterCheckboxListContainer
              showMatchAny
              list={internalCategory}
              filterKey='internalCategory'
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.InternalCategory',
                defaultMessage: 'Internal Category'
              })}
            />
          </SearchFilterContainer>
        )}
        {!hideSpend &&
          spendGroups &&
          spendGroups.size > 0 &&
          userAccess.accessSupplierSpend && (
            <SearchFilterContainer
              filterLabel={
                <FormattedMessage
                  id='SearchFiltersContainer.BusinessUnits'
                  defaultMessage='Business Units'
                />
              }
              panelId='spendGroups'
            >
              <FilterCheckboxListContainer
                showMatchAny
                list={spendGroups}
                filterKey='spendGroups'
                category={intl.formatMessage({
                  id: 'SearchFiltersContainer.BusinessUnits',
                  defaultMessage: 'Business Units'
                })}
              />
            </SearchFilterContainer>
          )}
        {!hideRating && relationshipRating && relationshipRating.size > 0 && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.Rating'
                defaultMessage='Rating'
              />
            }
            panelId='relationshipRating'
          >
            <FilterRelationshipRatingContainer
              key='relationshipRating'
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.Rating',
                defaultMessage: 'Rating'
              })}
            />
          </SearchFilterContainer>
        )}

        {!hideSpend &&
          recentPercentile &&
          recentPercentile.size > 0 &&
          userAccess.accessSupplierSpend && (
            <SearchFilterContainer
              filterLabel={
                <FormattedMessage
                  id='SearchFiltersContainer.SpendPrecentile'
                  defaultMessage='Spend Percentile'
                />
              }
              panelId='recentPercentile'
            >
              <FilterCheckboxListContainer
                showMatchAny
                list={recentPercentile}
                filterKey='recentPercentile'
                titleMap={spendPercentilesTitleMap}
                category={intl.formatMessage({
                  id: 'SearchFiltersContainer.SpendPrecentile',
                  defaultMessage: 'Spend Percentile'
                })}
              />
            </SearchFilterContainer>
          )}

        {isTealbot && warnings && warnings.size > 0 && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.Warnings'
                defaultMessage='Warnings'
              />
            }
            panelId='warnings'
          >
            <FilterCheckboxListContainer
              list={warnings}
              filterKey='warnings'
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.Warnings',
                defaultMessage: 'Warnings'
              })}
            />
          </SearchFilterContainer>
        )}
        {isTealbot && emailAvailable && emailAvailable.size > 0 && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.EmailAvailable'
                defaultMessage='Email Available'
              />
            }
            panelId='emailAvailable'
          >
            <FilterCheckboxListContainer
              list={emailAvailable}
              filterKey='emailAvailable'
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.EmailAvailable',
                defaultMessage: 'Email Available'
              })}
            />
          </SearchFilterContainer>
        )}

        {isTealbot && supplierUpdated && supplierUpdated.size > 0 && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.SupplierUpdated'
                defaultMessage='Supplier Updated'
              />
            }
            panelId='supplierUpdated'
          >
            <FilterCheckboxListContainer
              list={supplierUpdated}
              filterKey='supplierUpdated'
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.SupplierUpdated',
                defaultMessage: 'Supplier Updated'
              })}
            />
          </SearchFilterContainer>
        )}
        {isTealbot && supplierType && supplierType.size > 0 && (
          <SearchFilterContainer
            filterLabel={
              <FormattedMessage
                id='SearchFiltersContainer.SupplierType'
                defaultMessage='Supplier Type'
              />
            }
            panelId='supplierType'
          >
            <FilterCheckboxListContainer
              list={supplierType}
              filterKey='supplierType'
              category={intl.formatMessage({
                id: 'SearchFiltersContainer.SupplierType',
                defaultMessage: 'Supplier Type'
              })}
            />
          </SearchFilterContainer>
        )}
      </Fragment>
    )
  }

  return width && !useDialog.includes(width) ? (
    <div>
      <div className='bg-white br2 ba b--black-10'>
        <div className='w-100 dt bg-white pa3'>
          <h5 className='f7 w-90 fw6 ma0 dib v-mid'>
            <FormattedMessage
              id='SearchFiltersContainer.AdvancedFilters'
              defaultMessage='Advanced Filters'
            />
          </h5>
          <div className='dib w-10 tr'>
            {showCollapseAll ? (
              <IconButton
                onClick={handleCollapseAll}
                aria-expanded='true'
                aria-label='Collapse all advanced filters'
              >
                <img
                  src={ImageExpandAll}
                  className='v-mid pointer'
                  alt='Collapse all advanced filters'
                />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleExpandAll}
                aria-expanded='false'
                aria-label='Expand all advanced filters'
              >
                <img
                  src={ImageCollapseAll}
                  className='v-mid pointer'
                  alt='Expand all advanced filters'
                />
              </IconButton>
            )}
          </div>
        </div>
        <div>{renderFilters()}</div>
      </div>
    </div>
  ) : (
    <Dialog open={showFilterDialog} onClose={closeFiltersDialog} fullScreen>
      <DialogTitle>
        <FormattedMessage
          id='SearchFiltersContainer.SearchFilters'
          defaultMessage='Search filters'
        />
      </DialogTitle>
      <DialogContent>{renderFilters()}</DialogContent>
      <DialogActions>
        <Button
          label={
            <FormattedMessage
              id='SearchFiltersContainer.Close'
              defaultMessage='Close'
            />
          }
          size='large'
          autoSize
          onClick={closeFiltersDialog}
        />
      </DialogActions>
    </Dialog>
  )
}

type ContainerProps = {
  match: { params: { vetId: string } }
} & RouteComponentProps

export default withRouter(
  connect(
    (state: RootState, props: ContainerProps) => {
      return {
        supplierAuthorizations: searchSelectors.getSupplierAuthorizations(
          state
        ),
        corporateRelationship: searchSelectors.getCorporateRelationship(state),
        diversity: searchSelectors.getCertificationCategory(state, 'diversity'),
        sustainability: searchSelectors.getCertificationCategory(
          state,
          'sustainability'
        ),
        security: searchSelectors.getCertificationCategory(state, 'security'),
        quality: searchSelectors.getCertificationCategory(state, 'quality'),
        food: searchSelectors.getCertificationCategory(state, 'food'),
        communities: searchSelectors.getCommunities(state),
        communityStatus: searchSelectors.getCommunityStatus(state),
        emailAvailable: searchSelectors.getEmailAvailable(state),
        supplierUpdated: searchSelectors.getSupplierUpdated(state),
        warnings: searchSelectors.getWarnings(state),
        supplierType: searchSelectors.getSupplierType(state),
        isTealbot: sessionSelectors.userHasRole(state, [
          'tealbot',
          'clientConcierge'
        ]),
        isFilteredByCommunities:
          filterOptionsSelectors.getFilterOption(state, 'communities').size > 0,
        isFilteredAttachments: filterOptionsSelectors.getByKey(
          state,
          'attachments'
        ),
        label: searchSelectors.getLabel(state),
        internalCategory: searchSelectors.getInternalCategory(state),
        relationshipRating: searchSelectors.getRelationshipRating(state),
        recentPercentile: searchSelectors.getRecentPercentile(state),
        country: searchSelectors.getCountryAggregations(state),
        usePanelDefault: filterPanelsSelectors.useDefault(
          state,
          props.match.params.vetId || 'suppliers'
        ),
        showCollapseAll: filterPanelsSelectors.showCollapseAll(
          state,
          props.match.params.vetId || 'suppliers'
        ),
        showFilterDialog: searchSelectors.showFilterDialog(state),
        spendGroups: searchSelectors.getSpendGroups(state),
        sector: searchSelectors.getNaicsAggregationsByKey(state, 'sector')
      }
    },
    {
      setFilterPanels,
      closeFiltersDialog
    }
  )(withWidth()(SearchFilters))
)
