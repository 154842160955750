import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

const OnboardingNext: FunctionComponent = () => {
  return (
    <div className='ph3-5 pv3'>
      <ul
        className='lh-copy f5 fw4 mid-gray ma0 f5'
        style={{ lineHeight: '2em' }}
      >
        <li>
          <FormattedMessage
            id='OnboardingNext.One'
            defaultMessage='Add additional contacts'
          />
        </li>
        <li>
          <FormattedMessage
            id='OnboardingNext.Two'
            defaultMessage='Upload a presentation'
          />
        </li>
        <li>
          <FormattedMessage
            id='OnboardingNext.Three'
            defaultMessage='List some of your customers'
          />
        </li>
        <li>
          <FormattedMessage
            id='OnboardingNext.Four'
            defaultMessage='Upload a video'
          />
        </li>
        <li>
          <FormattedMessage
            id='OnboardingNext.Five'
            defaultMessage='Verify your social feeds'
          />
        </li>
      </ul>
    </div>
  )
}

export default OnboardingNext
