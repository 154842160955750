import { fromJS } from 'immutable'
import fileToUrl from './fileToUrl'
import parseTags from './parseTags'
import OrgUnit from '../../models/OrgUnit'
import Certification from '../../models/Certification'
import certAgencyRank from 'shared/utils/data/certAgencyRank'
import moment from 'moment'

export default function parseSupplier(orgUnit: OrgUnit) {
  const {
    id,
    name,
    description,
    longDescription,
    modified,
    domains,
    supplier = {},
    buyer = {},
    buyerType,
    ...restOfOrg
  } = orgUnit

  const org = {
    ...supplier,
    ...buyer
  }

  const {
    offerings,
    differentiators,
    certification,
    locations,
    logo,
    pitchDeckFile,
    backgroundPicture,
    links,
    relatedCompany,
    ...restOfOrgUnitDetails
  } = org

  let uniqueSimilarSuppliers = {}

  return {
    // flatten the links
    ...links,

    // rest of org unit details
    ...restOfOrgUnitDetails,

    ...restOfOrg,
    id,
    name,
    description,
    longDescription,
    domains,
    buyerType,

    // parse tags fields
    offerings: parseTags(offerings),
    differentiators: parseTags(differentiators),
    // parse the diversity objects
    certifications: certification && parseCertifications(certification),

    // flatten the address
    address: parseAddress(locations),

    // remove duplicate
    relatedCompany: relatedCompany?.filter(company => {
      if (uniqueSimilarSuppliers[company.orgUnit]) {
        return false
      } else {
        uniqueSimilarSuppliers[company.orgUnit] = true
        return true
      }
    }),

    phone: locations && locations[0] && locations[0]['phone'],

    locations:
      locations &&
      locations.map(({ components = {}, ...rest }, index) => ({
        name: components.house,
        poBox: components.po_box,
        houseNumber: components.house_number,
        level: components.level,
        unit: components.unit,
        road: components.road,
        suburb: components.suburb,
        city: components.city,
        stateDistrict: components.state_district,
        state: components.state || components.stateAbbrev,
        postalCode: components.postcode,
        country: components.country,
        lat: components.lat,
        long: components.long,
        countryCpi: components.countryCpi,
        isPrimary: index === 0,
        ...rest
      })),
    // denormalize related company
    // relatedCompany: !dontRecurse && relatedCompany && relatedCompany.length > 0 &&
    //   expanded.OrgUnit && mapValues(
    //     relatedCompany, orgId => {
    //       return parseSupplier(expanded.OrgUnit[orgId])
    //     }
    //   )

    // convert file objects to urls
    logoUrl: fileToUrl(logo),
    pitchDeckUrl: fileToUrl(pitchDeckFile),
    coverUrl: fileToUrl(backgroundPicture),

    // last modified date
    modifiedDate: modified && modified.date
  }
}

export const remapCertification = ({
  category,
  subCategory,
  certExpiration,
  cert,
  certAgency,
  certificateNumber,
  sourceURL,
  timeStamp,
  info,
  ...rest
}: Certification) => {
  return {
    category,
    subCategory,
    certExpiration: certExpiration && new Date(certExpiration),
    certificationUrl: fileToUrl(cert),
    certAgency: certAgency !== 'Unknown' ? certAgency : '',
    certificateNumber: certificateNumber,
    sourceURL,
    timeStamp,
    confirmed:
      info?.potential ||
      !certExpiration ||
      moment(certExpiration, 'YYYY-MM-DDTHH:mm:ss.sssZ').isBefore(
        new Date(),
        'day'
      )
        ? false
        : true,
    info,
    ...rest
  }
}

export const sortCertifications = (c1, c2) => {
  return c2.subCategory > c1.subCategory
    ? -1
    : c2.subCategory < c1.subCategory
    ? 1
    : c1.confirmed && !c2.confirmed
    ? -1
    : c2.confirmed && !c1.confirmed
    ? 1
    : c1?.info?.potential && !c2?.info?.potential
    ? -1
    : c2?.info?.potential && !c1?.info?.potential
    ? 1
    : certAgencyRank(c2.certAgency) - certAgencyRank(c1.certAgency)
}

function parseCertifications(certifications: Certification[]) {
  return fromJS(certifications.map(remapCertification).sort(sortCertifications))
    .groupBy(certification => certification.get('category'))
    .entrySeq()
    .toJS()
}

function parseAddress(locations) {
  let address = ''
  const primaryLocation = (locations && locations[0]) || {}

  if (primaryLocation.address) {
    address = `${primaryLocation.address}`
    if (
      primaryLocation.addCountry &&
      primaryLocation.components &&
      primaryLocation.components.country
    ) {
      address = `${address}, ${primaryLocation.components.country}`
    }
  }
  return address
}
