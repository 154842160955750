import React, { useState, useCallback, ChangeEvent } from 'react'
import Label from 'shared/components/Label'
import TextArea from 'shared/components/TextArea'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import Checkbox from '@material-ui/core/Checkbox'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettings } from '../../actions'
import RootState from 'shared/models/RootState'
import { useDebouncedCallback } from 'use-debounce'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import { RecordOf } from 'immutable'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import AttributesSetting from '../AttributesSetting'
import withStyles from '@material-ui/styles/withStyles'
import DiversityQualificationRules from './DiversityQualificationRules'
import sessionSelectors from 'shared/selectors/sessionSelectors'

const daysMap: Array<Array<string>> = [
  new Array(31).fill(''),
  new Array(28).fill(''),
  new Array(31).fill(''),
  new Array(30).fill(''),
  new Array(31).fill(''),
  new Array(30).fill(''),
  new Array(31).fill(''),
  new Array(31).fill(''),
  new Array(30).fill(''),
  new Array(31).fill(''),
  new Array(30).fill(''),
  new Array(31).fill('')
]

const StyleCheckbox = withStyles({
  root: {
    padding: 3
  }
})(Checkbox)

const AppConfigSRM = () => {
  const dispatch = useDispatch()
  const isReadonly = useSelector(
    (state: RootState) => !!state.get('session').get('partnerConcierge')
  )
  const stateBuyerDescription = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'buyerDescription'])
  )
  const stateAddress = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'locations', '0', 'address'])
  )
  const reviewOptions: RecordOf<{
    rating: boolean
    preferred: boolean
  }> = useSelector(settingsSelectors.getReviewOptions)
  const attachmentTypes = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'attachmentTypes'])
  )
  const relationshipAttributes = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'relationshipAttributes'])
  )
  const followerRoles = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'followerRoles'])
  )
  const yearEnd: string = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'yearEnd'])
  )

  const contractExpiryWindowDays: number = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'contractExpiryWindowDays'])
  )
  const isTealbot = useSelector((state: RootState) =>
    sessionSelectors.userHasRole(state, 'tealbot')
  )

  const isBuyerAdmin = useSelector((state: RootState) =>
    sessionSelectors.userHasRole(state, 'buyerAdmin')
  )

  const [buyerDescription, setBuyerDescription] = useState<string>(
    stateBuyerDescription
  )
  const [address, setAddress] = useState<string>(stateAddress)

  const [month, setMonth] = useState<number>(() => {
    return yearEnd ? parseInt(yearEnd.split('/')[0]) : 12
  })

  const [day, setDay] = useState<number>(() => {
    return yearEnd ? parseInt(yearEnd.split('/')[1]) : 31
  })

  const [daysToContractExpiry, setDaysToContractExpiry] = useState<number>(
    contractExpiryWindowDays
  )

  const debouncedUpdateSettings = useDebouncedCallback((settings: any) => {
    dispatch(updateSettings(settings))
  }, 500)

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.currentTarget
      if (name === 'buyerDescription') {
        setBuyerDescription(value)
      }
      debouncedUpdateSettings.callback({
        [name]: value
      })
    },
    [debouncedUpdateSettings]
  )

  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAddress(e.currentTarget.value)
  }

  const handleAddressSave = () => {
    handleUpdateSettings({
      locations: [{ address }]
    })
  }

  const handleReviewOptionsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.currentTarget
    handleUpdateSettings({
      reviewOptions: Object.assign({}, reviewOptions?.toJS(), {
        [name]: checked
      })
    })
  }

  const handleUpdateSettings = (settings: { [key: string]: any }) => {
    dispatch(updateSettings(settings))
  }

  const handleYearEndChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.currentTarget.name === 'month') {
      setMonth(parseInt(e.currentTarget.value))
      // verify day valid
      const validDay =
        day > daysMap[parseInt(e.currentTarget.value) - 1].length
          ? daysMap[parseInt(e.currentTarget.value) - 1].length
          : day
      setDay(validDay)
      dispatch(
        updateSettings({
          yearEnd: `${e.currentTarget.value}/${validDay}`
        })
      )
    } else {
      setDay(parseInt(e.currentTarget.value))
      dispatch(
        updateSettings({
          yearEnd: `${month}/${e.currentTarget.value}`
        })
      )
    }
  }

  const handleContractExpiryWindowChange = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    setDaysToContractExpiry(parseInt(e.currentTarget.value))
    dispatch(
      updateSettings({
        contractExpiryWindowDays: e.currentTarget.value
      })
    )
  }

  return (
    <>
      {isTealbot && (
        <>
          <Label noPadding htmlFor='buyerDescription'>
            <FormattedMessage
              id='AppConfigSRM.BuyerDescription'
              defaultMessage='Buyer Description'
            />
          </Label>
          <TextArea
            onChange={handleInputChange}
            value={buyerDescription}
            name='buyerDescription'
            id='buyerDescription'
            maxLength={200}
            readOnly={isReadonly}
          />
          <Label htmlFor='address'>
            <FormattedMessage
              id='AppConfigSRM.Address'
              defaultMessage='Address'
            />
          </Label>
          <Input
            onChange={handleAddressChange}
            value={address}
            name='address'
            readOnly={isReadonly}
          />
          <Button
            autoSize
            disabled={address === stateAddress || isReadonly}
            className='mt1'
            label={
              <FormattedMessage
                id='AppConfigSRM.UpdateLocation'
                defaultMessage='Update Location'
              />
            }
            onClick={handleAddressSave}
          />
          <Label>
            <FormattedMessage
              id='AppConfigSRM.YearEnd'
              defaultMessage='Year End'
            />
          </Label>
          <Select
            name='month'
            value={month?.toString()}
            onChange={handleYearEndChange}
            aria-label='select month'
          >
            <FormattedMessage
              id='AppConfigSRM.January'
              defaultMessage='January'
            >
              {message => <option value='1'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage
              id='AppConfigSRM.February'
              defaultMessage='February'
            >
              {message => <option value='2'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage id='AppConfigSRM.March' defaultMessage='March'>
              {message => <option value='3'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage id='AppConfigSRM.April' defaultMessage='April'>
              {message => <option value='4'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage id='AppConfigSRM.May' defaultMessage='May'>
              {message => <option value='5'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage id='AppConfigSRM.June' defaultMessage='June'>
              {message => <option value='6'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage id='AppConfigSRM.July' defaultMessage='July'>
              {message => <option value='7'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage id='AppConfigSRM.August' defaultMessage='August'>
              {message => <option value='8'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage
              id='AppConfigSRM.September'
              defaultMessage='September'
            >
              {message => <option value='9'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage
              id='AppConfigSRM.October'
              defaultMessage='October'
            >
              {message => <option value='10'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage
              id='AppConfigSRM.November'
              defaultMessage='November'
            >
              {message => <option value='11'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage
              id='AppConfigSRM.December'
              defaultMessage='December'
            >
              {message => <option value='12'>{message}</option>}
            </FormattedMessage>
          </Select>
          <Select
            name='day'
            value={day?.toString()}
            onChange={handleYearEndChange}
            aria-label='select day'
          >
            {daysMap[month - 1].map((v, i) => (
              <option key={`day-${i}`} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </Select>
          <div className='mt3'>
            <div className='w-100 w-50-ns dib v-top pr2-ns'>
              <AttributesSetting
                label={
                  <FormattedMessage
                    id='Settings.AttachmentTypes'
                    defaultMessage='Attachment Types'
                  />
                }
                description={`eg. {
              "incidence":{"order":70,"label":"Incidence"},
              "audit":{"order":10,"label":"Audit"},
              "certification":{"label":"Certification","order":60},
              "contract":{"label":"Contract","order":20},
              "other":{"order":500,"label":"Other"}
            }`}
                fieldName='attachmentTypes'
                value={
                  attachmentTypes ? JSON.stringify(attachmentTypes.toJS()) : ''
                }
                onUpdate={handleUpdateSettings}
                isReadonly={isReadonly}
              />
            </div>
            <div className='w-100 w-50-ns dib v-top pl2-ns mt3 mt0-ns'>
              <AttributesSetting
                label={
                  <FormattedMessage
                    id='Settings.RelationshipAttributes'
                    defaultMessage='Relationship Attributes'
                  />
                }
                description={`eg. {
              "restricted":{"order":40,"label":"Restricted"},
              "existing":{"order":30,"label":"Existing"},
              "preferred":{"order":10,"label":"Preferred"},
              "nonRecruit":{"order":100,"label":"Non Recruit"},
              "doNotUse":{"order":60,"label":"Do Not Use"}
              }`}
                fieldName='relationshipAttributes'
                value={
                  relationshipAttributes
                    ? JSON.stringify(relationshipAttributes.toJS())
                    : ''
                }
                onUpdate={handleUpdateSettings}
                isReadonly={isReadonly}
              />
            </div>
            <div className='w-100 w-50-ns dib v-top pr2-ns mt3'>
              <AttributesSetting
                label={
                  <FormattedMessage
                    id='Settings.FollowerRolesLabel'
                    defaultMessage='Follower Roles'
                  />
                }
                description={`eg. {
              "primaryContact":{"order":10,"label":"Primary Contact"},
              "secondaryContact":{"order":20,"label":"Secondary Contact"},
              "categoryManager":{"order":30,"label":"Category Manager"}
            }`}
                fieldName='followerRoles'
                value={
                  followerRoles ? JSON.stringify(followerRoles.toJS()) : ''
                }
                onUpdate={handleUpdateSettings}
                isReadonly={isReadonly}
              />
            </div>
          </div>

          <div className='mt3'>
            <Label noPadding>
              <FormattedMessage id='Options' defaultMessage='Options' />
            </Label>
            <FormControlLabel
              label={
                <FormattedMessage
                  id='AppConfigSRM.RatingQuestions'
                  defaultMessage='Review Rating Questions'
                />
              }
              control={
                <StyleCheckbox
                  name='rating'
                  checked={reviewOptions?.get('rating')}
                  color='primary'
                  size='small'
                  onChange={handleReviewOptionsChange}
                  disabled={isReadonly}
                />
              }
            />
            <br />
            <FormControlLabel
              label={
                <FormattedMessage
                  id='AppConfigSRM.RatingPreferred'
                  defaultMessage='Review Preferred Supplier Question'
                />
              }
              control={
                <StyleCheckbox
                  name='preferred'
                  checked={reviewOptions?.get('preferred')}
                  color='primary'
                  size='small'
                  onChange={handleReviewOptionsChange}
                  disabled={isReadonly}
                />
              }
            />
          </div>
        </>
      )}

      {isBuyerAdmin && (
        <>
          <Label>
            <FormattedMessage
              id='AppConfigSRM.contractExpiryWindowDays'
              defaultMessage='Contract Expiry Window (in Days)'
            />
          </Label>
          <Select
            name='daysToContractExpiry'
            value={daysToContractExpiry}
            onChange={handleContractExpiryWindowChange}
            aria-label='days to contract expiry'
          >
            <FormattedMessage
              id='AppConfigSRM.thirtyDays'
              defaultMessage='30 days'
            >
              {message => <option value='30'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage
              id='AppConfigSRM.sixtyDays'
              defaultMessage='60 days'
            >
              {message => <option value='60'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage
              id='AppConfigSRM.ninetyDays'
              defaultMessage='90 days'
            >
              {message => <option value='90'>{message}</option>}
            </FormattedMessage>
          </Select>
          <DiversityQualificationRules />
        </>
      )}
    </>
  )
}

export default AppConfigSRM
