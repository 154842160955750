import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import community from 'shared/utils/api/community'

export function* loadCommunitySaga(action) {
  const { communityId } = action.payload

  return yield call(community.getById, communityId)
}

export default createApiRequestSaga(loadCommunitySaga)
