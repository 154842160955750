import React, { useEffect, useState, ChangeEvent, MouseEvent } from 'react'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Checkbox from '@material-ui/core/Checkbox'
import withStyles from '@material-ui/styles/withStyles'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettings } from '../../actions'
import RootState from 'shared/models/RootState'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import CategoriesSetting from '../CategoriesSetting'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import camelCase from 'lodash.camelcase'
import startCase from 'lodash.startcase'
import RichTextEditor, {
  createValueFromString,
  createEmptyValue
} from 'react-rte'

const StyleCheckbox = withStyles({
  root: {
    padding: 3
  }
})(Checkbox)

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ]
}

const AppConfigRFI = () => {
  const dispatch = useDispatch()
  const isReadonly = useSelector(
    (state: RootState) => !!state.get('session').get('partnerConcierge')
  )
  const vetApproval = useSelector(settingsSelectors.vetApproval)
  const hasConcierge = useSelector(settingsSelectors.hasConcierge)
  const stateDepartments = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'departments')
  )
  const stateBuyerTermsAndConditions = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'buyerTermsAndConditions')
  )
  const stateCategories = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'categories')
  )

  const [departments, setDepartments] = useState<string>(
    () => stateDepartments?.map(startCase).join(', ') || ''
  )
  const [buyerTermsAndConditions, setBuyerTermsAndConditions] = useState<any>(
    createEmptyValue()
  )

  useEffect(() => {
    if (stateBuyerTermsAndConditions) {
      setBuyerTermsAndConditions(
        createValueFromString(stateBuyerTermsAndConditions || '', 'markdown')
      )
    }
  }, [stateBuyerTermsAndConditions])

  const handleUpdateSettings = (settings: { [key: string]: any }) => {
    dispatch(updateSettings(settings))
  }

  const handleRFIOptionsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.currentTarget
    handleUpdateSettings({
      [name]: checked
    })
  }

  const handleUpdateDepartments = () => {
    const newDepartments = departments?.split(',').map(camelCase) || []

    handleUpdateSettings({
      departments: newDepartments
    })
  }

  const handleRTEChange = (value: any) => {
    setBuyerTermsAndConditions(value)
  }

  const handleTCSave = (e: MouseEvent<HTMLButtonElement>) => {
    handleUpdateSettings({
      buyerTermsAndConditions: buyerTermsAndConditions
        .toString('markdown')
        .substr(0, 10000)
    })
  }

  return (
    <>
      <div>
        <Label noPadding>
          <FormattedMessage id='Options' defaultMessage='Options' />
        </Label>
        <FormControlLabel
          label={
            <FormattedMessage
              id='AppConfigRFI.RequiresRFIApproval'
              defaultMessage='Requires RFI approval'
            />
          }
          control={
            <StyleCheckbox
              color='primary'
              size='small'
              name='vetApproval'
              checked={vetApproval}
              onChange={handleRFIOptionsChange}
              disabled={isReadonly}
            />
          }
        />
        <br />
        <FormControlLabel
          label={
            <FormattedMessage
              id='AppConfigRFI.GenerateTasksForRFIAutomatically'
              defaultMessage='Generate tasks for RFI automatically.'
            />
          }
          control={
            <StyleCheckbox
              color='primary'
              size='small'
              name='hasConcierge'
              checked={hasConcierge}
              onChange={handleRFIOptionsChange}
              disabled={isReadonly}
            />
          }
        />
      </div>
      <Label htmlFor='departments'>
        <FormattedMessage
          id='AppConfigRFI.Departments'
          defaultMessage='Departments (separate them by comma eg. Sales, Development'
        />
      </Label>
      <Input
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setDepartments(e.currentTarget.value)
        }
        value={departments}
        name='departments'
        className='mt2'
        readOnly={isReadonly}
      />
      <Button
        autoSize
        disabled={isReadonly}
        className='mt1'
        label={<FormattedMessage id='Update' defaultMessage='Update' />}
        onClick={handleUpdateDepartments}
      />

      <div className='mt3'>
        <CategoriesSetting
          label={
            <FormattedMessage
              id='AppConfigRFI.Categories'
              defaultMessage='Categories'
            />
          }
          description={`eg. [{
            "categoryId": "manufacturingOps",
            "name": "Manufacturing Ops",
            "subCategories": [{"categoryId": "manufacturingOps-capitalEquipment", "name": "Capital Equipment"}]
            }]`}
          fieldName='categories'
          value={stateCategories ? JSON.stringify(stateCategories.toJS()) : ''}
          onUpdate={handleUpdateSettings}
          isReadonly={isReadonly}
        />
      </div>

      <Label>
        <FormattedMessage
          id='Settings.TermsAndConditions'
          defaultMessage='Terms and Conditions'
        />
      </Label>
      <RichTextEditor
        value={buyerTermsAndConditions}
        onChange={handleRTEChange}
        toolbarConfig={toolbarConfig}
        placeholder={
          <FormattedMessage
            id='Settings.TermsPlaceholder'
            defaultMessage='Customize terms and conditions'
          />
        }
        editorClassName='react-rte-editor'
        toolbarClassName='react-rte-toolbar'
        readOnly={isReadonly}
      />
      <Button
        autoSize
        disabled={isReadonly}
        className='mt1'
        label={<FormattedMessage id='Update' defaultMessage='Update' />}
        onClick={handleTCSave}
      />
    </>
  )
}

export default AppConfigRFI
