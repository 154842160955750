import React, { useState, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import collectionSelectors from '../../../Search/selectors/collectionSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import Badge from '@material-ui/core/Badge'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import iconCart from 'shared/assets/icons/collection.svg'
import { FormattedMessage } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'
import CollectionContainer from '../../../Search/containers/CollectionContainer'
import { AccessControl } from 'shared/models/AccessControl'
import Button from 'shared/components/Button'
import storageManager from 'shared/utils/storageManager'
import SendPunchoutSuppliersButton from '../SendPunchoutSuppliersButton'
import { List } from 'immutable'
import partner from 'shared/utils/api/partner'
import { notify } from 'shared/actions'

const CustomBadge = withStyles({
  badge: {
    marginTop: -2,
    fontSize: '0.5rem',
    height: 16,
    minWidth: 16
  }
})(Badge)

type Props = {
  collection?: List<any>
  accessControl: AccessControl
  notify: typeof notify
}

export const CartContainer = (props: Props) => {
  const cartIconRef = useRef<HTMLImageElement>(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const toggleOpenCart = () => {
    setOpenDialog(open => !open)
  }

  const punchoutPayload = useMemo(() => storageManager.getItem('punchout'), [])

  if (
    props.accessControl.access !== 'punchout' &&
    (!props.collection || props.collection.size < 1)
  ) {
    return null
  }

  return (
    <>
      <div className='mr3 mr4-l'>
        <CustomBadge
          badgeContent={props.collection?.size}
          color='primary'
          onClick={toggleOpenCart}
          className='pointer'
        >
          <Tooltip
            title={
              <FormattedMessage
                id='CartContainer.Tip'
                defaultMessage='List of selected suppliers'
              />
            }
          >
            <img
              src={iconCart}
              alt='cart'
              className='pointer dim'
              style={{ width: 18 }}
              ref={cartIconRef}
            />
          </Tooltip>
        </CustomBadge>
      </div>
      {props.accessControl.access === 'punchout' && (
        <>
          <SendPunchoutSuppliersButton autoSize size='small' className='mr3' />
          <Button
            autoSize
            size='small'
            secondary
            onClick={async () => {
              partner
                .sendPunchoutCart({
                  punchoutPayload,
                  cart: []
                })
                .catch((err: Error) => {
                  props.notify({
                    message: err.message
                  })
                })
            }}
          >
            <FormattedMessage id='CancelButton' />
          </Button>
        </>
      )}
      <Popover
        open={openDialog}
        anchorEl={cartIconRef.current}
        onClose={toggleOpenCart}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className='pa3'>
          {props.collection && props.collection.size > 0 ? (
            <CollectionContainer
              noWrapper
              toggleOpenCart={toggleOpenCart}
              disableActions={props.accessControl.access === 'punchout'}
            />
          ) : (
            <FormattedMessage
              id='CartContainer.Empty'
              defaultMessage='List is empty'
            />
          )}
        </div>
      </Popover>
    </>
  )
}

export default connect(
  state => ({
    collection: collectionSelectors.getCollection(state),
    accessControl: sessionSelectors.getAccessControl(state)
  }),
  {
    notify
  }
)(CartContainer)
