import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, put } from 'redux-saga/effects'
import sendMessageSaga from 'shared/sagas/sendMessageSaga'
import { foundExistingOrgs } from '../../actions'
import { addVetSupplier, resetAddByEmail } from '../../../Vets/actions'
import { connectToSupplier } from '../../../shared/actions'
import supplier from 'shared/utils/api/supplier'
import suppliers from 'shared/utils/api/suppliers'
import extractDomainFromEmail from 'shared/utils/extractDomainFromEmail'
import Immutable, { RecordOf } from 'immutable'

type ActionType = {
  type: string
  payload: RecordOf<{
    companyName: string
    companyWebsite: string
    contactEmail: string
    ignoreMatchingOrgs: boolean
    autoConnect: boolean
    vetId: string
    websiteError: string
    noMessage?: boolean
    allowForbiddenDomain?: boolean
  }>
}

export function* inviteSupplierSaga(action: ActionType) {
  const {
    companyName,
    companyWebsite,
    contactEmail,
    ignoreMatchingOrgs,
    autoConnect,
    vetId,
    websiteError,
    noMessage,
    allowForbiddenDomain
  } = Immutable.isImmutable(action.payload)
    ? action.payload.toJS()
    : action.payload

  // see if the entered domain belongs to an existing org unit
  let orgUnitsFound

  // try to get the supplier by its domain
  if (companyWebsite) {
    orgUnitsFound = yield call(
      supplier.getBuyerByDomain,
      companyWebsite,
      allowForbiddenDomain
    )
  }

  // if couldn't find it by its domain try again with the contact email
  if ((!orgUnitsFound || !orgUnitsFound.found) && contactEmail) {
    orgUnitsFound = yield call(supplier.getBuyerByDomain, contactEmail)
  }

  let newOrgUnit
  if (
    !noMessage &&
    (ignoreMatchingOrgs ||
      (orgUnitsFound && orgUnitsFound.forbidden) ||
      (websiteError && websiteError.length > 0))
  ) {
    // ignore match or forbidden found, send a message to concierge
    yield call(sendMessageSaga, action)
  } else if (!vetId && orgUnitsFound && orgUnitsFound.found) {
    // found match, present them
    yield put(foundExistingOrgs(orgUnitsFound))
  } else {
    // not found, create it and present it
    const websiteUrl = companyWebsite || extractDomainFromEmail(contactEmail)
    newOrgUnit = yield call(suppliers.create, {
      name: companyName,
      websiteUrl
    })

    if (newOrgUnit.id) {
      if (autoConnect) {
        yield put(connectToSupplier({ supplierId: newOrgUnit.id }))
      }
      if (vetId) {
        yield put(
          addVetSupplier({
            vetId,
            supplierIds: [newOrgUnit.id],
            email: contactEmail
          })
        )
        yield put(resetAddByEmail())
      } else {
        yield put(
          foundExistingOrgs([{ id: newOrgUnit.id, name: newOrgUnit.name }])
        )
      }
    }
  }

  return yield {
    ignoreMatchingOrgs,
    newOrgUnit
  }
}

export default createApiRequestSaga(inviteSupplierSaga, {
  dontResetForm: true
})
