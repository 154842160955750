import * as api from '../api'
import apiRoutes from 'shared/routes/apiRoutes'
import parseUser from 'shared/utils/data/parseUser'
import parseSupplier from '../../data/parseSupplier'
import reverseParsedUser from '../../data/reverseParsedUser'
import mapValues from 'lodash.mapvalues'
import fileToUrl from '../../data/fileToUrl'

const apiRoute = `${apiRoutes.authService}/users`

export const getAll = query =>
  api.get(apiRoute, query).then(users => mapValues(users, parseUser))

export const getAllByEmail = email =>
  api.post(`${apiRoutes.authService}/login/email/lookup`, {
    email
  })

export const updateUserRoles = (userId, roles) =>
  api
    .put(`${apiRoute}/${userId}/roles`, {
      roles
    })
    .then(response => response)

export const inviteUser = ({ orgUnitId, user, message, ignoreEmail }) => {
  return api
    .post(`${apiRoutes.authService}/users`, {
      orgUnitId,
      user,
      message,
      ignoreEmail
    })
    .then(parseUser)
}

export const resendUserInvite = ({
  orgUnitIdInvitingTo,
  userIdsToResendInviteTo
}) => {
  return api.post(`${apiRoute}/resendUserInvite`, {
    orgUnitIdInvitingTo,
    userIdsToResendInviteTo
  })
}

export const inviteUsers = (orgUnitId, users) => {
  return Promise.all(users.map(user => inviteUser({ orgUnitId, user })))
}

export const inviteUsersOnBehalf = props =>
  api.post(`${apiRoute}/inviteUsersOnBehalf`, props)

export const getMetricsById = userId => {
  return api
    .get(`${apiRoute}/${userId}/metrics`)
    .then(({ expanded, suppliers, tags }) => ({
      userId,
      tags,
      suppliers: suppliers.map(
        supplier =>
          expanded[supplier.id] &&
          Object.assign({}, supplier, expanded[supplier.id])
      )
    }))
}

export const getUserById = userId => {
  return api.get(`${apiRoute}/${userId}`).then(response => {
    const {
      expanded: { OrgUnit },
      parents: { OrgUnit: parentId }
    } = response
    return {
      user: parseUser(response),
      orgUnitsById: OrgUnit &&
        OrgUnit[parentId] && { [parentId]: parseSupplier(OrgUnit[parentId]) }
    }
  })
}

export const updateById = (userId, userChanges) => {
  return api
    .put(`${apiRoute}/${userId}`, reverseParsedUser(userChanges))
    .then(parseUser)
}

export const updateProfilePictureById = (userId, profilePicture) => {
  return api
    .postFile(`${apiRoute}/${userId}/profilePicture`, {
      profilePicture
    })
    .then(fileToUrl)
}

export const changePassword = ({ userId, password }) =>
  api.put(`${apiRoutes.authService}/password/concierge`, {
    behalfOfUserId: userId,
    newPassword: password
  })

export const search = params => api.get(`${apiRoute}/search`, params)

export const bulkAddUsers = parameters => {
  return api.postFile(`${apiRoute}/addUsers`, parameters)
}
