import React, { Component, ReactNode, MouseEvent, KeyboardEvent } from 'react'
import { WrappedFieldInputProps } from 'redux-form'
import classNames from 'classnames'

type Props = {
  onChange: (
    e: MouseEvent<HTMLLabelElement> | KeyboardEvent<HTMLLabelElement>
  ) => void
  checked?: boolean
  label?: string | ReactNode
  input?: WrappedFieldInputProps
  name?: string
  labelRight?: boolean
  labelFontLight?: boolean
  disabled?: boolean
  ariaLabel?: string
}

class Switch extends Component<Props> {
  render() {
    const {
      input,
      checked,
      label,
      labelRight,
      labelFontLight,
      onChange,
      disabled,
      ariaLabel
    } = this.props

    const switchClass =
      (!disabled && checked) || (input && input.value && !disabled)
        ? 'bg-teal'
        : 'bg-black-50'
    const dotClass = checked || (input && input.value) ? 'fr' : ''
    const labelClass = `${labelFontLight ? 'fw4' : 'fw6'} f7 dib v-mid ${
      labelRight ? 'ml2' : 'mr2'
    }`

    return (
      <div className='dib'>
        {label && !labelRight && <div className={labelClass}>{label}</div>}
        <label
          role='switch'
          aria-checked={checked}
          aria-label={ariaLabel}
          aria-disabled={disabled}
          onClick={
            disabled
              ? undefined
              : input
              ? () => input.onChange(!input.value)
              : onChange
          }
          className={classNames(
            `v-mid relative w1-5 pa1 br-pill dib v-mid`,
            !disabled && `pointer`,
            switchClass
          )}
          tabIndex={0}
          onKeyDown={e => {
            if (e.key === 'Enter' && !disabled) {
              input ? input.onChange(!input.value) : onChange(e)
            }
          }}
        >
          <div
            className={`br-100 bg-white ${dotClass}`}
            style={{ width: '0.75rem', height: '0.75rem' }}
          />
        </label>
        {label && labelRight && <div className={labelClass}>{label}</div>}
      </div>
    )
  }
}

export default Switch
