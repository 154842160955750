import React, { Component } from 'react'
import FilterCheckboxList from '../../components/FilterCheckboxList'
import { fromJS, List, Map, RecordOf } from 'immutable'
import qs from 'qs'
import searchContactsSelectors from '../../../shared/selectors/searchContactsSelectors'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router'
import RootState from 'shared/models/RootState'

type Props = {
  query: Map<string, Map<string, List<string>>>
  filterKey: string
  items: List<
    RecordOf<{
      key: string
      key_as_string?: string
      doc_count: number
    }>
  >
  selectedItems: List<string>
  isSearching: boolean
  labelFormatter?: (params: string) => string
} & RouteComponentProps

class FilterCheckboxListContainer extends Component<Props> {
  handleChange = e => {
    const { history, query, filterKey } = this.props

    const { name, checked } = e.currentTarget

    const newQuery = query.updateIn(
      ['filter', filterKey],
      fromJS([]),
      filtersForKey =>
        checked
          ? filtersForKey.push(name)
          : filtersForKey.filter(val => val !== name)
    )

    history.push({
      search: qs.stringify(newQuery.toJS())
    })
  }

  render() {
    return <FilterCheckboxList {...this.props} onChange={this.handleChange} />
  }
}

type ContainerProps = {
  filterKey: string
} & RouteComponentProps

export default withRouter(
  connect((state: RootState, props: ContainerProps) => {
    const query = searchContactsSelectors.getParsedQuery(props.location.search)
    const filters =
      state.getIn([
        'buyer',
        'contactsSearch',
        'resultFilters',
        props.filterKey
      ]) || fromJS([])
    const selectedFilters = query.getIn(['filter', props.filterKey], fromJS([]))

    return {
      query,
      items: filters,
      selectedItems: selectedFilters,
      isSearching: state.getIn(['buyer', 'contactsSearch', 'isSearching'])
    }
  })(FilterCheckboxListContainer)
)
