import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select, all } from 'redux-saga/effects'
import survey from 'shared/utils/api/survey'
import surveySelectors from '../../selectors/surveySelectors'

export function* saveSurveyResponseSaga(action) {
  const { surveyResponseDetailId, answer, questionId } = action.payload
  const valuesToClearAttachments = ['no', 'preferNotToAnswer']
  const specialCaseQuestions = [
    'occupationalChargesViolations',
    'negativeEnvironmentalImpacts'
  ]

  let response
  if (
    surveyResponseDetailId &&
    (valuesToClearAttachments.includes(answer) ||
      (specialCaseQuestions.includes(questionId) && answer === 'true'))
  ) {
    // remove all attachments if any before saving the response
    const responseDetail = yield select(
      surveySelectors.getSurveyResponseDetailById,
      surveyResponseDetailId
    )
    const attachments = responseDetail?.get('attachments')?.toJS()
    const attachmentType = responseDetail?.get('attachmentType')
    if (attachments?.length > 0 && attachmentType === 'doc') {
      yield all(
        attachments.map(attachment => {
          return call(survey.removeSurveyAttachment, {
            surveyResponseDetailId,
            attachmentType: 'doc',
            attachment
          })
        })
      )
    }
    response = yield call(survey.saveSurveyResponse, {
      ...action.payload,
      attachments: []
    })
  } else {
    response = yield call(survey.saveSurveyResponse, action.payload)
  }

  return yield response
}

export default createApiRequestSaga(saveSurveyResponseSaga)
