import qs from 'qs'
import ApiError from './ApiError'
import auth from './auth'
import storageManager from '../storageManager'

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

export const get = <T = any>(url: string, params?: object) => {
  if (params) {
    const queryParams = qs.stringify(params)
    url = `${url}?${queryParams}`
  }

  return doRequest<T>(url, {
    method: 'GET'
  })
}

export const post = (url: string, body?: object, query?: object) => {
  if (query) {
    const queryParams = qs.stringify(query)
    url = `${url}?${queryParams}`
  }

  return doRequest(url, {
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
    method: 'POST'
  })
}

export const put = (url: string, body?: object) => {
  return doRequest(url, {
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
    method: 'PUT'
  })
}

export const remove = (url: string, body?: object, query?: object) => {
  if (query) {
    const queryParams = qs.stringify(query)
    url = `${url}?${queryParams}`
  }

  return doRequest(url, {
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
    method: 'DELETE'
  })
}

export const postForm = (url: string, body: object) => {
  let form = document.createElement('form')
  form.method = 'post'
  form.action = url

  Object.entries(body).forEach(([key, value]) => {
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = key
    hiddenField.value = JSON.stringify(value)
    form.appendChild(hiddenField)
  })

  document.body.appendChild(form)
  form.submit()
}

export const postFile = (url: string, body: { [key: string]: any }) =>
  uploadFile(url, body, 'POST')
export const putFile = (url: string, body: { [key: string]: any }) =>
  uploadFile(url, body, 'PUT')

export const doOpenRequest = <T>(url: string, fetchOptions: RequestInit) => {
  return window.fetch(url, fetchOptions).then(handleResponse) as Promise<T>
}

function uploadFile(url: string, body: {}, method: RequestMethod) {
  const formData = objectToFormData(body)

  return doRequest(url, {
    body: formData,
    method
  })
}

function objectToFormData(obj, fd?: FormData, namespace?: string): FormData {
  const formData = fd || new window.FormData()
  let formKey

  Object.keys(obj).forEach(k => {
    if (namespace) {
      formKey = `${namespace}[${k}]`
    } else {
      formKey = k
    }

    if (obj[k] !== undefined && obj[k] !== null) {
      // recurse if the value is a another object
      if (
        typeof obj[k] === 'object' &&
        !(obj[k] instanceof Blob) &&
        !(obj[k] instanceof File)
      ) {
        objectToFormData(obj[k], formData, formKey)

        // need to include the filename if it is a blob
      } else if (obj[k] instanceof Blob) {
        formData.append(formKey, obj[k], obj[k].filename)
      } else {
        formData.append(formKey, obj[k])
      }
    }
  })

  return formData
}

function doRequest<T = any>(
  url: string,
  fetchOptions: RequestInit
): Promise<T> {
  const optionsWithAuth = fetchOptionsWithAuth(fetchOptions)
  return (
    window
      .fetch(url, optionsWithAuth)
      // retry the fetch if it fails
      .catch(err => {
        console.log(err)
        console.log('Retry request to:', url)
        return window.fetch(url, optionsWithAuth)
      })
      .then(response => retry502response(response, url, optionsWithAuth))
      .then(response => handle401Response(response, url, fetchOptions))
      .then(handleResponse)
  )
}

function handleResponse(response: Response) {
  if (response.ok) {
    const contentType = response.headers.get('content-type')
    return (
      response.status !== 200 ||
      (contentType?.includes('application/octet-stream')
        ? response.blob()
        : response.json())
    )
  } else {
    if (response.status === 429) {
      return Promise.reject(
        new ApiError({
          statusCode: response.status,
          clientErrorMessage: 'Too many attempts. Please try again in 1 minute.'
        })
      )
    } else {
      const contentType = response.headers.get('content-type')
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then(err => Promise.reject(new ApiError(err)))
      } else {
        return Promise.reject(
          new ApiError({
            statusCode: response.status,
            devErrorMessage: response.statusText
          })
        )
      }
    }
  }
}

// retry once 502 responses
function retry502response(
  response: Response,
  url: string,
  fetchOptions: RequestInit
) {
  return !response.ok && response.status === 502
    ? window.fetch(url, fetchOptions)
    : response
}

function handle401Response(response, url, fetchOptions) {
  // refresh the tokens if it is a 401
  if (!response.ok && response.status === 401) {
    const refreshToken = storageManager.getItem('refreshToken')
    return auth.refreshTokens(refreshToken).then(tokens => {
      if (!!tokens) {
        // retry the last request if the tokens refreshed
        const fOptionsWithAuth = fetchOptionsWithAuth(fetchOptions)
        return window
          .fetch(url, fOptionsWithAuth)
          .then(response => retry502response(response, url, fOptionsWithAuth))
      } else {
        // otherwise just reject with a 401
        return Promise.reject(
          new ApiError({
            statusCode: 401,
            clientErrorMessage: 'Your session has expired.'
          })
        )
      }
    })
  } else {
    // continue the chain if is not a 401
    return response
  }
}

function fetchOptionsWithAuth(fetchOptions: RequestInit = {}) {
  const accessToken = storageManager.getItem('accessToken')

  return {
    ...fetchOptions,
    headers: accessToken
      ? {
          ...fetchOptions.headers,
          Authorization: `Bearer ${accessToken}`
        }
      : {
          ...fetchOptions.headers
        }
  }
}

export const download = (url: string, params?: object) => {
  if (params) {
    const queryParams = qs.stringify(params)
    url = `${url}?${queryParams}`
  }
  return doDownloadRequest(url, {
    method: 'GET'
  })
}

function doDownloadRequest(url: string, fetchOptions: RequestInit) {
  const fOptionsWithAuth = fetchOptionsWithAuth(fetchOptions)
  return window
    .fetch(url, fOptionsWithAuth)
    .then(response => retry502response(response, url, fOptionsWithAuth))
    .then(response => handle401Response(response, url, fetchOptions))
    .then(handleDownloadResponse)
}

function handleDownloadResponse(response) {
  if (response.ok) {
    return response
  } else {
    if (response.status === 429) {
      return Promise.reject(
        new ApiError({
          statusCode: response.status,
          clientErrorMessage: 'Too many attempts. Please try again in 1 minute.'
        })
      )
    } else {
      const contentType = response.headers.get('content-type')
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then(err => Promise.reject(new ApiError(err)))
      } else {
        return Promise.reject(
          new ApiError({
            statusCode: response.status,
            devErrorMessage: response.statusText
          })
        )
      }
    }
  }
}
