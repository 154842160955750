import { call } from 'redux-saga/effects'
import vets from 'shared/utils/api/vets'
import parseCard from 'shared/utils/data/parseCard'
import mapValues from 'lodash.mapvalues'
import mapKeys from 'lodash.mapkeys'
import forEach from 'lodash.foreach'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import parseSupplier from '../../../../shared/utils/data/parseSupplier'

export function* loadVetSaga(action) {
  // vetId is passed in as action.payload
  const response = yield call(vets.getVet, action.payload)
  // response: { vet, vetBuyerSupplier }

  // check if supplier object exists (if supplier still exists at Tealbook), and return a new response.vetBuyerSupplier object if true
  const cleanVetBuyerSupplier = Object.keys(response.vetBuyerSupplier).reduce(
    (result, vetBSId) => {
      if (response.vetBuyerSupplier[vetBSId].supplier) {
        result[vetBSId] = response.vetBuyerSupplier[vetBSId]
      }
      return result
    },
    {}
  )

  // setup cards state
  let cardsById = {}
  let cardsIdsBySupplierByUser = {}
  forEach(cleanVetBuyerSupplier, vetBuyerSupplier => {
    cardsById = Object.assign(
      {},
      cardsById,
      mapValues(vetBuyerSupplier.cards, parseCard)
    )
    cardsIdsBySupplierByUser = Object.assign({}, cardsIdsBySupplierByUser, {
      [vetBuyerSupplier.supplier.id]: mapValues(
        mapKeys(vetBuyerSupplier.cards, card => card.parents.User),
        card => card.id
      )
    })
  })

  // setup users state
  let usersAllIdsBySupplier = {}
  let usersById = {}
  forEach(cleanVetBuyerSupplier, vetBuyerSupplier => {
    if (vetBuyerSupplier.users) {
      usersAllIdsBySupplier[vetBuyerSupplier.supplier.id] = Object.keys(
        vetBuyerSupplier.users
      )
    }
  })
  forEach(cleanVetBuyerSupplier, vetBuyerSupplier => {
    if (vetBuyerSupplier.users) {
      usersById = Object.assign({}, usersById, vetBuyerSupplier.users)
    }
  })

  let orgUnitsById = {}
  forEach(cleanVetBuyerSupplier, vetBuyerSupplier => {
    if (vetBuyerSupplier.supplier) {
      orgUnitsById[vetBuyerSupplier.supplier.id] = parseSupplier(
        vetBuyerSupplier.supplier
      )
    }
  })

  return yield {
    cardsById,
    cardsIdsBySupplierByUser,
    usersById,
    usersAllIdsBySupplier,
    orgUnitsById,
    //...response
    vet: response.vet,
    vetBuyerSupplier: cleanVetBuyerSupplier
  }
}

export default createApiRequestSaga(loadVetSaga, {
  notifyOnError: false
})
