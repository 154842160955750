import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select } from 'redux-saga/effects'
import posts from 'shared/utils/api/posts'

export function* createPostSaga(action) {
  const { visibility, postImageFile: file, ...rest } = action.payload
  const companyData = yield select(state =>
    state.getIn(['supplier', 'company', 'data'])
  )
  const orgUnit = companyData.get('id')
  const name = companyData.get('name')

  const post = yield call(posts.create, {
    visibility: visibility === 'all' ? ['buyer', 'supplier'] : [visibility],
    ...rest
  })

  if (file) {
    const fileResponse = yield call(posts.uploadImage, {
      postId: post.id,
      file
    })
    post.image = fileResponse
  }

  post.expanded['OrgUnit'] = { [orgUnit]: { orgUnit, name } }
  return yield post
}

export default createApiRequestSaga(createPostSaga)
