import React, { Component } from 'react'
import { List, RecordOf } from 'immutable'

const styles = {
  label: 'f6 db',
  input: 'mr1',
  disabled: 'bg-near-white gray',
  errorMessage: 'f7 fw3 red mt2 dib'
}

type Props = {
  options: List<RecordOf<{ value: string; label: string }>>
  meta: { error?: string; touched?: boolean }
  input: {
    name: string
    onChange: (changeChecked: number | Array<string>) => void
    onFocus: () => void
    value: Array<string>
  }
}

class CheckboxGroup extends Component<Props> {
  render() {
    const { input, meta = {}, options, ...otherProps } = this.props

    const { name, onChange, onFocus } = input
    const inputValue = input.value
    const checkboxes = options.map((option, index) => {
      const handleChange = event => {
        onChange(
          event.target.checked
            ? inputValue.push(option.get('value'))
            : inputValue.splice(inputValue.indexOf(option.get('value')), 1)
        )
      }

      const checked = inputValue.includes(option.get('value'))
      return (
        <label key={`checkbox-${index}`} className={styles.label}>
          <input
            type='checkbox'
            name={`${name}[${index}]`}
            className={styles.input}
            value={option.get('value')}
            checked={checked}
            onChange={handleChange}
            onFocus={onFocus}
            {...otherProps}
          />
          <span>{option.get('label')}</span>
        </label>
      )
    })

    return (
      <div>
        <div>{checkboxes}</div>
        {meta.touched && meta.error && (
          <span className={styles.errorMessage}>{meta.error}</span>
        )}
      </div>
    )
  }
}

export default CheckboxGroup
