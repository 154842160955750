import { connect } from 'react-redux'
import CorporateSection from '../../../../shared/components/CorporateSection'
import profileSelectors from '../../selectors/profileSelectors'
import RootState from 'shared/models/RootState'

export default connect((state: RootState) => {
  const parentOrg = profileSelectors.getParentOrg(state)
  const orgName = profileSelectors.getDataField(state, 'name')
  const parentOrgName =
    parentOrg && orgName !== parentOrg.get('name') ? parentOrg.get('name') : ''

  return {
    name: parentOrgName,
    numberOfEmployees: (parentOrg && parentOrg.get('employeeNumber')) || '',
    yearFounded: (parentOrg && parentOrg.get('yearFounded')) || '',
    annualRevenue: (parentOrg && parentOrg.get('yearlyRevenue')) || '',
    dunsNumber: profileSelectors.getDataField(state, 'dunsNumber'),
    taxId: profileSelectors.getDataField(state, 'taxId'),
    lei: profileSelectors.getDataField(state, 'lei'),
    classificationCodesBySchema: profileSelectors.groupClassificationCodesBySchema(
      state
    )
  }
})(CorporateSection)
