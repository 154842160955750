export default function parseSupplier({ id, logo, name, websiteUrl, domains }) {
  return {
    id,
    name,
    supplier: {
      links: {
        websiteUrl
      },
      logo
    },
    domains
  }
}
