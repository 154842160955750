import * as actionTypes from '../../actionTypes'
import {
  LINK_BUYER_WITH_INTEGRATION,
  UPDATE_SETTINGS,
  LOAD_WEBHOOK_CONFIGS,
  SAVE_WEBHOOK_CONFIG
} from '../../../../admin/actionTypes'
import { fromJS, List, Map, RecordOf } from 'immutable'
import { requestSuccess, requestFailure, LOGOUT } from 'shared/actionTypes'
import UploadedFile from 'shared/models/UploadedFile'
import {
  BaseRulesType,
  ExcludeRulesType,
  IncludeRulesType
} from 'buyer/Insights/store/diversityReportReducer'

export type LicenseAccess = {
  accessNonRelatedSuppliers: boolean
  accessSupplierSpend: boolean
  accessExportBasic: boolean
  accessExportAdvanced: boolean
  accessExportAll: boolean
  accessRFI: boolean
  accessInsights: boolean
  accessContacts: boolean
  accessChat: boolean
  accessNews: boolean
  accessCommunities: boolean
  accessDiversityReport: boolean
  accessTier2: boolean
}
export type CorporateRulesType = {
  baseRules: RecordOf<BaseRulesType>
  includeRules: RecordOf<IncludeRulesType>
  excludeRules: RecordOf<ExcludeRulesType>
  configuredInfo: RecordOf<{
    created: { createdDate: Date; user: string }
    updated: { updatedDate: Date; user: string }
  }>
  uniqueSpend: boolean
}
export type SettingsState = {
  isLoading: boolean
  isUpdating: boolean
  vetApproval: boolean
  attachmentTypes: List<any>
  relationshipAttributes: List<any>
  ratingQuestions: List<any>
  error: Error
  allowWordDocs: boolean
  partners: List<string>
  isSaml: boolean
  saml: Map<string, any>
  logoFile?: UploadedFile
  supplierProfileCustomPage: RecordOf<{
    title: string
    pageSections: List<{
      title: string
      questions: List<{
        questionId: string
        required?: boolean
      }>
    }>
  }>
  licenseConfig?: RecordOf<{
    business: RecordOf<LicenseAccess>
    professional: RecordOf<LicenseAccess>
    advanced: RecordOf<LicenseAccess>
    elite: RecordOf<LicenseAccess>
  }>
  corporateDiversityQualificationRules?: RecordOf<CorporateRulesType>
  integrations?: Map<string, any>
  acceptCertBasedOnReportStartDate: boolean
}

export const defaultState = fromJS({
  isLoading: false,
  isUpdating: false,
  vetApproval: false,
  attachmentTypes: [],
  relationshipAttributes: [],
  ratingQuestions: [],
  error: undefined,
  allowWordDocs: false,
  partners: [],
  isSaml: false,
  saml: '',
  hideChat: false,
  logoFile: undefined,
  supplierProfileCustomPage: {},
  corporateDiversityQualificationRules: undefined,
  integrations: {},
  acceptCertBasedOnReportStartDate: false
})

const settingsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_SETTINGS:
      return state.set('isLoading', true)

    case UPDATE_SETTINGS:
      return state.set('isUpdating', true)

    case requestSuccess(LINK_BUYER_WITH_INTEGRATION):
      return action.payload.credsSaved?.status === 'success'
        ? state.mergeIn(
            ['integrations', action.payload.integrator],
            fromJS({
              credentialsSubmitted: true,
              username: action.payload.username
            })
          )
        : state
    case requestSuccess(UPDATE_SETTINGS):
      return state.merge(fromJS(action.payload)).set('isUpdating', false)

    case requestFailure(UPDATE_SETTINGS):
      return state.set('isUpdating', false)

    case requestSuccess(actionTypes.GET_SETTINGS):
      const { questionsById, ...rest } = action.payload
      return state.merge(
        fromJS({
          isLoading: false,
          ...rest
        })
      )

    case requestFailure(actionTypes.GET_SETTINGS):
      return state.merge(
        fromJS({
          error: action.payload,
          isLoading: false
        })
      )

    case requestSuccess(LOAD_WEBHOOK_CONFIGS):
      return state.setIn(
        ['integrationPartnerConfigs', 'webhookConfig'],
        fromJS(action.payload)
      )

    case requestSuccess(SAVE_WEBHOOK_CONFIG):
      return state.setIn(
        ['integrationPartnerConfigs', 'webhookConfig', action.payload.webhook],
        fromJS(action.payload)
      )

    case LOGOUT:
      return defaultState

    default:
      return state
  }
}

export default settingsReducer
