import { Record, fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { LOGOUT } from 'shared/actionTypes'

export const SuggestRecord = Record({
  cache: fromJS({}),
  searching: false,
  searchError: undefined
})

const suggestReducer = (state = new SuggestRecord(), action) => {
  switch (action.type) {
    case actionTypes.SUGGEST_SUPPLIER:
      return state.merge({
        searching: true,
        searchError: undefined
      })
    case actionTypes.SUGGEST_SUPPLIER_SUCCESS:
      const { key, results } = action.payload
      return state
        .merge({
          searching: false,
          searchError: undefined
        })
        .mergeIn(['cache'], fromJS({ [key]: results }))
    case actionTypes.SUGGEST_SUPPLIER_FAILURE:
      return state.merge({
        searching: false,
        searchError: action.payload
      })
    case LOGOUT:
      return new SuggestRecord()
    default:
      return state
  }
}

export default suggestReducer
