import React, { ReactNode } from 'react'

type Props = {
  label: ReactNode
  percentage: number
  value: string | number
}

const ProgressBar = ({ label, percentage = 0, value }: Props) => {
  return (
    <div className='relative mv1 bg-near-white'>
      <div
        className='absolute bg-moon-gray o-50 top-0 bottom-0'
        style={{ width: `${percentage}%` }}
      />
      <div className='relative flex justify-between pa1 f7 fw6 black'>
        <span>{label}</span>
        <div>
          {value &&
            `(${
              typeof value === 'number' ? value.toLocaleString() : value
            })`}{' '}
          {percentage}%
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
