import { call } from 'redux-saga/effects'
import suppliers from 'shared/utils/api/suppliers'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import mapValues from 'lodash.mapvalues'
import mapKeys from 'lodash.mapkeys'

export function* loadSupplierProfileSaga(action) {
  const {
    supplier,
    communities = {},
    cards = {},
    users = {},
    tags = {},
    relationship = {},
    supplierRelationships = {},
    surveyResponse
  } = yield call(suppliers.getById, action.payload, action.meta)
  const cardsIdsByUser = mapValues(
    mapKeys(cards, card => card.owner),
    card => card.id
  )

  return yield {
    supplier,
    communities,
    users,
    combinedTags: tags,
    cardsById: cards,
    cardsIdsByUser,
    relationship,
    supplierRelationships,
    surveyResponse
  }
}

export default createApiRequestSaga(loadSupplierProfileSaga)
