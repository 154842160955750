import ExtendableError from 'es6-error'

type InitialValues = {
  clientErrorMessage?: string
  devErrorMessage?: string
  statusCode?: number
  errorCode?: number
}

class ApiError extends ExtendableError {
  statusCode?: number = 0
  devErrorMessage?: string = ''
  errorCode?: number = 0

  constructor({
    clientErrorMessage = '',
    devErrorMessage,
    statusCode,
    errorCode
  }: InitialValues) {
    super(clientErrorMessage)
    this.statusCode = statusCode
    this.devErrorMessage = devErrorMessage
    this.errorCode = errorCode
  }
}

export default ApiError
