export const GET_COMMUNITIES = 'buyer/Communities/GET_COMMUNITIES'
export const GET_COMMUNITY = 'buyer/Communities/GET_COMMUNITY'
export const REMOVE_FROM_COMMUNITY = 'buyer/Communities/REMOVE_FROM_COMMUNITY'
export const INVITE_TO_COMMUNITIES = 'buyer/Communities/INVITE_TO_COMMUNITIES'
export const INVITE_EXISTING = 'buyer/Communities/INVITE_EXISTING'
export const BATCH_INVITE_TO_COMMUNITIES =
  'buyer/Communities/BATCH_INVITE_TO_COMMUNITIES'
export const UPDATE_COMMUNITY_BUYER = 'buyer/Communities/UPDATE_COMMUNITY_BUYER'
export const UPDATE_COMMUNITY = 'buyer/Communities/UPDATE_COMMUNITY'
export const LOAD_COMMUNITY_INSIGHT = 'buyer/Communities/LOAD_COMMUNITY_INSIGHT'
export const BATCH_INVITE_TO_TIER2 = 'buyer/Communities/BATCH_INVITE_TO_TIER2'
