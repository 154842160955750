import { call, fork, select, take, race, delay } from 'redux-saga/effects'
import messages from '../../utils/api/message'
import createApiRequestSaga from '../../utils/sagas/createApiRequestSaga'
import * as sharedActionTypes from 'shared/actionTypes'
import { EXIT_MESSAGES_APP } from 'messages/actionTypes'
import parseSupplier from '../../utils/data/parseSupplier'
import mapValues from 'lodash.mapvalues'

export const apiRequestSaga = createApiRequestSaga(loadConversationWithUserSaga)

export function* loadConversationWithUserSaga(action) {
  // 2 ways to load conversation, by userId (userToMessage) or by topicId (messageTopicId)
  let userId = action.payload.userToMessage
  const topicId = yield action.payload.messageTopicId ||
    select(state => state.getIn(['mess', 'topicsByUser', userId]))

  // exit early if there are no topics
  if (!topicId) {
    return yield {}
  }

  const conversationMessages = yield call(
    messages.getMessagesByAncestorId,
    topicId
  )
  const orgUnitsById = conversationMessages.reduce((agg, message) => {
    return message.supplierProfileId
      ? Object.assign(
          {},
          agg,
          mapValues(message.expanded.OrgUnit, parseSupplier)
        )
      : agg
  }, {})

  if (!userId) {
    const currentUserId = yield select(state =>
      state.getIn(['session', 'userId'])
    )
    userId =
      conversationMessages &&
      conversationMessages[0] &&
      conversationMessages[0].userIds
        .filter(userId => userId !== currentUserId)
        .toString()
  }

  return yield {
    topicId,
    userId,
    messages: conversationMessages,
    orgUnitsById
  }
}

export default function* saga() {
  // start the saga when we select a user to message
  let action = yield take(sharedActionTypes.SELECT_USER_TO_MESSAGE)

  while (true) {
    // get messages
    yield fork(apiRequestSaga, action)

    // refresh every 10 seconds
    const {
      loggedOut,
      changedUser,
      unselectUser,
      exitMessagesApp
    } = yield race({
      loggedOut: take(sharedActionTypes.LOGOUT),
      refresh: delay(10000), // 10 seconds in miliseconds
      unselectUser: take(sharedActionTypes.UNSELECT_USER_TO_MESSAGE),
      changedUser: take(sharedActionTypes.SELECT_USER_TO_MESSAGE),
      exitMessagesApp: take(EXIT_MESSAGES_APP)
    })

    if (loggedOut || exitMessagesApp || unselectUser) {
      action = yield take(sharedActionTypes.SELECT_USER_TO_MESSAGE)
    } else if (changedUser) {
      action = changedUser
      continue
    }
  }
}
