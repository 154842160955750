import { call, select } from 'redux-saga/effects'
import cards from 'shared/utils/api/cards'
import cardsSelectors from '../../selectors/cardsSelectors'
import createApiRequest from 'shared/utils/sagas/createApiRequestSaga'

function* unfollowSupplierSaga(action) {
  const supplierId = action.payload
  const card = yield select(cardsSelectors.getBySupplier, supplierId)

  /* no card, throw an error */
  if (
    !card ||
    card.size === 0 ||
    card.get('placeholderCard') ||
    card.get('id').endsWith('-1')
  ) {
    throw new Error('Try to unfollow without a real card')
  }

  const cardId = card.get('id')
  const cardChanges = { connected: false }
  yield call(cards.update, cardId, cardChanges)

  return yield {
    cardId,
    cardChanges
  }
}

export default createApiRequest(unfollowSupplierSaga)
