import { all, takeLatest, takeEvery } from 'redux-saga/effects'
import loadVetsSaga from './loadVetsSaga'
import loadVetSaga from './loadVetSaga'
import * as actionTypes from '../actionTypes'
import startVetSaga from './startVetSaga'
import addInternalCommentSaga from './addInternalCommentSaga'
import updateInternalCommentSaga from './updateInternalCommentSaga'
import removeInternalCommentSaga from './removeInternalCommentSaga'
import updateFormVetSaga from './updateVetSaga/updateVetSaga'
import addVetSupplierSaga from './addVetSupplierSaga'
import removeVetSupplierSaga from './removeVetSupplierSaga'
import inviteVetSupplierSaga from './inviteVetSupplierSaga'
import approveVetSupplierSaga from './approveVetSupplierSaga'
import addCriteriaSaga from './addCriteriaSaga'
import removeCriteriaSaga from './removeCriteriaSaga'
import updateCriteriaSaga from './updateCriteriaSaga'
import upVoteSaga from './upVoteSaga'
import downVoteSaga from './downVoteSaga'
import chooseVetSupplierSaga from './chooseVetSupplierSaga'
import rejectVetSupplierSaga from './rejectVetSupplierSaga'
import addVetTeamMemberSaga from './addVetTeamMemberSaga'
import removeVetTeamSaga from './removeVetTeamSaga'
import updateVetTeamSaga from './updateVetTeamSaga'
import takeFirst from 'shared/utils/sagas/takeFirst'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'
import tagSuppliersSaga from './tagSuppliersSaga'
import removeAttachmentSaga from './removeAttachmentSaga'
import updateVetBuyerSupplierSaga from './updateVetBuyerSupplierSaga'
import removeResponseFileSaga from './removeResponseFileSaga'
import addVetSupplierByEmailSaga from './addVetSupplierByEmailSaga'
import addContactSendSaga from './addContactSendSaga'
import removeContactSendSaga from './removeContactSendSaga'
import updateContactSendSaga from './updateContactSendSaga'

export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.ADD_INTERNAL_COMMENT, addInternalCommentSaga),
    takeLatest(actionTypes.UPDATE_INTERNAL_COMMENT, updateInternalCommentSaga),
    takeLatest(actionTypes.REMOVE_INTERNAL_COMMENT, removeInternalCommentSaga),
    takeLatest(actionTypes.LOAD_VETS, loadVetsSaga),
    takeLatest(actionTypes.LOAD_VET, loadVetSaga),
    takeEvery(actionTypes.UPDATE_VET, updateFormVetSaga),
    takeLatest(actionTypes.ADD_CRITERIA, addCriteriaSaga),
    takeLatest(actionTypes.REMOVE_CRITERIA, removeCriteriaSaga),
    takeLatest(actionTypes.UPDATE_CRITERIA, updateCriteriaSaga),
    takeFirst(actionTypes.UP_VOTE, upVoteSaga),
    takeFirst(actionTypes.DOWN_VOTE, downVoteSaga),
    takeFirst(actionTypes.CHOOSE_VET_SUPPLIER, chooseVetSupplierSaga),
    takeFirst(actionTypes.REJECT_VET_SUPPLIER, rejectVetSupplierSaga),
    takeLatest(actionTypes.ADD_VET_TEAM_MEMBER, addVetTeamMemberSaga),
    takeFirst(actionTypes.REMOVE_VET_TEAM_MEMBER, removeVetTeamSaga),
    takeFirst(actionTypes.UPDATE_VET_TEAM_MEMBER, updateVetTeamSaga),
    takeFirst(actionTypes.CREATE_VET, startVetSaga),
    takeFirst(actionTypes.ADD_VET_SUPPLIER, addVetSupplierSaga),
    takeFirst(actionTypes.REMOVE_VET_SUPPLIER, removeVetSupplierSaga),
    takeFirst(actionTypes.INVITE_VET_SUPPLIER, inviteVetSupplierSaga),
    takeFirst(actionTypes.APPROVE_VET_SUPPLIER, approveVetSupplierSaga),
    takeFirst(actionTypes.TAG_SUPPLIERS, tagSuppliersSaga),
    takeFirst(
      actionTypes.CLOSE_VET,
      createApiRequestSaga(vets.close, { usePayload: true })
    ),
    takeFirst(
      actionTypes.CANCEL_VET,
      createApiRequestSaga(vets.cancel, { usePayload: true })
    ),
    takeFirst(
      actionTypes.REOPEN_VET,
      createApiRequestSaga(vets.reopen, { usePayload: true })
    ),
    takeFirst(
      actionTypes.DELETE_VET,
      createApiRequestSaga(vets.remove, { usePayload: true })
    ),
    takeFirst(
      actionTypes.DELETE_PROJECT_BACKGROUND,
      createApiRequestSaga(vets.removeProjectBackground, {
        usePayload: true,
        returnPayload: true
      })
    ),
    takeFirst(actionTypes.REMOVE_ATTACHMENT, removeAttachmentSaga),
    takeFirst(
      actionTypes.UPDATE_VET_BUYER_SUPPLIER,
      updateVetBuyerSupplierSaga
    ),
    takeFirst(actionTypes.REMOVE_RESPONSE_FILE, removeResponseFileSaga),
    takeFirst(actionTypes.ADD_VET_SUPPLIER_BY_EMAIL, addVetSupplierByEmailSaga),
    takeFirst(
      actionTypes.ADD_VET_ADDITION,
      createApiRequestSaga(vets.addVetAddition, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(
      actionTypes.CANCEL_VET_ADDITION,
      createApiRequestSaga(vets.cancelVetAddition, {
        usePayload: true,
        returnPayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(actionTypes.ADD_CONTACT_SEND_TO, addContactSendSaga),
    takeFirst(actionTypes.REMOVE_CONTACT_SEND_TO, removeContactSendSaga),
    takeFirst(actionTypes.UPDATE_CONTACT_SEND_TO, updateContactSendSaga),
    takeLatest(
      actionTypes.LOAD_VET_QUESTIONS,
      createApiRequestSaga(vets.getVetQuestionsForBuyer, {
        usePayload: true
      })
    ),
    takeFirst(
      actionTypes.ANSWER_VET_QUESTION,
      createApiRequestSaga(vets.updateVetQuestionResponse, {
        usePayload: true
      })
    )
  ])
}
