import supplier from 'shared/utils/api/supplier'
import { ADD_TAGS, REMOVE_TAGS, UPDATE_TAGS } from '../../actionTypes'

import createUpdateTagsSaga from 'shared/utils/sagas/createUpdateTagsSaga'

const updateSupplierTagsSaga = createUpdateTagsSaga({
  apiAddTags: supplier.addTags,
  apiDeleteTags: supplier.deleteTags,
  ADD_TAGS,
  REMOVE_TAGS,
  SET_TAGS: UPDATE_TAGS
})

export default updateSupplierTagsSaga
