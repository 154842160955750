import React, { Component } from 'react'
import { connect } from 'react-redux'
import searchSelectors from '../../selectors/searchSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import Pill from 'shared/components/Pill'
import Scrollable from 'shared/components/Scrollable'
import { addTagToSupplier, removeTagFromSupplier } from '../../actions'
import camelCase from 'lodash.camelcase'
import { RecordOf, List } from 'immutable'

type Props = {
  isTealbot: boolean
  tags: List<number>
  peerTags: List<RecordOf<{ name: string }>>
  supplierId: string
  cardId: string
  addTagToSupplier: (params: {
    tagsFieldName: string
    newTag: string
    supplierId: string
    cardId: string
  }) => void
  removeTagFromSupplier: (params: {
    removeTag: string
    supplierId: string
    cardId: string
  }) => void
}
export class MyTagsContainer extends Component<Props> {
  render() {
    const {
      isTealbot,
      tags,
      peerTags,
      addTagToSupplier,
      removeTagFromSupplier,
      supplierId,
      cardId
    } = this.props

    return peerTags.size > 0 ? (
      <div className='mb3'>
        <Scrollable maxHeight={200}>
          {peerTags
            .toList()
            .sort((tagA, tagB) =>
              tagA.get('name').toUpperCase() > tagB.get('name').toUpperCase()
                ? 1
                : -1
            )
            .map(tag => {
              const tagIsSelected = tags.includes(camelCase(tag.get('name')))
              return (
                <Pill
                  key={tag.get('name')}
                  label={tag.get('name')}
                  color={tagIsSelected && 'primary'}
                  onToggle={
                    tagIsSelected
                      ? !isTealbot
                        ? () =>
                            removeTagFromSupplier({
                              removeTag: tag.get('name'),
                              supplierId,
                              cardId
                            })
                        : undefined
                      : () =>
                          addTagToSupplier({
                            tagsFieldName: 'offerings',
                            newTag: tag.get('name'),
                            supplierId,
                            cardId
                          })
                  }
                />
              )
            })}
        </Scrollable>
      </div>
    ) : null
  }
}

type ContainerProps = {
  supplierId: string
  cardId: string
}

export default connect(
  (state, props: ContainerProps) => {
    const cardId = searchSelectors.getCardId(state)
    const supplierId = searchSelectors.getSupplierId(state)
    const { tags, peerTags } = searchSelectors.getMyTags(
      state,
      props.supplierId || supplierId,
      props.cardId || cardId
    )

    return {
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      tags,
      peerTags
    }
  },
  {
    addTagToSupplier,
    removeTagFromSupplier
  }
)(MyTagsContainer)
