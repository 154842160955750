import React, { FunctionComponent, Fragment } from 'react'
import { Route, Switch } from 'react-router'
import CommunityForm from './containers/CommunityForm'
import CommunitiesPageContainer from './containers/CommunitiesPageContainer'
import CommunityContainer from './containers/CommunityContainer'
import paths from './routes/paths'
import CommunityHeaderContainer from './containers/CommunityHeaderContainer'
import CommunityMetricsContainer from './containers/CommunityMetricsContainer'
import CommunityBuyersContainer from './containers/CommunityBuyersContainer'

const Communities: FunctionComponent = () => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path={paths.basePath}
          component={CommunitiesPageContainer}
        />
        <Route path={paths.community} component={CommunityHeaderContainer} />
      </Switch>
      <Switch>
        <Route
          exact
          path={paths.communityBuyers}
          component={CommunityBuyersContainer}
        />
        <Route
          exact
          path={paths.communityMetrics}
          component={CommunityMetricsContainer}
        />
        <Route exact path={paths.communityForm} component={CommunityForm} />
        <Route path={paths.community} component={CommunityContainer} />
      </Switch>
    </Fragment>
  )
}

export default Communities
