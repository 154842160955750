import React, { FunctionComponent } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'

type Props = {
  handleCloseDialog: () => void
  handleUpdateStatus: () => void
  blockUser: () => void
  firstName: string
  lastName: string
  isStatusDialogOpen: boolean
  status?: string
}

const BlockUser: FunctionComponent<Props> = props => {
  const {
    handleCloseDialog,
    blockUser,
    firstName,
    lastName,
    isStatusDialogOpen,
    status,
    handleUpdateStatus
  } = props

  return (
    <div className='mt3 cf'>
      <Button
        label={
          status === 'Blocked' ? (
            <FormattedMessage
              id='BlockUser.UnblockUser'
              defaultMessage='Unblock User'
            />
          ) : (
            <FormattedMessage
              id='BlockUser.BlockUser'
              defaultMessage='Block User'
            />
          )
        }
        secondary
        onClick={handleUpdateStatus}
      />
      <Dialog open={isStatusDialogOpen} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>
          <FormattedMessage
            id='EditUserProfile.BlockTheUser'
            defaultMessage='Block the user'
          />
        </DialogTitle>
        <DialogContent className='mt3'>
          <Text>
            <FormattedMessage
              id='EditUserProfile.SureAboutBlockingUser'
              defaultMessage='Are you sure you want to block {firstName} {lastName}?'
              values={{ firstName, lastName }}
            />
          </Text>
        </DialogContent>
        <DialogActions>
          <Button
            label={
              <FormattedMessage
                id='EditUserProfile.Block'
                defaultMessage='Block'
              />
            }
            autoSize
            onClick={blockUser}
          />
          <Button
            label={<FormattedMessage id='CancelButton' />}
            autoSize
            secondary
            onClick={handleCloseDialog}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BlockUser
