export const SEARCH_FOR_ORGANIZATION = 'dataPortal/SEARCH_FOR_ORGANIZATION'
export const CHANGE_USER_EMAIL = 'dataPortal/CHANGE_USER_EMAIL'
export const EDIT_USER_EMAIL = 'dataPortal/EDIT_USER_EMAIL'
export const CONNECT_USER_TO_ORG = 'dataPortal/CONNECT_USER_TO_ORG'
export const CREATE_ORG = 'dataPortal/CREATE_ORG'
export const START_CREATING_ORG = 'dataPortal/START_CREATING_ORG'
export const STOP_CREATING_ORG = 'dataPortal/STOP_CREATING_ORG'
export const BECOME_TEALBOT = 'dataPortal/BECOME_TEALBOT'
export const MAKE_SUPPLIER_A_BUYER = 'dataPortal/MAKE_SUPPLIER_A_BUYER'
export const MOVE_SUPPLIER = 'dataPortal/MOVE_SUPPLIER'

export const START_CONNECTING_USER_TO_ORG =
  'dataPortal/START_CONNECTING_USER_TO_ORG'
export const STOP_CONNECTING_USER_TO_ORG =
  'dataPortal/STOP_CONNECTING_USER_TO_ORG'
export const SELECT_SUGGESTED_USER = 'dataPortal/SELECT_SUGGESTED_USER'
export const ADD_CONTACT = 'dataPortal/ADD_CONTACT'
export const REMOVE_CONTACT = 'dataPortal/REMOVE_CONTACT'

export const DELETE_SUPPLIER = 'dataPortal/DELETE_SUPPLIER'
export const DROP_INDEX = 'dataPortal/DROP_INDEX'
export const REINDEX_SUPPLIERS = 'dataPortal/REINDEX_SUPPLIERS'
export const REINDEX_USERS = 'dataPortal/REINDEX_USERS'
export const ADD_USER_MAPPING = 'dataPortal/ADD_USER_MAPPING'
export const BACKUP_DATASTORE = 'dataPortal/BACKUP_DATASTORE'
export const FLUSH_CACHE = 'dataPortal/FLUSH_CACHE'
export const RUN_PATCH = 'dataPortal/RUN_PATCH'
export const UPLOAD_FILE_TO_DATA_LOADER =
  'dataPortal/UPLOAD_FILE_TO_DATA_LOADER'
export const SYNC_DIVERSITY = 'dataPortal/SYNC_DIVERSITY'

export const CREATE_COMMUNITY = 'dataPortal/CREATE_COMMUNITY'
export const JOIN_COMMUNITY = 'dataPortal/JOIN_COMMUNITY'
export const REMOVE_FROM_COMMUNITY = 'dataPortal/REMOVE_FROM_COMMUNITY'
export const GET_REASON_KEY = 'dataPortal/GET_REASON_KEY'
export const CLOSE_LOGIN_DIALOG = 'dataPortal/CLOSE_LOGIN_DIALOG'

export const UPLOAD_DATA_QUALITY_FILE = 'dataPortal/UPLOAD_DATA_QUALITY_FILE'
export const GET_DATA_QUALITY_JOBS = 'dataPortal/GET_DATA_QUALITY_JOBS'
export const CONFIRM_COLUMNS_MAPPING = 'dataPortal/CONFIRM_COLUMNS_MAPPING'
export const GET_JOB_STATUS = 'dataPortal/GET_JOB_STATUS'
export const CLEAR_JOB_STATUS = 'dataPortal/CLEAR_JOB_STATUS'
