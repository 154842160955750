import React, { Component, Fragment } from 'react'
import ListItem from 'shared/components/List/ListItem'
import Avatar from 'shared/components/Avatar'
import dateFormat from 'shared/utils/dateFormat'
import classNames from 'classnames'
import incomingIcon from 'shared/assets/icons/incoming.svg'
import outgoingIcon from 'shared/assets/icons/outgoing.svg'
import BlockIcon from '@material-ui/icons/Block'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
const styles = {
  wrapper: 'flex pv2 ph2 items-center w-100 bt b--light-gray',
  selected: 'bg-near-white'
}

type Props = {
  firstName: string
  lastName: string
  email: string
  title?: string
  orgName: string
  lastMessageMine: boolean
  profilePictureUrl: string
  lastMessageDate: Date
  lastMessage: string
  numUnreadMessages: number
  selected: boolean
  blocked: boolean
}

class ConversationListItem extends Component<Props> {
  render() {
    const {
      firstName,
      lastName,
      email,
      title,
      orgName = '',
      profilePictureUrl,
      lastMessageMine,
      lastMessageDate,
      lastMessage,
      numUnreadMessages,
      blocked,
      selected
    } = this.props

    return (
      <ListItem
        className={classNames(styles.wrapper, selected && styles.selected)}
        leftAvatar={
          <div className='dib' style={{ width: '2rem' }}>
            <Avatar
              url={profilePictureUrl}
              style={{ width: '100%' }}
              name={`${firstName || ''} ${lastName || ''}`}
            />
          </div>
        }
        primaryText={
          <Fragment>
            <div className='flex items-center justify-between'>
              <div className='dib w1 mr1'>
                <Tooltip
                  title={
                    lastMessageMine ? (
                      <FormattedMessage
                        id='ConversationListItem.Outgoing'
                        defaultMessage='Outgoing message'
                      />
                    ) : (
                      <FormattedMessage
                        id='ConversationListItem.Incoming'
                        defaultMessage='Incoming message'
                      />
                    )
                  }
                >
                  <img
                    alt='direction'
                    className='v-mid w-100'
                    src={lastMessageMine ? outgoingIcon : incomingIcon}
                  />
                </Tooltip>
              </div>
              <div className='flex items-center flex-auto'>
                {firstName || lastName
                  ? `${firstName || ''} ${lastName || ''}`
                  : email}
                {blocked && (
                  <BlockIcon style={{ fontSize: '16px' }} className='ml1' />
                )}
              </div>

              {numUnreadMessages > 0 && (
                <div
                  className='w1 h1 ml1'
                  style={{
                    fontSize: 10,
                    lineHeight: '16px',
                    backgroundColor: '#ff6f6f',
                    color: 'white',
                    display: 'inline-block',
                    borderRadius: '50%',
                    textAlign: 'center'
                  }}
                >
                  {numUnreadMessages}
                </div>
              )}
            </div>
            <div className='mt1 f8 flex justify-between'>
              <div className='flex-auto'>
                {title ? (
                  <FormattedMessage
                    id='ConversationListItem.TitleOrgName'
                    defaultMessage='{title} at {orgName}'
                    values={{ title, orgName }}
                  />
                ) : (
                  <FormattedMessage
                    id='ConversationListItem.OrgName'
                    defaultMessage='at {orgName}'
                    values={{ orgName }}
                  />
                )}
              </div>
              {lastMessageDate && (
                <div className='gray'>{dateFormat(lastMessageDate)}</div>
              )}
            </div>
          </Fragment>
        }
        secondaryText={
          lastMessage && (
            <div className='bt b--light-gray pt1'>
              <div className='truncate'>{lastMessage}</div>
            </div>
          )
        }
        mini
      />
    )
  }
}

export default ConversationListItem
