import React from 'react'
import { useSelector } from 'react-redux'
import insightsSelectors from 'buyer/Insights/store/insightsSelectors'
import Loading from 'shared/components/Loading'
import { useParams } from 'react-router-dom'
import RootState from 'shared/models/RootState'
import SingleDetails from './SingleDetails'
import CompareDetails from './CompareDetails'

export type SpendItemType = {
  totalAmount: number
  qualified: 'No' | 'Yes'
  name: string
  domain: string
  internalSupplierId: string[]
} & { [key: string]: string }

type Props = {
  subCategory: string
  type?: 'qualified' | 'potential' | 'disqualified' // only use for viewing single report
}

const ReportRowDetails = ({ subCategory, type }: Props) => {
  const params = useParams()
  const isLoading = useSelector(insightsSelectors.isSnapshotsLoading)

  const filteredLineItems1: SpendItemType[] = useSelector(
    (state: RootState) =>
      params.report1 &&
      insightsSelectors.getLineItemsBySubCategoryByType(
        state,
        params.report1,
        subCategory,
        type
      )
  )
  const filteredLineItems2: SpendItemType[] = useSelector(
    (state: RootState) =>
      params.report2 &&
      insightsSelectors.getLineItemsBySubCategoryByType(
        state,
        params.report2,
        subCategory,
        type
      )
  )
  const rules1 = useSelector(
    (state: RootState) =>
      params.report1 &&
      insightsSelectors.getSavedReportRulesById(state, params.report1)
  )
  const rules2 = useSelector(
    (state: RootState) =>
      params.report2 &&
      insightsSelectors.getSavedReportRulesById(state, params.report2)
  )
  const teamExclude =
    rules1?.get('excludeRules_myTeam') === 'rejected' ||
    rules2?.get('excludeRules_myTeam') === 'rejected'

  return (
    <div className='pa4 ma3 ba b--black-10 bg-white'>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {filteredLineItems1 && !filteredLineItems2 && (
            <SingleDetails lineItems={filteredLineItems1} />
          )}
          {filteredLineItems1 && filteredLineItems2 && (
            <CompareDetails
              subCategory={subCategory}
              teamExclude={teamExclude}
              lineItems1={filteredLineItems1}
              lineItems2={filteredLineItems2}
            />
          )}
        </>
      )}
    </div>
  )
}

export default ReportRowDetails
