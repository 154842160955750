import React, { Component, ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { CardElement, injectStripe } from 'react-stripe-elements'
import Warning from 'shared/components/Warning'
import ExternalLink from 'shared/components/ExternalLink'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import countryList from 'react-select-country-list'
import Select from 'shared/components/Select'
import statesList from 'shared/utils/statesList'
import { processPayment } from 'supplier/shared/actions'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import CreditCardErrorCode from './CreditCardErrorCode'
import CreditCardErrorMessage from './CreditCardErrorMessage'

type Props = {
  processPayment: (token: string) => void
  processing: boolean
  stripe: { createToken: any }
  onCancel: () => void
  paymentError: string
  defaultCountryAbbrev: string
  defaultStateAbbrev: string
}

type State = {
  creditCardForm: {
    name: string
    address_line1: string
    address_city: string
    address_state: string
    address_country: string
    address_zip: string
  }
  countryOptions: Array<{ value: string; label: string }>
  creditCardError?: {
    message: string
    code: CreditCardErrorCode
  }
}

export class PaymentForm extends Component<Props, State> {
  state: State = {
    creditCardForm: {
      name: '',
      address_line1: '',
      address_city: '',
      address_state: this.props.defaultStateAbbrev || '',
      address_country: this.props.defaultCountryAbbrev || '',
      address_zip: ''
    },
    countryOptions: countryList().getData()
  }

  handleSubmit = async ev => {
    ev.preventDefault()
    const { processPayment, processing } = this.props

    if (processing || !!this.state.creditCardError) {
      return false
    }

    const token = await this.props.stripe.createToken({
      ...this.state.creditCardForm
    })
    //stop request to server if credit card is empty or incomplete
    if (token.error) {
      this.setState({
        creditCardError: token.error
      })
    } else {
      return processPayment(token)
    }
  }

  handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'address_country') {
      this.setState({
        creditCardForm: {
          ...this.state.creditCardForm,
          [e.target.name]: e.target.value,
          address_state: ''
        }
      })
    } else {
      this.setState({
        creditCardForm: {
          ...this.state.creditCardForm,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  handleCreditCardChange = (event: {
    error?: {
      message: string
      code: CreditCardErrorCode
    }
  }) => {
    this.setState({
      creditCardError: event.error
    })
  }

  render() {
    const { countryOptions } = this.state
    const {
      onCancel,
      paymentError,
      defaultCountryAbbrev,
      defaultStateAbbrev
    } = this.props

    const states =
      statesList[
        this.state.creditCardForm.address_country || defaultCountryAbbrev
      ]

    return (
      <form
        className='checkout'
        onSubmit={this.handleSubmit}
        autoComplete='off'
      >
        <Text className='mt2'>
          <FormattedMessage
            id='PaymemtForm.SubscribeToTealbookToGetAccess'
            defaultMessage='Subscribe to TealBook to get access to additional insight. For $680 (USD) per year, you get access to additional insight into the marketplace and your relationships to existing and potential customers.'
          />
        </Text>
        <Label>
          <FormattedMessage
            id='PaymentForm.CardholderName'
            defaultMessage='Cardholder name'
          />
        </Label>
        <Input
          name='name'
          value={this.state.creditCardForm.name}
          autoComplete='name'
          required
          onChange={this.handleInputChange}
        />
        <Label>
          <FormattedMessage
            id='PaymentForm.CreditCardInformation'
            defaultMessage='Credit card information'
          />
        </Label>
        <CardElement
          className='bg-white f6 ba b--black-10 border-box pa2 w-100 br1'
          hidePostalCode
          onChange={this.handleCreditCardChange}
        />
        {this.state.creditCardError && (
          <Warning
            className='pa1 mb-3 red'
            message={<CreditCardErrorMessage {...this.state.creditCardError} />}
          />
        )}
        <Label>
          <FormattedMessage id='PaymentForm.Address' defaultMessage='Address' />
        </Label>
        <Input
          name='address_line1'
          autoComplete='street-address'
          value={this.state.creditCardForm.address_line1}
          required
          onChange={this.handleInputChange}
        />
        <div className='dib w-100 w-50-l'>
          <div className='pr2-l'>
            <Label>
              <FormattedMessage id='PaymentForm.City' defaultMessage='City' />
            </Label>
            <Input
              name='address_city'
              autoComplete='address-level2'
              value={this.state.creditCardForm.address_city}
              required
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className='dib w-100 w-50-l'>
          <div className='pl2-l'>
            <Label>
              <FormattedMessage id='PaymentForm.State' defaultMessage='State' />
            </Label>
            {states ? (
              <Select
                fullWidth
                name='address_state'
                value={
                  this.state.creditCardForm.address_state || defaultStateAbbrev
                }
                required
                onChange={this.handleInputChange}
              >
                <FormattedMessage
                  id='SelectPaymentForm.StateProvince'
                  defaultMessage='Select State / Province'
                >
                  {message => (
                    <option key={'select-state'} value=''>
                      {message}
                    </option>
                  )}
                </FormattedMessage>
                {states &&
                  Object.keys(states).map(key => (
                    <option key={key} value={key}>
                      {states[key]}
                    </option>
                  ))}
              </Select>
            ) : (
              <Input
                name='address_state'
                autoComplete='address-level1'
                value={this.state.creditCardForm.address_state}
                required
                onChange={this.handleInputChange}
              />
            )}
          </div>
        </div>
        <div className='dib w-100 w-50-l'>
          <div className='pr2-l'>
            <Label>
              <FormattedMessage
                id='PaymentForm.Country'
                defaultMessage='Country'
              />
            </Label>
            <Select
              fullWidth
              name='address_country'
              value={
                this.state.creditCardForm.address_country ||
                defaultCountryAbbrev
              }
              required
              onChange={this.handleInputChange}
            >
              {countryOptions.map(country => (
                <option key={country.value} value={country.value}>
                  {country.label}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div className='dib w-100 w-50-l'>
          <div className='pl2-l'>
            <Label>
              <FormattedMessage id='PaymentForm.Zip' defaultMessage='Zip' />
            </Label>
            <Input
              name='address_zip'
              autoComplete='postal-code'
              value={this.state.creditCardForm.address_zip}
              required
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        {paymentError && <Warning className='mt2 red' message={paymentError} />}
        <Text className='mt3'>
          <FormattedMessage
            id='PaymentForm.BySubscribing '
            defaultMessage="By subscribing, you agree to TealBook's "
          />
          <ExternalLink href='https://tealbook.com/premium/'>
            <FormattedMessage
              id='PaymentForm.TermConditions'
              defaultMessage='Terms and Conditions'
            />
          </ExternalLink>
          <FormattedMessage
            id='PaymentForm.CardWillBeCharged'
            defaultMessage='. Your credit card will be charged $680 (USD). This subscription will automatically renew unless you cancel prior to the renewal date.'
          />
        </Text>
        <div className='mt3'>
          <Button
            autoSize
            type='submit'
            label={
              <FormattedMessage
                id='PaymentForm.Subscribe'
                defaultMessage='Subscribe'
              />
            }
            className='mr3'
          />
          <Button
            autoSize
            secondary
            label={<FormattedMessage id='CancelButton' />}
            onClick={onCancel}
          />
        </div>
      </form>
    )
  }
}

export default injectStripe(
  connect(
    (state: RootState) => ({
      processing: state.getIn(['supplier', 'company', 'paymentProcessing']),
      paymentError: state.getIn(['supplier', 'company', 'paymentError'])
    }),
    { processPayment }
  )(PaymentForm)
)
