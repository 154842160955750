import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import community from 'shared/utils/api/community'
import communityParser from 'shared/utils/data/community/parser'

export function* updateCommunityBuyerSaga(action) {
  const { communityId, ...communityBuyer } = action.payload
  const response = yield call(community.addClient, {
    communityId,
    ...communityBuyer
  })
  return yield {
    byId: {
      [response.id]: communityParser(response)
    }
  }
}

export default createApiRequestSaga(updateCommunityBuyerSaga)
