import { connect } from 'react-redux'
import CommunityCard from '../../components/CommunityCard'
import communitiesSelectors from 'supplier/Communities/selectors/communitiesSelectors'

type Props = {
  communityId: string
}

export default connect((state, props: Props) => {
  const community = communitiesSelectors.getById(state, props.communityId)
  const membership = communitiesSelectors.getMembershipByCommunityId(
    state,
    props.communityId
  )
  const buyers = community.get('buyers')
  return {
    logoUrl: community.get('logoUrl'),
    name: community.get('name'),
    description: community.get('description'),
    isPrivate: community.get('isPrivate'),
    supplierSearchable: community.get('supplierSearchable'),
    numberBuyers: buyers && buyers.size,
    hasForm: !!community.get('form'),
    hasCompletedForm: membership.getIn(['response', 'status']) === 'submitted',
    comPlanMembershipId: membership.get('id')
  }
})(CommunityCard)
