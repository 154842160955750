import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'

function* downVoteSaga(action) {
  const vetBuyerSupplierId = action.payload
  const voteResults = yield call(vets.downVoteVetSupplier, {
    vetBuyerSupplierId
  })

  return yield { vetBuyerSupplierId, voteResults }
}

export default createApiRequestSaga(downVoteSaga)
