import React, { useEffect, useState, useMemo, createContext } from 'react'
import { openJob, navToNext } from '../../actions'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import jobsSelectors from '../../selectors/jobsSelectors'
import RootState from 'shared/models/RootState'
import JobOrgUnitCard from '../JobOrgUnitCard'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import Loading from 'shared/components/Loading'
import paths from '../../routes'
import Link from 'shared/components/Link'
import { updateJobAnswers } from '../../actions'
import { List } from 'immutable'

export const JobAnswersContext = createContext<{
  answers: any
  handleChange: (questionId, value) => void
}>({
  answers: {},
  handleChange: (questionId, value) => undefined
})

const JobDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{
    jobId: string
    row: string
  }>()

  const jobId = useMemo(() => {
    return params.jobId
  }, [params.jobId])

  const rowNum = useMemo(() => {
    return !isNaN(Number(params.row)) ? Number(params.row) : undefined
  }, [params.row])

  const job = useSelector((state: RootState) =>
    jobsSelectors.getJobById(state, jobId)
  )
  const questions = useSelector((state: RootState) =>
    state.getIn(['jobs', 'jobQuestions'])
  )
  const rows = useSelector((state: RootState) =>
    state.getIn(['jobs', 'jobRows'])
  )

  const numRows = rows?.size
  const rowsAnswered = useSelector(jobsSelectors.getRowsAnswered)
  const loadingJob = useSelector((state: RootState) =>
    state.getIn(['jobs', 'loadingJob'])
  )
  const submittingAnswer = useSelector((state: RootState) =>
    state.getIn(['jobs', 'submittingAnswer'])
  )

  const initialAnswers = useMemo(() => {
    const classificationCodes = rows?.getIn([rowNum, 'classificationCodes'])
    const rowQuestions =
      job?.get('type') === 'oneToOne'
        ? questions.get(rowNum)
          ? List([questions.get(rowNum)])
          : List([])
        : questions
    const naicsIndex = rowQuestions?.findIndex(
      q => q.get('answerType') === 'NAICS'
    )
    const naicsId =
      naicsIndex !== -1 && rowQuestions?.getIn([naicsIndex, 'questionId'])
    const naics =
      naicsIndex !== -1
        ? classificationCodes?.reduce((reduction, code) => {
            reduction[code.get('code')] = 'keep'
            return reduction
          }, {})
        : undefined

    return (
      naicsId && {
        [naicsId]: naics
      }
    )
  }, [questions, job, rows, rowNum])

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [answers, setAnswers] = useState<any>(initialAnswers)

  const validationRulesSelector = jobsSelectors.createValidationRulesSelector(
    jobId,
    rowNum
  )
  const validationRules = useSelector(validationRulesSelector)
  const isValid = useMemo(() => {
    return (
      !validationRules.required.length ||
      validationRules.required.every(questionId => !!answers[questionId])
    )
  }, [answers, validationRules])

  const instructions = useMemo(() => {
    return job?.get('instructions')
  }, [job])

  useEffect(() => {
    if (!loadingJob && !numRows) {
      dispatch(openJob({ jobId, rowNum }))
    }
  }, [dispatch, loadingJob, numRows, jobId, rowNum])

  useEffect(() => {
    if (initialAnswers) {
      setAnswers(initialAnswers)
    }
  }, [initialAnswers])

  useEffect(() => {
    if (instructions) {
      setIsDialogOpen(true)
    }
  }, [instructions])

  const handleNextProfile = () => {
    dispatch(navToNext({ jobId, rowNum }))
  }

  const handleChange = (questionId: string, value) => {
    setAnswers(Object.assign({}, answers, { [questionId]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (isValid) {
      dispatch(
        updateJobAnswers({
          jobId,
          rowNum,
          ...answers
        })
      )
    }
  }

  return loadingJob ? (
    <Loading />
  ) : params.row === '-1' ? (
    <div className='bg-white ba br b--black-10 pa2 pa3-ns mt3-5'>
      <Text>
        All answers have been submitted.{' '}
        <Link to={paths.jobs}>Back to List.</Link>
      </Text>
    </div>
  ) : (
    <>
      <form onSubmit={handleSubmit}>
        <div
          className='flex justify-between items-center pv2 pv3-ns bg-near-white bb b--black-10'
          style={{ position: 'sticky', top: 50, zIndex: 10 }}
        >
          <h5 className='f5 fw4 ma0'>
            Done: {rowsAnswered.size}/{numRows}
          </h5>
          <div>
            <Button
              autoSize
              size='small'
              type='submit'
              disabled={!isValid || submittingAnswer}
            >
              {job?.get('type') !== 'oneToOne' ? 'Confirm' : 'Next'}
            </Button>
            {job?.get('type') !== 'oneToOne' &&
              numRows - rowsAnswered.size > 1 && (
                <Button
                  autoSize
                  size='small'
                  className='ml2'
                  secondary
                  onClick={handleNextProfile}
                >
                  Skip
                </Button>
              )}
            <Button
              autoSize
              size='small'
              className='ml2'
              secondary
              onClick={() => history.push(paths.jobs)}
            >
              Back to List
            </Button>
          </div>
        </div>
        <JobAnswersContext.Provider
          value={{
            answers,
            handleChange
          }}
        >
          <JobOrgUnitCard rowNum={Number(rowNum)} jobId={jobId} />
        </JobAnswersContext.Provider>
      </form>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>Instruction</DialogTitle>
        <DialogContent>
          <Text>{job?.get('instructions')}</Text>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default JobDetails
