import React, { Component } from 'react'
import { connect } from 'react-redux'
import { markPostRead } from '../../actions'
import postsSelectors from '../../selectors/postsSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import PostItem from '../../components/PostItem'
import PostItemActions from '../../components/PostItemActions'
import withPost from '../withPost'
import withPostActions from '../withPostActions'
import Loading from 'shared/components/Loading'
import { List } from 'immutable'
import RootState from 'shared/models/RootState'
import throttle from 'lodash.throttle'

const PostContainer = withPost(PostItem)
const PostActionsContainer = withPostActions(PostItemActions)
const POST_INCREMENT_VALUE = 10

type Props = {
  markPostRead: (arg: string) => void
  posts: List<string>
  isLoading: boolean
  isClientApp: boolean
}

type State = {
  markRead: { [key: string]: boolean }
  numRenderedPosts: number
}

export class PostsListContainer extends Component<Props, State> {
  state: State = {
    markRead: {},
    numRenderedPosts: POST_INCREMENT_VALUE
  }

  componentDidMount() {
    window.addEventListener('scroll', this.throttleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttleScroll)
  }

  handleScroll = () => {
    const { numRenderedPosts } = this.state
    const { posts } = this.props
    if (
      numRenderedPosts < posts.size &&
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500
    ) {
      this.setState({
        numRenderedPosts:
          numRenderedPosts + POST_INCREMENT_VALUE < posts.size
            ? numRenderedPosts + POST_INCREMENT_VALUE
            : posts.size
      })
    }
  }

  throttleScroll = throttle(this.handleScroll, 200)

  handlePostRead = postId => {
    const { markPostRead } = this.props
    const { markRead } = this.state

    if (!markRead[postId]) {
      markPostRead(postId)
      this.setState({
        markRead: { ...markRead, [postId]: true }
      })
    }
  }

  render() {
    const { posts, isLoading, isClientApp } = this.props
    const { markRead, numRenderedPosts } = this.state

    return (
      <div className='mt3'>
        {isLoading && <Loading />}

        {!isLoading &&
          posts &&
          posts.take(numRenderedPosts).map(postId => {
            return (
              <div key={postId}>
                <PostContainer
                  postId={postId}
                  onPostRead={this.handlePostRead}
                  isClientApp={isClientApp}
                />
                <PostActionsContainer
                  postId={postId}
                  hasRead={markRead[postId]}
                  onPostRead={this.handlePostRead}
                />
              </div>
            )
          })}
      </div>
    )
  }
}

export default connect(
  (state: RootState) => ({
    isLoading: postsSelectors.isLoading(state),
    posts: postsSelectors.getPostIdsSortByDate(state),
    isClientApp: routingSelectors.isClientApp(state)
  }),
  { markPostRead }
)(PostsListContainer)
