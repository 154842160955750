import React, { Component, Fragment, ReactNode } from 'react'
import ListItem from 'shared/components/List/ListItem'
import Link from 'shared/components/Link'
import ExternalLink from 'shared/components/ExternalLink'
import Text from 'shared/components/Text'
import OrgLogo from 'shared/components/OrgLogo'
import buyerPaths from 'buyer/routes/paths'
import startCase from 'lodash.startcase'
import { List } from 'immutable'

type Props = {
  id: string
  name: string
  logoUrl: string
  domains: Array<string>
  rightButton: ReactNode
  publicProfile?: boolean
  hasConcierge?: boolean
  categorization?: string
  isClient?: boolean
  fixedFilters?: any
  defaultFilters?: any
  showConcierge?: string
  clientOf?: string
  vetApproval?: boolean
  partners?: List<string>
}

class OrgSearchResultItem extends Component<Props> {
  render() {
    const {
      id,
      name,
      logoUrl,
      domains,
      rightButton,
      publicProfile,
      hasConcierge,
      showConcierge,
      categorization,
      fixedFilters,
      defaultFilters,
      clientOf,
      isClient,
      vetApproval,
      partners
    } = this.props

    return (
      <ListItem
        leftAvatar={
          <Link to={buyerPaths.supplierProfile} params={{ supplierId: id }}>
            <OrgLogo url={logoUrl} name={name} />
          </Link>
        }
        primaryText={
          <Fragment>
            <Link to={buyerPaths.supplierProfile} params={{ supplierId: id }}>
              {name}
              {publicProfile && ' (public)'}
              {hasConcierge && ' (vet tasks)'}
              {showConcierge &&
                showConcierge !== 'yes' &&
                ` (concierge ${showConcierge})`}
              {vetApproval && ' (vet approval)'}
              {clientOf && clientOf === 'tealbook' && ' (clientOf)'}
              {fixedFilters && fixedFilters.size > 0 && ' (fixed filters)'}
            </Link>
            {categorization && (
              <Text secondary>Categorization as {categorization}</Text>
            )}
            {!categorization && isClient && (
              <div className='red f7 fw4'>Categorization Missing</div>
            )}
          </Fragment>
        }
        secondaryText={
          <Fragment>
            <div>
              {domains &&
                domains.map(domain => (
                  <ExternalLink
                    key={domain}
                    href={domain}
                    className='mid-gray f7 fw3 dim no-underline pointer hover-teal pr3 dib'
                  >
                    {domain}
                  </ExternalLink>
                ))}
            </div>
            {fixedFilters && fixedFilters.size > 0 && (
              <div className='mt2 f7'>
                <b className='mid-gray'>Fixed Filters: </b>
                {fixedFilters
                  .map((filter, index) => {
                    return filter
                      .map((v, k) => {
                        return (
                          <div key={`${index}-${v}-${k}`}>
                            {startCase(k)}: {v.join(',')}
                          </div>
                        )
                      })
                      .toArray()
                  })
                  .toArray()}
              </div>
            )}
            {defaultFilters && defaultFilters.size > 0 && (
              <div className='mt2 f7'>
                <b className='mid-gray'>Default Filters: </b>
                {defaultFilters
                  .map((filter, index) => {
                    return filter
                      .map((v, k) => {
                        return (
                          <div key={`${index}-${v}-${k}`}>
                            {startCase(k)}: {v.join(',')}
                          </div>
                        )
                      })
                      .toArray()
                  })
                  .toArray()}
              </div>
            )}
            {partners && partners.size > 0 && (
              <div className='mt2 f7'>
                <b className='mid-gray'>Partners: </b>
                {partners.join(', ')}
              </div>
            )}
          </Fragment>
        }
        rightButton={rightButton}
      />
    )
  }
}

export default OrgSearchResultItem
