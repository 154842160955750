import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'

export function* removeResponseFileSaga(action) {
  yield call(vets.removeResponseFile, action.payload)
  return yield action.payload
}

export default createApiRequestSaga(removeResponseFileSaga)
