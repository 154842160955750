import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import community from 'shared/utils/api/community'

export function* leaveCommunitySaga(action) {
  const { comPlanMembershipId } = action.payload
  yield call(community.leaveCommunity, comPlanMembershipId)
  return yield action.payload
}

export default createApiRequestSaga(leaveCommunitySaga)
