import { fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { requestFailure, requestSuccess, LOGOUT } from 'shared/actionTypes'

const surveyReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case requestSuccess(actionTypes.LOAD_SUPPLIER_PROFILE):
      return state.set('surveyResponse', fromJS(action.payload.surveyResponse))

    case requestSuccess(actionTypes.LOAD_SURVEY_RESPONSE):
      return state.set('surveyResponseDetailByKey', fromJS(action.payload))

    case actionTypes.OPEN_ESG_REQUEST_DIALOG:
      return state.set('requestESGSurvey', true)

    case actionTypes.CLOSE_ESG_REQUEST_DIALOG:
      return state.set('requestESGSurvey', false)

    case requestSuccess(actionTypes.LOAD_ESG_REQUEST_DETAILS):
      return state.set('esgRequestDetailByKey', fromJS(action.payload))

    case actionTypes.OPEN_VERIFY_ESG_ATTACHMENT_DIALOG:
      return state.set('openVerifyAttachmentDialog', action.payload)

    case requestFailure(actionTypes.VERIFY_ESG_ATTACHMENT):
    case actionTypes.CLOSE_VERIFY_ESG_ATTACHMENT_DIALOG:
      return state.set('openVerifyAttachmentDialog', '')

    case requestSuccess(actionTypes.VERIFY_ESG_ATTACHMENT):
      const { pageId, questionId, parentQuestionId } = action.payload
      return state
        .set('openVerifyAttachmentDialog', '')
        .setIn(
          [
            'surveyResponseDetailByKey',
            `${pageId || ''}-${parentQuestionId || ''}-${questionId || ''}`
          ],
          fromJS(action.payload)
        )

    case LOGOUT:
      return fromJS({})

    default:
      return state
  }
}

export default surveyReducer
