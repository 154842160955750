import React, { Component } from 'react'
import { connect } from 'react-redux'
import VetsList from 'shared/components/VetsList'
import PageSideSection from 'shared/components/PageSideSection'
import vetBuyerSuppliersSelectors from '../../selectors/vetBuyerSuppliersSelectors'
import Scrollable from 'shared/components/Scrollable'
import { FormattedMessage } from 'react-intl'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { RecordOf, List } from 'immutable'

type Props = {
  vets: List<
    RecordOf<{
      date: string
      id: string
      lead: string
      name: string
      status: string
    }>
  >
  currentUserId: string
  onlyMine?: boolean
}

export class SupplierVetsContainer extends Component<Props> {
  render() {
    const { vets, currentUserId, onlyMine } = this.props

    // filter for current user vets, in order to display on Personal Page
    const filteredVets = onlyMine
      ? vets.filter(vet => vet.get('lead') === currentUserId)
      : vets

    return (
      <PageSideSection
        title={
          onlyMine ? (
            <FormattedMessage
              id='SupplierVetsContainer.MyRFI'
              defaultMessage="My current RFI's"
            />
          ) : (
            <FormattedMessage
              id='SupplierVetsContainer.CurrentRFI'
              defaultMessage='Current RFIs'
            />
          )
        }
      >
        <Scrollable>
          {filteredVets.size > 0 ? (
            <VetsList vets={filteredVets} />
          ) : (
            <h5 className='f7 fw6 mid-gray mt2 ma0'>
              <FormattedMessage
                id='SupplierVetsContainer.NoRFIAvailable'
                defaultMessage='No RFI available'
              />
            </h5>
          )}
        </Scrollable>
      </PageSideSection>
    )
  }
}

export const mapStateToProps = state => ({
  vets: vetBuyerSuppliersSelectors.getVetBuyerSuppliersInList(state),
  currentUserId: sessionSelectors.getUserId(state)
})

export default connect(mapStateToProps)(SupplierVetsContainer)
