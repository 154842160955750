import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import communities from 'shared/utils/api/community'

export function* viewCommunityFormPageSaga(action) {
  const { membershipId, pageId, userId } = action.payload

  yield call(communities.updateMembership, {
    membershipId,
    membership: {
      response: {
        pagesViewed: {
          [pageId]: {
            viewed: true,
            page: pageId
          }
        }
      }
    }
  })

  return {
    membershipId,
    pageId,
    userId
  }
}

export default createApiRequestSaga(viewCommunityFormPageSaga)
