import { put, call, select, delay } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import suppliers from 'shared/utils/api/suppliers'
import searchForOrgSaga from '../searchForOrgSaga'
import { fromJS } from 'immutable'
import { SEARCH_FOR_ORGANIZATION } from '../../actionTypes'
import { notify } from 'shared/actions'

export function* deleteSupplierSaga(action) {
  const response = yield call(suppliers.deleteSupplier, action.payload)
  if (!response.status) {
    yield put(
      notify({
        message: response.message
      })
    )
  }
  yield delay(500)
  const q = yield select(state => state.getIn(['dataPortal', 'orgs', 'q']))
  return yield call(searchForOrgSaga, {
    type: SEARCH_FOR_ORGANIZATION,
    payload: fromJS({ q })
  })
}

export default createApiRequestSaga(deleteSupplierSaga, {
  notifyOnError: true
})
