import React, { Component } from 'react'
import { connect } from 'react-redux'
import { suggestOrgUnit } from 'shared/actions'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import Label from 'shared/components/Label'
import {
  addCustomer,
  openNewCustomerDialog,
  closeGetVerifiedDialog
} from '../../actions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import AddNewCustomerForm from '../AddNewCustomerForm'
import ClientListContainer from './ClientListContainer'
import { FormattedMessage } from 'react-intl'
import GetVerifiedDialog from '../../../shared/components/GetVerifiedDialog'
import RootState from 'shared/models/RootState'
import { List } from 'immutable'
import LookupSuppliers from 'shared/components/LookupSuppliers'

type Props = {
  addCustomer: ({ orgUnitId: string }) => void
  suggestOrgUnit: (eTargetValue: string) => void
  suggestedCustomers: List<string>
  suggestQuery: string
  loadingSuggestions: boolean
  newCustomerDialogIsOpen: boolean
  openNewCustomerDialog: () => void
  verifyingOrgUnitId: string
  closeGetVerifiedDialog: () => void
}

export class OurCustomersSectionContainer extends Component<Props> {
  state = {
    companyName: '',
    orgUnitIdState: '',
    openInviteDialog: false
  }

  handleClickSuggestedOrgUnitId = orgUnitId => {
    this.props.addCustomer({
      orgUnitId
    })

    this.setState({ companyName: '' })
  }

  handleCompanyNameChange = companyName => {
    this.setState({
      companyName
    })

    if (companyName.length > 2) {
      this.props.suggestOrgUnit(companyName)
    }
  }

  handleRequestClose = () => {
    this.props.suggestOrgUnit('')
    this.setState({ companyName: '' })
  }

  render() {
    const {
      suggestedCustomers,
      suggestQuery,
      loadingSuggestions,
      newCustomerDialogIsOpen,
      openNewCustomerDialog,
      verifyingOrgUnitId,
      closeGetVerifiedDialog
    } = this.props

    const { companyName } = this.state

    const open = !!companyName && !newCustomerDialogIsOpen && !!suggestQuery
    return (
      <>
        <ClientListContainer />

        <div className='bg-near-white ph3-5 pb3-5'>
          <Label>
            <FormattedMessage
              id='OurCustomersSectionContainer.AddACustomer'
              defaultMessage='Add a Customer'
            />
          </Label>
          <FormattedMessage
            id='OurCustomersSectionContainer.AddCompanyName'
            defaultMessage='Add Company Name'
          >
            {message => (
              <LookupSuppliers
                placeholder={message}
                open={open}
                loadingSuggestions={loadingSuggestions}
                suggestedCustomers={suggestedCustomers}
                suggestQuery={suggestQuery}
                openNewCustomerDialog={openNewCustomerDialog}
                onClose={this.handleRequestClose}
                onHandleClickSuggestedOrgUnitId={
                  this.handleClickSuggestedOrgUnitId
                }
                onClickMenuItem={() => this.setState({ companyName: '' })}
                companyName={companyName}
                onChangeCompanyName={this.handleCompanyNameChange}
              />
            )}
          </FormattedMessage>
        </div>
        <Dialog open={newCustomerDialogIsOpen} fullWidth disableBackdropClick>
          <DialogTitle>
            <FormattedMessage
              id='OurCustomersSectionContainer.AddAsCustomer'
              defaultMessage='Add {suggestQuery} as a customer'
              values={{ suggestQuery }}
            />
          </DialogTitle>
          <DialogContent>
            <AddNewCustomerForm
              addingCustomer
              initialValues={{
                companyName: suggestQuery
              }}
            />
          </DialogContent>
        </Dialog>

        <GetVerifiedDialog
          open={!!verifyingOrgUnitId}
          onClose={closeGetVerifiedDialog}
        />
      </>
    )
  }
}

export default connect(
  (state: RootState, props: any) => {
    return {
      newCustomerDialogIsOpen: orgsSelectors.newCustomerDialogIsOpen(state),
      loadingSuggestions: orgsSelectors.loadingSuggestions(state),
      suggestQuery: orgsSelectors.getSuggestQuery(state),
      suggestedCustomers: orgsSelectors.getSuggestedIds(state),
      verifyingOrgUnitId: state.getIn([
        'supplier',
        'company',
        'verifyingOrgUnitId'
      ])
    }
  },
  {
    addCustomer,
    suggestOrgUnit,
    openNewCustomerDialog,
    closeGetVerifiedDialog
  }
)(OurCustomersSectionContainer)
