import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import AutoSaveInput from 'shared/components/AutoSaveInput'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import companySelectors from '../../selectors/companySelectors'
import { saveCompanyInfo } from '../../../Company/actions'
import {
  validateFacebook,
  validateLinkedIn,
  validateTwitter
} from 'shared/utils/data/validate'
import RootState from 'shared/models/RootState'

type Props = {
  saveCompanyInfo: () => void
  description: string
  linkedInUrl: string
  facebookUrl: string
  twitterId: string
}

export class DescriptionForm extends Component<Props> {
  render() {
    const {
      saveCompanyInfo,
      description,
      linkedInUrl,
      facebookUrl,
      twitterId
    } = this.props

    return (
      <Fragment>
        <Label>Basic Description</Label>
        <AutoSaveInput
          name='description'
          value={description}
          component={TextArea}
          maxLength={200}
          onSave={saveCompanyInfo}
        />
        <Label>Linkedin</Label>
        <AutoSaveInput
          name='supplier'
          value={linkedInUrl}
          validate={[validateLinkedIn]}
          normalize={v => ({ links: { linkedInUrl: v } })}
          component={Input}
          onSave={saveCompanyInfo}
        />
        <Label>Facebook</Label>
        <AutoSaveInput
          name='supplier'
          value={facebookUrl}
          validate={[validateFacebook]}
          normalize={v => ({ links: { facebookUrl: v } })}
          component={Input}
          onSave={saveCompanyInfo}
        />
        <Label>Twitter</Label>
        <AutoSaveInput
          name='supplier'
          value={twitterId}
          validate={[validateTwitter]}
          normalize={v => ({ links: { twitterId: v } })}
          component={Input}
          onSave={saveCompanyInfo}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state: RootState) => {
    const headerInfo = companySelectors.getCompanyHeaderInfo(state)
    return {
      description: headerInfo.description,
      linkedInUrl: headerInfo.linkedInUrl,
      facebookUrl: headerInfo.facebookUrl,
      twitterId: headerInfo.twitterId
    }
  },
  { saveCompanyInfo }
)(DescriptionForm)
