import { call, select } from 'redux-saga/effects'
import cards from 'shared/utils/api/cards'
import createApiRequest from 'shared/utils/sagas/createApiRequestSaga'
import profileSelectors from '../../selectors/profileSelectors'
import Immutable from 'immutable'

function* updateCardSaga(action) {
  const { userId, id, ...cardChanges } = Immutable.Iterable.isIterable(
    action.payload
  )
    ? action.payload.toJS()
    : action.payload

  // create a card if is not there yet or card end with -1
  let newCard
  if (!id || id.endsWith('-1')) {
    // get current supplier
    const orgUnitId = yield select(profileSelectors.getDataField, 'id')

    // if a user id was included it means we are creating a card for a colleague
    if (userId) {
      newCard = yield call(cards.createForColleague, {
        userId,
        orgUnitId,
        ...cardChanges
      })
    } else {
      newCard = yield call(cards.create, {
        supplier: orgUnitId,
        ...cardChanges
      })
    }
  } else {
    yield call(cards.update, id, cardChanges)
  }

  return yield {
    cardId: id,
    cardChanges,
    newCard
  }
}

export default createApiRequest(updateCardSaga)
