import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'
import Immutable from 'immutable'

export function* removeInternalCommentSaga(action) {
  const {
    index,
    vetBuyerSupplierId,
    ...noteObj
  } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  yield call(vets.removeBuyerSupplierNotes, { vetBuyerSupplierId, ...noteObj })
  return yield { index, vetBuyerSupplierId }
}

export default createApiRequestSaga(removeInternalCommentSaga)
