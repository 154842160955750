import React, { Component, Fragment, ReactNode } from 'react'
import { List } from 'immutable'
import Input from 'shared/components/Input'
import Pill from 'shared/components/Pill'
import Text from 'shared/components/Text'
import CloseIcon from '@material-ui/icons/Clear'
import { FormattedMessage } from 'react-intl'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import storageManager from 'shared/utils/storageManager'

const styles = {
  wrapper: 'pt3-5',
  editForm: 'w-100 dt',
  title: 'f7 fw6 mid-gray ma0 mb2',
  editContentWrapper: 'dtc mt2',
  editButtonWrapper: 'tr pa0 border-box dtc v-top w-20-l w-30-m w-40',
  addForm: 'bg-near-white pa3 mt2'
}

type Props = {
  title: string | ReactNode
  addButtonLabel: string | ReactNode
  addLabel: string | ReactNode
  editMode: boolean
  addMode: boolean
  onEdit: () => void
  onCancelEdit: () => void
  onSaveEdit: () => void
  onAdd: () => void
  onCancelAdd: () => void
  onSaveAdd: () => void
  tags: List<string>
  input: { value: List<string>; onChange: (value: List<string>) => void }
}

class TagsInput extends Component<Props> {
  static defaultProps = {
    input: {
      value: List([]),
      onChange: () => {}
    },
    addButtonLabel: 'Add new'
  }

  state = {
    newTag: '',
    showHint: false,
    doNotShow: !!storageManager.getItem('doNotShowAddTagHint')
  }

  tagExists(tag, tags) {
    const tagText = tag.toLowerCase()
    return tags && tags.some(t => t.toLowerCase() === tagText)
  }

  handleDelete = i => {
    const {
      input: { value, onChange }
    } = this.props
    onChange(value.delete(i))
  }

  handleAdd = () => {
    const {
      input: { value, onChange }
    } = this.props
    const { newTag } = this.state

    if (newTag) {
      if (value) {
        if (!this.tagExists(newTag, value)) {
          onChange(value.push(newTag))
        }
      } else {
        onChange(List([newTag]))
      }
    }

    this.setState({
      newTag: ''
    })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.setState({ showHint: false })
      this.handleAdd()
    }
  }

  handleNewTagChange = e => {
    this.setState({
      newTag: e.target.value,
      showHint: e.target.value !== ''
    })
  }

  handleDoNotShow = () => {
    storageManager.setItem('doNotShowAddTagHint', true)
    this.setState({ doNotShow: true })
  }

  render() {
    const { newTag, showHint, doNotShow } = this.state
    const {
      input: { value },
      title,
      addButtonLabel
    } = this.props

    return (
      <Fragment>
        <div className={styles.editForm}>
          <div className={styles.editContentWrapper}>
            {title && <h3 className={styles.title}>{title}</h3>}
            {value &&
              value.map((tag, index) => (
                <Pill
                  key={index}
                  label={tag}
                  onRequestDelete={() => this.handleDelete(index)}
                />
              ))}
          </div>
        </div>

        <div className='border-box'>
          <div className={styles.addForm}>
            {addButtonLabel && <Text>{addButtonLabel}</Text>}
            <Input
              onKeyPress={this.handleKeyPress}
              onChange={this.handleNewTagChange}
              value={newTag}
            />
            {!doNotShow && showHint && (
              <Text className='red mt2 flex items-center'>
                <img
                  src={TealbotCircleImage}
                  alt='tealbot-tip'
                  className='mr3 w2 h2'
                />
                <FormattedMessage
                  id='TagsInput.PressEnterToAddNewTag'
                  defaultMessage='Press enter to add new tag.'
                />
                <span
                  className='ml1 dim teal pointer bg-light-gray ph1 br2'
                  onClick={this.handleDoNotShow}
                >
                  <CloseIcon style={{ width: 10, height: 10 }} />
                  <FormattedMessage
                    id='TagsInput.DoNotShowAgain'
                    defaultMessage='Do not show again'
                  />
                </span>
              </Text>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default TagsInput
