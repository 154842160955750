import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Page from 'shared/components/Page'
import Loading from 'shared/components/Loading'
import Tooltip from 'shared/components/Tooltip'
import PostingStatContainer from '../PostingStatContainer'
import SupplierStatContainer from '../SupplierStatContainer'
import TagCloudWidget from '../../components/TagCloudWidget'
import LeaderboardOrgUnitWidget from '../../components/LeaderboardOrgUnitWidget'
import { FormattedMessage } from 'react-intl'
import paths from '../../../SupplierProfile/routes'
import parsePath from 'shared/utils/parsePath'
import { RouteComponentProps } from 'react-router'
import { List, RecordOf, Map } from 'immutable'
import RootState from 'shared/models/RootState'
import ScrollToPosition from 'shared/components/ScrollToPosition'

const styles = {
  triangleDown: {
    width: 0,
    marginRight: 8,
    verticalAlign: 'middle',
    display: 'inline-block',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: '11px solid #ff333a'
  },
  triangleUp: {
    width: 0,
    marginRight: 8,
    display: 'inline-block',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: '11px solid #093'
  }
}
type OrgUnit = {
  id: string
  profilePictureUrl: string
  profileUrl: string
  name: string
  categorization: string
  totalVets: string
  totalSearches: string
  totalConnections: string
  viewsLastMonth: number
  viewsThisMonth: number
  views: number
}

type Props = {
  isLoading: boolean
  communityId: string
  activeBuyers?: List<RecordOf<OrgUnit>>
  activeSuppliers: List<RecordOf<OrgUnit>>
  commonTags: Map<string, number>
  commonSearchTerms: Map<string, number>
} & RouteComponentProps

export class CommunityMetricsContainer extends Component<Props> {
  render() {
    const {
      isLoading,
      communityId,
      activeBuyers,
      activeSuppliers,
      commonTags,
      commonSearchTerms,
      history: { push }
    } = this.props

    return isLoading ? (
      <Loading />
    ) : (
      <Page title={'Metrics'}>
        <ScrollToPosition to={220} strict='up' />
        <div className='flex-wrap'>
          <div className='w-50-ns w-100 dib ph2 v-top'>
            <SupplierStatContainer communityId={communityId} push={push} />
          </div>
          <div className='w-50-ns w-100 dib ph2 v-top'>
            <PostingStatContainer communityId={communityId} />
          </div>
          <div className='w-50-ns w-100 dib ph2 v-top'>
            <LeaderboardOrgUnitWidget
              title={
                <FormattedMessage
                  id='CommunityMetricsContainer.ActiveBuyers'
                  defaultMessage='Most Active Buyers'
                />
              }
              noData={
                <FormattedMessage
                  id='CommunityMetricsContainer.NoBuyer'
                  defaultMessage='No Buyer in the Community'
                />
              }
              orgUnits={activeBuyers}
              secondaryTextRenderer={buyer => (
                <FormattedMessage
                  id='CommunityMetricsContainer.BuyerStatLine'
                  defaultMessage='{numberOfVets} RFIs, {numberOfConnections} Connections, {numberOfSearches} Searches'
                  values={{
                    numberOfVets: buyer.get('totalVets')
                      ? buyer.get('totalVets').toLocaleString()
                      : 0,
                    numberOfSearches: buyer.get('totalSearches')
                      ? buyer.get('totalSearches').toLocaleString()
                      : 0,
                    numberOfConnections: buyer.get('totalConnections')
                      ? buyer.get('totalConnections').toLocaleString()
                      : 0
                  }}
                />
              )}
            />
          </div>
          <div className='w-50-ns w-100 dib ph2 v-top'>
            <LeaderboardOrgUnitWidget
              title={
                <FormattedMessage
                  id='CommunityMetricsContainer.ActiveSuppliers'
                  defaultMessage='Top 10 Trending Suppliers'
                />
              }
              noData={
                <FormattedMessage
                  id='CommunityMetricsContainer.NoSupplier'
                  defaultMessage='No Supplier in the Community'
                />
              }
              orgUnits={
                activeSuppliers &&
                activeSuppliers.map(supplier =>
                  supplier.set(
                    'profileUrl',
                    parsePath(paths.basePath, {
                      supplierId: supplier.get('id')
                    })
                  )
                )
              }
              secondaryTextRenderer={supplier => (
                <Fragment>
                  <Tooltip
                    title={
                      <FormattedMessage
                        id='CommunityMetricsContainer.ViewsLasMonth'
                        defaultMessage='{viewsLastMonth} views last month'
                        values={{
                          viewsLastMonth: supplier.get('viewsLastMonth')
                            ? supplier.get('viewsLastMonth').toLocaleString()
                            : 0
                        }}
                      />
                    }
                  >
                    <span
                      style={
                        supplier.get('viewsThisMonth', 0) >
                        supplier.get('viewsLastMonth', 0)
                          ? styles.triangleUp
                          : styles.triangleDown
                      }
                    />
                  </Tooltip>
                  <FormattedMessage
                    id='CommunityMetricsContainer.Views'
                    defaultMessage='{viewsThisMonth} views this month, {views} views in total'
                    values={{
                      viewsThisMonth: supplier.get('viewsThisMonth')
                        ? supplier.get('viewsThisMonth').toLocaleString()
                        : 0,
                      views: supplier.get('views')
                        ? supplier.get('views').toLocaleString()
                        : 0
                    }}
                  />
                </Fragment>
              )}
            />
          </div>
          <div className='w-50-ns w-100 dib ph2 v-top'>
            <TagCloudWidget
              title={
                <FormattedMessage
                  id='CommunityMetricsContainer.CommonTags'
                  defaultMessage='Common Tags'
                />
              }
              noData={
                <FormattedMessage
                  id='CommunityMetricsContainer.NoTag'
                  defaultMessage='No Tag Available'
                />
              }
              tags={commonTags}
            />
          </div>
          <div className='w-50-ns w-100 dib ph2 v-top'>
            <TagCloudWidget
              title={
                <FormattedMessage
                  id='CommunityMetricsContainer.CommonSearchTerms'
                  defaultMessage='Common Search Terms'
                />
              }
              noData={
                <FormattedMessage
                  id='CommunityMetricsContainer.NoTerm'
                  defaultMessage='No Term Available'
                />
              }
              tags={commonSearchTerms}
            />
          </div>
        </div>
      </Page>
    )
  }
}

type ContainerProps = RouteComponentProps<{ communityId: string }>

export default connect((state: RootState, props: ContainerProps) => {
  const {
    match: {
      params: { communityId }
    }
  } = props

  return {
    communityId,
    isLoading: !state.getIn([
      'buyer',
      'insights',
      'communityById',
      communityId
    ]),
    commonTags: state.getIn([
      'buyer',
      'insights',
      'communityById',
      communityId,
      'supplierTags'
    ]),
    commonSearchTerms: state.getIn([
      'buyer',
      'insights',
      'communityById',
      communityId,
      'commonSearchTerms'
    ]),
    activeBuyers: state.getIn([
      'buyer',
      'insights',
      'communityById',
      communityId,
      'mostActiveBuyers'
    ]),
    activeSuppliers: state.getIn([
      'buyer',
      'insights',
      'communityById',
      communityId,
      'mostActiveSuppliers'
    ])
  }
})(CommunityMetricsContainer)
