import React, { Component, ReactNode } from 'react'
import AddInfoButton from '../AddInfoButton'
import Button from '../Button'
import Warning from '../Warning'
import { Field } from 'redux-form/immutable'
import SelectPublicPrivate from '../SelectPublicPrivate'
import { FormattedMessage } from 'react-intl'

const styles = {
  wrapper: 'border-box',
  form: 'bg-near-white pb3-5 ph3-5',
  formActions: 'mt3',
  cancelButton: 'ml2'
}

export interface AddFormProps {
  onSubmit: () => void
  noAddButton?: boolean
  noActions?: boolean
  addButtonLabel?: string | ReactNode
  submitButtonLabel?: string | ReactNode
  editMode?: boolean
  errorMessage?: string
  enablePublicPrivate?: boolean
  onClickAdd?: () => void
  onClickCancel?: () => void
}

class AddForm extends Component<AddFormProps> {
  state = {
    showHint: false
  }

  render() {
    const {
      addButtonLabel,
      submitButtonLabel,
      onSubmit,
      onClickAdd,
      onClickCancel,
      editMode,
      errorMessage,
      enablePublicPrivate,
      children,
      noAddButton,
      noActions
    } = this.props

    return (
      <div className={styles.wrapper}>
        {!noAddButton && onClickAdd && (
          <AddInfoButton
            onClick={onClickAdd}
            label={
              addButtonLabel || (
                <FormattedMessage id='AddForm.Add' defaultMessage='Add' />
              )
            }
          />
        )}

        {(editMode || noAddButton) && (
          <form onSubmit={onSubmit} className={styles.form}>
            {children}

            {!noActions && (
              <div className={styles.formActions}>
                <Button
                  type='submit'
                  autoSize
                  label={
                    submitButtonLabel || (
                      <FormattedMessage id='AddForm.Add' defaultMessage='Add' />
                    )
                  }
                />
                <Button
                  label={<FormattedMessage id='CancelButton' />}
                  autoSize
                  secondary
                  onClick={onClickCancel}
                  className={styles.cancelButton}
                />
                {enablePublicPrivate && (
                  <Field
                    name='isPrivate'
                    component={SelectPublicPrivate}
                    className='v-mid ml2'
                  />
                )}
              </div>
            )}

            {errorMessage && <Warning message={errorMessage} className='mt4' />}
          </form>
        )}
      </div>
    )
  }
}

export default AddForm
