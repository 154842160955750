import React, { Component } from 'react'
import { connect } from 'react-redux'
import orgsSelectors from '../../store/selectors/orgsSelectors'
import OrgSearchResultItem from '../../components/OrgSearchResultItem'
import Button from 'shared/components/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import connectUserToOrgSelectors from '../../store/selectors/connectUserToOrgSelectors'
import Loading from 'shared/components/Loading'
import ListItem from 'shared/components/List/ListItem'
import AddContactForm from '../AddContactForm'
import { loadOrg } from 'shared/actions'
import {
  connectUserToOrg,
  removeContact,
  startConnectingUserToOrg,
  stopConnectingUserToOrg
} from '../../store/actions'
import Contact from 'shared/models/Contact'
import { RecordOf, List, Map } from 'immutable'

type Props = {
  selectedUserEmail: string
  contacts: List<RecordOf<Contact>>
  orgs: List<
    RecordOf<{
      id: string
      name: string
      logoUrl: string
      domains: Array<string>
    }>
  >
  selectedOrg: Map<
    string,
    RecordOf<{
      id: string
      name: string
      logoUrl: string
      domains: Array<string>
    }>
  >
  removeContact: (index: number) => void
  stopConnectingUserToOrg: () => void
  loadOrg: (orgId: string) => void
  startConnectingUserToOrg: (orgId: string) => void
  connectUserToOrg: (connectUsertoOrg: {
    email: string
    orgId: string
    contacts: List<RecordOf<Contact>>
  }) => void
}
type State = {
  openDialog: boolean
  orgId: string
}

export class OrgSearchResults extends Component<Props, State> {
  state = {
    orgId: '',
    openDialog: false
  }

  handleFinishConnect = () => {
    const { orgId } = this.state
    const { selectedUserEmail, connectUserToOrg, contacts } = this.props

    connectUserToOrg({
      email: selectedUserEmail,
      orgId,
      contacts
    })

    this.handleRequestClose()
  }

  handleClickConnect = orgId => {
    const { loadOrg, startConnectingUserToOrg } = this.props
    loadOrg(orgId)
    startConnectingUserToOrg(orgId)
    this.setState({
      openDialog: true,
      orgId
    })
  }

  handleRequestClose = () => {
    const { stopConnectingUserToOrg } = this.props

    stopConnectingUserToOrg()
    this.setState({
      openDialog: false,
      orgId: ''
    })
  }

  render() {
    const {
      orgs,
      removeContact,
      selectedUserEmail,
      selectedOrg,
      contacts
    } = this.props

    const { openDialog } = this.state

    const dialogTitle = selectedOrg
      ? `Connecting ${selectedUserEmail} to ${selectedOrg.get('name')}`
      : `Connecting ${selectedUserEmail}`

    return (
      <div className='ba b--light-gray ph3 bg-white pb4'>
        {orgs &&
          orgs.map(org => (
            <OrgSearchResultItem
              key={org.get('id')}
              id={org.get('id')}
              name={org.get('name')}
              logoUrl={org.get('logoUrl')}
              domains={org.get('domains')}
              rightButton={
                <Button
                  label='Connect'
                  onClick={() => this.handleClickConnect(org.get('id'))}
                />
              }
            />
          ))}

        <Dialog open={openDialog} fullWidth>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent className='mt3'>
            {selectedOrg.size > 0 && (
              <div>
                {contacts &&
                  contacts.map((c, i) => (
                    <ListItem
                      key={i}
                      primaryText={
                        <span>
                          {c.getIn(['user', 'firstName'])}{' '}
                          {c.getIn(['user', 'lastName'])}
                        </span>
                      }
                      secondaryText={
                        <div>
                          <div>{c.getIn(['user', 'email'])}</div>
                          <button
                            type='button'
                            className='f7 fw3 link pv2 ph0 dim red bg-transparent b--transparent ba pointer'
                            onClick={() => removeContact(i)}
                          >
                            Remove
                          </button>
                        </div>
                      }
                    />
                  ))}

                <AddContactForm domains={selectedOrg.get('domains')} />

                <Button
                  label='Connect'
                  onClick={this.handleFinishConnect}
                  autoSize
                  className='mt3-5 mr3'
                />

                <Button
                  label='Cancel'
                  onClick={this.handleRequestClose}
                  autoSize
                  secondary
                />
              </div>
            )}

            {!selectedOrg && <Loading />}
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

export default connect(
  state => ({
    orgs: orgsSelectors.getAll(state),
    contacts: connectUserToOrgSelectors.getContacts(state),
    selectedUserEmail: connectUserToOrgSelectors.getEmail(state),
    selectedOrg: connectUserToOrgSelectors.getSelectedOrg(state)
  }),
  {
    loadOrg,
    removeContact,
    connectUserToOrg,
    startConnectingUserToOrg,
    stopConnectingUserToOrg
  }
)(OrgSearchResults)
