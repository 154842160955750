import { withStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'

export default withStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    zIndex: 1
  }
})(BottomNavigation)
