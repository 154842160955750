import React, { useState, ChangeEvent } from 'react'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import { useDispatch, useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import { RecordOf } from 'immutable'
import HelpIcon from '@material-ui/icons/HelpOutline'
import Tooltip from 'shared/components/Tooltip'
import startCase from 'lodash.startcase'
import { notify } from 'shared/actions'

type SAML = {
  entryPoint: string
  logoutUrl: string
  additionalParams: object
  additionalAuthorizeParams: object
  logoutParams: object
  certificate: string
  authnRequestBinding: string
  externalIdAttributeName: string
  emailAttributeName: string
}

const samlHelpText = {
  entryPoint: `URL of IdP for non-logout requests (or all requests if logoutUrl is null).  Used on login to get a one time user token in TealBook that's then used to create a normal TealBook token.  When we refresh we don't go back to SAML, we continue to regenerate the refresh tokens.`,
  logoutUrl: `URL of IdP for logout requests (if null, uses same as entryPoint)`,
  additionalParams: `Dictionary of query params to include with all requests to IdP. These don't relate to the user or session, they could be used to identify the TealBook organization or something else they need to know about the source of the request.`,
  additionalAuthorizeParams: `Dictionary of query params to include with authorize requests to IdP. These don't relate to the user or session, they could be used to identify the TealBook organization or something else they need to know about the source of the request.`,
  logoutParams: `Dictionary of query params to include with logout requests to IdP. These don't relate to the user or session, they could be used to identify the TealBook organization or something else they need to know about the source of the request.`,
  certificate: `the IdP's public signing certificate.  They may be rotating this as frequently monthly so we need to figure out how.`,
  authnRequestBinding: `Indicates binding type ('HTTP-POST' or 'HTTP-Redirect').  HTTP-Redirect is the default with Passport SAML library.  They come to us and they aren't authenticated and don't have a token then how do we take them to the iDP to login.`,
  externalIdAttributeName: `SAMLResponse attribute name corresponding to externalId (if used).  Used to identify the field in the SAML response that has the external id of the user, if they are mapping on a persistent id instead of email.`,
  emailAttributeName: `SAMLResponse attribute name corresponding to email address.  Used to identify the field in the SAML response that has the email id of the user.  One of externalIdAttributeName and emailAttributeName must be set.  If both are available then externalIdAttributeName takes precedence.`
}

const samlPropsOrder = [
  'entryPoint',
  'logoutUrl',
  'additionalParams',
  'additionalAuthorizeParams',
  'logoutParams',
  'certificate',
  'authnRequestBinding',
  'externalIdAttributeName',
  'emailAttributeName'
]

type Props = {
  onUpdate: (value) => void
}

const SamlJsonEditor = (props: Props) => {
  const dispatch = useDispatch()
  const saml: RecordOf<SAML> = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'saml')
  )

  const [editSaml, setEditSaml] = useState<{ [key: string]: string }>(() => {
    const samlObj = saml?.toJS()
    for (const key in samlObj) {
      if (key.endsWith('Params')) {
        samlObj[key] = JSON.stringify(samlObj[key])
      }
    }
    return samlObj
  })

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditSaml(
      Object.assign({}, editSaml, {
        [e.currentTarget.name]: e.currentTarget.value
      })
    )
  }

  const handleUpdateSaml = () => {
    let currentProp: string = ''
    let copySaml = Object.assign({}, editSaml)
    try {
      for (const key in copySaml) {
        currentProp = key
        if (key.endsWith('Params')) {
          copySaml[key] = JSON.parse(copySaml[key])
        }
      }
      props.onUpdate({ saml: copySaml })
    } catch (e) {
      dispatch(notify({ message: `Invalid Property: ${currentProp}` }))
    }
  }

  return (
    <>
      <div className='mt2 br1 ba b--black-10'>
        <Table stickyHeader size='small'>
          <TableHead className='bg-light-gray pv3'>
            <TableRow>
              <TableCell style={{ width: 200 }}>Property</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          {samlPropsOrder.map(prop => (
            <TableBody>
              <TableRow>
                <TableCell style={{ width: 200 }}>
                  <div className='flex items-center'>
                    <Text>{startCase(prop)}</Text>
                    <Tooltip title={samlHelpText[prop]}>
                      <HelpIcon className='ml1' />
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell>
                  <Input
                    name={prop}
                    value={editSaml[prop]}
                    onChange={handleInputChange}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </div>
      <Button autoSize className='mt2' onClick={handleUpdateSaml}>
        Update
      </Button>
    </>
  )
}

export default SamlJsonEditor
