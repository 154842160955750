import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import posts from 'shared/utils/api/posts'

export function* deletePostSaga(action) {
  yield call(posts.deletePost, action.payload)
  return yield action.payload
}

export default createApiRequestSaga(deletePostSaga)
