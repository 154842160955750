import { connect } from 'react-redux'
import UserComment from '../../components/UserComment'
import usersSelectors from '../../selectors/usersSelectors'

type Props = {
  userId: string
  userProfileUrl: string
}

export const mapStateToProps = (state, props: Props) => {
  const user = usersSelectors.getById(state, props.userId)

  return (
    user && {
      profilePictureUrl: user.get('profilePictureUrl'),
      userProfileUrl: props.userProfileUrl,
      firstName: user.get('firstName'),
      lastName: user.get('lastName')
    }
  )
}

const UserCommentContainer = connect(mapStateToProps)(UserComment)

export default UserCommentContainer
