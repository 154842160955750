import searchSelectors from '../searchSelectors'
import filterOptionsSelectors from '../filterOptionsSelectors'
import { createSelector } from 'reselect'

export const isPanelCollapse = (state, ref, key) =>
  state.getIn(['buyer', 'filterPanels', ref, 'collapse']) &&
  state.getIn(['buyer', 'filterPanels', ref, 'collapse']).has(key)
    ? state.getIn(['buyer', 'filterPanels', ref, 'collapse', key])
    : true

export const useDefault = (state, ref) =>
  !state.getIn(['buyer', 'filterPanels', ref, 'collapse'])

export const showCollapseAll = createSelector(
  searchSelectors.getAggregations,
  filterOptionsSelectors.getFilterOptionCommunities,
  (state, ref) => state.getIn(['buyer', 'filterPanels', ref, 'collapse']),
  (state, ref) => state.getIn(['buyer', 'filterPanels', ref, 'lastAction']),
  (aggregations, filteredCommunities, collapse, lastAction) => {
    const allExpanded =
      !collapse || (collapse && collapse.every(v => v === false))

    return allExpanded || lastAction === 'collapse'
  }
)
