import React, { Component } from 'react'
import createEditForm from 'shared/utils/createEditForm'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import ExternalLink from 'shared/components/ExternalLink'
import { Field } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { SAVE_COMPANY_INFO } from '../../actionTypes'
import companySelectors from '../../../shared/selectors/companySelectors'
import { validateUrl } from 'shared/utils/data/validate'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'
import VideoIcon from '@material-ui/icons/OndemandVideo'

const EditForm = createEditForm({
  formName: 'supplier/company/videos',
  submitActionType: SAVE_COMPANY_INFO
})

type Props = {
  initialValues: {
    supplier: {
      links: {
        videoUrls: List<string>
      }
    }
  }
}

export class VideosEditForm extends Component<Props> {
  render() {
    const { initialValues } = this.props
    const videoUrls = initialValues.supplier.links.videoUrls
    const firstVideoUrl: string = videoUrls && videoUrls.first()

    return (
      <FormattedMessage
        id='VideosEditForm.UploadVideoToShare'
        defaultMessage='Upload videos to share content about your company.'
      >
        {message => (
          <EditForm
            placeholder={message}
            initialValues={initialValues}
            showReadModeContent={!!firstVideoUrl}
            readModeContent={
              <div>
                <h3 className='f7 fw6 mid-gray ma0 mb2'>Video</h3>
                {firstVideoUrl && (
                  <ExternalLink href={firstVideoUrl}>
                    <div className='flex items-center dim'>
                      <VideoIcon fontSize='large' />
                      <span className='ml2'>
                        <FormattedMessage
                          id='VideoEditForm.OpenVideoLink'
                          defaultMessage='Open video'
                        />
                      </span>
                    </div>
                  </ExternalLink>
                )}
              </div>
            }
          >
            <Label>
              <FormattedMessage
                id='VideoEditForm.VideoURLLink'
                defaultMessage='Video URL'
              />
            </Label>

            <FormattedMessage
              id='VideoEditForm.ExampleVideoUrl'
              defaultMessage='e.g. https://www.youtube.com/watch?v=vQaVdw-pMRY'
            >
              {message => (
                <Field
                  name='supplier.links.videoUrls[0]'
                  component={Input}
                  validate={[validateUrl]}
                  placeholder={message as string}
                />
              )}
            </FormattedMessage>
          </EditForm>
        )}
      </FormattedMessage>
    )
  }
}

export default connect(state => ({
  initialValues: {
    supplier: {
      links: {
        videoUrls: companySelectors.getLink(state, 'videoUrls')
      }
    }
  }
}))(VideosEditForm)
