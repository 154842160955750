import React, { Component, Fragment } from 'react'
import Paper from 'shared/components/Paper'
import NewPostForm from '../../components/NewPostForm'
import { connect } from 'react-redux'
import { createPost } from '../../actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import postsSelectors from '../../selectors/postsSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import Hidden from '@material-ui/core/Hidden'
import Dialog from '@material-ui/core/Dialog'
import ContentAdd from '@material-ui/icons/Add'
import { List } from 'immutable'
import Fab from '@material-ui/core/Fab'
import RootState from 'shared/models/RootState'

type Props = {
  firstName: string
  lastName: string
  role: string
  profilePictureUrl: string
  communities: List<string>
  trendingTags: List<string>
  isClientApp: boolean
  canBroadcast: boolean
  createPost: (arg: string) => void
}

type State = {
  openCreatePostDialog: boolean
}

class NewPostFormContainer extends Component<Props, State> {
  state = {
    openCreatePostDialog: false
  }

  handleSubmitWhenOnMobile = formValues => {
    this.props.createPost(formValues)
    this.toggleCreatePostDialog()
  }

  toggleCreatePostDialog = () => {
    this.setState({
      openCreatePostDialog: !this.state.openCreatePostDialog
    })
  }

  render() {
    return (
      <Fragment>
        <Hidden smDown>
          <Paper noPadding>
            <NewPostForm {...this.props} onSubmit={this.props.createPost} />
          </Paper>
        </Hidden>

        <Hidden mdUp>
          <Dialog fullScreen open={this.state.openCreatePostDialog}>
            <NewPostForm
              {...this.props}
              onSubmit={this.handleSubmitWhenOnMobile}
              onClickCancel={this.toggleCreatePostDialog}
              creatingPost
            />
          </Dialog>

          <div className='fixed tr w-100 mw8 pr4 pb4 bottom-2 z-1 mb2'>
            <Fab onClick={this.toggleCreatePostDialog} color='primary'>
              <ContentAdd color='inherit' />
            </Fab>
          </div>
        </Hidden>
      </Fragment>
    )
  }
}

export default connect(
  (state: RootState) => {
    const currentUser = sessionSelectors.getUser(state)
    const trendingTags = postsSelectors.getTrendingTags(state)
    return {
      firstName: currentUser.get('firstName'),
      lastName: currentUser.get('lastName'),
      role: currentUser.get('title'),
      profilePictureUrl: currentUser.get('profilePictureUrl'),
      communities: postsSelectors.getCommunities(state),
      isClientApp: routingSelectors.isClientApp(state),
      trendingTags,
      canBroadcast:
        (sessionSelectors.userHasRole(state, 'clientConcierge') ||
          (sessionSelectors.userHasRole(state, 'tealbot') &&
            sessionSelectors.userHasRole(state, 'buyer'))) &&
        currentUser.get('email').endsWith('@tealbook.com')
    }
  },
  {
    createPost
  }
)(NewPostFormContainer)
