import { call } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* addClassificationCodeSaga(action) {
  yield call(supplier.addClassificationCode, action.payload)
  return yield action.payload
}

export default createApiRequestSaga(addClassificationCodeSaga)
