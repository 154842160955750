import React, { Component } from 'react'
import InviteSupplierForm from 'buyer/Search/components/InviteSupplierForm'
import { ADD_CUSTOMER } from '../../actionTypes'
import { closeNewCustomerDialog } from '../../actions'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { getFormValues } from 'redux-form/immutable'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import OrgUnit from 'shared/models/OrgUnit'
import { RecordOf, List } from 'immutable'
import { InjectedFormProps } from 'redux-form'

type Props = {
  hasSubmitted: boolean
  addCustomer: (arg) => void
  matchingOrgs: List<RecordOf<OrgUnit>>
  formValues: any
  handleSubmit: (formValues) => () => void
  onCancel: () => void
  isSubmitting: boolean
  addingCustomer: boolean
  messageSent: boolean
  contactEmail: string
  errorMessage: string
  newSupplierCreated: () => void
  clearErrorMessage: () => void
  onClickConciergeTask: () => void
  onClickEditFields: (params: any) => void
}

type State = {
  hasSubmitted: boolean
}

class AddNewCustomerForm extends Component<Props & InjectedFormProps, State> {
  state: State = {
    hasSubmitted: false
  }

  handleSubmit = formValues => {
    const { addCustomer, matchingOrgs } = this.props

    const formVals =
      matchingOrgs && matchingOrgs.size > 0
        ? formValues.set('ignoreMatchingOrgs', true)
        : formValues

    addCustomer(formVals.toJS())
  }

  handleSelectMatchingOrg = orgUnitId => {
    const { formValues } = this.props

    this.handleSubmit(formValues.set('orgUnitId', orgUnitId))
  }

  render() {
    const { handleSubmit, ...rest } = this.props

    return (
      <InviteSupplierForm
        {...rest}
        onSubmit={handleSubmit(this.handleSubmit)}
        onClickMatchingOrg={this.handleSelectMatchingOrg}
        onClickNoneOfTheAbove={handleSubmit(this.handleSubmit)}
      />
    )
  }
}

const formName = 'supplier/company/ourCustomers'
const getFormValuesSelector = getFormValues(formName)

export default connectReduxForm(
  state => ({
    hasSubmitted: orgsSelectors.isCustomerAdded(state),
    matchingOrgs: orgsSelectors.getMatchingOrgs(state),
    formValues: getFormValuesSelector(state)
  }),
  {
    addCustomer: createSubmitFormAction(formName, ADD_CUSTOMER),
    onCancel: closeNewCustomerDialog
  },
  {
    form: formName,
    enableReinitialize: true
  },
  AddNewCustomerForm
)
