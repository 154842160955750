import React, { Component } from 'react'
import { connect } from 'react-redux'
import StarsRatingInput from '../../components/StarsRatingInput'
import { Field } from 'redux-form/immutable'
import formValidators from 'shared/utils/formValidators'
import RootState from 'shared/models/RootState'
import { defineMessages, injectIntl, IntlShape } from 'react-intl'

const messages = defineMessages({
  Quality: {
    id: 'RatingQuestionField.Quality',
    defaultMessage: 'Quality'
  },
  Pricing: {
    id: 'RatingQuestionField.Pricing',
    defaultMessage: 'Pricing'
  },
  Delivery: {
    id: 'RatingQuestionField.Delivery',
    defaultMessage: 'Delivery'
  },
  Cooperation: {
    id: 'RatingQuestionField.Cooperation',
    defaultMessage: 'Cooperation'
  }
})

type Props = {
  name: string
  label: string
  starsLabel: string[]
  required?: boolean
  intl: IntlShape
} & ContainerProps

class RatingQuestionField extends Component<Props> {
  requiredValidator = val => {
    return this.props.required && formValidators.isRequired(val)
  }

  render() {
    const { name, label, starsLabel, required, intl } = this.props

    const starsRatingValidator = formValidators.createStarsRatingValidator()

    return (
      <Field
        component={StarsRatingInput}
        name={`ratingResponses.responses.${name}`}
        label={messages[label] ? intl.formatMessage(messages[label]) : label}
        starsLabel={starsLabel}
        validate={[starsRatingValidator, this.requiredValidator]}
        format={val => (!val ? 0 : parseFloat(val))}
        required={required}
      />
    )
  }
}

type ContainerProps = {
  questionId: string
}

export default connect((state: RootState, props: ContainerProps) => {
  const question = state.questions.byId[props.questionId]

  return {
    name: props.questionId,
    label: question?.label,
    starsLabel: question?.responseOptions
  }
})(injectIntl(RatingQuestionField))
