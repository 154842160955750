import React, { Component, Fragment } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '../../assets/icons/menu.svg'
import { withStyles } from '@material-ui/styles'
import IconButton from '../IconButton'

type Props = {
  classes?: any
  ariaLabel?: string
}

const styles = theme => ({
  focusVisible: {
    outline: 'auto',
    outLineColor: 'black',
    background: 'white'
  }
})

class DropDownMenu extends Component<Props> {
  state = {
    anchorEl: null
  }

  handleClickMenuIcon = e => {
    this.setState({
      anchorEl: e.currentTarget
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  render() {
    const { children, classes, ariaLabel, ...restProps } = this.props
    const childrenWithClose = React.Children.map(
      children,
      (child: JSX.Element) => {
        return (
          child &&
          React.cloneElement(child, {
            onClick: () => {
              if (child.props.onClick) {
                child.props.onClick()
              }
              this.handleClose()
            }
          })
        )
      }
    )

    const { anchorEl } = this.state

    return (
      <Fragment>
        <IconButton
          onClick={this.handleClickMenuIcon}
          aria-expanded={!!anchorEl}
          aria-label={ariaLabel}
          focusVisibleClassName={classes.focusVisible}
          style={{ width: 30, height: 30, padding: 0, minWidth: 30 }}
        >
          <img src={MenuIcon} alt='menu' />
        </IconButton>
        <Menu
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          {...restProps}
        >
          {childrenWithClose}
        </Menu>
      </Fragment>
    )
  }
}

export default withStyles(styles)(DropDownMenu)
