import React, { ReactElement, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import surveySelectors from '../../selectors/surveySelectors'
import RootState from 'shared/models/RootState'
import { saveSurveyResponse } from '../../actions'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import MetricOrCustomQuestionContainer from '../MetricOrCustomQuestionContainer'

type Props = {
  pageId: string
  parentQuestionId?: string
  questionId: string
  fieldName?: 'answer' | 'reason'
  placeholder?: string
  className?: string
  question?: string | ReactElement<FormattedMessage>
}

export type MetricUnit = {
  value: string
  source: string
  key: string
}

const MetricOrCustomQuestionList = (props: Props) => {
  const {
    pageId,
    questionId,
    parentQuestionId,
    fieldName = 'answer',
    ...rest
  } = props

  const surveyId = useSelector(surveySelectors.getEsgSurveyId)
  const response = useSelector((state: RootState) => {
    return surveySelectors.getResponseDetailByIds(
      state,
      pageId,
      questionId,
      parentQuestionId
    )
  })

  const [allMetrics, setAllMetrics] = useState<MetricUnit[]>([
    { value: '', source: 'metric', key: Math.random().toString() }
  ])
  const [inputIsEmpty, setInputIsEmpty] = useState<boolean>(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const inputValue = response?.get(fieldName)
    const answerSources = response?.get('answerSources')

    if (
      inputValue &&
      allMetrics.length === 1 &&
      !allMetrics[0].value &&
      !inputIsEmpty
    ) {
      const metrics = inputValue.split('|')
      const newMetrics = metrics.map((value, index) => ({
        value,
        source: answerSources ? Array.from(answerSources)[index] : 'customText',
        key: Math.random().toString()
      }))
      setAllMetrics(newMetrics)
    }
  }, [allMetrics, fieldName, response, inputIsEmpty])

  const handleMetricChange = (newValue: MetricUnit, index: number) => {
    const { value, source, key } = newValue
    if (index < allMetrics.length) {
      allMetrics[index] = Object.assign({}, allMetrics[index], {
        value,
        source,
        key
      })
    } else {
      allMetrics.push({ value, source, key: Math.random().toString() })
    }

    if (allMetrics.length === 1 && allMetrics[0].value === '') {
      setInputIsEmpty(true)
    } else {
      setInputIsEmpty(false)
    }
    setAllMetrics([...allMetrics])

    let valueToDispatch = generateValue(allMetrics)
    let answerSources: string[] = []
    allMetrics.forEach(metric => {
      answerSources.push(metric.source || 'customText')
    })

    dispatch(
      saveSurveyResponse({
        surveyId,
        surveyResponseDetailId: response?.get('id'),
        pageId,
        questionId,
        parentQuestionId,
        [fieldName]: valueToDispatch,
        [`${fieldName}Type`]: 'list',
        answerSources
      })
    )
  }
  const generateValue = (newMetrics: MetricUnit[]) => {
    let valueToDispatch = ''
    newMetrics.forEach((metric, index) => {
      valueToDispatch += (index === 0 ? '' : '|') + metric.value
    })
    return valueToDispatch
  }

  const handleDeleteMetric = (index: number) => {
    let answerSources = response?.get('answerSources')
    if (answerSources) {
      const newSources: string[] = Array.from(
        answerSources.filter((key, i) => {
          return index !== i
        })
      )
      let newMetrics = allMetrics
      newMetrics.splice(index, 1)
      setAllMetrics(newMetrics)
      if (newMetrics.length === 1 && newMetrics[0].value === '') {
        setInputIsEmpty(true)
      }
      let valueToDispatch = generateValue(newMetrics)

      dispatch(
        saveSurveyResponse({
          surveyId,
          surveyResponseDetailId: response?.get('id'),
          pageId,
          questionId,
          parentQuestionId,
          [fieldName]: valueToDispatch,
          [`${fieldName}Type`]: 'list',
          answerSources: newSources
        })
      )
    }
  }

  const handleAddMoreMetrics = () => {
    setInputIsEmpty(false)
    handleMetricChange(
      { value: '', source: 'metric', key: Math.random().toString() },
      allMetrics.length
    )
  }

  return (
    <div className='mb1'>
      {allMetrics.map((element, index) => {
        return (
          <MetricOrCustomQuestionContainer
            key={element.key}
            pageId={pageId}
            questionId={questionId}
            parentQuestionId={parentQuestionId}
            fieldName={fieldName}
            metric={element}
            metricIndex={index}
            onMetricChange={handleMetricChange}
            onDeleteMetric={handleDeleteMetric}
            {...rest}
          />
        )
      })}
      <Button
        onClick={handleAddMoreMetrics}
        label={
          <FormattedMessage
            id='MetricOrCustomQuestionList.addMore'
            defaultMessage='Add more'
          />
        }
        className='w3.5 dib'
      />
    </div>
  )
}

export default MetricOrCustomQuestionList
