import { connect } from 'react-redux'
import CommunityDetails from 'supplier/Communities/components/CommunityDetails'
import RootState from 'shared/models/RootState'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps<{ communityId: string }>

export default connect((state: RootState, props: Props) => ({
  isLoading: state.getIn(['buyer', 'communities', 'isLoading']),
  isClientApp: true,
  publicClients: communitiesSelectors.getPublicBuyers(
    state,
    props.match.params.communityId
  ),
  privateClients: communitiesSelectors.getPrivateBuyers(
    state,
    props.match.params.communityId
  )
}))(CommunityDetails)
