import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import vetsSelectors from '../../selectors/vetsSelectors'
import MyVets from '../../components/MyVets'
import { loadVets, createVet } from '../../actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'

export default connect(
  state => ({
    currentUserId: sessionSelectors.getUserId(state),
    vets: vetsSelectors.getFlatItemsInList(state),
    isLoadingVets: vetsSelectors.isFetching(state),
    isVetApprover: sessionSelectors.userHasRole(state, 'buyerVetApprover')
  }),
  {
    onStartNewVet: createVet,
    onLoadVets: loadVets
  }
)(withRouter(MyVets))
