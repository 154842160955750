import React from 'react'
import { FormattedMessage } from 'react-intl'

type Props = {
  totalPercentage: number
}

const Encouragement = (props: Props) => {
  return (
    <span className='fw6 mr1' style={{ color: '#1E6A77' }}>
      {props.totalPercentage === 100 ? (
        <FormattedMessage
          id='Encouragement.OutstandingText'
          defaultMessage='Outstanding!'
        />
      ) : props.totalPercentage > 85 ? (
        <FormattedMessage
          id='Encouragement.AlmostPerfectText'
          defaultMessage='Almost perfect!'
        />
      ) : props.totalPercentage > 75 ? (
        <FormattedMessage
          id='Encouragement.GreatWorkText'
          defaultMessage='Great job!'
        />
      ) : (
        <FormattedMessage
          id='Encouragement.NiceWorkText'
          defaultMessage='Nice work!'
        />
      )}
    </span>
  )
}

export default Encouragement
