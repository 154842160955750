import React, { useState, useMemo, useEffect, MouseEvent } from 'react'
import Popover from '@material-ui/core/Popover'
import useUserAccess from 'shared/utils/useUserAccess'
import Button from 'shared/components/Button'
import iconSetting from 'shared/assets/icons/setting.svg'
import suppliers from 'shared/utils/api/suppliers'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import qs from 'qs'
import startCase from 'lodash.startcase'
import searchSelectors from '../../selectors/searchSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import storageManager from 'shared/utils/storageManager'
import Checkbox from 'shared/components/Checkbox'
import Divider from 'shared/components/Divider'
import Scrollable from 'shared/components/Scrollable'
import FileSaver from 'file-saver'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'
import { iconLabelStyle } from 'shared/components/NavBar/NavLink'
import IconButton from 'shared/components/IconButton'

const messages = defineMessages({
  name: { id: 'ExportSuppliersButton.Name', defaultMessage: 'Name' },
  domain: { id: 'ExportSuppliersButton.Domain', defaultMessage: 'Domain' },
  status: { id: 'ExportSuppliersButton.Status', defaultMessage: 'Status' },
  phone: { id: 'ExportSuppliersButton.Phone', defaultMessage: 'Primary Phone' },
  website: { id: 'ExportSuppliersButton.Website', defaultMessage: 'Website' },
  shortDescription: {
    id: 'ExportSuppliersButton.ShortDescription',
    defaultMessage: 'Short Description'
  },
  longDescription: {
    id: 'ExportSuppliersButton.LongDescription',
    defaultMessage: 'Long Description'
  },
  offerings: {
    id: 'ExportSuppliersButton.Offerings',
    defaultMessage: 'Offerings'
  },
  classificationsCodes: {
    id: 'ExportSuppliersButton.ClassificationsCodes',
    defaultMessage: 'NAICS'
  },
  addresses: {
    id: 'ExportSuppliersButton.Addresses',
    defaultMessage: 'Addresses'
  },
  countries: {
    id: 'ExportSuppliersButton.Countries',
    defaultMessage: 'Countries'
  },
  contacts: {
    id: 'ExportSuppliersButton.Contacts',
    defaultMessage: 'Contacts'
  },
  diversity: {
    id: 'ExportSuppliersButton.Diversity',
    defaultMessage: 'Diversity Qualifications'
  },
  quality: {
    id: 'ExportSuppliersButton.Quality',
    defaultMessage: 'Quality Qualifications'
  },
  food: {
    id: 'ExportSuppliersButton.Food',
    defaultMessage: 'Food Qualifications'
  },
  sustainability: {
    id: 'ExportSuppliersButton.Sustainability',
    defaultMessage: 'Sustainability Qualifications'
  },
  security: {
    id: 'ExportSuppliersButton.Security',
    defaultMessage: 'Security Qualifications'
  },
  comments: {
    id: 'ExportSuppliersButton.Comments',
    defaultMessage: 'Comments'
  },
  ratings: { id: 'ExportSuppliersButton.Ratings', defaultMessage: 'Ratings' },
  dunsNumber: {
    id: 'ExportSuppliersButton.DunsNumber',
    defaultMessage: 'DUNS'
  },
  lei: { id: 'ExportSuppliersButton.Lei', defaultMessage: 'LEI' },
  size: {
    id: 'ExportSuppliersButton.Size',
    defaultMessage: 'Number of Employees'
  },
  revenue: {
    id: 'ExportSuppliersButton.Revenue',
    defaultMessage: 'Yearly Revenue'
  },
  warning: {
    id: 'ExportSuppliersButton.WebsiteWarning',
    defaultMessage: 'Warning'
  },
  geographicRisk: {
    id: 'ExportSuppliersButton.GeographicRisk',
    defaultMessage: 'Geographic Risk'
  },
  referenceScore: {
    id: 'ExportSuppliersButton.ReferenceScore',
    defaultMessage: 'Reference Score'
  }
})

const basicColumns = [
  'name',
  'domain',
  'phone',
  'addresses',
  'contacts',
  'status',
  'warning'
]

type Props = {
  disabled?: boolean
}

const useStyles = makeStyles({
  icon: iconLabelStyle.icon
})

const ExportSuppliersButton = (props: Props) => {
  const { disabled } = props
  const userAccess = useUserAccess()
  const location = useLocation()
  const intl = useIntl()
  const classes = useStyles()

  const supplierIds = useSelector(searchSelectors.getSupplierIds)
  const canExportMore: boolean = useSelector(state =>
    sessionSelectors.userHasRole(state, ['tealbot', 'clientConcierge'])
  )

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [availableColumns, setAvailableColumns] = useState<Array<string>>([])
  const [exportColumns, setExportColumns] = useState<Array<string>>([])
  const [exporting, setExporting] = useState<boolean>(false)

  const allowColumns = useMemo(() => {
    if (userAccess.accessExportAll) {
      return availableColumns
    } else if (userAccess.accessExportAdvanced) {
      return availableColumns
    } else {
      return basicColumns
    }
  }, [
    availableColumns,
    userAccess.accessExportAdvanced,
    userAccess.accessExportAll
  ])

  useEffect(() => {
    let saveColumns = storageManager.getItem('exportColumns') || basicColumns
    if (saveColumns && allowColumns) {
      setExportColumns(saveColumns.filter(col => allowColumns.includes(col)))
    }
  }, [allowColumns])

  useEffect(() => {
    suppliers.getExportColumns().then(columns => {
      setAvailableColumns(columns.map(col => col.key))
    })
  }, [])

  const showConfigure = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const exportFile = supplierIds => {
    suppliers
      .downloadExportFile({ supplierIds: supplierIds, exportColumns })
      .then(blob => {
        FileSaver.saveAs(blob, `export.csv`)
        setExporting(false)
      })
  }

  const toggleColumn = (col: string) => {
    const newColumns = exportColumns.includes(col)
      ? [...exportColumns.filter(ec => ec !== col)]
      : [...exportColumns, col]

    setExportColumns(newColumns)
    storageManager.setItem('exportColumns', newColumns)
  }

  const handleExportFile = () => {
    setExporting(true)
    if (canExportMore) {
      const params = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        arrayLimit: 100
      })
      suppliers
        .searchSuppliers(Object.assign({}, params, { limit: 1000 }))
        .then(({ hits }) => {
          if (hits) {
            const supplierIds = hits.map(item => item.supplier.id)
            exportFile(supplierIds)
          }
        })
    } else {
      exportFile(supplierIds?.toJS())
    }
  }

  return (
    <>
      <Button
        autoSize
        size='small'
        onClick={handleExportFile}
        className='ml2'
        disabled={disabled || exporting}
      >
        {exporting ? (
          <CircularProgress
            color='secondary'
            disableShrink
            size={22}
            thickness={4}
          />
        ) : (
          <FormattedMessage
            id='SearchContainer.Export'
            defaultMessage='Export'
          />
        )}
      </Button>
      <IconButton onClick={showConfigure}>
        <img
          src={iconSetting}
          alt='Config'
          className={`w1 v-mid dib ml2 pointer ${classes.icon}`}
          aria-label='Config'
        />
      </IconButton>
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div className='ph3'>
          <span id='exportSettings' className='db pt3 mb1 f7 fw6'>
            <FormattedMessage
              id='ExportSuppliersButton.Label'
              defaultMessage='Export Columns Configuration'
            />
          </span>
          <Scrollable labelledBy='exportSettings'>
            {allowColumns?.map(col => {
              return (
                <Checkbox
                  key={col}
                  label={
                    messages[col]
                      ? intl.formatMessage(messages[col])
                      : startCase(col)
                  }
                  labelFontLight
                  className='mv2'
                  checked={exportColumns.includes(col)}
                  disabled={
                    exportColumns.includes(col) && exportColumns.length === 1
                  }
                  onChange={() => toggleColumn(col)}
                />
              )
            })}
          </Scrollable>
        </div>
        <Divider />
        <div className='pa3'>
          <Button autoSize size='small' onClick={() => setAnchorEl(null)}>
            <FormattedMessage id='Close' defaultMessage='Close' />
          </Button>
        </div>
      </Popover>
    </>
  )
}

export default ExportSuppliersButton
