import React, { Component, ReactNode } from 'react'
import ImageAdd from 'shared/assets/icons/add.svg'

const styles = {
  button:
    'tl f6 fw5 mid-gray pointer bg-near-white pa3 br2 w-100 b--none outline-0 mt3-5',
  plusIcon: 'dib pointer dim v-mid pr3'
}

/**
 * Creates the grey button with add icon to add information to a supplier profile section
 */
type Props = {
  onClick: () => void
  label: string | ReactNode
}
class AddInfoButton extends Component<Props> {
  render() {
    const { onClick, label } = this.props

    return (
      <button type='button' className={styles.button} onClick={onClick}>
        <img
          src={ImageAdd}
          alt='add'
          className={styles.plusIcon}
          style={{ width: 20 }}
        />
        {label}
      </button>
    )
  }
}

export default AddInfoButton
