import React, { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import { MetricUnit } from '../MetricOrCustomQuestionList/MetricOrCustomQuestionList'
import MetricOrCustomQuestion from '../MetricOrCustomQuestion'

type Props = {
  pageId: string
  parentQuestionId?: string
  questionId: string
  fieldName?: 'answer' | 'reason'
  placeholder?: string
  className?: string
  question?: string | ReactElement<FormattedMessage>
  metric: MetricUnit
  metricIndex: number
  onMetricChange: (newValue: MetricUnit, index: number) => void
  onDeleteMetric: (index: number) => void
}

const MetricOrCustomQuestionContainer = (props: Props) => {
  const {
    fieldName = 'answer',
    question,
    metric,
    metricIndex,
    onMetricChange,
    ...rest
  } = props

  const handleToggleChange = () => {
    const newAnswerSource =
      metric.source === 'customText' ? 'metric' : 'customText'
    onMetricChange(
      { value: '', source: newAnswerSource, key: metric.key },
      metricIndex
    )
  }

  return (
    <MetricOrCustomQuestion
      isCustomEntrySelected={metric.source === 'customText'}
      onToggleChange={handleToggleChange}
      question={metricIndex === 0 ? question : ''}
      fieldName={fieldName}
      metric={metric}
      metricIndex={metricIndex}
      onMetricChange={onMetricChange}
      {...rest}
    />
  )
}

export default MetricOrCustomQuestionContainer
