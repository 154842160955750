import React, { Component, Fragment, FormEvent } from 'react'
import { InjectedFormProps } from 'redux-form'
import {
  reduxForm,
  Field,
  formValueSelector,
  isSubmitting
} from 'redux-form/immutable'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { CREATE_PASSWORD } from 'accounts/actionTypes'
import { getFormSubmitErrors } from 'shared/reducers'
import validateToken from './validateToken'
import paths from '../../routes/paths'
import Card from 'shared/components/Card'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import SubTitleText from 'shared/components/SubTitleText'
import TitleText from 'shared/components/TitleText'
import { Link } from 'react-router-dom'
import Warning from 'shared/components/Warning'
import TealbookLogoWrapper from '../../components/TealbookLogoWrapper'
import jwtDecode from 'jwt-decode'
import formValidators from 'shared/utils/formValidators'
import CreatingOrgPage from '../../components/CreatingOrgPage'
import AgreeToTerms from 'shared/components/AgreeToTerms'
import Select from 'shared/components/Select'
import Page from 'shared/components/Page'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import { connect } from 'react-redux'
import { RecordOf } from 'immutable'
import qs from 'qs'

type FormFields = {
  firstName: string
  lastName: string
  language: string
  password: string
  title: string
  foundTealbook: string
  termsAcceptedDate: string
}

type Props = {
  language: string
  location: { search: string }
  errorMessage: string
  invalid: boolean
  isSubmitting: boolean
  displayCreatingOrgPage: string
  handleSubmit: (formValues: {}) =>
    | ((event: FormEvent<HTMLFormElement>) => void)
    | undefined
  createPasswordRequest: (userAndPassword: {
    user?: Omit<FormFields, 'password'>
    redirectTo?: string
    password: string
    token: string
  }) => void
}

type State = {
  hasValidToken: boolean
  isValidatingToken: boolean
  showLoading: boolean
  passwordRules: { [key: string]: string }
  emailIsCorrect: boolean
  emailIsWrong: boolean
  confirmEmail: boolean
  email: string
  agreeToTerms: boolean
  user: string
  passwordRulesValidator: () => string | undefined
  timedOut: boolean
  noTAndCs: boolean
}

export class CreatePasswordForm extends Component<
  Props & InjectedFormProps<FormFields>,
  State
> {
  token: string = ''

  state: State = {
    hasValidToken: false,
    isValidatingToken: true,
    showLoading: false,
    passwordRules: {},
    emailIsCorrect: false,
    emailIsWrong: false,
    confirmEmail: false,
    email: '',
    agreeToTerms: false,
    user: '',
    passwordRulesValidator: () => '',
    timedOut: false,
    noTAndCs: false
  }

  componentDidMount() {
    const parsedQuery = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    })
    const token = typeof parsedQuery.token === 'string' ? parsedQuery.token : ''

    const timeoutId = window.setTimeout(() => {
      this.setState({
        timedOut: true
      })
    }, 10000)

    validateToken(token).then(response => {
      window.clearTimeout(timeoutId)
      if (response.hasValidToken) {
        const { sub, expressEntry, noTAndCs } = jwtDecode<{
          sub: string
          expressEntry: boolean
          noTAndCs?: boolean
        }>(token)

        // cache token
        this.token = token

        this.setState({
          confirmEmail: expressEntry,
          email: sub,
          noTAndCs: !!noTAndCs,
          passwordRulesValidator: formValidators.createPasswordRulesValidator(
            response.passwordRules
          ),
          ...response
        })
      } else {
        this.setState({
          ...response
        })
      }
    })

    window.setTimeout(() => {
      if (this.state.isValidatingToken) {
        this.setState({
          showLoading: true
        })
      }
    }, 1000)
  }

  handleSubmit = (formValues: RecordOf<FormFields>) => {
    if (this.state.user || this.state.agreeToTerms) {
      const { redirectTo } = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      })
      this.props.createPasswordRequest({
        user: !this.state.user
          ? {
              firstName: formValues.get('firstName'),
              lastName: formValues.get('lastName'),
              title: formValues.get('title'),
              foundTealbook: formValues.get('foundTealbook'),
              termsAcceptedDate: new Date().toISOString(),
              language: this.props.language
            }
          : undefined,
        password: formValues.get('password'),
        token: this.token,
        redirectTo
      })
    }
  }

  validateConfirmPassword = (value: string, allValues: RecordOf<FormFields>) =>
    value && value === allValues.get('password') ? '' : "Passwords don't match"

  handleEmailIsCorrect = () => {
    this.setState({
      emailIsCorrect: true
    })
  }

  handleEmailIsWrong = () => {
    this.setState({
      emailIsWrong: true
    })
  }

  handleChangeAgreeToTerms = () => {
    this.setState({
      agreeToTerms: !this.state.agreeToTerms
    })
  }

  render() {
    const {
      handleSubmit,
      errorMessage,
      invalid,
      isSubmitting,
      displayCreatingOrgPage
    } = this.props
    const {
      hasValidToken,
      isValidatingToken,
      showLoading,
      confirmEmail,
      email,
      emailIsCorrect,
      emailIsWrong,
      passwordRulesValidator,
      agreeToTerms,
      user,
      timedOut
    } = this.state

    if (timedOut) {
      return (
        <Page title='Verification Timed Out' className='tc'>
          <TealbookLogoWrapper />
          <TitleText>
            <FormattedMessage
              id='CreatePasswordForm.OhNoThereSeemsToBeAProblem'
              defaultMessage='Oh No, there seems to be a problem.'
            />
          </TitleText>

          <p className='f4 mw6 tl center mt4 pl3'>
            <FormattedMessage
              id='CreatePasswordForm.PleaseMakeSureToDoTheFollowing'
              defaultMessage='Please make sure to do the following:'
            />
          </p>

          <ul className='mw6 tl center'>
            <li className='mb2'>
              <FormattedMessage
                id='CreatePasswordForm.CheckYourNetworkConnectivity'
                defaultMessage='Check your network connectivity'
              />
            </li>
            <li className='mb2'>
              <FormattedMessage
                id='CreatePasswordForm.CheckYourFirewallSettings'
                defaultMessage='Check your firewall settings'
              />
            </li>
          </ul>

          <div className='mt4 mw6 center pa4 ba b--black-10 bg-white'>
            <Text>
              <FormattedMessage
                id='CreatePasswordForm.IfNoneOfTheAboveWork'
                defaultMessage='If none of the above works, please try refreshing the page.'
              />
              <br />
              <br />
              <FormattedMessage
                id='CreatePasswordForm.IfTheProblemPresistFeelFreeToContactSupport'
                defaultMessage='If the problem persist feel free to contact support at {phone} or at {email} and we will be able to help.'
                values={{
                  phone: (
                    <span className='teal f7 fw4 dim no-underline pointer'>
                      1-866-620-6640
                    </span>
                  ),
                  email: (
                    <a
                      className='teal f7 fw4 dim no-underline pointer'
                      href='mailto:support@tealbook.com'
                    >
                      support@tealbook.com
                    </a>
                  )
                }}
              />

              <br />

              <FormattedMessage
                id='CreatePasswordForm.OtherwiseTryAgainLater'
                defaultMessage='Otherwise try again later.'
              />
            </Text>

            <div className='mt3-5 w5 center'>
              <Button
                onClick={() => window.location.reload()}
                label={
                  <FormattedMessage
                    id='CreatePasswordForm.Refresh'
                    defaultMessage='Refresh'
                  />
                }
              />
            </div>
          </div>
        </Page>
      )
    }

    if (displayCreatingOrgPage) {
      return <CreatingOrgPage />
    }

    return (
      <Fragment>
        <TealbookLogoWrapper />

        {isValidatingToken && showLoading && (
          <div className='ph3'>
            <SubTitleText>
              <FormattedMessage
                id='CreatePasswordForm.VerificationValid'
                defaultMessage='Making sure the verification is still valid...'
              />
            </SubTitleText>
          </div>
        )}

        {!isValidatingToken && !hasValidToken && (
          <div className='ph3'>
            <TitleText>
              <FormattedMessage
                id='CreatePasswordForm.EmailExpired'
                defaultMessage='Oh No, your email has expired... :('
              />
            </TitleText>
            <SubTitleText>
              <Link to={paths.register}>
                <span className='f5 fw6 dib teal no-underline underline-hover pl1 pointer'>
                  <FormattedMessage
                    id='CreatePasswordForm.TryAgain'
                    defaultMessage='Please try registering again'
                  />
                </span>
              </Link>
            </SubTitleText>
          </div>
        )}

        {!isValidatingToken && hasValidToken && (
          <div className='ph3'>
            {confirmEmail && !emailIsCorrect && !emailIsWrong && (
              <Fragment>
                <FormattedMessage
                  id='CreatePasswordForm.YourEmail'
                  defaultMessage='Is your email'
                />{' '}
                <span className='fw4'>{email}</span>?
                <div className='mt3'>
                  <Button
                    onClick={this.handleEmailIsCorrect}
                    label={
                      <FormattedMessage
                        id='CreatePasswordForm.Yes'
                        defaultMessage='Yes'
                      />
                    }
                    className='w4 dib mr3'
                  />
                  <Button
                    onClick={this.handleEmailIsWrong}
                    label={<FormattedMessage id='No' />}
                    className='w4 dib'
                    secondary
                  />
                </div>
              </Fragment>
            )}

            {confirmEmail && emailIsWrong && (
              <Fragment>
                <TitleText>
                  <FormattedMessage
                    id='CreatePasswordForm.WrongEmail'
                    defaultMessage='Sorry about that, you must have gotten the wrong email'
                  />
                </TitleText>
                <SubTitleText>
                  <Link to={paths.register}>
                    <span className='f5 fw6 dib teal no-underline underline-hover pl1 pointer'>
                      <FormattedMessage
                        id='CreatePasswordForm.RegisteringAgain'
                        defaultMessage='Please try registering again'
                      />
                    </span>
                  </Link>
                </SubTitleText>
              </Fragment>
            )}

            {(!confirmEmail || emailIsCorrect) && (
              <Fragment>
                {!user ? (
                  <>
                    <TitleText>
                      <FormattedMessage
                        id='CreatePasswordForm.Welcome'
                        defaultMessage='Welcome to TealBook!'
                      />
                    </TitleText>
                    <div className='mw8 center'>
                      <SubTitleText>
                        <FormattedMessage
                          id='CreatePasswordForm.WizardMessage'
                          defaultMessage='Thank you for signing up. This wizard will take you through the process and explain more about TealBook.'
                        />
                      </SubTitleText>
                      <SubTitleText>
                        <FormattedMessage
                          id='CreatePasswordForm.WizardMessage2'
                          defaultMessage='First, tell us a bit about you.'
                        />
                      </SubTitleText>
                    </div>
                  </>
                ) : (
                  <>
                    <TitleText>
                      <FormattedMessage
                        id='CreatePasswordForm.Verified'
                        defaultMessage='Great! Your email has been verified.'
                      />
                    </TitleText>
                    <SubTitleText>
                      <FormattedMessage
                        id='CreatePasswordForm.NewPassword'
                        defaultMessage='Please create a new password for your account.'
                      />
                    </SubTitleText>
                  </>
                )}

                <form
                  onSubmit={handleSubmit(this.handleSubmit)}
                  className='mt4 mw6 center tl'
                >
                  <Card>
                    {!user && (
                      <>
                        <div>
                          <Label required htmlFor='firstName'>
                            <FormattedMessage
                              id='CreatePasswordForm.FirstName'
                              defaultMessage='First Name'
                            />
                          </Label>
                          <Field
                            name='firstName'
                            component={Input}
                            validate={[formValidators.isRequired]}
                          />
                        </div>
                        <div>
                          <Label required htmlFor='lastName'>
                            <FormattedMessage
                              id='CreatePasswordForm.LastName'
                              defaultMessage='Last Name'
                            />
                          </Label>
                          <Field
                            name='lastName'
                            component={Input}
                            validate={[formValidators.isRequired]}
                          />
                        </div>
                        <div>
                          <Label htmlFor='title'>
                            <FormattedMessage
                              id='CreatePasswordForm.CompanyPosition'
                              defaultMessage='Company Position'
                            />
                          </Label>
                          <Field
                            name='title'
                            component={Input}
                            autoComplete='new-title'
                          />
                        </div>
                        <div>
                          <Label htmlFor='foundTealbook'>
                            <FormattedMessage
                              id='CreatePasswordForm.FindTealbook'
                              defaultMessage='How did you find TealBook?'
                            />
                          </Label>
                          <Field
                            component={Select}
                            id='foundTealbook'
                            name='foundTealbook'
                          >
                            <FormattedMessage
                              id='CreatePasswordForm.selectAnOption'
                              defaultMessage='Select an option'
                            >
                              {message => (
                                <option value='' disabled>
                                  {message}
                                </option>
                              )}
                            </FormattedMessage>

                            <FormattedMessage
                              id='CreatePasswordForm.EmailInvitation'
                              defaultMessage='Email invitation'
                            >
                              {message => (
                                <option value='Email invitation'>
                                  {message}
                                </option>
                              )}
                            </FormattedMessage>

                            <FormattedMessage
                              id='CreatePasswordForm.ClientRequest'
                              defaultMessage='Client request'
                            >
                              {message => (
                                <option value='Client request'>
                                  {message}
                                </option>
                              )}
                            </FormattedMessage>

                            <FormattedMessage
                              id='CreatePasswordForm.FoundItMyself'
                              defaultMessage='Found it myself'
                            >
                              {message => (
                                <option value='Found it myself'>
                                  {message}
                                </option>
                              )}
                            </FormattedMessage>

                            <FormattedMessage
                              id='CreatePasswordForm.ColleagueRecomendation'
                              defaultMessage='Colleague recommendation'
                            >
                              {message => (
                                <option value='Colleague recommendation'>
                                  {message}
                                </option>
                              )}
                            </FormattedMessage>

                            <FormattedMessage
                              id='CreatePasswordForm.Other'
                              defaultMessage='Other'
                            >
                              {message => (
                                <option value='Other'>{message}</option>
                              )}
                            </FormattedMessage>
                          </Field>
                        </div>
                      </>
                    )}

                    <div>
                      <input
                        defaultValue={email}
                        type='input'
                        style={{ display: 'none' }}
                      />
                      <Label htmlFor='password' required>
                        <FormattedMessage
                          id='CreatePasswordForm.Password'
                          defaultMessage='Password'
                        />
                      </Label>
                      <Field
                        name='password'
                        component={Input}
                        type='password'
                        validate={[passwordRulesValidator]}
                        required
                        autoComplete='new-password'
                      />
                    </div>
                    <div>
                      <Label htmlFor='confirmPassword' required>
                        <FormattedMessage
                          id='CreatePasswordForm.ConfirmPassword'
                          defaultMessage='Confirm Password'
                        />
                      </Label>
                      <Field
                        name='confirmPassword'
                        component={Input}
                        type='password'
                        validate={[this.validateConfirmPassword]}
                        required
                        autoComplete='new-confirm-password'
                      />
                    </div>

                    {!user && (
                      <AgreeToTerms
                        agreeToTerms={agreeToTerms}
                        onChange={this.handleChangeAgreeToTerms}
                        noTermsAndConditions={this.state.noTAndCs}
                        className='mt3'
                      />
                    )}

                    {errorMessage && (
                      <Warning className='mt3' message={errorMessage} />
                    )}

                    <Button
                      type='submit'
                      label={
                        <FormattedMessage
                          id='CreatePasswordForm.Continue'
                          defaultMessage='Continue'
                        />
                      }
                      className='mt4'
                      disabled={
                        (!user && !agreeToTerms) || invalid || isSubmitting
                      }
                    />
                  </Card>
                </form>
              </Fragment>
            )}
          </div>
        )}
      </Fragment>
    )
  }
}

const formName = 'createPassword'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)
const selector = formValueSelector(formName)
const submittingSelector = isSubmitting(formName)

export default connect(
  (state: RootState) => ({
    isSubmitting: submittingSelector(state),
    errorMessage: formSubmitErrorsSelector(state),
    password: selector(state, 'password'),
    displayCreatingOrgPage: state.getIn(['session', 'displayCreatingOrgPage']),
    language: state.get('i18n').locale
  }),
  {
    createPasswordRequest: createSubmitFormAction(formName, CREATE_PASSWORD)
  }
)(
  reduxForm<FormFields, Props>({
    form: formName
  })(CreatePasswordForm)
)
