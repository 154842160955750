import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import naics from 'shared/models/Naics'
import Scrollable from 'shared/components/Scrollable'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import Text from 'shared/components/Text'
import Select from 'react-select'

export const NaicsName = ({ code }) => {
  const normalizeCode = naics[code]
    ? code
    : code.length === 6 && code.slice(-1) === '0' && naics[code.slice(0, -1)]
    ? code.slice(0, -1)
    : ''

  return normalizeCode ? <FormattedMessage {...naics[normalizeCode]} /> : null
}

const naicsList = Object.keys(naics).map(key => {
  return { value: key, label: `${key} - ${naics[key]['defaultMessage']}` }
})

type Props = {
  naics: string[]
  onAdd: (code: string) => void
  onDelete: (index: number) => void
}

const Naics = (props: Props) => {
  const { naics, onAdd, onDelete } = props
  const [input, setInput] = useState<string>('')

  const handleInputChange = (inputValue, meta) => {
    setInput(inputValue)
  }

  const handleSelected = ({ value }) => {
    onAdd(value)
    setInput('')
  }

  const handleFilterOption = ({ label, value, data }, rawInput) => {
    return (
      !rawInput || label.toLowerCase().indexOf(rawInput.toLowerCase()) !== -1
    )
  }

  const handleRemoveCode = (index: number) => {
    onDelete(index)
  }

  return (
    <>
      <Scrollable maxHeight={200}>
        {naics.map((c, i) => (
          <div key={c} className='dt mb2 dt--fixed'>
            <div className='dtc w3'>
              <Text>{c}</Text>
            </div>
            <div className='dtc w-auto'>
              <Text>
                <NaicsName code={c} />
              </Text>
            </div>
            <div className='dtc w2'>
              <IconButton
                aria-label='remove'
                onClick={() => handleRemoveCode(i)}
              >
                <ClearIcon fontSize='small' />
              </IconButton>
            </div>
          </div>
        ))}
      </Scrollable>
      <div className='mt2'>
        <Select
          aria-label='Add Naics'
          inputValue={input}
          onInputChange={handleInputChange}
          onChange={handleSelected}
          placeholder={`Add Naics Code/Industry`}
          options={naicsList}
          noOptionsMessage={inputValue => 'No NAICS Found'}
          controlShouldRenderValue={false}
          filterOption={handleFilterOption}
          styles={{
            control: base => ({ ...base, fontSize: '0.825rem' }),
            placeholder: base => ({
              ...base,
              fontSize: '.875rem'
            }),
            menuList: base => ({
              ...base,
              fontSize: '0.825rem',
              color: '#555'
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 })
          }}
        />
      </div>
    </>
  )
}

export default Naics
