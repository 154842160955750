import React, { Component, ReactNode } from 'react'
import Tooltip from 'shared/components/Tooltip'
import { List } from 'immutable'
import Popover from '@material-ui/core/Popover'
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  MessageDescriptor
} from 'react-intl'
import { translatedFilterKeys } from 'shared/utils/data/defineMessages'
import camelCase from 'lodash.camelcase'
import { Skeleton } from '@material-ui/lab'

type Props = {
  titleMap?: { [key: string]: string }
  filterKey?: string
  list: List<{ value: string; label: string; count?: number; id?: string }>
  selected: List<string | List<string>>
  disabled?: List<string>
  disabledMessage?: string | ReactNode
  limit?: number
  selectedOnly?: boolean
  prefix?: string
  onChange: (targetValue: string) => void
  showAll?: (showAll: boolean) => void
  showSkeleton?: boolean
  intl: IntlShape
  translatedMessages?: {
    [key: string]: MessageDescriptor
  }
  showValue?: boolean
  category?: string
  classes?: any
}

export class CheckboxList extends Component<Props> {
  static defaultProps = {
    limit: 10
  }

  state = {
    showAll: false,
    popoverOpen: false,
    popoverAnchorEl: null
  }

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    })
  }

  handleDisabled = e => {
    this.setState({
      popoverAnchorEl: e.target,
      popoverOpen: true
    })
  }

  handleShowAll = e => {
    this.setState({
      showAll: true
    })
  }

  handleOnChange = e => {
    const { onChange } = this.props
    onChange(e.target.value)
  }
  // use CertificationCategories as the single source of truth for up to date categorizations. Back-end devs update CertificationCategories
  // subTypes have been translated in FilterCertificationContainer
  // translatedFilterKeys is not up to date for Certification Categories
  renderLabel = (value: string, label: string, count?: number, id?: string) => {
    const {
      titleMap,
      prefix,
      showValue,
      intl,
      filterKey,
      translatedMessages: providedTranslatedMessages
    } = this.props

    const translatedMessages =
      providedTranslatedMessages ||
      (filterKey && translatedFilterKeys[filterKey])

    // special case for attributes and attachments because it supports custom label
    const definedMessageKey =
      filterKey === 'attributes' || filterKey === 'attachments'
        ? camelCase(label)
        : value || label

    const formatMessageId =
      translatedMessages &&
      (translatedMessages[definedMessageKey] ||
        translatedMessages[camelCase(definedMessageKey)])

    const translatedLabel = formatMessageId
      ? intl.formatMessage(formatMessageId)
      : label

    return (
      <Tooltip
        title={(titleMap && titleMap[value]) || translatedLabel || value}
      >
        <label
          htmlFor={`${prefix || ''}${id || value}`}
          className='f7 fw3 mid-gray pointer'
        >
          {showValue && `${value} - `}
          {translatedLabel}
          {count && ` (${count.toLocaleString()})`}
        </label>
      </Tooltip>
    )
  }

  render() {
    const {
      list,
      selected = List([]),
      disabled = List([]),
      disabledMessage,
      limit = 10,
      selectedOnly,
      prefix,
      showSkeleton,
      filterKey,
      category
    } = this.props
    const { showAll, popoverOpen, popoverAnchorEl } = this.state

    const isNaicsFiltered =
      filterKey === 'sector' ||
      filterKey === 'subsector' ||
      filterKey === 'group'

    const checkboxes = list
      .slice(
        0,
        showAll ||
          (selectedOnly && isNaicsFiltered) ||
          (limit && list.size < limit)
          ? undefined
          : limit
      )
      .map(item => {
        const isDisabled = disabled.includes(item.value.toString())
        return !selectedOnly ||
          selected.size === 0 ||
          selected.includes(item.value.toString()) ? (
          <div key={item.value}>
            {showSkeleton && (
              <div className='pt1'>
                <Skeleton variant='rect' width='100%' height={18} />
              </div>
            )}
            {!showSkeleton && (
              <div
                key={item.value}
                className='pv1 flex'
                onClick={
                  isDisabled && disabledMessage
                    ? this.handleDisabled
                    : undefined
                }
              >
                <input
                  type='checkbox'
                  id={`${prefix || ''}${item.id || item.value}`}
                  onChange={this.handleOnChange}
                  onKeyDown={e => {
                    if (e.key === ' ') {
                      e.preventDefault()
                      !(isDisabled && disabledMessage)
                        ? this.handleOnChange(e)
                        : this.handleDisabled(e)
                    }
                  }}
                  className='b--black-10 mr1'
                  value={item.value}
                  checked={
                    filterKey === 'certificationExpiredExcluded'
                      ? !selected.includes(item.value.toString())
                      : selected.includes(item.value.toString())
                  }
                  disabled={isDisabled}
                />
                {this.renderLabel(item.value, item.label, item.count, item.id)}
              </div>
            )}
          </div>
        ) : null
      })

    return (
      <div>
        <fieldset style={{ display: 'contents' }}>
          <legend aria-label={category || filterKey} />
          {checkboxes}
        </fieldset>
        {!showAll &&
          !isNaicsFiltered &&
          !(selectedOnly && selected.size > 0) &&
          limit < list.size && (
            <button
              className='f8 fw3 gray pointer mt2 mb0 lh-copy dib v-mid bn bg-transparent'
              onClick={this.handleShowAll}
            >
              <FormattedMessage
                id='CheckboxList.SeeMore'
                defaultMessage='See more'
              />{' '}
              &raquo;
            </button>
          )}
        <Popover
          open={popoverOpen}
          anchorEl={popoverAnchorEl}
          onClose={this.togglePopover}
          anchorOrigin={{
            vertical: 20,
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div className='dib lh-copy f7 fw4 mid-gray ph2 pv1'>
            {disabledMessage}
          </div>
        </Popover>
      </div>
    )
  }
}

export default injectIntl(CheckboxList)
