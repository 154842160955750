import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { getCommunities } from './Communities/actions'
import settingsSelectors from './shared/selectors/settingsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import createRoutes from './routes/createRoutes'
import BuyerAppBarContainer from './shared/containers/BuyerAppBarContainer'
import Hidden from '@material-ui/core/Hidden'
import BottomNavigation from 'shared/components/BottomNavigation'
import BottomNavigationAction from 'shared/components/BottomNavigationAction'
import NavIcon from 'shared/components/NavIcon'
import SuppliersIcon from 'shared/assets/icons/nav-suppliers.svg'
import VetIcon from 'shared/assets/icons/nav-vet.svg'
import InsightIcon from 'shared/assets/icons/nav-insight.svg'
import SuppliersTealIcon from 'shared/assets/icons/nav-suppliers-teal.svg'
import VetTealIcon from 'shared/assets/icons/nav-vet-teal.svg'
import InsightTealIcon from 'shared/assets/icons/nav-insight-teal.svg'
import CommunitiesIcon from 'shared/assets/icons/nav-communities.svg'
import CommunitiesTealIcon from 'shared/assets/icons/nav-communities-teal.svg'
import PeopleIcon from 'shared/assets/icons/nav-people.svg'
import PeopleTealIcon from 'shared/assets/icons/nav-people-teal.svg'
import MessagesIcon from 'shared/assets/icons/nav-messages.svg'
import MessagesTealIcon from 'shared/assets/icons/nav-messages-teal.svg'
import buyer from './routes/paths'
import searchSelectors from './Search/selectors/searchSelectors'
import messagesSelectors from 'shared/selectors/messagesSelectors'
import postsSelectors from 'posting/selectors/postsSelectors'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from 'shared/components/ListItemText'
import MoreIcon from 'shared/assets/icons/nav-more.svg'
import TealboardTealIcon from 'shared/assets/icons/nav-tealboard-teal.svg'
import TealboardIcon from 'shared/assets/icons/nav-tealboard.svg'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Loading from 'shared/components/Loading'
import { useHistory, useLocation } from 'react-router'
import RootState from 'shared/models/RootState'
import { AccessControl } from 'shared/models/AccessControl'
import useUserAccess from 'shared/utils/useUserAccess'
import { List as ImmuList } from 'immutable'
import { LicenseAccess } from 'buyer/shared/reducers/settingsReducer'
import HelpIcon from 'shared/assets/icons/doubt.svg'
import externalPaths from 'shared/routes/externalPaths'

const messages = defineMessages({
  search: {
    id: 'App.Suppliers',
    defaultMessage: 'Suppliers'
  },
  vets: {
    id: 'App.RFI',
    defaultMessage: 'RFI'
  },
  insight: {
    id: 'App.Insight',
    defaultMessage: 'Insight'
  },
  tealboard: {
    id: 'App.News',
    defaultMessage: 'News'
  },
  communities: {
    id: 'App.Communities',
    defaultMessage: 'Communities'
  },
  messages: {
    id: 'App.Messages',
    defaultMessage: 'Messages'
  },
  people: {
    id: 'App.People',
    defaultMessage: 'People'
  },
  knowledgeBase: {
    id: 'App.knowledgeBase',
    defaultMessage: 'Knowledge Base'
  },
  more: {
    id: 'App.More',
    defaultMessage: 'More'
  }
})

type Props = {
  queryString: string
  buyerType: string
  accessControl: AccessControl
  hasFixedFilters: boolean
  numberOfUnreadMessages: number
  numberOfUnreadPosts: number
  getCommunities: () => void
}

export const App = (props: Props) => {
  const history = useHistory()
  const location = useLocation()
  const intl = useIntl()
  const userAccess: LicenseAccess = useUserAccess() as LicenseAccess
  const [moreDialogOpen, setMoreDialogOpen] = useState(false)

  const {
    buyerType,
    accessControl,
    hasFixedFilters,
    numberOfUnreadMessages,
    numberOfUnreadPosts,
    getCommunities
  } = props

  const showTabs = useMemo(() => {
    let showTabs: Array<string> = []
    if (userAccess.accessRFI) {
      showTabs.push('vets')
    }
    if (userAccess.accessInsights) {
      showTabs.push('insight')
    }
    if (userAccess.accessNews) {
      showTabs.push('tealboard')
    }
    if (userAccess.accessChat) {
      showTabs.push('messages')
    }
    if (userAccess.accessCommunities) {
      showTabs.push('communities')
    }
    if (userAccess.accessContacts) {
      showTabs.push('people')
    }
    showTabs.push('knowledgeBase')

    return ImmuList(showTabs)
  }, [userAccess])

  const selectedBottomNav = useMemo(() => {
    let value
    if (
      location.pathname.indexOf(buyer.search) === 0 ||
      location.pathname.indexOf(`${buyer.basePath}/suppliers`) === 0
    ) {
      value = 'search'
    } else if (location.pathname.indexOf(buyer.vets) === 0) {
      value = 'vets'
    } else if (location.pathname.indexOf(buyer.insight) === 0) {
      value = 'insight'
    } else if (location.pathname.indexOf(buyer.tealboard) === 0) {
      value = 'tealboard'
    } else if (location.pathname.indexOf(buyer.messages) === 0) {
      value = 'messages'
    } else if (location.pathname.indexOf(buyer.people) === 0) {
      value = 'people'
    } else if (location.pathname.indexOf(buyer.communities) === 0) {
      value = 'communities'
    } else if (
      location.pathname.indexOf(externalPaths.knowledgeBaseLink) === 0
    ) {
      value = 'knowledgeBase'
    }
    return value
  }, [location.pathname])

  useEffect(() => {
    getCommunities()
  }, [getCommunities])

  const toggleMoreDialog = () => {
    setMoreDialogOpen(!moreDialogOpen)
  }

  const handleNavMenuClick = (to: string) => {
    if (to === 'more') {
      setMoreDialogOpen(open => !open)
    } else if (to === 'knowledgeBase') {
      window.open(externalPaths.knowledgeBaseLink)
      setMoreDialogOpen(false)
    } else {
      history.push(buyer[to])
      setMoreDialogOpen(false)
    }
  }

  const handleNavChange = (event, value) => {
    handleNavMenuClick(value)
  }

  const showSearchOnNav = !(
    location.pathname.includes('search') || location.pathname.includes('vets/')
  )

  const hideAppBar =
    location.pathname.includes('report-snapshot/') ||
    location.pathname.includes('esg-survey')

  if (!buyerType) {
    return <Loading />
  }

  return hideAppBar ? (
    createRoutes(hasFixedFilters, accessControl, userAccess)
  ) : (
    <>
      <BuyerAppBarContainer
        pathname={location.pathname}
        showSearchBar={showSearchOnNav}
      />
      {createRoutes(hasFixedFilters, accessControl, userAccess)}
      {accessControl.access === 'full' && (
        <Hidden mdUp>
          <BottomNavigation
            value={selectedBottomNav}
            onChange={handleNavChange}
            showLabels
          >
            <BottomNavigationAction
              label={
                <FormattedMessage
                  id='App.Suppliers'
                  defaultMessage='Suppliers'
                />
              }
              icon={
                <NavIcon
                  src={
                    selectedBottomNav === 'search'
                      ? SuppliersTealIcon
                      : SuppliersIcon
                  }
                />
              }
              value={'search'}
            />
            {showTabs.size > 4
              ? showTabs
                  .take(3)
                  .map(tab => (
                    <BottomNavigationAction
                      key={tab}
                      label={intl.formatMessage(messages[tab])}
                      icon={
                        <AppNavIcon
                          tab={tab}
                          highlight={selectedBottomNav === tab}
                        />
                      }
                      value={tab}
                    />
                  ))
              : showTabs.map(tab => (
                  <BottomNavigationAction
                    key={tab}
                    label={intl.formatMessage(messages[tab])}
                    icon={
                      <AppNavIcon
                        tab={tab}
                        highlight={selectedBottomNav === tab}
                      />
                    }
                    value={tab}
                  />
                ))}
            {showTabs.size > 4 && (
              <BottomNavigationAction
                label={intl.formatMessage(messages['more'])}
                icon={<AppNavIcon tab={'more'} />}
                value={'more'}
              />
            )}
          </BottomNavigation>
        </Hidden>
      )}

      <Dialog
        open={moreDialogOpen || false}
        onClose={toggleMoreDialog}
        fullWidth
      >
        <DialogTitle>Navigate to...</DialogTitle>
        <DialogContent>
          <List>
            {showTabs.skip(3).map(tab => (
              <ListItem
                key={tab}
                button
                onClick={() => handleNavMenuClick(tab)}
              >
                <ListItemText
                  numberOfNotifications={
                    selectedBottomNav === 'communities'
                      ? numberOfUnreadPosts && 1
                      : selectedBottomNav === 'messages'
                      ? numberOfUnreadMessages
                      : undefined
                  }
                >
                  {intl.formatMessage(messages[tab])}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  )
}

type AppNavIconProp = {
  tab: string
  highlight?: boolean
}

const AppNavIcon = ({ tab, highlight = false }: AppNavIconProp) => {
  switch (tab) {
    case 'search':
      return <NavIcon src={highlight ? SuppliersTealIcon : SuppliersIcon} />
    case 'vets':
      return <NavIcon src={highlight ? VetTealIcon : VetIcon} />
    case 'insight':
      return <NavIcon src={highlight ? InsightTealIcon : InsightIcon} />
    case 'tealboard':
      return <NavIcon src={highlight ? TealboardTealIcon : TealboardIcon} />
    case 'communities':
      return <NavIcon src={highlight ? CommunitiesTealIcon : CommunitiesIcon} />
    case 'messages':
      return <NavIcon src={highlight ? MessagesTealIcon : MessagesIcon} />
    case 'people':
      return <NavIcon src={highlight ? PeopleTealIcon : PeopleIcon} />
    case 'knowledgeBase':
      return <NavIcon src={HelpIcon} />
    case 'more':
      return <NavIcon src={MoreIcon} />
    default:
      return null
  }
}

export default connect(
  (state: RootState) => ({
    buyerType: settingsSelectors.getBuyerType(state),
    accessControl: sessionSelectors.getAccessControl(state),
    hasFixedFilters: settingsSelectors.getOrgFixedFilters(state).size > 0,
    numberOfUnreadPosts: postsSelectors.getUnread(state),
    numberOfUnreadMessages: messagesSelectors.getNumberOfUnreadMessages(state),
    queryString: searchSelectors.getQueryStringByRef(state, 'buyerSearch') || ''
  }),
  {
    getCommunities
  }
)(App)
