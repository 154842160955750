import React, { FunctionComponent, useState, useCallback } from 'react'
import { Field, FieldArray } from 'redux-form/immutable'
import Input from 'shared/components/Input'
import ProfileSubmitButton from 'shared/components/ProfileSubmitButton'
import CancelButton from 'shared/components/CancelButton'
import Label from 'shared/components/Label'
import TextArea from 'shared/components/TextArea'
import InfoOutline from '@material-ui/icons/InfoOutlined'
import Tooltip from 'shared/components/Tooltip'
import CheckboxGroup from 'shared/components/CheckboxGroup'
import {
  validateFacebook,
  validateLinkedIn,
  validateTwitter
} from 'shared/utils/data/validate'
import Warning from 'shared/components/Warning'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Checkbox from 'shared/components/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Switch from 'shared/components/Switch'
import SelectFromList from 'shared/components/SelectFromList'
import { fromJS, List, RecordOf } from 'immutable'
import { FormattedMessage } from 'react-intl'
import Select from 'shared/components/Select'
import { supportedLanguages } from '../../../../i18n'
import { WebsiteWarningType } from 'shared/components/WebsiteWarning'
import startCase from 'lodash.startcase'
import chatbot from 'shared/utils/chatbot'

const styles = {
  headerActions: 'pt2 pb2 border-box tr',
  formColumn: 'dib w-50-ns w-100 w-100 fl pl3 pr3',
  footerMessage: 'gray pb3 f6 pt3 tc',
  contactUsLink: 'fw6 teal underline-hover dim pointer'
}

export const normalizeTwitterUrl = val =>
  val.indexOf('?') > -1 ? val.substring(0, val.indexOf('?')) : val

export const validateDomains = value =>
  value && value.size > 0 ? (
    undefined
  ) : (
    <FormattedMessage
      id='EditCompanyProfileHeader.SelectDomaine'
      defaultMessage='Please select at least one domain'
    />
  )

type Props = {
  errorMessage: string
  isTealbot: boolean
  onSubmit: () => void
  onCancel: () => void
  addDomain: (newDomain: string) => void
  formDomainsValue: List<string>
  changeFieldValue: (arg0: string, arg1: List<string>) => void
  domainsOptions: List<RecordOf<{ value: string; label: string }>>
  website: string
  sanctionedCompany: List<string>
  flagCountryList?: List<
    RecordOf<{
      country: string
      status: boolean
    }>
  >
  language: string
  supplierType: string
  websiteWarning?: WebsiteWarningType
}

export const EditCompanyProfileHeader: FunctionComponent<Props> = props => {
  const [domainDialogOpen, setDomainDialogOpen] = useState<boolean>(false)
  const [newDomain, setNewDomain] = useState<string>('')
  const [
    sanctionedCompanyDialogOpen,
    setSanctionedCompanyDialogOpen
  ] = useState<boolean>(false)
  const [currentDescriptionLanguage, setCurrentDescriptionLanguage] = useState<
    string
  >(props.language)

  const { formDomainsValue, changeFieldValue, domainsOptions } = props

  const toggleDomainDialogOpen = () => {
    setDomainDialogOpen(true)
    setNewDomain(!domainDialogOpen ? '' : newDomain)
  }

  const toggleDomainDialogClose = () => {
    setDomainDialogOpen(false)
  }

  const handleNewDomainChange = e => {
    setNewDomain(e.target.value)
  }

  const handleAddDomain = () => {
    const { addDomain } = props
    addDomain(newDomain)
    changeFieldValue('domains', formDomainsValue.push(newDomain))
    toggleDomainDialogClose()
  }

  const toggleSanctionOpen = () => {
    setSanctionedCompanyDialogOpen(true)
  }

  const toggleSanctionClose = () => {
    setSanctionedCompanyDialogOpen(false)
  }

  const clearSanctionedCompany = () => {
    const { changeFieldValue } = props
    changeFieldValue('supplier.bannedCompanyIds', List([]))
    toggleSanctionClose()
  }

  const renderFormField = (fieldName: string, label: string, options) => {
    return (
      <div className='f7'>
        <Label htmlFor={fieldName}>{label}</Label>
        <Field name={fieldName} component={Input} type='text' {...options} />
      </div>
    )
  }

  const renderAlternativeNames: any = useCallback(({ fields }) => {
    return (
      <>
        <Button
          label={
            <FormattedMessage
              id='EditCompanyProfileHeader.AddAlternativeName'
              defaultMessage='Add an alternative name'
            />
          }
          autoSize
          className='mt1'
          onClick={() => fields.push()}
        />
        {fields.map((alternativeName, index) => {
          return (
            <div className='dt w-100 mt1' key={`alternative-${index}`}>
              <div className='dtc v-mid'>
                <FormattedMessage
                  id='EditCompanyProfileHeader.EnterAlternativeNamePlaceholder'
                  defaultMessage='Enter an alternative name'
                >
                  {message => (
                    <Field
                      component={Input}
                      name={alternativeName}
                      placeholder={message as string}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className='dtc v-mid w3'>
                <Button
                  secondary
                  label={
                    <FormattedMessage
                      id='EditCompanyProfileHeader.RemoveButton'
                      defaultMessage='Remove'
                    />
                  }
                  style={{ color: 'red' }}
                  onClick={() => fields.remove(index)}
                />
              </div>
            </div>
          )
        })}
      </>
    )
  }, [])

  const {
    onSubmit,
    errorMessage,
    onCancel,
    website,
    isTealbot,
    sanctionedCompany,
    supplierType
  } = props

  const flagCountryList =
    (typeof props.flagCountryList !== 'boolean' && props.flagCountryList) ||
    List([])

  const flagCountryListOptions: List<RecordOf<{
    label: string
    value: string
  }>> = flagCountryList.map(el =>
    fromJS({
      label: el.get('country'),
      value: el.get('country')
    })
  )

  const flagCountryListParser = value =>
    value.filter(item => item.get('status')).map(item => item.get('country'))

  const normalizeFlagCountry = value =>
    flagCountryList.map(item =>
      item.set('status', value.includes(item.get('country')))
    )

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className={styles.headerActions}>
          <CancelButton onClick={onCancel} />
          <ProfileSubmitButton />
        </div>

        <div className='bg-white b--black-10 br1 ba overflow-auto tl pa3 pl0 pr0'>
          {errorMessage && (
            <div className='ph3'>
              <Warning message={errorMessage} />
            </div>
          )}

          <div className={styles.formColumn}>
            <div>
              <Label>
                <FormattedMessage
                  id='EditCompanyProfileHeader.NameLabel'
                  defaultMessage='Name'
                />
              </Label>
              <Field component={Input} name='name' maxLength={150} />
              <Label>
                <FormattedMessage
                  id='EditCompanyProfileHeader.PhoneNumberLabel'
                  defaultMessage='Phone Number'
                />
              </Label>
              <Field
                component={Input}
                name='supplier.phones.office'
                maxLength={150}
                normalize={value => [value]}
              />
              {isTealbot && (
                <>
                  <FieldArray
                    name={`alternativeNames`}
                    component={renderAlternativeNames}
                  />
                  <Label>
                    <FormattedMessage
                      id='EditCompanyProfileHeader.SupplierTypeLabel'
                      defaultMessage='Supplier Type'
                    />
                  </Label>
                  <Text>{supplierType}</Text>
                </>
              )}
              {(isTealbot || !website) && (
                <>
                  <Label>
                    <FormattedMessage
                      id='EditCompanyProfileHeader.WebsiteUrlLabel'
                      defaultMessage='Website Url'
                    />
                  </Label>
                  <Field component={Input} name='supplier.links.websiteUrl' />
                </>
              )}
              {isTealbot && (
                <div>
                  <Label>
                    <FormattedMessage
                      id='EditCompanyProfileHeader.DomainLabel'
                      defaultMessage='Domain'
                    />
                  </Label>
                  {domainsOptions.size > 1 ? (
                    <Field
                      component={CheckboxGroup}
                      name='domains'
                      options={domainsOptions}
                      validate={validateDomains}
                    />
                  ) : (
                    <Text>
                      {domainsOptions && domainsOptions.getIn(['0', 'label'])}
                    </Text>
                  )}
                  <Button
                    label={
                      <FormattedMessage
                        id='EditCompanyProfileHeader.AddANewDomain'
                        defaultMessage='Add a new domain'
                      />
                    }
                    autoSize
                    className='mt1'
                    onClick={toggleDomainDialogOpen}
                  />
                  <Label htmlFor='search'>
                    <FormattedMessage
                      id='EditComanyProfileHeader.appearanceInSearch'
                      defaultMessage='Appearance In Search'
                    />
                  </Label>
                  <Field component={Switch} name='supplier.showSupplier' />

                  {flagCountryList.size > 0 && (
                    <>
                      <Label htmlFor='flagCountry'>
                        <FormattedMessage
                          id='EditComanyProfileHeader.SanctionedCountry'
                          defaultMessage='Sanctioned Country'
                        />
                      </Label>
                      <Field
                        component={CheckboxGroup}
                        name='supplier.flagCountry'
                        options={flagCountryListOptions}
                        format={flagCountryListParser}
                        normalize={normalizeFlagCountry}
                      />
                    </>
                  )}

                  {sanctionedCompany && sanctionedCompany.size > 0 && (
                    <div className='mt3'>
                      <Button
                        secondary
                        label={
                          <FormattedMessage
                            id='EditCompanyProfileHeader.ClearSanctionedCompany'
                            defaultMessage='Clear Sanctioned Company'
                          />
                        }
                        style={{ color: 'red' }}
                        onClick={toggleSanctionOpen}
                      />
                    </div>
                  )}

                  <Label htmlFor='status'>
                    <FormattedMessage
                      id='EditComanyProfileHeader.status'
                      defaultMessage='Company Status'
                    />
                  </Label>

                  <FormattedMessage
                    id='EditCompanyProfileHeader.SelectStatusPlaceholder'
                    defaultMessage='Select Status'
                  >
                    {message => (
                      <Field
                        component={SelectFromList}
                        placeholder={message as string}
                        options={fromJS([
                          { key: 'Inactive', label: 'Inactive' },
                          { key: 'Active', label: 'Active' },
                          { key: 'Acquired', label: 'Acquired' }
                        ])}
                        name='status'
                      />
                    )}
                  </FormattedMessage>

                  <Label htmlFor='status date'>
                    <FormattedMessage
                      id='EditComanyProfileHeader.StatusDateLabel'
                      defaultMessage='Status Date'
                    />
                  </Label>

                  <FormattedMessage
                    id='EditCompanyProfileHeader.YearPlaceholder'
                    defaultMessage='Year'
                  >
                    {message => (
                      <Field
                        placeholder={message as string}
                        maxLength='4'
                        type='number'
                        component={Input}
                        name='statusDate'
                      />
                    )}
                  </FormattedMessage>
                </div>
              )}
            </div>

            <div className='flex justify-between items-center mt3 mb1'>
              <Label htmlFor='description' className='db f7 fw6'>
                <FormattedMessage
                  id='EditComanyProfileHeader.BasicDescriptionLabel'
                  defaultMessage='Basic Description'
                />
              </Label>
              <Select
                onChange={e =>
                  setCurrentDescriptionLanguage(e.currentTarget.value)
                }
                value={currentDescriptionLanguage}
              >
                {supportedLanguages.map(({ key, title }) => (
                  <option key={key} value={key}>
                    {title}
                  </option>
                ))}
              </Select>
            </div>
            <Field
              name={`description.${currentDescriptionLanguage}`}
              maxLength={200}
              component={TextArea}
            />
            <Label htmlFor='diversityProgram'>
              <FormattedMessage
                id='EditComanyProfileHeader.DiversityProgram'
                defaultMessage='Supplier Diversity Program'
              />
              <Tooltip
                placement='right-start'
                title={
                  <div className='w5'>
                    <FormattedMessage
                      id='StandardAttributes.DiversityProgramInfo'
                      defaultMessage='Do you have a program for prioritizing spend with small and/or diverse suppliers and can provide your spend with these suppliers to your clients?'
                    />
                  </div>
                }
              >
                <InfoOutline className='v-mid dib' style={{ height: '19px' }} />
              </Tooltip>
            </Label>
            <Field
              name='supplier.diversityProgram'
              component={Checkbox}
              labelFontLight
              label={
                <FormattedMessage
                  id='StandardAttributes.DiversityProgramLabel'
                  defaultMessage='Yes'
                />
              }
            />
          </div>

          <div className={styles.formColumn}>
            <FormattedMessage
              id='EditCompanyProfileHeader.LinkedInProfilePlaceholder'
              defaultMessage='LinkedIn profile eg. https://www.linkedin.com/company/3875906/'
            >
              {message =>
                renderFormField('supplier.links.linkedInUrl', 'Linkedin', {
                  placeholder: message,
                  validate: validateLinkedIn
                })
              }
            </FormattedMessage>

            <FormattedMessage
              id='EditCompanyProfileHeader.FacebookPlaceholder'
              defaultMessage='Facebook page eg. https://www.facebook.com/tealbook/'
            >
              {message =>
                renderFormField('supplier.links.facebookUrl', 'Facebook', {
                  placeholder: message,
                  validate: validateFacebook
                })
              }
            </FormattedMessage>

            <FormattedMessage
              id='EditCompanyProfileHeader.TwitterPlaceholder'
              defaultMessage='Twitter handle eg. @tealbook'
            >
              {message =>
                renderFormField('supplier.links.twitterId', 'Twitter', {
                  placeholder: message,
                  validate: validateTwitter,
                  normalize: normalizeTwitterUrl
                })
              }
            </FormattedMessage>

            {props.websiteWarning && (
              <>
                <Label htmlFor='search'>
                  <FormattedMessage
                    id='EditComanyProfileHeader.WebsiteWarning'
                    defaultMessage='Website Warning'
                  />
                </Label>
                <Field
                  name='supplier.websiteWarning'
                  component={Checkbox}
                  labelFontLight
                  label={startCase(props.websiteWarning)}
                  normalize={val => (!val ? '' : props.websiteWarning)}
                />
              </>
            )}
          </div>
        </div>

        {!isTealbot && website && (
          <div className={styles.footerMessage}>
            <FormattedMessage
              id='EditCompanyProfileHeader.ChangeWebsite'
              defaultMessage='If you would like to change your website please {contactUs}.'
              values={{
                contactUs: (
                  <span onClick={chatbot.open} className={styles.contactUsLink}>
                    <FormattedMessage
                      id='EditCompanyProfileHeader.ContactUs'
                      defaultMessage='contact us'
                    />
                  </span>
                )
              }}
            />
          </div>
        )}
      </form>
      <Dialog open={domainDialogOpen} disableBackdropClick>
        <DialogTitle>
          <FormattedMessage
            id='EditComanyProfileHeader.ANewDomainTitle'
            defaultMessage='Add a new domain'
          />
        </DialogTitle>
        <DialogContent>
          <Text className='mt3'>
            <FormattedMessage
              id='EditComanyProfileHeader.EnterNewDomainTitle'
              defaultMessage='Please enter the new domain name'
            />
          </Text>
          <Input value={newDomain} onChange={handleNewDomainChange} />
        </DialogContent>
        <DialogActions>
          <Button
            label={
              <FormattedMessage
                id='EditCompanyProfileHeader.AddButton'
                defaultMessage='Add'
              />
            }
            onClick={handleAddDomain}
            autoSize
          />
          <Button
            label={<FormattedMessage id='CancelButton' />}
            secondary
            onClick={toggleDomainDialogClose}
            autoSize
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={sanctionedCompanyDialogOpen}
        // disableBackdropClick
      >
        <DialogTitle>
          <FormattedMessage
            id='EditComanyProfileHeader.SanctionedCompanyWarningTitle'
            defaultMessage='Warning'
          />
        </DialogTitle>
        <DialogContent>
          <Text className='mt3'>
            <FormattedMessage
              id='EditComanyProfileHeader.SanctionedCompanyWarningMessage'
              defaultMessage="Are you sure this company isn't sanctioned?"
            />
          </Text>
        </DialogContent>
        <DialogActions>
          <Button
            label={
              <FormattedMessage
                id='EditCompanyProfileHeader.YesButton'
                defaultMessage='Yes'
              />
            }
            onClick={clearSanctionedCompany}
            autoSize
          />
          <Button
            label={<FormattedMessage id='No' />}
            secondary
            onClick={toggleSanctionClose}
            autoSize
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditCompanyProfileHeader
