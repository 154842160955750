import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'

const apiRoute = `${apiRoutes.authService}/tier2`

type Tier2SpendAllocation = {
  buyer: {
    id: string
    name: string
  }
  communityPlanMembershipId: string
  tier2?: Array<{
    quarter: number
    year: number
    overallAllocation: number
    diverseSupplierAllocations: Array<{
      id: string
      allocation: number
      spend: number
      name: string
    }>
  }>
}
export const getSpendAllocation = () =>
  api.get<Tier2SpendAllocation[]>(`${apiRoute}/allocated`).then(result =>
    result.map(item => ({
      communityPlanMembershipId: item.communityPlanMembershipId,
      buyerOrgUnitId: item.buyer.id,
      buyerName: item.buyer.name,
      allocatedSpend:
        item.tier2?.reduce((result, tier2Item) => {
          const aggYear = result[tier2Item.year] || {}
          return {
            ...result,
            [tier2Item.year]: {
              ...aggYear,
              [tier2Item.quarter]: tier2Item
            }
          }
        }, {}) || {}
    }))
  )

export const getSpendAllocationPreview = (previewData: {
  communityPlanMembershipId: string
  year: number
  quarter: number
  overallAllocation?: number
  diverseSupplierAllocations: Array<{
    id: string
    spend: number
    name: string
    allocation?: number
  }>
}) => api.post(`${apiRoute}/allocated/preview`, previewData)

export const tier2SupplierList = () => api.get(apiRoute)
export const exportTier2SupplierList = () =>
  api.get(apiRoute, { exportTable: true })

export const createTier2Community = () => api.post(`${apiRoute}/create`)

export const acceptTier2Invite = comId =>
  api.post(`${apiRoute}/accept/${comId}`)
export const declineTier2Invite = comId =>
  api.post(`${apiRoute}/decline/${comId}`)

export const inviteTier2ByGroup = (recentPercentile: any) => {
  return api.get(`${apiRoute}/invite`, recentPercentile)
}

export const getTier2Range = () => {
  return api.get(`${apiRoute}/overview/range`)
}

export const getTier2Agencies = () => {
  return api.get(`${apiRoute}/diverse/agencies`)
}

export const loadTier2Data = ({
  startYear,
  startQuarter,
  endYear,
  endQuarter,
  subCategories,
  certAgencies,
  countries
}) => {
  return api
    .post(`${apiRoute}/overview/details`, {
      startYear,
      startQuarter,
      endYear,
      endQuarter,
      subCategories,
      certAgencies,
      countries
    })
    .then(({ overview, uniqueTotalTier2Spend }) => {
      let subCategoriesMap = {}
      return {
        uniqueTotalTier2Spend,
        overview:
          overview &&
          overview.map(tier2Item => {
            if (subCategoriesMap[tier2Item.supplier.id]) {
              subCategoriesMap[tier2Item.supplier.id].push(tier2Item.tier2Type)
            } else {
              subCategoriesMap[tier2Item.supplier.id] = [tier2Item.tier2Type]
            }
            return {
              ...tier2Item,
              subCategories: subCategoriesMap[tier2Item.supplier.id]
            }
          })
      }
    })
}
