import React, { Component, ChangeEvent } from 'react'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import OrgLogo from 'shared/components/OrgLogo'
import { FormattedMessage } from 'react-intl'
import CameraIcon from '@material-ui/icons/CameraEnhance'
import Button from '@material-ui/core/Button'
import UploadImageDialog from 'shared/components/UploadImageDialog'
import { supportedLanguages } from '../../../../i18n'
import { Map } from 'immutable'
import Select from 'shared/components/Select'

type Props = {
  name?: string
  description: Map<string, string>
  longDescription: Map<string, string>
  logoUrl?: string
  language: string
  saveCompanyInfo: (info: {
    name?: string
    description?: Object
    longDescription?: Object
  }) => void
  uploadFile: ({ fieldName: string, file: any }) => void
}

type State = {
  openUploadDialog: boolean
  file: File | null
  companyName: string
  description: Map<string, string>
  longDescription: Map<string, string>
  currentDescriptionLanguage: string
}

class OnboardingCompany extends Component<Props, State> {
  state: State = {
    openUploadDialog: false,
    file: null,
    companyName: this.props.name || '',
    description: this.props.description,
    longDescription: this.props.longDescription,
    currentDescriptionLanguage: this.props.language
  }

  handleUpload = (fieldName: string) => {
    const { file } = this.state
    if (file) {
      this.props.uploadFile({ fieldName, file })
    }
    this.setState({ openUploadDialog: false })
  }

  componentWillUnmount() {
    let changedValues: {
      name?: string
      description?: { [key: string]: string }
      longDescription?: { [key: string]: string }
    } = {}

    if (this.props.name !== this.state.companyName) {
      changedValues.name = this.state.companyName
    }
    if (this.props.description !== this.state.description) {
      changedValues.description = this.state.description.toJSON()
    }
    if (this.props.longDescription !== this.state.longDescription) {
      changedValues.longDescription = this.state.longDescription.toJSON()
    }

    this.props.saveCompanyInfo(changedValues)
  }

  render() {
    const {
      openUploadDialog,
      description,
      longDescription,
      currentDescriptionLanguage
    } = this.state

    return (
      <>
        <div className='db w-100 ph3-5 pv3'>
          <div className='dib w-100 w-30-ns v-top'>
            <Label>
              <FormattedMessage
                id='OnboardingCompany.Logo'
                defaultMessage='Your company logo'
              />
            </Label>
            <div
              className='pa3 pa0-ns flex items-center bg-white br2 tc relative'
              style={{
                height: '150px',
                width: '150px',
                minWidth: '150px',
                maxHeight: '150px'
              }}
            >
              <OrgLogo name={this.state.companyName} url={this.props.logoUrl} />
              <Button
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0
                }}
                variant='contained'
                onClick={() => this.setState({ openUploadDialog: true })}
              >
                <CameraIcon />
              </Button>
            </div>
          </div>
          <div className='dib w-100 w-70-ns v-top'>
            <Select
              onChange={e =>
                this.setState({
                  currentDescriptionLanguage: e.currentTarget.value
                })
              }
              value={currentDescriptionLanguage}
            >
              {supportedLanguages.map(({ key, title }) => (
                <option key={key} value={key}>
                  {title}
                </option>
              ))}
            </Select>
            <Label>
              <FormattedMessage
                id='OnboardingCompany.Name'
                defaultMessage='Your company name'
              />
            </Label>
            <Input
              value={this.state.companyName}
              name='name'
              onChange={e =>
                this.setState({
                  companyName: e.target.value
                })
              }
            />
            <Label>
              <FormattedMessage
                id='OnboardingCompany.ShortDescription'
                defaultMessage='Short description'
              />
            </Label>
            <TextArea
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                this.setState({
                  description: description.set(
                    currentDescriptionLanguage,
                    e.target.value
                  )
                })
              }}
              value={description.get(currentDescriptionLanguage) || ''}
              name='description'
              maxLength={200}
              height={55}
            />
            <Label>
              <FormattedMessage
                id='OnboardingCompany.LongDescription'
                defaultMessage='Long description'
              />
            </Label>
            <TextArea
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                this.setState({
                  longDescription: longDescription.set(
                    currentDescriptionLanguage,
                    e.target.value
                  )
                })
              }}
              value={longDescription.get(currentDescriptionLanguage) || ''}
              name='longDescription'
              maxLength={2000}
            />
          </div>
        </div>
        <UploadImageDialog
          open={openUploadDialog}
          title={<FormattedMessage id='OnboardingCompany.Logo' />}
          label={
            <FormattedMessage
              id='OnboardingCompany.UploadLogo'
              defaultMessage='Upload your company logo'
            />
          }
          imageAttributes={{
            logo: true,
            imageUrl: this.props.logoUrl
          }}
          fieldName='logo'
          onChange={file => this.setState({ file })}
          onCancel={() => this.setState({ openUploadDialog: false })}
          onUpload={this.handleUpload}
        />
      </>
    )
  }
}

export default OnboardingCompany
