import { fromJS, List, Map } from 'immutable'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import { createSelector } from 'reselect'
import RootState from 'shared/models/RootState'
import CertificationCategories from 'shared/models/CertificationCategories'

const availableCertifications = Object.keys(CertificationCategories)

export const getFilterOptions = createSelector(
  (state: RootState) => state.getIn(['buyer', 'filterOptions']),
  filterOptions => {
    return filterOptions
      .delete('scope')
      .delete('sort')
      .delete('certificationExpiredExcluded')
      .delete('attachmentsExpiredExcluded')
      .delete('similarSuppliers')
  }
)

export const getFilterOption = (state, key) => {
  return getFilterOptions(state).has(key)
    ? getFilterOptions(state).get(key)
    : key === 'scope'
    ? getFilterScope(state)
    : List([])
}

export const getFilterOptionLevels = createSelector(
  (state, firstLevelKey, secondLevelKey, thirdLevelKey) => [
    firstLevelKey,
    secondLevelKey,
    thirdLevelKey,
    getFilterOption(state, firstLevelKey),
    getFilterOption(state, secondLevelKey),
    getFilterOption(state, thirdLevelKey)
  ],
  ([
    firstLevelKey,
    secondLevelKey,
    thirdLevelKey,
    selectedFirstLevel,
    selectedSecondLevel,
    selectedThirdLevel
  ]: [
    string,
    string,
    string,
    List<string | List<string>>,
    List<string | List<string>>,
    List<string | List<string>>
  ]) => {
    const isOr =
      List.isList(selectedFirstLevel.first()) ||
      (!!selectedSecondLevel && List.isList(selectedSecondLevel.first())) ||
      (!!selectedThirdLevel && List.isList(selectedThirdLevel.first()))

    return isOr
      ? Map({
          [firstLevelKey]: selectedFirstLevel.get(0)
            ? selectedFirstLevel.get(0)
            : List([]),
          [secondLevelKey]:
            selectedSecondLevel.size > 0
              ? selectedSecondLevel.get(0)
              : List([]),
          [thirdLevelKey]:
            selectedThirdLevel.size > 0 ? selectedThirdLevel.get(0) : List([])
        })
      : Map({
          [firstLevelKey]: List([]),
          [secondLevelKey]: List([]),
          [thirdLevelKey]: List([])
        })
  }
)

export const getFilterOptionCommunities = state => {
  return getFilterOption(state, 'communities')
}
export const getFilterScope = state => {
  return state.getIn(['buyer', 'filterOptions', 'scope']) || 'All'
}
export const getSort = state => {
  const defaultRelevance = settingsSelectors.getDefaultRelevance
  return (
    state.getIn(['buyer', 'filterOptions', 'sort']) ||
    defaultRelevance ||
    'Relevance'
  )
}
export const getByKey = (state, key) => {
  return state.getIn(['buyer', 'filterOptions', key])
}

export const getCertificationExpiredExcludedForSummary = createSelector(
  (state: RootState) =>
    state.getIn(['buyer', 'filterOptions', 'certificationExpiredExcluded']),
  getFilterOptions,
  (filteredCertExpiredPotential, filters) =>
    // check queryString for certificationExpiredExcluded.
    // search will have all available certifications pre-populated
    // only want to show 'Include Expired and Potential' pill if selected certification isn't in certificationExpiredExcluded array, and certification has been selected by user
    fromJS(
      availableCertifications.filter(
        cert =>
          !!filters.size &&
          filters.get(cert) &&
          !filteredCertExpiredPotential?.includes(cert)
      )
    )
)

export const isActive = state => {
  const filters = getFilterOptions(state)
  const certificationExpiredExcluded = getByKey(
    state,
    'certificationExpiredExcluded'
  )
  const attachmentsExpiredExcluded = getByKey(
    state,
    'attachmentsExpiredExcluded'
  )
  const similarSuppliers = getByKey(state, 'similarSuppliers')

  return (
    (filters && filters.size > 0) ||
    (certificationExpiredExcluded && certificationExpiredExcluded.size > 0) ||
    attachmentsExpiredExcluded ||
    (similarSuppliers && similarSuppliers.size > 0)
  )
}
