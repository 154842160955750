import React, { Component } from 'react'
import { Field, formValueSelector } from 'redux-form/immutable'
import { createSelector } from 'reselect'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import dataSelectors from 'shared/selectors/dataSelectors'
import { closeGetVerifiedDialog, getVerified } from '../../actions'
import Popover from '@material-ui/core/Popover'
import { FormattedMessage } from 'react-intl'
import { fromJS } from 'immutable'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form/immutable'
import { InjectedFormProps } from 'redux-form'
import RootState from 'shared/models/RootState'
import { List } from 'immutable'

type Props = {
  domains: List<string>
  email: string
  emailWebDomain: List<string>
  getVerified: (values) => void
  onCancel: () => void
} & InjectedFormProps

type State = {
  anchorEl: HTMLElement | null
  openEmailSuggestion: boolean
}

export class GetVerifiedForm extends Component<Props, State> {
  state: State = {
    anchorEl: null,
    openEmailSuggestion: false
  }

  handleChangeEmailValue = email => {
    const { change } = this.props
    change('user.email', email)
    this.setState({
      openEmailSuggestion: false
    })
  }

  verifyEmail = (value, allValues) => {
    const { domains } = this.props

    return domains &&
      !domains.some(domain => {
        const parsedDomain = domain.split('@')
        return (
          value &&
          (value
            .toUpperCase()
            .endsWith(
              `@${parsedDomain[parsedDomain.length - 1].toUpperCase()}`
            ) ||
            value
              .toUpperCase()
              .endsWith(
                `.${parsedDomain[parsedDomain.length - 1].toUpperCase()}`
              ))
        )
      }) ? (
      <FormattedMessage
        id='GetVerifiedForm.EmailShouldMatchTheDomainOfTheCompany'
        defaultMessage='Email should match the domain of the company'
      />
    ) : (
      undefined
    )
  }

  render() {
    const {
      domains,
      getVerified,
      handleSubmit,
      onCancel,
      email,
      emailWebDomain
    } = this.props

    const parsedDomains =
      domains && domains.map((domain: string) => domain.split('@').pop())

    return (
      <form
        onSubmit={handleSubmit(getVerified)}
        autoComplete='off'
        className='mb3'
      >
        <Label required htmlFor='user.firstName'>
          <FormattedMessage
            id='GetVerifiedForm.FirstName'
            defaultMessage='First Name'
          />
        </Label>
        <Field
          component={Input}
          name='user.firstName'
          required
          autoComplete='new-user-first-name'
        />

        <Label required htmlFor='user.lastName'>
          <FormattedMessage
            id='GetVerifiedForm.LastName'
            defaultMessage='Last Name'
          />
        </Label>
        <Field
          component={Input}
          name='user.lastName'
          required
          autoComplete='new-user-last-name'
        />

        <Label
          required
          htmlFor={
            emailWebDomain && emailWebDomain.size < 1
              ? 'user.email'
              : 'user[email]'
          }
        >
          <FormattedMessage id='GetVerifiedForm.Email' defaultMessage='Email' />
        </Label>
        {emailWebDomain && emailWebDomain.size < 1 && (
          <Field
            autoComplete='off'
            component={Input}
            name='user.email'
            type='email'
            validate={this.verifyEmail}
            required
            onChange={(e: any) => {
              const newEmail = e.currentTarget.value
              this.setState({
                anchorEl: e.currentTarget,
                openEmailSuggestion: !!newEmail && newEmail.endsWith('@')
              })
            }}
          />
        )}

        {emailWebDomain && emailWebDomain.size > 0 && (
          <Field
            component={Input}
            name='user[email]'
            value='user[email]'
            disabled
          />
        )}

        {emailWebDomain &&
          emailWebDomain.size < 1 &&
          parsedDomains &&
          parsedDomains.size > 0 && (
            <Popover
              open={this.state.openEmailSuggestion}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              disableAutoFocus
              disableEnforceFocus
              onClose={() =>
                this.setState({
                  openEmailSuggestion: false
                })
              }
            >
              {parsedDomains.map(domain => (
                <div
                  key={domain}
                  className='pa2 dim pointer'
                  onClick={() =>
                    this.handleChangeEmailValue(`${email}${domain}`)
                  }
                >
                  <Text>{`${email}${domain}`}</Text>
                </div>
              ))}
            </Popover>
          )}

        {emailWebDomain && emailWebDomain.size < 1 && domains && (
          <Text className='gray i'>
            {
              <FormattedMessage
                id='GetVerifiedForm.ValidEmail'
                defaultMessage='Valid email'
              />
            }{' '}
            {`${domains.size > 1 ? 'domains' : 'domain'}: ${domains
              .map(domain => `@${domain}`)
              .join(', ')}`}
          </Text>
        )}

        <Label htmlFor='message'>
          <FormattedMessage
            id='GetVerifiedForm.Message'
            defaultMessage='Message (Link to your company profile will be inserted automatically)'
          />
        </Label>
        <Field component={TextArea} name='message' id='message' />

        <Button
          label={
            <FormattedMessage
              id='GetVerifiedForm.SendButton'
              defaultMessage='Send'
            />
          }
          type='submit'
          autoSize
          className='mr3 mt3'
        />

        <Button
          label={<FormattedMessage id='CancelButton' />}
          autoSize
          secondary
          onClick={onCancel}
        />
      </form>
    )
  }
}

const formName = 'supplier/company/getVerified'
const formSelector = formValueSelector(formName)

export const getSupplierRelationshipOfOrgUnitToVerify = createSelector(
  (state: RootState) =>
    dataSelectors.getByEntity(state, 'supplierRelationships'),
  (state: RootState, orgUnitId: string) => orgUnitId,
  (supplierRelationships, orgUnitId) =>
    supplierRelationships.toList().find(item => item.get('buyer') === orgUnitId)
)

export default connect(
  (state: RootState) => {
    const verifyingOrgUnitId = state.getIn([
      'supplier',
      'company',
      'verifyingOrgUnitId'
    ])
    const client = dataSelectors.getById(state, 'orgUnits', verifyingOrgUnitId)
    const supplierRelationship =
      getSupplierRelationshipOfOrgUnitToVerify(state, verifyingOrgUnitId) ||
      fromJS({})

    const contactName = supplierRelationship
      .getIn(['supplierCollaboration', 'contactName'], '')
      .split(' ')
    const domains = client.get('domains')
    const emailWebDomain =
      domains && domains.filter(domain => domain.includes('@'))
    return {
      emailWebDomain,
      domains,
      email: formSelector(state, 'user.email'),
      initialValues: {
        orgUnitId: verifyingOrgUnitId,
        orgUnitName: client.get('name'),
        user: {
          firstName: contactName[0],
          lastName: contactName[contactName.length - 1],
          email:
            emailWebDomain && emailWebDomain.size > 0
              ? emailWebDomain.get(0)
              : supplierRelationship.getIn([
                  'supplierCollaboration',
                  'contactEmail'
                ])
        },
        supplierRelationshipId: supplierRelationship.get('id'),
        message:
          'Please visit my company profile to confirm our business relationship'
      }
    }
  },
  {
    onCancel: closeGetVerifiedDialog,
    getVerified
  }
)(
  reduxForm({
    form: formName
  })(GetVerifiedForm)
)
