import React from 'react'
import { Route, Switch, Redirect } from 'react-router'
import { NavLink } from 'shared/components/AppBar'
import Divider from 'shared/components/Divider'
import NavBar from 'shared/components/NavBar'
import paths from '../../routes/paths'
import DataQualityServiceUpload from './DataQualityServiceUpload'
import DataQualityServiceResults from './DataQualityServiceResults'

const DataQualityService = () => {
  return (
    <>
      <div className='bg-white pl4'>
        <NavBar className='mw8 center'>
          <NavLink
            label='Upload'
            to={paths.dataQualityService}
            isActive={(match, location) =>
              location.pathname === paths.dataQualityService
            }
          />
          <NavLink label='Results' to={paths.dataQualityServiceResults} />
        </NavBar>
      </div>

      <Divider />

      <Switch>
        <Route
          exact
          path={paths.dataQualityService}
          component={DataQualityServiceUpload}
        />
        <Route
          exact
          path={paths.dataQualityServiceResults}
          component={DataQualityServiceResults}
        />
        <Redirect from={paths.basePath} to={paths.dataQualityService} />
      </Switch>
    </>
  )
}

export default DataQualityService
