import React, { memo, useMemo, useState, useEffect } from 'react'
import Paper from 'shared/components/Paper'
import { FormattedMessage } from 'react-intl'
import Link from 'shared/components/Link'
import OrgLogo from 'shared/components/OrgLogo'
import Text from 'shared/components/Text'
import parsePath from 'shared/utils/parsePath'
import buyerPaths from 'buyer/routes/paths'
import { useHistory } from 'react-router'
import useQuery from 'shared/utils/useQuery'
import qs from 'qs'
import Checkbox from 'shared/components/Checkbox'
import SearchFilter from 'shared/models/SearchFilter'
import ImageArrowDown from 'shared/assets/icons/arrow-down.svg'
import ImageArrowUp from 'shared/assets/icons/arrow-up.svg'
import { Collapse } from '@material-ui/core'
import { useSelector } from 'react-redux'
import collectionSelectors from 'buyer/Search/selectors/collectionSelectors'
import { Map } from 'immutable'
import storageManager from 'shared/utils/storageManager'

type Props = {
  collection?: Map<string, any>
}

const PunchoutInitialCart = memo((props: Props) => {
  const history = useHistory()
  const query = useQuery<SearchFilter>() || {}
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const similarSuppliers = useMemo(() => query.similarSuppliers || [], [
    query.similarSuppliers
  ])
  const similarSuppliersById = useMemo(
    () =>
      similarSuppliers.reduce(
        (result, supplierId) => ({
          ...result,
          [supplierId]: true
        }),
        {}
      ),
    [similarSuppliers]
  )

  const hasCollection = props.collection && props.collection.size > 0

  useEffect(() => {
    if (hasCollection) {
      setIsCollapsed(true)
    }
  }, [hasCollection])

  const initialCart = useMemo(
    () => storageManager.getItem('punchout')?.cart || [],
    []
  )

  const handleChangeCheckbox = (orgUnitId: string, checked: boolean) => {
    const newQuery = {
      ...query,
      similarSuppliers: checked
        ? similarSuppliers.concat(orgUnitId)
        : similarSuppliers.filter(id => id !== orgUnitId)
    }

    history.push({
      search: qs.stringify(newQuery)
    })
  }

  return (
    <Paper className='mb4 pt3-5'>
      <div
        className='f7 fw6 mb2 flex items-center pointer'
        onClick={() =>
          initialCart.length > 0 && setIsCollapsed(collapsed => !collapsed)
        }
      >
        <FormattedMessage
          id={'PunchoutInitialCart.suppliersMatching'}
          defaultMessage={
            '{numberOfSuppliers} Suppliers found matching the criteria'
          }
          values={{
            numberOfSuppliers: initialCart.length
          }}
        />

        {initialCart.length > 0 && (
          <div className='flex-auto tr'>
            <img
              src={!isCollapsed ? ImageArrowUp : ImageArrowDown}
              alt='Collapse'
            />
          </div>
        )}
      </div>

      {initialCart.length > 0 && (
        <Collapse in={!isCollapsed}>
          <Text className='mb3'>
            <FormattedMessage
              id='PunchoutInitialCart.CheckSuppliers'
              defaultMessage='Check the suppliers to include in your search'
            />
          </Text>

          {initialCart.map(orgUnit => {
            const profileUrl = `${parsePath(buyerPaths.supplierProfile, {
              supplierId: orgUnit.id
            })}`
            const logoUrl = orgUnit.logoUrl
            const checked = !!similarSuppliersById[orgUnit.id]
            return (
              <div key={orgUnit.id} className='pointer mb3 flex items-center'>
                <Checkbox
                  checked={checked}
                  onChange={() => handleChangeCheckbox(orgUnit.id, !checked)}
                />
                <div
                  className='logo pa1 ba b--black-10 br2 tc bg-white ml2'
                  style={{ height: '50px', width: '50px' }}
                >
                  <Link to={profileUrl}>
                    <OrgLogo url={logoUrl} />
                  </Link>
                </div>
                <div className='mid-gray mw4-5-l mw6-m mw4-5 pl2'>
                  <Link to={profileUrl}>
                    <h5 className='name f7 fw3 mid-gray ma0 truncate'>
                      {orgUnit.name}
                    </h5>
                  </Link>
                </div>
              </div>
            )
          })}

          {/* <Divider className='mb3' /> */}
          {/* <div className='db mt2'>
            <Button
              secondary
              label={
                <FormattedMessage
                  id='CollectionContainer.FindSimilarSupplier'
                  defaultMessage='Find similar suppliers'
                />
              }
              onClick={handleClickFindSimilarSuppliers}
            />
          </div> */}
        </Collapse>
      )}
    </Paper>
  )
})

const PunchoutInitialCartContainer = () => {
  const collection: Map<string, any> = useSelector(
    collectionSelectors.getCollection
  )
  return <PunchoutInitialCart collection={collection} />
}

export default PunchoutInitialCartContainer
