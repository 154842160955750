import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import createRootReducer from './createRootReducer'
import {
  routerMiddleware,
  ConnectedRouter
} from 'connected-react-router/immutable'
import ConnectedIntlProvider from './i18n/ConnectedIntlProvider'
import configureStore from './shared/utils/configureStore'
import sagas from './sagas'

import AppContainer from './shared/containers/AppContainer'
import ErrorBoundary from './shared/components/ErrorBoundary'
import { contactsUpload, saveMobileContacts } from './accounts/actions'

import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import logger from './shared/utils/logger'
import CustomMuiThemeProvider from './shared/components/CustomMuiThemeProvider'

// third party libs
import 'react-virtualized/styles.css'
import 'tachyons/css/tachyons.css'
import 'tachyons/src/_word-break.css'

// global css
import './styles/app.css'
import Zendesk, { ZendeskAPI } from 'react-zendesk'
const ZENDESK_KEY = '945dd15a-83f2-4787-9701-07071996ae14'

logger.setup()
const history = createBrowserHistory()

// async middlewares
const sagaMiddleware = createSagaMiddleware()

const store = configureStore(createRootReducer(history), undefined, [
  sagaMiddleware,
  routerMiddleware(history)
])

// allow the mobile-app to dispatch action to app (for contacts upload)
window.mobileEntryStore = store
window.mobileContactsUpload = contactsUpload
window.saveMobileContacts = saveMobileContacts

sagaMiddleware.run(sagas)
store.runSaga = sagaMiddleware.run

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <CustomMuiThemeProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ConnectedIntlProvider>
            <ErrorBoundary>
              <AppContainer />
            </ErrorBoundary>
          </ConnectedIntlProvider>
        </MuiPickersUtilsProvider>
      </CustomMuiThemeProvider>
    </ConnectedRouter>
    <Zendesk
      zendeskKey={ZENDESK_KEY}
      onLoaded={() => {
        ZendeskAPI('webWidget', 'hide')
        ZendeskAPI('webWidget:on', 'close', function() {
          ZendeskAPI('webWidget', 'hide')
          const tealbotSupport: HTMLDivElement | null = document.querySelector(
            '#tealbotSupport'
          )
          if (tealbotSupport) {
            tealbotSupport.style.opacity = '1'
          }
        })
      }}
    />
  </Provider>,
  document.getElementById('root')
)

// reload the app if another tab changed their session status
window.addEventListener('storage', e => {
  const oldValue = e.oldValue
  const newValue = e.newValue
  if (
    e.key === 'accessToken' &&
    ((!oldValue && newValue) || (oldValue && !newValue))
  ) {
    window.location.reload()
  }
})
