import React, { Component } from 'react'
import { connect } from 'react-redux'
import statsSelectors from '../../../shared/selectors/statsSelectors'
import Avatar from 'shared/components/Avatar'
import Text from 'shared/components/Text'
import withUser from 'shared/utils/withUser'
import ConnectedTo from './ConnectedTo'
import Divider from 'shared/components/Divider'
import { FormattedMessage } from 'react-intl'
import { RecordOf, List } from 'immutable'

type Props = {
  user: RecordOf<{
    avatarUrl: string
    fullName: string
    title: string
    connectedTo: List<string>
  }>
} & ContainerProps

const ConnectedToWithUser = withUser(ConnectedTo)

export class ClientUserDetail extends Component<Props> {
  render() {
    const { user } = this.props

    return user ? (
      <div className='pa3'>
        <div className='dt w-100'>
          {console.log(user)}
          <div className='v-mid w2 h2 dtc'>
            <Avatar url={user.get('avatarUrl')} name={user.get('fullName')} />
          </div>
          <div className='v-mid dtc mid-gray pl2'>
            <h5 className='ma0 f5 fw4'>{user.get('fullName')}</h5>
            {user.get('title') && <Text>{user.get('title')}</Text>}
          </div>
        </div>
        <Divider className='mv2' />
        <div className='mt2 mb1 f7'>
          <FormattedMessage
            id='ClientUserDetail.ConnectToTheFollowingColleagues'
            defaultMessage='Connects to the following colleagues:'
          />
        </div>
        {user
          .get('connectedTo')
          .toList()
          .map(user => (
            <ConnectedToWithUser key={user} userId={user} />
          ))}
      </div>
    ) : null
  }
}

type ContainerProps = {
  clientId: string
  clientUserId: string
}

export default connect((state, props: ContainerProps) => ({
  user: statsSelectors.getClientUser(state, props.clientId, props.clientUserId)
}))(ClientUserDetail)
