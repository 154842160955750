import React from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes/paths'
import { FormattedMessage } from 'react-intl'

const TechConfigNav = () => {
  return (
    <NavBar>
      <NavLink
        label={
          <FormattedMessage
            id='TechConfigNav.Settings'
            defaultMessage='Settings'
          />
        }
        to={paths.techConfig}
        exact
      />
      <NavLink
        label={
          <FormattedMessage
            id='TechConfigNav.IntegrationPartner'
            defaultMessage='Partner Configuration'
          />
        }
        to={paths.techWebhooks}
        exact
      />
    </NavBar>
  )
}

export default TechConfigNav
