import * as actionTypes from '../../actionTypes'
import { UPLOAD_SPEND_FILE } from 'shared/actionTypes'
import { requestSuccess, requestFailure } from 'shared/actionTypes'
import { fromJS } from 'immutable'

const initialState = fromJS({
  isUploading: false,
  uploadError: false
})

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_CLIENT_FILE:
    case actionTypes.GENERATE_USER_TOKEN:
    case actionTypes.BULK_ADD_USERS:
    case UPLOAD_SPEND_FILE:
      return state.merge(
        fromJS({
          isUploading: true,
          uploadError: false
        })
      )

    case requestSuccess(actionTypes.UPLOAD_CLIENT_FILE):
    case requestSuccess(actionTypes.GENERATE_USER_TOKEN):
    case requestSuccess(actionTypes.BULK_ADD_USERS):
    case requestSuccess(UPLOAD_SPEND_FILE):
      return state.set('isUploading', false)

    case requestFailure(actionTypes.UPLOAD_CLIENT_FILE):
    case requestFailure(actionTypes.GENERATE_USER_TOKEN):
    case requestFailure(actionTypes.BULK_ADD_USERS):
    case requestFailure(UPLOAD_SPEND_FILE):
      return state.merge(
        fromJS({
          isUploading: false,
          uploadError: true
        })
      )

    default:
      return state
  }
}

export default uploadReducer
