import React, { Component } from 'react'
import { List, RecordOf } from 'immutable'
import ViewAnswerContainer from '../../containers/ViewAnswer'

type Props = {
  membershipId: string
  pageQuestions: List<RecordOf<{ question: string }>>
}

class PageAnswers extends Component<Props> {
  render() {
    const { pageQuestions, membershipId } = this.props
    return (
      <div>
        {pageQuestions &&
          pageQuestions.size > 0 &&
          pageQuestions.map(question => {
            const questionId = question.get('question')
            return (
              <ViewAnswerContainer
                key={questionId}
                membershipId={membershipId}
                questionId={questionId}
              />
            )
          })}
      </div>
    )
  }
}

export default PageAnswers
