import React, { Component } from 'react'
import { connect } from 'react-redux'
import profileSelectors from '../../selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import AttachmentCardContainer from 'shared/containers/AttachmentCardContainer'
import { removeRelationshipAttachment } from '../../actions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf, Map } from 'immutable'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'

type Props = {
  removeAttachment: (arg: string) => void
  userId: string
  attachments: List<
    RecordOf<{
      createdBy: string
      createdDate: Date
      documentFileUrl?: string
      expirationDate: Date
      type: string
      note: string
    }>
  >
  canRemoveAttachment: boolean
  onlyMine?: boolean
  attachmentTypes: Map<string, string>
}
export class RelationshipAttachments extends Component<Props> {
  state = {
    removeAttachmentDialog: {
      isOpen: false,
      attachment: '',
      message: ''
    }
  }

  handleCloseDialogRequest = () => {
    this.setState({
      removeAttachmentDialog: {
        isOpen: false
      }
    })
  }

  handleRemoveAttachment = () => {
    const { removeAttachment } = this.props
    const {
      removeAttachmentDialog: { attachment }
    } = this.state
    removeAttachment(attachment)
    this.setState({
      removeAttachmentDialog: {
        isOpen: false
      }
    })
  }

  confirmDeletion = a => {
    this.setState({
      removeAttachmentDialog: {
        isOpen: true,
        attachment: a,
        message: `Are you sure you want to delete "${a.get('type')}"?`
      }
    })
  }

  render() {
    const {
      userId,
      attachments,
      onlyMine,
      canRemoveAttachment,
      attachmentTypes
    } = this.props
    const {
      removeAttachmentDialog: { isOpen, message }
    } = this.state

    // filter for current user attachments in order to display to Personal Page
    const filteredAttachments = onlyMine
      ? attachments.filter(user => user.get('createdBy') === userId)
      : attachments
    return (
      <div>
        {filteredAttachments &&
          filteredAttachments.map((a, index) => {
            const label =
              attachmentTypes &&
              attachmentTypes.has(a.get('type')) &&
              attachmentTypes.getIn([a.get('type'), 'label'])
            return (
              <AttachmentCardContainer
                key={index}
                type={a.get('type')}
                label={label}
                expirationDate={a.get('expirationDate')}
                attachmentUrl={a.get('documentFileUrl')}
                note={a.get('note')}
                createdDate={a.get('createdDate')}
                createdByProfileUrl={
                  a.get('createdBy') &&
                  parsePath(paths.userprofile, { userId: a.get('createdBy') })
                }
                userId={a.get('createdBy')}
                onRemove={
                  a.get('createdDate') &&
                  (canRemoveAttachment || userId === a.get('createdBy'))
                    ? () => this.confirmDeletion(a)
                    : undefined
                }
              />
            )
          })}

        <Dialog open={isOpen} onClose={this.handleCloseDialogRequest}>
          <DialogTitle>
            <FormattedMessage
              id='RelationshipAttachments.Confirmation'
              defaultMessage='Confirmation'
            />
          </DialogTitle>
          <DialogContent className='mt3'>{message}</DialogContent>
          <DialogActions>
            <Button
              label={
                <FormattedMessage
                  id='RelationshipAttachments.Ok'
                  defaultMessage='OK'
                />
              }
              type='button'
              onClick={this.handleRemoveAttachment}
              autoSize
            />
            <Button
              label={<FormattedMessage id='CancelButton' />}
              onClick={this.handleCloseDialogRequest}
              secondary
              autoSize
            />
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export const mapStateToProps = state => {
  const relationship = profileSelectors.getRelationship(state)
  return {
    attachments: relationship && relationship.get('attachments'),
    canRemoveAttachment: sessionSelectors.userHasRole(state, 'buyerDataAdmin'),
    userId: sessionSelectors.getUserId(state),
    attachmentTypes: settingsSelectors.getAttachmentTypes(state)
  }
}

export default connect(mapStateToProps, {
  removeAttachment: removeRelationshipAttachment
})(RelationshipAttachments)
