import React, { Component, Fragment } from 'react'
import Logo from 'shared/components/OrgLogo'
import { withStyles } from '@material-ui/core/styles'
import CameraIcon from '@material-ui/icons/CameraEnhance'
import MaterialButton from '@material-ui/core/Button'
import UploadImageDialog from 'shared/components/UploadImageDialog'
import EditProfileButton from 'shared/components/EditProfileButton'
import AutoSaveInput from 'shared/components/AutoSaveInput'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import Switch from 'shared/components/Switch'
import { FormattedMessage } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import imgCheck from 'shared/assets/icons/check.svg'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import SearchIcon from '@material-ui/icons/Search'
import Checkbox from 'shared/components/Checkbox'
import Tooltip from 'shared/components/Tooltip'

type Props = {
  name: string
  description: string
  longDescription?: string
  logoUrl?: string
  isTealbot?: boolean
  communityId?: string
  supplierIsMember?: boolean
  isPrivate?: boolean
  supplierSearchable?: boolean
  allowInterSupplierPosting?: boolean
  inviteExisting?: ({ communityId: string, sendEmail: boolean }) => void
  onUpdate?: ({ logo: string }) => void
  onLeaveCommunity?: () => void
  onJoinCommunity?: () => void
}

const MaterialButtonWithStyles = withStyles({
  root: {
    minWidth: 'auto'
  },
  label: {
    textTransform: 'none'
  }
})(MaterialButton)

export class CommunityHeader extends Component<Props> {
  state = {
    editMode: false,
    openUploadImageDialog: false,
    openInviteAllDialog: false,
    file: '',
    sendEmail: true
  }

  toggleUploadImageDialog = () => {
    this.setState({
      openUploadImageDialog: !this.state.openUploadImageDialog
    })
  }

  handleUploadImage = () => {
    const { onUpdate } = this.props
    const { file } = this.state

    if (file && onUpdate) {
      onUpdate({ logo: file })
    }

    this.toggleUploadImageDialog()
  }

  handleFileChange = file => {
    this.setState({
      file
    })
  }

  handleInviteAll = () => {
    if (this.props.communityId && this.props.inviteExisting) {
      this.props.inviteExisting({
        communityId: this.props.communityId,
        sendEmail: this.state.sendEmail
      })
      this.toggleInviteAllDialog()
    }
  }

  toggleEditMode = () => {
    this.setState({
      editMode: !this.state.editMode
    })
  }

  handleSave = changes => {
    const { onUpdate } = this.props

    if (changes && onUpdate) {
      onUpdate(changes)
    }
  }

  toggleInviteAllDialog = () => {
    const { openInviteAllDialog } = this.state
    this.setState({ openInviteAllDialog: !openInviteAllDialog })
  }

  render() {
    const {
      logoUrl,
      name,
      isPrivate,
      supplierSearchable,
      allowInterSupplierPosting,
      description,
      longDescription,
      isTealbot,
      supplierIsMember,
      onJoinCommunity,
      onLeaveCommunity,
      onUpdate
    } = this.props
    const {
      openUploadImageDialog,
      openInviteAllDialog,
      editMode,
      sendEmail
    } = this.state

    return (
      <section className='bg-white pb4 pb4-ns'>
        <div className='mw8 w-100 center dt border-box nt5-ns'>
          <div className='nt4-ns dt tc w-100 mw8-ns ph4 ph3-ns ph4-m'>
            <div className='pr4-ns pr0 dtc-ns pt6-ns dib'>
              <div
                className='pa3 pa0-ns flex items-center bg-white br2 tc relative'
                style={{
                  height: '150px',
                  width: '150px',
                  minWidth: '150px',
                  maxHeight: '150px'
                }}
              >
                <Logo url={logoUrl} square />
                {isTealbot && onUpdate && (
                  <MaterialButtonWithStyles
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0
                    }}
                    variant='contained'
                    onClick={() => this.toggleUploadImageDialog()}
                  >
                    <CameraIcon />
                  </MaterialButtonWithStyles>
                )}
              </div>
            </div>
            <div className='dtc-ns mt0-ns v-top tl w-100 pt6-ns pt3'>
              <div className='w-100 dt'>
                <h5 className='f3 mid-gray ma0 cf'>
                  {editMode ? (
                    <div className='dib mt1 lh-solid'>
                      <Label noPadding>
                        <FormattedMessage
                          id='CommunityHeader.Community'
                          defaultMessage='Community'
                        />
                      </Label>
                      <FormattedMessage
                        id='CommunityHeader.PlaceholderCommunityName'
                        defaultMessage={`Enter the community's name`}
                      >
                        {community => (
                          <AutoSaveInput
                            className='w-100 w5-ns'
                            component={Input}
                            maxLength={50}
                            value={name}
                            name='name'
                            placeholder={community}
                            onSave={this.handleSave}
                          />
                        )}
                      </FormattedMessage>
                    </div>
                  ) : (
                    <div className='dib tr-ns mt1 lh-title'>
                      {name}
                      <br />

                      <span className='f9 v-mid flex items-center justify-end'>
                        {supplierSearchable && (
                          <SearchIcon style={{ fontSize: 18 }} />
                        )}
                        {isPrivate ? (
                          <>
                            <span style={{ textAlign: 'left' }}>
                              {supplierSearchable && (
                                <Tooltip
                                  title={
                                    <FormattedMessage
                                      id='CommunityHeader.CooperativeDescription'
                                      defaultMessage='Suppliers share their profiles with one another'
                                    />
                                  }
                                >
                                  <span>
                                    <FormattedMessage
                                      id='CommunityHeader.Cooperative'
                                      defaultMessage='Cooperative'
                                    />
                                    &nbsp;
                                  </span>
                                </Tooltip>
                              )}
                              <FormattedMessage
                                id='CommunityHeader.Invitation-OnlyCommunity'
                                defaultMessage='Invitation-Only Community'
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            <span style={{ textAlign: 'left' }}>
                              {supplierSearchable && (
                                <Tooltip
                                  title={
                                    <FormattedMessage
                                      id='CommunityHeader.CooperativeDescription'
                                      defaultMessage='Suppliers share their profiles with one another'
                                    />
                                  }
                                >
                                  <span>
                                    <FormattedMessage
                                      id='CommunityCard.Cooperative'
                                      defaultMessage='Cooperative'
                                    />
                                    &nbsp;
                                  </span>
                                </Tooltip>
                              )}
                              <FormattedMessage
                                id='CommunityHeader.OpenToEveryone'
                                defaultMessage='Open to Everyone Community'
                              />
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                  )}
                  <div className='fr border-box dtc v-top'>
                    {supplierIsMember && (
                      <div>
                        <img src={imgCheck} alt='check' className='v-mid mr2' />
                        <span className='ma0 f7 fw4 teal v-mid mr1'>
                          <FormattedMessage
                            id='CommunityHeader.Member'
                            defaultMessage='Member'
                          />
                        </span>
                        {onLeaveCommunity && (
                          <DropDownMenu>
                            <MenuItem onClick={onLeaveCommunity}>
                              Leave Community
                            </MenuItem>
                          </DropDownMenu>
                        )}
                      </div>
                    )}

                    {!supplierIsMember && onJoinCommunity && (
                      <div>
                        <Button
                          label={
                            <FormattedMessage
                              id='CommunityHeader.JoinCommunity'
                              defaultMessage='Join Community'
                            />
                          }
                          autoSize
                          onClick={onJoinCommunity}
                        />
                      </div>
                    )}
                    {isTealbot && !editMode && (
                      <div>
                        <FormattedMessage
                          id='CommunityHeader.InviteAllExisting'
                          defaultMessage='Invite All Existing'
                        >
                          {label => (
                            <Button
                              autoSize
                              secondary
                              className='mr2'
                              label={label}
                              onClick={this.toggleInviteAllDialog}
                            />
                          )}
                        </FormattedMessage>
                      </div>
                    )}
                    {isTealbot &&
                      onUpdate &&
                      (editMode ? (
                        <FormattedMessage
                          id='CommunityHeader.ButtonDone'
                          defaultMessage='Done'
                        >
                          {label => (
                            <Button
                              label={label}
                              autoSize
                              onClick={this.toggleEditMode}
                            />
                          )}
                        </FormattedMessage>
                      ) : (
                        <EditProfileButton onClick={this.toggleEditMode} />
                      ))}
                  </div>
                </h5>
                {editMode && (
                  <Fragment>
                    <Label>
                      <FormattedMessage
                        id='CommunityHeader.LabelIsCommunityInvitation-Only'
                        defaultMessage='Is community invitation-only?'
                      />
                    </Label>
                    <Switch
                      label={isPrivate ? 'Yes' : 'No'}
                      labelRight
                      labelFontLight
                      checked={isPrivate}
                      name='isPrivate'
                      onChange={() =>
                        this.handleSave({
                          isPrivate: !isPrivate
                        })
                      }
                    />
                  </Fragment>
                )}
                {editMode && (
                  <Fragment>
                    <Label>
                      <FormattedMessage
                        id='CommunityHeader.LabelIsCommunitySearchable'
                        defaultMessage='Is community searchable?'
                      />
                    </Label>
                    <Switch
                      label={supplierSearchable ? 'Yes' : 'No'}
                      labelRight
                      labelFontLight
                      checked={supplierSearchable}
                      name='supplierSearchable'
                      onChange={() =>
                        this.handleSave({
                          supplierSearchable: !supplierSearchable
                        })
                      }
                    />
                  </Fragment>
                )}
                {editMode && (
                  <Fragment>
                    <Label>
                      <FormattedMessage
                        id='CommunityHeader.LabelAllowSuppliersToPostToOneAnother'
                        defaultMessage='Allow suppliers to post to one another?'
                      />
                    </Label>
                    <Switch
                      label={allowInterSupplierPosting ? 'Yes' : 'No'}
                      labelRight
                      labelFontLight
                      checked={allowInterSupplierPosting}
                      name='allowInterSupplierPosting'
                      onChange={() =>
                        this.handleSave({
                          allowInterSupplierPosting: !allowInterSupplierPosting
                        })
                      }
                    />
                  </Fragment>
                )}
                {editMode ? (
                  <Fragment>
                    <Label>
                      <FormattedMessage
                        id='CommunityHeader.LabelDescription'
                        defaultMessage='Description'
                      />
                    </Label>
                    <FormattedMessage
                      id='CommunityHeader.PlaceholderDescription'
                      defaultMessage={`Enter the community's description`}
                    >
                      {message => (
                        <AutoSaveInput
                          className='w-100 w5-ns'
                          component={TextArea}
                          maxLength={500}
                          value={description}
                          name='description'
                          placeholder={message}
                          onSave={this.handleSave}
                        />
                      )}
                    </FormattedMessage>
                    <Label>
                      <FormattedMessage
                        id='CommunityHeader.LabelLongDescription'
                        defaultMessage='Long Description'
                      />
                    </Label>
                    <FormattedMessage
                      id='CommunityHeader.PlaceholderLongDescription'
                      defaultMessage={`Enter the community's long description`}
                    >
                      {message => (
                        <AutoSaveInput
                          className='w-100 w5-ns'
                          component={TextArea}
                          maxLength={1000}
                          value={longDescription}
                          name='longDescription'
                          placeholder={message}
                          onSave={this.handleSave}
                        />
                      )}
                    </FormattedMessage>
                  </Fragment>
                ) : (
                  <Fragment>
                    <p className='lh-copy f6 fw4 ma0 mt3 tl lh-copy dark-gray mw6-ns'>
                      {description}
                    </p>
                    <div className='w-100'>
                      <Text truncate truncateLines={3} className='mt3 w-75-ns'>
                        {longDescription}
                      </Text>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        <FormattedMessage
          id='CommunityHeader.TitleUploadLogo'
          defaultMessage='Upload Community Logo'
        >
          {title => (
            <UploadImageDialog
              open={openUploadImageDialog}
              title={title}
              imageAttributes={{
                logo: true,
                imageUrl: logoUrl
              }}
              fieldName='logo'
              onChange={this.handleFileChange}
              onCancel={this.toggleUploadImageDialog}
              onUpload={this.handleUploadImage}
            />
          )}
        </FormattedMessage>
        <Dialog
          open={openInviteAllDialog}
          onClose={this.toggleInviteAllDialog}
          fullWidth
        >
          <DialogTitle>
            <FormattedMessage
              id='CommunityHeader.InviteAllDialogTitle'
              defaultMessage='Confirmation'
            />
          </DialogTitle>
          <DialogContent>
            <div className='mt3'>
              <Text>
                <FormattedMessage
                  id='CommunityHeader.InviteAllDialogContent'
                  defaultMessage='Are you sure you want to invite all existing suppliers to this community?'
                />
              </Text>
            </div>
            <div className='mt3'>
              <Checkbox
                name='sendEmail'
                label={
                  <FormattedMessage
                    id='CommunityHeader.SendEmail'
                    defaultMessage='Send an email'
                  />
                }
                checked={sendEmail}
                onChange={e =>
                  this.setState({
                    sendEmail: e.currentTarget.checked
                  })
                }
              />
            </div>
          </DialogContent>
          <DialogActions>
            <FormattedMessage
              id='CommunityHeader.ButtonInviteAll'
              defaultMessage='Invite All'
            >
              {label => (
                <Button autoSize label={label} onClick={this.handleInviteAll} />
              )}
            </FormattedMessage>
            <FormattedMessage id='CancelButton'>
              {label => (
                <Button
                  autoSize
                  secondary
                  label={label}
                  onClick={this.toggleInviteAllDialog}
                />
              )}
            </FormattedMessage>
          </DialogActions>
        </Dialog>
      </section>
    )
  }
}

export default CommunityHeader
