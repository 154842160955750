import React, { FunctionComponent, useEffect } from 'react'
import PageSection from 'shared/components/PageSection'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import Switch from 'shared/components/Switch'
import { connect } from 'react-redux'
import { updateVet, loadVetQuestions } from '../../actions'
import Page from 'shared/components/Page'
import RootState from 'shared/models/RootState'
import Loading from 'shared/components/Loading'
import VetQuestions from './VetQuestions'

type Props = {
  vet: any
  vetId: string
  enableQandA: boolean
  numberOfQuestions: number
  isFetching?: boolean
  isLoadingVetQuestions?: boolean
  updateVet: typeof updateVet
  loadVetQuestions: typeof loadVetQuestions
}

export const QandAPage: FunctionComponent<Props> = props => {
  const { vetId, loadVetQuestions } = props

  useEffect(() => {
    if (vetId) {
      loadVetQuestions(vetId)
    }
  }, [loadVetQuestions, vetId])

  const handleToggleQandA = () => {
    props.updateVet({
      vetId: props.vetId,
      enableQandA: !props.enableQandA
    })
  }

  if (props.isFetching) {
    return <Loading />
  }

  return (
    <Page title='Q &amp; A'>
      {props.numberOfQuestions < 1 ? (
        <PageSection
          noPadding
          title={
            <FormattedMessage
              id='QandAPage.EnableQuestions'
              defaultMessage='Enable Questions and Answers'
            />
          }
          actions={
            props.numberOfQuestions < 1 && (
              <Switch
                checked={props.enableQandA}
                onChange={handleToggleQandA}
              />
            )
          }
        >
          {!props.enableQandA ? (
            <Text className='pa3-5'>
              <FormattedMessage
                id='QandAPage.enablingQuestions'
                defaultMessage='Enabling questions and answers allows suppliers to ask questions, and you can share your answers with other suppliers within this RFI.'
              />
            </Text>
          ) : (
            <Text className='pa3-5'>
              <FormattedMessage
                id='QandAPage.noQuestions'
                defaultMessage='No questions.'
              />
            </Text>
          )}
        </PageSection>
      ) : (
        <VetQuestions />
      )}
    </Page>
  )
}

const QandAPageContainer = connect(
  (state: RootState) => ({
    isFetching: state.getIn(['buyer', 'currentVet', 'isFetching']),
    vetId: state.getIn(['buyer', 'currentVet', 'data', 'id']),
    enableQandA: state.getIn(['buyer', 'currentVet', 'data', 'enableQandA']),
    numberOfQuestions: state.getIn(['buyer', 'currentVet', 'vetQuestions']).size
  }),
  {
    updateVet,
    loadVetQuestions
  }
)(QandAPage)

export default QandAPageContainer
