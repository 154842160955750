import React, { Component, ReactNode } from 'react'
import iconLike from 'shared/assets/icons/thumbup.svg'
import iconTealLike from 'shared/assets/icons/thumbup-teal.svg'
import classNames from 'classnames'

const styles = {
  topMargin: 'mt2',
  wrapper: 'dib f7 fw4 ma0 mr4',
  clickable: 'pointer dim',
  color: 'gray',
  likedColor: 'teal',
  label: 'ml1'
}

type Props = {
  count?: number
  noCountLabel: string | ReactNode
  liked: boolean
  noMargin?: boolean
  onClick?: () => void
}

class LikeButton extends Component<Props> {
  render() {
    const { count, noCountLabel, liked, onClick, noMargin } = this.props
    return (
      <div
        className={classNames(
          styles.wrapper,
          !noMargin && styles.topMargin,
          liked ? styles.likedColor : styles.color,
          onClick && styles.clickable
        )}
        onClick={onClick ? onClick : undefined}
      >
        <img src={liked ? iconTealLike : iconLike} alt='Like' />{' '}
        <span className={styles.label}>{!count ? noCountLabel : count}</span>
      </div>
    )
  }
}

export default LikeButton
