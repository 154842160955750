import React, { FC } from 'react'
import VetNavBarContainer from '../../containers/VetNavBarContainer'
import Divider from 'shared/components/Divider'
import dateFormat from 'shared/utils/dateFormat'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import moment from 'moment'
import { translatedFilterKeys } from 'shared/utils/data/defineMessages'
import camelCase from 'lodash.camelcase'

const styles = {
  wrapper: 'mw8 center ph3',
  detailsWrapper: 'mw8 w-100 center border-box dt',
  secondaryDetailsWrapper: 'dtc tr v-mid w5',
  createdBy: 'f7 fw4 gray ma0',
  respondBy: 'f7 fw6 gray ma0 mt1',
  secondaryDetailsLabel: 'fw6 mr1'
}

type Props = {
  vetCreatedBy: string
  vetResponseBy: string
  vetBuyerSupplierId: string
  vetName: string
  vetStatus: string
  redirectFrom: string
  intl: IntlShape
}

export const VetHeader: FC<Props> = ({
  vetCreatedBy,
  vetResponseBy,
  vetBuyerSupplierId,
  vetName,
  vetStatus,
  redirectFrom,
  intl
}) => {
  const responseBy = moment(vetResponseBy)
  const daysToResponse = responseBy.diff(new Date(), 'days')

  return (
    <header
      className='bg-tb-gray'
      style={{
        position: 'sticky',
        top: 50,
        zIndex: 50
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.detailsWrapper}>
          <h5 className='mid-gray f4 fw6 mv4'>
            {vetName || (
              <FormattedMessage
                id='VetHeader.UntitledRFI'
                defaultMessage='Untitled RFI'
              />
            )}
          </h5>
          <div className={styles.secondaryDetailsWrapper}>
            <h2 className={styles.createdBy}>
              <span className={styles.secondaryDetailsLabel}>
                <FormattedMessage
                  id='VetHeader.CreatedBy'
                  defaultMessage='Created by'
                />
              </span>
              {vetCreatedBy}
            </h2>
            {vetResponseBy && (
              <h2 className={styles.respondBy}>
                <span className={styles.secondaryDetailsLabel}>
                  {daysToResponse < 0 ? (
                    <FormattedMessage
                      id='VetHeader.SubmitRequiredBy'
                      defaultMessage='Response was required by'
                    />
                  ) : daysToResponse > 30 || daysToResponse < 3 ? (
                    <FormattedMessage
                      id='VetHeader.SubmitResponseBy'
                      defaultMessage='Submit your response by'
                    />
                  ) : (
                    <FormattedMessage
                      id='VetHeader.SubmitResponseWithin'
                      defaultMessage='Submit your response within'
                    />
                  )}
                </span>
                <span
                  className={`white ph1 br1 ${
                    daysToResponse < 0
                      ? 'bg-red'
                      : daysToResponse < 5
                      ? 'bg-gold'
                      : 'bg-green'
                  }`}
                >
                  {daysToResponse > 30 || daysToResponse < 3 ? (
                    dateFormat(responseBy)
                  ) : (
                    <FormattedMessage
                      id='VetHeader.Days'
                      defaultMessage='{responseByDate} days'
                      values={{ responseByDate: daysToResponse }}
                    />
                  )}
                </span>
              </h2>
            )}
            <div className='mt3'>
              {translatedFilterKeys.collaborationStatus[camelCase(vetStatus)]
                ? intl.formatMessage(
                    translatedFilterKeys.collaborationStatus[
                      camelCase(vetStatus)
                    ]
                  )
                : vetStatus}
            </div>
          </div>
        </div>
        <VetNavBarContainer
          vetBuyerSupplierId={vetBuyerSupplierId}
          redirectFrom={redirectFrom}
        />
      </div>
      <Divider />
    </header>
  )
}

export default injectIntl(VetHeader)
