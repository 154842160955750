import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { searchForOrganization } from '../../store/actions'
import CreateCardPage from '../../components/CreateCardPage'
import userEmailSelectors from '../../store/selectors/userEmailSelectors'
import orgsSelectors from '../../store/selectors/orgsSelectors'

type Props = {
  searchForOrganization: (q: string) => void
  displaySupplierFinder: boolean
  selectedUserEmail: string
  q: string
}

export class CreateCardPageContainer extends Component<Props> {
  componentDidMount() {
    this.props.searchForOrganization(
      fromJS({
        q: this.props.q
      })
    )
  }

  render() {
    return <CreateCardPage {...this.props} />
  }
}

export default connect(
  state => ({
    q: orgsSelectors.getQ(state),
    selectedUserEmail: userEmailSelectors.getEmail(state),
    displaySupplierFinder:
      !!userEmailSelectors.getEmail(state) &&
      !userEmailSelectors.getEditMode(state)
  }),
  {
    searchForOrganization
  }
)(CreateCardPageContainer)
