import { connect } from 'react-redux'
import { updateCard } from '../../actions'
import ConnectTeamMember from '../../components/ConnectTeamMember'
import profileSelectors from '../../selectors/profileSelectors'

export default connect(
  state => ({
    usersWithoutCards: profileSelectors.getUsersWithoutCards(state)
  }),
  {
    onDone: updateCard
  }
)(ConnectTeamMember)
