import React, { Component } from 'react'
import Page from 'shared/components/Page'
import Search from '../../../Search'
import VetTeamCard from '../VetTeamCard'
import VetSupplierListContainer from '../../containers/VetSupplierListContainer'
import { FormattedMessage } from 'react-intl'

type Props = {
  match: { params: { vetId: string } }
}

class VetSearch extends Component<Props> {
  render() {
    const { vetId } = this.props.match.params
    return (
      <Page title={<FormattedMessage id='AddSearch' defaultMessage='Search' />}>
        <Search
          vetId={vetId}
          topSideContent={<VetSupplierListContainer className='mb3' />}
        >
          <div>
            <VetTeamCard />
          </div>
        </Search>
      </Page>
    )
  }
}

export default VetSearch
