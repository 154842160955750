import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addSupplierComment } from '../../../SupplierProfile/actions'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'
import searchSelectors from '../../../Search/selectors/searchSelectors'
import { List } from 'immutable'

type Props = {
  relationship: string
  addSupplierComment: typeof addSupplierComment
} & ContainerProps

type State = {
  note: string
  supplierId: string
  relationship: List<string>
}

export class MyNotesContainer extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      note: '',
      supplierId: props.supplierId,
      relationship: props.relationship
    }
  }

  componentWillUnmount() {
    const { addSupplierComment } = this.props
    const { note, supplierId, relationship } = this.state

    if (note) {
      addSupplierComment({
        supplierId,
        relationship: relationship.size > 0 && relationship,
        isPrivate: false,
        messageKind: 'Relationship',
        newNote: note
      })
      this.setState({ note: '' })
    }
  }

  handleOnChange = e => {
    this.setState({
      note: e.target.value
    })
  }

  render() {
    const { note } = this.state

    return (
      <div>
        <Label>
          <FormattedMessage
            id='MyNotesContainer.LabelNote'
            defaultMessage='Note'
          />
        </Label>
        <TextArea
          value={note}
          onChange={this.handleOnChange}
          maxLength={1000}
        />
      </div>
    )
  }
}

type ContainerProps = {
  supplierId: string
}

export default connect(
  (state, props: ContainerProps) => {
    const resultItem = searchSelectors.getResultItemBySupplierId(
      state,
      props.supplierId
    )
    const relationship = resultItem && resultItem.get('relationship')

    return {
      relationship
    }
  },
  { addSupplierComment }
)(MyNotesContainer)
