const equalsIgnoreOrder = (
  a: string[] | undefined,
  b: string[] | undefined
) => {
  if (!!a !== !!b) {
    return false
  }
  if (a && b && a.length !== b.length) {
    return false
  }
  if (a && b) {
    const uniqueValues = Array.from(new Set([...a, ...b]))
    for (const v of uniqueValues) {
      const aCount = a.filter(e => e === v).length
      const bCount = b.filter(e => e === v).length
      if (aCount !== bCount) return false
    }
  }
  return true
}

export default equalsIgnoreOrder
