import React, { FunctionComponent } from 'react'
import { RecordOf, List } from 'immutable'
import ContactCardContainer from 'shared/containers/ContactCardContainer'

type Props = {
  baseUrl: string
  contacts: List<
    RecordOf<{
      user: string
    }>
  >
}
const ContactsGrid: FunctionComponent<Props> = props => {
  return (
    <div className='ph3-5 pb3-5'>
      {props.contacts.map((contact, index) => (
        <ContactCardContainer
          key={contact.get('user')}
          baseUrl={props.baseUrl}
          userId={contact.get('user')}
        />
      ))}
    </div>
  )
}

export default ContactsGrid
