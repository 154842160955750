import React, { FC } from 'react'
import { connect } from 'react-redux'
import statsSelectors from '../../../shared/selectors/statsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import Text from 'shared/components/Text'
import Scrollable from 'shared/components/Scrollable'
import ListItem from 'shared/components/List/ListItem'
import OrgLogo from 'shared/components/OrgLogo'
import Badge from 'shared/components/Badge'
import Triangle from 'shared/components/Triangle'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf } from 'immutable'

const styles = {
  upColor: '#093',
  downColor: '#ff333a'
}

type Props = {
  appearanceDetails: List<
    RecordOf<{
      orgUnit: string
      currentRank: number
      previousRank: number
      logoUrl: string
      name: string
    }>
  >
  currentOrgUnit: string
}

export const AppearanceDetails: FC<Props> = ({
  appearanceDetails,
  currentOrgUnit
}) => {
  const getRankText = place => {
    if (place === 1) {
      return (
        <FormattedMessage
          id='AppearanceDetails.FirstRank'
          defaultMessage='1st'
        />
      )
    } else if (place === 2) {
      return (
        <FormattedMessage
          id='AppearanceDetails.SecondRank'
          defaultMessage='2nd'
        />
      )
    } else if (place === 3) {
      return (
        <FormattedMessage
          id='AppearanceDetails.ThirdRank'
          defaultMessage='3rd'
        />
      )
    } else {
      return (
        <FormattedMessage
          id='AppearanceDetails.OtherRanks'
          defaultMessage='{place}th'
          values={{ place }}
        />
      )
    }
  }

  return appearanceDetails && appearanceDetails.size > 0 ? (
    <div className='pa3'>
      <Text className='mb1'>
        <FormattedMessage
          id='AppearanceDetails.CompaniesLikeMeText'
          defaultMessage='Top 10 "Companies Like Me" ranked by search appearances.'
        />
      </Text>
      <Scrollable>
        {appearanceDetails
          .map(supplier => {
            const myOrgUnit = currentOrgUnit === supplier.get('orgUnit')
            const rankDown =
              supplier.get('currentRank') < supplier.get('previousRank')
            const color = rankDown ? styles.downColor : styles.upColor
            const direction = rankDown ? 'down' : 'up'

            return (
              <div className='dt w-100' key={supplier.get('orgUnit')}>
                <div className='dtc v-mid pr3 w2'>
                  <Badge place={supplier.get('currentRank')} />
                </div>
                <div className='dtc v-mid'>
                  <ListItem
                    mini
                    className='pv2'
                    leftAvatar={
                      <OrgLogo
                        url={supplier.get('logoUrl')}
                        name={supplier.get('name')}
                      />
                    }
                    primaryText={
                      myOrgUnit ? (
                        <span className='fw7' style={{ color }}>
                          {supplier.get('name')}
                        </span>
                      ) : (
                        supplier.get('name')
                      )
                    }
                    rightContent={
                      myOrgUnit && (
                        <Tooltip
                          title={
                            <>
                              <Text>
                                {
                                  <FormattedMessage
                                    id='AppearanceDetails.LastMonthRankText'
                                    defaultMessage='Last month in {previousRank} place'
                                    values={{
                                      previousRank: getRankText(
                                        supplier.get('previousRank')
                                      )
                                    }}
                                  />
                                }{' '}
                              </Text>
                              <Text>
                                {
                                  <FormattedMessage
                                    id='AppearanceDetails.CurrentRankText'
                                    defaultMessage='This month in {currentRank} place'
                                    values={{
                                      currentRank: getRankText(
                                        supplier.get('currentRank')
                                      )
                                    }}
                                  />
                                }
                              </Text>
                            </>
                          }
                        >
                          <Triangle
                            color={color}
                            direction={direction}
                            size={8}
                          />
                        </Tooltip>
                      )
                    }
                  />
                </div>
              </div>
            )
          })
          .toArray()}
      </Scrollable>
    </div>
  ) : (
    <Text className='pa3'>
      <FormattedMessage
        id='AppearanceDetails.NoAdditionalDataText'
        defaultMessage='No profile views in the last 90 days'
      />
    </Text>
  )
}

export default connect(state => ({
  currentOrgUnit: sessionSelectors.getOrgUnitId(state),
  appearanceDetails: statsSelectors.getAppearanceDetails(state)
}))(AppearanceDetails)
