import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from '../createSubmitFormAction'
import {
  isSubmitting,
  getFormSubmitErrors,
  formValueSelector
} from 'redux-form/immutable'
import { startEditMode, stopEditMode } from '../../actions'
import RootState from 'shared/models/RootState'

type Props = {
  onSubmit: () => void
  handleSubmit: (onSubmit: Function) => void
  stopEditMode: (params: string) => void
  startEditMode: (params: string) => void
  reset: () => void
}

type EditFormOptions = {
  formName: string
  submitActionType: string
  stopEditModeOnSave?: boolean
  injectFieldValues?: any
  destroyOnUnmount?: boolean
}
const editModeForm = ({
  formName,
  injectFieldValues,
  submitActionType,
  stopEditModeOnSave,
  ...otherReduxFormOptions
}: EditFormOptions) => {
  return WrappedComponent => {
    class EditModeForm extends Component<Props> {
      startEditMode = () => {
        const { startEditMode } = this.props
        startEditMode(formName)
      }
      stopEditMode = () => {
        const { stopEditMode, reset } = this.props
        stopEditMode(formName)
        reset()
      }
      render() {
        const { handleSubmit, onSubmit, ...rest } = this.props
        return (
          <WrappedComponent
            {...rest}
            startEditMode={this.startEditMode}
            stopEditMode={this.stopEditMode}
            onSubmit={handleSubmit(onSubmit)}
          />
        )
      }
    }
    const formIsSubmitting = isSubmitting(formName)
    const formErrors = getFormSubmitErrors(formName) as (
      state: RootState
    ) => any
    const valueSelector = injectFieldValues && formValueSelector(formName)
    return connectReduxForm(
      state => {
        const fieldValues =
          injectFieldValues &&
          injectFieldValues.reduce((result, field) => {
            result[field] = valueSelector(state, field)
            return result
          }, {})
        return {
          editMode: state.getIn(['editMode', formName]),
          isSubmitting: formIsSubmitting(state),
          errorMessage: formErrors(state) && formErrors(state).get('errors'),
          ...fieldValues
        }
      },
      {
        startEditMode,
        stopEditMode,
        onSubmit:
          submitActionType &&
          createSubmitFormAction(formName, submitActionType, stopEditModeOnSave)
      },
      {
        form: formName,
        enableReinitialize: true,
        ...otherReduxFormOptions
      },
      EditModeForm
    )
  }
}
export default editModeForm
