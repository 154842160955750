import React, { Component, Fragment } from 'react'
import Text from 'shared/components/Text'
import StarIcon from '@material-ui/icons/Star'
import Tooltip from 'shared/components/Tooltip'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { FormattedMessage } from 'react-intl'
import ExternalLink from 'shared/components/ExternalLink'
import Location from 'shared/models/Location'
import { RecordOf } from 'immutable'

export type CompanyLocationProps = {
  name: string
  poBox: string
  level: string
  unit: string
  suburb: string
  stateDistrict: string
  state: string
  postalCode: string
  address: string
  description: string
  phone: string
  houseNumber: string
  road: string
  city: string
  stateAbbrev?: string
  country: string
  publicView?: boolean
  isPrimary: boolean
  lat: number
  long: number
  location?: RecordOf<Location>
}

class CompanyLocation extends Component<CompanyLocationProps> {
  render() {
    const {
      address,
      description,
      phone,
      name,
      poBox,
      houseNumber,
      level,
      unit,
      road,
      suburb,
      city,
      stateDistrict,
      state,
      postalCode,
      country,
      publicView,
      isPrimary,
      lat,
      long
    } = this.props

    const secondLine = [road, unit, level].filter(text => !!text)
    const thirdLine = [suburb, city, stateDistrict, state, postalCode].filter(
      text => !!text
    )

    return (
      <div className='ph1'>
        {!!description && (
          <div className='mb2'>
            {lat && long ? (
              <ExternalLink
                href={`https://www.google.ca/maps/place/${houseNumber} ${secondLine.join(
                  ', '
                )},${thirdLine.join(', ')}/@${lat},${long}`}
                className='mid-gray no-underline dim'
              >
                <span className='fw6 v-mid'>{description}</span>
              </ExternalLink>
            ) : (
              <span className='fw6 v-mid'>{description}</span>
            )}
            {isPrimary && <StarIcon color='primary' className='v-mid ml2' />}
          </div>
        )}

        <Text>
          {!!address &&
            secondLine.length < 1 &&
            thirdLine.length < 1 &&
            !name &&
            !country && (
              <Fragment>
                <span className='v-mid'>{address}</span>
                {!publicView && (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id='CompanyLocation.TheAddressNotRecognized'
                        defaultMessage='The address was not recognized. Please provide additional details'
                      />
                    }
                    classes={{ popper: 'mw5' }}
                  >
                    <ErrorOutlineIcon color='error' className='v-mid ml2' />
                  </Tooltip>
                )}
                <br />
              </Fragment>
            )}

          {!!name && (
            <Fragment>
              {name} <br />
            </Fragment>
          )}

          {secondLine.length > 0 && (
            <Fragment>
              {poBox} {houseNumber && <Fragment>{houseNumber}&nbsp;</Fragment>}
              {secondLine.join(', ')}
              <br />
            </Fragment>
          )}

          {thirdLine.length > 0 && (
            <Fragment>
              {thirdLine.join(', ')}
              <br />
            </Fragment>
          )}

          {country}
        </Text>

        <Text>{phone}</Text>
      </div>
    )
  }
}

export default CompanyLocation
