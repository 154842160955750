import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import RootState from 'shared/models/RootState'

export const getCommunities = createSelector(
  (state: RootState) => state.getIn(['buyer', 'communities', 'byId']),
  communities => communities || fromJS({})
)

export const getCommunitiesWithoutTier2 = createSelector(
  getCommunities,
  communities => communities && communities.filter(comm => !comm.get('tier2'))
)
export const getCommunitiesList = createSelector(getCommunities, communities =>
  communities.toList()
)
export const getCommunitiesLessTier2 = createSelector(
  getCommunitiesList,
  communities => communities.filter(community => !community.get('tier2'))
)
export const getTier2Community = createSelector(
  getCommunitiesList,
  communities => communities.find(comm => !!comm.get('tier2'))
)
export const getCommunityById = (state, id) => getCommunities(state).get(id)
export const getBySupplierId = (state, supplierId) => {
  return state.getIn(['buyer', 'communities', 'bySupplierId', supplierId])
}
export const getCommunitiesBySupplierId = (state, supplierId) => {
  const ids = state.getIn(['buyer', 'communities', 'bySupplierId', supplierId])
  return getCommunities(state).filter(
    community => ids && ids.includes(community.get('id'))
  )
}

export const getMemberships = state => {
  return state.getIn(['buyer', 'communities', 'membershipsById']) || fromJS({})
}
export const getMembershipById = (state, id) => getMemberships(state).get(id)
export const getMembershipsBySupplierId = createSelector(
  (state, supplierId) =>
    state.getIn([
      'buyer',
      'communities',
      'membershipsBySupplierId',
      supplierId
    ]),
  getMemberships,
  (membershipIds, memberships) =>
    memberships.filter(
      membership =>
        membershipIds && membershipIds.includes(membership.get('id'))
    )
)

export const getCommunitiesForInviteLessTier2 = createSelector(
  getMembershipsBySupplierId,
  getCommunitiesLessTier2,
  (supplierMemberships, clientCommunities) => {
    return clientCommunities
      .map(community => {
        const member = supplierMemberships.filter(member => {
          return member.getIn(['parents', 'Community']) === community.get('id')
        })
        return member.size === 0
          ? community
          : community.set('membershipStatus', member.first().get('status'))
      })
      .sort((commA, commB) => {
        let sortMembershipStatus
        if (commA.has('membershipStatus') === commB.has('membershipStatus')) {
          if (commA.get('membershipStatus') === commB.get('membershipStatus')) {
            sortMembershipStatus = 0
          } else if (commB.get('membershipStatus') === 'Accepted') {
            sortMembershipStatus = 1
          } else {
            sortMembershipStatus = -1
          }
        } else if (commB.has('membershipStatus')) {
          sortMembershipStatus = 1
        } else {
          sortMembershipStatus = -1
        }

        return (
          sortMembershipStatus ||
          (commA.get('name').toUpperCase() > commB.get('name').toUpperCase()
            ? 1
            : -1)
        )
      })
  }
)

export const createGetSupplierCommunitiesSelector = communitiesStatus =>
  createSelector(
    getMembershipsBySupplierId,
    getCommunitiesList,
    (supplierMemberships, clientCommunities) => {
      const commIds = supplierMemberships
        .filter(mem => {
          return (
            !communitiesStatus ||
            (Array.isArray(communitiesStatus)
              ? communitiesStatus.includes(mem.get('status'))
              : mem.get('status') === communitiesStatus)
          )
        })
        .toList()
        .map(mem => mem.getIn(['parents', 'Community']))
      return clientCommunities
        .filter(community => !community.get('tier2'))
        .filter(community => commIds.includes(community.get('id')))
        .sort((commA, commB) => {
          let sortPrivate
          if (commA.get('isPrivate') === commB.get('isPrivate')) {
            sortPrivate = 0
          } else if (commB.get('isPrivate')) {
            sortPrivate = 1
          } else {
            sortPrivate = -1
          }
          return (
            sortPrivate ||
            commA.get('name').toUpperCase() > commB.get('name').toUpperCase()
          )
        })
    }
  )

const createGetBuyersSelector = isPublic =>
  createSelector(
    (state, communityId) =>
      state.getIn(['buyer', 'communities', 'byId', communityId, 'buyers']),
    buyers =>
      buyers &&
      buyers
        .filter(buyer =>
          isPublic
            ? buyer.get('name')
            : !buyer.get('name') && buyer.get('categorization')
        )
        .sort((buyerA, buyerB) => {
          const nameA = isPublic
            ? buyerA.get('name')
            : buyerA.get('categorization')
          const nameB = isPublic
            ? buyerB.get('name')
            : buyerB.get('categorization')
          if (nameA.toLowerCase() > nameB.toLowerCase()) {
            return 1
          } else {
            return -1
          }
        })
  )

export const getPublicBuyers = createGetBuyersSelector(true)
export const getPrivateBuyers = createGetBuyersSelector(false)
