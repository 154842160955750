function scrollTo(
  ele: null | undefined | HTMLElement,
  to: number,
  duration?: number
) {
  const element = ele || document.scrollingElement || document.documentElement
  const start = element.scrollTop
  const change = to - start
  const startDate = +new Date()
  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return -(c / 2) * (t * (t - 2) - 1) + b
  }

  const animateScroll = () => {
    const currentDate = +new Date()
    const currentTime = currentDate - startDate
    element.scrollTop = parseInt(
      easeInOutQuad(currentTime, start, change, duration),
      10
    )
    if (duration && currentTime < duration) {
      window.requestAnimationFrame(animateScroll)
    } else {
      element.scrollTop = to
    }
  }

  animateScroll()
}

export default scrollTo
