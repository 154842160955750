import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DiversityReportButtonFilters from '../DiversityReport/DiversityReportButtonFilters'
import QuarterRangeSlider, {
  Quarter
} from 'shared/components/QuarterRangeSlider'
import Loading from 'shared/components/Loading'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from 'react-intl'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import RootState from 'shared/models/RootState'
import {
  getTier2Agencies,
  loadTier2Data,
  getTier2Range
} from '../../store/actions'
import { List } from 'immutable'
import CategoryOverview from './CategoryOverview'
import SupplierAllocation from './SupplierAllocation'
import Text from 'shared/components/Text'

type Props = {
  minYear: number
  maxYear: number
  minQuarter: number
  maxQuarter: number
  certAgencies: List<string>
}

const Tier2SpendOverview = (props: Props) => {
  const { minYear, maxYear, minQuarter, maxQuarter, certAgencies } = props
  const [currentRange, setCurrentRange] = useState<string>('')

  const quarterRangeLimits: [Quarter, Quarter] = [
    {
      year: minYear,
      quarter: minQuarter
    },
    {
      year: maxYear,
      quarter: maxQuarter
    }
  ]

  const [quarterRangeValue, setQuarterRangeValue] = useState<
    [Quarter, Quarter]
  >(quarterRangeLimits)

  const dispatch = useDispatch()

  const loadTier2 = useCallback(
    (start, end) => {
      dispatch(
        loadTier2Data({
          startYear: start.year,
          startQuarter: start.quarter,
          endYear: end.year,
          endQuarter: end.quarter
        })
      )
      setCurrentRange(`${start.year}${start.quarter}${end.year}${end.quarter}`)
    },
    [dispatch]
  )

  useEffect(() => {
    if (minYear && maxYear && minQuarter && maxQuarter) {
      loadTier2(
        { year: minYear, quarter: minQuarter },
        { year: maxYear, quarter: maxQuarter }
      )
    }
  }, [minYear, maxYear, minQuarter, maxQuarter, loadTier2])

  const handleChangeCommitted = values => {
    loadTier2(values[0], values[1])
  }

  const handleQualificationChange = () => {
    loadTier2(quarterRangeValue[0], quarterRangeValue[1])
  }

  return !!minYear && !!maxYear && !!minQuarter && !!maxQuarter ? (
    <div>
      <ScrollToPosition />
      <Grid container spacing={3}>
        <Grid item md={6}>
          <div className='mt3 mb2 f4 gray'>
            {minYear === maxYear && minQuarter === maxQuarter ? (
              `${minYear} Q${minQuarter}`
            ) : (
              <FormattedMessage
                id='Spend.DateRangeReport'
                defaultMessage='From {fromQuarter} to {toQuarter}'
                values={{
                  fromQuarter: `${quarterRangeValue[0].year} Q${quarterRangeValue[0].quarter}`,
                  toQuarter: `${quarterRangeValue[1].year} Q${quarterRangeValue[1].quarter}`
                }}
              />
            )}
          </div>
          <QuarterRangeSlider
            value={quarterRangeValue}
            onChange={setQuarterRangeValue}
            minQuarter={quarterRangeLimits[0]}
            maxQuarter={quarterRangeLimits[1]}
            onChangeCommitted={handleChangeCommitted}
          />
        </Grid>
        <Grid item md={6}>
          <div className='mv3'>
            <Text>
              <FormattedMessage id='Spend.QualificationHelp' />
            </Text>
          </div>
          <DiversityReportButtonFilters
            tier2
            authorities={certAgencies}
            onApply={handleQualificationChange}
          />
        </Grid>
      </Grid>
      <div>
        <CategoryOverview range={currentRange} />
        <SupplierAllocation range={currentRange} />
      </div>
    </div>
  ) : (
    <Text className='mt3'>
      <FormattedMessage
        id='Tier2SpendOverview.NoData'
        defaultMessage="Spend overview will be available after one or more of the suppliers you've invited shares tier 2 spend information."
      />
    </Text>
  )
}

const Tier2SpendOverviewContainer = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTier2Range())
    dispatch(getTier2Agencies())
  }, [dispatch])

  const minYear = useSelector<RootState, number>(
    state => state.getIn(['buyer', 'insights', 'tier2', 'minYear']) || 0
  )
  const minQuarter = useSelector<RootState, number>(
    state => state.getIn(['buyer', 'insights', 'tier2', 'minQuarter']) || 0
  )
  const maxYear = useSelector<RootState, number>(
    state => state.getIn(['buyer', 'insights', 'tier2', 'maxYear']) || 0
  )
  const maxQuarter = useSelector<RootState, number>(
    state => state.getIn(['buyer', 'insights', 'tier2', 'maxQuarter']) || 0
  )
  const certAgencies = useSelector<RootState, List<string>>(state =>
    state.getIn(['buyer', 'insights', 'tier2', 'certAgencies'])
  )

  if (!certAgencies) {
    return <Loading />
  }

  return (
    <Tier2SpendOverview
      minYear={minYear}
      minQuarter={minQuarter}
      maxYear={maxYear}
      maxQuarter={maxQuarter}
      certAgencies={certAgencies}
    />
  )
}

export default Tier2SpendOverviewContainer
