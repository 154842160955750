import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import Link from 'shared/components/Link'

const CustomerLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'rgb(189, 189, 189)'
  },
  barColorPrimary: {
    backgroundColor: '#2cbe4e'
  }
})(LinearProgress)

type Props = {
  recentPercentile: number
  recentAmount?: number
  linkTo?: string
  definition?: boolean
  supplierName?: string
}

class SpendGauge extends Component<Props> {
  getApproxAmount() {
    const { recentAmount } = this.props

    if (!recentAmount) {
      return ''
    }

    const numberOfDigits = recentAmount
      ? Math.floor(recentAmount).toString().length
      : 0

    const roundAmount =
      Math.round((recentAmount / Math.pow(10, numberOfDigits)) * 10) *
      Math.pow(10, numberOfDigits - 1)

    return roundAmount > 1000 ? (
      `: ~$${roundAmount.toLocaleString()}`
    ) : (
      <FormattedMessage
        id='SpendGauge.OrLess'
        defaultMessage=': ~$ {lessThanValue} or less'
        values={{ lessThanValue: (1000).toLocaleString() }}
      />
    )
  }

  getTooltipText() {
    const { recentPercentile } = this.props
    if (recentPercentile > 80) {
      return (
        <FormattedMessage id='SpendGage.Highest' defaultMessage='Highest' />
      )
    } else if (recentPercentile > 60) {
      return <FormattedMessage id='SpendGage.High' defaultMessage='High' />
    } else if (recentPercentile > 40) {
      return <FormattedMessage id='SpendGage.Median' defaultMessage='Median' />
    } else if (recentPercentile > 20) {
      return <FormattedMessage id='SpendGage.Low' defaultMessage='Low' />
    } else if (recentPercentile > 0) {
      return (
        <FormattedMessage id='SpendGage.VeryLow' defaultMessage='Very Low' />
      )
    } else {
      return 'No'
    }
  }

  render() {
    const { recentPercentile, linkTo, supplierName } = this.props

    return recentPercentile !== undefined ? (
      <div className='dib w3 mr2 mr3-ns v-mid'>
        <Tooltip
          title={
            <FormattedMessage
              id='SpendGauge.Spend'
              defaultMessage='{tooltipText} spend{approxAmount}'
              values={{
                approxAmount: this.getApproxAmount(),
                tooltipText: this.getTooltipText()
              }}
            />
          }
        >
          {linkTo ? (
            <Link
              to={linkTo}
              aria-label={`Link to ${supplierName}'s Spend Overview Section`}
            >
              <CustomerLinearProgress
                variant='determinate'
                value={recentPercentile}
                aria-label='Spend Gauge with Link'
              />
            </Link>
          ) : (
            <CustomerLinearProgress
              id='spendGauge'
              variant='determinate'
              value={recentPercentile}
              aria-label='Spend Gauge'
            />
          )}
        </Tooltip>
      </div>
    ) : null
  }
}

export default SpendGauge
