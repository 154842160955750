import React, { Component } from 'react'
/**
 * Used as a styling component to create sections within a page
 */

class Card extends Component {
  render() {
    return (
      <div className='bg-white br1 pa4 ba b--black-10'>
        {this.props.children}
      </div>
    )
  }
}

export default Card
