import { connect } from 'react-redux'
import createAddForm from 'shared/utils/createAddForm'
import AddUserForm from 'shared/components/AddUserForm'
import usersSelectors from 'shared/selectors/usersSelectors'
import { ADD_VET_CONTACT } from '../../actionTypes'
import createInviteUserActions from 'shared/utils/inviteUser/createActions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import { getFormValues, isSubmitting } from 'redux-form/immutable'
import inviteVetContactsSelectors from '../../selectors/inviteVetContactsSelectors'
import { createSelector } from 'reselect'

const emailToDomain = value => {
  return value && value.indexOf('@') !== -1 && value.split('@')[1]
}

const {
  cancelInvitingUser,
  selectNewUser,
  selectUser
} = createInviteUserActions('invitePrimaryContact')

const formName = 'supplier/vets/addPrimaryContact'
const formValueSelector = getFormValues(formName)

const getDomains = createSelector(
  state => sessionSelectors.getUser(state).get('email'),
  email => [emailToDomain(email)]
)

const getInitialValues = createSelector(
  inviteVetContactsSelectors.getSelectedUser,
  inviteVetContactsSelectors.getNewUser,
  (selectedUser, newUser) => ({
    user: selectedUser || newUser
  })
)

const getContactIds = createSelector(
  vetBuyerSupplierSelectors.getVetContacts,
  contacts => contacts && contacts.map(c => c.get('user'))
)

const AddPrimaryContactContainer = connect(
  state => {
    const formValues = formValueSelector(state)
    const creatingNewUser = inviteVetContactsSelectors.isCreatingNewUser(state)
    const contactIds = getContactIds(state)

    return {
      caption: 'Invite user to an RFI',
      isSubmitting: isSubmitting(formName)(state),
      email: formValues && formValues.getIn(['user', 'email']),
      addingContact: inviteVetContactsSelectors.isAddingContact(state),
      creatingNewUser,
      domains: getDomains(state),
      users: usersSelectors.getColleagues(state),
      suggestedUsers: usersSelectors.getColleaguesExcludingUsers(
        state,
        contactIds
      ),
      initialValues: getInitialValues(state)
    }
  },
  {
    onClickCancel: cancelInvitingUser,
    onClickSuggestedUser: selectUser,
    onClickNewUser: selectNewUser
  }
)(
  createAddForm(
    {
      formName,
      submitActionType: ADD_VET_CONTACT
    },
    AddUserForm
  )
)

export default AddPrimaryContactContainer
