import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'

const ReduxFormMaterialCheckbox = ({ input, ...rest }) => (
  <Checkbox
    checked={input.value ? true : false}
    onChange={input.onChange}
    color='primary'
    size='small'
    {...rest}
  />
)

export default ReduxFormMaterialCheckbox
