import { connect } from 'react-redux'
import userMetricsSelectors from 'buyer/shared/selectors/userMetricsSelectors'
import SupplierConnections from '../../components/SupplierConnections'

type Props = {
  userId: string
}

export const mapStateToProps = (state, props: Props) => {
  return {
    supplierCount: userMetricsSelectors.getUserMetricsSupplierCount(state),
    suppliers: userMetricsSelectors.getUserMetricsSuppliers(state)
  }
}

export default connect(mapStateToProps)(SupplierConnections)
