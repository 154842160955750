import { Map, fromJS, List } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { requestSuccess, LOGOUT } from 'shared/actionTypes'
import VetRecordFactory from './VetRecordFactory'

const defaultState = Map({
  data: VetRecordFactory({}),
  isFetching: false,
  editTeam: false,
  fetchErrorMessage: '',
  addByEmail: Map({}),
  vetQuestions: List()
})

const currentVetReducer = (state = defaultState, action) => {
  let criticalCriteria
  switch (action.type) {
    case actionTypes.LOAD_VET:
      return action.meta && action.meta.dontClearState
        ? state
        : state.merge({
            data: VetRecordFactory({}),
            isFetching: true,
            fetchErrorMessage: ''
          })
    case actionTypes.LOAD_VET_SUCCESS:
    case actionTypes.UPDATE_VET_SUCCESS:
      return state.merge({
        isFetching: false,
        data: VetRecordFactory(action.payload.vet)
      })
    case actionTypes.LOAD_VET_FAILURE:
      return state.merge({
        isFetching: false,
        fetchErrorMessage: action.payload
      })
    case requestSuccess(actionTypes.ADD_VET_TEAM_MEMBER):
      return state
        .setIn(['data', 'isPrivate'], false)
        .updateIn(['data', 'users'], users => {
          if (!users.some(u => u.user === action.payload.user.id)) {
            return users.push({
              user: action.payload.user.id,
              lead: false,
              subscribed: true,
              requestedBy: false
            })
          } else {
            return users
          }
        })
    case requestSuccess(actionTypes.UPDATE_VET_TEAM_MEMBER):
      return state.updateIn(['data', 'users'], users => {
        const { user1, user2 } = action.payload
        return users.map(user => {
          return user.user === user1.user
            ? user1
            : user.user === user2.user
            ? user2
            : user
        })
      })
    case requestSuccess(actionTypes.REMOVE_VET_TEAM_MEMBER):
      return state.updateIn(['data', 'users'], users => {
        return users.filter(u => u.user !== action.payload.userId)
      })
    case requestSuccess(actionTypes.ADD_CRITERIA):
      criticalCriteria = state.getIn(['data', 'criticalCriteria'])
      return state.setIn(
        ['data', 'criticalCriteria'],
        criticalCriteria.push(action.payload)
      )
    case requestSuccess(actionTypes.REMOVE_CRITERIA):
      criticalCriteria = state.getIn(['data', 'criticalCriteria'])
      return state.setIn(
        ['data', 'criticalCriteria'],
        criticalCriteria.delete(action.payload)
      )
    case requestSuccess(actionTypes.UPDATE_CRITERIA):
      criticalCriteria = state.getIn(['data', 'criticalCriteria'])
      return state.setIn(
        ['data', 'criticalCriteria'],
        criticalCriteria.set(action.payload.index, action.payload.toCriteria)
      )

    case requestSuccess(actionTypes.CANCEL_VET):
      return state.setIn(['data', 'decision'], 'Cancelled')

    case requestSuccess(actionTypes.CLOSE_VET):
      return state.setIn(['data', 'decision'], 'Closed')

    case requestSuccess(actionTypes.REOPEN_VET):
      return state.setIn(['data', 'decision'], action.payload)

    case requestSuccess(actionTypes.REMOVE_ATTACHMENT):
      return state.updateIn(['data', 'document'], document => {
        const fileIndex = document
          ? document.findIndex(
              file => file.get('fileName') === action.payload.fileName
            )
          : -1
        return fileIndex !== -1 ? document.delete(fileIndex) : document
      })

    case requestSuccess(actionTypes.DELETE_PROJECT_BACKGROUND):
      return state.updateIn(
        ['data', 'projectBackground'],
        projectBackground => {
          const fileIndex = projectBackground
            ? projectBackground.findIndex(
                file => file.get('fileName') === action.payload.fileName
              )
            : -1
          return fileIndex !== -1
            ? projectBackground.delete(fileIndex)
            : projectBackground
        }
      )

    case requestSuccess(actionTypes.ADD_VET_SUPPLIER_BY_EMAIL):
      return state.set('addByEmail', fromJS(action.payload))

    case requestSuccess(actionTypes.INVITE_VET_SUPPLIER):
      return !state.getIn(['data', 'supplierFirstInviteDate'])
        ? state
            .setIn(['data', 'decision'], 'Sent')
            .setIn(['data', 'isPrivate'], false)
            .setIn(
              ['data', 'supplierFirstInviteDate'],
              new Date().toISOString()
            )
        : state
            .setIn(['data', 'decision'], 'Sent')
            .setIn(['data', 'isPrivate'], false)

    case actionTypes.INVITE_SUPPLIER_FROM_VET:
      return state.setIn(['addByEmail', 'creatingSupplier'], true)

    case actionTypes.RESET_ADD_BY_EMAIL:
      return state.set('addByEmail', Map({}))

    case requestSuccess(actionTypes.ADD_VET_ADDITION):
      return state.updateIn(['data', 'additions'], additions =>
        additions
          ? additions.push(fromJS(action.payload))
          : fromJS([action.payload])
      )

    case requestSuccess(actionTypes.CANCEL_VET_ADDITION):
      return state.updateIn(
        ['data', 'additions', action.payload.index],
        addition => addition.set('active', false)
      )

    case requestSuccess(actionTypes.ADD_VET_SUPPLIER):
      return !state.getIn(['data', 'supplierFirstSuggestDate'])
        ? state.setIn(
            ['data', 'supplierFirstSuggestDate'],
            new Date().toISOString()
          )
        : state

    case actionTypes.LOAD_VET_QUESTIONS:
      return state.set('isLoadingVetQuestions', true)

    case requestSuccess(actionTypes.LOAD_VET_QUESTIONS):
      return state
        .set('vetQuestions', fromJS(action.payload.questions))
        .set('isLoadingVetQuestions', false)

    case actionTypes.ANSWER_VET_QUESTION:
      return state.update('vetQuestions', vetQuestions => {
        const vetQuestionIndex = vetQuestions.findIndex(
          question => question.get('id') === action.payload.questionId
        )
        return vetQuestions
          .mergeIn(
            [vetQuestionIndex, 'response'],
            fromJS({
              response: action.payload.response,
              isPublic: action.payload.isPublic
            })
          )
          .setIn([vetQuestionIndex, 'question'], action.payload.question)
      })

    case requestSuccess(actionTypes.ANSWER_VET_QUESTION):
      return state.update('vetQuestions', vetQuestions => {
        const vetQuestionIndex = vetQuestions.findIndex(
          question => question.get('id') === action.payload.id
        )
        return vetQuestions.set(vetQuestionIndex, fromJS(action.payload))
      })

    case LOGOUT:
      return defaultState
    default:
      return state
  }
}

export default currentVetReducer
