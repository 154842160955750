import React, { FunctionComponent, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { fromJS, List, RecordOf } from 'immutable'
import searchContactsSelectors from '../../../shared/selectors/searchContactsSelectors'
import Text from 'shared/components/Text'
import Divider from 'shared/components/Divider'
import Switch from 'shared/components/Switch'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import qs from 'qs'
import SearchLocationContainer from '../SearchLocationContainer'
import FilterCheckboxList from '../../components/FilterCheckboxList'
import RootState from 'shared/models/RootState'

function filterLocationFilters(filtersForKey = fromJS([]), value) {
  return filtersForKey.filter(item => !item.startsWith(value))
}

function addFilterIfDoesntExist(filtersArray = fromJS([]), value) {
  return filtersArray.filter(val => val !== value).push(value)
}

const extractList = (maybeList: List<string | List<string>>): List<string> => {
  const firstValue = maybeList.first()
  return List.isList(firstValue) ? firstValue : maybeList
}

function shouldShowFilters({
  filters,
  selectedFilters,
  selectedParentFilters,
  initialSelectedParentFilters,
  matchAny,
  isSearching
}) {
  // the parent filter must be selected
  return (
    selectedParentFilters &&
    selectedParentFilters.size > 0 &&
    // show the filters when there are actual options
    filters &&
    filters.size > 0 &&
    // if we are in "match any mode"
    // then our last search musht have included a parent filter
    (!matchAny ||
      (initialSelectedParentFilters && // if our last search was in "match any mode"
        // then there must still be a selected filter
        (!List.isList(initialSelectedParentFilters.first()) ||
          initialSelectedParentFilters.first().size > 0) &&
        initialSelectedParentFilters.size > 0)) &&
    // hide the filters when searching or a filter has been selected
    (!isSearching || (selectedFilters && selectedFilters.size > 0))
  )
}
type ItemsProps = List<
  RecordOf<{
    key: string
    key_as_string?: string
    doc_count: number
  }>
>
type Query = RecordOf<{
  scope: string
  filter: {
    country?: List<string>
    state?: List<string>
    city?: List<string>
    source?: List<string>
    isPublic?: List<string>
    roles?: List<string>
    scope?: string
  }
}>

type Props = {
  query: Query
  country: ItemsProps
  state: ItemsProps
  city: ItemsProps
  showMatchAny: boolean
  isSearching: boolean
  selectedCountry: List<string | List<string>>
  selectedState: List<string | List<string>>
  selectedCity: List<string | List<string>>
} & RouteComponentProps

export const LocationFiltersContainer: FunctionComponent<Props> = props => {
  const [matchAny, setMatchAny] = useState(false)

  const [selectedCountry, setSelectedCountry] = useState<List<string>>(List())

  const [selectedState, setSelectedState] = useState<List<string>>(List())

  const [selectedCity, setSelectedCity] = useState<List<string>>(List())

  useEffect(() => {
    const selectedCountry = props.selectedCountry || fromJS([])
    const selectedState = props.selectedState || fromJS([])
    const selectedCity = props.selectedCity || fromJS([])

    setMatchAny(
      !props.selectedCountry
        ? false
        : !!List.isList(props.selectedCountry.first())
    )

    setSelectedCountry(extractList(selectedCountry))
    setSelectedState(extractList(selectedState))
    setSelectedCity(extractList(selectedCity))
  }, [props.selectedCity, props.selectedCountry, props.selectedState])

  const handleChange = filterKey => e => {
    const { history, query } = props

    const { name, checked } = e.currentTarget

    if (!matchAny) {
      const newQuery = checked
        ? query.updateIn(['filter', filterKey], (filtersForKey = fromJS([])) =>
            filtersForKey.push(name)
          )
        : query.updateIn(['filter'], (filters = fromJS({})) => {
            return filters.merge({
              country: filterLocationFilters(filters.get('country'), name),
              state: filterLocationFilters(filters.get('state'), name),
              city: filterLocationFilters(filters.get('city'), name)
            })
          })

      history.push({
        search: qs.stringify(newQuery.toJS())
      })
    } else {
      if (checked) {
        if (filterKey === 'country') {
          setSelectedCountry(selectedCountry => selectedCountry.push(name))
        }
        if (filterKey === 'state') {
          setSelectedState(selectedState => selectedState.push(name))
        }
        if (filterKey === 'city') {
          setSelectedCity(selectedCity => selectedCity.push(name))
        }
        const splitName = name.split('::')
        // update the selected country if selecting a state or city
        if (filterKey === 'state' || filterKey === 'city') {
          setSelectedCountry(
            addFilterIfDoesntExist(selectedCountry, splitName[0])
          )
        }
        // update the selected state if selecting a city
        if (filterKey === 'city') {
          setSelectedState(
            addFilterIfDoesntExist(
              selectedState,
              `${splitName[0]}::${splitName[1]}`
            )
          )
        }
      } else {
        setSelectedCountry(filterLocationFilters(selectedCountry, name))
        setSelectedState(filterLocationFilters(selectedState, name))
        setSelectedCity(filterLocationFilters(selectedCity, name))
      }
    }
  }

  const toggleMatchAny = () => {
    const { history, query } = props

    if (!matchAny) {
      setMatchAny(true)
    } else {
      const newFilters =
        selectedCountry.size < 2 &&
        selectedState.size < 2 &&
        selectedCity.size < 2
          ? {
              country: selectedCountry,
              state: selectedState,
              city: selectedCity
            }
          : {
              country: undefined,
              state: undefined,
              city: undefined
            }

      const newQuery = query.mergeIn(['filter'], newFilters)
      history.push({
        search: qs.stringify(newQuery.toJS())
      })
    }
  }

  const handleApplyClick = () => {
    const { history, query } = props

    const newQuery = query.mergeIn(['filter'], {
      country: [selectedCountry],
      state: [selectedState],
      city: [selectedCity]
    })

    history.push({
      search: qs.stringify(newQuery.toJS())
    })
  }

  const {
    country,
    state,
    city,
    showMatchAny,
    isSearching,
    selectedCountry: initialSelectedCountry,
    selectedState: initialSelectedState
  } = props

  const showStateFilters = shouldShowFilters({
    filters: state,
    selectedFilters: selectedState,
    selectedParentFilters: selectedCountry,
    initialSelectedParentFilters: initialSelectedCountry,
    matchAny,
    isSearching
  })

  const showCityFilters = shouldShowFilters({
    filters: city,
    selectedFilters: selectedCity,
    selectedParentFilters: selectedState,
    initialSelectedParentFilters: initialSelectedState,
    matchAny,
    isSearching
  })

  return (
    <>
      {showMatchAny && (
        <div className='flex items-center justify-between mb2'>
          <Text secondary={!matchAny}>
            <FormattedMessage
              id='LocationFiltersContainer.MatchesAnyOf'
              defaultMessage='Matches any of...'
            />
          </Text>
          <Switch checked={matchAny} onChange={toggleMatchAny} />
        </div>
      )}

      {(!selectedCity || selectedCity.size < 1) && !matchAny && (
        <SearchLocationContainer />
      )}

      <>
        {country && (
          <FilterCheckboxList
            items={country}
            selectedItems={selectedCountry}
            onChange={handleChange('country')}
            isSearching={isSearching}
          />
        )}

        {showStateFilters && (
          <>
            <Divider className='mv1' />
            <div className='pl2'>
              <FilterCheckboxList
                // filterKey='state'
                items={state}
                selectedItems={selectedState}
                onChange={handleChange('state')}
                isSearching={isSearching}
              />
            </div>
          </>
        )}

        {showCityFilters && (
          <>
            <Divider className='mv1' />
            <div className='pl3'>
              <FilterCheckboxList
                // filterKey='city'
                items={city}
                selectedItems={selectedCity}
                onChange={handleChange('city')}
                isSearching={isSearching}
              />
            </div>
          </>
        )}
      </>

      {matchAny && (
        <div className='tr'>
          <Divider className='mt1 mb2' />
          <Button
            label={
              <FormattedMessage
                id='LocationFiltersContainer.apply'
                defaultMessage='Apply'
              />
            }
            autoSize
            disabled={isSearching}
            onClick={handleApplyClick}
          />
        </div>
      )}
    </>
  )
}

type ContainerProps = {
  location: { search: string }
}
export default withRouter(
  connect((state: RootState, props: ContainerProps) => {
    const query = searchContactsSelectors.getParsedQuery(props.location.search)
    const filters = query.get('filter') || fromJS({})
    const resultFilters =
      state.getIn(['buyer', 'contactsSearch', 'resultFilters']) || fromJS({})

    return {
      isSearching: state.getIn(['buyer', 'contactsSearch', 'isSearching']),
      query,
      selectedCountry: filters.get('country'),
      selectedState: filters.get('state'),
      selectedCity: filters.get('city'),
      country: resultFilters.get('country'),
      state: resultFilters.get('state'),
      city: resultFilters.get('city'),
      showMatchAny: true
    }
  })(LocationFiltersContainer)
)
