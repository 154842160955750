import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import vets from 'shared/utils/api/vets'

function* upVoteSaga(action) {
  const vetBuyerSupplierId = action.payload
  const voteResults = yield call(vets.upVoteVetSupplier, { vetBuyerSupplierId })

  return yield { vetBuyerSupplierId, voteResults }
}

export default createApiRequestSaga(upVoteSaga)
