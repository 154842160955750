import { fromJS } from 'immutable'
import sessionSelectors from '../sessionSelectors'
import { createSelector } from 'reselect'

export const getCurrentUserOrgUnit = state => {
  const currentUserOrgUnitId = sessionSelectors.getOrgUnitId(state)
  return getById(state, currentUserOrgUnitId)
}
export const getOrgs = state => state.getIn(['orgs', 'byId']) || fromJS({})
export const getById = (state, orgId) =>
  state.getIn(['orgs', 'byId', orgId]) || fromJS({})
export const getSuggestedIds = state => {
  const orgUnitId = sessionSelectors.getOrgUnitId(state)
  const suggests = state.getIn(['orgs', 'suggestedIds'])
  return suggests && suggests.filter(org => org !== orgUnitId)
}
export const getSuggestQuery = state => state.getIn(['orgs', 'suggestQuery'])
export const loadingSuggestions = state =>
  state.getIn(['orgs', 'loadingSuggestions'])
export const getOrgsName = (state, orgIds) =>
  getOrgs(state).filter(org => orgIds && orgIds.includes(org.get('id')))
export const newCustomerDialogIsOpen = state =>
  state.getIn(['orgs', 'newCustomerDialogIsOpen'])
export const getMatchingOrgs = state => state.getIn(['orgs', 'matchingOrgs'])
export const isCustomerAdded = state => state.getIn(['orgs', 'customerAdded'])

export const getLocale = state => state.get('i18n').locale

export const getByIdUpdatedData = createSelector(
  [getById, getLocale],
  (org, lang) => {
    const description = org.get('description')
    const longDescription = org.get('longDescription')

    return org.merge({
      description:
        !description || typeof description === 'string'
          ? description
          : description.get(lang) ||
            description.get('en') ||
            description
              .toList()
              .filter(d => !!d)
              .first(),
      longDescription:
        !longDescription || typeof longDescription === 'string'
          ? longDescription
          : longDescription?.get(lang) || description?.get('en')
    })
  }
)
