import React, { Component } from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import { FormattedMessage } from 'react-intl'

type Props = {
  communityId: string
}

export class CommunityNavBar extends Component<Props> {
  render() {
    const { communityId } = this.props

    return (
      <div>
        <NavBar>
          <FormattedMessage
            id='CommunityNavBar.DetailsLabel'
            defaultMessage='Details'
          >
            {label => (
              <NavLink
                label={label}
                to={`${parsePath(paths.community, { communityId })}`}
                isActive={(match, location) =>
                  location.pathname ===
                  parsePath(paths.community, { communityId })
                }
              />
            )}
          </FormattedMessage>
          <FormattedMessage
            id='CommunityNavBar.FormLabel'
            defaultMessage='Form'
          >
            {label => (
              <NavLink
                label={label}
                to={`${parsePath(paths.communityForm, {
                  communityId,
                  pageNum: 1
                })}`}
                isActive={(match, location) => {
                  return (
                    location.pathname.startsWith(
                      parsePath(paths.communityFormBasePath, { communityId })
                    ) ||
                    location.pathname ===
                      parsePath(paths.beginCommunityForm, { communityId }) ||
                    location.pathname ===
                      parsePath(paths.finishCommunityForm, { communityId })
                  )
                }}
              />
            )}
          </FormattedMessage>
        </NavBar>
      </div>
    )
  }
}

export default CommunityNavBar
