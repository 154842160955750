import React, { useState, useMemo, useEffect, ChangeEvent } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import PageSection from 'shared/components/PageSection'
import { useSelector } from 'react-redux'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import { useDispatch } from 'react-redux'
import { getBulkEsgSuppliers } from '../../store/actions'
import bulkActionsSelectors from 'buyer/Insights/store/bulkActionsSelectors'
import Loading from 'shared/components/Loading'
import { List, RecordOf } from 'immutable'
import {
  BulkSupplier,
  Contact
} from '../../store/bulkActionsReducer/bulkActionsReducer'
import Paper from 'shared/components/Paper'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg'
import ContactItem from 'shared/components/ContactItem'
import fileToUrl from 'shared/utils/data/fileToUrl'
import { makeStyles, MenuItem, Select } from '@material-ui/core'
import Checkbox from 'shared/components/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useSmallScreen from 'shared/utils/useSmallScreen'

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '0.825rem',
    fontWeight: 400,
    padding: '5px',
    paddingLeft: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#F5FAFA',
    border: '1px solid #028383'
  },
  bold: {
    fontWeight: 600,
    paddingRight: '4px'
  },
  button: {
    border: 'none',
    backgroundColor: 'inherit',
    cursor: 'pointer',
    color: '#028383'
  },
  centerAlignment: {
    display: 'flex',
    alignItems: 'center'
  },
  overflowVisible: {
    overflow: 'visible!important' as 'visible'
  }
}))

const messages = defineMessages({
  placeholderSearch: {
    id: 'BulkOutreach.SearchForASupplier',
    defaultMessage: 'Search for a Supplier'
  }
})

const BulkOutreach = () => {
  const classes = useStyles()
  const isMobile = useSmallScreen()
  const dispatch = useDispatch()
  const intl = useIntl()
  const loading = useSelector(bulkActionsSelectors.isLoading)
  const suppliers: List<BulkSupplier> = useSelector(
    bulkActionsSelectors.getSuppliers
  )
  const styles = useStyles()

  useEffect(() => {
    dispatch(getBulkEsgSuppliers())
  }, [dispatch])
  const [selectedContacts, setSelectedContacts] = useState(
    new Map<string, string | undefined>()
  )
  const [searchString, setSearchString] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')
  const [sortBy, setSortBy] = useState<
    'supplier' | 'contacts' | 'ytdSpend' | ''
  >('')
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([])

  useMemo(() => {
    let defaultContacts = new Map()
    suppliers.forEach(supplier => {
      defaultContacts.set(
        supplier.supplier.orgUnitId,
        supplier.contacts[0] ? supplier.contacts[0].userId : undefined
      )
    })
    setSelectedContacts(new Map([...defaultContacts]))
  }, [suppliers])

  const sortedSuppliers: List<BulkSupplier> = useMemo(() => {
    return suppliers
      ?.filter(supplier => {
        return (
          !searchString ||
          supplier.supplier.name
            .toLowerCase()
            .includes(searchString.toLowerCase())
        )
      })
      .sort((supplier1, supplier2) => {
        let sortValue1: number | string, sortValue2: number | string

        if (!sortBy) {
          return 0
        }

        if (sortBy === 'supplier') {
          sortValue1 = supplier1.supplier.name
          sortValue2 = supplier2.supplier.name
        } else if (sortBy === 'contacts') {
          const { contacts: contacts1 } = supplier1
          const { contacts: contacts2 } = supplier2

          const contact1: Contact | undefined =
            contacts1 &&
            contacts1.find(
              c =>
                c.userId === selectedContacts.get(supplier1.supplier.orgUnitId)
            )
          const contact2: Contact | undefined =
            contacts2 &&
            contacts2.find(
              c =>
                c.userId === selectedContacts.get(supplier2.supplier.orgUnitId)
            )

          sortValue1 = contact1
            ? `${contact1.firstName || ''} ${contact1.lastName || ''}`
            : ''
          sortValue2 = contact2
            ? `${contact2.firstName || ''} ${contact2.lastName || ''}`
            : ''
        } else {
          sortValue1 = supplier1.ytdSpend?.toString() || ''
          sortValue2 = supplier2.ytdSpend?.toString() || ''
        }

        if (sortDirection === 'ASC') {
          return sortValue1.toLowerCase() > sortValue2.toLowerCase() ? 1 : -1
        } else {
          return sortValue1.toLowerCase() < sortValue2.toLowerCase() ? 1 : -1
        }
      })
  }, [suppliers, sortBy, sortDirection, searchString, selectedContacts])

  const getRow = ({ index }) => {
    return (
      sortedSuppliers && sortedSuppliers.size > 0 && sortedSuppliers.get(index)
    )
  }

  const handleSortChange = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value)
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const value = e.currentTarget.value
    const index = selectedSuppliers.indexOf(value)
    if (index !== -1) {
      selectedSuppliers.splice(index, 1)
      setSelectedSuppliers([...selectedSuppliers])
    } else {
      setSelectedSuppliers([...selectedSuppliers, value])
    }
  }

  const renderCheckboxCell = ({
    rowData
  }: {
    rowData: RecordOf<BulkSupplier>
  }) => {
    const value = rowData.supplier.orgUnitId
    return (
      <input
        type='checkbox'
        value={value}
        checked={selectedSuppliers.includes(value)}
        onChange={handleCheckboxChange}
      />
    )
  }

  const renderSupplierName = ({ cellData }: TableCellProps) => {
    return cellData.name
  }

  const handleChange = (e, company) => {
    setSelectedContacts(
      new Map([...selectedContacts, [company, e.target.value]])
    )
  }

  const renderContact = ({ cellData, rowData }: TableCellProps) => {
    const orgUnitId = rowData.supplier.orgUnitId
    const selectedUserId = selectedContacts.get(orgUnitId)
    const selectedContact = cellData.find(c => c.userId === selectedUserId)
    return selectedContact ? (
      <Select
        value={selectedContact.userId}
        variant='outlined'
        className='w-75'
        onChange={e => handleChange(e, rowData.supplier.orgUnitId)}
        style={{ height: '48px' }}
        aria-label={`Selected Contact: ${
          selectedContact.firstName ? selectedContact.firstName : ''
        } ${selectedContact.lastName ? selectedContact.lastName : ''}`}
      >
        {cellData.map((contact: Contact, index) => (
          <MenuItem key={contact.userId} value={contact.userId}>
            <ContactItem
              firstName={contact.firstName}
              lastName={contact.lastName}
              title={contact.title}
              profilePictureUrl={fileToUrl(contact.profilePicture)}
              isSustainabilityContact={!!contact.sustainabilityContact}
              isEsgSurvey
            />
          </MenuItem>
        ))}
      </Select>
    ) : (
      'Please add a contact'
    )
  }

  const renderYtdSpend = ({ cellData }: TableCellProps) => {
    return <div>{cellData ? `$${cellData.toLocaleString()}` : '-'}</div>
  }

  return (
    <PageSection
      noPadding
      noPaper
      title={
        <FormattedMessage
          id='BulkOutreach.BulkAction'
          defaultMessage='Bulk Actions'
        />
      }
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className='flex justify-between mb3'>
            <div className='w-50'>
              <Input
                ariaLabel='Search for a Supplier'
                placeholder={intl.formatMessage(messages.placeholderSearch)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                value={searchString}
                onChange={handleSearchChange}
              />
            </div>
            <div className='w50'></div>
          </div>
          <Paper noPadding>
            {selectedSuppliers.length > 0 && (
              <div className={classes.root}>
                <div className={classes.centerAlignment}>
                  <div className={classes.bold}>{selectedSuppliers.length}</div>
                  <FormattedMessage
                    id='BulkOutreach.SuppliersSelected'
                    defaultMessage='{count, plural, one {supplier selected.} other {suppliers selected.}}'
                    values={{
                      count: selectedSuppliers.length.toLocaleString()
                    }}
                  />
                  <button
                    className={classes.button + ' ' + classes.bold}
                    onClick={() => setSelectedSuppliers([])}
                  >
                    <FormattedMessage
                      id='BulkOutreach.ClearSelection'
                      defaultMessage='Clear selection'
                    />
                  </button>
                </div>
                <IconButton
                  className={classes.button}
                  onClick={() => setSelectedSuppliers([])}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            <Table
              rowGetter={getRow}
              rowCount={sortedSuppliers.size}
              sort={handleSortChange}
              sortDirection={sortDirection}
              sortBy={sortBy}
              rowHeight={65}
              minWidth={1000}
            >
              <Column
                label={
                  <Checkbox
                    checked={
                      selectedSuppliers.length === sortedSuppliers?.size &&
                      sortedSuppliers?.size !== 0
                    }
                    onChange={e => {
                      e.target.checked
                        ? setSelectedSuppliers(
                            Array.from(
                              sortedSuppliers.map(s => s.supplier.orgUnitId)
                            )
                          )
                        : setSelectedSuppliers([])
                    }}
                  />
                }
                dataKey='id'
                cellRenderer={renderCheckboxCell}
                width={isMobile ? 44 : 24}
                disableSort
              />
              <Column
                label={
                  <FormattedMessage
                    id='BulkOutreach.SupplierName'
                    defaultMessage='Supplier Name'
                  />
                }
                aria-label='Supplier Name Column'
                dataKey='supplier'
                cellRenderer={renderSupplierName}
                width={400}
              />
              <Column
                label={
                  <FormattedMessage
                    id='BulkOutreach.Contact'
                    defaultMessage='Contact'
                  />
                }
                aria-label='Contact Column'
                dataKey='contacts'
                cellRenderer={renderContact}
                width={400}
                className={styles.overflowVisible}
              />
              <Column
                label={
                  <FormattedMessage
                    id='BulkOutreach.YTDSpendAmount'
                    defaultMessage='YTD Spend'
                  />
                }
                aria-label='YTD Spend Column'
                dataKey='ytdSpend'
                cellRenderer={renderYtdSpend}
                width={100}
              />
            </Table>
            {!sortedSuppliers?.size && (
              <Text className='tc pv4'>
                <FormattedMessage
                  id='BulkOutreach.NoSupplier'
                  defaultMessage='No Supplier Found'
                />
              </Text>
            )}
          </Paper>
        </>
      )}
    </PageSection>
  )
}

export default BulkOutreach
