import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import cards from 'shared/utils/api/cards'

export function* removeRatingResponsesSaga(action) {
  yield call(cards.deleteRatingResponses, action.payload)
  return yield { cardId: action.payload, cardChanges: { ratingResponses: {} } }
}

export default createApiRequestSaga(removeRatingResponsesSaga)
