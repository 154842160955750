import React, { Component } from 'react'
import TealbookLogo from './tealbook-logo.png'
import TealbookWhiteLogo from './tealbook-logo-white.png'
import CCABLogo from './ccab-logo.png'
import RFAQLogo from './rfaq-logo.svg'
import iValuaLogo from './iValua-logo.png'

/**
 * TealbookLogo is a html img tag with a fixed image of a tealbook logo
 */

type Props = {
  isLoadingLogo?: boolean
  clientOf?: 'custom' | 'ccab' | 'ivalua' | 'rfaq'
  className?: string
  white?: boolean
  customLogoUrl?: string
}

class AppLogo extends Component<Props> {
  render() {
    const {
      className,
      white,
      clientOf,
      customLogoUrl,
      isLoadingLogo
    } = this.props

    const logoSrc =
      clientOf === 'custom' && customLogoUrl
        ? customLogoUrl
        : clientOf === 'ccab'
        ? CCABLogo
        : clientOf === 'ivalua'
        ? iValuaLogo
        : clientOf === 'rfaq'
        ? RFAQLogo
        : white
        ? TealbookWhiteLogo
        : TealbookLogo

    if (isLoadingLogo) {
      return null
    }

    return <img src={logoSrc} className={className || 'h1-5'} alt='logo' />
  }
}

export default AppLogo
