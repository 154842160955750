import React from 'react'
import PostBySection, { PostBySectionProps } from './PostBySection'

type Props = {
  id: string
  title: string
  imageUrl?: string
  onClick: (postId: string) => void
} & PostBySectionProps

const SpotlightCard = (props: Props) => {
  const { title, id, imageUrl, onClick } = props

  return title ? (
    <div
      className={`bg-white br2 mh2 ba b--black-10 ${
        title.length < 70 ? 'f4' : ''
      }`}
      style={{ maxWidth: 329 }}
    >
      <div
        className='white pointer dim flex items-center pa3 br2 fw6 lh-copy br--top'
        style={{
          height: 150,
          backgroundColor: '#009CB3',
          backgroundImage: imageUrl
            ? `
            linear-gradient( rgba(0, 156, 179,.75), rgba(0, 156, 179,.75) ),
            url('${imageUrl}')
          `
            : 'none',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
        onClick={() => onClick(id)}
      >
        {title}
      </div>
      <div className='pa2'>
        <PostBySection {...props} />
      </div>
    </div>
  ) : null
}

export default SpotlightCard
