import React, { Component } from 'react'
import { isSubmitting, formValueSelector } from 'redux-form/immutable'
import { RESET_PASSWORD } from '../../actionTypes'
import connectReduxForm from 'shared/utils/connectReduxForm'
import { getFormSubmitErrors } from 'shared/reducers'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import EnterEmailForm from '../../components/EnterEmailForm'
import TealbookLogoWrapper from '../../components/TealbookLogoWrapper'
import { FormattedMessage } from 'react-intl'
import users from 'shared/utils/api/users'
import ListItem from 'shared/components/List/ListItem'
import fileToUrl from 'shared/utils/data/fileToUrl'
import OrgLogo from 'shared/components/OrgLogo'
import Card from 'shared/components/Card'
import Text from 'shared/components/Text'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { List, RecordOf } from 'immutable'
import UploadedFile from 'shared/models/UploadedFile'
import RootState from 'shared/models/RootState'
import { RouteComponentProps } from 'react-router-dom'
import qs from 'qs'

type OrgUnits = {
  userId: string
  orgUnitId: string
  orgUnitLogo: UploadedFile
  orgUnitName: string
  domains: Array<string>
}

type Props = {
  resetPasswordRequest: ({ email, orgUnitId, userId: string }) => void
  handleSubmit: (values: {}) => () => void
  errorMessage: string
  email: string
  loginOptions: string
  client?: string
}
type State = {
  orgUnits?: List<RecordOf<OrgUnits>>
  userNotFound: boolean
  selectedOrgUnit
}
export class ResetPasswordForm extends Component<Props, State> {
  state: State = {
    userNotFound: false,
    selectedOrgUnit: undefined,
    orgUnits: undefined
  }

  handleSubmit = values => {
    const { resetPasswordRequest } = this.props
    const { selectedOrgUnit } = this.state

    if (values.has('email') && !selectedOrgUnit) {
      users.getAllByEmail(values.get('email')).then(result => {
        // multiple org units to choose from
        if (result.length > 1) {
          this.setState({
            orgUnits: result,
            userNotFound: false
          })
          // only one org unit to login to
        } else if (result.length === 1) {
          resetPasswordRequest({
            email: values.get('email'),
            orgUnitId: result[0].orgUnitId,
            userId: result[0].userId
          })
          // the user doesn't exist
        } else {
          this.setState({
            userNotFound: true
          })
        }
      })
    }
  }

  render() {
    const {
      resetPasswordRequest,
      handleSubmit,
      errorMessage,
      email,
      loginOptions,
      client
    } = this.props
    const { orgUnits, selectedOrgUnit, userNotFound } = this.state
    const options = !!loginOptions && loginOptions.split(',')

    return (
      <TealbookLogoWrapper
        clientOf={client}
        small={options && options.includes('iframe')}
      >
        {orgUnits && !selectedOrgUnit && (
          <Card>
            <div className='tl'>
              <div className='flex justify-between'>
                <Text>
                  <FormattedMessage
                    id='ResetPasswordForm.multipleOrgs'
                    defaultMessage={`
                      More than one company was found linked to this email.
                      Which one would you like to reset your password?
                    `}
                  />
                </Text>
                <IconButton
                  onClick={() => this.setState({ orgUnits: undefined })}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              {orgUnits.map(orgUnit => (
                <div
                  key={orgUnit.orgUnitId}
                  className='pointer hover-bg-near-white ph3'
                  onClick={() =>
                    resetPasswordRequest({
                      email,
                      orgUnitId: orgUnit.orgUnitId,
                      userId: orgUnit.userId
                    })
                  }
                >
                  <ListItem
                    leftAvatar={
                      <OrgLogo
                        url={fileToUrl(orgUnit.orgUnitLogo)}
                        name={orgUnit.orgUnitName}
                      />
                    }
                    primaryText={orgUnit.orgUnitName}
                    secondaryText={orgUnit.domains && orgUnit.domains.join(' ')}
                  />
                </div>
              ))}
            </div>
          </Card>
        )}
        {!orgUnits && (
          <FormattedMessage
            id='ResetPasswordForm.resetPassword'
            defaultMessage='Reset Password'
          >
            {submitLabel => (
              <FormattedMessage
                id='ResetPasswordForm.userNotFound'
                defaultMessage={`That email address doesn't have an associated account.`}
              >
                {notFoundMessage => (
                  <EnterEmailForm
                    handleSubmit={handleSubmit(this.handleSubmit)}
                    submitLabel={submitLabel}
                    errorMessage={userNotFound ? notFoundMessage : errorMessage}
                    loginOptions={loginOptions}
                    client={client}
                  />
                )}
              </FormattedMessage>
            )}
          </FormattedMessage>
        )}
      </TealbookLogoWrapper>
    )
  }
}

const formName = 'accounts/resetPassword'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)
const formIsSubmitting = isSubmitting(formName)
const formSelector = formValueSelector(formName)

export default connectReduxForm(
  (state: RootState, props: Pick<RouteComponentProps, 'location'>) => {
    const { options, email, client } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    })

    return {
      errorMessage: formSubmitErrorsSelector(state),
      submitting: formIsSubmitting(state),
      loginOptions: options,
      client,
      initialValues: {
        email
      },
      email: formSelector(state, 'email')
    }
  },
  {
    resetPasswordRequest: createSubmitFormAction(formName, RESET_PASSWORD)
  },
  {
    form: formName
  },
  ResetPasswordForm
)
