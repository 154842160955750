const parseNameFromUser = (
  email?: string,
  firstName?: string,
  lastName?: string
) =>
  firstName && lastName
    ? `${firstName} ${lastName}`
    : firstName || lastName || email

export default parseNameFromUser
