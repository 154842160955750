import React from 'react'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import SelfCertifyDialog from '../../components/SelfCertifyDialog'
import profileSelectors from '../../../SupplierProfile/selectors/profileSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import { messageUser } from 'shared/actions'
import {
  openAddOutreachContactForm,
  cancelAddingOutreachContact
} from '../../../../buyer/SupplierProfile/actions'
import { List, RecordOf } from 'immutable'
import User from 'shared/models/User'

type Props = {
  contacts: List<RecordOf<User>>
  activeUserId: string
  supplierId: string
  requestedContactId: string
  clientName: string
  taskId: string
  isSelfCertifyDialogOpen: boolean
  requestedContact: RecordOf<User>
  messageUser: (arg: string) => void
  handleToggleSelfCertifyDialog: () => void
  handleResendSupplierOutreachRequest: (
    taskId: string,
    status: string,
    currentUserId: string,
    selectedRequestContactId: string
  ) => void
  onClickNewUser: () => void
  onClickCancel: () => void
}

const SelfCertifyDialogContainer = (props: Props) => {
  const { handleToggleSelfCertifyDialog, onClickCancel } = props
  const handleCancel = () => {
    handleToggleSelfCertifyDialog()
  }
  const handleCancelSelfCertifyNewUserForm = () => {
    onClickCancel()
    handleToggleSelfCertifyDialog()
  }
  return (
    <SelfCertifyDialog
      handleCancel={handleCancel}
      onMessageUser={props.messageUser}
      handleCancelSelfCertifyNewUserForm={handleCancelSelfCertifyNewUserForm}
      {...props}
    />
  )
}

type ContainerProps = {
  requestedContactId: string
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const contacts = profileSelectors.getContactsForOutreachRequest(state)
    const orgUnitId = sessionSelectors.getOrgUnitId(state)
    const orgUnit = orgsSelectors.getById(state, orgUnitId)
    const requestedContact = usersSelectors.getById(
      state,
      props.requestedContactId
    )
    return {
      contacts,
      clientName: orgUnit.get('name'),
      requestedContact
    }
  },
  {
    messageUser,
    onClickNewUser: openAddOutreachContactForm,
    onClickCancel: cancelAddingOutreachContact
  }
)(SelfCertifyDialogContainer)
