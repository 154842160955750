import React, { useEffect, useState, useCallback } from 'react'
import Card from 'shared/components/Card'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import Warning from 'shared/components/Warning'
import { Field } from 'redux-form/immutable'
import TextArea from 'shared/components/TextArea'
import FileInput from 'shared/components/FileInput'
import formValidators from 'shared/utils/formValidators'
import { FormattedMessage } from 'react-intl'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import InputLabel from '@material-ui/core/InputLabel'
import Text from 'shared/components/Text'
import loader from 'shared/utils/api/loader'
import insight from 'shared/utils/api/insight'
import startCase from 'lodash.startcase'
import Checkbox from 'shared/components/Checkbox'
import { RouteComponentProps } from 'react-router'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import Divider from 'shared/components/Divider'
import Slider from '@material-ui/core/Slider'
import {
  getDateFromQuarter,
  generateMarks,
  getTextFromQuarter,
  getQuarterFromDate
} from 'shared/utils/sliderUtilsForSpendAndLoader'
import moment, { Moment } from 'moment'
import Switch from 'shared/components/Switch'
import Table from 'shared/components/Table'
import { Column, TableCellProps } from 'react-virtualized'
import dateFormat from 'shared/utils/dateFormat'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import { useDispatch } from 'react-redux'
import { notify } from 'shared/actions'
import exportData from 'shared/utils/exportData'

const styles = {
  page: 'mt4',
  section: 'mb3',
  readonly: 'mt2 db f7 gray'
}
type Props = {
  isTealbot: boolean
  handleSubmit: () => void
  minSpendDate?: Moment
  maxSpendDate?: Moment
  yearEnd: string
  checked: boolean
  initialValues: any
  enableNewLoader: boolean
  change: (field: string, value: any) => void
} & RouteComponentProps

const Loader = (props: Props) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    isTealbot,
    minSpendDate,
    maxSpendDate,
    yearEnd,
    enableNewLoader,
    change
  } = props

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()
  const [loaderList, setLoaderList] = useState<any[]>([])
  const [statById, setStatById] = useState<any>({})
  const [conciergeActionList, setConciergeActionList] = useState<any[]>([])
  const [cursorStart, setCursorStart] = useState<string>('')
  const [cursorEnd, setCursorEnd] = useState<string>('')
  const [selectedStatId, setSelectStatId] = useState<string>('')
  const [computeTrendPressed, setComputeTrendPressed] = useState<boolean>(false)
  const [sliderValue, setSliderValue] = useState<Array<number>>([])
  const [deleteSpendPressed, setDeleteSpendPressed] = useState<boolean>(false)
  const [
    deleteNoSpendAttributesPressed,
    setDeleteNoSpendAttributesPressed
  ] = useState<boolean>(false)
  const [apiResponse, setApiResponse] = useState<string>('')
  const [confirmDeleteSpendDialog, setConfirmDeleteSpendDialog] = useState<
    boolean
  >(false)
  const [deleteSpendLineFlag, setDeleteSpendLineFlag] = useState<boolean>(false)

  const getLoaderStatus = useCallback(async () => {
    let detailsById = {}
    let listForLoader = []
    let listForConciergeAction = []

    const loaderResponse = await loader
      .getClientLoaderStatus('loader')
      .catch(console.error)

    if (loaderResponse?.results) {
      listForLoader = loaderResponse.results.map(({ details, ...rest }) => ({
        percentComplete: details.progress,
        linesRead: details.linesRead,
        user: details.user,
        body: details.body,
        ...rest
      }))
      detailsById = loaderResponse.results.reduce((result, status) => {
        result[status.id] = status.details
        return result
      }, detailsById)
    }

    const conciergeActionResponse = await loader
      .getClientLoaderStatus('conciergeAction')
      .catch(console.error)

    if (conciergeActionResponse?.results) {
      listForConciergeAction = conciergeActionResponse.results.map(
        ({ details, ...rest }) => ({
          percentComplete: details.progress,
          linesRead: details.linesRead,
          type: startCase(details.action),
          user: details.user,
          body: details.body,
          ...rest
        })
      )
      detailsById = conciergeActionResponse.results.reduce((result, status) => {
        result[status.id] = status.details
        return result
      }, detailsById)
    }

    setCursorStart(conciergeActionResponse.cursorStart)
    setCursorEnd(conciergeActionResponse.cursorEnd)
    setLoaderList(listForLoader)
    setConciergeActionList(listForConciergeAction)
    setStatById(detailsById)
  }, [])

  useEffect(() => {
    getLoaderStatus()
  }, [getLoaderStatus])

  useEffect(() => {
    if (!intervalId) {
      const id = global.setInterval(getLoaderStatus, 20000)
      setIntervalId(id)
    }

    return () => {
      if (intervalId) {
        global.clearInterval(intervalId)
      }
    }
  }, [getLoaderStatus, intervalId])

  useEffect(() => {
    if (maxSpendDate) {
      setSliderValue([
        getQuarterFromDate(
          moment(maxSpendDate).subtract(1, 'quarter'),
          yearEnd
        ),
        getQuarterFromDate(maxSpendDate, yearEnd)
      ])
    }
  }, [maxSpendDate, yearEnd])

  const handleChange = (event, sliderValue) => {
    setSliderValue(sliderValue)
  }
  const onCheckBoxChange = e => {
    if (!deleteSpendLineFlag) {
      setConfirmDeleteSpendDialog(true)
    } else {
      setDeleteSpendLineFlag(false)
    }
  }

  const handleDownloadLog = (statId: string) => {
    insight
      .getStatLog(statId)
      .then((response: string[]) => {
        exportData.exportList(response, statId, 'log', false)
      })
      .catch(err =>
        dispatch(
          notify({
            message: err.message
          })
        )
      )
  }

  const renderLoadType = ({ rowData }: TableCellProps) => {
    const { body } = rowData
    let loadType
    if (body?.action === 'client') {
      loadType =
        body?.listType === 'supplierTier2Load' ? 'Tier2 Load' : 'Spend Load'
    } else {
      loadType = 'Data Load'
    }

    return <div>{loadType}</div>
  }

  return (
    <div className={styles.page}>
      <Main>
        <Card>
          <form onSubmit={handleSubmit}>
            {isTealbot && (
              <>
                <Field
                  component={Switch}
                  labelRight
                  name='enableNewLoader'
                  label='Enable "/newLoader"'
                />
                <Divider className='mv3' />
              </>
            )}

            <div className={styles.section}>
              <Field
                component={FileInput}
                accept='.txt, .json, .csv, .xlsx'
                className='mt3'
                name='attachedFile'
                limit={1000}
                required
              />
              <Warning
                message={
                  <FormattedMessage
                    id='Loader.MakeSureTheFileMeetsTheRequiredFormat'
                    defaultMessage='Make sure the file meets the required format'
                  />
                }
                className='mt2 mb4'
              />
              <Label className='mt3'>
                <FormattedMessage
                  id='Loader.RequestBody'
                  defaultMessage='Request Body (must be JSON)'
                />
              </Label>
              <Field
                component={TextArea}
                name='body'
                validate={[formValidators.isJSON]}
              />

              {isTealbot && (
                <Field
                  component={Checkbox}
                  name='deleteSpendLineItems'
                  label={
                    <FormattedMessage
                      id='Loader.DeleteSpend'
                      defaultMessage='Delete spend line items'
                    />
                  }
                  normalize={(newValue, oldValue) => {
                    return false
                  }}
                  onChange={onCheckBoxChange}
                />
              )}

              <Button
                label={
                  <FormattedMessage
                    id='Loader.UploadFile'
                    defaultMessage='Upload File'
                  />
                }
                type='submit'
                autoSize
                className='mt3'
              />
            </div>
          </form>
          <Divider className='mv3' />
          {loaderList?.length > 0 && (
            <>
              <Label noPadding>Loader Jobs Status</Label>
              <div className='bg-white br1 ba b--black-10 overflow-hidden'>
                <Table
                  rowGetter={({ index }) => loaderList[index]}
                  rowCount={loaderList.length}
                  onRowClick={({ rowData, index }) => {
                    if (index !== undefined) {
                      setSelectStatId(rowData.id)
                    }
                  }}
                  minWidth={750}
                >
                  <Column label={'ID'} dataKey='id' width={170} />
                  <Column
                    label={'Type'}
                    dataKey='id'
                    width={120}
                    cellRenderer={renderLoadType}
                  />
                  <Column
                    label={'Date'}
                    dataKey='created'
                    width={80}
                    cellRenderer={({ cellData }: TableCellProps) => {
                      return cellData && dateFormat(cellData.date)
                    }}
                  />
                  <Column label={'%'} dataKey='percentComplete' width={40} />
                  <Column label={'Lines Read'} dataKey='linesRead' width={70} />
                  <Column label={'User'} dataKey='user' width={170} />
                  <Column
                    label={'Action'}
                    dataKey='id'
                    width={75}
                    cellRenderer={({ cellData }) => {
                      return (
                        <Button
                          size='small'
                          variant='text'
                          onClick={() => handleDownloadLog(cellData)}
                        >
                          Download
                        </Button>
                      )
                    }}
                  />
                </Table>
              </div>
              <Divider className='mv3' />
            </>
          )}
          {conciergeActionList?.length > 0 && (
            <>
              <Label noPadding>Other Jobs Status</Label>
              <div className='bg-white br1 ba b--black-10 overflow-hidden'>
                <Table
                  rowGetter={({ index }) => conciergeActionList[index]}
                  rowCount={conciergeActionList.length}
                  onRowClick={({ rowData, index }) => {
                    if (index !== undefined) {
                      setSelectStatId(rowData.id)
                    }
                  }}
                >
                  <Column label={'ID'} dataKey='id' width={175} />
                  <Column
                    label={'Date'}
                    dataKey='created'
                    width={80}
                    cellRenderer={({ cellData }: TableCellProps) => {
                      return cellData && dateFormat(cellData.date)
                    }}
                  />
                  <Column
                    label={'% / Lines'}
                    dataKey='percentComplete'
                    width={75}
                  />
                  <Column label={'Type'} dataKey='type' width={130} />
                  <Column label={'User'} dataKey='user' width={130} />
                  <Column
                    label={'Action'}
                    dataKey='id'
                    width={75}
                    cellRenderer={({ cellData }) => {
                      return (
                        <Button
                          size='small'
                          variant='text'
                          onClick={() => handleDownloadLog(cellData)}
                        >
                          Download
                        </Button>
                      )
                    }}
                  />
                </Table>
              </div>
              <Divider className='mv3' />
            </>
          )}
          <Button
            autoSize
            disabled={computeTrendPressed}
            onClick={event => {
              api.post(
                `${apiRoutes.loader}/${
                  enableNewLoader ? 'util' : 'loader'
                }/computeTrends`
              )
              setComputeTrendPressed(true)
            }}
          >
            {!computeTrendPressed ? (
              <FormattedMessage
                id='Loader.ComputeTrends'
                defaultMessage='Compute Trends'
              />
            ) : (
              <FormattedMessage
                id='Loader.ComputeTrendsProcessing'
                defaultMessage='Computing Trends in Progress...'
              />
            )}
          </Button>
          <Divider className='mv3' />
          <Label className='mt3'>
            <FormattedMessage
              id='Loader.UpdateSpendDetails'
              defaultMessage='Update Spend Details'
            />
          </Label>
          <Label>
            <FormattedMessage
              id='Loader.DeleteSpendDetails'
              defaultMessage='Delete Spend Details'
            />
          </Label>
          <Text>
            <FormattedMessage
              id='Loader.SpendRangeDeleteInfo'
              defaultMessage='Note: use the slider to select a date range of spend details you would like to clear from all suppliers'
            />
          </Text>
          {minSpendDate && maxSpendDate && (
            <Slider
              aria-labelledby='range-slider'
              step={25}
              min={getQuarterFromDate(minSpendDate, yearEnd)}
              max={getQuarterFromDate(maxSpendDate, yearEnd)}
              onChange={handleChange}
              value={sliderValue}
              marks={generateMarks(
                getQuarterFromDate(minSpendDate, yearEnd),
                getQuarterFromDate(maxSpendDate, yearEnd),
                sliderValue
              )}
            />
          )}
          {sliderValue && sliderValue && (
            <div className='mv2'>
              <Text>
                <span className='mr1-ns'>
                  <FormattedMessage
                    id='Loader.DateRange'
                    defaultMessage='Selected Date Range: '
                  />
                </span>
                <FormattedMessage
                  id='Loader.DateRangeVal'
                  defaultMessage='{fromQuarter} to {toQuarter}'
                  values={{
                    fromQuarter: getTextFromQuarter(sliderValue[0]),
                    toQuarter: getTextFromQuarter(sliderValue[1])
                  }}
                />
              </Text>
            </div>
          )}
          <Button
            autoSize
            disabled={deleteSpendPressed}
            onClick={event => {
              api
                .remove(
                  `${apiRoutes.authService}/relationships/admin/deleteSpend`,
                  undefined,
                  {
                    startDate: getDateFromQuarter(
                      sliderValue[0],
                      false,
                      yearEnd
                    ),
                    endDate: getDateFromQuarter(sliderValue[1], true, yearEnd)
                  }
                )
                .then(response => {
                  if (response && response.status) {
                    setDeleteSpendPressed(false)
                    setApiResponse(
                      `${startCase(response.status)}!: Deleting spend range`
                    )
                  }
                })
              setDeleteSpendPressed(true)
              setApiResponse('')
            }}
          >
            {!deleteSpendPressed ? (
              <FormattedMessage
                id='Loader.DeleteSpendRange'
                defaultMessage='Delete Spend'
              />
            ) : (
              <FormattedMessage
                id='Loader.DeletingSpendRange'
                defaultMessage='Deleting Selected Spend'
              />
            )}
          </Button>
          <Divider className='mv3' />
          <div className='mv3'>
            <Text>
              <FormattedMessage
                id='Loader.DeleteAttributesInfo'
                defaultMessage='Click here to delete relationship attributes for suppliers without Spend Details'
              />
            </Text>
          </div>
          <Button
            autoSize
            disabled={deleteNoSpendAttributesPressed}
            onClick={event => {
              api
                .remove(
                  `${apiRoutes.authService}/relationships/admin/deleteNoSpendAttributes`
                )
                .then(response => {
                  if (response && response.status) {
                    setDeleteNoSpendAttributesPressed(false)
                    setApiResponse(
                      `${startCase(
                        response.status
                      )}!: Deleting relationship attributes`
                    )
                  }
                })
              setDeleteNoSpendAttributesPressed(true)
              setApiResponse('')
            }}
          >
            {!deleteNoSpendAttributesPressed ? (
              <FormattedMessage
                id='Loader.DeleteRelAttributes'
                defaultMessage='Delete Relationship Attributes'
              />
            ) : (
              <FormattedMessage
                id='Loader.DeletingRelAttributes'
                defaultMessage='Deleting Relationship Attributes'
              />
            )}
          </Button>
          {apiResponse.includes('Success') && (
            <div className='dt'>
              <div className='dtc f6 v-mid pl2 pt4'>{apiResponse}</div>
            </div>
          )}{' '}
          {apiResponse && !apiResponse.includes('Success') && (
            <Warning message={apiResponse} className='pt4 mb4' />
          )}
        </Card>
      </Main>
      <Aside>
        <Card>
          <InputLabel>Loader State</InputLabel>
          {cursorStart && (
            <div>
              <Label>Cursor Start</Label>
              <Text>{cursorStart?.toString()}</Text>
            </div>
          )}
          {cursorEnd && (
            <div>
              <Label>Cursor End</Label>
              <Text>{cursorEnd?.toString()}</Text>
            </div>
          )}
          {statById[selectedStatId] ? (
            Object.keys(statById[selectedStatId])
              .filter(key => !!statById[selectedStatId])
              .sort()
              .map(key => {
                const value = statById[selectedStatId][key]
                return (
                  <div key={key}>
                    <Label>{startCase(key)}</Label>
                    <Text>
                      {typeof value === 'object' && !Array.isArray(value)
                        ? JSON.stringify(value)
                        : value.toString()}
                    </Text>
                  </div>
                )
              })
          ) : (
            <Warning
              message={'Select a loader job row to see the details'}
              className='mt4'
            />
          )}
        </Card>
      </Aside>
      <ConfirmationDialog
        onClose={() => setConfirmDeleteSpendDialog(false)}
        open={confirmDeleteSpendDialog}
        onConfirm={() => {
          change('deleteSpendLineItems', true)
          setDeleteSpendLineFlag(true)
          setConfirmDeleteSpendDialog(false)
        }}
      >
        <Text>
          <FormattedMessage
            id='Loader.ConfirmDelete'
            defaultMessage='Are you sure you want to delete existing spend line items?'
          />
        </Text>
      </ConfirmationDialog>
    </div>
  )
}

export default Loader
