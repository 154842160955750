import React, { useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import Slider, { SliderProps } from '@material-ui/core/Slider'

const CustomSlider = withStyles({
  root: {
    marginTop: 14,
    marginLeft: 8,
    width: '90%'
  },
  mark: {
    height: 8,
    width: 1,
    marginTop: -3
  },
  markLabel: {
    marginLeft: '6px',
    fontSize: '12px'
  }
})(Slider)

export type Quarter = {
  quarter: number
  year: number
}

type Props = {
  minQuarter: Quarter
  maxQuarter: Quarter
  value: [Quarter, Quarter]
  onChange: (value: [Quarter, Quarter]) => void
  onChangeCommitted?: (value: [Quarter, Quarter]) => void
} & Omit<SliderProps, 'value' | 'onChange'>

const getSliderValueFromQuarter = (quarter: Quarter) =>
  quarter.year * 100 + (quarter.quarter - 1) * 25

const getQuarterFromSliderValue = (value: number): Quarter => ({
  year: Math.trunc(value / 100),
  quarter: (value % 100) / 25 + 1
})

type GenMarksArgs = {
  minQuarterValue: number
  maxQuarterValue: number
  selectedQuarterRange: [number, number]
}
const generateMarks = ({
  minQuarterValue,
  maxQuarterValue,
  selectedQuarterRange
}: GenMarksArgs) => {
  let markValue = minQuarterValue
  let sliderMarks: Array<{ value: number; label: string }> = []
  while (markValue <= maxQuarterValue) {
    const currentQuarter = getQuarterFromSliderValue(markValue)

    sliderMarks.push({
      value: markValue,
      /**
       * Display year and quarter for the following cases:
       *   - is the first mark, to see where does the slider start
       *   - the selected value ends on the first quarter
       */
      label:
        (markValue === minQuarterValue && markValue % 100 !== 0) ||
        (markValue === selectedQuarterRange[1] && markValue % 100 === 0)
          ? `${currentQuarter.year} Q${currentQuarter.quarter}`
          : // Display only year if it is the first quarter
          markValue % 100 === 0
          ? `${currentQuarter.year}`
          : // Display only the quarter if is within the selected range
          selectedQuarterRange[0] <= markValue &&
            markValue <= selectedQuarterRange[1]
          ? `Q${currentQuarter.quarter}`
          : ''
    })
    markValue = markValue + 25
  }
  return sliderMarks
}

const QuarterRangeSlider = (props: Props) => {
  const {
    minQuarter,
    maxQuarter,
    value,
    onChange,
    onChangeCommitted,
    ...rest
  } = props

  const minSliderValue = getSliderValueFromQuarter(minQuarter)
  const maxSliderValue = getSliderValueFromQuarter(maxQuarter)
  const sliderValue: [number, number] = useMemo(
    () => [
      getSliderValueFromQuarter(value[0]),
      getSliderValueFromQuarter(value[1])
    ],
    [value]
  )

  const sliderMarks = useMemo(
    () =>
      generateMarks({
        minQuarterValue: minSliderValue,
        maxQuarterValue: maxSliderValue,
        selectedQuarterRange: sliderValue
      }),
    [minSliderValue, maxSliderValue, sliderValue]
  )

  return (
    <CustomSlider
      aria-labelledby='range-slider'
      step={25}
      min={minSliderValue}
      max={maxSliderValue}
      marks={sliderMarks}
      onChange={(event, newValue) => {
        onChange([
          getQuarterFromSliderValue(newValue[0]),
          getQuarterFromSliderValue(newValue[1])
        ])
      }}
      onChangeCommitted={
        onChangeCommitted
          ? (event, newValue) => {
              onChangeCommitted([
                getQuarterFromSliderValue(newValue[0]),
                getQuarterFromSliderValue(newValue[1])
              ])
            }
          : undefined
      }
      value={sliderValue}
      {...rest}
    />
  )
}

export default QuarterRangeSlider
