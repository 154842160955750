import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { SowJobOrgUnit } from 'shared/models/Job'
import SowSideOrg from './SowSideOrg'

export const SortableSowSideOrg = SortableElement(SowSideOrg)

type Props = {
  disabled: boolean
  orgs?: Array<SowJobOrgUnit>
  currentOrgUnitId?: string
  onClick?: (orgUnitId: string) => void
  onDeprioritize?: (ele: HTMLElement, orgUnitId: string, row: number) => void
  onPrioritize?: (ele: HTMLElement, orgUnitId: string) => void
}

const SowSideList = (props: Props) => {
  const {
    orgs,
    disabled,
    currentOrgUnitId,
    onClick,
    onDeprioritize,
    onPrioritize
  } = props
  return (
    <div>
      {orgs?.map((org, index: number) => (
        <SortableSowSideOrg
          index={index}
          key={org.orgUnitId as string}
          orgUnitId={org.orgUnitId as string}
          rowNum={index}
          name={org.name as string}
          logoUrl={
            org.logoUrl ||
            (org.domains ? `https://logo.clearbit.com/${org.domains[0]}` : '')
          }
          isSelected={currentOrgUnitId === org.orgUnitId}
          isDisabled={disabled}
          onClick={() =>
            !disabled && onClick && onClick(org.orgUnitId as string)
          }
          onMenuDeprioritize={onDeprioritize}
          onMenuPrioritize={onPrioritize}
          disabled={disabled}
        />
      ))}
    </div>
  )
}

export default SortableContainer(SowSideList)
