const getEditUser = state => state.getIn(['admin', 'editUser'])

export const getUser = state => state.getIn(['admin', 'editUser', 'user'])
export const getUserRoles = state =>
  state.getIn(['admin', 'editUser', 'user', 'roles'])
export const isSaving = state => !!getEditUser(state).saving
export const getSavingError = state => getEditUser(state).saveError
export const getBulkUsersSelected = state =>
  state.getIn(['admin', 'editUser', 'bulkUsersSelected'])
export const getBulkErrorMessage = state =>
  state.getIn(['admin', 'editUser', 'bulkErrorMessage'])
