import React, { useState, useMemo, ReactNode } from 'react'
import { List } from 'immutable'
import { FormattedMessage } from 'react-intl'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from 'shared/components/Avatar'
import CreatableSelect from 'react-select/creatable'
import { RecordOf } from 'immutable'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import BlockIcon from '@material-ui/icons/Block'
import User from 'shared/models/User'

type OptionItemProps = {
  firstName?: string
  lastName?: string
  profilePictureUrl?: string
  email?: string
  blocked?: boolean
}
export const OptionItem = (props: OptionItemProps) => {
  const { firstName, lastName, profilePictureUrl, email, blocked } = props

  return (
    <MenuItem className='items-center'>
      <Avatar
        url={profilePictureUrl}
        className='w2'
        name={`${firstName || ''} ${lastName || ''}`}
      />

      <div className='pl2'>
        <div className='flex items-center'>
          <span className='truncate'>
            {!firstName && !lastName ? (
              email
            ) : (
              <>
                {firstName} {lastName}
              </>
            )}
          </span>
          {blocked && <BlockIcon fontSize='small' className='ml2' />}
        </div>

        {blocked && (
          <div className='f7 gray truncate'>
            <FormattedMessage
              id='UserLookup.useCannotBeUsed'
              defaultMessage='User cannot be used'
            />
          </div>
        )}
      </div>
    </MenuItem>
  )
}

type Props = {
  users: List<RecordOf<User>>
  label?: string | ReactNode
  onClickSuggestedUser: (user: RecordOf<User>) => void
  onClickNewUser?: (fullName: string) => void
}

const UserLookup = (props: Props) => {
  const { users, label, onClickSuggestedUser, onClickNewUser } = props
  const [selectInput, setSelectInput] = useState<string>('')
  const [openBlockedUserDialog, setOpenBlockedUserDialog] = useState<boolean>(
    false
  )

  const handleSelectInputChange = (inputValue: string) => {
    setSelectInput(inputValue)
  }

  const handleNewUserSelected = user => {
    if (user) {
      if (user.blocked) {
        setOpenBlockedUserDialog(true)
      } else if (onClickSuggestedUser) {
        onClickSuggestedUser(user)
      }
    }
    setSelectInput('')
  }

  const filterOption = props => {
    if (!selectInput || props.value) {
      return true
    }

    const user = props.data
    const userFullName = `${user.firstName &&
      user.firstName.trim()} ${user.lastName &&
      user.lastName.trim()}`.toLowerCase()

    return userFullName.includes(selectInput.toLowerCase())
  }

  const handleToggleBlockedUserDialog = value => {
    setOpenBlockedUserDialog(value)
  }

  const formatOptionLabel = (opt, data) => {
    if (opt.label) {
      return (
        <MenuItem>
          {opt.value ? (
            <FormattedMessage
              id='UserLookup.InviteUserName'
              defaultMessage='Invite "{name}"'
              values={{
                name: opt.value
              }}
            />
          ) : (
            <FormattedMessage
              id='UserLookup.InviteNewUser'
              defaultMessage='Invite a new User'
            />
          )}
        </MenuItem>
      )
    }
    return (
      <OptionItem
        key={opt.id}
        firstName={opt.firstName}
        lastName={opt.lastName}
        profilePictureUrl={opt.profilePictureUrl}
        email={opt.email}
        blocked={opt.blocked}
      />
    )
  }

  const sortUsers = useMemo(() => {
    return !users
      ? []
      : !selectInput
      ? users.toJS()
      : users
          .sort((userA, userB) => {
            let fullNameA = `${userA.get('firstName') &&
              userA.get('firstName').trim()} ${userA.get('lastName') &&
              userA.get('lastName').trim()}`.toLowerCase()
            let fullNameB = `${userB.get('firstName') &&
              userB.get('firstName').trim()} ${userB.get('lastName') &&
              userB.get('lastName').trim()}`.toLowerCase()
            fullNameA = fullNameA.startsWith(selectInput)
              ? `0${fullNameA}`
              : fullNameA
            fullNameB = fullNameB.startsWith(selectInput)
              ? `0${fullNameB}`
              : fullNameB

            if (fullNameA > fullNameB) {
              return 1
            }
            if (fullNameA < fullNameB) {
              return -1
            }
            return 0
          })
          .toJS()
  }, [users, selectInput])

  return (
    <>
      <label id='userLookupLabel' className='db pt3 mb1 f7 fw6'>
        {label}
      </label>
      <CreatableSelect
        role='combobox'
        aria-labelledby='userLookupLabel'
        aria-autocomplete='list'
        isValidNewOption={() => !!onClickNewUser}
        onCreateOption={() => {
          if (onClickNewUser) {
            onClickNewUser(selectInput)
          }
        }}
        formatCreateLabel={inputValue => {
          return inputValue
            ? `Invite ${inputValue} as a new user`
            : `Invite a new user`
        }}
        formatOptionLabel={formatOptionLabel}
        inputValue={selectInput}
        onInputChange={handleSelectInputChange}
        getOptionLabel={opt =>
          opt.firstName ? `Invite ${opt.firstName} as a new user` : opt.label
        }
        onChange={handleNewUserSelected}
        controlShouldRenderValue={false}
        defaultValue={{ value: 0, label: 0 }}
        placeholder={
          (
            <FormattedMessage
              id='UserLookup.TypeInName'
              defaultMessage='Type in name'
            />
          ) as any
        }
        options={sortUsers as any}
        filterOption={filterOption}
        styles={{
          placeholder: base => ({
            ...base,
            fontSize: '.875rem'
          }),
          dropdownIndicator: provided => ({
            ...provided,
            color: '#757575',
            ':hover': {
              color: '#5C5C5C',
              transition: 'all 0.3s ease'
            }
          }),
          option: (provided, state) => ({
            backgroundColor:
              state.isFocused || state.selected ? '#919191' : 'white'
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 })
        }}
        menuPortalTarget={document.body}
      />

      <Dialog
        open={openBlockedUserDialog}
        onClose={() => handleToggleBlockedUserDialog(false)}
      >
        <DialogContent>
          <Text className='mt3'>
            <FormattedMessage
              id='UserLookup.userIsBlocked'
              defaultMessage='The user you selected is blocked. The action cannot be performed.'
            />
          </Text>
        </DialogContent>
        <DialogActions>
          <Button
            label={
              <FormattedMessage id='UserLookup.close' defaultMessage='Close' />
            }
            autoSize
            onClick={() => handleToggleBlockedUserDialog(false)}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UserLookup
