const mergeFilters = (filters1 = {}, filters2 = {}) => {
  Object.keys(filters2).forEach(key => {
    if (!filters1[key]) {
      filters1[key] = filters2[key]
    } else {
      filters2[key].forEach(f2Value => {
        if (typeof f2Value === 'string') {
          if (!filters1[key].includes(f2Value)) {
            filters1[key].push(f2Value)
          }
        } else {
          if (
            !filters1[key].some(f1Value => {
              return (
                typeof f1Value !== 'string' &&
                f1Value.sort().join('|') === f2Value.sort().join('|')
              )
            })
          ) {
            filters1[key].push(f2Value)
          }
        }
      })
    }
  })
  return filters1
}

export default mergeFilters
