import buyerBasePath from '../routes/paths'

const basePath = buyerBasePath.supplierProfile

const paths = {
  basePath,
  myCompany: `${basePath}/mycompany`,
  personal: `${basePath}/personal`,
  formResponse: `${basePath}/formResponse`,
  custom: `${basePath}/custom`
}

export default paths
