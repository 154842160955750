import React from 'react'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { openESGRequestDialog } from 'buyer/SupplierProfile/actions'
import surveySelectors from 'buyer/SupplierProfile/selectors/surveySelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import RootState from 'shared/models/RootState'
import Avatar from 'shared/components/Avatar'
import ListItem from 'shared/components/List/ListItem'
import { ReactComponent as HealthSafety } from 'shared/assets/images/esg/icon-health-safety.svg'
import { ReactComponent as Environment } from 'shared/assets/images/esg/icon-environment.svg'
import { ReactComponent as DiversityInclusion } from 'shared/assets/images/esg/icon-diversity.svg'
import { ReactComponent as Governance } from 'shared/assets/images/esg/icon-governance.svg'
import { ReactComponent as Privacy } from 'shared/assets/images/esg/icon-privacy.svg'

type ContactItemProps = {
  firstName: string
  lastName: string
  title?: string
  profilePictureUrl?: string
}
export const ContactItem = (props: ContactItemProps) => {
  const { firstName, lastName, profilePictureUrl, title } = props

  return (
    <ListItem
      leftAvatar={
        <Avatar
          url={profilePictureUrl}
          name={`${firstName || ''} ${lastName || ''}`}
        />
      }
      primaryText={
        <div className='flex items-center f7-5'>
          <span>
            {firstName} {lastName}
          </span>
        </div>
      }
      secondaryText={
        title ? <div className='f9 ma0 gray'>{title}</div> : undefined
      }
      className='ma0'
      mini
    />
  )
}

const RequestSustainabilitySurvey = () => {
  const dispatch = useDispatch()
  const activeESGRequest = useSelector(
    surveySelectors.getActiveEsgRequestDetails
  )
  const activeRequestStatus: string | undefined = activeESGRequest?.get(
    'status'
  )
  const activeRequestCreator = useSelector((state: RootState) =>
    usersSelectors.getById(state, activeESGRequest?.getIn(['created', 'user']))
  )

  const handleRequestClick = () => {
    dispatch(openESGRequestDialog())
  }

  return (
    <div className='mt3-5'>
      <div className='flex items-center justify-between mr5'>
        <div className='flex flex-column'>
          <HealthSafety width={30} />
          <Text className='fw6'>
            <FormattedMessage
              id='RequestSustainabilitySurvey.HealthSafety'
              defaultMessage={'Health & Safety'}
            />
          </Text>
        </div>
        <div className='flex flex-column'>
          <Environment width={30} />
          <Text className='fw6'>
            <FormattedMessage
              id='RequestSustainabilitySurvey.Environment'
              defaultMessage={'Environment'}
            />
          </Text>
        </div>
        <div className='flex flex-column'>
          <DiversityInclusion width={30} />
          <Text className='fw6'>
            <FormattedMessage
              id='RequestSustainabilitySurvey.DiversityInclusion'
              defaultMessage={'Diversity Inclusion'}
            />
          </Text>
        </div>
        <div className='flex flex-column'>
          <Governance width={30} />
          <Text className='fw6'>
            <FormattedMessage
              id='RequestSustainabilitySurvey.Governance'
              defaultMessage={'Governance'}
            />
          </Text>
        </div>
        <div className='flex flex-column'>
          <Privacy width={30} />
          <Text className='fw6'>
            <FormattedMessage
              id='RequestSustainabilitySurvey.Privacy'
              defaultMessage={'Privacy'}
            />
          </Text>
        </div>
      </div>
      <Text className='mv3'>
        <FormattedMessage
          id='RequestSustainabilitySurvey.RequestV2'
          defaultMessage='TealBook has launched a sustainability survey to capture information about your suppliers’ Environment, Social, Governance (ESG) efforts. This survey is an opportunity for you to gain insight into your suppliers’ sustainability initiatives, and the results will be publicly available to all buyers on the TealBook platform. Click the button below to invite this supplier to take the survey.'
        />
      </Text>
      {activeRequestStatus === 'Created' ? (
        <>
          <div className='dib v-top w-70'>
            <div className='f9 fw6 ml2 mb1 mid-gray'>
              <FormattedMessage
                id='SurveyInvitationSent.InvitedBy'
                defaultMessage={`Invited By`}
              />
            </div>
            {activeRequestCreator && (
              <ContactItem
                key={activeRequestCreator.get('id')}
                firstName={activeRequestCreator.get('firstName')}
                lastName={activeRequestCreator.get('lastName')}
                profilePictureUrl={activeRequestCreator.get(
                  'profilePictureUrl'
                )}
                title={activeRequestCreator.get('title')}
              />
            )}
          </div>
          <div className='dib w-30 v-top tr'>
            <Button disabled>
              <FormattedMessage
                id='SurveyInvitationSent.Button'
                defaultMessage='Survey Invitation Sent'
              />
            </Button>
          </div>
        </>
      ) : activeRequestStatus === 'InProgress' ? (
        <>
          <div className='dib v-top w-70'>
            <div className='f9 fw6 ml2 mb1 mid-gray'>
              <FormattedMessage
                id='SurveyInvitationSent.InvitedBy'
                defaultMessage={`Invited By`}
              />
            </div>
            {activeRequestCreator && (
              <ContactItem
                key={activeRequestCreator.get('id')}
                firstName={activeRequestCreator.get('firstName')}
                lastName={activeRequestCreator.get('lastName')}
                profilePictureUrl={activeRequestCreator.get(
                  'profilePictureUrl'
                )}
                title={activeRequestCreator.get('title')}
              />
            )}
          </div>
          <div className='dib w-30 v-mid tr'>
            <Button disabled>
              <FormattedMessage
                id='SurveyInProgress.Button'
                defaultMessage='This survey is in progress'
              />
            </Button>
          </div>
        </>
      ) : (
        <div className='tr'>
          <Button autoSize onClick={handleRequestClick}>
            <FormattedMessage
              id='RequestSustainabilitySurvey.Button'
              defaultMessage='Request Sustainability Survey'
            />
          </Button>
        </div>
      )}
    </div>
  )
}

export default RequestSustainabilitySurvey
