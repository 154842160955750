import { put, all } from 'redux-saga/effects'
import { addPersonalRelationship } from '../../actions'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* batchAddPersonalRelationshipSaga(action) {
  const { supplierIds = [], ...rest } = action.payload

  yield all(
    supplierIds.map(supplierId =>
      put(
        addPersonalRelationship({
          supplierId,
          ...rest
        })
      )
    )
  )
}

export default createApiRequestSaga(batchAddPersonalRelationshipSaga, {
  returnPayload: true
})
