import { take, call, fork } from 'redux-saga/effects'

export default function* takeFirst(actionType, saga) {
  yield fork(takeFirstWatcher, actionType, saga)
}

export function* takeFirstWatcher(actionType, saga) {
  while (true) {
    const action = yield take(actionType)
    yield call(saga, action)
  }
}
