import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call } from 'redux-saga/effects'
import message from '../../utils/api/message'
import Immutable from 'immutable'

export function* addMessageSaga(action) {
  const {
    ancestorId,
    newNote,
    isPrivate,
    messageKind
  } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  const newMessage = yield call(message.postMessageToAncestor, ancestorId, {
    body: newNote,
    isPrivate
  })

  return yield {
    messageKind,
    ancestorId,
    newMessage
  }
}

export default createApiRequestSaga(addMessageSaga)
