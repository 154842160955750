import { call, put } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'
import { notify } from 'shared/actions'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* addDomainSaga(action) {
  const checkDomain = yield call(supplier.getBuyerByDomain, action.payload)

  if (checkDomain.found && checkDomain.redirectFrom) {
    return yield checkDomain.redirectFrom
  }
  if (
    !checkDomain.found &&
    (!checkDomain.forbidden || checkDomain.email) &&
    checkDomain.cleanDomain
  ) {
    yield call(supplier.addDomain, checkDomain.cleanDomain)
    return yield checkDomain.cleanDomain
  } else {
    yield put(
      notify({
        message: `Invalid Domain${
          checkDomain.found
            ? ': already exists'
            : checkDomain.forbidden
            ? ': forbidden'
            : ''
        }`
      })
    )
    return yield false
  }
}

export default createApiRequestSaga(addDomainSaga, { notifyOnError: true })
