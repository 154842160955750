import { combineReducers } from 'redux-immutable'

import sharedReducers from './shared/reducers/rootReducer'
import suggest from './shared/reducers/suggestReducer'
import settings from './shared/reducers/settingsReducer'
import insights from './Insights/store/insightsReducer'
import diversityReportReducer from './Insights/store/diversityReportReducer'
import bulkActionsReducer from './Insights/store/bulkActionsReducer'
import supplierProfile from './SupplierProfile/reducers/rootReducer'
import searchReducers from './Search/reducers/rootReducer'
import vetsReducers from './Vets/reducers/rootReducer'
import communitiesReducers from './Communities/reducers/rootReducer'

const rootReducer = combineReducers({
  ...sharedReducers,
  suggest,
  settings,
  supplierProfile,
  insights,
  diversityReport: diversityReportReducer,
  bulkActions: bulkActionsReducer,
  ...searchReducers,
  ...vetsReducers,
  ...communitiesReducers
})

export default rootReducer
