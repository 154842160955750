export const tier2DiverseSpendData = {
  entity: 'tier2/diverse',
  resultParser: data =>
    data?.length > 0 &&
    data.sort((item1, item2) => {
      if (!item1 || !item2) {
        return 0
      }
      // if the years are the same, compare the quarters
      if (item1.year === item2.year) {
        return item1.quarter <= item2.quarter ? -1 : 1
      }
      return item1.year < item2.year ? -1 : 1
    })
}
