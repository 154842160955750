import React, { Component, Fragment } from 'react'
import TextArea from 'shared/components/TextArea'
import { Field } from 'redux-form/immutable'
import Warning from 'shared/components/Warning'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'

type Props = {
  outcome: string
  open: boolean
  onRequestClose: () => void
  onSubmit: () => void
  error: string
}

class OutcomeDialog extends Component<Props> {
  render() {
    const { outcome, open, onRequestClose, onSubmit, error } = this.props
    const isAccepting = outcome === 'accept'
    const message = isAccepting ? (
      <FormattedMessage
        id='OutcomeDialog.AcceptThisSupplier'
        defaultMessage='You are choosing to accept this supplier. Send them a message with next steps.'
      />
    ) : (
      <FormattedMessage
        id='OutcomeDialog.YouAreChoosingToRejectSupplier'
        defaultMessage='You are choosing to reject this supplier? Send them a message to let them know why.'
      />
    )

    const notesField = isAccepting ? (
      <FormattedMessage
        id='OutcomeDialog.SendAMessageAccept'
        defaultMessage='Send a message to let the supplier know that they have been accepted'
      >
        {message => (
          <Field
            name='notes'
            component={TextArea}
            type='text'
            placeholder={message as string}
            required
          />
        )}
      </FormattedMessage>
    ) : (
      <FormattedMessage
        id='OutcomeDialog.SendAMessageDecline'
        defaultMessage="Let the supplier know why you aren't ready to move forward"
      >
        {message => (
          <Field
            name='notes'
            component={TextArea}
            type='text'
            placeholder={message as string}
            required
          />
        )}
      </FormattedMessage>
    )

    return (
      <form onSubmit={onSubmit}>
        <Dialog
          open={open}
          onClose={onRequestClose}
          fullWidth
          disableBackdropClick
        >
          <form onSubmit={onSubmit}>
            <DialogTitle>
              <FormattedMessage
                id='OutcomeDialog.Outcome'
                defaultMessage='Outcome'
              />
            </DialogTitle>

            <DialogContent className='mt3'>
              <Text>{message}</Text>
              <div className='mt4'>
                <div className='pb2 fw6 f7'>
                  {isAccepting ? 'Message' : 'Reason'}
                </div>
                {notesField}
              </div>
              {isAccepting && (
                <Fragment>
                  <div className='pb2 fw6 f7'>
                    <FormattedMessage
                      id='OutcomeDialog.NextSteps'
                      defaultMessage='Next Steps'
                    />
                  </div>
                  <FormattedMessage
                    id='OutcomeDialog.LetThemKnowNextSteps'
                    defaultMessage='Let them know what the next steps are'
                  >
                    {message => (
                      <Field
                        name='nextSteps'
                        component={TextArea}
                        type='text'
                        placeholder={message as string}
                        required
                      />
                    )}
                  </FormattedMessage>
                </Fragment>
              )}

              {error && <Warning message={error} className='mb3' />}

              <Field
                name='vetBuyerSupplierId'
                component='input'
                type='hidden'
              />
              <Field name='outcome' component='input' type='hidden' />
            </DialogContent>

            <DialogActions>
              <Button
                label={
                  <FormattedMessage
                    id='OutcomeDialog.Send'
                    defaultMessage='Send'
                  />
                }
                type='submit'
                autoSize
              />
              <Button
                label={<FormattedMessage id='CancelButton' />}
                onClick={onRequestClose}
                secondary
                autoSize
              />
            </DialogActions>
          </form>
        </Dialog>
      </form>
    )
  }
}

export default OutcomeDialog
