import React from 'react'
import { FormattedMessage } from 'react-intl'

export const createStarsRatingValidator = fourStarsBased => {
  const upperLimit = fourStarsBased ? 4 : 5
  return val =>
    val < 0 || val > upperLimit
      ? `Value must be between 0 and ${upperLimit}`
      : undefined
}

export const isRequired = val =>
  val === undefined || !val ? 'Field is required' : undefined

export const createConfirmValidator = (fieldNameToCompare, pluralLabel) => (
  value,
  allValues
) =>
  value !== allValues.get(fieldNameToCompare)
    ? `The ${pluralLabel} don't match.`
    : undefined

export const createPasswordRulesValidator = rules => (value, allValues) => {
  if (value) {
    if (!(!rules.min || value.length >= rules.min)) {
      return `At least ${rules.min} ${
        rules.min > 1 ? `characters` : `character`
      }`
    }
    if (!(!rules.max || value.length <= rules.max)) {
      return `At most ${rules.max} ${
        rules.max > 1 ? `characters` : `character`
      }`
    }
    if (
      !(
        !rules.specialCount ||
        (value.match(/[!@#$%^&*)(+=._-]/g) &&
          value.match(/[!@#$%^&*)(+=._-]/g).length >= rules.specialCount)
      )
    ) {
      return `At least ${rules.specialCount} special ${
        rules.specialCount > 1 ? `characters` : `character`
      }`
    }
    if (
      !(
        !rules.numericCount ||
        (value.match(/[0-9]/g) &&
          value.match(/[0-9]/g).length >= rules.numericCount)
      )
    ) {
      return `At least ${rules.numericCount} numeric ${
        rules.numericCount > 1 ? `characters` : `character`
      }`
    }
    if (
      !(
        !rules.alphaLowerCount ||
        (value.match(/[a-z]/g) &&
          value.match(/[a-z]/g).length >= rules.alphaLowerCount)
      )
    ) {
      return `At least ${rules.alphaLowerCount} lower case ${
        rules.alphaLowerCount > 1 ? `characters` : `character`
      }`
    }
    if (
      !(
        !rules.alphaUpperCount ||
        (value.match(/[A-Z]/g) &&
          value.match(/[A-Z]/g).length >= rules.alphaUpperCount)
      )
    ) {
      return `At least ${rules.alphaUpperCount} upper case ${
        rules.alphaUpperCount > 1 ? `characters` : `character`
      }`
    }
  }
  return undefined
}

export const isJSON = value => {
  if (!value) {
    return undefined
  }

  try {
    JSON.parse(value)
  } catch (err) {
    return 'Invalid JSON format'
  }
}

export const validateDUNS = value => {
  //excepting 9 numbers only
  if (!!value && (value.length !== 9 || !Number(value))) {
    return (
      <FormattedMessage
        id='CorporateSection.invalidDUNS'
        defaultMessage='This is not a valid DUNS number.'
      />
    )
  }
}

export const validateTIN = value => {
  // open validation to account for global variation of Tax id's
  // arbitrarily chose maximum length of 20 digits
  // arbitrarily chose minimum length of 5

  if (!!value && (value.length < 5 || value.length > 20)) {
    return (
      <FormattedMessage
        id='CorporateSection.invalidTID'
        defaultMessage='This is not a valid Tax ID.'
      />
    )
  }
}

export const validateLEI = value => {
  //expecting 20 characters made up of 18 chars, 2 numbers
  const substringOne = value.substring(0, 18)
  const substringTwo = Number(value.substring(18))
  if (!!value && (value.length !== 20 || !substringOne || !substringTwo)) {
    return (
      <FormattedMessage
        id='CorporateSection.invalidLEI'
        defaultMessage='This is not a valid LEI.'
      />
    )
  }
}
