import React, { Component } from 'react'
import { connect } from 'react-redux'
import profileSelectors from '../../selectors/profileSelectors'
import ListComponent from 'shared/components/List'
import SimilarSupplierListItem from './SimilarSupplierListItem'
import PageSideSection from 'shared/components/PageSideSection'
import Scrollable from 'shared/components/Scrollable'
import Button from 'shared/components/Button'
import suppliersApi from 'shared/utils/api/suppliers'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { searchSimilarSuppliers } from '../../../shared/actions'
import { FormattedMessage } from 'react-intl'
import { RecordOf, List } from 'immutable'
import RootState from 'shared/models/RootState'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from 'shared/components/Tooltip'

type Props = {
  currentSupplierId: string
  hasBetaRole: boolean
  suppliers: List<RecordOf<{ orgUnit: string }>>
  searchSimilarSuppliers: (arg: Array<string>) => void
}
export class SimilarSuppliers extends Component<Props> {
  handleClickSimilarThumbs = ({ supplierId: otherSupplier, ...rest }) => {
    const { currentSupplierId } = this.props

    suppliersApi.similar({
      supplierId: currentSupplierId,
      otherSupplier,
      ...rest
    })
  }

  render() {
    const {
      currentSupplierId,
      suppliers,
      hasBetaRole,
      searchSimilarSuppliers
    } = this.props

    return (
      <div className='cf'>
        <PageSideSection
          title={
            <>
              <FormattedMessage
                id='SimilarSupplier.SimilarSupplier'
                defaultMessage='Similar Suppliers'
              />
              <Tooltip
                title={
                  <FormattedMessage
                    id='SimilarSupplier.SimilarSupplierHelp'
                    defaultMessage='Suppliers listed from most similar to least similar.'
                  />
                }
              >
                <InfoIcon className='v-mid dib' style={{ height: '19px' }} />
              </Tooltip>
            </>
          }
        >
          <Scrollable>
            <ListComponent>
              {suppliers &&
                suppliers.map(supplier => (
                  <SimilarSupplierListItem
                    key={supplier.get('orgUnit')}
                    supplierId={supplier.get('orgUnit')}
                    onClickThumbs={this.handleClickSimilarThumbs}
                    hasBetaRole={hasBetaRole}
                  />
                ))}
            </ListComponent>
          </Scrollable>
          <Button
            className='mt3'
            label={
              <FormattedMessage
                id='SimilarSupplier.RefineSimilarSuppliers'
                defaultMessage='Refine similar suppliers'
              />
            }
            onClick={() => searchSimilarSuppliers([currentSupplierId])}
          />
        </PageSideSection>
      </div>
    )
  }
}

export default connect(
  (state: RootState) => ({
    suppliers: profileSelectors.getSimilarSuppliers(state),
    currentSupplierId: profileSelectors.getDataField(state, 'id'),
    hasBetaRole: sessionSelectors.userHasRole(state, 'beta')
  }),
  { searchSimilarSuppliers }
)(SimilarSuppliers)
