import React, { useState } from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../diversityPaths'
import { FormattedMessage } from 'react-intl'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import { CSSTransition } from 'react-transition-group'
import Hidden from '@material-ui/core/Hidden'
import CloseIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import storageManager from 'shared/utils/storageManager'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import Divider from 'shared/components/Divider'
import chatbot from 'shared/utils/chatbot'
import TealbotHelpIcon from 'shared/components/TealbotHelpIcon'
import ExternalLink from 'shared/components/ExternalLink'

const InsightDiversityNavBar = () => {
  const [doNotShow, setDoNotShow] = useState<boolean>(
    !!storageManager.getItem('doNotShowTier2SupplierHelp')
  )

  const handleDoNotShow = () => {
    storageManager.setItem('doNotShowTier2SupplierHelp', true)
    setDoNotShow(true)
  }

  const handleShow = () => {
    storageManager.setItem('doNotShowTier2SupplierHelp', false)
    setDoNotShow(false)
  }
  return (
    <div className='mw8 center ph3 cf'>
      <CSSTransition
        in={!doNotShow}
        timeout={300}
        classNames='tealbotHelp'
        unmountOnExit
      >
        <div className='bg-white br1 ba b--black-10 mt3-5 mb0 flex'>
          <div className='pa3-5 flex-auto'>
            <div className='lh-copy f7 fw4 mid-gray ma0'>
              <Text>
                <FormattedMessage
                  id='DiversityReport.Tier2Help1'
                  defaultMessage='We have identified over 650,000 small and/or diverse suppliers operating in the US and around the world. You can use TealBook to see how much of your spend is with these suppliers. You can even allocate that diverse spend to your customers with diversity reporting programs, helping them meet their goals.'
                />
              </Text>
              <Label>
                <FormattedMessage
                  id='DiversityReport.Tier2Help2'
                  defaultMessage='To generate your diversity report:'
                />
              </Label>
              <ol>
                <li>
                  <FormattedMessage
                    id='DiversityReport.Tier2Help3'
                    defaultMessage='Accept any invitations you see on this page from your customers.'
                  />
                </li>
                <li>
                  <FormattedMessage
                    id='DiversityReport.Tier2Help4'
                    defaultMessage='Download the template below and import your spend data. It is important for the column names to match exactly.'
                  />
                  <Text className='mv2 mr1'>
                    <span className={'red fw6 mr2'}>
                      <FormattedMessage
                        id='DiversityReport.PrivacyNotice'
                        defaultMessage='PRIVACY NOTE:'
                      />
                    </span>
                    <FormattedMessage
                      id='DiversityReport.Tier2Help6'
                      defaultMessage='We will not share this detailed data with any other company. When you choose to share an allocation with a customer, we will only share a summarized version. This will contain no vendor details.'
                    />
                  </Text>
                </li>
                <li>
                  <FormattedMessage
                    id='DiversityReport.Tier2Help7'
                    defaultMessage='Upload the completed template and review mapping for each file before you add it to your report.'
                  />
                  <Text className='mv2 mr1'>
                    <span className={'red fw6 mr2'}>
                      <FormattedMessage id='DiversityReport.PrivacyNotice' />
                    </span>
                    <FormattedMessage
                      id='DiversityReport.Tier2Help9b'
                      defaultMessage='We will only share your summarized spend information by diverse category if you specifically select the customer to share it with. If you choose to share, your customer will be able to see a summary of the diverse spend per quarter you allocated to them grouped by diversity category. We do not share information about your suppliers with your customers.'
                    />
                  </Text>
                </li>
                <li>
                  <FormattedMessage
                    id='DiversityReport.Tier2Hel10'
                    defaultMessage='Review your diversity report. It will show how much of the spend in the quarter was with small, women-owned, minority-owned, and other categories of diverse companies.'
                  />
                </li>
                <li>
                  <FormattedMessage
                    id='DiversityReport.Tier2Help11'
                    defaultMessage='Allocate your customers a percentage of your diversity spend based on how much of your total income they represent and click the Share button.'
                  />
                </li>
              </ol>
              <Label>
                <FormattedMessage
                  id='DiversityReport.Tier2HelpNDALabel'
                  defaultMessage='Non-Disclosure Agreement: '
                />
              </Label>
              <Text className='mb3'>
                <FormattedMessage
                  id='DiversityReport.Tier2HelpNDA'
                  defaultMessage='You may {download} our Non-Disclosure Agreement signed by our CEO, Stephany Lapierre, for your documents. We hope it gives you the comfort and confidence necessary to share the requested information with us and that it clarifies how we intend to use it. Please let us know if you have any questions about this document through our Support Centre.'
                  values={{
                    download: (
                      <ExternalLink
                        href={`${window.location.host}/downloads/Tealbook_Supplier_NDA.pdf`}
                      >
                        <FormattedMessage
                          id='DiversityReport.Download'
                          defaultMessage='download'
                        />
                      </ExternalLink>
                    )
                  }}
                />
              </Text>
              <Text>
                <FormattedMessage
                  id='DiversityReport.Tier2Hel12b'
                  defaultMessage='We are doing our best to make this process as simple for you as possible. If you need help, or have any questions, please {contactSupport}!'
                  values={{
                    contactSupport: (
                      <span className='teal pointer dim' onClick={chatbot.open}>
                        <FormattedMessage
                          id='DiversityReport.ContactUsHere'
                          defaultMessage={`contact us here`}
                        />
                      </span>
                    )
                  }}
                />
              </Text>
            </div>
          </div>
          <Hidden smDown>
            <div className='ml3-ns w4 self-center'>
              <img src={TealbotCircleImage} alt='tealbot-tip' />
            </div>
          </Hidden>
          <div className='pr2 pt2'>
            <IconButton
              onClick={handleDoNotShow}
              style={{
                width: 32,
                height: 32
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </CSSTransition>

      <div className='flex items-center'>
        <NavBar>
          <NavLink
            to={paths.diversityInvitation}
            label={
              <FormattedMessage
                id='InsightDiversityNavBar.Invitation'
                defaultMessage='Invitation'
              />
            }
          />

          <NavLink
            to={paths.diversityLoad}
            label={
              <FormattedMessage
                id='InsightDiversityNavBar.Load'
                defaultMessage='Load'
              />
            }
            isActive={(_, location) =>
              location.pathname.startsWith(paths.diversityLoad)
            }
          />

          <NavLink
            to={paths.diversityReport}
            label={
              <FormattedMessage
                id='InsightDiversityNavBar.DiverseSpend'
                defaultMessage='Report'
              />
            }
            isActive={(_, location) =>
              location.pathname === paths.diversityReport
            }
          />

          <NavLink
            to={paths.diversityShare}
            label={
              <FormattedMessage
                id='InsightDiversityNavBar.Share'
                defaultMessage='Share'
              />
            }
            isActive={(_, location) =>
              location.pathname === paths.diversityShare
            }
          />
        </NavBar>

        {doNotShow && (
          <CSSTransition
            in={doNotShow}
            timeout={100}
            classNames='tealbotHelp'
            unmountOnExit
          >
            <TealbotHelpIcon
              show={doNotShow}
              imageClassName='dim pointer w2 ma0'
              onClick={handleShow}
            />
          </CSSTransition>
        )}
      </div>
      <Divider />
    </div>
  )
}

export default InsightDiversityNavBar
