import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import postsApi from 'shared/utils/api/posts'
import parseUser from 'shared/utils/data/parseUser'
import parseSupplier from 'shared/utils/data/parseSupplier'
import mapValues from 'lodash.mapvalues'

export function* loadPostsSaga(action) {
  const { userId = undefined, orgUnitId = undefined } = action.payload || {}
  const posts = yield call(postsApi.getAll, action.payload)
  const trendingTags = yield call(postsApi.getTrendingTags, {})

  let usersById = {}
  let orgUnitsById = {}
  const byId =
    posts &&
    posts.reduce((result, post) => {
      result[post.id] = post
      const { User = {}, OrgUnit = {} } = post.expanded
      usersById = Object.assign({}, usersById, mapValues(User, parseUser))
      orgUnitsById = Object.assign(
        {},
        orgUnitsById,
        mapValues(OrgUnit, parseSupplier)
      )
      return result
    }, {})
  const sortByDate = posts && posts.map(post => post.id)

  return yield {
    byId,
    sortByDate,
    usersById,
    orgUnitsById,
    userId,
    orgUnitId,
    trendingTags
  }
}

export default createApiRequestSaga(loadPostsSaga)
