import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import community from 'shared/utils/api/community'
import tier2 from 'shared/utils/api/tier2'

export function* inviteToCommunitiesSaga(action) {
  const comIds = Object.keys(
    action.payload
      .get('communities')
      .filter(value => value)
      .toJS()
  )
  const supplierId = action.payload.get('supplierId')

  const inviteResponse = yield call(community.invitesToCommunities, {
    comIds,
    supplierId
  })

  const inviteToTier2Community =
    action.payload.get('tier2') && inviteResponse
      ? yield call(tier2.tier2SupplierList)
      : undefined
  const {
    bySupplierId,
    membershipsById,
    membershipsBySupplierId
  } = inviteResponse
  return {
    bySupplierId,
    membershipsById,
    membershipsBySupplierId,
    inviteToTier2Community
  }
}

export default createApiRequestSaga(inviteToCommunitiesSaga)
