import { ComponentType } from 'react'
import { connect } from 'react-redux'
import postsSelectors from '../../selectors/postsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { upVote, clearVote } from '../../actions'
import RootState from 'shared/models/RootState'

type Props = {
  postId: string
}

function withPostActions<P>(component: ComponentType<P>) {
  return connect(
    (state: RootState, props: Props) => {
      const post = postsSelectors.getById(state, props.postId)
      const body = post && post.get('body')
      const bodyIsEmpty =
        !body ||
        // react-rte adds spaces instead of empty values
        // see https://github.com/sstur/react-rte/issues/277
        body.replace(/\n/g, '').length === 1

      const likes = post && post.get('upVotes')
      const currentUser = sessionSelectors.getUser(state)
      const currentUserId = currentUser.get('id')
      const {
        numComments,
        numLikes,
        numRead,
        numImpressions
      } = postsSelectors.getPostStat(state, props.postId)

      return {
        id: props.postId,
        liked: likes && likes.includes(currentUserId),
        likeAsRead:
          bodyIsEmpty || !(body.indexOf('\n') !== -1 || body.length > 50),
        numComments,
        numLikes,
        numReads: numRead,
        numViews: numImpressions
      }
    },
    { onUpVote: upVote, onClearVote: clearVote }
    // @ts-ignore
  )(component)
}

export default withPostActions
