import parseTags from '../parseTags'
import mapKeys from 'lodash.mapkeys'
import mapValues from 'lodash.mapvalues'
import camelCase from 'lodash.camelcase'

export default function parseCard(card) {
  const {
    // parse these fields
    differentiators,
    offerings,
    contacts,
    attributes,
    privateOfferings,
    // remove these fields
    parents,
    expanded,

    ...rest
  } = card

  return {
    contacts: mapValues(
      mapKeys(contacts, c => c.user),
      c => {
        c.isPrivate = c.isPrivate !== false // default private
        return c
      }
    ),
    attributes:
      attributes && Array.isArray(attributes)
        ? attributes.reduce((result, attr) => {
            result[camelCase(attr)] = attr
            return result
          }, {})
        : attributes,
    differentiators: parseTags(differentiators),
    offerings: parseTags(offerings),
    owner: card.parents.User,
    privateOfferings: parseTags(privateOfferings),
    ...rest
  }
}
