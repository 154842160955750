import { Record, fromJS } from 'immutable'
import { requestSuccess, requestFailure, LOGOUT } from 'shared/actionTypes'
import * as actionTypes from '../../actionTypes'

const VetBuyerSuppliersRecord = Record({
  data: undefined,
  totalCount: 0,
  isFetching: false,
  fetchErrorMessage: undefined
})

const vetBuyerSuppliersReducer = (
  state = new VetBuyerSuppliersRecord(),
  action
) => {
  switch (action.type) {
    case actionTypes.GET_VET_BUYER_SUPPLIERS:
      return state.merge({
        isFetching: true,
        fetchErrorMessage: undefined
      })
    case requestSuccess(actionTypes.GET_VET_BUYER_SUPPLIERS):
      return new VetBuyerSuppliersRecord({
        totalCount: action.payload.totalCount,
        data: fromJS(action.payload.vetBuyerSuppliers)
      })
    case requestFailure(actionTypes.GET_VET_BUYER_SUPPLIERS):
      return state.merge({
        isFetching: false,
        fetchErrorMessage: action.payload
      })
    case LOGOUT:
      return new VetBuyerSuppliersRecord()
    default:
      return state
  }
}

export default vetBuyerSuppliersReducer
