import { List } from 'immutable'
import { UserRole } from 'shared/models/User'

const canDeleteUser = (
  userToDeleteRoles: string[],
  currentUserRoles: List<UserRole>
) => {
  const isSupplierAdmin = currentUserRoles.includes('supplierAdmin')
  const isBuyerAdmin = currentUserRoles.includes('buyerAdmin')
  const isTealbot = currentUserRoles.includes('tealbot')
  if (!!userToDeleteRoles.length) {
    if (
      isSupplierAdmin &&
      userToDeleteRoles.every(role => role.startsWith('supplier'))
    ) {
      return true
    }
    if (
      isBuyerAdmin &&
      !userToDeleteRoles.find(role => role.startsWith('supplier'))
    ) {
      return true
    } else if ((isSupplierAdmin && isBuyerAdmin) || isTealbot) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export default canDeleteUser
