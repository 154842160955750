import React, { Component, ReactNode } from 'react'

/**
 * This component displays an html checkbox like:
 * <input type='checkbox'/> with extra error handling, usually through React Forms passing values <br/>
 * When used with redux forms the value of the Checkbox is saved to the redux form state <br/>
 * (checked = true, unchecked = false)
 */

type Props = {
  checked: boolean
  name?: string
  value?: string
  input?: any
  label?: ReactNode
  disabled?: boolean
  labelFontLight?: boolean
  className?: string
  meta?: { error?: string; touched?: boolean }
  onChange?: (e: any) => void
  inline?: boolean
}

class Checkbox extends Component<Props> {
  render() {
    const {
      input,
      meta = {},
      label,
      labelFontLight,
      checked,
      className,
      inline,
      ...otherProps
    } = this.props

    return (
      <label
        className={
          !inline
            ? `pointer db flex items-start ${className || ''}`
            : `pointer mr3 ${className || ''}`
        }
      >
        {meta.touched && meta.error && <span>{meta.error}</span>}
        <input
          type='checkbox'
          checked={checked || !!(input && input.value)}
          {...input}
          {...otherProps}
        />
        {label && (
          <span
            className={`ml2 f7 ${labelFontLight ? 'fw3' : 'fw6'}`}
            style={{ marginTop: 1 }}
          >
            {label}
          </span>
        )}
      </label>
    )
  }
}

export default Checkbox
