import naicsStd from 'shared/models/NaicsStd'
import { fromJS, RecordOf } from 'immutable'

export const getNaicsRules = (
  code: string
): RecordOf<{ revenue: string; employees: string; footnote: string }> => {
  const rules: { revenue: string; employees: string; footnote: string } =
    naicsStd[code]
  let revenue: string, employees: string, footnote: string
  // code is 6 digits and defined
  if (rules) {
    revenue = rules.revenue !== '0' ? rules.revenue : ''
    employees = rules.employees !== '0' ? rules.employees : ''
    footnote = rules.footnote !== '0' ? rules.footnote : ''
    return fromJS({ revenue, employees, footnote })
  }
  // get all naics codes that contain code as a prefix
  const filteredNaics: string[] = Object.keys(naicsStd).filter(x =>
    x.startsWith(code)
  )
  // there are no naics codes with matching prefix
  if (filteredNaics.length === 0) {
    return fromJS({ revenue: '', employees: '', footnote: '' })
  }
  // get minimum existing (not '0') revenue and employee rules from naics codes with code as a prefix
  const minRevenue: number = filteredNaics.reduce(
    (acc, key) =>
      acc <= parseFloat(naicsStd[key].revenue) || naicsStd[key].revenue === '0'
        ? acc
        : parseFloat(naicsStd[key].revenue),
    Number.MAX_VALUE
  )
  const minEmployees: number = filteredNaics.reduce(
    (acc, key) =>
      acc <= parseFloat(naicsStd[key].employees) ||
      naicsStd[key].employees === '0'
        ? acc
        : parseFloat(naicsStd[key].employees),
    Number.MAX_VALUE
  )
  // convert number to string
  revenue = minRevenue !== Number.MAX_VALUE ? `${minRevenue}` : ''
  employees = minEmployees !== Number.MAX_VALUE ? `${minEmployees}` : ''
  footnote = ''
  return fromJS({ revenue, employees, footnote })
}
