import { call, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import suppliers from 'shared/utils/api/suppliers'
import exportData from 'shared/utils/exportData'
import usersSelectors from 'shared/selectors/usersSelectors'
import dateFormat from 'shared/utils/dateFormat'

export function* getSupplierCommentsAndTagsForExportSaga() {
  //getting supplier tags was an intensive query, will need to come up with a different approach

  // let supplierTags = yield call(suppliers.getRecentSuppliersTags, true)
  let comments = yield call(suppliers.getRecentSuppliers, true)
  let users = yield select(usersSelectors.getUsers)

  const getFullName = created =>
    `${users.getIn([created.user, 'firstName'])} ${users.getIn([
      created.user,
      'lastName'
    ])}`

  let expandedData: Array<{
    Supplier: string
    Type: string
    Text: string
    Author: string
    Date: string
  }> = []

  // supplierTags.forEach(
  //   ({ supplier, created, differentiators, offerings, privateOfferings }) => {
  //     const fullName = getFullName(created)

  //     if (offerings) {
  //       offerings.forEach(offering => {
  //         expandedData.push({
  //           Supplier: supplier.name,
  //           Type: 'Offerings',
  //           Text: offering,
  //           Author: fullName,
  //           Date: dateFormat(created.date)
  //         })
  //       })
  //     }
  //     if (differentiators) {
  //       differentiators.forEach(differentiator => {
  //         expandedData.push({
  //           Supplier: supplier.name,
  //           Type: 'Differentiators',
  //           Text: differentiator,
  //           Author: fullName,
  //           Date: dateFormat(created.date)
  //         })
  //       })
  //     }
  //     if (privateOfferings) {
  //       privateOfferings.forEach(privateOffering => {
  //         expandedData.push({
  //           Supplier: supplier.name,
  //           Type: 'Internal Offerings',
  //           Text: privateOffering,
  //           Author: fullName,
  //           Date: dateFormat(created.date)
  //         })
  //       })
  //     }
  //   }
  // )

  comments.recent.forEach(({ recentMessage, name }) => {
    const fullName = getFullName(recentMessage.created)

    expandedData.push({
      Supplier: name,
      Type: 'Comments',
      Text: recentMessage.body,
      Author: fullName,
      Date: dateFormat(recentMessage.created.date)
    })
  })

  expandedData.sort((supplierA, supplierB) => {
    if (supplierA.Supplier === supplierB.Supplier) {
      return 0
    } else {
      return supplierA.Supplier > supplierB.Supplier ? 1 : -1
    }
  })

  exportData.exportCSV(expandedData, 'Recent Supplier Activity')
  return yield expandedData
}

export default createApiRequestSaga(getSupplierCommentsAndTagsForExportSaga)
