import React, { Component } from 'react'
import Page from '../Page'
import TitleText from '../TitleText'
import Text from '../Text'
import AppBarContainer from '../../containers/AppBarContainer'
import UserNavContainer from '../../containers/UserNavContainer'
import { FormattedMessage } from 'react-intl'

const styles = {
  phoneAndEmail: 'teal f7 fw4 dim no-underline pointer'
}

export class BadAccountPage extends Component {
  render() {
    return (
      <div>
        <AppBarContainer userNav={<UserNavContainer />} homeUrl='/bad-account'>
          <div className='flex-auto' />
        </AppBarContainer>
        <Page
          title={
            <FormattedMessage
              id='BadAccountPage.AccountNeedsSupport'
              defaultMessage='Account Needs Support'
            />
          }
          className='tc'
        >
          <TitleText>
            <FormattedMessage
              id='BadAccount.SeemsToBeAProblem'
              defaultMessage='Oh No, there seems to be a problem.'
            />
          </TitleText>

          <div className='mt4 mw6 center pa4 ba b--black-10 bg-white'>
            <Text>
              <FormattedMessage
                id='BadAccount.IssueWithAccount'
                defaultMessage="Unfortunately we have found an issue with the account.
                Don't worry though, contact our support team at"
              />
              &nbsp;
              <span className={styles.phoneAndEmail}>
                <FormattedMessage
                  id='BadAccount.PhoneNumber'
                  defaultMessage='1-866-620-6640'
                />
              </span>{' '}
              &nbsp;
              <FormattedMessage
                id='BadAccount.OrAt'
                defaultMessage='or at'
              />{' '}
              &nbsp;
              <a
                className={styles.phoneAndEmail}
                href='mailto:support@tealbook.com'
              >
                <FormattedMessage
                  id='BadAccount.Email'
                  defaultMessage='support@tealbook.com'
                />
              </a>
              &nbsp;
              <FormattedMessage
                id='BadAccount.AbleToHelp'
                defaultMessage='and we will be able to help.'
              />
            </Text>
          </div>
        </Page>
      </div>
    )
  }
}

export default BadAccountPage
