import React from 'react'
import { connect } from 'react-redux'
import BeginCommunityFormPage from '../../components/BeginCommunityFormPage'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import parsePath from 'shared/utils/parsePath'
import communitiesPaths from '../../routes/paths'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'

type Props = {
  communityId: string
  name: string
}

const BeginCommunityFormPageContainer = (props: Props) => {
  const { communityId, name } = props
  const history = useHistory()

  const handleBegin = () => {
    history.push(
      parsePath(communitiesPaths.communityForm, {
        communityId,
        pageNum: 1
      })
    )
  }

  const handleCancel = () => {
    history.push(communitiesPaths.basePath)
  }

  return (
    <BeginCommunityFormPage
      name={name}
      onBegin={handleBegin}
      onCancel={handleCancel}
    />
  )
}

export default connect(
  (state, props: RouteComponentProps<{ communityId: string }>) => {
    const communityId = props.match.params.communityId
    const community = communitiesSelectors.getById(state, communityId)

    return {
      communityId,
      name: community && community.get('name')
    }
  }
)(BeginCommunityFormPageContainer)
