export const LOAD_COMMUNITY = 'supplier/Communities/LOAD_COMMUNITY'
export const LOAD_COMMUNITIES = 'supplier/Communities/LOAD_COMMUNITIES'
export const ACCEPT_COMMUNITY = 'supplier/Communities/ACCEPT_COMMUNITY'
export const DECLINE_COMMUNITY = 'supplier/Communities/DECLINE_COMMUNITY'
export const LEAVE_COMMUNITY = 'supplier/Communities/LEAVE_COMMUNITY'

export const ANSWER_QUESTION = 'supplier/communities/ANSWER_QUESTION'
export const SUBMIT_COMMUNITY_FORM_RESPONSE =
  'supplier/communities/SUBMIT_COMMUNITY_FORM_RESPONSE'
export const VIEW_COMMUNITY_FORM_PAGE =
  'supplier/communities/VIEW_COMMUNITY_FORM_PAGE'
