import React, {
  useRef,
  useState,
  useMemo,
  memo,
  ChangeEvent,
  useCallback,
  useEffect,
  ReactElement
} from 'react'
import { RecordOf, List } from 'immutable'
import Tealbot from 'shared/assets/icons/tealbot-circle.png'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import FileInput from 'shared/components/FileInput'
import Checkbox from '@material-ui/core/Checkbox'
import AddCertContainer from '../../containers/AddCertContainer'
import { useSelector, useDispatch } from 'react-redux'
import {
  saveSurveyResponse,
  uploadSurveyAttachment,
  removeSurveyAttachment
} from '../../actions'
import surveySelectors from '../../selectors/surveySelectors'
import RootState from 'shared/models/RootState'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Clear'
import Certificate from '../../containers/CertificateContainer'
import Attachment from '../Attachment'
import {
  AttachmentType,
  CertType
} from '../../reducers/surveyReducer/surveyReducer'
import Divider from 'shared/components/Divider'
import Select from 'shared/components/Select'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const messages = defineMessages({
  yes: { id: 'Yes', defaultMessage: 'Yes' },
  no: { id: 'No', defaultMessage: 'No' }
})

type Props = {
  pageId: string
  parentQuestionId?: string
  questionId: string
  type?: 'cert' | 'doc'
  label?: string | ReactElement<FormattedMessage>
  buttonLabel?: string
  multiple?: boolean
  uploadDocTypes?: Array<{ value: string; label: string }>
  suggestion?: List<
    RecordOf<{
      category: string
      certAgency: string
      certExpiration: string
      certificateNumber: string
      certificationUrl: string
      subCategory: string
      timeStamp: string
    }>
  >
  certCategory?:
    | 'diversity'
    | 'quality'
    | 'security'
    | 'sustainability'
    | 'food'
  certSubCategory?: string
}

const AttachmentQuestion = (props: Props) => {
  const {
    type,
    label,
    buttonLabel,
    suggestion,
    certCategory,
    certSubCategory,
    pageId,
    parentQuestionId,
    questionId,
    multiple,
    uploadDocTypes
  } = props
  const dispatch = useDispatch()
  const intl = useIntl()

  const addButton = useRef<HTMLLabelElement>(null)

  const questionResponse = useSelector((state: RootState) =>
    surveySelectors.getResponseDetailByIds(
      state,
      pageId,
      questionId,
      parentQuestionId
    )
  )
  const isUploading = useSelector(surveySelectors.isLoading)

  const [attachmentTypeSelected, setAttachmentTypeSelected] = useState<string>(
    ''
  )
  const [suggestSelected, setSuggestSelected] = useState<string>('')
  const [docTypeSelected, setTypeSelected] = useState<string>('')

  const attachments = questionResponse?.get('attachments')
  const attachmentType = questionResponse?.get('attachmentType')

  const filteredSuggestion = useMemo(() => {
    return suggestion?.filter(cert => {
      const index = attachments?.findIndex(
        (att: RecordOf<CertType>) =>
          att.get('timeStamp') === cert.get('timeStamp')
      )
      return !attachments || index === -1
    })
  }, [suggestion, attachments])

  useEffect(() => {
    if (!type && attachmentType) {
      setAttachmentTypeSelected(attachmentType)
    }
  }, [attachmentType, type])

  const saveResponse = useCallback(
    attachments => {
      dispatch(
        saveSurveyResponse({
          surveyResponseDetailId: questionResponse?.get('id'),
          surveyId: questionResponse?.get('surveyResponseId'),
          ...questionResponse?.toJS(),
          attachmentType: type || attachmentTypeSelected,
          attachments
        })
      )
    },
    [dispatch, type, questionResponse, attachmentTypeSelected]
  )

  const handleUploadAttachmentChange = (file: File) => {
    if (file) {
      dispatch(
        uploadSurveyAttachment({
          surveyResponseDetailId: questionResponse?.get('id'),
          docType: uploadDocTypes && docTypeSelected,
          file
        })
      )
      setTypeSelected('')
    }
  }

  const handleRemoveAttachment = (
    attachment: RecordOf<AttachmentType> | RecordOf<CertType>
  ) => {
    dispatch(
      removeSurveyAttachment({
        attachmentType,
        surveyResponseDetailId: questionResponse?.get('id'),
        attachment: attachment.toJS()
      })
    )
    setSuggestSelected('')
  }

  const handleSuggestedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const timeStamp = e.currentTarget.value
    setSuggestSelected(timeStamp)

    const cert = filteredSuggestion?.find(
      suggest => suggest.get('timeStamp') === timeStamp
    )
    const category = cert?.get('category')
    const subCategory = cert?.get('subCategory')
    const attachment = {
      category,
      subCategory,
      timeStamp
    }

    const tempAttachments = attachments?.toJS() || []
    saveResponse([...tempAttachments, attachment])
  }

  const handleDocTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setTypeSelected(e.currentTarget.value)
  }

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    let answer = e.currentTarget.value
    setAttachmentTypeSelected(answer)
  }

  return (
    <>
      {!type && (
        <div className='flex justify-between items-start'>
          <div className='mv2 pt1 w-two-thirds'>
            <Text className='fw6'>
              <FormattedMessage
                id='QuestionAttachment.certified'
                defaultMessage='Is it certified?'
              />
            </Text>
          </div>
          <div className='flex items-center justify-end w-third'>
            <RadioGroup
              color='primary'
              aria-label='answer'
              name='answer'
              value={attachmentTypeSelected}
              onChange={handleRadioChange}
              row
            >
              <FormControlLabel
                control={<Radio color='primary' size='small' value={'cert'} />}
                label={intl.formatMessage(messages.yes)}
                disabled={!!attachments?.size}
              />
              <FormControlLabel
                control={<Radio color='primary' size='small' value={'doc'} />}
                label={intl.formatMessage(messages.no)}
                disabled={!!attachments?.size}
              />
            </RadioGroup>
          </div>
        </div>
      )}
      {(type === 'cert' || attachmentTypeSelected === 'cert') &&
        (!attachments?.size || multiple) &&
        !!filteredSuggestion?.size && (
          <div className='mb3'>
            <div className='flex items-center'>
              <div className='w2 dib mr2'>
                <img src={Tealbot} alt='Tealbot' className='w-100' />
              </div>
              <Text>
                <FormattedMessage
                  id='QuestionAttachment.Suggested'
                  defaultMessage='Suggested certificates'
                />
              </Text>
            </div>
            {filteredSuggestion.map(cert => (
              <div
                key={cert.get('timeStamp')}
                className='br1 b--light-silver ba ph2 mv1 w-50-ns flex justify-between items-center'
              >
                <div>
                  <Certificate certTimeStamp={cert.get('timeStamp')} />
                </div>
                <Checkbox
                  name='attachment'
                  aria-label='Select Certificate'
                  checked={suggestSelected.includes(cert.get('timeStamp'))}
                  value={cert.get('timeStamp')}
                  size='small'
                  color='primary'
                  onChange={handleSuggestedChange}
                />
              </div>
            ))}
            <Divider className='w-50-ns mt3' />
          </div>
        )}
      {attachments &&
        attachments.map((attachment, i) => (
          <div
            key={`attachment-${i}`}
            className='br1 b--light-silver ba ph2 pv1 w-50-ns flex justify-between items-center mv1'
          >
            {attachmentType === 'doc' && (
              <Attachment attachment={attachment as RecordOf<AttachmentType>} />
            )}
            {attachmentType === 'cert' && (
              <Certificate
                certTimeStamp={(attachment as RecordOf<CertType>).get(
                  'timeStamp'
                )}
              />
            )}
            <div className='mr2'>
              <IconButton
                onClick={() => handleRemoveAttachment(attachment)}
                aria-label='Remove'
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      {(multiple || !attachments || !attachments.size) &&
        (type === 'doc' || attachmentTypeSelected === 'doc') && (
          <div className='br1 b--light-silver ba pa2 w-50-ns flex justify-between items-center mt1'>
            {uploadDocTypes ? (
              <Select value={docTypeSelected} onChange={handleDocTypeChange}>
                <FormattedMessage
                  id='QuestionAttachment.DocType'
                  defaultMessage='Please select document type'
                >
                  {message => <option value=''>{message}</option>}
                </FormattedMessage>
                {uploadDocTypes.map(docType => (
                  <option key={docType.value} value={docType.value}>
                    {docType.label}
                  </option>
                ))}
              </Select>
            ) : (
              <Text>
                {label || (
                  <FormattedMessage
                    id='QuestionAttachment.ProvideDocumentation'
                    defaultMessage='Upload supporting documentation'
                  />
                )}
              </Text>
            )}
            <div className='ml2'>
              <Button
                autoSize
                onClick={() => addButton.current?.click()}
                disabled={isUploading || (uploadDocTypes && !docTypeSelected)}
              >
                {buttonLabel || (
                  <FormattedMessage
                    id='QuestionAttachment.Upload'
                    defaultMessage='Upload'
                  />
                )}
              </Button>
              <label hidden ref={addButton}>
                <FileInput
                  name='responseFile'
                  accept={'.pdf'}
                  onChange={handleUploadAttachmentChange}
                  value=''
                />
              </label>
            </div>
          </div>
        )}
      {(!attachments || !attachments.size || multiple) &&
        (type === 'cert' || attachmentTypeSelected === 'cert') && (
          <AddCertContainer
            certCategory={certCategory}
            certSubCategory={certSubCategory}
            hasSuggestion={filteredSuggestion && filteredSuggestion.size > 0}
          />
        )}
    </>
  )
}

export default memo(AttachmentQuestion)
