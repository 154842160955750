import React from 'react'
import { Route, Switch, Redirect } from 'react-router'
import UserProfileContainer from 'shared/containers/UserProfileContainer'
import EditUserProfileContainer from 'shared/containers/EditUserProfileContainer'
import Communities from '../Communities'
import paths from './paths'
import CompanyApp from '../Company'
import Vets from '../Vets'
import Insight from '../Insight'
import PostingApp from 'posting/App'
import MessagesApp from 'messages/App'
import SubscriptionPageContainer from '../shared/containers/SubscriptionPageContainer'
import BuyerProfileContainer from '../shared/containers/BuyerProfileContainer'
import FAQ from 'shared/components/FAQ'
import Target from '../Target'
import SearchPage from '../Search/components/SearchPage'
import SupplierProfilePageContainer from '../SupplierProfile/containers/SupplierProfilePageContainer'
import DiversityReport from 'supplier/Insight/components/DiversityReport'
import TealbookContributorsContainer from 'shared/containers/TealbookContributorsContainer'
import EsgSurvey from 'supplier/Survey/components/EsgSurvey'

const createRoutes = (defaultTab: string, showSuppliersTab: boolean) => (
  <Switch>
    <Route path={paths.tealboard} component={PostingApp} />
    <Route path={paths.communities} component={Communities} />
    <Route path={paths.company} component={CompanyApp} />
    <Route path={paths.target} component={Target} />
    <Route path={paths.vets} component={Vets} />
    <Route path={paths.insight} component={Insight} />
    <Route path={paths.diversity} component={DiversityReport} />
    <Route path={paths.messageTopic} component={MessagesApp} />
    <Route path={paths.messages} component={MessagesApp} />
    <Route path={paths.clientProfile} component={BuyerProfileContainer} />
    {showSuppliersTab && <Route path={paths.search} component={SearchPage} />}
    <Route path={paths.faq} component={FAQ} />
    <Route
      path={paths.subscriptionPage}
      component={SubscriptionPageContainer}
    />

    {showSuppliersTab && (
      <Route
        path={paths.supplierProfile}
        component={SupplierProfilePageContainer}
      />
    )}

    <Route
      path={paths.editUserProfile}
      render={props => (
        <EditUserProfileContainer
          basePath={paths.basePath}
          userId={props.match.params.userId}
          history={props.history}
        />
      )}
    />

    <Route
      path={paths.userProfile}
      render={props => (
        <UserProfileContainer
          basePath={paths.basePath}
          userId={props.match.params.userId}
          history={props.history}
        />
      )}
    />
    <Route
      path={paths.contributors}
      component={TealbookContributorsContainer}
    />
    <Route path={paths.esgSurvey} component={EsgSurvey} />
    {/* Default page for supplier app */}
    <Redirect from={paths.basePath} to={paths[defaultTab || 'insight']} />
  </Switch>
)

export default createRoutes
