import { fromJS } from 'immutable'
import { requestSuccess } from 'shared/actionTypes'
import {
  LOAD_SUPPLIER_PROFILE,
  ADD_CERTIFICATION_VALIDATION,
  REMOVE_CERTIFICATION_VALIDATION,
  VERIFY_SUPPLIER
} from '../../../SupplierProfile/actionTypes'

const supplierRelationshipsReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case requestSuccess(LOAD_SUPPLIER_PROFILE):
      return fromJS({
        byId: action.payload.supplierRelationships.byId
      })

    case requestSuccess(ADD_CERTIFICATION_VALIDATION):
      return action.payload.isNewRelationship
        ? state.setIn(
            ['byId', action.payload.supplierRelationship.id],
            fromJS(action.payload.supplierRelationship)
          )
        : state.setIn(
            [
              'byId',
              action.payload.supplierRelationship.id,
              'buyerCollaboration',
              'certificationValidation'
            ],
            fromJS(
              action.payload.supplierRelationship.buyerCollaboration
                .certificationValidation
            )
          )

    case requestSuccess(REMOVE_CERTIFICATION_VALIDATION):
      const { category, subCategory, timeStamp } = action.payload
      return state.setIn(
        [
          'byId',
          action.payload.supplierRelationshipId,
          'buyerCollaboration',
          'certificationValidation',
          `${category}-${subCategory}-${timeStamp || ''}`
        ],
        state
          .getIn(
            [
              'byId',
              action.payload.supplierRelationshipId,
              'buyerCollaboration',
              'certificationValidation',
              `${category}-${subCategory}-${timeStamp || ''}`
            ],
            fromJS([])
          )
          .shift()
      )

    case requestSuccess(VERIFY_SUPPLIER):
      return state.setIn(
        [
          'byId',
          action.payload.supplierRelationshipId,
          'buyerCollaboration',
          'verifyClient'
        ],
        action.payload.verifyClient
      )

    default:
      return state
  }
}

export default supplierRelationshipsReducer
