import { call } from 'redux-saga/effects'
import vets from 'shared/utils/api/vets'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

function* getSupplierVetsSaga(action) {
  const response = yield call(vets.getVetBSsForSupplier, action.payload)
  return yield {
    totalCount: response.count,
    vetBuyerSuppliers: response.details
  }
}

export default createApiRequestSaga(getSupplierVetsSaga)
