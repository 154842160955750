import { put, select, call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { requestBuyerForReference } from '../../actions'
import { updateData } from 'shared/actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import relationships from 'shared/utils/api/relationships'
import profileSelectors from '../../selectors/profileSelectors'
import { List } from 'immutable'

export function* requestBuyerForReferenceSaga(
  action: ReturnType<typeof requestBuyerForReference>
) {
  const currentUserOrgUnitId = yield select(sessionSelectors.getOrgUnitId)
  const currentUserId = yield select(sessionSelectors.getUserId)
  const { companyName, ...rest } = action.payload

  const weekFromToday = new Date()
  weekFromToday.setDate(weekFromToday.getDate() + 7)

  yield put(
    updateData({
      entity: 'tasks',
      record: {
        type: 'RequestBuyerContact',
        linkedOrgUnit: currentUserOrgUnitId,
        orgUnitName: companyName,
        responseDate: weekFromToday,
        form: rest
      }
    })
  )

  const relationship = yield select(profileSelectors.getRelationship)
  const newReferenceRequest = {
    created: {
      user: currentUserId,
      date: new Date()
    },
    status: 'Pending',
    statusDate: new Date()
  }

  if (relationship && relationship.get('id')) {
    const referenceRequests = relationship.get('referenceRequests') || List([])
    return yield call(relationships.update, relationship.get('id'), {
      referenceRequests: referenceRequests.push(newReferenceRequest).toJS()
    })
  } else {
    const currentSupplierId = yield select(profileSelectors.getDataField, 'id')
    return yield call(relationships.create, currentSupplierId, {
      referenceRequests: [newReferenceRequest]
    })
  }
}

export default createApiRequestSaga(requestBuyerForReferenceSaga)
