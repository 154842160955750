import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'
import buyerPaths from '../routes/paths'

export const loadVets = createAction(actionTypes.LOAD_VETS)
export const loadVetsSuccess = createAction(actionTypes.LOAD_VETS_SUCCESS)
export const loadVetsFailure = createAction(actionTypes.LOAD_VETS_FAILURE)

export const startNewVet = createAction(actionTypes.START_NEW_VET)
export const createVet = createAction(actionTypes.CREATE_VET)
export const closeVet = createAction(actionTypes.CLOSE_VET)
export const cancelVet = createAction(actionTypes.CANCEL_VET)
export const reopenVet = createAction(actionTypes.REOPEN_VET)
export const deleteVet = createAction(
  actionTypes.DELETE_VET,
  payload => payload,
  () => ({
    redirectTo: buyerPaths.vets
  })
)

export const updateVet = createAction(actionTypes.UPDATE_VET)
export const updateVetSuccess = createAction(actionTypes.UPDATE_VET_SUCCESS)
export const updateVetFailure = createAction(actionTypes.UPDATE_VET_FAILURE)

export const loadVet = createAction(
  actionTypes.LOAD_VET,
  payload => payload,
  (payload, dontClearState) => ({
    dontClearState
  })
)
export const loadVetSuccess = createAction(actionTypes.LOAD_VET_SUCCESS)
export const loadVetFailure = createAction(actionTypes.LOAD_VET_FAILURE)

export const removeVetTeamMember = createAction(
  actionTypes.REMOVE_VET_TEAM_MEMBER
)
export const updateVetTeamMember = createAction(
  actionTypes.UPDATE_VET_TEAM_MEMBER
)

export const addVetSupplier = createAction(actionTypes.ADD_VET_SUPPLIER)
export const addVetSupplierSuccess = createAction(
  actionTypes.ADD_VET_SUPPLIER_SUCCESS
)
export const addVetSupplierFailure = createAction(
  actionTypes.ADD_VET_SUPPLIER_FAILURE
)

export const addVetSupplierByEmail = createAction(
  actionTypes.ADD_VET_SUPPLIER_BY_EMAIL
)
export const inviteSupplierFromVet = createAction(
  actionTypes.INVITE_SUPPLIER_FROM_VET
)
export const resetAddByEmail = createAction(actionTypes.RESET_ADD_BY_EMAIL)

export const removeVetSupplier = createAction(actionTypes.REMOVE_VET_SUPPLIER)
export const removeVetSupplierSuccess = createAction(
  actionTypes.REMOVE_VET_SUPPLIER_SUCCESS
)
export const removeVetSupplierFailure = createAction(
  actionTypes.REMOVE_VET_SUPPLIER_FAILURE
)

export const inviteVetSupplier = createAction(actionTypes.INVITE_VET_SUPPLIER)
export const inviteVetSupplierSuccess = createAction(
  actionTypes.INVITE_VET_SUPPLIER_SUCCESS
)
export const inviteVetSupplierFailure = createAction(
  actionTypes.INVITE_VET_SUPPLIER_FAILURE
)

export const approveVetSupplier = createAction(actionTypes.APPROVE_VET_SUPPLIER)
export const approveVetSupplierSuccess = createAction(
  actionTypes.APPROVE_VET_SUPPLIER_SUCCESS
)
export const approveVetSupplierFailure = createAction(
  actionTypes.APPROVE_VET_SUPPLIER_FAILURE
)

export const addInternalComment = createAction(actionTypes.ADD_INTERNAL_COMMENT)
export const addInternalCommentSuccess = createAction(
  actionTypes.ADD_INTERNAL_COMMENT_SUCCESS
)
export const addInternalCommentFailure = createAction(
  actionTypes.ADD_INTERNAL_COMMENT_FAILURE
)
export const updateInternalComment = createAction(
  actionTypes.UPDATE_INTERNAL_COMMENT
)
export const removeInternalComment = createAction(
  actionTypes.REMOVE_INTERNAL_COMMENT
)
export const tagSuppliers = createAction(actionTypes.TAG_SUPPLIERS)

export const removeCriteria = createAction(actionTypes.REMOVE_CRITERIA)

export const upVote = createAction(actionTypes.UP_VOTE)
export const downVote = createAction(actionTypes.DOWN_VOTE)

export const chooseVetSupplier = createAction(actionTypes.CHOOSE_VET_SUPPLIER)
export const rejectVetSupplier = createAction(actionTypes.REJECT_VET_SUPPLIER)

export const removeAttachment = createAction(actionTypes.REMOVE_ATTACHMENT)
export const deleteProjectBackground = createAction(
  actionTypes.DELETE_PROJECT_BACKGROUND
)

export const clearErrorMessage = createAction(actionTypes.CLEAR_ERROR_MESSAGE)

export const updateVetBuyerSupplier = createAction(
  actionTypes.UPDATE_VET_BUYER_SUPPLIER
)
export const removeResponseFile = createAction(actionTypes.REMOVE_RESPONSE_FILE)
export const addVetAddition = createAction(actionTypes.ADD_VET_ADDITION)
export const cancelVetAddition = createAction(actionTypes.CANCEL_VET_ADDITION)

export const addContactSendTo = createAction(actionTypes.ADD_CONTACT_SEND_TO)
export const removeContactSendTo = createAction(
  actionTypes.REMOVE_CONTACT_SEND_TO
)
export const updateContactSendTo = createAction(
  actionTypes.UPDATE_CONTACT_SEND_TO
)
export const loadVetQuestions = createAction(actionTypes.LOAD_VET_QUESTIONS)
export const answerVetQuestion = createAction(actionTypes.ANSWER_VET_QUESTION)
