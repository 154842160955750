import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

export const loadCommunity = createAction(actionTypes.LOAD_COMMUNITY)
export const loadCommunities = createAction(actionTypes.LOAD_COMMUNITIES)
export const acceptCommunity = createAction(actionTypes.ACCEPT_COMMUNITY)
export const declineCommunity = createAction(actionTypes.DECLINE_COMMUNITY)
export const leaveCommunity = createAction(actionTypes.LEAVE_COMMUNITY)

export const answerQuestion = createAction(actionTypes.ANSWER_QUESTION)
export const submitCommunityFormResponse = createAction(
  actionTypes.SUBMIT_COMMUNITY_FORM_RESPONSE
)
export const viewCommunityFormPage = createAction(
  actionTypes.VIEW_COMMUNITY_FORM_PAGE
)
