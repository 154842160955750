import React, { Component } from 'react'

const styles = {
  wrapper: ''
}

class List extends Component {
  render() {
    return <div className={styles.wrapper}>{this.props.children}</div>
  }
}

export default List
