import { fromJS } from 'immutable'
import {
  EDIT_CONTACT,
  CANCEL_EDIT_CONTACT,
  UPDATE_CONTACT
} from '../../actionTypes'
import { requestSuccess } from 'shared/actionTypes'

const editContactReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case CANCEL_EDIT_CONTACT:
      return state.merge(
        fromJS({
          isEditing: false,
          contact: undefined
        })
      )

    case EDIT_CONTACT:
      return state.merge(
        fromJS({
          isEditing: true,
          contact: action.payload
        })
      )
    case requestSuccess(UPDATE_CONTACT):
      return state.merge(
        fromJS({
          isEditing: false
        })
      )
    default:
      return state
  }
}

export default editContactReducer
