import React, { Component } from 'react'
import Page from 'shared/components/Page'
import FindSupplierContainer from '../../containers/FindSupplierContainer'
import TealbotOrgSearchResults from '../../containers/TealbotOrgSearchResults'
import CreateOrganizationFormContainer from '../../containers/CreateOrganizationFormContainer'
import SearchBarContainer from '../../containers/SearchBarContainer'

type Props = {
  onSelectOrgForRelationshipSuppliersStat?: (
    orgId: string,
    orgName: string
  ) => void
}

class TealbotPage extends Component<Props> {
  render() {
    const { onSelectOrgForRelationshipSuppliersStat } = this.props

    return (
      <Page title='tealbot'>
        <div className='w-75-ns center'>
          <SearchBarContainer />
          <FindSupplierContainer
            orgSearchResults={
              <TealbotOrgSearchResults
                onSelectOrgForRelationshipSuppliersStat={
                  onSelectOrgForRelationshipSuppliersStat
                }
              />
            }
            createOrgForm={<CreateOrganizationFormContainer />}
          />
        </div>
      </Page>
    )
  }
}

export default TealbotPage
