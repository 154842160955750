import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'

export const apiRoute = `${apiRoutes.authService}/dqService`

export const uploadFile = (file: File) => {
  return api.postFile(`${apiRoute}/fileUpload`, file)
}

export const confirmColumnsMapping = ({ jobId, filename, ...rest }) => {
  return api.post(`${apiRoute}/load/${jobId}/${filename}`, rest)
}

export const getJobs = () => {
  return api.get(`${apiRoute}/jobs`)
}

export const downloadFile = ({ jobId, folder, filename }) => {
  return api.get(`${apiRoute}/downloadFile/${jobId}/${folder}/${filename}`)
}

export const getJobStatus = jobId => {
  return api
    .get(`${apiRoute}/jobs/${jobId}`)
    .then(({ dqRowsStatus, ...rest }) => ({
      dqRowsStatus: !dqRowsStatus ? {} : dqRowsStatus,
      ...rest
    }))
}
