import React, { Component, ReactNode } from 'react'
import ListItem, { ListItemProps } from '../List/ListItem'
import Avatar from '../Avatar'
import Link from '../Link'

export type UserListItemProps = {
  profilePictureUrl: string
  firstName: string
  lastName: string
  email?: string
  prefix?: string | ReactNode
  postfix?: string
  highlightAvatar?: string
  profileUrl?: string
  title?: string
} & Omit<ListItemProps, 'primaryText' | 'leftAvatar'>

class UserListItem extends Component<UserListItemProps> {
  withLink(node: ReactNode) {
    return this.props.profileUrl ? (
      <Link to={this.props.profileUrl}>{node}</Link>
    ) : (
      node
    )
  }

  render() {
    const {
      profilePictureUrl,
      firstName,
      lastName,
      email,
      prefix,
      postfix,
      highlightAvatar,
      title,
      ...otherProps
    } = this.props

    return (
      <ListItem
        {...otherProps}
        leftAvatar={this.withLink(
          <Avatar
            url={profilePictureUrl}
            className='w-100 h-100'
            name={`${firstName} ${lastName}`}
            highlight={highlightAvatar}
          />
        )}
        primaryText={this.withLink(
          !firstName && !lastName ? (
            <span className='black'>
              {prefix && `${prefix} `}
              {email}
              {postfix && ` ${postfix}`}
            </span>
          ) : (
            <>
              <span className='black'>
                {prefix && `${prefix} `}
                {firstName} {lastName}
                {postfix && ` ${postfix}`}
              </span>
              {title ? (
                <span className='db f9 fw4 black'> {title}</span>
              ) : (
                undefined
              )}
            </>
          )
        )}
      />
    )
  }
}

export default UserListItem
