import React, { FC, useState, ChangeEvent, MouseEvent } from 'react'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import RadioButton from 'shared/components/RadioButton'
import RadioButtonGroup from 'shared/components/RadioButton/RadioButtonGroup'
import Button from 'shared/components/Button'
import TextArea from 'shared/components/TextArea'
import Page from 'shared/components/Page'
import Main from 'shared/components/Layout/Main'
import PageSection from 'shared/components/PageSection'
import { FormattedMessage } from 'react-intl'
import RichTextEditor, { createValueFromString } from 'react-rte'

export const Terms = () => (
  <>
    <Text>
      <FormattedMessage
        id='VetTermsPage.TermsTextOne'
        defaultMessage='Both parties may receive certain information
      that is proprietary and confidential to the other party
      or to a third party with which TealBook Client or TealBook
      supplier has entered into a confidentiality agreement.
      Neither party may use any of this information other than in
      connection with its performance of the Services, nor may it
      disclose this information to any other person or entity without
      the disclosing the party’s prior written approval.'
      />
    </Text>
    <br />
    <Text>
      <FormattedMessage
        id='VetTermsPage.TermsTextTwo'
        defaultMessage='This proprietary and confidential information includes,
      but is not limited to, any marketing agency, client, or contact
      lists, or any technical, scientific, trade, research,
      manufacturing, marketing, customer, supplier, or distribution
      information that will be disclosed to the receiving party,
      regardless of whether such information is specifically designated
      as confidential and regardless of whether such information is in
      written, oral, electronic, or other form.'
      />
    </Text>
    <br />
    <Text>
      <FormattedMessage
        id='VetTermsPage.ConfidentialityDoNotApplyTo'
        defaultMessage='These confidentiality and non-use obligations do not apply to
      information that:'
      />
    </Text>
    <ol type='i' className='lh-copy f7 fw4 mid-gray mv2'>
      <li className='mb2'>
        {' '}
        <FormattedMessage
          id='VetTermsPage.PartysPossessionList'
          defaultMessage='is already in a party’s possession without an obligation of confidentiality (as shown by written records)'
        />{' '}
      </li>
      <li className='mb2'>
        {' '}
        <FormattedMessage
          id='VetTermsPage.PublicDomainList'
          defaultMessage='was in the public domain at the time it was disclosed to the receiving party, or later becomes part of the public domain other than through disclosure of that information by the receiving party'
        />{' '}
      </li>
      <li className='mb2'>
        {' '}
        <FormattedMessage
          id='VetTermsPage.WithoutBreachOfAgreementList'
          defaultMessage='becomes known to the receiving party without restriction from a source other than the disclosing party, without breach of this Agreement and not otherwise in violation of our rights'
        />{' '}
      </li>
      <li className='mb2'>
        {' '}
        <FormattedMessage
          id='VetTermsPage.DisclosedPursuantList'
          defaultMessage='must be disclosed pursuant to an order or requirement of a court, administrative agency, or other governmental body, provided that the receiving party gives the disclosing party prompt notice of any such order or requirement and cooperate with the disclosing party to determine whether to take legally available steps to resist or narrow such disclosure, and that the receiving party takes all reasonable and lawful actions to obtain confidential treatment for such disclosure.'
        />{' '}
      </li>
    </ol>
    <Text>
      <FormattedMessage
        id='VetTermsPage.TermsTextThree'
        defaultMessage='Information shall not be considered in the public domain
      simply because one or more component portions of it are in the
      public domain. These confidentiality and non-use obligations will
      survive the expiration or any termination of this Agreement, and
      will continue for 5 years after the effective date of that expiration
      or termination.'
      />
    </Text>
  </>
)

type Props = {
  onDeclineVet: (string) => void
  terms: string
  onOpenVet: (e: MouseEvent<HTMLButtonElement>) => void
}

const VetTermsPage: FC<Props> = ({ onDeclineVet, terms, onOpenVet }) => {
  const [accepted, setAccepted] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const handleAcceptToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setAccepted(e.target.value)
  }
  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value)
  }

  const handleDeclineVet = () => {
    onDeclineVet(description)
  }

  return (
    <Page
      title={
        <FormattedMessage id='VetTermsPage.TermsTitle' defaultMessage='Terms' />
      }
    >
      <Main>
        <PageSection
          title={
            <FormattedMessage
              id='VetTermsPage.TermsAndConditionTitle'
              defaultMessage='Terms and Conditions'
            />
          }
        >
          {terms ? (
            <div className='rte-readonly-wrapper mt3-5'>
              <RichTextEditor
                value={createValueFromString(terms, 'markdown')}
                editorClassName='readonly'
                readOnly
              />
            </div>
          ) : (
            <div className='mt3-5'>
              <Terms />
            </div>
          )}
          <div className='pt3-5'>
            <RadioButtonGroup
              title={
                <FormattedMessage
                  id='VetTermsPage.AgreeToTermsAndConditions'
                  defaultMessage='I agree to the terms and conditions stated above.'
                />
              }
            >
              <RadioButton
                label={
                  <FormattedMessage
                    id='VetTermsPage.YesRadioButton'
                    defaultMessage='Yes'
                  />
                }
                value={'yes'}
                name='accept'
                onChange={handleAcceptToggle}
              />
              <RadioButton
                label={<FormattedMessage id='No' />}
                value={'no'}
                name='accept'
                onChange={handleAcceptToggle}
              />
            </RadioButtonGroup>
          </div>
          {accepted === 'no' && (
            <div className='mt4'>
              <Label>
                <FormattedMessage
                  id='VetTermsPage.RFIDoNotAgreeLabel'
                  defaultMessage='Please provide an explanation for declining the RFI (optional)'
                />
              </Label>
              <FormattedMessage
                id='VetTermsPage.DoNotAgreePlaceHolder'
                defaultMessage="Please let us know why you don't agree with the terms and conditions"
              >
                {(message: string) => (
                  <TextArea
                    placeholder={message}
                    maxLength={500}
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                )}
              </FormattedMessage>
              <Button
                autoSize
                label={
                  <FormattedMessage
                    id='VetTermsPage.SendButton'
                    defaultMessage='Send'
                  />
                }
                onClick={handleDeclineVet}
              />
            </div>
          )}

          {accepted === 'yes' && (
            <div className='mt4'>
              <Button
                autoSize
                type='submit'
                label={
                  <FormattedMessage
                    id='VetTermsPage.ContinueButton'
                    defaultMessage='Continue'
                  />
                }
                onClick={onOpenVet}
              />
            </div>
          )}
        </PageSection>
      </Main>
    </Page>
  )
}

export default VetTermsPage
