import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import startCase from 'lodash.startcase'

export const messages = defineMessages({
  ExpiringContract: {
    id: 'RequestTypeByCell.contractRenewalRequest',
    defaultMessage: 'Contract Renewal Request'
  },
  SelfCertifyRequest: {
    id: 'RequestTypeByCell.selfCertifyRequest',
    defaultMessage: 'Self Certify Request'
  },
  ESGSurveyRequest: {
    id: 'RequestTypeByCell.esgSurveyRequest',
    defaultMessage: 'Sustainability Survey Request'
  },
  EcoVadisAssessment: {
    id: 'RequestTypeByCell.EcoVadisAssessment',
    defaultMessage: 'EcoVadis Assessment'
  }
})

const RequestTypeByCell = ({ outreachType }) => {
  const intl = useIntl()
  return (
    <div>
      {messages[outreachType]
        ? intl.formatMessage(messages[outreachType])
        : startCase(outreachType)}
    </div>
  )
}

export default RequestTypeByCell
