import { Map } from 'immutable'
import * as actionTypes from '../../actionTypes'

const editModeReducer = (state = Map({}), action) => {
  switch (action.type) {
    case actionTypes.START_EDIT_MODE:
      return state.set(action.payload, true)

    case actionTypes.STOP_EDIT_MODE:
      return state.set(action.payload, false)

    default:
      return state
  }
}

export default editModeReducer
