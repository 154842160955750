import React, { Component, ReactNode } from 'react'
import { Field } from 'redux-form/immutable'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import storageManager from 'shared/utils/storageManager'
import CloseIcon from '@material-ui/icons/Clear'
import { FormattedMessage } from 'react-intl'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'

const styles = {
  form: 'bg-near-white ph3-5 pb3-5'
}

type Props = {
  label?: ReactNode | string
  inputId?: string
  inputPlaceholder: string | ReactNode
  numberOfTags: number
  limit: number
  footerNote?: string | ReactNode
  onSubmit?: () => void
  showButton?: boolean
  onChange?: (element: HTMLElement) => void
}

type State = {
  showHint: boolean
  doNotShow?: boolean
  limitReached: boolean
}

class AddTagForm extends Component<Props, State> {
  static defaultProps = {
    limit: 100,
    numberOfTags: 0
  }

  state: State = {
    showHint: false,
    limitReached: false
  }

  componentDidMount() {
    this.setState({
      doNotShow: !!storageManager.getItem('doNotShowAddTagHint')
    })
  }

  handleChange = e => {
    const { onChange, numberOfTags, limit } = this.props
    const { doNotShow } = this.state
    if (e.target.value.split(',').length + numberOfTags > limit) {
      this.setState({ limitReached: true })
    } else {
      this.setState({ limitReached: false })
    }
    if (!doNotShow) {
      this.setState({ showHint: e.target.value !== '' })
    }
    if (onChange) {
      onChange(e)
    }
  }

  handleKeyDown = e => {
    const { doNotShow } = this.state
    if (e.keyCode === 13 && this.state.limitReached) {
      e.preventDefault()
    }
    if (!doNotShow && e.keyCode === 13) {
      this.setState({ showHint: false })
    }
  }

  handleDoNotShow = () => {
    storageManager.setItem('doNotShowAddTagHint', true)
    this.setState({ doNotShow: true })
  }

  render() {
    const {
      label,
      inputPlaceholder,
      footerNote,
      limit,
      showButton,
      onSubmit
    } = this.props
    const { showHint, doNotShow, limitReached } = this.state

    return (
      <form onSubmit={onSubmit} className={styles.form}>
        <Label htmlFor={this.props.inputId}>{label}</Label>
        <div className='dt w-100'>
          <div className='dtc v-mid'>
            <Field
              component={Input}
              id={this.props.inputId}
              name='newTag'
              placeholder={inputPlaceholder as string}
              required
              maxLength={150}
              autoComplete='off'
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
            />
          </div>

          {showButton && (
            <div className='dtc v-mid'>
              <Button
                label={
                  <FormattedMessage id='AddTagsForm.Add' defaultMessage='Add' />
                }
                type='submit'
                className='ml2'
                disabled={limitReached}
              />
            </div>
          )}
        </div>
        {!doNotShow && showHint && (
          <Text className='red mt2 flex items-center'>
            <img
              src={TealbotCircleImage}
              alt='tealbot-tip'
              className='mr3 w2 h2'
            />
            <FormattedMessage
              id='AddTagForm.PressEnterToAddNewTag'
              defaultMessage='Press enter to add new tag.'
            />
            <span
              className='ml1 dim teal pointer bg-light-gray ph1 br2'
              onClick={this.handleDoNotShow}
            >
              <CloseIcon style={{ width: 10, height: 10 }} />
              <FormattedMessage
                id='AddTagForm.DoNotShowAgain'
                defaultMessage='Do not show again'
              />
            </span>
          </Text>
        )}
        {limitReached && (
          <Text className='red mt1'>
            <FormattedMessage
              id='AddTagForm.TheNumberOfTagsExceededMaxLimit'
              defaultMessage='The number of tags exceeded maximum limit ({limit})'
              values={{ limit }}
            />
          </Text>
        )}
        {footerNote && <Text className='mt3'>{footerNote}</Text>}
      </form>
    )
  }
}

export default AddTagForm
