import React, { Component } from 'react'
import { connect } from 'react-redux'
import currentVetSuppliersSelectors from '../../../Vets/selectors/currentVetSuppliersSelectors'
import collectionSelectors from '../../../Search/selectors/collectionSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { addVetSupplier } from 'buyer/Vets/actions'
import { addCollection, removeCollection } from 'buyer/Search/actions'
import currentVetSelectors from '../../../Vets/selectors/currentVetSelectors'
import searchSelectors from '../../../Search/selectors/searchSelectors'
import iconAddCart from 'shared/assets/icons/add-collection.svg'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import IconButton from 'shared/components/IconButton'
type Props = {
  supplier: any
  vetId: string
  isInVet: boolean
  isInCollection: boolean
  userId: string
  supplierId: string
  vetIsReadOnly: boolean
  buttonStyle?: boolean
  showRemove?: boolean
  onAddVetSupplier: (arg: { vetId: string; supplierIds: Array<string> }) => void
  onAddCollection: (
    arg0: { supplierId: string; userId: string },
    arg1: string
  ) => void
  onRemoveCollection: (payload: { userId: string; supplierId: string }) => void
}
export class AddSupplierListItemContainer extends Component<Props> {
  render() {
    const {
      isInVet,
      isInCollection,
      supplier,
      userId,
      supplierId,
      vetId,
      onAddVetSupplier,
      onAddCollection,
      onRemoveCollection,
      vetIsReadOnly,
      buttonStyle,
      showRemove
    } = this.props

    let addToVet
    if (vetId && !vetIsReadOnly && !isInVet) {
      addToVet = (
        <Tooltip
          title={
            <FormattedMessage
              id='AddSupplierListItemContainer.AddToRFI'
              defaultMessage='Add to RFI'
            />
          }
        >
          <span
            id='addVetsText'
            className='f8 fw6 ma0 dib v-mid gray pointer underline-hover hover-teal mr2 dim'
            onClick={() =>
              onAddVetSupplier({ vetId, supplierIds: [supplierId] })
            }
          >
            <span
              className='dib br--left br2 white pl1 pv1 pr2 v-mid f9 fw4'
              style={{ background: '#8B8B8B' }}
            >
              RFI
            </span>
            <span
              className='dib bg-white ba br-100 v-mid relative tc f4 nl2'
              style={{
                color: '#8B8B8B',
                height: '15px',
                width: '15px',
                lineHeight: '15px',
                borderColor: '#8B8B8B',
                borderWidth: '2px'
              }}
            >
              +
            </span>
          </span>
        </Tooltip>
      )
    }

    const addToCollection = !isInCollection ? (
      <Tooltip
        title={
          <FormattedMessage
            id='AddSupplierListItemContainer.AddToList'
            defaultMessage='Add to List'
          />
        }
      >
        {buttonStyle ? (
          <Button
            size='small'
            onClick={() =>
              onAddCollection({ supplierId, userId }, supplier.toJS())
            }
          >
            <FormattedMessage
              id='AddSupplierListItemContainer.AddToList'
              defaultMessage='Add to List'
            />
          </Button>
        ) : (
          <IconButton
            onClick={() =>
              onAddCollection({ supplierId, userId }, supplier.toJS())
            }
          >
            <img
              src={iconAddCart}
              alt='add to list'
              className='w1-5 v-mid pointer mr1'
            />
          </IconButton>
        )}
      </Tooltip>
    ) : showRemove ? (
      <Button
        size='small'
        variant='text'
        caution
        onClick={() => onRemoveCollection({ supplierId, userId })}
      >
        <FormattedMessage
          id='AddSupplierListItemContainer.RemoveFromList'
          defaultMessage='Remove from List'
        />
      </Button>
    ) : null

    return (
      <div className='dib v-mid'>
        {addToCollection} {addToVet}
      </div>
    )
  }
}

export const mapStateToProps = (state, props) => {
  const { vetId, supplierId } = props

  const resultItem = searchSelectors.getResultItemBySupplierId(
    state,
    supplierId
  )
  const userId = sessionSelectors.getUserId(state)

  return {
    userId,
    supplier: resultItem && resultItem.get('supplier'),
    vetIsReadOnly: currentVetSelectors.isReadonly(state),
    isInVet:
      vetId && currentVetSuppliersSelectors.isInCurrentVet(state, supplierId),
    isInCollection: collectionSelectors.isInCollection(state, supplierId)
  }
}

export default connect(mapStateToProps, {
  onAddVetSupplier: addVetSupplier,
  onAddCollection: addCollection,
  onRemoveCollection: removeCollection
})(AddSupplierListItemContainer)
