import React, { Component } from 'react'
import { connect } from 'react-redux'
import ListItem from 'shared/components/List/ListItem'
import OrgLogo from 'shared/components/OrgLogo'
import { openGetVerifiedDialog } from '../../../Company/actions'
import dataSelectors from 'shared/selectors/dataSelectors'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { FormattedMessage } from 'react-intl'
import { RecordOf } from 'immutable'
import Button from 'shared/components/Button'

type Props = {
  orgUnitId: string
  orgUnitName: string
  orgLogoUrl: string
  openGetVerifiedDialog: (orgUnitId: string) => void
  client: RecordOf<{
    supplierCollaboration: {
      requestVerify: boolean
    }
    buyerCollaboration: {
      verifyClient: 'agree' | 'disagree'
    }
  }>
}

export class ConnectionListItem extends Component<Props> {
  handleClickGetVerified = () => {
    const { orgUnitId, openGetVerifiedDialog } = this.props

    openGetVerifiedDialog(orgUnitId)
  }

  render() {
    const { orgUnitName, orgLogoUrl, client } = this.props

    return (
      <ListItem
        mini
        className='pv2'
        leftAvatar={<OrgLogo url={orgLogoUrl} name={orgUnitName} />}
        primaryText={orgUnitName}
        secondaryText={
          !client ? (
            <Button
              autoSize
              size='small'
              secondary
              onClick={this.handleClickGetVerified}
            >
              <FormattedMessage id='ClientListItemContainer.GetVerifiedButton' />
            </Button>
          ) : client.getIn(['buyerCollaboration', 'verifyClient']) ===
            'agree' ? (
            <CheckCircleIcon className='teal' />
          ) : client.getIn(['buyerCollaboration', 'verifyClient']) ===
            'disagree' ? (
            <div className='red flex'>
              <ErrorIcon />
              <span className='flex-auto ml1'>
                <FormattedMessage id='ClientListItemContainer.RelationshipNotConfirmed' />
              </span>
            </div>
          ) : client.getIn(['supplierCollaboration', 'requestVerify']) ? (
            <span className='f6 gray'>
              <FormattedMessage id='ClientListItemContainer.PendingStatus' />
            </span>
          ) : (
            <Button
              autoSize
              size='small'
              secondary
              onClick={this.handleClickGetVerified}
            >
              <FormattedMessage id='ClientListItemContainer.GetVerifiedButton' />
            </Button>
          )
        }
      />
    )
  }
}

type ContainerProps = {
  orgUnitId: string
}

export default connect(
  (state, props: ContainerProps) => ({
    client: dataSelectors.getSupplierRelationshipByBuyerId(
      state,
      props.orgUnitId
    )
  }),
  {
    openGetVerifiedDialog
  }
)(ConnectionListItem)
