import React from 'react'
import Avatar from 'shared/components/Avatar'
import Text from 'shared/components/Text'
import Link from 'shared/components/Link'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import { FormattedMessage } from 'react-intl'
import dateFormat from 'shared/utils/dateFormat'

export type PostBySectionProps = {
  id: string
  title: string
  profilePictureUrl: string
  isClientApp: boolean
  currentUserId: string
  currentUserName: string
  currentUserEmail: string
  userId: string
  firstName: string
  lastName: string
  email: string
  orgName: string
  orgUnitId?: string
  createdDate: string
  orgProfileUrl: string
  profileUrl: string
  handleReportPost: (params: any) => void
}

const PostBySection = (props: PostBySectionProps) => {
  const {
    id,
    title,
    profilePictureUrl,
    isClientApp,
    profileUrl,
    orgProfileUrl,
    currentUserId,
    currentUserEmail,
    currentUserName,
    orgUnitId,
    userId,
    firstName,
    lastName,
    email,
    orgName,
    createdDate,
    handleReportPost
  } = props
  return (
    <div className='flex justify-between items-center'>
      <div className='w2'>
        <Avatar
          url={profilePictureUrl}
          name={`${firstName || ''} ${lastName || ''}`}
        />
      </div>
      <div className='flex-auto pl2'>
        <Text className='truncate'>
          <Link to={profileUrl}>{`${firstName || ''} ${lastName || ''}`}</Link>{' '}
          {orgName && (
            <FormattedMessage
              id='SpotlightCard.AtOrgUnitName'
              defaultMessage='at <link>{orgName}</link>'
              values={{
                orgName,
                link: (name: string) =>
                  isClientApp && orgProfileUrl ? (
                    <Link to={orgProfileUrl}>{name}</Link>
                  ) : (
                    name
                  )
              }}
            />
          )}
        </Text>
        <Text>
          <FormattedMessage
            id='PostBySection.OnDate'
            defaultMessage='On {createdDate}'
            values={{
              createdDate: dateFormat(createdDate, 'MM/DD/YYYY', true)
            }}
          />
        </Text>
      </div>
      <DropDownMenu>
        <MenuItem
          onClick={() =>
            handleReportPost({
              action: 'Report on post',
              postId: id,
              postTitle: title,
              postDate: createdDate,
              postUserId: userId,
              postUserName: `${firstName} ${lastName}`,
              postUserEmail: email,
              postOrgUnitId: orgUnitId,
              postOrganization: orgName,
              reportUserId: currentUserId,
              reportUserEmail: currentUserEmail,
              reportUserName: currentUserName
            })
          }
        >
          <FormattedMessage
            id='PostItem.ReportPostMenuItem'
            defaultMessage='Report Post'
          />
        </MenuItem>
      </DropDownMenu>
    </div>
  )
}

export default PostBySection
