import React, { Component, ReactNode } from 'react'
import Paper from 'shared/components/Paper'

type Props = {
  title?: string | ReactNode
}

class UserProfileCard extends Component<Props> {
  render() {
    const { title, children } = this.props

    return (
      <div>
        <Paper>
          <h4 className='ma0 mb3 f7 fw6 mt3-5'>{title}</h4>
          <div className='mid-gray'>{children}</div>
        </Paper>
      </div>
    )
  }
}

export default UserProfileCard
