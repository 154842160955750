import { requestSuccess } from '../../actionTypes'
import * as communitiesActionTypes from 'supplier/Communities/actionTypes'
import { GET_COMMUNITY, GET_COMMUNITIES } from 'buyer/Communities/actionTypes'
import * as buyerActionTypes from 'buyer/shared/actionTypes'
import Question from '../../models/Question'

type QuestionsState = {
  byId: {
    [key: string]: Question | undefined
  }
}

const initialState: QuestionsState = {
  byId: {}
}

const questionsReducer = (state = initialState, action): QuestionsState => {
  switch (action.type) {
    case requestSuccess(buyerActionTypes.GET_SETTINGS):
    case requestSuccess(communitiesActionTypes.LOAD_COMMUNITY):
    case requestSuccess(GET_COMMUNITY):
    case requestSuccess(GET_COMMUNITIES):
      return {
        byId: {
          ...state.byId,
          ...action.payload.questionsById
        }
      }
    default:
      return state
  }
}

export default questionsReducer
