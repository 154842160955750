import camelcase from 'lodash.camelcase'

const extractExpandedEntitiesFromRecord = (record, aggResult = {}) => {
  if (record && record.expanded) {
    return Object.entries(record.expanded).reduce((result, [key, record]) => {
      const newKey = `${camelcase(key)}s`
      const aggResultEntities = aggResult[newKey] || {}

      return {
        ...result,

        [newKey]: {
          ...aggResultEntities,
          ...record
        }
      }
    }, {})
  }

  return {}
}

const extractExpandedEntitiesFromList = data =>
  data.reduce((result, record) => {
    const mergedResults = extractExpandedEntitiesFromRecord(record, result)

    return {
      ...result,
      ...mergedResults
    }
  }, {})

const extractExpandedEntities = listOrRecord =>
  Array.isArray(listOrRecord)
    ? extractExpandedEntitiesFromList(listOrRecord)
    : extractExpandedEntitiesFromRecord(listOrRecord)

export default extractExpandedEntities
