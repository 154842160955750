import * as actionTypes from '../actionTypes'
import { takeLatest, all, takeEvery } from 'redux-saga/effects'
import takeFirst from 'shared/utils/sagas/takeFirst'
import loadCommunitiesSaga from './loadCommunitiesSaga'
import declineCommunitySaga from './declineCommunitySaga'
import acceptCommunity from './acceptCommunitySaga'
import answerQuestionSaga from './answerQuestionSaga'
import submitCommunityFormResponseSaga from './submitCommunityFormResponseSaga'
import viewCommunityFormPageSaga from './viewCommunityFormPageSaga'
import loadCommunitySaga from './loadCommunitySaga'
import leaveCommunitySaga from './leaveCommunitySaga'

export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.LOAD_COMMUNITIES, loadCommunitiesSaga),
    takeFirst(actionTypes.DECLINE_COMMUNITY, declineCommunitySaga),
    takeFirst(actionTypes.ACCEPT_COMMUNITY, acceptCommunity),
    takeEvery(actionTypes.ANSWER_QUESTION, answerQuestionSaga),
    takeFirst(
      actionTypes.SUBMIT_COMMUNITY_FORM_RESPONSE,
      submitCommunityFormResponseSaga
    ),
    takeEvery(actionTypes.VIEW_COMMUNITY_FORM_PAGE, viewCommunityFormPageSaga),
    takeLatest(actionTypes.LOAD_COMMUNITY, loadCommunitySaga),
    takeFirst(actionTypes.LEAVE_COMMUNITY, leaveCommunitySaga)
  ])
}
