import React, { useState, ChangeEvent, useMemo, useEffect } from 'react'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/BorderColor'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import suppliers from 'shared/utils/api/suppliers'
import Select from 'shared/components/Select'
import startCase from 'lodash.startcase'
import Checkbox from '@material-ui/core/Checkbox'
import withStyles from '@material-ui/styles/withStyles'
import Scrollable from 'shared/components/Scrollable'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useSelector } from 'react-redux'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import RootState from 'shared/models/RootState'

const StyleCheckbox = withStyles({
  root: {
    padding: 3
  }
})(Checkbox)

type Props = {
  fieldName: string
  value: string
  onUpdate: (value) => void
  isReadonly?: boolean
}

const FilterRulesJsonEditor = (props: Props) => {
  const attachments = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'attachmentTypes')
  )
  const attributes = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'relationshipAttributes')
  )
  const [aggregations, setAggregations] = useState<
    { [key: string]: any } | undefined
  >(undefined)
  const [communities, setCommunities] = useState<
    { [key: string]: any } | undefined
  >(undefined)
  const [newKey, setNewKey] = useState<string>('')
  const [newValue, setNewValue] = useState<string[]>([])
  const [operator, setOperator] = useState<'AND' | 'OR'>('AND')
  const [editKey, setEditKey] = useState<string>('')
  const [editValue, setEditValue] = useState<string[]>([])
  const [editOperator, setEditOperator] = useState<'AND' | 'OR'>('AND')
  const [filters, setFilters] = useState<{ [key: string]: any }>(() => {
    try {
      const objValue = JSON.parse(props.value)
      return objValue?.reduce((filters, filter) => {
        return Object.assign(filters, filter)
      }, {})
    } catch (e) {
      console.error(e)
      return {}
    }
  })
  const [editingKey, setEditingKey] = useState<string>('')
  const [removingKey, setRemovingKey] = useState<string>('')

  useEffect(() => {
    // get all the available aggregations and their options for the filter
    if (!aggregations) {
      const configAttachments = attachments
        ?.entrySeq()
        .map(([key, value]) => ({ key: key }))
        .toJS()
      const configAttributes = attributes
        ?.entrySeq()
        .map(([key, value]) => ({ key: key }))
        .toJS()

      suppliers
        .searchSuppliers({ limit: 0, agg: true, scope: 'All' })
        .then(response => {
          const {
            aggregations: searchAggregations,
            communities: searchCommunities
          } = response
          setAggregations(
            Object.assign(searchAggregations, {
              attachments: configAttachments,
              attributes: configAttributes
            })
          )
          setCommunities(searchCommunities)
        })
    }
  }, [aggregations, attachments, attributes])

  // existing filter keys
  const setKeys = useMemo(() => {
    return filters ? Object.keys(filters) : []
  }, [filters])

  const handleKeyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setNewKey(e.currentTarget.value)
    setNewValue([])
  }

  const handleOperatorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.currentTarget.name === 'editOperator') {
      setEditOperator(e.currentTarget.value as 'OR' | 'AND')
    } else {
      setOperator(e.currentTarget.value as 'OR' | 'AND')
    }
  }

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const useValue = e.currentTarget.name === 'editValue' ? editValue : newValue
    const index = useValue.indexOf(e.currentTarget.value)
    const value =
      index === -1
        ? [...useValue, e.currentTarget.value]
        : [...useValue.slice(0, index), ...useValue.slice(index + 1)]

    if (e.currentTarget.name === 'editValue') {
      setEditValue(value)
    } else {
      setNewValue(value)
    }
  }

  const handleAddRule = () => {
    const newFilters = Object.assign({}, filters, {
      [newKey]: operator === 'OR' ? [newValue] : newValue
    })
    props.onUpdate({
      [props.fieldName]: [newFilters]
    })
    setFilters(newFilters)
    setNewKey('')
    setNewValue([])
  }

  const handleEditClick = (key: string) => {
    setEditKey(key)
    const value = filters[key]
    setEditValue(Array.isArray(value[0]) ? value[0] : value)
    setEditOperator(Array.isArray(value[0]) ? 'OR' : 'AND')
    setEditingKey(key)
  }

  const handleUpdateClick = () => {
    const newFilters = Object.assign({}, filters, {
      [editKey]: editOperator === 'OR' ? [editValue] : editValue
    })
    props.onUpdate({
      [props.fieldName]: [newFilters]
    })
    setFilters(newFilters)
    setEditingKey('')
    setEditKey('')
    setEditValue([])
  }

  const handleCancelClick = () => {
    setEditingKey('')
    setEditKey('')
    setEditValue([])
  }

  const removeFilter = () => {
    delete filters[removingKey]
    const newFilters = Object.assign({}, filters)
    props.onUpdate({
      [props.fieldName]: [newFilters]
    })
    setFilters(newFilters)
    setRemovingKey('')
  }

  return (
    <div className='mt2'>
      <form onSubmit={handleAddRule}>
        <div className='flex justify-between items-top'>
          <div className='mr2'>
            <Select
              value={newKey}
              onChange={handleKeyChange}
              disabled={!!editingKey}
              aria-label='key'
            >
              <option value='' disabled>
                Select a filter key
              </option>
              {aggregations &&
                Object.keys(aggregations).map(key =>
                  !setKeys.includes(key) ? (
                    <option key={key} value={key}>
                      {startCase(key)}
                    </option>
                  ) : null
                )}
            </Select>
          </div>
          <div className='flex-auto mr2'>
            <div className='br1 ba b--black-10'>
              {aggregations &&
              newKey &&
              aggregations[newKey] &&
              !!aggregations[newKey].length ? (
                <Scrollable maxHeight={140}>
                  {aggregations &&
                    aggregations[newKey]
                      // sort aggregation options
                      .sort((a1, a2) => {
                        return a1.key > a2.key ? 1 : -1
                      })
                      // generate checkboxes
                      .map(agg => {
                        return (
                          <div key={agg.key}>
                            <FormControlLabel
                              label={
                                <Text>
                                  {newKey === 'communities' && communities
                                    ? communities[agg.key]
                                    : startCase(agg.key)}
                                </Text>
                              }
                              control={
                                <StyleCheckbox
                                  color='primary'
                                  size='small'
                                  checked={newValue?.includes(agg.key)}
                                  value={agg.key}
                                  onChange={handleValueChange}
                                  disabled={!!editingKey}
                                />
                              }
                            />
                          </div>
                        )
                      })}
                </Scrollable>
              ) : (
                <Text style={{ padding: '.4rem' }}>No Value</Text>
              )}
            </div>
          </div>
          <div className='mr2'>
            <Select
              value={operator}
              onChange={handleOperatorChange}
              disabled={!!editingKey}
              aria-label='operator'
            >
              <option value='AND'>AND</option>
              <option value='OR'>OR</option>
            </Select>
          </div>
          <Button
            autoSize
            onClick={handleAddRule}
            disabled={!newKey || !newValue?.length || !!editingKey}
            aria-label='add'
          >
            Add
          </Button>
        </div>
      </form>
      <div className='mt2 br1 ba b--black-10'>
        <Table stickyHeader size='small'>
          <TableHead className='bg-light-gray pv3'>
            <TableRow>
              <TableCell style={{ width: 190 }}>Filter</TableCell>
              <TableCell>Value</TableCell>
              <TableCell style={{ width: 110 }}>Operator</TableCell>
              {!props.isReadonly && (
                <TableCell style={{ width: 100 }}>&nbsp;</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {setKeys.map(key => (
              <TableRow key={key}>
                <TableCell align='left'>
                  <Text>{startCase(key)}</Text>
                </TableCell>
                <TableCell align='left'>
                  {editingKey === key ? (
                    <div className='br1 ba b--black-10'>
                      {aggregations &&
                      editingKey &&
                      aggregations[editingKey] &&
                      !!aggregations[editingKey].length ? (
                        <Scrollable maxHeight={140}>
                          {aggregations[editingKey]
                            .sort((a1, a2) => {
                              return a1.key > a2.key ? 1 : -1
                            })
                            .map(agg => {
                              return (
                                <div key={agg.key}>
                                  <FormControlLabel
                                    label={
                                      <Text>
                                        {communities && newKey === 'communities'
                                          ? communities[agg.key]
                                          : startCase(agg.key)}
                                      </Text>
                                    }
                                    control={
                                      <StyleCheckbox
                                        name='editValue'
                                        color='primary'
                                        size='small'
                                        checked={editValue?.includes(agg.key)}
                                        value={agg.key}
                                        onChange={handleValueChange}
                                      />
                                    }
                                  />
                                </div>
                              )
                            })}
                        </Scrollable>
                      ) : (
                        <Text style={{ padding: '.4rem' }}>No Value</Text>
                      )}
                    </div>
                  ) : (
                    <Text>
                      {Array.isArray(filters[key][0])
                        ? filters[key][0]
                            .map(value => {
                              return key === 'communities'
                                ? (communities && communities[value]) || value
                                : startCase(value)
                            })
                            .join(', ')
                        : filters[key]
                            .map(value => {
                              return key === 'communities'
                                ? (communities && communities[value]) || value
                                : startCase(value)
                            })
                            .join(', ')}
                    </Text>
                  )}
                </TableCell>
                <TableCell>
                  {editingKey === key ? (
                    <Select
                      name='editOperator'
                      value={editOperator}
                      onChange={handleOperatorChange}
                      aria-label='edit operator'
                    >
                      <option value='AND'>AND</option>
                      <option value='OR'>OR</option>
                    </Select>
                  ) : (
                    <Text>{Array.isArray(filters[key][0]) ? 'OR' : 'AND'}</Text>
                  )}
                </TableCell>
                {!props.isReadonly && (
                  <TableCell align='right' style={{ verticalAlign: 'top' }}>
                    {!editingKey ? (
                      <>
                        <span
                          className='pointer dim mr2'
                          role='button'
                          onClick={() => handleEditClick(key)}
                          aria-label='edit'
                        >
                          <EditIcon fontSize='small' />
                        </span>
                        <span
                          role='button'
                          onClick={() => setRemovingKey(key)}
                          className='pointer dim'
                          aria-label='remove'
                        >
                          <CloseIcon fontSize='small' />
                        </span>
                      </>
                    ) : editingKey === key ? (
                      <>
                        <Button
                          size='small'
                          disabled={!editKey && !editValue?.length}
                          onClick={handleUpdateClick}
                          aria-label='update'
                        >
                          <FormattedMessage
                            id='Update'
                            defaultMessage='Update'
                          />
                        </Button>
                        <Button
                          secondary
                          size='small'
                          onClick={handleCancelClick}
                          className='mt2'
                          aria-label='cancel'
                        >
                          <FormattedMessage
                            id='Cancel'
                            defaultMessage='Cancel'
                          />
                        </Button>
                      </>
                    ) : null}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <ConfirmationDialog
        open={!!removingKey}
        onClose={() => setRemovingKey('')}
        onConfirm={removeFilter}
      >
        <Text>
          <FormattedMessage
            id='CategoriesJsonEditor.ConfirmRemove'
            defaultMessage='Are you sure you want to remove this?'
          />
        </Text>
      </ConfirmationDialog>
    </div>
  )
}

export default FilterRulesJsonEditor
