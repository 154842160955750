import React, { useCallback, useState, useMemo } from 'react'
import InviteToTier2Dialog from '../../components/InviteToTier2Dialog'
import { RecordOf, List } from 'immutable'
import { useSelector, useDispatch } from 'react-redux'
import communitySelectors from '../../../Communities/selectors/communitiesSelectors'
import RootState from 'shared/models/RootState'
import { batchInviteToTier2 } from '../../../Communities/actions'

type Tier2Communities = List<
  RecordOf<{
    buyerId: string
    buyerName: string
    communityId: string
    status: string
  }>
>
type Props = {
  inviteSupplierDialogOpen: boolean
  onCloseTier2Dialog: () => void
  suppliers: Array<
    RecordOf<{
      id: string
      name: string
      logoUrl: string
      relatedCompany: boolean
    }>
  >
}

const BatchInviteToTier2Container = ({
  suppliers,
  inviteSupplierDialogOpen,
  onCloseTier2Dialog
}: Props) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const dispatch = useDispatch()
  const tier2Community = useSelector<RootState, Tier2Communities>(state =>
    communitySelectors
      .getCommunitiesList(state)
      .filter(community => community.get('tier2'))
  )
  const supplierIds = useMemo(
    () =>
      suppliers.reduce<string[]>((result, currentId) => {
        result.push(currentId.get('id'))
        return result
      }, []),
    [suppliers]
  )
  const selectedSuppliers = supplierId => {
    if (
      supplierId === 'allSuppliers' &&
      selectedIds.length === supplierIds.length
    ) {
      setSelectedIds([])
    } else if (supplierId === 'allSuppliers') {
      setSelectedIds(supplierIds)
    } else if (selectedIds.includes(supplierId)) {
      setSelectedIds(selectedIds.filter(id => id !== supplierId))
    } else if (!selectedIds.includes(supplierId)) {
      setSelectedIds([...selectedIds, supplierId])
    }
  }
  const handleInvite = useCallback(() => {
    const tier2CommId = tier2Community && tier2Community.getIn([0, 'id'])
    dispatch(batchInviteToTier2({ selectedIds, tier2CommId }))
    onCloseTier2Dialog()
  }, [onCloseTier2Dialog, tier2Community, dispatch, selectedIds])

  return (
    <InviteToTier2Dialog
      onHandleInvite={handleInvite}
      onHandleCloseTier2Dialog={onCloseTier2Dialog}
      inviteSupplierDialogOpen={inviteSupplierDialogOpen}
      suppliers={suppliers}
      onSelectSuppliers={selectedSuppliers}
      selectedIds={selectedIds}
      supplierIds={supplierIds}
    />
  )
}

export default BatchInviteToTier2Container
