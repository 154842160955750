import React, { Component } from 'react'
import StarsRating from 'shared/components/StarsRating'
import { List } from 'immutable'

type Props = {
  ratings?: List<{ label: string; value: number }>
}

class RatingDetails extends Component<Props> {
  render() {
    const { ratings } = this.props

    return (
      <div>
        {ratings &&
          ratings.map((rating, index) => (
            <div key={index} className='dib mr4'>
              <StarsRating label={rating.label} value={rating.value} mini />
            </div>
          ))}
      </div>
    )
  }
}

export default RatingDetails
