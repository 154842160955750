import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { updateVet, tagSuppliers } from '../../actions'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import Pill from 'shared/components/Pill'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import currentVetSuppliersSelector from '../../selectors/currentVetSuppliersSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'
import RootState from 'shared/models/RootState'

type Props = {
  vetId: string
  readonly?: boolean
  isTealbot: boolean
  suppliersToTag: List<string>
  keywords: List<string>
  tagSuppliers: (supplierTags: {
    suppliersToTag: List<string>
    keywords: any
  }) => void
  updateVet: (vetChanges: { vetId: string; keywords: Array<string> }) => void
}

export class KeywordsContainer extends Component<Props> {
  handleDeleteKeyword = keyword => {
    const { updateVet, keywords, vetId } = this.props
    const index = keywords.indexOf(keyword)

    updateVet({
      vetId,
      keywords: keywords.delete(index).toArray()
    })
  }

  handleClickTagAllSuppliers = () => {
    const { keywords, tagSuppliers, suppliersToTag } = this.props
    tagSuppliers({
      suppliersToTag,
      keywords
    })
  }

  render() {
    const { keywords, readonly, suppliersToTag, isTealbot } = this.props

    return keywords && keywords.size > 0 ? (
      <Fragment>
        <Label>
          <FormattedMessage
            id='KeywordsContainer.Keywords'
            defaultMessage='Keywords'
          />
        </Label>
        {isTealbot && suppliersToTag && suppliersToTag.size > 0 && (
          <div className='tr'>
            <Button
              label={
                <FormattedMessage
                  id='KeywordsContainer.TagApprovedSuppliers'
                  defaultMessage='Tag approved suppliers'
                />
              }
              autoSize
              onClick={this.handleClickTagAllSuppliers}
            />
          </div>
        )}

        {keywords.map(keyword => (
          <Pill
            key={keyword}
            label={keyword}
            onRequestDelete={
              readonly ? undefined : () => this.handleDeleteKeyword(keyword)
            }
          />
        ))}
      </Fragment>
    ) : (
      <Fragment>
        <Label>
          <FormattedMessage
            id='KeywordsContainer.Keywords'
            defaultMessage='Keywords'
          />
        </Label>
        {readonly && (
          <Text>
            <FormattedMessage
              id='KeywordsContainer.KeywordNotDefined'
              defaultMessage='None'
            />
          </Text>
        )}
      </Fragment>
    )
  }
}

export default connect(
  (state: RootState) => ({
    keywords: currentVetSelectors.getVetField(state, 'keywords'),
    isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
    suppliersToTag: currentVetSuppliersSelector.getSuppliersToTag(state)
  }),
  {
    updateVet,
    tagSuppliers
  }
)(KeywordsContainer)
