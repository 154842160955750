import { call, delay } from 'redux-saga/effects'
import suppliers from '../../utils/api/suppliers'
import createApiRequestSaga from '../../utils/sagas/createApiRequestSaga'
import parseSupplier from '../../utils/data/parseSupplier'
import parseSuggest from '../../utils/data/parseSuggest'
import mapKeys from 'lodash.mapkeys'

export function* suggestOrgUnitSaga(action) {
  yield delay(300)

  // ignore if the query is: empty or short
  if (!action.payload) {
    return yield {}
  }

  const response = yield call(suppliers.suggestSuppliers, {
    q: action.payload,
    limit: 7
  })

  return yield {
    suggestedIds: response.map(supplier => supplier.id),
    orgUnitsById: mapKeys(
      response.map(supplier => parseSupplier(parseSuggest(supplier))),
      orgUnit => orgUnit.id
    )
  }
}

export default createApiRequestSaga(suggestOrgUnitSaga)
